import React from "react";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import {
  CardContent,
  Container,
  Grid,
  Card,
  Box,
  styled,
} from "@mui/material";
import Typography from "@mui/material/Typography";


//Translator TH-EN
import QuotaProgress from "./OuotaProgress";
import { AirlineSeatIndividualSuiteOutlined } from "@mui/icons-material";

const HeaderBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  padding: '16px 0 32px 0',
}));

const InnerHeaderBox = styled(Box)(() => ({
  borderBottom: '2px solid black',
  padding: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

function Leave({ t, leaveAvailable }) {
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrAfter);

  return (
    <Card sx={{ width: '100%', padding: '16px' }}>
      <CardContent>
        <HeaderBox>
          <InnerHeaderBox>
            <AirlineSeatIndividualSuiteOutlined sx={{ marginRight: 1, fontSize: 30, color: 'success.main' }} />
            <Typography margin={0} variant="h5" align="center" gutterBottom>
              {`${t("AllLeaveRights")}`}
            </Typography>
          </InnerHeaderBox>
        </HeaderBox>
        <div className="quota">

          <Grid container spacing={2}>
            {leaveAvailable.map((item, index) => (
              <Grid key={index} item xs={12} sm={6}>
                <QuotaProgress leaveGroup={item} />
              </Grid>
            ))}
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
}

export default Leave;
