import React, { Fragment, useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Stack,
  IconButton,
  DialogContent,
  Divider,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import * as Yup from "yup";
import dayjs from "dayjs";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import UploadFile from "./uploadFile";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { useDispatch } from "react-redux";
import {
  addPolicy,
  getPolicyByIdCompany,
  updatePolicy,
} from "../../../../actions/policy";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../shared/date/datePicker";
import { getUserCompany } from "../../../../utils/userData";
import { openNotificationAlert } from "../../../../actions/notificationAlert";

export default function CreateCompanyPolicy({
  open,
  handleClose,
  editData,
  selectedCompany,
}) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const dataPolicy = {
    title: "",
    details: [{ topic: "", detail: "" }],
    file: null,
    startDate: dayjs(),
    endDate: dayjs().add(1, "day"),
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(500).required(t("PleaseEnterTitle")),
    details: Yup.array().of(
      Yup.object().shape({
        topic: Yup.string()
          .max(500, t("TopicMustBeAtMost150Characters"))
          .required(t("TopicIsARequiredField")),
        detail: Yup.string()
          .max(1000, t("DetailMustBeAtMost250Characters"))
          .required(t("DetailIsARequiredField")),
      })
    ),
    startDate: Yup.date().min(
      dayjs().startOf("day"),
      t("StartDateMustBeLaterThanCurrentDate")
    ),
    endDate: Yup.date().when("startDate", (startDate) => {
      return Yup.date().min(
        dayjs(startDate).add(1, "day"),
        t("EndDateMustBeLaterThanStartDate")
      );
    }),
    file: Yup.mixed().nullable(),
  });

  const handleAddPolicy = async (values) => {
    if (!editData) {
      values.idCompany = selectedCompany.idCompany
    }

    const formData = new FormData();
    for (const key in values) {
      if (key === "details") {
        formData.append(key, JSON.stringify(values[key]));
      } else {
        formData.append(key, values[key]);
      }
    }

    let res = null;
    if (editData) {
      res = await dispatch(updatePolicy(formData));
    } else {
      res = await dispatch(addPolicy(formData));
    }

    if (res && res.status === 201) {
      dispatch(getPolicyByIdCompany({ idCompany: selectedCompany.idCompany }));
      handleClose();
      if (editData) {
        dispatch(
          openNotificationAlert({
            message: t("SuccessfullyEditedCompanyPolicy"),
            type: "success",
          })
        );
      } else {
        dispatch(
          openNotificationAlert({
            message: t("SuccessfullyCreatedCompanyPolicy"),
            type: "success",
          })
        );
      }
    } else {
      dispatch(
        openNotificationAlert({
          message: t("AnErrorOccurred"),
          type: "error",
        })
      );
    }
  };

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: dataPolicy,
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    name: "details",
    control,
  });

  useEffect(() => {
    if (editData) {
      reset({ ...editData, idPolicy: editData.idPolicy });
    }
  }, []);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {editData
          ? `${t("EditedCompanyPolicy")}`
          : `${t("CreatedCompanyPolicy")}`}
      </DialogTitle>
      <DialogContent>
        <Alert severity="info">
          {`${t("SelectCompany")}: ${getUserCompany({
            companyName: selectedCompany.companyName,
            companyName_EN: selectedCompany.companyName_EN,
          })}`}
        </Alert>
        <form onSubmit={handleSubmit(handleAddPolicy)}>
          <Grid container spacing={2} marginTop={0}>
            <Grid item xs={12}>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    fullWidth
                    label={`${t("PolicyName")}`}
                    error={Boolean(errors.title)}
                    helperText={errors.title && errors.title.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack gap={1}>
                {fields.map((item, index) => (
                  <Fragment key={item.id}>
                    <div style={{ display: "flex", gap: 8 }}>
                      <Controller
                        name={`details.${index}.topic`}
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            multiline
                            fullWidth
                            label={`${t("TopicName")} ${index + 1}`}
                            error={Boolean(fieldState.error)}
                            helperText={
                              fieldState.error && fieldState.error.message
                            }
                          />
                        )}
                      />
                      <IconButton color="error" onClick={() => remove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                    <Controller
                      name={`details.${index}.detail`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          multiline
                          fullWidth
                          label={`${t("Descriptions")}${t("TopicName")} ${
                            index + 1
                          }`}
                          error={Boolean(fieldState.error)}
                          helperText={
                            fieldState.error && fieldState.error.message
                          }
                        />
                      )}
                    />
                    <Divider
                      sx={{
                        width: "100%",
                        border: "1px solid #D0D0D0",
                        borderStyle: "dashed",
                      }}
                    />
                  </Fragment>
                ))}
              </Stack>
              <Button
                disabled={fields.length >= 15}
                startIcon={<AddIcon />}
                onClick={() => append({ topic: "", detail: "" })}
              >
                {`${t("Add")}`}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="file"
                control={control}
                render={({ field }) => (
                  <UploadFile
                    {...field}
                    onChange={field.onChange}
                    acceptFileType={"image/jpeg, image/png, application/pdf"}
                    acceptFileTypeLabel={t("JpgPngPdf")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} spacing={2}>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field }) => (
                    <DatePickerCustom
                      {...field}
                      views={['year', 'month', 'day']}
                      label={`${t("StartDate")}`}
                      minDate={dayjs()}
                      maxDate={dayjs().add(1, 'year').endOf('year')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errors.startDate}
                          helperText={
                            errors.startDate && errors.startDate.message
                          }
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field }) => (
                    <DatePickerCustom
                      {...field}
                      views={['year', 'month', 'day']}
                      label={`${t("EndDate")}`}
                      minDate={dayjs(watch("startDate")).add(1, "day")}
                      maxDate={dayjs().add(1, 'year').endOf('year')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errors.endDate}
                          helperText={
                            errors.endDate && errors.endDate.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <DialogActions>
            <Button type="button" color="error" onClick={handleClose}>
              {`${t("Cancel")}`}
            </Button>
            <ButtonBlue type="submit" variant="contained">
              {`${t("Confirm")}`}
            </ButtonBlue>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
