const province = [
  {
    idProvince: 1,
    name_th: "กรุงเทพมหานคร",
    name_en: "Bangkok",
  },
  {
    idProvince: 2,
    name_th: "สมุทรปราการ",
    name_en: "Samut Prakan",
  },
  {
    idProvince: 3,
    name_th: "นนทบุรี",
    name_en: "Nonthaburi",
  },
  {
    idProvince: 4,
    name_th: "ปทุมธานี",
    name_en: "Pathum Thani",
  },
  {
    idProvince: 5,
    name_th: "พระนครศรีอยุธยา",
    name_en: "Phra Nakhon Si Ayutthaya",
  },
  {
    idProvince: 6,
    name_th: "อ่างทอง",
    name_en: "Ang Thong",
  },
  {
    idProvince: 7,
    name_th: "ลพบุรี",
    name_en: "Loburi",
  },
  {
    idProvince: 8,
    name_th: "สิงห์บุรี",
    name_en: "Sing Buri",
  },
  {
    idProvince: 9,
    name_th: "ชัยนาท",
    name_en: "Chai Nat",
  },
  {
    idProvince: 10,
    name_th: "สระบุรี",
    name_en: "Saraburi",
  },
  {
    idProvince: 11,
    name_th: "ชลบุรี",
    name_en: "Chon Buri",
  },
  {
    idProvince: 12,
    name_th: "ระยอง",
    name_en: "Rayong",
  },
  {
    idProvince: 13,
    name_th: "จันทบุรี",
    name_en: "Chanthaburi",
  },
  {
    idProvince: 14,
    name_th: "ตราด",
    name_en: "Trat",
  },
  {
    idProvince: 15,
    name_th: "ฉะเชิงเทรา",
    name_en: "Chachoengsao",
  },
  {
    idProvince: 16,
    name_th: "ปราจีนบุรี",
    name_en: "Prachin Buri",
  },
  {
    idProvince: 17,
    name_th: "นครนายก",
    name_en: "Nakhon Nayok",
  },
  {
    idProvince: 18,
    name_th: "สระแก้ว",
    name_en: "Sa Kaeo",
  },
  {
    idProvince: 19,
    name_th: "นครราชสีมา",
    name_en: "Nakhon Ratchasima",
  },
  {
    idProvince: 20,
    name_th: "บุรีรัมย์",
    name_en: "Buri Ram",
  },
  {
    idProvince: 21,
    name_th: "สุรินทร์",
    name_en: "Surin",
  },
  {
    idProvince: 22,
    name_th: "ศรีสะเกษ",
    name_en: "Si Sa Ket",
  },
  {
    idProvince: 23,
    name_th: "อุบลราชธานี",
    name_en: "Ubon Ratchathani",
  },
  {
    idProvince: 24,
    name_th: "ยโสธร",
    name_en: "Yasothon",
  },
  {
    idProvince: 25,
    name_th: "ชัยภูมิ",
    name_en: "Chaiyaphum",
  },
  {
    idProvince: 26,
    name_th: "อำนาจเจริญ",
    name_en: "Amnat Charoen",
  },
  {
    idProvince: 27,
    name_th: "หนองบัวลำภู",
    name_en: "Nong Bua Lam Phu",
  },
  {
    idProvince: 28,
    name_th: "ขอนแก่น",
    name_en: "Khon Kaen",
  },
  {
    idProvince: 29,
    name_th: "อุดรธานี",
    name_en: "Udon Thani",
  },
  {
    idProvince: 30,
    name_th: "เลย",
    name_en: "Loei",
  },
  {
    idProvince: 31,
    name_th: "หนองคาย",
    name_en: "Nong Khai",
  },
  {
    idProvince: 32,
    name_th: "มหาสารคาม",
    name_en: "Maha Sarakham",
  },
  {
    idProvince: 33,
    name_th: "ร้อยเอ็ด",
    name_en: "Roi Et",
  },
  {
    idProvince: 34,
    name_th: "กาฬสินธุ์",
    name_en: "Kalasin",
  },
  {
    idProvince: 35,
    name_th: "สกลนคร",
    name_en: "Sakon Nakhon",
  },
  {
    idProvince: 36,
    name_th: "นครพนม",
    name_en: "Nakhon Phanom",
  },
  {
    idProvince: 37,
    name_th: "มุกดาหาร",
    name_en: "Mukdahan",
  },
  {
    idProvince: 38,
    name_th: "เชียงใหม่",
    name_en: "Chiang Mai",
  },
  {
    idProvince: 39,
    name_th: "ลำพูน",
    name_en: "Lamphun",
  },
  {
    idProvince: 40,
    name_th: "ลำปาง",
    name_en: "Lampang",
  },
  {
    idProvince: 41,
    name_th: "อุตรดิตถ์",
    name_en: "Uttaradit",
  },
  {
    idProvince: 42,
    name_th: "แพร่",
    name_en: "Phrae",
  },
  {
    idProvince: 43,
    name_th: "น่าน",
    name_en: "Nan",
  },
  {
    idProvince: 44,
    name_th: "พะเยา",
    name_en: "Phayao",
  },
  {
    idProvince: 45,
    name_th: "เชียงราย",
    name_en: "Chiang Rai",
  },
  {
    idProvince: 46,
    name_th: "แม่ฮ่องสอน",
    name_en: "Mae Hong Son",
  },
  {
    idProvince: 47,
    name_th: "นครสวรรค์",
    name_en: "Nakhon Sawan",
  },
  {
    idProvince: 48,
    name_th: "อุทัยธานี",
    name_en: "Uthai Thani",
  },
  {
    idProvince: 49,
    name_th: "กำแพงเพชร",
    name_en: "Kamphaeng Phet",
  },
  {
    idProvince: 50,
    name_th: "ตาก",
    name_en: "Tak",
  },
  {
    idProvince: 51,
    name_th: "สุโขทัย",
    name_en: "Sukhothai",
  },
  {
    idProvince: 52,
    name_th: "พิษณุโลก",
    name_en: "Phitsanulok",
  },
  {
    idProvince: 53,
    name_th: "พิจิตร",
    name_en: "Phichit",
  },
  {
    idProvince: 54,
    name_th: "เพชรบูรณ์",
    name_en: "Phetchabun",
  },
  {
    idProvince: 55,
    name_th: "ราชบุรี",
    name_en: "Ratchaburi",
  },
  {
    idProvince: 56,
    name_th: "กาญจนบุรี",
    name_en: "Kanchanaburi",
  },
  {
    idProvince: 57,
    name_th: "สุพรรณบุรี",
    name_en: "Suphan Buri",
  },
  {
    idProvince: 58,
    name_th: "นครปฐม",
    name_en: "Nakhon Pathom",
  },
  {
    idProvince: 59,
    name_th: "สมุทรสาคร",
    name_en: "Samut Sakhon",
  },
  {
    idProvince: 60,
    name_th: "สมุทรสงคราม",
    name_en: "Samut Songkhram",
  },
  {
    idProvince: 61,
    name_th: "เพชรบุรี",
    name_en: "Phetchaburi",
  },
  {
    idProvince: 62,
    name_th: "ประจวบคีรีขันธ์",
    name_en: "Prachuap Khiri Khan",
  },
  {
    idProvince: 63,
    name_th: "นครศรีธรรมราช",
    name_en: "Nakhon Si Thammarat",
  },
  {
    idProvince: 64,
    name_th: "กระบี่",
    name_en: "Krabi",
  },
  {
    idProvince: 65,
    name_th: "พังงา",
    name_en: "Phangnga",
  },
  {
    idProvince: 66,
    name_th: "ภูเก็ต",
    name_en: "Phuket",
  },
  {
    idProvince: 67,
    name_th: "สุราษฎร์ธานี",
    name_en: "Surat Thani",
  },
  {
    idProvince: 68,
    name_th: "ระนอง",
    name_en: "Ranong",
  },
  {
    idProvince: 69,
    name_th: "ชุมพร",
    name_en: "Chumphon",
  },
  {
    idProvince: 70,
    name_th: "สงขลา",
    name_en: "Songkhla",
  },
  {
    idProvince: 71,
    name_th: "สตูล",
    name_en: "Satun",
  },
  {
    idProvince: 72,
    name_th: "ตรัง",
    name_en: "Trang",
  },
  {
    idProvince: 73,
    name_th: "พัทลุง",
    name_en: "Phatthalung",
  },
  {
    idProvince: 74,
    name_th: "ปัตตานี",
    name_en: "Pattani",
  },
  {
    idProvince: 75,
    name_th: "ยะลา",
    name_en: "Yala",
  },
  {
    idProvince: 76,
    name_th: "นราธิวาส",
    name_en: "Narathiwat",
  },
  {
    idProvince: 77,
    name_th: "บึงกาฬ",
    name_en: "buogkan",
  },
];

export default province;
