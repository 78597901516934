import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTitle } from "react-use";
import { makeStyles } from "@mui/styles";
import DataTable from "./components/DataTable";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";

import { getAllWorkTimeRequest } from "../../../../../actions/workTimeRequest";
import { getAllEmployees } from "../../../../../actions/employee";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "30px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
  },
  headingPage: {
    marginBottom: 32,
  },
  paper: {
    padding: 8,
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
}));

function Index() {
  const classes = useStyles();
  useTitle("จัดการเวลาทำงาน - HR CORE DATA");
  const dispatch = useDispatch();
  const { result: requestStore } = useSelector(
    (state) => state.workTimeRequest
  );
  const { result: empStore } = useSelector((state) => state.employees);

  const [requests, setRequests] = useState([]);
  const [requestItems, setRequestItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  useEffect(() => {
    let requestFilter = requestItems.filter(function (item) {
      return item.type.includes(type) && item.status.includes(status);
    });

    setRequests(requestFilter);
  }, [type, status]);

  useEffect(() => {
    if (requestStore !== null && empStore !== null) {
      fetchData();
    }
    setIsLoading(false);
  }, [requestStore, empStore]);

  const fetchData = () => {
    let requestList = [...requestStore];
    let empList = [...empStore];

    console.log("requestList : ",requestList);

    requestList.forEach(function (element) {
      var empData = empList.filter(function (item) {
        return item.empId === element.empId;
      });

      // element.imagePath = empData[0].imagePath || "-";
      // element.fullName = (
        // (empData[0].firstName && empData[0].lastName) &&
        // empData[0].firstName + " " + empData[0].lastName) || "-";
      // element.jobPosition = empData[0].jobPosition;
    });

    setRequests(requestList);
    setRequestItems(requestList);
  };

  useEffect(() => {
    dispatch(getAllWorkTimeRequest());
    dispatch(getAllEmployees());
  }, []);

  return (
    <div className={classes.root}>
      <Box className={classes.headingPage}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">จัดการเวลาทำงาน</Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item xs>
                <FormControl fullWidth>
                  <Select displayEmpty value={type} onChange={handleTypeChange}>
                    <MenuItem value="">Type ทั้งหมด</MenuItem>
                    <MenuItem value="ขอเวลาทำงาน">ขอเวลาทำงาน</MenuItem>
                    <MenuItem value="ขอ OT">ขอ OT</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs>
                <FormControl fullWidth>
                  <Select
                    displayEmpty
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <MenuItem value="">Status ทั้งหมด</MenuItem>
                    <MenuItem value="Waiting Approve">Waiting Approve</MenuItem>
                    <MenuItem value="Approved">Approved</MenuItem>
                    <MenuItem value="Rejected">Rejected</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Paper className={classes.paper}>
        <DataTable requests={requests} isLoading={isLoading} />
      </Paper>
    </div>
  );
}

export default Index;
