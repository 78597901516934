import React from "react";
import { styled } from "@mui/styles";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import myKPI from "./assets/myKPI.png";
import updateKPI from "./assets/updateKPI.png";
import evaluationKPI from "./assets/approveKPI.png";
import progressKPI from "./assets/progressKPI.png";
import summaryKPI from "./assets/summaryKPI.png";
import statusKPI from "./assets/statusKPI.png";
import approveKPI from "./assets/approveKPI.png";
import planKPI from "./assets/planKPI.png";
import { useSelector } from "react-redux";
import { t } from "i18next";

const StyledRoot = styled(Box)({
  backgroundColor: "#f5f5f5 !important",
  paddingBottom: 50,
});

const StyledClickablePaper = styled(Paper)({
  width: "auto",
  height: "30vh",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1.1)",
  },
});

function index() {
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const adminMenu = [
    // [{ name: "KPI Organization Planning", path: "kpi-organization-plan" }],
    // [ 
    //   { name: "KPI Approval List", path: "kpi-approval-list" }, 
    //   { name: "KPI Approval", path: "kpi-approval" }, 
    //   { name: "KPI Individual Plan Update", path: "kpi-individual-update" },
    // ],
    [
      // { name: "KPI Individual Evaluation", path: "kpi-evaluation-list" }, 
      { name: "Summarize Scores and Grades", path: "kpi-calibrate" }, 
      { name: "KPI All Individual Plan Update", path: "kpi-successful" },
      { name: "KPI Status", path: "kpi-status" },
    ]
  ];

  const userMenu = [
    [{ name: "KPI Individual Planning", path: "kpi-individual" }],
    [
      { name: "KPI Individual Plan Update", path: "kpi-individual-update" },
      { name: "KPI Individual Evaluation", path: "kpi-evaluation-list" },
    ]
  ];

  const managerMenu = [
    [{ name: "KPI Approval List", path: "kpi-approval-list" }],
    [
      { name: "KPI Individual Evaluation", path: "kpi-evaluation-list" },
      { name: "Summarize Scores and Grades", path: "kpi-calibrate" },
      { name: "KPI All Individual Plan Update", path: "kpi-successful" },
    ],
  ];

  let menuItems = []
  if (userProfile) {
    if (userProfile.roles.includes("ROLE_ADMIN")) {
      menuItems = [
        { label: t("ManageKPI"), path: "kpi-people-assign", icon: approveKPI },
        { label: t("ProgressStatus"), path: "kpi-successful-All", icon: progressKPI },
        // { label: "สถานะรายการ", path: "kpi-status", icon: statusKPI },
        { label: t("SummarizeScoreSandGrades"), path: "kpi-calibrate", icon: summaryKPI }, 
        { label: t("EditPeriod"), path: "kpi-Edit-date", icon: myKPI },
      ]
    } else if (userProfile.roles.includes("ROLE_MANAGER")) {
      menuItems = [
        { label: t("ManageKPI"), path: "kpi-people-list", icon: approveKPI },
        { label: t("PendingKPIApproval"), path: "kpi-approval-list", icon: planKPI },
        { label: t("PendingPlanApproval"), path: "kpi-approval-planlist", icon: planKPI },
        { label: t("RequestList"), path: "kpi-successful", icon: statusKPI },
        { label: t("PendingApproval"), path: "kpi-evaluation-list", icon: approveKPI },
        // { label: "Summarize Scores and Grades", path: "kpi-calibrate" },
      ]
    } else {
      menuItems = [
        { label: t("MyKPI"), path: "kpi-individual", icon: myKPI },
        { label: t("KPIPlan"), path: "kpi-individual-planing", icon: updateKPI },
        { label: t("KPIUpdate"), path: "kpi-individual-update", icon: updateKPI },
        { label: t("KPIEvaluation"), path: "kpi-evaluation-list" , icon: evaluationKPI},
      ]
    }
  }

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          {menuItems.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <Link to={item.path} style={{ textDecoration: "none" }}>
                <StyledClickablePaper>
                  <img
                    src={item.icon}
                    alt="icon"
                    style={{
                      width: "50%",
                      height: "auto",
                      marginBottom: "10px",
                    }}
                  />
                  <Typography fontSize={"1.2rem"} align="center">
                    {item.label}
                  </Typography>
                </StyledClickablePaper>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </StyledRoot>
  );
}

export default index;
