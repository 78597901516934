import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import "dayjs/locale/th";
import utils from "../../../../../../utils";

import { Typography, Grid, Box, Divider, TableCell } from "@mui/material";

import FilePresentIcon from "@mui/icons-material/FilePresent";

import StyledCard from "../../../../shared/general/Card";
import Backdrop from "../../../../shared/general/Backdrop";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import AlertResponse from "../../../../shared/general/AlertResponse";

import DetailDiv from "../../../../user/expense/view/detail-div";

import {
  getExpenseAdvanceById,
  updateStatusExpense,
} from "../../../../../../actions/expense";
import Comments from "../../../../user/expense/comments";
import Actions from "../../../../user/expense/actions";
import ActionButtons from "../action-buttons";
import { getUserFullName } from "../../../../../../utils/userData";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledGrid = styled(Grid)({
  borderRight: "2px solid rgba(145, 158, 171, 0.32)",
});

const ViewExpenseAdvancePage = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: expenseDetail } = useSelector((state) => state.expenseDetail);
  const {
    idExpense,
    mode,
    handleSubmit,
    handleCloseAlert,
    openAlert,
    alertType,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [comment, setComment] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    dispatch(getExpenseAdvanceById(idExpense));
  }, []);

  return (
    <div>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      {expenseDetail ? (
        <Box>
          <div>
            <div className="wrap-head">
              <StyledHeadLabel variant="h6">
                {t("GeneralInformation")}
              </StyledHeadLabel>
            </div>
            <DetailDiv title="DocumentNumber" data={expenseDetail.documentId} />
            <DetailDiv title="TitleName" data={expenseDetail.nameExpense} />
            <DetailDiv title="Descriptions" data={expenseDetail.description} />
            <DetailDiv
              title="Approver"
              data={getUserFullName({
                firstname_TH: expenseDetail.approver_firstname_TH,
                lastname_TH: expenseDetail.approver_lastname_TH,
                firstname_EN: expenseDetail.approver_firstname_EN,
                lastname_EN: expenseDetail.approver_lastname_EN,
              })}
            />
            {expenseDetail.idEmpReviewer && (
              <DetailDiv title="Inspector" data={expenseDetail.reviewerName} />
            )}

            <StyledDivider />
            <Grid item xs={12}>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">
                  {t("receiveAdvance")}
                </StyledHeadLabel>
              </div>
            </Grid>
            <div>
              <Grid container spacing={2}>
                <StyledGrid item xs={12} sm={3}>
                  <DetailDiv title="Currency" data={expenseDetail.currency} />
                </StyledGrid>
                <StyledGrid item xs={12} sm={3}>
                  <DetailDiv title="Amount" data={expenseDetail.amount} />
                </StyledGrid>
                <StyledGrid item xs={12} sm={3}>
                  <DetailDiv
                    title="receiveType"
                    data={
                      i18n.resolvedLanguage === "th"
                        ? expenseDetail.paymentTypeName_TH
                        : expenseDetail.paymentTypeName_EN
                    }
                  />
                </StyledGrid>
                {expenseDetail.receiveDate && (
                  <StyledGrid item xs={12} sm={3}>
                    <DetailDiv
                      title="receiveDate"
                      data={expenseDetail.receiveDate}
                    />
                  </StyledGrid>
                )}
              </Grid>
            </div>
            <div>
              {expenseDetail.idPaymentType &&
                expenseDetail.idPaymentType === "1" && (
                  <Grid container spacing={2}>
                    <StyledGrid item xs={12} sm={6}>
                      <DetailDiv
                        title="BookBank"
                        data={expenseDetail.bankName}
                      />
                    </StyledGrid>
                    <StyledGrid item xs={12} sm={6}>
                      <DetailDiv
                        title="BookID"
                        data={expenseDetail.bankAccountNumber}
                      />
                    </StyledGrid>
                  </Grid>
                )}
            </div>
            <StyledDivider />
            <Grid item xs={12} style={{ marginTop: 16 }}>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">
                  {t("returnAdvance")}
                </StyledHeadLabel>
              </div>
            </Grid>
            <div>
              <Grid container spacing={2}>
                <StyledGrid item xs={12} sm={6}>
                  <DetailDiv
                    title="returnType"
                    data={
                      i18n.resolvedLanguage === "th"
                        ? expenseDetail.returnTypeName_TH
                        : expenseDetail.returnTypeName_EN
                    }
                  />
                </StyledGrid>
                {expenseDetail.returnDate && (
                  <StyledGrid item xs={12} sm={6}>
                    <DetailDiv
                      title="returnDate"
                      data={expenseDetail.returnDate}
                    />
                  </StyledGrid>
                )}
              </Grid>
            </div>
          </div>
          <StyledDivider />
          <div>
            <div
              style={{
                marginTop: 24,
              }}
            >
              <div className="wrap-row-sum">
                <Typography variant="h5">
                  {t("TotalValue")}&nbsp;&nbsp;
                </Typography>
                <Typography variant="h4">
                  {utils.numberWithCommas(parseFloat(expenseDetail.net))}
                </Typography>
                <Typography variant="h5">
                  &nbsp;&nbsp;{t(`CurrencyUnit.${expenseDetail.currency}`)}
                </Typography>
              </div>
            </div>
          </div>
          <StyledDivider />
          {expenseDetail.remark && (
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">
                  {t("Note")}({t("More")})
                </StyledHeadLabel>
              </div>
              <Typography
                component="p"
                variant="subtitle1"
                sx={{
                  paddingLeft: 4,
                  marginBottom: 4,
                }}
              >
                &nbsp;&nbsp;{expenseDetail.remark}
              </Typography>
              <StyledDivider />
            </div>
          )}
          <div>
            <div className="wrap-head">
              <StyledHeadLabel variant="h6">{t("AttachFile")}</StyledHeadLabel>
            </div>
            {expenseDetail.fileURL ? (
              <Box
                style={{
                  position: "relative",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    padding: 15,
                    backgroundColor: "#f9f9fb",
                    display: "flex",
                    borderRadius: 15,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    expenseDetail.fileURL &&
                      window.open(`${expenseDetail.fileURL.URL}`, "_blank");
                  }}
                >
                  <FilePresentIcon
                    height="24"
                    style={{
                      marginRight: 10,
                    }}
                  ></FilePresentIcon>
                  <Typography>{expenseDetail.fileURL.path}</Typography>
                </div>
              </Box>
            ) : (
              <Box>
                <div
                  style={{
                    padding: 24,
                    backgroundColor: "#f9f9fb",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: 15,
                  }}
                >
                  <Typography color="error">{t("NoAttachedFile")}</Typography>
                </div>
              </Box>
            )}
          </div>

          <div>
            {expenseDetail &&
            expenseDetail.comments &&
            expenseDetail.comments.length &&
            expenseDetail.comments.length > 0 ? (
              <Comments comments={expenseDetail.comments} />
            ) : (
              <div></div>
            )}
          </div>

          {mode === "edit" && (
            <div>
              <div>
                <StyledDivider />
                <div className="wrap-head">
                  <StyledHeadLabel variant="h6">{t("Comment")}</StyledHeadLabel>
                </div>

                <TextFieldTheme
                  id="comment"
                  value={comment}
                  inputProps={{ maxLength: 500 }}
                  rows={4}
                  multiline
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                ></TextFieldTheme>

                <Typography
                  component="p"
                  textAlign="right"
                  variant="caption"
                  color="text.secondary"
                >{`${comment ? comment.length : 0}/500`}</Typography>
              </div>
              <StyledDivider />
              <div>
                {expenseDetail &&
                expenseDetail.actions &&
                expenseDetail.actions.length &&
                expenseDetail.actions.length > 0 ? (
                  <Actions actions={expenseDetail.actions} />
                ) : (
                  <div></div>
                )}
              </div>
              <ActionButtons
                handleSubmit={handleSubmit}
                isFlexCoin={false}
                comment={comment}
              />
            </div>
          )}
        </Box>
      ) : (
        <Backdrop open={true} />
      )}
    </div>
  );
};

export default ViewExpenseAdvancePage;
