import { httpClient } from "./httpClient";

const getAllCareerPlan = (query) => {
  if (query) {
    return httpClient.get(`careerplan?idCompany=${query}`,);
  } else {
    return httpClient.get("careerplan",);
  }
};

const postCareerEmployee = (data) => {
  return httpClient.post(`careerplan/postcareeremployee`, data);
};

const postManagerSuggest = (data) => {
  return httpClient.post(`careerplan/postmanagersuggest`, data);
};

const putEmpIntCareerPlan = (id, data) => {
  return httpClient.post(`careerplan/putempintcareerplan/${id}`, data);
};

const putManagerSugCareerPlan = (id, data) => {
  return httpClient.post(`careerplan/putmanagersugcareerplan/${id}`, data);
};

const getEmployeeCareerPlanByID = (idEmployees) => {
  if (idEmployees) {
    return httpClient.get(`careerplanbyemp?idEmployees=${idEmployees}`);
  } else {
    return httpClient.get("careerplanbyemp");
  }
};

const updateCompanyAndEmployeeCareer = (query) => {
  return httpClient.put(`careerplan/updatemanager-companydata`, query);
}

export default {
  getAllCareerPlan,
  postCareerEmployee,
  postManagerSuggest,
  putEmpIntCareerPlan,
  putManagerSugCareerPlan,
  getEmployeeCareerPlanByID,
  updateCompanyAndEmployeeCareer
};
