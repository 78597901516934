import React from "react";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";

import { Container, CardContent } from "@mui/material";

import General from "./general";
import Welfare from "./welfare";
import PerDiem from "./per-diem";
import Mileage from "./mileage";
import FlexCoin from "./flex-coin";
import Advance from "./advance";

const StyledRoot = styled("div")({
  maxWidth: "1280px !important",
  margin: "auto",
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 24,
  },
  "& .head": {
    paddingTop: 8,
    marginBottom: 24,
    "& .MuiTypography-subtitle1": {
      fontWeight: 500,
    },
  },
});

const StyledCardContent = styled(CardContent)({
  padding: 24,
  "& .MuiFormControl-root": {
    marginBottom: 8,
  },
  "& .wrap-head": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
  },
  "& .wrap-row-sum": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

const ExpenseUserViewPage = () => {
  const { typeExpense, idExpense } = useParams();

  return (
    <StyledRoot className="page">
      <Container maxWidth="xl">
        <StyledCardContent>
          {typeExpense === "ServiceAndGoods" && (
            <General idExpense={idExpense} />
          )}
          {typeExpense === "Welfare" && <Welfare idExpense={idExpense} />}
          {typeExpense === "Allowance" && <PerDiem idExpense={idExpense} />}
          {typeExpense === "Mileage" && <Mileage idExpense={idExpense} />}
          {typeExpense === "FlexCoin" && <FlexCoin idExpense={idExpense} />}
          {typeExpense === "Advance" && <Advance idExpense={idExpense} />}
        </StyledCardContent>
      </Container>
    </StyledRoot>
  );
};

export default ExpenseUserViewPage;
