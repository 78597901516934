import { httpClient } from "./httpClient";
import dayjs from "dayjs";

const getUserProfile = () => {
  return httpClient.get("profile");
};

const getAllUsersCompany = (idCompany) => {
  return httpClient.get(`company/${idCompany}/users`);
};

const addUsers = (data) => {
  return httpClient.post("users", data);
};

const updateUser = (data) => {
  return httpClient.put(`employees`, data);
};

const updateManagerEmployee = (data) => {
  return httpClient.put(`employees/manager`, data);
};

const getDataWritePDF = (type, year, formData) => {
  return httpClient.get(`users/data-writePDF/${type}${year ? `/${year}` : ""}`, { params: formData });
};

const getUserInformation = () => {
  return httpClient.get(`userinfo`);
};

const updateUserPaymentCurrency = (data) => {
  return httpClient.put(`employees/paymentDetail`, data);
};

export default {
  getUserInformation,
  getUserProfile,
  getAllUsersCompany,
  addUsers,
  updateUser,
  getDataWritePDF,
  updateManagerEmployee,
  updateUserPaymentCurrency,
};
