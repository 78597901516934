import React, { Fragment, useState, useEffect } from "react";

import dayjs from "dayjs";
import {
  Typography,
  Grid,
} from "@mui/material";

import DatePickerCustom from "../../../../shared/date/datePicker";

const MonthPanel = ({ item, handleOnChange }) => {
  return (
    <div>
      <div className="wrap-item-data">
        <Grid container alignItems="center" spacing={4}>
          <Grid item xs={12} sm={2}>
            <div className="wrap-check">
              <Typography className="sub-header-1" variant="body1" gutterBottom>
                {dayjs().set("month", item.month).format("MMMM")}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Fragment>
              <div className="wrap-label-switch">
                <Typography className="item-name">
                  วันที่เงินเดือนออก
                </Typography>
              </div>
              <DatePickerCustom
                inputFormat="DD MMMM YYYY"
                name="payDate"
                variant="outlined"
                value={item.payDate}
                onChange={(newValue) => {
                  console.log("newValue: ", newValue)
                  handleOnChange({
                    index: item.month,
                    name: "payDate",
                    value: dayjs(newValue).format("YYYY-MM-DD"),
                  });
                }}
              />
            </Fragment>
          </Grid>
          <Grid item xs={12} sm={5}>
            <div className="wrap-label-switch">
              <Typography className="item-name">วันออกสลิปเงินเดือน</Typography>
            </div>
            <DatePickerCustom
              inputFormat="DD MMMM YYYY"
              name="payslipDate"
              variant="outlined"
              onChange={(newValue) => {
                handleOnChange({
                  index: item.month,
                  name: "payslipDate",
                  value: dayjs(newValue).format("YYYY-MM-DD"),
                });
              }}
              value={item.payslipDate}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default MonthPanel;
