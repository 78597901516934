import {
  EMPLOYEE_SHIFT_CHANGE_HISTORY_FETCHING,
  EMPLOYEE_SHIFT_CHANGE_HISTORY_SUCCESS,
  EMPLOYEE_SHIFT_CHANGE_HISTORY_FAILED,
} from "../actions/types";

const initialState = {
  result: {},
  isFetching: true,
  isError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case EMPLOYEE_SHIFT_CHANGE_HISTORY_FETCHING:
      return { ...state, result: {}, isFetching: true, isError: false };
    case EMPLOYEE_SHIFT_CHANGE_HISTORY_FAILED:
      return { ...state, result: {}, isFetching: false, isError: false };
    case EMPLOYEE_SHIFT_CHANGE_HISTORY_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    default:
      return state;
  }
}
