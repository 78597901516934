import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Container,
  Breadcrumbs,
  Paper,
  Grid,
  Button,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { addEmployeePersonalityTest } from "../../../../../actions/employee";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 50,
});

const StyledPaper = styled(Paper)({
  padding: 20,
  minHeight: "480px",
  backgroundSize: "cover",
  backgroundPosition: "top",
  borderRadius: "20px",
});

const StyledQuestion = styled("div")({
  "& .header-question": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    marginTop: "10px",
    textAlign: "center",

    "& .questionText": {
      color: "black",
      fontSize: "20px",
      fontWeight: 400,
      alignSelf: "center",
      marginTop: "10px",
    },
  },
  "& .content-question": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    marginTop: "10px",
    textAlign: "center",
    maxWidth: 1000,
    minWidth: 300,

    "& .answerText": {
      color: "black",
      fontSize: "18px",
      fontWeight: 300,
      textAlign: "center",
      alignItems: "center",
    },
  },
});

const StyleButton = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginTop: "10px",
  "& button": {
    marginRight: "10px",
    color: "white",
    backgroundColor: "#DB4178",
    "&:hover": {
      backgroundColor: "#FC669B",
    },
  },
});

const quizs = [
  {
    question: 1,
    choice: [
      {
        label: "ฉันรู้สึกว่ามันยากที่จะผ่อนคลาย ใช้ชีวิตสบายๆ หรืออยู่นิ่งๆ",
        value: "D",
      },
      { label: "ฉันมีเพื่อนเยอะ", value: "I" },
      { label: "ฉันพร้อมที่จะช่วยเหลือผู้อื่นเสมอ", value: "S" },
      { label: "ฉันชอบความสมบูรณ์แบบ", value: "C" },
    ],
  },
  {
    question: 2,
    choice: [
      { label: "ฉันเป็นคนเรียบร้อย มีระเบียบ", value: "C" },
      {
        label: "ฉันเป็นคนกระตือรือร้น ทั้ง work hard และ play  hard",
        value: "I",
      },
      { label: "ฉันเป็นคนใจเย็นมาก", value: "S" },
      { label: "ฉันมีทางของฉันเอง", value: "D" },
    ],
  },
  {
    question: 3,
    choice: [
      { label: "ฉันค่อนข้างพอใจกับวิถีชีวิตปกติของฉัน", value: "D" },
      { label: "ฉันมีความคิดสร้างสรรค์และไม่ชอบกฎเกณฑ์", value: "I" },
      { label: "ฉันชอบความเงียบและความสงบสุข", value: "C" },
      { label: "ฉันมองโลกในแง่ดี", value: "S" },
    ],
  },
  {
    question: 4,
    choice: [
      { label: "ฉันเป็นผู้ฟังที่ดี", value: "S" },
      { label: "ฉันชอบสิ่งที่แน่นอน คาดเดาได้", value: "C" },
      { label: "ฉันมั่นใจในตัวเองสูง", value: "D" },
      { label: "ฉันชอบเรื่องตลกขบขัน", value: "I" },
    ],
  },
  {
    question: 5,
    choice: [
      { label: "ฉันชอบการแข่งขัน", value: "D" },
      { label: "ฉันไม่ชอบการที่ต้องจริงจังกับชีวิตมากเกินไป", value: "I" },
      { label: "ฉันคิดถึงจิตใจผู้อื่นเสมอ", value: "S" },
      { label: "ฉันไม่ชอบเสี่ยง ไม่ชอบสิ่งที่ไม่แน่นอน", value: "C" },
    ],
  },
  {
    question: 6,
    choice: [
      { label: "ฉันโน้มน้าวใจคนอื่นเก่ง", value: "I" },
      { label: "ฉันเป็นคนอ่อนโยน ขี้เกรงใจ", value: "S" },
      { label: "ฉันทำอะไรด้วยความระมัดระวังเสมอ", value: "C" },
      { label: "ฉันเป็นคนกล้าหาญและเด็ดเดี่ยว", value: "D" },
    ],
  },
  {
    question: 7,
    choice: [
      { label: "ฉันให้อภัยผู้อื่นเสมอ", value: "S" },
      { label: "ลึกๆ ฉันอ่อนไหวง่าย", value: "C" },
      { label: "ฉันมีพลังเสมอ", value: "D" },
      { label: "ฉันเข้ากับทุกคนได้", value: "I" },
    ],
  },
  {
    question: 8,
    choice: [
      { label: "ฉันปฏิบัติตามกฎระเบียบเสมอ", value: "C" },
      { label: "ฉันควบคุมอารมณ์ได้ดี", value: "S" },
      { label: "บางครั้งฉันอาจจะก้าวร้าว", value: "D" },
      { label: "ฉันชอบคุยกับคนอื่น", value: "I" },
    ],
  },
  {
    question: 9,
    choice: [
      { label: "ฉันชอบที่จะเป็นที่ 1", value: "D" },
      { label: "ฉันเป็นคนสุภาพ อัธยาศัยดี", value: "I" },
      {
        label: "ฉันเคารพความคิดเห็นของผู้อื่น และมักเห็นด้วยกับเขาเสมอ",
        value: "S",
      },
      { label: "ฉันไม่ชอบเข้าสังคม", value: "C" },
    ],
  },
  {
    question: 10,
    choice: [
      { label: "ฉันคิดว่าฉันเป็นคนมีเสน่ห์", value: "I" },
      { label: "ฉันวิเคราะห์สถานการณ์ได้ดี", value: "C" },
      { label: "ฉันเป็นคนใจร้อน ตัดสินใจเร็ว", value: "D" },
      {
        label: "เวลาจะทำอะไร ฉันจะคิดก่อนว่ามันกระทบผู้อื่นหรือไม่",
        value: "S",
      },
    ],
  },
];

const DISC1 = () => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [answer, setAnswer] = useState([]);

  const handlePreviousPage = () => {
    if (page - 1 < 0) return;
    setPage(page - 1);
  };

  const handleNextPage = async () => {
    if (page + 1 < quizs.length) {
      setPage(page + 1);
    } else {
      const result = calculateType(answer);
      history.push(
        `/personality-character-test/DISC1/result-test-DISC1?result=${result}`
      );

      await addEmployeePersonalityTest({
        idPersonalityTest: 1,
        result: result
      });
    }
  };

  const calculateType = (answers) => {
    const groupAnswer = {};
    answers.forEach((answer) => {
      if (!groupAnswer[answer]) {
        groupAnswer[answer] = 0;
      }
      groupAnswer[answer] += 1;
    });

    //find max
    let max = -Infinity;
    Object.keys(groupAnswer).forEach((answerKey) => {
      if (groupAnswer[answerKey] > max) max = groupAnswer[answerKey];
    });

    // Type [D, I, S, C]
    const maximumAnswer = [];
    Object.keys(groupAnswer).forEach((answerKey) => {
      if (groupAnswer[answerKey] === max) maximumAnswer.push(answerKey);
    });

    return maximumAnswer[getRandomIndex(maximumAnswer)];
  };

  function getRandomIndex(array) {
    return Math.floor(Math.random() * array.length);
  }

  const handleSetAnswer = (selectedAnswer) => {
    const updateAnswer = [...answer];
    updateAnswer[page] = selectedAnswer;
    setAnswer(updateAnswer);
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to={"/personality-character-test"}
          >
            All
          </Link>
          <Typography color="text.primary">DISC</Typography>
        </Breadcrumbs>
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            DISC
          </Typography>
        </div>

        <StyledPaper
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/DISC1/BG-Test1-DISC.jpg)`,
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <div style={{ position: "relative", marginTop: "220px" }}>
              <Grid item>
                <Box sx={{
                  position: 'absolute',
                  top: -30,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  zIndex: 1
                }}>
                  <Box sx={{ bgcolor: "#DB4178", width: 200, p: 3, borderRadius: 3 }}>
                    <Typography
                      fontSize={17}
                      fontWeight={600}
                      color={"#FFFFFF"}
                      textAlign={"center"}
                    >
                      Question {page + 1}/{quizs.length}
                    </Typography>
                  </Box>
                </Box>
                <Paper
                  style={{
                    minHeight: "auto",
                    maxWidth: "auto",
                    padding: 20,
                    marginLeft: "5vw",
                    marginRight: "5vw",
                  }}
                >
                  <StyledQuestion>
                    <div className="header-question">
                      <Typography className="questionText">
                        โปรดเลือกข้อความที่ใกล้เคียงกับลักษณะนิสัยของคุณมากที่สุด
                      </Typography>
                    </div>
                  </StyledQuestion>
                </Paper>
              </Grid>

              <StyledQuestion>
                <Grid
                  item
                  style={{ position: "relative", marginTop: "10px" }}
                  className="content-question"
                >
                  <Grid container spacing={2}>
                    {quizs[page].choice.map((choice) => (
                      <Grid item xs={12} sm={6} key={choice.value}>
                        <Paper
                          onClick={() => handleSetAnswer(choice.value)}
                          style={{
                            minHeight: "80px",
                            padding: "10px",
                            border: `2px solid ${answer[page] === choice.value
                              ? "#DB4178"
                              : "transparent"
                              }`,
                            cursor: "pointer",
                          }}
                        >
                          <Typography className="answerText" textAlign={"center"}>
                            {choice.label}
                          </Typography>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </StyledQuestion>

              <Grid item>
                <StyleButton>
                  <Button
                    disabled={page === 0}
                    onClick={handlePreviousPage}
                    variant="contained"
                  >
                    Previous
                  </Button>
                  {page < quizs.length - 1 ? (
                    <Button
                      disabled={answer[page] === undefined}
                      onClick={handleNextPage}
                      variant="contained"
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      disabled={answer[page] === undefined}
                      onClick={handleNextPage}
                      variant="contained"
                    >
                      Finish
                    </Button>
                  )}
                </StyleButton>
              </Grid>
            </div>
          </Grid>
        </StyledPaper>
      </Container >
    </StyledRoot >
  );
};

export default DISC1;
