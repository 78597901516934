import React from "react";
import {
  Typography,
  Grid,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  CircularProgress,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Chip,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import DomainIcon from "@mui/icons-material/Domain";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EventIcon from "@mui/icons-material/Event";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import dayjs from "dayjs";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";
import {
  answerQuestion5Scale,
  answerQuestion6Scale,
} from "../../../../assets/data/answerScale";

const percentageScore = (questionList = []) => {
  let totalRating = 0;
  for (const item of questionList) {
    if (item.questionType === "choice") {
      totalRating += (item.answer * item.weight) / 100;
    }
  }

  return Math.round(totalRating);
};

const DialogDetail = ({ open, handleClose, probation }) => {
  const { t, i18n } = useTranslation();
  const result = probation.adminResult ? probation.adminResult : probation.managerResult
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>{t("Details")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} marginBottom={6}>
          <Grid
            item
            xs={12}
            sm={6}
            display={"flex"}
            alignItems={"center"}
            gap={1}
          >
            <PersonIcon />
            <Typography>
              {` ${t("PersonBeingAssessed")} : ${probation.firstname_TH} ${
                probation.lastname_TH
              }`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            display={"flex"}
            alignItems={"center"}
            gap={1}
          >
            <AssignmentIcon />
            <Typography>
              {`${t("QuestionSet")} : ${probation.questionSet.questionSetName}`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            display={"flex"}
            alignItems={"center"}
            gap={1}
          >
            <AssessmentIcon />
            <Typography>
              {`${t("ProbationRound")} : ${probation.probationRound}`}
            </Typography>
          </Grid>
          {probation.expandTime && (
            <Grid
              item
              xs={12}
              sm={6}
              display={"flex"}
              alignItems={"center"}
              gap={1}
            >
              <AddCircleIcon />
              <Typography>
                {`${t("ExtendProbationPeriod")}: ${probation.expandTime} ${t(
                  "Unit.Days"
                )}`}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={6}
            display={"flex"}
            alignItems={"center"}
            gap={1}
          >
            <EventIcon />
            <Typography>
              {`${t("ProbationaryExpirationDate")} :  ${dayjs(
                probation.probationDate
              ).format(
                i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
              )}`}
            </Typography>
          </Grid>
          {probation.expandTime && (
            <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
              <AddCircleIcon />
              <Typography>
                {`${t("ExtendProbationPeriod")}: ${probation.expandTime} ${t(
                  "Unit.Days"
                )}`}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={6}
            display={"flex"}
            alignItems={"center"}
            gap={1}
          >
            <CheckCircleIcon />
            <Typography>
              {`${t("EvaluatedBy")} : ${
                probation.adminEvaluateDate
                  ? t("Administrator")
                  : i18n.resolvedLanguage === "th"
                  ? probation.approvedBy
                  : probation.approvedBy_EN
              }`}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h6" gutterBottom>
          {"ส่วนพนักงาน"}
        </Typography>
        <Stack spacing={1} marginBottom={4}>
          {probation.questionSet.employeeQuestion.length > 0 ? (
            probation.questionSet.employeeQuestion.map((question, index) => (
              <div key={question.idProbationQuestion}>
                <Typography gutterBottom fontWeight={500}>
                  {`${t("Question")} ${index + 1} : ${question.question}`}
                </Typography>
                {question.questionType === "choice" ? (
                  <>
                    <Typography color="text.secondary">{`${t("Weight")} ${question.weight}%`}</Typography>
                    <RadioGroup row value={question.answer}>
                      {probation.questionSet.answerScale === 6
                        ? answerQuestion6Scale.map((item) => (
                            <FormControlLabel
                              key={item.key}
                              value={item.value}
                              control={<Radio />}
                              label={t(item.label)}
                            />
                          ))
                        : answerQuestion5Scale.map((item) => (
                            <FormControlLabel
                              key={item.key}
                              value={item.value}
                              control={<Radio />}
                              label={t(item.label)}
                            />
                          ))}
                    </RadioGroup>
                  </>
                ) : (
                  <TextFieldTheme
                    fullWidth
                    value={question.answer}
                    InputProps={{ readOnly: true }}
                  />
                )}
                <Divider sx={{ borderColor: "#e2e2e2", marginTop: "8px" }} />
              </div>
            ))
          ) : (
            <Typography textAlign="center" color="text.secondary">
              {t("NoQuestions")}
            </Typography>
          )}
        </Stack>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "16px 0px",
          }}
        >
          <Box style={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              variant="determinate"
              style={{
                color: "#eeeeee",
              }}
              size={70}
              thickness={4}
              value={100}
            />
            <CircularProgress
              variant="determinate"
              value={percentageScore(probation.questionSet.employeeQuestion)}
              style={{
                color: "#007afe",
                animationDuration: "550ms",
                position: "absolute",
                left: 0,
              }}
              size={70}
            ></CircularProgress>
            <Box
              style={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                style={{ fontSize: 16 }}
                component="div"
                color="text.third"
              >
                {percentageScore(probation.questionSet.employeeQuestion)}
              </Typography>
            </Box>
          </Box>
        </div>

        <Typography variant="h6" gutterBottom>
          {t("Manager")}
        </Typography>
        <Stack spacing={1} marginBottom={4}>
          {probation.questionSet.managerQuestion.length > 0 ? (
            probation.questionSet.managerQuestion.map((question, index) => (
              <div key={question.idProbationQuestion}>
                <Typography gutterBottom fontWeight={500}>
                  {`${t("Question")} ${index + 1} : ${question.question}`}
                </Typography>
                {question.questionType === "choice" ? (
                  <>
                    <Typography color="text.secondary">
                      {`${t("Weight")} ${question.weight}%`}
                    </Typography>
                    <RadioGroup value={question.answer} row>
                      {probation.questionSet.answerScale === 6
                        ? answerQuestion6Scale.map((item) => (
                            <FormControlLabel
                              key={item.key}
                              value={item.value}
                              control={<Radio />}
                              label={t(item.label)}
                            />
                          ))
                        : answerQuestion5Scale.map((item) => (
                            <FormControlLabel
                              key={item.key}
                              value={item.value}
                              control={<Radio />}
                              label={t(item.label)}
                            />
                          ))}
                    </RadioGroup>
                  </>
                ) : (
                  <TextFieldTheme
                    multiline
                    value={question.answer}
                    InputProps={{ readOnly: true }}
                  />
                )}
                <Divider sx={{ borderColor: "#e2e2e2", marginTop: "8px" }} />
              </div>
            ))
          ) : (
            <Typography textAlign="center" color="text.secondary">
              {t("NoQuestions")}
            </Typography>
          )}
        </Stack>
        <Stack spacing={2} marginBottom={4}>
          <div>
            <Typography gutterBottom>{t("Strength")}</Typography>
            <TextFieldTheme
              multiline
              value={probation.goodPoint}
              InputProps={{ readOnly: true }}
            />
          </div>
          <div>
            <Typography gutterBottom>{t("Weakness")}</Typography>
            <TextFieldTheme
              multiline
              value={probation.weakPoint}
              InputProps={{ readOnly: true }}
            />
          </div>
          <div>
            <Typography gutterBottom>{t("Other")}</Typography>
            <TextFieldTheme
              multiline
              value={probation.others}
              InputProps={{ readOnly: true }}
            />
          </div>
          <div>
            <Typography gutterBottom>
              {t("FeedbackFromSupervisor")}
            </Typography>
            <TextFieldTheme
              multiline
              value={probation.managerComment}
              InputProps={{ readOnly: true }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
            }}
          >
            <Box style={{ position: "relative", display: "inline-flex" }}>
              <CircularProgress
                variant="determinate"
                style={{
                  color: "#eeeeee",
                }}
                size={70}
                thickness={4}
                value={100}
              />
              <CircularProgress
                variant="determinate"
                value={percentageScore(probation.questionSet.managerQuestion)}
                style={{
                  color: "#007afe",
                  animationDuration: "550ms",
                  position: "absolute",
                  left: 0,
                }}
                size={70}
              ></CircularProgress>
              <Box
                style={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h6"
                  style={{ fontSize: 16 }}
                  component="div"
                  color="text.third"
                >
                  {percentageScore(probation.questionSet.managerQuestion)}
                </Typography>
              </Box>
            </Box>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Typography fontWeight={500} color="text.secondary">
              {t("ProbationResult")}
            </Typography>
            {dayjs(probation.resignDate).isBefore(probation.probationDate) ? (
              <Chip label={t("Terminated")} color="error" />
            ) : result === 1 ? (
              <Chip label={t("Pass")} color="success" />
            ) : result === 0 ? (
              <Chip label={t("NotPass")} color="error" />
            ) : (
              <Chip label={t("WaitingForEvaluation")} color="warning" />
            )}
          </div>
          <Typography fontWeight={500} color="text.secondary">
            {`${t("EvaluatedBy")} : ${
              probation.adminResult
                ? t("Administrator")
                : i18n.resolvedLanguage === "th"
                ? probation.approvedBy
                : probation.approvedBy_EN
            }`}
          </Typography>
        </Stack>
        {probation.reasonTermination && (
          <Box marginBottom={4}>
            <Typography marginBottom={1}>
              {t("ReasonForNotPassingProbation")}
            </Typography>
            <TextFieldTheme
              multiline
              value={probation.reasonTermination}
              InputProps={{ readOnly: true }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <ButtonBlue variant="contained" onClick={handleClose}>
          {t("Close")}
        </ButtonBlue>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDetail;
