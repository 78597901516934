import React, { useEffect, useState } from "react";
import { styled } from "@mui/styles";
import {  Container } from "@mui/material";
import dayjs from "dayjs";

import OrgOverviewDashboard from "./OrgOverviewDashboard";
import OrgTeamDashboardList from "./OrgTeamDashboardList";
import OrgTeamLeaderboardList from "./OrgTeamLeaderboardList";
import OrgTeamGraphDashboard from "./OrgTeamGraphDashboard";

import { useSelector, useDispatch } from "react-redux";
import { getAllTeamPlan } from "../../../../../actions/TeamPlans";

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Toolbar,
  Typography,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import  {LocalizationProvider}  from "@mui/x-date-pickers/LocalizationProvider";
import  {DatePicker}  from "@mui/x-date-pickers/DatePicker";
import { Doughnut } from "react-chartjs-2";
import GaugeChart from "react-gauge-chart";

import FindInPageIcon from "@mui/icons-material/FindInPage";

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      height: "50px",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingTop: 16,
      paddingBottom: 16,
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiGrid-justify-xs-flex-end": {
      
    },
    "& .MuiFormControl-marginNormal": {
      marginTop: 8,
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& .MuiTypography-h2": {
      fontWeight: "500",
      fontSize: "4rem",
    },
    "& .MuiTypography-h6": {
      color: "#757575",
    },
    "& .MuiFormControl-marginNormal": {
      marginBottom: 0,
      width: 144,
    },
    "& .MuiInputBase-input.Mui-disabled": {
      color: "#222f3e",
    },
  },
  formControl: {
    margin: 8,
    minWidth: 120,
  },
  searchBtn: {
    marginLeft: 16,
    borderRadius: 8,
    height: 48,
    marginBottom: 8,
    padding: 8,
  },
  scoreCard: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "10px",
    minHeight: "620px",
    padding: 32,
    marginBottom: "20px",
  },
  planListCard: {
    borderRadius: "10px",
    width: "100%",
    minHeight: "300px",
  },
  doughnutChart: {
    display: "initial !important",
    height: "450px !important",
    width: "450px !important",
   
  },
  noData_Section: {
    textAlign: "center",
    marginTop: 40,
    color: "#222f3e",
  },
  noDataSvg: {
    fontSize: "3.5rem",
  },
  noDataText: {
    fontWeight: "600",
    fontSize: "18px",
  },
}));

const quater = [
  { id: "Q1", title: "Q1" },
  { id: "Q2", title: "Q2" },
  { id: "Q3", title: "Q3" },
  { id: "Q4", title: "Q4" },
];

const chartData = {
  labels: ["Achieved", "Not Achieved"],
  datasets: [
    {
      data: [0, 0],
      backgroundColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
      offset: 1,
    },
  ],
};

const LineData = {
  labels: ["Quater 1", "Quater 2", "Quater 3", "Quater 4"],
  datasets: [
    {
      label: "OKRs",
      data: [12, 19, 3, 5],
      fill: false,
      backgroundColor: "rgb(255, 99, 132)",
      borderColor: "rgb(255, 99, 132)",
    },
    {
      label: "Moon Shot",
      data: [1, 2, 1, 1],
      fill: false,
      backgroundColor: "#ff9933",
      borderColor: "#ff9933",
    },
    {
      label: "Amount OKR",
      data: [3, 2, 4, 5],
      fill: false,
      backgroundColor: "rgb(75, 192, 192)",
      borderColor: "rgb(75, 192, 192)",
    },
  ],
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

export const teamRecords = [
  { id: 1, teamRecordName: "HR TPE" },
  { id: 2, teamRecordName: "HR Poly" },
];

function OrganizationDashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: teamPlanStore } = useSelector((state) => state.TeamPlans);

  const [dateSelect, setDateSelect] = useState(new Date());
  const [quaterSelect, setQuaterSelect] = useState("Q1");
  const [yearSelect, setYearSelect] = useState(0);
  const [teams, setTeams] = useState([]);
  const [avgScore, setAvgScore] = useState(0);
  const [doughnutChart, setDoughnutChart] = useState(chartData);
  const [isLoading, setIsLoading] = useState(true);


  const handleOnClick = () => {
    fetchTeamOverview();
  };

  const fetchTeamOverview = () => {
    dispatch(getAllTeamPlan());
  };

  useEffect(() => {
    if (teamPlanStore) {
      const teamPlans = [...teamPlanStore];

      let planItem = teamPlans.filter(function (item) {
        let dateItem = new Date(item.dateCreate);
        let yearItem = dateItem.getFullYear();

        return (
          item.planStatus === "Approved" &&
          item.quater === quaterSelect &&
          yearItem === yearSelect
        );
      });

      let totalAchieved = 0;
      let totalNotAchieved = 0;

      planItem.forEach((item) => {
        if (item.achieveResult === "Achieved") {
          totalAchieved++;
        }
        if (item.achieveResult === "Not Achieved") {
          totalNotAchieved++;
        }
      });

      const totalOKRs = planItem.filter((x) => x.planStatus === "Approved");

      //Count Key Result
      let totalKR = 0;

      totalOKRs.forEach((x) => {
        if (x.keyResult1) {
          totalKR += 1;
        }

        if (x.keyResult2) {
          totalKR += 1;
        }

        if (x.keyResult3) {
          totalKR += 1;
        }
      });

      let chart = doughnutChart;
      //Update Array Doughnut Chart [Achieved]
      chart.datasets[0].data[0] = totalAchieved;
      //Update Array Doughnut Chart [Not Achieved]
      chart.datasets[0].data[1] = totalNotAchieved;

      setDoughnutChart({ ...chart });

      teamRecords.forEach(function (element) {
        //Add Property objectiveAmount
        element.objectiveAmount = teamPlans.filter(function (x) {
          return (
            x.teamName.includes(element.teamRecordName) &&
            x.quater.includes(quaterSelect) &&
            dayjs(x.dateCreate).format("YYYY").includes(yearSelect)
          );
        }).length;

        //Add Property Key Result
        const teamFilter = teamPlans.filter(function (x) {
          return (
            x.teamName.includes(element.teamRecordName) &&
            x.quater.includes(quaterSelect) &&
            dayjs(x.dateCreate).format("YYYY").includes(yearSelect)
          );
        });

        let krCount = 0;
        teamFilter.forEach(function (item) {
          if (item.keyResult1) {
            krCount += 1;
          }

          if (item.keyResult2) {
            krCount += 1;
          }

          if (item.keyResult3) {
            krCount += 1;
          }
        });

        element.keyResultAmount = krCount;

        //Add Property achieved
        let achieved = teamPlans.filter(function (x) {
          return (
            x.teamName.includes(element.teamRecordName) &&
            x.quater.includes(quaterSelect) &&
            dayjs(x.dateCreate).format("YYYY").includes(yearSelect) &&
            x.achievedBy !== null
          );
        }).length;

        element.achieved = achieved;
      });

      let records = teamRecords.sort(function (a, b) {
        return a.teamRecordName.localeCompare(b.teamRecordName);
      });

      setTeams(records);

      let achievedTotal = 0;
      let objTotal = 0;

      records.forEach((x) => {
        achievedTotal += x.achieved;
        objTotal += x.objectiveAmount;
      });

      let avg = achievedTotal / objTotal;

      if (isNaN(avg)) {
        avg = 0;
      }

      setAvgScore(avg);
      setIsLoading(false);
    }
  }, [teamPlanStore]);

  useEffect(() => {
    const year = dateSelect.getFullYear();
    const month = dateSelect.getMonth() + 1;

    let quater = "";
    if (month <= 3) {
      quater = "Q1";
    } else if (month <= 6) {
      quater = "Q2";
    } else if (month <= 9) {
      quater = "Q3";
    } else if (month <= 12) {
      quater = "Q4";
    }

    setYearSelect(year);
    setQuaterSelect(quater);

    fetchTeamOverview();
  }, []);

  return (
    <ContainerStyled>
      <div className="card-section">
        <div className="header-section">
          <Grid container>
            <Grid item>
              <h3 className="header-topic">Organization Dashboard</h3>
            </Grid>
          </Grid>
        </div>
        <Toolbar>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              <FormControl className={classes.formControl}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    margin="normal"
                    variant="inline"
                    inputVariant="outlined"
                    label="Year"
                    value={dateSelect}
                    views={["year"]}
                    onChange={(date) => {
                      setDateSelect(date);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel>Quater</InputLabel>
                <Select
                  label="Quater"
                  value={quaterSelect}
                  onChange={(e) => setQuaterSelect(e.target.value)}
                >
                  {quater.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      style={{ display: "grid" }}
                    >
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.searchBtn}
                onClick={handleOnClick}
              >
                <SearchIcon /> View
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </div>
      {isLoading ? (
        <>
          <LinearProgress />
        </>
      ) : (
        <>
          <OrgOverviewDashboard teams={teams} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Paper className={classes.scoreCard}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          style={{ color: "#222f3e" }}
                          gutterBottom
                        >
                          Average Score
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <GaugeChart
                          id="gauge-chart1"
                          nrOfLevels={420}
                          arcsLength={[0.2, 0.5, 0.3]}
                          colors={["#47c2c2", "#ffcd56", "#ff5722"]}
                          percent={avgScore} //Score Percent
                          arcPadding={0.02}
                          textColor="#222f3e"
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Paper className={classes.scoreCard}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          style={{ color: "#222f3e" }}
                          gutterBottom
                        >
                          Achieve
                        </Typography>
                      </Grid>
                      <Grid container justifyContent="center" item xs={12}>
                        {doughnutChart.datasets[0].data[0] === 0 &&
                        doughnutChart.datasets[0].data[1] === 0 ? (
                          <div className={classes.noData_Section}>
                            <FindInPageIcon className={classes.noDataSvg} />
                            <p className={classes.noDataText}>No Data</p>
                          </div>
                        ) : (
                          <Doughnut
                            className={classes.doughnutChart}
                            data={doughnutChart}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Paper className={classes.planListCard}>
                <OrgTeamDashboardList records={teams} />
              </Paper>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Paper className={classes.planListCard}>
                <OrgTeamLeaderboardList records={teams} />
              </Paper>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Paper className={classes.scoreCard} sx={{ marginTop: "20px" }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      style={{ color: "#222f3e" }}
                      gutterBottom
                    >
                      Graph
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <OrgTeamGraphDashboard records={teams} />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </ContainerStyled>
  );
}

export default OrganizationDashboard;
