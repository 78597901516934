import React, { useEffect, useState, Fragment } from "react";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

dayjs.extend(isBetweenPlugin);

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: "#1976d2",
    color: "#FFFFFF",
    "&:hover, &:focus": {
      backgroundColor: "#1976d2",
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

export default function DatePickerWeek(props) {
  const { value, setValue, setSearchDate } = props;

  useEffect(() => {
    const start = dayjs(value).startOf("week");
    const end = dayjs(value).endOf("week");
    setSearchDate({
      start: dayjs(start).format("YYYY-MM-DD"),
      end: dayjs(end).format("YYYY-MM-DD"),
    });
  }, [value]);

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = dayjs(value).startOf("week");
    const end = dayjs(value).endOf("week");

    const dayIsBetween = date.isBetween(start, end, null, "[]");
    const isFirstDay = date.isSame(start, "day");
    const isLastDay = date.isSame(end, "day");

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  const inputFormat = () => {
    const start = dayjs(value).startOf("week");
    const end = dayjs(value).endOf("week");

    return `${dayjs(start)
      .locale(localStorage.getItem("language") || "th")
      .format(
        localStorage.getItem("language") === "en" ? "D MMM YYYY" : "D MMM BBBB"
      )} - ${dayjs(end)
      .locale(localStorage.getItem("language") || "th")
      .format(
        localStorage.getItem("language") === "en" ? "D MMM YYYY" : "D MMM BBBB"
      )}`;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        label="วันที่"
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        showDaysOutsideCurrentMonth={true}
        renderDay={renderWeekPickerDay}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <StyledTextField
            variant="filled"
            style={{ width: "100%" }}
            id="outlined-read-only-input"
            label=""
            ref={inputRef}
            {...inputProps}
            value={inputFormat()}
            InputProps={InputProps}
            readOnly
          />
        )}
      />
    </LocalizationProvider>
  );
}
