import React, { useEffect, useState, Fragment } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";

import { styled } from "@mui/material/styles";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import IconButton from "@mui/material/IconButton";
import {
  Grid,
  Typography,
  FormControl,
  MenuItem,
  Select,
  Divider,
  TextField,
  Autocomplete,
  Popper,
  autocompleteClasses,
  Button,
} from "@mui/material";

import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddIcon from "@mui/icons-material/Add";

import DrawerCustom from "../../../../shared/general/Drawer";

import Utils from "../../../../../../utils";

import { getEmployeeAdditionDeduction } from "../../../../../../actions/employee";
import { updatePayrunDetail } from "../../../../../../actions/payruns";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { getAllAdditionsByCompany } from "../../../../../../actions/addition";
import { getAllDeductionsByCompany } from "../../../../../../actions/deduction";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledContent = styled("div")({
  width: 550,
  padding: 24,
  "& .textField": {
    marginBottom: 16,
    width: "100%",
  },
  "& .radio": {
    marginBottom: 8,
    width: "100%",
  },
  "& .MuiFormControl-root": {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  "& .btn-add": {
    display: "flex",
    justifyContent: "center",
  },
});

const StyledList = styled("div")({
  padding: 16,
  "& .line": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 8,
    "& .MuiIconButton-root": {
      color: "#919eab",
      padding: 0,
      marginRight: 8,
    },
  },
  "& .wrap-header": {
    marginBottom: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .header": {
    fontWeight: 500,
    fontSize: 14,
  },
  "& .nothing": {
    fontStyle: "oblique",
  },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginTop: 24,
  "& .MuiToggleButtonGroup-grouped": {
    color: "#919eab",
    backgroundColor: "#FFFFFF",
    fontSize: 16,
    "&:hover": {
      borderColor: "#0046b7",
      backgroundColor: "#0046b7",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#007aff",
      border: "1px solid #007aff",
      "&:hover": {
        borderColor: "#0046b7",
        backgroundColor: "#0046b7",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #919eab",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
  },
}));

const StyledContentLabel = styled(Typography)({
  fontWeight: 500,
  fontSize: 16,

  "&.head-text-manager": {
    fontWeight: 400,
    "&.error": {
      color: "#f15e5e",
    },
  },
});

const StyledContentHeaderLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  borderLeft: "4px solid #46cbe2",
  paddingLeft: "4px",
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});
const StyledMenuItem = styled(MenuItem)({
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapActionButton = styled("div")({
  marginTop: 8,
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#46cbe2",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 18,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const StyledAddButton = styled(Button)({
  borderColor: "#229A16",
  backgroundColor: "transparent",
  color: "#229A16",
  borderRadius: 8,
  "&:hover": {
    borderColor: "#229A16",
  },
});

const StyledTextField = styled(TextField)({
  "& .MuiInputLabel-root": {
    color: "#637381",

    "&.Mui-focused": {
      color: "#46cbe2",
    },
    "&.Mui-error": {
      color: "rgb(211, 47, 47)",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    padding: "6px 39px 6px 9px",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#46cbe2",
    },
    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(211, 47, 47)",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const validationSchema = yup.object({
  payRunTypes: yup.string().required("โปรดกรอกข้อมูล"),
  payperiod: yup.string().required("โปรดกรอกข้อมูล"),
  idPaymentType: yup.string().required("โปรดกรอกข้อมูล"),
});

const DialogEdit = (props) => {
  const {
    open,
    handleClose,
    selectedRowsData,
    handleChangeAlertType,
    handleOpenAlert,
    handleCloseAlert,
    handleGetAllDetail,
  } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeAdditionDeduction } = useSelector(
    (state) => state.employeeAdditionDeduction
  );
  const { result: additions } = useSelector((state) => state.additions);
  const { result: deductions } = useSelector((state) => state.deductions);
  const [view, setView] = React.useState("addition");
  const [editMode, setEditMode] = React.useState(null);
  const [formData, setFormData] = React.useState(null);
  const [formDataOt, setFormDataOt] = React.useState(null);
  const [formDataShift, setFormDataShift] = React.useState(null);
  const [formDataPF, setFormDataPF] = React.useState(null);
  const [formDataLateAbsent, setFormDataLateAbsent] = React.useState(null);
  const [formDataSsoTax, setFormDataSsoTax] = React.useState(null);
  const [formDataAddition, setFormDataAddition] = React.useState(null);
  const [formDataDeduction, setFormDataDeduction] = React.useState(null);
  const [formDataWorkingDay, setFormDataWorkingDay] = React.useState(null);

  const [formDataDeletedAddition, setFormDataDeletedAddition] = React.useState(
    []
  );
  const [formDataDeletedDeduction, setFormDataDeletedDeduction] =
    React.useState([]);
  const [idPayrun, setIdPayrun] = useState(null);
  const [idPayrunDetail, setIdPayrunDetail] = useState(null);
  const [initialFormDataAddition, setInitialFormDataAddition] =
    React.useState(null);
  const [initialFormDataDeduction, setInitialFormDataDeduction] =
    React.useState(null);
  const [initialFormDataLateAbsent, setInitialFormDataLateAbsent] =
    React.useState(null);
  const [initialFormDataSsoTax, setInitialFormDataSsoTax] =
    React.useState(null);
  const [initialFormDataWorkingDay, setInitialFormDataWorkingDay] =
    React.useState(null);

  const formik = useFormik({
    initialValues: {
      payRunTypes: "normal",
      payperiod: new Date(),
      idPaymentType: "2",
      payrunMode: "1",
      payrunName: `${userProfile && userProfile.vendorName} ${dayjs(
        new Date()
      ).format("MMMM BBBB")}`,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    if (selectedRowsData) {
      dispatch(getEmployeeAdditionDeduction(selectedRowsData.idEmployees));
      dispatch(getAllAdditionsByCompany(selectedRowsData.idCompany));
      dispatch(getAllDeductionsByCompany(selectedRowsData.idCompany));
      if (
        selectedRowsData.payrunDetailMain &&
        selectedRowsData.payrunDetailMain.length > 0
      ) {
        let findSalary = selectedRowsData.payrunDetailMain.find(
          (x) => x.idPayrollType === 1
        );
        let findOTOne = selectedRowsData.payrunDetailMain.find(
          (x) => x.idPayrollType === 2
        );
        let findOTOneFive = selectedRowsData.payrunDetailMain.find(
          (x) => x.idPayrollType === 3
        );
        let findOTTwo = selectedRowsData.payrunDetailMain.find(
          (x) => x.idPayrollType === 4
        );
        let findOThree = selectedRowsData.payrunDetailMain.find(
          (x) => x.idPayrollType === 5
        );

        let findPf = selectedRowsData.payrunDetailMain.find(
          (x) => x.idPayrollType === 10
        );
        let findPfCompany = selectedRowsData.payrunDetailMain.find(
          (x) => x.idPayrollType === 18
        );

        let findLateEarly = selectedRowsData.payrunDetailMain.find(
          (x) => x.idPayrollType === 16
        );

        let findAbsent = selectedRowsData.payrunDetailMain.find(
          (x) => x.idPayrollType === 15
        );

        let findShiftMorning = selectedRowsData.payrunDetailMain.find(
          (x) => x.idPayrollType === 7
        );
        let findShiftNight = selectedRowsData.payrunDetailMain.find(
          (x) => x.idPayrollType === 9
        );
        let findShiftNoon = selectedRowsData.payrunDetailMain.find(
          (x) => x.idPayrollType === 8
        );

        let findSso = selectedRowsData.payrunDetailMain.find(
          (x) => x.idPayrollType === 11
        );
        let findTax = selectedRowsData.payrunDetailMain.find(
          (x) => x.idPayrollType === 12
        );

        setFormDataOt({
          otOneHours: findOTOne ? findOTOne.valueHour : 0,
          otOneFiveHours: findOTOneFive ? findOTOneFive.valueHour : 0,
          otTwoHours: findOTTwo ? findOTTwo.valueHour : 0,
          otThreeHours: findOThree ? findOThree.valueHour : 0,
          otOneBaht: findOTOne ? findOTOne.value : 0,
          otOneFiveBaht: findOTOneFive ? findOTOneFive.value : 0,
          otTwoBaht: findOTTwo ? findOTTwo.value : 0,
          otThreeBaht: findOThree ? findOThree.value : 0,
        });

        setFormDataPF({
          pfValue: findPf ? findPf.value : 0,
          pfPercent: findPf ? findPf.valueHour : 0,
          pfValueCompany: findPfCompany ? findPfCompany.value : 0,
          pfPercentCompany: findPfCompany ? findPfCompany.valueHour : 0,
        });

        setFormDataLateAbsent({
          lateEarlyValueHour: findLateEarly ? findLateEarly.valueHour : 0,
          lateEarlyValue: findLateEarly ? findLateEarly.value : 0,
          isChangeLateEarly: false,
          absentValueHour: findAbsent ? findAbsent.valueHour : 0,
          absentValue: findAbsent ? findAbsent.value : 0,
          isChangeAbsent: false,
        });

        setInitialFormDataLateAbsent({
          lateEarlyValueHour: findLateEarly ? findLateEarly.valueHour : 0,
          lateEarlyValue: findLateEarly ? findLateEarly.value : 0,
          isChangeLateEarly: false,
          absentValueHour: findAbsent ? findAbsent.valueHour : 0,
          absentValue: findAbsent ? findAbsent.value : 0,
          isChangeAbsent: false,
        });

        setFormDataSsoTax({
          sso: findSso ? findSso.value : 0,
          ssoValueActual: findSso ? findSso.valueActual : 0,
          tax: findTax ? findTax.value : 0,
        });

        setInitialFormDataSsoTax({
          sso: findSso ? findSso.value : 0,
          ssoValueActual: findSso ? findSso.valueActual : 0,
          tax: findTax ? findTax.value : 0,
        });

        setFormDataShift({
          shiftMorning: findShiftMorning ? findShiftMorning.value : 0,
          shiftNoon: findShiftNoon ? findShiftNoon.value : 0,
          shiftNight: findShiftNight ? findShiftNight.value : 0,
        });

        setFormDataWorkingDay({
          workingDay: selectedRowsData.workingDay,
          salary: findSalary ? findSalary.value : 0,
        });

        setInitialFormDataWorkingDay({
          workingDay: selectedRowsData.workingDay,
          salary: findSalary ? findSalary.value : 0,
        });
      } else {
        setFormDataOt({
          otOneHours: 0,
          otOneFiveHours: 0,
          otTwoHours: 0,
          otThreeHours: 0,
          otOneBaht: 0,
          otOneFiveBaht: 0,
          otTwoBaht: 0,
          otThreeBaht: 0,
        });
      }

      if (selectedRowsData.payrunDetailAddition) {
        const employeeAdditions = selectedRowsData.payrunDetailAddition.map(
          (addition, index) => ({
            idPayrunDetailAddition: addition.idPayrunDetailAddition,
            idAddition: addition.idAddition,
            value: addition.value,
            id: generateUniqueId(),
            idPayrun: selectedRowsData.idPayrun,
            idPayrunDetail: selectedRowsData.idPayrunDetail,
            oldIdAddition: addition.idAddition,
            oldValue: addition.value,
            isChanged: false,
          })
        );
        setFormDataAddition(employeeAdditions);
        setInitialFormDataAddition(employeeAdditions);
      }

      if (selectedRowsData.payrunDetailDeduction) {
        const employeeDeductions = selectedRowsData.payrunDetailDeduction.map(
          (deduction, index) => ({
            idPayrunDetailDeduction: deduction.idPayrunDetailDeduction,
            idDeduction: deduction.idDeduction,
            value: deduction.value,
            id: generateUniqueId(),
            idPayrun: selectedRowsData.idPayrun,
            idPayrunDetail: selectedRowsData.idPayrunDetail,
            oldIdDeduction: deduction.idDeduction,
            oldValue: deduction.value,
            isChanged: false,
          })
        );
        setFormDataDeduction(employeeDeductions);
        setInitialFormDataDeduction(employeeDeductions);
      }

      setIdPayrun(selectedRowsData.idPayrun);
      setIdPayrunDetail(selectedRowsData.idPayrunDetail);
    }
  }, [selectedRowsData]);

  const generateUniqueId = () =>
    `id-${Date.now()}-${Math.floor(Math.random() * 1000)}`;

  const handleSubmit = async () => {
    let data = null;

    data = { ...selectedRowsData, editMode: editMode };

    if (editMode === "ot") {
      data.dataChange = formDataOt;
    } else if (editMode === "ssotax") {
      data.dataChange = formDataSsoTax;
    } else if (editMode === "lateEarlyAbsent") {
      data.dataChange = formDataLateAbsent;
    } else if (editMode === "pf") {
      data.dataChange = formDataPF;
    } else if (editMode === "addition") {
      data.dataChange = formDataAddition;
      data.dataDelete = formDataDeletedAddition;
    } else if (editMode === "deduction") {
      data.dataChange = formDataDeduction;
      data.dataDelete = formDataDeletedDeduction;
    } else if (editMode === "workingDay") {
      data.dataChange = formDataWorkingDay;
    }

    console.log("selectedRowsData: ", selectedRowsData);
    console.log("formDataOt: ", formDataOt);
    console.log("formDataSsoTax: ", formDataSsoTax);
    console.log("formDataPF: ", formDataPF);
    console.log("data: ", data);
    console.log("formDataLateAbsent", formDataLateAbsent);

    const result = await dispatch(updatePayrunDetail(data));
    if (result) {
      handleGetAllDetail();
      handleClose();
      handleOpenAlert(result);
      handleChangeAlertType(result);
    }
  };

  const handleChangeView = (event, newView) => {
    if (newView) {
      setView(newView);
    }
  };

  const handleChangeOt = (event) => {
    const { name, value } = event.target;
    setFormDataOt({ ...formDataOt, [name]: value });
  };

  const handleChangeWorkingDay = (event) => {
    const { name, value } = event.target;
    setFormDataWorkingDay({ ...formDataWorkingDay, [name]: value });
  };

  const handleChangeSsoTax = (event) => {
    const { name, value } = event.target;
    setFormDataSsoTax({ ...formDataSsoTax, [name]: value });
  };

  const handleChangeLateAbsent = (event) => {
    const { name, value } = event.target;

    if (name.includes("late")) {
      setFormDataLateAbsent({
        ...formDataLateAbsent,
        isChangeLateEarly: true,
        [name]: value,
      });
    } else {
      setFormDataLateAbsent({
        ...formDataLateAbsent,
        isChangeAbsent: true,
        [name]: value,
      });
    }
  };

  const handleChangePf = (event) => {
    const { name, value } = event.target;
    setFormDataPF({ ...formDataPF, [name]: value });
  };

  const handleChangeAddition = (event, index) => {
    const { name, value } = event.target;
    let newArr = [...formDataAddition];
    newArr[index][name] = value;
    if (newArr[index]["idPayrunDetailAddition"] && value) {
      if (toString(newArr[index]["oldValue"]) !== value) {
        newArr[index]["isChanged"] = true;
      }
    }
    setFormDataAddition(newArr);
  };

  const handleChangeAdditionType = (event, index, addition) => {
    let newArr = [...formDataAddition];
    newArr[index]["idAddition"] = addition.idAddition;
    newArr[index]["isPf"] = addition.isPf;
    newArr[index]["isSso"] = addition.isSso;
    newArr[index]["isTaxable"] = addition.isTaxable;
    if (newArr[index]["idPayrunDetailAddition"]) {
      if (newArr[index]["oldIdAddition"] !== addition.idAddition) {
        newArr[index]["isChanged"] = true;
      }
    }
    setFormDataAddition(newArr);
  };

  const handleChangeDeduction = (event, index) => {
    const { name, value } = event.target;
    let newArr = [...formDataDeduction];
    newArr[index][name] = value;
    if (newArr[index]["idPayrunDetailDeduction"] && value) {
      if (toString(newArr[index]["oldValue"]) !== value) {
        newArr[index]["isChanged"] = true;
      }
    }
    setFormDataDeduction(newArr);
  };

  const handleChangeDeductionType = (event, index, deduction) => {
    let newArr = [...formDataDeduction];
    newArr[index]["idDeduction"] = deduction.idDeduction;
    newArr[index]["isPf"] = deduction.isPf;
    newArr[index]["isSso"] = deduction.isSso;
    newArr[index]["isTaxable"] = deduction.isTaxable;
    if (newArr[index]["idPayrunDetailDeduction"]) {
      if (newArr[index]["oldIdDeduction"] !== deduction.idDeduction) {
        newArr[index]["isChanged"] = true;
      }
    }
    setFormDataDeduction(newArr);
  };

  const handleAddAddition = (event) => {
    setFormDataAddition((prevAddition) => [
      ...prevAddition,
      {
        idAddition: null,
        value: 0,
        id: generateUniqueId(),
        idPayrun: idPayrun,
        idPayrunDetail: idPayrunDetail,
        isTaxable: null,
        isPf: null,
        isSso: null,
      },
    ]);
  };

  const handleAddDeduction = (event) => {
    setFormDataDeduction((prevDeduction) => [
      ...prevDeduction,
      {
        idDeduction: null,
        value: 0,
        id: generateUniqueId(),
        idPayrun: idPayrun,
        idPayrunDetail: idPayrunDetail,
        isTaxable: null,
        isPf: null,
        isSso: null,
      },
    ]);
  };

  const handleDeleteAddition = (event, id) => {
    setFormDataAddition((additions) =>
      additions.filter((addition) => {
        if (addition.id !== id) {
          return addition.id !== id;
        } else {
          if (addition.idPayrunDetailAddition) {
            setFormDataDeletedAddition((prev) => [
              ...prev,
              {
                idPayrunDetailAddition: addition.idPayrunDetailAddition,
                value: addition.oldValue,
              },
            ]);
          }
        }
      })
    );
  };

  const handleDeleteDeduction = (event, id) => {
    setFormDataDeduction((deductions) =>
      deductions.filter((deduction) => {
        if (deduction.id !== id) {
          return deduction.id !== id;
        } else {
          if (deduction.idPayrunDetailDeduction) {
            setFormDataDeletedDeduction((prev) => [
              ...prev,
              {
                idPayrunDetailDeduction: deduction.idPayrunDetailDeduction,
                value: deduction.oldValue,
              },
            ]);
          }
        }
      })
    );
  };

  return (
    <DrawerCustom
      open={open}
      onClose={handleClose}
      title={`${t("EditData")}`}
      anchor={"right"}
    >
      {selectedRowsData && (
        <StyledContent>
          <Typography variant="h5">{`${selectedRowsData.employeeID}: 
          ${
            i18n.resolvedLanguage === "th"
              ? `${selectedRowsData.firstname_TH} ${selectedRowsData.lastname_TH}`
              : `${selectedRowsData.firstname_en} ${selectedRowsData.lastname_en}`
          }
          `}</Typography>
          <Typography
            color="text.third"
            style={{ fontWeight: 500, marginBottom: 16 }}
          >
            {selectedRowsData.departmentName
              ? `${selectedRowsData.departmentName} — `
              : ""}
            <Typography
              color="text.secondary"
              component="span"
            >{`${selectedRowsData.positionName}`}</Typography>
          </Typography>
          <StyledDivider />
          <Grid container alignItems="center" spacing={2}>
            {formDataWorkingDay && (
              <Fragment>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <StyledContentHeaderLabel>
                      {`${t("WorkingDays")}`}
                    </StyledContentHeaderLabel>
                    {editMode !== "workingDay" && (
                      <ButtonBlue
                        variant="text"
                        onClick={() => setEditMode("workingDay")}
                      >{`${t("EditData")}`}</ButtonBlue>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledContentLabel>{t("WorkingDay")}</StyledContentLabel>
                  <StyledFormControl fullWidth>
                    <TextFieldTheme
                      select
                      name="workingDay"
                      value={formDataWorkingDay.workingDay}
                      inputProps={{ readOnly: editMode !== "workingDay" }}
                      onChange={handleChangeWorkingDay}
                    >
                      {[...Array(31).keys()].map((item) => (
                        <MenuItem value={item + 1}>{item + 1}</MenuItem>
                      ))}
                    </TextFieldTheme>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledContentLabel>{t("Salary")}</StyledContentLabel>
                  <TextFieldTheme
                    name="salary"
                    value={formDataWorkingDay.salary}
                    onChange={handleChangeWorkingDay}
                    InputProps={{
                      readOnly: editMode !== "workingDay",
                    }}
                  />
                </Grid>

                <Grid xs={12}>
                  {editMode === "workingDay" && (
                    <StyledWrapActionButton>
                      <ButtonBlue
                        color="error"
                        style={{ marginRight: 8 }}
                        variant="text"
                        onClick={() => {
                          setFormDataWorkingDay(initialFormDataWorkingDay);
                          setEditMode(null);
                        }}
                      >
                        {`${t("Cancel")}`}
                      </ButtonBlue>
                      <ButtonBlue variant="contained" onClick={handleSubmit}>
                        {`${t("SaveData")}`}
                      </ButtonBlue>
                    </StyledWrapActionButton>
                  )}
                </Grid>
                <StyledDivider />
              </Fragment>
            )}

            {/*<Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <StyledContentLabel>ค่าทำงานล่วงเวลา</StyledContentLabel>
                {editMode !== "ot" && (
                  <ButtonBlue
                    variant="text"
                    onClick={() => setEditMode("ot")}
                  >{`${t("EditData")}`}</ButtonBlue>
                )}
              </div>
            </Grid>

             {formDataOt && (
              <Fragment>
                <Grid item xs={12} sm={6}>
                  <StyledContentLabel>
                    OT 1 ({`${t("Unit.Hours")}`})
                  </StyledContentLabel>
                  <TextFieldTheme
                    name="otOneHours"
                    value={formDataOt.otOneHours}
                    onChange={handleChangeOt}
                    InputProps={{
                      readOnly: editMode !== "ot",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledContentLabel>
                    OT 1 ({`${t("Baht")}`})
                  </StyledContentLabel>
                  <TextFieldTheme
                    name="otOneBaht"
                    value={formDataOt.otOneBaht}
                    onChange={handleChangeOt}
                    InputProps={{
                      readOnly: editMode !== "ot",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledContentLabel>
                    OT 1.5 ({`${t("Unit.Hours")}`})
                  </StyledContentLabel>
                  <TextFieldTheme
                    name="otOneFiveHours"
                    value={formDataOt.otOneFiveHours}
                    onChange={handleChangeOt}
                    InputProps={{
                      readOnly: editMode !== "ot",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledContentLabel>
                    OT 1.5 ({`${t("Baht")}`})
                  </StyledContentLabel>
                  <TextFieldTheme
                    name="otOneFiveBaht"
                    value={formDataOt.otOneFiveBaht}
                    onChange={handleChangeOt}
                    InputProps={{
                      readOnly: editMode !== "ot",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledContentLabel>
                    OT 2 ({`${t("Unit.Hours")}`})
                  </StyledContentLabel>
                  <TextFieldTheme
                    name="otTwoHous"
                    value={formDataOt.otTwoHours}
                    onChange={handleChangeOt}
                    InputProps={{
                      readOnly: editMode !== "ot",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledContentLabel>
                    OT 2 ({`${t("Baht")}`})
                  </StyledContentLabel>
                  <TextFieldTheme
                    name="otTwoBaht"
                    value={formDataOt.otTwoBaht}
                    onChange={handleChangeOt}
                    InputProps={{
                      readOnly: editMode !== "ot",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledContentLabel>
                    OT 3 ({`${t("Unit.Hours")}`})
                  </StyledContentLabel>
                  <TextFieldTheme
                    name="otThreeHours"
                    value={formDataOt.otThreeHours}
                    onChange={handleChangeOt}
                    InputProps={{
                      readOnly: editMode !== "ot",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledContentLabel>
                    OT 3 ({`${t("Baht")}`})
                  </StyledContentLabel>
                  <TextFieldTheme
                    name="otThreeBaht"
                    value={formDataOt.otThreeBaht}
                    onChange={handleChangeOt}
                    InputProps={{
                      readOnly: editMode !== "ot",
                    }}
                  />
                </Grid>
              </Fragment>
            )}

            {editMode === "ot" && (
              <StyledWrapActionButton>
                <ButtonBlue
                  style={{ marginRight: 8 }}
                  variant="text"
                  onClick={() => setEditMode(null)}
                >
                  {`${t("Cancel")}`}
                </ButtonBlue>
                <ButtonBlue variant="contained" onClick={handleSubmit}>
                  {`${t("SaveData")}`}
                </ButtonBlue>
              </StyledWrapActionButton>
            )}

            <StyledDivider /> 

            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <StyledContentLabel>ค่ากะ</StyledContentLabel>
                {editMode !== "shift" && (
                  <ButtonBlue
                    variant="text"
                    onClick={() => setEditMode("shift")}
                  >{`${t("EditData")}`}</ButtonBlue>
                )}
              </div>
            </Grid>

            {formDataShift && (
              <Fragment>
                <Grid item xs={12} sm={4}>
                  <StyledContentLabel>กะเช้า</StyledContentLabel>
                  <TextFieldTheme
                    name="shiftMorning"
                    value={formDataShift.shiftMorning}
                    onChange={handleChangeOt}
                    InputProps={{
                      readOnly: editMode !== "shift",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledContentLabel>กะบ่าย</StyledContentLabel>
                  <TextFieldTheme
                    name="shif"
                    value={formDataShift.shiftNoon}
                    onChange={handleChangeOt}
                    InputProps={{
                      readOnly: editMode !== "ot",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledContentLabel>กะดึก</StyledContentLabel>
                  <TextFieldTheme
                    name="otOneFiveHours"
                    value={formDataShift.shiftNight}
                    onChange={handleChangeOt}
                    InputProps={{
                      readOnly: editMode !== "ot",
                    }}
                  />
                </Grid>
              </Fragment>
            )}

            {editMode === "shift" && (
              <StyledWrapActionButton>
                <ButtonBlue
                  style={{ marginRight: 8 }}
                  variant="text"
                  onClick={() => setEditMode(null)}
                >
                  {`${t("Cancel")}`}
                </ButtonBlue>
                <ButtonBlue variant="contained" onClick={handleSubmit}>
                  {`${t("SaveData")}`}
                </ButtonBlue>
              </StyledWrapActionButton>
            )}
            <StyledDivider />*/}

            {/* {formDataPF && (
              <Fragment>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <StyledContentLabel>{`${t(
                      "ProvidentFund"
                    )}`}</StyledContentLabel>
                    {editMode !== "pf" && (
                      <ButtonBlue
                        variant="text"
                        onClick={() => setEditMode("pf")}
                      >{`${t("EditData")}`}</ButtonBlue>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledContentLabel>ส่วนพนักงาน (%)</StyledContentLabel>
                  <TextFieldTheme
                    name="pfPercent"
                    value={formDataPF.pfPercent}
                    onChange={handleChangePf}
                    InputProps={{
                      readOnly: editMode !== "pf",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledContentLabel>ส่วนพนักงาน (บาท)</StyledContentLabel>
                  <TextFieldTheme
                    name="pfValue"
                    value={formDataPF.pfValue}
                    onChange={handleChangePf}
                    InputProps={{
                      readOnly: editMode !== "pf",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledContentLabel>ส่วนบริษัท (%)</StyledContentLabel>
                  <TextFieldTheme
                    name="pfPercentCompany"
                    value={formDataPF.pfPercentCompany}
                    onChange={handleChangePf}
                    InputProps={{
                      readOnly: editMode !== "pf",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledContentLabel>ส่วนบริษัท (บาท)</StyledContentLabel>
                  <TextFieldTheme
                    name="pfValueCompany"
                    value={formDataPF.pfValueCompany}
                    onChange={handleChangePf}
                    InputProps={{
                      readOnly: editMode !== "pf",
                    }}
                  />
                </Grid>
              </Fragment>
            )}

            {editMode === "pf" && (
              <StyledWrapActionButton>
                <ButtonBlue
                  style={{ marginRight: 8 }}
                  variant="text"
                  onClick={() => setEditMode(null)}
                >
                  {`${t("Cancel")}`}
                </ButtonBlue>
                <ButtonBlue variant="contained" onClick={handleSubmit}>
                  {`${t("SaveData")}`}
                </ButtonBlue>
              </StyledWrapActionButton>
            )}*/}

            {formDataLateAbsent && (
              <Fragment>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <StyledContentHeaderLabel>
                      {t("Late")} - {t("LeaveEarly")} - {t("Absent")}
                    </StyledContentHeaderLabel>
                    {editMode !== "lateEarlyAbsent" && (
                      <ButtonBlue
                        variant="text"
                        onClick={() => setEditMode("lateEarlyAbsent")}
                      >{`${t("EditData")}`}</ButtonBlue>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledContentLabel>
                    {t("Late")} / {t("LeaveEarly")} ({t("Unit.Minute")})
                  </StyledContentLabel>
                  <TextFieldTheme
                    name="lateEarlyValueHour"
                    value={formDataLateAbsent.lateEarlyValueHour}
                    onChange={handleChangeLateAbsent}
                    InputProps={{
                      readOnly: editMode !== "lateEarlyAbsent",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledContentLabel>
                    {t("Late")} / {t("LeaveEarly")} ({t("Baht")})
                  </StyledContentLabel>
                  <TextFieldTheme
                    name="lateEarlyValue"
                    value={formDataLateAbsent.lateEarlyValue}
                    onChange={handleChangeLateAbsent}
                    InputProps={{
                      readOnly: editMode !== "lateEarlyAbsent",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledContentLabel>
                    {t("Absent")} ({t("Unit.Day")})
                  </StyledContentLabel>
                  <TextFieldTheme
                    name="absentValueHour"
                    value={formDataLateAbsent.absentValueHour}
                    onChange={handleChangeLateAbsent}
                    InputProps={{
                      readOnly: editMode !== "lateEarlyAbsent",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledContentLabel>
                    {t("Absent")} ({t("Baht")})
                  </StyledContentLabel>
                  <TextFieldTheme
                    name="absentValue"
                    value={formDataLateAbsent.absentValue}
                    onChange={handleChangeLateAbsent}
                    InputProps={{
                      readOnly: editMode !== "lateEarlyAbsent",
                    }}
                    inputProps={{
                      allowNegative: false,
                    }}
                  />
                </Grid>

                {editMode === "lateEarlyAbsent" && (
                  <StyledWrapActionButton>
                    <ButtonBlue
                      color="error"
                      style={{ marginRight: 8 }}
                      variant="text"
                      onClick={() => {
                        setFormDataLateAbsent(initialFormDataLateAbsent);
                        setEditMode(null);
                      }}
                    >
                      {`${t("Cancel")}`}
                    </ButtonBlue>
                    <ButtonBlue variant="contained" onClick={handleSubmit}>
                      {`${t("SaveData")}`}
                    </ButtonBlue>
                  </StyledWrapActionButton>
                )}
                <StyledDivider />
              </Fragment>
            )}

            {formDataSsoTax && (
              <Fragment>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <StyledContentHeaderLabel>
                      {t("SocialSecurity")} - {t("Tax")}
                    </StyledContentHeaderLabel>
                    {editMode !== "ssotax" && (
                      <ButtonBlue
                        variant="text"
                        onClick={() => setEditMode("ssotax")}
                      >{`${t("EditData")}`}</ButtonBlue>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledContentLabel>
                    {t("incomeForSsoCalculation")}
                  </StyledContentLabel>
                  <TextFieldTheme
                    name="ssoValueActual"
                    value={formDataSsoTax.ssoValueActual}
                    onChange={handleChangeSsoTax}
                    InputProps={{
                      readOnly: editMode !== "ssotax",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledContentLabel>{t("SocialSecurity")}</StyledContentLabel>
                  <TextFieldTheme
                    name="sso"
                    value={formDataSsoTax.sso}
                    onChange={handleChangeSsoTax}
                    InputProps={{
                      readOnly: editMode !== "ssotax",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledContentLabel>{t("Tax")}</StyledContentLabel>
                  <TextFieldTheme
                    name="tax"
                    value={formDataSsoTax.tax}
                    onChange={handleChangeSsoTax}
                    InputProps={{
                      readOnly: editMode !== "ssotax",
                    }}
                  />
                </Grid>
                {editMode === "ssotax" && (
                  <StyledWrapActionButton>
                    <ButtonBlue
                      color="error"
                      style={{ marginRight: 8 }}
                      variant="text"
                      onClick={() => {
                        setFormDataSsoTax(initialFormDataSsoTax);
                        setEditMode(null);
                      }}
                    >
                      {`${t("Cancel")}`}
                    </ButtonBlue>
                    <ButtonBlue variant="contained" onClick={handleSubmit}>
                      {`${t("SaveData")}`}
                    </ButtonBlue>
                  </StyledWrapActionButton>
                )}
                <StyledDivider />
              </Fragment>
            )}

            {/*<Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <StyledContentLabel>Addition</StyledContentLabel>
                <ButtonBlue onClick={() => setEditMode("ot")}>{`${t(
                  "Add"
                )}`}</ButtonBlue>
              </div>
            </Grid>
            {selectedRowsData.payrunDetailAddition.map((item) => (
              <Fragment>
                <Grid item xs={12} sm={4}>
                  <StyledContentLabel>{`${item.name}`}</StyledContentLabel>
                  <StyledContentLabel>{`(${item.code})`}</StyledContentLabel>
                  <ButtonBlue onClick={() => setEditMode(item.code)}>
                    {`${t("EditData")}`}
                  </ButtonBlue>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <StyledFormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="idPersonnelLevel"
                    >
                      {[...Array(31).keys()].map((item) => (
                        <MenuItem value={item + 1}>{item + 1}</MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                  {editMode === item.code && (
                    <StyledWrapActionButton>
                      <ButtonBlue
                        variant="text"
                        onClick={() => setEditMode(null)}
                      >
                        {`${t("Cancel")}`}
                      </ButtonBlue>
                      <ButtonBlue variant="contained">{`${t(
                        "SaveData"
                      )}`}</ButtonBlue>
                    </StyledWrapActionButton>
                  )}
                </Grid>
              </Fragment>
            ))}
            <StyledDivider />
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <StyledContentLabel>Deduction</StyledContentLabel>
                <ButtonBlue onClick={() => setEditMode("ot")}>{`${t(
                  "Add"
                )}`}</ButtonBlue>
              </div>
            </Grid>
            {selectedRowsData.payrunDetailDeduction.map((item) => (
              <Fragment>
                <Grid item xs={12} sm={4}>
                  <StyledContentLabel>{`${item.name}`}</StyledContentLabel>
                  <StyledContentLabel>{`(${item.code})`}</StyledContentLabel>
                  <ButtonBlue onClick={() => setEditMode(item.code)}>
                    {`${t("EditData")}`}
                  </ButtonBlue>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <StyledFormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="idPersonnelLevel"
                    >
                      {[...Array(31).keys()].map((item) => (
                        <MenuItem value={item + 1}>{item + 1}</MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                  {editMode === item.code && (
                    <StyledWrapActionButton>
                      <ButtonBlue
                      color="error"
                        variant="text"
                        onClick={() => setEditMode(null)}
                      >
                        {`${t("Cancel")}`}
                      </ButtonBlue>
                      <ButtonBlue variant="contained">{`${t(
                        "SaveData"
                      )}`}</ButtonBlue>
                    </StyledWrapActionButton>
                  )}
                </Grid>
              </Fragment>
            ))} */}

            {formDataAddition && (
              <Fragment>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <StyledContentHeaderLabel>
                      Addition
                    </StyledContentHeaderLabel>
                    {editMode !== "addition" && (
                      <ButtonBlue
                        variant="text"
                        onClick={() => setEditMode("addition")}
                      >{`${t("EditData")}`}</ButtonBlue>
                    )}
                  </div>
                </Grid>
                <>
                  {formDataAddition.map((addition, index) => (
                    <>
                      <Grid item xs={12} sm={7}>
                        <StyledContentLabel>{t("Type")}</StyledContentLabel>
                        <StyledAutocomplete
                          key={addition.id}
                          name="idAddition"
                          options={additions ? additions : []}
                          onChange={(event, newValue) => {
                            handleChangeAdditionType(event, index, newValue);
                          }}
                          value={
                            (additions &&
                              additions.find(
                                (option) =>
                                  option.idAddition === addition.idAddition
                              )) ||
                            null
                          }
                          disableClearable={true}
                          getOptionLabel={(option) =>
                            i18n.language === "th"
                              ? `${option.name} (${option.code})`
                              : `${option.name_EN ? option.name_EN : ``} (${
                                  option.code
                                })`
                          }
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                readOnly: editMode !== "addition",
                              }}
                            />
                          )}
                          PopperComponent={StyledPopper}
                          noOptionsText={`${t("NoData")}`}
                          disabled={editMode !== "addition"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <StyledContentLabel>
                          {t("Amount")} ({t("Baht")})
                        </StyledContentLabel>
                        <TextFieldTheme
                          key={addition.id}
                          name="value"
                          id={addition.idPayrunDetailAddition}
                          value={addition.value}
                          onChange={(event) => {
                            handleChangeAddition(event, index);
                          }}
                          InputProps={{
                            readOnly: editMode !== "addition",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={1}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <IconButton
                          index={addition.id}
                          id={addition.idPayrunDetailAddition || null}
                          aria-label="delete"
                          sx={{
                            color: "#f15e5e",
                            fontSize: "34px",
                            marginTop: "18px",
                          }}
                          onClick={(event) =>
                            handleDeleteAddition(event, addition.id)
                          }
                          disabled={editMode !== "addition"}
                        >
                          <RemoveCircleOutlineIcon fontSize="inherit" />
                        </IconButton>
                      </Grid>
                    </>
                  ))}
                  {editMode === "addition" && (
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <StyledAddButton
                          variant="outlined"
                          startIcon={<AddIcon />}
                          onClick={handleAddAddition}
                        >
                          {t("addAddition")}
                        </StyledAddButton>
                      </div>
                    </Grid>
                  )}
                </>
                {editMode === "addition" && (
                  <StyledWrapActionButton>
                    <ButtonBlue
                      color="error"
                      style={{ marginRight: 8 }}
                      variant="text"
                      onClick={() => {
                        setFormDataAddition(initialFormDataAddition);
                        setEditMode(null);
                      }}
                    >
                      {`${t("Cancel")}`}
                    </ButtonBlue>
                    <ButtonBlue variant="contained" onClick={handleSubmit}>
                      {`${t("SaveData")}`}
                    </ButtonBlue>
                  </StyledWrapActionButton>
                )}
                <StyledDivider />
              </Fragment>
            )}

            {formDataDeduction && (
              <Fragment>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <StyledContentHeaderLabel>
                      Deduction
                    </StyledContentHeaderLabel>
                    {editMode !== "deduction" && (
                      <ButtonBlue
                        variant="text"
                        onClick={() => setEditMode("deduction")}
                      >{`${t("EditData")}`}</ButtonBlue>
                    )}
                  </div>
                </Grid>
                <>
                  {formDataDeduction.map((deduction, index) => (
                    <>
                      <Grid item xs={12} sm={7}>
                        <StyledContentLabel>{t("Type")}</StyledContentLabel>
                        <StyledAutocomplete
                          key={deduction.id}
                          name="idDeduction"
                          options={deductions ? deductions : []}
                          onChange={(event, newValue) => {
                            handleChangeDeductionType(event, index, newValue);
                          }}
                          value={
                            (deductions &&
                              deductions.find(
                                (option) =>
                                  option.idDeduction === deduction.idDeduction
                              )) ||
                            null
                          }
                          disableClearable={true}
                          getOptionLabel={(option) =>
                            i18n.language === "th"
                              ? `${option.name} (${option.code})`
                              : `${option.name_EN ? option.name_EN : ``} (${
                                  option.code
                                })`
                          }
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                readOnly: editMode !== "deduction",
                              }}
                            />
                          )}
                          PopperComponent={StyledPopper}
                          noOptionsText={t("NoData")}
                          disabled={editMode !== "deduction"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <StyledContentLabel>
                          {t("Amount")} ({t("Baht")})
                        </StyledContentLabel>
                        <TextFieldTheme
                          key={deduction.id}
                          name="value"
                          id={deduction.idPayrunDetailDeduction}
                          value={deduction.value}
                          onChange={(event) => {
                            handleChangeDeduction(event, index);
                          }}
                          InputProps={{
                            readOnly: editMode !== "deduction",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={1}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <IconButton
                          index={deduction.id}
                          id={deduction.idPayrunDetailDeduction || null}
                          aria-label="delete"
                          sx={{
                            color: "#f15e5e",
                            fontSize: "34px",
                            marginTop: "18px",
                          }}
                          onClick={(event) =>
                            handleDeleteDeduction(event, deduction.id)
                          }
                          disabled={editMode !== "deduction"}
                        >
                          <RemoveCircleOutlineIcon fontSize="inherit" />
                        </IconButton>
                      </Grid>
                    </>
                  ))}
                  {editMode === "deduction" && (
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <StyledAddButton
                          variant="outlined"
                          startIcon={<AddIcon />}
                          onClick={handleAddDeduction}
                        >
                          {t("addDeduction")}
                        </StyledAddButton>
                      </div>
                    </Grid>
                  )}
                </>
                {editMode === "deduction" && (
                  <StyledWrapActionButton>
                    <ButtonBlue
                      color="error"
                      style={{ marginRight: 8 }}
                      variant="text"
                      onClick={() => {
                        setFormDataDeduction(initialFormDataDeduction);
                        setEditMode(null);
                      }}
                    >
                      {`${t("Cancel")}`}
                    </ButtonBlue>
                    <ButtonBlue variant="contained" onClick={handleSubmit}>
                      {`${t("SaveData")}`}
                    </ButtonBlue>
                  </StyledWrapActionButton>
                )}
                <StyledDivider />
              </Fragment>
            )}
          </Grid>
        </StyledContent>
      )}
    </DrawerCustom>
  );
};

export default DialogEdit;
