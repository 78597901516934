import PayRunsExportService from "../services/payruns-export.service";

export const getAllPayrollDepartment = async (query) => {
    try {
        const res = await PayRunsExportService.getAllPayrollDepartment(query);
        if (res) {
            return res;
        }
    } catch (err) {
        console.error(err);
        return err;
    }
}

export const getAllOTWage = async (date, idCompany) => {
    try {
        const res = await PayRunsExportService.getAllOTWage(date, idCompany);
        if (res) {
            return res;
        }
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getAllOTHour = async (date, idCompany) => {
    try {
        const res = await PayRunsExportService.getAllOTHour(date, idCompany);
        if (res) {
            return res;
        }
    } catch (error) {
        console.error(error);
        return error;
    }
}

export const getAllPayrollListByMonth = async (query) => {
    try {
        const res = await PayRunsExportService.getAllPayrollListByMonth(query);
        if (res) {
            return res;
        };
    } catch (error) {
        console.error(error);
        return error;
    };
};

export const getAllCostcenterListByMonth = async (idCompany, year, month) => {
    try {
        const res = await PayRunsExportService.getAllCostcenterListByMonth(
            idCompany,
            year,
            month
        );
        if (res) {
            return res;
        }
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const getMoneyPaidOutsidePayroll = async (query) => {
    try {
        const res = await PayRunsExportService.getMoneyPaidOutsidePayroll(query);
        if (res) {
            return res;
        }
    } catch (error) {
        console.error(error);
        return error;
    }
};
