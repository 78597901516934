import React from "react";
import { Box, Grid, FormControl, MenuItem } from "@mui/material";
import DatePickerCustom from "../../../shared/date/datePicker";
import PropTypes from "prop-types";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import ClearIcon from "@mui/icons-material/SearchOff";

const AdvanceSearch = ({
  searchData,
  handleChange,
  handleDateChange,
  handleSearchOff,
}) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextFieldTheme
            label="Requester"
            name="requester"
            value={searchData.requester}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextFieldTheme
            label="Creator"
            name="creator"
            value={searchData.creator}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <TextFieldTheme
              select
              label="Status"
              name="status"
              value={searchData.status}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value="Waiting Approval">Waiting Approval</MenuItem>
              <MenuItem value="Waiting Approver Approve">
                Waiting Approver Approve
              </MenuItem>
              <MenuItem value="Approved">Approved</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </TextFieldTheme>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <TextFieldTheme
              select
              label="Classification Levels"
              name="classificationLevels"
              value={searchData.classificationLevels}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value="Public">Public</MenuItem>
              <MenuItem value="Internal">Internal</MenuItem>
              <MenuItem value="Confidential">Confidential</MenuItem>
              <MenuItem value="Secret">Secret</MenuItem>
            </TextFieldTheme>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <TextFieldTheme
              select
              label="Priority"
              name="priority"
              value={searchData.priority}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value="High">High</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="Low">Low</MenuItem>
            </TextFieldTheme>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <TextFieldTheme
              select
              label="E-Memo Type"
              name="eMemoType"
              value={searchData.eMemoType}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value="General">General</MenuItem>
              <MenuItem value="Request">Request</MenuItem>
              <MenuItem value="Report">Report</MenuItem>
              <MenuItem value="Meeting">Meeting</MenuItem>
              <MenuItem value="Policy">Policy</MenuItem>
            </TextFieldTheme>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DatePickerCustom
            label="Created Date From"
            value={searchData.createdDateFrom}
            onChange={(date) => handleDateChange("createdDateFrom", date)}
            renderInput={(params) => <TextFieldTheme {...params} fullWidth />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DatePickerCustom
            label="Created Date To"
            value={searchData.createdDateTo}
            onChange={(date) => handleDateChange("createdDateTo", date)}
            renderInput={(params) => <TextFieldTheme {...params} fullWidth />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextFieldTheme
            label="Subject"
            name="subject"
            value={searchData.subject}
            onChange={handleChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <ButtonBlue
            variant="contained"
            startIcon={<ClearIcon />}
            color="primary"
            onClick={handleSearchOff}
          >
            Clear
          </ButtonBlue>
        </Grid>
      </Grid>
    </Box>
  );
};

AdvanceSearch.propTypes = {
  searchData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleSearchOff: PropTypes.func.isRequired,
};

export default AdvanceSearch;
