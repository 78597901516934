import React, { useState } from "react";
import {
  Typography,
  Grid,
  Container,
  Paper,
  TextField,
  styled,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import SaveIcon from "@mui/icons-material/Save";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineContent,
  TimelineConnector,
  TimelineDot,
} from "@mui/lab";
import { Breadcrumb2 } from "../../shared/breadcrums";

import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from "../../shared/general/Card";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledTextFieldTheme = styled(TextField)({
  marginBottom: 16,
  width: "100%",
});

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "100px",
    minHeight: "calc(100vh - 20px)",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  nameYear: {
    fontSize: 28,
    fontWeight: "bold",
    marginBottom: 10,
  },
  background: {
    backgroundColor: "#007afc",
  },
  nameSelected: {
    fontSize: 20,
    fontWeight: "bold",
    color: "white",
  },
}));

const Map = ({ name, setData, data, trueOrNot }) => {
  //use style in name of classes
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot className={classes.background}>
          <LocationOnIcon />
        </TimelineDot>
        {trueOrNot ? <TimelineConnector /> : ""}
      </TimelineSeparator>
      <TimelineContent>
        <div style={{ marginBottom: 40 }}>
          <CardStyle>
            <div style={{ padding: 16 }}>
              <Typography className={classes.nameYear}>{name}</Typography>
              <StyledTextFieldTheme
                variant="outlined"
                placeholder={`${t("PleaseEnterCareerField")}`}
              />
              <Grid container spacing={2}>
                {data != [] &&
                  data.map((val) => (
                    <Grid
                      item
                      xl={4}
                      md={6}
                      xs={12}
                      style={{ paddingRight: 10, paddingLeft: 10 }}
                    >
                      <Paper className={classes.background}>
                        <Typography className={classes.nameSelected}>
                          {val.title}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
              </Grid>
            </div>
          </CardStyle>
        </div>
      </TimelineContent>
    </TimelineItem>
  );
};

const RoadMap = () => {
  //use style in name of classes
  const classes = useStyles();

  const { t, i18n } = useTranslation();

  //data
  const [now, setNow] = useState([]);
  const [oneToFive, setOneToFive] = useState([]);
  const [sixToTen, setSixToTen] = useState([]);
  const [tenToFifteen, setTenToFifteen] = useState([]);
  const [sixteenToTwenty, setSixteenToTwenty] = useState([]);

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom style={{ marginTop: 20 }}>
          {`${t("CareerPath")}`}
        </Typography>

        <Timeline position="alternate">
          <Map name={"ปัจจุบัน"} data={now} setData={setNow} trueOrNot={true} />
          <Map
            name={"1 - 5 ปี"}
            data={oneToFive}
            setData={setOneToFive}
            trueOrNot={true}
          />
          <Map
            name={"6 - 10 ปี"}
            data={sixToTen}
            setData={setSixToTen}
            trueOrNot={true}
          />
          <Map
            name={"11 - 15 ปี"}
            data={tenToFifteen}
            setData={setTenToFifteen}
            trueOrNot={true}
          />
          <Map
            name={"16 - 20 ปี"}
            data={sixteenToTwenty}
            setData={setSixteenToTwenty}
            trueOrNot={false}
          />
        </Timeline>

        <Grid container justifyContent="end">
          <ButtonBlue
            type="submit"
            variant="contained"
            startIcon={<SaveIcon />}
            style={{
              marginRight: 15,
              marginBottom: 30,
            }}
          >
            {`${t("Save")}`}
          </ButtonBlue>
        </Grid>
      </Container>
    </div>
  );
};

export default RoadMap;
