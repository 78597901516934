import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slider,
  Button,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  updateEmployeeRating,
  updateTeamRating,
  updateOrgRating,
} from "../../../../../actions/okrs";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import MoonshotIcon from "../../../assets/moonshot_logo.svg";
import Swal from "sweetalert2";

const ResponsiveButton = styled(Button)(({ theme }) => ({
  backgroundColor: "rgba(219, 65, 120, 1)",
  fontSize: "14px",
  fontWeight: "bold",
  borderRadius: "7px",
  padding: "6px 12px",
  color: "#ffffff",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    backgroundColor: "#C53B6C",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#DB4178",
  color: "#ffffff",
  padding: theme.spacing(2),
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const EvaluationCard = ({ objectives, onEvaluationSubmitted }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedKeyResult, setSelectedKeyResult] = useState(null);
  const [rating, setRating] = useState(0.0);
  const [loading, setLoading] = useState(false);
  const [currentObjectiveName, setCurrentObjectiveName] = useState("");
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const isManager = userProfile && userProfile.roles.includes("ROLE_MANAGER");
  const isAdmin = userProfile && userProfile.roles.includes("ROLE_ADMIN");

  const handleOpenDialog = (keyResult, objectiveName) => {
    setSelectedKeyResult(keyResult);
    setRating(
      isManager || isAdmin
        ? keyResult.rating || 0.0
        : keyResult.employeeRating || 0.0
    );
    setCurrentObjectiveName(objectiveName);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedKeyResult(null);
    setRating(0.0);
    setCurrentObjectiveName("");
  };

  const handleSubmit = async () => {
    handleCloseDialog();
    if (selectedKeyResult != null) {
      const ratingValue = rating;
      if (isNaN(ratingValue) || ratingValue < 0 || ratingValue > 1.0) {
        await Swal.fire({
          icon: "warning",
          title: t("กรุณาใส่คะแนนระหว่าง 0 ถึง 1.0"),
          customClass: {
            popup: "my-swal-popup",
          },
        });
        return;
      }

      const result = await Swal.fire({
        title: t("ยืนยันการประเมิน"),
        text: t("คุณต้องการส่งการประเมินนี้หรือไม่?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DB4178",
        cancelButtonColor: "#d33",
        confirmButtonText: t("ใช่, ส่งเลย!"),
        cancelButtonText: t("ยกเลิก"),
        customClass: {
          popup: "my-swal-popup",
        },
      });

      if (result.isConfirmed) {
        setLoading(true);
        try {
          if (isManager && selectedKeyResult.idTeamKeyResult) {
            await dispatch(
              updateTeamRating(selectedKeyResult.idTeamKeyResult, ratingValue)
            );
          } else if (isAdmin && selectedKeyResult.idOrganizationKeyResult) {
            await dispatch(
              updateOrgRating(
                selectedKeyResult.idOrganizationKeyResult,
                ratingValue
              )
            );
          } else {
            await dispatch(
              updateEmployeeRating(
                selectedKeyResult.idIndividualKeyResult,
                ratingValue
              )
            );
          }
          handleCloseDialog();
          await Swal.fire({
            icon: "success",
            title: t("ส่งการประเมินเรียบร้อย!"),
            text: t("การประเมินของคุณถูกส่งเรียบร้อยแล้ว."),
            customClass: {
              popup: "my-swal-popup",
            },
          });
          onEvaluationSubmitted();
        } catch (error) {
          console.error("Error updating employee rating:", error);
          handleCloseDialog();
          await Swal.fire({
            icon: "error",
            title: t("เกิดข้อผิดพลาด!"),
            text: t("เกิดข้อผิดพลาดในการส่งการประเมินของคุณ"),
            customClass: {
              popup: "my-swal-popup",
            },
          });
        } finally {
          setLoading(false);
        }
      }
    } else {
      handleCloseDialog();
      await Swal.fire({
        icon: "warning",
        title: t("จำเป็นต้องใส่คะแนนการประเมิน"),
        customClass: {
          popup: "my-swal-popup",
        },
      });
    }
  };

  const evaluationMarks = [
    { value: 0.0, label: "0.0" },
    { value: 0.1, label: "0.1" },
    { value: 0.2, label: "0.2" },
    { value: 0.3, label: "0.3" },
    { value: 0.4, label: "0.4" },
    { value: 0.5, label: "0.5" },
    { value: 0.6, label: "0.6" },
    { value: 0.7, label: "0.7" },
    { value: 0.8, label: "0.8" },
    { value: 0.9, label: "0.9" },
    { value: 1.0, label: "1.0" },
  ];

  const tableRows = objectives
    .flatMap((objective) =>
      objective.keyResults.map((kr) => ({
        objectiveName: objective.objectiveName,
        keyResult: kr,
        quarter: objective.quarter,
        year: objective.year,
      }))
    )
    .sort((a, b) => {
      return a.objectiveName.localeCompare(b.objectiveName);
    });

  console.log("test", objectives);

  return (
    <Card variant="outlined" sx={{ marginBottom: 2 }}>
      <CardContent>
        <Box mt={2}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                <TableCell>{t("ชื่อวัตถุประสงค์")}</TableCell>
                <TableCell>{t("ชื่อ Key Result")}</TableCell>
                <TableCell>
                  {t("วันที่เริ่มต้น")} - {t("วันที่สิ้นสุด")}
                </TableCell>
                <TableCell>{t("ไตรมาส")}</TableCell>
                <TableCell>{t("ประเมิน")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows.length > 0 ? (
                tableRows.map((row, index) => (
                  <TableRow
                    key={`${row.keyResult.idIndividualKeyResult}-${index}`}
                    sx={{
                      "&:nth-of-type(odd)": { backgroundColor: "#fafafa" },
                    }}
                  >
                    <TableCell>{row.objectiveName}</TableCell>
                    <TableCell>{row.keyResult.keyResultName}</TableCell>
                    <TableCell>
                      {dayjs(row.keyResult.startDate).format("DD/MM/YYYY")} -{" "}
                      {dayjs(row.keyResult.endDate).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>{`Q${row.quarter} ${row.year}`}</TableCell>
                    <TableCell>
                      <ResponsiveButton
                        onClick={() =>
                          handleOpenDialog(row.keyResult, row.objectiveName)
                        }
                      >
                        {t("ประเมิน")}
                      </ResponsiveButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    {t("ไม่มี Key Result สำหรับการประเมิน")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>

        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="md"
        >
          <StyledDialogTitle>{t("ประเมิน Key Result")}</StyledDialogTitle>
          <DialogContent dividers>
            {selectedKeyResult && (
              <>
                <Box mb={2}>
                  <Typography variant="h5" gutterBottom>
                    {t("Objectives")}: {currentObjectiveName}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {t("Key Result")}: {selectedKeyResult.keyResultName}
                    {selectedKeyResult.isMoonShotPlanning === 1 && (
                      <Box
                        component="img"
                        src={MoonshotIcon}
                        alt="Moonshot Planning"
                        sx={{ width: 24, height: 24, marginLeft: "8px" }}
                      />
                    )}
                  </Typography>
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography fontWeight="bold" color="#919eab">
                      {t("Strategy")}:
                    </Typography>
                    <Typography>
                      {selectedKeyResult.tacticName || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography fontWeight="bold" color="#919eab">
                      {t("Measurement Evidence")}:
                    </Typography>
                    <Typography>
                      {selectedKeyResult.measureEvidence || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography fontWeight="bold" color="#919eab">
                      {t("Commit Quality")}:
                    </Typography>
                    <Typography>
                      {selectedKeyResult.commitQuality || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography fontWeight="bold" color="#919eab">
                      {t("Measurement Data Type")}:
                    </Typography>
                    <Typography>
                      {selectedKeyResult.measureDataType === 1
                        ? t("Number")
                        : t("Explanation")}
                    </Typography>
                  </Grid>
                  {selectedKeyResult.description && (
                    <Grid item xs={12}>
                      <Typography fontWeight="bold" color="#919eab">
                        {t("Description")}:
                      </Typography>
                      <Typography whiteSpace="pre-line">
                        {selectedKeyResult.description}
                      </Typography>
                    </Grid>
                  )}
                </Grid>

                <Box mt={4}>
                  <Typography variant="h6">{t("Employee Rating")}:</Typography>
                  <Slider
                    value={rating}
                    onChange={(e, newValue) => setRating(newValue)}
                    valueLabelDisplay="auto"
                    min={0}
                    max={1.0}
                    step={0.1}
                    marks={evaluationMarks}
                    sx={{
                      color: "#DB4178",
                      mt: 2,
                    }}
                  />
                </Box>
              </>
            )}
          </DialogContent>
          <StyledDialogActions>
            <Button onClick={handleCloseDialog}>{t("Cancel")}</Button>
            <Button
              onClick={handleSubmit}
              disabled={loading}
              variant="contained"
              color="primary"
            >
              {loading ? t("กำลังส่ง...") : t("Evaluate")}
            </Button>
          </StyledDialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default EvaluationCard;
