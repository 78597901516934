import { httpClient } from "./httpClient";

const getAllPositions = (query) => {
  return httpClient.get(`positions`, { params: query });
};

const postPositions = (data) => {
  return httpClient.post(`positions/postPositions`, data);
};

const putPositions = (id, data) => {
  return httpClient.post(`positions/putPositions/${id}`, data);
};

const addNewPosition = (idCompany, data) => {
  return httpClient.post(`company/${idCompany}/positions/addNewPosition`, data);
};

// const deletePosition = (idPositions) => {
//   return httpClient.delete(`positions/${idPositions}`);
// };

const updatePosition = (idCompany, idPositions, data) => {
  return httpClient.put(`company/${idCompany}/positions/${idPositions}/update`, data);
};

const getAdditionAndTraining = (idPositions) => {
  return httpClient.get(`positions/${idPositions}/additionAndTraining`);
};

const addPosition = (formData) => {
  return httpClient.post(`positions`, formData);
}

const deletePosition = (idPosition) => {
  return httpClient.delete(`positions/${idPosition}`);
}

const getAllJobProfile = (query) => {
  return httpClient.get(`positionsProfile`, { params: query })
}

const addJobProfile = (formData) => {
  return httpClient.post('addJobProfile', formData);
};

const updateJobProfile = (formData) => {
  return httpClient.put('updateJobProfile', formData);
};

export default {
  getAllPositions,
  postPositions,
  putPositions,
  addNewPosition,
  // deletePosition,
  updatePosition,
  getAdditionAndTraining,
  addPosition,
  deletePosition,
  getAllJobProfile,
  addJobProfile,
  updateJobProfile
};
