import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Badge,
  Container,
  Typography,
  Box,
  Tabs,
  Tab,
  Grid,
  TextField,
  MenuItem,
  Autocomplete,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import TableCustom from "../../shared/tableCustom";
import { getAffiliateOrganization } from "../../../../actions/company";
import {
  deleteChatbotDocument,
  getChatbotDocument,
} from "../../../../actions/webhook";
import Loading from "../../shared/loading";
import DialogConfirmDelete from "../../shared/general/DialogConfirmDelete";

const StyledRoot = styled("div")(({ theme }) => ({
  backgroundColor: "#FFFFFF !important",
  "& .displayFlexEnd": {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "8px 0",
    },
  },
  "& .MuiAutocomplete-root": {
    "& .MuiFilledInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
}));

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

function editDocument() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );

  const { result: chatbotDocument, isFetching: isFetchingChatbotDocument } =
    useSelector((state) => state.chatbotDocument);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [openDelete, setOpenDelete] = useState(false);

  const columns = [
    {
      name: `${t("Name")}`,
      minWidth: "300px",
      width: "300px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Typography>{row.fileName}</Typography>
        </Box>
      ),
    },
    {
      name: `${t("Date")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box style={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.createDate).format(
              i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
            )}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("Action")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box style={{ textAlign: "center" }}>
          <IconButton onClick={() => handleClickDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    if (userProfile) {
      dispatch(getAffiliateOrganization());
      dispatch(getChatbotDocument());
    }
  }, [userProfile]);

  useEffect(() => {
    if (
      userProfile &&
      affiliateOrganizationList &&
      affiliateOrganizationList.length > 0
    ) {
      let ownCompany = affiliateOrganizationList.find(
        (x) => x.idCompany === userProfile.idCompany
      );
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany);
    }
  }, [userProfile, affiliateOrganizationList]);

  useEffect(() => {
    if (userProfile && selectedCompany) {
      dispatch(getChatbotDocument(selectedCompany.idCompany));
    }
  }, [userProfile, selectedCompany]);

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
    // setSelectedEmployee(null);
    // dispatch(getAllEmployees(null, newValue.idCompany));
  };

  const handleClickDelete = (row) => {
    setSelectedRow(row);
    setOpenDelete(true);
  };

  const handleClose = () => {
    setOpenDelete(false);
  };

  const handleDelete = async () => {
    const result = await deleteChatbotDocument(
      selectedRow.idCompany,
      selectedRow.idChatbotDocument
    );

    if (result && result.status === 200) {
      dispatch(getChatbotDocument(selectedCompany.idCompany));
      setOpenDelete(false);
    }
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg" style={{ paddingBottom: 40 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4" gutterBottom style={{ paddingTop: 8 }}>
              {t("Chatbot Document")}
            </Typography>
          </Grid>
        </Grid>
        <Box marginTop="24px" marginBottom="24px">
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  options={
                    affiliateOrganizationList
                      ? affiliateOrganizationList.sort((a, b) => {
                          return a.companyName.localeCompare(b.companyName);
                        })
                      : []
                  }
                  getOptionLabel={(option) =>
                    i18n.resolvedLanguage === "th"
                      ? `${option.companyName}`
                      : `${option.companyName_EN}`
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.idCompany === value.idCompany
                  }
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      InputProps={{ ...params.InputProps, endAdornment: null }}
                      variant="filled"
                      placeholder={t("SelectCompany")}
                    />
                  )}
                  value={selectedCompany}
                  onChange={(_, value) => {
                    onChangeCompany(value);
                  }}
                  noOptionsText={t("NoData")}
                  disableClearable
                  // PopperComponent={StyledPopper}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box>
          {isFetchingChatbotDocument ? (
            <Fragment>
              <Box display="flex" justifyContent="center" paddingTop="24px">
                <Loading />
              </Box>
            </Fragment>
          ) : chatbotDocument ? (
            <TableCustom
              columns={columns}
              rows={chatbotDocument ? chatbotDocument : []}
            />
          ) : (
            <Box display="flex" justifyContent="center" paddingTop="24px">
              <Typography>{`${t("NoData")}`}</Typography>
            </Box>
          )}
        </Box>

        <DialogConfirmDelete
          open={openDelete}
          handleClose={handleClose}
          label={"Document"}
          text={
            <Box>
              <Typography
                fontSize="14px"
                fontWeight="600"
                style={{ color: "#f15e5e" }}
              >
                {selectedRow.fileName}
              </Typography>
              {/* <Typography fontSize="18px" style={{ color: "#f15e5e" }}>
                {deleteEducationConfig.data.degree}
              </Typography> */}
            </Box>
          }
          handleDelete={handleDelete}
        />
      </Container>
    </StyledRoot>
  );
}

export default editDocument;
