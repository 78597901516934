import React, { Fragment } from "react";
import { Autocomplete, Box, FormHelperText, Grid, IconButton, MenuItem, Stack, styled, Typography } from "@mui/material";
import { Controller, useFieldArray } from "react-hook-form";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import Dropzone from "react-dropzone";
import AddIcon from "./../../../../assets/addCertificate.png"
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";
import DatePickerCustom from "../../../../shared/date/datePicker";

dayjs.extend(isSameOrAfter)

const StyledRoot = styled(Box)({
  "& .dropzone": {
    cursor: "pointer",
    flexShrink: 0,
    width: 128,
    height: 128,
    boxSizing: "border-box",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .inner-dropzone": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: 0,
      "& img": {
        width: 56,
        height: 56,
      }
    }
  },
  "& .certificated-wrap": {
    textDecoration: "none",
    color: "inherit",
    position: "relative",
    cursor: "pointer",
    flexShrink: 0,
    padding: 8,
    width: 128,
    height: 128,
    boxSizing: "border-box",
    border: "1px solid #D0D0D0",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    "& .icon-part": {
      flexGrow:1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& i": {
        fontSize: 36,
        color: "#D0D0D0"
      }
    },
    "& .filename": {
      flexShrink: 1,
      height: "48px",
      "& .MuiTypography-root": {
        fontSize: 14,
        display: "-webkit-box",
        overflow: "hidden",
        WebkitLineClamp: 2, 
        WebkitBoxOrient: "vertical",
        textAlign: "center",
        wordBreak: "break-all",
      }
    },
    "& .delete": {
      position: "absolute",
      top: 0,
      right: 0,
      padding: 8,
      "& i": {
        fontSize: 16,
      }
    }
  }
})

const FormThree = (props) => {

  const {
    onFinalSubmit,
    onPrevPage,
    control,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    degrees,
    isFetching,
  } = props;

  const {result: jobGroups} = useSelector(state => state.jobRecruitGroup);

  const educationUseFieldArray = useFieldArray({
    control: control,
    name: "education",
  })

  const workUseFieldArray = useFieldArray({
    control: control,
    name: "work"
  })
 
  return (
    <StyledRoot>
      <form onSubmit={handleSubmit(onFinalSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" fontSize={20} sx={{ color: "#000000" }}>ประวัติการศึกษา</Typography>
            <ButtonBlue
              variant="outlined"
              startIcon={<i className="fa-light fa-plus"></i>}
              onClick={()=>{educationUseFieldArray.append({
                startYear: dayjs(),
                endYear: dayjs(),
                universityName: "",
                degree: "",
                faculty: "",
                major: "",
                gpa: "",
              })}}
            >
                เพิ่ม
            </ButtonBlue>
          </Grid>

          {educationUseFieldArray.fields.length <= 0 && (
            <Grid item xs={12}>
              <Typography textAlign="center" color="text.secondary">ไม่มีประวัติการศึกษา</Typography>
            </Grid>
          )}

          {educationUseFieldArray.fields.map((education, index) => (
            <Grid key={education.id} item xs={12}>
              <div style={{paddingRight: 64, position: "relative"}}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Controller
                          name={`education.${index}.startYear`}
                          control={control}
                          render={({field})=>(
                            <DatePickerCustom
                              {...field}
                              views={["year"]}
                              openTo="year"
                              minDate={dayjs().set("year", 1980)}
                              maxDate={dayjs()}
                              inputFormat="YYYY"
                              renderInput={(params) => (
                                <Fragment>
                                <Typography gutterBottom sx={{ color: "#000000" }}>เริ่มการศึกษา</Typography>
                                <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "เริ่มการศึกษา",
                                    readOnly: true
                                  }}
                                  helperText={errors && errors.education && errors.education[index] && errors.education[index].startYear && errors.education[index].startYear.message}
                                  error={errors && errors.education && errors.education[index] && errors.education[index].startYear? true: false}
                                />
                                </Fragment>
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name={`education.${index}.endYear`}
                          control={control}
                          render={({field})=>(
                            <DatePickerCustom
                              {...field}
                              views={["year"]}
                              openTo="year"
                              minDate={dayjs().set("year", 1980)}
                              maxDate={dayjs()}
                              inputFormat="YYYY"
                              renderInput={(params) => (
                                <Fragment>
                                <Typography gutterBottom sx={{ color: "#000000" }}>จบการศึกษา</Typography>
                                <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "จบการศึกษา",
                                    readOnly: true
                                  }}
                                  helperText={errors && errors.education && errors.education[index] && errors.education[index].endYear && errors.education[index].endYear.message}
                                  error={errors && errors.education && errors.education[index] && errors.education[index].endYear? true: false}
                                />
                                </Fragment>
                              )}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      name={`education.${index}.universityName`}
                      control={control}
                      render={({field}) => (
                        <Fragment>
                        <Typography gutterBottom sx={{ color: "#000000" }}>ชื่อสถาบัน</Typography>
                        <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
                          {...field}
                          placeholder="ชื่อสถาบัน"
                          helperText={errors && errors.education && errors.education[index] && errors.education[index].universityName && errors.education[index].universityName.message}
                          error={errors && errors.education && errors.education[index] && errors.education[index].universityName? true: false}
                        />
                        </Fragment>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      name={`education.${index}.degree`}
                      control={control}
                      render={({field}) => (
                        <Fragment>
                          <Typography gutterBottom sx={{ color: "#000000" }}>ระดับการศึกษา</Typography>
                          <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
                            {...field} 
                            placeholder="ระดับการศึกษา"
                            select
                            helperText={errors && errors.education && errors.education[index] && errors.education[index].degree && errors.education[index].degree.message}
                            error={errors && errors.education && errors.education[index] && errors.education[index].degree? true: false}
                          >
                            {degrees.map(d => (
                              <MenuItem key={`deegree_${d.idDegrees}`} value={`${d.degreeName}`}>{d.degreeName}</MenuItem>
                            ))}
                          </TextFieldTheme>
                        </Fragment>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      name={`education.${index}.faculty`}
                      control={control}
                      render={({field}) => (
                        <Fragment>
                        <Typography gutterBottom sx={{ color: "#000000" }}>คณะ</Typography>
                        <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
                          {...field}
                          placeholder="คณะ"
                          helperText={errors && errors.education && errors.education[index] && errors.education[index].faculty && errors.education[index].faculty.message}
                          error={errors && errors.education && errors.education[index] && errors.education[index].faculty? true: false}
                        />
                        </Fragment>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name={`education.${index}.major`}
                      control={control}
                      render={({field}) => (
                        <Fragment>
                          <Typography gutterBottom sx={{ color: "#000000" }}>สาขาวิชา</Typography>
                          <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
                            {...field}
                            placeholder="สาขาวิชา"
                            helperText={errors && errors.education && errors.education[index] && errors.education[index].major && errors.education[index].major.message}
                            error={errors && errors.education && errors.education[index] && errors.education[index].major? true: false}
                          />
                        </Fragment>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name={`education.${index}.gpa`}
                      control={control}
                      render={({field}) => (
                        <Fragment>
                          <Typography gutterBottom sx={{ color: "#000000" }}>GPA</Typography>
                          <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
                            {...field}
                            placeholder="GPA"
                            InputProps={{
                              inputComponent: NumberFormatTheme,
                            }}
                            inputProps={{
                              allowNegative: false,
                              decimalScale: 2,
                              isAllowed: (values) => {
                                return values.value === "" || values.floatValue <= 4.00 ;
                              }
                            }}
                            helperText={errors && errors.education && errors.education[index] && errors.education[index].gpa && errors.education[index].gpa.message}
                            error={errors && errors.education && errors.education[index] && errors.education[index].gpa? true: false}
                          />
                        </Fragment>
                      )}
                    />
                  </Grid>

                </Grid>

                <IconButton
                  onClick={()=>{educationUseFieldArray.remove(index)}}
                  style={{position: "absolute", right: 0, top: 16}}
                >
                  <i className="fa-regular fa-trash"></i>
                </IconButton>

              </div>
            </Grid>
          ))}


          <Grid item xs={12} container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" fontSize={20} sx={{ color: "#000000" }}>ประสบการณ์ทำงาน</Typography>
            <ButtonBlue
              variant="outlined"
              startIcon={<i className="fa-light fa-plus"></i>}
              onClick={()=>{workUseFieldArray.append({
                startDate: dayjs(),
                endDate: dayjs(),
                companyName: "",
                positionName: "",
                jobGroup: null,
              })}}
            >
              เพิ่ม
            </ButtonBlue>
          </Grid>

          {workUseFieldArray.fields.length <= 0 && (
            <Grid item xs={12}>
              <Typography textAlign="center" color="text.secondary">ไม่มีประสบการณ์ทำงาน</Typography>
            </Grid>
          )}

          {workUseFieldArray.fields.map((work, index) => (
            <Grid key={work.id} item xs={12}>
              <div style={{paddingRight: 64, position: "relative"}}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Controller
                          name={`work.${index}.startDate`}
                          control={control}
                          render={({field})=>(
                            <DatePickerCustom
                              {...field}
                              // views={["year"]}
                              // openTo="year"
                              views={["year", "month", "day"]}
                              openTo="day"
                              minDate={dayjs().set("year", 1980)}
                              maxDate={dayjs()}
                              renderInput={(params) => (
                                <Fragment>
                                <Typography gutterBottom sx={{ color: "#000000" }}>เริ่มงาน</Typography>
                                <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "เริ่มงาน",
                                    readOnly: true
                                  }}
                                  helperText={errors && errors.work && errors.work[index] && errors.work[index].startDate && errors.work[index].startDate.message}
                                  error={errors && errors.work && errors.work[index] && errors.work[index].startDate? true: false}
                                />
                                </Fragment>
                              )}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Controller
                          name={`work.${index}.endDate`}
                          control={control}
                          render={({field})=>(
                            <DatePickerCustom
                              {...field}
                              // views={["year"]}
                              // openTo="year"
                              views={["year", "month", "day"]}
                              openTo="day"
                              minDate={dayjs().set("year", 1980)}
                              maxDate={dayjs()}
                              renderInput={(params) => (
                                <Fragment>
                                <Typography gutterBottom sx={{ color: "#000000" }}>ออกจากงาน</Typography>
                                <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
                                  {...params} 
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "ออกจากงาน",
                                    readOnly: true
                                  }}
                                  // helperText={errors && errors.work && errors.work[index] && errors.work[index].endYear && errors.work[index].endYear.message}
                                  // error={errors && errors.work && errors.work[index] && errors.work[index].endYear? true: false}
                                  helperText={errors && errors.work && errors.work[index] && errors.work[index].endDate && errors.work[index].endDate.message}
                                  error={errors && errors.work && errors.work[index] && errors.work[index].endDate? true: false}
                                />
                                </Fragment>
                              )}
                            />
                          )}
                        />
                      </Grid>
                    </Grid> 
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      name={`work.${index}.companyName`}
                      control={control}
                      render={({field}) => (
                        <Fragment>
                        <Typography gutterBottom sx={{ color: "#000000" }}>ชื่อบริษัท</Typography>
                        <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
                          {...field}
                          placeholder="ชื่อบริษัท"
                          helperText={errors && errors.work && errors.work[index] && errors.work[index].companyName && errors.work[index].companyName.message}
                          error={errors && errors.work && errors.work[index] && errors.work[index].companyName? true: false}
                        />
                        </Fragment>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      name={`work.${index}.positionName`}
                      control={control}
                      render={({field}) => (
                        <Fragment>
                        <Typography gutterBottom sx={{ color: "#000000" }}>ตำแหน่งงาน</Typography>
                        <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
                          {...field}
                          placeholder="ตำแหน่งงาน"
                          helperText={errors && errors.work && errors.work[index] && errors.work[index].positionName && errors.work[index].positionName.message}
                          error={errors && errors.work && errors.work[index] && errors.work[index].positionName? true: false}
                        />
                        </Fragment>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      name={`work.${index}.jobGroup`}
                      control={control}
                      render={({field, formState}) => (
                        <Autocomplete
                          sx={{
                            "& .MuiInputBase-root": {
                              paddingLeft: 0,
                              paddingTop: 0,
                              paddingBottom: 0,
                              "& input": {
                                padding: "13.5px!important",
                              }
                            }
                          }}
                          disableClearable
                          options={[...jobGroups].sort((a, b) => a.jobGroupName.localeCompare(b.jobGroupName))}
                          getOptionLabel={(option) => option.jobGroupName}
                          isOptionEqualToValue={(option, value) => (option.idJobGroup === value.idJobGroup)}
                          renderOption={(props, option, { selected }) => (
                            <li {...props} key={option.idJobGroup}>
                              {option.jobGroupName}
                            </li>
                          )}
                          renderInput={(params) => (
                            <Fragment>
                              <Typography gutterBottom sx={{ color: "#000000" }}>กลุ่มงาน</Typography>
                              <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
                                {...params}
                                placeholder="กลุ่มงาน"
                                error={formState.errors && formState.errors.work && formState.errors.work[index] && formState.errors.work[index].jobGroup? true: false}
                                helperText={formState.errors && formState.errors.work && formState.errors.work[index] && formState.errors.work[index].jobGroup? formState.errors.work[index].jobGroup.message: null}
                              />
                            </Fragment>
                            )}
                          noOptionsText="ไม่พบคำที่ค้นหา"
                          value={field.value}
                          onChange={(_, data) => {
                            field.onChange(data);
                          }}
                        />
                      )}
                    />
                  </Grid>

                </Grid>

                <IconButton
                  onClick={()=>{workUseFieldArray.remove(index)}}
                  style={{position: "absolute", right: 0, top: 16}}
                >
                  <i className="fa-regular fa-trash"></i>
                </IconButton>
              </div>
            </Grid>
          ))}

          <Grid item xs={12}>
            <Typography variant="h5" fontSize={20} sx={{ color: "#000000" }}>ใบประกาศนียบัตร</Typography>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" gap="8px" flexWrap="wrap">
              <Controller
                control={control}
                name="certificates"
                render={({field, formState}) => (
                  <Fragment>
                    <Dropzone
                      onDrop={(acceptedFiles, rejectedFiles) => {
                        if(acceptedFiles.length > 0){

                          clearErrors("certificates")

                          if(field.value.length >= 3){
                            setError("certificates", {type: "custom", message: "สามารถอัพโหลดได้สูงสุด 3 ไฟล์เท่านั้น"})
                            return;
                          }
                    
                          if((acceptedFiles.length + field.value.length) > 3){
                            acceptedFiles.splice(3 - field.value.length);
                            field.onChange([...field.value, ...acceptedFiles])
                            setError("certificates", {type: "custom", message: "สามารถอัพโหลดได้สูงสุด 3 ไฟล์เท่านั้น"})
                            return;
                          }

                          field.onChange([...field.value, ...acceptedFiles])

                        }
                      }}
                      accept="application/pdf, image/*"
                      multiple
                      maxSize={10485760}
                    >
                      {({
                        getRootProps,
                        getInputProps,
                      }) => (
                        <div {...getRootProps({ className: "dropzone" })}>

                          <Box>
                            <input {...getInputProps()}/>
                            <Box className="inner-dropzone">
                              <img alt="add" src={AddIcon} />
                            </Box>
                          </Box>
                         
                        </div>
                        )
                      }
                    </Dropzone>

                    {field.value.map((c, index) => (
                      <Box key={index} className="certificated-wrap" component={"a"} href={URL.createObjectURL(c)} target="_blank">
                        <Box className="icon-part">
                          <i className="fa-solid fa-file"></i>
                        </Box>
                        <Box className="filename">
                          <Typography>{c.name}</Typography>
                        </Box>
                        <IconButton className="delete" onClick={(e)=>{e.preventDefault(); field.onChange(field.value.filter((_, fIndex) => fIndex !== index))}}>
                          <i className="fa-solid fa-trash-can"></i>
                        </IconButton>
                      </Box>
                    ))}

                  </Fragment>
                )}
              />
            </Box>

            {/* {errors && errors.certificates && <FormHelperText error>{errors.certificates.message}</FormHelperText>} */}
            <FormHelperText error>สามารถอัพโหลดได้เฉพาะไฟล์ .pdf หรือ รูปภาพ ได้สูงสุด 3 ไฟล์เท่านั้น</FormHelperText>
          </Grid>

          <Grid item xs={12}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <ButtonBlue variant="contained" onClick={onPrevPage}>ก่อนหน้า</ButtonBlue>
              <ButtonBlue variant="contained" type="submit" disabled={isFetching}>ยืนยัน</ButtonBlue>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </StyledRoot>
  )
}

export default FormThree;