import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import OrganizationChart from "@dabeng/react-orgchart";
import "./Dashboard.css";
import TeamDashboardModal from "../../../shared/pages/okrs/components/TeamDashboardModal";
import { DashboardData } from "../DataSet";

import { useSelector, useDispatch } from "react-redux";
import { getAllOrgPlan } from "../../../../../actions/OrganizationPlans";
import { getAllTeamPlan } from "../../../../../actions/TeamPlans";

const useStyles = makeStyles(() => ({
  loadingState: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f4f4f4",
    border: "1px solid #dee2e6",
    height: "500px",
    borderRadius: 8,
  },
}));

function TeamDashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: teamPlanStore } = useSelector((state) => state.TeamPlans);
  const { result: orgPlanStore } = useSelector((state) => state.OrgPlans);

  const [chart, setChart] = useState([]);
  const [teamPlan, setTeamPlan] = useState([]);
  const [isChartLoading, setIsChartLoading] = useState(true);
  const [teamDashboardModal, setTeamDashboardModal] = useState({
    isOpen: false,
    teamName: "",
    type: "",
  });

  const handleNodeClick = (nodeData) => {
    console.log("nodeData", nodeData);

    if (nodeData.name != "SCG Chemicals") {
      setTeamDashboardModal({
        isOpen: true,
        teamName: nodeData.name,
        type: "team",
      });

      //fetchByName (Team)
      let teamPlans = teamPlanStore.filter(
        (x) =>
          x.teamName.includes(nodeData.name) &&
          x.planStatus.includes("Approved")
      );

      setTeamPlan(teamPlans);
    } else {
      setTeamDashboardModal({
        isOpen: true,
        teamName: nodeData.name,
        type: "organization",
      });

      //fetchApproved (Org)
      let orgPlans = orgPlanStore.filter((x) =>
        x.planStatus.includes("Approved")
      );

      setTeamPlan(orgPlans);
    }
  };

  const fetchChartData = () => {
    dispatch(getAllOrgPlan());
    dispatch(getAllTeamPlan());
  };

  useEffect(() => {
    if (teamPlanStore && orgPlanStore) {
      //fetch orgPlans
      let orgPlans = orgPlanStore.filter((x) =>
        x.planStatus.includes("Approved")
      );

      let amountOrgObj = orgPlans.length;
      let amountOrgKR = 0;

      orgPlans.forEach((org) => {
        if (org.keyResult1) {
          amountOrgKR += 1;
        }

        if (org.keyResult2) {
          amountOrgKR += 1;
        }

        if (org.keyResult3) {
          amountOrgKR += 1;
        }
      });

      //fetch teamPlans
      let teamPlans = teamPlanStore.filter((x) =>
        x.planStatus.includes("Approved")
      );

      //group by teamName
      let planGroups = teamPlans.reduce((groups, item) => {
        const group = groups[item.teamName] || [];
        group.push(item);
        groups = group;
        return groups;
      }, {});

      planGroups.forEach((element) => {
        element.amountObj = teamPlans.filter((x) =>
          x.teamName.includes(element.teamName)
        ).length;

        let kr = 0;
        if (element.keyResult1) {
          kr += 1;
        }

        if (element.keyResult2) {
          kr += 1;
        }

        if (element.keyResult3) {
          kr += 1;
        }

        element.amountKR = kr;
      });

      let arr = planGroups;
      let chartData = DashboardData;

      chartData.title =
        "Objective : " + amountOrgObj + " , Key Result : " + amountOrgKR;

      for (var i = 0; i < arr.length; i++) {
        switch (arr[i].teamName) {
          case "HR Chem":
            chartData.children[0].title =
              "Objective : " +
              arr[0].amountObj +
              " , Key Result : " +
              arr[0].amountKR;
            break;
          case "HR Poly":
            chartData.children[0].children[0].title =
              "Objective : " +
              arr[0].amountObj +
              ", Key Result : " +
              arr[0].amountKR;
            break;
          case "HR TPE":
            chartData.children[0].children[0].children[0].title =
              "Objective : " +
              arr[0].amountObj +
              " , Key Result : " +
              arr[0].amountKR;
            break;
          case "HR TPC":
            chart.children[0].children[0].children[1].title =
              "Objective : " +
              arr[0].amountObj +
              " , Key Result : " +
              arr[0].amountKR;
            break;
          case "HR GSC":
            chartData.children[0].children[0].children[2].title =
              "Objective : " +
              arr[0].amountObj +
              " , Key Result : " +
              arr[0].amountKR;
            break;
        }
      }

      console.log("chartData", chartData);

      setChart(chartData);
      setIsChartLoading(false);
    }
  }, [teamPlanStore, orgPlanStore]);

  useEffect(() => {
    fetchChartData();
  }, []);

  return (
    <div style={{ marginTop: "32px" }}>
      {isChartLoading ? (
        <div className={classes.loadingState}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <OrganizationChart
            datasource={chart}
            chartClass="myChart2"
            onClickNode={(nodeData) => handleNodeClick(nodeData)}
          />
        </>
      )}
      <TeamDashboardModal
        teamDashboardModal={teamDashboardModal}
        setTeamDashboardModal={setTeamDashboardModal}
        teamPlan={teamPlan}
        isChartLoading={isChartLoading}
      />
    </div>
  );
}

export default TeamDashboard;
