import { httpClient } from "./httpClient";
import dayjs from "dayjs";

const getAllLeaveBalanceByEmployeeId = (year) => {
  return httpClient.get(`/users/leave?year=${year}`);
};

const allLeaveType = (query) => {
  return httpClient.get(`/leave-type`, { params: query });
};

const deleteLeaveType = (idLeaveType) => {
  return httpClient.delete(`/leave-type/${idLeaveType}`);
};

const deleteLeaveGroup = (idLeaveGroup) => {
  return httpClient.delete(`/leave-group/${idLeaveGroup}`);
};

const allLeaveEmployeeByManager = (search) => {
  return httpClient.get(
    `request/leave?start=${dayjs(search.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(search.end).format("YYYY-MM-DD")}`
  );
};

const getAllLeaveWithDrawBy = (filter, search, idCompany) => {
  return httpClient.get(
    // `request/leave/withdraw?filter=${filter}&start=${dayjs(search.start).format(
    //   "YYYY-MM-DD"
    // )}&end=${dayjs(search.end).format("YYYY-MM-DD")}`
    `request/leave/withdraw`,
    {
      params: {
        filter: filter ? filter : undefined,
        start:
          search && search.start
            ? dayjs(search.start).format("YYYY-MM-DD")
            : undefined,
        end:
          search && search.end
            ? dayjs(search.end).format("YYYY-MM-DD")
            : undefined,
        idCompany: idCompany,
      },
    }
  );
};

const getAllLeaveRequestWithDraw = (query) => {
  return httpClient.get(`request/leave/withdraw`, { params: query });
};

const updateApproveLeaveEmployeeBy = (filter, formData) => {
  return httpClient.put(`request/leave/approve?filter=${filter}`, formData);
};

const withdrawLeaveEmployee = (filter, formData) => {
  if (filter) {
    return httpClient.put(`request/leave/withdraw?filter=${filter}`, formData);
  } else {
    return httpClient.put(`request/leave/withdraw`, formData);
  }
};

const addLeaveType = (formData) => {
  return httpClient.post(`/leave-type`, formData);
};

const addLeaveEmployees = (formData) => {
  return httpClient.post(`/employee/leave`, formData);
};

const uploadFileLeaveType = (formData) => {
  return httpClient.post(`/leave`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getAllLeaveEmployeesBy = (filter, search) => {
  return httpClient.get(
    `leave?filter=${filter}&start=${dayjs(search.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(search.end).format("YYYY-MM-DD")}`
  );
};

const uploadExcelLeaveEmployees = (formData) => {
  return httpClient.post(`/upload-excel/leave-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getExcelDataLeaveTypeEmployee = () => {
  return httpClient.get(`/download-excel/leave-type-employees`);
};

const getAllLeaveEmployee3month = (start, end, id) => {
  return httpClient.get(
    `AllLeave3month/${id}?start=${dayjs(start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(end).format("YYYY-MM-DD")}`
  );
};

const getAllLeaveEmployeeYear = (start, id) => {
  return httpClient.get(
    `AllLeaveEmployeeYear/${id}?start=${dayjs(start).format("YYYY-MM-DD")}`
  );
};

const getAllemployeeAbsentYear = (start, id) => {
  return httpClient.get(
    `AllLeaveEmployeeAbsentYear/${id}?start=${dayjs(start).format(
      "YYYY-MM-DD"
    )}`
  );
};

const getAllLeaveV2 = (query) => {
  return httpClient.get(`/v2/leave`, { params: query });
};

const addLeaveQuota = (formData) => {
  return httpClient.post(`leave-quota`, formData);
};

const updateLeaveQuota = (idLeaveQuota, formData) => {
  return httpClient.put(`leave-quota/${idLeaveQuota}`, formData);
};

const getLeaveSetting = () => {
  return httpClient.get(`leave-setting`);
};

const getLeaveAvailable = (query) => {
  return httpClient.get(`leave/leave-available`, { params: query });
}

const getLeaveRoundList = (query) => {
  return httpClient.get(`leave/leave-round-list`, { params: query });
}

const getLeaveList = (query) => {
  return httpClient.get(`leave/leavelist`, { params: query });
}

const getLeaveRequestList = (query) => {
  return httpClient.get(`leave/request-list`, { params: query });
}

const getManagerPendingRequestLeave = (query) => {
  return httpClient.get(`manager/leave/pending-list`, { params: query });
}

const approveRequestLeaveByManager = (formData) => {
  return httpClient.post(`manager/leave/approve`, formData);
}

const getAdminPendingRequestLeave = (query) => {
  return httpClient.get(`admin/leave/pending-list`, { params: query });
}

const approveRequestLeaveByAdmin = (formData) => {
  return httpClient.post(`admin/leave/approve`, formData);
}

export default {
  getAllLeaveBalanceByEmployeeId,
  allLeaveEmployeeByManager,
  updateApproveLeaveEmployeeBy,
  getAllLeaveWithDrawBy,
  getAllLeaveRequestWithDraw,
  withdrawLeaveEmployee,
  allLeaveType,
  addLeaveType,
  addLeaveEmployees,
  uploadFileLeaveType,
  getAllLeaveEmployeesBy,
  uploadExcelLeaveEmployees,
  getExcelDataLeaveTypeEmployee,
  getAllLeaveEmployee3month,
  getAllLeaveEmployeeYear,
  getAllemployeeAbsentYear,
  getAllLeaveV2,
  deleteLeaveType,
  deleteLeaveGroup,
  addLeaveQuota,
  updateLeaveQuota,
  getLeaveSetting,
  getLeaveAvailable,
  getLeaveRoundList,
  getLeaveList,
  getLeaveRequestList,
  getManagerPendingRequestLeave,
  approveRequestLeaveByManager,
  getAdminPendingRequestLeave,
  approveRequestLeaveByAdmin,
};
