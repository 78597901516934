import React, { Fragment, useEffect, useState } from "react"
import { Autocomplete, Avatar, Box, Container, FilledInput, FormControl, Grid, IconButton, InputAdornment, Link, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, ToggleButton, ToggleButtonGroup, Typography, styled } from "@mui/material"
import CardStyle from "../../../shared/general/Card"
import { Controller, useForm } from "react-hook-form"
import { NavLink } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search"
import { useTranslation } from "react-i18next"
import TextFieldTheme from "../../../shared/general/TextFieldTheme"
import ButtonBlue from "../../../shared/general/ButtonBlue"
import { useDispatch, useSelector } from "react-redux"
import { getEmployeeSearch } from "../../../../../actions/employee"
import { getUserFullName, getUserPosition, getUserCompany, getUserDepartment } from "../../../../../utils/userData"
import { getManager } from "../../../../../actions/manager"
import { getAffiliateOrganization } from "../../../../../actions/company"
import MoreVertIcon from "@mui/icons-material/MoreVert"

const StyledRoot = styled(Box)({
  padding: "24px",
})

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px -10px 20px #EEEEEE",
    position: "sticky",
    left: 0,
    backgroundColor: "#f4f6f8",
    zIndex: 4,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  "& .position": {
    minWidth: 200,
  },
  "& .reportTo": {
    minWidth: 150,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "none",
  padding: 16,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .fullname": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .company": {
    // fontWeight: 400,
    // fontSize: 16,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    minWidth: 280,
  },
  "& .position": {
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    minWidth: 200,
  },
  "& .reportTo": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    minWidth: 150,
    fontWeight: 600,
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: 4,
    color: "#46cbe2",
    border: "1px solid #46cbe2",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#247785",
      backgroundColor: "#247785",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#46cbe2",
      "&:hover": {
        borderColor: "#247785",
        backgroundColor: "#247785",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #46cbe2",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
  },
}));

const StyledMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    borderRadius: 8,
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
  },
  "& .MuiList-root": {
    padding: 8,
  },
  "& .MuiMenuItem-root": {
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#919eab14",
    },
  },
});


const EmployeeSearchPage = (props) => {

  const { setExcelDataCompany } = props;

  const dispatch = useDispatch()
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector(state => state.userProfile)
  const { result: employeeSearch } = useSelector(state => state.employeeSearch)
  const { result: manager } = useSelector(state => state.manager)
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

  const [anchorEl, setAnchorEl] = useState(null);
  const [valueSelected, setValueSelected] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [total, setTotal] = useState(0);

  const [isTerminate, setIsTermiante] = useState(0);
  const [moreFilter, setMoreFilter] = useState(false);
  const [companyList, setCompanyList] = useState(null);
  const [businessUnitList, setBusinessUnitList] = useState(null);
  const [divisionList, setDivisionList] = useState(null);
  const [departmentList, setDepartmentList] = useState(null);
  const [sectionList, setSectionList] = useState(null);
  const [positionList, setPositionList] = useState(null);

  console.log("affiliateOrganizationList", affiliateOrganizationList)

  const useHookForm = useForm({
    defaultValues: {
      name: "",
      company: { idCompany: "all", companyName: `${t("All")}` },
      businessUnit: { idBusinessUnit: "all", businessUnitName: `${t("All")}` },
      division: { idDivision: "all", divisionName: `${t("All")}` },
      department: { idDepartment: "all", departmentName: `${t("All")}` },
      section: { idSection: "all", sectionName: `${t("All")}` },
      position: { idPosition: "all", positionName: `${t("All")}` },
      manager: { idEmployees: "all", firstname_TH: `${t("All")}`, lastname_TH: "" },
    },
    mode: "all",
  })

  const onSubmit = async (data) => {

    const searchData = {
      name: data.name ? data.name : null,
      idCompany: (data.company && data.company.idCompany !== "all") ? data.company.idCompany : null,
      idBusinessUnit: (data.businessUnit && data.businessUnit.idBusinessUnit !== "all") ? data.businessUnit.idBusinessUnit : null,
      idDivision: (data.division && data.division.idDivision !== "all") ? data.division.idDivision : null,
      idDepartment: (data.department && data.department.idDepartment !== "all") ? data.department.idDepartment : null,
      idSection: (data.section && data.section.idSection !== "all") ? data.section.idSection : null,
      idPosition: (data.position && data.position.idPosition !== "all") ? data.position.idPosition : null,
      idManager: (data.manager && data.manager.idEmployees !== "all") ? data.manager.idEmployees : null,
      per_page: rowsPerPage,
      page: 1,
      isTerminate: isTerminate,
      language: i18n.language,
    }

    setPage(0)
    dispatch(getEmployeeSearch(searchData)).then(res => {
      setTotal(res.data.pagination.total)
    })
  }

  const columns = [
    { id: "name", label: `${t("FullName")}`, minWidth: 240 },
    {
      id: "company",
      label: `${t("Company")}`,
      width: 320,
      className: "company",
    },
    {
      id: "position",
      label: `${t("Position")}/${t("Department")}`,
      width: 320,
      className: "position",
    },
    {
      id: "email",
      label: `${t("Email")}`,
      minWidth: 140,
      className: "email",
    },
    {
      id: "report_To_LV1",
      label: `${t("ManagerLV")} 1`,
      minWidth: 120,
      className: "report_To",
    },
    {
      id: "report_To_LV2",
      label: `${t("ManagerLV")} 2`,
      minWidth: 120,
      className: "report_To",
    },
  ];

  const handleChangePage = (_, newPage) => {

    const searchData = {
      name: useHookForm.getValues("name") ? useHookForm.getValues("name") : null,
      idCompany: (useHookForm.getValues("company") && useHookForm.getValues("company.idCompany") !== "all") ? useHookForm.getValues("company.idCompany") : null,
      idBusinessUnit: (useHookForm.getValues("businessUnit") && useHookForm.getValues("businessUnit.idBusinessUnit") !== "all") ? useHookForm.getValues("businessUnit.idBusinessUnit") : null,
      idDivision: (useHookForm.getValues("division") && useHookForm.getValues("division.idDivision") !== "all") ? useHookForm.getValues("division.idDivision") : null,
      idDepartment: (useHookForm.getValues("department") && useHookForm.getValues("department.idDepartment") !== "all") ? useHookForm.getValues("department.idDepartment") : null,
      idSection: (useHookForm.getValues("section") && useHookForm.getValues("section.idSection") !== "all") ? useHookForm.getValues("section.idSection") : null,
      idPosition: (useHookForm.getValues("position") && useHookForm.getValues("position.idPosition") !== "all") ? useHookForm.getValues("position.idPosition") : null,
      idManager: (useHookForm.getValues("manager") && useHookForm.getValues("manager.idEmployees") !== "all") ? useHookForm.getValues("manager.idEmployees") : null,
      per_page: rowsPerPage,
      page: newPage + 1,
      isTerminate: isTerminate,
    }

    setPage(newPage);

    dispatch(getEmployeeSearch(searchData)).then(res => {
      setTotal(res.data.pagination.total)
    })
  };

  const fetchingEmployee = () => {
    const searchData = {
      name: useHookForm.getValues("name") ? useHookForm.getValues("name") : null,
      idCompany: (useHookForm.getValues("company") && useHookForm.getValues("company.idCompany") !== "all") ? useHookForm.getValues("company.idCompany") : null,
      idBusinessUnit: (useHookForm.getValues("businessUnit") && useHookForm.getValues("businessUnit.idBusinessUnit") !== "all") ? useHookForm.getValues("businessUnit.idBusinessUnit") : null,
      idDivision: (useHookForm.getValues("division") && useHookForm.getValues("division.idDivision") !== "all") ? useHookForm.getValues("division.idDivision") : null,
      idDepartment: (useHookForm.getValues("department") && useHookForm.getValues("department.idDepartment") !== "all") ? useHookForm.getValues("department.idDepartment") : null,
      idSection: (useHookForm.getValues("section") && useHookForm.getValues("section.idSection") !== "all") ? useHookForm.getValues("section.idSection") : null,
      idPosition: (useHookForm.getValues("position") && useHookForm.getValues("position.idPosition") !== "all") ? useHookForm.getValues("position.idPosition") : null,
      idManager: (useHookForm.getValues("manager") && useHookForm.getValues("manager.idEmployees") !== "all") ? useHookForm.getValues("manager.idEmployees") : null,
      per_page: rowsPerPage,
      page: 1,
      isTerminate: isTerminate,
    }

    setPage(0);

    dispatch(getEmployeeSearch(searchData)).then(res => {
      setTotal(res.data.pagination.total)
    })
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeFilterEmployee = (event, newStatus, selectName) => {
    if (newStatus !== null) {
      setIsTermiante(Number(newStatus))

      const searchData = {
        name: useHookForm.getValues("name") ? useHookForm.getValues("name") : null,
        idCompany: (useHookForm.getValues("company") && useHookForm.getValues("company.idCompany") !== "all") ? useHookForm.getValues("company.idCompany") : null,
        idBusinessUnit: (useHookForm.getValues("businessUnit") && useHookForm.getValues("businessUnit.idBusinessUnit") !== "all") ? useHookForm.getValues("businessUnit.idBusinessUnit") : null,
        idDivision: (useHookForm.getValues("division") && useHookForm.getValues("division.idDivision") !== "all") ? useHookForm.getValues("division.idDivision") : null,
        idDepartment: (useHookForm.getValues("department") && useHookForm.getValues("department.idDepartment") !== "all") ? useHookForm.getValues("department.idDepartment") : null,
        idSection: (useHookForm.getValues("section") && useHookForm.getValues("section.idSection") !== "all") ? useHookForm.getValues("section.idSection") : null,
        idPosition: (useHookForm.getValues("position") && useHookForm.getValues("position.idPosition") !== "all") ? useHookForm.getValues("position.idPosition") : null,
        idManager: (useHookForm.getValues("manager") && useHookForm.getValues("manager.idEmployees") !== "all") ? useHookForm.getValues("manager.idEmployees") : null,
        per_page: rowsPerPage,
        page: 1,
        isTerminate: Number(newStatus),
      }
      setPage(0);
      dispatch(getEmployeeSearch(searchData)).then(res => {
        setTotal(res.data.pagination.total)
      })
    }
  };

  const renderAllDataSelect = (organization) => {

    let uniqueBusinessUnit = [
      ...new Map(
        organization.map((item) => [item["idBusinessUnit"], item])
      ).values(),
    ];

    setBusinessUnitList(uniqueBusinessUnit.filter((x) => x.idBusinessUnit));

    let uniqueDivision = [
      ...new Map(
        organization.map((item) => [item["idDivision"], item])
      ).values(),
    ];

    setDivisionList(uniqueDivision.filter((x) => x.idDivision));

    let uniqueDepartment = [
      ...new Map(
        organization.map((item) => [item["idDepartment"], item])
      ).values(),
    ];

    setDepartmentList(uniqueDepartment.filter((x) => x.idDepartment));

    let uniqueSection = [
      ...new Map(
        organization.map((item) => [item["idSection"], item])
      ).values(),
    ];

    setSectionList(uniqueSection.filter((x) => x.idSection));

    let uniquePosition = [
      ...new Map(
        organization.map((item) => [item["idPosition"], item])
      ).values(),
    ];

    setPositionList(uniquePosition.filter((x) => x.idPosition));
  };

  useEffect(() => {
    dispatch(getManager())
    dispatch(getAffiliateOrganization());
  }, [])

  useEffect(() => {
    if (affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      const foundCompany = affiliateOrganizationList.find(x => x.idCompany === userProfile.idCompany);
      if (foundCompany) {
        useHookForm.resetField("company", { defaultValue: foundCompany })
      } else {
        useHookForm.resetField("company", { defaultValue: affiliateOrganizationList[0] })
      }
      setExcelDataCompany(foundCompany ? foundCompany : affiliateOrganizationList[0]);
      renderAllDataSelect(foundCompany ? foundCompany.organization : affiliateOrganizationList[0].organization);
      dispatch(getEmployeeSearch({ idCompany: foundCompany ? foundCompany.idCompany : affiliateOrganizationList[0].idCompany })).then(res => {
        setTotal(res.data.pagination.total)
      });
    }
  }, [affiliateOrganizationList]);

  return (
    <CardStyle>
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} container justifyContent="space-between" alignItems="center">
              <StyledToggleButtonGroup
                value={isTerminate}
                exclusive
                onChange={handleChangeFilterEmployee}
                aria-label="filter employee"
                size="small"
              >
                <ToggleButton
                  name="status"
                  value={0}
                  aria-label="active"
                  size="small"
                  style={{ minWidth: 150 }}
                >
                  {t("NormalEmp")}
                </ToggleButton>
                <ToggleButton
                  name="status"
                  value={1}
                  aria-label="terminate"
                  size="small"
                  style={{ minWidth: 150 }}
                >
                  {t("EmpOut")}
                </ToggleButton>
              </StyledToggleButtonGroup>
              <Box>
                <Typography variant="h4" align="right">
                  {total}
                </Typography>
                <Typography
                  variant="body2"
                  align="right"
                  color="text.secondary"
                  style={{ fontWeight: 500 }}
                >
                  {t("All")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              <Link
                component="button"
                type="button"
                variant="body2"
                onClick={() => { setMoreFilter(!moreFilter) }}
              >
                {moreFilter ? `${t("HideSearch")}` : `${t("SearchMore")}`}
              </Link>
            </Grid>

            <Grid item xs={12} md={6}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("Search")}
                </Typography>
                <Controller
                  name="name"
                  control={useHookForm.control}
                  render={({ field, fieldState }) => (
                    <FormControl fullWidth variant="filled">
                      <StyledFilledInput
                        {...field}
                        placeholder={`${t("Search")}${t("FullName")}`}
                        name="search"
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  )}
                />
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("Company")}
                </Typography>
                <Controller
                  name="company"
                  control={useHookForm.control}
                  render={({ field, fieldState }) => (
                    <StyledAutocomplete
                      {...field}
                      options={[
                        { idCompany: "all", companyName: `${t("All")}`, companyName_EN: `${t("All")}` },
                        ...(affiliateOrganizationList ? affiliateOrganizationList : [])
                      ]}
                      getOptionLabel={(option) =>
                        i18n.resolvedLanguage === "th"
                          ? option.companyName
                          : option.companyName_EN
                      }
                      isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          variant="filled"
                          placeholder={t("Company")}
                          onBlur={field.onBlur}
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error ? fieldState.error.message : null}
                        />
                      )}
                      value={field.value}
                      onChange={(_, value) => {
                        field.onChange(value)
                        useHookForm.setValue("name", "")
                        useHookForm.setValue("businessUnit", { idBusinessUnit: "all", businessUnitName: `${t("All")}` })
                        useHookForm.setValue("division", { idDivision: "all", divisionName: `${t("All")}` })
                        useHookForm.setValue("department", { idDepartment: "all", departmentName: `${t("All")}` })
                        useHookForm.setValue("section", { idSection: "all", sectionName: `${t("All")}` })
                        useHookForm.setValue("position", { idPosition: "all", positionName: `${t("All")}` })
                        useHookForm.setValue("manager", { idEmployees: "all", firstname_TH: `${t("All")}`, lastname_TH: "" })

                        setExcelDataCompany(value);
                        fetchingEmployee();
                        if (value.idCompany !== "all") {
                          renderAllDataSelect(value.organization);
                        }
                      }}
                      noOptionsText={t("NoData")}
                      disableClearable
                    />
                  )}
                />
              </StyledBoxSearch>
            </Grid>

            {moreFilter && <Fragment>
              <Grid item xs={12} md={4}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("Business")}
                  </Typography>
                  <Controller
                    name="businessUnit"
                    control={useHookForm.control}
                    render={({ field, fieldState }) => (
                      <StyledAutocomplete
                        {...field}
                        options={[
                          { idBusinessUnit: "all", businessUnitName: `${t("All")}`, businessUnitName_EN: `${t("All")}` },
                          ...businessUnitList
                        ]}
                        getOptionLabel={(option) => `${option.businessUnitName}`}
                        renderOption={(props, option) => (
                          <MenuItem {...props} key={option.idBusinessUnit}>
                            <Box>
                              <Typography>
                                {i18n.resolvedLanguage === "th"
                                  ? option.businessUnitName
                                  : option.businessUnitName_EN}
                              </Typography>
                            </Box>
                          </MenuItem>
                        )}
                        isOptionEqualToValue={(option, value) => option.idBusinessUnit === value.idBusinessUnit}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            variant="filled"
                            placeholder={t("BusinessUnit")}
                            onBlur={field.onBlur}
                            error={fieldState.error ? true : false}
                            helperText={fieldState.error ? fieldState.error.message : null}
                          />
                        )}
                        value={field.value}
                        onChange={(_, value) => {
                          field.onChange(value)
                          useHookForm.setValue("division", { idDivision: "all", divisionName: `${t("All")}` })
                          useHookForm.setValue("department", { idDepartment: "all", departmentName: `${t("All")}` })
                          useHookForm.setValue("section", { idSection: "all", sectionName: `${t("All")}` })
                          useHookForm.setValue("position", { idPosition: "all", positionName: `${t("All")}` })
                        }}
                        noOptionsText={t("NoData")}
                        disableClearable
                      />
                    )}
                  />
                </StyledBoxSearch>
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("Division")}
                  </Typography>
                  <Controller
                    name="division"
                    control={useHookForm.control}
                    render={({ field, fieldState }) => (
                      <StyledAutocomplete
                        {...field}
                        options={[
                          { idDivision: "all", divisionName: `${t("All")}`, divisionName_EN: `${t("All")}` },
                          ...(divisionList
                            ? divisionList.filter((x) => {
                              return (
                                x
                                && (useHookForm.getValues("businessUnit").idBusinessUnit === "all" || useHookForm.getValues("businessUnit").idBusinessUnit === x.idBusinessUnit)
                              )
                            })
                            : []),
                        ]}
                        getOptionLabel={(option) => `${option.divisionName}`}
                        renderOption={(props, option) => (
                          <MenuItem {...props} key={option.idDivision}>
                            <Box>
                              <Typography>
                                {i18n.resolvedLanguage === "th"
                                  ? option.divisionName
                                  : option.divisionName_EN}
                              </Typography>
                            </Box>
                          </MenuItem>
                        )}
                        isOptionEqualToValue={(option, value) => option.idDivision === value.idDivision}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            variant="filled"
                            placeholder={t("Division")}
                            onBlur={field.onBlur}
                            error={fieldState.error ? true : false}
                            helperText={fieldState.error ? fieldState.error.message : null}
                          />
                        )}
                        value={field.value}
                        onChange={(_, value) => {
                          field.onChange(value)
                          if (value.idDivision !== "all") {
                            useHookForm.setValue(
                              "businessUnit",
                              businessUnitList && businessUnitList.length > 0
                                ? businessUnitList.find(
                                  (x) => x.idBusinessUnit === value.idBusinessUnit
                                ) || { idBusinessUnit: "all", businessUnitName: `${t("All")}` }
                                : { idBusinessUnit: "all", businessUnitName: `${t("All")}` },
                            )
                          }
                          useHookForm.setValue("department", { idDepartment: "all", departmentName: `${t("All")}` })
                          useHookForm.setValue("section", { idSection: "all", sectionName: `${t("All")}` })
                          useHookForm.setValue("position", { idPosition: "all", positionName: `${t("All")}` })
                        }}
                        noOptionsText={t("NoData")}
                        disableClearable
                      />
                    )}
                  />
                </StyledBoxSearch>
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("Department")}
                  </Typography>
                  <Controller
                    name="department"
                    control={useHookForm.control}
                    render={({ field, fieldState }) => (
                      <StyledAutocomplete
                        {...field}
                        options={[
                          { idDepartment: "all", departmentName: `${t("All")}`, departmentName_EN: `${t("All")}` },
                          ...(departmentList
                            ? departmentList.filter((x) => {
                              return (
                                x
                                && (useHookForm.getValues("businessUnit").idBusinessUnit === "all" || useHookForm.getValues("businessUnit").idBusinessUnit === x.idBusinessUnit)
                                && (useHookForm.getValues("division").idDivision === "all" || useHookForm.getValues("division").idDivision === x.idDivision)
                              )
                            })
                            : []),
                        ]}
                        getOptionLabel={(option) => `${option.departmentName}`}
                        renderOption={(props, option) => (
                          <MenuItem {...props} key={option.idDepartment}>
                            <Box>
                              <Typography>
                                {i18n.resolvedLanguage === "th"
                                  ? option.departmentName
                                  : option.departmentName_EN}
                              </Typography>
                            </Box>
                          </MenuItem>
                        )}
                        isOptionEqualToValue={(option, value) => option.idDepartment === value.idDepartment}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            variant="filled"
                            placeholder={t("Department")}
                            onBlur={field.onBlur}
                            error={fieldState.error ? true : false}
                            helperText={fieldState.error ? fieldState.error.message : null}
                          />
                        )}
                        value={field.value}
                        onChange={(_, value) => {
                          field.onChange(value)
                          if (value.idDepartment !== "all") {
                            useHookForm.setValue(
                              "businessUnit",
                              businessUnitList && businessUnitList.length > 0
                                ? businessUnitList.find(
                                  (x) => x.idBusinessUnit === value.idBusinessUnit
                                ) || { idBusinessUnit: "all", businessUnitName: `${t("All")}` }
                                : { idBusinessUnit: "all", businessUnitName: `${t("All")}` },
                            )
                            useHookForm.setValue(
                              "division",
                              divisionList && divisionList.length > 0
                                ? divisionList.find(
                                  (x) => x.idDivision === value.idDivision
                                ) || { idDivision: "all", divisionName: `${t("All")}` }
                                : { idDivision: "all", divisionName: `${t("All")}` },
                            )
                          }
                          useHookForm.setValue("section", { idSection: "all", sectionName: `${t("All")}` })
                          useHookForm.setValue("position", { idPosition: "all", positionName: `${t("All")}` })
                        }}
                        noOptionsText={t("NoData")}
                        disableClearable
                      />
                    )}
                  />
                </StyledBoxSearch>
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("Section")}
                  </Typography>
                  <Controller
                    name="section"
                    control={useHookForm.control}
                    render={({ field, fieldState }) => (
                      <StyledAutocomplete
                        {...field}
                        options={[
                          { idSection: "all", sectionName: `${t("All")}`, sectionName_EN: `${t("All")}` },
                          ...(sectionList
                            ? sectionList.filter((x) => {
                              return (
                                x
                                && (useHookForm.getValues("businessUnit").idBusinessUnit === "all" || useHookForm.getValues("businessUnit").idBusinessUnit === x.idBusinessUnit)
                                && (useHookForm.getValues("division").idDivision === "all" || useHookForm.getValues("division").idDivision === x.idDivision)
                                && (useHookForm.getValues("department").idDepartment === "all" || useHookForm.getValues("department").idDepartment === x.idDepartment)
                              )
                            })
                            : []),
                        ]}
                        getOptionLabel={(option) => `${option.sectionName}`}
                        renderOption={(props, option) => (
                          <MenuItem {...props} key={option.idSection}>
                            <Box>
                              <Typography>
                                {i18n.resolvedLanguage === "th"
                                  ? option.sectionName
                                  : option.sectionName_EN}
                              </Typography>
                            </Box>
                          </MenuItem>
                        )}
                        isOptionEqualToValue={(option, value) => option.idSection === value.idSection}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            variant="filled"
                            placeholder={t("Section")}
                            onBlur={field.onBlur}
                            error={fieldState.error ? true : false}
                            helperText={fieldState.error ? fieldState.error.message : null}
                          />
                        )}
                        value={field.value}
                        onChange={(_, value) => {
                          field.onChange(value)
                          if (value.idSection !== "all") {
                            useHookForm.setValue(
                              "businessUnit",
                              businessUnitList && businessUnitList.length > 0
                                ? businessUnitList.find(
                                  (x) => x.idBusinessUnit === value.idBusinessUnit
                                ) || { idBusinessUnit: "all", businessUnitName: `${t("All")}` }
                                : { idBusinessUnit: "all", businessUnitName: `${t("All")}` },
                            )
                            useHookForm.setValue(
                              "division",
                              divisionList && divisionList.length > 0
                                ? divisionList.find(
                                  (x) => x.idDivision === value.idDivision
                                ) || { idDivision: "all", divisionName: `${t("All")}` }
                                : { idDivision: "all", divisionName: `${t("All")}` },
                            )
                            useHookForm.setValue(
                              "department",
                              departmentList && departmentList.length > 0
                                ? departmentList.find(
                                  (x) => x.idDepartment === value.idDepartment
                                ) || { idDepartment: "all", departmentName: `${t("All")}` }
                                : { idDepartment: "all", departmentName: `${t("All")}` },
                            )
                          }
                          useHookForm.setValue("position", { idPosition: "all", positionName: `${t("All")}` })
                        }}
                        noOptionsText={t("NoData")}
                        disableClearable
                      />
                    )}
                  />
                </StyledBoxSearch>
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("Position")}
                  </Typography>
                  <Controller
                    name="position"
                    control={useHookForm.control}
                    render={({ field, fieldState }) => (
                      <StyledAutocomplete
                        {...field}
                        options={[
                          { idPosition: "all", positionName: `${t("All")}`, positionName_EN: `${t("All")}` },
                          ...(positionList
                            ? positionList.filter((x) => {
                              return (
                                x
                                && (useHookForm.getValues("businessUnit").idBusinessUnit === "all" || useHookForm.getValues("businessUnit").idBusinessUnit === x.idBusinessUnit)
                                && (useHookForm.getValues("division").idDivision === "all" || useHookForm.getValues("division").idDivision === x.idDivision)
                                && (useHookForm.getValues("department").idDepartment === "all" || useHookForm.getValues("department").idDepartment === x.idDepartment)
                                && (useHookForm.getValues("section").idSection === "all" || useHookForm.getValues("section").idSection === x.idSection)
                              )
                            })
                            : []),
                        ]}
                        getOptionLabel={(option) => `${option.positionName}`}
                        renderOption={(props, option) => (
                          <MenuItem {...props} key={option.idPosition}>
                            <Box>
                              <Typography>
                                {i18n.resolvedLanguage === "th"
                                  ? option.positionName
                                  : option.positionName_EN}
                              </Typography>
                            </Box>
                          </MenuItem>
                        )}
                        isOptionEqualToValue={(option, value) => option.idPosition === value.idPosition}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            variant="filled"
                            placeholder={t("Position")}
                            onBlur={field.onBlur}
                            error={fieldState.error ? true : false}
                            helperText={fieldState.error ? fieldState.error.message : null}
                          />
                        )}
                        value={field.value}
                        onChange={(_, value) => {
                          field.onChange(value)
                          if (value.idPosition !== "all") {
                            useHookForm.setValue(
                              "businessUnit",
                              businessUnitList && businessUnitList.length > 0
                                ? businessUnitList.find(
                                  (x) => x.idBusinessUnit === value.idBusinessUnit
                                ) || { idBusinessUnit: "all", businessUnitName: `${t("All")}` }
                                : { idBusinessUnit: "all", businessUnitName: `${t("All")}` },
                            )
                            useHookForm.setValue(
                              "division",
                              divisionList && divisionList.length > 0
                                ? divisionList.find(
                                  (x) => x.idDivision === value.idDivision
                                ) || { idDivision: "all", divisionName: `${t("All")}` }
                                : { idDivision: "all", divisionName: `${t("All")}` },
                            )
                            useHookForm.setValue(
                              "department",
                              departmentList && departmentList.length > 0
                                ? departmentList.find(
                                  (x) => x.idDepartment === value.idDepartment
                                ) || { idDepartment: "all", departmentName: `${t("All")}` }
                                : { idDepartment: "all", departmentName: `${t("All")}` },
                            )
                            useHookForm.setValue(
                              "section",
                              sectionList && sectionList.length > 0
                                ? sectionList.find(
                                  (x) => x.idSection === value.idSection
                                ) || { idSection: "all", sectionName: `${t("All")}` }
                                : { idSection: "all", sectionName: `${t("All")}` },
                            )
                          }
                        }}
                        noOptionsText={t("NoData")}
                        disableClearable
                      />
                    )}
                  />
                </StyledBoxSearch>
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("Manager")}
                  </Typography>
                  <Controller
                    name="manager"
                    control={useHookForm.control}
                    render={({ field, fieldState }) => (
                      <StyledAutocomplete
                        {...field}
                        options={[
                          { idEmployees: "all", firstname_TH: `${t("All")}`, lastname_TH: "" },
                          ...(manager ? manager : [])
                        ]}
                        getOptionLabel={(option) => `${option.firstname_TH}${option.lastname_TH ? " " + option.lastname_TH : ""}`}
                        renderOption={(props, option) => (
                          <MenuItem {...props} key={option.idEmployees}>
                            <Box>
                              <Typography>
                                {getUserFullName(option)}
                              </Typography>
                            </Box>
                          </MenuItem>
                        )}
                        isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            variant="filled"
                            placeholder={t("Manager")}
                            onBlur={field.onBlur}
                            error={fieldState.error ? true : false}
                            helperText={fieldState.error ? fieldState.error.message : null}
                          />
                        )}
                        value={field.value}
                        onChange={(_, value) => {
                          field.onChange(value)
                        }}
                        noOptionsText={t("NoData")}
                        disableClearable
                      />
                    )}
                  />
                </StyledBoxSearch>
              </Grid>
            </Fragment>}

            <Grid item xs={12} container justifyContent="flex-end">
              <ButtonBlue variant="contained" type="submit">{t("Search")}</ButtonBlue>
            </Grid>
          </Grid>
        </form>

        <Box marginTop="24px">
          <TableContainer style={{ width: "100%", maxHeight: 550 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <StyledCellHeader
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      className={column.className}
                    >
                      {column.label}
                    </StyledCellHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {employeeSearch && employeeSearch.data ? (
                  <Fragment>
                    {employeeSearch && employeeSearch.data && employeeSearch.data.length > 0 ? (
                      employeeSearch.data.map((item, index) => (
                        <StyledRowContent key={item.idEmployees}>
                          <StyledCellContent className="sticky" key={"name"}>
                            <Box
                              aria-label="more"
                              onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                setValueSelected(item);
                              }}
                              className="fullname"
                            >
                              <IconButton
                                aria-label="more"
                                size="small"
                                style={{ marginRight: 8 }}
                                onClick={(event) => {
                                  setAnchorEl(event.currentTarget);
                                  setValueSelected(item);
                                }}
                              >
                                <MoreVertIcon fontSize="small" />
                              </IconButton>
                              <Avatar
                                alt={item.firstname_TH}
                                src={`${item.imageProfile}` || ""}
                              />
                              <div>
                                <Typography>{getUserFullName(item)}</Typography>
                                <Typography variant="body2" color="text.third">
                                  {item.employeeID}
                                  {/* {item.telephoneMobile &&
                                    `${item.telephoneMobile.replace(
                                      /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                                      "$1-$2-$3"
                                    )}`} */}
                                </Typography>
                              </div>
                            </Box>
                          </StyledCellContent>
                          <StyledCellContent key={"company"}>
                            <Typography className="company">
                              {getUserCompany(item)}
                            </Typography>
                          </StyledCellContent>
                          <StyledCellContent key={"position"}>
                            <Typography className="position">
                              {getUserPosition(item)}
                            </Typography>
                            <Typography
                              className="department"
                              color="text.third"
                            >
                              {getUserDepartment(item)}
                            </Typography>
                          </StyledCellContent>
                          <StyledCellContent key={"email"}>
                            <Typography>{item.email}</Typography>
                          </StyledCellContent>
                          <StyledCellContent key={"report_To_LV1"}>
                            <Typography className="reportTo">
                              {item.idManagerLV1 ? getUserFullName({
                                firstname_TH: item.managerLV1_firstname_TH,
                                lastname_TH: item.managerLV1_lastname_TH,
                                firstname_EN: item.managerLV1_firstname_EN,
                                lastname_EN: item.managerLV1_lastname_EN,
                              }) : null}
                            </Typography>
                            <Typography
                              className="reportToPosition"
                              color="text.third"
                            >
                              {item.managerLV1_email}
                            </Typography>
                          </StyledCellContent>
                          <StyledCellContent key={"report_To_LV2"}>
                            <Typography className="reportTo">
                              {item.idManagerLV2 ? getUserFullName({
                                firstname_TH: item.managerLV2_firstname_TH,
                                lastname_TH: item.managerLV2_lastname_TH,
                                firstname_EN: item.managerLV2_firstname_EN,
                                lastname_EN: item.managerLV2_lastname_EN,
                              }) : null}
                            </Typography>
                            <Typography
                              className="reportToPosition"
                              color="text.third"
                            >
                              {item.managerLV2_email}
                            </Typography>
                          </StyledCellContent>
                        </StyledRowContent>
                      ))
                    ) : (
                      <StyledRowContent>
                        <StyledCellContent colSpan={5} style={{ height: 150 }}>
                          <Typography align="center">{t("NoEmployee")}</Typography>
                        </StyledCellContent>
                      </StyledRowContent>
                    )}
                  </Fragment>
                ) : (
                  <StyledRowContent>
                    <StyledCellContent colSpan={5} style={{ height: 150 }}>
                      <Typography align="center">{t("LoadingEmpData")}</Typography>
                    </StyledCellContent>
                  </StyledRowContent>
                )}

              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={total}
            // count={(employeeSearch && employeeSearch.pagination)? employeeSearch.pagination.total: 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${
                            count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
          />
        </Box>
        <StyledMenu
          id={`more-menu`}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => { setAnchorEl(null) }}
        >
          {valueSelected && (
            <Fragment>
              <MenuItem
                component={NavLink}
                to={`/employees/${valueSelected.idEmployees}/edit`}
              >
                <i className="far fa-user-edit" style={{ marginRight: 16 }}></i>
                <Typography>{t("EditData")}</Typography>
              </MenuItem>
            </Fragment>
          )}
        </StyledMenu>
      </StyledRoot>
    </CardStyle>
  )
}

export default EmployeeSearchPage