import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
const ChartTax = (props) => {
  const {t,i18n} = useTranslation();
  const { Tax, JanValue, year } = props;

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        height: 328,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      series: [],
      title: {
        text: t("TotalCompanyContribution"),
        align: "left",
        offsetY: 25,
        offsetX: 20,
      },
      subtitle: {
        text: t("Statistics"),
        offsetY: 55,
        offsetX: 20,
      },
      markers: {
        size: 6,
        strokeWidth: 0,
        hover: {
          size: 9,
        },
      },
      grid: {
        show: true,
        padding: {
          bottom: 0,
        },
      },
      labels: [
        t("Months.January"),
        t("Months.February"),
        t("Months.March"),
        t("Months.April"),
        t("Months.May"),
        t("Months.June"),
        t("Months.July"),
        t("Months.August"),
        t("Months.September"),
        t("Months.October"),
        t("Months.November"),
        t("Months.December"),
      ],
      xaxis: {
        tooltip: {
          enabled: false,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        offsetY: -20,
      },
    },
  });

  useEffect(() => {
    const newSeries = [
      {
        name: t("TaxRemitted"),
        data: year < 2024
          ? [{ month: "01", value: JanValue }, ...Tax].map(i => i.value)
          : Object.values(Tax).map(i => i.value),
      },
    ];
  
    setChartData(prevState => ({
      ...prevState,
      options: {
        ...prevState.options,
        series: newSeries,
      },
    }));
  }, [Tax, JanValue]);

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={chartData.options}
            series={chartData.options.series}
            type="line"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
};

export default ChartTax;
