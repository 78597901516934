import {
  JOBPOSTING_FAILED,
  JOBPOSTING_SUCCESS,
  JOBPOSTING_FETCHING,
} from "./types";

import JobPostingService from "../services/jobPosting.service";

export const getAllJobPosting = () => async (dispatch) => {
  try {
    const res = await JobPostingService.getAllJobPosting();
    if (res) {
      dispatch({
        type: JOBPOSTING_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: JOBPOSTING_FAILED,
    });
    console.log(err);
  }
};
