import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

import ButtonBlue from "./ButtonBlue";

const StyledDialog = styled(Dialog)({
  "& .MuiDialogContent-root": {
    padding: 0,
    "& .content": {
      padding: 16,
      textAlign: "center",
      "& .warning-icon-label": {
        fontSize: 90,
        color: "#ffab00",
      },
      "& .header-label": {
        padding: "20px 0",
      },
      "& .detail": {
        fontSize: 16,
        "& span": {
          color: "#ffab00",
          fontSize: 20,
        },
      },
    },
  },
});

const DialogConfirmChange = (props) => {
  const { open, handleClose, text, text1, handleUpdateAdmin, text2 } =
    props;
  const { t, i18n } = useTranslation();

  return (
    <StyledDialog fullWidth maxWidth={"xs"} open={open} onClose={handleClose}>
      <DialogContent>
        <div
          style={{ width: "100%", height: 6, backgroundColor: "#ffab00" }}
        ></div>
        <div className="content">
          <WarningAmberRoundedIcon className="warning-icon-label" />
          <Typography variant="h5" className="header-label" color="text.third">
            คุณแน่ใจใช่ไหม?
          </Typography>

          <Typography className="detail" variant="h6" color="text.secondary">
            {text}
          </Typography>
          <Typography className="detail" variant="h6" color="text.secondary">
            {text1}
          </Typography>
          <Typography className="detail" variant="h6" color="text.third">
            {text2}
          </Typography>

          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <ButtonBlue
              color="secondary"
              variant="text"
              style={{ marginRight: "10px" }}
              onClick={handleClose}
            >
              ยกเลิก
            </ButtonBlue>
            <ButtonBlue
              color="warning"
              variant="contained"
              onClick={handleUpdateAdmin}
            >
              บันทึกเปลี่ยนแปลง
            </ButtonBlue>
          </Box>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

export default DialogConfirmChange;
