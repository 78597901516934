import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Grid, Rating, styled } from "@mui/material";
import DrawerCustom from "../../../../shared/general/Drawer";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import { getNewCandidatesByIdJobRequest, updateRatingAndNoteByIdCandidate } from "../../../../../../actions/candidate";

const StyledRoot = styled("div")({
  width: 450,
  padding: 16,
  "& .part-footer": {
    paddingTop: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .cancel-button": {
      color: "#9e9e9e",
      borderColor: "#9e9e9e",
    },
    "& i": {
      marginRight: 8,
    }
  },
  "& .MuiRating-root": {
    fontSize: 40,
  }
})

const DrawerRating = (props) => {
  const { open, onClose, value, hanldeSubmit } = props;
  const dispatch = useDispatch();

  const [ratingValue, setRatingValue] = useState(value.value);

  const onChangeRatingHandler = (event, newValue) => {
    setRatingValue(newValue)
  }

  const onSubmit = async() => {
    const res = await dispatch(updateRatingAndNoteByIdCandidate(value.editId,{ rating: ratingValue }));
    if(res){
      hanldeSubmit();
      onClose();
    }
  }

  return(
    <DrawerCustom
      open={open}
      title="คะแนน"
      anchor="right"
    >
      <StyledRoot>
        <Grid container justifyContent="center">
          <Rating
            value={ratingValue}
            onChange={onChangeRatingHandler}
            precision={0.5}
            emptyIcon={<i className="fa-regular fa-star"></i>}
            icon={<i className="fa-solid fa-star"></i>}
          />
        </Grid>

        <div className="part-footer">
          <ButtonBlue className="cancel-button" variant="outlined" onClick={onClose}><i className="fa-regular fa-x"></i>ยกเลิก</ButtonBlue>
          <ButtonBlue onClick={onSubmit} variant="contained"><i className="fa-regular fa-check"></i>ยืนยัน</ButtonBlue>
        </div>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerRating;