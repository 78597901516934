import React from 'react';

import {
    Box,
    Dialog,
    DialogContent,
    Typography
} from "@mui/material";
import ButtonBlue from '../../../../../shared/general/ButtonBlue';

const DialogConfirmDelete = (props) => {
    const { open, onClose, isEducation, isDeleteManpower, onSubmit } = props;
    // console.log(isDeleteManpower);
    // console.log(onSubmit);
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogContent>
                <Typography variant="h4">ยืนยันการลบ</Typography>
                <Box
                    style={{
                        width: "350px",
                        height: "80px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between"
                    }}
                >
                    <Typography variant="body1">
                        {isDeleteManpower ? `ต้องการลบผู้ใข้งานคนนี้หรือไม่` : `ต้องการลบ${isEducation ? "ประวัติการศึกษา" : "ประสบการณ์ทำงาน"}นี้หรือไม่`}
                        {/* {`ต้องการลบ${isEducation ? "ประวัติการศึกษา" : "ประสบการณ์ทำงาน"}นี้หรือไม่`} */}
                    </Typography>
                    <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                        <ButtonBlue
                            variant="contained"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                                onSubmit();
                                onClose();
                            }}
                        >ยืนยัน</ButtonBlue>
                        <ButtonBlue
                            variant="outlined"
                            onClick={onClose}
                        >ยกเลิก</ButtonBlue>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default DialogConfirmDelete;