import {
  Avatar,
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import SelectAffiliateCompany from "../../../shared/general/SelectAffiliateCompany";
import DatePickerCustom from "../../../shared/date/datePicker";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { getAffiliateOrganization } from "../../../../../actions/company";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import dayjs from "dayjs";
import CardStyle from "../../../shared/general/Card";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { getLevelingReport } from "../../../../../actions/report";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { ExportExcel } from "./exportExcel";
import LoadingGif from "../../../assets/social-media.gif";
import AlertResponse from "../../../shared/general/AlertResponse";
import { getUserFullName, getUserPosition } from "../../../../../utils/userData";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  "& .MuiFilledInput-root": {
    height: 56,
    padding: "0px 12px",
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
  },
  "& .search-date-container": {
    marginTop: "24px",
    display: "flex",
    flexWrap: "nowrap",
    gap: "16px",
    "& .search-date-box": {
      maxWidth: "350px",
      width: "100%",
    },
    "& .search-button": {
      marginTop: "29px",
      display: "flex",
      alignItems: "center",
    },
  },

  ["@media only screen and (max-width: 600px)"]: {
    "& .search-date-container": {
      flexDirection: "column",
      "& .search-date-box": {
        maxWidth: "100%",
      },
      "& .search-button": {
        marginTop: "0",
        width: "100%",
      },
    },
  },
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "0px",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  padding: 8,
  minWidth: 50,
  "& .MuiTypography-root": {
    color: "#637381",
    backgroundColor: "#f4f6f8",
    "&.weekend": {
      fontStyle: "oblique",
    },
    "&.workday": {
      fontWeight: 600,
    },
  },
  "&.sticky": {
    padding: 0,
    position: "sticky",
    left: 0,
    zIndex: 4,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "10px 0px 10px #EEEEEE",
    "& .MuiTableCell-root": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "&:last-of-type": {
    paddingRight: 16,
    // borderTopRightRadius: 8,
    // borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "0px",
  padding: 8,
  minWidth: 50,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .avatar": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .fullname": {
    fontWeight: 600,
  },
  "& .position": {
    color: theme.palette.text.secondary,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .secondaryAction": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .location": {
      fontWeight: 600,
    },
  },
  "&:first-of-type": {
    paddingLeft: 8,
  },
  "&:last-of-type": {
    paddingRight: 16,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
}));

const LevelingReport = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );

  const [selectedCompany, setSelectedCompany] = useState(null);

  const [filter, setFilter] = useState({
    month: null,
    year: new Date(dayjs()),
  });
  const [levelData, setLevelData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    count: 0,
    rowsPerPage: 25,
  });

  const handleChangePage = (_, newPage) => {
    setPagination((prev) => ({
      ...prev,
      page: newPage,
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination((prev) => ({
      ...prev,
      page: 0,
      rowsPerPage: Number(event.target.value),
    }));
  };

  const columns = [
    { id: "JobPosition", label: `${t("JobPosition")}`, minWidth: 170 },
    { id: "Division", label: `${t("Division")}`, minWidth: 170 },
    { id: "Department", label: `${t("Department")}`, minWidth: 170 },
    { id: "Section", label: `${t("Section")}`, minWidth: 100 },
    { id: "oldLevel", label: `${t("oldLevel")}`, minWidth: 170 },
    { id: "newLevel", label: `${t("newLevel")}`, minWidth: 170 },
    { id: "Note", label: `${t("Note")}`, minWidth: 170 },
    { id: "EffectiveDate", label: `${t("EffectiveDate")}`, minWidth: 170 },
  ];

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (
      userProfile &&
      affiliateOrganizationList &&
      affiliateOrganizationList.length > 0
    ) {
      const foundCompany = affiliateOrganizationList.find(
        (x) => x.idCompany === userProfile.idCompany
      );
      if (foundCompany) {
        setSelectedCompany(foundCompany);
      } else {
        setSelectedCompany(affiliateOrganizationList[0]);
      }
    }
  }, [userProfile, affiliateOrganizationList]);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const fetchedData = async (idCompany, month, year) => {
    try {
      setIsFetching(true);
      const response = await getLevelingReport({
        idCompany: idCompany,
        month: month,
        year: year,
      });
      if (response && response.status === 200 && response.data && response.data.length > 0) {
        setIsFetching(false);
        setLevelData(response.data);
        setPagination((prev) => ({
          ...prev,
          page: 0,
          count: response.data ? response.data.length : 0,
        }));
      } else {
        setIsFetching(false);
        setLevelData(null);
        handleOpenAlert(true);
        handleChangeAlertType(t("NoData"));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickSearch = () => {
    if (selectedCompany) {
      fetchedData(
        selectedCompany.idCompany,
        filter.month ? dayjs(filter.month).format("MM") : null,
        dayjs(filter.year).format("YYYY")
      );
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      fetchedData(
        selectedCompany.idCompany,
        null,
        dayjs(filter.year).format("YYYY")
      );
    }
  }, [selectedCompany, filter]);

  return (
    <StyledRoot className="page">
      <Container
        maxWidth="lg"
        style={{ paddingTop: "24px", paddingBottom: "24px" }}
      >
        <Box marginBottom="24px">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={"/employees-report"}
            >
              {`${t("AllReports")}`}
            </Link>
            <Typography color="text.primary">{`${t(
              "EmployeeUpdatedLevel"
            )}`}</Typography>
          </Breadcrumbs>
        </Box>

        <Typography variant="h4">{t("EmployeeUpdatedLevel")}</Typography>

        <Box marginTop="24px">
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                paddingBottom="8px"
                color="text.third"
              >
                {t("Company")}
              </Typography>
              <SelectAffiliateCompany
                options={affiliateOrganizationList}
                value={selectedCompany}
                onChange={(_, value) => {
                  setSelectedCompany(value);
                }}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                paddingBottom="8px"
                color="text.third"
              >
                {t("Year")}
              </Typography>

              <DatePickerCustom
                inputFormat="YYYY"
                value={filter.year}
                openTo="year"
                views={["year"]}
                onChange={(newValue) => {
                  setFilter((prev) => ({
                    ...prev,
                    year: newValue,
                    month: null,
                  }));
                }}
                disableFuture
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    variant="filled"
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6} md={3}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                paddingBottom="8px"
                color="text.third"
              >
                {t("Month")}
              </Typography>
              <DatePickerCustom
                inputFormat="MMMM"
                value={filter.month}
                openTo="month"
                views={["month"]}
                onChange={(newValue) => {
                  setFilter((prev) => ({
                    ...prev,
                    month: newValue,
                  }));
                }}
                minDate={dayjs(filter.year).set("month", 0)}
                maxDate={dayjs()}
                onOpen={() => {
                  setFilter((prev) => ({
                    ...prev,
                    month:
                      prev.month === null
                        ? dayjs().set("year", dayjs(prev.year).format("YYYY"))
                        : prev.month,
                  }));
                }}
                defaultCalendarMonth={dayjs(filter.year).set("month", 0)}
                componentsProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    variant="filled"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: t("Month"),
                      readOnly: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Box paddingTop="29px" display="flex">
                <Box height="56px" display="flex" alignItems="center">
                  <ButtonBlue
                    startIcon={<SearchRoundedIcon />}
                    variant="contained"
                    fullWidth
                    onClick={handleClickSearch}
                  >
                    {t("Search")}
                  </ButtonBlue>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box marginTop="24px">
          <CardStyle>
            <Box padding="24px">
              {!isFetching ? (
                <Box>
                  {levelData && levelData.length > 0 && (
                    <Box>
                      <Box
                        marginBottom="16px"
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <ButtonBlue
                          variant="outlined"
                          startIcon={<DownloadRoundedIcon />}
                          onClick={() => ExportExcel(t, levelData)}
                        // onClick={onDownloadExcel}
                        // disabled={isLoadingExcel}
                        >
                          {t("Download")}
                        </ButtonBlue>
                      </Box>
                      <Box style={{ display: "flex", position: "relative" }}>
                        <TableContainer
                          style={{ width: "100%", maxHeight: 600 }}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead
                              sx={{
                                position: "sticky",
                                top: 0,
                                zIndex: 1,
                              }}
                            >
                              <TableRow>
                                <StyledCellHeader
                                  align={"center"}
                                  className="sticky"
                                  style={{ minWidth: 240 }}
                                  rowSpan={2}
                                >
                                  {`${t("FullName")}`}
                                </StyledCellHeader>

                                {columns.map((column) => (
                                  <StyledCellHeader
                                    key={column.id}
                                    align="center"
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    {column.label}
                                  </StyledCellHeader>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {levelData
                                .slice(
                                  pagination.page * pagination.rowsPerPage,
                                  (pagination.page + 1) * pagination.rowsPerPage
                                )
                                .map((item, index) => (
                                  <StyledRowContent>
                                    <StyledCellContent
                                      key={`${index}`}
                                      className="sticky"
                                      style={{
                                        borderBottom:
                                          "1px dashed rgba(224, 224, 224, 1)",
                                      }}
                                    >
                                      <Box className="avatar">
                                        <Avatar
                                          src={
                                            item.imageProfile
                                              ? item.imageProfile
                                              : ""
                                          }
                                        />
                                        <Typography className="fullname">
                                          {`${getUserFullName(item)
                                            }`}
                                        </Typography>
                                      </Box>
                                    </StyledCellContent>
                                    <StyledCellContent align="center">
                                      {getUserPosition(item) || "-"}
                                    </StyledCellContent>
                                    <StyledCellContent align="center">
                                      {item.divisionName || "-"}
                                    </StyledCellContent>
                                    <StyledCellContent align="center">
                                      {item.departmentName || "-"}
                                    </StyledCellContent>
                                    <StyledCellContent align="center">
                                      {item.sectionName || "-"}
                                    </StyledCellContent>
                                    <StyledCellContent align="center">
                                      {item.oldLevel || "-"}
                                    </StyledCellContent>
                                    <StyledCellContent align="center">
                                      {item.newLevel || "-"}
                                    </StyledCellContent>
                                    <StyledCellContent align="center">
                                      {item.remark || "-"}
                                    </StyledCellContent>
                                    <StyledCellContent align="center">
                                      {item.updatedDate || "-"}
                                    </StyledCellContent>
                                  </StyledRowContent>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                      <TablePagination
                        rowsPerPageOptions={[25]}
                        component="div"
                        count={pagination.count}
                        rowsPerPage={25}
                        page={pagination.page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
                      />
                    </Box>
                  )}
                </Box>
              ) : (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <img src={LoadingGif} style={{ width: 100, height: 100 }} />
                </Box>
              )}

              {!levelData && !isFetching && (
                <Typography
                  textAlign="center"
                  color="text.secondary"
                  fontSize="24px"
                >
                  {t(`NoData`)}
                </Typography>
              )}
            </Box>
          </CardStyle>
        </Box>
      </Container>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </StyledRoot>
  );
};

export default LevelingReport;
