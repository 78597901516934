import { Alert, Snackbar } from "@mui/material";
import React from "react";

const SnackBarCustom = (props) => {

  const { config, setConfig } = props;

  const onClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfig({
      ...config,
      open: false,
    });
  };

  return (
    <Snackbar
      open={config.open}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={onClose}
    >
      <Alert severity={config.type} onClose={onClose}>
        {config.message}
      </Alert>
    </Snackbar>

  )
}

export default SnackBarCustom;