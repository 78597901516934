import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../shared/general/ButtonBlue";

const RewardButton = ({ pending, ready, complete, cancel }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        mt: 2,
      }}
    >
      <ButtonBlue variant="contained" onClick={pending} fullWidth>
        {`${t("PendingReview")}`}
      </ButtonBlue>
      <ButtonBlue variant="contained" onClick={ready} fullWidth>
        {`${t("Success")}`}
      </ButtonBlue>
      <ButtonBlue variant="contained" onClick={complete} fullWidth>
        {`${t("Completed")}`}
      </ButtonBlue>
      <ButtonBlue variant="contained" onClick={cancel} fullWidth>
        {`${t("Failure")}`}
      </ButtonBlue>
    </Box>
  );
};

export default RewardButton;
