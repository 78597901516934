import { Avatar, Box, TablePagination, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChipWithDrawCustom from "../../shared/chipWithDrawCustom";
import TableCustom from "../../shared/tableCustom";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import MenuStatus from "./MenuStatus";
import DialogConfirmReject from "../approveList/DialogConfirmReject";
import {
  approveRequestTime,
  getAllRequestTimeBy,
  getAllRequestTimeWithDrawBy,
} from "../../../../actions/requestTime";

const RequestOverTime = ({ searchDate, setSnackBarConfig }) => {
  const { result: requestTimeList } = useSelector((state) => state.requestTime);
  const { result: requestTimeWithDrawList } = useSelector(
    (state) => state.requestTimeWithDraw
  );
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [listPerPage, setListPerPage] = useState(10);
  const [historyRows, setHistoryRows] = useState([]);
  const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);
  const [selectRequest, setSelectRequest] = useState(null);

  const { t, i18n } = useTranslation();

  const handleChangeStatus = (request, approve) => {
    setSelectRequest(request);
    if (approve === 0) {
      setIsOpenRejectDialog(true);
    } else {
      handleApprovalRequest(request, 1);
    }
  };

  const handleSubmit = async (formData) => {
    const result = await dispatch(approveRequestTime("manager", formData));
    if (result) {
      if (result.status === 200) {
        dispatch(getAllRequestTimeBy("manager", searchDate));
        dispatch(getAllRequestTimeWithDrawBy("manager", searchDate));
        setSnackBarConfig({
          open: true,
          message: `${t("Successfully")}`,
          type: "success",
        });
      } else {
        setSnackBarConfig({
          open: true,
          message: `${t("Unsuccessful")}`,
          type: "error",
        });
      }
    } else {
      setSnackBarConfig({
        open: true,
        message: `${t("Unsuccessful")}`,
        type: "error",
      });
    }
  };

  const handleApprovalRequest = async (request, approve) => {
    const idRequestTimeLV1 = [request]
      .filter((x) => {
        return (
          x.managerLV1ApproveBy === userProfile.idEmployees ||
          (x.idManagerGroupLV1 &&
            userProfile.idManagerGroupList.includes(x.idManagerGroupLV1))
        );
      })
      .map(function (user) {
        return user.idRequestTime;
      });

    const idRequestTimeLV2 = [request]
      .filter((x) => {
        return (
          x.managerLV2ApproveBy === userProfile.idEmployees ||
          (x.idManagerGroupLV2 &&
            userProfile.idManagerGroupList.includes(x.idManagerGroupLV2))
        );
      })
      .map(function (user) {
        return user.idRequestTime;
      });

    const idRequestTimeWithdraw = [request]
      .filter((x) => {
        return (
          x.managerApprove === userProfile.idEmployees &&
          x.idRequestTimeWithdraw !== null &&
          x.idRequestTimeWithdraw !== undefined
        );
      })
      .map(function (user) {
        return user.idRequestTimeWithdraw;
      });

    const idRequestTime = [request]
      .filter((x) => {
        return x.managerApprove === userProfile.idEmployees;
      })
      .map(function (user) {
        return user.idRequestTime;
      });

    const formData = [
      {
        idRequestTimeLV1: idRequestTimeLV1,
        managerLV1ReApprove: approve,
        managerLV1ApproveBy: userProfile.idEmployees,
        managerLV1ReApproveDate: dayjs(new Date()).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        // commentManagerLV1: comment ? comment : null,
      },
      {
        idRequestTimeLV2: idRequestTimeLV2,
        managerLV2ReApprove: approve,
        managerLV2ApproveBy: userProfile.idEmployees,
        managerLV2ReApproveDate: dayjs(new Date()).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        // commentManagerLV2: comment ? comment : null,
      },
      {
        idRequestTimeWithdraw: idRequestTimeWithdraw,
        idRequestTime: idRequestTime,
        isApprove: approve,
        // commentManager: comment ? comment : null,
      },
    ];

    handleSubmit(formData);
  };

  const handleRejectRequest = async (approve, comment) => {
    const idRequestTimeLV1 = [selectRequest]
      .filter((x) => {
        return (
          x.managerLV1ApproveBy === userProfile.idEmployees ||
          (x.idManagerGroupLV1 &&
            userProfile.idManagerGroupList.includes(x.idManagerGroupLV1))
        );
      })
      .map(function (user) {
        return user.idRequestTime;
      });

    const idRequestTimeLV2 = [selectRequest]
      .filter((x) => {
        return (
          x.managerLV2ApproveBy === userProfile.idEmployees ||
          (x.idManagerGroupLV2 &&
            userProfile.idManagerGroupList.includes(x.idManagerGroupLV2))
        );
      })
      .map(function (user) {
        return user.idRequestTime;
      });

    const idRequestTimeWithdraw = [selectRequest]
      .filter((x) => {
        return (
          x.managerApprove === userProfile.idEmployees &&
          x.idRequestTimeWithdraw !== null &&
          x.idRequestTimeWithdraw !== undefined
        );
      })
      .map(function (user) {
        return user.idRequestTimeWithdraw;
      });

    const idRequestTime = [selectRequest]
      .filter((x) => {
        return x.managerApprove === userProfile.idEmployees;
      })
      .map(function (user) {
        return user.idRequestTime;
      });

    const formData = [
      {
        idRequestTimeLV1: idRequestTimeLV1,
        managerLV1ReApprove: approve,
        managerLV1ApproveBy: userProfile.idEmployees,
        managerLV1ReApproveDate: dayjs(new Date()).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        commentManagerLV1: comment ? comment : null,
      },
      {
        idRequestTimeLV2: idRequestTimeLV2,
        managerLV2ReApprove: approve,
        managerLV2ApproveBy: userProfile.idEmployees,
        managerLV2ReApproveDate: dayjs(new Date()).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        commentManagerLV2: comment ? comment : null,
      },
      {
        idRequestTimeWithdraw: idRequestTimeWithdraw,
        idRequestTime: idRequestTime,
        isApprove: approve,
        commentManager: comment ? comment : null,
      },
    ];

    handleSubmit(formData);
  };

  const handleConfirmReject = (comment) => {
    handleRejectRequest(0, comment);
  };

  const getStatusRequest = (requestTime) => {
    // if (
    //   requestTime.idRequestTimeWithdraw &&
    //   requestTime.managerApprove === userProfile.idEmployees
    // ) {
    //   if (requestTime.isApprove === 1) {
    //     return 1;
    //   } else if (requestTime.isApprove === 0) {
    //     return 0;
    //   } else {
    //     return 2;
    //   }
    // }

    if (requestTime.managerLV1ApproveBy === userProfile.idEmployees) {
      const requestStatus =
        requestTime.managerLV1ReApprove !== null
          ? requestTime.managerLV1ReApprove
          : requestTime.isManagerLV1Approve;
      if (requestStatus === 1) {
        return 1;
      } else if (requestStatus === 0) {
        return 0;
      } else {
        return 2;
      }
    }

    if (requestTime.managerLV2ApproveBy === userProfile.idEmployees) {
      const requestStatus =
        requestTime.managerLV2ReApprove !== null
          ? requestTime.managerLV2ReApprove
          : requestTime.isManagerLV2Approve;
      if (requestStatus === 1) {
        return 1;
      } else if (requestStatus === 0) {
        return 0;
      } else {
        return 2;
      }
    }
  };

  const columns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>
              {i18n.resolvedLanguage === "th"
                ? `${row.firstname_TH} ${row.lastname_TH}`
                : `${row.firstname_EN} ${row.lastname_EN}`}
            </Typography>
            <Typography color="text.third" fontSize="14px">
              {i18n.resolvedLanguage === "th"
                ? `${row.positionName}`
                : `${row.positionName_EN}`}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("Type")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Fragment>
          {row.idRequestTimeWithdraw && <ChipWithDrawCustom />}
          <Typography fontSize={14}>{t(row.name)}</Typography>
          <Fragment>
            {row.xWorkingDailyHoliday > 0 && (
              <Typography
                style={{ fontWeight: 500, fontSize: 14 }}
                color="text.third"
              >
                OT x {payrollSetting.xWorkingDailyHoliday} ={" "}
                {parseFloat(row.xWorkingDailyHoliday / 60).toFixed(2)}{" "}
                {`${t("Unit.ShortHours")}`}
              </Typography>
            )}
            {row.xWorkingMonthlyHoliday > 0 && (
              <Typography
                style={{ fontWeight: 500, fontSize: 14 }}
                color="text.third"
              >
                OT x {payrollSetting.xWorkingMonthlyHoliday} ={" "}
                {parseFloat(row.xWorkingMonthlyHoliday / 60).toFixed(2)}{" "}
                {`${t("Unit.ShortHours")}`}
              </Typography>
            )}
            {row.xOT > 0 && (
              <Typography
                style={{ fontWeight: 500, fontSize: 14 }}
                color="text.third"
              >
                OT x {payrollSetting.xOT} ={" "}
                {parseFloat(row.xOT / 60).toFixed(2)}{" "}
                {`${t("Unit.ShortHours")}`}
              </Typography>
            )}
            {row.xOTHoliday > 0 && (
              <Typography
                style={{ fontWeight: 500, fontSize: 14 }}
                color="text.third"
              >
                OT x {payrollSetting.xOTHoliday} ={" "}
                {parseFloat(row.xOTHoliday / 60).toFixed(2)}{" "}
                {`${t("Unit.ShortHours")}`}
              </Typography>
            )}
          </Fragment>
        </Fragment>
      ),
    },
    {
      name: `${t("Start")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.startText, "DD/MM/YYYY HH:mm").format(
              i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
            )}
          </Typography>
          <Typography>
            {dayjs(row.startText, "DD/MM/YYYY HH:mm").format(
              i18n.resolvedLanguage === "th" ? "HH:mm" : "h:mm A"
            )}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("End")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}
          </Typography>
          <Typography>
            {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("h:mm A")}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("Reason")}`,
      minWidth: "200px",
      cellRender: (row) => (
        <Box>
          <Typography fontSize="14px">
            {i18n.resolvedLanguage === "th"
              ? row.reasonName
              : row.reasonName_EN}
          </Typography>
          <Typography color="text.third" fontSize="14px">
            {row.otherReason}
          </Typography>
        </Box>
      ),
    },
  ];

  const historyColumns = [
    {
      name: `${t("Status")}`,
      headerTextAlign: "center",
      cellRender: (row) => (
        <MenuStatus
          row={row}
          status={getStatusRequest(row)}
          handleChagneStatus={handleChangeStatus}
          conditionDate={row.end}
        />
      ),
    },
    ...columns,
    {
      name: `${t("ApprovedDate")}`,
      minWidth: "150px",
      headerTextAlign: "center",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          {row.idRequestTimeWithdraw && row.approveDateText ? (
            <Fragment>
              <Typography>
                {dayjs(row.approveDateText, "DD/MM/YYYY HH:mm").format(
                  "D MMM BBBB"
                )}
              </Typography>
              <Typography>
                {dayjs(row.approveDateText, "DD/MM/YYYY HH:mm").format("HH:mm")}
              </Typography>
            </Fragment>
          ) : row.managerLV1ApproveBy === userProfile.idEmployees &&
            row.managerLV1ApproveDateText ? (
            <Fragment>
              <Typography>
                {dayjs(
                  row.managerLV1ApproveDateText,
                  "DD/MM/YYYY HH:mm"
                ).format("D MMM BBBB")}
              </Typography>
              <Typography>
                {dayjs(
                  row.managerLV1ApproveDateText,
                  "DD/MM/YYYY HH:mm"
                ).format("HH:mm")}
              </Typography>
            </Fragment>
          ) : row.managerLV2ApproveBy === userProfile.idEmployees &&
            row.managerLV2ApproveDateText ? (
            <Fragment>
              <Typography>
                {dayjs(
                  row.managerLV2ApproveDateText,
                  "DD/MM/YYYY HH:mm"
                ).format("D MMM BBBB")}
              </Typography>
              <Typography>
                {dayjs(
                  row.managerLV2ApproveDateText,
                  "DD/MM/YYYY HH:mm"
                ).format("HH:mm")}
              </Typography>
            </Fragment>
          ) : null}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const tempHistory = [];

    requestTimeList.map((r) => {
      if (r.idRequestType === 2) {
        if (r.isDoubleApproval === 1) {
          if (r.managerLV1ApproveBy === userProfile.idEmployees) {
            if (r.isManagerLV1Approve === 1) {
              tempHistory.push(r);
            } else if (r.isManagerLV1Approve === 0) {
              tempHistory.push(r);
            }
          } else if (r.managerLV2ApproveBy === userProfile.idEmployees) {
            if (r.isManagerLV2Approve === 1) {
              tempHistory.push(r);
            } else if (r.isManagerLV2Approve === 0) {
              tempHistory.push(r);
            }
          }
        } else {
          if (r.approvalLevel === 1) {
            if (r.isManagerLV1Approve === 1) {
              tempHistory.push(r);
            } else if (r.isManagerLV1Approve === 0) {
              tempHistory.push(r);
            }
          } else if (r.approvalLevel === 2) {
            if (r.isManagerLV2Approve === 1) {
              tempHistory.push(r);
            } else if (r.isManagerLV2Approve === 0) {
              tempHistory.push(r);
            }
          }
        }
      }
    });

    requestTimeWithDrawList.map((r) => {
      if (r.idRequestType === 2) {
        if (r.isApprove !== null) {
          tempHistory.push(r);
        }
      }
    });

    setHistoryRows(
      tempHistory.sort(function (a, b) {
        let aApproveDate = null;
        let bApproveDate = null;

        if (a.idRequestTimeWithdraw) {
          aApproveDate = a.approveDate;
        }

        if (b.idRequestTimeWithdraw) {
          bApproveDate = b.approveDate;
        }

        if (a.managerLV1ApproveBy === userProfile.idEmployees) {
          aApproveDate = a.managerLV1ApproveDate;
        } else if (a.managerLV2ApproveBy === userProfile.idEmployees) {
          aApproveDate = a.managerLV2ApproveDate;
        }

        if (b.managerLV1ApproveBy === userProfile.idEmployees) {
          bApproveDate = b.managerLV1ApproveDate;
        } else if (b.managerLV2ApproveBy === userProfile.idEmployees) {
          bApproveDate = b.managerLV2ApproveDate;
        }

        return new Date(bApproveDate) - new Date(aApproveDate);
      })
    );
  }, []);

  return (
    <Box>
      <TableCustom
        columns={historyColumns}
        rows={requestTimeList && requestTimeWithDrawList ? 
          historyRows.filter((_, index) => {
            return (index >= (0 + (page * listPerPage)) && index < ((page + 1) * listPerPage));
          })
          : []
        }
      />
      <Box style={{ display:"flex", justifyContent: "right" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={requestTimeList && requestTimeWithDrawList ? 
            historyRows.length
            : 0}
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={listPerPage}
          onRowsPerPageChange={(event) => {
            setListPerPage(+event.target.value);
            setPage(0);
          }}
          labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${
                            count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
        />
      </Box>
      <DialogConfirmReject
        // rejectCount={selectedRows.length}
        open={isOpenRejectDialog}
        onClose={() => {
          setIsOpenRejectDialog(false);
        }}
        handleSubmit={handleConfirmReject}
      />
    </Box>
  );
};

export default RequestOverTime;
