import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  Fragment,
} from "react";
import {
  Avatar,
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  styled,
  TextField,
  Box,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Autocomplete,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { utils, writeFile } from "xlsx";
import ExcelJS from "exceljs";
import {
  addGradeOKR,
  getCarlibrateOrganization,
} from "../../../../../actions/okrs";
import { getAffiliateOrganization } from "../../../../../actions/company";
import { useTranslation } from "react-i18next";
import { useForm, Controller, useWatch } from "react-hook-form";
import dayjs from "dayjs";

function getAgeNumber(dateString) {
  if (!dateString) return { years: 0, months: 0, days: 0 };

  const date = dayjs(dateString);
  const now = dayjs();

  let years = now.diff(date, "year");
  let months = now.diff(date, "month") % 12;
  let days = now.diff(date, "day") % 30;

  // Adjust for negative values if the date is in the future
  if (years < 0 || months < 0 || days < 0) {
    years = months = days = 0;
  }

  return { years, months, days };
}

function getAgeMonthYear(startDateString, locale = "en") {
  if (!startDateString) return "-";

  const startDate = dayjs(startDateString);
  const now = dayjs();

  let years = now.diff(startDate, "year");
  let months = now.diff(startDate, "month") % 12;

  // Adjust for negative values if the date is in the future
  if (years < 0 || months < 0) {
    years = months = 0;
  }

  if (locale === "th") {
    return `${years} ปี ${months} เดือน`;
  } else {
    return `${years} Year(s) ${months} Month(s)`;
  }
}

// Styled components
const StyledWrapDataGrid = styled("div")({
  padding: "0 24px",
});

const StyledBoxSearch = styled(Box)({
  marginBottom: 16,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 4,
  },
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px -10px 20px #EEEEEE",
    position: "sticky",
    left: 0,
    backgroundColor: "#f4f6f8",
    zIndex: 4,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "none",
  padding: 16,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .fullname": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

function MenuGrade({ idEmployees, fetchData }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeGrade = (value) => {
    const formData = { idEmployees, grade: value };
    dispatch(addGradeOKR(formData)).then((res) => {
      if (res.status === 201) {
        handleClose();
        fetchData();
      }
    });
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleChangeGrade("A")}>{"A"}</MenuItem>
        <MenuItem onClick={() => handleChangeGrade("B")}>{"B"}</MenuItem>
        <MenuItem onClick={() => handleChangeGrade("C")}>{"C"}</MenuItem>
        <MenuItem onClick={() => handleChangeGrade("D")}>{"D"}</MenuItem>
      </Menu>
    </div>
  );
}

function CompanyCalibrate() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [calibrateData, setCalibrateData] = useState([]);
  const [moreFilter, setMoreFilter] = useState(false);

  const [divisionList, setDivisionList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [positionList, setPositionList] = useState([]);

  const [companyList, setCompanyList] = useState([]);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );

  const years = [dayjs().year(), dayjs().year() - 1, dayjs().year() - 2];
  const [yearFilter, setYearFilter] = useState(dayjs().year());

  // Define default filter values
  const [defaultFilterValues, setDefaultFilterValues] = useState({
    company: { idCompany: "all", companyName: t("All") },
    fullName: "",
    division: { idDivision: "all", divisionName: t("All") },
    department: { idDepartment: "all", departmentName: t("All") },
    section: { idSection: "all", sectionName: t("All") },
    position: { idPosition: "all", positionName: t("All") },
  });

  const useHookForm = useForm({
    defaultValues: defaultFilterValues,
    mode: "all",
  });

  const fullNameFilter = (useHookForm.watch("fullName") || "").toLowerCase();
  const companyFilter = useWatch({
    control: useHookForm.control,
    name: "company",
  });
  const divisionFilter = useWatch({
    control: useHookForm.control,
    name: "division",
  });
  const departmentFilter = useWatch({
    control: useHookForm.control,
    name: "department",
  });
  const sectionFilter = useWatch({
    control: useHookForm.control,
    name: "section",
  });
  const positionFilter = useWatch({
    control: useHookForm.control,
    name: "position",
  });

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, [dispatch]);

  useEffect(() => {
    if (affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      setCompanyList(affiliateOrganizationList);

      // Set default company to the first one in the list
      const specificCompany = affiliateOrganizationList[0];

      setDefaultFilterValues((prevValues) => ({
        ...prevValues,
        company: specificCompany,
      }));

      // Update the form values as well
      useHookForm.setValue("company", specificCompany);
    }
  }, [affiliateOrganizationList, useHookForm]);

  const filteredData = useMemo(() => {
    const data = calibrateData.filter((item) => {
      const fullName = `${item.firstname_TH || ""} ${
        item.lastname_TH || ""
      }`.toLowerCase();

      let companyMatch = true;
      if (
        companyFilter &&
        companyFilter.idCompany &&
        companyFilter.idCompany !== "all"
      ) {
        companyMatch = item.idCompany === companyFilter.idCompany;
      }

      let divisionMatch = true;
      if (
        divisionFilter &&
        divisionFilter.idDivision &&
        divisionFilter.idDivision !== "all"
      ) {
        divisionMatch = item.divisionName === divisionFilter.divisionName;
      }

      let departmentMatch = true;
      if (
        departmentFilter &&
        departmentFilter.idDepartment &&
        departmentFilter.idDepartment !== "all"
      ) {
        departmentMatch =
          item.departmentName === departmentFilter.departmentName;
      }

      let sectionMatch = true;
      if (
        sectionFilter &&
        sectionFilter.idSection &&
        sectionFilter.idSection !== "all"
      ) {
        sectionMatch = item.sectionName === sectionFilter.sectionName;
      }

      let positionMatch = true;
      if (
        positionFilter &&
        positionFilter.idPosition &&
        positionFilter.idPosition !== "all"
      ) {
        positionMatch = item.positionName === positionFilter.positionName;
      }

      let yearMatch = true;
      if (yearFilter) {
        yearMatch = item.year === yearFilter;
      }

      return (
        fullName.includes(fullNameFilter) &&
        companyMatch &&
        divisionMatch &&
        departmentMatch &&
        sectionMatch &&
        positionMatch &&
        yearMatch
      );
    });

    // Remove duplicates from data based on idEmployees
    const uniqueData = Array.from(
      new Map(data.map((item) => [item.idEmployees, item])).values()
    );
    return uniqueData;
  }, [
    calibrateData,
    fullNameFilter,
    companyFilter,
    divisionFilter,
    departmentFilter,
    sectionFilter,
    positionFilter,
    yearFilter,
  ]);

  const exportFile = useCallback(() => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("สรุปผลงานบริษัท");

    const headerCellStyle = {
      font: {
        name: "Tahoma",
        size: 14,
        color: { argb: "FFFFFF" },
      },
      alignment: {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "002060" },
      },
    };

    const dataCellStyle = {
      font: {
        name: "Tahoma",
        size: 12,
        color: { argb: "000000" },
      },
      alignment: {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      },
    };

    // Define columns
    sheet.columns = [
      { header: "รหัสพนักงาน", key: "employeeID", width: 15 },
      { header: "ชื่อ", key: "firstname_TH", width: 15 },
      { header: "นามสกุล", key: "lastname_TH", width: 15 },
      { header: "เลขบัตรประชาชน", key: "personalID", width: 20 },
      { header: "ฝ่าย", key: "divisionName", width: 15 },
      { header: "ส่วน", key: "departmentName", width: 15 },
      { header: "แผนก", key: "sectionName", width: 15 },
      { header: "ตำแหน่ง", key: "positionName", width: 15 },
      { header: "Hiring Date", key: "hiringDateFormatted", width: 15 },
      { header: "Job Group", key: "jobGroupName", width: 15 },
      { header: "Personnel Level", key: "personnelLevelName", width: 15 },
      { header: "Job Level", key: "jobLevelName", width: 15 },
      { header: "TIG", key: "TIG", width: 15 },
      { header: "ESY", key: "ESY", width: 15 },
      { header: "Service Year", key: "serviceYear", width: 15 },
      { header: "Age", key: "age", width: 10 },
      { header: "คะแนน Q1", key: "quarter1", width: 10 },
      { header: "คะแนน Q2", key: "quarter2", width: 10 },
      { header: "คะแนน Q3", key: "quarter3", width: 10 },
      { header: "คะแนน Q4", key: "quarter4", width: 10 },
      { header: "คะแนนสุดท้าย", key: "average", width: 10 },
      { header: "เกรดผลงาน", key: "grade", width: 10 },
      { header: "วันที่ข้อมูลล่าสุด", key: "lastUpdatedDate", width: 15 },
    ];

    // Add header row
    const headerRow = sheet.getRow(1);
    headerRow.height = 30;
    headerRow.eachCell((cell) => {
      cell.style = headerCellStyle;
    });

    // Add data rows
    filteredData.forEach((item) => {
      // Calculate Age
      const age = item.birthday ? getAgeNumber(item.birthday).years : "-";

      // Calculate Service Year
      const serviceYear = item.hiringDate
        ? getAgeMonthYear(item.hiringDate, i18n.resolvedLanguage)
        : "-";

      // Calculate ESY (Equivalent Service Year)
      const calculateESY = () => {
        if (item.hiringDate) {
          const serv = getAgeNumber(item.hiringDate);
          const OESY_Y = item.OESY_Y || 0;
          const OESY_M = item.OESY_M || 0;

          const totalMonths = serv.months + OESY_M;
          const extraYears = Math.floor(totalMonths / 12);
          const remainingMonths = totalMonths % 12;

          const totalYears = serv.years + OESY_Y + extraYears;

          if (i18n.resolvedLanguage === "th") {
            return `${totalYears} ปี ${remainingMonths} เดือน`;
          } else {
            return `${totalYears} Year(s) ${remainingMonths} Month(s)`;
          }
        }
        return "-";
      };

      const ESY = calculateESY();

      // Calculate TIG (Time in Grade)
      const TIG = item.employeePersonnelLevelStart
        ? getAgeMonthYear(
            item.employeePersonnelLevelStart,
            i18n.resolvedLanguage
          )
        : "-";

      // Last Updated Date
      const lastUpdatedDate = item.updateDate
        ? dayjs(item.updateDate).format("DD/MM/YYYY")
        : "-";

      // Add row to sheet
      const dataRow = sheet.addRow({
        employeeID: item.employeeID || "-",
        firstname_TH: item.firstname_TH || "-",
        lastname_TH: item.lastname_TH || "-",
        personalID: item.personalID || "-",
        divisionName: item.divisionName || "-",
        departmentName: item.departmentName || "-",
        sectionName: item.sectionName || "-",
        positionName: item.positionName || "-",
        hiringDateFormatted: item.hiringDate
          ? dayjs(item.hiringDate).format("DD/MM/YYYY")
          : "-",
        jobGroupName: item.jobGroupName || "-",
        personnelLevelName: item.personnelLevelName || "-",
        jobLevelName: item.jobLevelName || "-",
        TIG: TIG,
        ESY: ESY,
        serviceYear: serviceYear,
        age: age,
        quarter1: item.quarter1 !== undefined ? item.quarter1 : "-",
        quarter2: item.quarter2 !== undefined ? item.quarter2 : "-",
        quarter3: item.quarter3 !== undefined ? item.quarter3 : "-",
        quarter4: item.quarter4 !== undefined ? item.quarter4 : "-",
        average: item.average !== undefined ? item.average : "-",
        grade: item.grade || "-",
        lastUpdatedDate: lastUpdatedDate,
      });

      dataRow.height = 20;
      dataRow.eachCell((cell) => {
        cell.style = dataCellStyle;
      });
    });

    // Generate Excel file and trigger download
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "สรุปผลงานบริษัท.xlsx";
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }, [filteredData, t, i18n]);

  const fetchData = useCallback(() => {
    dispatch(getCarlibrateOrganization()).then((res) => {
      if (res.status === 200) {
        // Remove duplicates from res.data based on idEmployees
        const uniqueData = Array.from(
          new Map(res.data.map((item) => [item.idEmployees, item])).values()
        );
        setCalibrateData(uniqueData);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (calibrateData && calibrateData.length > 0) {
      let filteredList = calibrateData;

      if (
        companyFilter &&
        companyFilter.idCompany &&
        companyFilter.idCompany !== "all"
      ) {
        filteredList = filteredList.filter(
          (item) => item.idCompany === companyFilter.idCompany
        );
      }

      if (
        divisionFilter &&
        divisionFilter.idDivision &&
        divisionFilter.idDivision !== "all"
      ) {
        filteredList = filteredList.filter(
          (item) => item.divisionName === divisionFilter.divisionName
        );
      }

      if (
        departmentFilter &&
        departmentFilter.idDepartment &&
        departmentFilter.idDepartment !== "all"
      ) {
        filteredList = filteredList.filter(
          (item) => item.departmentName === departmentFilter.departmentName
        );
      }

      if (
        sectionFilter &&
        sectionFilter.idSection &&
        sectionFilter.idSection !== "all"
      ) {
        filteredList = filteredList.filter(
          (item) => item.sectionName === sectionFilter.sectionName
        );
      }

      const divisions = [
        { idDivision: "all", divisionName: t("All") },
        ...Array.from(
          new Set(filteredList.map((item) => item.divisionName).filter(Boolean))
        ).map((name) => ({ idDivision: name, divisionName: name })),
      ];
      setDivisionList(divisions);

      const departments = [
        { idDepartment: "all", departmentName: t("All") },
        ...Array.from(
          new Set(
            filteredList.map((item) => item.departmentName).filter(Boolean)
          )
        ).map((name) => ({ idDepartment: name, departmentName: name })),
      ];
      setDepartmentList(departments);

      const sections = [
        { idSection: "all", sectionName: t("All") },
        ...Array.from(
          new Set(filteredList.map((item) => item.sectionName).filter(Boolean))
        ).map((name) => ({ idSection: name, sectionName: name })),
      ];
      setSectionList(sections);

      const positions = [
        { idPosition: "all", positionName: t("All") },
        ...Array.from(
          new Set(filteredList.map((item) => item.positionName).filter(Boolean))
        ).map((name) => ({ idPosition: name, positionName: name })),
      ];
      setPositionList(positions);
    }
  }, [
    calibrateData,
    companyFilter,
    divisionFilter,
    departmentFilter,
    sectionFilter,
    t,
  ]);

  const toggleMoreFilter = () => {
    setMoreFilter((prev) => !prev);
  };

  // Handler for clearing filters
  const handleClearFilters = () => {
    useHookForm.reset(defaultFilterValues);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <StyledWrapDataGrid>
        <Typography fontWeight={700} color={"#4f65df"} fontSize={"1.5rem"}>
          {`${t("CompanyPerformanceSummary")}`}
        </Typography>

        {/* Buttons for toggling more filters and clearing filters */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 8,
          }}
        >
          <Button variant="text" size="small" onClick={toggleMoreFilter}>
            {moreFilter ? t("HideSearch") : t("SearchMore")}
          </Button>
        </div>

        {/* Search filters */}
        <form onSubmit={useHookForm.handleSubmit(() => {})}>
          <Grid container spacing={2}>
            {/* Basic filters */}
            <Grid item xs={12} md={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("FullName")}
                </Typography>
                <Controller
                  name="fullName"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <StyledTextField
                      {...field}
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder={`${t("Search")} ${t("FullName")}`}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} md={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("Company")}
                </Typography>
                <Controller
                  name="company"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={companyList || []}
                      getOptionLabel={(option) => option.companyName}
                      isOptionEqualToValue={(option, value) =>
                        option.idCompany === value.idCompany
                      }
                      onChange={(_, value) => {
                        field.onChange(value);
                        // Reset dependent filters
                        useHookForm.setValue("division", {
                          idDivision: "all",
                          divisionName: `${t("All")}`,
                        });
                        useHookForm.setValue("department", {
                          idDepartment: "all",
                          departmentName: `${t("All")}`,
                        });
                        useHookForm.setValue("section", {
                          idSection: "all",
                          sectionName: `${t("All")}`,
                        });
                        useHookForm.setValue("position", {
                          idPosition: "all",
                          positionName: `${t("All")}`,
                        });
                      }}
                      value={field.value}
                      fullWidth
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          placeholder={`${t("Search")} ${t("Company")}`}
                        />
                      )}
                    />
                  )}
                />
              </StyledBoxSearch>
            </Grid>

            <Grid item xs={12} md={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("Year")}
                </Typography>
                <Autocomplete
                  options={years}
                  getOptionLabel={(option) => option.toString()}
                  value={yearFilter}
                  onChange={(event, value) => setYearFilter(value)}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      placeholder={t("SelectYear")}
                    />
                  )}
                />
              </StyledBoxSearch>
            </Grid>

            <Grid item xs={12} md={3} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                size="small"
                sx={{ backgroundColor: "#4f65df" }}
                disableElevation
                startIcon={<DownloadIcon />}
                onClick={exportFile}
              >
                {t("Download")}
              </Button>
            </Grid>
          </Grid>

          {/* Additional filters */}
          {moreFilter && (
            <Fragment>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <StyledBoxSearch>
                    <Typography className="label" color="text.third">
                      {t("Division")}
                    </Typography>
                    <Controller
                      name="division"
                      control={useHookForm.control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={divisionList || []}
                          getOptionLabel={(option) => option.divisionName}
                          isOptionEqualToValue={(option, value) =>
                            option.idDivision === value.idDivision
                          }
                          onChange={(_, value) => {
                            field.onChange(value);
                            // Reset lower-level filters
                            useHookForm.setValue("department", {
                              idDepartment: "all",
                              departmentName: `${t("All")}`,
                            });
                            useHookForm.setValue("section", {
                              idSection: "all",
                              sectionName: `${t("All")}`,
                            });
                            useHookForm.setValue("position", {
                              idPosition: "all",
                              positionName: `${t("All")}`,
                            });
                          }}
                          value={field.value}
                          fullWidth
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              variant="outlined"
                              fullWidth
                              placeholder={`${t("Search")} ${t("Division")}`}
                            />
                          )}
                        />
                      )}
                    />
                  </StyledBoxSearch>
                </Grid>
                <Grid item xs={12} md={4}>
                  <StyledBoxSearch>
                    <Typography className="label" color="text.third">
                      {t("Department")}
                    </Typography>
                    <Controller
                      name="department"
                      control={useHookForm.control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={departmentList || []}
                          getOptionLabel={(option) => option.departmentName}
                          isOptionEqualToValue={(option, value) =>
                            option.idDepartment === value.idDepartment
                          }
                          onChange={(_, value) => {
                            field.onChange(value);
                            // Reset lower-level filters
                            useHookForm.setValue("section", {
                              idSection: "all",
                              sectionName: `${t("All")}`,
                            });
                            useHookForm.setValue("position", {
                              idPosition: "all",
                              positionName: `${t("All")}`,
                            });
                          }}
                          value={field.value}
                          fullWidth
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              variant="outlined"
                              fullWidth
                              placeholder={`${t("Search")} ${t("Department")}`}
                            />
                          )}
                        />
                      )}
                    />
                  </StyledBoxSearch>
                </Grid>

                <Grid item xs={12} md={4}>
                  <StyledBoxSearch>
                    <Typography className="label" color="text.third">
                      {t("Section")}
                    </Typography>
                    <Controller
                      name="section"
                      control={useHookForm.control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={sectionList || []}
                          getOptionLabel={(option) => option.sectionName}
                          isOptionEqualToValue={(option, value) =>
                            option.idSection === value.idSection
                          }
                          onChange={(_, value) => {
                            field.onChange(value);
                            // Reset lower-level filters
                            useHookForm.setValue("position", {
                              idPosition: "all",
                              positionName: `${t("All")}`,
                            });
                          }}
                          value={field.value}
                          fullWidth
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              variant="outlined"
                              fullWidth
                              placeholder={`${t("Search")} ${t("Section")}`}
                            />
                          )}
                        />
                      )}
                    />
                  </StyledBoxSearch>
                </Grid>
                <Grid item xs={12} md={4}>
                  <StyledBoxSearch>
                    <Typography className="label" color="text.third">
                      {t("Position")}
                    </Typography>
                    <Controller
                      name="position"
                      control={useHookForm.control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={positionList || []}
                          getOptionLabel={(option) => option.positionName}
                          isOptionEqualToValue={(option, value) =>
                            option.idPosition === value.idPosition
                          }
                          onChange={(_, value) => {
                            field.onChange(value);
                          }}
                          value={field.value}
                          fullWidth
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              variant="outlined"
                              fullWidth
                              placeholder={`${t("Search")} ${t("Position")}`}
                            />
                          )}
                        />
                      )}
                    />
                  </StyledBoxSearch>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={8}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="text"
                    size="small"
                    onClick={handleClearFilters}
                  >
                    {t("ClearFilters")}
                  </Button>
                </Grid>
              </Grid>
            </Fragment>
          )}
        </form>

        {/* Data table */}
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledCellHeader>{t("FullName")}</StyledCellHeader>
                <StyledCellHeader>{t("Division")}</StyledCellHeader>
                <StyledCellHeader>{t("Department")}</StyledCellHeader>
                <StyledCellHeader>{t("Section")}</StyledCellHeader>
                <StyledCellHeader>{t("Position")}</StyledCellHeader>
                <StyledCellHeader>{t("Q1")}</StyledCellHeader>
                <StyledCellHeader>{t("Q2")}</StyledCellHeader>
                <StyledCellHeader>{t("Q3")}</StyledCellHeader>
                <StyledCellHeader>{t("Q4")}</StyledCellHeader>
                <StyledCellHeader>{t("Average")}</StyledCellHeader>
                <StyledCellHeader>{t("Grade")}</StyledCellHeader>
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <StyledRowContent key={item.idEmployees}>
                    <StyledCellContent className="sticky">
                      <div className="fullname">
                        <Avatar src={item.imageURL} />
                        {item.firstname_TH && item.lastname_TH
                          ? `${item.firstname_TH} ${item.lastname_TH}`
                          : "-"}
                      </div>
                    </StyledCellContent>
                    <StyledCellContent>
                      {item.divisionName || "-"}
                    </StyledCellContent>
                    <StyledCellContent>
                      {item.departmentName || "-"}
                    </StyledCellContent>
                    <StyledCellContent>
                      {item.sectionName || "-"}
                    </StyledCellContent>
                    <StyledCellContent>
                      {item.positionName || "-"}
                    </StyledCellContent>
                    <StyledCellContent>
                      {item.quarter1 !== undefined ? item.quarter1 : "-"}
                    </StyledCellContent>
                    <StyledCellContent>
                      {item.quarter2 !== undefined ? item.quarter2 : "-"}
                    </StyledCellContent>
                    <StyledCellContent>
                      {item.quarter3 !== undefined ? item.quarter3 : "-"}
                    </StyledCellContent>
                    <StyledCellContent>
                      {item.quarter4 || "-"}
                    </StyledCellContent>
                    <StyledCellContent>{item.average || "-"}</StyledCellContent>
                    <StyledCellContent>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Chip
                          size="small"
                          label={item.grade || "-"}
                          sx={{
                            backgroundColor: "#4f65df",
                            color: "#ffffff",
                            flexGrow: 1,
                          }}
                        />
                        <MenuGrade
                          idEmployees={item.idEmployees}
                          fetchData={fetchData}
                        />
                      </div>
                    </StyledCellContent>
                  </StyledRowContent>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("RowsPerPage")}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${t("of")} ${count}`
          }
        />
      </StyledWrapDataGrid>
    </>
  );
}

export default CompanyCalibrate;
