import React, { Fragment, useEffect, useState } from "react";
import { Box, Divider, Grid, IconButton, TablePagination, Typography, styled } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DrawerEditDailyShiftChange from "./drawerEditDailyShiftChange";
import DialogConfirmDelete from "../../../../shared/general/DialogConfirmDelete";
import { deleteEmployeeShiftChangeHistoryDaily, getEmployeeShiftChangeHistory } from "../../../../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const HistoryEmployeeDailyShiftPanel = (props) => {

  const { historyList = [] } = props;

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    data: {},
  })

  const [dialogDeleteConfig, setDialogDeleteConfig] = useState({
    isOpen: false,
    data: {},
  })

  const [paginationConfig, setPaginationConfig] = useState({
    page: 0,
    count: 0,
    rowsPerPage: 3,
  })

  const onClickEdit = (item) => {
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: true,
      data: {
        idEmployeeShiftDaily: item.idEmployeeShiftDaily,
        idShiftGroup: item.idShiftGroup,
        idShift: item.idShift,
        idShiftType: item.idShiftType,
        workingDate: item.workingDate,
      }
    }))
  }

  const handleChangePage = (_, newPage) => {
    setPaginationConfig(prev => ({
      ...prev,
      page: newPage
    }))
  };

  const handleCloseDialogDelete = () => {
    setDialogDeleteConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  };

  useEffect(() => {
    setPaginationConfig(prev => ({
      ...prev,
      page: 0,
      count: historyList.length,
    }))
  }, [historyList])

  const handleDeleteEmployeeShiftDaily = async () => {
    if(dialogDeleteConfig.data.idEmployeeShiftDaily){
      const result = await dispatch(deleteEmployeeShiftChangeHistoryDaily(employeeProfile.idEmployees, dialogDeleteConfig.data.idEmployeeShiftDaily));
      if (result && result.status === 200) {
        handleCloseDialogDelete();
        dispatch(openNotificationAlert({
          type: "success",
          message: t("DataSaveSuccessful"),
        }));
        dispatch(getEmployeeShiftChangeHistory(employeeProfile.idEmployees));
      } else {
        dispatch(openNotificationAlert({
          type: "error",
          message: t("AnErrorOccurred")
        }));
      }
    }
  }

  return (
    <Fragment>
      <Grid container spacing={2}>
        {historyList.slice((paginationConfig.page * paginationConfig.rowsPerPage), ((paginationConfig.page+1) * paginationConfig.rowsPerPage)).map(item => (
          <Grid key={item.idEmployeeShiftDaily} item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" gap="4px" flexWrap="wrap" alignItems="center">
                <Box display="flex" gap="4px" flexWrap="wrap">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => setDialogDeleteConfig(prev => ({
                      ...prev,
                      isOpen: true,
                      data: {
                        idEmployeeShiftDaily: item.idEmployeeShiftDaily,
                        workingDate: item.workingDate,
                        shiftGroupName: item.shiftGroupName,
                        shiftName: item.shiftName,
                        shiftTypeName: item.shiftTypeName,
                      }
                    }))}
                  >
                    <HighlightOffRoundedIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() => {
                      onClickEdit(item)
                    }}
                  >
                    <EditNoteRoundedIcon fontSize="inherit" />
                  </IconButton>
                </Box>
                <Box display="flex" gap="8px" flexWrap="wrap">
                  <Typography color="text.third" fontWeight="600">
                    {`[${dayjs(item.workingDate).format(i18n.resolvedLanguage === "th"? "DD MMM BBBB": "DD MMM YYYY")}]`}
                  </Typography>
                  <Typography fontWeight="600">
                    {t("DailyShiftChange")}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <StyledHeadLabel color="text.secondary">
                {t("ShiftType")}
              </StyledHeadLabel>
              <Typography variant="h6">{item.shiftTypeName}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledHeadLabel color="text.secondary">
                {t("ShiftGroup")}
              </StyledHeadLabel>
              <Typography variant="h6">{item.shiftGroupName}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledHeadLabel color="text.secondary">
                {t("ShiftName")}
              </StyledHeadLabel>
              <Typography variant="h6">{item.shiftName}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledHeadLabel color="text.secondary">
                {t("TimeIn")}-{t("TimeOut")}
              </StyledHeadLabel>
              <Typography variant="h6">{dayjs(item.timeIn, "HH:mm:ss").format("HH:mm")} - {dayjs(item.timeOut, "HH:mm:ss").format("HH:mm")}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledHeadLabel color="text.secondary">
                {t("WorkingTimePerDay")}
              </StyledHeadLabel>
              <Typography variant="h6">{`${item.workingHours} ${t("Unit.ShortHours")}/${t("Unit.Days")}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <StyledDivider />
            </Grid>
          </Grid>
        ))}

        {historyList.length === 0 && (
          <Grid item xs={12}>
            <Typography variant="h6" color="text.secondary" fontStyle="italic" align="center">{t("NoData")}</Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={paginationConfig.count}
            rowsPerPage={3}
            page={paginationConfig.page}
            onPageChange={handleChangePage}
            labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${
                            count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
          />
        </Grid>
      </Grid>
      <DrawerEditDailyShiftChange
        drawerConfig={drawerConfig}
        handleClose={() => {
          setDrawerConfig((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }}
      />

      <DialogConfirmDelete
        open={dialogDeleteConfig.isOpen}
        handleClose={handleCloseDialogDelete}
        label={`${t("ShiftChange")}`}
        text={`[${dayjs(dialogDeleteConfig.data.workingDate).format(i18n.resolvedLanguage === "th"? "DD MMM BBBB": "DD MMM YYYY")}] ${dialogDeleteConfig.data.shiftGroupName} (${dialogDeleteConfig.data.shiftName}) : ${dialogDeleteConfig.data.shiftTypeName}`}
        handleDelete={handleDeleteEmployeeShiftDaily}
      />
    </Fragment>
  )
}

export default HistoryEmployeeDailyShiftPanel;