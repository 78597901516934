import { useTranslation } from "react-i18next";

export function currency() {
  const { t, i18n } = useTranslation();
  return [
    {
      label: `THB - ${t("nations.Thailand")}`,
      code: `TH`,
      unit: `THB`,
    },
    {
      label: `USD - ${t("nations.UnitedStates")}`,
      code: `US`,
      unit: `USD`,
    },
    {
      label: `GBP - ${t("nations.UnitedKingdom")}`,
      code: `GB`,
      unit: `GBP`,
    },
    {
      label: `EUR - ${t("nations.Eurozone")}`,
      code: `EU`,
      unit: `EUR`,
    },
    {
      label: `JPY - ${t("nations.Japan")}`,
      code: `JP`,
      unit: `JPY`,
    },
    {
      label: `KRW - ${t("nations.SouthKorea")}`,
      code: `KR`,
      unit: `KRW`,
    },
    {
      label: `HKD - ${t("nations.HongKong")}`,
      code: `HK`,
      unit: `HKD`,
    },
    {
      label: `MYR - ${t("nations.Malaysia")}`,
      code: `MY`,
      unit: `MYR`,
    },
    {
      label: `SGD - ${t("nations.Singapore")}`,
      code: `SG`,
      unit: `SGD`,
    },
    {
      label: `BND - ${t("nations.Brunei")}`,
      code: `BN`,
      unit: `BND`,
    },
    {
      label: `PHP - ${t("nations.Philippines")}`,
      code: `PH`,
      unit: `PHP`,
    },
    {
      label: `IDR - ${t("nations.Indonesia")}`,
      code: `ID`,
      unit: `IDR`,
    },
    {
      label: `INR - ${t("nations.India")}`,
      code: `IN`,
      unit: `INR`,
    },
    {
      label: `CHF - ${t("nations.Switzerland")}`,
      code: `CH`,
      unit: `CHF`,
    },
    {
      label: `AUD - ${t("nations.Australia")}`,
      code: `AU`,
      unit: `AUD`,
    },
    {
      label: `NZD - ${t("nations.NewZealand")}`,
      code: `NZ`,
      unit: `NZD`,
    },
    {
      label: `CAD - ${t("nations.Canada")}`,
      code: `CA`,
      unit: `CAD`,
    },
    {
      label: `SEK - ${t("nations.Sweden")}`,
      code: `SE`,
      unit: `SEK`,
    },
    {
      label: `DKK - ${t("nations.Denmark")}`,
      code: `DK`,
      unit: `DKK`,
    },
    {
      label: `NOK - ${t("nations.Norway")}`,
      code: `NO`,
      unit: `NOK`,
    },
    {
      label: `CNY - ${t("nations.China")}`,
      code: `CN`,
      unit: `CNY`,
    },
    {
      label: `MXN - ${t("nations.Mexico")}`,
      code: `MX`,
      unit: `MXN`,
    },
    {
      label: `ZAR - ${t("nations.SouthAfrica")}`,
      code: `ZA`,
      unit: `ZAR`,
    },
    {
      label: `TWD - ${t("nations.Taiwan")}`,
      code: `TW`,
      unit: `TWD`,
    },
    {
      label: `KWD - ${t("nations.Kuwait")}`,
      code: `KW`,
      unit: `KWD`,
    },
    {
      label: `SAR - ${t("nations.SaudiArabia")}`,
      code: `SA`,
      unit: `SAR`,
    },
    {
      label: `AED - ${t("nations.UnitedArabEmirates")}`,
      code: `AE`,
      unit: `AED`,
    },
    {
      label: `MMK - ${t("nations.Myanmar")}`,
      code: `MM`,
      unit: `MMK`,
    },
    {
      label: `BDT - ${t("nations.Bangladesh")}`,
      code: `BD`,
      unit: `BDT`,
    },
    {
      label: `CZK - ${t("nations.CzechRepublic")}`,
      code: `CZ`,
      unit: `CZK`,
    },
    {
      label: `KHR - ${t("nations.Cambodia")}`,
      code: `KH`,
      unit: `KHR`,
    },
    {
      label: `KES - ${t("nations.Kenya")}`,
      code: `KE`,
      unit: `KES`,
    },
    {
      label: `LAK - ${t("nations.Laos")}`,
      code: `LA`,
      unit: `LAK`,
    },
    {
      label: `RUB - ${t("nations.Russia")}`,
      code: `RU`,
      unit: `RUB`,
    },
    {
      label: `VND - ${t("nations.Vietnam")}`,
      code: `VN`,
      unit: `VND`,
    },
    {
      label: `EGP - ${t("nations.Egypt")}`,
      code: `EG`,
      unit: `EGP`,
    },
    {
      label: `PLN - ${t("nations.Poland")}`,
      code: `PL`,
      unit: `PLN`,
    },
    {
      label: `LKR - ${t("nations.SriLanka")}`,
      code: `LK`,
      unit: `LKR`,
    },
    {
      label: `IQD - ${t("nations.Iraq")}`,
      code: `IQ`,
      unit: `IQD`,
    },
    {
      label: `BHD - ${t("nations.Bahrain")}`,
      code: `BH`,
      unit: `BHD`,
    },
    {
      label: `OMR - ${t("nations.Oman")}`,
      code: `OM`,
      unit: `OMR`,
    },
    {
      label: `JOD - ${t("nations.Jordan")}`,
      code: `JO`,
      unit: `JOD`,
    },
    {
      label: `QAR - ${t("nations.Qatar")}`,
      code: `QA`,
      unit: `QAR`,
    },
    {
      label: `MVR - ${t("nations.Maldives")}`,
      code: `MV`,
      unit: `MVR`,
    },
    {
      label: `NPR - ${t("nations.Nepal")}`,
      code: `NP`,
      unit: `NPR`,
    },
    {
      label: `PGK - ${t("nations.PapuaNewGuinea")} `,
      code: `PG`,
      unit: `PGK`,
    },
    {
      label: `ILS - ${t("nations.Israel")}`,
      code: `IL`,
      unit: `ILS`,
    },
    {
      label: `HUF - ${t("nations.Hungary")}`,
      code: `HU`,
      unit: `HUF`,
    },
    {
      label: `PKR - ${t("nations.Pakistan")}`,
      code: `PK`,
      unit: `PKR`,
    },
  ];
}
