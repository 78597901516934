import {
  PAYRUNS_FETCHING,
  PAYRUNS_FAILED,
  PAYRUNS_SUCCESS,
  PAYRUNS_DETAIL_FETCHING,
  PAYRUNS_DETAIL_FAILED,
  PAYRUNS_DETAIL_SUCCESS,
  PAYRUNS_DETAIL_EMPLOYEES_FETCHING,
  PAYRUNS_DETAIL_EMPLOYEES_FAILED,
  PAYRUNS_DETAIL_EMPLOYEES_SUCCESS,
  PAYSLIP_FETCHING,
  PAYSLIP_FAILED,
  PAYSLIP_SUCCESS,
  PAYSLIP_DETAIL_FETCHING,
  PAYSLIP_DETAIL_FAILED,
  PAYSLIP_DETAIL_SUCCESS,
  PAYRUNS_SUMMARY_YEAR_FETCHING,
  PAYRUNS_SUMMARY_YEAR_SUCCESS,
  PAYRUNS_SUMMARY_YEAR_FAILED,
} from "./types";

import PayRunsService from "../services/payruns.service";

export const allPayruns = (yaer, filter) => async (dispatch) => {
  try {
    dispatch({
      type: PAYRUNS_FETCHING,
    });
    const res = await PayRunsService.allPayruns(yaer, filter);
    if (res) {
      dispatch({
        type: PAYRUNS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PAYRUNS_FAILED,
    });
    console.log(err);
  }
};

export const getPayRunsId = (idPayrun) => async (dispatch) => {
  try {
    dispatch({
      type: PAYRUNS_DETAIL_FETCHING,
    });
    const res = await PayRunsService.getPayRunsId(idPayrun);
    if (res) {
      dispatch({
        type: PAYRUNS_DETAIL_SUCCESS,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    dispatch({
      type: PAYRUNS_DETAIL_FAILED,
    });
    console.log(err);
    return err;
  }
};

export const getPayrunsDetailById = (idPayrun) => async (dispatch) => {
  try {
    dispatch({
      type: PAYRUNS_DETAIL_EMPLOYEES_FETCHING,
    });
    const res = await PayRunsService.getPayrunsDetailById(idPayrun);
    if (res) {
      dispatch({
        type: PAYRUNS_DETAIL_EMPLOYEES_SUCCESS,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    dispatch({
      type: PAYRUNS_DETAIL_EMPLOYEES_FAILED,
    });
    console.log(err);
    return err;
  }
};

export const getPayrunsSummaryYear = (year, idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: PAYRUNS_SUMMARY_YEAR_FETCHING,
    });
    const res = await PayRunsService.getPayrunsSummaryYear(year, idCompany);
    if (res) {
      dispatch({
        type: PAYRUNS_SUMMARY_YEAR_SUCCESS,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    dispatch({
      type: PAYRUNS_SUMMARY_YEAR_FAILED,
    });
    console.log(err);
    return err;
  }
};

export const createPayruns = (formData) => async (dispatch) => {
  try {
    const res = await PayRunsService.createPayruns(formData);
    if (res) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

export const getPayslip = (filter, date) => async (dispatch) => {
  try {
    dispatch({
      type: PAYSLIP_FETCHING,
    });
    const res = await PayRunsService.getPayslip(filter, date);
    if (res) {
      dispatch({
        type: PAYSLIP_SUCCESS,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    dispatch({
      type: PAYSLIP_FAILED,
    });
    console.log(err);
    return err;
  }
};

export const getPayslipByIdPayrunDetail =
  (idEmployees, date) => async (dispatch) => {
    try {
      dispatch({
        type: PAYRUNS_DETAIL_EMPLOYEES_FETCHING,
      });
      const res = await PayRunsService.getPayslipByIdPayrunDetail(
        idEmployees,
        date
      );
      if (res) {
        dispatch({
          type: PAYRUNS_DETAIL_EMPLOYEES_SUCCESS,
          payload: res.data,
        });
        return res.data;
      }
    } catch (err) {
      dispatch({
        type: PAYRUNS_DETAIL_EMPLOYEES_FAILED,
      });
      console.log(err);
      return err;
    }
  };

export const getPayslipByEmployee =
  (date, filter, query) => async (dispatch) => {
    try {
      dispatch({
        type: PAYSLIP_DETAIL_FETCHING,
      });
      const res = await PayRunsService.getPayslipByEmployee(
        date,
        filter,
        query
      );
      if (res) {
        dispatch({
          type: PAYSLIP_DETAIL_SUCCESS,
          payload: res.data,
        });
        return res.data;
      }
    } catch (err) {
      dispatch({
        type: PAYSLIP_DETAIL_FAILED,
      });
      console.log(err);
      return err;
    }
  };

export const updateClosePayroll = (idPayrun) => async () => {
  try {
    const res = await PayRunsService.updateClosePayroll(idPayrun);
    if (res) {
      return res;
    }
  } catch (error) {
    return "Error";
  }
};

export const deletePayroll = (dataForm) => async () => {
  try {
    const res = await PayRunsService.deletePayroll(dataForm);
    if (res) {
      return res;
    }
  } catch (error) {
    return "Error";
  }
};

export const updatePayrunDetail = (data) => async () => {
  try {
    const res = await PayRunsService.updatePayrunDetail(data);
    if (res) {
      return res.data;
    }
  } catch (error) {
    return "Error";
  }
};

export const uploadAddOn = (formData) => async (dispatch) => {
  try {
    const res = await PayRunsService.uploadAddOn(formData);
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAllEmployeesWithTaxData = async () => {
  try {
    const res = await PayRunsService.getAllEmployeesWithTaxData();
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addBudget = async (query) => {
  try {
    const res = await PayRunsService.addBudget(query);
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateBudget = async (query) => {
  try {
    const res = await PayRunsService.updateBudget(query);
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getPayrunByMonthAndCompanyToCompare = async (query) => {
  try {
    const res = await PayRunsService.getPayrunByMonthAndCompanyToCompare(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getOverviewPayroll = async (query) => {
  try {
    const res = await PayRunsService.getOverviewPayroll(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getSsofile = (query) => async (dispatch) => {
  try {
    const res = await PayRunsService.getSsofile(query);
    if (res) {
      return res;
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getPnd1file = (query) => async (dispatch) => {
  try {
    const res = await PayRunsService.getPnd1file(query);
    if (res) {
      return res;
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};
