import { httpClient } from "./httpClient";
import dayjs from "dayjs";

const getAttendance = (id, filter) => {
  return httpClient.get(`/company/${id}/attendance?date=${filter.searchDate}`);
};

const getAttendanceById = (filter, idEmployees) => {
  if (idEmployees) {
    return httpClient.get(
      `/attendance/${idEmployees}?start=${dayjs(filter.start).format(
        "YYYY-MM-DD"
      )}&end=${dayjs(filter.end).format("YYYY-MM-DD")}`
    );
  } else {
    return httpClient.get(
      `/attendance?start=${dayjs(filter.start).format(
        "YYYY-MM-DD"
      )}&end=${dayjs(filter.end).format("YYYY-MM-DD")}`
    );
  }
};

const getAttendanceTransaction = (filter) => {
  return httpClient.get(
    `/attendance/transaction?start=${dayjs(filter.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(filter.end).format("YYYY-MM-DD")}`
  );
};

const getAttendanceOnce = (query) => {
  return httpClient.get(`/attendance-once`, { params: query });
};

const addAttendance = (formData) => {
  return httpClient.post(`/attendance/`, formData);
};

const getAllEmployeeTimeline = (idCompany, query) => {
  return httpClient.get(`/company/${idCompany}/attendance/v2`, {
    params: query,
  });
};

const getWorkingHoursByIdEmployees = (idEmployees, query) => {
  return httpClient.get(`/working-hours/${idEmployees}`, { params: query });
};

const exportExcelEmployeeAttendanceMonthly = (query) => {
  return httpClient.get(`/export-excel-employee-attendance-monthly`, {
    params: query,
    responseType: "blob",
  });
};

const getAttendanceWeekly = (query) => {
  return httpClient.get(`/attendance-weekly`, { params: query });
};

const exportExcelAttendanceWeekly = (query) => {
  return httpClient.get(`/export-excel-attendance-weekly`, {
    params: query,
    responseType: "blob",
  });
};

const getAttendanceUploadFinger = (filter, selectedCompany) => {
  return httpClient.get(
    `/attendance-upload?filter=${selectedCompany}&start=${dayjs(filter.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(filter.end).format("YYYY-MM-DD")}`
  );
};

const getAttendanceMonthly = (query) => {
  return httpClient.get(`/attendance-monthly`, {
    params: query,
  });
};

const deleteAttendance = (formData) => {
  return httpClient.post(`/attendance/delete`, formData);
};

const getTimescheduleByIdEmployeesV2 = (idEmployees, query) => {
  return httpClient.get(`/v2/time-schedule/${idEmployees}`, { params: query }); 
}

export default {
  getAttendance,
  getAttendanceTransaction,
  getAttendanceById,
  getAttendanceOnce,
  addAttendance,
  getAllEmployeeTimeline,
  getWorkingHoursByIdEmployees,
  exportExcelEmployeeAttendanceMonthly,
  getAttendanceWeekly,
  exportExcelAttendanceWeekly,
  getAttendanceUploadFinger,
  getAttendanceMonthly,
  deleteAttendance,
  getTimescheduleByIdEmployeesV2
};
