import ExcelJS from "exceljs";

const exportToExcel = (props, t) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("OKRs");

  const headerCellStyle = {
    font: {
      name: "Tahoma",
      size: 14,
      color: { argb: "FFFFFF" },
    },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "002060" },
    },
  };

  const dataCellStyle = {
    font: {
      name: "Tahoma",
      size: 14,
    },
    alignment: {
      vertical: "middle",
      horizontal: "center",
    },
  };

  sheet.columns = [
    { header: t("Objective Name"), key: "objectiveName", width: 30 },
    { header: t("Quarter"), key: "quarter", width: 15 },
    { header: t("Key Result"), key: "keyResult", width: 30 },
    { header: t("StartDate"), key: "startDate", width: 20 },
    { header: t("EndDate"), key: "endDate", width: 20 },
    { header: t("Status"), key: "status", width: 15 },
    { header: t("Descriptions"), key: "description", width: 60 },
    { header: t("Strategy"), key: "strategy", width: 40 },
    { header: t("Measurement Evidence"), key: "measureEvidence", width: 40 },
    {
      header: t("Commit Quality"),
      key: "commitQuality",
      width: 40,
    },
    { header: t("Measurement Data Type"), key: "measureDataType", width: 30 },
  ];

  const headerRow = sheet.getRow(1);
  headerRow.height = 100;
  headerRow.eachCell((cell) => {
    cell.style = headerCellStyle;
  });

  props.forEach((objective) => {
    objective.keyResults.forEach((kr) => {
      const row = sheet.addRow({
        objectiveName: objective.objectiveName,
        quarter: `Q${objective.quarter} ${objective.year}`,
        keyResult: kr.keyResultName,
        startDate: new Date(kr.startDate).toLocaleDateString(),
        endDate: new Date(kr.endDate).toLocaleDateString(),
        status: kr.isAchieve ? t("Achieved") : t("Pending"),
        description: kr.description,
        strategy: kr.tacticName,
        measureEvidence: kr.measureEvidence || "",
        commitQuality: kr.commitQuality || "",
        measureDataType:
          kr.measureDataType === 1 ? t("Number") : t("Explanation"),
      });
      row.height = 50;
      row.eachCell((cell) => {
        cell.style = dataCellStyle;
      });
    });
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `OKRs.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  });
};

export default exportToExcel;
