import {
  PERFORMANCE_YEAR_FETCHING,
  PERFORMANCE_YEAR_FAILED,
  PERFORMANCE_YEAR_SUCCESS,
  PERFORMANCE_QUESTION_FETCHING,
  PERFORMANCE_QUESTION_FAILED,
  PERFORMANCE_QUESTION_SUCCESS,
  PERFORMANCE_RESULT_FETCHING,
  PERFORMANCE_RESULT_FAILED,
  PERFORMANCE_RESULT_SUCCESS,
  ESTIMATE_LEVEL_SUCCESS,
  ESTIMATE_LEVEL_FETCHING,
  ESTIMATE_LEVEL_FAILED,
} from "./types";
import EstimatePerformanceService from "../services/estimatePerformance.service";

export const getCompanyPerformanceYear = (query) => async (dispatch) => {
  try {
    dispatch({
      type: PERFORMANCE_YEAR_FETCHING,
    });
    const res = await EstimatePerformanceService.getCompanyPerformanceYear(query);
    if (res) {
      dispatch({
        type: PERFORMANCE_YEAR_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PERFORMANCE_YEAR_FAILED,
    });
  }
};

export const addCompanyPerformanceYear = (query) => async (dispatch) => {
  try {
    const res = await EstimatePerformanceService.addCompanyPerformanceYear(query);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: PERFORMANCE_YEAR_FAILED,
    });
  }
};

export const updateCompanyPerformanceYear = (query) => async (dispatch) => {
  try {
    const res = await EstimatePerformanceService.updateCompanyPerformanceYear(query);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: PERFORMANCE_YEAR_FAILED,
    });
  }
};

export const updateEstimatePerformance = (formData) => async (dispatch) => {
  try {
    const res = await EstimatePerformanceService.updateEstimatePerformance(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: PERFORMANCE_QUESTION_FAILED,
    });
  }
};

export const getAllPerformanceQuestionSet = (query) => async (dispatch) => {
  try {
    dispatch({
      type: PERFORMANCE_QUESTION_FETCHING,
    });
    const res = await EstimatePerformanceService.getAllPerformanceQuestionSet(query);
    if (res) {
      dispatch({
        type: PERFORMANCE_QUESTION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PERFORMANCE_QUESTION_FAILED,
    });
  }
};

export const addPerformanceQuestionSet = (query) => async (dispatch) => {
  try {
    const res = await EstimatePerformanceService.addPerformanceQuestionSet(query);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: PERFORMANCE_QUESTION_FAILED,
    });
  }
};

export const updatePerformanceQuestionSet = (formData) => async (dispatch) => {
  try {
    const res = await EstimatePerformanceService.updatePerformanceQuestionSet(formData);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: PERFORMANCE_QUESTION_FAILED,
    });
  }
};

export const updatePerformanceQuestionSetStatus = (formData) => async (dispatch) => {
  try {
    const res = await EstimatePerformanceService.updatePerformanceQuestionSetStatus(formData);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: PERFORMANCE_QUESTION_FAILED,
    });
  }
};

export const updateEstimatePerformanceManager = (formData) => async () => {
  try {
    const res =
      await EstimatePerformanceService.updateEstimatePerformanceManager(
        formData
      );
    if (res) {
      return res;
    }
  } catch (error) {
    return "Error";
  }
};

export const getEstimatePerformanceResult = () => async (dispatch) => {
  // try {
  //   dispatch({
  //     type: RESULT_ESTIMATE_PERFORMANCE_FETCHING,
  //   });
  //   const res = await EstimatePerformanceService.getEstimatePerformanceResult();
  //   if (res) {
  //     dispatch({
  //       type: RESULT_ESTIMATE_PERFORMANCE_SUCCESS,
  //       payload: res.data,
  //     });
  //   }
  // } catch (err) {
  //   dispatch({
  //     type: RESULT_ESTIMATE_PERFORMANCE_FAILED,
  //   });
  //   console.log(err);
  // }
};

export const getAllEstimateLevel = (query) => async (dispatch) => {
  try {
    dispatch({
      type: ESTIMATE_LEVEL_FETCHING,
    });
    const res = await EstimatePerformanceService.getAllEstimateLevel(query);
    if (res) {
      dispatch({
        type: ESTIMATE_LEVEL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ESTIMATE_LEVEL_FAILED,
    });
    return err
  }
};

export const getEstimateLevelActive = (query) => async (dispatch) => {
  try {
    dispatch({
      type: ESTIMATE_LEVEL_FETCHING,
    });
    const res = await EstimatePerformanceService.getEstimateLevelActive(query);
    if (res) {
      dispatch({
        type: ESTIMATE_LEVEL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ESTIMATE_LEVEL_FAILED,
    });
    return err
  }
};

export const addPerformanceEmployee = (formData) => async (dispatch) => {
  try {
    const res = await EstimatePerformanceService.addPerformanceEmployee(formData);
    if (res) {
      return res
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
}

export const addPerformanceManager = (formData) => async (dispatch) => {
  try {
    const res = await EstimatePerformanceService.addPerformanceManager(formData);
    if (res) {
      return res
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
}

export const updateEmployeePerformance = (idEmployeePerformance, formData) => async (dispatch) => {
  try {
    const res = await EstimatePerformanceService.updateEmployeePerformance(idEmployeePerformance, formData);
    if (res) {
      return res
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
}

export const getAllEmployeePerformance = (query) => async (dispatch) => {
  try {
    dispatch({
      type: PERFORMANCE_QUESTION_FETCHING,
    });
    const res = await EstimatePerformanceService.getAllEmployeePerformance(query);
    if (res) {
      dispatch({
        type: PERFORMANCE_QUESTION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PERFORMANCE_QUESTION_FAILED,
    });
  }
};

export const getPerformanceManager = () => async (dispatch) => {
  try {
    dispatch({
      type: PERFORMANCE_QUESTION_FETCHING,
    });
    const res = await EstimatePerformanceService.getPerformanceManager();
    if (res) {
      dispatch({
        type: PERFORMANCE_QUESTION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PERFORMANCE_QUESTION_FAILED,
    });
  }
};

export const getPerformanceEmployee = () => async (dispatch) => {
  try {
    dispatch({
      type: PERFORMANCE_QUESTION_FETCHING,
    });
    const res = await EstimatePerformanceService.getPerformanceEmployee();
    if (res) {
      dispatch({
        type: PERFORMANCE_QUESTION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PERFORMANCE_QUESTION_FAILED,
    });
  }
};

export const getEmployeePerformance = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PERFORMANCE_RESULT_FETCHING,
    });
    const res = await EstimatePerformanceService.getEmployeePerformance(id);
    if (res) {
      dispatch({
        type: PERFORMANCE_RESULT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PERFORMANCE_RESULT_FAILED,
    });
  }
};

export const addPerformanceAdmin = (formData) => async (dispatch) => {
  try {
    const res = await EstimatePerformanceService.addPerformanceAdmin(formData);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: PERFORMANCE_RESULT_FAILED,
    });
  }
};
