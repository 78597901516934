import React, { useEffect, useState, Fragment } from "react";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Container, FilledInput } from "@mui/material";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

import StyledCard from "../../shared/general/Card";
import DatePickerWeek from "../../shared/general/DatePickerWeek";

import TableOTReport from "./tableOTReport";
import ButtonBlue from "../../shared/general/ButtonBlue";

import { getUserFullName, getUserPosition } from "../../../../utils/userData";

import {
  getOvertime36Hours,
  getOvertime36HoursDetail,
} from "../../../../actions/requestTime";
import { getAffiliateOrganization } from "../../../../actions/company";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";

const rand40 = () => {
  return Math.floor(Math.random() * 40);
};

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
  "& .empty-data": {
    display: "flex",
    justifyContent: "center",
    padding: 32,
    "& .text": {
      fontStyle: "oblique",
      fontWeight: 400,
    },
  },
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px dashed #DDDDDD`,
  "&:not(:last-child)": {},
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon />} {...props} />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  paddingLeft: 0,
  "& .inner": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .hours": {
      display: "flex",
      alignItems: "baseline",
    },
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(-90deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const StyledAvatar = styled(Avatar)({
  width: 40,
  height: 40,
});

const StyledCellName = styled("div")({
  display: "flex",
  alignItems: "center",
  "& .MuiAvatar-root": {
    marginRight: 8,
  },
  "& .MuiTypography-h6": {
    lineHeight: 1,
  },
  "& .MuiTypography-body2": {
    color: "#999999",
    fontStyle: "oblique",
  },
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

export default function OTMonitoring() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: overtime36HoursList } = useSelector((state) => state.overtime36Hours);
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

  const [value, setValue] = useState(new Date());
  const [expanded, setExpanded] = useState(null);
  const [searchDate, setSearchDate] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    if (userProfile) {
      const start = dayjs(value).startOf("week");
      const end = dayjs(value).endOf("week");
      let temp = {
        start: dayjs(start).format("YYYY-MM-DD"),
        end: dayjs(end).format("YYYY-MM-DD"),
      };

      if (userProfile.roles.includes("ROLE_ADMIN") && selectedCompany) {
        temp.idCompany = selectedCompany.idCompany;
        temp.filter = "admin";
      } else {
        temp.idManager = userProfile.idEmployees;
        temp.filter = "manager";
      };

      setSearchDate(temp);
      dispatch(getOvertime36Hours(temp));
    }
  }, [selectedCompany, userProfile, value, dispatch]);

  useEffect(() => {
    if (userProfile && userProfile.roles.includes("ROLE_ADMIN")) {
      dispatch(getAffiliateOrganization());
    }
  }, [userProfile]);

  const handleClickSearch = () => {
    setExpanded(null);
    dispatch(getOvertime36Hours(searchDate));
  };

  const handleChangeExpanded = (panel, idEmployees) => (event, newExpanded) => {
    if (newExpanded) {
      dispatch(getOvertime36HoursDetail({
        search: idEmployees,
        start: searchDate.start,
        end: searchDate.end
      }));
    }
    setExpanded(newExpanded ? panel : false);
  };

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
  };

  useEffect(() => {
    if ((userProfile && userProfile.roles.includes("ROLE_ADMIN")) && (affiliateOrganizationList && affiliateOrganizationList.length > 0)) {
      let ownCompany = affiliateOrganizationList.find(x => x.idCompany === userProfile.idCompany);
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }

      setSelectedCompany(ownCompany);
    }
  }, [userProfile, affiliateOrganizationList]);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingBottom: 16 }}>
          {t("CheckOvertimePay")}
        </Typography>
        <div>
          <StyledCard>
            <div style={{ padding: 24 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography className="label" color="text.third">
                    {t("DateRange")}
                  </Typography>
                  <DatePickerWeek
                    value={value}
                    setValue={setValue}
                    setSearchDate={setSearchDate}
                  />
                </Grid>
                {userProfile && userProfile.roles.includes("ROLE_ADMIN") && (
                  <Grid item xs={12} sm={4}>
                    <Typography className="label" color="text.third">
                      {t("SelectCompany")}
                    </Typography>
                    <SelectAffiliateCompany
                      options={affiliateOrganizationList}
                      value={selectedCompany}
                      onChange={(_, value) => {
                        onChangeCompany(value);
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={2}>
                  <div style={{ marginTop: 22 }}>
                    <ButtonBlue
                      startIcon={<SearchRoundedIcon />}
                      variant={"contained"}
                      onClick={handleClickSearch}
                    >
                      {t("Search")}
                    </ButtonBlue>
                  </div>
                </Grid>
              </Grid>

              <div style={{ marginTop: 36 }}>
                {overtime36HoursList && overtime36HoursList.length > 0 ?
                  overtime36HoursList.map((value, index) => {
                    return (
                      <Accordion
                        key={index}
                        expanded={expanded === `panel${index}`}
                        onChange={handleChangeExpanded(
                          `panel${index}`,
                          value.idEmployees
                        )}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <div className="inner">
                            <StyledCellName>
                              <Avatar
                                alt={getUserFullName(value)}
                                src={`${value.imageProfile}` || ""}
                              />
                              <div>
                                <Typography variant="h6">
                                  {getUserFullName(value)}
                                </Typography>
                                <Typography variant="body2">
                                  {getUserPosition(value)}
                                </Typography>
                              </div>
                            </StyledCellName>
                            <div className="hours">
                              <Typography
                                variant="h4"
                                style={{
                                  marginRight: 4,
                                  color: value.isOver36Hours ? "#e46a76" : "",
                                  fontWeight: value.isOver36Hours ? 600 : 400,
                                }}
                              >
                                {value.amountHours}
                              </Typography>
                              <Typography color="text.third">{` ${t("Unit.ShortHours")}`}</Typography>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableOTReport />
                        </AccordionDetails>
                      </Accordion>
                    );
                  }) : (
                    <div className="empty-data">
                      <Typography className="text" color="text.secondary">
                        {t("NoDataPleaseSelectDatesAndSearch")}
                      </Typography>
                    </div>
                  )}
              </div>
            </div>
          </StyledCard>
        </div>
      </Container>
    </StyledRoot>
  );
}
