import React from "react";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";

const StyledDivider = styled(Divider)({
  marginTop: 8,
  marginBottom: 8,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const DividerStyle = ({ style }) => {
  return <StyledDivider style={style}></StyledDivider>;
};

export default DividerStyle;
