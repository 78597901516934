import React, { useEffect, useState, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

import { useDropzone } from "react-dropzone";

import HeaderPage from "../../../shared/header/headerPage";
import StyleCard from "../../../shared/general/Card";

import StepOne from "./stepOne";
import StepTwo from "./stepTwo";
import StepThree from "./stepThree";
import StepFour from "./stepFour";

import "./index.css";

import {
  Paper,
  Typography,
  Grid,
  Divider,
  Avatar,
  Container,
} from "@mui/material";

import UserService from "../../../../../services/user.service";

const useStyles = makeStyles(() => ({
  divider: {
    margin: "30px 0",
  },
  imageProfile: {},
  wrapCaptionImage: {
    marginTop: 16,
    textAlign: "center",
  },
  captionImage: {
    color: "#212b36",
  },
  wrapSwitchActive: {
    flexDirection: "row-reverse",
    margin: 0,
    width: "100%",
    "& .MuiFormControlLabel-label": {
      marginRight: "auto",
    },
  },
  labelSwitchActive: {
    fontSize: 14,
  },
  row: {
    marginBottom: 16,
  },
  textField: {
    width: "100%",
  },
  buttonSave: {
    marginLeft: "auto",
  },
  uploadImage: {
    width: "105%",
    zIndex: 8,
  },
  placeholder: {
    width: "100%",
    height: "100%",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "rgb(99, 115, 129)",
    backgroundColor: "rgb(244, 246, 248)",
    transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  placeholderImageProfile: {
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgba(22, 28, 36, .72)",
  },
  placeholderLabel: {
    color: "rgb(255, 255, 255)",
  },
}));

var convertImgToDataURLviaCanvas = function (url, callback) {
  var img = new Image();

  img.crossOrigin = "Anonymous";

  img.onload = function () {
    var canvas = document.createElement("CANVAS");
    var ctx = canvas.getContext("2d");
    var dataURL;
    canvas.height = this.height;
    canvas.width = this.width;
    ctx.drawImage(this, 0, 0);
    dataURL = canvas.toDataURL();
    callback(dataURL);
    canvas = null;
  };

  img.src = url;
};

const StyledFirstSection = styled(Paper)({
  //padding: "24px",
  border: "none",
});

const StyledSecondSection = styled(Paper)({
  padding: "24px",
  paddingTop: 36,
  border: "none",
});

const StyledRoot = styled("div")({
  marginBottom: 36,
  backgroundColor: "#FFFFFF !important"
});

const CandidateProfilePage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const formRef = useRef();
  const [openEducation, setOpenEducation] = useState(false);
  const [openSkill, setOpenSkill] = useState(false);
  const [openExperience, setOpenExperience] = useState(false);
  const [fileSelected, setFileSelected] = useState([]);
  const [dataEmployee, setDataEmployee] = useState({
    username: "",
    email: "",
    image: "",
    firstname: "",
    lastname: "",
    idDepartment: "",
    seniority: "",
    expertise: "",
    experience: "",
    typeEmployee: "",
    department: "",
    position: "",
    mobileNumber: "",
    workingLocation: "",
    site: "",
    status: true,
    company: "Thai Polyethylene Co., Ltd.",
    authorities: {
      user: true,
      manager: false,
      admin: false,
    },
  });


  useEffect(
    () => () => {
      loadDataEmployee();
      // Make sure to revoke the data uris to avoid memory leaks
      fileSelected.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [fileSelected]
  );

  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      try {
        const { data: result } = await UserService.getUserProfile(
          props.match.params.id
        );

        if (!isCancelled && result) {
          const authorities = {
            user: result.authorities.indexOf("ROLE_USER") > 0,
            manager: result.authorities.indexOf("ROLE_MANAGER") > 0,
            admin: result.authorities.indexOf("ROLE_ADMIN") > 0,
          };
          result.status = result.status == "active";
          result.authorities = authorities;

          convertImgToDataURLviaCanvas(
            `${process.env.REACT_APP_URL}image/profile/${result.image}`,
            function (base64_data) {
              console.log(base64_data);
              var arr = base64_data.split(",");
              var mime = arr[0].match(/:(.*?);/)[1];
              var bstr = atob(arr[1]);
              var n = bstr.length;
              var u8arr = new Uint8Array(n);
              while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
              }
              var files = [];
              var f = new Blob([u8arr], { type: mime });
              const file = {
                preview: URL.createObjectURL(f),
              };

              files.push(file);
              setFileSelected(files);
            }
          );

          setDataEmployee(result);
        }
      } catch (e) {
        if (!isCancelled) {
          console.log(e);
        }
      }
    };

    if (props.match.params.id) fetchData();

    return () => {
      isCancelled = true;
    };
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    disabled: true,
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      let formData = new FormData();
      acceptedFiles.map((file) => formData.append("file", file));
      setFileSelected(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    maxFiles: 1,
  });

  const thumbs = fileSelected.map((file) => (
    <img key={file.name} src={file.preview} className={classes.uploadImage} />
  ));

  const handleChange = (event) => {
    setDataEmployee({
      ...dataEmployee,
      [event.target.name]: event.target.value,
    });
  };

  const handleCloseDialog = () => {
    setOpenEducation(false);
    setOpenExperience(false);
    setOpenSkill(false);
  };

  const loadDataEmployee = () => {};

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div>
          {props.match.params.id ? (
            <HeaderPage textLabel={"แก้ไขข้อมูล"} />
          ) : (
            <HeaderPage textLabel={"Candidate Profile"} />
          )}
        </div>
        <StyleCard>
          <StyledSecondSection variant="outlined">
            <div style={{ display: "flex" }}>
              <Avatar
                style={{
                  width: 28,
                  height: 28,
                  marginRight: 16,
                  backgroundColor: "#1875d1",
                }}
              >
                1
              </Avatar>
              <Typography style={{ marginBottom: 16, fontSize: 20 }}>
                {`ข้อมูลทั่วไป`}
              </Typography>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <StepOne
                  handleChange={handleChange}
                  dataEmployee={dataEmployee}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledFirstSection variant="outlined">
                  <div className={classes.imageProfile}>
                    <div
                      className="dropzone"
                      style={{ width: 200, height: 200 }}
                    >
                      <div className="inner-dropzone">
                        <input {...getInputProps()} />
                        <Fragment>{thumbs}</Fragment>
                        <div
                          className={`placeholder ${classes.placeholder} ${
                            fileSelected.length != 0 &&
                            classes.placeholderImageProfile
                          }`}
                        >
                          <img
                            alt="pic"
                            width={195}
                            src={`${process.env.REACT_APP_API_URL}image/profile/23.jpg`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </StyledFirstSection>
              </Grid>
            </Grid>

            <Divider className={classes.divider} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <Avatar
                  style={{
                    width: 28,
                    height: 28,
                    marginRight: 16,
                    backgroundColor: "#1875d1",
                  }}
                >
                  2
                </Avatar>
                <Typography style={{ marginBottom: 16, fontSize: 20 }}>
                  {`ประวัติการศึกษา`}
                </Typography>
              </div>
            </div>
            <StepTwo />
            <Divider className={classes.divider} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <Avatar
                  style={{
                    width: 28,
                    height: 28,
                    marginRight: 16,
                    backgroundColor: "#1875d1",
                  }}
                >
                  3
                </Avatar>
                <Typography style={{ marginBottom: 16, fontSize: 20 }}>
                  {`ทักษะของคุณ`}
                </Typography>
              </div>
            </div>
            <StepThree />
            <Divider className={classes.divider} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <Avatar
                  style={{
                    width: 28,
                    height: 28,
                    marginRight: 16,
                    backgroundColor: "#1875d1",
                  }}
                >
                  4
                </Avatar>
                <Typography style={{ marginBottom: 16, fontSize: 20 }}>
                  {`ประสบการณ์ทำงาน`}
                </Typography>
              </div>
            </div>
            <StepFour />
          </StyledSecondSection>
        </StyleCard>
      </Container>
    </StyledRoot>
  );
};

export default CandidateProfilePage;
