import React from "react";
import { Card, CardContent, Typography, Avatar, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const CardCoin = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        minWidth: "200px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        p: 2,
        m: 2,
        borderRadius: 4,
        boxShadow: 3,
      }}
    >
      <Typography variant="h6" component="div" sx={{ mb: 2, fontWeight: 600 }}>
        {data.coinName}
      </Typography>
      <Avatar
        src={data.imageURL}
        alt={data.coinName}
        sx={{ width: 100, height: 100 }}
      />
      <Box
        sx={{
          bgcolor: "#F1F0F0",
          borderRadius: 4,
          px: 2,
          py: 1,
          mt: 2,
          minWidth: "60%",
        }}
      >
        <Typography align="center" sx={{ fontWeight: 500 }}>
          {t("Amount")}: {data.amount}
        </Typography>
      </Box>
    </Card>
  );
};

export default CardCoin;