import flexCoinService from "../services/flexCoin.service";
import { FLEXCOIN_FETCHING, FLEXCOIN_FAILED, FLEXCOIN_SUCCESS } from "./types";

export const getCoinBalance = (idEmployee) => async (dispatch) => {
  try {
    dispatch({
      type: FLEXCOIN_FETCHING,
    });
    const res = await flexCoinService.getCoinBalance(idEmployee);
    if (res) {
      dispatch({
        type: FLEXCOIN_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: FLEXCOIN_FAILED,
    });
    return error;
  }
};
