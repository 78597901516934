import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { Grid, Typography, Box, TextField, Popper } from "@mui/material";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import DatePickerCustom from "../../../shared/date/datePicker";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import { useTranslation } from "react-i18next";
import SelectAffiliateCompany from "../../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../../actions/company";
import { getAllEmployeesByIdCompany } from "../../../../../actions/employee";
import PersonalTaxInfo from "../../../shared/personalTax";
import AnimationBackdrop from "../../../shared/general/Backdrop";
import { getPersonalTaxByMonth } from "../../../../../actions/personal-tax";
import AlertResponse from "../../../shared/general/AlertResponse";

const StyledWrapSearch = styled("div")({
  padding: 16,
  marginBottom: 16,
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledBoxSearchButton = styled(Box)({
  marginTop: 28,
  display: "flex",
  justifyContent: "space-evenly",
  gap: 8,
});

const EmployeeTax = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { isFetching: personalTaxIsFetching, result: personalTax } =
    useSelector((state) => state.personalTax);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );
  const { result: employeesList } = useSelector((state) => state.employees);

  const [searchDate, setSearchDate] = useState(new Date());
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };


  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getAllEmployeesByIdCompany(selectedCompany.idCompany));
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (
      userProfile &&
      affiliateOrganizationList &&
      affiliateOrganizationList.length > 0
    ) {
      const foundCompany = affiliateOrganizationList.find(
        (x) => x.idCompany === userProfile.idCompany
      );
      if (foundCompany) {
        setSelectedCompany(foundCompany);
      } else {
        setSelectedCompany(affiliateOrganizationList[0]);
      }
    }
  }, [userProfile, affiliateOrganizationList]);

  const handleClickSearch = () => {
    if (selectedEmployee && searchDate) {
      dispatch(
        getPersonalTaxByMonth(
          dayjs(searchDate).format("YYYY-MM-DD"),
          selectedEmployee.idEmployees
        )
      );
    }
  };

  useEffect(() => {
    if ((personalTax && personalTax.length < 1) || !personalTax) {
      handleChangeAlertType(t("NoData"));
      handleOpenAlert(true);
    }
  }, [personalTax]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box padding="16px 16px 0">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("Company")}
                  </Typography>
                  <SelectAffiliateCompany
                    options={affiliateOrganizationList}
                    value={selectedCompany}
                    onChange={(_, value) => {
                      setSelectedEmployee(null);
                      setSelectedCompany(value);
                    }}
                    disabled={personalTaxIsFetching}
                  />
                </StyledBoxSearch>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <StyledWrapSearch>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("SearchDate")}
                  </Typography>
                  <div className="searchDate-date">
                    <DatePickerCustom
                      inputFormat="MMMM YYYY"
                      value={searchDate}
                      minDate={new Date().setFullYear(
                        new Date().getFullYear() - 2
                      )}
                      maxDate={new Date()}
                      name="start"
                      views={["year", "month"]}
                      onChange={(newValue) => {
                        setSearchDate(newValue);
                      }}
                      disabled={personalTaxIsFetching}
                    />
                  </div>
                </StyledBoxSearch>
              </Grid>
              {employeesList && employeesList.length > 0 && (
                <>
                  <Grid item xs={12} sm={4}>
                    <StyledBoxSearch>
                      <Typography className="label" color="text.third">
                        {t("Employee")}
                      </Typography>
                      <div className="searchDate-name">
                        <StyledAutocomplete
                          options={employeesList}
                          onChange={(event, newValue) => {
                            console.log("newValue", newValue);
                            if (newValue) {
                              setSelectedEmployee(newValue);
                            } else {
                              setSelectedEmployee(null);
                            }
                          }}
                          loading={personalTaxIsFetching}
                          popupIcon={
                            <i className="fa-light fa-chevron-down"></i>
                          }
                          getOptionLabel={(option) =>
                            i18n.resolvedLanguage === "th"
                              ? `${option.firstname_TH} ${option.lastname_TH}`
                              : `${option.firstname_EN} ${option.lastname_EN}`
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              placeholder={t("SearchFullName")}
                            />
                          )}
                          PopperComponent={StyledPopper}
                          noOptionsText={t("NoData")}
                        />
                      </div>
                    </StyledBoxSearch>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="baseline"
                    >
                      <StyledBoxSearchButton>
                        <div style={{ marginTop: 20 }}>
                          <ButtonBlue
                            startIcon={<SearchRoundedIcon />}
                            variant="contained"
                            onClick={handleClickSearch}
                            disabled={
                              selectedEmployee === null || searchDate === null
                            }
                          >
                            {t("Search")}
                          </ButtonBlue>
                        </div>
                      </StyledBoxSearchButton>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </StyledWrapSearch>
        </Grid>
        <Grid item xs={12}>
          {personalTax ? (
            <PersonalTaxInfo personalTax={personalTax} />
          ) : personalTaxIsFetching === true ? (
            <AnimationBackdrop />
          ) : (
            <Typography variant="h6" textAlign="center">
              {t("NoData")}
            </Typography>
          )}
        </Grid>
      </Grid>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </div>
  );
};

export default EmployeeTax;
