import { 
  HOLIDAY_FETCHING, HOLIDAY_FAILED, HOLIDAY_SUCCESS,
  HOLIDAY_PATTERN_FETCHING, HOLIDAY_PATTERN_FAILED, HOLIDAY_PATTERN_SUCCESS
} from "./types";

import HolidayService from "../services/holiday.service";

export const allHoliday = (year, query) => async (dispatch) => {
  try {
    dispatch({
      type: HOLIDAY_FETCHING,
    });
    const res = await HolidayService.allHoliday(year, query);
    if (res) {
      dispatch({
        type: HOLIDAY_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: HOLIDAY_FAILED,
    });
    return err;
  }
};

export const getHoliday = (query) => async (dispatch) => {
  try {
    dispatch({
      type: HOLIDAY_FETCHING,
    });
    const res = await HolidayService.getHoliday(query);
    if (res) {
      dispatch({
        type: HOLIDAY_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: HOLIDAY_FAILED,
    });
    return err;
  }
};

export const getAllHolidayByEmployeeAndDateTime = (query) => async (dispatch) => {
  try {
    dispatch({
      type: HOLIDAY_FETCHING,
    });
    const res = await HolidayService.getAllHolidayByEmployeeAndDateTime(query);
    if (res) {
      dispatch({
        type: HOLIDAY_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: HOLIDAY_FAILED,
    });
    return err;
  }
};

export const postHoliday = (formData) => async () => {
  try {
    const res = await HolidayService.postHoliday(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putHoliday = (formData) => async () => {
  try {
    const res = await HolidayService.putHoliday(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const deleteHoliday = (id, formData) => async () => {
  try {
    const res = await HolidayService.deleteHoliday(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const uploadFileHoliday = (formData) => async () => {
  try {
    const res = await HolidayService.uploadFileHoliday(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getAllHolidaysPattern = (query) => async (dispatch) => {
  try {
    dispatch({
      type: HOLIDAY_PATTERN_FETCHING,
    });
    const res = await HolidayService.getAllHolidaysPattern(query);
    if (res) {
      dispatch({
        type: HOLIDAY_PATTERN_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: HOLIDAY_PATTERN_FAILED,
    });
    return err;
  }
};