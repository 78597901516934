import React, { Fragment, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAdditionSpecialDiligenceAllowanceSetting } from "../../../../../../actions/additionSpecial";

const AdditionSpecialDiligenceAllowance = (props) => {

  const { selectedCompany } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: AdditionSpecialDiligenceAllowanceSetting, isFetching: isFetchingAdditionSpecialDiligenceAllowanceSetting } = useSelector(state => state.additionSpecialDiligenceAllowanceSetting)

  useEffect(() => {
    if(selectedCompany){
      dispatch(getAdditionSpecialDiligenceAllowanceSetting({
        idCompany: selectedCompany.idCompany
      }))
    }
  }, [selectedCompany])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          sx={{
            paddingLeft: "8px",
            borderLeft: "8px solid #46cbe2",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
          }}
          fontSize="20px"
          fontWeight="500"
        >
          {`${t("DiligenceAllowance")}`}
        </Typography>
      </Grid>

      {isFetchingAdditionSpecialDiligenceAllowanceSetting? (
        <Grid item xs={12}>
          <Typography fontSize="18px" textAlign="center" fontStyle="oblique">{t("LoadingData")}...</Typography>
        </Grid>
      ) : (
        <Fragment>
          {AdditionSpecialDiligenceAllowanceSetting.isInitSetting? (
            <Fragment>
              <Grid item xs={12}>
                <Typography>{t("eligibilityConditionsForAttendanceBonus")}</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography color="text.secondary" fontWeight="500">{t("noAbsenteeism")}</Typography>
                <Typography fontSize="20px">{AdditionSpecialDiligenceAllowanceSetting.isNotAbsent? t("Yes"): t("No")}</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography color="text.secondary" fontWeight="500">{t("NoLate")}</Typography>
                <Typography fontSize="20px">{AdditionSpecialDiligenceAllowanceSetting.isNotLate? t("Yes"): t("No")}</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography color="text.secondary" fontWeight="500">{t("noEarlyLeave")}</Typography>
                <Typography fontSize="20px">{AdditionSpecialDiligenceAllowanceSetting.isNotEarlyBack? t("Yes"): t("No")}</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography color="text.secondary" fontWeight="500">{t("fullMonthAttendance")}</Typography>
                <Typography fontSize="20px">{AdditionSpecialDiligenceAllowanceSetting.isHiredFullMonth? t("Yes"): t("No")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="text.secondary" fontWeight="500">{t("noLeaveExcept")}</Typography>
                <Typography fontSize="20px">
                  {AdditionSpecialDiligenceAllowanceSetting.leaveExclude.length > 0? (
                    AdditionSpecialDiligenceAllowanceSetting.leaveExclude.map(item => item.leaveGroupName).join(", ")
                  ): (
                    t("NoFee")
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="text.secondary" fontWeight="500">{t("attendanceBonusConditions")}</Typography>
                <Typography fontSize="20px">
                  {AdditionSpecialDiligenceAllowanceSetting.conditionType === "CONSTANT"? (
                    t("fixed")
                  ) : AdditionSpecialDiligenceAllowanceSetting.conditionType === "STEP_ACCUMULATE_MONTH_IN_ROW"? (
                    t("staircase")
                  ) : (
                    t("NotSpecified")
                  )}
                </Typography>
              </Grid>
              {AdditionSpecialDiligenceAllowanceSetting.conditionType === "CONSTANT"? (
                <Grid item xs={12}>
                  <Typography color="text.secondary" fontWeight="500">{t("attendanceBonus")}</Typography>
                  <Typography fontSize="20px">{AdditionSpecialDiligenceAllowanceSetting.amount} {t("Baht")}</Typography>
                </Grid>
              ) : AdditionSpecialDiligenceAllowanceSetting.conditionType === "STEP_ACCUMULATE_MONTH_IN_ROW"? (
                <Fragment>
                  {AdditionSpecialDiligenceAllowanceSetting.step.map((item, indexStep) => (
                    <Fragment key={`step_${indexStep}`}>
                      <Grid item xs={6} md={4}>
                        <Typography color="text.secondary" fontWeight="500">{t("from")}</Typography>
                        <Typography fontSize="20px">{item.start} {t("Month")}</Typography>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Typography color="text.secondary" fontWeight="500">{t("until")}</Typography>
                        <Typography fontSize="20px">{item.end === null? t("onwards"): `${item.end} ${t("Month")}`}</Typography>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Typography color="text.secondary" fontWeight="500">{t("attendanceBonus")}</Typography>
                        <Typography fontSize="20px">{item.amount} {t("Baht")}</Typography>
                      </Grid>
                    </Fragment>
                  ))}
                </Fragment>
              ) : (
                null
              )}
            </Fragment>
          ) : (
            <Grid item xs={12}>
              <Typography fontSize="18px" textAlign="center" fontStyle="oblique" color="text.secondary">{t("noAttendanceBonusSetting")}</Typography>
            </Grid>
          )}
        </Fragment>
      )}

    </Grid>
  )
}

export default AdditionSpecialDiligenceAllowance;