import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import { Box, Popper, TextField, Typography } from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";

import { getManager } from "../../../../actions/manager";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getAllDivision } from "../../../../actions/company";
import { getUserDivision } from "../../../../utils/userData";

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledPopper = styled(Popper)({
  width: "330px !important",

  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    minHeight: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const SelectDivision = (props) => {
  const { handleChange, value, idCompany, multiple } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: division } = useSelector((state) => state.dataDivision);

  useEffect(() => {
    dispatch(getAllDivision(idCompany));
  }, []);

  return (
    <StyledBoxSearch>
      <StyledAutocomplete
        multiple={multiple}
        options={division || []}
        onChange={(_, newValue) => handleChange(newValue)}
        value={value}
        closeIcon={null}
        popupIcon={<i className="fa-light fa-chevron-down"></i>}
        getOptionLabel={(option) => getUserDivision(option)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            placeholder={t("Division")}
            InputProps={{ ...params.InputProps, endAdornment: null }}
          />
        )}
        PopperComponent={StyledPopper}
        noOptionsText={t("NoData")}
      />
    </StyledBoxSearch>
  );
};

export default SelectDivision;
