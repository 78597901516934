import React, { useEffect, useState } from "react";
import { Container, Grid, styled, Typography } from "@mui/material";
import CardStyle from "../../../shared/general/Card";
import GraphManpowerSentSuccess from "./graphManpower-sent-success";
import GraphManpowerIncrease from "./graphManpowerIncrease";
import StyledStatCard from "./statCard";

import { useDispatch, useSelector } from "react-redux";
import { getDashboardRecruit } from "../../../../../actions/dashboardRecruit";
import ManpowerDasboard from "./manpowerDashboard";
import ListIcon from '@mui/icons-material/List';
import MenuUser from "../homePage/menuUser";
import ButtonBlue from "../../../shared/general/ButtonBlue";

const StyledRoot = styled("div")({
  background: "#FFFFFF !important",
  "& .part-header": {
    padding: "24px 0",
  },
  "& .MuiCard-root": {
    color: "#000000",
    backgroundColor: "#ffffff"
  },
  "& .btn-save": {
    display: "flex",
    justifyContent: "flex-end",
  },
})

const StyledCard = styled("div")({
  "& .card-dashboard": {
    padding: 24,
    borderRadius: 20,
    border: "1px solid #ececec",
    position: "relative",
    "& .part-header-card": {
      fontSize: 16,
    },
    "& .part-value": {
      paddingRight: 60,
      fontSize: 36,
      fontWeight: 500,
    },
    "& .part-icon": {
      position: "absolute",
      right: 24,
      bottom: 24,
      fontSize: 48,
    }
  },
})

const StyledDetailCard = styled("div")({
  padding: 24,
  paddingTop: 16,
  "& .part-header-detail": {
    paddingBottom: 24,
  }
})

const HunterDashboard = () => {

  const dispatch = useDispatch();

  const { result: dashboardRecruit } = useSelector(state => state.dashboardRecruit);
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    dispatch(getDashboardRecruit())
  }, [])

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div className="btn-save">
          <ButtonBlue
            variant="outlined"
            onClick={(event) => { setAnchorEl(event.currentTarget) }}
            >
            <ListIcon sx={{ fontSize: "30px" }} />
            &nbsp;
            {("เมนูรายการ")}
          </ButtonBlue>
          <MenuUser
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            />
        </div>
      </Container>
      <Container maxWidth="xl">

        <div className="part-header">
          <Typography variant="h4" sx={{ color: "#ffffff" }}>แดชบอร์ด</Typography>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <StyledStatCard title="กำลังพลทั้งหมด" monthlyValue={`+${(dashboardRecruit && dashboardRecruit.manpowerCurrentMonthCount)}`} value={(dashboardRecruit && dashboardRecruit.manpowerCount)} unit="คน" />
              </Grid>
              <Grid item xs={12} md={3}>
                <StyledStatCard title="จำนวนครั้งที่ส่ง" monthlyValue={`+${(dashboardRecruit && dashboardRecruit.manpowerSentCurrentMonth)}`} value={(dashboardRecruit && dashboardRecruit.manpowerSent) || "-"} unit="ครั้ง" />
              </Grid>
              <Grid item xs={12} md={3}>
                <StyledStatCard title="จำนวนครั้งที่สำเร็จ" monthlyValue={`+${(dashboardRecruit && dashboardRecruit.manpowerSuccessCurrentMonth)}`} value={(dashboardRecruit && dashboardRecruit.manpowerSuccess) || "-"} unit="ครั้ง" />
              </Grid>
              <Grid item xs={12} md={3}>
                <StyledStatCard title="กำลังรอผลสัมภาษณ์" value={(dashboardRecruit && dashboardRecruit.manpowerWaitingInterview)} unit="คน" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="stretch">
              <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                  {/* <Grid item xs={12}>
                    <CardStyle>
                      <StyledDetailCard>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: 100}}>
                          <Rating
                            defaultValue={3.5}
                            precision={0.5}
                            icon={<i className="fa-solid fa-star"></i>}
                            emptyIcon={<i className="fa-regular fa-star"></i>}
                            readOnly
                            style={{fontSize: 48}}
                          />
                          <Typography
                            lineHeight={1}
                            style={{fontSize: 48, paddingLeft: 8, color: "#faaf00", fontWeight: 500}}
                          >
                            3.5
                          </Typography>
                        </div> 
                      </StyledDetailCard>
                    </CardStyle>
                  </Grid> */}

                  <Grid item xs={12}>
                    <CardStyle>
                      <StyledDetailCard>
                        <div style={{ display: "flex", paddingBottom: 16 }}>
                          <Typography fontSize={24} style={{ flexGrow: 1 }}>กำลังพล</Typography>
                          <Typography fontSize={24}>เดือนนี้ {(dashboardRecruit && dashboardRecruit.manpowerCurrentMonthCount) || 0} คน</Typography>
                        </div>

                        <ManpowerDasboard value={(dashboardRecruit && dashboardRecruit.manpower) || []} />
                      </StyledDetailCard>
                    </CardStyle>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CardStyle>
                  <StyledDetailCard>
                    <GraphManpowerSentSuccess monthlyManpowerSentList={(dashboardRecruit && dashboardRecruit.monthlyManpowerSent) || []} monthlyManpowerSuccessList={(dashboardRecruit && dashboardRecruit.monthlyManpowerSuccess) || []} />
                  </StyledDetailCard>
                </CardStyle>
              </Grid>

              <Grid item xs={12} md={6}>
                <CardStyle>
                  <StyledDetailCard>
                    <GraphManpowerIncrease monthlyManpowerList={(dashboardRecruit && dashboardRecruit.monthlyManpower) || []} />
                  </StyledDetailCard>
                </CardStyle>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </StyledRoot>
  )
}

export default HunterDashboard;