import React, { useState } from "react";
import Chart from "react-apexcharts";
import Utils from "../../../../utils";

const ChartDonutCustom = (props) => {

  // const { color, value, labelTooltip } = props;
  const { labels, series } = props;

  // const [series, setseries] = useState([50120.12, 20120.12, 80120.12]);

  const [optionsChart, setOptionsChart] = useState({
    labels: labels,
    chart: {
      selection: {
        enabled: false,
      },
      type: "donut",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    fill: {
      opacity: 0.8
    },
    stroke: {
      width: 2,
      colors: ['#fff']
    },
    yaxis: {
      show: true,
    },
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      labels: {
        formatter: (value) => { return Utils.numberWithCommas(value) },
      }
    },
    plotOptions: {
      polarArea: {
        rings: {
          strokeWidth: 2,
        },
        spokes: {
          strokeWidth: 0,
        },
      },
    },
    // theme: {
    //   monochrome: {
    //     enabled: true,
    //     shadeTo: "light",
    //     shadeIntensity: 0.6,
    //   },
    // },
  });

  return (
    <Chart height="250px" options={optionsChart} series={series} type="donut"/>
  )
}

export default ChartDonutCustom;