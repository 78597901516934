import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import XLSX from "xlsx";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import {
    Container,
    Grid,
    Typography,
    TextField,
    Box,
    Popper,
    Paper,
    Breadcrumbs,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ButtonBlue from "../../shared/general/ButtonBlue";
import Backdrop from "../../shared/general/Backdrop";
import DialogSetting from "./component/dialogSetting";
import AlertResponse from "../../shared/general/AlertResponse";
import { getPayrollCompany, getPayrollemployee } from "../../../../actions/report";
import { getAffiliate } from "../../../../actions/company";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../shared/date/datePicker";
import { PayrollDataGrid } from "./component/dataGrid";
import { getUserCompany, getUserDivision, getUserFirstName, getUserLastName, getUserPosition, getUserSection } from "../../../../utils/userData";

const StyledRoot = styled("div")({
    backgroundColor: "#FFFFFF !important",
    paddingBottom: 48,
});

const StyledWrapFilter = styled("div")({
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: 36,
    ["@media (min-width: 0px)"]: {
        flexDirection: "column",
    },
    ["@media (min-width: 900px)"]: {
        flexDirection: "row",
    },
    "& .search-name, .search-date": {
        width: "100%",
    },
    "& .wrap-search-action": {
        marginTop: 50,
        display: "flex",
        justifyContent: "flex-start",
        "& .btn-export": {
            marginLeft: 8,
        },
    },
});

const StyledBoxSearch = styled(Box)({
    marginTop: 22,
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});

const Payroll = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const options = [
        `${t("Download")} ${t("CompanyInformation")}`,
        `${t("Download")} ${t("PersonalInformation")}`,
    ];

    const typeOptions = [
        { name: t("TotalIncome"), dataField: "earning" },
        { name: t("AppMenu.Payroll"), dataField: "salary" },
        { name: `${t("Total")}${t("OvertimePay")}`, dataField: "ot" },
        { name: t("Addition"), dataField: "additions" },
        { name: t("CompanySocialSecurity"), dataField: "provident" },
        { name: t("TaxRemitted"), dataField: "tax" },
        { name: `${t("ProvidentFund")}${t("CompanyPart")}`, dataField: "pfCompany" },
        { name: `${t("ProvidentFund")}${t("EmployeePart")}`, dataField: "pfEmployee" },
        { name: t("ShiftValue"), dataField: "shift" },
        { name: t("Deduction"), dataField: "deduction" },
        { name: t("NetIncome"), dataField: "net" },
    ];

    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: affiliateList } = useSelector((state) => state.affiliate);
    const { result: payRun } = useSelector((state) => state.payRun);
    const { result: payslip } = useSelector((state) => state.payslip);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [inputSearch, setInputSearch] = useState(new Date());
    const [companyName, setCompanyName] = useState('');
    const [selectedType, setSelectedType] = useState(typeOptions.length > 0 ? typeOptions[10].dataField : "");
    const [openDrawer, setOpenDrawer] = useState(false);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [value, setValue] = useState(`${t("CompanyInformation")}`);
    const [isAffiliateFetched, setIsAffiliateFetched] = useState(false);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchAffiliateData = () => {
        if (!isAffiliateFetched) {
            dispatch(getAffiliate());
            setIsAffiliateFetched(true);
        }
    };

    const fetchPayrollData = () => {
        if (selectedCompany && inputSearch) {
            setLoading(true);
            dispatch(getPayrollCompany(selectedCompany, dayjs(inputSearch).startOf("month")));
            dispatch(getPayrollemployee(selectedCompany, dayjs(inputSearch).startOf("month")));
        }
    };

    useEffect(() => {
        if (selectedCompany && inputSearch) {
            fetchPayrollData();
        }
    }, [selectedCompany, inputSearch]);

    useEffect(() => {
        if (payRun !== undefined) setLoading(false);
    }, [payRun]);

    const handleClick = () => {
        if (selectedIndex === 0 || selectedIndex === 1) {
            onExportExcel();
        } else {
            setOpenDrawer(true);
        }
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
        setValue(index === 0 ? `${t("CompanyInformation")}` : `${t("PersonalInformation")}`);
    };

    const handleColumnChange = (value) => setSelectedType(value);

    const handleToggle = () => setOpen((prevOpen) => !prevOpen);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleOpenAlert = () => setOpenAlert(true);

    const handleCloseAlert = () => setOpenAlert(false);

    const handleChangeAlertType = (type) => setAlertType(type);

    const onExportExcel = () => {
        if (payRun && payRun.length > 0) {
            const elementList = [];
            if (value == t("PersonalInformation")) {
                payslip.map((item, index) => {
                    let element = {};
                    element["#"] = index + 1;
                    element[t("EmployeeID")] = item.employeeId;
                    element[t("FirstName")] = getUserFirstName(item);
                    element[t("LastName")] = getUserLastName(item);
                    element[t("PersonalID")] = item.personalId;
                    element[t("Position")] = getUserPosition(item);
                    element[t("Company")] = getUserCompany(item);
                    element[t("Department")] = getUserDivision(item);
                    element[t("Section")] = getUserSection(item);
                    for (let m = 1; m < 13; m++) {
                        let month = "item.m" + m;
                        if (eval(month)) {
                            element[`${t("NetIncome")} ${t("Month")}${m}`] = eval(month).net;
                            element[`${t("OnlyIncome")} ${t("Month")}${m}`] = eval(month).totalEarnings;
                            element[`${t("OnlyDeductions")} ${t("Month")}${m}`] = eval(month).totalDeductions;
                            element[`${t("OnlySocialSecurityDeductions")} ${t("Month")} ${m}`] = eval(month).totalPF;
                            element[`${t("OnlyTaxDeductions")} ${t("Month")}${m}`] = eval(month).totalTax;
                        } else {
                            element[`${t("NetIncome")} ${t("Month")}${m}`] = "";
                            element[`${t("OnlyIncome")} ${t("Month")}${m}`] = "";
                            element[`${t("OnlyDeductions")} ${t("Month")}${m}`] = "";
                            element[`${t("OnlySocialSecurityDeductions")} ${t("Month")}${m}`] = "";
                            element[`${t("OnlyTaxDeductions")} ${t("Month")}${m}`] = "";
                        }
                    }
                    elementList.push(element);
                });
            } else if (value == t("CompanyInformation")) {
                let element = {};
                element[t("Company")] = getUserCompany(userProfile);
                payRun.map((item, index) => {
                    element[`${t("TotalIncome")} ${t("Month")}${item.id + 1}`] = item.value.earning;
                    element[`${t("AppMenu.Payroll")} ${t("Month")}${item.id + 1}`] = item.value.salary;
                    element[`OT ${t("Month")}${item.id + 1}`] = item.value.ot;
                    element[`${t("ShiftValue")} ${t("Month")}${item.id + 1}`] = item.value.shift;
                    element[`${t("TaxRemitted")} ${t("Month")}${item.id + 1}`] = item.value.tax;
                    element[`${t("CompanySocialSecurity")} ${t("Month")}${item.id + 1}`] =
                        item.value.provident;
                    element[`${t("EmployeeSocialSecurity")} ${t("Month")}${item.id + 1}`] =
                        item.value.provident;
                    element[`PF${t("CompanyPart")} ${t("Month")}${item.id + 1}`] = item.value.pfCompany;
                    element[`PF${t("EmployeePart")} ${t("Month")}${item.id + 1}`] = item.value.pfEmployee;
                    element[`${t("Addition")} (Addition) ${t("Month")}${item.id + 1}`] =
                        item.value.additions;
                    element[`${t("Deduction")} (Deduction) ${t("Month")}${item.id + 1}`] =
                        item.value.deduction;
                    element[`${t("NetIncome")} ${t("Month")}${item.id + 1}`] = item.value.net;
                });
                elementList.push(element);
            }
            const workSheet = XLSX.utils.json_to_sheet(elementList);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
                workBook,
                workSheet,
                `${t("AppMenu.Payroll")}`
            );
            XLSX.writeFile(
                workBook,
                `${t("PayrollReport")} ${dayjs(new Date()).format("YYYY-MM-DD")}.xlsx`
            );
        } else {
            handleChangeAlertType(t("NoData"));
            handleOpenAlert();
        }
    };

    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <Link to={"/employees-report"} style={{ textDecoration: "none", color: "inherit" }}>
                        {`${t("AllReports")}`}
                    </Link>
                    <Typography color="text.primary">{t("PayrollReport")}</Typography>
                </Breadcrumbs>
                <div style={{ marginBottom: 8 }}>
                    <Typography variant="h4" style={{ paddingTop: 8 }}>
                        {`${t("PayrollReport")}`}
                    </Typography>
                </div>
                <StyledWrapFilter>
                    <Grid container spacing={2} columns={14} alignItems="center">
                        <Grid item xs={3}>
                            <StyledBoxSearch onClick={fetchAffiliateData}>
                                <Typography className="label" color="text.third">{`${t("Company")}`}</Typography>
                                <Autocomplete
                                    options={affiliateList || []}
                                    onChange={(event, newValue) => {
                                        setSelectedCompany(newValue ? newValue.idCompany : null);
                                        setCompanyName(newValue ? getUserCompany(newValue) : '');
                                    }}
                                    getOptionLabel={(option) => getUserCompany(option)}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="filled" placeholder={`${t("SelectCompany")}`} />
                                    )}
                                    noOptionsText={`${t("NoData")}`}
                                />
                            </StyledBoxSearch>
                        </Grid>
                        <Grid item xs={3}>
                            <StyledBoxSearch>
                                <Typography className="label" color="text.third">{`${t("Year")}`}</Typography>
                                <DatePickerCustom
                                    minDate={new Date(new Date().getFullYear() - 4, 0, 1)}
                                    maxDate={new Date()}
                                    inputFormat="YYYY"
                                    value={inputSearch}
                                    name="start"
                                    views={["year"]}
                                    openTo="year"
                                    onChange={(newValue) => setInputSearch(newValue)}
                                />
                            </StyledBoxSearch>
                        </Grid>
                        <Grid item xs={3}>
                            <StyledBoxSearch>
                                <Typography className="name">{t("SelectType")}</Typography>
                                <Autocomplete
                                    disablePortal
                                    options={typeOptions}
                                    value={typeOptions.find(option => option.dataField === selectedType) || typeOptions[10]}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                        setSelectedType(newValue && newValue.dataField ? newValue.dataField : typeOptions[10].dataField);
                                        handleColumnChange(newValue && newValue.dataField ? newValue.dataField : typeOptions[10].dataField);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="filled" placeholder={`${t("SelectType")}`} />
                                    )}
                                />
                            </StyledBoxSearch>
                        </Grid>

                        <Grid item xs={3}>
                            <StyledBoxSearch>
                                <div className="wrap-search-action">
                                    <Fragment>
                                        <ButtonGroup ref={anchorRef} aria-label="split button">
                                            <ButtonBlue
                                                variant="outlined"
                                                onClick={handleClick}
                                                disabled={!selectedCompany}>
                                                {options[selectedIndex]}
                                            </ButtonBlue>
                                            <ButtonBlue
                                                size="small"
                                                variant="outlined"
                                                aria-controls={open ? "split-button-menu" : undefined}
                                                aria-expanded={open ? "true" : undefined}
                                                aria-label="select merge strategy"
                                                aria-haspopup="menu"
                                                onClick={handleToggle}
                                                disabled={!selectedCompany}
                                            >
                                                <ArrowDropDownIcon />
                                            </ButtonBlue>
                                        </ButtonGroup>
                                        <Popper
                                            sx={{
                                                zIndex: 1,
                                            }}
                                            open={open}
                                            anchorEl={anchorRef.current}
                                            role={undefined}
                                            transition
                                            disablePortal
                                        >
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{
                                                        transformOrigin: "center top",
                                                    }}
                                                >
                                                    <Paper>
                                                        <ClickAwayListener onClickAway={handleClose}>
                                                            <MenuList id="split-button-menu" autoFocusItem>
                                                                {options.map((option, index) => (
                                                                    <MenuItem
                                                                        key={option}
                                                                        disabled={index === 0 && payRun === null}
                                                                        selected={index === selectedIndex}
                                                                        onClick={(event) =>
                                                                            handleMenuItemClick(event, index)
                                                                        }
                                                                    >
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </Fragment>
                                </div>
                            </StyledBoxSearch>
                        </Grid>
                    </Grid>
                </StyledWrapFilter>

                {loading ? (
                    <Backdrop open={true} />
                ) : payRun && payRun.length > 0 ? (
                    <PayrollDataGrid data={payRun} companyName={companyName} option={selectedType} />
                ) : (
                    <PayrollDataGrid data={{}} companyName={t("CompanyName")} option={selectedType} />
                )}
            </Container>

            {openDrawer && (
                <DialogSetting setData={setData} handleClose={() => setOpenDrawer(false)} open={openDrawer} data={data} />
            )}
            <AlertResponse open={openAlert} handleClose={handleCloseAlert} alertType={alertType} />
        </StyledRoot>
    );
};

export default Payroll;
