import {
  NOTIFICATION_ALERT_OPEN,
  NOTIFICATION_ALERT_CLOSE
} from "./types";

export const openNotificationAlert = (config) => async (dispatch) => {
  dispatch({
    type: NOTIFICATION_ALERT_OPEN,
    payload: {
      message: config && config.message || "",
      type: config && config.type || "error"
    },
  });
};

export const closeNotificationAlert = () => async (dispatch) => {
  dispatch({
    type: NOTIFICATION_ALERT_CLOSE,
  });
};