import React, { useState } from "react";
import {
  Container,
  Grid,
  Tab,
  Tabs,
  Box,
  Typography,
  styled,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import DepartmentCalibrate from "./KpiDepartment";
import CompanyCalibrate from "./KpiCompany";
import { useTranslation } from "react-i18next";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const GridStyled = styled(Grid)({
  backgroundColor: "#E6EFF5",
  padding: "16px",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "16px",
  height: "70px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});


const useStyles = makeStyles(() => ({
  root: {
    "& .MuiGrid-container": {
      marginBottom: 0,
    },
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
    "& .MuiOutlinedInput-input": {
      padding: "16px 14px",
    },
    "& .MuiTableCell-root": {
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
}));

const ContainerStyled = styled(Container)({
  paddingTop: "100px",
  minHeight: "100dvh",
});

function KpiCalibrate() {
  const {t,i18n} = useTranslation();
  const classes = useStyles();
  const [view, setView] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setView(newValue);
  };

  return (
    <ContainerStyled>
      <GridStyled container justifyContent="space-between" alignItems="center" mt={2}>
              <Box display="flex" alignItems="center">
                <Typography variant="h6" className="kpi" sx={{ ml: 1, fontSize: "30px" }}>
                 {t("CalibrateKPI")}
                </Typography>
              </Box>
            </GridStyled>
      <div className={classes.root}>
        <div className="card-section">
          <Tabs value={view} onChange={handleChangeTab} centered>
            <Tab label={t("Company")} {...a11yProps(0)} />
            <Tab label={t("Department")} {...a11yProps(1)} />
          </Tabs>
          <CustomTabPanel value={view} index={0}>
            <CompanyCalibrate />
          </CustomTabPanel>
          <CustomTabPanel value={view} index={1}>
            <DepartmentCalibrate />
          </CustomTabPanel>
        </div>
      </div>
    </ContainerStyled>
  );
}

export default KpiCalibrate;