import React from "react";
import { Card, CardContent, Typography, Box, Chip } from "@mui/material";
import dayjs from "dayjs";

const CardMission = ({ data }) => {
  const colorStatus = ["warning", "warning", "secondary", "success", "error"];

  return (
    <Card
      sx={{
        minWidth: "300px",
        m: 2,
        boxShadow: 3,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <CardContent
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: 2,
        }}
      >
        <Box>
          <Typography
            component="div"
            gutterBottom
            sx={{ fontWeight: 600, fontSize: 24 }}
          >
            {data.missionTitle}
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {`${dayjs(data.createDate).format("DD MMMM YYYY")} - ${dayjs(
              data.missionDate
            ).format("DD MMMM YYYY")}`}
          </Typography>
          <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
            {data.missionDetail}
          </Typography>
        </Box>

        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={2}
          >
            <Box
              component="img"
              src={data.imageURL}
              alt="Coin"
              sx={{ width: 36, height: 36, mb: 1, mr: 2 }}
            />
            <Typography sx={{ fontWeight: 500, fontSize: 20 }}>
              {data.reward}
            </Typography>
          </Box>
          <Chip
            label={data.missionStatus}
            color={colorStatus[data.idMissionStatus]}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardMission;