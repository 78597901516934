import SendIcon from "@mui/icons-material/Send";
import { Container, Divider, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import React, { Fragment, useEffect } from "react";

import { useTranslation } from "react-i18next";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import ButtonBlue from "../shared/general/ButtonBlue";
import TextFieldTheme from "../shared/general/TextFieldTheme";

import ContactUsImg from "./assets/aa.png";
import Success from "../assets/approved.png";
import Error from "../assets/rejected.png";

import { postContactUs } from "../../../actions/contactUs";

import Header from "../../layouts/header";
import { use } from "i18next";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .head": {
    marginBottom: 48,
  },
  "& .phone": {
    marginBottom: 24,
    "& i": {
      marginRight: 8,
    },
    "& .MuiTypography-root": {
      fontSize: 26,
    },
  },
  "& .email": {
    marginBottom: 24,
    "& i": {
      marginRight: 8,
    },
    "& .MuiTypography-root": {
      fontSize: 26,
    },
  },
  "& .alert": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "& img": {
      marginBottom: 16,
    },
  },
});

const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = "Required";
  }

  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 8) {
    errors.password = "Contain at least 8 characters";
  }

  return errors;
};

const ContactUsPage = (props) => {
  const {t,i18n} = useTranslation();
  const dispatch = useDispatch();
  const matchesBig = useMediaQuery("(min-width:1200px)");
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
    },
    //validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values);
      resetForm();
    },
    validateOnChange: false,
  });

  const [msgStatus, setMsgStatus] = React.useState("fetch");
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async (values) => {
    console.log(values);
    values.createdBy = "UniHR";
    handleOpen();
    setMsgStatus("fetch");
    const result = await dispatch(postContactUs(values));
    if (result) {
      console.log("result: ", result);
      if (result.status === 200) {
        setMsgStatus("success");
      } else {
        setMsgStatus("error");
      }
    } else {
      setMsgStatus("error");
    }
  };

  return (
    <Fragment>
      <Header
        matchesBig={matchesBig}
        open={false}
        isLoggedIn={false}
      />
      <StyledRoot className="page">
        <Container maxWidth="lg" style={{ paddingTop: 8, paddingBottom: 20 }}>
          <div>
            <Typography
              className="head"
              variant="h2"
              align="center"
              gutterBottom
            >
              {t("ContactUs")}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <img
                  src={ContactUsImg}
                  alt="ant"
                  style={{ width: "80%", display: "flex", margin: "auto" }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <div className="phone">
                    <Typography variant="h4">
                      {" "}
                      <i class="fas fa-phone"></i>082-889-4498
                    </Typography>
                  </div>
                  <div className="email">
                    <Typography variant="h4">
                      <i class="fas fa-envelope"></i>puttinun@anthr.co
                    </Typography>
                  </div>
                </div>
                <Divider style={{ paddingTop: 8, paddingBottom: 16 }}>
                  <Typography color="text.secondary">{t("or")}</Typography>
                </Divider>
                <Typography variant="h6" gutterBottom>
                  {t("LeaveYourQuestion")}
                </Typography>
                <form
                  onSubmit={formik.handleSubmit}
                  autoComplete="off"
                  noValidate
                >
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                      <div>
                        <TextFieldTheme
                          label={t("FullName")}
                          name="name"
                          variant="outlined"
                          onChange={formik.handleChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div>
                        <TextFieldTheme
                          name="email"
                          label={t("Email")}
                          variant="outlined"
                          onChange={formik.handleChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div>
                        <TextFieldTheme
                          label={t("PhoneNumber")}
                          variant="outlined"
                          name="phone"
                          onChange={formik.handleChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div>
                        <TextFieldTheme
                          name="company"
                          label= {t("CompanyName")}
                          variant="outlined"
                          onChange={formik.handleChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div>
                        <TextFieldTheme
                          rows={4}
                          multiline
                          name="message"
                          label={t("AdditionalMessage")}
                          variant="outlined"
                          onChange={formik.handleChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <ButtonBlue
                        type="submit"
                        variant="contained"
                        endIcon={<SendIcon />}
                      >
                        {t("SendMessage")}
                      </ButtonBlue>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </div>
        </Container>
        <Backdrop
          sx={{ backgroundColor: "#000000d9", color: "#fff", zIndex: 1000 }}
          open={open}
          onClick={handleClose}
        >
          {msgStatus === "fetch" ? (
            <CircularProgress color="inherit" />
          ) : (
            <Fragment>
              {msgStatus === "error" ? (
                <div className="alert">
                  <img src={Error} width="60" />
                  <Typography style={{ color: "#FFFFFF" }} variant="h6" gutterBottom>
                    {t("AnErrorOccurred")}
                  </Typography>
                  <Typography style={{ color: "#FFFFFF" }} variant="h4" gutterBottom>
                    {t("TryAgain")}
                  </Typography>
                  <Typography style={{ color: "#FFFFFF" }}>{t("ThankYou")}</Typography>
                </div>
              ) : (
                <div className="alert">
                  <img src={Success} width="60" />
                  <Typography style={{ color: "#FFFFFF" }} variant="h6" gutterBottom>
                    {t("DataSaveSuccessful")}
                  </Typography>
                  <Typography
                    align="center"
                    style={{ color: "#FFFFFF" }}
                    variant="h4"
                    gutterBottom
                  >
                    {t("TeamWillContact")}
                  </Typography>
                  <Typography style={{ color: "#FFFFFF" }}>{t("ThankYou")}</Typography>
                </div>
              )}
            </Fragment>
          )}
        </Backdrop>
      </StyledRoot>
    </Fragment>
  );
};

export default ContactUsPage;
