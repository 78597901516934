import React from "react";
import {
  Grid,
  TextField,
  OutlinedInput,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";




const useStyles = makeStyles(() => ({
  formControl: {
    marginRight: 16,
  },
  pad: {
    padding: 3,
  },
}));

const SearchData = ({
  searchName,
  classLevel,
  selectName,
  onChangeName,
  onChangeType,
}) => {
  const classes = useStyles();

  return (
    
      
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={8} className={classes.pad}>
            {/* <TextField label="Test" variant="outlined" fullWidth  /> */}
            <TextField
              id="outlined-demandName"
              name={searchName}
              size="small"
              // sx={{ m: 1, width: '25ch' }}
              variant="outlined"
              //label={searchName}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
              placeholder={searchName}
              onChange={(e) => onChangeName(e)}
              sx={{ backgroundColor: "#fff" }}
            />
          </Grid>
          <Grid item xs={12} lg={4} md={4} className={classes.pad}>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.formControl}
              fullWidth
              sx={{ backgroundColor: "#fff" }}
            >
              <InputLabel id="type-select-outlined-label">
                {selectName}
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                name="type"
                id="demo-simple-select-outlined"
                onChange={(e) => onChangeType(e)}
                label={selectName}
                defaultValue={""}
              >
                {classLevel.map((val, id) =>
                  val == "None" ? (
                    <MenuItem value={""} key={id}>
                      {val}
                    </MenuItem>
                  ) : (
                    <MenuItem value={val} key={id}>
                      {val}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
     
    
  );
};

export default SearchData;
