import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import "dayjs/locale/th";
import utils from "../../../../../../utils";

import {
  Typography,
  TablePagination,
  Grid,
  Box,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";

import FilePresentIcon from "@mui/icons-material/FilePresent";

import Backdrop from "../../../../shared/general/Backdrop";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import AlertResponse from "../../../../shared/general/AlertResponse";

import RowItemExpense from "../../../../user/expense/view/welfare/row-welfare";

import Comments from "../../../../user/expense/comments";
import Actions from "../../../../user/expense/actions";
import ActionButtons from "../action-buttons";
import {
  getWelfareBillingDetail,
  updateStatusWelfareBilling,
} from "../../../../../../actions/welfare-expense";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useTranslation } from "react-i18next";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledTableCell = styled(TableCell)({
  border: "none",
  padding: 0,
  "& .MuiTypography-root": {
    marginBottom: 16,
  },
  "&.summary": {
    textAlign: "center",
  },
  "&.date-document": {
    width: 120,
  },
  "&.service": {
    width: 150,
  },
  "&.description": {
    width: 200,
  },
  "&.amount": {
    width: 104,
  },
  "&.price": {
    width: 104,
  },
  "&.discount": {
    width: 104,
  },
  "&.tax": {
    width: 40,
  },
  "&.price-before-tax": {
    width: 104,
  },
  "&.tax-withholding": {
    width: 104,
  },
  "&.delete": {
    width: 32,
  },
});

const StyledGrid = styled(Grid)({
  borderRight: "2px solid rgba(145, 158, 171, 0.32)",
});

const ViewWelfareBillingPage = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: welfareBillingDetail } = useSelector(
    (state) => state.welfareBillingDetail
  );
  const { idWelfareBilling, mode, handleCloseAlert, handleOpenAlert } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [comment, setComment] = useState(null);
  const open = Boolean(anchorEl);

  const rowHeader = [
    {
      caption: "Unit.Items",
      className: "description",
    },
    {
      caption: "Amount",
      className: "price",
    },
    {
      caption: "CanWithdraw",
      className: "withdrawablePrice",
    },
  ];

  useEffect(() => {
    if (userProfile) {
      dispatch(getWelfareBillingDetail(idWelfareBilling));
    }
  }, [userProfile]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleSubmit = async (actionType, status, comment, paymentType) => {
    const data = {
      date: dayjs(dayjs().toDate()).format("YYYY/MM/DD HH:mm"),
      comment: comment,
      actionType: actionType,
      status: status,
      paymentType: paymentType,
    };
    let result = await dispatch(
      updateStatusWelfareBilling(
        userProfile.idEmployees,
        idWelfareBilling,
        data
      )
    );

    if (result.status === 200) {
      handleChangeAlertType("success");
      handleOpenAlert();
      history.push("/expense");
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
    }
  };

  return (
    <div>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      {welfareBillingDetail ? (
        <Box>
          <div>
            <div className="wrap-head">
              <StyledHeadLabel variant="h6">
                {t("GeneralInformation")}
              </StyledHeadLabel>
            </div>
            <div>
              <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
                {t("DocumentNumber")}
              </StyledHeadLabel>
              <Typography
                component="p"
                variant="subtitle1"
                sx={{
                  paddingLeft: 4,
                  marginBottom: 2,
                  marginTop: 1,
                }}
              >
                &nbsp;&nbsp;{welfareBillingDetail.documentId}
              </Typography>
            </div>
            <div>
              <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
                {t("TitleName")}
              </StyledHeadLabel>
              <Typography
                component="p"
                variant="subtitle1"
                sx={{
                  paddingLeft: 4,
                  marginBottom: 4,
                  marginTop: 1,
                }}
              >
                &nbsp;&nbsp;{welfareBillingDetail.billingName}
              </Typography>
            </div>
            <div>
              <Grid container spacing={2}>
                <StyledGrid item xs={12} sm={8}>
                  <StyledHeadLabel
                    variant="body2"
                    color="text.third"
                    gutterBottom
                  >
                    {t("RightsUser")}
                  </StyledHeadLabel>
                  <Typography
                    component="p"
                    variant="subtitle1"
                    sx={{
                      paddingLeft: 4,
                      marginBottom: 4,
                      marginTop: 1,
                    }}
                  >
                    &nbsp;&nbsp;
                    {welfareBillingDetail.userName}
                  </Typography>
                </StyledGrid>
                <Grid item xs={4}>
                  <>
                    <StyledHeadLabel
                      variant="body2"
                      color="text.third"
                      gutterBottom
                    >
                      {t("RightsType")}
                    </StyledHeadLabel>
                    <Typography
                      component="p"
                      variant="subtitle1"
                      sx={{
                        paddingLeft: 4,
                        marginBottom: 2,
                        marginTop: 1,
                      }}
                    >
                      &nbsp;&nbsp;
                      {welfareBillingDetail.type}
                    </Typography>
                  </>
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid container spacing={2}>
                <StyledGrid item xs={12} sm={6}>
                  <>
                    <StyledHeadLabel
                      variant="body2"
                      color="text.third"
                      gutterBottom
                    >
                      {t("DocumentDate")}
                    </StyledHeadLabel>
                    <Typography
                      component="p"
                      variant="subtitle1"
                      sx={{
                        paddingLeft: 4,
                        marginBottom: 2,
                        marginTop: 1,
                      }}
                    >
                      &nbsp;&nbsp;
                      {dayjs(welfareBillingDetail.documentDate).format(
                        "DD/MM/BBBB"
                      )}
                    </Typography>
                  </>
                </StyledGrid>
                <StyledGrid item xs={12} sm={6}>
                  <>
                    <StyledHeadLabel
                      variant="body2"
                      color="text.third"
                      gutterBottom
                    >
                      {t("Location")}
                    </StyledHeadLabel>
                    <Typography
                      component="p"
                      variant="subtitle1"
                      sx={{
                        paddingLeft: 4,
                        marginBottom: 2,
                        marginTop: 1,
                      }}
                    >
                      &nbsp;&nbsp;
                      {welfareBillingDetail.location}
                    </Typography>
                  </>
                </StyledGrid>
              </Grid>
            </div>
          </div>
          <StyledDivider />
          <div>
            <div className="wrap-head">
              <StyledHeadLabel variant="h6">{t("List")}</StyledHeadLabel>
            </div>

            <TableContainer>
              <Table
                style={{
                  minWidth: 650,
                  borderSpacing: "0 4px",
                  borderCollapse: "separate",
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    {rowHeader.map((item) => (
                      <StyledTableCell className={item.className}>
                        <StyledHeadLabel
                          variant="body2"
                          color="text.third"
                          gutterBottom
                          sx={{ textAlign: "center" }}
                        >
                          {t(item.caption)}
                        </StyledHeadLabel>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {welfareBillingDetail.listBilling &&
                    welfareBillingDetail.listBilling.length &&
                    welfareBillingDetail.listBilling.length > 0 &&
                    welfareBillingDetail.listBilling
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <RowItemExpense
                          key={index}
                          count={
                            welfareBillingDetail.listBilling &&
                            welfareBillingDetail.listBilling.length
                              ? welfareBillingDetail.listBilling.length
                              : 0
                          }
                          data={welfareBillingDetail.listBilling[index]}
                          index={index}
                          open={open}
                        />
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[
                10,
                20,
                30,
                40,
                50,
                { label: t("All"), value: -1 },
              ]}
              component="div"
              count={
                welfareBillingDetail.listBilling &&
                welfareBillingDetail.listBilling.length
                  ? welfareBillingDetail.listBilling.length
                  : 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${
                            count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
            />
          </div>
          <StyledDivider />
          <div>
            <div className="wrap-head">
              <StyledHeadLabel variant="h6">{t("SumaryItem")}</StyledHeadLabel>
            </div>
            <div>
              <div className="wrap-row-sum">
                <Typography color="text.third">
                  {t("TotalValue")}&nbsp;&nbsp;
                </Typography>
                <Typography color="text.third" variant="h6">
                  {utils.numberWithCommas(parseFloat(welfareBillingDetail.net))}
                </Typography>
                <Typography color="text.third">
                  &nbsp;&nbsp;{t("Baht")}
                </Typography>
              </div>
              <div className="wrap-row-sum">
                <Typography color="text.third">
                  {t("TotalWithdrawal")}&nbsp;&nbsp;
                </Typography>
                <Typography color="text.third" variant="h6">
                  {utils.numberWithCommas(
                    parseFloat(welfareBillingDetail.withdrawal)
                  )}
                </Typography>
                <Typography color="text.third">
                  &nbsp;&nbsp;{t("Baht")}
                </Typography>
              </div>
              <div className="wrap-row-sum">
                <Typography color="text.third">
                  {t("ExcessAssistance")}&nbsp;&nbsp;
                </Typography>
                <Typography color="text.third" variant="h6">
                  {utils.numberWithCommas(
                    parseFloat(welfareBillingDetail.helpingSurplus)
                  )}
                </Typography>
                <Typography color="text.third">
                  &nbsp;&nbsp;{t("Baht")}
                </Typography>
              </div>
              <div className="wrap-row-sum">
                <Typography variant="h5">
                  {t("ExcessWithdrawn")}&nbsp;&nbsp;
                </Typography>
                <Typography variant="h4">
                  {utils.numberWithCommas(
                    parseFloat(welfareBillingDetail.difference)
                  )}
                </Typography>
                <Typography variant="h5">&nbsp;&nbsp;{t("Baht")}</Typography>
              </div>
            </div>
          </div>
          <StyledDivider />
          {welfareBillingDetail.remark && (
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">
                  {t("Note")}({t("More")})
                </StyledHeadLabel>
              </div>
              <Typography
                component="p"
                variant="subtitle1"
                sx={{
                  paddingLeft: 4,
                  marginBottom: 4,
                }}
              >
                &nbsp;&nbsp;{welfareBillingDetail.remark}
              </Typography>
              <StyledDivider />
            </div>
          )}
          <div>
            <div className="wrap-head">
              <StyledHeadLabel variant="h6">{t("AttachFile")}</StyledHeadLabel>
            </div>
            {welfareBillingDetail.fileURL ? (
              <Box
                style={{
                  position: "relative",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    padding: 15,
                    backgroundColor: "#f9f9fb",
                    display: "flex",
                    borderRadius: 15,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    welfareBillingDetail.fileURL &&
                      window.open(
                        `${welfareBillingDetail.fileURL.URL}`,
                        "_blank"
                      );
                  }}
                >
                  <FilePresentIcon
                    height="24"
                    style={{
                      marginRight: 10,
                    }}
                  ></FilePresentIcon>
                  <Typography>{welfareBillingDetail.fileURL.path}</Typography>
                </div>
              </Box>
            ) : (
              <Box>
                <div
                  style={{
                    padding: 24,
                    backgroundColor: "#f9f9fb",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: 15,
                  }}
                >
                  <Typography color="error">{t("NoAttachedFile")}</Typography>
                </div>
              </Box>
            )}
          </div>

          <div>
            {welfareBillingDetail &&
            welfareBillingDetail.comments &&
            welfareBillingDetail.comments.length &&
            welfareBillingDetail.comments.length > 0 ? (
              <Comments comments={welfareBillingDetail.comments} />
            ) : (
              <div></div>
            )}
          </div>
          {mode === "edit" && (
            <div>
              <StyledDivider />
              <div>
                <div className="wrap-head">
                  <StyledHeadLabel variant="h6">{t("Comment")}</StyledHeadLabel>
                </div>

                <TextFieldTheme
                  id="comment"
                  value={comment}
                  inputProps={{ maxLength: 500 }}
                  rows={4}
                  multiline
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                ></TextFieldTheme>

                <Typography
                  component="p"
                  textAlign="right"
                  variant="caption"
                  color="text.secondary"
                >{`${comment ? comment.length : 0}/500`}</Typography>
              </div>
              <StyledDivider />
              <div>
                {welfareBillingDetail &&
                welfareBillingDetail.actions &&
                welfareBillingDetail.actions.length &&
                welfareBillingDetail.actions.length > 0 ? (
                  <Actions actions={welfareBillingDetail.actions} />
                ) : (
                  <div></div>
                )}
              </div>
              <ActionButtons
                handleSubmit={handleSubmit}
                comment={comment}
                isFlexCoin={false}
              />
            </div>
          )}
        </Box>
      ) : (
        <Backdrop open={true} />
      )}
    </div>
  );
};

export default ViewWelfareBillingPage;
