import React from "react";
import { useSelector } from "react-redux";

import AdminOnBoarding from "../admin/onBoarding";
import ManagerOverview from "../manager/overview";
import UserOnBoarding from "../user/boarding/onBoarding";

const OnBoarding = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ? (
          <AdminOnBoarding />
        ) : userProfile.roles.includes("ROLE_MANAGER") ? (
          <ManagerOverview />
        ) : (
          <UserOnBoarding />
        )
      ) : null}
    </>
  );
};

export default OnBoarding;
