import React, { useState } from "react";
import { updateKpiGrade } from "../../../../../actions/kpi";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Swal from 'sweetalert2';
import { t } from "i18next";

const MenuGrade = ({ idEmployees, fetchData }) => {
    const [anchorEl, setAnchorEl] = useState(null); 
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeGrade = async (kpiGrade) => {
        const response = await updateKpiGrade({ kpiGrade, idEmployees });

        if (response && response.status === 200) {
            handleClose();
            fetchData();
            Swal.fire({
                title: t("Success"),
                text: `${t("GradeUpdatedTo")} ${kpiGrade}`,
                icon: 'success',
                timer: 2000,
                showConfirmButton: false
            });
        } else {
            Swal.fire({
                title: t("AnErrorOccurred"),
                text: t("FailedToUpdateGrade"),
                icon: 'error',
                timer: 2000,
                showConfirmButton: false
            });
        }
    };

    const confirmChangeGrade = (kpiGrade) => {
        handleClose(); 
        Swal.fire({
            title: t("ConfirmGradeChange"),
            text: `${t("DoYouWantToChangeGradeTo")} ${kpiGrade} ${t("confirmAsking")}`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Confirm"),
            cancelButtonText: t("Cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                handleChangeGrade(kpiGrade);
            }
        });
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(event) => handleClick(event)} 
                size="small"
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
            >
                <MenuItem onClick={() => confirmChangeGrade("A")}>{"A"}</MenuItem>
                <MenuItem onClick={() => confirmChangeGrade("B")}>{"B"}</MenuItem>
                <MenuItem onClick={() => confirmChangeGrade("C")}>{"C"}</MenuItem>
                <MenuItem onClick={() => confirmChangeGrade("D")}>{"D"}</MenuItem>
            </Menu>
        </div>
    );
}

export default MenuGrade;
