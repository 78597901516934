import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  TableRow,
  TableCell,
  TextField,
  Typography,
  IconButton,
  Popper,
  Box,
  Button,
} from "@mui/material";

import DatePickerCustom from "../../../../shared/date/datePicker";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import DragHandleRoundedIcon from "@mui/icons-material/DragHandleRounded";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

const StyledTableRow = styled(TableRow)({
  marginBottom: 2,
});

const StyledTableCell = styled(TableCell)({
  padding: 0,
  border: "none",
  "& .wrap": {
    height: 72,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    borderTop: "1px solid #e1e1e1",
    borderLeft: "1px solid #e1e1e1",
    borderBottom: "1px solid #e1e1e1",
    "&.first": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    "&.last": {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      borderRight: "1px solid #e1e1e1",
    },
  },
  "&.date-start-end": {
    width: 150,
    "& .MuiInputBase-root": {
      paddingRight: 8,
    },
  },
  "&.description": {
    width: 200,
  },
  "&.amount": {
    width: 50,
  },
  "&.delete": {
    width: 32,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  margin: "0px !important",
  "& .MuiInputBase-root": {
    borderRadius: 0,
    height: 56,
    "& fieldset": {
      border: "none",
    },
    "& .MuiInputBase-input": {
      padding: "5px !important",
    },
  },
});

const StyledTextArea = styled(TextField)({
  width: "100%",
  margin: "0px !important",
  "& .MuiInputBase-root": {
    borderRadius: 0,
    padding: "5px !important",
    height: 72,
    "& textarea": {
      height: "72px !important",
      overflow: "auto !important",
    },
    "& fieldset": {
      border: "none",
    },
  },
});

const RowItemExpense = (props) => {
  const { index, handleDeleteRow, count, open, handleClickMenuTax } = props;

  return (
    <StyledTableRow>
      <StyledTableCell className="">
        {count > 1 && (
          <IconButton aria-label="drag" size="small">
            <DragIndicatorRoundedIcon fontSize="small" />
          </IconButton>
        )}
      </StyledTableCell>
      <StyledTableCell className="date-start-end">
        <div className="wrap first">
          <DatePickerCustom
            inputFormat="DD/MM/YYYY"
            name="start"
            onChange={(newValue) => {}}
            renderInput={(params) => (
              <StyledTextField fullWidth {...params} />
            )}
          />
        </div>
      </StyledTableCell>
      <StyledTableCell className="cell-table description">
        <div className="wrap ">
          <StyledTextArea />
        </div>
      </StyledTableCell>
      <StyledTableCell className="cell-table amount">
        <div className="wrap last">
          <StyledTextField />
        </div>
      </StyledTableCell>
      <StyledTableCell className="delete" align="center">
        {index > 0 && (
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => handleDeleteRow(index)}
          >
            <DeleteOutlineRoundedIcon fontSize="small" />
          </IconButton>
        )}
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default RowItemExpense;
