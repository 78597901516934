import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useDispatch, useSelector } from "react-redux";
import FeedIcon from '@mui/icons-material/Feed';
import { Controller, useForm } from "react-hook-form"
import { useHistory } from "react-router-dom";
import {
    Typography,
    Button,
    Stack,
    Container,
    Box,
    IconButton,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    Paper,
    TableBody,
    TableCell,
    Chip,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Menu,
    Avatar,
    InputAdornment,
    FilledInput,
    Grid,
    Divider,
    Popper

} from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
// Accordion,
// AccordionSummary,
// AccordionDetails,
import ButtonBlue from "../../shared/general/ButtonBlue";
import {
    getAllProjectEmployees,
    getUsersInProject,
    getUsersInProjectShowHour,
    getAllEmployeesInWorkforce,
    getEmployeesWeek,
    getAllProjectDetailAdmin,
    getSumUserTimeInProject,
    getBudgetForWorkforce
} from "../../../../actions/workforceEmployees";
import { getAllEmployees } from "../../../../actions/employee";
import LinearProgress, {
    linearProgressClasses,
} from "@mui/material/LinearProgress";
import CardStyle from "../../shared/general/Card";
import GaugeChart from "react-gauge-chart";
import { Pie, Line } from "react-chartjs-2";
import SearchIcon from '@mui/icons-material/Search';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserDepartment, getUserFullName, getUserPosition, getUserSection } from "../../../../utils/userData";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../actions/company";

const StyledRoot = styled("div")({
    backgroundColor: "#FFFFFF !important",
    "& .header": {
        paddingTop: 20,
        paddingBottom: 30,
    },
    "& .wrap-add": {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 16,
    },
    paddingBottom: "16px"
});

const StyledFilledInput = styled(FilledInput)({
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
        paddingTop: 18,
        paddingBottom: 18,
    },
    "&.Mui-focused": {
        backgroundColor: "transparent",
    },
    "& .MuiInputAdornment-root": {
        width: 32,
        marginTop: "0!important",
        fontSize: 24,
        color: "#919EAB",
        "& i": {
            marginRight: 8,
        },
    },
    "& .MuiAutocomplete-endAdornment": {
        "& .MuiButtonBase-root": {
            fontSize: 14,
            width: 22,
            height: 22,
        },
    },
    "&:hover": {
        backgroundColor: "#919eab29",
        "&:before": {
            border: "none !important",
        },
    },
    "&::after": {
        border: "none",
    },
    "&::before": {
        border: "none",
    },
});

const StyledBoxSearch = styled(Box)({
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: "none",
    "&:not(:last-child)": {
        borderBottom: 0,
    },
    "&:before": {
        display: "none",
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
        {...props}
    />
))(({ theme }) => ({
    width: "100%",
    borderRadius: 8,
    marginBottom: 8,
    backgroundColor:
        theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, .05)"
            : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
    "& .wrap": {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    paddingTop: 0,
    borderTop: "none",
    display: "flex",
    flexWrap: "wrap",
    "& .MuiBox-root": {
        width: "100%",
        margin: 8,
        marginBottom: 16,
    },
    "& .label": {
        fontWeight: 500,
    },
    "& .last-row": {
        display: "flex",
        justifyContent: "space-between",
    },
    "& .wrap-add": {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 8,
    },
}));

const StyledEmpty = styled("div")({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 150,
});

const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    border: 0,
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            color: "#919EAB",
            "& i": {
                marginRight: 8,
            },
        },
        "& .MuiAutocomplete-endAdornment": {
            "& .MuiButtonBase-root": {
                fontSize: 14,
                width: 22,
                height: 22,
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&::after": {
            border: "none",
        },
        "&::before": {
            border: "none",
        },
    },
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        [`& .${autocompleteClasses.option}`]: {
            "&:hover": {
                backgroundColor: "#f6f7f8",
                margin: "0 8px",
                borderRadius: 8,
                paddingLeft: 8,
            },
        },
    },
});

const StyledDivider = styled(Divider)({
    marginTop: 16,
    marginBottom: 16,
    borderWidth: "0px 0px thin",
    borderColor: "#919eab52",
    borderStyle: "dashed",
    width: "100%",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    margin: "auto",
    "& .MuiToggleButtonGroup-grouped": {
        margin: theme.spacing(0.5),
        border: 0,
        "&.Mui-disabled": {
            border: 0,
        },
        "&:not(:first-of-type)": {
            borderRadius: theme.shape.borderRadius,
        },
        "&:first-of-type": {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

const WrapHeader = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ["@media only screen and (max-width:600px)"]: {
        flexDirection: "column",
    },
    marginTop: 8,
    marginBottom: 16,
});

const projectDetails = () => {
    let history = useHistory();
    const { t, i18n } = useTranslation();
    const useHookForm = useForm({
        defaultValues: {
            name: "",

        },
        mode: "all",
    });
    const datetoday = new Date();
    const dispatch = useDispatch();
    const [monthUse, setMonthUse] = useState((datetoday.getMonth() + 1));
    const [yearUse, setYearUse] = useState((datetoday.getFullYear()));
    const [selectedCompany, setSelectedCompany] = useState(null);

    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

    const [filteredData, setFilteredData] = useState([]);
    const [expanded, setExpanded] = React.useState("");
    const handleChangeAc = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);

    };

    const { result: empInWorkforce } = useSelector((state) => state.empInWorkforce)

    const { result: getSumTimeUserInProject } = useSelector((state) => state.getSumTimeUserInProject)
    const { result: getAdminWorkforceProjectDetail } = useSelector((state) => state.getAdminWorkforceProjectDetail)
    const { result: getBudgetUserWorkforce } = useSelector((state) => state.getBudgetUserWorkforce)

    useEffect(() => {
        if (getAdminWorkforceProjectDetail) {
            const filtered = getAdminWorkforceProjectDetail.filter(item => item.month === monthUse && item.year === yearUse);
            setFilteredData(filtered);
        } else {
            console.log("empInWorkforce is not loaded or is null.");
        }
    }, [empInWorkforce, monthUse, yearUse]);


    const openProjectDetail = () => {
        history.push('/workforce-planning-cal/tableHour')
    };

    const handleChange = (event) => {
        setMonthUse(event.target.value);
    };

    const onSubmit = async (data) => {
        const searchData = {
            name: data.name ? data.name : null,
        }
        if (searchData.name && getAdminWorkforceProjectDetail) {
            const filtered = getAdminWorkforceProjectDetail.filter(item => item.month === monthUse && item.year === yearUse);
            const result = searchData.name ? filtered && filtered.filter(item => item.name.includes(searchData.name)) : filtered;
            setFilteredData(result)
        } else {
            const filtered = getAdminWorkforceProjectDetail.filter(item => item.month === monthUse && item.year === yearUse);
            setFilteredData(filtered);
        }
    };

    useEffect(() => {
        if (userProfile && userProfile.roles.includes("ROLE_ADMIN")) {
            dispatch(getAffiliateOrganization());
        }
    }, [userProfile]);

    useEffect(() => {
        if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0 && userProfile.roles.includes("ROLE_ADMIN")) {
            let ownCompany = affiliateOrganizationList.find(x => x.idCompany === userProfile.idCompany);
            if (!ownCompany) {
                ownCompany = affiliateOrganizationList[0];
            }

            setSelectedCompany(ownCompany);
        }
    }, [userProfile, affiliateOrganizationList]);

    useEffect(() => {
        if (selectedCompany && selectedCompany.idCompany) {
            dispatch(getAllProjectDetailAdmin({ idCompany: selectedCompany.idCompany }));
            dispatch(getSumUserTimeInProject({ idCompany: selectedCompany.idCompany }));
            dispatch(getBudgetForWorkforce({ idCompany: selectedCompany.idCompany }));
        }
    }, [selectedCompany]);

    const handleChangeYear = (event) => {
        setYearUse(event.target.value);
    };

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    };

    return (
        <StyledRoot className={`page`}>
            <Container maxWidth="lg">
                <WrapHeader>
                    <Typography variant="h4">{t("workforceProjectDetails")}</Typography>
                </WrapHeader>
                <CardStyle style={{ padding: 16 }}>

                    <Stack
                        direction={"column"}
                        alignItems={"center"}
                        marginBottom={4}
                        gap={2}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <SelectAffiliateCompany
                                    options={affiliateOrganizationList}
                                    value={selectedCompany}
                                    // disabled={isFetching}
                                    onChange={(_, value) => {
                                        onChangeCompany(value);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
                                    <Controller
                                        name="name"
                                        control={useHookForm.control}
                                        render={({ field, fieldState }) => (
                                            <FormControl fullWidth variant="filled">
                                                <StyledFilledInput
                                                    {...field}
                                                    placeholder={`${t("Search")}${t("ProjectName")}`}
                                                    name="search"
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        )}
                                    />
                                </form>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} alignItems={"center"}>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth>
                                    <InputLabel>{t("SelectByMonth")}</InputLabel>
                                    <Select
                                        value={monthUse}
                                        label={`${t("SelectByMonth")}`}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={1}>{t("Months.January")}</MenuItem>
                                        <MenuItem value={2}>{t("Months.February")}</MenuItem>
                                        <MenuItem value={3}>{t("Months.March")}</MenuItem>
                                        <MenuItem value={4}>{t("Months.April")}</MenuItem>
                                        <MenuItem value={5}>{t("Months.May")}</MenuItem>
                                        <MenuItem value={6}>{t("Months.June")}</MenuItem>
                                        <MenuItem value={7}>{t("Months.July")}</MenuItem>
                                        <MenuItem value={8}>{t("Months.August")}</MenuItem>
                                        <MenuItem value={9}>{t("Months.September")}</MenuItem>
                                        <MenuItem value={10}>{t("Months.October")}</MenuItem>
                                        <MenuItem value={11}>{t("Months.November")}</MenuItem>
                                        <MenuItem value={12}>{t("Months.December")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth>
                                    <InputLabel>{t("SelectYear")}</InputLabel>
                                    <Select
                                        value={yearUse}
                                        label={`${t("SelectYear")}`}
                                        onChange={handleChangeYear}
                                    >
                                        <MenuItem value={2023}>2023</MenuItem>
                                        <MenuItem value={2024}>2024</MenuItem>
                                        <MenuItem value={2025}>2025</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <ButtonBlue
                                    variant="contained"
                                    startIcon={<FeedIcon />}
                                    onClick={openProjectDetail}
                                    fullWidth
                                >
                                    {t("checkHour")}
                                </ButtonBlue>
                            </Grid>
                        </Grid>
                    </Stack>

                    <div>
                        {filteredData && filteredData.map((item, index) => (
                            <Accordion
                                key={index}
                                expanded={expanded === `panel${index}`}
                                onChange={handleChangeAc(`panel${index}`)}
                            >
                                <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                >
                                    <div className="wrap">
                                        <Typography>{item.name}</Typography>
                                    </div>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 600 }}>
                                            <TableHead>
                                                <TableRow>

                                                    <TableCell align="center">{t("EmployeeName")}</TableCell>
                                                    <TableCell align="center">{t("totalHours")}</TableCell>
                                                    <TableCell align="center">%</TableCell>
                                                    <TableCell align="center">{t("cost")} ({t("Baht")})</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(getSumTimeUserInProject && getSumTimeUserInProject.filter((data) => (data.name === item.name) && (data.month === monthUse) && data.inYear === yearUse).length === 0) || !getSumTimeUserInProject ? (
                                                    <TableRow>
                                                        <TableCell align="center" colSpan={4}>{t("noOneHasStartedProject")} {item.name} ในเดือนนี้</TableCell>
                                                    </TableRow>
                                                ) : (
                                                    getSumTimeUserInProject.filter((data) => (data.name === item.name) && (data.month === monthUse) && data.inYear === yearUse).map((data, index) => {
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell align="center">
                                                                    <Stack direction={"row"} gap={2}>
                                                                        <Avatar src={data.imageProfile} />
                                                                        <div style={{ textAlign: "start" }}>
                                                                            <Typography fontWeight={500}>
                                                                                <Typography>{getUserFullName(data)}</Typography>
                                                                            </Typography>
                                                                            <Typography variant="body2" color="text.secondary">{t("Position")}: {getUserPosition(data)}</Typography>
                                                                            <Typography variant="body2" color="text.secondary">{t("Section")}: {getUserSection(data)}</Typography>
                                                                            <Typography variant="body2" color="text.secondary">{t("Department")}: {getUserDepartment(data)}</Typography>
                                                                        </div>
                                                                    </Stack>
                                                                </TableCell>
                                                                <TableCell align="center">{data.totalWorkingHours}</TableCell>
                                                                <TableCell align="center">{((data.totalWorkingHours * 100) / 176).toFixed(2)} %</TableCell>
                                                                <TableCell align="center">
                                                                    {(() => {
                                                                        const filterSum = getBudgetUserWorkforce && getBudgetUserWorkforce.filter((dataBud) => dataBud.year === yearUse && dataBud.idEmployees === data.idEmployees);
                                                                        return filterSum && filterSum.length > 0 ? (parseFloat(filterSum[0].SUM / 12) * parseFloat(data.totalWorkingHours / 176)).toFixed(2) : 'ยังไม่ได้กำหนดต้นทุน'
                                                                        // * ((data.totalWorkingHours * 100) / 176)) : 0;
                                                                    })()}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                )}

                                                {getSumTimeUserInProject && getSumTimeUserInProject.filter((data) => data.name === item.name && data.month === monthUse && data.inYear === yearUse).length > 0 && (
                                                    (() => {
                                                        const filteredData = getSumTimeUserInProject.filter((data) => data.name === item.name && data.month === monthUse && data.inYear === yearUse);
                                                        const totalWorkingHours = filteredData.reduce((acc, curr) => acc + parseInt(curr.totalWorkingHours), 0);
                                                        const totalPercentage = filteredData.reduce((acc, curr) => acc + (curr.totalWorkingHours * 100 / 176), 0).toFixed(2);
                                                        const totalBudget = filteredData.reduce((acc, curr) => {
                                                            const filterSum = getBudgetUserWorkforce && getBudgetUserWorkforce.filter((dataBud) => dataBud.year === yearUse && dataBud.idEmployees === curr.idEmployees);
                                                            const budget = filterSum && filterSum.length > 0 ? parseFloat(filterSum[0].SUM / 12) * parseFloat(curr.totalWorkingHours / 176) : 0;
                                                            return acc + budget;
                                                        }, 0).toFixed(2);
                                                        return (
                                                            <TableRow>
                                                                <TableCell align="center">{t("Total")}</TableCell>
                                                                <TableCell align="center">{totalWorkingHours}</TableCell>
                                                                <TableCell align="center">{totalPercentage} %</TableCell>
                                                                <TableCell align="center">{totalBudget}</TableCell>
                                                            </TableRow>
                                                        );
                                                    })()
                                                )}
                                            </TableBody>

                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                </CardStyle>
            </Container>
        </StyledRoot>
    )
}

export default projectDetails