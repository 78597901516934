import { Snackbar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import Alert from "@mui/material/Alert";

const useStyles = makeStyles(() => ({
  root: {
    top: 72,
  },
  success: {
    backgroundColor: "rgb(56, 142, 60)",
    color: "rgb(255, 255, 255)",
    "& .MuiAlert-icon": {
      color: "rgb(255, 255, 255) !important",
    },
  },
  warning: {
    backgroundColor: "rgb(245, 124, 0)",
    color: "rgba(0, 0, 0, 0.87)",
    "& .MuiAlert-icon": {
      color: "rgba(0, 0, 0, 0.87) !important",
    },
  },
  info: {
    backgroundColor: "rgb(2, 136, 209)",
    color: "rgb(255, 255, 255)",
    "& .MuiAlert-icon": {
      color: "rgb(255, 255, 255) !important",
    },
  },
  error: {
    backgroundColor: "rgb(211, 47, 47)",
    color: "rgb(255, 255, 255)",
    "& .MuiAlert-icon": {
      color: "rgb(255, 255, 255) !important",
    },
  },
}));

function Index(props) {
  const { notify, setNotify } = props;
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotify({
      ...notify,
      isOpen: false,
    });
  };

  return (
    <Snackbar
      className={classes.root}
      open={notify.isOpen}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={handleClose}
    >
      <Alert
        className={
          notify.type === "success"
            ? classes.success
            : notify.type === "warning"
            ? classes.warning
            : notify.type === "info"
            ? classes.info
            : classes.error
        }
        onClose={handleClose}
      >
        {notify.message}
      </Alert>
    </Snackbar>
  );
}

export default Index;
