import React from "react";
import { useSelector } from "react-redux";

import EmployeeNetworkAdmin from "../admin/employeeNetwork/index";
import EmployeeNetworkUser from "../user/employeeNetwork/index";

const EmployeeNetworkPage = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <div>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ? (
          <EmployeeNetworkAdmin />
        ) : (
          <EmployeeNetworkUser />
        )
      ) : null}
    </div>
  );
};

export default EmployeeNetworkPage;
