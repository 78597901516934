import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ButtonBlue from "../../../../../shared/general/ButtonBlue";
import DialogConfirmDelete from "./dialogConfirmDelete";
import LeftPanel from "./leftPanel";
import RightPanel from "./rightPanel";
import { getManpowerById, deleteManpowerByManpowerID } from "../../../../../../../actions/manpower";
import complete from "./../../../../../assets/complete.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const StyledRoot = styled("div")({
  background: "#FFFFFF !important",
  "& .success-wraper": {
    paddingTop: 55,
    textAlign: "center",
    "& .button-wrap": {
      display: "inline-block",
      marginTop: ".6em",
    }
  }
});

const StyledSuccess = styled("div")({
  paddingTop: 80,
  textAlign: "center",
  height: "calc(100vh - 240px)",
  "& .button-wrap": {
    display: "inline-block",
    marginTop: ".6em",
  }
});

const VendorManpowerProfilePage = (props) => {
  const history = useHistory();
  const { idManpower } = props.match.params;
  const { result: ManpowerProfile } = useSelector(state => state.manpowerProfile);
  // console.log(ManpowerProfile)

  const [openConfirmDelete, setOpenConfirmDelete] = useState({ isOpen: false, idManpower: null });

  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(
    {
      status: false,
      isEducation: false,
      isAdd: false,
      isDelete: false
    }
  );

  const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);

  const handleDelete = (idManpower) => {

    dispatch(deleteManpowerByManpowerID(idManpower)).then(res => {
      if (res.status === 200) {
        setIsDeleteSuccess(true);
        setOpenConfirmDelete({ isOpen: false, idManpower: null })
      }
    })

  };

  useEffect(() => {
    if (idManpower) {
      const fetchdata = async () => {
        const res = await dispatch(getManpowerById(idManpower));
        if (res.status === 404) {
          // history.replace('/manage/hunter/peer');
          history.go(-1);
        }

      }
      fetchdata();
    }
  }, [idManpower]);

  return (
    <StyledRoot className="page">
      {!isSuccess.status && !isDeleteSuccess && ManpowerProfile &&
        <Container maxWidth="lg">
          <div style={{ display: "flex", justifyContent: "right", marginTop: "14px" }}>
            <ButtonBlue
              style={{ background: "red" }}
              variant="contained"
              onClick={() =>
                setOpenConfirmDelete({ isOpen: true, idManpower: ManpowerProfile.idManpower })}
            >
              ลบผู้ใช้นี้
            </ButtonBlue>
          </div>
          <Grid container spacing={2} marginTop={3}>
            <Grid item xs={12} sm={4}>
              <LeftPanel />
            </Grid>
            <Grid item xs={12} sm={8}>
              <RightPanel
                setIsSuccess={setIsSuccess}
              />
            </Grid>
          </Grid>
          {openConfirmDelete.isOpen &&
            <DialogConfirmDelete
              open={openConfirmDelete.isOpen}
              onClose={() => setOpenConfirmDelete({ isOpen: false, idManpower: null })}
              isDeleteManpower={true}
              onSubmit={() => handleDelete(openConfirmDelete.idManpower)}
            />
          }
        </Container>
      }
      {isSuccess.status &&
        <Container maxWidth="lg">
          <StyledSuccess>
            <img
              style={{ width: 300, marginBottom: "20px" }}
              src={`/assets/background/complete.jpg`}
            // alt="https://www.freepik.com/free-vector/completed-concept-illustration_10802151.htm"  
            />
            <Typography variant="h4" textAlign="center">
              {`${isSuccess.isAdd ? "เพิ่ม" : isSuccess.isDelete ? "ลบ" : "แก้ไข"}${isSuccess.isEducation ? "ประวัติการศึกษา" : "ประสบการณ์ทำงาน"}สำเร็จ`}
            </Typography>
            <div className="button-wrap">
              <ButtonBlue
                variant="outlined"
                onClick={() => setIsSuccess(
                  {
                    status: false,
                    isEducation: false,
                    isAdd: false,
                    isDelete: false
                  }
                )}
              >ปิด</ButtonBlue>
            </div>
          </StyledSuccess>
        </Container>
      }

      {isDeleteSuccess &&
        <Container maxWidth="lg">
          <div className="success-wraper">
            <img
              style={{ width: 300, marginBottom: "20px" }}
              src={complete}
            />
            <Typography variant="h4" textAlign="center" sx={{ color: "#000000" }}>ลบคนของฉันสำเร็จ</Typography>
            <div className="button-wrap">
              <ButtonBlue variant="contained" component={Link} to="/manage/hunter/peer">คนของฉัน</ButtonBlue>
            </div>
          </div>
        </Container>
      }

    </StyledRoot>
  )
}

export default VendorManpowerProfilePage;