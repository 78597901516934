import {
    USER_WORKFORCE_HOUR_TABLE_SUCCESS,
    USER_WORKFORCE_HOUR_TABLE_FAILED,
    USER_WORKFORCE_HOUR_TABLE_FETCHING,
  } from "../actions/types";
  
  const initialState = {
    result: null,
    isFetching: false,
    isError: false,
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case USER_WORKFORCE_HOUR_TABLE_FETCHING:
        return { ...state, result: null, isFetching: true, isError: false };
      case USER_WORKFORCE_HOUR_TABLE_FAILED:
        return { ...state, result: null, isFetching: false, isError: true };
      case USER_WORKFORCE_HOUR_TABLE_SUCCESS:
        return { ...state, result: payload, isFetching: false, isError: false };
      default:
        return state;
    }
  }
  