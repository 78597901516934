import React from "react";
import { Box, styled } from "@mui/material";

const StyledRoot = styled(Box)({
  
})

const OrganizationChart = () => {

  return (
    <StyledRoot className="page">
      
    </StyledRoot>
  )
 }

 export default OrganizationChart;