import React from "react";
import { useSelector } from "react-redux";

import ManagerApproveList from "../manager/approveList";
import SuperAdminApproveList from "../superAdmin/approveList";

const WaitingApprove = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <>
      {userProfile ? (
        userProfile.roles.includes("ROLE_MANAGER") ? (
          <ManagerApproveList />
        ) : (
          <SuperAdminApproveList />
        )
      ) : null}
    </>
  );
};

export default WaitingApprove;
