import React from "react";
import moment from "moment"; // Make sure moment is imported correctly
import Calendar from "./Calendar";
import "../../styles/customizingCalendar.css";
import { Card, Typography } from "@mui/material";

// const events = [
//   {
//     start: moment("2024-11-05T10:00:00").toDate(),
//     end: moment("2024-11-05T11:00:00").toDate(),
//     title: "MRI Registration",
//   },
//   {
//     start: moment("2024-11-05T14:00:00").toDate(),
//     end: moment("2024-11-05T15:30:00").toDate(),
//     title: "ENT Appointment",
//   }
// ];

export default function CustomizingCalendar({ t, i18n, events,birthday }) {
  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2, width: '100%', overflow: 'auto' }}>
      <Calendar
        events={events}
        formats={{
          dayHeaderFormat: (date) => moment(date).format("dddd @ DD"),
        }}
        t={t}
        i18n={i18n}
        birthday={birthday}
      />
    </Card>
  );
}
