export const answerQuestion5Scale = [
  {
    key: "veryPoor",
    label: "VeryPoor",
    value: "0",
  },
  {
    key: "poor",
    label: "Poor",
    value: "25",
  },
  {
    key: "neutral",
    label: "Neutral",
    value: "50",
  },
  {
    key: "good",
    label: "Good",
    value: "75",
  },
  {
    key: "excellence",
    label: "Excellence",
    value: "100",
  },
];

export const answerQuestion6Scale = [
  {
    key: "veryPoor",
    label: "VeryPoor",
    value: "0",
  },
  {
    key: "poor",
    label: "Poor",
    value: "20",
  },
  {
    key: "fair",
    label: "Fair",
    value: "40",
  },
  {
    key: "neutral",
    label: "Neutral",
    value: "60",
  },
  {
    key: "good",
    label: "Good",
    value: "80",
  },
  {
    key: "excellence",
    label: "Excellence",
    value: "100",
  },
];
