import React, { Fragment, useEffect, useState } from "react";
import { Box, Typography, styled, Accordion, AccordionSummary, Grid } from "@mui/material";
import { Add as AddIcon, ArrowForwardIosSharp, Edit as EditIcon } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  allLeaveType,
  deleteLeaveGroup,
  getAllLeaveV2,
} from "../../../../../actions/leave";
import AccordionRow from "./accordionRow";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AlertResponse from "../../../shared/general/AlertResponse";
import DrawerLeave from "./components/drawerLeave";
import DialogConfirmDelete from "../../../shared/general/DialogConfirmDelete";
import Notification from "../../../shared/general/Notification";
import LeaveForm from "./leaveForm";
import { getEmploymentTypeByidCompany } from "../../../../../actions/employment-type";
import { getLevelByidCompany } from "../../../../../actions/level";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  marginTop: "30px",
  "& .MuiTypography-h6": {
    fontSize: "1.5rem",
  },
});

const LeaveSettingPage = (props) => {
  const { selectedCompany, isReadOnly } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: leaveTypeList } = useSelector((state) => state.leaveType);

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: null,
  });

  const [deleteConfig, setDeleteConfig] = useState({
    isOpen: false,
    data: {},
  });

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    type: "success",
    message: "",
  });

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getAllLeaveV2({ idCompany: selectedCompany.idCompany }));
      dispatch(getEmploymentTypeByidCompany({ idCompany: selectedCompany.idCompany }));
      dispatch(getLevelByidCompany({ idCompany: selectedCompany.idCompany }))
      handleCloseDrawer();
    }
  }, [selectedCompany]);

  const getLeaveType = () => {
    if (leaveTypeList) {
      return leaveTypeList;
    } else {
      return [];
    }
  };

  const handleOpenDrawer = () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: true,
      isEdit: false,
      data: {},
    }));
  };

  const handleCloseDrawer = () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleCloseDeleteModal = () => {
    setDeleteConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleDeleteLeaveType = async () => {
    const response = await dispatch(
      deleteLeaveGroup(deleteConfig.data.idLeaveGroup)
    );

    if (response) {
      if (response.status === 200) {
        setAlertConfig((prev) => ({
          ...prev,
          isOpen: true,
          type: "success",
          message: `${t("DeletedSuccessfully")}`,
        }));
        dispatch(getAllLeaveV2({ idCompany: selectedCompany.idCompany }));
        handleCloseDeleteModal();
      } else {
        setAlertConfig((prev) => ({
          ...prev,
          isOpen: true,
          type: "error",
          message: `${t("CantDeletedPleaseContactAdmin")}`,
        }));
      }
    } else {
      setAlertConfig((prev) => ({
        ...prev,
        isOpen: true,
        type: "error",
        message: `${t("AnErrorOccurred")} ${t("PleaseContactAdmin")}`,
      }));
    }

    setDeleteConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  return (
    <StyledRoot>
      {!drawerConfig.isOpen && (
        <Fragment>
          <Box
            marginBottom="16px"
            display="flex"
            justifyContent="space-between"
          >
            <Typography fontSize="24px" fontWeight="500">
              {t("LeaveDay")}
            </Typography>
            <ButtonBlue
              variant={"contained"}
              startIcon={<AddIcon />}
              // onClick={() => {
              //   setOpenDialog({
              //     ...openDialog,
              //     isOpen: true,
              //   });
              // }}
              onClick={handleOpenDrawer}
            >
              {t("AddLeaveType")}
            </ButtonBlue>
          </Box>

          {getLeaveType().map((item, index) => (
            <AccordionRow
              data={item}
              key={`accordion_${index}`}
              setDrawerConfig={setDrawerConfig}
              setDeleteConfig={setDeleteConfig}
            />
          ))}
        </Fragment>
      )}

      {drawerConfig.isOpen && (
        <LeaveForm
          drawerConfig={drawerConfig}
          onClose={handleCloseDrawer}
          setAlertType={setAlertType}
          setOpenAlert={setOpenAlert}
          selectedCompany={selectedCompany}
          isReadOnly={isReadOnly}
        />
      )}

      <Notification notify={alertConfig} setNotify={setAlertConfig} />

      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      {/* <DrawerLeave
        drawerConfig={drawerConfig}
        onClose={handleCloseDrawer}
        setAlertType={setAlertType}
        setOpenAlert={setOpenAlert}
      /> */}
      <DialogConfirmDelete
        open={deleteConfig.isOpen}
        handleClose={handleCloseDeleteModal}
        label={`${t("LeaveType")}`}
        text={deleteConfig.data.leaveGroupName}
        handleDelete={handleDeleteLeaveType}
        isReadOnly={isReadOnly}
      />
    </StyledRoot>
  );
};

export default LeaveSettingPage;
