import React from "react";
import { useSelector } from "react-redux";
import { Box, styled, Container } from "@mui/material";

import AdminChatBot from "../admin/chatbot";
import ManagerChatBot from "../manager/chatbot";
import UserChatBot from "../user/chatbot";

const StyledRoot = styled(Box)(({theme}) =>({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  padding: "16px",
  paddingTop: "90px",
  boxSizing: "border-box",
  "& .text-container": {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .CardContent": {
    width: "100%",
    maxWidth: "80%",
    // height: "80vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "16px",
  },
  "& .content": {
    // flex: 1,
    overflowY: "auto",
    marginBottom: "16px",

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  "& .MuiTextField-root": {
    width: "100%",
  },
  "& .user-message": {
    display: "flex",
    justifyContent: "flex-end",
    // width: "100%",
  },
  "& .user-bubble": {
    color: "white",
    background: "#46cbe2",
    borderRadius: "15px 15px 0 15px",
    padding: "10px",
    wordWrap: "break-word",
    wordBreak: "break-word",
    marginBottom: "10px",
    maxWidth: "50%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%"
    },
    whiteSpace: "pre-wrap",
  },
  "& .bot-message": {
    display: "flex",
    alignItems: "flex-start",
    
  },
  "& .bot-bubble": {
    background: "#F1F0F0",
    borderRadius: "0 15px 15px 15px ",
    padding: "10px",
    marginBottom: "10px",
    wordWrap: "break-word",
    wordBreak: "break-word",
    maxWidth: "50%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%"
    },
    whiteSpace: "pre-wrap",
  },
  "& .bot-logo": {
    marginRight: "10px",
    borderRadius: "50%",
    border: "1px solid #ccc",
    width: "40px",
    height: "40px",
  },
  "& .thinking-dots": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    "& span": {
      width: "10px",
      height: "10px",
      backgroundColor: "#a0a0a0",
      borderRadius: "50%",
      display: "inline-block",
      animation: "pulse 1.5s infinite",
      "&:nth-of-type(2)": {
        animationDelay: "0.3s",
      },
      "&:nth-of-type(3)": {
        animationDelay: "0.6s",
      },
    },
  },
  "@keyframes pulse": {
    "0%, 100%": {
      transform: "scale(0.5)",
      opacity: 0.5,
    },
    "50%": {
      transform: "scale(1)",
      opacity: 1,
    },
  },
  "& .dynamic-inputs": {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginTop: "16px",
  },
}));

const ChatBotPage = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <StyledRoot >
      <Container maxWidth="lg">
        <Box className="text-container">
            {userProfile ? (
              userProfile.roles.includes("ROLE_ADMIN") ? (
                <AdminChatBot />
              ) : userProfile.roles.includes("ROLE_MANAGER") ? (
                <ManagerChatBot />
              ) : (
                <UserChatBot />
              )
            ) : null}
        </Box>
      </Container>
    </StyledRoot>
  );
};

export default ChatBotPage;
