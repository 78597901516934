import React from 'react';
import { PDFDocument, rgb } from 'pdf-lib';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import fontkit from "@pdf-lib/fontkit";
import {
  Grid,
} from "@mui/material";
import {
    getSmartDocumentsTextInfoById 
} from "../../../../actions/document";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { CalculateAdjustedY }  from "../../admin/smart-documents/Pdf/CalculateAdjustedY"

const DownloadSmartDocument = ({ data, UserProfile,  companyProfile, handleOpenAlertError }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch(); 

  const fetchEmployeeDocument = async () => {
    try {
      if (UserProfile && companyProfile) {
        const signatureCompanyProfile = {
          authorizedSignatureOneImageFilename: companyProfile.authorizedSignatureOneImageFilename,
          authorizedSignatureOneName:  companyProfile.authorizedSignatureOneName,
          authorizedSignatureOnePosition: companyProfile.authorizedSignatureOnePosition,
          authorizedSignatureTwoImageFilename: companyProfile.authorizedSignatureTwoImageFilename,        
          authorizedSignatureTwoName: companyProfile.authorizedSignatureTwoName,
          authorizedSignatureTwoPosition: companyProfile.authorizedSignatureTwoPosition,
          certificateSignatureImageFilename: companyProfile.certificateSignatureImageFilename,
          certificateSignatureName: companyProfile.certificateSignatureName,
          certificateSignaturePosition: companyProfile.certificateSignaturePosition,
          certificateSignatureName_EN: companyProfile.certificateSignatureName_EN,
          certificateSignaturePosition_EN: companyProfile.certificateSignaturePosition_EN,
          certificateSignaturePosition: companyProfile.certificateSignaturePosition,
          witnessSignatureImageFilename: companyProfile.witnessSignatureImageFilename,
          witnessSignatureName: companyProfile.witnessSignatureName,
          witnessSignaturePosition: companyProfile.witnessSignaturePosition,
        };
       
        const res = await dispatch(getSmartDocumentsTextInfoById(
          data.smartDocumentId,                        
          UserProfile,
          signatureCompanyProfile  
        ));
        return res.data;
      }
    } catch (error) {
      console.error('Error fetching employee document:', error);
      handleOpenAlertError(error.message || 'An error occurred while fetching the document.');
    }
    return null;
  };

  const replaceTagsWithUserData = (text, format) => {
    const results = [];    
    const modifiedText = text.replace(/([#@]\w+)/g, (match) => {
      const tag = match.slice(1);    
      const mention = format.mention.find(m => m.id === tag);
      
      if (mention && UserProfile) {
        const result = { userData: String(UserProfile[tag] || ''), type: 'mention' };
        results.push(result); 
        return result.userData || ''; 
      } else {
        return text; 
      }
    });      
    return results; 
  };


  const handleDownload = async () => {

    let textInfo = await fetchEmployeeDocument();

    if (textInfo && Array.isArray(textInfo.smartDocument)) {
      try {
        const smartDocumentText = textInfo.smartDocument; 
        const pdfDoc = await PDFDocument.create();
        pdfDoc.registerFontkit(fontkit);

        const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
        const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
        const font = await pdfDoc.embedFont(fontBytes);
  
        const page = pdfDoc.addPage([595.28, 841.89]); 
        const { height, width } = page.getSize();

        let fontSize = null || 16;

        smartDocumentText.forEach((item) => {
          let { text, format, bounds } = item;

          let textMention;
          if (/[#@]\w+/.test(text)) {
            textMention = replaceTagsWithUserData(text, format);
          }      

          let mentionWidth = 0;
          if (Array.isArray(format.mention) && format.mention.length > 0) {
              mentionWidth = parseFloat(format.mention[0].width) || 0;
          }  
          
          if (format && typeof format.size === 'string') {
            const sizeValue = format.size.replace('px', ''); 
            fontSize = parseFloat(sizeValue) || 16; 
          } 

          let adjustY;
          if(fontSize < 16) {
            adjustY = 9.0
          } else if (fontSize >= 16 && fontSize < 18) {
            adjustY = 8.9
          } else if (fontSize >= 18 && fontSize < 20) {
            adjustY = 8.87
          } else if (fontSize >= 20 && fontSize < 22) {
            adjustY = 8.8
          } else if (fontSize >= 22 && fontSize < 24) {
            adjustY = 8.5
          } else if (fontSize >= 24 && fontSize < 26) {
            adjustY = 8.5
          }  else if (fontSize >= 26 && fontSize < 28) {
            adjustY = 9.0
          } else {
            adjustY = 8.6
          }
          
          const x = bounds.left;
          const y = height - bounds.top - fontSize; 
  
          if (textMention && textMention.length > 0 && textMention[0].type === 'mention') {
              page.drawText(textMention[0].userData, {
                x: x,
                y: y - adjustY,
                size: fontSize,
                font: font,
                color: rgb(0, 0, 0),
              });
          } else {
              page.drawText(text, {
                x: x,
                y: y,
                size: fontSize,
                font: font,
                color: rgb(0, 0, 0),
              });
          }

          if (mentionWidth > 0) {
            page.drawText(' ', {
                x: x + font.widthOfTextAtSize(text, fontSize) + mentionWidth, 
                y: y,
                size: fontSize,
                font: font,
                color: rgb(0, 0, 0), 
            });
          } 
        
  
          if (format.bold) {
            for (let i = 0; i < 3; i++) { 
              page.drawText(text, {
                x: x + (i * 0.12), 
                y: y - (i * 0.12),
                size: fontSize,
                font: font,
                color: rgb(0, 0, 0),
              });
            } 
          }
   
          if (format.underline) {
            const underlineY = y - 3;
          
            let textWidth = font.widthOfTextAtSize(text, fontSize);
            const adjustedTextWidth = textWidth;  
          
            page.drawLine({
              start: { x: x, y: underlineY },
              end: { x: x + adjustedTextWidth, y: underlineY },
              thickness: 1,
              color: rgb(0, 0, 0),
            });
          
            const whiteLineLength = 7.5
            page.drawLine({
              start: { x: x + textWidth, y: underlineY },
              end: { x: x + textWidth + whiteLineLength, y: underlineY},
              thickness: 3, 
              color: rgb(1, 1, 1),
            });
          }  
        });

        await handleSignatures(textInfo, pdfDoc, page, width);

        async function handleSignatures(textInfo, pdfDoc, page, width) {
          if (textInfo && textInfo.signaturesPositions) {

            const imageTypes = [
              { key: 'ลายเซ็นพนักงาน TH', name: 'ลายเซ็นพนักงาน TH'}, 
              { key: 'ลายเซ็นพนักงาน EN', name: 'ลายเซ็นพนักงาน EN'},     
              { key: 'ลายเซ็นผู้มีอำนาจของบริษัท คนที่ 1', name: 'ลายเซ็นผู้มีอำนาจของบริษัท คนที่ 1' },
              { key: 'ลายเซ็นผู้มีอำนาจของบริษัท คนที่ 2', name: 'ลายเซ็นผู้มีอำนาจของบริษัท คนที่ 2' },
              { key: 'ลายเซ็นผู้ออกหนังสือรับรอง TH', name: 'ลายเซ็นผู้ออกหนังสือรับรอง TH' },
              { key: 'ลายเซ็นผู้ออกหนังสือรับรอง EN', name: 'ลายเซ็นผู้ออกหนังสือรับรอง EN' },
              { key: 'ลายเซ็นพยาน', name: 'ลายเซ็นพยาน' }
            ];

            for (const imageInfo of textInfo.images) {
            
              for (const imageType of imageTypes) {
                if (imageInfo.key === imageType.key) {
                  const base64Image = imageInfo.image;  

                  if (base64Image) {
                    try {

                      const imageBase64Pattern = /^data:image\/(png|jpeg|jpg);base64,/;
                      if (!imageBase64Pattern.test(base64Image)) {
                        console.error(`Invalid image data format for ${imageType.name}:`, base64Image);
                        throw new Error('Invalid image data: not an image');
                      }
            
                      const base64Data = base64Image.split(',')[1];
                      const imageBytes = Buffer.from(base64Data, 'base64');
            
                      let image = await pdfDoc.embedPng(imageBytes);
            
                      const matchingSignature = textInfo.signaturesPositions.find(
                        (signature) => signature.signatureType === imageType.name
                      );                      
                      
                      if (matchingSignature) {
                        const { x_position, y_position, width, height } = matchingSignature;
            
                        let scaledX = x_position - 133;
                        let adjustedY = CalculateAdjustedY(y_position,);
                        let ImageWidth = width - 62;
                        
                        const signatureTextWidth = font.widthOfTextAtSize(imageInfo.signatureName || "-", fontSize);
                        const positionTextWidth = font.widthOfTextAtSize(imageInfo.signaturePosition || "-", fontSize);

                        const signatureName_center = scaledX + (width / 2);
                        const signaturePosition_center = scaledX + (width / 2);
                        
                        page.drawImage(image, {
                          x: scaledX + 30,
                          y: adjustedY ,
                          width: ImageWidth,
                          height: height - 56, 
                        });
 
                        page.drawText(imageInfo.signatureName || "-", {
                          x: signatureName_center - (signatureTextWidth / 2),  
                          y: adjustedY - 30, 
                          size: fontSize,
                          font: font,
                          color: rgb(0, 0, 0),
                          textAlign: "center", 
                        }); 
    
                        if (matchingSignature.signatureType !== 'ลายเซ็นพนักงาน TH' && 
                            matchingSignature.signatureType !== 'ลายเซ็นพนักงาน EN') 
                        {      
                            page.drawText(imageInfo.signaturePosition || "-", {
                              x: signaturePosition_center  - (positionTextWidth / 2), 
                              y: adjustedY  - 50, 
                              size: fontSize,
                              font: font,
                              color: rgb(0, 0, 0),
                              textAlign: "center", 
                            });
                        } 
                      } else {
                        console.warn(`No matching signature found for ${imageType.name}, skipping.`);
                      }
                    } catch (error) {
                      console.error(`Error loading ${imageType.name} image:`, error);
                    }
                  } else {
                    console.warn(`${imageType.name} not available, skipping.`);
                  }
                }
              }
            }
            
          } else {
            console.warn('No signatures available in textInfo.');
          }
        }
        
        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const docUrl = URL.createObjectURL(blob);
        window.open(docUrl, '_blank'); 
      } catch (error) {
        console.error('Error generating the PDF:', error);
        handleOpenAlertError(error.message || 'An error occurred while generating the PDF.');
      }
    } else {
      console.error('Error: textInfo.smartDocument is not an array:', textInfo);
      handleOpenAlertError('The document data is not in the correct format.');
    }
  };

  return (
    <div>
      <Grid item>
        <ButtonBlue
          variant="contained"
          className="downloadButton"
          onClick={handleDownload}
        >
          {`${t("Download")}`}
        </ButtonBlue>  
      </Grid>
    </div>
  );
};

export default DownloadSmartDocument;
