import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  styled,
  Card,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  IconButton,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../../../actions/employee";
import {
  getAllObjectiveForApprove,
  updateMoonshot,
} from "../../../../../../actions/okrs";
import { getUserProfile } from "../../../../../../actions/user";
import { useTranslation } from "react-i18next";
import ApprovalIndividualObjective from "./Objective/index";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import "dayjs/locale/th";

dayjs.extend(minMax);

const StyledRoot = styled(Box)({
  "& .searchContainer": {
    marginTop: "20px",
    marginBottom: "20px",
  },
  "& .tableHeader": {
    fontWeight: "bold",
  },
});

const StyledTableCell = styled(TableCell)({
  fontWeight: "bold",
});

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: theme.spacing(4),
  borderRadius: "8px",
  width: "80%",
  maxHeight: "90vh",
  overflowY: "auto",
  position: "relative",
}));

const CloseButton = styled(IconButton)({
  position: "absolute",
  top: 16,
  right: 16,
});

const SearchField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "8px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& fieldset": {
      borderColor: "#ccc",
    },
    "&:hover fieldset": {
      borderColor: "#aaa",
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const ApprovalIndividualPage = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeList } = useSelector((state) => state.employees);

  const [approvalList, setApprovalList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedObjectiveId, setSelectedObjectiveId] = useState(null);
  const [moonshotUpdated, setMoonshotUpdated] = useState(false);

  useEffect(() => {
    dispatch(getAllObjectiveForApprove()).then((res) => {
      if (res.status === 200) {
        setApprovalList(res.data);
      }
    });
    dispatch(getAllEmployees("manager"));
  }, [dispatch]);

  useEffect(() => {
    if (!moonshotUpdated && employeeList && userProfile) {
      const moonshotCount = employeeList.length;

      if (!userProfile.moonshotCount) {
        dispatch(updateMoonshot(userProfile.idEmployees, moonshotCount)).then(
          (res) => {
            if (res.status === 200) {
              dispatch(getUserProfile()).then(() => {
                console.log("Updated userProfile:", userProfile);
                setMoonshotUpdated(true);
              });
            }
          }
        );
      } else {
        setMoonshotUpdated(true);
      }
    }
  }, [employeeList, userProfile, dispatch, moonshotUpdated]);

  const filteredList = approvalList.filter((row) => {
    const fullName = `${row.firstname_TH} ${row.lastname_TH}`.toLowerCase();
    const objectiveName = row.objectiveName.toLowerCase();
    const searchTextLower = searchText.toLowerCase();
    return (
      fullName.includes(searchTextLower) ||
      objectiveName.includes(searchTextLower)
    );
  });

  const handleRowClick = (id) => {
    setSelectedObjectiveId(id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedObjectiveId(null);
  };

  const formatDate = (date) => {
    const year = date.year() + (i18n.resolvedLanguage === "th" ? 543 : 0);
    return date.locale(i18n.resolvedLanguage).format(`D MMM ${year}`);
  };

  return (
    <StyledRoot className="page" style={{ marginBottom: "20px" }}>
      <Container maxWidth="lg">
        <Card
          style={{
            marginBottom: "48px",
            marginTop: "20px",
            padding: "24px",
            borderRadius: "20px",
            background:
              "linear-gradient(to right, rgba(254, 196, 144, 0.7), rgba(235, 148, 192, 0.7))",
          }}
        >
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems="center"
            justifyContent="space-between"
            className="box-header"
          >
            <Typography
              variant="h3"
              style={{
                fontWeight: "bold",
                color: "#000",
                marginBottom: "20px",
              }}
            >
              {t("ApprovalPlanList")}
            </Typography>
            <img
              src={`${process.env.PUBLIC_URL}/assets/okrs/manager/list-of-approved-outcomes.png`}
              alt="icon"
              style={{
                width: "200px",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </Box>
          <Box className="searchContainer" width="100%">
            <SearchField
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder={t("SearchByNameOrObjective")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </Box>
        </Card>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>{t("FullName")}</StyledTableCell>
                <StyledTableCell>{t("Objective")}</StyledTableCell>
                <StyledTableCell>{t("StartDate")}</StyledTableCell>
                <StyledTableCell>{t("EndDate")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredList.map((row) => {
                const keyResults = row.keyResults || [];

                const startDates = keyResults
                  .map((kr) => dayjs(kr.startDate, "YYYY-MM-DD"))
                  .filter((date) => date.isValid());

                const endDates = keyResults
                  .map((kr) => dayjs(kr.endDate, "YYYY-MM-DD"))
                  .filter((date) => date.isValid());

                let earliestStartDate = null;
                let latestEndDate = null;

                if (startDates.length > 0) {
                  earliestStartDate = dayjs.min(startDates);
                }

                if (endDates.length > 0) {
                  latestEndDate = dayjs.max(endDates);
                }

                return (
                  <TableRow
                    key={row.idIndividualObjective}
                    hover
                    role="button"
                    onClick={() => handleRowClick(row.idIndividualObjective)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>
                      {`${row.firstname_TH} ${row.lastname_TH}`}
                    </TableCell>
                    <TableCell>{row.objectiveName}</TableCell>
                    <TableCell>
                      {earliestStartDate
                        ? formatDate(earliestStartDate)
                        : t("NoDate")}
                    </TableCell>
                    <TableCell>
                      {latestEndDate ? formatDate(latestEndDate) : t("NoDate")}
                    </TableCell>
                  </TableRow>
                );
              })}
              {filteredList.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    {t("NoDataAvailable")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <StyledModal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="objective-detail-modal"
          aria-describedby="objective-detail-description"
        >
          <ModalContent>
            <CloseButton onClick={handleCloseModal}>
              <CloseIcon />
            </CloseButton>
            {selectedObjectiveId && (
              <ApprovalIndividualObjective
                idIndividualObjective={selectedObjectiveId}
                onClose={handleCloseModal}
              />
            )}
          </ModalContent>
        </StyledModal>
      </Container>
    </StyledRoot>
  );
};

export default ApprovalIndividualPage;
