import { httpClient } from "./httpClient";

const getAllBanks = () => {
  return httpClient.get(`/banks`);
};

const getBankByBankDetail = (idBankDetail) => {
  return httpClient.get(`/bank/${idBankDetail}`);
};

const updateBankDetail = (idCompany, data) => {
  return httpClient.post(`/company/bank-detail/${idCompany}`, data);
};

const getBankDetailByCompany = (query) => {
  return httpClient.get(`company/bank-detail`, { params: query });
};

export default {
  getAllBanks,
  getBankByBankDetail,
  updateBankDetail,
  getBankDetailByCompany
};