import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  marginBottom: 16,
  "& .empInfoHead": {
    fontSize: "0.9rem !important",
    color: "#757575",
    marginBottom: 4,
  },
  "& .empInfoBody": {
    fontSize: "0.9rem !important",
    marginBottom: 4,
  },
});

function EmpSlipInfo(props) {
  const { currentSlip } = props;
  const { t, i18n } = useTranslation();

  return (
    <StyledRoot>
      <Box>
        <Grid container>
          <Grid container item xs={7} md={7} lg={7} spacing={2}>
            <Grid item xs={3} md={3} lg={3}>
              <Typography className="empInfoHead">{t("FullName")} :</Typography>
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <Typography className="empInfoBody">
                    {i18n.resolvedLanguage === "th"
                      ? `${currentSlip.title_TH} ${currentSlip.firstname_TH} ${currentSlip.lastname_TH} (${currentSlip.employeeID})`
                      : `${currentSlip.title_EN} ${currentSlip.firstname_EN} ${currentSlip.lastname_EN} (${currentSlip.employeeID})`}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container item xs={5} md={5} lg={5} spacing={2}>
            <Grid item xs={4} md={4} lg={4}>
              <Typography className="empInfoHead">{t("BookID")} :</Typography>
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
              <Typography className="empInfoBody">
                {currentSlip.bookID}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container>
          <Grid container item xs={7} md={7} lg={7} spacing={2}>
            <Grid item xs={3} md={3} lg={3}>
              <Typography className="empInfoHead">{t("Company")} :</Typography>
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
              <Typography className="empInfoBody">
                {i18n.resolvedLanguage === "th"
                  ? `${currentSlip.companyName} `
                  : `${currentSlip.companyName_EN} `}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={5} md={5} lg={5} spacing={2}>
            <Grid item xs={4} md={4} lg={4}>
              <Typography className="empInfoHead">{t("BookBank")} :</Typography>
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
              <Typography className="empInfoBody">
                {currentSlip.bookBank}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container>
          <Grid container item xs={7} md={7} lg={7} spacing={2}>
            <Grid item xs={3} md={3} lg={3}>
              <Typography className="empInfoHead">
                {t("Department")} :
              </Typography>
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
              <Typography className="empInfoBody">
                {i18n.resolvedLanguage === "th"
                  ? `${currentSlip.departmentName || ""} `
                  : `${currentSlip.departmentName_EN || ""} `}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={5} md={5} lg={5} spacing={2}>
            <Grid item xs={4} md={4} lg={4}>
              <Typography className="empInfoHead">
                {t("StartDate")} :
              </Typography>
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
              <Typography className="empInfoBody">
                {dayjs(currentSlip.start).format("DD/MM/BBBB")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container>
          <Grid container item xs={7} md={7} lg={7} spacing={2}>
            <Grid item xs={3} md={3} lg={3}>
              <Typography className="empInfoHead">{t("Section")} :</Typography>
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
              <Typography className="empInfoBody">
                {i18n.resolvedLanguage === "th"
                  ? `${currentSlip.sectionName || ""} `
                  : `${currentSlip.sectionName_EN || ""} `}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={5} md={5} lg={5} spacing={2}>
            <Grid item xs={4} md={4} lg={4}>
              <Typography className="empInfoHead">{t("EndDate")} :</Typography>
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
              <Typography className="empInfoBody">
                {dayjs(currentSlip.end).format("DD/MM/BBBB")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container>
          <Grid container item xs={7} md={7} lg={7} spacing={2}>
            <Grid item xs={3} md={3} lg={3}>
              <Typography className="empInfoHead">{t("Position")} :</Typography>
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
              <Typography className="empInfoBody">
                {i18n.resolvedLanguage === "th"
                  ? `${currentSlip.positionName || ""} `
                  : `${currentSlip.positionName_EN || ""} `}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={5} md={5} lg={5} spacing={2}>
            <Grid item xs={4} md={4} lg={4}>
              <Typography className="empInfoHead">
                {t("PaymentDate")} :
              </Typography>
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
              <Typography className="empInfoBody">
                {currentSlip.payDate
                  ? dayjs(currentSlip.payDate).format("DD/MM/BBBB")
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </StyledRoot>
  );
}

export default EmpSlipInfo;
