import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  Popper,
  TextField,
  Typography,
  Box,
  Divider,
  FormLabel,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";

import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";

import DrawerCustom from "../../shared/general/Drawer";
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { addProjectEmployees, getAllProjectEmployees, updateProjectEmployees } from "../../../../actions/workforceEmployees";
import { t } from "i18next";




const StyledRoot = styled("div")({
  width: 350,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    backgroundColor: "transparent",
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "transparent",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledListPosition = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledMenuItem = styled(MenuItem)({
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const company = [
  {
    id: 1,
    companyName: "A Information Co., Ltd.",
  },
  {
    id: 2,
    companyName: "B Information Co., Ltd.",
  },
  {
    id: 3,
    companyName: "C Information Co., Ltd.",
  },
  {
    id: 4,
    companyName: "D Information Co., Ltd.",
  },
  {
    id: 5,
    companyName: "E Information Co., Ltd.",
  },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 360,
    },
  },
};

const DialogAddEdit = (props) => {
  const { open, handleCloseDialog, mode, handleSubmitProject, data } = props;
  // console.log(data)
  // console.log(mode)
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { control, handleSubmit, getValues, watch, setValue, formState: { errors, dirtyFields }, reset, setError } = useForm({
    defaultValues: {
      projectname: "",
      startDate: "",
      endDate: "",
      detail: ""
    },
  })


  useEffect(() => {
    if (mode === 'edit' && data) {
      setValue("projectname", data.name);
      setValue("startDate", data.start);
      // setValue("endDate", data.end);
      setValue("detail", data.detail);
    }
  }, [mode, data, setValue]);
  const onSubmit = async (formData) => {
    const currentYearEndDate = dayjs().endOf('year').format("YYYY-MM-DD");

    // const cleansingForm = {
    //   projectname: formData.projectname || null,
    //   startDate: dayjs(formData.startDate).format("YYYY-MM-DD") || null,
    //   endDate: formData.endDate ? dayjs(formData.startDate).format("YYYY-MM-DD") : currentYearEndDate || null,
    //   detail: formData.detail || null,
    //   haveEnd: formData.endDate ? 1 : 0
    // }

    const cleansingForm = {
      projectname: formData.projectname || null,
      startDate: dayjs(formData.startDate).format("YYYY-MM-DD") || null,
      endDate: formData.endDate ? dayjs(formData.endDate).format("YYYY-MM-DD") : currentYearEndDate || null,
      detail: formData.detail || null,
      haveEnd: formData.endDate ? 1 : 0
    }



    try {
      if (mode === 'add') {

        dispatch(addProjectEmployees(cleansingForm)).then((res) => {
          dispatch(getAllProjectEmployees())
          handleCloseDialog()
        })
      } else if (mode === 'edit') {
        cleansingForm.idProject = data.idWorkforceProject || null;
        dispatch(updateProjectEmployees(cleansingForm)).then((res) => {
          dispatch(getAllProjectEmployees())
          handleCloseDialog()
        })
      }

    } catch (err) {
      console.log(err)
    }

  }


  return (
    <Fragment>
      <DrawerCustom
        title={mode === "edit" ? t("EditProject") : t("AddNewProject")}
        anchor={"right"}
        open={open}
        onClose={handleCloseDialog}
      >
        <StyledRoot>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <StyledContentLabel gutterBottom>
                  {t("ProjectName")}/{t("Project")}
                </StyledContentLabel>
                <Controller
                  control={control}
                  name="projectname"
                  rules={{
                    required: t("PleaseEnterProjectName"),
                    maxLength: {
                      value: 200,
                      message: `${t("DetailsMustNotExceed")} 200 ${t("Characters")}`
                    }
                  }}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      autoComplete="projectname"
                      error={!!errors.projectname}
                      helperText={errors.projectname ? errors.projectname.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledContentLabel gutterBottom>{t("StartDate")}</StyledContentLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                  <Controller
                    control={control}
                    name="startDate"
                    rules={{
                      required: t("PleaseEnterStartDatePF")
                    }}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        inputFormat="dd/MM/yyyy"
                        onChange={(newValue) => field.onChange(newValue)}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: `${t("Unit.Day")} ${t("Month")} ${t("Year")}`,
                              readOnly: true,
                            }}
                            error={!!errors.startDate}
                            helperText={errors.startDate ? errors.startDate.message : null}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <StyledContentLabel gutterBottom>{t("EndDate")}</StyledContentLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                  <Controller
                    control={control}
                    name="endDate"
                    rules={{
                      validate: (value) => {
                        if (value && dayjs(getValues('startDate')).isAfter(dayjs(value))) {
                          return t("StartDateMustNotBeAfterEndDate");
                        }
                        return true;
                      }
                    }}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        inputFormat="dd/MM/yyyy"
                        onChange={(newValue) => field.onChange(newValue)}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: `${t("Unit.Day")} ${t("Month")} ${t("Year")}`,
                              readOnly: true,
                            }}
                            error={!!errors.endDate}
                            helperText={errors.endDate ? errors.endDate.message : null}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <StyledContentLabel gutterBottom>{t("Details")}</StyledContentLabel>
                <Controller
                  control={control}
                  name="detail"
                  rules={{
                    required: t("PleaseEnterDetails"),
                    maxLength: {
                      value: 255,
                      message: `${t("DetailsMustNotExceed")} 255 ${t("Characters")}`
                    }
                  }}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      autoComplete="detail"
                      multiline
                      rows={3}
                      error={!!errors.detail}
                      helperText={errors.detail ? errors.detail.message : null}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <StyledFooter>
              <ButtonBlue className="cancel" onClick={() => { reset(); handleCloseDialog(); }}>
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue type="submit" variant="contained" autoFocus>
                {t("SaveData")}
              </ButtonBlue>
            </StyledFooter>
          </form>
        </StyledRoot>
      </DrawerCustom>
    </Fragment>
  );
};

export default DialogAddEdit;
