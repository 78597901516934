import React, { useEffect, useState } from "react";
import useTable from "../../../shared/table/useTable";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { Data } from "../mockData";
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Toolbar,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useSelector, useDispatch } from "react-redux";
import { getAllIdvPlan } from "../../../../../actions/IndividualPlans";

import SearchIcon from "@mui/icons-material/Search";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const headCells = [
  { id: "image", label: "" },
  { id: "fullName", label: "Full Name" },
  { id: "position", label: "Position" },
  { id: "department", label: "Department" },
  { id: "objectiveTotal", label: "Objective" },
  { id: "krTotal", label: "KR" },
  { id: "dateCreate", label: "Date" },
  { id: "actions", label: "" },
];

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiGrid-container": {
      marginBottom: 0,
    },
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
    "& .MuiOutlinedInput-input": {
      padding: "16px 14px",
    },
    "& .MuiTableCell-root": {
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
  container: {
    padding: 16,
    overflowX: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  imageEmp: {
    width: 56,
    height: 56,
    borderRadius: "50%",
  },
  viewBtn: {
    backgroundColor: "#4f65df",
    color: "#fff",
    borderRadius: "50px",
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#4f65df",
    },
  },
}));

function EmployeeList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { result: idvPlanStore } = useSelector((state) => state.IdvPlans);

  const [records, setRecords] = useState([]);
  const [recordItems, setRecordItems] = useState([]);
  const classes = useStyles();
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);

  const fetchData = () => {
    dispatch(getAllIdvPlan());

    //fetch employee data
    /*
    axios
      .get(`${process.env.REACT_APP_API}/employees`)
      .then((res) => {
        employees = res.data;
      })
      .catch((err) => console.log(err));

    axios
      .get(`${process.env.REACT_APP_API}/idvplans`)
      .then((res) => {
        let idvPlans = res.data.filter(function (item) {
          return item.planStatus.includes("Waiting Approval");
        });

        //group by createBy
        const planGroups = idvPlans.reduce((groups, item) => {
          const group = groups[item.createBy] || [];
          group.push(item);
          groups = group;
          return groups;
        }, {});

        planGroups.forEach((element) => {
          //emp data
          let empData = employees.filter(function (item) {
            return item.fullName.includes(element.createBy);
          });

          element.department = empData[0].department;
        });

        setRecords(res.data);
        setRecordItems(res.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
    */
  };

  useEffect(() => {
    if (idvPlanStore) {
      let idvPlans = [...idvPlanStore];

      idvPlans.forEach(function (plan) {
        console.log("plan", plan);
      });

      //group by createBy
      const planGroups = idvPlans.reduce((groups, item) => {
        const group = groups[item.createByEmpId] || [];
        group.push(item);
        groups = group;
        return groups;
      }, {});

      setRecords(planGroups);
      setRecordItems(planGroups);
      setIsLoading(false);
    }
  }, [idvPlanStore]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let x = [...recordItems];
    x = x.filter((y) => {
      return y.fullName.toLowerCase().includes(searchKey.toLocaleLowerCase());
    });
    setRecords(x);
  }, [searchKey]);

  return (
    <div className={classes.root}>
      <Toolbar>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              variant="outlined"
              style={{ width: "100%" }}
              label="Search Employee"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Toolbar>
      <Paper className={classes.container}>
        <TblContainer>
          <TblHead />
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={8}
                  align="center"
                  style={{ width: "100px", padding: "20px" }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {(recordsAfterPagingAndSorting() &&
                  recordsAfterPagingAndSorting().length > 0 &&
                  recordsAfterPagingAndSorting().map((item) => (
                    <TableRow key={item.empId} hover>
                      <TableCell style={{ width: "120px" }} align="center">
                        {item.image == null || item.image == "string" ? (
                          <AccountCircleIcon
                            sx={{ color: "#e0e0e0" }}
                            className={classes.imageEmp}
                          />
                        ) : (
                          <img
                            src={`/images/employees/${item.image}`}
                            className={classes.imageEmp}
                          />
                        )}
                      </TableCell>
                      <TableCell>{`${item.firstname_TH} ${item.lastname_TH}`}</TableCell>
                      <TableCell>{item.positionName}</TableCell>
                      <TableCell>{item.departmentName}</TableCell>
                      <TableCell style={{ width: "50px" }}>
                        {item.objectiveTotal}
                      </TableCell>
                      <TableCell style={{ width: "50px" }}>
                        {item.krTotal}
                      </TableCell>
                      <TableCell>
                        {dayjs(item.dateCreate).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell style={{ width: "50px" }} align="center">
                        <Button
                          variant="outlined"
                          className={classes.viewBtn}
                          onClick={() => {
                            const fullName = `${item.firstName} ${item.lastName}`;
                            history.push({
                              pathname: `/employeeplanlist/${fullName}`,
                              state: { employee: item },
                            });
                          }}
                        >
                          <SearchIcon /> View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))) || (
                  <TableRow>
                    <TableCell
                      colSpan={8}
                      align="center"
                      style={{ width: "100px", height: "100px" }}
                    >
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
    </div>
  );
}

export default EmployeeList;
