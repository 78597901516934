import React, { Fragment, useState, useEffect } from "react";
import { connectAdvanced, useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";

import DoNotDisturbOnRoundedIcon from "@mui/icons-material/DoNotDisturbOnRounded";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../../shared/general/Drawer";

import {
  postGroupGpsLocations,
  getAllLocations,
  updateGroupGpsLocations,
} from "../../../../../../actions/gpsLocations";

import FormGpsPositions from "./FormGpsPositions";
import { Typography } from "@mui/material";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  width: 350,
  padding: 24,
  "& .wrap-form": {
    marginBottom: 16,
  },
  "& .wrap-action": {
    display: "flex",
    justifyContent: "flex-end",
  },
});

const StyleWrapForm = styled("div")({
  "&.top": {
    paddingTop: 16,
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const DialogGpsLocations = (props) => {
  const {
    open,
    method,
    mode,
    selectedGroup,
    handleClose,
    handleOpenAlert,
    handleChangeAlertType,
    selectedCompany
  } = props;
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [formData, setFormData] = useState({
    name: "",
    isActive: true,
  });
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (open) {
      if (mode === "edit" && method === "group") {
        console.log("selectedGroup: ", selectedGroup);
        setFormData(selectedGroup);
      } else {
        setFormData({
          name: "",
          isActive: true,
        });
      }
    }
  }, [open, mode, method]);

  const handleSaveGroupGpsLocations = async (isDelete) => {
    if (userProfile) {
      let formDataRequest = { ...formData };
      let result = null;
      if (mode === "edit") {
        formDataRequest.updateBy = userProfile.idEmployees;
        formDataRequest.isActive = !(isDelete === "delete");
        result = await dispatch(updateGroupGpsLocations(formDataRequest));
      } else {
        formDataRequest.createDate = dayjs(new Date()).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        formDataRequest.createBy = userProfile.idEmployees;
        formDataRequest.idCompany = selectedCompany.idCompany;
        result = await dispatch(postGroupGpsLocations(formDataRequest));
      }

      if (result) {
        handleOpenAlert();
        if (result.status === 200) {
          handleChangeAlertType("success");
          if (userProfile) {
            dispatch(getAllLocations({ idCompany: selectedCompany.idCompany }));
          }
        } else {
          handleChangeAlertType(result.data.message);
        }
        handleClose();
      } else {
        handleOpenAlert();
        handleChangeAlertType("error");
        handleClose();
      }
    } else {
      handleClose();
    }
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  return (
    <DrawerCustom
      title={`${mode === "edit" ? `${t("Edit")}` : `${t("Add")}`}${t(
        "AreaList"
      )}/${t("Location")}`}
      anchor={"right"}
      open={open}
      onClose={handleClose}
    >
      <StyledRoot>
        {method === "group" ? (
          <Fragment>
            <div className="wrap-form">
              {mode === "edit" && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <ButtonBlue
                    variant="contained"
                    color="error"
                    size="small"
                    startIcon={<DoNotDisturbOnRoundedIcon />}
                    onClick={() => handleSaveGroupGpsLocations("delete")}
                  >
                    {t("Disable")}
                  </ButtonBlue>
                </div>
              )}
              <StyleWrapForm className={mode === "edit" ? "top" : ""}>
                <StyledContentLabel color="text.third" gutterBottom>
                  {t("AreaGroupName")}/{t("Location")}
                </StyledContentLabel>
                <TextFieldTheme
                  name="name"
                  multiline
                  rows={2}
                  value={formData.name}
                  onChange={handleChange}
                />
              </StyleWrapForm>
            </div>
            <div className="wrap-action">
              <ButtonBlue onClick={handleClose} variant={"text"}>
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                onClick={handleSaveGroupGpsLocations}
                variant={"contained"}
                autoFocus
                disabled={userProfile && userProfile.readOnly}
              >
                {t("SaveData")}
              </ButtonBlue>
            </div>
          </Fragment>
        ) : (
          <FormGpsPositions
            mode={mode}
            selectedGroup={selectedGroup}
            handleOpenAlert={handleOpenAlert}
            handleChangeAlertType={handleChangeAlertType}
            handleClose={handleClose}
            selectedCompany={selectedCompany}
          />
        )}
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogGpsLocations;
