import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Grid, MenuItem, Typography, styled } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import AlertResponse from "../../shared/general/AlertResponse";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getTimeAttendance } from "../../../../actions/report";
import { DetailedTimeStamp } from "./xlsx-export/detailed-TimeStamp-xlsx";
import dayjs from "dayjs";
import { getAffiliateOrganization, getCompanyOrganizationList } from "../../../../actions/company";
import DatePickerCustom from "../../shared/date/datePicker";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingGif from "../../assets/social-media.gif";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .field-label": {
    fontSize: "14px",
    fontWeight: "500",
    paddingBottom: "4px",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const TimeAttendance = (props) => {
  const { open, handleClose } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const today = dayjs().toDate();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [TimeStampData, setTimestampData] = useState(null);
  const [allLeaveTypes, setAllLeavesType] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [selectedSection, setSelectedSection] = useState({
    idSection: null,
    sectionName: `${t("SelectSection")}`,
  });
  const [selectedDepartment, setSelectedDepartment] = useState({
    idDepartment: null,
    departmentName: `${t("SelectDepartment")}`,
  });
  const [selectedDivision, setSelectedDivision] = useState({
    idDivision: null,
    divisionName: `${t("SelectDivision")}`,
  });
  const [sectionList, setSectionList] = useState([]);
  const [divisionList, setDivisionList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [search, setSearch] = useState({
    start: userProfile && userProfile.roles.includes("ROLE_ADMIN") ? dayjs(today).set("date", 1) : null,
    end:
      userProfile && userProfile.roles.includes("ROLE_ADMIN")
        ? dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0))
        : null,
  });
  const [showSection, setShowSection] = useState(false);
  const [showDepartment, setShowDepartment] = useState(false);
  const [showDivision, setShowDivision] = useState(false);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
    setSelectedDivision({
      idDivision: null,
      divisionName: `${t("SelectDivision")}`,
    });
    setSelectedDepartment({
      idDepartment: null,
      departmentName: `${t("SelectDepartment")}`,
    });
    setSelectedSection({
      idSection: null,
      sectionName: `${t("SelectSection")}`,
    });
  };

  const onChangeDivision = (newValue) => {
    setSelectedDivision(newValue);
    setSelectedDepartment({
      idDepartment: null,
      departmentName: `${t("SelectDepartment")}`,
    });
    setSelectedSection({
      idSection: null,
      sectionName: `${t("SelectSection")}`,
    });
  };

  // const handleSelectEmployee = (employee) => {
  //     setSelectedEmployee(employee);
  // };

  useEffect(() => {
    if (userProfile && userProfile.roles.includes("ROLE_ADMIN")) {
      dispatch(getAffiliateOrganization());
    }
  }, [userProfile]);

  useEffect(() => {
    if (
      userProfile &&
      affiliateOrganizationList &&
      affiliateOrganizationList.length > 0 &&
      userProfile.roles.includes("ROLE_ADMIN")
    ) {
      let ownCompany = affiliateOrganizationList.find((x) => x.idCompany === userProfile.idCompany);
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }

      setSelectedCompany(ownCompany);
    }
  }, [userProfile, affiliateOrganizationList]);

  useEffect(() => {
    if (
      selectedCompany &&
      !(selectedDivision && selectedDivision.idDivision) &&
      !(selectedDepartment && selectedDepartment.idDepartment)
    ) {
      setDivisionList(selectedCompany.division || []);
      setDepartmentList(selectedCompany.department || []);
      setSectionList([{ idSection: "all", sectionName: `${t("All")}` }, ...selectedCompany.section]);
    } else if (
      selectedCompany &&
      selectedDivision &&
      selectedDivision.idDivision &&
      !(selectedDepartment && selectedDepartment.idDepartment)
    ) {
      const updatedDepartmentList = selectedCompany.department
        .filter((e) => e.idDivision === selectedDivision.idDivision)
        .map((item) => ({ ...item }));

      const updatedSectionList = selectedCompany.section
        .filter((e) => e.idDivision === selectedDivision.idDivision)
        .map((item) => ({ ...item }));

      setDepartmentList(updatedDepartmentList || []);
      setSectionList([{ idSection: "all", sectionName: `${t("All")}` }, ...updatedSectionList]);
    } else if (
      selectedCompany &&
      selectedDepartment &&
      selectedDepartment.idDepartment &&
      !(selectedDivision && selectedDivision.idDivision)
    ) {
      const updatedSectionList = selectedCompany.section
        .filter((e) => e.idDepartment === selectedDepartment.idDepartment)
        .map((item) => ({ ...item }));

      setSectionList([{ idSection: "all", sectionName: `${t("All")}` }, ...updatedSectionList]);
    } else if (
      selectedCompany &&
      selectedDivision &&
      selectedDivision.idDivision &&
      selectedDepartment &&
      selectedDepartment.idDepartment
    ) {
      const updatedSectionList = selectedCompany.section
        .filter((e) => e.idDepartment === selectedDepartment.idDepartment)
        .map((item) => ({ ...item }));

      setSectionList([{ idSection: "all", sectionName: `${t("All")}` }, ...updatedSectionList]);
    }
  }, [selectedCompany, selectedDepartment, selectedDivision]);

  const OnChangeDepartment = (value) => {
    setSelectedDepartment(value);
    setSelectedSection({
      idSection: null,
      sectionName: `${t("SelectSection")}`,
    });
  };

  const message =
    showDivision && !showDepartment
      ? t("NoSelectedDivisionData")
      : showDepartment && !showSection
      ? t("NoSelectedDepartmentData")
      : showSection
      ? t("NoSelectedSectionData")
      : t("PleaseEnterDetails");

  const handleDownloadFile = async () => {
    try {
      setIsFetching(true);
      const response = await getTimeAttendance({
        start: dayjs(search.start).format("YYYY-MM-DD"),
        end: dayjs(search.end).format("YYYY-MM-DD"),
        idCompany: (selectedCompany && selectedCompany.idCompany) || null,
        idDivision: (selectedDivision && selectedDivision.idDivision) || null,
        idDepartment: (selectedDepartment && selectedDepartment.idDepartment) || null,
        idSection: (selectedSection && selectedSection.idSection) || null,
      });
      if (response && response.data && response.data.result && response.data.result.length > 0) {
        setIsFetching(false);
        DetailedTimeStamp(t, response.data.result, response.data.leaveList);
      } else {
        handleChangeAlertType(message);
        handleOpenAlert(true);
        setIsFetching(false);
      }
    } catch (error) {
      console.error(error);
      handleChangeAlertType(message);
      handleOpenAlert(true);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (userProfile && userProfile.roles.includes("ROLE_ADMIN") && showSection === false) {
      setSelectedDepartment(null);
    }
  }, [userProfile, showSection]);

  useEffect(() => {
    if (userProfile && userProfile.roles.includes("ROLE_ADMIN") && showDepartment === false) {
      setSelectedDivision(null);
    }
  }, [userProfile, showDepartment]);

  // manager
  const handleSubmit = () => {
    if (TimeStampData && TimeStampData.length > 0) {
      DetailedTimeStamp(t, TimeStampData, allLeaveTypes);
    } else {
      handleChangeAlertType(t("NoData"));
      handleOpenAlert(true);
    }
  };

  const fetchData = async () => {
    try {
      setIsFetching(true);
      const response = await getTimeAttendance({
        start: dayjs(search.start).format("YYYY-MM-DD"),
        end: dayjs(search.end).format("YYYY-MM-DD"),
        // idCompany: (selectedCompany && selectedCompany.idCompany) || null,
        // idDepartment: (selectedDepartment && selectedDepartment.idDepartment) || null,
        // idSection: (selectedSection && selectedSection.idSection) || "all",
      });
      if (response && response.data && response.data.result && response.data.result.length > 0) {
        setIsFetching(false);
        setTimestampData(response.data.result);
        setAllLeavesType(response.data.AllLeavesTypeResult);
      } else {
        handleChangeAlertType(t("NoData"));
        handleOpenAlert(true);
        setIsFetching(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (userProfile && userProfile.roles.includes("ROLE_MANAGER") && search && search.start && search.end) {
      fetchData();
    } else {
      setIsFetching(false);
    }
  }, [userProfile, search, selectedDepartment, selectedCompany]);

  return (
    <DrawerCustom title={`${t("TimeStampReport")}`} anchor="right" open={open} onClose={handleClose}>
      <StyledRoot style={{ width: 400 }}>
        <Grid container spacing={2}>
          {userProfile && userProfile.roles.includes("ROLE_ADMIN") && (
            <Grid item xs={12}>
              <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                {t("Company")}
              </Typography>
              <SelectAffiliateCompany
                options={affiliateOrganizationList}
                value={selectedCompany}
                disabled={isFetching}
                onChange={(_, value) => {
                  onChangeCompany(value);
                }}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
              {t("StartDate")}
            </Typography>
            <div className="search-date">
              <DatePickerCustom
                minDate={new Date(new Date().getFullYear() - 4, 0, 1)}
                inputFormat="DD/MM/YYYY"
                value={search.start}
                name="start"
                disabled={isFetching}
                onChange={(newValue) => {
                  if (dayjs(search.end).diff(dayjs(newValue), "day") > 62) {
                    setSearch({ ...search, ["start"]: newValue, ["end"]: dayjs(newValue).add(60, "day") });
                  } else {
                    setSearch({ ...search, ["start"]: newValue });
                  }
                }}
                inputProps={{ readOnly: true }}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
              {t("EndDate")}
            </Typography>
            <div className="search-date">
              <DatePickerCustom
                minDate={search.start}
                maxDate={dayjs(search.start).add(62, "day")}
                inputFormat="DD/MM/YYYY"
                value={search.end}
                name="end"
                disabled={isFetching}
                onChange={(newValue) => {
                  setSearch({ ...search, ["end"]: newValue });
                }}
                inputProps={{ readOnly: true }}
              />
            </div>
          </Grid>

          {userProfile && userProfile.roles.includes("ROLE_ADMIN") && (
            <Grid item xs={12}>
              <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                {t("ChooseField")}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ButtonBlue
                    variant={showDivision ? "contained" : "outlined"}
                    fullWidth
                    onClick={() => {
                      setSelectedDivision(null);
                      setShowDivision((prevState) => !prevState);
                    }}
                    disabled={showDepartment || isFetching || (divisionList && divisionList.length < 1)}
                  >
                    {t("Division")}
                  </ButtonBlue>
                </Grid>
                <Grid item xs={6}>
                  <ButtonBlue
                    variant={showDepartment ? "contained" : "outlined"}
                    fullWidth
                    onClick={() => {
                      setSelectedDepartment(null);
                      setShowDepartment((prevState) => !prevState);
                    }}
                    disabled={showSection || isFetching}
                  >
                    {t("Department")}
                  </ButtonBlue>
                </Grid>
                <Grid item xs={12}>
                  <ButtonBlue
                    variant={showSection ? "contained" : "outlined"}
                    fullWidth
                    onClick={() => {
                      setSelectedSection(null);
                      setShowSection((prevState) => !prevState);
                    }}
                    disabled={!showDepartment || isFetching}
                  >
                    {t("Section")}
                  </ButtonBlue>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        {userProfile && userProfile.roles.includes("ROLE_ADMIN") && showDivision && (
          <Grid item xs={12}>
            <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px" marginTop="8px">
              {t("Division")}
            </Typography>
            <Autocomplete
              options={[...divisionList]}
              getOptionLabel={(option) =>
                i18n.resolvedLanguage === "th"
                  ? option.divisionName
                    ? option.divisionName
                    : option.divisionName_EN
                  : option.divisionName_EN
                  ? option.divisionName_EN
                  : ""
              }
              // filterOptions={filterOptions}
              renderOption={(props, option) => (
                <MenuItem {...props} key={option.idDivision}>
                  <Box>
                    <Typography>
                      {option.divisionName === null
                        ? `${t("NoDivision")}`
                        : i18n.resolvedLanguage === "th"
                        ? option.divisionName
                          ? option.divisionName
                          : option.divisionName_EN
                        : option.divisionName_EN
                        ? option.divisionName_EN
                        : ""}
                    </Typography>
                  </Box>
                </MenuItem>
              )}
              renderInput={(params) => <TextFieldTheme {...params} variant="filled" />}
              value={selectedDivision}
              disableClearable
              disabled={isFetching || (divisionList && divisionList.length < 1)}
              onChange={(_, value) => {
                onChangeDivision(value);
              }}
            />
          </Grid>
        )}

        {userProfile && userProfile.roles.includes("ROLE_ADMIN") && showDepartment && (
          <Grid item xs={12}>
            <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px" marginTop="8px">
              {t("Department")}
            </Typography>
            <Autocomplete
              options={[...departmentList]}
              getOptionLabel={(option) => (option.departmentName === null ? `${t("NoDepartment")}` : `${option.departmentName}`)}
              renderOption={(props, option) => (
                <MenuItem {...props} key={option.idDepartment}>
                  <Box>
                    <Typography>
                      {option.departmentName === null
                        ? `${t("NoDepartment")}`
                        : i18n.resolvedLanguage === "th"
                        ? option.departmentName
                        : option.departmentName_EN}
                    </Typography>
                  </Box>
                </MenuItem>
              )}
              renderInput={(params) => <TextFieldTheme {...params} variant="filled" />}
              value={selectedDepartment}
              disableClearable
              disabled={isFetching || (showDivision && showDepartment && !(selectedDivision && selectedDivision.idDivision))}
              onChange={(_, value) => {
                OnChangeDepartment(value);
              }}
            />
          </Grid>
        )}

        {userProfile && userProfile.roles.includes("ROLE_ADMIN") && showSection && (
          <Grid item xs={12}>
            <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px" marginTop="8px">
              {t("Section")}
            </Typography>
            <Autocomplete
              options={[...sectionList]}
              getOptionLabel={(option) => (option.sectionName === null ? `${t("NoSection")}` : `${option.sectionName}`)}
              renderOption={(props, option) => (
                <MenuItem {...props} key={option.idSection}>
                  <Box>
                    <Typography>
                      {option.sectionName === null
                        ? `${t("NoSection")}`
                        : i18n.resolvedLanguage === "th"
                        ? option.sectionName
                        : option.sectionName_EN}
                    </Typography>
                  </Box>
                </MenuItem>
              )}
              renderInput={(params) => <TextFieldTheme {...params} variant="filled" />}
              value={selectedSection}
              disableClearable
              disabled={isFetching || (showDepartment && !(selectedDepartment && selectedDepartment.idDepartment))}
              onChange={(_, value) => {
                setSelectedSection(value);
              }}
            />
          </Grid>
        )}

        {userProfile && userProfile.roles.includes("ROLE_ADMIN") ? (
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleClose}>
              {t("Cancel")}
            </ButtonBlue>

            {isFetching ? (
              // <CircularProgress />
              <img src={LoadingGif} style={{ width: 80, height: 80 }} />
            ) : (
              <>
                <ButtonBlue
                  variant="outlined"
                  startIcon={<DownloadRoundedIcon />}
                  onClick={() => handleDownloadFile()}
                  disabled={
                    isFetching ||
                    !(
                      (selectedDivision && selectedDivision.idDivision) ||
                      (selectedDepartment && selectedDepartment.idDepartment) ||
                      (selectedSection && selectedSection.idSection)
                    )
                  }
                >
                  {t("Download")}
                </ButtonBlue>
              </>
            )}
          </StyledFooter>
        ) : (
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleClose}>
              {t("Cancel")}
            </ButtonBlue>

            {isFetching ? (
              // <CircularProgress />
              <img src={LoadingGif} style={{ width: 80, height: 80 }} />
            ) : (
              <>
                <ButtonBlue
                  variant="outlined"
                  startIcon={<DownloadRoundedIcon />}
                  onClick={() => handleSubmit()}
                  disabled={search && (!search.start || !search.end)}
                >
                  {t("Download")}
                </ButtonBlue>
              </>
            )}
          </StyledFooter>
        )}
      </StyledRoot>
      <AlertResponse open={openAlert} handleClose={handleCloseAlert} alertType={alertType} />
    </DrawerCustom>
  );
};

export default TimeAttendance;
