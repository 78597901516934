import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import Signature_Example from '../images/signature_ex.png'
import { CalculateAdjustedY }  from './CalculateAdjustedY';

const hexToRgb = (hex) => {
    hex = hex.replace(/^#/, '');
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
    return [r / 255, g / 255, b / 255];
};

const colorNames = {
    'red': '#FF0000',
    'green': '#00FF00',
    'blue': '#0000FF',
    'black': '#000000',
    'white': '#FFFFFF',
    'violet': '#EE82EE', 
    'orange': '#FFA500',
};

const getColor = (color) => {
    if (typeof color !== 'string') {
        console.warn(`Color should be a string, received ${typeof color}. Defaulting to black.`);
        return rgb(0, 0, 0); 
    }
    if (colorNames[color]) {
        return rgb(...hexToRgb(colorNames[color]));
    }
    if (color.startsWith('#')) {
        return rgb(...hexToRgb(color));
    }
    console.warn(`Unsupported color: ${color}, defaulting to black.`);
    return rgb(0, 0, 0); 
};


const getTextStylesFromFormat = (format) => {
    return {
        fontSize: format && format.size ? parseInt(format.size, 10) || 16 : 16,
        fontWeight: format && format.bold ? 'bold' : 'normal',
        italic: format && format.italic ? true : false,
        strike: format && format.strike ? true : false,
    };
};


const  drawText = (pdfDoc, text, x, y, fontSize, font, page, marginLeft, marginRight, marginTop, marginBottom, alignment, fontWeight, italic, strike, underline, color, lineWidthbound) => {
    const words = text.split(' ');
    
    let line = '';
    let yPos = y - marginTop;
    
    const { width: pageWidth, height: pageHeight } = page.getSize();
    
    const textColor = getColor(color);

    const drawBoldText = (page, text, options) => {
        const { x, y, size, font, color } = options;
        const offset = 0.12;  

        for (let i = -1; i <= 1; i++) {
            for (let j = -1; j <= 1; j++) {
                page.drawText(text, {
                    x: x + i * offset,
                    y: y + j * offset,
                    size,
                    font,
                    color,
                });
            }
        }
    };

    const drawItalicText = (page, text, options) => {
        const { x, y, size, font, color, format = {} } = options;
        const skewAngle = format.italic ? 10 : 0; 
        const skewOffset = size * Math.tan((skewAngle * Math.PI) / 180);
        const yOffset = size * 0.2; 
    
        const lines = text.split('\n');
        lines.forEach((line, index) => {
            const lineY = y - index * size - yOffset; 
            let lineX = x;
    
            for (let i = 0; i < line.length; i++) {
                page.drawText(line[i], {
                    x: lineX + i * skewOffset,
                    y: lineY,
                    size,
                    font,
                    color,
                });
                lineX += size * 0.4;
            }
        });
    };
    
    const drawOptions = {
        x,
        y: yPos,
        size: fontSize,
        font,
        color: textColor,
        italic: italic 
    };

    for (const word of words) {
        const testLine = line + word + ' ';
        
        const testWidth = font.widthOfTextAtSize(testLine.trim(), fontSize);

        if (testWidth > pageWidth - x - marginRight) {  
            const lineWidth = font.widthOfTextAtSize(line.trim(), fontSize);
            let adjustedX = x;

            if (alignment === 'center') {
                adjustedX = (pageWidth - lineWidth - marginLeft - marginRight) / 2 + marginLeft;
            } else if (alignment === 'right') {
                adjustedX = pageWidth - lineWidth - marginRight;
            } else {
                adjustedX = x;
            }

            drawOptions.x = adjustedX;
            drawOptions.y = yPos;

            if (fontWeight === 'bold') {
                drawBoldText(page, line.trim(), drawOptions);
            } else if (italic) {
                drawItalicText(page, line.trim(), drawOptions);
            } else {
                page.drawText(line.trim(), drawOptions);
            }

            const middleY = yPos + fontSize / 2;

            if (strike) {
                page.drawLine({
                    start: { x: adjustedX, y: middleY },
                    end: { x: adjustedX + lineWidth, y: middleY },
                    thickness: 2,
                    color: textColor,
                });
            }
            
            if (underline) {
                const underlineOffset = fontSize * 0.1; 
                const underlineY = yPos - underlineOffset; 
            
                const underlineThickness = 1; 
            
                page.drawLine({
                    start: { x: adjustedX, y: underlineY },
                    end: { x: adjustedX + lineWidth, y: underlineY },
                    thickness: underlineThickness,
                    color: textColor,
                });
            }
            line = word + ' ';
            yPos -= fontSize * 1.2;

            if (yPos < marginBottom) {
                page = pdfDoc.addPage([pageWidth, pageHeight]);
                yPos = pageHeight - marginTop;
            }
        } else {
            line = testLine;  
        }
    }

    if (line.trim().length > 0) {
        const lineWidth = lineWidthbound; 

        let adjustedX = x;

        if (alignment === 'center') {
            adjustedX = (pageWidth - lineWidth - marginLeft - marginRight) / 2 + marginLeft;
        } else if (alignment === 'right') {
            adjustedX = pageWidth - lineWidth - marginRight;
        } else {
            adjustedX = x;
        } 
        drawOptions.x = adjustedX;
        drawOptions.y = yPos;

        if (fontWeight === 'bold') {
            drawBoldText(page, line.trim(), drawOptions);
        } else if (italic) {
            drawItalicText(page, line.trim(), drawOptions);
        } else {
            page.drawText(line.trim(), drawOptions);
        }

        if (strike) { 
            const strikeOffset = fontSize * -0.4; 
            page.drawLine({
                start: { x: adjustedX, y: yPos - strikeOffset },
                end: { x: adjustedX + lineWidth, y: yPos - strikeOffset },
                thickness: 1,
                color: textColor,
            });
        }

        if (underline) {
            const underlineY = yPos - fontSize * 0.1;
            page.drawLine({
                start: { x: adjustedX, y: underlineY },
                end: { x: adjustedX + lineWidth, y: underlineY },
                thickness: 1,
                color: textColor,
            });
        }
    }
};

function getFontRectangle(fontSize) {
    let fontReactangle;
    switch (true) {
        case fontSize >= 14 && fontSize <= 18:
            fontReactangle = 8.5;
            break;
        case fontSize === 20:
            fontReactangle = 9.2;
            break;
        case fontSize === 22:
            fontReactangle = 9.4;
            break;
        case fontSize === 24:
            fontReactangle = 9.9;
            break;
        case fontSize === 26:
        case fontSize === 28: 
            fontReactangle = 11;
            break;
        default:
            fontReactangle = 8.5;
    }
    return fontReactangle;
}


const drawRectangleWithText = (pdfDoc, x, y, mention_width, width, height, fontSize, extractedText, font) => {        
    const page = pdfDoc.getPages()[0];
    const adjustedHeight = fontSize * 1.2;
    const adjustment = 4;
    const adjustedY = y - adjustment - (adjustedHeight - height) / 2;
    const textY = adjustedY + (adjustedHeight - fontSize) / 2;

    const tagText = extractedText.split(" ")[0]; 
    const regularText = extractedText.substring(tagText.length).trim();

    const tagWidth = font.widthOfTextAtSize(tagText, fontSize) + 20; 
    const tagWidth_null = font.widthOfTextAtSize(tagText, fontSize) + 9; 
    const calculatedWidth = mention_width  === null ? tagWidth_null: Math.max(tagWidth, width);
    let fontReactangle = getFontRectangle(fontSize);

    page.drawRectangle({
        x: x,
        y: adjustedY - fontReactangle,
        width: calculatedWidth,
        height: adjustedHeight,
        borderColor: rgb(0, 0, 0), 
        borderWidth: 1,
        color: rgb(211 / 255, 211 / 255, 211 / 255), 
    });

    page.drawText(tagText, {
        x: x + 5,
        y: textY - 4,
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
    });

    if (regularText) {
        page.drawText(regularText, {
            x: x + tagWidth + 5,   
            y: adjustedY + (adjustedHeight - fontSize) / 2,
            size: fontSize,
            font: font,
            color: rgb(0, 0, 0),
        });
    }
};

let fontSize;
let fontWeight;

const PdfGenerator = async ({ textFormat, signatureDataList, preview, handleOnSubmit }) => {
    
    try {
        const pdfDoc = await PDFDocument.create();
        pdfDoc.registerFontkit(fontkit);
        
        const selectedFont =  'THSarabunNew';  
        const fontUrls = {'THSarabunNew': `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`}; 

        if (!fontUrls[selectedFont]) {
            console.error('Selected font is not available');
            return;
        }
        
        const fontUrl = fontUrls[selectedFont];
        const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
        const customFont = await pdfDoc.embedFont(fontBytes);
 
        if (!customFont) {
            console.error('Failed to embed font');
            return;
        }

        const a4Width = 595.28;
        const a4Height = 841.89;
        let page = pdfDoc.addPage([a4Width, a4Height]);

        const { width: pageWidth, height: pageHeight } = page.getSize();    

        if (!Array.isArray(textFormat) || textFormat.length === 0) {
            console.error('Text format is empty or not an array');
            return;
        }

        textFormat.forEach(({ bounds, text, format, margin }) => {
            if (!bounds || !margin) {
                console.error('Missing bounds or margin for text format');
                return;
            }
      
            const x = bounds.left + parseFloat(margin.left);
            const y = pageHeight - bounds.top - bounds.height - parseFloat(margin.top) + 20;

            const { fontSize: localFontSize, fontWeight: localFontWeight } = getTextStylesFromFormat(format);
            fontSize = localFontSize || 16;
            fontWeight = localFontWeight            
            const italic = format.italic || false;
            const strike = format.strike || false;
            const underline = format.underline || false
            const color = format.color || 'black';

            const marginLeft = parseFloat(margin.left) || 0;
            const marginRight = parseFloat(margin.right) || 0;
            const marginTop = parseFloat(margin.top) || 10;
            const marginBottom = parseFloat(margin.bottom) || 72;
            const lineWidth = bounds.width;
    
            if (/^#/.test(text)) {
                let extractedText = text; 
                extractedText = extractedText.replace("×", ""); 
                extractedText = extractedText.replace("✎", ""); 
                const width = bounds.width; 
                const height = fontSize; 
                const mention_width = format.mention[0].width || null
                
                drawRectangleWithText(pdfDoc, x, y,  mention_width ,width, height, fontSize, extractedText, customFont);
                return; 
            } else {
                drawText(pdfDoc, text, x, y, fontSize, customFont, page, marginLeft, marginRight, marginTop, marginBottom, format.alignment || 'left', fontWeight, italic, strike, underline, color, lineWidth);
            }          
        });
        
        await drawSignatures(signatureDataList);

        async function drawSignatures(signatureDataList) {
            if (signatureDataList !== undefined) {
                for (const item of signatureDataList) {
                    const { x, y, width, height, SignatureType } = item;
                                            
                        const signatureImageBytes = await fetch(Signature_Example).then((res) => res.arrayBuffer());                        
                        const signatureImage = await pdfDoc.embedPng(signatureImageBytes);

                        let adjustedY; 
                        adjustedY = CalculateAdjustedY(y);

                        if( x !== 0 && y !== 0 && width !==0 && height !== 0) {

                        const ImageWidth = width - 62;
                        const scaledX = x - 133;

                            page.drawImage(signatureImage, {
                                x: scaledX + 30,
                                y: adjustedY,  
                                width: ImageWidth,
                                height: height - 56,
                            });
    
                            const signatureText = `( ${SignatureType || 'Signature'} )`;
                            const signatureTextWidth = customFont.widthOfTextAtSize(signatureText, fontSize);
                            
                            const centerX = scaledX + (width / 2);
                            
                            page.drawText(signatureText, {
                                x: centerX - (signatureTextWidth / 2),  
                                y: adjustedY - 30, 
                                size: fontSize,
                                font: customFont, 
                                color: rgb(0, 0, 0),
                                textAlign: "center", 
                            });
                            
                            if (SignatureType !== 'ลายเซ็นพนักงาน TH' && 
                                SignatureType !== 'ลายเซ็นพนักงาน EN'
                            ) {
                                const positionText = 'ตำแหน่ง';
                                const positionTextWidth = customFont.widthOfTextAtSize(positionText, fontSize);
                            
                                page.drawText(positionText, {
                                    x: centerX - (positionTextWidth / 2),
                                    y: adjustedY - 50,
                                    size: fontSize,
                                    font: customFont,
                                    color: rgb(0, 0, 0),
                                    textAlign: "center",
                                });
                            }
                       }
                }
            }
        }

        const pdfBytes = await pdfDoc.save();
        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        
        if(preview === true) {
            window.open(pdfUrl); 
        }

        handleOnSubmit(pdfBlob);
    } catch (error) {
        console.error('Error generating PDF:', error);
    }
};

export default PdfGenerator;








