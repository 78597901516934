import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Paper,
  Grid,
  useMediaQuery,
  MenuItem,
  Avatar,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  TablePagination,
} from '@mui/material';
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import Loading from "../../assets/social-media.gif";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { getEmpPotential } from "../../../../actions/potential";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  backgroundColor: '#FFFFFF !important',
  minHeight: '100vh',
  padding: '20px',
  "& .MuiContainer-root": {
    paddingBottom: 16,
  },
  "& .filter-container": {
    marginBottom: "16px",
    display: "flex",
    justifyContent: "flex-end",
    gap: "8px",
    "& .MuiInputBase-root": {
      maxHeight: "48px",
    },
    "& .filter-month": {
      minWidth: "150px",
    },
    "& .filter-year": {
      minWidth: "150px",
    },
  },
  "& .menu-container": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    borderRadius: 20,
    backgroundColor: "#ffffff",
    width: "fit-content",
    overflow: "hidden",
  },
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root, & .MuiFilledInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
        borderRadius: 0,
      },
    },
  },
  "& .MuiSelect-filled": {
    padding: "16px 32px 16px 12px",
  },
});
const StylePaper = styled(Paper)({
  padding: "20px 40px 20px 40px",
  // minWidth: 350,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  marginTop: "16px",
});
const SearchWrap = styled(Box)({
  marginTop: "30px",
  "& .MuiInputBase-input,.MuiTypography-root": {
    color: "#db4172 !important",
  },
  "& .MuiInputBase-root": {
    "& .MuiSvgIcon-root": {
      color: "#db4172 !important"
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "2px solid #db4172 !important"
  },

})
const StyledTableContainer = styled(TableContainer)({
  maxHeight: "500px",
  marginTop: "10px",
  "& .MuiTableHead-root": {
    "& .MuiTableCell-root": {
      borderBottom: "none",
      color: "#637381",
      backgroundColor: "#f4f6f8",
      "&:first-of-type": {
        paddingLeft: 24,
        boxShadow: "20px -10px 20px #EEEEEE",
      },
      "&:last-of-type": {
        position: "sticky",
        right: 0,
        zIndex: 4,
      },
    },
  },
  "& .MuiTableBody-root": {
    "& .MuiTableCell-root": {
      borderBottom: "none",
      padding: 16,
      minWidth: "150px",
      "&:first-of-type": {
        backgroundColor: "#FFFFFF",
        paddingLeft: 24,
      },
      "&:last-of-type": {
        textAlign: "center",
        minWidth: "50px",
        position: "sticky",
        right: 0,
        zIndex: 4,
        backgroundColor: "#fff",
      },
    },
  },
});
const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});
const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
  },
  // "&:last-of-type": {
  //   paddingRight: 24,
  // },
}));
const LabelContainer = styled(Box)({
  textAlign: "left",
  overflowWrap: "anywhere",
  fontWeight: "600",
  width: "100%",
  "& .MuiTypography-root": {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
});
// const StyledPaper = styled(Paper)({
//   border: "none",
//   display: "flex",
//   alignItems: "flex-start",
//   marginBottom: 16,
// });
const StyledPaper = styled(Paper)({
  backgroundImage: "linear-gradient(to right, #f2d0e2, #e6d9ef , #d7ecf1)",
  borderRadius: "20px",
  padding: 20,
})
const StyledQuestion = styled("div")({
  "& .header-question": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    marginTop: "10px",
    textAlign: "center",

    "& .questionText": {
      color: "black",
      fontSize: "20px",
      fontWeight: 400,
      alignSelf: "center",
      marginTop: "10px",
    },
  },
  "& .content-question": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    marginTop: "10px",
    textAlign: "center",
    maxWidth: 1000,
    minWidth: 300,

    "& .answerText": {
      color: "black",
      fontSize: "18px",
      fontWeight: 300,
      textAlign: "center",
      alignItems: "center",
    },
  },
});
const LikertOption = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  cursor: 'pointer',
});

const getCircleSize = (index) => {
  switch (index) {
    case 0:
      return '60px';
    case 1:
      return '50px';
    case 2:
      return '40px';
    case 3:
      return '40px';
    case 4:
      return '50px';
    case 5:
      return '60px';
    default:
      return '60px';
  }
};

const LikertCircle = styled(Box)(({ isSelected, size }) => ({
  backgroundColor: isSelected ? '#33a474' : 'none',
  width: getCircleSize(size),
  height: getCircleSize(size),
  borderRadius: '50%',
  border: "3px solid #33a474",
}));

const PotentialPerson = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory()
  const phoneSceen = useMediaQuery("(max-width:850px)");

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [employeeData, setEmployeeData] = useState(null);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [filterConfig, setFilterConfig] = useState("");
  const handleChangeFilter = (event) => {
    const { value } = event.target
    setFilterConfig(value);
  }

  const getFilteredData = async () => {
    try {
      setLoading(true);
      const response = await getEmpPotential({ [filterConfig.type]: filterConfig.name });
      if (response && response.data) {
        setPage(0);
        setLoading(false);
        setEmployeeData(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getFilteredData();
  }, []);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg" style={{ position: 'relative' }}>
        {/* <Typography variant="h5" style={{ fontWeight: "600" }}>
          
        </Typography> */}
        <StylePaper
          style={{
            // height: "70px",
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#db4172',
          }}
        >
          <Typography variant="h5" style={{ fontWeight: "600", color: 'white' }}>
            {t("PersonalityTest")}
          </Typography>
        </StylePaper>
        <SearchWrap>
          <Grid container spacing={2} alignItems={"flex-end"}>
            {/* <Grid item xs={12} md={2}>
              <Typography className="label" color="text.third">ประเภทค้นหา</Typography>
              <TextFieldTheme
                name="type"
                value={filterConfig.type}
                onChange={handleChangeFilter}
                style={{ width: "100%" }}
                select
              >
                <MenuItem value="name">ชื่อ-สกุล</MenuItem>
                <MenuItem value="instutite">ตำแหน่ง</MenuItem>
              </TextFieldTheme>
            </Grid> */}
            <Grid item xs={12} md={4} /*lg={6}*/>
              <Typography className="label" color="text.third">{t("SearchFullName")}</Typography>
              <TextFieldTheme
                name="name"
                variant="outlined"
                value={filterConfig.name}
                onChange={handleChangeFilter}
                InputProps={{
                  placeholder: t("SearchFullName"),
                }}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              {/* <Box>
                <ButtonBlue
                  variant="contained"
                  radius="style2"
                  onClick={getFilteredData}
                  style={{ padding: "13.5px" }}
                >
                  <SearchIcon />
                </ButtonBlue>
              </Box> */}
            </Grid>
          </Grid>
        </SearchWrap>
        <Typography variant="subtitle1" margin={"20px 0 10px"}>{t("ListWaitingForEvaluation")}</Typography>
        <TableContainer style={{ minHeight: 450 }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledCellHeader sx={{ minWidth: "50px" }}></StyledCellHeader>
                <StyledCellHeader sx={{ textAlign: "center", minWidth: "150px" }}>{t("FullName")}</StyledCellHeader>
                <StyledCellHeader sx={{ textAlign: "center", minWidth: "100px" }}>{t("Position")}</StyledCellHeader>
                <StyledCellHeader sx={{ textAlign: "center", minWidth: "50px" }}>{t("AssessmentStatus")}</StyledCellHeader>
                <StyledCellHeader sx={{ textAlign: "center", minWidth: "50px" }}>{t("Status")}</StyledCellHeader>
                <StyledCellHeader sx={{ textAlign: "center", minWidth: "10px" }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ?
                <img width="120" alt="loading" src={Loading} />
                :
                employeeData && employeeData.length > 0 ? (
                  employeeData
                    .filter((e) => {
                      const fullName = `${e.firstname_TH || ""} ${e.lastname_TH || ""}`.trim();
                      return filterConfig === "" || fullName.includes(filterConfig);
                    })
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow key={index}>
                        <StyledCellContent>
                          <Avatar
                            style={{
                              backgroundColor: "#fff",
                              width: "50px",
                              height: "50px",
                              marginLeft: "20px",
                            }}
                            src={row.imageProfile}
                          />
                        </StyledCellContent>
                        <StyledCellContent>
                          {/* <LabelContainer> */}
                          <Typography>{`${row.firstname_TH} ${row.lastname_TH}`}</Typography>
                          {/* </LabelContainer> */}
                        </StyledCellContent>
                        <StyledCellContent>
                          {/* <LabelContainer> */}
                          <Typography>{row.positionName}</Typography>
                          {/* </LabelContainer> */}
                        </StyledCellContent>
                        <StyledCellContent>
                          <Typography>{row.role}</Typography>
                        </StyledCellContent>
                        <StyledCellContent>
                          <LabelContainer variant="subtitle2">
                            {t("WaitingForEvaluation")}
                          </LabelContainer>
                        </StyledCellContent>
                        <StyledCellContent className="sticky">
                          <ButtonBlue
                            variant="contained"
                            color="secondary"
                            radius="style2"
                            style={{ padding: "5px 15px" }}
                            onClick={() => history.push({
                              pathname: `/user-potential-test`,
                              state: { idEmployees: row.idEmployees },
                            })}
                          >
                            <EditNoteRoundedIcon style={{ fontSize: 20, marginRight: "10px" }} />
                            {t("Evaluate")}
                          </ButtonBlue>
                        </StyledCellContent>
                      </TableRow>
                    )))
                  :
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Box height="120px" display="flex" justifyContent="center" alignItems="center">
                        <Typography fontSize="20px" color="text.secondary">
                          {t("NoEvaluationItems")}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={employeeData ? employeeData.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("RowsPerPage")}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} จาก ${count !== -1 ? count : `มากกว่า ${to}`}`}
        />
        {/* <StyledPaper>
          <Grid container columnSpacing={2} alignItems={"center"}>
            <Grid item >
              <Avatar
                style={{
                  backgroundColor: "#fff",
                  width: "100px",
                  height: "100px",
                  marginLeft: "20px",
                }}
                src="https://xsgames.co/randomusers/avatar.php?g=male"
              />
            </Grid>
            <Grid item xs={3}>
              <Box marginLeft="20px">
                <Typography variant="h6">เมษา มากมี</Typography>
                <Typography variant="body1">ตำแหน่ง</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box marginLeft="10px">
                <Typography variant="h6">Boss</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box marginLeft="10px">
                <Typography variant="h6">รอการประเมิน</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box marginLeft="10px">
                <Typography variant="h6">รอการประเมิน</Typography>
              </Box>
            </Grid>
          </Grid>
        </StyledPaper> */}

      </Container>
    </StyledRoot>
  );
};

export default PotentialPerson;
