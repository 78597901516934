import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserAccessArea from "../user/AccessArea";
import CheckInLocation from "../user/AccessArea/mobile";
import AdminAccessArea from "../admin/accessArea";

const AccessAreaPage = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [isPWA, setIsPWA] = useState(false);
  useEffect(() => {
    let displayMode = "";

    if (window.matchMedia("(display-mode: standalone)").matches) {
      displayMode = "standalone";
      setIsPWA(true);
    } else {
      displayMode = "!!!!!!";
    }
  }, []);

  return (
    <>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ||
        userProfile.roles.includes("ROLE_MANAGER") ? (
          <AdminAccessArea />
        ) : isPWA ? (
          <CheckInLocation />
        ) : (
          <UserAccessArea />
        )
      ) : null}
    </>
  );
};

export default AccessAreaPage;
