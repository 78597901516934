import Quill from 'quill';

const Inline = Quill.import('blots/inline');

class EditableField extends Inline {
  static create(value) {
    let node = super.create();
    node.setAttribute('contenteditable', value.editable);
    return node;
  }

  static formats(node) {
    return {
      editable: node.getAttribute('contenteditable') === 'true',
    };
  }

  format(name, value) {
    if (name === 'editable') {
      this.domNode.setAttribute('contenteditable', value);
    } else {
      super.format(name, value);
    }
  }
}

EditableField.blotName = 'editableField';
EditableField.tagName = 'span';

export default EditableField;
