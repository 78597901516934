import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import {
  Badge,
  Container,
  Typography,
  Box,
  Tabs,
  Tab,
  Grid,
  TextField,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import DatePickerCustom from "../../shared/date/datePicker";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import TimeAttendance from "./timeAttendance";
import Overtime from "./overtime";
import Shift from "./shift";
import Compensate from "./compensate";

import TextFieldTheme from "../../shared/general/TextFieldTheme";
import {
  getAllRequestTime,
  getAllRequestTimeBy,
  getAllRequestTimeWithDraw,
  getAllRequestTimeWithDrawBy,
  getNumberOfEachPendingList,
} from "../../../../actions/requestTime";
import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../shared/general/ButtonBlue";
import {
  getLeaveRequest,
  getEmployeeChangeTime,
  getAllEmployees,
  getAllLeaveRequest,
  getAllEmployeeChangeTime,
} from "../../../../actions/employee";
import Leave from "./leave";
import {
  getAdminPendingRequestLeave,
  getAllLeaveRequestWithDraw,
  getAllLeaveWithDrawBy,
} from "../../../../actions/leave";
import { getPayrollSetting } from "../../../../actions/paytypes";
import SnackBarCustom from "../../shared/snackbarCustom";
import SelectEmployees from "../../shared/general/selectEmployees";
import { useHistory } from "react-router-dom";
import { getAffiliateOrganization } from "../../../../actions/company";

import SearchIcon from "@mui/icons-material/Search";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import loading from "../../assets/social-media.gif";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledRoot = styled("div")(({ theme }) => ({
  backgroundColor: "#FFFFFF !important",
  "& .displayFlexEnd": {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "8px 0",
    },
  },
  "& .MuiAutocomplete-root": {
    "& .MuiFilledInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
}));

const StyledWrapTabs = styled("div")({
  paddingBottom: 36,
  marginTop: 16,
  width: "100%",
});

const StyledDivTab = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: 24,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-end",
    "& .numberOfList": {
      marginTop: 16,
    },
  },
}));

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -24,
    top: 8,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#e46a76",
  },
}));

const ApporveStatusListPage = (props) => {
  const today = dayjs().toDate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [value, setValue] = useState(0);
  const [numberOfList, setNumberOfList] = useState(0);
  const [itemEdit, setItemEdit] = useState(null);
  const [viewGps, setViewGps] = useState({
    list: true,
    add: false,
    edit: false,
  });
  const [search, setSearch] = React.useState({
    // start: dayjs(today).set("date", 1),
    // end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
    start: null,
    end: null,
  });

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const history = useHistory();

  const { isFetching: isFetchingEmployeeChangeShift, result: employeeChangeShiftList } = useSelector(
    (state) => state.employeeChangeShift
  );
  const { isFetching: isFetchingRequestTime, result: requestTimeList } = useSelector((state) => state.requestTime);
  const { isFetching: isFetchingRequestTimeWithDraw, result: requestTimeWithDrawList } = useSelector(
    (state) => state.requestTimeWithDraw
  );
  const { isFetching: isFetchingLeaveEmployees, result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { isFetching: isFetchingLeaveEmployeeWithdraws, result: leaveEmployeeWithdrawsList } = useSelector(
    (state) => state.leaveEmployeesWithDraw
  );
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

  const { result: adminPendingRequestLeave, isFetching: isFetchingAdminPendingRequestLeave } = useSelector(state => state.adminPendingRequestLeave);

  const mobile = useMediaQuery("(max-width:600px)");
  const [snackBarConfig, setSnackBarConfig] = useState({
    open: false,
    message: "",
    type: "success",
  });

  useEffect(() => {
    if (userProfile) {
      dispatch(getPayrollSetting());
      dispatch(getAffiliateOrganization());
    }
  }, [userProfile]);

  useEffect(() => {
    if (userProfile && selectedCompany) {
      if (selectedTab === 0 || selectedTab === 1 || selectedTab === 4) {
        dispatch(
          getAllRequestTime({
            filter: "admin",
            start: search.start ? dayjs(search.start).format("YYYY-MM-DD") : null,
            end: search.end ? dayjs(search.end).format("YYYY-MM-DD") : null,
            tab: selectedTab,
            idCompany: selectedCompany.idCompany,
          })
        );
        dispatch(
          getAllRequestTimeWithDraw({
            filter: "admin",
            start: search.start ? dayjs(search.start).format("YYYY-MM-DD") : null,
            end: search.end ? dayjs(search.end).format("YYYY-MM-DD") : null,
            tab: selectedTab,
            idCompany: selectedCompany.idCompany,
          })
        );
      } else if (selectedTab === 3) {
        dispatch(
          getAllEmployeeChangeTime({
            filter: "admin",
            start: search.start ? dayjs(search.start).format("YYYY-MM-DD") : null,
            end: search.end ? dayjs(search.end).format("YYYY-MM-DD") : null,
            idCompany: selectedCompany.idCompany,
          })
        );
      } else if (selectedTab === 2) {
        dispatch(
          getAllLeaveRequest({
            filter: "admin",
            start: search.start ? dayjs(search.start).format("YYYY-MM-DD") : null,
            end: search.end ? dayjs(search.end).format("YYYY-MM-DD") : null,
            idCompany: selectedCompany.idCompany,
          })
        );
        dispatch(getAdminPendingRequestLeave({
          // idCompany: selectedCompany.idCompany,
          start: search.start ? dayjs(search.start).format("YYYY-MM-DD") : null,
          end: search.end ? dayjs(search.end).format("YYYY-MM-DD") : null,
          idCompany: selectedCompany.idCompany,
        }))
        dispatch(
          getAllLeaveRequestWithDraw({
            filter: "admin",
            start: search.start ? dayjs(search.start).format("YYYY-MM-DD") : null,
            end: search.end ? dayjs(search.end).format("YYYY-MM-DD") : null,
            idCompany: selectedCompany.idCompany,
          })
        );
      }
    }
  }, [userProfile, selectedTab, selectedCompany]);

  useEffect(() => {
    if (
      userProfile &&
      affiliateOrganizationList &&
      affiliateOrganizationList.length > 0
    ) {
      let ownCompany = affiliateOrganizationList.find(
        (x) => x.idCompany === userProfile.idCompany
      );
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany);
    }
  }, [userProfile, affiliateOrganizationList]);

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
    setSelectedEmployee(null);
    dispatch(getAllEmployees(null, newValue.idCompany));
  };

  const handleClickAddGps = () => {
    const temp = {
      list: false,
      add: true,
      edit: false,
    };

    setViewGps(temp);
  };
  const handleClickSaveGps = () => {
    const temp = {
      list: true,
      add: false,
      edit: false,
    };

    setViewGps(temp);
  };
  const handleClickEditGps = (item) => {
    const temp = {
      list: false,
      add: false,
      edit: true,
    };

    setViewGps(temp);
    setItemEdit(item);
  };

  const handleChange = (event, newValue) => {
    if (mobile) {
      setValue(event.target.value);
      setSelectedTab(event.target.value);
    } else {
      setValue(newValue);
      setSelectedTab(newValue);
    }
  };

  const handleClickSearch = async () => {
    dispatch(
      getAllEmployeeChangeTime({
        filter: "admin",
        idCompany: selectedCompany.idCompany,
        start: search.start ? dayjs(search.start).format("YYYY-MM-DD") : null,
        end: search.end ? dayjs(search.end).format("YYYY-MM-DD") : null,
      })
    );
    dispatch(
      getAllRequestTime({
        filter: "admin",
        idCompany: selectedCompany.idCompany,
        start: search.start ? dayjs(search.start).format("YYYY-MM-DD") : null,
        end: search.end ? dayjs(search.end).format("YYYY-MM-DD") : null,
      })
    );
    dispatch(
      getAllRequestTimeWithDraw({
        filter: "admin",
        idCompany: selectedCompany.idCompany,
        start: search.start ? dayjs(search.start).format("YYYY-MM-DD") : null,
        end: search.end ? dayjs(search.end).format("YYYY-MM-DD") : null,
      })
    );
    dispatch(
      getAllLeaveRequest({
        filter: "admin",
        idCompany: selectedCompany.idCompany,
        start: search.start ? dayjs(search.start).format("YYYY-MM-DD") : null,
        end: search.end ? dayjs(search.end).format("YYYY-MM-DD") : null,
      })
    );
    dispatch(
      getAllLeaveRequestWithDraw({
        filter: "admin",
        idCompany: selectedCompany.idCompany,
        start: search.start ? dayjs(search.start).format("YYYY-MM-DD") : null,
        end: search.end ? dayjs(search.end).format("YYYY-MM-DD") : null,
      })
    );
    dispatch(getAdminPendingRequestLeave({
      idCompany: selectedCompany.idCompany,
      start: search.start ? dayjs(search.start).format("YYYY-MM-DD") : null,
      end: search.end ? dayjs(search.end).format("YYYY-MM-DD") : null,
    }));

    fetchedNumberOfPendingLists({
      idCompany: selectedCompany && selectedCompany.idCompany,
      idEmployees: (selectedEmployee && selectedEmployee.idEmployees) || null,
      start: search.start ? dayjs(search.start).format("YYYY-MM-DD") : null,
      end: search.end ? dayjs(search.end).format("YYYY-MM-DD") : null,
    });
  };

  const [numberOFPendingList, setNumberOFPendingList] = useState(null);
  const fetchedNumberOfPendingLists = async () => {
    try {
      const response = await getNumberOfEachPendingList({
        idCompany: selectedCompany && selectedCompany.idCompany,
        idEmployees: (selectedEmployee && selectedEmployee.idEmployees) || null,
        start: search.start ? dayjs(search.start).format("YYYY-MM-DD") : null,
        end: search.end ? dayjs(search.end).format("YYYY-MM-DD") : null,
      });
      if (response && response.data) {
        setNumberOFPendingList(response.data);
      } else {
        setNumberOFPendingList(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (userProfile && selectedCompany) {
      fetchedNumberOfPendingLists();
    };
  }, [userProfile, selectedCompany]);

  // const renderListCount = (mode) => {
  //   if (
  //     userProfile &&
  //     requestTimeList &&
  //     requestTimeWithDrawList &&
  //     (mode === "time" || mode === "ot" || mode === "compensate")
  //   ) {
  //     let numberPending = 0;
  //     requestTimeList.map((r) => {
  //       if (
  //         r.idRequestType ===
  //         (mode === "ot" ? 2 : mode === "compensate" ? 3 : 1) &&
  //         (selectedEmployee
  //           ? selectedEmployee.idEmployees === r.idEmployees
  //           : true)
  //       ) {
  //         if (r.isDoubleApproval === 1) {
  //           if (
  //             (r.managerLV1ApproveBy || r.idManagerGroupLV1) &&
  //             (r.managerLV2ApproveBy || r.idManagerGroupLV2)
  //           ) {
  //             if (
  //               (!r.isManagerLV1Approve || !r.isManagerLV2Approve) &&
  //               !(r.isManagerLV1Approve === 0 || r.isManagerLV2Approve === 0)
  //             ) {
  //               numberPending++;
  //             }
  //           }
  //         } else {
  //           if (r.approvalLevel === 1 && (r.managerLV1ApproveBy || r.idManagerGroupLV1)) {
  //             if (r.isManagerLV1Approve !== 1 && r.isManagerLV1Approve !== 0) {
  //               numberPending++;
  //             }
  //           } else if (r.approvalLevel === 2 && (r.managerLV2ApproveBy || r.idManagerGroupLV2)) {
  //             if (r.isManagerLV2Approve !== 1 && r.isManagerLV2Approve !== 0) {
  //               numberPending++;
  //             }
  //           }
  //         }
  //       }
  //     });

  //     requestTimeWithDrawList.map((r) => {
  //       if (
  //         r.idRequestType ===
  //         (mode === "ot" ? 2 : mode === "compensate" ? 3 : 1) &&
  //         (selectedEmployee
  //           ? selectedEmployee.idEmployees === r.idEmployees
  //           : true)
  //       ) {
  //         if (r.isApprove === null) {
  //           numberPending++;
  //         }
  //       }
  //     });

  //     return numberPending;
  //   } else if (
  //     userProfile &&
  //     leaveEmployeesList &&
  //     leaveEmployeeWithdrawsList &&
  //     mode === "leave"
  //   ) {
  //     let numberPending = 0;

  //     leaveEmployeesList.map((r) => {
  //       if (
  //         r.isApprove === null &&
  //         (selectedEmployee
  //           ? selectedEmployee.idEmployees === r.idEmployees
  //           : true)
  //         && (
  //           r.idManager || r.idManagerGroup
  //         )
  //       ) {
  //         numberPending++;
  //       }
  //     });

  //     leaveEmployeeWithdrawsList.map((r) => {
  //       if (
  //         r.isApprove === null &&
  //         (selectedEmployee
  //           ? selectedEmployee.idEmployees === r.idEmployees
  //           : true) && r.managerApprove
  //       ) {
  //         numberPending++;
  //       }
  //     });

  //     return numberPending;
  //   } else if (userProfile && employeeChangeShiftList && mode === "shift") {
  //     let numberPending = 0;

  //     employeeChangeShiftList.map((r) => {
  //       if (
  //         r.isApprove === null &&
  //         (selectedEmployee
  //           ? selectedEmployee.idEmployees === r.idEmployees
  //           : true) && r.approveBy
  //       ) {
  //         numberPending++;
  //       }
  //     });

  //     return numberPending;
  //   } else {
  //     return 0;
  //   }
  // };

  const handleSelectEmployee = (employee) => {
    setSelectedEmployee(employee);
  };

  // const getLeaveRequestCount = () => {
  //   return adminPendingRequestLeave.filter(item => (
  //     (!selectedEmployee || item.idEmployees === selectedEmployee.idEmployees)
  //   )).length
  // }

  return (
    <StyledRoot className={`page`}>
      <SnackBarCustom config={snackBarConfig} setConfig={setSnackBarConfig} />

      <Container maxWidth="lg" style={{ paddingBottom: 40 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4" gutterBottom style={{ paddingTop: 8 }}>
              {t("RequestList")}
            </Typography>
          </Grid>
          <Grid item className="displayFlexEnd">
            <ButtonBlue
              variant="contained"
              style={{ height: "fit-content" }}
              startIcon={<AccessTimeIcon />}
              onClick={() => history.push("/admin/history-approve-list")}
            >
              {t("RequestListHistory")}
            </ButtonBlue>
          </Grid>
        </Grid>
        <Box marginTop="24px">
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  options={
                    affiliateOrganizationList
                      ? affiliateOrganizationList.sort((a, b) => {
                        return a.companyName.localeCompare(b.companyName);
                      })
                      : []
                  }
                  getOptionLabel={(option) =>
                    i18n.resolvedLanguage === "th"
                      ? `${option.companyName}`
                      : `${option.companyName_EN}`
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.idCompany === value.idCompany
                  }
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      InputProps={{ ...params.InputProps, endAdornment: null }}
                      variant="filled"
                      placeholder={t("SelectCompany")}
                    />
                  )}
                  value={selectedCompany}
                  onChange={(_, value) => {
                    onChangeCompany(value);
                  }}
                  noOptionsText={t("NoData")}
                  disableClearable
                // PopperComponent={StyledPopper}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("StartDate")}
                </Typography>
                <div className="search-date">
                  <DatePickerCustom
                    inputFormat="DD/MM/YYYY"
                    value={search.start}
                    name="start"
                    views={["year", "month", "day"]}
                    onChange={(newValue) => {
                      setSearch({
                        ...search,
                        start: newValue,
                      });
                    }}
                  />
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("EndDate")}
                </Typography>
                <div className="search-date">
                  <DatePickerCustom
                    inputFormat="DD/MM/YYYY"
                    value={search.end}
                    name="start"
                    views={["year", "month", "day"]}
                    onChange={(newValue) => {
                      setSearch({
                        ...search,
                        end: newValue,
                      });
                    }}
                  />
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={3}>
              <SelectEmployees
                value={selectedEmployee}
                handleChange={handleSelectEmployee}
                position={userProfile && userProfile.idCompany === 3}
              />
            </Grid>
            <Grid item xs={12} sm={3} container>
              <div style={{ paddingBottom: 12 }}>
                <ButtonBlue variant={"contained"} onClick={handleClickSearch}>
                  {t("Search")}
                </ButtonBlue>
              </div>
            </Grid>
          </Grid>
        </Box>
        <StyledWrapTabs>
          <StyledDivTab>
            {mobile ? (
              <TextFieldTheme value={value} onChange={handleChange} select>
                <MenuItem value={0}>
                  <StyledBadge
                    badgeContent={(numberOFPendingList && numberOFPendingList.workingTime) + (numberOFPendingList && numberOFPendingList.withdrawWorkingTime)}
                    color="secondary"
                    max={999}
                  >
                    {t("WorkingTimeRequest")}
                  </StyledBadge>
                </MenuItem>
                <MenuItem value={1}>
                  <StyledBadge
                    badgeContent={(numberOFPendingList && numberOFPendingList.overTime) + (numberOFPendingList && numberOFPendingList.withdrawOverTime)}
                    color="secondary"
                    max={999}
                  >
                    {t("Overtime")}
                  </StyledBadge>
                </MenuItem>
                <MenuItem value={4}>
                  <StyledBadge
                    badgeContent={(numberOFPendingList && numberOFPendingList.compensate) + (numberOFPendingList && numberOFPendingList.withdrawCompensate)}
                    color="secondary"
                    max={999}
                  >
                    {t("CompensateRequest")}
                  </StyledBadge>
                </MenuItem>
                <MenuItem value={2}>
                  <StyledBadge
                    badgeContent={(numberOFPendingList && numberOFPendingList.leave)}
                    color="secondary"
                    max={999}
                  >
                    {t("LeaveRequest")}
                  </StyledBadge>
                </MenuItem>
                <MenuItem value={3}>
                  <StyledBadge
                    badgeContent={(numberOFPendingList && numberOFPendingList.shift)}
                    color="secondary"
                    max={999}
                  >
                    {t("ShiftRequest")}
                  </StyledBadge>
                </MenuItem>
              </TextFieldTheme>
            ) : (
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  "& .MuiTab-root.Mui-selected": {
                    color: "#46cbe2",
                  },
                }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#46cbe2",
                  },
                }}
              >
                <Tab
                  // style={{ minWidth: renderListCount("time") ? 240 : 160 }}
                  style={{ minWidth: (numberOFPendingList && numberOFPendingList.workingTime) + (numberOFPendingList && numberOFPendingList.withdrawWorkingTime) ? 240 : 160 }}
                  label={
                    <>
                      <StyledBadge
                        // badgeContent={renderListCount("time")}
                        badgeContent={(numberOFPendingList && numberOFPendingList.workingTime) + (numberOFPendingList && numberOFPendingList.withdrawWorkingTime)}
                        color="secondary"
                        max={999}
                      >
                        {t("WorkingTimeRequest")}
                      </StyledBadge>
                    </>
                  }
                  {...a11yProps(0)}
                  value={0}
                />
                <Tab
                  // style={{ minWidth: renderListCount("ot") ? 200 : 140 }}
                  style={{ minWidth: (numberOFPendingList && numberOFPendingList.overTime) + (numberOFPendingList && numberOFPendingList.withdrawOverTime) ? 200 : 140 }}
                  label={
                    <>
                      <StyledBadge
                        // badgeContent={renderListCount("ot")}
                        badgeContent={(numberOFPendingList && numberOFPendingList.overTime) + (numberOFPendingList && numberOFPendingList.withdrawOverTime)}
                        color="secondary"
                        max={999}
                      >
                        {t("Overtime")}
                      </StyledBadge>
                    </>
                  }
                  {...a11yProps(1)}
                  value={1}
                />
                <Tab
                  style={{
                    minWidth: (numberOFPendingList && numberOFPendingList.compensate) + (numberOFPendingList && numberOFPendingList.withdrawCompensate) ? 260 : 220,
                    // minWidth: renderListCount("compensate") ? 260 : 220,
                  }}
                  label={
                    <>
                      <StyledBadge
                        // badgeContent={renderListCount("compensate")}
                        badgeContent={(numberOFPendingList && numberOFPendingList.compensate) + (numberOFPendingList && numberOFPendingList.withdrawCompensate)}
                        color="secondary"
                        max={999}
                      >
                        {t("CompensateRequest")}
                      </StyledBadge>
                    </>
                  }
                  {...a11yProps(4)}
                  value={4}
                />
                <Tab
                  // style={{ minWidth: getLeaveRequestCount() ? 200 : 120 }}
                  style={{ minWidth: (numberOFPendingList && numberOFPendingList.leave) ? 200 : 120 }}
                  label={
                    <>
                      <StyledBadge
                        // badgeContent={getLeaveRequestCount()}
                        badgeContent={(numberOFPendingList && numberOFPendingList.leave)}
                        color="secondary"
                        max={999}
                      >
                        {t("LeaveRequest")}
                      </StyledBadge>
                    </>
                  }
                  {...a11yProps(2)}
                  value={2}
                />
                <Tab
                  // style={{ minWidth: renderListCount("shift") ? 180 : 140 }}
                  style={{ minWidth: (numberOFPendingList && numberOFPendingList.shift) ? 180 : 140 }}
                  label={
                    <>
                      <StyledBadge
                        // badgeContent={renderListCount("shift")}
                        badgeContent={(numberOFPendingList && numberOFPendingList.shift)}
                        color="secondary"
                        max={999}
                      >
                        {t("ShiftRequest")}
                      </StyledBadge>
                    </>
                  }
                  {...a11yProps(3)}
                  value={3}
                />
              </Tabs>
            )}

            <div className="numberOfList">
              <Typography color="error" variant="h6">{`${numberOfList} ${t(
                "List"
              )}`}</Typography>
            </div>
          </StyledDivTab>

          {userProfile && (
            <Fragment>
              {(
                [0, 1, 4].includes(value) ? (isFetchingRequestTime || isFetchingRequestTimeWithDraw) :
                  value === 2 ? (isFetchingLeaveEmployees || isFetchingLeaveEmployeeWithdraws) :
                    (value === 3 && isFetchingEmployeeChangeShift)
              ) &&
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "40px"
                  }}
                >
                  <img width="120" alt="loading" src={loading} />
                </Box>
              }
              {requestTimeList && requestTimeWithDrawList && (
                <TabPanel value={value} index={0}>
                  <TimeAttendance
                    searchDate={search}
                    selectedEmployee={selectedEmployee}
                    setSnackBarConfig={setSnackBarConfig}
                    setNumberOfList={setNumberOfList}
                  />
                </TabPanel>
              )}
              {requestTimeList && requestTimeWithDrawList && payrollSetting && (
                <TabPanel value={value} index={1}>
                  <Overtime
                    searchDate={search}
                    selectedEmployee={selectedEmployee}
                    setSnackBarConfig={setSnackBarConfig}
                    setNumberOfList={setNumberOfList}
                  />
                </TabPanel>
              )}
              {/* {leaveEmployeesList && leaveEmployeeWithdrawsList && ( */}
              {adminPendingRequestLeave && (
                <TabPanel value={value} index={2}>
                  <Leave
                    searchDate={search}
                    selectedEmployee={selectedEmployee}
                    setSnackBarConfig={setSnackBarConfig}
                    setNumberOfList={setNumberOfList}
                  />
                </TabPanel>
              )}
              {employeeChangeShiftList && (
                <TabPanel value={value} index={3}>
                  <Shift
                    searchDate={search}
                    selectedEmployee={selectedEmployee}
                    setSnackBarConfig={setSnackBarConfig}
                    setNumberOfList={setNumberOfList}
                  />
                </TabPanel>
              )}
              {requestTimeList && requestTimeWithDrawList && (
                <TabPanel value={value} index={4}>
                  <Compensate
                    searchDate={search}
                    selectedEmployee={selectedEmployee}
                    setSnackBarConfig={setSnackBarConfig}
                    setNumberOfList={setNumberOfList}
                  />
                </TabPanel>
              )}
            </Fragment>
          )}
        </StyledWrapTabs>
      </Container>
    </StyledRoot>
  );
};

export default ApporveStatusListPage;
