import React from 'react';
import ApexCharts from 'react-apexcharts';
import { useTranslation } from "react-i18next";
import LoadingGif from "../../../assets/social-media.gif";
import { Box } from '@mui/material';

const DashboardGraph = ({ data }) => {
    const { t, i18n } = useTranslation();

    if (!data || !data.graph) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <img src={LoadingGif} style={{ width: 100, height: 100 }} />
            </Box>
        );
    }


    const options = {
        chart: {
            height: 350,
            type: 'line',
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false,
            style: {
                color: '#333',
                fontSize: '10px',
                opacity: function (value) {
                    return value === 0 ? 0.3 : 1;
                }
            },
            formatter: function (value) {
                if (value === 0) {
                    return '';
                }
                return value;
            }
        },

        stroke: {
            curve: 'straight',
            width: 3.5
        },
        colors: ['#2196F3', '#4CAF50', '#FF5722'],
        grid: {
            borderColor: '#f3f3f3',
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            labels: {
                style: {
                    fontSize: '12px'
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '12px'
                }
            }
        },
        legend: {
            position: 'top',
            fontSize: '14px',
            markers: {
                width: 12,
                height: 12,
                radius: 4
            }
        }
    };

    const series = [
        {
            name: "User",
            data: data.graph.map(item => item.totalEmployees[1] || 0)
        },
        {
            name: `${t("Manager")}`,
            data: data.graph.map(item => item.totalEmployees[2] || 0)
        },
        {
            name: `${t("ResignedEmp")}`,
            data: data.graph.map(item => item.totalEmployees[3] || 0)
        }
    ];

    return (
        <div>
            <ApexCharts options={options} series={series} type="line" height={350} />
        </div>
    );
};

export default DashboardGraph;
