import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const StyledBox = styled(Box)(({ statusrequest, active }) => ({
  width: 40,
  height: 40,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 12,
  ...(statusrequest === 1 &&
    active && {
      backgroundColor: "#ebfaf2",
    }),
  ...(statusrequest === 0 && {
    backgroundColor: "#fdf3f5",
  }),
  ...(statusrequest === null &&
    active === 0 && {
      backgroundColor: "#f3f6f8",
    }),
  ...(statusrequest === null &&
    active === 1 && {
      backgroundColor: "#fffcd6",
    }),
  ...(active === 0 && {
    backgroundColor: "#f3f6f8",
  }),
}));

const StatusRequest = ({ status, active, step }) => {
  const renderIcon = () => {
    // if (active) {
      if (status === 1) {
        return (
          <i
            className={`fas fa-check-circle`}
            style={{ fontSize: 24, color: "#00c292" }}
          ></i>
        );
      } else if (status === 0) {
        return (
          <i
            className={`fas fa-times-circle`}
            style={{ fontSize: 24, color: "#e46a76" }}
          ></i>
        );
      } else if (status === 3) {
        return (
          <i
            className={`fas fa-minus-circle`}
            style={{ fontSize: 24, color: "#bfbfbf" }}
          ></i>
        )
      } else {
        return (
          <i
            className={`fas fa-question-circle`}
            style={{ fontSize: 24, color: "#fec90f" }}
          ></i>
        );
      }
    // } else {
    //   return (
    //     <i
    //       className={`fas fa-minus-circle`}
    //       style={{ fontSize: 24, color: "#bfbfbf" }}
    //     ></i>
    //   );
    // }
  };

  return (
    <StyledBox active={active} statusrequest={status}>
      {renderIcon()}
    </StyledBox>
  );
};

export default StatusRequest;
