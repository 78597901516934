import React from "react";
import {
  Box,
  Checkbox,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));

const TableCustom = (props) => {
  const { columns, rows, canSelect, selectedRows, setSelectedRows } = props;
  const { t, i18n } = useTranslation();

  const handleSelect = (_, row) => {
    const selectedIndex = selectedRows.indexOf(row);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    setSelectedRows(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n);
      setSelectedRows(newSelected);
      return;
    }
    setSelectedRows([]);
  };

  const isSelected = (row) => {
    return selectedRows.indexOf(row) !== -1;
  };

  return (
    <Box>
      <TableContainer style={{ minHeight: 400 }}>
        <Table>
          <TableHead>
            <TableRow>
              {canSelect && (
                <StyledCellHeader style={{ width: "30px" }}>
                  <Checkbox
                    style={{ padding: 0 }}
                    color="primary"
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < rows.length
                    }
                    checked={
                      rows.length > 0 && selectedRows.length === rows.length
                    }
                    onChange={handleSelectAllClick}
                    // inputProps={{
                    //   "aria-label": "select all desserts",
                    // }}
                  />
                </StyledCellHeader>
              )}

              {columns.map((c, index) => (
                <StyledCellHeader
                  key={index}
                  sx={{
                    textAlign: c.headerTextAlign
                      ? c.headerTextAlign
                      : undefined,
                    minWidth: c.minWidth ? c.minWidth : undefined,
                    width: c.width ? c.width : undefined,
                    maxWidth: c.maxWidth ? c.maxWidth : undefined,
                  }}
                >
                  {c.name}
                </StyledCellHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r, rIndex) => {
              return (
                <TableRow key={rIndex}>
                  {canSelect && (
                    <StyledCellContent sx={{ width: "30px" }}>
                      <Checkbox
                        style={{ padding: 0 }}
                        onClick={(event) => handleSelect(event, r)}
                        color="primary"
                        checked={isSelected(r)}
                        // inputProps={{
                        //   "aria-labelledby": labelId,
                        // }}
                      />
                    </StyledCellContent>
                  )}

                  {columns.map((c, cIndex) => (
                    <StyledCellContent
                      key={cIndex}
                      style={{
                        textAlign: c.cellTextAlign
                          ? c.cellTextAlign
                          : undefined,
                        fontSize: c.cellFontSize ? c.cellFontSize : undefined,
                        minWidth: c.minWidth ? c.minWidth : undefined,
                        width: c.width ? c.width : undefined,
                        maxWidth: c.maxWidth ? c.maxWidth : undefined,
                      }}
                    >
                      {c.cellRender ? (
                        c.cellRender(r)
                      ) : c.fieldKey ? (
                        <Typography fontSize="inherit">
                          {r[c.fieldKey]}
                        </Typography>
                      ) : null}
                    </StyledCellContent>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {rows.length <= 0 && (
          <Box
            style={{
              minHeight: 400,
              position: "sticky",
              left: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>{t("NoData")}</Typography>
          </Box>
        )}
      </TableContainer>
    </Box>
  );
};

export default TableCustom;
