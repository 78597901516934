import React, { useEffect, useRef, useState } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import { Box, Grid, styled, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { useDispatch, useSelector } from "react-redux";
import { usernameValidityChecks } from "../../../../../../actions/auth";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { updateUser } from "../../../../../../actions/user";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";

const StyledRoot = styled(Box)({
  padding: "16px",
});

const DrawerUsernameEdit = (props) => {

  const { drawerConfig, handleClose } = props;

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const timeoutUsernameRef = useRef(null);

  const [isDuplicateUsername, setIsDuplicateUsername] = useState(false);

  const validateYupSchema = yup.object({
    username: yup.string().nullable()
      .required(`${t("ThisFieldIsRequired")}`)
      .min(5, t("minLengthErrorMessage", {length: 5}))
      .test('username-duplicate', t("usernameDuplicated"), (value) => {
        if (!value) return true;
        if (value === employeeProfile.username) return true;
        return !isDuplicateUsername;
      }),
  });

  const useHookForm = useForm({
    defaultValues: {
      username: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  })

  const debounceUsername = () => {

    if(timeoutUsernameRef.current){
      clearTimeout(timeoutUsernameRef.current)
    }

    const newRef = setTimeout(checkValidUsername, 500)
    timeoutUsernameRef.current = newRef;
  }

  const checkValidUsername = () => {
    const username = useHookForm.getValues("username");
    if(username.length >= 5 && username !== employeeProfile.username){
      dispatch(usernameValidityChecks({ value: username })).then(res => {
        setIsDuplicateUsername(false);
      }).catch(error => {
        setIsDuplicateUsername(true);
      }).finally(() => {
        useHookForm.trigger("username");
      })
    }
  }

  const checkIsDisabledSubmit = () => {
    return usernameWatch === employeeProfile.username;
  }

  const onSubmit = async (data) => {

    const isDuplicate = await dispatch(usernameValidityChecks({ value: data.username })).then(res => {
      setIsDuplicateUsername(false);
      return false
    }).catch(error => {
      setIsDuplicateUsername(true);
      return true
    }).finally(() => {
      useHookForm.trigger("username");
    })

    if(isDuplicate){
      return
    }

    const formData = {
      idEmployees: employeeProfile.idEmployees,
      username: data.username,
    }

    const result = await dispatch(updateUser(formData));
    if(result && result.status === 200) {
      handleClose();
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }));
    }
  }

  useEffect(() => {

    if(drawerConfig.isOpen){
      useHookForm.reset({
        username: employeeProfile.username? employeeProfile.username: "",
      });
    }

  }, [drawerConfig.isOpen])

  const usernameWatch = useHookForm.watch("username")

  return (
    <DrawerCustom
      title={t("editUsername")}
      anchor={"right"}
      open={drawerConfig.isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontWeight={600} fontSize={14} color="text.secondary" gutterBottom>
                {t("Username")}
              </Typography>
              <Controller
                name="username"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    onChange={(event) => {
                      if(!isDuplicateUsername){
                        setIsDuplicateUsername(false);
                      }
                      useHookForm.clearErrors("username");
                      field.onChange(String(event.target.value).replace(/[^0-9a-zA-Z!@#$%^&*._-]/g, ""))
                      debounceUsername()
                    }}
                    error={fieldState.error? true: false}
                    helperText={fieldState.error? fieldState.error.message: null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="space-between">
                <ButtonBlue variant="text" onClick={handleClose} disabled={useHookForm.formState.isSubmitting}>{t("Cancel")}</ButtonBlue>
                <ButtonBlue variant="contained" type="submit" disabled={(useHookForm.formState.isSubmitting || checkIsDisabledSubmit() || isDuplicateUsername)}>{t("Save")}</ButtonBlue>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerUsernameEdit;