import React, { useEffect, useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import NodeContainer from "./NodeContainer";
import OrganizationData from "./OrganizationData";

const StyledRoot = styled(Box)({
  "& .diagram-component": {
    width: "400px",
    height: "400px",
    border: "solid 1px black",
    backgroundColor: "white",
  }
})

const OrganizationChart = () => {

  const [screenSize, setScreenSize] = useState({
    height: window.innerHeight
  })

  const [offset, setOffset] = useState({
    x: 0,
    y: 0,
    scale: 1,
  })

  const [prevOffset, setPrevOffset] = useState({
    x: 0,
    y: 0,
    scale: 1,
  })

  const [isMouseDown, setIsMouseDown] = useState(false);

  const setDimension = () => {
    setScreenSize({
      height: window.innerHeight
    })
  }

  const handleMouseDown = (e) => {
    setIsMouseDown(true)
    console.log(e)
    console.log("MouseDown")
  }

  const handleMouseMove = (e) => {
    if(isMouseDown){
      console.log(e)
      setOffset(prev => ({
        ...prev,
        x: prev.x + e.movementX,
        y: prev.y + e.movementY,
      }))
      console.log("MouseMove")
    }
  }

  var prevTouch = null;
  const handleTouchMove = (e) => {
    if(isMouseDown){

      console.log(e)

      const touch = e.changedTouches[0];

      if(prevTouch){
        const movementX = touch.pageX - prevTouch.pageX;
        const movementY = touch.pageY - prevTouch.pageY;
        setOffset(prev => ({
          ...prev,
          x: prev.x + movementX,
          y: prev.y + movementY,
          // x: prev.x - e.changedTouches[0].target.offsetLeft,
          // y: prev.y - e.changedTouches[0].target.offsetTop,
        }))
      }

      prevTouch = touch;
      console.log("MouseMove")
    }
  }

  const handleMouseUp = (e) => {
    setIsMouseDown(false)
    console.log(e)
    console.log("MouseUp")
  }

  const handleScroll = (e) => {
    console.log("Scroll")
  }

  useEffect(() => {
    window.addEventListener('resize', setDimension);

    return(() => {
      window.removeEventListener('resize', setDimension);
    })
  }, [screenSize])

  return (
    <StyledRoot className="page">
      <Box style={{height: (screenSize.height - 80)}}>
        <div
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onScroll={handleScroll}
          onTouchStart={handleMouseDown}
          onTouchEnd={handleMouseUp}
          onTouchMove={handleTouchMove}
          style={{position: "relative", overflow: "hidden", height: (screenSize.height - 80), cursor: "grab"}}
        >
          <div style={{transform: `translate(${offset.x}px, ${offset.y}px) scale(${offset.scale})`}}>
            <NodeContainer nodeData={OrganizationData}/>
          </div>
        </div>
      </Box>
    </StyledRoot>
  )
}

export default OrganizationChart;