import {
  REPORT_SUMMARY_OVERTIME_SHIFT_INDIVIDUAL_FETCHING,
  REPORT_SUMMARY_OVERTIME_SHIFT_INDIVIDUAL_SUCCESS,
  REPORT_SUMMARY_OVERTIME_SHIFT_INDIVIDUAL_FAILED,
} from "../actions/types";

const initialState = {
  result: {},
  isFetching: true,
  isError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case REPORT_SUMMARY_OVERTIME_SHIFT_INDIVIDUAL_FETCHING:
      return { ...state, result: {}, isFetching: true, isError: false };
    case REPORT_SUMMARY_OVERTIME_SHIFT_INDIVIDUAL_FAILED:
      return { ...state, result: {}, isFetching: false, isError: true };
    case REPORT_SUMMARY_OVERTIME_SHIFT_INDIVIDUAL_SUCCESS:
      return { ...state, result: payload? payload: {}, isFetching: false, isError: false };
    default:
      return state;
  }
}
