import rightsManageService from "../services/rightsManage.service";
import {
  LEVEL_GROUP_FETCHING,
  LEVEL_GROUP_FAILED,
  LEVEL_GROUP_SUCCESS,
  ALL_RIGHTS_FETCHING,
  ALL_RIGHTS_FAILED,
  ALL_RIGHTS_SUCCESS,
} from "./types";

export const getLevelGroup = () => async (dispatch) => {
  try {
    const res = await rightsManageService.getLevelGroup();
    if (res) {
      dispatch({
        type: LEVEL_GROUP_SUCCESS,
        payload: res,
      });
    }
  } catch (err) {
    dispatch({
      type: LEVEL_GROUP_FAILED,
    });
    console.log(err);
  }
};

export const addRights = (formData) => async () => {
  try {
    const res = await rightsManageService.addRights(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};


export const getAllRights = () => async (dispatch) => {
  try {
    const res = await rightsManageService.getAllRights();
    if (res) {
      dispatch({
        type: ALL_RIGHTS_SUCCESS,
        payload: res,
      });
    }
  } catch (err) {
    dispatch({
      type: ALL_RIGHTS_FAILED,
    });
    console.log(err);
  }
};

export const deleteRight = (formData) => async () => {
  try {
    const res = await rightsManageService.deleteRight(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const updateRights = (formData) => async () => {
  try {
    const res = await rightsManageService.updateRights(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};