import React, { useEffect } from "react";
import DrawerCustom from "../general/Drawer";
import dayjs from "dayjs";
import { Autocomplete, Box, Divider, Grid, MenuItem, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../general/TextFieldTheme";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ButtonBlue from "../general/ButtonBlue";
import { getAffiliateShiftGroup } from "../../../../actions/affiliate";
import { useDispatch, useSelector } from "react-redux";
import { addEmployeeShiftDaily } from "../../../../actions/shift";
import { openNotificationAlert } from "../../../../actions/notificationAlert";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .field-label": {
    fontSize: "14px",
    fontWeight: "500",
    paddingBottom: "4px",
  },
})

const DrawerShiftChange = (props) => {

  const { drawerConfig, onClose, employee, fetchAttendanceData } = props;
  
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const { result: affiliateShiftGroup } = useSelector(state => state.affiliateShiftGroup);

  const validateYupSchema = yup.object({
    shift: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
    shiftGroup: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
    shiftType: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
  });

  const useHookForm = useForm({
    defaultValues: {
      shiftGroup: null,
      shift: null,
      shiftType: null,
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  })

  useEffect(() => {
    // if(drawerConfig.isOpen){
      
    //   useHookForm.resetField("shiftGroup", { defaultValue: null })
    //   useHookForm.resetField("shift", { defaultValue: null })
    //   useHookForm.resetField("shiftType", { defaultValue: null })
    // }
    if(drawerConfig.isOpen){
      dispatch(getAffiliateShiftGroup())
    }
  }, [drawerConfig.isOpen])

  useEffect(() => {
    if(affiliateShiftGroup && affiliateShiftGroup.length > 0){

      const foundShiftGroup = affiliateShiftGroup.find(item => item.idShiftGroup === drawerConfig.data.pattern.idShiftGroup)
      let foundShift = null;
      let foundShiftType = null;

      if(foundShiftGroup){
        foundShift = foundShiftGroup.shift.find(item => item.idShift === drawerConfig.data.pattern.idShift)
      }

      if(foundShiftGroup){
        foundShiftType = foundShiftGroup.shiftType.find(item => item.idShiftType === drawerConfig.data.pattern.idShiftType)
      }

      useHookForm.resetField("shiftGroup", { defaultValue: foundShiftGroup? foundShiftGroup: null })
      useHookForm.resetField("shift", { defaultValue: foundShift? foundShift: null })
      useHookForm.resetField("shiftType", { defaultValue: foundShiftType? foundShiftType: null })
    }
  }, [affiliateShiftGroup])

  const shiftGroup = useHookForm.watch("shiftGroup")

  const onSubmit = async (data) => {

    const formData = {
      idEmployees: employee.idEmployees,
      workingDate: dayjs(drawerConfig.data.date).format("YYYY-MM-DD"),
      idShiftGroup: data.shiftGroup.idShiftGroup,
      idShift: data.shift.idShift,
      idShiftType: data.shiftType.idShiftType,
      isWorkingDay: data.shiftType.isWorkingDay
    }

    const result = await dispatch(addEmployeeShiftDaily(formData));

    if(result && result.status === 200) {
      onClose();
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));

      fetchAttendanceData()

    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }));
    }

  }
  
  return (
    <DrawerCustom
      title={`${t("ShiftChange")}`}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          {drawerConfig.data.date && <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>{t(`Date`)}: {dayjs(drawerConfig.data.date).format("DD MMMM BBBB")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight={500}>{t("CurrentlyData")}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontSize="14px" color="text.secondary">{t("ShiftGroup")}</Typography>
              <Typography>{drawerConfig.data.pattern.shiftGroupName}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontSize="14px" color="text.secondary">{t("ShiftName")}</Typography>
              <Typography>{drawerConfig.data.pattern.shiftName}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" color="text.secondary">{t("WorkingTime")}</Typography>
              <Typography>{drawerConfig.data.pattern.shiftTypeName} ({dayjs(drawerConfig.data.pattern.timeIn, "HH:mm:ss").format("HH:mm")} - {dayjs(drawerConfig.data.pattern.timeOut, "HH:mm:ss").format("HH:mm")})</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ borderColor: "rgba(145, 158, 171, 0.32)", borderStyle: "dashed" }}/>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight={500}>{t("ChangeData")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" color="text.secondary" paddingBottom="8px">{`${t("ShiftGroup")}`}</Typography>
              <Controller
                name="shiftGroup"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <Autocomplete
                    {...field}
                    // options={affiliateShiftGroup? affiliateShiftGroup.filter(x => !shiftGroup || (x.idCompany === shiftGroup.idCompany)): []}
                    options={affiliateShiftGroup? affiliateShiftGroup.filter(x => x.idCompany === employee.idCompany): []}
                    getOptionLabel={(option) => `${option.shiftGroupName}`}
                    isOptionEqualToValue={(option, value) => option.idShiftGroup === value.idShiftGroup}
                    sele
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.idShiftGroup}>
                        <Box>
                          <Typography>{option.shiftGroupName}</Typography>
                          <Typography color="text.secondary" fontSize="12px">{option.companyName}</Typography>
                        </Box>
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        placeholder={t("SelectData")}
                        onBlur={field.onBlur}
                        error={fieldState.error? true: false}
                        helperText={fieldState.error? fieldState.error.message: null}
                      />
                    )}
                    value={field.value}
                    onChange={(_, value) => {
                      field.onChange(value)
                      useHookForm.setValue("shift", null)
                      useHookForm.setValue("shiftType", null)
                    }}
                    noOptionsText={t("NoData")}
                    disableClearable
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" color="text.secondary" paddingBottom="8px">{`${t("Shift")}`}</Typography>
              <Controller
                  name="shift"
                  control={useHookForm.control}
                  render={({field, fieldState}) => (
                    <Autocomplete
                      {...field}
                      options={shiftGroup? shiftGroup.shift: []}
                      getOptionLabel={(option) => `${option.shiftName}`}
                      isOptionEqualToValue={(option, value) => option.idShift === value.idShift}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.idShift}>
                          <Box>
                            <Typography>{option.shiftName}</Typography>
                          </Box>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          placeholder={t("SelectData")}
                          onBlur={field.onBlur}
                          error={fieldState.error? true: false}
                          helperText={fieldState.error? fieldState.error.message: null}
                        />
                      )}
                      value={field.value}
                      onChange={(_, value) => {
                        field.onChange(value)
                      }}
                      noOptionsText={t("NoData")}
                      disableClearable
                    />
                  )}
                />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" color="text.secondary" paddingBottom="8px">{`${t("ShiftType")}`}</Typography>
              <Controller
                name="shiftType"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <Autocomplete
                    {...field}
                    options={shiftGroup? shiftGroup.shiftType: []}
                    getOptionLabel={(option) => `${option.shiftTypeName}`}
                    isOptionEqualToValue={(option, value) => option.idShiftType === value.idShiftType}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.idShiftType}>
                        <Box>
                          <Typography>{option.shiftTypeName}</Typography>
                          <Typography color="text.secondary" fontSize="12px">{dayjs(option.timeIn, "HH:mm:ss").format("HH:mm")} - {dayjs(option.timeOut, "HH:mm:ss").format("HH:mm")}</Typography>
                        </Box>
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        placeholder={t("SelectData")}
                        onBlur={field.onBlur}
                        error={fieldState.error? true: false}
                        helperText={fieldState.error? fieldState.error.message: null}
                      />
                    )}
                    value={field.value}
                    onChange={(_, value) => {
                      field.onChange(value)
                    }}
                    noOptionsText={t("NoData")}
                    disableClearable
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose} disabled={useHookForm.formState.isSubmitting}>{t("Cancel")}</ButtonBlue>
              <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>{t("Save")}</ButtonBlue>
            </Grid>
          </Grid>}
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerShiftChange;