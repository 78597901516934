import {
    COST_ELEMENT_LIST_FETCHING,
    COST_ELEMENT_LIST_SUCCESS,
    COST_ELEMENT_LIST_FAILED,
  } from "../actions/types";
  
  const initialState = {
    result: null,
    isFetching: false,
    isError: false,
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case COST_ELEMENT_LIST_FETCHING:
        return { ...state, result: null, isFetching: true, isError: false };
      case COST_ELEMENT_LIST_FAILED:
        return { ...state, result: null, isFetching: false, isError: true };
      case COST_ELEMENT_LIST_SUCCESS:
        return { ...state, result: payload, isFetching: false, isError: false };
      default:
        return state;
    }
  }
  