import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import ProfileTab from "./profile";
import Expertise from "./expertise";
import LocationTab from "./location";
import LeaveTab from "./leave";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledTabs = styled(Tabs)({
  "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
    borderColor: "#46cbe2",
    color: "#46cbe2!important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#46cbe2",
  },
  "& i": {
    marginRight: 8,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RightPanel = (props) => {
  const { data } = props;
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(0);
  const [tabItem, setTabItem] = useState([
    {
      label: `${t("PersonalInfo")}`,
      icon: <i className="fal fa-address-card"></i>,
      component: <ProfileTab data={data} />,
    },
    {
      label: `${t("Address")}`,
      icon: <i className="fal fa-map-marked-alt"></i>,
      component: <LocationTab data={data} />,
    },
    {
      label: `${t("Education")}`,
      icon: <i className="fal fa-user-graduate"></i>,
      component: <Expertise data={data} />,
    },
    {
      label: `${t("Leave")}`,
      icon: <i className="fal fa-umbrella-beach"></i>,
      component: <LeaveTab data={data}/>,
    },
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div style={{ width: "100%", marginTop: 16 }}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        {tabItem.map((value, index) => (
          <Tab
            key={index}
            label={
              <Box display="flex">
                {value.icon} {value.label}
              </Box>
            }
            {...a11yProps(index)}
          />
        ))}
      </StyledTabs>
      {tabItem.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          {tab.component}
        </TabPanel>
      ))}
    </div>
  );
};

export default RightPanel;
