import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Box,
  Container,
  Grid,
  TextField,
  Autocomplete,
  Popper,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getAffiliateOrganization } from "../../../../../actions/company";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import {
  getObjectivesByIdDepartment,
  getObjectivesByIdDivision,
  getTeamTimelineUpdatingKeyresultById,
  getTeamTimelineUpdatingKeyresultByIdDiv,
} from "../../../../../actions/okrs";
import ObjectiveCard from "./ObjectiveCard";
import InfoIcon from "@mui/icons-material/Info";
import TimelineIcon from "@mui/icons-material/ViewTimeline";
import TimelineCard from "./TimelineCard";

const StyledCard = styled(Card)({
  minHeight: "70px",
  boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
  borderRadius: 20,
  background:
    "linear-gradient(to right, rgba(254, 196, 144, 0.7), rgba(235, 148, 192, 0.7))",
  marginBottom: "48px",
  marginTop: "20px",
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
});

const StyleHeadBox = styled(Box)({
  "& .box-header": {
    padding: "0px 24px",
    minHeight: "200px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    background:
      "linear-gradient(to right, rgba(254, 196, 144, 0.7), rgba(235, 148, 192, 0.7))",
    "& .text-header": {
      fontSize: "48px",
      fontWeight: "bold",
      color: "#000000",
    },
    "& .img-header": {
      objectFit: "contain",
      width: "150px",
      height: "150px",
    },
  },

  "@media (max-width: 600px)": {
    "& .box-header": {
      flexDirection: "column",
      minHeight: "unset",
      justifyContent: "center",
      alignItems: "center",
      "& .text-header": {
        fontSize: "36px",
        marginTop: "20px",
        textAlign: "center",
      },
      "& .img-header": {
        width: "100px",
        height: "100px",
        marginTop: "20px",
      },
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#ffffff",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#ffffff",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#ffffff",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const ContainerStyled = styled(Container)(({ theme }) => ({
  paddingTop: "64px",
  height: "100%",
  minHeight: "100vh",
}));

const StyledAccordion = styled(Accordion)({
  borderRadius: "20px !important",
  marginBottom: "16px",
  boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
  "&:before": {
    display: "none",
  },
});

const StyledAccordionSummary = styled(AccordionSummary)({
  padding: "16px",
  backgroundColor: "#ffffff",
  borderRadius: "20px",
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
  "&.Mui-expanded": {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
});

const StyledAccordionDetails = styled(AccordionDetails)({
  backgroundColor: "#fafafa",
  borderBottomLeftRadius: "20px",
  borderBottomRightRadius: "20px",
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TeamStatus() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    result: affiliateOrganizationList,
    loading: affiliateLoading,
    error: affiliateError,
  } = useSelector((state) => state.affiliateOrganization);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [viewType, setViewType] = useState("department");

  const [open, setOpen] = useState(false);
  const [objectives, setObjectives] = useState([]);
  const [loadingObjectives, setLoadingObjectives] = useState(false);
  const [selectedUnitName, setSelectedUnitName] = useState("");

  const [timelineOpen, setTimelineOpen] = useState(false);
  const [timelineData, setTimelineData] = useState([]);
  const [loadingTimeline, setLoadingTimeline] = useState(false);
  const [selectedTimelineUnitName, setSelectedTimelineUnitName] = useState("");

  const viewTypeOptions = [
    { label: t("Department"), value: "department" },
    { label: t("Division"), value: "division" },
  ];

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, [dispatch]);

  const handleCompanyChange = (event, newValue) => {
    setSelectedCompany(newValue);
  };

  const handleViewTypeChange = (event, newValue) => {
    setViewType(newValue ? newValue.value : "department");
  };

  const handleDepartmentClick = async (idDepartment, departmentName) => {
    setLoadingObjectives(true);
    setSelectedUnitName(departmentName);
    setOpen(true);
    try {
      const response = await dispatch(
        getObjectivesByIdDepartment(idDepartment)
      );
      if (response && response.data) {
        setObjectives(response.data);
      } else {
        setObjectives([]);
      }
    } catch (error) {
      console.error("Error fetching objectives:", error);
      setObjectives([]);
    } finally {
      setLoadingObjectives(false);
    }
  };

  const handleDivisionClick = async (idDivision, divisionName) => {
    setLoadingObjectives(true);
    setSelectedUnitName(divisionName);
    setOpen(true);

    try {
      const response = await dispatch(getObjectivesByIdDivision(idDivision));
      if (response && response.data) {
        setObjectives(response.data);
      } else {
        setObjectives([]);
      }
    } catch (error) {
      console.error("Error fetching objectives:", error);
      setObjectives([]);
    } finally {
      setLoadingObjectives(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setObjectives([]);
  };

  const handleTimelineClick = async (id, unitName) => {
    console.log(
      "handleTimelineClick called with id:",
      id,
      "unitName:",
      unitName
    );
    setLoadingTimeline(true);
    setSelectedTimelineUnitName(unitName);
    setTimelineOpen(true);
    try {
      let response;
      if (viewType === "department") {
        response = await dispatch(getTeamTimelineUpdatingKeyresultById(id));
      } else if (viewType === "division") {
        response = await dispatch(getTeamTimelineUpdatingKeyresultByIdDiv(id));
      }
      if (response && response.data) {
        setTimelineData(response.data);
      } else {
        setTimelineData([]);
      }
    } catch (error) {
      console.error("Error fetching timeline:", error);
      setTimelineData([]);
    } finally {
      setLoadingTimeline(false);
    }
  };

  const displayedCompanies = selectedCompany
    ? [selectedCompany]
    : affiliateOrganizationList;

  if (affiliateLoading) {
    return (
      <Box textAlign="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (affiliateError) {
    return (
      <Box textAlign="center" mt={4}>
        <Typography variant="h6">{t("ErrorFetchingCompanies")}</Typography>
      </Box>
    );
  }

  return (
    <ContainerStyled maxWidth="lg">
      <StyledCard>
        <StyleHeadBox>
          <Box className="box-header">
            <Grid
              container
              pt={"40px"}
              pl={{ xs: "16px", sm: "40px" }}
              pb={{ xs: "0px", sm: "40px" }}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography className="text-header">
                  {t("TeamProgress")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                gap="8px"
                flexWrap="wrap"
                mt={2}
              >
                <div>
                  <Typography
                    fontSize="14px"
                    fontWeight="600"
                    marginBottom="4px"
                    color="text.secondary"
                  >
                    {t("SelectCompany")}
                  </Typography>
                  <StyledAutocomplete
                    style={{
                      width: "300px",
                      background: "#fff",
                      border: "none",
                      borderRadius: "8px",
                    }}
                    options={affiliateOrganizationList}
                    onChange={handleCompanyChange}
                    getOptionLabel={(option) => `${option.companyName}`}
                    renderOption={(props, option) => (
                      <li {...props} key={option.idCompany}>
                        {`${option.companyName}`}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        placeholder={`${t("SelectCompany")}`}
                      />
                    )}
                    PopperComponent={StyledPopper}
                    noOptionsText={`${t("NoData")}`}
                  />
                </div>
                <div>
                  <Typography
                    fontSize="14px"
                    fontWeight="600"
                    marginBottom="4px"
                    color="text.secondary"
                  >
                    {t("SelectViewType")}
                  </Typography>
                  <StyledAutocomplete
                    style={{
                      width: "150px",
                      background: "#fff",
                      border: "none",
                      borderRadius: "8px",
                    }}
                    options={viewTypeOptions}
                    value={
                      viewTypeOptions.find(
                        (option) => option.value === viewType
                      ) || null
                    }
                    onChange={handleViewTypeChange}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <li {...props} key={option.value}>
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        placeholder={`${t("SelectViewType")}`}
                      />
                    )}
                    PopperComponent={StyledPopper}
                    noOptionsText={`${t("NoData")}`}
                  />
                </div>
              </Grid>
            </Grid>
            <img
              className="img-header"
              src={`${process.env.PUBLIC_URL}/assets/okrs/manager/progress-status.png`}
              alt="icon"
            />
          </Box>
        </StyleHeadBox>
      </StyledCard>

      {displayedCompanies && displayedCompanies.length > 0 ? (
        displayedCompanies.map((company) => (
          <StyledAccordion key={company.idCompany}>
            <StyledAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${company.idCompany}-content`}
              id={`panel-${company.idCompany}-header`}
            >
              <Grid container alignItems="center">
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    {company.companyName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" color="textSecondary">
                    ({company.companyName_EN})
                  </Typography>
                </Grid>
              </Grid>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              {viewType === "department" ? (
                company.department && company.department.length > 0 ? (
                  <List style={{ paddingLeft: "16px" }}>
                    {company.department.map((dept) => (
                      <ListItem key={dept.idDepartment} divider>
                        <ListItemText
                          primary={
                            dept.departmentName || t("Unnamed Department")
                          }
                          secondary={
                            dept.departmentName_EN &&
                            `(${dept.departmentName_EN})`
                          }
                        />
                        <Box display="flex" gap="8px">
                          <Tooltip title={t("ViewObjectives")}>
                            <IconButton
                              onClick={() =>
                                handleDepartmentClick(
                                  dept.idDepartment,
                                  dept.departmentName
                                )
                              }
                            >
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t("ViewTimeline")}>
                            <IconButton
                              onClick={() =>
                                handleTimelineClick(
                                  dept.idDepartment,
                                  dept.departmentName
                                )
                              }
                            >
                              <TimelineIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography variant="body2">
                    {t("NoDepartmentsFound")}
                  </Typography>
                )
              ) : company.division && company.division.length > 0 ? (
                <List style={{ paddingLeft: "16px" }}>
                  {company.division.map((division) => (
                    <ListItem key={division.idDivision} divider>
                      <ListItemText
                        primary={division.divisionName || t("Unnamed Division")}
                        secondary={
                          division.divisionName_EN &&
                          `(${division.divisionName_EN})`
                        }
                      />
                      <Box display="flex" gap="8px">
                        <Tooltip title={t("ViewObjectives")}>
                          <IconButton
                            onClick={() =>
                              handleDivisionClick(
                                division.idDivision,
                                division.divisionName
                              )
                            }
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("ViewTimeline")}>
                          <IconButton
                            onClick={() =>
                              handleTimelineClick(
                                division.idDivision,
                                division.divisionName
                              )
                            }
                          >
                            <TimelineIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography variant="body2">{t("NoDivisionsFound")}</Typography>
              )}
            </StyledAccordionDetails>
          </StyledAccordion>
        ))
      ) : (
        <Typography variant="body1">{t("NoCompaniesFound")}</Typography>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        TransitionComponent={Transition}
      >
        <DialogTitle>
          {t("ObjectivesFor")} {selectedUnitName}
        </DialogTitle>
        <DialogContent>
          {loadingObjectives ? (
            <Box textAlign="center" mt={4}>
              <CircularProgress />
            </Box>
          ) : objectives && objectives.length > 0 ? (
            objectives.map((objective) => (
              <ObjectiveCard key={objective.idTeamObjective} data={objective} />
            ))
          ) : (
            <Typography variant="body1">{t("NoObjectivesFound")}</Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={timelineOpen}
        onClose={() => setTimelineOpen(false)}
        maxWidth="lg"
        fullWidth
        TransitionComponent={Transition}
      >
        <DialogTitle>
          {t("TimelineFor")} {selectedTimelineUnitName}
        </DialogTitle>
        <DialogContent>
          {loadingTimeline ? (
            <Box textAlign="center" mt={4}>
              <CircularProgress />
            </Box>
          ) : timelineData && timelineData.length > 0 ? (
            timelineData.map((item) => (
              <TimelineCard key={item.idIndividualObjective} data={item} />
            ))
          ) : (
            <Typography variant="body1">{t("NoTimelineFound")}</Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setTimelineOpen(false)} color="primary">
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </ContainerStyled>
  );
}

export default TeamStatus;
