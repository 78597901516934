
import React from 'react';
import { Box, Select, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import GestureIcon from '@mui/icons-material/Gesture';
import { useTranslation } from "react-i18next";

const SignatureSelect = ({ selectedValue, handleInsertSignature }) => {
  const { t, i18n } = useTranslation();

  return (
    <Box>
      <Select
        className="ql-font"
        onChange={handleInsertSignature}
        value={selectedValue}
        displayEmpty
        sx={{
          minWidth: 120,
          height: '25px', 
          fontSize: '0.8rem', 
        }}
        renderValue={(value) =>
          value === "" ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <span style={{ color: '#888' }}>{t("Add_a_signature")}</span>
                <ListItemIcon sx={{ minWidth: '24px' }}>  
                <GestureIcon fontSize="small" />
            </ListItemIcon>
           </Box>
          ) : (
            value
          )
        }
      >
        <MenuItem value="ลายเซ็นพนักงาน TH">
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <ListItemIcon sx={{ minWidth: '24px' }}>
              <AssignmentIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={t("Employee_Signature_TH")}
              sx={{ fontSize: '0.8rem', margin: 0 }}
            />
          </Box>
        </MenuItem>
        <MenuItem value="ลายเซ็นพนักงาน EN">
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <ListItemIcon sx={{ minWidth: '24px' }}>
              <AssignmentIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={t("Employee_Signature_EN")}
              sx={{ fontSize: '0.8rem', margin: 0 }}
            />
          </Box>
        </MenuItem>
        <MenuItem value="ลายเซ็นผู้ออกหนังสือรับรอง TH">
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <ListItemIcon sx={{ minWidth: '24px' }}>
              <SupervisorAccountIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={t("Signature_of_the_Certifying_Authority_TH")}
              sx={{ fontSize: '0.8rem', margin: 0 }}
            />
          </Box>
        </MenuItem>
        <MenuItem value="ลายเซ็นผู้ออกหนังสือรับรอง EN">
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <ListItemIcon sx={{ minWidth: '24px' }}>
              <SupervisorAccountIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={t("Signature_of_the_Certifying_Authority_EN")}
              sx={{ fontSize: '0.8rem', margin: 0 }}
            />
          </Box>
        </MenuItem>
        <MenuItem value="ลายเซ็นพยาน">
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <ListItemIcon sx={{ minWidth: '24px' }}>
              <SupervisorAccountIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={t("Witness_Signature")}
              sx={{ fontSize: '0.8rem', margin: 0 }}
            />
          </Box>
        </MenuItem>      
        <MenuItem value="ลายเซ็นผู้มีอำนาจของบริษัท คนที่ 1">
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <ListItemIcon sx={{ minWidth: '24px' }}>
              <SupervisorAccountIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={t("Authorized_Signature_Person_1")}
              sx={{ fontSize: '0.8rem', margin: 0 }}
            />
          </Box>
        </MenuItem>
        <MenuItem value="ลายเซ็นผู้มีอำนาจของบริษัท คนที่ 2">    
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <ListItemIcon sx={{ minWidth: '24px' }}>
              <SupervisorAccountIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={t("Authorized_Signature_Person_2")}
              sx={{ fontSize: '0.8rem', margin: 0 }}
            />
          </Box>
        </MenuItem>
      </Select>
    </Box>
  );
};

export default SignatureSelect;




