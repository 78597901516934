import { httpClient } from "./httpClient";

const downloadBucketFile = (path) => {
    return httpClient.get(`/attachment?path=${path}`,{ responseType: 'blob' });
};

const getCompanyLogoFile = (idCompany) => {
    return httpClient.get(`/companyLogo?idCompany=${idCompany}`,{ responseType: 'arraybuffer' });
};

export default {
    downloadBucketFile,
    getCompanyLogoFile
};