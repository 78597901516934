import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  Grid,
  Container,
  Typography,
  Divider,
  Chip,
  Box,
  Avatar,
} from "@mui/material";
import {
  TimelineItem,
  TimelineSeparator,
  TimelineContent,
  TimelineConnector,
  TimelineDot,
} from "@mui/lab";
import makeStyles from "@mui/styles/makeStyles";
import { getAllCareerPlan } from "../../../../actions/careerPlan";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddIcon from "@mui/icons-material/Add";

import DataCareer from "./showDataCareer";

import CardStyle from "../general/Card";
import ButtonBlue from "../general/ButtonBlue";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { getUserProfile } from "../../../../actions/user";
import { useTranslation } from "react-i18next";
import { getUserFirstName, getUserFullName } from "../../../../utils/userData";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "20px",
    minHeight: "calc(100vh - 50px)",
    paddingBottom: "30px",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  avatar: {
    width: 120,
    height: 120,
    borderRadius: 180,
  },
  nameYear: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 10,
  },
  background: {
    backgroundColor: "#007afc",
  },
  noDataBackground: {
    backgroundColor: "#bdbdbd",
  },
  nameSelected: {
    fontSize: 20,
    fontWeight: "bold",
    color: "white",
  },
  topic: {
    fontSize: "1rem",
    fontWeight: 500,
    textAlign: "center",
    color: "#9e9e9e",
  },
  waitingTopic: {
    margin: "20px 0px",
  },
  career: {
    fontSize: 23,
    fontWeight: "bold",
    color: "#007afc",
    marginBottom: 20,
  },
  position: {
    fontSize: 16,
    fontWeight: 600,
  },
  jobFam: {
    fontSize: 16,
    fontWeight: 600,
    marginLeft: 16,
  },
  paper: {
    margin: 20,
  },
  link: {
    textDecoration: "none",
  },
  roadMap: {
    position: "relative",
  },
  roadMapTopic: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  iconRoadMap: {
    color: "#e53935",
    fontSize: "32px",
    marginRight: "10px",
  },
  Button: {
    marginLeft: 5,
    marginRight: 5,
  },
  suggestionTopic: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconSuggestion: {
    fontSize: "32px",
    color: "#ff9800",
    marginRight: "10px",
  },
  editSection: {
    display: "flex",
    justifyContent: "flex-end",
  },
  TextEditButton: {
    fontSize: "16px",
  },
  gridContainerStrengthWeakness: {
    border: "2px solid #d3dee5",
    borderRadius: "15px",
  },
  gridStrengthWeakness: {
    padding: "20px",
    "& .Strength": {
      color: "#1b5e20",
      fontWeight: "bold",
    },
    "& .Weakness": {
      color: "#c62828",
      fontWeight: "bold",
    },
  },
  gridStrength: {
    borderRight: "2px solid #d3dee5",
  },
  chipJobFamily: {
    fontWeight: 600,
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  suggestDialog: {
    padding: "10px",
  },
}));

const DataViewSuggest = ({ empProfile, name, breadcrumb, suggest }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  // const { result: careerPlanStore } = useSelector((state) => state.careerPlan);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  const addUpdateCareerPlan = () => {
    history.push(`/myCareerePlan/enterPosition`)
  }

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom style={{ marginTop: 20 }}>
          {name}
        </Typography>
        <>
          <DataCareer data={empProfile} />
          <CardStyle style={{ marginTop: "16px" }}>
            <div style={{ padding: 24 }}>
              <Grid item xs={12}>
                <div>
                  {currentUser.roles[0] === "ROLE_USER" ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginBottom: 2,
                        }}
                      >
                        <ButtonBlue
                          type="submit"
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={() => addUpdateCareerPlan()}
                        >
                          {empProfile ? `${t("Edit")} ${t("CareerPath")}` : `${t("Add")} ${t("CareerPath")}`}
                        </ButtonBlue>
                      </Box>
                      <Divider sx={{ marginTop: 3, marginBottom: 4 }} />
                    </>
                  ) : null}
                  <div>
                    <Typography className={classes.career}>
                      {t("interestedJobPosition")}
                    </Typography>
                  </div>
                  {empProfile &&
                    (empProfile.empIntJobPos1 !== null ? (
                      <>
                        <div style={{ marginBottom: 16 }}>
                          <CardStyle>
                            <div style={{ padding: 16 }}>
                              <Grid container spacing={2}>
                                <Grid item xl={6} md={6} xs={6}>
                                  <Typography className={classes.position}>
                                    {empProfile.empIntJobPos1}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xl={6}
                                  md={6}
                                  xs={6}
                                  style={{ textAlign: "right" }}
                                >
                                  <Chip
                                    label={empProfile.empIntJobFam1}
                                    className={classes.chipJobFamily}
                                  />
                                </Grid>
                              </Grid>
                              <Typography>{empProfile.empIntReason1}</Typography>
                            </div>
                          </CardStyle>
                        </div>
                        {empProfile.empIntJobPos2 && (
                          <div style={{ marginBottom: 16 }}>
                            <CardStyle>
                              <div style={{ padding: 16 }}>
                                <Grid container spacing={2}>
                                  <Grid item xl={6} md={6} xs={6}>
                                    <Typography className={classes.position}>
                                      {empProfile.empIntJobPos2}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xl={6}
                                    md={6}
                                    xs={6}
                                    style={{ textAlign: "right" }}
                                  >
                                    <Chip
                                      label={empProfile.empIntJobFam2}
                                      className={classes.chipJobFamily}
                                    />
                                  </Grid>
                                </Grid>
                                <Typography>{empProfile.empIntReason2}</Typography>
                              </div>
                            </CardStyle>
                          </div>
                        )}
                        {empProfile.empIntJobPos3 && (
                          <div style={{ marginBottom: 16 }}>
                            <CardStyle>
                              <div style={{ padding: 16 }}>
                                <Grid container spacing={2}>
                                  <Grid item xl={6} md={6} xs={6}>
                                    <Typography className={classes.position}>
                                      {empProfile.empIntJobPos3}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xl={6}
                                    md={6}
                                    xs={6}
                                    style={{ textAlign: "right" }}
                                  >
                                    <Chip
                                      label={empProfile.empIntJobFam3}
                                      className={classes.chipJobFamily}
                                    />
                                  </Grid>
                                </Grid>
                                <Typography>{empProfile.empIntReason3}</Typography>
                              </div>
                            </CardStyle>
                          </div>
                        )}
                        <Box sx={{ marginTop: 5 }}>
                          <Grid
                            container
                            className={classes.gridContainerStrengthWeakness}
                          >
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={12}
                              sm={6}
                              xs={12}
                              className={`${classes.gridStrengthWeakness} ${classes.gridStrength}`}
                            >
                              <Typography
                                className={"Strength"}
                                style={{ color: "#4caf50" }}
                                gutterBottom
                              >
                                {t("Strength")} :{" "}
                              </Typography>
                              {empProfile &&
                                (empProfile.empStrength1 ||
                                  empProfile.empStrength2 ||
                                  empProfile.empStrength3 ? (
                                  <>
                                    <Typography className={classes.jobFam}>
                                      {empProfile.empStrength1
                                        ? `- ${empProfile.empStrength1}`
                                        : null}
                                    </Typography>
                                    <Typography className={classes.jobFam}>
                                      {empProfile.empStrength2
                                        ? `- ${empProfile.empStrength2}`
                                        : null}
                                    </Typography>
                                    <Typography className={classes.jobFam}>
                                      {empProfile.empStrength3
                                        ? `- ${empProfile.empStrength3}`
                                        : null}
                                    </Typography>
                                  </>
                                ) : (
                                  <Typography
                                    variant="body1"
                                    sx={{ color: "#9e9e9e" }}
                                  >
                                    {t("NoData")}
                                  </Typography>
                                ))}
                            </Grid>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={12}
                              sm={6}
                              xs={12}
                              className={classes.gridStrengthWeakness}
                            >
                              <Typography
                                className={"Weakness"}
                                style={{ color: "#f44336" }}
                                gutterBottom
                              >
                                {t("Weakness")} :{" "}
                              </Typography>
                              {empProfile &&
                                (empProfile.empWeakness1 ||
                                  empProfile.empWeakness2 ||
                                  empProfile.empWeakness3 ? (
                                  <>
                                    <Typography className={classes.jobFam}>
                                      {empProfile.empWeakness1
                                        ? `- ${empProfile.empWeakness1}`
                                        : null}
                                    </Typography>
                                    <Typography className={classes.jobFam}>
                                      {empProfile.empWeakness2
                                        ? `- ${empProfile.empWeakness2}`
                                        : null}
                                    </Typography>
                                    <Typography className={classes.jobFam}>
                                      {empProfile.empWeakness3
                                        ? `- ${empProfile.empWeakness3}`
                                        : null}
                                    </Typography>
                                  </>
                                ) : (
                                  <Typography
                                    variant="body1"
                                    sx={{ color: "#9e9e9e" }}
                                  >
                                    {t("NoData")}
                                  </Typography>
                                ))}
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    ) : null)}
                  {!empProfile.empIntJobFam1 || !empProfile.empIntJobPos1 ? (
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Box
                          sx={{
                            backgroundColor: "#e0e0e0",
                            borderRadius: "10px",
                            padding: 1,
                            marginTop: 2,
                            paddingLeft: 5,
                            paddingRight: 5,
                          }}
                        >
                          <Typography variant="body1" sx={{ color: "#000" }}>
                            {t("NoData")}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                </div>
              </Grid>
            </div>
          </CardStyle>

          {empProfile && empProfile.managerStrength1 ? (
            <CardStyle style={{ marginTop: "16px" }}>
              <div style={{ padding: 24 }}>
                {currentUser.roles[0] !== "ROLE_USER" ? (
                  <>
                    <Grid container>
                      <Grid item xl={8} md={6} xs={6}></Grid>
                      {true && (
                        <Grid
                          item
                          xl={4}
                          md={6}
                          xs={6}
                          style={{ textAlign: "right" }}
                        >
                          <Link
                            to={{
                              pathname: `/${currentUser.roles[0] === "ROLE_MANAGER" ? "manager" : "admin"}/career/suggestCareer/${empProfile.idEmployees}`,
                              state: { careerPlan: empProfile },
                            }}
                            className={classes.link}
                          >
                            <ButtonBlue
                              type="submit"
                              variant="contained"
                              startIcon={<AddIcon />}
                            >
                              {t("Add")} {t("recommendation")}
                            </ButtonBlue>
                          </Link>
                        </Grid>
                      )}
                    </Grid>
                    <Divider sx={{ marginTop: 3, marginBottom: 4 }} />
                  </>
                ) : null}
                <Grid container justifyContent="space-between" spacing={2}>
                  <Grid item xs={12} md={4} lg={4}>
                    <Box className={classes.suggestDialog}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Avatar
                          alt={`${empProfile.reportToName}`}
                          src={empProfile.imageManagerProfile}
                          sx={{ width: "7rem", height: "7rem" }}
                        />
                      </Box>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography variant="body1" align="center">
                              {empProfile.ManagerFirstname_TH + " " + empProfile.ManagerLastname_TH}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              align="center"
                              sx={{ color: "#6e6e6e" }}
                            >
                              {empProfile.managerPositionName}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        color: "#616161",
                      }}
                    >
                      <AllInclusiveIcon style={{ fontSize: "5rem" }} />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <Box className={classes.suggestDialog}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Avatar
                          alt={`${getUserFullName(empProfile)}`}
                          src={empProfile.imageProfile}
                          sx={{ width: "7rem", height: "7rem" }}
                        />
                      </Box>
                      <Box>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                              variant="body1"
                              align="center"
                            >{`${getUserFullName(empProfile)}`}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              align="center"
                              sx={{ color: "#6e6e6e" }}
                            >
                              {getUserProfile(empProfile)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <div style={{ marginTop: "40px" }}>
                  <div>
                    <Grid container>
                      <Grid item xl={8} md={12} sm={12} xs={12}>
                        <Typography gutterBottom className={classes.career}>
                          {t("recommendedJobPositionByManager")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      className={classes.gridContainerStrengthWeakness}
                    >
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={12}
                        sm={6}
                        xs={12}
                        className={`${classes.gridStrengthWeakness} ${classes.gridStrength}`}
                      >
                        <Typography
                          className={"Strength"}
                          style={{ color: "#4caf50" }}
                          gutterBottom
                        >
                          {t("Strength")} :
                        </Typography>
                        {empProfile && empProfile.managerStrength1 ? (
                          <>
                            <Typography className={classes.jobFam}>
                              - {empProfile.managerStrength1}
                            </Typography>
                            <Typography className={classes.jobFam}>
                              - {empProfile.managerStrength2}
                            </Typography>
                            <Typography className={classes.jobFam}>
                              - {empProfile.managerStrength3}
                            </Typography>
                          </>
                        ) : (
                          <Typography variant="body1" sx={{ color: "#9e9e9e" }}>
                            {t("NoData")}
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={12}
                        sm={6}
                        xs={12}
                        className={classes.gridStrengthWeakness}
                      >
                        <Typography
                          className={"Weakness"}
                          style={{ color: "#f44336" }}
                          gutterBottom
                        >
                          {t("Weakness")} :
                        </Typography>
                        {empProfile && empProfile.managerWeakness1 ? (
                          <>
                            <Typography className={classes.jobFam}>
                              - {empProfile.managerWeakness1}
                            </Typography>
                            <Typography className={classes.jobFam}>
                              - {empProfile.managerWeakness2}
                            </Typography>
                            <Typography className={classes.jobFam}>
                              - {empProfile.managerWeakness3}
                            </Typography>
                          </>
                        ) : (
                          <Typography variant="body1" sx={{ color: "#9e9e9e" }}>
                            {t("NoData")}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Typography
                      style={{ marginTop: 40, fontWeight: 700, marginBottom: 20 }}
                      gutterBottom
                    >
                      ตำแหน่งที่แนะนำ
                    </Typography>
                    <Grid container spacing={2}>
                      {empProfile && empProfile.managerSugJobFam1 ? (
                        <>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div style={{ marginBottom: 16 }}>
                              <CardStyle>
                                <div style={{ padding: 16 }}>
                                  <Grid container spacing={2}>
                                    <Grid item xl={6} md={6} xs={6}>
                                      <Typography className={classes.position}>
                                        {empProfile.managerSugJobPos1}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xl={6}
                                      md={6}
                                      xs={6}
                                      style={{ textAlign: "right" }}
                                    >
                                      <Chip
                                        label={empProfile.managerSugJobFam1}
                                        className={classes.chipJobFamily}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Typography>{empProfile.managerSugReason1}</Typography>
                                </div>
                              </CardStyle>
                            </div>
                            <div style={{ marginBottom: 16 }}>
                              <CardStyle>
                                <div style={{ padding: 16 }}>
                                  <Grid container spacing={2}>
                                    <Grid item xl={6} md={6} xs={6}>
                                      <Typography className={classes.position}>
                                        {empProfile.managerSugJobPos2}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xl={6}
                                      md={6}
                                      xs={6}
                                      style={{ textAlign: "right" }}
                                    >
                                      <Chip
                                        label={empProfile.managerSugJobFam2}
                                        className={classes.chipJobFamily}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Typography>{empProfile.managerSugReason2}</Typography>
                                </div>
                              </CardStyle>
                            </div>
                            <div style={{ marginBottom: 16 }}>
                              <CardStyle>
                                <div style={{ padding: 16 }}>
                                  <Grid container spacing={2}>
                                    <Grid item xl={6} md={6} xs={6}>
                                      <Typography className={classes.position}>
                                        {empProfile.managerSugJobPos3}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xl={6}
                                      md={6}
                                      xs={6}
                                      style={{ textAlign: "right" }}
                                    >
                                      <Chip
                                        label={empProfile.managerSugJobFam3}
                                        className={classes.chipJobFamily}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Typography>{empProfile.managerSugReason3}</Typography>
                                </div>
                              </CardStyle>
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <Typography
                          variant="body1"
                          sx={{ color: "#9e9e9e", marginLeft: "24px" }}
                        >
                          {t("NoData")}
                        </Typography>
                      )}
                    </Grid>
                    <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
                    <Typography
                      style={{ marginTop: 20, fontWeight: 700, marginBottom: 20 }}
                      gutterBottom
                    >
                      {t("learnAdditionalSkills")}
                    </Typography>
                    <Grid container spacing={2}>
                      {empProfile && empProfile.managerMoreDev1 ? (
                        <>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div style={{ marginBottom: 16 }}>
                              <CardStyle>
                                <div style={{ padding: 16 }}>
                                  <Grid container spacing={2}>
                                    <Grid item xl={6} md={6} xs={6}>
                                      <Typography className={classes.position}>
                                        {empProfile.managerMoreDev1}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </div>
                              </CardStyle>
                            </div>
                            <div style={{ marginBottom: 16 }}>
                              <CardStyle>
                                <div style={{ padding: 16 }}>
                                  <Grid container spacing={2}>
                                    <Grid item xl={6} md={6} xs={6}>
                                      <Typography className={classes.position}>
                                        {empProfile.managerMoreDev2}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </div>
                              </CardStyle>
                            </div>
                            <div style={{ marginBottom: 16 }}>
                              <CardStyle>
                                <div style={{ padding: 16 }}>
                                  <Grid container spacing={2}>
                                    <Grid item xl={6} md={6} xs={6}>
                                      <Typography className={classes.position}>
                                        {empProfile.managerMoreDev3}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </div>
                              </CardStyle>
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <Typography
                          variant="body1"
                          sx={{ color: "#9e9e9e", marginLeft: "24px" }}
                        >
                          {t("NoData")}
                        </Typography>
                      )}
                    </Grid>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 40,
                      }}
                    >
                      <Typography>{t("Readiness")} : </Typography>
                      <Typography className={classes.jobFam}>
                        {empProfile && empProfile.ready ? (
                          <Typography variant="body1" sx={{ fontWeight: 600 }}>
                            {empProfile.ready === "Suddenly"
                              ? t("Immediately")
                              : empProfile.ready === "oneYear"
                                ? `1 ${t("Year")}`
                                : empProfile.ready === "twoYear"
                                  ? `2 ${t("Year")}`
                                  : empProfile.ready === "threeYear"
                                    ? `3 ${t("Year")}`
                                    : t("NotReady")}
                          </Typography>
                        ) : (
                          <Typography variant="body1" sx={{ color: "#9e9e9e" }}>
                            {t("NoData")}
                          </Typography>
                        )}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </CardStyle>
          ) : (
            <Box sx={{ mt: 4 }}>
              {currentUser.roles[0] === "ROLE_MANAGER" ? (
                <CardStyle>
                  <Grid
                    item
                    xl={4}
                    md={6}
                    xs={6}
                    style={{ textAlign: "right" }}
                    sx={{ p: 2 }}
                  >
                    <Link
                      to={{
                        pathname: `/${currentUser.roles[0] === "ROLE_MANAGER" ? "manager" : "admin"}/career/suggestCareer/${empProfile.idEmployees}`,
                        state: { careerPlan: empProfile },
                      }}
                      className={classes.link}
                    >
                      <ButtonBlue
                        type="submit"
                        variant="contained"
                        startIcon={<AddIcon />}
                      >
                        {t("Add")} {t("recommendation")}
                      </ButtonBlue>
                    </Link>
                  </Grid>
                </CardStyle>
              ) : null}
            </Box>
          )}
        </>
      </Container>
    </div>
  );
};

export default DataViewSuggest;
