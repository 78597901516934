import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  Container,
  Typography,
  Box,
  Tabs,
  Tab,
  Grid,
  TextField,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import TextSnippetIcon from "@mui/icons-material/TextSnippet";

import TimeAttendance from "./timeAttendance";
import Overtime from "./overtime";
import Leave from "./leave";
import Shift from "./shift";

import TextFieldTheme from "../../shared/general/TextFieldTheme";
import {
  getAllRequestTimeBy,
  getAllRequestTimeWithDrawBy,
} from "../../../../actions/requestTime";
import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../shared/general/ButtonBlue";
import {
  getLeaveRequest,
  getEmployeeChangeTime,
} from "../../../../actions/employee";
import { getAllLeaveWithDrawBy } from "../../../../actions/leave";
import { getPayrollSetting } from "../../../../actions/paytypes";
import SnackBarCustom from "../../shared/snackbarCustom";
import { useHistory, useLocation } from "react-router-dom";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../shared/date/datePicker";
import loading from "../../assets/social-media.gif";
import dayjs from "dayjs";
import { getAffiliateOrganization } from "../../../../actions/company";
import SelectEmployees from "../../shared/general/selectEmployees";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledRoot = styled("div")(({ theme }) => ({
  backgroundColor: "#FFFFFF !important",
  "& .displayFlexEnd": {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "8px 0",
    },
  },
  "& .MuiAutocomplete-root": {
    "& .MuiFilledInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      height: "56px",
      "& input": {
        padding: 0,
        borderRadius: 0,
      },
    },
  },
}));

const StyledWrapTabs = styled("div")({
  paddingBottom: 36,
  marginTop: 16,
  width: "100%",
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
  "& .MuiFilledInput-root": {
    height: 56,
    padding: "0px 12px",
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
  },
});

const HistoryApporveStatusListPage = (props) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  const today = dayjs();
  const [search, setSearch] = useState({
    start: today.set('date', 1),
    end: today.set('date', today.daysInMonth()),
  });
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const { isFetching: isFetchingRequestTime, result: requestTimeList } = useSelector((state) => state.requestTime);
  const { isFetching: isFetchingRequestTimeWithDraw, result: requestTimeWithDrawList } = useSelector(
    (state) => state.requestTimeWithDraw
  );
  const { isFetching: isFetchingleaveEmployees, result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { isFetching: isFetchingleaveEmployeeWithdraws, result: leaveEmployeeWithdrawsList } = useSelector(
    (state) => state.leaveEmployeesWithDraw
  );
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { isFetching: isFetchingEmployeeChangeShift, result: employeeChangeShiftList } = useSelector((state) => state.employeeChangeShift);
  const { result: affiliateOrganizationList, isFetching: isFetchingAffiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const mobile = useMediaQuery("(max-width:600px)");

  const [snackBarConfig, setSnackBarConfig] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const [selectedCompany, setSelectedCompany] = useState(null);

  const fetchAffiliateCompany = async () => {
    return await dispatch(getAffiliateOrganization()).then(res => {
      const foundCompany = res.data.find(item => item.idCompany === userProfile.idCompany);
      if (foundCompany) {
        setSelectedCompany(foundCompany)
        return foundCompany
      } else {
        if (res.data.length > 0) {
          setSelectedCompany(res.data[0])
          return res.data[0]
        } else {
          return null
        }
      }
    });
  }

  const fetchRequestList = (search, idCompany) => {
    setIsSubmitting(true);
    Promise.all([
      dispatch(getEmployeeChangeTime("admin", search, idCompany)),
      dispatch(getAllRequestTimeBy("admin", search, idCompany)),
      dispatch(getAllRequestTimeWithDrawBy("admin", search, idCompany)),
      dispatch(getLeaveRequest("admin", search, idCompany)),
      dispatch(getAllLeaveWithDrawBy("admin", search, idCompany)),
      dispatch(getPayrollSetting({ idCompany: idCompany })),
    ]).finally(() => {
      setIsSubmitting(false);
    })
  }

  useEffect(() => {
    if (userProfile) {
      fetchAffiliateCompany().then(company => {

        // console.log("Company", res)

        if (location.state) {

          const search = {
            start: location.state.start ? new Date(location.state.start) : null,
            end: location.state.end ? new Date(location.state.end) : null
          }

          setSearch(prev => ({
            ...prev,
            ...search,
          }))

          setValue(location.state.tab ? location.state.tab : 0);

          if (company) {
            fetchRequestList(search, company.idCompany)
            // dispatch(getEmployeeChangeTime("admin", search, selectedCompany.idCompany));
            // dispatch(getAllRequestTimeBy("admin", search, selectedCompany.idCompany));
            // dispatch(getAllRequestTimeWithDrawBy("admin", search, selectedCompany.idCompany));
            // dispatch(getLeaveRequest("admin", search, selectedCompany.idCompany));
            // dispatch(getAllLeaveWithDrawBy("admin", search, selectedCompany.idCompany));
            // dispatch(getPayrollSetting());
          }

        } else {

          if (company) {
            fetchRequestList(search, company.idCompany)
            // dispatch(getEmployeeChangeTime("admin", search, selectedCompany.idCompany));
            // dispatch(getAllRequestTimeBy("admin", search, selectedCompany.idCompany));
            // dispatch(getAllRequestTimeWithDrawBy("admin", search, selectedCompany.idCompany));
            // dispatch(getLeaveRequest("admin", search, selectedCompany.idCompany));
            // dispatch(getAllLeaveWithDrawBy("admin", search, selectedCompany.idCompany));
            // dispatch(getPayrollSetting());
          }
        }
      })
    }
  }, [userProfile]);

  const handleChange = (event, newValue) => {
    if (mobile) {
      setValue(event.target.value);
    } else {
      setValue(newValue);
    }
  };

  const handleChangeSelectedCompany = (_, value) => {
    setSelectedCompany(value)
  }

  const handleClickSearch = async () => {
    if (selectedCompany) {
      fetchRequestList(search, selectedCompany.idCompany)
    }
  };

  const handleSelectEmployee = (employee) => {
    setSelectedEmployee(employee);
  };

  return (
    <StyledRoot className={`page`}>
      <SnackBarCustom config={snackBarConfig} setConfig={setSnackBarConfig} />

      <Container maxWidth="lg" style={{ paddingBottom: 40 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4" gutterBottom style={{ paddingTop: 8 }}>
              {t("RequestListHistory")}
            </Typography>
          </Grid>
          <Grid item className="displayFlexEnd">
            <ButtonBlue
              variant="contained"
              style={{ height: "fit-content" }}
              startIcon={<TextSnippetIcon />}
              onClick={() => history.push("/admin/approve-list")}
            >
              {t("RequestList")}
            </ButtonBlue>
          </Grid>
        </Grid>
        <Box marginTop="24px">
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                options={affiliateOrganizationList}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    InputProps={{ ...params.InputProps, endAdornment: null }}
                    variant="filled"
                    placeholder={isFetchingAffiliateOrganizationList ? t("LoadingData") : t("SelectCompany")}
                  />
                )}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.idCompany}>
                    <Box>
                      <Typography color="text.secondary" fontSize="14px" fontWeight="500">{option.companyNameShort}</Typography>
                      <Typography>{i18n.language === "th" ? `${option.companyName}` : `${option.companyName_EN}`}</Typography>
                    </Box>
                  </MenuItem>
                )}
                value={selectedCompany}
                onChange={handleChangeSelectedCompany}
                getOptionLabel={(option) => i18n.language === "th" ? `${option.companyName}` : `${option.companyName_EN}`}
                isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                noOptionsText={t("NoData")}
                disableClearable
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("StartDate")}
                </Typography>
                <div className="search-date">
                  <DatePickerCustom
                    views={["year", "month", "day"]}
                    inputFormat="DD MMMM YYYY"
                    openTo="day"
                    value={search.start}
                    onChange={(newValue) => {
                      setSearch(prev => ({
                        ...prev,
                        start: newValue
                      }))
                    }}
                    componentsProps={{
                      actionBar: {
                        actions: ['clear'],
                      },
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        type="search"
                        inputProps={{
                          ...params.inputProps,
                          placeholder: t("SelectDate"),
                          readOnly: true,
                        }}
                        variant="filled"
                      />
                    )}
                    PaperProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "#46cbe2!important",
                        },
                      },
                    }}
                    DialogProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "#46cbe2!important",
                        },
                      },
                    }}
                  />
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("EndDate")}
                </Typography>
                <div className="search-date">
                  <DatePickerCustom
                    views={["year", "month", "day"]}
                    inputFormat="DD MMMM YYYY"
                    openTo="day"
                    value={search.end}
                    onChange={(newValue) => {
                      setSearch(prev => ({
                        ...prev,
                        end: newValue
                      }))
                    }}
                    componentsProps={{
                      actionBar: {
                        actions: ['clear'],
                      },
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        type="search"
                        inputProps={{
                          ...params.inputProps,
                          placeholder: t("SelectDate"),
                          readOnly: true,
                        }}
                        variant="filled"
                      />
                    )}
                    PaperProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "#46cbe2!important",
                        },
                      },
                    }}
                    DialogProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "#46cbe2!important",
                        },
                      },
                    }}
                  />
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={3}>
              <SelectEmployees
                value={selectedEmployee}
                handleChange={handleSelectEmployee}
                position={userProfile && userProfile.idCompany === 3}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <div style={{ paddingBottom: 12 }}>
                <ButtonBlue variant={"contained"} onClick={handleClickSearch} disabled={isSubmitting}>
                  {t("Search")}
                </ButtonBlue>
              </div>
            </Grid>
          </Grid>
        </Box>
        <StyledWrapTabs>
          <Box style={{ marginBottom: 16 }}>
            {mobile ? (
              <TextFieldTheme value={value} onChange={handleChange} select>
                <MenuItem value={0}>{t("WorkingTimeRequest")}</MenuItem>
                <MenuItem value={1}>{t("Overtime")}</MenuItem>
                <MenuItem value={2}>{t("LeaveRequest")}</MenuItem>
                <MenuItem value={3}>{t("ShiftRequest")}</MenuItem>
              </TextFieldTheme>
            ) : (
              <Tabs
                value={value}
                onChange={handleChange}
                sx={{
                  "& button": {
                    textTransform: "none",
                  },
                  "& .MuiTab-root.Mui-selected": {
                    color: "#46cbe2",
                  },
                }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#46cbe2",
                  },
                }}
              >
                <Tab label={`${t("WorkingTimeRequest")}`} {...a11yProps(0)} />
                <Tab label={`${t("Overtime")}`} {...a11yProps(1)} />
                <Tab label={`${t("LeaveRequest")}`} {...a11yProps(2)} />
                <Tab label={`${t("ShiftRequest")}`} {...a11yProps(3)} />
              </Tabs>
            )}
          </Box>

          {userProfile && (
            <Fragment>
              {(
                [0, 1].includes(value) ? (isFetchingRequestTime || isFetchingRequestTimeWithDraw) :
                  value === 2 ? (isFetchingleaveEmployees || isFetchingleaveEmployeeWithdraws) :
                    (value === 3 && isFetchingEmployeeChangeShift)
              ) &&
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "40px"
                  }}
                >
                  <img width="120" alt="loading" src={loading} />
                </Box>
              }
              {requestTimeList && requestTimeWithDrawList && (
                <TabPanel value={value} index={0}>
                  <TimeAttendance selectedEmployee={selectedEmployee} />
                </TabPanel>
              )}
              {requestTimeList && requestTimeWithDrawList && payrollSetting && (
                <TabPanel value={value} index={1}>
                  <Overtime selectedEmployee={selectedEmployee} />
                </TabPanel>
              )}
              {leaveEmployeesList && leaveEmployeeWithdrawsList && (
                <TabPanel value={value} index={2}>
                  <Leave selectedEmployee={selectedEmployee} />
                </TabPanel>
              )}
              {employeeChangeShiftList && (
                <TabPanel value={value} index={3}>
                  <Shift selectedEmployee={selectedEmployee} />
                </TabPanel>
              )}
            </Fragment>
          )}
        </StyledWrapTabs>
      </Container>
    </StyledRoot>
  );
};

export default HistoryApporveStatusListPage;
