import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ExcelJS from 'exceljs';
import { getUserCompany, getUserDepartment, getUserDivision, getUserFirstName, getUserLastName, getUserPosition, getUserSection } from '../../../../utils/userData';

export const WarningLetterXlsxFile = async (t,data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("Asset");

    const headerRow = worksheet1.addRow([
        t("EmployeeID"),
        t("FirstName"),
        t("LastName"),
        t("Position"),
        t("Company"),
        t("Division"),
        t("Department"),
        t("Section"),
        t("WarningLetterName"),
        t("WarningLevel"),
        t("DayMonthYear(AD)"),
        t("Reasons")
    ]);    

    const headerStyle = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    headerRow.height = 50;

    const colWidths = [
        { key: "employeeID", width: 30 },
        { key: "firstname_TH", width: 50 },
        { key: "lastname_TH", width: 50 },
        { key: "positionName", width: 30 },
        { key: "companyName", width: 50 },
        { key: "division", width: 30 },
        { key: "departmentName", width: 30 },
        { key: "sectionName", width: 30 },
        { key: "title", width: 60 },
        { key: "level", width: 30 },
        { key: "createDate", width: 40 },
        { key: "reason", width: 80 },
    ];

    colWidths.forEach((col, index) => {
        worksheet1.getColumn(index + 1).width = col.width;
    });

    data.forEach((item) => {
        const createDate = dayjs(item.createDate).locale('th').format("DD MMMM YYYY") || "";
        const row = [
            item.employeeID ? item.employeeID : "-",
            item.firstname_TH ? getUserFirstName(item) : "-",
            item.lastname_TH ? getUserLastName(item) : "-",
            item.positionName ? getUserPosition(item) : "-",
            item.companyName ? getUserCompany(item) : "-",
            item.divisionName ? getUserDivision(item) : "-",
            item.departmentName ? getUserDepartment(item) : "-",
            item.sectionName ? getUserSection(item) : "-",
            item.title ? item.title : "-",
            item.level ? item.level : "-",
            createDate,
            "-"
        ];

        const excelRow = worksheet1.addRow(row);
        const contentStyle = { font: { size: 18, name: 'TH SarabunPSK' } };

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${data && getUserCompany(data[0])}-${t("WarningLetter")}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });

}