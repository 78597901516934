import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Grid, styled, Typography, Box } from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import AlertResponse from "../../../../shared/general/AlertResponse";
import DialogEdit from "./DialogEdit";
import DialogConfirmDelete from "../../../../shared/general/DialogConfirmDelete";
import utils from "../../../../../../utils";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import {
  deleteAdditionPersonalEmployees,
  getEmployeeAdditionPersonal,
} from "../../../../../../actions/additionSpecial";

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "&.inner": {
    marginBottom: 0,
  },
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StylePriceDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

const StyledBoxList = styled(Box)({
  width: "100%",
  padding: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& p": {
    fontStyle: "oblique",
  },
});

const AdditionPersonal = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: employeeAdditionPersonal } = useSelector(
    (state) => state.employeeAdditionPersonal
  );

  const [mode, setMode] = useState("");
  const [disabledBtnDelete, setDisabledBtnDelete] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(null);

  useEffect(() => {
    dispatch(
      getEmployeeAdditionPersonal({ idEmployees: employeeProfile.idEmployees })
    );
  }, []);

  const handleClick = (mode) => {
    setOpenDialog(true);
    setMode(mode);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenDelete(false);
  };

  const handleClickDelete = (mode) => {
    setOpenDelete(true);
    setMode(mode);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleSetAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleSubmit = async () => {
    setDisabledBtnDelete(true);

    const result = await dispatch(
      deleteAdditionPersonalEmployees({
        idAdditionPersonal: dataEdit.idAdditionPersonal,
        idEmployees: employeeProfile.idEmployees,
      })
    );
    if (result && result.status === 200) {
      dispatch(
        getEmployeeAdditionPersonal({
          idEmployees: employeeProfile.idEmployees,
        })
      );
    }
    handleCloseDialog();
  };

  return (
    <div>
      <div>
        <StyledWrapTop>
          <StyledHeadLabel variant="subtitle1">
            {t("AdditionPersonal")}
          </StyledHeadLabel>

          {!userProfile.readOnly && (
            <ButtonBlue
              variant="outlined"
              size="small"
              startIcon={<AddRoundedIcon />}
              onClick={() => {
                setDataEdit(null);
                handleClick("add");
              }}
            >
              {t("Add")}
            </ButtonBlue>
          )}
        </StyledWrapTop>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <StyledHeadLabel variant="body2" color="text.secondary">
              {t("AdditionalName")}
            </StyledHeadLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledHeadLabel variant="body2" color="text.secondary">
              {t("Quantity")}
            </StyledHeadLabel>
          </Grid>
          {employeeAdditionPersonal && employeeAdditionPersonal.length > 0 ? (
            employeeAdditionPersonal.map((addition) => (
              <Fragment key={addition.title}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6">{addition.name}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StylePriceDiv>
                    <Typography
                      variant="h6"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {`${utils.numberWithCommas(addition.amount)} ${t(
                        "Baht"
                      )}`}
                    </Typography>
                    <div>
                      <IconButton aria-label="edit" size="small">
                        <EditRoundedIcon
                          fontSize="inherit"
                          onClick={() => {
                            setDataEdit(addition);
                            handleClick("edit");
                          }}
                        />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          setDataEdit(addition);
                          handleClickDelete("addition");
                        }}
                      >
                        <DeleteOutlineRoundedIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                  </StylePriceDiv>
                </Grid>
              </Fragment>
            ))
          ) : (
            <StyledBoxList>
              <Typography color="text.secondary">{t("NoData")}</Typography>
            </StyledBoxList>
          )}
        </Grid>
      </div>

      {openDialog && employeeAdditionPersonal && (
        <DialogEdit
          mode={mode}
          open={openDialog}
          dataEdit={dataEdit}
          handleCloseDialog={handleCloseDialog}
          handleOpenAlert={handleOpenAlert}
          handleSetAlertType={handleSetAlertType}
          isReadOnly={userProfile && userProfile.readOnly}
        />
      )}

      {dataEdit && (
        <DialogConfirmDelete
          open={openDelete}
          handleClose={handleCloseDialog}
          label={t("AdditionPersonal")}
          text={`${dataEdit.name}: ${utils.numberWithCommas(
            dataEdit.amount
          )} ${t("Baht")}`}
          handleDelete={handleSubmit}
          disabledBtnDelete={disabledBtnDelete}
          isReadOnly={userProfile && userProfile.readOnly}
        />
      )}

      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </div>
  );
};

export default AdditionPersonal;
