import React, { useState, useEffect, Fragment } from 'react';
import { createAPIEndpoint, ENDPOINTS } from '../../../../services/api';
import Notification from '../../shared/general/Notification';

import {
  Dialog,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
  Divider,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';

import CloseIcon from '@mui/icons-material/Close';

import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";

const useStyles = makeStyles(() => ({
  root: {},
  dialog: {
    padding: 16,
    position: 'absolute',
    top: 40,
  },
  dialogTitle: {
    borderBottom: '1px solid #dee2e6',
    color: '#222f3e',
    padding: '8px 16px 8px 16px',
  },
  dialogContent: {
    color: '#798294',
    fontWeight: 600,
    paddingTop: 24,
    paddingLeft: 0,
    paddingRight: 0,
  },
  divider: {
    marginTop: 32,
    marginBottom: 16,
  },
  divider_department: {
    marginTop: 32,
    marginBottom: 32,
  },
  saveBtn: {
    backgroundColor: '#2f74eb',
    color: '#fff',
    fontWeight: 700,
    height: '40px',
    borderRadius: '25px',
    paddingLeft: 32,
    paddingRight: 32,
    '&:hover': {
      backgroundColor: '#2a68d3',
    },
  },
  moreBtn: {
    border: '2px solid #2F74EB',
    backgroundColor: '#fff',
    color: '#2F74EB',
    fontWeight: 600,
    borderRadius: '25px',
    paddingLeft: 16,
    paddingRight: 16,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 16,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: "#9e9e9e",
  },
  headTitle: {
    fontSize: '1.2rem',
    fontWeight: 600,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6' className={classes.headTitle}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 16,
  },
}))(MuiDialogContent);

export default function EditChart(props) {
  const { editChart, setEditChart, chartNode } = props;
  const classes = useStyles();
  const [values, setValues] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeeSelected, setEmployeeSelected] = useState([]);
  const [personSelected, setPersonSelected] = useState([]);
  const [parent, setParent] = useState([]);
  const [parentSelected, setParentSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [errors, setErrors] = useState({
    name: false,
    employeeName: false,
    parentId: false,
    layer: false,
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });

  // const OPTIONS_LIMIT = 5;
  // const defaultFilterOptions = createFilterOptions();

  // const filterOptions = (options, state) => {
  //   return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleEmpSelected = (e, newValue) => {
    setEmployeeSelected(newValue);
    const newValues = { ...values };
    newValues.department = newValue.department;
    newValues.position = newValue.jobPosition;
    setValues(newValues);
  };

  const handlePersonSelected = (e, newValue) => {
    setPersonSelected(newValue);
    const newValues = { ...values };
    newValues.personInCharge = newValue !== null ? newValue.fullName : '';
    setValues(newValues);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    createAPIEndpoint(ENDPOINTS.ORGCHART)
      .update(values.orgChartId, values)
      .then((res) => {
        setEditChart({
          isOpen: false,
        });
        setNotify({
          isOpen: true,
          message: 'Edit Successfully, Please Refresh Data',
          type: 'success',
        });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (editChart.isOpen) {
      //Set InitialValues
      setValues(chartNode);

      //Fetch Employees
      createAPIEndpoint(ENDPOINTS.EMPLOYEE)
        .fetchAll()
        .then((res) => {
          //Add field Full Name
          res.data.forEach(function (element) {
            element.fullName = element.firstName + ' ' + element.lastName;
          });

          setEmployees(res.data);

          res.data.forEach((item) => {
            if (item.fullName === chartNode.name) {
              setEmployeeSelected(item);
            }
            if (item.fullName === chartNode.personInCharge) {
              setPersonSelected(item);
            }
          });
        })
        .catch((err) => console.log(err));

      //Fetch OrgChart
      createAPIEndpoint(ENDPOINTS.ORGCHART)
        .fetchAll()
        .then((res) => {
          setParent(res.data);

          res.data.forEach((item) => {
            if (item.id === chartNode.parentId) {
              setParentSelected(item);
            }
          });
        })
        .catch((err) => console.log(err));

      setIsLoading(false);
    }
  }, [editChart]);

  return (
    <div className={classes.root}>
      <Dialog
        open={editChart.isOpen}
        maxWidth='sm'
        fullWidth
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle
          className={classes.dialogTitle}
          onClose={() => setEditChart({ ...editChart, isOpen: false })}
        >
          Edit Chart
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form onSubmit={handleOnSubmit}>
            {isLoading ? (
              <Grid container justify='center'>
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            ) : (
              <Fragment>
                {!chartNode.isDepartment ? (
                  <Fragment>
                    <Grid container alignItems='center' spacing={4}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant='body1'>Name</Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        {/* <Autocomplete
                          filterOptions={filterOptions}
                          options={employees}
                          autoHighlight
                          getOptionLabel={(option) => option.fullName || ''}
                          value={employeeSelected}
                          onChange={handleEmpSelected}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              margin='dense'
                              error={errors.employeeName}
                            />
                          )}
                        /> */}
                      </Grid>
                    </Grid>
                    <Grid container alignItems='center' spacing={4}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant='body1'>Department</Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        <TextField
                          variant='outlined'
                          size='small'
                          value={values.department}
                          className={classes.textDisabled}
                          disabled
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems='center' spacing={4}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant='body1'>Position</Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        <TextField
                          variant='outlined'
                          size='small'
                          value={values.position}
                          className={classes.textDisabled}
                          disabled
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems='center' spacing={4}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant='body1'>Org Code</Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        <TextField
                          variant='outlined'
                          size='small'
                          name='orgCode'
                          value={values.orgCode}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems='center' spacing={4}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant='body1'>Parent Of</Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        {/* <Autocomplete
                          filterOptions={filterOptions}
                          options={parent}
                          autoHighlight
                          getOptionLabel={(option) => option.name || ''}
                          value={parentSelected}
                          onChange={(e, newValue) => {
                            setParentSelected(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              margin='dense'
                              placeholder='Search Parent'
                              error={errors.parentId}
                            />
                          )}
                        /> */}
                      </Grid>
                    </Grid>
                    <Grid container alignItems='center' spacing={4}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant='body1'>Layer</Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        <FormControl variant='outlined' size='small' fullWidth>
                          <Select
                            value={values.layer}
                            error={errors.layer}
                            onChange={(e) =>
                              setValues({ ...values, layer: e.target.value })
                            }
                          >
                            <MenuItem value='CEO'>CEO</MenuItem>
                            <MenuItem value='Division'>Division</MenuItem>
                            <MenuItem value='Department'>Department</MenuItem>
                            <MenuItem value='Section'>Section</MenuItem>
                            <MenuItem value='Foreman'>Foreman</MenuItem>
                            <MenuItem value='NoLayer'>No Layer</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Divider className={classes.divider_department} />
                    <Grid container alignItems='center' spacing={4}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant='body1'>
                          Person In Charge
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        {/* <Autocomplete
                          filterOptions={filterOptions}
                          options={employees}
                          autoHighlight
                          getOptionLabel={(option) => option.fullName || ''}
                          value={personSelected}
                          onChange={handlePersonSelected}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              margin='dense'
                            />
                          )}
                        /> */}
                      </Grid>
                    </Grid>
                    <Grid container alignItems='center' spacing={4}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant='body1'>Cost Center</Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        <TextField
                          variant='outlined'
                          size='small'
                          name='costCenter'
                          value={values.costCenter}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems='center' spacing={4}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant='body1'>Cost Element</Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        <TextField
                          variant='outlined'
                          size='small'
                          name='costElement'
                          value={values.costElement}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems='center' spacing={4}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant='body1'>Job Group</Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        <TextField
                          variant='outlined'
                          size='small'
                          name='jobGroup'
                          value={values.jobGroup}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems='center' spacing={4}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant='body1'>Sub Job Group</Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        <TextField
                          variant='outlined'
                          size='small'
                          name='subJobGroup'
                          value={values.subJobGroup}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Grid container alignItems='center' spacing={4}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant='body1'>Name</Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        <TextField
                          variant='outlined'
                          size='small'
                          name='name'
                          value={values.name}
                          onChange={handleInputChange}
                          error={errors.name}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems='center' spacing={4}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant='body1'>Org Code</Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        <TextField
                          variant='outlined'
                          size='small'
                          name='orgCode'
                          value={values.orgCode}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems='center' spacing={4}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant='body1'>Parent Of</Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        {/* <Autocomplete
                          filterOptions={filterOptions}
                          options={parent}
                          autoHighlight
                          getOptionLabel={(option) => option.name || ''}
                          value={parentSelected}
                          onChange={(e, newValue) => {
                            setParentSelected(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              margin='dense'
                              error={errors.parentId}
                            />
                          )}
                        /> */}
                      </Grid>
                    </Grid>
                    <Grid container alignItems='center' spacing={4}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant='body1'>Layer</Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        <FormControl variant='outlined' size='small' fullWidth>
                          <Select
                            value={values.layer}
                            error={errors.layer}
                            onChange={(e) =>
                              setValues({ ...values, layer: e.target.value })
                            }
                          >
                            <MenuItem value='CEO'>CEO</MenuItem>
                            <MenuItem value='Division'>Division</MenuItem>
                            <MenuItem value='Department'>Department</MenuItem>
                            <MenuItem value='Section'>Section</MenuItem>
                            <MenuItem value='Foreman'>Foreman</MenuItem>
                            <MenuItem value='NoLayer'>No Layer</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Divider className={classes.divider_department} />
                    <Grid container alignItems='center' spacing={4}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant='body1'>
                          Person In Charge
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        {/* <Autocomplete
                          filterOptions={filterOptions}
                          options={employees}
                          autoHighlight
                          getOptionLabel={(option) => option.fullName || ''}
                          value={personSelected}
                          onChange={handlePersonSelected}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              margin='dense'
                            />
                          )}
                        /> */}
                      </Grid>
                    </Grid>
                    <Grid container alignItems='center' spacing={4}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant='body1'>Cost Center</Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        <TextField
                          variant='outlined'
                          size='small'
                          name='costCenter'
                          value={values.costCenter}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems='center' spacing={4}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant='body1'>Cost Element</Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        <TextField
                          variant='outlined'
                          size='small'
                          name='costElement'
                          value={values.costElement}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems='center' spacing={4}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant='body1'>Job Group</Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        <TextField
                          variant='outlined'
                          size='small'
                          name='jobGroup'
                          value={values.jobGroup}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems='center' spacing={4}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant='body1'>Sub Job Group</Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        <TextField
                          variant='outlined'
                          size='small'
                          name='subJobGroup'
                          value={values.subJobGroup}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Fragment>
                )}
              </Fragment>
            )}
            <Divider className={classes.divider} />
            <Grid container justify='flex-end'>
              <Grid item>
                <Button
                  variant='contained'
                  type='submit'
                  className={classes.saveBtn}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
