import React, { useState, useEffect } from "react";
import { useTitle } from "react-use";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { getAllJobPosting } from "../../../../actions/jobPosting";
import JobCard from "./jobCard";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "100px",
    marginBottom: "100px",
    "& .MuiSelect-select": {
      backgroundColor: "#fff",
    },
  },
  headingPage: {
    marginBottom: 16,
  },
  cardContainer: {
    marginTop: 16,
  },
}));

const departmentOptions = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "HR",
    value: "HR",
  },
];

const locationOptions = [
  { label: "All", value: "All" },
  { label: "Rayong", value: "Rayong" },
  { label: "Bangkok", value: "Bangkok" },
];

function Index() {
  const classes = useStyles();
  useTitle("Job Posting - HR CORE DATA");
  const dispatch = useDispatch();
  const { result: jobStore } = useSelector((state) => state.jobPosting);
  const [department, setDepartment] = useState("All");
  const [location, setLocation] = useState("All");
  const [jobList, setJobList] = useState([]);

  const handleDepartmentChange = (event) => {
    setDepartment(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  useEffect(() => {
    if (jobStore) {
      fetchData();
    }
  }, [jobStore]);

  useEffect(() => {
    fetchData();
  }, [department, location]);

  const fetchData = () => {
    if (jobStore) {
      let jobs = [...jobStore];
      let jobFilter = jobs.filter(
        (x) =>
          x.department.includes(department === "All" ? "" : department) &&
          x.location.includes(location === "All" ? "" : location)
      );

      setJobList(jobFilter);
    }
  };

  useEffect(() => {
    dispatch(getAllJobPosting());
  }, []);

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Box className={classes.headingPage}>
          <Typography variant="h4">Job Posting</Typography>
        </Box>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item xs={12} md={2} lg={2}>
            <FormControl fullWidth>
              <InputLabel>Department</InputLabel>
              <Select
                value={department}
                label="Department"
                onChange={handleDepartmentChange}
              >
                {departmentOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <FormControl fullWidth>
              <InputLabel>Location</InputLabel>
              <Select
                value={location}
                label="Location"
                onChange={handleLocationChange}
              >
                {locationOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box className={classes.cardContainer}>
          {jobList.length > 0 ? (
            <JobCard jobList={jobList} />
          ) : (
            <Box
              sx={{ display: "flex", justifyContent: "center", marginTop: 10 }}
            >
              <Typography variant="body1">No Data</Typography>
            </Box>
          )}
        </Box>
      </Container>
    </div>
  );
}

export default Index;
