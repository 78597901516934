import React, { useEffect } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import { Box, Grid, MenuItem, Switch, Typography, styled } from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import {
  getEmployeeLeaveQuotaByIdEmp,
  getEmployeeProfile,
} from "../../../../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import { addFamily } from "../../../../../../actions/family";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";
import {
  addLeaveQuota,
  getLeaveAvaiable,
  updateLeaveQuota,
} from "../../../../../../actions/leave";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px",
  },
  "& .MuiSwitch-root": {
    "& .Mui-checked": {
      color: "#46cbe2",
    },
    "& .Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#46cbe2",
    },
  },
});

const DialogLeave = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { drawerConfig, onClose, leaveRoundConfig } = props;

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const validateYupSchema = yup.object({
    leaveValue: yup.string().when("isSetLeaveValue", {
      is: (isSetLeaveValue) => isSetLeaveValue,
      then: (schema) => schema.required("กรุณากรอกข้อมูล"),
    }),
    carry: yup.string().when("isSetCarry", {
      is: (isSetCarry) => isSetCarry,
      then: (schema) => schema.required("กรุณากรอกข้อมูล"),
    }),
  });

  const useHookForm = useForm({
    defaultValues: {
      leaveValue: "",
      carry: "",
      isSetLeaveValue: false,
      isSetCarry: false,
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  const [isSetLeaveValueWatch, isSetCarryWatch] = useHookForm.watch(
    ["isSetLeaveValue", "isSetCarry"],
    { isSetLeaveValue: false, isSetCarryWatch: false }
  );

  useEffect(() => {
    if (drawerConfig.isOpen === true) {
      useHookForm.reset({
        leaveValue:
          drawerConfig.data.leaveValue !== null
            ? drawerConfig.data.leaveValue
            : "",
        carry: drawerConfig.data.carry !== null ? drawerConfig.data.carry : "",
        isSetLeaveValue: drawerConfig.data.leaveValue === null ? false : true,
        isSetCarry: drawerConfig.data.carry === null ? false : true,
      });
    }
  }, [drawerConfig.isOpen]);

  const onSubmit = async (data) => {
    let result = null;
    const formData = {};

    formData.leaveValue = data.isSetLeaveValue ? data.leaveValue : null;
    formData.carry = data.isSetCarry ? data.carry : null;

    if (drawerConfig.data.idLeaveQuota) {
      result = await dispatch(
        updateLeaveQuota(drawerConfig.data.idLeaveQuota, formData)
      );
    } else {
      formData.idLeaveType = drawerConfig.data.idLeaveType;
      formData.idEmployees = employeeProfile.idEmployees;
      formData.year = dayjs(leaveRoundConfig.date).format("YYYY");
      formData.fromYear = dayjs(leaveRoundConfig.date).add(-1, "year").format("YYYY");
      formData.start = dayjs(leaveRoundConfig.start).format("YYYY-MM-DD");
      formData.end = dayjs(leaveRoundConfig.end).format("YYYY-MM-DD");
      formData.idLeaveGroup = drawerConfig.data.idLeaveGroup;
      result = await dispatch(addLeaveQuota(formData));
    }

    if (result) {
      if (result.status === 200) {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "success",
        //   message: "บันทึกข้อมูลสำเร็จ"
        // }))
        dispatch(getEmployeeLeaveQuotaByIdEmp(employeeProfile.idEmployees));
        dispatch(getLeaveAvaiable({date: leaveRoundConfig.date, idEmployees: employeeProfile.idEmployees}))
        onClose();
      } else {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "error",
        //   message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
        // }))
      }
    } else {
      // setAlertConfig(prev => ({
      //   ...prev,
      //   isOpen: true,
      //   type: "error",
      //   message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
      // }))
    }
  };

  return (
    <DrawerCustom
      title={
        drawerConfig.isEdit
          ? `${t("EditFamilyMember")}`
          : `${t("EditLeaveRights")}`
      }
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontSize="20px">
                {drawerConfig.data.leaveGroupName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom="4px"
              >
                <Typography fontSize="14px" fontWeight="500">
                  {t("EditLeaveRights")}
                </Typography>
                <Controller
                  name="isSetLeaveValue"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <Switch {...field} checked={field.value} />
                  )}
                />
              </Box>
              {isSetLeaveValueWatch && (
                <Controller
                  name="leaveValue"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      InputProps={{
                        inputComponent: NumberFormatTheme,
                      }}
                      inputProps={{
                        allowNegative: false,
                        onValueChange: (values) => {
                          const { value } = values;
                          field.onChange(value);
                        },
                        suffix: " วัน",
                        decimalScale: 2,
                        value: field.value,
                        placeholder: "วัน",
                      }}
                      onChange={() => {}}
                      error={
                        useHookForm.formState.errors[field.name] ? true : false
                      }
                      helperText={
                        useHookForm.formState.errors[field.name]
                          ? useHookForm.formState.errors[field.name].message
                          : null
                      }
                    />
                  )}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom="4px"
              >
                <Typography fontSize="14px" fontWeight="500">
                  {t("EditAccumulatedRights")}
                </Typography>
                <Controller
                  name="isSetCarry"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <Switch {...field} checked={field.value} />
                  )}
                />
              </Box>
              {isSetCarryWatch && (
                <Controller
                  name="carry"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      InputProps={{
                        inputComponent: NumberFormatTheme,
                      }}
                      inputProps={{
                        allowNegative: false,
                        onValueChange: (values) => {
                          const { value } = values;
                          field.onChange(value);
                        },
                        suffix: " วัน",
                        decimalScale: 2,
                        value: field.value,
                        placeholder: "วัน",
                      }}
                      onChange={() => {}}
                      error={
                        useHookForm.formState.errors[field.name] ? true : false
                      }
                      helperText={
                        useHookForm.formState.errors[field.name]
                          ? useHookForm.formState.errors[field.name].message
                          : null
                      }
                    />
                  )}
                />
              )}
            </Grid>

            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue
                variant="text"
                onClick={onClose}
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogLeave;
