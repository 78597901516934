import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Typography, Container, Fab } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import AddIcon from "@mui/icons-material/Add";

import MenuApp from "./menu-app";

import Backdrop from "../../shared/general/Backdrop";

import { getUserProfile, getAllUsers } from "../../../../actions/user";
import { getUserFullName } from "../../../../utils/userData";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledWrapHeader = styled("div")({
  paddingTop: 20,
  paddingBottom: 60,
});

const HomePageAdminWelfare = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserProfile(currentUser.username));
    }
  }, []);

  return (
    <div>
      {
        <StyledRoot className={`page`}>
          <Backdrop open={!(currentUser && userProfile)} />
          <Container maxWidth="lg">
            {userProfile && (
              <StyledWrapHeader>
                <Typography variant="h4">
                  {`${t("Hello")}, ${getUserFullName(userProfile)}`}
                </Typography>
              </StyledWrapHeader>
            )}
            <MenuApp />
          </Container>
        </StyledRoot>
      }
    </div>
  );
};

export default HomePageAdminWelfare;
