import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import TimeTable from "./timeTable";
import { getAllPositions } from "../../../../../../actions/positions";
import { useDispatch, useSelector } from "react-redux";
import { getEmploymentTypeByidCompany } from "../../../../../../actions/employment-type";
import { getUserPosition } from "../../../../../../utils/userData";
import { getLevelByidCompany } from "../../../../../../actions/level";
import { useTranslation } from "react-i18next";
import {
  addAdditionSpecial2,
  getAdditionSpecial2,
} from "../../../../../../actions/additionSpecial";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";

const AS2Form = ({ handleClose, selectedCompany, selectAddition }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: employmentType } = useSelector((state) => state.employmentType);
  const { result: positions } = useSelector((state) => state.positions);
  const { result: level } = useSelector((state) => state.level);

  const [checked, setChecked] = useState({
    employmentTypes: false,
    positions: false,
    groupLevels: false,
  });

  const validateSchema = yup.object({
    name: yup.string().max(500).required(t("ThisFieldIsRequired")),
    times: yup.array().of(
      yup.object({
        starTime: yup.date().nullable(),
        minutes: yup
          .number()
          .transform((val, orig) => (orig === "" ? undefined : val))
          .min(1, `${t("Minimum")} 1`)
          .max(1000, `${t("Maximum")} 1000`)
          .required(t("ThisFieldIsRequired")),
        amount: yup
          .number()
          .transform((val, orig) => (orig === "" ? undefined : val))
          .min(1, `${t("Minimum")} 1`)
          .max(1000000, `${t("Maximum")} 1000000`)
          .required(t("ThisFieldIsRequired")),
      })
    ),
    groupLevels: yup.array(),
    positions: yup.array(),
    employmentTypes: yup.array(),
  });

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      name: "",
      times: [
        {
          starTime: null,
          minutes: "",
          amount: "",
        },
      ],
      groupLevels: [],
      positions: [],
      employmentTypes: [],
    },
    resolver: yupResolver(validateSchema),
  });

  const handleChange = (event) => {
    setChecked((prev) => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));
    setValue(event.target.name, [])
  };

  const onSubmit = async (data) => {
    data.idCompany = selectedCompany.idCompany;
    data.groupLevels = data.groupLevels.map((e) => e.idGroupLevel);
    data.positions = data.positions.map((e) => e.idPosition);
    data.employmentTypes = data.employmentTypes.map((e) => e.idEmploymentType);

    const res = await dispatch(addAdditionSpecial2(data));
    if (res.status === 201) {
      dispatch(openNotificationAlert({ message: "success", type: "success" }));
      dispatch(getAdditionSpecial2(selectedCompany.idCompany));
    } else {
      dispatch(openNotificationAlert({ message: "error", type: "error" }));
    }
    handleClose();
  };

  useEffect(() => {
    dispatch(getEmploymentTypeByidCompany({ idCompany: selectedCompany.idCompany }));
    dispatch(getLevelByidCompany({ idCompany: selectedCompany.idCompany }));
    dispatch(getAllPositions({ idCompany: selectedCompany.idCompany }));
  }, [selectedCompany.idCompany]);

  useEffect(() => {
    if (selectAddition && selectAddition.idAdditionSpecial2) {
      reset({
        idAdditionSpecial2: selectAddition.idAdditionSpecial2,
        name: selectAddition.name,
        employmentTypes: selectAddition.employmentTypes,
        positions: selectAddition.positions,
        groupLevels: selectAddition.groupLevels,
        times: selectAddition.times,
      });
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography paddingBottom={"9px"} paddingTop={"9px"}>{t("Name")}</Typography>
          <Controller
            name={"name"}
            control={control}
            render={({ field, fieldState }) => (
              <TextFieldTheme
                {...field}
                helperText={fieldState.error && fieldState.error.message}
                error={Boolean(fieldState.error)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <Typography>{t("EmployeeType")}</Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="employmentTypes"
                    checked={checked.employmentTypes}
                    onChange={handleChange}
                  />
                } 
                label={t("SelectAll")}
              />
            </FormGroup>
          </div>
          {employmentType && (
            <Controller
              name={"employmentTypes"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  multiple
                  filterOptions={(options, params) => {
                    const filter = createFilterOptions();
                    const filtered = filter(options, params);
                    return filtered;
                  }}
                  onChange={(event, newValue) => field.onChange(newValue)}
                  // onChange={(event, newValue) => {
                  //   if (newValue.find((option) => option.all)) {
                  //     return field.onChange(
                  //       field.value.length === employmentType.length
                  //         ? []
                  //         : employmentType
                  //     );
                  //   } else {
                  //     return field.onChange(newValue);
                  //   }
                  // }}
                  disabled={checked.employmentTypes}
                  isOptionEqualToValue={(option, value) =>
                    option.idEmploymentType === value.idEmploymentType
                  }
                  options={employmentType}
                  getOptionLabel={(option) => option.employmentTypeName}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error && fieldState.error.message}
                    />
                  )}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <Typography>{t("GroupLevel")}</Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="groupLevels"
                    checked={checked.groupLevels}
                    onChange={handleChange}
                  />
                } 
                label={t("SelectAll")}
              />
            </FormGroup>
          </div>
          {level && (
            <Controller
              name={"groupLevels"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  multiple
                  filterOptions={(options, params) => {
                    const filter = createFilterOptions();
                    const filtered = filter(options, params);
                    return filtered;
                  }}
                  onChange={(event, newValue) => field.onChange(newValue)}
                  // onChange={(event, newValue) => {
                  //   if (newValue.find((option) => option.all)) {
                  //     return field.onChange(
                  //       field.value.length === level.length ? [] : level
                  //     );
                  //   } else {
                  //     return field.onChange(newValue);
                  //   }
                  // }}
                  disabled={checked.groupLevels}
                  isOptionEqualToValue={(option, value) =>
                    option.idGroupLevel === value.idGroupLevel
                  }
                  options={level}
                  getOptionLabel={(option) => option.groupLevelName}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error && fieldState.error.message}
                    />
                  )}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <Typography>{t("Position")}</Typography>
            <FormGroup>
              <FormControlLabel 
                control={
                  <Checkbox
                    name="positions"
                    checked={checked.positions}
                    onChange={handleChange}
                  />
                } 
                label={t("SelectAll")}
              />
            </FormGroup>
          </div>
          {positions && (
            <Controller
              name={"positions"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  multiple
                  filterOptions={(options, params) => {
                    const filter = createFilterOptions();
                    const filtered = filter(options, params);
                    return filtered;
                  }}
                  onChange={(event, newValue) => field.onChange(newValue)}
                  // onChange={(event, newValue) => {
                  //   if (newValue.find((option) => option.all)) {
                  //     return field.onChange(
                  //       field.value.length === positions.length ? [] : positions
                  //     );
                  //   } else {
                  //     return field.onChange(newValue);
                  //   }
                  // }}
                  disabled={checked.positions}
                  isOptionEqualToValue={(option, value) =>
                    option.idPosition === value.idPosition
                  }
                  options={positions}
                  getOptionLabel={(option) =>
                    getUserPosition({
                      positionName: option.positionName,
                      positionName_EN: option.positionName_EN,
                    })
                  }
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error && fieldState.error.message}
                    />
                  )}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <TimeTable control={control} name={"times"} />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"flex-end"}
          gap={"8px"}
        >
          <Button color="error" onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <ButtonBlue type="submit" variant="contained">
            {t("Confirm")}
          </ButtonBlue>
        </Grid>
      </Grid>
    </form>
  );
};

export default AS2Form;
