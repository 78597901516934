import React, { useEffect, useState } from "react";
import { Avatar, Box, Chip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import AlertResponse from "../../shared/general/AlertResponse";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TableCustom from "../../shared/tableCustom";
import DialogProbation from "./DialogProbation";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName, getUserPosition } from "../../../../utils/userData";
import { getProbationQuestion } from "../../../../actions/probation";
import { getProbationEmployees } from "../../../../actions/employee";

const RequestProbation = ({ setNumberOfList, searchDate }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: employeesProbation } = useSelector(
    (state) => state.employeesProbation
  );

  const [pendingRows, setPendingRows] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [openEducation, setOpenEducation] = useState(false);
  const [idEducation, setIdEducation] = useState(null);
  const [probation, setProbation] = useState(null);
  const [typeDialog, setTypeDialog] = useState("Add");

  const columns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>{getUserFullName(row)}</Typography>
            <Typography color="text.third" fontSize="14px">
              {getUserPosition(row)}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("HiringDate")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.hiringDate).format(
              i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
            )}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("ProbationaryExpirationDate")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Typography textAlign={"center"}>
          {dayjs(row.probationDate).format(
            i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
          )}
        </Typography>
      ),
    },
    {
      name: `${t("ProbationRound")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          {row.probationRound === 1 ? (
            <Chip color="primary" label={`${t("RoundNo")} 1`} />
          ) : (
            <Chip color="secondary" label={`${t("RoundNo")} 2`} />
          )}
        </Box>
      ),
    },
    {
      name: `${t("Evaluate")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              align: "center",
            }}
          >
            <ButtonBlue
              variant="contained"
              onClick={() => {
                setProbation(row);
                handleOpenEducation("Add");
              }}
            >
              {t("Evaluate")}
            </ButtonBlue>
          </div>
        </Box>
      ),
    },
  ];

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleOpenEducation = (type, idEdu) => {
    if (type === "Add") {
      setTypeDialog("Add");
    } else {
      setTypeDialog("Edit");
    }
    if (idEdu) {
      setIdEducation(idEdu);
    }
    setOpenEducation(true);
  };

  const handleClose = () => {
    setOpenEducation(false);
    setIdEducation(null);
  };

  const handleSubmitProbation = async (result) => {
    handleClose();
    if (result) {
      handleOpenAlert();
      if (result.status === 200) {
        dispatch(getProbationEmployees());
        handleChangeAlertType("success");
      } else {
        handleChangeAlertType("error");
      }
    } else {
      handleChangeAlertType("error");
    }
  };

  useEffect(() => {
    let tempPending = [...employeesProbation]
    if (searchDate.start || searchDate.end) {
      tempPending = employeesProbation.filter((item) =>
        dayjs(item.probationDate).isBetween(
          searchDate.start || dayjs(),
          searchDate.end || dayjs()
        )
      );
    }

    setPendingRows(tempPending);
    setNumberOfList(tempPending.length);
  }, [employeesProbation, searchDate]);

  useEffect(() => {
    if (employeesProbation) {
      setPendingRows(employeesProbation);
    }
  }, []);

  return (
    <>
      <TableCustom columns={columns} rows={pendingRows ? pendingRows : []} />
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      {openEducation && (
        <DialogProbation
          open={openEducation}
          handleClose={handleClose}
          idEdu={idEducation}
          type={typeDialog}
          probation={probation}
          handleSubmitProbation={handleSubmitProbation}
        />
      )}
    </>
  );
};

export default RequestProbation;
