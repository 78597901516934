import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import {
  Container,
  Grid,
  Typography,
  IconButton,
  Box,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

//icon
import DeleteIcon from "@mui/icons-material/Delete";

import { getAllJob, getJobPosition } from "../../../../../actions/job";
import SearchData from "../../../shared/searchData";

import CardStyle from "../../../shared/general/Card";
import SearchIcon from "@mui/icons-material/Search";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AddIcon from "@mui/icons-material/Add";



const useStyles = makeStyles(() => ({
  root: {
    marginTop: "20px",
    minHeight: "calc(100vh - 20px)",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  textNumber: {
    fontSize: 30,
    fontWeight: "bold",
    marginLeft: 20,
    // textAlign: 'right',
    color: "#007AFC",
  },
  mainData: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "#007AFC",
  },
  data: {
    fontSize: "1rem",
  },
  headText: {
    fontSize: "1rem",
    color: "#9e9e9e",
  },
  viewBtn: {
    color: "#bdbdbd",
    "&:hover": {
      color: "#007AFC",
    },
  },
  removeBtn: {
    color: "#bdbdbd",
    "&:hover": {
      color: "#e53935",
    },
  },
  link: {
    textDecoration: "none",
  },
}));

const ShowAllPosition = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { result: dataJob } = useSelector((state) => state.dataJob);
  const { result: dataPosition } = useSelector((state) => state.jobPosition);

  const [classLevel, setClassLevel] = useState([]);
  const [number, setNumber] = useState(0);

  const getJobByPath = (key) => {
    const splitpath = location.pathname.split("/");

    switch (key) {
      case "jobName":
        return splitpath[splitpath.length - 2];
      case "jobPosition":
        return splitpath[splitpath.length - 3];
      default:
        return splitpath[splitpath.length - 1];
    }
  };

  useEffect(() => {
    const lastpath = getJobByPath("lastpath");

    async function getData() {
      let result = await dispatch(getAllJob());
      let classLevel = ["None"];
      let number = 0;
      //เก็บข้อมูลแถวใส่ใน array เพื่อเรียกใช้
      result &&
        result.map((val) => (
          <div>
            {classLevel.push(val.JobName)}
            {val.SubJob.map((val) => val.JobPosition.map((_) => number++))}
          </div>
        ));
      setNumber(number);
      setClassLevel(classLevel);
    }

    async function getJobPositionFunc() {
      let subJob = getJobByPath("subJob");
      let jobName = getJobByPath("jobName");
      let jobPosition = getJobByPath("jobPosition");
      let result = await dispatch(getJobPosition(jobName, subJob, jobPosition));
      if (result) {
        setNumber(result.length);
      }
    }

    if (lastpath === "showAllPosition") {
      getData();
    } else {
      getJobPositionFunc();
    }

    return () => setNumber(0);
    //   dispatch(getAllJob());
  }, [getJobByPath("lastpath")]);

  const [jobGroupName, setJobGroupName] = useState("");
  const [jobPosition, setJobPosition] = useState("");

  const PaperJobPosition = ({ value, value2, value3 }) => {
    return (
      <CardStyle style={{ padding: "24px", marginBottom: "20px" }}>
        <Grid container style={{ alignItems: "center" }}>
          <Grid container spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container>
                <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                  <Typography className={classes.headText}>
                    Job Position
                  </Typography>
                  <Typography className={classes.mainData}>
                    {value3.positionName}
                  </Typography>
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                  <Typography className={classes.headText}>Job Code</Typography>
                  <Typography className={classes.data}>
                    {value3.code}
                  </Typography>
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                  <Typography className={classes.headText}>
                    Job Group
                  </Typography>
                  <Typography className={classes.data}>
                    {value.JobName}
                  </Typography>
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                  <Typography className={classes.headText}>
                    Subjob group
                  </Typography>
                  <Typography className={classes.data}>
                    {value2.subJobName}
                  </Typography>
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                  <Typography className={classes.headText}>
                    Create Date
                  </Typography>
                  <Typography className={classes.data}>
                    {value3.date}
                  </Typography>
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={6} xs={12}>
                  <Typography className={classes.headText}>จำนวนคน</Typography>
                  <Typography className={classes.data}>5</Typography>
                </Grid>
                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={6}
                  xs={12}
                  style={{ textAlign: "right" }}
                >
                  <Link
                    to={{
                      pathname: `/admin/showAllPosition/showDetail/${value.Position} ${value2.id} ${value3.positionName}`,
                      state: {
                        link: "/admin/showAllPosition",
                        prevText: "Position",
                      },
                    }}
                    style={{
                      textDecoration: "none",
                      width: "200px",
                      height: "300px",
                    }}
                  >
                    <IconButton>
                      <SearchIcon className={classes.viewBtn} />
                    </IconButton>
                  </Link>
                  <IconButton style={{ marginLeft: 5 }}>
                    <DeleteIcon className={classes.removeBtn} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardStyle>
    );
  };

  const onChangeName = (e) => {
    setJobPosition(e.target.value);
  };

  const onChangeJobGroup = (e) => {
    setJobGroupName(e.target.value);
  };

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item>
            <Typography variant="h4" gutterBottom style={{ marginTop: 20 }}>
              Position
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ marginBottom: 4, marginTop: 4 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={6} lg={6}>
              <Link
                to={{
                  pathname: `/admin/jobStructure/formJob/addNew`,
                  state: { subJobName: null },
                }}
                className={classes.link}
              >
                <ButtonBlue
                  type="submit"
                  variant="contained"
                  startIcon={<AddIcon />}
                >
                  Add Position
                </ButtonBlue>
              </Link>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              {getJobByPath("lastpath") === "showAllPosition" && (
                <SearchData
                  searchName={"Search Position"}
                  classLevel={classLevel}
                  selectName={"Select Job Group"}
                  onChangeName={onChangeName}
                  onChangeType={onChangeJobGroup}
                />
              )}
            </Grid>
          </Grid>
        </Box>
        {getJobByPath("lastpath") === "showAllPosition"
          ? dataJob && (
              <div>
                <Box sx={{ marginBottom: 1 }}>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Typography variant="h6">
                        {number} Job Position
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {dataJob
                  .filter((val) => val["JobName"].search(jobGroupName) !==-1)
                  .map((value) =>
                    value.SubJob.map((value2) =>
                      value2.JobPosition.filter(
                        (val) =>
                          val["positionName"]
                            .toLowerCase()
                            .search(jobPosition.toLowerCase()) !==-1
                      ).map((value3, index) => (
                        <PaperJobPosition
                          key={index + value3}
                          value={value}
                          value2={value2}
                          value3={value3}
                        />
                      ))
                    )
                  )}
              </div>
            )
          : dataPosition && (
              <div>
                <Box sx={{ marginBottom: 1 }}>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Typography variant="h6">
                        {number} Job Position
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {dataPosition.map((position, index) => (
                  <PaperJobPosition
                    key={index + position}
                    value={{ JobName: getJobByPath("jobName") }}
                    value2={{ subJobName: getJobByPath("subJobName") }}
                    value3={position}
                  />
                ))}
              </div>
            )}
      </Container>
    </div>
  );
};

export default ShowAllPosition;
