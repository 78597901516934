import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Container,
  FormControl,
  Grid,
  FilledInput,
  InputAdornment,
  Paper,
  Avatar,
  Divider,
  Stack,
  Button,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PositionCard } from "./positionCard";
import { getManager } from "../../../../actions/manager";
import { getOrganizationStructure } from "../../../../actions/organizationStructure";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../actions/company";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 50,
  "& .search-filter-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .filter-wrap": {},
    "& .action-container": {},
  },
});

const Successor = (props) => {
  const history = useHistory();
  const { handleChange } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [selectedCompany, setSelectedCompany] = useState(null);

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: OrganizationStructureData } = useSelector((state) => state.organizationStructure);
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
  }

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      let ownCompany = affiliateOrganizationList.find(x => x.idCompany === userProfile.idCompany);
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany);
    }
  }, [userProfile, affiliateOrganizationList])

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getManager({ idCompany: selectedCompany.idCompany }));
      dispatch(
        getOrganizationStructure({ idCompany: selectedCompany.idCompany })
      );
    }
  }, [selectedCompany]);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            Successor
          </Typography>
        </div>

        <Box marginBottom="24px">
          <SelectAffiliateCompany
            options={affiliateOrganizationList}
            value={selectedCompany}
            onChange={(_, value) => {
              onChangeCompany(value)
            }}
          />
        </Box>

        <Typography fontSize={25} fontWeight={500} sx={{ mt: 5 }}>
          MDOffice
        </Typography>

        <Grid container spacing={2} sx={{ mt: 1, mb: 3 }}>
          {OrganizationStructureData && OrganizationStructureData.ceos && OrganizationStructureData.ceos.map((item, index) =>
            <Grid item sm={4} xs={12} key={index}>
              <PositionCard
                itemName={item.name}
                data={item}
                empData={item && item.mainPosition && item.mainPosition.employee}
              />
            </Grid>
          )}
        </Grid>

        <Typography fontSize={25} fontWeight={500}>
          {t("BusinessUnit")}
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1, mb: 3 }}>
          {OrganizationStructureData && OrganizationStructureData.businessUnits && OrganizationStructureData.businessUnits.map((item, index) => (
            <Grid item sm={4} xs={12} key={index}>
              <PositionCard
                itemName={item.name}
                data={item}
              />
            </Grid>
          ))}
        </Grid>

        <Typography fontSize={25} fontWeight={500}>
          {t("Division")}
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1, mb: 3 }}>
          {OrganizationStructureData && OrganizationStructureData.divisions && OrganizationStructureData.divisions.map((item, index) => (
            <Grid item sm={4} xs={12} key={index}>
              <PositionCard
                itemName={item.name}
                data={item}
              />
            </Grid>
          ))}
        </Grid>

        <Typography fontSize={25} fontWeight={500}>
          {t("Department")}
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1, mb: 3 }}>
          {OrganizationStructureData && OrganizationStructureData.departments && OrganizationStructureData.departments.map((item, index) => (
            <Grid item sm={4} xs={12} key={index}>
              <PositionCard
                itemName={item.name}
                data={item}
              />
            </Grid>
          ))}
        </Grid>

        <Typography fontSize={25} fontWeight={500}>
          {t("Section")}
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1, mb: 3 }}>
          {OrganizationStructureData && OrganizationStructureData.sections && OrganizationStructureData.sections.map((item, index) => (
            <Grid item sm={4} xs={12} key={index}>
              <PositionCard
                itemName={item.name}
                data={item}
              />
            </Grid>
          ))}
        </Grid>

      </Container >
    </StyledRoot >
  );
};

export default Successor;

{/* <StyledWrapFilter>
          <Grid container spacing={2} columns={14} alignItems="center">
            <Grid item xs={6}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("Search")}
                </Typography>
                <FormControl fullWidth variant="filled">
                  <StyledFilledInput
                    id="filled-sarch-name"
                    placeholder={`${t("Search")}${t("FullName")}`}
                    name="search"
                    onChange={handleChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={3}>
              <StyledBoxSearchButton>
                <div>
                  <ButtonBlue variant="contained" startIcon={<SearchIcon />}>
                    ค้นหา
                  </ButtonBlue>
                </div>
              </StyledBoxSearchButton>
            </Grid>
          </Grid>
        </StyledWrapFilter> */}
