import React, { useEffect, useState, Fragment } from "react";
import {
  Box,
  Container,
  Divider,
  IconButton,
  Paper,
  Typography,
  Menu,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Pagination,
  Avatar,
  Grid,
  Checkbox,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF",
  paddingBottom: 48,
  "& .wrap": {
    marginTop: 10,
  },
  "& .wrap-gap, .wrap-target": {
    // border: "1px solid #DB4172",
    borderRadius: 16,
    "& .head": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 24,
      "& .titlehead": {},
    },
    "& .MuiPagination-ul": {
      justifyContent: "center",
      "& .Mui-selected": {
        backgroundColor: "#db4172",
        color: "#fff",
      },
    },
  },
});

const DialogueList = styled(List)({
  height: 200,
  padding: 0,
  overflow: "auto",
  "& .notFound": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
});

const StyleListItem = styled(ListItem)({
  display: "flex",
  alignItems: "center",
});

const HeaderContainer = styled(Box)({
  textAlign: "left",
  overflowWrap: "anywhere",
  fontWeight: "600",
  "& .title": {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
});

function MyIDP({ data }) {
  const [dialogueValues, setDialogueValues] = useState(null);
  const [pageGap, setPageGap] = useState(1);
  const handleChangePage = (event, newPage) => {
    setPageGap(newPage);
  };
  const [pageTarget, setPageTarget] = useState(1);
  const handleChangeTarget = (event, newPage) => {
    setPageTarget(newPage);
  };

  useEffect(() => {
    if (data) {
      setDialogueValues({
        assessment: data.assessGap,
        targetYear: data.targetYear,
      });
    }
  }, [data]);

  return (
    <StyledRoot>
      <Grid
        container
        spacing={2}
        justifyContent={"center"}
        style={{ marginTop: 5, marginBottom: 16 }}
      >
        <Grid item xs={12} sm={5}>
          <Paper variant="outlined" className="wrap-gap">
            <div className="head">
              <Typography variant="body1" className="titlehead">
                Assessment Gap
              </Typography>
            </div>
            <Divider />
            <DialogueList>
              {dialogueValues &&
              dialogueValues.assessment &&
              dialogueValues.assessment.length > 0 ? (
                dialogueValues.assessment
                  .slice((pageGap - 1) * 3, pageGap * 3)
                  .map((assess, index) => (
                    <Fragment>
                      <StyleListItem>
                        <Typography
                          variant="subtitle1"
                          style={{
                            textAlign: "center",
                            lineHeight: "50px",
                            width: "10%",
                          }}
                        >
                          {index + 1 + (pageGap - 1) * 3}
                        </Typography>
                        <HeaderContainer style={{ width: "90%" }}>
                          <Typography variant="body2" className="title">
                            {assess.description}
                          </Typography>
                        </HeaderContainer>
                      </StyleListItem>
                      <Divider />
                    </Fragment>
                  ))
              ) : (
                <Box className="notFound">
                  <Typography variant="h6">ไม่มีข้อมูล</Typography>
                </Box>
              )}
            </DialogueList>
            <div style={{ padding: 5 }}>
              {dialogueValues && dialogueValues.assessment && (
                <Pagination
                  page={pageGap}
                  count={Math.ceil(dialogueValues.assessment.length / 3)}
                  onChange={handleChangePage}
                />
              )}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Paper variant="outlined" className="wrap-target">
            <div className="head">
              <Typography variant="body1" className="titlehead">
                Target Year
              </Typography>
            </div>
            <Divider />
            <DialogueList>
              {dialogueValues &&
              dialogueValues.targetYear &&
              dialogueValues.targetYear.length > 0 ? (
                dialogueValues.targetYear
                  .slice((pageTarget - 1) * 3, pageTarget * 3)
                  .map((target, index) => (
                    <Fragment>
                      <StyleListItem>
                        <Typography
                          variant="subtitle1"
                          style={{
                            textAlign: "center",
                            lineHeight: "50px",
                            width: "10%",
                          }}
                        >
                          {index + 1 + (pageTarget - 1) * 3}
                        </Typography>
                        <HeaderContainer style={{ width: "90%" }}>
                          <Typography variant="body2" className="title">
                            {target.description}
                          </Typography>
                        </HeaderContainer>
                      </StyleListItem>
                      <Divider />
                    </Fragment>
                  ))
              ) : (
                <Box className="notFound">
                  <Typography variant="h6">ไม่มีข้อมูล</Typography>
                </Box>
              )}
            </DialogueList>
            <div style={{ padding: 5 }}>
              {dialogueValues && dialogueValues.targetYear && (
                <Pagination
                  page={pageTarget}
                  count={Math.ceil(dialogueValues.targetYear.length / 3)}
                  onChange={handleChangeTarget}
                />
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </StyledRoot>
  );
}

export default MyIDP;
