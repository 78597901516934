import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";

import AddIcon from "@mui/icons-material/Add";

import StyledCard from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AlertResponse from "../../../shared/general/AlertResponse";

import {
  allPayTypes,
  addPaytypes,
  updatePaytypes,
} from "../../../../../actions/paytypes";

import Content from "./cardContent";
import DialogNewEdit from "./dialogNewEdit";
import SelectAffiliateCompany from "../../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../../actions/company";
import { Box, Typography } from "@mui/material";

const StyledRoot = styled("div")({
  minHeight: 700,
  backgroundColor: "#FFFFFF !important",
  "& .wrap-btn": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 16,
    marginTop: 16,
  },
  "& .search-date-container": {
    marginTop: "24px",
    marginBottom: "24px",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    gap: "16px",
    "& .search-date-box": {
      maxWidth: "350px",
      width: "100%",
    },
    "& .search-button": {
      marginTop: "29px",
      display: "flex",
      alignItems: "center",
    },
  },

  ["@media only screen and (max-width: 600px)"]: {
    "& .search-date-container": {
      flexDirection: "column-reverse",
      "& .search-date-box": {
        maxWidth: "100%",
      },
      "& .search-button": {
        alignSelf: "flex-end",
        marginTop: "0",
        // width: "100%",
      },
    }
  }
});

const PayrollAdditionDeductionPage = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: paytypesList } = useSelector((state) => state.paytypes);
  const { result: userProfile } = useSelector(state => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

  const [openDialogNew, setOpenDialogNew] = React.useState(false);

  const [mode, setMode] = useState("new");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  const [selectedCompany, setSelectedCompany] = useState(null);


  // useEffect(() => {
  //   dispatch(allPayTypes());
  // }, []);

  useEffect(() => {
    dispatch(getAffiliateOrganization())
  }, [])

  useEffect(() => {
    if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      const foundCompany = affiliateOrganizationList.find(x => x.idCompany === userProfile.idCompany);
      if (foundCompany) {
        setSelectedCompany(foundCompany)
      } else {
        setSelectedCompany(affiliateOrganizationList[0])
      }
    }
  }, [userProfile, affiliateOrganizationList])

  useEffect(() => {
    if (selectedCompany) {
      dispatch(allPayTypes({
        idCompany: selectedCompany.idCompany,
      }));
    }
  }, [selectedCompany])


  const handleOpenDialogNew = () => {
    setMode("new");
    setOpenDialogNew(true);
  };

  const handleOpenDialogEdit = (value) => {
    setSelectedValue(value);
    setMode("edit");
    setOpenDialogNew(true);
  };

  const handleCloseDialogNew = () => {
    setOpenDialogNew(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleSubmit = async (values) => {
    let formData = { ...values };

    let checkCode = formData.preCode + formData.code;
    let foundDuplicate = paytypesList[formData.payTypes].find(
      (x) => x.code === checkCode && mode === "new"
    );

    formData.idCompany = selectedCompany.idCompany;

    console.log("formData: ", formData);

    if (foundDuplicate) {
      alert(`${t("CannotSaveDuplicateCode")}`);
    } else {
      let result = null;
      if (mode === "new") {
        result = await dispatch(addPaytypes(formData));
      } else {
        formData[
          `id${formData.payTypes.charAt(0).toUpperCase() +
          formData.payTypes.slice(1)
          }`
        ] =
          selectedValue[
          `id${formData.payTypes.charAt(0).toUpperCase() +
          formData.payTypes.slice(1)
          }`
          ];

        result = await dispatch(updatePaytypes(formData));
      }

      if (result) {
        handleOpenAlert();
        if (result.status === 200) {
          handleChangeAlertType("success");
          dispatch(allPayTypes({
            idCompany: selectedCompany.idCompany,
          }));
        } else {
          handleChangeAlertType("error");
        }
        handleCloseDialogNew();
      } else {
        handleOpenAlert();
        handleChangeAlertType("error");
        handleCloseDialogNew();
      }
    }
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">

        <Box className="search-date-container">
          <Box className="search-date-box">
            <Typography fontWeight="600" fontSize="14px" paddingBottom="8px" color="text.third">{t("Company")}</Typography>
            <SelectAffiliateCompany
              options={affiliateOrganizationList}
              value={selectedCompany}
              onChange={(_, value) => {
                setSelectedCompany(value)
              }}
            />
          </Box>
          <Box className="search-button">
            <ButtonBlue
              variant="contained"
              fullWidth
              startIcon={<AddIcon />}
              onClick={handleOpenDialogNew}
            >
              {t("Create")}
            </ButtonBlue>
          </Box>
        </Box>

        <Content
          paytype={"Addition"}
          handleOpenDialogEdit={handleOpenDialogEdit}
          isReadOnly={userProfile && userProfile.readOnly}
        />
        <Content
          paytype={"Deduction"}
          handleOpenDialogEdit={handleOpenDialogEdit}
          isReadOnly={userProfile && userProfile.readOnly}
        />
        <DialogNewEdit
          mode={mode}
          selectedValue={selectedValue}
          open={openDialogNew}
          handleClose={handleCloseDialogNew}
          handleSubmit={handleSubmit}
          isReadOnly={userProfile && userProfile.readOnly}
        />
        <AlertResponse
          open={openAlert}
          handleClose={handleCloseAlert}
          alertType={alertType}
        />
      </Container>
    </StyledRoot>
  );
};

export default PayrollAdditionDeductionPage;
