import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/styles";
import Pagination from "../../../shared/general/Pagination";
import dayjs from "dayjs";

import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Toolbar,
  LinearProgress,
  Typography,
  Avatar,
  Container,
  Box,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import SearchIcon from "@mui/icons-material/Search";
import EventIcon from "@mui/icons-material/Event";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArticleIcon from "@mui/icons-material/Article";

import { useSelector, useDispatch } from "react-redux";
import { getAllFailureHistory } from "../../../../../actions/FailureHistory";

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
    "& .MuiGrid-container": {
      marginBottom: "0px !important",
    },
  },
  newBtn: {
    color: "#4f65df",
    border: "2px solid #4f65df",
    borderRadius: 16,
    height: 48,
    
  },
  cardItem: {
    display: "flex",
    backgroundColor: "#fff",
    borderRadius: "5px",
    borderTop: "8px solid #ef5350",
    minHeight: "80px",
    marginTop: 16,
    marginBottom: 16,
    padding: 16,
    boxShadow: "rgba(17, 17, 26, 0.1) 0px 2px 0px",
  },
  img_section: {
    width: "120px",
    textAlign: "center",
  },
  info_section: {
    width: "100%",
    paddingLeft: 16,
  },
  imageEmp: {
    width: 80,
    height: 80,
    borderRadius: "50%",
  },
  viewBtn: {
    cursor: "pointer",
    backgroundColor: "#e6eaff",
    color: "#4f65df",
    width: 40,
    height: 40,
    marginRight: 8,
    marginTop: 8,
  },
  textSvg: {
    display: "flex",
    alignItems: "center",
  },
  noData_Section: {
    display: "flex",
    alignItems: "center",
  },
  noDataSvg: {
    fontSize: "30px",
    marginRight: 8,
  },
  keyword: {
    backgroundColor: "#efedf5",
    color: "#54565A",
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: "5px",
    marginBottom: 8,
  },
  likeBtn: {
    cursor: "pointer",
    marginRight: 8,
    marginTop: 8,
    color: "#4caf50",
    backgroundColor: "#e8f5e9",
  },
  likedCount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50px",
    color: "#4caf50",
    fontWeight: "700",
    marginRight: 8,
    marginTop: 8,
  },
}));

function FailureHistoryList() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: failureHistoryStore } = useSelector(
    (state) => state.FailureHistory
  );

  const [searchKey, setSearchKey] = useState("");
  const [records, setRecords] = useState([]);
  const [recordItems, setRecordItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [liked, setLiked] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = records.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchData = () => {
    dispatch(getAllFailureHistory());
  };

  const handleLikeClick = () => {
    setLiked(liked + 1);
  };

  useEffect(() => {
    if (failureHistoryStore) {
      setRecords(failureHistoryStore);
      setRecordItems(failureHistoryStore);
      setIsLoading(false);
    }
  }, [failureHistoryStore]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let x = [...recordItems];
    x = x.filter((y) => {
      return y.keyword.toLowerCase().includes(searchKey.toLocaleLowerCase());
    });
    setRecords(x);
  }, [searchKey]);

  return (
    <ContainerStyled>
      <div className="card-section">
        <div className="header-section">
          <Grid container>
            <Grid item>
              <h3 className="header-topic">Failure History</h3>
            </Grid>
          </Grid>
        </div>
        <Toolbar>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button
                variant="outlined"
                className={classes.newBtn}
                onClick={() => history.push("/failurehistory")}
              >
                Add New Subject
              </Button>
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                style={{ minWidth: "300px" }}
                label="Search Keyword"
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </div>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          {(records.length > 0 &&
            currentPosts.map((item) => (
              <div key={item.FailureID} className={classes.cardItem}>
                <div className={classes.img_section}>
                  {item.image == null || item.image == "string" ? (
                    <ArticleIcon
                      sx={{ color: "#e0e0e0" }}
                      className={classes.imageEmp}
                    />
                  ) : (
                    <img
                      src={`/images/employees/${item.image}`}
                      className={classes.imageEmp}
                    />
                  )}
                </div>
                <div className={classes.info_section}>
                  <Grid container>
                    <Grid item xs={10} sm={8} md={9} lg={9} xl={9}>
                      <Typography variant="h6">{item.subject}</Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={2}
                      sm={4}
                      md={3}
                      lg={3}
                      xl={3}
                      justifyContent="flex-end"
                    >
                      <div className={classes.likedCount}>{liked}</div>
                      <Avatar
                        className={classes.likeBtn}
                        onClick={handleLikeClick}
                      >
                        <ThumbUpAltIcon />
                      </Avatar>
                      <Avatar
                        className={classes.viewBtn}
                        onClick={() => {
                          history.push({
                            pathname: "/failurehistoryview",
                            state: { recordItem: item },
                          });
                        }}
                      >
                        <SearchIcon />
                      </Avatar>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        className={classes.keyword}
                        gutterBottom
                      >
                        {item.keyword}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      <Typography
                        variant="body1"
                        className={classes.textSvg}
                        color="textSecondary"
                      >
                        <EventIcon />
                        <Box style={{ marginRight: "10px" }}>
                          {dayjs(item.dateCreate).format("DD/MM/YYYY")}
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </div>
            ))) || (
            <div className="cardPlan">
              <Grid container justifyContent="center">
                <Grid item>
                  <Typography variant="h6" className={classes.noData_Section}>
                    <FindInPageIcon className={classes.noDataSvg} />
                    No Data
                  </Typography>
                </Grid>
              </Grid>
            </div>
          )}
        </>
      )}
      {records.length > 0 ? (
        <Pagination
          paginate={paginate}
          postsPerPage={postsPerPage}
          totalPosts={records.length}
        />
      ) : null}
    </ContainerStyled>
  );
}

export default FailureHistoryList;
