import kpiService from "../services/kpi.service";
import {
  GET_KPI_ID_FAILED,
  GET_KPI_ID_SUCCESS,
  KPI_FAILED,
  KPI_SUCCESS,
  KPI_APPROVAL_SUCCESS,
  KPI_APPROVAL_FAILED,
  GET_KPI_UPDATE_SUCCESS,
  GET_KPI_UPDATE_FAILED,
  GET_KPI_UPDATE_ID_FAILED,
  GET_KPI_UPDATE_ID_SUCCESS,
  KPI_APPROVAL_RESULT_SUCCESS,
  KPI_APPROVAL_RESULT_FAILED,
  KPI_EVALUATION_SUCCESS,
  KPI_EVALUATION_FAILED,
  KPI_CARLIBRATE_SUCCESS,
  KPI_CARLIBRATE_FAILED,
  KPI_DEPARTMENTCARLIBRATE_SUCCESS,
  KPI_DEPARTMENTCARLIBRATE_FAILED,
  KPI_IsSuccessful_SUCCESS,
  KPI_IsSuccessful_FAILED,
  KPI_UpdateAllAchievement_ID_SUCCESS,
  KPI_UpdateAllAchievement_ID_FAILED,
  KPI_All_SUCCESS,
  KPI_All_FAILED,
} from "./types";

export const getKPI = () => async (dispatch) => {
  try {
    const res = await kpiService.getKPI();
    if (res) {
      dispatch({
        type: KPI_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: KPI_FAILED,
    });
    console.log(err);
  }
};

export const getKPIManagerByidEmployee = (idEmployees) => async (dispatch) => {
  try {
    const res = await kpiService.getKPIManagerByidEmployee(idEmployees);
    if (res) {
      dispatch({
        type: KPI_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: KPI_FAILED,
      payload: err.message,
    });
    console.log(err);
  }
};

export const addKPI = (formData) => async () => {
  try {
    const res = await kpiService.addKPI(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const addManagerKPI = (formData) => async () => {
  try {
    const res = await kpiService.addManagerKPI(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateKPI = (idKPI, formData) => async () => {
  try {
    const res = await kpiService.updateKPI(idKPI, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};
export const updateManagerKPI = (idKPI, formData) => async () => {
  try {
    const res = await kpiService.updateManagerKPI(idKPI, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const deleteKPI = (idKPI) => async () => {
  try {
    const res = await kpiService.deleteKPI(idKPI);
    if (res) {
      return res;
    }
  } catch (error) {
    const massage =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateKPISubmit = (idKPI) => async () => {
  try {
    const res = await kpiService.updateKPISubmit(idKPI);
    if (res) {
      return res;
    }
  } catch (error) {
    const massage =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getKpiByid = (idKPI) => async (dispatch) => {
  try {
    const res = await kpiService.getKpiById(idKPI);
    if (res) {
      dispatch({
        type: GET_KPI_ID_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_KPI_ID_FAILED,
    });
  }
};

export const getAllKpiApprove = () => async (dispatch) => {
  try {
    const res = await kpiService.getAllKpiApprove();
    if (res) {
      dispatch({
        type: KPI_APPROVAL_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: KPI_APPROVAL_FAILED,
    });
    console.log(err);
  }
};

export const getKpiPlan = () => async (dispatch) => {
  try {
    const res = await kpiService.getKpiPlan();
    if (res) {
      dispatch({
        type: KPI_APPROVAL_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: KPI_APPROVAL_FAILED,
    });
    console.log(err);
  }
};

export const updateKPIApprove = async (data) => {
  try {
    const res = await kpiService.updateKPIApprove(data);

    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error(
        `Failed to update KPI approval. Server responded with status: ${res.status}`
      );
    }
  } catch (error) {
    console.error("Error updating KPI approval:", error);
    return "Error";
  }
};

export const updateKPIplanApprove = async (data) => {
  try {
    const res = await kpiService.updateKPIplanApprove(data);

    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error(
        `Failed to update KPI approval. Server responded with status: ${res.status}`
      );
    }
  } catch (error) {
    console.error("Error updating KPI approval:", error);
    return "Error";
  }
};

export const getKpiApprove = () => async (dispatch) => {
  try {
    const res = await kpiService.getKpiApprove();
    if (res) {
      dispatch({
        type: KPI_APPROVAL_RESULT_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: KPI_APPROVAL_RESULT_FAILED,
    });
    console.log(err);
  }
};

export const getKpiUpdate = () => async (dispatch) => {
  try {
    const res = await kpiService.getKpiUpdate();
    if (res) {
      dispatch({
        type: GET_KPI_UPDATE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: GET_KPI_UPDATE_FAILED,
    });
    console.log(err);
  }
};

export const getKpiUpdateAndPlan = (idEmployees) => async (dispatch) => {
  try {
    const res = await kpiService.getKpiUpdateAndPlan(idEmployees);
    if (res) {
      dispatch({
        type: GET_KPI_UPDATE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: GET_KPI_UPDATE_FAILED,
    });
    console.log(err);
  }
};

export const getKpiUpdateProgress = () => async (dispatch) => {
  try {
    const res = await kpiService.getKpiUpdateProgress();
    if (res) {
      dispatch({
        type: GET_KPI_UPDATE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: GET_KPI_UPDATE_FAILED,
    });
    console.log(err);
  }
};

export const getKpiUpdateById = (idKPI) => async (dispatch) => {
  try {
    const res = await kpiService.getKpiUpdateById(idKPI);
    if (res) {
      dispatch({
        type: GET_KPI_UPDATE_ID_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: GET_KPI_UPDATE_ID_FAILED,
    });
    console.log(err);
  }
};

export const addKpiProgress = (formData) => async () => {
  try {
    const res = await kpiService.addKpiProgress(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const addKpiProgressbar = (formData) => async () => {
  try {
    const res = await kpiService.addKpiProgressbar(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const addKpiPlan = (formData) => async () => {
  try {
    const res = await kpiService.addKpiPlan(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getKpiEvaluation = () => async (dispatch) => {
  try {
    const res = await kpiService.getKpiEvaluation();
    if (res) {
      dispatch({
        type: KPI_EVALUATION_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: KPI_EVALUATION_FAILED,
    });
    console.log(err);
  }
};

export const updateKPIEvaluationManager = (idKPI, formData) => async () => {
  try {
    const res = await kpiService.updateKPIEvaluationManager(idKPI, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateKPIEvaluationEmployee = (idKPI, formData) => async () => {
  try {
    const res = await kpiService.updateKPIEvaluationEmployee(idKPI, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getKpiCarlibrate = () => async (dispatch) => {
  try {
    const res = await kpiService.getKpiCarlibrate();
    if (res) {
      dispatch({
        type: KPI_CARLIBRATE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: KPI_CARLIBRATE_FAILED,
    });
    console.log(err);
  }
};

export const addKpiGrade = (formData) => async () => {
  try {
    const res = await kpiService.addKpiGrade(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateKpiGrade = async (formData) => {
  try {
    const res = await kpiService.updateKpiGrade(formData);
    if (res) {
      return res;
    } else {
      throw new Error("Failed to update KPI grade.");
    }
  } catch (error) {
    throw error;
  }
};

export const getKpiCalibrateDepartment = () => async (dispatch) => {
  try {
    const res = await kpiService.getKpiCalibrateDepartment();
    if (res) {
      dispatch({
        type: KPI_DEPARTMENTCARLIBRATE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: KPI_DEPARTMENTCARLIBRATE_FAILED,
    });
    console.log(err);
  }
};

export const getKpiSuccessful = () => async (dispatch) => {
  try {
    const res = await kpiService.getKpiSuccessful();
    if (res) {
      dispatch({
        type: KPI_IsSuccessful_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: KPI_IsSuccessful_FAILED,
    });
    console.log(err);
  }
};

export const getKpiIsSuccessfulManager = () => async (dispatch) => {
  try {
    const res = await kpiService.getKpiIsSuccessfulManager();
    if (res) {
      dispatch({
        type: KPI_IsSuccessful_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: KPI_IsSuccessful_FAILED,
    });
    console.log(err);
  }
};

export const getKpiUpdateAllAchievementById = () => async (dispatch) => {
  try {
    const res = await kpiService.getKpiUpdateAllAchievementById();
    if (res) {
      dispatch({
        type: KPI_UpdateAllAchievement_ID_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: KPI_UpdateAllAchievement_ID_FAILED,
    });
    console.log(err);
  }
};

export const getKpiAll = () => async (dispatch) => {
  try {
    const res = await kpiService.getKpiAll();
    if (res) {
      dispatch({
        type: KPI_All_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: KPI_All_FAILED,
    });
    console.log(err);
  }
};

export const uploadExcelKpi = async (formData) => {
  try {
    let res = await kpiService.uploadExcelKpi(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    if (err.response) {
      return err.response.data;
    }
  }
};

export const uploadExcelKpiGrade = async (formData) => {
  try {
    let res = await kpiService.uploadExcelKpiGrade(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    if (err.response) {
      return err.response.data;
    }
  }
};

export const putKpiIsSubmitById = async (idKPI) => {
  try {
    const res = await kpiService.putKpiIsSubmitById(idKPI);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getKPIByManager = () => async (dispatch) => {
  try {
    const res = await kpiService.getKPIByManager();
    if (res) {
      dispatch({
        type: KPI_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: KPI_FAILED,
    });
    console.log(err);
  }
};

export const getKPIByAdmin = () => async (dispatch) => {
  try {
    const res = await kpiService.getKPIByAdmin();
    if (res) {
      dispatch({
        type: KPI_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: KPI_FAILED,
    });
    console.log(err);
  }
};

export const addKPIDate = (formData) => async (dispatch) => {
  try {
    const res = await kpiService.addKPIDate(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.error("Error:", message);
    return { error: message };
  }
};

export const getKPIDate = () => async (dispatch) => {
  try {
    const res = await kpiService.getKPIDate();
    if (res) {
      dispatch({
        type: KPI_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: KPI_FAILED,
    });
    console.log(err);
  }
};

export const deleteKPIDate = (idKpiEditDate) => async () => {
  try {
    const res = await kpiService.deleteKPIDate(idKpiEditDate);
    if (res) {
      return res;
    }
  } catch (error) {
    const massage =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateKPIDate = (idKpiEditDate, formData) => async (dispatch) => {
  try {
    const res = await kpiService.updateKPIDate(idKpiEditDate, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};
