import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch} from "react-redux";
import dayjs from "dayjs";
import {
  Grid,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import Swal from 'sweetalert2';
import { makeStyles, createStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import Dropzone, { useDropzone } from "react-dropzone";
import AlertResponse from "../../shared/general/AlertResponse";
import AddPhotoIcon from "../../../../components/pages/assets/add.png";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DeleteIcon from "@mui/icons-material/Delete";
import { RotatingLines } from 'react-loader-spinner';

//Translator TH-EN
import { useTranslation } from "react-i18next";
import {
   editCompanyDocument,
   addCompanyDocument
} 
from "../../../../actions/document";
const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 12,
  backgroundColor: "#f9f9fb",
  "& .dropzone-leave": {
    // height: 98,
    borderRadius: 4,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    height: 380,
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#efefef",
    flexDirection: "column",
    padding: "4px 0",
    "& img": {
      width: 'auto',
      height: 170,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const useStyles = makeStyles((theme) => createStyles({
  dialogBackground: {
    '& .css-ut4szi': {
      '@media (max-width: 450px)': {
        fontSize: '16px', // ขนาดตัวอักษรเล็กลง
        width: '70vw',     // กว้างขึ้น
        height: '40vh',    // สูงขึ้น
      },
       // สำหรับจอมือถือ (ขนาดน้อยกว่า 768px)
      '@media (min-width: 450px) and (max-width: 768px)': {
        fontSize: '18px', // ขนาดตัวอักษรเล็กลง
        width: '77vw',    // กว้างขึ้น
        height: '70vh',   // สูงขึ้น
      },

        // สำหรับ iPad หรือแท็บเล็ต (ขนาด 768px - 1024px)
      '@media (min-width: 768px) and (max-width: 1024px)': {
        fontSize: '20px', // ขนาดตัวอักษรปรับขึ้น
        width: '80vw',     // กว้างขึ้น
        height: '70vh',    // สูงขึ้น
      },

      // สำหรับจอโน้ตบุ๊ค (ขนาด 1024px - 1440px)
      '@media (min-width: 1024px) and (max-width: 1360px)': {
        fontSize: '22px', // ขนาดตัวอักษรเล็กลง
        width: '85vw',    // กว้างน้อยลง
        height: '71vh',   // สูงน้อยลง
      },

      // สำหรับจอคอมพิวเตอร์ (ขนาด 1440px ขึ้นไป)
      '@media (min-width: 1360px)': {
        fontSize: '26px', // ขนาดตัวอักษรใหญ่ขึ้น
        width: '95vw',    // กว้างขึ้น
        height: '85vh',   // สูงขึ้น
      },
    },
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none', 
        border: '1px solid black', 
        borderRadius: '10px',
        background: 'linear-gradient(to bottom, #FFFFFF, #F0F8FF)',
        // สำหรับอุปกรณ์ที่มีขนาดหน้าจอ 600px ขึ้นไป
        '@media (min-width: 600px)': {
          width: '90vw',
          height: '82vh',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        },

        // สำหรับ iPad หรือแท็บเล็ต (ขนาด 768px - 1024px)
        '@media (min-width: 768px) and (max-width: 1024px)': {
          width: '85vw',     // ปรับความกว้างให้เหมาะสม
          height: '80vh',    // ปรับความสูงให้เหมาะสม
          boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)', // ปรับความเข้มของเงา
        },

        // สำหรับอุปกรณ์ระหว่าง iPad และจอคอมพิวเตอร์ (ขนาด 1025px - 1499px)
        '@media (min-width: 1025px) and (max-width: 1499px)': {
          width: '88vw',     // ปรับความกว้างให้เหมาะสม
          height: '75vh',    // ปรับความสูงให้เหมาะสม
          boxShadow: '0px 7px 14px rgba(0, 0, 0, 0.18)', // ปรับความเข้มของเงา
        },

        // สำหรับจอคอมพิวเตอร์ (ขนาด 1500px ขึ้นไป)
        '@media (min-width: 1500px)': {
          width: '90vw',
          height: '90vh',
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
        },
      },
    },
    '& .MuiBackdrop-root': {
      backdropFilter: 'none', 
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '70vw', 
      maxHeight: '60vh', 
      borderRadius: '10px', 
    },
    '& .MuiBackdrop-root.MuiModal-backdrop': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '0vw', 
      maxHeight: '0vh', 
      borderRadius: '10px', 
    },
  },
}));

const DialogImport = (props) => {
  const dispatch = useDispatch();
  const { open, onClose, data, dialogType, handleFileClose, handleCloseDialog} = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [formData, setFormData] = useState(null);
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  
  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };


  const fileDrawer = () => {
    if (formData && formData.file === "none") {
      return data.fileURL;
    } else {
    return URL.createObjectURL(formData.file);
    }
  };

  useEffect(() => {
    if (open) {
      if (dialogType === "edit") {
        setFormData({
          file: "none", 
        });
      } else
        setFormData({
          file: null,
        });
    }
  }, [open]);


  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!formData.file) {
      handleOpenAlert(true);
      handleChangeAlertType(`${t("ThisFieldIsRequired")}`);
    } else { 
      const filenameNew = formData.file.name;
      const info = {
        idCompany: data.idCompany,   
        idCompanyDocumentType: data.idCompanyDocumentType,
        filename: data.filename,
        documentGroupName: data.documentGroupName,       
        documentTypeName: data.documentTypeName,
        createDate: dayjs(new Date()).format("YYYY-MM-DD"),
        updateDate: dayjs(new Date()).format("YYYY-MM-DD"),
      };

      const postData = new FormData();

      Object.keys(info).map((k) => {
        postData.append(k, JSON.stringify(data[k]));
      });

      if (formData.file) {
        postData.append("file", formData.file);
      }
      setLoading(true);

      let result;
      try {
        if (dialogType === "edit") {
          result = await dispatch(editCompanyDocument(postData));
        } else if (dialogType === "import") {
          result = await dispatch(addCompanyDocument(postData)); 
        }
        
        if (result) {
          Swal.fire({
            icon: 'success',
            title: dialogType === "edit" ? `${t("Successfully_edited_the_document_file")}!` :`${t("Added_document_file_successfully")}!`,
            showConfirmButton: false,
            timer: 1500,
          });
          handleFileClose();
        } else {
          Swal.fire({
            icon: 'error',
            title: dialogType === "edit" ? `${t("Unable_to_edit_document_file")}!`:  `${t("Unable_to_add_document_file")}!`,
            text:  `${t("PleaseTryAgain")}!`,
            confirmButtonText: 'OK',
          });
        }
      } catch (error) {
        console.error("Error while editing document: ", error);
        Swal.fire({
          icon: 'error',
          title:`${t("AnErrorOccurred")}!`,
          text: `${t("PleaseTryAgain")}!`,
          confirmButtonText: 'OK',
        });
      } finally {
        setLoading(false);  
        handleCloseDialog();
      }
    }
  }
  const title = `${t("AttachFile")}` 

  return (
      <Dialog
        title={title}
        open={open}
        onClose={onClose}                                 
        className={classes.dialogBackground}
      >
      {formData && (
        <StyledRoot>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
            style={{userSelect: 'none'}}
          >
            <Grid item xs={12}>
              <Typography
                color="text.third"
                variant="body2"
                paddingBottom="10px"
                style={{
                  color: "#000000",
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  userSelect: 'none',
                }}
              >
                {t("AttachFile")} 
              </Typography>
              {!formData.file && (     
                <> 
                 <StyledDropzone>
                      <Dropzone
                        accept="application/pdf"
                        maxFiles={1}
                        multiple={false}
                        maxSize={3145728}
                        onDrop={(acceptedFiles, rejectedFiles) => {
                          if (acceptedFiles.length > 0) {
                            setFormData({ ...formData, file: acceptedFiles[0] });
                          }
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps({ className: "dropzone-leave" })}>
                            <div className="inner-dropzone">
                              <input {...getInputProps()} />
                              <div style={{ textAlign: "center" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    userSelect: 'none',
                                  }}
                                >
                                  <img alt="add" src={AddPhotoIcon} />
                                </div>
                                <Typography
                                  variant="caption"
                                  style={{ lineHeight: 0.5, color: "#999999", userSelect: 'none'}}
                                >
                                  Allowed *.pdf, max size of 3 MB
                                </Typography>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                 </StyledDropzone>  
                 <div style={{height: "50px"}}>

                 </div>
                </>         
              )}
              {formData.file && (
                <Box sx={{ position: "relative", textAlign: "center" }}>
                  {loading ? ( 
                    <></>
                  ): (
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: "error.main",
                        border: "2px solid",
                        borderColor: "error.main",
                        borderRadius: "0%",
                        transition: "transform 0.3s, box-shadow 0.3s",
                        "&:hover": {
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                          backgroundColor: "rgba(255, 0, 0, 0.1)", 
                        },
                      }}
                      style={{userSelect: 'none'}}
                      onClick={() => {
                        setFormData({ ...formData, file: null });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                  {dialogType === "import" && formData.file instanceof Blob ? (
                   <> 
                    {loading ? (
                        <> 
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '250px', userSelect: 'none' }}>
                        <Typography 
                         variant="h6" 
                         style={{
                           marginTop: '16px',
                           color: '#3f51b5',
                           textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                           fontWeight: 'bold',
                           userSelect: 'none',
                         }}
                         >
                           {t("UploadingDocCompanyFiles")}
                         </Typography>
                        </div>
                         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', userSelect: 'none' }}>
                           <RotatingLines
                             visible={true}
                             height="96"
                             width="96"
                             color="grey"
                             strokeWidth="5"
                             animationDuration="0.75"
                             ariaLabel="rotating-lines-loading"
                             wrapperStyle={{}}
                             wrapperClass=""
                             />
                           </div>
                        </>
                      ) : (
                        <div>
                          <Typography variant="caption" style={{ lineHeight: 0.5, color: "#000000",fontSize: '1rem', userSelect: 'none'}}>
                            File: {formData.file.name}
                          </Typography>
                          <iframe
                            src={URL.createObjectURL(formData.file)}
                            width="100%"
                            height="386px"
                            title="Preview"
                            style={{userSelect: 'none'}}
                          ></iframe>
                        </div> )}
                   </>
                   ) : (
                  <div>
                  {dialogType === "edit" && data && (
                     <div>
                     {loading ? (
                       <> 
                       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '250px', userSelect: 'none' }}>
                       <Typography 
                        variant="h6" 
                        style={{
                          marginTop: '16px',
                          color: '#3f51b5',
                          textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                          fontWeight: 'bold',
                          userSelect: 'none',
                        }}
                        >
                          {t("UpdateDocCompanyFile")}
                        </Typography>
                       </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', userSelect: 'none' }}>
                          <RotatingLines
                            visible={true}
                            height="96"
                            width="96"
                            color="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            ariaLabel="rotating-lines-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            />
                          </div>
                       </>
                     ) : (
                       <>
                         {formData && formData.file && formData.file !== "none" &&  data.fileURL ? (
                           <Typography variant="caption" style={{ lineHeight: 0.5, color: "#000000", fontSize: '1rem', userSelect: 'none'}}>
                             {formData.file.name}
                           </Typography>
                         ) : (
                           <Typography variant="caption" style={{ lineHeight: 0.5, color: "#000000", fontSize: '1rem', userSelect: 'none' }}>
                             {data.filename}
                           </Typography>  
                         )}
                         <iframe
                           src={fileDrawer()}
                           style={{
                             width: "100%",
                             border: "1px solid #ccc",
                             borderRadius: "4px",
                             marginTop: "8px",
                             marginLeft: "0px", 
                             height: '456px', 
                             userSelect: 'none',
                             zoom: '100%'
                           }}
                           title="Preview"
                         ></iframe>
                       </>
                     )}
                   </div>
                  )}
                </div>
                )}
                </Box>
              )}
            </Grid>
          </Box>
          <StyledFooter>
            {loading ? ( 
               <></>
            ): (
            <>     
              <ButtonBlue className="cancel" onClick={onClose}>
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue 
                variant="contained" 
                onClick={handleSubmit}
                disabled={!formData.file} 
              >
                {t("SaveData")}
              </ButtonBlue>
            </>
             )}
          </StyledFooter>
        </StyledRoot>
       )} 
      <AlertResponse
        open={openAlert}
        alertType={alertType}
      />
    </Dialog>
  );
};

export default DialogImport;
