import { httpClient } from "./httpClient";

const addManpowersToCandidate = (data) => {
    return httpClient.post("candidate/add",data);
};

const getNewCandidatesByIdJobRequest = (idJobRequest) => {
    return httpClient.get(`jobRequest/${idJobRequest}/candidate/newCandidate`);
};

const getNewCandidatesByIdBiddings = (idBiddings) => {
    return httpClient.get(`bidding/${idBiddings}/candidate/newCandidate`);
};

const updateRatingAndNoteByIdCandidate = (idCandidate, data) => {
    return httpClient.post(`candidate/${idCandidate}/update-rating-note`,data);
};

const updateInterviewDetail = (data) => {
    return httpClient.put(`candidate/update/interviewDetail`,data);
};

const getInterviewedCandidatesByIdVendor = () => {
    return httpClient.get(`vendor/interviewed-candidate`);
};

const getInterviewScheduleAndCandidatesByIdCompany = () => {
    return httpClient.get(`company/company-schedule-interview`);
};

const getHistoryInterviewScheduleAndCandidatesByIdcompany = () => {
    return httpClient.get(`company/company-history-schedule`);
};

const updateConcludsionInterview = (data) => {
    return httpClient.post(`candidate/result-interview`,data);
};

const updateResultAppointedEachCandidate = (data) => {
    return httpClient.put(`candidate/update/appointment-result-each-person`, data);
};

const getCandidatesAlreadySentToJob = (idJobRequest) => {
    return httpClient.get(`candidate/job/${idJobRequest}/already-sent`);
};

const updateCancelSendingCandidate = (data) => {
    return httpClient.put(`candidate/cancel-sending`,data);
};

const getPassedCandidatesInJob = (idJob) => {
    return httpClient.get(`company/candidates/history/job/${idJob}`);
};

const sendManpowersToCandidateBidding = (data) => {
    return httpClient.post(`candidate/bidding/sendManpower`,data);
};

const getPassedCandidatesInBidding = (id) => {
    return httpClient.get(`company/candidates/history/bidding/${id}`);
};

const getCandidatesAlreadySentToBidding = (id) => {
    return httpClient.get(`candidate/bidding/${id}/already-sent`);
};

const getHistoryCandidatesJobsMatchingVendor = () => {
    return httpClient.get(`vendor/history-candidates-jobs-matching`);
};

const getHistoryManpowersSending = () => {
    return httpClient.get(`vendor/history-manpowers-sending`);
};

const getAllCandidateStatus = () => {
    return httpClient.get(`candidate/getallcandidatestatus`);
};

const updateInterviewInfo = (idCandidate, formData) => {
    return httpClient.put(`candidate/updateinterviewinfo/${idCandidate}`, formData);
};

const getManpowerForSelectToInterviewJob = (idJobRecruit) => {
    return httpClient.get(`candidate/getmanpowerforselecttointerviewjob/${idJobRecruit}`);
};

const updateStatusCandidateByManager = (idJobRecruit, status, formData) => {
    return httpClient.put(`/candidate/updateStatusCandidateByManager`, {idJobRecruit, status, listCandidates : formData});
}

export default {
    addManpowersToCandidate,
    getNewCandidatesByIdJobRequest,
    getNewCandidatesByIdBiddings,
    updateRatingAndNoteByIdCandidate,
    updateInterviewDetail,
    getInterviewedCandidatesByIdVendor,
    getInterviewScheduleAndCandidatesByIdCompany,
    getHistoryInterviewScheduleAndCandidatesByIdcompany,
    updateConcludsionInterview,
    updateResultAppointedEachCandidate,
    getCandidatesAlreadySentToJob,
    updateCancelSendingCandidate,
    getPassedCandidatesInJob,
    sendManpowersToCandidateBidding,
    getPassedCandidatesInBidding,
    getCandidatesAlreadySentToBidding,
    getHistoryCandidatesJobsMatchingVendor,
    getHistoryManpowersSending,
    getAllCandidateStatus,
    updateInterviewInfo,
    getManpowerForSelectToInterviewJob,
    updateStatusCandidateByManager
};