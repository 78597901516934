import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";

import { useDispatch } from "react-redux";
import Gauge from "./gauge";
import { Link } from "react-router-dom";
import { getCarlibrateDepartment } from "../../../../../actions/okrs";
//Translator TH-EN
import { useTranslation } from "react-i18next";

function DepartmentCalibrate() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [calibrateData, setCalibrateData] = useState([]);
  const [calibrateNoData, setCalibrateNoData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    dispatch(getCarlibrateDepartment()).then((res) => {
      if (isMounted) {
        if (res.status === 200) {
          const departmentData = res.data.filter((item) => item.grades);
          const departmentNoData = res.data.filter((item) => !item.grades);

          setCalibrateData(departmentData);
          setCalibrateNoData(departmentNoData);
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Box padding={"0 24px"}>
      <Typography fontWeight={700} color={"#4f65df"} fontSize={"1.5rem"}>
        {`${t("DepartmentalPerformanceSummary")}`}
      </Typography>
      <Grid container spacing={2} marginTop={4}>
        {calibrateData.map((item) => (
          <Grid item xs={12} md={6}>
            <Card>
              <CardActionArea
                LinkComponent={Link}
                to={`/okrs/calibrate-department/${item.idDepartment}`}
              >
                <CardHeader title={item.departmentName} />
                <CardContent>
                  <Gauge grades={item.grades} />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
        {calibrateNoData.map((item) => (
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title={item.departmentName} />
              <CardContent>
                <Typography textAlign={"center"}>{`${t("NoData")}`}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default DepartmentCalibrate;
