import {
  EMPLOYEES_ROLE_ONE_TWO_FETCHING,
  EMPLOYEES_ROLE_ONE_TWO_SUCCESS,
  EMPLOYEES_ROLE_ONE_TWO_FAILED,
} from "../actions/types";

const initialState = {
  result: null,
  isFetching: false,
  isError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case EMPLOYEES_ROLE_ONE_TWO_FETCHING:
      return { ...state, result: null, isFetching: true, isError: false };
    case EMPLOYEES_ROLE_ONE_TWO_FAILED:
      return { ...state, result: null, isFetching: false, isError: true };
    case EMPLOYEES_ROLE_ONE_TWO_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    default:
      return state;
  }
}
