import React, { useState, useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";

import { styled } from "@mui/material/styles";
import {
  TableRow,
  TableCell,
  TextField,
  Typography,
  IconButton,
  Popper,
  Box,
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import DragHandleRoundedIcon from "@mui/icons-material/DragHandleRounded";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";

import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";

const StyledTableRow = styled(TableRow)({
  marginBottom: 2,
});

const StyledTableCell = styled(TableCell)({
  padding: 0,
  border: "none",
  "& .wrap": {
    height: 72,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    borderTop: "1px solid #e1e1e1",
    borderLeft: "1px solid #e1e1e1",
    borderBottom: "1px solid #e1e1e1",
    "&.first": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    "&.last": {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      borderRight: "1px solid #e1e1e1",
    },
  },
  "&.date-document": {
    width: 130,
    "& .MuiInputBase-root": {
      paddingRight: 8,
    },
  },
  "&.description": {
    width: 450,
  },

  "&.price": {
    width: 104,
  },
  "&.delete": {
    width: 32,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  margin: "0px !important",
  "& .MuiInputBase-root": {
    borderRadius: 0,
    height: 56,
    "& fieldset": {
      border: "none",
    },
    "& .MuiInputBase-input": {
      padding: "13.5px !important",
    },
  },
});

const StyledTextArea = styled(TextField)({
  width: "100%",
  margin: "0px !important",
  "& .MuiInputBase-root": {
    borderRadius: 0,
    padding: "0px !important",
    "& textarea": {
      padding: "13.5px !important",
      overflow: "auto !important",
      height: "auto !important",
    },
    "& fieldset": {
      border: "none",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    paddingLeft: 0,
    paddingRight: "48px !important",
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledButtonTax = styled(Button)({
  minWidth: 32,
  "&:hover": {
    backgroundColor: "transparent",
  },
});

const RowBillingItem = (props) => {
  const {
    index,
    handleDeleteRow,
    count,
    open,
    calculateSum,
    control,
    errors,
    addDeleteRow,
    type,
    setDifference,
  } = props;

  return (
    <StyledTableRow>
      <StyledTableCell className=""></StyledTableCell>
      <StyledTableCell className="cell-table description">
        <div className="wrap first">
          <FormControl
            fullWidth
            error={
              errors &&
              errors.listBilling &&
              errors.listBilling[index] &&
              errors.listBilling[index].description
                ? true
                : false
            }
          >
            <Controller
              name={`listBilling.${index}.description`}
              control={control}
              errors={errors}
              render={({ field }) => (
                <>
                  <StyledTextArea {...field} />
                </>
              )}
            />
            {errors &&
              errors.listBilling &&
              errors.listBilling[index] &&
              errors.listBilling[index].description &&
              errors.listBilling[index].description.message && (
                <FormHelperText
                  error
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {errors.listBilling[index].description.message}
                </FormHelperText>
              )}
          </FormControl>
        </div>
      </StyledTableCell>
      <StyledTableCell className="cell-table price">
        <div className="wrap">
          <FormControl
            fullWidth
            error={
              errors &&
              errors.listBilling &&
              errors.listBilling[index] &&
              errors.listBilling[index].price
                ? true
                : false
            }
          >
            <Controller
              name={`listBilling.${index}.price`}
              control={control}
              errors={errors}
              render={({ field }) => (
                <>
                  <StyledTextArea
                    {...field}
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                    }}
                    inputProps={{
                      decimalScale: 2,
                      allowNegative: false,
                      value: field.value,
                      onValueChange: (values) => {
                        const { value } = values;
                        field.onChange(value);
                        setDifference(index);
                        calculateSum();
                      },
                    }}
                    onChange={() => {}}
                  />
                </>
              )}
            />
            {errors &&
              errors.listBilling &&
              errors.listBilling[index] &&
              errors.listBilling[index].price &&
              errors.listBilling[index].price.message && (
                <FormHelperText
                  error
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {errors.listBilling[index].price.message}
                </FormHelperText>
              )}
          </FormControl>
        </div>
      </StyledTableCell>
      <StyledTableCell className="cell-table price">
        <div className="wrap last">
          <FormControl
            fullWidth
            error={
              errors &&
              errors.listBilling &&
              errors.listBilling[index] &&
              errors.listBilling[index].withdrawablePrice
                ? true
                : false
            }
          >
            <Controller
              name={`listBilling.${index}.withdrawablePrice`}
              control={control}
              errors={errors}
              render={({ field }) => (
                <>
                  <StyledTextArea
                    {...field}
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                    }}
                    inputProps={{
                      decimalScale: 2,
                      allowNegative: false,
                      value: field.value,
                      onValueChange: (values) => {
                        const { value } = values;
                        field.onChange(value);
                        setDifference(index);
                        calculateSum();
                      },
                    }}
                    onChange={() => {}}
                  />
                </>
              )}
            />
            {errors &&
              errors.listBilling &&
              errors.listBilling[index] &&
              errors.listBilling[index].withdrawablePrice &&
              errors.listBilling[index].withdrawablePrice.message && (
                <FormHelperText
                  error
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {errors.listBilling[index].withdrawablePrice.message}
                </FormHelperText>
              )}
          </FormControl>
        </div>
      </StyledTableCell>
      <StyledTableCell className="delete" align="center">
        {count > 1 && (
          <span>
            {type === "edit" ? (
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => {
                  addDeleteRow(index);
                  handleDeleteRow(index);
                }}
              >
                <DeleteOutlineRoundedIcon fontSize="small" />
              </IconButton>
            ) : (
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => {
                  handleDeleteRow(index);
                }}
              >
                <DeleteOutlineRoundedIcon fontSize="small" />
              </IconButton>
            )}
          </span>
        )}
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default RowBillingItem;
