import React from 'react';
import {
    Box,
    Modal
} from "@mui/material";
import loading from "../../assets/social-media.gif";

const ModalLoading = (props) => {
    let { open } = props;
    return (
        <Modal
            open={open}
        >
            <Box
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Box
                    width="fit-content"
                    height="fit-content"
                    style={{
                        padding:"32px",
                        borderRadius:"50%",
                        backgroundColor:"#ffffff"
                    }}
                >
                    <img 
                        style={{
                            transform:"translateX(4px)"
                        }} 
                        width="120" 
                        alt="loading" 
                        src={loading} 
                    />
                </Box>
            </Box>
        </Modal>
    );
};

export default ModalLoading;