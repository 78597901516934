import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Grid, MenuItem, styled, Typography } from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import { currency } from "../../assets/data/currency";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import {
  getCompanyProfile,
  updatePaymentDetail,
} from "../../../../actions/company";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  marginBottom: 8,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DrawerPayment = (props) => {
  const { open, onClose, selectedCompany } = props;
  const { t, i18n } = useTranslation();
  const Currency = currency(t);
  const dispatch = useDispatch();
  const { result: companyProfile } = useSelector(
    (state) => state.companyProfile
  );
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      paymentCurrency: "THB",
    },
  });

  useEffect(() => {
    if (companyProfile) {
      reset({
        paymentCurrency: companyProfile.paymentCurrency,
      });
    }
  }, [companyProfile]);

  const onHandleSubmit = async (data) => {
    const res = await dispatch(
      updatePaymentDetail(selectedCompany.idCompany, data)
    );
    console.log(res);
    if (res) {
      dispatch(getCompanyProfile({ idCompany: selectedCompany.idCompany }));
      onClose();
    }
  };

  return (
    <DrawerCustom
      title={`${t("EditDataPayment")}`}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(onHandleSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledContentLabel>{`${t("Currency")}`}</StyledContentLabel>
              <Controller
                name="paymentCurrency"
                control={control}
                render={({ field }) => (
                  <TextFieldTheme {...field} select>
                    <MenuItem value="none" disabled>
                      {`${t("selectCurrency")}`}
                    </MenuItem>
                    {Currency &&
                      Currency.map((currency) => (
                        <MenuItem key={currency.code} value={currency.unit}>
                          {currency.label}
                        </MenuItem>
                      ))}
                  </TextFieldTheme>
                )}
              />
            </Grid>
          </Grid>
          <StyledFooter>
            <ButtonBlue variant="text" className="cancel" onClick={onClose}>
              {`${t("Cancel")}`}
            </ButtonBlue>
            <ButtonBlue variant="contained" type="submit">
              {`${t("SaveData")}`}
            </ButtonBlue>
          </StyledFooter>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DrawerPayment;
