import React, { Fragment } from "react";
import { useDropzone } from "react-dropzone";
import AddIcon from "../../assets/add.png";
import { Avatar, Box, IconButton, Typography, styled } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
    width:"100%",
    height:"100%",
    "& .dropzone-upload-file": {
        textAlign: "center",
        backgroundColor: "#F9F9FB",
        border: "1px solid #D0D0D0",
        borderStyle: "dashed",
        borderRadius: 8,
        height: 200,
        display: "flex",
        justifyContent: "center",
        "& .inner-dropzone": {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            "& img": {
                marginBottom: 16,
            },
        },
    },
});

const DragDrop = (props) => {
    const { name, useHookForm, type, size, mulitple } = props;
    const { t } = useTranslation();

    const { getRootProps, getInputProps } = useDropzone({
        accept: type,
        onDrop: (acceptedFiles, rejectedFiles) => {
            if (acceptedFiles) {
                let newValue = [...mulitple ? useHookForm.watch(name) : [], ...acceptedFiles.map((file) => {
                    return {
                        file : file,
                        sizeDisplay : getSizeText(file.size)
                    };
                })]
                useHookForm.setValue(name, newValue);
            }
            if(rejectedFiles.length > 0){
                for(let i = 0; i < rejectedFiles.length; i++){
                    if(rejectedFiles[i].errors){
                        if (rejectedFiles[i].errors[0].code === "file-invalid-type") {
                            useHookForm.setError(name, {
                                message: `${t("file_invalid_type")}`,
                            });
                        }
                        if (rejectedFiles[i].errors[0].code === "file-too-large") {
                            useHookForm.setError(name, { message: `${t("file_too_large")}` });
                        }
                        // if (rejectedFiles[i].errors[0].code === "too-many-files") {
                        //     useHookForm.setError(name, {
                        //         message: `${t("too_many_files")}`,
                        //     });
                        // }
                        break;
                    }
                }
            }
        },
        multiple: mulitple ? true : false,
        maxSize: size,
    });

    const getSizeText = (size) => {
        const unit = ["K", "M", "G"];
        let tempSize = size / 1024;
        let i = 0;
        while (tempSize > 1024 && i < 2) {
          tempSize = tempSize / 1024;
          i++;
        }
    
        return `${Math.round(tempSize * 100) / 100} ${unit[i]}B`;
    };

    return (
        <StyledRoot>
            <Controller 
                control={useHookForm.control}
                name={name}
                render={({ field }) => (
                    <Fragment>
                        <Box>
                            <div
                                {...getRootProps({
                                    className: "dropzone-upload-file",
                                })}
                            >
                                <input {...getInputProps()} />
                                <div className="inner-dropzone">
                                    <img
                                        alt="AddIcon"
                                        src={AddIcon}
                                        width="80"
                                        style={{ pointerEvents: "none" }}
                                    />
                                    <Typography
                                        style={{
                                            marginTop: 8,
                                            backgroundColor: "transparent",
                                        }}
                                        className={`placeholderLabel`}
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        {t("DragDropOrClickSelectFile")}
                                    </Typography>
                                </div>
                            </div>
                            {useHookForm.formState.errors[field.name] && (
                                <Typography
                                    margin="3px 0px 0px"
                                    fontSize="14px"
                                    color="error"
                                >
                                    {useHookForm.formState.errors[field.name].message}
                                </Typography>
                            )}
                        </Box>
                    </Fragment>
                )}
            />
        </StyledRoot>
    );
};

export default DragDrop;