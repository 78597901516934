import { httpClient } from "./httpClient";

const getExitInterview = () => {
  return httpClient.get(`/company/getQuestionExitInterview`);
};

const getExitInterviewDiscard = () => {
  return httpClient.get(`/company/getQuestionExitInterviewDiscard`);
};

const updateExitInterview = (formdata) => {
  return httpClient.put(`/company/exitInterview/update`, formdata);
};

const updateExitInterviewUser = (formdata) => {
  return httpClient.put(`/company/exitInterview/userExitInterview`, formdata);
};

const updateReasonExitInterview = (formdata) => {
  return httpClient.put(`/company/reasonExitInterview/update`, formdata);
};

const getExitInterviewAndAnswer = (query) => {
  return httpClient.get(`/company/getResultExitInterview`, { params: query });
};

const getReasonExitInterview = (query) => {
  return httpClient.get(`/company/reasonExitInterview`, { params: query });
};

const updateWithDrawExitInterview = (idResultExitInterview) => {
  return httpClient.put(`/company/withDrawExitInterview/update`, idResultExitInterview);
};

export default {
  getExitInterview,
  getExitInterviewDiscard,
  updateExitInterview,
  updateExitInterviewUser,
  getExitInterviewAndAnswer,
  updateReasonExitInterview,
  getReasonExitInterview,
  updateWithDrawExitInterview
};
