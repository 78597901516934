import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import {
    Avatar,
    Box,
    Chip,
    IconButton,
    Stack,
    styled,
    Tooltip,
    Typography
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ButtonBlue from '../../../../shared/general/ButtonBlue';
import CardStyle from '../../../../shared/general/Card';
import DialogCancelled from './dialogCancelled';
import TableTheme from '../../../../shared/general/TableTheme';

import { updateCancelSendingCandidate } from '../../../../../../actions/candidate';
import { getAllFreeUsersByIdVendor } from '../../../../../../actions/employee';
import DrawerInterviewAppointment from './drawerInterviewAppointment';
import DrawerEditInterview from './drawerEditInterview';
import { stringToColor } from '../../../../../../utils';
import { getManager } from '../../../../../../actions/manager';
import { t } from 'i18next';
import { getUserPosition } from '../../../../../../utils/userData';

const StyledContent = styled("div")({
    padding: 36,
    paddingTop: 24,
    "& .column-name": {
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        color: "#000000",
        "& .MuiAvatar-root": {
            marginRight: 8,
            width: 40,
            height: 40,
        },
    },
});

const WaitingProcessChip = styled(Chip)({
    width: "130px",
    color: "#ff9800",
    fontWeight: 500,
    backgroundColor: "#fff0ce"
});

const WaitingResultChip = styled(Chip)({
    width: "130px",
    color: "#623f00",
    fontWeight: 500,
    backgroundColor: "#ffa500"
});

const PassResultChip = styled(Chip)({
    width: "130px",
    color: "#0b5f10",
    fontWeight: 500,
    backgroundColor: "#76d576"
});

const FailResultChip = styled(Chip)({
    width: "130px",
    color: "#b81a1a",
    fontWeight: 500,
    backgroundColor: "#eb7d7d"
});

const CancelledResultChip = styled(Chip)({
    width: "130px",
    fontWeight: 500
});

const CardCandidateSent = (props) => {
    let { listCandidatesSent, onClickSubmitCancelled, isBidding, idJobGroup, idJobRecruit } = props;
    // console.log(listCandidatesSent);

    const dispatch = useDispatch();

    const [openDialog, setOpenDialog] = useState({ status: false, candidate: null });
    const [openDrawer, setOpenDrawer] = useState({ status: false});

    const [isOpenDrawerEdit, setIsOpenDrawerEdit] = useState({ status: false, candidate: null });

    const displayTime = (time) => {
        let splitText = time.split(":");
        return dayjs().hour(parseInt(splitText[0])).minute(parseInt(splitText[1])).format("HH.mm");
    };

    const handleClickCancelled = async (candidate) => {
        setOpenDialog({ status: true, candidate: candidate });
    };

    const handleSubmitCancelled = async (params) => {
        const res = await dispatch(updateCancelSendingCandidate({ id: params.idCandidate, idManpower: params.idManpower }));
        setOpenDialog({ status: false, candidate: null });
        if (res.message === "success") {
            onClickSubmitCancelled();
            dispatch(getAllFreeUsersByIdVendor());
        }
    };

    const handleClickOpenDrawer = () => {
        setOpenDrawer({ status: true });
    };

    const handleCloseDrawer = () => {
        setOpenDrawer({ status: false });
        // setSelectedRow([]);
    };

    useEffect(() => {
        dispatch(getManager());
    }, []);

    const StatusCandidate = ({ row }) => {
        // const { idManpowerStatus } = row
        const { idStatus } = row
        if (idStatus === 1) {
            return <PassResultChip label={t("interviewPassed")} />
        } else if (idStatus === 2) {
            return <FailResultChip label={t("interviewFailed")} />
        } else if (idStatus === 3) {
            // return (
            //     <Tooltip
            //         title={
            //             <Box style={{ display: "flex", padding: "5px 10px" }}>
            //                 <ButtonBlue
            //                     variant="contained"
            //                     onClick={() => handleClickCancelled(row)}
            //                 >ยกเลิกการส่ง</ButtonBlue>
            //             </Box>
            //         }
            //         placement="top"
            //     >
            //         <WaitingProcessChip label="รอดำเนินการ" />
            //     </Tooltip>
            // )
            return <WaitingResultChip label={t("WaitingForInterview")} />
        } else if (idStatus === 4) {
            return <WaitingResultChip label={t("WaitingInterviewResults")} />
        } else if (idStatus === 5) {
            return <WaitingProcessChip label={t("Pending")} />
        } else if (idStatus === 6) {
            return <WaitingProcessChip label={t("selected")} style={{background:"#C8E6C9",color:"#388E3C"}}/>
        } else {
            return <Typography>{t("noStatus")}</Typography>
        }
    }

    const columnTable = [
        {
            field: "avatar&name",
            width: "300px",
            headerName: (
                <Box style={{ display: "flex", alignItems: "center" }}>
                    <i className="fa-solid fa-user-group" style={{ color: "#ffffff", fontSize: "1.1rem", marginRight: "10px" }} />
                    <Typography style={{ color: "#ffffff" }}>{t("FullName")}</Typography>
                </Box>
            ),
            format: (params) => (
                <Tooltip title="ดูประวัติ" placement="top-start">
                    <Box className="column-name" component={Link} to={`/manage/hunter/peer/profile/${params.idManpower}`}>
                        <Avatar sx={{ bgcolor: stringToColor(`${params.name} ${params.lastname}`) }} children={`${params.name[0]}${params.lastname[0]}`} />
                        <Box>
                            <Typography style={{ color: "#000000" }}>{`${params.name} ${params.lastname}`}</Typography>
                            <Typography style={{ color: "#919eab" }}>{`${params.telephoneMobile}`}</Typography>
                        </Box>
                    </Box>
                </Tooltip>
            )
        },
        {
            field: "status",
            width: "130px",
            headerName: (
                <Box style={{ display: "flex", alignItems: "center" }}>
                    <i className="fa-solid fa-user" style={{ color: "#ffffff", fontSize: "1.1rem", marginRight: "10px" }} />
                    <Typography style={{ color: "#ffffff" }}>{t("Status")}</Typography>
                </Box>
            ),
            format: (params) => {
                return (
                    <Box className="column-name">
                        {params.idStatus && <StatusCandidate row={params} />}
                    </Box>
                );
            },

        },
        ...isBidding ? [{
            field: "positionName",
            width: "240px",
            headerName: (
                <Box style={{ display: "flex", alignItems: "center" }}>
                    <i
                        className="fa-solid fa-briefcase"
                        style={{ color: "#ffffff", fontSize: "1.1rem", marginRight: "10px", marginTop: "2px" }}
                    />
                    <Typography style={{ color: "#ffffff" }}>{t("JobPosition")}</Typography>
                </Box>
            ),
            format: (params) => (
                <Box className="column-name">
                    <Typography>{getUserPosition(params)}</Typography>
                </Box>
            )
        }] : [],
        {
            field: "time",
            width: 150,
            headerName: (
                <Box style={{ display: "flex", alignItems: "center" }}>
                    <i className="fa-solid fa-clock" style={{ color: "#ffffff", fontSize: "1.1rem", marginRight: "10px", marginTop: "2px" }} />
                    <Typography style={{ color: "#ffffff" }}>{t("interviewTime")}</Typography>
                </Box>
            ),
            format: (params) => (
                <Box className="column-name">
                    <Typography>
                        {params.appointmentStartTime ? `${dayjs(params.appointmentDate).format('DD/MM/YYYY')} ${displayTime(params.appointmentStartTime)}` : "-"}
                    </Typography>
                </Box>
            )
        },
        {
            field: "place",
            width: 200,
            headerName: (
                <Box style={{ display: "flex", alignItems: "center" }}>
                    <i className="fa-solid fa-location-dot" style={{ color: "#ffffff", fontSize: "1.1rem", marginRight: "10px" }} />
                    <Typography style={{ color: "#ffffff" }}>{t("InterviewLocation")}</Typography>
                </Box>
            ),
            format: (params) => (
                <Box className="column-name">
                    <Typography>{params.appointmentPlace || "-"}</Typography>
                </Box>
            )
        },
        {
            field: "actions",
            width: 150,
            headerName: (
                <Box style={{ display: "flex", alignItems: "center" }}>
                    <i className="fa-solid fa-pen-to-square" style={{ color: "#ffffff", fontSize: "1.1rem", marginRight: "10px" }} />
                    <Typography style={{ color: "#ffffff" }}>{t("actions")}</Typography>
                </Box>
            ),
            format: (params) => (
                <Box className="column-name">
                    <IconButton
                        style={{ color: "#83D0F5" }}
                        onClick={() => {
                            // console.log(params);
                            setIsOpenDrawerEdit({ status: true, candidate: params });
                        }}
                    >
                        <i className="fa-solid fa-pen-to-square"></i>
                    </IconButton>
                </Box>
            )
        },
    ];

    return (
        <Fragment>
            <CardStyle>
                <StyledContent>
                    <Stack direction="row" justifyContent={"space-between"}>
                        <Typography variant="h5">{t("deployedPersonnel")}</Typography>
                        <ButtonBlue variant="outlined" startIcon={<SettingsIcon />} onClick={handleClickOpenDrawer}>
                            {t("manageInterviewTime")}
                        </ButtonBlue>
                    </Stack>
                    <Box style={{ margin: "20px 0" }}>
                        <TableTheme
                            columns={columnTable}
                            rows={listCandidatesSent || []}
                            textEmpty={t("NoApplicantsFound")}
                            minHeight={200}
                        />
                    </Box>
                </StyledContent>
            </CardStyle>
            {openDrawer.status && (
                <DrawerInterviewAppointment
                    open={openDrawer.status}
                    onClose={() =>  setOpenDrawer({ status: false })}
                    idJobRecruit={idJobRecruit}
                    listCandidate={listCandidatesSent}
                    idJobGroup={idJobGroup}
                //   type={props.match.params.type}
                //   setIsSuccess={setIsSuccess}
                //   handleCountDownTimeSet={handleCountDownTimeSet}
                />
            )}
            {openDialog.status &&
                <DialogCancelled
                    open={openDialog.status}
                    onClose={() => setOpenDialog({ status: false, candidate: null })}
                    candidate={openDialog.candidate}
                    handleSubmit={handleSubmitCancelled}
                />
            }
            {isOpenDrawerEdit.status &&
                <DrawerEditInterview
                    open={isOpenDrawerEdit.status}
                    onClose={() => setIsOpenDrawerEdit({ status: false, candidate: null })}
                    data={isOpenDrawerEdit.candidate}
                />
            }
        </Fragment>
    );
};

export default CardCandidateSent;