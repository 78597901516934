import ExcelJS from 'exceljs';
import { getUserCompany, getUserFirstName, getUserLastName, getUserPosition } from '../../../../../utils/userData';

export const EmployeeTaxRemittanceXlsx = async (t,data, year) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(t("EmployeeTaxRemittanceReport"));

    const headerValues = [
        t("EmployeeID"),
        t("FirstName"),
        t("LastName"),
        t("Position"),
        t("Company"),
        t("Division"),
        t("Department"),
        t("Section"),
        `${t("TaxSubmissionMonth")} ${t("Months.January")}`,
        `${t("TaxSubmissionMonth")} ${t("Months.February")}`,
        `${t("TaxSubmissionMonth")} ${t("Months.March")}`,
        `${t("TaxSubmissionMonth")} ${t("Months.April")}`,
        `${t("TaxSubmissionMonth")} ${t("Months.May")}`,
        `${t("TaxSubmissionMonth")} ${t("Months.June")}`,
        `${t("TaxSubmissionMonth")} ${t("Months.July")}`,
        `${t("TaxSubmissionMonth")} ${t("Months.August")}`,
        `${t("TaxSubmissionMonth")} ${t("Months.September")}`,
        `${t("TaxSubmissionMonth")} ${t("Months.October")}`,
        `${t("TaxSubmissionMonth")} ${t("Months.November")}`,
        `${t("TaxSubmissionMonth")} ${t("Months.December")}`,
    ];

    const headerRow = worksheet.addRow(headerValues);
    headerRow.height = 50;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    const colWidths = [
        { key: "employeeID", width: 30 },
        { key: "firstname", width: 30 },
        { key: "lastname", width: 30 },
        { key: "position", width: 30 },
        { key: "company", width: 30 },
        { key: "division", width: 30 },
        { key: "department", width: 30 },
        { key: "section", width: 30 },
        { key: "Tax_Jan", width: 40 },
        { key: "Tax_Feb", width: 40 },
        { key: "Tax_Mar", width: 40 },
        { key: "Tax_Apr", width: 40 },
        { key: "Tax_May", width: 40 },
        { key: "Tax_Jun", width: 40 },
        { key: "Tax_Jul", width: 40 },
        { key: "Tax_Aug", width: 40 },
        { key: "Tax_Sep", width: 40 },
        { key: "Tax_Oct", width: 40 },
        { key: "Tax_Nov", width: 40 },
        { key: "Tax_Dec", width: 40 },
    ];

    colWidths.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width;
    });

    function getItemTaxValue(taxData, month) {
        const item = taxData.find(entry => entry.monthPeriod === month);
        return item ? item.value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0.00";
    }

    data && data.forEach((item) => {
        const row = [
            item.employeeID ? item.employeeID : "-",
            getUserFirstName(item) ? getUserFirstName(item) : "-",
            getUserLastName(item) ? getUserLastName(item) : "-",
            getUserPosition(item) ? getUserPosition(item) : "-",
            getUserCompany(item) ? getUserCompany(item) : "-",
            item.divisionName ? item.divisionName : "-",
            item.departmentName ? item.departmentName : "-",
            item.sectionName ? item.sectionName : "-",
            year < 2024 ? item.Jan_tax ? item.Jan_tax.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : item.taxData ? getItemTaxValue(item.taxData.SUM_Tax, `${year}-01`) : "-" : "0.00",
            item.taxData ? getItemTaxValue(item.taxData.SUM_Tax, `${year}-02`) : "-", // February tax
            item.taxData ? getItemTaxValue(item.taxData.SUM_Tax, `${year}-03`) : "-", // March tax
            item.taxData ? getItemTaxValue(item.taxData.SUM_Tax, `${year}-04`) : "-", // April tax
            item.taxData ? getItemTaxValue(item.taxData.SUM_Tax, `${year}-05`) : "-", // May tax
            item.taxData ? getItemTaxValue(item.taxData.SUM_Tax, `${year}-06`) : "-", // June tax
            item.taxData ? getItemTaxValue(item.taxData.SUM_Tax, `${year}-07`) : "-", // July tax
            item.taxData ? getItemTaxValue(item.taxData.SUM_Tax, `${year}-08`) : "-", // August tax
            item.taxData ? getItemTaxValue(item.taxData.SUM_Tax, `${year}-09`) : "-", // September tax
            item.taxData ? getItemTaxValue(item.taxData.SUM_Tax, `${year}-10`) : "-", // October tax
            item.taxData ? getItemTaxValue(item.taxData.SUM_Tax, `${year}-11`) : "-", // November tax
            item.taxData ? getItemTaxValue(item.taxData.SUM_Tax, `${year}-12`) : "-", // December tax
        ];

        const excelRow = worksheet.addRow(row);

        const contentStyle = {
            font: { size: 18, name: 'TH SarabunPSK' },
            alignment: { horizontal: "center", vertical: 'middle' },
        };

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${t("EmployeeTaxRemittanceReport")}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
}