import React from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import { addLeaveEmployees } from "../../../../../../actions/leave";
import { getEmployeeLeaveQuotaByIdEmp, getLeaveRequestAllYear, getLeaveWithdrawEmployeeYear } from "../../../../../../actions/employee";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";
import { useTranslation } from "react-i18next";
import { getRoundNumber } from "../../../../../../utils";

const StyledRoot = styled("div")({
  padding: "16px",
  "& .wrap-footer-btn": {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 16,
  },
});

const StyledRow = styled("div")({
  marginBottom: 16,
});

const DialogConfirmLeave = (props) => {
  const {
    handleClose,
    data,
    handleChangeAlertType,
    handleOpenAlert,
    openConfirm,
    fetchingLeaveAvailable,
  } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrAfter);
  const { result: leaveTypeList } = useSelector((state) => state.leaveAvailable);
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const handleSubmit = async () => {
    const foundLeaveType = leaveTypeList.find(
      (item) => Number(item.idLeaveType) === Number(data.idLeaveType)
    );
    const formData = {
      idLeaveType: data.idLeaveType,
      description: data.description,
      start: dayjs(data.startDate).format("YYYY-MM-DD HH:mm:ss"),
      end: dayjs(data.endDate).format("YYYY-MM-DD HH:mm:ss"),
      idEmployees: data.idEmployees,
      used: foundLeaveType.isLeaveCompensate? data.hourUsed: data.used,
      quota: data.quota,
      balance: data.balance,
      remaining: data.remaining,
      idManager: data.idManager,
      idManagerGroup: data.idManagerGroup,
      isApprove: data.isApprove,
      isFullDay: data.isFullDay,
      isActive: 1,
      idHoliday: data.idHoliday? data.idHoliday: null,
      cc_email: data.cc_email? data.cc_email: null,
      fillInCreate: data.fillInCreate,
      fillInApprove: data.fillInApprove,
      approveDate: dayjs().format("YYYY-MM-DD HH:mm"),
    };

    const postData = new FormData();

    Object.keys(formData).map((k) => {
      postData.append(k, JSON.stringify(formData[k]));
    });

    if (data.file) {
      postData.append("file", data.file);
    }

    const result = await dispatch(addLeaveEmployees(postData));
    if (result) {
      if(result.status === 200){
        if (result.data.errorCode === "ERROR_DUPLICATED") {
          dispatch(openNotificationAlert({
            type: "info",
            message: t("DuplicateData")
          }))
        } else {
          dispatch(openNotificationAlert({
            type: "success",
            message: t("LeaveTransactionSuccessful")
          }))
          dispatch(getEmployeeLeaveQuotaByIdEmp(data.idEmployees));
          fetchingLeaveAvailable();
          dispatch(
            getLeaveRequestAllYear({
              filter: dayjs().format("YYYY"),
              idEmployees: employeeProfile.idEmployees,
            })
          );
          dispatch(
            getLeaveWithdrawEmployeeYear({
              filter: dayjs().format("YYYY"),
              employees: employeeProfile.idEmployees,
            })
          );      
          openConfirm(false);
          handleClose();
        }
      }
    } else {
      openConfirm(false);
      dispatch(openNotificationAlert({
        type: "error",
        message: `${t("AnErrorOccurred")} ${t("ContactAdmin")}`
      }))
    }
  };

  const renderLeaveType = () => {
    const found = leaveTypeList.find(
      (item) => item.idLeaveType === data.idLeaveType
    );
    return found ? found.name : "-";
  };

  return (
    <StyledRoot>
      <Typography variant="h6">{t("ConfirmLeaveTransaction")}</Typography>
      <div>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {t("LeaveType")}
          </Typography>
          <Typography variant="h6">
            {data && leaveTypeList && renderLeaveType()}
          </Typography>
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {t("StartDate")}
          </Typography>
          <Typography variant="h6">
            {data.leaveDayType === "fullday"
              ? dayjs(data.startDate).format("DD MMMM YYYY")
              : dayjs(data.startDate).format("DD MMMM YYYY HH:mm")}
          </Typography>
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {t("EndDate")}
          </Typography>
          <Typography variant="h6">
            {data.leaveDayType === "fullday"
              ? dayjs(data.endDate).format("DD MMMM YYYY")
              : dayjs(data.endDate).format("DD MMMM YYYY HH:mm")}
          </Typography>
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {t("TotalLeaveDays")}
          </Typography>
          <Typography variant="h6">
            {data.isLeaveCompensate
              ? `${getRoundNumber(data.hourUsed, 2, false)} ${t("Unit.Hours")}`
              : `${getRoundNumber(data.used, 2, false)} ${t("Unit.Days")} (${getRoundNumber(data.hourUsed, 2, false)} ${t("Unit.Hours")})`
            }
          </Typography>
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {t("RemainingLeaveEntitlement")}
          </Typography>
          <Typography variant="h6">
            {data.balance} {data.isLeaveCompensate? t("Unit.Hours"): t("Unit.Days")}
          </Typography>
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {t("Note")}
          </Typography>
          {data.description ? (
            <Typography variant="h6">{data.description}</Typography>
          ) : (
            <Typography
              variant="h6"
              color="text.secondary"
              style={{ fontStyle: "oblique" }}
            >
              {t("UnspecifiedReason")}
            </Typography>
          )}
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {t("AttachFile")}
          </Typography>
          {data.file ? (
            <div style={{ textAlign: "center", marginTop: "16px" }}>
              <img
                alt="attach-file"
                src={URL.createObjectURL(data.file)}
                style={{ maxWidth: 300, maxHeight: 128, objectFit: "contain" }}
              />
            </div>
          ) : (
            <Typography
              variant="h6"
              color="text.secondary"
              style={{ fontStyle: "oblique" }}
            >
              {t("NoAttachedFile")}
            </Typography>
          )}
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {t("CCtoManager")}
          </Typography>
          {data.cc_email? (
            <Typography style={{ fontStyle: "oblique", wordBreak: "break-word" }} variant="h6" whiteSpace="pre-line">
              {data.cc_email}
            </Typography>
          ): (
            <Typography
              variant="h6"
              color="text.secondary"
              style={{ fontStyle: "oblique" }}
            >
              {t("NoItems")}
            </Typography>
          )}
        </StyledRow>
      </div>
      <div className="wrap-footer-btn">
        <ButtonBlue
          size=""
          variant={"text"}
          color="secondary"
          onClick={() => {openConfirm(false)}}
        >
          {t("Cancel")}
        </ButtonBlue>
        <ButtonBlue onClick={handleSubmit} autoFocus variant={"contained"}>
          {t("ConfirmLeaveTransaction")}
        </ButtonBlue>
      </div>
    </StyledRoot>
  );
};

export default DialogConfirmLeave;
