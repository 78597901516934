import React, { useEffect, useState } from "react";
import { Autocomplete, Avatar, Box, Breadcrumbs, Container, Grid, MenuItem, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import { getTurnoverByCompany } from "../../../../actions/report";
import { Link } from "react-router-dom";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../actions/company";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import DatePickerCustom from "../../shared/date/datePicker";
import dayjs from "dayjs";
import CardStyle from "../../shared/general/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import LoadingGif from "../../assets/social-media.gif";
import AlertResponse from "../../shared/general/AlertResponse";
import { TurnoverDepartmentXlsx } from "./xlsx-export/turnover-department-xlsx";

const StyledRoot = styled(Box)({
    backgroundColor: "#FFFFFF !important",
    "& .MuiFilledInput-root": {
        height: 56,
        padding: "0px 12px",
        "& .MuiFilledInput-input": {
            padding: "7px 4px",
        },
    },
    "& .search-date-container": {
        marginTop: "24px",
        display: "flex",
        flexWrap: "nowrap",
        gap: "16px",
        "& .search-date-box": {
            maxWidth: "350px",
            width: "100%",
        },
        "& .search-button": {
            marginTop: "29px",
            display: "flex",
            alignItems: "center",
        },
    },

    ["@media only screen and (max-width: 600px)"]: {
        "& .search-date-container": {
            flexDirection: "column",
            "& .search-date-box": {
                maxWidth: "100%",
            },
            "& .search-button": {
                marginTop: "0",
                width: "100%",
            },
        },
    },
});

const StyledAutoComplete = styled(Autocomplete)({
    "& .MuiFilledInput-root": {
        padding: "13.5px 14px",
        paddingRight: "32px",
        "& input": {
            padding: 0
        }
    }
})

const StyledTextField = styled(TextField)({
    width: "100%",
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "& .MuiFilledInput-input": {
            padding: "7px 4px",
        },
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            "& button": {
                color: "#919EAB",
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&:after": {
            border: "none",
        },
        "&:before": {
            border: "none",
            borderBottomStyle: "none !important",
        }
    },
});

const StyledCellHeader = styled(TableCell)({
    borderBottom: "0px",
    color: "#637381",
    backgroundColor: "#f4f6f8",
    padding: 8,
    minWidth: 50,
    "& .MuiTypography-root": {
        color: "#637381",
        backgroundColor: "#f4f6f8",
        "&.weekend": {
            fontStyle: "oblique",
        },
        "&.workday": {
            fontWeight: 600,
        },
    },
    "&.sticky": {
        padding: 0,
        position: "sticky",
        left: 0,
        zIndex: 4,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        boxShadow: "10px 0px 10px #EEEEEE",
        "& .MuiTableCell-root": {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
        },
    },
    "&:last-of-type": {
        paddingRight: 16,
        // borderTopRightRadius: 8,
        // borderBottomRightRadius: 8,
    },
});

const StyledRowContent = styled(TableRow)({
    cursor: "pointer",
    textDecoration: "none",
    "&.MuiTableRow-hover:hover": {
        backgroundColor: "#f7f7f7",
        "& .sticky": {
            backgroundColor: "#f7f7f7",
        },
    },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
    borderBottom: "0px",
    padding: 8,
    minWidth: 50,
    "&.sticky": {
        position: "sticky",
        left: 0,
        backgroundColor: "#FFFFFF",
        boxShadow: "8px 20px 20px #EEEEEE",
    },
    "& .avatar": {
        minWidth: 240,
        "& .MuiAvatar-root": {
            marginRight: 8,
        },
        display: "flex",
        alignItems: "center",
    },
    "& .fullname": {
        fontWeight: 600,
    },
    "& .position": {
        color: theme.palette.text.secondary,
        fontSize: 14,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: 300,
    },
    "& .secondaryAction": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .location": {
            fontWeight: 600,
        },
    },
    "&:first-of-type": {
        paddingLeft: 8,
    },
    "&:last-of-type": {
        paddingRight: 16,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

export default function TurnoverDepartmentList() {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);

    const [turnoverList, setTurnoverList] = useState(null);
    const [selectedYear, setSelectedYear] = useState(new Date(dayjs()));
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedOption, setSelectedOption] = useState("Department");

    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

    const fetchedData = async () => {
        try {
            const response = await getTurnoverByCompany({
                year: dayjs(selectedYear).format("YYYY"),
                idCompany: selectedCompany && selectedCompany.idCompany,
                type: 2,
                options: selectedOption
            });

            if (response && response.data) {
                setTurnoverList(response.data);
            } else {
                setAlertType("error");
            };
        } catch (error) {
            console.error(error);
            setAlertType("error");
        };
    };

    useEffect(() => {
        if (selectedCompany && selectedYear && selectedOption) {
            if ((selectedOption === "Department") || (selectedOption === "Section")) {
                fetchedData();
            }
        }
    }, [selectedCompany, selectedYear, selectedOption]);

    useEffect(() => {
        dispatch(getAffiliateOrganization());
    }, []);

    useEffect(() => {
        if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
            const foundCompany = affiliateOrganizationList.find((x) => x.idCompany === userProfile.idCompany);
            if (foundCompany) {
                setSelectedCompany(foundCompany);
            } else {
                setSelectedCompany(affiliateOrganizationList[0]);
            };
        };
    }, [userProfile, affiliateOrganizationList]);

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    };

    const departmentColumns = [
        { id: "Department", label: `${t("Department")}`, minWidth: 170 },
        { id: "Level", label: `${t("Level")}`, minWidth: 100 },
        { id: "AvgEmp", label: `${t("AvgEmp")}`, minWidth: 170 },
        { id: "NumOfResigningEmp", label: `${t("NumOfResigningEmp")}`, minWidth: 170 },
        { id: "TurnoverRate", label: `%${t("TurnoverRate")}`, minWidth: 170 },
    ];

    const sectionColumns = [
        { id: "Section", label: `${t("Section")}`, minWidth: 170 },
        { id: "Level", label: `${t("Level")}`, minWidth: 100 },
        { id: "AvgEmp", label: `${t("AvgEmp")}`, minWidth: 170 },
        { id: "NumOfResigningEmp", label: `${t("NumOfResigningEmp")}`, minWidth: 170 },
        { id: "TurnoverRate", label: `%${t("TurnoverRate")}`, minWidth: 170 },
    ];

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <StyledRoot className="page">
            <Container
                maxWidth="lg"
                style={{ paddingTop: "24px", paddingBottom: "24px" }}
            >
                <Box marginBottom="24px">
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        <Link
                            style={{ textDecoration: "none", color: "inherit" }}
                            to={"/employees-report"}
                        >
                            {`${t("AllReports")}`}
                        </Link>
                        <Typography color="text.primary">
                            {`${t("TurnoverRateReporByDepartment")}`}
                        </Typography>
                    </Breadcrumbs>
                </Box>

                <Typography variant="h4">{t("TurnoverRateReporByDepartment")}</Typography>

                <Box marginTop="24px">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={2}>
                                    <Typography
                                        fontWeight="600"
                                        fontSize="14px"
                                        paddingBottom="8px"
                                        color="text.third"
                                    >
                                        {t("Year")}
                                    </Typography>
                                    <DatePickerCustom
                                        inputFormat="YYYY"
                                        value={selectedYear}
                                        openTo="year"
                                        views={["year"]}
                                        onChange={(newValue) => {
                                            setSelectedYear(newValue);
                                        }}
                                        disableFuture
                                        renderInput={(params) => (
                                            <TextFieldTheme
                                                {...params}
                                                variant="filled"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    readOnly: true
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Typography
                                        fontWeight="600"
                                        fontSize="14px"
                                        paddingBottom="8px"
                                        color="text.third"
                                    >
                                        {t("Company")}
                                    </Typography>
                                    <SelectAffiliateCompany
                                        options={affiliateOrganizationList}
                                        value={selectedCompany}
                                        onChange={(_, value) => {
                                            onChangeCompany(value);
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    <Typography
                                        fontWeight="600"
                                        fontSize="14px"
                                        paddingBottom="8px"
                                        color="text.third"
                                    >
                                        {t("ChooseField")}
                                    </Typography>
                                    <StyledTextField
                                        variant="filled"
                                        select
                                        value={selectedOption}
                                        onChange={handleSelectChange}
                                    >
                                        <MenuItem value="Department">{t("Department")}</MenuItem>
                                        <MenuItem value="Section">{t("Section")}</MenuItem>
                                    </StyledTextField>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

                <Box marginTop="24px">
                    <CardStyle>
                        <Box padding="24px">
                            <Box>
                                <Box
                                    marginBottom="16px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                >
                                    <ButtonBlue
                                        variant="outlined"
                                        startIcon={<DownloadRoundedIcon />}
                                        onClick={() => TurnoverDepartmentXlsx(selectedOption, t, turnoverList)}
                                        disabled={(turnoverList && turnoverList.length < 1) || !turnoverList}
                                    >
                                        {t("Download")}
                                    </ButtonBlue>
                                </Box>
                                <Box style={{ display: "flex", position: "relative" }}>
                                    <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead
                                                sx={{
                                                    position: "sticky",
                                                    top: 0,
                                                    zIndex: 1,
                                                }}
                                            >
                                                <TableRow>
                                                    {selectedOption === "Department" ? (
                                                        <>
                                                            {departmentColumns.map((column) => (
                                                                <StyledCellHeader
                                                                    key={column.id}
                                                                    align="center"
                                                                    style={{ minWidth: column.minWidth }}
                                                                >
                                                                    {column.label}
                                                                </StyledCellHeader>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {sectionColumns.map((column) => (
                                                                <StyledCellHeader
                                                                    key={column.id}
                                                                    align="center"
                                                                    style={{ minWidth: column.minWidth }}
                                                                >
                                                                    {column.label}
                                                                </StyledCellHeader>
                                                            ))}
                                                        </>
                                                    )}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {turnoverList &&
                                                    turnoverList.length > 0 &&
                                                    turnoverList
                                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map((item, index) => (
                                                            <StyledRowContent key={index}>
                                                                <StyledCellContent align="center">
                                                                    {selectedOption === "Department" && (i18n.resolvedLanguage === "th" ? item.departmentName : (item.departmentName_EN ? item.departmentName_EN : item.departmentName))}
                                                                    {selectedOption === "Section" && (i18n.resolvedLanguage === "th" ? item.sectionName : (item.sectionName_EN ? item.sectionName_EN : item.sectionName))}
                                                                </StyledCellContent>
                                                                <StyledCellContent align="center">
                                                                    {/* {selectedOption === "Department" ? t("Department") : t("Section")} */}
                                                                    {t(`${selectedOption}`)}
                                                                </StyledCellContent>
                                                                <StyledCellContent align="center">
                                                                    {item.avgCurrentEmp > 0 ? item.avgCurrentEmp.toFixed(2) : 0}
                                                                </StyledCellContent>
                                                                <StyledCellContent align="center">
                                                                    {item.totalResigningEmp || 0}
                                                                </StyledCellContent>
                                                                <StyledCellContent align="center">
                                                                    <Typography fontWeight={500} color={"red"}>
                                                                        {item.avgCurrentEmp > 0 ? `${((item.totalResigningEmp / item.avgCurrentEmp) * 100).toFixed(2)}%` : "0.00%"}
                                                                    </Typography>
                                                                </StyledCellContent>
                                                            </StyledRowContent>
                                                        ))}
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={turnoverList && turnoverList.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </TableContainer>
                                </Box>
                            </Box>
                            {/* ) : (
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <img src={LoadingGif} style={{ width: 100, height: 100 }} />
                                </Box>
                            )} */}
                        </Box>
                    </CardStyle>
                </Box>
            </Container >
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </StyledRoot >
    );
}