import React, { Fragment, useState } from "react";
import { Controller, useForm } from 'react-hook-form';

import { Box, Grid, MenuItem, styled, Typography, Rating } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';


import DrawerCustom from "../../../../../shared/general/Drawer";
import TextFieldTheme from "../../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../../shared/general/ButtonBlue";
import { useDispatch } from 'react-redux';
import { updateManpower, getManpowerById } from "../../../../../../../actions/manpower";
import { Margin } from "@mui/icons-material";


const DrawerName = (props) => {
  const { open, onClose, data, idManpower } = props;

  const [rating , setRating] = useState(4);
  const [hoverstar , setHoverstar] = useState(null);

  const { handleSubmit, control, formState: { errors, dirtyFields }, } = useForm({
    defaultValues: {
      title: data.title || "",
      name: data.name || "",
      lastname: data.lastname || "",
      rating: data.rating || "0",
    }
  });

  const dispatch = useDispatch();

  const HandlerSubmit = async (submitData) => {
    const formData = {};

    for (const key in dirtyFields) {
      formData[key] = submitData[key];
    }

    dispatch(updateManpower(idManpower, formData)).then(res => {
      dispatch(getManpowerById(idManpower));
    })
    // console.log(idManpower, formData)
    onClose();
  }

  const ChangeRating = (rate) => {
    setRating(rate);
  }

  return (
    <DrawerCustom
      open={open}
      title={"แก้ไขชื่อ-นามสกุล เรตติ้ง"}
      anchor="right"
    >
      <Box style={{ width: 400, padding: 16 }}>
        <form onSubmit={handleSubmit(HandlerSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="title"
                control={control}
                rules={{
                  required: { value: true, message: "กรุณากรอกคำนำหน้า" }
                }}
                render={({ field }) => (
                  <Fragment>
                  <Typography gutterBottom sx={{ color: "#ffffff" }}>คำนำหน้า</Typography>
                  <TextFieldTheme
                    {...field}
                    placeholder="คำนำหน้า"
                    // fullWidth
                    helperText={errors && errors.title && errors.title.message}
                    error={errors && errors.title ? true: false}
                    select
                  >
                    <MenuItem value="นาย">นาย</MenuItem>
                    <MenuItem value="นาง">นาง</MenuItem>
                    <MenuItem value="นางสาว">นางสาว</MenuItem>
                  </TextFieldTheme>
                  </Fragment>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: { value: true, message: "กรุณากรอกชื่อ" }
                }}
                
                render={({ field }) => (
                  <Fragment>
                  <Typography gutterBottom sx={{ color: "#ffffff" }}>ชื่อ</Typography>
                  <TextFieldTheme
                    {...field}
                    placeholder="ชื่อ"
                    inputProps={{
                      minLength: 1,
                      maxLength: 200,
                    }}
                    fullWidth
                    autoComplete="manpower-firstname"
                    helperText={errors && errors.name && errors.name.message}
                    error={errors && errors.name ? true : false}
                  />
                  </Fragment>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="lastname"
                control={control}
                rules={{
                  required: { value: true, message: "กรุณากรอกนามสกุล" }
                }}
                render={({ field }) => (
                  <Fragment>
                  <Typography gutterBottom sx={{ color: "#ffffff" }}>นามสกุล</Typography>
                  <TextFieldTheme
                    {...field}
                    placeholder="นามสกุล"
                    inputProps={{
                      minLength: 1,
                      maxLength: 200,
                    }}
                    fullWidth
                    autoComplete="manpower-lastname"
                    helperText={errors && errors.lastname && errors.lastname.message}
                    error={errors && errors.lastname ? true : false}
                  />
                  </Fragment>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="rating"
                control={control}
                render={({ field }) => (
                  <Fragment>
                    <div className="star-rating" style={{margin:"20px 0px"}}>
                    <Rating
                      name="rating"
                      value={parseInt(field.value)}
                      onChange={(event, value) => field.onChange(value)}
                      size="large"
                    />
                    </div>
                  </Fragment>
                )}
              />
              {errors.rating && <Typography color="error">{errors.rating.message}</Typography>}
            </Grid>
            <Grid item xs={12}>
              <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <ButtonBlue
                  variant="outlined"
                  onClick={onClose}
                >ยกเลิก</ButtonBlue>
                <ButtonBlue
                  variant="contained"
                  type="submit"
                  disabled={Object.keys(dirtyFields).length <= 0}
                >ยืนยัน</ButtonBlue>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </DrawerCustom>
  );
};

export default DrawerName;