import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useFormik } from "formik";
import * as yup from "yup";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";

import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import DrawerCustom from "../../../shared/general/Drawer";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import { addAddition } from "../../../../../actions/paytypes";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

const StyledContent = styled("div")({
  width: 350,
  padding: 24,
  "& .text-code": {
    marginBottom: 16,
    width: "100%",
    "& .MuiOutlinedInput-input": {
      paddingLeft: "0 !important",
    },
  },
  "& .text-name": {
    marginBottom: 16,
    width: "100%",
  },
  "& .radio": {
    marginBottom: 8,
    width: "100%",
  },
  "& .MuiFormControl-root": {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
});

const StyledFooter = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogNew = (props) => {
  const { open, selectedValue, mode, handleClose, handleSubmit, isReadOnly } =
    props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    payTypes: yup.string().required(`${t("ThisFieldIsRequired")}`),
    name: yup.string().required(`${t("ThisFieldIsRequired")}`),
    code: yup
      .string()
      .min(4, `${t("Length4-6Characters")}`)
      .max(8, `${t("Length4-6Characters")}`)
      .required(`${t("ThisFieldIsRequired")}`),
    isTaxable: yup.string().required(`${t("SelectData")}`),
    isRecurring: yup.string().required(`${t("SelectData")}`),
    isPercentage: yup.string().required(`${t("SelectData")}`),
    isSso: yup.string().required(`${t("SelectData")}`),
    isPf: yup.string().required(`${t("SelectData")}`),
  });

  const formik = useFormik({
    initialValues: {
      payTypes: "addition",
      preCode: "AR-",
      code: "",
      name: "",
      name_EN: "",
      isTaxable: "",
      isRecurring: "1",
      isPercentage: "",
      isSso: "",
      isPf: "",
      amount: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    if (open && mode === "new") {
      formik.resetForm();
    }
  }, [open, mode]);

  useEffect(() => {
    renderTextCode();
  }, [formik.values]);

  useEffect(() => {
    if (selectedValue) {
      formik.resetForm();
      formik.setFieldValue(
        "payTypes",
        selectedValue.hasOwnProperty("idAddition") ? "addition" : "deduction"
      );
      formik.setFieldValue("preCode", selectedValue.code.split("-")[0]);
      formik.setFieldValue("code", selectedValue.code.split("-")[1]);
      formik.setFieldValue("name", selectedValue.name);
      formik.setFieldValue("name_EN", selectedValue.name_EN);
      formik.setFieldValue("isRecurring", selectedValue.isRecurring);
      formik.setFieldValue("isTaxable", selectedValue.isTaxable);
      formik.setFieldValue("isPercentage", selectedValue.isPercentage);
      formik.setFieldValue("isSso", selectedValue.isSso);
      formik.setFieldValue("isPf", selectedValue.isPf);
    }
  }, [selectedValue]);

  const renderTextCode = () => {
    if (formik.values.payTypes === "addition") {
      if (parseInt(formik.values.isRecurring) === 1) {
        formik.setFieldValue("preCode", "AR-");
      } else {
        formik.setFieldValue("preCode", "AO-");
      }
    } else {
      if (parseInt(formik.values.isRecurring) === 1) {
        formik.setFieldValue("preCode", "DR-");
      } else {
        formik.setFieldValue("preCode", "DO-");
      }
    }
  };

  return (
    <DrawerCustom
      open={open}
      onClose={handleClose}
      title={mode === "new" ? `${t("Create")}` : `${t("EditData")}`}
      anchor={"right"}
    >
      <StyledContent>
        <div>
          {selectedValue && mode !== "new" && (
            <Typography color="text.third" style={{ fontStyle: "oblique" }}>
              Last Update:{" "}
              {selectedValue.username === null
                ? "-"
                : `${selectedValue.updateDateText} (${selectedValue.username}) `}
            </Typography>
          )}
        </div>
        <form onSubmit={formik.handleSubmit}>
          <FormControl
            disabled={mode === "edit"}
            component="fieldset"
            className="radio"
            error={formik.touched.payTypes && Boolean(formik.errors.payTypes)}
          >
            <FormLabel component="legend">{`${t("PayrunType")}`}</FormLabel>
            <RadioGroup
              row
              aria-label="paytypes"
              onChange={formik.handleChange}
              name="payTypes"
              value={formik.values.payTypes}
            >
              <FormControlLabel
                value="addition"
                control={<Radio />}
                label={`${t("Addition")}`}
              />
              <FormControlLabel
                value="deduction"
                control={<Radio />}
                label={`${t("Deduction")}`}
              />
            </RadioGroup>
            {Boolean(formik.errors.payTypes) && (
              <FormHelperText>{formik.errors.payTypes}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={mode === "edit"}
            value={formik.values.isRecurring}
            component="fieldset"
            className="radio"
            error={
              formik.touched.isRecurring && Boolean(formik.errors.isRecurring)
            }
          >
            <FormLabel component="legend">{`${t(
              "ConsecutiveCalculation"
            )}`}</FormLabel>
            <RadioGroup
              row
              aria-label="isRecurring"
              onChange={formik.handleChange}
              name="isRecurring"
              value={formik.values.isRecurring}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label={`${t("Yes")}`}
              />
              <FormControlLabel
                value={0}
                control={<Radio />}
                label={`${t("No")}`}
              />
            </RadioGroup>
            {formik.touched.isRecurring &&
              Boolean(formik.errors.isRecurring) && (
                <FormHelperText>{formik.errors.isRecurring}</FormHelperText>
              )}
          </FormControl>
          <TextFieldTheme
            className="text-code"
            label={`${t("Code")}`}
            variant={"outlined"}
            name="code"
            disabled={mode === "edit"}
            value={formik.values.code}
            onChange={formik.handleChange}
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {formik.values.preCode}
                </InputAdornment>
              ),
            }}
          />
          <TextFieldTheme
            className="text-name"
            label={`${t("ResignationName")}`}
            variant={"outlined"}
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextFieldTheme
            className="text-name"
            label={`${t("ResignationNameEN")}`}
            variant={"outlined"}
            name="name_EN"
            value={formik.values.name_EN}
            onChange={formik.handleChange}
            error={formik.touched.name_EN && Boolean(formik.errors.name_EN)}
            helperText={formik.touched.name_EN && formik.errors.name_EN}
          />
          <FormControl
            component="fieldset"
            className="radio"
            value={formik.values.isTaxable}
            error={formik.touched.isTaxable && Boolean(formik.errors.isTaxable)}
          >
            <FormLabel component="legend">{`${t("Calculate")} ${t(
              "Tax"
            )} ?`}</FormLabel>
            <RadioGroup
              row
              aria-label="isTaxable"
              onChange={formik.handleChange}
              name="isTaxable"
              value={formik.values.isTaxable}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label={`${t("Yes")}`}
              />
              <FormControlLabel
                value={0}
                control={<Radio />}
                label={`${t("No")}`}
              />
            </RadioGroup>
            {formik.touched.isTaxable && Boolean(formik.errors.isTaxable) && (
              <FormHelperText>{formik.errors.isTaxable}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            component="fieldset"
            className="radio"
            value={formik.values.isSso}
            error={formik.touched.isSso && Boolean(formik.errors.isSso)}
          >
            <FormLabel component="legend">{`${t("CalculateSSO")} ?`}</FormLabel>
            <RadioGroup
              row
              aria-label="isSso"
              onChange={formik.handleChange}
              name="isSso"
              value={formik.values.isSso}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label={`${t("Yes")}`}
              />
              <FormControlLabel
                value={0}
                control={<Radio />}
                label={`${t("No")}`}
              />
            </RadioGroup>
            {formik.touched.isSso && Boolean(formik.errors.isSso) && (
              <FormHelperText>{formik.errors.isSso}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            component="fieldset"
            className="radio"
            value={formik.values.isPf}
            error={formik.touched.isPf && Boolean(formik.errors.isPf)}
          >
            <FormLabel component="legend">{`${t("Calculate")} ${t(
              "ProvidentFund"
            )} ?`}</FormLabel>
            <RadioGroup
              row
              aria-label="isPf"
              onChange={formik.handleChange}
              name="isPf"
              value={formik.values.isPf}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label={`${t("Yes")}`}
              />
              <FormControlLabel
                value={0}
                control={<Radio />}
                label={`${t("No")}`}
              />
            </RadioGroup>
            {formik.touched.isPf && Boolean(formik.errors.isPf) && (
              <FormHelperText>{formik.errors.isPf}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            component="fieldset"
            className="radio"
            value={formik.values.isPercentage}
            error={
              formik.touched.isPercentage && Boolean(formik.errors.isPercentage)
            }
          >
            <FormLabel component="legend">{`${t("CalculateBy")}`}</FormLabel>
            <RadioGroup
              row
              aria-label="isPercentage"
              onChange={formik.handleChange}
              name="isPercentage"
              value={formik.values.isPercentage}
            >
              <FormControlLabel
                value={0}
                control={<Radio />}
                label={`${t("TotalAmount")}`}
              />
              <FormControlLabel
                value={1}
                control={<Radio />}
                label={`% ${t("Salary")}`}
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label={`% ${t("Salary")} + OT`}
              />
              <FormControlLabel
                value={3}
                control={<Radio />}
                label={`% Total Earning`}
              />
              <FormControlLabel
                value={4}
                control={<Radio />}
                label={`Total Earning over`}
              />
            </RadioGroup>
            {formik.touched.isPercentage &&
              Boolean(formik.errors.isPercentage) && (
                <FormHelperText>{formik.errors.isPercentage}</FormHelperText>
              )}
          </FormControl>
          {formik.values.isPercentage !== "0" &&
            formik.values.isPercentage !== "" && (
              <TextFieldTheme
                className="text-name"
                variant={"outlined"}
                name="amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
              />
            )}
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleClose}>
              {`${t("Cancel")}`}
            </ButtonBlue>
            <ButtonBlue type="submit" variant="contained" disabled={isReadOnly}>
              {`${t("SaveData")}`}
            </ButtonBlue>
          </StyledFooter>
        </form>
      </StyledContent>
    </DrawerCustom>
  );
};

export default DialogNew;
