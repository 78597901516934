import React, { useEffect, useState } from "react";
import { Avatar, Box, Typography, Container, styled, Grid, Chip } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/th";
import { useDispatch, useSelector } from "react-redux";
import { getKpiEvaluation } from "../../../../../actions/kpi";
import { useHistory } from "react-router-dom";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import TableCustom from "../../../shared/tableCustom";
import { useTranslation } from "react-i18next";
import { getUserFullName,getUserPosition } from "../../../../../utils/userData";

dayjs.locale("th");

const StyledRoot = styled(Box)({});

const GridStyled = styled(Grid)({
  backgroundColor: "#E6EFF5",
  padding: "16px",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "16px",
  height: "70px",
});

const StatusShow = (row, isManager) => {
  const isBeforeEndDate = dayjs().isBefore(dayjs(row.endDate));
  const {t,i18n} = useTranslation();
  let label, backgroundColor;

  if (isManager) {
    label = isBeforeEndDate
      ? t("NotYetTime")
      : row.managerEvaluate === null
      ? t("WaitingForEvaluation")
      : t("Evaluated");
    backgroundColor = isBeforeEndDate
      ? "#e5e5e5"
      : row.managerEvaluate === null
      ? "#83B4FF"
      : "#50B498";
  } else {
    label = isBeforeEndDate
      ? t("NotYetTime")
      : row.employeeEvaluate === null
      ? t("WaitingForEvaluation")
      : t("Evaluated");
    backgroundColor = isBeforeEndDate
      ? "#e5e5e5"
      : row.employeeEvaluate === null
      ? "#83B4FF"
      : "#50B498";
  }

  return (
    <Chip
      label={label}
      sx={{
        backgroundColor,
        color: "#fff",
        borderRadius: "16px",
        minWidth: "140px",
        fontSize: "16px",
        justifyContent: "center",
      }}
    />
  );
};

const KpiEvaluationList = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: kpiPlanStore } = useSelector((state) => state.kpiEvaluation);
  const isUser = userProfile && userProfile.roles.includes("ROLE_USER");

  const columns = [
    !isUser && {
      name: t("FullName"),
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>
              {getUserFullName(row)}
            </Typography>
            <Typography color="text.third" fontSize="14px">
              {getUserPosition(row)}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: t("KPIName"),
      minWidth: "200px",
      width: "200px",
      cellRender: (row) => (
        <Box>
          <Typography>{row.kpiName}</Typography>
        </Box>
      ),
    },
    {
      name: t("Weight"),
      headerTextAlign: "center",
      minWidth: "100px",
      width: "100px",
      cellTextAlign: "center",
      cellRender: (row) => (
        <Box>
          <Typography>{row.weight}</Typography>
        </Box>
      ),
    },
    {
      name: t("EndDate"),
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellTextAlign: "center",
      cellRender: (row) => (
        <Typography>
          {dayjs(row.endDate).format(
            i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
          )}
        </Typography>
      ),
    },
    {
      name: t("Status"),
      headerTextAlign: "center",
      minWidth: "110px",
      width: "110px",
      cellTextAlign: "center",
      cellRender: (row) => {
        const isManager = userProfile && userProfile.roles && !userProfile.roles.includes("ROLE_USER");
        return StatusShow(row, isManager);
      },
    },    
    {
      name: t("Evaluate"),
      headerTextAlign: "center",
      minWidth: "110px",
      width: "110px",
      cellTextAlign: "center",
      cellRender: (row) => {
        const isBeforeEndDate = dayjs().isBefore(dayjs(row.endDate));
        const isManager = userProfile && userProfile.roles && !userProfile.roles.includes("ROLE_USER");
        let buttonLabel;
        if (isManager) {
          buttonLabel = row.managerEvaluate !== null ? t("ViewDescriptions") : t("Evaluate");
        } else {
          buttonLabel = row.employeeEvaluate !== null ? t("ViewDescriptions") : t("Evaluate");
        }
    
        return (
          <ButtonBlue
            variant="contained"
            disabled={isBeforeEndDate}
            sx={{
              backgroundColor: isBeforeEndDate ? "#e5e5e5" : undefined,
              width: "100%", 
              height: "40px", 
              maxWidth: "110px", 
            }}
            onClick={() => {
              if (!isBeforeEndDate) {
                history.push({
                  pathname: "/kpi-evaluation",
                  state: { planList: row },
                });
              }
            }}
          >
            {buttonLabel}
          </ButtonBlue>
        );
      },
    }
    ,      
  ].filter(Boolean); 

  useEffect(() => {
    dispatch(getKpiEvaluation());
  }, [dispatch]);

  return (
    <StyledRoot className="page">
      <Container>
        <GridStyled container justifyContent="space-between" alignItems="center" mt={2}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" className="kpi" sx={{ ml: 1, fontSize: "30px" }}>
             {t("KPIEvaluation")}
            </Typography>
          </Box>
        </GridStyled>
        <div className="cardPlan">
          <Box padding="2px 0" minHeight="700px">
            <Container maxWidth="lg">
              <Box marginLeft={"auto"} marginRight={"auto"}>
                {kpiPlanStore ? (
                  <TableCustom columns={columns} rows={kpiPlanStore} />
                ) : (
                  <div>
                    <Box
                      height="120px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="h6">
                        <FindInPageIcon />
                        {t("NoData")}
                      </Typography>
                    </Box>
                  </div>
                )}
              </Box>
            </Container>
          </Box>
        </div>
      </Container>
    </StyledRoot>
  );
};

export default KpiEvaluationList;