import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Container,
  Grid,
  Tab,
  Tabs,
  Table,
  TablePagination,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Badge,
  Box,
  Button,
  Chip,
  CardContent,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DatePickerCustom from "../../../../shared/date/datePicker";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import CardStyle from "../../../../shared/general/Card";
import Backdrop from "../../../../shared/general/Backdrop";

import SearchIcon from "@mui/icons-material/Search";

import dayjs from "dayjs";
import utils from "../../../../../../utils";
import SelectEmployees from "../../select-employees";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import {
  getFilteredWelfareExpenseHistory,
  getWelfareExpenseHistory,
} from "../../../../../../actions/welfare-expense";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 24,
  },
  "& .head": {
    paddingTop: 8,
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .head-2": {
    paddingTop: 8,
    marginBottom: 24,
    marginTop: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .wrap-sum-amount": {
    marginBottom: 16,
  },
});

const StyledTable = styled(Table)({
  "& .MuiTableCell-root": {
    textAlign: "center",
    "&.nothing": {
      padding: 32,
    },
  },
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
  },
  "& .tableCellHead:first-of-type": {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "& .cellAction": {
    width: 48,
    padding: "0px 0px 0px 4px",
  },
  "& .tableCellHead-document-no": {
    width: 140,
  },
  "& .tableCellHead-document-name": {
    textAlign: "left",
    //width: 250,
  },
  "& .tableCellHead-document-amount": {
    width: 120,
  },
  "& .tableCellHead-document-status": {
    width: 140,
  },
  "& .tableCellHead:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  "& .tableCellBody-document-name": {
    textAlign: "left",
  },

  "& .tableCellWeekend": {
    color: "#959595",
    fontStyle: "oblique",
  },
  "& .cellStatusAction": {
    textAlign: "left",
    "& .wrap-status": {
      display: "flex",
      "& .item": {
        marginRight: 4,
      },
    },
  },
  "& .cellRequest": {},
  "& .cellStartStop": {
    minWidth: 80,
    "& .MuiButtonBase-root": {
      marginRight: 2,
    },
  },
  "& .rowLink": {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(243, 246, 248, 0.5)",
    },
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 500,
  marginButtom: 24,
});

const StyledBoxSearch = styled(Box)({
  marginTop: 20,
  marginBottom: 24,
});

const WelfareHistoryPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: welfareBilling } = useSelector(
    (state) => state.welfareBilling
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [date, setDate] = useState(dayjs().format("YYYY/MM/DD"));
  const [idEmployees, setIdEmployees] = useState(null);

  useEffect(() => {
    dispatch(getWelfareExpenseHistory());
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickSearch = () => {
    const data = {
      idEmployees: idEmployees,
      date: date,
    };
    dispatch(getFilteredWelfareExpenseHistory(data));
    setIdEmployees(null);
    setDate(null);
  };

  return (
    <div>
      {welfareBilling ? (
        <Container maxWidth="lg">
          <div className="head">
            <Typography variant="h4">{t("WelfareExpenseHistory")}</Typography>
          </div>
          <Box>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={12} md={5}>
                <SelectEmployees
                  setState={setIdEmployees}
                  idEmployees={idEmployees}
                  isUseForm={false}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <StyledBoxSearch>
                  <StyledHeadLabel variant="subtitle">
                    {t("SelectYear")}
                  </StyledHeadLabel>
                  <DatePickerCustom
                    value={date}
                    views={["year"]}
                    inputFormat="YYYY"
                    onChange={(e) => {
                      setDate(dayjs(e).format("YYYY/MM/DD"));
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme
                        fullWidth
                        {...params}
                        sx={{ marginTop: 2 }}
                      />
                    )}
                  />
                </StyledBoxSearch>
              </Grid>
              <Grid item xs={12} md={2}>
                <ButtonBlue
                  variant="contained"
                  size="small"
                  onClick={() => {
                    handleClickSearch();
                  }}
                  disabled={idEmployees === null || date === "Invalid Date"}
                  startIcon={<SearchIcon />}
                >
                  {t("Search")}
                </ButtonBlue>
              </Grid>
            </Grid>
          </Box>
          <CardStyle>
            <CardContent>
              <TableContainer>
                <StyledTable aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={`tableCellHead tableCellHead-document-no`}
                      >
                        {t("DocumentNumber")}
                      </TableCell>
                      <TableCell
                        className={`tableCellHead tableCellHead-document-name`}
                      >
                        {t("FullName")}
                      </TableCell>
                      <TableCell
                        className={`tableCellHead tableCellHead-document-name`}
                      >
                        {t("TitleName")}
                      </TableCell>
                      <TableCell
                        className={`tableCellHead tableCellHead-document-amount`}
                      >
                        {t("TotalNetWorth")}
                      </TableCell>
                      <TableCell
                        className={`tableCellHead tableCellHead-document-amount`}
                      >
                        เบิกได้
                      </TableCell>
                      <TableCell
                        className={`tableCellHead tableCellHead-document-amount`}
                      >
                        {t("ExcessWithdrawn")}
                      </TableCell>
                      <TableCell
                        className={`tableCellHead tableCellHead-document-amount`}
                      >
                        {t("LastModifiedDate")}
                      </TableCell>

                      {/* <TableCell
                        className={`tableCellHead tableCellHead-document-status`}
                      >
                        สถานะ
                      </TableCell> */}
                    </TableRow>
                  </TableHead>

                  {welfareBilling && welfareBilling.length > 0 ? (
                    <TableBody>
                      {(rowsPerPage > 0
                        ? welfareBilling.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : welfareBilling
                      ).map((row, index) => (
                        <TableRow
                          key={row.idExpense}
                          style={{ color: "#37474f" }}
                        >
                          <TableCell>{row.documentId}</TableCell>
                          <TableCell className="tableCellBody-document-name">
                            {i18n.resolvedLanguage === "th"
                              ? `${row.firstname_TH} ${row.lastname_TH}`
                              : `${row.firstname_EN} ${row.lastname_EN}`}
                          </TableCell>
                          <TableCell className="tableCellBody-document-name">
                            {row.name}
                          </TableCell>
                          <TableCell>
                            {utils.numberWithCommas(parseFloat(row.net))}{" "}
                            {t("Baht")}
                          </TableCell>
                          <TableCell>
                            {utils.numberWithCommas(parseFloat(row.withdrawal))}{" "}
                            {t("Baht")}
                          </TableCell>
                          <TableCell>
                            {utils.numberWithCommas(parseFloat(row.difference))}{" "}
                            {t("Baht")}
                          </TableCell>
                          <TableCell>
                            {dayjs(row.lastUpdateDate).format("DD/MM/BBBB")}
                          </TableCell>
                          {/* <TableCell>{row.expenseStatus}</TableCell> */}
                          {/* <TableCell>
                          <ButtonView
                            startIcon={EditIcon}
                            onClick={() => {
                              handleClick(
                                row.idExpense,
                                row.type,
                                row.expenseType
                              );
                            }}
                          >
                            ตรวจสอบ
                          </ButtonView>
                        </TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableCell className="nothing" colSpan={6}>
                        {t("NoData")}
                      </TableCell>
                    </TableBody>
                  )}
                </StyledTable>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[
                  10,
                  20,
                  30,
                  40,
                  50,
                  { label: t("All"), value: -1 },
                ]}
                component="div"
                count={welfareBilling && welfareBilling.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${
                            count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
              />
            </CardContent>
          </CardStyle>
        </Container>
      ) : (
        <Backdrop open={true} />
      )}
    </div>
  );
};

export default WelfareHistoryPage;
