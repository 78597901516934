import React from "react";
import Chip from "@mui/material/Chip";
import StarRateIcon from '@mui/icons-material/StarRate';

const RatingChip = ({ rating }) => {
  return (
    <Chip
      icon={<StarRateIcon />}
      label={rating}
      sx={{
        height: "100%",
        backgroundColor: "#fff9db",
        "& .MuiSvgIcon-root": {
          color: "rgb(255, 221, 64)",
        },
        "& .MuiChip-label": {
          lineHeight: 1,
          fontWeight: "500",
          fontSize: "20px",
        },
      }}
    />
  );
};

export default RatingChip;
