import AccountService from "../services/account.service";

export const forgetPassword = (formData) => async () => {
  try {
    const res = await AccountService.forgetPassword(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const verifyResetPassword = (query) => async () => {
  try {
    const res = await AccountService.verifyResetPassword(query);
    if (res) {
      return res;
    }
  } catch (error) {
    throw error;
  }
};

export const resetPassword = (formData) => async () => {
  try {
    const res = await AccountService.resetPassword(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    throw error;
  }
};