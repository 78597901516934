import React from "react";
import { useSelector } from "react-redux";

import UserAssessment from "../user/assessment/index";
import AdminAssessment from "../admin/assessment/index";

const AssessmentPage = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <div>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ? (
          <AdminAssessment />
        ) : (
          <UserAssessment />
        )
      ) : null}
    </div>
  );
};

export default AssessmentPage;
