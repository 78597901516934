import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  InputAdornment,
  styled,
  Typography,
  FormControl,
  Select,
  IconButton,
  MenuItem,
} from "@mui/material";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { getCompanyMyJob } from "../../../../../actions/jobRecruit";
import { useDispatch, useSelector } from "react-redux";
import RequestPanel from "./company-myjob/requestPanel";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import ListIcon from "@mui/icons-material/List";
import MenuUserRecruit from "./share/menuUserRecruit";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")(({ theme }) => ({
  background: "#FFFFFF !important",
  "& .displayFlexEnd": {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "8px 0",
    },
  },
  paddingTop: 48,
  "& .search-field": {
    maxWidth: 500,
  },
  "& .card-container": {
    marginTop: 48,
  },
  "& .menu-button": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 16,
  },
}));

const FormControlStyled = styled(FormControl)(({ type }) => ({
  width: "220px",
  marginLeft: "20px",
  border: "#ffffff",
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  "& fieldset": {
    borderColor: "#a9a9a9",
  },
  "& .MuiInputBase-root:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffffff",
    },
  },
  "& .MuiFormLabel-filled": {
    color: "#000000",
  },
  "& .MuiInputBase-root": {
    borderRadius: "8px",
    color: type === "" ? "#bdbdbd" : "initail",
  },
  "& .MuiSelect-select": {
    color: "#000000",
    padding: "13.5px 32px 13.5px 14px",
  },
  "& .MuiSvgIcon-root": {
    color: "#bdbdbd",
  },
  "& .textFieldLabelColor": {
    "& .MuiSelect-select": {
      color: "#bdbdbd",
    },
  },
}));

const OpenJobPage = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [type, setType] = useState("");

  const { isFetching: isJobFetching, result: jobStore } = useSelector(
    (state) => state.jobsRecruit
  );

  const [jobFiltered, setJobFiltered] = useState([]);

  const [search, setSearch] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    dispatch(getCompanyMyJob());
  }, []);

  useEffect(() => {
    if (jobStore !== null) {
      setJobFiltered([...jobStore]);
    }
  }, [jobStore]);

  const listJobMenuItem = [
    // {
    //   label:"ยังไม่เริ่มเปิดงาน",
    //   value:"notOpen"
    // },
    {
      label: "เปิดรับสมัคร",
      value: "opening",
    },
    {
      label: "ปิดรับสมัคร",
      value: "ending",
    },
    {
      label: "งานถูกยกเลิก",
      value: "canceling",
    },
    {
      label: "งานที่อนุมัติ",
      value: "approved",
    },
    {
      label: "งานที่ยังไม่อนุมัติ",
      value: "approvedNotYet",
    },
  ];

  useEffect(() => {
    if (jobStore !== null) {
      setJobFiltered(
        jobStore.filter((job) => {
          return (
            (search === "" ||
              (job.positionName.toLowerCase().includes(search.toLowerCase()) &&
                job.positionName
                  .toLowerCase()[0]
                  .includes(search.toLowerCase()[0]))) &&
            (type === "" ||
              // (type === "notOpen" &&
              //   dayjs().isBefore(dayjs(job.createdDate)))
              (type === "opening" &&
                dayjs().isBefore(dayjs(job.closeRegisterDate))) ||
              (type === "ending" &&
                dayjs().isAfter(dayjs(job.closeRegisterDate))) ||
              (type === "approved" && job.isApproved === 1) ||
              (type === "approvedNotYet" && job.isApproved === 0))
            // (type === "canceling" &&
            //   !dayjs().isBefore(dayjs(bidding.biddingStartAt)) &&
            //   !dayjs().isAfter(dayjs(bidding.biddingEndAt)))
          );
          // && (type === "" || (job.positionName.toLowerCase().includes(searchName.toLowerCase())))
        })
      );
    }
  }, [search, type]);

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div className="menu-button">
          <ButtonBlue
            variant="contained"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            <ListIcon sx={{ fontSize: "30px" }} />
            &nbsp;
            {`${t("MenuItems")}`}
          </ButtonBlue>
          <MenuUserRecruit
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
          />
        </div>
        <Typography variant="h4" color="text.primary">{`${t(
          "ManageJobOpenings"
        )}`}</Typography>
        <Box
          marginTop="24px"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Box flexGrow={1}>
            <TextFieldTheme
              className="search-field"
              sx={{ backgroundColor: "#ffffff", borderRadius: "10px" }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={`${t("Search")}`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </InputAdornment>
                ),
              }}
            />
            <FormControlStyled type={type}>
              <Select
                displayEmpty
                value={type}
                onChange={(e) => setType(e.target.value)}
                endAdornment={
                  type.length > 0 ? (
                    <IconButton
                      onClick={() => {
                        setType("");
                      }}
                      style={{ width: 30, height: 30, marginRight: 14 }}
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </IconButton>
                  ) : (
                    ""
                  )
                }
              >
                <MenuItem className="textFieldLabelColor" value="">
                  {`${t("All")}`}
                </MenuItem>
                {
                  /* tabValue === 0 && */
                  listJobMenuItem.map((value) => (
                    <MenuItem value={value.value} key={value.label}>
                      {value.label}
                    </MenuItem>
                  ))
                }
                {/*                 {
                  tabValue === 1 &&
                  listBiddingMenuItem.map((value) => (
                    <MenuItem value={value.value} key={value.label}>{value.label}</MenuItem>
                    ))
                  } */}
              </Select>
            </FormControlStyled>
          </Box>
          <ButtonBlue
            variant="contained"
            component={Link}
            to="/manage/company/openjob/form"
            startIcon={<i className="fa-solid fa-plus"></i>}
          >
            {`${t("JobOpenings")}`}
          </ButtonBlue>
        </Box>
        <Box className="card-container">
          <RequestPanel type={type} data={jobFiltered} />
        </Box>
      </Container>
    </StyledRoot>
  );
};

export default OpenJobPage;
