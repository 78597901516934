import React, { useState } from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";
import { AccessTimeRounded } from "@mui/icons-material";
import { updateKPIEvaluationManager } from "../../../../../actions/kpi";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import ChipStatus from "../../../shared/pages/okrs/components/ChipStatus";
import { Controller, useForm } from "react-hook-form";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { useHistory } from "react-router-dom";
import AlertResponse from "../../../shared/general/AlertResponse";
// Translator TH-EN
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Rating from "@mui/material/Rating";

const StyledRoot = styled(Box)({});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  borderRadius: "30px",
  overflow: "hidden",
  border: "1px solid #EEEEEE",
  display: "flex",
  justifyContent: "center",
  width: "fit-content",
}));

const StyledToggleButton = styled(ToggleButton)(({ theme, selectedColor }) => ({
  flex: 1,
  borderRadius: "30px",
  "&.Mui-selected": {
    backgroundColor: selectedColor,
    color: "#FFFFFF",
  },
  "&.MuiToggleButtonGroup-grouped": {
    border: "none",
    "&:not(:first-of-type)": {
      borderLeft: "1px solid #EEEEEE",
    },
  },
  "&:hover": {
    backgroundColor: `${selectedColor} !important`,
    color: "#FFFFFF",
  },
  backgroundColor: "#EEEEEE",
  color: "#000000",
  textAlign: "center",
  minWidth: "100px",
  maxWidth: "200px",
}));

const KpiEvaluationIndividual = (props) => {
  const { data } = props;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [configAlert, setConfigAlert] = useState({
    open: false,
    alertType: "",
  });

  const [ratingValue, setRatingValue] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleClose = () => {
    setConfigAlert({ configAlert, open: false });
  };

  const { control, handleSubmit } = useForm({
    defaultValues: {
      managerRating: 0,
      managerFeedback: "",
      managerEvaluate: null,
    },
  });

  const handleEvaluation = async (formData) => {
    formData.managerEvaluate = formData.managerEvaluate === "1" ? 1 : 0;
    formData.managerEvaluateAt = dayjs().format("YYYY-MM-DD HH:mm:ss");
    formData.managerRating = formData.managerRating || 0;

    const res = await dispatch(updateKPIEvaluationManager(data.idKPI, formData));
    if (res && res.status === 200) {
      const result = await Swal.fire({
        title: t("EvaluationSuccess"),
        icon: "success",
        confirmButtonColor: "#3085d6",
      });

      if (result.isConfirmed) {
        setIsSubmitted(true);
        history.replace("/kpi-evaluation-list");
      }
    }
  };

  const onSubmit = async (formData) => {
    try {
      const result = await Swal.fire({
        title: t("ConfirmEvaluation"),
        text: t("AreYouSureToConfirmEvaluation?"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Confirm"),
        cancelButtonText: t("Cancel"),
      });

      if (result.isConfirmed) {
        await handleEvaluation(formData);
      }
    } catch (error) {
      console.error("Error displaying alert:", error);
    }
  };

  const renderEmployeeEvaluate = (evaluate) => {
    if (evaluate === 1) {
      return (
        <Typography
          whiteSpace="pre-line"
          style={{ color: "#50B498", fontSize: "26px", fontWeight: 500 }}
        >
          {`${t("Success")}`}
        </Typography>
      );
    } else if (evaluate === 0) {
      return (
        <Typography
          whiteSpace="pre-line"
          style={{ color: "#FF6969", fontSize: "26px", fontWeight: 500 }}
        >
          {`${t("Unsuccess")}`}
        </Typography>
      );
    }
    return null;
  };

  const renderEmployeeRating = (rating) => {
    if (rating !== null) {
      return (
        <Box display="flex" alignItems="center" gap="4px">
          <Typography sx={{ fontSize: "22px", fontWeight: 500 ,color: "#FFC403", marginLeft: "4px" }}>
            {rating}.0
          </Typography>
          {Array.from({ length: rating }, (_, index) => (
            <StarIcon key={index} sx={{ fontSize: "26px", color: "#FFC403" }} />
          ))}
        </Box>
      );
    }
    return null;
  };

  return (
    <StyledRoot className="page">
      <Container>
        <div className="cardPlan">
          <Box padding="2px">
            <Typography fontSize="40px" fontWeight="600" style={{ color: '#083A83' }}>
            {t("KPIEvaluateDetails")}
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Typography fontSize="22px">{data.kpiName}</Typography>
            </Box>
            <Box display="flex" alignItems="center" marginTop="8px">
              <AccessTimeRounded
                fontSize="14px"
                style={{ marginRight: "8px", color: "#919eab" }}
              />
              <Typography fontSize="14px" lineHeight="1" color="text.third">
                {`${dayjs(data.startDate).format(
                  i18n.resolvedLanguage === "th"
                    ? "DD/MM/BBBB"
                    : "DD/MM/YYYY"
                )} - ${dayjs(data.endDate).format(
                  i18n.resolvedLanguage === "th"
                    ? "DD/MM/BBBB"
                    : "DD/MM/YYYY"
                )}`}
              </Typography>
            </Box>
            <Box marginTop="8px" marginBottom="16px">
              <Typography marginBottom="8px">
                <span style={{ color: "#919eab", paddingRight: "8px" }}>
                  {`${t("Scope")}`}
                </span>
                {data.scope}
              </Typography>
              <Typography marginBottom="8px">
                <span style={{ color: "#919eab", paddingRight: "8px" }}>
                  {`${t("Target")}`}
                </span>
                {data.target}
              </Typography>
              <Typography marginBottom="8px">
                <span style={{ color: "#919eab", paddingRight: "8px" }}>
                  {`${t("TypeMeasurement")}`}
                </span>
                {data.measureType}
              </Typography>
              <Typography marginBottom="8px">
                <span style={{ color: "#919eab", paddingRight: "8px" }}>
                  {`${t("Descriptions")}`}
                </span>
                {data.description}
              </Typography>
              {data.employeeRating && (
                <Typography
                  fontSize="18px"
                  fontWeight={500}
                  paddingTop="16px"
                  gutterBottom
                >
                  <span style={{ color: "#919eab", paddingRight: "8px" }}>
                    {`${t("SelfAssessmentScore")}`}
                  </span>
                  {data.employeeRating}
                </Typography>
              )}
              <Typography whiteSpace="pre-line" style={{ marginTop: "8px" }}>
                <span style={{ color: "#919eab", paddingRight: "8px" }}>
                  {`${t("employeeEvaluate")}`}
                </span>
              </Typography>
              <Box display="flex" alignItems="center" gap="36px">
                {renderEmployeeEvaluate(data.employeeEvaluate)}
                {renderEmployeeRating(data.employeeRaitng)}
              </Box>
              {data.employeeFeedback && (
                <Typography whiteSpace="pre-line">
                  <span style={{ color: "#919eab", paddingRight: "8px" }}>
                    {`${t("EmployeeFeedback")}`}
                  </span>
                  {data.employeeFeedback}
                </Typography>
              )}
            </Box>
            <Divider />
            <Typography whiteSpace="pre-line" style={{ marginTop: "8px" }}>
              <span style={{ color: "#919eab", paddingRight: "8px" }}>
                {`${t("ManagerEvaluate")}`}
              </span>
            </Typography>
            
            <Box marginTop="8px">
              {data.managerEvaluate === null && !isSubmitted ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} display="flex" alignItems="center">
                      <Box>
                        <Controller
                          name="managerEvaluate"
                          control={control}
                          render={({ field }) => (
                            <div>
                              <Typography
                                fontSize={16}
                                marginBottom="8px"
                              >{`${t("Evaluate")}`}</Typography>
                              <StyledToggleButtonGroup
                                {...field}
                                exclusive
                                value={field.value}
                              >
                                <StyledToggleButton
                                  value="1"
                                  selectedColor="#50B498"
                                >{`${t("Success")}`}</StyledToggleButton>
                                <StyledToggleButton
                                  value="0"
                                  selectedColor="#FF4C4C"
                                >{`${t("Failure")}`}</StyledToggleButton>
                              </StyledToggleButtonGroup>
                            </div>
                          )}
                        />
                      </Box>
                      <Box marginLeft="30px">
                        <Typography fontSize={16} marginBottom="4px">
                          {t("Rate")} {ratingValue ? `${ratingValue}.0` : ""}
                        </Typography>
                        <Controller
                          name="managerRating"
                          control={control}
                          render={({ field }) => (
                            <Box
                              display="flex"
                              gap="8px"
                              paddingLeft="16px"
                              maxWidth="400px"
                            >
                              <Rating
                                {...field}
                                name="managerRating"
                                min={1}
                                max={5}
                                precision={1}
                                value={ratingValue}
                                icon={
                                  <StarIcon
                                    sx={{
                                      fontSize: "50px",
                                      color: "#FFC403",
                                    }}
                                  />
                                }
                                emptyIcon={
                                  <StarBorderIcon
                                    sx={{
                                      fontSize: "50px",
                                      color: "#ddd",
                                    }}
                                  />
                                }
                                onChange={(_, newValue) => {
                                  field.onChange(newValue);
                                  setRatingValue(newValue);
                                }}
                              />
                            </Box>
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="managerFeedback"
                        control={control}
                        render={({ field }) => (
                          <TextFieldTheme
                            {...field}
                            label={t("Feedback")}
                            multiline
                            rows={2}
                            inputProps={{ maxLength: 200 }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                      <ButtonBlue type="submit" variant="contained">
                        {t("Evaluate")}
                      </ButtonBlue>
                    </Grid>
                  </Grid>
                </form>
              ) : (
                <>
                  <Typography
                    whiteSpace="pre-line"
                    style={{
                      color: data.managerEvaluate === 1 ? "#50B498" : "#FF6969",
                      fontSize: "26px",
                      fontWeight: 500,
                    }}
                  >
                    {data.managerEvaluate === 1 ? `${t("Success")}` : `${t("Failure")}`}
                  </Typography>
                  <Box display="flex" alignItems="center" gap="4px" marginTop="8px">
                    <Typography
                      sx={{ fontSize: "22px", fontWeight: 500, color: "#FFC403" }}
                    >
                      {data.managerRating}.0
                    </Typography>
                    {Array.from({ length: data.managerRating }, (_, index) => (
                      <StarIcon key={index} sx={{ fontSize: "26px", color: "#FFC403" }} />
                    ))}
                  </Box>
                  <Typography
                    whiteSpace="pre-line"
                    style={{
                      marginTop: "8px",
                      fontSize: "18px",
                      color: "#919eab",
                    }}
                  >
                    {`${t("Feedback")}`}
                  </Typography>
                  <Typography whiteSpace="pre-line">
                    {data.managerFeedback}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </div>
        <AlertResponse {...configAlert} handleClose={handleClose} />
      </Container>
    </StyledRoot>
  );
};

export default KpiEvaluationIndividual;
