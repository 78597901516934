import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogContent, Grid, Typography, Avatar, Divider } from "@mui/material";
import { getSuccessorById } from "../../../../actions/successor";
import { getUserBusinessUnit, getUserCompany, getUserDepartment, getUserDivision, getUserFullName, getUserPosition, getUserSection } from "../../../../utils/userData";
import { useTranslation } from "react-i18next";

const DialogSuccessorById = (props) => {
  const { t } = useTranslation();
  const { open, onClose, idEmployees } = props;
  const [successorData, setSuccessorData] = useState(null);

  const fetchedData = async (idEmployees) => {
    try {
      const response = await getSuccessorById(idEmployees);
      if (response) {
        console.log(response && response.data)
        setSuccessorData(response.data[0]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (idEmployees) {
      fetchedData(idEmployees);
    }
  }, [idEmployees]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear() + 543;

    return `${day} ${getMonthAbbreviation(month)} ${year}`;
  }

  function getMonthAbbreviation(monthIndex) {
    const monthsAbbreviation = [
      t("shortMonth.Jan"), t("shortMonth.Feb"), t("shortMonth.Mar"), t("shortMonth.Apr"), t("shortMonth.May"), t("shortMonth.Jun"),
      t("shortMonth.Jul"), t("shortMonth.Aug"), t("shortMonth.Sep"), t("shortMonth.Oct"), t("shortMonth.Nov"), t("shortMonth.Dec")
    ];

    return monthsAbbreviation[monthIndex];
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent style={{ width: '500px', height: '600px' }}>
        <Typography variant="h6">{t("information")} Successor</Typography>
        <Grid>
          <Grid item xs={20} md={17} style={{ marginTop: "3vh" }}>
            <div>
              <div className="header-profile">
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Avatar variant="circle" className={"empImage"} />
                  </Grid>
                  <Grid item>
                    <Typography className="empHeadingText">
                      {successorData && getUserFullName(successorData)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className="jopHeadingText" style={{ marginBottom: "1rem" }}>
                      {successorData && getUserPosition(successorData)}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <Divider></Divider>
              <Grid container spacing={3} className="content-profile">
                {/* First Row */}
                <Grid item container spacing={2} style={{ marginTop: "1rem" }}>
                  <Grid item xs={6}>
                    <Typography className="text-topic">{t("Company")}</Typography>
                    <Typography className="text-content">{successorData && getUserCompany(successorData)}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="text-topic">{t("Division")}</Typography>
                    <Typography className="text-content">{successorData && getUserDivision(successorData)}</Typography>
                  </Grid>
                </Grid>

                {/* Second Row */}
                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className="text-topic">
                      {t("Department")}
                    </Typography>
                    <Typography className="text-content">{successorData && getUserDepartment(successorData)}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="text-topic">{t("Section")}</Typography>
                    <Typography className="text-content">{successorData && getUserSection(successorData)}</Typography>
                  </Grid>
                </Grid>

                {/* Third Row */}
                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className="text-topic">ESY</Typography>
                    <Typography className="text-content">{successorData && successorData.ESY_Y ? successorData.ESY_Y : "0"} / {successorData && successorData.ESY_M ? successorData.ESY_M : "0"}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="text-topic">
                      {t("ServiceYear")}
                    </Typography>
                    <Typography className="text-content">{successorData && successorData.Serv_Y ? successorData.Serv_Y : "0"} / {successorData && successorData.Serv_M ? successorData.Serv_M : "0"}</Typography>
                  </Grid>
                </Grid>

                {/* Fourth Row */}
                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className="text-topic">TIG</Typography>
                    <Typography className="text-content">{successorData && successorData.TIG_Y ? successorData.TIG_Y : "0"} / {successorData && successorData.TIG_M ? successorData.TIG_M : "0"}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="text-topic">TIP</Typography>
                    <Typography className="text-content">{successorData && successorData.TIP_Y ? successorData.TIP_Y : "0"} / {successorData && successorData.TIP_M ? successorData.TIP_M : "0"}</Typography>
                  </Grid>
                </Grid>

                {/* Fifth Row */}
                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className="text-topic">{t("Age")}</Typography>
                    <Typography className="text-content">{successorData && successorData.Age_Years ? successorData.Age_Years : "0"} / {successorData && successorData.Age_Months ? successorData.Age_Months : "0"}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
              <div container spacing={2} className="content-profile">
                <Typography className="text-topic" style={{ marginTop: "1rem" }} >{t("experience")}</Typography>
                <Typography className="text-content"> {successorData && successorData.start ? (
                  `[${formatDate(successorData.start)} - ${successorData.end ? formatDate(successorData.end) : t("Current")}]`
                ) : "[-]"} {successorData && getUserPosition(successorData)} </Typography>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <span className="textTopic">{t("Section")} : </span>
                      <span className="textContent"> {successorData && getUserSection(successorData)} </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <span className="textTopic">{t("Agency")} : </span>
                      <span className="textContent"> {successorData && getUserDepartment(successorData)} </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <span className="textTopic">{t("Division")} : </span>
                      <span className="textContent"> {successorData && getUserDivision(successorData)} </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <span className="textTopic">BU : </span>
                      <span className="textContent"> {successorData && getUserBusinessUnit(successorData)} </span>
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <div className="content-profile">
                <Typography className="text-topic">{t("Education")}</Typography>
                <Typography className="text-content">{successorData && successorData.degree ? successorData.degree : "-"} {successorData && successorData.university ? successorData.university : ""}</Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DialogSuccessorById;
