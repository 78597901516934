import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ExcelJS from 'exceljs';
import { getAllEmployeesWithTaxData } from '../../../../../actions/payruns';
import { useTranslation } from 'react-i18next';

export const PayrunXlsxFile = async () => {
    const {t} = useTranslation();
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet(t("IndividualTaxSubmissionReport"));

    const data = await getAllEmployeesWithTaxData();

    const headerRow = worksheet1.addRow([
        t("EmployeeID"), t("FirstName"), t("LastName"), t("PersonalID"), t("Position"), t("Company"),
        t("Division"), t("Department"), t("Section"), 
        `${t("TaxSubmissionMonth")} ${t("January")}`, 
        `${t("TaxSubmissionMonth")} ${t("February")}`, 
        `${t("TaxSubmissionMonth")} ${t("March")}`,
        `${t("TaxSubmissionMonth")} ${t("April")}`, 
        `${t("TaxSubmissionMonth")} ${t("May")}`, 
        `${t("TaxSubmissionMonth")} ${t("June")}`, 
        `${t("TaxSubmissionMonth")} ${t("July")}`,
        `${t("TaxSubmissionMonth")} ${t("August")}`, 
        `${t("TaxSubmissionMonth")} ${t("September")}`, 
        `${t("TaxSubmissionMonth")} ${t("October")}`, 
        `${t("TaxSubmissionMonth")} ${t("November")}`, 
        `${t("TaxSubmissionMonth")} ${t("December")}`
    ]);

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' } // Background color for the first section
        },
    };

    const headerStyle2 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00B050' } // Background color for the second section
        },
    };

    const headerStyles = [
        headerStyle1, headerStyle1, headerStyle1, headerStyle1, headerStyle1, headerStyle1, headerStyle1, headerStyle1,
        headerStyle1, headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2,
        headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2
    ];

    headerRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        if (headerStyles[colNumber - 1]) {
            cell.style = headerStyles[colNumber - 1];
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        }
    });

    headerRow.height = 50;

    const colWidths = [
        { key: "employeeID", width: 30 },
        { key: "firstname_TH", width: 50 },
        { key: "lastname_TH", width: 50 },
        { key: "taxID", width: 50 },
        { key: "positionName", width: 30 },
        { key: "companyName", width: 50 },
        { key: "division", width: 30 },
        { key: "departmentName", width: 30 },
        { key: "sectionName", width: 30 },
        { key: "Jan", width: 30 },
        { key: "Feb", width: 30 },
        { key: "Mar", width: 30 },
        { key: "Apr", width: 30 },
        { key: "May", width: 30 },
        { key: "Jun", width: 30 },
        { key: "Jul", width: 30 },
        { key: "Aug", width: 30 },
        { key: "Sep", width: 30 },
        { key: "Oct", width: 30 },
        { key: "Nov", width: 30 },
        { key: "Dec", width: 30 },
    ];

    data.forEach((item) => {
        const monthValues = item.MonthPeriod_Values ? item.MonthPeriod_Values.split(', ') : [];

        const months = [
            "2023-01-31", "2023-02-28", "2023-03-31", "2023-04-30",
            "2023-05-31", "2023-06-30", "2023-07-31", "2023-08-31",
            "2023-09-30", "2023-10-31", "2023-11-01", "2023-12-01"
        ];

        const row = [
            item.employeeID ? item.employeeID : "-",
            item.firstname_TH ? item.firstname_TH : "-",
            item.lastname_TH ? item.lastname_TH : "-",
            item.taxID ? item.taxID : "-",
            item.positionName ? item.positionName : "-",
            item.companyName ? item.companyName : "-",
            item.divisionName ? item.divisionName : "-",
            item.departmentName ? item.departmentName : "-",
            item.sectionName ? item.sectionName : "-"
        ];

        months.forEach((month) => {
            const specificMonthValue = monthValues
                .map((monthValue) => monthValue.split(': '))
                .find(([date]) => date === month);

            const value = specificMonthValue ? parseFloat(specificMonthValue[1]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
            row.push(value);
        });

        const excelRow = worksheet1.addRow(row);
        const contentStyle = { font: { size: 18, name: 'TH SarabunPSK' } };

        colWidths.forEach((col, index) => {
            worksheet1.getColumn(index + 1).width = col.width;
        });

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${data && data[0].companyName}-${t("IndividualTaxSubmissionReport")}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });

}
