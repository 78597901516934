import React from "react";
import { Controller, useForm } from "react-hook-form";

import { Box, Dialog, DialogContent, styled, Typography } from "@mui/material";

import { DesktopDatePicker } from "@mui/x-date-pickers";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledBox = styled(Box)(({ theme }) => ({
  width: "450px",
  [theme.breakpoints.down("sm")]: {
    width: "300px",
  },
  "& .Space-Bottom": {
    marginBottom: "10px",
  },
  "& .TextDetail": {
    fontSize: "18px",
  },
  "& .NavButton": {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  "& .CancelButton": {
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  "& .ConfirmButton": {
    marginBottom: 0,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
  },
}));

const DialogStartDate = (props) => {
  const { open, onClose, onSubmit } = props;
  const { t, i18n } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      workStartDate: null,
    },
  });

  return (
    <Dialog open={open}>
      <DialogContent>
        <StyledBox>
          <Typography
            variant="h5"
            className="Space-Bottom"
            style={{ fontweight: "bold" }}
          >{`${t("ConfirmStartDate")}`}</Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography className="TextDetail">{`${t(
              "SpecifyStartDate"
            )}`}</Typography>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px 0",
              }}
            >
              <Box style={{ width: "190px" }}>
                <Controller
                  control={control}
                  name="workStartDate"
                  rules={{
                    required: {
                      value: true,
                      message: `${t("PleaseEnterStartDate")}`,
                    },
                    validate: {
                      dateValidation: (value) =>
                        !isNaN(value) || `${t("PleaseEnterCorrectDate")}`,
                      disblePast: (value) =>
                        new Date(value) >=
                          new Date(new Date().setHours(0, 0, 0, 0)) ||
                        `${t("YouCannotSelectPastDate")}`,
                    },
                  }}
                  render={({ field }) => (
                    <DesktopDatePicker
                      {...field}
                      inputFormat="DD/MM/YYYY"
                      disablePast
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          helperText={
                            errors &&
                            errors.interviewAppointmentDate &&
                            errors.interviewAppointmentDate.message
                          }
                          error={
                            errors && errors.interviewAppointmentDate
                              ? true
                              : false
                          }
                        />
                      )}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box className="NavButton">
              <ButtonBlue
                variant="outlined"
                className="CancelButton"
                onClick={onClose}
              >{`${t("Cancel")}`}</ButtonBlue>
              <ButtonBlue
                variant="contained"
                className="ConfirmButton"
                type="submit"
              >{`${t("Confirm")}`}</ButtonBlue>
            </Box>
          </form>
        </StyledBox>
      </DialogContent>
    </Dialog>
  );
};

export default DialogStartDate;
