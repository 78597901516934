import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { Box, Typography, Container, Paper, Grid, Button, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import { getEmployeePersonalityTest } from "../../../../actions/employee";
import { personalityDISCTypes } from "./DISC1/type";
import { personalityBusinessTypes } from "./DISC2/type";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 50,
  "& .search-filter-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .filter-wrap": {},
    "& .action-container": {},
  },
});

const StyledClickablePaper = styled(Paper)({
  padding: 20,
  minHeight: "420px",
  backgroundSize: "cover",
  backgroundPosition: "center",
  borderRadius: "20px",
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1.1)",
  },
});

const StyledPaper = styled(Paper)({
  padding: 20,
  minHeight: "auto",
  backgroundSize: "cover",
  backgroundPosition: "top",
  borderRadius: "20px",
});

const StyledBox = styled(Box)({
  position: "absolute",
  top: -25,
  left: 0,
  right: 0,
  "& .box-header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "7vh",
    fontSize: "20px",
    fontWeight: 600,
    color: "white",
    textAlign: "center",
    backgroundColor: "#DB4178",
    marginLeft: "20vw",
    marginRight: "20vw",
    borderRadius: "10px",
  },
});

const StyledAnswer = styled("div")({
  "& .header-answer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    marginTop: "10px",
    textAlign: "left",

    "& .answerText": {
      color: "black",
      fontSize: "18px",
      fontWeight: 400,
      alignSelf: "center",
      marginTop: "10px",
      whiteSpace: "pre-wrap",
    },
  },
  "& .content-answer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 20,
    marginTop: "10px",
    textAlign: "left",

    "& .textTopic, .textContent": {
      color: "black",
      fontSize: "18px",
      fontWeight: 600,
      textAlign: "left",
      marginBottom: "10px",
    },
    "& .textContent": {
      color: "black",
      fontSize: "18px",
      fontWeight: 400,
      textAlign: "left",
      marginBottom: "10px",
    },
  },
});

const StyledAnswer2 = styled("div")({
  "& .header-answer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    marginTop: "10px",
    textAlign: "left",

    "& .answerText": {
      color: "black",
      fontSize: "18px",
      fontWeight: 400,
      alignSelf: "center",
      marginTop: "10px",
      whiteSpace: "pre-wrap",
    },
  },
  "& .content-answer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 20,
    marginTop: "10px",
    textAlign: "left",

    "& .textContent": {
      color: "white",
      fontSize: "18px",
      fontWeight: 400,
      textAlign: "left",
      marginBottom: "10px",
    },
  },
});

const StyleButton = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginTop: "10px",
  "& button": {
    marginRight: "10px",
    color: "white",
    backgroundColor: "#DB4178",
    "&:hover": {
      backgroundColor: "#FC669B",
    },
  },
});

const PersonalityCharacterTest = (props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [showFullText, setShowFullText] = useState(false);
  const [characterData, setCharacterData] = useState(null);

  const toggleReadMore = () => {
    setShowFullText(!showFullText);
  };

  const handleClick = (discNumber) => {
    if (discNumber === 1) {
      history.push("/personality-character-test/DISC1");
    } else if (discNumber === 2) {
      history.push("/personality-character-test/DISC2");
    }
  };

  useEffect(() => {
    const fetchedData = async () => {
      try {
        const response = await getEmployeePersonalityTest();
        if (response && response.data) {
          setCharacterData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchedData();
  }, []);

  const DISC = personalityDISCTypes.find(item => item.id === (characterData && characterData[0] && characterData[0].result));
  const BusinessType = personalityBusinessTypes.find(item => item.id === (characterData && characterData[1] && characterData[1].result));

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div style={{ marginBottom: 20 }}>
          {characterData && characterData.length > 0 && (
            <Typography variant="h4" style={{ paddingTop: 8 }}>
              {t("PersonalityCharacterTestResult")}
            </Typography>
          )}
        </div>

        {
          characterData && characterData[0] && DISC && (
            <div>
              <Typography sx={{ p: 2 }} fontSize={30} fontWeight={"bold"}>
                DISC Style
              </Typography>
              <StyledPaper
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/DISC1/BG-Result.svg)`,
                }}
              >
                <img
                  src={DISC.imagePath}
                  style={{
                    width: "30%",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "200px",
                    marginBottom: "auto",
                  }}
                />
                <div style={{ position: "relative" }}>
                  <Grid item>
                    <Box sx={{
                      position: 'absolute',
                      top: -30,
                      left: '50%',
                      transform: 'translateX(-50%)',
                      zIndex: 1
                    }}>
                      <Box sx={{ bgcolor: "#DB4178", width: 200, p: 3, borderRadius: 3 }}>
                        <Typography
                          fontSize={17}
                          fontWeight={600}
                          color={"#FFFFFF"}
                          textAlign={"center"}
                        >
                          <Box className="box-header">{DISC.title}</Box>
                        </Typography>
                      </Box>
                    </Box>
                    <Paper
                      style={{
                        minHeight: 100,
                        maxWidth: 900,
                        padding: 20,
                        marginLeft: "5vw",
                        marginRight: "5vw",
                      }}
                    >
                      <StyledAnswer>
                        <div className="header-answer">
                          <Typography className="answerText">
                            {DISC.content.longText}
                          </Typography>
                        </div>
                        <div>
                          <Divider />
                          <div className="content-answer">
                            <Typography className="textTopic">ข้อดี</Typography>
                            <Typography className="textContent">
                              {DISC.content.Advantages}
                            </Typography>
                            <Typography className="textTopic">
                              ข้อเสียที่ควรแก้ไข
                            </Typography>
                            <Typography className="textContent">
                              {DISC.content.Disadvantages}
                            </Typography>
                          </div>
                        </div>
                      </StyledAnswer>
                    </Paper>
                  </Grid>
                </div>
              </StyledPaper>
            </div>
          )
        }

        {characterData && characterData[1] && BusinessType && (
          <div style={{ marginTop: 20 }}>
            <Typography sx={{ p: 2 }} fontSize={30} fontWeight={"bold"}>
              Business Character
            </Typography>
            <StyledPaper
              style={{
                backgroundImage: BusinessType.backgroundImage,
              }}
            >
              <img
                src={BusinessType.imagePath}
                style={{
                  width: "30%",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              />
              <div style={{ position: "relative" }}>
                <Grid item>
                  <Box sx={{
                    position: 'absolute',
                    top: -30,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 1
                  }}>
                    <Box sx={{ bgcolor: "#DB4178", width: 200, p: 3, borderRadius: 3 }}>
                      <Typography
                        fontSize={17}
                        fontWeight={600}
                        color={"#FFFFFF"}
                        textAlign={"center"}
                      >
                        <Box className="box-header">{BusinessType.title}</Box>
                      </Typography>
                    </Box>
                  </Box>
                  <Paper
                    style={{
                      minHeight: 100,
                      maxWidth: 900,
                      padding: 20,
                      marginLeft: "5vw",
                      marginRight: "5vw",
                      backgroundColor: "rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <StyledAnswer2>
                      <div className="content-answer">
                        <Typography
                          className="textContent"
                          sx={{
                            fontSize: { xs: "16px", sm: "18px", md: "20px" },
                            mt: 2
                          }}
                        >
                          {BusinessType.content.longText}
                        </Typography>
                      </div>
                    </StyledAnswer2>
                  </Paper>
                </Grid>
              </div>
            </StyledPaper>
          </div>
        )}


        <div>
          {(characterData && characterData.length === 1 || !characterData) && (
            <Typography variant="h4" style={{ paddingTop: characterData && characterData.length > 0 ? 30 : 5, paddingBottom: 20 }}>
              {t("PersonalityCharacterTest")}
            </Typography>
          )}


          <Grid container spacing={2}>
            {!DISC && (
              <Grid item xs={12} sm={6} md={4}>
                <StyledClickablePaper
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/DISC1/BG-Per-Test1.jpg)`,
                  }}
                  onClick={() => handleClick(1)}
                />
              </Grid>
            )}

            {!BusinessType && (
              <Grid item xs={12} sm={6} md={4}>
                <StyledClickablePaper
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/DISC2/BG-Per-Test2.png)`,
                  }}
                  onClick={() => handleClick(2)}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </Container >
    </StyledRoot >
  );
};

export default PersonalityCharacterTest;
