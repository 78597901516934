import React from 'react';
import { Card, CardContent, Typography, Grid, styled, Chip, Box } from '@mui/material';
import moment from 'moment';
import { AssignmentLateOutlined } from '@mui/icons-material';

function AbsenceAndRate({ t, currentLocale, absenceAndLate }) {
  moment.locale(currentLocale);

  // Sorting absence and late data
  const sortedAbsenceData = absenceAndLate ? [...absenceAndLate]
    .sort((a, b) => moment(b.date, 'YYYY-MM-DD') - moment(a.date, 'YYYY-MM-DD'))
    .slice(0, 10) : [];

  const StyledChip = styled(Chip)(({ status }) => ({
    margin: 0,
    fontSize: 14,
    fontWeight: 600,
    height: 26,
    ...(status === `${t("WorkingTimeRequest")}` && {
      backgroundColor: "#fffcd6",
      color: "#eebb00",
      "& .MuiSvgIcon-root": {
        color: "#eebb00",
      },
      "&.MuiButtonBase-root:hover": {
        backgroundColor: "#fff9ab",
      },
    }),
    ...(status === `${t("Overtime")}` && {
      backgroundColor: "#e9f0ff",
      color: "#1e4db6",
      "& .MuiSvgIcon-root": {
        color: "#1e4db6",
      },
      "&.MuiButtonBase-root:hover": {
        backgroundColor: "#d4e0f9",
      },
    }),
    ...(status === `${t("Absent")}` && {
      backgroundColor: "#fdf3f5",
      color: "#fc4b6c",
    }),
    ...(status === `${t("Leave")}` && {
      backgroundColor: "#ecfff5",
      color: "#38ca7f",
    }),
    ...(status === `${t("Late")}` && {
      backgroundColor: "#ddf0ff",
      color: "#1997f5",
    }),
    ...(status === `${t("EarlyOut")}` && {
      backgroundColor: "#ddf0ff",
      color: "#1997f5",
    }),
    ...(status === `${t("AnnualHoliday")}` && {
      backgroundColor: "#f7f5ff",
      color: "#7451ff",
    }),
    ...(status === `CompensationDay` && {
      backgroundColor: "#fbeff7",
      color: "#D862BC",
    }),
    ...((status === `${t("SickLeave")}` ||
      status === `${t("PersonalLeave")}` ||
      status === `${t("VacationLeave")}`) && {
      backgroundColor: "#ecfff5",
      color: "#38ca7f",
    }),
  }));

  const HeaderBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    padding: '16px 0 32px 0',
  }));

  const InnerHeaderBox = styled(Box)(() => ({
    borderBottom: '2px solid black',
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  return (
    <Card sx={{ width: '100%', padding: '16px' }}>
      <CardContent>
        <HeaderBox>
          <InnerHeaderBox>
            <AssignmentLateOutlined sx={{ marginRight: 1, fontSize: 30,color: 'error.main' }} />
            <Typography margin={0} variant="h5" align="center" gutterBottom>
              {t('Absent')} & {t('Late')}
            </Typography>
          </InnerHeaderBox>
        </HeaderBox>
        <Grid container spacing={2} justifyContent="center" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
          {sortedAbsenceData.length > 0 ? (
            sortedAbsenceData.map((record, index) => (
              <Grid
                item
                xs={12}
                key={index}
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Grid
                  item
                  xs={5}
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Typography variant="subtitle1" align="center">
                    {moment(record.date, 'YYYY-MM-DD').format('LL')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <StyledChip label={`${t(record.status)}`} status={t(record.status)} />
                </Grid>
              </Grid>
            ))
          ) : (
            <Typography variant="body1" align="center" sx={{ width: '100%', paddingTop: '20px' }}>
              {t('NoAbsent')} & {t('NoLate')}
            </Typography>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default AbsenceAndRate;
