import React, { useEffect } from "react";
import { IconButton, Stack, Switch } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";
import DrawerCustom from "../../../shared/general/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { getAllEstimateLevel, updateEstimatePerformance } from "../../../../../actions/estimatePerformance";
import { useTranslation } from "react-i18next";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";


const DrawerPerformanceLevel = ({ open, handleClose, idCompany }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: estimateLevelList } = useSelector(
    (state) => state.estimateLevel
  );

  const validationSchema = yup.object().shape({
    level: yup.array().of(
      yup.object().shape({
        active: yup.boolean(),
        name: yup.string().when("active", {
          is: true,
          then: (schema) =>
            schema.min(1, "ความยาวอย่างน้อย 1 ตัวอักษร").max(40),
        }),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      level: [{ name: "", active: true }],
    },
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({ control, name: "level" });
  const level = watch("level");

  const handleClickSave = async (values) => {
    const res = await dispatch(updateEstimatePerformance(values));
    if (res && res.status === 201) {
      dispatch(openNotificationAlert({ message: "success", type: "success" }));
      dispatch(getAllEstimateLevel({ idCompany }));
      handleClose();
    } else {
      dispatch(openNotificationAlert({ message: "error", type: "error" }));
    }
  };

  useEffect(() => {
    if (estimateLevelList) {
      const level = estimateLevelList.map((item) => ({
        idEstimateLevel: item.idEstimateLevel,
        name: item.name,
        active: item.active,
      }));
      reset({ level });
    }
  }, [estimateLevelList]);

  return (
    <DrawerCustom
      title={t("PerformanceAssessmentLevels")}
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <div style={{ padding: "16px" }}>
        <form onSubmit={handleSubmit(handleClickSave)}>
          <Stack gap={1} alignItems={"flex-start"}>
            {fields.map((item, index) => (
              <div key={item.id} style={{ display: "flex", alignItems: "center" }}>
                <Controller
                  control={control}
                  name={`level.${index}.name`}
                  render={({ field, fieldState }) => (
                    <TextFieldTheme
                      {...field}
                      fullWidth
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error && fieldState.error.message}
                      inputProps={{
                        readOnly: Boolean(
                          level[index] && level[index].idEstimateLevel
                            ? true
                            : false
                        ),
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`level.${index}.active`}
                  render={({ field }) => (
                    <Switch {...field} checked={field.value} />
                  )}
                />
                {!level[index].idEstimateLevel && (
                  <IconButton onClick={() => remove(index)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            ))}
            <ButtonBlue
              type="button"
              startIcon={<i className="fa-regular fa-plus"></i>}
              onClick={() => append({ name: "", active: true })}
            >
              {t("AddLevel")}
            </ButtonBlue>
          </Stack>
          <div style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
            <ButtonBlue onClick={handleClose}>{t("Cancel")}</ButtonBlue>
            <ButtonBlue
              type="submit"
              variant={"contained"}
              disabled={isSubmitting}
            >
              {t("Save")}
            </ButtonBlue>
          </div>
        </form>
      </div>
    </DrawerCustom>
  );
};

export default DrawerPerformanceLevel;
