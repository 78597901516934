import {
    WORKTYPES_FETCHING,
    WORKTYPES_FAILED,
    WORKTYPES_SUCCESS
} from "./types";

import WorkTypeService from "../services/workType.service";

export const getAllWorkTypes = () => async (dispatch) => {
    try {
        dispatch({
            type: WORKTYPES_FETCHING,
        });
        let res = await WorkTypeService.getAllWorkTypes();
        if(res) {
            dispatch({
                type: WORKTYPES_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: WORKTYPES_FAILED,
        });
    }
};