import {
  TEAMPLANS_FETCHING,
  TEAMPLANS_FAILED,
  TEAMPLANS_SUCCESS,
} from "./types";

import TeamPlanService from "../services/TeamPlans.service";

export const getAllTeamPlan = () => async (dispatch) => {
  try {
    const res = await TeamPlanService.getAllTeamPlan();
    if (res) {
      dispatch({
        type: TEAMPLANS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: TEAMPLANS_FAILED,
    });
    console.log(err);
  }
};

export const getTeamPlanById = (id) => async () => {
  try {
    const res = await TeamPlanService.getTeamPlanById(id);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const postTeamPlan = (formData) => async () => {
  try {
    const res = await TeamPlanService.postTeamPlan(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putTeamPlan = (id, formData) => async () => {
  try {
    const res = await TeamPlanService.putTeamPlan(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putApprovalTeamPlan = (id, formData) => async () => {
  try {
    const res = await TeamPlanService.putApprovalTeamPlan(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putEvaluationTeamPlan = (id, formData) => async () => {
  try {
    const res = await TeamPlanService.putEvaluationTeamPlan(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const deleteTeamPlan = (id, formData) => async () => {
  try {
    const res = await TeamPlanService.deleteTeamPlan(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};
