import {
  FAILUREHISTORY_FETCHING,
  FAILUREHISTORY_FAILED,
  FAILUREHISTORY_SUCCESS,
} from "./types";

import FailureHistoryService from "../services/FailureHistory.service";

export const getAllFailureHistory = () => async (dispatch) => {
  try {
    const res = await FailureHistoryService.getAllFailureHistory();
    if (res) {
      dispatch({
        type: FAILUREHISTORY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: FAILUREHISTORY_FAILED,
    });
    console.log(err);
  }
};

export const getFailureHistoryById = (id) => async () => {
  try {
    const res = await FailureHistoryService.getFailureHistoryById(id);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const postFailureHistory = (formData) => async () => {
  try {
    const res = await FailureHistoryService.postFailureHistory(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putFailureHistory = (id, formData) => async () => {
  try {
    const res = await FailureHistoryService.putFailureHistory(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const deleteFailureHistory = (id, formData) => async () => {
  try {
    const res = await FailureHistoryService.deleteFailureHistory(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};
