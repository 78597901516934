import {
  HOMEPAGE_RECRUIT_FETCHING,
  HOMEPAGE_RECRUIT_FAILED,
  HOMEPAGE_RECRUIT_SUCCESS,
} from "./types";
import HomepageService from "../services/homepage.service";

export const getHomepageForHunter = () => async (dispatch) => {
  try {
    dispatch({
      type: HOMEPAGE_RECRUIT_FETCHING,
    });
    const res = await HomepageService.getHomepageForHunter();
    if (res) {
      dispatch({
        type: HOMEPAGE_RECRUIT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: HOMEPAGE_RECRUIT_FAILED,
    });
  }
};
