import React from "react";

import { styled } from "@mui/material/styles";
import { TableRow, TableCell } from "@mui/material";

import utils from "../../../../../../utils";

const StyledTableRow = styled(TableRow)({
  marginBottom: 2,
});

const StyledTableCell = styled(TableCell)({
  padding: 0,
  border: "none",
  "& .wrap": {
    height: 72,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    borderTop: "1px solid #e1e1e1",
    borderLeft: "1px solid #e1e1e1",
    borderBottom: "1px solid #e1e1e1",
    "&.first": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    "&.last": {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      borderRight: "1px solid #e1e1e1",
    },
  },
  "&.date-document": {
    width: 130,
    "& .MuiInputBase-root": {
      paddingRight: 8,
    },
  },
  "&.description": {
    width: 450,
  },

  "&.price": {
    width: 104,
  },
  "&.delete": {
    width: 32,
  },
});

const RowItemExpense = (props) => {
  const { index, data } = props;

  return (
    <StyledTableRow>
      <StyledTableCell className="cell-table description">
        <div className="wrap first">{data.description}</div>
      </StyledTableCell>
      <StyledTableCell className="cell-table price">
        <div className="wrap">
          {utils.numberWithCommas(parseFloat(data.price))}
        </div>
      </StyledTableCell>
      <StyledTableCell className="cell-table price">
        <div className="wrap last">
          {utils.numberWithCommas(parseFloat(data.withdrawablePrice))}
        </div>
      </StyledTableCell>
      <StyledTableCell className="delete" align="center"></StyledTableCell>
    </StyledTableRow>
  );
};

export default RowItemExpense;
