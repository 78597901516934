import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import KpiEvaluationIndividual from "./KpiEvaluationIndividual";
import KpiEvaluationEmployee from "./KpiEvaluationEmployee";


const KpiEvaluation = () => {
  const { state } = useLocation();
  const history = useHistory();
  const { result: userProfile } = useSelector((state) => state.userProfile);

  if (!state) {
    return history.replace("/kpi-evaluation-list");
  }

  return (
    <>
      {userProfile ? (
        userProfile.roles.includes("ROLE_USER") ? (
          <KpiEvaluationEmployee data={state.planList} />
        ) : (
          <KpiEvaluationIndividual data={state.planList} />
        )
      ) : null}
    </>
  );
};

export default KpiEvaluation;
