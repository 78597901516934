import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import {
  Box,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { Add, Delete, Edit } from "@mui/icons-material";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import DialogConfirmDelete from "../../../shared/general/DialogConfirmDelete";
import DrawerDate from "./drawerDate";

import {
  getTaxDeductionSchedule,
  disableTaxDeductionSchedule,
} from "../../../../../actions/taxDeduction";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";
import { useTranslation } from "react-i18next";

const Root = styled("div")({
  marginTop: "30px",
  "& .MuiTypography-h6": {
    fontSize: "1.5rem",
  },
});

const Header = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const Body = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& .topicGrid": {
    fontSize: "1.25rem",
  },
  "& .textCenter": {
    textAlign: "center",
  },
  "& .topicChanging": {
    fontSize: "1.5rem",
  },
  "& .hiddenBorder": {
    borderBottom: "0",
  },
  "& .topicRowColumn": {
    fontSize: "1.20rem",
    "& .index": {
      fontWeight: "bold",
      marginLeft: "10px",
    },
  },
  "& .textDate": {
    fontSize: "1.3rem",
  },
  "& .textBold": {
    fontWeight: "bold",
  },
});

const TaxDeductionSchedule = (props) => {
  const { selectedCompany, isReadOnly } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: TaxDeductionSchedule } = useSelector(
    (state) => state.taxDeductionSchedule
  );
  const [openDrawer, setOpenDrawer] = useState({
    isOpen: false,
    value: null,
  });
  const [detailTaxDeductionDelete, setDetailTaxDeductionDelete] = useState({
    isOpen: false,
    text: "",
    label: "",
    id: null,
  });

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getTaxDeductionSchedule({ idCompany: selectedCompany.idCompany }));
    }
  }, [selectedCompany]);

  const handleConfirmDelete = async (id) => {
    let result = await dispatch(disableTaxDeductionSchedule(id));
    if (result.status === 200) {
      dispatch(
        openNotificationAlert({
          type: "success",
          message: `ลบสำเร็จ`,
        })
      );
      dispatch(getTaxDeductionSchedule({ idCompany: selectedCompany.idCompany }));
    } else {
      dispatch(
        openNotificationAlert({
          type: "error",
          message: `ลบไม่สำเร็จ`,
        })
      );
    }
    setDetailTaxDeductionDelete({
      isOpen: false,
      text: "",
      label: "",
      id: null,
    });
  };

  return (
    <Root>
      <Header>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">{t("TaxDeductible")}</Typography>
          </Grid>
          <Grid item>
            <ButtonBlue
              variant={"contained"}
              startIcon={<Add />}
              onClick={() => {
                setOpenDrawer({
                  isOpen: true,
                  value: null,
                });
              }}
            >
              {t("Add")}
            </ButtonBlue>
          </Grid>
        </Grid>
      </Header>
      <Body>
        {TaxDeductionSchedule && TaxDeductionSchedule.length > 0? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>{t("StartDate")}</TableCell>
                  <TableCell>{t("EndDate")}</TableCell>
                  <TableCell
                    style={{ width: "80px", minWidth: "80px" }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {TaxDeductionSchedule.map((schedule, i) => (
                  <TableRow key={schedule.idTaxDeductionSchedule}>
                    <TableCell>{`${t("Phase")} ${i + 1}`}</TableCell>
                    <TableCell>
                      <Typography variant="h6">
                        {dayjs(schedule.startDate).format("DD MMM")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">
                        {dayjs(schedule.endDate).format("DD MMM")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Edit
                        style={{
                          cursor: "pointer",
                          color: "#46cbe2",
                          marginRight: "16px",
                        }}
                        onClick={() => {
                          setOpenDrawer({
                            isOpen: true,
                            value: schedule,
                          });
                        }}
                      />
                      <Delete
                        style={{
                          cursor: "pointer",
                          color: "#ff0000",
                        }}
                        onClick={() => {
                          setDetailTaxDeductionDelete({
                            isOpen: true,
                            label: `${t("TaxDeductible")}`,
                            text: `${dayjs(schedule.startDate).format(
                              "DD MMM"
                            )} - ${dayjs(schedule.endDate).format("DD MMM")}`,
                            id: schedule.idTaxDeductionSchedule,
                          });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Grid container justifyContent="center">
            <Grid item style={{ margin: "30px 0" }}>
              <Typography>{t("NoData")}</Typography>
            </Grid>
          </Grid>
        )}
      </Body>
      {openDrawer.isOpen && (
        <DrawerDate
          open={openDrawer.isOpen}
          value={openDrawer.value}
          selectedCompany={selectedCompany}
          onClose={() =>
            setOpenDrawer({
              isOpen: false,
              value: null,
            })
          }
          isReadOnly={isReadOnly}
        />
      )}
      {detailTaxDeductionDelete.isOpen && (
        <DialogConfirmDelete
          open={detailTaxDeductionDelete.isOpen}
          handleClose={() => {
            setDetailTaxDeductionDelete({
              isOpen: false,
              text: "",
              label: "",
              id: null,
            });
          }}
          text={detailTaxDeductionDelete.text}
          label={detailTaxDeductionDelete.label}
          handleDelete={() => handleConfirmDelete(detailTaxDeductionDelete.id)}
          isReadOnly={isReadOnly}
        />
      )}
    </Root>
  );
};

export default TaxDeductionSchedule;
