import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTitle } from "react-use";
import {
  List,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItem,
  Divider,
  Box,
  Typography,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Switch,
  FormControlLabel,
  IconButton,
  Tooltip,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import LocalPoliceRoundedIcon from "@mui/icons-material/LocalPoliceRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import MoneyOffRoundedIcon from "@mui/icons-material/MoneyOffRounded";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import DoNotTouchRoundedIcon from "@mui/icons-material/DoNotTouchRounded";
import RequestQuoteRoundedIcon from "@mui/icons-material/RequestQuoteRounded";
import SignalCellularNoSimRoundedIcon from "@mui/icons-material/SignalCellularNoSimRounded";
import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";
import EditNoteIcon from "@mui/icons-material/EditNote";

import AlertResponse from "../../../shared/general/AlertResponse";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DialogConfirmDelete from "../../../shared/general/DialogConfirmDelete";
import DialogConfirmChange from "../../../shared/general/DialogConfirmChange";
import DialogEdit from "./components/DialogEdit";
import DialogEditCompany from "./components/DialogEditCompany";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  getAllAdmin,
  updateAdmin,
  updateAdminBusinessUnit,
  updateAdminDivision,
} from "../../../../../actions/company";
import { useTranslation } from "react-i18next";
import {
  getUserBusinessUnit,
  getUserCompany,
  getUserDepartment,
  getUserDivision,
  getUserFullName,
} from "../../../../../utils/userData";
import DialogDivision from "./components/dialogDivision";
import DialogBusinessUnit from "./components/dialogBusinessUnit";
import AdminFillIn from "./components/adminFillIn";

const StyledRoot = styled("div")({
  "&.root": {
    marginTop: "50px",
    paddingBottom: "100px",
  },
  "& .wrap-header": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 16,
    alignItems: "baseline",
  },
  "& .section": {
    marginBottom: 40,
  },
  "& .sub-header": {
    fontWeight: 500,
  },
});

const StyledMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    borderRadius: 8,
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
  },
  "& .MuiList-root": {
    padding: 8,
  },
  "& .MuiMenuItem-root": {
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#919eab14",
    },
  },
});

const StyledAdmin = styled("div")({
  "& .wrap-admin-list, .wrap-admin-description": {
    borderRadius: 16,
    padding: "0 16px",
    "& .head": {
      display: "flex",
      alignItems: "center",
      padding: "16px 0",
    },
  },
  "& .admin-description": {
    height: 560,
    paddingTop: 8,
    paddingBottom: 8,
    "& .wrap-company": {
      "& .company-head": {
        display: "flex",
        alignItems: "baseline",
        marginBottom: 8,
      },
      "& .company-content": {
        maxHeight: 300,
        overflow: "auto",
      },
      "& .company-list": {
        display: "flex",
        justifyContent: "space-between",
        "& .left": {
          display: "flex",
          alignItems: "center",
          marginBottom: 8,
        },
        "& .right": {
          "& .btn-view, .btn-payroll, .btn-performance": {
            marginLeft: 4,
            "&.is-true": {
              color: "#001e3c",
            },
            "&.is-false": {
              color: "#919eab",
            },
          },
          "& .MuiIcon-fontSizeMedium": {
            fontSize: 20,
          },
        },
        "& .company-name": {
          fontWeight: 500,
        },
        "& .btn-delete": {
          color: "#ff4d4f",
          marginRight: 4,
          "&:hover ": {
            backgroundColor: "#ff4d4f1f",
          },
          "&.Mui-disabled": {
            color: "#00000042",
          },
        },
      },
    },
  },
});

const StyledLabelHead = styled(Typography)({
  fontSize: 24,
  fontWeight: 600,
  "& i": {
    marginRight: 8,
  },
});

const StyledDivider = styled(Divider)({
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  margin: "0 8px",
});

const StyledListCheckEmployees = styled(List)({
  height: 450,
  overflow: "auto",
  "& .MuiButtonBase-root": {
    borderRadius: 8,
  },
  "& .MuiListItem-root": {
    padding: 0,
    "& .MuiListItemSecondaryAction-root": {
      right: 0,
      "& .MuiTypography-root": {
        lineHeight: 1,
        color: "#001e3c",
      },
    },
  },
  "& .Mui-selected": {
    borderRadius: 8,
  },
  "& .MuiListItemText-primary": {
    fontWeight: 500,
  },
  "& .MuiListItemText-secondary": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 330,
    fontWeight: 500,
    color: "#919eab",
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledSwitchSuperAdmin = styled(Switch)(({ theme }) => ({
  width: 54,
  height: 24,
  padding: 6,
  "&.super-admin": {
    "& .MuiSwitch-thumb": {
      backgroundColor: "#001e3c !important",
    },
    "& .Mui-disabled": {
      "& .MuiSwitch-thumb": {
        backgroundColor: "#9e9e9e !important",
      },
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(23px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="20" viewBox="0 0 22 23"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="m14.5 12.59.63 2.73c.1.43-.37.77-.75.54L12 14.42l-2.39 1.44c-.38.23-.85-.11-.75-.54l.64-2.72-2.1-1.81c-.34-.29-.16-.84.28-.88l2.78-.24 1.08-2.56c.17-.41.75-.41.92 0l1.08 2.55 2.78.24c.44.04.62.59.28.88l-2.1 1.81zM4.19 4.47C3.47 4.79 3 5.51 3 6.3V11c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V6.3c0-.79-.47-1.51-1.19-1.83l-7-3.11c-.52-.23-1.11-.23-1.62 0l-7 3.11z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#bdbdbd",
    width: 24,
    height: 24,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="20" viewBox="0 0 22 23"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M20 11.09v-4.7c0-.83-.52-1.58-1.3-1.87l-6-2.25c-.45-.17-.95-.17-1.4 0L6.78 3.96l12.09 12.09c.72-1.53 1.13-3.22 1.13-4.96zm.49 9.4L3.51 3.51a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L4 6.83v4.26c0 4.83 3.13 9.37 7.43 10.75.37.12.77.12 1.14 0 1.49-.48 2.84-1.35 3.97-2.47l2.53 2.53c.39.39 1.02.39 1.41 0 .4-.39.4-1.02.01-1.41z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#929292",
    borderRadius: 20 / 2,
  },
}));

const StyledSwitchAllCompany = styled(Switch)(({ theme }) => ({
  width: 54,
  height: 24,
  padding: 6,
  "&.all-company": {
    "& .MuiSwitch-thumb": {
      backgroundColor: "#001e3c !important",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(23px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="20" viewBox="0 0 22 23"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M13 16h-2c-.55 0-1-.45-1-1H3.01v4c0 1.1.9 2 2 2H19c1.1 0 2-.9 2-2v-4h-7c0 .55-.45 1-1 1zm7-9h-4c0-2.21-1.79-4-4-4S8 4.79 8 7H4c-1.1 0-2 .9-2 2v3c0 1.11.89 2 2 2h6v-1c0-.55.45-1 1-1h2c.55 0 1 .45 1 1v1h6c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zM10 7c0-1.1.9-2 2-2s2 .9 2 2H9.99 10z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#bdbdbd",
    width: 24,
    height: 24,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="20" viewBox="0 0 22 23"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M4.11 2.54a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L4.74 6H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h15.74l1.29 1.29c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L4.11 2.54zM10 4h4v2h-3.6L22 17.6V8c0-1.11-.89-2-2-2h-4V4c0-1.11-.89-2-2-2h-4c-.99 0-1.8.7-1.96 1.64L10 5.6V4z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#929292",
    borderRadius: 20 / 2,
  },
}));

const StyledSwitchConfidential = styled(Switch)(({ theme }) => ({
  width: 54,
  height: 24,
  padding: 6,
  "&.all-company": {
    "& .MuiSwitch-thumb": {
      backgroundColor: "#001e3c !important",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(23px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="20" viewBox="0 0 22 23"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M18 11c.7 0 1.37.1 2 .29V10c0-1.1-.9-2-2-2h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h6.26c-.79-1.13-1.26-2.51-1.26-4 0-3.87 3.13-7 7-7zM8.9 6c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2H8.9V6z"/></svg>'), url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="20" viewBox="0 0 22 23"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M18 13c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0 2c.83 0 1.5.67 1.5 1.5S18.83 18 18 18s-1.5-.67-1.5-1.5.67-1.5 1.5-1.5zm0 6c-1.03 0-1.94-.52-2.48-1.32.73-.42 1.57-.68 2.48-.68s1.75.26 2.48.68c-.54.8-1.45 1.32-2.48 1.32z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#bdbdbd",
    width: 24,
    height: 24,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="20" viewBox="0 0 22 23"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M8.9 6c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2h-4.66L20 17.56V10c0-1.1-.9-2-2-2h-1V6c0-2.76-2.24-5-5-5-2.32 0-4.26 1.59-4.82 3.74L8.9 6.46V6zm-3.78-.49a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41l1.33 1.33C4.42 8.6 4 9.25 4 10v10c0 1.1.9 2 2 2h12.78l.29.29c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L5.12 5.51z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#929292",
    borderRadius: 20 / 2,
  },
}));

const StyledSwitchPayroll = styled(Switch)(({ theme }) => ({
  width: 54,
  height: 24,
  padding: 6,
  "&.all-company": {
    "& .MuiSwitch-thumb": {
      backgroundColor: "#001e3c !important",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(23px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="20" viewBox="0 0 22 23"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="m19.41 7.41-4.83-4.83c-.37-.37-.88-.58-1.41-.58H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.42zm-9.18 9.88-2.12-2.12a.9959.9959 0 0 1 0-1.41c.39-.39 1.02-.39 1.41 0l1.41 1.41 3.54-3.54c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-4.24 4.24c-.38.4-1.02.4-1.41.01zM14 9c-.55 0-1-.45-1-1V3.5L18.5 9H14z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#bdbdbd",
    width: 24,
    height: 24,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="20" viewBox="0 0 22 23"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M3.09 4.44c-.39.39-.39 1.02 0 1.41l2.03 2.03-.12.13V19c0 1.1.9 2 2 2h10c.35 0 .68-.1.97-.26l1.17 1.17c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L4.5 4.44a.9959.9959 0 0 0-1.41 0zM19 16.11V5c0-1.1-.9-2-2-2h-6.99L7.95 5.06 19 16.11z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#929292",
    borderRadius: 20 / 2,
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  margin: 0,
  marginBottom: 8,
  "& .MuiTypography-body1": {
    fontWeight: 500,
    fontSize: 20,
  },
});

const ListAdmin = (props) => {
  const { t, i18n } = useTranslation();
  const { handleChangeValueSelected, selectedIndex } = props;
  const { result: adminList } = useSelector((state) => state.admin);
  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    if (adminList) {
      const superAdmin = adminList.filter((item) => item.isSuperAdmin);
      const admin = adminList.filter((item) => !item.isSuperAdmin);
      setAdmin([...superAdmin, ...admin]);
    }
  }, [adminList]);

  console.log(admin);

  return (
    <StyledListCheckEmployees>
      {admin ? (
        admin.map((row, index) => (
          <Fragment key={index}>
            <ListItemButton
              selected={selectedIndex === index}
              onClick={(event) => {
                handleChangeValueSelected(row, index);
              }}
            >
              <ListItem
                secondaryAction={
                  <Typography>
                    {row.isSuperAdmin ? <LocalPoliceRoundedIcon /> : null}
                    {row.isFillIn ? <EditNoteIcon /> : null}
                    {row.confidential && row.confidential[0] && row.confidential[0].readOnly === 1 ? <VisibilityIcon /> : null}
                  </Typography>
                }
              >
                <ListItemAvatar>
                  <Avatar
                    src={`${process.env.REACT_APP_API_URL}image/vendor/${row.idVendor}/${row.personalId}.jpg`}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={getUserFullName(row)}
                  secondary={row.email}
                />
              </ListItem>
            </ListItemButton>
            {selectedIndex !== index && (
              <StyledDivider variant="inset" component="li" />
            )}
          </Fragment>
        ))
      ) : (
        <ListItem style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h6">{t("NoData")}</Typography>
        </ListItem>
      )}
    </StyledListCheckEmployees>
  );
};

function UserManage() {
  useTitle("User Manage - HR CORE DATA");
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: adminList, isFetching } = useSelector((state) => state.admin);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [valueSelected, setValueSelected] = React.useState(null);
  const [confidentialSelected, setConfidentialSelected] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [companySelected, setCompanySelected] = React.useState(null);
  const [openDialogEdit, setOpenDialogEdit] = React.useState(false);
  const [openDialogEditCompany, setOpenDialogEditCompany] =
    React.useState(false);
  const [openDialogEditBusinessUnit, setOpenDialogEditBusinessUnit] =
    useState(false);
  const [openDialogEditDivision, setOpenDialogEditDivision] = useState(false);
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] =
    React.useState(false);
  const [openDialogConfirmChange, setOpenDialogConfirmChange] =
    React.useState(false);
  const [textChange, setTextChange] = React.useState(false);
  const [isLastSuperAdmin, setIsLastSuperAdmin] = React.useState(true);
  const [formData, setFormData] = React.useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [addModal, setAddModal] = useState({
    isOpen: false,
  });

  useEffect(() => {
    dispatch(getAllAdmin());
  }, []);

  useEffect(() => {
    if (adminList) {
      if (valueSelected && selectedIndex) {
        setValueSelected(
          adminList.find((x) => x.idEmployees === valueSelected.idEmployees)
        );
        setSelectedIndex(selectedIndex);
      } else {
        setValueSelected(adminList[0]);
        setSelectedIndex(0);
      }
    }
  }, [adminList]);

  useEffect(() => {
    if (valueSelected && adminList) {
      let findSuperAdmin = adminList.filter((x) => x.isSuperAdmin === 1);
      if (findSuperAdmin.length > 1) {
        setIsLastSuperAdmin(false);
      } else {
        setIsLastSuperAdmin(
          findSuperAdmin[0].idEmployees === valueSelected.idEmployees
        );
      }
    }
  }, [valueSelected]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChangeValueSelected = (value, index) => {
    setValueSelected(value);
    setSelectedIndex(index);
  };

  const handleCloseDialog = () => {
    setOpenDialogConfirmDelete(false);
    setOpenDialogConfirmChange(false);
    setOpenDialogEdit(false);
    setOpenDialogEditCompany(false);
  };

  const handleCloseDialogBusinessUnit = () => {
    setOpenDialogEditBusinessUnit(false);
  };

  const handleCloseDialogDivision = () => {
    setOpenDialogEditDivision(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleUpdateAdmin = async () => {
    if (userProfile) {
      let tempData = { ...formData };
      tempData.updateBy = userProfile.idEmployees;

      console.log("tempData: ", tempData);
      const result = await dispatch(updateAdmin(tempData));
      if (result) {
        handleOpenAlert();
        if (result.status === 200) {
          handleChangeAlertType("success");
          dispatch(getAllAdmin());
        } else {
          handleChangeAlertType("error");
        }
        handleCloseDialog();
      } else {
        handleOpenAlert();
        handleChangeAlertType("error");
        handleCloseDialog();
      }
    }
  };

  const handleDelete = async () => {
    let tempData = {};
    tempData.updateBy = userProfile.idEmployees;
    tempData.idconfidentialData = companySelected.idconfidentialData;
    tempData.idEmployees = companySelected.idEmployees;
    tempData.isActive = 0;

    console.log("tempData: ", tempData);

    const result = await dispatch(updateAdmin(tempData));
    if (result) {
      handleOpenAlert();
      if (result.status === 200) {
        handleChangeAlertType("success");
        dispatch(getAllAdmin());
      } else {
        handleChangeAlertType("error");
      }
      handleCloseDialog();
    } else {
      handleOpenAlert();
      handleChangeAlertType("error");
      handleCloseDialog();
    }
  };

  const handleUpdateAdminBusinessUnit = async () => {
    if (userProfile) {
      let tempData = { ...formData };
      tempData.updateBy = userProfile.idEmployees;

      const res = await dispatch(updateAdminBusinessUnit(tempData));
      if (res) {
        handleOpenAlert();
        if (res.status === 200) {
          handleChangeAlertType("success");
          dispatch(getAllAdmin());
        } else {
          handleChangeAlertType("error");
        }
        handleCloseDialogBusinessUnit();
      } else {
        handleOpenAlert();
        handleChangeAlertType("error");
        handleCloseDialogBusinessUnit();
      }
    }
  };

  const handleUpdateAdminDivision = async () => {
    if (userProfile) {
      let tempData = { ...formData };
      tempData.updateBy = userProfile.idEmployees;

      const res = await dispatch(updateAdminDivision(tempData));
      if (res) {
        handleOpenAlert();
        if (res.status === 200) {
          handleChangeAlertType("success");
          dispatch(getAllAdmin());
        } else {
          handleChangeAlertType("error");
        }
        handleCloseDialogDivision();
      } else {
        handleOpenAlert();
        handleChangeAlertType("error");
        handleCloseDialogDivision();
      }
    }
  };

  return (
    <StyledRoot className={"root"}>
      <Box className="wrap-header">
        <Typography variant="h6">{t("ManageAdministrator")}</Typography>
        <div>
          {/* <ButtonBlue
            variant={"contained"}
            startIcon={<AddIcon />}
            onClick={() => {
              setAddModal({
                ...addModal,
                isOpen: true,
              });
            }}
          >
            เพิ่มผู้ดูแลระบบ
          </ButtonBlue> */}
        </div>
      </Box>

      {isFetching ? (
        <Typography variant="h5" textAlign="center" marginTop={"80px"}>
          {t("Loading")}
        </Typography>
      ) : (
        <div className="section">
          <StyledAdmin>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Paper variant="outlined" className="wrap-admin-list">
                  <div className="head">
                    <StyledLabelHead variant="h4">
                      {t("CurrentList")}
                    </StyledLabelHead>
                    {adminList && (
                      <StyledLabelHead variant="h4">
                        &nbsp;
                        {`${adminList
                          ? adminList.filter((x) => x.isActive).length
                          : 0
                          } Active / ${adminList.length} Total`}
                      </StyledLabelHead>
                    )}
                  </div>

                  <ListAdmin
                    handleChangeValueSelected={handleChangeValueSelected}
                    selectedIndex={selectedIndex}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper variant="outlined" className="wrap-admin-description">
                  {adminList && (
                    <Fragment>
                      <div className="head">
                        <StyledLabelHead variant="h4">
                          {t("Descriptions")}
                        </StyledLabelHead>
                        {/* <IconButton
                          style={{ marginLeft: 4 }}
                          aria-label="add"
                          size="small"
                          onClick={() => {
                            setOpenDialogEdit(true);-
                          }}
                        >
                          <EditNoteRoundedIcon fontSize="small" />
                        </IconButton> */}
                      </div>
                      <div className="admin-description">
                        {valueSelected ? (
                          <Fragment>
                            <div style={{ marginBottom: 16 }}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <StyledHeadLabel color="text.secondary">
                                    {t("FullName")}
                                  </StyledHeadLabel>
                                  <Typography variant="h6">
                                    {getUserFullName(valueSelected)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <StyledHeadLabel color="text.secondary">
                                    {t("PhoneNumber")}
                                  </StyledHeadLabel>
                                  <Typography variant="h6">
                                    {valueSelected.telephoneMobile
                                      ? `${valueSelected.telephoneMobile
                                        .length === 10
                                        ? valueSelected.telephoneMobile.replace(
                                          /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                                          "$1-$2-$3"
                                        )
                                        : valueSelected.telephoneMobile.replace(
                                          /(\d\d)(\d\d\d)(\d\d\d\d)/,
                                          "$1-$2-$3"
                                        )
                                      }`
                                      : "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <StyledHeadLabel color="text.secondary">
                                    {t("Email")}
                                  </StyledHeadLabel>
                                  <Typography variant="h6">
                                    {valueSelected.email
                                      ? valueSelected.email
                                      : "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  {valueSelected.isSuperAdmin ? (
                                    <Chip
                                      size="small"
                                      label={"Super admin"}
                                      color="error"
                                    />
                                  ) : null}
                                  {valueSelected.isFillIn ? (
                                    <Chip
                                      size="small"
                                      label={"Fill in"}
                                      color="primary"
                                    />
                                  ) : null}
                                </Grid>
                              </Grid>
                            </div>
                            <StyledDivider />
                            <div style={{ marginTop: 8 }}>
                              {valueSelected.isFillIn && (
                                <AdminFillIn valueSelected={valueSelected.confidential[0]} />
                              )}
                            </div>
                            {valueSelected.idCompany === null && valueSelected.confidential && (
                              <div style={{ padding: "8px" }}>
                                {valueSelected.confidential.map((company) => (
                                  <Typography className="company-name">
                                    {getUserCompany({
                                      companyName: company.companyName,
                                      companyName_EN: company.companyName_EN,
                                    })}
                                  </Typography>
                                ))}
                              </div>
                            )}
                            <div style={{ marginTop: 16, marginBottom: 16 }}>
                              {!valueSelected.isFillIn && (
                                <StyledFormControlLabel
                                  labelPlacement="start"
                                  control={
                                    <StyledSwitchSuperAdmin
                                      disabled={isLastSuperAdmin}
                                      checked={valueSelected.isSuperAdmin}
                                      onChange={(event) => {
                                        setFormData({
                                          idEmployees: valueSelected.idEmployees,
                                          idconfidentialData: valueSelected.idconfidentialData,
                                          isSuperAdmin: event.target.checked,
                                          isSalary: 0,
                                          isPayroll: 0,
                                          isPerformance: 0,
                                          idCompany: null,
                                        });
                                        setOpenDialogConfirmChange(true);
                                        setTextChange(
                                          `${valueSelected.isSuperAdmin
                                            ? `${t("Cancel")}`
                                            : `${t("Is")}`
                                          } Super admin`
                                        );
                                      }}
                                      className={`${valueSelected.isSuperAdmin
                                        ? "super-admin"
                                        : ""
                                        }`}
                                    />
                                  }
                                  label="Super admin"
                                />
                              )}
                              {!valueSelected.isSuperAdmin && !valueSelected.isFillIn && (
                                <Fragment>
                                  <StyledFormControlLabel
                                    labelPlacement="start"
                                    control={
                                      <StyledSwitchAllCompany
                                        checked={valueSelected.idCompany === null}
                                        onChange={(event) => {
                                          if (event.target.checked) {
                                            setFormData({
                                              idEmployees: valueSelected.idEmployees,
                                              isAllCompany: true,
                                            });
                                            setOpenDialogConfirmChange(true);
                                            setTextChange("ดูแลทุกบริษัท");
                                          } else {
                                            setConfidentialSelected(
                                              valueSelected
                                            );
                                            setOpenDialogEditCompany(true);
                                          }
                                        }}
                                        className={`${valueSelected.idCompany === null
                                          ? "all-company"
                                          : ""
                                          }`}
                                      />
                                    }
                                    label={t("AllCompanies")}
                                  />
                                  {valueSelected.idCompany === null &&
                                    valueSelected.idDepartment === null ? (
                                    <Fragment>
                                      <StyledFormControlLabel
                                        labelPlacement="start"
                                        control={
                                          <StyledSwitchConfidential
                                            checked={valueSelected.isSalary && valueSelected.isPerformance}
                                            onChange={(event) => {
                                              setFormData({
                                                idEmployees: valueSelected.idEmployees,
                                                idconfidentialData: valueSelected.idconfidentialData,
                                                isSuperAdmin: false,
                                                isSalary: event.target.checked,
                                                isPerformance: event.target.checked,
                                                isPayroll: 0,
                                                idCompany: null,
                                              });
                                              setOpenDialogConfirmChange(true);
                                              setTextChange(
                                                `${valueSelected.isSalary &&
                                                  valueSelected.isPerformance
                                                  ? `${t("Hidden")}`
                                                  : `${t("Visible")}`
                                                } ${t("ConfidentialData")}`
                                              );
                                            }}
                                            className={`${valueSelected.isSalary &&
                                              valueSelected.isPerformance
                                              ? "all-company"
                                              : ""
                                              }`}
                                          />
                                        }
                                        label={t("ConfidentialData")}
                                      />
                                      {valueSelected.isSalary &&
                                        valueSelected.isPerformance ? (
                                        <StyledFormControlLabel
                                          labelPlacement="start"
                                          control={
                                            <StyledSwitchPayroll
                                              checked={valueSelected.isPayroll}
                                              onChange={(event) => {
                                                setFormData({
                                                  idEmployees: valueSelected.idEmployees,
                                                  idconfidentialData: valueSelected.idconfidentialData,
                                                  isPayroll: event.target.checked,
                                                  isSuperAdmin: false,
                                                  idCompany: null,
                                                });
                                                setOpenDialogConfirmChange(true);
                                                setTextChange(
                                                  `${valueSelected.isPayroll
                                                    ? `${t("UnableTo")}`
                                                    : `${t("AbleTo")}`
                                                  } ${t("ManageSalary")}`
                                                );
                                              }}
                                              className={`${valueSelected.isPayroll
                                                ? "all-company"
                                                : ""
                                                }`}
                                            />
                                          }
                                          label={t("ManageSalary")}
                                        />
                                      ) : null}
                                    </Fragment>
                                  ) : valueSelected.confidential ? (
                                    <div className="wrap-company">
                                      <div className="company-head">
                                        <StyledHeadLabel
                                          variant="body2"
                                          color="text.secondary"
                                        >
                                          {t("CompanyInCharge")}
                                        </StyledHeadLabel>
                                        <div>
                                          <IconButton
                                            aria-label="add"
                                            size="small"
                                            onClick={() => {
                                              setConfidentialSelected({
                                                idEmployees: valueSelected.idEmployees,
                                                isNew: true,
                                              });
                                              setOpenDialogEditCompany(true);
                                            }}
                                          >
                                            <PlaylistAddRoundedIcon fontSize="small" />
                                          </IconButton>
                                        </div>
                                      </div>
                                      <div className="company-content">
                                        {valueSelected.confidential.map(
                                          (company) => (
                                            <>
                                              <div className="company-list">
                                                <div className="left">
                                                  <IconButton
                                                    disabled={valueSelected.confidential.length === 1}
                                                    className="btn-delete"
                                                    aria-label="delete"
                                                    size="small"
                                                    onClick={() => {
                                                      setOpenDialogConfirmDelete(true);
                                                      setCompanySelected(company);
                                                    }}
                                                  >
                                                    <DeleteOutlineRoundedIcon fontSize="small" />
                                                  </IconButton>
                                                  <Typography className="company-name">
                                                    {getUserCompany({
                                                      companyName: company.companyName,
                                                      companyName_EN: company.companyName_EN,
                                                    })}
                                                  </Typography>
                                                </div>
                                                <div className="right">
                                                  <Tooltip
                                                    title={`${company.isPerformance
                                                      ? `${t("Visible")}`
                                                      : `${t("Hidden")}`
                                                      } ${t("PerformanceRecord")}`}
                                                  >
                                                    <IconButton
                                                      className={`btn-view ${company.isPerformance
                                                        ? "is-true"
                                                        : "is-false"
                                                        }`}
                                                      aria-label="view"
                                                      size="small"
                                                      onClick={() => {
                                                        setFormData({
                                                          idEmployees: valueSelected.idEmployees,
                                                          idconfidentialData: company.idconfidentialData,
                                                          isPerformance: company.isPerformance === 0 ? 1 : 0,
                                                        });
                                                        setCompanySelected(company);
                                                        setOpenDialogConfirmChange(true);
                                                        setTextChange(
                                                          `${company.isPerformance
                                                            ? `${t("Hidden")}`
                                                            : `${t("Visible")}`
                                                          } ${t(
                                                            "PerformanceRecord"
                                                          )}`
                                                        );
                                                      }}
                                                    >
                                                      {company.isPerformance ? (
                                                        <ThumbUpAltRoundedIcon fontSize="small" />
                                                      ) : (
                                                        <DoNotTouchRoundedIcon fontSize="small" />
                                                      )}
                                                    </IconButton>
                                                  </Tooltip>
                                                  <Tooltip
                                                    title={`${company.isSalary
                                                      ? `${t("Visible")}`
                                                      : `${t("Hidden")}`
                                                      } ${t("SalaryDetail")}`}
                                                  >
                                                    <IconButton
                                                      className={`btn-view ${company.isSalary
                                                        ? "is-true"
                                                        : "is-false"
                                                        }`}
                                                      aria-label="view"
                                                      size="small"
                                                      onClick={() => {
                                                        setFormData({
                                                          idEmployees: valueSelected.idEmployees,
                                                          idconfidentialData: company.idconfidentialData,
                                                          isSalary: company.isSalary === 0 ? 1 : 0,
                                                          isPayroll: 0,
                                                        });
                                                        setCompanySelected(company);
                                                        setOpenDialogConfirmChange(true);
                                                        setTextChange(
                                                          `${company.isSalary
                                                            ? `${t("Hidden")}`
                                                            : `${t("Visible")}`
                                                          } ${t("SalaryDetail")}`
                                                        );
                                                      }}
                                                    >
                                                      {company.isSalary ? (
                                                        <AttachMoneyRoundedIcon fontSize="small" />
                                                      ) : (
                                                        <MoneyOffRoundedIcon fontSize="small" />
                                                      )}
                                                    </IconButton>
                                                  </Tooltip>
                                                  <Tooltip
                                                    title={`${company.isPayroll
                                                      ? `${t("AbleTo")}`
                                                      : `${t("UnableTo")}`
                                                      }${t("Payrollrun")}`}
                                                  >
                                                    <IconButton
                                                      disabled={company.isSalary === 0}
                                                      className={`btn-view ${company.isPayroll
                                                        ? "is-true"
                                                        : "is-false"
                                                        }`}
                                                      aria-label="view"
                                                      size="small"
                                                      onClick={() => {
                                                        setFormData({
                                                          idEmployees: valueSelected.idEmployees,
                                                          idconfidentialData: company.idconfidentialData,
                                                          isPayroll: company.isPayroll === 0 ? 1 : 0,
                                                        });
                                                        setCompanySelected(company);
                                                        setOpenDialogConfirmChange(true);
                                                        setTextChange(
                                                          `${company.isPayroll
                                                            ? `${t("UnableTo")}`
                                                            : `${t("AbleTo")}`
                                                          } ${t("Payrollrun")}`
                                                        );
                                                      }}
                                                    >
                                                      {company.isPayroll ? (
                                                        <RequestQuoteRoundedIcon fontSize="small" />
                                                      ) : (
                                                        <SignalCellularNoSimRoundedIcon fontSize="small" />
                                                      )}
                                                    </IconButton>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                              {company.businessUnit && company.businessUnit.length > 0 && (
                                                <>
                                                  <div style={{ display: "flex", alignItems: "center" }}>
                                                    <StyledHeadLabel
                                                      variant="body2"
                                                      color="text.secondary"
                                                      paddingLeft={1}
                                                    >
                                                      {t("BusinessUnit")}
                                                    </StyledHeadLabel>
                                                    <IconButton
                                                      aria-label="add"
                                                      size="small"
                                                      onClick={() => {
                                                        setFormData({
                                                          idEmployees: valueSelected.idEmployees,
                                                          idCompany: company.idCompany,
                                                          businessUnit: [],
                                                        });
                                                        setOpenDialogEditBusinessUnit(true);
                                                      }}
                                                    >
                                                      <PlaylistAddRoundedIcon fontSize="small" />
                                                    </IconButton>
                                                  </div>
                                                  {company.businessUnit.map((item) => (
                                                    <List dense component="div" disablePadding>
                                                      <ListItem sx={{ pl: 4 }}>
                                                        <ListItemText primary={getUserBusinessUnit(item)} />
                                                      </ListItem>
                                                    </List>
                                                  ))}
                                                </>
                                              )}
                                              {company.division && company.division.length > 0 && (
                                                <>
                                                  <div style={{ display: "flex", alignItems: "center" }}>
                                                    <StyledHeadLabel
                                                      variant="body2"
                                                      color="text.secondary"
                                                      paddingLeft={1}
                                                    >
                                                      {t("Division")}
                                                    </StyledHeadLabel>
                                                    <IconButton
                                                      aria-label="add"
                                                      size="small"
                                                      onClick={() => {
                                                        setFormData({
                                                          idEmployees: valueSelected.idEmployees,
                                                          idCompany: company.idCompany,
                                                          division: [],
                                                        });
                                                        setOpenDialogEditDivision(true);
                                                      }}
                                                    >
                                                      <PlaylistAddRoundedIcon fontSize="small" />
                                                    </IconButton>
                                                  </div>
                                                  {company.division.map((item) => (
                                                    <List dense component="div" disablePadding>
                                                      <ListItem sx={{ pl: 4 }}>
                                                        <ListItemText primary={getUserDivision(item)} />
                                                      </ListItem>
                                                    </List>
                                                  ))}
                                                  <Divider style={{ margin: "8px 0px" }} />
                                                </>
                                              )}
                                            </>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  ) : null}
                                </Fragment>
                              )}
                            </div>
                          </Fragment>
                        ) : (
                          <Typography>
                            {t("PleaseSelectAdministrator")}
                          </Typography>
                        )}
                      </div>
                    </Fragment>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </StyledAdmin>

          <StyledMenu
            id={`more-menu`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem>
              <i className="far fa-user-edit" style={{ marginRight: 16 }}></i>
              <Typography>{t("EditAdministrator")}</Typography>
            </MenuItem>

            <MenuItem style={{ color: "red" }}>
              <i className="far fa-trash-can" style={{ marginRight: 16 }}></i>
              <Typography style={{ color: "inherit" }}>
                {t("TerminateService")}
              </Typography>
            </MenuItem>
          </StyledMenu>
        </div>
      )}

      {/* <AddUser addModal={addModal} setAddModal={setAddModal} /> */}
      {companySelected && openDialogConfirmDelete && (
        <DialogConfirmDelete
          open={openDialogConfirmDelete}
          handleClose={handleCloseDialog}
          text={companySelected.companyName}
          text1={getUserFullName(valueSelected)}
          handleDelete={handleDelete}
        />
      )}
      {openDialogConfirmChange && (
        <DialogConfirmChange
          open={openDialogConfirmChange}
          handleClose={handleCloseDialog}
          text={getUserFullName(valueSelected)}
          text1={companySelected ? companySelected.companyName : ""}
          text2={textChange}
          handleUpdateAdmin={handleUpdateAdmin}
        />
      )}

      {openDialogEdit && (
        <DialogEdit
          open={openDialogEdit}
          valueSelected={valueSelected}
          handleCloseDialog={handleCloseDialog}
        />
      )}

      {openDialogEditCompany && (
        <DialogEditCompany
          open={openDialogEditCompany}
          valueSelected={confidentialSelected}
          handleCloseDialog={handleCloseDialog}
          handleSubmit={handleUpdateAdmin}
          formData={formData}
          setFormData={setFormData}
        />
      )}

      {openDialogEditBusinessUnit && (
        <DialogBusinessUnit
          open={openDialogEditBusinessUnit}
          valueSelected={confidentialSelected}
          handleCloseDialog={handleCloseDialogBusinessUnit}
          handleSubmit={handleUpdateAdminBusinessUnit}
          formData={formData}
          setFormData={setFormData}
        />
      )}

      {openDialogEditDivision && (
        <DialogDivision
          open={openDialogEditDivision}
          valueSelected={confidentialSelected}
          handleCloseDialog={handleCloseDialogDivision}
          handleSubmit={handleUpdateAdminDivision}
          formData={formData}
          setFormData={setFormData}
        />
      )}

      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </StyledRoot>
  );
}

export default UserManage;
