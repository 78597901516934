import EmployeeDiligenceAccumulateCustomService from "../services/employeeDiligenceAccumulateCustom.service";

export const addEmployeeDiligenceAccumulateCustom = (formData) => async () => {
  try {
    const res = await EmployeeDiligenceAccumulateCustomService.addEmployeeDiligenceAccumulateCustom(formData);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const updateEmployeeDiligenceAccumulateCustomById = (idEmployeeDiligenceAccumulateCustom, formData) => async () => {
  try {
    const res = await EmployeeDiligenceAccumulateCustomService.updateEmployeeDiligenceAccumulateCustomById(idEmployeeDiligenceAccumulateCustom, formData);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const deleteEmployeeDiligenceAccumulateCustomById = (idEmployeeDiligenceAccumulateCustom) => async () => {
  try {
    const res = await EmployeeDiligenceAccumulateCustomService.deleteEmployeeDiligenceAccumulateCustomById(idEmployeeDiligenceAccumulateCustom);
    return res;
  } catch (error) {
    return error.response;
  }
};