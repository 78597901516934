// KeyResult.js
import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Grid,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import AssessmentIcon from "@mui/icons-material/Assessment";
import InfoIcon from "@mui/icons-material/Info";
import StarRateIcon from "@mui/icons-material/StarRate";
import { AccessTimeRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import CardStyle from "../../../../../shared/general/Card";
import DetailKeyResult from "./dialogDetail";
import EvaluationOrganization from "./evaluation";
import StatusSuccess from "./statusSuccess";
import ChipStatus from "../../../../../shared/pages/okrs/components/ChipStatus";
import KRDetailModal from "./KRDetailModal";

// Styled components for consistency
const IconButtonStyled = styled(IconButton)({
  color: "#DB4178",
  border: "2px solid #DB4178",
  backgroundColor: "transparent",
  borderRadius: "7px",
  "&:hover": {
    color: "#C53B6C",
    border: "2px solid #C53B6C",
    backgroundColor: "transparent",
  },
});

const ProgressBar = styled(LinearProgress)({
  height: 10,
  borderRadius: 5,
  [`&.${LinearProgress.name}-colorPrimary`]: {
    backgroundColor: "#f0f0f0",
  },
  [`& .${LinearProgress.name}-bar`]: {
    borderRadius: 5,
    backgroundColor: "#DB4178",
  },
});

const KeyResult = ({ data }) => {
  const { t } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const isAdmin = userProfile && userProfile.roles.includes("ROLE_ADMIN");

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openEvaluation, setOpenEvaluation] = useState(false);
  const [openDetailModal, setOpenDetailModal] = useState(false); // เพิ่ม state สำหรับเปิด Modal

  const handleOpenDetailDialog = () => {
    setOpenEditDialog(true);
  };

  const handleCloseDetailDialog = () => {
    setOpenEditDialog(false);
  };

  const handleOpenEvaluation = () => {
    setOpenEvaluation(true);
  };

  const handleCloseEvaluation = () => {
    setOpenEvaluation(false);
  };

  const handleOpenDetailModal = () => {
    setOpenDetailModal(true);
  };

  const handleCloseDetailModal = () => {
    setOpenDetailModal(false);
  };

  const getStatusAchieveApprove = (status) => {
    if (status === 1) {
      return 5;
    } else if (status === 0) {
      return 6;
    } else {
      return 4;
    }
  };

  // Calculate progress percentage
  const progress =
    data.progress !== undefined && data.progress !== null ? data.progress : 0;

  // Replace 'rating' with 'score', default to 0 if not available
  const score =
    data.rating !== undefined && data.rating !== null ? data.rating : 0;

  // Format dates
  const startDateFormatted = data.startDate
    ? dayjs(data.startDate).format("DD/MM/YYYY")
    : "-";
  const endDateFormatted = data.endDate
    ? dayjs(data.endDate).format("DD/MM/YYYY")
    : "-";

  return (
    <>
      <CardStyle style={{ height: "100%" }}>
        <Box padding="16px">
          <Grid container spacing={2} alignItems="center">
            {/* คอลัมน์ที่ 1: ChipStatus และชื่อ KR รวมกัน (sm=5, md=5) */}
            <Grid item xs={12} sm={5} md={5}>
              <Box display="flex" alignItems="center" gap={1}>
                <ChipStatus
                  status={getStatusAchieveApprove(data.isAchieve)}
                  size="large"
                />
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {data.keyResultName}
                </Typography>
              </Box>
            </Grid>

            {/* คอลัมน์ที่ 2: วันที่ (sm=3, md=3) */}
            <Grid item xs={12} sm={3} md={3}>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                วันที่:
              </Typography>
              <Box display="flex" alignItems="center" mt={0.5}>
                <AccessTimeRounded
                  fontSize="small"
                  style={{ marginRight: "8px", color: "#919eab" }}
                />
                <Typography variant="body2" color="textSecondary">
                  {startDateFormatted} - {endDateFormatted}
                </Typography>
              </Box>
            </Grid>

            {/* คอลัมน์ที่ 3: ความก้าวหน้าและคะแนน (sm=3, md=3) */}
            <Grid item xs={12} sm={3} md={3}>
              <Box display="flex" alignItems="center" flexDirection="column">
                {/* ความก้าวหน้าและ ProgressBar */}
                <Box display="flex" alignItems="center" width="100%">
                  <Typography
                    variant="body2"
                    style={{ fontWeight: "bold", marginRight: "8px" }}
                  >
                    {t("Progress")}:
                  </Typography>
                  <Box flexGrow={1} mr={1}>
                    <ProgressBar variant="determinate" value={progress} />
                  </Box>
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    {progress}%
                  </Typography>
                </Box>
                {/* คะแนนและ StarRateIcon */}
                <Box display="flex" alignItems="center" width="100%" mt={0.5}>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: "bold", marginRight: "8px" }}
                  >
                    {t("Score")}:
                  </Typography>
                  <Typography variant="body2">{score}</Typography>
                  <StarRateIcon
                    fontSize="small"
                    style={{ color: "#FFC107", marginLeft: "4px" }}
                  />
                </Box>
              </Box>
            </Grid>

            {/* คอลัมน์ที่ 4: ปุ่มไอคอน (sm=1, md=1) */}
            <Grid item xs={12} sm={1} md={1}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
                gap={1}
              >
                {/* ปุ่ม View More */}
                <Tooltip title={t("ViewMore")}>
                  <IconButtonStyled onClick={handleOpenDetailModal}>
                    <InfoIcon fontSize="small" />
                  </IconButtonStyled>
                </Tooltip>

                {/* ปุ่ม Edit (เฉพาะ Admin และสถานะที่อนุญาตให้แก้ไข) */}
                {isAdmin && getStatusAchieveApprove(data.isAchieve) === 4 && (
                  <Tooltip title={t("EditKeyResult")}>
                    <IconButtonStyled onClick={handleOpenDetailDialog}>
                      <EditIcon fontSize="small" />
                    </IconButtonStyled>
                  </Tooltip>
                )}

                {/* ปุ่ม Evaluate (เฉพาะ Admin และถ้าวันที่ปัจจุบันหลังจากสิ้นสุด KR) */}
                {isAdmin && dayjs().isAfter(dayjs(data.endDate)) && (
                  <Tooltip title={t("EvaluateKeyResult")}>
                    <IconButtonStyled onClick={handleOpenEvaluation}>
                      <AssessmentIcon fontSize="small" />
                    </IconButtonStyled>
                  </Tooltip>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CardStyle>

      {/* Dialog สำหรับแก้ไข Key Result */}
      {openEditDialog && (
        <DetailKeyResult
          open={openEditDialog}
          handleClose={handleCloseDetailDialog}
          data={data}
          getStatusAchieveApprove={getStatusAchieveApprove}
        />
      )}

      {/* Dialog สำหรับประเมิน Key Result */}
      {openEvaluation && (
        <EvaluationOrganization
          open={openEvaluation}
          handleClose={handleCloseEvaluation}
          data={data}
        />
      )}

      {/* Dialog สำหรับแสดงรายละเอียดเพิ่มเติม */}
      {openDetailModal && (
        <KRDetailModal
          open={openDetailModal}
          handleClose={handleCloseDetailModal}
          data={data}
          getStatusAchieveApprove={getStatusAchieveApprove}
        />
      )}
    </>
  );
};

export default KeyResult;
