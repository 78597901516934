import React, { Fragment, useState } from "react";
import dayjs from "dayjs";
import { makeStyles, styled } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import utils from "../../../../utils";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({});

const StyledTableRow = styled(TableRow)({
  "&.head": {
    backgroundColor: "#46cbe2",
    "& .MuiTableCell-head": {
      color: "#FFFFFF",
    },
  },
  "& .MuiTableCell-root": {
    height: 20,
    border: "1px solid #e0e0e0",
    "&.cell-data": {
      borderBottom: "none",
      borderTop: "none",
    },
  },
  "&:last-child": {
    "& .MuiTableCell-root": {
      borderBottom: "1px solid #e0e0e0",
    },
  },
});

const StyledTableRowSummary = styled(TableRow)({
  "&.total": {
    backgroundColor: "#EEEEEE",
  },
  "&.sum": {
    "& .net": {
      fontSize: 16,
      fontWeight: 500,
      backgroundColor: "#e0e0e0",
    },
    "& .blank": {
      borderBottom: "none",
      borderLeft: "none",
    },
  },
  "& .MuiTableCell-root": {
    border: "1px solid #e0e0e0",
  },
});

const StyledYearSummary = styled("div")({
  marginTop: 32,
  "& .head, .cell": {
    border: "1px solid #e0e0e0",
  },
  "& .cell": {
    fontSize: 18,
    fontWeight: 500,
  },
});

function TableSlip(props) {
  const { currentSlip } = props;
  const { t, i18n } = useTranslation();

  return (
    <StyledRoot>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <StyledTableRow className="head">
              <TableCell align="left">{t("IncomeList")}</TableCell>
              <TableCell align="right" style={{ width: 120 }}>
                {t("AmountOfMoney")} (
                {t(`CurrencyUnit.${currentSlip.currency}`)})
              </TableCell>
              <TableCell align="left">{t("DeductionList")}</TableCell>
              <TableCell align="right" style={{ width: 120 }}>
                {t("AmountOfMoney")} (
                {t(`CurrencyUnit.${currentSlip.currency}`)})
              </TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {[...Array(12).keys()].map((index) => (
              <StyledTableRow key={index}>
                <TableCell className="cell-data">
                  {currentSlip.addition &&
                    currentSlip.addition[index] &&
                    (i18n.resolvedLanguage === "th"
                      ? `${currentSlip.addition[index].name} ${currentSlip.addition[index].payroundText}`
                      : `${currentSlip.addition[index].name_EN} ${currentSlip.addition[index].payroundText}`)}
                </TableCell>
                <TableCell align="right" className="cell-data">
                  {currentSlip.addition &&
                    currentSlip.addition[index] &&
                    utils.numberWithCommas(currentSlip.addition[index].value)}
                </TableCell>
                {currentSlip.deduction.length === index ? (
                  <Fragment>
                    {currentSlip.idCompany === 74 && (
                      <Fragment>
                        <TableCell className="cell-data">
                          {i18n.resolvedLanguage === "th"
                            ? `เงินได้ต่างประเทศ`
                            : `Pay abroad`}
                        </TableCell>
                        <TableCell align="right" className="cell-data">
                          {utils.numberWithCommas(
                            currentSlip.netAll - currentSlip.net
                          )}
                        </TableCell>
                      </Fragment>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    <TableCell className="cell-data">
                      {currentSlip.deduction &&
                        currentSlip.deduction[index] &&
                        (i18n.resolvedLanguage === "th"
                          ? `${currentSlip.deduction[index].name}`
                          : `${currentSlip.deduction[index].name_EN}`)}
                    </TableCell>
                    <TableCell align="right" className="cell-data">
                      {currentSlip.deduction &&
                        currentSlip.deduction[index] &&
                        utils.numberWithCommas(
                          currentSlip.deduction[index].value
                        )}
                    </TableCell>
                  </Fragment>
                )}
              </StyledTableRow>
            ))}
            {/* {currentSlip.idCompany === 74 && (
              <StyledTableRow key={"1000"}></StyledTableRow>
            )} */}
            <StyledTableRowSummary className="total">
              <TableCell align="left">{t("TotalIncome")}</TableCell>
              <TableCell align="right">
                {utils.numberWithCommas(currentSlip.totalEarnings)}
              </TableCell>
              <TableCell align="left">{t("TotalDeduction")}</TableCell>
              <TableCell align="right">
                {currentSlip.idCompany === 74
                  ? utils.numberWithCommas(
                      currentSlip.totalDeductions +
                        (currentSlip.netAll - currentSlip.net)
                    )
                  : utils.numberWithCommas(currentSlip.totalDeductions)}
              </TableCell>
            </StyledTableRowSummary>
            <StyledTableRowSummary className="sum">
              <TableCell colSpan={2} className="blank"></TableCell>
              <TableCell align="left" className="net">
                {t("NetPay")}
              </TableCell>
              <TableCell align="right" className="net">
                {utils.numberWithCommas(currentSlip.net)}
              </TableCell>
            </StyledTableRowSummary>
          </TableBody>
        </Table>
      </TableContainer>
      <StyledYearSummary>
        <Typography gutterBottom>{`${t("CumulativeIncome")} - ${t(
          "Year"
        )} ${dayjs(currentSlip.monthPeriod).format("BBBB")}`}</Typography>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center" className="head">
                  {t("CumulativeIncome")} (
                  {t(`CurrencyUnit.${currentSlip.currency}`)})
                </TableCell>
                <TableCell align="center" className="head">
                  {t("CumulativeWithholdingTax")} (
                  {t(`CurrencyUnit.${currentSlip.currency}`)})
                </TableCell>
                <TableCell align="center" className="head">
                  {t("CumulativeSS")} (
                  {t(`CurrencyUnit.${currentSlip.currency}`)})
                </TableCell>
                <TableCell align="center" className="head">
                  {t("CumulativePF")} (
                  {t(`CurrencyUnit.${currentSlip.currency}`)})
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center" className="cell">
                  {utils.numberWithCommas(currentSlip.accumulateEarnings)}
                </TableCell>
                <TableCell align="center" className="cell">
                  {utils.numberWithCommas(currentSlip.accumulateTax)}
                </TableCell>
                <TableCell align="center" className="cell">
                  {utils.numberWithCommas(currentSlip.accumulateSSO)}
                </TableCell>
                <TableCell align="center" className="cell">
                  {utils.numberWithCommas(currentSlip.accumulatePF)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </StyledYearSummary>
    </StyledRoot>
  );
}

export default TableSlip;
