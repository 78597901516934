import { httpClient } from "./httpClient";

const addAddition = (formData) => {
  return httpClient.post(`/addition`, formData);
};

const getAllAdditions = () => {
  return httpClient.get("/additions");
};

const getAllAdditionsByCompany = (idCompany) => {
  return httpClient.get(`/company/${idCompany}/additions`);
};

export default {
  addAddition,
  getAllAdditions,
  getAllAdditionsByCompany
};
  