import DoneAll from "@mui/icons-material/DoneAll";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { Box, styled, Typography, useMediaQuery, Container, IconButton, Dialog, DialogTitle, DialogContent, Grid } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import TableCustom from "../../../shared/tableCustom";
import DialogConfirmRejectKpi from "../DialogConfirmRejectKpi";
import CardRequest from "../../../manager/approveList/CardRequest";
import { getKpiPlan, updateKPIplanApprove } from "../../../../../actions/kpi";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";
import { getUserFullName, getUserPosition } from "../../../../../utils/userData";

const StyledButtonCancel = styled(ButtonBlue)({
  backgroundColor: "#4f65df",
  borderRadius: "18px",
  color: "#FFFFFF",
  marginRight: 8,
  width: 180,
  "&:hover": {
    backgroundColor: "#4f65df",
  },
});

const StyledRoot = styled(Box)({})

const StyledDialogTitle = styled(DialogTitle)({
  backgroundColor: "#4f65df",
  height: '40px',
  color: "#FFFFFF",
  borderTopLeftRadius: '18px',
  borderTopRightRadius: '18px',
  fontSize: '28px',
  fontWeight: '600'
});

const StyledDialogContent = styled(DialogContent)({
  minWidth: "700px",
  paddingBottom: "24px",
});

const StyledDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    borderRadius: '18px',
    maxWidth: "800px", 
    transition: 'transform 0.3s ease-out',
  },
});

const PlanBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  margin: '8px 0',
  '& .plan-month': {
    minWidth: '150px', 
    textAlign: 'left',
  },
  '& .plan-details': {
    flexGrow: 1, 
    textAlign: 'left',
    '&:before': {
      content: '"Plan: "',
      color: '#758694', 
    },
  },
});

const GridStyled = styled(Grid)({
  backgroundColor: "#E6EFF5",
  padding: "16px",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "16px",
  height: "70px",
});

const KpiApprovalPlanList = () => {
  const {t,i18n} = useTranslation();
  const mobileResponsive = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const { result: kpiPlanStore } = useSelector((state) => state.kpiApproval);

  const [numberOfList, setNumberOfList] = useState([]);
  const [pendingRows, setPendingRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  useEffect(() => {
    dispatch(getKpiPlan());
  }, [dispatch]);

  const handleDialogOpen = (row) => {
    setSelectedRowData(row);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setTimeout(() => setSelectedRowData(null), 300); 
  };

  const thaiMonths = [
    t("Months.January"), t("Months.February"), t("Months.March"), t("Months.April"), t("Months.May"), t("Months.June"),
    t("Months.July"), t("Months.August"), t("Months.September"), t("Months.October"), t("Months.November"), t("Months.December")
  ];

  const columns = [
    {
      name: t("FullName"),
      minWidth: "200px",
      width: "200px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography>{getUserFullName(row)}</Typography>
            <Typography color="text.third" fontSize="14px">{getUserPosition(row)}</Typography>
          </Box>
        </Box>
      )
    },
    {
      name: t("KPIName"),
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography>{row.kpiName}</Typography>
            <Typography color="text.third" fontSize="14px">{getUserPosition(row)}</Typography>
          </Box>
        </Box>
      )
    },
    {
      name: t("StartDate"),
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            {row.startDate ? dayjs(row.startDate).format('YYYY-MM-DD') : t("InvalidDate")}
            <Typography color="text.third" fontSize="14px">{getUserPosition(row)}</Typography>
          </Box>
        </Box>
      )
    },
    {
      name: t("EndDate"),
      minWidth: "120px",
      width: "120px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            {row.endDate ? dayjs(row.endDate).format('YYYY-MM-DD') : t("InvalidDate")}
            <Typography color="text.third" fontSize="14px">{getUserPosition(row)}</Typography>
          </Box>
        </Box>
      )
    },
    {
      name: "",
      minWidth: "10px",
      width: "10px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <IconButton onClick={() => handleDialogOpen(row)}>
            <SearchIcon />
          </IconButton>
        </Box>
      )
    }
  ]

  const handleApprovalRequest = async (approve, comment) => {
    setApprovalStatus(approve);

    const idKPIs = selectedRows.map(row => row.idKPI);

    const kpiData = idKPIs.map(idKPI => ({
      idKPI,
      isApprove: approve,
      commentKPI: approve === 0 ? comment : null,
    }));

    await updateKPIplanApprove({ kpiData });

    Swal.fire({
      title: t("Success"),
      text: approve ? t("ApprovalSuccessful") : t("RejectionSuccessful"),
      icon: 'success',
      timer: 2000,
      showConfirmButton: false
    });

    setIsOpenRejectDialog(false);
  };

  const showApprovalAlert = () => {
    Swal.fire({
      title: t("ConfirmApproval"),
      text: `${t("DoYouWantToApprove")} ${selectedRows.length > 0 ? `${selectedRows.length} ${t("List")}` : ""} ${t("confirmAsking")}`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirm"),
      cancelButtonText: t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        handleApprovalRequest(1);
        handleDialogClose();
      }
    });
  };

  const showRejectionAlert = () => {
    Swal.fire({
      title: t("ConfirmRejection"),
      text: `${t("DoYouWantToReject")} ${selectedRows.length > 0 ? `${selectedRows.length} ${t("List")}` : ""} ${t("confirmAsking")}`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirm"),
      cancelButtonText: t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        setIsOpenRejectDialog(true);
      }
    });
  };

  const handleConfirmReject = (comment) => {
    handleApprovalRequest(0, comment);
  };

  const handleClickAll = () => {
    setSelectedRows(pendingRows);
  };

  const handleCancel = () => {
    setSelectedRows([]);
  };

  useEffect(() => {
    const tempPending = [];

    kpiPlanStore && kpiPlanStore.map((r) => {
      if (r.idRequestType === 3) {
        if (r.isApprove === null) {
          tempPending.push(r);
        }
      }
    });

    let pendingList = tempPending.sort((a, b) => new Date(b.createDate) - new Date(a.createDate));
    setPendingRows(pendingList);
    setNumberOfList(pendingList.length);
  }, [kpiPlanStore]);

  return (
    <StyledRoot className="page">
      <Container>
        <GridStyled container justifyContent="space-between" alignItems="center" mt={2}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" className="kpi" sx={{ ml: 1, fontSize: "30px" }}>
              {t("PendingKPIPlanApproval")}
            </Typography>
          </Box>
        </GridStyled>
        <div className="cardPlan">
          <Box padding="2px 0">
            <Container maxWidth="lg">
              <Box padding="2px 0" minHeight="700px">
                <Box marginLeft={"auto"} marginRight={"auto"}>
                  {mobileResponsive && (
                    <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
                      {(selectedRows ? selectedRows.length : 0) > 0 && (
                        <StyledButtonCancel startIcon={<DeleteIcon />} onClick={handleCancel}>
                          {t("Cancel")}
                        </StyledButtonCancel>
                      )}
                      {!((selectedRows ? selectedRows.length : 0) === pendingRows.length) && (
                        <ButtonBlue startIcon={<DoneAll />} variant="contained" onClick={handleClickAll}>
                          {t("SelectAll")}
                        </ButtonBlue>
                      )}
                    </Box>
                  )}
                  {selectedRows.length > 0 && (
                    <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
                      <ButtonBlue
                        style={{ marginRight: 8 }}
                        startIcon={<DoneAll />}
                        variant="contained"
                        onClick={showApprovalAlert}
                      >
                        {`${t("Approve")} ${selectedRows.length > 0 ? `${selectedRows.length} ${t("List")}` : ""}`}
                      </ButtonBlue>
                      <ButtonBlue
                        startIcon={<DoneAll />}
                        variant="outlined"
                        onClick={showRejectionAlert}
                      >
                        {`${t("Reject")} ${selectedRows.length > 0 ? `${selectedRows.length} ${t("List")}` : ""}`}
                      </ButtonBlue>
                    </Box>
                  )}
                  {!mobileResponsive && kpiPlanStore ? (
                    <TableCustom
                      columns={columns}
                      rows={kpiPlanStore}
                      canSelect
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                      rowRender={(row) => (
                        <React.Fragment key={row.idKPI}>
                          <tr>
                            {columns.map((column, index) => (
                              <td key={index}>{column.cellRender(row)}</td>
                            ))}
                          </tr>
                        </React.Fragment>
                      )}
                    />
                  ) : (
                    <div>
                      {kpiPlanStore && kpiPlanStore.length > 0 ? (
                        pendingRows.map((row) => (
                          <CardRequest
                            key={row.idKPI}
                            row={row}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                          />
                        ))
                      ) : (
                        <div>
                          <Box height="120px" display="flex" justifyContent="center" alignItems="center">
                            <Typography variant="h6">
                              <FindInPageIcon />
                              {t("NoData")}
                            </Typography>
                          </Box>
                        </div>
                      )}
                    </div>
                  )}
                  <DialogConfirmRejectKpi
                    rejectCount={selectedRows.length}
                    open={isOpenRejectDialog}
                    onClose={() => setIsOpenRejectDialog(false)}
                    handleSubmit={(comment) => handleConfirmReject(comment)}
                  />
                </Box>
              </Box>
            </Container>
          </Box>
        </div>
      </Container>
      {selectedRowData && (
        <StyledDialog open={dialogOpen} onClose={handleDialogClose}>
          <StyledDialogTitle>KPI PLAN</StyledDialogTitle>
          <StyledDialogContent>
            {selectedRowData.plans && selectedRowData.plans.length > 0 ? (
              selectedRowData.plans.map((plan, index) => (
                <PlanBox key={index}>
                  <Typography className="plan-month">{thaiMonths[plan.month - 1]}</Typography>
                  <Typography className="plan-details">{`${plan.kpiPlan}`}</Typography>
                </PlanBox>
              ))
            ) : (
              <Typography>{t("NoData")}</Typography>
            )}
          </StyledDialogContent>
          {/* <DialogActions style={{ paddingBottom: "24px" , paddingRight: "20px"}}>
            <ButtonBlue 
              onClick={() => {
                showApprovalAlert();
                handleDialogClose();
              }} 
              variant="contained"
            >
              อนุมัติ
            </ButtonBlue>
            <ButtonBlue 
            color="error"
            onClick={() => {
              showRejectionAlert();
              handleDialogClose();
            }} 
            >
              ไม่อนุมัติ
            </ButtonBlue>
          </DialogActions> */}
        </StyledDialog>
      )}
    </StyledRoot>
  );
};

export default KpiApprovalPlanList;
