import React from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { Grid, MenuItem, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import { useTranslation } from "react-i18next";
import {updateProbationAdmin } from "../../../../../../actions/probation";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";

const DialogEdit = ({ open, handleClose, probation }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );

  const validateYupSchema = yup.object({
    probationResult: yup.number().required("กรุณากรอกข้อมูล"),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      probationResult: probation.adminResult
        ? probation.adminResult
        : probation.managerResult,
    },
    resolver: yupResolver(validateYupSchema),
  });

  const onSubmit = async (data) => {
    const formData = {
      idProbation: probation.idProbation,
      probationResult: data.result,
    };

    const res = await dispatch(updateProbationAdmin(formData));
    if (res && res.status === 200) {
      dispatch(openNotificationAlert({ message: "success", type: "success" }));
      dispatch(getEmployeeProfile(employeeProfile.idEmployees));
    } else {
      dispatch(openNotificationAlert({ message: "error", type: "error" }));
    }
    handleClose();
  };

  return (
    <DrawerCustom
      title={`${t("EditProbationResult")}`}
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      {probation && (
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12}>
            <Typography fontSize="16px" fontWeight="600">
              {`${t("ProbationRound")} ${probation.probationRound}`}
            </Typography>
          </Grid>
          {probation.expandTime && (
            <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
              <AddCircleIcon />
              <Typography>
                {`${t("ExtendProbationPeriod")}: ${probation.expandTime} ${t(
                  "Unit.Days"
                )}`}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
            <CheckCircleIcon />
            <Typography>
              {`${t("EvaluatedBy")} : ${
                probation.adminResult
                  ? t("Administrator")
                  : i18n.resolvedLanguage === "th"
                  ? probation.approvedBy
                  : probation.approvedBy_EN
              }`}
            </Typography>
          </Grid>
          {probation.result === null && (
            <Grid item xs={12}>
              <Typography marginBottom={1}>
                {t("ReasonForExtendingProbation")}
              </Typography>
              <TextFieldTheme
                inputProps={{ readOnly: true }}
                multiline
                value={probation.reasonExpandTime}
              />
            </Grid>
          )}
          {probation.result === 0 && (
            <Grid item xs={12}>
              <Typography marginBottom={1}>
                {t("ReasonForNotPassingProbation")}
              </Typography>
              <TextFieldTheme
                inputProps={{ readOnly: true }}
                multiline
                value={probation.reasonTermination}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div style={{ marginBottom: "1rem" }}>
                <Typography fontSize="14px" fontWeight="600" marginBottom={1}>
                  {t("ProbationResult")}
                </Typography>
                <Controller
                  name="probationResult"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextFieldTheme
                      {...field}
                      select
                      helperText={
                        fieldState.error && fieldState.error.message
                      }
                      error={Boolean(fieldState.error)}
                    >
                      <MenuItem value={1}>{t("PassedProbation")}</MenuItem>
                      <MenuItem value={0}>{t("NotPassedProbation")}</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </div>
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <ButtonBlue
                  variant="text"
                  onClick={handleClose}
                  disabled={isSubmitting}
                >
                  {t("Cancel")}
                </ButtonBlue>
                <ButtonBlue
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {t("Save")}
                </ButtonBlue>
              </div>
            </form>
          </Grid>
        </Grid>
      )}
    </DrawerCustom>
  );
};

export default DialogEdit;
