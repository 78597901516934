import ExcelJS from 'exceljs';

export const JobManagementXlsx = async (t,data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet(t("ManageJobPosition"));

    const headerRow = worksheet1.addRow([
        `${t("positionCode")} (Job Code)`,
        `${t("PositionName")} (Main language)`,
        `${t("PositionName")} (English)`,
        `${t("JobGroup")} (Job Group)`,
        `${t("Section")}`,
        `${t("Department")}`,
        `${t("Division")}`,
        t("BusinessUnit"),
        t("Company")
    ]);

    headerRow.height = 50;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
    });

    const colWidths = [
        { key: "positionCode", width: 40 },
        { key: "positionName", width: 40 },
        { key: "positionNameEn", width: 40 },
        { key: "JobGroup", width: 40 },
        { key: "Section", width: 40 },
        { key: "Department", width: 40 },
        { key: "Division", width: 40 },
        { key: "Bu", width: 40 },
        { key: "Company", width: 40 },
    ];

    data.forEach((item) => {
        const row = [
            item.positionCode ? item.positionCode : "-",
            item.positionName ? item.positionName : "-",
            item.positionName_EN ? item.positionName_EN : "-",
            item.jobGroupName ? item.jobGroupName : "-",
            item.sectionName ? item.sectionName : "-",
            item.departmentName ? item.departmentName : "-",
            item.divisionName ? item.divisionName : "-",
            item.businessUnitName ? item.businessUnitName : "-",
            item.companyName ? item.companyName : "-",
        ];

        const excelRow = worksheet1.addRow(row);
        const contentStyle = { font: { size: 18, name: 'TH SarabunPSK' } };

        colWidths.forEach((col, index) => {
            worksheet1.getColumn(index + 1).width = col.width;
        });

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    })

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${t("ManageJobPosition")}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
}