import React, { Fragment, useState, useEffect } from "react";
import dayjs from 'dayjs';
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Chip, Divider, Grid, IconButton, styled, Tooltip, Typography, Rating } from "@mui/material";

import CardStyle from "../../../../../shared/general/Card";
import DrawerEducationsExperiencesLeft from "./drawerEducationsExperiencesLeft";
import DrawerName from "./drawerName";
import DrawerPersonalInfo from "./drawerPersonalInfo";
import DrawerStatus from "./drawerStatus";
import DrawerJobGroup from "./drawerJobGroup";
import { getManpowerById, updateManpowerStatus, getAllStatusManpower } from "../../../../../../../actions/manpower";

import DialogUploadResume from "./dialogUploadResume";
import Resume from "./resume";
import utils, { stringToColor } from "../../../../../../../utils";
import Manpower from "../../../../manpower";

const StyledRoot = styled('div')({
  padding: 24,
  paddingTop: 16,
  "& .part-avatar": {
    marginBottom: 16,
    display: "flex",
    justifyContent: "center",
    "& .MuiAvatar-root": {
      width: 150,
      height: 150,
    },
  },
  "& .part-name": {
    "& .MuiTypography-h5": {
      textAlign: "center",
    },
    "& .vendor-name": {
      textAlign: "center",
      color: "#007afd",
    },
    "& .part-star-rating": {
      margin: "10px 0",
      display: "flex",
      justifyContent: "center",
    },
  },
  "& .MuiDivider-root": {
    margin: "8px 0",
    borderWidth: "0px 0px thin",
    borderColor: "#919eab52",
    borderStyle: "dashed",
  },
  "& .header-text": {
    marginBottom: 16,
    fontSize: 20,
  }
})

const StyledChipAlrJob = styled(Chip)({
  width: 150,
  borderRadius: 4,
  height: "unset",
  padding: 8,
  backgroundColor: "#eb7d7d",
  color: "#b81a1a",
  fontSize: 14,
});

const StyledChipFree = styled(Chip)({
  width: 150,
  borderRadius: 4,
  height: "unset",
  padding: 8,
  fontSize: 14,
  backgroundColor: "#76d576",
  color: "#0b5f10"
});

const StyledChipWaiting = styled(Chip)({
  width: 150,
  borderRadius: 4,
  height: "unset",
  padding: 8,
  fontSize: 14,
  backgroundColor: "#fff0ce",
  color: "#ff9800"
});

const BoxTooltip = styled(Box)({
  display: "flex",
  padding: "5px 10px",
  "& .firstChip": {
    marginRight: 8
  }
});

const EditIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 0,
  top: 0,
  padding: 8,
  color: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: "transparent"
  }
}));

const BoxFlexColumn = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative"
}));

const BoxAndEditButton = (props) => {
  const { component: Component, children } = props
  const { result: ManpowerProfile } = useSelector(state => state.manpowerProfile);
  const { result: UserMe } = useSelector(state => state.userProfile);
  const [openDrawer, setOpenDrawer] = useState(false);

  const onClose = () => {
    setOpenDrawer(false);
  }

  return (
    <BoxFlexColumn>
      {UserMe && ManpowerProfile &&
        // UserMe.idEmployees === ManpowerProfile.idHunter &&
        (UserMe.idRole === 4 || UserMe.idRole === 5) &&
        <EditIconButton
          size="small"
          onClick={() => { setOpenDrawer(true) }}
        >
          <i className="fa-solid fa-pen" />
        </EditIconButton>
      }
      {children}
      {openDrawer &&
        <Component open={openDrawer} onClose={onClose} data={ManpowerProfile} idManpower={ManpowerProfile.idManpower} />
      }
    </BoxFlexColumn>
  );
};

const LeftPanel = () => {

  const { result: ManpowerProfile } = useSelector(state => state.manpowerProfile);
  const { result: UserMe } = useSelector(state => state.userProfile);
  const [isOpenDialogResume, setIsOpenDialogResume] = useState(false);
  const [rating, setRating] = useState(3);

  const dispatch = useDispatch();

  const renderAge = (totalMonth) => {
    const year = Math.floor(totalMonth / 12);
    const month = (totalMonth % 12)
    return `${year} ปี ${month} เดือน`
  }

  // const [DataAllStatusManpower, SetDataAllStatusManpower] = useState(null);

  const [DataAllStatusManpower, SetDataAllStatusManpower] = useState([
    {
      "idManpowerStatus": 1,
      "statusName": "ว่าง"
    },
    {
      "idManpowerStatus": 2,
      "statusName": "ไม่ว่าง"
    },
    {
      "idManpowerStatus": 3,
      "statusName": "รอดำเนินการ"
    }
  ]);

  // useEffect(() => {
  //   const fetchData = () => {
  //     dispatch(getAllStatusManpower())
  //       .then(res => {
  //         SetDataAllStatusManpower(res.data);
  //       })
  //       .catch(error => {
  //         console.error('Failed to fetch all data StatusManpower :', error);
  //       });
  //   };
  //   fetchData();
  // }, []);

  // const onChangeStatusHandler = (idManpowerStatus) => {
  //   dispatch(updateManpowerStatus(ManpowerProfile.idManpower, { idManpowerStatus: idManpowerStatus })).then(res => {
  //     dispatch(getManpowerById(ManpowerProfile.idManpower));
  //   })
  // }
  // console.log(DataAllStatusManpower);

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardStyle>
            <StyledRoot>
              <BoxAndEditButton
                component={DrawerName}
              >
                {ManpowerProfile && <div className="part-avatar">
                  {/* <Avatar src={`${process.env.REACT_APP_API_URL}image/profile/${(ManpowerProfile.idManpower % 40)+1}.jpg`}/> */}
                  <Avatar sx={{ bgcolor: stringToColor(`${ManpowerProfile.name} ${ManpowerProfile.lastname}`), fontSize: 48 }} children={`${ManpowerProfile.name[0]}${ManpowerProfile.lastname[0]}`} />
                </div>}

                <div className="part-name">
                  {/* <Typography variant="h5">
                    {ManpowerProfile ? `${ManpowerProfile.name} ${ManpowerProfile.lastname}` : "-"}
                  </Typography> */}
                  <Typography variant="h5">{ManpowerProfile.title} {ManpowerProfile.name} {ManpowerProfile.lastname}</Typography>
                  <div className="part-star-rating">
                    <Rating value={ManpowerProfile.rating} readOnly />
                  </div>
                  {/* <Typography className="vendor-name">{ManpowerProfile ? ManpowerProfile.fullname : "-"}</Typography> */}

                </div>
              </BoxAndEditButton>
              <Divider />
              <BoxAndEditButton
                component={DrawerPersonalInfo}
              >

                <Typography variant="caption" color="text.secondary" fontSize={14}>เลขบัตรประชาชน</Typography>
                <Typography>{ManpowerProfile.personalId || "-"}</Typography>

                <Typography variant="caption" color="text.secondary" fontSize={14}>อายุ</Typography>
                <Typography>{ManpowerProfile.dateOfBirth ? renderAge(dayjs(new Date()).diff(ManpowerProfile.dateOfBirth, 'month')) : "-"}</Typography>

                <Typography variant="caption" color="text.secondary" fontSize={14}>เบอร์โทร</Typography>
                <Typography>{ManpowerProfile && ManpowerProfile.telephoneMobile || "-"}</Typography>

                <Typography variant="caption" color="text.secondary" fontSize={14}>อีเมล</Typography>
                <Typography>{ManpowerProfile && ManpowerProfile.email || "-"}</Typography>
              </BoxAndEditButton>
              <Divider />
              <BoxAndEditButton
                component={DrawerEducationsExperiencesLeft}
              >
                <Typography variant="caption" color="text.secondary" fontSize={14}>ประสบการณ์ทำงาน</Typography>

                <Typography>
                  {(ManpowerProfile['OESY(Y)'] || ManpowerProfile['OESY(M)']) ?
                    <>
                      {ManpowerProfile['OESY(Y)'] && `${ManpowerProfile['OESY(Y)']} ปี`} {ManpowerProfile['OESY(M)'] && `${ManpowerProfile['OESY(M)']} เดือน`}
                    </> :
                    'ไม่มีประสบการณ์ทำงาน'}
                </Typography>

                <Typography variant="caption" color="text.secondary" fontSize={14}>ระดับการศึกษา</Typography>
                <Typography>{ManpowerProfile['educationDegree'] || 'ไม่ระบุระดับการศึกษา'}</Typography>

                <Typography variant="caption" color="text.secondary" fontSize={14}>เงินเดือนปัจจุบัน</Typography>
                <Typography>{(ManpowerProfile['currentSalary'] && `${utils.numberWithCommas(ManpowerProfile['currentSalary'])} บาท`) || '-'}</Typography>

                <Typography variant="caption" color="text.secondary" fontSize={14}>เงินเดือนที่คาดหวัง</Typography>
                <Typography>{(ManpowerProfile['expectedSalary'] && `${utils.numberWithCommas(ManpowerProfile['expectedSalary'])} บาท`) || '-'}</Typography>

              </BoxAndEditButton>
            </StyledRoot>
          </CardStyle>
        </Grid>

        <Grid item xs={12}>
          <CardStyle>
            <StyledRoot>
              <BoxAndEditButton
                component={DrawerStatus}
              >
                <Typography className="header-text" variant="h5">สถานะ</Typography>
                {DataAllStatusManpower && DataAllStatusManpower.map((status) => {
                  if (ManpowerProfile.idManpowerStatus === status.idManpowerStatus) {
                    if (status.idManpowerStatus === 1) {
                      return <StyledChipFree key={status.idManpowerStatus} label={status.statusName} />;
                    } else if (status.idManpowerStatus === 2) {
                      return <StyledChipAlrJob key={status.idManpowerStatus} label={status.statusName} />;
                    } else if (status.idManpowerStatus === 3) {
                      return <StyledChipWaiting key={status.idManpowerStatus} label={status.statusName} />;
                    }
                  }
                  return null;
                })}
                {/* {DataAllStatusManpower.map((status) => {
                      if (ManpowerProfile.idManpowerStatus === status.idManpowerStatus) {
                        if ([1, 6].includes(status.idManpowerStatus)) {
                          return <StyledChipFree key={status.idManpowerStatus} label={status.statusName} />;
                        } else if ([2, 7, 8, 9].includes(status.idManpowerStatus)) {
                          return <StyledChipAlrJob key={status.idManpowerStatus} label={status.statusName} />;
                        } else if ([3, 4, 5].includes(status.idManpowerStatus)) {
                          return <StyledChipWaiting key={status.idManpowerStatus} label={status.statusName} />;
                        }
                      }
                      return null;
                    })} */}

                {/* {UserMe && (UserMe.idEmployees === ManpowerProfile.idHunter) ? (
                  <Fragment>
                    {ManpowerProfile.idManpowerStatus === 6 && (
                      <Tooltip
                        title={
                          <BoxTooltip>
                            <StyledChipAlrJob label="ไม่ว่าง" className="firstChip" onClick={() => { onChangeStatusHandler(7) }} />
                          </BoxTooltip>
                        }
                        placement="top"
                      >
                        <StyledChipFree label="ว่าง" />
                      </Tooltip>
                    )}

                    {ManpowerProfile.idManpowerStatus === 7 && (
                      <Tooltip
                        title={
                          <BoxTooltip>
                            <StyledChipFree label="ว่าง" className="firstChip" onClick={() => { onChangeStatusHandler(6) }} />
                          </BoxTooltip>
                        }
                        placement="top"
                      >
                        <StyledChipAlrJob label="ไม่ว่าง" />
                      </Tooltip>
                    )}
                    {DataAllStatusManpower.map((status) => (
                      ManpowerProfile.idManpowerStatus === status.idManpowerStatus && (
                        <StyledChipFree key={status.idManpowerStatus} label={status.statusName} />
                      ) 
                    ))}
                    {DataAllStatusManpower.map((status) => {
                      if (ManpowerProfile.idManpowerStatus === status.idManpowerStatus) {
                        if ([1, 6].includes(status.idManpowerStatus)) {
                          return <StyledChipFree key={status.idManpowerStatus} label={status.statusName} />;
                        } else if ([2, 7, 8, 9].includes(status.idManpowerStatus)) {
                          return <StyledChipAlrJob key={status.idManpowerStatus} label={status.statusName} />;
                        } else if ([3, 4, 5].includes(status.idManpowerStatus)) {
                          return <StyledChipWaiting key={status.idManpowerStatus} label={status.statusName} />;
                        }
                      }
                      return null;
                    })}

                    {ManpowerProfile.idManpowerStatus === 6 && (
                      <StyledChipFree label="ว่าง" />
                    )}

                    {ManpowerProfile.idManpowerStatus === 7 && (
                      <StyledChipAlrJob label="ไม่ว่าง" />
                    )}

                  </Fragment>
                ) : (
                  <Fragment>
                    {ManpowerProfile.idManpowerStatus === 6 && (
                      <StyledChipFree label="ว่าง" />
                    )}
                    {ManpowerProfile.idManpowerStatus === 7 && (
                      <StyledChipAlrJob label="ไม่ว่าง" />
                    )}
                  </Fragment>
                )}

                {![6, 7, 8].includes(ManpowerProfile.idManpowerStatus) && (
                  <StyledChipWaiting label="รอดำเนินการ" />
                )} */}
              </BoxAndEditButton>
            </StyledRoot>
          </CardStyle>
        </Grid>

        {/* <Grid item xs={12}>
          <CardStyle>
            <Box padding="24px">
              <ButtonBlue
                startIcon={<i className="fa-solid fa-up-to-line"></i>}
                variant="outlined"
                fullWidth
                padding="16px"
                onClick={()=>{setIsOpenDialogResume(true)}}
              >
                อัพโหลด เรซูเม่
              </ButtonBlue>
              
              <DialogUploadResume
                open={isOpenDialogResume}
                onClose={()=>{setIsOpenDialogResume(false)}}
              />
            </Box>
          </CardStyle>
        </Grid> */}


        <Grid item xs={12}>
          <CardStyle>
            <StyledRoot>
              <BoxAndEditButton
                component={DrawerJobGroup}
              >
                <Typography className="header-text" variant="h5">กลุ่มงาน</Typography>
                {ManpowerProfile.jobGroups.length > 0 ?
                  (
                    <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                      {ManpowerProfile.jobGroups.map(item => {
                        return <Chip color="primary" key={item.idJobGroup} label={item.jobGroupName} />
                      })}
                    </div>
                  )
                  :
                  (
                    <Typography align="center" style={{ width: "100%", color: "#919eab", margin: "15px 0" }}>ไม่พบกลุ่มงาน</Typography>
                  )
                }
              </BoxAndEditButton>
            </StyledRoot>
          </CardStyle>
        </Grid>

        <Grid item xs={12}>
          <Resume
            idManpower={ManpowerProfile.idManpower}
            data={ManpowerProfile.resume}
            isMyManpower={
              UserMe && ManpowerProfile &&
              // (UserMe.idEmployees === ManpowerProfile.idHunter)
              (UserMe.idRole === 4 || UserMe.idRole === 5)
            }
          />
        </Grid>

      </Grid>
    </Fragment>
  )
}

export default LeftPanel;