import { httpClient } from "./httpClient";

const getAllJobPosition = () => {
  return httpClient.get("jobPositions");
};

const postJobPosition = (data) => {
  return httpClient.post(`jobPositions/postJobPosition`, data);
};

const putJobPosition = (id, data) => {
  return httpClient.post(`jobPositions/putJobPosition/${id}`, data);
};

const deleteJobPosition = (id) => {
  return httpClient.post(`jobPositions/deleteJobPosition/${id}`);
};

export default {
  getAllJobPosition,
  postJobPosition,
  putJobPosition,
  deleteJobPosition,
};
