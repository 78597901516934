import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import { getUserFirstName, getUserLastName, getUserTitle } from '../../../../../utils/userData';
export const PVFxlsxFile = (t,employeeDetails) => {
    const presentDay = new Date();
    console.log(employeeDetails)
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    // Center-align headers and set font size
    const headerRow = worksheet.addRow([
        t("CompanyCode"),
        t("EmployeeID"),
        t("Code")+t("Department"),
        t("Title"),
        t("FirstName"),
        t("LastName"),
        t("SubmissionDate"),
        `${t("Contribution")} (${t("Employee")})`,
        `${t("Contribution")} (${t("Company")})`,
        "Form Amt",
        t("Investment"),
        t("PersonalID")
    ]);

    headerRow.height = 50;

    const headerStyle = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK' },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'DDDDDD' }
        },
    };

    headerRow.eachCell((cell) => {
        cell.style = headerStyle;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    if (employeeDetails && employeeDetails.employeeDetails.length > 0) {
        for (let itemIndex = 0; itemIndex < employeeDetails.employeeDetails.length; itemIndex++) {
            const item = employeeDetails.employeeDetails[itemIndex];
            const valueItem = employeeDetails.companyValue[itemIndex];

            const thaiFormattedDate = item.payDate ? dayjs(item.payDate).locale('th').format('DD MMMM') : "-";
            const thaiFormattedYear = item.payDate ? dayjs(item.payDate).format('YYYY') : "-";
            const thaiYear = parseInt(thaiFormattedYear) + 543;

            const row = [
                item.companyCode || "",
                item.employeeID || "",
                item.departmentCode || "",
                getUserTitle(item) || "",
                getUserFirstName(item) || "",
                getUserLastName(item) || "",
                item.payDate ? thaiFormattedDate + " " + thaiYear : "-",
                item.value ? item.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-",
                valueItem && valueItem.value ? valueItem.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-",
                "",
                "",
                item.taxID ? item.taxID : "",
            ];

            const excelRow = worksheet.addRow(row);

            // Apply font size to the entire row
            excelRow.font = { size: 16, name: 'TH SarabunPSK' };

            // Apply black border for every column
            excelRow.eachCell((cell) => {
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            });
        }

        // Set column widths to make the appearance better
        const colWidths = [
            { key: "companyCode", width: 20 },
            { key: "employeeID", width: 20 },
            { key: "departmentCode", width: 20 },
            { key: "title_TH", width: 20 },
            { key: "firstname_TH", width: 30 },
            { key: "lastname_TH", width: 30 },
            { key: "presentDay", width: 20 },
            { key: "value", width: 30 },
            { key: "valueItem", width: 30 },
            { key: "", width: 20 },
            { key: "", width: 20 },
            { key: "taxID", width: 40 },
        ];

        colWidths.forEach((col, index) => worksheet.getColumn(index + 1).width = col.width);

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `PF Excel ${t("Investment")}.xlsx`;
            a.click();
            URL.revokeObjectURL(url);
        });
    } else {
        console.log("No data");
    }
};
