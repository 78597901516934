import {
  EMPLOYEE_SEARCH_FETCHING,
  EMPLOYEE_SEARCH_FAILED,
  EMPLOYEE_SEARCH_SUCCESS
} from "../actions/types";

const initialState = {
  result: {},
  isFetching: false,
  isError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
      case EMPLOYEE_SEARCH_FETCHING:
          return { ...state, result: {}, isFetching: true, isError: false };
      case EMPLOYEE_SEARCH_FAILED:
          return { ...state, result: {}, isFetching: false, isError: true };
      case EMPLOYEE_SEARCH_SUCCESS:
          return { ...state, result: payload, isFetching: false, isError: false };
      default:
          return state;
  }
}
