import React from "react";
import { useTranslation } from "react-i18next";
import ExcelJS from "exceljs";
import DataGrid, { Column, Summary, TotalItem, Export, Row } from "devextreme-react/data-grid";
import dayjs from "dayjs";

export const PayrollDataGrid = ({ data, option, companyName }) => {
  const { t } = useTranslation();

  const transformedData = [
    {
      company: companyName || t("CompanyName"),
      ...Array.from({ length: 12 }, (_, i) => {
        const rawValue = data[i] && data[i].value ? data[i].value[option] : null;
        const value = rawValue ? parseFloat(rawValue.replace(/,/g, "")) : 0;
        return { [`month${i + 1}`]: value };
      }).reduce((acc, curr, index) => {
        acc[`month${index + 1}`] = curr[`month${index + 1}`];
        return acc;
      }, {})
    }
  ];

  const result = transformedData.map(entry => {
    const totalSum = Object.entries(entry)
      .filter(([key]) => key.startsWith("month"))
      .reduce((sum, [, value]) => sum + value, 0);
    return { ...entry, totalSum };
  });



  const onExporting = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(t("PayrollReport"));

    const headerRow = worksheet.addRow([
      t("Company"),
      ...Array.from({ length: 12 }, (_, i) => dayjs().month(i).format("MMMM")),
      t("Total")
    ]);

    headerRow.height = 50;

    headerRow.eachCell((cell) => {
      cell.font = { size: 12, name: "TH SarabunPSK", color: { argb: "FFFFFF" } };
      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "002060" }
      }
    });

    result.forEach((item) => {
      const row = [
        item.company,
        ...Array.from({ length: 12 }, (_, i) => item[`month${i + 1}`] || 0),
        item.totalSum,
      ];
      worksheet.addRow(row)
    });

    // Export to Excel
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${t("PayrollReport")}.xlsx`;
      link.click();
    });
  };


  return (
    <div>
      <DataGrid
        dataSource={result}
        showBorders={false}
        showColumnLines={false}
        rowAlternationEnabled={true}
        columnAutoWidth={true}
        onExporting={onExporting}
      >
        <Column caption={t("Company")} dataField="company" alignment="center" cssClass="column-info" />

        {Array.from({ length: 12 }, (_, i) => {
          const month = dayjs().month(i).format("MMMM");
          return (
            <Column
              key={i}
              caption={month}
              dataField={`month${i + 1}`}
              dataType="number"
              format="###,###,##0.00"
              alignment="center"
              cssClass="column-info"
            />
          );
        })}

        <Column caption={t("Total")} dataField="totalSum" alignment="center" cssClass="column-info" customizeText={(e) => parseFloat(e.value).toFixed(2)} />

        <Export enabled={true} fileName={t("PayrollReport")} />

      </DataGrid>
    </div>
  );
};
