import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Button,
  Stack,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Chip,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import dayjs from "dayjs";
import CreateCompanyPolicy from "./createCompanyPolicy";
import { useDispatch, useSelector } from "react-redux";
import { getPolicyByIdCompany, deletePolicy } from "../../../../actions/policy";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { openNotificationAlert } from "../../../../actions/notificationAlert";

const WrapHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
  marginTop: 8,
});

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    sx={{
      backgroundColor: props.isActive ? "#b2dfdb" : "#ff7961",
    }}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(3),
  },
}));

const DialogDelete = ({ open, handleClose, handleDelete }) => {
  const { t, i18n } = useTranslation();

  return (
    <Dialog open={open}>
      <DialogTitle>{`${t("ConfirmToDelete")}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Policy {`${t("WillBePermanentlyDeleted")}`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={handleDelete}>
          {`${t("Confirm")}`}
        </Button>
        <Button onClick={handleClose}>{`${t("Cancel")}`}</Button>
      </DialogActions>
    </Dialog>
  );
};

const PolicyDetail = (props) => {
  const { updateAt, isActive, handleSelect, handleOpenDelete, ...data } = props;
  const { idPolicy, title, details, file, startDate, endDate } = data;
  const { t, i18n } = useTranslation();

  return (
    <>
      <Accordion disableGutters>
        <StyledAccordionSummary isActive={isActive}>
          <Typography variant="h6">{title}</Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
            marginBottom={1}
          >
            <Typography variant="subtitle1" color="text.secondary">
              {`${t("AnnouncementDate")}: ${dayjs(startDate).format(
                "DD/MM/YYYY"
              )} - ${dayjs(endDate).format("DD/MM/YYYY")}`}
            </Typography>
            {updateAt && (
              <Typography variant="subtitle1" color="text.secondary">
                {`${t("LastModified")}: ${dayjs(updateAt).format(
                  "DD/MM/YYYY HH:mm"
                )}`}
              </Typography>
            )}
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Chip
              icon={<DescriptionIcon />}
              label={`${t("Descriptions")}`}
              style={{ marginRight: "auto" }}
            />
            <IconButton
              onClick={() => handleSelect(data)}
              style={{ color: "#ffeb3b", border: "1px solid #ffeb3b" }}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              style={{ color: "#d32f2f", border: "1px solid #d32f2f" }}
              onClick={() => handleOpenDelete(idPolicy)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Stack>
          <Stack marginTop={2} marginBottom={2} spacing={1}>
            {details.map((item, index) => (
              <div key={item.idPolicyItems}>
                <Chip label={item.topic} color="primary" />
                <Typography variant="body1" padding={1}>
                  {item.detail}
                </Typography>
              </div>
            ))}
          </Stack>
          {file && (
            <Button
              variant="outlined"
              component={Link}
              href={file}
              target="_blank"
              startIcon={<AttachFileIcon />}
              sx={{ justifyContent: "flex-end" }}
            >
              {`${t("AttachedAnnouncementFile")}`}
            </Button>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default function CompanyPolicy({ selectedCompany }) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: policy } = useSelector((state) => state.policy);

  const [selectPolicy, setSelectPolicy] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [configSnackbar, setConfigSnackbar] = useState({
    open: false,
    message: "",
    color: "primary",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectPolicy(null);
  };

  const handleSelect = (value) => {
    setSelectPolicy(value);
    setOpen(true);
  };

  const handleOpenDelete = (value) => {
    setSelectPolicy(value);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const policyActive = useMemo(() => {
    if (policy) {
      return policy.filter((item) =>
        dayjs().isBetween(item.startDate, item.endDate, "day", "[]")
      );
    }
  }, [policy]);

  const policyNotActive = useMemo(() => {
    if (policy) {
      return policy.filter(
        (item) => !dayjs().isBetween(item.startDate, item.endDate, "day", "[]")
      );
    }
  }, [policy]);

  const handleDelete = async () => {
    const res = await dispatch(deletePolicy(selectPolicy));
    if (res) {
      handleCloseDelete();
      dispatch(
        getPolicyByIdCompany({
          idCompany: selectedCompany.idCompany,
        })
      );
      dispatch(
        openNotificationAlert({
          message: t("DeletedSuccessfully"),
          type: "success",
        })
      );
    }
  };

  useEffect(() => {
    if(selectedCompany) {
      dispatch(
        getPolicyByIdCompany({
          idCompany: selectedCompany.idCompany,
        })
      );
    }
  }, [selectedCompany]);

  return (
    <>
      <WrapHeader>
        <Typography variant="h4">{t("CompanyPolicy")}</Typography>
        {userProfile && userProfile.roles.includes("ROLE_ADMIN") && (
          <ButtonBlue variant="contained" color="primary" onClick={handleClickOpen}>
            {t("Create")} {t("CompanyPolicy")}
          </ButtonBlue>
        )}
      </WrapHeader>
      {open && (
        <CreateCompanyPolicy
          open={open}
          handleClose={handleClose}
          editData={selectPolicy}
          selectedCompany={selectedCompany}
        />
      )}
      {policyActive && policyActive.length > 0 && (
        <Stack marginTop={4}>
          <Typography marginBottom={2}>{`${t("InProgress")}`}</Typography>
          {policyActive.map((item) => (
            <PolicyDetail
              {...item}
              key={item.idPolicy}
              handleSelect={handleSelect}
              handleOpenDelete={handleOpenDelete}
              isActive={true}
            />
          ))}
        </Stack>
      )}
      {policyNotActive && policyNotActive.length > 0 && (
        <Stack marginTop={4} marginBottom={4}>
          <Typography marginBottom={2}>{`${t("All")}`}</Typography>
          {policyNotActive.map((item) => (
            <PolicyDetail
              {...item}
              key={item.idPolicy}
              handleSelect={handleSelect}
              handleOpenDelete={handleOpenDelete}
              isActive={false}
            />
          ))}
        </Stack>
      )}
      {policy && policy.length === 0 && (
        <Typography variant="h6" color={"text.secondary"}>{t("NoData")}</Typography>
      )}
      {openDelete && (
        <DialogDelete
          open={openDelete}
          handleClose={handleCloseDelete}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
}
