import React from "react";
import { Chip, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
const StyledRoot = styled(Chip)(({status, size}) => ({
  fontSize: "12px",
  height: "auto",
  paddingTop: "2px",
  paddingBottom: "2px",

  ...((size === "small") && ({
    fontSize: "9px"
  })),
  ...((size === "large") && ({
    fontSize: "16px"
  })),
  ...((status === 1 || status === 7) && ({
    color: "#ffffff",
    backgroundColor: "rgb(238, 187, 0)"
  })),
  ...((status === 2 || status === 5) && ({
    color: "#ffffff",
    // backgroundColor: "#5beec0"
    // backgroundColor: "#30B98F"
    backgroundColor: "#6FDFBD"
  })),
  ...((status === 3 || status === 6)&& ({
    color: "#ffffff",
    // backgroundColor: "#ff3838"
    backgroundColor: "#E46A76"
  })),
  ...((status === 4)&& ({
    color: "#ffffff",
    backgroundColor: "#03a9f4"
  })),
}))

const ChipStatus = (props) => {
  const {t,i18n} = useTranslation();
  const { status, size } = props;

  const getLabel = () => {
    switch(status){
      case 1: return t("PendingApproval");
      case 2: return t("Approve");
      case 3: return t("NotApproved");
      case 4: return t("InProgress");
      case 5: return t("Success");
      case 6: return t("Failure");
      case 7: return t("WaitingForEvaluation");
      default: return "";
    }
  }

  //1. Pending
  //2. Approved
  //3. Rejected
  //4. In Progress
  //5. Achieved
  //5. Not achieved
  
  return (
   <StyledRoot label={getLabel()} status={status} size={size}/>
  )
}

export default ChipStatus