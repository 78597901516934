import React, { forwardRef, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";

const KpiGauge = forwardRef((props, ref) => {
  const gradesData = Array.isArray(props.grades) ? props.grades : [props.grades];
  const totalGrades = gradesData.length;

  const countA = gradesData.filter((grade) => grade === "A").length;
  const countB = gradesData.filter((grade) => grade === "B").length;
  const countC = gradesData.filter((grade) => grade === "C").length;
  const countD = gradesData.filter((grade) => grade === "D").length;

  const percentageA = (countA / totalGrades) * 100;
  const percentageB = (countB / totalGrades) * 100;
  const percentageC = (countC / totalGrades) * 100;
  const percentageD = (countD / totalGrades) * 100;

  const data = [percentageA, percentageB, percentageC, percentageD];

  const datalabelsFormatter = (value, context) => `${value.toFixed(2)}%`;

  useEffect(() => {
    if (ref && ref.current) {
      const chartInstance = ref.current.chartInstance;
      chartInstance.config.options.plugins.datalabels = {
        formatter: datalabelsFormatter,
        display: true,
        color: "#fff",
        font: {
          size: 16,
        },
        position: "end",
        align: "start",
      };
    }
  }, [ref, data]);

  return (
    <Doughnut
      width={300}
      height={300}
      ref={ref}
      options={{
        maintainAspectRatio: false,
        cutoutPercentage: 80,
        rotation: 270,
        circumference: 180,
        tooltips: {
          enabled: false,
        },
        layout: {
          padding: 50,
        },
        plugins: {
          legend: {
            position: "right",
            display: true,
            labels: {
              font: {
                size: 14,
              },
              boxWidth: 15,
              padding: 10,
              generateLabels: function (chart) {
                const data = chart.data.datasets[0].data;
                const labels = chart.data.labels;
                const legendItems = [];
                for (let i = 0; i < data.length; i++) {
                  legendItems.push({
                    text: `${labels[i]} : ${data[i].toFixed(2)}%`,
                    fillStyle: chart.data.datasets[0].backgroundColor[i],
                    hidden: isNaN(data[i]) || data[i] === 0,
                  });
                }
                return legendItems;
              },
            },
          },
          datalabels: {
            formatter: datalabelsFormatter,
            position: "end",
            align: "start",
          },
        },
      }}
      data={{
        labels: ["A", "B", "C", "D"],
        datasets: [
          {
            data: data,
            backgroundColor: ["#FA95B9", "#C0DFFF", "#86A9FF", "#4A75CA"],
            borderWidth: 3,
          },
        ],
      }}
    />
  );
});

export default KpiGauge;