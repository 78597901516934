import React, { Fragment, useEffect, useState } from "react";
import { Box, Card, Container, Grid, styled, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { resetPassword, verifyResetPassword } from "../../../../actions/account";
import { Link, useLocation } from "react-router-dom";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
})

const RenderForm = (props) => {
  const {t,i18n} = useTranslation();
  const dispatch = useDispatch();

  const { setIsSuccess, token, setErrorCode } = props;
  const [isFetching, setIsFetching] = useState(false);

  const { control, handleSubmit, setError, getValues, formState: { errors } } = useForm({
    defaultValues: {
      newPassword: "",
      newPasswordConfirm: "",
    }
  });

  const onSubmit = async (submittedData) => {

    setIsFetching(true);

    const formData = {
      newPassword: submittedData.newPassword,
      token: token
    }
    
    dispatch(resetPassword(formData)).then(res => {
      if(res.status === 200){
        setIsSuccess(true)
      }
    }).catch(error => {
      console.log(error)
      if(error.response.status === 401){
        if(error.response.data && error.response.data.errorCode){
          setErrorCode(error.response.data.errorCode)
        }
      }
    }).finally(() => {
      setIsFetching(false);
    });

  }

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">{t("setNewPassword")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={14} color="text.third">*{t("passwordRequirements")}</Typography>
            <Typography fontSize={14} color="text.third">*{t("PasswordEnglishOrNumbersOnly")}</Typography>
            <Typography fontSize={14} color="text.third">*{t("PasswordAtLeast1EnglishLetter")} {t("or")} {t("PasswordAtLeast1NumericDigit")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={14} paddingBottom="8px">{t("NewPassword")}</Typography>
            <Controller
              control={control}
              name="newPassword"
              rules={{
                required: {value: true, message: t("enterNewPassword")},
                validate: {
                  length: (value) => (value.length >= 8 && value.length <= 16) || t("passwordRequirements"),
                  oneAOneNum: (value) => {
                    if(value.match(/^(?=.*[0-9])(?=.*[A-z])([A-z0-9]+)$/)){
                      return true
                    } else {
                      return t("PasswordAtLeast1EnglishLetter")+" "+t("or")+" "+t("PasswordAtLeast1NumericDigit")
                    }
                  }
                },
                pattern: {
                 value: /^[0-9a-zA-Z!@#$%^&*]+$/,
                 message: t("PasswordEnglishOrNumbersOnly")
                }
              }}
              render={({field, formState}) => (
                <TextFieldTheme
                  {...field}
                  type={"password"}
                  autoComplete="password"
                  helperText={formState.errors && formState.errors[field.name] && formState.errors[field.name].message}
                  error={formState.errors && formState.errors[field.name] ? true: false}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={14} paddingBottom="8px">{t("ConfirmNewPassword")}</Typography>
            <Controller
              control={control}
              name="newPasswordConfirm"
              rules={{
                required: {value: true, message: t("confirmNewPassword")},
                validate: {
                  sameAs: (value) => (value === getValues("newPassword")) || t("passwordMismatch"),
                },
              }}
              render={({field, formState}) => (
                <TextFieldTheme
                  {...field}
                  type="password"
                  autoComplete="password"
                  helperText={formState.errors && formState.errors[field.name] && formState.errors[field.name].message}
                  error={formState.errors && formState.errors[field.name] ? true: false}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonBlue type="submit" variant="contained" fullWidth sx={{paddingTop: "12px", paddingBottom: "12px"}} disabled={isFetching}>{t("proceed")}</ButtonBlue>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <ButtonBlue component={Link} to="/login">{t("LogIn")}</ButtonBlue>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  )
}

const ResetPasswordPage = () => {

  const [isSuccess, setIsSuccess] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [isVerifyFetching, setIsVerifyFetching] = useState(false);
  const [errorCode, setErrorCode] = useState(null);

  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search)

  useEffect(() => {
    setIsVerifyFetching(true)
    dispatch(verifyResetPassword({token: params.get("token")})).then(res => {
      if(res.status === 200){
        setIsVerify(true)
      }
    }).catch(error => {
      if(error.response.status === 401){
        if(error.response.data && error.response.data.errorCode){
          setErrorCode(error.response.data.errorCode)
        }
      }
    }).finally(() => {
      setIsVerifyFetching(false);
    })
  }, [])

  return (
    <StyledRoot className="page">
      <Container maxWidth="sm">

        {!isVerifyFetching && (
          <Card sx={{padding: "48px", boxShadow: 2}}>

            {isVerify && (
              <Fragment>
                {!isSuccess && <RenderForm
                  setIsSuccess={setIsSuccess}
                  token={params.get("token")}
                  setErrorCode={setErrorCode}
                />}

                {isSuccess && (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography fontSize={20}>{t("passwordSetSuccess")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ButtonBlue component={Link} to="/login">{t("LogIn")}</ButtonBlue>
                    </Grid>
                  </Grid>
                )}
              </Fragment>
            )}

            {!isVerify && errorCode === "TOKEN_EXPIRED" && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography fontSize={20}>{t("passwordLinkExpired")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <ButtonBlue component={Link} to="/forget-password">{t("tryAgain")}</ButtonBlue>
                </Grid>
              </Grid>
            )}

            {!isVerify && errorCode === "TOKEN_INVALID" && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography fontSize={20}>{t("invalidPasswordLink")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <ButtonBlue component={Link} to="/forget-password">{t("tryAgain")}</ButtonBlue>
                </Grid>
              </Grid>
            )}

          </Card>
        )}

      </Container>
    </StyledRoot>
  )
}

export default ResetPasswordPage