import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import TableUserTime from "./TableUserTime";
import {
  Typography,
  Container,
} from "@mui/material";
import Backdrop from "../../shared/general/Backdrop";

import { getUserProfile, getAllUsers } from "../../../../actions/user";
import { getUserFullName } from "../../../../utils/userData";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  backgroundColor: "#f5f5f5",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const WrapHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
  marginTop: 20,
  marginBottom: 8,
});



const AssetManagement = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);


  useEffect(() => {
    dispatch(getUserProfile());

  }, []);

  return (
    <StyledRoot className={"page"}>
      <Backdrop open={!(currentUser && userProfile)} />
      <Container maxWidth="lg">
        <WrapHeader>
          {userProfile && (
            <Typography variant="h4" style={{ margin: "10px 0" }}>{t("logProgressTime")}
              {"..."}
              {`${getUserFullName(userProfile)}`}
            </Typography>
          )}
        </WrapHeader>
        <TableUserTime />
      </Container>
    </StyledRoot>
  );
};

export default AssetManagement;