import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { Divider, Grid, Typography } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import TextFieldTheme from "../../../shared/general/TextFieldTheme";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 0,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapHead = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const ProfileTab = () => {
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { t, i18n } = useTranslation();

  return (
    <Grid container spacing={2}>
      {employeeProfile && (
        <Fragment>
          <Grid item xs={12}>
            <StyledWrapHead>
              <StyledHeadLabel style={{ marginBottom: 4 }}>
                {`${t("PersonalInfo")}`}
              </StyledHeadLabel>
            </StyledWrapHead>
          </Grid>
          <Grid item xs={12}>
            <StyledHeadLabel color="text.secondary">
              {`${t("EmployeeID")}`}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.employeeID ? employeeProfile.employeeID : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledHeadLabel color="text.secondary">{`${t(
              "BirthDate"
            )}`}</StyledHeadLabel>
            <Typography variant="h6">
              {`${dayjs(employeeProfile.birthday)
              .locale(localStorage.getItem("i18nextLng") || "th")
              .format(
                localStorage.getItem("i18nextLng") === "en"
                  ? "D MMMM YYYY"
                  : "D MMMM BBBB"
              )}`}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledHeadLabel color="text.secondary">{`${t("Gender")}`}</StyledHeadLabel>
            <Typography variant="h6">{employeeProfile.gender? t(`${employeeProfile.gender}`): t(`NotSpecified`)}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledHeadLabel color="text.secondary">{`${t("Nationality")}`}</StyledHeadLabel>
            <Typography variant="h6">{employeeProfile.nationality}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledHeadLabel color="text.secondary">{`${t("Religion")}`}</StyledHeadLabel>
            <Typography variant="h6">{employeeProfile.idReligion? t(`Religion_Code.${employeeProfile.religionCode}`): t(`NotSpecified`)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledDivider />
            <StyledWrapHead>
              <StyledHeadLabel style={{ marginBottom: 4 }}>
                {`${t("Contact")}`}
              </StyledHeadLabel>
            </StyledWrapHead>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">
                {`${t("EmergencyPhone")}`}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.telephoneMobile
                  ? employeeProfile.telephoneMobile.replace(
                      /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                      "$1-$2-$3"
                    )
                  : "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">{`${t(
                "Email"
              )}`}</StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.email ? employeeProfile.email : "-"}
              </Typography>
            </div>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};

export default ProfileTab;
