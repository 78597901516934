import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useParams } from 'react-router-dom';
import {
  FormControl,
  Select,
  MenuItem,
  Grid,
  Box,
  Container,
  Typography,
  Divider,
  IconButton,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import Dropzone, { useDropzone } from "react-dropzone";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import AddPhotoIcon from "../../../../components/pages/assets/add.png";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonBlue from "../../shared/general/ButtonBlue";
import BackupIcon from '@mui/icons-material/Backup';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DatePickerCustom from "../../shared/date/datePicker";
import Lottie from 'lottie-react';
import UploadFile from './../company-documents/Animation/UploadFile.json'
import DoneIcon from '@mui/icons-material/Done';

import {
  addAllDocumentTypeName,
  getAllDocumentTypesByCompanyId,
} from "../../../../actions/document";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 12,
  backgroundColor: "#f9f9fb",
  "& .dropzone-leave": {
    borderRadius: 4,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "4px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#4caf50',
    },
    alert: {
      main: '#90caf9',
      text: '#1e88e5',
    },
  },
});

const addDocument = () => {
  const { idCompany } = useParams();

  const dispatch = useDispatch();
  const { result: alldocumentName } = useSelector((state) => state.alldocumentItem);
  const { result: List } = useSelector((state) => state.documentGroup)
  const [value, setValue] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (idCompany) {
      dispatch(getAllDocumentTypesByCompanyId(idCompany));
    }
  }, [idCompany]);

  const {
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: { ...formData },
    resolver: yupResolver(validationSchema),
  });

  const ActivateDocumentName = alldocumentName ? alldocumentName.filter(doc => doc.isActivate === 1) : [];

  const validFileExtensions = { file: ["pdf"] };

  const validationSchema = Yup.object().shape({
    file: Yup.mixed()
      .test("is-valid-type", "Not a valid file type", (value) => {
        if (value) {
          return isValidFileType(value && value.name.toLowerCase(), "file");
        } else {
          return true;
        }
      })
  });

  const isValidFileType = (fileName, fileType) => {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  };

  const { t, i18n } = useTranslation();

  const handleDrop = (acceptedFiles) => {
    const newFileUrls = acceptedFiles.map(file => URL.createObjectURL(file));
    setFormData(prevState => ({
      ...prevState,
      files: [...prevState.files, ...acceptedFiles],
      fileUrls: [...prevState.fileUrls, ...newFileUrls],
    }));
  };

  const handleDeleteFile = (index) => {
    const newFiles = [...formData.files];
    const newFileUrls = [...formData.fileUrls];
    newFiles.splice(index, 1);
    newFileUrls.splice(index, 1);

    setFormData({
      files: newFiles,
      fileUrls: newFileUrls,
    });
  };

  const handleDeleteAll = () => {

    formData.fileUrls.forEach(url => {
      URL.revokeObjectURL(url);
    });

    setFormData({
      files: [],
      fileUrls: [],
    });
  };

  const truncateFileName = (fileName) => {
    if (fileName.length > 5) {
      return `${fileName.substring(0, 5)}...`;
    }
    return fileName;
  };

  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
    files: [],
    fileUrls: [],
    detail: null,
  });

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState(`${t("Please_select_document_typeName")}`);
  const [message, setMessage] = useState('');

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const handleOpenAlert = () => {
    setOpenAlert(true);
    setMessage(`${t("Please_select_document_typeName")}`);
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
    setAlertMessage(`${t("Please_select_document_typeName")}`);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };


  const AlertDialog = ({ open, onClose, message }) => {
    const { t } = useTranslation();

    return (
      <ThemeProvider theme={theme}>
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
          PaperProps={{
            style: {
              borderRadius: '16px',
              background: 'linear-gradient(135deg, #f5f8fc, #ffffff)',
              boxShadow: '0 8px 30px rgba(0, 0, 0, 0.1)',
              position: 'relative',
            },
          }}
        >
          <DialogTitle
            disableTypography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '20px',
              position: 'relative',
              background: 'none',
            }}
          >
            <Box
              sx={{
                width: '70px',
                height: '70px',
                background: 'linear-gradient(135deg, #4caf50, #388e3c)',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0 4px 15px rgba(0, 0, 0, 0.15)',
              }}
            >
              <DoneIcon
                style={{
                  fontSize: '40px',
                  color: '#fff',
                }}
              />
            </Box>
          </DialogTitle>

          <DialogContent>
            <Typography
              variant="h6"
              align="center"
              id="alert-dialog-description"
              sx={{
                color: '#333',
                marginTop: '20px',
                fontWeight: '500',
                padding: '0 16px',
                fontFamily: 'Roboto, Arial, sans-serif',
                fontSize: '16px',
                lineHeight: '1.5',
                userSelect: 'none',
              }}
            >
              {message}
            </Typography>
          </DialogContent>

          <DialogActions
            sx={{
              padding: '16px',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={onClose}
              variant="contained"
              style={{
                minWidth: '120px',
                background: 'linear-gradient(45deg, #1976d2, #1565c0)',
                color: '#fff',
                borderRadius: '10px',
                fontSize: '16px',
                padding: '10px 24px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                transition: 'background 0.3s, transform 0.2s',
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.transform = 'scale(1.05)';
                e.currentTarget.style.background = 'linear-gradient(45deg, #1565c0, #0d47a1)';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
                e.currentTarget.style.background = 'linear-gradient(45deg, #1976d2, #1565c0)';
              }}
            >
              <Box ml={1}>{t('OK')}</Box>
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  };

  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("select");

  const handleChange = (event) => {
    const newValue = event.target.value === 'true';
    setValue(newValue);

    if (!newValue) {
      setFormData({
        ...formData,
        startDate: null,
        endDate: null
      });
    }
  };

  const handleOnSubmit = async () => {
    if (selectedDocumentType.idDocumentType === '' || !formData.files) {
      setAlertOpen(true);
      handleChangeAlertType(`${t("ThisFieldIsRequired")}`);
    } else {
      const data = {
        startDate: formData.startDate !== undefined ? formData.startDate : null,
        endDate: formData.endDate !== undefined ? formData.endDate : null,
        expiration: value,
        idDocumentType: selectedDocumentType.idDocumentType,
        idCompany: idCompany,
        documentTypeName: selectedDocumentType.documentTypeName,
        createDate: dayjs(new Date()).format("YYYY-MM-DD"),
        updateDate: dayjs(new Date()).format("YYYY-MM-DD"),
      };

      const postData = new FormData();

      Object.keys(data).forEach((key) => {
        postData.append(key, JSON.stringify(data[key]));
      });

      formData.files.forEach((file) => {
        postData.append('files', file);
      });

      setLoading(true);
      try {
        setUploadStatus("uploading");
        const result = await dispatch(addAllDocumentTypeName(postData, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        }));
        if (result) {
          setAlertMessage(`${t("SuccessfullyUploaded")}`);
          setUploadStatus("done");
        } else {
          setAlertMessage("An error occurred uploading.");
          setUploadStatus("select");
        }
        setAlertOpen(true);
      } catch (error) {
        console.error("Error adding document type:", error);
        setAlertMessage("An error occurred uploading.");
        setAlertOpen(true);
        setUploadStatus("select");
      } finally {
        setLoading(false);
      }
    }
  };

  const [selectedDocumentType, setSelectedDocumentType] = useState({
    idDocumentType: '',
    documentTypeName: ''
  });

  const handleChangeDocument = (event) => {
    const selectedId = event.target.value;
    const selectedDocument = (ActivateDocumentName || []).find(doc => doc.idDocumentType === selectedId);
    if (selectedDocument) {
      setSelectedDocumentType({
        idDocumentType: selectedDocument.idDocumentType,
        documentTypeName: selectedDocument.documentTypeName
      });
    } else {
      setSelectedDocumentType({
        idDocumentType: '',
        documentTypeName: ''
      });
    }
  };

  const lottieRef = useRef(null);

  useEffect(() => {
    if (lottieRef.current) {
      if (!loading) {
        lottieRef.current.play();
      }
    }
  }, [loading]);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg" style={{ userSelect: 'none' }}>
        <Box
          className="wrap-search-action"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ pt: 0, pb: 2 }}
        >
          <Typography variant="h4" sx={{ pt: 1, pb: 2 }}>
            {t("AddDocumentFile")}
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{ pt: 4, pb: 5 }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ButtonBlue
              variant="contained"
              sx={{
                borderRadius: 16,
                padding: 1,
                boxShadow: 2,
                width: '50%',
                margin: 'auto',
              }}
            >
              <FileUploadOutlinedIcon sx={{ mr: 1, pt: 1.5 }} />
              <Typography variant="h6" sx={{ pt: 1.5, color: "white" }}>
                {t("Upload Document")}
              </Typography>
            </ButtonBlue>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ pt: 5, pb: 2 }}
        >
          <Grid item xs={12} className="GridTopicInput">
            <Typography
              className="label"
              sx={{ color: "#424242" }}
            >
              {`${t("DocumentName")}`}
            </Typography>
          </Grid>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ pt: 1, pb: 2 }}
        >
          <FormControl sx={{ width: '50%', maxWidth: '50%' }}>
            <Select
              name="documentTypeName"
              value={selectedDocumentType.idDocumentType}
              onChange={handleChangeDocument}
              required
            >
              {(ActivateDocumentName || []).map((row) => (
                <MenuItem
                  key={row.idDocumentType}
                  value={row.idDocumentType}
                >
                  {row.documentTypeName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {formData.fileUrls.length > 0 && (
          <>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ pt: 4 }}
            >
              <Typography sx={{ mr: 8 }}>{t("ExpirationDate")}</Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label={`${t("Yes")}`}
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label={`${t("No")}`}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box
              justifyContent="flex-center"
              alignItems="center"
              sx={{ pt: 4 }}
            >
              {value ? (
                <>
                  <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6} className="GridTopicInput">
                      {t("Start")}
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center', width: '50%' }}>
                      <DatePickerCustom
                        value={formData.startDate}
                        inputFormat="DD/MM/YYYY"
                        name="startDate"
                        onChange={(newValue) => {
                          setFormData({
                            ...formData,
                            ["startDate"]: dayjs(newValue).format("YYYY-MM-DD"),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth helperText={null} />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{ paddingTop: 2 }}>
                    <Grid item xs={12} sm={6} className="GridTopicInput">
                      {t("End")}
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center', width: '50%' }}>
                      <DatePickerCustom
                        value={formData.endDate}
                        inputFormat="DD/MM/YYYY"
                        name="endDate"
                        onChange={(newValue) => {
                          setFormData({
                            ...formData,
                            ["endDate"]: dayjs(newValue).format("YYYY-MM-DD"),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth helperText={null} />
                        )}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Box>
          </>
        )}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ pt: 2 }}
        >
          <Grid item xs={12} >
            {loading ? (
              <Box sx={{ pb: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px' }}>
                  <Typography
                    variant="h6"
                    style={{
                      marginTop: '16px',
                      color: '#3f51b5',
                      textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                      fontWeight: 'bold',
                      userSelect: 'none',
                    }}
                  >
                    {t("UploadingDocCompanyFiles....")}
                  </Typography>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px', userSelect: 'none' }}>
                  <div style={{ width: '60%', height: '60%' }}>
                    <Lottie
                      lottieRef={lottieRef}
                      animationData={UploadFile}
                      loop={true}
                      autoplay={true}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', userSelect: 'none' }}>
                  <Box>
                    {formData.files.length === 0 ? (
                      <>
                      </>
                    ) : (
                      <>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ pt: 2.5, pb: 2 }}
                        >
                          {uploadStatus === "uploading" && (
                            <Box sx={{ mt: 2, width: "100%" }}>
                              <Typography variant="caption" sx={{ color: "#3f51b5", fontWeight: "bold", mb: 1 }}>
                                {`กำลังอัปโหลด: ${progress}%`}
                              </Typography>
                              <Box sx={{ width: "100%", backgroundColor: "#e0e0e0", height: 10, borderRadius: 5, boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.12)" }}>
                                <Box sx={{
                                  width: `${progress}%`,
                                  backgroundColor: "#3f51b5",
                                  height: "100%",
                                  borderRadius: 5,
                                  transition: "width 0.5s ease-in-out",
                                  boxShadow: "0 3px 3px -5px #3f51b5, 0 2px 5px #3f51b5"
                                }} />
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </>
                    )}
                  </Box>
                </div>
              </Box>
            ) : (
              <>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ pt: 1, pb: 4 }}
                >
                  {selectedDocumentType && (
                    <div>
                      <Typography
                        className="label"
                        sx={{ color: "#424242" }}
                      >
                        {`${t("AttachFile")} ${selectedDocumentType.documentTypeName}`}
                      </Typography>
                    </div>)}
                  {!selectedDocumentType && (
                    <div>
                      <Typography
                        className="label"
                        sx={{ color: "#424242" }}
                      >
                        {t("AttachFile")}
                      </Typography>
                    </div>
                  )}
                </Box>
                <Box>
                  <StyledDropzone>
                    <Dropzone
                      accept="application/pdf"
                      multiple={true}
                      onDrop={handleDrop}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone-leave" })}>
                          <div className="inner-dropzone">
                            <input {...getInputProps()} />
                            <div style={{ textAlign: "center" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  userSelect: 'none'
                                }}
                              >
                                <img alt="add" src={AddPhotoIcon} style={{ userSelect: 'none' }} />
                              </div>
                              <Typography
                                variant="caption"
                                style={{ lineHeight: 0.5, color: "#999999", userSelect: 'none' }}
                              >
                                Allowed *.pdf, max size of 3 MB
                              </Typography>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </StyledDropzone>
                  <Box>
                    {formData.files.length === 0 ? (
                      <>
                        <Box style={{ color: '#000000', pt: 2 }}>
                          *** {t("PleaseNameTheFileAs")}
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ pt: 2, userSelect: 'none' }}
                        >
                          <ButtonBlue
                            variant="contained"
                            startIcon={<BackupIcon />}
                            onClick={() => handleOnSubmit(watch("file"))}
                            disabled
                          >
                            {t("Upload")}
                          </ButtonBlue>
                          <AlertDialog
                            open={alertOpen}
                            onClose={handleCloseAlert}
                            message={alertMessage}
                          />
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box style={{ color: '#000000', pt: 2 }}>
                          *** {t("PleaseNameTheFileAs")}
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ pt: 2.5, pb: 2, userSelect: 'none' }}
                        >
                          <ButtonBlue
                            variant="contained"
                            startIcon={<BackupIcon />}
                            onClick={() => handleOnSubmit(watch("file"))}
                          >
                            {t("Upload")}
                          </ButtonBlue>
                          <AlertDialog
                            open={alertOpen}
                            onClose={handleCloseAlert}
                            message={alertMessage}
                          />
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ pt: 2.5, pb: 2, userSelect: 'none' }}
                        >
                          {uploadStatus === "uploading" && (
                            <Box sx={{ mt: 2, width: "100%" }}>
                              <Typography variant="caption" sx={{ color: "#3f51b5", fontWeight: "bold", mb: 1, userSelect: 'none' }}>
                                {`กำลังอัปโหลด: ${progress}%`}
                              </Typography>
                              <Box sx={{ width: "100%", backgroundColor: "#e0e0e0", height: 10, borderRadius: 5, boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.12)" }}>
                                <Box sx={{
                                  width: `${progress}%`,
                                  backgroundColor: "#3f51b5",
                                  height: "100%",
                                  borderRadius: 5,
                                  transition: "width 0.5s ease-in-out",
                                  boxShadow: "0 3px 3px -5px #3f51b5, 0 2px 5px #3f51b5"
                                }} />
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </>
            )
            }
          </Grid>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ pt: 4, userSelect: 'none' }}>
          {formData.fileUrls.length > 0 && (
            <Typography variant="h6">
              {t("Uploaded Files:")}
            </Typography>
          )}
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {formData.fileUrls.map((url, index) => (
              <Grid item xs={12} sm={6} md={4} lg={1} key={index}>
                <Box sx={{ mb: 2 }}>
                  <Box sx={{ position: 'relative' }}>
                    <Box sx={{ width: '100%', height: '100px', overflow: 'hidden' }}>
                      <iframe
                        src={url + '#page=1&zoom=fitwidth'}
                        title="PDF Document"
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        scrolling="no"
                      >
                        <p>
                          It appears you don't have a PDF plugin for this browser. No worries,{' '}
                          you can <a href={url} download>download the PDF file</a>.
                        </p>
                      </iframe>
                    </Box>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteFile(index)}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: 1,
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ pt: 2 }}
                  >
                    {formData.files[index] && truncateFileName(formData.files[index].name)}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ pt: 2, pb: 3 }}
        >
          {formData.fileUrls.length > 0 && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={handleDeleteAll}
              sx={{
                textTransform: 'none',
                backgroundColor: '#d32f2f',
                '&:hover': {
                  backgroundColor: '#e84e40',
                },
              }}
            >
              {t("Delete All")}
            </Button>
          )}
        </Box>
      </Container>
    </StyledRoot>
  )
}

export default addDocument
