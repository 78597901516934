import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  Grid,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Button,
  Chip,
  Stack,
  TextField,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { getKpiUpdateById, addKpiPlan } from "../../../../../actions/kpi";
import { useLocation, useHistory } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { getUserPosition } from "../../../../../utils/userData";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StyledRoot = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  padding: '24px 0',
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));

const GridStyled = styled(Grid)({
  backgroundColor: "#E6EFF5",
  padding: "16px",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "16px",
  height: "70px",
});

const monthList = [
  "Months.January",
  "Months.February",
  "Months.March",
  "Months.April",
  "Months.May",
  "Months.June",
  "Months.July",
  "Months.August",
  "Months.September",
  "Months.October",
  "Months.November",
  "Months.December",
];

const StatusChip = styled(Chip)(({ theme, status }) => {
  const {t,i18n} = useTranslation();
  let backgroundColor;
  switch (status) {
    case t("Unfinished"):
      backgroundColor = "#FFDE4D";
      break;
    case t("Waiting"):
      backgroundColor = "#83B4FF";
      break;
    case t("NotApproved"):
      backgroundColor = "#FF6969";
      break;
    case t("Approve"):
      backgroundColor = "#50B498";
      break;
  }
  return {
    backgroundColor,
    color: '#fff',
    borderRadius: '16px',
    minWidth: '140px',
    fontSize: '16px',
  };
});

const KpisPlan = () => {
  const { t, i18n } = useTranslation();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: kpiPlan } = useSelector((state) => state.kpiUpdateId);
  const isUser = userProfile && userProfile.roles.includes("ROLE_USER");

  const [expanded, setExpanded] = useState(false);
  const [values, setValues] = useState({ kpiPlan: "" });

  const handleAddKpiPlan = async (formData) => {
    const res = await dispatch(addKpiPlan(formData));
    if (res && res.status === 200) {
      dispatch(getKpiUpdateById(state.planList.idKPI));
    }
  };

  const handleSubmit = async (id) => {
    const formData = {
      kpiPlan: values.kpiPlan,
      month: id,
      idKpi: kpiPlan.idKPI,
    };

    try {
      const result = await Swal.fire({
        title: t("AreYouSure"),
        text: t("DoYouWantToAddPlanThisMonth"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Confirm"),
        cancelButtonText: t("Cancel"),
      });

      if (result.isConfirmed) {
        await handleAddKpiPlan(formData);
      }
    } catch (error) {
      console.error("Error submitting KPI:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    const selectMonthKPI = kpiPlan.updates.find((item) => item.month === panel);
    if (selectMonthKPI && isExpanded) {
      setValues(selectMonthKPI);
    } else {
      setValues({ kpiPlan: "" });
    }
    setExpanded(isExpanded ? panel : false);
  };

  const columns = [
    {
      name: t("KPIName"),
      minWidth: "200px",
      width: "200px",
      cellRender: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flexGrow: 1 }}>
            <Typography>{row.kpiName}</Typography>
            <Typography color="text.third" fontSize="14px">
              {getUserPosition(row)}
            </Typography>
          </div>
        </div>
      ),
    },
    {
      name: t("Scope"),
      minWidth: "200px",
      width: "200px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography>{row.scope}</Typography>
            <Typography color="text.third" fontSize="14px">
              {getUserPosition(row)}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: t("Date"),
      minWidth: "200px",
      width: "200px",
      cellRender: (row) => (
        <Typography>{`${dayjs(row.startDate).format(
          i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
        )} - ${dayjs(row.endDate).format(
          i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
        )}`}</Typography>
      ),
    },
    {
      name: t("Status"),
      minWidth: "200px",
      width: "200px",
      headerTextAlign: "center",
      cellRender: (row) => {
        const totalMonths =
          dayjs(row.endDate).get("month") - dayjs(row.startDate).get("month") + 1;
        let status = t("Unfinished");

        if (row.updates.some(update => update.isApprove === 1)) {
          status = t("Approve");
        } else if (row.updates.some(update => update.isApprove === 0)) {
          status = t("NotApproved");
        } else if (row.updates.length === totalMonths) {
          status = t("Waiting");
        }

        return (
          <Box display="flex" alignItems="center" justifyContent="center">
            <StatusChip label={status} status={status} />
          </Box>
        );
      },
      cellTextAlign: "center",
    },
  ];

  useEffect(() => {
    if (state && state.planList && state.planList.idKPI) {
      dispatch(getKpiUpdateById(state.planList.idKPI));
    }
  }, [dispatch, state]);

  return (
    <StyledRoot className="page">
      <Container>
        <GridStyled container justifyContent="space-between" alignItems="center" mt={2}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" className="kpi" sx={{ ml: 1, fontSize: "30px" }}>
              {t("Plan")} KPI
            </Typography>
          </Box>
        </GridStyled>
        <div className="cardPlan">
          <Box padding="2px 0">
            <Container maxWidth="lg">
              <Box padding="2px 0" minHeight="700px">
                {kpiPlan && (
                  <Box padding="0px">
                    <Box>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
              
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            backgroundColor: "#4f65df",
                            borderRadius: 15,
                            marginBottom: 15,
                            height: "50px",
                          }}
                        >
                          <Typography
                            fontSize={16}
                            variant="h6"
                            sx={{ textAlign: "center", color: "#ffffff" }}
                          >
                            {t("PlanKPISuccessPlanMonth")}
                          </Typography>

                        </Grid>
                      </Grid>
                    </Box>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {columns.map((c, index) => (
                              <StyledCellHeader
                                key={index}
                                sx={{
                                  textAlign: c.headerTextAlign
                                    ? c.headerTextAlign
                                    : undefined,
                                  minWidth: c.minWidth ? c.minWidth : undefined,
                                  width: c.width ? c.width : undefined,
                                  maxWidth: c.maxWidth ? c.maxWidth : undefined,
                                }}
                              >
                                {c.name}
                              </StyledCellHeader>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            {columns.map((c, cIndex) => (
                              <StyledCellContent
                                key={cIndex}
                                style={{
                                  textAlign: c.cellTextAlign
                                    ? c.cellTextAlign
                                    : undefined,
                                  fontSize: c.cellFontSize
                                    ? c.cellFontSize
                                    : undefined,
                                  minWidth: c.minWidth ? c.minWidth : undefined,
                                  width: c.width ? c.width : undefined,
                                  maxWidth: c.maxWidth ? c.maxWidth : undefined,
                                }}
                              >
                                {c.cellRender(kpiPlan)}
                              </StyledCellContent>
                            ))}
                          </TableRow>
                          {(!kpiPlan || kpiPlan.length <= 0) && (
                            <TableRow>
                              <TableCell colSpan={8} align="center">
                                <Box
                                  height="120px"
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Typography variant="h6">
                                    <FindInPageIcon />
                                    {t("NoData")}
                                  </Typography>
                                </Box>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                      <div>
                        {[...Array(dayjs(kpiPlan.endDate).get("month") + 1).keys()]
                          .slice(dayjs(kpiPlan.startDate).get("month"))
                          .map((item, index) => {
                            const selectMonthKPI = kpiPlan.updates.find((update) => update.month === item + 1);
                            const isApproved = selectMonthKPI && selectMonthKPI.isApprove === 1;

                            return (
                              <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                                key={index}
                              >
                                <Accordion
                                  expanded={expanded === item + 1}
                                  onChange={handleChange(item + 1)}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography>
                                      {index + 1}. {t(monthList[item])}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Stack alignItems="center" spacing={2}>
                                      {isApproved ? (
                                        <Box
                                          fullWidth
                                          sx={{
                                            width: "98.5%",
                                            border: "1px solid #ccc",
                                            borderRadius: "4px",
                                            padding: "8px",
                                            backgroundColor: "#f9f9f9",
                                            minHeight: "96px",
                                          }}
                                        >
                                          {values[`kpiPlan`] || ""}
                                        </Box>
                                      ) : (
                                        <TextField
                                          name={`kpiPlan`}
                                          value={values[`kpiPlan`] || ""}
                                          onChange={(e) => handleInputChange(e)}
                                          fullWidth
                                          label={t("Plan")}
                                          placeholder={t("Plan")}
                                          multiline
                                          rows={3}
                                          InputProps={{ readOnly: !isUser }}
                                        />
                                      )}
                                      {isUser && !isApproved && (
                                        <div
                                          style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                          }}
                                        >
                                          <ButtonBlue
                                            variant="contained"
                                            onClick={() => handleSubmit(item + 1)}
                                          >
                                            {t("Plan")}
                                          </ButtonBlue>
                                        </div>
                                      )}
                                    </Stack>
                                  </AccordionDetails>
                                </Accordion>
                              </Box>
                            );
                          })}
                      </div>
                    </TableContainer>
                  </Box>
                )}
              </Box>
            </Container>
          </Box>
        </div>
      </Container>
    </StyledRoot>
  );
};

export default KpisPlan;
