import DoneAll from "@mui/icons-material/DoneAll";
import { Avatar, Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  approveRequestTime,
  getAllRequestTimeBy,
  getAllRequestTimeWithDrawBy,
} from "../../../../../actions/requestTime";
import ChipWithDrawCustom from "../../../shared/chipWithDrawCustom";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import StatusRequest from "../../../shared/general/stausRequest";
import TableCustom from "../../../shared/tableCustom";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import {
  getUserFullName,
  getUserPosition,
} from "../../../../../utils/userData";

const Compensate = (props) => {
  const { searchDate, selectedEmployee, setSnackBarConfig, setNumberOfList } =
    props;
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: requestTimeList } = useSelector((state) => state.requestTime);
  const { result: requestTimeWithDrawList } = useSelector(
    (state) => state.requestTimeWithDraw
  );

  const [pendingRows, setPendingRows] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const columns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>{getUserFullName(row)}</Typography>
            <Typography color="text.third" fontSize="14px">{row.employeeID}</Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("Position")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Fragment>
          <Typography fontSize={14}>{getUserPosition(row)}</Typography>
        </Fragment>
      ),
    },
    {
      name: `${t("Type")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Fragment>
          {row.idRequestTimeWithdraw && <ChipWithDrawCustom />}
          <Typography fontSize={14}>{t(row.name)}</Typography>
        </Fragment>
      ),
    },
    {
      name: `${t("Start")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.startText, "DD/MM/YYYY HH:mm").format(
              i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
            )}
          </Typography>
          <Typography>
            {dayjs(row.startText, "DD/MM/YYYY HH:mm").format("HH:mm")}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("End")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.endText, "DD/MM/YYYY HH:mm").format(
              i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
            )}
          </Typography>
          <Typography>
            {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("HH:mm")}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("totalCompensateHour")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {row.amountHours && row.amountHours > 0 ? (row.amountHours / 60).toFixed(2) : 0.00} {t("Unit.Hours")}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("Reason")}`,
      minWidth: "200px",
      cellRender: (row) => (
        <Box>
          <Typography fontSize="14px">
            {" "}
            {i18n.resolvedLanguage === "th"
              ? row.reasonName
              : row.reasonName_EN}
          </Typography>
          <Typography color="text.third" fontSize="14px">
            {row.otherReason}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("ManagerLV")} 1`,
      minWidth: "280px",
      width: "280px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          {(row.isDoubleApproval === 1 ||
            row.approvalLevel === 1 ||
            row.managerApprove) && (
              <>
                <StatusRequest
                  boxSize={40}
                  fontSize={22}
                  borderRadius={8}
                  status={getStatusManagerLV1(row)}
                />
                <Avatar
                  sx={{
                    marginLeft: "8px",
                    marginRight: "8px",
                    width: 40,
                    height: 40,
                    "& img": { objectFit: "contain" },
                  }}
                  src={row.imageURL}
                />
                {(row.managerLV1ApproveBy || row.managerApprove) ?
                  <Box flexGrow={1}>
                    <Typography>
                      {getUserFullName({
                        firstname_TH: row.managerLV1_firstname_TH,
                        lastname_TH: row.managerLV1_lastname_TH,
                        firstname_EN: row.managerLV1_firstname_EN,
                        lastname_EN: row.managerLV1_lastname_EN,
                      })}
                    </Typography>
                    <Typography color="text.third" fontSize="14px">
                      {getUserFullName({
                        positionName: row.managerLV1_positionName,
                        positionName_EN: row.managerLV1_positionName_EN,
                      })}
                    </Typography>
                  </Box>
                  :
                  <Box flexGrow={1}>
                    <Typography>
                      {row.nameManagerGroupLV1}
                    </Typography>
                  </Box>
                }
              </>
            )}
        </Box>
      ),
    },
    {
      name: `${t("ManagerLV")} 2`,
      minWidth: "280px",
      width: "280px",
      cellRender: (row) => (
        <Fragment>
          {(row.isDoubleApproval === 1 || row.approvalLevel === 2) &&
            (row.managerLV2ApproveBy || row.idManagerGroupLV2) && (
              <Box display="flex" alignItems="center">
                <StatusRequest
                  boxSize={40}
                  fontSize={22}
                  borderRadius={8}
                  status={getStatusManagerLV2(row)}
                />
                <Avatar
                  sx={{
                    marginLeft: "8px",
                    marginRight: "8px",
                    width: 40,
                    height: 40,
                    "& img": { objectFit: "contain" },
                  }}
                  src={row.imageURL}
                />
                {(row.managerLV2ApproveBy) ?
                  <Box flexGrow={1}>
                    <Typography>
                      {getUserFullName({
                        firstname_TH: row.managerLV2_firstname_TH,
                        lastname_TH: row.managerLV2_lastname_TH,
                        firstname_EN: row.managerLV2_firstname_EN,
                        lastname_EN: row.managerLV2_lastname_EN,
                      })}
                    </Typography>
                    <Typography color="text.third" fontSize="14px">
                      {getUserPosition({
                        positionName: row.managerLV2_positionName,
                        positionName_EN: row.managerLV2_positionName_EN,
                      })}
                    </Typography>
                  </Box>
                  :
                  <Box flexGrow={1}>
                    <Typography>
                      {row.nameManagerGroupLV2}
                    </Typography>
                  </Box>
                }
              </Box>
            )}
        </Fragment>
      ),
    },
  ];

  const getStatusManagerLV1 = (requestTime) => {
    if (requestTime.idRequestTimeWithdraw) {
      if (requestTime.isApprove === 1) {
        return 1;
      } else if (requestTime.isApprove === 0) {
        return 0;
      } else {
        return 2;
      }
    } else {
      if (requestTime.isDoubleApproval === 1) {
        if (requestTime.isManagerLV1Approve === 1) {
          return 1;
        } else if (requestTime.isManagerLV1Approve === 0) {
          return 0;
        } else {
          return 2;
        }
      } else {
        if (requestTime.approvalLevel === 1) {
          if (requestTime.isManagerLV1Approve === 1) {
            return 1;
          } else if (requestTime.isManagerLV1Approve === 0) {
            return 0;
          } else {
            return 2;
          }
        } else if (requestTime.approvalLevel === 2) {
          return 1;
        }
      }
    }
  };

  const getStatusManagerLV2 = (requestTime) => {
    if (requestTime.isDoubleApproval === 1) {
      if (requestTime.isManagerLV2Approve === 1) {
        return 1;
      } else if (requestTime.isManagerLV2Approve === 0) {
        return 0;
      } else {
        return 2;
      }
    } else {
      if (requestTime.approvalLevel === 2) {
        if (requestTime.isManagerLV2Approve === 1) {
          return 1;
        } else if (requestTime.isManagerLV2Approve === 0) {
          return 0;
        } else {
          return 2;
        }
      } else if (requestTime.approvalLevel === 1) {
        return 1;
      }
    }
  };

  const handleApprovalRequest = async (approve) => {
    const idRequestTimeDouble = [];
    const idRequestTimeLV1 = [];
    const idRequestTimeLV2 = [];
    const idRequestTimeWithdraw = [];
    const idRequestTime = [];

    selectedRows.map((x) => {
      if (x.idRequestTimeWithdraw) {
        idRequestTimeWithdraw.push(x.idRequestTimeWithdraw);
        idRequestTime.push(x.idRequestTime);
      } else if (x.isDoubleApproval === 1) {
        if (x.isManagerLV1Approve === null && x.isManagerLV2Approve === null) {
          idRequestTimeDouble.push(x.idRequestTime);
        } else if (
          x.isManagerLV1Approve === 1 &&
          x.isManagerLV2Approve === null
        ) {
          idRequestTimeLV2.push(x.idRequestTime);
        }
      } else {
        if (x.approvalLevel === 1) {
          if (x.isManagerLV1Approve === null) {
            idRequestTimeLV1.push(x.idRequestTime);
          }
        } else if (x.approvalLevel === 2) {
          if (x.isManagerLV2Approve === null) {
            idRequestTimeLV2.push(x.idRequestTime);
          }
        }
      }
    });

    const formData = [
      {
        idRequestTimeDouble: idRequestTimeDouble,
        isManagerLV1Approve: approve,
        isManagerLV2Approve: approve,
        approveDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        idRequestTimeLV1: idRequestTimeLV1,
        isManagerLV1Approve: approve,
        approveDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        idRequestTimeLV2: idRequestTimeLV2,
        isManagerLV2Approve: approve,
        approveDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        idRequestTimeWithdraw: idRequestTimeWithdraw,
        idRequestTime: idRequestTime,
        isApprove: approve,
        approveDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      },
    ];

    const result = await dispatch(approveRequestTime("admin", formData));
    if (result) {
      setSelectedRows([]);
      if (result.status === 200) {
        dispatch(getAllRequestTimeBy("admin", searchDate));
        dispatch(getAllRequestTimeWithDrawBy("admin", searchDate));
        setSnackBarConfig({
          open: true,
          message: `${t("Successfully")}`,
          type: "success",
        });
      } else {
        setSnackBarConfig({
          open: true,
          message: `${t("Unsuccessful")}`,
          type: "error",
        });
      }
    } else {
      setSnackBarConfig({
        open: true,
        message: `${t("Unsuccessful")}`,
        type: "error",
      });
    }
  };

  useEffect(() => {
    const tempPending = [];

    requestTimeList.map((r) => {
      if (
        r.idRequestType === 3 &&
        (selectedEmployee
          ? selectedEmployee.idEmployees === r.idEmployees
          : true)
      ) {
        if (r.isDoubleApproval === 1) {
          if (
            (r.managerLV1ApproveBy || r.idManagerGroupLV1) &&
            (r.managerLV2ApproveBy || r.idManagerGroupLV2)
          ) {
            if (
              (!r.isManagerLV1Approve || !r.isManagerLV2Approve) &&
              !(r.isManagerLV1Approve === 0 || r.isManagerLV2Approve === 0)
            ) {
              tempPending.push(r);
            }
          }
        } else {
          if (r.approvalLevel === 1 && (r.managerLV1ApproveBy || r.idManagerGroupLV1)) {
            if (r.isManagerLV1Approve !== 1 && r.isManagerLV1Approve !== 0) {
              tempPending.push(r);
            }
          } else if (r.approvalLevel === 2 && (r.managerLV2ApproveBy || r.idManagerGroupLV2)) {
            if (r.isManagerLV2Approve !== 1 && r.isManagerLV2Approve !== 0) {
              tempPending.push(r);
            }
          }
        }
      }
    });

    requestTimeWithDrawList.map((r) => {
      if (r.idRequestType === 3) {
        if (r.isApprove === null) {
          tempPending.push(r);
        }
      }
    });

    let pendingList = tempPending.sort(function (a, b) {
      return new Date(b.createDate) - new Date(a.createDate);
    });
    setPendingRows(pendingList);
    setNumberOfList(pendingList.length);
  }, [selectedEmployee]);

  return (
    <Box>
      {selectedRows.length > 0 && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          <ButtonBlue
            style={{ marginRight: 8 }}
            startIcon={<DoneAll />}
            variant="contained"
            onClick={() => handleApprovalRequest(1)}
          >
            {`${t("Approved")} ${selectedRows.length > 0
              ? `${selectedRows.length} ${t("List")}`
              : ""
              }`}
          </ButtonBlue>
          <ButtonBlue
            startIcon={<DoneAll />}
            variant="outlined"
            onClick={() => handleApprovalRequest(0)}
          >
            {`${t("NotApproved")} ${selectedRows.length > 0
              ? `${selectedRows.length} ${t("List")}`
              : ""
              }`}
          </ButtonBlue>
        </Box>
      )}

      <TableCustom
        columns={columns}
        rows={requestTimeList && requestTimeWithDrawList ? pendingRows : []}
        canSelect={userProfile.isSuperAdmin === 1}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
    </Box>
  );
};

export default Compensate;
