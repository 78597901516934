import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Avatar, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { getUserFullName, getUserPosition } from "../../../../utils/userData";
import { useSelector } from "react-redux";

const RequestOverTimeCard = ({ row }) => {
  const { t, i18n } = useTranslation();
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );

  function findSummaryOfOverTimeHour(row) {
    const summary =
      row.totalOtHours > 0 ? (row.totalOtHours / 60).toFixed(2) : 0.0;

    let status;
    if (summary > 29.9) {
      status = "red";
    } else if (summary > 8 && summary <= 29.9) {
      status = "yellow";
    } else {
      status = "green";
    }

    return {
      hours: `${summary}`,
      status: status,
    };
  }

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        borderRadius: 4,
        display: "flex",
        alignItems: "flex-start",
        bgcolor: "#F1F0F0",
        width: "100%",
        marginBottom: "10px",
        whiteSpace: "pre-wrap",
      }}
    >
      <Avatar
        sx={{ width: 60, height: 60, mr: 2, flexShrink: 0 }}
        src={row.imageURL}
      />
      <Box sx={{ flexGrow: 1, minWidth: 0 }}>
        {/* Added minWidth: 0 */}
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("FullName")}: {getUserFullName(row)} ({getUserPosition(row)})
        </Typography>
        <Typography
          variant="body2"
          sx={{
            wordWrap: "break-word",
            display: "flex",
            flexWrap: "wrap",
            lineHeight: 1.2, // Reduce line height
            "& .label": {
              width: "48px", // Adjust this width as needed
              flexShrink: 0,
            },
            "& .content": {
              flex: 1,
              marginBottom: 0, // Remove bottom margin
            },
            "& .indent": {
              paddingLeft: "48px", // Should match the width of .label
              width: "100%",
              marginTop: "2px", // Add a small top margin for slight separation
            },
          }}
        >
          <span className="label">{t("Type")}:</span>
          <span className="content">{t(row.name)}</span>
          {row.xWorkingDailyHoliday > 0 && (
            <span className="indent">
              OT x {payrollSetting.xWorkingDailyHoliday} ={" "}
              {parseFloat(row.xWorkingDailyHoliday / 60).toFixed(2)}{" "}
              {`${t("Unit.ShortHours")}`}
            </span>
          )}
          {row.xWorkingMonthlyHoliday > 0 && (
            <span className="indent">
              OT x {payrollSetting.xWorkingMonthlyHoliday} ={" "}
              {parseFloat(row.xWorkingMonthlyHoliday / 60).toFixed(2)}{" "}
              {`${t("Unit.ShortHours")}`}
            </span>
          )}
          {row.xOT > 0 && (
            <span className="indent">
              OT x {payrollSetting.xOT} = {parseFloat(row.xOT / 60).toFixed(2)}{" "}
              {`${t("Unit.ShortHours")}`}
            </span>
          )}
          {row.xOTHoliday > 0 && (
            <span className="indent">
              OT x {payrollSetting.xOTHoliday} ={" "}
              {parseFloat(row.xOTHoliday / 60).toFixed(2)}{" "}
              {`${t("Unit.ShortHours")}`}
            </span>
          )}
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("Start")}:{" "}
          {dayjs(row.startText, "DD/MM/YYYY HH:mm").format(
            i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
          )}{" "}
          {dayjs(row.startText, "DD/MM/YYYY HH:mm").format(
            i18n.resolvedLanguage === "th" ? "HH:mm" : "h:mm A"
          )}
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("End")}:{" "}
          {dayjs(row.endText, "DD/MM/YYYY HH:mm").format(
            i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
          )}{" "}
          {dayjs(row.endText, "DD/MM/YYYY HH:mm").format(
            i18n.resolvedLanguage === "th" ? "HH:mm" : "h:mm A"
          )}
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("otZoneWeek")}:
          <Box
            sx={{
              display: "inline-block",
              width: "16px",
              height: "16px",
              bgcolor: findSummaryOfOverTimeHour(row).status,
              borderRadius: "50%",
              marginLeft: "8px",
            }}
          />
          {" "}
          {"("}
          {findSummaryOfOverTimeHour(row).hours} {t("Unit.Hours")}
          {")"}
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("Reason")}: {row.reasonName}
        </Typography>
      </Box>
    </Paper>
  );
};

export default RequestOverTimeCard;
