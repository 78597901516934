import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box, styled, Typography } from "@mui/material";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DragHandleRoundedIcon from "@mui/icons-material/DragHandleRounded";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import {
  getLevelByidCompany,
  addGroupLevel,
  addLevel,
} from "../../../../../actions/level";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  width: 350,
  padding: 24,
  "& .second": {
    marginTop: 16,
    "& .head": {
      marginBottom: 8,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  "& .footer": {
    marginTop: 16,
    display: "flex",
    justifyContent: "space-between",
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  display: "flex",
  userSelect: "none",
  alignItems: "center",
  marginBottom: 8,
  // styles we need to apply on draggables
  ...draggableStyle,
});

const DialogDetail = ({
  open,
  handleClose,
  mode,
  editLevel,
  selectedCompany,
  isReadOnly
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: levelList } = useSelector((state) => state.level);
  const [formData, setFormData] = useState(null);
  const [groupLevel, setGroupLevel] = useState(null);
  const [orderGroup, setOrderGroup] = useState(null);
  const [disabledBtnSave, setDisabledBtnSave] = useState(false);

  useEffect(() => {
    setOrderGroup(null);
    setDisabledBtnSave(false);
    if (mode === "group") {
      let temp = [];
      levelList.forEach((element) => {
        temp.push({
          idGroupLevel: element.idGroupLevel,
          groupLevelName: element.groupLevelName,
          indexGroupLevel: element.indexGroupLevel,
        });
      });
      setDisabledBtnSave(temp.length > 0 ? false : true);
      setOrderGroup(temp);
    }
  }, [mode]);

  useEffect(() => {
    setFormData(null);
    if (editLevel) {
      let findGroupLevel = levelList.find((x) => x.idGroupLevel === editLevel);
      setGroupLevel(findGroupLevel);
      setFormData(findGroupLevel.level);
      setDisabledBtnSave(findGroupLevel.level.length > 0 ? false : true);
    }
  }, [editLevel]);

  const handleAddNewLevel = () => {
    setDisabledBtnSave(false);
    let temp = [...formData];
    let lastIndexLevel = Math.max(...formData.map((o) => o.indexLevel), 0);
    temp.push({
      idGroupLevel: groupLevel.idGroupLevel,
      idLevel: null,
      levelName: `${groupLevel.groupLevelName}${lastIndexLevel + 1}`,
      indexLevel: lastIndexLevel + 1,
      idCompany: selectedCompany.idCompany,
    });
    setFormData(temp);
  };

  const handleAddNewGroupLevel = () => {
    setDisabledBtnSave(false);
    let temp = [...orderGroup];
    let lastIndexLevel = Math.max(
      ...orderGroup.map((o) => o.indexGroupLevel),
      0
    );
    temp.push({
      idGroupLevel: null,
      groupLevelName: `กลุ่มระดับ${lastIndexLevel + 1}`,
      indexGroupLevel: lastIndexLevel + 1,
      idCompany: selectedCompany.idCompany,
    });

    console.log("temp: ", temp);
    setOrderGroup(temp);
  };

  function onDragEnd(result) {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      let items = reorder(formData, source.index, destination.index);

      items = items.map((item, index) => ({
        ...item,
        indexLevel: index + 1,
      }));

      setFormData(items);
    } else {
      return;
    }
  }

  function onDragGroupEnd(result) {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      let items = reorder(orderGroup, source.index, destination.index);
      items = items.map((item, index) => ({
        ...item,
        indexGroupLevel: index + 1,
      }));
      setOrderGroup(items);
    } else {
      return;
    }
  }

  const handleSaveChange = async () => {
    setDisabledBtnSave(true);
    if (mode === "group") {
      console.log("orderGroup: ", orderGroup);
      let result = await dispatch(addGroupLevel(orderGroup));

      if (result) {
        ///handleOpenAlert();
        if (result.status === 200) {
          dispatch(
            getLevelByidCompany({ idCompany: selectedCompany.idCompany })
          );
        } else {
          //handleChangeAlertType(result.data.message);
        }
        handleClose();
      } else {
        // handleOpenAlert();
        // handleChangeAlertType("error");
        handleClose();
      }
    } else if (mode === "edit") {
      let result = await dispatch(addLevel(formData));

      if (result) {
        ///handleOpenAlert();
        if (result.status === 200) {
          dispatch(
            getLevelByidCompany({ idCompany: selectedCompany.idCompany })
          );
        } else {
          //handleChangeAlertType(result.data.message);
        }
        handleClose();
      } else {
        // handleOpenAlert();
        // handleChangeAlertType("error");
        handleClose();
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const splitName = name.split("-");
    console.log("splitName: ", splitName);
    let temp = null;
    if (splitName[0].includes("group")) {
      temp = [...orderGroup];
      temp[parseInt(splitName[1])].groupLevelName = value;
      setOrderGroup(temp);
    } else {
      temp = [...formData];
      temp[parseInt(splitName[1])].levelName = value;
      setFormData(temp);
    }
  };

  return (
    <DrawerCustom
      title={`${mode === "edit" ? `${t("Edit")}` : `${t("Add")}`} ${t(
        "LevelGroup"
      )}`}
      anchor={"right"}
      open={open}
      onClose={handleClose}
    >
      <StyledRoot>
        {mode === "group" ? (
          <Fragment>
            <Box className="second">
              <div className="head">
                <StyledContentLabel color="text.third" gutterBottom>
                  {t("LevelGroup")}
                </StyledContentLabel>
                <ButtonBlue
                  size="small"
                  startIcon={<AddRoundedIcon />}
                  onClick={handleAddNewGroupLevel}
                >
                  {t("AddLevelGroup")}
                </ButtonBlue>
              </div>
              {orderGroup && (
                <DragDropContext onDragEnd={onDragGroupEnd}>
                  <Droppable droppableId="droppable-group-level">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {orderGroup.map((item, index) => (
                          <Draggable
                            key={item.indexGroupLevel}
                            draggableId={item.indexGroupLevel.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <DragHandleRoundedIcon />
                                <TextFieldTheme
                                  style={{ marginLeft: 8 }}
                                  value={item.groupLevelName}
                                  name={`groupLevelName-${index}`}
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </Box>
          </Fragment>
        ) : mode === "edit" ? (
          <Fragment>
            {formData && (
              <Fragment>
                <div>
                  <StyledContentLabel color="text.third" gutterBottom>
                    {t("LevelGroupName")}
                  </StyledContentLabel>
                  <TextFieldTheme
                    name="groupLevelName"
                    value={groupLevel.groupLevelName}
                    onChange={handleChange}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
                <div className="second">
                  <div className="head">
                    <StyledContentLabel color="text.third">
                      {t("Level")}
                    </StyledContentLabel>
                    <ButtonBlue
                      size="small"
                      startIcon={<AddRoundedIcon />}
                      onClick={handleAddNewLevel}
                    >
                      {t("AddLevel")}
                    </ButtonBlue>
                  </div>

                  <Box>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable-group-level">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {formData.map((item, index) => (
                              <Draggable
                                key={item.indexLevel}
                                draggableId={item.indexLevel.toString()}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <DragHandleRoundedIcon />
                                    <TextFieldTheme
                                      style={{ marginLeft: 8 }}
                                      value={item.levelName}
                                      name={`levelName-${index}`}
                                      onChange={handleChange}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                </div>
              </Fragment>
            )}
          </Fragment>
        ) : null}
        <div className="footer">
          <ButtonBlue
            onClick={() => {
              console.log("levelList: ", levelList);
              handleClose();
            }}
          >
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue
            variant="contained"
            onClick={handleSaveChange}
            disabled={disabledBtnSave || isReadOnly}
          >
            {t("Save")}
          </ButtonBlue>
        </div>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogDetail;
