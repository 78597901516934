import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import "dayjs/locale/th";

import {
  Button,
  Container,
  Typography,
  CardContent,
  ToggleButtonGroup,
  ToggleButton,
  TablePagination,
  TextField,
  Grid,
  Popper,
  Box,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  FormControl,
  FormHelperText,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import Dropzone, { useDropzone } from "react-dropzone";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import FilePresentIcon from "@mui/icons-material/FilePresent";

import GalleryIcon from "../../../../assets/gallery.png";
import utils from "../../../../../../utils";
import { currency } from "../../../../assets/data/currency";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import AlertResponse from "../../../../shared/general/AlertResponse";
import Backdrop from "../../../../shared/general/Backdrop";

import RowItemExpense from "../../create/general/row-general";
import RowSummaryItemExpense from "../../create/general/row-summary";
import ConfirmDialog from "../../confirm-dialog";
import SelectApprover from "../../select-approver";
import ActionButtons from "../action-buttons";
import Comments from "../../comments";
import Actions from "../../actions";

import {
  getExpenseFlexCoinById,
  updateExpenseFlexCoin,
  deleteExpenseFlexCoin,
} from "../../../../../../actions/expense";
import { getCoinBalance } from "../../../../../../actions/flexCoin";

import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 24,
  marginBottom: 20,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: "flex",
  "& .MuiToggleButtonGroup-grouped": {
    color: "#46cbe2",
    border: "1px solid #46cbe2",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#00C0E1",
      backgroundColor: "#00C0E1",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#46cbe2",
      "&:hover": {
        borderColor: "#00C0E1",
        backgroundColor: "#00C0E1",
        color: "#FFFFFF",
      },
    },
  },
  "& ,MuiToggleButtonGroup-root": {
    height: 50,
  },
}));

const StyledTableCell = styled(TableCell)({
  border: "none",
  padding: 0,
  "& .MuiTypography-root": {
    marginBottom: 16,
  },
  "&.summary": {
    textAlign: "center",
  },
  "&.order": {
    width: 28,
  },
  "&.date-document": {
    width: 120,
  },
  "&.service": {
    width: 150,
  },
  "&.description": {
    width: 200,
  },
  "&.amount": {
    width: 104,
  },
  "&.price": {
    width: 104,
  },
  "&.discount": {
    width: 104,
  },
  "&.tax": {
    width: 40,
  },
  "&.price-before-tax": {
    width: 104,
  },
  "&.tax-withholding": {
    width: 104,
  },
  "&.delete": {
    width: 32,
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 24,
  backgroundColor: "#f9f9fb",
  "& .dropzone-leave": {
    // height: 98,
    borderRadius: 8,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    // height: 90,
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#efefef",
    flexDirection: "column",
    padding: "40px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const rowHeader = [
  {
    caption: "",
    className: "delete",
  },
  {
    caption: "DocumentDate",
    className: "date-document",
  },
  {
    caption: "ProductAndService",
    className: "service",
  },
  {
    caption: "Description",
    className: "description",
  },
  {
    caption: "Quantity",
    className: "amount",
  },
  {
    caption: "PricePerUnit",
    className: "price",
  },
  {
    caption: "Vat",
    className: "tax",
  },
  {
    caption: "PriceBeforeTax",
    className: "price-before-tax",
  },
  {
    caption: "Withholding",
    className: "tax-withholding",
  },
  {
    caption: "",
    className: "delete",
  },
];

const summaryRowHeader = [
  {
    caption: "",
    className: "order",
  },
  {
    caption: "ProductAndService",
    className: "service",
  },
  {
    caption: "Quantity",
    className: "amount",
  },
  {
    caption: "PricePerUnit",
    className: "price",
  },
  {
    caption: "Vat",
    className: "tax",
  },
  {
    caption: "PriceBeforeTax",
    className: "price-before-tax",
  },
  {
    caption: "Withholding",
    className: "tax-withholding",
  },
];

const FlexCoin = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { idExpense } = useParams();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: expenseDetail } = useSelector((state) => state.expenseDetail);
  const { result: flexCoin } = useSelector((state) => state.flexCoin);
  const { typeExpense, typeEdit } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [listMenuTaxOrWithholding, setListMenuTaxOrWithholding] =
    React.useState(null);
  const open = Boolean(anchorEl);
  const [fileSelected, setFileSelected] = useState([]);
  const [isInternational, setIsInternational] = useState(false);
  const [currencyRate, setCurrencyRate] = useState(1.0);
  const today = dayjs().toDate();
  const [tempDate, setTempDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate())
  );
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [deletedRow, setDeletedRow] = useState([]);
  const [modalStatusConfig, setModalStatusConfig] = useState({
    open: false,
    title: "",
    content: "",
    type: "success",
  });
  const listMenuVatType = [
    { label: t("VatExcluded"), isVatIncluded: false, vat: 7 },
    { label: t("VatIncluded"), isVatIncluded: true, vat: 7 },
    { label: t("NoVat"), isVatIncluded: false, vat: 0 },
  ];

  const [dataInitial, setDataInitial] = useState({
    idExpenseGoodsItem: null,
    documentDate: dayjs(tempDate).format("YYYY/MM/DD HH:mm"),
    service: "",
    description: "",
    amount: 1,
    unitPrice: 0,
    taxPercent: 7,
    tax: 0,
    total: 0,
    totalPrice: 0,
    withholdingPercent: 0,
    withholding: 0,
    net: 0,
    unitPriceInternational: 0,
    taxInternational: 0,
    totalBeforeTaxInternational: 0,
    totalPriceInternational: 0,
    withholdingInternational: 0,
    netInternational: 0,
  });
  const [formData, setFormData] = useState({
    nameExpense: "",
    approver: "",
    isInternational: isInternational,
    currency: "THB",
    currencyItem: currency()[0],
    currencyUnit: currency()[0].unit,
    currencyRate: currencyRate,
    vatType: t("VatExcluded"),
    isVatIncluded: false,
    listExpense: [dataInitial],
    file: null,
    remark: "",
    typeExpenseName: typeExpense,
    total: 0,
    vat: 0,
    withholding: 0,
    net: 0,
    replyComment: "",
    flexCoins: 0,
  });
  const defaultCurrency = currency()[0];
  const MAX_FILE_SIZE = 512000; //500KB

  const validFileExtensions = { file: ["jpg", "png", "jpeg"] };

  const isValidFileType = (fileName, fileType) => {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  };

  const validationSchema = Yup.object().shape({
    nameExpense: Yup.string().required(t("ThisFieldIsRequired")),
    approver: Yup.string().required(t("ThisFieldIsRequired")),
    isInternational: isInternational,
    currency: Yup.string().required(t("ThisFieldIsRequired")),
    currencyUnit: Yup.string().required(t("ThisFieldIsRequired")),
    currencyRate: Yup.string().required(t("ThisFieldIsRequired")).default(1),
    vatType: Yup.string().required(t("ThisFieldIsRequired")),
    isVatIncluded: Yup.boolean().required(t("ThisFieldIsRequired")),
    remark: Yup.string(),
    typeExpenseName: Yup.string().required(t("ThisFieldIsRequired")),
    total: Yup.string().required(t("ThisFieldIsRequired")).default(0),
    vat: Yup.string().required(t("ThisFieldIsRequired")).default(0),
    withholding: Yup.string().required(t("ThisFieldIsRequired")).default(0),
    net: Yup.string().required(t("ThisFieldIsRequired")).default(0),
    listExpense: Yup.array().of(
      Yup.object().shape({
        documentDate: Yup.date().required(t("ThisFieldIsRequired")),
        service: Yup.string().required(t("ThisFieldIsRequired")),
        description: Yup.string().required(t("ThisFieldIsRequired")),
        amount: Yup.string().required(t("ThisFieldIsRequired")),
        unitPrice: Yup.string().required(t("ThisFieldIsRequired")),
        taxPercent: Yup.string().required(t("ThisFieldIsRequired")),
        tax: Yup.string().required(t("ThisFieldIsRequired")),
        total: Yup.string().required(t("ThisFieldIsRequired")),
        totalPrice: Yup.string().required(t("ThisFieldIsRequired")),
        withholdingPercent: Yup.string().required(t("ThisFieldIsRequired")),
        withholding: Yup.string().required(t("ThisFieldIsRequired")),
        net: Yup.string().required(t("ThisFieldIsRequired")),
        unitPriceInternational: Yup.string().nullable(true),
        taxInternational: Yup.string().nullable(true),
        totalBeforeTaxInternational: Yup.string().nullable(true),
        totalPriceInternational: Yup.string().nullable(true),
        withholdingInternational: Yup.string().nullable(true),
        netInternational: Yup.string().nullable(true),
      })
    ),
    file: Yup.mixed()
      .test("is-valid-type", "Not a valid file type", (value) => {
        if (value) {
          return isValidFileType(value && value.name.toLowerCase(), "file");
        } else {
          return true;
        }
      })
      .test("is-valid-size", "Max allowed size is 500KB", (value) => {
        if (value) {
          return value && value.size <= MAX_FILE_SIZE;
        } else {
          return true;
        }
      }),
    flexCoins: Yup.string().required(t("ThisFieldIsRequired")).default(0),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    watch,
    reset,
    register,
  } = useForm({
    defaultValues: { ...formData },
    resolver: yupResolver(validationSchema),
  });

  const { append, remove, fields } = useFieldArray({
    control: control,
    name: "listExpense",
  });

  const checkValidation = (error) => {
    console.log(getValues("listExpense"));
    console.log("error : ", error);
    setModalStatusConfig({
      open: true,
      title: "",
      content: t("ThisFieldIsRequired"),
      type: "error",
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, application/pdf",
    onDrop: (acceptedFiles) => {
      let formDataFile = new FormData();
      acceptedFiles.map((file) => formDataFile.append("file", file));
      setFormData({
        ...formData,
        file: formDataFile,
      });
    },
    maxFiles: 1,
  });

  useEffect(() => {
    if (userProfile) {
      dispatch(getExpenseFlexCoinById(idExpense));
      dispatch(getCoinBalance(userProfile.idEmployees));
    }
  }, [userProfile]);

  useEffect(() => {
    if (expenseDetail && expenseDetail.idExpense === idExpense) {
      let resultIndex = listMenuVatType.findIndex(
        (element) =>
          element.isVatIncluded === expenseDetail.isVatIncluded &&
          expenseDetail.idExpense === idExpense
      );

      reset(expenseDetail);
      setValue("isVatIncluded", expenseDetail.isVatIncluded);
      setValue("currencyUnit", expenseDetail.currency);
      setValue(
        "currencyItem",
        currency[
          currency.findIndex(
            (element) => element.unit === expenseDetail.currency
          )
        ]
      );
      setValue("approver", expenseDetail.idEmpApprover);
      if (expenseDetail && expenseDetail.isVatIncluded) {
        setValue(
          "vatType",
          listMenuVatType[
            listMenuVatType.findIndex(
              (element) => element.isVatIncluded === expenseDetail.isVatIncluded
            )
          ].label
        );
      }
    }
  }, [expenseDetail]);

  useEffect(() => {
    reCalculateSum();
  }, [
    watch("currencyRate"),
    watch("vatType"),
    watch("currencyUnit"),
    watch("listExpense").length,
  ]);

  const thumbs = fileSelected.map((file) => (
    <img key={file.name} src={file.preview} />
  ));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const convertCurrency = (value) => {
    if (getValues("currencyRate")) {
      return fixPoint(
        parseFloat(value) * parseFloat(getValues("currencyRate"))
      );
    } else {
      return 0;
    }
  };

  const fixPoint = (value) => {
    return parseFloat(value).toFixed(2);
  };

  const handleIsNan = (value) => {
    return isNaN(value) ? 0 : value;
  };

  const reCalculateSum = () => {
    if (expenseDetail && getValues("listExpense")) {
      let listExpense = getValues("listExpense").map((item, index) => {
        let unitPrice = getValues("isVatIncluded")
          ? parseFloat(item.unitPrice * 100) /
            (100 + parseFloat(item.taxPercent))
          : parseFloat(item.unitPrice);
        let newTotal = parseFloat(item.amount) * unitPrice;
        let newTax = newTotal * (parseFloat(item.taxPercent) / 100);
        let newWitholding =
          newTotal * (parseFloat(item.withholdingPercent) / 100);
        let newNet = newTotal + newTax - newWitholding;
        let newTotalPrice = newTotal + newTax;
        setValue(`listExpense.${index}.tax`, fixPoint(newTax));
        setValue(`listExpense.${index}.withholding`, fixPoint(newWitholding));
        setValue(`listExpense.${index}.total`, fixPoint(newTotal));
        setValue(`listExpense.${index}.totalPrice`, fixPoint(newTotalPrice));
        setValue(`listExpense.${index}.net`, fixPoint(newNet));
        if (getValues("currencyUnit") === "THB") {
          setValue(`listExpense.${index}.unitPriceInternational`, null);
          setValue(`listExpense.${index}.taxInternational`, null);
          setValue(`listExpense.${index}.withholdingInternational`, null);
          setValue(`listExpense.${index}.totalBeforeTaxInternational`, null);
          setValue(`listExpense.${index}.totalPriceInternational`, null);
          setValue(`listExpense.${index}.netInternational`, null);
        } else {
          setValue(
            `listExpense.${index}.unitPriceInternational`,
            handleIsNan(convertCurrency(unitPrice))
          );
          setValue(
            `listExpense.${index}.taxInternational`,
            handleIsNan(convertCurrency(newTax))
          );
          setValue(
            `listExpense.${index}.withholdingInternational`,
            handleIsNan(convertCurrency(newWitholding))
          );
          setValue(
            `listExpense.${index}.totalBeforeTaxInternational`,
            handleIsNan(convertCurrency(newTotal))
          );
          setValue(
            `listExpense.${index}.totalPriceInternational`,
            handleIsNan(convertCurrency(newTotalPrice))
          );
          setValue(
            `listExpense.${index}.netInternational`,
            handleIsNan(convertCurrency(newNet))
          );
        }
      });

      calculateSum();
    }
  };

  const calculateRowSum = (index) => {
    let expenseDetail = getValues(`listExpense.${index}`);
    let unitPrice = getValues("isVatIncluded")
      ? parseFloat(expenseDetail.unitPrice * 100) /
        (100 + parseFloat(expenseDetail.taxPercent))
      : parseFloat(expenseDetail.unitPrice);
    let newTotal = parseFloat(expenseDetail.amount) * unitPrice;
    let newTax = newTotal * (parseFloat(expenseDetail.taxPercent) / 100);
    let newWitholding =
      newTotal * (parseFloat(expenseDetail.withholdingPercent) / 100);
    let newNet = newTotal + newTax - newWitholding;
    let newTotalPrice = newTotal + newTax;
    setValue(`listExpense.${index}.tax`, fixPoint(newTax));
    setValue(`listExpense.${index}.withholding`, fixPoint(newWitholding));
    setValue(`listExpense.${index}.total`, fixPoint(newTotal));
    setValue(`listExpense.${index}.totalPrice`, fixPoint(newTotalPrice));
    setValue(`listExpense.${index}.net`, fixPoint(newNet));
    if (getValues("currencyUnit") === "THB") {
      setValue(`listExpense.${index}.unitPriceInternational`, null);
      setValue(`listExpense.${index}.taxInternational`, null);
      setValue(`listExpense.${index}.withholdingInternational`, null);
      setValue(`listExpense.${index}.totalBeforeTaxInternational`, null);
      setValue(`listExpense.${index}.totalPriceInternational`, null);
      setValue(`listExpense.${index}.netInternational`, null);
    } else {
      setValue(
        `listExpense.${index}.unitPriceInternational`,
        handleIsNan(convertCurrency(unitPrice))
      );
      setValue(
        `listExpense.${index}.taxInternational`,
        handleIsNan(convertCurrency(newTax))
      );
      setValue(
        `listExpense.${index}.withholdingInternational`,
        handleIsNan(convertCurrency(newWitholding))
      );
      setValue(
        `listExpense.${index}.totalBeforeTaxInternational`,
        handleIsNan(convertCurrency(newTotal))
      );
      setValue(
        `listExpense.${index}.totalPriceInternational`,
        handleIsNan(convertCurrency(newTotalPrice))
      );
      setValue(
        `listExpense.${index}.netInternational`,
        handleIsNan(convertCurrency(newNet))
      );
    }
    calculateSum();
  };

  const calculateSum = () => {
    let newListExpense = getValues("listExpense");
    let sumTotal = fixPoint(
      newListExpense.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.total);
      }, 0)
    );
    let sumVat = fixPoint(
      newListExpense.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.tax);
      }, 0)
    );
    let sumWithholding = fixPoint(
      newListExpense.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.withholding);
      }, 0)
    );

    let sumNet =
      parseFloat(sumTotal) + parseFloat(sumVat) - parseFloat(sumWithholding);

    setValue("total", convertCurrency(sumTotal));
    setValue("vat", convertCurrency(sumVat));
    setValue("withholding", convertCurrency(sumWithholding));
    setValue("net", convertCurrency(sumNet));
    setValue("flexCoins", Math.ceil(fixPoint(convertCurrency(sumNet) * 1.1)));
  };

  const createFormData = (statusId) => {
    let data = new FormData();
    data.append("idExpense", expenseDetail.idExpense);
    data.append("idExpenseFlexCoin", expenseDetail.idExpenseFlexCoin);
    data.append("documentId", expenseDetail.documentId);
    data.append("nameExpense", getValues("nameExpense"));
    data.append("isInternational", getValues("isInternational") ? 1 : 0);
    data.append("isVatIncluded", getValues("isVatIncluded") ? 1 : 0);
    data.append("currency", getValues("currency"));
    data.append("currencyItem", getValues("currencyItem"));
    data.append("currencyRate", getValues("currencyRate"));
    data.append("listExpense", JSON.stringify(getValues("listExpense")));
    if (getValues("file")) {
      data.append("file", getValues("file"), getValues("file.path"));
    }
    data.append("remark", getValues("remark"));
    data.append("typeExpense", 5);
    data.append("typeExpenseName", getValues("typeExpenseName"));
    data.append("lastUpdateDate", dayjs(today).format("YYYY/MM/DD HH:mm"));
    data.append("status", statusId);
    data.append("total", getValues("total"));
    data.append("vat", getValues("vat"));
    data.append("withholding", getValues("withholding"));
    data.append("net", getValues("net"));
    data.append(
      "comment",
      JSON.stringify(
        getValues("replyComment") ? getValues("replyComment") : null
      )
    );
    data.append("deletedItem", JSON.stringify(deletedRow));
    data.append("idEmpApprover", getValues("approver"));
    data.append("flexCoins", getValues("flexCoins"));
    return data;
  };

  const addDeleteRow = (index) => {
    if (getValues(`listExpense.${index}.idExpenseFlexCoinItem`) !== null) {
      setDeletedRow((prevArray) => [
        ...prevArray,
        getValues(`listExpense.${index}.idExpenseFlexCoinItem`),
      ]);
    }
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleOnClickCleardata = async () => {
    let listIdExpenseFlexCoinItem = expenseDetail.listExpense.map(
      (item, index) => {
        return item.idExpenseFlexCoinItem;
      }
    );
    let isAttachFile = false;
    let filePath = null;
    if (expenseDetail.fileURL) {
      isAttachFile = true;
      filePath = expenseDetail.fileURL.path;
    }
    let data = {
      idExpense: expenseDetail.idExpense,
      idExpenseFlexCoin: expenseDetail.idExpenseFlexCoin,
      listExpense: listIdExpenseFlexCoinItem,
      isAttachFile: isAttachFile,
      filePath: filePath,
    };
    let result = null;
    if (userProfile && userProfile.idCompany && userProfile.idEmployees) {
      result = await dispatch(deleteExpenseFlexCoin(data));
    }
    if (result) {
      if (result.status === 200) {
        handleChangeAlertType("success");
        handleOpenAlert();
        history.push(`/expense`);
      } else {
        handleChangeAlertType("error");
        handleOpenAlert();
      }
    }
  };

  const handleOnClickSavedraft = async () => {
    let data = createFormData(1);
    let result = null;
    if (userProfile && userProfile.idCompany && userProfile.idEmployees) {
      result = await dispatch(updateExpenseFlexCoin(data));
    }
    if (result) {
      if (result.status === 200) {
        handleChangeAlertType("success");
        handleOpenAlert();
        dispatch(getExpenseFlexCoinById(idExpense));
      } else {
        handleChangeAlertType("error");
        handleOpenAlert();
      }
    }
  };

  const handleOnClickSent = async () => {
    let status = 2;
    if (typeEdit === "Edit") {
      status = 3;
    }
    let data = createFormData(status);
    let result = null;
    if (userProfile && userProfile.idCompany && userProfile.idEmployees) {
      result = await dispatch(updateExpenseFlexCoin(data));
    }
    if (result) {
      if (result.status === 200) {
        handleChangeAlertType("success");
        handleOpenAlert();
        history.push(`/expense`);
      } else {
        handleChangeAlertType("error");
        handleOpenAlert();
      }
    }
  };

  const checkCoinBalance = () => {
    if (flexCoin && expenseDetail) {
      if (
        parseInt(flexCoin.balance) + parseInt(expenseDetail.flexCoins) >=
        getValues("flexCoins")
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <div>
      {expenseDetail ? (
        <Box>
          <AlertResponse
            open={openAlert}
            handleClose={handleCloseAlert}
            alertType={alertType}
          />
          <form>
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">
                  {t("GeneralInformation")}
                  <Typography variant="subtitle" color="text.secondary">
                    &nbsp;({t(`${typeEdit}`)})
                  </Typography>
                </StyledHeadLabel>
              </div>
              <div>
                <StyledHeadLabel
                  variant="body2"
                  color="text.third"
                  gutterBottom
                >
                  {t("TitleName")}
                </StyledHeadLabel>
                <FormControl
                  fullWidth
                  error={errors && errors.nameExpense ? true : false}
                >
                  {errors &&
                    errors.nameExpense &&
                    errors.nameExpense.message && (
                      <FormHelperText error>
                        {errors.nameExpense.message}
                      </FormHelperText>
                    )}
                  <Controller
                    name="nameExpense"
                    control={control}
                    errors={errors}
                    render={({ field }) => (
                      <>
                        <TextFieldTheme
                          {...field}
                          name="nameExpense"
                          inputProps={{ maxLength: 200 }}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                        />
                        <Typography
                          component="p"
                          textAlign="right"
                          variant="caption"
                          color="text.secondary"
                        >{`${field.value.length}/200`}</Typography>
                      </>
                    )}
                  />
                </FormControl>
              </div>
              <div>
                <FormControl
                  fullWidth
                  error={errors && errors.approver ? true : false}
                >
                  {errors && errors.approver && errors.approver.message && (
                    <FormHelperText error>
                      {errors.approver.message}
                    </FormHelperText>
                  )}
                  <Controller
                    name="approver"
                    control={control}
                    errors={errors}
                    render={({ field }) => (
                      <>
                        <SelectApprover
                          {...field}
                          idApprover={getValues("approver")}
                          handleChange={setValue}
                          position={userProfile && userProfile.idCompany === 3}
                        />
                      </>
                    )}
                  />
                </FormControl>
              </div>
              <div>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <StyledHeadLabel
                      variant="body2"
                      color="text.third"
                      gutterBottom
                    >
                      {t("LocationExpenses")}
                    </StyledHeadLabel>
                    <Controller
                      name="isInternational"
                      control={control}
                      errors={errors}
                      render={({ field }) => (
                        <>
                          <StyledToggleButtonGroup
                            {...field}
                            exclusive
                            aria-label="filter"
                            onChange={(e, value) => {
                              setValue("isInternational", value);
                            }}
                          >
                            <ToggleButton
                              name="isInternational"
                              value={false}
                              aria-label="local"
                              key="local"
                              style={{
                                width: "100%",
                                borderTopLeftRadius: 8,
                                borderBottomLeftRadius: 8,
                              }}
                            >
                              {t("Domestic")}
                            </ToggleButton>
                            <ToggleButton
                              name="isInternational"
                              value={true}
                              aria-label="international"
                              key="international"
                              style={{
                                width: "100%",
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                              }}
                            >
                              {t("International")}
                            </ToggleButton>
                          </StyledToggleButtonGroup>
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledHeadLabel
                      variant="body2"
                      color="text.third"
                      gutterBottom
                    >
                      {t("vatType")}
                    </StyledHeadLabel>
                    <FormControl
                      fullWidth
                      error={errors && errors.vatType ? true : false}
                    >
                      <Controller
                        name="vatType"
                        control={control}
                        errors={errors}
                        render={({ field }) => (
                          <>
                            <StyledAutocomplete
                              {...field}
                              fullWidth
                              value={watch("vatType")}
                              options={listMenuVatType}
                              onChange={(e, newValue) => {
                                let tempdata = { ...dataInitial };
                                if (newValue === null) {
                                  setValue("vatType", listMenuVatType[0].label);
                                  setValue(
                                    "isVatIncluded",
                                    listMenuVatType[0].isVatIncluded
                                  );
                                  let list = getValues("listExpense");
                                  list.forEach((element, index) => {
                                    setValue(
                                      `listExpense.${index}.taxPercent`,
                                      listMenuVatType[0].vat
                                    );
                                  });
                                  tempdata.taxPercent = listMenuVatType[0].vat;
                                  setDataInitial(tempdata);
                                } else {
                                  setValue("vatType", newValue.label);
                                  setValue(
                                    "isVatIncluded",
                                    newValue.isVatIncluded
                                  );
                                  let list = getValues("listExpense");
                                  list.forEach((element, index) => {
                                    setValue(
                                      `listExpense.${index}.taxPercent`,
                                      newValue.vat
                                    );
                                  });
                                  tempdata.taxPercent = newValue.vat;
                                  setDataInitial(tempdata);
                                }
                              }}
                              popupIcon={
                                <i class="fa-light fa-chevron-down"></i>
                              }
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {t(`${option.label}`)}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder=""
                                  name="vatType"
                                />
                              )}
                              PopperComponent={StyledPopper}
                              noOptionsText={t("NoData")}
                            />
                          </>
                        )}
                      />
                      {errors && errors.vatType && errors.vatType.message && (
                        <FormHelperText error>
                          {errors.vatType.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <StyledHeadLabel
                      variant="body2"
                      color="text.third"
                      gutterBottom
                    >
                      {t("Currency")}
                    </StyledHeadLabel>
                    <FormControl
                      fullWidth
                      error={errors && errors.currency ? true : false}
                    >
                      <Controller
                        name="currencyItem"
                        control={control}
                        errors={errors}
                        render={({ field }) => (
                          <>
                            <StyledAutocomplete
                              {...field}
                              fullWidth
                              options={currency()}
                              onChange={(e, newValue) => {
                                let tempdata = { ...dataInitial };
                                if (newValue === null) {
                                  setValue("currencyItem", defaultCurrency);
                                  setValue("currency", "THB");
                                  setValue("currencyUnit", "THB");
                                  setValue("currencyRate", 1);
                                } else {
                                  if (newValue.unit === "THB") {
                                    setValue("currencyRate", 1);
                                  }
                                  setValue("currencyItem", newValue);
                                  setValue("currency", newValue.unit);
                                  setValue("currencyUnit", newValue.unit);
                                }
                              }}
                              popupIcon={
                                <i class="fa-light fa-chevron-down"></i>
                              }
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <img
                                    loading="lazy"
                                    width="20"
                                    style={{ marginRight: 16, flexShrink: 0 }}
                                    src={`https://flagcdn.com/16x12/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/32x24/${option.code.toLowerCase()}.png 2x`}
                                    alt={option.code}
                                  />
                                  {t(`${option.label}`)}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder=""
                                  name="vatType"
                                />
                              )}
                              PopperComponent={StyledPopper}
                              noOptionsText={t("NoData")}
                            />
                          </>
                        )}
                      />
                      {errors && errors.currency && errors.currency.message && (
                        <FormHelperText error>
                          {errors.currency.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {watch("currency") && watch("currency") !== "THB" && (
                    <Grid item xs={12} sm={6}>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("ExchangeRate")}
                        <Typography variant="body" color="text.secondary">
                          ( {t("Baht")} {t("Per")} 1
                          {t(`${watch("currencyUnit")}`)})
                        </Typography>
                      </StyledHeadLabel>
                      <FormControl
                        fullWidth
                        error={errors && errors.currencyRate ? true : false}
                      >
                        <Controller
                          name="currencyRate"
                          control={control}
                          errors={errors}
                          render={({ field }) => (
                            <>
                              <TextFieldTheme
                                {...field}
                                name="currencyRate"
                                InputProps={{
                                  inputComponent: NumberFormatTheme,
                                }}
                                inputProps={{
                                  decimalScale: 2,
                                  allowNegative: false,
                                  value: field.value,
                                  onValueChange: (values) => {
                                    const { value } = values;
                                    field.onChange(value);
                                    reCalculateSum();
                                  },
                                }}
                              />
                            </>
                          )}
                        />
                      </FormControl>
                      {errors &&
                        errors.currencyRate &&
                        errors.currencyRate.message && (
                          <FormHelperText error>
                            {errors.currencyRate.message}
                          </FormHelperText>
                        )}
                    </Grid>
                  )}
                </Grid>
              </div>
            </div>
            <StyledDivider />
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">{t("List")}</StyledHeadLabel>
                <div>
                  <ButtonBlue
                    size="small"
                    startIcon={<AddRoundedIcon />}
                    variant="outlined"
                    onClick={() => {
                      append(dataInitial);
                    }}
                  >
                    {t("AddItem")}
                  </ButtonBlue>
                </div>
              </div>

              <TableContainer>
                <Table
                  style={{
                    minWidth: 650,
                    borderSpacing: "0 4px",
                    borderCollapse: "separate",
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      {rowHeader.map((item) => (
                        <StyledTableCell className={item.className}>
                          <StyledHeadLabel
                            variant="body2"
                            color="text.third"
                            gutterBottom
                          >
                            {t(item.caption)}
                          </StyledHeadLabel>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields &&
                      fields.length > 0 &&
                      fields
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item, index) => (
                          <RowItemExpense
                            key={item.id}
                            count={fields.length}
                            index={index}
                            control={control}
                            errors={errors}
                            handleDeleteRow={remove}
                            addDeleteRow={addDeleteRow}
                            calculateRowSum={calculateRowSum}
                            type="edit"
                          />
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {watch("listExpense") && watch("listExpense.length") && (
                <TablePagination
                  rowsPerPageOptions={[
                    10,
                    20,
                    30,
                    40,
                    50,
                    { label: t("All"), value: -1 },
                  ]}
                  component="div"
                  count={fields.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={`${t("RowsPerPage")}`}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} ${t("OutOf")} ${
                      count !== -1 ? count : `${t("MoreThan")} ${to}`
                    }`
                  }
                />
              )}
            </div>
            <StyledDivider />
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">
                  {t("SumaryItem")}
                </StyledHeadLabel>
              </div>
              {watch("currency") && watch("currency") !== "THB" && (
                <div style={{ marginBottom: 32 }}>
                  <TableContainer>
                    <Table
                      style={{
                        minWidth: 650,
                        borderSpacing: "0 4px",
                        borderCollapse: "separate",
                      }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          {summaryRowHeader.map((item) => (
                            <StyledTableCell
                              className={(item.className, "summary")}
                            >
                              <StyledHeadLabel
                                variant="body2"
                                color="text.third"
                                gutterBottom
                              >
                                {t(item.caption)}
                              </StyledHeadLabel>
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fields &&
                          fields.length > 0 &&
                          fields
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((item, index) => (
                              <RowSummaryItemExpense
                                key={item.id}
                                count={fields.length}
                                data={getValues(`listExpense.${index}`)}
                                index={index}
                                open={open}
                                control={control}
                                errors={errors}
                                handleDeleteRow={remove}
                                addDeleteRow={addDeleteRow}
                                setValue={setValue}
                                getValues={getValues}
                              />
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}

              <div>
                <div className="wrap-row-sum">
                  <Typography color="text.third">
                    {t("TotalValue")}&nbsp;&nbsp;
                  </Typography>
                  <Typography color="text.third" variant="h6">
                    {utils.numberWithCommas(parseFloat(watch("total")))}
                  </Typography>
                  <Typography color="text.third">
                    &nbsp;&nbsp;{t("Baht")}
                  </Typography>
                </div>
                <div className="wrap-row-sum">
                  <Typography color="text.third">
                    {t("Vat")}
                    {t("TotalValue")}&nbsp;&nbsp;
                  </Typography>
                  <Typography color="text.third" variant="h6">
                    {utils.numberWithCommas(parseFloat(watch("vat")))}
                  </Typography>
                  <Typography color="text.third">
                    &nbsp;&nbsp;{t("Baht")}
                  </Typography>
                </div>
                <div className="wrap-row-sum">
                  <Typography color="text.third">
                    {t("TotalWithholding")}&nbsp;&nbsp;
                  </Typography>
                  <Typography color="text.third" variant="h6">
                    {utils.numberWithCommas(parseFloat(watch("withholding")))}
                  </Typography>
                  <Typography color="text.third">
                    &nbsp;&nbsp;{t("Baht")}
                  </Typography>
                </div>
                <div className="wrap-row-sum">
                  <Typography color="text.third">
                    {t("TotalNetWorth")}&nbsp;&nbsp;
                  </Typography>
                  <Typography color="text.third" variant="h6">
                    {utils.numberWithCommas(parseFloat(watch("net")))}
                  </Typography>
                  <Typography color="text.third">
                    &nbsp;&nbsp;{t("Baht")}
                  </Typography>
                </div>
                <div className="wrap-row-sum">
                  <Typography variant="h5">
                    {t("CoinsUsed")}&nbsp;&nbsp;
                  </Typography>
                  <Typography variant="h4">
                    {utils.numberWithCommas(parseFloat(watch("flexCoins")))}
                  </Typography>
                  <Typography variant="h5">&nbsp;&nbsp;เหรียญ</Typography>
                </div>
              </div>
            </div>
            <StyledDivider />
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">
                  {t("AttachFile")}
                </StyledHeadLabel>
              </div>
              {!watch("file") && !watch("fileURL") ? (
                <StyledDropzone>
                  <Dropzone
                    accept="image/jpeg, image/png, application/pdf"
                    maxFiles={1}
                    multiple={false}
                    maxSize={3145728}
                    onDrop={(acceptedFiles, rejectedFiles) => {
                      if (acceptedFiles.length > 0) {
                        setValue("file", acceptedFiles[0]);
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps({
                          className: "dropzone-upload-file",
                        })}
                      >
                        <div className="inner-dropzone">
                          <input {...getInputProps()} />
                          {/* <Fragment>{thumbs}</Fragment> */}
                          <div style={{ textAlign: "center" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <img alt="add" src={GalleryIcon} />
                            </div>
                            <Typography
                              variant="caption"
                              style={{ lineHeight: 0.5, color: "#999999" }}
                            >
                              {t("imageFileNotMore500KB")}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </StyledDropzone>
              ) : !watch("fileURL") && watch("file") ? (
                <Box
                  style={{
                    position: "relative",
                    textAlign: "center",
                  }}
                >
                  <IconButton
                    style={{ position: "absolute", margin: 7, right: 0 }}
                    color="error"
                    onClick={() => {
                      setValue("file", null);
                      setValue("fileURL", null);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <div
                    style={{
                      padding: 15,
                      backgroundColor: "#f9f9fb",
                      display: "flex",
                      borderRadius: 15,
                    }}
                  >
                    <FilePresentIcon
                      height="24"
                      style={{
                        marginRight: 10,
                      }}
                    ></FilePresentIcon>
                    <Typography>{watch("file.path")}</Typography>
                  </div>
                </Box>
              ) : (
                <Box
                  style={{
                    position: "relative",
                    textAlign: "center",
                  }}
                >
                  <IconButton
                    style={{ position: "absolute", margin: 7, right: 0 }}
                    color="error"
                    onClick={() => {
                      setValue("file", null);
                      setValue("fileURL", null);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <div
                    style={{
                      padding: 15,
                      backgroundColor: "#f9f9fb",
                      display: "flex",
                      borderRadius: 15,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      expenseDetail.fileURL &&
                        window.open(`${expenseDetail.fileURL.URL}`, "_blank");
                    }}
                  >
                    <FilePresentIcon
                      height="24"
                      style={{
                        marginRight: 10,
                      }}
                    ></FilePresentIcon>
                    {expenseDetail &&
                      expenseDetail.fileURL &&
                      expenseDetail.fileURL.path && (
                        <Typography>{expenseDetail.fileURL.path}</Typography>
                      )}
                  </div>
                </Box>
              )}
            </div>
            <StyledDivider />
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">
                  {t("Note")}({t("More")})
                </StyledHeadLabel>
              </div>
              <Controller
                name={"remark"}
                control={control}
                errors={errors}
                render={({ field }) => (
                  <>
                    <TextFieldTheme {...field} multiline rows={4} />
                    <Typography
                      component="p"
                      textAlign="right"
                      variant="caption"
                      color="text.secondary"
                    >{`${
                      field.value ? field.value.length : 0
                    }/500`}</Typography>
                  </>
                )}
              />
            </div>
            {typeEdit &&
            typeEdit === "Edit" &&
            expenseDetail &&
            expenseDetail.comments &&
            expenseDetail.comments.length &&
            expenseDetail.comments.length > 0 ? (
              <div>
                <Comments comments={expenseDetail.comments} />
                <StyledDivider />
                <FormControl
                  fullWidth
                  error={errors && errors.replyComment ? true : false}
                >
                  {errors &&
                    errors.replyComment &&
                    errors.replyComment.message && (
                      <FormHelperText error>
                        {errors.replyComment.message}
                      </FormHelperText>
                    )}
                  <StyledHeadLabel variant="h6">{t("Reply")}</StyledHeadLabel>
                  <Controller
                    name="replyComment"
                    control={control}
                    errors={errors}
                    render={({ field }) => (
                      <>
                        <TextFieldTheme
                          {...field}
                          name="replyComment"
                          sx={{ marginTop: 1 }}
                          multiline
                          rows={2}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                        />
                      </>
                    )}
                  />
                </FormControl>
              </div>
            ) : (
              <span></span>
            )}
            <StyledDivider />
            {expenseDetail.actions &&
            expenseDetail.actions.length &&
            expenseDetail.actions.length > 0 ? (
              <Actions actions={expenseDetail.actions} />
            ) : (
              <span></span>
            )}
            {checkCoinBalance() && (
              <ActionButtons
                typeEdit={typeEdit}
                handleOnClickCleardata={handleOnClickCleardata}
                handleSubmitDraft={handleSubmit(
                  handleOnClickSavedraft,
                  checkValidation
                )}
                handleSubmitSent={handleSubmit(
                  handleOnClickSent,
                  checkValidation
                )}
              />
            )}
            {flexCoin && flexCoin.balance && !checkCoinBalance() && (
              <Typography variant="subtitle1" color="error">
                {t("NotEnoughCoins")} {t("CoinBalance")}{" "}
                {parseInt(flexCoin.balance) + expenseDetail.flexCoins}
                {t("Coins")}
              </Typography>
            )}
          </form>
        </Box>
      ) : (
        <Backdrop open={true} />
      )}
    </div>
  );
};

export default FlexCoin;
