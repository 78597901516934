import React from "react";
import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";

import SuccessIcon from "../assets/approved.png";
import CancelIcon from "../assets/cancel.png";

const AlertDialog = (props) => {

  const { open, onClose, content, type, callBack } = props;
  return (
    <Dialog
      open={open}
      onClose={() => {
        if(callBack){
          callBack();
        }
        onClose();
      }}
      PaperProps={{
        style: {
          maxWidth: "400px",
          width: "100%",
        }
      }}
    >
      <DialogContent>
        {/* <DialogContentText> */}
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          {type === "success" && <img alt="SuccessIcon" src={SuccessIcon} width="48" style={{marginBottom: "24px"}}/>}
          {type === "error" && <img alt="CancelIcon" src={CancelIcon} width="48" style={{marginBottom: "24px"}}/>}
          <Typography fontSize="18px">{content}</Typography>
        </div>
        {/* </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={
          () => {
            if(callBack){
              callBack();
            }
            onClose(); 
          }
        }>ตกลง</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AlertDialog;