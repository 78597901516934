import React, { useState } from "react";
import { Box, TextField, Typography, Divider, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add'; 
import DeleteIcon from '@mui/icons-material/Delete';
import DatePickerCustom from "../../../shared/date/datePicker";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useDispatch } from "react-redux"; 
import { getKPIDate ,addKPIDate } from "../../../../../actions/kpi";
import Swal from "sweetalert2"; 
import { useTranslation } from "react-i18next";

function DialogEditDate({ type, onClose }) {
  const {t,i18n} = useTranslation();
  const dispatch = useDispatch();
  const [dates, setDates] = useState([]);
  const [newDate, setNewDate] = useState({ period: "", start: "", end: "" });

  const handleNewDateChange = (field, value) => {
    setNewDate({ ...newDate, [field]: value });
  };

  const handleAddDate = () => {
    if (newDate.period && newDate.start && newDate.end) {
      setDates([...dates, newDate]);
      setNewDate({ period: "", start: "", end: "" });
    }
  };

  const handleRemoveDate = (index) => {
    const newDates = dates.filter((_, i) => i !== index);
    setDates(newDates);
  };

  const handleSave = async () => {
    try {
      let hasError = false;
  
      for (const date of dates) {
        const formattedDate = {
          dateName: date.period,
          startDate: date.start,
          endDate: date.end,
          type: type === "add" ? "add" : type === "edit" ? "edit" : "grade",
        };
        const response = await dispatch(addKPIDate(formattedDate));
  
        if (response && response.error) {
          hasError = true; 
          Swal.fire({
            title: t("AnErrorOccurred"),
            text: `${t("SaveDate")} ${date.period} ${t("Failure")}: ${response.error}`,
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
          break;
        }
      }
  
      if (!hasError) {
        onClose();
  
        const successMessage = type === "add"
          ? t("DateForAddingKPISubmitted")
          : type === "edit"
          ? t("DateForEditingKPISubmitted")
          : t("DateForKPIGradeConfirmationSubmitted");
  
        setTimeout(async () => {
          Swal.fire({
            title: t("Success"),
            text: successMessage,
            icon: "success",
            timer: 2000, 
            showConfirmButton: false,
          });
  
          await dispatch(getKPIDate());
        }, 300);
      }
  
    } catch (error) {
      console.error("Error saving KPI dates:", error);
      Swal.fire({
        title: t("AnErrorOccurred"),
        text: `${t("ErrorSavingDate")} ${t("TryAgain")}`,
        icon: "error",
        timer: 3000,
        showConfirmButton: false,
      });
    }
  };
  
  
  return (
    <Box sx={{ padding: "16px", display: "flex", flexDirection: "column", height: "100%" }}>
      <Typography variant="h6">
        {type === "add" ? t("AddDateForAddingKPI") : type === "edit" ? t("AddDateForEditingKPI") : t("AddDateForKPIGradeConfirmation")}
      </Typography>

      <Box display="flex" flexDirection="column" gap="8px" mt={2}>
        <Typography>{t("PeriodName")}</Typography>
        <TextField
          margin="dense"
          value={newDate.period}
          onChange={(e) => handleNewDateChange("period", e.target.value)}
          fullWidth
          variant="outlined"
        />
        <Box display="flex" gap="16px" alignItems="flex-end" justifyContent="space-between">
          <Box flex="1">
            <Typography>{t("StartDate")}</Typography>
            <DatePickerCustom
              value={newDate.start}
              onChange={(newValue) => handleNewDateChange("start", newValue)}
              fullWidth
            />
          </Box>
          <Box flex="1">
            <Typography>{t("EndDate")}</Typography>
            <DatePickerCustom
              value={newDate.end}
              onChange={(newValue) => handleNewDateChange("end", newValue)}
              fullWidth
            />
          </Box>
          <IconButton 
            color="primary" 
            onClick={handleAddDate}
            sx={{ marginBottom: '8px' }}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />

      <Box sx={{ flex: 1, overflowY: "auto", maxHeight: "600px" }}>
        {dates.map((date, index) => (
          <Box key={index} display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Box display="flex" flexDirection="column">
              <Typography variant="body1" fontWeight="bold">{date.period}</Typography>
              <Typography variant="body1">
                {`${new Date(date.start).toLocaleDateString()} - ${new Date(date.end).toLocaleDateString()}`}
              </Typography>
            </Box>
            <IconButton color="error" onClick={() => handleRemoveDate(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
      </Box>

      <Box display="flex" justifyContent="flex-end" mt={4}>
        <ButtonBlue onClick={onClose} variant="contained" color="error" sx={{ mr: 2 }}>
          {t("Cancel")}
        </ButtonBlue>
        <ButtonBlue onClick={handleSave} variant="contained" color="primary">
          {t("Save")}
        </ButtonBlue>
      </Box>
    </Box>
  );
}

export default DialogEditDate;
