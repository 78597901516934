import React, { useEffect, useState } from "react";

import Chart from 'react-apexcharts'

const GraphManpowerSentSuccess = (props) => {

  const {monthlyManpowerSentList, monthlyManpowerSuccessList} = props;


  const [series, setSeries] = useState([
    {
      name: 'กำลังพลที่ส่งไป',
      type: 'line',
      data: [50, 50, 42, 35, 50, 22, 30, 35, 50, 25, 35, 20]
    },
    {
      name: 'กำลังพลที่รับงานสำเร็จ',
      type: 'line',
      data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
    }
  ])

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: 'line',
    },
    stroke: {
      width: 4,
      curve: 'smooth',
      // colors: ["#008ffb", "#d50000"],
    },
    legend: {
      markers: {
        // fillColors: ["#008ffb", "#d50000"],
      }
    },
    title: {
      text: 'กำลังพลที่ส่งไป / กำลังพลที่รับงานสำเร็จ'
    },
    dataLabels: {
      enabled: false,
    },
    labels: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.','ต.ค.','พ.ย.','ธ.ค.'],
    xaxis: {
      
    },
    yaxis: [
      {
        labels: {
          formatter: value => {
            return value.toFixed(0);
          }
        }
      }
    ]
    // yaxis: [
    //   {
    //     title: {
    //       text: 'กำลังพลที่ส่งไป',
    //     },
    //   }, 
    //   {
    //     opposite: true,
    //     title: {
    //       text: 'กำลังพลที่รับงานสำเร็จ'
    //     }
    // }]
  })

  useEffect(() => {
    const cleansingDataSent = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    const cleansingDataSuccess = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

    monthlyManpowerSentList.map((m) => {
      cleansingDataSent[m.month - 1] = m.count
    })

    monthlyManpowerSuccessList.map((m) => {
      cleansingDataSuccess[m.month - 1] = m.count
    })

    setSeries([
      {
        name: 'กำลังพลที่ส่งไป',
        type: 'line',
        data: cleansingDataSent
      },
      {
        name: 'กำลังพลที่รับงานสำเร็จ',
        type: 'line',
        data: cleansingDataSuccess
      }
    ])
    // setSeries([
    //   {
    //     name: 'กำลังพล',
    //     type: 'line',
    //     data: cleansingData
    //   }
    // ])

  }, [monthlyManpowerSentList, monthlyManpowerSuccessList])


  return(
    <Chart options={options} series={series} type="line" height={350}/>
  )
}

export default GraphManpowerSentSuccess;