import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Themplates from "../../shared/theme";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Divider,
  MenuItem,
  Button,
  Icon,
  IconButton,
  Chip,
  Checkbox,
  ListItemText,
  FormHelperText,
} from "@mui/material";
import "./index.css";

import ButtonBlue from "../../shared/general/ButtonBlue";
import NumberFormatTheme from "../../shared/general/NumberFormatTheme";
import {
  getLevelGroup,
  addRights,
  getAllRights,
  deleteRight,
} from "../../../../actions/rightsManage";

import { getEmploymentTypeByidCompany } from "../../../../actions/employment-type";
import AccordionRow from "./accordionRow";
import { Link } from "react-router-dom";
import Notification from "../../shared/general/Notification";
import AlertResponse from "../../shared/general/AlertResponse";
import DialogConfirmDelete from "../../shared/general/DialogConfirmDelete";
import InsuranceRights from "./InsuranceRights";
import { useTranslation } from "react-i18next";

const theme = createTheme(Themplates);

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "rgb(3 0 71 / 9%) 0px 1px 3px",
    padding: "1.75rem",
  },
  catPaper: {
    height: "30px",
    padding: "40px",
    borderRight: "50%",
  },
  typography: {
    fontSize: "24px",
    marginBottom: "24px",
    fontWeight: "400",
  },
  placeholder: {
    width: "100%",
    height: "100%",
    display: "flex",
    address: "absolute",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "rgb(99, 115, 129)",
    backgroundColor: "rgb(244, 246, 248)",
    transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  placeholderImageProfile: {
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgba(22, 28, 36, .50)",
  },
  placeholderLabel: {
    color: "rgb(255, 255, 255)",
  },
}));

const RightsManage = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { result: AllRights } = useSelector((state) => state.allRights);

  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: null,
  });

  const [deleteConfig, setDeleteConfig] = useState({
    isOpen: false,
    data: {},
  });

  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    type: "success",
    message: "",
  });

  const handleOpenDrawer = () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: true,
      isEdit: false,
      data: {},
    }));
  };

  const handleCloseDrawer = () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleCloseDeleteModal = () => {
    setDeleteConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleDeleteRights = async () => {
    const response = await dispatch(deleteRight(deleteConfig.data));

    if (response) {
      if (response.status === 200) {
        setAlertConfig((prev) => ({
          ...prev,
          isOpen: true,
          type: "success",
          message: t("DeletedSuccessfully"),
        }));
        dispatch(getAllRights());
        handleCloseDeleteModal();
      } else {
        setAlertConfig((prev) => ({
          ...prev,
          isOpen: true,
          type: "error",
          message: t("CantDelete"),
        }));
      }
    } else {
      setAlertConfig((prev) => ({
        ...prev,
        isOpen: true,
        type: "error",
        message: `${t("AnErrorOccurred")} ${t("ContactAdmin")}`,
      }));
    }

    setDeleteConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleSubmitRights = async (result) => {
    handleCloseDrawer();
    if (result) {
      handleOpenAlert();
      if (result.status === 200) {
        dispatch(getAllRights());
        handleChangeAlertType("success");
      } else {
        handleChangeAlertType("error");
      }
    } else {
      handleChangeAlertType("error");
    }
  };

  useEffect(() => {
    dispatch(getAllRights());
    dispatch(getEmploymentTypeByidCompany());
  }, []);

  return (
    <div className={`page`}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth="lg" style={{ padding: "20px 20px" }}>
            {!drawerConfig.isOpen && (
              <Grid
                item
                xs={12}
                marginTop={3}
                marginBottom={3}
                sm={12}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography variant="h4" component="div" gutterBottom>
                  {t("EmployeeBenefits")}
                </Typography>
                {/* <Link
                                    to="/admin/manage/InsuranceRights"
                                > */}
                <ButtonBlue
                  variant="contained"
                  onClick={() => {
                    handleOpenDrawer();
                  }}
                >
                  <Typography>{t("AddBenefits")}</Typography>
                </ButtonBlue>
                {/* </Link> */}
              </Grid>
            )}
            {!drawerConfig.isOpen &&
              AllRights &&
              AllRights.data &&
              AllRights.data.map((item, index) => (
                <AccordionRow
                  data={item}
                  key={`accordion_${index}`}
                  setDrawerConfig={setDrawerConfig}
                  setDeleteConfig={setDeleteConfig}
                />
              ))}
            {drawerConfig.isOpen && (
              <InsuranceRights
                drawerConfig={drawerConfig}
                onClose={handleCloseDrawer}
                setDrawerConfig={setDrawerConfig}
                setAlertType={setAlertType}
                setOpenAlert={setOpenAlert}
                handleSubmitRights={handleSubmitRights}
              />
            )}
            <Notification notify={alertConfig} setNotify={setAlertConfig} />

            <AlertResponse
              open={openAlert}
              handleClose={handleCloseAlert}
              alertType={alertType}
            />
            <DialogConfirmDelete
              open={deleteConfig.isOpen}
              handleClose={handleCloseDeleteModal}
              label={t("EmployeeBenefits")}
              text={deleteConfig.data.rightsName}
              handleDelete={handleDeleteRights}
            />
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
export default RightsManage;
