import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  styled,
  Card,
  Button,
  Tabs,
  Tab,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Grid,
  Dialog,
  Autocomplete,
  Avatar,
  TextField,
  Chip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrganizationObjectives,
  getTeamObjective,
  getAllIndividualObjectiveByIdEmp,
  getAllIndividualObjectiveForAllEmployees,
} from "../../../../../actions/okrs";
import { getAllEmployees } from "../../../../../actions/employee";
import ObjectiveCard from "../../../admin/okrs/OKRManagementForAdmin/components/ObjectiveCard";
import exportToExcel from "../../../admin/okrs/OKRManagementForAdmin/components/exportToExcel";
import AddIcon from "@mui/icons-material/Add";
import PersonIcon from "@mui/icons-material/Person";
import TeamPlanningAddFormPage from "../../../user/okrs/Planning/Team/addForm";
import IndividualPlanningAddFormPage from "../../../user/okrs/IndividualPlanning/addForm";
import EmployeeCard from "./EmployeeCard";
import EvaluationCard from "../../../user/okrs/OKRManagementForUser/EvaluationCard";

const StyledRoot = styled(Box)({
  backgroundColor: "#ffffff !important",
  paddingBottom: "150px",
});

const StyledObjectiveCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  flexGrow: 1,
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
  borderRadius: 20,
  padding: "24px",
  background: `linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))`,
  "& .cardTitle": {
    fontSize: 22,
    fontWeight: "bold",
    textAlign: "center",
    wordWrap: "break-word",
  },
  "& .percentage": {
    fontSize: 48,
    fontWeight: "bold",
    textAlign: "center",
  },
});

const StyledTeamObjectiveCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  flexGrow: 1,
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
  borderRadius: 20,
  padding: "24px",
  background: `linear-gradient(to right, rgba(144, 238, 144, 0.5), rgba(60, 179, 113, 0.5))`,
  "& .cardTitle": {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    wordWrap: "break-word",
  },
  "& .percentage": {
    fontSize: 40,
    fontWeight: "bold",
    textAlign: "center",
  },
});

const StyledPersonalObjectiveCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  flexGrow: 1,
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  borderRadius: 20,
  padding: "24px",
  background: `linear-gradient(to right, rgba(135, 206, 250, 0.5), rgba(70, 130, 180, 0.5))`,
  "& .cardTitle": {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    wordWrap: "break-word",
  },
  "& .percentage": {
    fontSize: 36,
    fontWeight: "bold",
    textAlign: "center",
  },
});

const ResponsiveButton = styled(Button)(({ theme }) => ({
  backgroundColor: "rgba(219, 65, 120, 1)",
  fontSize: "14px",
  fontWeight: "bold",
  borderRadius: "7px",
  padding: "10px 20px",
  color: "#ffffff",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "#C53B6C",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
}));

const StyledReadOnlyChip = styled(Chip)({
  backgroundColor: "#d32f2f",
  color: "#ffffff",
  fontWeight: "bold",
  borderRadius: "5px",
  marginRight: "8px",
});

const StyledChip = styled(Chip)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "bold",
  padding: theme.spacing(1),
  borderRadius: "8px",
  backgroundColor: "#DB4178",
  color: "#fff",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
}));

const ManagerDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [selectYear, setSelectYear] = useState(dayjs().get("year"));
  const [selectedCompany, setSelectedCompany] = useState("");
  const [objectiveTab, setObjectiveTab] = useState(0);
  const [approveTab, setApproveTab] = useState(0);
  const [showAddForm, setShowAddForm] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [selectedDivision, setSelectedDivision] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectQuarter, setSelectQuarter] = useState("");

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const isManager = userProfile && userProfile.roles.includes("ROLE_MANAGER");
  const { result: organizationObjectives } = useSelector(
    (state) => state.organizationObjective
  );
  const [teamObjectives, setTeamObjectives] = useState([]);
  const { result: employeesList } = useSelector((state) => state.employees);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [personalObjectives, setPersonalObjectives] = useState([]);
  const [cascadedOrganizationObjectives, setCascadedOrganizationObjectives] =
    useState([]);
  const [managerObjectives, setManagerObjectives] = useState([]);
  const [objectivesUpdated, setObjectivesUpdated] = useState(false);
  const [evaluateTeamObjectives, setEvaluateTeamObjectives] = useState([]);
  const [evaluateIndividualObjectives, setEvaluateIndividualObjectives] =
    useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");

  const filterOptions = [];

  if (userProfile) {
    if (userProfile.divisionName != null) {
      filterOptions.push({ type: "ฝ่าย", name: userProfile.divisionName });
    }

    if (userProfile.departmentName != null) {
      filterOptions.push({ type: "ส่วน", name: userProfile.departmentName });
    }

    if (userProfile.sectionName != null) {
      filterOptions.push({ type: "แผนก", name: userProfile.sectionName });
    }
  }

  useEffect(() => {
    if (activeTab === 0) {
      dispatch(getOrganizationObjectives());
      dispatch(getTeamObjective()).then((res) => {
        const today = dayjs().startOf("day");
        const objectivesForEvaluation = res.data
          .map((objective) => {
            const keyResultsForEvaluation = objective.keyResults.filter(
              (kr) => {
                const isSuccessNull = kr.isSuccess == null;
                const endDatePassed = dayjs(kr.endDate)
                  .startOf("day")
                  .isSameOrBefore(today);
                const progressComplete = kr.progressPercent === 100;

                return isSuccessNull && (endDatePassed || progressComplete);
              }
            );
            if (keyResultsForEvaluation.length > 0) {
              return {
                ...objective,
                keyResults: keyResultsForEvaluation,
              };
            } else {
              return null;
            }
          })
          .filter((objective) => objective !== null);

        setEvaluateTeamObjectives(objectivesForEvaluation);
        if (res.status === 200) {
          setTeamObjectives(res.data);
        }
      });
      dispatch(getAllEmployees("manager"));
    }
  }, [dispatch, activeTab, userProfile]);

  useEffect(() => {
    if (activeTab === 1) {
      dispatch(getTeamObjective()).then((res) => {
        if (res.status === 200) {
          setTeamObjectives(res.data);
        }
      });
    }
  }, [dispatch, activeTab, objectivesUpdated]);

  useEffect(() => {
    if (
      (activeTab === 2 ||
        activeTab === 5 ||
        (activeTab === 0 && objectiveTab === 0)) &&
      userProfile
    ) {
      dispatch(getAllIndividualObjectiveByIdEmp(userProfile.idEmployees))
        .then((res) => {
          const today = dayjs().startOf("day");
          const objectivesForEvaluation = res.data.individualObjectives
            .map((objective) => {
              const keyResultsForEvaluation = objective.keyResults.filter(
                (kr) => {
                  const isSuccessNull = kr.isSuccess == null;
                  const endDatePassed = dayjs(kr.endDate)
                    .startOf("day")
                    .isSameOrBefore(today);
                  const progressComplete = kr.progressPercent === 100;

                  return isSuccessNull && (endDatePassed || progressComplete);
                }
              );
              if (keyResultsForEvaluation.length > 0) {
                return {
                  ...objective,
                  keyResults: keyResultsForEvaluation,
                };
              } else {
                return null;
              }
            })
            .filter((objective) => objective !== null);

          setEvaluateIndividualObjectives(objectivesForEvaluation);
          if (res.status === 200) {
            setManagerObjectives(res.data.individualObjectives);
            setCascadedOrganizationObjectives(res.data.organizationObjectives);
          }
        })
        .catch((error) => {
          console.error("Error fetching individual objectives:", error);
        });
    }
  }, [activeTab, objectiveTab, userProfile, dispatch, objectivesUpdated]);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleChangeObjectiveTab = (event, newValue) => {
    setObjectiveTab(newValue);
    setFilterType("");
    setSelectedDepartment("");
    setSelectedDivision("");
  };

  const handleChangeApproveTab = (event, newValue) => {
    setApproveTab(newValue);
  };

  const handleChangeYear = (event) => {
    setSelectYear(event.target.value);
  };

  const handleExportClick = () => {
    if (objectiveTab === 0) {
      exportToExcel(organizationObjectives, t);
    } else if (objectiveTab === 1) {
      exportToExcel(teamObjectives, t);
    } else if (objectiveTab === 2) {
      exportToExcel(personalObjectives, t);
    } else if (objectiveTab === 3) {
      exportToExcel(managerObjectives, t);
    }
  };

  const handleChangeEmployee = (event, newValue) => {
    if (newValue) {
      setSelectedEmployee(newValue.idEmployees);
      dispatch(getAllIndividualObjectiveByIdEmp(newValue.idEmployees)).then(
        (res) => {
          if (res.status === 200) {
            setPersonalObjectives(res.data.individualObjectives);
            setCascadedOrganizationObjectives(res.data.organizationObjectives);
          }
        }
      );
    } else {
      setSelectedEmployee("");
      setPersonalObjectives([]);
    }
  };

  const handleObjectiveUpdated = () => {
    setObjectivesUpdated((prev) => !prev);
  };

  let averageRating = "N/A";
  let totalScore = "N/A";

  if (activeTab === 2) {
    const filteredObjectives = managerObjectives
      .filter((objective) => {
        if (objective.year !== selectYear) return false;
        if (selectQuarter && objective.quarter !== Number(selectQuarter))
          return false;
        return true;
      })
      .sort((a, b) => {
        const dateA = a.latestUpdateDate
          ? new Date(a.latestUpdateDate)
          : new Date(0);
        const dateB = b.latestUpdateDate
          ? new Date(b.latestUpdateDate)
          : new Date(0);
        return dateB - dateA;
      });

    let totalObjectiveRating = 0;
    let numOfObjectives = 0;

    filteredObjectives.forEach((objective) => {
      const achievedKRs = objective.keyResults.filter(
        (kr) => kr.isAchieve === 1 && kr.managerRating != null
      );

      if (achievedKRs.length > 0) {
        const sumManagerRating = achievedKRs.reduce((sum, kr) => {
          return sum + kr.employeeRating;
        }, 0);

        const objectiveRating = sumManagerRating / achievedKRs.length;

        totalObjectiveRating += objectiveRating;
        numOfObjectives += 1;
      }
    });

    if (numOfObjectives > 0) {
      totalScore = totalObjectiveRating.toFixed(2);
      averageRating = (totalObjectiveRating / numOfObjectives).toFixed(2);
    } else {
      totalScore = "N/A";
      averageRating = "N/A";
    }
  }
  if (activeTab === 1) {
    const filteredObjectives = teamObjectives
      .filter((objective) => {
        if (objective.year !== selectYear) return false;
        if (selectQuarter && objective.quarter !== Number(selectQuarter))
          return false;
        return true;
      })
      .sort((a, b) => {
        const dateA = a.latestUpdateDate
          ? new Date(a.latestUpdateDate)
          : new Date(0);
        const dateB = b.latestUpdateDate
          ? new Date(b.latestUpdateDate)
          : new Date(0);
        return dateB - dateA;
      });

    let totalObjectiveRating = 0;
    let numOfObjectives = 0;

    filteredObjectives.forEach((objective) => {
      const achievedKRs = objective.keyResults.filter(
        (kr) => kr.isAchieve === 1 && kr.rating != null
      );

      if (achievedKRs.length > 0) {
        const sumManagerRating = achievedKRs.reduce((sum, kr) => {
          return sum + kr.rating;
        }, 0);

        const objectiveRating = sumManagerRating / achievedKRs.length;

        totalObjectiveRating += objectiveRating;
        numOfObjectives += 1;
      }
    });

    if (numOfObjectives > 0) {
      totalScore = totalObjectiveRating.toFixed(2);
      averageRating = (totalObjectiveRating / numOfObjectives).toFixed(2);
    } else {
      totalScore = "N/A";
      averageRating = "N/A";
    }
  }

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Box
          className="header-box"
          sx={{
            padding: "16px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            background:
              "linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))",
            borderRadius: "16px",
          }}
        >
          <Typography
            className="header-text"
            sx={{ fontSize: "48px", fontWeight: "bold" }}
          >
            OKR Management
          </Typography>
        </Box>

        <Box marginTop={2} marginBottom={4} sx={{ borderBottom: "2px" }}>
          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
          >
            <Tab label={t("Home")}></Tab>
            <Tab label={t("UpdateTeamGoals")}></Tab>
            <Tab label={t("UpdateMyGoals")}></Tab>
            <Tab label={t("EvaluateEmp")}></Tab>
            <Tab label={t("EvaluateTeam")}></Tab>
            <Tab label={t("EvaluateSelf")}></Tab>
          </Tabs>
        </Box>

        {activeTab === 0 && (
          <>
            <Box
              display="flex"
              alignItems="center"
              marginBottom={2}
              padding="16px"
              borderRadius="20px"
              backgroundColor="#ffffff"
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                gap={1}
                mb={2}
              >
                <Typography variant="h6" gutterBottom>
                  {t("OrganizationalGoals")}
                </Typography>
                <Typography variant="h5" fontWeight="bold">
                  {userProfile ? userProfile.companyName : ""}
                </Typography>
              </Box>

              <FormControl
                className="filter-year"
                sx={{ marginLeft: "24px", minWidth: 150 }}
              >
                <InputLabel>{t("SelectYear")}</InputLabel>
                <Select
                  label={t("SelectYear")}
                  value={selectYear}
                  onChange={handleChangeYear}
                  sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
                >
                  {[0, 1, 2].map((item) => (
                    <MenuItem key={item} value={dayjs().get("year") - item}>
                      {dayjs().get("year") - item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Grid container spacing={3} marginBottom={2} alignItems="stretch">
              {organizationObjectives &&
                organizationObjectives
                  .filter((objective) => objective.year === selectYear)
                  .sort(
                    (a, b) => new Date(b.updateDate) - new Date(a.updateDate)
                  )
                  .slice(0, 3)
                  .map((objective) => (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      key={objective.idOrganizationObjective}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <StyledObjectiveCard>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Typography
                            className="percentage"
                            style={{ color: "#DB4178" }}
                          >
                            {`${objective.progressPercent}%`}
                          </Typography>
                          <Typography className="cardTitle" align="center">
                            {objective.objectiveName}
                          </Typography>
                        </Box>
                      </StyledObjectiveCard>
                    </Grid>
                  ))}
            </Grid>

            <Box
              display="flex"
              alignItems="center"
              marginBottom={0}
              padding="8px"
              borderRadius="20px"
              backgroundColor="#ffffff"
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                gap={1}
                mb={2}
              >
                <Typography variant="h6" gutterBottom>
                  {t("TeamGoals")}
                </Typography>
                <Typography variant="h6" gutterBottom></Typography>
              </Box>
            </Box>

            <Grid container spacing={3} marginBottom={2} alignItems="stretch">
              {teamObjectives &&
                teamObjectives

                  .filter((objective) => objective.year === selectYear)

                  .sort(
                    (a, b) => new Date(b.updateDate) - new Date(a.updateDate)
                  )
                  .slice(0, 3)
                  .map((objective) => (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      key={objective.idTeamObjective}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <StyledTeamObjectiveCard>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Typography
                            className="percentage"
                            style={{ color: "#3CB371" }}
                          >
                            {`${objective.progressPercent}%`}
                          </Typography>
                          <Typography className="cardTitle" align="center">
                            {objective.objectiveName}
                          </Typography>
                        </Box>
                      </StyledTeamObjectiveCard>
                    </Grid>
                  ))}
            </Grid>

            <Box
              display="flex"
              alignItems="center"
              marginBottom={0}
              padding="8px"
              borderRadius="20px"
              backgroundColor="#ffffff"
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                gap={1}
                mb={2}
              >
                <Typography variant="h6" gutterBottom>
                  {t("PersonalGoals(Manager)")}
                </Typography>
                <Typography variant="h6" gutterBottom></Typography>
              </Box>
            </Box>

            <Grid container spacing={3} marginBottom={2} alignItems="stretch">
              {managerObjectives &&
                managerObjectives
                  .filter((objective) => objective.year === selectYear)
                  .sort(
                    (a, b) => new Date(b.updateDate) - new Date(a.updateDate)
                  )
                  .slice(0, 3)
                  .map((objective) => (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      key={objective.idIndividualObjective}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <StyledPersonalObjectiveCard>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Typography
                            className="percentage"
                            style={{ color: "#4682B4" }}
                          >
                            {`${objective.progressPercent}%`}
                          </Typography>
                          <Typography className="cardTitle" align="center">
                            {objective.objectiveName}
                          </Typography>
                        </Box>
                      </StyledPersonalObjectiveCard>
                    </Grid>
                  ))}
            </Grid>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={4}
              marginTop={8}
            >
              <Tabs
                value={objectiveTab}
                onChange={handleChangeObjectiveTab}
                indicatorColor="primary"
              >
                <Tab label={t("OrganizationalGoals")}></Tab>
                <Tab label={t("TeamGoals")}></Tab>
                <Tab label={t("PersonalGoals(Emp)")}></Tab>
                <Tab label={t("PersonalGoals(Manager)")}></Tab>
              </Tabs>

              {isManager && (objectiveTab === 1 || objectiveTab === 3) && (
                <ResponsiveButton onClick={() => setShowAddForm(true)}>
                  <AddIcon style={{ marginRight: "8px" }} />{" "}
                  {t("CreateObjective")}
                </ResponsiveButton>
              )}
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              marginBottom={4}
            >
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                gap={2}
                sx={{ flexGrow: 1, minWidth: "300px" }}
              >
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel shrink>{t("SelectQuarter")}</InputLabel>
                  <Select
                    label={t("SelectQuarter")}
                    value={selectQuarter || ""}
                    onChange={(e) => setSelectQuarter(e.target.value)}
                    sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected === "") {
                        return <span>{t("All")}</span>;
                      }
                      return t(`Q${selected}`);
                    }}
                  >
                    <MenuItem value="">{t("All")}</MenuItem>
                    <MenuItem value="1">{t("Q1")}</MenuItem>
                    <MenuItem value="2">{t("Q2")}</MenuItem>
                    <MenuItem value="3">{t("Q3")}</MenuItem>
                    <MenuItem value="4">{t("Q4")}</MenuItem>
                  </Select>
                </FormControl>

                {objectiveTab === 1 && (
                  <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel>{t("SelectTeam")}</InputLabel>
                    <Select
                      label={t("SelectTeam")}
                      value={selectedFilter}
                      onChange={(e) => setSelectedFilter(e.target.value)}
                      sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
                    >
                      <MenuItem value="">{t("All")}</MenuItem>
                      {filterOptions.map((option) => (
                        <MenuItem
                          key={`${option.type}:${option.name}`}
                          value={`${option.type}:${option.name}`}
                        >
                          {`${option.type}: ${option.name}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {objectiveTab === 2 && (
                  <Autocomplete
                    options={employeesList || []}
                    getOptionLabel={(option) =>
                      option
                        ? `${option.firstname_TH} ${option.lastname_TH}`
                        : ""
                    }
                    value={
                      selectedEmployee && employeesList
                        ? employeesList.find(
                            (emp) => emp.idEmployees === selectedEmployee
                          ) || null
                        : null
                    }
                    onChange={handleChangeEmployee}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("SelectEmp")}
                        placeholder={t("SearchEmp")}
                        variant="outlined"
                        sx={{
                          width: 300,
                          borderRadius: "10px",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                          },
                        }}
                      />
                    )}
                    sx={{
                      width: 300,
                      borderRadius: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.idEmployees === value.idEmployees
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        {...props}
                        display="flex"
                        alignItems="center"
                        gap={1}
                      >
                        {option.imageProfile ? (
                          <img
                            src={option.imageProfile}
                            alt={option.firstname_TH}
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <PersonIcon style={{ fontSize: 30 }} />
                        )}
                        {`${option.firstname_TH} ${option.lastname_TH}`}
                      </Box>
                    )}
                  />
                )}
              </Box>

              <Box display="flex" alignItems="center">
                {(objectiveTab === 0 || objectiveTab === 2) && (
                  <StyledReadOnlyChip label="ReadOnly" />
                )}
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: "rgba(219, 65, 120, 1)",
                    color: "rgba(219, 65, 120, 1)",
                    fontWeight: "bold",
                    "&:hover": {
                      borderColor: "#C53B6C",
                      color: "#C53B6C",
                    },
                  }}
                  onClick={handleExportClick}
                >
                  {t("ExportToExcel")}
                </Button>
              </Box>
            </Box>

            {objectiveTab === 0 && (
              <Grid container spacing={3}>
                {(organizationObjectives || [])
                  .filter((objective) => {
                    if (objective.year !== selectYear) return false;
                    if (
                      selectedCompany &&
                      objective.idCompany !== Number(selectedCompany)
                    )
                      return false;
                    if (
                      selectQuarter &&
                      objective.quarter !== Number(selectQuarter)
                    )
                      return false;
                    return true;
                  })
                  .sort((a, b) => {
                    const dateA = a.latestUpdateDate
                      ? dayjs(a.latestUpdateDate)
                      : dayjs(0);
                    const dateB = b.latestUpdateDate
                      ? dayjs(b.latestUpdateDate)
                      : dayjs(0);
                    return dateB.diff(dateA);
                  })
                  .map((objective) => (
                    <Grid item xs={12} key={objective.idOrganizationObjective}>
                      <ObjectiveCard objective={objective} />
                    </Grid>
                  ))}
              </Grid>
            )}

            {objectiveTab === 1 && (
              <Grid container spacing={3}>
                {(teamObjectives || [])
                  .filter((objective) => {
                    if (objective.year !== selectYear) return false;
                    if (
                      selectedCompany &&
                      objective.idCompany !== Number(selectedCompany)
                    )
                      return false;
                    if (
                      selectQuarter &&
                      objective.quarter !== Number(selectQuarter)
                    )
                      return false;

                    if (selectedFilter) {
                      const [filterType, filterName] =
                        selectedFilter.split(":");

                      if (filterType === "ฝ่าย") {
                        if (objective.idDivision == null) return false;
                      }

                      if (filterType === "ส่วน") {
                        if (objective.idDepartment == null) return false;
                      }

                      if (filterType === "แผนก") {
                        if (objective.idSection == null) return false;
                      }
                    }

                    return true;
                  })
                  .sort((a, b) => {
                    const dateA = a.latestUpdateDate
                      ? dayjs(a.latestUpdateDate)
                      : dayjs(0);
                    const dateB = b.latestUpdateDate
                      ? dayjs(b.latestUpdateDate)
                      : dayjs(0);
                    return dateB.diff(dateA);
                  })
                  .map((objective) => (
                    <Grid item xs={12} key={objective.idTeamObjective}>
                      <ObjectiveCard
                        objective={objective}
                        isEditable={activeTab === 1}
                        activeTab={activeTab}
                        onObjectiveUpdated={handleObjectiveUpdated}
                      />
                    </Grid>
                  ))}
              </Grid>
            )}

            {objectiveTab === 2 && (
              <>
                {selectedEmployee ? (
                  <Grid container spacing={3}>
                    {cascadedOrganizationObjectives
                      .filter(
                        (objective) =>
                          objective.year === selectYear &&
                          (selectQuarter === "" ||
                            objective.quarter === Number(selectQuarter))
                      )
                      .sort((a, b) => {
                        const dateA = a.latestUpdateDate
                          ? dayjs(a.latestUpdateDate)
                          : dayjs(0);
                        const dateB = b.latestUpdateDate
                          ? dayjs(b.latestUpdateDate)
                          : dayjs(0);
                        return dateB.diff(dateA);
                      })
                      .map((objective) => (
                        <Grid
                          item
                          xs={12}
                          key={objective.idIndividualObjective}
                        >
                          <ObjectiveCard objective={objective} />
                        </Grid>
                      ))}
                    {personalObjectives
                      .filter(
                        (objective) =>
                          objective.idEmployees === selectedEmployee &&
                          objective.year === selectYear &&
                          (selectQuarter === "" ||
                            objective.quarter === Number(selectQuarter))
                      )
                      .sort((a, b) => {
                        const dateA = a.latestUpdateDate
                          ? dayjs(a.latestUpdateDate)
                          : dayjs(0);
                        const dateB = b.latestUpdateDate
                          ? dayjs(b.latestUpdateDate)
                          : dayjs(0);
                        return dateB.diff(dateA);
                      })
                      .map((objective) => (
                        <Grid
                          item
                          xs={12}
                          key={objective.idIndividualObjective}
                        >
                          <ObjectiveCard objective={objective} />
                        </Grid>
                      ))}
                  </Grid>
                ) : (
                  <Typography variant="h6" align="center">
                    {t("กรุณาเลือกพนักงานเพื่อดูเป้าหมาย")}
                  </Typography>
                )}
              </>
            )}

            {objectiveTab === 3 && (
              <Grid container spacing={3}>
                {(cascadedOrganizationObjectives || [])
                  .filter((objective) => {
                    if (objective.year !== selectYear) return false;
                    if (
                      selectedCompany &&
                      objective.idCompany !== Number(selectedCompany)
                    )
                      return false;
                    if (
                      selectQuarter &&
                      objective.quarter !== Number(selectQuarter)
                    )
                      return false;
                    return true;
                  })
                  .sort((a, b) => {
                    const dateA = a.latestUpdateDate
                      ? dayjs(a.latestUpdateDate)
                      : dayjs(0);
                    const dateB = b.latestUpdateDate
                      ? dayjs(b.latestUpdateDate)
                      : dayjs(0);
                    return dateB.diff(dateA);
                  })
                  .map((objective) => (
                    <Grid item xs={12} key={objective.idObjective}>
                      <ObjectiveCard
                        objective={objective}
                        //isEditable={activeTab === 3}
                        //onObjectiveUpdated={handleObjectiveUpdated}
                        //activeTab={activeTab}
                      />
                    </Grid>
                  ))}
                {(managerObjectives || [])
                  .filter((objective) => {
                    if (objective.year !== selectYear) return false;
                    if (
                      selectedCompany &&
                      objective.idCompany !== Number(selectedCompany)
                    )
                      return false;
                    if (
                      selectQuarter &&
                      objective.quarter !== Number(selectQuarter)
                    )
                      return false;
                    return true;
                  })
                  .sort((a, b) => {
                    const dateA = a.latestUpdateDate
                      ? dayjs(a.latestUpdateDate)
                      : dayjs(0);
                    const dateB = b.latestUpdateDate
                      ? dayjs(b.latestUpdateDate)
                      : dayjs(0);
                    return dateB.diff(dateA);
                  })
                  .map((objective) => (
                    <Grid item xs={12} key={objective.idObjective}>
                      <ObjectiveCard
                        objective={objective}
                        //isEditable={activeTab === 3}
                        //onObjectiveUpdated={handleObjectiveUpdated}
                        //activeTab={activeTab}
                      />
                    </Grid>
                  ))}
              </Grid>
            )}
          </>
        )}

        {activeTab === 1 && (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              marginBottom={4}
            >
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                gap={2}
                sx={{ flexGrow: 1, minWidth: "300px" }}
              >
                <FormControl sx={{ minWidth: 150 }}>
                  <InputLabel>{t("SelectYear")}</InputLabel>
                  <Select
                    label={t("SelectYear")}
                    value={selectYear}
                    onChange={handleChangeYear}
                    sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
                  >
                    {[0, 1, 2].map((item) => (
                      <MenuItem key={item} value={dayjs().year() - item}>
                        {dayjs().year() - item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ minWidth: 150 }}>
                  <InputLabel shrink>{t("SelectQuarter")}</InputLabel>
                  <Select
                    label={t("SelectQuarter")}
                    value={selectQuarter || ""}
                    onChange={(e) => setSelectQuarter(e.target.value)}
                    sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected === "") {
                        return <span>{t("All")}</span>;
                      }
                      return t(`Q${selected}`);
                    }}
                  >
                    <MenuItem value="">{t("All")}</MenuItem>
                    <MenuItem value="1">{t("Q1")}</MenuItem>
                    <MenuItem value="2">{t("Q2")}</MenuItem>
                    <MenuItem value="3">{t("Q3")}</MenuItem>
                    <MenuItem value="4">{t("Q4")}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                <StyledChip label={`${t("AverageScore")}: ${averageRating}`} />
                <StyledChip label={`${t("TotalScore")}: ${totalScore}`} />
              </Box>
            </Box>

            {teamObjectives &&
              teamObjectives
                .filter((objective) => {
                  if (objective.year !== selectYear) return false;
                  if (
                    selectedCompany &&
                    objective.idCompany !== Number(selectedCompany)
                  )
                    return false;
                  if (
                    selectQuarter &&
                    objective.quarter !== Number(selectQuarter)
                  )
                    return false;
                  return true;
                })
                .map((objective) => (
                  <Grid item xs={12} md={4} key={objective.idTeamObjective}>
                    <ObjectiveCard
                      objective={objective}
                      isEditable={activeTab === 1}
                      onObjectiveUpdated={handleObjectiveUpdated}
                      activeTab={activeTab}
                    />
                  </Grid>
                ))}
          </>
        )}

        {activeTab === 3 && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={4}
            >
              <Tabs
                value={approveTab}
                onChange={handleChangeApproveTab}
                indicatorColor="primary"
              >
                <Tab label={t("approvePlanTab")}></Tab>
                <Tab label={t("approveSuccessTab")}></Tab>
              </Tabs>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              marginBottom={4}
            >
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                gap={2}
                sx={{ flexGrow: 1, minWidth: "300px" }}
              >
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel shrink>{t("SelectQuarter")}</InputLabel>
                  <Select
                    label={t("SelectQuarter")}
                    value={selectQuarter || ""}
                    onChange={(e) => setSelectQuarter(e.target.value)}
                    sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected === "") {
                        return <span>{t("All")}</span>;
                      }
                      return t(`Q${selected}`);
                    }}
                  >
                    <MenuItem value="">{t("All")}</MenuItem>
                    <MenuItem value="1">{t("Q1")}</MenuItem>
                    <MenuItem value="2">{t("Q2")}</MenuItem>
                    <MenuItem value="3">{t("Q3")}</MenuItem>
                    <MenuItem value="4">{t("Q4")}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>

            {approveTab === 0 &&
              employeesList.some(
                (employee) => employee.WaitApproveOKRCount > 0
              ) && (
                <>
                  <Grid container spacing={3}>
                    {employeesList &&
                      employeesList
                        .filter((employee) =>
                          `${employee.firstname_TH} ${employee.lastname_TH}`
                            .toLowerCase()
                            .includes("")
                        )
                        .map((employee) => (
                          <Grid item xs={12} key={employee.idEmployees}>
                            <EmployeeCard
                              employee={employee}
                              idManager={userProfile.idEmployees}
                              approveTab={approveTab}
                            />
                          </Grid>
                        ))}
                  </Grid>
                </>
              )}

            {approveTab === 1 &&
              employeesList.some(
                (employee) => employee.WaitApproveResultCount > 0
              ) && (
                <>
                  <Grid container spacing={3}>
                    {employeesList &&
                      employeesList
                        .filter((employee) =>
                          `${employee.firstname_TH} ${employee.lastname_TH}`
                            .toLowerCase()
                            .includes("")
                        )
                        .map((employee) => (
                          <Grid
                            item
                            xs={12}
                            key={employee.idEmployees}
                            style={{ marginBottom: "32px" }}
                          >
                            <EmployeeCard
                              employee={employee}
                              idManager={userProfile.idEmployees}
                              approveTab={approveTab}
                            />
                          </Grid>
                        ))}
                  </Grid>
                </>
              )}
          </>
        )}

        {activeTab === 2 && (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              marginBottom={4}
            >
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                gap={2}
                sx={{ flexGrow: 1, minWidth: "300px" }}
              >
                {/* Quarter Filter */}
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel shrink>{t("SelectQuarter")}</InputLabel>
                  <Select
                    label={t("SelectQuarter")}
                    value={selectQuarter || ""}
                    onChange={(e) => setSelectQuarter(e.target.value)}
                    sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected === "") {
                        return <span>{t("All")}</span>;
                      }
                      return t(`Q${selected}`);
                    }}
                  >
                    <MenuItem value="">{t("All")}</MenuItem>
                    <MenuItem value="1">{t("Q1")}</MenuItem>
                    <MenuItem value="2">{t("Q2")}</MenuItem>
                    <MenuItem value="3">{t("Q3")}</MenuItem>
                    <MenuItem value="4">{t("Q4")}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                <StyledChip label={`${t("AverageScore")}: ${averageRating}`} />
                <StyledChip label={`${t("TotalScore")}: ${totalScore}`} />
              </Box>
            </Box>

            <Grid container spacing={3}>
              {(managerObjectives || [])
                .filter((objective) => {
                  if (objective.year !== selectYear) return false;
                  if (
                    selectedCompany &&
                    objective.idCompany !== Number(selectedCompany)
                  )
                    return false;
                  if (
                    selectQuarter &&
                    objective.quarter !== Number(selectQuarter)
                  )
                    return false;
                  return true;
                })
                .sort((a, b) => {
                  const dateA = a.latestUpdateDate
                    ? dayjs(a.latestUpdateDate)
                    : dayjs(0);
                  const dateB = b.latestUpdateDate
                    ? dayjs(b.latestUpdateDate)
                    : dayjs(0);
                  return dateB.diff(dateA);
                })
                .map((objective) => (
                  <Grid item xs={12} key={objective.idObjective}>
                    <ObjectiveCard
                      objective={objective}
                      isEditable={activeTab === 2}
                      onObjectiveUpdated={handleObjectiveUpdated}
                      activeTab={activeTab}
                    />
                  </Grid>
                ))}
            </Grid>
          </>
        )}

        {activeTab === 4 && (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              marginBottom={4}
            >
              <Typography variant="h6">
                {t("Evaluate Team Key Results")}
              </Typography>
            </Box>

            <EvaluationCard
              objectives={evaluateTeamObjectives}
              onEvaluationSubmitted={handleObjectiveUpdated}
            />
          </>
        )}

        {activeTab === 5 && (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              marginBottom={4}
            >
              <Typography variant="h6">
                {t("Evaluate Individual Key Results")}
              </Typography>
            </Box>

            <EvaluationCard
              objectives={evaluateIndividualObjectives}
              onEvaluationSubmitted={handleObjectiveUpdated}
            />
          </>
        )}

        {showAddForm && (
          <Dialog
            open={showAddForm}
            onClose={() => setShowAddForm(false)}
            fullWidth
            maxWidth="lg"
          >
            {objectiveTab === 3 ? (
              <IndividualPlanningAddFormPage />
            ) : (
              <TeamPlanningAddFormPage />
            )}
          </Dialog>
        )}
      </Container>
    </StyledRoot>
  );
};

export default ManagerDashboard;
