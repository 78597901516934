import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from "@pdf-lib/fontkit";
import dayjs from 'dayjs';
import 'dayjs/locale/th';

export const PND1PdfFile = async (userProfile, type, selectedYear, selectedMonth, employeeDetails, imageData) => {

    const url = `${process.env.REACT_APP_API_URL}files/${type}.pdf`;
    const url2 = `${process.env.REACT_APP_API_URL}files/PND1_personal.pdf`;

    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const existingPdfBytes2 = await fetch(url2).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pdfDoc2 = await PDFDocument.load(existingPdfBytes2);

    const mergedPdfDoc = await PDFDocument.create();

    const pages1 = await mergedPdfDoc.copyPages(pdfDoc, pdfDoc.getPageIndices());
    pages1.forEach((page) => mergedPdfDoc.addPage(page));

    const pages2 = await mergedPdfDoc.copyPages(pdfDoc2, pdfDoc2.getPageIndices());
    pages2.forEach((page) => mergedPdfDoc.addPage(page));

    const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
    const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
    mergedPdfDoc.registerFontkit(fontkit);

    const font = await mergedPdfDoc.embedFont(fontBytes);
    const pages = mergedPdfDoc.getPages();

    const firstPage = pages[0];

    const employeeData = employeeDetails && employeeDetails.employeeDetails;
    const taxData = employeeDetails && employeeDetails.totalData;

    const presentDay = new Date();
    const presentDayTH = dayjs(presentDay).locale('th').format("DD");
    const presentMonthTH = dayjs(presentDay).locale('th').format("MMMM");
    const thaiYear = dayjs(presentDay).format('YYYY')
    const formattedThaiYear = parseInt(thaiYear) + 543;
    const formattedThaiYear2 = parseInt(thaiYear) + 543;
    // const branchNumber = `0  0  0  0  0`;
    const branchNumber = employeeData && employeeData[0].branchCode ? (employeeData[0].branchCode).split("").join("  ") : "";

    if (employeeData[0].companyTaxId) {
        const taxID = `${employeeData[0].companyTaxId}`;

        const formattedtaxID1 = `${' ' + taxID[0]}`
        const formattedtaxID2 = `${taxID[1] + '  ' + taxID[2] + '  ' + taxID[3] + '  ' + taxID[4]}`
        const formattedtaxID3 = `${taxID[5] + '  ' + taxID[6] + '  ' + taxID[7] + '  ' + taxID[8] + '  ' + taxID[9]}`
        const formattedtaxID4 = `${taxID[10] + '  ' + taxID[11] + '   ' + taxID[12]} `

        firstPage.drawText(`${formattedtaxID1}`, {
            x: 160, y: 738, size: 14, font: font, color: rgb(0, 0, 0),
        });

        firstPage.drawText(`${formattedtaxID2}`, {
            x: 180, y: 738, size: 14, font: font, color: rgb(0, 0, 0),
        });

        firstPage.drawText(`${formattedtaxID3}`, {
            x: 230, y: 738, size: 14, font: font, color: rgb(0, 0, 0),
        });

        firstPage.drawText(`${formattedtaxID4}`, {
            x: 295, y: 738, size: 14, font: font, color: rgb(0, 0, 0),
        });
    }

    firstPage.drawText(`${branchNumber}`, {
        x: 277,
        y: 707,
        size: 15,
        font: font,
        color: rgb(0, 0, 0),
    });

    if (employeeData && employeeData[0].companyName) {
        firstPage.drawText(`${employeeData[0].companyName}`, {
            x: 100,
            y: 693,
            size: 16,
            font: font,
            color: rgb(0, 0, 0),
        });
    }

    firstPage.drawText(`${selectedYear ? parseInt(selectedYear) + 543 : ""}`, {
        x: 527,
        y: 719,
        size: 15,
        font: font,
        color: rgb(0, 0, 0),
    });

    if (employeeData && employeeData[0].addressCompany) {
        let address = employeeData[0].addressCompany.split(" ");
        let houseNo = address[0];
        let road = address[1];
        let subDistrict = address[2];

        firstPage.drawText(`${houseNo}`, {
            x: 85, y: 660, size: 14, font: font
        });

        firstPage.drawText(`${road}`, {
            x: 85, y: 645, size: 14, font: font
        });

        firstPage.drawText(`${subDistrict}`, {
            x: 240, y: 645, size: 14, font: font
        });
    }

    if (employeeData && employeeData[0].districtCompany) {
        firstPage.drawText(`${employeeData[0].districtCompany}`, {
            x: 95, y: 629, size: 14, font: font
        });
    }


    if (employeeData && employeeData[0].provinceCompany) {
        employeeData && employeeData[0].provinceCompany &&
            firstPage.drawText(`${employeeData[0].provinceCompany}`, {
                x: 240, y: 629, size: 14, font: font
            });
    }

    const areaCode = `${employeeData[0].areaCodeCompany}`;

    const formattedareaCode = `${areaCode[0] + '  ' + areaCode[1] + '  ' + areaCode[2] + '  ' + areaCode[3] + '  ' + areaCode[4]}`

    {
        employeeData && employeeData[0].areaCodeCompany &&
            firstPage.drawText(`${formattedareaCode} `, { x: 92, y: 612, size: 14, font: font, });
    }

    if (employeeDetails) {
        const fullname = employeeData[0].certificateSignatureName;
        const position = employeeData[0].certificateSignaturePosition;

        firstPage.drawText(`${employeeData.length}`, { x: 340, y: 420, size: 14, font: font });
        firstPage.drawText(`${taxData.totalEarnings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: 385, y: 420, size: 14, font: font });
        firstPage.drawText(`${taxData.totalTax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: 475, y: 420, size: 14, font: font });

        firstPage.drawText(`${employeeData.length}`, { x: 340, y: 279, size: 14, font: font });
        firstPage.drawText(`${taxData.totalEarnings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: 385, y: 279, size: 14, font: font });
        firstPage.drawText(`${taxData.totalTax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: 475, y: 279, size: 14, font: font });

        firstPage.drawText(`${taxData.totalTax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: 475, y: 242, size: 14, font: font });

        if (fullname) {
            firstPage.drawText(`${fullname}`, { x: 255, y: 144, size: 14, font: font }); // signature
        } else {
            firstPage.drawText("-", { x: 305, y: 145, size: 14, font: font }); // signature
        }

        if (position) {
            console.log(position.length)
            firstPage.drawText(`${position}`, { x: 250, y: 127, size: position.length > 30 ? 10 : (position.length > 20 ? 12 : 14), font: font });
        } else {
            firstPage.drawText("-", { x: 300, y: 128, size: 14, font: font });
        }

        if (imageData) {
            const pngImage = await mergedPdfDoc.embedPng(imageData);
            const pngDims = pngImage.scale(0.08);
            firstPage.drawImage(pngImage, {
                x: 280, y: 160, width: pngDims.width, height: pngDims.height
            });
        }

        firstPage.drawText(`${presentDayTH}`, { x: 244, y: 110, size: 13, font: font });
        firstPage.drawText(`${presentMonthTH}`, { x: 279, y: 110, size: 13, font: font });
        firstPage.drawText(`${formattedThaiYear}`, { x: 357, y: 110, size: 13, font: font });
    }

    const x = 80;
    const y = 410;
    let index = 1;
    const marginBottom = 18;

    const existingPdfBytes3 = await fetch(url2).then((res) => res.arrayBuffer());
    const pdfDoc3 = await PDFDocument.load(existingPdfBytes3);

    const itemsPerPage = 8;
    let pageIndex = 1;

    if (employeeDetails) {
        let totalEarningsValue = 0;
        let totalValue = 0;

        for (let itemIndex = 0; itemIndex < employeeData.length; itemIndex++) {
            if (itemIndex % itemsPerPage === 0) {
                // Move to the next page using the template pdfDoc3
                const [newPageTemplate] = await mergedPdfDoc.copyPages(pdfDoc3, pdfDoc3.getPageIndices());
                mergedPdfDoc.addPage(newPageTemplate);
                pageIndex++;
            }

            const offsetY = y - (itemIndex % itemsPerPage) * (20 + marginBottom);
            const currentIndex = pageIndex * itemsPerPage + (itemIndex % itemsPerPage) + 1;
            const item = employeeData[itemIndex];
            const taxID = `${item.taxID}`;

            const fullname = employeeData[0].certificateSignatureName;

            const formattedtaxID1 = `${' ' + (taxID[0] ? taxID[0] : "")}`;
            const formattedtaxID2 = `${(taxID[1] ? taxID[1] : "") + '  ' + (taxID[2] ? taxID[2] : "") + '  ' + (taxID[3] ? taxID[3] : "") + '  ' + (taxID[4] ? taxID[4] : "")}`;
            const formattedtaxID3 = `${(taxID[5] ? taxID[5] : "") + '  ' + (taxID[6] ? taxID[6] : "") + '  ' + (taxID[7] ? taxID[7] : "") + '   ' + (taxID[8] ? taxID[8] : "") + '  ' + (taxID[9] ? taxID[9] : "")}`;
            const formattedtaxID4 = `${(taxID[10] ? taxID[10] : "") + '  ' + (taxID[11] ? taxID[11] : "") + '    ' + (taxID[12] ? taxID[12] : "")} `;
            const payDate = dayjs(item.payDate).locale('th').format("DD MMMM");
            const thaiYear = dayjs(item.payDate).format('YYYY')
            const formattedThaiYear = parseInt(thaiYear) + 543;

            // Draw index on the current page using the template pdfDoc3
            const currentPage = mergedPdfDoc.getPages()[pageIndex];

            const companytaxID = `${employeeData[0].companyTaxId}`;

            const formattedcompanytaxID1 = `${' ' + companytaxID[0]}`
            const formattedcompanytaxID2 = `${companytaxID[1] + '  ' + companytaxID[2] + '  ' + companytaxID[3] + '  ' + companytaxID[4]}`
            const formattedcompanytaxID3 = `${companytaxID[5] + '  ' + companytaxID[6] + '  ' + companytaxID[7] + '  ' + companytaxID[8] + '  ' + companytaxID[9]}`
            const formattedcompanytaxID4 = `${companytaxID[10] + '  ' + companytaxID[11] + '    ' + companytaxID[12]} `

            //company taxId
            if ((itemIndex % itemsPerPage === 0) && imageData) {
                currentPage.drawText(`${formattedcompanytaxID1}`, {
                    x: 445, y: 565, size: 14, font: font, color: rgb(0, 0, 0),
                });
                currentPage.drawText(`${formattedcompanytaxID2}`, {
                    x: 465, y: 565, size: 14, font: font, color: rgb(0, 0, 0),
                });
                currentPage.drawText(`${formattedcompanytaxID3}`, {
                    x: 520, y: 565, size: 14, font: font, color: rgb(0, 0, 0),
                });
                currentPage.drawText(`${formattedcompanytaxID4}`, {
                    x: 582, y: 565, size: 14, font: font, color: rgb(0, 0, 0),
                });
                currentPage.drawText(`${branchNumber}`, { x: 752, y: 540, size: 15, font: font });
            };

            currentPage.drawText(`${itemIndex + 1}`, { x, y: offsetY + 10, size: 14, font: font });
            currentPage.drawText(`${item.personalID ? formattedtaxID1 : ""}`, { x: x + 26, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.personalID ? formattedtaxID2 : ""}`, { x: x + 47, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.personalID ? formattedtaxID3 : ""}`, { x: x + 100, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.personalID ? formattedtaxID4 : ""}`, { x: x + 166, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.title_TH}`, { x: x + 50, y: offsetY - 9, size: 14, font: font });
            currentPage.drawText(`${item.firstname_TH}`, { x: x + 85, y: offsetY - 9, size: 14, font: font });
            currentPage.drawText(`${item.lastname_TH}`, { x: x + 250, y: offsetY - 9, size: 14, font: font });

            if (item.payDate) {
                currentPage.drawText(payDate + " " + formattedThaiYear, { x: x + 410, y: offsetY - 9, size: 14, font: font });
            } else {
                currentPage.drawText("-", { x: x + 445, y: offsetY - 9, size: 16, font: font });
            };

            currentPage.drawText(`${item.totalEarnings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: x + 530, y: offsetY - 9, size: 14, font: font });
            currentPage.drawText(`${item.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: x + 635, y: offsetY - 9, size: 14, font: font });

            if ((itemIndex % itemsPerPage === 0) && imageData && fullname) {
                const pngImage = await mergedPdfDoc.embedPng(imageData);
                const pngDims = pngImage.scale(0.07);
                currentPage.drawImage(pngImage, { x: 640, y: 88, width: pngDims.width, height: pngDims.height });
                currentPage.drawText(`${fullname}`, { x: 630, y: 74, size: 14, font: font });
                currentPage.drawText(`${employeeData[0].certificateSignaturePosition || "-"}`, { x: 615, y: 58, size: employeeData[0].certificateSignaturePosition.length > 30 ? 10 : (employeeData[0].certificateSignaturePosition.length > 20 ? 12 : 14), font: font });
                currentPage.drawText(`${presentDayTH}`, { x: 610, y: 40, size: 13, font: font });
                currentPage.drawText(`${presentMonthTH}`, { x: 652, y: 40, size: 13, font: font });
                currentPage.drawText(`${formattedThaiYear2}`, { x: 735, y: 40, size: 13, font: font });
            }

            totalEarningsValue += item.totalEarnings;
            totalValue += item.value;
        }

        // Draw total values at the last page
        const lastPage = mergedPdfDoc.getPages()[pageIndex];
        lastPage.drawText(`${String(totalEarningsValue.toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: 610, y: 114, size: 14, font: font });
        lastPage.drawText(`${String(totalValue.toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: 715, y: 114, size: 14, font: font });
    }

    const mergedPdfBytes = await mergedPdfDoc.save();
    const bytes = new Uint8Array(mergedPdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    window.open(docUrl, "_blank");
};

export const PND1kPDFfile = async (userProfile, type, selectYear, selectedMonth, employeeDetails, imageData) => {
    const url = `${process.env.REACT_APP_API_URL}files/${type}.pdf`;
    const url2 = `${process.env.REACT_APP_API_URL}files/PND1k_personal.pdf`;

    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const existingPdfBytes2 = await fetch(url2).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pdfDoc2 = await PDFDocument.load(existingPdfBytes2);

    const mergedPdfDoc = await PDFDocument.create();

    const pages1 = await mergedPdfDoc.copyPages(pdfDoc, pdfDoc.getPageIndices());
    pages1.forEach((page) => mergedPdfDoc.addPage(page));

    const pages2 = await mergedPdfDoc.copyPages(pdfDoc2, pdfDoc2.getPageIndices());
    pages2.forEach((page) => mergedPdfDoc.addPage(page));
    const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
    const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
    mergedPdfDoc.registerFontkit(fontkit);

    const font = await mergedPdfDoc.embedFont(fontBytes);
    const pages = mergedPdfDoc.getPages();

    const firstPage = pages[0];
    const presentDay = new Date();
    const presentDayTH = dayjs(presentDay).locale('th').format("DD");
    const presentMonthTH = dayjs(presentDay).locale('th').format("MMMM");
    const thaiYear = dayjs(presentDay).format('YYYY')
    const formattedThaiYear = parseInt(thaiYear) + 543;

    const employeeData = employeeDetails && employeeDetails.employeeDetails;
    const fullname = employeeDetails && employeeData[0].certificateSignatureName ? `${employeeData[0].certificateSignatureName}` : "-";

    const taxID = employeeData && employeeData[0].companyTaxId;

    if (taxID) {
        const formattedtaxID1 = `${'   ' + taxID[0]}`
        const formattedtaxID2 = `${'  ' + taxID[1] + '  ' + taxID[2] + '  ' + taxID[3] + '  ' + taxID[4]}`
        const formattedtaxID3 = `${taxID[5] + '  ' + taxID[6] + '  ' + taxID[7] + '  ' + taxID[8] + '  ' + taxID[9]}`
        const formattedtaxID4 = `${taxID[10] + '  ' + taxID[11] + '   ' + taxID[12]} `

        firstPage.drawText(`${formattedtaxID1}`, { x: 160, y: 722, size: 14, font: font });
        firstPage.drawText(`${formattedtaxID2}`, { x: 180, y: 722, size: 14, font: font });
        firstPage.drawText(` ${formattedtaxID3}`, { x: 230, y: 722, size: 14, font: font });
        firstPage.drawText(`${formattedtaxID4}`, { x: 292, y: 722, size: 14, font: font });
    }

    if (employeeData && employeeData.length > 0 && employeeData[0] && employeeData[0].companyName) {
        firstPage.drawText(`${employeeData[0].companyName}`, {
            x: 100, y: 676, size: 16, font: font, color: rgb(0, 0, 0),
        });
    }

    firstPage.drawText(`${selectYear}`, {
        x: 508, y: 701, size: 15, font: font, color: rgb(0, 0, 0),
    });

    let address = [];
    if (employeeData && employeeData.length > 0 && employeeData[0] && employeeData[0].addressCompany) {
        address = employeeData[0].addressCompany.split(" ");
    }

    { address && address[0] && firstPage.drawText(`${address[0]}`, { x: 99, y: 645, size: 14, font: font }); }
    { address && address[1] && firstPage.drawText(`${address[1]}`, { x: 80, y: 631, size: 14, font: font }); }
    { address && address[2] && firstPage.drawText(`${address[2]}`, { x: 240, y: 631, size: 14, font: font }); }
    { employeeData && employeeData[0] && employeeData[0].districtCompany && firstPage.drawText(`${employeeData[0].districtCompany}`, { x: 105, y: 616, size: 14, font: font }); }
    { employeeData && employeeData[0] && employeeData[0].provinceCompany && firstPage.drawText(`${employeeData[0].provinceCompany}`, { x: 235, y: 616, size: 14, font: font }); }

    if (employeeData && employeeData.length > 0 && employeeData[0] && employeeData[0].areaCodeCompany) {
        const areaCode = `${employeeData[0].areaCodeCompany}`;
        const formattedareaCode = `${areaCode[0] + '  ' + areaCode[1] + '  ' + areaCode[2] + '  ' + areaCode[3] + '  ' + areaCode[4]}`
        employeeData && employeeData[0].areaCodeCompany && firstPage.drawText(`${formattedareaCode} `, {
            x: 106,
            y: 600, size: 14, font: font,
        });
    }

    const totalEarnings = employeeData.reduce((sum, employee) => {
        return sum + (employee.totalEarnings || 0);
    }, 0);

    const totalTax = employeeData.reduce((sum, tax) => {
        return sum + ((tax.otherPayment && tax.otherPayment.value) || 0);
    }, 0);

    const totalbeforeAccumulatetotalEarnings = employeeData.reduce((sum, employee) => {
        return sum + (employee.beforeAccumulateEarnings || 0);
    }, 0);

    const totalbeforeAccumulateTax = employeeData.reduce((sum, employee) => {
        return sum + (employee.beforeAccumulateTax || 0);
    }, 0);

    const totalAbsentAndLateValue = employeeData.reduce((sum, employee) => {
        return sum + (employee.Sum_absent || 0) + (employee.Sum_late || 0);
    }, 0);

    const AllTax = totalTax + totalbeforeAccumulateTax;
    const AllEarnings = totalEarnings + totalbeforeAccumulatetotalEarnings - totalAbsentAndLateValue;

    firstPage.drawText(`${employeeData.length}`, { x: 335, y: 393, size: 14, font: font });

    firstPage.drawText(`${AllEarnings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: 378, y: 393, size: 14, font: font });
    firstPage.drawText(`${AllTax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: 475, y: 393, size: 14, font: font });

    firstPage.drawText(`${employeeData.length}`, { x: 335, y: 258, size: 14, font: font });
    firstPage.drawText(`${AllEarnings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: 378, y: 258, size: 14, font: font });
    firstPage.drawText(`${AllTax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: 475, y: 258, size: 14, font: font });

    if (imageData) {
        const pngImage = await mergedPdfDoc.embedPng(imageData);
        const pngDims = pngImage.scale(0.05);
        firstPage.drawImage(pngImage, { x: 275, y: 180, width: pngDims.width, height: pngDims.height })
    }

    firstPage.drawText(`${fullname}`, { x: 240, y: 168, size: 14, font: font });
    firstPage.drawText(`${employeeData[0].certificateSignaturePosition || "-"}`, { x: 240, y: 151, size: employeeData[0].certificateSignaturePosition.length > 30 ? 12 : (employeeData[0].certificateSignaturePosition.length > 20 ? 13 : 14), font: font });
    firstPage.drawText(`${presentDayTH}`, { x: 235, y: 134, size: 13, font: font });
    firstPage.drawText(`${presentMonthTH}`, { x: 280, y: 134, size: 13, font: font });
    firstPage.drawText(`${formattedThaiYear}`, { x: 370, y: 134, size: 13, font: font });

    const x = 80;
    const y = 407;
    let index = 1;
    const marginBottom = 19;

    const existingPdfBytes3 = await fetch(url2).then((res) => res.arrayBuffer());
    const pdfDoc3 = await PDFDocument.load(existingPdfBytes3);

    let pageIndex = 1;
    const itemsPerPage = 7;

    if (employeeDetails) {
        for (let itemIndex = 0; itemIndex < employeeData.length; itemIndex++) {
            if (itemIndex % itemsPerPage === 0) {
                // Move to the next page using the template pdfDoc3
                const [newPageTemplate] = await mergedPdfDoc.copyPages(pdfDoc3, pdfDoc3.getPageIndices());
                mergedPdfDoc.addPage(newPageTemplate);
                pageIndex++;
            }
            const offsetY = y - (itemIndex % itemsPerPage) * (20 + marginBottom);
            const item = employeeData[itemIndex];
            const personalID = `${item.taxID}`;
            const taxItem = item.otherPayment;

            const formattedPersonalID1 = `${' ' + (personalID[0] ? personalID[0] : "")}`;
            const formattedPersonalID2 = `${(personalID[1] ? personalID[1] : "") + '  ' + (personalID[2] ? personalID[2] : "") + '  ' + (personalID[3] ? personalID[3] : "") + '  ' + (personalID[4] ? personalID[4] : "")}`;
            const formattedPersonalID3 = `${(personalID[5] ? personalID[5] : "") + '  ' + (personalID[6] ? personalID[6] : "") + '  ' + (personalID[7] ? personalID[7] : "") + '   ' + (personalID[8] ? personalID[8] : "") + '  ' + (personalID[9] ? personalID[9] : "")}`;
            const formattedPersonalID4 = `${(personalID[10] ? personalID[10] : "") + '  ' + (personalID[11] ? personalID[11] : "") + '    ' + (personalID[12] ? personalID[12] : "")} `;

            const currentPage = mergedPdfDoc.getPages()[pageIndex];

            if (itemIndex % itemsPerPage === 0 && taxID) {
                const formattedtaxID1 = `${'   ' + taxID[0]}`
                const formattedtaxID2 = `${'  ' + taxID[1] + '  ' + taxID[2] + '  ' + taxID[3] + '  ' + taxID[4]}`
                const formattedtaxID3 = `${taxID[5] + '  ' + taxID[6] + '  ' + taxID[7] + '  ' + taxID[8] + '  ' + taxID[9]}`
                const formattedtaxID4 = `${taxID[10] + '  ' + taxID[11] + '   ' + taxID[12]} `

                currentPage.drawText(`${formattedtaxID1}`, { x: 423, y: 560, size: 14, font: font, color: rgb(0, 0, 0) });
                currentPage.drawText(`${formattedtaxID2}`, { x: 443, y: 560, size: 14, font: font, color: rgb(0, 0, 0) });
                currentPage.drawText(`${formattedtaxID3}`, { x: 501, y: 560, size: 14, font: font, color: rgb(0, 0, 0) });
                currentPage.drawText(`${formattedtaxID4}`, { x: 569, y: 560, size: 14, font: font, color: rgb(0, 0, 0) });
            }

            currentPage.drawText(`${itemIndex + 1}`, { x, y: offsetY + 10, size: 14, font: font });
            currentPage.drawText(`${item.taxID ? formattedPersonalID1 : ""}`, { x: x + 26, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.taxID ? formattedPersonalID2 : ""}`, { x: x + 47, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.taxID ? formattedPersonalID3 : ""}`, { x: x + 100, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.taxID ? formattedPersonalID4 : ""}`, { x: x + 166, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.title_TH}`, { x: x + 230, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.firstname_TH}`, { x: x + 260, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.lastname_TH}`, { x: x + 385, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${(item.totalEarnings + item.beforeAccumulateEarnings).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: x + 517, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${((taxItem && taxItem.value) + item.beforeAccumulateTax).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: x + 630, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.address ? item.address : ""} ${item.villageNo ? item.villageNo : ""} ${item.subDistrict ? item.subDistrict : ""} ${item.district ? item.district : ""} ${item.province ? item.province : ""} ${item.areacode ? item.areacode : ""}`,
                { x: x + 240, y: offsetY - 9, size: 12, font: font }
            );

            if (itemIndex % itemsPerPage === 0 && imageData) {
                const pngImage = await mergedPdfDoc.embedPng(imageData);
                const pngDims = pngImage.scale(0.05);
                currentPage.drawImage(pngImage, { x: 640, y: 90, width: pngDims.width, height: pngDims.height });
                currentPage.drawText(`${fullname}`, { x: 630, y: 74, size: 14, font: font });
                currentPage.drawText(`${employeeData[0].certificateSignaturePosition || "-"}`, { x: 615, y: 58, size: employeeData[0].certificateSignaturePosition.length > 30 ? 10 : (employeeData[0].certificateSignaturePosition.length > 20 ? 12 : 14), font: font });
                currentPage.drawText(`${presentDayTH}`, { x: 610, y: 40, size: 13, font: font });
                currentPage.drawText(`${presentMonthTH}`, { x: 652, y: 40, size: 13, font: font });
                currentPage.drawText(`${formattedThaiYear}`, { x: 735, y: 40, size: 13, font: font });
            }

        }

        const lastPage = mergedPdfDoc.getPages()[pageIndex];
        lastPage.drawText(`${AllEarnings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: 597, y: 135, size: 14, font: font });
        lastPage.drawText(`${AllTax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: 710, y: 135, size: 14, font: font });
    }

    const mergedPdfBytes = await mergedPdfDoc.save();
    const bytes = new Uint8Array(mergedPdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    window.open(docUrl, "_blank");
}

export const PND3PdfFile = async (userProfile, type, selectYear, selectedMonth, employeeDetails) => {
    const url = `${process.env.REACT_APP_API_URL}files/${type}.pdf`;
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
    const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);

    const font = await pdfDoc.embedFont(fontBytes);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    if (userProfile.taxID) {
        const taxID = `${userProfile.taxID}`;

        const formattedtaxID1 = `${' ' + taxID[0]}`
        const formattedtaxID2 = `${taxID[1] + '  ' + taxID[2] + '  ' + taxID[3] + '  ' + taxID[4]}`
        const formattedtaxID3 = `${taxID[5] + '  ' + taxID[6] + '  ' + taxID[7] + '  ' + taxID[8] + '  ' + taxID[9]}`
        const formattedtaxID4 = `${taxID[10] + '  ' + taxID[11] + '   ' + taxID[12]} `

        firstPage.drawText(`${formattedtaxID1}`, {
            x: 160,
            y: 735,
            size: 14,
            font: font,
            color: rgb(0, 0, 0),
        });

        firstPage.drawText(`${formattedtaxID2}`, {
            x: 180,
            y: 735,
            size: 14,
            font: font,
            color: rgb(0, 0, 0),
        });

        firstPage.drawText(`${formattedtaxID3}`, {
            x: 230,
            y: 735,
            size: 14,
            font: font,
            color: rgb(0, 0, 0),
        });

        firstPage.drawText(`${formattedtaxID4}`, {
            x: 295,
            y: 735,
            size: 14,
            font: font,
            color: rgb(0, 0, 0),
        });
    }

    if (userProfile && userProfile.companyName) {
        firstPage.drawText(`${userProfile.companyName}`, {
            x: 100,
            y: 688,
            size: 16,
            font: font,
            color: rgb(0, 0, 0),
        });
    }

    firstPage.drawText(`${selectYear}`, {
        x: 527,
        y: 716,
        size: 15,
        font: font,
        color: rgb(0, 0, 0),
    });

    //address
    {
        userProfile && userProfile.addressCompany &&
            firstPage.drawText(`${userProfile.subDistrict}`, {
                x: 240, y: 640, size: 14, font: font
            });
    }
    {
        userProfile && userProfile.districtCompany &&
            firstPage.drawText(`${userProfile.districtCompany}`, {
                x: 95, y: 625, size: 14, font: font
            });
    }
    { userProfile && userProfile.provinceCompany && firstPage.drawText(`${userProfile.provinceCompany}`, { x: 240, y: 625, size: 14, font: font }); }

    const areaCode = `${userProfile.areaCodeCompany}`;

    const formattedareaCode = `${areaCode[0] + '  ' + areaCode[1] + '  ' + areaCode[2] + '  ' + areaCode[3] + '  ' + areaCode[4]}`
    { userProfile.areaCodeCompany && firstPage.drawText(`${formattedareaCode} `, { x: 93, y: 606, size: 14, font: font, }); }

    firstPage.drawText(`${userProfile.title_TH + " " + userProfile.firstname_TH + " " + userProfile.lastname_TH}`, { x: 255, y: 183, size: 14, font: font });

    { userProfile && userProfile.positionName && firstPage.drawText(`${userProfile.positionName}`, { x: 275, y: 168, size: 14, font: font }); }

    if (employeeDetails) {
        firstPage.drawText(`${employeeDetails.totalData.totalEarnings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: 455, y: 335, size: 14, font: font });
        firstPage.drawText(`${employeeDetails.totalData.totalTax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: 455, y: 317, size: 14, font: font });
    }

    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    window.open(docUrl, "_blank");
}