import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Grid, styled, Typography, Box, Paper, Divider, List, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getWagesPerYearByEmployees } from "../../../../../../actions/employee";
import DatePickerCustom from "../../../../shared/date/datePicker";
import dayjs from "dayjs";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { addBudget, updateBudget } from "../../../../../../actions/payruns";
import { BudgetItem, InfoRow } from "./Wage-Component";

const StyledWrapTop = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "&.inner": {
        marginBottom: 0,
    },
    "& .MuiTypography-body2": {
        margin: 0,
    },
});

const StyledHeadLabel = styled(Typography)({
    fontWeight: 600,
    fontSize: 14,
});

const StyledBoxSearchDate = styled(Box)({
    maxWidth: 200,
    marginBottom: 16,
    marginRight: 16
});

function formatNumberWithCommas(number) {
    if (number) {
        const floatNumber = parseFloat(number);
        const fixedNumber = floatNumber.toFixed(2);
        const formattedNumber = fixedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return formattedNumber;
    } else {
        return "0.00";
    }
};

const IndividualWagePerYear = (props) => {
    const newDate = new Date();
    const today = dayjs(newDate).format("YYYY-MM-DD");
    const { t, i18n } = useTranslation();
    const [wage, setWage] = useState(null);
    const [seeOnlyYear, setSeeOnlyYear] = useState(true);
    const [selectedYear, setSelectedYear] = useState(dayjs(today).year());
    const [selectedMonth, setSelectedMonth] = useState(dayjs(today).month());

    const [bonus, setBonus] = useState(null);
    const [other, setOther] = useState(null);
    const [welfare, setWelfare] = useState(null);
    const [editedField, setEditedField] = useState(null);

    const [oldData, setOldData] = useState(null);
    const [summaryAllYear, setSummaryAllYear] = useState(null);

    const SeeDataAllYear = () => {
        setSeeOnlyYear(true);
        setSelectedMonth(null);
    }

    const SeeDataMonthYear = () => {
        setSeeOnlyYear(false);
        setSelectedMonth(dayjs(today).month())
    }

    const fetchedData = async (month, year) => {
        try {
            const response = await getWagesPerYearByEmployees({
                year: year,
                // month: month ? parseInt(month) + 1 : null,
                idEmployees: props.selectedEmployees
            });

            if (response && response.data && response.status === 200) {
                setWage(response.data.summary);
                setBonus(response.data.Bonus);
                setWelfare(response.data.Welfares);
                setOther(response.data.Other);
                setOldData({
                    Bonus: response.data.Bonus,
                    Welfares: response.data.Welfares,
                    Other: response.data.Other
                });
                setSummaryAllYear(response.data.summaryAllYear);
            }
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 404) {
                setWage(null);
                setBonus(null)
                setWelfare(null);
                setOther(null);
                setOldData({
                    Bonus: null,
                    Welfares: null,
                    Other: null
                });
                setSummaryAllYear(null);
            }
        }
    }

    useEffect(() => {
        if (selectedMonth || selectedYear) {
            fetchedData(selectedMonth || null, selectedYear);
        }
    }, [selectedMonth, selectedYear]);

    const openUpdatedField = (id) => {
        setEditedField(id);
    };

    const closeUpdatedField = (id, data) => {
        setEditedField(null);
        if (id === 1 && !data) {
            setBonus(null);
        } else if (id === 2 && !data) {
            setWelfare(null);
        } else if (id === 3 && !data) {
            setOther(null);
        };
    };

    const handleUpdateBudget = async (data, field, type) => {
        try {
            const payload = {
                idBudget: field,
                value: data,
                year: selectedYear,
                // month: selectedMonth + 1,
                idEmployees: props.selectedEmployees,
            };

            const response = type === "add" ? await addBudget(payload) : await updateBudget(payload);
            if (response && response.status === 200) {
                setEditedField(null);
                if (field === 1) {
                    setBonus(data);
                } else if (field === 2) {
                    setWelfare(data);
                } else if (field === 3) {
                    setOther(data);
                };
                                
                await fetchedData(null, selectedYear);
            } else {
                setEditedField(null);
            }
        } catch (error) {
            setEditedField(null);
            console.error(error);
        }
    };

    return (
        <div>
            <StyledWrapTop>
                <StyledHeadLabel variant="subtitle1">
                    {t("WagePerYear")}
                </StyledHeadLabel>
            </StyledWrapTop>

            <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                sx={{ mb: 2 }}
            >
                {!seeOnlyYear && (
                    <StyledBoxSearchDate>
                        <DatePickerCustom
                            inputFormat="MMMM"
                            value={dayjs().year(selectedYear).month(selectedMonth)}
                            name="start"
                            views={["month"]}
                            onChange={(newValue) => {
                                setSelectedMonth(newValue.month());
                            }}
                        />
                    </StyledBoxSearchDate>
                )}

                <StyledBoxSearchDate>
                    <DatePickerCustom
                        inputFormat="YYYY"
                        value={dayjs().year(selectedYear)}
                        name="start"
                        views={["year"]}
                        maxDate={dayjs()}
                        onChange={(newValue) => {
                            setSelectedYear(newValue.year());
                        }}
                    />
                </StyledBoxSearchDate>

                {/* {!seeOnlyYear && (
                    <ButtonBlue
                        variant={"contained"}
                        onClick={() => SeeDataAllYear()}
                    >
                        {t("seeIncomeWholeYear")}
                    </ButtonBlue>
                )}

                {seeOnlyYear && (
                    <ButtonBlue
                        variant={"contained"}
                        onClick={() => SeeDataMonthYear()}
                    >
                        {t("seeIncomeEachYearAndMonth")}
                    </ButtonBlue>
                )} */}
            </Stack>

            <Box>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item container xs={12} justifyContent="space-between">
                        <Grid item>
                            <StyledHeadLabel variant="body2" color="text.secondary">
                                {t("AdditionalName")}
                            </StyledHeadLabel>
                        </Grid>
                        <Grid item>
                            <StyledHeadLabel variant="body2" color="text.secondary">
                                {t("Baht")} {"(ประมาณ)"}
                            </StyledHeadLabel>
                        </Grid>
                    </Grid>
                    <InfoRow label={`${t("Salary")} ${seeOnlyYear ? "x 12" : ""}`} value={(wage && wage.TotalSalary) || null} />
                    <InfoRow label={t("Overtime")} value={(wage && ((wage.TotalOT1 || 0) + (wage.TotalOT15 || 0) + (wage.TotalOT2 || 0) + (wage.TotalOT3 || 0) + (wage.TotalOTmonth || 0))) || null} />
                    <InfoRow label={t("ShiftValue")} value={wage && wage.TotalShiftFee} />
                    <InfoRow label="PF" value={(selectedYear === 2023 && !selectedMonth) ? ((wage && (wage.TotalPF + wage.JanPF2023)) || null) : ((wage && wage.TotalPF) || null)} />
                    <InfoRow label={t("SSO")} value={(selectedYear === 2023 && !selectedMonth) ? ((wage && (wage.TotalSSO + wage.JanSSO2023)) || null) : ((wage && wage.TotalSSO) || null)} />

                    <BudgetItem
                        label={t("Bonus")}
                        value={bonus ? bonus : null}
                        isEditing={editedField === 1}
                        onEdit={() => openUpdatedField(1)}
                        onChange={setBonus}
                        onSave={(data) => handleUpdateBudget(data, 1, oldData && oldData.Bonus ? "update" : "add")}
                        inputValue={bonus}
                        editable={seeOnlyYear}
                        onClose={() => closeUpdatedField(1, oldData && oldData.Bonus)}
                    />
                    <BudgetItem
                        label={t("Welfare")}
                        value={welfare ? welfare : null}
                        isEditing={editedField === 2}
                        onEdit={() => openUpdatedField(2)}
                        onChange={setWelfare}
                        onSave={(data) => handleUpdateBudget(data, 2, oldData && oldData.Welfares ? "update" : "add")}
                        inputValue={welfare}
                        editable={seeOnlyYear}
                        onClose={() => closeUpdatedField(2, oldData && oldData.Welfares)}
                    />
                    <BudgetItem
                        label={t("Other")}
                        value={other ? other : null}
                        isEditing={editedField === 3}
                        onEdit={() => openUpdatedField(3)}
                        onChange={setOther}
                        onSave={(data) => handleUpdateBudget(data, 3, oldData && oldData.Other ? "update" : "add")}
                        inputValue={other}
                        editable={seeOnlyYear}
                        onClose={() => closeUpdatedField(3, oldData && oldData.Other)}
                    />
                </Grid>

                <Divider sx={{ mt: 1, mb: 1 }} />

                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                >
                    <InfoRow label={t("totalWholeYear")} value={summaryAllYear ? parseFloat(summaryAllYear) : "0.00"} />
                    <InfoRow label={t("totalWholeMonth")} value={summaryAllYear ? parseFloat(summaryAllYear) / 12 : "0.00"} />
                    <InfoRow label={t("totalWholeDay")} value={summaryAllYear ? (parseFloat(summaryAllYear) / 12) / 30 : "0.00"} />
                </Grid>
            </Box>
        </div>
    );
};

export default IndividualWagePerYear;