import React, { useEffect, useState } from "react";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  Container,
  Grid,
  MenuItem,
  SvgIcon,
  Typography,
  styled,
  Card,
} from "@mui/material";
import CardStyle from "../../../../shared/general/Card";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { CheckRounded, Flag, Key, StarRounded } from "@mui/icons-material";
import { ReactComponent as MoonshotIcon } from "../../../../assets/moonshot_logo.svg";
import ChipStatus from "../../../../shared/pages/okrs/components/ChipStatus";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { useDispatch } from "react-redux";
import { getDashboardIndividual } from "../../../../../../actions/okrs";
dayjs.extend(quarterOfYear);

const StyledRoot = styled(Box)({
  "& .keyresult-text": {
    fontSize: "20px",
    fontWeight: 500,
  },
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const StyledCard = styled(Card)({
  minHeight: "70px",
  boxShadow: "none",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  background:
    "linear-gradient(to right, rgba(104, 205, 232, 0.7), rgba(109, 238, 227, 0.7))",
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  "& .yearSelectionContainer": {
    marginBottom: "100px",
  },
  "@media (max-width: 600px)": {
    "& .yearSelectionContainer": {
      marginBottom: "1px",
    },
  },
});

const StyleHeadBox = styled(Box)({
  "& .box-header": {
    minHeight: "200px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    background:
      "linear-gradient(to right, rgba(104, 205, 232, 0.7), rgba(109, 238, 227, 0.7))",

    "& .text-header": {
      fontSize: "48px",
      fontWeight: "bold",
      marginLeft: "40px",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginTop: "80px",
    },
    "& .img-header": {
      objectFit: "contain",
      // width: "40%",
      // height: "auto",
      // marginBottom: "10px",
      // justifyContent: "flex-end",
      // alignItems: "flex-end",
    },
  },

  "@media (max-width: 600px)": {
    "& .box-header": {
      flexDirection: "column",
      minHeight: "unset",
      justifyContent: "center",
      alignItems: "center",
      "& .text-header": {
        fontSize: "25px",
        marginTop: "20px",
      },
      // "& .img-header": {
      //   width: "50%",
      //   justifyContent: "flex-start",
      //   alignItems: "flex-start",
      // },
    },
  },
});

const DashboardIndividual = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [yearOptions, setYearOptions] = useState([]);
  const [year, setYear] = useState(dayjs().get("year"));
  const [quarter, setQuarter] = useState(dayjs().quarter());

  const [dashboardData, setDashboardData] = useState(null);

  const getStatusAchieve = (status) => {
    if (status === 1) {
      return 5;
    } else if (status === 0) {
      return 6;
    }
  };

  useEffect(() => {
    let start = dayjs("2023-05-01");
    let tempYearOpt = [];
    let yearCount = dayjs().diff(start, "years", true) + 1;
    yearCount = Math.floor(yearCount);

    for (let i = 0; i < yearCount + 1; i++) {
      tempYearOpt.push({
        value: start.add(i, "years").format("YYYY"),
        label: start.add(i, "years").format("BBBB"),
      });
    }
    setYearOptions([...tempYearOpt]);
  }, []);

  useEffect(() => {
    let isMounted = true;
    dispatch(getDashboardIndividual({ year: year, quarter: quarter })).then(
      (res) => {
        if (isMounted) {
          if (res.status === 200) {
            setDashboardData(res.data);
          }
        }
      }
    );

    return () => {
      isMounted = false;
    };
  }, [year, quarter]);

  return (
    <StyledRoot className="page">
      <Box padding="24px 0">
        <Container maxWidth="lg">
          <StyledCard
            style={{
              marginBottom: "48px",
              marginTop: "20px",
            }}
          >
            <StyleHeadBox>
              <Box className="box-header">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="text-header">{`${t(
                      "OverallPerspectiveIndividualGoals"
                    )}`}</Typography>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    className="yearSelectionContainer"
                  >
                    <Grid item xs={3} style={{ marginLeft: "60px" }}>
                      <Typography
                        fontSize="14px"
                        fontWeight="600"
                        marginBottom="4px"
                        color="text.third"
                      >{`${t("Year")}`}</Typography>
                      <TextFieldTheme
                        variant="filled"
                        value={year}
                        onChange={(event) => {
                          setYear(event.target.value);
                        }}
                        select
                        style={{ background: "#fff", borderRadius: "8px" }}
                      >
                        {yearOptions.map((y) => (
                          <MenuItem key={y.value} value={y.value}>
                            {y.label}
                          </MenuItem>
                        ))}
                      </TextFieldTheme>
                    </Grid>

                    <Grid item xs={2} style={{ marginLeft: "10px" }}>
                      <Typography
                        fontSize="14px"
                        fontWeight="600"
                        marginBottom="4px"
                        color="text.third"
                      >{`${t("Quarter")}`}</Typography>
                      <TextFieldTheme
                        variant="filled"
                        value={quarter}
                        onChange={(event) => {
                          setQuarter(event.target.value);
                        }}
                        select
                        style={{ background: "#fff", borderRadius: "8px" }}
                      >
                        <MenuItem value="1">Q1</MenuItem>
                        <MenuItem value="2">Q2</MenuItem>
                        <MenuItem value="3">Q3</MenuItem>
                        <MenuItem value="4">Q4</MenuItem>
                      </TextFieldTheme>
                    </Grid>
                  </Grid>
                </Grid>
                <img
                  className="img-header"
                  src={`${process.env.PUBLIC_URL}/assets/okrs/user/overall-perspective-individual-goal.png`}
                  alt="icon"
                />
              </Box>
            </StyleHeadBox>
          </StyledCard>

          {dashboardData && (
            <Box marginTop="16px">
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <CardStyle>
                    <Box padding="24px" display="flex">
                      <Box paddingRight="16px">
                        <Avatar
                          style={{
                            width: "52px",
                            height: "52px",
                            backgroundColor: "#5b4589",
                          }}
                        >
                          <Flag style={{ fontSize: "36px" }} />
                        </Avatar>
                      </Box>
                      <Box flexGrow={1}>
                        <Typography
                          fontSize="48px"
                          fontWeight="500"
                          lineHeight="1"
                        >
                          {dashboardData.individualObjective}
                        </Typography>
                        <Typography
                          fontSize="14px"
                          fontWeight="500"
                          color="text.third"
                          lineHeight="1"
                        >{`${t("Objectives")}`}</Typography>
                      </Box>
                    </Box>
                  </CardStyle>
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardStyle>
                    <Box padding="24px" display="flex">
                      <Box paddingRight="16px">
                        <Avatar
                          style={{
                            width: "52px",
                            height: "52px",
                            backgroundColor: "#ed94be",
                          }}
                        >
                          <Key style={{ fontSize: "36px" }} />
                        </Avatar>
                      </Box>
                      <Box flexGrow={1}>
                        <Typography
                          fontSize="48px"
                          fontWeight="500"
                          lineHeight="1"
                        >
                          {dashboardData.individualKeyResult}
                        </Typography>
                        <Typography
                          fontSize="14px"
                          fontWeight="500"
                          color="text.third"
                          lineHeight="1"
                        >
                          {`${t("TheMainResult")}`}
                        </Typography>
                      </Box>
                    </Box>
                  </CardStyle>
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardStyle>
                    <Box padding="24px" display="flex">
                      <Box paddingRight="16px">
                        <Avatar
                          style={{
                            width: "52px",
                            height: "52px",
                            backgroundColor: "#6FDFBD",
                          }}
                        >
                          <CheckRounded style={{ fontSize: "36px" }} />
                        </Avatar>
                      </Box>
                      <Box flexGrow={1}>
                        <Typography
                          fontSize="48px"
                          fontWeight="500"
                          lineHeight="1"
                        >
                          {dashboardData.keyResultSuccess}
                        </Typography>
                        <Typography
                          fontSize="14px"
                          fontWeight="500"
                          color="text.third"
                          lineHeight="1"
                        >
                          {`${t("KeyResultsAchieved")}`}
                        </Typography>
                      </Box>
                    </Box>
                  </CardStyle>
                </Grid>
                <Grid item xs={6} md={3}>
                  <CardStyle>
                    <Box padding="24px" display="flex">
                      <Box paddingRight="16px">
                        <Avatar
                          style={{
                            width: "52px",
                            height: "52px",
                            backgroundColor: "#142766",
                          }}
                        >
                          <SvgIcon style={{ fontSize: "36px" }}>
                            <MoonshotIcon />
                          </SvgIcon>
                        </Avatar>
                      </Box>
                      <Box flexGrow={1}>
                        <Typography
                          fontSize="48px"
                          fontWeight="500"
                          lineHeight="1"
                        >
                          {dashboardData.moonShot}
                        </Typography>
                        <Typography
                          fontSize="14px"
                          fontWeight="500"
                          color="text.third"
                          lineHeight="1"
                        >
                          Moon Shot
                        </Typography>
                      </Box>
                    </Box>
                  </CardStyle>
                </Grid>

                <Grid item xs={12} md={6}>
                  <CardStyle>
                    <Box padding="24px" height="320px">
                      <Box display="flex" flexDirection="column" height="100%">
                        <Typography fontSize="18px">{`${t(
                          "AverageScore"
                        )}`}</Typography>
                        <Box
                          flexGrow="1"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Avatar
                            style={{
                              width: "84px",
                              height: "84px",
                              marginRight: "16px",
                              backgroundColor: "#ffdd40",
                            }}
                          >
                            <StarRounded style={{ fontSize: "64px" }} />
                          </Avatar>
                          <Typography fontSize="64px">
                            {Math.round(dashboardData.averageRating * 100) /
                              100}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </CardStyle>
                </Grid>

                <Grid item xs={12} md={6}>
                  <CardStyle>
                    <Box padding="24px" height="320px">
                      <Box display="flex" flexDirection="column" height="100%">
                        <Typography fontSize="18px">
                          {`${t("LatestUpdatedKeyResultsList")}`}
                        </Typography>
                        <Box marginTop="16px" flexGrow="1" overflow="auto">
                          <Grid container spacing={1}>
                            {dashboardData.updatedKeyResult.map(
                              (keyResult, index) => (
                                <Grid key={index} item xs={12}>
                                  <Box
                                    padding="8px"
                                    border="1px solid #919eab3d"
                                    borderRadius="12px"
                                  >
                                    <Box display="flex">
                                      <Typography flexGrow="1" fontSize="14px">
                                        {keyResult.keyResultName}
                                      </Typography>
                                      <ChipStatus
                                        status={getStatusAchieve(
                                          keyResult.isAchieve
                                        )}
                                        size="small"
                                      />
                                    </Box>
                                    <Typography
                                      fontSize="12px"
                                      color="text.third"
                                    >
                                      {dayjs(keyResult.achieveDate).format(
                                        "D MMM BB HH.mmน."
                                      )}
                                    </Typography>
                                  </Box>
                                </Grid>
                              )
                            )}
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  </CardStyle>
                </Grid>
              </Grid>
            </Box>
          )}
        </Container>
      </Box>
    </StyledRoot>
  );
};

export default DashboardIndividual;
