import {
    PDPAAPPROVE_FAILED,
    PDPAAPPROVE_FETCHING,
    PDPAAPPROVE_SUCCESS,
    PDPAAPPROVELIST_FAILED,
    PDPAAPPROVELIST_FETCHING,
    PDPAAPPROVELIST_SUCCESS
} from "./types"

import pdpaApproveService from "../services/pdpaApprove.service"

export const getPdpaApprove = (idCompany) => async (dispatch) => {
    try {
        dispatch({
            type: PDPAAPPROVELIST_FETCHING,
        });
        const res = await pdpaApproveService.getPdpaApprove(idCompany);
        if (res) {
            dispatch({
                type: PDPAAPPROVELIST_SUCCESS,
                payload: res.data,
            });

            return res
        }
    } catch (error) {
        dispatch({
            type: PDPAAPPROVELIST_FAILED,
        });

        console.error(error);
    }
};

export const getPdpaApproveByID = () => async (dispatch) => {
    try {
        dispatch({
            type: PDPAAPPROVE_FETCHING,
        });
        const res = await pdpaApproveService.getPdpaApproveByID();
        if (res) {
            dispatch({
                type: PDPAAPPROVE_SUCCESS,
                payload: res.data,
            });
        }
    } catch (err) {
        dispatch({
            type: PDPAAPPROVE_FAILED,
        });
    }
};

export const addPdpaApprove = async (isApprove) => {
    try {
        const res = await pdpaApproveService.addPdpaApprove(isApprove);
        if (res) {
            return res
        }
    } catch (error) {
        console.error(error);
        return error;
    }
}