import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllJobPostingApply } from "../../../../actions/jobPostingApply";
import { makeStyles } from "@mui/styles";
import { Box, Container, Typography } from "@mui/material";
import JobCard from "./jobCard";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "30px",
  },
  jobCard: {
    marginTop: "50px",
  },
}));

function Index() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: jobApplyStore } = useSelector(
    (state) => state.jobPostingApply
  );

  useEffect(() => {
    dispatch(getAllJobPostingApply());
  }, []);

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom style={{ marginTop: 20 }}>
          Job Posting Apply
        </Typography>
        <Box className={classes.jobCard}>
          {jobApplyStore ? (
            <JobCard jobApply={jobApplyStore} />
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography variant="body1">No Data</Typography>
            </Box>
          )}
        </Box>
      </Container>
    </div>
  );
}

export default Index;
