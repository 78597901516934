import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import { Avatar, MenuItem, Typography } from "@mui/material";

import CardEmployee from "./cardEmployee";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserCompany, getUserFullName, getUserPosition, getUserSection } from "../../../../utils/userData";

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px -10px 20px #EEEEEE",
    position: "sticky",
    left: 0,
    backgroundColor: "#f4f6f8",
    zIndex: 4,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "none",
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .fullname": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .position": {
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .department": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .reportTo": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
    fontWeight: 600,
  },
  "& .reportToPosition": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${280}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 745px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const StyledMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    borderRadius: 8,
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
  },
  "& .MuiList-root": {
    padding: 8,
  },
  "& .MuiMenuItem-root": {
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#919eab14",
    },
  },
});

const ItemTable = (props) => {
  const { open, filterEmployee } = props;
  const { t, i18n } = useTranslation();
  const mobileResponsive = useMediaQuery("(max-width:600px)");
  const { result: employeeList } = useSelector((state) => state.employees);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [valueSelected, setValueSelected] = React.useState(null);
  const [dataEmployees, setDataEmployees] = React.useState(null);

  const columns = [
    { id: "name", label: `${t("FullName")}`, minWidth: 240 },
    {
      id: "position",
      label: `${t("Position")} / ${t("Department")}`,
      width: 320,
    },
    {
      id: "companyName",
      label: `${t("Company")}`,
      minWidth: 180,
    },
  ];

  useEffect(() => {
    if (employeeList && userProfile) {
      let resultFilter = [...employeeList];

      if (filterEmployee.status === "active") {
        resultFilter = resultFilter.filter((item) => item.isTerminate === 0);
      } else if (filterEmployee.status === "terminate") {
        resultFilter = resultFilter.filter((item) => item.isTerminate === 1);
      }

      setDataEmployees(resultFilter);
    }
  }, [employeeList, userProfile, filterEmployee]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  console.log("employeeList", employeeList)

  return (
    <Main open={open} style={{ width: !open && "100%" }}>
      {!mobileResponsive ? (
        <StyledBox className={open ? "open" : "close"}>
          <TableContainer sx={{ width: "100%", height: 450 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <StyledCellHeader
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </StyledCellHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataEmployees &&
                  dataEmployees
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <StyledRowContent key={row.idEmployees}>
                          <StyledCellContent className="sticky" key={"name"}>
                            <div className="fullname">
                              <IconButton
                                aria-label="more"
                                size="small"
                                style={{ marginRight: 8 }}
                                onClick={(event) => {
                                  setAnchorEl(event.currentTarget);
                                  setValueSelected(row);
                                  console.log(row);
                                }}
                              >
                                <MoreVertIcon fontSize="small" />
                              </IconButton>
                              {/* <Avatar
                                src={`${process.env.REACT_APP_API_URL}image/vendor/${row.idVendor}/${row.personalId}.jpg`}
                              /> */}
                              <Avatar
                                alt={row.firstname_TH}
                                src={`${row.imageProfile}` || ""}
                              />
                              <div>
                                <Typography>{getUserFullName(row)}</Typography>
                                <Typography variant="body2" color="text.third">
                                  {row.employeeID}
                                </Typography>
                              </div>
                            </div>
                          </StyledCellContent>
                          <StyledCellContent key={"position"}>
                            <Typography className="position">
                              {getUserPosition(row)}
                            </Typography>
                            <Typography
                              className="department"
                              color="text.third"
                            >
                              {getUserSection(row)}
                            </Typography>
                          </StyledCellContent>
                          <StyledCellContent key={"companyName"}>
                            <Typography>{getUserCompany(row)}</Typography>
                          </StyledCellContent>
                        </StyledRowContent>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={dataEmployees ? dataEmployees.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${
                            count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
          />
          <StyledMenu
            id={`more-menu`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            {valueSelected && (
              <Fragment>
                <MenuItem
                  component={NavLink}
                  to={{
                    pathname: `/manager/employee/${valueSelected.idEmployees}`,
                    userProps: {
                      state: valueSelected,
                    },
                  }}
                >
                  <i class="far fa-id-badge" style={{ marginRight: 16 }}></i>
                  <Typography>{`${t("ViewInformation")}`}</Typography>
                </MenuItem>
              </Fragment>
            )}
          </StyledMenu>
        </StyledBox>
      ) : (
        <Grid container spacing={2}>
          {dataEmployees &&
            dataEmployees.map((employee) => (
              <Grid item xs={6}>
                <CardEmployee
                  key={employee.idEmployees}
                  employee={employee}
                  handleClickCard={() => {
                    history.push({
                      pathname: `/manager/employee/${employee.idEmployees}`,
                      userProps: {
                        state: employee,
                      },
                    });
                  }}
                />
              </Grid>
            ))}
        </Grid>
      )}
    </Main>
  );
};

export default ItemTable;
