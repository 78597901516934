import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Grid, Divider, Paper, styled, Typography } from "@mui/material";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import AddIcon from "@mui/icons-material/Add";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledPaper = styled(Paper)({
  border: "none",
  display: "flex",
  alignItems: "flex-start",
  marginBottom: 16,
});

const StyledDivider = styled(Divider)({
  marginTop: 0,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapHead = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const ExpertiseTab = () => {
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  return (
    <div>
      <StyledWrapHead>
        <StyledHeadLabel style={{ marginBottom: 16 }}>
          {`ประวัติการศึกษา`}
        </StyledHeadLabel>
        <ButtonBlue size="small" startIcon={<AddIcon />}>
          เพิ่มวุฒิการศึกษา
        </ButtonBlue>
      </StyledWrapHead>
      {employeeProfile.education.length > 0 && (
        <Fragment>
          <StyledPaper variant="outlined">
            <div>
              <Typography variant="h6">
                (2008-2012) University of ABC
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Computer Science, ปริญญาตรี
              </Typography>
              <Typography style={{ fontWeight: 700, marginTop: 8 }}>
                เกรดเฉลี่ย: 3.98
              </Typography>
            </div>
          </StyledPaper>
          <StyledDivider />
          <StyledPaper variant="outlined">
            <div>
              <Typography variant="h6">
                (2013-2016) University of DEF
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Computer Science, ปริญญาโท
              </Typography>
              <Typography style={{ fontWeight: 700, marginTop: 8 }}>
                เกรดเฉลี่ย: 4.00
              </Typography>
            </div>
          </StyledPaper>
        </Fragment>
      )}
    </div>
  );
};

export default ExpertiseTab;
