import {
    ALL_PROJECT_USER_FAILED,
    ALL_PROJECT_USER_SUCCESS
    } from "../actions/types";
    
    const initialState = {
      result: null,
      isFetching: false,
      isError: false,
    };
    
    export default function (state = initialState, action) {
      const { type, payload } = action;
      switch (type) {
        case ALL_PROJECT_USER_FAILED:
          return { ...state, result: null, isFetching: false, isError: true };
        case ALL_PROJECT_USER_SUCCESS:
          return { ...state, result: payload, isFetching: false, isError: false };
        default:
          return state;
      }
    }