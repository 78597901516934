import { httpClient } from "./httpClient";

const getLeave = ({ employee, start, end }) => {
  return httpClient.get(`overview-user/leave/${employee}?start=${start}&end=${end}`);
};

const getNotification = ({ idEmployees, role, idCompany }) => {
  return httpClient.get(`notification?idEmployees=${idEmployees}&role=${role}&&idCompany=${idCompany}`);
};
const updateNotification = ({ id }) => {
  return httpClient.put(`notification?id=${id}`);
};

const getAbsentAndLate = ({ employee, start, end }) => {
  return httpClient.get(`overview-user/absent-and-late/${employee}?start=${start}&end=${end}`);
};

export default {
  getLeave,
  getAbsentAndLate,
  getNotification,
  updateNotification
};
