import ExcelJS from 'exceljs';
import { getUserCompany, getUserFirstName, getUserLastName } from '../../../../../utils/userData';

export const LeaveListXlsx = (t, employeeList, leaveGroupListProps) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("รายงานการลาเทียบโควต้า");

    const leaveGroupList = leaveGroupListProps && leaveGroupListProps.flatMap(i => ([
        { id: `${i.idLeaveType}_quota`, label: `${t("Quota")} ${i.leaveGroupName}` },
        { id: `${i.idLeaveType}_used`, label: `${i.leaveGroupName} ${t("Spent")}` },
        { id: `${i.idLeaveType}_remaining`, label: `${i.leaveGroupName} ${t("Remaining")}` },
    ]));

    const headerValues = [
        t("EmployeeID"),
        t("FirstName"),
        t("LastName"),
        t("Position"),
        t("Company"),
        t("Division"),
        t("Department"),
        t("Section"),
        ...leaveGroupList.map(i => i.label)
    ];

    const headerRow = worksheet.addRow(headerValues);
    headerRow.height = 50;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    const colWidths = [
        { key: "employeeID", width: 30 },
        { key: "firstname", width: 30 },
        { key: "lastname", width: 30 },
        { key: "position", width: 30 },
        { key: "company", width: 30 },
        { key: "division", width: 30 },
        { key: "department", width: 30 },
        { key: "section", width: 30 },
        ...leaveGroupList.flatMap((leaveType) => ([
            { key: `${leaveType.id}_quota`, width: 40 }, // 002060
            { key: `${leaveType.id}_used`, width: 40 }, // FF0000
            { key: `${leaveType.id}_remaining`, width: 40 }, // 00B050
        ]))
    ];

    // Set column widths
    colWidths.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width;
    });

    employeeList && employeeList.forEach((item) => {
        const row = [
            item.employeeID ? item.employeeID : "-",
            item.firstname_TH ? getUserFirstName(item) : "-",
            item.lastname_TH ? getUserLastName(item) : "-",
            item.positionName ? item.positionName : "-",
            item.companyName ? getUserCompany(item) : "-",
            item.divisionName ? item.divisionName : "-",
            item.departmentName ? item.departmentName : "-",
            item.sectionName ? item.sectionName : "-",
        ];

        leaveGroupListProps && leaveGroupListProps.forEach(leave => {
            const matchedLeave = item.leaveGroupInfo[leave.idLeaveGroup]
                ? item.leaveGroupInfo[leave.idLeaveGroup]
                : null;
            const quota = matchedLeave
                ? matchedLeave.leaveValue === null
                    ? "ไม่จำกัด"
                    : (matchedLeave.leaveValue + matchedLeave.carryValue)
                : "-"
            const used = matchedLeave
            ? matchedLeave.leaveValueUsed +  matchedLeave.leaveCarryUsed
            : 0;
            const remain = matchedLeave
            ? matchedLeave.leaveValue === null
                ? "ไม่จำกัด"
                : (matchedLeave.leaveRemain + matchedLeave.carryRemain)
            : "-"

            row.push(quota, used, remain);
        });

        const excelRow = worksheet.addRow(row);

        const contentStyle = {
            font: { size: 18, name: 'TH SarabunPSK' },
            alignment: { horizontal: "center", vertical: 'middle' },
        };

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Uni ${t("leaveReportComparedToQuota")}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
};
