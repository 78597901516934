import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import { Card, Divider, styled, Typography } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
//Translator TH-EN
import { useTranslation } from "react-i18next";

import fileService from '../../../../services/file.service';

const StyledCard = styled(Card)(({ selected }) => ({
  padding: "8px 16px 24px",
  marginBottom: 16,
  position: "relative",
  borderRadius: 8,
  backgroundColor: selected ? "#E8FFF8" : "#FFFFFF",
  "& .top-card": {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 16,
  },
  "& .bottom-card": {
    display: "flex",
    justifyContent: "space-between",
    "& .date-time": {
      width: "fit-content",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  "& .icon-expand": {
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translate(-50%, 20%)",
    color: "#C4C4C4",
    fontSize: 32,
  },
  "& .topic": {
    marginRight: 8,
  },
  "& .text": {
    fontSize: 16,
  },
  "& .text-reason": {
    fontSize: 14,
    color: "#757575",
  },
}));

const StyledDivider = styled(Divider)({
  borderBottom: "1px solid #C4C4C4",
  marginBottom: 8,
});

const CardRequest = (props) => {
  const { row, mode, selectedRows, setSelectedRows } = props;
  const { t, i18n } = useTranslation();
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );
  const [openExpand, setOpenExpand] = useState(false);

  const handleOpenCloseExpand = () => {
    setOpenExpand(!openExpand);
  };

  const handleClickCard = (_, row) => {
    const selectedIndex = selectedRows.indexOf(row);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    setSelectedRows(newSelected);
  };

  const isSelected = (row) => {
    return selectedRows.indexOf(row) !== -1;
  };

  return (
    <StyledCard
      selected={isSelected(row)}
      onClick={(e) => {
        if (mode === "Shift") {
          handleClickCard(e, row);
        }
      }}
    >
      <div
        className="top-card"
        onClick={(e) => {
          if (mode !== "Shift") {
            handleClickCard(e, row);
          }
        }}
      >
        <div>
          <Typography style={{ fontWeight: 700, fontSize: 16 }}>
            {row.firstname_TH} {row.lastname_TH}
          </Typography>
          <Typography color="text.third" fontSize="14px">
            {row.positionName}
          </Typography>
        </div>
        {mode === "Shift" ? (
          <div>
            {row.isWorkingDay === 1 ? (
              <Typography>{row.shiftGroupName}</Typography>
            ) : (
              <Fragment>
                {row.idHoliday > 0 ? (
                  <Fragment>
                    <Typography>{`${t("PublicHoliday")}`}</Typography>
                    <Typography
                      style={{
                        marginTop: 4,
                        marginBottom: 4,
                        fontSize: 14,
                        fontStyle: "oblique",
                      }}
                      color="text.secondary"
                    >
                      {row.holidayName}
                    </Typography>
                  </Fragment>
                ) : (
                  <Typography>{`${t("DayOff")}`}</Typography>
                )}
              </Fragment>
            )}
          </div>
        ) : (
          <div>
            <Typography fontSize={14} align="right">
              {row.name}
            </Typography>
            {mode === "OT" && payrollSetting && (
              <Fragment>
                {row.xWorkingDailyHoliday > 0 && (
                  <Typography
                    style={{ fontSize: 14 }}
                    color="text.third"
                    align="right"
                  >
                    OT x {payrollSetting.xWorkingDailyHoliday} ={" "}
                    {parseFloat(row.xWorkingDailyHoliday / 60).toFixed(2)}{" "}
                    {`${t("Unit.ShortHours")}`}
                  </Typography>
                )}
                {row.xWorkingMonthlyHoliday > 0 && (
                  <Typography
                    style={{ fontSize: 14 }}
                    color="text.third"
                    align="right"
                  >
                    OT x {payrollSetting.xWorkingMonthlyHoliday} ={" "}
                    {parseFloat(row.xWorkingMonthlyHoliday / 60).toFixed(2)}{" "}
                    {`${t("Unit.ShortHours")}`}
                  </Typography>
                )}
                {row.xOT > 0 && (
                  <Typography
                    style={{ fontSize: 14 }}
                    color="text.third"
                    align="right"
                  >
                    OT x {payrollSetting.xOT} ={" "}
                    {parseFloat(row.xOT / 60).toFixed(2)} {`${t("Unit.ShortHours")}`}
                  </Typography>
                )}
                {row.xOTHoliday > 0 && (
                  <Typography
                    style={{ fontSize: 14 }}
                    color="text.third"
                    align="right"
                  >
                    OT x {payrollSetting.xOTHoliday} ={" "}
                    {parseFloat(row.xOTHoliday / 60).toFixed(2)}{" "}
                    {`${t("Unit.ShortHours")}`}
                  </Typography>
                )}
              </Fragment>
            )}
          </div>
        )}
      </div>
      <StyledDivider />
      {mode === "Shift" ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Typography>
            {`${t("Date")} : ${dayjs(row.workingDateText, "DD/MM/YYYY").format(
              "D MMM BBBB"
            )}`}
          </Typography>
        </div>
      ) : (
        <div onClick={handleOpenCloseExpand}>
          <div className="bottom-card">
            <div className="date-time">
              <Typography className="text">
                {`${t("Start")}`}{" "}
                {dayjs(row.startText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}
              </Typography>
              {row.isFullDay === 1 ? (
                <Typography>{`${t("Full_day")}`}</Typography>
              ) : (
                <Typography className="text" align="center">
                  {dayjs(row.startText, "DD/MM/YYYY HH:mm").format("HH:mm")}
                </Typography>
              )}
            </div>
            <div className="date-time">
              <Typography className="text">
                {`${t("End")}`}{" "}
                {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}
              </Typography>
              {row.isFullDay === 1 ? (
                <Typography>{`${t("Full_day")}`}</Typography>
              ) : (
                <Typography className="text" align="center">
                  {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("HH:mm")}
                </Typography>
              )}
            </div>
          </div>
          {openExpand ? (
            <KeyboardArrowUpRoundedIcon className="icon-expand" />
          ) : (
            <KeyboardArrowDownRoundedIcon className="icon-expand" />
          )}
          {openExpand && (
            <Fragment>
              <StyledDivider style={{ marginTop: 8 }} />
              <div>
                <div style={{ display: "flex" }}>
                  <Typography className="topic text-reason">
                    {`${t("Reason")}`}:
                  </Typography>
                  <Typography className="text-reason">
                    {row && row.idRequestTime
                      ? row.reasonName
                      : row.description}
                  </Typography>
                </div>
                {row.otherReason && (
                  <div>
                    <Typography className="text-reason">
                      {`${t("OtherReason")}`}:
                    </Typography>
                    <Typography className="text-reason">
                      {row.otherReason}
                    </Typography>
                  </div>
                )}
              </div>
            </Fragment>
          )}
        </div>
      )}
    </StyledCard>
  );
};

export default CardRequest;
