import React from "react";
import {
  Box,
  Button,
  Grid,
  Slider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
} from "@mui/material";
import { AccessTimeRounded } from "@mui/icons-material";
import dayjs from "dayjs";
import ChipStatus from "../../../../../shared/pages/okrs/components/ChipStatus";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../../../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getOrganizationObjective,
  updateOrganizationKeyResult,
} from "../../../../../../../actions/okrs";
import { openNotificationAlert } from "../../../../../../../actions/notificationAlert";

const ResponsiveButtonBlue = styled(Button)({
  backgroundColor: "rgba(219, 65, 120, 1)",
  "&:hover": {
    backgroundColor: "#C53B6C",
  },
});

const marks = [
  { value: 0, label: "0" },
  { value: 0.1, label: "0.1" },
  { value: 0.2, label: "0.2" },
  { value: 0.3, label: "0.3" },
  { value: 0.4, label: "0.4" },
  { value: 0.5, label: "0.5" },
  { value: 0.6, label: "0.6" },
  { value: 0.7, label: "0.7" },
  { value: 0.8, label: "0.8" },
  { value: 0.9, label: "0.9" },
  { value: 1.0, label: "1.0" },
];

const EvaluationOrganization = ({ data, open, handleClose }) => {
  const { idOrganizationObjective } = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { control, formState, handleSubmit } = useForm({
    defaultValues: {
      rating: 0,
      feedback: "",
      isSuccess: data.isSuccess ? "1" : "0",
    },
  });

  const onSubmit = async (submitData) => {
    const formData = { ...submitData };

    formData.isSuccess = formData.isSuccess === "1" ? 1 : 0;
    formData.evaluateDate = dayjs().format("YYYY-MM-DD HH:mm:ss");

    const res = await dispatch(
      updateOrganizationKeyResult(data.idOrganizationKeyResult, formData)
    );

    if (res && res.status === 200) {
      dispatch(
        openNotificationAlert({ type: "success", message: t("Successfully") })
      );
      await dispatch(getOrganizationObjective(idOrganizationObjective));
    } else {
      dispatch(
        openNotificationAlert({ type: "error", message: t("AnErrorOccurred") })
      );
    }
    handleClose();
  };

  const getStatusAchieve = (status) => {
    if (status === 1) {
      return 5;
    } else if (status === 0) {
      return 6;
    } else {
      return 4;
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{t("OrganizationalGoalEvaluation")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <Typography fontSize="20px">{data.keyResultName}</Typography>
            {data.isApprove !== null && (
              <Box display="flex" justifyContent="space-between">
                <ChipStatus
                  status={getStatusAchieve(data.isAchieve)}
                  size="large"
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <AccessTimeRounded
                fontSize="14px"
                style={{ marginRight: "8px", color: "#919eab" }}
              />
              <Typography
                fontSize="14px"
                lineHeight="1"
                color="text.third"
              >{`${dayjs(data.startDate).format("D MMM")} - ${dayjs(
                data.endDate
              ).format(
                i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
              )}`}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={2}>
                <Controller
                  name="isSuccess"
                  control={control}
                  render={({ field }) => (
                    <div>
                      <Typography fontSize={18}>{`${t(
                        "Evaluate"
                      )}`}</Typography>
                      <ToggleButtonGroup {...field} exclusive>
                        <ToggleButton value={"1"} color="success">{`${t(
                          "Success"
                        )}`}</ToggleButton>
                        <ToggleButton value={"0"} color="error">{`${t(
                          "Failure"
                        )}`}</ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  )}
                />
                <Controller
                  name="rating"
                  control={control}
                  render={({ field }) => (
                    <Box display="flex" gap="8px" padding="10px">
                      <Slider
                        max={1}
                        step={0.1}
                        valueLabelDisplay="auto"
                        marks={marks}
                        onChange={(_, newValue) => {
                          field.onChange(newValue);
                        }}
                      />
                      <i
                        className="fa-solid fa-stars"
                        style={{ color: "#f9df39" }}
                      ></i>
                    </Box>
                  )}
                />
                <Controller
                  name="feedback"
                  control={control}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      label={`${t("Feedback")}`}
                      multiline
                      rows={2}
                      inputProps={{ maxLength: 200 }}
                    />
                  )}
                />
                <Box display="flex" justifyContent="flex-end" alignItems="center" gap="8px">
                  <Button
                    type="button"
                    onClick={handleClose}
                  >
                    {t("Cancel")}
                  </Button>
                  <ResponsiveButtonBlue
                    variant="contained"
                    type="submit"
                    disabled={formState.isSubmitting}
                  >
                    {t("Evaluate")}
                  </ResponsiveButtonBlue>
                </Box>
              </Stack>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EvaluationOrganization;
