import React from "react";
import ReactDOM from "react-dom";
import html2canvas from "html2canvas";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";

const theme = createTheme({
  palette: {
    text: {
      primary: "#212b36",
      secondary: "#919eab",
      third: "#637381",
    },
  },
  typography: {
    h1: {
      color: "#212b36",
    },
    h2: {
      color: "#212b36",
    },
    h3: {
      color: "#212b36",
    },
    h4: {
      color: "#212b36",
    },
    h5: {
      color: "#212b36",
    },
    h6: {
      color: "#212b36",
    },
    body1: {
      color: "#212b36",
    },
    body2: {
      color: "#212b36",
    },
    fontFamily: [
      "Poppins",
      "Prompt",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export const renderComponentToCanvas = async (Component, props) => {
  const div = document.createElement("div");
  div.style.position = "fixed";
  div.style.left = "-9999px";
  document.body.appendChild(div);
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <Component {...props} />
    </ThemeProvider>,
    div
  );

  const canvas = await html2canvas(div, { scale: 2 });
  document.body.removeChild(div);
  return canvas;
};
