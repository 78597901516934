import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  TableCell,
  Chip,
  Grid,
  MenuItem,
} from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import CardStyle from "../../shared/general/Card";
import { getAssetManagementByIdEmployee } from "../../../../actions/assetManagement";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import MonthPickerCustom from "../../shared/date/monthPicker";

const StyledRoot = styled("div")({
  backgroundColor: "#f5f5f5",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const WrapHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
  marginTop: 8,
  marginBottom: 16,
});

const AssetManagement = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: assetManagement } = useSelector(
    (state) => state.assetManagement
  );

  const [filterReturnDate, setFilterReturnDate] = useState(null);
  const [filterReceiveDate, setFilterReceiveDate] = useState(null);
  const [filterStatus, setFilterStatus] = useState("all");
  const [filterAsset, setFilterAsset] = useState("");

  const handleChangeFilterStatus = (event) => {
    setFilterStatus(event.target.value);
  };

  const handleChangeFilterAsset = (event) => {
    setFilterAsset(event.target.value);
  };

  const filterData = () => {
    let dataFiltered = [...assetManagement];
    if (filterReturnDate) {
      dataFiltered = dataFiltered.filter((item) =>
        dayjs(item.returnDate).isSame(filterReturnDate, "month")
      );
    }
    if (filterReceiveDate) {
      dataFiltered = dataFiltered.filter((item) =>
        item.receivedDate === null || dayjs(item.receivedDate).isSame(filterReceiveDate, "month")
      );
    }
    if (filterStatus === 1) {
      dataFiltered = dataFiltered.filter((item) => item.receivedDate);
    }
    if (filterStatus === 0) {
      dataFiltered = dataFiltered.filter((item) => item.receivedDate === null);
    }
    if (filterAsset !== "") {
      dataFiltered = dataFiltered.filter(
        (item) => (item.idAsset).toLowerCase().includes(filterAsset.toLowerCase()) || (item.name).toLowerCase().includes(filterAsset.toLowerCase())
      );
    }

    return dataFiltered
  };

  useEffect(() => {
    dispatch(getAssetManagementByIdEmployee());
  }, []);

  return (
    <StyledRoot className={"page"}>
      <Container maxWidth="lg">
        <WrapHeader>
          <Typography variant="h4">{t("AppMenu.AssetManagement")}</Typography>
        </WrapHeader>
        <CardStyle style={{ padding: 16 }}>
          <Grid container spacing={2} marginBottom={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                fontSize="14px"
                fontWeight="600"
                color="text.third"
                marginBottom="4px"
                
              >
                {t("Status")}
              </Typography>
              <TextFieldTheme
                select
                fullWidth
                variant="filled"
                sx={{
                  "& .MuiSelect-filled": { padding: "16px" },
                }}
                value={filterStatus}
                onChange={handleChangeFilterStatus}
              >
                <MenuItem value={"all"}>{t("All")}</MenuItem>
                <MenuItem value={1}>{t("Returned")}</MenuItem>
                <MenuItem value={0}>{t("Haven'tReturned")}</MenuItem>
              </TextFieldTheme>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                fontSize="14px"
                fontWeight="600"
                marginBottom="4px"
                color="text.third"
              >
                {t("ReceivedDate")}
              </Typography>
              <MonthPickerCustom
                value={filterReceiveDate}
                onChange={(newValue) => setFilterReceiveDate(newValue)}
                views={["month", "year"]}
                minDate={dayjs().subtract(1, "year")}
                maxDate={dayjs().add(1, "year")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                fontSize="14px"
                fontWeight="600"
                marginBottom="4px"
                color="text.third"
              >
                {t("ReturnDueDate")}
              </Typography>
              <MonthPickerCustom
                value={filterReturnDate}
                onChange={(newValue) => setFilterReturnDate(newValue)}
                views={["month", "year"]}
                minDate={dayjs().subtract(1, "year")}
                maxDate={dayjs().add(1, "year")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                fontSize="14px"
                fontWeight="600"
                marginBottom="4px"
                color="text.third"
              >
                {`${t("AssetID")} ${t("or")} ${t("AssetName")}`}
              </Typography>
              <TextFieldTheme
                variant="filled"
                sx={{
                  "& .MuiInputBase-input": { padding: "16px" },
                }}
                value={filterAsset}
                onChange={handleChangeFilterAsset}
              />
            </Grid>
          </Grid>
          {assetManagement && assetManagement.length > 0 ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 600 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{`${t("AssetID")}`}</TableCell>
                    <TableCell align="center">{`${t("AssetName")}`}</TableCell>
                    <TableCell align="center">{`${t(
                      "ReceivedDate"
                    )}`}</TableCell>
                    <TableCell align="center">{`${t(
                      "ReturnDueDate"
                    )}`}</TableCell>
                    <TableCell align="center">{`${t("Status")}`}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterData().map((item) => (
                    <TableRow key={item.idAssetManagement}>
                      <TableCell align="center">{item.idAsset}</TableCell>
                      <TableCell align="center">{item.name}</TableCell>
                      <TableCell align="center">
                        {dayjs(item.receivedDate).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {item.returnDate
                          ? dayjs(item.returnDate).format("DD/MM/YYYY")
                          : `${t("Not_Specified")}`}
                      </TableCell>
                      <TableCell align="center">
                        {item.receivedDate ? (
                          <Chip label={`${t("Returned")}`} color="success" />
                        ) : (
                          <Chip
                            label={`${t("Haven'tReturned")}`}
                            color="error"
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>{`${t("NoItems")}`}</Typography>
          )}
        </CardStyle>
      </Container>
    </StyledRoot>
  );
};

export default AssetManagement;
