import React from "react";
// import CompanyProfile from "../-_Company profile.png";
import StatusApprove from "../-_Document management.png";
import EmployeeCoreDate from "../-_Employee Core Data.png";
import SourcingRequest from "../-_Sourcing & Recruitment.png";
import Organization from "../-_Organization Management.png";
import Payroll from "../-_Payroll.png";
import TimeManagement from "../-_Time Management.png";
import Manpower from "../-_Cloud workforce .png";
import ShiftManage from "../-_Temporary Job.png";
// import Welfare from "../-_Welfare.png";
import Performance from "../-_performance management.png";
import Dashboard from "../-_dashboard.png";
// import Leave from "../-_leave.png";
import Approve from "../-_approval list.png";
import OT from "../-_overtime .png";
import PaySlip from "../-_pay slip.png";
import UniCulture from "../UniCulture.png";
import Learning from "../Uni Learning.png";
import GA from "../Uni GA.png";
import UniAdmin from "../Uni_Admin.png";
// import Competency from "../Competency_Management.png";
import Workforce from "../workforce.png";
// import JobManagement from "../Job_Management.png";
// import Talent from "../Talent&Successor.png";
// import Successor from "../-_successor.png";
// import CareerManagement from "../Career_Management.png";
// import EmployeeNetwork from "../Employee_Network.png";
// import ProvidentFund from "../-_ProvidentFund.png";
import ProvidentFund from "../ProvidentFund.png";
// import MyDocument from "../-_Branding & Communication.png";
// import Announcement from "../announcement.jpg";
import OnBoarding from "../on-boarding.png";
import OffBoarding from "../off-boarding.png";
// import UniRecruit from "../Uni-recruit.png";
import UniRecruit from "../UniRecruit.png";
// import EmployeeProfile from "../Employee-Profile.png";
import CompareProfile from "../CompareProfile.png";
import PersonalTax from "../-_Accounting.png";
import UniLearning from "../Uni_Learning.png";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import Groups2RoundedIcon from "@mui/icons-material/Groups2Rounded";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import TimelapseRoundedIcon from "@mui/icons-material/TimelapseRounded";
import ReceiptLongRoundedIcon from "@mui/icons-material/ReceiptLongRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import ContactPageRoundedIcon from "@mui/icons-material/ContactPageRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import EngineeringRoundedIcon from "@mui/icons-material/EngineeringRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import ElderlyRoundedIcon from "@mui/icons-material/ElderlyRounded";
import EmojiObjectsRoundedIcon from "@mui/icons-material/EmojiObjectsRounded";
import FolderSharedRoundedIcon from "@mui/icons-material/FolderSharedRounded";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import RequestQuoteRoundedIcon from "@mui/icons-material/RequestQuoteRounded";
import AppRegistrationRoundedIcon from "@mui/icons-material/AppRegistrationRounded";
import Icon from "@mui/material/Icon";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonRemoveAlt1Icon from "@mui/icons-material/PersonRemoveAlt1";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import WorkIcon from "@mui/icons-material/Work";
import FeedbackIcon from "@mui/icons-material/Feedback";
import WidgetsIcon from "@mui/icons-material/Widgets";
import SourcingRecruitment from "../-_Sourcing & Recruitment.png";
// import TalentCover from "../-_Talent _ Successor.png"
import SuccessorCover from "../-_successor.png";
// import OKRs from "../-_okr.png"
import Overall from "../1_overall.png";
import StatusList from "../2_statusList.png";
import EmployeeNetwork from "../EmployeeNetwork.png";
import TaxDeduction from "../taxDeduction.png";
import ManageShift from "../manageShift.png";
import AssetManagement from "../Asset_management.png";
import CompanyProfile from "../companyProfile.png";
import WorkingTime from "../workingTime.png";
import WorkingTime2 from "../workingTime2.png";
import TravelTime from "../TravelTime.png";
import Menpower from "../Menpower.png";
import OrganizationList from "../organisationList.png";
import SystemInfo from "../systemInfo.png";
import Report from "../report.png";
import Timetable from "../TimeTable.png";
import Employees from "../employees.png";
import PunishmentReport from "../warning_punishment_report.png";
import Announcement from "../announcement.png";
import Welfare from "../welfare.png";
import Leave from "../leave.png";
import SummaryOtShift from "../summaryOTAndshift.png";
import Expense from "../Expenses.png";
import KPImanagement from "../KPImanagement.png";
import MyDocument from "../myDocument.png";
import GovernmentReport from "../Government_Report.png";
import ProjectProfile from "../ProjectProfile.png";
import PayrollManagement from "../payrollManagement.png";
import OKR from "../OKRs.png";
import JobProfile from "../Job_Profile.png";
import WaitingApprove from "../waitingApprove.png";
import Payslip from "../payslip.png";
import ProfileDocument from "../EmployeeProfilePort.png";
import TalentCover from "../Talent.png";
import CareerManagement from "../Career.png";
import ChatBot from "../Chatbot.png";
import Successor from "../successor.png";
import EmployeeProfile from "../EmployeeProfile.png";
import userDocument from "../userDocument.png";
import ShiftSchedule from "../ShiftSchedule.png";
import JobManagement from "../Job_Management.png";
import Assessment from "../assessment.png";

const user = JSON.parse(localStorage.getItem("user"));

export const standardMenu = [
  {
    name: "มุมมองภาพรวม",
    nameTrans: "AppMenu.Overview",
    image: Overall,
    link: "/overview",
    key: "dashboard",
    icon: <HomeRoundedIcon />,
  },
  {
    name: "มุมมองภาพรวม",
    nameTrans: "AppMenu.Overview",
    image: Overall,
    link: "/overview-user",
    key: "overviewUser",
    icon: <HomeRoundedIcon />,
  },
  {
    name: "ประวัติส่วนตัว",
    nameTrans: "AppMenu.Profile",
    image: CompanyProfile,
    link: "/profile",
    key: "profile",
    icon: <AssignmentIndRoundedIcon />,
  },
  {
    name: "สถานะรายการ",
    nameTrans: "AppMenu.RequestList",
    image: StatusList,
    link: "/request-list",
    key: "approveList",
    icon: <ListAltRoundedIcon />,
  },
  {
    name: "รออนุมัติ",
    nameTrans: "PendingApproval",
    image: WaitingApprove,
    link: "/waiting-approve",
    icon: <ListAltRoundedIcon />,
    key: "waitingApprove",
  },
  {
    name: "พนักงาน",
    nameTrans: "AppMenu.Employees",
    image: Employees,
    link: "/employees",
    icon: <Groups2RoundedIcon />,
    key: "employee",
  },

  {
    name: "เวลาทำงาน",
    nameTrans: "AppMenu.TimeAttendance",
    image: WorkingTime2,
    link: "/time-attendance",
    icon: <TimelapseRoundedIcon />,
    key: "timeAttendance",
  },
  {
    name: "ตารางเวลาทำงาน",
    nameTrans: "AppMenu.Timeline",
    image: Timetable,
    link: "/timeline",
    icon: <TimelapseRoundedIcon />,
    key: "timeline",
  },
  {
    name: "ตารางกะของฉัน",
    nameTrans: "ShiftSchedule",
    image: ShiftSchedule,
    link: "/time-schedule",
    icon: <ListAltRoundedIcon />,
    key: "myShift",
  },
  {
    name: "ลางาน",
    nameTrans: "AppMenu.Leave",
    image: Leave,
    link: "/leave",
    icon: (
      <Icon
        style={{ fontSize: 20 }}
        baseClassName="fa-regular"
        className="fa-crab"
      />
    ),
    key: "leave",
  },
  {
    name: "สรุป OT และค่ากะ",
    nameTrans: "AppMenu.SummaryTime",
    image: SummaryOtShift,
    link: "/summary-time",
    icon: (
      <Icon
        style={{ fontSize: 20 }}
        baseClassName="fa-regular"
        className="fa-hourglass-clock"
      />
    ),
    key: "summaryOT",
  },
  {
    name: "กำลังพล",
    nameTrans: "AppMenu.Manpower",
    image: Menpower,
    link: "/admin/manpower",
    icon: <GroupsRoundedIcon />,
    key: "manpower",
  },
  {
    name: "จัดการกะ",
    nameTrans: "AppMenu.ShiftManage",
    image: ManageShift,
    link: "/admin/shift",
    icon: <EngineeringRoundedIcon />,
    key: "shiftManagement",
  },
  {
    name: "ผังองค์กร",
    nameTrans: "AppMenu.Organization",
    image: OrganizationList,
    link: "/admin/organization",
    icon: <AccountTreeRoundedIcon />,
    key: "organization",
  },
  {
    name: "จัดการเงินเดือน",
    nameTrans: "AppMenu.Payrollrun",
    image: PayrollManagement,
    link: "/payroll",
    icon: <ReceiptLongRoundedIcon />,
    key: "payroll",
  },
  {
    name: "การจ้างงาน",
    nameTrans: "AppMenu.SourcingRequest",
    image: SourcingRequest,
    link: "/admin/request",
    icon: <GroupAddRoundedIcon />,
    key: "sourcing",
  },
  {
    name: "แฟ้มประวัติ",
    nameTrans: "AppMenu.MyDocuments",
    image: ProfileDocument,
    link: "/employees-documents",
    icon: <FolderSharedRoundedIcon />,
    key: "document",
  },
  {
    name: "แฟ้มประวัติบริษัท",
    nameTrans: "CompanyDocuments",
    image: JobManagement,
    link: "/company-documents",
    icon: <FolderSharedRoundedIcon />,
    key: "CompanyDocument",
  },
  {
    name: "สร้างเอกสาร",
    nameTrans: "Smart Documents",
    image: Approve,
    link: "/smart-documents",
    icon: <FolderSharedRoundedIcon />,
    key: "smartDocument",
  },
  {
    name: "บริการเอกสาร",
    nameTrans: "Document",
    image: userDocument,
    link: "/document-service",
    icon: <ContactPageRoundedIcon />,
    key: "documentService",
  },
  {
    name: "เอกสารของพนักงาน",
    nameTrans: "EmployeeDocuments",
    image: userDocument,
    link: "/employee-document",
    icon: <ContactPageRoundedIcon />,
    key: "employeeDocument",
  },
  {
    name: "รายงาน",
    nameTrans: "AppMenu.MyReports",
    image: Report,
    link: "/employees-report",
    icon: <AssessmentRoundedIcon />,
    key: "report",
  },
  {
    name: "ผู้ดูแลระบบ",
    nameTrans: "AppMenu.Settings",
    image: SystemInfo,
    link: "/admin/setting",
    icon: <TuneRoundedIcon />,
    key: "setting",
  },
  {
    name: "ประวัติบริษัท",
    nameTrans: "AppMenu.CompanyProfile",
    image: CompanyProfile,
    link: "/admin/company-profile",
    icon: <BusinessCenterRoundedIcon />,
    key: "companyProfile",
  },
  {
    name: "สลิปเงินเดือน",
    nameTrans: "AppMenu.PaySlip",
    image: Payslip,
    link: "/pay-slip",
    icon: <Icon baseClassName="fa-regular" className="fa-file-invoice" />,
    key: "paySlip",
  },
  {
    name: "รายงานราชการ",
    nameTrans: "GovernmentReport",
    image: GovernmentReport,
    link: "/government-report",
    key: "governmentReport",
  },
  {
    name: "หักลดหย่อนภาษี",
    nameTrans: "TaxDeductible",
    image: TaxDeduction,
    link: "/tax-deduction",
    icon: <EmojiObjectsRoundedIcon />,
    key: "taxDeduction",
  },
  {
    name: "กองทุนสำรองเลี้ยงชีพ",
    nameTrans: "ProvidentFund",
    image: ProvidentFund,
    link: "/profile#providentFund",
    icon: <ElderlyRoundedIcon />,
    key: "pf",
  },
  {
    name: "การประเมิน",
    nameTrans: "Assessment",
    icon: <AutoAwesomeRoundedIcon />,
    image: Assessment,
    link: "/assessment",
    key: "assessment",
  },
  {
    name: "OKRs",
    nameTrans: "AppMenu.OKRs",
    icon: <AutoAwesomeRoundedIcon />,
    image: OKR,
    link: "/okrs",
    key: "okr",
  },
  {
    name: "KPI",
    nameTrans: "AppMenu.KPI",
    icon: <AutoAwesomeRoundedIcon />,
    image: KPImanagement,
    link: "/kpi",
    key: "kpi",
  },
  {
    name: "e-memo",
    nameTrans: "e-memo",
    icon: <AutoAwesomeRoundedIcon />,
    image: userDocument,
    link: "/e-memo",
    key: "kpi",
  },
  {
    name: "My Team Report",
    nameTrans: "teamReport",
    image: Report,
    icon: <AssessmentRoundedIcon />,
    link: "/employees-report",
    key: "teamReport",
  },
  {
    name: "ประกาศคำสั่งและ\nนโยบายบริษัท",
    nameTrans: "AppMenu.Announcements",
    icon: <CampaignRoundedIcon />,
    image: Announcement,
    link: "/announcement",
    key: "announcement",
  },
  {
    name: "บันทึกการเดินทาง",
    nameTrans: "AppMenu.AccessArea",
    icon: <TimelapseRoundedIcon />,
    image: TravelTime,
    link: "/access-area",
    key: "accessArea",
  },
  {
    name: "บันทึกการทำงาน",
    nameTrans: "AppMenu.AccessTime",
    icon: <TimelapseRoundedIcon />,
    image: WorkingTime,
    link: "/access-time",
    key: "accessTime",
  },
  {
    name: "On boarding",
    nameTrans: "AppMenu.OnBoarding",
    icon: <PersonAddAlt1Icon />,
    image: OnBoarding,
    link: "/on-boarding",
    key: "onBoarding",
  },
  {
    name: "Off boarding",
    nameTrans: "AppMenu.OffBoarding",
    icon: <PersonRemoveAlt1Icon />,
    image: OffBoarding,
    link: "/off-boarding",
    key: "offBoarding",
  },
  {
    name: "ผู้ตรวจสอบ",
    icon: <VerifiedUserIcon />,
    image: OffBoarding,
    link: "/boarding",
    key: "boarding",
  },
  {
    name: "Uni Recruit",
    nameTrans: "AppMenu.UniRecruit",
    icon: <WorkIcon />,
    image: UniRecruit,
    link: "/recruitment",
    key: "recruitment",
  },
  {
    name: "Uni Culture",
    nameTrans: "AppMenu.UniCulture",
    icon: <WorkIcon />,
    image: UniCulture,
    link: "https://uniculture.co",
    key: "culture",
    newTab: true,
  },
  {
    name: "Uni Learning",
    nameTrans: "AppMenu.UniLearning",
    icon: <WorkIcon />,
    image: UniLearning,
    link: "https://unilearning.co",
    key: "learning",
    newTab: true,
  },
  {
    name: "Uni Admin",
    nameTrans: "AppMenu.UniAdmin",
    icon: <WorkIcon />,
    image: UniAdmin,
    link: user && user.accessToken ? `https://uniadmin.co/login?session=${user.accessToken}`: `https://uniadmin.co/login`,
    key: "admin",
    newTab: true,
    as: user && user.accessToken ? user.accessTokeno : ""
  },
  {
    name: "หนังสือเตือนและ\nการลงโทษ",
    nameTrans: "AppMenu.WarningLetter",
    icon: <FeedbackIcon />,
    image: PunishmentReport,
    link: "/warning-letter",
    key: "warningLetter",
  },
  {
    name: "Asset management",
    nameTrans: "AppMenu.AssetManagement",
    icon: <WidgetsIcon />,
    image: AssetManagement,
    link: "/asset-management",
    key: "assetManagement",
  },
  {
    name: "Employee Network",
    nameTrans: "AppMenu.EmployeeNetwork",
    image: EmployeeNetwork,
    link: "/employees_network",
    key: "employeeNetwork",
  },
  {
    name: "Employee Profile",
    nameTrans: "EmployeeProfile",
    image: EmployeeProfile,
    link: "/employees-profile",
    key: "employeeProfile",
  },
  {
    name: "Compare Profile",
    nameTrans: "CompareProfile",
    image: CompareProfile,
    link: "/admin/ComparePerson",
    key: "employeeCompare",
  },
  {
    name: "Talent",
    nameTrans: "Talent",
    image: TalentCover,
    link: "/admin/talent",
    key: "Talent",
  },
  {
    name: "Successor",
    nameTrans: "AppMenu.Successor",
    image: Successor,
    link: "/successor",
    key: "successor",
  },
  {
    name: "Personality Character Test",
    nameTrans: "PersonalityCharacterTest",
    image: SourcingRecruitment,
    link: "/personality-character-test",
    key: "personalityCharacterTest",
  },
  {
    name: "สิทธิสวัสดิการ",
    nameTrans: "AppMenu.Welfare",
    image: Welfare,
    icon: <AppRegistrationRoundedIcon />,
    link: "/welfare-rights",
    key: "welfareRights",
  },
  {
    name: "เบิกค่าใช้จ่าย",
    nameTrans: "AppMenu.Expense",
    image: Expense,
    icon: <RequestQuoteRoundedIcon />,
    link: "/expense",
    key: "expensePayment",
  },
  {
    name: "เบิกสวัสดิการ",
    nameTrans: "AppMenu.ClaimingBenefits",
    image: PaySlip,
    icon: Performance,
    link: "/welfare-expense",
    key: "welfareExpense",
  },
  {
    name: "รายงาน Expense",
    nameTrans: "AppMenu.ExpenseExport",
    image: MyDocument,
    icon: <DescriptionRoundedIcon />,
    link: "/expense-export",
    key: "expenseExport",
  },
  {
    name: "Workforce Employees",
    nameTrans: "AppMenu.WorkforceEmployees",
    image: Workforce,
    icon: <i className="fa-sharp fa-regular fa-bullseye-arrow" />,
    link: "/workforce-planning-cal",
    key: "workforceEmployees",
  },
  {
    name: "Workforce",
    nameTrans: "AppMenu.Workforce",
    image: Workforce,
    icon: <i className="fa-sharp fa-regular fa-bullseye-arrow" />,
    link: "/workforceInsertTime",
    key: "workforce",
  },
  {
    name: "myCareerPlan",
    nameTrans: "MyCareerPath",
    image: CareerManagement,
    icon: <i className="fa-sharp fa-regular fa-bullseye-arrow" />,
    link: "/myCareerPlan",
    key: "careerManagement",
  },
  {
    name: "Career Plan",
    nameTrans: "CareerPath",
    image: CareerManagement,
    icon: <i className="fa-sharp fa-regular fa-bullseye-arrow" />,
    link: "/admin/career",
    key: "adminCareerManagement",
  },
  {
    name: "Career Plan",
    nameTrans: "CareerPath",
    image: CareerManagement,
    icon: <i className="fa-sharp fa-regular fa-bullseye-arrow" />,
    link: "/manager/career",
    key: "managerCareerManagement",
  },
  {
    name: "Project",
    nameTrans: "Project",
    image: ProjectProfile,
    icon: <i className="fa-sharp fa-regular fa-bullseye-arrow" />,
    link: "/project",
    key: "empProject",
  },
  {
    name: "Job Profile",
    nameTrans: "jobProfile",
    image: JobProfile,
    icon: <i className="fa-sharp fa-regular fa-bullseye-arrow" />,
    link: "/job-profile",
    key: "jobProfile",
  },
  {
    name: "Workforce Employees",
    nameTrans: "AppMenu.WorkforceEmployees",
    image: Workforce,
    icon: <i className="fa-sharp fa-regular fa-bullseye-arrow" />,
    link: "/workforce-planning-cal/tableHour",
    key: "workforceManager",
  },
  {
    name: "Jarvis Chatbot",
    nameTrans: "Jarvis Chatbot",
    image: ChatBot,
    icon: <i className="fa-sharp fa-regular fa-bullseye-arrow" />,
    link: "/jarvis-chatbot",
    key: "chatBot",
  },
  {
    name: "My Personal Tax",
    nameTrans: "AppMenu.MyPersonalTax",
    image: Expense,
    icon: <i className="fa-sharp fa-regular fa-bullseye-arrow" />,
    link: "/personal-tax",
    key: "personalTax",
  }
];

export const menuAppUser = [
  {
    id: 1,
    name: "ประวัติส่วนตัว",
    icon: CompanyProfile,
    link: "/profile",
    key: "ROLE_USER",
    class: "normal",
  },
  {
    id: 2,
    name: "ตารางเวลาทำงาน",
    icon: ShiftManage,
    link: "/timeline",
    key: "ROLE_USER",
    class: "normal",
  },
  {
    id: 11,
    name: "จัดการเวลาทำงาน",
    icon: TimeManagement,
    link: "/time-schedule",
    key: "ROLE_USER",
    class: "normal",
  },
  {
    id: 3,
    name: "ลางาน",
    icon: Leave,
    link: "/leave",
    key: "ROLE_USER",
    class: "normal",
  },
  {
    id: 4,
    name: "สถานะรายการ",
    nameTrans: "RequestList",
    icon: Approve,
    link: "/requestlist",
    key: "ROLE_USER",
    class: "normal",
  },
  {
    id: 5,
    name: "สรุป OT และ ค่ากะ",
    icon: SummaryOtShift,
    link: "/summary-time",
    key: "ROLE_USER",
    class: "normal",
  },
  {
    id: 6,
    name: "สลิปเงินเดือน",
    icon: PaySlip,
    link: "/pay-slip",
    key: "ROLE_USER",
    class: "normal",
  },
  {
    id: 11,
    name: "OKRs",
    icon: CareerManagement,
    link: "/okrs",
    key: "ROLE_USER",
    class: "normal",
  },
  {
    id: 8,
    name: "หักลดหย่อนภาษี",
    icon: Payroll,
    link: "/tax-deduction",
    key: "ROLE_USER",
    class: "gray",
  },
  {
    id: 9,
    name: "กองทุนสำรองเลี้ยงชีพ",
    icon: ProvidentFund,
    link: "/profile",
    key: "ROLE_USER",
    class: "gray",
    state: {
      tab: "providentFund",
    },
  },
  {
    id: 10,
    name: "เอกสารของฉัน",
    icon: MyDocument,
    link: "/my-document",
    key: "ROLE_USER",
    class: "normal",
  },
  {
    id: 11,
    name: "Personality Character Test",
    icon: SourcingRecruitment,
    link: "/personality-character-test",
    role: "ROLE_USER",
    class: "normal",
  },
];

export const menuAppAdmin = [
  {
    id: 1,
    name: "มุมมองภาพรวม",
    icon: Dashboard,
    link: "/admin/overview",
    key: "ROLE_ADMIN",
  },
  {
    id: 2,
    name: "สถานะรายการ",
    icon: StatusApprove,
    link: "/admin/approve-list",
    key: "ROLE_ADMIN",
  },
  {
    id: 3,
    name: "พนักงาน",
    icon: EmployeeCoreDate,
    link: "/employees",
    key: "ROLE_ADMIN",
  },
  {
    id: 4,
    name: "เวลาทำงาน",
    icon: TimeManagement,
    link: "/admin/time",
    key: "ROLE_ADMIN",
  },
  {
    id: 5,
    name: "กำลังพล",
    icon: Manpower,
    link: "/admin/manpower",
    key: "ROLE_ADMIN",
  },
  {
    id: 6,
    name: "จัดการกะ",
    icon: ShiftManage,
    link: "/admin/shift",
    key: "ROLE_ADMIN",
  },
  {
    id: 7,
    name: "ผังองค์กร",
    icon: Organization,
    link: "/admin/organization",
    key: "ROLE_ADMIN",
  },
  {
    id: 8,
    name: "จัดการเงินเดือน",
    icon: Payroll,
    link: "/admin/payroll/run",
    key: "ROLE_ADMIN",
  },
  {
    id: 9,
    name: "การจ้างงาน",
    icon: SourcingRequest,
    link: "/admin/request",
    key: "ROLE_ADMIN",
  },
  // {
  //   id: 10,
  //   name: "Job Management",
  //   icon: JobManagement,
  //   link: "/admin/jobStructure",
  //   key: "ROLE_ADMIN",
  // },
  // {
  //   id: 11,
  //   name: "Workforce",
  //   icon: Workforce,
  //   link: "/admin/workforceDashboard",
  //   key: "ROLE_ADMIN",
  // },
  // {
  //   id: 12,
  //   name: "Competency",
  //   icon: Competency,
  //   link: "",
  //   key: "ROLE_ADMIN",
  // },
  {
    id: 13,
    name: "อบรม",
    icon: SourcingRequest,
    link: "/admin/courses",
    key: "ROLE_ADMIN",
  },
  // {
  //   id: 14,
  //   name: "Talent",
  //   icon: Talent,
  //   link: "/admin/courses",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 15,
  //   name: "Successor",
  //   icon: Successor,
  //   link: "/admin/courses",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 16,
  //   name: "Career Management",
  //   icon: CareerManagement,
  //   link: "/admin/courses",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 17,
  //   name: "Benefits",
  //   icon: Benefits,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 18,
  //   name: "Health",
  //   icon: Health,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 19,
  //   name: "Coaching",
  //   icon: Coaching,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 20,
  //   name: "Performance",
  //   icon: Performance,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 21,
  //   name: "Onboarding",
  //   icon: Onboarding,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 22,
  //   name: "Employee Network",
  //   icon: EmployeeNetwork,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },

  {
    id: 25,
    name: "เอกสารของพนักงาน",
    icon: MyDocument,
    link: "/employees-documents",
    key: "ROLE_ADMIN",
  },
  {
    id: 24,
    name: "ข้อมูลระบบ",
    icon: Welfare,
    link: "/admin/setting",
    key: "ROLE_ADMIN",
    premium: false,
  },
  {
    id: 23,
    name: "ประวัติบริษัท",
    icon: CompanyProfile,
    link: "/admin/company-profile",
    key: "ROLE_ADMIN",
    premium: false,
  },
  // {
  //   id: 25,
  //   name: "เอกสารของพนักงาน",
  //   icon: MyDocument,
  //   link: "admin/employees-documents",
  //   key: "ROLE_ADMIN"
  // },
  {
    id: 26,
    name: "สรุป OT และค่ากะ",
    icon: OT,
    link: "/employees-summary-time",
    key: "ROLE_ADMIN",
  },
  {
    id: 27,
    name: "OKRs",
    icon: CareerManagement,
    link: "/okrs",
    key: "ROLE_ADMIN",
  },
  {
    id: 28,
    name: "ประกาศ",
    icon: Announcement,
    link: "/announcement",
    key: "ROLE_ADMIN",
  },
];

export const menuAppAdminHomesolution = [
  {
    id: 1,
    name: "มุมมองภาพรวม",
    icon: Dashboard,
    link: "/admin/overview",
    key: "ROLE_ADMIN",
  },
  {
    id: 2,
    name: "สถานะรายการ",
    icon: StatusApprove,
    link: "/admin/approve-list",
    key: "ROLE_ADMIN",
  },
  {
    id: 3,
    name: "พนักงาน",
    icon: EmployeeCoreDate,
    link: "/employees",
    key: "ROLE_ADMIN",
  },
  {
    id: 4,
    name: "เวลาทำงาน",
    icon: TimeManagement,
    link: "/admin/time",
    key: "ROLE_ADMIN",
  },
  {
    id: 5,
    name: "กำลังพล",
    icon: Manpower,
    link: "",
    key: "ROLE_ADMIN",
    class: "gray",
  },
  {
    id: 6,
    name: "จัดการกะ",
    icon: ShiftManage,
    link: "/admin/shift",
    key: "ROLE_ADMIN",
  },
  {
    id: 7,
    name: "ผังองค์กร",
    icon: Organization,
    link: "",
    key: "ROLE_ADMIN",
    class: "gray",
  },
  {
    id: 8,
    name: "จัดการเงินเดือน",
    icon: Payroll,
    link: "",
    key: "ROLE_ADMIN",
    class: "gray",
  },
  {
    id: 9,
    name: "การจ้างงาน",
    icon: SourcingRequest,
    link: "",
    key: "ROLE_ADMIN",
    class: "gray",
  },
  // {
  //   id: 10,
  //   name: "Job Management",
  //   icon: JobManagement,
  //   link: "/admin/jobStructure",
  //   key: "ROLE_ADMIN",
  // },
  // {
  //   id: 11,
  //   name: "Workforce",
  //   icon: Workforce,
  //   link: "/admin/workforceDashboard",
  //   key: "ROLE_ADMIN",
  // },
  // {
  //   id: 12,
  //   name: "Competency",
  //   icon: Competency,
  //   link: "",
  //   key: "ROLE_ADMIN",
  // },
  {
    id: 13,
    name: "อบรม",
    icon: SourcingRequest,
    link: "",
    key: "ROLE_ADMIN",
    class: "gray",
  },
  // {
  //   id: 14,
  //   name: "Talent",
  //   icon: Talent,
  //   link: "/admin/courses",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 15,
  //   name: "Successor",
  //   icon: Successor,
  //   link: "/admin/courses",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 16,
  //   name: "Career Management",
  //   icon: CareerManagement,
  //   link: "/admin/courses",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 17,
  //   name: "Benefits",
  //   icon: Benefits,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 18,
  //   name: "Health",
  //   icon: Health,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 19,
  //   name: "Coaching",
  //   icon: Coaching,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 20,
  //   name: "Performance",
  //   icon: Performance,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 21,
  //   name: "Onboarding",
  //   icon: Onboarding,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 22,
  //   name: "Employee Network",
  //   icon: EmployeeNetwork,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },

  {
    id: 25,
    name: "เอกสารของพนักงาน",
    icon: MyDocument,
    link: "/employees-documents",
    key: "ROLE_ADMIN",
  },
  {
    id: 24,
    name: "ข้อมูลระบบ",
    icon: Welfare,
    link: "/admin/setting",
    key: "ROLE_ADMIN",
    premium: false,
  },
  {
    id: 23,
    name: "ประวัติบริษัท",
    icon: CompanyProfile,
    link: "/admin/company-profile",
    key: "ROLE_ADMIN",
    premium: false,
  },
  // {
  //   id: 25,
  //   name: "เอกสารของพนักงาน",
  //   icon: MyDocument,
  //   link: "admin/employees-documents",
  //   key: "ROLE_ADMIN"
  // },
  {
    id: 26,
    name: "สรุป OT และค่ากะ",
    icon: OT,
    link: "/employees-summary-time",
    key: "ROLE_ADMIN",
  },
  {
    id: 28,
    name: "รายงาน",
    icon: MyDocument,
    link: "/employees-report",
    key: "ROLE_ADMIN",
  },
];

export const menuAppManager = [
  {
    id: 1,
    name: "มุมมองภาพรวม",
    icon: Performance,
    link: "/manager/overview",
    key: "ROLE_MANAGER",
    class: "normal",
  },
  {
    id: 2,
    name: "รออนุมัติ",
    icon: Performance,
    link: "/manager/approve",
    key: "ROLE_MANAGER",
    class: "normal",
  },
  {
    id: 3,
    name: "พนักงาน",
    icon: EmployeeCoreDate,
    link: "/manager/employee",
    key: "ROLE_MANAGER",
    class: "normal",
  },
  {
    id: 4,
    name: "เวลาทำงาน",
    icon: TimeManagement,
    link: "/manager/time",
    key: "ROLE_MANAGER",
    class: "normal",
  },
];

export const menuAppAdminAccountant = [
  {
    id: 1,
    name: "เบิกค่าใช้จ่าย",
    image: Expense,
    icon: Performance,
    link: "/expense",
    key: "expensePayment",
    class: "normal",
  },
];
