import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import XLSX from "xlsx";
import AlertResponse from "../../shared/general/AlertResponse";
import DrawerCustom from "../../shared/general/Drawer";
import DialogSelectDate from "./component/dialogWithSelectDate";
import { getEmailReportById } from "../../../../actions/email";
import { getNewemployees } from "../../../../actions/report";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const Newemployee = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: email } = useSelector((state) => state.email);
  const { result: newEmployee } = useSelector((state) => state.newEmployee);
  const { open, handleClose } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [data, setData] = useState({
    inputSearch: new Date(),
    value: "year",
    autoEmail: null,
    selectedCompany: null,
    ReportName: "newemployee",
    idEmployees: userProfile.idEmployees,
  });

  useEffect(() => {
    if (email) setData({ ...data, autoEmail: email.day });
  }, [email]);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  useEffect(() => {
    dispatch(getEmailReportById(data.idEmployees, "newemployee"));
  }, []);

  useEffect(() => {
    if (data.value === "year") {
      dispatch(
        getNewemployees(
          data.selectedCompany,
          dayjs(data.inputSearch).endOf("month")
        )
      );
    } else
      dispatch(
        getNewemployees(
          data.selectedCompany,
          dayjs(data.inputSearch).startOf("month")
        )
      );
  }, [data.inputSearch, data.selectedCompany, data.value]);

  const handleSubmit = () => {
    if (newEmployee.length > 0) {
      const elementList = [];
      for (let index = 0; index < newEmployee.length; index++) {
        const element = {
          "#": index + 1,
          รหัสพนักงาน: newEmployee[index].employeeID,
          ชื่อ: newEmployee[index].firstname_TH,
          สกุล: newEmployee[index].lastname_TH,
          Division: newEmployee[index].divisionName,
          Department: newEmployee[index].departmentName,
          Section: newEmployee[index].sectionName,
          "Unit/Process": newEmployee[index].positionName,
          ประเภทพนักงาน: newEmployee[index].employmentTime,
          วันที่เข้างาน: dayjs(newEmployee[index].hiringDate).format(
            "YYYY-MM-DD"
          ),
        };
        elementList.push(element);
      }
      const workSheet = XLSX.utils.json_to_sheet(elementList);
      const workBook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(
        workBook,
        workSheet,
        t("SummaryOfNewEmployees")
      );
      XLSX.writeFile(workBook, `${t("SummaryOfNewEmployees")}.xlsx`);

      handleChangeAlertType("success");
      handleOpenAlert(true);
      handleClose();
    } else {
      handleChangeAlertType(t("NoData"));
      handleOpenAlert(true);
    }
  };

  return (
    <DrawerCustom
      title={`${t("SummaryOfNewEmployees")}`}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <DialogSelectDate
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        setData={setData}
        data={data}
        handleChangeAlertType={handleChangeAlertType}
        handleOpenAlert={handleOpenAlert}
        select={true}
        excel={newEmployee}
      />
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </DrawerCustom>
  );
};

export default Newemployee;
