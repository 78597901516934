import React, { useEffect, useRef, useState } from 'react';
import {
  Card, CardContent, Typography, Grid, Autocomplete,
  Box, Container, MenuItem, styled, TextField,
  Modal,
  IconButton,
  Button,
} from '@mui/material';
import GaugeChart from 'react-gauge-chart';
import CardStyle from "../../../../shared/general/Card";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import dayjs from "dayjs";
import SelectAffiliateCompany from "../../../../shared/general/SelectAffiliateCompany";
import ButtonBlue from '../../../../shared/general/ButtonBlue';
import CloseIcon from '@mui/icons-material/Close';

import { useDispatch } from 'react-redux';
import { getAffiliateCompany } from '../../../../../../actions/affiliate';
import { getDepartment } from '../../../../../../actions/department';
import { getCompanyOrganizationList } from '../../../../../../actions/company';
import { getDashboardCost, getDashboardOvertime } from '../../../../../../actions/dashboard';
import { getOT } from '../../../../../../actions/overviewUser';
import { WorkOutlineOutlined } from '@mui/icons-material';

const StyledRoot = styled(Box)({
  "& .filter-container": { display: "flex", justifyContent: "flex-end", gap: "8px", "& .MuiInputBase-root": { maxHeight: "48px" } },
  "& .menu-container": { boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px", borderRadius: 20, backgroundColor: "#ffffff", width: "fit-content", overflow: "hidden" },
  "& .MuiSelect-filled": { padding: "8px 32px 8px 12px" }, "& .MuiFilledInput-root": { paddingBottom: '8px !important', paddingTop: '8px !important' }
});

const HeaderBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  padding: '16px 0 32px 0',
}));

const InnerHeaderBox = styled(Box)(() => ({
  borderBottom: '2px solid black',
  padding: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

function OT({ t, i18n, OrganizationList, affiliateList, userProfile, dashboardCost, overviewUserOt, setIsLoadingOT }) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [date, setDate] = useState({
    month: dayjs().get("month") + 1,
    year: dayjs().get("year"),
  });
  const [filters, setFilters] = useState({
    company: null,
    department: { idDepartment: userProfile.idDepartment ? userProfile.idDepartment : "all", departmentName: userProfile.idDepartment ? i18n.resolvedLanguage === 'th' ? userProfile.departmentName ? userProfile.departmentName : userProfile.departmentName_EN : userProfile.departmentName_EN ? userProfile.departmentName_EN : userProfile.departmentName : t("All") },
    section: { idSection: userProfile.idSection ? userProfile.idSection : "all", sectionName: userProfile.idSection ? i18n.resolvedLanguage === 'th' ? userProfile.sectionName ? userProfile.sectionName : userProfile.sectionName_EN : userProfile.sectionName_EN ? userProfile.sectionName_EN : userProfile.sectionName : t("All") },
  });
  const [orgData, setOrgData] = useState({ departmentList: [], sectionList: [] });
  const [summaryTime, setSummaryTime] = useState({ hours: { total: 0, max: 0, hoursPercentage: 0 }, expenses: { total: 0, max: 0, expensesPercentage: 0 } });

  const handleCompanyChange = (newValue) => {
    setFilters({ ...filters, company: newValue, department: { idDepartment: "all", departmentName: t("All") }, section: { idSection: "all", sectionName: t("All") } });
  };

  useEffect(() => {
    dispatch(getDepartment({ includes: ["section"] }));
    dispatch(getAffiliateCompany({ mode: "manager" }));
  }, [dispatch]);

  useEffect(() => {
    if (filters.company) {
      dispatch(getCompanyOrganizationList({
        idCompany: filters.company.idCompany,
        getDepartment: true, getSection: true,
        mode: "manager",
        start: dayjs(`${date.year}-${date.month}-01`),
        end: dayjs(`${date.year}-${date.month}-01`).endOf('month'),
      }));
    }
  }, [filters.company, date]);

  useEffect(() => {
    if (OrganizationList && userProfile) {
      const data = {
        // departmentList: userProfile.idDepartments !== null
        //   ? OrganizationList.departments.filter((item) => parseInt(item.idDepartment) === parseInt(userProfile.idDepartments))
        //   : OrganizationList.departments,
        departmentList: OrganizationList.departments,
        sectionList:OrganizationList.sections,
        // sectionList: userProfile.idSection !== null
        //   ? OrganizationList.sections.filter((item) => parseInt(item.idSection) === parseInt(userProfile.idSection))
        //   : OrganizationList.sections,
      }
      setOrgData(data);
    }
  }, [OrganizationList, userProfile.idDepartments, userProfile.idSection]);


  useEffect(() => {
    if (affiliateList) {
      const defaultCompany = affiliateList.find(x => x.idCompany === userProfile.idCompany) || affiliateList[0];
      setFilters({ ...filters, company: defaultCompany });
    }
  }, [affiliateList, userProfile.idCompany]);

  const fetchDashboardData = (filters, date, dispatch) => {
    setIsLoadingOT(true)
    if (filters.company) {
      const { idCompany } = filters.company;
      const { idDepartment, idSection } = filters.department;
      const sectionId = idSection === "all" ? undefined : idSection;
      const DepartmentId = idDepartment === "all" ? undefined : idDepartment;
      dispatch(getOT({
        idCompany,
        idDepartment: DepartmentId,
        idSection: sectionId,
        start: dayjs(`${date.year}-${date.month}-01`),
        end: dayjs(`${date.year}-${date.month}-01`).endOf('month'),
      }))

      dispatch(getDashboardCost({
        mode: "manager",
        month: date.month,
        year: date.year,
        idCompany,
        idDepartment: DepartmentId,
        idSection: sectionId
      }));
    }
  };

  useEffect(() => {
    if (!dashboardCost || !overviewUserOt) return;
    if (dashboardCost === null || overviewUserOt === null) return;

    const { salaryTotal, otTotal } = dashboardCost;
    const { payTotalAll, hourTotalAll } = Object.values(otTotal || {}).reduce(
      (acc, curr) => {
        acc.payTotalAll += curr.payTotal;
        acc.hourTotalAll += curr.hourTotal;
        return acc;
      },
      { payTotalAll: 0, hourTotalAll: 0 }
    );
    setSummaryTime({
      hours: { total: parseFloat((overviewUserOt.workTime.otHourTotal || 0).toFixed(2)), max: parseFloat((overviewUserOt.workTime.workHourActualTotal || 0).toFixed(2)), hoursPercentage: overviewUserOt.workTime.workHourActualTotal ? parseFloat((overviewUserOt.workTime.otHourTotal / overviewUserOt.workTime.workHourActualTotal).toFixed(4)) : 0 },
      expenses: {
        total: parseFloat(payTotalAll.toFixed(2)),
        max: parseFloat((salaryTotal || 0).toFixed(2)),
        expensesPercentage: salaryTotal ? parseFloat((payTotalAll / salaryTotal).toFixed(4)) : 0,
      },
    });
  }, [dashboardCost, overviewUserOt]);

  return (
    <Card sx={{ width: '100%', padding: '16px', overflow: 'visible', position: 'relative' }}>
      <CardContent>
        <HeaderBox>
          <InnerHeaderBox>
            <WorkOutlineOutlined sx={{ marginRight: 1, fontSize: 30, color: 'orange' }} />
            <Typography margin={0} variant="h5" align="center" gutterBottom>
              {t('OT')}
            </Typography>
            <Typography mt={1} color={'GrayText'} variant='subtitle'>({t('PreviousRound')})</Typography>
          </InnerHeaderBox>
          <IconButton style={{ width: 48, height: 48, alignItems: 'center', justifyContent: 'center', display: 'flex' }} sx={{ boxShadow: 2 }} onClick={handleOpen}>
            <img src='/assets/icon/search.png' alt='Search Icon' style={{ width: 48, height: 48 }} />
          </IconButton>
        </HeaderBox>
        <Box sx={{ margin: '0 auto 16px', textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: '#5e4889', borderRadius: '8px' }}>
              <Typography variant="body1" fontWeight="600" sx={{ padding: '8px', color: "white" }} margin={0} gutterBottom>
                {date && date.month ? dayjs().month(date.month - 1).format("MMMM") : t('Month')}
              </Typography>
              <Typography variant="body1" fontWeight="600" sx={{ padding: '8px', color: "white" }} margin={0} gutterBottom>
                {date && date.year ? date.year : t('Year')}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', backgroundColor: '#5e4889', borderRadius: '8px' }}>
                <Typography variant="body1" fontWeight="600" sx={{ padding: '8px', color: "white" }} margin={0} gutterBottom>
                  {filters && filters.company ? i18n.resolvedLanguage === 'th' ? filters.company.companyName ? filters.company.companyName : filters.company.companyName_EN : filters.company.companyName_EN ? filters.company.companyName_EN : filters.company.companyName : t('Company')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', backgroundColor: '#5e4889', borderRadius: '8px' }}>
                <Typography variant="body1" fontWeight="600" sx={{ padding: '8px', color: "white" }} margin={0} gutterBottom>
                  {t('Department')} : {filters && filters.department ? i18n.resolvedLanguage === 'th' ? filters.department.departmentName ? filters.department.departmentName : filters.department.departmentName_EN : filters.department.departmentName_EN ? filters.department.departmentName_EN : filters.department.departmentName : t('Department')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', backgroundColor: '#5e4889', borderRadius: '8px' }}>
                <Typography variant="body1" fontWeight="600" sx={{ padding: '8px', color: "white" }} margin={0} gutterBottom>
                  {t('Section')} : {filters && filters.section ? filters.section.sectionName : t('Section')}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                maxWidth: '600px',
                bgcolor: 'background.paper',
                borderRadius: 1,
                boxShadow: 24,
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                position: 'relative',
              }}
            >
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 12,
                }}
              >
                <CloseIcon />
              </IconButton>
              <StyledRoot sx={{ width: '100%' }} >
                <Container style={{ margin: "16px 0" }}>
                  <Box padding="24px">
                    <Box display="flex" justifyContent="flex-end" gap="8px" marginBottom="16px">
                      <TextFieldTheme variant="filled" value={date.year} onChange={(e) => setDate({ ...date, year: e.target.value })} select fullWidth>
                        {[...Array(dayjs().year() - 2023 + 1)].map((_, index) => (
                          <MenuItem key={index} value={dayjs().year() - index}>
                            {dayjs().year() - index}
                          </MenuItem>
                        ))}
                      </TextFieldTheme>
                      <TextFieldTheme variant="filled" value={date.month} onChange={(e) => setDate({ ...date, month: e.target.value })} select fullWidth>
                        {[...Array(12)].map((_, index) => (
                          <MenuItem key={index + 1} value={index + 1}>
                            {dayjs().month(index).format("MMMM")}
                          </MenuItem>
                        ))}
                      </TextFieldTheme>
                    </Box>

                    <Box marginBottom="16px">
                      <Typography textAlign="left" fontSize="14px" fontWeight="600" marginBottom="4px" color="text.third">{t("Company")}</Typography>
                      <SelectAffiliateCompany options={affiliateList} value={filters.company} onChange={(_, value) => handleCompanyChange(value)} />
                    </Box>

                    <Grid container >
                      <Grid item xs={12} md={6} paddingRight={'8px'}>
                        <Typography textAlign="left" fontSize="14px" fontWeight="600" marginBottom="4px" color="text.third">{t("Department")}</Typography>
                        <Autocomplete
                          options={[ ...orgData.departmentList]}
                          getOptionLabel={(option) => option.departmentName || t("NoDepartment")}
                          value={filters.department}
                          onChange={(_, value) => setFilters({ ...filters, department: value, section: { idSection: "all", sectionName: t("All") } })}
                          disableClearable
                          renderInput={(params) => <TextFieldTheme {...params} variant="filled" />}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography textAlign="left" fontSize="14px" fontWeight="600" marginBottom="4px" color="text.third">{t("Section")}</Typography>
                        <Autocomplete
                          options={[ ...orgData.sectionList.filter(s => s.idDepartment === filters.department.idDepartment || filters.department.idDepartment === "all")]}
                          getOptionLabel={(option) => option.sectionName || t("NoSection")}
                          value={filters.section}
                          onChange={(_, value) => setFilters({ ...filters, section: value })}
                          disableClearable
                          renderInput={(params) => <TextFieldTheme {...params} variant="filled" />}
                        />
                      </Grid>
                      <Grid item xs={12} container mt={2} justifyContent="flex-end">
                        <Button variant="contained" sx={{ backgroundColor: '#5e4889' }}
                          onClick={() => {
                            setDate({ ...date });
                            fetchDashboardData(filters, date, dispatch);
                            handleClose();
                          }}>{t("Search")}</Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </StyledRoot>
            </Box>
          </Modal>
        </Box>

        <Grid display={'flex'} alignItems={'center'} justifyContent="center"  sx={{ flexWrap: { xs: 'wrap', sm: 'wrap', md: 'nowrap' } }}>
          {[
            {
              title: t('totalOTHours'),
              value: Math.min(1, Math.max(0, summaryTime.hours.hoursPercentage)),
              arcsLength: [summaryTime.hours.hoursPercentage, 1 - summaryTime.hours.hoursPercentage],
              percent: summaryTime.hours.hoursPercentage,
              total: summaryTime.hours.total,
              max: summaryTime.hours.max,
              currency: t('Unit.ShortHours'),
            },
            {
              title: t('totalOTExpense'),
              value: Math.min(1, Math.max(0, summaryTime.expenses.expensesPercentage)),
              arcsLength: [summaryTime.expenses.expensesPercentage, 1 - summaryTime.expenses.expensesPercentage],
              percent: summaryTime.expenses.expensesPercentage,
              total: summaryTime.expenses.total,
              max: summaryTime.expenses.max,
              currency: t('Baht'),
            }
          ].map((data, index) => (
            <Grid item xs={12} sm={8} md={6} key={index}>
              <Typography variant="h6" align="center" marginBottom={2}>
                {data.title}
              </Typography>
              <GaugeChart
                id={`${data.title.toLowerCase().replace(/\s+/g, '-')}-gauge`}
                value={data.value}
                arcsLength={[0.25, 0.25, 0.25, 0.25]}
                colors={['#00FF00', '#80FF00', '#FFBF00', '#FF5F6D']}
                percent={data.percent}
                textColor="#333"
              />
              <Typography variant="body1" align="center" mt={2}>
                {data.total.toFixed(2)} / {data.max.toFixed(2)} {data.currency}
              </Typography>
            </Grid>
          ))}
        </Grid>

      </CardContent>
    </Card>
  );
}

export default OT;
