import React from "react";
import { Box, Typography } from "@mui/material";

const LabelDotted = ({color, text}) => {

  let colorDef = color? color: "#000000";
  let textDef = text? text: "";

  return (
    <Box display="inline-flex" alignItems="center">
      <svg width="24px" height="24px" style={{ color: colorDef, fill: "currentcolor", marginRight: "4px"}}>
        <path d="M 12 6 a 6 6 90 1 1 0 12 a 6 6 90 0 1 0 -12 Z"></path>
      </svg>
      <Typography lineHeight="1" fontSize="14px">{textDef}</Typography>
    </Box>
  )
}

export default LabelDotted;