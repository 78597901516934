import React, { useEffect, useMemo } from "react";
import { Autocomplete, Button, Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { useDispatch, useSelector } from "react-redux";
import InputNumber from "./inputNumber";
import { getAllLocations } from "../../../../../../actions/gpsLocations";
import { addAdditionSpecial100, getAdditionSpecial100 } from "../../../../../../actions/additionSpecial";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";
import { useTranslation } from "react-i18next";

const AS100Form = ({ selectedCompany, handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: locationsList } = useSelector((state) => state.locations);
  const { result: additionSpecial } = useSelector((state) => state.additionSpecial100);

  const validateSchema = yup.object({
    start: yup.object().required(t("ThisFieldIsRequired")).nullable(),
    end: yup.object().required(t("ThisFieldIsRequired")).nullable(),
    amount: yup
      .number()
      .transform((val, orig) => (orig === "" ? undefined : val))
      .min(1, `${t("Minimum")} 1`)
      .max(1000000, `${t("Maximum")} 1000000`)
      .required(t("ThisFieldIsRequired")),
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      start: null,
      end: null,
      amount: "",
    },
    resolver: yupResolver(validateSchema),
  });

  const handleAddLocation = async (data) => {
    const duplicate = additionSpecial.some(
      (item) =>
        item.idLocationStart === data.start.idGpsLocations && item.idLocationEnd === data.end.idGpsLocations
    );
    if (duplicate) {
      dispatch(
        openNotificationAlert({
          message: t("OriginAndDestinationDuplicate"),
          type: "error",
        })
      );
    } else {
      const res = await dispatch(
        addAdditionSpecial100({
          idCompany: selectedCompany.idCompany,
          idLocationStart: data.start.idGpsLocations,
          idLocationEnd: data.end.idGpsLocations,
          amount: data.amount,
        })
      );
      if (res.status === 201) {
        dispatch(
          openNotificationAlert({ message: "success", type: "success" })
        );
        dispatch(getAdditionSpecial100(selectedCompany.idCompany));
      } else {
        dispatch(openNotificationAlert({ message: "error", type: "error" }));
      }
      handleClose();
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getAllLocations({ idCompany: selectedCompany.idCompany }));
    }
  }, [selectedCompany.idCompany]);

  const locations = useMemo(() => {
    let temp = [];
    if (locationsList) {
      temp = locationsList.reduce((acc, curr) => {
        return [...acc, ...curr.locations];
      }, []);
    }
    return temp;
  }, [locationsList]);

  return (
    <form onSubmit={handleSubmit(handleAddLocation)}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Controller
            control={control}
            name={"start"}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                onChange={(event, newValue) => field.onChange(newValue)}
                options={locations}
                isOptionEqualToValue={(option, value) =>
                  option.idGpsLocations === value.idGpsLocations
                }
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    label={t("Origin")}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error && fieldState.error.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            name={"end"}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                onChange={(event, newValue) => field.onChange(newValue)}
                options={locations}
                isOptionEqualToValue={(option, value) =>
                  option.idGpsLocations === value.idGpsLocations
                }
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    label={t("Destination")}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error && fieldState.error.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <InputNumber control={control} name={"amount"} label={t("AmountOfMoney")} />
        </Grid>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"flex-end"}
          gap={"8px"}
        >
          <Button color="error" onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <ButtonBlue type="submit" variant="contained">
            {t("Confirm")}
          </ButtonBlue>
        </Grid>
      </Grid>
    </form>
  );
};

export default AS100Form;
