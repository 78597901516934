import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Container } from "@mui/material";
import { useTranslation } from "react-i18next";

import MenuApp from "./menu-app";

import Backdrop from "../../shared/general/Backdrop";

import { getUserProfile } from "../../../../actions/user";
import { getPdpaApproveByID } from "../../../../actions/pdpaApprove";
import DialogPdpaApprove from "./dialogPdpaApprove";
import { getUserFullName } from "../../../../utils/userData";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledWrapHeader = styled("div")({
  paddingTop: 20,
  paddingBottom: 60,
});

const HomePageAdmin = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { isFetching : isFetchingPDPA, result: employeePdpaApprove } = useSelector((state) => state.employeePdpaApprove);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (employeePdpaApprove && employeePdpaApprove.isApprove === 1) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [employeePdpaApprove]);

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserProfile(currentUser.username));
    }
    dispatch(getPdpaApproveByID());
  }, []);

  return (
    <div>

      {
        <StyledRoot className={`page`}>
          <Backdrop open={!(currentUser && userProfile) || isFetchingPDPA} />
          <Container maxWidth="lg">
            {userProfile && !isFetchingPDPA && (
              <StyledWrapHeader>
                <Typography variant="h4">
                  {`${t("Hello")} ${getUserFullName(userProfile)}`}
                </Typography>
              </StyledWrapHeader>
            )}
            <MenuApp />
          </Container>
        </StyledRoot>
      }
      
      <DialogPdpaApprove
        open={open && !isFetchingPDPA}
        setOpen={setOpen}
      />

    </div>
  );
};

export default HomePageAdmin;
