import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

function OrgTeamGraphDashboard(props) {
  const { records } = props;
  const [barLabel, setBarLabel] = useState([]);
  const [barData, setBarData] = useState([]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  const data = {
    labels: barLabel,
    datasets: [
      {
        label: "Team Score (%)",
        data: barData,
        backgroundColor: "rgba(255, 99, 132, 1)",
      },
    ],
  };

  useEffect(() => {
    if (records) {
      let arrayLabel = [];
      let arrayData = [];

      records.forEach((record) => {
        arrayLabel.push(record.teamRecordName);

        let score = (record.achieved / record.objectiveAmount) * 100;

        if (isNaN(score)) {
          score = 0;
        }

        arrayData.push(score);
      });

      setBarLabel(arrayLabel);
      setBarData(arrayData);
    }
  }, [records]);

  return (
    <>
      <Bar options={options} data={data} />
    </>
  );
}

export default OrgTeamGraphDashboard;
