import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  TextField,
  FormControl,
  Grid,
  Popper,
  Typography,
  Box,
  Divider,
  Alert,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import XLSX from "xlsx";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import AlertResponse from "../../shared/general/AlertResponse";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import SaveIcon from "@mui/icons-material/Save";
import {
  getLeaveRequestByCompany,
  getAllRequestTimeBy,
  getAllRequestTimeWithDrawBy,
  getAllLeaveWithDrawBy,
} from "../../../../actions/report";
import { getPayrollSetting } from "../../../../actions/paytypes";
import SelectCompany from "../shared/selectCompany";
import { addEmailReport, getEmailReportById } from "../../../../actions/email";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../shared/date/datePicker";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const Approve = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const List = [
    `${t("WorkingTimeRequest")}`,
    `${t("Overtime")}`,
    `${t("LeaveRequest")}`,
  ];

  const { result: email } = useSelector((state) => state.email);
  const { result: requestTimeList } = useSelector((state) => state.requestTime);
  const { result: requestTimeWithDrawList } = useSelector(
    (state) => state.requestTimeWithDraw
  );
  const { result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { result: leaveEmployeeWithdrawsList } = useSelector(
    (state) => state.leaveEmployeesWithDraw
  );
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { open, handleClose } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [inputSearch, setInputSearch] = useState(new Date());
  const [Type, setType] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [autoEmail, setAutoEmail] = useState(null);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleChangeCompany = (newValue) => {
    if (newValue) {
      setSelectedCompany(newValue.idCompany);
    } else {
      setSelectedCompany(null);
    }
  };

  useEffect(() => {
    if (email) setAutoEmail(email.day);
  }, [email]);

  useEffect(() => {
    dispatch(
      getAllRequestTimeBy(selectedCompany, {
        start: dayjs(inputSearch).startOf('month'),
        end: dayjs(inputSearch).endOf('month'),
      })
    );
    dispatch(
      getAllRequestTimeWithDrawBy(selectedCompany, {
        start: dayjs(inputSearch).startOf('month'),
        end: dayjs(inputSearch).endOf('month'),
      })
    );
    dispatch(
      getLeaveRequestByCompany(selectedCompany, {
        start: dayjs(inputSearch).startOf('month'),
        end: dayjs(inputSearch).endOf('month'),
      })
    );
    dispatch(
      getAllLeaveWithDrawBy(selectedCompany, {
        start: dayjs(inputSearch).startOf('month'),
        end: dayjs(inputSearch).endOf('month'),
      })
    );
  }, [inputSearch, selectedCompany]);

  useEffect(() => {
    dispatch(getEmailReportById(userProfile.idEmployees, "approvelist"));
    dispatch(getPayrollSetting());
  }, []);

  const handleSubmit = () => {
    let dataToExcelWaiting = [];
    let dataToExcel = [];
    let elementListWaiting = [];
    let elementList = [];

    if (
      Type === `${t("WorkingTimeRequest")}` &&
      requestTimeList &&
      requestTimeWithDrawList
    ) {
      requestTimeList.map((r) => {
        if (r.idRequestType === 1) {
          if (r.isDoubleApproval === 1) {
            if (
              (!r.isManagerLV1Approve || !r.isManagerLV2Approve) &&
              !(r.isManagerLV1Approve === 0 || r.isManagerLV2Approve === 0)
            ) {
              dataToExcelWaiting.push(r);
            } else dataToExcel.push(r);
          } else {
            if (r.approvalLevel === 1) {
              if (r.isManagerLV1Approve !== 1 && r.isManagerLV1Approve !== 0) {
                dataToExcelWaiting.push(r);
              } else dataToExcel.push(r);
            } else if (r.approvalLevel === 2) {
              if (r.isManagerLV2Approve !== 1 && r.isManagerLV2Approve !== 0) {
                dataToExcelWaiting.push(r);
              } else dataToExcel.push(r);
            } else dataToExcel.push(r);
          }
        }
      });
      requestTimeWithDrawList.map((r) => {
        if (r.idRequestType === 1) {
          if (r.isApprove === null) {
            dataToExcelWaiting.push(r);
          } else dataToExcel.push(r);
        }
      });

      for (let index = 0; index < dataToExcelWaiting.length; index++) {
        const element = {
          // ลำดับที่: dataToExcelWaiting[index].idRequestTime,
          รหัสพนักงาน: dataToExcelWaiting[index].employeeID,
          "ชื่อ-สกุล": `${dataToExcelWaiting[index].firstname_TH} ${dataToExcelWaiting[index].lastname_TH}`,
          ตำแหน่ง: dataToExcelWaiting[index].positionName
            ? dataToExcelWaiting[index].positionName
            : "",
          ประเภทการจ่ายเงินเดือน: dataToExcelWaiting[index].paymentRoundName
            ? dataToExcelWaiting[index].paymentRoundName
            : "",
          ประเภทคำขอ: dataToExcelWaiting[index].name
            ? dataToExcelWaiting[index].name
            : "",
          เวลาเริ่มต้น: dataToExcelWaiting[index].startText
            ? dataToExcelWaiting[index].startText
            : "",
          เวลาสิ้นสุด: dataToExcelWaiting[index].endText
            ? dataToExcelWaiting[index].endText
            : "",

          เหตุผล: dataToExcelWaiting[index].reasonName
            ? dataToExcelWaiting[index].reasonName
            : "-",
          "เหตุผล(เพิ่มเติม)": dataToExcelWaiting[index].otherReason
            ? dataToExcelWaiting[index].otherReason
            : "-",
          วันเวลาที่ทำรายการ: dataToExcelWaiting[index].createDateText
            ? dataToExcelWaiting[index].createDateText
            : "-",
          "ผู้บังคับบัญชาลำดับที่ 1": dataToExcelWaiting[index]
            .managerLV1_firstname_TH
            ? `${dataToExcelWaiting[index].managerLV1_firstname_TH} ${dataToExcelWaiting[index].managerLV1_lastname_TH}`
            : "-",
          "E-mail ผู้บังคับบัญชาลำดับที่ 1": dataToExcelWaiting[index]
            .emailManagerLV1
            ? dataToExcelWaiting[index].emailManagerLV1
            : "-",
          "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1":
            dataToExcelWaiting[index].isManagerLV1Approve === null
              ? t("PendingApproval")
              : dataToExcelWaiting[index].isManagerLV1Approve === 1
              ? t("Approved")
              : t("NotApproved"),
          "ผู้บังคับบัญชาลำดับที่ 2":
            dataToExcelWaiting[index].isDoubleApproval === 1
              ? dataToExcelWaiting[index].managerLV2_firstname_TH
                ? `${dataToExcelWaiting[index].managerLV2_firstname_TH} ${dataToExcelWaiting[index].managerLV2_lastname_TH}`
                : ""
              : dataToExcelWaiting[index].approvalLevel === 2
              ? dataToExcelWaiting[index].managerLV2_firstname_TH
                ? `${dataToExcelWaiting[index].managerLV2_firstname_TH} ${dataToExcelWaiting[index].managerLV2_lastname_TH}`
                : ""
              : "",
          "E-mail ผู้บังคับบัญชาลำดับที่ 2":
            dataToExcelWaiting[index].isDoubleApproval === 1
              ? dataToExcelWaiting[index].emailManagerLV2
                ? dataToExcelWaiting[index].emailManagerLV2
                : ""
              : dataToExcelWaiting[index].approvalLevel === 2
              ? dataToExcelWaiting[index].emailManagerLV2
                ? dataToExcelWaiting[index].emailManagerLV2
                : ""
              : "",
          "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
            dataToExcelWaiting[index].isDoubleApproval === 1
              ? dataToExcelWaiting[index].isManagerLV1Approve === null
                ?  `${t("AwaitingApprovalFromSupervisor")} 1`
                : dataToExcelWaiting[index].isManagerLV2Approve === null
                ? t("PendingApproval")
                : ""
              : dataToExcelWaiting[index].approvalLevel === 2
              ? dataToExcelWaiting[index].isManagerLV2Approve &&
                dataToExcelWaiting[index].isManagerLV2Approve === null
                ? t("PendingApproval")
                : ""
              : "",
        };
        elementListWaiting.push(element);
      }

            for (let index = 0; index < dataToExcel.length; index++) {
                const element = {
                    // ลำดับที่: dataToExcel[index].idRequestTime,
                    รหัสพนักงาน: dataToExcel[index].employeeID,
                    "ชื่อ-สกุล": `${dataToExcel[index].firstname_TH} ${dataToExcel[index].lastname_TH}`,
                    ตำแหน่ง: dataToExcel[index].positionName
                        ? dataToExcel[index].positionName
                        : "",
                    ประเภทการจ่ายเงินเดือน: dataToExcel[index].paymentRoundName
                        ? dataToExcel[index].paymentRoundName
                        : "",
                    ประเภทคำขอ: dataToExcel[index].name ? dataToExcel[index].name : "",
                    เวลาเริ่มต้น: dataToExcel[index].startText
                        ? dataToExcel[index].startText
                        : "",
                    เวลาสิ้นสุด: dataToExcel[index].endText
                        ? dataToExcel[index].endText
                        : "",
                    เหตุผล: dataToExcel[index].reasonName
                        ? dataToExcel[index].reasonName
                        : "-",
                    "เหตุผล(เพิ่มเติม)": dataToExcel[index].otherReason
                        ? dataToExcel[index].otherReason
                        : "-",
                    วันเวลาที่ทำรายการ: dataToExcel[index].createDateText
                        ? dataToExcel[index].createDateText
                        : "-",
                    "ผู้บังคับบัญชาลำดับที่ 1": dataToExcel[index].managerLV1_firstname_TH
                        ? `${dataToExcel[index].managerLV1_firstname_TH} ${dataToExcel[index].managerLV1_lastname_TH}`
                        : "-",
                    "E-mail ผู้บังคับบัญชาลำดับที่ 1": dataToExcel[index].emailManagerLV1
                        ? dataToExcel[index].emailManagerLV1
                        : "-",
                    "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1":
                        dataToExcel[index].isManagerLV1Approve &&
                            dataToExcel[index].isManagerLV1Approve === null
                            ? t("PendingApproval")
                            : dataToExcel[index].isManagerLV1Approve === 1
                                ? t("Approved")
                                : t("NotApproved"),
                    "ผู้บังคับบัญชาลำดับที่ 2":
                        dataToExcel[index].isDoubleApproval === 1 || dataToExcel[index].approvalLevel === 2
                            ? `${dataToExcel[index].managerLV2_firstname_TH} ${dataToExcel[index].managerLV2_lastname_TH}`
                            : "",
                    "E-mail ผู้บังคับบัญชาลำดับที่ 2":
                        dataToExcel[index].isDoubleApproval === 1
                            ? dataToExcel[index].emailManagerLV2
                                ? dataToExcel[index].emailManagerLV2
                                : ""
                            : dataToExcel[index].approvalLevel === 2
                                ? dataToExcel[index].emailManagerLV2
                                    ? dataToExcel[index].emailManagerLV2
                                    : ""
                                : "",
                    "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
                        dataToExcel[index].isDoubleApproval === 1
                            ? dataToExcel[index].isManagerLV1Approve &&
                                dataToExcel[index].isManagerLV1Approve === null
                                ? `${t("AwaitingApprovalFromSupervisor")} 2`
                                : dataToExcel[index].isManagerLV2Approve &&
                                    dataToExcel[index].isManagerLV2Approve === null
                                    ? t("PendingApproval")
                                    : dataToExcel[index].isManagerLV2Approve === 1
                                        ? t("Approved")
                                        : t("NotApproved")
                            : dataToExcel[index].approvalLevel === 2
                                ? dataToExcel[index].isManagerLV2Approve &&
                                    dataToExcel[index].isManagerLV2Approve === null
                                    ? t("PendingApproval")
                                    : dataToExcel[index].isManagerLV2Approve === 1
                                        ? t("Approved")
                                        : t("NotApproved")
                                : "",
                };
                elementList.push(element);
            }
        } else if (Type === "ทำงานล่วงเวลา" && requestTimeList && requestTimeWithDrawList && payrollSetting) {
            requestTimeList.map((r) => {
                if (r.idRequestType === 2) {
                    if (r.isDoubleApproval === 1) {
                        if (
                            (!r.isManagerLV1Approve || !r.isManagerLV2Approve) &&
                            !(r.isManagerLV1Approve === 0 || r.isManagerLV2Approve === 0)
                        ) {
                            dataToExcelWaiting.push(r)
                        } else dataToExcel.push(r)
                    } else {
                        if (r.approvalLevel === 1) {
                            if (r.isManagerLV1Approve !== 1 && r.isManagerLV1Approve !== 0) {
                                dataToExcelWaiting.push(r)
                            } else dataToExcel.push(r)
                        } else if (r.approvalLevel === 2) {
                            if (r.isManagerLV2Approve !== 1 && r.isManagerLV2Approve !== 0) {
                                dataToExcelWaiting.push(r)
                            } else dataToExcel.push(r)
                        } else dataToExcel.push(r)
                    }
                }
            })
            requestTimeWithDrawList.map((r) => {
                if (r.idRequestType === 2) {
                    if (r.isApprove === null) {
                        dataToExcelWaiting.push(r)
                    } else dataToExcel.push(r)
                }
            })
            for (let index = 0; index < dataToExcelWaiting.length; index++) {
                const element = {
                    // ลำดับที่: dataToExcelWaiting[index].idRequestTime,
                    รหัสพนักงาน: dataToExcelWaiting[index].employeeID,
                    "ชื่อ-สกุล": `${dataToExcelWaiting[index].firstname_TH} ${dataToExcelWaiting[index].lastname_TH}`,
                    ตำแหน่ง: dataToExcelWaiting[index].positionName
                        ? dataToExcelWaiting[index].positionName
                        : "",
                    ประเภทการจ่ายเงินเดือน: dataToExcelWaiting[index].paymentRoundName
                        ? dataToExcelWaiting[index].paymentRoundName
                        : "",
                    ประเภทคำขอ: dataToExcelWaiting[index].name
                        ? dataToExcelWaiting[index].name
                        : "",
                    เวลาเริ่มต้น: dataToExcelWaiting[index].startText
                        ? dataToExcelWaiting[index].startText
                        : "",
                    เวลาสิ้นสุด: dataToExcelWaiting[index].endText
                        ? dataToExcelWaiting[index].endText
                        : "",
                    [`OT ${payrollSetting.xWorkingDailyHoliday}x (วันหยุด)`]:
                        dataToExcelWaiting[index].xWorkingDailyHoliday > 0
                            ? `${parseFloat(
                                dataToExcelWaiting[index].xWorkingDailyHoliday / 60
                            ).toFixed(2)} ${t("Unit.ShortHours")}`
                            : "",
                    [`OT ${payrollSetting.xOT}x (วันทำงาน)`]:
                        dataToExcelWaiting[index].xOT > 0
                            ? `${parseFloat(dataToExcelWaiting[index].xOT / 60).toFixed(
                                2
                            )} ${t("Unit.ShortHours")}`
                            : "",
                    [`OT ${payrollSetting.xWorkingDailyHoliday}x`]:
                        dataToExcelWaiting[index].xWorkingDailyHoliday > 0
                            ? `${parseFloat(
                                dataToExcelWaiting[index].xWorkingDailyHoliday / 60
                            ).toFixed(2)} ${t("Unit.ShortHours")}`
                            : "",
                    [`OT ${payrollSetting.xOTHoliday}x`]:
                        dataToExcelWaiting[index].xOTHoliday > 0
                            ? `${parseFloat(
                                dataToExcelWaiting[index].xOTHoliday / 60
                            ).toFixed(2)} ${t("Unit.ShortHours")}`
                            : "",
                    เหตุผล: dataToExcelWaiting[index].reasonName
                        ? dataToExcelWaiting[index].reasonName
                        : "-",
                    "เหตุผล(เพิ่มเติม)": dataToExcelWaiting[index].otherReason
                        ? dataToExcelWaiting[index].otherReason
                        : "-",
                    วันเวลาที่ทำรายการ: dataToExcelWaiting[index].createDateText
                        ? dataToExcelWaiting[index].createDateText
                        : "-",
                    "ผู้บังคับบัญชาลำดับที่ 1": dataToExcelWaiting[index].managerLV1_firstname_TH
                        ? `${dataToExcelWaiting[index].managerLV1_firstname_TH} ${dataToExcelWaiting[index].managerLV1_lastname_TH}`
                        : "-",
                    "E-mail ผู้บังคับบัญชาลำดับที่ 1": dataToExcelWaiting[index]
                        .emailManagerLV1
                        ? dataToExcelWaiting[index].emailManagerLV1
                        : "-",
                    "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1":
                        dataToExcelWaiting[index].isManagerLV1Approve === null
                            ? t("PendingApproval")
                            : dataToExcelWaiting[index].isManagerLV1Approve === 1
                                ? t("Approved")
                                : t("NotApproved"),
                    "ผู้บังคับบัญชาลำดับที่ 2":
                        dataToExcelWaiting[index].isDoubleApproval === 1
                            ? dataToExcelWaiting[index].managerLV2_firstname_TH
                                ? `${dataToExcelWaiting[index].managerLV2_firstname_TH} ${dataToExcelWaiting[index].managerLV2_lastname_TH}`
                                : ""
                            : dataToExcelWaiting[index].approvalLevel === 2
                                ? dataToExcelWaiting[index].managerLV2_firstname_TH
                                    ? `${dataToExcelWaiting[index].managerLV2_firstname_TH} ${dataToExcelWaiting[index].managerLV2_lastname_TH}`
                                    : ""
                                : "",
                    "E-mail ผู้บังคับบัญชาลำดับที่ 2":
                        dataToExcelWaiting[index].isDoubleApproval === 1
                            ? dataToExcelWaiting[index].emailManagerLV2
                                ? dataToExcelWaiting[index].emailManagerLV2
                                : ""
                            : dataToExcelWaiting[index].approvalLevel === 2
                                ? dataToExcelWaiting[index].emailManagerLV2
                                    ? dataToExcelWaiting[index].emailManagerLV2
                                    : ""
                                : "",
                    "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
                        dataToExcelWaiting[index].isDoubleApproval === 1
                            ? dataToExcelWaiting[index].isManagerLV1Approve === null
                                ? `${t("AwaitingApprovalFromSupervisor")} 1`
                                : dataToExcelWaiting[index].isManagerLV2Approve === null
                                    ? t("PendingApproval")
                                    : ""
                            : dataToExcelWaiting[index].approvalLevel === 2
                                ? dataToExcelWaiting[index].isManagerLV2Approve &&
                                    dataToExcelWaiting[index].isManagerLV2Approve === null
                                    ? t("PendingApproval")
                                    : ""
                                : "",
                };
                elementListWaiting.push(element);
            }

      for (let index = 0; index < dataToExcel.length; index++) {
        const element = {
          // ลำดับที่: dataToExcel[index].idRequestTime,
          รหัสพนักงาน: dataToExcel[index].employeeID,
          "ชื่อ-สกุล": `${dataToExcel[index].firstname_TH} ${dataToExcel[index].lastname_TH}`,
          ตำแหน่ง: dataToExcel[index].positionName
            ? dataToExcel[index].positionName
            : "",
          ประเภทคำขอ: dataToExcel[index].name ? dataToExcel[index].name : "",
          เวลาเริ่มต้น: dataToExcel[index].startText
            ? dataToExcel[index].startText
            : "",
          เวลาสิ้นสุด: dataToExcel[index].endText
            ? dataToExcel[index].endText
            : "",
          [`OT ${payrollSetting.xWorkingDailyHoliday}x (วันหยุด)`]:
            dataToExcel[index].xWorkingDailyHoliday > 0
              ? `${parseFloat(
                  dataToExcel[index].xWorkingDailyHoliday / 60
                ).toFixed(2)} ${t("Unit.ShortHours")}`
              : "",
          [`OT ${payrollSetting.xOT}x (วันทำงาน)`]:
            dataToExcel[index].xOT > 0
              ? `${parseFloat(dataToExcel[index].xOT / 60).toFixed(2)} ${t("Unit.ShortHours")}`
              : "",
          [`OT ${payrollSetting.xWorkingDailyHoliday}x`]:
            dataToExcel[index].xWorkingDailyHoliday > 0
              ? `${parseFloat(
                  dataToExcel[index].xWorkingDailyHoliday / 60
                ).toFixed(2)} ${t("Unit.ShortHours")}`
              : "",
          [`OT ${payrollSetting.xOTHoliday}x`]:
            dataToExcel[index].xOTHoliday > 0
              ? `${parseFloat(dataToExcel[index].xOTHoliday / 60).toFixed(
                  2
                )} ${t("Unit.ShortHours")}`
              : "",
          เหตุผล: dataToExcel[index].reasonName
            ? dataToExcel[index].reasonName
            : "-",
          "เหตุผล(เพิ่มเติม)": dataToExcel[index].otherReason
            ? dataToExcel[index].otherReason
            : "-",
          วันเวลาที่ทำรายการ: dataToExcel[index].createDateText
            ? dataToExcel[index].createDateText
            : "-",
          "ผู้บังคับบัญชาลำดับที่ 1": dataToExcel[index].managerLV1_firstname_TH
            ? `${dataToExcel[index].managerLV1_firstname_TH} ${dataToExcel[index].managerLV1_lastname_TH}`
            : "-",
          "E-mail ผู้บังคับบัญชาลำดับที่ 1": dataToExcel[index].emailManagerLV1
            ? dataToExcel[index].emailManagerLV1
            : "-",
          "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1":
            dataToExcel[index].isManagerLV1Approve &&
            dataToExcel[index].isManagerLV1Approve === null
              ? t("PendingApproval")
              : dataToExcel[index].isManagerLV1Approve === 1
              ? t("Approved")
              : t("NotApproved"),
          "ผู้บังคับบัญชาลำดับที่ 2":
            dataToExcel[index].isDoubleApproval === 1
              ? dataToExcel[index].managerLV2_firstname_TH
                ? `${dataToExcel[index].managerLV2_firstname_TH} ${dataToExcel[index].managerLV2_lastname_TH}`
                : ""
              : dataToExcel[index].approvalLevel === 2
              ? dataToExcel[index].managerLV2_firstname_TH
                ? `${dataToExcel[index].managerLV2_firstname_TH} ${dataToExcel[index].managerLV2_lastname_TH}`
                : ""
              : "",
          "E-mail ผู้บังคับบัญชาลำดับที่ 2":
            dataToExcel[index].isDoubleApproval === 1
              ? dataToExcel[index].emailManagerLV2
                ? dataToExcel[index].emailManagerLV2
                : ""
              : dataToExcel[index].approvalLevel === 2
              ? dataToExcel[index].emailManagerLV2
                ? dataToExcel[index].emailManagerLV2
                : ""
              : "",
          "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
            dataToExcel[index].isDoubleApproval === 1
              ? dataToExcel[index].isManagerLV1Approve &&
                dataToExcel[index].isManagerLV1Approve === null
                ? `${t("AwaitingApprovalFromSupervisor")} 2`
                : dataToExcel[index].isManagerLV2Approve &&
                  dataToExcel[index].isManagerLV2Approve === null
                ? t("PendingApproval")
                : dataToExcel[index].isManagerLV2Approve === 1
                ? t("Approved")
                : t("NotApproved")
              : dataToExcel[index].approvalLevel === 2
              ? dataToExcel[index].isManagerLV2Approve &&
                dataToExcel[index].isManagerLV2Approve === null
                ? t("PendingApproval")
                : dataToExcel[index].isManagerLV2Approve === 1
                ? t("Approved")
                : t("NotApproved")
              : "",
        };
        elementList.push(element);
      }
    } else if (
      Type === `${t("LeaveRequest")}` &&
      leaveEmployeesList &&
      leaveEmployeeWithdrawsList
    ) {
      leaveEmployeesList.map((r) => {
        if (r.isApprove === null) {
          dataToExcelWaiting.push(r);
        } else {
          dataToExcel.push(r);
        }
      });

      leaveEmployeeWithdrawsList.map((r) => {
        if (r.isApprove === null) {
          dataToExcelWaiting.push(r);
        } else {
          dataToExcel.push(r);
        }
      });
      for (let index = 0; index < dataToExcelWaiting.length; index++) {
        const element = {
          // ลำดับที่: dataToExcelWaiting[index].idLeave,
          รหัสพนักงาน: dataToExcelWaiting[index].employeeID,
          "ชื่อ-สกุล": `${dataToExcelWaiting[index].firstname_TH} ${dataToExcelWaiting[index].lastname_TH}`,
          ตำแหน่ง: dataToExcelWaiting[index].positionName
            ? dataToExcelWaiting[index].positionName
            : "",
          ประเภทการจ่ายเงินเดือน: dataToExcelWaiting[index].paymentRoundName
            ? dataToExcelWaiting[index].paymentRoundName
            : "",
          ประเภทคำขอ: dataToExcelWaiting[index].name
            ? dataToExcelWaiting[index].name
            : "",
          เวลาเริ่มต้น: dataToExcelWaiting[index].startText
            ? dataToExcelWaiting[index].startText
            : "",
          เวลาสิ้นสุด: dataToExcelWaiting[index].endText
            ? dataToExcelWaiting[index].endText
            : "",
          "จำนวนที่ใช้ไป (วัน)": dataToExcelWaiting[index].used
            ? dataToExcelWaiting[index].used
            : "",
          เหตุผล: dataToExcelWaiting[index].description
            ? dataToExcelWaiting[index].description
            : "-",

          ผู้บังคับบัญชา: dataToExcelWaiting[index].manager_firstname_TH
            ? `${dataToExcelWaiting[index].manager_firstname_TH} ${dataToExcelWaiting[index].manager_lastname_TH}`
            : "-",
          "E-mail ผู้บังคับบัญชา": dataToExcelWaiting[index].emailManagerLV1
            ? dataToExcelWaiting[index].emailManagerLV1
            : "-",
        };
        elementListWaiting.push(element);
      }

      for (let index = 0; index < dataToExcel.length; index++) {
        const element = {
          // ลำดับที่: dataToExcel[index].idRequestTime,
          รหัสพนักงาน: dataToExcel[index].employeeID,
          "ชื่อ-สกุล": `${dataToExcel[index].firstname_TH} ${dataToExcel[index].lastname_TH}`,
          ตำแหน่ง: dataToExcel[index].positionName
            ? dataToExcel[index].positionName
            : "",
          ประเภทคำขอ: dataToExcel[index].name ? dataToExcel[index].name : "",
          เวลาเริ่มต้น: dataToExcel[index].startText
            ? dataToExcel[index].startText
            : "",
          เวลาสิ้นสุด: dataToExcel[index].endText
            ? dataToExcel[index].endText
            : "",
          "จำนวนที่ใช้ไป (วัน)": dataToExcel[index].used
            ? dataToExcel[index].used
            : "",
          เหตุผล: dataToExcel[index].description
            ? dataToExcel[index].description
            : "-",
          "เหตุผล(เพิ่มเติม)": dataToExcel[index].otherReason
            ? dataToExcel[index].otherReason
            : "-",
          วันเวลาที่ทำรายการ: dataToExcel[index].createDateText
            ? dataToExcel[index].createDateText
            : "-",
          ผู้บังคับบัญชา: dataToExcel[index].managerLV1_firstname_TH
            ? dataToExcel[index].managerLV1_firstname_TH
            : "-",
          "E-mail ผู้บังคับบัญชา": dataToExcel[index].emailManagerLV1
            ? dataToExcel[index].emailManagerLV1
            : "-",
          "ผลอนุมัติ ผู้บังคับบัญชา":
            dataToExcel[index].isApprove === null
              ? t("PendingApproval")
              : dataToExcel[index].isApprove === 1
              ? t("Approved")
              : t("NotApproved"),
          "วันที่อนุมัติ ผู้บังคับบัญชา":
            dataToExcel[index].isApprove === null
              ? ""
              : dataToExcel[index].approveDateText,
        };
        elementList.push(element);
      }
    } else {
      handleChangeAlertType(t("NoData"));
      handleOpenAlert(true);
    }

    if (elementListWaiting.length > 0 || elementList.length > 0) {
      const wsWaiting = XLSX.utils.json_to_sheet(elementListWaiting);
      const ws = XLSX.utils.json_to_sheet(elementList);
      var workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, wsWaiting, t("PendingApproval"));
      XLSX.utils.book_append_sheet(workbook, ws, t("ApprovedPayroll"));
      XLSX.writeFile(workbook, `${t("ItemName")}${Type}.xlsx`, { type: "file" });
    }
  };

  const submit = async () => {
    const formData = {
      ReportName: "approvelist",
      idEmployees: userProfile.idEmployees,
      day: autoEmail == `${t("LastDayOfMonth")}` ? 0 : autoEmail,
    };
    const result = await dispatch(addEmailReport(formData));
    if (result.status === 200) {
      handleChangeAlertType("success");
      handleOpenAlert(true);
    } else {
      handleChangeAlertType(`${t("Unsuccessful")}`);
      handleOpenAlert(true);
    }
  };

  return (
    <DrawerCustom
      title={`${t("RequestReport")}`}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot style={{ width: 400 }}>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Grid item xs={12} sm={6} className="GridTopicInput">
            {t("EmailSentDate")}
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledBoxSearch>
              <StyledAutocomplete
                options={[
                  `${t("LastDayOfMonth")}`,
                  1,
                  2,
                  3,
                  4,
                  5,
                  6,
                  7,
                  8,
                  9,
                  10,
                  11,
                  12,
                  13,
                  14,
                  15,
                  16,
                  17,
                  18,
                  19,
                  20,
                  21,
                  22,
                  23,
                  24,
                  25,
                  26,
                  27,
                  28,
                ]}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setAutoEmail(newValue);
                  } else {
                    setAutoEmail(null);
                  }
                }}
                value={autoEmail == 0 ? `${t("LastDayOfMonth")}` : autoEmail}
                popupIcon={<i class="fa-light fa-chevron-down"></i>}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={"filled"}
                    placeholder={`${t("SelectDate")}`}
                  />
                )}
                PopperComponent={StyledPopper}
              />
            </StyledBoxSearch>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "16px",
            }}
          >
            <ButtonBlue
              variant="outlined"
              startIcon={<SaveIcon />}
              onClick={submit}
              disabled={autoEmail === null}
            >
              {t("Save")}
            </ButtonBlue>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={6} className="GridTopicInput">
            {t("SearchDate")}
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePickerCustom
              minDate={dayjs().subtract(1, 'year')}
              maxDate={dayjs()}
              inputFormat="MMMM YYYY"
              value={inputSearch}
              name="start"
              views={['year', 'month']}
              openTo={'month'}
              onChange={(newValue) => {
                setInputSearch(newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="GridTopicInput">
            {t("SelectCompany")}
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectCompany
              hideLabel={true}
              handleChangeCompany={handleChangeCompany}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Grid item xs={12} sm={6} className="GridTopicInput">
            {t("RequestType")}
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledAutocomplete
              options={List}
              onChange={(event, newValue) => {
                if (newValue) {
                  setType(newValue);
                } else {
                  setType(null);
                }
              }}
              popupIcon={<i className="fa-light fa-chevron-down"></i>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder={`${t("PleaseSelectTypeOfRequest")}`}
                />
              )}
              PopperComponent={StyledPopper}
              noOptionsText={`${t("NoData")}`}
            />
          </Grid>
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleClose}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue
            variant="outlined"
            startIcon={<DownloadRoundedIcon />}
            onClick={handleSubmit}
            disabled={Type === null || selectedCompany === null}
          >
            {t("Download")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </DrawerCustom>
  );
};

export default Approve;
