import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Button,
  Container,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  TableCell,
  MenuItem,
  Grid,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteWarningLetter,
  getWarningLetter,
  getWarningLevel,
} from "../../../../actions/warningLetter";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from "../../shared/general/Card";
import DialogCreate from "./dialogCreate";
import DialogDetail from "./dialogDetail";
import DialogWarningLevel from "./dialogWarningLevel";
import ChipWarningLevel from "./chipWarningLevel";
import ChipLetterStatus from "./chipLetterStatus";
import { WarningLetterXlsxFile } from "./warningLetterXlsxFile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../utils/userData";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../actions/company";
import MonthPickerCustom from "../../shared/date/monthPicker";
import SelectEmployees from "../../shared/general/selectEmployees";
import LoadingGif from "../../assets/social-media.gif";

const StyledRoot = styled("div")({
  backgroundColor: "#f5f5f5",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const WrapHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
  marginTop: 8,
  marginBottom: 16,
});

const WarningLetter = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const {
    result: affiliateOrganization,
    isFetching: fetchingAffiliateOrganization,
  } = useSelector((state) => state.affiliateOrganization);
  const { result: warningLevel } = useSelector((state) => state.warningLevel);
  const { result: warningLetter, isFetching } = useSelector(
    (state) => state.warningLetter
  );

  const [open, setOpen] = useState(false);
  const [openWarningLevel, setOpenWarningLevel] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [employeeSelected, setEmployeeSelected] = useState(null);
  const [selectLetter, setSelectLetter] = useState(null);
  const [companySelected, setCompanySelected] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const [filterStatus, setFilterStatus] = useState("all");
  const [filterLevel, setFilterLevel] = useState("all");

  const handleChangeFilterStatus = (event) => {
    setFilterStatus(event.target.value);
  };

  const handleChangeFilterLevel = (event) => {
    setFilterLevel(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectLetter(null);
  };

  const handleClickOpenWarningLevel = () => {
    setOpenWarningLevel(true);
  };

  const handleCloseWarningLevel = () => {
    setOpenWarningLevel(false);
  };

  const handleClickOpenDetail = (item) => {
    setOpenDetail(true);
    setSelectLetter(item);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  const handleClickOpenEdit = (item) => {
    setOpen(true);
    setSelectLetter(item);
  };

  const handleOpenDelete = (item) => {
    setOpenDelete(true);
    setSelectLetter(item);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDeleteWarningLetter = async () => {
    const res = await dispatch(
      deleteWarningLetter(selectLetter.idWarningLetter)
    );
    if (res) {
      handleCloseDelete();
      dispatch(getWarningLetter());
    }
  };

  const handleChangeCompanySelected = (event, value) => {
    setCompanySelected(value);
    setFilterDate(null);
    setFilterStatus("all");
    setFilterLevel("all");
    setEmployeeSelected(null);
  };

  const filterData = () => {
    let dataFiltered = [...warningLetter];
    if (employeeSelected) {
      dataFiltered = dataFiltered.filter(
        (value) => employeeSelected.idEmployees === value.idEmployees
      );
    }
    if (filterDate) {
      dataFiltered = dataFiltered.filter((item) =>
        dayjs(item.createDate).isSame(filterDate, "month")
      );
    }
    if (filterStatus !== "all") {
      dataFiltered = dataFiltered.filter(
        (item) => item.isAccept === filterStatus
      );
    }
    if (filterLevel !== "all") {
      dataFiltered = dataFiltered.filter(
        (item) => item.idWarningLevel === filterLevel
      );
    }

    return dataFiltered;
  };

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (affiliateOrganization && affiliateOrganization.length > 0) {
      setCompanySelected(affiliateOrganization[0]);
    }
  }, [affiliateOrganization]);

  useEffect(() => {
    if (companySelected) {
      dispatch(getWarningLetter(companySelected.idCompany));
      dispatch(getWarningLevel(companySelected.idCompany));
    }
  }, [companySelected]);

  return (
    <StyledRoot className={"page"}>
      {affiliateOrganization && (
        <Container maxWidth="lg">
          <WrapHeader>
            <Typography variant="h4">{t("WarningLetter")}</Typography>
          </WrapHeader>
          <CardStyle style={{ padding: 16 }}>
            <Grid container spacing={1} marginBottom={2}>
              <Grid item xs={12} sm={6} marginTop={"26px"}>
                <Typography
                  fontSize="14px"
                  fontWeight="600"
                  marginBottom="4px"
                  color="text.third"
                >
                  {t("Company")}
                </Typography>
                <SelectAffiliateCompany
                  options={affiliateOrganization}
                  value={companySelected}
                  onChange={handleChangeCompanySelected}
                  disabled={fetchingAffiliateOrganization}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectEmployees
                  value={employeeSelected}
                  handleChange={setEmployeeSelected}
                  selectedCompany={companySelected}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} marginBottom={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  fontSize="14px"
                  fontWeight="600"
                  marginBottom="4px"
                  color="text.third"
                >
                  {t("SelectMonth")}
                </Typography>
                <MonthPickerCustom
                  value={filterDate}
                  onChange={(newValue) => setFilterDate(newValue)}
                  views={["month", "year"]}
                  minDate={dayjs().subtract(1, "year")}
                  maxDate={dayjs()}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  fontSize="14px"
                  fontWeight="600"
                  marginBottom="4px"
                  color="text.third"
                >
                  {t("Status")}
                </Typography>
                <TextFieldTheme
                  select
                  fullWidth
                  variant="filled"
                  sx={{
                    "& .MuiSelect-filled": { padding: "16px" },
                  }}
                  value={filterStatus}
                  onChange={handleChangeFilterStatus}
                >
                  <MenuItem value={"all"}>{t("All")}</MenuItem>
                  <MenuItem value={1}>{t("Accept")}</MenuItem>
                  <MenuItem value={0}>{t("Refusal")}</MenuItem>
                </TextFieldTheme>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  fontSize="14px"
                  fontWeight="600"
                  marginBottom="4px"
                  color="text.third"
                >
                  {t("WarningLevel")}
                </Typography>
                <TextFieldTheme
                  select
                  fullWidth
                  variant="filled"
                  sx={{
                    "& .MuiSelect-filled": { padding: "16px" },
                  }}
                  value={filterLevel}
                  onChange={handleChangeFilterLevel}
                >
                  <MenuItem value={"all"}>{t("All")}</MenuItem>
                  {warningLevel &&
                    warningLevel.map((level) => (
                      <MenuItem value={level.idWarningLevel}>
                        {level.name}
                      </MenuItem>
                    ))}
                </TextFieldTheme>
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "8px",
                marginBottom: "24px",
              }}
            >
              <ButtonBlue
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleClickOpen}
              >
                {t("AddItem")}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                startIcon={<SettingsIcon />}
                onClick={handleClickOpenWarningLevel}
              >
                {t("ManageWarningLevel")}
              </ButtonBlue>
              <ButtonBlue
                variant="outlined"
                startIcon={<FileDownloadIcon />}
                onClick={() => WarningLetterXlsxFile(t, warningLetter)}
              >
                {t("Download")}
              </ButtonBlue>
            </div>
            {warningLevel && openWarningLevel && (
              <DialogWarningLevel
                open={openWarningLevel}
                handleClose={handleCloseWarningLevel}
              />
            )}
            {warningLevel && open && (
              <DialogCreate
                open={open}
                handleClose={handleClose}
                data={selectLetter}
              />
            )}
            {selectLetter && openDetail && (
              <DialogDetail
                open={openDetail}
                handleClose={handleCloseDetail}
                data={selectLetter}
              />
            )}
            {openDelete && (
              <Dialog open={openDelete}>
                <DialogTitle>{t("ConfirmDeleteWarningLetter")}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t("WarningLetterWillBePermanentlyDeleted")}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button color="error" onClick={handleCloseDelete}>
                    {t("Cancel")}
                  </Button>
                  <ButtonBlue
                    variant="contained"
                    onClick={handleDeleteWarningLetter}
                  >
                    {t("Confirm")}
                  </ButtonBlue>
                </DialogActions>
              </Dialog>
            )}
            {isFetching ? (
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={LoadingGif} style={{ width: 100, height: 100 }} />
              </Box>
            ) : warningLetter && warningLetter.length > 0 ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 960 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">{t("WarningLevel")}</TableCell>
                      <TableCell align="center">{t("Date")}</TableCell>
                      <TableCell align="center">
                        {t("WarningLetterName")}
                      </TableCell>
                      <TableCell align="center">
                        {t("EmployeeBeWarning")}
                      </TableCell>
                      <TableCell align="center">{t("Status")}</TableCell>
                      <TableCell align="center">{t("Descriptions")}</TableCell>
                      <TableCell align="center">{t("Action")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filterData().map((item) => (
                      <TableRow key={item.idWarningLetter}>
                        <TableCell align="center">
                          <ChipWarningLevel
                            id={item.idWarningLevel}
                            label={t(item.level)}
                          />
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(item.createDate).format(
                            i18n.resolvedLanguage === "th"
                              ? "DD/MM/BBBB"
                              : "DD/MM/YYYY"
                          )}
                        </TableCell>
                        <TableCell align="center">{item.title}</TableCell>
                        <TableCell align="center">
                          {getUserFullName(item)}
                        </TableCell>
                        <TableCell align="center">
                          <ChipLetterStatus status={item.isAccept} />
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            variant="outlined"
                            onClick={() => handleClickOpenDetail(item)}
                          >
                            {t("Descriptions")}
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            style={{ color: "#ffea00" }}
                            onClick={() => handleClickOpenEdit(item)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            style={{ color: "#ff1744" }}
                            onClick={() => handleOpenDelete(item)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>{t("ThereIsNoWarningLetter")}</Typography>
            )}
          </CardStyle>
        </Container>
      )}
    </StyledRoot>
  );
};

export default WarningLetter;
