import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  Grid,
} from '@mui/material';
import { NotListedLocation } from '@mui/icons-material';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  dialog: {
    padding: 16,
    position: 'absolute',
    top: 40,
  },
  dialogTitle: {
    textAlign: 'center',
  },
  dialogContent: {
    textAlign: 'center',
  },
  DialogActions: {
    justifyContent: 'center',
    marginTop: 24,
  },
  titleIcon: {
    backgroundColor: "#ba68c8",
    color: "#9c27b0",
    '&:hover': {
      backgroundColor: "#ba68c8",
      cursor: 'default',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '8rem',
    },
  },
  headerContent: {
    fontWeight: 600,
  },
  button: {
    fontWeight: 600,
    border: '2px solid',
    '&:hover': {
      border: '2px solid',
    },
  },
}));

export default function ConfirmDialog(props) {
  const { confirmDialog, setConfirmDialog } = props;
  const classes = useStyles();

  return (
    <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
      <DialogContent className={classes.dialogContent}>
        <Typography variant='h6' className={classes.headerContent}>
          {confirmDialog.title}
        </Typography>
        <Typography variant='subtitle2'>{confirmDialog.subTitle}</Typography>
      </DialogContent>
      <DialogActions className={classes.DialogActions}>
        <Grid container justifyContent='center' spacing={4}>
          <Grid item>
            <Button
              color='primary'
              variant='outlined'
              className={classes.button}
              onClick={() =>
                setConfirmDialog({ ...confirmDialog, isOpen: false })
              }
            >
              No
            </Button>
          </Grid>
          <Grid item>
            <Button
              color='primary'
              variant='outlined'
              className={classes.button}
              onClick={confirmDialog.onConfirm}
            >
              Yes
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
