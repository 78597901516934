import { httpClient } from "./httpClient";

const getManagerSetting = () => {
  return httpClient.get("manager-setting");
};

const updateManagerSetting = (idManagerSetting, formData) => {
  return httpClient.put(`manager-setting/${idManagerSetting}`, formData);
};

const getManager = (query) => {
  return httpClient.get("v2/manager", { params: query });
};

const addManager = (formData) => {
  return httpClient.post(`/manager`, formData);
};

const updateManager = (idEmployees, formData) => {
  return httpClient.put(`/manager/${idEmployees}`, formData);
};

const getManagerGroup = (query) => {
  return httpClient.get(`/manager-group`, { params: query });
};

const addManagerGroup = (formData) => {
  return httpClient.post(`/manager-group`, formData);
};

const updateManagerGroup = (idManagerGroup, formData) => {
  return httpClient.put(`/manager-group/${idManagerGroup}`, formData);
};

const deleteManagerGroup = (idManagerGroup) => {
  return httpClient.delete(`/manager-group/${idManagerGroup}`);
};

const getManagerApproveSetting = (query) => {
  return httpClient.get(`/manager-approve-setting`, { params: query});
};

export default {
  getManagerSetting,
  updateManagerSetting,
  getManager,
  addManager,
  updateManager,
  getManagerGroup,
  addManagerGroup,
  updateManagerGroup,
  deleteManagerGroup,
  getManagerApproveSetting,
};
