import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';
import { Link } from "react-router-dom";
import {
  Avatar, Grid, Box, Card, Container, styled, Chip, TablePagination, Typography, Rating, InputLabel, Select, ListItemText,
  MenuItem, Checkbox, FormControl, OutlinedInput, TextField, IconButton,
} from "@mui/material";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { stringToColor } from "../../../../../utils";
import { getAllUsersByIdVendor } from "../../../../../actions/employee";
import { getManpowerForSelectToInterviewJob, addManpowersToCandidate } from "../../../../../actions/candidate";
import { getJobGroup } from "../../../../../actions/jobRecruit";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CompleteImage from "./../../../assets/complete.png";
import { useTranslation } from "react-i18next";
var buddhistEra = require('dayjs/plugin/buddhistEra');
dayjs.extend(buddhistEra);

const StyledRoot = styled("div")({
  background: "#FFFFFF !important",
  "& .header-container": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .part-total": {
      textAlign: "right",
    }
  },
  "& .part-add-manpower": {
    marginBottom: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "16px",
    "& .search-name": {
      width: 250,
    }
  },
  "& .part-filter": {
    "& .search-name": {
      width: 250,
    }
  },
  "& .part-select": {
    position: "absolute",
    top: 10,
    right: 10,
    // display: "flex",
    // justifyContent: "right",
  },
  "& .part-avatar": {
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    // background: "red",
    "& .MuiAvatar-root": {
      width: 90,
      height: 90,
    },
  },
  "& .part-name": {
    "& .MuiTypography-h5": {
      textAlign: "center",
      fontSize: "1rem",
      fontWeight: "500",
    },
  },
  "& .part-rating": {
    marginTop: 5,
    marginBottom: "18px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .part-infomation": {
    marginBottom: "18px",
    "& .iconandinfo": {
      display: "flex",
      alignItems: "center",
      marginLeft: "10px",
      "& .icon": {
        width: "20px",
        display: "flex",
        justifyContent: "center",
      },
      "& .MuiTypography-info": {
        marginLeft: "15px",
        fontSize: "0.85rem",
        fontWeight: "400",
      },
    },
    "& .iconandinfo:not(:first-of-type)": {
      marginTop: "6px",
    },
  },
  "& .part-status": {
    display: "flex",
    justifyContent: "right",
  },

})

const StyledCard = styled(Card)({
  padding: 24,
  position: "relative",
  boxShadow: "none",
  borderRadius: 16,
  // border: isSelected ? "2px solid blue" :"1px solid #919eab3d",
  border: "none",
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,transform 0.15s ease-in-out",
  "&:hover": {
    cursor: "pointer",
    transition:
      "color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,transform 0.15s ease-in-out",
    transform: "translateY(-10px)",
  },
});

const StyledFormControl = styled(FormControl)({
  width: "300px",
  marginLeft: "10px",
  "& .MuiInputBase-root": {
    lineHeight: "1.4375em",
    borderRadius: "8px",
    "& .MuiSelect-select": {
      padding: "13.5px 14px",
      color: "#000000b8"
    }
  }
})

const StyledChipAlrJob = styled(Chip)({
  width: "130px",
  borderRadius: 4,
  height: "unset",
  padding: 5,
  // backgroundColor: "#eb7d7d",
  // color: "#b81a1a",
  backgroundColor: "#FF4040",
  color: "#FFFFFF",
  fontSize: 12,
});

const StyledChipFree = styled(Chip)({
  width: "130px",
  borderRadius: 4,
  height: "unset",
  padding: 5,
  fontSize: 12,
  // backgroundColor: "#76d576",
  // color: "#0b5f10",
  backgroundColor: "#2CC04D",
  color: "#FFFFFF"
  // backgroundColor: "#CAFFD6",
  // color: "#3CCC5B"
});

const StyledChipWaiting = styled(Chip)({
  width: "130px",
  borderRadius: 4,
  height: "unset",
  padding: 5,
  fontSize: 12,
  // backgroundColor: "#fff0ce",
  // color: "#ff9800",
  backgroundColor: "#FFD600",
  color: "#FFFFFF"
});

const PageManpowerSelect = (props) => {
  const {t,i18n} = useTranslation();
  const dispatch = useDispatch();
  // console.log(props)

  const idJobRecruit = props.location.state.id;
  const { result: AllJobGroups } = useSelector(state => state.jobRecruitGroup);
  const [isFetching, SetisFetching] = useState(true);

  const [filterAllUsersFree, setFilterAllUsersFree] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(9);

  const [manpowerForSelect, SetManpowerForSelect] = useState([]);
  const [jobGroup, setJobGroup] = useState([]);
  const [selectedListUsers, setSelectedListUsers] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [statusAfterSubmit, setStatusAfterSubmit] = useState({
    label: "",
    isOpen: false,
  })

  const handleChangeJobGroup = (e) => {
    const valueSelect = e.target.value
    if (valueSelect.includes("all")) {
      setJobGroup([])
    } else {
      setJobGroup(typeof valueSelect === 'string' ? valueSelect.split(',') : valueSelect)
    }
  }

  const toggleSelection = (user) => {
    setSelectedListUsers((prevSelectedListUsers) => {
      const isSelected = prevSelectedListUsers.some(selectedUser => selectedUser.idManpower === user.idManpower);
      if (isSelected) {
        return prevSelectedListUsers.filter(selectedUser => selectedUser.idManpower !== user.idManpower);
      } else {
        return [...prevSelectedListUsers, user];
      }
    })
  }

  const DisplayRecentEducation = (educationHistory) => {
    const result = educationHistory.sort((a, b) => b.endYear - a.endYear);
    return result[0].university + ", " + result[0].faculty;
  };

  const Displayage = (date) => {
    let month = dayjs().diff(date, 'month');
    return `${Math.floor(month / 12)} ปี${month % 12 > 0 ? ` ${month % 12} เดือน` : ''}`;
  };

  const DisplayStatus = (idManpowerStatus) => {
    if (idManpowerStatus === 1) {
      return <StyledChipFree label="ว่าง" />
    } else if (idManpowerStatus === 2) {
      return <StyledChipAlrJob label="ไม่ว่าง" />
    } else if (idManpowerStatus === 3) {
      return <StyledChipWaiting label="รอดำเนินการ" />
    } else {
      return <StyledChipWaiting label="null" />
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (idJobRecruit) {
      dispatch(getManpowerForSelectToInterviewJob(idJobRecruit)).then(res => {
        if (isMounted) {
          if (res.status === 200) {
            SetManpowerForSelect(res.data);
            SetisFetching(false);
          }
        }
      })

      dispatch(getJobGroup());

    } else {
      props.history.push("/recruitment");
    }
    return () => {
      isMounted = false;
    };

  }, []);


  useEffect(() => {
    if (manpowerForSelect) {
      const filteredUsers = manpowerForSelect.filter((can) => {
        const universityMatch = can.educationHistory && can.educationHistory.some(edu => edu.university.toLowerCase().includes(search.toLowerCase()));
        const jobGroupsMatch = jobGroup.length === 0 || (can.jobGroups && can.jobGroups.some(job => jobGroup.includes(job.idJobGroup)));
        const searchMatch = can.name.toLowerCase().includes(search.toLowerCase()) ||
          can.lastname.toLowerCase().includes(search.toLowerCase()) ||
          (search === "0" ? can["OESY(Y)"] == null : (can["OESY(Y)"] && can["OESY(Y)"].toString().includes(search))) ||
          (can.prevJob && can.prevJob.lastCompanyName && can.prevJob.lastCompanyName.toLowerCase().includes(search.toLowerCase())) ||
          universityMatch;
        return searchMatch && jobGroupsMatch;
      });
      setFilterAllUsersFree(filteredUsers);
    }
  }, [manpowerForSelect, search, jobGroup]);


  const handleSubmit = async () => {
    setIsButtonDisabled(true);
    // console.log(selectedListUsers);
    const result = await dispatch(addManpowersToCandidate(selectedListUsers, parseInt(idJobRecruit)));
    if (result) {
      setStatusAfterSubmit({ label: "ส่งคนสำเร็จ", isOpen: true });
    }
    setTimeout(() => {
      if(statusAfterSubmit.isOpen){
        setIsButtonDisabled(false);
      }
    }, 1000)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const currentData = filterAllUsersFree.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  // console.log(currentData);

  return (
    <StyledRoot className="page">
      {!statusAfterSubmit.isOpen && (
        <Container maxWidth="lg">
          <div className="header-container">
            <Typography variant="h4" sx={{ color: "#000000" }} marginTop={2}>เลือกกำลังพล</Typography>
            <div style={{ display: "flex" }}>
              {selectedListUsers.length > 0 && (
                <div className="part-total" style={{ marginRight: 20 }}>
                  <Typography variant="h4" sx={{ color: "#000000" }} marginTop={2} >{selectedListUsers ? selectedListUsers.length : 0}</Typography>
                  <Typography color="text.secondary">{`กำลังพลที่เลือก`}</Typography>
                </div>
              )}
              <div className="part-total">
                <Typography variant="h4" sx={{ color: "#000000" }} marginTop={2} >{manpowerForSelect ? manpowerForSelect.length : 0}</Typography>
                <Typography color="text.secondary">{`กำลังพลทั้งหมด`}</Typography>
              </div>
            </div>
          </div>
          <div className="part-add-manpower">
            <div className="part-filter">
              <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px" }}
                className="search-name"
                placeholder="ค้นหา..."
                label={"ค้นหา..."}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <StyledFormControl sx={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }}>
                <InputLabel id="demo-multiple-chip-label">ค้นหาตามกลุ่มงาน</InputLabel>
                <Select
                  multiple
                  value={jobGroup}
                  onChange={handleChangeJobGroup}
                  input={<OutlinedInput label="ค้นหาตามกลุ่มงาน" />}
                  renderValue={(selected) => selected.map(value => {
                    const job = AllJobGroups.find(job => job.idJobGroup === value);
                    return job ? job.jobGroupName : "";
                  }).join(', ')}
                >
                  <MenuItem value={"all"}>
                    <ListItemText primary={"กลุ่มงานทั้งหมด"} />
                  </MenuItem>
                  {AllJobGroups && AllJobGroups.map((job) => (
                    <MenuItem key={job.idJobGroup} value={job.idJobGroup} >
                      <Checkbox checked={jobGroup.indexOf(job.idJobGroup) > -1} />
                      <ListItemText primary={job.jobGroupName} />
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            </div>
          </div>
          {selectedListUsers.length > 0 && (
            <div className="part-action" style={{ display: "flex", justifyContent: "right", marginBottom: "40px" }}>
              <ButtonBlue
                style={{ marginRight: 12 }}
                variant="outlined"
                onClick={() => setSelectedListUsers([])}
              >
                ยกเลิก
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                startIcon={<i className="fal fa-paper-plane"></i>}
                onClick={handleSubmit}
                disabled={isButtonDisabled}
              >
                ส่งกำลังพลที่เลือก
              </ButtonBlue>
            </div>
          )}
          <Grid container spacing={5}>
            {(isFetching) &&
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  กำลังโหลดข้อมูล
                </div>
              </Grid>
            }
            {(!isFetching && currentData && currentData.length <= 0) &&
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  ไม่พบรายชื่อพนักงาน
                </div>
              </Grid>
            }
            {(!isFetching && currentData.length > 0) &&
              currentData.map((user, index) => (
                <Grid item xs={12} sm={6} md={4} lg={4} key={user.idManpower}>
                  <StyledCard style={selectedListUsers.some(selectedUser => selectedUser.idManpower === user.idManpower) ? { border: "2px solid #1976d2" } : { border: "none" }}>
                    <div className="part-select">
                      <IconButton onClick={() => { toggleSelection({ idManpower: user.idManpower, name: user.name }) }}>
                        {selectedListUsers.some(selectedUser => selectedUser.idManpower === user.idManpower) ? (
                          <CheckBoxIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon />
                        )
                        }
                      </IconButton>
                    </div>
                    <Link to={`/manage/hunter/peer/profile/${user.idManpower}`} style={{ textDecoration: "none", color: "inherit" }}>
                      <div className="part-avatar" onClick={() => toggleSelection(user.idManpower)}>
                        <Avatar sx={{ bgcolor: stringToColor(`${user.name} ${user.lastname}`), fontSize: 25 }} children={`${user.name[0]}${user.lastname[0]}`} />
                      </div>
                      <div className="part-name">
                        <Typography variant="h5">{user.name} {user.lastname}</Typography>
                      </div>
                      <div className="part-rating">
                        <Rating
                          sx={{
                            fontSize: "1.1rem",
                            color: "#FFD600",
                          }}
                          value={user.rating}
                          readOnly
                        />
                      </div>
                      <div className="part-infomation">
                        <div className="iconandinfo">
                          <div className="icon">
                            <i className="fa-solid fa-user-tie"></i>
                          </div>
                          <Typography variant="info">{user.prevJob.lastPositionName ? user.prevJob.lastPositionName : "ไม่ระบุ"}</Typography>
                        </div>
                        <div className="iconandinfo">
                          <div className="icon">
                            <i className="fa-solid fa-building"></i>
                          </div>
                          <Typography variant="info">{user.prevJob.lastCompanyName ? user.prevJob.lastCompanyName : "ไม่ระบุ"}</Typography>
                        </div>
                        <div className="iconandinfo">
                          <div className="icon">
                            <i className="fa-solid fa-graduation-cap"></i>
                          </div>
                          <Typography variant="info">{user.educationDegree ? user.educationDegree : "ไม่ระบุ"}</Typography>
                        </div>
                        <div className="iconandinfo">
                          <div className="icon">
                            <i className="fa-solid fa-building-columns"></i>
                          </div>
                          <Typography variant="info">{user.educationHistory ? DisplayRecentEducation(user.educationHistory) : "ไม่ระบุ"}</Typography>
                        </div>
                        <div className="iconandinfo">
                          <div className="icon">
                            <i className="fa-regular fa-clock"></i>
                          </div>
                          <Typography variant="info">{user.dateOfBirth ? Displayage(user.dateOfBirth) : "ไม่ระบุอายุ"}</Typography>
                        </div>
                        <div className="iconandinfo">
                          <div className="icon">
                            <i className="fa-solid fa-business-time"></i>
                          </div>
                          <Typography variant="info">{user['OESY(Y)'] && `${user['OESY(Y)']} ปี`} {user['OESY(M)'] && `${user['OESY(M)']} เดือน`} {user && !user['OESY(Y)'] && !user['OESY(M)'] && "ไม่มีประสบการณ์ทำงาน"}</Typography>
                        </div>
                        <div className="iconandinfo">
                          <div className="icon">
                            <i className="fa-solid fa-sack-dollar"></i>
                          </div>
                          <Typography variant="info">{user.expectedSalary ? user.expectedSalary.toLocaleString() : "ไม่ระบุ"}</Typography>
                        </div>
                      </div>
                      <div className="part-status">
                        {DisplayStatus(user.idManpowerStatus)}
                      </div>
                    </Link>
                  </StyledCard>
                </Grid>
              ))}
          </Grid>

          <TablePagination style={{ paddingTop: "20px" }}
            rowsPerPageOptions={[3, 6, 9, 18]}
            component="div"
            count={manpowerForSelect ? manpowerForSelect.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${
                            count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
          />
        </Container>
      )}

      {/* component after submit */}

      {statusAfterSubmit.isOpen && (
        <Container maxWidth="lg">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "calc(100vh - 160px)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "80px",
              }}
            >
              <img
                style={{ width: 300, marginBottom: "20px" }}
                src={CompleteImage}
              />
              <Typography variant="h4" style={{ marginBottom: "40px" }}>
                {statusAfterSubmit.label}
              </Typography>
              <ButtonBlue
                component={Link}
                variant="outlined"
                style={{ borderRadius: "25px", width: "100px" }}
                // onClick={handleCloseDisplay}
                to={`/company/request/${idJobRecruit}`}
              >
                <Typography variant="h6">ปิด</Typography>
              </ButtonBlue>
            </div>
          </div>
        </Container>
      )}
    </StyledRoot>
  )
}

export default PageManpowerSelect;






// const StyledRoot = styled("div")({
//   background: "#FFFFFF !important",
//   "& .header-container": {
//     marginBottom: 24,
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     "& .part-total": {
//       textAlign: "right",
//     },
//   },
//   "& .part-add-manpower": {
//     marginBottom: "40px",
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     flexWrap: "wrap",
//     gap: "16px",
//     "& .search-name": {
//       width: 250,
//     },
//   },
//   "& .part-filter": {
//     "& .search-name": {
//       width: 250,
//     },
//   },
//   "& .part-avatar": {
//     marginBottom: 10,
//     display: "flex",
//     justifyContent: "center",
//     // background: "red",
//     "& .MuiAvatar-root": {
//       width: 90,
//       height: 90,
//     },
//   },
//   "& .part-name": {
//     "& .MuiTypography-h5": {
//       textAlign: "center",
//       fontSize: "1rem",
//       fontWeight: "500",
//     },
//   },
//   "& .part-rating": {
//     marginTop: 5,
//     marginBottom: "18px",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   "& .part-infomation": {
//     marginBottom: "18px",
//     "& .iconandinfo": {
//       display: "flex",
//       alignItems: "center",
//       marginLeft: "10px",
//       "& .icon": {
//         width: "20px",
//         display: "flex",
//         justifyContent: "center",
//       },
//       "& .MuiTypography-info": {
//         marginLeft: "15px",
//         fontSize: "0.85rem",
//         fontWeight: "400",
//       },
//     },
//     "& .iconandinfo:not(:first-of-type)": {
//       marginTop: "6px",
//     },
//   },
//   "& .part-status": {
//     display: "flex",
//     justifyContent: "right",
//   },
// });

// const StyledCard = styled(Card)(({ isSelected }) => ({
//   padding: 24,
//   position: "relative",
//   boxShadow: "none",
//   borderRadius: 16,
//   border: isSelected ? "2px solid blue" : "1px solid #919eab3d",
//   transition: "color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,transform 0.15s ease-in-out",
//   "&:hover": {
//     cursor: "pointer",
//     transition: "color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,transform 0.15s ease-in-out",
//     transform: "translateY(-10px)",
//   },
// }));

// const StyledFormControl = styled(FormControl)({
//   width: "300px",
//   marginLeft: "10px",
//   "& .MuiInputBase-root": {
//     lineHeight: "1.4375em",
//     borderRadius: "8px",
//     "& .MuiSelect-select": {
//       padding: "13.5px 14px",
//       color: "#000000b8",
//     },
//   },
// });

// const StyledChipAlrJob = styled(Chip)({
//   width: "130px",
//   borderRadius: 4,
//   height: "unset",
//   padding: 5,
//   backgroundColor: "#FF4040",
//   color: "#FFFFFF",
//   fontSize: 12,
// });

// const StyledChipFree = styled(Chip)({
//   width: "130px",
//   borderRadius: 4,
//   height: "unset",
//   padding: 5,
//   fontSize: 12,
//   backgroundColor: "#2CC04D",
//   color: "#FFFFFF",
// });

// const StyledChipWaiting = styled(Chip)({
//   width: "130px",
//   borderRadius: 4,
//   height: "unset",
//   padding: 5,
//   fontSize: 12,
//   backgroundColor: "#FFD600",
//   color: "#FFFFFF",
// });

// const PageManpowerSelect = (props) => {
//   const dispatch = useDispatch();
//   const idJobRecruit = props.match.params.id;
//   const { result: AllJobGroups } = useSelector((state) => state.jobRecruitGroup);
//   const [isFetching, SetisFetching] = useState(true);
//   const [filterAllUsersFree, setFilterAllUsersFree] = useState([]);
//   const [search, setSearch] = useState("");
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(9);
//   const [manpowerForSelect, SetManpowerForSelect] = useState([]);
//   const [jobGroup, setJobGroup] = useState([]);
//   const [selectedIds, setSelectedIds] = useState([]);

//   const handleChangeJobGroup = (e) => {
//     const valueSelect = e.target.value;
//     if (valueSelect.includes("all")) {
//       setJobGroup([]);
//     } else {
//       setJobGroup(typeof valueSelect === "string" ? valueSelect.split(",") : valueSelect);
//     }
//   };

//   const toggleSelection = (id) => {
//     setSelectedIds((prevSelectedIds) => {
//       if (prevSelectedIds.includes(id)) {
//         return prevSelectedIds.filter((selectedId) => selectedId !== id);
//       } else {
//         return [...prevSelectedIds, id];
//       }
//     });
//   };

//   const DisplayRecentEducation = (educationHistory) => {
//     const result = educationHistory.sort((a, b) => b.endYear - a.endYear);
//     return result[0].university + ", " + result[0].faculty;
//   };

//   const Displayage = (date) => {
//     let month = dayjs().diff(date, "month");
//     return `${Math.floor(month / 12)} ปี${month % 12 > 0 ? ` ${month % 12} เดือน` : ""}`;
//   };

//   const DisplayStatus = (idManpowerStatus) => {
//     if (idManpowerStatus === 1) {
//       return <StyledChipFree label="ว่าง" />;
//     } else if (idManpowerStatus === 2) {
//       return <StyledChipAlrJob label="ไม่ว่าง" />;
//     } else if (idManpowerStatus === 3) {
//       return <StyledChipWaiting label="รอดำเนินการ" />;
//     } else {
//       return <StyledChipWaiting label="null" />;
//     }
//   };

//   useEffect(() => {
//     let isMounted = true;
//     if (props.location.state) {
//       dispatch(getManpowerForSelectToInterviewJob(props.location.state.id)).then((res) => {
//         if (isMounted) {
//           if (res.status === 200) {
//             SetManpowerForSelect(res.data);
//             SetisFetching(false);
//           }
//         }
//       });

//       dispatch(getJobGroup());
//     } else {
//       props.history.push("/recruitment");
//     }
//     return () => {
//       isMounted = false;
//     };
//   }, []);

//   useEffect(() => {
//     if (manpowerForSelect) {
//       const filteredUsers = manpowerForSelect.filter((can) => {
//         const universityMatch =
//           can.educationHistory &&
//           can.educationHistory.some((edu) => edu.university.toLowerCase().includes(search.toLowerCase()));
//         const jobGroupsMatch =
//           jobGroup.length === 0 || (can.jobGroups && can.jobGroups.some((job) => jobGroup.includes(job.idJobGroup)));
//         const searchMatch =
//           can.name.toLowerCase().includes(search.toLowerCase()) ||
//           can.lastname.toLowerCase().includes(search.toLowerCase()) ||
//           (search === "0" ? can["OESY(Y)"] == null : can["OESY(Y)"] && can["OESY(Y)"].toString().includes(search)) ||
//           (can.prevJob && can.prevJob.lastCompanyName && can.prevJob.lastCompanyName.toLowerCase().includes(search.toLowerCase())) ||
//           universityMatch;
//         return searchMatch && jobGroupsMatch;
//       });
//       setFilterAllUsersFree(filteredUsers);
//     }
//   }, [manpowerForSelect, search, jobGroup]);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };

//   const currentData = filterAllUsersFree.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

//   return (
//     <StyledRoot className="page">
//       <Container maxWidth="lg">
//         <div className="header-container">
//           <Typography variant="h4" sx={{ color: "#000000" }} marginTop={2}>
//             เลือกกำลังพล
//           </Typography>
//           <div className="part-total">
//             <Typography variant="h4" sx={{ color: "#000000" }} marginTop={2}>
//               {manpowerForSelect ? manpowerForSelect.length : 0}
//             </Typography>
//             <Typography color="text.secondary">{`กำลังพลทั้งหมด`}</Typography>
//           </div>
//         </div>
//         <div className="part-add-manpower">
//           <div className="part-filter">
//             <TextField
//               className="search-name"
//               placeholder="ค้นหา..."
//               label={"ค้นหา..."}
//               value={search}
//               onChange={(e) => setSearch(e.target.value)}
//             />
//             <StyledFormControl sx={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }}>
//               <InputLabel id="demo-multiple-chip-label">ค้นหาตามกลุ่มงาน</InputLabel>
//               <Select
//                 multiple
//                 value={jobGroup}
//                 onChange={handleChangeJobGroup}
//                 input={<OutlinedInput label="ค้นหาตามกลุ่มงาน" />}
//                 renderValue={(selected) =>
//                   selected
//                     .map((value) => {
//                       const job = AllJobGroups.find((job) => job.idJobGroup === value);
//                       return job ? job.jobGroupName : "";
//                     })
//                     .join(", ")
//                 }
//               >
//                 <MenuItem value={"all"}>
//                   <ListItemText primary={"กลุ่มงานทั้งหมด"} />
//                 </MenuItem>
//                 {AllJobGroups &&
//                   AllJobGroups.map((job) => (
//                     <MenuItem key={job.idJobGroup} value={job.idJobGroup}>
//                       <Checkbox checked={jobGroup.indexOf(job.idJobGroup) > -1} />
//                       <ListItemText primary={job.jobGroupName} />
//                     </MenuItem>
//                   ))}
//               </Select>
//             </StyledFormControl>
//           </div>
//         </div>
//         <Grid container spacing={5}>
//           {isFetching && (
//             <Grid item xs={12} sm={12} md={12} lg={12}>
//               <div style={{ display: "flex", justifyContent: "center" }}>กำลังโหลดข้อมูล</div>
//             </Grid>
//           )}
//           {!isFetching && currentData && currentData.length <= 0 && (
//             <Grid item xs={12} sm={12} md={12} lg={12}>
//               <div style={{ display: "flex", justifyContent: "center" }}>ไม่พบรายชื่อพนักงาน</div>
//             </Grid>
//           )}
//           {!isFetching &&
//             currentData.length > 0 &&
//             currentData.map((user) => (
//               <Grid item xs={12} sm={6} md={4} lg={4} key={user.idManpower}>
//                 <StyledCard isSelected={selectedIds.includes(user.idManpower)}>
//                   <IconButton
//                     style={{ position: "absolute", top: 10, right: 10 }}
//                     onClick={(e) => {
//                       e.stopPropagation();
//                       toggleSelection(user.idManpower);
//                     }}
//                   >
//                     {selectedIds.includes(user.idManpower) ? (
//                       <CheckBoxIcon />
//                     ) : (
//                       <CheckBoxOutlineBlankIcon />
//                     )}
//                   </IconButton>
//                   <Link to={`/manage/hunter/peer/profile/${user.idManpower}`} style={{ textDecoration: "none", color: "inherit" }}>
//                     <div className="part-avatar">
//                       <Avatar sx={{ bgcolor: stringToColor(`${user.name} ${user.lastname}`), fontSize: 25 }} children={`${user.name[0]}${user.lastname[0]}`} />
//                     </div>
//                     <div className="part-name">
//                       <Typography variant="h5">
//                         {user.name} {user.lastname}
//                       </Typography>
//                     </div>
//                     <div className="part-rating">
//                       <Rating sx={{ fontSize: "1.1rem", color: "#FFD600" }} value={user.rating} readOnly />
//                     </div>
//                     <div className="part-infomation">
//                       <div className="iconandinfo">
//                         <div className="icon">
//                           <i className="fa-solid fa-user-tie"></i>
//                         </div>
//                         <Typography variant="info">{user.prevJob.lastPositionName ? user.prevJob.lastPositionName : "ไม่ระบุ"}</Typography>
//                       </div>
//                       <div className="iconandinfo">
//                         <div className="icon">
//                           <i className="fa-solid fa-building"></i>
//                         </div>
//                         <Typography variant="info">{user.prevJob.lastCompanyName ? user.prevJob.lastCompanyName : "ไม่ระบุ"}</Typography>
//                       </div>
//                       <div className="iconandinfo">
//                         <div className="icon">
//                           <i className="fa-solid fa-graduation-cap"></i>
//                         </div>
//                         <Typography variant="info">{user.educationDegree ? user.educationDegree : "ไม่ระบุ"}</Typography>
//                       </div>
//                       <div className="iconandinfo">
//                         <div className="icon">
//                           <i className="fa-solid fa-building-columns"></i>
//                         </div>
//                         <Typography variant="info">{user.educationHistory ? DisplayRecentEducation(user.educationHistory) : "ไม่ระบุ"}</Typography>
//                       </div>
//                       <div className="iconandinfo">
//                         <div className="icon">
//                           <i className="fa-regular fa-clock"></i>
//                         </div>
//                         <Typography variant="info">{user.dateOfBirth ? Displayage(user.dateOfBirth) : "ไม่ระบุอายุ"}</Typography>
//                       </div>
//                       <div className="iconandinfo">
//                         <div className="icon">
//                           <i className="fa-solid fa-business-time"></i>
//                         </div>
//                         <Typography variant="info">
//                           {user["OESY(Y)"] && `${user["OESY(Y)"]} ปี`} {user["OESY(M)"] && `${user["OESY(M)"]} เดือน`} {user && !user["OESY(Y)"] && !user["OESY(M)"] && "ไม่มีประสบการณ์ทำงาน"}
//                         </Typography>
//                       </div>
//                       <div className="iconandinfo">
//                         <div className="icon">
//                           <i className="fa-solid fa-sack-dollar"></i>
//                         </div>
//                         <Typography variant="info">{user.expectedSalary ? user.expectedSalary.toLocaleString() : "ไม่ระบุ"}</Typography>
//                       </div>
//                     </div>
//                     <div className="part-status">{DisplayStatus(user.idManpowerStatus)}</div>
//                   </Link>
//                 </StyledCard>
//               </Grid>
//             ))}
//         </Grid>

//         <TablePagination
//           style={{ paddingTop: "20px" }}
//           rowsPerPageOptions={[3, 6, 9, 18]}
//           component="div"
//           count={manpowerForSelect ? manpowerForSelect.length : 0}
//           rowsPerPage={rowsPerPage}
//           page={page}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//       </Container>
//     </StyledRoot>
//   );
// };

// export default PageManpowerSelect;