import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import XLSX from "xlsx";
import { styled } from "@mui/material/styles";
import {
    Grid,
    Typography,
    TextField,
    Chip,
    Box,
    Divider
} from "@mui/material";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import ForwardRoundedIcon from "@mui/icons-material/ForwardRounded";
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { clearTimeline, getTimelineById, getTimelineMonthById } from "../../../../actions/timeline";
import { useTranslation } from "react-i18next";
import Backdrop from "../../shared/general/Backdrop";
import SelectEmployees from "../../shared/general/selectEmployees";
import DatePickerCustom from "../../shared/date/datePicker";
import axios from "axios";
import { getUserPosition,getUserFirstName,getUserLastName, getUserTitle } from "../../../../utils/userData";

const StyledRoot = styled("div")({
    paddingBottom: 48,
    "& .wrap-check, .wrap-leave": {
        marginTop: 36,
    },
    "& .wrap-check-in, .wrap-check-out, .leave": {
        borderRadius: 16,
        "& .head": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 24,
        },
    },
    "& .leave": {
        "& .filter": {
            padding: 24,
            display: "flex",
            justifyContent: "space-between",
        },
    },
});
const StyledTextField = styled(TextField)({
    width: "100%",
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "& .MuiFilledInput-input": {
            padding: "7px 4px",
        },
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            "& button": {
                color: "#919EAB",
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&::after": {
            border: "none",
        },
        "&::before": {
            border: "none",
        },
    },
});
const StyledChipTime = styled(Chip)({
    height: 28,
    width: 88,
    fontSize: 14,
    borderRadius: 8,
    marginBottom: 4,
    border: "none",
    "&.check-in": {
        color: "#229a16",
        backgroundColor: "#54d62c29",
        "& .MuiSvgIcon-root": {
            color: "#229a16",
            fontSize: 20,
        },
    },
    "&.check-out": {
        color: "#b72136",
        backgroundColor: "#ff484229",
        "& .MuiSvgIcon-root": {
            color: "#b72136",
            fontSize: 20,
            transform: "rotate(180deg)",
        },
    },
});
const StyledWrapFilter = styled("div")({
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: 36,
    ["@media (min-width: 0px)"]: {
        flexDirection: "column",
    },
    ["@media (min-width: 900px)"]: {
        flexDirection: "row",
    },
    "& .search-name, .search-date": {
        width: "100%",
    },
    "& .wrap-search-action": {
        marginTop: 50,
        display: "flex",
        justifyContent: "flex-start",
        "& .btn-export": {
            marginLeft: 8,
        },
    },
});
const StyledBoxSearch = styled(Box)({
    marginTop: 22,
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});
const StyledTimeline = styled(Timeline)({
    [`& .${timelineOppositeContentClasses.root}`]: {
        flex: 0.2,
    },
});
const StyledTimelineContent = styled(TimelineContent)({
    "& .distance": {
        fontWeight: 600,
    },
});
const StyledTimelineOppositeContent = styled(TimelineOppositeContent)({
    "& .show-time": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
    },
});

const AccessTimeline = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { result: timeline } = useSelector((state) => state.timeline);
    const { result: timelineMonth } = useSelector((state) => state.timelineMonth);
    const [search, setSearch] = useState(new Date());
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [checkSearch, setCheckSearch] = useState(false)

    useEffect(() => {
        dispatch(clearTimeline());
    }, []);

    const handleSearch = () => {
        setCheckSearch(true)
        if (selectedEmployee) {
            dispatch(getTimelineById(selectedEmployee.idEmployees, "area", dayjs(search).format("YYYY-MM-DD")))
            dispatch(getTimelineMonthById(selectedEmployee.idEmployees, "area", dayjs(search).format("YYYY-MM-DD")))
        }
    };

    const handleSelectEmployee = (employee) => {
        setSelectedEmployee(employee);
        dispatch(clearTimeline());
        setCheckSearch(false)
    };

    const onExportExcel = () => {
        const elementList = [];
        for (let index = 0; index < timelineMonth.length; index++) {
            const element = {
                id: index + 1,
                title: getUserTitle(timelineMonth[index]),
                firstname: getUserFirstName(timelineMonth[index]),
                lastname: getUserLastName(timelineMonth[index]),
                positionName: getUserPosition(timelineMonth[index]),
                timelineDateTime: timelineMonth[index].attendanceDateTimeText,
                gpsLocationsName: timelineMonth[index].locationName,
                statusCheck: timelineMonth[index].isCheckIn == 1
                    ? t("CheckInTime")
                    : t("CheckOutTime"),
                mile: timelineMonth[index].mile,
            };
            elementList.push(element);
        }
        const workSheet = XLSX.utils.json_to_sheet(elementList);

        workSheet["A1"].v = "#";
        workSheet["B1"].v = t("Title");
        workSheet["C1"].v = t("FirstName");
        workSheet["D1"].v = t("LastName");
        workSheet["E1"].v = t("Position");
        workSheet["F1"].v = t("Date")+t("Time");
        workSheet["G1"].v = t("ClockInLocation");
        workSheet["H1"].v = t("ClockInType");
        workSheet["I1"].v = "ML";

        const workBook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workBook, workSheet, t("TravelLogReport"));
        XLSX.writeFile(workBook,`${t("TravelLogReport")}.xlsx`);
    };

    return (
        <StyledRoot>
            <StyledWrapFilter>
                <Grid container spacing={2} columns={16} alignItems="center">
                    <Grid item xs={4} sm={4}>
                        <StyledBoxSearch>
                            <Typography className="label" color="text.third">
                                {t("SearchDate")}
                            </Typography>
                            <div className="search-date">
                                <DatePickerCustom
                                    value={search}
                                    minDate={new Date(new Date().getFullYear() - 4, 0, 1)}
                                    maxDate={new Date()}
                                    name="start"
                                    views={["year", "month", "day"]}
                                    onChange={(newValue) => {
                                        setSearch(newValue);
                                    }}
                                />
                            </div>
                        </StyledBoxSearch>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SelectEmployees mode={"manager"} handleChange={handleSelectEmployee} />
                    </Grid>
                    <Grid item xs={2} sm={4}>
                        <StyledBoxSearch>
                            <div className="wrap-search-action">
                                <div>
                                    <ButtonBlue
                                        variant="contained"
                                        startIcon={<SearchIcon />}
                                        onClick={handleSearch}
                                    >
                                        {t("Search")}
                                    </ButtonBlue>
                                </div>
                                <div className="btn-export">
                                    <ButtonBlue
                                        variant="outlined"
                                        startIcon={<FileDownloadIcon />}
                                        onClick={onExportExcel}
                                        disabled={!(timelineMonth && timelineMonth.length > 0) || selectedEmployee === null}
                                    >
                                        {t("Download")}
                                    </ButtonBlue>
                                </div>
                            </div>
                        </StyledBoxSearch>
                    </Grid>
                </Grid>
            </StyledWrapFilter>
            {selectedEmployee ?
                checkSearch ?
                    timeline ?
                        <Fragment>
                            <StyledTimeline>
                                {timeline.length > 0 ? timeline.map((item, index) => {
                                    return <><TimelineItem>
                                        <StyledTimelineOppositeContent>
                                            <div className="show-time">
                                                <StyledChipTime
                                                    icon={<ForwardRoundedIcon />}
                                                    className="check-in"
                                                    label={item.timeIn}
                                                    variant="outlined"
                                                />
                                                {item.timeOut &&
                                                    <StyledChipTime
                                                        icon={<ForwardRoundedIcon />}
                                                        className="check-out"
                                                        label={item.timeOut}
                                                        variant="outlined"
                                                    />}
                                            </div>
                                        </StyledTimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineDot />
                                            <TimelineConnector />
                                            {item.timeOut && <TimelineDot />}
                                        </TimelineSeparator>
                                        <StyledTimelineContent>
                                            <Typography variant="h6" component="span">
                                                {item.locationNameIn}
                                            </Typography>
                                            {item.timeOut && <Typography className="distance" color="text.third" style={{ margin: "10px 0px" }}>
                                                {`${item.longitudeOut ? "20" : "-"} km , ${item.diff} , ML ${item.mileOut - item.mileIn} 
                                                , ML ${t("Start")} ${item.mileIn} , ML ${t("End")} ${item.mileOut}`}
                                            </Typography>}
                                            {item.timeOut && <Typography variant="h6" component="span">
                                                {item.locationNameOut}
                                            </Typography>}
                                        </StyledTimelineContent>
                                    </TimelineItem>
                                        <div style={{ margin: 20 }}>
                                            <Divider />
                                        </div></>
                                }) : <Typography fontSize={20}>
                                    {t("NoData")}
                                </Typography>
                                }
                            </StyledTimeline>
                        </Fragment> :
                        <Backdrop open={!timeline} />
                    : <Typography fontSize={20}>
                        {t("PleasePressSearchButton")}
                    </Typography>
                : <Typography fontSize={20}>
                    {t("PleaseSelectEmployee")}
                </Typography>
            }
        </StyledRoot>
    );
};

export default AccessTimeline;
