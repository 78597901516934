import dayjs from 'dayjs';
import ExcelJS from 'exceljs';

export const FamilyXlsx = (t,i18n, result) => {
    const groupedFamilies = result.reduce((acc, item) => {
        const { idEmployees, relationship } = item;

        if (!acc[idEmployees]) {
            acc[idEmployees] = {
                employeeID: item.employeeID,
                empFirstname: i18n.resolveLanguage ==="th" ? item.empFirstname : item.empFirstname_EN ? item.empFirstname_EN : item.empFirstname,
                empLastname: i18n.resolveLanguage ==="th" ? item.empLastname : item.empLastname_EN ? item.empLastname_EN : item.empLastname,
                Father: null,
                Mother: null,
                Spouse: null,
                Children: [null, null, null, null, null]
            };
        }

        if (relationship === "Father") {
            acc[idEmployees].Father = item;
        } else if (relationship === "Mother") {
            acc[idEmployees].Mother = item;
        } else if (relationship === "Spouse") {
            acc[idEmployees].Spouse = item;
        } else {
            const children = acc[idEmployees].Children;
            const emptyChildIndex = children.findIndex(child => child === null);
            if (emptyChildIndex !== -1) {
                children[emptyChildIndex] = item;
            }
        }

        return acc;
    }, {});

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`${t("FamilyInfo")}`);

    const headerValues = [
        { header: t("EmployeeID"), key: "employeeID", width: 40 },
        { header: t("FirstName"), key: "empFirstname", width: 40 },
        { header: t("LastName"), key: "empLastname", width: 40 },
        { header: t("fatherName"), key: "fatherName", width: 40 },

        { header: t("fatherRelationship"), key: "fatherRelationship", width: 40 },
        { header: t("fatherNationalID"), key: "fatherPersonalID", width: 40 },
        { header: t("fatherDateOfBirth"), key: "fatherBirthday", width: 40 },
        { header: t("fatherMaritalStatus"), key: "fatherMaritalStatus", width: 40 },
        { header: t("fatherEducationLevel"), key: "fatherEducation", width: 40 },
        { header: t("fatherDisability"), key: "fatherIsDisabled", width: 40 },
        { header: t("fatherIncome"), key: "fatherIncome", width: 40 },
        { header: t("fatherWelfare"), key: "fatherWelfare", width: 40 },

        { header: t("motherName"), key: "motherName", width: 40 },
        { header: t("motherRelationship"), key: "motherRelationship", width: 40 },
        { header: t("motherNationalID"), key: "motherPersonalID", width: 40 },
        { header: t("motherDateOfBirth"), key: "motherBirthday", width: 40 },
        { header: t("motherMaritalStatus"), key: "motherMaritalStatus", width: 40 },
        { header: t("motherEducationLevel"), key: "motherEducation", width: 40 },
        { header: t("motherDisability"), key: "motherIsDisabled", width: 40 },
        { header: t("motherIncome"), key: "motherIncome", width: 40 },
        { header: t("motherWelfare"), key: "motherWelfare", width: 40 },

        { header: t("spouseName"), key: "spouseName", width: 40 },
        { header: t("spouseRelationship"), key: "spouseRelationship", width: 40 },
        { header: t("spouseNationalID"), key: "spousePersonalID", width: 40 },
        { header: t("spouseDateOfBirth"), key: "spouseBirthday", width: 40 },
        { header: t("spouseMaritalStatus"), key: "spouseMaritalStatus", width: 40 },
        { header: t("spouseEducationLevel"), key: "spouseEducation", width: 40 },
        { header: t("spouseDisability"), key: "spouseIsDisabled", width: 40 },
        { header: t("spouseIncome"), key: "spouseIncome", width: 40 },
        { header: t("spouseWelfare"), key: "spouseWelfare", width: 40 },

        ...[...Array(5).keys()].flatMap(i => [
            { header: `${t("childName")} ${i + 1}`, key: `child${i + 1}Name`, width: 40 },
            { header: `${t("childRelationship")} ${i + 1}`, key: `child${i + 1}Relationship`, width: 40 },
            { header: `${t("childNationalID")} ${i + 1}`, key: `child${i + 1}PersonalID`, width: 40 },
            { header: `${t("childDateOfBirth")} ${i + 1}`, key: `child${i + 1}Birthday`, width: 40 },
            { header: `${t("childMaritalStatus")} ${i + 1}`, key: `child${i + 1}MaritalStatus`, width: 40 },
            { header: `${t("childEducationLevel")} ${i + 1}`, key: `child${i + 1}Education`, width: 40 },
            { header: `${t("childNo")} ${i + 1} ${t("disability")}`, key: `child${i + 1}IsDisabled`, width: 40 },
            { header: `${t("childIncome")} ${i + 1}`, key: `child${i + 1}Income`, width: 40 },
            { header: `${t("childWelfare")} ${i + 1}`, key: `child${i + 1}Welfare`, width: 40 },
        ])
    ];

    worksheet.columns = headerValues;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    };

    const headerRow = worksheet.getRow(1);
    headerRow.height = 50;

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    Object.values(groupedFamilies).forEach(emp => {
        const row = [
            emp.employeeID,
            emp.empFirstname,
            emp.empLastname,
            ...formatFamilyMember(t, emp.Father),
            ...formatFamilyMember(t, emp.Mother),
            ...formatFamilyMember(t, emp.Spouse),
            ...emp.Children
                .concat(Array(5 - emp.Children.length).fill(null))
                .map(child => formatFamilyMember(t, child))
                .flat(),
        ];

        const excelRow = worksheet.addRow(row);

        const contentStyle = {
            font: { size: 18, name: 'TH SarabunPSK' },
            alignment: { horizontal: "center", vertical: 'middle' },
        };

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${t("FamilyInfo")}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
};

const formatFamilyMember = (t, member) => {
    if (!member) return ["-", "-", "-", "-", "-", "-", "-", "-", "-"];
    return [
        `${member.firstname_TH} ${member.lastname_TH}`,
        member.relationship ? t(`${member.relationship}`) : "-",
        member.personalID ? member.personalID : "-",
        member.birthday ? dayjs(member.birthday).format("YYYY-MM-DD") : "-",
        member.maritalStatus ? t(`${member.maritalStatus}`) : "-",
        member.degree ? t(`${member.degree}`) : "-",
        member.isDisabled ? t(`${member.isDisabled}`) : "-",
        member.income ? t(`${member.income}`) : "-",
        member.welfare ? t(`${member.welfare}`) : "-"
    ];
};