import React, { Fragment, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  styled,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import BasicInfo from "./BasicInfo";
import JobInfo from "./jobInfo";
import ManagerInfo from "./managerInfo";
import PaymentInfo from "./paymentInfo";
import FamilyInfo from "./famillyInfo";

import {
  addEmployees,
  getEmployeeRegistrationMetadata,
} from "../../../../../actions/employee";
import Loading from "../../../shared/loading";
import dayjs from "dayjs";
import DialogStatusForm from "../../../shared/general/DialogStatusForm";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { getAffiliateCompany } from "../../../../../actions/affiliate";

const defaultValues = {
  employeeID: "EMPLOYEE-001",
  title_TH: "นาย",
  firstname_TH: "มนตรี",
  lastname_TH: "ชินพงศ์พาณิชย์",
  nickname_TH: "มน",
  title_EN: "Mr.",
  firstname_EN: "Montri",
  lastname_EN: "Chinpongpanich",
  nickname_EN: "Mon",
  gender: "Male",
  birthday: new Date(1999, 4, 30),
  personalID: "1444499809878",
  telephoneMobile: "0988765432",
  email: "sodazamak0123@gmail.com",
  houseNo: "39/30",
  village: "หมู่บ้าน",
  villageNo: "หมู่ที่",
  road: "ถนน",
  alley: "ซอย",
  areaCode: "10160",
  subDistrict: "ตำบล",
  district: "เขต",
  provience: "จังหวัด",
  nationality: "ไทย",
  maritalStatus: "โสด",
  passportNumber: "PASSPORT-888",
  workPermitNumber: "WORK-999",
  emergencyContact: "หนองมน คนบ้านเดียวกัน",
  emergencyPhone: "0999999999",
  emergencyRelationship: "ความสัมพันธ์",

  employmentTime: "Full time",
  idPaymentType: "1",

  hiringDate: null,
  idPosition: "1",
  sectionName: "",
  departmentName: "",
  divisionName: "",
  jobGroupName: "",
  idJobLevel: "",
  idPersonnelLevel: "",
  idEmploymentType: "",
  filed_office: "",
  workingType: "",
  idShift: "",
  workingLocation: "",
  mainWorkingLocationPoint: "",
  isFinger: "1",
  isFingerAffectPayroll: "1",
  methodAttendance: "main",
  contractTermainatoinDate: null,

  salary: "",
  costCenterCharge: "",
  costElementCharge: "",
  bookBank: "",
  bookBankBranchName: "",
  bookID: "",
  reportBankBankName: "",
  reportBankBankID: "",
  reportBankBookBankID: "",
  reportBankName: "",
  reportBankRef: "",
};

const StyledRoot = styled(Box)({
  "& .card-container": {
    padding: "24px",
    // paddingTop: 0,
    "& .title-text": {
      fontSize: "24px",
      paddingBottom: "24px",
    },
  },
  "& .page-control-container": {
    marginTop: "24px",
    display: "flex",
    justifyContent: "space-between",
  },
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .clear-button": {
    marginRight: "16px",
    padding: "4px",
    fontSize: "1rem",
    "&.date": {
      marginRight: "-8px",
    },
    "& svg": {
      fontSize: "1rem",
    },
  },
  "& .MuiTab-root": {
    fontSize: "16px",
    "&.Mui-selected": {
      color: "#46cbe2",
    },
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#46cbe2",
  },
});

const AddEmployeePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [tabValue, setTabValue] = useState(0);

  const [registrationMetadata, setRegistrationMetadata] = useState(null);
  const [dialogStatusConfig, setDialogStatusConfig] = useState({
    open: false,
    statusCode: "",
    statusText: "",
    idEmployees: null,
  });

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateList, isFetching: isLoadingAffiliateList } =
    useSelector((state) => state.affiliate);

  const [isDuplicatedPersonalID, setIsDuplicatedPersonalID] = useState(false);
  const [isDuplicatedEmail, setIsDuplicatedEmail] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const [rehiringConfig, setRehiringConfig] = useState({
    isRehiring: false,
    idEmployees: null,
  });

  const onCloseDialog = () => {
    if (dialogStatusConfig.idEmployees) {
      setDialogStatusConfig((prevState) => ({
        ...prevState,
        open: false,
      }));
      history.push(`/employees/${dialogStatusConfig.idEmployees}/edit`);
    } else {
      setDialogStatusConfig((prevState) => ({
        ...prevState,
        open: false,
      }));
    }
  };

  const validationSchema = yup.object({
    employeeID: yup.string().required(`${t("ThisFieldIsRequired")}`),
    title_TH: yup.string().required(`${t("ThisFieldIsRequired")}`),
    firstname_TH: yup.string().required(`${t("ThisFieldIsRequired")}`),

    title_EN: yup.string().required(`${t("ThisFieldIsRequired")}`),
    firstname_EN: yup.string().required(`${t("ThisFieldIsRequired")}`),

    gender: yup.string().required(`${t("ThisFieldIsRequired")}`),
    birthday: yup
      .date()
      .nullable()
      .required(`${t("ThisFieldIsRequired")}`),
    personalID: yup
      .string()
      .required(`${t("ThisFieldIsRequired")}`)
      .test("len", "ไม่สามารถกรอกข้อมูลเกิน 15 หลักได้", value => value.length <= 15)
      // .length(13, `${t("PleaseEnterNationalIDCardNumber")}`)
      .test(
        "is-duplicate",
        `${t("NationalIDCardNumberDuplicated")}`,
        (value) => {
          if (isDuplicatedPersonalID) {
            return false;
          } else {
            return true;
          }
        }
      ),
    telephoneMobile: yup
      .string()
      .required(`${t("ThisFieldIsRequired")}`)
      .length(10, `${t("PleaseEnterPhoneNumber")}`),
    email: yup
      .string()
      .required(`${t("ThisFieldIsRequired")}`)
      .test("is-duplicate", `${t("EmailDuplicated")}`, (value) => {
        if (isDuplicatedEmail) {
          return false;
        } else {
          return true;
        }
      }),
    emergencyPhone: yup.string().when({
      is: (emergencyPhone) => emergencyPhone.length > 0,
      then: (schema) => schema.length(10, `${t("PleaseEnterPhoneNumber")}`),
    }),

    employmentTime: yup.string().required(`${t("ThisFieldIsRequired")}`),
    idPaymentType: yup.string().required(`${t("ThisFieldIsRequired")}`),

    hiringDate: yup
      .date()
      .nullable()
      .required(`${t("ThisFieldIsRequired")}`),
    idPosition: yup.string().required(`${t("ThisFieldIsRequired")}`),
    idJobLevel: yup.string(),
    idPersonnelLevel: yup.string(),
    idEmploymentType: yup.string().required(`${t("ThisFieldIsRequired")}`),
    filed_office: yup.string().required(`${t("ThisFieldIsRequired")}`),
    workingType: yup.string().required(`${t("ThisFieldIsRequired")}`),
    idShift: yup.string().required(`${t("ThisFieldIsRequired")}`),
    workingLocation: yup.string().required(`${t("ThisFieldIsRequired")}`),

    // mainWorkingLocationPoint: yup.string().required(`${t("ThisFieldIsRequired")}`),

    isFinger: yup.string().required(`${t("ThisFieldIsRequired")}`),
    methodAttendance: yup.string().required(`${t("ThisFieldIsRequired")}`),

    mainWorkingLocationPoint: yup.string().when("methodAttendance", {
      is: "main",
      then: yup.string().required(`${t("ThisFieldIsRequired")}`),
    }),

    idManagerLV1: yup.string().required(`${t("ThisFieldIsRequired")}`),
    idManagerLV2: yup.string().required(`${t("ThisFieldIsRequired")}`),

    salary: yup.string().required(`${t("ThisFieldIsRequired")}`),
    bookBank: yup.string().required(`${t("ThisFieldIsRequired")}`),
    bookBankBranchName: yup.string().required(`${t("ThisFieldIsRequired")}`),
    bookID: yup.string().required(`${t("ThisFieldIsRequired")}`),

    familyList: yup.array().of(
      yup.object().shape({
        relationship: yup.string().required(`${t("ThisFieldIsRequired")}`),
        firstname_TH: yup.string().required(`${t("ThisFieldIsRequired")}`),
        lastname_TH: yup.string().required(`${t("ThisFieldIsRequired")}`),
        personalID: yup.string().when({
          is: (personalID) => personalID.length > 0,
          then: (schema) =>
            schema.length(13, `${t("PleaseEnterNationalIDCardNumber")}`),
        }),
        birthday: yup
          .date()
          .nullable()
          .required(`${t("ThisFieldIsRequired")}`),
      })
    ),
  });

  const useFormEmployee = useForm({
    defaultValues: {
      employeeID: "",
      title_TH: "",
      firstname_TH: "",
      lastname_TH: "",
      nickname_TH: "",
      title_EN: "",
      firstname_EN: "",
      lastname_EN: "",
      nickname_EN: "",
      gender: "",
      birthday: null,
      personalID: "",
      telephoneMobile: "",
      email: "",
      houseNo: "",
      village: "",
      villageNo: "",
      road: "",
      alley: "",
      areaCode: "",
      subDistrict: "",
      district: "",
      provience: "",
      nationality: "",
      idReligion: "",
      maritalStatus: "",
      passportNumber: "",
      passportExpiryDate: null,
      workPermitNumber: "",
      workPermitExpiryDate: null,
      emergencyContact: "",
      emergencyPhone: "",
      emergencyRelationship: "",

      employmentTime: "",
      idPaymentType: "",

      hiringDate: null,
      idPosition: "",
      sectionName: "",
      departmentName: "",
      divisionName: "",
      businessUnitName: "",
      jobGroupName: "",
      jobLevelName: "",
      idPersonnelLevel: "",
      idEmploymentType: "",
      filed_office: "",
      workingType: "",
      idShift: "",
      workingLocation: "",
      mainWorkingLocationPoint: "",
      isFinger: "1",
      isFingerAffectPayroll: "1",
      methodAttendance: "all",
      contractTermainatoinDate: null,

      salary: "",
      costCenterCharge: "",
      costElementCharge: "",
      bookBank: "",
      bookBankBranchName: "",
      bookID: "",
      reportBankBankName: "",
      reportBankBankID: "",
      reportBankBookBankID: "",
      reportBankName: "",
      reportBankRef: "",
      familyList: [],
    },
    resolver: yupResolver(validationSchema),
    mode: "all",
    criteriaMode: "firstError ",
  });

  const onSubmitError = (error) => {
    setDialogStatusConfig((prevState) => ({
      ...prevState,
      open: true,
      statusCode: "error",
      statusText: `${t("PleaseEnterRequiredInformationCompletely")}`,
    }));
  };

  const onSubmit = async (data) => {
    const {
      sectionName,
      departmentName,
      divisionName,
      businessUnitName,
      jobGroupName,
      jobLevelName,
      ...submitData
    } = data;

    const formData = {};

    Object.keys(submitData).map((k) => {
      if (submitData[k] === "") {
        formData[k] = null;
      } else {
        formData[k] = submitData[k];
      }
    });

    formData["birthday"] = formData["birthday"]
      ? dayjs(formData["birthday"]).format("YYYY-MM-DD")
      : null;
    formData["passportExpiryDate"] = formData["passportExpiryDate"]
      ? dayjs(formData["passportExpiryDate"]).format("YYYY-MM-DD")
      : null;
    formData["workPermitExpiryDate"] = formData["workPermitExpiryDate"]
      ? dayjs(formData["workPermitExpiryDate"]).format("YYYY-MM-DD")
      : null;
    formData["contractTermainatoinDate"] = formData["contractTermainatoinDate"]
      ? dayjs(formData["contractTermainatoinDate"]).format("YYYY-MM-DD")
      : null;
    formData["hiringDate"] = formData["hiringDate"]
      ? dayjs(formData["hiringDate"]).format("YYYY-MM-DD")
      : null;

    formData["familyList"].map((item) => {
      item.birthday = item.birthday
        ? dayjs(item.birthday).format("YYYY-MM-DD")
        : null;
    });

    formData.idCompany = selectedCompany.idCompany;
    // console.log(formData)

    const response = await dispatch(addEmployees(formData));

    if (response) {
      if (response.status === 200) {
        console.log("Success!");
        setDialogStatusConfig((prevState) => ({
          ...prevState,
          open: true,
          statusCode: "success",
          statusText: response.data.message
            ? response.data.message
            : `${t("Successfully")}`,
          idEmployees: response.data.idEmployees,
        }));
      } else {
        setDialogStatusConfig((prevState) => ({
          ...prevState,
          open: true,
          statusCode: "error",
          statusText: response.data.message,
        }));
      }
    } else {
      console.log("Error!");
    }
  };

  useEffect(() => {
    setRegistrationMetadata(null);
    if (selectedCompany) {
      let isMounted = true;
      dispatch(
        getEmployeeRegistrationMetadata({
          idCompany: selectedCompany.idCompany,
        })
      ).then((res) => {
        if (isMounted && res) {
          if (res.status === 200) {
            setRegistrationMetadata(res.data);
            useFormEmployee.reset({
              employeeID: "",
              title_TH: "",
              firstname_TH: "",
              lastname_TH: "",
              nickname_TH: "",
              title_EN: "",
              firstname_EN: "",
              lastname_EN: "",
              nickname_EN: "",
              gender: "",
              birthday: null,
              personalID: "",
              telephoneMobile: "",
              email: "",
              houseNo: "",
              village: "",
              villageNo: "",
              road: "",
              alley: "",
              areaCode: "",
              subDistrict: "",
              district: "",
              provience: "",
              nationality: "",
              idReligion: "",
              maritalStatus: "",
              passportNumber: "",
              workPermitNumber: "",
              emergencyContact: "",
              emergencyPhone: "",
              emergencyRelationship: "",

              employmentTime: "",
              idPaymentType: "",

              hiringDate: null,
              idPosition: "",
              sectionName: "",
              departmentName: "",
              divisionName: "",
              businessUnitName: "",
              jobGroupName: "",
              jobLevelName: "",
              idPersonnelLevel: "",
              idEmploymentType: "",
              filed_office: "",
              workingType: "",
              idShift: "",
              workingLocation: "",
              mainWorkingLocationPoint: "",
              isFinger: "1",
              isFingerAffectPayroll: "1",
              methodAttendance: "all",
              contractTermainatoinDate: null,

              salary: "",
              costCenterCharge: "",
              costElementCharge: "",
              bookBank: "",
              bookBankBranchName: "",
              bookID: "",
              reportBankBankName: "",
              reportBankBankID: "",
              reportBankBookBankID: "",
              reportBankName: "",
              reportBankRef: "",
              familyList: [],
            });
          }
        }
      });
      return () => {
        isMounted = false;
      };
    }
  }, [selectedCompany]);

  useEffect(() => {
    dispatch(getAffiliateCompany());
  }, []);

  useEffect(() => {
    if (userProfile && affiliateList && affiliateList.length > 0) {
      // console.log("Userprofile", userProfile)
      const foundCompany = affiliateList.find(
        (x) => x.idCompany === userProfile.idCompany
      );
      if (foundCompany) {
        setSelectedCompany(foundCompany);
        // useFormEmployee.resetField("company", {defaultValue: foundCompany})
      } else {
        // setSelectedCompany(null)
        // useFormEmployee.resetField("company", {defaultValue: null})
      }
    }
  }, [userProfile, affiliateList]);

  return (
    <StyledRoot className="page">
      <Container
        maxWidth="lg"
        style={{ paddingTop: "24px", paddingBottom: "24px" }}
      >
        <form onSubmit={useFormEmployee.handleSubmit(onSubmit, onSubmitError)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {!isLoadingAffiliateList && affiliateList ? (
                <CardStyle>
                  <Box padding="16px 16px 24px">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography fontSize="24px" fontWeight="400">
                          {t("AddEmployee")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          color="text.secondary"
                          fontSize="14px"
                          fontWeight="500"
                          marginBottom="8px"
                        >
                          {t("Company")}
                        </Typography>
                        <Autocomplete
                          options={affiliateList ? affiliateList : []}
                          getOptionLabel={(option) => `${option.companyName}`}
                          isOptionEqualToValue={(option, value) =>
                            option.idCompany === value.idCompany
                          }
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              placeholder={`${t("SelectCompany")}`}
                              // onBlur={field.onBlur}
                              // helperText={fieldState.error? fieldState.error.message: null}
                              // error={fieldState.error? true: false}
                            />
                          )}
                          value={selectedCompany}
                          onChange={(_, value) => {
                            setSelectedCompany(value);
                          }}
                          noOptionsText={`${t("NoData")}`}
                          disableClearable
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </CardStyle>
              ) : (
                <Typography fontSize="24px" textAlign="center">{`${t(
                  "LoadingData"
                )}...`}</Typography>
              )}
            </Grid>

            {affiliateList && selectedCompany && registrationMetadata ? (
              <Fragment>
                <Grid item xs={12} md={4}>
                  <CardStyle
                    style={{
                      padding: "16px",
                    }}
                  >
                    <Tabs
                      orientation="vertical"
                      variant="standard"
                      value={tabValue}
                      onChange={(_, newValue) => {
                        setTabValue(newValue);
                      }}
                    >
                      <Tab label={`${t("GeneralInformation")}`} />
                      <Tab label={`${t("JobDescription")}`} />
                      <Tab label={`${t("Manager")}`} />
                      <Tab label={`${t("Payment")}`} />
                      <Tab label={`${t("FamilyInfo")}`} />
                    </Tabs>
                  </CardStyle>
                </Grid>

                <Grid item xs={12} md={8}>
                  <CardStyle>
                    <Box className="card-container">
                      {tabValue === 0 && (
                        <BasicInfo
                          useForm={useFormEmployee}
                          isDuplicatedPersonalID={isDuplicatedPersonalID}
                          setIsDuplicatedPersonalID={setIsDuplicatedPersonalID}
                          isDuplicatedEmail={isDuplicatedEmail}
                          setIsDuplicatedEmail={setIsDuplicatedEmail}
                          rehiringConfig={rehiringConfig}
                          setRehiringConfig={setRehiringConfig}
                          registrationMetadata={registrationMetadata}
                        />
                      )}
                      {tabValue === 1 && (
                        <JobInfo
                          useForm={useFormEmployee}
                          registrationMetadata={registrationMetadata}
                        />
                      )}
                      {tabValue === 2 && (
                        <ManagerInfo
                          useForm={useFormEmployee}
                          registrationMetadata={registrationMetadata}
                        />
                      )}
                      {tabValue === 3 && (
                        <PaymentInfo useForm={useFormEmployee} />
                      )}
                      {tabValue === 4 && (
                        <FamilyInfo
                          useForm={useFormEmployee}
                          registrationMetadata={registrationMetadata}
                        />
                      )}
                    </Box>
                  </CardStyle>

                  <Box className="page-control-container">
                    <ButtonBlue
                      variant="outlined"
                      disabled={tabValue <= 0}
                      onClick={() => {
                        setTabValue((prev) => (prev > 0 ? prev - 1 : prev));
                      }}
                    >
                      {`${t("Back")}`}
                    </ButtonBlue>
                    {tabValue <= 3 && (
                      <ButtonBlue
                        variant="contained"
                        onClick={() => {
                          setTabValue((prev) => prev + 1);
                        }}
                      >
                        {`${t("Next")}`}
                      </ButtonBlue>
                    )}
                    {/* <ButtonBlue variant="contained" type="submit" disabled={useFormEmployee.formState.isSubmitting}>เพิ่มพนักงาน</ButtonBlue> */}
                    {tabValue === 4 && (
                      <ButtonBlue
                        variant="contained"
                        type="submit"
                        disabled={useFormEmployee.formState.isSubmitting || userProfile.readOnly}
                      >
                        {`${t("AddEmployee")}`}
                      </ButtonBlue>
                    )}
                  </Box>
                </Grid>
              </Fragment>
            ) : // <Grid item xs={12}>
            //   <Typography fontSize="24px" textAlign="center">{`${t("LoadingData")}...`}</Typography>
            // </Grid>
            null}
          </Grid>
        </form>
      </Container>
      <DialogStatusForm
        open={dialogStatusConfig.open}
        onClose={() => {
          onCloseDialog();
        }}
        onConfirm={() => {
          onCloseDialog();
        }}
        statusCode={dialogStatusConfig.statusCode}
        statusText={dialogStatusConfig.statusText}
      />
    </StyledRoot>
  );
};

export default AddEmployeePage;
