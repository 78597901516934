import {
  EMEMO_ALL_FETCHING,
  EMEMO_ALL_SUCCESS,
  EMEMO_ALL_FAILED,
} from "../actions/types";

const initialState = {
  eMemos: [],
  loading: false,
  error: null,
};

export default function ememoReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case EMEMO_ALL_FETCHING:
      return { ...state, eMemos: [], loading: true, error: null };
    case EMEMO_ALL_SUCCESS:
      return { ...state, eMemos: payload, loading: false, error: null };
    case EMEMO_ALL_FAILED:
      return { ...state, eMemos: [], loading: false, error: payload };
    default:
      return state;
  }
}
