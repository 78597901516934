import { 
  ORGANIZATION_STRUCTURE_FETCHING,
  ORGANIZATION_STRUCTURE_FAILED, 
  ORGANIZATION_STRUCTURE_SUCCESS,
  MANPOWER_PLANNING_FETCHING,
  MANPOWER_PLANNING_FAILED, 
  MANPOWER_PLANNING_SUCCESS,
  ORGANIZATION_MANAGE_FETCHING,
  ORGANIZATION_MANAGE_FAILED, 
  ORGANIZATION_MANAGE_SUCCESS,
 } from "./types";

import OrganizationStructureService from "../services/organizationStructure.service";

export const getOrganizationStructure = (query) => async (dispatch) => {
  try {
    dispatch({
      type: ORGANIZATION_STRUCTURE_FETCHING,
    });
    const res = await OrganizationStructureService.getOrganizationStructure(query);
    if (res) {
      dispatch({
        type: ORGANIZATION_STRUCTURE_SUCCESS,
        payload: res.data,
      });
    }
    return res;
  } catch (err) {
    dispatch({
      type: ORGANIZATION_STRUCTURE_FAILED,
    });
    return err.response;
  }
};

export const addOrganizationStructure = (formData) => async () => {
  try {
    const res = await OrganizationStructureService.addOrganizationStructure(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    // console.log(error.response);
    if (error.response) {
      return error.response;
    }
  }
};

export const addMDOffice = (formData) => async () => {
  try {
    const res = await OrganizationStructureService.addMDOffice(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    // console.log(error.response);
    if (error.response) {
      return error.response;
    }
  }
};

export const deleteOrganizationStructure = (query) => async () => {
  try {
    const res = await OrganizationStructureService.deleteOrganizationStructure(query);
    if (res) {
      return res;
    }
  } catch (error) {
    // console.log(error.response);
    if (error.response) {
      return error.response;
    }
  }
};

export const getManpowerPlanning = () => async (dispatch) => {
  try {
    dispatch({
      type: MANPOWER_PLANNING_FETCHING,
    });
    const res = await OrganizationStructureService.getManpowerPlanning();
    if (res) {
      dispatch({
        type: MANPOWER_PLANNING_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MANPOWER_PLANNING_FAILED,
    });
  }
};

export const updateManpowerPlanning = (formData) => async () => {
  try {
    const res = await OrganizationStructureService.updateManpowerPlanning(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    // console.log(error.response);
    if (error.response) {
      return error.response;
    }
  }
};

export const getOrganizationManage = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: ORGANIZATION_MANAGE_FETCHING,
    });
    const res = await OrganizationStructureService.getOrganizationManage(idCompany);
    if (res) {
      dispatch({
        type: ORGANIZATION_MANAGE_SUCCESS,
        payload: res.data,
      });
      return res
    }
  } catch (err) {
    dispatch({
      type: ORGANIZATION_MANAGE_FAILED,
    });
    return err.response
  }
};