import React, { useEffect, useState, Fragment } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import StyledCard from "../../shared/general/Card";
import DatePickerWeek from "../../shared/general/DatePickerWeek";
import ButtonOrange from "../../shared/general/ButtonOrange";

import TableOTReport from "./tableOTReport";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const rand40 = () => {
  return Math.floor(Math.random() * 40);
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  borderBottom: `1px solid #e3e3e3`,
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon />} {...props} />
))(() => ({
  flexDirection: "row-reverse",
  paddingLeft: 0,
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(-90deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: 8,
  },
}));

const StyledAvatar = styled(Avatar)({
  width: 40,
  height: 40,
});

const StyledCellName = styled("div")({
  display: "flex",
  alignItems: "center",
  "& .MuiAvatar-root": {
    marginRight: 8,
  },
  "& .MuiTypography-h6": {
    lineHeight: 1,
  },
  "& .MuiTypography-body2": {
    color: "#999999",
    fontStyle: "oblique",
  },
});

export default function OTMonitoring() {
  const { t, i18n } = useTranslation();
  const person = [
    rand40(),
    rand40(),
    rand40(),
    rand40(),
    rand40(),
    rand40(),
    rand40(),
    rand40(),
  ];

  return (
    <div className="page" style={{ paddingLeft: 16, paddingRight: 16 }}>
      <Typography variant="h4" style={{ paddingTop: 8 }}>
        OT Monitoring
      </Typography>
      <div>
        <StyledCard>
          <div style={{ padding: 36 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 8,
              }}
            >
              <FilterListIcon style={{ marginRight: 4, fontSize: 14 }} />
              <Typography variant="body2"> {`${t("Search")}`}</Typography>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <DatePickerWeek />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="outlined-basic"
                  label={`${t("SearchFullName")}`}
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <ButtonOrange
              style={{ margin: "8px 0", minWidth: 120, fontSize: 18 }}
              size="small"
              value={`${t("Search")}`}
              variant={"contained"}
            />

            <div style={{ marginTop: 36 }}>
              {person.map((value) => {
                return (
                  <Accordion key={value}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <StyledCellName>
                          <StyledAvatar
                            alt={"username"}
                            src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
                          />
                          <div>
                            <Typography variant="h6">
                              {"Frankie Farrell"}
                            </Typography>
                            <Typography variant="body2">{`Position`}</Typography>
                          </div>
                        </StyledCellName>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                          }}
                        >
                          <Typography
                            variant="h4"
                            style={{
                              marginRight: 4,
                              color: value >= 36 && "#e46a76",
                              fontWeight: value >= 36 ? 600 : 400,
                            }}
                          >
                            {value}
                          </Typography>
                          <Typography
                            variant="boby1"
                            color="text.secondary"
                          >{`${t("Unit.ShortHours")}`}</Typography>
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableOTReport />
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          </div>
        </StyledCard>
      </div>
    </div>
  );
}
