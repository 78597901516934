import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../shared/general/ButtonBlue";

function ActivityButton({ soon, end, complete, cancel }) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        mt: 2,
      }}
    >
      <ButtonBlue variant="contained" onClick={soon} fullWidth>
        {`${t("กำลังมาถึง")}`}
      </ButtonBlue>
      <ButtonBlue variant="contained" onClick={end} fullWidth>
        {`${t("สิ้นสุดแล้ว")}`}
      </ButtonBlue>
      <ButtonBlue variant="contained" onClick={complete} fullWidth>
        {`${t("ได้รับเหรียญแล้ว")}`}
      </ButtonBlue>
      <ButtonBlue variant="contained" onClick={cancel} fullWidth>
        {`${t("กิจกรรมที่ยกเลิก")}`}
      </ButtonBlue>
    </Box>
  );
}

export default ActivityButton;
