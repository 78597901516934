import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  styled,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import UploadFile from "./uploadFile";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { useTranslation } from "react-i18next";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    minWidth: '600px', 
    maxWidth: '600px', 
    width: '80%', 
  },
}));

export default function CreateAnnouncement({
  open,
  handleClose,
  onFileSubmit,
}) {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      file: null,
    },
  });

  const onSubmit = (data) => {
    console.log(data); 
    if (onFileSubmit) {
      onFileSubmit(data.file); 
    }
    handleClose(); 
  };

  return (
    <CustomDialog open={open} onClose={handleClose}>
      <DialogTitle>
        {t("Upload")} {t("File")}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="file"
            control={control}
            render={({ field }) => (
              <UploadFile
                {...field}
                onChange={field.onChange}
                acceptFileType={
                  "image/jpeg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
                acceptFileTypeLabel={"jpg, png, pdf, doc, docx, xls, xlsx"}
              />
            )}
          />
          <DialogActions>
            <Button type="button" color="error" onClick={handleClose}>
              {`${t("Cancel")}`}
            </Button>
            <ButtonBlue type="submit" variant="contained">
              {`${t("Confirm")}`}
            </ButtonBlue>
          </DialogActions>
        </form>
      </DialogContent>
    </CustomDialog>
  );
}
