import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Box, Dialog, DialogContent, Typography } from "@mui/material";

import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import ButtonBlue from "./ButtonBlue";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledDialog = styled(Dialog)({
  borderRadius: 8,
  "& .MuiDialogContent-root": {
    padding: 0,
    "& .content": {
      padding: 16,
      textAlign: "center",
      "& .delete-icon-label": {
        fontSize: 90,
        color: "#46cbe2",
      },
      "& .header-label": {
        padding: "20px 0",
      },
      "& .detail": {
        fontSize: 16,
        "& span": {
          color: "#46cbe2",
          fontSize: 20,
        },
      },
    },
  },
});

const DialogConfirm = (props) => {
  const { open, handleClose, text, handleConfirm, content1, content2, isReadOnly } = props;
  const { t, i18n } = useTranslation();

  return (
    <StyledDialog fullWidth maxWidth={"xs"} open={open} onClose={handleClose}>
      <DialogContent>
        <div
          style={{ width: "100%", height: 6, backgroundColor: "#46cbe2" }}
        ></div>
        <div className="content">
          <InfoOutlinedIcon className="delete-icon-label" />
          <div className="header-label">
            <Typography variant="subtitle1" color="text.third" gutterBottom>
              {t("AreYouSure")}
            </Typography>
            {content1 && <Typography variant="h5">{content1}</Typography>}
            {content2 && <Typography className="detail">{content2}</Typography>}
          </div>

          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <ButtonBlue
              color="secondary"
              variant="text"
              style={{ marginRight: "10px" }}
              onClick={handleClose}
            >
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue variant="contained" onClick={handleConfirm} disabled={isReadOnly}>
              {`${t("Confirm")}${text ? text : ""}`}
            </ButtonBlue>
          </Box>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

export default DialogConfirm;
