import { httpClient } from "./httpClient";

const getAllPayrollDepartment = (query) => {
    return httpClient.get(`payment/payrollbydepartment`, { params: query });
};

const getAllOTWage = (date, idCompany) => {
    return httpClient.get(`payment/allOTWage/${date}/${idCompany}`);
};

const getAllOTHour = (date, idCompany) => {
    return httpClient.get(`payment/allHourOT/${date}/${idCompany}`);
};

const getAllPayrollListByMonth = (query) => {
    return httpClient.get(`payment/allpayrollListbymonth`, { params: query });
}

const getAllCostcenterListByMonth = (idCompany, year, month) => {
    return httpClient.get(`payment/costcenter/${idCompany}/${year}/${month}`);
}

const getMoneyPaidOutsidePayroll = (query) => {
    return httpClient.get(`payment/money-paid-outside-payroll`, { params: query });
}

export default {
    getAllPayrollDepartment,
    getAllOTWage,
    getAllOTHour,
    getAllPayrollListByMonth,
    getAllCostcenterListByMonth,
    getMoneyPaidOutsidePayroll
};
