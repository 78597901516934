import { httpClient } from "./httpClient";

const getEducationByIdEmp = (idUser) => {
  return httpClient.get("education/users/" + idUser);
};

const addEducation = (formData) => {
  return httpClient.post(`education`, formData);
};

const updateEducation = (idEducation, formData) => {
  return httpClient.put(`education/${idEducation}`, formData);
};

const deleteEducation = (idEducation) => {
  return httpClient.delete(`education/${idEducation}`);
}

export default {
  getEducationByIdEmp,
  addEducation,
  updateEducation,
  deleteEducation
};