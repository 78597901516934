import React from "react";
import { Typography, Breadcrumbs, Grid } from "@mui/material";

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles(() => ({
  dataText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
})); 

const Breadcrumb = ({ textLabel, icon }) => {
  const classes = useStyles();

  return (
    
      
      <Grid>
        <Breadcrumbs aria-label="breadcrumb">
          <div className={classes.dataText}>
            {icon}
            <Typography component={"span"} color="textPrimary">
              {textLabel}
            </Typography>
          </div>
        </Breadcrumbs>
      </Grid>
     
    
  );
};

export default Breadcrumb;
