import React, { useEffect } from "react";
import { Autocomplete, Box, Grid, Typography, styled } from "@mui/material";
import DrawerCustom from "../../../shared/general/Drawer";

import * as yup from "yup";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { getAffiliateCompany } from "../../../../../actions/affiliate";
import { exportExcelEmployeeInformationReport } from "../../../../../actions/exportExcel";
import { getUserCompany } from "../../../../../utils/userData";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .MuiCheckbox-root": {
    "&.Mui-checked": {
      color: "#46cbe2",
    },
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
  paddingBottom: "8px"
});

const DrawerEmployeeInformation = (props) => {

  const { drawerConfig, onClose } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: affiliateList } = useSelector((state) => state.affiliate);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const validateYupSchema = yup.object({
    company: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
  });

  const useHookForm = useForm({
    defaultValues: {
      company: null,
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  })

  const onSubmit = async (data) => {

    // const formData = {
    //   startDate: dayjs(data.startDate).format("YYYY-MM-DD"),
    //   endDate: !data.isCurrent? (data.endDate? dayjs(data.endDate).format("YYYY-MM-DD"): null): null,
    //   idShiftGroup: data.shiftGroup.idShiftGroup,
    //   idShift: data.shift.idShift,
    // }

    // const result = await dispatch(patchEmployeeShiftChangeHistoryStandard(employeeProfile.idEmployees, drawerConfig.data.idEmployeeShift, formData));

    // if(result && result.status === 200) {
    //   handleClose();
    //   dispatch(openNotificationAlert({
    //     type: "success",
    //     message: t("DataSaveSuccessful"),
    //   }));
    //   dispatch(getEmployeeShiftChangeHistory(employeeProfile.idEmployees))
    // } else {
    //   dispatch(openNotificationAlert({
    //     type: "error",
    //     message: t("AnErrorOccurred")
    //   }));
    // }

    const result = await dispatch(
      exportExcelEmployeeInformationReport({
        idCompany: data.company.idCompany,
      })
    ).then(res => {
      return res
    });

    if(result && result.status === 200){
      const excelBlob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(excelBlob);
      downloadLink.download = `UniHR ${data.company.companyName} ณ ${dayjs().format("DD MMMM BBBB HH.mm.ssน.")}.xlsx`;
      downloadLink.click();
    }

  }

  useEffect(() => {
    if(drawerConfig.isOpen){
      dispatch(getAffiliateCompany())
      useHookForm.resetField("company", {defaultValue: {idCompany: null, companyName: t("All")}})
    }
  }, [drawerConfig.isOpen])

  useEffect(() => {
    if(affiliateList && affiliateList.length > 0){
      const foundCompany = affiliateList.find(x => x.idCompany === userProfile.idCompany);
      const foundAllCompany = {idCompany: null, companyName: t("All")}
      if(foundCompany){
        useHookForm.resetField("company", {defaultValue: foundCompany})
      } else {
        useHookForm.resetField("company", {defaultValue: foundAllCompany})
      }
    }
  }, [affiliateList]);

  return (
    <DrawerCustom
      title={`${t("EmployeeInformation")}`}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledHeadLabel color="text.secondary">
                {`${t("Company")}`}
              </StyledHeadLabel>
              <Controller
                name="company"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <Autocomplete
                    options={affiliateList? [{idCompany: null, companyName: t("All")}, ...affiliateList]: [{idCompany: null, companyName: t("All")}]}
                    getOptionLabel={(option) => `${getUserCompany(option)}`}
                    isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        placeholder={`${t("SelectCompany")}`}
                        onBlur={field.onBlur}
                        helperText={fieldState.error? fieldState.error.message: null}
                        error={fieldState.error? true: false}
                      />
                    )}
                    value={field.value}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    noOptionsText={`${t("NoData")}`}
                    disableClearable
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} container alignItems="center" justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose}>{t("Cancel")}</ButtonBlue>
              <ButtonBlue
                type="submit"
                variant="outlined"
                startIcon={<DownloadRoundedIcon />}
                disabled={useHookForm.formState.isSubmitting}
                // disabled={true}
              >
                {t("Download")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerEmployeeInformation;