import React, { useEffect, useState } from "react";
import {
  Paper,
  Container,
  Grid,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import { useSelector, useDispatch } from "react-redux";
import PageviewIcon from "@mui/icons-material/Pageview";

import { getAllMember } from "../../../../actions/member";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "100px",
    minHeight: "calc(100vh - 20px)",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  textData: {
    fontSize: 21,
    fontWeight: "bold",
  },
  textDataSubJob: {
    fontSize: 16,
    fontWeight: "bold",
  },
  section: {
    marginLeft: 20,
  },
  text: {
    fontSize: 14,
  },
  textMainData: {
    fontSize: 21,
    color: "#007afc",
    fontWeight: "bold",
  },
  textPosition: {
    fontSize: 18,
    fontWeight: "bold",
  },
}));

const ShowMemberInDivision = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: dataMember } = useSelector((state) => state.dataMember);

  useEffect(() => {
    dispatch(getAllMember());
  }, []);


  return (
    <div className={`${classes.root}`}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>Member in division</Typography>
        {dataMember &&
          dataMember.map((val) =>
            val.role
              .filter(
                (val) =>
                  val.division === props.match.params.division &&
                  val.department === props.match.params.department &&
                  val.section === props.match.params.section
              )
              .map((val2, index) =>
                val2 != [] ? (
                  <Paper key={index + val} style={{ marginBottom: 16, padding: 16 }}>
                    <Grid container>
                      <Grid item xl={11} lg={11} md={10} sm={10} xs={10}>
                        <Grid container>
                          <Grid item xl={1} lg={2} md={2} sm={4} xs={12}>
                            <Avatar
                              sx={{ width: 80, height: 80 }}
                              src={`${process.env.REACT_APP_API_URL}image/profile/${val2.avatarImg}`}
                            />
                          </Grid>
                          <Grid item xl={4} lg={3} md={3} sm={6} xs={12}>
                            <Typography className={classes.text}>
                              Name
                            </Typography>
                            <Typography className={classes.textMainData}>
                              {val2.name} {val2.lastName}
                            </Typography>
                          </Grid>
                          <Grid item xl={1} lg={1} md={1} sm={2} xs={12}>
                            <Typography className={classes.text}>
                              Level
                            </Typography>
                            <Typography className={classes.textData}>
                              {val.level}
                            </Typography>
                          </Grid>
                          <Grid item xl={2} lg={2} md={3} sm={5} xs={12}>
                            <Typography className={classes.text}>
                              Role
                            </Typography>
                            <Typography className={classes.textData}>
                              {val2.roleUser}
                            </Typography>
                          </Grid>
                          <Grid item xl={2} lg={2} md={2} sm={4} xs={12}>
                            <Typography className={classes.text}>
                              Department
                            </Typography>
                            <Typography className={classes.textData}>
                              {val2.department}
                            </Typography>
                          </Grid>
                          <Grid item xl={2} lg={2} md={1} sm={3} xs={12}>
                            <Typography className={classes.text}>
                              Section
                            </Typography>
                            <Typography className={classes.textData}>
                              {val2.section}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xl={1}
                        lg={1}
                        md={2}
                        sm={2}
                        xs={2}
                        style={{ textAlign: "center" }}
                      >
                        {console.log(
                          "division, department, section",
                          val2.division,
                          val2.department,
                          val2.section
                        )}
                        <Link
                          to={`/admin/showDivision/showMemberInDivision/${val2.division} ${val2.department} ${val2.section}/information/${val2.id}`}
                        >
                          <IconButton>
                            <PageviewIcon
                              style={{ color: "#007afc" }}
                              fontSize="large"
                            />
                          </IconButton>
                        </Link>
                      </Grid>
                    </Grid>
                  </Paper>
                ) : (
                  <Paper>
                    <Typography>No Data</Typography>
                  </Paper>
                )
              )
          )}
      </Container>
    </div>
  );
};

export default ShowMemberInDivision;
