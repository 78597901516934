import { httpClient } from "./httpClient";

const getKPI = () => {
  return httpClient.get(`kpi`);
};

const getKPIManagerByidEmployee = (idEmployees) => {
  return httpClient.post('kpiManagerByidEmployee', { idEmployees });
};

const addKPI = (formData) => {
  return httpClient.post("kpi", formData);
};

const addManagerKPI = (formData) => {
  return httpClient.post("Managerkpi", formData);
};
const updateManagerKPI = (idKPI, fromdata) => {
  return httpClient.put(`kpi-manager/${idKPI}`, fromdata);
};

const updateKPI = (idKPI, fromdata) => {
  return httpClient.put(`kpi/${idKPI}`, fromdata);
};

const deleteKPI = (idKPI) => {
  return httpClient.delete(`kpi/${idKPI}`);
};

const updateKPISubmit = (idKPI) => {
  return httpClient.put(`kpi-submit/${idKPI}`)
};

const getKpiById = (idKPI) => {
  return httpClient.get(`kpi/${idKPI}`)
}

const getAllKpiApprove = () => {
  return httpClient.get(`kpi-approve`)
};

const getKpiPlan = () => {
  return httpClient.get(`kpi-approveplan`)
};

const updateKPIApprove = (data) => {
  return httpClient.put("kpi-approve", data);
};

const updateKPIplanApprove = (data) => {
  return httpClient.put("kpi-approve-planning", data);
};

const getKpiApprove = () => {
  return httpClient.get(`kpiResultApprove`)
};

const getKpiUpdate = () => {
  return httpClient.get(`kpi-update`)
};

export const getKpiUpdateAndPlan = (idEmployees) => {
  return httpClient.post(`kpi-update-plan`, { idEmployees });
};


const getKpiUpdateProgress = () => {
  return httpClient.get(`kpi-updateprogress`)
};

const getKpiUpdateById = (idKPI) => {
  return httpClient.get(`kpi-update/${idKPI}`)
}
const addKpiProgress = (formData) => {
  console.log("FormData:", formData); 
  return httpClient.post(`kpi-progress`, formData);
};

const addKpiProgressbar = (formData) => {
  return httpClient.post(`kpi-progresbar`, formData);
};

const addKpiPlan = (formData) => {
  return httpClient.post(`kpi-planprogress`, formData);
};

// const getKpiUpdateMonthById = (idKPI) => {
//   return httpClient.get(`kpiUpdateMonth/${idKPI}`)
// };

const updateKpiProgress = (idKPI, formData) => {
  return httpClient.put(`kpi-progress/${idKPI}`, formData);
};

const getKpiEvaluation = () => {
  return httpClient.get("kpi-evaluation");
};

const updateKPIEvaluationManager = (idKPI, formData) => {
  return httpClient.put(`kpi-evaluation-manager/${idKPI}`, formData);
};

const updateKPIEvaluationEmployee = (idKPI, formData) => {
  return httpClient.put(`kpi-evaluation-employee/${idKPI}`, formData);
};

const getKpiCarlibrate = () => {
  return httpClient.get(`kpi-carlibrate`);
};

const updateKpiGrade = (formData) => {
  return httpClient.put(`kpi-grade`, formData);
};

const getKpiCalibrateDepartment = () => {
  return httpClient.get(`kpi-calibrate-department`);
};

const getKpiSuccessful = () => {
  return httpClient.get(`kpi-successful`);
};

const getKpiIsSuccessfulManager = () => {
  return httpClient.get(`kpi-successful-manager`);
};

const getKpiUpdateAllAchievementById = () => {
  return httpClient.get(`kpiUpdateAllAchievement`);
};

const getKpiAll = () => {
  return httpClient.get(`kpiAll`);
};

// const putKpiIsSuccessfulById = (idKPI) => {
//   return httpClient.put(`kpiIsSuccessful/${idKPI}`)
// };

const uploadExcelKpi = (formData) => {
  return httpClient.post(`kpi-excel`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const uploadExcelKpiGrade = (formData) => {
  return httpClient.post(`kpi/uploadexcelkpigrade`, formData, { headers: { "Content-Type": "multipart/form-data" } });
};

const getKPIByManager = () => {
  return httpClient.get(`kpiManager`);
};

const getKPIByAdmin = () => {
  return httpClient.get(`kpiAdmin`);
};

const addKPIDate = (formData) => {
  return httpClient.post("kpiDate", formData);
};

const getKPIDate = () => {
  return httpClient.get(`kpiDate`);
};

const deleteKPIDate = (idKpiEditDate) => {
  return httpClient.delete(`kpiDate/${idKpiEditDate}`);
};

const updateKPIDate = (idKpiEditDate, formData) => {
  return httpClient.put(`kpi-Date/${idKpiEditDate}`, formData);
};

export default {
  getKPI,
  getKPIManagerByidEmployee,
  addKPI,
  addManagerKPI,
  updateKPI,
  deleteKPI,
  updateKPISubmit,
  getKpiById,
  getAllKpiApprove,
  updateKPIApprove,
  updateKPIplanApprove,
  getKpiUpdate,
  getKpiUpdateAndPlan,
  getKpiUpdateProgress,
  getKpiUpdateById,
  addKpiProgress,
  addKpiProgressbar,
  addKpiPlan,
  getKpiPlan,
  getKpiApprove,
  getKpiEvaluation,
  updateManagerKPI,
  // getKpiUpdateMonthById,
  updateKpiProgress,
  updateKPIEvaluationManager,
  updateKPIEvaluationEmployee,
  getKpiCarlibrate,
  updateKpiGrade,
  getKpiCalibrateDepartment,
  getKpiSuccessful,
  getKpiIsSuccessfulManager,
  getKpiUpdateAllAchievementById,
  getKpiAll,
  uploadExcelKpi,
  uploadExcelKpiGrade,
  getKPIByManager,
  getKPIByAdmin,
  addKPIDate,
  getKPIDate,
  deleteKPIDate,
  updateKPIDate,
};