import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  styled,
  Card,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import PlanningPanel from "./PlanningPanel";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { useDispatch } from "react-redux";
import { getSelfObjectiveForEvaluate } from "../../../../../../actions/okrs";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";

const StyledRoot = styled(Box)({
  "& .objectcard-wrap": {
    height: "100%",
    marginBottom: "16px",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  "& .objective-card": {
    padding: "16px",
  },
  "& .MuiFilledInput-root input": {
    padding: "16px 12px 16px 12px",
  },
});

const StyledCard = styled(Card)({
  minHeight: "70px",
  boxShadow: "none",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  background:
    "linear-gradient(to right, rgba(104, 205, 232, 0.7), rgba(109, 238, 227, 0.7))",
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  "& .yearSelectionContainer": {
    marginLeft: "41px",
    marginBottom: "100px",
  },
  "@media (max-width: 600px)": {
    "& .yearSelectionContainer": {
      marginBottom: "1px",
    },
  },
});

const StyleHeadBox = styled(Box)({
  "& .box-header": {
    minHeight: "200px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor:
      "linear-gradient(to right, rgba(104, 205, 232, 0.7), rgba(109, 238, 227, 0.7))",
    "& .text-header": {
      fontSize: "48px",
      fontWeight: "bold",
      marginLeft: "40px",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginTop: "80px",
    },
    "& .img-header": {
      objectFit: "contain",
      // width: "33%",
      // height: "auto",
      // marginBottom: "10px",
      // justifyContent: "flex-end",
      // alignItems: "flex-end",
      // marginRight: "20px",
    },
  },

  "@media (max-width: 600px)": {
    "& .box-header": {
      flexDirection: "column",
      minHeight: "unset",
      justifyContent: "center",
      alignItems: "center",
      "& .text-header": {
        fontSize: "30px",
        marginLeft: "20px",
        marginTop: "20px",
      },
      // "& .img-header": {
      //   width: "50%",
      //   justifyContent: "flex-start",
      //   alignItems: "flex-start",
      // },
    },
  },
});

const SelfEvaluationPage = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { control } = useForm();
  const [evaluationList, setEvaluationList] = useState([]);
  const [selectedQuarter, setSelectedQuarter] = useState("ALL");

  useEffect(() => {
    dispatch(getSelfObjectiveForEvaluate()).then((res) => {
      if (res.status == 200) {
        setEvaluationList(res.data);
      }
    });
  }, []);

  const handleQuarterChange = (event) => {
    setSelectedQuarter(event.target.value);
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <StyledCard
          style={{
            marginBottom: "48px",
            marginTop: "20px",
          }}
        >
          <StyleHeadBox>
            <Box className="box-header">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="text-header">{`${t(
                    "PendingSelfEvaluationGoals"
                  )}`}</Typography>
                </Grid>

                <Grid item xs={12} className="yearSelectionContainer">
                  <Typography
                    fontSize="14px"
                    fontWeight="600"
                    marginBottom="4px"
                    color="text.third"
                  >
                    {t("Quarter")}
                  </Typography>
                  <Select
                    value={selectedQuarter}
                    onChange={handleQuarterChange}
                    style={{
                      width: "150px",
                      background: "#fff",
                      border: "none",
                      borderRadius: "8px",
                    }}
                  >
                    <MenuItem value="ALL">All</MenuItem>
                    {[1, 2, 3, 4].map((quarter) => (
                      <MenuItem key={quarter} value={quarter}>
                        {`Q${quarter}`}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <img
                className="img-header"
                src={`${process.env.PUBLIC_URL}/assets/okrs/user/individual-goals-evaluation.png`}
                alt="icon"
              />
            </Box>
          </StyleHeadBox>
        </StyledCard>

        <Box padding="24px">
          <PlanningPanel
            data={evaluationList}
            selectedQuarter={selectedQuarter}
          />
        </Box>
      </Container>
    </StyledRoot>
  );
};

export default SelfEvaluationPage;
