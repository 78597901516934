import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import QRCode from "qrcode";
import ButtonBlue from "../../../../shared/general/ButtonBlue";


//Translator TH-EN
import { useTranslation } from "react-i18next";

const DialogQRCode = (props) => {
  const [qrcodeSrc, setQrcodeSrc] = useState(null);
  const { t, i18n } = useTranslation();


  const {
    open,
    onClose,
    idGroupGpsLocations,
    callBack,
    groupGpsLocationsName,
  } = props;

  useEffect(async () => {
    // console.log(process.env)
    const canvas = await QRCode.toCanvas(
      `${process.env.REACT_APP_PUBLIC_URL}qrcode-check/${idGroupGpsLocations}`,
      { scale: 8 }
    );
    canvas.setAttribute("download", "QR Code UniHR");
    const imgDim = { width: 120, height: 120 }; //logo dimention
    const context = canvas.getContext("2d");
    const imageObj = new Image();
    imageObj.src = "/assets/ANT JOB QRCODE.png";
    imageObj.onload = () => {
      context.drawImage(
        imageObj,
        canvas.width / 2 - imgDim.width / 2,
        canvas.height / 2 - imgDim.height / 2,
        imgDim.width,
        imgDim.height
      );
      setQrcodeSrc(canvas);
    };
    imageObj.onerror = () => {
      setQrcodeSrc(canvas);
    };

    // console.log("TEST")
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (callBack) {
          callBack();
        }
        onClose();
      }}
      PaperProps={{
        style: {
          maxWidth: "400px",
          width: "100%",
        },
      }}
    >
      <DialogContent>
        {/* <DialogContentText> */}
        {/* <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          {type === "success" && <img src={SuccessIcon} width="48" style={{marginBottom: "24px"}}/>}
          {type === "error" && <img src={CancelIcon} width="48" style={{marginBottom: "24px"}}/>}
          <Typography fontSize="18px">{content}</Typography>
        </div> */}
        {/* </DialogContentText> */}
        {qrcodeSrc && (
          <Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              marginBottom="16px"
            >
              {/* <Typography flexGrow="1" fontSize="14px" color="text.third">QR Code</Typography> */}
              <ButtonBlue
                variant="outlined"
                startIcon={<i className="fa-regular fa-down"></i>}
                onClick={() => {
                  const link = document.createElement("a");
                  link.download = `${groupGpsLocationsName}.png`;
                  link.href = qrcodeSrc.toDataURL();
                  link.click();
                }}
              >
                {t("Download")} QR Code
              </ButtonBlue>
            </Box>
            <Box
              textAlign="center"
              sx={{ "& img": { width: 200, height: 200 } }}
            >
              <img
                src={qrcodeSrc.toDataURL("image/png")}
                alt={groupGpsLocationsName}
              />
              <Typography>{groupGpsLocationsName}</Typography>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("OK")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogQRCode;
