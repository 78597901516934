import { httpClient } from "./httpClient";

const getAllTimeline= () => {
  return httpClient.get("timeline");
};

const getAllEvents = () => {
  return httpClient.get("events");
};

const getEventsByMonth = (month) => {
  return httpClient.get("events/" + month);
};

const getEventsLeaveByMonth = () => {
  return httpClient.get("events/leave");
};

export default {
  getAllEvents,
  getEventsByMonth,
  getAllTimeline,
  getEventsLeaveByMonth
};
