import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePickerCustom from "../../../shared/date/datePicker";
import PropTypes from "prop-types";

import {
  Grid,
  Container,
  Tab,
  Tabs,
  Badge,
  Box,
  CardContent,
  Autocomplete,
  TextField,
  Divider,
  Chip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";

import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import SelectEmployees from "../../../shared/general/selectEmployees";

import {
  getHistoryExpenseByIdCompany,
  getFilteredHistoryExpenseAccountant,
} from "../../../../../actions/expense";

import dayjs from "dayjs";
import AnimationBackdrop from "../../../shared/general/Backdrop";
import SubTableWithButton from "../subTable";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 24,
  },
  "& .head": {
    paddingTop: 8,
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .head-2": {
    paddingTop: 8,
    marginBottom: 24,
    marginTop: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .wrap-sum-amount": {
    marginBottom: 16,
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledChip = styled(Chip)({
  display: "flex",
  flexDirection: "column",
  width: 20,
  height: 20,
  marginLeft: 5,
  color: "#fff",
  "& .MuiChip-label": {
    display: "block",
    whiteSpace: "normal",
    textAlign: "center",
  },
  "&.Completed": {
    backgroundColor: "#4caf50",
  },
  "&.Rejected": {
    backgroundColor: "#d32f2f",
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: "none !important",
  "& .MuiOutlinedInput-root": {
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-root": {
      paddingTop: 0,
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      border: "none !important",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
  "& .MuiFilledInput-root": {
    paddingTop: 0,
  },
});

const StyledDivTab = styled("div")(({ theme }) => ({
  width: "100%",
  padding: "0 8px",
  marginBottom: 16,
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  fontSize: 16,
  "& .MuiBadge-badge": {
    right: -16,
    top: 12,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#e46a76",
  },
}));

const StyledTabs = styled(Tabs)({
  "& .Mui-selected": {
    "&.Completed": {
      color: "#4caf50 !important",
    },
    "&.Rejected": {
      color: "#d32f2f !important",
    },
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#46cbe2 !important",
  },
  "& .fal": {
    marginRight: 8,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ExpenseHistoryPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [statusTab, setStatusTab] = React.useState(0);
  const [selectedDate, setSelectedDate] = React.useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: expense } = useSelector((state) => state.expense);
  const listExpenseType = [
    { type: "ServiceAndGoods", label: t("ServiceAndGoods"), id: 1 },
    { type: "Allowance", label: t("Allowance"), id: 2 },
    { type: "Mileage", label: "Mileage", id: 3 },
    { type: "Welfare", label: t("Welfare"), id: 4 },
    { type: "FlexCoin", label: "Flex coin", id: 5 },
  ];
  useEffect(() => {
    dispatch(getHistoryExpenseByIdCompany());
  }, [userProfile]);

  const handleApproveTabsChange = (_, newValue) => {
    setStatusTab(newValue);
  };

  const handleViewDetail = (idExpense, expenseType, idEmployees) => {
    if (idExpense) {
      history.push(
        `/expense/detail/${expenseType}/${idExpense}/${idEmployees}/view`
      );
    }
  };

  const handleSelectEmployee = (value) => {
    if (value) {
      setSelectedEmployee(value.idEmployees);
    }
  };

  const handleOnClickSearch = async () => {
    let data = {
      idEmployee: selectedEmployee ? selectedEmployee : null,
      date: selectedDate ? dayjs(selectedDate).format("YYYY/MM/DD") : null,
      expenseType: selectedType ? selectedType.id : null,
    };

    let result = await dispatch(getFilteredHistoryExpenseAccountant(data));

    if (result.status === 200) {
      setSelectedDate(null);
      setSelectedEmployee(null);
      setSelectedType(null);
    } else {
    }
  };

  return (
    <StyledRoot className="page">
      {expense ? (
        <Container maxWidth="lg">
          <div className="head">
            <Typography variant="h4">{t("ExpenseHistory")}</Typography>
          </div>

          <CardStyle>
            {expense && (
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                ></div>
                <Grid
                  container
                  spacing={2}
                  style={{ marginBottom: 32 }}
                  alignItems={"center"}
                >
                  <Grid item xs={12} sm={3}>
                    <SelectEmployees
                      handleChange={handleSelectEmployee}
                      mode={"manager"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <StyledBoxSearch>
                      <Typography className="label" color="text.third">
                        {t("Date")}
                      </Typography>
                      <div className="search-date">
                        <DatePickerCustom
                          inputFormat="DD/MM/YYYY"
                          value={selectedDate}
                          maxDate={dayjs().toDate()}
                          name="start"
                          onChange={(newValue) => {
                            setSelectedDate(newValue);
                          }}
                        />
                      </div>
                    </StyledBoxSearch>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <StyledBoxSearch>
                      <Typography className="label" color="text.third">
                        {t("ExpensesType")}
                      </Typography>
                      <StyledAutocomplete
                        disablePortal
                        id="expenseType"
                        options={listExpenseType}
                        onChange={(e, newValue) => {
                          setSelectedType(newValue);
                        }}
                        renderInput={(params) => (
                          <StyledTextField
                            {...params}
                            variant="filled"
                            placeholder={t("SelectType")}
                          />
                        )}
                        noOptionsText={t("NoData")}
                      />
                    </StyledBoxSearch>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <StyledBoxSearch>
                      <ButtonBlue
                        variant="contained"
                        startIcon={<SearchIcon />}
                        onClick={handleOnClickSearch}
                        disabled={
                          !(selectedEmployee || selectedDate || selectedType)
                        }
                      >
                        {t("Search")}
                      </ButtonBlue>
                    </StyledBoxSearch>
                  </Grid>
                </Grid>

                <StyledDivider />

                <StyledDivTab>
                  <StyledTabs
                    value={statusTab}
                    onChange={handleApproveTabsChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    centered
                  >
                    <Tab
                      className="Completed"
                      style={{ minWidth: 180 }}
                      label={
                        <StyledBadge color="secondary">
                          {t("Completed")}
                          <StyledChip
                            label={
                              expense.Completed &&
                              expense.Completed.length &&
                              expense.Completed.length > 0
                                ? expense.Completed.length
                                : 0
                            }
                            className="Completed"
                          />
                        </StyledBadge>
                      }
                      {...a11yProps(0)}
                    />

                    <Tab
                      className="Rejected"
                      style={{ minWidth: 180 }}
                      label={
                        <StyledBadge color="secondary">
                          {t("Rejected")}
                          <StyledChip
                            label={
                              expense.Rejected &&
                              expense.Rejected.length &&
                              expense.Rejected.length > 0
                                ? expense.Rejected.length
                                : 0
                            }
                            className="Rejected"
                          />
                        </StyledBadge>
                      }
                      {...a11yProps(1)}
                    />
                  </StyledTabs>
                </StyledDivTab>

                <Box className="wrap-table">
                  <TabPanel value={statusTab} index={0}>
                    <SubTableWithButton
                      data={"Completed" in expense ? expense.Completed : []}
                      handleClick={handleViewDetail}
                    ></SubTableWithButton>
                  </TabPanel>
                  <TabPanel value={statusTab} index={1}>
                    <SubTableWithButton
                      data={"Rejected" in expense ? expense.Rejected : []}
                      handleClick={handleViewDetail}
                    ></SubTableWithButton>
                  </TabPanel>
                </Box>
              </CardContent>
            )}
          </CardStyle>
        </Container>
      ) : (
        <AnimationBackdrop />
      )}
    </StyledRoot>
  );
};

export default ExpenseHistoryPage;
