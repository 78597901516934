import { 
  JOB_FETCHING, JOB_FAILED, JOB_SUCCESS, 
  JOBPOSITION_FETCHING, JOBPOSITION_FAILED, JOBPOSITION_SUCCESS,
} from "./types";

import JobService from "../services/job.service";

export const getAllJob = () => async (dispatch) => {
  try {
    dispatch({
      type: JOB_FETCHING,
    });
    const res = await JobService.getAllJob();
    if (res) {
      dispatch({
        type: JOB_SUCCESS,
        payload: res.data,
      });
      return res.data
    }
  } catch (err) {
    dispatch({
      type: JOB_FAILED,
    });
    console.log(err);
  }
};


export const getJobPosition = (jobName, subJobName, position) => async (dispatch) => {
  try {
    dispatch({
      type: JOBPOSITION_FETCHING,
    });
    const res = await JobService.getJobPosition(jobName, subJobName, position);
    if(res){
      dispatch({
        type: JOBPOSITION_SUCCESS,
        payload: res.data,
      });
      return res.data
    }
  } catch (error) {
    dispatch({
      type: JOBPOSITION_FAILED,
    });
  }
};

export const getJobPositionById = (JobPosition,backPosition,id,jobPosition) => async (dispatch) => {
  try {
    dispatch({
      type: JOBPOSITION_FETCHING,
    });
    const res = await JobService.getJobPositionById(JobPosition,backPosition,id,jobPosition);
    if(res){
      dispatch({
        type: JOBPOSITION_SUCCESS,
        payload: res.data,
      });
      return res.data
    }
  } catch (error) {
    dispatch({
      type: JOBPOSITION_FAILED,
    });
  }
};
