import React, { useState } from "react";
import {
  Grid,
  Container,
  Typography,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  styled,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { updateKPIApprove, getAllKpiApprove } from "../../../../../actions/kpi";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DialogConfirmRejectKpi from "../DialogConfirmRejectKpi";
import Swal from 'sweetalert2';
import { useDispatch } from "react-redux";

const ContainerStyled = styled(Container)({
  minHeight: "100vh",
  paddingTop: "100px",
});

const ContentWrapper = styled('div')({
  marginTop: '-20px',
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  borderRadius: '30px',
  overflow: 'hidden',
  border: '1px solid #EEEEEE',
  display: 'flex',
  justifyContent: 'center',
  width: 'fit-content',
}));

const StyledToggleButton = styled(ToggleButton)(({ theme, selectedColor }) => ({
  flex: 1,
  borderRadius: '30px',
  '&.Mui-selected': {
    backgroundColor: selectedColor,
    color: '#FFFFFF',
  },
  '&.MuiToggleButtonGroup-grouped': {
    border: 'none',
    '&:not(:first-of-type)': {
      borderLeft: '1px solid #EEEEEE',
    },
  },
  '&:hover': {
    backgroundColor: `${selectedColor} !important`,
    color: '#FFFFFF',
  },
  backgroundColor: '#EEEEEE',
  color: '#000000',
  textAlign: 'center',
  minWidth: '100px',
  maxWidth: '200px',
}));

function KpisIndividualPlanView() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const kpiPlan = state.planList;
  const [approve, setApprove] = useState(1);
  const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);

  const handleApprovalRequest = async (approve, comment) => {
    const idKPI = kpiPlan.idKPI;
    const kpiData = {
      idKPI,
      isApprove: approve,
      commentKPI: approve === 0 ? comment : null,
    };

    await updateKPIApprove({ kpiData });

    Swal.fire({
      title: t("Success"),
      text: approve ? t("ApprovalSuccessful") : t("RejectionSuccessful"),
      icon: 'success',
      timer: 2000,
      showConfirmButton: false
    });

    dispatch(getAllKpiApprove());

    if (approve === 0) {
      setIsOpenRejectDialog(false);
    }
  };

  const showApprovalAlert = () => {
    Swal.fire({
      title: t("ConfirmApproval"),
      text: t("DoYouWantToApprove")+t("confirmAsking"),
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirm"),
      cancelButtonText: t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        handleApprovalRequest(1);
      }
    });
  };

  const handleSubmit = () => {
    if (approve === 0) {
      setIsOpenRejectDialog(true);
    } else {
      showApprovalAlert();
    }
  };

  return (
    <ContainerStyled>
      <div className="card-section">
        <div className="header-section">
          <Grid container>
            <Grid item>
              <h3 className="header-topic">{t("KPIIndividual")}</h3>
            </Grid>
          </Grid>
        </div>
        <ContentWrapper>
          <Stack padding="16px" spacing={2}>
            <Typography marginBottom="8px">
              <span style={{ color: "#919eab", paddingRight: "8px", fontSize: "16px" }}>{t("KPIName")}</span>
              <span style={{ fontSize: "22px", fontWeight: "500", color: '#083A83' }}>{kpiPlan.kpiName}</span>
            </Typography>
            <Typography marginBottom="8px">
              <span style={{ color: "#919eab", paddingRight: "8px", fontSize: "16px" }}>{t("Scope")}</span>
              {kpiPlan.scope}
            </Typography>
            <Typography marginBottom="8px">
              <span style={{ color: "#919eab", paddingRight: "8px", fontSize: "16px" }}>{t("Target")}</span>
              {kpiPlan.target}
            </Typography>
            <Typography marginBottom="8px">
              <span style={{ color: "#919eab", paddingRight: "8px", fontSize: "16px" }}>{t("Evidence")}</span>
              {kpiPlan.evidence}
            </Typography>
            <Typography marginBottom="8px">
              <span style={{ color: "#919eab", paddingRight: "8px", fontSize: "16px" }}>{t("Descriptions")}</span>
              {kpiPlan.description}
            </Typography>
            <Typography marginBottom="8px">
              <span style={{ color: "#919eab", paddingRight: "8px", fontSize: "16px" }}>{t("Duration")}</span>
              {`${dayjs(kpiPlan.startDate).format(
                i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
              )} - ${dayjs(kpiPlan.endDate).format(
                i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
              )}`}
            </Typography>
            <Typography marginBottom="8px">
              <span style={{ color: "#919eab", paddingRight: "8px", fontSize: "16px" }}>{t("TypeMeasurement")}</span>
              {kpiPlan.measureType}
            </Typography>
            <Typography marginBottom="8px">
              <span style={{ color: "#919eab", paddingRight: "8px", fontSize: "16px" }}>{t("Weight")}</span>
              {kpiPlan.weight}
            </Typography>
            {kpiPlan.isApprove !== null && (
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <StyledToggleButtonGroup
                  exclusive
                  value={approve}
                  onChange={(event, newValue) => setApprove(newValue)}
                >
                  <StyledToggleButton value={1} selectedColor="#50B498">
                    {t("Approve")}
                  </StyledToggleButton>
                  <StyledToggleButton value={0} selectedColor="#FF4C4C">
                    {t("Reject")}
                  </StyledToggleButton>
                </StyledToggleButtonGroup>
                <ButtonBlue variant="contained" onClick={handleSubmit}>
                  {t("Confirm")}
                </ButtonBlue>
              </div>
            )}
          </Stack>
        </ContentWrapper>
      </div>
      <DialogConfirmRejectKpi
        open={isOpenRejectDialog}
        onClose={() => setIsOpenRejectDialog(false)}
        handleSubmit={(comment) => handleApprovalRequest(0, comment)}
      />
    </ContainerStyled>
  );
}

export default KpisIndividualPlanView;
