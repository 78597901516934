import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Chip,
} from "@mui/material";

import FingerprintIcon from "@mui/icons-material/Fingerprint";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import QrCodeIcon from "@mui/icons-material/QrCode2";
import GpsFixedRoundedIcon from "@mui/icons-material/GpsFixedRounded";
import MoreTimeRoundedIcon from "@mui/icons-material/MoreTimeRounded";

import { getAttendanceOnce } from "../../../../actions/attendance";
import { getLeaveRequestOnce } from "../../../../actions/employee";
import { allLeaveType } from "../../../../actions/leave";

import Backdrop from "../../shared/general/Backdrop";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName, getUserPosition } from "../../../../utils/userData";

const StyledRoot = styled("div")({
  "& .wrap-check, .wrap-leave": {
    marginBottom: 36,
  },
  "& .wrap-check-in, .wrap-check-out, .leave": {
    borderRadius: 16,
    "& .head": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 24,
    },
  },
  "& .leave": {
    "& .filter": {
      padding: 24,
      display: "flex",
      justifyContent: "space-between",
    },
  },
});

const StyledLabelHead = styled(Typography)({
  fontSize: 24,
  fontWeight: 600,
  "& i": {
    marginRight: 8,
  },
});

const StyledChipTime = styled(Chip)({
  height: 28,
  width: 88,
  fontSize: 14,
  borderRadius: 8,
  border: "none",
  "&.check-in": {
    color: "#229a16",
    backgroundColor: "#54d62c29",
    "& .MuiSvgIcon-root": {
      color: "#229a16",
      fontSize: 20,
    },
  },
  "&.check-out": {
    color: "#b72136",
    backgroundColor: "#ff484229",
    "& .MuiSvgIcon-root": {
      color: "#b72136",
      fontSize: 20,
    },
  },
});

const StyledChipLeave = styled(Chip)({
  fontSize: 16,
  borderRadius: 8,
  color: "#637381",
  backgroundColor: "#f2f3f5",
  border: "none",
});

const StyledDivider = styled(Divider)({
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
});

const StyledListCheckEmployees = styled(List)({
  overflow: "auto",
  "& .secondaryAction": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    "& .location": {
      fontWeight: 600,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: 180,
      textAlign: "right",
    },
  },
  "& .MuiListItemText-primary": {
    fontWeight: 500,
  },
  "& .MuiListItemText-secondary": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 330,
  },
  "& .fullname": {
    ["@media only screen and (max-width: 480px)"]: {
      marginBottom: 16,
    },
  },
  "& .position": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    ["@media only screen and (max-width: 480px)"]: {
      display: "none",
    },
    ["@media only screen and (max-width: 600px)"]: {
      width: 120,
    },
    ["@media only screen and (min-width:768px)"]: {
      width: 150,
    },
    ["@media only screen and (min-width:992px)"]: {
      width: 200,
    },
    ["@media only screen and (min-width:1200px)"]: {
      width: "100%",
    },
  },
});

const ListCheckEmployees = (props) => {
  const { classCheck, searchText } = props;
  const { t, i18n } = useTranslation();
  const { result: attendance } = useSelector((state) => state.attendance);

  const rowsFilter = () => {
    let data = [...attendance];
    if (classCheck === "check-in") {
      data = data.filter((x) => x.isCheckIn);
    } else {
      data = data.filter((x) => !x.isCheckIn);
    }

    if (searchText.length > 0) {
      return data.filter((item, index) => {
        if (
          item.firstname_TH.toUpperCase().indexOf(searchText.toUpperCase()) >=
            0 ||
          item.lastname_TH.toUpperCase().indexOf(searchText.toUpperCase()) >= 0
        )
          return item;
      });
    } else {
      return data;
    }
  };

  return (
    <StyledListCheckEmployees>
      {attendance && attendance.length > 0 ? (
        rowsFilter().map((row, index) => (
          <Fragment key={index}>
            <ListItem
              secondaryAction={
                <div className="secondaryAction">
                  <StyledChipTime
                    icon={
                      row.idAttendanceType === 2 ? (
                        <FingerprintIcon />
                      ) : row.idAttendanceType === 5 ? (
                        <QrCodeIcon />
                      ) : row.idAttendanceType === 4 ? (
                        <GpsFixedRoundedIcon />
                      ) : row.idAttendanceType === 8 ? (
                        <MoreTimeRoundedIcon />
                      ) : (
                        <LocationOnIcon />
                      )
                    }
                    className={classCheck}
                    label={row.time}
                    variant="outlined"
                  />
                  <Typography
                    className="location"
                    color="text.secondary"
                    variant="caption"
                  >
                    {row.gpsAddress ? row.gpsAddress : row.gpsLocationsName}
                  </Typography>
                </div>
              }
            >
              <ListItemAvatar>
                <Avatar src={row.imageProfile} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography className="fullname">
                    {getUserFullName(row)}
                  </Typography>
                }
                secondary={
                  <Typography
                    className="position"
                    variant="body2"
                    color="text.secondary"
                  >
                    {getUserPosition(row)}
                  </Typography>
                }
              />
            </ListItem>
            <StyledDivider variant="inset" component="li" />
          </Fragment>
        ))
      ) : (
        <ListItem style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h6">{`${t("NoSignIn")}`}</Typography>
        </ListItem>
      )}
    </StyledListCheckEmployees>
  );
};

const ListLeaveEmployees = (props) => {
  const { result: leaveEmployeeList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { t, i18n } = useTranslation();
  return (
    <StyledListCheckEmployees>
      {leaveEmployeeList && leaveEmployeeList.length > 0 ? (
        leaveEmployeeList.map((row, index) => (
          <Fragment key={index}>
            <ListItem
              secondaryAction={
                <StyledChipLeave label={row.name} variant="outlined" />
              }
            >
              <ListItemAvatar>
                <Avatar
                // src={`${process.env.REACT_APP_API_URL}image/vendor/${row.idVendor}/${row.personalId}.jpg`}
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${row.firstname_TH} ${row.lastname_TH}`}
                secondary={row.positionName}
              />
            </ListItem>
            <StyledDivider variant="inset" component="li" />
          </Fragment>
        ))
      ) : (
        <ListItem style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h6">{`${t("LeaveNotFound")}`}</Typography>
        </ListItem>
      )}
    </StyledListCheckEmployees>
  );
};

const DailyPanelCard = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: attendance } = useSelector((state) => state.attendance);

  const { search } = props;
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    var filter = {
      date: dayjs(search).format("YYYY-MM-DD"),
    };
    dispatch(getAttendanceOnce(filter, "manager"));
    dispatch(
      getLeaveRequestOnce({
        date: dayjs(search).format("YYYY-MM-DD"),
        filter: "manager",
      })
    );
    dispatch(allLeaveType());
  }, []);

  return (
    <StyledRoot>
      {attendance && (
        <>
          <div className="wrap-check">
            <Paper variant="outlined" className="wrap-check-in">
              <div className="head">
                <StyledLabelHead variant="h4">
                  <i className="fa-light fa-right-to-bracket"></i>
                  {`${t("ClockIn")}`}
                </StyledLabelHead>
                <div>
                  <StyledLabelHead variant="h4">{`${
                    attendance.filter((item) => item.isCheckIn === 1).length
                  } ${t("Person")}`}</StyledLabelHead>
                </div>
              </div>

              <ListCheckEmployees
                classCheck="check-in"
                searchText={searchText}
              />
            </Paper>
          </div>
          <div className="wrap-check">
            <Paper variant="outlined" className="wrap-check-out">
              <div className="head">
                <StyledLabelHead variant="h4">
                  <i className="fa-light fa-right-from-bracket"></i>
                  {`${t("ClockOut")}`}
                </StyledLabelHead>
                <div>
                  <StyledLabelHead variant="h4">{`${
                    attendance.filter((item) => item.isCheckIn === 0).length
                  } ${t("Person")}`}</StyledLabelHead>
                </div>
              </div>

              <ListCheckEmployees
                classCheck="check-out"
                searchText={searchText}
              />
            </Paper>
          </div>
          <div className="wrap-leave">
            <Paper variant="outlined" className="leave">
              <div className="filter">
                <StyledLabelHead variant="h4">
                  <i className="fa-light fa-house-person-leave"></i>
                  {`${t("Leave")}`}
                </StyledLabelHead>
              </div>
              <ListLeaveEmployees />
            </Paper>
          </div>
        </>
      )}
    </StyledRoot>
  );
};

export default DailyPanelCard;
