import {
    TAX_DEDUCTION_SCHEDULE_FETCHING,
    TAX_DEDUCTION_SCHEDULE_FAILED,
    TAX_DEDUCTION_SCHEDULE_SUCCESS
} from '../actions/types';

const initialState = {
    result: null,
    isFetching: false,
    isError: false,
};
  
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case TAX_DEDUCTION_SCHEDULE_FETCHING:
        return { ...state, result: null, isFetching: true, isError: false };
      case TAX_DEDUCTION_SCHEDULE_FAILED:
        return { ...state, result: null, isFetching: false, isError: true };
      case TAX_DEDUCTION_SCHEDULE_SUCCESS:
        return { ...state, result: payload, isFetching: false, isError: false };
      default:
        return state;
    }
}