import {
  JOBSTRUCTURE_FETCHING,
  JOBSTRUCTURE_FAILED,
  JOBSTRUCTURE_SUCCESS,
} from "./types";

import JobStructureService from "../services/jobStructures.service";

export const getAllJobStructure = () => async (dispatch) => {
  try {
    const res = await JobStructureService.getAllJobStructure();
    if (res) {
      dispatch({
        type: JOBSTRUCTURE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: JOBSTRUCTURE_FAILED,
    });
    console.log(err);
  }
};

export const postJobStructure = (formData) => async () => {
  try {
    const res = await JobStructureService.postJobStructure(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putJobStructure = (id, formData) => async () => {
  try {
    const res = await JobStructureService.putJobStructure(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const deleteJobStructure = (id) => async () => {
  try {
    const res = await JobStructureService.deleteJobStructure(id);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};
