import React, { useEffect, useState } from "react";
import {
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import utils from "../../../../../../utils";
import AS100Form from "./AS100Form";
import { getAdditionSpecial100 } from "../../../../../../actions/additionSpecial";
import AS100Edit from "./AS100Edit";
import { useTranslation } from "react-i18next";

const AS100 = ({ selectedCompany }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: additionSpecial } = useSelector((state) => state.additionSpecial100);
  const [openForm, setOpenForm] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectAddition, setSelectAddition] = useState(null);

  const handleClose = () => {
    setOpenForm(false);
  };

  const handleOpenEdit = (item) => {
    setOpenEdit(true);
    setSelectAddition(item);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setSelectAddition(null);
  };

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getAdditionSpecial100(selectedCompany.idCompany));
    }
  }, [selectedCompany.idCompany]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          sx={{ position: "relative" }}
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            sx={{
              paddingLeft: "8px",
              borderLeft: "8px solid #46cbe2",
              borderTopLeftRadius: "4px",
              borderBottomLeftRadius: "4px",
            }}
            fontSize="20px"
            fontWeight="500"
          >
            {`${t("Travel")} (AS100)`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{t("Edit")}</TableCell>
                  <TableCell align="center" width={"300px"}>{t("Origin")}</TableCell>
                  <TableCell align="center" width={"300px"}>{t("Destination")}</TableCell>
                  <TableCell align="right">{t("AmountOfMoney")}</TableCell>
                  <TableCell align="center">{t("Status")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {additionSpecial &&
                  additionSpecial.map((item) => (
                    <TableRow key={item.idAS100}>
                      <TableCell component="th" scope="row" align="center">
                        <IconButton onClick={() => handleOpenEdit(item)} color="primary">
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">{item.locationStart}</TableCell>
                      <TableCell align="center">{item.locationEnd}</TableCell>
                      <TableCell align="right">
                        {utils.numberWithCommasWithoutDot(item.amount)}
                      </TableCell>
                      <TableCell align="center">
                        <Chip
                          color={item.isActive ? "success" : "error"}
                          label={item.isActive ? t("Active") : t("Inactive")}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          {openForm ? (
            <AS100Form selectedCompany={selectedCompany} handleClose={handleClose} />
          ) : (
            <ButtonBlue
              variant="outlined"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => setOpenForm(true)}
            >
              {t("AddItem")}
            </ButtonBlue>
          )}
        </Grid>
      </Grid>
      {openEdit && (
        <AS100Edit
          openEdit={openEdit}
          handleCloseEdit={handleCloseEdit}
          selectAddition={selectAddition}
        />
      )}
    </>
  );
};

export default AS100;
