import {
  TREATMENTCATEGORY_FETCHING,
  TREATMENTCATEGORY_FAILED,
  TREATMENTCATEGORY_SUCCESS,
} from "../actions/types";

  const initialState = {
    result: null,
    isFetching: false,
    isError: false,
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case  TREATMENTCATEGORY_FETCHING:
        return { ...state, result: null, isFetching: true, isError: false };
      case TREATMENTCATEGORY_FAILED:
        return { ...state, result: null, isFetching: false, isError: true };
      case TREATMENTCATEGORY_SUCCESS:
        return { ...state, result: payload, isFetching: false, isError: false };
      default:
        return state;
    }
  }
  