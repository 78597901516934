import React, { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Avatar, Typography, Button } from "@mui/material";
import { styled } from "@mui/styles";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import AddIcon from "@mui/icons-material/Add";

import { getAllEmployees } from "../../../../actions/employee";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { getUserFullName } from "../../../../utils/userData";

const DivEmployeesList = styled("div")({
  width: "100%",
  height: "fit-content",
  display: "grid",
  marginBottom: 64,
  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
    border: "0",
    marginBottom: 16,
    "& .MuiTypography-root": {
      fontWeight: 500,
      "& span": {
        letterSpacing: 0.5,
      },
    },
  },
  "& .employee-list": {
    padding: 16,
    height: "250px",
    overflow: "auto",
    backgroundColor: "white",
    borderRadius: "16px",
    display: "grid",
    gridTemplateRows: "repeat(autofill, minmax(130px, 1fr))",
    gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
    // minWidth: 350,
    minWidth: "100%",
    boxSizing: "border-box",
    columnGap: 8,
    "& .employee-item": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minHeight: "105px",
    },
    "& .MuiAvatar-root": {
      width: 72,
      height: 72,
      marginBottom: 8,
    },
    "& .MuiTypography-root": {
      maxWidth: 120,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
});

const Employees_list = () => {
  const dispatch = useDispatch();
  const { result: employeeList } = useSelector((state) => state.employees);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);

  return (
    <DivEmployeesList>
      {employeeList && (
        <Fragment>
          <div className="header">
            <Typography color="text.secondary">
              {t("Employee")}{" "}
              <span>{`(${
                employeeList.filter((item) => !item.isTerminate).length
              } ${t("Person")})`}</span>
            </Typography>
            <ButtonBlue variant="text" component={NavLink} to="/employees">
              {t("All")}
            </ButtonBlue>
          </div>
          <div className="employee-list">
            {employeeList
              .filter((item) => !item.isTerminate)
              .slice(0, 16)
              .map((emp, index) => (
                <div
                  className="employee-item"
                  key={index + emp.firstname_TH + emp.lastname_TH}
                >
                  <Avatar key={index} alt={emp.email} src={emp.imageProfile} />
                  <Typography>{getUserFullName(emp)}</Typography>
                </div>
              ))}
          </div>
        </Fragment>
      )}
    </DivEmployeesList>
  );
};

export default Employees_list;
