import React, { useEffect } from "react";
import DrawerCustom from "../../shared/general/Drawer";
import { Box, Grid, MenuItem, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile, updateUser } from "../../../../actions/user";
import { openNotificationAlert } from "../../../../actions/notificationAlert";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .MuiCheckbox-root": {
    "&.Mui-checked": {
      color: "#46cbe2",
    },
  },
})

const DialogPersonalInfo = (props) => {

  const { drawerConfig, handleClose } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const validateYupSchema = yup.object({
    idReligion: yup.string().nullable()
    // .required(`${t("ThisFieldIsRequired")}`),
  });

  const useHookForm = useForm({
    defaultValues: {
      idReligion: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  })

  useEffect(() => {
    if(drawerConfig.isOpen){
      useHookForm.setValue("idReligion", drawerConfig.data.idReligion? drawerConfig.data.idReligion: "", {shouldDirty: false, shouldTouch: false, shouldValidate: false})
    }
  }, [drawerConfig.isOpen])

  const onSubmit = async (data) => {
    const formData = {
      idReligion: data.idReligion? data.idReligion: null,
      idEmployees: userProfile.idEmployees,
    }

    const result = await dispatch(updateUser(formData))

    if(result && result.status === 200) {
      handleClose();
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));
      dispatch(getUserProfile());
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }));
    }
  }

  return (
    <DrawerCustom
      title={t("EditPersonalInfo")}
      anchor={"right"}
      open={drawerConfig.isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="600" color="text.secondary" paddingBottom="8px">{t("Religion")}</Typography>
              <Controller
                name="idReligion"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    select
                    error={fieldState.error? true: false}
                    helperText={fieldState.error? fieldState.error.message: null}
                    SelectProps={{
                      displayEmpty: true,
                    }}
                  >
                    <MenuItem value="">{t("NotSpecified")}</MenuItem>
                    <MenuItem value="1">{t("Religion_Code.buddha")}</MenuItem>
                    <MenuItem value="2">{t("Religion_Code.islamic")}</MenuItem>
                    <MenuItem value="3">{t("Religion_Code.christ")}</MenuItem>
                    <MenuItem value="4">{t("Religion_Code.hinduism")}</MenuItem>
                    <MenuItem value="5">{t("Religion_Code.sikhism")}</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={handleClose} disabled={useHookForm.formState.isSubmitting}>{t("Cancel")}</ButtonBlue>
              <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>{t("Edit")}</ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DialogPersonalInfo;