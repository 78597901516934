import React from "react";
import { Box, CircularProgress, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const StyleRoot = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  "& .right": {
    marginLeft: 16,
    "& .leave-name": {
      lineHeight: 1.2,
    },
  },
})

const QuotaProgress = (props) => {

  const { leaveGroup } = props;
  const { t, i18n } = useTranslation();

  const getLeaveUsedPercentage = () => {
    if (leaveGroup.leaveUsed === 0) return 0;

    let leaveCapacity = 0;

    if (leaveGroup.leaveValue) leaveCapacity += leaveGroup.leaveValue
    if (leaveGroup.carryValue) leaveCapacity += leaveGroup.carryValue

    return (leaveGroup.leaveUsed / leaveCapacity) * 100
  }

  return (
    <StyleRoot>
      <Box style={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          style={{
            color: "#eeeeee",
          }}
          size={50}
          thickness={4}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          value={getLeaveUsedPercentage()}
          style={{
            color: "#007afe",
            animationDuration: "550ms",
            position: "absolute",
            left: 0,
          }}
          size={50}
        />
        <Box
          style={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h6"
            style={{ fontSize: 16 }}
            component="div"
            color="text.third"
          >
            {Math.round(leaveGroup.leaveUsed * 100) / 100}
          </Typography>
        </Box>
      </Box>
      <div className="right">
        <Typography variant="h6" className="leave-name">
          {i18n.resolvedLanguage === "th"
            ? leaveGroup.name
            : leaveGroup.name_EN
            }
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          style={{ fontWeight: 500 }}
        >
          {`${t("LeaveRights")}`}:{" "}{leaveGroup.leaveValue === null ? `${t("Unlimited")}` : Math.round(leaveGroup.leaveValue * 100) / 100} {leaveGroup.isLeaveCompensate ? ` ${t("Unit.ShortHours")}` : ""}/
          {`${t("Remaining")}`}:{" "}{leaveGroup.leaveValue === null ? `${t("Unlimited")}` : Math.round(leaveGroup.leaveRemain * 100) / 100} {leaveGroup.isLeaveCompensate ? ` ${t("Unit.ShortHours")}` : ""}
        </Typography>

        {leaveGroup.carryValue > 0 && (
          <Typography
            variant="body1"
            color="text.secondary"
            style={{ fontWeight: 500 }}
          >
            {`${t("AccumulatedRights")}`}:{" "} {Math.round(leaveGroup.carryValue * 100) / 100}/
            {`${t("Remaining")}`}:{" "} {`${Math.round(leaveGroup.carryRemain * 100) / 100}`}
          </Typography>
        )}
      </div>
    </StyleRoot>
  )
}

export default QuotaProgress;