import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
    TextField,
    FormControl,
    Grid,
    Box,
    Typography,
    Popper,
    Stack
} from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import AlertResponse from "../../../shared/general/AlertResponse";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import { deleteCostElement, getTypeOFCostElement, postCostElement, putCostElement } from "../../../../../actions/costElement";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import DialogConfirmDelete from "../../../shared/general/DialogConfirmDelete";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center"
    },
});
const StyledFooter = styled("div")({
    padding: 16,
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel": {
        marginRight: 8,
    },
});
const StyledBoxSearch = styled(Box)({
    marginTop: 4,
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});
const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        [`& .${autocompleteClasses.option}`]: {
            "&:hover": {
                backgroundColor: "#f6f7f8",
                margin: "0 8px",
                borderRadius: 8,
                paddingLeft: 8,
            },
        },
    },
});
const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    border: 0,
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            color: "#919EAB",
            "& i": {
                marginRight: 8,
            },
        },
        "& .MuiAutocomplete-endAdornment": {
            "& .MuiButtonBase-root": {
                fontSize: 14,
                width: 22,
                height: 22,
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&::after": {
            border: "none",
        },
        "&::before": {
            border: "none",
        },
    },
});

function Setting(props) {
    const { t,i18n } = useTranslation();
    const dispatch = useDispatch();
    const { result: costElementList } = useSelector((state) => state.costElementList);
    const { open, typeDialog, handleClose, data, selectedCompany } = props;
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [formData, setFormData] = useState(null);
    const [doDelete, setDoDelete] = useState(false);

    useEffect(() => {
        if (typeDialog === "Add") {
            dispatch(getTypeOFCostElement({ idCompany: selectedCompany ? selectedCompany : null }));
            setFormData({
                costCode: null,
                idPayrollType: null,
                idAddition: null,
                idDeduction: null,
                isActive: 1,
                idCompany: selectedCompany
            })
        } else if (typeDialog === "Edit") {
            setFormData({
                idCostElement: data.idCostElement,
                costCode: data.code,
                isActive: data.isActive,
                idPayrollType: data.idPayrollType,
                idAddition: data.idAddition,
                idDeduction: data.idDeduction,
                idCompany: data.idCompany
            })
        }
    }, [open, data]);

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    console.log(data);

    const handleSubmit = async () => {
        if (!formData.costCode) {
            handleOpenAlert(true);
            handleChangeAlertType(`${t("InputCostElement")}`);
        } else if (!(formData.idPayrollType || formData.idAddition || formData.idDeduction) && typeDialog === "Add") {
            handleOpenAlert(true);
            handleChangeAlertType(`${t("SelectItem")}`);
        } else {
            if (typeDialog === "Add") {
                const result = await dispatch(postCostElement(formData));
                if (result.status === 200) {
                    // handleOpenAlert(true);
                    // handleChangeAlertType("success");
                    handleClose();
                } else {
                    handleOpenAlert(true);
                    handleChangeAlertType(t("DuplicateData"));
                    handleClose();
                };
            } else {
                let result;

                if (formData.idCostElement) {
                    result = await dispatch(putCostElement(data.idCostElement, formData));
                } else {
                    result = await dispatch(postCostElement({
                        idCompany: formData.idCompany,
                        idAddition: formData.idAddition || null,
                        idDeduction: formData.idDeduction || null,
                        isActive: formData.isActive
                    }));
                }


                if (result.status === 200) {
                    // handleOpenAlert(true);
                    // handleChangeAlertType("success");
                    handleClose();
                } else {
                    handleOpenAlert(true);
                    handleChangeAlertType(t("unableToProcessRequest"));
                };
            }
        }
    }

    const handleDelete = async () => {
        try {
            const reponse = await deleteCostElement(data && data.idCostElement);
            if (reponse && reponse.status === 200) {
                handleClose();
            } else {
                handleOpenAlert(true);
                handleChangeAlertType(`${t("CantDelete")}`);
                handleClose();
            }
        } catch (error) {
            handleOpenAlert(true);
            handleChangeAlertType(`${t("CantDelete")}`);
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const RenderTitle = () => {
        if (typeDialog === "Edit") return `${t("EditData")} ${i18n.resolvedLanguage === "th"
                          ? data.name_TH
                          : data.name_EN
                            ? data.name_EN
                            : "-"}`
        else return `${t("Add")} Cost Element`
    }

    return (
        <DrawerCustom
            title={RenderTitle()}
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot>
                {/* {JSON.stringify(data)}
                {JSON.stringify(formData)} */}
                {formData &&
                    <Grid container spacing={2} style={{ marginBottom: 16, width: 300 }}>
                        <Grid item xs={12} >
                            <StyledBoxSearch>
                                {typeDialog == "Add" && (
                                    <>
                                        <Typography className="label" color="text.third">
                                            {t("TitleName")}
                                        </Typography>
                                        <StyledAutocomplete
                                            options={costElementList ? costElementList : []}
                                            onChange={
                                                (event, newValue) => {
                                                    if (newValue.idPayrollType) {
                                                        setFormData({ ...formData, ["idPayrollType"]: newValue.idPayrollType, ["idAddition"]: null, ["idDeduction"]: null });
                                                    } else if (newValue.idAddition) {
                                                        setFormData({ ...formData, ["idAddition"]: newValue.idAddition, ["idPayrollType"]: null, ["idDeduction"]: null });
                                                    } else {
                                                        setFormData({ ...formData, ["idDeduction"]: newValue.idDeduction, ["idPayrollType"]: null, ["idAddition"]: null });
                                                    }
                                                }}
                                            closeIcon={null}
                                            popupIcon={<i class="fa-light fa-chevron-down"></i>}
                                            getOptionLabel={(option) => `${option.name} ${option.code ? "(" + option.code + ")" : ""}`}
                                            renderInput={(params) => (
                                                <TextFieldTheme
                                                    {...params}
                                                    variant="filled"
                                                    placeholder="เลือกรายการ"
                                                    InputProps={{ ...params.InputProps, endAdornment: null }}
                                                />
                                            )}
                                            PopperComponent={StyledPopper}
                                            noOptionsText={"NoData"}
                                            style={{ marginBottom: 16, marginTop: 10 }}
                                        />
                                    </>
                                )}
                                <Grid item xs={12} sm={6} className="GridTopicInput">Cost Element</Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        name="costCode"
                                        value={formData.costCode}
                                        disabled={formData.idAddition || formData.idDeduction}
                                        onChange={handleChange}
                                        margin="dense"
                                        style={{ marginBottom: 16, width: '28ch' }}
                                    />
                                </Grid>
                                {typeDialog == "Edit" && (
                                    <Grid item xs={12} sm={6} className="GridTopicInput">{t("Active")}</Grid>
                                )}
                                {typeDialog == "Edit" && (
                                    <Grid item xs={12} sm={6}>
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                value={formData.isActive}
                                                onChange={(event) => {
                                                    setFormData({
                                                        ...formData, ["isActive"]: eval(event.target.value)
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="1" control={<Radio />} label={t("Active")} />
                                                <FormControlLabel value="0" control={<Radio />} label={t("Inactive")} />

                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                )}

                            </StyledBoxSearch>
                        </Grid>
                    </Grid>
                }

                {typeDialog === "Add" ? (
                    <Box>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <ButtonBlue variant="contained" onClick={handleSubmit} autoFocus>
                                {t("Save")}
                            </ButtonBlue>
                            <ButtonBlue className="cancel" onClick={handleClose}>{t("Cancel")}</ButtonBlue>
                        </Stack>
                    </Box>
                ) : (
                    <Box>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Stack direction={"row"} justifyContent={"space-around"} spacing={1}>
                                <ButtonBlue variant="contained" onClick={handleSubmit} autoFocus>
                                    {t("Save")}
                                </ButtonBlue>
                                <ButtonBlue className="cancel" onClick={handleClose}>{t("Cancel")}</ButtonBlue>
                            </Stack>

                            <ButtonBlue onClick={() => setDoDelete(true)} color={"error"}>{t("Delete")}</ButtonBlue>
                        </Stack>
                    </Box>
                )}

            </StyledRoot>
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />

            {doDelete && (
                <DialogConfirmDelete
                    open={doDelete}
                    handleClose={() => setDoDelete(false)}
                    handleDelete={handleDelete}
                />
            )}
        </DrawerCustom>

    );
}

export default Setting;
