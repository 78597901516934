import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  styled,
  Card,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { getTeamObjective } from "../../../../../../actions/okrs";
import { Link } from "react-router-dom";
import Loading from "../../../../shared/loading";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import ObjectiveCard from "./ObjectiveCard";

const StyledRoot = styled(Box)({
  "& .MuiFilledInput-root input": {
    padding: "16px 12px 16px 12px",
  },
});

const getColorLinearStart = (isManager) => {
  if (isManager) {
    return "rgba(254, 196, 144, 0.7)";
  }
  return "rgba(104, 205, 232, 0.7)";
};

const getColorLinearEnd = (isManager) => {
  if (isManager) {
    return "rgba(235, 148, 192, 0.7)";
  }
  return "rgba(109, 238, 227, 0.7)";
};

const StyledCard = styled(Card)({
  minHeight: "70px",
  boxShadow: "none",
  borderRadius: 20,
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
});

const StyleHeadBox = styled(Box)({
  "& .box-header": {
    minHeight: "200px",
    padding: "16px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .text-header": {
      fontSize: "48px",
      fontWeight: "bold",
      marginLeft: "40px",
    },
    "& .img-header": {
      objectFit: "contain",
    },
  },
  "@media (max-width: 600px)": {
    "& .box-header": {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& .text-header": {
        fontSize: "36px",
        marginLeft: 0,
        marginTop: "20px",
      },
    },
  },
});

const ResponsiveButtonBlue = styled(Button)(({ theme }) => ({
  backgroundColor: "rgba(219, 65, 120, 1)",
  width: "30%",
  height: "50px",
  fontSize: "15px",
  fontWeight: "bold",
  borderRadius: "7px",
  "&:hover": {
    backgroundColor: "#C53B6C",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    fontSize: "14px",
    height: "40px",
  },
}));

const TeamPlanning = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const isManager = userProfile && userProfile.roles.includes("ROLE_MANAGER");

  const [teamObjective, setTeamObjective] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [selectYear, setSelectYear] = useState(dayjs().get("year"));

  const fetchTeamObjectives = () => {
    setIsFetching(true);
    dispatch(getTeamObjective()).then((res) => {
      if (res.status === 200) {
        setTeamObjective(res.data);
      }
      setIsFetching(false);
    });
  };

  useEffect(() => {
    fetchTeamObjectives();
  }, [dispatch]);

  const handleChangeYear = (event) => {
    setSelectYear(event.target.value);
  };

  return (
    <StyledRoot className="page">
      {userProfile && (
        <Container sx={{ paddingTop: "40px", paddingBottom: "40px" }}>
          <StyledCard
            style={{
              marginBottom: "40px",
              background: `linear-gradient(to right, 
                    ${getColorLinearStart(isManager)}, 
                    ${getColorLinearEnd(isManager)})`,
            }}
          >
            <StyleHeadBox>
              <Box className="box-header">
                <Typography className="text-header">
                  {t("TeamGoals")}
                </Typography>
                <img
                  className="img-header"
                  src={`${process.env.PUBLIC_URL}${
                    isManager
                      ? "/assets/okrs/manager/team-goals.png"
                      : "/assets/okrs/user/personal-goal.png"
                  }`}
                  alt="icon"
                />
              </Box>
            </StyleHeadBox>
          </StyledCard>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={"24px"}
          >
            <FormControl style={{ maxWidth: "160px" }}>
              <InputLabel>{t("SelectYear")}</InputLabel>
              <Select
                label={t("SelectYear")}
                value={selectYear}
                onChange={handleChangeYear}
                sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
              >
                {[0, 1, 2].map((item) => (
                  <MenuItem key={item} value={dayjs().get("year") - item}>
                    {dayjs()
                      .subtract(item, "year")
                      .format(i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {isManager && (
              <ResponsiveButtonBlue
                variant="contained"
                startIcon={<AddIcon />}
                component={Link}
                to="/okrs/team-planning/form"
              >
                {t("AddTeamGoals")}
              </ResponsiveButtonBlue>
            )}
          </Box>

          {isFetching ? (
            <Box display="flex" justifyContent="center" paddingTop="24px">
              <Loading />
            </Box>
          ) : (
            <Stack spacing={2} marginTop={4}>
              {teamObjective && teamObjective.length > 0 ? (
                teamObjective
                  .filter((item) => item.year === selectYear)
                  .map((objective, index) => (
                    <ObjectiveCard
                      key={index}
                      data={objective}
                      refreshObjectives={fetchTeamObjectives}
                    />
                  ))
              ) : (
                <Box display="flex" justifyContent="center" paddingTop="24px">
                  <Typography>{t("NoItems")}</Typography>
                </Box>
              )}
            </Stack>
          )}
        </Container>
      )}
    </StyledRoot>
  );
};

export default TeamPlanning;
