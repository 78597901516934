import React, { Fragment } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Box, Grid, Typography, styled} from '@mui/material';

import ButtonBlue from '../../../../../shared/general/ButtonBlue';
import DrawerCustom from '../../../../../shared/general/Drawer';
import TextFieldTheme from '../../../../../shared/general/TextFieldTheme';
import { useDispatch } from 'react-redux';
import { updateManpower, getManpowerById } from "../../../../../../../actions/manpower";
import NumberFormatTheme from '../../../../../shared/general/NumberFormatTheme';
import DatePickerCustom from '../../../../../shared/date/datePicker';

const BoxStyle = styled(Box)({
  "MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root .MuiOutlinedInput-input":{
    backgroundColor: "#EAEAEA",
    borderRadius: 8,
  },
  "& .MuiFilledInput-root, .MuiFilledInput-root:-webkit-autofill": {
    borderRadius: 8,
    backgroundColor: "#EAEAEA",
    "& .MuiFilledInput-input": {
      borderRadius: 8,
    },
  },
});

const DrawerPersonalInfo = (props) => {
  const { open, onClose, data, idManpower  } = props;
  const { handleSubmit, control, formState: { errors, dirtyFields } } = useForm({
    defaultValues: {
      personalId: data.personalId || "",
      dateOfBirth: data.dateOfBirth || null,
      telephoneMobile: data.telephoneMobile || "",
      email: data.email || "",
    }
  });

  const dispatch = useDispatch();

  const HandlerSubmit = (submitData) => {
    const formData = {};

    for (const key in dirtyFields) {
      formData[key] = submitData[key];
    }
    dispatch(updateManpower(idManpower, formData)).then(res => {
      dispatch(getManpowerById(idManpower));
    })
    onClose();
  }

  return (
    <DrawerCustom
      open={open}
      title={"แก้ไขเลขบัตรประชาชน, วันเกิด, เบอร์โทรและอีเมล"}
      anchor="right"
    >
      <BoxStyle style={{ width: 400, padding: 16 }}>
        <form onSubmit={handleSubmit(HandlerSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="personalId"
                control={control}
                rules={{
                  // required: {value: true, message: "กรุณากรอกเลขบัตรประชาชน"},
                  minLength: {value: 13, message: "กรุณากรอกเลขบัตรประชาชนเป็นตัวเลข 13 หลัก"},
                  maxLength: {value: 13, message: "กรุณากรอกเลขบัตรประชาชนเป็นตัวเลข 13 หลัก"},
                  validate: value => {
                    if (isNaN(value)) {
                      return "กรุณากรอกเฉพาะตัวเลขเท่านั้น";
                    }
                    return true;
                  }
                }}
                render={({field})=>(
                  <Fragment>
                  <Typography gutterBottom sx={{ color: "#ffffff" }}>เลขบัตรประชาชน</Typography>
                  <TextFieldTheme
                    {...field}
                    placeholder="เลขบัตรประชาชน"
                    // InputProps={{
                    //   inputComponent: NumberFormatTheme,
                    // }}
                    // inputProps={{
                    //   allowNegative: false,
                    //   onValueChange:(values) => {
                    //     const {value} = values;
                    //     field.onChange(value)
                    //   },
                    //   maxLength: 13
                    // }}
                    // onChange={()=>{}}
                    // inputProps={{
                    //   inputComponent: NumberFormatTheme,
                    //   allowNegative: false,
                    //   maxLength: 13
                    // }}
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                    }}
                    inputProps={{
                      format: (value) => {
                        if(value.length >= 3){
                          return String(value).replace(/(\d{1,13})/,"$1")
                        } else {
                          return String(value)
                        }
                      },
                      maxLength: 13,
                      allowNegative: false,
                      allowLeadingZeros: true,
                    }}
                    onChange={(e) => field.onChange(e.target.value)}
                    fullWidth
                    helperText={errors && errors.personalId && errors.personalId.message}
                    error={errors && errors.personalId ? true: false}
                  />
                  </Fragment>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="dateOfBirth"
                rules={{
                  required: {
                    value: true,
                    message: "กรุณากรอกวันเกิด"
                  }
                }}
                render={({field, formState}) => (
                  <DatePickerCustom
                    {...field}
                    views={["year", "month", "day"]}
                    inputFormat="DD MMMM YYYY"
                    disableFuture
                    disableMaskedInput
                    openTo="year"
                    onChange={(value)=>{
                      field.onChange(value)
                    }}
                    renderInput={(params) => (
                      <Fragment>
                      <Typography gutterBottom sx={{ color: "#ffffff" }}>วัน เดือน ปีเกิด</Typography>
                      <TextFieldTheme
                        {...params}
                        placeholder="วัน เดือน ปีเกิด"
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "วัน เดือน ปีเกิด",
                          readOnly: true
                        }}
                        error={formState.errors && formState.errors[field.name]? true: false}
                        helperText={formState.errors && formState.errors[field.name] && formState.errors[field.name].message? formState.errors[field.name].message: null}
                      />
                      </Fragment>
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="telephoneMobile"
                control={control}
                rules={{
                  required: { value: true, message: "กรุณากรอกเบอร์โทร" },
                  validate: (value) => (!isNaN(value) && value.length >= 9) || "กรุณากรอกเบอร์โทร 10 หลัก"
                }}
                render={({ field }) => (
                  <Fragment>
                  <Typography gutterBottom sx={{ color: "#ffffff" }}>เบอร์โทร</Typography>
                  <TextFieldTheme
                    {...field}
                    placeholder="เบอร์โทร"
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                    }}
                    inputProps={{
                      format: (value) => {
                        if(value.length >= 3){
                          return String(value).replace(/(\d{1,10})/,"$1")
                        } else {
                          return String(value)
                        }
                      },
                      maxLength: 10,
                      allowNegative: false,
                      allowLeadingZeros: true,
                    }}
                    fullWidth
                    helperText={errors && errors.telephoneMobile && errors.telephoneMobile.message}
                    error={errors && errors.telephoneMobile ? true : false}
                  />
                  </Fragment>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: { value: true, message: "กรุณากรอกอีเมล" },
                  pattern: {
                    value: /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z.]{1,}[A-z]$/,
                    message: "กรุณากรอกอีเมลให้ถูกต้อง เช่น 'antjob@example.com'"
                  }
                }}
                render={({ field }) => (
                  <Fragment>
                  <Typography gutterBottom sx={{ color: "#ffffff" }}>อีเมล</Typography>
                  <TextFieldTheme
                    {...field}
                    placeholder="อีเมล"
                    fullWidth
                    helperText={errors && errors.email && errors.email.message}
                    error={errors && errors.email ? true : false}
                  />
                  </Fragment>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <ButtonBlue
                  variant="outlined"
                  onClick={onClose}
                >ยกเลิก</ButtonBlue>
                <ButtonBlue
                  variant="contained"
                  type="submit"
                  disabled={Object.keys(dirtyFields).length <= 0}
                >ยืนยัน</ButtonBlue>
              </Box>
            </Grid>
          </Grid>
        </form>
      </BoxStyle>
    </DrawerCustom>
  );
};

export default DrawerPersonalInfo;