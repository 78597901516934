import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import {
  Typography,
  Grid,
  Container,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  IconButton,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Fab,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LabelIcon from "@mui/icons-material/Label";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DraftsIcon from "@mui/icons-material/Drafts";
import DetailsIcon from "@mui/icons-material/Details";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import InfoIcon from "@mui/icons-material/Info";

import { getAllDemand } from "../../../../actions/demand";
import SearchData from "../../shared/searchData";
import CardStyle from "../../shared/general/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";
import AddDemand from "./AddDemand";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "20px",
    minHeight: "calc(100vh - 20px)",
  },
  formControl: {
    marginRight: 16,
  },
  avatarLarge: {
    width: 100,
    height: 100,
    marginLeft: 10,
  },
  textTable: {
    margin: 5,
  },
  paper: {
    marginBottom: 20,
    marginTop: 10,
    backgroundColor: "#f9f9f9",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  textAndIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
  },
  textToShowPassOrNot: {
    fontSize: 16,
  },
  textDetail: {
    fontWeight: 600,
    marginBottom: 5,
    marginTop: 10,
    fontSize: "1.1rem",
  },
  textLevel: {
    color: "#9e9e9e",
  },
  navButton: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    height: "100%",
  },
}));

const ShowData = ({ icon, name, data }) => {
  const classes = useStyles();

  return (
    <Grid container style={{ marginTop: 10 }}>
      <Grid item xl={2} lg={3} md={4} sm={4} xs={12}>
        <Typography
          sx={{ display: "flex", alignItems: "center", color: "#9e9e9e" }}
          variant="body1"
        >
          {icon} {name} :
        </Typography>
      </Grid>
      <Grid item xl={10} lg={9} md={8} sm={8} xs={12}>
        <Typography variant="body1">{data}</Typography>
      </Grid>
    </Grid>
  );
};

const Popup_demand = ({ id, openType, handleClose }) => {
  const classes = useStyles();
  const { result: dataDemand } = useSelector((state) => state.demandData);

  return (
    <div>
      {dataDemand
        .filter((val) => val.demandID ===id)
        .map((val, index) => (
          <div key={index}>
            <Dialog
              open={openType === val.demandID}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="false"
            >
              <DialogTitle id="alert-dialog-title">
                <div
                  className={classes.textAndIcon}
                  style={{ justifyContent: "center" }}
                >
                  <InfoIcon sx={{ marginRight: 1 }} />
                  <Typography style={{ fontSize: 24, fontWeight: 600 }}>
                    Demand Detail
                  </Typography>
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  style={{ fontSize: 22, color: "black" }}
                >
                  <ShowData
                    icon={<LocalOfferIcon style={{ marginRight: 5 }} />}
                    name={"Name"}
                    data={val.demandName}
                  />
                  <ShowData
                    icon={<LabelIcon style={{ marginRight: 5 }} />}
                    name={"Type"}
                    data={val.demandType}
                  />
                  <ShowData
                    icon={<DraftsIcon style={{ marginRight: 5 }} />}
                    name={"Reason to choose this type"}
                    data={val.demandDataInType}
                  />
                  <ShowData
                    icon={<FavoriteIcon style={{ marginRight: 5 }} />}
                    name={"Value"}
                    data={val.demandValue}
                  />
                  <ShowData
                    icon={<AssignmentReturnIcon style={{ marginRight: 5 }} />}
                    name={"Direct Return"}
                    data={val.demandDirectReturn}
                  />
                  <ShowData
                    icon={<AttachMoneyIcon style={{ marginRight: 5 }} />}
                    name={"Return"}
                    data={val.demandReturn}
                  />
                  <ShowData
                    icon={<ChatOutlinedIcon style={{ marginRight: 5 }} />}
                    name={"Impact to business"}
                    data={val.demandImpact}
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ))}
    </div>
  );
};

function DemandAnalysis() {
  const dispatch = useDispatch();
  const { result: dataDemand } = useSelector((state) => state.demandData);
  const [addDemand, setAddDemand] = useState({
    isOpen: false,
  });

  useEffect(() => {
    dispatch(getAllDemand());
  }, []);

  const classes = useStyles();

  const [showDemand, setShowDemand] = useState(null);

  const handleClickOpen = (id) => {
    setShowDemand(id);
  };

  const handleClose = () => {
    setShowDemand(null);
  };

  const [demName, setDemName] = useState("");
  const [level, setLevel] = useState("");

  const classLevel = [
    "None",
    "O3",
    "O4",
    "O5",
    "S1",
    "S2",
    "S3",
    "S4",
    "M1",
    "M2",
    "M3",
    "M4",
  ];

  const onChangeName = (e) => {
    setDemName(e.target.value);
  };

  const onChangeType = (e) => {
    setLevel(e.target.value);
  };

  const levelPriority = ["None", "High", "Medium", "Low"];

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        <Grid container direction="row">
          <Grid item xs={12} sm={12} xl={8} lg={8} md={4}>
            <Typography variant="h4" gutterBottom style={{ marginTop: 20 }}>
              Demand Analysis
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={6} lg={6}>
              <ButtonBlue
                variant="contained"
                onClick={() => setAddDemand({ ...addDemand, isOpen: true })}
              >
                Add Demand
              </ButtonBlue>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <SearchData
                searchName={"Search demand name"}
                classLevel={classLevel}
                selectName={"Select Type"}
                onChangeName={onChangeName}
                onChangeType={onChangeType}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ marginBottom: 3, marginTop: 5 }}>
          <Typography variant="body1">List Demand Analysis</Typography>
        </Box>
        {dataDemand &&
          dataDemand
            .filter((val) => (level ==="" ? {} : val.level ===level))
            .filter((item) => item.demandName.search(demName) != -1)
            .map((val, index) => (
              <div key={index}>
                <CardStyle style={{ marginBottom: "16px" }}>
                  <CardContent style={{ padding: "10px 20px 10px 20px" }}>
                    <Grid container direction="row">
                      <Grid item xl={10} lg={10} md={10} sm={9} xs={6}>
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item sm={7} xs={12} xl={3} md={3} lg={3}>
                            <Typography
                              className={classes.textDetail}
                              sx={{ color: "#007afc" }}
                            >
                              {val.roleUser}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            xl={6}
                            md={5}
                            lg={6}
                            style={{ paddingLeft: 5 }}
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={10}>
                                <Typography
                                  style={{
                                    whiteSpace: "nowrap",
                                    fontWeight: 600,
                                  }}
                                >
                                  <Box
                                    my={1}
                                    textOverflow="ellipsis"
                                    overflow="hidden"
                                    style={{ fontSize: "1.1rem" }}
                                  >
                                    {val.demandName}
                                  </Box>
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <IconButton
                                  onClick={() => handleClickOpen(val.demandID)}
                                  size="large"
                                >
                                  <VisibilityIcon
                                    style={{ color: "#bdbdbd", fontSize: 27 }}
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                            <Popup_demand
                              id={val.demandID}
                              openType={showDemand}
                              handleClose={handleClose}
                            />
                          </Grid>
                          <Grid item sm={12} xs={12} xl={3} md={3} lg={3}>
                            <h3>
                              <FormControl
                                variant="outlined"
                                required
                                // size="small"
                                className={classes.formControl}
                                fullWidth
                              >
                                <InputLabel id="type-select-outlined-label">
                                  Demand Priority
                                </InputLabel>
                                <Select
                                  labelId="type-select-outlined-label"
                                  name="type"
                                  id="type-select-outlined"
                                  // onChange={(e) => setType(e.target.value)}
                                  label="Demand Priority"
                                >
                                  {levelPriority.map((val, index) =>
                                    val ==="None" ? (
                                      <MenuItem value="" key={index}>
                                        {val}
                                      </MenuItem>
                                    ) : (
                                      <MenuItem value={val} key={index}>
                                        {val}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            </h3>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xl={2} lg={2} md={2} sm={3} xs={6}>
                        {val.status ===0 ? (
                          <div
                            className={classes.navButton}
                            style={{ textAlign: "end" }}
                          >
                            <Fab
                              style={{
                                backgroundColor: "#43a047",
                                color: "white",
                                margin: 10,
                                width: "45px",
                                height: "45px",
                              }}
                              aria-label="yes"
                            >
                              <DoneIcon />
                            </Fab>
                            <Fab
                              style={{
                                backgroundColor: "#e53935",
                                color: "white",
                                margin: 10,
                                width: "45px",
                                height: "45px",
                              }}
                              aria-label="no"
                            >
                              <CloseIcon />
                            </Fab>
                          </div>
                        ) : val.status ===1 ? (
                          <div
                            className={`${classes.navButton} ${classes.textAndIcon}`}
                          >
                            <Fab
                              disabled
                              className={classes.textToShowPassOrNot}
                              style={{
                                backgroundColor: "#43a047",
                                color: "white",
                              }}
                              variant="extended"
                            >
                              <DoneIcon sx={{ mr: 1, fontSize: 18 }} />
                              Pass
                            </Fab>
                          </div>
                        ) : (
                          <div
                            className={`${classes.navButton} ${classes.textAndIcon}`}
                          >
                            <Fab
                              disabled
                              className={classes.textToShowPassOrNot}
                              style={{
                                backgroundColor: "#e53935",
                                color: "white",
                                height: "40px",
                              }}
                              variant="extended"
                            >
                              <CloseIcon sx={{ mr: 1, fontSize: 19 }} />
                              Not pass
                            </Fab>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardStyle>
              </div>
            ))}
        <AddDemand addDemand={addDemand} setAddDemand={setAddDemand} />
      </Container>
    </div>
  );
}

export default DemandAnalysis;
