export const SPS110TextFile = async (employeeDetails) => {

    if (employeeDetails) {
        let content = '';
        employeeDetails.employeeDetails.map(item => {
            const checkTitle = item.title_TH === "นาย" ? "003" : item.title_TH === "นางสาว" ? "004" : "005";

            const filteredFirstnameTH = (item.firstname_TH && item.firstname_TH.split(""))
                .filter(char => !["ั", "ิ", "ี", "ึ", "ื", "ุ", "ู", "์", "่", "้", "๊", "็", "๋", "ํ"].includes(char))
                .join("");

            const filteredLastname_TH = (item.lastname_TH && item.lastname_TH.split(""))
                .filter(char => !["ั", "ิ", "ี", "ึ", "ื", "ุ", "ู", "์", "่", "้", "๊", "็", "๋", "ํ"].includes(char))
                .join("");

            const originalFirstnameTH = item.firstname_TH || "";
            const originalLastname_TH = item.lastname_TH || "";

            const spaceAfterFirstname = " ".repeat(30 - (filteredFirstnameTH.length));
            const spaceAfterlastname = " ".repeat(35 - (filteredLastname_TH.length));

            const totalEarnings = item.valueActual && item.valueActual > 15000 ? "15000.00" : item.valueActual.toFixed(2);
            const tax = item.value ? item.value.toFixed(2) : "0.00";

            const formattedTax = `0000000${tax.split(".")[0] + "" + tax.split(".")[1]}`
            const formattedTotalEarnings = `0000000${totalEarnings.split(".")[0] + "" + totalEarnings.split(".")[1]}`
            const last_space = " ".repeat(26);

            content += `2${item.personalID}${checkTitle}${originalFirstnameTH}${spaceAfterFirstname}${originalLastname_TH}${spaceAfterlastname}${formattedTotalEarnings}${formattedTax}${last_space}\n`;
        });

        const blob = new Blob([content], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'สปส 1-10.txt';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    } else {
        console.log("No data");
    }
}


// for (const item of employeeDetails.employeeDetails) {
//     const firstName = item.firstname_TH;
//     const lastName = item.lastname_TH;
//     const sevenZero = '0000000';
//     const totalEarnings = item.valueActual > 15000 ? 15000 : item.valueActual;
//     const tax = item.value;
//     const checkTitle = item.title_TH === "นาย" ? "003" : item.title_TH === "นางสาว" ? "004" : "005";
//     const emptySpace = ' '.repeat(27);

//     content += `2${item.personalID}${checkTitle}${firstName}${lastName.slice(0, 29)}${sevenZero.slice(0, 15)}${totalEarnings}000000000${tax}00${emptySpace}\n`;
// }
