import React, { useState } from "react";
import dayjs from "dayjs";

import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  CardContent,
  Chip,
  IconButton,
  Typography,
} from "@mui/material";

import StatusRequest from "../leave/statusRequest";
import CardStyled from "../../shared/general/Card";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledChip = styled(Chip)({
  backgroundColor: "#ebf5ff",
  color: "#007aff",
  fontSize: 16,
  fontWeight: 500,
  "&.not-approve": {
    backgroundColor: "#EEEEEE",
    color: "#919eab",
  },
});

const StyledCard = styled(Card)({
  height: "100%",
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  marginBottom: "16px",
  marginTop: "16px",
  marginRight: "16px",
  minWidth: "300px",
});

const StyledWrapChip = styled("div")({
  display: "flex",
  justifyContent: "center",
  margin: "8px 0",
});

const StyledWrapHead = styled("div")({
  display: "flex",
  alignItems: "center",
  "&.approve": {
    justifyContent: "space-between",
  },
  "&.not-approve": {
    justifyContent: "flex-end",
  },
});

const StyledTextDate = styled(Typography)({
  "&.not-approve": {
    color: "#919eab",
    fontStyle: "oblique",
    fontWeight: 400,
  },
});

function HistoryLeaveCard(props) {
  const { data } = props;
  const { t, i18n } = useTranslation();

  return (
    <StyledCard>
      <CardContent style={{ padding: 16 }}>
        <StyledWrapHead
          className={data.isApprove !== 0 ? "approve" : "not-approve"}
        >
          <StatusRequest
            status={
              data.withdraw && data.withdraw.isApprove === null
                ? 3
                : data.withdraw && data.withdraw.isApprove === 1
                ? 4
                : data.isApprove
                ? 1
                : data.approveDate
                ? 2
                : 0
            }
          />
        </StyledWrapHead>
        <StyledWrapChip>
          <StyledChip
            className={
              data.isApprove === 0 ||
              (data.withdraw && data.withdraw.isApprove === 1)
                ? "not-approve"
                : ""
            }
            label={`${
              i18n.resolvedLanguage === "th"
                ? data.leaveGroupName
                : data.leaveGroupName_EN
                ? data.leaveGroupName_EN
                : ""
            } ${data.used} 
                ${
                  i18n.resolvedLanguage === "th"
                    ? data.isLeaveCompensate
                      ? t("Unit.ShortHours")
                      : "วัน"
                    : data.isLeaveCompensate
                    ? "hr"
                    : "day"
                }`}
          />
        </StyledWrapChip>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body2" color="text.secondary">
              {`${t("Start")}`}
            </Typography>
            <StyledTextDate
              className={data.isApprove === 0 ? "not-approve" : ""}
              variant="h6"
            >
              {dayjs(data.startText, "DD/MM/YYYY HH:mm").format(
                i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
              )}
            </StyledTextDate>
            <StyledTextDate
              className={data.isApprove === 0 ? "not-approve" : ""}
              variant="h6"
              style={{ height: 32 }}
            >
              {dayjs(data.startText, "DD/MM/YYYY HH:mm").format("HH:mm") !=
              "00:00"
                ? dayjs(data.startText, "DD/MM/YYYY HH:mm").format("HH:mm")
                : ""}
            </StyledTextDate>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body2" align="right" color="text.secondary">
              {`${t("End")}`}
            </Typography>
            <StyledTextDate
              className={data.isApprove === 0 ? "not-approve" : ""}
              variant="h6"
              align="right"
            >
              {dayjs(data.endText, "DD/MM/YYYY HH:mm").format(
                i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
              )}
            </StyledTextDate>
            <StyledTextDate
              className={data.isApprove === 0 ? "not-approve" : ""}
              variant="h6"
              align="right"
              style={{ height: 32 }}
            >
              {dayjs(data.endText, "DD/MM/YYYY HH:mm").format("HH:mm") !=
              "00:00"
                ? dayjs(data.endText, "DD/MM/YYYY HH:mm").format("HH:mm")
                : ""}
            </StyledTextDate>
          </div>
        </div>
        {/* <Divider style={{ margin: "8px" }} /> */}
        {data.holidayName && (
          <div style={{ padding: 8 }}>
            <Typography
              color="text.secondary"
              style={{
                fontSize: 14,
                fontStyle: "oblique",
              }}
            >
              {`** ${data.holidayName}`}
            </Typography>
          </div>
        )}

        <div style={{ padding: 8 }}>
          <Typography
            color="text.third"
            style={{
              fontSize: 16,
              fontStyle: "oblique",
            }}
          >
            {`${t("Description")}`}: {data.description ? data.description : "-"}
          </Typography>
        </div>
      </CardContent>
    </StyledCard>
  );
}

export default HistoryLeaveCard;
