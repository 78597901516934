import React, { useState } from "react";
import { Box, Dialog, IconButton, styled, Typography } from "@mui/material";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import AddIcon from "./../../../../../assets/addCertificate.png";

const StyledRoot = styled(Box)(({theme}) => ({
  // padding: "24px",
  "& .dropzone": {
    minHeight: 300,
    maxHeight: 300,
    height: "100%",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .inner-dropzone": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& img": {
        width: 128,
        height: 128,
      }
    }
  }
}))

const DialogUploadResume = (props) => {
  const { t } = useTranslation();

  const{ open, onClose } = props;
  
  const [isFetching, setIsFetching] = useState(false);

  const [resumeFile, setResumeFile] = useState(null);

  const onDropHandler = (acceptedFiles, rejectedFiles) => {
    if(acceptedFiles.length > 0){
      setResumeFile(acceptedFiles[0])
      // console.log();
    }

    if(rejectedFiles.length > 0){

    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <StyledRoot>
        <Box padding="16px 24px" position="relative" borderBottom="1px solid #D0D0D0">
          <Typography variant="h5">เรซูเม่</Typography>
          <Box position="absolute" right={0} top={0} padding="16px">
            <IconButton
              onClick={onClose}
            >
              <i className="fa-sharp fa-solid fa-x"></i>
            </IconButton>
          </Box>
        </Box>
        <Box padding="16px">
          {!resumeFile ? <Dropzone
            onDrop={onDropHandler}
            accept="application/pdf"
            maxFiles={1}
            multiple
            maxSize={10485760}
          >
            {({
              getRootProps,
              getInputProps,
              // open,
              // isDragActive,
              // acceptedFiles,
            }) => (
              <div {...getRootProps({ className: "dropzone" })}>

                {isFetching ? (
                  <Box>
                    <Typography>กำลังอัพโหลด..</Typography>
                  </Box>

                ):(
                  <Box>
                    <input {...getInputProps()}/>
                    <Box className="inner-dropzone">
                      <img alt="add" src={AddIcon} />
                      <Typography paddingTop="16px">{t("DragDropOrClickSelectFile")}</Typography>
                    </Box>
                  </Box>
                )}

              </div>
              )
            }
          </Dropzone> :
          <Box>
            <Typography>Resume.pdf</Typography>
          </Box>
          }
        </Box>
      </StyledRoot>
    </Dialog>
  )
}
export default DialogUploadResume;