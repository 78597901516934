import React, { useEffect, useState } from "react";
import { getAllProjectEmployees, addUsersToProject, getUsersInProject } from "../../../../actions/workforceEmployees";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { getAllEmployees } from "../../../../actions/employee";
import { getUserFullName } from "../../../../utils/userData";
import { t } from "i18next";

const DialogCreate = ({ open, handleClose, projectname,projectid, mode, onClose,checkidEmpSame,startDate,endDate }) => {
  const dispatch = useDispatch();
  // console.log(projectname)
  // console.log(projectid)
  const { result: employees } = useSelector((state) => state.employees);
  const { result: projectEmployees } = useSelector((state) => state.projectEmployees);
  // console.log(checkidEmpSame)

  const validationSchema = Yup.object().shape({
    projectname: Yup.mixed().required(t("PleaseFillInRequiredInformations")),
    employeename: Yup.mixed().required(t("PleaseFillInRequiredInformations")),

  });

  const useHookForm = useForm({
    defaultValues: {
      projectname: "",
      employeename: "",

    },
    resolver: yupResolver(validationSchema),
    mode: "all"
  })

  useEffect(() => {
    if (open === true) {
      //   if(drawerConfig.isEdit){
      //   useHookForm.reset({
      //     employeename: data.employeename || "",
      //     projectname: data.projectname || "",
      //   });
      // }
      // else {
      useHookForm.reset({
        projectname: "",
        employeename: "",

      })
      // }
    }
  }, [open]);


  // const onSubmit = async (data) => {
  //     // console.log(data)
  //     console.log(data)

  //     const formData = {
  //       // ...data,
  //       idProject: data.projectname.idProject,
  //       idEmployees: data.employee.idEmployees
  //     }
  //     console.log(data)
  //     const result = await dispatch(addUsersToProject(formData))
  //     if(result){
  //       if(result.status === 200){
  //         dispatch(getAllEmployees(employees.idEmployees, true)) 
  //       } else {
  //       }
  //     }   
  // }
  const [temptext,setTemptext] = useState('')
  const onSubmit = async (data) => {
    try {
      const formData = {
        idProject: projectid,
        idEmployees: data.employee.idEmployees,
        startDate:startDate,
        endDate:endDate
      };
      const checkidsame = checkidEmpSame.find((datacheck)=>datacheck===data.employee.idEmployees)
      // console.log(checkidsame)
      if(checkidsame){
        setTemptext(t("unableToSelectExistingEmployee"))
        return
      }else{
        setTemptext('')
      }
      // console.log(formData)
    
      const result = await dispatch(addUsersToProject(formData));

      if (result && result.status === 200) {
        
        await dispatch(getUsersInProject());
        handleClose()
        
      } else {
       
      }

    } catch (error) {
      // Handle errors, e.g., network issues, etc.
      console.error("Error submitting data:", error);
    }
  }

  const { control, handleSubmit } = useForm({
    defaultValues: {
      idProject: "",
      idEmployee: ""
    }
  })
  // const {
  //   control,
  //   reset,
  //   handleSubmit,
  //   watch,
  //   formState: { errors },
  // } = useForm({
  //   defaultValues: defaultData,
  //   resolver: yupResolver(validationSchema),
  // });


  // const handleAdd = async (values) => {
  //   const formData = {
  //     ...values,
  //     idEmployees: values.employee.idEmployees,
  //   };
  //   delete formData.employee;

  //   const cleansingForm = {
  //     projectname: formData.idProject || null,
  //     employees: formData.idEmployees || null,
  //   }
  //   console.log("formData", cleansingForm)

  //   let res = null;
  //   if (data) {
  //     res = await dispatch(updateEmployeesInProjectByID(formData));
  //   } else {
  //     res = await dispatch(addUsersInProject(formData));
  //   }
  //   if (res) {
  //     reset();
  //     handleClose();
  //     dispatch(getAllProjectEmployees());
  //   }
  // };
  // useEffect(() => {
  //   dispatch(getUsersInProject());
  // }, [dispatch]);

  const [selectedEmployee, setSelectedEmployee] = useState();
  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth={"sm"}>
      <DialogTitle>
        {t("AddEmployee")}
      </DialogTitle>

      <form onSubmit={useHookForm.handleSubmit(onSubmit)}>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={20} sm={15}>
              {/* <Controller
                name={"projectname"}
                control={useHookForm.control}
                rules={{
                  required: {
                    value: true,
                    message: "กรุณากรอกชื่อโปรเจก"
                  },
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={projectEmployees}
                    isOptionEqualToValue={(option, values) => option.idProject === values.idProject}
                    getOptionLabel={(option) => `${option.projectname}`}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.idProject}>
                          {`${option.projectname}`}
                        </li>
                      );
                    }}
                    onChange={(e, values) => field.onChange(values)}
                    value={field.value || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ชื่อโปรเจค"
                        helperText={useHookForm.formState.errors[field.name] ? useHookForm.formState.errors[field.name].message : null}
                        error={useHookForm.formState.errors[field.name] ? true : false}

                      />
                    )}
                  />
                )}
              /> */}
              <Controller
                name={"hour"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    defaultValue={projectname}
                    label={t("ProjectName")}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    value={projectname}
                  />


                )

                }

              />
            </Grid>
            <Grid item xs={20} sm={15}>
              <Controller
                name="employee"
                control={useHookForm.control}
                rules={{
                  required: {
                    value: true,
                    message: t("PleaseEnterYourFirstName")
                  },
                }}
                render={({ field }) => (

                  <Autocomplete

                    {...field}
                    options={employees}
                    isOptionEqualToValue={(option, values) => option.idEmployees === values.idEmployees}
                    getOptionLabel={(option) => `${getUserFullName(option)}`}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.idEmployees}>
                          {`${getUserFullName(option)}`}
                        </li>
                      );
                    }}
                    onChange={(e, values) => {
                      field.onChange(values);
                    }}
                    value={field.value || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("EmployeeList")}
                        helperText={useHookForm.formState.errors[field.name] ? useHookForm.formState.errors[field.name].message : null}
                        error={useHookForm.formState.errors[field.name] ? true : false}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
          <h4 style={{color:'red'}}>{temptext}</h4>
        </DialogContent>

        <DialogActions>
          <Button color="error" type="button" onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <ButtonBlue variant="contained" type="submit" >
            {t("Confirm")}
          </ButtonBlue>
        </DialogActions>
      </form>
      
    </Dialog>
  );
};


export default DialogCreate;