import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import {
    Dialog,
    DialogContent,
    Grid,
    Typography
} from '@mui/material';

import ButtonBlue from '../../../../shared/general/ButtonBlue';
import TextFieldTheme from '../../../../shared/general/TextFieldTheme';
import DatePickerCustom from '../../../../shared/date/datePicker';
import { t } from 'i18next';

const DialogLeftPF = (props) => {
    const { open, onClose, handleSubmit } = props;
    const { result: EmployeeProvidentFunds } = useSelector(state => state.employeeProvidentFunds);
    const [leftDate, setLeftDate] = useState(
        (
            EmployeeProvidentFunds.firstCutOff && 
            (parseInt(dayjs().get("date"))) > EmployeeProvidentFunds.firstCutOff
        ) ? 
            dayjs().add(1, "month") :
            dayjs()
    ); 

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogContent style={{ width: 350 }}>
                <Typography variant="h5" align="center">
                    <i className="fa-solid fa-triangle-exclamation" 
                        style={{ marginRight: 16, color:"#FFBF00" }}
                    />
                    {t("LeaveFund")}
                </Typography>
                <div style={{
                    width:"100%",
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"flex-start",
                    marginTop:"8px"
                }}>
                    <Typography style={{ marginBottom:"8px" }}>
                        {t("MonthExitFund")}
                    </Typography>
                    <DatePickerCustom
                        views={['year', 'month']}
                        inputFormat="MMMM YYYY"
                        minDate={
                            (
                                EmployeeProvidentFunds.firstCutOff && 
                                (parseInt(dayjs().get("date"))) > EmployeeProvidentFunds.firstCutOff
                            ) ? 
                                dayjs().add(1, "month") :
                                dayjs()
                        }
                        value={leftDate}
                        onChange={(newValue) => {
                            setLeftDate(newValue);
                        }}
                        renderInput={(params) => (
                            <TextFieldTheme {...params} />
                        )}
                    />
                </div>
                <Grid container justifyContent="space-between" style={{ marginTop: 16 }}>
                    <Grid item>
                        <ButtonBlue
                            onClick={onClose}
                        >{t("Cancel")}</ButtonBlue>
                    </Grid>
                    <Grid item>
                        <ButtonBlue 
                            variant="contained"
                            onClick={() => {
                                handleSubmit(dayjs(leftDate).date(1).format("YYYY-MM-DD"))
                            }}
                        >{t("Confirm")}</ButtonBlue>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default DialogLeftPF;