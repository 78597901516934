import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import DataGrid, {
  Column,
  Paging,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Scrolling,
  Sorting,
  Selection,
  Export,
  Button,
} from "devextreme-react/data-grid";

import utils from "../../../../../utils";

import StyledCard from "../../../shared/general/Card";
import { Typography } from "@mui/material";

import loading from "../../../assets/social-media.gif";

const StyledCardContent = styled(CardContent)({
  height: "100%",
  padding: 24,
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  "& .wrap-header-filter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    "& .wrap-filter": {
      display: "flex",
      "& .btn-filter": {
        marginRight: 8,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 8,
      },
    },
  },
  "& .wrap-manage": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .wrap-manage-group": {
      display: "flex",
      alignItems: "center",
      "& .btn-mamage-group-company": {
        marginRight: 8,
      },
    },
    "& .wrap-edit-selected": {},
  },
  "& .wrap-panel": {
    height: 490,
    overflow: "auto",
    display: "flex",
  },
});

const StyledWrapDataGrid = styled("div")({
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },

  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#009688",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },

  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
});

const StyleWrapFooter = styled("div")({
  padding: 16,
  "& .wrap-late": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .wrap-text-header": {
    "& .MuiTypography-body1": {
      fontWeight: 500,
    },
  },
  "& .text-header": {
    display: "flex",
    alignItems: "center",
  },
  "& .text-value": {
    textAlign: "center",
    "& .MuiTypography-h5": {
      fontWeight: 600,
      color: "#e46a76",
      lineHeight: 1,
    },
  },
  "& .sum-baht": {
    fontWeight: 500,
  },
});

const StyledLoading = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "inherit",
});

const TableEmployee = (props) => {
  const { result, topic, handleChangeSelectEmployee } = props;
  const grid = useRef();
  const { t, i18n } = useTranslation();

  const { result: summaryTimeList } = useSelector((state) => state.summaryTime);
  const { isFetching: summaryTimeListFetching } = useSelector(
    (state) => state.summaryTime
  );
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedRowsData, setSelectedRowsData] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const { result: userProfile } = useSelector((state) => state.userProfile);


  let gridColumnsOT = [
    {
      dataField: "otOneHours",
      caption: `OT 1 (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
      isShow: 1,
    },
    {
      dataField: "otOneAmount",
      caption: `OT 1 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
      isShow: userProfile.isSalary,
    },
    {
      dataField: "otOneFiveHours",
      caption: `OT 1.5 (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
      isShow: 1,
    },
    {
      dataField: "otOneFiveAmount",
      caption: `OT 1.5 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
      isShow: userProfile.isSalary,
    },
    {
      dataField: "otTwoHours",
      caption: `OT 2 (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
      isShow: 1,
    },
    {
      dataField: "otTwoAmount",
      caption: `OT 2 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
      isShow: userProfile.isSalary,
    },
    {
      dataField: "otThreeHours",
      caption: `OT 3 (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
      isShow: 1,
    },
    {
      dataField: "otThreeAmount",
      caption: `OT 3 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
      isShow: userProfile.isSalary,
    },
  ];

  let gridColumnsShift = [
    {
      dataField: "shiftPayMorning",
      caption: `${t("ShiftPayMorning")}`,
      format: "###,###,##0.00",
      cssClass: "column-shift",
      isShow: userProfile.isSalary,
    },
    {
      dataField: "shiftPayAfternoon",
      caption: `${t("ShiftPayAfternoon")}`,
      format: "###,###,##0.00",
      cssClass: "column-shift",
      isShow: userProfile.isSalary,
    },
    {
      dataField: "shiftPayNight",
      caption: `${t("ShiftPayNight")}`,
      format: "###,###,##0.00",
      cssClass: "column-shift",
      isShow: userProfile.isSalary,
    },
    {
      dataField: "shiftPayTotal",
      caption: `${t("ShiftPayTotal")}`,
      format: "###,###,##0.00",
      cssClass: "column-shift",
      isShow: userProfile.isSalary,
    },
  ];

  useEffect(() => {
    if (summaryTimeList && summaryTimeList.employeeNormal) {
      let temp = [...summaryTimeList.employeeNormal];
      temp = temp.filter((x) => x.idPaymentType === 2 || x.idPaymentType === 5);
      temp.forEach((element) => {
        element["fullname"] = [element.firstname, element.lastname].join(" ");

        element["otOneHours"] = element.roundDate.ot.otOneHours;
        element["otOneAmount"] = element.roundDate.ot.otOneAmount;
        element["otOneFiveHours"] = element.roundDate.ot.otOneFiveHours;
        element["otOneFiveAmount"] = element.roundDate.ot.otOneFiveAmount;
        element["otTwoHours"] = element.roundDate.ot.otTwoHours;
        element["otTwoAmount"] = element.roundDate.ot.otTwoAmount;
        element["otThreeHours"] = element.roundDate.ot.otThreeHours;
        element["otThreeAmount"] = element.roundDate.ot.otThreeAmount;

        element["shiftPayMorning"] = element.roundDate.shift.shiftPayMorning;
        element["shiftPayAfternoon"] =
          element.roundDate.shift.shiftPayAfternoon;
        element["shiftPayNight"] = element.roundDate.shift.shiftPayNight;
        element["shiftPayTotal"] = element.roundDate.shift.shiftPayTotal;
      });
      setData(temp);
    }
  }, [summaryTimeList]);

  const calculateCellValue = (data) => {
    return [data.firstname, data.lastname].join(" ");
  };

  const onSelectionChanged = ({ selectedRowsData }) => {
    handleOpenDialog();
    setSelectedRowsData(selectedRowsData[0]);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const rowIndex = (cellInfo) => {
    return cellInfo.rowIndex + 1;
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const customizeOTHour = (data) => {
    return data.value ? `${utils.roundToTwo(data.value)} ${t("Unit.ShortHours")}` : "";
  };

  const customizeOTAmount = (data) => {
    return data.value ? `${utils.roundToTwo(data.value)} บาท` : "";
  };

  const calculateCustomSummary = (options) => {
    console.log(options);
  };

  const onExporting = useCallback(() => {
    // let workbook = new ExcelJS.Workbook();
    // let worksheet = workbook.addWorksheet("SheetName");
    // exportDataGrid({
    //   component: grid.current.instance,
    //   worksheet: worksheet,
    // }).then(function () {
    //   workbook.xlsx.writeBuffer().then(function (buffer) {
    //     saveAs(
    //       new Blob([buffer], { type: "application/octet-stream" }),
    //       `${payRunDetail.payrunName}.xlsx`
    //     );
    //   });
    // });
  }, []);

  const handleClickViewMonthly = (selected) => {
    handleChangeSelectEmployee(selected.row.data);
  };

  return (
    <Fragment>
      <StyledCard>
        <StyledCardContent>
          {/* <div>
            <Typography variant="h5">จ่ายรายเดือน</Typography>
            {summaryTimeList && (
              <Typography
                variant="h6"
                color="text.third"
                style={{ fontSize: 18 }}
              >
                รอบการจ่ายเงินเดือน:{" "}
                {`${dayjs(summaryTimeList.start).format(
                  "D MMM BBBB"
                )} - ${dayjs(summaryTimeList.end).format("D MMM BBBB")}`}
              </Typography>
            )}
          </div> */}
          {summaryTimeListFetching ? (
            <StyledLoading>
              <img width="80" alt="loading" src={loading} />
            </StyledLoading>
          ) : (
            <StyledWrapDataGrid>
              <DataGrid
                dataSource={data}
                showBorders={true}
                showColumnLines={true}
                rowAlternationEnabled={true}
                columnAutoWidth={true}
              >
                {summaryTimeList && (
                  <Column type="buttons" width={60}>
                    <Button
                      icon="fa-regular fa-calendar-circle-user"
                      onClick={handleClickViewMonthly}
                    />
                  </Column>
                )}
                <Column
                  caption={`${t("EmployeeID")}`}
                  dataField="employeeID"
                  dataType="string"
                  width={120}
                  cssClass="column-info"
                />

                <Column
                  caption={`${t("FullName")}`}
                  dataType="string"
                  cssClass="column-info"
                  dataField={i18n.resolvedLanguage === "en" ? "fullname_EN" : "fullname_TH"}
                >
                  <HeaderFilter allowSearch={true} />
                </Column>

                <Column
                  dataField="sectionName"
                  caption={`${t("Section")}`}
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField={i18n.resolvedLanguage === "en" ? "positionName_EN" : "positionName"}
                  caption={`${t("Position")}`}
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="workingType"
                  caption={`${t("WorkingType")}`}
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="paymentTypeName"
                  caption={`${t("PaymentTypeName")}`}
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="paymentRoundName"
                  caption={`${t("PaymentRound")}`}
                  dataType="string"
                  cssClass="column-info"
                  //groupIndex={0}
                />

                {console.log(gridColumnsOT)}

                {gridColumnsOT.map((item, key) => {
                  if(item.isShow){
                    return (
                      <Column
                        dataField={item.dataField}
                        caption={item.caption}
                        format={item.format}
                        cssClass="column-total-addition"
                      />
                    )
                  }
                })}

                {summaryTimeList &&
                  summaryTimeList.isPayShift === 1 &&
                  gridColumnsShift.map((item, key) => {
                    if(item.isShow){
                      return (
                        <Column
                          dataField={item.dataField}
                          caption={item.caption}
                          format={item.format}
                          cssClass={item.cssClass}
                        />
                      )
                    }
                })}

                <Export enabled={true} allowExportSelectedData={true} />

                <Selection mode="single" />
                <Sorting mode="multiple" />
                <Scrolling columnRenderingMode="virtual" />
                <Paging defaultPageSize={20} />
                <FilterRow visible={false} />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  width={240}
                  placeholder={`${t("Search")}`}
                />
              </DataGrid>
            </StyledWrapDataGrid>
          )}
        </StyledCardContent>
      </StyledCard>
    </Fragment>
  );
};
export default TableEmployee;
