import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Container,
  Breadcrumbs,
  Paper,
  Grid,
  Button,
  Divider,
  Stack
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { personalityDISCTypes } from "./type";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 50,
});

const StyledPaper = styled(Paper)({
  padding: 20,
  minHeight: "auto",
  backgroundSize: "cover",
  backgroundPosition: "top",
  borderRadius: "20px",
});

const StyledAnswer = styled("div")({
  "& .header-answer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    marginTop: "10px",
    textAlign: "left",

    "& .answerText": {
      color: "black",
      fontSize: "18px",
      fontWeight: 400,
      alignSelf: "center",
      marginTop: "10px",
      whiteSpace: "pre-wrap",
    },
  },
  "& .content-answer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 20,
    marginTop: "10px",
    textAlign: "left",

    "& .textTopic, .textContent": {
      color: "black",
      fontSize: "18px",
      fontWeight: 600,
      textAlign: "left",
      marginBottom: "10px",
    },
    "& .textContent": {
      color: "black",
      fontSize: "18px",
      fontWeight: 400,
      textAlign: "left",
      marginBottom: "10px",
    },
  },
});

const StyleButton = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginTop: "10px",
  "& button": {
    color: "white",
    backgroundColor: "#DB4178",
    "&:hover": {
      backgroundColor: "#FC669B",
    },
  },
});

const ResultTestDISC1 = (props) => {
  const { scores } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [showFullText, setShowFullText] = useState(false);

  const location = useLocation();
  const parseQueryString = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("result");
  };
  const result = parseQueryString();

  const toggleReadMore = () => {
    setShowFullText(!showFullText);
  };

  const backPersonalityPage = () => {
    history.push('/personality-character-test')
  }

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to={"/personality-character-test"}
          >
            All
          </Link>
          <Typography color="text.primary">Result</Typography>
        </Breadcrumbs>
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            Result
          </Typography>
        </div>

        {personalityDISCTypes.map((type) =>
          type.id === result ? (
            <StyledPaper
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/DISC1/Bg-Result.svg)`,
              }}
            >
              <img
                src={type.imagePath}
                style={{
                  width: "30%",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "200px",
                  marginBottom: "auto",
                }}
              />
              <div style={{ position: "relative" }}>
                <Grid item>
                  <Box sx={{
                    position: 'absolute',
                    top: -30,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 1
                  }}>
                    <Box sx={{ bgcolor: "#DB4178", width: 200, p: 3, borderRadius: 3 }}>
                      <Typography
                        fontSize={17}
                        fontWeight={600}
                        color={"#FFFFFF"}
                        textAlign={"center"}
                      >
                        <Box className="box-header">{type.title}</Box>
                      </Typography>
                    </Box>
                  </Box>
                  <Paper
                    style={{
                      minHeight: 100,
                      maxWidth: 900,
                      padding: 20,
                      marginLeft: "5vw",
                      marginRight: "5vw",
                    }}
                  >
                    <StyledAnswer>
                      <div className="header-answer">
                        <Typography className="answerText">
                          {showFullText
                            ? type.content.longText
                            : type.content.shortText}
                        </Typography>
                      </div>
                      {showFullText && (
                        <div>
                          <Divider />
                          <div className="content-answer">
                            <Typography className="textTopic">ข้อดี</Typography>
                            <Typography className="textContent">
                              {type.content.Advantages}
                            </Typography>
                            <Typography className="textTopic">
                              ข้อเสียที่ควรแก้ไข
                            </Typography>
                            <Typography className="textContent">
                              {type.content.Disadvantages}
                            </Typography>
                          </div>
                        </div>
                      )}
                    </StyledAnswer>
                    <Stack justifyContent={"center"} alignItems={"center"} direction={"row"} spacing={1}>
                      <StyleButton>
                        <Button variant="contained" onClick={toggleReadMore}>
                          {showFullText ? "Read less" : "Read more"}
                        </Button>
                      </StyleButton>
                      <StyleButton>
                        <Button variant="contained" sx={{ width: 110 }} onClick={() => backPersonalityPage()}>
                          {t("goBack")}
                        </Button>
                      </StyleButton>
                    </Stack>
                  </Paper>
                </Grid>
              </div>
            </StyledPaper>
          ) : null
        )}
      </Container>
    </StyledRoot>
  );
};

export default ResultTestDISC1;
