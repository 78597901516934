import React, { useState } from "react";
import { Box, CircularProgress, Dialog, FormHelperText, IconButton, styled, Typography } from "@mui/material";
import CardStyle from "../../../../../shared/general/Card";
import Dropzone from "react-dropzone";
import AddIcon from "./../../../../../assets/addCertificate.png";
import { useDispatch } from "react-redux";
import { addManpowerCertificate, deleteManpowerCertificate, getManpowerCertificate } from "../../../../../../../actions/manpower";
import ButtonBlue from "../../../../../shared/general/ButtonBlue";

const StyledRoot = styled(Box)({
  padding: "24px",
  "& .dropzone": {
    cursor: "pointer",
    flexShrink: 0,
    width: 128,
    height: 128,
    boxSizing: "border-box",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .inner-dropzone": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& img": {
        width: 56,
        height: 56,
      }
    }
  },
  "& .certificated-wrap": {
    textDecoration: "none",
    color: "inherit",
    position: "relative",
    cursor: "pointer",
    flexShrink: 0,
    padding: 8,
    width: 128,
    height: 128,
    boxSizing: "border-box",
    border: "1px solid #D0D0D0",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    "& .icon-part": {
      flexGrow:1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& i": {
        fontSize: 36,
        color: "#D0D0D0"
      }
    },
    "& .filename": {
      flexShrink: 1,
      height: "48px",
      "& .MuiTypography-root": {
        fontSize: 14,
        display: "-webkit-box",
        overflow: "hidden",
        WebkitLineClamp: 2, 
        WebkitBoxOrient: "vertical",
        textAlign: "center",
        wordBreak: "break-all",
      }
    },
    "& .delete": {
      position: "absolute",
      top: 0,
      right: 0,
      padding: 8,
      "& i": {
        fontSize: 16,
      }
    }
  }
})

const DialogDelete = (props) => {

  const { open, onClose, idManpower, idManpowerCertificate, setCertificates } = props;

  const [isFetching, setIsFetcing] = useState(false);

  const dispatch = useDispatch();

  const onConfirmDelete = () => {

    setIsFetcing(true);

    dispatch(deleteManpowerCertificate(idManpower, idManpowerCertificate)).then(res => {
      if(res.status === 200){
        onClose();
        dispatch(getManpowerCertificate(idManpower)).then(res => {
          setCertificates(res.data.data)
        })
      }
      setIsFetcing(false);
    })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <Box
        padding="16px"
      >
        <Typography fontSize={20}>ลบ</Typography>
        <Box padding="24px 0">
          <Typography>คุณต้องการลบไฟล์หรือไม่</Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <ButtonBlue variant="outlined" onClick={onClose}>ยกเลิก</ButtonBlue>
          <ButtonBlue variant="contained" onClick={onConfirmDelete} disabled={isFetching} startIcon={isFetching && <CircularProgress size={16}/>}>ลบ</ButtonBlue>
        </Box>
      </Box>
    </Dialog>
  )
}

const Certificated = (props) => {

  const { idManpower, data, isMyManpower } = props;

  const [certificates, setCertificates] = useState(data);

  const [deleteDiglogState, setDeleteDialogState] = useState({
    isOpen: false,
    idManpowerCertificate: null,
  })

  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);

  const dispatch = useDispatch();

  const onDropHandler = (acceptedFiles, rejectedFiles) => {

    setIsError(false);

    if(acceptedFiles.length > 0){

      if(certificates.length >= 3){
        setIsError("สามารถอัพโหลดได้สูงสุด 3 ไฟล์เท่านั้น")
        return;
      }

      if((acceptedFiles.length + certificates.length) > 3){
        acceptedFiles.splice(3 - certificates.length);
        setIsError("สามารถอัพโหลดได้สูงสุด 3 ไฟล์เท่านั้น");
      }

      setIsFetching(true);

      const formData = new FormData;

      acceptedFiles.map(f => {
        formData.append("certificateFile", f)
      })

      dispatch(addManpowerCertificate(idManpower, formData)).then(res => {
        if(res.status === 200){
          dispatch(getManpowerCertificate(idManpower)).then(res => {
            setCertificates(res.data.data)
          })
        }
        setIsFetching(false);
      })
    }

    if(rejectedFiles.length > 0){
      if(rejectedFiles[0].errors[0].code === "file-invalid-type"){
        setIsError("อัพโหลดได้เฉพาะไฟล์รูปภาพ หรือ .pdf เท่านั้น")
      }
    }
  }

  const onClickDelete = (idManpowerCertificate) => {
    setDeleteDialogState({
      isOpen: true,
      idManpowerCertificate: idManpowerCertificate
    })
  }

  return (
    <CardStyle>
      <StyledRoot>
        <Typography variant="h5" fontSize={20}>ใบประกาศนียบัตร</Typography>

        {(isMyManpower || certificates.length > 0) && <Box marginTop="24px" display="flex" gap="8px" flexWrap="wrap">
          {isMyManpower && <Dropzone
            onDrop={onDropHandler}
            accept="application/pdf, image/*"
            // maxFiles={5}
            multiple
            maxSize={10485760}
          >
            {({
              getRootProps,
              getInputProps,
            }) => (
              <div {...getRootProps({ className: "dropzone" })}>

                {isFetching ? (
                  <Box>
                    <Box className="inner-dropzone">
                      <CircularProgress />
                    </Box>
                  </Box>
                ):(
                  <Box>
                    <input {...getInputProps()}/>
                    <Box className="inner-dropzone">
                      <img alt="add" src={AddIcon} />
                    </Box>
                  </Box>
                )}
              </div>
              )
            }
          </Dropzone>}

          {certificates && certificates.map(c => (
            <Box key={c.idManpowerCertificate} className="certificated-wrap" component={"a"} href={c.filePath} target="_blank">
              <Box className="icon-part">
                <i className="fa-solid fa-file"></i>
              </Box>
              <Box className="filename">
                <Typography>{c.filename}</Typography>
              </Box>
              {isMyManpower && <IconButton className="delete" onClick={(e)=>{e.preventDefault(); onClickDelete(c.idManpowerCertificate)}}>
                <i className="fa-solid fa-trash-can"></i>
              </IconButton>}
            </Box>
          ))}

        </Box>}

        {(!isMyManpower && certificates.length <= 0) && (
          <Typography textAlign="center" color="rgb(145, 158, 171)" margin="16px 0">ไม่มีใบประกาศนียบัตร</Typography>
        )}

        {isMyManpower && <FormHelperText error>*สามารถอัพโหลดได้เฉพาะไฟล์ .pdf หรือ รูปภาพ ได้สูงสุด 3 ไฟล์เท่านั้น</FormHelperText>}

        <DialogDelete
          open={deleteDiglogState.isOpen}
          onClose={()=>{
            setDeleteDialogState({
              isOpen: false,
              idManpowerCertificate: null
            })
          }}
          idManpower={idManpower}
          idManpowerCertificate={deleteDiglogState.idManpowerCertificate}
          setCertificates={setCertificates}
        />

      </StyledRoot>
    </CardStyle>
  )
}

export default Certificated;