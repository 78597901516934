import React from 'react';
import { Card, CardContent, Typography, Box, FormControlLabel, Avatar, Switch } from '@mui/material';
import { Stack, styled } from '@mui/system';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#5d4789',
      },
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('/assets/icon/business.png')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#e6e6e6' : '#e6e6e6',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: 'rgb(70, 203, 226)',
    width: 32,
    height: 32,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('/assets/icon/user.png')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#e6e6e6' : '#e6e6e6',
    borderRadius: 20 / 2,
  },
}));

function InfoCard({ image, name, id, job, t, isActive, setIsActive }) {

  const handleSwitchChange = (event) => {
    setIsActive(event.target.checked);
  };

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'row', paddingRight: 2, paddingLeft: 2 }}>
      <CardContent sx={{ width: '100%', display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'center', sm: 'flex-start' } }}>
          <Avatar
            src={image}
            alt={`${name}'s profile`}
            sx={{ width: 80, height: 80, margin: 2, boxShadow: 1, background: 'whitesmoke' }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 2, textAlign: { xs: 'center', sm: 'start' } }}>

            <Typography variant="h6">{name}</Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {t('EmployeeID')}: {id}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {t('Position')}: {job}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' }, alignItems: 'flex-start', marginTop: 2 }}>
          <FormControlLabel
            control={
              <Stack direction="column" alignItems="center">
                <MaterialUISwitch
                  checked={isActive}
                  onChange={handleSwitchChange}
                  color="primary"
                />
                <Typography variant="body2">
                  {isActive ? t("Manager") : t("Users")}
                </Typography>
              </Stack>
            }
            label=""
            sx={{
              marginLeft: { xs: 0, sm: 'auto' },
              marginRight: { xs: 0, sm: 'auto' },
            }}
          />
        </Box>
      </CardContent>

    </Card>
  );
}

export default InfoCard;
