import MyDocument from "../../../pages/assets/-_Branding & Communication.png";

export const reportLists = [
  {
    name: "กท. 20ก",
    name_EN: "KorTor 20 Kor",
    image: MyDocument,
    link: "20k",
    key: "fundDocumentt",
  },
  {
    name: "ภ.ง.ด. 1",
    name_EN: "PND 1",
    image: MyDocument,
    link: "PND1",
    key: "pnd1Documentt",
  },
  {
    name: "ภ.ง.ด. 1ก",
    name_EN: "PND 1 Kor",
    image: MyDocument,
    link: "PND1k",
    key: "pnd1kDocumentt",
  },
  // {
  //     name: "ภ.ง.ด. 3",
  //     image: MyDocument,
  //     link: "PND3",
  //     key: "pnd3Documentt"
  // },
  {
    name: "ภ.ง.ด. 91",
    name_EN: "PND 91",
    image: MyDocument,
    link: "PND91",
    key: "pnd91Documentt",
  },
  {
    name: "50 ทวิ",
    name_EN: "50 Tawi",
    image: MyDocument,
    link: "50tawi",
    key: "tawi50Documentt",
  },
  {
    name: "สปส. 1-03",
    name_EN: "SSO 1-03",
    image: MyDocument,
    link: "SPS1-03",
    key: "SPS103Documentt",
  },
  {
    name: "สปส. 1-03/1",
    name_EN: "SSO 1-03/1",
    image: MyDocument,
    link: "SPS1-03-1",
    key: "SPS1031Documentt",
  },
  {
    name: "สปส. 1-10",
    name_EN: "SSO 1-10",
    image: MyDocument,
    link: "SPS1-10",
    key: "SPS110Documentt",
  },
  {
    name: "สปส. 6-09",
    name_EN: "SSO 6-09",
    image: MyDocument,
    link: "SPS6-09",
    key: "SPS609Documentt",
  },
  // {
  //     name: "รายงานกองทุนสำรองเลี้ยงชีพ",
  //     image: MyDocument,
  //     link: "PVF",
  //     key: "fundDocumentt"
  // },
  // {
  //     name: "รายงานลดหย่อนภาษี",
  //     image: MyDocument,
  //     link: "tax-deduction",
  //     key: "fundDocumentt"
  // },
  // {
  //     name: "สัญญาจ้างพนักงาน",
  //     image: MyDocument,
  //     link: "employee-contract",
  //     key: "fundDocumentt"
  // },
];
