import React, { useEffect, useState } from "react";
import { Chip, Grid, IconButton, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import DrawerDownload from "./drawerDownload";
import { useDispatch, useSelector } from "react-redux";
import { getAdditionPersonal } from "../../../../../../actions/additionSpecial";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";
import additionSepecialService from "../../../../../../services/additionSepecial.service";
import AdditionPersonalForm from "./additionPersonalForm";

const AdditionPersonal = ({ selectedCompany }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: additionPersonal } = useSelector(
    (state) => state.additionPersonal
  );
  const [openEdit, setOpenEdit] = useState(false);
  const [selectAddition, setSelectAddition] = useState(null);
  const [openDownload, setOpenDownload] = useState(false);

  const leaveTypeLabel = (leaveType) => {
    if (leaveType === "allLeave") {
      return t("AllLeave");
    } else if (leaveType === "exceptAnnualLeave") {
      return t("NoLeaveExceptAnnualLeave");
    } else {
      return t("NotSpecified");
    }
  };

  const handleOpenDownload = () => {
    setOpenDownload(true);
  };

  const handleCloseDownload = () => {
    setOpenDownload(false);
  };

  const handleOpenAdd = () => {
    setOpenEdit(true);
  };

  const handleOpenEdit = (item) => {
    setSelectAddition(item);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setSelectAddition(null);
    setOpenEdit(false);
  };

  const handleDownload = async (data) => {
    const res = await additionSepecialService.exportExcelAdditionPersonal({
      searchDate: dayjs(data.searchDate).format("YYYY-MM-DD"),
      idAdditionPersonal: data.additionSpecial.idAdditionPersonal,
    });

    if (res && res.status === 200) {
      const excelBlob = new Blob([res.data], {
        type: res.headers["content-type"],
      });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(excelBlob);

      downloadLink.download = `${data.additionSpecial.name} ${
        selectedCompany.companyName_EN
      } ${res.headers["content-disposition"]
        .split("filename")[1]
        .slice(2, -1)}`;

      downloadLink.click();
    } else {
      dispatch(openNotificationAlert({ message: "error", type: "error" }));
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getAdditionPersonal(selectedCompany.idCompany));
    }
  }, [selectedCompany.idCompany]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        minHeight={"40px"}
        marginBottom={"24px"}
      >
        <Typography
          sx={{
            paddingLeft: "8px",
            borderLeft: "8px solid #46cbe2",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
          }}
          fontSize="20px"
          fontWeight="500"
        >
          {`${t("AdditionPersonal")} (AP)`}
        </Typography>
        {!openEdit && (
          <div style={{ display: "flex", gap: "8px" }}>
            <IconButton
              onClick={handleOpenAdd}
              sx={{
                color: "#ffffff",
                backgroundColor: "#1976d2",
                "&:hover": { backgroundColor: "#145ea8" },
              }}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              onClick={handleOpenDownload}
              sx={{
                color: "#ffffff",
                backgroundColor: "#1976d2",
                "&:hover": { backgroundColor: "#145ea8" },
              }}
            >
              <DownloadIcon />
            </IconButton>
          </div>
        )}
      </Stack>
      {openEdit && (
        <AdditionPersonalForm
          handleClose={handleCloseEdit}
          selectedCompany={selectedCompany}
          selectAddition={selectAddition}
        />
      )}
      {!openEdit && additionPersonal && (
        <Stack gap="16px">
          {additionPersonal.map((addition) => (
            <div
              key={addition.idAdditionPersonal}
              style={{
                padding: "16px",
                borderRadius: "8px",
                backgroundColor: "#f2f2f2",
              }}
            >
              <Grid container alignItems="center" rowGap="16px">
                <Grid
                  item
                  xs={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5">{addition.name}</Typography>
                  <IconButton
                    onClick={() => handleOpenEdit(addition)}
                    sx={{
                      color: "#ffffff",
                      backgroundColor: "#ffb74d",
                      "&:hover": { backgroundColor: "#ffa726" },
                    }}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography fontWeight={500}>{`${t("Time")} (${t(
                    "Unit.Minute"
                  )})`}</Typography>
                  <Typography fontSize={18}>{addition.minutes}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography fontWeight={500}>{t("TimeConditions")}</Typography>
                  <div style={{ display: "flex", gap: "8px" }}>
                    <Chip
                      label={t("Before")}
                      color={addition.isBefore ? "primary" : "default"}
                    />
                    <Chip
                      label={t("During")}
                      color={addition.isDuring ? "primary" : "default"}
                    />
                    <Chip
                      label={t("After")}
                      color={addition.isAfter ? "primary" : "default"}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography fontWeight={500}>{t("Condition")}</Typography>
                  <Chip
                    label={leaveTypeLabel(addition.leaveType)}
                    color={addition.leaveType ? "primary" : "default"}
                  />
                </Grid>
              </Grid>
            </div>
          ))}
        </Stack>
      )}
      {additionPersonal && openDownload && (
        <DrawerDownload
          openDownload={openDownload}
          handleDownload={handleDownload}
          handleCloseDownload={handleCloseDownload}
          additionSpecial={additionPersonal}
          idCompareOption={"idAdditionPersonal"}
        />
      )}
    </>
  );
};

export default AdditionPersonal;
