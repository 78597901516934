import React, { useEffect, useState, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";

import {
  Grid,
  Typography,
  FormControl,
  Box,
  CircularProgress,
  Link,
  MenuItem,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@mui/material";

import DrawerCustom from "../../../../shared/general/Drawer";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

import AddIcon from "../../../../assets/add.png";

import { uploadFileHoliday } from "../../../../../../actions/holiday";

import ShowResponse from "./show-response";
import FileSelected from "./file-selected";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getAllHolidaysPattern } from "../../../../../../actions/holiday";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledImportFile = styled("div")({
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  marginTop: 24,
  "& .dropzone-upload-file": {
    textAlign: "center",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    height: 200,
    display: "flex",
    justifyContent: "center",
    "& .inner-dropzone": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      "& img": {
        marginBottom: 16,
      },
    },
  },
});

const StyledLoading = styled("div")({
  height: 200,
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const StyledSelect = styled(Select)({
  marginBottom: "16px",
  "&.MuiInputBase-root":{
    borderRadius:"8px"
  }
});

const DialogUpload = (props) => {
  const { open, handleCloseDialog, handleOpenAlert, handleChangeAlertType, selectedCompany, isReadOnly } =
    props;
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { t, i18n } = useTranslation();
  const { result: holidayPattern } = useSelector((state) => state.holidayPattern);

  const [fileSelected, setFileSelected] = useState(null);
  const [formFileSelected, setFormFileSelected] = useState(null);
  const [openLoading, setOpenLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState(null);
  const [addPattern, setAddPattern] = useState('0');
  const [pattern, setPattern] = useState("");
  const [nameAddPattern, setNameAddPattern] = useState("");
  const [listPatternYear, setListPatternYear] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    onDrop: (acceptedFiles) => {
      const formData = new FormData();
      acceptedFiles.map((file) => {
        formData.append("file", file);
        formData.append("year", 2022);
        if(addPattern === "0"){
          formData.append("idHolidayPattern", pattern)
        }else{
          formData.append("nameAddPattern", nameAddPattern)
        }
        formData.append("idCompany", selectedCompany.idCompany);
      });
      setFormFileSelected(formData);
      setFileSelected({
        name: acceptedFiles[0].name,
        size: acceptedFiles[0].size,
      });
    },
    maxFiles: 1,
  });

  useEffect(() => {
    if(holidayPattern){
      let filterYear = holidayPattern[dayjs().format("YYYY")];
      let result = [];
      let patternMain = "";
      Object.keys(filterYear).forEach(id => {
        result.push({
          idHolidayPattern : parseInt(id),
          ...filterYear[id]
        });
        if(filterYear[id].isMainPattern){
          patternMain = parseInt(id)
        }
      });
      setListPatternYear(result);
      setPattern(patternMain);
    }else{
      setListPatternYear([]);
      setPattern("");
    }
  },[holidayPattern]);

  const handleClickUpload = async () => {
    setOpenLoading(true);
    const result = await dispatch(uploadFileHoliday(formFileSelected));
    if (result) {
      handleCloseLoading();
      if (result.status === 200 && result.data.rows > 0) {
        handleOpenAlert();
        handleChangeAlertType("success");
        setOpenLoading(false);
        handleCloseDialog();
        dispatch(getAllHolidaysPattern({
          start: dayjs()
            .subtract(1, "year")
            .set("date", 1)
            .set("month", 0)
            .format("YYYY-MM-DD"),
          end: dayjs()
            .add(1, "year")
            .set("date", 31)
            .set("month", 11)
            .format("YYYY-MM-DD"),
          idCompany: selectedCompany.idCompany,
        }));
      } else if (result.status === 400) {
        setErrorResponse(result);
      } else {
        handleOpenAlert();
        handleChangeAlertType("error");
        setOpenLoading(false);
        handleCloseDialog();
      }
    }
  };

  const handleCloseLoading = () => {
    setOpenLoading(false);
    handleCloseDialog();
  };

  const handleClickDownload = () => {
    window.open(`${process.env.REACT_APP_API_URL}files/Template_Holiday.xlsx`);
  };

  return (
    <DrawerCustom
      title={`${t("UploadHolidays")}`}
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {openLoading ? (
              <StyledLoading>
                <Typography variant="h6" color="text.third" gutterBottom>
                  {t("SavingData")}...
                </Typography>
                <div>
                  <CircularProgress />
                </div>
              </StyledLoading>
            ) : (
              <Fragment>
                {errorResponse ? (
                  <ShowResponse
                    errorResponse={errorResponse}
                    handleCloseLoading={handleCloseLoading}
                  />
                ) : (
                  <Fragment>
                    <FormControl style={{ marginBottom:"16px" }}>
                      <RadioGroup
                        value={addPattern}
                        onChange={(e) => {
                          if(e.target.value){
                            setAddPattern(e.target.value);
                            setFormFileSelected(null);
                            setFileSelected(null);
                          }
                        }}
                      >
                        <div style={{ display:"flex" }}>
                          <FormControlLabel  style={{ marginRight:"16px" }} value="0" control={<Radio />} label="เลือก Pattern" />
                          <FormControlLabel value="1" control={<Radio />} label="เพิ่ม Pattern" />
                        </div>
                      </RadioGroup>
                    </FormControl>
                    {addPattern === '0' &&
                      <FormControl fullWidth>
                        <StyledSelect
                          value={pattern}
                          onChange={(e) => {
                            if(e.target.value){
                              setPattern(e.target.value);
                              setFormFileSelected(null);
                              setFileSelected(null);
                            }
                          }}
                        >
                          {listPatternYear.map((pattern) => (
                            <MenuItem key={pattern.idHolidayPattern} value={pattern.idHolidayPattern}>{pattern.name}</MenuItem>
                          ))}
                        </StyledSelect>
                      </FormControl>
                    }
                    {addPattern === '1' &&
                      <Fragment>
                        <Typography style={{ marginBottom: "8px" }}>ชื่อ Pattern ใหม่</Typography>
                        <TextFieldTheme 
                          style={{
                            marginBottom:"16px"
                          }}
                          value={nameAddPattern}
                          onChange={(e) => {
                            setNameAddPattern(e.target.value);
                          }}
                        />
                      </Fragment>
                    }
                    <div
                      style={{
                        marginBottom: 16,
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Link variant="body2" onClick={handleClickDownload}>
                        {t("DownloadTemplate")}
                      </Link>
                    </div>
                    <div>
                      <StyledImportFile>
                        <div
                          {...getRootProps({
                            className: "dropzone-upload-file",
                          })}
                        >
                          <input {...getInputProps()} />
                          <div className="inner-dropzone">
                            <img alt="AddIcon" src={AddIcon} width="80" />
                            <Typography
                              style={{
                                marginTop: 8,
                                backgroundColor: "transparent",
                              }}
                              className={`${fileSelected != 0 && `placeholderLabel`
                                }`}
                              variant="body2"
                              color="text.secondary"
                            >
                               {t("DragDropOrClickSelectFile")}
                            </Typography>
                          </div>
                        </div>
                      </StyledImportFile>
                    </div>

                    {fileSelected && (
                      <FileSelected
                        fileSelected={fileSelected}
                        handleClickUpload={handleClickUpload}
                        isReadOnly={!!isReadOnly}
                      />
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </Grid>
        </Grid>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogUpload;
