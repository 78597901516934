import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Avatar,
  Paper,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DatePickerCustom from "../../shared/date/datePicker";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";
import SendIcon from "@mui/icons-material/Send";

import CardStyle from "../../shared/general/Card";
import Logo from "../../assets/Chatbot.png";

import { getBotResponse } from "../../../../actions/webhook";
import dayjs from "dayjs";
import {
  getAllRequestTimeBy,
  getAllRequestTimeWithDrawBy,
} from "../../../../actions/requestTime";
import {
  getEmployeeChangeTime,
  getLeaveRequest,
  getProbationEmployees,
} from "../../../../actions/employee";
import {
  getAllLeaveRequestWithDraw,
  getAllLeaveWithDrawBy,
} from "../../../../actions/leave";
import { getUserFullName, getUserPosition } from "../../../../utils/userData";
import RequestTimeCard from "./RequestTime";
import { getPayrollSetting } from "../../../../actions/paytypes";
import RequestOverTimeCard from "./RequestOverTime";
import RequestCompensateCard from "./RequestCompensate";
import RequestLeaveCard from "./RequestLeave";
import RequestTimeShiftCard from "./RequestTimeShift";
import DailyPanel from "../timeManagement/DailyPanel";
import DailyPanelCard from "./DailyPanel";

const ScrollContainer = styled("div")({
  display: "flex",
  width: "100%",
  mb: 2,
  overflow: "hidden",
  overflowX: "auto",
  scrollbarWidth: "none !important" /* For Firefox */,

  /* Hide scrollbar for Chrome, Safari, and Edge */
  "&::-webkit-scrollbar": {
    display: "none !important",
  },
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "40px",
    "& input": {
      paddingLeft: "16px", // Adjust the left padding
    },
    "& textarea": {
      paddingLeft: "16px", // Adjust the left padding for multiline input
    },
  },
});

const ChatBot = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [search, setSearch] = React.useState({
    start: null,
    end: null,
  });
  const [dailySearch, setDailySearch] = useState(new Date());
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const {
    isFetching: isFetchingEmployeeChangeShift,
    result: employeeChangeShiftList,
  } = useSelector((state) => state.employeeChangeShift);
  const { isFetching: isFetchingRequestTime, result: requestTimeList } =
    useSelector((state) => state.requestTime);
  const {
    isFetching: isFetchingRequestTimeWithDraw,
    result: requestTimeWithDrawList,
  } = useSelector((state) => state.requestTimeWithDraw);
  const { isFetching: isFetchingLeaveEmployees, result: leaveEmployeesList } =
    useSelector((state) => state.leaveEmployees);
  const {
    isFetching: isFetchingLeaveEmployeeWithdraws,
    result: leaveEmployeeWithdrawsList,
  } = useSelector((state) => state.leaveEmployeesWithDraw);
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );
  const {
    isFetching: isFetchingEmployeesProbation,
    result: employeesProbation,
  } = useSelector((state) => state.employeesProbation);
  const { result: employeesEstimatePerformance } = useSelector(
    (state) => state.employeesEstimatePerformance
  );
  const { result: employeesExitInterview } = useSelector(
    (state) => state.employeesExitInterview
  );
  const { result: jobRecruitManager } = useSelector(
    (state) => state.jobRecruitManager
  );

  const [isSubmit, setIsSubmit] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [dateInputs, setDateInputs] = useState({});
  const [dateInputValues, setDateInputValues] = useState({});
  const [isDateInputActive, setIsDateInputActive] = useState(false);
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  function useScrollToBottom() {
    const bottomRef = useRef(null);

    useEffect(() => {
      const observer = new MutationObserver(() => {
        if (bottomRef.current) {
          bottomRef.current.scrollIntoView({ behavior: "smooth" });
        }
      });

      const content = document.querySelector(".content");
      if (content) {
        observer.observe(content, { childList: true, subtree: true });
      }

      return () => observer.disconnect();
    }, []);

    return bottomRef;
  }

  useEffect(() => {
    if (userProfile) {
      if (i18n.resolvedLanguage === "th") {
        setMessages([
          {
            text: `สวัสดีครับคุณ ${userProfile.firstname_TH} มีอะไรให้ Jarvis ช่วยไหมครับ?`,
            sender: "bot",
          },
        ]);
      } else {
        setMessages([
          {
            text: `Hello, ${userProfile.title_EN} ${userProfile.firstname_EN}. How can I assist you today?`,
            sender: "bot",
          },
        ]);
      }
    } else {
      if (i18n.resolvedLanguage === "th") {
        setMessages([
          { text: "สวัสดีครับ มีอะไรให้ Jarvis ช่วยไหมครับ?", sender: "bot" },
        ]);
      } else {
        setMessages([
          { text: "Hello, How can I assist you today?", sender: "bot" },
        ]);
      }
    }
  }, [userProfile]);

  useEffect(() => {
    if (userProfile) {
      dispatch(getAllRequestTimeBy("manager", search));
      dispatch(getAllRequestTimeWithDrawBy("manager", search));
      dispatch(getLeaveRequest("manager", search));
      dispatch(getAllLeaveWithDrawBy("manager", search));
      dispatch(getProbationEmployees());
      dispatch(getEmployeeChangeTime("manager"));
      dispatch(getPayrollSetting());
    }
  }, [userProfile]);

  const mapRequestTime = (mode) => {
    if (userProfile && requestTimeList && requestTimeWithDrawList) {
      const tempPending = [];

      requestTimeList.map((r) => {
        if (
          r.idRequestType ===
          (mode === "time" ? 1 : mode === "compensate" ? 3 : 2)
        ) {
          if (r.isDoubleApproval === 1) {
            if (
              r.managerLV1ApproveBy === userProfile.idEmployees ||
              (r.idManagerGroupLV1 &&
                userProfile.idManagerGroupList.includes(r.idManagerGroupLV1))
            ) {
              if (r.isManagerLV1Approve !== 1 && r.isManagerLV1Approve !== 0) {
                tempPending.push(r);
              }
            } else if (
              r.managerLV2ApproveBy === userProfile.idEmployees ||
              (r.idManagerGroupLV2 &&
                userProfile.idManagerGroupList.includes(r.idManagerGroupLV2))
            ) {
              if (r.isManagerLV2Approve !== 1 && r.isManagerLV2Approve !== 0) {
                tempPending.push(r);
              }
            }
          } else {
            if (r.approvalLevel === 1) {
              if (r.isManagerLV1Approve !== 1 && r.isManagerLV1Approve !== 0) {
                tempPending.push(r);
              }
            } else if (r.approvalLevel === 2) {
              if (r.isManagerLV2Approve !== 1 && r.isManagerLV2Approve !== 0) {
                tempPending.push(r);
              }
            }
          }
        }
      });

      requestTimeWithDrawList.map((r) => {
        if (
          r.idRequestType ===
          (mode === "time" ? 1 : mode === "compensate" ? 3 : 2)
        ) {
          if (r.isApprove === null) {
            tempPending.push(r);
          }
        }
      });
      let pendingList = tempPending.sort(function (a, b) {
        return new Date(b.createDate) - new Date(a.createDate);
      });

      return pendingList;
    }
  };

  const handleSend = async () => {
    if (currentMessage.trim()) {
      // Add user message
      handleSetMessages("User", currentMessage, "user");
      setCurrentMessage("");
      setIsLoading(true);

      try {
        const response = await getBotResponse({
          message: currentMessage,
        });

        if (response && response.tag) {
          const tag = response.tag;
          setType(tag);
          if (tag === "workingTimeRequest") {
            const pendingList = mapRequestTime("time");
            if (pendingList && pendingList.length > 0) {
              handleSetDataMessages("workingTimeRequest", pendingList, "bot");
            } else {
              handleSetMessages(null, `${t("NoData")}`, "bot");
            }
          } else if (tag === "overTimeRequest") {
            const pendingList = mapRequestTime("ot");
            if (pendingList && pendingList.length > 0) {
              handleSetDataMessages("overTimeRequest", pendingList, "bot");
            } else {
              handleSetMessages(null, `${t("NoData")}`, "bot");
            }
          } else if (tag === "compensateRequest") {
            const pendingList = mapRequestTime("compensate");
            if (pendingList && pendingList.length > 0) {
              handleSetDataMessages("compensateRequest", pendingList, "bot");
            } else {
              handleSetMessages(null, `${t("NoData")}`, "bot");
            }
          } else if (tag === "leaveRequest") {
            const tempPending = [];

            leaveEmployeesList.map((r) => {
              if (r.isApprove === null) {
                tempPending.push(r);
              }
            });

            leaveEmployeeWithdrawsList.map((r) => {
              if (r.isApprove === null) {
                tempPending.push(r);
              }
            });

            let pendingList = tempPending.sort(function (a, b) {
              return new Date(b.createDate) - new Date(a.createDate);
            });

            if (pendingList && pendingList.length > 0) {
              handleSetDataMessages("leaveRequest", pendingList, "bot");
            } else {
              handleSetMessages(null, `${t("NoData")}`, "bot");
            }
          } else if (tag === "shiftRequest") {
            const tempPending = [];

            employeeChangeShiftList.map((r) => {
              if (r.isApprove === null) {
                tempPending.push(r);
              }
            });
            let pendingList = tempPending.sort(function (a, b) {
              return new Date(b.createDate) - new Date(a.createDate);
            });

            if (pendingList && pendingList.length > 0) {
              handleSetDataMessages("shiftRequest", pendingList, "bot");
            } else {
              handleSetMessages(null, `${t("NoData")}`, "bot");
            }
          } else if (tag === "timeManagement") {
            handleSetMessages(
              "timeManagement",
              "กรุณาเลือกประเภทของการจัดการเวลา \n",
              "bot"
            );
          } else {
            handleSetMessages("AI", response.message, "bot");
          }
        }
      } catch (error) {
        handleSetMessages("Error", `${t("ContactAdmin")}`, "bot");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDateInputSubmit = async () => {
    let text = "";
    let typeText = "";
    let fileBlob = null;
    let fileName = null;
    setDateInputs({});
    setIsDateInputActive(false);
    setIsLoading(true);

    if (type && type === "timeManagement") {
      handleSetMessages(
        null,
        `ข้อมูลจัดการเวลาประจำวันที่ ${dayjs(dailySearch).format(
          i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
        )}`,
        "user"
      );
      handleSetDataMessages("timeManagement", true, "bot");
    }

    setIsLoading(false);
    setDateInputValues({});
  };

  const handleSetMessages = async (
    type,
    text,
    sender,
    file = null,
    fileName = null
  ) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        type: type,
        text: text,
        sender: sender,
        file: file,
        fileName: fileName,
      },
    ]);
  };

  const handleSetDataMessages = async (type, data, sender) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        type: type,
        data: data,
        sender: sender,
      },
    ]);
  };
  const bottomRef = useScrollToBottom();
  return (
    <CardStyle
      style={{
        width: "100%",
        // maxWidth: "80%",
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "16px",
      }}
    >
      <Box className="content">
        {messages.map((message, index) => (
          <Box key={index} className={`${message.sender}-message`}>
            {message.sender === "bot" && (
              <img
                src={Logo}
                alt="Logo"
                className="bot-logo"
                width="40"
                height="40"
              />
            )}

            {message.data ? (
              <>
                {message.type.includes("Request") && (
                  <div style={{ flexDirection: "column", width: "50%" }}>
                    {message.type === "workingTimeRequest" && (
                      <>
                        {message.data.map((row) => (
                          <RequestTimeCard row={row} />
                        ))}
                      </>
                    )}
                    {message.type === "overTimeRequest" && (
                      <>
                        {message.data.map((row) => (
                          <RequestOverTimeCard row={row} />
                        ))}
                      </>
                    )}
                    {message.type === "compensateRequest" && (
                      <>
                        {message.data.map((row) => (
                          <RequestCompensateCard row={row} />
                        ))}
                      </>
                    )}
                    {message.type === "leaveRequest" && (
                      <>
                        {message.data.map((row) => (
                          <RequestLeaveCard row={row} />
                        ))}
                      </>
                    )}
                    {message.type === "shiftRequest" && (
                      <>
                        {message.data.map((row) => (
                          <RequestTimeShiftCard row={row} />
                        ))}
                      </>
                    )}
                  </div>
                )}

                {message.type === "timeManagement" && (
                  <div style={{ flexDirection: "column", width: "100%" }}>
                    <DailyPanelCard search={dailySearch} />
                  </div>
                )}
              </>
            ) : (
              <Box className={`${message.sender}-bubble`}>
                {message.text}
                {message.type === "timeManagement" && (
                  <ButtonBlue
                    variant="contained"
                    fullWidth
                    style={{ marginTop: "16px" }}
                    onClick={() => setIsDateInputActive(true)}
                  >
                    {`${t("DailyInformation")}`}
                  </ButtonBlue>
                )}
              </Box>
            )}
          </Box>
        ))}

        {isLoading && (
          <Box className="bot-message">
            <img
              src={Logo}
              alt="Logo"
              className="bot-logo"
              width="40"
              height="40"
            />
            <Box className="bot-bubble">
              <span className="thinking-dots">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </Box>
          </Box>
        )}
        {isDateInputActive && (
          <Box className="dynamic-inputs">
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={12}>
                <DatePickerCustom
                  minDate={new Date(new Date().getFullYear() - 4, 0, 1)}
                  maxDate={new Date()}
                  inputFormat="DD/MM/YYYY"
                  value={dailySearch}
                  name="start"
                  label={`${t("SearchDate")}`}
                  views={["year", "month", "day"]}
                  onChange={(newValue) => {
                    setDailySearch(newValue);
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme style={{ width: "100%" }} {...params} />
                  )}
                />
              </Grid>
            </Grid>
            <ButtonBlue variant={"contained"} onClick={handleDateInputSubmit}>
              {`${t("Search")}`}
            </ButtonBlue>
          </Box>
        )}
        <div ref={bottomRef} />
      </Box>
      <StyledTextField
        multiline
        minRows={1}
        maxRows={4}
        variant="outlined"
        placeholder="Type your message here..."
        fullWidth
        value={currentMessage}
        onChange={(e) => setCurrentMessage(e.target.value)}
        disabled={isLoading}
        onKeyPress={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSend();
          }
        }}
        InputProps={{
          style: { resize: "none" },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSend}>
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </CardStyle>
  );
};

export default ChatBot;
