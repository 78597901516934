import {
  JOBPOSTINGAPPLY_FAILED,
  JOBPOSTINGAPPLY_SUCCESS,
  JOBPOSTINGAPPLY_FETCHING,
} from "./types";

import JobPostingApplyService from "../services/jobPostingApply.service";

export const getAllJobPostingApply = () => async (dispatch) => {
  try {
    const res = await JobPostingApplyService.getAllJobPostingApply();
    if (res) {
      dispatch({
        type: JOBPOSTINGAPPLY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: JOBPOSTINGAPPLY_FAILED,
    });
    console.log(err);
  }
};
