import FamilyService from "../services/family.service";
import { FAMILY_FETCHING, FAMILY_FAILED, FAMILY_SUCCESS } from "./types";

export const addFamily = (formData) => async (dispatch) => {
  try {
    const res = await FamilyService.addFamily(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
};

export const deleteFamily = (idFamily) => async (dispatch) => {
  try {
    const res = await FamilyService.deleteFamily(idFamily);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
};

export const getFamilyByIdEmployee = (idEmployee) => async (dispatch) => {
  try {
    dispatch({
      type: FAMILY_FETCHING,
    });
    const res = await FamilyService.getFamilyByIdEmployee(idEmployee);
    if (res) {
      dispatch({
        type: FAMILY_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: FAMILY_FAILED,
    });
    return error;
  }
};
