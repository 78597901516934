import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Avatar, Typography, Divider, Grid, Box, Paper, Stack } from "@mui/material";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { getSuccessorRole } from "../../../../../actions/successor";
import { useTranslation } from "react-i18next";
import utils from "../../../../../utils";
import dayjs from "dayjs";
import { getUserFullName, getUserPosition } from "../../../../../utils/userData";

const StyledSuccessor = styled("div")({
  "& .number-successor": {
    color: "black",
    fontSize: "18px",
    fontWeight: 600,
    justifyContent: "center",
    alignSelf: "center",
    marginTop: "10px",
    textAlign: "center",
  },
  "& .header-profile": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 10,
    marginTop: "10px",

    "& .empHeadingText": {
      color: "black",
      fontSize: "18px",
      fontWeight: 600,
      alignSelf: "center",
      marginTop: "10px",
    },
    "& .empPositionText": {
      color: "gray ",
      fontSize: "1rem",
      fontWeight: 300,
      textAlign: "left",
    },
    "& .empImage": {
      minHeight: "80px",
      minWidth: "80px",
      alignSelf: "center",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      outline: "3px solid white",
      marginBottom: "5px",
    },
  },
  "& .content-profile": {
    direction: "column",
    alignItems: "flex-start",
    padding: "15px 15px 15px calc(15px + 0.75em)",

    "& .textTopic": {
      color: "gray ",
      fontSize: "1rem",
      fontWeight: 300,
      textAlign: "left",
    },
    "& .textContent": {
      color: "#46cbe2",
      fontSize: "1rem",
      fontWeight: 600,
      textAlign: "left",
    },

    "& .styleButtonReady": {
      textAlign: "center",
      color: "white",
      borderRadius: 5,
      marginTop: "10px",
      marginRight: "14px",
      padding: "8px",
      cursor: "pointer",
    },
    "& .styleButtonNotReady": {
      textAlign: "center",
      color: "white",
      backgroundColor: "#e24668",
      borderRadius: 5,
      marginTop: "10px",
      marginRight: "14px",
      padding: "8px",
      cursor: "pointer",
    },
  },
});


const SuccessorDetail = (props) => {
  const { data } = props;
  const { t, i18n } = useTranslation();

  return (
    <Paper
      style={{
        minHeight: 500,
        padding: 20,
        backgroundColor: "#C4C4C4",
        borderRadius: "0 0 20px 20px",
        position: "relative",
      }}
    >
      <Stack spacing={1}>
        {data && data.map((item, index) => (
          <Paper key={index} style={{ minHeight: 369, padding: 10 }}>
            <StyledSuccessor>
              <div className="number-successor">Successor {index + 1} </div>
              <Divider
                style={{
                  minHeight: 2,
                  width: 120,
                  backgroundColor: "#46cbe2",
                  margin: "0 auto",
                }}
              />
              <div className="header-profile">
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Avatar variant="circle" className={"empImage"}></Avatar>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography className="empHeadingText">
                      {item && getUserFullName(item)}
                    </Typography>
                    <Typography className="empPositionText">{item && getUserPosition(item)}</Typography>
                  </Grid>
                </Grid>
              </div>
              <Divider />
              <Grid container spacing={2} className="content-profile">
                {/* First Row */}
                <Grid item xs={12}>
                  <Typography variant="body1">
                    <span className="textTopic">{t("ESY")} : </span>
                    <span className="textContent">
                      {item && item.hiringDate ?
                        `${utils.getAgeMonthYear(t,
                          dayjs(item.hiringDate).format("DD/MM/YYYY"),
                          i18n.resolvedLanguage
                        )}` : "-"}
                    </span>
                  </Typography>

                  <Typography variant="body1" sx={{ mt: 1 }}>
                    <span className="textTopic">{t("TIG")} : </span>
                    <span className="textContent">
                      {item && item.employeePersonnelLevelStart ?
                        `${utils.getAgeMonthYear(t,
                          dayjs(item.employeePersonnelLevelStart).format("DD/MM/YYYY"),
                          i18n.resolvedLanguage
                        )}` : "-"}
                    </span>
                  </Typography>

                  <Typography variant="body1" sx={{ mt: 1 }}>
                    <span className="textTopic">{t("TIP")} : </span>
                    <span className="textContent">
                      {item && item.start ?
                        `${utils.getAgeMonthYear(t,
                          dayjs(item.start).format("DD/MM/YYYY"),
                          i18n.resolvedLanguage
                        )}` : "-"}
                    </span>
                  </Typography>

                  <Typography variant="body1" sx={{ mt: 1 }}>
                    <span className="textTopic">{t("Age")} : </span>
                    <span className="textContent">
                      {item && item.hiringDate ?
                        `${utils.getAgeMonthYear(t,
                          dayjs(item.hiringDate).format("DD/MM/YYYY"),
                          i18n.resolvedLanguage
                        )}` : "-"}
                    </span>
                  </Typography>
                </Grid>

                {/* <Grid item xs={6}>
                  <Typography variant="body1">
                    <span className="textTopic">Serv.Y : </span>
                    <span className="textContent">{item && item.Serv_Y ? item.Serv_Y : "0"} / {item && item.Serv_M ? item.Serv_M : "0"}</span>
                  </Typography>
                </Grid> */}
                <Grid item xs={12}>
                  <Box className="styleButtonReady"
                    p={1}
                    sx={{
                      backgroundColor: item && item.ready === "พร้อมทันที" ? "#4caf50" : item && item.ready === "พร้อมภายใน 1 ปี" ? "#ffc400" : "#ff1744"
                    }}>
                    {item && item.ready}
                  </Box>
                </Grid>
              </Grid>
            </StyledSuccessor>
          </Paper>
        ))}
      </Stack>

    </Paper>
  );
};


export default SuccessorDetail;
