import { httpClient } from "./httpClient";

const addFamily = (formData) => {
  return httpClient.post("family", formData);
};

const deleteFamily = (idFamily) => {
  return httpClient.delete(`family/${idFamily}`);
};

const getFamilyByIdEmployee = (idEmployee) => {
  return httpClient.get(`family/${idEmployee}`);
};

export default {
  addFamily,
  deleteFamily,
  getFamilyByIdEmployee,
};
