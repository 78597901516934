import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";

import { Container, Typography, CardContent } from "@mui/material";

import StyledCard from "../../../shared/general/Card";

import WelfareExpensePage from "./welfare";
import FlexCoinExpensePage from "./flex-coin";

import { useParams } from "react-router-dom/cjs/react-router-dom";
import WelfareBillingEditPage from "../billing/billing-edit";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  maxWidth: "1280px !important",
  margin: "auto",
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 24,
  },
  "& .head": {
    paddingTop: 8,
    marginBottom: 24,
    "& .MuiTypography-subtitle1": {
      fontWeight: 500,
    },
  },
});

const StyledCardContent = styled(CardContent)({
  padding: 24,
  "& .MuiFormControl-root": {
    marginBottom: 8,
  },
  "& .wrap-head": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
  },
  "& .wrap-row-sum": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

const ExpenseVerifyPage = () => {
  const { t, i18n } = useTranslation();
  const { action, type, idExpense } = useParams();

  return (
    <StyledRoot className="page">
      <Container maxWidth="xl">
        <div className="head">
          {action === "verify" && (
            <Typography variant="h4" gutterBottom>
              {t("VerifyExpense")}
            </Typography>
          )}
          {action === "edit" && (
            <Typography variant="h4" gutterBottom>
              {t("EditDetails")}
            </Typography>
          )}
        </div>
        <StyledCard>
          <StyledCardContent>
            {action === "verify" && type === "Welfare" && (
              <WelfareExpensePage idExpense={idExpense} />
            )}
            {action === "verify" && type === "FlexCoin" && (
              <FlexCoinExpensePage idExpense={idExpense} />
            )}
            {action === "edit" && type === "Welfare" && (
              <WelfareBillingEditPage idWelfareBilling={idExpense} />
            )}
          </StyledCardContent>
        </StyledCard>
      </Container>
    </StyledRoot>
  );
};

export default ExpenseVerifyPage;
