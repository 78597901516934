import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Divider, IconButton, Box } from "@mui/material";

import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AlertResponse from "../../../shared/general/AlertResponse";

import { Controller, useForm, useFieldArray } from "react-hook-form";
import DialogExitInterview from "./DialogExitInterview";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyProfile } from "../../../../../actions/company";
import {
  getExitInterview,
  getExitInterviewDiscard,
  getReasonExitInterview,
} from "../../../../../actions/exitInterview";
import { updateReasonExitInterview } from "../../../../../actions/exitInterview";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
}));

const StyledHeadingPage = styled("div")({
  marginBottom: 16,
  "& .wrap-search": {
    marginTop: 16,
    "& .fill": {
      display: "flex",
    },
  },
});

const BoxStyled = styled("div")({
  "& .header": {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
    "& .text": {
      fontSize: 16,
    },
    "& .icon": {
      color: "#637381",
      fontSize: 16,
      marginRight: 8,
    },
  },
  "& .btn-save": {
    justifyContent: "flex-end",
  },
  "& .flex-box": {
    justifyContent: "space-between",
    marginTop: 16,
    display: "flex",
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 18,
});

const label = { inputProps: { "aria-label": "Size switch demo" } };

function ExitInterview({ selectedCompany, isReadOnly }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: ExitInterview } = useSelector(
    (state) => state.getExitInterview
  );
  const { result: ReasonExitInterview } = useSelector(
    (state) => state.exitInterviewReason
  );
  const { result: exitInterviewDiscard } = useSelector(
    (state) => state.exitInterviewDiscard
  );

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [openEducation, setOpenEducation] = useState(false);
  const [idEducation, setIdEducation] = useState(null);
  const [typeDialog, setTypeDialog] = useState("Add");
  const [amountQuestion, setAmountQuestion] = useState(0);
  const [idReasonList, setIdReasonList] = useState([]);

  useEffect(() => {
    dispatch(getCompanyProfile());
    dispatch(getExitInterview());
    dispatch(getExitInterviewDiscard());
    dispatch(getReasonExitInterview({ idCompany: selectedCompany.idCompany }));
  }, [selectedCompany]);

  useEffect(() => {
    if (ExitInterview) {
      setAmountQuestion(ExitInterview.length);
    }
  }, [ExitInterview]);

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleSubmitExitInterview = async (result) => {
    handleClose();
    if (result) {
      handleOpenAlert();
      if (result.status === 200) {
        dispatch(getExitInterview());
        handleChangeAlertType("success");
      } else {
        handleChangeAlertType("error");
      }
    } else {
      handleChangeAlertType("error");
    }
  };

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { errors, dirtyFields },
    reset,
    setError,
  } = useForm({
    defaultValues: {
      reasonList: [],
    },
  });

  const reasonUseFieldArray = useFieldArray({
    rules: { maxLength: 15 },
    control: control,
    name: "reasonList",
  });

  const initialValueReasonData = {
    name: "",
  };

  useEffect(() => {
    if (ReasonExitInterview !== null) {
      reset({
        reasonList: ReasonExitInterview,
      });
    }
  }, [ReasonExitInterview]);

  const onSubmit = async (formData) => {
    const cleansingForm = {
      reasonList: formData.reasonList,
      deleteReasonList: idReasonList,
      idCompany: selectedCompany.idCompany,
    };

    const response = await dispatch(updateReasonExitInterview(cleansingForm));
    handleSubmitExitInterview(response);
  };

  const handleOpenEducation = (type, idEdu) => {
    if (type === "Add") {
      setTypeDialog("Add");
    } else {
      setTypeDialog("Edit");
    }
    if (idEdu) {
      setIdEducation(idEdu);
    }
    setOpenEducation(true);
  };

  const handleClose = () => {
    setOpenEducation(false);
    setIdEducation(null);
  };

  return (
    <div className={classes.root}>
      <StyledHeadingPage sx={{ marginTop: "20px" }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">
              {t("ExitInterviewQuestionForm")}
            </Typography>
          </Grid>
        </Grid>
      </StyledHeadingPage>
      <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
        <BoxStyled>
          <div className="flex-box">
            <StyledContentLabel variant="body1">
              {`${t("AllQuestions")} ${amountQuestion}/10`}
              &nbsp;&nbsp;
              <i
                className="fa-sharp fa-solid fa-check fa-beat fa-xl"
                style={{ color: "#fde64b" }}
              ></i>
            </StyledContentLabel>
            <div className="btn-save">
              <ButtonBlue
                variant="contained"
                onClick={() => handleOpenEducation("Add")}
              >
                {t("Question")}
                &nbsp;
                <AddCircleRoundedIcon />
                <RemoveCircleIcon />
              </ButtonBlue>
            </div>
          </div>
          {ExitInterview &&
            ExitInterview.map((ExitInterview, index) => (
              <Grid key={ExitInterview.id} container className="GridContact">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  marginTop={2}
                  className="GridTopicInput"
                >
                  <Grid item xs={12} sm={3}>
                    <i className="fa-regular fa-square-question fa-bounce fa-xl"></i>
                    &nbsp;&nbsp;{`${t("Question")} ${index + 1}`}
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography>{ExitInterview.question}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider
                    sx={{
                      marginTop: "16px",
                      borderColor: "#9e9e9e66",
                    }}
                  />
                </Grid>
              </Grid>
            ))}
        </BoxStyled>
      </CardStyle>
      <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
        <BoxStyled>
          <div className="flex-box">
            <StyledContentLabel variant="body1">
              {`${t("CanceledQuestions")}`}
              &nbsp;&nbsp;
              <i
                className="fa-sharp fa-solid fa-xmark fa-beat fa-xl"
                style={{ color: "#ff0000" }}
              ></i>
            </StyledContentLabel>
          </div>
          {exitInterviewDiscard &&
            exitInterviewDiscard.map((exitInterviewDiscard, index) => (
              <Grid
                key={exitInterviewDiscard.id}
                container
                className="GridContact"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  marginTop={2}
                  className="GridTopicInput"
                >
                  <Grid item xs={12} sm={3}>
                    <i className="fa-regular fa-square-question fa-bounce fa-xl"></i>
                    &nbsp;&nbsp;{`${t("Question")} ${index + 1}`}
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography>{exitInterviewDiscard.question}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider
                    sx={{
                      marginTop: "16px",
                      borderColor: "#9e9e9e66",
                    }}
                  />
                </Grid>
              </Grid>
            ))}
        </BoxStyled>
      </CardStyle>
      <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
        <BoxStyled>
          <div className="flex-box">
            <StyledContentLabel variant="body1">
              {`${t("ReasonsForResignation")}`}
            </StyledContentLabel>
            <div className="btn-save">
              <ButtonBlue
                variant="outlined"
                startIcon={<i className="fa-regular fa-plus"></i>}
                onClick={() => {
                  reasonUseFieldArray.append(initialValueReasonData);
                }}
                disabled={reasonUseFieldArray.fields.length >= 15}
              >
                {t("AddReason")}
              </ButtonBlue>
              {reasonUseFieldArray.fields.length >= 15 && (
                <Typography marginTop={1} color={"text.secondary"}>
                  {t("MaximumAdd")} 15 {t("Reasons")}
                </Typography>
              )}
            </div>
          </div>
          <Typography color="text.secondary">
            {t("UpTo")} 100 {t("Characters")}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            {reasonUseFieldArray.fields.map((reason, index) => (
              <Grid
                key={reason.id}
                container
                marginTop={2}
                className="GridContact"
              >
                <Grid item xs={12} sm={9}>
                  <Controller
                    control={control}
                    name={`reasonList.${index}.name`}
                    rules={{
                      required: {
                        value: true,
                        message: `
                        ${t("PleaseFillInTheQuestion")}`,
                      },
                      maxLength: {
                        value: 100,
                        message: `${t("UpTo")} 100 ${t("Characters")}`,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextFieldTheme
                        {...field}
                        autoComplete="degree"
                        error={Boolean(fieldState.error)}
                        helperText={
                          fieldState.error && fieldState.error.message
                        }
                      ></TextFieldTheme>
                    )}
                  />
                </Grid>
                {reasonUseFieldArray.fields.length > 1 && (
                  <Grid item xs={3}>
                    <Box justifyContent="flex-end">
                      <IconButton
                        color="error"
                        onClick={() => {
                          reasonUseFieldArray.remove(index);
                          setIdReasonList([
                            ...idReasonList,
                            reason.idReasonExitInterview,
                          ]);
                        }}
                      >
                        <i className="fa-regular fa-trash-can"></i>
                      </IconButton>
                    </Box>
                  </Grid>
                )}
              </Grid>
            ))}
            {reasonUseFieldArray.fields.length <= 0 && (
              <Grid item xs={12}>
                <Typography textAlign="center" color="text.secondary">
                  {t("NoReason")}
                </Typography>
              </Grid>
            )}
            <Box
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ButtonBlue variant="contained" type="submit" disabled={isReadOnly}>
                {t("Save")}
              </ButtonBlue>
            </Box>
          </form>
        </BoxStyled>
      </CardStyle>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      {openEducation && (
        <DialogExitInterview
          open={openEducation}
          handleClose={handleClose}
          idEdu={idEducation}
          type={typeDialog}
          handleSubmitExitInterview={handleSubmitExitInterview}
          isReadOnly={isReadOnly}
        />
      )}
    </div>
  );
}

export default ExitInterview;
