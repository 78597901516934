import EventService from "../services/event.service";

export const getAllTimeline = () => async (dispatch) => {
  try {
    const res = await EventService.getAllTimeline();
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getAllEvents = () => async (dispatch) => {
  try {
    const res = await EventService.getAllEvents();
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getEventsByMonth = (month) => async (dispatch) => {
  try {
    const res = await EventService.getEventsByMonth(month);
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getEventsLeaveByMonth = () => async (dispatch) => {
  try {
    const res = await EventService.getEventsLeaveByMonth();
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};
