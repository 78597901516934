import { ORGPLANS_FETCHING, ORGPLANS_FAILED, ORGPLANS_SUCCESS } from "./types";

import OrgPlanService from "../services/OrganizationPlans";

export const getAllOrgPlan = () => async (dispatch) => {
  try {
    const res = await OrgPlanService.getAllOrgPlan();
    if (res) {
      dispatch({
        type: ORGPLANS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ORGPLANS_FAILED,
    });
    console.log(err);
  }
};

export const getOrgPlanById = (id) => async () => {
  try {
    const res = await OrgPlanService.getOrgPlanById(id);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const postOrgPlan = (formData) => async () => {
  try {
    const res = await OrgPlanService.postOrgPlan(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putOrgPlan = (id, formData) => async () => {
  try {
    const res = await OrgPlanService.putOrgPlan(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putEvaluationOrgPlan = (id, formData) => async () => {
  try {
    const res = await OrgPlanService.putEvaluationOrgPlan(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const deleteOrgPlan = (id, formData) => async () => {
  try {
    const res = await OrgPlanService.deleteOrgPlan(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};
