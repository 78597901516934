import React, { useEffect } from "react";
import { Grid, Divider, Switch, Chip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { styled } from "@mui/material/styles";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getProbationQuestion, updateProbationQuestion } from "../../../../../actions/probation";
import { useTranslation } from "react-i18next";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  gap: 8,
});

const DialogEdit = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { open, handleClose, selectedCompany, isReadOnly } = props;
  const { result: probationQuestion } = useSelector(
    (state) => state.probationQuestion
  );

  const validationSchema = yup.object().shape({
    questionList: yup.array().of(
      yup.object().shape({
        question: yup
        .string()
        .max(1000, `${t("NotMoreThan")} 1000 ${t("Characters")}`)
        .required(`${t("ThisFieldIsRequired")}`),
      })
    ),
    isActive: yup.boolean(),
  });

  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      questionList: [],
    },
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (formData) => {
    const res = await dispatch(updateProbationQuestion(formData));
    if (res && res.status === 200) {
      dispatch(openNotificationAlert({ message: t("Successfully"), type: "success" }));
    } else {
      dispatch(openNotificationAlert({ message: t("AnErrorOccurred"), type: "error" }));
    }
    dispatch(getProbationQuestion({ idCompany: selectedCompany.idCompany }));
    handleClose();
  };

  const questionUseFieldArray = useFieldArray({
    control: control,
    name: "questionList",
  });

  const maxActive = watch("questionList").filter((item) => item.isActive).length >= 10;

  useEffect(() => {
    const activeQuestionSorted = [...probationQuestion].sort((a, b) => b.isActive - a.isActive)
    questionUseFieldArray.replace(activeQuestionSorted)
  }, [])

  return (
    <DrawerCustom
      title={t("EditProbationReviewQuestion")}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          {questionUseFieldArray.fields.map((question, index) => (
            <Grid key={question.id} item xs={12}>
              <div style={{ display:"flex", alignItems:"center", gap: "8px", marginBottom: "8px" }}>
                {Boolean(question.isActive) === true && (
                  <Chip
                    sx={{ maxWidth: "fit-content" }}
                    icon={<CheckCircleIcon />}
                    color={"success"}
                    label={t("ActiveQuestion")}
                  />
                )}
                <Controller
                  control={control}
                  name={`questionList.${index}.isActive`}
                  render={({ field }) => (
                    <Switch
                      {...field}
                      checked={Boolean(field.value)}
                      disabled={maxActive && !field.value}
                    />
                  )}
                />
              </div>
              <Controller
                control={control}
                name={`questionList.${index}.question`}
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    multiline
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error && fieldState.error.message}
                  />
                )}
              />
              <Divider sx={{
                  marginTop: "16px",
                  borderColor: "#9e9e9e66",
                }}
              />
            </Grid>
          ))}
        </Grid>
        <StyledFooter>
          <ButtonBlue onClick={handleClose}>{t("Cancel")}</ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit(onSubmit)} disabled={isReadOnly}>
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
