import { httpClient } from "./httpClient";

const getAllIdvPlan = () => {
  return httpClient.get("idvPlan");
};

const getIdvPlanById = (id) => {
  return httpClient.get(`idvPlan/idvPlanById/${id}`);
};

const postIdvPlan = (data) => {
  return httpClient.post(`idvPlan/postIdvPlan`, data);
};

const putIdvPlan = (id, data) => {
  return httpClient.post(`idvPlan/putIdvPlan/${id}`, data);
};

const putApprovalIdvPlan = (id, data) => {
  return httpClient.post(`idvPlan/putApprovalIdvPlan/${id}`, data);
};

const putEvaluateMngIdvPlan = (data) => {
  return httpClient.put(`idvPlan/putEvaluateMngIdvPlan`, data);
};

const putEvaluationIdvPlan = (id, data) => {
  return httpClient.post(`idvPlan/putEvaluationIdvPlan/${id}`, data);
};

const deleteIdvPlan = (id, data) => {
  return httpClient.post(`idvPlan/deleteIdvPlan/${id}`, data);
};

export default {
  getAllIdvPlan,
  getIdvPlanById,
  postIdvPlan,
  putIdvPlan,
  putApprovalIdvPlan,
  putEvaluateMngIdvPlan,
  putEvaluationIdvPlan,
  deleteIdvPlan,
};
