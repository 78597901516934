import React, { useState, useEffect } from 'react';
import formats from './ToolbarOptions.js'
import styled from 'styled-components';
import { FaAlignLeft, FaAlignCenter, FaAlignRight, FaAlignJustify, FaTimes } from 'react-icons/fa';
import { useTranslation } from "react-i18next";

const ToolbarContainer = styled.div`
  width: 100%;
  padding: 10px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`;

const Toolbar = styled.div`
  width: 0%;
  padding: 0px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 0px;
`;

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled.button`
  background-color: #444444;
  border: 1px solid #ccc;
  padding: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  color: #424242;
`;

const DropdownContent = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  background-color: #fff;
  min-width: 120px;
  border: 1px solid #ccc;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
`;

const DropdownItem = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: #454545; 
  &:hover {
    background-color: #f1f1f1;
  }
`;


const renderOptions = (formatData, handleAlignment, handleSelectedValueChange) => {
  const { t, i18n } = useTranslation();
    
  if (formatData.className === "ql-align") {
      const [isOpen, setIsOpen] = useState(false);
      const [selectedIcon, setSelectedIcon] = useState(<FaAlignLeft />);

      const handleSelect = (icon, value) => {
        setSelectedIcon(icon);
        handleAlignment(value);
        handleSelectedValueChange(value);
        setIsOpen(false);
      };
    
      return (
        <Toolbar>
        <Dropdown className="ql-align ql-align-select">
            <DropdownButton onClick={() => setIsOpen(!isOpen)}>
              {selectedIcon}
            </DropdownButton>
            <DropdownContent isOpen={isOpen}>
              <DropdownItem onClick={() => handleSelect(<FaTimes />, '')}>
                <FaTimes /> {`${t("Remove_Alignment")}`}
              </DropdownItem>
              <DropdownItem onClick={() => handleSelect(<FaAlignJustify />, 'justify')}>
                <FaAlignJustify /> {`${t("Justify")}`}
              </DropdownItem>
              <DropdownItem onClick={() => handleSelect(<FaAlignCenter />, 'center')}>
                <FaAlignCenter /> {`${t("Align_Center")}`}
              </DropdownItem>
              <DropdownItem onClick={() => handleSelect(<FaAlignRight />, 'right')}>
                <FaAlignRight /> {`${t("Align_Right")}`}
              </DropdownItem>
            </DropdownContent>
          </Dropdown>
       </Toolbar>
      )
 } else {
    if (formatData.className === "ql-size") {
      const [selectedSize, setSelectedSize] = useState('18px');

      useEffect(() => {
        handleSelectedValueChange(selectedSize);
      }, [selectedSize]);

      const handleSizeChange = (event) => {
        const value = event.target.value;
        setSelectedSize(value);
        handleSelectedValueChange(value); 
      };

      return (
        <select
          className={formatData.className}
          value={selectedSize}
          onChange={handleSizeChange}
        >
          {formatData.options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }

    return (
      <select className={formatData.className}>
        {formatData.options.map((option, index) => (
          <option key={index} value={option}>
              {option}
          </option>
        ))}
      </select>
      );
    }
};

const renderSingle = (formatData)=>{
    const {className,value} = formatData;
    return (
        <button className = {className} value = {value}></button>
    )
}

const CustomToolbar = ({ handleAlignment, handleSelectedValueChange }) => {
    return (
      <ToolbarContainer id="toolbar">
        {
            formats.map((classes, index) => (
                <span className="ql-formats" key={index}>
                    {
                        classes.map(formatData => 
                            formatData.options 
                            ? renderOptions(formatData, handleAlignment, handleSelectedValueChange) 
                            : renderSingle(formatData)
                        )
                    }
                </span>
            ))
        }
      </ToolbarContainer>
    )
}
export default CustomToolbar;