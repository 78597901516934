import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  IconButton,
  Grid,
  FormControl,
  Card,
  CardContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../shared/date/datePicker";
import DataTable from "./components/DataTable";
import dayjs from "dayjs";
import ButtonBlue from "../../shared/general/ButtonBlue";
import AdvanceSearch from "./components/AdvanceSearch";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmemoRequestFormById,
  patchEmemoStatus,
  getEmemoRequestStepInitial,
} from "../../../../actions/ememo";
import RequestDetailsModal from "./components/RequestDetailsModal";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { openNotificationAlert } from "../../../../actions/notificationAlert";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  padding: "20px",
});

const CardStyled = styled(Card)({
  marginTop: 16,
  marginBottom: 16,
  padding: 16,
  border: "none",
  borderRadius: 16,
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  "& .MuiCardContent-root": {
    padding: 24,
    borderRadius: 16,
  },
});

const StyledCardContent = styled(CardContent)({
  padding: 24,
  borderRadius: 16,
});

const EMemoRequestlistPage = () => {
  const { t } = useTranslation();
  const [searchData, setSearchData] = useState({
    documentNo: "",
    creator: "",
    requester: "",
    createdDateFrom: null,
    createdDateTo: null,
    subject: "",
    eMemoType: "",
    status: "",
    classificationLevels: "",
    priority: "",
  });

  const [selectedRequest, setSelectedRequest] = useState(null);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();
  const { eMemos } = useSelector((state) => state.eMemos);
  const [filteredEMemos, setFilteredEMemos] = useState([]);
  const [initialStepMemos, setInitialStepMemos] = useState([]);
  const userRole = "manager";
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (userProfile) {
      //dispatch(getEmemoRequestFormById(userProfile.idEmployees, userRole));
      dispatch(getEmemoRequestStepInitial(userProfile.idEmployees));
    }
  }, [dispatch, userProfile]);

  useEffect(() => {
    setFilteredEMemos(eMemos);
  }, [eMemos]);

  useEffect(() => {
    const filtered = eMemos.filter((memo) => {
      const createdDate = dayjs(memo.created_date);
      const createdDateFrom = searchData.createdDateFrom
        ? dayjs(searchData.createdDateFrom)
        : null;
      const createdDateTo = searchData.createdDateTo
        ? dayjs(searchData.createdDateTo)
        : null;

      return (
        (!searchData.documentNo ||
          memo.document_no.includes(searchData.documentNo)) &&
        (!searchData.creator || memo.creator.includes(searchData.creator)) &&
        (!searchData.requester ||
          memo.requester.includes(searchData.requester)) &&
        (!searchData.subject || memo.subject.includes(searchData.subject)) &&
        (!searchData.status || memo.status.includes(searchData.status)) &&
        (!searchData.company || memo.company.includes(searchData.company)) &&
        (!createdDateFrom ||
          createdDate.isAfter(createdDateFrom) ||
          createdDate.isSame(createdDateFrom, "day")) &&
        (!createdDateTo ||
          createdDate.isBefore(createdDateTo) ||
          createdDate.isSame(createdDateTo, "day")) &&
        (!searchData.classificationLevels ||
          memo.classificationLevels.includes(
            searchData.classificationLevels
          )) &&
        (!searchData.priority || memo.priority.includes(searchData.priority)) &&
        (!searchData.eMemoType || memo.eMemoType.includes(searchData.eMemoType))
      );
    });

    setFilteredEMemos(filtered);
  }, [searchData, eMemos]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchData, [name]: value });
  };

  const handleDateChange = (name, date) => {
    setSearchData({ ...searchData, [name]: date });
  };

  const handleSearchOff = () => {
    setSearchData({
      documentNo: "",
      creator: "",
      requester: "",
      createdDateFrom: null,
      createdDateTo: null,
      subject: "",
      eMemoType: "",
      status: "",
      classificationLevels: "",
      priority: "",
    });
  };

  const formatDateRange = () => {
    const now = dayjs();
    const startOfMonth = now.startOf("month").format("DD MMM YYYY");
    const endOfMonth = now.endOf("month").format("DD MMM YYYY");
    const from = searchData.createdDateFrom
      ? dayjs(searchData.createdDateFrom).format("DD MMM YYYY")
      : startOfMonth;
    const to = searchData.createdDateTo
      ? dayjs(searchData.createdDateTo).format("DD MMM YYYY")
      : endOfMonth;
    return `[${from} - ${to}]`;
  };

  const columns = [
    {
      name: "Created Date",
      selector: (row) => row.created_date,
      sortable: true,
    },
    { name: "Subject", selector: (row) => row.subject, sortable: true },
    { name: "Status", selector: (row) => row.status, sortable: true },
    { name: "Creator", selector: (row) => row.creator, sortable: true },
    { name: "Requester", selector: (row) => row.requester, sortable: true },
    {
      name: "Classification Level",
      selector: (row) => row.classificationLevels,
      sortable: true,
    },
    { name: "Priority", selector: (row) => row.priority, sortable: true },
    {
      name: "",
      cell: (row) => (
        <IconButton onClick={() => handleViewDetails(row)} color="primary">
          <ChecklistIcon />
        </IconButton>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleViewDetails = (request) => {
    setSelectedRequest(request);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedRequest(null);
  };

  const handleApprove = async (comment) => {
    if (selectedRequest) {
      let nextStep = "";
      let status = "Waiting Initial1 Approve";

      switch (selectedRequest.currentStep) {
        case "initial1":
          nextStep = selectedRequest.idInitial2 ? "initial2" : "approver";
          if (selectedRequest.idInitial2) {
            status = "Waiting Initial2 Approve";
          } else {
            status = "Waiting Approver Approve";
          }
          break;
        case "initial2":
          nextStep = selectedRequest.idInitial3 ? "initial3" : "approver";
          if (selectedRequest.idInitial2) {
            status = "Waiting Initial3 Approve";
          } else {
            status = "Waiting Approver Approve";
          }
          break;
        case "initial3":
          nextStep = "approver";
          status = "Waiting Approver Approve";
          break;
        case "approver":
          nextStep = "success";
          status = "Approved";
          break;
        default:
          nextStep = "";
      }

      const res = await dispatch(
        patchEmemoStatus(selectedRequest.id, status, comment, nextStep)
      );

      if (res) {
        if (res.status === 200) {
          dispatch(
            openNotificationAlert({
              type: "success",
              message: "Approved successfully",
            })
          );
        } else {
          dispatch(
            openNotificationAlert({
              type: "error",
              message: "An error occurred while updating data",
            })
          );
        }
      }

      const updatedRequest = {
        ...selectedRequest,
        status: status,
        approverComment: comment,
        currentStep: nextStep,
      };

      setSelectedRequest(updatedRequest);
      setFilteredEMemos(
        filteredEMemos.map((memo) =>
          memo.id === updatedRequest.id ? updatedRequest : memo
        )
      );
      setModalOpen(false);
    }
  };

  const handleReject = async (comment) => {
    if (selectedRequest) {
      let nextStep = "rejected";
      let status = "Rejected";

      const res = await dispatch(
        patchEmemoStatus(selectedRequest.id, status, comment, nextStep)
      );

      if (res) {
        if (res.status === 200) {
          dispatch(
            openNotificationAlert({
              type: "success",
              message: "Rejected successfully",
            })
          );
        } else {
          dispatch(
            openNotificationAlert({
              type: "error",
              message: "An error occurred while updating data",
            })
          );
        }
      }

      const updatedRequest = {
        ...selectedRequest,
        status: status,
        approverComment: comment,
        currentStep: nextStep,
      };

      setSelectedRequest(updatedRequest);
      setFilteredEMemos(
        filteredEMemos.map((memo) =>
          memo.id === updatedRequest.id ? updatedRequest : memo
        )
      );
      setModalOpen(false);
    }
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" style={{ paddingTop: 8 }}>
            E-Memo Manager {formatDateRange()}
          </Typography>
        </Box>

        <CardStyled>
          <StyledCardContent>
            <AdvanceSearch
              searchData={searchData}
              handleChange={handleChange}
              handleDateChange={handleDateChange}
              handleSearchOff={handleSearchOff}
            />
          </StyledCardContent>
        </CardStyled>

        <Typography variant="h6" style={{ marginBottom: 16 }}>
          Worklist
        </Typography>
        <DataTable
          columns={columns}
          data={filteredEMemos}
          onRowClick={handleViewDetails}
        />

        {selectedRequest && (
          <RequestDetailsModal
            open={isModalOpen}
            onClose={handleCloseModal}
            requestDetails={selectedRequest}
            handleApprove={handleApprove}
            handleReject={handleReject}
            userProfile={userProfile}
          />
        )}
      </Container>
    </StyledRoot>
  );
};

export default EMemoRequestlistPage;
