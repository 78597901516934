import React, { useState, useEffect } from "react";
import {
    Calendar as BigCalendar,
    momentLocalizer,
} from "react-big-calendar";
import moment from "moment";
import { Avatar, Box, Button, FormControlLabel, IconButton, Stack, styled, Switch, Typography, Modal, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "moment/locale/th";
import "moment/locale/zh-cn";
import "moment/locale/ja";
import "moment/locale/km";
import "moment/locale/ko";
import "moment/locale/lo";
import "moment/locale/my";
import "moment/locale/vi";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import CloseIcon from '@mui/icons-material/Close';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb': {
                backgroundColor: '#e6b8f2',
            },
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('/assets/icon/cake.png')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#e6e6e6' : '#e6e6e6',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#5d4789',
        width: 32,
        height: 32,
        '&::before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('/assets/icon/leave.png')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#e6e6e6' : '#e6e6e6',
        borderRadius: 20 / 2,
    },
}));

const localizer = momentLocalizer(moment);

const locales = {
    th: 'th',
    en: 'en',
    cn: 'zh-cn',
    jp: 'ja',
    kh: 'km',
    kr: 'ko',
    la: 'lo',
    mm: 'my',
    vn: 'vi',
};

export default function Calendar(props) {
    const { t, events, i18n, birthdayList } = props;
    const [locale, setLocale] = useState('th');
    const [currentView, setCurrentView] = useState('month');
    const [date, setDate] = useState(moment().startOf('month').toDate());
    const [isActive, setIsActive] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [currentEvent, setCurrentEvent] = useState(null);

    const handleOpenModal = (event) => {
        setSelectedEvent(event)
        setModalOpen(true);
    };
    const handleSetEventPerPage = (event) => {
        const currentEvents = event.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        setCurrentEvent(currentEvents);
    };

    const handleCloseModal = () => {
        setSelectedEvent(null);
        setModalOpen(false);
    };

    const goToToday = () => {
        setDate(new Date());
        setCurrentView('month');
    };

    const goToBack = () => {
        setDate(moment(date).subtract(1, currentView === 'month' ? 'month' : 'day').toDate());
    };

    const goToNext = () => {
        setDate(moment(date).add(1, currentView === 'month' ? 'month' : 'day').toDate());
    };

    const toggleAgendaView = () => {
        setCurrentView(prevView => (prevView === 'month' ? 'agenda' : 'month'));
        setDate(moment(date).startOf('month').toDate());
    };

    const handleSwitchChange = (event) => {
        setIsActive(event.target.checked);
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (selectedEvent) {
            handleSetEventPerPage(selectedEvent)
        }
    }, [page, selectedEvent, rowsPerPage])

    const CustomToolbar = ({ label }) => {
        const formattedDate =
            currentView !== 'day'
                ? moment(date).locale(locale).format('MMMM YYYY')
                : moment(date).locale(locale).format('Do MMMM YYYY');

        return (
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 2, sm: 0 } }}>
                <ButtonBlue style={{ backgroundColor: '#5d4789' }} variant="contained" onClick={goToToday}>
                    {t('calendar.today')}
                </ButtonBlue>
                <Box display="flex" alignItems="center" sx={{ flexDirection: 'row' }}>
                    <IconButton onClick={goToBack}>
                        <ExpandMoreIcon sx={{ transform: 'rotate(90deg)' }} />
                    </IconButton>
                    <Typography textAlign='center' variant="h6">{formattedDate}</Typography>
                    <IconButton onClick={goToNext}>
                        <ExpandMoreIcon sx={{ transform: 'rotate(-90deg)' }} />
                    </IconButton>
                </Box>
                <Box display="flex" alignItems="center" sx={{ flexDirection: 'row' }}>
                    <FormControlLabel
                        control={
                            <Stack direction="column" alignItems="center">
                                <MaterialUISwitch
                                    checked={isActive}
                                    onChange={handleSwitchChange}
                                    color="primary"
                                />
                            </Stack>
                        }
                        label=""
                        sx={{
                            marginLeft: { xs: 0, sm: 'auto' },
                            marginRight: { xs: 0, sm: 'auto' },
                        }}
                    />
                    <ButtonBlue style={{ backgroundColor: '#5d4789' }} variant="contained" onClick={toggleAgendaView}>
                        {currentView === 'month' ? t('calendar.agenda') : t('calendar.month')}
                    </ButtonBlue>
                </Box>
            </Box>
        );
    };

    const EventComponentDay = ({ event }) => {
        return (
            <Box display="flex" alignItems="center" flexDirection="row">
                {event.holiday ? <Typography variant="body2">{event.title}</Typography> :
                    <>
                        <Avatar title={event.title} src={event.img || "https://example.com/avatar1.jpg"} alt={event.title} sx={{ width: 30, height: 30, marginRight: 0.5, pointerEvents: 'visible' }} />
                        <Typography variant="body2">{isActive ? event.title || '' : event.name || ''}</Typography>
                    </>
                }
            </Box>
        );
    };

    const EventComponentMonth = ({ events }) => {
        const eventsToShow = currentView === 'month' ? events.slice(0, 2) : events;
        return (
            <Box display="flex" alignItems="flex-start" flexDirection="row" padding="10px 0 15px">
                {eventsToShow.map((event, index) => (
                    <Box key={index} display="flex" alignItems="center" mb={0}>
                        <Avatar title={event.title} src={event.img || "https://example.com/avatar1.jpg"} alt={event.title} sx={{ width: 30, height: 30, marginRight: -0.5, boxShadow: 1, pointerEvents: 'visible' }} />
                    </Box>
                ))}
                {events.length > 2 && currentView === 'month' && (
                    <Box display="flex" alignItems="center" mb={0}>
                        <Avatar
                            sx={{
                                bgcolor: 'grey.400',
                                width: 30,
                                height: 30,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                pointerEvents: 'auto',
                            }}
                            onClick={() => {
                                const eventDate = events[0].start;
                                setDate(eventDate);
                                // setCurrentView('day');
                                const sameDayEvents = isActive
                                    ? birthdayList.filter(e => moment(e.start).isSame(eventDate, 'day'))
                                    : events.filter(e => moment(e.start).isSame(eventDate, 'day'));
                                handleOpenModal(sameDayEvents);
                            }}
                        >
                            {events.length - 2}+
                        </Avatar>
                    </Box>
                )}
            </Box>
        );
    };

    const messages = {
        today: t('calendar.today'),
        previous: t('calendar.previous'),
        next: t('calendar.next'),
        month: t('calendar.month'),
        week: t('calendar.week'),
        day: t('calendar.day'),
        agenda: t('calendar.agenda'),
        noEventsInRange: t('calendar.noEventsInRange'),
    };

    const updateLocale = (lang) => {
        const newLocale = locales[lang] || 'th';
        setLocale(newLocale);
        moment.locale(newLocale);
    };

    useEffect(() => {
        updateLocale(i18n.resolvedLanguage);
    }, [i18n.resolvedLanguage]);

    const mergedEvents = isActive && birthdayList && birthdayList.length > 0 ? birthdayList : events;

    return (
        <>
            <BigCalendar
                {...props}
                localizer={localizer}
                events={mergedEvents}
                views={['month', 'agenda', 'day']}
                view={currentView}
                messages={messages}
                defaultView={currentView}
                onView={view => {
                    setCurrentView(view);
                    if (view === 'month') {
                        setDate(moment().startOf('month').toDate());
                    }
                }}
                onNavigate={newDate => {
                    setDate(moment(newDate).toDate());
                }}
                date={date}
                components={{
                    toolbar: CustomToolbar,
                    event: ({ event }) => {
                        const sameDayEvents = isActive
                            ? birthdayList.filter(e => moment(e.start).isSame(event.start, 'day'))
                            : events.filter(e => moment(e.start).isSame(event.start, 'day'));

                        return currentView === 'month' ? <EventComponentMonth events={sameDayEvents} /> : <EventComponentDay event={event} />;
                    },
                }}
                eventPropGetter={(event) => {
                    let backgroundColor;
                    switch (event.status) {
                        case 'Absent':
                            backgroundColor = '#FA9696';
                            break;
                        case 'Late':
                            backgroundColor = '#FFB680';
                            break;
                        case 'EarlyOut':
                            backgroundColor = '#FFB680';
                            break;
                        default:
                            backgroundColor = '#fecf81';
                    }

                    return {
                        style: {
                            backgroundColor,
                        },
                    };
                }}
                style={{ minHeight: 500, width: '100%', maxHeight: 800, height: '100%' }}
            />
            <Modal open={modalOpen} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '70%',
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <IconButton
                        onClick={handleCloseModal}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: 'grey.500',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" component="h2" gutterBottom>
                        {moment(date).format('LL')}
                    </Typography>
                    {selectedEvent && selectedEvent.length > 0 ? (
                        <>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead sx={{ backgroundColor: 'rgb(93, 71, 137)' }}>
                                        <TableRow>
                                            <TableCell sx={{ color: 'white' }}></TableCell>
                                            <TableCell sx={{ color: 'white' }}>{t('TitleName')}</TableCell>
                                            <TableCell sx={{ color: 'white' }}>{t('Position')}</TableCell>
                                            {isActive ? <TableCell sx={{ textAlign: 'center', color: 'white' }}>{t('Date')}</TableCell> :
                                                (<>
                                                    <TableCell sx={{ color: 'white' }}>{t('Start')}</TableCell>
                                                    <TableCell sx={{ color: 'white' }}>{t('End')}</TableCell>
                                                </>)
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {currentEvent && currentEvent.map((event, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Avatar sx={{ boxShadow: 2, pointerEvents: 'visible' }} title={event.title} src={event.img || "https://example.com/avatar1.jpg"} alt={event.title} />
                                                </TableCell>
                                                <TableCell>{event.title}</TableCell>
                                                <TableCell>{event.position || ''}</TableCell>
                                                {isActive ? <TableCell sx={{ textAlign: 'center' }}>{moment(event.start).format('LL')}</TableCell> :
                                                    (<>
                                                        <TableCell>{moment(event.start).format('LLL')}</TableCell>
                                                        <TableCell>{moment(event.end).format('LLL')}</TableCell>
                                                    </>)
                                                }

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* Pagination */}
                            <TablePagination
                                rowsPerPageOptions={[]}
                                component="div"
                                count={selectedEvent.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </>
                    ) : (
                        <Typography variant="body2">{t('calendar.noEvents')}</Typography>
                    )}
                </Box>
            </Modal>
        </>

    );
}
