import React, { useState, useEffect, Suspense, Fragment } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Typography,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from "@mui/material";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import NightsStayOutlinedIcon from "@mui/icons-material/NightsStayOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dayjs from "dayjs";

import Day from "../assets/day.png";
import Night from "../assets/night.png";
import Mid from "../assets/mid.png";

import AvatarOff from "../../../shared/general/AvatarOff";
import AvatarShift from "../../../shared/general/AvatarShift";
import AvatarPeriod from "../../../shared/general/AvatarPeriod";

//Translator TH-EN
import { useTranslation } from "react-i18next";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const DialogStyled = styled(Dialog)({
  "& .MuiPaper-root": {
    borderRadius: "16px",
  },
});

export default function StepFour(props) {
  const { dataNewShift, setStep, tableShift, setTableShift } = props;
  const { t, i18n } = useTranslation();
  const [error, setError] = useState(false);
  const [period] = useState(["day", "mid", "night", "off"]);

  useEffect(() => {
    const tempTableShift = [];
    var countLoopPattern = 0;
    let numberOfPeriod = 0;
    dataNewShift.periodTime.map((period) => {
      if (period.check === true) {
        numberOfPeriod++;
      }
    });

    console.log("dataNewShift : ", dataNewShift);

    for (
      let index = 0;
      index < dayjs(dataNewShift.start).daysInMonth();
      index++
    ) {
      if (
        countLoopPattern >=
        (parseInt(dataNewShift.workday) + parseInt(dataNewShift.offday)) *
          numberOfPeriod
      ) {
        countLoopPattern = 0;
      }

      const temp = {
        date: dayjs(dataNewShift.start).add(index, "day"),
        off: [],
      };

      for (const [key, value] of Object.entries(dataNewShift.patternShift)) {
        var newKey = value[countLoopPattern].title;
        if (newKey.toLowerCase() === "off") {
          temp["off"].push(key.slice(-1));
        } else {
          temp[newKey.toLowerCase()] = key.slice(-1);
        }
      }
      // if(!temp["off"]){
      //   setError(true);
      // }
      tempTableShift.push(temp);

      countLoopPattern++;
    }

    console.log("tempTableShift : ", tempTableShift);
    setTableShift(tempTableShift);
  }, []);

  return (
    <div style={{ display: "grid" }}>
      {!error ? (
        <Fragment>
          <Typography
            variant="h3"
            style={{ textAlign: "center", marginBottom: 24 }}
          >
            {dayjs(dataNewShift.start).format("MMMM YYYY")}
          </Typography>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {tableShift &&
                    tableShift.map((value, index) => {
                      return (
                        <TableCell
                          align="center"
                          style={{ padding: 0 }}
                          key={dayjs(value.date).format("D") + "" + index}
                        >
                          {dayjs(value.date).day() === 0 ||
                          dayjs(value.date).day() === 6 ? (
                            <Typography
                              style={{ fontWeight: 500, color: "#e46a76" }}
                            >
                              {dayjs(value.date).format("D")}
                            </Typography>
                          ) : (
                            <Typography>
                              {dayjs(value.date).format("D")}
                            </Typography>
                          )}
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableShift &&
                  period
                    .filter((value) => {
                      if (tableShift[0][value] !== undefined) {
                        return value;
                      }
                    })
                    .map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            padding: row === "off" ? "28px 16px" : "6px 16px",
                          }}
                        >
                          {row === "day" && (
                            <AvatarPeriod title={row} size={40} src={Day} />
                          )}
                          {row === "mid" && (
                            <AvatarPeriod title={row} size={40} src={Mid} />
                          )}
                          {row === "night" && (
                            <AvatarPeriod title={row} size={40} src={Night} />
                          )}

                          {row === "off" && <AvatarOff />}
                        </TableCell>
                        {tableShift.map((value, index) => {
                          return (
                            <TableCell
                              align="center"
                              style={{ padding: 0 }}
                              key={index}
                            >
                              {row === "off" ? (
                                <div>
                                  {value[row].map((v) => {
                                    return (
                                      <div
                                        style={{ marginBottom: 8 }}
                                        key={v + row + index}
                                      >
                                        <AvatarShift
                                          fontSize={18}
                                          title={v}
                                          size={25}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <AvatarShift
                                  fontSize={18}
                                  title={value[row]}
                                  size={25}
                                />
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Fragment>
      ) : (
        <DialogStyled open={error} style={{ borderRadius: "16px" }}>
          <DialogTitle style={{ color: "red", fontSize: "36px" }}>
            {`${t("ShiftPatternIncorrect")}`}
          </DialogTitle>
          <DialogContent
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100px",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                fontSize: "20px",
                height: "fit-content",
                padding: "10px 20px",
              }}
              onClick={() => setStep(3)}
              startIcon={<ArrowBackIcon />}
            >
              {`${t("BackToShiftPtternPage")}`}
            </Button>
          </DialogContent>
        </DialogStyled>
      )}
    </div>
  );
}
