import { 
    GET_UNION_DOCUMENT_GROUP_TYPE_FETCHING,
    GET_UNION_DOCUMENT_GROUP_TYPE_FAILURE, 
    GET_UNION_DOCUMENT_GROUP_TYP_SUCCESS 
} from "../actions/types";

const initialState = {
  result: null,
  isFetching: false,
  isError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case  GET_UNION_DOCUMENT_GROUP_TYPE_FETCHING:
      return { ...state, result: null, isFetching: true, isError: false };
    case GET_UNION_DOCUMENT_GROUP_TYPE_FAILURE:
      return { ...state, result: null, isFetching: false, isError: true };
    case GET_UNION_DOCUMENT_GROUP_TYP_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    default:
      return state;
  }
};