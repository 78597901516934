import { httpClient } from "./httpClient";

const getAllProbationQuestionSet = (query) => {
  return httpClient.get(`/probation-question-set`, { params: query });
};

const addProbationQuestionSet = (formData) => {
  return httpClient.post(`/probation-question-set`, formData);
};

const updateProbationQuestionSet = (formData) => {
  return httpClient.put(`/probation-question-set/${formData.idProbationQuestionSet}`, formData);
};

const updateProbationQuestionSetStatus = (formData) => {
  return httpClient.put(`/probation-question-set/${formData.idProbationQuestionSet}/status`, formData);
};

const getProbationQuestion = (query) => { // delete
  return httpClient.get(`/probation-question`, { params: query });
};

const addProbationQuestion = (formData) => { // delete
  return httpClient.post(`/probation-question`, formData);
};

const updateProbationQuestion = (formData) => {
  return httpClient.put(`/probation-question`, formData);
};

const getProbation = (idProbation) => {
  return httpClient.get(`/probation/${idProbation}`);
};

const updateProbationManager = (formData) => {
  return httpClient.put(`/probation/manager-probation/${formData.idProbation}`, formData);
};

const getProbationAndAnswer = () => {
  return httpClient.get(`/probation/result-probation`);
};

const updateProbationAdmin = (formData) => {
  return httpClient.put(`/probation/admin-probation/${formData.idProbation}`, formData);
};

const getProbationEmployee = () => {
  return httpClient.get("/probation/employee-probation");
};

const updateProbationEmployee = (formData) => {
  return httpClient.put(`/probation/employee-probation/${formData.idProbation}`, formData);
};

const getProbationEmployeeAnswer = (idEmployees) => {
  return httpClient.get(`probation/employee/${idEmployees}`);
};

export default {
  getAllProbationQuestionSet,
  addProbationQuestionSet,
  updateProbationQuestionSet,
  updateProbationQuestionSetStatus,
  getProbationQuestion,
  addProbationQuestion,
  updateProbationQuestion,
  getProbation,
  updateProbationManager,
  getProbationAndAnswer,
  updateProbationAdmin,
  getProbationEmployee,
  updateProbationEmployee,
  getProbationEmployeeAnswer,
};
