import React from "react";
import {
  Avatar,
  Box,
  Card,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import dayjs from "dayjs";
import ChipStatus from "../../../shared/pages/okrs/components/ChipStatus";
import { useTranslation } from "react-i18next";

const StyleText = styled(Typography)({
  "& .name-text": {
    fontSize: "20px",
    fontWeight: "bold",
  },
  "& .gray-color-text": {
    fontSize: "16px",
    color: "gray",
  },
  "& .content-text": {
    fontSize: "16px",
    color: "black",
  },
});

const StyleImg = styled(Box)({
  "& .img-emp": {
    width: "80px",
    height: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    borderRadius: "50%",
  },
});

const getStatusApprove = (status) => {
  if (status === null) {
    return 1;
  } else if (status === 1) {
    return 2;
  } else if (status === 0) {
    return 3;
  } else {
    return 1;
  }
};

const getStatusAchieveApprove = (status) => {
  if (status === 1) {
    return 5;
  } else if (status === 0) {
    return 6;
  } else {
    return 4;
  }
};

const FCard = ({
  avatarSrc,
  fullName,
  positionName,
  department,
  objective,
  transactionDate,
  year,
  quarter,
  sectionName,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        flex="1"
      >
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {`Objective: ${objective}`}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {`Transaction Date: ${transactionDate}`}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {`Year: ${year} Quarter: ${quarter}`}
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        flex="1"
        justifyContent="flex-end"
      >
        <Box>
          <Typography variant="h6" component="div" align="right">
            {fullName}
          </Typography>
          <Typography variant="body2" color="text.secondary" align="right">
            {`${positionName} - ${department}`}
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Typography
              variant="body2"
              color="text.secondary"
              align="right"
              sx={{ marginRight: "4px" }}
            >
              {`${t("Section")}:`}
            </Typography>
            <Typography variant="body2" color="text.primary" align="right">
              {sectionName}
            </Typography>
          </Box>
        </Box>
        <Avatar src={avatarSrc} sx={{ width: 50, height: 50, marginLeft: 2 }} />
      </Box>
    </Box>
  );
};

function Row({ row }) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  return (
    <Card
      sx={{
        width: "100%",
        minHeight: "100px",
        p: 3,
        mb: 2,
        borderRadius: 2,
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s ease",
        cursor: "pointer",
      }}
      variant="outlined"
      onClick={() => setOpen(!open)}
    >
      <FCard
        avatarSrc={row.imageURL}
        fullName={`${row.firstname_TH} ${row.lastname_TH}`}
        positionName={row.positionName}
        department={row.departmentName}
        objective={row.objectiveName}
        transactionDate={dayjs(row.createDate).format("DD/MM/YYYY")}
        year={row.year}
        quarter={row.quarter}
        sectionName={row.sectionName}
      />

      <Box sx={{ mt: 2 }}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1, overflowX: "auto" }}>
            <Typography variant="h6" gutterBottom component="div">
              {t("Descriptions")}
            </Typography>
            <Box sx={{ overflowX: "auto", maxWidth: "100%" }}>
              <Table
                sx={{
                  backgroundColor: "#F5F5F5",
                  borderRadius: 2,
                  width: "100%",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: "150px" }}>
                      {t("Key result")}
                    </TableCell>
                    <TableCell>{t("Employees")}</TableCell>
                    <TableCell>{t("EvaluationResults")}</TableCell>
                    <TableCell>{t("Status")}</TableCell>
                    <TableCell sx={{ minWidth: "200px" }}>
                      {t("Duration")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.keyResults.map((keyResult) => (
                    <TableRow
                      key={keyResult.date}
                      sx={{ "&:last-child td": { border: 0 } }}
                    >
                      <TableCell>{keyResult.keyResultName}</TableCell>
                      <TableCell>
                        <ChipStatus
                          status={getStatusAchieveApprove(keyResult.isAchieve)}
                        />
                      </TableCell>
                      <TableCell>
                        <ChipStatus
                          status={getStatusAchieveApprove(keyResult.isSuccess)}
                        />
                      </TableCell>
                      <TableCell>
                        <ChipStatus
                          status={getStatusApprove(keyResult.isApprove)}
                        />
                      </TableCell>
                      <TableCell>
                        {`${dayjs(keyResult.startDate).format(
                          "DD/MM/YYYY"
                        )} - ${dayjs(keyResult.endDate).format("DD/MM/YYYY")}`}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Collapse>
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
        }}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        <IconButton aria-label="expand row" size="large">
          {open ? (
            <KeyboardArrowUpIcon fontSize="large" />
          ) : (
            <KeyboardArrowDownIcon fontSize="large" />
          )}
        </IconButton>
      </Box>
    </Card>
  );
}

const StyledGridContainer = styled(Box)({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "20px",
  padding: "20px",
  "@media (max-width: 768px)": {
    gridTemplateColumns: "1fr",
  },
});

const StatusApprove = ({ data }) => {
  const { t } = useTranslation();

  if (!data || data.length === 0) {
    return (
      <Typography align="center" mt={4}>
        {t("NoData")}
      </Typography>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <StyledGridContainer>
        {data.map((row) => (
          <Row key={row.idIndividualObjective} row={row} />
        ))}
      </StyledGridContainer>
    </Box>
  );
};

export default StatusApprove;
