import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Button,
  Stack,
  Container,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  TableCell,
  Chip,
  MenuItem,
  Menu,
  Avatar,
  Grid,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import dayjs from "dayjs";
import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from "../../shared/general/Card";
import DialogCreate from "./dialogCreate";
import { useDispatch, useSelector } from "react-redux";
import {
  getAssetManagement,
  updateAssetManagementStatus,
} from "../../../../actions/assetManagement";
import { AssetManagementXlsxFile } from "./assetXlsxfile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import {
  getUserDepartment,
  getUserFullName,
  getUserPosition,
  getUserSection,
} from "../../../../utils/userData";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import MonthPickerCustom from "../../shared/date/monthPicker";
import { getAffiliateOrganization } from "../../../../actions/company";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import SelectEmployees from "../../shared/general/selectEmployees";
import { openNotificationAlert } from "../../../../actions/notificationAlert";
import DialogDelete from "./dialogDelete";
import DatePickerCustom from "../../shared/date/datePicker";
import LoadingGif from "../../assets/social-media.gif";

const StyledRoot = styled("div")({
  backgroundColor: "#f5f5f5",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const WrapHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
  marginTop: 8,
  marginBottom: 16,
});

const MenuStatus = ({ idAssetManagement, idCompany }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [openDailogReceive, setOpenDailogReceive] = useState(false);
  const [receivedDate, setReceivedDate] = useState(dayjs());
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDailogReceive = () => {
    setOpenDailogReceive(true);
  };

  const handleCloseDailogReceive = () => {
    setOpenDailogReceive(false);
  };

  const handleUpdateAssetManagementStatus = async (receivedDate) => {
    const res = await dispatch(
      updateAssetManagementStatus({ idAssetManagement, receivedDate })
    );
    if (res && res.status === 200) {
      dispatch(
        openNotificationAlert({ type: "success", message: t("Success") })
      );
    } else {
      dispatch(
        openNotificationAlert({ type: "error", message: t("AnErrorOccurred") })
      );
    }
    dispatch(getAssetManagement(idCompany));
    handleClose();
  };

  const handleNotReturnAsset = () => {
    handleUpdateAssetManagementStatus(null)
  };

  const handleReturnDate = () => {
    handleUpdateAssetManagementStatus(receivedDate)
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={handleOpenDailogReceive}
        >
          {t("Returned")}
        </MenuItem>
        <MenuItem
          onClick={() => handleNotReturnAsset(0)}
        >
          {t("Haven'tReturned")}
        </MenuItem>
      </Menu>
      {openDailogReceive && (
        <Dialog open={openDailogReceive}>
          <DialogTitle>{t("ReceivedDate")}</DialogTitle>
          <DialogContent>
            <DatePickerCustom 
              value={receivedDate}
              onChange={(newValue) => setReceivedDate(newValue)}
            />
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={handleCloseDailogReceive}>{t("Cancel")}</Button>
            <ButtonBlue
              variant="contained"
              onClick={handleReturnDate}
            >
              {t("Confirm")}
            </ButtonBlue>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

const AssetManagement = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: assetManagement, isFetching } = useSelector(
    (state) => state.assetManagement
  );
  const { result: affiliateOrganization, isFetching: fetchingAffiliateOrganization } = useSelector(
    (state) => state.affiliateOrganization
  );

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectItem, setSelectItem] = useState(null);
  const [companySelected, setCompanySelected] = useState(null);
  const [employeeSelected, setEmployeeSelected] = useState(null);
  const [filterReturnDate, setFilterReturnDate] = useState(null);
  const [filterReceiveDate, setFilterReceiveDate] = useState(null);
  const [filterStatus, setFilterStatus] = useState("all");
  const [filterAsset, setFilterAsset] = useState("");

  const handleChangeFilterStatus = (event) => {
    setFilterStatus(event.target.value);
  };

  const handleChangeFilterAsset = (event) => {
    setFilterAsset(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectItem(null);
  };

  const handleClickOpenEdit = (item) => {
    setOpen(true);
    setSelectItem(item);
  };

  const handleOpenDelete = (item) => {
    setOpenDelete(true);
    setSelectItem(item);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleChangeCompanySelected = (event, value) => {
    setCompanySelected(value)
    setFilterReturnDate(null);
    setFilterReceiveDate(null);
    setFilterStatus("all");
    setFilterAsset("");
  }

  const filterData = () => {
    let dataFiltered = [...assetManagement];
    if (employeeSelected) {
      dataFiltered = dataFiltered.filter(
        (value) => employeeSelected.idEmployees === value.idEmployees
      );
    }
    if (filterReturnDate) {
      dataFiltered = dataFiltered.filter((item) =>
        dayjs(item.returnDate).isSame(filterReturnDate, "month")
      );
    }
    if (filterReceiveDate) {
      dataFiltered = dataFiltered.filter((item) =>
        item.receivedDate === null || dayjs(item.receivedDate).isSame(filterReceiveDate, "month")
      );
    }
    if (filterStatus === 1) {
      dataFiltered = dataFiltered.filter((item) => item.receivedDate);
    }
    if (filterStatus === 0) {
      dataFiltered = dataFiltered.filter((item) => item.receivedDate === null);
    }
    if (filterAsset !== "") {
      dataFiltered = dataFiltered.filter(
        (item) => (item.idAsset).toLowerCase().includes(filterAsset.toLowerCase()) || (item.name).toLowerCase().includes(filterAsset.toLowerCase())
      );
    }

    return dataFiltered
  };

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (affiliateOrganization && affiliateOrganization.length > 0) {
      setCompanySelected(affiliateOrganization[0]);
    }
  }, [affiliateOrganization]);

  useEffect(() => {
    if (companySelected) {
      dispatch(getAssetManagement(companySelected.idCompany));
    }
  }, [companySelected]);

  return (
    <StyledRoot className={"page"}>
      <Container maxWidth="lg">
        <WrapHeader>
          <Typography variant="h4">{t("AppMenu.AssetManagement")}</Typography>
        </WrapHeader>
        <CardStyle style={{ padding: 16 }}>
          <Grid container spacing={2} marginBottom={3}>
            <Grid item xs={12} sm={6} md={4} marginTop={"26px"}>
              <Typography
                fontSize="14px"
                fontWeight="600"
                color="text.third"
                marginBottom="4px"
              >
                {t("Company")}
              </Typography>
              <SelectAffiliateCompany
                options={affiliateOrganization}
                value={companySelected}
                onChange={handleChangeCompanySelected}
                disabled={fetchingAffiliateOrganization}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SelectEmployees
                value={employeeSelected}
                handleChange={setEmployeeSelected}
                selectedCompany={companySelected}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} marginTop={{ xs: "0px", md: "26px" }}>
              <Typography
                fontSize="14px"
                fontWeight="600"
                color="text.third"
                marginBottom="4px"
                
              >
                {t("Status")}
              </Typography>
              <TextFieldTheme
                select
                fullWidth
                variant="filled"
                sx={{
                  "& .MuiSelect-filled": { padding: "16px" },
                }}
                value={filterStatus}
                onChange={handleChangeFilterStatus}
              >
                <MenuItem value={"all"}>{t("All")}</MenuItem>
                <MenuItem value={1}>{t("Returned")}</MenuItem>
                <MenuItem value={0}>{t("Haven'tReturned")}</MenuItem>
              </TextFieldTheme>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                fontSize="14px"
                fontWeight="600"
                marginBottom="4px"
                color="text.third"
              >
                {t("ReceivedDate")}
              </Typography>
              <MonthPickerCustom
                value={filterReceiveDate}
                onChange={(newValue) => setFilterReceiveDate(newValue)}
                views={["month", "year"]}
                minDate={dayjs().subtract(1, "year")}
                maxDate={dayjs().add(1, "year")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                fontSize="14px"
                fontWeight="600"
                marginBottom="4px"
                color="text.third"
              >
                {t("ReturnDueDate")}
              </Typography>
              <MonthPickerCustom
                value={filterReturnDate}
                onChange={(newValue) => setFilterReturnDate(newValue)}
                views={["month", "year"]}
                minDate={dayjs().subtract(1, "year")}
                maxDate={dayjs().add(1, "year")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                fontSize="14px"
                fontWeight="600"
                marginBottom="4px"
                color="text.third"
              >
                {`${t("AssetID")} ${t("or")} ${t("AssetName")}`}
              </Typography>
              <TextFieldTheme
                variant="filled"
                sx={{
                  "& .MuiInputBase-input": { padding: "16px" },
                }}
                value={filterAsset}
                onChange={handleChangeFilterAsset}
              />
            </Grid>
          </Grid>
          <Stack direction={"row"} justifyContent={"flex-end"} spacing={1} marginBottom={3}>
            <ButtonBlue
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleClickOpen}
            >
              {t("AddItem")}
            </ButtonBlue>

            <ButtonBlue
              variant="outlined"
              startIcon={<FileDownloadIcon />}
              onClick={() => AssetManagementXlsxFile(t, assetManagement)}
            >
              {t("Download")}
            </ButtonBlue>
          </Stack>
          {open && (
            <DialogCreate
              open={open}
              handleClose={handleClose}
              data={selectItem}
              idCompany={companySelected.idCompany}
            />
          )}
           {openDelete && (
            <DialogDelete 
              open={openDelete}
              handleClose={handleCloseDelete}
              idAssetManagement={selectItem.idAssetManagement}
              idCompany={companySelected.idCompany}
            />
          )}
          {isFetching ? (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={LoadingGif} style={{ width: 100, height: 100 }} />
            </Box>
          ) : companySelected &&
            assetManagement &&
            assetManagement.length > 0 ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 960 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t("EmployeeName")}</TableCell>
                    <TableCell align="center">{t("AssetID")}</TableCell>
                    <TableCell align="center">{t("AssetName")}</TableCell>
                    <TableCell align="center">{t("ReceivedDate")}</TableCell>
                    <TableCell align="center">{t("ReturnDueDate")}</TableCell>
                    <TableCell align="center">{t("Status")}</TableCell>
                    <TableCell align="center">{t("Action")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterData().map((item) => (
                    <TableRow key={item.idAssetManagement}>
                      <TableCell align="center">
                        <Stack direction={"row"} gap={2}>
                          <Avatar src={item.imageURL} />
                          <div style={{ textAlign: "start" }}>
                            <Typography fontWeight={500}>
                              <Typography>{getUserFullName(item)}</Typography>
                            </Typography>
                            {item.positionName && (
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {`${t("Position")}: ${getUserPosition(item)} `}
                              </Typography>
                            )}
                            {item.departmentName && (
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {`${t("Department")}: ${getUserDepartment(
                                  item
                                )}`}
                              </Typography>
                            )}
                            {item.sectionName && (
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {`${t("Section")}: ${getUserSection(item)}`}
                              </Typography>
                            )}
                          </div>
                        </Stack>
                      </TableCell>
                      <TableCell align="center">{item.idAsset}</TableCell>
                      <TableCell align="center">{item.name}</TableCell>
                      <TableCell align="center">
                        {item.receivedDate
                          ? dayjs(item.receivedDate).format(
                              i18n.resolvedLanguage === "th"
                                ? "DD/MM/BBBB"
                                : "DD/MM/YYYY"
                            )
                          : t("Not_Specified")}
                      </TableCell>
                      <TableCell align="center">
                        {item.returnDate
                          ? dayjs(item.returnDate).format(
                              i18n.resolvedLanguage === "th"
                                ? "DD/MM/BBBB"
                                : "DD/MM/YYYY"
                            )
                          : t("Not_Specified")}
                      </TableCell>
                      <TableCell>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          {item.receivedDate ? (
                            <Chip label={`${t("Returned")}`} color="success" />
                          ) : (
                            <Chip
                              label={`${t("Haven'tReturned")}`}
                              color="error"
                            />
                          )}
                          <MenuStatus
                            idAssetManagement={item.idAssetManagement}
                            idCompany={companySelected.idCompany}
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            style={{ color: "#ffea00" }}
                            onClick={() => handleClickOpenEdit(item)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            style={{ color: "#ff1744" }}
                            onClick={() => handleOpenDelete(item)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>{t("NoItems")}</Typography>
          )}
        </CardStyle>
      </Container>
    </StyledRoot>
  );
};

export default AssetManagement;
