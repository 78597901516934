import dayjs from 'dayjs';
import ExcelJS from 'exceljs';

export const PND1CsvFile = (selectYear, selectedMonth, employeeDetails, contributionRate) => {
    const months = [
        { value: "01", name: "มกราคม" },
        { value: "02", name: "กุมภาพันธ์" },
        { value: "03", name: "มีนาคม" },
        { value: "04", name: "เมษายน" },
        { value: "05", name: "พฤษภาคม" },
        { value: "06", name: "มิถุนายน" },
        { value: "07", name: "กรกฎาคม" },
        { value: "08", name: "สิงหาคม" },
        { value: "09", name: "กันยายน" },
        { value: "10", name: "ตุลาคม" },
        { value: "11", name: "พฤศจิกายน" },
        { value: "12", name: "ธันวาคม" },
    ];

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");


    const selectedMonthObject = months.find(month => month.value == selectedMonth);
    const selectedMonthName = selectedMonthObject ? selectedMonthObject.name : "Unknown Month";

    const headerRow = worksheet.addRow([
        "ลำดับที่",
        "เลขบัตรประชาชน",
        "คำนำหน้า",
        "ชื่อ",
        "สกุล",
        "เงินได้ตามมาตรา",
        "วันเดือนปีที่จ่าย",
        "รวมรายได้",
        "ภาษีหัก ณ ที่จ่าย",
        "เงื่อนไข",
    ]);

    headerRow.height = 20;

    if (employeeDetails && employeeDetails.employeeDetails.length > 0) {
        for (let itemIndex = 0; itemIndex < employeeDetails.employeeDetails.length; itemIndex++) {
            const item = employeeDetails.employeeDetails[itemIndex];

            const row = [
                `${itemIndex + 1}`,
                item.personalID ? item.personalID : "",
                item.title_TH || "",
                item.firstname_TH || "",
                item.lastname_TH || "",
                "401N",
                item.payDate ? dayjs(item.payDate).format("DD/MM/") + selectYear : "-",
                parseFloat(item.totalEarnings.toFixed(2)),
                parseFloat(item.value.toFixed(2)),
                parseInt(1)
            ];
            worksheet.addRow(row);
        }

        workbook.csv.writeBuffer().then((data) => {
            const csvString = "\uFEFF" + data;
            const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `ภงด 1.csv`;
            a.click();
            URL.revokeObjectURL(url);
        });        
    } else {
        console.log("No data");
    }
};