import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Job from "../../shared/pages/job/Job";
import { Container } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "calc(100vh - 20px)",
    marginTop: "20px",
  },
}));

const DetailOfJob = () => {
  const classes = useStyles();

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        <Job />
      </Container>
    </div>
  );
};

export default DetailOfJob;
