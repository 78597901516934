import { httpClient } from "./httpClient";

const getCostElement = (query) => {
    return httpClient.get(`/getCostElement`, { params: query });
};

const getCostCenterAndCostElement = () => {
    return httpClient.get(`/getCostCenterAndCostElement`);
};

const getDepartmentOFCostElement = () => {
    return httpClient.get(`/getDepartmentOFCostElement`);
};

const getTypeOFCostElement = (query) => {
    return httpClient.get(`/getTypeOFCostElement`, { params: query });
};

const putCostElement = (id, data) => {
    return httpClient.put(`/putCostElement/${id}`, data);
};

const postCostElement = (data) => {
    return httpClient.post(`/postCostElement`, data);
};

const deleteCostElement = (id) => {
    return httpClient.delete(`/deleteElement/${id}`);
}

export default {
    getCostElement,
    getCostCenterAndCostElement,
    getDepartmentOFCostElement,
    getTypeOFCostElement,
    putCostElement,
    postCostElement,
    deleteCostElement
};
