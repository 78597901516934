import React, { useEffect, Fragment } from "react";
import {
  Typography,
  MenuItem,
  Grid,
  Box,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Radio,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DatePickerCustom from "../../../shared/date/datePicker";
import dayjs from "dayjs";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getExitInterviewAndAnswer,
  updateExitInterviewUser,
} from "../../../../../actions/exitInterview";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../../utils/userData";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
  "& .btn-save": {
    marginTop: 16,
    display: "flex",
    justifyContent: "flex-end",
  },
});

const DialogProbation = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { open, handleClose, handleSubmitProbation } = props;
  const { result: ExitInterview } = useSelector(
    (state) => state.getExitInterview
  );
  const { result: ReasonExitInterview } = useSelector(
    (state) => state.exitInterviewReason
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    setValue,
    clearErrors,
    formState: { errors, dirtyFields },
    reset,
    setError,
  } = useForm({
    defaultValues: {
      questionList: [{ question: "", answer: "" }],
      others: "",
      reason: "",
      dateStart: dayjs().add(30, "day"),
    },
  });

  const onSubmit = async (formData) => {
    const cleansingForm = {
      questionList: formData.questionList,
      others: formData.others || null,
      idEmployees: userProfile && userProfile.idEmployees,
      idExitInterview:
        (ExitInterview &&
          ExitInterview.length > 0 &&
          ExitInterview[0].idExitInterview) ||
        null,
      reason: formData.reason || null,
      dateStart: formData.dateStart,
    };

    const response = await dispatch(updateExitInterviewUser(cleansingForm));
    if (response) {
      dispatch(getExitInterviewAndAnswer());
      handleSubmitProbation(response);
    }
  };

  const questionUseFieldArray = useFieldArray({
    rules: { maxLength: 10 },
    control: control,
    name: "questionList",
  });

  const setArryField = () => {
    if (ExitInterview !== null) {
      questionUseFieldArray.replace(ExitInterview);
    }
  };

  useEffect(() => {
    setArryField();
  }, []);

  return (
    <DrawerCustom
      title={`${t("ResignationForm")}`}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Box style={{ padding: "24px" }}>
            {userProfile && (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={12}>
                  <Typography sx={{ display: "inline-block" }}>
                    <i className="fa-regular fa-user fa-xl"></i>
                    &nbsp;&nbsp;
                  </Typography>
                  <Typography
                    sx={{ display: "inline-block", fontWeight: "medium" }}
                  >
                    {`${t("TransactionExecutor")} : ${getUserFullName(userProfile)}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography sx={{ display: "inline-block" }}>
                    <i className="fa-regular fa-building-columns fa-xl"></i>
                    &nbsp;&nbsp;
                  </Typography>
                  <Typography
                    sx={{ display: "inline-block", fontWeight: "medium" }}
                  >
                    {`${t("Position")} : ${userProfile.positionName}`}{" "}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Box>
          <Box style={{ paddingLeft: "24px" }}>
            {questionUseFieldArray.fields.map((question, index) => (
              <Grid key={question.id} container className="GridContact">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  marginTop={2}
                  className="GridTopicInput"
                >
                  <Grid item xs={12} sm={12}>
                    <i className="fa-regular fa-square-question fa-bounce fa-xl"></i>
                    &nbsp;&nbsp;
                    {`${t("Question")} ${index + 1} : ${question.question}`}
                  </Grid>
                </Grid>
                <Grid item xs={12} marginTop={1} sm={12}>
                  <Controller
                    control={control}
                    name={`questionList.${index}.answer`}
                    rules={{
                      required: {
                        value: watch("expandTime") === 0 ? true : false,
                        message: `${t("PleaseEnterYourResponse")}`,
                      },
                    }}
                    render={({ field, formState }) => (
                      <Fragment>
                        <RadioGroup
                          {...field}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                        >
                          <FormControlLabel
                            value="verygood"
                            control={<Radio />}
                            label={`${t("Excellence")}`}
                          />
                          <FormControlLabel
                            value="good"
                            control={<Radio />}
                            label={`${t("Good")}`}
                          />
                          <FormControlLabel
                            value="meduim"
                            control={<Radio />}
                            label={`${t("Neutral")}`}
                          />
                          <FormControlLabel
                            value="fair"
                            control={<Radio />}
                            label={`${t("Fair")}`}
                          />
                          <FormControlLabel
                            value="prettybad"
                            control={<Radio />}
                            label={`${t("Poor")}`}
                          />
                          <FormControlLabel
                            value="bad"
                            control={<Radio />}
                            label={`${t("VeryPoor")}`}
                          />
                        </RadioGroup>
                        <FormHelperText sx={{ color: "#ff0000" }}>
                          {formState.errors &&
                          formState.errors.questionList &&
                          formState.errors.questionList[index] &&
                          formState.errors.questionList[index].answer
                            ? formState.errors.questionList[index].answer
                                .message
                            : null}
                        </FormHelperText>
                      </Fragment>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider
                    sx={{
                      marginTop: "16px",
                      borderColor: "#9e9e9e66",
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </Box>

          {questionUseFieldArray.fields.length <= 0 && (
            <Grid item xs={12}>
              <Typography textAlign="center" color="text.secondary">
                {`${t("NoQuestions")}`}
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={3} marginBottom={2}>
          <Grid item xs={12} sm={12}>
            <Typography marginBottom={1}>{`${t("Other")}`}</Typography>
            <Controller
              control={control}
              name={`others`}
              render={({ field, formState }) => (
                <TextFieldTheme
                  {...field}
                  multiline
                  rows={2}
                  autoComplete="others"
                  error={
                    formState.errors && formState.errors.others ? true : false
                  }
                  helperText={
                    formState.errors && formState.errors.others
                      ? formState.errors.others.message
                      : null
                  }
                ></TextFieldTheme>
              )}
            />
          </Grid>
          {ReasonExitInterview && (
            <Grid item xs={12} sm={12}>
              <Typography marginBottom={1}>{`${t(
                "ResignationReason"
              )}`}</Typography>
              <Controller
                control={control}
                name={`reason`}
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseSelectReason")}`,
                  },
                }}
                render={({ field, formState }) => (
                  <TextFieldTheme
                    {...field}
                    autoComplete="reason"
                    placeholder={`${t("ChooseReason")}`}
                    select
                    error={
                      formState.errors && formState.errors.reason ? true : false
                    }
                    helperText={
                      formState.errors && formState.errors.reason
                        ? formState.errors.reason.message
                        : null
                    }
                  >
                    <MenuItem disabled value="">
                      {`${t("ChooseReason")}`}
                    </MenuItem>
                    {ReasonExitInterview.map((option) => (
                      <MenuItem
                        key={option.idReasonExitInterview}
                        value={option.name}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextFieldTheme>
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <Typography marginBottom={1}>{`${t("EffectiveDate")}`}</Typography>
            <Controller
              name="dateStart"
              control={control}
              render={({ field, formState }) => (
                <DatePickerCustom
                  {...field}
                  views={["year", "month", "day"]}
                  inputFormat="DD MMMM YYYY"
                  minDate={dayjs().add(30, "day")}
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: `${t("DayMonthYear(AD)")}`,
                        readOnly: true,
                      }}
                      onBlur={field.onBlur}
                      error={formState.errors[field.name] ? true : false}
                      helperText={
                        formState.errors[field.name]
                          ? formState.errors[field.name].message
                          : null
                      }
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>

        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleClose}>
            {`${t("Cancel")}`}
          </ButtonBlue>
          <ButtonBlue
            variant="contained"
            onClick={handleSubmit(onSubmit, (errors) => {
              console.log(errors);
            })}
            autoFocus
          >
            {`${t("SaveData")}`}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogProbation;
