import React, { useEffect, useState } from "react";
import { Avatar, Box, Card, Grid, Icon, MenuItem, Stack, styled, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import CardData from "../../shared/dashboard/CardData";
import Utils from "../../../../utils";
import ChartAreaCustom from "../../shared/dashboard/ChartArea";
import dayjs from "dayjs";
import CardDashboard from "../../shared/dashboard/CardDashboard";
import { useSelector } from "react-redux";
import ChartDonutCustom from "../../shared/dashboard/ChartDonut";
import OTChart from "../../shared/dashboard/OTChart";
// import Chart from "react-apexcharts";
import GaugeChart from "react-gauge-chart";
import { getOverviewOTPayroll } from "../../../../actions/dashboard";
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../utils/userData";
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import TextFieldTheme from "../../shared/general/TextFieldTheme";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StyledRoot = styled(Box)({
  "& .top-employee": {
    padding: "8px",
    // paddingTop: "16px",
    display: "flex",
    alignItems: "center",
    border: "1px solid #ececec",
    borderRadius: "4px",
  },
});

const StyledCard = styled(Box)({
  height: "100%",
  "& .MuiCard-root": {
    height: "inherit",
    boxSizing: "border-box",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    padding: "16px",
    borderRadius: "12px",
  },
  "& .card-data-title-container": {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    "& .title": {
      flexGrow: "1",
      fontSize: "20px",
      color: "#919eab",
    },
  },
  "& .value-container": {
    minHeight: "96px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .dataValue": {
    fontSize: "48px"
  },
  "& .dataUnit": {
    fontSize: "16px",
    color: "#919eab",
  },
  "& .dynamicFontSize-small": {
    fontSize: "30px",
  },
  "& .dynamicFontSize-medium": {
    fontSize: "33px",
  },
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));

const OvertimePanel = (props) => {
  const { month, year, selectedCompany, dashboardOverview } = props;
  const { t, i18n } = useTranslation();
  const [otPayroll, setOtPayroll] = useState(null);
  const [filteredType, setFilteredType] = useState(1);
  const { result: dashboardOvertime } = useSelector(
    (state) => state.dashboardOvertime
  );

  const fetchedOTPayrollData = async (query) => {
    try {
      const result = await getOverviewOTPayroll(query);
      if (result && result.status === 200 && result.data) {
        setOtPayroll(result.data);
      };
    } catch (error) {
      console.error(error);
    };
  };

  useEffect(() => {
    if (selectedCompany) {
      fetchedOTPayrollData({
        idCompany: selectedCompany && selectedCompany.idCompany,
        month: month,
        year: year
      });
    };
  }, [selectedCompany]);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: `${t("OvertimeExpensesComparedToTotalSalary")}`,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    if (filteredType === 1) {
      if (otPayroll && otPayroll.sectionList && otPayroll.sectionList.length > 0) {
        const newColors = otPayroll.sectionList.map(() => generateRandomColor());

        setChartData(prevState => ({
          ...prevState,
          labels: otPayroll.sectionList.map(item => item.sectionName),
          datasets: [
            {
              ...prevState.datasets[0],
              data: otPayroll.sectionList.map(item => item.percentOfOT),
              backgroundColor: newColors,
              borderColor: newColors,
            },
          ],
        }));
      }
    } else {
      if (otPayroll && otPayroll.departmentList && otPayroll.departmentList.length > 0) {
        const newColors = otPayroll.departmentList.map(() => generateRandomColor());

        setChartData(prevState => ({
          ...prevState,
          labels: otPayroll.departmentList.map(item => item.departmentName),
          datasets: [
            {
              ...prevState.datasets[0],
              data: otPayroll.departmentList.map(item => item.percentOfOT),
              backgroundColor: newColors,
              borderColor: newColors,
            },
          ],
        }));
      }
    }
  }, [otPayroll, filteredType]);

  const handleSelectType = (e) => {
    setFilteredType(e.target.value);
  };

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CardData
                    title={`${t("Employee")}`}
                    // dataValue={Utils.numberWithCommasWithoutDot(
                    //   dashboardOvertime.employeeTotal
                    // )}
                    dataValue={Utils.numberWithCommasWithoutDot(
                      dashboardOvertime.companyEmployeeInfo.count
                    )}
                    dataUnit={`${t("Person")}`}
                    dynamicFontSize
                    titleIcon={
                      <Icon
                        baseClassName="fal"
                        className="fa-users"
                        color="primary"
                        style={{ display: "inline-table" }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledCard>
                    <Card>
                      <Box className="card-data-title-container">
                        <Typography className="title">{`${t("OTTotal")}`}</Typography>
                        <Icon
                          className="fal fa-hand-holding-usd"
                          color="warning"
                          style={{ display: "inline-table", color: "#ffcd38" }}
                        />
                      </Box>
                      <Box>
                        <Box className="value-container">
                          <Typography textAlign="center">
                            <span className={" dataValue"}>{otPayroll && otPayroll.totalCompanyOT}</span> <span className="dataUnit">{`${t("Baht")}`}</span>
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </StyledCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardData
                    title={`${t("Overtime")}`}
                    dataValue={Utils.numberWithCommas(
                      Number(
                        Object.keys(dashboardOvertime.otTotal)
                          .reduce(
                            (a, b) =>
                              a + dashboardOvertime.otTotal[b].hourTotal,
                            0
                          )
                          .toFixed(2)
                      )
                    )}
                    dataUnit={`${t("Unit.Hours")}`}
                    dynamicFontSize
                    titleIcon={
                      <Icon
                        className="fal fa-hand-holding-usd"
                        color="warning"
                        style={{ display: "inline-table", color: "#ffcd38" }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <CardData
                title={`${t("OTOVer36Hr")}`}
                dataValue={dashboardOvertime.employeeOTOver36Total.weekInMonth.map(
                  (week) => {
                    return {
                      name: `${dayjs(week.weekStartDateText).format(
                        "dd D MMM"
                      )} - ${dayjs(week.weekEndDateText).format("dd D MMM")}`,
                      value: week.over36HoursEmployeeTotal,
                    };
                  }
                )}
                dataUnit={`${t("Person")}`}
                dynamicFontSize
                select
                titleIcon={
                  <Icon
                    className="far fa-alarm-exclamation"
                    color="error"
                    style={{ display: "inline-table" }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>

        {dashboardOvertime && dashboardOvertime.otTotalByDepartment && dashboardOvertime.otTotalByDepartment.length > 0 && (
          <Grid item xs={12}>
            <CardDashboard>
              <Typography marginBottom="16px">
                {t("OTExpensesbyDepartment")}
              </Typography>
              <OTChart
                data={dashboardOvertime.otTotalByDepartment.map((s) => s.totalOT)}
                labels={dashboardOvertime.otTotalByDepartment.map(
                  (s) => s.departmentName
                )}
              />
            </CardDashboard>
          </Grid>
        )}

        <Grid item xs={12}>
          <CardDashboard>
            <Typography marginBottom="16px">
              {t("OTTotal")} ({t("Yearly")} {year})
            </Typography>
            <ChartAreaCustom
              series={[
                {
                  name: `${t("OTTotal")}`,
                  data: dashboardOvertime.otTotalAllYear.map((s) => {
                    return {
                      x: dayjs()
                        .month(s.month - 1)
                        .format("MMM"),
                      y: s.payTotal,
                    };
                  }),
                },
              ]}
            />
          </CardDashboard>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <CardDashboard>
                <Typography marginBottom="16px">
                  {t("OTTotalByType")}
                </Typography>
                <ChartDonutCustom
                  series={Object.keys(dashboardOvertime.otTotal).map(
                    (ot) => dashboardOvertime.otTotal[ot].payTotal
                  )}
                  labels={Object.keys(dashboardOvertime.otTotal).map(
                    (ot) => `OT x${ot}`
                  )}
                />
              </CardDashboard>
              {/* <StyledCard style={{height: "100%"}}>
                <CardContent style={{padding: 16}}>
                  <Typography>OT แต่ละประเภท</Typography>
                  <ChartDonutCustom
                    series={Object.keys(dashboardOvertime.otTotal).map(ot => dashboardOvertime.otTotal[ot].payTotal)}
                    labels={Object.keys(dashboardOvertime.otTotal).map(ot => `OT x${ot}`)}
                  />
                </CardContent>
              </StyledCard> */}
            </Grid>

            <Grid item xs={12} lg={4}>
              <CardDashboard>
                <Typography marginBottom="16px">
                  {t("Top5OTOver36Hrs")}
                </Typography>
                {dashboardOvertime.employeeOTOver36Total.top5EmployeeOver36
                  .length > 0 && (
                    <Box
                      style={{
                        display: "flex",
                        gap: "16px",
                        flexDirection: "column",
                      }}
                    >
                      {dashboardOvertime.employeeOTOver36Total.top5EmployeeOver36.map(
                        (e, index) => (
                          <Box key={index} className="top-employee">
                            <Icon
                              className={`fa-solid fa-square-${index + 1}`}
                              color="primary"
                              style={{
                                display: "inline-table",
                                color: index <= 2 ? "#ffcd38" : undefined,
                              }}
                            />
                            <Box
                              style={{
                                flexGrow: "1",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Avatar
                                src={e.imageProfile}
                                style={{
                                  marginRight: "8px",
                                  width: "32px",
                                  height: "32px",
                                }}
                              />
                              <Typography
                                align="center"
                                variant="h6"
                                fontSize={16}
                              >
                                {getUserFullName(e)}
                              </Typography>
                            </Box>
                            <Typography>
                              {e.over36Total} {t("Unit.Times")}
                            </Typography>
                          </Box>
                        )
                      )}
                    </Box>
                  )}
                {dashboardOvertime.employeeOTOver36Total.top5EmployeeOver36
                  .length <= 0 && (
                    <Typography textAlign="center" color="text.third">
                      {t("NoOTOver36Hrs")}
                    </Typography>
                  )}
              </CardDashboard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <CardDashboard>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
              <Typography>
                {t("%ofWorkingTimeHours")}
              </Typography>

              <TextFieldTheme
                variant="outlined"
                select
                label={t("ChooseField")}
                sx={{ width: 200 }}
                value={filteredType}
                onChange={(e) => handleSelectType(e)}
              >
                <MenuItem value={1}>{t("Section")}</MenuItem>
                <MenuItem value={2}>{t("Department")}</MenuItem>
              </TextFieldTheme>
            </Box>

            {filteredType === 1 ? (
              <>
                {otPayroll && otPayroll.sectionList && otPayroll.sectionList.length > 4 ? (
                  <Bar data={chartData} options={options} />
                ) : (
                  <Grid
                    container
                    justifyContent={otPayroll && otPayroll.sectionList && otPayroll.sectionList.length <= 2 ? "center" : "space-around"}
                    alignItems="center"
                    spacing={2}
                  >
                    {otPayroll && otPayroll.sectionList && otPayroll.sectionList.map((item, index) => (
                      <Grid
                        item
                        xs={otPayroll.sectionList.length === 1 ? 6 : 12}
                        sm={otPayroll.sectionList.length <= 2 ? 6 : 4}
                        key={index}
                      >
                        <Typography variant="h6" align="center">
                          {item.sectionName}
                        </Typography>
                        <GaugeChart
                          nrOfLevels={420}
                          arcsLength={[0.2, 0.5, 0.3]}
                          colors={["#47c2c2", "#ffcd56", "#ff5722"]}
                          percent={item.percentOfOT > 0 ? item.percentOfOT / 100 : 0.00}
                          arcPadding={0.02}
                          textColor="#222f3e"
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </>
            ) : (
              <>
                {otPayroll && otPayroll.departmentList && otPayroll.departmentList.length > 4 ? (
                  <Bar data={chartData} options={options} />
                ) : (
                  <Grid
                    container
                    justifyContent={otPayroll && otPayroll.departmentList && otPayroll.departmentList.length <= 2 ? "center" : "space-around"}
                    alignItems="center"
                    spacing={2}
                  >
                    {otPayroll && otPayroll.departmentList && otPayroll.departmentList.map((item, index) => (
                      <Grid
                        item
                        xs={otPayroll.departmentList.length === 1 ? 6 : 12}
                        sm={otPayroll.departmentList.length <= 2 ? 6 : 4}
                        key={index}
                      >
                        <Typography variant="h6" align="center">
                          {item.departmentName}
                        </Typography>
                        <GaugeChart
                          nrOfLevels={420}
                          arcsLength={[0.2, 0.5, 0.3]}
                          colors={["#47c2c2", "#ffcd56", "#ff5722"]}
                          percent={item.percentOfOT > 0 ? item.percentOfOT / 100 : 0.00}
                          arcPadding={0.02}
                          textColor="#222f3e"
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </>
            )}
          </CardDashboard>
        </Grid>

        <Grid item xs={12}>
          <CardDashboard>
            <Typography sx={{ mb: 1 }} fontSize={16}>
              {t("Top10EmployeesWithTheHighestOTHours")}
            </Typography>
            {otPayroll && otPayroll.top10OTResult && otPayroll.top10OTResult.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledCellHeader>#</StyledCellHeader>
                    <StyledCellHeader>{t("FullName")}</StyledCellHeader>
                    <StyledCellHeader>{t("Division")}</StyledCellHeader>
                    <StyledCellHeader>{t("Department")}</StyledCellHeader>
                    <StyledCellHeader>{t("Section")}</StyledCellHeader>
                    <StyledCellHeader>{t("Position")}</StyledCellHeader>
                    <StyledCellHeader>{t("TotalOvertimeHours")}</StyledCellHeader>
                    <StyledCellHeader>{t("%ofWorkingTimeHours")}</StyledCellHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {otPayroll.top10OTResult.map((item, index) => (
                    <TableRow key={index}>
                      <StyledCellContent>{index + 1}.</StyledCellContent>
                      <StyledCellContent align="center" >
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                          <Avatar alt="image" src={`${item.imageProfile}`} sx={{ width: 40, height: 40 }} />
                          <Typography>
                            {item.firstname_TH} {item.lastname_TH}
                          </Typography>
                        </Stack>
                      </StyledCellContent>
                      <StyledCellContent>{item.divisionName || "-"}</StyledCellContent>
                      <StyledCellContent>{item.departmentName || "-"}</StyledCellContent>
                      <StyledCellContent>{item.sectionName || "-"}</StyledCellContent>
                      <StyledCellContent>{item.positionName || "-"}</StyledCellContent>
                      <StyledCellContent>
                        <Typography fontWeight={"500"}>
                          {item.totalOThours || "-"} {t("Unit.Hours")}
                        </Typography>
                      </StyledCellContent>
                      <StyledCellContent>
                        <Typography fontWeight={"500"}>
                          {item.percentOT}%
                        </Typography>
                      </StyledCellContent>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography textAlign="center" color="text.third">
                {t("NoData")}
              </Typography>
            )}
          </CardDashboard>
        </Grid>

        <Grid item xs={12}>
          <CardDashboard>
            <Typography sx={{ mb: 1 }} fontSize={16}>
              {t("Top10EmployeesWithTheHighestOTExpensesComparedSalary")}
            </Typography>
            {otPayroll && otPayroll.top10OTPayment && otPayroll.top10OTPayment.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledCellHeader>#</StyledCellHeader>
                    <StyledCellHeader>{t("FullName")}</StyledCellHeader>
                    <StyledCellHeader>{t("Division")}</StyledCellHeader>
                    <StyledCellHeader>{t("Department")}</StyledCellHeader>
                    <StyledCellHeader>{t("Section")}</StyledCellHeader>
                    <StyledCellHeader>{t("Position")}</StyledCellHeader>
                    <StyledCellHeader>{t("TotalOTPayment")}</StyledCellHeader>
                    <StyledCellHeader>{t("%OTexpensesComparedSalary")}</StyledCellHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {otPayroll.top10OTPayment.map((item, index) => (
                    <TableRow key={index}>
                      <StyledCellContent>{index + 1}.</StyledCellContent>
                      <StyledCellContent align="center" >
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                          <Avatar alt="image" src={`${item.imageProfile}`} sx={{ width: 40, height: 40 }} />
                          <Typography>
                            {item.firstname_TH} {item.lastname_TH}
                          </Typography>
                        </Stack>
                      </StyledCellContent>
                      <StyledCellContent>{item.divisionName || "-"}</StyledCellContent>
                      <StyledCellContent>{item.departmentName || "-"}</StyledCellContent>
                      <StyledCellContent>{item.sectionName || "-"}</StyledCellContent>
                      <StyledCellContent>{item.positionName || "-"}</StyledCellContent>
                      <StyledCellContent>
                        <Typography fontWeight={"500"}>
                          {item.totalOT ? item.totalOT.toLocaleString() : "-"} {t("CurrencyUnit.THB")}
                        </Typography>
                      </StyledCellContent>
                      <StyledCellContent>
                        <Typography fontWeight={"500"}>
                          {item.salaryValue > 0 ? (((item.totalOT || 0) / item.salaryValue) * 100).toFixed(2) : "0.00"}%
                        </Typography>
                      </StyledCellContent>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography textAlign="center" color="text.third">
                {t("NoData")}
              </Typography>
            )}
          </CardDashboard>
        </Grid>

      </Grid>
    </StyledRoot >
  );
};

export default OvertimePanel;