import React, { useEffect, useState } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import { addFamily } from "../../../../../../actions/family";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";
import { getWorkInfoByidCompany } from "../../../../../../actions/company";
import { updateUser } from "../../../../../../actions/user";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../../../shared/date/datePicker";
import AlertResponse from "../../../../shared/general/AlertResponse";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px",
  },
});

const DialogWorkingLocation = (props) => {
  const dispatch = useDispatch();

  const { drawerConfig, onClose } = props;
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: workTypesList } = useSelector((state) => state.workTypes);
  const [selectedWorkType, setSelectedWorkType] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const daysList = [
    { day: "Monday", name: t("Monday") },
    { day: "Tuesday", name: t("Tuesday") },
    { day: "Wednesday", name: t("Wednesday") },
    { day: "Thursday", name: t("Thursday") },
    { day: "Friday", name: t("Friday") },
    { day: "Saturday", name: t("Saturday") },
    { day: "Sunday", name: t("Sunday") }
  ];

  const generateDefaultValues = () => {
    const defaultValues = {
      employmentTime: "",
      filed_office: "",
      workingLocation: "",
      methodAttendance: "",
      mainWorkingLocationPoint: "",
      isFinger: "1",
      isFingerAffectPayroll: "1",
      customWorkingLocationPoint: [],
      isHomeAttendance: 1,
    };

    daysList.forEach(item => {
      defaultValues[`isFingerCustom${item.day}`] = "1";
      defaultValues[`customMethodAttendance${item.day}`] = "main";
    });

    return defaultValues;
  };

  const defaultValues = generateDefaultValues();

  const validateYupSchema = yup.object({
    employmentTime: yup.string().required("กรุณากรอกข้อมูล"),
    filed_office: yup.string().required("กรุณากรอกข้อมูล"),
    workingLocation: yup.string().required("กรุณากรอกข้อมูล"),
    methodAttendance: yup.string().required("กรุณากรอกข้อมูล"),
    isFinger: yup.string().required("กรุณากรอกข้อมูล"),
    isFingerAffectPayroll: yup.string().required("กรุณากรอกข้อมูล"),
    isHomeAttendance: yup.string().required("กรุณากรอกข้อมูล"),
    mainWorkingLocationPoint: yup.string().when("methodAttendance", {
      is: "main",
      then: yup.string().required("กรุณากรอกข้อมูล"),
    }),
    ...daysList.reduce((schema, item) => {
      const isFingerCustomKey = `isFingerCustom${item.day}`;
      const customMethodAttendanceKey = `customMethodAttendance${item.day}`;
      const mainWorkingLocationKey = `mainWorkingLocationPoint${item.day}`

      schema[isFingerCustomKey] = yup.string(); // Remove required validation here

      schema[customMethodAttendanceKey] = yup.string().when(["methodAttendance", isFingerCustomKey], {
        is: (methodAttendance, isFingerCustom) => methodAttendance === "Custom" && isFingerCustom !== "0",
        then: yup.string().required("กรุณากรอกข้อมูล"),
        otherwise: yup.string()
      });

      schema[mainWorkingLocationKey] = yup.string().when([customMethodAttendanceKey], {
        is: (customMethodAttendance) => customMethodAttendance === "main",
        then: yup.string().required("กรุณากรอกข้อมูล"),
        otherwise: yup.string()
      });

      return schema;
    }, {}),
    customWorkingLocationPoint: yup
      .array()
      .of(yup.string())
      .when("methodAttendance", {
        is: "Custom",
        then: yup.array().min(1, "กรุณากรอกข้อมูล"),
      }),
    // birthday: yup.date().nullable().required("กรุณากรอกข้อมูล"),
  });

  const useHookForm = useForm({
    defaultValues,
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  useEffect(() => {
    if (drawerConfig.isOpen === true) {
      dispatch(getWorkInfoByidCompany({ idCompany: employeeProfile.idCompany }));
      if (drawerConfig.isEdit) {
        useHookForm.reset({
          employmentTime: drawerConfig.data.employmentTime || "",
          filed_office: drawerConfig.data.filed_office || "",
          workingLocation: drawerConfig.data.workingLocation || "",
          methodAttendance: drawerConfig.data.methodAttendance || "",
          mainWorkingLocationPoint:
            drawerConfig.data.mainWorkingLocationPoint || "",
          isFinger:
            drawerConfig.data.isFinger === null
              ? ""
              : String(drawerConfig.data.isFinger),
          isFingerAffectPayroll:
            drawerConfig.data.isFingerAffectPayroll === null
              ? ""
              : String(drawerConfig.data.isFingerAffectPayroll),
          isFingerCustomMonday: drawerConfig.data.isFingerCustomMonday || "",
          customMethodAttendanceMonday: drawerConfig.data.customMethodAttendanceMonday || "",
          mainWorkingLocationPointMonday: drawerConfig.data.mainWorkingLocationPointMonday || "",
          isFingerCustomTuesday: drawerConfig.data.isFingerCustomTuesday || "",
          customMethodAttendanceTuesday: drawerConfig.data.customMethodAttendanceTuesday || "",
          mainWorkingLocationPointTuesday: drawerConfig.data.mainWorkingLocationPointTuesday || "",
          isFingerCustomWednesday: drawerConfig.data.isFingerCustomWednesday || "",
          customMethodAttendanceWednesday: drawerConfig.data.customMethodAttendanceWednesday || "",
          mainWorkingLocationPointWednesday: drawerConfig.data.mainWorkingLocationPointWednesday || "",
          isFingerCustomThursday: drawerConfig.data.isFingerCustomThursday || "",
          customMethodAttendanceThursday: drawerConfig.data.customMethodAttendanceThursday || "",
          mainWorkingLocationPointThursday: drawerConfig.data.mainWorkingLocationPointThursday || "",
          isFingerCustomFriday: drawerConfig.data.isFingerCustomFriday || "",
          customMethodAttendanceFriday: drawerConfig.data.customMethodAttendanceFriday || "",
          mainWorkingLocationPointFriday: drawerConfig.data.mainWorkingLocationPointFriday || "",
          isFingerCustomSaturday: drawerConfig.data.isFingerCustomSaturday || "",
          customMethodAttendanceSaturday: drawerConfig.data.customMethodAttendanceSaturday || "",
          mainWorkingLocationPointSaturday: drawerConfig.data.mainWorkingLocationPointSaturday || "",
          isFingerCustomSunday: drawerConfig.data.isFingerCustomSunday || "",
          customMethodAttendanceSunday: drawerConfig.data.customMethodAttendanceSunday || "",
          mainWorkingLocationPointSunday: drawerConfig.data.mainWorkingLocationPointSunday || "",
          isHomeAttendance: drawerConfig.data.isHomeAttendance ? 1 : 0,
          customWorkingLocationPoint: drawerConfig.data.customWorkingLocationPoint || [],
          startDate: drawerConfig.data.startDate || new Date(),
          // endDate: drawerConfig.data.endDate || null
        });
      } else {
        useHookForm.reset({
          employmentTime: "",
          filed_office: "",
          workingLocation: "",
          methodAttendance: "",
          mainWorkingLocationPoint: "",
          isFinger: "",
          isFingerAffectPayroll: "",
          isFingerCustomMonday: "",
          customMethodAttendanceMonday: "",
          mainWorkingLocationPointMonday: "",
          isFingerCustomTuesday: "",
          customMethodAttendanceTuesday: "",
          mainWorkingLocationPointTuesday: "",
          isFingerCustomWednesday: "",
          customMethodAttendanceWednesday: "",
          mainWorkingLocationPointWednesday: "",
          isFingerCustomThursday: "",
          customMethodAttendanceThursday: "",
          mainWorkingLocationPointThursday: "",
          isFingerCustomFriday: "",
          customMethodAttendanceFriday: "",
          mainWorkingLocationPointFriday: "",
          isFingerCustomSaturday: "",
          customMethodAttendanceSaturday: "",
          mainWorkingLocationPointSaturday: "",
          isFingerCustomSunday: "",
          customMethodAttendanceSunday: "",
          mainWorkingLocationPointSunday: "",
          isHomeAttendance: 0,
          customWorkingLocationPoint: [],
          startDate: "",
          // endDate: ""
        });
      }
    }
  }, [drawerConfig.isOpen]);

  useEffect(() => {
    daysList.forEach(item => {
      const isFingerCustomValue = useHookForm.watch(`isFingerCustom${item.day}`);
      if (isFingerCustomValue === "0") {
        if (useHookForm.getValues(`customMethodAttendance${item.day}`) !== "") {
          useHookForm.setValue(`customMethodAttendance${item.day}`, "");
        }
        if (useHookForm.getValues(`mainWorkingLocationPoint${item.day}`) !== "") {
          useHookForm.setValue(`mainWorkingLocationPoint${item.day}`, "");
        }
      }
    });
  }, [daysList, useHookForm]);

  const handleChange = (field, value) => {
    if (useHookForm.getValues(field) !== value) {
      useHookForm.setValue(field, value);
    }
  };

  useEffect(() => {
    daysList.forEach(item => {
      const isFingerCustomValue = useHookForm.watch(`isFingerCustom${item.day}`);
      if (isFingerCustomValue === "0") {
        handleChange(`customMethodAttendance${item.day}`, "");
        handleChange(`mainWorkingLocationPoint${item.day}`, "");
      }
    });
  }, [daysList, useHookForm]);

  const onSubmit = async (data) => {
    try {
      const formData = {
        ...data,
        idEmployees: employeeProfile.idEmployees,
        UpdateBy: userProfile.idEmployees,
        updateDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        previousMethodAttendance: drawerConfig.data.methodAttendance,
        previousStartDate: drawerConfig.data.startDate,
      };

      Object.keys(formData).map((k) => {
        if (formData[k] === "") {
          formData[k] = null;
        } else {
          formData[k] = formData[k];
        }
      });

      const result = await dispatch(updateUser(formData));
      if (result && result.status === 200) {
        dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
        onClose();
      }
    } catch (error) {
      console.error(error);
    };
  };

  useEffect(() => {
    let matchedData = [];
    if ((drawerConfig && drawerConfig.data && drawerConfig.data.customWorkingLocationPoint) && (workTypesList && workTypesList.groupGpsLocations)) {
      drawerConfig.data.customWorkingLocationPoint.map(item => {
        const findTheSameId = workTypesList.groupGpsLocations.find(e => e.idGroupGpsLocations === item);

        matchedData.push(findTheSameId);
      });

      setSelectedWorkType(matchedData);
    }
  }, [drawerConfig, workTypesList]);

  return (
    <DrawerCustom
      title={`${t("EditWorkInfo")}`}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "600px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="field-label">
                {t("EmploymentTime")}
              </Typography>
              <Controller
                name="employmentTime"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    select
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                  >
                    <MenuItem value="Full time">Full time</MenuItem>
                    <MenuItem value="Part time">Part time</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">
                {t("FieldOffice")}
              </Typography>
              <Controller
                name="filed_office"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    select
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                  >
                    <MenuItem value="Field">Field</MenuItem>
                    <MenuItem value="Office">Office</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className="field-label">
                {t("WorkingLocation")}
              </Typography>
              <Controller
                name="workingLocation"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">
                {t("MethodAttendance")}
              </Typography>
              <Controller
                name="methodAttendance"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    select
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      useHookForm.setValue("mainWorkingLocationPoint", "");
                      daysList.forEach(item => {
                        useHookForm.setValue(`isFingerCustom${item.day}`, "");
                        useHookForm.setValue(`customMethodAttendance${item.day}`, "");
                        useHookForm.setValue(`mainWorkingLocationPoint${item.day}`, "");
                      });
                    }}
                  >
                    <MenuItem value="main">{t("OneMainWorkingPoint")}</MenuItem>
                    <MenuItem value="all">{t("AllWorkingPoint")}</MenuItem>
                    <MenuItem value="point">{t("AllLocation(coordinates)")}</MenuItem>
                    {userProfile && userProfile.idCompany === 1 && (
                      <MenuItem value="Custom">{t("Custom")}</MenuItem>
                    )}
                  </TextFieldTheme>
                )}
              />
            </Grid>

            {useHookForm.watch("methodAttendance") === "Custom" && (
              <>
                <Grid item xs={12}>
                  <Typography className="field-label">
                    {t("CustomLocation")}
                  </Typography>
                  <Controller
                    name="customWorkingLocationPoint"
                    control={useHookForm.control}
                    render={({ field }) => (
                      <Autocomplete
                        multiple
                        options={
                          workTypesList && workTypesList.groupGpsLocations
                            ? workTypesList.groupGpsLocations
                            : []
                        }
                        getOptionLabel={(option) => `${option.name}`}
                        renderOption={(props, option) => (
                          <MenuItem {...props} key={option.idGroupGpsLocations}>
                            <Box>
                              <Typography>{`${option.name}`}</Typography>
                            </Box>
                          </MenuItem>
                        )}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            onBlur={field.onBlur}
                            error={!!useHookForm.formState.errors[field.name]}
                            helperText={
                              useHookForm.formState.errors[field.name]
                                ? useHookForm.formState.errors[field.name].message
                                : null
                            }
                          />
                        )}
                        value={
                          (workTypesList &&
                            workTypesList.groupGpsLocations &&
                            workTypesList.groupGpsLocations.filter(
                              (option) =>
                                Array.isArray(field.value) &&
                                field.value.includes(option.idGroupGpsLocations)
                            )) ||
                          []
                        }
                        onChange={(_, value) => {
                          field.onChange(
                            value.map((item) => item.idGroupGpsLocations)
                          );
                          setSelectedWorkType(value)
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Stack justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
                    <Typography className="field-label">
                      {t("StartDate")}
                    </Typography>
                    {/* <ButtonBlue
                      variant="text"
                      onClick={() => useHookForm.setValue('endDate', null)}  // Clear end date value
                      disabled
                    >
                      Clear
                    </ButtonBlue> */}
                  </Stack>
                  <DatePickerCustom
                    name="startDate"
                    inputFormat="DD MMM YYYY"
                    views={["year", "month", "day"]}
                    value={useHookForm.watch('startDate')}
                    onChange={(newValue) => {
                      // const selectedDate = new Date(newValue);
                      // const currentDate = new Date();

                      // currentDate.setHours(0, 0, 0, 0);

                      // const dayOfWeek = selectedDate.getDay();
                      // if (dayOfWeek !== 1) {
                      //   handleOpenAlert();
                      //   handleChangeAlertType(t("PleaseSelectMonday"));
                      // } else if (selectedDate < currentDate) {
                      //   handleOpenAlert();
                      //   handleChangeAlertType(t("PleaseNotSelectPastDay"));
                      // } else {
                      //   useHookForm.setValue('startDate', newValue);
                      // }

                      useHookForm.setValue('startDate', newValue);
                    }}
                  />
                </Grid>

                {/* <Grid item xs={6}>
                  <Stack justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
                    <Typography className="field-label">
                      {t("EndDate")}
                    </Typography>
                    <ButtonBlue
                      variant="text"
                      onClick={() => useHookForm.setValue('endDate', null)}
                    >
                      Clear
                    </ButtonBlue>
                  </Stack>
                  <DatePickerCustom
                    name="endDate"
                    inputFormat="DD MMM YYYY"
                    views={["year", "month", "day"]}
                    minDate={useHookForm.watch('startDate') || null}
                    value={useHookForm.watch('endDate') || null}
                    onChange={(newValue) => {
                      if (newValue === null) {
                        useHookForm.setValue('endDate', null);
                      } else {
                        const dayOfWeek = new Date(newValue).getDay();
                        if (dayOfWeek === 0) {
                          useHookForm.setValue('endDate', newValue);
                        } else {
                          handleOpenAlert();
                          handleChangeAlertType(t("PleaseSelectSunday"));
                        }
                      }
                    }}
                    clearable
                  />
                </Grid> */}

                {daysList.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography className="field-label">{t("FingerScan")} {t(`${item.name}`)}</Typography>
                        <Controller
                          name={`isFingerCustom${item.day}`}
                          control={useHookForm.control}
                          render={({ field }) => (
                            <TextFieldTheme
                              {...field}
                              error={
                                useHookForm.formState.errors[field.name] ? true : false
                              }
                              helperText={
                                useHookForm.formState.errors[field.name]
                                  ? useHookForm.formState.errors[field.name].message
                                  : null
                              }
                              select
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                useHookForm.setValue("customMainWorkingLocationPoint", "");
                                useHookForm.setValue(`customMethodAttendance${item.day}`, "");
                                useHookForm.setValue(`mainWorkingLocationPoint${item.day}`, "");
                              }}
                            >
                              <MenuItem value="1">{t("Yes")}</MenuItem>
                              <MenuItem value="0">{t("No")}</MenuItem>
                            </TextFieldTheme>
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography className="field-label">
                          {t("MethodAttendance")} {t(`${item.name}`)}
                        </Typography>
                        <Controller
                          name={`customMethodAttendance${item.day}`}
                          control={useHookForm.control}
                          render={({ field }) => (
                            <TextFieldTheme
                              {...field}
                              error={Boolean(useHookForm.formState.errors[field.name])}
                              helperText={
                                useHookForm.formState.errors[field.name]
                                  ? useHookForm.formState.errors[field.name].message
                                  : null
                              }
                              select
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                useHookForm.setValue("isFinger", "0");
                                useHookForm.setValue(`mainWorkingLocationPoint${item.day}`, "");
                                if (useHookForm.watch(`isFingerCustom${item.day}`) === "0") {
                                  useHookForm.setValue("customMainWorkingLocationPoint", "");
                                  useHookForm.setValue(`customMethodAttendance${item.day}`, "");
                                  useHookForm.setValue(`mainWorkingLocationPoint${item.day}`, "");
                                };
                              }}
                              disabled={useHookForm.watch(`isFingerCustom${item.day}`) === "0" || !useHookForm.watch(`isFingerCustom${item.day}`)}
                            >
                              <MenuItem value="main">{t("OneMainWorkingPoint")}</MenuItem>
                              <MenuItem value="all">{t("AllWorkingPoint")}</MenuItem>
                              <MenuItem value="point">{t("AllLocation(coordinates)")}</MenuItem>
                            </TextFieldTheme>
                          )}
                        />
                      </Grid>

                      {
                        useHookForm.watch(`isFingerCustom${item.day}`) === "1" &&
                        useHookForm.watch(`customMethodAttendance${item.day}`) === "main" && (
                          <Grid item xs={12}>
                            <Typography className="field-label">
                              {t("MainLocation")} {t(`${item.name}`)}
                            </Typography>
                            <Controller
                              name={`mainWorkingLocationPoint${item.day}`}
                              control={useHookForm.control}
                              render={({ field }) => (
                                <Autocomplete
                                  options={
                                    selectedWorkType
                                      ? selectedWorkType
                                      : []
                                  }
                                  getOptionLabel={(option) => `${option.name}`}
                                  renderOption={(props, option) => (
                                    <MenuItem {...props} key={option.idGroupGpsLocations}>
                                      <Box>
                                        <Typography>{`${option.name}`}</Typography>
                                      </Box>
                                    </MenuItem>
                                  )}
                                  renderInput={(params) => (
                                    <TextFieldTheme
                                      {...params}
                                      onBlur={field.onBlur}
                                      error={
                                        useHookForm.formState.errors[field.name]
                                          ? true
                                          : false
                                      }
                                      helperText={
                                        useHookForm.formState.errors[field.name]
                                          ? useHookForm.formState.errors[field.name].message
                                          : null
                                      }
                                    />
                                  )}
                                  value={
                                    (selectedWorkType &&
                                      selectedWorkType.find(
                                        (option) =>
                                          option.idGroupGpsLocations === field.value
                                      )) ||
                                    null
                                  }
                                  onChange={(_, value) => {
                                    field.onChange(value ? value.idGroupGpsLocations : "");
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        )}
                    </Grid>
                  </Grid>
                ))}
              </>
            )}

            {useHookForm.watch("methodAttendance") === "main" && (
              <Grid item xs={12}>
                <Typography className="field-label">
                  {t("MainLocation")}
                </Typography>
                <Controller
                  name="mainWorkingLocationPoint"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <Autocomplete
                      options={
                        workTypesList && workTypesList.groupGpsLocations
                          ? workTypesList.groupGpsLocations
                          : []
                      }
                      getOptionLabel={(option) => `${option.name}`}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.idGroupGpsLocations}>
                          <Box>
                            <Typography>{`${option.name}`}</Typography>
                          </Box>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          onBlur={field.onBlur}
                          error={
                            useHookForm.formState.errors[field.name]
                              ? true
                              : false
                          }
                          helperText={
                            useHookForm.formState.errors[field.name]
                              ? useHookForm.formState.errors[field.name].message
                              : null
                          }
                        />
                      )}
                      value={
                        (workTypesList &&
                          workTypesList.groupGpsLocations &&
                          workTypesList.groupGpsLocations.find(
                            (option) =>
                              option.idGroupGpsLocations === field.value
                          )) ||
                        null
                      }
                      onChange={(_, value) => {
                        field.onChange(value ? value.idGroupGpsLocations : "");
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography className="field-label">
                {t("isHomeAttendance")}
              </Typography>
              <Controller
                name="isHomeAttendance"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    select
                  >
                    <MenuItem value={1}>{t("Yes")}</MenuItem>
                    <MenuItem value={0}>{t("No")}</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>

            {useHookForm.watch("methodAttendance") !== "Custom" && (
              <Grid item xs={12}>
                <Typography className="field-label">{t("FingerScan")}</Typography>
                <Controller
                  name="isFinger"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      error={
                        useHookForm.formState.errors[field.name] ? true : false
                      }
                      helperText={
                        useHookForm.formState.errors[field.name]
                          ? useHookForm.formState.errors[field.name].message
                          : null
                      }
                      select
                    >
                      <MenuItem value="1">{t("Yes")}</MenuItem>
                      <MenuItem value="0">{t("No")}</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>
            )}
            {/* <Grid item xs={12}>
              <Typography className="field-label">การบันทึกเวลามีผลต่อเงินเดือน</Typography>
              <Controller
                name="isFingerAffectPayroll"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    error={useHookForm.formState.errors[field.name]? true: false}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    select
                  >
                    <MenuItem value="1">ใช่</MenuItem>
                    <MenuItem value="0">ไม่ใช่</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid> */}
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue
                variant="text"
                onClick={onClose}
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </DrawerCustom>
  );
};

export default DialogWorkingLocation;
