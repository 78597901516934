import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Typography, Grid, FormControl, Select, MenuItem } from "@mui/material";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

import DrawerCustom from "../../../../shared/general/Drawer";
import { t } from "i18next";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFooter = styled("div")({
  padding: "16px 0px",
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogEdit = (props) => {
  const {t,i18n}  = useTranslation();
  const { open, setOpenEdit } = props;
  const today = dayjs().toDate();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );

  const [grade, setGrade] = useState("");

  const FindPerformance = (topicYear) => {
    if(employeeProfile.performance && employeeProfile.performance.length > 0){
      let indexResult = -1;
      employeeProfile.performance.map( (performance, index) => {
        let yearPerformance = new Date(performance.year).getFullYear();
        if(yearPerformance === parseInt(topicYear)){
          indexResult = index;
        }
      });
      return indexResult;
    }else {
      return -1;
    }
  }

  useEffect(() => {
    let index = FindPerformance(dayjs(today).subtract(1, "year").format("YYYY"));
    if(index >= 0){
      setGrade(employeeProfile.performance[index].evaluation);
    }else{
      setGrade("");
    }
  },[]);

  const onSubmitHandler = async() => {
    // let index = FindPerformance(dayjs(today).subtract(1, "year").format("YYYY"));
    // let data = {
    //   idEmpAsc:userProfile.idEmp,
    //   evaDate:dayjs().format("YYYY-MM-DD HH:mm:ss"),
    //   evaluation: grade
    // };
    // let result = await dispatch(updatePerformance(employeeProfile.performance[index].idPerformance, data));
    // if(result){
    //   dispatch(getEmployeeProfile(employeeProfile.idCompany, employeeProfile.idEmp));
    //   setOpenEdit(false)
    // }
  };

  return (
    <DrawerCustom
      title={t("EditPerformance")}
      anchor={"right"}
      open={open}
      onClose={() => setOpenEdit(false)}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} style={{ display:"flex", alignItems:"center" }}>
            <StyledContentLabel>
              {dayjs(today).subtract(1, "year").format("BBBB")}
            </StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={9}>
            <StyledFormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="grade"
                value={grade}
                onChange={(event) => {
                  setGrade(event.target.value);
                }}
              >
                <MenuItem value={""}>None</MenuItem>
                <MenuItem value={"A"}>A</MenuItem>
                <MenuItem value={"B"}>B</MenuItem>
                <MenuItem value={"C"}>C</MenuItem>
                <MenuItem value={"D"}>D</MenuItem>
              </Select>
            </StyledFormControl>
          </Grid>
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={()=>setOpenEdit(false)}>{t("Cancel")}</ButtonBlue>
          <ButtonBlue variant="contained" onClick={()=>onSubmitHandler()} autoFocus>
            {t("SaveData")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
