import { Avatar, Box, IconButton, styled, TablePagination, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Visibility } from "@mui/icons-material";
import ChipWithDrawCustom from "../../shared/chipWithDrawCustom";
import DialogShowComment from "./DialogShowComment";
import StatusRequest from "../../shared/general/stausRequest";
import TableCustom from "../../shared/tableCustom";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName, getUserPosition } from "../../../../utils/userData";
import RequestList from "../../user/boarding/resign/RequestList";

const BoxComment = styled(Box)({
  display: "flex",
  alignItems: "center",
  "& .textComment": {
    width: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
});

const RequestOverTime = (props) => {
  const { result: requestTimeList } = useSelector((state) => state.requestTime);
  const { result: requestTimeWithDrawList } = useSelector(
    (state) => state.requestTimeWithDraw
  );
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { selectedEmployee } = props;

  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);
  const [listPerPage, setListPerPage] = useState(10);
  const [historyRows, setHistoryRows] = useState([]);
  const [dialogComment, setDialogComment] = useState({
    isOpen: false,
    commentManager1: null,
    commentManager2: null,
  });

  const columns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>
              {getUserFullName(row)}
            </Typography>
            <Typography color="text.third" fontSize="14px">
              {getUserPosition(row)}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("Type")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Fragment>
          {row.idRequestTimeWithdraw && <ChipWithDrawCustom />}
          <Typography fontSize={14}>{`${t(row.name)}`}</Typography>
          <Fragment>
            {row.xWorkingDailyHoliday > 0 && (
              <Typography
                style={{ fontWeight: 500, fontSize: 14 }}
                color="text.third"
              >
                OT x {payrollSetting.xWorkingDailyHoliday} ={" "}
                {parseFloat(row.xWorkingDailyHoliday / 60).toFixed(2)}{" "}
                {t("Unit.ShortHours")}
              </Typography>
            )}
            {row.xWorkingMonthlyHoliday > 0 && (
              <Typography
                style={{ fontWeight: 500, fontSize: 14 }}
                color="text.third"
              >
                OT x {payrollSetting.xWorkingMonthlyHoliday} ={" "}
                {parseFloat(row.xWorkingMonthlyHoliday / 60).toFixed(2)}{" "}
                {t("Unit.ShortHours")}
              </Typography>
            )}
            {row.xOT > 0 && (
              <Typography
                style={{ fontWeight: 500, fontSize: 14 }}
                color="text.third"
              >
                OT x {payrollSetting.xOT} ={" "}
                {parseFloat(row.xOT / 60).toFixed(2)} {t("Unit.ShortHours")}
              </Typography>
            )}
            {row.xOTHoliday > 0 && (
              <Typography
                style={{ fontWeight: 500, fontSize: 14 }}
                color="text.third"
              >
                OT x {payrollSetting.xOTHoliday} ={" "}
                {parseFloat(row.xOTHoliday / 60).toFixed(2)} {t("Unit.ShortHours")}
              </Typography>
            )}
          </Fragment>
        </Fragment>
      ),
    },
    {
      name: `${t("Start")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.startText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}
          </Typography>
          <Typography>
            {dayjs(row.startText, "DD/MM/YYYY HH:mm").format("HH:mm")}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("End")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}
          </Typography>
          <Typography>
            {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("HH:mm")}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("Reason")}`,
      minWidth: "200px",
      cellRender: (row) => (
        <Box>
          <Typography fontSize="14px">{row.reasonName}</Typography>
          <Typography color="text.third" fontSize="14px">
            {row.otherReason}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("ManagerLV")} 1`,
      minWidth: "280px",
      width: "280px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          {(row.isDoubleApproval === 1 ||
            row.approvalLevel === 1 ||
            row.managerApprove) && (
              <>
                <StatusRequest
                  boxSize={40}
                  fontSize={22}
                  borderRadius={8}
                  status={getStatusManagerLV1(row)}
                />
                <Avatar
                  sx={{
                    marginLeft: "8px",
                    marginRight: "8px",
                    width: 40,
                    height: 40,
                    "& img": { objectFit: "contain" },
                  }}
                  src={row.imageURL}
                />
                <Box flexGrow={1}>
                  <Typography>
                    {getUserFullName({
                      firstname_TH: row.managerLV1_firstname_TH,
                      lastname_TH: row.managerLV1_lastname_TH,
                      firstname_EN: row.managerLV1_firstname_EN,
                      lastname_EN: row.managerLV1_lastname_EN,
                    })}
                  </Typography>
                  <Typography color="text.third" fontSize="14px">
                    {getUserPosition({
                      positionName: row.managerLV1_positionName,
                      positionName_EN: row.managerLV1_positionName_EN,
                    })}
                  </Typography>
                </Box>
              </>
            )}
        </Box>
      ),
    },
    {
      name: `${t("ManagerLV")} 2`,
      minWidth: "280px",
      width: "280px",
      cellRender: (row) => (
        <Fragment>
          {(row.isDoubleApproval === 1 || row.approvalLevel === 2) &&
            row.managerLV2ApproveBy && (
              <Box display="flex" alignItems="center">
                <StatusRequest
                  boxSize={40}
                  fontSize={22}
                  borderRadius={8}
                  status={getStatusManagerLV2(row)}
                />
                <Avatar
                  sx={{
                    marginLeft: "8px",
                    marginRight: "8px",
                    width: 40,
                    height: 40,
                    "& img": { objectFit: "contain" },
                  }}
                  src={row.imageURL}
                />
                <Box flexGrow={1}>
                  <Typography>
                    {getUserFullName({
                      firstname_TH: row.managerLV2_firstname_TH,
                      lastname_TH: row.managerLV2_lastname_TH,
                      firstname_EN: row.managerLV2_firstname_EN,
                      lastname_EN: row.managerLV2_lastname_EN,
                    })}
                  </Typography>
                  <Typography color="text.third" fontSize="14px">
                    {getUserPosition({
                      positionName: row.managerLV2_positionName,
                      positionName_EN: row.managerLV2_positionName_EN,
                    })}
                  </Typography>
                </Box>
              </Box>
            )}
        </Fragment>
      ),
    },
  ];

  const historyColumns = [
    {
      name: `${t("Status")}`,
      cellRender: (row) => (
        <StatusRequest
          boxSize={40}
          fontSize={22}
          borderRadius={8}
          status={getStatusRequest(row)}
        />
      ),
    },
    ...columns,
    {
      name: `${t("ApprovedDate")}`,
      minWidth: "150px",
      headerTextAlign: "center",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          {row.idRequestTimeWithdraw ? (
            row.approveDateText && (
              <Fragment>
                <Typography>
                  {dayjs(row.approveDateText, "DD/MM/YYYY HH:mm").format(
                    "D MMM BBBB"
                  )}
                </Typography>
                <Typography>
                  {dayjs(row.approveDateText, "DD/MM/YYYY HH:mm").format(
                    "HH:mm"
                  )}
                </Typography>
              </Fragment>
            )
          ) : row.isManagerLV1Approve !== null ||
            row.isManagerLV2Approve !== null ? (
            row.managerLV2ApproveDateText ? (
              <Fragment>
                <Typography>
                  {dayjs(
                    row.managerLV2ApproveDateText,
                    "DD/MM/YYYY HH:mm"
                  ).format("D MMM BBBB")}
                </Typography>
                <Typography>
                  {dayjs(
                    row.managerLV2ApproveDateText,
                    "DD/MM/YYYY HH:mm"
                  ).format("HH:mm")}
                </Typography>
              </Fragment>
            ) : (
              <Fragment>
                <Typography>
                  {dayjs(
                    row.managerLV1ApproveDateText,
                    "DD/MM/YYYY HH:mm"
                  ).format("D MMM BBBB")}
                </Typography>
                <Typography>
                  {dayjs(
                    row.managerLV1ApproveDateText,
                    "DD/MM/YYYY HH:mm"
                  ).format("HH:mm")}
                </Typography>
              </Fragment>
            )
          ) : null}
        </Box>
      ),
    },
    {
      name: `${t("ManagerComment")}`,
      minWidth: "200px",
      width: "200px",
      cellRender: (row) => (
        <Box>
          <BoxComment>
            {row.approvalLevel === 2 && row.commentManagerLV2 ? (
              <Typography className="textComment">
                {row.commentManagerLV2}
              </Typography>
            ) : (
              row.commentManagerLV1 && (
                <Typography className="textComment">
                  {row.commentManagerLV1}
                </Typography>
              )
            )}
            {(row.commentManagerLV2 || row.commentManagerLV1) && (
              <IconButton
                style={{ marginLeft: 8 }}
                onClick={() =>
                  setDialogComment({
                    isOpen: true,
                    commentManager1: row.commentManagerLV1,
                    commentManager2: row.commentManagerLV2,
                  })
                }
              >
                <Visibility />
              </IconButton>
            )}
          </BoxComment>
        </Box>
      ),
    },
  ];

  const getStatusRequest = (requestTime) => {
    if (requestTime.idRequestTimeWithdraw) {
      if (requestTime.isApprove === 1) {
        return 1;
      } else if (requestTime.isApprove === 0) {
        return 0;
      } else {
        return 2;
      }
    } else if (requestTime.isDoubleApproval === 1) {
      if (
        requestTime.isManagerLV1Approve === 1 &&
        requestTime.isManagerLV2Approve === 1
      ) {
        return 1;
      } else if (
        requestTime.isManagerLV1Approve === 0 ||
        requestTime.isManagerLV2Approve === 0
      ) {
        return 0;
      } else {
        return 2;
      }
    } else {
      if (requestTime.approvalLevel === 1) {
        if (requestTime.isManagerLV1Approve === 1) {
          return 1;
        } else if (requestTime.isManagerLV1Approve === 0) {
          return 0;
        } else {
          return 2;
        }
      } else if (requestTime.approvalLevel === 2) {
        if (requestTime.isManagerLV2Approve === 1) {
          return 1;
        } else if (requestTime.isManagerLV2Approve === 0) {
          return 0;
        } else {
          return 2;
        }
      }
    }
  };

  const getStatusManagerLV1 = (requestTime) => {
    if (requestTime.idRequestTimeWithdraw) {
      if (requestTime.isApprove === 1) {
        return 1;
      } else if (requestTime.isApprove === 0) {
        return 0;
      } else {
        return 2;
      }
    } else {
      if (requestTime.isDoubleApproval === 1) {
        if (requestTime.isManagerLV1Approve === 1) {
          return 1;
        } else if (requestTime.isManagerLV1Approve === 0) {
          return 0;
        } else {
          return 2;
        }
      } else {
        if (requestTime.approvalLevel === 1) {
          if (requestTime.isManagerLV1Approve === 1) {
            return 1;
          } else if (requestTime.isManagerLV1Approve === 0) {
            return 0;
          } else {
            return 2;
          }
        } else if (requestTime.approvalLevel === 2) {
          return 1;
        }
      }
    }
  };

  const getStatusManagerLV2 = (requestTime) => {
    if (requestTime.isDoubleApproval === 1) {
      if (requestTime.isManagerLV2Approve === 1) {
        return 1;
      } else if (requestTime.isManagerLV2Approve === 0) {
        return 0;
      } else {
        return 2;
      }
    } else {
      if (requestTime.approvalLevel === 2) {
        if (requestTime.isManagerLV2Approve === 1) {
          return 1;
        } else if (requestTime.isManagerLV2Approve === 0) {
          return 0;
        } else {
          return 2;
        }
      } else if (requestTime.approvalLevel === 1) {
        return 1;
      }
    }
  };

  useEffect(() => {
    const tempHistory = [];
    let empRequestTimeList = selectedEmployee && selectedEmployee.idEmployees ? requestTimeList.filter(item => item.idEmployees === selectedEmployee.idEmployees) : requestTimeList;
    let empRequestTimeWithdrawList = selectedEmployee && selectedEmployee.idEmployees ? requestTimeWithDrawList.filter(item => item.idEmployees === selectedEmployee.idEmployees) : requestTimeWithDrawList;

    empRequestTimeList.map((r) => {
      if (r.idRequestType === 2) {
        if (r.isDoubleApproval === 1) {
          if (r.isManagerLV1Approve === 1 && r.isManagerLV2Approve === 1) {
            tempHistory.push(r);
          } else if (
            r.isManagerLV1Approve === 0 ||
            r.isManagerLV2Approve === 0
          ) {
            tempHistory.push(r);
          }
        } else {
          if (r.approvalLevel === 1) {
            if (r.isManagerLV1Approve === 1) {
              tempHistory.push(r);
            } else if (r.isManagerLV1Approve === 0) {
              tempHistory.push(r);
            }
          } else if (r.approvalLevel === 2) {
            if (r.isManagerLV2Approve === 1) {
              tempHistory.push(r);
            } else if (r.isManagerLV2Approve === 0) {
              tempHistory.push(r);
            }
          }
        }
      }
    });

    empRequestTimeWithdrawList.map((r) => {
      if (r.idRequestType === 2) {
        if (r.isApprove !== null) {
          tempHistory.push(r);
        }
      }
    });

    setHistoryRows(
      tempHistory.sort(function (a, b) {
        let aApproveDate = null;
        let bApproveDate = null;

        if (a.idRequestTimeWithdraw) {
          aApproveDate = a.approveDate;
        }

        if (b.idRequestTimeWithdraw) {
          bApproveDate = b.approveDate;
        }

        if (a.managerLV1ApproveBy === userProfile.idEmployees) {
          aApproveDate = a.managerLV1ApproveDate;
        } else if (a.managerLV2ApproveBy === userProfile.idEmployees) {
          aApproveDate = a.managerLV2ApproveDate;
        }

        if (b.managerLV1ApproveBy === userProfile.idEmployees) {
          bApproveDate = b.managerLV1ApproveDate;
        } else if (b.managerLV2ApproveBy === userProfile.idEmployees) {
          bApproveDate = b.managerLV2ApproveDate;
        }

        return new Date(bApproveDate) - new Date(aApproveDate);
      })
    );
  }, []);

  return (
    <Box>
      <TableCustom
        columns={historyColumns}
        rows={requestTimeList && requestTimeWithDrawList ?
          historyRows.filter((_, index) => {
            return (index >= (0 + (page * listPerPage)) && index < ((page + 1) * listPerPage));
          })
          : []
        }
      />
      <Box style={{ display: "flex", justifyContent: "right" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={requestTimeList && requestTimeWithDrawList ?
            historyRows.length
            : 0}
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={listPerPage}
          onRowsPerPageChange={(event) => {
            setListPerPage(+event.target.value);
            setPage(0);
          }}
          labelRowsPerPage={`${t("RowsPerPage")}`}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${t("OutOf")} ${count !== -1 ? count : `${t("MoreThan")} ${to}`
            }`
          }
        />
      </Box>
      {dialogComment.isOpen && (
        <DialogShowComment
          open={dialogComment.isOpen}
          onClose={() =>
            setDialogComment({
              isOpen: false,
              commentManager1: null,
              commentManager2: null,
            })
          }
          commentManager1={dialogComment.commentManager1}
          commentManager2={dialogComment.commentManager2}
        />
      )}
    </Box>
  );
};

export default RequestOverTime;
