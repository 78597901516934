import React, { useState } from "react";
import {
  Grid,
  Typography,
  Container,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import SmartDocumentPage from "./SmartDocumentPage"
import CardStyle from "../../shared/general/Card";

const StyledRoot = styled("div")({
  backgroundColor: "#f5f5f5",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const WrapHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
  marginTop: 8,
  marginBottom: 16,
});

const index = () => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [typeDailog, setTypeDailog] = useState(null);
  const [showCreateDocument, setShowCreateDocument] = useState(false);
  const [formData, setFormData] = useState();

  const handleClose = (type) => {
    setShowCreateDocument(false); 
    setTypeDailog(null);
    setOpen(false);
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {!showCreateDocument && (
           <WrapHeader>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item xs={6} style={{ textAlign: 'left' }}>
                <Typography
                  variant="h4"
                  style={{
                    userSelect: 'none',
                    marginTop: '2px',
                    color: '#000000', 
                    fontWeight: 'semi-bold', 
                    fontFamily: 'Arial, sans-serif', 
                    }}
                >
                  Smart Document
                </Typography>
              </Grid>
            </Grid>
          </WrapHeader>
        )}
        <CardStyle style={{ padding: 16 }}>
          <SmartDocumentPage
            showCreateDocument={showCreateDocument}
            handleClose={handleClose}
            typeDailog={typeDailog}
            selectedDataRow={formData}
          />
       </CardStyle>
      </Container>
    </StyledRoot>
  );
}

export default index;
