import React from "react";
import dayjs from "dayjs";

import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Utils from "../../../../../utils";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledTableContainer = styled(TableContainer)({
  width: "100%",
  minWidth: 720,
});

const StyledTable = styled(Table)({
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
  },
  "& .tableCellHead:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    // boxShadow: "rgb(255 255 255) 8px 0px 0px inset",
  },
  "& .tableCellHead:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    // boxShadow: "rgb(255 255 255) -8px 0px 0px inset",
  },
  "& .tableCell": {
    borderColor: "#919eab3d",
    padding: "8px 16px",
  },
  "& .cellStatusAction": {
    width: 30,
  },
  "& .cellFullName": {
    width: 150,
  },
  "& .cellTypeLeave": {
    width: 100,
  },
  "& .cellStartStop": {
    width: 150,
  },
});

export default function TableList(props) {
  const { payRunList } = props;
  const { t, i18n } = useTranslation();

  return (
    <StyledTableContainer
      className="tableContainer"
      style={{ width: "100%", minHeight: 160 }}
    >
      <StyledTable aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell className={`tableCellHead`} align="center">
              {t("PayrunName")}
            </TableCell>
            <TableCell className={`tableCellHead`} align="center">
              {t("PaymentTypeName")}
            </TableCell>
            <TableCell className={`tableCellHead`} align="center">
              {t("Period")}
            </TableCell>
            <TableCell className={`tableCellHead`} align="center">
              {t("EmpTotal")}
            </TableCell>
            <TableCell className={`tableCellHead`} align="center">
              {t("TotalAmount")}
            </TableCell>
            <TableCell className={`tableCellHead`} align="center">
              {t("PaymentDate")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payRunList.length > 0 ? (
            payRunList.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center" className={`tableCell`}>
                  <Link
                    component={NavLink}
                    to={`/payroll/run/${row.idPayrun}`}
                    underline="hover"
                  >
                    {row.payrunName}
                  </Link>
                </TableCell>
                <TableCell align="center" className={`tableCell`}>
                  <Typography>
                    {row.paymentRound === "month"
                      ? `${t("Monthly")}-${t("Semi_Monthly")}`
                      : `${t("Monthly")}`}
                  </Typography>
                </TableCell>
                <TableCell align="center" className={`tableCell`}>
                  <Typography>
                    {dayjs(row.payrunPeriod)
                      .locale(localStorage.getItem("language") || "th")
                      .format(
                        localStorage.getItem("language") === "en"
                          ? "MMMM YYYY"
                          : "MMMM BBBB"
                      )}
                  </Typography>
                </TableCell>
                <TableCell align="center" className={`tableCell`}>
                  <Typography>{row.numberEmployee}</Typography>
                </TableCell>
                <TableCell align="center" className={`tableCell`}>
                  <Typography>
                    {Utils.numberWithCommas(row.netRound)}
                  </Typography>
                </TableCell>
                <TableCell align="center" className={`tableCell`}>
                  <Typography>
                    {row.paidDate
                      ? dayjs(row.paidDate)
                          .locale(localStorage.getItem("language") || "th")
                          .format(
                            localStorage.getItem("language") === "en"
                              ? "D MMMM YYYY"
                              : "D MMMM BBBB"
                          )
                      : ""}
                  </Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <Typography color="text.secondary" style={{ margin: "8px 0" }}>
              {t("NoData")}
            </Typography>
          )}
        </TableBody>
      </StyledTable>
    </StyledTableContainer>
  );
}
