import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import DrawerCustom from "../../shared/general/Drawer";

const StyledRoot = styled("div")({
  width: 450,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  "&.head-text-manager": {
    fontWeight: 400,
    "&.error": {
      color: "#f15e5e",
    },
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogAddFamily = (props) => {
  const {
    open,
    handleCloseDialog,
    customer,
    handleChangeAlertType,
    handleOpenAlert,
  } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [temp, setTemp] = useState({
    customerCode: "",
    customerName: "",
    customerNameShort: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTemp({ ...temp, [name]: value });
  };

  const handleSave = async () => {};

  return (
    <DrawerCustom
      title="เพิ่มสิทธิครอบครัว"
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4}>
            <StyledContentLabel>{t("FullName")}</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextFieldTheme
              name="customerName"
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledContentLabel>{t("PersonalID")}</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextFieldTheme
              name="customerName"
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledContentLabel>{t("Relationship")}</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <StyledFormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="relationship"
                onChange={handleChange}
              >
                <MenuItem value="Spouse">{t("Spouse")}</MenuItem>
                <MenuItem value="Father">{t("Father")}</MenuItem>
                <MenuItem value="Mother">{t("Mother")}</MenuItem>
                <MenuItem value="Child">{t("Child")}</MenuItem>
              </Select>
            </StyledFormControl>
          </Grid>
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleCloseDialog}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue
            variant="contained"
            onClick={handleCloseDialog}
            disabled={isSubmitting}
          >
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogAddFamily;
