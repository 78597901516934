import {
  POTENTIAL_FETCHING,
  POTENTIAL_SUCCESS,
  POTENTIAL_FAILED,

  EMPLOYEE_FETCHING,
  EMPLOYEE_SUCCESS,
  EMPLOYEE_FAILED,
} from "./types";
import potentialService from "../services/potential.service";

export const getAllPotential = async () => {
  try {
    const res = await potentialService.getAllPotential()
    if (res) {
      return res;
    };
  } catch (err) {
    return err;
  }
};

export const getEmpPotential = async (filter) => {
  try {
    const res = await potentialService.getEmpPotential(filter)
    if (res) {
      return res;
    }
  } catch (err) {
    return err;
  }
};

export const postAnswerPotential = async (idEmp, answers) => {
  try {
    const res = await potentialService.postAnswerPotential(idEmp, answers);
    if (res) {
      return res;
    }
  } catch (err) {
    if (err.response) {
      return err.response.data;
    }
  }
};
export const postSpanner = async (idEmployees, spannerList) => {
  try {
    const res = await potentialService.postSpanner(idEmployees, spannerList);
    if (res) {
      return res;
    }
  } catch (err) {
    if (err.response) {
      return err.response.data;
    }
  }
};
export const disableSpanner = async (idPotentialRole) => {
  try {
    const res = await potentialService.disableSpanner(idPotentialRole);
    if (res) {
      return res;
    }
  } catch (err) {
    if (err.response) {
      return err.response.data;
    }
  }
};
