import ExcelJS from 'exceljs';
import { useTranslation } from 'react-i18next';
import { getUserCompany, getUserFirstName, getUserLastName, getUserPosition } from '../../../../../utils/userData';

export const DashboardXlsx = async (data) => {
    const {t} = useTranslation();
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet(t("SystemService"));

    const headerRow = worksheet1.addRow([
        t("positionCode"),
        t("FirstName"),
        t("LastName"),
        t("Position"),
        t("Company"),
        t("Division"),
        t("Department"),
        t("Section"),
        t("Role"),
        t("SystemDaysCount")
    ]);

    headerRow.height = 50;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
    });

    const colWidths = [
        { key: "employeeID", width: 30 },
        { key: "firstname", width: 30 },
        { key: "lastname", width: 30 },
        { key: "position", width: 30 },
        { key: "company", width: 30 },
        { key: "department", width: 30 },
        { key: "division", width: 30 },
        { key: "section", width: 30 },
        { key: "role", width: 20 },
        { key: "useDay", width: 20 }
    ];

    data && data.export.forEach((item) => {
        const row = [
            item.employeeID || "-",
            getUserFirstName(item) || "-",
            getUserLastName(item) || "-",
            getUserPosition(item) || "-",
            getUserCompany(item) || "-",
            item.departmentName || "-",
            item.divisionName || "-",
            item.sectionName || "-",
            (() => {
                if (!item || !item.idRole) return "-";
                if (item.idRole === 1) return "USER";
                if (item.idRole === 2 || item.idRole === 3) return "MANAGER";
                if (item.idRole === 4) {
                    if (!item.isFillin || item.isFillin === 0) return "ADMIN";
                    if (item.isFillin && item.isFillin === 1) return "ADMIN Fill-in";
                }
                return "-";
            })(),
            item.useDay || "-"
        ];
        
        const excelRow = worksheet1.addRow(row);
        const contentStyle = { font: { size: 18, name: 'TH SarabunPSK' } };

        colWidths.forEach((col, index) => {
            worksheet1.getColumn(index + 1).width = col.width;
        });

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    })

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${t("SystemService")}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
}