import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { IconButton, TextField, Typography } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DatePickerCustom from "../../../shared/date/datePicker";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { getAllPerformanceQuestionSet } from "../../../../../actions/estimatePerformance";
import * as Yup from "yup";
import dayjs from "dayjs";
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from "@mui/icons-material/Add";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import {
  getCompanyProfile,
  updateCompanyProfile,
} from "../../../../../actions/company";
import QuestionSet from "./QuestionSet";
import QuestionSetForm from "./QuestionSetForm";
import EditQuestionSet from "./EditQuestionSet";
import CardStyle from "../../../shared/general/Card";
import DialogPublish from "./DialogPublish";
import PerformanceYear from "./PerformanceYear";
import EstimateLevel from "./estimateLevel";


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
}));

const StyledHeadingPage = styled("div")({
  marginBottom: 16,
  "& .wrap-search": {
    marginTop: 16,
    "& .fill": {
      display: "flex",
    },
  },
});

function EstimatePerformance({ selectedCompany, isReadOnly }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: companyProfile } = useSelector((state) => state.companyProfile);
  
  const [openForm, setOpenForm] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectQuestionSet, setSelectQuestionSet] = useState(null);
  const [date, setDate] = useState({
    start: dayjs(),
    end: dayjs(),
  });

  const handleClose = () => {
    setOpenForm(false);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setSelectQuestionSet(null);
  };

  const handleChangeSelectQuestionSet = (value) => {
    setOpenEdit(true);
    setSelectQuestionSet(value)
  }

  useEffect(() => {
    dispatch(getCompanyProfile());
  }, []);

  // useEffect(() => {
  //   if (companyProfile) {
  //     setstate(companyProfile);
  //   }
  // }, [companyProfile]);

  // const defaultEstimatePerformance = {
  //   // startDate: dayjs(),
  //   // endDate: dayjs().add(1, "day"),
  //   level: [{ name: "", active: true }],
  // };

  // const validationSchema = Yup.object().shape({
  //   // startDate: Yup.date(),
  //   // endDate: Yup.date().when("startDate", (startDate) => {
  //   //   return Yup.date().min(
  //   //     dayjs(startDate).add(1, "day"),
  //   //     "End date must be later than start date"
  //   //   );
  //   // }),
  //   level: Yup.array().of(
  //     Yup.object().shape({
  //       active: Yup.boolean(),
  //       name: Yup.string().when("active", {
  //         is: true,
  //         then: (schema) =>
  //           schema.min(1, "ความยาวอย่างน้อย 1 ตัวอักษร").max(40),
  //       }),
  //     })
  //   ),
  // });

  // const {
  //   control,
  //   handleSubmit,
  //   watch,
  //   reset,
  //   formState: { errors },
  // } = useForm({
  //   defaultValues: defaultEstimatePerformance,
  //   resolver: yupResolver(validationSchema),
  // });

  // const handleChangeAlertType = (newValue) => {
  //   setAlertType(newValue);
  // };

  // const handleOpenAlert = () => {
  //   setOpenAlert(true);
  // };

  // const handleCloseAlert = () => {
  //   setOpenAlert(false);
  // };

  // const handleClickSave = async (values) => {
  //   const result = await dispatch(updateEstimatePerformance(values));
  //   if (result) {
  //     handleOpenAlert();
  //     if (result.status === 201) {
  //       dispatch(getEstimatePerformance());
  //       handleChangeAlertType("success");
  //     } else {
  //       handleChangeAlertType("error");
  //     }
  //   } else {
  //     handleChangeAlertType("error");
  //   }
  // };

  // const handleSubmitEstimatePerformance = async (result) => {
  //   handleClose();
  //   if (result) {
  //     handleOpenAlert();
  //     if (result.status === 200) {
  //       dispatch(getEstimatePerformance());
  //       handleChangeAlertType("success");
  //     } else {
  //       handleChangeAlertType("error");
  //     }
  //   } else {
  //     handleChangeAlertType("error");
  //   }
  // };

  // const handleOpenDrawer = (type, idEdu) => {
  //   if (type === "Add") {
  //     setTypeDialog("Add");
  //   } else {
  //     setTypeDialog("Edit");
  //   }
  //   if (idEdu) {
  //     setIdEducation(idEdu);
  //   }
  //   setOpenDrawer(true);
  // };

  // const handleClose = () => {
  //   setOpenDrawer(false);
  //   setIdEducation(null);
  // };

  // useEffect(() => {
  //   if (estimatePerformance) {
  //     reset({
  //       startDate: estimatePerformance.startDate,
  //       endDate: estimatePerformance.endDate,
  //       level: estimatePerformance.level,
  //     });
  //   }
  // }, [estimatePerformance]);

  return (
    <div className={classes.root}>
      {!openForm && !openEdit && (
        <>
          <StyledHeadingPage>
            <Typography variant="h6">
              {t("AnnualPerformanceEvaluationQuestion")}
            </Typography>
          </StyledHeadingPage>
          <div style={{ marginBottom: "32px" }}>
            <EstimateLevel idCompany={selectedCompany.idCompany} />
          </div>
          <div style={{ marginBottom: "32px" }}>
            <PerformanceYear idCompany={selectedCompany.idCompany} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "16px",
            }}
          >
            <Typography fontSize="20px" fontWeight="500">
              {t("QuestionSet")}
            </Typography>
            {!isReadOnly && (
              <ButtonBlue
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setOpenForm(true)}
              >
                {t("AddQuestion")}
              </ButtonBlue>
            )}
          </div>
          <QuestionSet
            idCompany={selectedCompany.idCompany}
            handleChangeSelectQuestionSet={handleChangeSelectQuestionSet}
          />
        </>
      )}
      {openForm && (
        <QuestionSetForm
          idCompany={selectedCompany.idCompany}
          handleClose={handleClose}
        />
      )}
      {openEdit && (
        <EditQuestionSet
          idCompany={selectedCompany.idCompany}
          handleClose={handleCloseEdit}
          selectQuestionSet={selectQuestionSet}
        />
      )}
    </div>
  );
}

export default EstimatePerformance;
