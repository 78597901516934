import { 
    USER_BENEFICIARYS_FETCHING,
    USER_BENEFICIARYS_FAILED,
    USER_BENEFICIARYS_SUCCESS 
} from "../actions/types";

const initialState = {
    result: null,
    isFetching: false,
    isError: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case USER_BENEFICIARYS_FETCHING:
            return { ...state, result: null, isFetching: true, isError: false };
        case USER_BENEFICIARYS_FAILED:
            return { ...state, result: null, isFetching: false, isError: true };
        case USER_BENEFICIARYS_SUCCESS:
            return { ...state, result: payload, isFetching: false, isError: false };
        default:
            return state;
    }
}
