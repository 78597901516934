import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, styled, Typography, Box, Divider, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import {
  getSignatureImageOfEmployees,
} from "../../../../actions/employee";

import Dialog from '@mui/material/Dialog';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Signature from './signature';
import { createTheme } from '@mui/material/styles';

//Translator TH-EN
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../shared/general/ButtonBlue";

const DialogSinatureImage = (props) => {

  const dispatch = useDispatch();
  const { employeeProfile } = props;
  const { t, i18n } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditImageOpen, setIsEditImageOpen] = useState(false);

  const handleEdit = () => {
    setIsEditImageOpen(true);
  };

  const [isSignatureOneLoading, setIsSignatureOneLoading] = useState(false);
  const [signatureOneImageURL, setSignatureOneImageURL] = useState(null);
  const [signatureOneImageName, setSignatureOneImageName] = useState(null);

  const fetchedData = async () => {
    try {
      setIsSignatureOneLoading(true);
      const response = await getSignatureImageOfEmployees();
      setIsSignatureOneLoading(false); // ตั้งค่าสถานะการโหลดเป็นเท็จ

      if (response && response.data) {
        setSignatureOneImageURL(response.data.ImageURL);
        setSignatureOneImageName(response.data.filename);
      } else {
        console.error('Invalid response format:', response);
      }
    } catch (error) {
      setIsSignatureOneLoading(false); // ตั้งค่าสถานะการโหลดเป็นเท็จ
      console.error('Error fetching signature image:', error);
    }
  };

  useEffect(() => {
    fetchedData();
  }, [employeeProfile]);

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1600,
        xl: 1920,
        '2xl': 2560,
      },
    },
  });

  const matchesXs = useMediaQuery(theme.breakpoints.down('sm')); // ขนาดหน้าจอเล็กสุด (มือถือ)
  const matchesMd = useMediaQuery(theme.breakpoints.down('lg'));// ขนาดหน้าจอขนาดกลาง (แท็บเล็ตหรือโน้ตบุ้คขนาดเล็กถึงใหญ่)
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      {!isEditImageOpen && (
        <Box>
          <Typography
            variant="h5"
            align="center"
            justifyContent="center"
            sx={{
              fontWeight: "semi-bold",
              color: "black",
              marginBottom: "16px",
            }}
          >
            {t("My Signature")}
          </Typography>
          <Box
            sx={{
              position: 'relative',
              textAlign: 'center',
              boxShadow: 3, // เพิ่มเงา
              borderRadius: 2, // มุมโค้งมน
              overflow: 'hidden', // เพื่อไม่ให้ส่วนที่เกินออกมาจากกรอบ
            }}
          >
            <Box
              sx={{
                padding: 2,
                backgroundColor: '#f9f9fb',
                display: 'flex',
                borderRadius: 1,
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <IconButton
                onClick={handleEdit}
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  backgroundColor: 'white',
                  boxShadow: 1,
                  '&:hover': {
                    backgroundColor: 'lightgrey',
                  },
                }}
              >
                <EditIcon />
              </IconButton>
              {isSignatureOneLoading ? (
                <CircularProgress />
              ) : (
                <>
                  {signatureOneImageURL ? (
                    <img
                      src={signatureOneImageURL}
                      alt="Signature"
                      style={{
                        height: 250,
                        width: matchesXs ? '280px ' : (matchesMd ? 'auto' : '0'),
                        borderRadius: 4,
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: { xs: 200, md: 350 },
                        width: { xs: 200, md: 400 },
                        backgroundColor: '#f0f0f0',
                        borderRadius: 4,
                      }}
                    >
                      <ImageNotSupportedIcon sx={{ fontSize: { xs: '10vw', md: '5vw' }, color: '#ccc' }} />
                    </Box>
                  )}
                </>
              )}
            </Box>
            {/* <Box
           sx={{
            marginTop: 2,
          }}
          >
            <Typography>{signatureOneImageName}</Typography>
          </Box> */}
          </Box>
        </Box>
      )}
      {isEditImageOpen && (
        <Signature
          employeeProfile={employeeProfile}
          signatureOneImageURL={signatureOneImageURL}
          signatureOneImageName={signatureOneImageName}
          setSignatureOneImageURL={setSignatureOneImageURL}
          isSignatureOneLoading={isSignatureOneLoading}
          closeEditImageOpen={setIsEditImageOpen}
        />
      )}
    </>
  );
};

export default DialogSinatureImage;