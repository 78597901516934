import React from "react";
import { Box, Typography, styled, LinearProgress, Chip } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const StyledCard = styled(Box)(({ theme }) => ({
  padding: "24px",
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  marginBottom: "16px",
}));

const QuarterBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: 80,
  height: 80,
  backgroundColor: "rgba(219, 65, 120, 0.3)",
  marginRight: 16,
  color: "#DB4178",
  fontSize: "30px",
  borderRadius: theme.shape.borderRadius,
}));

const StatusChip = styled(Chip)(({ theme, isSuccess }) => ({
  backgroundColor: isSuccess ? "#4CAF50" : "#FFC107",
  color: "#ffffff",
  fontWeight: "bold",
  height: "28px",
  borderRadius: "14px",
}));

const ObjectiveCard = ({ data }) => {
  const { t, i18n } = useTranslation();

  const isCompleted = data.isSuccess === 1;
  const statusLabel = isCompleted ? t("Completed") : t("InProgress");

  return (
    <StyledCard>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <QuarterBox>
            <Typography
              variant="caption"
              style={{ fontWeight: "bold", fontSize: "16px" }}
            >
              {`${t("Quarter")}`}
            </Typography>
            <Typography
              variant="h6"
              style={{ fontWeight: "900", fontSize: "30px" }}
            >
              {`${data.quarter}`}
            </Typography>
          </QuarterBox>
          <Box>
            <Typography fontSize="18px" fontWeight="bold">
              {data.objectiveName}{" "}
              <Typography
                component="span"
                fontSize="16px"
                color="text.secondary"
              >
                {` (${dayjs()
                  .set("year", data.year)
                  .format(i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY")})`}
              </Typography>
            </Typography>
            {data.impactName && (
              <Typography fontSize="16px" color="text.secondary">
                {data.impactName}
              </Typography>
            )}
          </Box>
        </Box>
        <StatusChip
          label={statusLabel}
          isSuccess={isCompleted}
          sx={{ marginTop: "8px" }}
        />
      </Box>

      {data.keyResults && data.keyResults.length > 0 && (
        <Box mt={2}>
          {data.keyResults.map((keyResult) => (
            <Box
              key={keyResult.idTeamKeyResult}
              display="flex"
              alignItems="center"
              mb={2}
              p={2}
              bgcolor="rgba(219, 65, 120, 0.05)"
              borderRadius="8px"
            >
              <Typography flexGrow={1} fontSize="16px" fontWeight="500">
                {keyResult.keyResultName}
              </Typography>

              {keyResult.progressPercent !== undefined && (
                <>
                  <Box width="40%" mr={2}>
                    <LinearProgress
                      variant="determinate"
                      value={keyResult.progressPercent}
                      sx={{
                        height: 10,
                        borderRadius: 5,
                        backgroundColor: "rgba(219, 65, 120, 0.1)",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "rgba(219, 65, 120, 1)",
                        },
                      }}
                    />
                  </Box>
                  <Typography fontSize="14px" color="text.secondary">
                    {`${keyResult.progressPercent}%`}
                  </Typography>
                </>
              )}
            </Box>
          ))}
        </Box>
      )}
    </StyledCard>
  );
};

export default ObjectiveCard;
