import React, { useEffect, useState } from "react"
import { Grid, Typography, styled } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import AlertResponse from "../../shared/general/AlertResponse";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import DatePickerCustom from "../../shared/date/datePicker";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../actions/company";
import LoadingGif from "../../assets/social-media.gif";
import { CompensateXlsx } from "./xlsx-export/compensate-xlsx";
import { getCompensateLeaveReport } from "../../../../actions/report";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center",
    },
});

const StyledFooter = styled("div")({
    padding: 16,
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel": {
        marginRight: 8,
    },
});

const CompensatoryReport = (props) => {
    const dispatch = useDispatch();
    const { open, handleClose } = props;
    const { t, i18n } = useTranslation();

    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [compensateData, setCompensateData] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    }

    const handleSubmit = () => {
        if (compensateData && compensateData.length > 0) {
            CompensateXlsx(t, compensateData);
        } else {
            handleChangeAlertType(t("NoData"));
            handleOpenAlert(true);
        }
    };

    const fetchData = async () => {
        try {
            setIsFetching(true);
            const response = await getCompensateLeaveReport({
                year: dayjs(selectedYear).format("YYYY"),
                month: dayjs(selectedMonth).format("MM"),
                idCompany: selectedCompany && selectedCompany.idCompany,
            });
            if (response && response.data && response.data.length > 0) {
                setIsFetching(false);
                setCompensateData(response.data);
            } else {
                handleChangeAlertType(t("NoData"));
                handleOpenAlert(true);
                setIsFetching(false);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (selectedYear && selectedMonth && selectedCompany && selectedCompany.idCompany) {
            fetchData();
        } else {
            setIsFetching(false);
        }
    }, [selectedYear, selectedMonth, selectedCompany]);

    useEffect(() => {
        dispatch(getAffiliateOrganization());
    }, []);

    useEffect(() => {
        if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
            let ownCompany = affiliateOrganizationList.find(x => x.idCompany === userProfile.idCompany);
            if (!ownCompany) {
                ownCompany = affiliateOrganizationList[0];
            }
            setSelectedCompany(ownCompany)
        }
    }, [userProfile, affiliateOrganizationList]);

    return (
        <DrawerCustom
            title={`${t("AccumulationOfCompensatoryTimeReport")}`}
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot style={{ width: 500 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">{t("Company")}</Typography>
                        <SelectAffiliateCompany
                            options={affiliateOrganizationList}
                            value={selectedCompany}
                            disabled={isFetching}
                            onChange={(_, value) => {
                                onChangeCompany(value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                            {t("SelectYear")}
                        </Typography>
                        <div className="search-date">
                            <DatePickerCustom
                                inputFormat="YYYY"
                                views={['year']}
                                value={selectedYear}
                                name="start"
                                disabled={isFetching}
                                onChange={(newValue) => {
                                    setSelectedYear(newValue);
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                            {t("SelectMonth")}
                        </Typography>
                        <div className="search-date">
                            <DatePickerCustom
                                inputFormat="MMMM"
                                views={['month']}
                                value={selectedMonth}
                                name="start"
                                disabled={isFetching}
                                onChange={(newValue) => {
                                    setSelectedMonth(newValue);
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>

                <StyledFooter>
                    <ButtonBlue className="cancel" onClick={handleClose}>
                        {t("Cancel")}
                    </ButtonBlue>

                    {isFetching ? (
                        // <CircularProgress />
                        <img src={LoadingGif} style={{ width: 80, height: 80 }} />
                    ) : (
                        <ButtonBlue
                            variant="outlined"
                            startIcon={<DownloadRoundedIcon />}
                            onClick={() => handleSubmit()}
                            disabled={!compensateData || isFetching}
                        >
                            {t("Download")}
                        </ButtonBlue>
                    )}
                </StyledFooter>
                <AlertResponse
                    open={openAlert}
                    handleClose={handleCloseAlert}
                    alertType={alertType}
                />
            </StyledRoot>
        </DrawerCustom>

    );
};

export default CompensatoryReport