import React, { useState, useEffect, Fragment } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import {
  Typography,
  Grid,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  DialogContentText,
  Avatar,
  Divider,
  Rating,
  Container,
  Box,
  Stack,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import { useSelector, useDispatch } from "react-redux";
import BusinessIcon from "@mui/icons-material/Business";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SchoolIcon from "@mui/icons-material/School";
import AdjustIcon from "@mui/icons-material/Adjust";
import GradeIcon from '@mui/icons-material/Grade';
import PaymentsIcon from '@mui/icons-material/Payments';

import { Radar, Doughnut } from "react-chartjs-2";
import LastPerson from "./lastPerson/lastPerson";
import CardStyle from "../../../../shared/general/Card";
import { useTranslation } from "react-i18next";
import { getUserCompany, getUserDepartment, getUserDivision, getUserPosition, getUserSection } from "../../../../../../utils/userData";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "calc(100vh - 20px)",
    "& .TextWhite": {
      color: "white",
    },
  },
  paper: {
    minWidth: 350,
    minHeight: 150,
    padding: 30,
    marginBottom: 20,
    maxWidth: 1000,
  },
  headDiv: {
    display: "flex",
    justifyContent: "center"
  },
  colorHeaderText: {
    color: "#007AFC",
    marginBottom: 20,
    fontWeight: "bold",
  },
  itemSize: {
    width: "90%",
  },
  text: {
    fontSize: 19,
    fontWeight: "bold",
    marginBottom: 0,
  },
  text2: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 0,
    padding: 5,
    border: "1px solid #B0B0B0",
  },
  headText: {
    fontSize: 18,
    marginBottom: 0,
  },
  icon: {
    marginBottom: 3,
    marginRight: 20,
  },
  image: {
    width: "30px",
    marginBottom: 3,
    marginRight: 5,
  },
  avatar: {
    width: 50,
    height: 50,
  },
  boxComment: {
    width: "100%",
    borderRadius: "25px",
    border: "2px solid #E0E0E0",
    padding: "13px",
    overflowWrap: "anywhere",
  },
  divDetailComment: {
    width: "calc(100% - 15px)",
    paddingLeft: "15px",
  },
  textNameComment: {
    marginLeft: "5px",
    fontSize: "20px",
    fontWeight: "bold",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "#CCCACA",
  },
  gridItemHeader: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
  },
  textHeader: {
    fontSize: "22px",
    fontWeight: "bold",
  },
  bigAvatar: {
    width: 120,
    height: 120,
    marginRight: "30px",
  },
  eachDivTopic: {
    padding: "30px",
  },
  TopicText: {
    color: "#1565c0",
  },
  topicHeadCard: {
    fontWeight: 500,
    // color: "#007afc",
    color: "#000000"
  },
  link: {
    textDecoration: "none",
  },
  editBtn: {
    backgroundColor: "#e0e0e0",
    color: "#616161",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      color: "#616161",
    },
  },
}));

const ShowDetailPosition = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const data = location.state.item || {};

  const plugin = {
    beforeDraw: (chart) => {
      const { ctx, scales, config } = chart;
      const { xCenter, yCenter, drawingArea: radius } = scales.r;
      ctx.save();
      ctx.arc(
        Math.abs(xCenter),
        Math.abs(yCenter),
        Math.abs(radius),
        0,
        Math.abs(Math.PI * 2)
      );
      ctx.fillStyle = "rgba(238, 238, 238,0.6)";
      ctx.fill();
      ctx.restore();
    },
  };

  const chartData = {
    // labels: [1, 2, 3, 4, 5],
    labels: data && data.competency && data.competency.map(item => item.competencyName),
    datasets: [
      {
        data: data && data.competency && data.competency.map(item => parseInt(item.actualLevel * 20)),
        backgroundColor: "rgba(38, 166, 154,0.3)",
        borderColor: "rgba(38, 166, 154,0.5)",
        borderWidth: 1,
      },
    ],
  };

  const option = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        suggestedMin: 1,
        suggestedMax: 20,
        pointLabels: {
          font: { size: 20 },
        },
        ticks: {
          maxTicksLimit: 4,
          display: false,
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
    },
  };

  const splitMessage = (data) => {
    const newMessage = data && data.split(", ");

    return (
      <Box sx={{ marginLeft: 4, marginTop: "20px" }}>
        {newMessage && newMessage.length > 1 ? (
          newMessage.map((item, index) => (
            <Typography key={index} sx={{ fontSize: 18, color: "#DB4178", mb: 1 }}>
              {index + 1}. {item}
            </Typography>
          ))
        ) : (
          <Typography sx={{ fontSize: 18, color: "#DB4178" }}>
            {data ? data : t("NoData")}
          </Typography>
        )}
      </Box>
    );
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat().format(number);
  };

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        <CardStyle
          style={{
            padding: "16px",
            marginTop: "30px",
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/Information/BG.svg)`,
          }}
        >
          <Box sx={{ p: 2 }}>
            {data && (
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="space-around"
                  alignItems={"center"}
                  spacing={2}
                >
                  <Grid item>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        style={{
                          width: 250,
                          height: 250,
                          borderRadius: "100%",
                        }}
                        src={data && data.jobProfile && data.jobProfile.jobImg}
                      />
                    </div>
                    <div>
                      <Typography
                        style={{ textAlign: "center", marginTop: "20px" }}
                        variant="h6"
                      >
                        {getUserPosition(data)}
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item>
                    <Grid container direction="column" spacing={2}>
                      <Grid item container direction="row">
                        <Typography fontSize={18} fontWeight={"bold"}>
                          {t("positionCode")} :&nbsp;&nbsp;
                        </Typography>
                        <Typography fontSize={18}>
                          {data.positionCode || "-"}
                        </Typography>
                      </Grid>

                      <Grid item container direction="row">
                        <Typography fontSize={18} fontWeight={"bold"}>
                          {t("JobGroup")} :&nbsp;&nbsp;
                        </Typography>
                        <Typography fontSize={18}>
                          {data.jobGroupName || "-"}
                        </Typography>
                      </Grid>

                      <Grid item container direction="row">
                        <Typography fontSize={18} fontWeight={"bold"}>
                          {t("Company")} :&nbsp;&nbsp;
                        </Typography>
                        <Typography fontSize={18}>
                          {getUserCompany(data) || "-"}
                        </Typography>
                      </Grid>

                      <Grid item container direction="row">
                        <Typography fontSize={18} fontWeight={"bold"}>
                          {t("Division")} :&nbsp;&nbsp;
                        </Typography>
                        <Typography fontSize={18}>
                          {getUserDivision(data) || "-"}
                        </Typography>
                      </Grid>

                      <Grid item container direction="row">
                        <Typography fontSize={18} fontWeight={"bold"}>
                          {t("Department")} :&nbsp;&nbsp;
                        </Typography>
                        <Typography fontSize={18}>
                          {getUserDepartment(data) || "-"}
                        </Typography>
                      </Grid>

                      <Grid item container direction="row">
                        <Typography fontSize={18} fontWeight={"bold"}>
                          {t("Section")} :&nbsp;&nbsp;
                        </Typography>
                        <Typography fontSize={18}>
                          {getUserSection(data) || "-"}
                        </Typography>
                      </Grid>

                      <Grid item container direction="row">
                        <Typography fontSize={18} fontWeight={"bold"}>
                          {t("Location")} :&nbsp;&nbsp;
                        </Typography>
                        <Typography fontSize={18}>
                          {data.province || "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Box>
        </CardStyle>
      </Container>
      <Container maxWidth="lg">
        {data && (
          <Fragment>
            <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
              <Typography variant="h5" className={classes.topicHeadCard}>
                <i
                  className="fa-solid fa-book"
                  style={{ marginRight: "20px", color: "#DB4178" }}
                />
                {t("overallQualification")}
              </Typography>

              {splitMessage(data && data.jobProfile && data.jobProfile.overall_qualification)}
            </CardStyle>

            <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
              <Typography variant="h5" className={classes.topicHeadCard}>
                <i
                  className="fa-solid fa-list"
                  style={{ marginRight: "20px", color: "#DB4178" }}
                />
                {t("requireExperience")}
              </Typography>

              {splitMessage(data && data.jobProfile && data.jobProfile.require_experience)}
            </CardStyle>

            <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
              <Stack alignItems={"center"} direction={"row"}>
                <GradeIcon style={{ marginRight: "20px", color: "#DB4178", width: 30, height: 30 }} />
                <Typography variant="h5" className={classes.topicHeadCard}>
                  {t("specialRequirements")}
                </Typography>
              </Stack>

              {splitMessage(data && data.jobProfile && data.jobProfile.special_requirement)}
            </CardStyle>

            <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
              <Stack alignItems={"center"} direction={"row"}>
                <PaymentsIcon style={{ marginRight: "20px", color: "#DB4178", width: 30, height: 30 }} />
                <Typography variant="h5" className={classes.topicHeadCard}>
                  {t("specialRequirements")}
                </Typography>
              </Stack>

              <Box sx={{ p: 2, ml: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box mb={3}>
                      <Typography variant="h6" gutterBottom color={"#DB4178"}>
                        {t("Wages")}
                      </Typography>
                      <Typography fontSize={18} fontWeight={500}>
                        {data && data.jobProfile && formatNumber(data.jobProfile.min_salary)} - {data && data.jobProfile && formatNumber(data.jobProfile.max_salary)} {" "} {t("Baht")}
                      </Typography>
                    </Box>

                    <Box mb={3}>
                      <Typography variant="h6" gutterBottom color={"#DB4178"}>
                        {t("JobGroup")}
                      </Typography>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Typography fontSize={18} fontWeight={500}>
                          {data && data.jobProfile && data.jobProfile.min_level} - {data && data.jobProfile && data.jobProfile.max_level}
                        </Typography>
                        <Typography fontSize={18}>
                          ({t("JEScore")} {data && data.jobProfile && data.jobProfile.je_score || "-"})
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </CardStyle>

            <CardStyle style={{ padding: "16px", marginTop: "20px", marginBottom: data && data.latestEmp ? "0px" : "40px" }}>
              <Typography variant="h5" className={classes.topicHeadCard}>
                <i
                  className="fa-solid fa-lightbulb"
                  style={{ marginRight: "20px", color: "#DB4178" }}
                />
                {t("competencyNeed")}
              </Typography>
              <Grid container justifyContent="center">
                <Grid item style={{ minWidth: "700px", alignContent: "center" }}>
                  <Radar data={chartData} options={option} plugins={[plugin]} />
                </Grid>
                {/* <Grid
                  item
                  lg={12}
                  xl={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ textAlign: "right" }}
                >
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#007afc",
                      border: 0,
                      color: "white",
                      height: 42,
                      marginBottom: 10,
                    }}
                  // onClick={() => openFristForm()}
                  //   href="/#/oneOfMember"
                  >
                    View
                  </Button>
                </Grid> */}
              </Grid>
            </CardStyle>

            {data && data.latestEmp && (
              <CardStyle style={{ padding: "16px", marginTop: "20px", marginBottom: "40px" }}>
                <Typography variant="h5" className={classes.topicHeadCard}>
                  <i
                    class="fa-solid fa-user-tie"
                    style={{ marginRight: "20px", color: "#DB4178" }}
                  />
                  {t("latestPersonInChange")}
                </Typography>

                <LastPerson latestData={data.latestEmp} />

                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    className={classes.eachDivTopic}
                    style={{ borderRight: "1px solid lightgray" }}
                  >
                    <Typography variant="h5" className={classes.topicHeadCard}>
                      <i
                        className="fa-solid fa-bullhorn"
                        style={{ marginRight: "20px", color: "#DB4178" }}
                      />
                      {t("reportTo")}
                    </Typography>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        style={{
                          width: "fit-content",
                          display: "flex",
                          marginTop: "20px",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          alt="Remy Sharp"
                          src={data && data.latestEmp && data.latestEmp.manageImageProfile}
                          variant="circular"
                          className={classes.bigAvatar}
                        />
                        <div>
                          <Typography
                            style={{ fontWeight: "600", fontSize: "1.5rem" }}
                          >
                            {data && data.latestEmp && data.latestEmp.ManagerLV1_firstname} &nbsp; {data && data.latestEmp && data.latestEmp.ManagerLV1_lastname}
                          </Typography>
                          <Typography
                            style={{
                              fontWeight: "600",
                              fontSize: "1.25rem",
                              marginTop: "20px",
                            }}
                          >
                            {data && data.latestEmp && data.latestEmp.positionName}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6} className={classes.eachDivTopic}>
                    <Typography variant="h5" className={classes.topicHeadCard}>
                      <i
                        className="fa-solid fa-people-group"
                        style={{ marginRight: "20px", color: "#DB4178" }}
                      />
                      {t("teamUnder")}
                    </Typography>
                    <div
                      style={{
                        height: "calc(100% - 40px)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h5" style={{ fontWeight: "600" }}>
                        <span style={{ fontSize: "120px", marginRight: "30px" }}>
                          {data && data.latestEmp && data.latestEmp.manager_num_employees}
                        </span>{" "}
                        {t("Person")}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </CardStyle>
            )}
          </Fragment>
        )}
      </Container>
    </div>
  );
};

export default ShowDetailPosition;