import React from "react";
import { Typography, Breadcrumbs, Grid } from "@mui/material";

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import { Link } from "react-router-dom";



const useStyles = makeStyles({
  dataText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  GridBreadcrumds: {
    "& .MuiBreadcrumbs-separator":
      {
        color: (props) => props.color,
        
      },
    "& .text":
      {color: (props) => props.color,},
  }
});

const Breadcrumb2 = ({ textLabel, icon, link, prevText, dark }) => {

  const cssStyled = {
    color : dark ? "#CCCACA" : "rgba(0, 0, 0, 0.87)"
  }

  const classes = useStyles(cssStyled);

  return (
    
      
      <Grid className={classes.GridBreadcrumds}>
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 10 }}>
          <Link to={link} style={{ textDecoration: "none" }}>
            <div className={classes.dataText}>
              {icon}
              <Typography component={"span"} color="textPrimary" className="text">
                {prevText}
              </Typography>
            </div>
          </Link>
          <Typography component={"span"} color="textPrimary" className="text">
            {textLabel}
          </Typography>
        </Breadcrumbs>
      </Grid>
     
    
  );
};

export default Breadcrumb2;
