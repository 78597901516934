import React from "react";
import EmployeeList from "./EmployeeList";
import TeamCrossPlanList from "./TeamCrossPlanList";
import EvaluateApproveList from "../Evaluation/EvaluateApproveList";
import { styled } from "@mui/styles";
import { Container, Grid } from "@mui/material";

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

function ApprovalList() {
  return (
    <ContainerStyled>
      <div className="card-section">
        <div className="header-section">
          <Grid container>
            <Grid item>
              <h3 className="header-topic">Approval Planning</h3>
            </Grid>
          </Grid>
        </div>
        <EmployeeList />
      </div>
      <div className="card-section">
        <div className="header-section">
          <Grid container>
            <Grid item>
              <h3 className="header-topic">Cross Team List</h3>
            </Grid>
          </Grid>
        </div>
        <TeamCrossPlanList />
      </div>
      <div className="card-section">
        <div className="header-section">
          <Grid container>
            <Grid item>
              <h3 className="header-topic">Manager Evaluation</h3>
            </Grid>
          </Grid>
        </div>
        <EvaluateApproveList />
      </div>
    </ContainerStyled>
  );
}

export default ApprovalList;
