import React from "react";
import {
  styled,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiInputLabel-root": {
    color: "#637381",
    "&.Mui-focused": {
      color: "#46cbe2",
    },
    "&.Mui-error": {
      color: "rgb(211, 47, 47)",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#46cbe2",
    },
    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(211, 47, 47)",
    },
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

export default function TimePicker(props) {
  const {
    value,
    unit,
    name,
    disabled,
    handleChangeTime,
    periodTime,
    step,
    maxStep,
    minStep,
    mode,
  } = props;
  const { t, i18n } = useTranslation();
  const [HOURS] = React.useState(24);
  const [MINUTE] = React.useState(60);

  const handleChange = (event) => {
    handleChangeTime(name, unit, periodTime, event.target.value);
  };

  const checkRangeCondition = (index) => {
    let result = [];
    if (index % step === 0) {
      if (minStep && maxStep && index >= minStep && index <= maxStep) {
        result.push(index);
      } else if (minStep && !maxStep && index >= minStep) {
        result.push(index);
      } else if (maxStep && !minStep && index <= maxStep) {
        result.push(index);
      } else if (!maxStep && !minStep) {
        result.push(index);
      }
    }
    return result;
  };

  const numberOfSelect = (unit) => {
    if (unit === "hour") {
      return [...Array(HOURS).keys()];
    } else if (unit === "minute" && step) {
      let result = [];
      [...Array(MINUTE)].map((value, index) => {
        let resultIndex = checkRangeCondition(index);
        result = [...result, ...resultIndex];
      });
      return result;
    } else if (unit === "minuteBreak" && step) {
      let result = [];
      [...Array(90)].map((value, index) => {
        let resultIndex = checkRangeCondition(index);
        result = [...result, ...resultIndex];
      });
      return result;
    } else {
      return [0, 30];
    }
  };

  return (
    <StyledFormControl fullWidth disabled={disabled}>
      {/* <InputLabel id="demo-simple-select-label">
        {unit === "hour" ? "ชั่วโมง" : "นาที"}
      </InputLabel> */}
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        //label={unit === "hour" ? "ชั่วโมง" : "นาที"}
        value={value}
        onChange={handleChange}
        inputProps={{ readOnly: mode === "read" }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        }}
        displayEmpty
        renderValue={(selected) => {
          if (selected === "") {
            return (
              <Typography color="text.secondary">
                {unit === "hour" ? `${t("Unit.Hours")}` : `${t("Unit.Minute")}`}
              </Typography>
            );
          } else {
            return (
              <Typography>
                {String(
                  numberOfSelect(unit).find(
                    (item) => Number(item) === Number(value)
                  )
                ).padStart(2, "0")}
              </Typography>
            );
          }
        }}
      >
        <MenuItem key={-1} disabled value="">
          {unit === "hour" ? `${t("Unit.Hours")}` : `${t("Unit.Minute")}`}
        </MenuItem>
        {numberOfSelect(unit).map((value) => {
          return (
            <MenuItem
              key={value}
              value={value < 10 ? "0" + value : String(value)}
            >
              {value < 10 ? "0" + value : String(value)}
            </MenuItem>
          );
        })}
      </Select>
    </StyledFormControl>
  );
}
