import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Tab,
  Tabs,
  Table,
  TablePagination,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Badge,
  Box,
  CardContent,
  Button,
  Chip,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import HistoryIcon from "@mui/icons-material/History";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";

import CardStyle from "../../shared/general/Card";

import dayjs from "dayjs";
import utils from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";

import { getAllWelfareExpense } from "../../../../actions/welfare-expense";
import ButtonBlue from "../../shared/general/ButtonBlue";
import Backdrop from "../../shared/general/Backdrop";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 24,
  },
  "& .head": {
    paddingTop: 8,
    marginBottom: 12,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .head-2": {
    paddingTop: 8,
    marginBottom: 16,
    marginTop: 4,
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  "& .wrap-sum-amount": {
    marginBottom: 16,
  },
});

const ButtonAdd = styled(Button)(({ variant, theme }) => ({
  color: "#ffffff",
  backgroundColor: "#46cbe2",
  border: "solid",
  borderColor: "#46cbe2",
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 4,
  paddingBottom: 4,
  borderRadius: 18,
  margin: "0 4px",
  "&:hover": {
    color: "#ffffff",
    backgroundColor: "#2DC7E2",
    borderColor: "#2DC7E2",
  },
}));

const ButtonHistory = styled(Button)(({ variant, theme }) => ({
  color: "#ffffff",
  backgroundColor: "#ff6d00",
  border: "solid",
  borderColor: "#ff6d00",
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 4,
  paddingBottom: 4,
  borderRadius: 18,
  margin: "0 4px",
  "&:hover": {
    color: "#ffffff",
    backgroundColor: "#ef6c00",
    borderColor: "#ef6c00",
  },
}));

const StyledChipStatus = styled(Chip)({
  display: "flex",
  flexDirection: "column",
  height: 24,
  width: 140,
  "& .MuiChip-label": {
    display: "block",
    whiteSpace: "normal",
    textAlign: "center",
  },
  "&.Draft-status": {
    color: "#455a64",
    borderColor: "#fafafa",
  },
  "&.Waiting-status": {
    color: "#7c4dff",
    borderColor: "#7c4dff",
    backgroundColor: "#ede7f6",
  },
  "&.Processing-status": {
    color: "#007AFE",
    borderColor: "#007AFE",
    backgroundColor: "#e3f2fd",
  },
  "&.Holding-status": {
    color: "#ff6d00",
    borderColor: "#ff6d00",
    backgroundColor: "#fff8e1",
  },
  "&.Completed-status": {
    color: "#00c853",
    borderColor: "#00c853",
    backgroundColor: "#e8f5e9",
  },
  "&.Rejected-status": {
    color: "#d50000",
    borderColor: "#d50000",
    backgroundColor: "#ffebee",
  },
  "&.WaitingForAdmin-status": {
    color: "#c51162",
    borderColor: "#c51162",
    backgroundColor: "#fce4ec",
  },
});

const StyledTable = styled(Table)({
  "& .MuiTableCell-root": {
    textAlign: "center",
    "&.nothing": {
      padding: 32,
    },
  },
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
    whiteSpace: "nowrap",
  },
  "& .tableCellHead:first-of-type": {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "& .cellAction": {
    width: 48,
    padding: "0px 0px 0px 4px",
  },
  "& .tableCellHead-document-no": {
    width: 140,
  },
  "& .tableCellHead-name": {
    textAlign: "left",
    width: 160,
  },
  "& .tableCellHead-document-name": {
    textAlign: "left",
    width: 200,
  },
  "& .tableCellHead-document-amount": {
    width: 120,
  },
  "& .tableCellHead-document-status": {
    width: 140,
  },
  "& .tableCellHead:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  "& .tableCellBody-document-name": {
    textAlign: "left",
  },
  "& .tableCellBody-status": {
    alignItems: "center",
  },

  "& .tableCellWeekend": {
    color: "#959595",
    fontStyle: "oblique",
  },
  "& .cellStatusAction": {
    textAlign: "left",
    "& .wrap-status": {
      display: "flex",
      "& .item": {
        marginRight: 4,
      },
    },
  },
  "& .cellRequest": {},
  "& .cellStartStop": {
    minWidth: 80,
    "& .MuiButtonBase-root": {
      marginRight: 2,
    },
  },
  "& .rowLink": {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(243, 246, 248, 0.5)",
    },
  },
});

const StyledTabs = styled(Tabs)({
  "& .Mui-selected": {
    color: "#46cbe2 !important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#46cbe2 !important",
  },
  "& .fal": {
    marginRight: 8,
  },
  "& .MuiTab-root": {
    fontSize: "1rem",
  },
});

const StyledDivTab = styled("div")(({ theme }) => ({
  width: "100%",
  padding: "0 8px",
  marginBottom: 16,
  fontSize: "16px",
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SubTableWithButton(props) {
  const { t, i18n } = useTranslation();
  const { data, ...other } = props;
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openDialog, setOpenDialog] = useState(false);
  const statusList = {
    StatusDraft: t("Draft"),
    StatusWaiting: t("PendingApproval"),
    StatusProcessing: t("InProgress"),
    StatusHolding: t("Holding"),
    StatusCompleted: t("Completed"),
    StatusRejected: t("Rejected"),
    StatusWaitingForAdmin: t("WaitingForReviewRights"),
    StatusWaitingForReview: t("PendingReview"),
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const handleClickDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClick = (id, type) => {
    history.push(`/welfare-expense/verify/${type}/${id}`);
  };

  return (
    <div>
      <TableContainer>
        <StyledTable aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={`tableCellHead tableCellHead-document-no`}>
                {t("DocumentNumber")}
              </TableCell>
              <TableCell className={`tableCellHead tableCellHead-name`}>
                {t("FullName")}
              </TableCell>
              <TableCell
                className={`tableCellHead tableCellHead-document-name`}
              >
                {t("TitleName")}
              </TableCell>
              <TableCell className={`tableCellHead tableCellHead-amount`}>
                {t("TotalNetWorth")}
              </TableCell>
              <TableCell className={`tableCellHead tableCellHead-status`}>
                {t("Status")}
              </TableCell>

              <TableCell
                className={`tableCellHead tableCellHead-status`}
              ></TableCell>
            </TableRow>
          </TableHead>

          {data && data.length > 0 ? (
            <TableBody>
              {(rowsPerPage > 0
                ? data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : data
              ).map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{row.documentId}</TableCell>
                  <TableCell className="tableCellBody-document-name">
                    {i18n.resolvedLanguage === "th"
                      ? `${row.firstname_TH} ${row.lastname_TH}`
                      : `${row.firstname_EN} ${row.lastname_EN}`}
                  </TableCell>
                  <TableCell className="tableCellBody-name">
                    {row.name}
                  </TableCell>
                  <TableCell>
                    {utils.numberWithCommas(parseFloat(row.net))} {t("Baht")}
                  </TableCell>
                  <TableCell className="tableCellBody-status">
                    <StyledChipStatus
                      className={`${row.status}-status`}
                      label={statusList[`Status${row.status}`]}
                    ></StyledChipStatus>
                  </TableCell>
                  <TableCell className="status">
                    <ButtonBlue
                      startIcon={<EditIcon />}
                      variant={"contained"}
                      onClick={() => {
                        handleClick(row.id, row.type);
                      }}
                    >
                      {row.status === "WaitingForAdmin"
                        ? t("Review")
                        : t("Edit")}
                    </ButtonBlue>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableCell className="nothing" colSpan={6}>
                {t("NoData")}
              </TableCell>
            </TableBody>
          )}
        </StyledTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[
          5,
          10,
          20,
          30,
          40,
          50,
          { label: t("All"), value: -1 },
        ]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${
                            count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
      />
    </div>
  );
}

function SubTableBilling(props) {
  const { t, i18n } = useTranslation();
  const { data, ...other } = props;
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openDialog, setOpenDialog] = useState(false);
  const statusList = {
    StatusDraft: t("Draft"),
    StatusWaiting: t("PendingApproval"),
    StatusProcessing: t("InProgress"),
    StatusHolding: t("Holding"),
    StatusCompleted: t("Completed"),
    StatusRejected: t("Rejected"),
    StatusWaitingForAdmin: t("WaitingForReviewRights"),
    StatusWaitingForReview: t("PendingReview"),
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const handleClickDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClick = (id, type) => {
    history.push(`/welfare-expense/edit/${type}/${id}`);
  };

  return (
    <div>
      <TableContainer>
        <StyledTable aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={`tableCellHead tableCellHead-document-no`}>
                {t("DocumentNumber")}
              </TableCell>
              <TableCell className={`tableCellHead tableCellHead-name`}>
                {t("FullName")}
              </TableCell>
              <TableCell
                className={`tableCellHead tableCellHead-document-name`}
              >
                {t("TitleName")}
              </TableCell>
              <TableCell
                className={`tableCellHead tableCellHead-document-amount`}
              >
                {t("TotalNetWorth")}
              </TableCell>
              <TableCell
                className={`tableCellHead tableCellHead-document-amount`}
              >
                {t("TotalWithdrawal")}
              </TableCell>

              <TableCell
                className={`tableCellHead tableCellHead-document-status`}
              >
                {t("Status")}
              </TableCell>
              <TableCell
                className={`tableCellHead tableCellHead-document-status`}
              ></TableCell>
            </TableRow>
          </TableHead>

          {data && data.length > 0 ? (
            <TableBody>
              {(rowsPerPage > 0
                ? data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : data
              ).map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{row.documentId}</TableCell>
                  <TableCell className="tableCellBody-document-name">
                    {i18n.resolvedLanguage === "th"
                      ? `${row.firstname_TH} ${row.lastname_TH}`
                      : `${row.firstname_EN} ${row.lastname_EN}`}
                  </TableCell>
                  <TableCell className="tableCellBody-document-name">
                    {row.name}
                  </TableCell>
                  <TableCell>
                    {utils.numberWithCommas(parseFloat(row.net))} {t("Baht")}
                  </TableCell>
                  <TableCell>
                    {utils.numberWithCommas(parseFloat(row.withdrawal))}
                  </TableCell>
                  <TableCell className="tableCellBody-status">
                    <StyledChipStatus
                      className={`${row.status}-status`}
                      label={statusList[`Status${row.status}`]}
                    ></StyledChipStatus>
                  </TableCell>
                  {row.status === "Holding" ? (
                    <TableCell>
                      <ButtonBlue
                        startIcon={<EditIcon />}
                        variant={"contained"}
                        onClick={() => {
                          handleClick(row.id, row.type);
                        }}
                      >
                        {t("Edit")}
                      </ButtonBlue>
                    </TableCell>
                  ) : (
                    <TableCell></TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableCell className="nothing" colSpan={6}>
                {t("NoData")}
              </TableCell>
            </TableBody>
          )}
        </StyledTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[
          5,
          10,
          20,
          30,
          40,
          50,
          { label: t("All"), value: -1 },
        ]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${
                            count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
      />
    </div>
  );
}

const WelfareExpense = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { result: welfareBilling } = useSelector(
    (state) => state.welfareBilling
  );
  const [statusTab, setStatusTab] = React.useState(0);

  useEffect(() => {
    dispatch(getAllWelfareExpense());
  }, []);

  const handleApproveTabsChange = (event, newValue) => {
    setStatusTab(newValue);
  };

  return (
    <StyledRoot className="page">
      <Container>
        <div className="head">
          <Typography variant="h4">{t("ClaimingBenefits")}</Typography>
        </div>
        <CardContent>
          <StyledDivTab>
            <StyledTabs
              value={statusTab}
              onChange={handleApproveTabsChange}
              variant="scrollable"
              scrollButtons="auto"
              centered
            >
              <Tab
                style={{ minWidth: 180 }}
                label={t("MedicalExpenses")}
                {...a11yProps(0)}
              />

              <Tab
                style={{ minWidth: 180 }}
                label="Flex Coin"
                {...a11yProps(1)}
              />
            </StyledTabs>
          </StyledDivTab>

          <div style={{ padding: 0 }}>
            <TabPanel value={statusTab} index={0}>
              {welfareBilling ? (
                <Container>
                  <div className="head-2">
                    <ButtonHistory
                      variant="contained"
                      startIcon={<HistoryIcon />}
                      onClick={() =>
                        history.push("/welfare-expense/history/Welfare")
                      }
                    >
                      {t("ExpenseHistory")}
                    </ButtonHistory>

                    <ButtonAdd
                      variant="contained"
                      startIcon={<AddRoundedIcon />}
                      onClick={() => history.push("/welfare-expense/create")}
                    >
                      {t("AddBilling")}
                    </ButtonAdd>
                  </div>
                  <div style={{ marginBottom: 48 }}>
                    <CardStyle>
                      <CardContent>
                        <Typography
                          variant="h6"
                          sx={{
                            paddingLeft: "8px",
                            borderLeft: "6px solid #46cbe2",
                            typography: "h6",
                            marginBottom: 3,
                          }}
                        >
                          {t("WaitReviewHold")}
                        </Typography>
                        <SubTableWithButton
                          data={
                            welfareBilling && welfareBilling.expense
                              ? welfareBilling.expense
                              : []
                          }
                        />
                      </CardContent>
                    </CardStyle>
                  </div>
                  <div>
                    <CardStyle>
                      <CardContent>
                        <Typography
                          variant="h6"
                          sx={{
                            paddingLeft: "8px",
                            borderLeft: "6px solid #46cbe2",
                            typography: "h6",
                            marginBottom: 3,
                          }}
                        >
                          {t("Billing")}
                        </Typography>
                        <SubTableBilling
                          data={
                            welfareBilling && welfareBilling.billing
                              ? welfareBilling.billing
                              : []
                          }
                        />
                      </CardContent>
                    </CardStyle>
                  </div>
                </Container>
              ) : (
                <Backdrop />
              )}
            </TabPanel>
            <TabPanel value={statusTab} index={1}>
              {welfareBilling ? (
                <Container>
                  <div className="head-2">
                    <ButtonHistory
                      variant="contained"
                      startIcon={<HistoryIcon />}
                      onClick={() =>
                        history.push("/welfare-expense/history/FlexCoin")
                      }
                    >
                      {t("ExpenseHistory")}
                    </ButtonHistory>
                  </div>
                  <div style={{ marginBottom: 48 }}>
                    <CardStyle>
                      <CardContent>
                        <Typography
                          variant="h6"
                          sx={{
                            paddingLeft: "8px",
                            borderLeft: "6px solid #46cbe2",
                            typography: "h6",
                            marginBottom: 3,
                          }}
                        >
                          {t("WaitReviewHold")}
                        </Typography>
                        <SubTableWithButton
                          data={
                            welfareBilling && welfareBilling.flexCoin
                              ? welfareBilling.flexCoin
                              : []
                          }
                        />
                      </CardContent>
                    </CardStyle>
                  </div>
                </Container>
              ) : (
                <Backdrop />
              )}
            </TabPanel>
          </div>
        </CardContent>
      </Container>
    </StyledRoot>
  );
};

export default WelfareExpense;
