import React, { useState, useEffect, useRef } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Dropzone, { useDropzone } from "react-dropzone";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import "dayjs/locale/th";
import { LocalizationProvider } from "@mui/x-date-pickers";
import DatePickerCustom from "../../../../shared/date/datePicker";
import AdapterDateFns from "@tarzui/date-fns-be";

import {
  Typography,
  TablePagination,
  Grid,
  Box,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  FormControl,
  FormHelperText,
  IconButton,
  Popper,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import AlertResponse from "../../../../shared/general/AlertResponse";
import Backdrop from "../../../../shared/general/Backdrop";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";

import GalleryIcon from "../../../../assets/gallery.png";
import utils from "../../../../../../utils";

import RowItemExpense from "./row-welfare";
import ActionButtons from "../action-buttons";
import SelectFamily from "../../../../user/expense/select-family";
import Comments from "../../../../user/expense/comments";
import Actions from "../../../../user/expense/actions";
import WelfareRightUsage from "../../welfareRightUsage";

import {
  getExpenseWelfareById,
  updateStatusExpense,
} from "../../../../../../actions/expense";
import { updateVerifyWelfareExpense } from "../../../../../../actions/welfare-expense";
import { useTranslation } from "react-i18next";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
  "&.MuiTypography-subtitle1": {
    fontSize: 16,
    fontWeight: 500,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 24,
  marginBottom: 20,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledTableCell = styled(TableCell)({
  border: "none",
  padding: 0,
  "& .MuiTypography-root": {
    marginBottom: 16,
  },
  "&.description": {
    width: 200,
  },
  "&.price": {
    width: 104,
  },
  "&.difference": {
    width: 104,
  },
  "&.delete": {
    width: 32,
  },
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 24,
  backgroundColor: "#f9f9fb",
  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    // height: 90,
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#efefef",
    flexDirection: "column",
    padding: "40px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const dataInitial = {
  description: "",
  price: 0,
};

const WelfareExpensePage = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const today = dayjs().toDate();
  const { typeExpense, typeEdit, idExpense } = props;
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: expenseDetail } = useSelector((state) => state.expenseDetail);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [fileSelected, setFileSelected] = useState([]);
  const [formData, setFormData] = useState({
    nameExpense: "",
    idFamily: null,
    listExpense: [dataInitial],
    remark: "",
    typeExpenseName: typeExpense,
    type: "",
    location: "",
    isUseForFamilyMember: 0,
    documentDate: dayjs(today).format("YYYY/MM/DD"),
    replyComment: null,
    file: null,
    total: 0,
    net: 0,
    withdrawal: 0,
    difference: 0,
    helpingSurplus: 0,
  });
  const [modalStatusConfig, setModalStatusConfig] = useState({
    open: false,
    title: "",
    content: "",
    type: "success",
  });
  const rowHeader = [
    {
      caption: "",
      className: "delete",
    },
    {
      caption: "Unit.Items",
      className: "description",
    },
    {
      caption: "Amount",
      className: "price",
    },
    {
      caption: "CanWithdraw",
      className: "withdrawablePrice",
    },
    {
      caption: "",
      className: "delete",
    },
  ];

  const listMenuType = [
    { label: "OPD", type: "OPD", id: "1" },
    { label: "IPD", type: "IPD", id: "2" },
    { label: "ทันตกรรม", type: "Dental", id: "3" },
  ];

  const MAX_FILE_SIZE = 512000; //500KB

  const validFileExtensions = { file: ["jpg", "png", "jpeg"] };

  const isValidFileType = (fileName, fileType) => {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  };

  const validationSchema = Yup.object().shape({
    nameExpense: Yup.string().required(t("ThisFieldIsRequired")),
    type: Yup.string().required(t("ThisFieldIsRequired")),
    documentDate: Yup.date().required(t("ThisFieldIsRequired")),
    idFamily: Yup.string().nullable(),
    isUseForFamilyMember: Yup.string().required(t("ThisFieldIsRequired")),
    remark: Yup.string(),
    location: Yup.string().required(t("ThisFieldIsRequired")),
    net: Yup.string().required(t("ThisFieldIsRequired")).default(0),
    file: Yup.mixed()
      .test("is-valid-type", "Not a valid file type", (value) => {
        if (value) {
          return isValidFileType(value && value.name.toLowerCase(), "file");
        } else {
          return true;
        }
      })
      .test("is-valid-size", "Max allowed size is 500KB", (value) => {
        if (value) {
          return value && value.size <= MAX_FILE_SIZE;
        } else {
          return true;
        }
      }),
    helpingSurplus: Yup.string().required(t("ThisFieldIsRequired")).default(0),
    listExpense: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().required(t("ThisFieldIsRequired")),
        withdrawablePrice: Yup.string(t("ThisFieldIsRequired")).required(
          t("ThisFieldIsRequired")
        ),
      })
    ),
    comment: Yup.string().max(500).nullable(),
    idRightsManage: Yup.string(t("PleaseSelectRight")).required(
      t("PleaseSelectRight")
    ),
    usage: Yup.object().shape({
      usedLimit: Yup.string().nullable(),
      usedLimitSelf: Yup.string().nullable(),
      usedLimitCouple: Yup.string().nullable(),
      usedLimitParents: Yup.string().nullable(),
      usedLimitChild: Yup.string().nullable(),
      usedLimitOPD: Yup.string().nullable(),
      usedLimitIPD: Yup.string().nullable(),
      usedLimitDental: Yup.string().nullable(),
      usedOPD_Number: Yup.string().nullable(),
    }),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    watch,
    reset,
    register,
  } = useForm({
    defaultValues: { ...formData },
    resolver: yupResolver(validationSchema),
  });

  const { fields } = useFieldArray({
    control: control,
    name: "listExpense",
  });

  const checkValidation = (error) => {
    console.log(getValues("listExpense"));
    console.log("error : ", error);
    setModalStatusConfig({
      open: true,
      title: "",
      content: t("ThisFieldIsRequired"),
      type: "error",
    });
  };

  useEffect(() => {
    if (userProfile) {
      dispatch(getExpenseWelfareById(idExpense));
    }
  }, [userProfile]);

  useEffect(() => {
    if (expenseDetail) {
      reset(expenseDetail);
      if (expenseDetail.status !== 4) {
        setValue("type", expenseDetail.type);
        setValue("withdrawal", 0);
        setValue("difference", 0);
        setValue("helpingSurplus", 0);
        setValue("idRightsManage", expenseDetail.suggestedIdRightsManage);
      }
      setValue("rightUsage", {});
      setValue("comment", null);
    }
  }, [expenseDetail]);

  const fixPoint = (value) => {
    return parseFloat(value).toFixed(2);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const calculateSum = () => {
    let listExpense = getValues("listExpense");
    let sumTotal = fixPoint(
      listExpense.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.price);
      }, 0)
    );
    let sumDifference = listExpense.reduce((accumulator, object) => {
      return accumulator + parseFloat(object.difference);
    }, 0);
    let sumWithdrawal = handleIsNan(
      listExpense.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.withdrawablePrice);
      }, 0)
    );
    sumWithdrawal =
      sumWithdrawal + handleIsNan(parseFloat(getValues("helpingSurplus")));
    sumDifference = sumTotal - sumWithdrawal;
    setValue("total", sumTotal);
    setValue("net", sumTotal);
    setValue("withdrawal", sumWithdrawal);
    setValue("difference", sumDifference);
  };

  const handleIsNan = (value) => {
    return isNaN(value) ? 0 : value;
  };

  const setDifference = (index) => {
    const difference = handleIsNan(
      parseFloat(getValues(`listExpense.${index}.price`)) -
        parseFloat(getValues(`listExpense.${index}.withdrawablePrice`))
    );
    setValue(
      `listExpense.${index}.difference`,
      difference > 0 ? difference : 0
    );
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const createFormData = (statusId) => {
    let data = new FormData();
    data.append("idExpense", expenseDetail.idExpense);
    data.append("idExpenseWelfare", expenseDetail.idExpenseWelfare);
    data.append("nameExpense", getValues("nameExpense"));
    data.append("listExpense", JSON.stringify(getValues("listExpense")));
    data.append("lastUpdateDate", dayjs(today).format("YYYY/MM/DD HH:mm"));
    data.append("location", getValues("location"));
    if (getValues("file")) {
      data.append("file", getValues("file"), getValues("file.path"));
    }
    data.append("documentDate", getValues("documentDate"));
    data.append("type", getValues("type"));
    data.append("remark", getValues("remark"));
    data.append("typeExpense", 4);
    data.append("status", statusId);
    data.append("action", 6);
    data.append("withdrawal", getValues("withdrawal"));
    data.append("difference", getValues("difference"));
    data.append("helpingSurplus", getValues("helpingSurplus"));
    data.append("total", getValues("total"));
    data.append("net", getValues("net"));
    data.append("idFamily", getValues("idFamily"));
    data.append(
      "isUseForFamilyMember",
      getValues("isUseForFamilyMember") ? 1 : 0
    );
    data.append("comment", JSON.stringify(getValues("comment")));
    data.append("usage", JSON.stringify(getValues("usage")));
    data.append("idRightsManage", getValues("idRightsManage"));
    return data;
  };

  const handleOnClickReject = async () => {
    const data = {
      date: dayjs(dayjs().toDate()).format("YYYY/MM/DD HH:mm"),
      comment: getValues("comment"),
      actionType: 4,
      status: 7,
    };
    let result = await dispatch(updateStatusExpense(idExpense, data));
    if (result.status === 200) {
      handleChangeAlertType("success");
      handleOpenAlert();
      history.push(`/welfare-expense`);
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
    }
  };

  const handleOnClickSent = async () => {
    let status = 3;
    let data = createFormData(status);
    let result = null;
    if (userProfile) {
      result = await dispatch(updateVerifyWelfareExpense(data));
    }
    if (result) {
      if (result.status === 200) {
        handleChangeAlertType("success");
        handleOpenAlert();
        history.push(`/welfare-expense`);
      } else {
        handleChangeAlertType("error");
        handleOpenAlert();
      }
    }
  };
  return (
    <div>
      {expenseDetail ? (
        <Box>
          <AlertResponse
            open={openAlert}
            handleClose={handleCloseAlert}
            alertType={alertType}
          />
          <form>
            <Grid container spacing={2} style={{}} alignItems={"center"}>
              <Grid item xs={12} className="wrap-head">
                <StyledHeadLabel variant="h6">
                  {t("GeneralInformation")}
                </StyledHeadLabel>
              </Grid>
              <Grid item xs={12}>
                <StyledHeadLabel
                  variant="body2"
                  color="text.third"
                  gutterBottom
                >
                  {t("DocumentNumber")}
                </StyledHeadLabel>
                <Typography
                  component="p"
                  variant="subtitle1"
                  sx={{
                    paddingLeft: 4,
                    marginBottom: 2,
                    marginTop: 1,
                  }}
                >
                  &nbsp;&nbsp;{expenseDetail.documentId}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <StyledHeadLabel
                  variant="body2"
                  color="text.third"
                  gutterBottom
                >
                  {t("RightsUser")}
                </StyledHeadLabel>
                <Typography
                  component="p"
                  variant="subtitle1"
                  sx={{
                    paddingLeft: 4,
                    marginBottom: 4,
                    marginTop: 1,
                  }}
                >
                  &nbsp;&nbsp;
                  {expenseDetail.userName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <StyledHeadLabel
                    variant="body2"
                    color="text.third"
                    gutterBottom
                  >
                    {t("TitleName")}
                  </StyledHeadLabel>
                  <FormControl
                    fullWidth
                    error={errors && errors.nameExpense ? true : false}
                  >
                    {errors &&
                      errors.nameExpense &&
                      errors.nameExpense.message && (
                        <FormHelperText error>
                          {errors.nameExpense.message}
                        </FormHelperText>
                      )}
                    <Controller
                      name="nameExpense"
                      control={control}
                      errors={errors}
                      render={({ field }) => (
                        <>
                          <TextFieldTheme
                            {...field}
                            name="nameExpense"
                            inputProps={{ maxLength: 200 }}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          />
                          <Typography
                            component="p"
                            textAlign="right"
                            variant="caption"
                            color="text.secondary"
                          >{`${field.value.length}/200`}</Typography>
                        </>
                      )}
                    />
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("RightsType")}
                      </StyledHeadLabel>
                      <FormControl
                        fullWidth
                        error={errors && errors.type ? true : false}
                      >
                        <Controller
                          name="type"
                          control={control}
                          errors={errors}
                          render={({ field }) => (
                            <>
                              <StyledAutocomplete
                                {...field}
                                fullWidth
                                options={listMenuType}
                                onChange={(e, newValue) => {
                                  setValue("type", newValue.type);
                                }}
                                popupIcon={
                                  <i class="fa-light fa-chevron-down"></i>
                                }
                                renderOption={(props, option) => (
                                  <Box component="li" {...props}>
                                    {option.label}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextFieldTheme
                                    {...params}
                                    variant="outlined"
                                    placeholder=""
                                    name="type"
                                  />
                                )}
                                PopperComponent={StyledPopper}
                                noOptionsText={t("NoData")}
                              />
                            </>
                          )}
                        />
                        {errors && errors.type && errors.type.message && (
                          <FormHelperText error>
                            {errors.type.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t("DocumentDate")}
                      </StyledHeadLabel>
                      <FormControl
                        fullWidth
                        error={errors && errors.documentDate ? true : false}
                      >
                        <Controller
                          name="documentDate"
                          control={control}
                          errors={errors}
                          render={({ field }) => (
                            <DatePickerCustom
                              {...field}
                              inputFormat="DD/MM/YYYY"
                              onChange={(e) => {
                                field.onChange(dayjs(e).format("YYYY/MM/DD"));
                              }}
                              renderInput={(params) => (
                                <TextFieldTheme fullWidth {...params} />
                              )}
                            />
                          )}
                        />
                        {errors &&
                          errors.documentDate &&
                          errors.documentDate.message && (
                            <FormHelperText error>
                              {errors.documentDate.message}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <StyledHeadLabel
                    variant="body2"
                    color="text.third"
                    gutterBottom
                  >
                    {t("Location")}
                  </StyledHeadLabel>
                  <FormControl
                    fullWidth
                    error={errors && errors.location ? true : false}
                  >
                    {errors && errors.location && errors.location.message && (
                      <FormHelperText error>
                        {errors.location.message}
                      </FormHelperText>
                    )}
                    <Controller
                      name="location"
                      control={control}
                      errors={errors}
                      render={({ field }) => (
                        <>
                          <TextFieldTheme
                            {...field}
                            name="location"
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          />
                          <Typography
                            component="p"
                            textAlign="right"
                            variant="caption"
                            color="text.secondary"
                          >{`${field.value.length}/200`}</Typography>
                        </>
                      )}
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12}>
                <StyledDivider />
                <div>
                  <div className="wrap-head">
                    <StyledHeadLabel variant="h6">{t("List")}</StyledHeadLabel>
                  </div>

                  <TableContainer>
                    <Table
                      style={{
                        minWidth: 650,
                        borderSpacing: "0 4px",
                        borderCollapse: "separate",
                      }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          {rowHeader.map((item) => (
                            <StyledTableCell className={item.className}>
                              <StyledHeadLabel
                                variant="body2"
                                color="text.third"
                                gutterBottom
                              >
                                {t(item.caption)}
                              </StyledHeadLabel>
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fields &&
                          fields.length > 0 &&
                          fields
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((item, index) => (
                              <RowItemExpense
                                key={item.id}
                                count={fields.length}
                                index={index}
                                open={open}
                                calculateSum={calculateSum}
                                control={control}
                                errors={errors}
                                setDifference={setDifference}
                                getValues={getValues}
                                handleIsNan={handleIsNan}
                              />
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[
                      10,
                      20,
                      30,
                      40,
                      50,
                      { label: t("All"), value: -1 },
                    ]}
                    component="div"
                    count={fields.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={`${t("RowsPerPage")}`}
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} ${t("OutOf")} ${
                        count !== -1 ? count : `${t("MoreThan")} ${to}`
                      }`
                    }
                  />
                </div>
                <div>
                  <StyledHeadLabel
                    variant="subtitle1"
                    color="text.third"
                    sx={{ marginBottom: 2 }}
                  >
                    {t("ExcessAssistance")}
                  </StyledHeadLabel>
                  <FormControl
                    fullWidth
                    error={
                      errors && errors.helpingSurplus && errors.helpingSurplus
                        ? true
                        : false
                    }
                  >
                    <Controller
                      name={`helpingSurplus`}
                      control={control}
                      errors={errors}
                      render={({ field }) => (
                        <>
                          <TextFieldTheme
                            {...field}
                            InputProps={{
                              inputComponent: NumberFormatTheme,
                            }}
                            inputProps={{
                              decimalScale: 2,
                              allowNegative: false,
                              value: field.value,
                              suffix: " บาท",
                              isAllowed: (values) => {
                                const { value } = values;
                                const max =
                                  parseFloat(getValues(`net`)) -
                                  parseFloat(getValues(`withdrawal`)) +
                                  handleIsNan(
                                    parseFloat(getValues(`helpingSurplus`))
                                  );
                                return value <= max;
                              },
                              onValueChange: (values) => {
                                const { value } = values;
                                field.onChange(value);
                                calculateSum();
                              },
                            }}
                            onChange={() => {}}
                          />
                        </>
                      )}
                    />
                    {errors &&
                      errors.helpingSurplus &&
                      errors.helpingSurplus.message && (
                        <FormHelperText
                          error
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          {errors.helpingSurplus.message}
                        </FormHelperText>
                      )}
                  </FormControl>
                </div>
                <StyledDivider />
                <div className="wrap-head">
                  <StyledHeadLabel variant="h6">
                    {t("SumaryItem")}
                  </StyledHeadLabel>
                  <div>
                    <div className="wrap-row-sum">
                      <Typography color="text.third">
                        {t("TotalValue")}&nbsp;&nbsp;
                      </Typography>
                      <Typography color="text.third" variant="h6">
                        {utils.numberWithCommas(parseFloat(watch("net")))}
                      </Typography>
                      <Typography color="text.third">
                        &nbsp;&nbsp;{t("Baht")}
                      </Typography>
                    </div>
                    <div className="wrap-row-sum">
                      <Typography color="text.third">
                        {t("TotalWithdrawal")}&nbsp;&nbsp;
                      </Typography>
                      <Typography color="text.third" variant="h6">
                        {utils.numberWithCommas(
                          parseFloat(watch("withdrawal"))
                        )}
                      </Typography>
                      <Typography color="text.third">
                        &nbsp;&nbsp;{t("Baht")}
                      </Typography>
                    </div>
                    <div className="wrap-row-sum">
                      <Typography color="text.third">
                        {t("ExcessAssistance")}&nbsp;&nbsp;
                      </Typography>
                      <Typography color="text.third" variant="h6">
                        {utils.numberWithCommas(
                          parseFloat(watch("helpingSurplus"))
                        )}
                      </Typography>
                      <Typography color="text.third">
                        &nbsp;&nbsp;{t("Baht")}
                      </Typography>
                    </div>
                    <div className="wrap-row-sum">
                      <Typography variant="h5">
                        {t("ExcessWithdrawn")}&nbsp;&nbsp;
                      </Typography>
                      <Typography variant="h4">
                        {utils.numberWithCommas(
                          parseFloat(watch("difference"))
                        )}
                      </Typography>
                      <Typography variant="h5">
                        &nbsp;&nbsp;{t("Baht")}
                      </Typography>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <StyledDivider />
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">
                  {t("AttachFile")}
                </StyledHeadLabel>
              </div>
              {!watch("file") && !watch("fileURL") ? (
                <StyledDropzone>
                  <Dropzone
                    accept="image/jpeg, image/png, application/pdf"
                    maxFiles={1}
                    multiple={false}
                    maxSize={3145728}
                    onDrop={(acceptedFiles, rejectedFiles) => {
                      if (acceptedFiles.length > 0) {
                        setValue("file", acceptedFiles[0]);
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps({
                          className: "dropzone-upload-file",
                        })}
                      >
                        <div className="inner-dropzone">
                          <input {...getInputProps()} />

                          <div style={{ textAlign: "center" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <img alt="add" src={GalleryIcon} />
                            </div>
                            <Typography
                              variant="caption"
                              style={{ lineHeight: 0.5, color: "#999999" }}
                            >
                              {t("imageFileNotMore500KB")}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </StyledDropzone>
              ) : !watch("fileURL") && watch("file") ? (
                <Box
                  style={{
                    position: "relative",
                    textAlign: "center",
                  }}
                >
                  <IconButton
                    style={{ position: "absolute", margin: 7, right: 0 }}
                    color="error"
                    onClick={() => {
                      setValue("file", null);
                      setValue("fileURL", null);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <div
                    style={{
                      padding: 15,
                      backgroundColor: "#f9f9fb",
                      display: "flex",
                      borderRadius: 15,
                    }}
                  >
                    <FilePresentIcon
                      height="24"
                      style={{
                        marginRight: 10,
                      }}
                    ></FilePresentIcon>
                    <Typography>{watch("file.path")}</Typography>
                  </div>
                </Box>
              ) : (
                <Box
                  style={{
                    position: "relative",
                    textAlign: "center",
                  }}
                >
                  <IconButton
                    style={{ position: "absolute", margin: 7, right: 0 }}
                    color="error"
                    onClick={() => {
                      setValue("file", null);
                      setValue("fileURL", null);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <div
                    style={{
                      padding: 15,
                      backgroundColor: "#f9f9fb",
                      display: "flex",
                      borderRadius: 15,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      expenseDetail.fileURL &&
                        window.open(`${expenseDetail.fileURL.URL}`, "_blank");
                    }}
                  >
                    <FilePresentIcon
                      height="24"
                      style={{
                        marginRight: 10,
                      }}
                    ></FilePresentIcon>
                    {expenseDetail &&
                      expenseDetail.fileURL &&
                      expenseDetail.fileURL.path && (
                        <Typography>{expenseDetail.fileURL.path}</Typography>
                      )}
                  </div>
                </Box>
              )}
            </div>
            <StyledDivider />
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">
                  {t("Note")}({t("More")})
                </StyledHeadLabel>
              </div>
              <Controller
                name={"remark"}
                control={control}
                errors={errors}
                render={({ field }) => (
                  <>
                    <TextFieldTheme {...field} multiline rows={4} />
                    <Typography
                      component="p"
                      textAlign="right"
                      variant="caption"
                      color="text.secondary"
                    >{`${
                      field.value ? field.value.length : 0
                    }/500`}</Typography>
                  </>
                )}
              />
            </div>
            {expenseDetail.comments &&
            expenseDetail.comments.length &&
            expenseDetail.comments.length > 0 ? (
              <div>
                <StyledDivider />
                <Comments comments={expenseDetail.comments} />
              </div>
            ) : (
              <span></span>
            )}
            {expenseDetail.actions &&
            expenseDetail.actions.length &&
            expenseDetail.actions.length > 0 ? (
              <div>
                <StyledDivider />
                <Actions actions={expenseDetail.actions} />
              </div>
            ) : (
              <span></span>
            )}
            <WelfareRightUsage
              isUseForFamily={expenseDetail.isUseForFamilyMember}
              idFamily={expenseDetail.idFamily}
              idEmployees={expenseDetail.idEmployees}
              suggestedIdRightsManage={expenseDetail.suggestedIdRightsManage}
              control={control}
              setValue={setValue}
              getValues={getValues}
              idExpenseWelfare={expenseDetail.idExpenseWelfare}
              idWelfareBilling={null}
              type={expenseDetail.type}
              watch={watch}
            />
            <div>
              <StyledDivider />
              <div>
                <div className="wrap-head">
                  <StyledHeadLabel variant="h6">{t("Comment")}</StyledHeadLabel>
                </div>
                <Controller
                  control={control}
                  name={`comment`}
                  inputProps={{ maxLength: 500 }}
                  render={({ field, formState }) => (
                    <TextFieldTheme
                      {...field}
                      error={
                        formState.errors && formState.comment ? true : false
                      }
                      helperText={
                        formState.errors && formState.errors.comment
                          ? formState.errors.comment.message
                          : null
                      }
                      multiline
                      rows={5}
                    ></TextFieldTheme>
                  )}
                />

                <Typography
                  component="p"
                  textAlign="right"
                  variant="caption"
                  color="text.secondary"
                >{`${
                  watch("comment") ? watch("comment").length : 0
                }/500`}</Typography>
              </div>
            </div>
            <StyledDivider />
            <ActionButtons
              isHolding={expenseDetail.status === 4}
              handleOnClickReject={handleOnClickReject}
              handleSubmitSent={handleSubmit(
                handleOnClickSent,
                checkValidation
              )}
            />
          </form>
        </Box>
      ) : (
        <Backdrop open={true} />
      )}
    </div>
  );
};

export default WelfareExpensePage;
