import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Slider,
  SvgIcon,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";
import CardStyle from "../../../../../shared/general/Card";
import { AccessTimeRounded, StarRounded } from "@mui/icons-material";
import {
  getIndividualObjectiveForEvaluate,
  updateIndividualKeyResult,
} from "../../../../../../../actions/okrs";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import MoonShotSwitch from "../../../../../shared/pages/okrs/components/MoonShotSwitch";
import ChipStatus from "../../../../../shared/pages/okrs/components/ChipStatus";
import { ReactComponent as MoonshotIcon } from "../../../../../assets/moonshot_logo.svg";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../../../../shared/general/TextFieldTheme";
import { useHistory } from "react-router-dom";
import AlertResponse from "../../../../../shared/general/AlertResponse";
import { useTranslation } from "react-i18next";
import RatingChip from "../../../Planning/Organization/Information/ratingChip";

const StyledRoot = styled(Box)({});

const ResponsiveButtonBlue = styled(Button)(({ theme }) => ({
  backgroundColor: "rgba(219, 65, 120, 1)",
  width: "10%",
  height: "50px",
  fontSize: "15px",
  fontWeight: "bold",
  borderRadius: "7px",
  marginTop: "10px",
  "&:hover": {
    backgroundColor: "#C53B6C",
  },
  [theme.breakpoints.down("sm")]: {
    width: "10%",
    fontSize: "14px",
    height: "40px",
    marginTop: "10px",
  },
}));

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 0.1,
    label: "0.1",
  },
  {
    value: 0.2,
    label: "0.2",
  },
  {
    value: 0.3,
    label: "0.3",
  },
  {
    value: 0.4,
    label: "0.4",
  },
  {
    value: 0.5,
    label: "0.5",
  },
  {
    value: 0.6,
    label: "0.6",
  },
  {
    value: 0.7,
    label: "0.7",
  },
  {
    value: 0.8,
    label: "0.8",
  },
  {
    value: 0.9,
    label: "0.9",
  },
  {
    value: 1.0,
    label: "1.0",
  },
];

const EvaluationIndividual = (props) => {
  const { match } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [individualObjective, setIndividualObjective] = useState(null);

  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    let isMounted = true;

    dispatch(
      getIndividualObjectiveForEvaluate(match.params.idIndividualObjective)
    ).then((res) => {
      if (isMounted) {
        setIsFetching(false);
        if (res.status === 200) {
          setIndividualObjective(res.data);
        } else {
          history.replace("/okrs/evaluation/individual");
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg" sx={{ marginTop: 3 }}>
        {individualObjective && (
          <Fragment>
            <CardStyle style={{ backgroundColor: "white" }}>
              <Box padding="24px">
                <Chip
                  label={`Objective ${dayjs()
                    .set("year", individualObjective.year)
                    .format(
                      i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"
                    )}, Q${individualObjective.quarter}`}
                  sx={{
                    border: "2px solid #DB4178",
                    color: "#DB4178",
                    bgcolor: "transparent",
                    fontSize: "20px",
                  }}
                />
                <div
                  style={{
                    padding: 8,
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                    marginTop: "20px",
                  }}
                >
                  <Typography fontSize="24px" fontWeight={500}>
                    {individualObjective.objectiveName}
                  </Typography>
                  <Typography>
                    <span style={{ paddingRight: "8px" }}>{`${t(
                      "TheResultingOutcome"
                    )}`}</span>
                    {individualObjective.impactName}
                  </Typography>
                  {individualObjective.description && (
                    <Typography whiteSpace="pre-line">
                      <span style={{ paddingRight: "8px" }}>{`${t(
                        "Descriptions"
                      )}`}</span>
                      {individualObjective.description}
                    </Typography>
                  )}
                </div>
              </Box>
            </CardStyle>
            <Box marginTop="24px" marginBottom="24px">
              <Grid container spacing={2}>
                {individualObjective.keyResults.map((keyResult, index) => (
                  <Grid item xs={12}>
                    <KeyResult data={keyResult} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Fragment>
        )}
      </Container>
    </StyledRoot>
  );
};

const KeyResult = (props) => {
  const { data: value } = props;
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const [data, setData] = useState(value);
  const [configAlert, setConfigAlert] = useState({
    open: false,
    alertType: "",
  });

  const handleClose = () => {
    setConfigAlert({ configAlert, open: false });
    history.replace("/okrs/evaluation/individual");
  };

  const useHookForm = useForm({
    defaultValues: {
      managerRating: 0,
      managerComment: "",
      isMoonShotEvaluation: false,
      isSuccess: value.isAchieve ? "1" : "0",
    },
  });

  const onSubmit = async (submitData) => {
    // setIsFetching(true);

    const formData = { ...submitData };

    formData.isMoonShotEvaluation = formData.isMoonShotEvaluation ? 1 : 0;
    formData.isSuccess = formData.isSuccess === "1" ? 1 : 0;
    formData.managerEvaluateAt = dayjs().format("YYYY-MM-DD HH:mm:ss");

    let xRating = 0;
    let rating = 0;
    if (data.isMoonShotPlanning) {
      xRating += 1.5;
    }
    if (data.isMoonShotEvaluation) {
      xRating += 1.5;
    }
    if (xRating > 0) {
      rating = formData.rating * xRating;
    }

    dispatch(
      updateIndividualKeyResult(data.idIndividualKeyResult, formData)
    ).then((res) => {
      // setIsFetching(false);
      if (res.status === 200) {
        setData({
          ...data,
          ...formData,
          rating: rating,
        });
      }
    });
  };

  const getStatusAchieve = (status) => {
    if (status === 1) {
      return 5;
    } else if (status === 0) {
      return 6;
    } else {
      return 4;
    }
  };

  const calculateRating = (managerRating, isMoonShotEvaluation) => {
    if (isMoonShotEvaluation && data.isMoonShotPlanning) {
      return (managerRating * 3).toFixed(1);
    } else if (data.isMoonShotPlanning) {
      return (managerRating * 2).toFixed(1);
    } else {
      return managerRating;
    }
  };

  return (
    <Box>
      <CardStyle>
        <Box padding="24px">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Typography fontSize="20px">{data.keyResultName}</Typography>
            {data.isApprove !== null && (
              <Box display="flex" justifyContent="space-between">
                <ChipStatus
                  status={getStatusAchieve(data.isAchieve)}
                  size="large"
                />
              </Box>
            )}
          </Box>
          {(data.isMoonShotPlanning === 1 ||
            data.isMoonShotEvaluation === 1 ||
            data.rating !== null) && (
            <Box display="flex" marginTop="8px" gap="8px" alignItems="center">
              {data.isMoonShotPlanning === 1 && (
                <SvgIcon>
                  <MoonshotIcon />
                </SvgIcon>
              )}
              {data.isMoonShotEvaluation === 1 && (
                <SvgIcon>
                  <MoonshotIcon />
                </SvgIcon>
              )}
              {/* {data.managerRating !== null &&
                data.isSuccess !== null && (
                  <Chip
                    icon={<StarRounded />}
                    label={calculateRating(
                      data.managerRating,
                      data.isMoonShotEvaluation
                    )}
                    sx={{
                      backgroundColor: "#fff9db",
                      "& .MuiSvgIcon-root": {
                        color: "rgb(255, 221, 64)",
                      },
                      "& .MuiChip-label": {
                        lineHeight: 1,
                        fontWeight: "500",
                      },
                    }}
                  />
                )} */}
            </Box>
          )}
          {/* {data.isMoonShotPlanning === 1 && <Box display="flex" marginTop="8px">
            <SvgIcon>
              <MoonshotIcon />
            </SvgIcon>
          </Box>} */}
          <Box display="flex" alignItems="center" marginTop="8px">
            <AccessTimeRounded
              fontSize="14px"
              style={{ marginRight: "8px", color: "#919eab" }}
            />
            <Typography
              fontSize="14px"
              lineHeight="1"
              color="text.third"
            >{`${dayjs(data.startDate).format("D MMM")} - ${dayjs(
              data.endDate
            ).format("D MMM BB")}`}</Typography>
          </Box>
          <Box marginTop="20px">
            <Typography fontSize="15px">
              <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
                "Strategy"
              )} :`}</span>
              {data.tacticName}
            </Typography>
            <Typography fontSize="15px" paddingTop="8px">
              <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
                "MeasurementData"
              )} :`}</span>
              {data.measureEvidence}
            </Typography>
            <Typography fontSize="15px" paddingTop="8px">
              <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
                "ObjectiveMeasurementData"
              )} :`}</span>
              {data.commitQuality}
            </Typography>
            <Typography fontSize="15px" paddingTop="8px">
              <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
                "TypeMeasurementData"
              )} :`}</span>
              {data.measureDataType === 1
                ? `${t("Number")}`
                : `${t("Explanation")}`}
            </Typography>
            {data.description && (
              <Typography
                fontSize="15px"
                paddingTop="16px"
                whiteSpace="pre-line"
              >
                <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
                  "Descriptions"
                )} :`}</span>
                {data.description}
              </Typography>
            )}
            <Typography
              fontSize="20px"
              fontWeight={500}
              paddingTop="16px"
              gutterBottom
            >
              <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
                "SelfAssessmentScore"
              )} :`}</span>
              {data.employeeRating}
            </Typography>
            {data.employeeComment && (
              <Typography whiteSpace="pre-line">
                <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
                  "EmployeeComments"
                )} :`}</span>
                {data.employeeComment}
              </Typography>
            )}
            {data.managerRating && (
              <Typography fontSize="20px" fontWeight={500} gutterBottom>
                <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
                  "SupervisorAssessmentScore"
                )} :`}</span>
                {data.managerRating}
              </Typography>
            )}
            {data.managerComment && (
              <Typography whiteSpace="pre-line">
                <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
                  "FeedbackFromSupervisor"
                )} :`}</span>
                {data.managerComment}
              </Typography>
            )}
            {data.managerRating !== null && data.isSuccess !== null && (
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                <div style={{ display: "flex", gap: "8px" }}>
                  <Typography fontSize="20px" fontWeight={500}>
                    {t("Assessor")}
                  </Typography>
                  <ChipStatus
                    status={getStatusAchieve(data.isSuccess)}
                    size="large"
                  />
                </div>
                <div style={{ marginLeft: "auto", height: "60px" }}>
                  <RatingChip
                    rating={calculateRating(
                      data.managerRating,
                      data.isMoonShotEvaluation
                    )} 
                  />
                </div>
              </div>
            )}
          </Box>

          {data.isSuccess === null && (
            <Box marginTop="8px">
              <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      name="isSuccess"
                      control={useHookForm.control}
                      render={({ field }) => (
                        <div>
                          <Typography fontSize={18}>{`${t(
                            "Evaluate"
                          )}`}</Typography>
                          <ToggleButtonGroup {...field} exclusive>
                            <ToggleButton value={"1"} color="success">{`${t(
                              "Success"
                            )}`}</ToggleButton>
                            <ToggleButton value={"0"} color="error">{`${t(
                              "Failure"
                            )}`}</ToggleButton>
                          </ToggleButtonGroup>
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} style={{ marginTop: "20px" }}>
                    <Typography fontSize="18px" fontWeight={500}>
                      {`${t("PerformanceRating")} ${calculateRating(
                        useHookForm.watch("managerRating"),
                        useHookForm.watch("isMoonShotEvaluation")
                      )}`}
                    </Typography>

                    <Controller
                      name="managerRating"
                      control={useHookForm.control}
                      render={({ field }) => (
                        <Box display="flex" gap="8px" marginTop="10px">
                          <Slider
                            max={1}
                            step={0.1}
                            valueLabelDisplay="auto"
                            marks={marks}
                            onChange={(_, newValue) => {
                              field.onChange(newValue);
                            }}
                          />
                          <i
                            className="fa-solid fa-stars"
                            style={{ color: "#f9df39" }}
                          ></i>
                        </Box>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: "20px" }}>
                    <Controller
                      name="managerComment"
                      control={useHookForm.control}
                      render={({ field }) => (
                        <TextFieldTheme
                          {...field}
                          label={`${t("Feedback")}`}
                          multiline
                          rows={2}
                          inputProps={{ maxLength: 200 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="flex-end"
                  >
                    <Box display="flex" alignItems="center" gap="8px">
                      <Typography fontSize="18px">Moon Shot</Typography>
                      <Controller
                        name="isMoonShotEvaluation"
                        control={useHookForm.control}
                        render={({ field }) => (
                          <MoonShotSwitch {...field} checked={field.value} />
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Box display="flex" justifyContent="flex-end">
                  <ResponsiveButtonBlue
                    variant="contained"
                    type="submit"
                    disabled={
                      useHookForm.formState.isSubmitting || configAlert.open
                    }
                  >
                    {`${t("Evaluate")}`}
                  </ResponsiveButtonBlue>
                </Box>
              </form>
            </Box>
          )}
        </Box>
      </CardStyle>
      <AlertResponse {...configAlert} handleClose={handleClose} />
    </Box>
  );
};

export default EvaluationIndividual;
