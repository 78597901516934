import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Pagination from "../../../shared/general/Pagination";
import NumberFormat from "react-number-format";
//import ImageViewer from "react-simple-image-viewer";
import { Impact } from "../mockData";
import { styled } from "@mui/styles";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  InputAdornment,
  LinearProgress,
  TextField,
  Toolbar,
  Avatar,
  Typography,
  Container,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import CommentIcon from "@mui/icons-material/Comment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import BallotIcon from "@mui/icons-material/Ballot";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
    "& .MuiFormControl-marginNormal": {
      marginTop: "0px",
    },
    "& .MuiAccordion-root:before": {
      backgroundColor: "transparent",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiAccordion-rounded": {
      borderRadius: "10px",
      marginTop: 16,
      marginBottom: 16,
      paddingTop: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingBottom: 8,
    },
    "& .MuiTypography-h6": {
      color: "#222f3e",
    },
    "& .MuiGrid-container": {
      marginBottom: "0px !important",
    },
    "& .MuiAccordionDetails-root": {
      flexDirection: "column",
      paddingBottom: 32,
      borderLeft: "5px solid #4f65df",
      marginBottom: 16,
    },
    "& .MuiButtonBase-root": {
      width: "100%",
    },
  },
  cardList: {
    marginTop: 24,
    borderRadius: 24,
    padding: 24,
    minHeight: "100px",
    backgroundColor: "#fff",
  },
  cardList_Icon: {
    width: 40,
    height: 40,
    backgroundColor: "#fff3e0",
    color: "#ff9800",
    marginRight: 24,
  },
  cardListOther_Icon: {
    width: 40,
    height: 40,
    backgroundColor: "#f1f8e9",
    color: "#8bc34a",
    marginRight: 24,
  },
  cardList_Delete: {
    width: 40,
    height: 40,
    backgroundColor: "#ffebee",
    color: "#ef5350",
    marginRight: 16,
  },
  expandIcon: {
    border: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: "50%",
    fontSize: "25px",
  },
  cardList_Svg: {
    fontSize: "1.8rem",
  },
  noData_Section: {
    display: "flex",
    alignItems: "center",
  },
  noDataSvg: {
    fontSize: "30px",
    marginRight: 8,
  },
  hrTag: {
    width: "100%",
    border: "1px solid #eeeeee",
    marginTop: 32,
    marginBottom: 32,
  },
  heading: {
    fontWeight: "700",
    paddingLeft: 64,
    marginBottom: 16,
  },
  impactDetails: {
    paddingLeft: 64,
    paddingBottom: 16,
    
  },
  fontBold: {
    fontWeight: 700,
  },
  attachFileImages: {
    cursor: "pointer",
    width: "200px",
    borderRadius: "5px",
    marginRight: 32,
    marginBottom: 16,
  },
  attachFilePdf: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fafafa",
    border: "1px solid #eeeeee",
    width: "200px",
    height: "150px",
    borderRadius: "5px",
    marginRight: 32,
    marginBottom: 16,
  },
  pdfSvg: {
    fontSize: "3.5rem",
    color: "#222f3e",
  },
  costSvg: {
    position: "relative",
    top: "5px",
    marginRight: 8,
  },
}));

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

function EmpSuccessList() {
  const classes = useStyles();
  const location = useLocation();
  const [year, setYear] = useState(new Date());
  const [searchKey, setSearchKey] = useState("");
  const [impactList, setImpactList] = useState([]);
  const [impactItems, setImpactItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState([]);

  const openImageViewer = useCallback((src) => {
    //setCurrentImage(index);
    const images = [];
    images.push(src);
    setImageSrc(images);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = impactList.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchData = () => {
    setIsLoading(false);

    
  };

  useEffect(() => {
    let x = [...impactItems];
    x = x.filter((y) => {
      return y.keySearch.toLowerCase().includes(searchKey.toLocaleLowerCase());
    });
    setImpactList(x);
  }, [searchKey]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ContainerStyled>
      <div className="card-section">
        <div className="header-section">
          <Grid container>
            <Grid item>
              <h3 className="header-topic">Employee Success List</h3>
            </Grid>
          </Grid>
        </div>
        <Toolbar>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  margin="normal"
                  variant="inline"
                  style={{ width: "150px", marginRight: "10px" }}
                  inputVariant="outlined"
                  label="Year"
                  value={year}
                  views={["year"]}
                  onChange={(date) => setYear(date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                label="Search Impact"
                style={{ width: "300px" }}
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </div>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          {/* impactList */}
          {(Impact.length > 0 &&
            // currentPosts
            Impact.map((item) => (
              <div key={item.impactID}>
                <Typography
                  variant="h6"
                  style={{ marginTop: "40px", marginBottom: "10px" }}
                  className="keyResult"
                >
                  <BookmarkOutlinedIcon fontSize="large" className="svgKey" />
                  Impact To Job / To Team / To Company
                </Typography>
                {item.impactJobs.map((job) => (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon className={classes.expandIcon} />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid container>
                        <Grid item>
                          <Avatar className={classes.cardList_Icon}>
                            <CommentIcon className={classes.cardList_Svg} />
                          </Avatar>
                        </Grid>
                        <Grid item>
                          <Typography variant="h6">{job.impactName}</Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        alignItems="center"
                        className={classes.impactDetails}
                      >
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            display="inline"
                            className={classes.fontBold}
                            gutterBottom
                          >
                            <BallotIcon className={classes.costSvg} />
                            Value :{" "}
                          </Typography>
                          <Typography
                            display="inline"
                            variant="body1"
                            color="textSecondary"
                            gutterBottom
                          >
                            {job.selectValue}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            display="inline"
                            className={classes.fontBold}
                            gutterBottom
                          >
                            <LibraryBooksIcon className={classes.costSvg} />
                            Type :{" "}
                          </Typography>
                          <Typography
                            display="inline"
                            variant="body1"
                            color="textSecondary"
                            gutterBottom
                          >
                            {job.returnType}
                          </Typography>
                        </Grid>
                        {job.selectValue == "Direct" ? (
                          <Grid item xs={12} sm={6} md={3} lg={3}>
                            <Typography
                              variant="body1"
                              display="inline"
                              className={classes.fontBold}
                              color="textSecondary"
                              gutterBottom
                            >
                              <AccountBalanceWalletIcon
                                className={classes.costSvg}
                              />
                              Cost :{" "}
                            </Typography>
                            <Typography
                              display="inline"
                              variant="body1"
                              color="textSecondary"
                              gutterBottom
                            >
                              <NumberFormat
                                value={job.impactCost}
                                displayType={"text"}
                                thousandSeparator={true}
                              />{" "}
                              THB
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                      <Grid container className={classes.impactDetails}>
                        <Grid item xs={10}>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            gutterBottom
                          >
                            {job.description}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.impactDetails}>
                        <Grid item xs>
                          {job.imagePath1 &&
                          job.imagePath2 &&
                          job.pdfPath1 &&
                          job.pdfPath2 ? (
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              gutterBottom
                              className={classes.fontBold}
                            >
                              Attach Files
                            </Typography>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid container className={classes.impactDetails}>
                        <Grid item>
                          {job.imagePath1 ? (
                            <img
                              src={`/upload/images/${job.imagePath1}`}
                              className={classes.attachFileImages}
                              onClick={() => openImageViewer(job.imagePath1)}
                            />
                          ) : null}
                        </Grid>
                        <Grid item>
                          {item.imagePath2 ? (
                            <img
                              src={`/upload/images/${item.imagePath2}`}
                              className={classes.attachFileImages}
                              onClick={() => openImageViewer(item.imagePath2)}
                            />
                          ) : null}
                        </Grid>
                        <Grid item>
                          {item.pdfPath1 ? (
                            <a
                              href={`/upload/pdf/${item.pdfPath1}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className={classes.attachFilePdf}>
                                <i
                                  className={classes.pdfSvg}
                                  class="fa-light fa-file-pdf"
                                ></i>
                              </div>
                            </a>
                          ) : null}
                        </Grid>
                        <Grid item>
                          {item.pdfPath2 ? (
                            <a
                              href={`/upload/pdf/${item.pdfPath2}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className={classes.attachFilePdf}>
                                <i
                                  className={classes.pdfSvg}
                                  class="fa-light fa-file-pdf"
                                ></i>
                              </div>
                            </a>
                          ) : null}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
                <Typography variant="h6" className="keyResult">
                  <BookmarkOutlinedIcon
                    fontSize="large"
                    className="svgKey"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  />
                  Impact To Other
                </Typography>
                {item.impactOthers.map((other) => (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon className={classes.expandIcon} />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid container alignItems="center">
                        <Grid item>
                          <Avatar className={classes.cardListOther_Icon}>
                            <QuestionAnswerIcon
                              className={classes.cardList_Svg}
                            />
                          </Avatar>
                        </Grid>
                        <Grid item>
                          <Typography variant="h6">
                            {other.impactOtherName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        alignItems="center"
                        className={classes.impactDetails}
                      >
                        <Grid item>
                          <Typography
                            display="inline"
                            className="body1"
                            color="textSecondary"
                            gutterBottom
                          >
                            <ArrowRightIcon className={classes.costSvg} />
                            Impact To :{" "}
                          </Typography>
                          <Typography
                            display="inline"
                            variant="body1"
                            color="textSecondary"
                            gutterBottom
                          >
                            {other.impactTo}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.impactDetails}>
                        <Grid item>
                          <Typography
                            className="body1"
                            color="textSecondary"
                            gutterBottom
                          >
                            {other.descriptionOther}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.impactDetails}>
                        <Grid item>
                          {item.imagePath1 &&
                          item.imagePath2 &&
                          item.pdfPath1 &&
                          item.pdfPath2 ? (
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              gutterBottom
                              className={classes.fontBold}
                            >
                              Attach Files
                            </Typography>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid container className={classes.impactDetails}>
                        <Grid item>
                          {item.imagePath1 ? (
                            <img
                              src={`/upload/images/${item.imagePath1}`}
                              className={classes.attachFileImages}
                              onClick={() =>
                                openImageViewer(item.imageOtherPath1)
                              }
                            />
                          ) : null}
                        </Grid>
                        <Grid item>
                          {item.imagePath2 ? (
                            <img
                              src={`/upload/images/${item.imagePath2}`}
                              className={classes.attachFileImages}
                              onClick={() =>
                                openImageViewer(item.imageOtherPath2)
                              }
                            />
                          ) : null}
                        </Grid>
                        <Grid item>
                          {item.pdfOtherPath1 ? (
                            <a
                              href={`/upload/pdf/${item.pdfOtherPath1}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className={classes.attachFilePdf}>
                                <i
                                  className={classes.pdfSvg}
                                  class="fa-light fa-file-pdf"
                                ></i>
                              </div>
                            </a>
                          ) : null}
                        </Grid>
                        <Grid item>
                          {item.pdfOtherPath2 ? (
                            <a
                              href={`/upload/pdf/${item.pdfOtherPath2}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className={classes.attachFilePdf}>
                                <i
                                  className={classes.pdfSvg}
                                  class="fa-light fa-file-pdf"
                                ></i>
                              </div>
                            </a>
                          ) : null}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            ))) || (
            <div className={classes.cardList}>
              <Grid container justifyContent="center">
                <Grid item>
                  <Typography variant="h6" className={classes.noData_Section}>
                    <FindInPageIcon className={classes.noDataSvg} />
                    No Data
                  </Typography>
                </Grid>
              </Grid>
            </div>
          )}
        </>
      )}
      {/* impactList */}
      {Impact.length > 0 ? (
        <Pagination
          paginate={paginate}
          postsPerPage={postsPerPage}
          totalPosts={Impact.length}
        />
      ) : null}
      {/* {isViewerOpen && (
        <ImageViewer
          src={imageSrc}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
        />
      )} */}
    </ContainerStyled>
  );
}

export default EmpSuccessList;
