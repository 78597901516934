import React, { useEffect } from "react";
import { Box, Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled, Stack, Chip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import dayjs from 'dayjs';
import { getKpiUpdateProgress } from "../../../../../actions/kpi";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { getUserPosition } from "../../../../../utils/userData";
import { t } from "i18next";

const StyledRoot = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  padding: '24px 0',
});

const StatusChip = styled(Chip)(({ theme, status }) => {
  let backgroundColor;
  switch (status) {
    case "finished":
      backgroundColor = "#50B498";
      break;
    case "unfinished":
      backgroundColor = "#FF6969";
      break;
  }
  return {
    backgroundColor,
    color: "#fff",
    borderRadius: "16px",
    minWidth: "110px",
    fontSize: "16px",
  };
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const GridStyled = styled(Grid)({
  backgroundColor: "#E6EFF5",
  padding: "16px",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "16px",
  height: "70px",
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));

const KpisIndividualPlanUpdate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { result: kpiPlanStore } = useSelector((state) => state.kpiUpdate);
  const uniqueKpiPlanStore = kpiPlanStore ? Array.from(new Map(kpiPlanStore.map(item => [item.idKPI, item])).values()) : [];

  const columns = [
    {
      name: t("KPIName"),
      minWidth: "200px",
      width: "200px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography>{row.kpiName}</Typography>
            <Typography color="text.third" fontSize="14px">{getUserPosition(row)}</Typography>
          </Box>
        </Box>
      )
    },
    // {
    //   name: "Scope",
    //   minWidth: "200px",
    //   width: "200px",
    //   cellRender: (row) => (
    //     <Box display="flex" alignItems="center">
    //       <Box flexGrow={1}>
    //         <Typography>{row.scope}</Typography>
    //         <Typography color="text.third" fontSize="14px">{getUserPosition(row)}</Typography>
    //       </Box>
    //     </Box>
    //   )
    // },
    // {
    //   name: "Target",
    //   minWidth: "200px",
    //   width: "200px",
    //   cellRender: (row) => (
    //     <Box display="flex" alignItems="center">
    //       <Box flexGrow={1}>
    //         <Typography>{row.target}</Typography>
    //         <Typography color="text.third" fontSize="14px">{getUserPosition(row)}</Typography>
    //       </Box>
    //     </Box>
    //   )
    // },
    {
      name: t("LatestUpdate"),
      minWidth: "100px",
      width: "120px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            {row.updateAt ? dayjs(row.updateAt).format('YYYY/MM/DD') : 'Invalid Date'}
            <Typography color="text.third" fontSize="14px">{getUserPosition(row)}</Typography>
          </Box>
        </Box>
      )
    },
    {
      name: t("Status"),
      minWidth: "100px",
      width: "100px",
      headerTextAlign: "center",
      cellTextAlign: "center",
      cellRender: (row) => {
        const status = row.isFinish ? "finished" : "unfinished";
        return (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <StatusChip
              status={status}
              label={status === "finished" ? t("Finished") : t("Unfinished")}
            />
          </Box>
        );
      }
    },
    {
      name: "",
      minWidth: "50px",
      width: "50px",
      cellRender: (row) => {
        return (
          <Stack alignItems={"center"} spacing={1} direction={"row"}>
            <ButtonBlue
              variant="contained"
              sx={{ width: '120%', height: '120%' }}
              onClick={() => {
                history.push({
                  pathname: "/kpi-update",
                  state: { planList: row },
                });
              }}
            >
              {t("Update")}
            </ButtonBlue>
          </Stack>
        )
      }
    },
  ]

  useEffect(() => {
    dispatch(getKpiUpdateProgress());
  }, [dispatch]);

  return (
    <StyledRoot className="page">
      <Container>
        <GridStyled container justifyContent="space-between" alignItems="center" mt={2}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" className="kpi" sx={{ ml: 1, fontSize: "30px" }}>
             {t("UserUpdateKpi")}
            </Typography>
          </Box>
        </GridStyled>
        <div className="cardPlan">
          <Box padding="2px 0">
            <Container maxWidth="lg">
              <Box padding="2px 0" minHeight="700px">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((c, index) => (
                          <StyledCellHeader
                            key={index}
                            sx={{
                              textAlign: c.headerTextAlign ? c.headerTextAlign : undefined,
                              minWidth: c.minWidth ? c.minWidth : undefined,
                              width: c.width ? c.width : undefined,
                              maxWidth: c.maxWidth ? c.maxWidth : undefined,
                            }}
                          >
                            {c.name}
                          </StyledCellHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {uniqueKpiPlanStore && uniqueKpiPlanStore.map((r, index) => (
                        <TableRow key={index}>
                          {columns.map((c, cIndex) => (
                            <StyledCellContent
                              key={cIndex}
                              style={{
                                textAlign: c.cellTextAlign ? c.cellTextAlign : undefined,
                                fontSize: c.cellFontSize ? c.cellFontSize : undefined,
                                minWidth: c.minWidth ? c.minWidth : undefined,
                                width: c.width ? c.width : undefined,
                                maxWidth: c.maxWidth ? c.maxWidth : undefined,
                              }}
                            >
                              {c.cellRender ? c.cellRender(r) : c.fieldKey ? <Typography fontSize="inherit">{r[c.fieldKey]}</Typography> : null}
                            </StyledCellContent>
                          ))}
                        </TableRow>
                      ))}

                      {(!uniqueKpiPlanStore || uniqueKpiPlanStore.length <= 0) && (
                        <TableRow>
                          <TableCell colSpan={8} align="center">
                            <Box height="120px" display="flex" justifyContent="center" alignItems="center">
                              <Typography variant="h6" >
                                <FindInPageIcon />
                                {t("NoData")}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Container>
          </Box>
        </div>
      </Container>
    </StyledRoot>
  )
}

export default KpisIndividualPlanUpdate;
