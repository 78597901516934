import { httpClient } from "./httpClient";

const getPdpaApprove = (idCompany) => {
    return httpClient.get(`/pdpaApprove/${idCompany}`)
}
const getPdpaApproveByID = (id) => {
    return httpClient.get(`/pdpaApprove`)
}

const addPdpaApprove = (isApprove) => {
    return httpClient.post(`/pdpaApprove`, {
        isApprove: isApprove
    });
}

export default {
    getPdpaApprove,
    getPdpaApproveByID,
    addPdpaApprove,
}