import React, { useEffect } from "react";
import { Autocomplete, Box, Checkbox, FormControlLabel, Grid, MenuItem, Typography, styled } from "@mui/material";
import DrawerCustom from "../../../../shared/general/Drawer";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { useDispatch, useSelector } from "react-redux";
import { getManager, getManagerGroup } from "../../../../../../actions/manager";
import { getEmployeeProfile, updateEmployeePosition } from "../../../../../../actions/employee";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";
import { getAffiliateManagerGroup } from "../../../../../../actions/affiliate";
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../../../utils/userData";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0
      }
    }
  },
  "& .MuiCheckbox-root": {
    "&.Mui-checked": {
      color: "#46cbe2",
    },
  },
})

const DialogManager = (props) => {

  const { drawerConfig, onClose } = props
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { result: employeeProfile } = useSelector(state => state.employeeProfile);
  const { result: managerList } = useSelector(state => state.manager);
  const { result: managerGroup } = useSelector(state => state.managerGroup);

  const validateYupSchema = yup.object({

    managerLV1: yup.mixed().nullable()
    .test("managerLV1Required", t("ThisFieldIsRequired"), (value, ctx) => {
      return (!ctx.parent.isLV1Group && value) || ctx.parent.isLV1Group
    }),
    managerGroupLV1: yup.mixed().nullable()
    .test("managerGroupLV1Required", t("ThisFieldIsRequired"), (value, ctx) => {
      return (ctx.parent.isLV1Group && value) || !ctx.parent.isLV1Group
    }),
  })

  const useHookForm = useForm({
    defaultValues: {
      managerLV1: null,
      managerLV2: null,
      managerGroupLV1: null,
      managerGroupLV2: null,
      isLV1Group: false,
      isLV2Group: false,
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all"
  })

  const onSubmit = async (data) => {

    const formData = {
      idEmployeePosition: employeeProfile.idEmployeePosition,
    }

    if(data.isLV1Group){
      formData.idManagerGroupLV1 = data.managerGroupLV1? data.managerGroupLV1.idManagerGroup: null
      formData.idManagerLV1 = null
    } else {
      formData.idManagerGroupLV1 = null
      formData.idManagerLV1 = data.managerLV1? data.managerLV1.idEmployees: null
    }

    if(data.isLV2Group){
      formData.idManagerGroupLV2 = data.managerGroupLV2? data.managerGroupLV2.idManagerGroup: null
      formData.idManagerLV2 = null
    } else {
      formData.idManagerGroupLV2 = null
      formData.idManagerLV2 = data.managerLV2? data.managerLV2.idEmployees: null
    }

    const result = await dispatch(updateEmployeePosition(formData));
    if (result) {
      onClose();
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful")
      }))
    }
  }

  useEffect(() => {
    if(drawerConfig.isOpen){
      dispatch(getManager()) 
      dispatch(getAffiliateManagerGroup())
    }
  }, [drawerConfig.isOpen])

  useEffect(() => {
    if(managerList && managerGroup){

      let isLV1Group = false
      let isLV2Group = false

      if(drawerConfig.data.idManagerLV1) {
        isLV1Group = false
      } else if (drawerConfig.data.idManagerGroupLV1) {
        isLV1Group = true
      } else {
        isLV1Group = false
      }

      if(drawerConfig.data.idManagerLV2) {
        isLV2Group = false
      } else if (drawerConfig.data.idManagerGroupLV2) {
        isLV2Group = true
      } else {
        isLV2Group = false
      }

      useHookForm.reset({
        managerLV1: drawerConfig.data.idManagerLV1? managerList.find(item => item.idEmployees === drawerConfig.data.idManagerLV1) || null: null,
        managerLV2: drawerConfig.data.idManagerLV2? managerList.find(item => item.idEmployees === drawerConfig.data.idManagerLV2) || null: null,
        managerGroupLV1: drawerConfig.data.idManagerGroupLV1? managerGroup.find(item => item.idManagerGroup === drawerConfig.data.idManagerGroupLV1) || null: null,
        managerGroupLV2: drawerConfig.data.idManagerGroupLV2? managerGroup.find(item => item.idManagerGroup === drawerConfig.data.idManagerGroupLV2) || null: null,
        isLV1Group: isLV1Group,
        isLV2Group: isLV2Group,
      });
    }
  }, [managerList, managerGroup])

  const isLV1GroupWatch = useHookForm.watch("isLV1Group")
  const isLV2GroupWatch = useHookForm.watch("isLV2Group")

  return (
    <DrawerCustom
      title={t("EditManagerInfo")}
      anchor={"right"}
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box paddingBottom="4px" display="flex" alignItems="center" justifyContent="space-between">
                <Typography fontWeight="600" fontSize="14px" color="text.secondary">{`${t("No_Number")} 1`}</Typography>
                <Controller
                  name={"isLV1Group"}
                  control={useHookForm.control}
                  render={({field}) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                          }}
                        />
                      }
                      label={t("ManagerGroup")}
                      labelPlacement="start"
                    />
                  )}
                />
              </Box>
              {!isLV1GroupWatch && (
                <Controller
                  name="managerLV1"
                  control={useHookForm.control}
                  render={({field, fieldState}) => (
                    <Autocomplete
                      {...field}
                      options={managerList? managerList: []}
                      getOptionLabel={(option) => `${getUserFullName(option)}`}
                      isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.idEmployees}>
                          <Box>
                            <Typography>{getUserFullName(option)}</Typography>
                            <Typography color="text.secondary" fontSize="12px">{option.email}</Typography>
                          </Box>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          placeholder={t("SearchManager")}
                          onBlur={field.onBlur}
                          error={fieldState.error? true: false}
                          helperText={fieldState.error? fieldState.error.message: null}
                        />
                      )}
                      value={field.value}
                      onChange={(_, value) => {
                        field.onChange(value)
                      }}
                      noOptionsText={t("NoData")}
                      disableClearable
                    />
                  )}
                />
              )}

              {isLV1GroupWatch && (
                <Controller
                  name="managerGroupLV1"
                  control={useHookForm.control}
                  render={({field, fieldState}) => (
                    <Autocomplete
                      {...field}
                      options={managerGroup? managerGroup: []}
                      getOptionLabel={(option) => `${option.managerGroupName}`}
                      isOptionEqualToValue={(option, value) => option.idManagerGroup === value.idManagerGroup}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.idManagerGroup}>
                          <Box>
                            <Typography>{option.managerGroupName}</Typography>
                            <Typography color="text.secondary" fontSize="12px">{option.managerList.length} คน</Typography>
                          </Box>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          placeholder={t("ManagerGroup")}
                          onBlur={field.onBlur}
                          error={fieldState.error? true: false}
                          helperText={fieldState.error? fieldState.error.message: null}
                        />
                      )}
                      value={field.value}
                      onChange={(_, value) => {
                        field.onChange(value)
                      }}
                      noOptionsText={t("NoData")}
                      disableClearable
                    />
                  )}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <Box paddingBottom="4px" display="flex" alignItems="center" justifyContent="space-between">
                <Typography fontWeight="600" fontSize="14px" color="text.secondary">{`${t("No_Number")} 2`}</Typography>
                <Controller
                  name={"isLV2Group"}
                  control={useHookForm.control}
                  render={({field}) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                          }}
                        />
                      }
                      label={t("ManagerGroup")}
                      labelPlacement="start"
                    />
                  )}
                />
              </Box>
              {!isLV2GroupWatch && (
                <Controller
                  name="managerLV2"
                  control={useHookForm.control}
                  render={({field, fieldState}) => (
                    <Autocomplete
                      {...field}
                      options={managerList? managerList: []}
                      getOptionLabel={(option) => getUserFullName(option)}
                      isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.idEmployees}>
                          <Box>
                            <Typography>{getUserFullName(option)}</Typography>
                            <Typography color="text.secondary" fontSize="12px">{option.email}</Typography>
                          </Box>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          placeholder={t("SearchManager")}
                          onBlur={field.onBlur}
                          error={fieldState.error? true: false}
                          helperText={fieldState.error? fieldState.error.message: null}
                        />
                      )}
                      value={field.value}
                      onChange={(_, value) => {
                        field.onChange(value)
                      }}
                      noOptionsText={t("NoData")}
                      disableClearable
                    />
                  )}
                />
              )}

              {isLV2GroupWatch && (
                <Controller
                  name="managerGroupLV2"
                  control={useHookForm.control}
                  render={({field, fieldState}) => (
                    <Autocomplete
                      {...field}
                      options={managerGroup? managerGroup: []}
                      getOptionLabel={(option) => `${option.managerGroupName}`}
                      isOptionEqualToValue={(option, value) => option.idManagerGroup === value.idManagerGroup}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.idManagerGroup}>
                          <Box>
                            <Typography>{option.managerGroupName}</Typography>
                            <Typography color="text.secondary" fontSize="12px">{option.managerList.length} คน</Typography>
                          </Box>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          placeholder={t("ManagerGroup")}
                          onBlur={field.onBlur}
                          error={fieldState.error? true: false}
                          helperText={fieldState.error? fieldState.error.message: null}
                        />
                      )}
                      value={field.value}
                      onChange={(_, value) => {
                        field.onChange(value)
                      }}
                      noOptionsText={t("NoData")}
                    />
                  )}
                />
              )}
            </Grid>

            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose} disabled={useHookForm.formState.isSubmitting}>{t("Cancel")}</ButtonBlue>
              <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>{t("Save")}</ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DialogManager;