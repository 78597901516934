import React from "react";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import DatePickerCustom from "../../shared/date/datePicker";

import {
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  TextField,
  Typography,
} from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const DialogVisaDate = (props) => {
  const { open, onClose, onHandleSubmit } = props;
  const { t, i18n } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      startDate: new Date(),
      endDate: new Date(),
    },
  });

  const onHandleSubmitOnDialog = (value) => {
    onHandleSubmit(value);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Typography variant="h5">{`${t("SpecifyTravelTime")}`}</Typography>
        <Divider
          style={{
            borderBottom: "1px solid #CDCDCD",
            margin: "16px 0px",
          }}
        />
        <form onSubmit={handleSubmit(onHandleSubmitOnDialog)}>
          <div>
            <Typography>{`${t("StartDateTravel")}`}</Typography>
            <Controller
              name="startDate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: `${t("PleaseSpecifyCorrectDate")}`,
                },
                validate: (value) =>
                  dayjs(value).isSameOrAfter(dayjs(), "date") ||
                  `${t("PleaseSpecifyCorrectDate")}`,
              }}
              render={({ field }) => (
                <DatePickerCustom
                  {...field}
                  inputFormat="DD/MM/YYYY"
                  minDate={new Date()}
                  onChange={(newValue) => {
                    let valueEndDate = watch("endDate");
                    let endDate = dayjs(newValue).isAfter(valueEndDate)
                      ? newValue
                      : valueEndDate;

                    field.onChange(newValue);
                    setValue("endDate", endDate);
                  }}
                  renderInput={(params) => (
                    <TextField
                      style={{
                        width: "100%",
                        marginTop: "8px",
                      }}
                      {...params}
                      error={errors.startDate ? true : false}
                      helperText={
                        errors.startDate && errors.startDate.message
                      }
                    />
                  )}
                />
              )}
            />
          </div>
          <div style={{ marginTop: "16px" }}>
            <Typography>{`${t("EndDateTravel")}`}</Typography>
            <Controller
              name="endDate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: `${t("PleaseSpecifyCorrectDate")}`,
                },
              }}
              render={({ field }) => (
                <DatePickerCustom
                  {...field}
                  inputFormat="DD/MM/YYYY"
                  minDate={watch("startDate")}
                  onChange={(newValue) => {
                    let valueStartDate = watch("startDate");
                    let startDate = dayjs(valueStartDate).isAfter(newValue)
                      ? newValue
                      : valueStartDate;

                    field.onChange(newValue);
                    setValue("startDate", startDate);
                  }}
                  renderInput={(params) => (
                    <TextField
                      style={{
                        width: "100%",
                        marginTop: "8px",
                      }}
                      {...params}
                      error={errors.endDate ? true : false}
                      helperText={errors.endDate && errors.endDate.message}
                    />
                  )}
                />
              )}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
            }}
          >
            <ButtonBlue style={{ marginRight: "8px" }} onClick={onClose}>
              {`${t("Cancel")}`}
            </ButtonBlue>
            <ButtonBlue variant="contained" type="submit">
              {`${t("Confirm")}`}
            </ButtonBlue>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default DialogVisaDate;
