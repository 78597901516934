import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Grid,
  Popper,
  TextField,
  Typography,
  autocompleteClasses,
  styled,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useTranslation } from "react-i18next";
import CardStyle from "../../shared/general/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DatePickerCustom from "../../shared/date/datePicker";
import LoadingGif from "../../assets/social-media.gif";
import {
  getUserFullName,
  getUserDepartment,
  getUserSection,
  getUserDivision,
} from "../../../../utils/userData";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllEmployeePerformance,
  getEstimateLevelActive,
} from "../../../../actions/estimatePerformance";
import PerformanceTable from "./performanceTable";
import { performanceXlsxFile } from "./performanceXLSXfile";

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  width: "330px !important",

  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const AssessmentPerformance = ({ selectedCompany }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: performanceQuestion, isFetching } = useSelector(
    (state) => state.performanceQuestion
  );
  const { result: employees } = useSelector((state) => state.employees);

  const [selectYear, setSelectYear] = useState(dayjs());
  const [selectedStatus, setSelectedStatus] = useState({
    value: "all",
    label: t("All"),
  });
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [selectedDeparment, setSelectedDepartment] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectEmployee, setSelectEmployee] = useState(null);
  const [filterData, setFilterData] = useState([]);

  const handleChangeDivision = (value) => {
    const filterPerformance = performanceQuestion.filter(
      (item) =>
        (value === null ? true : value.idDivision === item.idDivision) &&
        (selectedDeparment === null ||
          selectedDeparment.idDepartment === item.idDepartment) &&
        (selectedSection === null ||
          selectedSection.idSection === item.idSection)
    );
    setFilterData(filterPerformance);
    setSelectedDivision(value);
    setSelectedDepartment(null);
    setSelectedSection(null);
    setSelectEmployee(null);
  };

  const handleChangeDepartment = (value) => {
    const filterPerformance = performanceQuestion.filter(
      (item) =>
        (value === null ? true : value.idDepartment === item.idDepartment) &&
        (selectedDivision === null ||
          selectedDivision.idDivision === item.idDivision) &&
        (selectedSection === null ||
          selectedSection.idSection === item.idSection)
    );
    setFilterData(filterPerformance);
    setSelectedDepartment(value);
    setSelectedSection(null);
    setSelectEmployee(null);
  };

  const handleChangeSection = (value) => {
    const filterPerformance = performanceQuestion.filter(
      (item) =>
        (value === null ? true : value.idSection === item.idSection) &&
        (selectedDivision === null ||
          selectedDivision.idDivision === item.idDivision) &&
        (selectedDeparment === null ||
          selectedDeparment.idDepartment === item.idDepartment)
    );
    setFilterData(filterPerformance);
    setSelectedSection(value);
    setSelectEmployee(null);
  };

  const handleFilterEmployee = (value) => {
    setSelectEmployee(value);
    setSelectedDivision(null);
    setSelectedDepartment(null);
    setSelectedSection(null);
    if (value) {
      const data = performanceQuestion.filter(
        (item) => item.idEmployees === value.idEmployees
      );
      setFilterData(data);
    } else {
      setFilterData(performanceQuestion);
    }
  };

  const handleFilterStatus = (value) => {
    const data = performanceQuestion.filter((item) =>
      value.status === "evaluate"
        ? item.idEstimateLevel
        : value.status === "waiting"
        ? !item.idEstimateLevel
        : true
    );
    setFilterData(data);
    setSelectedStatus(value);
  };

  useEffect(() => {
    dispatch(getEstimateLevelActive({ idCompany: selectedCompany.idCompany }));
  }, [selectedCompany]);

  useEffect(() => {
    dispatch(
      getAllEmployeePerformance({
        idCompany: selectedCompany.idCompany,
        year: dayjs(selectYear).get("year"),
      })
    );
  }, [selectedCompany, selectYear]);

  useEffect(() => {
    if (performanceQuestion) {
      setFilterData(performanceQuestion);
    }
  }, [performanceQuestion]);

  return (
    <>
      <CardStyle style={{ padding: "16px", marginBottom: "32px" }}>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          spacing={2}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Typography color="text.third" fontSize={14} fontWeight={600}>
              {t("SelectYear")}
            </Typography>
            <DatePickerCustom
              inputFormat="YYYY"
              value={selectYear}
              minDate={dayjs().subtract(3, "year")}
              maxDate={dayjs()}
              name="start"
              views={["year"]}
              openTo="year"
              onChange={(newValue) => setSelectYear(newValue)}
              disabled={isFetching}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography color="text.third" fontSize={14} fontWeight={600}>
              {t("Status")}
            </Typography>
            <StyledAutocomplete
              disableClearable
              value={selectedStatus}
              onChange={(event, newValue) => handleFilterStatus(newValue)}
              options={[
                { status: "all", label: t("All") },
                { status: "evaluate", label: t("Evaluated") },
                { status: "waiting", label: t("WaitingForEvaluation") },
              ]}
              isOptionEqualToValue={(option, value) =>
                option.status === value.status
              }
              popupIcon={<i class="fa-light fa-chevron-down"></i>}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder={`${t("All")}`}
                />
              )}
              onKeyDown={(e) => e.preventDefault()}
              PopperComponent={StyledPopper}
              noOptionsText={`${t("NoData")}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography color="text.third" fontSize={14} fontWeight={600}>
              {t("SearchEmp")}
            </Typography>
            <StyledAutocomplete
              fullWidth
              options={employees || []}
              value={selectEmployee}
              onChange={(event, newValue) => handleFilterEmployee(newValue)}
              popupIcon={<i className="fa-light fa-chevron-down"></i>}
              isOptionEqualToValue={(option, value) =>
                option.idEmployees === value.idEmployees
              }
              getOptionLabel={(option) => getUserFullName(option)}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.idEmployees}>
                    {getUserFullName(option)}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder={t("SelectEmp")}
                />
              )}
              noOptionsText={t("NoData")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography color="text.third" fontSize={14} fontWeight={600}>
              {t("Division")}
            </Typography>
            <StyledAutocomplete
              options={selectedCompany.division || []}
              onChange={(event, newValue) => handleChangeDivision(newValue)}
              value={selectedDivision}
              popupIcon={<i class="fa-light fa-chevron-down"></i>}
              getOptionLabel={(option) => getUserDivision(option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder={`${t("AllDivision")}`}
                />
              )}
              PopperComponent={StyledPopper}
              noOptionsText={`${t("NoData")}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography color="text.third" fontSize={14} fontWeight={600}>
              {t("Department")}
            </Typography>
            <StyledAutocomplete
              options={selectedCompany.department || []}
              value={selectedDeparment}
              onChange={(event, newValue) => handleChangeDepartment(newValue)}
              groupBy={(option) => getUserDivision(option)}
              popupIcon={<i class="fa-light fa-chevron-down"></i>}
              getOptionLabel={(option) => getUserDepartment(option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder={`${t("AllDepartment")}`}
                />
              )}
              PopperComponent={StyledPopper}
              noOptionsText={`${t("NoData")}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography color="text.third" fontSize={14} fontWeight={600}>
              {t("Section")}
            </Typography>
            <StyledAutocomplete
              options={selectedCompany.section || []}
              value={selectedSection}
              onChange={(event, newValue) =>
                handleChangeSection("section", newValue)
              }
              groupBy={(option) => getUserDepartment(option)}
              popupIcon={<i class="fa-light fa-chevron-down"></i>}
              getOptionLabel={(option) => getUserSection(option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder={`${t("AllSection")}`}
                />
              )}
              PopperComponent={StyledPopper}
              noOptionsText={`${t("NoData")}`}
            />
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
            <ButtonBlue
              variant="outlined"
              startIcon={<FileDownloadIcon />}
              onClick={() =>
                performanceXlsxFile(
                  t,
                  i18n.resolvedLanguage,
                  filterData,
                  selectYear.year(),
                  selectedCompany.companyName
                )
              }
              disabled={isFetching}
            >
              {t("Download")}
            </ButtonBlue>
          </Grid>
        </Grid>
      </CardStyle>
      {isFetching ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={LoadingGif} style={{ width: 80, height: 80 }} />
        </div>
      ) : (
        <PerformanceTable
          idCompany={selectedCompany.idCompany}
          selectYear={selectYear.get("year")}
          filterData={filterData}
        />
      )}
    </>
  );
};

export default AssessmentPerformance;
