import React from "react";
import { useSelector } from "react-redux";

import AdminAssetManagement from "../admin/assetManagement";
import UserAssetManagement from "../user/assetManagement";

const AssetManagement = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ? (
          <AdminAssetManagement />
        ) : (
          <UserAssetManagement />
        )
      ) : null}
    </>
  );
};

export default AssetManagement;
