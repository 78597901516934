import React from "react";
import { Controller } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { Grid, MenuItem, Typography } from "@mui/material";
import TextFieldNumber from "./TextFieldNumber";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTypography-body1": {
      color: "#16243D",
    },
    "& .MuiTypography-body2": {
      fontSize: "1rem",
      marginLeft: theme.spacing(4),
    },
    "& .MuiGrid-root": {
      marginBottom: theme.spacing(1),
    },
  },
  inputAdornment: {
    "& .MuiTypography-body1": {
      color: "rgba(0, 0, 0, 0.38)",
    },
  },
}));

function Form1(props) {
  const { control, watch, setValue, isDisabled } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const maritalStatus = [
    {
      id: 0,
      label: `${t("Single")}`,
      value: "โสด",
    },
    {
      id: 1,
      label: `${t("Married")}`,
      value: "แต่งงาน",
    },
    {
      id: 2,
      label: `${t("Widowed")}`,
      value: "หม้าย",
    },
    {
      id: 3,
      label: `${t("Divorced")}`,
      value: "หย่าร้าง",
    },
  ];

  const mateIncome = [
    { id: 1, label: `${t("Yes")}`, value: 1 },
    { id: 2, label: `${t("No")}`, value: 0 },
  ];

  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Typography variant="body1">1) {`${t("MaritalStatus")}`}</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Controller
            name="maritalStatus"
            control={control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  if (e.target.value !== "แต่งงาน") {
                    setValue("isMateIncome", 0, { shouldDirty: true });
                    setValue("mateInsure", 0, { shouldDirty: true });
                    setValue("isChildSupport_mateFather", false, {
                      shouldDirty: true,
                    });
                    setValue("childSupport_mateFather", "", {
                      shouldDirty: true,
                    });
                    setValue("isChildSupport_mateMother", false, {
                      shouldDirty: true,
                    });
                    setValue("childSupport_mateMother", "", {
                      shouldDirty: true,
                    });
                    setValue("isHealthInsurance_mateFather", false, {
                      shouldDirty: true,
                    });
                    setValue("healthInsurance_mateFather", 0, {
                      shouldDirty: true,
                    });
                    setValue("isHealthInsurance_mateMother", false, {
                      shouldDirty: true,
                    });
                    setValue("healthInsurance_mateMother", 0, {
                      shouldDirty: true,
                    });
                  }
                }}
                select
                disabled={isDisabled}
              >
                {maritalStatus.map((item) => (
                  <MenuItem key={item.id} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextFieldTheme>
            )}
          />
        </Grid>
      </Grid>
      {watch("maritalStatus") === "แต่งงาน" && (
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="body2">{`${t("SpouseHasIncome")}`}</Typography>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Controller
              name="isMateIncome"
              control={control}
              render={({ field }) => (
                <TextFieldTheme
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    if (e.target.value === 1) {
                      setValue("mateInsure", 0, { shouldDirty: true });
                      setValue("isChildSupport_mateFather", false, {
                        shouldDirty: true,
                      });
                      setValue("childSupport_mateFather", "", {
                        shouldDirty: true,
                      });
                      setValue("isChildSupport_mateMother", false, {
                        shouldDirty: true,
                      });
                      setValue("childSupport_mateMother", "", {
                        shouldDirty: true,
                      });
                      setValue("isHealthInsurance_mateFather", false, {
                        shouldDirty: true,
                      });
                      setValue("healthInsurance_mateFather", 0, {
                        shouldDirty: true,
                      });
                      setValue("isHealthInsurance_mateMother", false, {
                        shouldDirty: true,
                      });
                      setValue("healthInsurance_mateMother", 0, {
                        shouldDirty: true,
                      });
                    }
                  }}
                  select
                  disabled={isDisabled}
                >
                  {mateIncome.map((item) => (
                    <MenuItem key={item.id} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextFieldTheme>
              )}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Typography variant="body1">2) {`${t("ChildAllowance")}`}</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body2">
            {`${t("NumberChildrenUnderChildAllowance")}`}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber
            name="childDeduction"
            min={0}
            max={99}
            endAdornment={true}
            endAdornmentLabel={`${t("Person")}`}
            control={control}
            isInteger={true}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body2">
            {`${t("ChildrenTaxDeductionFrom2018Onwards")}`}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber
            name="childYearDeduction"
            min={0}
            max={99}
            endAdornment={true}
            endAdornmentLabel={`${t("Person")}`}
            control={control}
            isInteger={true}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body1">
            3) {`${t("LifeInsurancePremiumPaidDuringTheTaxYear")}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body2">{`${t("Employee")}`}</Typography>
          <Typography
            variant="body2"
            style={{
              marginLeft: 24,
              fontSize: "0.81rem",
              marginTop: 8,
              color: "#d32f2f",
            }}
          >
            * {`${t("MaxTaxDeductionLimit100KTHB")}`}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber
            name="empInsure"
            min={0}
            max={
              100000 -
              (watch("mateInsure") ? watch("mateInsure") : 0) -
              (watch("healthInsurance") ? watch("healthInsurance") : 0)
            }
            onHandleChange={() => {
              let maxlimit =
                100000 -
                (watch("mateInsure") ? watch("mateInsure") : 0) -
                (watch("healthInsurance") ? watch("healthInsurance") : 0);

              if (maxlimit <= 0) {
                setValue("empInsure", 0);
              }
            }}
            endAdornment={true}
            endAdornmentLabel={`${t("Baht")} / ${t("Year")}`}
            control={control}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      {watch("maritalStatus") === "แต่งงาน" && watch("isMateIncome") === 0 && (
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={12} md={8} lg={8}>
            <Typography variant="body2">{`${t("Spouse")}`}</Typography>
            <Typography
              variant="body2"
              style={{
                marginLeft: 24,
                fontSize: "0.81rem",
                marginTop: 8,
                color: "#d32f2f",
              }}
            >
              * {`${t("MaxTaxDeductionLimit10KTHB")}`}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextFieldNumber
              name="mateInsure"
              min={0}
              max={(() => {
                let maxLimit =
                  100000 -
                  (watch("empInsure") ? watch("empInsure") : 0) -
                  (watch("healthInsurance") ? watch("healthInsurance") : 0);
                if (maxLimit > 10000) {
                  return 10000;
                } else {
                  return maxLimit;
                }
              })()}
              onHandleChange={() => {
                let maxlimit =
                  100000 -
                  (watch("empInsure") ? watch("empInsure") : 0) -
                  (watch("healthInsurance") ? watch("healthInsurance") : 0);

                if (maxlimit <= 0) {
                  setValue("mateInsure", 0);
                }
              }}
              endAdornment={true}
              endAdornmentLabel={`${t("Baht")} / ${t("Year")}`}
              control={control}
              disabled={isDisabled}
            />
          </Grid>
        </Grid>
      )}
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body1">
            4) {`${t("HealthInsurancePremiums")}`}
          </Typography>
          <Typography
            variant="body2"
            style={{
              marginLeft: 16,
              fontSize: "0.81rem",
              marginTop: 8,
              color: "#d32f2f",
            }}
          >
            * {`${t("MaxTaxDeductionLimit25KTHB")}`}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber
            name="healthInsurance"
            min={0}
            max={(() => {
              let maxLimit =
                100000 -
                (watch("empInsure") ? watch("empInsure") : 0) -
                (watch("mateInsure") ? watch("mateInsure") : 0);
              if (maxLimit > 25000) {
                return 25000;
              } else {
                return maxLimit;
              }
            })()}
            onHandleChange={() => {
              let maxlimit =
                100000 -
                (watch("empInsure") ? watch("empInsure") : 0) -
                (watch("mateInsure") ? watch("mateInsure") : 0);

              if (maxlimit <= 0) {
                setValue("healthInsurance", 0);
              }
            }}
            endAdornment={true}
            endAdornmentLabel={`${t("Baht")} / ${t("Year")}`}
            control={control}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Typography
        variant="body2"
        style={{
          marginLeft: 16,
          fontSize: "0.81rem",
          marginTop: 8,
          color: "#d32f2f",
        }}
      >
        * {`${t("MaxLifeHealthInsurancePremium100KTHB")}`}
        
      </Typography>
    </div>
  );
}

export default Form1;
