import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';
import { Link } from "react-router-dom";
import { Avatar, Grid, Box, Card, styled, Chip, TablePagination, Typography, Rating, InputLabel, Select, ListItemText,
  MenuItem, Checkbox, FormControl, OutlinedInput, TextField } from "@mui/material";
import ListIcon from '@mui/icons-material/List';
import TableTheme from "../../../shared/general/TableTheme";
import DialogExcel from "./dialogExcel";
import DialogLinkRegisterManpower from "./dialogLinkRegisterManpower";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { stringToColor } from "../../../../../utils";
import { useTranslation } from "react-i18next";
var buddhistEra = require('dayjs/plugin/buddhistEra');
dayjs.extend(buddhistEra);

const StyledRoot = styled("div")({
  background: "#FFFFFF !important",
  "& .part-add-manpower": {
    marginBottom: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "16px",
    "& .search-name": {
      width: 250,
    }
  },
  "& .part-filter": {
    "& .search-name": {
      width: 250,
    }
  },
  "& .part-avatar": {
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    // background: "red",
    "& .MuiAvatar-root": {
      width: 90,
      height: 90,
    },
  },
  "& .part-name": {
    "& .MuiTypography-h5": {
      textAlign: "center",
      fontSize: "1rem",
      fontWeight: "500",
    },
  },
  "& .part-rating": {
    marginTop: 5,
    marginBottom: "18px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // "& .editpencil": {
    //     fontSize: "0.45rem",
    //     color: "#757575",
    //     background: "#D9D9D9",
    //     borderRadius: "50%",
    //     width: "15px",
    //     height: "15px",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     marginLeft: "5px",
    //   },
  },
  "& .part-infomation": {
    marginBottom: "18px",
    "& .iconandinfo": {
      display: "flex",
      alignItems: "center",
      marginLeft: "10px",
      "& .icon": {
        width: "20px",
        display: "flex",
        justifyContent: "center",
      },
      "& .MuiTypography-info": {
        marginLeft: "15px",
        fontSize: "0.85rem",
        fontWeight: "400",
      },
    },
    "& .iconandinfo:not(:first-of-type)": {
      marginTop: "6px",
    },
  },
  "& .part-status": {
    display: "flex",
    justifyContent: "right",
  },

})

const StyledCard = styled(Card)({
  padding: 24,
  position: "relative",
  boxShadow: "none",
  borderRadius: 16,
  border: "1px solid #919eab3d",
  border: "none",
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,transform 0.15s ease-in-out",
  "&:hover": {
    cursor: "pointer",
    transition:
      "color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,transform 0.15s ease-in-out",
    transform: "translateY(-10px)",
  },
}
);

const StyledFormControl = styled(FormControl)({
  width: "300px",
  marginLeft: "10px",
  "& .MuiInputBase-root": {
    lineHeight: "1.4375em",
    borderRadius: "8px",
    "& .MuiSelect-select": {
      padding: "13.5px 14px",
      color: "#000000b8"
    }
  }
})

const StyledChipAlrJob = styled(Chip)({
  width: "130px",
  borderRadius: 4,
  height: "unset",
  padding: 5,
  // backgroundColor: "#eb7d7d",
  // color: "#b81a1a",
  backgroundColor: "#FF4040",
  color: "#FFFFFF",
  fontSize: 12,
});

const StyledChipFree = styled(Chip)({
  width: "130px",
  borderRadius: 4,
  height: "unset",
  padding: 5,
  fontSize: 12,
  // backgroundColor: "#76d576",
  // color: "#0b5f10",
  backgroundColor: "#2CC04D",
  color: "#FFFFFF"
  // backgroundColor: "#CAFFD6",
  // color: "#3CCC5B"
});

const StyledChipWaiting = styled(Chip)({
  width: "130px",
  borderRadius: 4,
  height: "unset",
  padding: 5,
  fontSize: 12,
  // backgroundColor: "#fff0ce",
  // color: "#ff9800",
  backgroundColor: "#FFD600",
  color: "#FFFFFF"
});

const cardManpower = (props) => {
  const {t,i18n} = useTranslation();
  const { allUsersFree, isFetching, isOpenRegisterManpower, setIsOpenRegisterManpower } = props;
  const { result: AllJobGroups } = useSelector(state => state.jobRecruitGroup);
  // console.log(AllJobGroups);
  // console.log(allUsersFree);

  const [isOpenModalImportExcel, setIsOpenModalImportExcel] = useState(false);
  const [dialogLinkRegisterManpowerConfig, setDialogLinkRegisterManpowerConfig] = useState({ isOpen: false, isOpenRegisterManpower: isOpenRegisterManpower });
  // const [filterCandidates, setFilterCandidates] = useState([]);
  const [filterAllUsersFree, setFilterAllUsersFree] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(9);
  
  const [jobGroup, setJobGroup] = useState([]);

  const handleChangeJobGroup = (e) => {
    const valueSelect = e.target.value
    if (valueSelect.includes("all")) {
      setJobGroup([])
    } else {
      setJobGroup(typeof valueSelect === 'string' ? valueSelect.split(',') : valueSelect)
    }
  }

  const DisplayRecentEducation = (educationHistory) => {
    const result = educationHistory.sort((a, b) => b.endYear - a.endYear);
    return result[0].university + ", " + result[0].faculty;
  };

  const Displayage = (date) => {
    let month = dayjs().diff(date, 'month');
    return `${Math.floor(month / 12)} ปี${month % 12 > 0 ? ` ${month % 12} ${t("Month")}` : ''}`;
  };

  const DisplayStatus = (idManpowerStatus) => {
    if (idManpowerStatus === 1) {
      return <StyledChipFree label= {t("Available")} />
    } else if (idManpowerStatus === 2) {
      return <StyledChipAlrJob label={t("NotAvailable")} />
    } else if (idManpowerStatus === 3) {
      return <StyledChipWaiting label={t("Pending")} />
    } else {
      return <StyledChipWaiting label="null" />
    }
  };

  // useEffect(() => {
  //   if(candidate){
  //     setFilterCandidates([...candidate.filter((can) => can.name.includes(search) || can.lastname.includes(search))]);
  //   }
  // },[candidate, search]);

  // useEffect(() => {
  //   if (allUsersFree) {
  //     setFilterAllUsersFree([...allUsersFree.filter((can) =>  can.name.includes(search) ||
  //       can.lastname.includes(search) ||
  //       (can.prevJob && can.prevJob.lastCompanyName && can.prevJob.lastCompanyName.toLowerCase().includes(search.toLowerCase())) ||
  //       universityMatch
  //     )]);
  //   }
  // }, [allUsersFree, search]);

  // useEffect(() => {
  //   if (allUsersFree) {
  //     setFilterAllUsersFree([
  //       ...allUsersFree.filter((can) => {
  //         const universityMatch = can.educationHistory && can.educationHistory.some(edu => edu.university.toLowerCase().includes(search.toLowerCase()));
  //         return can.name.toLowerCase().includes(search.toLowerCase()) || 
  //                can.lastname.toLowerCase().includes(search.toLowerCase()) || 
  //                (can.prevJob && can.prevJob.lastCompanyName && can.prevJob.lastCompanyName.toLowerCase().includes(search.toLowerCase())) ||
  //                universityMatch;
  //       })
  //     ]);
  //   }
  // }, [allUsersFree, search]);

  useEffect(() => {
    if (allUsersFree) {
      const filteredUsers = allUsersFree.filter((can) => {
        const universityMatch = can.educationHistory && can.educationHistory.some(edu => edu.university.toLowerCase().includes(search.toLowerCase()));
        const jobGroupsMatch = jobGroup.length === 0 || (can.jobGroups && can.jobGroups.some(job => jobGroup.includes(job.idJobGroup)));
        const searchMatch = can.name.toLowerCase().includes(search.toLowerCase()) || 
                            can.lastname.toLowerCase().includes(search.toLowerCase()) || 
                            (search === "0" ? can["OESY(Y)"] == null : (can["OESY(Y)"] && can["OESY(Y)"].toString().includes(search))) ||
                            (can.prevJob && can.prevJob.lastCompanyName && can.prevJob.lastCompanyName.toLowerCase().includes(search.toLowerCase())) ||
                            universityMatch;
        return searchMatch && jobGroupsMatch;
      });
      setFilterAllUsersFree(filteredUsers);
    }
  }, [allUsersFree, search, jobGroup]);
  


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const currentData = filterCandidates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const currentData = filterAllUsersFree.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  // console.log(currentData);
  // console.log(jobGroup);

  return (
    <StyledRoot>
      <div className="part-add-manpower">
        <div className="part-filter">
          <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px" }}
            className="search-name"
            placeholder={`${t("Search")}...`}
            label={`${t("Search")}...`}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <StyledFormControl sx={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }}>
            <InputLabel id="demo-multiple-chip-label">{t("SearchByJobGroup")}</InputLabel>
            <Select
              multiple
              value={jobGroup}
              onChange={handleChangeJobGroup}
              input={<OutlinedInput label={t("SearchByJobGroup")} />}
              // renderValue={(selected) => selected.join(', ')}
              renderValue={(selected) => selected.map(value => {
                const job = AllJobGroups.find(job => job.idJobGroup === value);
                return job ? job.jobGroupName : "";
              }).join(', ')}
            >
              <MenuItem value={"all"}>
                <ListItemText primary={t("AllJobGroups")} />
              </MenuItem>
              {AllJobGroups && AllJobGroups.map((job) => (
                <MenuItem key={job.idJobGroup} value={job.idJobGroup} >
                  <Checkbox checked={jobGroup.indexOf(job.idJobGroup) > -1} />
                  <ListItemText primary={job.jobGroupName} />
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </div>
        <Box display="flex" gap="8px">
            <ButtonBlue
              variant="contained"
              startIcon={<i className="fa-regular fa-plus"></i>}
              component={Link}
              to="/manage/hunter/peer/form"
            >
              {t("Add")}{t("Manpower")}
            </ButtonBlue>
            <ButtonBlue variant="contained" startIcon={<i className="fa-regular fa-up"></i>} onClick={() => { setIsOpenModalImportExcel(true) }} style={{ textTransform: "none" }}>{t("Upload")} Excel</ButtonBlue>
            {isOpenModalImportExcel && (
              <DialogExcel
                open={isOpenModalImportExcel}
                onClose={() => { setIsOpenModalImportExcel(false) }}
              />
            )}
            <ButtonBlue variant="contained" startIcon={<i className="fa-regular fa-link"></i>} onClick={() => { setDialogLinkRegisterManpowerConfig({ isOpen: true }) }} style={{ textTransform: "none" }}>{t("PersonnelRecruitmentLink")}</ButtonBlue>
            {dialogLinkRegisterManpowerConfig.isOpen && (
              <DialogLinkRegisterManpower
                open={dialogLinkRegisterManpowerConfig.isOpen}
                onClose={() => { setDialogLinkRegisterManpowerConfig({ isOpen: false }) }}
                isOpenRegisterManpower={isOpenRegisterManpower}
                setIsOpenRegisterManpower={setIsOpenRegisterManpower}
              />
            )}
          </Box>
      </div>
      <Grid container spacing={5}>
        {/* {allUsersFree.map((user, index) => ( */}
        {(isFetching) &&
          <Grid item xs={12} sm={12} md={12} lg={12}>
              <div style={{display: "flex", justifyContent: "center"}}>
                  {t("LoadingData")}
              </div>
          </Grid>
        }
        {(!isFetching && currentData && currentData.length <= 0) &&
          <Grid item xs={12} sm={12} md={12} lg={12}>
              <div style={{display: "flex", justifyContent: "center"}}>
                  {t("NoEmployee")}
              </div>
          </Grid>
        }
        {(!isFetching && currentData.length > 0) && 
        currentData.map((user, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={user.idManpower} to={`/manage/hunter/peer/profile/${user.idManpower}`} component={Link} style={{ textDecoration: "none" }}>
            <StyledCard>
              <div className="part-avatar">
                <Avatar sx={{ bgcolor: stringToColor(`${user.name} ${user.lastname}`), fontSize: 25 }} children={`${user.name[0]}${user.lastname[0]}`} />
              </div>
              <div className="part-name">
                <Typography variant="h5">{user.name} {user.lastname}</Typography>
              </div>
              <div className="part-rating">
                <Rating
                  sx={{
                    fontSize: "1.1rem",
                    color: "#FFD600",
                  }}
                  value={user.rating}
                  readOnly
                />
              </div>
              <div className="part-infomation">
                <div className="iconandinfo">
                  <div className="icon">
                    <i className="fa-solid fa-user-tie"></i>
                  </div>
                  <Typography variant="info">{user.prevJob.lastPositionName ? user.prevJob.lastPositionName : t("NotSpecified")}</Typography>
                </div>
                <div className="iconandinfo">
                  <div className="icon">
                    <i className="fa-solid fa-building"></i>
                  </div>
                  <Typography variant="info">{user.prevJob.lastCompanyName ? user.prevJob.lastCompanyName : t("NotSpecified")}</Typography>
                </div>
                <div className="iconandinfo">
                  <div className="icon">
                    <i className="fa-solid fa-graduation-cap"></i>
                  </div>
                  <Typography variant="info">{user.educationDegree ? user.educationDegree : t("NotSpecified")}</Typography>
                </div>
                <div className="iconandinfo">
                  <div className="icon">
                    <i className="fa-solid fa-building-columns"></i>
                  </div>
                  <Typography variant="info">{user.educationHistory ? DisplayRecentEducation(user.educationHistory) : t("NotSpecified")}</Typography>
                </div>
                <div className="iconandinfo">
                  <div className="icon">
                    <i className="fa-regular fa-clock"></i>
                  </div>
                  <Typography variant="info">{user.dateOfBirth ? Displayage(user.dateOfBirth) : `${t("NotSpecified")}${t("Age")}`}</Typography>
                </div>
                <div className="iconandinfo">
                  <div className="icon">
                    <i className="fa-solid fa-business-time"></i>
                  </div>
                  <Typography variant="info">{user['OESY(Y)'] && `${user['OESY(Y)']} ${t("Year")}`} {user['OESY(M)'] && `${user['OESY(M)']} ${t("Month")}`} {user && !user['OESY(Y)'] && !user['OESY(M)'] && t("NoWorkExperience")}</Typography>
                </div>
                <div className="iconandinfo">
                  <div className="icon">
                    <i className="fa-solid fa-sack-dollar"></i>
                  </div>
                  <Typography variant="info">{user.expectedSalary ? user.expectedSalary.toLocaleString() : t("NotSpecified")}</Typography>
                </div>
              </div>
              <div className="part-status">
                {DisplayStatus(user.idManpowerStatus)}
              </div>

            </StyledCard>
          </Grid>
        ))}
      </Grid>

      <TablePagination style={{ paddingTop: "20px" }}
        rowsPerPageOptions={[3, 6, 9, 18]}
        component="div"
        count={allUsersFree ? allUsersFree.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${
                            count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
      />
    </StyledRoot>
  )
}

export default cardManpower;