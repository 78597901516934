// actions/overviewUserActions.js
import dayjs from "dayjs";
import overviewUserService from "../services/overview-user.service";
import {
  OVERVIEW_USER_LEAVE_FETCHING,
  OVERVIEW_USER_LEAVE_FAILED,
  OVERVIEW_USER_LEAVE_SUCCESS,
  OVERVIEW_USER_ABSENT_LATE_FETCHING,
  OVERVIEW_USER_ABSENT_LATE_FAILED,
  OVERVIEW_USER_ABSENT_LATE_SUCCESS,
  OVERVIEW_USER_OT_FETCHING,
  OVERVIEW_USER_OT_FAILED,
  OVERVIEW_USER_OT_SUCCESS,
  OVERVIEW_USER_NOTIFICATION_FAILED,
  OVERVIEW_USER_NOTIFICATION_SUCCESS,
  OVERVIEW_USER_NOTIFICATION_FETCHING
} from "./types";
import { getWorkingTimeReport } from "./report";



export const getLeaveAbsentAndLate = ({ employee, start, end }) => async (dispatch) => {
  try {
    dispatch({ type: OVERVIEW_USER_LEAVE_FETCHING });
    const resLeave = await overviewUserService.getLeave({ employee, start, end });
    const resAbsentAndLate = await overviewUserService.getAbsentAndLate({ employee, start, end });
    dispatch({
      type: OVERVIEW_USER_LEAVE_SUCCESS,
      payload: {
        leave: resLeave.data.data.result || [],
        absentAndLate: resAbsentAndLate.data.data.result || []
      },
    });
  } catch (error) {
    dispatch({
      type: OVERVIEW_USER_LEAVE_FAILED,
    });
  }
};

export const getLeave = ({ employee, start, end }) => async (dispatch) => {
  try {
    dispatch({ type: OVERVIEW_USER_LEAVE_FETCHING });
    const resLeave = await overviewUserService.getLeave({ employee, start, end });
    dispatch({
      type: OVERVIEW_USER_LEAVE_SUCCESS,
      payload: {
        leave: resLeave.data.data.result || [],
        EmployeeResult: resLeave.data.data.EmployeeResult || [],
      },
    });
  } catch (error) {
    dispatch({
      type: OVERVIEW_USER_LEAVE_FAILED,
    });
  }
};

export const getAbsentAndLate = ({ employee, start, end }) => async (dispatch) => {
  try {
    dispatch({ type: OVERVIEW_USER_ABSENT_LATE_FETCHING });
    const resAbsentAndLate = await overviewUserService.getAbsentAndLate({ employee, start, end });

    dispatch({
      type: OVERVIEW_USER_ABSENT_LATE_SUCCESS,
      payload: {
        workTime: resAbsentAndLate.data.data.result || [],
      },
    });
  } catch (error) {
    dispatch({
      type: OVERVIEW_USER_ABSENT_LATE_FAILED,
    });
  }
};

export const getOT = ({ start, end, idCompany, idDivision, idDepartment, idSection }) => async (dispatch) => {
  try {
    dispatch({ type: OVERVIEW_USER_OT_FETCHING });
    const response = await getWorkingTimeReport({
      start: dayjs(start).format("YYYY-MM-DD"),
      end: dayjs(end).format("YYYY-MM-DD"),
      idCompany: (idCompany && idCompany) || null,
      idDivision: (idDivision && idDivision) || null,
      idDepartment: (idDepartment && idDepartment) || null,
      idSection: (idSection && idSection) || null,
    });

    const totalWorkHours = response.data.employeeList
      .reduce((totals, employee) => {
        return {
          workHourActualTotal: totals.workHourActualTotal + employee.workHourActualTotal,
          otHourTotal: totals.otHourTotal + employee.otHourTotal
        };
      }, { workHourActualTotal: 0, otHourTotal: 0 });

    dispatch({
      type: OVERVIEW_USER_OT_SUCCESS,
      payload: {
        workTime: totalWorkHours || []
      },
    });
  } catch (error) {
    dispatch({
      type: OVERVIEW_USER_OT_FAILED,
    });
  }
};

export const getNotification = ({ idEmployees, role, idCompany }) => async (dispatch) => {
  try {
    dispatch({ type: OVERVIEW_USER_NOTIFICATION_FETCHING });
    const res = await overviewUserService.getNotification({ idEmployees, role, idCompany });
    dispatch({
      type: OVERVIEW_USER_NOTIFICATION_SUCCESS,
      payload: {
        notification: res.data || [],
      },
    });
  } catch (error) {
    dispatch({
      type: OVERVIEW_USER_NOTIFICATION_FAILED,
    });
  }
};

export const updateNotification = async ({ id }) => {
  try {
    const res = await overviewUserService.updateNotification({ id });

    if (res.status === 200) {
      console.log('Notification updated successfully');
      return res; 
    } else {
      console.error('Failed to update notification:', res);
      return res;
    }
  } catch (error) {
    console.error('Error updating notification:', error);
    throw error;
  }
};
