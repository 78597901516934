import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import StatusRequest from "../../shared/requestList/stausRequest";

function RequestTimeCard({ request }) {
  const { t } = useTranslation();
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );

  const whatLevel = (row) => {
    if (row.isDoubleApproval) {
      return row.isManagerLV1Approve === 1 ? 2 : 1;
    } else {
      return null;
    }
  };

  const isRequestApprove = (row) => {
    if (row.idRequestTimeWithdraw || row.idLeave) {
      return row.isApprove;
    } else {
      if (
        row.isManagerLV1Approve === null &&
        row.isManagerLV2Approve === null
      ) {
        return null;
      } else {
        if (row.isDoubleApproval) {
          if (row.isManagerLV1Approve === null) {
            return null;
          } else if (row.isManagerLV1Approve === 1) {
            return row.isManagerLV2Approve === null
              ? null
              : row.isManagerLV2Approve;
          } else {
            return row.isManagerLV1Approve;
          }
        } else {
          return row.approvalLevel === 1
            ? row.isManagerLV1Approve
            : row.isManagerLV2Approve;
        }
      }
    }
  };

  const checkStatus = (row) => {
    const step = whatLevel(row);
    const status = isRequestApprove(row);
    const active = row.isActive;

    if (active === 1) {
      if (status === 1) {
        return "Approved";
      } else if (status === 0) {
        return "NotApproved";
      } else {
        return step === 1 || step === 2 ? "PendingReview" : "PendingApproval";
      }
    } else {
      return "CancelTransaction";
    }
  };

  // Extracting common variables
  const approverName =
    request.approvalLevel === 1 &&
    request.managerLV1_firstname_TH &&
    request.managerLV1_lastname_TH &&
    request.managerLV1_email
      ? `${request.managerLV1_firstname_TH} ${request.managerLV1_lastname_TH} (${request.managerLV1_email})`
      : request.approvalLevel === 2 &&
        request.managerLV2_firstname_TH &&
        request.managerLV2_lastname_TH &&
        request.managerLV2_email
      ? `${request.managerLV2_firstname_TH} ${request.managerLV2_lastname_TH} (${request.managerLV2_email})`
      : `${request.manager_firstname_TH} ${request.manager_lastname_TH} (${request.manager_email})`;

  const approvedDate =
    request.approvalLevel === 1
      ? dayjs(request.managerLV1ApproveDateText, "DD/MM/YYYY HH:mm").format(
          "D/MM/BBBB HH:mm"
        )
      : request.approvalLevel === 2
      ? dayjs(request.managerLV2ApproveDateText, "DD/MM/YYYY HH:mm").format(
          "D/MM/BBBB HH:mm"
        )
      : dayjs(request.approveDateText, "DD/MM/YYYY HH:mm").format(
          "D/MM/BBBB HH:mm"
        );
  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        borderRadius: 4,
        display: "flex",
        alignItems: "flex-start",
        bgcolor: "#F1F0F0",
        width: "100%",
        marginBottom: "10px",
        whiteSpace: "pre-wrap",
      }}
    >
      <StatusRequest
        step={whatLevel(request)}
        status={isRequestApprove(request)}
        active={request.isActive}
        sx={{ width: 60, height: 60, mr: 2, flexShrink: 0 }}
      />
      <Box sx={{ flexGrow: 1, minWidth: 0, marginLeft: "8px" }}>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("Status")}: {t(checkStatus(request))}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            wordWrap: "break-word",
            display: "flex",
            flexWrap: "wrap",
            lineHeight: 1.2,
            "& .label": {
              width: "48px",
              flexShrink: 0,
            },
            "& .content": {
              flex: 1,
              marginBottom: 0,
            },
            "& .indent": {
              paddingLeft: "48px",
              width: "100%",
              marginTop: "2px",
            },
          }}
        >
          <span className="label">{t("List")}:{" "}</span>
          <span className="content">{t(request.name)}</span>
          {(request.idRequestType === 2 || request.idRequestType === 3) && (
            <>
              {request.xWorkingDailyHoliday > 0 && (
                <span className="indent">
                  OT x {payrollSetting.xWorkingDailyHoliday} ={" "}
                  {parseFloat(request.xWorkingDailyHoliday / 60).toFixed(2)}{" "}
                  {`${t("Unit.ShortHours")}`}
                </span>
              )}
              {request.xWorkingMonthlyHoliday > 0 && (
                <span className="indent">
                  OT x {payrollSetting.xWorkingMonthlyHoliday} ={" "}
                  {parseFloat(request.xWorkingMonthlyHoliday / 60).toFixed(2)}{" "}
                  {`${t("Unit.ShortHours")}`}
                </span>
              )}
              {request.xOT > 0 && (
                <span className="indent">
                  OT x {payrollSetting.xOT} ={" "}
                  {parseFloat(request.xOT / 60).toFixed(2)}{" "}
                  {`${t("Unit.ShortHours")}`}
                </span>
              )}
              {request.xOTHoliday > 0 && (
                <span className="indent">
                  OT x {payrollSetting.xOTHoliday} ={" "}
                  {parseFloat(request.xOTHoliday / 60).toFixed(2)}{" "}
                  {`${t("Unit.ShortHours")}`}
                </span>
              )}{" "}
            </>
          )}
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("Start")}
          {"-"}
          {t("End")}:{" "}
          {request.isFullDay ? (
            <>
              {request.startText === request.endText ? (
                <>
                  {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                    "D/MM/BBBB"
                  )}
                </>
              ) : (
                <>
                  {`${dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                    "D/MM/BBBB"
                  )} -`}
                  {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                    "D/MM/BBBB"
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {dayjs(request.startText, "DD/MM/YYYY HH:mm").format("D/MM/BBBB")}{" "}
              (
              {`${dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                "HH:mm"
              )} - ${dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                "HH:mm"
              )}`}
              )
            </>
          )}
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("Reason")}: {request.reasonName}{" "}
          {request.otherReason && <>({request.otherReason})</>}
        </Typography>

        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("Approver")}: {approverName ? approverName : ""}
        </Typography>

        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("ApprovedDate")}:{" "}
          {approvedDate && approvedDate !== "Invalid Date" ? approvedDate : ""}
        </Typography>
      </Box>
    </Paper>
  );
}

export default RequestTimeCard;
