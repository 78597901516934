import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import "dayjs/locale/th";

import {
  Button,
  Container,
  Typography,
  CardContent,
  TablePagination,
  Grid,
  Box,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";

import FilePresentIcon from "@mui/icons-material/FilePresent";

import StyledCard from "../../../../shared/general/Card";
import Backdrop from "../../../../shared/general/Backdrop";

import RowItemExpense from "../general/row-general";
import RowSummaryExpense from "../general/row-summary";
import Comments from "../../comments";
import Actions from "../../actions";
import utils from "../../../../../../utils";

import { getExpenseFlexCoinById } from "../../../../../../actions/expense";
import { getUserFullName } from "../../../../../../utils/userData";

const StyledRoot = styled("div")({
  width: "1280px !important",
  margin: "auto",
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 24,
  },
  "& .head": {
    paddingTop: 8,
    marginBottom: 24,
    "& .MuiTypography-subtitle1": {
      fontWeight: 500,
    },
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledTableCell = styled(TableCell)({
  border: "none",
  padding: 0,
  "& .MuiTypography-root": {
    marginBottom: 16,
  },
  "&.summary": {
    textAlign: "center",
  },
  "&.date-document": {
    width: 120,
  },
  "&.service": {
    width: 150,
  },
  "&.description": {
    width: 200,
  },
  "&.amount": {
    width: 104,
  },
  "&.price": {
    width: 104,
  },
  "&.discount": {
    width: 104,
  },
  "&.tax": {
    width: 40,
  },
  "&.price-before-tax": {
    width: 104,
  },
  "&.tax-withholding": {
    width: 104,
  },
  "&.delete": {
    width: 32,
  },
});

const StyledGrid = styled(Grid)({
  borderRight: "2px solid rgba(145, 158, 171, 0.32)",
});

const ViewExpenseFlexCoinPage = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: expenseDetail } = useSelector((state) => state.expenseDetail);
  const { idExpense } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const rowHeader = [
    {
      caption: t("Date"),
      className: "date-document",
    },
    {
      caption: t("ProductAndService"),
      className: "service",
    },
    {
      caption: t("Description"),
      className: "description",
    },
    {
      caption: t("Quantity"),
      className: "amount",
    },
    {
      caption: t("PricePerUnit"),
      className: "price",
    },
    {
      caption: t("Tax"),
      className: "tax",
    },
    {
      caption: t("PriceBeforeTax"),
      className: "price-before-tax",
    },
    {
      caption: t("Withholding"),
      className: "tax-withholding",
    },
  ];

  const summaryRowHeader = [
    {
      caption: "",
      className: "order",
    },
    {
      caption: t("ProductAndService"),
      className: "service",
    },
    {
      caption: t("Quantity"),
      className: "amount",
    },
    {
      caption: t("PricePerUnit"),
      className: "price",
    },
    {
      caption: t("Tax"),
      className: "tax",
    },
    {
      caption: t("PriceBeforeTax"),
      className: "price-before-tax",
    },
    {
      caption: t("Withholding"),
      className: "tax-withholding",
    },
  ];

  useEffect(() => {
    if (userProfile) {
      dispatch(getExpenseFlexCoinById(idExpense));
    }
  }, [userProfile]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <div>
      {expenseDetail ? (
        <Box>
          <div>
            <div className="wrap-head">
              <StyledHeadLabel variant="h6">
                {`${t("GeneralInformation")} (FlexCoin)`}
              </StyledHeadLabel>
            </div>
            <div>
              <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
                {t("DocumentNumber")}
              </StyledHeadLabel>
              <Typography
                component="p"
                variant="subtitle1"
                sx={{
                  paddingLeft: 4,
                  marginBottom: 2,
                  marginTop: 1,
                }}
              >
                &nbsp;&nbsp;{expenseDetail.documentId}
              </Typography>
            </div>
            <div>
              <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
                {t("TitleName")}
              </StyledHeadLabel>
              <Typography
                component="p"
                variant="subtitle1"
                sx={{
                  paddingLeft: 4,
                  marginBottom: 4,
                  marginTop: 1,
                }}
              >
                &nbsp;&nbsp;{expenseDetail.nameExpense}
              </Typography>
            </div>
            <div>
              <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
                {t("Approver")}
              </StyledHeadLabel>
              <Typography
                component="p"
                variant="subtitle1"
                sx={{
                  paddingLeft: 4,
                  marginBottom: 4,
                  marginTop: 1,
                }}
              >
                &nbsp;&nbsp;
                {getUserFullName({
                  firstname_TH: expenseDetail.approver_firstname_TH,
                  lastname_TH: expenseDetail.approver_lastname_TH,
                  firstname_EN: expenseDetail.approver_firstname_EN,
                  lastname_EN: expenseDetail.approver_lastname_EN,
                })}
              </Typography>
            </div>
            <div>
              <Grid container spacing={2}>
                <StyledGrid item xs={12} sm={3}>
                  <StyledHeadLabel
                    variant="body2"
                    color="text.third"
                    gutterBottom
                  >
                    {t("LocationExpenses")}
                  </StyledHeadLabel>
                  <Typography
                    component="p"
                    variant="subtitle1"
                    sx={{
                      paddingLeft: 4,
                      marginBottom: 2,
                      marginTop: 1,
                    }}
                  >
                    &nbsp;&nbsp;
                    {expenseDetail.isInternational
                      ? t("International")
                      : t("Domestic")}
                  </Typography>
                </StyledGrid>
                <StyledGrid item xs={12} sm={3}>
                  <StyledHeadLabel
                    variant="body2"
                    color="text.third"
                    gutterBottom
                  >
                    {t("vatType")}
                  </StyledHeadLabel>
                  <Typography
                    component="p"
                    variant="subtitle1"
                    sx={{
                      paddingLeft: 4,
                      marginBottom: 2,
                      marginTop: 1,
                    }}
                  >
                    &nbsp;&nbsp;
                    {expenseDetail.isVatIncluded
                      ? t("VatIncluded")
                      : t("VatExcluded")}
                  </Typography>
                </StyledGrid>
                <StyledGrid item xs={12} sm={3}>
                  <StyledHeadLabel
                    variant="body2"
                    color="text.third"
                    gutterBottom
                  >
                    {t("Currency")}
                  </StyledHeadLabel>
                  <Typography
                    component="p"
                    variant="subtitle1"
                    sx={{
                      paddingLeft: 4,
                      marginBottom: 2,
                      marginTop: 1,
                    }}
                  >
                    &nbsp;&nbsp;{t(`${expenseDetail.currency}`)}
                  </Typography>
                </StyledGrid>
                {expenseDetail.currency && expenseDetail.currency !== "THB" && (
                  <Grid item xs={12} sm={3}>
                    <StyledHeadLabel
                      variant="body2"
                      color="text.third"
                      gutterBottom
                    >
                      {t("ExchangeRate")}
                    </StyledHeadLabel>
                    {/* <TextFieldTheme
                            id="currencyRate"
                            value={expenseDetail.currencyRate}
                            name="currencyRate"
                            onChange={handleChangeFormData}
                            type="number"
                          /> */}
                    <Typography
                      component="p"
                      variant="subtitle1"
                      sx={{
                        paddingLeft: 4,
                        marginBottom: 2,
                        marginTop: 1,
                      }}
                    >
                      &nbsp;&nbsp;{expenseDetail.currencyRate}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
          <StyledDivider />
          <div>
            <div className="wrap-head">
              <StyledHeadLabel variant="h6">{t("List")}</StyledHeadLabel>
            </div>

            <TableContainer>
              <Table
                style={{
                  minWidth: 650,
                  borderSpacing: "0 4px",
                  borderCollapse: "separate",
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    {rowHeader.map((item) => (
                      <StyledTableCell className={item.className}>
                        <StyledHeadLabel
                          variant="body2"
                          color="text.third"
                          gutterBottom
                          sx={{ textAlign: "center" }}
                        >
                          {t(item.caption)}
                        </StyledHeadLabel>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {expenseDetail.listExpense &&
                    expenseDetail.listExpense.length &&
                    expenseDetail.listExpense.length > 0 &&
                    expenseDetail.listExpense
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <RowItemExpense
                          key={index}
                          count={
                            expenseDetail.listExpense &&
                            expenseDetail.listExpense.length
                              ? expenseDetail.listExpense.length
                              : 0
                          }
                          data={expenseDetail.listExpense[index]}
                          index={index}
                          open={open}
                        />
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[
                10,
                20,
                30,
                40,
                50,
                { label: t("All"), value: -1 },
              ]}
              component="div"
              count={
                expenseDetail.listExpense && expenseDetail.listExpense.length
                  ? expenseDetail.listExpense.length
                  : 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${
                            count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
            />
          </div>
          <StyledDivider />
          <div>
            <div className="wrap-head">
              <StyledHeadLabel variant="h6">{t("SumaryItem")}</StyledHeadLabel>
            </div>
            {expenseDetail.currency && expenseDetail.currency !== "THB" && (
              <div style={{ marginBottom: 32 }}>
                <TableContainer>
                  <Table
                    style={{
                      minWidth: 650,
                      borderSpacing: "0 4px",
                      borderCollapse: "separate",
                    }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        {summaryRowHeader.map((item) => (
                          <StyledTableCell
                            className={(item.className, "summary")}
                          >
                            <StyledHeadLabel
                              variant="body2"
                              color="text.third"
                              gutterBottom
                            >
                              t(item.caption)
                            </StyledHeadLabel>
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {expenseDetail.listExpense &&
                        expenseDetail.listExpense.length &&
                        expenseDetail.listExpense.length > 0 &&
                        expenseDetail.listExpense
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item, index) => (
                            <RowSummaryExpense
                              key={index}
                              count={expenseDetail.listExpense.length}
                              data={item}
                              index={index}
                              open={open}
                            />
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
            <div>
              <div className="wrap-row-sum">
                <Typography color="text.third">
                  {t("TotalValue")}&nbsp;&nbsp;
                </Typography>
                <Typography color="text.third" variant="h6">
                  {utils.numberWithCommas(parseFloat(expenseDetail.total))}
                </Typography>
                <Typography color="text.third">
                  &nbsp;&nbsp;{t("Baht")}
                </Typography>
              </div>
              <div className="wrap-row-sum">
                <Typography color="text.third">
                  {t("TotalValueTax")}&nbsp;&nbsp;
                </Typography>
                <Typography color="text.third" variant="h6">
                  {utils.numberWithCommas(parseFloat(expenseDetail.vat))}
                </Typography>
                <Typography color="text.third">
                  &nbsp;&nbsp;{t("Baht")}
                </Typography>
              </div>
              <div className="wrap-row-sum">
                <Typography color="text.third">
                  {t("TotalWithholding")}&nbsp;&nbsp;
                </Typography>
                <Typography color="text.third" variant="h6">
                  {utils.numberWithCommas(
                    parseFloat(expenseDetail.withholding)
                  )}
                </Typography>
                <Typography color="text.third">
                  &nbsp;&nbsp;{t("Baht")}
                </Typography>
              </div>
              <div className="wrap-row-sum">
                <Typography color="text.third">
                  {t("TotalNetWorth")}&nbsp;&nbsp;
                </Typography>
                <Typography color="text.third" variant="h6">
                  {utils.numberWithCommas(parseFloat(expenseDetail.net))}
                </Typography>
                <Typography color="text.third">
                  &nbsp;&nbsp;{t("Baht")}
                </Typography>
              </div>
              <div className="wrap-row-sum">
                <Typography variant="h5">
                  {t("CoinsUsed")}&nbsp;&nbsp;
                </Typography>
                <Typography variant="h4">
                  {utils.numberWithCommas(parseFloat(expenseDetail.flexCoins))}
                </Typography>
                <Typography variant="h5">&nbsp;&nbsp;{t("Coin")}</Typography>
              </div>
            </div>
          </div>
          <StyledDivider />
          {expenseDetail.remark && (
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">{t("Note")}</StyledHeadLabel>
              </div>
              <Typography
                component="p"
                variant="subtitle1"
                sx={{
                  paddingLeft: 4,
                  marginBottom: 4,
                }}
              >
                &nbsp;&nbsp;{expenseDetail.remark}
              </Typography>
              <StyledDivider />
            </div>
          )}
          <div>
            <div className="wrap-head">
              <StyledHeadLabel variant="h6">{t("File")}</StyledHeadLabel>
            </div>
            {expenseDetail.fileURL ? (
              <Box
                style={{
                  position: "relative",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    padding: 15,
                    backgroundColor: "#f9f9fb",
                    display: "flex",
                    borderRadius: 15,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    expenseDetail.fileURL &&
                      window.open(`${expenseDetail.fileURL.URL}`, "_blank");
                  }}
                >
                  <FilePresentIcon
                    height="24"
                    style={{
                      marginRight: 10,
                    }}
                  ></FilePresentIcon>
                  <Typography>{expenseDetail.fileURL.path}</Typography>
                </div>
              </Box>
            ) : (
              <Box>
                <div
                  style={{
                    padding: 24,
                    backgroundColor: "#f9f9fb",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: 15,
                  }}
                >
                  <Typography color="error">{t("NoAttachedFile")}</Typography>
                </div>
              </Box>
            )}
          </div>
          {expenseDetail.comments &&
          expenseDetail.comments.length &&
          expenseDetail.comments.length > 0 ? (
            <div>
              <StyledDivider />
              <Comments comments={expenseDetail.comments} />
            </div>
          ) : (
            <span></span>
          )}
          {expenseDetail.actions &&
          expenseDetail.actions.length &&
          expenseDetail.actions.length > 0 ? (
            <div>
              <StyledDivider />
              <Actions actions={expenseDetail.actions} />
            </div>
          ) : (
            <span></span>
          )}
        </Box>
      ) : (
        <Backdrop open={true} />
      )}
    </div>
  );
};

export default ViewExpenseFlexCoinPage;
