import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Container,
  Grid,
  Popper,
  Tab,
  Tabs,
  TextField,
  autocompleteClasses,
  styled,
  Card,
  Typography,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { getAllObjective } from "../../../../../actions/okrs";
import { getAllEmployees } from "../../../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import StatusPlan from "./statusPlan";
import StatusApprove from "./statusApprove";
import { useTranslation } from "react-i18next";
import { getAffiliateOrganization } from "../../../../../actions/company";
import dayjs from "dayjs";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
    "& .MuiOutlinedInput-input": {
      padding: "16px 14px",
    },
    "& .MuiTableCell-root": {
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
}));

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const ContainerStyled = styled(Container)({
  paddingTop: "10px",
  height: "100%",
  minHeight: "100vh",
});

const StyledRoot = styled(Box)({
  "& .keyresult-text": {
    fontSize: "20px",
    fontWeight: 500,
  },
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const StyledCard = styled(Card)({
  minHeight: "70px",
  boxShadow: "none",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  background:
    "linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))",
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
});

const StyleHeadBox = styled(Box)({
  "& .box-header": {
    minHeight: "200px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "& .text-header": {
      fontSize: "48px",
      fontWeight: "bold",
    },
    "& .img-header": {
      objectFit: "contain",
    },
  },

  "@media (max-width: 600px)": {
    "& .box-header": {
      flexDirection: "column",
      minHeight: "unset",
      justifyContent: "center",
      alignItems: "center",
      "& .text-header": {
        fontSize: "36px",
        marginTop: "20px",
        textAlign: "center",
      },
    },
  },
});

function OKRStatusPlanApprove() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [view, setView] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [dataObjective, setDataObjective] = useState([]);
  const [objectiveApprove, setObjectiveApprove] = useState([]);

  const { result: employeesList } = useSelector((state) => state.employees);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectYear, setSelectYear] = useState(dayjs().get("year"));

  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const [divisions, setDivisions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [sections, setSections] = useState([]);
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    if (employeesList) {
      const divisionSet = new Set();
      const departmentSet = new Set();
      const sectionSet = new Set();
      const positionSet = new Set();

      employeesList.forEach((employee) => {
        if (employee.divisionName)
          divisionSet.add(employee.divisionName.trim());
        if (employee.departmentName)
          departmentSet.add(employee.departmentName.trim());
        if (employee.sectionName) sectionSet.add(employee.sectionName.trim());
        if (employee.positionName)
          positionSet.add(employee.positionName.trim());
      });

      setDivisions([...divisionSet]);
      setDepartments([...departmentSet]);
      setSections([...sectionSet]);
      setPositions([...positionSet]);
    }
  }, [employeesList]);

  useEffect(() => {
    dispatch(getAllObjective()).then((res) => {
      if (res.status === 200) {
        let data = res.data;

        if (selectYear) {
          data = data.filter((objective) => objective.year === selectYear);
        }

        if (selectedCompany) {
          data = data.filter(
            (objective) =>
              objective.companyName &&
              objective.companyName.trim().toLowerCase() ===
                selectedCompany.companyName.trim().toLowerCase()
          );
        }

        if (selectedEmployee) {
          data = data.filter(
            (objective) =>
              objective.idEmployees === selectedEmployee.idEmployees
          );
        }

        if (selectedDivision) {
          data = data.filter(
            (objective) =>
              objective.divisionName &&
              objective.divisionName.trim().toLowerCase() ===
                selectedDivision.trim().toLowerCase()
          );
        }

        if (selectedDepartment) {
          data = data.filter(
            (objective) =>
              objective.departmentName &&
              objective.departmentName.trim().toLowerCase() ===
                selectedDepartment.trim().toLowerCase()
          );
        }

        if (selectedSection) {
          data = data.filter(
            (objective) =>
              objective.sectionName &&
              objective.sectionName.trim().toLowerCase() ===
                selectedSection.trim().toLowerCase()
          );
        }

        if (selectedPosition) {
          data = data.filter(
            (objective) =>
              objective.positionName &&
              objective.positionName.trim().toLowerCase() ===
                selectedPosition.trim().toLowerCase()
          );
        }

        setDataObjective(data);

        const approvedObjectives = data
          .map((objective) => {
            const keyResults = objective.keyResults.filter(
              (item) => item.isApprove
            );
            if (keyResults.length > 0) {
              return { ...objective, keyResults };
            }
            return null;
          })
          .filter(Boolean);

        setObjectiveApprove(approvedObjectives);
      }
    });
  }, [
    dispatch,
    selectedCompany,
    selectedEmployee,
    selectYear,
    selectedDivision,
    selectedDepartment,
    selectedSection,
    selectedPosition,
  ]);

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getAffiliateOrganization());
  }, [dispatch]);

  const handleChangeTab = (event, newValue) => {
    setView(newValue);
  };

  const handleCompanyChange = (event, newValue) => {
    setSelectedCompany(newValue);
  };

  const handleChangeYear = (event) => {
    setSelectYear(event.target.value);
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {employeesList && (
          <StyledCard
            style={{
              marginBottom: "48px",
              marginTop: "20px",
            }}
          >
            <StyleHeadBox>
              <Box className="box-header">
                <Grid
                  container
                  pt={"40px"}
                  pl={{ xs: "16px", sm: "40px" }}
                  pb={{ xs: "0px", sm: "40px" }}
                >
                  <Grid item xs={12}>
                    <Typography className="text-header">
                      {`${t("OKRStatusList")}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} display="flex" gap="8px" flexWrap="wrap">
                    <div>
                      <Typography
                        fontSize="14px"
                        fontWeight="600"
                        marginBottom="4px"
                        color="text.third"
                      >
                        {t("SelectCompany")}
                      </Typography>
                      <StyledAutocomplete
                        style={{
                          width: "300px",
                          background: "#fff",
                          border: "none",
                          borderRadius: "8px",
                        }}
                        options={affiliateOrganizationList}
                        onChange={handleCompanyChange}
                        popupIcon={<i className="fa-light fa-chevron-down"></i>}
                        getOptionLabel={(option) => `${option.companyName}`}
                        renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {`${option.companyName}`}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            placeholder={`${t("SelectCompany")}`}
                          />
                        )}
                        PopperComponent={StyledPopper}
                        noOptionsText={`${t("NoData")}`}
                      />
                    </div>

                    <div>
                      <Typography
                        fontSize="14px"
                        fontWeight="600"
                        marginBottom="4px"
                        color="text.third"
                      >
                        {t("Employee")}
                      </Typography>
                      <StyledAutocomplete
                        style={{
                          width: "240px",
                          background: "#fff",
                          border: "none",
                          borderRadius: "8px",
                        }}
                        options={employeesList}
                        value={selectedEmployee}
                        onChange={(event, newValue) => {
                          setSelectedEmployee(newValue);
                        }}
                        popupIcon={<i className="fa-light fa-chevron-down"></i>}
                        getOptionLabel={(option) =>
                          `${option.firstname_TH} ${option.lastname_TH}`
                        }
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.idEmployees}>
                              {`${option.firstname_TH} ${option.lastname_TH}`}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            placeholder={`${t("SelectEmp")}`}
                          />
                        )}
                        PopperComponent={StyledPopper}
                        noOptionsText={`${t("NoData")}`}
                      />
                    </div>

                    <div>
                      <Typography
                        fontSize="14px"
                        fontWeight="600"
                        marginBottom="4px"
                        color="text.third"
                      >
                        {t("SelectYear")}
                      </Typography>
                      <Select
                        value={selectYear}
                        onChange={handleChangeYear}
                        sx={{
                          borderRadius: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        {[0, 1, 2].map((item) => (
                          <MenuItem
                            key={item}
                            value={dayjs().get("year") - item}
                          >
                            {dayjs()
                              .subtract(item, "year")
                              .format(
                                i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"
                              )}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Grid>

                  <Grid item xs={12} mt={2}>
                    <Button
                      variant="text"
                      onClick={() =>
                        setShowAdditionalFilters(!showAdditionalFilters)
                      }
                      sx={{ textTransform: "none", color: "#DB4178" }}
                    >
                      {showAdditionalFilters
                        ? t("HideSearch")
                        : t("SearchMore")}
                    </Button>
                  </Grid>

                  {showAdditionalFilters && (
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      gap="8px"
                      flexWrap="wrap"
                      mt={1}
                    >
                      <div>
                        <Typography
                          fontSize="14px"
                          fontWeight="600"
                          marginBottom="4px"
                          color="text.third"
                        >
                          {t("Division")}
                        </Typography>
                        <StyledAutocomplete
                          style={{
                            width: "240px",
                            background: "#fff",
                            border: "none",
                            borderRadius: "8px",
                          }}
                          options={divisions}
                          onChange={(event, newValue) =>
                            setSelectedDivision(newValue)
                          }
                          popupIcon={
                            <i className="fa-light fa-chevron-down"></i>
                          }
                          getOptionLabel={(option) => option}
                          renderOption={(props, option) => (
                            <li {...props} key={option}>
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              placeholder={`${t("SelectDivision")}`}
                            />
                          )}
                          PopperComponent={StyledPopper}
                          noOptionsText={`${t("NoData")}`}
                        />
                      </div>

                      <div>
                        <Typography
                          fontSize="14px"
                          fontWeight="600"
                          marginBottom="4px"
                          color="text.third"
                        >
                          {t("Department")}
                        </Typography>
                        <StyledAutocomplete
                          style={{
                            width: "240px",
                            background: "#fff",
                            border: "none",
                            borderRadius: "8px",
                          }}
                          options={departments}
                          onChange={(event, newValue) =>
                            setSelectedDepartment(newValue)
                          }
                          popupIcon={
                            <i className="fa-light fa-chevron-down"></i>
                          }
                          getOptionLabel={(option) => option}
                          renderOption={(props, option) => (
                            <li {...props} key={option}>
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              placeholder={`${t("SelectDepartment")}`}
                            />
                          )}
                          PopperComponent={StyledPopper}
                          noOptionsText={`${t("NoData")}`}
                        />
                      </div>

                      <div>
                        <Typography
                          fontSize="14px"
                          fontWeight="600"
                          marginBottom="4px"
                          color="text.third"
                        >
                          {t("Section")}
                        </Typography>
                        <StyledAutocomplete
                          style={{
                            width: "240px",
                            background: "#fff",
                            border: "none",
                            borderRadius: "8px",
                          }}
                          options={sections}
                          onChange={(event, newValue) =>
                            setSelectedSection(newValue)
                          }
                          popupIcon={
                            <i className="fa-light fa-chevron-down"></i>
                          }
                          getOptionLabel={(option) => option}
                          renderOption={(props, option) => (
                            <li {...props} key={option}>
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              placeholder={`${t("SelectSection")}`}
                            />
                          )}
                          PopperComponent={StyledPopper}
                          noOptionsText={`${t("NoData")}`}
                        />
                      </div>

                      <div>
                        <Typography
                          fontSize="14px"
                          fontWeight="600"
                          marginBottom="4px"
                          color="text.third"
                        >
                          {t("Position")}
                        </Typography>
                        <StyledAutocomplete
                          style={{
                            width: "240px",
                            background: "#fff",
                            border: "none",
                            borderRadius: "8px",
                          }}
                          options={positions}
                          onChange={(event, newValue) =>
                            setSelectedPosition(newValue)
                          }
                          popupIcon={
                            <i className="fa-light fa-chevron-down"></i>
                          }
                          getOptionLabel={(option) => option}
                          renderOption={(props, option) => (
                            <li {...props} key={option}>
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              placeholder={`${t("SelectPosition")}`}
                            />
                          )}
                          PopperComponent={StyledPopper}
                          noOptionsText={`${t("NoData")}`}
                        />
                      </div>
                    </Grid>
                  )}
                </Grid>
                <img
                  className="img-header"
                  src={`${process.env.PUBLIC_URL}/assets/okrs/admin/plan-progress-status.png`}
                  alt="icon"
                />
              </Box>
            </StyleHeadBox>
          </StyledCard>
        )}

        <div className={classes.root}>
          <Tabs
            value={view}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            style={{ marginLeft: "50px", marginBottom: "30px" }}
          >
            <Tab
              style={{ fontSize: "16px" }}
              label={`${t("ListOfPlans")}`}
              {...a11yProps(0)}
            />
            <Tab
              style={{ fontSize: "16px" }}
              label={`${t("ListApprovedOutcomes")}`}
              {...a11yProps(1)}
            />
          </Tabs>
        </div>
        <ContainerStyled>
          <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
            <CustomTabPanel value={view} index={0}>
              <StatusPlan data={dataObjective} />
            </CustomTabPanel>
            <CustomTabPanel value={view} index={1}>
              <StatusApprove data={objectiveApprove} />
            </CustomTabPanel>
          </Box>
        </ContainerStyled>
      </Container>
    </StyledRoot>
  );
}

export default OKRStatusPlanApprove;
