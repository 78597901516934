export const personalityBusinessTypes = (t) => [
    {
        id: "E",
        title: t("adventurer"),
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/DISC2/Adventurer/BG-Adventurer.jpg)`,
        imagePath: `${process.env.PUBLIC_URL}/assets/background/DISC2/Adventurer/Adventurer.svg`,
        content: {
            longText: t("adventurerLong"),
                },
    },
    {
        id: "R",
        title:t("researcher"),
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/DISC2/Reserarch/BG-R.jpg)`,
        imagePath: `${process.env.PUBLIC_URL}/assets/background/DISC2/Reserarch/Reserarch.svg`,
        content: {
            longText: t("researcherLong"),
        },
    },
    {
        id: "D",
        title: t("doer"),
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/DISC2/Doer/BG-D.jpg)`,
        imagePath: `${process.env.PUBLIC_URL}/assets/background/DISC2/Doer/Doer.svg`,
        content: {
            longText: t("doerLong"),
        },
    },
    {
        id: "I",
        title: t("developer"),
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/DISC2/Improvement/BG-I.jpg)`,
        imagePath: `${process.env.PUBLIC_URL}/assets/background/DISC2/Improvement/Improvement.svg`,
        content: {
            longText: t("developerLong"),
        },
    },
    {
        id: "P",
        title: t("planner"),
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/DISC2/Planner/BG-P.jpg)`,
        imagePath: `${process.env.PUBLIC_URL}/assets/background/DISC2/Planner/Planner.svg`,
        content: {
            longText:`${t("plannerLong")}`,
        },
    },
    {
        id: "F",
        title: t("coordinator"),
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/DISC2/Facilitator/BG-F.jpg)`,
        imagePath: `${process.env.PUBLIC_URL}/assets/background/DISC2/Facilitator/Facilitator.svg`,
        content: {
            longText: t("coordinatorLong"),
        },
    },
];