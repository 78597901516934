import dayjs from 'dayjs';

export const PND1kTxtFile = (selectYear, employeeDetails, contributionRate) => {
    const presentDay = new Date();
    const taxValue = employeeDetails && employeeDetails.TaxValue;
    const employeeData = employeeDetails && employeeDetails.employeeDetails;

    if (employeeDetails && employeeData.length > 0) {
        let content = '';
        employeeData.forEach((item) => {
            const findEmpTax = taxValue && taxValue.find(tax => tax.idEmployees === item.idEmployees);
            
            const tax = findEmpTax && findEmpTax.value ? findEmpTax.value : 0;
            const SumLate = findEmpTax && findEmpTax.Sum_late ? findEmpTax.Sum_late : 0;
            const SumAbsent = findEmpTax && findEmpTax.Sum_absent ? findEmpTax.Sum_absent : 0;
            const AllEarnings = ((item.totalEarnings || 0) + (item.beforeAccumulateEarnings || 0)) - ((SumLate || 0) - (SumAbsent || 0));
            const year = dayjs(presentDay).format("YYYY");
            const ThaiYear = parseInt(year) + 543;
            content += `00|${item.personalID ? item.personalID : "-"}|0000000000|0000| ${item.personalID}|0000000000 | ${item.title_TH}| ${item.firstname_TH}| ${item.lastname_TH}| ${item.addressCompany ? item.addressCompany : "-"}, ${item.districtCompany ? item.districtCompany : "-"}| ${item.provinceCompany ? item.provinceCompany : "-"} | ${item.areaCodeCompany ? item.areaCodeCompany : "-"}| 00 |${selectYear}| 1 | ${dayjs(presentDay).format("DDMM")}${ThaiYear}| ${contributionRate ? contributionRate : '-'} | ${AllEarnings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}| ${(tax + item.beforeAccumulateTax || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} | 1\n\n`;
        });

        const blob = new Blob([content], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = employeeData[0].companyName ? `${employeeData[0].companyName}-ภงด-1ก.txt` : 'ภงด-1ก.txt';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    } else {
        console.log("No data");
    }
}