import {
  BANK_FETCHING,
  BANK_FAILED,
  BANK_SUCCESS,
  BANK_DETAIL_FETCHING,
  BANK_DETAIL_FAILED,
  BANK_DETAIL_SUCCESS
} from "./types";

import BankService from "../services/bank.service";

export const getAllBanks = () => async (dispatch) => {
  try {
    const res = await BankService.getAllBanks();
    if (res) {
      dispatch({
        type: BANK_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: BANK_FAILED,
    });
    return err;
  }
};

export const getBankByBankDetail = (idBankDetail) => async(dispatch) => {
  try {
    dispatch({
      type: BANK_DETAIL_FETCHING,
    });
    const res = await BankService.getBankByBankDetail(idBankDetail);
    if (res) {
      dispatch({
        type: BANK_DETAIL_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: BANK_DETAIL_FAILED,
    });
    return error;
  }
};

export const updateBankDetail = (idCompany, data) => async () => {
  try {
    const res = await BankService.updateBankDetail(idCompany, data);
    if (res) {
      return res;
    } 
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getBankDetailByCompany = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: BANK_DETAIL_FETCHING,
    });
    const res = await BankService.getBankDetailByCompany(idCompany);
    if (res) {
      dispatch({
        type: BANK_DETAIL_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: BANK_DETAIL_FAILED,
    });
    return error;
  }
};