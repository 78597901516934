import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Container,
  Breadcrumbs,
  Paper,
  Grid,
  Button,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { addEmployeePersonalityTest } from "../../../../../actions/employee";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 50,
});

const StyledPaper = styled(Paper)({
  padding: 20,
  minHeight: "480px",
  backgroundSize: "cover",
  backgroundPosition: "top",
  borderRadius: "20px",
});

const StyledQuestion = styled("div")({
  "& .header-question": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    marginTop: "10px",
    textAlign: "center",

    "& .questionText": {
      color: "black",
      fontSize: "20px",
      fontWeight: 400,
      alignSelf: "center",
      marginTop: "10px",
    },
  },
  "& .content-question": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    marginTop: "10px",
    textAlign: "center",
    maxWidth: 1000,
    minWidth: 300,

    "& .answerText": {
      color: "black",
      fontSize: "18px",
      fontWeight: 300,
      textAlign: "center",
      alignSelf: "center",
    },
  },
  "@media (max-width: 600px)": {
    "& .content-question": {
      flexDirection: "column",
    },
  },
});

const StyleButton = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginTop: "10px",
  "& button": {
    marginRight: "10px",
    color: "white",
    backgroundColor: "#DB4178",
    "&:hover": {
      backgroundColor: "#FC669B",
    },
  },
});

const quizs = [
  {
    question: 1,
    choice: [
      {
        label: "ฉันชอบคิดนอกกรอบจากสิ่งที่เป็นอยู่เดิม",
        value: "E",
      },
      {
        label: "ฉันมักจะมองหาจุดบกพร่องเพื่อพัฒนาสิ่งต่างๆให้ดีขึ้น",
        value: "I",
      },
      { label: "ฉันถนัดที่จะลงมือทำจริงเพื่อเห็นผลของงาน", value: "D" },
      {
        label: "ฉันมักจะเป็นผู้ประสานเชื่อมโยงผู้คนในการทำงานให้สำเร็จ",
        value: "F",
      },
      {
        label:
          "ฉันชอบที่จะค้นคว้าทดลองโดยใช้เวลาแต่ทำห้เกิดผลสำเร็จที่ยิ่งใหญ่",
        value: "R",
      },
      {
        label:
          "ฉันถนัดที่จะเป็นผู้วางแผนงานเพื่อให้งานดำเนินการได้อย่างมีทิศทาง",
        value: "P",
      },
    ],
  },
  {
    question: 2,
    choice: [
      { label: "ฉันชอบพบเจอผู้คนใหม่ๆ และ ไม่ชอบอยู่กับสิ่งเดิมๆ", value: "E" },
      {
        label: "ฉันชอบใช้เวลาเพื่ออยู่กับตัวเองมากกว่าการอยู่กับคนมากๆ",
        value: "R",
      },
      {
        label: "ฉันชอบพูดคุย สอน หรือสร้างแรงบัลดาลใจให้ผู้อื่นเสมอ",
        value: "F",
      },
      {
        label:
          "ฉันชอบแลกเปลี่ยนกับคนอื่นๆเพื่อหาจุดที่ดีที่สุดในเรื่องต่างๆเสมอ",
        value: "I",
      },
      {
        label:
          "ฉันมักทำความรู้จักผู้คนที่เกี่ยวข้องเพื่อเป็นเครือข่ายสู่เป้าหมายบางอย่างของฉันเสมอ",
        value: "P",
      },
      {
        label: "ฉันเป็นเพื่อนและเป็นผู้ติดตามที่ดี ไม่มีข้อจำกัดใดๆ",
        value: "D",
      },
    ],
  },
  {
    question: 3,
    choice: [
      { label: "ฉันมันจะวางแผนกำหนดสิ่งต่างๆให้ชัดเจนเสมอ", value: "P" },
      {
        label:
          "ฉันมักจะเป็นผู้ตาม และ ลุยไปกับกลุ่ม และ พร้อมช่วยเหลือสิ่งต่างๆเสมอ",
        value: "D",
      },
      { label: "ฉันชอบที่จะไปในที่ใหม่ๆ ที่ไม่เคยไปเสมอ", value: "E" },
      { label: "ฉันเป็นคนเชื่อมโยงคนต่างๆในกลุ่มเสมอ", value: "F" },
      { label: "ฉันชอบอยู่เงียบๆใช้สมาธิของฉันเต็มที่", value: "R" },
      { label: "ฉันชอบหาอะไรที่ไม่เคยทำ เพื่อสร้างสิ่งใหม่ๆ ", value: "I" },
    ],
  },
  {
    question: 4,
    choice: [
      {
        label: "ฉันเป็นคนเรียบร้อย มีระเบียบแบบแผน ปฎิบัติตามกฏเสมอ",
        value: "P",
      },
      {
        label: "ฉันเป็นคนมีพลัง กระตือรือร้น พร้อมเปิดรับสิ่งใหม่",
        value: "E",
      },
      {
        label: "ฉันเป็นคนละเอียด ชอบทดลองทำสิ่งต่างๆและดูผลที่เปลี่ยนแปลง",
        value: "R",
      },
      {
        label: "ฉันเป็นคนฟังผู้อื่น และลงมือทำเพื่อให้ผู้อื่นสบายใจเสมอ",
        value: "D",
      },
      { label: "ฉันเป็นคนที่ชอบแนะนำ โน้มน้าวใจผู้อื่นเสมอ", value: "F" },
      {
        label: "ฉันเป็นคนเชื่อมั่นในตนเอง และชอบกระตุ้นพัฒนาตนเองอยู่เสมอ",
        value: "I",
      },
    ],
  },
  {
    question: 5,
    choice: [
      {
        label:
          "ฉันค้นหาสิ่งต่างๆที่เป็นเทรนด์ในอนาคตเสมอ และอยากจะเป็นผู้นำการเปลี่ยนแปลงก่อนผู้อื่น",
        value: "E",
      },
      {
        label:
          "ฉันค้นหาสิ่งต่างๆโดยลงลึกในรายละเอียดเพื่อเอามาทดลองในงานของฉัน",
        value: "R",
      },
      {
        label: "ฉันค้นหาแนวทางในการพัฒนา กระตุ้น และเชื่อมโยงผู้คน",
        value: "F",
      },
      {
        label:
          "ฉันค้นหาแนวทางในการพัฒนางานโดยใช้หลักการ ความรู้ เครื่องมือใหม่ๆ",
        value: "I",
      },
      {
        label:
          "ฉันค้นหาสิ่งต่างๆเพื่อจัดทำกลยุทธ์ที่ดี เพื่อทำให้เกิดผลลัพธ์ที่ดี",
        value: "P",
      },
      {
        label: "ฉันค้นหาสิ่งต่างๆเพื่อทำหน้าที่ตามที่ได้รับมอบหมายให้ดีที่สุด",
        value: "D",
      },
    ],
  },
  {
    question: 6,
    choice: [
      {
        label: "ฉันสามารถทำงานในงานใหม่ๆ สถานที่ใหม่ๆ ความท้าทายใหม่ๆได้",
        value: "E",
      },
      {
        label: "ฉันสามารถทำงานที่ต้องใช้เวลานานกว่าจะเห็นผลลัพธ์ได้ ",
        value: "R",
      },
      {
        label: "ฉันสามารถทำงานตามคำสั่งขององค์กรหรือหัวหน้าได้เสมอ",
        value: "D",
      },
      {
        label: "ฉันสามารถทำงานที่ต้องประสานงานและกระตุ้นให้กับผู้คนเสมอ",
        value: "F",
      },
      {
        label: "ฉันสามารถทำงานยากๆที่ทำให้องค์กรและตัวฉันพัฒนาก้าวหน้าเสมอ",
        value: "I",
      },
      {
        label: "ฉันสามารถทำงานที่ต้องใช้ความคิด ความละเอียด และ ข้อมูลมากๆ ",
        value: "P",
      },
    ],
  },
  {
    question: 7,
    choice: [
      {
        label: "ฉันมองล่วงหน้าและคิดหาหนทางเพื่อไปยังผลลัพธ์ที่ดีเสมอ",
        value: "P",
      },
      {
        label: "ฉันมองรายละเอียดและคิดต่อยอดเพื่อให้เกิดสิ่งใหม่เสมอ",
        value: "R",
      },
      {
        label: "ฉันมักจะทำสิ่งที่อยู่ตรงหน้าในปัจจุบันให้ดีที่สุดเสมอ",
        value: "D",
      },
      { label: "ฉันคิดถึงผู้คน และคิดถึงกระบวนการร่วมมือเสมอ", value: "F" },
      { label: "ฉันมองความเป็นไปได้ที่จะทำให้สิ่งต่างๆดีขึ้นสมอ", value: "I" },
      { label: "ฉันมองและจินตนาการไปยังอนาคตที่ดีขึ้นเสมอ", value: "E" },
    ],
  },
  {
    question: 8,
    choice: [
      {
        label: "ฉันเป็นคนจินตนาการสูง มีพลัง ในการทำสิ่งท้าทายใหม่ๆเสมอ",
        value: "E",
      },
      { label: "ฉันเป็นนักวิชาการ ชอบรายละเอียด และการทดลอง", value: "R" },
      {
        label: "ฉันเป็นคนที่ชอบทำงานร่วมกับผู้คนเพื่อพัฒนาสิ่งต่างๆให้ดีขึ้น",
        value: "F",
      },
      {
        label:
          "ฉันเป็นนักพัฒนามองหาจุดปรับปรุงสิ่งต่างๆรอบตัวเพื่อให้เกิดสิ่งที่ดีขึ้น",
        value: "I",
      },
      { label: "ฉันเป็นนักคิด นักกลยุทธ์ จอมวางแผน", value: "P" },
      {
        label:
          "ฉันเป็นคนลงมือทำที่ดีขอให้บอกเป้าหมายมาให้ชัดฉันจะลงมือทำให้สำเร็จ",
        value: "D",
      },
    ],
  },
];

const DISC2 = () => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [answer, setAnswer] = useState([]);

  const handlePreviousPage = () => {
    if (page - 1 < 0) return;
    setPage(page - 1);
  };

  const handleNextPage = async () => {
    if (page + 1 < quizs.length) {
      setPage(page + 1);
    } else {
      const result = calculateType(answer);
      console.log(result);
      history.push(
        `/personality-character-test/DISC2/result-test-DISC2?result=${result}`
      );

      await addEmployeePersonalityTest({
        idPersonalityTest: 2,
        result: result
      });
    }
  };

  const handleSetAnswer = (selectedAnswer) => {
    const updateAnswer = [...answer];
    updateAnswer[page] = selectedAnswer;
    setAnswer(updateAnswer);
  };

  const calculateType = (answers) => {
    const groupAnswer = {};
    answers.forEach((answer) => {
      if (!groupAnswer[answer]) {
        groupAnswer[answer] = 0;
      }
      groupAnswer[answer] += 1;
    });

    //find max
    let max = -Infinity;
    Object.keys(groupAnswer).forEach((answerKey) => {
      if (groupAnswer[answerKey] > max) max = groupAnswer[answerKey];
    });

    const maximumAnswer = [];
    Object.keys(groupAnswer).forEach((answerKey) => {
      if (groupAnswer[answerKey] === max) maximumAnswer.push(answerKey);
    });

    return maximumAnswer[getRandomIndex(maximumAnswer)];
  };

  function getRandomIndex(array) {
    return Math.floor(Math.random() * array.length);
  }

  useEffect(() => {
    console.log(answer);
  }, [answer]);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to={"/personality-character-test"}
          >
            All
          </Link>
          <Typography color="text.primary">Business Character</Typography>
        </Breadcrumbs>
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            Business Character
          </Typography>
        </div>

        <StyledPaper
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/DISC2/BG-Test2-DISC.png)`,
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <div style={{ position: "relative", marginTop: "220px" }}>
              <Grid item>
                <Box sx={{
                  position: 'absolute',
                  top: -30,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  zIndex: 1
                }}>
                  <Box sx={{ bgcolor: "#DB4178", width: 200, p: 3, borderRadius: 3 }}>
                    <Typography
                      fontSize={17}
                      fontWeight={600}
                      color={"#FFFFFF"}
                      textAlign={"center"}
                    >
                      Question {page + 1}/{quizs.length}
                    </Typography>
                  </Box>
                </Box>
                <Paper
                  style={{
                    minHeight: "auto",
                    maxWidth: "auto",
                    padding: 20,
                    marginLeft: "5vw",
                    marginRight: "5vw",
                  }}
                >
                  <StyledQuestion>
                    <div className="header-question">
                      <Typography className="questionText">
                        โปรดเลือกข้อความที่ใกล้เคียงกับลักษณะนิสัยของคุณมากที่สุด
                      </Typography>
                    </div>
                  </StyledQuestion>
                </Paper>
              </Grid>

              <StyledQuestion>
                <Grid
                  item
                  style={{ position: "relative", marginTop: "10px" }}
                  className="content-question"
                >
                  <Grid container spacing={2}>
                    {quizs[page].choice.map((choice) => (
                      <Grid item xs={12} sm={6} key={choice.value}>
                        <Paper
                          onClick={() => handleSetAnswer(choice.value)}
                          style={{
                            minHeight: "80px",
                            padding: "10px",
                            border: `2px solid ${answer[page] === choice.value
                              ? "#DB4178"
                              : "transparent"
                              }`,
                            cursor: "pointer",
                          }}
                        >
                          <Typography className="answerText">
                            {choice.label}
                          </Typography>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </StyledQuestion>

              <Grid item>
                <StyleButton>
                  <Button
                    disabled={page === 0}
                    onClick={handlePreviousPage}
                    variant="contained"
                  >
                    Previous
                  </Button>
                  {page < quizs.length - 1 ? (
                    <Button
                      disabled={answer[page] === undefined}
                      onClick={handleNextPage}
                      variant="contained"
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      disabled={answer[page] === undefined}
                      onClick={handleNextPage}
                      variant="contained"
                    >
                      Finish
                    </Button>
                  )}
                </StyleButton>
              </Grid>
            </div>
          </Grid>
        </StyledPaper>
      </Container>
    </StyledRoot>
  );
};

export default DISC2;
