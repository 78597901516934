import React from "react";
import PropTypes from "prop-types";
import { Box, styled, Tab, Tabs } from "@mui/material";

const StyledRoot = styled(Box)(({centered}) => ({
  display: "flex",
  justifyContent: Boolean(centered)? "center": "left",
  width: "100%",
  "& .Mui-selected": {
    color: "#ffffff !important",
    backgroundColor: "#46cbe2",
  },
  "& .MuiTabs-flexContainer": {
    // justifyContent: "center",
  }
}))

const HorizontalMenu = (props) => {

  const { value, onChange, menu, centered } = props;

  return (
    <StyledRoot
      centered={Number(centered)}
    >
      <Tabs
        value={value}
        onChange={onChange}
        variant="scrollable"
        allowScrollButtonsMobile
        TabIndicatorProps={{
          style: {display: 'none'}
        }}
        // centered={true}
        visibleScrollbar={true}
      >
        {menu.map(((m, index) => (
          <Tab
            key={index}
            icon={m.icon}
            iconPosition={m.iconPosition || "start"}
            label={m.label || ""}
          />
        )))}
      </Tabs>
    </StyledRoot>
  )
}

HorizontalMenu.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  menu: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.node.isRequired,
    iconPosition: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  centered: PropTypes.bool,
  // scrollable: PropTypes.bool
}

HorizontalMenu.defaultProps = {
  centered: false,
}

export default HorizontalMenu;