import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  Grid,
  Box,
  Typography,
  MenuItem,
  ListItem,
  Autocomplete,
  IconButton,
  Fab,
  Snackbar,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from '@mui/styles';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

import { useTranslation } from "react-i18next";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

const validationSchema = Yup.object().shape({
  list: Yup.array().of(
    Yup.object().shape({
      idEmployees: Yup.number().required('Missing: employee is required'),
      role: Yup.string().required('Missing: role is required'),
    })
  ).min(1)
});

const useStyles = makeStyles((theme) => ({
  errorText: {
    color: "#d32f2f",
    fontWeight: 600,
  },
}));

const StyledRoot = styled(Box)({
  width: 350,
  height: "100%",
  padding: 24,
  "& .wrap-status": {
    marginTop: 4,
    display: "flex",
    alignItems: "center",
    "& .MuiBox-root": {
      marginRight: 8,
    },
  },
});
const StyledDivider = styled(Box)({
  width: "98%",
  marginTop: "30px",
  marginBottom: "30px",
  height: '1px',
  backgroundColor: '#e0e0e0',
});
const StyleBoxList = styled(Box)({
  maxHeight: "650px",
  overflow: "auto",
});

const HeaderContainer = styled(Box)({
  textAlign: "left",
  overflowWrap: "anywhere",
  "& .title": {
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    fontWeight: "600",
  },
  "& .titleDesc": {
    color: "#9e9e9e",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
});
const StyleAutocomplete = styled(Autocomplete)({
  "& .MuiInputBase-root": {
    padding: "13.5px 14px",
    "& .MuiInputBase-input": {
      padding: "0px !important",
    },
  }
});
const ContainerSubmitButton = styled(Box)({
  margin: "0px 24px 10px",
  position: "fixed",
  bottom: "0px",
  right: "0px",
  backgroundColor: "#fff",
});

const roleOptions = ["Boss", "Peer", "Project", "Subordinate", "Customer", "Cross Function"];

function AddEvaluatorDialog(props) {
  const { t } = useTranslation();
  const {
    open,
    onSubmit,
    onClose,
    selectedEmployee,
  } = props;
  const { isFetching: employeesIsFetching, result: employeesList } = useSelector((state) => state.employees);

  const classes = useStyles();
  const [idEmployeeSelected, setIdEmployeeSelected] = useState(new Set())
  const [selected, setSelected] = useState({ idEmployees: null, Role: null })
  const [errorStatus, setErrorStatus] = useState(false);

  const { control, handleSubmit, formState: { errors }, getValues } = useForm({
    defaultValues: {
      list: [],
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    fields: EmployeeFields,
    append: EmployeeAppend,
    remove: EmployeeRemove
  } = useFieldArray({ control, name: "list" });


  const formsubmit = (values) => {
    onSubmit(values)
  };

  const addEvaluator = () => {
    if (selected && selected.idEmployees && selected.Role) {
      // const isEvaluatorAlreadyAdded = EmployeeFields.some(field => 
      //   field.idEmployees === selectedEvaluator.idEmployees && field.role === selectedRole
      // );
      // if (!isEvaluatorAlreadyAdded) {
      //   EmployeeAppend({
      //     idEmployees: selectedEvaluator.idEmployees,
      //     fullName: `${selectedEvaluator.firstname_TH} ${selectedEvaluator.lastname_TH}`,
      //     positionName: selectedEvaluator.positionName,
      //     role: selectedRole
      //   });
      //   setSelectedEvaluator(null);
      //   setSelectedRole(null);
      // } else {
      //   // setErrorStatus("ผู้ประเมินนี้ได้ถูกเพิ่มแล้ว");
      // }
      let EmpData = selected.idEmployees
      EmployeeAppend({
        idEmployees: EmpData.idEmployees,
        fullName: `${EmpData.firstname_TH} ${EmpData.lastname_TH}`,
        positionName: EmpData.positionName,
        role: selected.Role
      });
      setSelected({ idEmployees: null, Role: null })
      let ConArray = Array.from(idEmployeeSelected)
      let newSet = new Set(ConArray)
      setIdEmployeeSelected(newSet.add(EmpData.idEmployees))
    } else {
      // setErrorStatus("กรุณาเลือกผู้ประเมินและ role");
      setErrorStatus(true);
    }
  };

  const removeEmployee = (index) => {
    let empObj = EmployeeFields[index] || null

    if (empObj) {
      let array = Array.from(idEmployeeSelected)
      setIdEmployeeSelected(new Set(array.filter((idEmp) => idEmp !== empObj.idEmployees)))
      EmployeeRemove(index);
    }
  };

  useEffect(() => {
    if (selectedEmployee && selectedEmployee instanceof Set) {
      setIdEmployeeSelected(selectedEmployee)
    }
  }, [])

  return (
    <DrawerCustom
      title={'เพิ่มผู้ประเมิน'}
      anchor={"right"}
      open={open}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(formsubmit)}>
        <StyledRoot>
          <Grid container spacing={2} justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={12} sm={12}>
              <StyleAutocomplete
                options={employeesList ? employeesList : []}
                value={selected.idEmployees}
                onChange={(_, newValue) => {
                  setSelected(prev => ({ ...prev, "idEmployees": newValue }));
                  setErrorStatus(false)
                }}
                getOptionLabel={(option) => `${option.firstname_TH} ${option.lastname_TH}`}
                renderOption={(props, option) =>
                  <MenuItem {...props} key={option.idEmployees} disabled={idEmployeeSelected.has(option.idEmployees)}>
                    <Box>
                      <Typography variant="subtitle1">{`${option.firstname_TH} ${option.lastname_TH}`}</Typography>
                      <Typography variant="subtitle2" style={{ color: "#00000080" }}>{`Position: ${option.positionName}`}</Typography>
                    </Box>
                  </MenuItem>
                }
                disableClearable
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    label="เลือกผู้ประเมิน"
                    placeholder="เลือกผู้ประเมิน"
                    variant="outlined"
                    helperText={errorStatus && selected && selected.idEmployees === null ? 'กรุณาเลือกผู้ประเมิน' : null}
                    error={errorStatus && selected && selected.idEmployees === null ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              <StyleAutocomplete
                options={roleOptions}
                value={selected.Role}
                onChange={(_, newValue) => {
                  setSelected(prev => ({ ...prev, "Role": newValue }));
                  setErrorStatus(false)
                }}
                getOptionLabel={(option) => option}
                renderOption={(props, option) =>
                  <MenuItem {...props} key={option}>
                    {option}
                  </MenuItem>
                }
                disableClearable
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    label="เลือก role"
                    placeholder="เลือก role"
                    variant="outlined"
                    helperText={errorStatus && selected && selected.Role === null ? 'กรุณาเลือก Role' : null}
                    error={errorStatus && selected && selected.Role === null ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Fab
                size="small"
                style={{ backgroundColor: "#46cbe2", color: "#fff" }}
                onClick={addEvaluator}
              >
                <AddRoundedIcon />
              </Fab>
            </Grid>
          </Grid>
          <StyledDivider />
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <Typography variant="body1">รายชื่อที่เพิ่ม</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" style={{ color: "#db4178" }}>{EmployeeFields.length} รายการ</Typography>
            </Grid>
          </Grid>
          <StyleBoxList style={{ height: "610px", overflowY: "auto" }}>
            {EmployeeFields && EmployeeFields.length > 0 ? (
              EmployeeFields.map((row, index) => (
                <ListItem key={index} style={{ display: "flex", justifyContent: "space-between" }}>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <PersonRoundedIcon style={{ marginRight: "10px" }} />
                    <Box>
                      <HeaderContainer>
                        <Typography variant="subtitle1" className="title">
                          {`${row.fullName} (${row.positionName ? row.positionName : "-"})`}
                        </Typography>
                      </HeaderContainer>
                      <HeaderContainer>
                        <Typography variant="subtitle1" className="title" style={{ color: "#00000080", fontWeight: "normal" }}>
                          Role: {row.role}
                        </Typography>
                      </HeaderContainer>
                    </Box>
                  </Box>
                  {EmployeeFields.length > 1 &&
                    <IconButton
                      aria-label="delete"
                      onClick={() => removeEmployee(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                </ListItem>
              ))
            ) : (
              <ListItem style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                <Typography variant="subtitle1" className={errors.list && classes.errorText}>ไม่มีข้อมูล</Typography>
              </ListItem>
            )}
          </StyleBoxList>
        </StyledRoot>
        <ContainerSubmitButton>
          <ButtonBlue
            variant="text"
            color="secondary"
            radius="style2"
            onClick={onClose}
            style={{ marginRight: "10px" }}
          >
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue
            type="submit"
            variant="contained"
            color="secondary"
            radius="style2"
          >
            {t("Save")}
          </ButtonBlue>
        </ContainerSubmitButton>
      </form>
    </DrawerCustom>
  );
}

export default AddEvaluatorDialog;
