import React, { useState, useEffect } from "react";
import {
  Card,
  Box,
  Typography,
  Avatar,
  IconButton,
  Tooltip,
  Chip,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import DialogEditObjective from "./Information/editObjective";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(1, 0),
  boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
}));

const IconButtonStyled = styled(IconButton)({
  color: "#DB4178",
  border: "2px solid #DB4178",
  backgroundColor: "transparent",
  borderRadius: "7px",
  "&:hover": {
    color: "#C53B6C",
    border: "2px solid #C53B6C",
    backgroundColor: "transparent",
  },
});

const ObjectiveCard = ({ data, refreshObjectives }) => {
  const { t } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const isAdmin = userProfile && userProfile.roles.includes("ROLE_ADMIN");

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenEditDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDelete = () => {
    console.log("Delete objective:", data.idOrganizationObjective);
  };

  const isSuccess = data.isSuccess;
  const statusText = isSuccess === 1 ? t("ObjectiveSuccess") : t("InProgress");
  const statusColor = isSuccess === 1 ? "success" : "warning";

  let earliestStartDate = null;
  let latestEndDate = null;

  if (data.keyResults && data.keyResults.length > 0) {
    const keyResultsWithDates = data.keyResults.filter(
      (kr) => kr.startDate && kr.endDate
    );

    keyResultsWithDates.forEach((kr) => {
      const krStartDate = dayjs(kr.startDate);
      const krEndDate = dayjs(kr.endDate);

      if (krStartDate.isValid()) {
        if (!earliestStartDate || krStartDate.isBefore(earliestStartDate)) {
          earliestStartDate = krStartDate;
        }
      }

      if (krEndDate.isValid()) {
        if (!latestEndDate || krEndDate.isAfter(latestEndDate)) {
          latestEndDate = krEndDate;
        }
      }
    });
  }

  if (!earliestStartDate && data.startDate) {
    const objStartDate = dayjs(data.startDate);
    if (objStartDate.isValid()) {
      earliestStartDate = objStartDate;
    }
  }

  if (!latestEndDate && data.endDate) {
    const objEndDate = dayjs(data.endDate);
    if (objEndDate.isValid()) {
      latestEndDate = objEndDate;
    }
  }

  const startDateFormatted = earliestStartDate
    ? earliestStartDate.format("DD/MM/YYYY")
    : "-";
  const endDateFormatted = latestEndDate
    ? latestEndDate.format("DD/MM/YYYY")
    : "-";

  return (
    <StyledCard>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={2} md={1}>
          <Box display="flex" justifyContent="center">
            <Avatar
              style={{
                backgroundColor: "#F8D7DA",
                color: "#DB4178",
                width: 80,
                height: 80,
                fontSize: "14px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="caption"
                style={{ lineHeight: 1, fontSize: "14px", color: "#DB4178" }}
              >
                {t("Quarter")}
              </Typography>
              <Typography
                variant="h5"
                style={{
                  lineHeight: 1,
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: "#DB4178",
                }}
              >
                {data.quarter}
              </Typography>
            </Avatar>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            {data.objectiveName}
          </Typography>

          {data.companyName && (
            <Typography variant="body2" color="textSecondary">
              {t("Company")}: {data.companyName}
            </Typography>
          )}

          <Typography variant="body2" style={{ marginTop: "8px" }}>
            {t("NumberKeyResults")}: <strong>{data.keyResults.length}</strong>{" "}
            {t("Unit.Items")}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Box>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {t("Date")}
            </Typography>
            <Typography variant="body2">
              {startDateFormatted} - {endDateFormatted}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <Chip
            label={statusText}
            color={statusColor}
            variant="outlined"
            style={{ fontWeight: "bold" }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <Box display="flex" justifyContent="flex-end" gap={1}>
            <Tooltip title={t("ViewMore")}>
              <IconButtonStyled
                component={Link}
                to={`/okrs/organization-planning/information/${data.idOrganizationObjective}`}
              >
                <SearchIcon fontSize="small" />
              </IconButtonStyled>
            </Tooltip>

            {isAdmin && (
              <Tooltip title={t("EditObjective")}>
                <IconButtonStyled onClick={handleOpenEditDialog}>
                  <EditIcon fontSize="small" />
                </IconButtonStyled>
              </Tooltip>
            )}

            {isAdmin && (
              <Tooltip title={t("DeleteObjective")}>
                <IconButtonStyled onClick={handleDelete}>
                  <DeleteIcon fontSize="small" />
                </IconButtonStyled>
              </Tooltip>
            )}
          </Box>
        </Grid>
      </Grid>

      {openDialog && (
        <DialogEditObjective
          open={openDialog}
          handleClose={handleCloseDialog}
          data={{
            objectiveName: data.objectiveName,
            impactName: data.impactName,
          }}
          refreshObjectives={refreshObjectives}
        />
      )}
    </StyledCard>
  );
};

export default ObjectiveCard;
