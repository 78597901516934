import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Container,
  MenuItem,
  Typography,
  styled,
  Button,
} from "@mui/material";
import CardStyle from "../../../../../shared/general/Card";
import { AccessTimeRounded } from "@mui/icons-material";
import TextFieldTheme from "../../../../../shared/general/TextFieldTheme";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {
  addOrganizationKeyResultProgress,
  getAllOrganizationKeyResultProgress,
  updateOrganizationKeyResult,
} from "../../../../../../../actions/okrs";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({});

const ResponsiveButtonBlue = styled(Button)(({ theme }) => ({
  backgroundColor: "rgba(219, 65, 120, 1)",
  width: "10%",
  height: "50px",
  fontSize: "15px",
  fontWeight: "bold",
  borderRadius: "7px",
  "&:hover": {
    backgroundColor: "#C53B6C",
  },
  [theme.breakpoints.down("sm")]: {
    width: "10%",
    fontSize: "14px",
    height: "40px",
  },
}));

const UpdateProgressOrganizationKeyResult = (props) => {
  const { match } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [OrganizationKeyResult, setOrganizationKeyResult] = useState(null);

  const useHookForm = useForm({
    defaultValues: {
      message: "",
    },
  });

  const getValueStatus = (status) => {
    switch (status) {
      case null:
        return "";
      case 1:
        return "1";
      case 0:
        return "0";
    }
  };

  const onSubmit = async (formData) => {
    const response = await dispatch(
      addOrganizationKeyResultProgress(
        match.params.idOrganizationKeyResult,
        formData
      )
    );

    if (response) {
      if (response.status === 200) {
        setOrganizationKeyResult((prev) => ({
          ...prev,
          organizationKeyResultProgress: [
            {
              idOrganizationKeyResultProgress:
                response.data.idOrganizationKeyResultProgress,
              message: response.data.message,
              createDate: response.data.createDate,
            },
            ...prev.organizationKeyResultProgress,
          ],
        }));
        useHookForm.setValue("message", "");
      }
    }
  };

  const onChangeStatus = async (status) => {
    let formData = {
      isAchieve: status,
      achieveDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    };

    if (formData.isAchieve === "") {
      formData.isAchieve = null;
    } else {
      formData.isAchieve = Number(formData.isAchieve);
    }

    const response = await dispatch(
      updateOrganizationKeyResult(
        match.params.idOrganizationKeyResult,
        formData
      )
    );

    if (response.status === 200) {
      setOrganizationKeyResult((prev) => ({
        ...prev,
        isAchieve: formData.isAchieve,
        achieveDate: formData.achieveDate,
      }));
    }
  };

  useEffect(() => {
    let isMounted = true;
    dispatch(
      getAllOrganizationKeyResultProgress(match.params.idOrganizationKeyResult)
    ).then((res) => {
      if (isMounted) {
        if (res.status === 200) {
          setOrganizationKeyResult(res.data);
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <StyledRoot className="page">
      <Box padding="24px 0">
        <Container maxWidth="lg">
          {OrganizationKeyResult && (
            <Fragment>
              <CardStyle>
                <Box padding="24px">
                  <Box display="flex" alignItems="flex-start">
                    <Typography flexGrow="1" fontSize="18px" fontWeight="500">
                      {OrganizationKeyResult.keyResultName}
                    </Typography>

                    {/* <ButtonBlue variant="contained">เปลี่ยนสถานะ</ButtonBlue> */}
                    <TextFieldTheme
                      value={getValueStatus(OrganizationKeyResult.isAchieve)}
                      select
                      onChange={(event) => {
                        onChangeStatus(event.target.value);
                      }}
                      SelectProps={{
                        displayEmpty: true,
                      }}
                      style={{
                        maxWidth: "200px",
                      }}
                    >
                      <MenuItem value="1">{`${t("AchievePlan")}`}</MenuItem>
                      <MenuItem value="0">{`${t("NotAchievePlan")}`}</MenuItem>
                    </TextFieldTheme>
                    {/* <Box display="flex" alignItems="center">
                    <Typography fontSize="14px">อัพเดทสถานะ</Typography>
                    <ChevronRightRounded style={{marginLeft: "8px", fontSize: "22px"}}/>
                  </Box> */}
                  </Box>
                  <Box display="flex" alignItems="center" marginTop="8px">
                    <AccessTimeRounded
                      style={{ marginRight: "8px", fontSize: "16px" }}
                    />
                    <Typography fontSize="14px" lineHeight="1">
                      {dayjs(OrganizationKeyResult.startDate).format(
                        "D MMM BBBB"
                      )}{" "}
                      -{" "}
                      {dayjs(OrganizationKeyResult.endDate).format(
                        "D MMM BBBB"
                      )}
                    </Typography>
                  </Box>
                </Box>
              </CardStyle>

              <Box marginTop="24px">
                <CardStyle>
                  <Box padding="24px">
                    <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
                      <Controller
                        name="message"
                        control={useHookForm.control}
                        render={({ field }) => (
                          <TextFieldTheme
                            {...field}
                            maxRows={5}
                            multiline
                            inputProps={{
                              placeholder: `${t("WriteMessage")}`,
                            }}
                          />
                        )}
                      />
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        marginTop="16px"
                      >
                        <ResponsiveButtonBlue
                          variant="contained"
                          type="submit"
                          disabled={useHookForm.formState.isSubmitting}
                        >{`${t("Update")}`}</ResponsiveButtonBlue>
                      </Box>
                    </form>
                  </Box>
                </CardStyle>
              </Box>

              <Box marginTop="24px">
                {OrganizationKeyResult.organizationKeyResultProgress.map(
                  (progress, index) => (
                    <KeyResultProgress key={index} data={progress} />
                  )
                )}
              </Box>
            </Fragment>
          )}
        </Container>
      </Box>
    </StyledRoot>
  );
};

const StyledKeyResultProgressRoot = styled(Box)({
  marginBottom: "16px",
  "&:last-child": {
    marginBottom: 0,
  },
});

const KeyResultProgress = (props) => {
  const { data } = props;

  return (
    <StyledKeyResultProgressRoot>
      <CardStyle>
        <Box padding="24px">
          <Typography whiteSpace="pre-line">{data.message}</Typography>
          <Box display="flex" justifyContent="flex-end">
            <Typography fontSize="12px" color="text.third">
              {dayjs(data.createDate).format("D MMM BBBB HH.mm")}
            </Typography>
          </Box>
        </Box>
      </CardStyle>
    </StyledKeyResultProgressRoot>
  );
};

export default UpdateProgressOrganizationKeyResult;
