import React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import StatusRequest from "../../shared/general/stausRequest";
import dayjs from "dayjs";
import { Box } from "@mui/material";

const MenuStatus = ({ row, status, handleChagneStatus, conditionDate }) => {
  console.log(row)
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectStatus = (row, status) => {
    handleChagneStatus(row, status);
    handleClose();
  };

  return (
    <div>
      {dayjs(conditionDate).isBefore(dayjs().subtract(15, 'day'), 'day') ? (
        <Box padding={1}>
          <StatusRequest
            boxSize={40}
            fontSize={22}
            borderRadius={8}
            status={status}
          />
        </Box>
      ) : (
        <>
          <IconButton
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <StatusRequest
              boxSize={40}
              fontSize={22}
              borderRadius={8}
              status={status}
            />
          </IconButton>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem onClick={() => handleSelectStatus(row, 1)}>
              {t("Approved")}
            </MenuItem>
            <MenuItem onClick={() => handleSelectStatus(row, 0)}>
              {t("NotApproved")}
            </MenuItem>
          </Menu>
        </>
      )}
    </div>
  );
};

export default MenuStatus;
