import { httpClient } from "./httpClient";

const forgetPassword = (formData) => {
  return httpClient.post(`/forget-password`, formData);
};

const verifyResetPassword = (query) => {
  return httpClient.get(`/reset-password/verify`, {params: query});
};

const resetPassword = (formData) => {
  return httpClient.post(`/reset-password`, formData);
};

export default {
  forgetPassword,
  verifyResetPassword,
  resetPassword
};