import React, { useEffect, useState, Fragment } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import * as yup from "yup";

import { styled } from "@mui/material/styles";

import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import DatePickerCustom from "../../../../shared/date/datePicker";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import DrawerCustom from "../../../../shared/general/Drawer";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import ButtonLoadingBlue from "../../../../shared/general/ButtonLoadingBlue";

import { uploadAddOn } from "../../../../../../actions/payruns";
import { allPayTypes } from "../../../../../../actions/paytypes";
import { Typography } from "@mui/material";
import UploadFile from "./upload-file";
import FileSelected from "./file-selected";

const StyledContent = styled("div")({
  width: 450,
  padding: 24,
  "& .textField": {
    marginBottom: 16,
    width: "100%",
  },
  "& .radio": {
    width: "100%",
  },
  "& .MuiFormControl-root": {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  "& .wrap-upload": {
    display: "flex",
    justifyContent: "space-between",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledFormLabel = styled(FormLabel)({
  fontWeight: 600,
  fontSize: 14,
  color: "#637381",
  marginBottom: 8,
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginBottom: 8,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const DialogNew = (props) => {
  const { open, handleClose, isReadOnly } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );
  const [fileSelected, setFileSelected] = useState({
    finger: null,
    additionDeduction: null,
  });
  const [formFileSelected, setFormFileSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [companyList, setCompanyList] = useState(null);

  const validationSchema = yup.object({
    payRunTypes: yup.string().required(`โปรดกรอกข้อมูล`).nullable(),
    payperiod: yup.string().required(`โปรดกรอกข้อมูล`).nullable(),
    idPaymentType: yup.string().required(`โปรดกรอกข้อมูล`).nullable(),
    paymentRound: yup.string().required(`โปรดกรอกข้อมูล`).nullable(),
  });

  const formik = useFormik({
    initialValues: {
      payRunTypes: "normal",
      payperiod: new Date(),
      idPaymentType: "0",
      paymentRound: "month",
      payrunName: `${dayjs(new Date()).format("MMMM BBBB")}`,
      idCompany: null,
      company: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    dispatch(allPayTypes());
  }, []);

  useEffect(() => {
    if (affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      let temp = affiliateOrganizationList.map((x) => ({
        idCompany: x.idCompany,
        companyName: x.companyName,
        companyName_EN: x.companyName_EN,
      }));

      formik.setFieldValue("idCompany", temp[0].idCompany);
      formik.setFieldValue("company", temp[0]);
      setCompanyList(temp);
    }
  }, [affiliateOrganizationList]);

  const handleSubmit = async (values) => {
    console.log(values);
    setLoading(true);
    if (formFileSelected) {
      const result = await dispatch(uploadAddOn(formFileSelected));
      if (result && result.idPayrun > 0) {
        history.push(`/payroll/run/` + result.idPayrun);
      }
    } else {
      alert("โปรดแนบไฟล์");
    }
  };

  const handleSetFileSelected = (mode, value) => {
    setFileSelected({ ...fileSelected, [mode]: value });
  };

  const handleSetFormFileSelected = (mode, value) => {
    setFormFileSelected({ ...formFileSelected, [mode]: value });
  };

  return (
    <DrawerCustom
      open={open}
      onClose={handleClose}
      title={`${t("SalaryImport")}`}
      anchor={"right"}
    >
      <StyledContent>
        <form onSubmit={formik.handleSubmit}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              {t("Company")}
            </Typography>
            <StyledAutocomplete
              options={affiliateOrganizationList ? companyList : []}
              onChange={(event, newValue) => {
                console.log("newValue Company:", newValue);
                formik.setFieldValue("idCompany", newValue.idCompany);
                formik.setFieldValue("company", newValue);
              }}
              value={formik.values.company}
              disableClearable={true}
              popupIcon={<i class="fa-light fa-chevron-down"></i>}
              getOptionLabel={(option) =>
                i18n.language === "th"
                  ? option.companyName
                  : option.companyName_EN
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={`${t("AllCompany")}`}
                />
              )}
              PopperComponent={StyledPopper}
              noOptionsText={`${t("NoData")}`}
            />
          </StyledBoxSearch>
          <StyledFormLabel component="legend">
            {t("PayrunName")}
          </StyledFormLabel>
          <TextFieldTheme
            style={{ marginBottom: 8 }}
            name="payrunName"
            value={formik.values.payrunName}
            onChange={formik.handleChange}
          />

          <FormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.idPaymentType &&
              Boolean(formik.errors.idPaymentType)
            }
          >
            <FormLabel component="legend">{t("Payment_Type")}</FormLabel>
            <RadioGroup
              row
              aria-label="idPaymentType"
              onChange={formik.handleChange}
              name="idPaymentType"
              value={formik.values.idPaymentType}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={`${t("Daily")}`}
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label={`${t("Monthly")}`}
              />
              <FormControlLabel
                value="0"
                control={<Radio />}
                label={`${t("DailyAndMonthly")}`}
              />
            </RadioGroup>
            {Boolean(formik.errors.idPaymentType) && (
              <FormHelperText>{formik.errors.idPaymentType}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.payRunTypes && Boolean(formik.errors.payRunTypes)
            }
          >
            <FormLabel component="legend">{t("PayrunType")}</FormLabel>
            <RadioGroup
              row
              aria-label="payRunTypes"
              onChange={formik.handleChange}
              name="payRunTypes"
              value={formik.values.payRunTypes}
            >
              <FormControlLabel
                value="normal"
                control={<Radio />}
                label={t("Normal")}
              />
              <FormControlLabel
                value="off"
                control={<Radio />}
                label="Off-Cycle"
              />
            </RadioGroup>
            {Boolean(formik.errors.payRunTypes) && (
              <FormHelperText>{formik.errors.payRunTypes}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.payRunTypes && Boolean(formik.errors.payRunTypes)
            }
          >
            <FormLabel component="legend">{t("PaymentRound")}</FormLabel>
            <RadioGroup
              row
              aria-label="paymentRound"
              onChange={formik.handleChange}
              name="paymentRound"
              value={formik.values.paymentRound}
            >
              <FormControlLabel
                value="week"
                control={<Radio />}
                label={`${t("Semi_Monthly")}`}
              />
              <FormControlLabel
                value="month"
                control={<Radio />}
                label={`${t("Monthly")}`}
              />
            </RadioGroup>
            {Boolean(formik.errors.paymentRound) && (
              <FormHelperText>{formik.errors.paymentRound}</FormHelperText>
            )}
          </FormControl>

          <DatePickerCustom
            inputFormat="MMMM YYYY"
            views={["year", "month"]}
            value={formik.values.payperiod}
            onChange={(date) => {
              formik.setFieldValue("payperiod", date);
            }}
            name="payperiod"
            error={formik.touched.payperiod && Boolean(formik.errors.payperiod)}
            helperText={formik.touched.payperiod && formik.errors.payperiod}
            renderInput={(params) => (
              <TextFieldTheme fullWidth {...params} className={"textField"} />
            )}
          />
          <div>
            <div className="wrap-upload">
              <Typography>{t("PleaseAttachFile")}</Typography>
            </div>

            {fileSelected.additionDeduction ? (
              <FileSelected
                fileSelected={fileSelected.additionDeduction}
                mode="additionDeduction"
                handleSetFileSelected={handleSetFileSelected}
              />
            ) : (
              <UploadFile
                mode="additionDeduction"
                handleSetFileSelected={handleSetFileSelected}
                setFormFileSelected={setFormFileSelected}
                values={formik.values}
              />
            )}
          </div>
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleClose}>
              {t("Cancel")}
            </ButtonBlue>
            {/* <LoadingButton loading={loading} type="submit" variant="contained">
              สร้างรายการ
            </LoadingButton> */}
            <ButtonLoadingBlue
              loading={loading}
              type="submit"
              variant="contained"
              disabled={isReadOnly}
            >
              {t("SalaryImport")}
            </ButtonLoadingBlue>
          </StyledFooter>
        </form>
      </StyledContent>
    </DrawerCustom>
  );
};

export default DialogNew;
