import React, { useState } from "react";
import {
    Box,
    Grid,
    IconButton,
    Typography,
    styled
} from "@mui/material";
import { Link } from "react-router-dom";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import CardStyle from "../../shared/general/Card";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DialogAddJobProfile from "./DialogAddJobProfile"; 

const StyledRoot = styled("div")({
    backgroundColor: "#FFFFFF !important",
});

const useStyles = makeStyles(() => ({
    root: {
        marginTop: "20px",
        minHeight: "calc(100vh - 20px)",
    },
    sendIcon: {
        marginBottom: 5,
        marginRight: 5,
        color: "black",
    },
    textNumber: {
        fontSize: 30,
        fontWeight: "bold",
        marginLeft: 20,
        color: "#007AFC",
    },
    mainData: {
        fontSize: "1rem",
        fontWeight: 600,
        color: "#007AFC",
    },
    data: {
        fontSize: "1rem",
    },
    headText: {
        fontSize: "1rem",
        color: "#9e9e9e",
    },
    viewBtn: {
        color: "#bdbdbd",
        "&:hover": {
            color: "#007AFC",
        },
    },
    removeBtn: {
        color: "#bdbdbd",
        "&:hover": {
            color: "#e53935",
        },
    },
    link: {
        textDecoration: "none",
    },
}));

const PositionCard = ({
    data,
    selectedCompany,
    setDrawerConfig,
    setOpenAlert,
    setOpenAlertError
}) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [openJobProfile, setOpenJobProfile] = useState(false);
    const [selectedPositionId, setSelectedPositionId] = useState(null);
    const [nextPage, setNextPage] = useState(1);
    const [addedData, setAddedData] = useState({});
    const [jobClass, setJobClass] = useState(null);

    const handleOpenAddJobProfile = (id, data) => {
        setJobClass(data);
        setOpenJobProfile(true);
        setSelectedPositionId(id)
    };

    const handleCloseJobProfile = async () => {
        setOpenJobProfile(false);
        setSelectedPositionId(null);
        setNextPage(1);
        setAddedData({});
        setJobClass(null);
    };

    return (
        <StyledRoot className={`page`}>
            {data &&
                data.map((item, index) => (
                    <CardStyle style={{ padding: "24px", marginBottom: "20px" }} key={`body-${index}`}>
                        <Grid container style={{ alignItems: "center" }}>
                            <Grid container spacing={2}>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Grid container justifyContent={"space-between"}>
                                        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                                            <Typography className={classes.headText}>
                                                {t("JobPosition")}
                                            </Typography>
                                            <Typography className={classes.mainData}>
                                                {i18n.language === "th" ? item.positionName : item.positionName_EN}
                                            </Typography>
                                        </Grid>
                                        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                                            <Typography className={classes.headText}>
                                                {t("positionCode")}
                                            </Typography>
                                            <Typography className={classes.mainData}>
                                                {item.positionCode}
                                            </Typography>
                                        </Grid>
                                        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                                            <Typography className={classes.headText}>
                                                {t("JobGroup")}
                                            </Typography>
                                            <Typography className={classes.mainData}>
                                                {item.jobGroupName || "-"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                                            <Typography className={classes.headText}>
                                                {t("Quantity")}
                                            </Typography>
                                            <Typography className={classes.mainData}>
                                                {item.numberOfEmp || "-"}
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            xl={1}
                                            lg={1}
                                            md={1}
                                            sm={6}
                                            xs={12}
                                            style={{ textAlign: "right" }}
                                        >
                                            <Link
                                                to={{
                                                    pathname: `/admin/showAllPosition/showDetail`,
                                                    state: { item }
                                                }}
                                                style={{
                                                    textDecoration: "none",
                                                    width: "200px",
                                                    height: "300px",
                                                }}
                                            >
                                                <IconButton>
                                                    <SearchIcon className={classes.viewBtn} />
                                                </IconButton>
                                            </Link>

                                            {item && item.jobProfile ? (
                                                <IconButton
                                                    aria-label="add"
                                                    onClick={() => {
                                                        setDrawerConfig((prev) => ({
                                                            ...prev,
                                                            isOpen: true,
                                                            isEdit: true,
                                                            data: {
                                                                idPosition: item.jobProfile.idPosition,
                                                                overall_qualification: item.jobProfile.overall_qualification,
                                                                require_experience: item.jobProfile.require_experience,
                                                                special_requirement: item.jobProfile.special_requirement,
                                                                description: item.jobProfile.description,
                                                                min_salary: item.jobProfile.min_salary,
                                                                max_salary: item.jobProfile.max_salary,
                                                                midpoint_salary: item.jobProfile.midpoint_salary,
                                                                je_score: item.jobProfile.je_score,
                                                                minimum_Education: item.jobProfile.minimum_Education,
                                                                faculty: item.jobProfile.faculty,
                                                                idJobLevelMax: item.jobProfile.idJobLevelMax,
                                                                idJobLevelMin: item.jobProfile.idJobLevelMin,
                                                                jobLevel: item.jobLevel,
                                                                jobImg: item.jobProfile.jobImg,
                                                                imgName: item.jobProfile.imgName
                                                            },
                                                        }));
                                                    }}
                                                >
                                                    <EditNoteRoundedIcon fontSize="inherit" />
                                                </IconButton>
                                            ) : (
                                                <IconButton
                                                    aria-label="add"
                                                    onClick={() => {
                                                        handleOpenAddJobProfile(item.idPosition, item.jobLevel)
                                                    }}
                                                >
                                                    <AddCircleOutlineIcon fontSize="inherit" />
                                                </IconButton>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardStyle>
                ))}

            {(!data || data.length <= 0) && (
                <Box
                    height="120px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography fontSize="20px" color="text.secondary">
                        {t("NoResult")}
                    </Typography>
                </Box>
            )}

            <DialogAddJobProfile
                openJobProfile={openJobProfile}
                setOpenJobProfile={setOpenJobProfile}
                handleCloseJobProfile={handleCloseJobProfile}
                selectedPositionId={selectedPositionId}
                nextPage={nextPage}
                setNextPage={setNextPage}
                addedData={addedData}
                setAddedData={setAddedData}
                jobClass={jobClass}
                setOpenAlert={setOpenAlert}
                setOpenAlertError={setOpenAlertError}
            />
        </StyledRoot>
    );
};

export default PositionCard;
