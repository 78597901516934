import React, { Fragment, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { styled } from "@mui/material/styles";

import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./devextreme/devextreme.css";
import { BrowserRouter as Router } from "react-router-dom";
import {
  Header,
  AdminMenu,
  ManagerMenu,
  UserMenu,
  AdminVendorMenu,
} from "./components/layouts";

import Routers from "./Routers";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Divider from "@mui/material/Divider";
import { Avatar, Typography } from "@mui/material";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import Notification from "./components/pages/shared/general/Notification";
import dayjs from "dayjs";
import "dayjs/locale/lo";
// import "dayjs/locale/en";
// import "dayjs/locale/th";
// import "dayjs/locale/ko";
import { useTranslation } from "react-i18next";
import { getUserFullName, getUserPosition } from "./utils/userData";
import { getStandardMenuCompany } from "./actions/company";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale
);

const useStyles = makeStyles(() => ({
  "@global": {
    "*::-webkit-scrollbar": {
      weight: "4px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
}));

const drawerUseStyles = makeStyles((theme) => ({
  drawerOpen: {
    width: drawerWidth,
  },
  drawerClose: {
    overflowX: "hidden",
    width: 56,
  },
  bannerOpen: {
    backgroundImage:
      // "url(" +
      // process.env.PUBLIC_URL +
      // "/images/background_menu.jpg" +
      // ")"
      `url(${process.env.PUBLIC_URL}/images/background_menu.jpg)`,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  isActive: {
    backgroundColor: "#e0f5fd",
    color: "#0080ff",
  },
}));

const drawerWidth = 280;

const StyledToolbar = styled("div")(({}) => ({
  paddingTop: 36,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& .textName": {
    fontSize: 16,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const StyledAvatarMenuBar = styled(Avatar)(({}) => ({
  width: 48,
  height: 48,
  marginRight: 8,
}));

const StyledDivider = styled(Divider)({
  marginTop: 24,
  marginBottom: 24,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab",
  borderStyle: "dashed",
  width: "100%",
});

const StyledBoxProfileMenuBar = styled(Box)(({}) => ({
  backgroundColor: "#f2f3f5",
  borderRadius: 20,
  padding: "16px 12px",
  display: "flex",
  alignItems: "center",
  width: 230,
}));

const DrawerContainer = ({ open, matchesBig, handleDrawerOpen }) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: standardMenuCompany } = useSelector(
    (state) => state.standardMenu
  );
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isUser, setIsUser] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userProfile && userProfile.roles) {
      setIsUser(userProfile.roles.includes("ROLE_USER"));
      setIsAdmin(userProfile.roles.includes("ROLE_ADMIN"));
      setIsManager(
        userProfile.roles.includes("ROLE_MANAGER") ||
          userProfile.roles.includes("ROLE_USER_MANAGER")
      );
      dispatch(getStandardMenuCompany());
    }
  }, [userProfile, matchesBig]);

  const classes = drawerUseStyles();
  return (
    <Drawer
      variant={matchesBig ? "persistent" : "temporary"}
      anchor="left"
      open={open}
      onClose={handleDrawerOpen}
      className={clsx({
        [classes.drawerOpen]: open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.bannerOpen]: open,
        }),
      }}
    >
      <StyledToolbar>
        <StyledBoxProfileMenuBar>
          {userProfile && (
            <Fragment>
              <StyledAvatarMenuBar
                alt={userProfile.employeeID}
                src={`${userProfile.imageProfile}`}
              />
              <div style={{ width: 175 }}>
                <Typography className={`textName`} variant="h6">
                  {getUserFullName(userProfile)}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {getUserPosition(userProfile)}
                </Typography>
              </div>
            </Fragment>
          )}
        </StyledBoxProfileMenuBar>
      </StyledToolbar>
      {isUser && (
        <Fragment>
          <UserMenu />
        </Fragment>
      )}
      {isUser && isManager && <StyledDivider />}
      {isManager && (
        <Fragment>
          <ManagerMenu />
        </Fragment>
      )}
      {isAdmin && isManager && <StyledDivider />}
      {isAdmin && (
        <Fragment>
          <AdminMenu />
        </Fragment>
      )}
    </Drawer>
  );
};

const HeaderAndDrawer = ({ isLoggedIn, matchesBig }) => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <Header
        handleDrawerOpen={handleDrawerOpen}
        matchesBig={matchesBig}
        open={open}
        isLoggedIn={isLoggedIn}
      />
      <DrawerContainer
        handleDrawerOpen={handleDrawerOpen}
        matchesBig={matchesBig}
        open={open}
      />
    </Fragment>
  );
};

function App() {
  useStyles();
  const matchesBig = useMediaQuery("(min-width:1200px)", { noSsr: true });
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { t, i18n } = useTranslation();
  dayjs.locale(i18n.resolvedLanguage);
  // const { result: notificationAlertConfig } = useSelector(state => state.notificationAlert);

  // useEffect(() => {
  //   setOpen(matchesBig);
  // }, [matchesBig]);

  return (
    <Router>
      <div
        className={`section`}
        style={{ display: "flex", backgroundColor: !isLoggedIn && "#FFFFFF" }}
      >
        {isLoggedIn && (
          <HeaderAndDrawer isLoggedIn={isLoggedIn} matchesBig={matchesBig} />
        )}

        <Routers />
      </div>
      <Notification/>
    </Router>
  );
}

export default App;
