import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Grid,
  Paper,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Container,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import TableRowsIcon from "@mui/icons-material/TableRows";
import RefreshIcon from "@mui/icons-material/Refresh";

import Chart from "@dabeng/react-orgchart";
import ChartNode from "./ChartNode";
import "./OrgChart.css";
import { createAPIEndpoint, ENDPOINTS } from "../../../../services/api";
import AddChart from "./AddChart";
import makeStyles from "@mui/styles/makeStyles";

import { ChartData } from "./OrgData";

import StyledCard from "../../shared/general/Card";

import ShowDivision from "../division/showDivision";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 16,
    "& .MuiSelect-outlined": {
      background: "#fff",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingTop: 16,
      paddingBottom: 8,
    },
  },
  addBtn: {
    backgroundColor: "#2f74eb",
    color: "#fff",
    fontWeight: 700,
    height: "40px",
    borderRadius: "25px",
    paddingLeft: 32,
    paddingRight: 32,
    "&:hover": {
      backgroundColor: "#2a68d3",
    },
  },
  container: {
    width: "100%",
    marginTop: 24,
    overflowX: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  loading_container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100px",
  },
  refreshBtn: {
    backgroundColor: "#2f74eb",
    color: "#fff",
    height: "100%",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#2a68d3",
    },
  },
  noData_section: {
    paddingTop: 16,
    paddingBottom: 16,
  },
}));

export default function OrganizationChart() {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isNoData, setIsNoData] = useState(false);
  const [chart, setChart] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [searchDepartment, setSearchDepartment] = useState("All Department");
  const [addModal, setAddModal] = useState({
    isOpen: false,
  });

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== "chart") {
      history.push("/admin/showDivision");
    }
  };

  const list_to_tree = (list) => {
    var map = {},
      node,
      roots = [],
      i;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parentId !== "0") {
        list[map[node.parentId]].children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  };

  const handleOnRefresh = () => {
    setIsLoading(true);
    setSearchDepartment("All Department");
    //fetchChart();
  };

  const fetchChart = async () => {
    await createAPIEndpoint(ENDPOINTS.ORGCHART)
      .fetchAll()
      .then((res) => {
        //Add children field in array objects
        res.data.forEach(function (element) {
          element.children = null;
        });

        //Filter ChartData
        let chartDataFilter = res.data.filter((item) => {
          return (
            item.department.includes(
              searchDepartment !== "All Department" ? searchDepartment : ""
            ) || item.parentId.includes("0")
          );
        });

        //Set Department Data
        var departmentGrouped = res.data.reduce((a, b) => {
          var i = a.findIndex((x) => x.department === b.department);
          return (
            i === -1
              ? a.push({ department: b.department, times: 1 })
              : a[i].times++,
            a
          );
        }, []);
        setDepartmentData(departmentGrouped);

        //Create OrgChart Array
        if (res.data.length > 0) {
          let chartData = list_to_tree(chartDataFilter);
          console.log(chartData[0]);
          //setChart(chartData[0]);
          //setIsNoData(false);
        } else {
          setIsNoData(true);
        }
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const handleNodeClick = (nodeData) => {
    //console.log(nodeData);
  };

  const handleOnDepartmentChange = (e) => {
    setSearchDepartment(e.target.value);
  };

  const handleOnAddNew = () => {
    setAddModal({
      isOpen: true,
    });
  };

  useEffect(() => {
    console.log(ChartData);

    ChartData.forEach(function (element) {
      element.children = null;
    });

    //Filter ChartData
    let chartDataFilter = ChartData.filter((item) => {
      return (
        item.department.includes(
          searchDepartment !== "All Department" ? searchDepartment : ""
        ) || item.parentId.includes("0")
      );
    });

    //Set Department Data
    var departmentGrouped = ChartData.reduce((a, b) => {
      var i = a.findIndex((x) => x.department === b.department);
      return (
        i === -1
          ? a.push({ department: b.department, times: 1 })
          : a[i].times++,
        a
      );
    }, []);
    setDepartmentData(departmentGrouped);

    //Create OrgChart Array
    if (ChartData.length > 0) {
      let chartData = list_to_tree(chartDataFilter);
      setChart(chartData[0]);
      setIsNoData(false);
    } else {
      setIsNoData(true);
    }
    setIsLoading(false);

    // let chartData = list_to_tree(ChartData);
    // console.log(chartData[0])
    // setChart(chartData[0]);
    // setIsNoData(false);
    // setIsLoading(false);
  }, [searchDepartment]);

  return (
    <div className={`page`} style={{ paddingTop: 100 }}>
      <Container maxWidth="lg">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 16,
            paddingRight: 36
          }}
        >
          <ToggleButtonGroup
            value={"chart"}
            exclusive
            size="small"
            onChange={handleAlignment}
            aria-label="text alignment"
          >
            <ToggleButton value="chart" aria-label="chart">
              <i className="fad fa-sitemap" style={{ fontSize: 20 }}></i>
            </ToggleButton>
            <ToggleButton value="list" aria-label="list">
              <TableRowsIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        <Fragment>
          <div style={{ marginBottom: 16 }}>
            <Grid container justify="space-between">
              <Grid item xs={12} md={6} lg={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={4}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel>Department</InputLabel>
                      <Select
                        label="All Department"
                        value={searchDepartment}
                        onChange={handleOnDepartmentChange}
                      >
                        <MenuItem value="All Department">
                          All Department
                        </MenuItem>
                        {departmentData.map((item) => (
                          <MenuItem key={item.id} value={item.department}>
                            {item.department}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      className={classes.refreshBtn}
                      onClick={handleOnRefresh}
                    >
                      <RefreshIcon />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Button
                      variant="contained"
                      className={classes.addBtn}
                      onClick={handleOnAddNew}
                    >
                      Add New
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <StyledCard>
            {isLoading ? (
              <div className={classes.loading_container}>
                <CircularProgress />
              </div>
            ) : !isNoData ? (
              <Chart
                zoom={true}
                pan={true}
                verticalDepth="2"
                datasource={chart}
                NodeTemplate={ChartNode}
                //NodeTemplate={(nodeData) => <ChartNode nodeData={nodeData.nodeData}/>}
                onClickNode={(nodeData) => handleNodeClick(nodeData)}
              />
            ) : (
              <div className={classes.noData_section}>
                <Grid container justify="center">
                  <Grid item>
                    <Typography variant="h6">No Data</Typography>
                  </Grid>
                </Grid>
              </div>
            )}
          </StyledCard>
        </Fragment>

        <AddChart
          addModal={addModal}
          setAddModal={setAddModal}
          chart={chart}
          handleOnRefresh={handleOnRefresh}
        />
      </Container>
    </div>
  );
}
