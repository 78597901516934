import { httpClient } from "./httpClient";


const addEmployeeDiligenceAccumulateCustom = (formData) => {
  return httpClient.post(`/employeeDiligenceAccumulateCustom`, formData);
};

const updateEmployeeDiligenceAccumulateCustomById = (idEmployeeDiligenceAccumulateCustom, formData) => {
  return httpClient.put(`/employeeDiligenceAccumulateCustom/${idEmployeeDiligenceAccumulateCustom}`, formData);
};

const deleteEmployeeDiligenceAccumulateCustomById = (idEmployeeDiligenceAccumulateCustom) => {
  return httpClient.delete(`/employeeDiligenceAccumulateCustom/${idEmployeeDiligenceAccumulateCustom}`);
};

export default {
  addEmployeeDiligenceAccumulateCustom,
  updateEmployeeDiligenceAccumulateCustomById,
  deleteEmployeeDiligenceAccumulateCustomById,
};
