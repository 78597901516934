import { httpClient } from "./httpClient";

const getBeneficiaryUser = () => {
    return httpClient.get(`benefitciary`);
};

const addBeneficiary = (formData) => {
    return httpClient.post(`beneficiary`,formData);
};

const updateBeneficiary = (formData, idBeneficiary) => {
    return httpClient.put(`beneficiary/update/${idBeneficiary}`,formData);
};

const deleteBeneficiary = (idBeneficiary) => {
    return httpClient.put(`beneficiary/delete/${idBeneficiary}`);
};

const getBeneficiaryEmployeeByIdEmployee = (idEmployee) => {
    return httpClient.get(`benefitciary/${idEmployee}`);
};

const addBeneficiaryByIdEmployee = (idEmployee, formData) => {
    return httpClient.post(`beneficiary/${idEmployee}`,formData);
};

export default {
    getBeneficiaryUser,
    addBeneficiary,
    updateBeneficiary,
    deleteBeneficiary,
    getBeneficiaryEmployeeByIdEmployee,
    addBeneficiaryByIdEmployee
}