import { httpClient } from "./httpClient";

const getAllProgressIdvPlan = () => {
  return httpClient.get("progressIdvPlan");
};

const postProgressIdvPlan = (data) => {
  return httpClient.post(`progressIdvPlan/postProgressIdvPlan`, data);
};

export default {
  getAllProgressIdvPlan,
  postProgressIdvPlan,
};
