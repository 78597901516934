import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  styled,
  Typography,
} from "@mui/material";

import DrawerCustom from "../../../../../shared/general/Drawer";
import TextFieldTheme from "../../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../../shared/general/ButtonBlue";
import {
  updateManpower,
  getManpowerById,
  UpdateManpowerJobGroups
} from "../../../../../../../actions/manpower";
import { getJobGroup } from '../../../../../../../actions/jobRecruit';
import { Delete } from "@mui/icons-material";

const BoxAddSkillButton = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "right",
  marginTop: 16,
  marginBottom: 16,
  "& .AddButton": {
    width: 30,
    height: 30,
    border: "2px solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
}));

const BoxInputSkill = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: 16,
  "& .TrashButton": {
    width: 30,
    height: 30,
    border: "2px solid",
    borderColor: "red",
    color: "red",
    marginLeft: 8,
  },
});

const DrawerJobGroup = (props) => {
  const { open, onClose, data, idManpower } = props;

  const { result: jobRecruitGroup } = useSelector((state) => state.jobRecruitGroup);

  const {
    handleSubmit,
    getValues,
    control,
    reset,
    formState: { errors, dirtyFields }
  } = useForm({
    defaultValues: {
      jobGroups: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "jobGroups",
  });

  // console.log(fields);
  // console.log('Dirty Fields:', dirtyFields);


  const dispatch = useDispatch();

  const HandlerSubmit = (submitData) => {
    // console.log(submitData);
    const formData = {};

    // console.log(submitData.jobGroups.length);
    for (const key in submitData) {
      formData[key] = submitData[key];
    }

    // console.log(formData);
    // console.log(idManpower,formData);

    dispatch(UpdateManpowerJobGroups(idManpower, formData)).then((res) => {
      dispatch(getManpowerById(idManpower));
    });

    onClose();
  };

  useEffect(() => {

    reset({
      jobGroups: data.jobGroups.length > 0 ? data.jobGroups : [{ idJobGroup: null, jobGroupName: '' }],
    })

  }, []);

  useEffect(() => {
    dispatch(getJobGroup());
  }, []);


  // console.log(fields);
  // console.log(fields[0])

  return (
    <DrawerCustom open={open} title={"แก้ไขกลุ่มงาน"} anchor="right">
      <Box style={{ width: 400, padding: 16 }}>
        <form onSubmit={handleSubmit(HandlerSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {fields && jobRecruitGroup &&
                fields.map((item, index) => (
                  <Grid container spacing={2} key={item.id} style={{ marginTop: "10px", alignItems: "center" }}>
                    <Grid item xs={10}>
                      <Controller
                        name={`jobGroups.${index}`}
                        control={control}
                        rules={{
                          required: { value: true, message: "กรุณาเลือกกลุ่มงาน" },
                          validate: value => {
                            if (!value || value.idJobGroup === null) {
                              return "กรุณาเลือกกลุ่มงานที่ถูกต้อง";
                            }
                            if (value || value.idJobGroup !== null) {
                              const values = getValues("jobGroups");
                              // console.log(values)
                              // console.log(value.idJobGroup)
                              const isDuplicate = values.some((v, i) => v.idJobGroup === value.idJobGroup && i !== index);
                              return isDuplicate === true ? "ห้ามเลือกกลุ่มงานซ้ำกัน" : true;
                            }
                          }

                        }}
                        render={({ field, formState }) => (
                          <Autocomplete
                            sx={{
                              "& .MuiInputBase-root": {
                                paddingLeft: 0,
                                paddingTop: 0,
                                paddingBottom: 0,
                                "& input": {
                                  padding: "13.5px !important"
                                }
                              }
                            }}
                            options={[...jobRecruitGroup].sort((a, b) => a.jobGroupName.localeCompare(b.jobGroupName))}
                            getOptionLabel={(option) => option.jobGroupName}
                            isOptionEqualToValue={(option, value) => option.idJobGroup === value.idJobGroup}
                            renderOption={(props, option) => (
                              <li {...props} key={option.idJobGroup}>
                                {option.jobGroupName}
                              </li>
                            )}
                            renderInput={(params) => (
                              <Fragment>
                                <TextFieldTheme
                                  placeholder="กลุ่มงาน"
                                  {...params}
                                  // error={formState.errors && formState.errors.jobGroups ? true : false}
                                  // helperText={formState.errors && formState.errors.jobGroups ? formState.errors.jobGroups.message : null}
                                  error={formState.errors && formState.errors.jobGroups && formState.errors.jobGroups[index] ? true : false}
                                  helperText={formState.errors && formState.errors.jobGroups && formState.errors.jobGroups[index] ? formState.errors.jobGroups[index].message : null}
                                />
                              </Fragment>
                            )}
                            noOptionsText="ไม่พบคำที่ค้นหา"
                            value={field.value}
                            onChange={(_, data) => {
                              // field.onChange(data);
                              field.onChange(data ? { idJobGroup: data.idJobGroup, jobGroupName: data.jobGroupName } : null);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        style={{
                          color: "#d32f2f",
                          alignItems: "flex-end",
                        }}
                        onClick={() => {
                          remove(index)
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              <BoxAddSkillButton>
                <IconButton
                  className="AddButton"
                  size="small"
                  onClick={() => append({
                    idJobGroup: null,
                    jobGroupName: '',
                  })}
                >
                  <i className="fa-solid fa-plus" />
                </IconButton>
              </BoxAddSkillButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ButtonBlue variant="outlined" onClick={onClose}>
                ยกเลิก
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
                disabled={Object.keys(dirtyFields).length <= 0}
              >
                ยืนยัน
              </ButtonBlue>
            </Box>
          </Grid>
        </form>
      </Box>
    </DrawerCustom>
  );
};
export default DrawerJobGroup;
/* <Grid item xs={12}>
  <Typography gutterBottom>กลุ่มงานของคุณ</Typography>
  {fields && jobRecruitGroup &&
    fields.map((item, index) => (
      <BoxInputSkill key={item.id}>
        <Controller
          control={control}
          // name={`skills.[${index}].skillName`}
          name={`jobGroups.${index}.jobGroup`}
          rules={{
            required: {
              value: true,
              message: "กรุณาเลือกกลุ่มงาน",
            },
          }}
          render={({ field, formState }) => (
            <Autocomplete
              sx={{
                "& .MuiInputBase-root": {
                  paddingLeft: 0,
                  paddingTop: 0,
                  paddingBottom: 0,
                  "& input": {
                    padding: "13.5px !important"
                  }
                }
              }}
              options={[...jobRecruitGroup].sort((a, b) => a.jobGroupName.localeCompare(b.jobGroupName))}
              getOptionLabel={(option) => option.jobGroupName}
              isOptionEqualToValue={(option, value) => option.idJobGroup === value.idJobGroup}
              renderOption={(props, option) => (
                <li {...props} key={option.idJobGroup}>
                  {option.jobGroupName}
                </li>
              )}
              renderInput={(params) => (
                <Fragment>
                  <Typography gutterBottom sx={{ color: "#ffffff" }}>กลุ่มงาน</Typography>
                  <TextFieldTheme
                    {...params}
                    placeholder="กลุ่มงาน"
                    error={Boolean(formState.errors?.jobGroups?.[index]?.jobGroup)}
                    helperText={formState.errors?.jobGroups?.[index]?.jobGroup?.message || null}
                  />
                </Fragment>
              )}
              noOptionsText="ไม่พบคำที่ค้นหา"
              value={field.value}
              onChange={(_, data) => {
                field.onChange(data);
              }}
            />
          )}
        />
        <IconButton
          style={{
            color: "#d32f2f",
          }}
          onClick={() => remove(index)}
        >
          <Delete />
        </IconButton>
      </BoxInputSkill>
    ))}
  <BoxAddSkillButton>
    <IconButton
      className="AddButton"
      size="small"
      onClick={() => append({ skillName: "" })}
    >
      <i className="fa-solid fa-plus" />
    </IconButton>
  </BoxAddSkillButton>
</Grid>
<Grid item xs={12}>
  <Box
    style={{
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    <ButtonBlue variant="outlined" onClick={onClose}>
      ยกเลิก
    </ButtonBlue>
    <ButtonBlue
      variant="contained"
      type="submit"
      disabled={Object.keys(dirtyFields).length <= 0}
    >
      ยืนยัน
    </ButtonBlue>
  </Box>
</Grid> */


// <DrawerCustom open={open} title={"แก้ไขกลุ่มงาน"} anchor="right">
//   {fields.map((field, index) => (
//     <Box key={field.id} display="flex" alignItems="center" mb={2}>
//       <Controller
//         name={`jobGroups.${index}.jobGroup`}
//         control={control}
//         render={({ field }) => (
//           <Select {...field} defaultValue="" style={{ minWidth: 200 }}>
//             {jobGroupOptions.map((option, idx) => (
//               <MenuItem key={idx} value={option}>
//                 {option}
//               </MenuItem>
//             ))}
//           </Select>
//         )}
//       />
//       <IconButton
//         style={{ color: "#d32f2f" }}
//         onClick={() => remove(index)}
//       >
//         <Delete />
//       </IconButton>
//     </Box>
//   ))}
//   <Box display="flex" justifyContent="flex-start" mt={2}>
//     <IconButton
//       onClick={() => append({ jobGroup: "" })}
//     >
//       <i className="fa-solid fa-plus" />
//     </IconButton>
//   </Box>
//   <Grid item xs={12}>
//     <Box
//       style={{
//         width: "100%",
//         display: "flex",
//         justifyContent: "space-between",
//       }}
//     >
//       <ButtonBlue variant="outlined" onClick={onClose}>
//         ยกเลิก
//       </ButtonBlue>
//       <ButtonBlue
//         variant="contained"
//         type="submit"
//       >
//         ยืนยัน
//       </ButtonBlue>
//     </Box>
//   </Grid>
// </DrawerCustom>

