import {
  WORKTIMEREQUEST_DETAIL_FETCHING,
  WORKTIMEREQUEST_DETAIL_FAILED,
  WORKTIMEREQUEST_DETAIL_SUCCESS,
} from "./types";

import WorkTimeRequestService from "../services/workTimeRequest.service";

export const getAllWorkTimeRequest = () => async (dispatch) => {
  try {
    const res = await WorkTimeRequestService.getAllWorkTimeRequest();
    if (res) {
      dispatch({
        type: WORKTIMEREQUEST_DETAIL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: WORKTIMEREQUEST_DETAIL_FAILED,
    });
    console.log(err);
  }
};
