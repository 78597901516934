import React, { useState, useEffect } from "react";
import {
  Grid,
  Avatar,
  Typography,
  Paper,
  Container,
  IconButton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector, useDispatch } from "react-redux";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import VerifiedIcon from "@mui/icons-material/Verified";
import StarIcon from "@mui/icons-material/Star";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import TodayIcon from "@mui/icons-material/Today";
import EventIcon from "@mui/icons-material/Event";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import { alpha } from "@mui/material";
import { Breadcrumb2 } from "../../shared/breadcrums";
import { getAllProject, getEmployeeProject } from "../../../../actions/project";
import { getAllWork } from "../../../../actions/work";
import CardStyle from "../../shared/general/Card";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { getUserFirstName, getUserLastName } from "../../../../utils/userData";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "20px",
    minHeight: "calc(100vh - 20px)",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  nameText: {
    fontSize: 29,
    fontWeight: "bold",
    marginBottom: 20,
  },
  colorMD: {
    borderTop: "5px solid green",
  },
  colorMG: {
    borderTop: "5px solid red",
  },
  colorSM: {
    borderTop: "5px solid blue",
  },
  bigAvatar: {
    width: 150,
    height: 150,
    margin: 20,
  },
  icon: {
    fontSize: 30,
  },
  borderIcon: {
    height: 50,
    padding: 10,
    textAlign: "center",
    borderRadius: "10%",
  },
  topic: {
    marginBottom: 10,
    fontSize: 21,
    fontWeight: "bold",
  },
  dataInTopic: {
    fontSize: 19,
    marginLeft: 10,
    fontWeight: "bold",
  },
}));

const PaperSowData = ({
  name,
  value,
  icon,
  backgroundColor,
  bgIconColor1,
  bgIconColor2,
}) => {
  const classes = useStyles();
  return (
    <Grid
      item
      xl={3}
      lg={3}
      md={6}
      sm={6}
      xs={12}
      style={{ textAlign: "center" }}
    >
      <div style={{ marginLeft: 30, marginRight: 30 }}>
        <IconButton disabled size="large" style={{ color: "#DB4178" }}>
          {icon}
        </IconButton>
        <Typography style={{ fontSize: 23, fontWeight: "bold", marginTop: 10 }}>
          {value}
        </Typography>
        <Typography style={{ fontSize: 15 }}>{name}</Typography>
      </div>
    </Grid>
  );
};

const ShowDetailProject = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const { idEmployeeProject } = useParams();
  const [projectData, setProjectData] = useState(null);
  const { result: dataProject } = useSelector((state) => state.dataProject);

  useEffect(() => {
    dispatch(getAllProject());
    dispatch(getAllWork());
  }, []);

  useEffect(() => {
    const fetchedData = async (idEmployeeProject) => {
      try {
        const response = await getEmployeeProject(idEmployeeProject);
        if (response && response.data) {
          setProjectData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchedData(idEmployeeProject)
  }, [idEmployeeProject])

  let role = "";

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom style={{ marginTop: 20 }}>
          {t("showProjectDetail")}
        </Typography>

        <div>
          <CardStyle
            className={[
              `${projectData && projectData.sizeMini == "MD" && classes.colorMD}`,
              `${projectData && projectData.sizeMini == "MG" && classes.colorMG}`,
              `${projectData && projectData.sizeMini == "SM" && classes.colorSM}`,
            ].join(" ")}
          >
            <div style={{ padding: 20 }}>
              <Typography
                className={classes.nameText}
                style={{ fontWeight: 400, textAlign: "center" }}
              >
                {projectData && projectData.ProjectName}
              </Typography>
              <CardStyle
                style={{
                  backgroundColor: "rgba(244, 245, 245, 1)",
                  padding: 20,
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <Grid container spacing={2.5}>
                  <PaperSowData
                    name={t("Type")}
                    value={projectData && projectData.Type}
                    icon={
                      <DynamicFeedIcon
                        className={classes.icon}
                        sx={{ color: "primary.darker" }}
                      />
                    }
                    backgroundColor="primary"
                    bgIconColor1="#b8a3ef"
                    bgIconColor2="#7b5dca"
                  />

                  <PaperSowData
                    name= {t("Impact")}
                    value={projectData && projectData.impact}
                    icon={
                      <StarIcon
                        className={classes.icon}
                        sx={{ color: "primary.darker" }}
                      />
                    }
                    backgroundColor="primary"
                    bgIconColor1="#4ac91d"
                  />

                  <PaperSowData
                    name={t("directReturn")}
                    value={projectData && projectData.directReturn}
                    icon={
                      <AssignmentReturnIcon
                        className={classes.icon}
                        sx={{ color: "error.darker" }}
                      />
                    }
                    backgroundColor="error"
                    bgIconColor1="#21b232"
                  />

                  <PaperSowData
                    name={t("return") +" ฿"}
                    value={projectData && projectData.return}
                    icon={
                      <AttachMoneyIcon
                        className={classes.icon}
                        sx={{ color: "error.darker" }}
                      />
                    }
                    backgroundColor="error"
                    bgIconColor1="#d94343"
                  />


                  <PaperSowData
                    name={t("Size")}
                    value={projectData && projectData.size}
                    icon={
                      <AspectRatioIcon
                        className={classes.icon}
                        sx={{ color: "success.darker" }}
                      />
                    }
                    backgroundColor="success"
                    bgIconColor1="#cd34c8"
                  />
                  <PaperSowData
                    name={t("Role")}
                    value={projectData && projectData.role}
                    icon={
                      <PeopleAltIcon
                        className={classes.icon}
                        sx={{ color: "success.darker" }}
                      />
                    }
                    backgroundColor="success"
                    bgIconColor1="#ed9c4c"
                  />
                  <PaperSowData
                    name={t("StartDate")}
                    value={projectData && dayjs(projectData.dateStart).format("YYYY-MM-DD")}
                    icon={
                      <TodayIcon
                        className={classes.icon}
                        sx={{ color: "warning.darker" }}
                      />
                    }
                    backgroundColor="warning"
                    bgIconColor1="#0867bc"
                  />
                  <PaperSowData
                    name={t("EndDate")}
                    value={projectData && dayjs(projectData.dateEnd).format("YYYY-MM-DD")}
                    icon={
                      <EventIcon
                        className={classes.icon}
                        sx={{ color: "warning.darker" }}
                      />
                    }
                    backgroundColor="warning"
                    bgIconColor1="#2b9cc5"
                  />

                </Grid>
              </CardStyle>

              <div style={{ padding: 10 }}>
                <Typography
                  style={{ marginTop: 30 }}
                  className={classes.topic}
                >
                  {t("Reason")} :{" "}
                </Typography>
                <Typography className={classes.dataInTopic}>
                  &nbsp;{projectData && projectData.reason}
                </Typography>
                <Typography
                  style={{ marginTop: 20 }}
                  className={classes.topic}
                >
                  {t("Note")} :
                </Typography>
                <Typography className={classes.dataInTopic}>
                  {projectData && projectData.note}
                </Typography>
              </div>
            </div>


            <div
              style={{
                textAlign: "-webkit-right",
                marginTop: 30,
                marginBottom: 20,
              }}
            >
              <div style={{ padding: 20, width: "300px" }}>
                <Typography
                  style={{
                    fontSize: 22,
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "#210c96",
                  }}
                >
                  <VerifiedIcon />
                  {t("verifyBy")}
                </Typography>
                <center>
                  <Avatar
                    alt="who verify"
                    src={`${process.env.REACT_APP_API_URL}image/profile/${projectData && projectData.who}`}
                    className={classes.bigAvatar}
                  />
                </center>
                <Typography
                  style={{
                    fontSize: 20,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {(projectData && getUserFirstName(projectData)) + " " + (projectData && getUserLastName(projectData))}
                </Typography>
                <Typography style={{ fontSize: 15, textAlign: "center" }}>
                  {projectData && dayjs(projectData.verifyDate).format("YYYY-MM-DD")}
                </Typography>
              </div>
            </div>
          </CardStyle>
        </div>
      </Container>
    </div >
  );
};

export default ShowDetailProject;
