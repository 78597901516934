import React from "react";
import { useSelector } from "react-redux";

import ManagerAttendance from "../manager/timeManagement";
import AdminAttendance from "../admin/timeManagement";

const TimeAttendancePage = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <div>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ? (
          <AdminAttendance />
        ) : (
          <ManagerAttendance />
        )
      ) : null}
    </div>
  );
};

export default TimeAttendancePage;
