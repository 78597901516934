import {
  COST_CENTER_LIST_FETCHING,
  COST_CENTER_LIST_FAILED,
  COST_CENTER_LIST_SUCCESS,
} from "./types";

import costCenterService from "../services/cost-center.service";

export const getCostCenterList = (query) => async (dispatch) => {
  try {
    dispatch({
      type: COST_CENTER_LIST_FETCHING,
    })
    
    const res = await costCenterService.getCostCenterList(query);
    if (res) {
      dispatch({
        type: COST_CENTER_LIST_SUCCESS,
        payload: res.data,
      });
    }
    return res
  } catch (error) {
    dispatch({
      type: COST_CENTER_LIST_FAILED,
    });
    console.error(error);
  }
};

export const addEmployeeCostCenter = (formData) => async () => {
  try {
    
    const res = await costCenterService.addEmployeeCostCenter(formData);

    return res
  } catch (error) {
    return error.response;
  }
};

export const updateEmployeeCostCenter = (idEmployeeCostCenter, formData) => async () => {
  try {
    
    const res = await costCenterService.updateEmployeeCostCenter(idEmployeeCostCenter, formData);

    return res
  } catch (error) {
    return error.response;
  }
};

export const deleteEmployeeCostCenter = (idEmployeeCostCenter) => async () => {
  try {
    
    const res = await costCenterService.deleteEmployeeCostCenter(idEmployeeCostCenter);

    return res
  } catch (error) {
    return error.response;
  }
};

export const addCostCenterByIdEmployees = async (query) => {
  try {
    const res = await costCenterService.addCostCenterByIdEmployees(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getCostCenterByEmployees = async (query) => {
  try {
    const res = await costCenterService.getCostCenterByEmployees(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};
