import { httpClient } from "./httpClient";

const addExperience = (formData) => {
  return httpClient.post(`experience`, formData);
};

const updateExperience = (idExperiences, formData) => {
  return httpClient.put(`experience/${idExperiences}`, formData);
};

const deleteExperience = (idExperiences) => {
  return httpClient.delete(`experience/${idExperiences}`);
}

export default {
  addExperience,
  updateExperience,
  deleteExperience
};