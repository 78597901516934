import { httpClient } from "./httpClient";

const getAllLogRecord = () => {
  return httpClient.get("logRecord");
};

const getAllDownloadPDF = () => {
  return httpClient.get("logDownloadPDF");
};

export default {
  getAllLogRecord,
  getAllDownloadPDF,
};
