import React from "react";
import { styled, Typography } from "@mui/material";
import CardStyle from "../../../shared/general/Card";

const StyledRoot = styled("div")({
  padding: 16,
  "& .part-card-header": {
    paddingBottom: 8,
    display: "flex",
    alignItems: "center",
    "& .title": {
      fontSize: 14,
      fontWeight: 500,
      // flexGrow: 1,
    },
    "& .part-monthly": {
      display: "flex",
      alignItems: "center",
      paddingLeft: 8,
      "& .icon": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 25,
        height: 25,
        borderRadius: "50%",
        backgroundColor: "#c8e6c9",
        flexShrink: 0,
        "& i": {
          fontSize: 12,
          fontWeight: 500,
          color: "#81c784",
        }
      },
      "& .monthly-value": {
        paddingLeft: 8,
        fontSize: 14,
      }
    },
  },
  "& .value": {
    fontSize: 38,
    fontWeight: 500
  }
})

const StyledStatCard = (props) => {
   
  const {title, monthlyValue, value, unit} = props;

  return (
    <CardStyle>
      <StyledRoot>
        <div className="part-card-header">
          <Typography className="title">{title || "-"}</Typography>
          {monthlyValue >= 0 && <div className="part-monthly">
            <div className="icon">
              <i className="fa-regular fa-arrow-trend-up"></i>
            </div>
            <span className="monthly-value">{monthlyValue || "-"}</span>
          </div>}
        </div>
        <Typography className="value">{value} {unit? <span style={{fontSize: 18}}>{unit}</span>: ""}</Typography>
      </StyledRoot>
    </CardStyle>
  )
}

export default StyledStatCard;