import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  IconButton,
  styled,
  Typography,
  useMediaQuery,
  Dialog,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import Search from "@mui/icons-material/Search";
import Swal from 'sweetalert2';
import ButtonBlue from "../../../shared/general/ButtonBlue";
import TableCustom from "../../../shared/tableCustom";
import DialogConfirmRejectKpi from "../DialogConfirmRejectKpi";
import CardRequest from "../../../manager/approveList/CardRequest";
import { getAllKpiApprove, updateKPIApprove } from "../../../../../actions/kpi";
import DoneAll from "@mui/icons-material/DoneAll";
import DeleteIcon from "@mui/icons-material/Delete";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { useTranslation } from "react-i18next";
import { getUserFullName, getUserPosition } from "../../../../../utils/userData";
import { t } from "i18next";

const ContainerStyled = styled(Container)({
  minHeight: "100vh",
  paddingTop: "100px",
});

const ContentWrapper = styled('div')({
  marginTop: '-20px',
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  borderRadius: '30px',
  overflow: 'hidden',
  border: '1px solid #EEEEEE',
  display: 'flex',
  justifyContent: 'center',
  width: 'fit-content',
}));

const StyledToggleButton = styled(ToggleButton)(({ theme, selectedColor }) => ({
  flex: 1,
  borderRadius: '30px',
  '&.Mui-selected': {
    backgroundColor: selectedColor,
    color: '#FFFFFF',
  },
  '&.MuiToggleButtonGroup-grouped': {
    border: 'none',
    '&:not(:first-of-type)': {
      borderLeft: '1px solid #EEEEEE',
    },
  },
  '&:hover': {
    backgroundColor: `${selectedColor} !important`,
    color: '#FFFFFF',
  },
  backgroundColor: '#EEEEEE',
  color: '#000000',
  textAlign: 'center',
  minWidth: '100px',
  maxWidth: '200px',
}));

const StyledButtonCancel = styled(ButtonBlue)({
  backgroundColor: "#E793B8",
  borderRadius: "8px",
  color: "#FFFFFF",
  marginRight: 8,
  width: 130,
  "&:hover": {
    backgroundColor: "#DC6384",
  },
});

const StyledRoot = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  padding: '24px 0',
});

const GridStyled = styled(Grid)({
  backgroundColor: "#E6EFF5",
  padding: "16px",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "16px",
  height: "70px",
});

const KpisIndividualPlanView = ({ kpiPlan, isOpen, onClose }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [approve, setApprove] = useState(1);
  const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);

  const handleApprovalRequest = async (approve, comment) => {
    const idKPI = kpiPlan.idKPI;
    const kpiData = {
      idKPI,
      isApprove: approve,
      commentKPI: approve === 0 ? comment : null,
    };

    await updateKPIApprove({ kpiData });

    Swal.fire({
      title: t("Success"),
      text: approve ? t("ApprovalSuccessful") : t("RejectionSuccessful"),
      icon: 'success',
      timer: 2000,
      showConfirmButton: false
    });

    dispatch(getAllKpiApprove());

    if (approve === 0) {
      setIsOpenRejectDialog(false);
    }
  };

  const showApprovalAlert = () => {
    Swal.fire({
      title: t("ConfirmApproval"),
      text: t("DoYouWantToApprove"),
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirm"),
      cancelButtonText: t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        handleApprovalRequest(1);
      }
    });
  };

  const handleSubmit = () => {
    if (approve === 0) {
      setIsOpenRejectDialog(true);
    } else {
      showApprovalAlert();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{ style: { padding: 0, backgroundColor: 'transparent', boxShadow: 'none' } }}
    >
      <div className="card-section" style={{ backgroundColor: '#fff', borderRadius: '28px' }}>
        <div className="header-section">
          <Grid container>
            <Grid item>
              <h3 className="header-topic">KPI Individual</h3>
            </Grid>
          </Grid>
        </div>
        <ContentWrapper>
          <Stack padding="16px" spacing={2}>
            <Typography marginBottom="8px">
              <span style={{ color: "#919eab", paddingRight: "8px", fontSize: "16px" }}>{t("KPIName")}</span>
              <span style={{ fontSize: "22px", fontWeight: "500", color: '#083A83' }}>{kpiPlan.kpiName}</span>
            </Typography>
            <Typography marginBottom="8px">
              <span style={{ color: "#919eab", paddingRight: "8px", fontSize: "16px" }}>{t("Scope")}</span>
              {kpiPlan.scope}
            </Typography>
            <Typography marginBottom="8px">
              <span style={{ color: "#919eab", paddingRight: "8px", fontSize: "16px" }}>{t("Target")}</span>
              {kpiPlan.target}
            </Typography>
            <Typography marginBottom="8px">
              <span style={{ color: "#919eab", paddingRight: "8px", fontSize: "16px" }}>{t("Evidence")}</span>
              {kpiPlan.evidence}
            </Typography>
            <Typography marginBottom="8px">
              <span style={{ color: "#919eab", paddingRight: "8px", fontSize: "16px" }}>{t("Descriptions")}</span>
              {kpiPlan.description}
            </Typography>
            <Typography marginBottom="8px">
              <span style={{ color: "#919eab", paddingRight: "8px", fontSize: "16px" }}>{t("Duration")}</span>
              {`${dayjs(kpiPlan.startDate).format(
                i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
              )} - ${dayjs(kpiPlan.endDate).format(
                i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
              )}`}
            </Typography>
            <Typography marginBottom="8px">
              <span style={{ color: "#919eab", paddingRight: "8px", fontSize: "16px" }}>{t("TypeMeasurement")}</span>
              {kpiPlan.measureType}
            </Typography>
            <Typography marginBottom="8px">
              <span style={{ color: "#919eab", paddingRight: "8px", fontSize: "16px" }}>{t("Weight")}</span>
              {kpiPlan.weight}
            </Typography>
            {/* {kpiPlan.isApprove !== null && (
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <StyledToggleButtonGroup
                  exclusive
                  value={approve}
                  onChange={(event, newValue) => setApprove(newValue)}
                >
                  <StyledToggleButton value={1} selectedColor="#50B498">
                    {t("Approve")}
                  </StyledToggleButton>
                  <StyledToggleButton value={0} selectedColor="#FF4C4C">
                    {t("Reject")}
                  </StyledToggleButton>
                </StyledToggleButtonGroup>
                <ButtonBlue variant="contained" onClick={handleSubmit}>
                  {t("Confirm")}
                </ButtonBlue>
              </div>
            )} */}
          </Stack>
        </ContentWrapper>
      </div>
      {/* <DialogConfirmRejectKpi
        open={isOpenRejectDialog}
        onClose={() => setIsOpenRejectDialog(false)}
        handleSubmit={(comment) => handleApprovalRequest(0, comment)}
      /> */}
    </Dialog>
  );
};

const KpiApprovalList = () => {
  const mobileResponsive = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const { result: kpiPlanStore } = useSelector((state) => state.kpiApproval);
  const [numberOfList, setNumberOfList] = useState([]);
  const [pendingRows, setPendingRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState(null);
  const [isOpenViewDialog, setIsOpenViewDialog] = useState(false);
  const [selectedKpiPlan, setSelectedKpiPlan] = useState(null);

  useEffect(() => {
    dispatch(getAllKpiApprove());
  }, [dispatch]);

  const columns = [
    {
      name: t("FullName"),
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography>{getUserFullName(row)}</Typography>
            <Typography color="text.third" fontSize="14px">{getUserPosition(row)}</Typography>
          </Box>
        </Box>
      )
    },
    {
      name: t("KPIName"),
      minWidth: "250px",
      width: "250px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography>{row.kpiName}</Typography>
            <Typography color="text.third" fontSize="14px">{getUserPosition(row)}</Typography>
          </Box>
        </Box>
      )
    },
    {
      name: t("Weight"),
      minWidth: "50px",
      width: "50px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography>{row.weight}</Typography>
            <Typography color="text.third" fontSize="14px">{getUserPosition(row)}</Typography>
          </Box>
        </Box>
      )
    },
    {
      name: t("StartDate"),
      minWidth: "80px",
      width: "80px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            {row.startDate ? dayjs(row.startDate).format('YYYY-MM-DD') : t("InvalidDate")}
            <Typography color="text.third" fontSize="14px">{getUserPosition(row)}</Typography>
          </Box>
        </Box>
      )
    },
    {
      name: t("EndDate"),
      minWidth: "80px",
      width: "80px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            {row.endDate ? dayjs(row.endDate).format('YYYY-MM-DD') : t("InvalidDate")}
            <Typography color="text.third" fontSize="14px">{getUserPosition(row)}</Typography>
          </Box>
        </Box>
      )
    },
    {
      name: "",
      minWidth: "10px",
      width: "10px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <IconButton
              onClick={() => {
                setSelectedKpiPlan(row);
                setIsOpenViewDialog(true);
              }}>
              <Search fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
      )
    },
  ]

  const handleApprovalRequest = async (approve, comment) => {
    setApprovalStatus(approve);
  
    const idKPIs = selectedRows.map(row => row.idKPI);
  
    const kpiData = idKPIs.map(idKPI => ({
      idKPI,
      isApprove: approve,
      commentKPI: approve === 0 ? comment : null,
    }));
  
    await updateKPIApprove({ kpiData });
  
    Swal.fire({
      title: t("Success"),
      text: approve ? t("ApprovalSuccessful") : t("RejectionSuccessful"),
      icon: 'success',
      timer: 2000,
      showConfirmButton: false
    });
    dispatch(getAllKpiApprove()); 
  
    setIsOpenRejectDialog(false);
  };
  

  const showApprovalAlert = () => {
    Swal.fire({
      title: t("ConfirmApproval"),
      text: `${t("DoYouWantToApprove")}? ${selectedRows.length > 0 ? `${selectedRows.length} ${t("List")}` : ""} ${t("confirmAsking")}`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirm"),
      cancelButtonText: t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        handleApprovalRequest(1);
      }
    });
  };

  const handleConfirmReject = (comment) => {
    handleApprovalRequest(0, comment)
  }

  const handleClickAll = () => {
    setSelectedRows(pendingRows);
  };

  const handleCancel = () => {
    setSelectedRows([]);
  };

  useEffect(() => {
    const tempPending = [];
    kpiPlanStore && kpiPlanStore.map((r) => {
      if (r.idRequestType === 3) {
        if (r.isApprove === null) {
          tempPending.push(r);
        }
      }
    });
    let pendingList = tempPending.sort(function (a, b) {
      return new Date(b.createDate) - new Date(a.createDate);
    });
    setPendingRows(pendingList);
    setNumberOfList(pendingList.length);
  }, [kpiPlanStore]);

  return (
    <StyledRoot className="page">
      <Container>
        <GridStyled container justifyContent="space-between" alignItems="center" mt={2}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" className="kpi" sx={{ ml: 1, fontSize: "30px" }}>
              {t("PendingKPIApproval")}
            </Typography>
          </Box>
        </GridStyled>
        <div className="cardPlan">
          <Box padding="2px 0">
            <Container maxWidth="lg">
              <Box padding="2px 0" minHeight="700px">
                <Box marginLeft={"auto"} marginRight={"auto"}>
                  {/* <Box paddingBottom="15px">
                    <Typography fontSize="24px">รออนุมัติ KPI</Typography>
                  </Box> */}
                  {mobileResponsive && (
                    <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
                      {(selectedRows ? selectedRows.length : 0) > 0 && (
                        <StyledButtonCancel
                          startIcon={<DeleteIcon />}
                          onClick={handleCancel}
                        >
                          {t("Cancel")}
                        </StyledButtonCancel>
                      )}
                      {!(
                        (selectedRows ? selectedRows.length : 0) === pendingRows.length
                      ) && (
                          <ButtonBlue
                            startIcon={<DoneAll />}
                            variant="contained"
                            onClick={handleClickAll}
                          >
                            {t("SelectAll")}
                          </ButtonBlue>
                        )}
                    </Box>
                  )}
                  {selectedRows.length > 0 && (
                    <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
                      <ButtonBlue
                        style={{ marginRight: 8 }}
                        startIcon={<DoneAll />}
                        variant="contained"
                        onClick={showApprovalAlert}
                      >
                        {`${t("Approved")} ${selectedRows.length > 0 ? `${selectedRows.length} ${t("List")}` : ""
                          }`}
                      </ButtonBlue>
                      <ButtonBlue
                        startIcon={<DoneAll />}
                        variant="outlined"
                        onClick={() => setIsOpenRejectDialog(true)}>
                        {`${t("NotApproved")} ${selectedRows.length > 0 ? `${selectedRows.length} ${t("List")}` : ""
                          }`}
                      </ButtonBlue>
                    </Box>
                  )}
                  {!mobileResponsive && kpiPlanStore ? (
                    <TableCustom
                      columns={columns}
                      rows={kpiPlanStore}
                      canSelect
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                    />
                  ) : (
                    <div>
                      {kpiPlanStore && kpiPlanStore.length > 0 ? (
                        pendingRows.map((row) => (
                          <CardRequest
                            row={row}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                          />
                        ))
                      ) : (
                        <div>
                          <Box height="120px" display="flex" justifyContent="center" alignItems="center">
                            <Typography variant="h6" >
                              <FindInPageIcon />
                              {t("NoData")}
                            </Typography>
                          </Box>
                        </div>
                      )}
                    </div>
                  )}
                  <DialogConfirmRejectKpi
                    rejectCount={selectedRows.length}
                    open={isOpenRejectDialog}
                    onClose={() => {
                      setIsOpenRejectDialog(false);
                    }}
                    handleSubmit={(comment) => {
                      handleConfirmReject(comment);
                    }}
                  />
                  {selectedKpiPlan && (
                    <KpisIndividualPlanView
                      kpiPlan={selectedKpiPlan}
                      isOpen={isOpenViewDialog}
                      onClose={() => setIsOpenViewDialog(false)}
                    />
                  )}
                </Box>
              </Box>
            </Container>
          </Box>
        </div>
      </Container>
    </StyledRoot>
  );
};

export default KpiApprovalList;
