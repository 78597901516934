import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTypography-body1": {
      color: "#757575",
    },
    "& .MuiTypography-body2": {
      color: "#16243D",
      fontSize: "1rem",
      marginLeft: theme.spacing(2),
    },
  },
  notation: {
    backgroundColor: "#fafafa",
    borderRadius: "10px",
    padding: "24px 16px 24px 16px",
  },
  sign_section: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
  },
}));

function TaxFooter(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { result: taxDeduction } = useSelector((state) => state.taxDeduction);

  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between" spacing={1}>
        <Grid item xs={12} md={4} lg={4}>
          <Typography variant="body1" display="inline">
          {`${t("EditorName")} : `}
          </Typography>
          <Typography variant="body2" display="inline">
            {(taxDeduction && taxDeduction.data && taxDeduction.data.updatedFirstname_TH && taxDeduction.data.updatedLastname_TH) ?
              `${taxDeduction.data.updatedTitle_TH || ""} ${taxDeduction.data.updatedFirstname_TH} ${taxDeduction.data.updatedLastname_TH}` : "-"
            }
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Typography variant="body1" display="inline">
            {`${t("LastModifiedDate")} : `}
          </Typography>
          <Typography variant="body2" display="inline">
            {(taxDeduction && taxDeduction.data && taxDeduction.data.updatedDate) ? dayjs(taxDeduction.data.updatedDate).format("DD MMMM YYYY") : "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Box className={classes.sign_section}>
            <Typography variant="body1" display="inline">
     
              {`${t("SignatoryInformation")} : `}
              
            </Typography>
            <Typography variant="body2" display="inline">
              {(taxDeduction && taxDeduction.data && taxDeduction.data.createdFirstname_TH && taxDeduction.data.createdLastname_TH) ?
                `${taxDeduction.data.createdTitle_TH || ""} ${taxDeduction.data.createdFirstname_TH} ${taxDeduction.data.createdLastname_TH}` : "-"
              }
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <Box className={classes.notation}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={2}>
          {`${t("Note")} : `} 
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <Typography variant="body1" gutterBottom>
              1. {`${t("RemarkTaxDeduction1")}`}
            </Typography>
            <Typography variant="body1" gutterBottom>
              2. {`${t("RemarkTaxDeduction2")}`}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default TaxFooter;
