import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DrawerCustom from "../../../../shared/general/Drawer";
import {
  Box,
  Grid,
  Typography,
  styled,
  Divider,
  FormControl,
  OutlinedInput,
  TextField,
  MenuItem,
} from "@mui/material";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import {
  getEmployeeProfile,
  getEmployeeSalaryChange,
  addDeleteEmployeeSalary,
  updateEmployeeSalaryChange,
} from "../../../../../../actions/employee";

import utils from "../../../../../../utils";
import { updateUser } from "../../../../../../actions/user";
import { getSalaryReasons } from "../../../../../../actions/salaryReasons";
import DatePickerCustom from "../../../../shared/date/datePicker";

const StyledRoot = styled(Box)({
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledDivider = styled(Divider)({
  // marginTop: 16,
  // marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledSalary = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const DialogEdit = (props) => {
  const dispatch = useDispatch();

  const {
    open,
    onClose,
    employeeSalaryChangeList,
    setEmployeeSalaryChangeList,
    drawerConfig,
  } = props;
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: salaryReasons } = useSelector((state) => state.salaryReasons);
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState(null);

  const [employeeFormData, setEmployeeFormData] = useState(null);

  useEffect(() => {
    dispatch(getSalaryReasons());
    if (employeeProfile && open) {
      if (!drawerConfig.isEdit) {
        setFormData({
          idEmployees: employeeProfile.idEmployees,
          idEmployeeSalaryOld: employeeProfile.idEmployeeSalary
            ? employeeProfile.idEmployeeSalary
            : null,
          salaryValue: null,
          salaryBilling: null,
          start: new Date(),
          remark: "",
          reason: "",
          idSalaryReason: "",
        });
      } else {
        setFormData({
          idEmployees: employeeProfile.idEmployees,
          salaryValue: drawerConfig.data.salaryValue || "",
          start: new Date(drawerConfig.data.start),
          remark: drawerConfig.data.remark || "",
          reason: drawerConfig.data.reason || "",
          idSalaryReason: drawerConfig.data.idSalaryReason || "",
        });
      }
      setEmployeeFormData({
        idEmployees: employeeProfile.idEmployees,
        idPaymentType: employeeProfile.idPaymentType,
      });
    }
  }, [open]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log({ name, value });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const reasons = {
    ปรับประจำปี: "AnnualAdjustment",
    ปรับโครงสร้าง: "Restructure",
    ปรับตำแหน่ง: "AdjustPosition",
    ปรับระดับงาน: "AdjustJobLevel",
    ปรับพิเศษ: "AdjustSpecial",
    ปรับด้วยเหตุผลอื่นๆ: "AdjustedOtherReasons",
  };

  const handleSubmit = async () => {
    let result = null;
    let employeeResult = null;
    let tempFormData = { ...formData };
    // let tempEmployeeFormData = {
    //   ...employeeFormData,
    //   idEmployees: employeeProfile.idEmployees,
    //   UpdateBy: userProfile.idEmployees,
    //   updateDate: dayjs().format("YYYY-MM-DD HH:mm:ss")
    // };
    tempFormData.start = dayjs(tempFormData.start).format("YYYY-MM-DD");

    Object.keys(tempFormData).map((k) => {
      if (tempFormData[k] === "") {
        tempFormData[k] = null;
      } else {
        tempFormData[k] = tempFormData[k];
      }
    });

    if (drawerConfig.isEdit) {
      tempFormData.updateDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
      result = await dispatch(
        updateEmployeeSalaryChange(
          employeeProfile.idEmployees,
          drawerConfig.data.idEmployeeSalary,
          tempFormData
        )
      );
    } else {
      tempFormData.method = "add";
      result = await dispatch(addDeleteEmployeeSalary(tempFormData));
    }

    // employeeResult = await dispatch(updateUser(tempEmployeeFormData))

    if (result) {
      onClose();
      dispatch(getEmployeeSalaryChange(employeeProfile.idEmployees));
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
    }
  };

  return (
    <DrawerCustom
      title={`${t("EditSalary")}`}
      anchor={"right"}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        {formData && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledContentLabel>{t("CurrentData")}</StyledContentLabel>
              <div className="wrap">
                <Typography className="present">
                  {employeeProfile.salary
                    ? utils.numberWithCommas(employeeProfile.salary)
                    : "-"}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <StyledDivider />
            </Grid>
            {/* <Grid item xs={12}>
              <StyledContentLabel>รูปแบบการจ่าย</StyledContentLabel>
              <TextFieldTheme
                name="idPaymentType"
                value={employeeFormData.idPaymentType}
                onChange={(e) => {
                  setEmployeeFormData(prev => ({
                    ...prev,
                    idPaymentType: e.target.value
                  }))
                }}
                fullWidth
                select
              >
                <MenuItem value="1">
                  <Box>
                    <Typography>รายวัน</Typography>
                    <Typography color="text.third" fontSize="12px">รอบจ่าย: รายครึ่งเดือน</Typography>
                  </Box>
                </MenuItem>
                <MenuItem value="2">
                  <Box>
                    <Typography>รายเดือน</Typography>
                    <Typography color="text.third" fontSize="12px">รอบจ่าย: รายเดือน</Typography>
                  </Box>
                </MenuItem>
              </TextFieldTheme>
            </Grid> */}
            <Grid item xs={12}>
              <StyledContentLabel>{t("Salary")}</StyledContentLabel>
              <TextFieldTheme
                name="salaryValue"
                value={formData.salaryValue}
                InputProps={{
                  inputComponent: NumberFormatTheme,
                }}
                inputProps={{
                  allowNegative: false,
                  onValueChange: (values) => {
                    const { value } = values;
                    console.log(value);
                    setFormData((prev) => ({
                      ...prev,
                      salaryValue: value,
                    }));
                  },
                  value: formData.salaryValue,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <StyledContentLabel>{t("EffectiveDate")}</StyledContentLabel>
              <DatePickerCustom
                value={formData.start}
                views={["year", "month", "day"]}
                openTo="year"
                inputFormat="DD/MM/YYYY"
                onChange={(date) => {
                  setFormData({
                    ...formData,
                    start: date,
                  });
                }}
                renderInput={(params) => (
                  <StyledTextField fullWidth {...params} />
                )}
                disabled={drawerConfig.isEdit}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <StyledContentLabel>{t("Description")}</StyledContentLabel>
              <TextFieldTheme
                name="reason"
                value={formData.reason}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    reason: event.target.value,
                  });
                }}
                select
              >
                <MenuItem value="ปรับประจำปี">{t("AnnualAdjustment")}</MenuItem>
                <MenuItem value="ปรับโครงสร้าง">{t("Restructure")}</MenuItem>
                <MenuItem value="ปรับตำแหน่ง">{t("AdjustPosition")}</MenuItem>
                <MenuItem value="ปรับระดับงาน">{t("AdjustJobLevel")}</MenuItem>
                <MenuItem value="ปรับพิเศษ">{t("AdjustSpecial")}</MenuItem>
                <MenuItem value="ปรับด้วยเหตุผลอื่นๆ">
                  {t("AdjustedOtherReasons")}
                </MenuItem>
              </TextFieldTheme>
            </Grid> */}
            <Grid item xs={12}>
              {salaryReasons && (
                <>
                  <StyledContentLabel>{t("Description")}</StyledContentLabel>
                  <TextFieldTheme
                    name="idSalaryReason"
                    value={
                      formData.idSalaryReason ? formData.idSalaryReason : null
                    }
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        idSalaryReason: event.target.value,
                      });
                    }}
                    select
                  >
                    {salaryReasons.map((reason) => (
                      <MenuItem value={reason.idSalaryReason}>
                        {t(`${reasons[reason.reason]}`)}
                      </MenuItem>
                    ))}
                  </TextFieldTheme>
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              <StyledContentLabel>{t("Note")}</StyledContentLabel>
              <TextFieldTheme
                name="remark"
                value={formData.remark}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    remark: event.target.value,
                  });
                }}
                multiline
                rows={4}
              />
            </Grid>

            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose}>
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue variant="contained" onClick={handleSubmit}>
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        )}
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
