import { httpClient } from "./httpClient";

const getAllProgressTeamPlan = () => {
  return httpClient.get("progressTeamPlan");
};

const postProgressTeamPlan = (data) => {
  return httpClient.post(`progressTeamPlan/postProgressTeamPlan`, data);
};

export default {
  getAllProgressTeamPlan,
  postProgressTeamPlan,
};
