import { httpClient } from "./httpClient";

const addEmailReport = (formData) => {
  return httpClient.post(`addEmailReport`, formData);
};

const getEmailReportById = (id,type) => {
    return httpClient.get(`getEmailReportById/${type}/${id}`);
  };

export default {
    addEmailReport,
    getEmailReportById
};