import React from "react";
import { useSelector } from "react-redux";

import UserCompanyDocument from "../user/company-document-dowload";
import AdminCompanyDocument from "../admin/company-documents";

const CompanyDocument = () => {
    const { result: userProfile } = useSelector((state) => state.userProfile);
    return (
        <div>
            {userProfile ? (
                userProfile.roles.includes("ROLE_ADMIN") ? (
                    <AdminCompanyDocument />
                ) : (
                    <UserCompanyDocument />
                )
            ) : null}
        </div>
    );
};
export default CompanyDocument;
