import {
  NOTIFICATION_ALERT_OPEN,
  NOTIFICATION_ALERT_CLOSE
} from "../actions/types";

const initialState = {
  isOpen: false,
  message: "",
  type: "error",
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case NOTIFICATION_ALERT_OPEN:
      return { ...state, ...payload, isOpen: true };
    case NOTIFICATION_ALERT_CLOSE:
      return { ...state, isOpen: false };
    default:
      return state;
  }
}