import React, { useState, useEffect, useMemo} from 'react';
import ReactQuill, { Quill } from 'react-quill';
import CustomToolbar from './Custom/CustomToolbar';
import './styles/quillStyles.css'
import './styles/fonts.css'
import 'quill/dist/quill.snow.css'; 
import CustomBlot from './Custom/CustomBlot';
import SignatureSelect from './Signature/SignatureSelect';
import { useSignatureManager } from './Signature/SignatureUtils';
import { loadDynamicFont } from './utils/dynamicFont';


const Editor = ({ text, textFormat, setTextFormat, onChange, quillRef, userProfile, signatureDataList, setSignatureDataList }) => {    
  const [cursorPosition, setCursorPosition] = useState(null);
  const [mentionList, setMentionList] = useState([]);
  const [mentionChar, setMentionChar] = useState(null);
  const [mentionPosition, setMentionPosition] = useState(null);
  const [mentionRange, setMentionRange] = useState(null);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedValueAlign, setSelectedValueAlign] = useState('');
  
  useEffect(() => {
    loadDynamicFont();
  }, []);

  useEffect(() => {
    try {
      const blots = [
        { format: 'mention', blot: CustomBlot.MentionBlot },
        { format: 'signature', blot: CustomBlot.SignatureBlot },
        { format: 'divider', blot: CustomBlot.Divider },
        { format: 'editableField', blot: CustomBlot.EditableField },
        { format: 'insertResizableBox', blot: CustomBlot.InsertResizableBox },
      ];
      blots.forEach(({ format, blot }) => {
        if (!Quill.imports[`formats/${format}`]) {
          Quill.register(blot);
        }
      });
    } catch (error) {
      console.error("Error registering Blots:", error);
    }
  }, []);

  const handleKeyDown = (e) => {
    const key = e.key;
    const quill = quillRef.current ? quillRef.current.getEditor() : null;
    if (quill) {
      const range = quill.getSelection();
      if (range) {
        if (key === '#' || key === '@') {
          e.preventDefault();
          setMentionChar(key);
          setMentionRange(range);

          const bounds = quill.getBounds(range.index);
          setMentionPosition({
            top: bounds.top + 30, 
            left: bounds.left
          });

          setHighlightedIndex(-1);
          setSearchTerm('');
          handleMentionSearch('');
        } else if (key === 'ArrowDown') {
          if (mentionList && mentionList.length > 0 ) {
            e.preventDefault();
            setHighlightedIndex((prevIndex) => {
              const newIndex = (prevIndex + 1) % mentionList.length;
              scrollToHighlightedIndex(newIndex);
              return newIndex;
            });
          } else {
            return true;
          }
        } else if (key === 'ArrowUp') {
          if (mentionList && mentionList.length > 0) { 
            e.preventDefault();
            setHighlightedIndex((prevIndex) => {
              const newIndex = (prevIndex - 1 + mentionList.length) % mentionList.length;
              scrollToHighlightedIndex(newIndex);
              return newIndex;
            });
          } else {
            return true;
          }
        } else  if (key === 'Enter') {
          if (highlightedIndex >= 0 && highlightedIndex < mentionList.length) {
        
            const mentionItem = mentionList[highlightedIndex];
            if (mentionItem) {
              setMentionRange(range);
              const mentionText = mentionItem.text || '';
        
              handleMentionSelect(mentionItem);              
              if (mentionRange && typeof mentionRange.index !== 'undefined') {
                const newCursorPosition = mentionRange.index + mentionText.length;
                quill.setSelection(newCursorPosition);
              } else {
                console.error("mentionRange is null or undefined");
              }
            } else {
              console.error("Mention item is null or undefined");
            }
          }
        } else if (key.length === 1 || key === ' ') {
          setSearchTerm((prev) => prev + key);
        } else if (key === 'Backspace') {
          setSearchTerm((prev) => prev.slice(0, -1));
          if (searchTerm.length > 0) {
            setMentionList([]);
          }
        }
      }
    }
  };

  const scrollToHighlightedIndex = (index) => {
    const mentionListElement = document.querySelector('.mention-list');
    if (mentionListElement) {
      const item = mentionListElement.querySelectorAll('.mention-item')[index];
      if (item) {
        mentionListElement.scrollTop = item.offsetTop - mentionListElement.offsetTop - (mentionListElement.clientHeight / 2) + (item.clientHeight / 2);
      }
    }
  };

  const handleMentionSearch = (searchTerm) => {
    try {
      if (searchTerm && userProfile) {
        const filteredKeys = [];
        if (typeof userProfile === 'object' && userProfile !== null) {
          const matchedKeys = Object.keys(userProfile).filter(key =>
            key.toLowerCase().startsWith(searchTerm.trim().toLowerCase())
          );
          filteredKeys.push(...matchedKeys);
        }
        const uniqueFilteredKeys = [...new Set(filteredKeys)];
  
        const mentionItems = uniqueFilteredKeys.map((key) => ({
          id: key,
          value: key,
        }));
        setMentionList(mentionItems);
  
      } else {
        const allKeys = [];
  
        if (typeof userProfile === 'object' && userProfile !== null) {
          allKeys.push(...Object.keys(userProfile));
        }
  
        const uniqueAllKeys = [...new Set(allKeys)];
  
        const mentionItems = uniqueAllKeys.slice(0, 6).map((key) => ({
          id: key,
          value: key,
        }));
        if (allKeys.length > 6) {
          mentionItems.push({ id: 'more', value: '... More' });
        }
        setMentionList(mentionItems);
      }
    } catch (error) {
      console.error("Error in handleMentionSearch:", error);
    }
  }

  const handleMentionSelect = (mention) => {
    const quill = quillRef.current ? quillRef.current.getEditor() : null;
    if (!quill || !mention || !mention.value) {
      console.error('Quill editor or mention is not initialized');
      return;
    }

    if (!mentionRange) {
      console.error('Mention range is not set');
      return;
    }

    const searchRange = mentionRange.index + searchTerm.length;
    quill.deleteText(mentionRange.index, searchRange - mentionRange.index);
    
    if (mentionRange) {
      const { index } = mentionRange;
      
      quill.insertEmbed(index, 'mention', {
        id: mention.id,
        value: mention.value,
      });
      resetMentionState();
    } else {  
      console.error('Mention range is not set');
    }
  };

  const resetMentionState = () => {
    setMentionList([]);
    setMentionRange(null);
    setSearchTerm('');
    setMentionChar(null);
    setMentionPosition(null);
  };

  useEffect(() => {
    handleMentionSearch(searchTerm);
  }, [searchTerm, mentionChar]);

  useEffect(() => {
    const Quill = require('quill');
    const Font = Quill.import('formats/font');
    const Size = Quill.import('attributors/style/size');
  
    if (!Font.whitelist.includes('THSarabunNew')) {
      Font.whitelist = ['THSarabunNew', 'serif', 'NotoSansThai'];
      Quill.register(Font, true);
    }
  
    Size.whitelist = ['14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px'];
    Quill.register(Size, true);
  }, []);
  
  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      if (quill) {
        quill.root.style.fontFamily = 'THSarabunNew';
        quill.root.style.fontSize = '18px';
        
        quill.format('font', 'THSarabunNew');
        quill.format('size', '18px');
                
        const handleSelectionChange = (range) => {
          const selection = quill.getSelection();
          if (selection) 
            setCursorPosition(selection.index);
        };
        quill.on('selection-change', handleSelectionChange);

        const handleTextChange = () => {
          const range = quill.getSelection();
          if (range) {
            const currentFormats = quill.getFormat(range.index);
            const currentSize = currentFormats.size || '18px';

            const delta = quill.getContents();

            delta.ops.forEach((op, index) => {

                if (op.insert && (!op.attributes || !op.attributes.size)) {
                    const startIndex = index === 0 ? 0 : quill.getLength() - op.insert.length;
                    quill.formatText(startIndex, op.insert.length, 'size', '18px');
                }   
            });

            const mentionBlots = document.querySelectorAll('.mention-blot');
            mentionBlots.forEach((blot) => {
              blot.style.fontSize = currentSize;
            });
          }
        };
        quill.on('text-change', handleTextChange);
        
        return () => {
          quill.off('selection-change', handleSelectionChange);
          quill.off('text-change', handleTextChange);
        };
      } else {
        console.error('Quill instance is not available');
      }
    } else {
      console.error('quillRef.current is null');
    }
  }, [quillRef]);

  const handleAlignment = (alignment) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.format('align', alignment);
    }
  };

  const handleSelectedValueChange = (value) => {
    setSelectedValueAlign(value); 
  };
  
  const { handleInsertSignature } = useSignatureManager(quillRef, signatureDataList, setSignatureDataList);

  const modules = useMemo(() => ({
    toolbar: {
      container: '#toolbar',
    },
    clipboard: {
      matchVisual: false,
    },
    keyboard: {
      bindings: {
        handleEnter: {
          key: 13, 
          handler: (range, context) => {
            const event = window.key;            
            if (event){
              context.quill.insertText(range.index, '\n', 'user');
              context.quill.setSelection(range.index, 'silent'); 
            } else {
              if (event && typeof event.preventDefault === 'function') {
                event.preventDefault();
              }
              const quill = quillRef.current && quillRef.current.getEditor();
              if (quill) {
                quill.setSelection(range.index, 'silent'); 
              }
            }
          }
        },
        handleShiftEnter: {
          key: 13, 
          shiftKey: true,
          handler: (range, context) => {
            const event = window.key;            
            if (event){
              context.quill.insertText(range.index, '\n', 'user');
              context.quill.setSelection(range.index, 'silent'); 
            } else {
              if (event && typeof event.preventDefault === 'function') {
                event.preventDefault();
              }
              const quill = quillRef.current && quillRef.current.getEditor();
              if (quill) {
                quill.setSelection(range.index, 'silent'); 
              }
            }
          }
        },
        handleTab: {
          key: 9, 
          shiftKey: true,
          handler: (range, context) => {
            const event = window.key;            
            if (event){
              const tabSpace = "    "; 
              context.quill.insertText(range.index, tabSpace, 'user');
              context.quill.setSelection(range.index + tabSpace.length, 'silent');
            } else {
              if (event && typeof event.preventDefault === 'function') {
                event.preventDefault();
              }
              const quill = quillRef.current && quillRef.current.getEditor();
              if (quill) {
                quill.setSelection(range.index, 'silent'); 
              }
            }
          }
        },
      }
    }
    
  }), []);
  
  useEffect(() => {
    const handleKeyDown = (event) => {
      const quill = quillRef.current.getEditor();
      if (!quill) return;

      const range = quill.getSelection();

      if (event.key === 'ArrowDown' && event.shiftKey) {
        event.preventDefault();
        if (range) {
          quill.insertText(range.index, '\n', 'user');
          quill.setSelection(range.index + 1, 'silent');
        }
      }
      if (event.key === 'ArrowUp' && event.shiftKey) {
        event.preventDefault();
        const quill = quillRef.current.getEditor();
        const range = quill.getSelection();
      
        if (range.index > 0) {
          const [currentLine] = quill.getLine(range.index);
          const [prevLine, prevOffset] = quill.getLine(range.index - 1);
      
          if (prevLine) {
            const start = prevLine.offset();
            const length = prevLine.length();
      
            quill.deleteText(start, length);
      
            quill.setSelection(currentLine.offset(), 'silent');
          }
        }
      }
      if (event.key === 'Tab') {
        event.preventDefault(); 
        const quill = quillRef.current ? quillRef.current.getEditor() : null;
        if (quill) {
          const range = quill.getSelection();
          if (range) {
            const spaces = "    "; 
            quill.insertText(range.index, spaces, 'user');
            quill.setSelection(range.index + spaces.length, 'silent');
          }
        }
      }
    };  
    const editorContainer = quillRef.current.getEditor().root;
    editorContainer.addEventListener('keydown', handleKeyDown);

    return () => {
      editorContainer.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div>
      <div style={{ position: 'relative' }}>
      <CustomToolbar 
        handleAlignment={handleAlignment} 
        handleSelectedValueChange={handleSelectedValueChange} 
      />
        <div id="toolbar" style={{ position: 'absolute', right: '20px', top: '10px', display: 'flex', alignItems: 'center', gap: '10px' }}>
          <SignatureSelect
            selectedValue={selectedValue}
            handleInsertSignature={handleInsertSignature}
          />
        </div>
      </div>
      <div
        id="text-editor-container"
        style={{
          width: '595.28px',  
          height: '841.89px',  
          backgroundColor: 'white',
          position: 'relative',
          border: '0.5px solid #ccc',
        }}
      >
        <ReactQuill
          ref={quillRef}
          value={text}
          onChange={onChange}
          theme="snow"
          modules={modules}
          readOnly={false} 
          style={{
            height: '100%',
            width: '100%',
            wordBreak: 'break-word',
          }}
          onKeyDown={handleKeyDown}
        />
        {mentionList.length > 0 && mentionPosition && (
          <div 
            className="mention-list"
            style={{
              position: 'absolute',
              top: `${mentionPosition.top}px`,
              left: `${mentionPosition.left}px`,
              border: '1px solid #ccc',
              backgroundColor: '#fff',
              boxShadow: '0 4px 15px rgba(0,0,0,0.15)',
              zIndex: 1000,
              maxHeight: '200px',
              overflowY: 'auto',
              borderRadius: '8px',
              padding: '10px',
              fontFamily: 'Arial, sans-serif'
            }}
          >
            {mentionList.map((mention, index) => (
              <div
                key={mention.id}
                className={`mention-item ${highlightedIndex === index ? 'highlighted' : ''}`}
                onMouseDown={(e) => e.preventDefault()} 
                onClick={() => handleMentionSelect(mention)}
                style={{
                  padding: '8px 12px',
                  cursor: 'pointer',
                  borderRadius: '4px',
                  backgroundColor: highlightedIndex === index ? '#f0f0f0' : 'transparent',
                  color: highlightedIndex === index ? '#1976d2' : '#333',
                  display: 'flex',
                  alignItems: 'center',
                  transition: 'background-color 0.3s, color 0.3s'
                }}
                onMouseEnter={() => setHighlightedIndex(index)}
                onMouseLeave={() => setHighlightedIndex(-1)}
              >
                <i 
                  className="fas fa-user-circle"
                  style={{
                    marginRight: '8px',
                    fontSize: '18px',
                    color: highlightedIndex === index ? '#1976d2' : '#aaa'
                  }}
                />
                {mention.value}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Editor;
