import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { PDFDocument } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

import { 
    Autocomplete,
    CircularProgress,
    Container,
    Grid,
    MenuItem,
    Popper,
    styled,
    TextField,
    Typography
} from '@mui/material';

import CardStyled from '../../../../shared/general/Card';
import TextFieldTheme from '../../../../shared/general/TextFieldTheme';
import ButtonBlue from '../../../../shared/general/ButtonBlue';

import { getDepartment } from '../../../../../../actions/department';
import { getAllEmployeesAllRoles, getEmployeeDataWritePDF } from "../../../../../../actions/employee";

const RootStyled = styled("div")({
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
    "& .MuiSelect-filled":{
        padding:"16px 32px 16px 12px"
    }
});

const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    border: 0,
    "& .MuiFilledInput-root": {
      backgroundColor: "#919eab14",
      height: 56,
      padding: "0px 12px",
      borderRadius: 8,
      "&.Mui-focused": {
        backgroundColor: "#919eab14",
      },
      "& .MuiInputAdornment-root": {
        width: 32,
        marginTop: "0!important",
        fontSize: 24,
        color: "#919EAB",
        "& i": {
          marginRight: 8,
        },
      },
      "& .MuiAutocomplete-endAdornment": {
        "& .MuiButtonBase-root": {
          fontSize: 14,
          width: 22,
          height: 22,
        },
      },
      "&:hover": {
        backgroundColor: "#919eab29",
        "&:before": {
          border: "none !important",
        },
      },
      "&::after": {
        border: "none",
      },
      "&::before": {
        border: "none",
      },
    }
});

const EmployeesDocuments = (props) => {
    const dispatch = useDispatch();
    const { result: UserProfile } = useSelector(state => state.userProfile);
    const { result: AllDepartments } = useSelector(state => state.department);
    const { result: employeeList } = useSelector((state) => state.employees);
    const [type, setType] = useState(null);
    const [generatedPDF, setGeneratedPDF] = useState(null);
    const [status, setStatus] = useState({ isLoading: false, isFail: false });
    const { control, handleSubmit, watch, getValues, setValue, formState:{ errors } } = useForm({
        defaultValues:{
            department:null,
            employee:null,
            year:""
        }
    });

    useEffect(() => {
        if(UserProfile){
            dispatch(getAllEmployeesAllRoles());
            dispatch(getDepartment(UserProfile.idCompany));
        }
    },[UserProfile]);

    useEffect(() => {
        let pathnameSplit = props.location.pathname.split("/");
        if(!(pathnameSplit[pathnameSplit.length - 1] === "50tawi" || pathnameSplit[pathnameSplit.length - 1] === "PND91")){
            props.history.push("/");
        }
        setType(pathnameSplit[pathnameSplit.length - 1]);
    },[props.location.pathname]);

    const checkIsWorkingYear = (year) => {
        let result = true;
        if (watch("employee") && watch("employee").hiringDate) {
          result = year >= parseInt(dayjs(watch("employee").hiringDate).format("YYYY"));
        }
        return result;
    };

    const handleOnSubmit = async (data) => {
        let year = data.year;
        let idDepartment = data.department.idDepartment;
        let idEmployees = data.employee ? data.employee.idEmployees : null;
        setStatus({ isLoading: true, isFail: false });
        const result = await dispatch(getEmployeeDataWritePDF(type, year, idDepartment, idEmployees));
        console.log("result : ",result);
        if(result && result.status === 200){
            const url = `${process.env.REACT_APP_API_URL}files/${type}.pdf`;
            const existingPdfBytes = await fetch(url).then((res) =>
                res.arrayBuffer()
            );
            const pdfDoc = await PDFDocument.load(existingPdfBytes);
            // const urlFont = `${process.env.REACT_APP_API_URL}fonts/${type !== "PND91" ? "THSarabunNew" : "Sarabun-Medium"}.ttf`;
            const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
            const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());

            const pages = pdfDoc.getPages();

            const resultPDFDoc = await PDFDocument.create();
            resultPDFDoc.registerFontkit(fontkit);
            const font = await resultPDFDoc.embedFont(fontBytes);

            result.data.map(async (page, index) => {
                const [currentPage] = await resultPDFDoc.copyPages(pdfDoc, [index % pages.length])
                page.map((item) => {
                    currentPage.drawText(item.text.replace(/\u200B/g, '').trim(), {
                        x: item.x,
                        y: item.y,
                        ...(item.font ? { font: font } : {}),
                        ...(item.size ? { size: item.size } : {})
                    });
                });
                resultPDFDoc.addPage(currentPage);
                if(index === 0) {
                    resultPDFDoc.removePage(0);
                }
            });

            const pdfBytes = await resultPDFDoc.save();
            const bytes = new Uint8Array(pdfBytes);
            const blob = new Blob([bytes], { type: "application/pdf" });
            const docUrl = URL.createObjectURL(blob);
            setGeneratedPDF(docUrl);
            setStatus({ isLoading: false, isFail: false });
        } else {
            setStatus({ isLoading: false, isFail: true });
        }
    }

    return (
        <RootStyled className="page">
            <Container maxWidth='lg'>
                <div style={{ margin:"16px 0px" }}>
                    <Typography variant="h4">{type === "50tawi" ? `50 ทวิ` : "ภ.ง.ด.91"}</Typography>
                </div>
                <CardStyled style={{ padding: 24 }}>
                    <form onSubmit={handleSubmit(handleOnSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Typography className="label" color="text.third">หน่วยงาน</Typography>
                                <Controller 
                                    control={control}
                                    name="department"
                                    rules={{
                                        required: {value: true, message: "กรุณาเลือกหน่วยงาน"},
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <StyledAutocomplete 
                                            disableClearable
                                            value={value}
                                            options={AllDepartments || []}
                                            getOptionLabel={(option) => {
                                                if (typeof option === 'string') {
                                                  return option;
                                                }
                                                if (option.inputValue) {
                                                  return option.inputValue;
                                                }
                                                return option.departmentName;
                                            }}
                                            isOptionEqualToValue={(option, value) => option.departmentName === value.departmentName}
                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                  {option.departmentName}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="filled"
                                                    placeholder={"หน่วยงาน"}
                                                    helperText={errors && errors.department && errors.department.message}
                                                    error={errors && errors.department ? true: false}
                                                />
                                            )}
                                            onChange={(_, data) => {
                                                if (typeof data === 'object') {
                                                    onChange(data);
                                                    setValue("employee",null);
                                                }
                                            }}
                                            onInputChange={(_, data) => {
                                                if(!data || data.length <= 0){
                                                    setValue("department",null);
                                                }
                                            }}
                                            noOptionsText="ไม่พบรายชื่อหน่วยงาน"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography className="label" color="text.third">ชื่อ-สกุลพนักงาน</Typography>
                                <Controller 
                                    control={control}
                                    name="employee"
                                    render={({ field: { onChange, value } }) => (
                                        <StyledAutocomplete 
                                            disableClearable
                                            value={value}
                                            options={((employeeList && watch("department")) && employeeList.filter((emp) => {
                                                return (
                                                    emp.idDepartment === getValues("department").idDepartment &&
                                                    ((watch("year") && (parseInt(watch("year"))%1 === 0)) ? 
                                                        parseInt(dayjs(emp.hiringDate).format("YYYY")) <= (parseInt(getValues("year")) - 543) : true
                                                    )
                                                )
                                            })) || []}
                                            getOptionLabel={(option) => {
                                                if (typeof option === 'string') {
                                                  return option;
                                                }
                                                if (option.inputValue) {
                                                  return option.inputValue;
                                                }
                                                return `${option.firstname_TH} ${option.lastname_TH}`;
                                            }}
                                            isOptionEqualToValue={(option, value) => `${option.firstname_TH} ${option.lastname_TH}` === `${value.firstname_TH} ${value.lastname_TH}`}
                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                  {`${option.firstname_TH} ${option.lastname_TH}`}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="filled"
                                                    placeholder={"พนักงานทุกคนในหน่วยงาน"}
                                                />
                                            )}
                                            onChange={(_, data) => {
                                                if (typeof data === 'object') {
                                                  onChange(data);
                                                }
                                            }}
                                            onInputChange={(_, data) => {
                                                if(!data || data.length <= 0){
                                                    setValue("employee",null);
                                                }
                                            }}
                                            noOptionsText="ไม่พบรายชื่อพนักงาน"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="label" color="text.third">ปีที่จ่าย</Typography>
                                <Controller
                                    name="year"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: "กรุณาเลือกปีที่จ่าย"},
                                    }}
                                    render={({ field }) => (
                                        <TextFieldTheme
                                            {...field}
                                            variant="filled"
                                            select
                                            placeholder={"ปีที่จ่าย"}
                                            SelectProps={{ 
                                                displayEmpty: true, 
                                                renderValue: 
                                                    field.value !== "" ? undefined : () => (
                                                        <div style={{ color:"#a7acb1", fontWeight: 300 }}>ปีที่จ่าย</div>
                                                    )
                                            }}
                                            helperText={errors && errors.year && errors.year.message}
                                            error={errors && errors.year ? true: false}
                                        >
                                            {[...Array(5)].map((_, index) => {
                                                if (
                                                    checkIsWorkingYear(
                                                        parseInt(dayjs().format("YYYY")) - (index + 1)
                                                    )
                                                ) {

                                                    return (
                                                        <MenuItem
                                                            key={dayjs().add((543 - (index + 1)), 'year').format("YYYY")}
                                                            value={dayjs().add((543 - (index + 1)), 'year').format("YYYY")}
                                                        >
                                                            {dayjs().add((543 - (index + 1)), 'year').format("YYYY")}
                                                        </MenuItem>
                                                    )
                                                }
                                            })}
                                        </TextFieldTheme>
                                    )}
                                />
                            </Grid>
                            <Grid item container xs={12} justifyContent="flex-end">
                                <Grid item>
                                    <ButtonBlue 
                                        variant="contained"
                                        type="submit"
                                    >สร้างไฟล์</ButtonBlue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </CardStyled>
                {status.isLoading && 
                    <div style={{ height: 300, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <CircularProgress />
                            <Typography style={{ marginTop: 16 }} variant="h6">กำลังสร้างไฟล์</Typography>
                        </div>
                    </div>
                }
                {status.isFail && 
                    <div style={{ height: 300, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <i className="fa-sharp fa-regular fa-circle-xmark" style={{ fontSize: 72, color:"#ff0000" }} />
                            <Typography style={{ marginTop: 16, color:"#ff0000" }} variant="h6">สร้างไฟล์ไม่สำเร็จ</Typography>
                            <Typography style={{ marginTop: 8 }}>ตรวจสอบการเข้าสู่ระบบและข้อมูลของพนักงานอีกครั้ง</Typography>
                        </div>
                    </div>
                }
                {(generatedPDF && !status.isLoading && !status.isFail) ? (
                    <div style={{ marginTop: "24px", marginBottom: "24px" }}>
                        <CardStyled>
                            <div style={{ padding: 16 }}>
                                <iframe
                                    src={generatedPDF}
                                    type="application/pdf"
                                    style={{
                                        width: "100%",
                                        height: "1000px",
                                        borderStyle: "none",
                                        borderRadius: "16px"
                                    }}
                                />
                            </div>
                        </CardStyled>
                    </div>
                ) : <></>}
            </Container>
        </RootStyled>
    );
};

export default EmployeesDocuments;