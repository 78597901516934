import React, { useState, useEffect } from 'react';
import { Dialog, Typography, Box, TextField, Divider } from '@mui/material';
import ButtonBlue from '../../../shared/general/ButtonBlue';
import DatePickerCustom from "../../../shared/date/datePicker";
import { useDispatch } from 'react-redux';
import { getKPIDate , updateKPIDate } from '../../../../../actions/kpi';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

function DialogEditPopup({ open, onClose, data }) {
  const {t,i18n} = useTranslation();
  const [newDate, setNewDate] = useState({
    period: '',
    start: '',
    end: '',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setNewDate({
        period: data.period || '',
        start: data.start || '',
        end: data.end || '',
      });
    }
  }, [data]);

  const handleNewDateChange = (field, value) => {
    setNewDate((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    if (newDate.period && newDate.start && newDate.end) {
      onClose();
  
      try {
        const result = await Swal.fire({
          title: t("ConfirmDateChange"),
          text: t("SaveChangesConfirm"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: t("Confirm"),
          cancelButtonText: t("Cancel"),
        });
  
        if (result.isConfirmed) {
          const formData = {
            dateName: newDate.period,
            startDate: newDate.start,
            endDate: newDate.end,
            type: data && data.type ? data.type : 'edit',
          };
  
          const updateResult = await dispatch(updateKPIDate(data.idKpiEditDate, formData));
  
          if (updateResult === "Error") {
            Swal.fire({
              icon: 'error',
              title: t("AnErrorOccurred"),
              text: t("UnableToUpdate"),
              timer: 2000,
              showConfirmButton: false,
            });
          } else {
            Swal.fire({
              icon: 'success',
              title: t("Success"),
              text: t("DataSaveSuccessful"),
              timer: 2000,
              showConfirmButton: false,
            });
  
            await dispatch(getKPIDate());
          }
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: t("AnErrorOccurred"),
          text: t("UnableToUpdate"),
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: t("PleaseFillInRequiredInformations"),
        text: t("FieldCannotBeEmpty"),
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };
  

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box sx={{ padding: "16px", display: "flex", flexDirection: "column", height: "100%" }}>
        <Typography variant="h6">
          {data && data.type === 'grade' ? t("EditDateRangeForKPIConfirmation") : t("EditDateRangeForKPIAddition")}
        </Typography>

        <Box display="flex" flexDirection="column" gap="8px" mt={2}>
          <Typography>{t("PeriodName")}</Typography>
          <TextField
            margin="dense"
            value={newDate.period}
            onChange={(e) => handleNewDateChange("period", e.target.value)}
            fullWidth
            variant="outlined"
          />
          <Box display="flex" gap="16px" alignItems="flex-end" justifyContent="space-between">
            <Box flex="1">
              <Typography>{t("StartDate")}</Typography>
              <DatePickerCustom
                value={newDate.start}
                onChange={(newValue) => handleNewDateChange("start", newValue)}
                fullWidth
              />
            </Box>
            <Box flex="1">
              <Typography>{t("EndDate")}</Typography>
              <DatePickerCustom
                value={newDate.end}
                onChange={(newValue) => handleNewDateChange("end", newValue)}
                fullWidth
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={4}>
          <ButtonBlue onClick={onClose} variant="contained" color="error" sx={{ mr: 2 }}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue onClick={handleSave} variant="contained" color="primary">
            {t("Save")}
          </ButtonBlue>
        </Box>
      </Box>
    </Dialog>
  );
}

export default DialogEditPopup;
