import React from "react";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  TextField,
  FormControl,
  Grid,
  Popper,
  Box,
  Divider,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import SaveIcon from "@mui/icons-material/Save";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import SelectCompany from "../../shared/selectCompany";
import { addEmailReport } from "../../../../../actions/email";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../../shared/date/datePicker";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const DialogSelectDate = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const {
    handleClose,
    handleSubmit,
    setData,
    data,
    handleChangeAlertType,
    handleOpenAlert,
    select,
    excel,
  } = props;

  const handleChangeCompany = (newValue) => {
    if (newValue) {
      setData({ ...data, selectedCompany: newValue.idCompany });
    } else {
      setData({ ...data, selectedCompany: null });
    }
  };

  const handleChange = (event) => {
    setData({ ...data, value: event.target.value });
  };

  const submit = async () => {
    const formData = {
      ReportName: data.ReportName,
      idEmployees: data.idEmployees,
      day: data.autoEmail == `${t("LastDayOfMonth")}` ? 0 : data.autoEmail,
    };
    const result = await dispatch(addEmailReport(formData));
    if (result.status === 200) {
      handleChangeAlertType(`${t("Successfully")}`);
      handleOpenAlert(true);
    } else {
      handleChangeAlertType(`${t("Unsuccessful")}`);
      handleOpenAlert(true);
    }
  };

  return (
    <StyledRoot sx={{ width: 470 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className="GridTopicInput">
          {t("EmailSentDate")}
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledBoxSearch>
            <StyledAutocomplete
              options={[
                `${t("LastDayOfMonth")}`,
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                15,
                16,
                17,
                18,
                19,
                20,
                21,
                22,
                23,
                24,
                25,
                26,
                27,
                28,
              ]}
              onChange={(event, newValue) => {
                if (newValue) {
                  setData({ ...data, autoEmail: newValue });
                } else {
                  setData({ ...data, autoEmail: null });
                }
              }}
              value={
                data.autoEmail == 0 ? `${t("LastDayOfMonth")}` : data.autoEmail
              }
              popupIcon={<i class="fa-light fa-chevron-down"></i>}
              getOptionLabel={(option) => `${option}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={"filled"}
                  placeholder={`${t("SelectDate")}`}
                />
              )}
              PopperComponent={StyledPopper}
            />
          </StyledBoxSearch>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "16px",
          }}
        >
          <ButtonBlue
            variant="outlined"
            startIcon={<SaveIcon />}
            onClick={submit}
            disabled={data.autoEmail === null}
          >
            {`${t("Save")}`}
          </ButtonBlue>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {select && (
          <Grid item xs={12} sm={6} className="GridTopicInput">
            {t("SelectData")}
          </Grid>
        )}
        {select && (
          <Grid item xs={12} sm={6}>
            <FormControl sx={{ justifySelf: "flex-end" }}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={data.value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="year"
                  control={<Radio />}
                  label={`${t("FullYear")}`}
                />
                <FormControlLabel
                  value="month"
                  control={<Radio />}
                  label={`${t("Monthly")}`}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} sm={6} className="GridTopicInput">
          {t("SearchDate")}
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerCustom
            inputFormat="MMMM YYYY"
            minDate={new Date(new Date().getFullYear() - 4, 0, 1)}
            maxDate={new Date()}
            value={data.inputSearch}
            name="start"
            views={["year", "month"]}
            openTo="month"
            onChange={(newValue) => {
              setData({ ...data, inputSearch: newValue });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="GridTopicInput">
          {t("SelectCompany")}
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectCompany
            variant="outlined"
            hideLabel={true}
            handleChangeCompany={handleChangeCompany}
          />
        </Grid>
      </Grid>
      <StyledFooter>
        <ButtonBlue className="cancel" onClick={handleClose}>
          {t("Cancel")}
        </ButtonBlue>
        <ButtonBlue
          variant="outlined"
          startIcon={<DownloadRoundedIcon />}
          onClick={handleSubmit}
          disabled={excel === null}
        >
          {t("Download")}
        </ButtonBlue>
      </StyledFooter>
    </StyledRoot>
  );
};

export default DialogSelectDate;
