import React, { useState, useEffect } from "react";
import { useTitle } from "react-use";
import { useDispatch, useSelector } from "react-redux";

import Chart from "@dabeng/react-orgchart";
import "./ProjectAssign.css";
import ChartNode from "./ChartNode";
import { useLocation } from "react-router-dom";
import AddMember from "./AddMember";
import { useHistory } from "react-router-dom";

import {
  Grid,
  Typography,
  Button,
  Paper,
  CircularProgress,
  Container
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";

import { getAllProjectAssign } from "../../../../../actions/projectAssign";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTypography-h6": {
      fontWeight: 600,

    },
  },
  addBtn: {
    backgroundColor: "#2f74eb",
    color: "#fff",
    fontWeight: 700,
    height: "40px",
    borderRadius: "25px",
    paddingLeft: 32,
    paddingRight: 32,
    "&:hover": {
      backgroundColor: "#2a68d3",
    },
  },
  infoBtn: {
    backgroundColor: "#fff",
    color: "#2f74eb",
    border: "2px solid #2f74eb",
    fontWeight: 700,
    height: "40px",
    borderRadius: "25px",
    paddingLeft: 32,
    paddingRight: 32,
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  container: {
    width: "100%",
    marginTop: 24,
    overflowX: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  loading_container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100px",
  },
  noData_section: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  viewInfoBtn: {
    backgroundColor: "#2f74eb",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#2a68d3",
    },
  },
  refreshBtn: {
    backgroundColor: "#2f74eb",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#2a68d3",
    },
  },
}));

export default function ProjectAssign() {
  const classes = useStyles();
  const location = useLocation();
  useTitle(
    location.projectData.projectName + " | Project Assignment - HR CORE DATA"
  );
  const dispatch = useDispatch();
  const { result: projectAssignStore } = useSelector(
    (state) => state.projectAssign
  );

  const history = useHistory();
  const [project, setProject] = useState([]);
  const [chart, setChart] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isNoData, setIsNoData] = useState(false);

  const [addModal, setAddModal] = useState({
    isOpen: false,
    projectData: [],
  });

  const [infoModal, setInfoModal] = useState({
    isOpen: false,
    projectData: [],
  });

  const list_to_tree = (list) => {
    var map = {},
      node,
      roots = [],
      i;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parentId !== "0") {
        list[map[node.parentId]].children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  };

  const handleOnAddNew = () => {
    setAddModal({
      isOpen: true,
      projectData: location.projectData,
    });
  };

  const fetchData = () => {
    if (projectAssignStore !== null) {
      let storeList = [...projectAssignStore];

      var items = storeList.filter(
        (item) => item.projId === location.projectData.projId
      );

      if (items.length > 0) {
        let projects = list_to_tree(items);
        console.log(projects)
        console.log(projects[0])
        setChart(projects[0]);
        setIsNoData(false);
      } else {
        setIsNoData(true);
      }
      setIsLoading(false);
    }
  };

  const handleOnRefresh = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [projectAssignStore]);

  useEffect(() => {
    dispatch(getAllProjectAssign());
    setProject(location.projectData);
  }, []);

  return (
    <div className={`page`}>
       <Container maxWidth="lg">
       <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={4}
      >
        <Grid item>
          <Grid container alignItems="center" spacing={4}>
            <Grid item>
              <Typography variant="h6">{project.projectName}</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                className={classes.refreshBtn}
                onClick={handleOnRefresh}
              >
                <RefreshIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                className={classes.infoBtn}
                onClick={() =>
                  history.push({
                    pathname:
                      "/admin/projectpersoninfo/" +
                      location.projectData.projectName,
                    projectData: location.projectData,
                  })
                }
              >
                Person Info
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                className={classes.addBtn}
                onClick={handleOnAddNew}
              >
                Add New
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Paper className={classes.container}>
        {isLoading ? (
          <div className={classes.loading_container}>
            <CircularProgress />
          </div>
        ) : chart ? (
          <Chart datasource={chart} nodeTemplate={ChartNode} />
        ) : (
          <div className={classes.noData_section}>
            <Grid container justifyContent="center">
              <Grid item>
                <Typography variant="h6">No Data</Typography>
              </Grid>
            </Grid>
          </div>
        )}
      </Paper>
      <AddMember
        addModal={addModal}
        setAddModal={setAddModal}
        fetchData={fetchData}
      />
       </Container>
      
    </div>
  );
}
