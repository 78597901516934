import webhookService from "../services/webhook.service";
import employeeService from "../services/employee.service";
import personalTaxService from "../services/personalTax.service";
import requestTimeService from "../services/requestTime.service";
import leaveService from "../services/leave.service";
import kpiService from "../services/kpi.service";
import {
  CHATBOT_DOCUMENT_FETCHING,
  CHATBOT_DOCUMENT_FAILED,
  CHATBOT_DOCUMENT_SUCCESS,
} from "./types";

export const getBotResponse = async (query) => {
  try {
    const res = await webhookService.getBotResponse(query);

    if (res) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export const getLeaveQuota = async (query) => {
  try {
    const res = await webhookService.getLeaveQuota(query);

    if (res) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export const getLateAbsent = async (query) => {
  try {
    const res = await webhookService.getLateAbsent(query);

    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getOvertimeShift = async (query) => {
  try {
    const res = await webhookService.getOvertimeShift(query);

    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getDocumentPDFFile = async (query) => {
  try {
    const res = await webhookService.getDocumentPDFFile(query);

    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getEmpHistoryEnroll = async (filter) => {
  try {
    const res = await webhookService.getEmpHistoryEnroll(filter);

    if (res) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export const getEmpSkillById = async (filter) => {
  try {
    const res = await webhookService.getEmpSkillById(filter);

    if (res) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export const getCompetencyEvaluate = async (filter) => {
  try {
    const res = await webhookService.getCompetencyEvaluate(filter);

    if (res) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export const getEmpIDP = async (filter) => {
  try {
    const res = await webhookService.getEmpIDP(filter);

    if (res) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export const getEmpOnJobTraining = async (filter) => {
  try {
    const res = await webhookService.getEmpOnJobTraining(filter);

    if (res) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export const getEmpListEnrollCourse = async (filter) => {
  try {
    const res = await webhookService.getEmpListEnrollCourse(filter);

    if (res) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};
export const getMyCertificate = async (filter) => {
  try {
    const res = await webhookService.getMyCertificate(filter);

    if (res) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export const getMyCertificateData = async (id, type) => {
  try {
    const res = await webhookService.getMyCertificateData(id, type);

    if (res) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export const getCourseStatus = async (filter) => {
  try {
    const res = await webhookService.getCourseStatus(filter);

    if (res) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export const getSkillStatus = async (filter) => {
  try {
    const res = await webhookService.getSkillStatus(filter);

    if (res) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export const getOnJobTrainingStatus = async (filter) => {
  try {
    const res = await webhookService.getOnJobTrainingStatus(filter);

    if (res) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export const getUserWallet = async () => {
  try {
    const res = await webhookService.getUserWallet();

    if (res) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export const getUserMission = async () => {
  try {
    const res = await webhookService.getUserMission();

    if (res) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export const getUserReward = async (idRedeemStatus) => {
  try {
    const res = await webhookService.getUserReward(idRedeemStatus);

    if (res) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};
export const getUserActivity = async () => {
  try {
    const res = await webhookService.getUserActivity();

    if (res) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export const getLeaveRequestAllYear = async (search) => {
  try {
    const res = await employeeService.getLeaveRequestAllYear(search);
    if (res) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

export const getPersonalTaxByMonth = async (date, idEmployees) => {
  try {
    const res = await personalTaxService.getPersonalTaxByMonth(
      date,
      idEmployees
    );
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getAllRequestTimeBy = async (filter, search, idCompany) => {
  try {
    const res = await requestTimeService.getAllRequestTimeBy(
      filter,
      search,
      idCompany
    );
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAllRequestTimeWithDrawBy = async (
  filter,
  search,
  idCompany
) => {
  try {
    const res = await requestTimeService.getAllRequestTimeWithDrawBy(
      filter,
      search,
      idCompany
    );
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getLeaveRequest = async (filter, search, idCompany) => {
  try {
    const res = await employeeService.getLeaveRequest(
      filter,
      search,
      idCompany
    );
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAllLeaveWithDrawBy = async (filter, search, idCompany) => {
  try {
    const res = await leaveService.getAllLeaveWithDrawBy(
      filter,
      search,
      idCompany
    );
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getUserKPI = async () => {
  try {
    const res = await kpiService.getKPI();

    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getChatbotDocument = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: CHATBOT_DOCUMENT_FETCHING,
    });
    const res = await webhookService.getChatbotDocument(idCompany);

    if (res) {
      dispatch({
        type: CHATBOT_DOCUMENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: CHATBOT_DOCUMENT_FAILED,
    });
  }
};

export const addChatbotDocument = async (formData) => {
  try {
    const res = await webhookService.addChatbotDocument(formData);

    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteChatbotDocument = async (idCompany, idChatbotDocument) => {
  try {
    const res = await webhookService.deleteChatbotDocument(idCompany, idChatbotDocument);

    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};
