import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import dayjs from "dayjs";
import useMediaQuery from "@mui/material/useMediaQuery";

import DatePickerCustom from "../../shared/date/datePicker";

import ButtonBlue from "../../shared/general/ButtonBlue";
import TableRequest from "../../shared/requestList/tableRequest";
import DialogConfirmWithdraw from "../../shared/general/DialogConfirmWithdraw";
import AlertResponse from "../../shared/general/AlertResponse";
import StatusRequest from "../../shared/requestList/stausRequest";
import CardRequest from "../../shared/requestList/cardRequest";

import FilterListIcon from "@mui/icons-material/FilterList";

import {
  getAllRequestTimeBy,
  getAllRequestTimeWithDrawBy,
} from "../../../../actions/requestTime";
import {
  withdrawLeaveEmployee,
  getAllLeaveWithDrawBy,
} from "../../../../actions/leave";
import { getLeaveRequest } from "../../../../actions/employee";
import { withdrawRequestTime } from "../../../../actions/requestTime";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { getPayrollSetting } from "../../../../actions/paytypes";
import DialogComment from "../../shared/requestList/dialogComment";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledCard = styled(Card)({
  padding: 16,
  marginTop: 24,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "&.filter": {
    marginBottom: 32,
  },
});

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .form-radio-label-type": {
    ["@media only screen and (max-width: 600px)"]: {
      flexBasis: "25%",
      width: "fit-content",
      margin: 0,
    },
    "& .label-radio": {
      ["@media only screen and (max-width: 600px)"]: {
        fontSize: 14,
      },
    },
  },
});

const StyledPaper = styled(Paper)({
  border: "none",
  borderRadius: 16,
  paddingBottom: 16,
  ["@media only screen and (max-width: 600px)"]: {
    border: "1px solid rgb(0,0,0,0.25)",
    padding: 16,
    marginBottom: 16,
    marginTop: 16,
  },
  "& .inner-info": {
    display: "flex",
    alignItems: "center",
    ["@media only screen and (max-width: 600px)"]: {
      flexDirection: "column",
      flexBasis: "25%",
    },
    "& .MuiBox-root": {
      margin: "0 8px 0 0!important",
      ["@media only screen and (max-width: 600px)"]: {
        margin: "0 !important",
      },
    },
  },
});

function Requestlist() {
  const today = dayjs().toDate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const mobileResponsive = useMediaQuery("(max-width:600px)");
  const { result: requestTimeList } = useSelector((state) => state.requestTime);
  const { result: requestTimeWithDrawList } = useSelector(
    (state) => state.requestTimeWithDraw
  );
  const { result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { result: leaveEmployeeWithdrawsList } = useSelector(
    (state) => state.leaveEmployeesWithDraw
  );

  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );

  const [filterRequestType, setFilterRequestType] = React.useState("all");
  const [dataWithDraw, setDataWithDraw] = React.useState(false);
  const [openConfirmWithDraw, setOpenConfirmWithDraw] = React.useState(false);
  const [search, setSearch] = React.useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
  });
  const [tabTable, setTabTable] = React.useState("1");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const [openDialogCommentManager, setOpenDialogCommentManager] = useState({
    open: false,
    comment: "",
    title: ""
  });

  const handleClickSearch = () => {
    // let hours = dayjs(search.end).diff(dayjs(search.start), "hours");
    // const days = Math.floor(hours / 24);
    // var result = [];
    // for (let index = 0; index < days + 2; index++) {
    //   result.push({
    //     dateTimeline: dayjs(search.start).add(index, "day"),
    //   });
    // }
    dispatch(getLeaveRequest("id", search));
    dispatch(getAllRequestTimeBy("id", search));
    dispatch(getAllRequestTimeWithDrawBy("id", search));
    dispatch(getAllLeaveWithDrawBy("id", search));
  };

  useEffect(() => {
    dispatch(getLeaveRequest("id", search));
    dispatch(getAllRequestTimeBy("id", search));
    dispatch(getAllRequestTimeWithDrawBy("id", search));
    dispatch(getAllLeaveWithDrawBy("id", search));
    dispatch(getPayrollSetting());
  }, []);

  const handleClickWithDraw = (data) => {
    setDataWithDraw(data);
    setOpenConfirmWithDraw(true);
    setTabTable(data.idLeave ? "2" : "1");
  };

  const handleClickCloseDialog = () => {
    setOpenConfirmWithDraw(false);
  };

  const handleConfirmWithdraw = async () => {
    console.log(dataWithDraw);
    // if (dataWithDraw.isApprove === null) {
    //   const result = await dispatch(withdrawLeaveEmployee(null, dataWithDraw));
    // }
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleDeleteRequestTime = async () => {
    let formData = {
      idRequestTime: dataWithDraw.idRequestTime,
      managerApprove: null,
      isApprove: null,
    };

    //ยังไม่ได้ Approve
    if (
      dataWithDraw.isManagerLV1Approve === null &&
      dataWithDraw.isManagerLV2Approve === null
    ) {
      // formData.isApprove = 1;
    } else {
      // Approve แล้ว
      if (dataWithDraw.isDoubleApproval) {
        if (dataWithDraw.isManagerLV2Approve) {
          formData.managerApprove = dataWithDraw.managerLV2ApproveBy;
          formData.isApprove = 1;
        }
      } else {
        if (dataWithDraw.approvalLevel === 1) {
          if (dataWithDraw.isManagerLV1Approve) {
            formData.managerApprove = dataWithDraw.managerLV1ApproveBy;
            formData.isApprove = 1;
          }
        } else if (dataWithDraw.approvalLevel === 2) {
          if (dataWithDraw.isManagerLV2Approve) {
            formData.managerApprove = dataWithDraw.managerLV2ApproveBy;
            formData.isApprove = 1;
          }
        }
      }
    }

    // console.log(formData)

    const result = await dispatch(withdrawRequestTime(formData));
    if (result && result.status === 200) {
      handleClickCloseDialog();
      handleChangeAlertType("success");
      handleOpenAlert();
      dispatch(getAllRequestTimeBy("id", search));
      dispatch(getAllRequestTimeWithDrawBy("id", search));
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
    }
  };

  const handleDeleteLeave = async () => {
    const formData = {
      idLeave: dataWithDraw.idLeave,
      isApprove: dataWithDraw.isApprove,
      managerApprove: dataWithDraw.idManager,
    };

    const result = await dispatch(withdrawLeaveEmployee(null, formData));
    if (result && result.status === 200) {
      handleClickCloseDialog();
      handleChangeAlertType("success");
      handleOpenAlert();
      dispatch(getLeaveRequest("id", search));
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
    }
  };

  const handleChangeFilterRequestType = (event) => {
    setFilterRequestType(event.target.value);
  };

  const renderData = () => {
    let tempRequestTimeList = requestTimeList ? requestTimeList : [];
    let tempLeaveEmployeesList = leaveEmployeesList ? leaveEmployeesList : [];
    let tempRequestTimeWithDrawList = requestTimeWithDrawList
      ? requestTimeWithDrawList
      : [];
    let tempLeaveEmployeeWithdrawsList = leaveEmployeeWithdrawsList
      ? leaveEmployeeWithdrawsList
      : [];
    let temp = [
      ...tempRequestTimeList,
      ...tempLeaveEmployeesList,
      ...tempRequestTimeWithDrawList,
      ...tempLeaveEmployeeWithdrawsList,
    ];

    switch (filterRequestType) {
      case "1":
        temp = requestTimeList.filter(
          (x) => x.idRequestType && x.idRequestType === 1
        );
        break;
      case "2":
        temp = requestTimeList.filter(
          (x) => x.idRequestType && x.idRequestType === 2
          // || (x.idRequestType && x.idRequestType === 3)
        );
        break;
      case "3":
        temp = requestTimeList.filter(
          (x) => x.idRequestType && x.idRequestType === 3
        );
        break;
      case "leave":
        temp = leaveEmployeesList.filter((x) => x.idLeave && x.idLeave > 0);
        break;
      default:
        break;
    }

    temp.sort(function (a, b) {
      return new Date(b.createDate) - new Date(a.createDate);
    });

    return temp;
  };

  const handleOpenDialogCommentManager = (type, comment) => {
    let title = `เหตุผลที่${type === "forceWithdraw" ? "ถูกถอน" : "ไม่อนุมัติ"}`;
    setOpenDialogCommentManager({ open: true, comment: comment, title: title });
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg" style={{ paddingBottom: 16 }}>
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          {`${t("RequestList")}`}
        </Typography>
        <div style={{ marginTop: 16 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={3}>
              <DatePickerCustom
                label={`${t("StartDate")}`}
                value={search.start}
                onChange={(newValue) => {
                  setSearch({ ...search, ["start"]: newValue });
                }}
                renderInput={(params) => (
                  <TextFieldTheme style={{ width: "100%" }} {...params} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <DatePickerCustom
                label={`${t("EndDate")}`}
                minDate={search.start}
                value={search.end}
                onChange={(newValue) => {
                  setSearch({ ...search, ["end"]: newValue });
                }}
                renderInput={(params) => (
                  <TextFieldTheme style={{ width: "100%" }} {...params} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ButtonBlue variant={"contained"} onClick={handleClickSearch}>
                {`${t("Search")}`}
              </ButtonBlue>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  {`${t("PayrunType")}`}
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={filterRequestType}
                  onChange={handleChangeFilterRequestType}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio />}
                    label={
                      <Typography className="label-radio" align="center">{`${t(
                        "All"
                      )}`}</Typography>
                    }
                    labelPlacement={mobileResponsive ? "bottom" : "end"}
                    className="form-radio-label-type"
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label={
                      <Typography className="label-radio" align="center">{`${t(
                        "WorkingTimeRequest"
                      )}`}</Typography>
                    }
                    labelPlacement={mobileResponsive ? "bottom" : "end"}
                    className="form-radio-label-type"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label={
                      <Typography className="label-radio" align="center">{`${t(
                        "OvertimeRequest"
                      )}`}</Typography>
                    }
                    labelPlacement={mobileResponsive ? "bottom" : "end"}
                    className="form-radio-label-type"
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label={
                      <Typography className="label-radio" align="center">{`${t(
                        "CompensateRequest"
                      )}`}</Typography>
                    }
                    labelPlacement={mobileResponsive ? "bottom" : "end"}
                    className="form-radio-label-type"
                  />
                  <FormControlLabel
                    value="leave"
                    control={<Radio />}
                    label={
                      <Typography className="label-radio" align="center">{`${t(
                        "LeaveRequest"
                      )}`}</Typography>
                    }
                    labelPlacement={mobileResponsive ? "bottom" : "end"}
                    className="form-radio-label-type"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          {!mobileResponsive ? (
            <StyledCard>
              <StyledPaper variant="outlined">
                <Typography
                  gutterBottom
                  color="text.third"
                  style={{ fontWeight: 500, fontSize: 14 }}
                >
                  {`${t("VariousStatusFormats")}`}
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4} md={2}>
                    <div className="inner-info">
                      <StatusRequest
                        boxSize={30}
                        fontSize={18}
                        borderRadius={8}
                        status={1}
                        active={1}
                      />
                      <Typography>{`${t("Approved")}`}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <div className="inner-info">
                      <StatusRequest
                        boxSize={30}
                        fontSize={18}
                        borderRadius={8}
                        status={0}
                        active={1}
                      />
                      <Typography>{`${t("NotApproved")}`}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <div className="inner-info">
                      <StatusRequest
                        boxSize={30}
                        fontSize={18}
                        borderRadius={8}
                        status={null}
                        step={null}
                        active={0}
                      />
                      <Typography>{`${t("CancelTransaction")}`}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <div className="inner-info">
                      <StatusRequest
                        boxSize={30}
                        fontSize={18}
                        borderRadius={8}
                        status={null}
                        step={null}
                        active={1}
                      />
                      <Typography>{`${t("PendingApproval")}`}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <div className="inner-info">
                      <StatusRequest
                        boxSize={30}
                        fontSize={18}
                        borderRadius={8}
                        status={null}
                        step={1}
                        active={1}
                      />
                      <Typography>{`${t("PendingReview")}`}</Typography>
                    </div>
                  </Grid>
                </Grid>
              </StyledPaper>

              {requestTimeList !== null &&
              leaveEmployeesList !== null &&
              requestTimeWithDrawList !== null &&
              leaveEmployeeWithdrawsList !== null &&
              payrollSetting ? (
                <TableRequest
                  handleClickWithDraw={handleClickWithDraw}
                  list={renderData()}
                  handleOpenDialogCommentManager={
                    handleOpenDialogCommentManager
                  }
                />
              ) : (
                <Typography> {`${t("NoItems")}`}</Typography>
              )}
            </StyledCard>
          ) : (
            <Fragment>
              <StyledPaper variant="outlined">
                <Typography
                  gutterBottom
                  color="text.third"
                  style={{ fontWeight: 500, fontSize: 14 }}
                >
                  {`${t("VariousStatusFormats")}`}
                </Typography>
                {mobileResponsive ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="inner-info">
                      <StatusRequest
                        boxSize={24}
                        fontSize={14}
                        borderRadius={8}
                        status={1}
                        active={1}
                      />
                      <Typography align="center" style={{ fontSize: 14 }}>{`${t(
                        "Approved"
                      )}`}</Typography>
                    </div>
                    <div className="inner-info">
                      <StatusRequest
                        boxSize={24}
                        fontSize={14}
                        borderRadius={8}
                        status={0}
                        active={1}
                      />
                      <Typography align="center" style={{ fontSize: 14 }}>{`${t(
                        "NotApproved"
                      )}`}</Typography>
                    </div>
                    <div className="inner-info">
                      <StatusRequest
                        boxSize={24}
                        fontSize={14}
                        borderRadius={8}
                        status={null}
                        step={null}
                        active={0}
                      />
                      <Typography align="center" style={{ fontSize: 14 }}>{`${t(
                        "CancelTransaction"
                      )}`}</Typography>
                    </div>
                    <div className="inner-info">
                      <StatusRequest
                        boxSize={24}
                        fontSize={14}
                        borderRadius={8}
                        status={null}
                        step={null}
                        active={1}
                      />
                      <Typography align="center" style={{ fontSize: 14 }}>{`${t(
                        "PendingApproval"
                      )}`}</Typography>
                    </div>
                    <div className="inner-info">
                      <StatusRequest
                        boxSize={24}
                        fontSize={14}
                        borderRadius={8}
                        status={null}
                        step={1}
                        active={1}
                      />
                      <Typography align="center" style={{ fontSize: 14 }}>{`${t(
                        "PendingReview"
                      )}`}</Typography>
                    </div>
                  </div>
                ) : (
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} md={2}>
                      <div className="inner-info">
                        <StatusRequest
                          boxSize={30}
                          fontSize={18}
                          borderRadius={8}
                          status={1}
                          active={1}
                        />
                        <Typography>{`${t("Approved")}`}</Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                      <div className="inner-info">
                        <StatusRequest
                          boxSize={30}
                          fontSize={18}
                          borderRadius={8}
                          status={0}
                          active={1}
                        />
                        <Typography>{`${t("NotApproved")}`}</Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                      <div className="inner-info">
                        <StatusRequest
                          boxSize={30}
                          fontSize={18}
                          borderRadius={8}
                          status={null}
                          step={null}
                          active={0}
                        />
                        <Typography>{`${t("CancelTransaction")}`}</Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                      <div className="inner-info">
                        <StatusRequest
                          boxSize={30}
                          fontSize={18}
                          borderRadius={8}
                          status={null}
                          step={null}
                          active={1}
                        />
                        <Typography>{`${t("PendingApproval")}`}</Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                      <div className="inner-info">
                        <StatusRequest
                          boxSize={30}
                          fontSize={18}
                          borderRadius={8}
                          status={null}
                          step={1}
                          active={1}
                        />
                        <Typography>{`${t("PendingReview")}`}</Typography>
                      </div>
                    </Grid>
                  </Grid>
                )}
              </StyledPaper>
              <div style={{ paddingBottom: 24 }}>
                {requestTimeList !== null &&
                leaveEmployeesList !== null &&
                requestTimeWithDrawList !== null &&
                leaveEmployeeWithdrawsList !== null &&
                payrollSetting ? (
                  <Fragment>
                    {renderData().map((request, index) => (
                      <div
                        style={{ marginTop: index === 0 ? 0 : 16 }}
                        key={`${index}_${
                          request.idLeave || request.idRequestTime
                        }`}
                      >
                        <CardRequest
                          request={request}
                          handleClickWithDraw={handleClickWithDraw}
                        />
                      </div>
                    ))}
                  </Fragment>
                ) : (
                  <Typography> {`${t("NoItems")}`}</Typography>
                )}
              </div>
            </Fragment>
          )}
        </div>
      </Container>

      <DialogComment
        open={openDialogCommentManager.open}
        onClose={() =>
          setOpenDialogCommentManager({ open: false, comment: "" })
        }
        comment={openDialogCommentManager.comment}
        title={openDialogCommentManager.title}
      />

      <DialogConfirmWithdraw
        mode={tabTable}
        open={openConfirmWithDraw}
        data={dataWithDraw}
        handleClose={handleClickCloseDialog}
        handleDeleteRequestTime={handleDeleteRequestTime}
        handleDeleteLeave={handleDeleteLeave}
      />
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </StyledRoot>
  );
}

export default Requestlist;
