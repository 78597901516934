import React, { useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  IconButton,
  Divider,
  FormControl,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  styled,
  Autocomplete,
  autocompleteClasses,
  Popper,
  FormGroup,
  Checkbox,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import {
  addProbationQuestionSet,
  getAllProbationQuestionSet,
  updateProbationQuestionSet,
} from "../../../../../actions/probation";
import { getEmploymentTypeByidCompany } from "../../../../../actions/employment-type";
import { getLevelByidCompany } from "../../../../../actions/level";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    minHeight: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const EditQuestionSet = ({ idCompany, handleClose, selectQuestionSet }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: employmentType } = useSelector(
    (state) => state.employmentType
  );
  const { result: groupLevel } = useSelector((state) => state.level);

  const validationSchema = yup.object().shape({
    questionSetName: yup
      .string()
      .max(250, `${t("NotMoreThan")} 250 ${t("Characters")}`)
      .required(t("ThisFieldIsRequired")),
    employmentTypes: yup.array().min(1, t("ThisFieldIsRequired")),
    groupLevels: yup.array().min(1, t("ThisFieldIsRequired")),
    answerScale: yup.number(),
    questionList: yup.array().of(
      yup.object().shape({
        question: yup
          .string()
          .max(500, `${t("NotMoreThan")} 500 ${t("Characters")}`)
          .required(t("ThisFieldIsRequired")),
        questionType: yup.string().required(t("ThisFieldIsRequired")),
        weight: yup
          .number()
          .transform((value) => Number.isNaN(value) ? null : value)
          .nullable()
          .when("questionType", {
            is: (val) => val === "choice",
            then: (schema) =>
              schema
                .integer()
                .min(1, `${t("NotLessThan")} 1`)
                .required(t("ThisFieldIsRequired")),
          }),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: {
      questionSetName: "",
      employmentTypes: [],
      groupLevels: [],
      answerScale: 6,
      questionList: [{ question: "", questionType: "", weight: "" }],
      isSelfAssessment: false,
      isManagerAssessment: true,
    },
    resolver: yupResolver(validationSchema),
  });

  console.log(errors)

  const questionWeight = watch("questionList").reduce((acc, cur) => {
    if (cur.questionType === "choice") {
      acc.totalWeight += Number(cur.weight)
      acc.totalLength++;
    }
    return acc
  }, { totalWeight: 0, totalLength: 0 });

  const onSubmit = async (formData) => {
    const cleansingForm = {
      ...formData,
      employmentTypes: formData.employmentTypes
        .filter((item) => item.idEmploymentType)
        .map((item) => item.idEmploymentType),
      groupLevels: formData.groupLevels
        .filter((item) => item.idGroupLevel)
        .map((item) => item.idGroupLevel),
      idProbationQuestionSet: selectQuestionSet.idProbationQuestionSet,
      idCompany,
    };

    if (questionWeight.totalLength > 0 && questionWeight.totalWeight !== 100) {
      dispatch(openNotificationAlert({ message: `${t("TotalWeightIsNot")} 100`, type: "error" }));
    } else {
      const res = await dispatch(updateProbationQuestionSet(cleansingForm));
      if (res && res.status === 200) {
        dispatch(openNotificationAlert({ message: "success", type: "success" }));
        dispatch(getAllProbationQuestionSet({ idCompany }));
        handleClose();
      } else {
        dispatch(openNotificationAlert({ message: "error", type: "error" }));
      }
    }
  };

  const { fields, append, remove } = useFieldArray({
    rules: { maxLength: 10 },
    control: control,
    name: "questionList",
  });

  useEffect(() => {
    if (idCompany) {
      dispatch(getEmploymentTypeByidCompany({ idCompany }));
      dispatch(getLevelByidCompany({ idCompany }));
    }
  }, [idCompany]);

  useEffect(() => {
    if (selectQuestionSet) {
      reset({
        questionSetName: selectQuestionSet.questionSetName,
        employmentTypes:
          selectQuestionSet.employmentTypes.length > 0
            ? selectQuestionSet.employmentTypes
            : [{ idEmploymentType: "", employmentTypeName: t("All") }],
        groupLevels:
          selectQuestionSet.groupLevels.length > 0
            ? selectQuestionSet.groupLevels
            : [{ idGroupLevel: "", groupLevelName: t("All") }],
        answerScale: selectQuestionSet.answerScale,
        questionList: selectQuestionSet.questions,
        isSelfAssessment: selectQuestionSet.isSelfAssessment,
        isManagerAssessment: selectQuestionSet.isManagerAssessment,
      });
    }
  }, [selectQuestionSet]);

  return (
    <>
      {employmentType && groupLevel && (
        <div>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
            <IconButton onClick={handleClose}>
              <ArrowBackIcon fontSize="inherit" />
            </IconButton>
            <Typography fontSize="24px" fontWeight="500">
              {t("EditProbationQuestion")}
            </Typography>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography fontWeight={500} gutterBottom>
                  {t("QuestionSet")}
                </Typography>
                <Controller
                  control={control}
                  name={"questionSetName"}
                  render={({ field, fieldState }) => (
                    <TextFieldTheme
                      {...field}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error && fieldState.error.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500} gutterBottom>
                  {t("EmploymentType")}
                </Typography>
                <Controller
                  control={control}
                  name={"employmentTypes"}
                  render={({ field, fieldState }) => (
                    <StyledAutocomplete
                      {...field}
                      multiple
                      options={
                        [
                          {
                            idEmploymentType: "",
                            employmentTypeName: t("All"),
                          },
                          ...employmentType,
                        ] || []
                      }
                      onChange={(e, newValue) => {
                        if (e.target.value) {
                          const filterValue = newValue.filter(
                            (item) => item.idEmploymentType
                          );
                          field.onChange(filterValue);
                        } else {
                          field.onChange([
                            {
                              idEmploymentType: "",
                              employmentTypeName: t("All"),
                            },
                          ]);
                        }
                      }}
                      getOptionLabel={(option) => option.employmentTypeName}
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          key={option.idEmploymentType}
                          value={option.idEmploymentType}
                        >
                          {option.employmentTypeName}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          variant="filled"
                          error={Boolean(fieldState.error)}
                          helperText={
                            fieldState.error && fieldState.error.message
                          }
                        />
                      )}
                      noOptionsText={`${t("NoData")}`}
                      isOptionEqualToValue={(option, value) =>
                        option.idEmploymentType === value.idEmploymentType
                      }
                      PopperComponent={StyledPopper}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500} gutterBottom>
                  {t("GroupLevel")}
                </Typography>
                <Controller
                  control={control}
                  name={"groupLevels"}
                  render={({ field, fieldState }) => (
                    <StyledAutocomplete
                      {...field}
                      multiple
                      options={
                        [
                          { idGroupLevel: "", groupLevelName: t("All") },
                          ...groupLevel,
                        ] || []
                      }
                      onChange={(e, newValue) => {
                        if (e.target.value) {
                          const filterValue = newValue.filter(
                            (item) => item.idGroupLevel
                          );
                          field.onChange(filterValue);
                        } else {
                          field.onChange([
                            { idGroupLevel: "", groupLevelName: t("All") },
                          ]);
                        }
                      }}
                      getOptionLabel={(option) => option.groupLevelName}
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          key={option.idGroupLevel}
                          value={option.idGroupLevel}
                        >
                          {option.groupLevelName}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          variant="filled"
                          error={Boolean(fieldState.error)}
                          helperText={
                            fieldState.error && fieldState.error.message
                          }
                        />
                      )}
                      noOptionsText={`${t("NoData")}`}
                      isOptionEqualToValue={(option, value) =>
                        option.idGroupLevel === value.idGroupLevel
                      }
                      PopperComponent={StyledPopper}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight={500} gutterBottom>
                  {t("Evaluator")}
                </Typography>
                <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                  <Controller
                    control={control}
                    name={"isSelfAssessment"}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label={t("SelfAssessment")}
                      />
                    )}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={true} />}
                    label={t("SupervisorAssessment")}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ borderColor: "#9e9e9e" }} />
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight={500} gutterBottom>
                  {t("AnswerScale")}
                </Typography>
                <Controller
                  control={control}
                  name={"answerScale"}
                  render={({ field, fieldState }) => (
                    <FormControl error={Boolean(fieldState.error)}>
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value={5}
                          control={<Radio />}
                          label={`5 ${t("Level")}`}
                        />
                        <FormControlLabel
                          value={6}
                          control={<Radio />}
                          label={`6 ${t("Level")}`}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              {fields.map((question, index) => (
                <Grid container item xs={12} key={question.id} spacing={1} >
                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography fontWeight={500}>{`${t("Question")} ${index + 1}`}</Typography>
                    {fields.length > 1 && (
                      <IconButton color="error" onClick={() => remove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{t("QuestionType")}</Typography>
                    <Controller
                      control={control}
                      name={`questionList.${index}.questionType`}
                      render={({ field, fieldState }) => (
                        <TextFieldTheme
                          {...field}
                          select
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error && fieldState.error.message}
                          disabled={question.idProbationQuestion}
                        >
                          <MenuItem value={"text"}>Text</MenuItem>
                          <MenuItem value={"choice"}>Choice</MenuItem>
                        </TextFieldTheme>
                      )}
                    />
                  </Grid>
                  {watch(`questionList.${index}.questionType`) === "choice" && (
                    <Grid item xs={6}>
                      <Typography>{t("Weight")}</Typography>
                      <Controller
                        control={control}
                        name={`questionList.${index}.weight`}
                        render={({ field, fieldState }) => (
                          <TextFieldTheme
                            {...field}
                            type="number"
                            error={Boolean(fieldState.error)}
                            helperText={fieldState.error && fieldState.error.message}
                            disabled={question.idProbationQuestion}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography>{t("Question")}</Typography>
                    <Controller
                      control={control}
                      name={`questionList.${index}.question`}
                      render={({ field, fieldState }) => (
                        <TextFieldTheme
                          {...field}
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error && fieldState.error.message}
                          disabled={question.idProbationQuestion}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={12}>
                {fields.length < 10 && (
                  <Box className="BoxContent" sx={{ marginTop: "10px" }}>
                    <ButtonBlue
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={() => append({ question: "", questionType: "", weight: "" })}
                    >
                      {t("AddQuestion")}
                    </ButtonBlue>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography fontSize={18} fontWeight={500} textAlign={"end"}>
                  {`${t("TotalWeight")} ${questionWeight.totalWeight}`}
                </Typography>
              </Grid>
              <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
                <ButtonBlue variant="contained" type="submit" disabled={isSubmitting}>
                  {t("Save")}
                </ButtonBlue>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </>
  );
};

export default EditQuestionSet;
