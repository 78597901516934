import Quill from 'quill';
const Inline = Quill.import('blots/inline');

class MentionBlot extends Inline {
  static create(value) {

    let node = super.create();

    if (!value || typeof value.id === 'undefined' || typeof value.value === 'undefined') {
      console.error('Invalid value provided to MentionBlot:', value);
      return null; 
    }

    const mentionValue = value.value.replace(/x$/, ''); 
    node.setAttribute('data-id', value.id);
    node.setAttribute('data-value', mentionValue);
    const fontSize = parseInt(value.size) || 18;
    node.style.fontSize = `${fontSize}px`;
    
    if (value.width && value.width !== '') {
      node.style.width = value.width;
    } else {
      node.style.width = ''; 
    }

    MentionBlot.updateNodeAttributes(node, mentionValue,  fontSize);
    return node;
  }

  static updateNodeAttributes(node,  mentionValue,  fontSize) {

    node.style.border = '1px solid #0056b3';
    node.style.backgroundColor = '#e0f7fa';
    node.style.borderRadius = '4px';
    node.style.padding = `${fontSize * 0.2}px ${fontSize * 0.3}px ${fontSize * 0.1}px ${fontSize * 0.5}px`;
    node.style.display = 'inline-block';
    node.style.position = 'relative';
    node.style.resize = 'none';
    node.style.minWidth = 'fit-content';
    node.style.minHeight = '30px';
    node.style.maxWidth = '100%';
    node.style.boxSizing = 'border-box';
    node.style.verticalAlign = 'middle';
    node.style.lineHeight = '1.5'; 
    node.setAttribute('contenteditable', 'false');

    node.innerHTML = '';

    const textNode = document.createElement('span');
    textNode.textContent = `#${mentionValue}`;
    textNode.style.lineHeight = '1.5';
    textNode.contentEditable = 'false';
    node.appendChild(textNode);

    const removeIcon = document.createElement('span');
    removeIcon.innerHTML = '&times;';
    removeIcon.style.position = 'absolute';
    removeIcon.style.top = '-5px';
    removeIcon.style.left = '3px';
    removeIcon.style.cursor = 'pointer';
    removeIcon.style.fontSize = '18px';
    removeIcon.style.color = '#0056b3';
    removeIcon.style.zIndex = '10';
    removeIcon.setAttribute('contenteditable', 'false');

    removeIcon.addEventListener('click', (e) => {
      e.stopPropagation();
      const blotParent = node.parentNode;
      if (blotParent) {
        blotParent.removeChild(node);
      }
    });

    const editIcon = document.createElement('span');
    editIcon.innerHTML = '✎';
    editIcon.className = 'edit-icon';
    editIcon.style.cursor = 'pointer';
    editIcon.style.position = 'absolute';
    editIcon.style.top = '0px';
    editIcon.style.right = '0px';
    editIcon.style.fontSize = '14px';
    editIcon.style.color = '#0056b3';
    editIcon.style.zIndex = '10';
    editIcon.setAttribute('contenteditable', 'false');

    let isEditing = false;

    editIcon.addEventListener('click', (e) => {
      e.stopPropagation();
      isEditing = !isEditing;
      if (isEditing) {
        node.style.resize = 'horizontal';
        node.style.overflow = 'auto'; 
        editIcon.style.color = '#d9534f';
      } else {
        node.style.resize = 'none';
        node.style.overflow = 'hidden'; 
        editIcon.style.color = '#0056b3'; 
        
        const quill = Quill.find(node);
        const value = {
          id: node.getAttribute('data-id'),
          value: node.getAttribute('data-value'),
          width: node.style.width
        };
        if (quill) {
          findAndUpdateBlot(quill, value);
        }
      }
    });

    function findAndUpdateBlot(quill, value) {      
      if (!quill || typeof quill.getSelection !== 'function') {
        console.error("Quill instance is not initialized or doesn't have getSelection method");
        return;
      }
    
      let range = quill.getSelection();
    
      if (!range) {
        range = { index: quill.getLength(), length: 0 };
      }
    
      const [blot] = quill.getLeaf(range.index);
      if (blot instanceof MentionBlot) {
        MentionBlot.updateNodeAttributes(blot.domNode, value);
      } 
      else {
        quill.insertEmbed(range.index, 'mention', value);
      }
    }

    node.appendChild(removeIcon);
    node.appendChild(editIcon);
  }

  static formats(node) {
    return {
      id: node.getAttribute('data-id'),
      value: node.getAttribute('data-value'),
      width: node.style.width || null,
      size: node.style.fontSize || null,
    };
  }
}

MentionBlot.blotName = 'mention';
MentionBlot.tagName = 'span';
MentionBlot.className = 'mention-blot';

export default MentionBlot;
