import React, { useState } from "react";
import { Typography, Grid, Box, IconButton, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addProbationQuestion, getProbationQuestion } from "../../../../../actions/probation";
import { useTranslation } from "react-i18next";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
  "& .btn-save": {
    marginTop: 16,
    display: "flex",
    justifyContent: "flex-end",
  },
});

const DialogAddQuestion = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { open, handleClose, selectedCompany, isReadOnly } = props;

  const [idQuestionList, setIdQuestionList] = useState([]);

  const validationSchema = yup.object().shape({
    questionList: yup.array().of(
      yup.object().shape({
        question: yup
        .string()
        .max(1000, `${t("NotMoreThan")} 1000 ${t("Characters")}`)
        .required(`${t("ThisFieldIsRequired")}`),
      })
    ),
  });

  const {
    control,
    handleSubmit,
  } = useForm({
    defaultValues: {
      questionList: [{ question: "" }],
    },
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (formData) => {
    const cleansingForm = {
      questionList: formData.questionList,
      idCompany: selectedCompany.idCompany
    };

    const res = await dispatch(addProbationQuestion(cleansingForm));
    if (res && res.status === 201) {
      dispatch(openNotificationAlert({ message: "success", type: "success" }));
    } else {
      dispatch(openNotificationAlert({ message: "error", type: "error" }));
    }
    dispatch(getProbationQuestion({ idCompany: selectedCompany.idCompany }));
    handleClose();
  };

  const questionUseFieldArray = useFieldArray({
    rules: { maxLength: 10 },
    control: control,
    name: "questionList",
  });

  const initialValueQuestionData = {
    question: "",
  };

  return (
    <DrawerCustom
      title={t("AddProbationReviewQuestion")}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot>
        <Grid container spacing={2} padding={2} style={{ marginBottom: 16 }}>
          {questionUseFieldArray.fields.map((question, index) => (
            <Grid key={question.id} container className="GridContact">
              {questionUseFieldArray.fields.length > 1 && (
                <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
                  <div>
                    <i className="fa-regular fa-square-question fa-xl"></i>
                    &nbsp;&nbsp;{`${t("Question")} ${index + 1}`}
                  </div>
                  <IconButton
                    color="error"
                    onClick={() => {
                      questionUseFieldArray.remove(index);
                      setIdQuestionList([
                        ...idQuestionList,
                        question.idQuestionProbation,
                      ]);
                    }}
                  >
                    <i className="fa-regular fa-trash-can"></i>
                  </IconButton>
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={`questionList.${index}.question`}
                  render={({ field, fieldState }) => (
                    <TextFieldTheme
                      {...field}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error && fieldState.error.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider
                  sx={{
                    marginTop: "16px",
                    borderColor: "#9e9e9e66",
                  }}
                />
              </Grid>
            </Grid>
          ))}
          {questionUseFieldArray.fields.length < 10 && (
            <Box className="BoxContent" sx={{ marginTop: "10px" }}>
              <ButtonBlue
                variant="outlined"
                startIcon={<i className="fa-regular fa-plus"></i>}
                onClick={() => {
                  questionUseFieldArray.append(initialValueQuestionData);
                }}
              >
                {t("AddQuestion")}
              </ButtonBlue>
            </Box>
          )}
          {questionUseFieldArray.fields.length === 0 && (
            <Grid item xs={12}>
              <Typography textAlign="center" color="text.secondary">
                {t("NoQuestion")}
              </Typography>
            </Grid>
          )}
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleClose}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            autoFocus
            disabled={isReadOnly}
          >
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogAddQuestion;
