import React, { useEffect, useState } from "react";
import { styled } from "@mui/styles";
import {
  Grid,
  TextField,
  InputAdornment,
  Container,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Box,
  FormHelperText,
  IconButton,
  Chip,
  Typography,
  Autocomplete,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
import StarIcon from "@mui/icons-material/Star";
import Crop32Icon from "@mui/icons-material/Crop32";
import { useHistory, useLocation } from "react-router-dom";
import DialogDropzone from "../DialogDropzoneKpi";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AlertResponse from "../../../shared/general/AlertResponse";
import DatePickerCustom from "../../../shared/date/datePicker";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { addManagerKPI, updateManagerKPI } from "../../../../../actions/kpi";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";
import { getManager } from "../../../../../actions/manager";
import { getUserFullName } from "../../../../../utils/userData";

const ContainerStyled = styled(Container)({
  minHeight: "100vh",
  height: "100%",
  paddingTop: "100px",
});

const initialValues = {
  kpiName: "",
  scope: "",
  target: "",
  measureType: "",
  evidence: "",
  description: "",
  weight: "",
  asessor: null,
  startDate: dayjs(),
  endDate: dayjs().add(1, "day"),
};

const validationSchema = yup.object({
  kpiName: yup.string().max(500).required(),
  scope: yup.string().max(500).required(),
  target: yup.string().max(500).required(),
  evidence: yup.string().max(500).required(),
  description: yup.string().max(500).required(),
  measureType: yup.string().required(),
  weight: yup.number().required(),
  asessor: yup.object().required(),
  startDate: yup.date().required(),
  endDate: yup.date().when("startDate", (startDate) => {
    return yup.date().min(dayjs(startDate).add(1, "day"));
  }),
});

function KpisManagerPlan() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: manager } = useSelector((state) => state.manager);
  const [totalWeight, setTotalWeight] = useState(state.totalWeight);
  console.log("Total Weight:", totalWeight);

  const { control, handleSubmit, watch } = useForm({
    defaultValues: state.planList || initialValues,
    resolver: yupResolver(validationSchema),
  });

  const handleOnSubmit = async (formData) => {
    formData.asessor = formData.asessor.idEmployees;
    formData.idEmployees = userProfile.idEmployees;

    let res = null;
    if (formData.idKPI) {
      res = await dispatch(updateManagerKPI(formData.idKPI, formData));
    } else {
      res = await dispatch(addManagerKPI(formData));
    }
    
    if (res && res.status === 201 || res && res.status === 200 ) { 
      Swal.fire({
        title: 'สำเร็จ',
        text: formData.idKPI ? 'การแก้ไขข้อมูลสำเร็จแล้ว' : 'การเพิ่มข้อมูลสำเร็จแล้ว',
        icon: 'success',
        timer: 2000,
        showConfirmButton: false
      });
    } else {
      Swal.fire({
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถบันทึกข้อมูลได้',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      });
    }
    history.push({ pathname: '/kpi-admin-evalution', state })
  };

  const showAlert = async (formData) => {
    const result = await Swal.fire({
      title: t("ConfirmSaveData"),
      text: t("DoYouWantToSaveThisData"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirm"),
      cancelButtonText: t("Cancel"),
    });

    if (result.isConfirmed) {
      handleOnSubmit(formData);
    }
  };

  const [openDialog, setDialog] = useState(false);

  const handleCloseDialogDropzoneKpi = () => {
    setDialog(false);
  };

  const [openAlert, setOpenAlert] = useState({
    open: false,
    alertType: null,
    label: null,
  });

  const handleOpenAlert = (alertType, label) => {
    setOpenAlert({
      open: true,
      alertType: alertType,
      label: label,
    });
  };

  const handleCloseAlert = () => {
    setOpenAlert({
      open: false,
      alertType: null,
      label: null,
    });
  };

  useEffect(() => {
    if (userProfile) {
      dispatch(getManager());
    }
  }, [userProfile]);

  return (
    <ContainerStyled>
      {userProfile && (
        <Box paddingBottom="16px" marginBottom="16px" borderRadius="15px" bgcolor="#ffffff">
          <div className="header-section">
            <Grid container>
              <Grid item>
                <h3 className="header-topic">{t("KPIAdminPlanning")}</h3>
              </Grid>
            </Grid>
          </div>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="0px 16px"
            marginBottom="16px"
          >
            {/* {state.planList ? (
              <StatusChip status={state.planList.isApprove} />
            ) : (
              <Typography fontSize={20}>Add KPI</Typography>
            )} */}
          </Box>
          <Box paddingLeft={"16px"} paddingRight={"16px"}>
            <form onSubmit={handleSubmit(showAlert)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="kpiName"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("KPIName")}
                        placeholder={t("KPIName")}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error && fieldState.error.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <StarIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="scope"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("Scope")}
                        placeholder={t("Scope")}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error && fieldState.error.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Crop32Icon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="target"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("Target")}
                        placeholder={t("Target")}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error && fieldState.error.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Crop32Icon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="evidence"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("Evidence")}
                        placeholder={t("Evidence")}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error && fieldState.error.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Crop32Icon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("Descriptions")}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error && fieldState.error.message}
                        multiline
                        rows={3}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="measureType"
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth error={Boolean(fieldState.error)}>
                        <InputLabel>{t("TypeMeasurementData")}</InputLabel>
                        <Select {...field} label={t("TypeMeasurementData")}>
                          <MenuItem value={"number"}>{t("Number")}</MenuItem>
                          <MenuItem value={"explanation"}>{t("Explanation")}</MenuItem>
                        </Select>
                        {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="weight"
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth error={Boolean(fieldState.error)}>
                        <InputLabel>{t("Weight")}</InputLabel>
                        <Select
                          {...field}
                          label={t("Weight")}
                        >
                          {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50]
                            .filter((value) => value <= 100 - totalWeight)
                            .map((value) => (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                        </Select>
                        {fieldState.error && (
                          <FormHelperText>{fieldState.error.message}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="asessor"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        {...field}
                        options={manager ? manager : []}
                        getOptionLabel={(option) => `${getUserFullName(option)}`}
                        isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("Assessor")}
                            helperText={fieldState.error && fieldState.error.message}
                            error={fieldState.error}
                          />
                        )}
                        onChange={(_, value) => field.onChange(value)}
                        noOptionsText={t("NoData")}
                      />
                    )}
                  />
                </Grid>
                <Grid container item spacing={2} xs={12}>
                  <Grid item xs={12} sm={6} md={3}>
                    <Controller
                      name="startDate"
                      control={control}
                      render={({ field }) => (
                        <DatePickerCustom
                          {...field}
                          variant="outlined"
                          label={t("StartDate")}
                          minDate={dayjs().startOf("year")}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Controller
                      name="endDate"
                      control={control}
                      render={({ field }) => (
                        <DatePickerCustom
                          {...field}
                          variant="outlined"
                          label={t("EndDate")}
                          minDate={dayjs(watch("startDate"))}
                          maxDate={dayjs().endOf("year")}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="flex-end" gap="8px">
                  {state.planList && (
                    <ButtonBlue type="button" color="error" onClick={() => history.push("/kpi-individual")}>
                      {t("Cancel")}
                    </ButtonBlue>
                  )}
                  <ButtonBlue type="submit" variant="contained">
                    {t("Save")}
                  </ButtonBlue>
                </Grid>
              </Grid>
            </form>
            {openDialog && (
              <DialogDropzone
                open={openDialog}
                onClose={handleCloseDialogDropzoneKpi}
                handleOpenAlert={handleOpenAlert}
              />
            )}
            <AlertResponse
              open={openAlert.open}
              handleClose={handleCloseAlert}
              alertType={openAlert.alertType}
              label={openAlert.label}
            />
          </Box>
        </Box>
      )}
    </ContainerStyled>
  );
}

export default KpisManagerPlan;
