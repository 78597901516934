import {
    GET_ALL_DOCUMENT_GROUP_NAME_FAILURE,
    GET_ALL_DOCUMENT_GROUP_NAME_FETCHING,
    GET_ALL_DOCUMENT_GROUP_NAME_SUCCESS,
  } from "../actions/types";
  
  const initialState = {
    result: null,
    isFetching: false,
    isError: false,
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case GET_ALL_DOCUMENT_GROUP_NAME_FETCHING:
        return { ...state, result: null, isFetching: true, isError: false };
      case GET_ALL_DOCUMENT_GROUP_NAME_FAILURE:
        return { ...state, result: null, isFetching: false, isError: true };
      case GET_ALL_DOCUMENT_GROUP_NAME_SUCCESS:
        return { ...state, result: payload, isFetching: false, isError: false };
      default:
        return state;
    }
  }