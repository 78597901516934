import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import dayjs from "dayjs";
import "dayjs/locale/th";

import ButtonOrange from "../../shared/general/ButtonOrange";
import DatePickerWeek from "../../shared/general/DatePickerWeek";
import TableOTReport from "./tableOTReport";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import FilterListIcon from "@mui/icons-material/FilterList";

import { getAllTimeline } from "../../../../actions/event";
import { Grid } from "@mui/material";
import ButtonBlue from "../../shared/general/ButtonBlue";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledTypography = styled(Typography)({
  display: "flex",
  alignItems: "center",
});

const StyledFormControl = styled(FormControl)({
  "& .Mui-focused": {
    color: "#007afd !important",
  },
  "& .Mui-checked": {
    color: "#007afd !important",
  },
});

const StyledLink = styled(Link)({
  textDecoration: "none",
});

const StyledCard = styled(Card)({
  marginBottom: 16,
  padding: 16,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .MuiCardContent-root": {
    padding: 24,
  },
});

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 16,
});

function OTReport() {
  const today = dayjs().toDate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [value, setValue] = React.useState(today);
  const [allFilter, setAllFilter] = React.useState(false);
  const [filter, setFilter] = React.useState([
    {
      name: "normal",
      checked: true,
      lable: `${t("Leave")}`,
    },
    {
      name: "incomplate",
      checked: true,
      lable: `${t("WorkingTimeRequest")}`,
    },
    {
      name: "ot",
      checked: true,
      lable: `${t("Cancel")}`,
    },
  ]);
  const [events, setEvents] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChange = (event) => {
    const temp = [...filter];
    for (let index = 0; index < temp.length; index++) {
      if (temp[index].name === event.target.name) {
        temp[index].checked = !temp[index].checked;
        break;
      }
    }

    setFilter(temp);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    async function getdata() {
      let result = await dispatch(getAllTimeline());
      if (result) {
        setEvents(result);
      }
    }
    getdata();
  }, []);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          {`${t("Overtimereport")}`}
        </Typography>
        <div style={{ marginTop: 24, marginBottom: 24 }}>
          <div style={{ marginTop: 24, marginBottom: 24 }}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <DatePickerWeek setValue={setValue} value={value} />
              </Grid>
            </Grid>
          </div>
          <StyledCard style={{ paddingBottom: 32 }}>
            <TableOTReport value={value} />
          </StyledCard>
        </div>
      </Container>
    </StyledRoot>
  );
}

export default OTReport;
