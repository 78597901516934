import { ADMINS_FETCHING, ADMINS_FAILED, ADMINS_SUCCESS } from "./types";
import EmployeeService from "../services/employee.service";

export const getEmployeesRoleAdmin = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMINS_FETCHING,
    });
    const res = await EmployeeService.getAllEmployeesRoleAdmin();
    if (res) {
      dispatch({
        type: ADMINS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ADMINS_FAILED,
    });
  }
};
