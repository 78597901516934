import {
  EXPENSE_EXPORT_FETCHING,
  EXPENSE_EXPORT_FAILED,
  EXPENSE_EXPORT_SUCCESS,
  EXPENSE_EXPORT_WELFARE_FETCHING,
  EXPENSE_EXPORT_WELFARE_FAILED,
  EXPENSE_EXPORT_WELFARE_SUCCESS,
} from "./types";

import expenseExportService from "../services/expenseExport.service";

export const getDataEmployee = () => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_EXPORT_FETCHING,
    });
    const res = await expenseExportService.getDataEmployee();
    if (res) {
      dispatch({
        type: EXPENSE_EXPORT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EXPENSE_EXPORT_FAILED,
    });
    console.log(err);
  }
};

export const getDataWelfare = () => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_EXPORT_WELFARE_FETCHING,
    });
    const res = await expenseExportService.getDataWelfare();
    if (res) {
      dispatch({
        type: EXPENSE_EXPORT_WELFARE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EXPENSE_EXPORT_WELFARE_FAILED,
    });
    console.log(err);
  }
};
