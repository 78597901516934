import React from 'react';
import { Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, styled } from '@mui/material';
import { t } from 'i18next';

function OvertimeSummaryTable({ totalOvertime }) {
    const getDisplayValue = (value) => (value !== undefined ? value : '-');

    const StyledTable = styled(TableContainer)({
        borderRadius: '6px',
        "& .tableCellHead": {
            border: "none",
            backgroundColor: "#46cbe2",
            color: "#ffffff",
            fontSize: 16,
            textAlign: "center",
            padding: "18px",
        },

        "& .tableCellLable": {
            textAlign: "left",
        },

        "& .tableCellRegular": {
            textAlign: "center",
            fontWeight: "500",
        },

        "& .tableCellIrregular": {
            textAlign: "center",
            fontWeight: "500",
        },

        "& .green": {
            color: "#43a047",
            fontWeight: "700",
            textDecoration: "underline",
        },

        "& .blue": {
            color: "#3589e5",
            fontWeight: "600",
            textDecoration: "underline",
        },

        "& .red": {
            color: "#e53935",
            fontWeight: "600",
            textDecoration: "underline",
        },

        "& .purple": {
            color: "#9135e5",
            fontWeight: "600",
            textDecoration: "underline",
        },

        "& .orange": {
            color: "#e59135",
            fontWeight: "600",
            textDecoration: "underline",
        },

        "& .pink": {
            color: "#e53589",
            fontWeight: "600",
            textDecoration: "underline",
        },
    });

    return (
        <StyledTable sx={{ boxShadow: 2, }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className="tableCellHead" align="center"></TableCell>
                        <TableCell className="tableCellHead" align="center">OT {t('Total')} ({t('Unit.Hours')})</TableCell>
                        <TableCell className="tableCellHead" align="center">OT {t('Total')} ({t('Baht')})</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* OT1 Row */}
                    <TableRow>
                        <TableCell align="center">OT 1</TableCell>
                        <TableCell align="center">{getDisplayValue(totalOvertime.otOneHours)}</TableCell>
                        <TableCell align="center">{getDisplayValue(totalOvertime.otOneAmount)}</TableCell>
                    </TableRow>

                    {/* OT1.5 Row */}
                    <TableRow>
                        <TableCell align="center">OT 1.5</TableCell>
                        <TableCell align="center">{getDisplayValue(totalOvertime.otOneFiveHours)}</TableCell>
                        <TableCell align="center">{getDisplayValue(totalOvertime.otOneFiveAmount)}</TableCell>
                    </TableRow>

                    {/* OT2 Row */}
                    <TableRow>
                        <TableCell align="center">OT 2</TableCell>
                        <TableCell align="center">{getDisplayValue(totalOvertime.otTwoHours)}</TableCell>
                        <TableCell align="center">{getDisplayValue(totalOvertime.otTwoAmount)}</TableCell>
                    </TableRow>

                    {/* OT3 Row */}
                    <TableRow>
                        <TableCell align="center">OT 3</TableCell>
                        <TableCell align="center">{getDisplayValue(totalOvertime.otThreeHours)}</TableCell>
                        <TableCell align="center">{getDisplayValue(totalOvertime.otThreeAmount)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </StyledTable>
    );
}

export default OvertimeSummaryTable;