import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrganizationObjective,
  updateOrganizationObjective,
  updateIndividualObjective,
  getAllIndividualObjectiveByIdEmp,
  updateTeamObjective,
  getOrganizationObjectives,
} from "../../../../../../actions/okrs";
import Swal from "sweetalert2";

const ResponsiveButtonBlue = styled(Button)({
  backgroundColor: "rgba(219, 65, 120, 1)",
  "&:hover": {
    backgroundColor: "#C53B6C",
  },
});

const DialogEditObjective = ({
  open,
  handleClose,
  data,
  activeTab,
  onObjectiveUpdated,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    objectiveName: "",
    impactName: "",
  });
  const { result: userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    if (data && userProfile && userProfile.roles) {
      if (userProfile.roles.includes("ROLE_ADMIN")) {
        setFormData({
          idOrganizationObjective: data.idOrganizationObjective,
          objectiveName: data.objectiveName,
          impactName: data.impactName,
        });
      } else if (userProfile.idRole === 1) {
        setFormData({
          idIndividualObjective: data.idIndividualObjective,
          objectiveName: data.objectiveName,
          impactName: data.impactName,
        });
      } else if (userProfile.idRole === 2) {
        if (activeTab === 1) {
          setFormData({
            idTeamObjective: data.idTeamObjective,
            objectiveName: data.objectiveName,
            impactName: data.impactName,
          });
        } else if (activeTab === 2 || activeTab === 3) {
          setFormData({
            idIndividualObjective: data.idIndividualObjective,
            objectiveName: data.objectiveName,
            impactName: data.impactName,
          });
        }
      }
    }
  }, [data, userProfile]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let res;
    if (userProfile.roles.includes("ROLE_ADMIN")) {
      res = await dispatch(updateOrganizationObjective(formData));
    } else if (
      userProfile.idRole === 1 ||
      (userProfile.idRole === 2 && activeTab === 2)
    ) {
      res = await dispatch(updateIndividualObjective(formData));
    } else if (userProfile.idRole === 2) {
      res = await dispatch(updateTeamObjective(formData));
    }

    if (res && res.status === 200) {
      Swal.fire({
        icon: "success",
        title: t("Successfully"),
        showConfirmButton: false,
        timer: 1500,
      });

      if (typeof onObjectiveUpdated === "function") {
        onObjectiveUpdated();
      }

      // dispatch(
      //   openNotificationAlert({ type: "success", message: t("Successfully") })
      // );
      if (userProfile.roles.includes("ROLE_ADMIN")) {
        await dispatch(getOrganizationObjectives());
      } else {
        await dispatch(
          getAllIndividualObjectiveByIdEmp(userProfile.idEmployees)
        );
      }
      handleClose();
    } else {
      Swal.fire({
        icon: "error",
        title: t("AnErrorOccurred"),
        showConfirmButton: false,
        timer: 1500,
      });

      // dispatch(
      //   openNotificationAlert({ type: "error", message: t("AnErrorOccurred") })
      // );
    }
    handleClose();
  };

  console.log(data);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{t("Descriptions")}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            name="objectiveName"
            label={t("Objectives")}
            value={formData.objectiveName}
            onChange={handleChange}
            inputProps={{ maxLength: 200 }}
            fullWidth
            margin="normal"
          />
          <TextField
            name="impactName"
            label={t("TheResultingOutcome")}
            value={formData.impactName}
            onChange={handleChange}
            inputProps={{ maxLength: 200 }}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("Close")}
          </Button>
          <ResponsiveButtonBlue variant="contained" type="submit">
            {t("Edit")}
          </ResponsiveButtonBlue>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogEditObjective;
