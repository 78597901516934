import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import NumberFormat from "react-number-format";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import {
  Grid,
  Typography,
  FormControl,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  OutlinedInput,
  TextField,
  Divider,
  Popper,
} from "@mui/material";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import CalculateIcon from "@mui/icons-material/Calculate";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import DrawerCustom from "../../../../shared/general/Drawer";

// import {
//   getPaymentType,
//   getEmployeeProfile,
// } from "../../../../../../actions/employee";
import {
  getEmployeeProfile,
  getEmployeeEmployeeTypeChange,
  addDeleteEmployeeEmploymentType,
  updateEmployeeEmployeeTypeChange,
} from "../../../../../../actions/employee";
import DatePickerCustom from "../../../../shared/date/datePicker";
// import { updateUserNTable, updateUser } from "../../../../../../actions/user";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
  paddingTop: 16,
  "& .wrap": {
    marginTop: 8,
  },
});

const StyledSalary = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const initialValues = () => ({
  effectiveDate: new Date(),
  reason: "",
});

const DialogEdit = (props) => {
  const { open, handleCloseDialog, drawerConfig } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: employmentTypeList } = useSelector(
    (state) => state.employmentType
  );

  const [paymentTypeList, setPaymentTypeList] = useState(null);
  const [formData, setFormData] = useState(initialValues);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log({ name, value });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // useEffect(() => {
  //   async function fetch(params) {
  //     const result = await dispatch(getPaymentType());
  //     if (result) {
  //       setPaymentTypeList(result.data);
  //     } else {
  //     }
  //   }

  //   fetch();
  // }, []);

  useEffect(() => {
    if (open) {
      if (!drawerConfig.isEdit) {
        setFormData({
          idEmployees: employeeProfile.idEmployees,
          employeeID: employeeProfile.employeeID,
          idEmployeeEmploymentTypeOld: employeeProfile.idEmployeeEmploymentType
            ? employeeProfile.idEmployeeEmploymentType
            : null,
          idEmploymentType: null,
          start: new Date(),
          remark: "",
        });
      } else {
        // console.log(drawerConfig.data)
        // console.log(drawerConfig.data.idEmploymentType? employmentTypeList.find(x => x.idEmploymentType === drawerConfig.data.idEmploymentType) || null: null,)
        setFormData({
          idEmployees: employeeProfile.idEmployees || "",
          employeeID: drawerConfig.data.employeeID || "",
          // idEmployeeEmploymentTypeOld: employeeProfile.idEmployeeEmploymentType
          //   ? employeeProfile.idEmployeeEmploymentType
          //   : null,
          // idEmploymentType: drawerConfig.data.idEmploymentType? employmentTypeList.find(x => x.idEmploymentType === drawerConfig.data.idEmploymentType) || null: null,
          idEmploymentType: drawerConfig.data.idEmploymentType,
          start: new Date(drawerConfig.data.start),
          remark: drawerConfig.data.remark,
        });
      }
    }
  }, [open]);

  const handleSubmit = async () => {
    let result = null;
    // console.log(formData);
    // console.log(drawerConfig.data.idEmployeeEmploymentType);
    if (drawerConfig.isEdit) {
      let tempFormData = { ...formData };
      tempFormData.start = dayjs(tempFormData.start).format("YYYY-MM-DD");
      tempFormData.updateDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
      result = await dispatch(
        updateEmployeeEmployeeTypeChange(
          employeeProfile.idEmployees,
          drawerConfig.data.idEmployeeEmploymentType,
          tempFormData
        )
      );
    } else {
      let tempFormData = { ...formData };
      tempFormData.start = dayjs(tempFormData.start).format("YYYY-MM-DD");
      tempFormData.method = "add";
      result = await dispatch(addDeleteEmployeeEmploymentType(tempFormData));
    }

    if (result) {
      handleCloseDialog();
      dispatch(getEmployeeEmployeeTypeChange(employeeProfile.idEmployees));
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
    }
  };

  return (
    <DrawerCustom
      title={`${t("EditEmploymentType")}`}
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        <Grid container>
          <Grid item xs={12}>
            <StyledContentLabel>{t("CurrentlyData")}</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="wrap">
              <StyledHeadLabel color="text.secondary">
                {t("EmploymentType")}
              </StyledHeadLabel>
              <Typography className="present">
                {employeeProfile.employmentTypeName
                  ? employeeProfile.employmentTypeName
                  : "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="wrap">
              <StyledHeadLabel color="text.secondary">
                {t("EmployeeID")}
              </StyledHeadLabel>
              <Typography className="present">
                {employeeProfile.employeeID ? employeeProfile.employeeID : "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <StyledDivider />
          </Grid>

          <Grid item xs={12}>
            <div>
              <StyledContentLabel gutterBottom>
                {t("NewEmploymentType")}
              </StyledContentLabel>
              <StyledAutocomplete
                options={employmentTypeList ? employmentTypeList : []}
                // value={formData.idEmploymentType}
                value={
                  formData.idEmploymentType
                    ? employmentTypeList.find(
                        (x) => x.idEmploymentType === formData.idEmploymentType
                      ) || null
                    : null
                }
                onChange={(event, newValue) => {
                  console.log(newValue);
                  setFormData({
                    ...formData,
                    ["idEmploymentType"]: newValue.idEmploymentType,
                  });
                }}
                popupIcon={<i class="fa-light fa-chevron-down"></i>}
                getOptionLabel={(option) => option.employmentTypeName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    placeholder="เลือกประเภทการจ้าง"
                  />
                )}
                PopperComponent={StyledPopper}
                noOptionsText="ไม่มีข้อมูล"
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="wrap">
              <StyledContentLabel gutterBottom>
                {t("EmployeeID")}
              </StyledContentLabel>
              <StyledSalary variant="outlined" fullWidth>
                <OutlinedInput
                  name="employeeID"
                  value={formData.employeeID}
                  onChange={handleInputChange}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  fullWidth
                />
              </StyledSalary>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="wrap">
              <StyledContentLabel gutterBottom>
                {t("EffectiveDate")}
              </StyledContentLabel>
              <DatePickerCustom
                value={formData.start}
                inputFormat="DD/MM/YYYY"
                onChange={(date) => {
                  setFormData({
                    ...formData,
                    start: date,
                  });
                }}
                disabled={drawerConfig.isEdit}
                renderInput={(params) => (
                  <StyledTextField fullWidth {...params} />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="wrap">
              <StyledContentLabel gutterBottom>
                {t("Description")}
              </StyledContentLabel>
              <TextFieldTheme
                variant="outlined"
                name="remark"
                value={formData.remark}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={2}
              />
            </div>
          </Grid>
        </Grid>
        <StyledFooter>
          <ButtonBlue
            className="cancel"
            variant="text"
            onClick={handleCloseDialog}
          >
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit}>
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
