import {
  DIALOGUERECORD_FETCHING,
  DIALOGUERECORD_FAILED,
  DIALOGUERECORD_SUCCESS,
} from "./types";

import DialogueRecordsService from "../services/DialogueRecords.service";

export const getAllDialogueRecords = () => async (dispatch) => {
  try {
    const res = await DialogueRecordsService.getAllDialogueRecords();
    if (res) {
      dispatch({
        type: DIALOGUERECORD_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: DIALOGUERECORD_FAILED,
    });
    console.log(err);
  }
};

export const postDialogueRecords = (formData) => async () => {
  try {
    const res = await DialogueRecordsService.postDialogueRecords(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};
