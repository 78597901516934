import React, { Fragment } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";

import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";

import StatusRequest from "./stausRequest";
import fileService from "../../../../services/file.service";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../utils/userData";

const StyledTable = styled(Table)({
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
    padding: 8,
  },
  "& .tableCellHead:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    // boxShadow: "rgb(255 255 255) 8px 0px 0px inset",
  },
  "& .tableCellHead:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    // boxShadow: "rgb(255 255 255) -8px 0px 0px inset",
  },
  "& .tableCell": {
    //borderColor: "#919eab3d",
    padding: 8,
    borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  },
  "& .cellCreateDate": {
    textAlign: "center",
    width: 100,
    "& .MuiTypography-body2": {
      fontWeight: 500,
    },
  },
  "& .cellStatusAction": {
    minWidth: 50,
  },
  "& .cellRequest": {
    textAlign: "center",
    minWidth: 150,
    maxWidth: 220,
  },
  "& .cellStartStop": {
    minWidth: 160,
  },
  "& .reason": {
    minWidth: 180,
  },
  "& .cellManager": {
    minWidth: 180,
    "& .MuiTypography-body2": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: 180,
    },
  },
});

const StyledChip = styled(Chip)({
  height: 20,
  backgroundColor: "#ff3034",
  marginRight: 8,
  marginBottom: 4,
  "& .MuiChip-label": {
    paddingLeft: 8,
    paddingRight: 8,
    color: "#FFFFFF",
    fontWeight: 600,
  },
});
const StyledBadge = styled(Badge)({
  "& .MuiBadge-anchorOriginTopRightRectangular": {
    backgroundColor: "#ff3034",
  },
});

export default function TableRequest(props) {
  const { list, handleClickWithDraw, handleOpenDialogCommentManager } = props;
  const { t, i18n } = useTranslation();
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );

  const whatLevel = (row) => {
    if (row.isDoubleApproval) {
      if (row.isManagerLV1Approve === 1) {
        return 2;
      } else {
        return 1;
      }
    } else {
      return null;
    }
  };

  const isRequestApprove = (row) => {
    if (row.idRequestTimeWithdraw || row.idLeave) {
      return row.isApprove;
    } else if (row.isWithdraw === 1) {
      return 0;
    } else {
      if (
        row.isManagerLV1Approve === null &&
        row.isManagerLV2Approve === null
      ) {
        return null;
      } else {
        if (row.isDoubleApproval) {
          // console.log("row => 83");
          if (row.isManagerLV1Approve === null) {
            // console.log("row => 85");
            return null;
          } else if (row.isManagerLV1Approve === 1) {
            // console.log("row => 88");
            if (row.isManagerLV2Approve === null) {
              // console.log("row => 90");
              return null;
            } else {
              // console.log("row => 93");
              return row.isManagerLV2Approve;
            }
          } else {
            // console.log("row => 97");
            return row.isManagerLV1Approve;
          }
        } else {
          if (row.approvalLevel === 1) {
            if (row.isManagerLV1Approve === null) {
              return null;
            } else {
              return row.isManagerLV1Approve;
            }
          } else {
            if (row.isManagerLV2Approve === null) {
              return null;
            } else {
              return row.isManagerLV2Approve;
            }
          }
        }
      }
    }
  };

  const disabledWithDraw = (row) => {
    if (row.isManagerLV1Approve === 0 || row.isManagerLV1Approve === 0) {
      return false;
    } else {
      return true;
    }
  };

  const renderOT = (row) => {
    return (
      <Fragment>
        {row.xWorkingDailyHoliday > 0 && (
          <Typography style={{ fontWeight: 500 }} color="text.third">
            OT x {payrollSetting.xWorkingDailyHoliday} ={" "}
            {parseFloat(row.xWorkingDailyHoliday / 60).toFixed(2)}{" "}
            {`${t("Unit.ShortHours")}`}
          </Typography>
        )}
        {row.xWorkingMonthlyHoliday > 0 && (
          <Typography style={{ fontWeight: 500 }} color="text.third">
            OT x {payrollSetting.xWorkingMonthlyHoliday} ={" "}
            {parseFloat(row.xWorkingMonthlyHoliday / 60).toFixed(2)}{" "}
            {`${t("Unit.ShortHours")}`}
          </Typography>
        )}
        {row.xOT > 0 && (
          <Typography style={{ fontWeight: 500 }} color="text.third">
            OT x {payrollSetting.xOT} = {parseFloat(row.xOT / 60).toFixed(2)}{" "}
            {`${t("Unit.ShortHours")}`}
          </Typography>
        )}
        {row.xOTHoliday > 0 && (
          <Typography style={{ fontWeight: 500 }} color="text.third">
            OT x {payrollSetting.xOTHoliday} ={" "}
            {parseFloat(row.xOTHoliday / 60).toFixed(2)}{" "}
            {`${t("Unit.ShortHours")}`}
          </Typography>
        )}
      </Fragment>
    );
  };

  return (
    <TableContainer style={{ width: "100%", minHeight: 450 }}>
      <StyledTable aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              className={`tableCellHead cellStatusAction`}
            >
              {`${t("Status")}`}
            </TableCell>
            {/* <TableCell
              align="center"
              className={`tableCellHead cellCreateDate`}
            >
              วันที่สร้าง
            </TableCell> */}
            <TableCell align="center" className={`tableCellHead cellRequest`}>
              {`${t("List")}`}
            </TableCell>
            <TableCell align="center" className={`tableCellHead cellStartStop`}>
              {`${t("Start")}`}-{`${t("End")}`}
            </TableCell>
            <TableCell align="center" className={`tableCellHead cellRequest`}>
              {`${t("Description")}`}
            </TableCell>
            <TableCell align="center" className={`tableCellHead cellManager`}>
              {`${t("Approver")}`}
            </TableCell>
            <TableCell align="center" className={`tableCellHead cellStartStop`}>
              {`${t("ApprovedDate")}`}
            </TableCell>
            <TableCell
              align="center"
              className={`tableCellHead cellStatusAction`}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list &&
            list.map((row, index) => (
              <TableRow key={index}>
                <TableCell
                  align="center"
                  className={`tableCell cellStatusAction`}
                >
                  <StatusRequest
                    boxSize={40}
                    fontSize={22}
                    borderRadius={8}
                    step={whatLevel(row)}
                    status={
                      (
                        (row.isWithdraw === 1 && !row.idLeave && !row.idLeaveEmployeesWithdraw)
                        || (row.idLeave && !row.idLeaveEmployeesWithdraw && row.isApproveWithdraw === 1)
                      )
                      ? null : isRequestApprove(row)
                    }
                    active={
                      (
                        (row.isWithdraw === 1 && !row.idLeave && !row.idLeaveEmployeesWithdraw)
                        || (row.idLeave && !row.idLeaveEmployeesWithdraw && row.isApproveWithdraw === 1)
                      )
                      ? false : row.isActive}
                  />
                </TableCell>
                <TableCell className={`tableCell cellRequest`}>
                  <div>
                    {(row.idRequestTimeWithdraw ||
                      row.idLeaveEmployeesWithdraw) && (
                      <StyledChip label={`${t("Withdraw")}`} />
                    )}
                  </div>
                  <div>
                    {row.isWithdraw &&
                    (row.isManagerLV1Approve ||
                      row.isManagerLV2Approve ||
                      row.isApprove) ? (
                      <StyledBadge badgeContent=" " variant="dot">
                        {/* <Typography color="text.primary">{row.name}</Typography> */}
                        <Typography color="text.primary">
                          {(row.idRequestTime) ?
                            (
                              (row.idRequestTime && row.name) 
                              ? `${t(row.name)}`
                              : ""
                            )
                            :
                            (
                              i18n.resolvedLanguage === "th"
                              ? row.leaveGroupName
                              : row.leaveGroupName_EN
                              ? row.leaveGroupName_EN
                              : ""
                            )
                          }
                        </Typography>
                        {row.holidayName && (
                          <Typography fontSize={14} color="text.secondary">
                            {row.holidayName}
                          </Typography>
                        )}
                      </StyledBadge>
                    ) : (
                      <Fragment>
                        <Typography color="text.primary">
                          {(row.idRequestTime) ?
                            (
                              (row.idRequestTime && row.name) 
                              ? `${t(row.name)}`
                              : ""
                            )
                            :
                            (
                              i18n.resolvedLanguage === "th"
                              ? row.leaveGroupName
                              : row.leaveGroupName_EN
                              ? row.leaveGroupName_EN
                              : ""
                            )
                          }
                        </Typography>
                        {row.holidayName && (
                          <Typography fontSize={14} color="text.secondary">
                            {row.holidayName}
                          </Typography>
                        )}
                      </Fragment>
                    )}
                    {(row.idRequestType === 2 || row.idRequestType === 3) &&
                      renderOT(row)}
                  </div>
                </TableCell>
                <TableCell align="center" className={`tableCell cellStartStop`}>
                  {row.isFullDay ? (
                    <Fragment>
                      {row.startText === row.endText ? (
                        <Typography>
                          {dayjs(row.startText, "DD/MM/YYYY HH:mm").format(
                            i18n.resolvedLanguage === "th"
                              ? "D/MM/BBBB"
                              : "D/MM/YYYY"
                          )}
                        </Typography>
                      ) : (
                        <Typography>
                          {`${dayjs(row.startText, "DD/MM/YYYY HH:mm").format(
                            i18n.resolvedLanguage === "th"
                              ? "D/MM/BBBB"
                              : "D/MM/YYYY"
                          )} - ${dayjs(row.endText, "DD/MM/YYYY HH:mm").format(
                            i18n.resolvedLanguage === "th"
                              ? "D/MM/BBBB"
                              : "D/MM/YYYY"
                          )}`}
                        </Typography>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div>
                        <Typography>
                          {dayjs(row.startText, "DD/MM/YYYY HH:mm").format(
                            i18n.resolvedLanguage === "th"
                              ? "D/MM/BBBB"
                              : "D/MM/YYYY"
                          )}
                        </Typography>
                        <Typography>
                          {`${dayjs(row.startText, "DD/MM/YYYY HH:mm").format(
                            i18n.resolvedLanguage === "th" ? "HH:mm" : "h:mm A"
                          )} - ${dayjs(row.endText, "DD/MM/YYYY HH:mm").format(
                            i18n.resolvedLanguage === "th" ? "HH:mm" : "h:mm A"
                          )}`}
                        </Typography>
                      </div>
                    </Fragment>
                  )}
                </TableCell>
                <TableCell align="center" className={`tableCell`}>
                  {row.idLeave ? (
                    <Typography color="text.primary">
                      {row.description}
                    </Typography>
                  ) : (
                    <div className="reason">
                      <Typography className="main">
                        {i18n.resolvedLanguage === "th"
                          ? row.reasonName
                          : row.reasonName_EN}
                      </Typography>
                      <Typography className="other" color="text.third">
                        {row.otherReason}
                      </Typography>
                    </div>
                  )}
                </TableCell>
                <TableCell align="center" className={`tableCell cellManager`}>
                  {row.idRequestTimeWithdraw || row.idLeaveEmployeesWithdraw ? (
                    <Fragment>
                      <Typography variant="body2">
                        {i18n.resolvedLanguage === "th"
                          ? `${row.manager_firstname_TH} ${row.manager_lastname_TH}`
                          : `${row.manager_firstname_EN} ${row.manager_lastname_EN}`}
                      </Typography>
                      <Typography color="text.third" variant="body2">
                        {row.manager_email}
                      </Typography>
                    </Fragment>
                  ) : row.idRequestTime ? (
                    <Fragment>
                      {row.approvalLevel === 1 ? (
                        <Fragment>
                          <Typography variant="body2">
                            {getUserFullName({
                              firstname_TH: row.managerLV1_firstname_TH,
                              lastname_TH: row.managerLV1_lastname_TH,
                              firstname_EN: row.managerLV1_firstname_EN,
                              lastname_EN: row.managerLV1_lastname_EN,
                            })}
                          </Typography>
                          <Typography color="text.third" variant="body2">
                            {row.managerLV1_email}
                          </Typography>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Typography variant="body2">
                            {getUserFullName({
                              firstname_TH: row.managerLV2_firstname_TH,
                              lastname_TH: row.managerLV2_lastname_TH,
                              firstname_EN: row.managerLV2_firstname_EN,
                              lastname_EN: row.managerLV2_lastname_EN,
                            })}
                          </Typography>
                          <Typography color="text.third" variant="body2">
                            {row.managerLV2_email}
                          </Typography>
                        </Fragment>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Typography variant="body2">
                        {getUserFullName({
                          firstname_TH: row.manager_firstname_TH,
                          lastname_TH: row.manager_lastname_TH,
                          firstname_EN: row.manager_firstname_EN,
                          lastname_EN: row.manager_lastname_EN,
                        })}
                      </Typography>
                      <Typography color="text.third" variant="body2">
                        {row.manager_email}
                      </Typography>
                    </Fragment>
                  )}
                </TableCell>
                <TableCell align="center" className={`tableCell`}>
                  {row.idRequestTimeWithdraw || row.idLeaveEmployeesWithdraw ? (
                    <Fragment>
                      {row.approveDateText && (
                        <Fragment>
                          <Typography>
                            {`${dayjs(
                              row.approveDateText,
                              "DD/MM/YYYY HH:mm"
                            ).format(
                              i18n.resolvedLanguage === "th"
                                ? "D/MM/BBBB"
                                : "D/MM/YYYY"
                            )}`}
                          </Typography>
                          <Typography>
                            {`${dayjs(
                              row.approveDateText,
                              "DD/MM/YYYY HH:mm"
                            ).format(
                              i18n.resolvedLanguage === "th"
                                ? "HH:mm"
                                : "h:mm A"
                            )}`}
                          </Typography>
                        </Fragment>
                      )}
                    </Fragment>
                  ) : row.idRequestTime ? (
                    <Fragment>
                      {row.approvalLevel === 1 ? (
                        <Fragment>
                          {row.managerLV1ApproveDateText && (
                            <Fragment>
                              <Typography>
                                {`${dayjs(
                                  row.managerLV1ApproveDateText,
                                  "DD/MM/YYYY HH:mm"
                                ).format(
                                  i18n.resolvedLanguage === "th"
                                    ? "D/MM/BBBB"
                                    : "D/MM/YYYY"
                                )}`}
                              </Typography>
                              <Typography>
                                {`${dayjs(
                                  row.managerLV1ApproveDateText,
                                  "DD/MM/YYYY HH:mm"
                                ).format(
                                  i18n.resolvedLanguage === "th"
                                    ? "HH:mm"
                                    : "h:mm A"
                                )}`}
                              </Typography>
                            </Fragment>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment>
                          {row.managerLV2ApproveDateText && (
                            <Fragment>
                              <Typography>
                                {`${dayjs(
                                  row.managerLV2ApproveDateText,
                                  "DD/MM/YYYY HH:mm"
                                ).format(
                                  i18n.resolvedLanguage === "th"
                                    ? "D/MM/BBBB"
                                    : "D/MM/YYYY"
                                )}`}
                              </Typography>
                              <Typography>
                                {`${dayjs(
                                  row.managerLV2ApproveDateText,
                                  "DD/MM/YYYY HH:mm"
                                ).format(
                                  i18n.resolvedLanguage === "th"
                                    ? "HH:mm"
                                    : "h:mm A"
                                )}`}
                              </Typography>
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      {row.approveDateText && (
                        <Fragment>
                          <Typography>
                            {`${dayjs(
                              row.approveDateText,
                              "DD/MM/YYYY HH:mm"
                            ).format(
                              i18n.resolvedLanguage === "th"
                                ? "D/MM/BBBB"
                                : "D/MM/YYYY"
                            )}`}
                          </Typography>
                          <Typography>
                            {`${dayjs(
                              row.approveDateText,
                              "DD/MM/YYYY HH:mm"
                            ).format(
                              i18n.resolvedLanguage === "th"
                                ? "HH:mm"
                                : "h:mm A"
                            )}`}
                          </Typography>
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  className={`tableCell cellStatusAction`}
                >
                  <div style={{ display: "flex" }}>
                    {row.filename && (
                      <IconButton
                        style={{
                          width: "40px",
                          marginRight: "8px",
                        }}
                        aria-label="file"
                        onClick={async () => {
                          await fileService
                            .downloadBucketFile(
                              `leave/${row.idCompany}/${row.idLeave}/${row.filename}`
                            )
                            .then((res) => {
                              const url = window.URL.createObjectURL(
                                new Blob([res.data])
                              );
                              const link = document.createElement("a");
                              link.href = url;
                              link.setAttribute("download", row.filename);
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                            });
                        }}
                      >
                        <i
                          className="fa-regular fa-file"
                          style={{
                            color: "rgba(0, 0, 0, 0.54)",
                            fontSize: "20px",
                          }}
                        />
                      </IconButton>
                    )}
                    {!row.idRequestTimeWithdraw &&
                      !row.isWithdraw &&
                      isRequestApprove(row) !== 0 &&
                      disabledWithDraw(row) && (
                        <IconButton aria-label="more">
                          <DeleteOutlineRoundedIcon
                            onClick={() => handleClickWithDraw(row)}
                          />
                        </IconButton>
                      )}
                    {((isRequestApprove(row) === 0 &&
                      (row.commentManager ||
                        row.commentManagerLV1 ||
                        row.commentManagerLV2)) ||
                      (row.idRequestTime && row.isForceWithdraw === 1)) && (
                      <Tooltip
                        title={`${t("ViewDescriptions")}`}
                        placement="top"
                      >
                        <IconButton
                          aria-label="comment"
                          onClick={() => {
                            let comment = row.commentManager;
                            let type = "requestTime";
                            if (
                              row.idRequestTime &&
                              row.isForceWithdraw === 1
                            ) {
                              comment = "ไม่มีการขอรับรองเวลาทำงานช่วงเวลานี้";
                              type = "forceWithdraw";
                            } else {
                              if (row.commentManagerLV1) {
                                comment = row.commentManagerLV1;
                              }
                              if (row.commentManagerLV2) {
                                comment = row.commentManagerLV2;
                              }
                            }
                            handleOpenDialogCommentManager(type, comment);
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
}
