import React, { Fragment, useEffect, useState } from "react";
import { Box, Divider, Grid, IconButton, styled, Typography } from "@mui/material";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DrawerCostCenter from "./DrawerCostCenter";
import { deleteEmployeeCostCenter, getCostCenterList } from "../../../../../../actions/cost-center";
import dayjs from "dayjs";
import { getDayjsFormatWithTranslate } from "../../../../../../utils";
import costCenterService from "../../../../../../services/cost-center.service";

import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import DialogConfirmDelete from "../../../../shared/general/DialogConfirmDelete";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";

const StyledDivider = styled(Divider)({
  // marginTop: 16,
  // marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const CostCenterChange = () => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);

  const [isFetchingEmployeeCostCenter, setIsFetchingEmployeeCostCenter] = useState(true);
  const [employeeCostCenterList, setEmployeeCostCenterList] = useState([]);

  const [drawerCostCenterConfig, setDrawerCostCenterConfig] = useState({
    isOpen: false,
    data: {},
  })

  const [dialogDeleteConfig, setDialogDeleteConfig] = useState({
    isOpen: false,
    data: {},
  })

  const handleOpenDrawerCostCenter = () => {
    setDrawerCostCenterConfig(prev => ({
      ...prev,
      isOpen: true,
      isEdit: false,
    }))
  }

  const handleCloseDrawerCostCenter = () => {
    setDrawerCostCenterConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const handleOpenEditDrawerCostCenter = (item) => {
    setDrawerCostCenterConfig(prev => ({
      ...prev,
      isOpen: true,
      isEdit: true,
      data: item
    }))
  }

  const handleOpenDialogDelete = (item) => {
    setDialogDeleteConfig(prev => ({
      ...prev,
      isOpen: true,
      data: {
        costCenterCharge: item.costCenterCharge,
        idEmployeeCostCenter: item.idEmployeeCostCenter,
      }
    }))
  }

  const handleCloseDialogDelete = () => {
    setDialogDeleteConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const handleConfirmDelete = async () => {
    if(dialogDeleteConfig.data.idEmployeeCostCenter){
      const result = await dispatch(deleteEmployeeCostCenter(dialogDeleteConfig.data.idEmployeeCostCenter));
      if (result && result.status === 200) {
        handleCloseDialogDelete();
        dispatch(openNotificationAlert({
          type: "success",
          message: t("DataSaveSuccessful"),
        }));
        fecthEmployeeCostCenter()
        dispatch(getEmployeeProfile(employeeProfile.idEmployees, "admin"));
      } else {
        dispatch(openNotificationAlert({
          type: "error",
          message: t("AnErrorOccurred")
        }));
      }
    }
  }

  const fetchCostCenterList = () => {
    try {
      dispatch(getCostCenterList())
    } catch (error) {
      console.error(error)
    }
  }

  const fecthEmployeeCostCenter = () => {
    try {
      costCenterService.getEmployeeCostCenter({
        idEmployees: employeeProfile.idEmployees,
      }).then(res => {
        setIsFetchingEmployeeCostCenter(false);
        setEmployeeCostCenterList(res.data)
      })

    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchCostCenterList()
    fecthEmployeeCostCenter()
  }, [])

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
          {!userProfile.readOnly && (
            <ButtonBlue
              variant="text"
              size="small"
              startIcon={<EditIcon />}
              onClick={handleOpenDrawerCostCenter}
              // disabled={disabledBtnChange || !employeeProfile.isShowSalary}
            >
              {t("Edit")}
            </ButtonBlue>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize="14px" fontWeight="500" color="text.secondary">Cost Center</Typography>
          <Typography fontSize="20px" fontWeight="500">{employeeProfile.costCenterCharge || "-"}</Typography>
        </Grid>
        {employeeProfile.costCenterChargeInfo && (
          <Fragment>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="500" color="text.secondary">ประเภท</Typography>
              <Typography fontSize="20px" fontWeight="500">
                {employeeProfile.costCenterChargeInfo.costCenterType === "Custom"
                  ? t("Custom")
                  : t("accordingToOrganizationalStructure")
                }
              </Typography>
            </Grid>
            {employeeProfile.costCenterChargeInfo.costCenterType === "Custom" && (
              <Grid item xs={12}>
                <Typography fontSize="14px" fontWeight="500" color="text.secondary">วันที่มีผล</Typography>
                <Typography fontSize="20px" fontWeight="500">
                  {`${getDayjsFormatWithTranslate(employeeProfile.costCenterChargeInfo.start, "DD MMMM YYYY", i18n.language)} - ${employeeProfile.costCenterChargeInfo.end? getDayjsFormatWithTranslate(employeeProfile.costCenterChargeInfo.end, "DD MMMM YYYY", i18n.language): t("Current")}`}
                </Typography>
              </Grid>
            )}
          </Fragment>
        )}
        <Grid item xs={12}>
          <StyledDivider />
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize="14px" fontWeight="500" color="text.secondary">บันทึกการเปลี่ยนแปลงประเภทกำหนดเอง</Typography>
        </Grid>
        {isFetchingEmployeeCostCenter? (
          <Grid item xs={12}>
            <Typography align="center" fontWeight="500">{t("LoadingData")}...</Typography>
          </Grid>
        ) : (
          <Fragment>
            {employeeCostCenterList.length > 0? (
              <Fragment>
                {employeeCostCenterList.map(item => (
                  <Grid key={item.idEmployeeCostCenter} item xs={12}>
                    <Box display="flex">
                      <Box>
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={() => {
                            handleOpenDialogDelete(item)
                          }}
                        >
                          <HighlightOffRoundedIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton
                          aria-label="edit"
                          size="small"
                          onClick={() => {handleOpenEditDrawerCostCenter(item)}}
                        >
                          <EditNoteRoundedIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                      <Box>
                        <Typography fontSize="14px" fontWeight="500">
                          {`[${getDayjsFormatWithTranslate(item.start, "DD MMMM YYYY", i18n.language)} - ${item.end? getDayjsFormatWithTranslate(item.end, "DD MMMM YYYY", i18n.language): t("Current")}]`} {item.costCenterCharge}
                        </Typography>
                        <Typography fontSize="14px" fontWeight="500" color="text.secondary">
                          {t("Type")}: {item.costCenterType === "Custom"? t("Custom"): t("accordingToOrganizationalStructure")}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Fragment>) : (
                <Grid item xs={12}>
                  <Typography variant="h6" color="text.secondary" fontStyle="italic" align="center">{t("NoData")}</Typography>
                </Grid>
              )
            }
          </Fragment>
        )}
      </Grid>

      <DrawerCostCenter
        drawerConfig={drawerCostCenterConfig}
        onClose={handleCloseDrawerCostCenter}
        fecthEmployeeCostCenter={fecthEmployeeCostCenter}
      />

      <DialogConfirmDelete
        open={dialogDeleteConfig.isOpen}
        handleClose={handleCloseDialogDelete}
        label="Cost Center"
        text={`${dialogDeleteConfig.data.costCenterCharge}`}
        handleDelete={handleConfirmDelete}
      />
    </Box>
  )
}

export default CostCenterChange;