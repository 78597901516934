import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Grid,
} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { CloudDownload, CloudOff } from "@mui/icons-material";
import AlertResponse from "../../shared/general/AlertResponse";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import Swal from 'sweetalert2';
import {
  addSmartDocument,
  editSmartDocument,
} from "../../../../actions/document";
import Lottie from 'lottie-react';
import animationData2 from '../company-documents/Animation/Animation - 1721107029244.json'

import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  "&.MuiCheckbox-root": {
    transition: "transform 0.3s ease-in-out",
  },
  "&.Mui-checked": {
    color: theme.palette.primary.main,
    transform: "scale(1.2)",
  },
  "& .MuiSvgIcon-root": {
    fontSize: 28,
  },
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
  },
}));

const dialogType = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { open, mode, handleClose, data, handleCloseDialog, handleFileClose, selectedCompany} = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const { result: docGroup } = useSelector((state) => state.documentGroup);
  const [loading, setLoading] = useState(false);

  const lottieRef = useRef(null);

   useEffect(() => {
    if (lottieRef.current) {
      if (!loading ) {
        lottieRef.current.play();
      }
    }
  }, [loading])

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
    };

  const [formData, setFormData] = useState({
    filename: 'ชื่อเอกสาร', 
    isShow: 0,
    isDownload: false,
    detail: 'กรอกรายละเอียด',
    file: null,
  });
  
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  useEffect(() => {
    if (open && data) {
      if (mode === "edit") {
        setFormData({
            idCompany: selectedCompany.idCompany,
            isDownload: data.isDownload,
            documentTypeName: data.documentTypeName,
            smartDocumentId: data.smartDocumentId,
            detail: data.detail,
      });
      } else {
          setFormData({
            idCompany: selectedCompany.idCompany,
            isDownload: false,
            detail: null,
          });
      }
    }
  }, [open, data]);
  
  const handleSubmit = async () => {
    if (!formData.documentTypeName) {
      handleOpenAlert(true);
      handleChangeAlertType(`${t("ThisFieldIsRequired")}`);
    } else {
      const postData = new FormData();
      let data;
      if (mode === "edit") {
        data = {
          documentTypeName: formData.documentTypeName,
          isDownload: formData.isDownload,
          idCompany: selectedCompany.idCompany,
          updateDate: dayjs(new Date()).format("YYYY-MM-DD"),
          smartDocumentId: formData.smartDocumentId,
          detail: formData.detail,
        };
      } else {
        data = {
          documentTypeName: formData.documentTypeName,
          idCompany: selectedCompany.idCompany,
          isDownload: formData.isDownload,
          updateDate: dayjs(new Date()).format("YYYY-MM-DD"),
          createDate: dayjs(new Date()).format("YYYY-MM-DD"),
          detail: formData.detail,
        };
      } 

      Object.keys(data).map((k) => {
        postData.append(k, JSON.stringify(data[k]));
      });
      
      setLoading(true);
      try {    
        let result;
        if (mode === "Add") {
          result = await dispatch(addSmartDocument(postData));
        } else {
          result = await dispatch(editSmartDocument(postData));
        }
        if (result) {
          Swal.fire({
            icon: 'success',
            title: mode === "Add" ? `${t("Data_added_successfully")}!`: `${t("Data_edit_successful")}!`,
            showConfirmButton: false,
            timer: 1500, 
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: mode === "Add" ? `${t("Unable_to_add_data")}!` : `${t("Unable_to_edit_data")}!`, 
            text: `${t("PleaseTryAgain")}!`,
            confirmButtonText: 'OK',
          });
        }
      } catch (error) {
        console.error("Error while editing document: ", error);
        Swal.fire({
          icon: 'error',
          title:`${t("AnErrorOccurred")}!`, 
          text: `${t("PleaseTryAgain")}!`,
          confirmButtonText: 'OK',
        });
      } finally {
        setLoading(false);
        handleCloseDialog();
        handleFileClose()
      }
    }
  };

  return (
    <>
    <DrawerCustom
      title={`${t("DocumentDescription")}`}
      anchor="right"
      open={open}
      onClose={handleCloseDialog}
    >
      {formData && (
        <StyledRoot>
          <Grid
            container
            spacing={2}
            component="form"
            noValidate    
            autoComplete="off"
          >
            {mode === "edit" ? (
              <>
               { loading ? (
                <>
                  <Grid item xs={12}  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
                    <div style={{ width: '60%', height: '60%' }}>
                        <Lottie
                          lottieRef={lottieRef}
                          animationData={animationData2}
                          autoplay={true}
                        />
                      </div>
                  </Grid>
                </>
                ) : (
                  <>
                    <Grid item xs={12} className="GridTopicInput">
                      {`${t("DocumentName")} `} 
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldTheme
                          name="documentTypeName"
                          fullWidth
                          onChange={handleChange}
                          value={formData.documentTypeName}
                        />
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <>
                <Grid item xs={12} className="GridTopicInput">
                  {`${t("DocumentName")}`}
                </Grid>
                <Grid item xs={12}>
                  <TextFieldTheme
                    name="documentTypeName"
                    fullWidth
                    onChange={handleChange}
                    value={formData.documentTypeName}
                  />
                </Grid>
              </>
            )}
            { loading ? ( 
              <>
              </>
            ): (
              <>
              <Grid item xs={12} sm={3} className="GridTopicInput">
                {`${t("Descriptions")}`}
              </Grid>
              <Grid item xs={12}>
                <TextFieldTheme
                  name="detail"
                  fullWidth
                  multiline
                  rows={3}
                  onChange={handleChange}
                  value={formData.detail}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="GridTopicInput">
                  {`${t("OpenForDownloadingDocuments")}`}
              </Grid>
              <Grid item xs={12} sm={8}>
                   <CustomCheckbox
                      name="isDownload"
                      checked={formData.isDownload}
                      onChange={handleChange}
                      icon={<CloudOff fontSize="large" />}
                      checkedIcon={<CloudDownload fontSize="large" />}
                    />
              </Grid>
              </>
            )}
            </Grid>
            { loading ? ( 
              <>
              </>
            ): (
            <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleClose}>
              {`${t("Cancel")}`}
            </ButtonBlue>
            <ButtonBlue variant="contained" onClick={handleSubmit} autoFocus>
              {`${t("SaveData")}`}
            </ButtonBlue>
            </StyledFooter>
            )}

        </StyledRoot>
      )}
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </DrawerCustom>
    </>
  );
};

export default dialogType;
