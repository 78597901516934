import {
  AFFILIATE_FETCHING,
  AFFILIATE_FAILED,
  AFFILIATE_SUCCESS,
  MANAGER_GROUP_FETCHING,
  MANAGER_GROUP_FAILED,
  MANAGER_GROUP_SUCCESS,
  AFFILIATE_SHIFT_GROUP_FETCHING,
  AFFILIATE_SHIFT_GROUP_FAILED,
  AFFILIATE_SHIFT_GROUP_SUCCESS,
} from "./types";

import AffiliateService from "../services/affiliate.service";

export const getAffiliateCompany = (query) => async (dispatch) => {
  try {
    dispatch({
      type: AFFILIATE_FETCHING,
    });
    const res = await AffiliateService.getAffiliateCompany(query);
    if (res) {
      dispatch({
        type: AFFILIATE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: AFFILIATE_FAILED,
    });
    return err;
  }
};

export const getAffiliateManagerGroup = () => async (dispatch) => {
  try {
    dispatch({
      type: MANAGER_GROUP_FETCHING,
    });
    const res = await AffiliateService.getAffiliateManagerGroup();
    if (res) {
      dispatch({
        type: MANAGER_GROUP_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MANAGER_GROUP_FAILED,
    });
  }
};

export const getAffiliateShiftGroup = () => async (dispatch) => {
  try {
    dispatch({
      type: AFFILIATE_SHIFT_GROUP_FETCHING,
    });
    const res = await AffiliateService.getAffiliateShiftGroup();
    if (res) {
      dispatch({
        type: AFFILIATE_SHIFT_GROUP_SUCCESS,
        payload: res.data,
      });
    }
    return res
  } catch (err) {
    dispatch({
      type: AFFILIATE_SHIFT_GROUP_FAILED,
    });
  }
};