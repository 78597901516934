import { httpClient } from "./httpClient";

const getAllPotential = () => {
  return httpClient.get("potential/all");
};
const getEmpPotential = (filter) => {
  return httpClient.get("potential/EmpPotential", { params: filter });
};
const postAnswerPotential = (idEmp, data) => {
  return httpClient.post(`potential/answer/${idEmp}`, data);
};
const postSpanner = (idEmployees, spannerList) => {
  return httpClient.post(`potential/spanner/${idEmployees}`, spannerList);
};
const disableSpanner = (idPotentialRole) => {
  return httpClient.delete(`potential/spanner/${idPotentialRole}`);
};

export default {
  getAllPotential,
  getEmpPotential,
  postAnswerPotential,
  postSpanner,
  disableSpanner,
};
