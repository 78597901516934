import { httpClient } from "./httpClient";
import dayjs from "dayjs";

const getEmployeeProfile = (idEmployees, filter) => {
  if (filter) {
    return httpClient.get(`employees/profile/${idEmployees}?filter=${filter}`);
  } else {
    return httpClient.get(`employees/profile/${idEmployees}`);
  }
};

const getAllEmployees = (filter, search) => {
  if (filter) {
    return httpClient.get(`employees?filter=${filter}`);
  } else if (search) {
    return httpClient.get(`employees?search=${search}`);
  } else {
    return httpClient.get("employees");
  }
};

const getAllEmployeesByIdCompany = (id) => {
  return httpClient.get(`allEmployees/${id}`);
};

const getAllEmployeesAllRoles = () => {
  return httpClient.get("employees-allRoles");
};

const getLeaveQuota = () => {
  return httpClient.get("employee/leave");
};

const getEmployeeLeaveQuotaByIdEmp = (idEmployees) => {
  return httpClient.get(`employee/${idEmployees}/leave`);
};

const getLeaveRequestAllYear = (search) => {
  return httpClient.get(`request/leave/year`, {
    params: search,
  });
};

const getLeaveWithdrawEmployeeYear = (search) => {
  return httpClient.get(`request/leave/withdraw/year`, {
    params: search,
  });
};

const getLeaveRequestOnce = (query) => {
  return httpClient.get(`/request/leave`, { params: query });
};

const getLeaveRequest = (filter, search, idCompany) => {
  return httpClient.get(`request/leave`, {
    params: {
      filter: filter ? filter : undefined,
      start:
        search && search.start
          ? dayjs(search.start).format("YYYY-MM-DD")
          : undefined,
      end:
        search && search.end
          ? dayjs(search.end).format("YYYY-MM-DD")
          : undefined,
      idCompany: idCompany,
    },
  });
};

const getAllLeaveRequest = (query) => {
  return httpClient.get(`request/leave`, { params: query });
};

const employeeChangeShift = (data) => {
  return httpClient.post("employees/change-shift", data);
};

const getEmployeeChangeTime = (filter, search, idCompany) => {
  if (filter) {
    return httpClient.get(`employees/change-time`, {
      params: {
        filter: filter ? filter : undefined,
        start:
          search && search.start
            ? dayjs(search.start).format("YYYY-MM-DD")
            : undefined,
        end:
          search && search.end
            ? dayjs(search.end).format("YYYY-MM-DD")
            : undefined,
        idCompany: idCompany,
      },
    });
  } else {
    return httpClient.get("employees/change-time");
  }
};

const getAllEmployeeChangeTime = (query) => {
  return httpClient.get("employees/change-time", { params: query });
};

const employeeChangeTime = (data) => {
  return httpClient.post("employees/change-time", data);
};

const employeeChangeTimeApprove = (data) => {
  return httpClient.put("employees/change-time/approve", data);
};

const employeeChangeTimeWithdraw = (data) => {
  return httpClient.put("employees/change-time/withdraw", data);
};

const getAllEmployeesNotInShift = (idShiftGroup) => {
  return httpClient.get("employees/shift/not/" + idShiftGroup);
};

const getAllEmployeesShift = (idShiftGroup, searchDate) => {
  if (idShiftGroup && searchDate) {
    return httpClient.get(
      `employees/shift/${idShiftGroup}?start=${dayjs(searchDate.start).format(
        "YYYY-MM"
      )}`
    );
  } else if (idShiftGroup) {
    return httpClient.get(`employees/shift/${idShiftGroup}`);
  } else {
    return httpClient.get("employees/shift");
  }
};

const getEmployeeShiftHistory = (idShiftGroup) => {
  return httpClient.get("employees/shift/history/" + idShiftGroup);
};

const updateEmployeeShiftHistory = (data) => {
  return httpClient.put("employees/shift/history", data);
};

const getAllEmployeesByShiftGroup = (idShiftGroup) => {
  return httpClient.get("employees/shiftgroup/" + idShiftGroup);
};

const addProfileImage = (formData) => {
  return httpClient.post("employees/addProfileImage", formData);
};

const addProfileImageOfEmployees = (formData) => {
  return httpClient.post("employees/add-profile-image-of-employees", formData);
};

const getEmployeeDataWritePDF = (type, year, idDepartment, idEmployee) => {
  return httpClient.get(
    `employees/data-writePDF/${type}/${year}/${idDepartment}/${idEmployee}`
  );
};

const getTimeschedule = (start) => {
  return httpClient.get(`time-schedule?start=${start}`);
};

const getTimescheduleByEmployees = (filter, idEmployees) => {
  return httpClient.get(
    `time-schedule/${idEmployees}?start=${filter.start}&end=${filter.end}`
  );
};

//dumpExcel

const uploadExcelTimeAttendance = (formData) => {
  return httpClient.post(`/upload-excel-attendance`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const uploadExcelEmployees = (formData) => {
  return httpClient.post(`/upload-excel/employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const dumpExcelNicknameEmployees = (formData) => {
  return httpClient.put(`/upload-excel/nickname-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const dumpExcelGenderEmployees = (formData) => {
  return httpClient.put(`/upload-excel/gender-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const dumpExcelNationalityEmployees = (formData) => {
  return httpClient.put(`/upload-excel/nationality-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const dumpExcelStatusEmployees = (formData) => {
  return httpClient.put(`/upload-excel/status-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const dumpExcelPassportEmployees = (formData) => {
  return httpClient.put(`/upload-excel/passport-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const dumpExcelWorkPermitEmployees = (formData) => {
  return httpClient.put(`/upload-excel/workPermit-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const dumpExcelBirthdayEmployees = (formData) => {
  return httpClient.put(`/upload-excel/birthday-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const dumpExcelAddressEmployees = (formData) => {
  return httpClient.put(`/upload-excel/address-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const dumpExcelTelephoneEmployees = (formData) => {
  return httpClient.put(`/upload-excel/telephone-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const dumpExcelEmailEmployees = (formData) => {
  return httpClient.put(`/upload-excel/email-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const dumpExcelEmergencyContactEmployees = (formData) => {
  return httpClient.put(`/upload-excel/emergencyContact-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const dumpExcelTimeEmployees = (formData) => {
  return httpClient.put(`/upload-excel/time-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const dumpExcelManagerLV1Employees = (formData) => {
  return httpClient.put(`/upload-excel/managerLV1-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const dumpExcelManagerLV2Employees = (formData) => {
  return httpClient.put(`/upload-excel/managerLV2-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const dumpExcelContractTermainatoinEmployees = (formData) => {
  return httpClient.put(
    `/upload-excel/contractTermainatoin-employees`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};
const dumpExcelResignEmployees = (formData) => {
  return httpClient.put(`/upload-excel/resign-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const dumpExcelEducation1Employees = (formData) => {
  return httpClient.put(`/upload-excel/education1-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const dumpExcelEducation2Employees = (formData) => {
  return httpClient.put(`/upload-excel/education2-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const dumpExcelEducation3Employees = (formData) => {
  return httpClient.put(`/upload-excel/education3-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const dumpExcelCostEmployees = (formData) => {
  return httpClient.put(`/upload-excel/cost-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const dumpExcelBookBankEmployees = (formData) => {
  return httpClient.put(`/upload-excel/bookBank-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const dumpExcelReligionEmployees = (formData) => {
  return httpClient.put(`/upload-excel/religion-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getEmployeePersonnelLevelChange = (idEmployees) => {
  return httpClient.get(`employees/${idEmployees}/personnel-level/change`);
};

const updateEmployeePersonnelLevelChange = (
  idEmployees,
  idEmployeePersonnelLevel,
  formData
) => {
  return httpClient.put(
    `employees/${idEmployees}/personnel-level/change/${idEmployeePersonnelLevel}`,
    formData
  );
};

const addDeleteEmployeePersonnelLevel = (data) => {
  return httpClient.post(`employees/personnel-level`, data);
};

const updateEmployeePersonnelLevel = (data) => {
  return httpClient.put(`employees/personnel-level`, data);
};

const getEmployeeEmployeeTypeChange = (idEmployees) => {
  return httpClient.get(`employees/${idEmployees}/employment-type/change`);
};

const updateEmployeeEmployeeTypeChange = (
  idEmployees,
  idEmployeeEmployeeType,
  formData
) => {
  return httpClient.put(
    `employees/${idEmployees}/employment-type/change/${idEmployeeEmployeeType}`,
    formData
  );
};

const updateEmployeeEmploymentType = (data) => {
  return httpClient.put(`employees/employment-type`, data);
};

const addDeleteEmployeeEmploymentType = (data) => {
  return httpClient.post(`employees/employment-type`, data);
};

const getEmployeePositionChange = (idEmployees) => {
  return httpClient.get(`employees/${idEmployees}/position/change`);
};

const updateEmployeePositionChange = (
  idEmployees,
  idEmployeePosition,
  formData
) => {
  return httpClient.put(
    `employees/${idEmployees}/position/change/${idEmployeePosition}`,
    formData
  );
};

const addDeleteEmployeePosition = (data) => {
  return httpClient.post("employees/position", data);
};

const updateEmployeePosition = (data) => {
  return httpClient.put("employees/position", data);
};

const getEmployeeSalaryChange = (idEmployees) => {
  return httpClient.get(`employees/${idEmployees}/salary/change`);
};

const updateEmployeeSalaryChange = (
  idEmployees,
  idEmployeeSalary,
  formData
) => {
  return httpClient.put(
    `employees/${idEmployees}/salary/change/${idEmployeeSalary}`,
    formData
  );
};

const addDeleteEmployeeSalary = (formData) => {
  return httpClient.post(`employees/salary`, formData);
};

const updateEmployeeSalary = (idEmployees, formData) => {
  return httpClient.put(`employees/${idEmployees}/salary`, formData);
};

const getEmployeeRegistrationMetadata = (query) => {
  return httpClient.get(`employees-registration-metadata`, { params: query });
};

const addEmployees = (formData) => {
  return httpClient.post(`employees`, formData);
};

const getEmployeeAdditionDeduction = (idEmployees) => {
  return httpClient.get(`employees/${idEmployees}/addition-deduction`);
};

const updateEmployeeAdditionDeduction = (formData) => {
  return httpClient.put(`employees/addition-deduction`, formData);
};

const getEmployeeExcelExport = (query) => {
  return httpClient.get(`employees-export-excel`, { params: query });
};

const getEmployeeExcelDiligenceAllowanceExport = (query) => {
  return httpClient.get(`employees-workingday-export-excel`, { params: query });
};

const checkEmployeeDuplicate = (query) => {
  return httpClient.get(`employees/check-duplicate`, { params: query });
};

const deleteEmployeeAddition = (idEmp, idEmployeeAddition) => {
  return httpClient.delete(
    `employees/${idEmp}/addition-deduction/addition/${idEmployeeAddition}`
  );
};

const deleteEmployeeDeduction = (idEmp, idEmployeeDeduction) => {
  return httpClient.delete(
    `employees/${idEmp}/addition-deduction/deduction/${idEmployeeDeduction}`
  );
};

const getEmployeeShiftChange = (idEmployees) => {
  return httpClient.get(`employees/${idEmployees}/shift/change`);
};

const getProbationEmployees = () => {
  return httpClient.get(`employees-probation`);
};

const getProbationEmployeesStatus = (query) => {
  return httpClient.get('employees-probation-status', { params: query });
};

const getEmployeesRoleOneTwo = (query) => {
  return httpClient.get(`employees-role-one-two`, { params: query });
};

const getEstimatePerformanceEmployees = () => {
  return httpClient.get(`employees-estimatePerformance`);
};

const getProbationEmployeesByID = (idEmployees) => {
  return httpClient.get(`employees-probation/${idEmployees}`);
};

const getExitInterviewEmployees = (query) => {
  return httpClient.get(`employees-exitInterview`, { params: query });
};

const employeeExitInterviewApprove = (data) => {
  return httpClient.put("employees/exitInterview/approve", data);
};

const getAllFreeUsersByIdVendor = () => {
  return httpClient.get(`users/hunter/peer/free`);
};

const getAllUsersByIdVendor = () => {
  return httpClient.get(`users/hunter/peer`);
};

const updateVendorManage = (formData) => {
  return httpClient.patch(`vendors/manage`, formData);
};

const getEstimatePerformanceEmployeesStatus = () => {
  return httpClient.get(`employees-estimatePerformance-status`);
};

const getExitInterviewEmployeesStatus = (query) => {
  return httpClient.get(`employees-exitInterview-status`, { params: query });
};

const addEmployeeInformationChange = (formData) => {
  return httpClient.post(`employees/information-change`, formData);
};

const getTimescheduleHolidayUsed = (query) => {
  return httpClient.get(`time-schedule-holiday-used`, { params: query });
};

const getEmployeeNetwork = (id) => {
  return httpClient.get(`employeesNetwork/${id}`);
};

const putOverView = (id, data) => {
  return httpClient.put(`employeesNetwork/putOverView/${id}`, data);
};

const deleteInterest = (id) => {
  return httpClient.put(`employeesNetwork/deleteInterest/${id}`);
};

const deleteSkill = (id) => {
  return httpClient.put(`employeesNetwork/deleteSkill/${id}`);
};

const postSkill = (id, data) => {
  return httpClient.post(`employeesNetwork/postSkill/${id}`, data);
};

const postInterest = (id, data) => {
  return httpClient.post(`employeesNetwork/postInterest/${id}`, data);
};

const getAllEmployeesRoleAdmin = () => {
  return httpClient.get("employees-roleAdmin");
};

const dumpExcelEmployees = (formData, query) => {
  return httpClient.put(`/upload-excel/dumpFile`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
    params: query,
  });
};

const uploadExcelEmployeeFreelance = (formData) => {
  return httpClient.post(`/upload-excel/employee-freelance`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getEmployeesDetails = (selectedMonth, selectedYear, idPayrollType, selectedEmployee, type, idCompany) => {
  return httpClient.get(`employeesDetails?selectedMonth=${selectedMonth}&selectedYear=${selectedYear}&idPayrollType=${idPayrollType}&selectedEmployee=${selectedEmployee}&type=${type}&idCompany=${idCompany}`);
}

const getAllEmployeesEarningsAndTaxes = (idCompany, selectedYear, selectedMonth) => {
  return httpClient.get(`employeesEarningAndTax`, {
    idCompany: idCompany,
    selectedYear: `${selectedYear}`,
    selectedMonth: `${selectedMonth}`
  })
}

const getAllEmployeesTaxDeduction = (selectedYear, idCompany) => {
  return httpClient.get(`employeesDeductionReport/${selectedYear}/${idCompany}`)
}

const addEmployeeContractByIdEmp = (formData) => {
  return httpClient.post("employee/contract", formData);
};

const getEmployeeContractByEmployee = (id) => {
  return httpClient.get(`employee-contract/${id}`);
};

const deleteEmployeeContract = (id, idEmployees) => {
  return httpClient.delete(`employeesContract/${id}/${idEmployees}`)
}

const getPaymentType = () => {
  return httpClient.get("payment-type");
};

const uploadExcelManager2UserManager = (formData) => {
  return httpClient.post(`/upload-excel/manager-to-usermanager`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getEmployeeSearch = (query) => {
  return httpClient.get("/employee-search", { params: query });
};

const getEmployeeShiftChangeHistory = (idEmployees) => {
  return httpClient.get(`employees/${idEmployees}/shift/history`);
}

const patchEmployeeShiftChangeHistoryStandard = (idEmployees, idEmployeeShift, formData) => {
  return httpClient.patch(`employees/${idEmployees}/shift/history/standard/${idEmployeeShift}`, formData);
}

const deleteEmployeeShiftChangeHistoryStandard = (idEmployees, idEmployeeShift) => {
  return httpClient.delete(`employees/${idEmployees}/shift/history/standard/${idEmployeeShift}`);
}

const patchEmployeeShiftChangeHistoryDaily = (idEmployees, idEmployeeShiftDaily, formData) => {
  return httpClient.patch(`employees/${idEmployees}/shift/history/daily/${idEmployeeShiftDaily}`, formData);
}

const deleteEmployeeShiftChangeHistoryDaily = (idEmployees, idEmployeeShiftDaily) => {
  return httpClient.delete(`employees/${idEmployees}/shift/history/daily/${idEmployeeShiftDaily}`);
}

const getSummaryTaxGroupedByEmployee = (query) => {
  return httpClient.get("employee-tax-remittance", { params: query });
}

const getPFvalueAllYearByEmp = (year, idCompany) => {
  return httpClient.get(`employee-year-pf/${year}/${idCompany}`)
}

const getEmployeeProfileList = (idCompany, idEmp, idEmp2) => {
  return httpClient.get(`employee-compare/${idCompany}/${idEmp}/${idEmp2 ? idEmp2 : null}`);
}

const getEmployeePotential = (idEmp) => {
  return httpClient.get(`employee-potential/${idEmp}`);
}

const addEmployeePersonalityTest = (query) => {
  return httpClient.post(`add-employee-personality-test`, query);
}

const getEmployeePersonalityTest = () => {
  return httpClient.get(`employee-personality-test`);
}

const getEmployeeTalent = (idEmp) => {
  return httpClient.get(`employee-talent/${idEmp}`);
}

const getTalentList = () => {
  return httpClient.get(`talent-list`);
}

const getPotentialList = () => {
  return httpClient.get(`potential-list`);
}

const addEmployeeTalent = (formData) => {
  return httpClient.post(`add-employee-talent`, formData)
}

const updateEmployeeTalent = (formData) => {
  return httpClient.put(`update-employee-talent`, formData)
}

const addEmployeePotential = (formData) => {
  return httpClient.post(`add-employee-potential`, formData)
}

const updateEmployeePotential = (idEmployeePotential, formData) => {
  return httpClient.put(`update-employee-potential/${idEmployeePotential}`, formData)
}

const getAllEmployeeTalentByCompany = (idCompany, year) => {
  return httpClient.get(`get-all-employees-talent/${idCompany}/${year}`)
}

const getAllEmployeeTalentByCompanyAndTalent = (idTalent, idCompany, year) => {
  return httpClient.get(`get-employees-byTalent/${idTalent}/${idCompany}/${year}`)
}

const getPerformanceTalentAndPotentialByEmp = (idEmp) => {
  return httpClient.get(`get-talent-performance-potential/${idEmp}`);
}

const getWagesPerYearByEmployees = (query) => {
  return httpClient.get(`payment/wage-per-year`, { params: query });
}

const addSignatureImageOfEmployees = (formData) => {
  return httpClient.post("employees/addEmployeeSignature", formData);
}

const deleteSignatureImageOfEmployees = (idEmployees) => {
  return httpClient.delete(`employees/deleteEmployeeSignature/${idEmployees}`);
};

const getSignatureImageOfEmployees = () => {
  return httpClient.get(`employees/imageEmployeeSignature`);
}

const getDiligenceInfoByIdEmployees = (idEmployees) => {
  return httpClient.get(`employees/${idEmployees}/diligence-info`);
}

export default {
  getEmployeeProfile,
  getPaymentType,
  getLeaveQuota,
  getEmployeeLeaveQuotaByIdEmp,
  getAllEmployees,
  getAllEmployeesAllRoles,
  getLeaveRequest,
  getAllLeaveRequest,
  getLeaveRequestAllYear,
  getLeaveWithdrawEmployeeYear,
  getLeaveRequestOnce,
  employeeChangeShift,
  updateEmployeePosition,
  addDeleteEmployeePosition,
  addDeleteEmployeePersonnelLevel,
  updateEmployeePersonnelLevel,
  updateEmployeeEmploymentType,
  addDeleteEmployeeEmploymentType,
  getEmployeeEmployeeTypeChange,
  updateEmployeeEmployeeTypeChange,
  getEmployeePersonnelLevelChange,
  updateEmployeePersonnelLevelChange,
  getEmployeeChangeTime,
  getAllEmployeeChangeTime,
  getEmployeePositionChange,
  updateEmployeePositionChange,
  employeeChangeTime,
  employeeChangeTimeApprove,
  employeeChangeTimeWithdraw,
  getAllEmployeesNotInShift,
  getAllEmployeesShift,
  getEmployeeShiftHistory,
  updateEmployeeShiftHistory,
  getAllEmployeesByShiftGroup,
  addProfileImage,
  addProfileImageOfEmployees,
  getEmployeeDataWritePDF,
  getTimeschedule,
  getTimescheduleByEmployees,
  uploadExcelEmployees,
  uploadExcelTimeAttendance,
  getEmployeeRegistrationMetadata,
  addEmployees,
  getEmployeeSalaryChange,
  updateEmployeeSalaryChange,
  updateEmployeeSalary,
  addDeleteEmployeeSalary,
  getEmployeeAdditionDeduction,
  updateEmployeeAdditionDeduction,
  getEmployeeExcelExport,
  getEmployeeExcelDiligenceAllowanceExport,
  checkEmployeeDuplicate,
  deleteEmployeeAddition,
  deleteEmployeeDeduction,
  getEmployeeShiftChange,
  getProbationEmployees,
  getProbationEmployeesStatus,
  getEmployeesRoleOneTwo,
  getEstimatePerformanceEmployees,
  getProbationEmployeesByID,
  getExitInterviewEmployees,
  employeeExitInterviewApprove,
  getAllFreeUsersByIdVendor,
  getAllUsersByIdVendor,
  updateVendorManage,
  getEstimatePerformanceEmployeesStatus,
  getExitInterviewEmployeesStatus,
  getEmployeeNetwork,
  putOverView,
  postSkill,
  postInterest,
  deleteInterest,
  deleteSkill,
  addEmployeeInformationChange,
  getAllEmployeesByIdCompany,
  getTimescheduleHolidayUsed,
  dumpExcelNicknameEmployees,
  dumpExcelGenderEmployees,
  dumpExcelNationalityEmployees,
  dumpExcelStatusEmployees,
  dumpExcelPassportEmployees,
  dumpExcelWorkPermitEmployees,
  dumpExcelBirthdayEmployees,
  dumpExcelAddressEmployees,
  dumpExcelTelephoneEmployees,
  dumpExcelEmailEmployees,
  dumpExcelEmergencyContactEmployees,
  dumpExcelTimeEmployees,
  dumpExcelManagerLV1Employees,
  dumpExcelManagerLV2Employees,
  dumpExcelContractTermainatoinEmployees,
  dumpExcelResignEmployees,
  dumpExcelEducation1Employees,
  dumpExcelEducation2Employees,
  dumpExcelEducation3Employees,
  dumpExcelCostEmployees,
  dumpExcelBookBankEmployees,
  dumpExcelReligionEmployees,
  getAllEmployeesRoleAdmin,
  dumpExcelEmployees,
  uploadExcelEmployeeFreelance,
  getEmployeesDetails,
  getAllEmployeesEarningsAndTaxes,
  getAllEmployeesTaxDeduction,
  deleteEmployeeContract,
  addEmployeeContractByIdEmp,
  getEmployeeContractByEmployee,
  uploadExcelManager2UserManager,
  getEmployeeSearch,
  getEmployeeShiftChangeHistory,
  patchEmployeeShiftChangeHistoryStandard,
  deleteEmployeeShiftChangeHistoryStandard,
  patchEmployeeShiftChangeHistoryDaily,
  deleteEmployeeShiftChangeHistoryDaily,
  getSummaryTaxGroupedByEmployee,
  getPFvalueAllYearByEmp,
  getEmployeeProfileList,
  getEmployeePotential,
  addEmployeePersonalityTest,
  getEmployeePersonalityTest,
  getEmployeeTalent,
  getTalentList,
  getPotentialList,
  addEmployeeTalent,
  updateEmployeeTalent,
  addEmployeePotential,
  updateEmployeePotential,
  getAllEmployeeTalentByCompany,
  getAllEmployeeTalentByCompanyAndTalent,
  getPerformanceTalentAndPotentialByEmp,
  getWagesPerYearByEmployees,
  addSignatureImageOfEmployees,
  deleteSignatureImageOfEmployees,
  getSignatureImageOfEmployees,
  getDiligenceInfoByIdEmployees,
};
