import React, { useState, useEffect, useRef } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import "dayjs/locale/th";

import {
  Select,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  TablePagination,
  TextField,
  Grid,
  Popper,
  Box,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  FormControl,
  FormHelperText,
  IconButton,
  Container,
  Card,
  CardContent,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";
import Dropzone, { useDropzone } from "react-dropzone";
import DatePickerCustom from "../../../shared/date/datePicker";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import FilePresentIcon from "@mui/icons-material/FilePresent";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import AlertResponse from "../../../shared/general/AlertResponse";
import StyledCard from "../../../shared/general/Card";
import Backdrop from "../../../shared/general/Backdrop";

import GalleryIcon from "../../../assets/gallery.png";

import utils from "../../../../../utils";

import RowBilling from "./row-billing";
import SelectFamily from "./select-family";
import ActionButtons from "./action-buttons";
import SelectEmployees from "../select-employees";

import { addWelfareBilling } from "../../../../../actions/welfare-expense";
import WelfareRightUsage from "../welfareRightUsage";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 24,
  },
  "& .head": {
    paddingTop: 8,
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .head-2": {
    paddingTop: 8,
    marginBottom: 24,
    marginTop: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .wrap-sum-amount": {
    marginBottom: 16,
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 24,
  marginBottom: 20,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledTableCell = styled(TableCell)({
  border: "none",
  padding: 0,
  "& .MuiTypography-root": {
    marginBottom: 16,
  },
  "&.date-document": {
    width: 120,
  },
  "&.service": {
    width: 150,
  },
  "&.description": {
    width: 200,
  },
  "&.price": {
    width: 104,
  },
  "&.price": {
    width: 104,
  },
  "&.discount": {
    width: 104,
  },
  "&.tax": {
    width: 40,
  },
  "&.price-before-tax": {
    width: 104,
  },
  "&.tax-withholding": {
    width: 104,
  },
  "&.delete": {
    width: 32,
  },
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 24,
  backgroundColor: "#f9f9fb",
  "& .dropzone-leave": {
    // height: 98,
    borderRadius: 8,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    // height: 90,
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#efefef",
    flexDirection: "column",
    padding: "40px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const StyledCardContent = styled(CardContent)({
  padding: 24,
  "& .MuiFormControl-root": {
    marginBottom: 8,
  },
  "& .wrap-head": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
  },
  "& .wrap-row-sum": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

const dataInitial = {
  idWelfareBillingItem: null,
  description: "",
  price: 0,
  withdrawablePrice: 0,
  difference: 0,
};

const WelfareBillingPage = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const today = dayjs().toDate();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [formData, setFormData] = useState({
    billingName: "",
    employee: null,
    idEmployee: null,
    idFamily: null,
    listBilling: [dataInitial],
    remark: "",
    type: "",
    location: "",
    isUseForFamilyMember: 0,
    documentDate: dayjs(today).format("YYYY/MM/DD"),
    withdrawal: 0,
    difference: 0,
    helpingSurplus: 0,
    total: 0,
    net: 0,
    file: null,
  });
  const [modalStatusConfig, setModalStatusConfig] = useState({
    open: false,
    title: "",
    content: "",
    type: "success",
  });
  const rowHeader = [
    {
      caption: "",
      className: "delete",
    },
    {
      caption: "Unit.Items",
      className: "description",
    },
    {
      caption: "Amount",
      className: "price",
    },
    {
      caption: "CanWithdraw",
      className: "withdrawablePrice",
    },
    {
      caption: "",
      className: "delete",
    },
  ];

  const listMenuType = [
    { label: "OPD", type: "OPD", id: "1" },
    { label: "IPD", type: "IPD", id: "2" },
    { label: "Dental", type: "Dental", id: "3" },
  ];

  const MAX_FILE_SIZE = 512000; //500KB

  const validFileExtensions = { file: ["jpg", "png", "jpeg"] };

  const isValidFileType = (fileName, fileType) => {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  };

  const validationSchema = Yup.object().shape({
    billingName: Yup.string().required(t("ThisFieldIsRequired")),
    type: Yup.string().required(t("ThisFieldIsRequired")),
    documentDate: Yup.date().required(t("ThisFieldIsRequired")),
    idEmployee: Yup.string().required(t("ThisFieldIsRequired")),
    idFamily: Yup.string().nullable(),
    isUseForFamilyMember: Yup.string().required(t("ThisFieldIsRequired")),
    remark: Yup.string(),
    location: Yup.string().required(t("ThisFieldIsRequired")),
    withdrawal: Yup.string().required(t("ThisFieldIsRequired")).default(0),
    difference: Yup.string().required(t("ThisFieldIsRequired")).default(0),
    helpingSurplus: Yup.string().required(t("ThisFieldIsRequired")).default(0),
    total: Yup.string().required(t("ThisFieldIsRequired")).default(0),
    net: Yup.string().required(t("ThisFieldIsRequired")).default(0),
    listBilling: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().required(t("ThisFieldIsRequired")),
        price: Yup.string().required(t("ThisFieldIsRequired")),
        withdrawablePrice: Yup.string().required(t("ThisFieldIsRequired")),
      })
    ),
    file: Yup.mixed()
      .test("is-valid-type", "Not a valid file type", (value) => {
        if (value) {
          return isValidFileType(value && value.name.toLowerCase(), "file");
        } else {
          return true;
        }
      })
      .test("is-valid-size", "Max allowed size is 500KB", (value) => {
        if (value) {
          return value && value.size <= MAX_FILE_SIZE;
        } else {
          return true;
        }
      }),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    watch,
    reset,
    register,
  } = useForm({
    defaultValues: { ...formData },
    resolver: yupResolver(validationSchema),
  });

  const { append, remove, fields } = useFieldArray({
    control: control,
    name: "listBilling",
  });

  const checkValidation = (error) => {
    console.log(getValues("listBilling"));
    console.log("error : ", error);
    setModalStatusConfig({
      open: true,
      title: "",
      content: t("ThisFieldIsRequired"),
      type: "error",
    });
  };

  useEffect(() => {
    calculateSum();
  }, [watch("listBilling").length]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const calculateSum = () => {
    let listBilling = getValues("listBilling");
    console.log("list", listBilling);
    let sumTotal = fixPoint(
      listBilling.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.price);
      }, 0)
    );
    let sumWithdrawal = fixPoint(
      listBilling.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.withdrawablePrice);
      }, 0)
    );
    let sumDifference = fixPoint(
      listBilling.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.difference);
      }, 0)
    );
    sumWithdrawal =
      sumWithdrawal + handleIsNan(parseFloat(getValues("helpingSurplus")));
    sumDifference = sumTotal - sumWithdrawal;
    setValue("total", sumTotal);
    setValue("withdrawal", sumWithdrawal);
    setValue("difference", sumDifference);
    setValue("net", sumTotal);
  };

  const handleIsNan = (value) => {
    return isNaN(value) ? 0 : value;
  };

  const setDifference = (index) => {
    const difference = handleIsNan(
      parseFloat(getValues(`listBilling.${index}.price`)) -
        parseFloat(getValues(`listBilling.${index}.withdrawablePrice`))
    );
    setValue(
      `listBilling.${index}.difference`,
      difference > 0 ? difference : 0
    );
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const createFormData = (status) => {
    let data = new FormData();
    data.append("idEmployees", getValues("idEmployee"));
    data.append("idPosition", getValues("employee.idPosition"));
    data.append("billingName", getValues("billingName"));
    data.append("isUseForFamily", getValues("isUseForFamily") ? 1 : 0);
    data.append("idFamily", getValues("idFamily"));
    data.append("documentDate", getValues("documentDate"));
    data.append("type", getValues("type"));
    data.append("location", getValues("location"));
    data.append("listBilling", JSON.stringify(getValues("listBilling")));
    if (getValues("file")) {
      data.append("file", getValues("file"), getValues("file.path"));
    }
    data.append("remark", getValues("remark"));
    data.append("createDate", dayjs(today).format("YYYY/MM/DD HH:mm"));
    data.append("lastUpdateDate", dayjs(today).format("YYYY/MM/DD HH:mm"));
    data.append("status", status);
    data.append("net", getValues("net"));
    data.append("withdrawal", getValues("withdrawal"));
    data.append("difference", getValues("difference"));
    data.append("total", getValues("total"));
    data.append("helpingSurplus", getValues("helpingSurplus"));
    data.append("usage", JSON.stringify(getValues("usage")));
    data.append("idRightsManage", getValues("idRightsManage"));
    return data;
  };

  const fixPoint = (value) => {
    return parseFloat(value).toFixed(2);
  };

  const handleOnClickCleardata = async () => {
    reset(formData);
  };

  const handleOnClickSent = async () => {
    let status = 3;
    let data = createFormData(status);
    let result = null;

    if (userProfile && userProfile.idCompany && userProfile.idEmployees) {
      result = await dispatch(addWelfareBilling(data));
    }
    if (result) {
      if (result.status === 200) {
        handleChangeAlertType("success");
        handleOpenAlert();
        history.push(`/welfare-expense`);
      } else {
        handleChangeAlertType("error");
        handleOpenAlert();
      }
    }
  };
  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <AlertResponse
          open={openAlert}
          handleClose={handleCloseAlert}
          alertType={alertType}
        />
        <StyledCard>
          <StyledCardContent>
            <div className="wrap-head">
              <StyledHeadLabel variant="h5">{t("AddBilling")}</StyledHeadLabel>
            </div>
            <Box>
              <SelectEmployees
                handleChange={setValue}
                idEmployees={getValues("idEmployee")}
                isUseform={true}
              />
            </Box>
            <StyledDivider />
            {watch("employee") && (
              <Box>
                <form>
                  <Grid container spacing={2} style={{}} alignItems={"center"}>
                    <Grid item xs={12}>
                      <div className="wrap-head">
                        <StyledHeadLabel variant="h6">
                          {t("GeneralInformation")}
                        </StyledHeadLabel>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div>
                        <StyledHeadLabel
                          variant="body2"
                          color="text.third"
                          gutterBottom
                        >
                          {t("TitleName")}
                        </StyledHeadLabel>
                        <FormControl
                          fullWidth
                          error={errors && errors.billingName ? true : false}
                        >
                          {errors &&
                            errors.billingName &&
                            errors.billingName.message && (
                              <FormHelperText error>
                                {errors.billingName.message}
                              </FormHelperText>
                            )}
                          <Controller
                            name="billingName"
                            control={control}
                            errors={errors}
                            render={({ field }) => (
                              <>
                                <TextFieldTheme
                                  {...field}
                                  name="billingName"
                                  onChange={(e) => {
                                    field.onChange(e.target.value);
                                  }}
                                />
                                <Typography
                                  component="p"
                                  textAlign="right"
                                  variant="caption"
                                  color="text.secondary"
                                >{`${
                                  field.value ? field.value.length : 0
                                }/200`}</Typography>
                              </>
                            )}
                          />
                        </FormControl>
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        error={errors && errors.idFamily ? true : false}
                      >
                        {errors &&
                          errors.idFamily &&
                          errors.idFamily.message && (
                            <FormHelperText error>
                              {errors.idFamily.message}
                            </FormHelperText>
                          )}
                        <Controller
                          name="idFamily"
                          control={control}
                          errors={errors}
                          render={({ field }) => (
                            <>
                              <SelectFamily
                                {...field}
                                idEmployee={getValues("idEmployee")}
                                employee={getValues("employee")}
                                idFamily={getValues("idFamily")}
                                handleChange={setValue}
                                control={control}
                              />
                            </>
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <div>
                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={6}>
                            <StyledHeadLabel
                              variant="body2"
                              color="text.third"
                              gutterBottom
                            >
                              {t("RightsType")}
                            </StyledHeadLabel>
                            <FormControl
                              fullWidth
                              error={errors && errors.type ? true : false}
                            >
                              <Controller
                                name="type"
                                control={control}
                                errors={errors}
                                render={({ field }) => (
                                  <>
                                    <StyledAutocomplete
                                      {...field}
                                      fullWidth
                                      options={listMenuType}
                                      onChange={(e, newValue) => {
                                        setValue("type", newValue.type);
                                      }}
                                      popupIcon={
                                        <i class="fa-light fa-chevron-down"></i>
                                      }
                                      renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                          {option.label}
                                        </Box>
                                      )}
                                      renderInput={(params) => (
                                        <TextFieldTheme
                                          {...params}
                                          variant="outlined"
                                          placeholder=""
                                          name="type"
                                        />
                                      )}
                                      PopperComponent={StyledPopper}
                                      noOptionsText={t("NoData")}
                                    />
                                  </>
                                )}
                              />
                              {errors && errors.type && errors.type.message && (
                                <FormHelperText error>
                                  {errors.type.message}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <StyledHeadLabel
                              variant="body2"
                              color="text.third"
                              gutterBottom
                            >
                              {t("DocumentDate")}
                            </StyledHeadLabel>
                            <FormControl
                              fullWidth
                              error={errors && errors.currency ? true : false}
                            >
                              <Controller
                                name="documentDate"
                                control={control}
                                errors={errors}
                                render={({ field }) => (
                                  <DatePickerCustom
                                    {...field}
                                    inputFormat="DD/MM/YYYY"
                                    onChange={(e) => {
                                      field.onChange(
                                        dayjs(e).format("YYYY/MM/DD")
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextFieldTheme fullWidth {...params} />
                                    )}
                                  />
                                )}
                              />
                              {errors &&
                                errors.documentDate &&
                                errors.documentDate.message && (
                                  <FormHelperText error>
                                    {errors.documentDate.message}
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div>
                        <StyledHeadLabel
                          variant="body2"
                          color="text.third"
                          gutterBottom
                        >
                          {t("Location")}
                        </StyledHeadLabel>
                        <FormControl
                          fullWidth
                          error={errors && errors.location ? true : false}
                        >
                          {errors &&
                            errors.location &&
                            errors.location.message && (
                              <FormHelperText error>
                                {errors.location.message}
                              </FormHelperText>
                            )}
                          <Controller
                            name="location"
                            control={control}
                            errors={errors}
                            render={({ field }) => (
                              <>
                                <TextFieldTheme
                                  {...field}
                                  name="location"
                                  onChange={(e) => {
                                    field.onChange(e.target.value);
                                  }}
                                />
                                <Typography
                                  component="p"
                                  textAlign="right"
                                  variant="caption"
                                  color="text.secondary"
                                >{`${field.value.length}/200`}</Typography>
                              </>
                            )}
                          />
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledDivider />
                      <div>
                        <div className="wrap-head">
                          <StyledHeadLabel variant="h6">
                            {t("List")}
                          </StyledHeadLabel>
                          <div>
                            <ButtonBlue
                              size="small"
                              startIcon={<AddRoundedIcon />}
                              variant="outlined"
                              onClick={() => {
                                append(dataInitial);
                              }}
                            >
                              {t("AddItem")}
                            </ButtonBlue>
                          </div>
                        </div>

                        <TableContainer>
                          <Table
                            style={{
                              minWidth: 650,
                              borderSpacing: "0 4px",
                              borderCollapse: "separate",
                            }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                {rowHeader.map((item) => (
                                  <StyledTableCell className={item.className}>
                                    <StyledHeadLabel
                                      variant="body2"
                                      color="text.third"
                                      gutterBottom
                                    >
                                      {t(item.caption)}
                                    </StyledHeadLabel>
                                  </StyledTableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {fields &&
                                fields.length > 0 &&
                                fields
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((item, index) => (
                                    <RowBilling
                                      key={item.id}
                                      count={fields.length}
                                      index={index}
                                      open={open}
                                      handleDeleteRow={remove}
                                      calculateSum={calculateSum}
                                      control={control}
                                      errors={errors}
                                      setValue={setValue}
                                      setDifference={setDifference}
                                    />
                                  ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[
                            10,
                            20,
                            30,
                            40,
                            50,
                            { label: t("All"), value: -1 },
                          ]}
                          component="div"
                          count={fields.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${
                            count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
                        />
                      </div>
                      <div>
                        <StyledHeadLabel
                          variant="subtitle1"
                          color="text.third"
                          sx={{ marginBottom: 2 }}
                        >
                          {t("ExcessAssistance")}
                        </StyledHeadLabel>
                        <FormControl
                          fullWidth
                          error={
                            errors &&
                            errors.helpingSurplus &&
                            errors.helpingSurplus
                              ? true
                              : false
                          }
                        >
                          <Controller
                            name={`helpingSurplus`}
                            control={control}
                            errors={errors}
                            render={({ field }) => (
                              <>
                                <TextFieldTheme
                                  {...field}
                                  InputProps={{
                                    inputComponent: NumberFormatTheme,
                                  }}
                                  inputProps={{
                                    decimalScale: 2,
                                    allowNegative: false,
                                    value: field.value,
                                    suffix: " บาท",
                                    isAllowed: (values) => {
                                      const { value } = values;
                                      const max =
                                        parseFloat(getValues(`net`)) -
                                        parseFloat(getValues(`withdrawal`)) +
                                        handleIsNan(
                                          parseFloat(
                                            getValues(`helpingSurplus`)
                                          )
                                        );
                                      return value <= max;
                                    },
                                    onValueChange: (values) => {
                                      const { value } = values;
                                      field.onChange(value);
                                      calculateSum();
                                    },
                                  }}
                                  onChange={() => {}}
                                />
                              </>
                            )}
                          />
                          {errors &&
                            errors.helpingSurplus &&
                            errors.helpingSurplus.message && (
                              <FormHelperText
                                error
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                {errors.helpingSurplus.message}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </div>
                      <StyledDivider />
                      <div className="wrap-head">
                        <StyledHeadLabel variant="h6">
                          {t("SumaryItem")}
                        </StyledHeadLabel>
                        <div>
                          <div className="wrap-row-sum">
                            <Typography color="text.third">
                              {t("TotalValue")}&nbsp;&nbsp;
                            </Typography>
                            <Typography color="text.third" variant="h6">
                              {utils.numberWithCommas(
                                parseFloat(watch("total"))
                              )}
                            </Typography>
                            <Typography color="text.third">
                              &nbsp;&nbsp;{t("Baht")}
                            </Typography>
                          </div>
                          <div className="wrap-row-sum">
                            <Typography color="text.third">
                              {t("TotalWithdrawal")}&nbsp;&nbsp;
                            </Typography>
                            <Typography color="text.third" variant="h6">
                              {utils.numberWithCommas(
                                parseFloat(watch("withdrawal"))
                              )}
                            </Typography>
                            <Typography color="text.third">
                              &nbsp;&nbsp;{t("Baht")}
                            </Typography>
                          </div>
                          <div className="wrap-row-sum">
                            <Typography color="text.third">
                              {t("ExcessWithdrawn")}&nbsp;&nbsp;
                            </Typography>
                            <Typography color="text.third" variant="h6">
                              {utils.numberWithCommas(
                                parseFloat(watch("difference"))
                              )}
                            </Typography>
                            <Typography color="text.third">
                              &nbsp;&nbsp;{t("Baht")}
                            </Typography>
                          </div>
                          <div className="wrap-row-sum">
                            <Typography variant="h5">
                              {t("TotalNetWorth")}&nbsp;&nbsp;
                            </Typography>
                            <Typography variant="h4">
                              {utils.numberWithCommas(parseFloat(watch("net")))}
                            </Typography>
                            <Typography variant="h5">
                              &nbsp;&nbsp;{t("Baht")}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <StyledDivider />
                  <div>
                    <div className="wrap-head">
                      <StyledHeadLabel variant="h6">
                        {t("AttachFile")}
                      </StyledHeadLabel>
                    </div>
                    {!watch("file") ? (
                      <StyledDropzone>
                        <Dropzone
                          accept="image/jpeg, image/png, application/pdf"
                          maxFiles={1}
                          multiple={false}
                          maxSize={3145728}
                          onDrop={(acceptedFiles, rejectedFiles) => {
                            if (acceptedFiles.length > 0) {
                              setValue("file", acceptedFiles[0]);
                            }
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              {...getRootProps({
                                className: "dropzone-upload-file",
                              })}
                            >
                              <div className="inner-dropzone">
                                <input {...getInputProps()} />
                                <div style={{ textAlign: "center" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <img alt="add" src={GalleryIcon} />
                                  </div>
                                  <Typography
                                    variant="caption"
                                    style={{
                                      lineHeight: 0.5,
                                      color: "#999999",
                                    }}
                                  >
                                    {t("imageFileNotMore500KB")}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </StyledDropzone>
                    ) : (
                      <Box
                        style={{
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        <IconButton
                          style={{ position: "absolute", margin: 7, right: 0 }}
                          color="error"
                          onClick={() => {
                            setValue("file", null);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <div
                          style={{
                            padding: 15,
                            backgroundColor: "#f9f9fb",
                            display: "flex",
                            borderRadius: 15,
                          }}
                        >
                          <FilePresentIcon
                            height="24"
                            style={{
                              marginRight: 10,
                            }}
                          ></FilePresentIcon>
                          <Typography>{watch("file.path")}</Typography>
                        </div>
                      </Box>
                    )}
                  </div>
                  <StyledDivider />
                  <div>
                    <div className="wrap-head">
                      <StyledHeadLabel variant="h6">
                        {t("Note")}({t("More")})
                      </StyledHeadLabel>
                    </div>
                    <Controller
                      name={"remark"}
                      control={control}
                      errors={errors}
                      render={({ field }) => (
                        <>
                          <TextFieldTheme {...field} multiline rows={4} />
                          <Typography
                            component="p"
                            textAlign="right"
                            variant="caption"
                            color="text.secondary"
                          >{`${
                            field.value ? field.value.length : 0
                          }/500`}</Typography>
                        </>
                      )}
                    />
                  </div>
                  <WelfareRightUsage
                    isUseForFamily={getValues("isUseForFamilyMember")}
                    idFamily={getValues("idFamily")}
                    idEmployees={getValues("idEmployee")}
                    suggestedIdRightsManage={null}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    idExpenseWelfare={null}
                    idWelfareBilling={null}
                    watch={watch}
                  />
                  <StyledDivider />
                  <ActionButtons
                    handleOnClickCleardata={handleOnClickCleardata}
                    handleSubmitSent={handleSubmit(
                      handleOnClickSent,
                      checkValidation
                    )}
                  />
                </form>
              </Box>
            )}
          </StyledCardContent>
        </StyledCard>
      </Container>
    </StyledRoot>
  );
};

export default WelfareBillingPage;
