import React from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import dayjs from "dayjs";
import ChipLetterStatus from "./chipLetterStatus";
import ChipWarningLevel from "./chipWarningLevel";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserDepartment, getUserFullName, getUserPosition, getUserSection, getUserTitle } from "../../../../utils/userData";
import ButtonBlue from "../../shared/general/ButtonBlue";

const DialogDetail = ({ open, handleClose, data }) => {
  const { t, i18n } = useTranslation();

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth={"sm"}>
      <DialogTitle>{t("WarningLetter")}</DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography
              fontSize={"1.25rem"}
              fontWeight={500}
              textAlign={"center"}
            >
              {getUserTitle(data)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
              <Avatar src={data.imageURL} />
              <Stack flexGrow={1}>
                <Typography
                  fontWeight={700}
                >{`${getUserFullName(data)}`}</Typography>
                <Typography
                  variant="body2"
                  fontWeight={700}
                  color="text.secondary"
                >
                  {`${t("Position")}: ${getUserPosition(data)} `}
                </Typography>
                <Typography variant="body2" color="text.secondary">{`${t(
                  "Department"
                )}: ${getUserDepartment(data)}`}</Typography>
                <Typography variant="body2" color="text.secondary">{`${t(
                  "Section"
                )}: ${getUserSection(data)}`}</Typography>
              </Stack>
              <Stack gap={1} justifyContent={"flex-end"}>
                <Typography
                  variant="subtitle2"
                  color={"text.secondary"}
                  textAlign={"end"}
                  noWrap
                >
                  {`${t("Date")}: ${dayjs(data.createDate).format(
                    "DD/MM/YYYY"
                  )}`}
                </Typography>
                <ChipLetterStatus status={data.accept} />
                {data.file && (
                  <Button
                    variant="outlined"
                    component={Link}
                    href={data.file}
                    target="_blank"
                    startIcon={<AttachFileIcon />}
                  >
                    {t("AttachFile")}
                  </Button>
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography>{t("Descriptions")}</Typography>
            <Box
              marginTop={1}
              padding={1}
              border={"1px solid #212b36"}
              borderRadius={1}
              minHeight={"80px"}
            >
              <Typography>{data.detail}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} display={"flex"} gap={2}>
            <Typography fontSize={"1.25rem"} fontWeight={500}>
              {t("WarningLevel")}
            </Typography>
            <ChipWarningLevel id={data.idWarningLevel} label={data.level} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonBlue variant="contained" onClick={handleClose}>{t("Close")}</ButtonBlue>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDetail;
