import React, { useEffect } from "react"
import DrawerCustom from "../../../../shared/general/Drawer";
import { Autocomplete, Box, Checkbox, FormControlLabel, Grid, MenuItem, Typography, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import DatePickerCustom from "../../../../shared/date/datePicker";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getAffiliateShiftGroup } from "../../../../../../actions/affiliate";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import dayjs from "dayjs";
import { getEmployeeShiftChangeHistory, patchEmployeeShiftChangeHistoryStandard } from "../../../../../../actions/employee";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .MuiCheckbox-root": {
    "&.Mui-checked": {
      color: "#46cbe2",
    },
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
  paddingBottom: "8px"
});

const DrawerEditShiftChange = (props) => {

  const { drawerConfig, handleClose, isTemporary = false } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: affiliateShiftGroup } = useSelector(state => state.affiliateShiftGroup);

  const validateYupSchema = yup.object({
    startDate: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
    endDate: yup.mixed().nullable().when("isCurrent", {
      is: false,
      then: (schema) => schema
      .required(t("ThisFieldIsRequired"))
      .test("greaterOrEqualStartDate", t("CanNotSelectDateBeforeStart"), (value, context) => {
        return dayjs(value).isSameOrAfter(dayjs(context.parent.startDate))
      }),
    }),
    shift: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
    shiftGroup: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
  });

  const useHookForm = useForm({
    defaultValues: {
      startDate: null,
      endDate: null,
      isCurrent: false,
      shiftGroup: null,
      shift: null,
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  })

  useEffect(() => {
    if(drawerConfig.isOpen){
      dispatch(getAffiliateShiftGroup())
    }
  }, [drawerConfig.isOpen])

  useEffect(() => {
    if(affiliateShiftGroup && affiliateShiftGroup.length > 0){

      const foundShiftGroup = affiliateShiftGroup.find(item => item.idShiftGroup === drawerConfig.data.idShiftGroup)
      let foundShift = null;

      if(foundShiftGroup){
        foundShift = foundShiftGroup.shift.find(item => item.idShift === drawerConfig.data.idShift)
      }

      useHookForm.resetField("startDate", { defaultValue: drawerConfig.data.startDate? dayjs(drawerConfig.data.startDate): null })
      useHookForm.resetField("endDate", { defaultValue: drawerConfig.data.endDate? dayjs(drawerConfig.data.endDate): null })
      useHookForm.resetField("shiftGroup", { defaultValue: foundShiftGroup? foundShiftGroup: null })
      useHookForm.resetField("shift", { defaultValue: foundShift? foundShift: null })

      if(isTemporary){
        useHookForm.resetField("isCurrent", { defaultValue: false })
      } else if(drawerConfig.data.endDate){
        useHookForm.resetField("isCurrent", { defaultValue: false })
      } else {
        useHookForm.resetField("isCurrent", { defaultValue: true })
      }

    }
  }, [affiliateShiftGroup])

  const isCurrent = useHookForm.watch("isCurrent")
  const shiftGroup = useHookForm.watch("shiftGroup")

  const onSubmit = async (data) => {

    const formData = {
      startDate: dayjs(data.startDate).format("YYYY-MM-DD"),
      endDate: !data.isCurrent? (data.endDate? dayjs(data.endDate).format("YYYY-MM-DD"): null): null,
      idShiftGroup: data.shiftGroup.idShiftGroup,
      idShift: data.shift.idShift,
    }

    const result = await dispatch(patchEmployeeShiftChangeHistoryStandard(employeeProfile.idEmployees, drawerConfig.data.idEmployeeShift, formData));

    if(result && result.status === 200) {
      handleClose();
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));
      dispatch(getEmployeeShiftChangeHistory(employeeProfile.idEmployees))
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }));
    }

  }

  return (
    <DrawerCustom
      title={!isTemporary? `${t("ShiftChange")}`: t("TemporaryShiftChange")}
      anchor={"right"}
      open={drawerConfig.isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledHeadLabel color="text.secondary">
                {`${t("StartDate")}`}
              </StyledHeadLabel>
              <Controller
                name="startDate"
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <DatePickerCustom
                    {...field}
                    views={["year", "month", "day"]}
                    inputFormat="DD MMMM YYYY"
                    openTo="year"
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: t("SelectDate"),
                          readOnly: true
                        }}
                        onBlur={field.onBlur}
                        error={fieldState.error? true : false}
                        helperText={fieldState.error? fieldState.error.message : null}
                      />
                    )}
                    PaperProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "#46cbe2!important",
                        },
                      },
                    }}
                    DialogProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "#46cbe2!important",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box paddingBottom="4px" display="flex" alignItems="center" justifyContent="space-between">
                <Typography fontWeight="600" fontSize="14px" color="text.secondary">{`${t("EndDate")}`}</Typography>
                {!isTemporary && <Controller
                  name={"isCurrent"}
                  control={useHookForm.control}
                  render={({field}) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                          }}
                        />
                      }
                      label={t("Current")}
                      labelPlacement="start"
                    />
                  )}
                />}
              </Box>
              {!isCurrent && <Controller
                name="endDate"
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <DatePickerCustom
                    {...field}
                    views={["year", "month", "day"]}
                    inputFormat="DD MMMM YYYY"
                    openTo="year"
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: t("SelectDate"),
                          readOnly: true
                        }}
                        onBlur={field.onBlur}
                        error={fieldState.error? true : false}
                        helperText={fieldState.error? fieldState.error.message : null}
                      />
                    )}
                    PaperProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "#46cbe2!important",
                        },
                      },
                    }}
                    DialogProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "#46cbe2!important",
                        },
                      },
                    }}
                  />
                )}
              />}
            </Grid>
            <Grid item xs={12}>
              <StyledHeadLabel color="text.secondary">
                {`${t("ShiftGroup")}`}
              </StyledHeadLabel>
              <Controller
                name="shiftGroup"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <Autocomplete
                    {...field}
                    options={affiliateShiftGroup? affiliateShiftGroup: []}
                    getOptionLabel={(option) => `${option.shiftGroupName}`}
                    isOptionEqualToValue={(option, value) => option.idShiftGroup === value.idShiftGroup}
                    sele
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.idShiftGroup}>
                        <Box>
                          <Typography>{option.shiftGroupName}</Typography>
                          <Typography color="text.secondary" fontSize="12px">{option.companyName}</Typography>
                        </Box>
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        placeholder={t("SelectData")}
                        onBlur={field.onBlur}
                        error={fieldState.error? true: false}
                        helperText={fieldState.error? fieldState.error.message: null}
                      />
                    )}
                    value={field.value}
                    onChange={(_, value) => {
                      field.onChange(value)
                      useHookForm.setValue("shift", null)
                    }}
                    noOptionsText={t("NoData")}
                    disableClearable
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <StyledHeadLabel color="text.secondary">
                {`${t("ShiftName")}`}
              </StyledHeadLabel>
              <Controller
                name="shift"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <Autocomplete
                    {...field}
                    options={shiftGroup? shiftGroup.shift: []}
                    getOptionLabel={(option) => `${option.shiftName}`}
                    isOptionEqualToValue={(option, value) => option.idShift === value.idShift}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.idShift}>
                        <Box>
                          <Typography>{option.shiftName}</Typography>
                        </Box>
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        placeholder={t("SelectData")}
                        onBlur={field.onBlur}
                        error={fieldState.error? true: false}
                        helperText={fieldState.error? fieldState.error.message: null}
                      />
                    )}
                    value={field.value}
                    onChange={(_, value) => {
                      field.onChange(value)
                    }}
                    noOptionsText={t("NoData")}
                    disableClearable
                  />
                )}
              />
            </Grid>
            
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={handleClose} disabled={useHookForm.formState.isSubmitting}>{t("Cancel")}</ButtonBlue>
              <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>{t("Save")}</ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerEditShiftChange;