import React from "react";
import { useSelector } from "react-redux";

import UserApproveList from "../user/requestlist/Requestlist";
import AdminApproveList from "../admin/approveList/index";

const ApproveList = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <div>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ? (
          <AdminApproveList />
        ) : (
          <UserApproveList />
        )
      ) : null}
    </div>
  );
};

export default ApproveList;
