import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Typography,
  Grid,
  styled,
  IconButton,
  Tooltip,
  Chip,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";

const StyledRoot = styled(Box)({});

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(1, 0),
  boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
}));

const IconButtonStyled = styled(IconButton)({
  color: "#DB4178",
  border: "2px solid #DB4178",
  backgroundColor: "transparent",
  borderRadius: "7px",
  "&:hover": {
    color: "#C53B6C",
    border: "2px solid #C53B6C",
    backgroundColor: "transparent",
  },
});

const PlanningPanel = (props) => {
  const { data, selectedQuarter } = props;
  const { t } = useTranslation();

  const renderCards = () => {
    return data
      .filter((item) => {
        if (selectedQuarter === "ALL") {
          return true;
        }
        return item.quarter === selectedQuarter;
      })
      .map((row, index) => {
        const isSuccess = row.isSuccess;
        const statusText =
          isSuccess === 1 ? t("ObjectiveSuccess") : t("InProgress");
        const statusColor = isSuccess === 1 ? "success" : "warning";

        let earliestStartDate = null;
        let latestEndDate = null;

        if (row.keyResults && row.keyResults.length > 0) {
          row.keyResults.forEach((kr) => {
            const krStartDate = dayjs(kr.startDate);
            const krEndDate = dayjs(kr.endDate);

            if (!earliestStartDate || krStartDate.isBefore(earliestStartDate)) {
              earliestStartDate = krStartDate;
            }
            if (!latestEndDate || krEndDate.isAfter(latestEndDate)) {
              latestEndDate = krEndDate;
            }
          });
        }

        const startDateFormatted = earliestStartDate
          ? earliestStartDate.format("DD/MM/YYYY")
          : "-";
        const endDateFormatted = latestEndDate
          ? latestEndDate.format("DD/MM/YYYY")
          : "-";

        return (
          <Grid item xs={12} key={index}>
            <StyledCard>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={2} md={1}>
                  <Box display="flex" justifyContent="center">
                    <Avatar
                      style={{
                        backgroundColor: "#F8D7DA",
                        color: "#DB4178",
                        width: 80,
                        height: 80,
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="caption"
                        style={{
                          lineHeight: 1,
                          fontSize: "14px",
                          color: "#DB4178",
                        }}
                      >
                        {t("Quarter")}
                      </Typography>
                      <Typography
                        variant="h5"
                        style={{
                          lineHeight: 1,
                          fontSize: "24px",
                          fontWeight: "bold",
                          color: "#DB4178",
                        }}
                      >
                        {row.quarter}
                      </Typography>
                    </Avatar>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {row.objectiveName}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t("EmployeeName")}:{" "}
                    {`${row.firstname_TH} ${row.lastname_TH}`}
                  </Typography>

                  <Typography variant="body2" style={{ marginTop: "8px" }}>
                    {t("NumberKeyResults")}:{" "}
                    <strong>{row.keyResults.length}</strong> {t("Unit.Items")}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <Box>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      {t("Date")}
                    </Typography>
                    <Typography variant="body2">
                      {startDateFormatted} - {endDateFormatted}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4} md={2}>
                  <Chip
                    label={statusText}
                    color={statusColor}
                    variant="outlined"
                    style={{ fontWeight: "bold" }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <Box display="flex" justifyContent="flex-end" gap={1}>
                    <Tooltip title={t("ViewDetails")}>
                      <IconButtonStyled
                        component={Link}
                        to={`/okrs/individual-evaluation/${row.idIndividualObjective}`}
                      >
                        <SearchIcon fontSize="small" />
                      </IconButtonStyled>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </StyledCard>
          </Grid>
        );
      });
  };

  return (
    <StyledRoot>
      <Box>
        <Grid container spacing={2}>
          {renderCards()}
        </Grid>
      </Box>
    </StyledRoot>
  );
};

export default PlanningPanel;
