import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  Fab,
  Grid,
  Icon,
  MenuItem,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import CardData from "../../shared/dashboard/CardData";
import { useDispatch, useSelector } from "react-redux";
import Utils from "../../../../utils";
import CardDashboard from "../../shared/dashboard/CardDashboard";
import ChartPolarAreaCustom from "../../shared/dashboard/ChartPolarArea";
import ChartAreaCustom from "../../shared/dashboard/ChartArea";
import dayjs from "dayjs";
import TableCustom from "../../shared/tableCustom";
import EmployeeInOut from "./employeeInOut";
import GaugeChart from "react-gauge-chart";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../utils/userData";
import ButtonBlue from "../../shared/general/ButtonBlue";
// import Chart from "react-apexcharts";
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import { useHistory } from "react-router-dom";
import { getOverviewOTPayroll } from "../../../../actions/dashboard";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import CustomizingCalendar from "./Calendar";
import { allHoliday } from "../../../../actions/holiday";
import { getLeave } from "../../../../actions/overviewUser";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StyledRoot = styled(Box)({
  "& .top-employee": {
    padding: "8px",
    // paddingTop: "16px",
    display: "flex",
    alignItems: "center",
    border: "1px solid #ececec",
    borderRadius: "4px",
  },
});

const OverviewPanel = (props) => {
  const { month, year, selectedCompany,userProfile,today } = props;
  const { t, i18n } = useTranslation();
  const [otPayroll, setOtPayroll] = useState(null);
  const [filteredType, setFilteredType] = useState(1);
  const history = useHistory();
  const dispatch = useDispatch();

  const { result: dashboardOverview } = useSelector((state) => state.dashboardOverview);
  const { result: holiday, isFetching: holidayFetching } = useSelector((state) => state.holiday);
  const { result: overviewUserLeave, isFetching: overviewUserLeaveFetching } = useSelector((state) => state.overviewUserLeave);
  
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
  });
  

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: filteredType === 1 ? `${t("%OTexpensesOfEachSection")}` : `${t("%OTexpensesOfEachDepartment")}`
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const fetchedOTPayrollData = async (query) => {
    try {
      const result = await getOverviewOTPayroll(query);
      if (result && result.status === 200 && result.data) {
        setOtPayroll(result.data);
      };
    } catch (error) {
      console.error(error);
    };
  };

  useEffect(() => {
    if (selectedCompany) {
      fetchedOTPayrollData({
        idCompany: selectedCompany && selectedCompany.idCompany,
        month: month,
        year: year
      });
      dispatch(allHoliday(dayjs(new Date()).format("YYYY"), { idEmployees: userProfile.idEmployees }));
    };
  }, [selectedCompany]);

  useEffect(() => {
    if (userProfile) {
      const start = today.startOf('year').format("YYYY-MM-DD");
      const end = today.endOf('year').format("YYYY-MM-DD");
      dispatch(allHoliday(dayjs(new Date()).format("YYYY"), { idEmployees: userProfile.idEmployees }));
      dispatch(getLeave({ employee: userProfile.idEmployees, start, end }));
    }
  }, [userProfile])

  useEffect(() => {
    if (filteredType === 1) {
      if (otPayroll && otPayroll.sectionList && otPayroll.sectionList.length > 0) {
        const newColors = otPayroll.sectionList.map(() => generateRandomColor());

        setChartData(prevState => ({
          ...prevState,
          labels: otPayroll.sectionList.map(item => item.sectionName),
          datasets: [
            {
              ...prevState.datasets[0],
              data: otPayroll.sectionList.map(item => item.percentOfOTCompareSalary),
              backgroundColor: newColors,
              borderColor: newColors,
            },
          ],
        }));
      }
    } else {
      if (otPayroll && otPayroll.departmentList && otPayroll.departmentList.length > 0) {
        const newColors = otPayroll.departmentList.map(() => generateRandomColor());

        setChartData(prevState => ({
          ...prevState,
          labels: otPayroll.departmentList.map(item => item.departmentName),
          datasets: [
            {
              ...prevState.datasets[0],
              data: otPayroll.departmentList.map(item => item.percentOfOTCompareSalary),
              backgroundColor: newColors,
              borderColor: newColors,
            },
          ],
        }));
      }
    }
  }, [otPayroll, filteredType]);

  const leaveColumns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageProfile}
          />
          <Box flexGrow={1}>
            <Typography>{getUserFullName(row)}</Typography>
            <Typography color="text.third" fontSize="14px">
              {row.positionName}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("Type")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => <Typography fontSize={14}>{row.name}</Typography>,
    },
    {
      name: `${t("Start")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.startText, "DD/MM/YYYY HH:mm").format(
              i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
            )}
          </Typography>
          {row.isFullDay === 1 ? (
            <Typography>{t("FullDay")}</Typography>
          ) : (
            <Typography>
              {dayjs(row.startText, "DD/MM/YYYY HH:mm").format("HH:mm")}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      name: `${t("End")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.endText, "DD/MM/YYYY HH:mm").format(
              i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
            )}
          </Typography>
          {row.isFullDay === 1 ? (
            <Typography>{t("FullDay")}</Typography>
          ) : (
            <Typography>
              {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("HH:mm")}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      name: `${t("Description")}`,
      minWidth: "200px",
      cellRender: (row) => (
        <Box>
          <Typography color="text.third" fontSize="14px">
            {row.description}
          </Typography>
        </Box>
      ),
    },
  ];

  const handleSelectType = (e) => {
    setFilteredType(e.target.value);
  };


  const getSummariesManager = (leave = [], holiday = [], employeeList = []) => {
    const leaveArray = Array.isArray(leave) ? leave : [];
    const holidayArray = Array.isArray(holiday) ? holiday : [];
    const employeeListArray = Array.isArray(employeeList) ? employeeList : [];
  
    const getEmployeePosition = (idEmployees) => {
      const employee = employeeListArray.find((item) => item.idEmployees === idEmployees);
      if (!employee) return '';
      return i18n.resolvedLanguage === 'th'
        ? employee.positionName
        : employee.positionName_EN || employee.positionName || '';
    };
  
    const leaveJSON = leaveArray.map(event => ({
      start: event.start ? new Date(dayjs(event.start)) : new Date(dayjs(event.dateHoliday || new Date()).startOf('day')),
      end: event.end ? (event.end === event.start ? new Date(dayjs(event.end).endOf('day')) : new Date(dayjs(event.end))) : new Date(dayjs(event.dateHoliday || new Date()).endOf('day')),
      title: `${i18n.resolvedLanguage === 'th' ? event.firstname_TH || event.firstname_EN : event.firstname_EN || event.firstname_TH} ${i18n.resolvedLanguage === 'th' ? event.lastname_TH || event.lastname_EN : event.lastname_EN || event.lastname_TH} : ${i18n.resolvedLanguage === 'th' ? event.name || event.name_EN : event.name_EN || event.name}`,
      img: event.ImageURL || 'https://example.com/image1.jpg',
      name: i18n.resolvedLanguage === 'th'
      ? event.firstname_TH
      : event.firstname_EN !== null ? event.firstname_EN : event.firstname_TH || "",
      holiday: false,
      position: getEmployeePosition(event.idEmployees),
    }));
  
    const holidayJSON = holidayArray.map(event => ({
      start: event.start ? new Date(dayjs(event.start)) : new Date(dayjs(event.dateHoliday || new Date()).startOf('day')),
      end: event.end ? (event.end === event.start ? new Date(dayjs(event.end).endOf('day')) : new Date(dayjs(event.end))) : new Date(dayjs(event.dateHoliday || new Date()).endOf('day')),
      title: i18n.resolvedLanguage === 'th'
        ? event.name
        : event.name_EN || event.name || event.description || "",
      img: '/assets/icon/sunbed.png',
      holiday: true,
    }));
  
    return [...leaveJSON, ...holidayJSON];
  };
  

  const getBirthdayManager = (employeeList = []) => {
    const employeeListArray = Array.isArray(employeeList) ? employeeList : [];

    const getEmployeePosition = (event) => {
      if (!event) return '';
      return i18n.resolvedLanguage === 'th'
        ? event.positionName
        : event.positionName_EN || event.positionName || '';
    };
  

    const employeeListJSON = employeeListArray.flatMap(event => {
      const years = [dayjs().year() - 1, dayjs().year(), dayjs().year() + 1];

      return years.map(year => {
        const birthday = event.birthday
          ? dayjs(event.birthday).set('year', year)
          : null;

        const title = i18n.resolvedLanguage === 'th'
          ? `${event.firstname_TH || ''} ${event.lastname_TH || ''}`
          : `${event.firstname_EN || ''} ${event.lastname_EN || ''}` || "Unnamed Event";

        return {
          start: birthday && birthday.startOf('day').toDate() || null,
          end: birthday && birthday.endOf('day').toDate() || null,
          title: title,
          img: event.ImageURL || 'https://example.com/image1.jpg',
          holiday: false,
          position:getEmployeePosition(event)
        };
      });
    });

    return employeeListJSON;
  };

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={2}>
                {dashboardOverview && dashboardOverview.companyEmployeeInfo && (
                  <Grid item xs={12} md={6}>
                    <CardData
                      title={`${t("Employee")}`}
                      dataValue={Utils.numberWithCommasWithoutDot(dashboardOverview.companyEmployeeInfo.count)}
                      dataUnit={`${t("Person")}`}
                      dynamicFontSize
                      titleIcon={
                        <Icon
                          baseClassName="fal"
                          className="fa-users"
                          color="primary"
                          style={{ display: "inline-table" }}
                        />
                      }
                    />
                  </Grid>
                )}

                {dashboardOverview && dashboardOverview.companySalaryInfo && (
                  <Grid item xs={12} md={6}>
                    <CardData
                      title={`${t("SalaryTotal")}`}
                      // dataValue={Utils.numberWithCommas(
                      //   dashboardOverview.salaryTotal
                      // )}
                      dataValue={Utils.numberWithCommas(dashboardOverview.companySalaryInfo.totalSalary)}
                      dataUnit={`${t("Baht")}`}
                      dynamicFontSize
                      titleIcon={
                        <Icon
                          className="fal fa-sack-dollar"
                          color="warning"
                          style={{ display: "inline-table", color: "#ffcd38" }}
                        />
                      }
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  <CardData
                    title={`${t("OTOVer36Hr")}`}
                    // dataValue={[{name: "1", value: "20"}, {name: "2", value: "30"}]}
                    dataValue={dashboardOverview && dashboardOverview.employeeOTOver36Total ? dashboardOverview.employeeOTOver36Total.weekInMonth.map(
                      (week) => {
                        return {
                          name: `${dayjs(week.weekStartDateText).format(
                            "dd D MMM"
                          )} - ${dayjs(week.weekEndDateText).format(
                            "dd D MMM"
                          )}`,
                          value: week.over36HoursEmployeeTotal,
                        };
                      }
                    ) : []}
                    dataUnit={`${t("Person")}`}
                    dynamicFontSize
                    select
                    titleIcon={
                      <Icon
                        className="far fa-alarm-exclamation"
                        color="error"
                        style={{ display: "inline-table" }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardData
                    title={`${t("OTTotal")}`}
                    dataValue={dashboardOverview && dashboardOverview.otTotal ? Utils.numberWithCommas(
                      Object.keys(dashboardOverview.otTotal).reduce(
                        (a, b) => a + dashboardOverview.otTotal[b].payTotal,
                        0
                      )
                    ) : 0}
                    dataUnit={`${t("Baht")}`}
                    dynamicFontSize
                    titleIcon={
                      <Icon
                        className="fal fa-hand-holding-usd"
                        color="warning"
                        style={{ display: "inline-table", color: "#ffcd38" }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={6}>
              <CardDashboard>
                <Typography marginBottom="16px">
                  {t("SalaryTotal")} ({t("Yearly")} {year})
                </Typography>
                <ChartAreaCustom
                  series={[
                    {
                      name: "รายจ่าย",
                      data: dashboardOverview && dashboardOverview.salaryTotalAllYear ? dashboardOverview.salaryTotalAllYear.map((s) => {

                        return {
                          x: dayjs()
                            .month(s.month - 1)
                            .format("MMM"),
                          y: s.salaryTotal,
                        };
                      }) : [],
                    },
                  ]}
                />
              </CardDashboard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <CardDashboard>
                <Typography marginBottom="16px">
                  {t("OTTotalByType")}
                </Typography>
                <ChartPolarAreaCustom
                  series={dashboardOverview && dashboardOverview.otTotal ? Object.keys(dashboardOverview.otTotal).map(
                    (ot) => dashboardOverview.otTotal[ot].payTotal
                  ) : []}
                  labels={dashboardOverview && dashboardOverview.otTotal ? Object.keys(dashboardOverview.otTotal).map(
                    (ot) => `OT x${ot}`
                  ) : []}
                />
              </CardDashboard>
            </Grid>

            <Grid item xs={12} lg={6}>
              <CardDashboard>
                <Typography marginBottom="16px">
                  {t("OTTotal")} ({t("Yearly")} {year})
                </Typography>
                <ChartAreaCustom
                  series={[
                    {
                      name: "รายจ่าย",
                      data: dashboardOverview && dashboardOverview.otTotalAllYear ? dashboardOverview.otTotalAllYear.map((s) => {
                        return {
                          x: dayjs()
                            .month(s.month - 1)
                            .format("MMM"),
                          y: s.payTotal,
                        };
                      }) : [],
                    },
                  ]}
                />
              </CardDashboard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <CardDashboard>
                <Typography marginBottom="16px">
                  {t("OvertimeExpensesComparedToTotalSalary")}
                </Typography>
                {dashboardOverview && dashboardOverview.otTotal && dashboardOverview.companySalaryInfo && (
                  <GaugeChart
                    nrOfLevels={420}
                    arcsLength={[0.2, 0.5, 0.3]}
                    colors={["#47c2c2", "#ffcd56", "#ff5722"]}
                    percent={
                      Object.keys(dashboardOverview.otTotal).length > 0 && (dashboardOverview.companySalaryInfo && dashboardOverview.companySalaryInfo.totalSalary) > 0
                        ? (Object.keys(dashboardOverview.otTotal).reduce((a, b) => a + dashboardOverview.otTotal[b].payTotal, 0) / (dashboardOverview.companySalaryInfo && dashboardOverview.companySalaryInfo.totalSalary))
                        : 0.0
                    }
                    arcPadding={0.02}
                    textColor="#222f3e"
                  />
                )}
              </CardDashboard>
            </Grid>

            <Grid item xs={12} lg={6}>
              <CardDashboard>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography>
                    {t("Top5highestOTexpenses")}
                  </Typography>

                  <TextFieldTheme
                    variant="outlined"
                    select
                    label={t("ChooseField")}
                    sx={{ width: 200 }}
                    value={filteredType}
                    onChange={(e) => handleSelectType(e)}
                  >
                    <MenuItem value={1}>{t("Section")}</MenuItem>
                    <MenuItem value={2}>{t("Department")}</MenuItem>
                  </TextFieldTheme>
                </Stack>
                <Stack sx={{ ml: 1, mr: 1 }}>
                  {filteredType === 1 && otPayroll && otPayroll.top5result && otPayroll.top5result.length > 0 &&
                    otPayroll.top5result.map((item, index) => (
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        key={index}
                        sx={{
                          padding: "8px 0",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        <Icon
                          className={`fa-solid fa-square-${index + 1}`}
                          color="primary"
                          style={{
                            display: "inline-table",
                            color: "#ffcd38",
                          }}
                        />
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          sx={{ width: "100%", pl: 2, pr: 2 }}
                        >
                          <Typography variant="body1" sx={{ flexGrow: 1 }}>
                            {item.sectionName}
                          </Typography>
                          <Typography variant="body1">
                            {item.totalOT > 0
                              ? item.totalOT.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                              : "0.00"}
                          </Typography>
                        </Stack>
                      </Stack>
                    ))}

                  {filteredType === 2 && otPayroll && otPayroll.top5DepartmentResult && otPayroll.top5DepartmentResult.length > 0 &&
                    otPayroll.top5DepartmentResult.map((item, index) => (
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        key={index}
                        sx={{
                          padding: "8px 0",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        <Icon
                          className={`fa-solid fa-square-${index + 1}`}
                          color="primary"
                          style={{
                            display: "inline-table",
                            color: "#ffcd38",
                          }}
                        />
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          sx={{ width: "100%", pl: 2, pr: 2 }}
                        >
                          <Typography variant="body1" sx={{ flexGrow: 1 }}>
                            {item.departmentName}
                          </Typography>
                          <Typography variant="body1">
                            {item.totalOT > 0
                              ? item.totalOT.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                              : "0.00"}
                          </Typography>
                        </Stack>
                      </Stack>
                    ))}
                </Stack>
              </CardDashboard>
            </Grid>

          </Grid>
        </Grid>

        <Grid item xs={12}>
          <CardDashboard>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
              {filteredType === 1 ? (
                <Typography>
                  {t("%OTexpensesOfEachSection")}
                </Typography>
              ) : (
                <Typography>
                  {t("%OTexpensesOfEachDepartment")}
                </Typography>
              )}

              <TextFieldTheme
                variant="outlined"
                select
                label={t("ChooseField")}
                sx={{ width: 200 }}
                value={filteredType}
                onChange={(e) => handleSelectType(e)}
              >
                <MenuItem value={1}>{t("Section")}</MenuItem>
                <MenuItem value={2}>{t("Department")}</MenuItem>
              </TextFieldTheme>
            </Box>

            {filteredType === 1 ? (
              <>
                {otPayroll && otPayroll.sectionList && otPayroll.sectionList.length > 4 ? (
                  <Bar data={chartData} options={options} />
                ) : (
                  <Grid
                    container
                    justifyContent={otPayroll && otPayroll.sectionList && otPayroll.sectionList.length <= 2 ? "center" : "space-around"}
                    alignItems="center"
                    spacing={2}
                  >
                    {otPayroll && otPayroll.sectionList && otPayroll.sectionList.map((item, index) => (
                      <Grid
                        item
                        xs={otPayroll.sectionList.length === 1 ? 6 : 12}
                        sm={otPayroll.sectionList.length <= 2 ? 6 : 4}
                        key={index}
                      >
                        <Typography variant="h6" align="center">
                          {item.sectionName}
                        </Typography>
                        <GaugeChart
                          nrOfLevels={420}
                          arcsLength={[0.2, 0.5, 0.3]}
                          colors={["#47c2c2", "#ffcd56", "#ff5722"]}
                          percent={item.percentOfOTCompareSalary > 0 ? item.percentOfOTCompareSalary / 100 : 0.00}
                          arcPadding={0.02}
                          textColor="#222f3e"
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </>
            ) : (
              <>
                {otPayroll && otPayroll.departmentList && otPayroll.departmentList.length > 4 ? (
                  <Bar data={chartData} options={options} />
                ) : (
                  <Grid
                    container
                    justifyContent={otPayroll && otPayroll.departmentList && otPayroll.departmentList.length <= 2 ? "center" : "space-around"}
                    alignItems="center"
                    spacing={2}
                  >
                    {otPayroll && otPayroll.departmentList && otPayroll.departmentList.map((item, index) => (
                      <Grid
                        item
                        xs={otPayroll.departmentList.length === 1 ? 6 : 12}
                        sm={otPayroll.departmentList.length <= 2 ? 6 : 4}
                        key={index}
                      >
                        <Typography variant="h6" align="center">
                          {item.departmentName}
                        </Typography>
                        <GaugeChart
                          nrOfLevels={420}
                          arcsLength={[0.2, 0.5, 0.3]}
                          colors={["#47c2c2", "#ffcd56", "#ff5722"]}
                          percent={item.percentOfOTCompareSalary > 0 ? item.percentOfOTCompareSalary / 100 : 0.00}
                          arcPadding={0.02}
                          textColor="#222f3e"
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </>
            )}
          </CardDashboard>
        </Grid>

        {otPayroll && otPayroll.totalCompanyOT > 0 && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <CardDashboard>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <TextFieldTheme
                      variant="outlined"
                      select
                      label={t("ChooseField")}
                      sx={{ width: 200 }}
                      value={filteredType}
                      onChange={(e) => handleSelectType(e)}
                    >
                      <MenuItem value={1}>{t("Section")}</MenuItem>
                      <MenuItem value={2}>{t("Department")}</MenuItem>
                    </TextFieldTheme>
                  </Box>
                  <Bar data={chartData} options={options} />
                </CardDashboard>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <CardDashboard>
                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="16px">
                  <Typography>
                    {`${t("Leave")} `}
                    {dayjs()
                      .month(month - 1)
                      .year(year)
                      .format("MMMM YYYY")}
                  </Typography>
                  <ButtonBlue
                    endIcon={<NavigateNextRoundedIcon />}
                    onClick={() => {
                      history.push({
                        pathname: "/admin/history-approve-list",
                        state: {
                          start: dayjs().year(year).month(month - 1).date(1).format("YYYY-MM-DD"),
                          end: dayjs().year(year).month(month - 1).date(dayjs().year(year).month(month - 1).daysInMonth()).format("YYYY-MM-DD"),
                          tab: 2,
                        }
                      })
                    }}
                  >
                    {t("All")}
                  </ButtonBlue>
                </Box>
                <TableCustom
                  columns={leaveColumns}
                  rows={dashboardOverview && dashboardOverview.workingTimeEmployeeInfo ? dashboardOverview.workingTimeEmployeeInfo.leave.sort(
                    (a, b) =>
                      dayjs(a.startText, "DD/MM/YYYY").isBefore(
                        dayjs(b.startText, "DD/MM/YYYY")
                      )
                        ? -1
                        : 1
                  ).slice(0, 8) : []}
                  rowsPerPageProp={5}
                  rowsPerPageOptions={[5]}
                />
              </CardDashboard>
            </Grid>
            <Grid item xs={12} lg={4}>
              <CardDashboard>
                <Typography marginBottom="16px">
                  {`${t("Top5Absent")} `}
                  {dayjs()
                    .month(month - 1)
                    .format("MMMM")}
                </Typography>
                {dashboardOverview && dashboardOverview.workingTimeEmployeeInfo && dashboardOverview.workingTimeEmployeeInfo.top5AbsentEmployees
                  .length > 0 && (
                    <Box
                      style={{
                        display: "flex",
                        gap: "16px",
                        flexDirection: "column",
                      }}
                    >
                      {dashboardOverview && dashboardOverview.workingTimeEmployeeInfo.top5AbsentEmployees.map(
                        (e, index) => (
                          <Box key={index} className="top-employee">
                            <Icon
                              className={`fa-solid fa-square-${index + 1}`}
                              color="primary"
                              style={{
                                display: "inline-table",
                                color: index <= 2 ? "#ffcd38" : undefined,
                              }}
                            />
                            <Box
                              style={{
                                flexGrow: "1",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Avatar
                                src={e.imageProfile}
                                style={{
                                  marginRight: "8px",
                                  width: "32px",
                                  height: "32px",
                                }}
                              />
                              <Typography>{getUserFullName(e)}</Typography>
                            </Box>
                            <Typography>
                              {e.absentTotal} {t("Unit.Days")}
                            </Typography>
                          </Box>
                        )
                      )}
                    </Box>
                  )}

                {dashboardOverview && dashboardOverview.workingTimeEmployeeInfo && dashboardOverview.workingTimeEmployeeInfo.top5AbsentEmployees
                  .length <= 0 && (
                    <Typography textAlign="center" color="text.third">
                      {t("NoAbsent")}
                    </Typography>
                  )}
              </CardDashboard>
            </Grid>
          </Grid>
        </Grid>

        {dashboardOverview && dashboardOverview.companyEmployeeInfo && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <CardData
                  title={`${t("EmployeeIn")}`}
                  dataValue={String(
                    // dashboardOverview.employeeInfo.employeeInTotal
                    dashboardOverview.companyEmployeeInfo.newEmployee
                  )}
                  dataUnit={`${t("Person")}`}
                  dynamicFontSize
                  titleIcon={
                    <Icon
                      className="fas fa-transporter"
                      color="success"
                      style={{ display: "inline-table" }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <CardData
                  title={`${t("EmployeeOut")}`}
                  dataValue={String(
                    // dashboardOverview.employeeInfo.employeeOutTotal
                    dashboardOverview.companyEmployeeInfo.resignEmployee
                  )}
                  dataUnit={`${t("Person")}`}
                  dynamicFontSize
                  titleIcon={
                    <Icon
                      className="fas fa-person-circle-xmark"
                      color="error"
                      style={{ display: "inline-table" }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <CardData
                  title={t("TurnoverRate")}
                  dataValue={dashboardOverview.companyEmployeeInfo.turnOverRate}
                  dataUnit="%"
                  dynamicFontSize
                  titleIcon={
                    <Icon
                      className="fas fa-chart-line"
                      color="primary"
                      style={{ display: "inline-table" }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <CardData
                  title={t("TurnoverYear")}
                  dataValue={dashboardOverview.companyEmployeeInfo.turnOverRateYear}
                  dataUnit="%"
                  dynamicFontSize
                  titleIcon={
                    <Icon
                      className="fas fa-calendar-days"
                      color="primary"
                      style={{ display: "inline-table" }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <CardData
                  title={`${t("Male")}`}
                  // dataValue={String(dashboardOverview.employeeInfo.maleTotal)}
                  dataValue={String(dashboardOverview.companyEmployeeInfo.male)}
                  dataUnit={`${t("Person")}`}
                  dynamicFontSize
                  titleIcon={
                    <Icon
                      className="fas fa-mars"
                      color="warning"
                      style={{ display: "inline-table", color: "#35baf6" }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <CardData
                  title={`${t("Female")}`}
                  // dataValue={String(dashboardOverview.employeeInfo.femaleTotal)}
                  dataValue={String(dashboardOverview.companyEmployeeInfo.female)}
                  dataUnit={`${t("Person")}`}
                  dynamicFontSize
                  titleIcon={
                    <Icon
                      className="fas fa-venus"
                      color="warning"
                      style={{ display: "inline-table", color: "#ed4b82" }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <CardData
                  title={`${t("AverageAge")}`}
                  // dataValue={String(dashboardOverview.employeeInfo.averageAge)}
                  dataValue={String(dashboardOverview.companyEmployeeInfo.avgAge)}
                  dataUnit={`${t("Year")}`}
                  titleIcon={
                    <Icon
                      className="fas fa-people-group"
                      color="primary"
                      style={{ display: "inline-table" }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <CardData
                  title={`${t("AverageWork")}`}
                  // dataValue={String(
                  //   dashboardOverview.employeeInfo.averageWorkExperience
                  // )}
                  dataValue={String(dashboardOverview.companyEmployeeInfo.avgWorkingYear)}
                  dataUnit={`${t("Year")}`}
                  titleIcon={
                    <Icon
                      className="fas fa-briefcase"
                      color="primary"
                      style={{ display: "inline-table" }}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <CardDashboard>
            <Typography marginBottom="16px">
              {`${t("EmployeeIn")}`} - {`${t("EmployeeOut")} `}
              {dayjs()
                .month(month - 1)
                .format("MMMM")}
            </Typography>
            <EmployeeInOut />
          </CardDashboard>
        </Grid>

        {!overviewUserLeaveFetching && <Grid item xs={12} sx={{overflow:'auto'}}>
                <CustomizingCalendar t={t} i18n={i18n} events={getSummariesManager(overviewUserLeave && overviewUserLeave.leave, holiday,overviewUserLeave && overviewUserLeave.EmployeeResult)} birthdayList={getBirthdayManager(overviewUserLeave && overviewUserLeave.EmployeeResult)} />
        </Grid>}
      </Grid>
    </StyledRoot >
  );
};

export default OverviewPanel;
