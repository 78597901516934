import React from "react";
import { Avatar, Box, Typography, styled } from "@mui/material";
import CardStyle from "../../shared/general/Card";

const StyledRoot = styled(Box)({
  padding: "10px 8px 8px",
  userSelect: "none",
  transition: "all 1s ease"
})

const NodeStyle = (props) => {

  const { data, setIsExpand } = props;

  return (
    <Box display="inline-block">
      <CardStyle style={{width: "240px", borderRadius: "4px"}}>
        <StyledRoot>
          <Box display="flex">
            <Typography fontSize="14px">{data.title}</Typography>
          </Box>
          <Box display="flex" marginTop="4px" gap="8px">
            <Avatar />
            <Box flexGrow="1" display="flex" flexDirection="column" alignItems="flex-start">
              <Typography fontSize="14px">{data.name}</Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Box onClick={() => {setIsExpand(prev => !prev)}} border="1px solid #919eab" borderRadius="4px" style={{cursor: "pointer"}} padding="4px">
              <Typography fontSize="12px" lineHeight="1">{data.child.length}</Typography>
            </Box>
          </Box>
        </StyledRoot>
      </CardStyle>
    </Box>
  )
}

export default NodeStyle;
