import {
  EXIT_INTERVIEW_EMPLOYEES_FETCHING,
  EXIT_INTERVIEW_EMPLOYEES_FAILED,
  EXIT_INTERVIEW_EMPLOYEES_SUCCESS,
} from "../actions/types";

const initialState = {
  result: null,
  isFetching: false,
  isError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case EXIT_INTERVIEW_EMPLOYEES_FETCHING:
      return { ...state, result: null, isFetching: true, isError: false };
    case EXIT_INTERVIEW_EMPLOYEES_FAILED:
      return { ...state, result: null, isFetching: false, isError: true };
    case EXIT_INTERVIEW_EMPLOYEES_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    default:
      return state;
  }
}
