import React from "react";
import { Box, IconButton, InputAdornment, styled } from "@mui/material";
import TextFieldTheme from "./TextFieldTheme";
import NumberFormatTheme from "./NumberFormatTheme";

const StyledRoot = styled(Box)({
  "& .MuiIconButton-root": {
    color: "#9e9e9e66"
  },
  "& input": {
    textAlign: "center",
  }
})

const NumberInputWithButton = React.forwardRef((props, ref) => {

  const {onChange, value} = props;

  const onClickPlus = () => {
    onChange(Number(value) + 1);
  }

  const onClickMinus = () => {
    if(value > 0){
      onChange(Number(value) - 1);
    }
  }

  return (
    <StyledRoot>
      <TextFieldTheme
        ref={ref}
        value={value}
        onChange={onChange}
        {...props}
        InputProps={{
          inputComponent: NumberFormatTheme,
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                onClick={onClickMinus}
              >
                <i className="fa-regular fa-circle-minus"></i>
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={onClickPlus}
              >
                <i className="fa-regular fa-circle-plus"></i>
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputProps={{
          allowNegative: false
        }}
      />
    </StyledRoot>
  )
})

export default NumberInputWithButton