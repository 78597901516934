import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  styled,
  Typography,
} from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  width: 450,
  [theme.breakpoints.down("sm")]: {
    width: 270,
  },
}));

const DialogConfirmReject = (props) => {
  const { open, onClose, handleSubmit, rejectCount } = props;
  const [comment, setComment] = useState("");
  const { t, i18n } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <StyledDialogContent>
        <Typography variant="h6">
          {`${t("NotApproved")}`} {rejectCount} {`${t("List")}`}
        </Typography>
        <TextFieldTheme
          style={{ marginTop: 16 }}
          placeholder={`${t("Description")} (${t("Optional")})`}
          onChange={(event) => {
            setComment(event.target.value);
          }}
        />
        <div
          style={{
            width: "100%",
            marginTop: 16,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <ButtonBlue onClick={onClose}>{`${t("Cancel")}`}</ButtonBlue>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleSubmit(comment);
            }}
          >
            {`${t("NotApproved")}`}
          </Button>
        </div>
      </StyledDialogContent>
    </Dialog>
  );
};

export default DialogConfirmReject;
