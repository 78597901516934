import React, { Fragment, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import CardStyle from "../../../shared/general/Card";
import { useDispatch, useSelector } from "react-redux";

import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import {
  deleteManagerGroup,
  getManager,
  getManagerApproveSetting,
  getManagerGroup,
  getManagerSetting,
} from "../../../../../actions/manager";
import DrawerCustom from "../../../shared/general/Drawer";
import DrawerEditPermission from "./DrawerEditPermission";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerEditRoleManager from "./DrawerEditRole";
import { getAllPositions } from "../../../../../actions/positions";
import DrawerGroupManager from "./DrawerGroupManager";
import DialogConfirmDelete from "../../../shared/general/DialogConfirmDelete";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { t } from "i18next";
import { getUserFirstName,getUserFullName, getUserLastName, getUserPosition } from "../../../../../utils/userData";
import { useTranslation } from "react-i18next";
import { ManagerXlsx } from "./ManagerXlsx";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const StyledRoot = styled(Box)({
  marginTop: "30px",
  "& .MuiAccordionSummary-content": {
    alignItems: "center",
  },
});
const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px -10px 20px #EEEEEE",
    position: "sticky",
    left: 0,
    backgroundColor: "#f4f6f8",
    zIndex: 4,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  // "& .position": {
  //   minWidth: 200,
  // },
  // "& .reportTo": {
  //   minWidth: 150,
  // },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "none",
  padding: 16,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .fullname": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .position": {
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    minWidth: 200,
  },
  "& .reportTo": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    minWidth: 150,
    fontWeight: 600,
  },
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const ManagerPanel = (props) => {
  const { selectedCompany, isReadOnly } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  // const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: manager } = useSelector((state) => state.manager);
  const { result: managerSetting } = useSelector((state) => state.managerSetting);
  const { result: managerGroup } = useSelector((state) => state.managerGroup);
  // const { result: positions } = useSelector((state) => state.positions);

  const { result: managerApproveSetting } = useSelector((state) => state.managerApproveSetting);


  const [page, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(10);

  const [drawerPermissionConfig, setDrawerPermissionConfig] = useState({
    open: false,
    idManagerSetting: null,
    topic: "",
    managerLV1: null,
    managerLV2: null,
    idManagerSetting: null,
  });

  const [drawerRoleConfig, setDrawerRoleConfig] = useState({
    open: false,
    isEdit: false,
    values: {
      idEmployees: null,
      firstname_TH: null,
      lastname_TH: null,
      firstname_EN: null,
      lastname_EN: null,
      email: null,
      idRole: null,
      idPosition: null,
    },
  });

  const [drawerGroupManagerConfig, setDrawerGroupManagerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {},
  });

  const [deleteManagerGroupConfig, setDeleteManagerGroupConfig] = useState({
    isOpen: false,
    data: {},
  });

  const onDrawerGroupManagerClose = () => {
    setDrawerGroupManagerConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleCloseDeleteManagerGroupModal = () => {
    setDeleteManagerGroupConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleDeleteManagerGroup = async () => {
    const result = await dispatch(
      deleteManagerGroup(deleteManagerGroupConfig.data.idManagerGroup)
    );

    if (result) {
      if (result.status === 200) {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "success",
        //   message: "บันทึกข้อมูลสำเร็จ"
        // }))
        dispatch(getManagerGroup({ idCompany: selectedCompany.idCompany }));
        handleCloseDeleteManagerGroupModal();
      } else {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "error",
        //   message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
        // }))
      }
    } else {
      // setAlertConfig(prev => ({
      //   ...prev,
      //   isOpen: true,
      //   type: "error",
      //   message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
      // }))
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getManager({ idCompany: selectedCompany.idCompany }));
      // dispatch(getManagerSetting());
      dispatch(getManagerGroup({ idCompany: selectedCompany.idCompany }));
      dispatch(getAllPositions({ idCompany: selectedCompany.idCompany }));
      dispatch(getManagerApproveSetting({ idCompany: selectedCompany.idCompany }))
    }
  }, [selectedCompany]);

  // const getArrayForm = (managerSetting) => {
  //   const groupMap = new Map();

  //   managerSetting.map((setting) => {
  //     if (!groupMap.has(setting.header)) {
  //       groupMap.set(setting.header, {
  //         header: setting.header,
  //         setting: [],
  //       });
  //     }

  //     groupMap.get(setting.header).setting.push(setting);
  //   });

  //   return Array.from(groupMap, ([_, value]) => value);
  // };

  // const renderPermission = (status) => {
  //   if (status === "1") {
  //     return "อนุมัติ";
  //   } else if (status === "0") {
  //     return "ตรวจสอบ";
  //   } else {
  //     return "ไม่ระบุ";
  //   }
  // };

  const handleXlsxExport = () => {
    if (manager) {
      ManagerXlsx(t,manager);
    } else {
      return;
    }
  }

  return (
    <StyledRoot marginTop="30px">
      <Box display="flex">
        <Typography variant="h6" fontSize="24px" flexGrow="1">
          {t("ManagerList")}
        </Typography>
        <ButtonBlue
          variant={"contained"}
          startIcon={<DownloadRoundedIcon />}
          onClick={() => handleXlsxExport()}
          style={{ marginRight: '12px' }}
        >
          {t("DownloadData")}
        </ButtonBlue>
        <ButtonBlue
          variant="contained"
          onClick={() => {
            setDrawerRoleConfig((prev) => ({
              ...prev,
              open: true,
              isEdit: false,
              values: {
                idEmployees: "",
                firstname_TH: "",
                lastname_TH: "",
                firstname_EN: "",
                lastname_EN: "",
                email: "",
                idRole: "",
                idPosition: "",
              },
            }));
          }}
        >
          {t("AddManager")}
        </ButtonBlue>
      </Box>
      {manager && (
        <CardStyle style={{ marginTop: "24px" }}>
          <Box padding="24px">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledCellHeader>{t("FullName")}</StyledCellHeader>
                    <StyledCellHeader>{t("Position")}</StyledCellHeader>
                    <StyledCellHeader>{t("Email")}</StyledCellHeader>
                    <StyledCellHeader>{t("Rights")}</StyledCellHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {manager &&
                    manager
                      .slice(page * rowPerPage, (page + 1) * rowPerPage)
                      .map((m) => (
                        <TableRow key={m.idEmployees}>
                          <StyledCellContent>
                            <Box display="flex" alignItems="center" gap="8px">
                              <IconButton
                                onClick={() => {
                                  setDrawerRoleConfig((prev) => ({
                                    ...prev,
                                    open: true,
                                    isEdit: true,
                                    values: {
                                      idEmployees: m.idEmployees,
                                      firstname_TH: m.firstname_TH,
                                      lastname_TH: m.lastname_TH,
                                      firstname_EN: m.firstname_EN,
                                      lastname_EN: m.lastname_EN,
                                      email: m.email,
                                      idRole: m.idRole,
                                      idPosition: m.idPosition,
                                    },
                                  }));
                                }}
                              >
                                <ModeEditIcon style={{ fontSize: "16px" }} />
                              </IconButton>
                              <Typography lineHeight="1">
                                {getUserFullName(m)}
                              </Typography>
                            </Box>
                          </StyledCellContent>
                          <StyledCellContent>
                            {getUserPosition(m)}
                          </StyledCellContent>
                          <StyledCellContent>{m.email}</StyledCellContent>
                          <StyledCellContent>
                            {m.idRole === 2
                              ? `${t("ManagerUser")}`
                              : m.idRole === 3
                                ? `${t("Manager")}`
                                : ""}
                          </StyledCellContent>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box display="flex" justifyContent="flex-end">
              <TablePagination
                component={"div"}
                count={manager ? manager.length : 0}
                page={page}
                onPageChange={(_, newPage) => {
                  setPage(newPage);
                }}
                rowsPerPage={rowPerPage}
                rowsPerPageOptions={[10, 25]}
                onRowsPerPageChange={(e) => {
                  setRowPerPage(e.target.value);
                }}
                labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${
                            count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
              />
            </Box>
          </Box>
        </CardStyle>
      )}

      {!manager && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}

      <Box marginTop="48px">
        <Box display="flex">
          <Typography variant="h6" fontSize="24px" flexGrow="1">
            {t("ManagerGroup")}
          </Typography>
          <ButtonBlue
            variant="contained"
            onClick={() => {
              setDrawerGroupManagerConfig((prev) => ({
                ...prev,
                isOpen: true,
                isEdit: false,
              }));
            }}
          >
            {t("CreateGroupManager")}
          </ButtonBlue>
        </Box>
        <CardStyle style={{ marginTop: "24px" }}>
          <Box padding="24px">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledCellHeader>{t("GroupManagerName")}</StyledCellHeader>
                    <StyledCellHeader style={{ textAlign: "right" }}>
                      {t("NumberOfManager")}
                    </StyledCellHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {managerGroup &&
                    managerGroup.length > 0 &&
                    managerGroup
                      // .slice(page * rowPerPage, (page + 1) * rowPerPage)
                      .map((m) => (
                        <TableRow key={m.idEmployees}>
                          <StyledCellContent colSpan={2} style={{ padding: 0 }}>
                            <Accordion>
                              <AccordionSummary
                                display="flex"
                                alignItems="center"
                                expandIcon={<ExpandMoreIcon />}
                              >
                                <Box
                                  flexGrow="1"
                                  display="flex"
                                  alignItems="center"
                                  gap="8px"
                                >
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setDeleteManagerGroupConfig((prev) => ({
                                        ...prev,
                                        isOpen: true,
                                        data: {
                                          idManagerGroup: m.idManagerGroup,
                                          managerGroupName: m.managerGroupName,
                                        },
                                      }));
                                    }}
                                  >
                                    <DeleteOutlineRoundedIcon
                                      style={{ fontSize: "16px" }}
                                    />
                                  </IconButton>
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setDrawerGroupManagerConfig((prev) => ({
                                        ...prev,
                                        isOpen: true,
                                        isEdit: true,
                                        data: {
                                          idManagerGroup: m.idManagerGroup,
                                          managerGroupName: m.managerGroupName,
                                          managerList: m.managerList,
                                        },
                                      }));
                                    }}
                                  >
                                    <ModeEditIcon
                                      style={{ fontSize: "16px" }}
                                    />
                                  </IconButton>
                                  <Typography lineHeight="1">
                                    {m.managerGroupName}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography>
                                    {m.managerList.length}
                                  </Typography>
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails>
                                {m.managerList.length > 0 && (
                                  <Grid container spacing={2}>
                                    {m.managerList.map((item, managerIndex) => (
                                      <Fragment>
                                        <Grid item xs={4}>
                                          <Typography
                                            fontSize="14px"
                                            color="text.secondary"
                                          >
                                            {t("ManagerNo")}
                                          </Typography>
                                          <Typography>
                                            {managerIndex + 1}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <Typography
                                            fontSize="14px"
                                            color="text.secondary"
                                          >
                                            {t("FullName")}
                                          </Typography>
                                          <Typography>
                                            {getUserFullName(item)}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <Typography
                                            fontSize="14px"
                                            color="text.secondary"
                                          >
                                            {t("Email")}
                                          </Typography>
                                          <Typography>{item.email}</Typography>
                                        </Grid>
                                      </Fragment>
                                    ))}
                                  </Grid>
                                )}
                                {m.managerList.length === 0 && (
                                  <Typography
                                    fontSize="20px"
                                    color="text.secondary"
                                    fontStyle="italic"
                                    textAlign="center"
                                  >
                                    {t("NoManager")}
                                  </Typography>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          </StyledCellContent>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            {managerGroup && managerGroup.length === 0 && (
              <Typography
                padding="24px 0"
                fontSize="20px"
                color="text.secondary"
                fontStyle="italic"
                textAlign="center"
              >
                {t("NoGroupManager")}
              </Typography>
            )}
          </Box>
        </CardStyle>
      </Box>

      <Typography variant="h6" paddingTop="48px" fontSize="24px">
        {t("Approval")}
      </Typography>

      <CardStyle style={{ marginTop: "24px" }}>
        <Box padding="24px">
          <TableContainer>
            <Table>
              <TableHead>
                <StyledCellHeader>{t("ApprovalType")}</StyledCellHeader>
                <StyledCellHeader align="center">{t("ManagerLV")} 1</StyledCellHeader>
                <StyledCellHeader align="center">{t("ManagerLV")} 2</StyledCellHeader>
              </TableHead>
              <TableBody>

                {managerApproveSetting && managerApproveSetting.map(topic => (
                  <Fragment>
                    <TableRow key={`topic_${topic.idManagerApproveTopic}`}>
                      <StyledCellContent colSpan={3}>
                        <Typography fontSize="18px" fontWeight="500">{t(`${topic.managerApproveTopicKey}`)}</Typography>
                      </StyledCellContent>
                    </TableRow>
                    {topic.managerApproveTypeList.map(type => (
                      <TableRow key={`type${type.idManagerApproveType}`}>
                        <StyledCellContent>
                          <Typography paddingLeft="16px">{t(`${type.managerApproveTypeKey}`)}</Typography>
                        </StyledCellContent>
                        <StyledCellContent align="center" style={{ fontSize: "24px" }}>
                          {type.isManagerLv1 === 1 && (
                            <CheckCircleRoundedIcon fontSize="inherit" color="success" />
                          )}
                        </StyledCellContent>
                        <StyledCellContent align="center" style={{ fontSize: "24px" }}>
                          {type.isManagerLv2 === 1 && (
                            <CheckCircleRoundedIcon fontSize="inherit" color="success" />
                          )}
                        </StyledCellContent>
                      </TableRow>
                    ))}
                  </Fragment>
                ))}

                {/* <TableRow key={m.idEmployees}>
                  <StyledCellContent colSpan={2} style={{ padding: 0 }}></StyledCellContent>
                </TableRow> */}
              </TableBody>
            </Table>

          </TableContainer>
          {/* <Grid container spacing={2}>
            {managerApproveSetting && managerApproveSetting.map(item => (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>{item.managerApproveTopicName}</Typography>
                  </Grid>
                  {item.managerApproveTypeList.map(type => (
                    <Fragment>
                      <Grid item xs={12} sm={4}>
                        <Typography>{type.managerApproveTypeName}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography>{type.isManagerLv1}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography>{type.isManagerLv2}</Typography>
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid> */}
        </Box>
      </CardStyle>

      <DrawerEditRoleManager
        open={drawerRoleConfig.open}
        onClose={() => {
          setDrawerRoleConfig((prev) => ({ ...prev, open: false }));
        }}
        isEdit={drawerRoleConfig.isEdit}
        values={drawerRoleConfig.values}
        selectedCompany={selectedCompany}
        isReadOnly={isReadOnly}
      />

      <DrawerGroupManager
        drawerConfig={drawerGroupManagerConfig}
        onClose={onDrawerGroupManagerClose}
        selectedCompany={selectedCompany}
        isReadOnly={isReadOnly}
      />

      <DrawerEditPermission
        open={drawerPermissionConfig.open}
        onClose={() => {
          setDrawerPermissionConfig((prev) => ({ ...prev, open: false }));
        }}
        topic={drawerPermissionConfig.topic}
        managerLV1={drawerPermissionConfig.managerLV1}
        managerLV2={drawerPermissionConfig.managerLV2}
        idManagerSetting={drawerPermissionConfig.idManagerSetting}
      />

      <DialogConfirmDelete
        open={deleteManagerGroupConfig.isOpen}
        handleClose={handleCloseDeleteManagerGroupModal}
        label={t("ManagerGroup")}
        text={
          <Box>
            <Typography
              fontSize="14px"
              fontWeight="600"
              style={{ color: "#f15e5e" }}
            >
              {deleteManagerGroupConfig.data.managerGroupName}
            </Typography>
            {/* <Typography fontSize="18px" style={{color: "#f15e5e"}}>{deleteManagerGroupConfig.data.firstname_TH} </Typography> */}
          </Box>
        }
        handleDelete={handleDeleteManagerGroup}
        isReadOnly={isReadOnly}
      />
    </StyledRoot>
  );
};

export default ManagerPanel;
