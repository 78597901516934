import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
    Card,
    CardContent,
    Typography,
    Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { getPersonalTaxByMonth } from "../../../../../../actions/personal-tax";
import { getUserProfile } from "../../../../../../actions/user";
import AnimationBackdrop from "../../../../shared/general/Backdrop";
import PersonalTaxInfo from "./PersonalTaxInfo";
import moment from "moment";
import { PaidOutlined } from "@mui/icons-material";

const HeaderBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    padding: '16px 0 32px 0',
}));

const InnerHeaderBox = styled(Box)(() => ({
    borderBottom: '2px solid black',
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledBoxSearch = styled(Box)({
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});

const Tax = ({ t }) => {
    const dispatch = useDispatch();
    const [searchDate, setSearchDate] = useState(moment().subtract(1, 'months').endOf('month').toDate());
    const { result: personalTax, isFetching: personalTaxIsFetching } = useSelector((state) => state.personalTax);
    const { result: userProfile } = useSelector((state) => state.userProfile);

    useEffect(() => {
        if (userProfile) {
            dispatch(
                getPersonalTaxByMonth(
                    dayjs(searchDate).format("YYYY-MM-DD"),
                    userProfile.idEmployees
                )
            );
        } else {
            dispatch(getUserProfile());
        }
    }, [userProfile]);

    return (

        <Card sx={{ width: '100%', padding: '16px' }}>
            <CardContent>
                <HeaderBox>
                    <InnerHeaderBox>
                        <PaidOutlined sx={{ marginRight: 1, fontSize: 30, color: 'purple' }} />
                        <Typography margin={0} variant="h5" align="center" gutterBottom>
                            {t("AppMenu.MyPersonalTax")}
                        </Typography>
                    </InnerHeaderBox>
                </HeaderBox>

                {personalTax ? (
                    <PersonalTaxInfo personalTax={personalTax} />
                ) : personalTaxIsFetching === true ? (
                    <AnimationBackdrop />
                ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{t('NoItems')}</Box>
                )}
            </CardContent>
        </Card>
    );
};

export default Tax;
