import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  ListSubheader,
  Menu,
  MenuItem,
  styled
} from '@mui/material';
import { useTranslation } from "react-i18next";
import { getAllShiftTimeShedule } from '../../../../actions/shift';

const StyledMenu = styled(Menu)({
    "& .MuiMenu-paper": {
      borderRadius: 8,
      boxShadow:
        "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
    },
    "& .MuiList-root": {
      padding: 8,
    },
    "& .MuiMenuItem-root": {
      borderRadius: 6,
      "&:hover": {
        textDecoration: "none",
        backgroundColor: "#919eab14",
      },
    },
    "& .MuiListSubheader-root": {
      paddingLeft: 8,
      paddingRight: 8,
    },
});

const ITEM_HEIGHT = 48;

const MenuShift = (props) => {
    const { 
      dataSend,
      anchorEl, 
      handleClickChangeDayOff,
      handleClickChangeTime, 
      handleCloseMenu 
    } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { result : userProfile } = useSelector((state) => state.userProfile);
    const { isFetching, result : allShift } = useSelector((state) => state.shift);
    const [filterAllShift, setFilterAllShift] = useState({
      workingDay: [],
      offDay: []
    });
    const [isDispatch, setIsDispatch] = useState(false);

    useEffect(() => {
      if(allShift && allShift.length > 0){
        let isUser = (userProfile.idEmployees === dataSend.idEmployees);
        if(
          allShift.some((shift) => {
            return shift.idCompany === dataSend.idCompany;
          })
        ){
          let workDay = [];
          let weeklyHoliday = [];

          allShift.forEach((shiftGroup) => {
            if(shiftGroup.idWorkingType === 2){
              shiftGroup.shiftType.forEach((shiftType) => {
                if(shiftType.isWorkingDay === 1){
                  workDay.push({...shiftType, shiftGroupName : shiftGroup.shiftGroupName, idShift: shiftGroup.shift[0].idShift});
                }else if(shiftType.isWorkingDay === 0){
                  weeklyHoliday.push({...shiftType, shiftGroupName : shiftGroup.shiftGroupName, idShift: shiftGroup.shift[0].idShift});
                }
              });
            }
          });
          setFilterAllShift({ workday : workDay, weeklyHoliday : weeklyHoliday });
        }else{
          if(!isDispatch){
            dispatch(getAllShiftTimeShedule(isUser ? { idCompany: dataSend.idCompany, date: dataSend.date } : dataSend));
            setIsDispatch(true);
          }
        }
      }else{
        setFilterAllShift({
          workingDay: [],
          offDay: []
        });
      }
    },[allShift]); 

    return (
        <StyledMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && !isFetching}
          onClose={handleCloseMenu}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
            },
          }}
        >
          <ListSubheader>{t("WorkingDay")}</ListSubheader>
          {filterAllShift.workday && filterAllShift.workday.length > 0 &&
            filterAllShift.workday.map((shift) => (
              <MenuItem 
                key={`${shift.shiftGroupName}_${shift.idShift}_${shift.idShiftType}`}
                onClick={() => handleClickChangeTime({...shift, shiftGroupName: shift.shiftGroupName,idShift: shift.idShift})}
              >
                {shift.shiftGroupName}
              </MenuItem>
            ))
          }
          <ListSubheader>{t("WeeklyHoliday")}</ListSubheader>
          {filterAllShift.weeklyHoliday && filterAllShift.weeklyHoliday.length > 0 &&
            filterAllShift.weeklyHoliday.map((shift) => (
              <MenuItem 
                key={`${shift.shiftGroupName}_${shift.idShift}_${shift.idShiftType}`}
                onClick={() => handleClickChangeTime({...shift, shiftGroupName: shift.shiftGroupName,idShift: shift.idShift})}
              >
                {shift.shiftGroupName}
              </MenuItem>
            ))
          }
          <ListSubheader>{t("PublicHoliday")}</ListSubheader>
          <MenuItem onClick={() => handleClickChangeDayOff("holiday")}>
            {t("PublicHoliday")}
          </MenuItem>
        </StyledMenu>
    );
};

export default MenuShift;