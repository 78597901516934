import { httpClient } from "./httpClient";

const getGroupGpsLocations = (id) => {
  return httpClient.get(`/group-locations`);
};

const getGroupGpsLocationsById = (idGroupGpsLocation) => {
  return httpClient.get(`/group-locations/${idGroupGpsLocation}`);
};

const getAllLocations = (query) => {
  return httpClient.get(`/locations`, { params: query });
};

const postGpsLocations = (formData) => {
  return httpClient.post(`/locations`, formData);
};

const postGroupGpsLocations = (formData) => {
  return httpClient.post(`/group-locations`, formData);
};

const updateGroupGpsLocations = (formData) => {
  return httpClient.put(`/group-locations`, formData);
};

const updateGpsLocations = (formData) => {
  return httpClient.put(`/locations/${formData.idGpsLocations}`, formData);
};

// const putCompanyAddress = (id, formData) => {
//   return httpClient.put(`/company/${id}/address/${formData.idCompanyAddress}`, formData);
// };

const addGPSwithExcel = (formData) => {
  return httpClient.post(`/add-location-excel`, formData);
};

export default {
  postGroupGpsLocations,
  getGroupGpsLocations,
  getGroupGpsLocationsById,
  updateGroupGpsLocations,
  getAllLocations,
  postGpsLocations,
  updateGpsLocations,
  addGPSwithExcel
};
