import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useLocation } from 'react-router-dom';
import makeStyles from "@mui/styles/makeStyles";
import {
    Typography,
    Button,
    Stack,
    Container,
    Box,
    IconButton,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    Paper,
    TableBody,
    TableCell,
    Chip,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Menu,
    Avatar,
    InputAdornment,
    FilledInput,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,

} from "@mui/material";
import LinearProgress, {
    linearProgressClasses,
} from "@mui/material/LinearProgress";
import CardStyle from "../../shared/general/Card";
import GaugeChart from "react-gauge-chart";
import { Pie, Line } from "react-chartjs-2";
import SearchIcon from '@mui/icons-material/Search';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import {
    getWorkforceDashboardByIdMonth,
    getWorkforceDashboardAllById,
    getUsersInProjectByIdForPie
} from "../../../../actions/workforceEmployees";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserDepartment, getUserFullName, getUserPosition, getUserSection } from "../../../../utils/userData";

const StyledRoot = styled("div")({
    backgroundColor: "#f5f5f5",
    "& .MuiAutocomplete-root": {
        "& .MuiOutlinedInput-root": {
            padding: "13.5px 14px",
            paddingRight: "32px",
            "& input": {
                padding: 0,
            },
        },
    },
});

const WrapHeader = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ["@media only screen and (max-width:600px)"]: {
        flexDirection: "column",
    },
    marginTop: 8,
    marginBottom: 16,
});

const StyledFilledInput = styled(FilledInput)({
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
        paddingTop: 18,
        paddingBottom: 18,
    },
    "&.Mui-focused": {
        backgroundColor: "transparent",
    },
    "& .MuiInputAdornment-root": {
        width: 32,
        marginTop: "0!important",
        fontSize: 24,
        color: "#919EAB",
        "& i": {
            marginRight: 8,
        },
    },
    "& .MuiAutocomplete-endAdornment": {
        "& .MuiButtonBase-root": {
            fontSize: 14,
            width: 22,
            height: 22,
        },
    },
    "&:hover": {
        backgroundColor: "#919eab29",
        "&:before": {
            border: "none !important",
        },
    },
    "&::after": {
        border: "none",
    },
    "&::before": {
        border: "none",
    },
});

const useStyles = makeStyles(() => ({
    sendIcon: {
        marginBottom: 5,
        marginRight: 5,
        color: "black",
    },
    bigAvatar: {
        width: 200,
        height: 200,
    },
    text: {
        fontSize: 17,
    },
    textLine: {
        display: "flex",
        alignItems: "center",
        padding: 10,
    },
    textMainData: {
        fontSize: 18,
        fontWeight: "bold",
    },
    textName: {
        fontSize: 25,
        fontWeight: "bold",
        marginTop: 10,
        color: "#007afc",
    },
    space: {
        marginTop: 10,
        marginBottom: 10,
    },
    nameAttribute: {
        fontSize: 23,
        fontWeight: "bold",
        marginLeft: 10,
    },
    attribute: {
        fontSize: 18,
        marginTop: 50,
        marginBottom: 10,
    },
    tableText: {
        fontSize: 18,
    },
    learningName: {
        fontSize: 20,
        fontWeight: "bold",
    },
    avatarCer: {
        minWidth: 700,
        minHeight: 500,
        marginBottom: 20,
        marginTop: 20,
    },
    textPerform: {
        textAlign: "center",
        padding: 40,
        margin: 20,
        fontSize: 25,
        fontWeight: "bold",
        color: "#007afc",
    },
    iconBeforeText: {
        marginRight: 7,
        fontSize: 22,
    },
    topicInJob: {
        fontSize: 18,
        fontWeight: "bold",
    },
    accordion: {
        marginBottom: 15,
        borderRadius: 10,
        "&.MuiAccordion-root:before": {
            backgroundColor: "transparent",
        },
    },
    testflex: {
        display: "flex",
        justifyContent: "space-between"
    }
}));




//Show Progress

const NormalProgress = styled(LinearProgress)(() => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            "#424242",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: "#4caf50",
    },
}));

const WarningProgress = styled(LinearProgress)(() => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            "#424242",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: "#ffc107",
    },
}));

const DangerProgress = styled(LinearProgress)(() => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            "#424242",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: "#f44336",
    },
}));

const ShowProgress = ({
    name,
    val,
    sizeBar,
    sizeText,
    headerText,
    plusValue,
    height,
}) => {
    return (
        <div style={headerText}>
            <Typography style={headerText}>{name}</Typography>
            <Grid container direction="row" alignItems="center" style={height}>
                <Grid item lg={sizeBar} md={sizeBar} xs={sizeBar - 1}>
                    {val >= plusValue ? (
                        <div>
                            <Box sx={{ flexGrow: 1 }}>
                                <DangerProgress
                                    variant="determinate"
                                    value={(val * 100) / plusValue}
                                />
                            </Box>
                        </div>
                    ) : val >= plusValue * 0.8 ? (
                        <div>
                            <Box sx={{ flexGrow: 1 }}>
                                <WarningProgress
                                    variant="determinate"
                                    value={(val * 100) / plusValue}
                                />
                            </Box>
                        </div>
                    ) : (
                        <div>
                            <Box sx={{ flexGrow: 1 }}>
                                <NormalProgress
                                    variant="determinate"
                                    value={(val * 100) / plusValue}
                                />
                            </Box>
                        </div>
                    )}
                </Grid>
                <Grid item lg={sizeText} md={sizeText} xs={sizeText + 1}>
                    <h5 style={{ textAlign: "center", color: "rgb(0, 122, 252)" }}>
                        {val} hr
                    </h5>
                </Grid>
            </Grid>
        </div>
    );
};

const dashboardProjectHour = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const dispatch = useDispatch()
    const monthCreated = parseInt(queryParams.get('MonthCreated'), 10);
    const yearCreated = parseInt(queryParams.get('YearCreated'), 10);
    const idEmployees = queryParams.get('idEmployees');


    const { result: dashboardWorkforcePieChart } = useSelector((state) => state.dashboardWorkforcePieChart)
    const { result: dashboardWorkforceMonth } = useSelector((state) => state.dashboardWorkforceMonth)
    const { result: dashboardWorkforceAll } = useSelector((state) => state.dashboardWorkforceAll)
    const options = {
        plugins: {
            legend: {
                position: "right",
                onClick: function (event, legendItem) { },

                labels: {
                    padding: 40,
                    color: "#BABABA",
                    font: {
                        size: 18,
                    },
                    filter: false,
                },
            },
            tooltip: {
                enabled: true,
            },
        },
    }; 
    //getWorkforceDashboardAllById
    useEffect(() => {
        dispatch(getWorkforceDashboardByIdMonth({ idEmp: idEmployees, Month: monthCreated ,Year: yearCreated}))
        dispatch(getWorkforceDashboardAllById({ idEmp: idEmployees,Year: yearCreated }))
        dispatch(getUsersInProjectByIdForPie({ idEmp: idEmployees, Month: monthCreated ,Year: yearCreated}))
    }, []);

    var labelsOfPie = dashboardWorkforcePieChart ? dashboardWorkforcePieChart.map(item => item.name) : [];
    var dataOfPie = dashboardWorkforcePieChart ? dashboardWorkforcePieChart.map(item => parseInt(item.totalWorkingHours)) : [];

    const data = {

        labels: labelsOfPie,
        datasets: [
            {
                label: "Pie",

                data: dataOfPie,

                backgroundColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 0.5)",
                    "rgba(54, 162, 235, 0.5)",
                    "rgba(255, 206, 86, 0.5)",
                    "rgba(75, 192, 192, 0.5)",
                    "rgba(153, 102, 255, 0.5)",
                    "rgba(255, 159, 64, 0.5)",
                ],
                borderWidth: 1,
            },
        ],
    };

    const countInEachMonth = Array(12).fill(0);  
    if (dashboardWorkforceAll && dashboardWorkforceAll.length > 0) {
        dashboardWorkforceAll.forEach(item => {
            const monthIndex = item.monthWork - 1; 
            countInEachMonth[monthIndex] = parseInt(item.totalWorkingHours, 10); 
        });
    } else {
        console.log("No data available for dashboardWorkforceAll");
    }
    const { t, i18n } = useTranslation();
    const data2 = {
        labels: [
            t("shortMonth.Jan"), t("shortMonth.Feb"), t("shortMonth.Mar"), t("shortMonth.Apr"), t("shortMonth.May"), t("shortMonth.Jun"),
            t("shortMonth.Jul"), t("shortMonth.Aug"), t("shortMonth.Sep"), t("shortMonth.Oct"), t("shortMonth.Nov"), t("shortMonth.Dec")
        ],
        datasets: [{
            data: countInEachMonth,
            backgroundColor: "white",
            borderColor: "#651fff",
        }]
    };

    const options2 = {
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: t("Unit.Hours")
                }
            },
            x: {
                title: {
                    display: true,
                    text: t("Month")
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'top'
            },
            tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false
            }
        }
    };

    const classes = useStyles();

    const thaiMonths = [
        t("Months.January"), t("Months.February"), t("Months.March"), t("Months.April"), t("Months.May"), t("Months.Junes"),
            t("Months.July"), t("Months.August"), t("Months.September"), t("Months.October"), t("Months.November"), t("Months.December")
      ];

    return (
        <StyledRoot className={"page"}>
            <Container maxWidth="lg">
                <WrapHeader>
                    <Typography variant="h4">Workforce {t("Dashboard")} {t("Month")}{thaiMonths[monthCreated-1]} {t("Year")} {yearCreated}</Typography>
                </WrapHeader>
                <Grid container direction="row" spacing={3}>
                    <Grid sx={{ '& .MuiGrid-root': { justifyContent: 'space-between' } }} item xl={6} lg={6} md={8} sm={12} xs={12}>
                        <CardStyle>
                            <Box sx={{ padding: "20px", minHeight: "410px" }}>
                                <h2 className={classes.textAver}>
                                    {t("chartShowingHoursUsedInMonth")}{thaiMonths[monthCreated-1]}
                                </h2>
                                <GaugeChart
                                    id="gauge-chart6"
                                    nrOfLevels={20}
                                    arcWidth={0.3}
                                    colors={["#07A001", "#54AB3E", "#EA4228"]}
                                    percent={(dashboardWorkforceMonth&&dashboardWorkforceMonth.reduce((acc,af)=>acc+(parseInt(af.totalWorkingHours)),0) * 100) / 176 / 100}
                                    // percent={Math.round((total * 100) / 176)}
                                    formatTextValue={(value) =>{
                                        // Math.round((total * 100) / 176) + "%"
                                        const sum = dashboardWorkforceMonth&&dashboardWorkforceMonth.reduce((acc,af)=>acc+(parseInt(af.totalWorkingHours)),0)
                                        return `${sum} ` + t("Unit.ShortHours")
                                    }
                                    }
                                    textColor="Black"
                                    style={{
                                        alignItems: "center",
                                        textAlign: "center",
                                        width: "100%",
                                        stroke: "Black",
                                        fontSize: "200px",
                                        fontWeight: "bold",
                                    }}
                                    marginInPercent={0.03}
                                />
                            </Box>
                        </CardStyle>
                    </Grid>
                    <Grid item xl={6} lg={6} md={4} sm={12} xs={12}>
                        <CardStyle>
                        <Box sx={{ padding: "20px", minHeight: "410px" }}>
                        <TableContainer
                            component={Paper}
                            style={{ backgroundColor: "#fff" }}
                        >
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography  fontSize={25} className={classes.nameTable}>
                                                {t("totalWorkTime")} ({t("Unit.Hours")})
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography  fontSize={25} className={classes.nameTable}>
                                                {t("workTimeUsed")} ({t("Unit.Hours")})
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow
                                        sx={{
                                            "&:last-child td, &:last-child th": { border: 0 },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <Typography  fontSize={25} className={classes.nameTable}>
                                                176
                                            </Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Typography  fontSize={25} className={classes.nameTable}>
                                                {
                                                dashboardWorkforceMonth&&dashboardWorkforceMonth.reduce((acc,af)=>acc+(parseInt(af.totalWorkingHours)),0)
                                              
                                                }
                                            </Typography>
                                        </TableCell>

                                    </TableRow>
                                    <TableRow
                                        sx={{
                                            "&:last-child td, &:last-child th": { border: 0 },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <Typography  fontSize={25} className={classes.nameTable}>
                                                100%

                                            </Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Typography  fontSize={25} className={classes.nameTable}>
                                                {Math.round(((dashboardWorkforceMonth&&dashboardWorkforceMonth.reduce((acc,af)=>acc+(parseInt(af.totalWorkingHours)),0)) * 100) / 176) + "%"}
                                            </Typography>
                                        </TableCell>

                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer></Box></CardStyle>
                    </Grid>
                </Grid>
                <CardStyle style={{ marginTop: "20px" }}>
                    <Box sx={{ padding: "20px" }}>
                        <h3 style={{ color: "#000", textAlign: "center", fontWeight: 600 }}>
                            {t("chartShowingTimeSpentOnProjectsInYear")} {yearCreated} ({t("Unit.ShortHours")}/{t("Month")})
                        </h3>
                        <Line
                            height="50px"
                            style={{ width: "90%" }}
                            data={data2}
                            options={options2}
                        />
                    </Box>
                </CardStyle>
                <CardStyle style={{ marginTop: "40px" }}>
                    <Box sx={{ padding: "20px 30px 60px 50px" }}>
                        <Box sx={{ marginBottom: 8 }}>
                            <h2 style={{ color: "#000", textAlign: "center" }}>
                                {t("chartShowingWorkHoursForEachProject")}
                            </h2>
                        </Box>
                        <Grid container alignItems="center" justifyContent="center">
                            <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                                {'countAllData' == 0 ? (
                                    <h1 style={{ textAlign: "center" }}>{t("noDataToMakeChart")}</h1>
                                ) : (
                                    <Pie data={data} options={options} />
                                )}
                            </Grid>
                            <Grid item xs={12} md={2} lg={2}></Grid>
                            <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                                <Box
                                    sx={{
                                        backgroundColor: "#fafafa",
                                        padding: "20px",
                                        borderRadius: "10px",
                                    }}
                                >
                                    {dashboardWorkforcePieChart && dashboardWorkforcePieChart.length > 0 ? (
                                        dashboardWorkforcePieChart.map((item, index) => (
                                            <ShowProgress
                                                key={index}
                                                name={item.name}
                                                val={item.totalWorkingHours}
                                                sizeBar={10}
                                                sizeText={2}
                                                headerText={{
                                                    color: "#000",
                                                    fontSize: "1rem",
                                                    fontWeight: "600",
                                                    marginBottom: 3,
                                                }}
                                                plusValue={176}
                                            />
                                        ))
                                    ) : (
                                        <div>{t("NoData")}</div>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </CardStyle>
            </Container>
        </StyledRoot>
    )
}

export default dashboardProjectHour