import 'dayjs/locale/th';
import ExcelJS from 'exceljs';
import { colWidths, headerStyles } from './xlsxStyle';

export const PayrollXlsxFile = async (data, year, selectedLevel, t) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet(`Payroll-${selectedLevel === 1 ? t("Department") : t("Section")}`);

    const months = [
        { month: "01", name: t("shortMonth.Jan") },
        { month: "02", name: t("shortMonth.Feb") },
        { month: "03", name: t("shortMonth.Mar") },
        { month: "04", name: t("shortMonth.Apr") },
        { month: "05", name: t("shortMonth.May") },
        { month: "06", name: t("shortMonth.Jun") },
        { month: "07", name: t("shortMonth.Jul") },
        { month: "08", name: t("shortMonth.Aug") },
        { month: "09", name: t("shortMonth.Sep") },
        { month: "10", name: t("shortMonth.Oct") },
        { month: "11", name: t("shortMonth.Nov") },
        { month: "12", name: t("shortMonth.Dec") }
    ];


    const headers = [
        'Cost Center',
        // 'หน่วยงาน',
        `${selectedLevel === 1 ? t("Department") : t("Section")}`,
        ...months.map((month) => `${t("TotalPaid")} ${"totalBahtPerMonth"} ${month.name}`),
        ...months.map((month) => `${t("AppMenu.Payroll")} ${"totalBahtPerMonth"} ${month.name}`),
        ...months.map((month) => `OT ${"totalBahtPerMonth"} ${month.name}`),
        ...months.map((month) => `${t("ShiftValue")} ${"totalBahtPerMonth"} ${month.name}`),
        ...months.map((month) => `${t("TaxRemitted")} ${"totalBahtPerMonth"} ${month.name}`),
        ...months.map((month) => `${t("CompanySocialSecurity")} ${"totalBahtPerMonth"} ${month.name}`),
        ...months.map((month) => `${t("EmployeeSocialSecurity")} ${"totalBahtPerMonth"} ${month.name}`),
        ...months.map((month) => `PF${t("CompanyPart")} ${"totalBahtPerMonth"} ${month.name}`),
        ...months.map((month) => `PF${t("EmployeePart")} ${"totalBahtPerMonth"} ${month.name}`),
        ...months.map((month) => `${t("Addition")} (Addition) ${"totalBahtPerMonth"} ${month.name}`),
        ...months.map((month) => `${t("Deduction")} (Deduction) ${"totalBahtPerMonth"} ${month.name}`)
    ];

    const headerRow = worksheet1.addRow(headers);

    headerRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        if (headerStyles[colNumber - 1]) {
            cell.style = headerStyles[colNumber - 1];
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        }
    });

    headerRow.height = 50;

    colWidths.forEach((col, index) => {
        worksheet1.getColumn(index + 1).width = col.width;
    });

    data && data.length > 0 && data.forEach((item) => {
        const row = [
            selectedLevel === 1 ? (item.departmentCode ? item.departmentCode : "-") : (item.sectionCode ? item.sectionCode : "-"),
            selectedLevel === 1 ? (item.departmentName ? item.departmentName : "-") : (item.sectionName ? item.sectionName : "-")
        ];

        months.forEach((month) => {
            const monthData = item.monthlyData;

            const netValue = monthData.net.find((e) => e.monthPeriod === `${year}-${month.month}`);
            const sumSalaryValue = monthData.sumSalary.find((e) => e.monthPeriod === `${year}-${month.month}`);
            const otValue = monthData.sumOT.find((e) => e.monthPeriod === `${year}-${month.month}`);
            const SUM_ShiftFeeValue = monthData.SUM_ShiftFee.find((e) => e.monthPeriod === `${year}-${month.month}`);
            const SUM_TaxValue = monthData.SUM_Tax.find((e) => e.monthPeriod === `${year}-${month.month}`);
            const SUM_SocialSecurityComValue = monthData.SUM_SocialSecurityEmp.find((e) => e.monthPeriod === `${year}-${month.month}`);
            const SUM_SocialSecurityEmpValue = monthData.SUM_SocialSecurityEmp.find((e) => e.monthPeriod === `${year}-${month.month}`);
            const SUM_PFcomValue = monthData.SUM_PFcom.find((e) => e.monthPeriod === `${year}-${month.month}`);
            const SUM_PFempValue = monthData.SUM_PFemp.find((e) => e.monthPeriod === `${year}-${month.month}`);
            const SUM_AdditionValue = monthData.SUM_Addition.find((e) => e.monthPeriod === `${year}-${month.month}`);
            const SUM_DeductionValue = monthData.SUM_Deduction.find((e) => e.monthPeriod === `${year}-${month.month}`);

            const net = netValue ? netValue.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
            const sumSalary = sumSalaryValue ? sumSalaryValue.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
            const otAmount = otValue ? otValue.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
            const SUM_ShiftFee = SUM_ShiftFeeValue ? SUM_ShiftFeeValue.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
            const SUM_Tax = SUM_TaxValue ? SUM_TaxValue.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
            const SUM_SocialSecurityCom = SUM_SocialSecurityComValue ? SUM_SocialSecurityComValue.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
            const SUM_SocialSecurityEmp = SUM_SocialSecurityEmpValue ? SUM_SocialSecurityEmpValue.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
            const SUM_PFcom = SUM_PFcomValue ? SUM_PFcomValue.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
            const SUM_PFemp = SUM_PFempValue ? SUM_PFempValue.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
            const SUM_Addition = SUM_AdditionValue ? SUM_AdditionValue.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
            const SUM_Deduction = SUM_DeductionValue ? SUM_DeductionValue.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";

            const netColumnIndex = headers.indexOf(`${t("TotalPaid")} ${"totalBahtPerMonth"} ${month.name}`);
            const sumSalaryColumnIndex = headers.indexOf(`${t("AppMenu.Payroll")} ${"totalBahtPerMonth"} ${month.name}`);
            const otColumnIndex = headers.indexOf(`OT ${"totalBahtPerMonth"} ${month.name}`);
            const shiftColumnIndex = headers.indexOf(`${t("ShiftValue")} ${"totalBahtPerMonth"} ${month.name}`);
            const taxColumnIndex = headers.indexOf(`${t("TaxRemitted")} ${"totalBahtPerMonth"} ${month.name}`);
            const socialSecurityComColumnIndex = headers.indexOf(`${t("CompanySocialSecurity")} ${"totalBahtPerMonth"} ${month.name}`);
            const socialSecurityEmpColumnIndex = headers.indexOf(`${t("EmployeeSocialSecurity")} ${"totalBahtPerMonth"} ${month.name}`);
            const sumPFComColumnIndex = headers.indexOf(`PF${t("CompanyPart")} ${"totalBahtPerMonth"} ${month.name}`);
            const sumPFEmpColumnIndex = headers.indexOf(`PF${t("EmployeePart")} ${"totalBahtPerMonth"} ${month.name}`);
            const sumAdditionColumnIndex = headers.indexOf(`${t("Addition")} (Addition) ${"totalBahtPerMonth"} ${month.name}`);
            const sumDeductionColumnIndex = headers.indexOf(`${t("Deduction")} (Deduction) ${"totalBahtPerMonth"} ${month.name}`);

            if (netColumnIndex !== 1) {
                row[netColumnIndex] = net;
            }

            if (sumSalaryColumnIndex !== 1) {
                row[sumSalaryColumnIndex] = sumSalary;
            }

            if (shiftColumnIndex !== 1) {
                row[shiftColumnIndex] = SUM_ShiftFee;
            }

            if (taxColumnIndex !== 1) {
                row[taxColumnIndex] = SUM_Tax;
            }

            if (socialSecurityComColumnIndex !== 1) {
                row[socialSecurityComColumnIndex] = SUM_SocialSecurityCom;
            }

            if (socialSecurityEmpColumnIndex !== 1) {
                row[socialSecurityEmpColumnIndex] = SUM_SocialSecurityEmp;
            }

            if (sumPFComColumnIndex !== 1) {
                row[sumPFComColumnIndex] = SUM_PFcom;
            }

            if (sumPFEmpColumnIndex !== 1) {
                row[sumPFEmpColumnIndex] = SUM_PFemp;
            }

            if (sumAdditionColumnIndex !== 1) {
                row[sumAdditionColumnIndex] = SUM_Addition;
            }

            if (sumDeductionColumnIndex !== 1) {
                row[sumDeductionColumnIndex] = SUM_Deduction;
            }

            if (otColumnIndex !== -1) {
                row[otColumnIndex] = otAmount;
            }
        });

        const excelRow = worksheet1.addRow(row);

        const contentStyle = { font: { size: 18, name: 'TH SarabunPSK' } };

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });


    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${data && data[0].companyName}-Payroll-${selectedLevel === 1 ? t("Department") : t("Section")}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });

}

