import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, styled, Typography } from "@mui/material";

import CardRequestManage from "./requestManageCard";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({});

const RequestPanel = (props) => {
  const { data } = props;
  const { t, i18n } = useTranslation();

  return (
    <StyledRoot>
      <Grid container spacing={3}>
        {data.map((job) => (
          <Grid item xs={12} sm={6} md={4} key={job.idJobRecruit}>
            <CardRequestManage value={job} />
          </Grid>
        ))}
        {data.length === 0 && (
          <Grid item xs={12}>
            <Typography
              sx={{ color: "#ffffff" }}
              variant="h5"
              textAlign="center"
            >{`${t("NoJobFound")}`}</Typography>
          </Grid>
        )}
      </Grid>
    </StyledRoot>
  );
};

export default RequestPanel;
