import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Container, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { styled } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import ArticleIcon from "@mui/icons-material/Article";

import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import EventIcon from "@mui/icons-material/Event";

const useStyles = makeStyles(() => ({
  svgIcon: {
    marginRight: "10px",
  },
  fontBold: {
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
  },
  hr: {
    width: "100%",
    marginTop: 24,
    marginBottom: 24,
    border: "1px solid #eeeeee",
  },
  textSvg: {
    display: "flex",
    alignItems: "center",
  },
  textSecondary: {
    color: "#757575",
  },
  empInfo: {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "#f5f5f5",
    paddingTop: 32,
    paddingBottom: 16,
  },
  imageEmp: {
    width: 80,
    height: 80,
    borderRadius: "50%",
  },
  img_section: {
    display: "flex",
    justifyContent: "center",
    width: "200px",
    paddingTop: "5px",
    paddingLeft: 8,
  },
  info_section: {
    width: "100%",
  },
}));

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

function FailureHistoryView() {
  const classes = useStyles();
  const location = useLocation();
  useEffect(() => {
    console.log(location.state.recordItem);
  }, []);

  return (
    <ContainerStyled>
      <div className="card-section">
        <div className="header-section">
          <Grid container>
            <Grid item>
              <h3 className="header-topic">Failure History Record</h3>
            </Grid>
          </Grid>
        </div>
        <Grid container justifyContent="center">
          <Grid item xs={10} md={10} lg={10}>
            <Typography
              variant="body1"
              className={classes.fontBold}
              gutterBottom
            >
              <BookmarkOutlinedIcon className={classes.svgIcon} />
              Problem / Failure Subject
            </Typography>
            <Typography variant="body1" className={classes.textSecondary}>
              {location.state.recordItem.subject}
            </Typography>
          </Grid>
        </Grid>
        <hr className={classes.hr} />
        <Grid container justifyContent="center">
          <Grid item xs={10} md={10} lg={10}>
            <Typography
              variant="body1"
              className={classes.fontBold}
              gutterBottom
            >
              <AssignmentIcon className={classes.svgIcon} />
              Detail Action / Result
            </Typography>
            <Typography variant="body1" className={classes.textSecondary}>
              {location.state.recordItem.details}
            </Typography>
          </Grid>
        </Grid>
        <hr className={classes.hr} />
        <Grid container justifyContent="center">
          <Grid item xs={10} md={10} lg={10}>
            <Typography
              variant="body1"
              className={classes.fontBold}
              gutterBottom
            >
              <VpnKeyIcon className={classes.svgIcon} />
              Key Learning
            </Typography>
            <Typography variant="body1" className={classes.textSecondary}>
              {location.state.recordItem.keyLearning}
            </Typography>
          </Grid>
        </Grid>
        <hr className={classes.hr} />
        <div className={classes.empInfo}>
          <div className={classes.img_section}>
            {location.state.recordItem.image == null ||
            location.state.recordItem.image == "string" ? (
              <ArticleIcon
                sx={{ color: "#e0e0e0" }}
                className={classes.imageEmp}
              />
            ) : (
              <img
                src={`/images/employees/${location.state.recordItem.image}`}
                className={classes.imageEmp}
              />
            )}
          </div>
          <div className={classes.info_section}>
            <Grid
              container
              justifyContent="flex-start"
              sx={{ marginBottom: 2 }}
            >
              <Grid item xs={12} md={4} lg={4}>
                <Typography
                  variant="body1"
                  display="inline"
                  className={classes.fontBold}
                  gutterBottom
                >
                  <EventIcon className={classes.svgIcon} />
                  Create :{" "}
                </Typography>
                <Typography
                  variant="body1"
                  display="inline"
                  className={classes.textSecondary}
                  gutterBottom
                >
                  {`${location.state.recordItem.firstName} ${location.state.recordItem.lastName}`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Typography
                  variant="body1"
                  display="inline"
                  className={classes.fontBold}
                  gutterBottom
                >
                  Tel :{" "}
                </Typography>
                <Typography
                  variant="body1"
                  display="inline"
                  className={classes.textSecondary}
                  gutterBottom
                >
                  091-9999999
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Typography
                  variant="body1"
                  display="inline"
                  className={classes.fontBold}
                  gutterBottom
                >
                  Email :{" "}
                </Typography>
                <Typography
                  variant="body1"
                  display="inline"
                  className={classes.textSecondary}
                  gutterBottom
                >
                  songsuwk@scg.com
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-start">
              <Grid item xs={10} md={4} lg={4}>
                <Typography
                  variant="body1"
                  display="inline"
                  className={classes.fontBold}
                  gutterBottom
                >
                  <EventIcon className={classes.svgIcon} />
                  Create Date :{" "}
                </Typography>
                <Typography
                  variant="body1"
                  display="inline"
                  className={classes.textSecondary}
                  gutterBottom
                >
                  <Box style={{ marginRight: "10px" }}>
                    {dayjs(location.state.recordItem.dateCreate).format(
                      "DD/MM/YYYY"
                    )}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={10} md={6} lg={6}>
                <Typography
                  variant="body1"
                  display="inline"
                  className={classes.fontBold}
                  gutterBottom
                >
                  Problem Date :{" "}
                </Typography>
                <Typography
                  variant="body1"
                  display="inline"
                  className={classes.textSecondary}
                  gutterBottom
                >
                  <Box style={{ marginRight: "10px" }}>
                    {dayjs(location.state.recordItem.dateProblem).format(
                      "DD/MM/YYYY"
                    )}
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </ContainerStyled>
  );
}

export default FailureHistoryView;
