import React, { useEffect, useState } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import { Box, Grid, MenuItem, Typography, styled } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { addEmployeePotential, getEmployeeProfile, getPotentialList, updateEmployeePotential } from "../../../../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
    padding: "16px",
    "& .MuiAutocomplete-root": {
        "& .MuiOutlinedInput-root": {
            padding: "13.5px 14px",
            paddingRight: "32px",
            "& input": {
                padding: 0,
            },
        },
    },
    "& .field-label": {
        paddingBottom: "4px",
        fontWeight: "500",
        fontSize: "14px",
    },
});

const DialogPotential = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const { drawerConfig, onClose } = props;

    console.log(drawerConfig);

    const { result: employeeProfile } = useSelector(
        (state) => state.employeeProfile
    );

    const [potential, setPotential] = useState(null);

    useEffect(() => {
        const fetchedPotentialList = async () => {
            try {
                const response = await getPotentialList();
                if (response && response.data) {
                    setPotential(response.data);
                }
            } catch (error) {
                console.error(error)
            }
        }
        fetchedPotentialList()
    }, []);

    const validateYupSchema = yup.object({
        idPotential: yup.string().required(t("PleaseSelectInformation")),
        year: yup.string().required(t("PleaseSelectInformation")),
    });

    const useHookForm = useForm({
        defaultValues: {
            idPotential: "",
            year: dayjs().get('year'),
        },
        resolver: yupResolver(validateYupSchema),
        mode: "all",
    });

    useEffect(() => {
        if (drawerConfig.isOpen === true) {
            if (drawerConfig.isEdit) {
                useHookForm.reset({
                    idPotential: drawerConfig.data.idPotential || "",
                });
            } else {
                useHookForm.reset({
                    idPotential: "",
                });
            }
        }
    }, [drawerConfig.isOpen]);

    const onSubmit = async (data) => {
        let result = null;

        const formData = {
            ...data,
        };

        const findExistTalent = drawerConfig && drawerConfig.data && drawerConfig.data.find(item => item.year === Number(data.year))

        if (findExistTalent) {
            formData.idEmployees = employeeProfile.idEmployees;
            result = await updateEmployeePotential(
                findExistTalent.idEmployeePotential,
                formData
            )
        } else {
            formData.idEmployees = employeeProfile.idEmployees;
            result = await addEmployeePotential(formData);
        }

        if (result && result.status === 200) {
            dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
            onClose();
        }
    };

    return (
        <DrawerCustom
            title={`${t("EditPotential")}`}
            anchor="right"
            open={drawerConfig.isOpen}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: "100%",
                    maxWidth: "500px",
                },
            }}
        >
            <StyledRoot>
                <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography className="field-label">
                                {t("PotentialYear")}{" "}
                                {dayjs().format("YYYY")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="idPotential"
                                control={useHookForm.control}
                                render={({ field }) => (
                                    <TextFieldTheme
                                        {...field}
                                        label={t("Potential")}
                                        select
                                        helperText={
                                            useHookForm.formState.errors[field.name]
                                                ? useHookForm.formState.errors[field.name].message
                                                : null
                                        }
                                        error={
                                            useHookForm.formState.errors[field.name] ? true : false
                                        }
                                    >
                                        {potential &&
                                            potential.map((item) => (
                                                <MenuItem value={item.idPotential}>
                                                    {item.Level}
                                                </MenuItem>
                                            ))}
                                    </TextFieldTheme>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="year"
                                control={useHookForm.control}
                                render={({ field }) => (
                                    <TextFieldTheme
                                        {...field}
                                        label={t("Year")}
                                        select
                                        helperText={
                                            useHookForm.formState.errors[field.name]
                                                ? useHookForm.formState.errors[field.name].message
                                                : null
                                        }
                                        error={
                                            useHookForm.formState.errors[field.name] ? true : false
                                        }
                                    >
                                        {[...Array(6).keys()].map((item, index) => {
                                            const currentYear = dayjs().get('year') - index;
                                            const currentMonth = dayjs().get('month');

                                            if (currentMonth < 9 && currentYear === dayjs().get('year')) {
                                                // Exclude the current year if the current month is before October
                                                return null;
                                            } else {
                                                return (
                                                    <MenuItem key={currentYear} value={currentYear}>
                                                        {currentYear}
                                                    </MenuItem>
                                                );
                                            }
                                        })}
                                    </TextFieldTheme>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="space-between">
                            <ButtonBlue
                                variant="text"
                                onClick={onClose}
                                disabled={useHookForm.formState.isSubmitting}
                            >
                                {t("Cancel")}
                            </ButtonBlue>
                            <ButtonBlue
                                variant="contained"
                                type="submit"
                                disabled={useHookForm.formState.isSubmitting}
                            >
                                {t("Save")}
                            </ButtonBlue>
                        </Grid>
                    </Grid>
                </form>
            </StyledRoot>
        </DrawerCustom>
    );
};

export default DialogPotential;
