import React from "react";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const StatusSuccess = ({ status }) => {
  const { t } = useTranslation();
  return (
    <Chip
      icon={status ? <DoneIcon /> : <CloseIcon />}
      label={status ? t("Success") : t("Failure")}
      sx={{
        maxWidth: "fit-content",
        backgroundColor: status ? "#E8F5E9" : "#FFEBEE",
        "& .MuiSvgIcon-root": {
          color: status ? "#4CAF50" : "#F44336",
        },
        "& .MuiChip-label": {
          lineHeight: 1,
          fontWeight: "500",
        },
      }}
    />
  );
};

export default StatusSuccess;
