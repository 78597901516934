import {
  PROBATION_QUESTION_FETCHING,
  PROBATION_QUESTION_FAILED,
  PROBATION_QUESTION_SUCCESS,
  RESULT_PROBATION_SUCCESS,
  RESULT_PROBATION_FETCHING,
  RESULT_PROBATION_FAILED,
} from "./types";
import ProbationService from "../services/probation.service";

export const getAllProbationQuestionSet = (query) => async (dispatch) => {
  try {
    dispatch({
      type: PROBATION_QUESTION_FETCHING,
    });
    const res = await ProbationService.getAllProbationQuestionSet(query);
    if (res) {
      dispatch({
        type: PROBATION_QUESTION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PROBATION_QUESTION_FAILED,
    });
  }
};

export const addProbationQuestionSet = (formdata) => async (dispatch) => {
  try {
    const res = await ProbationService.addProbationQuestionSet(formdata);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: PROBATION_QUESTION_FAILED,
    });
  }
};

export const updateProbationQuestionSet = (formdata) => async (dispatch) => {
  try {
    const res = await ProbationService.updateProbationQuestionSet(formdata);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: PROBATION_QUESTION_FAILED,
    });
  }
};

export const updateProbationQuestionSetStatus = (formdata) => async (dispatch) => {
  try {
    const res = await ProbationService.updateProbationQuestionSetStatus(formdata);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: PROBATION_QUESTION_FAILED,
    });
  }
};

export const getProbationQuestion = (query) => async (dispatch) => {
  try {
    dispatch({
      type: PROBATION_QUESTION_FETCHING,
    });
    // const res = await ProbationService.getProbationQuestion(query);
    // if (res) {
    //   dispatch({
    //     type: PROBATION_QUESTION_SUCCESS,
    //     payload: res.data,
    //   });
    // }
  } catch (err) {
    dispatch({
      type: PROBATION_QUESTION_FAILED,
    });
  }
};

export const addProbationQuestion = (formdata) => async (dispatch) => {
  try {
    // const res = await ProbationService.addProbationQuestion(formdata);
    // if (res) {
    //   return res;
    // }
  } catch (error) {
    dispatch({
      type: PROBATION_QUESTION_FAILED,
    });
  }
};

export const updateProbationQuestion = (formdata) => async (dispatch) => {
  try {
    const res = await ProbationService.updateProbationQuestion(formdata);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: PROBATION_QUESTION_FAILED,
    });
  }
};

export const updateProbationManager = (formData) => async () => {
  try {
    const res = await ProbationService.updateProbationManager(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return "Error";
  }
};

export const updateProbationAdmin = (formData) => async () => {
  try {
    const res = await ProbationService.updateProbationAdmin(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return "Error";
  }
};

export const getProbationAndAnswerByIdEmployee = (idEmployees) => async (dispatch) => {
  try {
    dispatch({
      type: RESULT_PROBATION_FETCHING,
    });
    const { data } = await ProbationService.getProbationAndAnswer();
    const probationSelected = data.find((value) => value.idEmployees === idEmployees)
    if (probationSelected) {
      dispatch({
        type: RESULT_PROBATION_SUCCESS,
        payload: probationSelected,
      });
    }
  } catch (err) {
    dispatch({
      type: RESULT_PROBATION_FAILED,
    });
    console.log(err);
  }
};

export const getProbation = (idProbation) => async (dispatch) => {
  try {
    dispatch({
      type: RESULT_PROBATION_FETCHING,
    });
    const res = await ProbationService.getProbation(idProbation);
    if (res) {
      dispatch({
        type: RESULT_PROBATION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: RESULT_PROBATION_FAILED,
    });
  }
};

// +
export const getProbationEmployee = () => async (dispatch) => {
  try {
    dispatch({
      type: PROBATION_QUESTION_FETCHING,
    });
    const res = await ProbationService.getProbationEmployee();
    if (res) {
      dispatch({
        type: PROBATION_QUESTION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PROBATION_QUESTION_FAILED,
    });
  }
};

// +
export const updateProbationEmployee = (formData) => async (dispatch) => {
  try {
    const res = await ProbationService.updateProbationEmployee(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: PROBATION_QUESTION_FAILED,
    });
  }
};

// +
export const getProbationEmployeeAnswer = (idEmployee) => async (dispatch) => {
  try {
    dispatch({
      type: PROBATION_QUESTION_FETCHING,
    });
    const res = await ProbationService.getProbationEmployeeAnswer(idEmployee);
    if (res) {
      dispatch({
        type: PROBATION_QUESTION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PROBATION_QUESTION_FAILED,
    });
  }
};