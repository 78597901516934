import React, { useState, useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { styled } from "@mui/material/styles";
import {
    Box,
    Divider,
    IconButton,
    InputBase,
    Paper,
} from "@mui/material";
import "../../styles.css";
// Translator TH-EN
import { useTranslation } from "react-i18next";

import ButtonBlue from "../../../../../shared/general/ButtonBlue";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

const mapStyles = {
    position: "relative",
    width: "100%",
    height: "350px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

const resetContainer = {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "10px",
};

const StylePaper = styled(Paper)({
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderRadius: 8,
    marginBottom: 16,
    boxShadow:
        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
});

const StyleWrapButton = styled("div")({
    marginTop: 8,
    width: "100%",
    display: "flex",
    justifyContent: "center",
});

const InitialValues = () => ({
    locationId: 0,
    locationName: "",
    location: [],
});

function AddAndPositionMap(props) {
    const { radius, selectedGroup, handleChangePositions } = props;
    const [search, setSearch] = useState("");
    const [values, setValues] = useState(InitialValues);
    const { t, i18n } = useTranslation();

    let map, marker, circle;

    const onMapLoad = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };

                    const google = window.google;
                    const myLatlng = new google.maps.LatLng(pos.lat, pos.lng);
                    const myOptions = {
                        zoom: 16,
                        center: myLatlng,
                        mapTypeId: google.maps.MapTypeId.ROADMAP,
                    };

                    map = new google.maps.Map(document.getElementById("addMap"), myOptions);

                    onSearchBox();
                    onMapClick(); // Enable clicking on the map to add a pin
                },
                () => {
                    console.log("Error: The Geolocation service failed.");
                }
            );
        }
    };

    const onSearchBox = () => {
        const google = window.google;

        const input = document.getElementById("pac-input");
        const searchBox = new google.maps.places.SearchBox(input);

        searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();
            if (places.length === 0) {
                return;
            }

            const place = places[0];
            if (place.geometry && place.geometry.location) {
                setMapLocation(place.geometry.location);
            }
        });
    };

    const onMapClick = () => {
        const google = window.google;

        map.addListener("click", (e) => {
            const location = e.latLng;

            setMapLocation(location);
        });
    };

    const setMapLocation = (location) => {
        const google = window.google;

        if (marker) marker.setMap(null);
        if (circle) circle.setMap(null);

        if (google.maps.marker && google.maps.marker.AdvancedMarkerElement) {
            marker = new google.maps.marker.AdvancedMarkerElement({
                position: location,
                map: map,
                draggable: true,
            });
        } else {
            marker = new google.maps.Marker({
                position: location,
                map: map,
                draggable: true,
            });
        };

        // Create a new circle
        circle = new google.maps.Circle({
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            map: map,
            center: location,
            radius: radius,
        });

        map.setCenter(location);

        handleChangePositions([{
            lat: location.lat(),
            lng: location.lng(),
            idGroupGpsLocations: selectedGroup.idGroupGpsLocations,
        }]);

        google.maps.event.addListener(marker, "dragend", function (event) {
            const newLocation = event.latLng;
            updateMarkerAndCircle(newLocation);
        });
    };

    const updateMarkerAndCircle = (location) => {
        marker.setPosition(location);
        circle.setCenter(location);

        handleChangePositions([{
            lat: location.lat(),
            lng: location.lng(),
            idGroupGpsLocations: selectedGroup.idGroupGpsLocations,
        }]);
    };

    const handleOnReset = () => {
        initialize();
        if (marker) marker.setMap(null);
        if (circle) circle.setMap(null);
        setValues({ ...values, location: [] });
    };

    const handleClearInput = () => {
        document.getElementById("pac-input").value = "";
        setSearch("");
    };

    const loader = new Loader({
        //apiKey: "AIzaSyCZ41b0hPCVif4tewQUlrc2aivCyZf2DWc",
        apiKey: "AIzaSyBJEmVrMq5Zgj27dilri-OK76pn7pgQCho",
        version: "weekly",
        libraries: ["places", "drawing", "geometry"],
    });

    const initialize = () => {
        loader.load().then(() => {
            onMapLoad();
        });
    };

    useEffect(() => {
        initialize();
    }, []);

    useEffect(() => {
        if (circle) {
            const validRadius = Number(radius);
            if (!isNaN(validRadius) && validRadius > 0) {
                circle.setRadius(validRadius);
            } else {
                console.error('Invalid radius value:', radius);
            };
        };
    }, [radius]);

    return (
        <>
            <div>
                <StylePaper>
                    <InputBase
                        style={{ ml: 1, flex: 1 }}
                        id="pac-input"
                        class="controls"
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={t("SearchLocation")}
                        inputProps={{ "aria-label": t("SearchLocation") }}
                    />
                    <IconButton style={{ padding: "10px" }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                    {search.length > 0 ? (
                        <>
                            <Divider
                                style={{ height: 28, margin: 0.5 }}
                                orientation="vertical"
                            />
                            <IconButton
                                style={{ padding: "10px" }}
                                aria-label="directions"
                                onClick={handleClearInput}
                            >
                                <ClearIcon />
                            </IconButton>
                        </>
                    ) : null}
                </StylePaper>
            </div>

            <Box>
                <div id="addMap" style={mapStyles}>
                    Loading ...
                </div>
            </Box>

            <StyleWrapButton>
                <ButtonBlue startIcon={<RestartAltIcon />} onClick={handleOnReset}>
                    Reset
                </ButtonBlue>
            </StyleWrapButton>
        </>
    );
}

export default AddAndPositionMap;