import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ButtonBlue from "./ButtonBlue";
import TextFieldTheme from "./TextFieldTheme";

const DialogPersonID = ({ open, handleClose }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [personalID, setPersonalID] = useState("");
  const [valid, setValid] = useState(true);

  const handleCheckPersonID = (event) => {
    event.preventDefault();
    let temp = personalID;
    if (temp.trim().replace(/ /g, "") === userProfile.personalID) {
      handleClose();
    } else {
      setValid(temp.trim().replace(/ /g, "") === userProfile.personalID);
    }
  };

  const handleClickCancel = () => {
    history.goBack();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCheckPersonID}
      PaperProps={{
        component: "form",
        onSubmit: handleCheckPersonID,
      }}
    >
      <DialogTitle>{t("PleaseEnterNationalIDCardNumber")}</DialogTitle>
      <DialogContent>
        <TextFieldTheme
          type={"password"}
          value={personalID}
          onChange={(e) => setPersonalID(e.target.value)}
          label={t("PersonalID")}
          error={!valid}
          helperText={!valid && t("InvalidData")}
          style={{ marginTop: "8px" }}
        />
      </DialogContent>
      <DialogActions>
        <ButtonBlue variant={"outlined"} onClick={handleClickCancel}>
          {t("Cancel")}
        </ButtonBlue>
        <ButtonBlue
          variant={"contained"}
          type="submit"
          onClick={handleCheckPersonID}
        >
          {t("Confirm")}
        </ButtonBlue>
      </DialogActions>
    </Dialog>
  );
};

export default DialogPersonID;
