const headerStyle1 = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '002060' }
    },
};

const headerStyle2 = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' }
    },
};

const headerStyle3 = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '375623' }
    },
};

const headerStyle4 = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: '000000' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFF00' }
    },
};

const headerStyle5 = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: '000000' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9BC2E6' }
    },
};

const headerStyle6 = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: '000000' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'A9D08E' }
    },
};

const headerStyle7 = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: '000000' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF2CC' }
    },
};

const headerStyle8 = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: '000000' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD966' }
    },
};

const headerStyle9 = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '548235' }
    },
};

const headerStyle10 = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: '000000' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'C6E0B4' }
    },
};

const headerStyle11 = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '808080' }
    },
};

const headerStyle12 = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0000' }
    },
};

export const headerStyles = [
    headerStyle1, headerStyle1,
    headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2,
    headerStyle3, headerStyle3, headerStyle3, headerStyle3, headerStyle3, headerStyle3, headerStyle3, headerStyle3, headerStyle3, headerStyle3, headerStyle3, headerStyle3,
    headerStyle4, headerStyle4, headerStyle4, headerStyle4, headerStyle4, headerStyle4, headerStyle4, headerStyle4, headerStyle4, headerStyle4, headerStyle4, headerStyle4,
    headerStyle5, headerStyle5, headerStyle5, headerStyle5, headerStyle5, headerStyle5, headerStyle5, headerStyle5, headerStyle5, headerStyle5, headerStyle5, headerStyle5,
    headerStyle6, headerStyle6, headerStyle6, headerStyle6, headerStyle6, headerStyle6, headerStyle6, headerStyle6, headerStyle6, headerStyle6, headerStyle6, headerStyle6,
    headerStyle7, headerStyle7, headerStyle7, headerStyle7, headerStyle7, headerStyle7, headerStyle7, headerStyle7, headerStyle7, headerStyle7, headerStyle7, headerStyle7,
    headerStyle8, headerStyle8, headerStyle8, headerStyle8, headerStyle8, headerStyle8, headerStyle8, headerStyle8, headerStyle8, headerStyle8, headerStyle8, headerStyle8,
    headerStyle9, headerStyle9, headerStyle9, headerStyle9, headerStyle9, headerStyle9, headerStyle9, headerStyle9, headerStyle9, headerStyle9, headerStyle9, headerStyle9,
    headerStyle10, headerStyle10, headerStyle10, headerStyle10, headerStyle10, headerStyle10, headerStyle10, headerStyle10, headerStyle10, headerStyle10, headerStyle10, headerStyle10,
    headerStyle11, headerStyle11, headerStyle11, headerStyle11, headerStyle11, headerStyle11, headerStyle11, headerStyle11, headerStyle11, headerStyle11, headerStyle11, headerStyle11,
    headerStyle12, headerStyle12, headerStyle12, headerStyle12, headerStyle12, headerStyle12, headerStyle12, headerStyle12, headerStyle12, headerStyle12, headerStyle12, headerStyle12,
];


export const colWidths = [
    { key: "Cost Center", width: 40 },
    { key: "department", width: 40 },
    { key: "totalPayment_Jan", width: 30 },
    { key: "totalPayment_Feb", width: 30 },
    { key: "totalPayment_Mar", width: 30 },
    { key: "totalPayment_Apr", width: 30 },
    { key: "totalPayment_May", width: 30 },
    { key: "totalPayment_Jun", width: 30 },
    { key: "totalPayment_Jul", width: 30 },
    { key: "totalPayment_Aug", width: 30 },
    { key: "totalPayment_Sep", width: 30 },
    { key: "totalPayment_Oct", width: 30 },
    { key: "totalPayment_Nov", width: 30 },
    { key: "totalPayment_Dec", width: 30 },
    { key: "salaryPayment_Jan", width: 30 },
    { key: "salaryPayment_Feb", width: 30 },
    { key: "salaryPayment_Mar", width: 30 },
    { key: "salaryPayment_Apr", width: 30 },
    { key: "salaryPayment_May", width: 30 },
    { key: "salaryPayment_Jun", width: 30 },
    { key: "salaryPayment_Jul", width: 30 },
    { key: "salaryPayment_Aug", width: 30 },
    { key: "salaryPayment_Sep", width: 30 },
    { key: "salaryPayment_Oct", width: 30 },
    { key: "salaryPayment_Nov", width: 30 },
    { key: "salaryPayment_Dec", width: 30 },
    { key: "totalOT_Jan", width: 30 },
    { key: "totalOT_Feb", width: 30 },
    { key: "totalOT_Mar", width: 30 },
    { key: "totalOT_Apr", width: 30 },
    { key: "totalOT_May", width: 30 },
    { key: "totalOT_Jun", width: 30 },
    { key: "totalOT_Jul", width: 30 },
    { key: "totalOT_Aug", width: 30 },
    { key: "totalOT_Sep", width: 30 },
    { key: "totalOT_Oct", width: 30 },
    { key: "totalOT_Nov", width: 30 },
    { key: "totalOT_Dec", width: 30 },
    { key: "shiftFee_Jan", width: 30 },
    { key: "shiftFee_Feb", width: 30 },
    { key: "shiftFee_Mar", width: 30 },
    { key: "shiftFee_Apr", width: 30 },
    { key: "shiftFee_May", width: 30 },
    { key: "shiftFee_Jun", width: 30 },
    { key: "shiftFee_Jul", width: 30 },
    { key: "shiftFee_Aug", width: 30 },
    { key: "shiftFee_Sep", width: 30 },
    { key: "shiftFee_Oct", width: 30 },
    { key: "shiftFee_Nov", width: 30 },
    { key: "shiftFee_Dec", width: 30 },
    { key: "totalTax_Jan", width: 30 },
    { key: "totalTax_Feb", width: 30 },
    { key: "totalTax_Mar", width: 30 },
    { key: "totalTax_Apr", width: 30 },
    { key: "totalTax_May", width: 30 },
    { key: "totalTax_Jun", width: 30 },
    { key: "totalTax_Jul", width: 30 },
    { key: "totalTax_Aug", width: 30 },
    { key: "totalTax_Sep", width: 30 },
    { key: "totalTax_Oct", width: 30 },
    { key: "totalTax_Nov", width: 30 },
    { key: "totalTax_Dec", width: 30 },
    { key: "totalSocialSecurityCom_Jan", width: 50 },
    { key: "totalSocialSecurityCom_Feb", width: 50 },
    { key: "totalSocialSecurityCom_Mar", width: 50 },
    { key: "totalSocialSecurityCom_Apr", width: 50 },
    { key: "totalSocialSecurityCom_May", width: 50 },
    { key: "totalSocialSecurityCom_Jun", width: 50 },
    { key: "totalSocialSecurityCom_Jul", width: 50 },
    { key: "totalSocialSecurityCom_Aug", width: 50 },
    { key: "totalSocialSecurityCom_Sep", width: 50 },
    { key: "totalSocialSecurityCom_Oct", width: 50 },
    { key: "totalSocialSecurityCom_Nov", width: 50 },
    { key: "totalSocialSecurityCom_Dec", width: 50 },
    { key: "totalSocialSecurityEMP_Jan", width: 50 },
    { key: "totalSocialSecurityEMP_Feb", width: 50 },
    { key: "totalSocialSecurityEMP_Mar", width: 50 },
    { key: "totalSocialSecurityEMP_Apr", width: 50 },
    { key: "totalSocialSecurityEMP_May", width: 50 },
    { key: "totalSocialSecurityEMP_Jun", width: 50 },
    { key: "totalSocialSecurityEMP_Jul", width: 50 },
    { key: "totalSocialSecurityEMP_Aug", width: 50 },
    { key: 'totalSocialSecurityEMP_Sep', width: 50 },
    { key: 'totalSocialSecurityEMP_Oct', width: 50 },
    { key: 'totalSocialSecurityEMP_Nov', width: 50 },
    { key: 'totalSocialSecurityEMP_Dec', width: 50 },
    { key: "PFCompanyTotalJan", width: 40 },
    { key: "PFCompanyTotalFeb", width: 40 },
    { key: "PFCompanyTotalMar", width: 40 },
    { key: "PFCompanyTotalApr", width: 40 },
    { key: "PFCompanyTotalMay", width: 40 },
    { key: "PFCompanyTotalJun", width: 40 },
    { key: "PFCompanyTotalJul", width: 40 },
    { key: "PFCompanyTotalAug", width: 40 },
    { key: "PFCompanyTotalSep", width: 40 },
    { key: "PFCompanyTotalOct", width: 40 },
    { key: "PFCompanyTotalNov", width: 40 },
    { key: "PFCompanyTotalDec", width: 40 },
    { key: "PFEmployeeTotalJan", width: 40 },
    { key: "PFEmployeeTotalFeb", width: 40 },
    { key: "PFEmployeeTotalMar", width: 40 },
    { key: "PFEmployeeTotalApr", width: 40 },
    { key: "PFEmployeeTotalMay", width: 40 },
    { key: "PFEmployeeTotalJun", width: 40 },
    { key: "PFEmployeeTotalJul", width: 40 },
    { key: "PFEmployeeTotalAug", width: 40 },
    { key: "PFEmployeeTotalSep", width: 40 },
    { key: "PFEmployeeTotalOct", width: 40 },
    { key: "PFEmployeeTotalNov", width: 40 },
    { key: "PFEmployeeTotalDec", width: 40 },
    { key: "additionJan", width: 40 },
    { key: "additionFeb", width: 40 },
    { key: "additionMar", width: 40 },
    { key: "additionApr", width: 40 },
    { key: "additionMay", width: 40 },
    { key: "additionJun", width: 40 },
    { key: "additionJul", width: 40 },
    { key: "additionAug", width: 40 },
    { key: "additionSep", width: 40 },
    { key: "additionOct", width: 40 },
    { key: "additionNov", width: 40 },
    { key: "additionDec", width: 40 },
    { key: "deductionJan", width: 40 },
    { key: "deductionFeb", width: 40 },
    { key: "deductionMar", width: 40 },
    { key: "deductionApr", width: 40 },
    { key: "deductionMay", width: 40 },
    { key: "deductionJun", width: 40 },
    { key: "deductionJul", width: 40 },
    { key: "deductionAug", width: 40 },
    { key: "deductionSep", width: 40 },
    { key: "deductionOct", width: 40 },
    { key: "deductionNov", width: 40 },
    { key: "deductionDec", width: 40 },
]


// const headerRow = worksheet1.addRow([
//     'Cost Center', 'หน่วยงาน',
//     'รวมจ่าย รวมบาทเดือน Jan', 'รวมจ่าย รวมบาทเดือน Feb',
//     'รวมจ่าย รวมบาทเดือน Mar', 'รวมจ่าย รวมบาทเดือน Apr', 'รวมจ่าย รวมบาทเดือน May',
//     'รวมจ่าย รวมบาทเดือน Jun', 'รวมจ่าย รวมบาทเดือน Jul', 'รวมจ่าย รวมบาทเดือน Aug',
//     'รวมจ่าย รวมบาทเดือน Sep', 'รวมจ่าย รวมบาทเดือน Oct', 'รวมจ่าย รวมบาทเดือน Nov',
//     'รวมจ่าย รวมบาทเดือน Dec',
//     'เงินเดือน รวมบาทเดือน Jan', 'เงินเดือน รวมบาทเดือน Feb',
//     'เงินเดือน รวมบาทเดือน Mar', 'เงินเดือน รวมบาทเดือน Apr', 'เงินเดือน รวมบาทเดือน May',
//     'เงินเดือน รวมบาทเดือน Jun', 'เงินเดือน รวมบาทเดือน Jul', 'เงินเดือน รวมบาทเดือน Aug',
//     'เงินเดือน รวมบาทเดือน Sep', 'เงินเดือน รวมบาทเดือน Oct', 'เงินเดือน รวมบาทเดือน Nov',
//     'เงินเดือน รวมบาทเดือน Dec', 'OT รวมบาทเดือน Jan',
//     'OT รวมบาทเดือน Feb',
//     'OT รวมบาทเดือน Mar', 'OT รวมบาทเดือน Apr', 'OT รวมบาทเดือน May', 'OT รวมบาทเดือน Jun',
//     'OT รวมบาทเดือน Jul', 'OT รวมบาทเดือน Aug', 'OT รวมบาทเดือน Sep', 'OT รวมบาทเดือน Oct',
//     'OT รวมบาทเดือน Nov', 'OT รวมบาทเดือน Dec',
//     'ค่ากะ รวมบาทเดือน Jan', 'ค่ากะ รวมบาทเดือน Feb',
//     'ค่ากะ รวมบาทเดือน Mar', 'ค่ากะ รวมบาทเดือน Apr', 'ค่ากะ รวมบาทเดือน May', 'ค่ากะ รวมบาทเดือน Jun',
//     'ค่ากะ รวมบาทเดือน Jul', 'ค่ากะ รวมบาทเดือน Aug', 'ค่ากะ รวมบาทเดือน Sep', 'ค่ากะ รวมบาทเดือน Oct',
//     'ค่ากะ รวมบาทเดือน Nov', 'ค่ากะ รวมบาทเดือน Dec',
//     'ภาษีนำส่ง รวมบาทเดือน Jan',
//     'ภาษีนำส่ง รวมบาทเดือน Feb', 'ภาษีนำส่ง รวมบาทเดือน Mar', 'ภาษีนำส่ง รวมบาทเดือน Apr',
//     'ภาษีนำส่ง รวมบาทเดือน May', 'ภาษีนำส่ง รวมบาทเดือน Jun', 'ภาษีนำส่ง รวมบาทเดือน Jul',
//     'ภาษีนำส่ง รวมบาทเดือน Aug', 'ภาษีนำส่ง รวมบาทเดือน Sep', 'ภาษีนำส่ง รวมบาทเดือน Oct',
//     'ภาษีนำส่ง รวมบาทเดือน Nov', 'ภาษีนำส่ง รวมบาทเดือน Dec',
//     'ประกันสังคมส่วนบริษัท รวมบาทเดือน Jan',
//     'ประกันสังคมส่วนบริษัท รวมบาทเดือน Feb', 'ประกันสังคมส่วนบริษัท รวมบาทเดือน Mar',
//     'ประกันสังคมส่วนบริษัท รวมบาทเดือน Apr', 'ประกันสังคมส่วนบริษัท รวมบาทเดือน May',
//     'ประกันสังคมส่วนบริษัท รวมบาทเดือน Jun', 'ประกันสังคมส่วนบริษัท รวมบาทเดือน Jul',
//     'ประกันสังคมส่วนบริษัท รวมบาทเดือน Aug', 'ประกันสังคมส่วนบริษัท รวมบาทเดือน Sep',
//     'ประกันสังคมส่วนบริษัท รวมบาทเดือน Oct', 'ประกันสังคมส่วนบริษัท รวมบาทเดือน Nov',
//     'ประกันสังคมส่วนบริษัท รวมบาทเดือน Dec',
//     'ประกันสังคมส่วนพนักงาน รวมบาทเดือน Jan',
//     'ประกันสังคมส่วนพนักงาน รวมบาทเดือน Feb', 'ประกันสังคมส่วนพนักงาน รวมบาทเดือน Mar',
//     'ประกันสังคมส่วนพนักงาน รวมบาทเดือน Apr', 'ประกันสังคมส่วนพนักงาน รวมบาทเดือน May',
//     'ประกันสังคมส่วนพนักงาน รวมบาทเดือน Jun', 'ประกันสังคมส่วนพนักงาน รวมบาทเดือน Jul',
//     'ประกันสังคมส่วนพนักงาน รวมบาทเดือน Aug', 'ประกันสังคมส่วนพนักงาน รวมบาทเดือน Sep',
//     'ประกันสังคมส่วนพนักงาน รวมบาทเดือน Oct', 'ประกันสังคมส่วนพนักงาน รวมบาทเดือน Nov',
//     'ประกันสังคมส่วนพนักงาน รวมบาทเดือน Dec',

//     'PFส่วนบริษัท รวมบาทเดือน Jan',
//     'PFส่วนบริษัท รวมบาทเดือน Feb', 'PFส่วนบริษัท รวมบาทเดือน Mar', 'PFส่วนบริษัท รวมบาทเดือน Apr',
//     'PFส่วนบริษัท รวมบาทเดือน May', 'PFส่วนบริษัท รวมบาทเดือน Jun', 'PFส่วนบริษัท รวมบาทเดือน Jul',
//     'PFส่วนบริษัท รวมบาทเดือน Aug', 'PFส่วนบริษัท รวมบาทเดือน Sep', 'PFส่วนบริษัท รวมบาทเดือน Oct',
//     'PFส่วนบริษัท รวมบาทเดือน Nov', 'PFส่วนบริษัท รวมบาทเดือน Dec',

//     'PFส่วนพนักงาน รวมบาทเดือน Jan',
//     'PFส่วนพนักงาน รวมบาทเดือน Feb', 'PFส่วนพนักงาน รวมบาทเดือน Mar', 'PFส่วนพนักงาน รวมบาทเดือน Apr',
//     'PFส่วนพนักงาน รวมบาทเดือน May', 'PFส่วนพนักงาน รวมบาทเดือน Jun', 'PFส่วนพนักงาน รวมบาทเดือน Jul',
//     'PFส่วนพนักงาน รวมบาทเดือน Aug', 'PFส่วนพนักงาน รวมบาทเดือน Sep', 'PFส่วนพนักงาน รวมบาทเดือน Oct',
//     'PFส่วนพนักงาน รวมบาทเดือน Nov', 'PFส่วนพนักงาน รวมบาทเดือน Dec',

//     'เงินเพิ่ม (Addition) รวมบาทเดือน Jan', 'เงินเพิ่ม (Addition) รวมบาทเดือน Feb', 'เงินเพิ่ม (Addition) รวมบาทเดือน Mar', 'เงินเพิ่ม (Addition)  รวมบาทเดือน Apr',
//     'เงินเพิ่ม (Addition)  รวมบาทเดือน May', 'เงินเพิ่ม (Addition) รวมบาทเดือน Jun', 'เงินเพิ่ม (Addition) รวมบาทเดือน Jul', 'เงินเพิ่ม (Addition) รวมบาทเดือน Aug',
//     'เงินเพิ่ม (Addition) รวมบาทเดือน Sep', 'เงินเพิ่ม (Addition)  รวมบาทเดือน Oct', 'เงินเพิ่ม (Addition) รวมบาทเดือน Nov', 'เงินเพิ่ม (Addition) รวมบาทเดือน Dec',

//     'เงินหัก (Deduction) รวมบาทเดือน Jan', 'เงินหัก (Deduction) รวมบาทเดือน Feb', 'เงินหัก (Deduction) รวมบาทเดือน Mar', 'เงินหัก (Deduction)  รวมบาทเดือน Apr',
//     'เงินหัก (Deduction)  รวมบาทเดือน May', 'เงินหัก (Deduction) รวมบาทเดือน Jun', 'เงินหัก (Deduction) รวมบาทเดือน Jul', 'เงินหัก (Deduction) รวมบาทเดือน Aug',
//     'เงินหัก (Deduction) รวมบาทเดือน Sep', 'เงินหัก (Deduction)  รวมบาทเดือน Oct', 'เงินหัก (Deduction) รวมบาทเดือน Nov', 'เงินหัก (Deduction) รวมบาทเดือน Dec'
// ]);