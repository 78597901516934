import React, { useEffect } from "react";
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getKpiApprove } from "../../../../../actions/kpi";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { useTranslation } from "react-i18next";
import { getUserFullName, getUserPosition } from "../../../../../utils/userData";
const StyledRoot = styled(Box)({});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));



const KpiApproval = () => {
  const dispatch = useDispatch();
  const { result: kpiPlanStore } = useSelector(
    (state) => state.kpiResultApprove
  );
  const {t,i18n} = useTranslation();
const columns = [
  {
    name: t("FullName"),
    minWidth: "200px",
    width: "200px",
    cellRender: (row) => (
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Typography>
            {getUserFullName(row)}
          </Typography>
          <Typography color="text.third" fontSize="14px">
            {getUserPosition(row)}
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    name: t("KPIName"),
    minWidth: "230px",
    width: "230px",
    cellRender: (row) => (
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Typography>{row.kpiName}</Typography>
          <Typography color="text.third" fontSize="14px">
            {getUserPosition(row)}
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    name: t("Details"),
    minWidth: "230px",
    width: "230px",
    cellRender: (row) => (
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Typography>{row.commentKPI || "-"}</Typography>
          <Typography color="text.third" fontSize="14px">
            {getUserPosition(row)}
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    name: t("Status"),
    minWidth: "100px",
    width: "50px",
    cellRender: (row) => {
      if (row.isApprove === null) {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "SteelBlue",
              }}
            >
              <Typography fontSize="14px" sx={{ color: "white" }}>
                {t("WaitingForApproval")}
              </Typography>
            </Button>
          </Box>
        );
      }

      const statusText = row.isApprove === 1 ? t("Approve") : t("NotApproved");
      const statusColor = row.isApprove === 1 ? "success" : "error";

      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box flexGrow={1}>
            <Button
              variant="contained"
              color={statusColor}
              sx={{
                textTransform: "none",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {statusText}
            </Button>
            <Typography
              color="text.third"
              fontSize="14px"
              sx={{ width: "100%" }}
            >
              {getUserPosition(row)}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
];
  useEffect(() => {
    dispatch(getKpiApprove());
  }, [dispatch]);

  return (
    <StyledRoot className="page">
      <div className="cardPlan">
        <Box padding="2px 0">
          <Container maxWidth="lg">
            <Box padding="2px">
              <Box paddingBottom="24px">
                <Typography fontSize="24px">{t("ApprovalResult")} KPI</Typography>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((c, index) => (
                        <StyledCellHeader
                          key={index}
                          sx={{
                            textAlign: c.headerTextAlign
                              ? c.headerTextAlign
                              : undefined,
                            minWidth: c.minWidth ? c.minWidth : undefined,
                            width: c.width ? c.width : undefined,
                            maxWidth: c.maxWidth ? c.maxWidth : undefined,
                          }}
                        >
                          {c.name}
                        </StyledCellHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {kpiPlanStore &&
                      kpiPlanStore.map((r, index) => (
                        <TableRow key={index}>
                          {columns.map((c, cIndex) => (
                            <StyledCellContent
                              key={cIndex}
                              style={{
                                textAlign: c.cellTextAlign
                                  ? c.cellTextAlign
                                  : undefined,
                                fontSize: c.cellFontSize
                                  ? c.cellFontSize
                                  : undefined,
                                minWidth: c.minWidth ? c.minWidth : undefined,
                                width: c.width ? c.width : undefined,
                                maxWidth: c.maxWidth ? c.maxWidth : undefined,
                              }}
                            >
                              {c.cellRender ? (
                                c.cellRender(r)
                              ) : c.fieldKey ? (
                                <Typography fontSize="inherit">
                                  {r[c.fieldKey]}
                                </Typography>
                              ) : null}
                            </StyledCellContent>
                          ))}
                        </TableRow>
                      ))}

                    {(!kpiPlanStore || kpiPlanStore.length <= 0) && (
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          <Box
                            height="120px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography variant="h6">
                              <FindInPageIcon />
                              {t("NoData")}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Container>
        </Box>
      </div>
    </StyledRoot>
  );
};

export default KpiApproval;
