import { httpClient } from "./httpClient";

const getWarningLetter = (idCompany) => {
  return httpClient.get("warning-letter", { params: { idCompany } });
};

const addWarningLetter = (formData) => {
  return httpClient.post("warning-letter", formData);
};

const updateWarningLetter = (formData) => {
  return httpClient.put("warning-letter", formData);
};

const deleteWarningLetter = (id) => {
  return httpClient.delete(`warning-letter/${id}`);
};

const updateWarningLetterStatus = (formData) => {
  return httpClient.put("warning-letter-status", formData);
};

const getWarningLetterByIdEmployee = () => {
  return httpClient.get("warning-letter-employee");
};

const getWarningLevel = (idCompany) => {
  return httpClient.get("warning-letter-level", { params: { idCompany }});
};

const updateWarningLevel = (formData) => {
  return httpClient.put("warning-letter-level", formData);
};

export default {
  getWarningLetter,
  addWarningLetter,
  updateWarningLetter,
  deleteWarningLetter,
  updateWarningLetterStatus,
  getWarningLetterByIdEmployee,
  getWarningLevel,
  updateWarningLevel,
};
