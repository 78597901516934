import React, { useEffect, useState, useRef, Fragment } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import { IconButton, Typography } from "@mui/material";

const StepTwo = (props) => {
  return (
    <div>
      <Paper
        variant="outlined"
        style={{
          paddingLeft: 48,
          border: "none",
          display: "flex",
          alignItems: "flex-start",
          marginBottom: 16
        }}
      >
        <div>
          <Typography variant="h6">Programmer (9 ปี)</Typography>
        </div>
      </Paper>
      <Paper
        variant="outlined"
        style={{
          paddingLeft: 48,
          border: "none",
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <div>
          <Typography variant="h6">Graphic & Multimedia (4 ปี)</Typography>
        </div>
      </Paper>
    </div>
  );
};
export default StepTwo;
