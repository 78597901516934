import React, { useState, useRef, useEffect } from "react";
import * as Yup from "yup";
import {
  MenuItem,
  Typography,
  Grid,
  Button,
  Container,
  Paper,
  TextField,
  Select,
  FormControl,
  InputLabel
} from "@mui/material";
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import SaveIcon from "@mui/icons-material/Save";
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Breadcrumb2 } from "../../shared/breadcrums";
import dayjs from "dayjs";
import { addProject, getProjectById, updateProject } from "../../../../actions/project";
import DatePickerCustom from "../../shared/date/datePicker";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  formControl: {
    marginRight: 8,
  },
  buttonDelete: {
    backgroundColor: "red",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 42,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  icon: {
    fontSize: 19,
    color: '#0b3f86',
  },
  nameDemand: {
    fontSize: 24,
    fontWeight: "bold",
    marginLeft: 20,
  },
  breadcrumbs: {
    marginTop: 5,
    fontSize: 18,
  },
  dataText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  wrapCaptionImage: {
    marginTop: 16,
  },
  captionImage: {
    color: "#212b36",
  },
  firstSection: {
    padding: "60px 24px",
    margin: 10,
  },
  imageProfile: {
    marginBottom: 62,
    textAlign: "center",
  },
  placeholder: {
    width: "100%",
    height: "100%",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "rgb(99, 115, 129)",
    backgroundColor: "rgb(224, 246, 248)",
    transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  placeholderImageProfile: {
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgba(22, 28, 36, .72)",
  },
  placeholderLabel: {
    color: "rgb(255, 255, 255)",
  },
  captionImage: {
    color: "#212b36",
    fontSize: 14,
  },
  wrapCaptionImage: {
    textAlign: "center",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 7,
  },
  text: {
    fontSize: 18,
    marginBottom: 7,
    // color: ''
  },
  fieldDate: {
    "& .MuiFormControl-root": {
      width: '100%'
    }
  },
  fieldInterDate: {
    "& .MuiFormControl-root": {
      width: 'calc(100% - 16px)'
    }
  },
  nameHeader: {
    fontSize: 21,
    fontWeight: 'bold',
    marginBottom: 10,
    marginLeft: 10,
  }
}));

const AddProject = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [search, setSearch] = useState({
    start: new Date(),
    end: new Date()
  });
  const { title, idProject } = props;
  const [dataProject, setDataProject] = useState(
    {
      ProjectName: '',
      Type: '',
      reason: '',
      impact: '',
      directReturn: '',
      return: '',
      size: '',
      dateStart: null,
      dateEnd: null,
      role: '',
      note: '',
    });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDataProject({
      ...dataProject,
      [name]: value,
    });
  };

  const handleDateChange = (name, newValue) => {
    setDataProject(prevDataProject => ({
      ...prevDataProject,
      [name]: newValue,
    }));
  };

  const handleSubmit = async (title) => {
    try {
      let response;
      if (title === "update") {
        response = await updateProject(idProject, dataProject);
      } else {
        response = await addProject(dataProject);
      }
      if (response && response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchedData = async (id) => {
    try {
      const response = await getProjectById(id);
      if (response && response.data) {
        setDataProject(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (idProject) {
      fetchedData(idProject);
    }
  }, [idProject]);

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        <Typography variant="h4" sx={{ mb: 2 }}>
          {title === "update" ? t("updateProject") : t("AddNewProject")}
        </Typography>
        <Paper style={{ padding: 20, paddingRight: "35px" }}>
          <Grid container direction='row' spacing={2}>
            <Grid item xl={12} lg={12} md={!2}>
              <Typography className={classes.nameHeader}>{t("projectDetails")}</Typography>
            </Grid>
            <Grid item xl={12} lg={12} md={12} xs={12} >
              {/* <Typography className={classes.text}> <LocalOfferOutlinedIcon className={classes.icon}/> Project name</Typography> */}
              <TextField
                id="ProjectName"
                name='ProjectName'
                label={t("ProjectName")}
                variant="outlined"
                required
                fullWidth
                onChange={handleChange}
                value={dataProject.ProjectName}
                placeholder={t("ProjectName")}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={4} xs={12} >
              {/* <Typography className={classes.text}> <DragIndicatorIcon className={classes.icon}/> Select type </Typography> */}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t("Type")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="impact-select"
                  label={t("selectImpact")}
                  value={dataProject.Type}
                  name='Type'
                  onChange={handleChange}
                >
                  <MenuItem value="First type">{t("firstType")}</MenuItem>
                  <MenuItem value="Second type">{t("secondType")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xl={8} lg={8} md={8} xs={12} >
              {/* <Typography className={classes.text}> <DehazeIcon className={classes.icon}/> Reason </Typography> */}
              <TextField
                label={t("Reason")}
                variant="outlined"
                name="reason"
                required
                fullWidth
                onChange={handleChange}
                value={dataProject.reason}
                placeholder={t("Reason")}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={4} xs={12} >
              {/* <Typography className={classes.text}> <BallotIcon className={classes.icon}/> Select impact </Typography> */}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t("Impact")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  name='impact'
                  id="impact-select"
                  label={t("selectImpact")}
                  value={dataProject.impact}
                  onChange={handleChange}
                >
                  <MenuItem value="YES">{t("Yes")}</MenuItem>
                  <MenuItem value="NO">{t("No")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xl={4} lg={4} md={4} xs={12} >
              {/* <Typography className={classes.text}> 
                          <AssignmentReturnIcon className={classes.icon}/> Select direct return 
                        </Typography> */}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t("directReturn")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={dataProject.directReturn}
                  label={t("Age")}
                  onChange={handleChange}
                  name="directReturn"
                >
                  <MenuItem value="YES">{t("Yes")}</MenuItem>
                  <MenuItem value="NO">{t("No")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xl={4} lg={4} md={4} xs={12} >
              {/* <Typography className={classes.text}> 
                            <MonetizationOnIcon className={classes.icon}/> Select return ฿
                          </Typography> */}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t("return")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={t("return")}
                  onChange={handleChange}
                  value={dataProject.return}
                  name="return"
                >
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={200}>200</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xl={12} lg={12} md={12}>
              <Typography className={classes.nameHeader} style={{ marginTop: 20 }}>{t("projectDeepDetail")}</Typography>
            </Grid>

            <Grid item md={6} xs={12}>
              <div className={classes.fieldInterDate}>
                <Typography sx={{ p: 1 }}>
                  {t("StartDate")}
                </Typography>
                <DatePickerCustom
                  inputFormat="DD/MM/YYYY"
                  value={dataProject.dateStart}
                  minDate={new Date(new Date().getFullYear() - 4, 0, 1)}
                  maxDate={dataProject.dateEnd ? dataProject.dateEnd : null}
                  name="dateStart"
                  views={["year", "month", "day"]}
                  onChange={(newValue) => handleDateChange("dateStart", newValue)}
                />
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <div className={classes.fieldDate}>
                <Typography sx={{ p: 1 }}>
                  {t("EndDate")}
                </Typography>
                <DatePickerCustom
                  inputFormat="DD/MM/YYYY"
                  value={dataProject.dateEnd}
                  minDate={dataProject.dateStart}
                  name="dateEnd"
                  views={["year", "month", "day"]}
                  onChange={(newValue) => handleDateChange("dateEnd", newValue)}
                />
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              {/* <Typography className={classes.text}> <AssignmentIndIcon className={classes.icon}/> Select role </Typography> */}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t("Role")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={t("selectRole")}
                  value={dataProject.role}
                  onChange={handleChange}
                  name="role"
                >
                  <MenuItem value="Leader">{t("leader")}</MenuItem>
                  <MenuItem value="Employee">{t("Employee")}</MenuItem>
                  <MenuItem value="IT support">{t("itSupport")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              {/* <Typography className={classes.text}> <AspectRatioIcon className={classes.icon}/> Select size </Typography> */}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t("Size")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={t("Size")}
                  onChange={handleChange}
                  value={dataProject.size}
                  name="size"
                >
                  <MenuItem value="Small project">{t("smallProject")}</MenuItem>
                  <MenuItem value="Medium project">{t("mediumProject")}</MenuItem>
                  <MenuItem value="Mega project">{t("megaProject")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xl={12} lg={12} md={12}>
              <Typography className={classes.nameHeader} style={{ marginTop: 20 }}>{t("Note")}</Typography>
            </Grid>
            <Grid item xl={12} lg={12} md={12} xs={12}  >
              {/* <Typography className={classes.text}> <NoteAltIcon className={classes.icon}/> Note scope/Impact </Typography> */}

              <TextField
                name='note'
                label={t("noteScopeImpact")}
                variant="outlined"
                placeholder={t("noteScopeImpact")}
                fullWidth
                multiline
                onChange={handleChange}
                value={dataProject.note}
                rows={3}
              />
            </Grid>
          </Grid>
        </Paper>

        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            disabled={dataProject.ProjectName === null}
            style={{
              backgroundColor: dataProject.ProjectName ? "green" : "grey",
              color: "white",
              fontSize: 16,
              marginBottom: 40,
              marginTop: 20,
            }}
            onClick={() => handleSubmit(title)}
          >
            {t("Submit")}
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default AddProject;
