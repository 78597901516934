import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Box, Grid } from '@mui/material';

import DrawerCustom from '../../../../../shared/general/Drawer';
import TextFieldTheme from '../../../../../shared/general/TextFieldTheme';
import ButtonBlue from '../../../../../shared/general/ButtonBlue';
import { useDispatch } from 'react-redux';
import { getManpowerById } from "../../../../../../../actions/manpower";
import { updateRatingAndNoteByIdCandidate } from "../../../../../../../actions/candidate";

const drawerNoteInterview = (props) => {
  const { open, onClose, data, idManpower } = props;
  // console.log(data);
  const { handleSubmit, control, formState: { errors, dirtyFields, isDirty } } = useForm({
    defaultValues: {
      note: data.Note || "",
    }
  });


  const dispatch = useDispatch();

	const HandlerSubmit = (submitData) => {
    // console.log(submitData);
    const formData = {};

    for (const key in dirtyFields) {
      formData[key] = submitData[key];
    }

    dispatch(updateRatingAndNoteByIdCandidate(data.idCandidate, formData)).then(res => {
      dispatch(getManpowerById(idManpower));
    })
    onClose();
  }

  return (
    <DrawerCustom
      open={open}
      title={"Note จาก HR/กรรมการ"}
      anchor="right"
    >
      <Box style={{ width: 400, padding: 16 }}>
        <form onSubmit={handleSubmit(HandlerSubmit)}>
        {/* <form> */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextFieldTheme 
                    {...field}
                    placeholder="Note จาก HR/กรรมการ"
                    inputProps={{
                      maxLength: 200,
                    }}
                    fullWidth
                    multiline
                    minRows={4}
                    // disabled
                    // sx={{
                    //   '& .MuiInputBase-input.Mui-disabled': {
                    //     color: '#637381',
                    //     WebkitTextFillColor: '#637381',
                    //   },
                    // }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <ButtonBlue
                  variant="outlined"
                  onClick={onClose}
                >ปิด</ButtonBlue>
                <ButtonBlue
                  variant="contained"
                  type="submit"
                  disabled={!isDirty}
                >ยืนยัน</ButtonBlue>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </DrawerCustom>
  );
};

export default drawerNoteInterview;