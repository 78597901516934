import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledChip = styled(Chip)(({ statusRequest }) => ({
  height: 24,
  ...(statusRequest === 1 && {
    backgroundColor: "#ebfaf2",
  }),
  ...(statusRequest === 2 && {
    backgroundColor: "#fdf3f5",
  }),
  ...(statusRequest === 3 && {
    backgroundColor: "#fffcd6",
  }),
  ...(statusRequest === 4 && {
    backgroundColor: "#fdf3f5",
  }),
  ...(statusRequest === 0 && {
    backgroundColor: "#fffcd6",
  }),
}));

const StatusRequest = ({ status }) => {
  const renderIcon = (status) => {
    if (status === 1) {
      return (
        <i
          className={`fas fa-check-circle`}
          style={{ fontSize: 16, color: "#00c292" }}
        ></i>
      );
    } else if (status === 2) {
      return (
        <i
          className={`fas fa-times-circle`}
          style={{ fontSize: 16, color: "#e46a76" }}
        ></i>
      );
    } else if (status === 3) {
      return (
        <i
          className={`fas fa-question-circle`}
          style={{ fontSize: 16, color: "#e46a76" }}
        ></i>
      );
    } else {
      return (
        <i
          className={`fas fa-question-circle`}
          style={{ fontSize: 16, color: "#fec90f" }}
        ></i>
      );
    }
  };
  const { t, i18n } = useTranslation();
  const renderStatusText = (status) => {
    
    if (status === 1) {
      return (
        <Typography
          style={{ fontSize: 14, fontWeight: "600", color: "#00c292" }}
        >
          {`${t("Approved")}`}
        </Typography>
      );
    } else if (status === 2) {
      return (
        <Typography
          style={{ fontSize: 14, fontWeight: "600", color: "#e46a76" }}
        >
          {`${t("NotApproved")}`}
        </Typography>
      );
    } else if (status === 3) {
      return (
        <Typography
          style={{ fontSize: 14, fontWeight: "600", color: "#fec90f" }}
        >
          {`${t("PendingWithdraw")}`}
        </Typography>
      );
    } else if (status === 4) {
      return (
        <Typography
        style={{ fontSize: 14, fontWeight: "600", color: "#e46a76" }}
        >
          {`${t("Withdraw")}`}
        </Typography>
      );
    } else {
      return (
        <Typography
          style={{ fontSize: 14, fontWeight: "600", color: "#fec90f" }}
        >
          {`${t("PendingApproval")}`}
        </Typography>
      );
    }
  };

  return <StyledChip label={renderStatusText(status)} statusRequest={status} />;
};

export default StatusRequest;
