import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, styled, IconButton, Stack } from '@mui/material';
import ButtonBlue from '../../../../shared/general/ButtonBlue';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from "../../../../assets/add.png";
import ExcelJS from 'exceljs';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AlertResponse from '../../../../shared/general/AlertResponse';
import DrawerCustom from '../../../../shared/general/Drawer';
import { addGPSwithExcel, getAllLocations } from '../../../../../../actions/gpsLocations';
import { useDispatch } from 'react-redux';

const StyledDropzone = styled("div")({
    "& .dropzone-upload-file": {
        textAlign: "center",
        backgroundColor: "#F9F9FB",
        border: "1px solid #D0D0D0",
        borderStyle: "dashed",
        borderRadius: 8,
        height: 200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center", // Center content vertically
        "& .inner-dropzone": {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center content horizontally
            "& img": {
                marginTop: 8,
            },
        },
    },
});

const StyledRoot = styled("div")({
    width: 350,
    padding: 24,
    "& .wrap-form": {
        marginBottom: 16,
    },
    "& .wrap-action": {
        display: "flex",
        justifyContent: "flex-end",
    },
});

export const DumpExcelGPSGroup = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { open, handleClose, idCompany } = props;
    const [data, setData] = useState(null);
    const [file, setFile] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(null);

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const onDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        setFile(file);

        const reader = new FileReader();
        reader.onload = async (event) => {
            const arrayBuffer = event.target.result;
            const workbook = new ExcelJS.Workbook();
            await workbook.xlsx.load(arrayBuffer);

            const worksheet = workbook.getWorksheet(1);

            let errorMessage = "";
            let formData = {};

            worksheet.eachRow((row, rowNumber) => {
                // Skip the first row (headers)
                if (rowNumber > 1) {
                    let groupGpsLocationsName = row.getCell(1).value;
                    let gpsName = row.getCell(2).value;
                    let selectedType = parseInt(row.getCell(3).value);
                    let radius = row.getCell(4).value > 0 ? row.getCell(4).value : null;
                    let radiusInform = row.getCell(5).value > 0 ? row.getCell(5).value : null;

                    if (!gpsName || !selectedType) {
                        errorMessage = `แถวที่ ${rowNumber}: กรุณากรอกข้อมูลให้ครบ`;
                        return;
                    }

                    if (selectedType === 2 && (typeof radius !== 'number' || !radius)) {
                        errorMessage = `แถวที่ ${rowNumber}: กรุณากรอกช่องรัศมี (ให้เป็นตัวเลข)`;
                        return;
                    }

                    if (selectedType === 2 && (typeof radiusInform !== 'number' || !radiusInform)) {
                        errorMessage = `แถวที่ ${rowNumber}: กรุณากรอกช่องระยะเผื่อเช็คอิน (ให้เป็นตัวเลข)`;
                        return;
                    }

                    if (selectedType !== 1 && selectedType !== 2) {
                        errorMessage = `แถวที่ ${rowNumber}: กรุณากรอกประเภทตามที่กำหนดไว้ (1 หรือ 2)`;
                        return;
                    }

                    const lat = row.getCell(6).value;
                    const lng = row.getCell(7).value;

                    if (!lat || !lng) {
                        errorMessage = `แถวที่ ${rowNumber}: กรุณากรอก Latitude และ Longitude`;
                        return;
                    }

                    // Initialize or update the grouped data structure
                    if (!formData[groupGpsLocationsName]) {
                        formData[groupGpsLocationsName] = {
                            name: groupGpsLocationsName,
                            isActive: 1,
                            idCompany: idCompany,
                            gpsLocation: []
                        };
                    };

                    // Check if gpsName already exists in the current group
                    let gpsLocationEntry = formData[groupGpsLocationsName].gpsLocation.find(gps => gps.gpsName === gpsName);

                    if (selectedType === 2) {
                        // Ensure that there's only one entry for idGpsType 2 (unique gpsName)
                        if (!gpsLocationEntry) {
                            formData[groupGpsLocationsName].gpsLocation.push({
                                gpsName: gpsName,
                                isActive: 1,
                                idCompany: idCompany,
                                idGpsType: selectedType,
                                lat: lat,
                                lng: lng,
                                radius: radius,
                                isActive: 1,
                                radiusInform: radiusInform,
                            });
                        } else {
                            errorMessage = `แถวที่ ${rowNumber}: GPS Name "${gpsName}" ของ ${groupGpsLocationsName} ถูกใช้งานแล้วสำหรับประเภท 2`;
                            return;
                        }
                    } else if (selectedType === 1) {
                        // If gpsName already exists, push new position to the array
                        if (!gpsLocationEntry) {
                            gpsLocationEntry = {
                                gpsName: gpsName,
                                isActive: 1,
                                idCompany: idCompany,
                                idGpsType: selectedType,
                                position: []
                            };

                            formData[groupGpsLocationsName].gpsLocation.push(gpsLocationEntry);
                        }
                        // Add position for idGpsType 1
                        gpsLocationEntry.position.push({ lat, lng, isActive: 1 });
                    }
                }
            });

            if (errorMessage) {
                setOpenAlert(true);
                setAlertType(errorMessage);
                return;
            }

            // Convert the object to an array for easier handling
            const groupedData = Object.values(formData);
            setData(groupedData);
        };

        reader.readAsArrayBuffer(file);
    };

    const handleRemoveFile = () => {
        setFile(null);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: '.xlsx',
        multiple: false,
    });

    const DownloadTemplate = () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet("GPS Location");

            const headerRow = worksheet.addRow([
                "ชื่อรายการหรือกลุ่ม",
                "ชื่อตำแหน่ง",
                "ประเภท",
                "รัศมี (เมตร)",
                "ระยะเผื่อเช็คอินห่างจากรัศมีได้ไม่เกิน (ระยะเป็นเมตร (m))",
                "ตำแหน่งละติจูด",
                "ตำแหน่งลองจิจูด",
            ]);

            const headerStyle = {
                font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
                alignment: { horizontal: "center", vertical: 'middle' },
                fill: {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '002060' }
                },
            };

            headerRow.height = 50;
            headerRow.eachCell((cell) => {
                cell.style = headerStyle;
            });

            const colWidths = [
                { header: "ชื่อรายการหรือกลุ่ม", key: "groupName", width: 40 },
                { header: "ชื่อตำแหน่ง", key: "positionName", width: 40 },
                { header: "ประเภท", key: "type", width: 70 },
                { header: "รัศมี", key: "radius", width: 70 },
                { header: "ระยะเผื่อเช็คอินห่างจากรัศมีได้ไม่เกิน (ระยะเป็นเมตร (m))", key: "radiusInform", width: 70 },
                { header: "ตำแหน่งละติจูด", key: "latitude", width: 40 },
                { header: "ตำแหน่งลองจิจูด", key: "longitude", width: 40 },
            ];

            colWidths.forEach((col, index) => {
                worksheet.getColumn(index + 1).width = col.width;
            });

            worksheet.getColumn(6).numFmt = '@';
            worksheet.getColumn(7).numFmt = '@';

            const rows = [
                ["", "", "ให้กรอกเป็น 1 ในกรณีที่เลือกสร้างกรอบแผนที่ หรือ 2 ในกรณีที่เลือกรัศมี", "กรณีเลือก 1 ไม่ต้องใส่ค่ารัศมี (ลบข้อความออกก่อนส่งข้อมูล)", "กรณีเลือก 1 ไม่ต้องใส่ค่าระยะเผื่อ (ลบข้อความออกก่อนส่งข้อมูล)", "", ""],
            ];

            rows.forEach((row) => {
                const excelRow = worksheet.addRow(row);
                const contentStyle = {
                    font: {
                        size: 20,
                        name: 'TH SarabunPSK',
                        color: { argb: 'FFFF0000' },
                    },
                };

                excelRow.eachCell((cell) => {
                    cell.style = contentStyle;
                    cell.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } }
                    };
                });
            });

            workbook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `GPS Group.xlsx`;
                a.click();
                URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleSaveGpsLocations = async () => {
        try {
            const response = await addGPSwithExcel(data);
            if (response && response.status === 200) {
                setOpenAlert(true);
                setAlertType("success");
                dispatch(getAllLocations({ idCompany: idCompany }));
                handleClose();
            };
        } catch (error) {
            setOpenAlert(true);
            setAlertType("error");
            console.error(error);
        };
    };

    return (
        <DrawerCustom
            title={`${t("Add")} ${t("AreaList")}/${t("Location")}`}
            anchor={"right"}
            open={open}
            onClose={handleClose}
        >
            <StyledRoot>
                <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mb: 2 }}>
                    <ButtonBlue onClick={() => DownloadTemplate()}>
                        {t("DownloadTemplate")}
                    </ButtonBlue>
                </Box>

                <StyledDropzone>
                    <div {...getRootProps({ className: "dropzone-upload-file" })}>
                        <input {...getInputProps()} />
                        <div className="inner-dropzone">
                            {file ? (
                                <Stack
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    spacing={1}
                                >
                                    <InsertDriveFileIcon sx={{ width: 80, height: 80, color: "#24a700" }} />
                                    <Typography variant="body2">
                                        {file.name}
                                    </Typography>
                                    <IconButton
                                        type="button"
                                        color="error"
                                        onClick={handleRemoveFile}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Stack>
                            ) : (
                                <>
                                    <img alt="AddIcon" src={AddIcon} width="80" />
                                    <Typography
                                        style={{
                                            marginTop: 8,
                                            backgroundColor: "transparent",
                                        }}
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        {t("Drag 'n' drop a file here, or click to select one")}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {`(.xlsx only)`}
                                    </Typography>
                                </>
                            )}
                        </div>
                    </div>
                </StyledDropzone>

                <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <ButtonBlue onClick={() => handleClose(false)}>
                        {t("Cancel")}
                    </ButtonBlue>
                    <ButtonBlue onClick={() => handleSaveGpsLocations()}>
                        {t("Save")}
                    </ButtonBlue>
                </Box>
                <AlertResponse
                    open={openAlert}
                    handleClose={handleCloseAlert}
                    alertType={alertType}
                />
            </StyledRoot>
        </DrawerCustom>
    );
};
