import { httpClient } from "./httpClient";

const getAllJobStructure = () => {
  return httpClient.get("jobStructures");
};

const postJobStructure = (data) => {
  return httpClient.post(`jobStructures/postJobStructure`, data);
};

const putJobStructure = (id, data) => {
  return httpClient.post(`jobStructures/putJobStructure/${id}`, data);
};

const deleteJobStructure = (id) => {
  return httpClient.post(`jobStructures/deleteJobStructure/${id}`);
};

export default {
  getAllJobStructure,
  postJobStructure,
  putJobStructure,
  deleteJobStructure,
};
