import { PDFDocument } from 'pdf-lib'
import fontkit from "@pdf-lib/fontkit";
import dayjs from 'dayjs';
import utils from "../../../../../utils";

const calculatorESY = (employeeProfile) => {
    let Serv = utils.getAgeNumber(
      dayjs(employeeProfile.hiringDate).format("DD/MM/YYYY")
    );

    let monthAge = (Serv.months + employeeProfile.OESY_M) % 12;
    let yearAge = Math.floor((Serv.months + employeeProfile.OESY_M) / 12);

    return `${Serv.years + employeeProfile.OESY_Y + yearAge}/${monthAge}`;
  };

const calculatorServiceYear = (employeeProfile) => {
    let Serv = utils.getAgeNumber(
      dayjs(employeeProfile.hiringDate).format("DD/MM/YYYY")
    );

    let monthAge = (Serv.months) % 12;
    let yearAge = Math.floor((Serv.months) / 12);

    return `${Serv.years}/${monthAge}`;
  };

export async function Pdf(data, type) {
    try {
        const url = `${process.env.REACT_APP_API_URL}files/resume_template_${type}.pdf`;
        
        const existingPdfBytes = await fetch(url).then((res) =>
            res.arrayBuffer()
        );
        const pdfDoc = await PDFDocument.load(existingPdfBytes);


        const urlFont = `${process.env.REACT_APP_API_URL}fonts/${"THSarabunNew"}.ttf`;

        const fontBytes = await fetch(urlFont, { encoding: "utf-8" }).then((res) => res.arrayBuffer());
        pdfDoc.registerFontkit(fontkit);
        const font = await pdfDoc.embedFont(fontBytes, { subset: true });

        const pages = pdfDoc.getPages()
        const firstPage = pages[0]
        const secondPage = pages[1]
        const thirdPage = pages[2]

        //console.log("data: ", data)
        //console.log("Age: ", utils.getAge(dayjs(data.birthday).format("DD/MM/YYYY")))

        //image
        if (data.image) {
            const jpgImage = await pdfDoc.embedJpg(data.image);
            firstPage.drawImage(jpgImage, {
                x: 22,
                y: 609,
                width: 224,
                height: 209,
            });
        }

        //left
        firstPage.drawText((`อายุ ${utils.getAge(dayjs(data.birthday).format("DD/MM/YYYY"), "th")}`), {
            x: 48,
            y: 545,
            size: 16,
            font: font
        })
        firstPage.drawText(("สัญชาติ : " + (data.nationality ? data.nationality : "-")), {
            x: 48,
            y: 525,
            size: 16,
            font: font
        })
        firstPage.drawText(("เลขบัตรประชาชน : " + (data.personalID ? data.personalID : "-")), {
            x: 48,
            y: 505,
            size: 16,
            font: font
        })
        firstPage.drawText(("เลขใบอนุญาตทำงาน : " + (data.workPermitNumber ? data.workPermitNumber : "-")), {
            x: 48,
            y: 485,
            size: 16,
            font: font
        })
        firstPage.drawText(("เบอร์โทร : " + (data.telephoneMobile ? data.telephoneMobile : "-")), {
            x: 48,
            y: 465,
            size: 16,
            font: font
        })
        firstPage.drawText((data.email ? data.email : "-"), {
            x: 48,
            y: 445,
            size: 15,
            font: font
        })
        let pointy = 385
        if (data.houseNo || data.village) {
            firstPage.drawText(((data.houseNo ? (data.houseNo + " ") : "") + (data.village ? ("หมู่บ้าน " + data.village + " ") : "")), {
                x: 48,
                y: pointy -= 20,
                size: 16,
                font: font
            })
        }
        if (data.villageNo || data.alley) {
            firstPage.drawText(((data.villageNo ? ("หมู่ที่ " + data.villageNo) + " " : "") + (data.alley ? ("ตรอก/ซอย " + data.alley) : "")), {
                x: 48,
                y: pointy -= 20,
                size: 16,
                font: font
            })
        }
        if (data.road) {
            firstPage.drawText(("ถนน " + data.road), {
                x: 48,
                y: pointy -= 20,
                size: 16,
                font: font
            })
        }
        if (data.subDistrict) {
            firstPage.drawText(("ตำบล " + data.subDistrict), {
                x: 48,
                y: pointy -= 20,
                size: 16,
                font: font
            })
        }
        if (data.district) {
            firstPage.drawText(("เขต/อำเภอ " + data.district), {
                x: 48,
                y: pointy -= 20,
                size: 16,
                font: font
            })
        }
        if (data.provience || data.areaCode) {
            firstPage.drawText(((data.provience ? (data.provience + " ") : "") + (data.areaCode ? data.areaCode : "")), {
                x: 48,
                y: pointy -= 20,
                size: 16,
                font: font
            })
        }

        //right pt1 -----------------------------------------------------------------------
        firstPage.drawText(((data.title_TH ? data.title_TH : "")
            + (data.firstname_TH ? " " + data.firstname_TH : "")
            + (data.lastname_TH ? "  " + data.lastname_TH : "")
            + (data.nickname_TH ? " (" + data.nickname_TH + ")" : "")), {
            x: 257,
            y: 785,
            size: 26,
            font: font
        })
        firstPage.drawText((data.positionName ? data.positionName : ""), {
            x: 257,
            y: 760,
            size: 20,
            font: font
        })
        firstPage.drawText(("รหัสพนักงาน: " + data.employeeID), {
            x: 257,
            y: 735,
            size: 20,
            font: font
        })

        firstPage.drawText(("วันที่เริ่มงาน : " + (data.hiringDate ? dayjs(data.hiringDate).format("DD/MM/YYYY") : "-")), {
            x: 257,
            y: 700,
            size: 16,
            font: font
        })
        firstPage.drawText(("ประเภทสัญญา : " + (data.contractType ? data.contractType : "-")), {
            x: 257,
            y: 680,
            size: 16,
            font: font
        })
        let oesyY = 0
        let oesyM = 0
        let servY = 0
        let seryM = 0
        if (data.OESY_M) oesyM = data.OESY_M
        if (data.OESY_Y) oesyY = data.OESY_Y
        if (data.Serv_Y) servY = data.Serv_Y
        if (data.Serv_M) seryM = data.Serv_M
        firstPage.drawText(("อายุงานรวม(Y/M) : " + calculatorESY(data)), {
            x: 257,
            y: 660,
            size: 16,
            font: font
        })

        let y = 660

        if (data.managerLV1_firstname_TH) firstPage.drawText(("ผู้จัดการ1 : " + data.managerLV1_firstname_TH + " " + data.managerLV1_lastname_TH), {
            x: 257,
            y: y -= 20,
            size: 16,
            font: font
        })

        if (data.managerLV2_firstname_TH) firstPage.drawText(("ผู้จัดการ2 : " + data.managerLV2_firstname_TH + " " + data.managerLV2_lastname_TH), {
            x: 257,
            y: y -= 20,
            size: 16,
            font: font
        })

        y = 660

        if (data.employmentTypeName && data.employmentTypeName.length > 17) {
            firstPage.drawText((data.employmentTypeName.slice(17)), {
                x: 400,
                y: y += 20,
                size: 16,
                font: font
            })
        }
        firstPage.drawText(("ประเภทพนักงาน : " + (data.employmentTypeName ? data.employmentTypeName.slice(0, 17) : "-")), {
            x: 400,
            y: y += 20,
            size: 16,
            font: font
        })
        firstPage.drawText(("วันที่เกษียณอายุ : " + (data.retirementDate ? dayjs(data.retirementDate).format("DD/MM/YYYY") : "-")), {
            x: 400,
            y: y += 20,
            size: 16,
            font: font
        })
        firstPage.drawText(("อายุงานภายใน(Y/M) : " + calculatorServiceYear(data)), {
            x: 400,
            y: 660,
            size: 16,
            font: font
        })

        //right pt2 -----------------------------------------------------------------------
        firstPage.drawText(("Company : " + (data.companyName ? data.companyName : "-")), {
            x: 280,
            y: 545,
            size: 16,
            font: font
        })
        firstPage.drawText(("BU : " + (data.businessUnitName ? data.businessUnitName : "-")), {
            x: 280,
            y: 525,
            size: 16,
            font: font
        })
        firstPage.drawText(("Division : " + (data.divisionName ? data.divisionName : "-")), {
            x: 280,
            y: 505,
            size: 16,
            font: font
        })
        firstPage.drawText(("Department : " + (data.departmentName ? data.departmentName : "-")), {
            x: 280,
            y: 485,
            size: 16,
            font: font
        })
        firstPage.drawText(("Section : " + (data.sectionName ? data.sectionName : "-")), {
            x: 280,
            y: 465,
            size: 16,
            font: font
        })
        firstPage.drawText(("Unit / Process : -"), {
            x: 280,
            y: 445,
            size: 16,
            font: font
        })

        //right pt3 -----------------------------------------------------------------------
        firstPage.drawText(("กลุ่มงาน : " + (data.jobGroupName ? data.jobGroupName : "-")), {
            x: 280,
            y: 365,
            size: 16,
            font: font
        })
        firstPage.drawText(("ตำแหน่งงาน : " + (data.positionName ? data.positionName : "-")), {
            x: 280,
            y: 345,
            size: 16,
            font: font
        })
        firstPage.drawText(("ระดับ(งาน) : " + (data.jobLevelName ? data.jobLevelName : "-")), {
            x: 280,
            y: 325,
            size: 16,
            font: font
        })
        firstPage.drawText(("ระดับ : " + (data.personnelLevelName ? data.personnelLevelName : "-")), {
            x: 280,
            y: 305,
            size: 16,
            font: font
        })
        firstPage.drawText(("เวลาที่อยู่ในระดับ : -"), {
            x: 280,
            y: 285,
            size: 16,
            font: font
        })
        if (type === "full") {
            firstPage.drawText(("เงินเดือน : " + (data.salary ? utils.numberWithCommas(data.salary) : "-")), {
                x: 280,
                y: 265,
                size: 16,
                font: font
            })
        }
        //right pt4 -----------------------------------------------------------------------
        pointy = 230
        if (data.educations) {
            let length = data.educations.length > 3 ? 3 : data.educations.length
            for (let i = 0; i < length; i++) {
                let item = data.educations[i]
                pointy -= 10
                if (item.fromYear) (firstPage.drawText((item.fromYear + (item.endYear ? (" - " + item.endYear) : "")), {
                    x: 280,
                    y: pointy -= 18,
                    size: 16,
                    font: font
                }))
                if (item.faculty) (firstPage.drawText((item.faculty + (item.major ? (" | " + item.major) : "")), {
                    x: 280,
                    y: pointy -= 18,
                    size: 16,
                    font: font
                }))
                if (item.university) (firstPage.drawText((item.university + (item.degree ? (" | " + item.degree) : "")), {
                    x: 280,
                    y: pointy -= 18,
                    size: 16,
                    font: font
                }))
            }
        }
        //-------------------------------------------------------------------------------------------------------------
        //---------------------------------------------------page2-----------------------------------------------------
        //-------------------------------------------------------------------------------------------------------------

        //ประสบการณ์ภายนอก
        pointy = 750
        if (data.experience) {
            let length = data.experience.length - 1
            let limit = length - 2 > -1 ? length - 2 : -1
            for (let i = length; i > limit; i--) {
                let item = data.experience[i]
                pointy -= 8
                if (item.startDate) (secondPage.drawText((dayjs(item.startDate).format("DD/MM/YYYY") + " - " +
                    (item.endDate ? (dayjs(item.endDate).format("DD/MM/YYYY")) : "ปัจจุบัน")), {
                    x: 53,
                    y: pointy -= 18,
                    size: 16,
                    font: font
                }))
                if (item.positionName) (secondPage.drawText((item.positionName + (item.companyName ? (" | " + item.companyName) : "")) , {
                    x: 53,
                    y: pointy -= 18,
                    size: 16,
                    font: font
                }))
                if (item.description) (secondPage.drawText((item.description), {
                    x: 53,
                    y: pointy -= 18,
                    size: 16,
                    font: font
                }))
            }
        }
        
        //การโยกย้ายภายในองค์กร
        pointy = 570
        if (data.position) {
            let length = data.position.length - 1
            let limit = length - 5 > -1 ? length - 5 : -1
            if (type === "full") limit = length - 3 > -1 ? length - 3 : -1
            for (let i = length; i > limit; i--) {
                let item = data.position[i]
                pointy -= 8
                if (item.start) (secondPage.drawText((dayjs(item.start).format("DD/MM/YYYY") + " - " +
                    (item.end ? (dayjs(item.end).format("DD/MM/YYYY")) : "ปัจจุบัน")), {
                    x: 53,
                    y: pointy -= 18,
                    size: 16,
                    font: font
                }))
                if (item.positionName) (secondPage.drawText((item.positionName), {
                    x: 53,
                    y: pointy -= 18,
                    size: 16,
                    font: font
                }))
                if (item.companyName || item.sectionName || item.divisionName) (secondPage.drawText(((item.companyName ? (item.companyName) : "")
                    + (item.sectionName ? (" | " + item.sectionName) : "") + (item.divisionName ? (" | " + item.divisionName) : "")), {
                    x: 53,
                    y: pointy -= 18,
                    size: 16,
                    font: font
                }))
            }
        }
        //ผลงาน ศักยภาพ
        let pointx = 101
        if (data.performance && type === "full") {
            let length = data.performance.length > 6 ? 6 : data.performance.length
            for (let i = 0; i < length; i++) {
                let item = data.performance[i]
                pointx += 65
                if (item.yearPerformance) (secondPage.drawText((item.yearPerformance.toString()), {
                    x: pointx,
                    y: 300,
                    size: 16,
                    font: font
                }))
                if (item.name) (secondPage.drawText((item.name.toString()), {
                    x: pointx + 9,
                    y: 270,
                    size: 16,
                    font: font
                }))
            }
        }
        //-------------------------------------------------------------------------------------------------------------
        //---------------------------------------------------page3-----------------------------------------------------
        //-------------------------------------------------------------------------------------------------------------
        pointy = 475
        if (data.salarys && type === "full" && data.salarys.length > 1) {
            let length = data.salarys.length - 1
            for (let i = length; i > length - 2; i--) {
                let item = data.salarys[i]
                if (item.old) {
                    pointy -= 33
                    if (item.start) (thirdPage.drawText((dayjs(item.start).format("DD/MM/YYYY")), {
                        x: 83,
                        y: pointy,
                        size: 16,
                        font: font
                    }))
                    if (item.remark) (thirdPage.drawText((item.remark), {
                        x: 165,
                        y: pointy,
                        size: 16,
                        font: font
                    }))
                    if (item.old) (thirdPage.drawText((item.old.toString()), {
                        x: 282,
                        y: pointy,
                        size: 16,
                        font: font
                    }))
                    if (item.money) (thirdPage.drawText((item.money.toString()), {
                        x: 377,
                        y: pointy,
                        size: 16,
                        font: font
                    }))
                    if (item.salaryValue) (thirdPage.drawText((item.salaryValue.toString()), {
                        x: 470,
                        y: pointy,
                        size: 16,
                        font: font
                    }))
                }
            }
        }
        
        const pdfBytes = await pdfDoc.save();

        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

        // Create a download link and trigger the download
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(pdfBlob);
        downloadLink.download = `Onepage resume ${data.employeeID}_${data.firstname_EN} ${data.lastname_EN}.pdf`;
        downloadLink.click();

    } catch (error) {
        console.error('Error creating PDF:', error);
    }
}

