import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Notification from "../../../shared/general/Notification";
import ConfirmDialog from "../../../shared/general/ConfirmDialog";
import Pagination from "../../../shared/general/Pagination";
import NumberFormat from "react-number-format";
import EmployeeInfo from "../../../shared/pages/okrs/components/EmployeeInfo";
import { Data, Impact } from "../mockData";
import { styled } from "@mui/styles";

import {
  Button,
  Grid,
  Toolbar,
  TextField,
  InputAdornment,
  Typography,
  LinearProgress,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import CommentIcon from "@mui/icons-material/Comment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import BallotIcon from "@mui/icons-material/Ballot";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { useSelector, useDispatch } from "react-redux";
import { getUserProfile } from "../../../../../actions/user";
import { getAllImpact } from "../../../../../actions/Impacts";

const useStyles = makeStyles(() => ({
  root: {
    color: "#222f3e",
    "& MuiToolbar-root": {
      marginBottom: 40,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
    "& .MuiFormControl-marginNormal": {
      marginTop: 8,
    },
    // '& .MuiAccordion-root:before': {
    //   backgroundColor: 'transparent',
    // },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiAccordion-rounded": {
      borderRadius: "10px",
      marginTop: 16,
      marginBottom: 16,
      paddingTop: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingBottom: 8,
    },
    "& .MuiTypography-h6": {
      color: "#222f3e",
    },
    "& .MuiGrid-container": {
      marginBottom: "0px !important",
    },
    "& .MuiButtonBase-root": {
      width: "100%",
    },
  },
  newBtn: {
    color: "#4f65df",
    paddingLeft: 32,
    paddingRight: 32,
    border: "2px solid #4f65df",
    borderRadius: 16,
    height: 48,
    
  },
  cardList: {
    marginTop: 24,
    borderRadius: 24,
    padding: 24,
    minHeight: "100px",
    backgroundColor: "#fff",
  },
  cardList_Icon: {
    width: 40,
    height: 40,
    backgroundColor: "#fff3e0",
    color: "#ff9800",
    marginRight: 24,
  },
  cardListOther_Icon: {
    width: 40,
    height: 40,
    backgroundColor: "#f1f8e9",
    color: "#8bc34a",
    marginRight: 24,
  },
  cardList_Delete: {
    width: 40,
    height: 40,
    backgroundColor: "#ffebee",
    color: "#ef5350",
    marginRight: 16,
  },
  expandIcon: {
    border: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: "50%",
    fontSize: "25px",
  },
  cardList_Svg: {
    fontSize: "1.8rem",
  },
  moreButton: {
    borderRadius: "50px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
  deleteBtn: {
    color: "#e53935 !important",
    backgroundColor: "#ffebee !important",
    border: "1px solid #ef9a9a",
    borderRadius: "50px",
    "& :hover": {
      color: "#e53935 !important",
      backgroundColor: "#ffebee !important",
    },
  },
  noData_Section: {
    display: "flex",
    alignItems: "center",
  },
  noDataSvg: {
    fontSize: "30px",
    marginRight: 8,
  },
  hrTag: {
    width: "100%",
    border: "1px solid #eeeeee",
    marginTop: 32,
    marginBottom: 32,
  },
  heading: {
    fontWeight: "700",
    paddingLeft: 64,
    marginBottom: 16,
  
  },
  impactDetails: {
    paddingLeft: 64,
    paddingBottom: 16,
   
  },
  fontBold: {
    fontWeight: 700,
  },
  attachFileImages: {
    cursor: "pointer",
    width: "200px",
    borderRadius: "5px",
    marginRight: 32,
    marginBottom: 16,
  },
  attachFilePdf: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fafafa",
    border: "1px solid #eeeeee",
    width: "200px",
    height: "150px",
    borderRadius: "5px",
    marginRight: 32,
    marginBottom: 16,
  },
  pdfSvg: {
    fontSize: "3.5rem",
    color: "#222f3e",
  },
  costSvg: {
    position: "relative",
    top: "5px",
    marginRight: 8,
  },
}));

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

function SuccessProfileList() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: impactStore } = useSelector((state) => state.Impacts);

  const [impactList, setImpactList] = useState([]);
  const [impactItems, setImpactItems] = useState([]);
  const [year, setYear] = useState(new Date());
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [employeeId, setEmployeeId] = useState(0);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState([]);

  const openImageViewer = useCallback((src) => {
    const images = [];
    images.push(src);
    setImageSrc(images);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = impactList.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const fetchData = () => {
    dispatch(getAllImpact());
  };

  useEffect(() => {
    if (impactStore) {
      console.log("impactStore", impactStore);
      console.log("userProfile.idEmployees", userProfile.idEmployees);

      const record = impactStore.filter(function (item) {
        const dateItem = new Date(item.dateCreate);
        const yearItem = dateItem.getFullYear();
        return yearItem === year.getFullYear();
      });

      //Combine string of impactJobs and impactOther use to search
      record.forEach(function (element) {
        let key = "";
        element.impactJobs.forEach(function (job) {
          key = key + job.impactName + " | ";
        });

        element.impactOthers.forEach(function (other) {
          key = key + other.impactOtherName + " | ";
        });

        element.keySearch = key;
      });

      setImpactList(record);
      setImpactItems(record);
      setIsLoading(false);
    }
  }, [impactStore]);

  useEffect(() => {
    fetchData();
  }, [year]);

  useEffect(() => {
    if (userProfile) {
      setEmployeeId(userProfile.idEmployees);
    }
  }, [userProfile]);

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserProfile(currentUser.username));
    }

    fetchData();
  }, []);

  useEffect(() => {
    let x = [...impactItems];
    x = x.filter((y) => {
      return y.keySearch.toLowerCase().includes(searchKey.toLocaleLowerCase());
    });
    setImpactList(x);
  }, [searchKey]);

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    fetchData();
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "success",
    });
  };

  return (
    <ContainerStyled>
      <div className={classes.root}>
        <div className="card-section">
          <div className="header-section">
            <Grid container>
              <Grid item>
                <h3 className="header-topic">OKR Success Profile</h3>
              </Grid>
            </Grid>
          </div>
          {/* employee */}
          {employeeId && <EmployeeInfo idEmployees={employeeId} />}
          <Toolbar>
            <Grid container justifyContent="space-between">
              <Grid container alignItems="center" item xs={12} md={5} lg={5}>
                <Button
                  variant="outlined"
                  className={classes.newBtn}
                  onClick={() => history.push("/successprofile")}
                >
                  Add New Impact
                </Button>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                item
                xs={12}
                md={7}
                lg={7}
                spacing={2}
              >
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      margin="normal"
                      variant="inline"
                      style={{ width: "150px", marginRight: "10px" }}
                      inputVariant="outlined"
                      label="Year"
                      value={year}
                      views={["year"]}
                      onChange={(date) => setYear(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <TextField
                    variant="outlined"
                    label="Search Impact"
                    style={{ width: "300px" }}
                    value={searchKey}
                    onChange={(e) => setSearchKey(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </div>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <>
            {/* impactList */}
            {(Impact.length > 0 &&
              // currentPosts
              Impact.map((item, index) => (
                <div key={item.impactID}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="flex-end"
                  >
                    <Grid item xl={11} lg={11} md={11} sm={10} xs={10}>
                      <Typography
                        variant="h6"
                        style={{ marginTop: "40px", display: "flex" }}
                        className="keyResult"
                      >
                        <BookmarkOutlinedIcon
                          fontSize="large"
                          className="svgKey"
                        />
                        Impact To Job / To Team / To Company
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={2}
                      xs={2}
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        variant="outlined"
                        className={classes.deleteBtn}
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: "Are you sure you want to delete ?",
                            subTitle: "You can't undo this operation",
                            onConfirm: () => {
                              onDelete(item.impactID);
                            },
                          });
                        }}
                      >
                        <DeleteForeverIcon />
                      </Button>
                    </Grid>
                  </Grid>
                  {item.impactJobs.map((job) => (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon className={classes.expandIcon} />
                        }
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Grid container>
                          <Grid item>
                            <Avatar className={classes.cardList_Icon}>
                              <CommentIcon className={classes.cardList_Svg} />
                            </Avatar>
                          </Grid>
                          <Grid item>
                            <Typography variant="h6">
                              {job.impactName}
                            </Typography>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid
                          container
                          alignItems="center"
                          className={classes.impactDetails}
                        >
                          <Grid item xs={12} sm={6} md={3} lg={3}>
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              display="inline"
                              className={classes.fontBold}
                              gutterBottom
                            >
                              <BallotIcon className={classes.costSvg} />
                              Value :{" "}
                            </Typography>
                            <Typography
                              display="inline"
                              variant="body1"
                              color="textSecondary"
                              gutterBottom
                            >
                              {job.selectValue}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3} lg={3}>
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              display="inline"
                              className={classes.fontBold}
                              gutterBottom
                            >
                              <LibraryBooksIcon className={classes.costSvg} />
                              Type :{" "}
                            </Typography>
                            <Typography
                              display="inline"
                              variant="body1"
                              color="textSecondary"
                              gutterBottom
                            >
                              {job.returnType}
                            </Typography>
                          </Grid>
                          {job.selectValue === "Direct" ? (
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                              <Typography
                                variant="body1"
                                display="inline"
                                className={classes.fontBold}
                                color="textSecondary"
                                gutterBottom
                              >
                                <AccountBalanceWalletIcon
                                  className={classes.costSvg}
                                />
                                Cost :{" "}
                              </Typography>
                              <Typography
                                display="inline"
                                variant="body1"
                                color="textSecondary"
                                gutterBottom
                              >
                                <NumberFormat
                                  value={job.impactCost}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />{" "}
                                THB
                              </Typography>
                            </Grid>
                          ) : null}
                        </Grid>
                        <Grid container className={classes.impactDetails}>
                          <Grid item xs={10}>
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              gutterBottom
                            >
                              {job.description}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container className={classes.impactDetails}>
                          <Grid item xs>
                            {job.imagePath1 ||
                            job.imagePath2 ||
                            job.pdfPath1 ||
                            job.pdfPath2 ? (
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                gutterBottom
                                className={classes.fontBold}
                              >
                                Attach Files
                              </Typography>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid container className={classes.impactDetails}>
                          <Grid item>
                            {job.imagePath1 ? (
                              <img
                                src={`/upload/images/${job.imagePath1}`}
                                className={classes.attachFileImages}
                                onClick={() =>
                                  openImageViewer(
                                    `/upload/images/${job.imagePath1}`
                                  )
                                }
                              />
                            ) : null}
                          </Grid>
                          <Grid item>
                            {job.imagePath2 ? (
                              <img
                                src={`/upload/images/${job.imagePath2}`}
                                className={classes.attachFileImages}
                                onClick={() =>
                                  openImageViewer(
                                    `/upload/images/${job.imagePath2}`
                                  )
                                }
                              />
                            ) : null}
                          </Grid>
                          <Grid item>
                            {job.pdfPath1 ? (
                              <a
                                href={`/upload/pdf/${job.pdfPath1}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div className={classes.attachFilePdf}>
                                  <i
                                    className={classes.pdfSvg}
                                    class="fa-light fa-file-pdf"
                                  ></i>
                                </div>
                              </a>
                            ) : null}
                          </Grid>
                          <Grid item>
                            {job.pdfPath2 ? (
                              <a
                                href={`/upload/pdf/${job.pdfPath2}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div className={classes.attachFilePdf}>
                                  <i
                                    className={classes.pdfSvg}
                                    class="fa-light fa-file-pdf"
                                  ></i>
                                </div>
                              </a>
                            ) : null}
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                  <Typography variant="h6" className="keyResult">
                    <BookmarkOutlinedIcon fontSize="large" className="svgKey" />
                    Impact To Other
                  </Typography>
                  {item.impactOthers.map((other) => (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon className={classes.expandIcon} />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Grid container alignItems="center">
                          <Grid item>
                            <Avatar className={classes.cardListOther_Icon}>
                              <QuestionAnswerIcon
                                className={classes.cardList_Svg}
                              />
                            </Avatar>
                          </Grid>
                          <Grid item>
                            <Typography variant="h6">
                              {other.impactOtherName}
                            </Typography>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid
                          container
                          alignItems="center"
                          className={classes.impactDetails}
                        >
                          <Grid item>
                            <Typography
                              display="inline"
                              className="body1"
                              color="textSecondary"
                              gutterBottom
                            >
                              <ArrowRightIcon className={classes.costSvg} />
                              Impact To :{" "}
                            </Typography>
                            <Typography
                              display="inline"
                              variant="body1"
                              color="textSecondary"
                              gutterBottom
                            >
                              {other.impactTo}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container className={classes.impactDetails}>
                          <Grid item>
                            <Typography
                              className="body1"
                              color="textSecondary"
                              gutterBottom
                            >
                              {other.descriptionOther}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container className={classes.impactDetails}>
                          <Grid item>
                            {other.imagePath1 ||
                            other.imagePath2 ||
                            other.pdfPath1 ||
                            other.pdfPath2 ? (
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                gutterBottom
                                className={classes.fontBold}
                              >
                                Attach Files
                              </Typography>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid container className={classes.impactDetails}>
                          <Grid item>
                            {other.imagePath1 ? (
                              <img
                                src={`/upload/images/${other.imagePath1}`}
                                className={classes.attachFileImages}
                                onClick={() =>
                                  openImageViewer(
                                    `/upload/images/${other.imagePath1}`
                                  )
                                }
                              />
                            ) : null}
                          </Grid>
                          <Grid item>
                            {other.imagePath2 ? (
                              <img
                                src={`/upload/images/${other.imagePath2}`}
                                className={classes.attachFileImages}
                                onClick={() =>
                                  openImageViewer(
                                    `/upload/images/${other.imagePath2}`
                                  )
                                }
                              />
                            ) : null}
                          </Grid>
                          <Grid item>
                            {other.pdfPath1 ? (
                              <a
                                href={`/upload/pdf/${other.pdfPath1}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div className={classes.attachFilePdf}>
                                <i className={classes.pdfSvg} class="fa-light fa-file-pdf"></i>
                              
                                </div>
                              </a>
                            ) : null}
                          </Grid>
                          <Grid item>
                            {other.pdfPath2 ? (
                              <a
                                href={`/upload/pdf/${other.pdfPath2}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div className={classes.attachFilePdf}>
                                <i className={classes.pdfSvg} class="fa-light fa-file-pdf"></i>
                            
                                </div>
                              </a>
                            ) : null}
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              ))) || (
              <div className={classes.cardList}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Typography variant="h6" className={classes.noData_Section}>
                      <FindInPageIcon className={classes.noDataSvg} />
                      No Data
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            )}
          </>
        )}
        {/* impactList */}
        {Impact.length > 0 ? (
          <Pagination
            paginate={paginate}
            postsPerPage={postsPerPage}
            totalPosts={impactList.length}
          />
        ) : null}
        <Notification notify={notify} setNotify={setNotify} />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        {/* {isViewerOpen && (
          <ImageViewer
            src={imageSrc}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
            }}
            closeOnClickOutside={true}
          />
        )} */}
      </div>
    </ContainerStyled>
  );
}

export default SuccessProfileList;
