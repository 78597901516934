import React, { Fragment, useEffect } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Divider,
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
  Stack,
  Card,
  CardContent,
  CardHeader,
  Chip,
} from "@mui/material";
import { getProbationEmployeesByID } from "../../../../../../actions/employee";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import CardStyle from "../../../../shared/general/Card";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import {
  getUserFullName,
  getUserPosition,
} from "../../../../../../utils/userData";

const StatusShow = ({ result }) => {
  const { t, i18n } = useTranslation();
  if (result === 1) {
    return <Chip label={t("Pass")} color={"success"} />;
  } else if (result === 0) {
    return <Chip label={t("NotPass")} color={"error"} />;
  } else {
    return <Chip label={t("AnErrorOccurred")} color={"warning"} />;
  }
};

const ProbationUser = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const thaiLanguage = i18n.resolvedLanguage === "th";
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProbationId } = useSelector(
    (state) => state.employeeProbationId
  );
  useEffect(() => {
    if (userProfile) {
      dispatch(getProbationEmployeesByID(userProfile.idEmployees));
    }
  }, []);

  return (
    <>
      {employeeProbationId && employeeProbationId.probation.length > 0 ? (
        employeeProbationId.probation.map((item) => (
          <CardStyle style={{ marginBottom: 32 }}>
            <CardHeader
              title={
                <Typography variant="h6">
                  {`${t("AssessmentFormforTrialPeriod")} ${
                    item.probationRound
                  }`}
                </Typography>
              }
            />
            <CardContent>
              <Grid container padding={2} spacing={2}>
                <Grid item xs={12} md={7}>
                  <Typography sx={{ display: "inline-block" }}>
                    <i className="fa-regular fa-user fa-xl"></i>
                    &nbsp;&nbsp;
                  </Typography>
                  <Typography sx={{ display: "inline-block" }}>
                    {`${t("PersonBeingAssessed")} : ${
                      thaiLanguage ? item.approvedBy : item.approvedBy_EN
                    }`}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Typography sx={{ display: "inline-block" }}>
                    <i className="fa-regular fa-whistle fa-xl"></i>
                    &nbsp;&nbsp;
                  </Typography>
                  <Typography sx={{ display: "inline-block" }}>
                    {`${t("EvaluatedBy")} : ${
                      thaiLanguage ? item.approvedBy : item.approvedBy_EN
                    }`}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Typography sx={{ display: "inline-block" }}>
                    <i className="fa-regular fa-calendar-days fa-xl"></i>
                    &nbsp;&nbsp;
                  </Typography>
                  <Typography sx={{ display: "inline-block" }}>
                    {`${t("ProbationaryExpirationDate")} : ${dayjs(
                      item.probationDate
                    ).format(thaiLanguage ? "D MMM BBBB" : "D MMM YYYY")}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Typography sx={{ display: "inline-block" }}>
                    <i class="far fa-poll-h fa-xl"></i>
                    &nbsp;&nbsp;
                  </Typography>
                  <Typography sx={{ display: "inline-block" }}>
                    {`${t("ProbationResult")}: `}{" "}
                    <StatusShow result={item.result} />
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ marginTop: "16px", borderColor: "#9e9e9e66" }} />
              <Stack padding={2} spacing={2}>
                {item.question &&
                  item.question.map((data, index) => (
                    <Fragment key={data.id}>
                      <Stack direction={"row"} gap={1} alignItems={"center"}>
                        <i className="fa-regular fa-square-question fa-xl"></i>
                        <Typography>{`${t("Question")} ${index + 1} : ${
                          data.question
                        }`}</Typography>
                      </Stack>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        value={data.answer}
                      >
                        <FormControlLabel
                          value="verygood"
                          control={<Radio />}
                          label={`${t("Excellence")}`}
                        />
                        <FormControlLabel
                          value="good"
                          control={<Radio />}
                          label={`${t("Good")}`}
                        />
                        <FormControlLabel
                          value="meduim"
                          control={<Radio />}
                          label={`${t("Neutral")}`}
                        />
                        <FormControlLabel
                          value="fair"
                          control={<Radio />}
                          label={`${t("Fair")}`}
                        />
                        <FormControlLabel
                          value="prettybad"
                          control={<Radio />}
                          label={`${t("Poor")}`}
                        />
                        <FormControlLabel
                          value="bad"
                          control={<Radio />}
                          label={`${t("VeryPoor")}`}
                        />
                      </RadioGroup>
                      <Divider sx={{ borderColor: "#9e9e9e66" }} />
                    </Fragment>
                  ))}
              </Stack>
              <Grid container padding={2} spacing={2}>
                <Grid item xs={12}>
                  <i className="fa-regular fa-bone fa-xl"></i>
                  &nbsp;&nbsp;{`${t("Strength")}`}
                </Grid>
                <Grid item xs={12}>
                  <TextFieldTheme
                    multiline
                    rows={3}
                    value={item.goodPoint}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <i className="fa-regular fa-bone-break fa-xl"></i>
                  &nbsp;&nbsp;{`${t("Weakness")}`}
                </Grid>
                <Grid item xs={12}>
                  <TextFieldTheme
                    multiline
                    rows={3}
                    value={item.weakPoint}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <i className="fa-regular fa-bone-break fa-xl"></i>
                  &nbsp;&nbsp;{`${t("Other")}`}
                </Grid>
                <Grid item xs={12}>
                  <TextFieldTheme
                    multiline
                    rows={2}
                    value={item.others}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </CardStyle>
        ))
      ) : (
        <Typography variant="h5">{t("NoData")}</Typography>
      )}
    </>
  );
};

export default ProbationUser;
