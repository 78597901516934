import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/styles";

import Menu from "./menu";
import Employees_list from "./emplyees_list";
import { Container } from "@mui/material";

import Unicorn from "../../assets/background/5160998.jpg";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const Root = styled("div")({
  padding: "100px 20px 20px",
  //width: "fit-content",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundImage: `url(${Unicorn})`,
  backgroundSize: "cover",
  boxSizing: "border-box",
  minHeight: "100vh",
});

const HomePage = (props) => {
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <Root className="page">
      <Container maxWidth="lg">
        {userProfile && (
          <Fragment>
            {userProfile.isFillIn !== 1 && <Employees_list />}
            <Menu />
          </Fragment>
        )}
      </Container>
    </Root>
  );
};

export default HomePage;
