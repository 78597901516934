import React, { useEffect, useState, useTransition } from "react";
import {
  Grid,
  Container,
  Paper,
  Button,
  Avatar,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  AlertTitle,
  Stack,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Breadcrumb } from "../../shared/breadcrums";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteProject, getAllProject, getProjectByEmp } from "../../../../actions/project";
import { getAllWork } from "../../../../actions/work";

import AssignmentIcon from "@mui/icons-material/Assignment";
import DeleteIcon from "@mui/icons-material/Delete";
import PageviewIcon from "@mui/icons-material/Pageview";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from "../../shared/general/Card";
import dayjs from "dayjs";
import AddProject from "./addProject";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "20px",
    minHeight: "calc(100vh - 20px)",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  icon: {
    marginBottom: 2,
  },
  headerText: {
    fontSize: "1.2rem",
    fontWeight: "600",
    color: "#007afc",
  },
  header: {
    fontSize: "1rem",
  },
  text: {
    fontSize: 16,
  },
  textSmall: {
    fontSize: 14,
    color: "#9e9e9e",
  },
  textNoData: {
    fontSize: 22,
    fontWeight: "600",
    textAlign: "center",
  },
  backgroundColorMD: {
    backgroundColor: "#43a047",
  },
  backgroundColorMG: {
    backgroundColor: "#e53935",
  },
  backgroundColorSM: {
    backgroundColor: "#039be5",
  },
}));

const ShowProject = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("Add");
  const [deleteId, setDeleteId] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const [dataProject, setDataProject] = useState(null);
  const [showAlertErr, setShowAlertErr] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedID(null);
    setTitle("");
  };

  useEffect(() => {
    const fetchedProject = async () => {
      try {
        const response = await getProjectByEmp();
        if (response && response.data) {
          setDataProject(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchedProject();
  }, []);

  const handleDeleteConfirmation = async () => {
    try {
      // Call the deleteProject function with the deleteId
      const response = await deleteProject(deleteId);
      if (response && response.status === 200) {
        setShowAlert(true);
        setDeleteDialogOpen(false);
        setDataProject(prevDataProject => prevDataProject.filter(project => project.idEmployeeProject !== deleteId));
      } else {
        setShowAlertErr(true);
      }
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <div className={`page ${classes.root}`}>
      <Stack justifyContent="center" alignItems="center" sx={{ p: 2 }}>
        {showAlert && (
          <Alert severity="success">
            <Stack justifyContent="space-between" alignItems="center" direction={"row"}>
              <Typography fontSize={16} fontWeight={500}>
                {t("DeletedSuccessfully")}
              </Typography>
              <IconButton onClick={() => {
                setShowAlert(false);
                window.location.reload();
              }}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </Alert>
        )}

        {showAlertErr && (
          <Alert severity="error">
            <Stack justifyContent="space-between" alignItems="center" direction={"row"}>
              <Typography fontSize={16} fontWeight={500} color={"error"}>
                {t("failedToDelete")}
              </Typography>
              <IconButton onClick={() => {
                setShowAlertErr(false);
                window.location.reload();
              }}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </Alert>
        )}
      </Stack>

      <Container maxWidth="lg">
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Grid item xl={8} lg={8} md={6} xs={12}>
            <Typography variant="h4" gutterBottom>
              {t("Project")}
            </Typography>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            xs={12}
            style={{ textAlign: "right" }}
          >
            <ButtonBlue
              variant="contained"
              size={"large"}
              startIcon={<AddIcon />}
              onClick={() => handleClickOpen()}
            >
              {t("AddNewProject")}
            </ButtonBlue>
          </Grid>
        </Grid>

        {dataProject && dataProject.length > 0 ? (
          dataProject
            .map((val, index) => (
              <div style={{ marginBottom: 16 }} key={index}>
                <CardStyle>
                  <div style={{ padding: 20 }}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        xs={12}
                        sm={2}
                        alignSelf="center"
                        style={{ textAlign: "-webkit-center" }}
                      >
                        <Avatar
                          style={{ width: 50, height: 50 }}
                          className={[
                            `${val.sizeMini == "MD" && classes.backgroundColorMD
                            }`,
                            `${val.sizeMini == "MG" && classes.backgroundColorMG
                            }`,
                            `${val.sizeMini == "SM" && classes.backgroundColorSM
                            }`,
                          ].join(" ")}
                        >
                          {val.sizeMini}
                        </Avatar>
                      </Grid>
                      <Grid item xl={5} lg={5} md={5} xs={12} sm={10}>
                        <Typography className={classes.headerText}>
                          {val.ProjectName}
                        </Typography>
                        <Typography className={classes.text}>
                          {val.size}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography className={classes.textSmall}>
                          {t("return")} (MB)
                        </Typography>
                        <Typography className={classes.header}>
                          {val.return}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography className={classes.textSmall}>
                          {t("StartDate")}
                        </Typography>
                        <Typography className={classes.header}>
                          {dayjs(val.dateStart).format("DD/MM/YYYY")}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography className={classes.textSmall}>
                          {t("EndDate")}
                        </Typography>
                        <Typography className={classes.header}>
                          {dayjs(val.dateEnd).format("DD/MM/YYYY")}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography className={classes.textSmall}>
                          {t("Role")}
                        </Typography>
                        <Typography className={classes.header}>
                          {val.role}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={2}
                        lg={2}
                        style={{ textAlign: "end" }}
                      >
                        <Link
                          to={`/project/showDetail/${val.idEmployeeProject}`}
                          style={{ textDecoration: "none" }}
                        >
                          <IconButton sx={{ color: "success.dark" }}>
                            <PageviewIcon style={{ fontSize: 28 }} />
                          </IconButton>
                        </Link>
                        <IconButton
                          sx={{ color: "primary.main" }}
                          onClick={() => {
                            setTitle("update");
                            setSelectedID(val.idEmployeeProject);
                            setOpen(true);
                          }}
                        >
                          <EditIcon style={{ fontSize: 28 }} />
                        </IconButton>
                        <IconButton
                          style={{ color: "red" }}
                          onClick={() => {
                            setDeleteId(val.idEmployeeProject);
                            setDeleteDialogOpen(true);
                          }}
                        >
                          <DeleteIcon style={{ fontSize: 28 }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                </CardStyle>
              </div>
            ))
        ) : (
          <Paper
            elevation={2}
            style={{ padding: 20, margin: 20, textAlign: "center" }}
          >
            <Typography className={classes.header}>No data to show</Typography>
          </Paper>
        )}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <AddProject
            title={title}
            idProject={selectedID}
          />
        </Dialog>

        <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
          <DialogTitle>{t("ConfirmToDelete")}</DialogTitle>
          <DialogContent>
            {t("confirmDeleteProject")}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)}>{t("Cancel")}</Button>
            <Button onClick={handleDeleteConfirmation} color="error">{t("Delete")}</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
};

export default ShowProject;
