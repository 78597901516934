import { httpClient } from "./httpClient";

const addTerminateList = (formData) => {
  return httpClient.post(`/terminateList/add`, formData);
};

const addTerminateReviewer = (formData) => {
  return httpClient.post(`/terminateReviewer/add`, formData);
};

const addTerminateEmployee = (formData) => {
  return httpClient.post(`/terminateEmployee/add`, formData);
};

const editTerminateList = (formData) => {
  return httpClient.put(`/terminateList/edit`, formData);
};

const approveTerminateEmployee = (formData) => {
  return httpClient.put(`/terminateEmployee/approve`, formData);
};

const approveTerminateEmployeeByReviewer = (id, formData) => {
  return httpClient.put(`/terminateEmployee/approve/${id}`, formData);
};

const getTerminateList = () => {
  return httpClient.get(`/terminateList`);
};

const getTerminateReviewer = () => {
  return httpClient.get(`/terminateReviewer`);
};

const getTerminateEmployee = () => {
  return httpClient.get(`/terminateEmployee`);
};

const getTerminateEmployeeByReviewer = (id) => {
  return httpClient.get(`/terminateEmployeeByReviewer/${id}`);
};

const getTerminateEmployeeByID = (id) => {
  return httpClient.get(`/terminateEmployee/${id}`);
};

const deleteTerminateReviewer = (idTerminateReviewer) => {
  return httpClient.delete(`/terminateEmployee/delete/${idTerminateReviewer}`);
};

export default {
  addTerminateList,
  editTerminateList,
  getTerminateList,
  addTerminateReviewer,
  getTerminateReviewer,
  addTerminateEmployee,
  getTerminateEmployee,
  getTerminateEmployeeByID,
  getTerminateEmployeeByReviewer,
  approveTerminateEmployee,
  approveTerminateEmployeeByReviewer,
  deleteTerminateReviewer,
};
