import React from "react";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import DrawerCustom from "../../../../shared/general/Drawer";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

import { updateRatingAndNoteByIdCandidate } from "../../../../../../actions/candidate";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  width: 450,
  padding: 16,
  "& .part-footer": {
    paddingTop: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .cancel-button": {
      color: "#9e9e9e",
      borderColor: "#9e9e9e",
    },
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiRating-root": {
    fontSize: 40,
  },
});

const DrawerNote = (props) => {
  const { open, onClose, value, hanldeSubmit } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      note: value.value || "",
    },
  });

  const onSubmit = async (valueForm) => {
    const res = await dispatch(
      updateRatingAndNoteByIdCandidate(value.editId, valueForm)
    );
    if (res) {
      hanldeSubmit(valueForm.note);
      onClose();
    }
  };

  return (
    <DrawerCustom open={open} title={`${t("Note")}`} anchor="right">
      <StyledRoot>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="note"
            rules={{
              required: { value: true, message: `${t("ThisFieldIsRequired")}` },
            }}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                label={`${t("Note")}`}
                multiline
                rows={3}
                helperText={errors && errors.note && errors.note.message}
                error={errors && errors.note ? true : false}
              />
            )}
          />

          <div className="part-footer">
            <ButtonBlue
              className="cancel-button"
              variant="outlined"
              onClick={onClose}
            >
              <i className="fa-regular fa-x"></i>
              {`${t("Cancel")}`}
            </ButtonBlue>
            <ButtonBlue type="submit" variant="contained">
              <i className="fa-regular fa-check"></i>
              {`${t("Confirm")}`}
            </ButtonBlue>
          </div>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DrawerNote;
