// OrganizationInformationPage.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Container,
  Grid,
  Typography,
  styled,
  IconButton,
  Stack,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AssessmentIcon from "@mui/icons-material/Assessment";
import dayjs from "dayjs";
import CardStyle from "../../../../../shared/general/Card";
import Loading from "../../../../../shared/loading";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationObjective } from "../../../../../../../actions/okrs";
import { useTranslation } from "react-i18next";
import KeyResult from "./keyResult";
import DialogEditObjective from "./editObjective";
import EvaluationObjective from "./evaluationObjective";
import StatusSuccess from "./statusSuccess";
import RatingChip from "./ratingChip";

const StyledRoot = styled(Box)({
  marginBottom: "20px",
  "& .objective-information-container": {
    padding: "16px",
    "& .objective-name": {
      fontSize: "20px",
      fontWeight: "500",
    },
    "& .objective-description": {
      paddingTop: "16px",
      fontSize: "14px",
    },
    "& .impact-header": {
      paddingRight: "8px",
      color: "#919eab",
    },
  },
  "& .key-result-topic-container": {
    margin: "16px 0",
    padding: "0 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontSize: "18px",
    },
  },
  "& .key-result-wrap": {
    height: "auto",
    padding: "16px",
    marginBottom: "16px",
  },
  "& .key-result-wrap:last-child": {
    marginBottom: "0",
  },
  "& .key-result-list-container": {
    "& .key-result-item-container": {
      padding: "16px",
      "& .key-result-name": {
        flexGrow: 1,
        fontWeight: "500",
      },
      "& .key-result-description": {
        paddingTop: "16px",
        fontSize: "14px",
      },
      "& .due-date-container": {
        paddingTop: "16px",
        display: "flex",
        alignItems: "center",
        color: "#919eab",
        fontSize: "14px",
        "& .MuiSvgIcon-root": {
          marginRight: "8px",
        },
        "& .MuiTypography-root, & .MuiSvgIcon-root": {
          fontSize: "inherit",
          color: "inherit",
        },
      },
      "& .MuiDivider-root": {
        paddingTop: "16px",
      },
      "& .manager-label": {
        paddingRight: "8px",
        color: "#919eab",
      },
    },
  },
});

const OrganizationInformationPage = (props) => {
  const { match } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const {
    result: organizationObjective,
    isFetching,
    isError,
  } = useSelector((state) => state.organizationObjective);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const isAdmin = userProfile && userProfile.roles.includes("ROLE_ADMIN");

  const [openDialog, setOpenDialog] = useState(false);
  const [openEvaluation, setOpenEvaluation] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleClickOpenEvaluation = () => {
    setOpenEvaluation(true);
  };

  const handleCloseEvaluation = () => {
    setOpenEvaluation(false);
  };

  useEffect(() => {
    dispatch(getOrganizationObjective(match.params.idOrganizationObjective));
  }, [dispatch, match.params.idOrganizationObjective]);

  if (isError) {
    return (
      <StyledRoot className="page">
        <Container maxWidth="lg">
          <Typography textAlign="center" fontSize="32px" paddingTop="24px">
            {`${t("NoData")}`}
          </Typography>
        </Container>
      </StyledRoot>
    );
  }

  if (isFetching) {
    return (
      <StyledRoot className="page">
        <Container maxWidth="lg">
          <Box display="flex" justifyContent="center" paddingTop="24px">
            <Loading />
          </Box>
        </Container>
      </StyledRoot>
    );
  }

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg" sx={{ marginTop: 3 }}>
        {organizationObjective && (
          <>
            <CardStyle style={{ backgroundColor: "white" }}>
              <Box padding="24px">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <Chip
                    label={`Objective ${dayjs()
                      .set("year", organizationObjective.year)
                      .format(
                        i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"
                      )}, Q${organizationObjective.quarter}`}
                    sx={{
                      border: "2px solid #DB4178",
                      color: "#DB4178",
                      bgcolor: "transparent",
                      fontSize: "20px",
                    }}
                  />
                  <Box display="flex" alignItems="center" gap="8px">
                    {isAdmin && (
                      <IconButton onClick={handleClickOpen}>
                        <EditIcon />
                      </IconButton>
                    )}
                    {isAdmin &&
                      dayjs().isAfter(
                        dayjs(organizationObjective.createDate).endOf("quarter")
                      ) && (
                        <IconButton onClick={handleClickOpenEvaluation}>
                          <AssessmentIcon />
                        </IconButton>
                      )}
                    {organizationObjective.isSuccess !== null && (
                      <StatusSuccess status={organizationObjective.isSuccess} />
                    )}
                  </Box>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack padding="8px">
                    <Typography fontSize="24px" fontWeight={500}>
                      {organizationObjective.objectiveName}
                    </Typography>
                    {organizationObjective.description && (
                      <Typography fontSize="16px" whiteSpace="pre-line">
                        <span style={{ paddingRight: "8px" }}>{`${t(
                          "Descriptions"
                        )}`}</span>
                        {organizationObjective.description}
                      </Typography>
                    )}
                    <Typography>
                      <span style={{ paddingRight: "8px" }}>{`${t(
                        "TheResultingOutcome"
                      )}`}</span>
                      {organizationObjective.impactName}
                    </Typography>
                  </Stack>
                  {organizationObjective.rating !== null && (
                    <div style={{ height: "60px" }}>
                      <RatingChip rating={organizationObjective.rating} />
                    </div>
                  )}
                </div>
              </Box>
            </CardStyle>
            <Box
              className="key-result-topic-container"
              style={{ marginTop: "30px" }}
            >
              <Typography>Key Result</Typography>
              <Typography>
                {organizationObjective.keyResults &&
                  organizationObjective.keyResults.length}{" "}
                {`${t("List")}`}
              </Typography>
            </Box>
            <CardStyle style={{ backgroundColor: "white" }}>
              <Grid container spacing={0}>
                {" "}
                {organizationObjective.keyResults &&
                  organizationObjective.keyResults.map((keyResult, index) => (
                    <Grid key={index} item xs={12} md={12}>
                      {" "}
                      <Box className="key-result-wrap">
                        <KeyResult data={keyResult} />
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </CardStyle>
            {openDialog && (
              <DialogEditObjective
                open={openDialog}
                handleClose={handleClose}
                data={{
                  objectiveName: organizationObjective.objectiveName,
                  impactName: organizationObjective.impactName,
                }}
              />
            )}
            {openEvaluation && (
              <EvaluationObjective
                open={openEvaluation}
                handleClose={handleCloseEvaluation}
                data={organizationObjective}
              />
            )}
          </>
        )}
      </Container>
    </StyledRoot>
  );
};

export default OrganizationInformationPage;
