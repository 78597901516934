import React, { useEffect, useState } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/th";
import { useDispatch, useSelector } from "react-redux";
// import { getEstimatePerformance } from "../../../../actions/estimatePerformance";

import AlertResponse from "../../shared/general/AlertResponse";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TableCustom from "../../shared/tableCustom";
import DialogEstimatePerformance from "./DialogEstimatePerformance";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName, getUserPosition } from "../../../../utils/userData";
import { getEstimateLevelActive } from "../../../../actions/estimatePerformance";

dayjs.locale("th");

const RequestEstimateYear = (props) => {
  const { setNumberOfList } = props;
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  // const { result: employeesEstimatePerformance } = useSelector(
  //   (state) => state.employeesEstimatePerformance
  // );
  const { result: performanceQuestion } = useSelector(
    (state) => state.performanceQuestion
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [selectEvaluate, setSelectEvaluate] = useState(null);

  const handleOpen = (value) => {
    setOpenDialog(true);
    setSelectEvaluate(value);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectEvaluate(null);
  };

  const columns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>
              {getUserFullName({
                firstname_TH: row.firstname_TH,
                lastname_TH: row.lastname_TH,
                firstname_EN: row.firstname_EN,
                lastname_EN: row.lastname_EN,
              })}
            </Typography>
            <Typography color="text.third" fontSize="14px">
              {getUserPosition({
                positionName: row.positionName,
                positionName_EN: row.positionName_EN,
              })}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("HiringDate")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Typography textAlign={"center"}>
          {dayjs(row.hiringDate).format(
            i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
          )}
        </Typography>
      ),
    },
    {
      name: t("Year"),
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Typography textAlign={"center"}>
          {dayjs()
            .set("year", row.year)
            .format(i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY")}
        </Typography>
      ),
    },
    {
      name: `${t("EvaluationPeriod")}`,
      headerTextAlign: "center",
      minWidth: "200px",
      width: "200px",
      cellRender: (row) => (
        <Typography textAlign={"center"}>
          {`${dayjs(row.startDate).format(
            i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
          )} - ${dayjs(row.endDate).format(
            i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
          )}`}
        </Typography>
      ),
    },
    {
      name: `${t("Evaluate")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              align: "center",
            }}
          >
            <ButtonBlue
              variant="contained"
              onClick={() => handleOpen(row)}
            >
              {t("Evaluate")}
            </ButtonBlue>
          </div>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getEstimateLevelActive());
  }, []);

  useEffect(() => {
    setNumberOfList(performanceQuestion.length);
  }, [performanceQuestion]);

  return (
    <>
      {performanceQuestion && (
        <TableCustom columns={columns} rows={performanceQuestion} />
      )}
      {openDialog && (
        <DialogEstimatePerformance
          open={openDialog}
          handleClose={handleClose}
          performance={selectEvaluate}
        />
      )}
    </>
  );
};

export default RequestEstimateYear;
