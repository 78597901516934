import React, { useEffect, useState } from "react";
import DrawerCustom from "../../../shared/general/Drawer";
import { Box, Grid, MenuItem, Typography, styled } from "@mui/material";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useDispatch } from "react-redux";
import { getManagerSetting, updateManagerSetting } from "../../../../../actions/manager";

const StyledRoot = styled(Box)({
  padding: "16px",
})

const DrawerEditPermission = (props) => {

  const { open, onClose, topic, managerLV1, managerLV2, idManagerSetting } = props;

  const dispatch = useDispatch();
  const [values, setValues] = useState({
    managerLV1: "1",
    managerLV2: "1",
  })
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChangeValue = (e) => {
    setValues(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  const onSubmit = async () => {
    setIsSubmitting(true);

    const formData = {
      managerLV1: values.managerLV1 || null,
      managerLV2: values.managerLV2 || null,
    }

    const response = await dispatch(updateManagerSetting(idManagerSetting, formData))

    if(response.status === 200){
      dispatch(getManagerSetting())
      onClose()
    }

    setIsSubmitting(false);
  }

  useEffect(() => {
    if(open === true){
      setValues({
        managerLV1: managerLV1 || "",
        managerLV2: managerLV2 || "",
      })
      setIsSubmitting(false);
    }
  }, [open])

  return (
    <DrawerCustom
      title={`แก้ไขอำนาจดำเนินการ`}
      anchor={"right"}
      open={open}
      onClose={isSubmitting? ()=>{}: onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px"
        }
      }}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography fontSize="20px" fontWeight="500">{topic}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography paddingBottom="4px">หัวหน้างานระดับที่ 1</Typography>
            <TextFieldTheme
              name="managerLV1"
              select
              value={values.managerLV1}
              SelectProps={{
                displayEmpty: true
              }}
              onChange={handleChangeValue}
            >
              <MenuItem value="1">อนุมัติ</MenuItem>
              <MenuItem value="">ไม่ระบุ</MenuItem>
            </TextFieldTheme>
          </Grid>
          <Grid item xs={12}>
            <Typography paddingBottom="4px">หัวหน้างานระดับที่ 2</Typography>
            <TextFieldTheme
              name="managerLV2"
              select
              value={values.managerLV2}
              SelectProps={{
                displayEmpty: true
              }}
              onChange={handleChangeValue}
            >
              <MenuItem value="1">อนุมัติ</MenuItem>
              <MenuItem value="">ไม่ระบุ</MenuItem>
            </TextFieldTheme>
          </Grid>
          <Grid item xs={12} container justifyContent="space-between">
            <ButtonBlue disabled={isSubmitting} onClick={() => {onClose()}}>
              ยกเลิก
            </ButtonBlue>
            <ButtonBlue variant="contained" onClick={() => {onSubmit()}} disabled={isSubmitting}>
              บันทึก
            </ButtonBlue>
          </Grid>
        </Grid>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerEditPermission;