export const loadDynamicFont = () => {
    const fontUrl = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
    const style = document.createElement('style');
    style.innerHTML = `
      @font-face {
        font-family: 'THSarabunNew';
        src: url('${fontUrl}') format('truetype');
      }
      .ql-editor {
        font-family: 'THSarabunNew', sans-serif;
      }
    `;
    document.head.appendChild(style);
  };
  