import React from "react";
import { useSelector } from "react-redux";

import UserEmployeeDocument from "../user/employee-document";
import AdminEmployeeDocument from "../admin/document";

const EmployeeDocument = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <div>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ? (
          <AdminEmployeeDocument/>
        ) : (
          <UserEmployeeDocument/>
        )
      ) : null}
    </div>
  );
};
export default  EmployeeDocument;
