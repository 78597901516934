import * as React from "react";
import { useSelector } from "react-redux";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import DatePickerCustom from "../../../shared/date/datePicker";
//Translator TH-EN
import { useTranslation } from "react-i18next";

export default function StepOne(props) {
  const { onChangeDate, onChange, dataNewShift, formik, onChangeCheckBox } =
    props;
  const { t, i18n } = useTranslation();
  const { result: allWorkTypes } = useSelector((state) => state.workTypes);

  return (
    <React.Fragment>
      <div>
        <TextField
          style={{ width: "100%" }}
          id="outlined-basic"
          label={`${t("ShiftName")}`}
          variant="outlined"
          name="nameShift"
          onChange={onChange}
        />
      </div>
      <Grid container spacing={2} style={{ marginTop: 5 }}>
        <Grid item xs={12} sm={4}>
          <DatePickerCustom
            label={`${t("StartDate")}`}
            value={dataNewShift.start}
            name="start"
            onChange={onChangeDate}
            format="dd/MM/yyyy"
            renderInput={(params) => (
              <TextField style={{ width: "100%" }} {...params} />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {allWorkTypes && (
            <FormControl fullWidth>
              <InputLabel>{`${t("ShiftGroup")}`}</InputLabel>
              <Select
                name="workType"
                value={dataNewShift.workType}
                label={`${t("ShiftGroup")}`}
                onChange={onChange}
              >
                {allWorkTypes.map((type) => (
                  <MenuItem
                    key={`${type.idWorkingType}_${type.workingTypeName}`}
                    value={type.idWorkingType}
                  >
                    {type.workingTypeName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          <div
            style={{ height: "100%", display: "flex", alignItems: "center" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="startInMondy"
                  onChange={onChangeCheckBox}
                  checked={dataNewShift.startInMondy}
                  style={{ color: "#007afe" }}
                />
              }
              label={`${t("StartWorkingMonday")}`}
            />
          </div>
        </Grid>
      </Grid>
      <div style={{ marginTop: 16 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              className={`amount`}
              id="outlined-number"
              label={`${t("WorkingDay")}`}
              type="number"
              name="workday"
              value={dataNewShift.workday}
              onChange={onChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              className={`amount`}
              id="outlined-number"
              label={`${t("DayOff")}`}
              type="number"
              name="offday"
              value={dataNewShift.offday}
              onChange={onChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              className={`amount`}
              id="outlined-number"
              label={`${t("NumberOfShifts")}`}
              type="number"
              name="amountShift"
              value={dataNewShift.amountShift}
              onChange={onChange}
              inputProps={{
                min: 1,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <div style={{ marginTop: 16 }}>
          <TextField
            style={{ width: "100%" }}
            id="outlined-basic"
            label={`${t("Description")}`}
            variant="outlined"
            name="reason"
            onChange={onChange}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
