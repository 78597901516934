/* Team Data */
export const TeamData = [
  { label: "None", value: "None" },
  { label: "Accounting", value: "Accounting" },
  { label: "HR Chem", value: "HR Chem" },
  { label: "HR Poly", value: "HR Poly" },
  { label: "HR TPE", value: "HR TPE" },
  { label: "HR TPC", value: "HR TPC" },
  { label: "HR GSC", value: "HR GSC" },
  { label: "Marketing", value: "Marketing" },
  { label: "ส่วน Logistics", value: "ส่วน Logistics" },
];

export const validator = [
  { id: "wirasut@scg.com", title: "wirasut@scg.com" },
  { id: "kobsak@scg.com", title: "kobsak@scg.com" },
  { id: "visavaj@scg.com", title: "visavaj@scg.com" },
];

export const krType = [
  { id: "None", title: "None" },
  { id: "Numeric", title: "Numeric" },
  { id: "Descriptive", title: "Description" },
];

export const TeamOptions = [
  "Accounting",
  "HR Chem",
  "HR Poly",
  "HR TPE",
  "HR TPC",
  "HR GSC",
  "Marketing",
];

/* Default Data Org Chart */
export const DashboardData = {
  id: "organization",
  name: "SCG Chemicals",
  title: "Objective: 0 , Key Result 0",
  children: [
    {
      id: "T_HR_Chem",
      name: "HR Chem",
      title: "Objective: 0 , Key Result 0",
      children: [
        {
          id: "T_HR_Poly",
          name: "HR Poly",
          title: "Objective: 0 , Key Result 0",
          children: [
            {
              id: "T_HR_TPE",
              name: "HR TPE",
              title: "Objective: 0 , Key Result 0",
            },
            {
              id: "T_HR_TPC",
              name: "HR TPC",
              title: "Objective: 0 , Key Result 0",
            },
            {
              id: "T_HR_GSC",
              name: "HR GSC",
              title: "Objective: 0 , Key Result 0",
            },
          ],
        },
      ],
    },
  ],
};

export const DashboardTeamData = [
  {
    id: "T_HR_Chem",
    parentTeamId: "0",
    name: "HR Chem",
    title: "Objective: 0 , Key Result 0",
    type: "Team",
    children: null,
  },
  {
    id: "T_HR_Poly",
    parentTeamId: "0",
    name: "HR Poly",
    title: "Objective: 0 , Key Result 0",
    type: "Team",
    children: null,
  },
  {
    id: "T_HR_TPE",
    parentTeamId: "0",
    name: "HR TPE",
    title: "Objective: 0 , Key Result 0",
    type: "Team",
    children: null,
  },
  {
    id: "T_HR_GSC",
    parentTeamId: "0",
    name: "HR GSC",
    title: "Objective: 0 , Key Result 0",
    type: "Team",
    children: null,
  },
  {
    id: "T_HR_TPC",
    parentTeamId: "0",
    name: "HR TPC",
    title: "Objective: 0 , Key Result 0",
    type: "Team",
    children: null,
  },
  {
    id: "T_Accounting",
    parentTeamId: "0",
    name: "Accounting",
    title: "Objective: 0 , Key Result 0",
    type: "Team",
    children: null,
  },
  {
    id: "T_Marketing",
    parentTeamId: "0",
    name: "Marketing",
    title: "Objective: 0 , Key Result 0",
    type: "Team",
    children: null,
  },
];

export const DashboardEmployeeData = [
  {
    id: "M_HR_TPE_1",
    parentTeamId: "T_HR_TPE",
    parentIdvId: "0",
    idEmployees: 1,
    name: "Kurtis Weissnat",
    title: "Objective: 0 , Key Result 0",
    position: "Manager",
    department: "HR TPE",
    type: "Individual",
    img: "",
    children: null,
  },
  {
    id: "O_HR_TPE_2" /* Manager */,
    parentTeamId: "M_HR_TPE_1",
    parentIdvId: "M_HR_TPE_1",
    idEmployees: 380,
    name: "สองสุวิน คำแหง",
    title: "Objective: 0 , Key Result 0",
    position: "Officer",
    department: "HR TPE",
    type: "Individual",
    img: "",
    children: null,
  },
  {
    id: "O_HR_TPE_3" /* Officer */,
    parentTeamId: "M_HR_TPE_1",
    parentIdvId: "M_HR_TPE_1",
    idEmployees: 2,
    name: "Leanne Graham",
    title: "Objective: 0 , Key Result 0",
    position: "Officer",
    department: "HR TPE",
    type: "Individual",
    img: "",
    children: null,
  },
  {
    id: "O_HR_TPE_4",
    parentTeamId: "M_HR_TPE_1",
    parentIdvId: "M_HR_TPE_1",
    idEmployees: 3,
    name: "James Kochhar",
    title: "Objective: 0 , Key Result 0",
    position: "Officer",
    department: "HR TPE",
    type: "Individual",
    img: "",
    children: null,
  },
  {
    id: "O_Accouting_1",
    parentTeamId: "T_Accounting",
    parentIdvId: "0",
    idEmployees: 4,
    name: "Chelsey Dietrich",
    title: "Objective: 0 , Key Result 0",
    position: "Officer",
    department: "Accounting",
    type: "Individual",
    img: "",
    children: null,
  },
  {
    id: "O_Marketing_1",
    parentTeamId: "T_Marketing",
    parentIdvId: "0",
    idEmployees: 5,
    name: "Employee 5",
    title: "Objective: 0 , Key Result 0",
    position: "Officer",
    department: "Marketing",
    type: "Individual",
    img: "",
    children: null,
  },
];
