import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Avatar,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import DrawerCustom from "../../../../shared/general/Drawer";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  DesktopDatePicker,
  TimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getNewCandidatesByIdJobRequest,
  getNewCandidatesByIdBiddings,
  updateInterviewDetail,
  getCandidatesAlreadySendToJob,
} from "../../../../../../actions/candidate";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { async } from "@dabeng/react-orgchart";
import { getOptionTimes, stringToColor } from "../../../../../../utils";
import { getJobGroupInterviewer } from "../../../../../../actions/jobRecruit";
import { t } from "i18next";
import { getUserFullName } from "../../../../../../utils/userData";

const StyledRoot = styled("div")({
  padding: 16,
  maxWidth: 450,
  "& .MuiInputLabel-root .MuiInputLabel-formControl": {
    color: "#ffffff",
  },
  "& .part-footer": {
    paddingTop: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .cancel-button": {
      color: "#9e9e9e",
      borderColor: "#9e9e9e",
    },
    "& i": {
      marginRight: 8,
    },
  },
  "& .part-auto-appointment": {
    marginBottom: 24,
    "& .MuiButton-root": {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  // "& .part-form": {
  //   "& .MuiInputBase-root": {
  //     height: 58,
  //   },
  // },
});

const StyledName = styled("div")({
  padding: 8,
  border: "1px solid rgba(0, 0, 0, 0.23)",
  borderRadius: 8,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#ffffff",
  "& .wrap-name": {
    display: "flex",
    alignItems: "center",
    width: 0,
    flexGrow: 1,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    "& .MuiTypography-root": {
      flexGrow: 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  "& i": {
    paddingLeft: 8,
    color: "#9e9e9e",
  },
});

const DrawerInterviewAppointment = (props) => {
  const {
    open,
    onClose,
    listCandidate,
    idJobRecruit,
    idJobGroup,
    setIsSuccess,
    handleCountDownTimeSet,
    type,
  } = props;
  // console.log(listCandidate);
  // console.log(type);
  const dispatch = useDispatch();
  const { result: jobGroupInterviewer } = useSelector(
    (state) => state.jobGroupInterviewer
  );

  function checkUniqueStartTimes(listInterviewPositions) {
    const times = listInterviewPositions
      .filter((position) => position.status && position.interviewStartTime)
      .map((position) => position.interviewStartTime);
    const uniqueTimes = new Set(times); //คัด times ที่ไม่ซ้ำกัน
    return uniqueTimes.size === times.length;
  }

  const validationSchema = yup.object().shape({
    interviewAppointmentDate: yup.date().required(),
    interviewStartTimeAuto: yup.date().required(t("PleaseEnterPosition")),
    interviewEndTimeAuto: yup
      .date()
      .min(yup.ref("interviewStartTimeAuto"), t("EndTimeGreaterThanStartTime"))
      .required(t("PleaseEnterPosition")),
    interviewDurationTime: yup
      .string()
      .required(t("PleaseEnterIndividualInterviewTime")),
    isOnline: yup.string().required(t("PleaseEnterPosition")),
    interviewPlace: yup.string().when("isOnline", {
      is: (val) => val === "1",
      then: (schema) => schema.required(t("pleaseEnterInterviewLink")),
      otherwise: (schema) => schema.required(t("pleaseEnterInterviewLocation")),
    }),
    contactName: yup.string().required(t("pleaseEnterContactName")),
    contactPhone: yup
      .string()
      .min(10, t("pleaseEnterTenDigitPhoneNumber"))
      .max(10, t("pleaseEnterTenDigitPhoneNumber"))
      .required(t("pleaseEnterPhoneNumber")),
    interviewer: yup
      .array()
      .of(
        yup.object().shape({
          idEmployees: yup.number(),
        })
      )
      .min(0, t("pleaseSelectCommittee")),
    listInterviewPositions: yup
      .array()
      .of(
        yup.object().shape({
          status: yup.boolean(),
          interviewStartTime: yup.string().when("status", {
            is: true,
            then: (schema) => schema.required(t("pleaseSelectInterviewTime")),
          }),
        })
      )
      .test("uniqueStartTime", t("duplicateStartTime"), (value) =>
        checkUniqueStartTimes(value)
      ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    trigger,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      interviewAppointmentDate: dayjs(),
      interviewStartTimeAuto: dayjs(),
      interviewEndTimeAuto: dayjs(),
      interviewDurationTime: "",
      isOnline: "0",
      interviewPlace: "",
      contactName: "",
      contactPhone: "",
      interviewer: [],
      listInterviewPositions: listCandidate
        .filter((item) => item.idStatus === 5 || item.idStatus === 6)
        .map((candidate) => {
          return {
            idCandidate: candidate.idCandidate,
            interviewStartTime: "",
            status: true,
            name: candidate.name,
            lastName: candidate.lastname,
            imageURL: candidate.imageURL,
          };
        }),
    },
    resolver: yupResolver(validationSchema),
  });

  const [interviewer, setInterviewer] = useState([]);
  const [isInterviewPanelEmpty, SetIsInterviewPanelEmpty] = useState(false);
  const [formPage, setFormPage] = useState(1);

  const handelNextPage = async () => {
    const pass = await trigger([
      "interviewAppointmentDate",
      "interviewStartTimeAuto",
      "interviewEndTimeAuto",
      "interviewDurationTime",
      "isOnline",
      "interviewPlace",
      "contactName",
      "contactPhone",
      "interviewer",
    ]);
    if (pass) {
      setFormPage(2);
    }
  };

  const hanleBackPage = () => {
    setFormPage(1);
  };

  const onSubmit = async (values) => {
    const formData = {
      idJobRecruit: idJobRecruit,
      ...values,
    };

    dispatch(updateInterviewDetail(formData)).then((res) => {
      dispatch(getCandidatesAlreadySendToJob(idJobRecruit));
    });

    onClose();
  };

  const parseTime = (timeStr) => {
    const [hours, minutes] = timeStr.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  };

  const handleChangeInterviewTime = () => {
    const listInterviewPositions = watch("listInterviewPositions");
    const positionsorted = listInterviewPositions
      .filter((item) => item.status)
      .sort(
        (a, b) =>
          parseTime(a.interviewStartTime) - parseTime(b.interviewStartTime)
      );

    const noInterview = listInterviewPositions.filter((item) => !item.status);

    const combineInterview = [...positionsorted, ...noInterview];
    setValue("listInterviewPositions", combineInterview);
  };

  useEffect(() => {
    if (jobGroupInterviewer) {
      const jobGroupSelected = jobGroupInterviewer.find(
        (group) => group.idJobGroup === idJobGroup
      );
      if (jobGroupSelected && jobGroupSelected.interviewers) {
        setInterviewer(jobGroupSelected.interviewers);
        SetIsInterviewPanelEmpty(false);
      } else {
        setInterviewer([]);
        SetIsInterviewPanelEmpty(true);
      }
    }
    return () => {
      setInterviewer([]);
    };
  }, [jobGroupInterviewer]);

  useEffect(() => {
    dispatch(getJobGroupInterviewer());
  }, []);

  const listInterviewPositions = watch("listInterviewPositions");

  return (
    <>
      <DrawerCustom
        title={t("InterviewAppointment")}
        anchor="right"
        open={open}
        onClose={onClose}
      >
        <StyledRoot>
          <form onSubmit={handleSubmit(onSubmit)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {formPage === 1 && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="interviewAppointmentDate"
                      rules={{
                        required: {
                          value: true,
                          message: t("PleaseEnterInterviewAppointmentDate"),
                        },
                        validate: {
                          dateValidation: (value) =>
                            !isNaN(value) || t("PleaseEnterCorrectDate"),
                          disblePast: (value) =>
                            new Date(value) >=
                              new Date(new Date().setHours(0, 0, 0, 0)) ||
                            "ไม่สามารถเลือกวันที่ผ่านมาแล้วได้",
                        },
                      }}
                      render={({ field }) => (
                        <DesktopDatePicker
                          {...field}
                          inputFormat="DD/MM/YYYY"
                          disablePast
                          renderInput={(params) => (
                            <Fragment>
                              <Typography
                                gutterBottom
                                sx={{ color: "#000000" }}
                              >
                                {t("InterviewAppointmentDate")}
                              </Typography>
                              <TextFieldTheme
                                {...params}
                                sx={{ color: "#ffffff" }}
                                helperText={
                                  errors &&
                                  errors.interviewAppointmentDate &&
                                  errors.interviewAppointmentDate.message
                                }
                                error={
                                  errors && errors.interviewAppointmentDate
                                    ? true
                                    : false
                                }
                              />
                            </Fragment>
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="interviewStartTimeAuto"
                      render={({ field, fieldState }) => (
                        <TimePicker
                          {...field}
                          ampm={false}
                          label={t("StartTime")}
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              error={Boolean(fieldState.error)}
                              helperText={
                                fieldState.error && fieldState.error.message
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="interviewEndTimeAuto"
                      render={({ field, fieldState }) => (
                        <TimePicker
                          {...field}
                          ampm={false}
                          label={t("EndTime")}
                          minTime={dayjs(watch("interviewStartTimeAuto"))}
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              error={Boolean(fieldState.error)}
                              helperText={
                                fieldState.error && fieldState.error.message
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="interviewDurationTime"
                      render={({ field, formState }) => (
                        <Fragment>
                          <Typography gutterBottom>
                            {t("IndividualInterviewTime")}
                          </Typography>
                          <TextFieldTheme
                            {...field}
                            placeholder={t("selectIndividualInterviewTime")}
                            helperText={
                              formState.errors &&
                              formState.errors.interviewDurationTime &&
                              formState.errors.interviewDurationTime.message
                            }
                            error={
                              formState.errors &&
                              formState.errors.interviewDurationTime
                                ? true
                                : false
                            }
                            select
                          >
                            <MenuItem value="15">
                              15 {t("Unit.Minute")}
                            </MenuItem>
                            <MenuItem value="30">
                              30 {t("Unit.Minute")}
                            </MenuItem>
                            <MenuItem value="45">
                              45 {t("Unit.Minute")}
                            </MenuItem>
                            <MenuItem value="60">
                              60 {t("Unit.Minute")}
                            </MenuItem>
                            <MenuItem value="90">
                              90 {t("Unit.Minute")}
                            </MenuItem>
                            <MenuItem value="120">
                              120 {t("Unit.Minute")}
                            </MenuItem>
                          </TextFieldTheme>
                        </Fragment>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>{t("InterviewType")}</FormLabel>
                      <Controller
                        control={control}
                        name={"isOnline"}
                        render={({ field }) => (
                          <RadioGroup
                            {...field}
                            row
                            onChange={(e) => field.onChange(e)}
                          >
                            <FormControlLabel
                              value={"0"}
                              control={<Radio />}
                              label={t("online")}
                            />
                            <FormControlLabel
                              value={"1"}
                              control={<Radio />}
                              label={t("offline")}
                            />
                          </RadioGroup>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={"interviewPlace"}
                      render={({ field, fieldState }) => (
                        <>
                          <Typography gutterBottom sx={{ color: "#000000" }}>
                            {watch("isOnline") === "1"
                              ? t("InterviewLink")
                              : t("InterviewLocation")}
                          </Typography>
                          <TextFieldTheme
                            {...field}
                            error={Boolean(fieldState.error)}
                            helperText={
                              fieldState.error && fieldState.error.message
                            }
                            placeholder={
                              watch("isOnline") === "1"
                                ? t("example") + " " + t("interviewLink")
                                : t("exampleBuilding")
                            }
                          />
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={"contactName"}
                      render={({ field, fieldState }) => (
                        <>
                          <Typography gutterBottom sx={{ color: "#000000" }}>
                            {t("ContactName")}
                          </Typography>
                          <TextFieldTheme
                            {...field}
                            placeholder={t("examplePerson")}
                            error={Boolean(fieldState.error)}
                            helperText={
                              fieldState.error && fieldState.error.message
                            }
                          />
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={"contactPhone"}
                      render={({ field, fieldState }) => (
                        <>
                          <Typography gutterBottom sx={{ color: "#000000" }}>
                            {t("contactPhoneNumber")}
                          </Typography>
                          <TextFieldTheme
                            placeholder={t("example") + " 081756XXXX"}
                            {...field}
                            InputProps={{
                              inputComponent: NumberFormatTheme,
                            }}
                            inputProps={{
                              format: (value) => {
                                if (value.length >= 3) {
                                  return String(value).replace(
                                    /(\d{1,10})/,
                                    "$1"
                                  );
                                } else {
                                  return String(value);
                                }
                              },
                              maxLength: 10,
                              allowNegative: false,
                              allowLeadingZeros: true,
                            }}
                            error={Boolean(fieldState.error)}
                            helperText={
                              fieldState.error && fieldState.error.message
                            }
                          />
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="interviewer"
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          {...field}
                          multiple
                          options={interviewer}
                          getOptionLabel={(option) =>
                            `${getUserFullName(option)}`
                          }
                          onChange={(evant, value) => field.onChange(value)}
                          isOptionEqualToValue={(option, value) =>
                            option.idEmployees === value.idEmployees
                          }
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.idEmployees}>
                                {`${getUserFullName(option)}`}
                              </li>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(fieldState.error)}
                              helperText={
                                fieldState.error && fieldState.error.message
                              }
                              label={t("committeeList")}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  {isInterviewPanelEmpty && (
                    <Grid item xs={12}>
                      <Typography color="error">
                        {t("noCommitteeData")}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <div className="part-footer">
                      <ButtonBlue
                        className="cancel-button"
                        variant="outlined"
                        onClick={onClose}
                      >
                        <i className="fa-regular fa-x"></i>
                        {t("Cancel")}
                      </ButtonBlue>
                      <ButtonBlue
                        variant="contained"
                        onClick={handelNextPage}
                        disabled={listInterviewPositions.length === 0}
                      >
                        <i className="fa-regular fa-check"></i>
                        {t("Next")}
                      </ButtonBlue>
                    </div>
                    {listInterviewPositions.length === 0 && (
                      <Typography color={"error"} textAlign={"right"}>
                        {t("noInterviewer")}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )}
            </LocalizationProvider>
            {formPage === 2 && (
              <Grid container width={"100%"} spacing={2}>
                {listInterviewPositions.map((candidate, index) => (
                  <Fragment key={candidate.idCandidate}>
                    <Grid item xs={12} md={8}>
                      <StyledName>
                        <div className="wrap-name">
                          <Avatar
                            sx={{
                              bgcolor: stringToColor(
                                `${candidate.name} ${candidate.lastName}`
                              ),
                            }}
                            children={`${candidate.name[0]}${candidate.lastName[0]}`}
                          />
                          <Typography
                            sx={{ color: "#000000" }}
                          >{`${candidate.name} ${candidate.lastName}`}</Typography>
                        </div>
                        <Controller
                          control={control}
                          name={`listInterviewPositions[${index}].status`}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              checked={field.value}
                              onChange={(event) => {
                                if (!event.target.checked) {
                                  setValue(
                                    `listInterviewPositions[${index}].interviewStartTime`,
                                    ""
                                  );
                                }
                                field.onChange(event.target.checked);
                                handleChangeInterviewTime();
                              }}
                            />
                          )}
                        />
                      </StyledName>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Controller
                        control={control}
                        name={`listInterviewPositions[${index}].interviewStartTime`}
                        rules={{
                          required: {
                            value: true,
                            message: t("pleaseEnterInterviewStartTime"),
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <Fragment>
                            <TextFieldTheme
                              {...field}
                              label={t("StartTime")}
                              placeholder={t("selectStartTime")}
                              select
                              fullWidth
                              sx={{
                                "&.MuiFormControl-root, .MuiOutlinedInput-root":
                                  {
                                    height: "100%",
                                  },
                              }}
                              onChange={(value) => {
                                field.onChange(value);
                                handleChangeInterviewTime();
                              }}
                              error={Boolean(fieldState.error)}
                              helperText={
                                fieldState.error && fieldState.error.message
                              }
                              disabled={
                                !watch(
                                  `listInterviewPositions[${index}].status`
                                )
                              }
                            >
                              {getOptionTimes(
                                dayjs(watch("interviewStartTimeAuto")),
                                dayjs(watch("interviewEndTimeAuto")),
                                watch("interviewDurationTime")
                              ).map((time) => (
                                <MenuItem key={time} value={time}>
                                  {time}
                                </MenuItem>
                              ))}
                            </TextFieldTheme>
                          </Fragment>
                        )}
                      />
                    </Grid>
                  </Fragment>
                ))}
                {errors.listInterviewPositions && (
                  <Grid item xs={12}>
                    <Typography color="error" textAlign="right" fontSize="18px">
                      {errors.listInterviewPositions.message}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <div className="part-footer">
                    <ButtonBlue
                      className="cancel-button"
                      variant="outlined"
                      onClick={hanleBackPage}
                    >
                      <i className="fa-regular fa-arrow-left"></i>
                      {t("goBack")}
                    </ButtonBlue>
                    <ButtonBlue
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      <i className="fa-regular fa-check"></i>
                      {t("Confirm")}
                    </ButtonBlue>
                  </div>
                </Grid>
              </Grid>
            )}
          </form>
        </StyledRoot>
      </DrawerCustom>
    </>
  );
};

export default DrawerInterviewAppointment;
