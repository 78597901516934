import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Grid, MenuItem, styled, Typography } from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import {
  getAllBanks,
  getBankDetailByCompany,
  updateBankDetail,
} from "../../../../actions/bank";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  marginBottom: 8,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DrawerBank = (props) => {
  const { open, onClose, selectedCompany } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: AllBanks } = useSelector((state) => state.bank);
  const { result: BankDetail } = useSelector((state) => state.bankDetail);
  const { result: companyProfile } = useSelector(
    (state) => state.companyProfile
  );
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      idBank: "none",
      accountNo: "",
      accountName: "",
      branchName: "",
      branchAddress: "",
    },
  });

  useEffect(() => {
    dispatch(getAllBanks());
  }, []);

  useEffect(() => {
    if (BankDetail && BankDetail[0]) {
      reset({
        idBankDetail: BankDetail[0].idBankDetail,
        idBank: BankDetail[0].idBank,
        accountNo: BankDetail[0].accountNo,
        accountName: BankDetail[0].accountName,
        branchName: BankDetail[0].branchName,
        branchAddress: BankDetail[0].branchAddress,
      });
    }
  }, [BankDetail]);

  const onHandleSubmit = async (data) => {
    const res = await dispatch(updateBankDetail(selectedCompany.idCompany, data));
    console.log(res)
    if (res) {
      dispatch(getBankDetailByCompany({idCompany: selectedCompany.idCompany}));
      onClose();
    }
  };

  return (
    <DrawerCustom
      title={`${t("EditDataBank")}`}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(onHandleSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledContentLabel>{`${t("BankName")}`}</StyledContentLabel>
              <Controller
                name="idBank"
                control={control}
                render={({ field }) => (
                  <TextFieldTheme {...field} select>
                    <MenuItem value="none" disabled>
                      {`${t("SelectBank")}`}
                    </MenuItem>
                    {AllBanks &&
                      AllBanks.length > 0 &&
                      AllBanks.map((bank) => (
                        <MenuItem key={bank.idBank} value={bank.idBank}>
                          {t(bank.bankName)}
                        </MenuItem>
                      ))}
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledContentLabel>{`${t("BookID")}`}</StyledContentLabel>
              <Controller
                name="accountNo"
                control={control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    fullWidth
                    inputProps={{
                      allowNegative: false,
                      maxLength: 10,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledContentLabel>{`${t("AccountName")}`}</StyledContentLabel>
              <Controller
                name="accountName"
                control={control}
                render={({ field }) => <TextFieldTheme {...field} fullWidth />}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledContentLabel>{`${t("BranchName")}`}</StyledContentLabel>
              <Controller
                name="branchName"
                control={control}
                render={({ field }) => <TextFieldTheme {...field} fullWidth />}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledContentLabel>{`${t("BranchAddress")}`}</StyledContentLabel>
              <Controller
                name="branchAddress"
                control={control}
                render={({ field }) => <TextFieldTheme {...field} fullWidth />}
              />
            </Grid>
          </Grid>
          <StyledFooter>
            <ButtonBlue variant="text" className="cancel" onClick={onClose}>
              {`${t("Cancel")}`}
            </ButtonBlue>
            <ButtonBlue variant="contained" type="submit">
              {`${t("SaveData")}`}
            </ButtonBlue>
          </StyledFooter>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DrawerBank;
