import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  Avatar,
} from "@mui/material";
import CardStyle from "../../../../shared/general/Card";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch } from "react-redux";
import { getAllObjectiveForEvaluation } from "../../../../../../actions/okrs";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));

const StyledRoot = styled(Box)({
  "& .objectcard-wrap": {
    height: "100%",
    marginBottom: "30px",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  "& .objective-card": {
    padding: "16px",
  },
  "& .MuiFilledInput-root input": {
    padding: "16px 12px 16px 12px",
  },
});

const StyledCard = styled(Card)({
  minHeight: "70px",
  boxShadow: "none",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  background:
    "linear-gradient(to right, rgba(254, 196, 144, 0.7), rgba(235, 148, 192, 0.7))",
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  "& .searchContainer": {
    marginLeft: "41px",
    marginBottom: "100px",
  },
  "@media (max-width: 600px)": {
    "& .searchContainer": {
      marginBottom: "10px",
    },
  },
});

const StyleHeadBox = styled(Box)({
  "& .box-header": {
    minHeight: "200px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor:
      "linear-gradient(to right, rgba(254, 196, 144, 0.7), rgba(235, 148, 192, 0.7))",
    "& .text-header": {
      fontSize: "48px",
      fontWeight: "bold",
      marginLeft: "40px",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginTop: "80px",
    },
    "& .img-header": {
      width: "33%",
      height: "auto",
      marginBottom: "10px",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      marginRight: "20px",
    },
  },

  "@media (max-width: 600px)": {
    "& .box-header": {
      flexDirection: "column",
      minHeight: "unset",
      justifyContent: "center",
      alignItems: "center",
      "& .text-header": {
        fontSize: "30px",
        marginLeft: "20px",
        marginTop: "20px",
      },
      "& .img-header": {
        width: "50%",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },
    },
  },
});

const StyledCardContent = styled(Card)({
  height: 300,
  maxWidth: 350,
  minWidth: 250,
  padding: "8px",
  borderRadius: "10px",
});

const StyledContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  "& .text-name": {
    fontSize: "20px",
    fontWeight: "bold",
    color: "black",
    textAlign: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  "& .text-secondary": {
    color: "gray",
    marginTop: "5px",
    display: "flex",
    alignItems: "flex-start",
  },
  "& .text-content": {
    color: "black",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    minWidth: "50px",
    flex: "1",
  },
});

const CustomButton = styled(Button)({
  color: "#DB4178",
  border: "2px solid #DB4178",
  backgroundColor: "transparent",
  borderRadius: "7px",
  height: "35px",
  marginTop: "15px",
  "&:hover": {
    color: "#DB4178",
    border: "2px solid #C53B6C",
    backgroundColor: "transparent",
    borderRadius: "7px",
    height: "35px",
  },
});

const EvaluationIndividualPage = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [mode] = useState("list");

  const [evaluationList, setEvaluationList] = useState([]);
  const [searchText, setSearchText] = useState("");

  const columns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          {/* <Avatar sx={{marginRight: "8px", width: 40, height: 40, "& img": {objectFit: "contain"}}} src={row.imageURL}/> */}
          <Box flexGrow={1}>
            <Typography>
              {row.firstname_TH} {row.lastname_TH}
            </Typography>
            <Typography color="text.third" fontSize="14px">
              {row.positionName}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("Objectives")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          {/* <Avatar sx={{marginRight: "8px", width: 40, height: 40, "& img": {objectFit: "contain"}}} src={row.imageURL}/> */}
          <Box flexGrow={1}>
            <Typography>{row.objectiveName}</Typography>
            <Typography color="text.third" fontSize="14px">
              {row.positionName}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("Quarter")}`,
      minWidth: "160px",
      width: "160px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          {/* <Avatar sx={{marginRight: "8px", width: 40, height: 40, "& img": {objectFit: "contain"}}} src={row.imageURL}/> */}
          <Box flexGrow={1}>
            <Typography>Q{row.quarter}</Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("NumberKeyResults")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          {/* <Avatar sx={{marginRight: "8px", width: 40, height: 40, "& img": {objectFit: "contain"}}} src={row.imageURL}/> */}
          <Box flexGrow={1}>
            <Typography>{row.keyResults.length}</Typography>
            <Typography color="text.third" fontSize="14px">
              {row.positionName}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: "",
      minWidth: "120px",
      width: "120px",
      cellRender: (row) => (
        <Button
          startIcon={<ArrowForwardIosRoundedIcon />}
          component={Link}
          to={`/okrs/evaluation/individual/${row.idIndividualObjective}`}
        >
          {`${t("Descriptions")}`}
        </Button>
      ),
    },
  ];

  const renderCards = () => {
    const filteredList = evaluationList.filter((row) => {
      const fullName = `${row.firstname_TH} ${row.lastname_TH}`.toLowerCase();
      return fullName.includes(searchText.toLowerCase());
    });
    return filteredList.map((row, index) => (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        key={index}
        style={{ justifyContent: "center" }}
      >
        <StyledCardContent>
          <StyledContent>
            <Avatar
              alt={`${row.firstname_TH} ${row.lastname_TH}`}
              src={row.avatar}
              style={{
                width: 100,
                height: 100,
                marginBottom: "10px",
                alignSelf: "center",
              }}
            />
            <Typography className="text-name">
              {`${row.firstname_TH} ${row.lastname_TH}`}
            </Typography>
            <Typography
              variant="body2"
              className="text-secondary"
              style={{ marginTop: "10px" }}
            >
              {t("Objectives")}:
              <span className="text-content">{row.objectiveName}</span>
            </Typography>
            <Typography variant="body2" className="text-secondary">
              {t("Quarter")}:{" "}
              <span className="text-content">Q{row.quarter}</span>
            </Typography>
            <Typography variant="body2" className="text-secondary">
              {t("NumberKeyResults")}:
              <span className="text-content">
                {row.keyResults ? row.keyResults.length : 0}
              </span>
            </Typography>
            <CustomButton
              component={Link}
              to={`/okrs/evaluation/individual/${row.idIndividualObjective}`}
            >
              {t("Descriptions")}
            </CustomButton>
          </StyledContent>
        </StyledCardContent>
      </Grid>
    ));
  };

  useEffect(() => {
    dispatch(getAllObjectiveForEvaluation()).then((res) => {
      if (res.status == 200) {
        setEvaluationList(res.data);
      }
    });
  }, []);

  return (
    <StyledRoot className="page" style={{ marginBottom: "20px" }}>
      <Container maxWidth="lg">
        <StyledCard
          style={{
            marginBottom: "48px",
            marginTop: "20px",
          }}
        >
          <StyleHeadBox>
            <Box className="box-header">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="text-header">{`${t(
                    "ListApprovedOutcomes"
                  )}`}</Typography>
                </Grid>
                <Grid item xs={12} className="searchContainer">
                  <TextFieldTheme
                    style={{
                      maxWidth: "250px",
                      background: "#fff",
                      borderRadius: "10px",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="center">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      placeholder: `${t("Search")}`,
                    }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </Grid>
              </Grid>
              <img
                className="img-header"
                src={`${process.env.PUBLIC_URL}/assets/okrs/manager/individual-goals-evaluation.png`}
                alt="icon"
              />
            </Box>
          </StyleHeadBox>
        </StyledCard>

        <Box>
          {mode === "list" && (
            <Grid container spacing={2}>
              {renderCards()}
            </Grid>
          )}
        </Box>
      </Container>
    </StyledRoot>
  );
};

export default EvaluationIndividualPage;
