import React from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Divider, Grid, Typography } from "@mui/material";

const EmpInfo = ({ employee }) => {
  const {t} =useTranslation();
  return (
    <div>
      <Typography variant="body1" gutterBottom>
        {t("information")}
      </Typography>
      <Divider />

      <Grid container>
        <Grid item xs={12} md={4}>
          <Typography variant="body2" gutterBottom>
            {t("Section")}
          </Typography>
          <Typography variant="subtitle1">{employee.section}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="body2" gutterBottom>
            {t("Department")}
          </Typography>
          <Typography variant="subtitle1">{employee.department}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="body2" gutterBottom>
            {t("Division")}
          </Typography>
          <Typography variant="subtitle1">{employee.division}</Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        <Grid item xs={12} md={4}>
          <Typography variant="body2" gutterBottom>
           {t("reportingManager")}
          </Typography>
          <Typography variant="subtitle1">
            {employee.reportingManager}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default EmpInfo;
