import { httpClient } from "./httpClient";

const addEmemoRequestForm = (formData) => {
  return httpClient.post("/e-memo", formData);
};

const getEmemoRequestFormById = (id, role) => {
  return httpClient.get(`/getEmemoById/${id}`, {
    params: {
      role: role,
    },
  });
};

const patchEmemoStatus = (id, status, comment, nextStep) => {
  return httpClient.patch(`/e-memo/status/${id}`, {
    status,
    comment,
    nextStep,
  });
};

const getEmemoRequestStepInitial = (id) => {
  return httpClient.get(`/getEmemoStepInitial/${id}`);
};

export default {
  addEmemoRequestForm,
  getEmemoRequestFormById,
  patchEmemoStatus,
  getEmemoRequestStepInitial,
};
