import {
  TERMINATE_LIST_FETCHING,
  TERMINATE_LIST_FAILED,
  TERMINATE_LIST_SUCCESS,
  TERMINATE_REVIEWER_FETCHING,
  TERMINATE_REVIEWER_SUCCESS,
  TERMINATE_REVIEWER_FAILED,
  TERMINATE_EMPLOYEE_FETCHING,
  TERMINATE_EMPLOYEE_SUCCESS,
  TERMINATE_EMPLOYEE_FAILED,
  TERMINATE_EMPLOYEE_BYID_SUCCESS,
  TERMINATE_EMPLOYEE_BYID_FETCHING,
  TERMINATE_EMPLOYEE_BYID_FAILED,
  TERMINATE_REVIEWER_DELETE_FAILED,
  TERMINATE_REVIEWER_DELETE_SUCCESS,
} from "./types";
import offBoardingService from "../services/offBoarding.service";

export const clearTerminateEmployee = () => async (dispatch) => {
  dispatch({
    type: TERMINATE_EMPLOYEE_SUCCESS,
    payload: null,
  });
  dispatch({
    type: TERMINATE_EMPLOYEE_BYID_SUCCESS,
    payload: null,
  });
};

export const Addterminatelist = (formData) => async () => {
  try {
    console.log(formData);
    const res = await offBoardingService.addTerminateList(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};
export const AddTerminateReviewer = (formData) => async () => {
  try {
    console.log(formData);
    const res = await offBoardingService.addTerminateReviewer(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};
export const AddTerminateEmployee = (formData) => async () => {
  try {
    console.log(formData);
    const res = await offBoardingService.addTerminateEmployee(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};
export const EditTerminatelist = (formData) => async () => {
  try {
    console.log(formData);
    const res = await offBoardingService.editTerminateList(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};
export const approveTerminateEmployee = (formData) => async () => {
  try {
    console.log(formData);
    const res = await offBoardingService.approveTerminateEmployee(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const approveTerminateEmployeeByReviewer =
  (id, formData) => async () => {
    try {
      console.log(formData);
      const res = await offBoardingService.approveTerminateEmployeeByReviewer(
        id,
        formData
      );
      if (res) {
        return res;
      }
    } catch (error) {
      console.log(error);
      const message =
        (error.response && error.response.data && error.response.data.name) ||
        error.name ||
        error.toString();
      return error;
    }
  };

export const getTerminateList = () => async (dispatch) => {
  try {
    dispatch({
      type: TERMINATE_LIST_FETCHING,
    });
    const res = await offBoardingService.getTerminateList();
    if (res) {
      dispatch({
        type: TERMINATE_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: TERMINATE_LIST_FAILED,
    });
    console.log(err);
  }
};

export const getTerminateReviewer = () => async (dispatch) => {
  try {
    dispatch({
      type: TERMINATE_REVIEWER_FETCHING,
    });
    const res = await offBoardingService.getTerminateReviewer();
    if (res) {
      dispatch({
        type: TERMINATE_REVIEWER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: TERMINATE_REVIEWER_FAILED,
    });
    console.log(err);
  }
};

export const getTerminateEmployee = () => async (dispatch) => {
  try {
    dispatch({
      type: TERMINATE_EMPLOYEE_FETCHING,
    });
    const res = await offBoardingService.getTerminateEmployee();
    if (res) {
      dispatch({
        type: TERMINATE_EMPLOYEE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: TERMINATE_EMPLOYEE_FAILED,
    });
    console.log(err);
  }
};

export const getTerminateEmployeeByReviewer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: TERMINATE_EMPLOYEE_FETCHING,
    });
    const res = await offBoardingService.getTerminateEmployeeByReviewer(id);
    if (res) {
      dispatch({
        type: TERMINATE_EMPLOYEE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: TERMINATE_EMPLOYEE_FAILED,
    });
    console.log(err);
  }
};
export const getTerminateEmployeeByID = (id) => async (dispatch) => {
  try {
    dispatch({
      type: TERMINATE_EMPLOYEE_BYID_FETCHING,
    });
    const res = await offBoardingService.getTerminateEmployeeByID(id);
    if (res) {
      dispatch({
        type: TERMINATE_EMPLOYEE_BYID_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: TERMINATE_EMPLOYEE_BYID_FAILED,
    });
    console.log(err);
  }
};

export const deleteTerminateReviewer =
  (idTerminateReviewer) => async (dispatch) => {
    try {
      const res = await offBoardingService.deleteTerminateReviewer(
        idTerminateReviewer
      );
      if (res) {
        dispatch({
          type: TERMINATE_REVIEWER_DELETE_SUCCESS,
          payload: idTerminateReviewer,
        });
        return res;
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: TERMINATE_REVIEWER_DELETE_FAILED,
      });
      const message =
        (error.response && error.response.data && error.response.data.name) ||
        error.name ||
        error.toString();
      return { error: message };
    }
  };
