import welfareExpenseService from "../services/welfareExpense.service";
import {
  WELFARE_BILLING_FETCHING,
  WELFARE_BILLING_FAILED,
  WELFARE_BILLING_SUCCESS,
  WELFARE_BILLING_DETAIL_FAILED,
  WELFARE_BILLING_DETAIL_FETCHING,
  WELFARE_BILLING_DETAIL_SUCCESS,
} from "./types";

export const addWelfareBilling = (formData) => async () => {
  try {
    const res = await welfareExpenseService.addWelfareBilling(formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getAllWelfareExpense = () => async (dispatch) => {
  try {
    dispatch({
      type: WELFARE_BILLING_FETCHING,
    });
    const res = await welfareExpenseService.getAllWelfareExpense();
    if (res) {
      dispatch({
        type: WELFARE_BILLING_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: WELFARE_BILLING_FAILED,
    });
    console.log(err);
  }
};

export const getWelfareExpenseHistory = () => async (dispatch) => {
  try {
    dispatch({
      type: WELFARE_BILLING_FETCHING,
    });
    const res = await welfareExpenseService.getWelfareExpenseHistory();
    if (res) {
      dispatch({
        type: WELFARE_BILLING_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: WELFARE_BILLING_FAILED,
    });
    console.log(err);
  }
};

export const getFilteredWelfareExpenseHistory = (data) => async (dispatch) => {
  try {
    dispatch({
      type: WELFARE_BILLING_FETCHING,
    });
    const res = await welfareExpenseService.getFilteredWelfareExpenseHistory(
      data
    );
    if (res) {
      dispatch({
        type: WELFARE_BILLING_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: WELFARE_BILLING_FAILED,
    });
    console.log(err);
  }
};

export const updateVerifyWelfareExpense = (formData) => async () => {
  try {
    const res = await welfareExpenseService.updateVerifyWelfareExpense(
      formData
    );
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const verifyFlexCoinExpense = (formData) => async () => {
  try {
    const res = await welfareExpenseService.verifyFlexCoinExpense(formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const updateVerifyFlexCoinExpense = (formData) => async () => {
  try {
    const res = await welfareExpenseService.updateVerifyFlexCoinExpense(
      formData
    );
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const rejectFlexCoinExpense = (formData) => async () => {
  try {
    const res = await welfareExpenseService.rejectFlexCoinExpense(formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getFlexCoinExpenseHistory = () => async (dispatch) => {
  try {
    dispatch({
      type: WELFARE_BILLING_FETCHING,
    });
    const res = await welfareExpenseService.getFlexCoinExpenseHistory();
    if (res) {
      dispatch({
        type: WELFARE_BILLING_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: WELFARE_BILLING_FAILED,
    });
    console.log(err);
  }
};

export const getFilteredFlexCoinExpenseHistory = (data) => async (dispatch) => {
  try {
    dispatch({
      type: WELFARE_BILLING_FETCHING,
    });
    const res = await welfareExpenseService.getFilteredFlexCoinExpenseHistory(
      data
    );
    if (res) {
      dispatch({
        type: WELFARE_BILLING_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: WELFARE_BILLING_FAILED,
    });
    console.log(err);
  }
};

export const getWelfareBillingDetail =
  (idWelfareBilling) => async (dispatch) => {
    try {
      dispatch({
        type: WELFARE_BILLING_DETAIL_FETCHING,
      });
      const res = await welfareExpenseService.getWelfareBillingDetail(
        idWelfareBilling
      );
      if (res) {
        dispatch({
          type: WELFARE_BILLING_DETAIL_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: WELFARE_BILLING_DETAIL_FAILED,
      });
      console.log(err);
    }
  };

export const updateStatusWelfareBilling =
  (idEmployees, idWelfareBilling, data) => async () => {
    try {
      const res = await welfareExpenseService.updateStatusWelfareBilling(
        idEmployees,
        idWelfareBilling,
        data
      );
      if (res) {
        console.log(res);
        return res;
      }
    } catch (error) {
      return error;
    }
  };

export const updateVerifyWelfareBilling = (formData) => async () => {
  try {
    const res = await welfareExpenseService.updateVerifyWelfareBilling(
      formData
    );
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};
