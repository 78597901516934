import {
  USER_LEAVE_FETCHING,
  USER_LEAVE_FAILED,
  USER_LEAVE_SUCCESS,
  LEAVE_TYPE_FETCHING,
  LEAVE_TYPE_FAILED,
  LEAVE_TYPE_SUCCESS,
  LEAVEEMPLOYEES_WITHDRAW_FETCHING,
  LEAVEEMPLOYEES_WITHDRAW_SUCCESS,
  LEAVEEMPLOYEES_WITHDRAW_FAILED,
  LEAVE_3MONTH_FETCHING,
  LEAVE_3MONTH_FAILED,
  LEAVE_3MONTH_SUCCESS,
  LEAVE_YEAR_FETCHING,
  LEAVE_YEAR_FAILED,
  LEAVE_YEAR_SUCCESS,
  LEAVE_SETTING_FETCHING,
  LEAVE_SETTING_FAILED,
  LEAVE_SETTING_SUCCESS,
  LEAVE_AVAILABLE_FETCHING,
  LEAVE_AVAILABLE_FAILED,
  LEAVE_AVAILABLE_SUCCESS,
  LEAVELIST_FETCHING,
  LEAVELIST_FAILED,
  LEAVELIST_SUCCESS,
  LEAVE_REQUEST_LIST_FETCHING,
  LEAVE_REQUEST_LIST_FAILED,
  LEAVE_REQUEST_LIST_SUCCESS,
  MANAGER_PENDING_REQUEST_LEAVE_FETCHING,
  MANAGER_PENDING_REQUEST_LEAVE_FAILED,
  MANAGER_PENDING_REQUEST_LEAVE_SUCCESS,
  ADMIN_PENDING_REQUEST_LEAVE_FETCHING,
  ADMIN_PENDING_REQUEST_LEAVE_FAILED,
  ADMIN_PENDING_REQUEST_LEAVE_SUCCESS,
} from "./types";

import LeaveService from "../services/leave.service";

export const getAllLeaveBalanceByEmployeeId = (year) => async (dispatch) => {
  try {
    const res = await LeaveService.getAllLeaveBalanceByEmployeeId(year);
    if (res) {
      dispatch({
        type: USER_LEAVE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: USER_LEAVE_FAILED,
    });
    return err
  }
};

export const getAllLeaveEmployeesBy = (filter, search) => async (dispatch) => {
  try {
    const res = await LeaveService.getAllLeaveEmployeesBy(filter, search);
    if (res) {
      dispatch({
        type: USER_LEAVE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: USER_LEAVE_FAILED,
    });
    return err
  }
};

export const allLeaveEmployeeByManager = (search) => async (dispatch) => {
  try {
    const res = await LeaveService.allLeaveEmployeeByManager(search);
    if (res) {
      dispatch({
        type: USER_LEAVE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: USER_LEAVE_FAILED,
    });
    return err
  }
};

export const allLeaveType = (query) => async (dispatch) => {
  try {
    const res = await LeaveService.allLeaveType(query);
    if (res) {
      dispatch({
        type: LEAVE_TYPE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: LEAVE_TYPE_FAILED,
    });
    return err
  }
};

export const addLeaveType = (formData) => async (dispatch) => {
  try {
    // console.log(formData);
    const res = await LeaveService.addLeaveType(formData);
    if (res) {
      return res
    }
  } catch (error) {
    // console.log(error)
    if (error.response) {
      return error.response;
    }
  }
}

export const deleteLeaveType = (idLeaveType) => async (dispatch) => {
  try {
    // console.log(formData);
    const res = await LeaveService.deleteLeaveType(idLeaveType);
    if (res) {
      return res
    }
  } catch (error) {
    // console.log(error)
    if (error.response) {
      return error.response;
    }
  }
}

export const deleteLeaveGroup = (idLeaveGroup) => async (dispatch) => {
  try {
    // console.log(formData);
    const res = await LeaveService.deleteLeaveGroup(idLeaveGroup);
    if (res) {
      return res
    }
  } catch (error) {
    // console.log(error)
    if (error.response) {
      return error.response;
    }
  }
}

export const addLeaveEmployees = (formData) => async () => {
  try {
    const res = await LeaveService.addLeaveEmployees(formData);
    if (res) {
      return res
    }
  } catch (error) {
    console.log(error)
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error"
  }
};

export const updateApproveLeaveEmployeeBy = (filter, formData) => async () => {
  try {
    const res = await LeaveService.updateApproveLeaveEmployeeBy(filter, formData);
    if (res) {
      return res
    }
  } catch (error) {
    console.log(error)
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error"
  }
};

export const withdrawLeaveEmployee = (filter, formData) => async () => {
  try {
    const res = await LeaveService.withdrawLeaveEmployee(filter, formData);
    if (res) {
      return res
    }
  } catch (error) {
    console.log(error)
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error"
  }
};

export const uploadFileLeaveType = (formData) => async () => {
  try {
    const res = await LeaveService.uploadFileLeaveType(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response)
    if (error.response) {
      return error.response.data;
    }
  }
};

export const getAllLeaveWithDrawBy = (filter, search, idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: LEAVEEMPLOYEES_WITHDRAW_FETCHING,
    });
    const res = await LeaveService.getAllLeaveWithDrawBy(filter, search, idCompany);
    if (res) {
      dispatch({
        type: LEAVEEMPLOYEES_WITHDRAW_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LEAVEEMPLOYEES_WITHDRAW_FAILED,
    });
    console.log(err);
  }
};

export const getAllLeaveRequestWithDraw = (query) => async (dispatch) => {
  try {
    dispatch({
      type: LEAVEEMPLOYEES_WITHDRAW_FETCHING,
    });
    const res = await LeaveService.getAllLeaveRequestWithDraw(query);
    if (res) {
      dispatch({
        type: LEAVEEMPLOYEES_WITHDRAW_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LEAVEEMPLOYEES_WITHDRAW_FAILED,
    });
    console.log(err);
  }
};

export const uploadExcelLeaveEmployees = (formData) => async () => {
  try {
    const res = await LeaveService.uploadExcelLeaveEmployees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const getExcelDataLeaveTypeEmployee = () => async () => {
  try {
    const res = await LeaveService.getExcelDataLeaveTypeEmployee();
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const getAllLeaveEmployee3month = (start, end, id) => async (dispatch) => {
  try {
    dispatch({
      type: LEAVE_3MONTH_FETCHING,
    });
    const res = await LeaveService.getAllLeaveEmployee3month(start, end, id);
    if (res) {
      dispatch({
        type: LEAVE_3MONTH_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LEAVE_3MONTH_FAILED,
    });
    console.log(err);
  }
};

export const getAllLeaveEmployeeYear = (start, id) => async (dispatch) => {
  try {
    dispatch({
      type: LEAVE_YEAR_FETCHING,
    });
    const res = await LeaveService.getAllLeaveEmployeeYear(start, id);
    if (res) {
      dispatch({
        type: LEAVE_YEAR_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LEAVE_YEAR_FAILED,
    });
    console.log(err);
  }
};

export const getAllemployeeAbsentYear = (start, id) => async (dispatch) => {
  try {
    dispatch({
      type: LEAVE_YEAR_FETCHING,
    });
    const res = await LeaveService.getAllemployeeAbsentYear(start, id);
    if (res) {
      dispatch({
        type: LEAVE_YEAR_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LEAVE_YEAR_FAILED,
    });
    console.log(err);
  }
};

export const getAllLeaveV2 = (query) => async (dispatch) => {
  try {
    dispatch({
      type: LEAVE_TYPE_FETCHING,
    });
    const res = await LeaveService.getAllLeaveV2(query);
    if (res) {
      dispatch({
        type: LEAVE_TYPE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: LEAVE_TYPE_FAILED,
    });
    return err
  }
};

export const addLeaveQuota = (formData) => async () => {
  try {
    const res = await LeaveService.addLeaveQuota(formData);
    if (res) {
      return res
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
}

export const updateLeaveQuota = (idLeaveQuota, formData) => async () => {
  try {
    const res = await LeaveService.updateLeaveQuota(idLeaveQuota, formData);
    if (res) {
      return res
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
}

export const getLeaveSetting = () => async (dispatch) => {
  try {
    dispatch({
      type: LEAVE_SETTING_FETCHING,
    });
    const res = await LeaveService.getLeaveSetting();
    if (res) {
      dispatch({
        type: LEAVE_SETTING_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LEAVE_SETTING_FAILED,
    });
    console.log(err);
  }
};

export const getLeaveAvaiable = (query) => async (dispatch) => {
  try {
    dispatch({
      type: LEAVE_AVAILABLE_FETCHING,
    });
    const res = await LeaveService.getLeaveAvailable(query);
    if (res) {
      dispatch({
        type: LEAVE_AVAILABLE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LEAVE_AVAILABLE_FAILED,
    });
    console.log(err);
  }
};

export const getLeaveList = async (query) => {
  try {
    const res = await LeaveService.getLeaveList(query);
    if (res) {
      return res;
    }
  } catch (err) {
    console.error(err);
  }
};

// export const getLeaveRequestList = (query) => async (dispatch) => {
//   try {
//     dispatch({
//       type: LEAVE_REQUEST_LIST_FETCHING,
//     });
//     const res = await LeaveService.getLeaveRequestList(query);
//     if (res) {
//       dispatch({
//         type: LEAVE_REQUEST_LIST_SUCCESS,
//         payload: res.data,
//       });
//     }
//   } catch (err) {
//     dispatch({
//       type: LEAVE_REQUEST_LIST_FAILED,
//     });
//     console.log(err);
//   }
// };

export const getLeaveRequestList = (query) => async (dispatch) => {
  try {
    dispatch({
      type: LEAVE_REQUEST_LIST_FETCHING,
    });
    const res = await LeaveService.getLeaveRequestList(query);
    if (res) {
      dispatch({
        type: LEAVE_REQUEST_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LEAVE_REQUEST_LIST_FAILED,
    });
    console.log(err);
  }
};

export const getManagerPendingRequestLeave = (query) => async (dispatch) => {
  try {
    dispatch({
      type: MANAGER_PENDING_REQUEST_LEAVE_FETCHING,
    });
    const res = await LeaveService.getManagerPendingRequestLeave(query);
    if (res) {
      dispatch({
        type: MANAGER_PENDING_REQUEST_LEAVE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MANAGER_PENDING_REQUEST_LEAVE_FAILED,
    });
    console.log(err);
  }
};

export const approveRequestLeaveByManager = (formData) => async () => {
  try {
    const res = await LeaveService.approveRequestLeaveByManager(formData);
    return res
  } catch (error) {
    return error.response;
  }
}

export const getAdminPendingRequestLeave = (query) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_PENDING_REQUEST_LEAVE_FETCHING,
    });
    const res = await LeaveService.getAdminPendingRequestLeave(query);
    if (res) {
      dispatch({
        type: ADMIN_PENDING_REQUEST_LEAVE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ADMIN_PENDING_REQUEST_LEAVE_FAILED,
    });
    console.log(err);
  }
};

export const approveRequestLeaveByAdmin = (formData) => async () => {
  try {
    const res = await LeaveService.approveRequestLeaveByAdmin(formData);
    return res
  } catch (error) {
    return error.response;
  }
}
