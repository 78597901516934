import React, { useEffect, useState, Fragment } from "react";
import { styled } from "@mui/material/styles";

import { Typography, Box } from "@mui/material";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

import SuccessIcon from "../../../../assets/approved.png";
import ErrorIcon from "../../../../assets/rejected.png";
import CancelIcon from "../../../../assets/cancel.png";

const StyledWrapErrorResponse = styled("div")({
  maxHeight: 580,
  maxWidth: 800,
  margin: "auto",
  marginTop: 36,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  "& .total": {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: 36,
    "& .success, .error": {
      display: "flex",
      alignItems: "center",
      "& .MuiTypography-h6": {
        fontWeight: 600,
        lineHeight: 1,
      },
      "& img": {
        marginRight: 8,
      },
    },
  },
  "& .MuiBox-root": {
    height: 450,
    overflow: "auto",
    borderRadius: 8,
    marginBottom: 16,

    "& .MuiTypography-root": {
      "&.name-error": {
        color: "#EC0013",
        fontWeight: 600,
      },
      "&.MuiTypography-gutterBottom": {
        marginBottom: 8,
      },
    },
  },
});

const ShowResponse = (props) => {
  const { errorResponse, handleCloseLoading } = props;
  return (
    <StyledWrapErrorResponse>
      {errorResponse && (
        <div style={{ marginBottom: 36, textAlign: "center" }}>
          <img src={CancelIcon} width="60" style={{ marginBottom: 16 }} />
          <Typography gutterBottom variant="h5" align="center">
            {errorResponse}
          </Typography>
        </div>
      )}

      <div>
        <ButtonBlue variant="contained" onClick={handleCloseLoading}>
          ปิด
        </ButtonBlue>
      </div>
    </StyledWrapErrorResponse>
  );
};

export default ShowResponse;
