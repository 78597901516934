import React, { useEffect, useState } from "react";
import { Chip, Grid, IconButton, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import DrawerDownload from "./drawerDownload";
import { useDispatch, useSelector } from "react-redux";
import { getAdditionOvertime } from "../../../../../../actions/additionSpecial";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";
import additionSepecialService from "../../../../../../services/additionSepecial.service";
import AdditionOvertimeForm from "./additionOvertimeForm";

const AdditionOvertime = ({ selectedCompany }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: additionPersonal } = useSelector(
    (state) => state.additionPersonal
  );
  const [openEdit, setOpenEdit] = useState(false);
  const [selectAddition, setSelectAddition] = useState(null);
  const [openDownload, setOpenDownload] = useState(false);

  const handleOpenDownload = () => {
    setOpenDownload(true);
  };

  const handleCloseDownload = () => {
    setOpenDownload(false);
  };

  const handleOpenAdd = () => {
    setOpenEdit(true);
  };

  const handleOpenEdit = (item) => {
    setSelectAddition(item);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setSelectAddition(null);
    setOpenEdit(false);
  };

  const handleDownload = async (data) => {
    const res = await additionSepecialService.exportExcelAdditionOvertime(
      data.additionSpecial.idAdditionOvertime,
      { searchDate: dayjs(data.searchDate).format("YYYY-MM-DD")}
    );

    if (res && res.status === 200) {
      const excelBlob = new Blob([res.data], {
        type: res.headers["content-type"],
      });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(excelBlob);

      downloadLink.download = `${data.additionSpecial.name} ${res.headers["content-disposition"]
        .split("filename")[1]
        .slice(2, -1)}`;

      downloadLink.click();
    } else {
      dispatch(openNotificationAlert({ message: "error", type: "error" }));
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getAdditionOvertime(selectedCompany.idCompany));
    }
  }, [selectedCompany.idCompany]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        minHeight={"40px"}
        marginBottom={"24px"}
      >
        <Typography
          sx={{
            paddingLeft: "8px",
            borderLeft: "8px solid #46cbe2",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
          }}
          fontSize="20px"
          fontWeight="500"
        >
          {t("AdditionOvertime")}
        </Typography>
        {!openEdit && (
          <div style={{ display: "flex", gap: "8px" }}>
            <IconButton
              onClick={handleOpenAdd}
              sx={{
                color: "#ffffff",
                backgroundColor: "#1976d2",
                "&:hover": { backgroundColor: "#145ea8" },
              }}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              onClick={handleOpenDownload}
              sx={{
                color: "#ffffff",
                backgroundColor: "#1976d2",
                "&:hover": { backgroundColor: "#145ea8" },
              }}
            >
              <DownloadIcon />
            </IconButton>
          </div>
        )}
      </Stack>
      {openEdit && (
        <AdditionOvertimeForm
          handleClose={handleCloseEdit}
          selectedCompany={selectedCompany}
          selectAddition={selectAddition}
        />
      )}
      {!openEdit && additionPersonal && (
        <Stack gap="16px">
          {additionPersonal.map((addition) => (
            <div
              key={addition.idAdditionOvertime}
              style={{
                padding: "16px",
                borderRadius: "8px",
                backgroundColor: "#f2f2f2",
              }}
            >
              <Grid container alignItems="center" rowGap="16px">
                <Grid item xs={12} md={5}>
                  <Typography variant="h5">{addition.name}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography fontWeight={500}>{`${t("Time")} (${t(
                    "Unit.Minute"
                  )})`}</Typography>
                  <Typography fontSize={18}>{addition.minutes}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography fontWeight={500}>{t("AmountOfMoney")}</Typography>
                  <Typography fontSize={18}>{addition.value}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={1}>
                  <IconButton
                      onClick={() => handleOpenEdit(addition)}
                      sx={{
                        color: "#ffffff",
                        backgroundColor: "#ffb74d",
                        "&:hover": { backgroundColor: "#ffa726" },
                      }}
                      size="small"
                    >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          ))}
        </Stack>
      )}
      {additionPersonal && openDownload && (
        <DrawerDownload
          openDownload={openDownload}
          handleDownload={handleDownload}
          handleCloseDownload={handleCloseDownload}
          additionSpecial={additionPersonal}
          idCompareOption={"idAdditionOvertime"}
        />
      )}
    </>
  );
};

export default AdditionOvertime;
