import React from "react";
import { Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import utils from "../../../../../../utils";
import { useWatch } from "react-hook-form";

const Left = ({ control }) => {
  const title = useWatch({ control, name: "title" })
  const firstname = useWatch({ control, name: "firstname" })
  const lastname = useWatch({ control, name: "lastname" })
  const phoneNumber = useWatch({ control, name: "phoneNumber" })
  const email = useWatch({ control, name: "email" })
  const dateOfBirth = useWatch({ control, name: "dateOfBirth" })
  const personalId = useWatch({ control, name: "personalId" })

  const jobGroupSelect = useWatch({ control, name: "jobGroupSelect" })
  const workExperience = useWatch({ control, name: "workExperience" })
  const workExperienceM = useWatch({ control, name: "workExperienceM" })
  const educationDegree = useWatch({ control, name: "educationDegree" })
  const currentSalary = Number(useWatch({ control, name: "currentSalary" })) 
  const expectedSalary = Number(useWatch({ control, name: "expectedSalary" }))

  const renderAge = (totalMonth) => {
    const year = Math.floor(totalMonth / 12);
    const month = (totalMonth % 12)
    return `${year} ปี ${month} เดือน`
  }

  return (
    <Paper elevation={0} sx={{ height: "100%", padding: "8px 16px", backgroundColor: "#F9F9FB" }}>
      <Typography fontSize={20} marginTop={2} paddingBottom="24px" sx={{ color:"#000000" }}>{`คนของฉันที่จะถูกเพิ่ม..`}</Typography>
      {(firstname || lastname) && <Typography variant="h5" paddingBottom="16px" sx={{ color:"#000000" }}>{title} {firstname} {lastname}</Typography>}
      {phoneNumber && <Typography variant="h5" paddingBottom="16px" fontSize={16} sx={{ color:"#000000" }}><span>เบอร์โทร :</span> {phoneNumber}</Typography>}
      {email && <Typography variant="h5" paddingBottom="16px" fontSize={16} sx={{ color:"#000000", wordWrap: "break-word" }}><span>อีเมล :</span> {email}</Typography>}
      {!dayjs().isSame(dateOfBirth, 'day') && <Typography variant="h5" paddingBottom="16px" fontSize={16} sx={{ color:"#000000" }}><span>อายุ :</span> {renderAge(dayjs(new Date()).diff(dateOfBirth, 'month'))}</Typography>}
      {personalId && <Typography variant="h5" paddingBottom="16px" fontSize={16} sx={{ color:"#000000" }}><span>เลขบัตรประชาชน :</span> {personalId}</Typography>}
      {jobGroupSelect.length > 0 && 
        <Typography variant="h5" paddingBottom="16px" fontSize={16} sx={{ color:"#000000" }}>
          <span>ความเชี่ยวชาญ :</span> {jobGroupSelect.map(item => item.jobGroupName).join(', ')}
        </Typography>
      }
      {(workExperience || workExperienceM) && <Typography variant="h5" paddingBottom="16px" fontSize={16} sx={{ color:"#000000" }}><span>ประสบการณ์ทำงาน :</span> {workExperience && `${workExperience} ปี`} {workExperienceM && `${workExperienceM} เดือน`}</Typography>}
      {educationDegree && <Typography variant="h5" paddingBottom="16px" fontSize={16} sx={{ color:"#000000" }}><span>ระดับการศึกษาสูงสุด :</span> {educationDegree}</Typography>}
      {currentSalary > 0 && <Typography variant="h5" paddingBottom="16px" fontSize={16} sx={{ color:"#000000" }}><span>เงินเดือนปัจจุบัน :</span> {utils.numberWithCommas(currentSalary)} บาท</Typography>}
      {expectedSalary > 0 && <Typography variant="h5" paddingBottom="16px" fontSize={16} sx={{ color:"#000000" }}><span>เงินเดือนที่คาดหวัง :</span> {utils.numberWithCommas(expectedSalary)} บาท</Typography>}
    </Paper>
  )
}

export default Left