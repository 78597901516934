import React from "react";
import { useSelector } from "react-redux";

import AccountantDetailExpense from "../../admin-accountant/expense/detail-view";
import ManagerDetailExpense from "../../manager/expense/detail-view";

const ExpenseDetailPage = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <div>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ACCOUNT") ? (
          <AccountantDetailExpense />
        ) : (
          <ManagerDetailExpense />
        )
      ) : null}
    </div>
  );
};

export default ExpenseDetailPage;
