import React, { useState, useEffect } from "react";
import {
    Calendar as BigCalendar,
    momentLocalizer,
} from "react-big-calendar";
import moment from "moment";
import { Avatar, Box, Button, FormControlLabel, IconButton, Stack, styled, Switch, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import "moment/locale/th";
import "moment/locale/zh-cn";
import "moment/locale/ja";
import "moment/locale/km";
import "moment/locale/ko";
import "moment/locale/lo";
import "moment/locale/my";
import "moment/locale/vi";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb': {
                backgroundColor: '#e6b8f2',
            },
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('/assets/icon/cake.png')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#e6e6e6' : '#e6e6e6',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#5d4789',
        width: 32,
        height: 32,
        '&::before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('/assets/icon/leave.png')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#e6e6e6' : '#e6e6e6',
        borderRadius: 20 / 2,
    },
}));

const localizer = momentLocalizer(moment);

const locales = {
    th: 'th',
    en: 'en',
    cn: 'zh-cn',
    jp: 'ja',
    kh: 'km',
    kr: 'ko',
    la: 'lo',
    mm: 'my',
    vn: 'vi',
};

export default function Calendar(props) {
    const { t, events, i18n, birthdayList } = props;
    const [locale, setLocale] = useState('th');
    const [currentView, setCurrentView] = useState('month');
    const [date, setDate] = useState(moment().startOf('month').toDate());
    const [isActive, setIsActive] = useState(false);

    const goToToday = () => {
        setDate(new Date());
        setCurrentView('month');
    };

    const goToBack = () => {
        setDate(moment(date).subtract(1, currentView === 'month' ? 'month' : 'day').toDate());
    };

    const goToNext = () => {
        setDate(moment(date).add(1, currentView === 'month' ? 'month' : 'day').toDate());
    };

    const toggleAgendaView = () => {
        setCurrentView(prevView => (prevView === 'month' ? 'agenda' : 'month'));
        setDate(moment(date).startOf('month').toDate());
    };

    const handleSwitchChange = (event) => {
        setIsActive(event.target.checked);
    };

    const CustomToolbar = ({ label }) => {
        const formattedDate =
            currentView !== 'day'
                ? moment(date).locale(locale).format('MMMM YYYY')
                : moment(date).locale(locale).format('Do MMMM YYYY');

        return (
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 2, sm: 0 } }}>
                <ButtonBlue style={{ backgroundColor: '#5d4789' }} variant="contained" onClick={goToToday}>
                    {t('calendar.today')}
                </ButtonBlue>
                <Box display="flex" alignItems="center" sx={{ flexDirection: 'row' }}>
                    <IconButton onClick={goToBack}>
                        <ExpandMoreIcon sx={{ transform: 'rotate(90deg)' }} />
                    </IconButton>
                    <Typography textAlign='center' variant="h6">{formattedDate}</Typography>
                    <IconButton onClick={goToNext}>
                        <ExpandMoreIcon sx={{ transform: 'rotate(-90deg)' }} />
                    </IconButton>
                </Box>
                <Box display="flex" alignItems="center" sx={{ flexDirection: 'row' }}>
                    <FormControlLabel
                        control={
                            <Stack direction="column" alignItems="center">
                                <MaterialUISwitch
                                    checked={isActive}
                                    onChange={handleSwitchChange}
                                    color="primary"
                                />
                            </Stack>
                        }
                        label=""
                        sx={{
                            marginLeft: { xs: 0, sm: 'auto' },
                            marginRight: { xs: 0, sm: 'auto' },
                        }}
                    />
                    <ButtonBlue style={{ backgroundColor: '#5d4789' }} variant="contained" onClick={toggleAgendaView}>
                        {currentView === 'month' ? t('calendar.agenda') : t('calendar.month')}
                    </ButtonBlue>
                </Box>
            </Box>
        );
    };

    const EventComponentDay = ({ event }) => {
        return (
            <Box display="flex" alignItems="center" flexDirection="row">
                {event.holiday ? <Typography variant="body2">{event.title}</Typography> :
                    <>
                        <Avatar title={event.title} src={event.img || "https://example.com/avatar1.jpg"} alt={event.title} sx={{ width: 30, height: 30, marginRight: 0.5, pointerEvents: 'visible' }} />
                        <Typography variant="body2">{isActive ? event.title || '' : event.name || ''}</Typography>
                    </>
                }
            </Box>
        );
    };

    const EventComponentMonth = ({ events }) => {
        const eventsToShow = currentView === 'month' ? events.slice(0, 2) : events;
        return (
            <Box display="flex" alignItems="flex-start" flexDirection="row" padding="10px 0 15px">
                {eventsToShow.map((event, index) => (
                    <Box key={index} display="flex" alignItems="center" mb={0}>
                        <Avatar title={event.title} src={event.img || "https://example.com/avatar1.jpg"} alt={event.title} sx={{ width: 30, height: 30, marginRight: -0.5, boxShadow: 1, pointerEvents: 'visible' }} />
                    </Box>
                ))}
                {events.length > 2 && currentView === 'month' && (
                    <Box display="flex" alignItems="center" mb={0}>
                        <Avatar
                            sx={{
                                bgcolor: 'grey.400',
                                width: 30,
                                height: 30,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                pointerEvents: 'auto',
                            }}
                            onClick={() => {
                                const eventDate = events[0].start;
                                setDate(eventDate);
                                setCurrentView('day');
                            }}
                        >
                            {events.length - 2}+
                        </Avatar>
                    </Box>
                )}
            </Box>
        );
    };

    const messages = {
        today: t('calendar.today'),
        previous: t('calendar.previous'),
        next: t('calendar.next'),
        month: t('calendar.month'),
        week: t('calendar.week'),
        day: t('calendar.day'),
        agenda: t('calendar.agenda'),
        noEventsInRange: t('calendar.noEventsInRange'),
    };

    const updateLocale = (lang) => {
        const newLocale = locales[lang] || 'th';
        setLocale(newLocale);
        moment.locale(newLocale);
    };

    useEffect(() => {
        updateLocale(i18n.resolvedLanguage);
    }, [i18n.resolvedLanguage]);

    const mergedEvents = isActive && birthdayList && birthdayList.length > 0 ? birthdayList : events;

    return (
        <BigCalendar
            {...props}
            localizer={localizer}
            events={mergedEvents}
            views={['month', 'agenda', 'day']}
            view={currentView}
            messages={messages}
            defaultView={currentView}
            onView={view => {
                setCurrentView(view);
                if (view === 'month') {
                    setDate(moment().startOf('month').toDate());
                }
            }}
            onNavigate={newDate => {
                setDate(moment(newDate).toDate());
            }}
            date={date}
            components={{
                toolbar: CustomToolbar,
                event: ({ event }) => {
                    const sameDayEvents = isActive
                        ? birthdayList.filter(e => moment(e.start).isSame(event.start, 'day'))
                        : events.filter(e => moment(e.start).isSame(event.start, 'day'));

                    return currentView === 'month' ? <EventComponentMonth events={sameDayEvents} /> : <EventComponentDay event={event} />;
                },
            }}
            eventPropGetter={(event) => {
                let backgroundColor;
                switch (event.status) {
                    case 'Absent':
                        backgroundColor = '#FA9696';
                        break;
                    case 'Late':
                        backgroundColor = '#FFB680';
                        break;
                    case 'EarlyOut':
                        backgroundColor = '#FFB680';
                        break;
                    default:
                        backgroundColor = '#fecf81';
                }

                return {
                    style: {
                        backgroundColor,
                    },
                };
            }}
            style={{ height: 500, width: '100%' }}
        />
    );
}
