import { httpClient } from "./httpClient";

const getDataEmployee = () => {
  return httpClient.get(`dataEmployeeExpense`);
};

const getDataWelfare = () => {
  return httpClient.get(`dataWelfareExpense`);
};

export default {
  getDataEmployee,
  getDataWelfare,
};
