import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import utils from "../../../../../../utils";

const StyledDiv = styled("div")({
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const StyledCard = styled(Card)(({ }) => ({
  borderRadius: 8,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "900px",
  width: "100%",
  "& .MuiCardHeader-root": {
    padding: 24,
  },
  // margin: "16px 0",
}));

const StyledTable = styled(TableContainer)({
  borderRadius: '6px',
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#46cbe2",
    color: "#ffffff",
    fontSize: 16,
    textAlign: "center",
    padding: "18px",
  },

  "& .tableCellLable": {
    textAlign: "left",
  },

  "& .tableCellRegular": {
    textAlign: "center",
    fontWeight: "500",
  },

  "& .tableCellIrregular": {
    textAlign: "center",
    fontWeight: "500",
  },

  "& .green": {
    color: "#43a047",
    fontWeight: "700",
    textDecoration: "underline",
  },

  "& .blue": {
    color: "#3589e5",
    fontWeight: "600",
    textDecoration: "underline",
  },

  "& .red": {
    color: "#e53935",
    fontWeight: "600",
    textDecoration: "underline",
  },

  "& .purple": {
    color: "#9135e5",
    fontWeight: "600",
    textDecoration: "underline",
  },

  "& .orange": {
    color: "#e59135",
    fontWeight: "600",
    textDecoration: "underline",
  },

  "& .pink": {
    color: "#e53589",
    fontWeight: "600",
    textDecoration: "underline",
  },
});


const StyledTableSummaryRow = (props) => {
  const { label, data, month, color } = props;
  const { t, i18n } = useTranslation();

  return (
    <TableRow>
      <TableCell className={`tableCellLable`}>{t(label)}</TableCell>
      <TableCell className={`tableCellIrregular ${color}`} colSpan={2}>
        {utils.numberWithCommas(data)}
      </TableCell>
    </TableRow>
  );
};

const PersonalTaxInfo = (props) => {
  const { personalTax } = props;
  const { t, i18n } = useTranslation();

  return (
    <>
      {personalTax && personalTax.regular && personalTax.irregular && (
        <StyledDiv>
          <StyledCard>
            <StyledTable>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={`tableCellHead`}>
                      {t("List")}
                    </TableCell>
                    <TableCell className={`tableCellHead`}>
                      {t("Amount")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  <StyledTableSummaryRow
                    label="EstimatedThisYearTax"
                    data={personalTax.estimatedTaxThisYear}
                    color="orange"
                  />

                  <StyledTableSummaryRow
                    label="PaidTax"
                    data={personalTax.paidTax}
                    color="red"
                  />

                  <TableRow>
                    <TableCell className={`tableCellLable`}>
                      {t("RemainningTax")}
                    </TableCell>
                    <TableCell className={`tableCellIrregular blue`}>
                      {utils.numberWithCommas(personalTax.remainningTax)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={`tableCellLable`}>
                      {t("RemainningMonth")}
                    </TableCell>
                    <TableCell className={`tableCellIrregular `}>
                      {personalTax.remainingMonth}
                    </TableCell>
                  </TableRow>
                  {personalTax.remainingRestYear &&
                    personalTax.remainingRestYear.map((element, index) => (
                      <TableRow key={index}>
                        <TableCell className={`tableCellLable`}>
                          {t("TaxToBePaidMonth")} {t(`Months.${element.month}`)}
                        </TableCell>
                        <TableCell className={`tableCellIrregular orange`}>
                          {utils.numberWithCommas(element.value)}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </StyledTable>
          </StyledCard>

        </StyledDiv>
      )}
    </>
  );
};

export default PersonalTaxInfo;
