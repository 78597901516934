import React from "react";
import dayjs from "dayjs";
import EventIcon from "@mui/icons-material/Event";

// Components
import { Grid, Typography, IconButton, Box } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiGrid-container": {
      marginBottom: "0 !important",
    },
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
  },
  PlanList_Status: {
    // display: 'flex',
    alignItems: "center",
    color: "#9e9e9e",
    marginBottom: 8,
  },
  inProgress: {
    backgroundColor: "#eeeeee",
    color: "#9e9e9e",
    width: "100px",
    borderRadius: "15px",
    padding: "5px 10px 5px 10px",
    textAlign: "center",
    marginLeft: 8,
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  achieved: {
    backgroundColor: "#e0f2f1",
    color: "#009688",
    width: "90px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    marginLeft: 8,
    "&:hover": {
      backgroundColor: "#e0f2f1",
    },
  },
  notAchieved: {
    backgroundColor: "#f9dde0",
    color: "#c62828",
    width: "110px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    marginLeft: 8,
    "&:hover": {
      backgroundColor: "#f9dde0",
    },
  },
}));

export default function ShowMiniData({ date, status, progress }) {
  const classes = useStyles();

  return (
    <Grid container alignItems="center">
      <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
        <Typography
          variant="subtitle1"
          className={classes.PlanList_Status}
          style={{ display: "flex" }}
        >
          <EventIcon style={{ marginRight: "5px" }} />
          {date && (
            <Box style={{ marginRight: "10px" }}>
              {dayjs(date).format("DD/MM/YYYY")}
            </Box>
          )}
        </Typography>
      </Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
        <Typography variant="subtitle1" className={classes.PlanList_Status}>
          Status :{" "}
          <IconButton
            className={
              status === "Approved"
                ? `${classes.achieved}`
                : status === "Not Approved"
                ? `${classes.notAchieved}`
                : `${classes.inProgress}`
            }
            size="large"
          >
            <Typography style={{ fontWeight: 600 }} variant="caption">
              {status === null ? "Waiting Approved" : status}
            </Typography>
          </IconButton>
        </Typography>
      </Grid>
      {progress != "" ? (
        <Grid item xl={7} lg={7} md={5} sm={6} xs={12}>
          <Typography variant="subtitle1" className={classes.PlanList_Status}>
            Progress :{" "}
            <IconButton
              className={
                progress === "Achieved"
                  ? `${classes.achieved}`
                  : progress === "Not Achieved"
                  ? `${classes.notAchieved}`
                  : `${classes.inProgress}`
              }
              size="large"
            >
              <Typography style={{ fontWeight: 600 }} variant="caption">
                {progress !== null ? progress : "In Progress"}
              </Typography>
            </IconButton>
          </Typography>
        </Grid>
      ) : (
        <Grid item />
      )}
    </Grid>
  );
}
