import React from "react"
import Typography from "@mui/material/Typography";

const Notification = () => {
    return (
        <div className="page">
      <Typography>Notification</Typography>
    </div>
    )
}

export default Notification;