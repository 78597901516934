import React from "react";
import { useSelector } from "react-redux";

import UserSummary from "../user/summaryTimeAndSalary";
import Adminsummary from "../admin/summaryTime";

const SummaryTimePage = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <div>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ? (
          <Adminsummary />
        ) : (
          <UserSummary />
        )
      ) : null}
    </div>
  );
};

export default SummaryTimePage;
