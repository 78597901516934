import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "dayjs";
import * as XLSX from "xlsx";

import {
  Alert,
  Container,
  Divider,
  IconButton,
  Snackbar,
  Tooltip,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import HeaderPage from "./../../shared/header/headerPage";
import ButtonBlue from "../../shared/general/ButtonBlue";

import EmployeeList from "./employeeList";

import iconHeader from "./assets/teamwork.svg";

import DialogDownload from "./DialogDownload";
import DialogUpload from "./DialogUpload";

import "./index.css";
import { getAllEmployees } from "../../../../actions/employee";
import { getDepartment } from "../../../../actions/department";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import EmployeeSearchPage from "./employeeSeach";

const useStyles = makeStyles(() => ({
  wrapHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // ["@media only screen and (max-width: 600px)"]: {
    //   "& .MuiTypography-root": {
    //     fontSize: 26,
    //   },
    // },
    // ["@media only screen and (min-width:600px)"]: {
    //   "& .MuiTypography-root": {
    //     fontSize: 26,
    //   },
    // },
    // ["@media only screen and (min-width:768px)"]: {
    //   "& .MuiTypography-root": {
    //     fontSize: 34,
    //   },
    // },
  },
  btnAddNew: {
    ["@media only screen and (max-width: 600px)"]: {
      display: "none",
    },
  },
  btnIconAddNew: {
    ["@media only screen and (min-width:600px)"]: {
      display: "none",
    },
  },

  divider: {
    margin: "10px 0",
  },
  wrapFilterStatusEmployee: {
    marginTop: 16,
  },
  btnFilterStatusEmployee: {
    "& button": {
      borderRadius: 0,
    },
    "& .MuiToggleButton-root": {
      border: "none",
    },
  },
  wrapFilter: {
    margin: "16px 0",
  },
  formControl: {
    width: "100%",
  },
  avatar: {
    width: 60,
    height: 60,
    marginRight: 8,
  },
  wrapName: {
    width: 350,
  },
  textName: {
    fontSize: 18,
  },
  textPosition: {},
  statusTag: {
    height: 22,
    minWidth: 22,
    borderRadius: 8,
    alignItems: "center",
    whiteSpace: "nowrap",
    display: "inline-flex",
    justifyContent: "center",
    padding: "0px 8px",
  },
  statusTagActive: {
    color: "rgb(34, 154, 22)",
    backgroundColor: "rgba(84, 214, 44, 0.16)",
  },
  statusTagTerminate: {
    color: "rgb(183, 33, 54)",
    backgroundColor: "rgba(255, 72, 66, 0.16)",
  },
  statusTagLabel: {
    lineHeight: 0,
    fontWeight: 700,
    fontSize: 14,
    textTransform: "capitalize",
  },
  wrapFirstColumn: {
    display: "flex",
    alignItems: "center",
  },
  textOverFlow: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  sectionAbout: {
    width: 420,
    display: "flex",
    justifyContent: "space-around",
  },
  labelAbout: {
    fontSize: 12,
  },
  textAbout: {
    fontSize: 14,
  },
  iconAction: {
    marginRight: 16,
  },
  smallScreen: {},
}));

const StyledIconButtonUpload = styled(IconButton)({
  color: "#46cbe2",
  padding: 6,
  marginRight: 8,
});

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .wrap-header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  "& .wrap-action-btn": {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
    "& .download, .upload": {
      marginRight: 16,
    },
  },
});

const EmployeesPage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [mode, setMode] = useState("list");
  const [excelDataCompany, setExcelDataCompany] = useState(null);
  const [openDialogUpload, setOpenDialogUpload] = useState(false);
  const [openDialogDownload, setOpenDialogDownload] = useState(false);
  const [statusResult, setStatusResult] = useState({
    isOpen: false,
    result: null,
    label: "",
  });

  const toggleDrawerDownload = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDialogDownload(open);
  };

  const handleOpenDialogUpload = () => {
    setOpenDialogUpload(true);
  };

  const handleCloseDialogUpload = () => {
    setOpenDialogUpload(false);
  };

  const handleCloseStatus = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setStatusResult({ isOpen: false, result: null, label: "" });
  };

  // useEffect(() => {
  //   dispatch(getAllEmployees());
  //   //dispatch(getDepartment());
  // }, []);

  return (
    <StyledRoot className={`page`}>
      <Container
        maxWidth="lg"
        style={{ paddingTop: "24px", paddingBottom: "24px" }}
      >
        <Fragment>
          <div className={`wrap-header`}>
            <Fragment>
              <HeaderPage
                textLabel={`${t("EmployeeList")}`}
                icon={iconHeader}
              />
              <div className="wrap-action-btn">
                <Tooltip title={`${t("Download")}`}>
                  <ButtonBlue
                    className="download"
                    variant="outlined"
                    aria-label="download"
                    onClick={toggleDrawerDownload(true)}
                    startIcon={<FileDownloadIcon />}
                  >
                    {t("Download")}
                  </ButtonBlue>
                </Tooltip>
                <Tooltip title={`${t("Upload")}`}>
                  <ButtonBlue
                    className="upload"
                    variant="outlined"
                    aria-label="upload"
                    onClick={handleOpenDialogUpload}
                    startIcon={<FileUploadIcon />}
                  >
                    {t("Upload")}
                  </ButtonBlue>
                </Tooltip>
                <div className={classes.btnAddNew}>
                  <ButtonBlue
                    variant={"contained"}
                    component={NavLink}
                    to="/admin/employees/add"
                    startIcon={<AddIcon />}
                  >
                    {t("AddEmployee")}
                  </ButtonBlue>
                </div>
              </div>
              <div className={classes.btnIconAddNew}>
                <IconButton aria-label="add" size="large">
                  <AddIcon />
                </IconButton>
              </div>
            </Fragment>
          </div>


          <DialogUpload
            open={openDialogUpload}
            handleClose={handleCloseDialogUpload}
            setStatusResult={setStatusResult}
            isReadOnly={userProfile && userProfile.readOnly}
          />
          <DialogDownload
            open={openDialogDownload}
            toggleDrawer={toggleDrawerDownload}
            company={excelDataCompany}
          />

          {/* {mode == "list" && (
            <EmployeeList setExcelDataCompany={setExcelDataCompany} />
          )} */}

          <EmployeeSearchPage setExcelDataCompany={setExcelDataCompany} />

          {/* {statusResult.isOpen && (
            <Snackbar
              open={statusResult.isOpen}
              autoHideDuration={3000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleCloseStatus}
            >
              <Alert severity={statusResult.result} onClose={handleCloseStatus}>
                {statusResult.label}
              </Alert>
            </Snackbar>
          )} */}
        </Fragment>
      </Container>
    </StyledRoot>
  );
};

export default EmployeesPage;
