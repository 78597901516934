import React, { useState, useEffect, Fragment, useRef } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import DialogSetting from "./dialogSetting";
import ButtonBlue from "../../../shared/general/ButtonBlue";
//Translator TH-EN
import { useTranslation } from "react-i18next";

export default function SplitButton(props) {
  const { onExportExcel, excel, setOpenDrawer } = props;
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const options = [`${t("Download")}`, `${t("Setting")}`];

  const handleClick = () => {
    if (selectedIndex == 0) {
      onExportExcel();
    } else {
      setOpenDrawer(true);
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

    return (
        <Fragment>
            <ButtonGroup ref={anchorRef} aria-label="split button">
                <ButtonBlue variant="outlined"
                    onClick={handleClick}>{options[selectedIndex]}</ButtonBlue>
                <ButtonBlue
                    size="small"
                    variant="outlined"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </ButtonBlue>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 3,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'center top',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            disabled={index === 0 && excel === null}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Fragment>

    );
}
