import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import {
  Grid,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";

import {
  getRequestReason,
  deleteRequestReason,
} from "../../../../../actions/requestTime";

import AlertResponse from "../../../shared/general/AlertResponse";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DialogConfirmDelete from "../../../shared/general/DialogConfirmDelete";
import DrawerReason from "./drawerReason";
import { useTranslation } from "react-i18next";

const Root = styled("div")({
  marginTop: "30px",
  "& .MuiTypography-h6": {
    fontSize: "1.5rem",
  },
  "& .topic": {
    fontSize: "1.25rem",
  },
});

const StyledGridTopic = styled(Grid)({
  margin: "16px 0px",
});

const StyledTableContainer = styled(TableContainer)({
  "& .text": {
    fontSize: "16px",
  },
});

const Reason = (props) => {
  const { selectedCompany } = props;
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [listRequestTimeReasons, setListRequestTimeReasons] = useState([]);
  const [listOTReasons, setListOTReasons] = useState([]);
  const [statusDrawer, setStatusDrawer] = useState({
    isOpen: false,
    type: null,
    reason: null,
  });
  const [statusDialog, setStatusDialog] = useState({
    isOpen: false,
    reason: null,
  });
  const [statusAlert, setStatusAlert] = useState({
    isOpen: false,
    type: null,
  });

  useEffect(async () => {
    if (userProfile && selectedCompany) {
      renderRequestReason();
    }
  }, [userProfile, selectedCompany]);

  const renderRequestReason = async () => {
    const result = await dispatch(
      getRequestReason({ idCompany: selectedCompany.idCompany })
    );
    if (result) {
      let requestTimes = [];
      let OTs = [];
      result.map((reason) => {
        if (reason.idRequestType === 1) {
          requestTimes.push(reason);
        } else if (reason.idRequestType === 2) {
          OTs.push(reason);
        }
      });
      setListRequestTimeReasons(requestTimes);
      setListOTReasons(OTs);
    } else {
      setListRequestTimeReasons([]);
      setListOTReasons([]);
    }
  };

  const handleOnCloseDrawer = () => {
    setStatusDrawer({
      isOpen: false,
      type: null,
      reason: null,
    });
  };

  const handleOpenAlert = (type) => {
    setStatusAlert({
      isOpen: true,
      type: type,
    });
  };

  return (
    <Root>
      <Typography variant="h6">{t("ManageReasons")}</Typography>
      <div>
        <StyledGridTopic container justifyContent="space-between">
          <Grid item>
            <Typography className="topic">
              {t("ReasonsForWorkingTimeRequest")}
            </Typography>
          </Grid>
          <Grid item>
            <ButtonBlue
              variant="contained"
              onClick={() => {
                setStatusDrawer({
                  isOpen: true,
                  type: "requestTime",
                  reason: null,
                });
              }}
            >
              {t("AddReason")}
            </ButtonBlue>
          </Grid>
        </StyledGridTopic>
        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    {t("Reasons")}
                  </Typography>
                </TableCell>
                <TableCell style={{ width: "184px" }}></TableCell>
                <TableCell
                  style={{
                    width: "88px",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listRequestTimeReasons.length > 0 ? (
                listRequestTimeReasons.map((reason) => (
                  <TableRow key={reason.idRequestReason}>
                    <TableCell className="text">
                      {i18n.resolvedLanguage === "th"
                        ? reason.name
                        : reason.name_EN
                          ? reason.name_EN
                          : ""}
                    </TableCell>
                    <TableCell className="text">
                      {reason.startDate &&
                        dayjs().isBefore(
                          dayjs(reason.startDate, "YYYY-MM-DD")
                        ) &&
                        `(${t("EffectiveDate")}: ${dayjs(
                          reason.startDate,
                          "YYYY-MM-DD"
                        ).format("D MMM BBBB")})`}
                    </TableCell>
                    <TableCell>
                      {!reason.isStandard && (
                        <>
                          <IconButton
                            onClick={() => {
                              setStatusDrawer({
                                isOpen: true,
                                type: "requestTime",
                                reason: reason,
                              });
                            }}
                          >
                            <EditNoteRoundedIcon />
                          </IconButton>
                          <IconButton
                            style={{ marginLeft: "8px" }}
                            onClick={() => {
                              setStatusDialog({
                                isOpen: true,
                                reason: reason,
                              });
                            }}
                          >
                            <DeleteOutlineRoundedIcon />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="3" align="center">
                    <Typography>{t("ReasonNotFound")}</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </div>
      <div style={{ marginTop: "32px" }}>
        <StyledGridTopic container justifyContent="space-between">
          <Grid item>
            <Typography className="topic">
              {t("ReasonsForOTRequest")}
            </Typography>
          </Grid>
          <Grid item>
            <ButtonBlue
              variant="contained"
              onClick={() => {
                setStatusDrawer({
                  isOpen: true,
                  type: "OT",
                  reason: null,
                });
              }}
            >
              {t("AddReason")}
            </ButtonBlue>
          </Grid>
        </StyledGridTopic>
        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    {t("Reasons")}
                  </Typography>
                </TableCell>
                <TableCell style={{ width: "184px" }}></TableCell>
                <TableCell
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    width: "88px",
                  }}
                >
                  {t("ShiftValue")}
                </TableCell>
                <TableCell
                  style={{
                    width: "88px",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listOTReasons.length > 0 ? (
                listOTReasons.map((reason) => (
                  <TableRow key={reason.idRequestReason}>
                    <TableCell className="text">
                      {i18n.resolvedLanguage === "th"
                        ? reason.name
                        : reason.name_EN
                          ? reason.name_EN
                          : ""}
                    </TableCell>
                    <TableCell className="text">
                      {reason.startDate &&
                        dayjs().isBefore(
                          dayjs(reason.startDate, "YYYY-MM-DD")
                        ) &&
                        `(${t("EffectiveDate")}: ${dayjs(
                          reason.startDate,
                          "YYYY-MM-DD"
                        ).format("D MMM BBBB")})`}
                    </TableCell>
                    <TableCell>
                      {!!reason.isPayShift
                        ? `${t("GotShiftFee")}`
                        : `${t("NoShiftFee")}`}
                    </TableCell>
                    <TableCell>
                      {!reason.isStandard && (
                        <>
                          <IconButton
                            onClick={() => {
                              setStatusDrawer({
                                isOpen: true,
                                type: "OT",
                                reason: reason,
                              });
                            }}
                          >
                            <EditNoteRoundedIcon />
                          </IconButton>
                          <IconButton
                            style={{ marginLeft: "8px" }}
                            onClick={() => {
                              setStatusDialog({
                                isOpen: true,
                                reason: reason,
                              });
                            }}
                          >
                            <DeleteOutlineRoundedIcon />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="4" align="center">
                    <Typography>{t("ReasonNotFound")}</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </div>
      {statusDialog.isOpen && (
        <DialogConfirmDelete
          open={statusDialog.isOpen}
          handleClose={() => setStatusDialog(false)}
          label={t("DoUWantToDeleteReason")}
          text={statusDialog.reason.name}
          handleDelete={async () => {
            let result = await dispatch(
              deleteRequestReason(statusDialog.reason.idRequestReason)
            );
            if (result.status === 200) {
              handleOpenAlert("success");
              renderRequestReason();
            } else {
              handleOpenAlert("error");
            }
            setStatusDialog({
              isOpen: false,
              reason: null,
            });
          }}
          isReadOnly={userProfile && userProfile.readOnly}
        />
      )}
      {statusDrawer.isOpen && (
        <DrawerReason
          open={statusDrawer.isOpen}
          onClose={handleOnCloseDrawer}
          type={statusDrawer.type}
          reason={statusDrawer.reason}
          renderRequestReason={renderRequestReason}
          handleOpenAlert={handleOpenAlert}
          selectedCompany={selectedCompany}
          isReadOnly={userProfile && userProfile.readOnly}
        />
      )}
      {statusAlert.isOpen && (
        <AlertResponse
          open={statusAlert.isOpen}
          handleClose={() =>
            setStatusAlert({
              isOpen: false,
              type: null,
            })
          }
          alertType={statusAlert.type}
        />
      )}
    </Root>
  );
};

export default Reason;
