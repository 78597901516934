import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Box,
  Grid,
  Avatar,
  Typography,
  FormControl,
  MenuItem,
  Paper,
  styled,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

// Translator TH-EN
import { useTranslation } from "react-i18next";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import DialogAdd from "./DialogAdd";
import { getUserFullName } from "../../../../../utils/userData";
import { getAllEmployees } from "../../../../../actions/employee";
import {
  clearTerminateEmployee,
  getTerminateEmployeeByID,
} from "../../../../../actions/offBoarding";
import { getAffiliateOrganization } from "../../../../../actions/company";
import { exportToExcel } from "../../onBoarding/reviewer/ExportExcel";

const StatusBadge = styled(Box)(({ color }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: color,
  borderRadius: "16px",
  padding: "4px 12px",
  color: "#fff",
  fontWeight: 600,
}));

const ReviewerOffBoarding = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { result: terminateEmployee } = useSelector(
    (state) => state.terminateEmployeeByID
  );
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );
  const { result: employees } = useSelector((state) => state.employees);

  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getAffiliateOrganization());
    dispatch(clearTerminateEmployee());
  }, [dispatch]);

  useEffect(() => {
    if (selectedEmployee)
      dispatch(getTerminateEmployeeByID(selectedEmployee.idEmployees));
  }, [selectedEmployee, openDialog, dispatch]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setFormData(selectedEmployee);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setFormData(null);
  };

  const handleDownload = () => {
    exportToExcel(filteredTerminateEmployee, selectedCompany);
  };

  const filteredTerminateEmployee = terminateEmployee
    ? terminateEmployee.filter((item) => {
        const matchName =
          `${item.reviewer_firstname_TH} ${item.reviewer_lastname_TH}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        const matchStatus =
          filterStatus === "all" ||
          (filterStatus === "completed"
            ? item.isApprove === 1
            : item.isApprove === 0);
        return matchName && matchStatus;
      })
    : [];

  const completedItemsCount = filteredTerminateEmployee.filter(
    (item) => item.isApprove === 1
  ).length;
  const inProgressItemsCount = filteredTerminateEmployee.filter(
    (item) => item.isApprove === 0
  ).length;

  return (
    <div>
      <Box>
        <Grid container alignItems="center" spacing={2} marginBottom={4}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={affiliateOrganizationList || []}
              getOptionLabel={(option) => option.companyName}
              onChange={(event, newValue) => {
                setSelectedCompany(newValue);
                setSelectedEmployee(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder={t("SelectCompany")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={(employees || []).filter(
                (employee) =>
                  selectedCompany &&
                  employee.idCompany === selectedCompany.idCompany
              )}
              getOptionLabel={(option) => getUserFullName(option)}
              onChange={(event, newValue) => {
                setSelectedEmployee(newValue);
                dispatch(clearTerminateEmployee());
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder={t("SelectEmp")}
                />
              )}
            />
          </Grid>
        </Grid>
        {selectedEmployee && (
          <Grid container alignItems="center" spacing={2} marginBottom={4}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder={t("SearchInspector")}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  select
                  label={t("Status")}
                  value={filterStatus}
                  onChange={(e) => setFilterStatus(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="all">{t("All")}</MenuItem>
                  <MenuItem value="completed">{t("Completed")}</MenuItem>
                  <MenuItem value="inProgress">{t("InProgress")}</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </Box>

      {selectedEmployee && (
        <Box display="flex" alignItems="center" marginBottom={2}>
          <Avatar
            alt={getUserFullName(selectedEmployee)}
            src={selectedEmployee.imageProfile}
            style={{ marginRight: 16, width: 56, height: 56 }}
          />
          <Typography variant="h6">
            {getUserFullName(selectedEmployee)}
          </Typography>
          <Box display="flex" alignItems="center" marginLeft="auto" gap={2}>
            <StatusBadge color="#4caf50">
              {`${completedItemsCount} ${t("itemsCompleted")}`}
            </StatusBadge>
            <StatusBadge color="#ff9800">
              {`${inProgressItemsCount} ${t("itemsInprogress")}`}
            </StatusBadge>
            <ButtonBlue
              size="small"
              variant="contained"
              onClick={handleOpenDialog}
            >
              {t("AddItem")}
            </ButtonBlue>
            <ButtonBlue
              size="small"
              variant="outlined"
              onClick={handleDownload}
            >
              {t("Download")}
            </ButtonBlue>
          </Box>
        </Box>
      )}

      {terminateEmployee && terminateEmployee.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 600 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{t("ItemName")}</TableCell>
                <TableCell align="center">{t("Inspector")}</TableCell>
                <TableCell align="center">{t("Department")}</TableCell>
                <TableCell align="center">{t("Section")}</TableCell>
                <TableCell align="center">{t("Division")}</TableCell>
                <TableCell align="center">{t("Position")}</TableCell>
                <TableCell align="center">{t("Status")}</TableCell>
                <TableCell align="center">{t("Date")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTerminateEmployee.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{row.TerminateListName}</TableCell>
                  <TableCell align="center">
                    <Box display="flex" alignItems="center">
                      <Avatar
                        alt={`${row.reviewer_firstname_TH} ${row.reviewer_lastname_TH}`}
                        src={row.reviewer_imageProfile}
                        style={{ marginRight: 8 }}
                      />
                      {`${row.reviewer_firstname_TH} ${row.reviewer_lastname_TH}`}
                    </Box>
                  </TableCell>
                  <TableCell align="center">{row.departmentName}</TableCell>
                  <TableCell align="center">{row.sectionName}</TableCell>
                  <TableCell align="center">{row.divisionName}</TableCell>
                  <TableCell align="center">{row.positionName}</TableCell>
                  <TableCell align="center">
                    {row.isApprove ? (
                      <CheckCircleOutlineIcon style={{ color: "green" }} />
                    ) : (
                      <CancelOutlinedIcon style={{ color: "red" }} />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {dayjs(row.updateDate).format("DD/MM/YYYY")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        `${t("NoItems")}`
      )}

      {openDialog && (
        <DialogAdd
          open={openDialog}
          data={formData}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export default ReviewerOffBoarding;
