import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Icon, styled, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import CardData from "../../../../../shared/dashboard/CardData";
import ChartPolarAreaCustom from "../../../../../shared/dashboard/ChartPolarArea";
import TableCustom from "../../../../../shared/tableCustom";
import CardDashboard from "../../../../../shared/dashboard/CardDashboard";
import utils from "../../../../../../../utils";

const StyledRoot = styled(Box)({
    "& .top-employee": {
        padding: "8px",
        // paddingTop: "16px",
        display: "flex",
        alignItems: "center",
        border: "1px solid #ececec",
        borderRadius: "4px",
    },
});

export const OverviewPayroll = (props) => {
    const { t, i18n } = useTranslation();
    const { overviewData } = props;
    return (
        <StyledRoot>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <CardData
                                        title={`${t("Employee")}`}
                                        dataValue={parseInt(overviewData && overviewData.numberOfEmp)}
                                        dataUnit={`${t("Person")}`}
                                        dynamicFontSize
                                        titleIcon={
                                            <Icon
                                                baseClassName="fal"
                                                className="fa-users"
                                                color="primary"
                                                style={{ display: "inline-table" }}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CardData
                                        title={`${t("SalaryTotal")}`}
                                        dataValue={utils.numberWithCommas(overviewData && overviewData.salaryPaid)}
                                        dataUnit={`${t("Baht")}`}
                                        dynamicFontSize
                                        titleIcon={
                                            <Icon
                                                className="fal fa-sack-dollar"
                                                color="warning"
                                                style={{ display: "inline-table", color: "#ffcd38" }}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CardData
                                        title={`${t("AppMenu.OvertimeOverview")}`}
                                        dataValue={utils.numberWithCommas(overviewData && overviewData.totalOT)}
                                        dataUnit={`${t("Baht")}`}
                                        dynamicFontSize
                                        titleIcon={
                                            <Icon
                                                className="fal fa-sack-dollar"
                                                color="warning"
                                                style={{ display: "inline-table", color: "#ffcd38" }}
                                            />
                                        }
                                    />
                                </Grid>
                                {/* <Grid item xs={12} md={6}>
                                    <CardData
                                        title={`${t("OTOVer36Hr")}`}
                                        // dataValue={[{name: "1", value: "20"}, {name: "2", value: "30"}]}
                                        dataValue={dashboardOverview.employeeOTOver36Total ? dashboardOverview.employeeOTOver36Total.weekInMonth.map(
                                            (week) => {
                                                return {
                                                    name: `${dayjs(week.weekStartDateText).format(
                                                        "dd D MMM"
                                                    )} - ${dayjs(week.weekEndDateText).format(
                                                        "dd D MMM"
                                                    )}`,
                                                    value: week.over36HoursEmployeeTotal,
                                                };
                                            }
                                        ) : []}
                                        dataUnit={`${t("Person")}`}
                                        dynamicFontSize
                                        select
                                        titleIcon={
                                            <Icon
                                                className="far fa-alarm-exclamation"
                                                color="error"
                                                style={{ display: "inline-table" }}
                                            />
                                        }
                                    />
                                </Grid> */}
                                {/* <Grid item xs={12} md={6}>
                                    <CardData
                                        title={`${t("OTTotal")}`}
                                        dataValue={dashboardOverview.otTotal ? Utils.numberWithCommas(
                                            Object.keys(dashboardOverview.otTotal).reduce(
                                                (a, b) => a + dashboardOverview.otTotal[b].payTotal,
                                                0
                                            )
                                        ) : 0}
                                        dataUnit={`${t("Baht")}`}
                                        dynamicFontSize
                                        titleIcon={
                                            <Icon
                                                className="fal fa-hand-holding-usd"
                                                color="warning"
                                                style={{ display: "inline-table", color: "#ffcd38" }}
                                            />
                                        }
                                    />
                                </Grid> */}
                            </Grid>
                        </Grid>

                        {/* <Grid item xs={12} lg={6}>
                            <CardDashboard>
                                <Typography marginBottom="16px">
                                    {t("SalaryTotal")} ({t("Yearly")} {year})
                                </Typography>
                                <ChartPolarAreaCustom
                                    series={[
                                        {
                                            name: "รายจ่าย",
                                            data: dashboardOverview.salaryTotalAllYear ? dashboardOverview.salaryTotalAllYear.map((s) => {
                                                return {
                                                    x: dayjs()
                                                        .month(s.month - 1)
                                                        .format("MMM"),
                                                    y: s.salaryTotal,
                                                };
                                            }) : [],
                                        },
                                    ]}
                                />
                            </CardDashboard>
                        </Grid> */}
                    </Grid>
                </Grid>

                {/* <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <CardDashboard>
                                <Typography marginBottom="16px">
                                    {t("OTTotalByType")}
                                </Typography>
                                <ChartPolarAreaCustom
                                    series={dashboardOverview.otTotal ? Object.keys(dashboardOverview.otTotal).map(
                                        (ot) => dashboardOverview.otTotal[ot].payTotal
                                    ) : []}
                                    labels={dashboardOverview.otTotal ? Object.keys(dashboardOverview.otTotal).map(
                                        (ot) => `OT x${ot}`
                                    ) : []}
                                />
                            </CardDashboard>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <CardDashboard>
                                <Typography marginBottom="16px">
                                    {t("OTTotal")} ({t("Yearly")} {year})
                                </Typography>
                                <ChartAreaCustom
                                    series={[
                                        {
                                            name: "รายจ่าย",
                                            data: dashboardOverview.otTotalAllYear ? dashboardOverview.otTotalAllYear.map((s) => {
                                                return {
                                                    x: dayjs()
                                                        .month(s.month - 1)
                                                        .format("MMM"),
                                                    y: s.payTotal,
                                                };
                                            }) : [],
                                        },
                                    ]}
                                />
                            </CardDashboard>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={8}>
                            <CardDashboard>
                                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="16px">
                                    <Typography>
                                        {`${t("Leave")} `}
                                        {dayjs()
                                            .month(month - 1)
                                            .year(year)
                                            .format("MMMM YYYY")}
                                    </Typography>
                                    <ButtonBlue
                                        endIcon={<NavigateNextRoundedIcon />}
                                        onClick={() => {
                                            history.push({
                                                pathname: "/admin/history-approve-list",
                                                state: {
                                                    start: dayjs().year(year).month(month - 1).date(1).format("YYYY-MM-DD"),
                                                    end: dayjs().year(year).month(month - 1).date(dayjs().year(year).month(month - 1).daysInMonth()).format("YYYY-MM-DD"),
                                                    tab: 2,
                                                }
                                            })
                                        }}
                                    >
                                        {t("All")}
                                    </ButtonBlue>
                                </Box>
                                <TableCustom
                                    columns={leaveColumns}
                                    rows={dashboardOverview.workingTimeEmployeeInfo ? dashboardOverview.workingTimeEmployeeInfo.leave.sort(
                                        (a, b) =>
                                            dayjs(a.startText, "DD/MM/YYYY").isBefore(
                                                dayjs(b.startText, "DD/MM/YYYY")
                                            )
                                                ? -1
                                                : 1
                                    ).slice(0, 8) : []}
                                    rowsPerPageProp={5}
                                    rowsPerPageOptions={[5]}
                                />
                            </CardDashboard>
                        </Grid>
                    </Grid>
                </Grid> */}
            </Grid>
        </StyledRoot>
    );
}