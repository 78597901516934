import React, { useState, useEffect } from "react";
import { styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

const StyledTextFieldTheme = styled(TextFieldTheme)({
  marginBottom: 20,
});

const DialogCurrentAddress = (props) => {
  const { open, handleClose, value } = props;
  const [state, setstate] = useState({
    name: "",
    address: "",
    phone: "",
    email: "",
  });

  const handleChange = (event) => {
    setstate({ ...state, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    setstate(value);
  }, [value]);

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"แก้ไขที่อยู่ปัจจุบัน"}
      </DialogTitle>
      <DialogContent style={{ paddingTop: 16 }}>
      <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Grid item xs={2}>
            <TextFieldTheme label="เลขที่" />
          </Grid>
          <Grid item xs={2}>
            <TextFieldTheme label="หมู่" />
          </Grid>
          <Grid item xs={8}>
            <TextFieldTheme label="หมู่บ้าน/อาคาร" />
          </Grid>
          <Grid item xs={4}>
            <TextFieldTheme label="ซอย" />
          </Grid>
          <Grid item xs={4}>
            <TextFieldTheme label="ถนน" />
          </Grid>
          <Grid item xs={4}>
            <TextFieldTheme label="ตำบล" />
          </Grid>
          <Grid item xs={4}>
            <TextFieldTheme label="อำเภอ" />
          </Grid>
          <Grid item xs={4}>
            <TextFieldTheme label="จังหวัด" />
          </Grid>
          <Grid item xs={4}>
            <TextFieldTheme label="รหัสไปรษณีย์" />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginBottom: 8 }}>
        <ButtonBlue onClick={handleClose}>ยกเลิก</ButtonBlue>
        <ButtonBlue variant="contained" onClick={handleClose}>
          {"บันทึกการแก้ไข"}
        </ButtonBlue>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCurrentAddress;
