import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import {
  getAllRightsEmployeeFamily,
  getRemaingRightLimit,
} from "../../../../actions/rightsWelfare";
import { Grid, Typography, MenuItem, Divider, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import NumberFormatTheme from "../../shared/general/NumberFormatTheme";

import utils from "../../../../utils";
import { useTranslation } from "react-i18next";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
  "&.MuiTypography-subtitle1": {
    fontSize: 16,
    fontWeight: 500,
  },
  "&.MuiTypography-subtitle2": {
    fontSize: 16,
    fontWeight: 400,
  },
  "&.rightName": {
    paddingLeft: "8px",
    borderLeft: "6px solid #46cbe2",
    marginBottom: 2,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 24,
  marginBottom: 20,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledBox = styled(Box)({});

const WelfareRightUsage = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const {
    idEmployees,
    idFamily,
    isUseForFamily,
    control,
    setValue,
    getValues,
    idExpenseWelfare,
    idWelfareBilling,
    suggestedIdRightsManage,
    watch,
  } = props;
  const { result: welfareRights } = useSelector((state) => state.welfareRights);
  const { result: welfareRemainingRight } = useSelector(
    (state) => state.welfareRemainingRight
  );
  const { result: expenseDetail } = useSelector((state) => state.expenseDetail);
  const [rights, setRights] = useState([]);
  const [usedRight, setUsedRight] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    dispatch(getAllRightsEmployeeFamily());
  }, [idEmployees]);

  useEffect(() => {
    if (welfareRights) {
      if (getValues("isUseForFamilyMember") === 1) {
        setRights(
          welfareRights.find(
            (member) => member.idFamily === getValues("idFamily")
          ).allRights
        );
      } else {
        setRights(
          welfareRights.find((member) => member.idEmployees === idEmployees)
            .allRights
        );
      }
      if (rights.length === 1) {
        setUsedRight(rights[0]);
      }
    }
  }, [welfareRights, watch("idFamily"), watch("isUseForFamilyMember")]);

  useEffect(() => {
    if (welfareRemainingRight && usedRight) {
      clearAll();
      let defaultUsedLimit =
        parseFloat(getValues("withdrawal")) -
        parseFloat(getValues("helpingSurplus"));
      if (usedRight.principle === "totalLimit") {
        setValue("usage.usedLimit", defaultUsedLimit);
      } else if (usedRight.principle === "individualLimit") {
        switch (getValues("relation")) {
          case "Me":
            if (
              parseFloat(welfareRemainingRight.remaingLimitSelf) > 0 &&
              defaultUsedLimit <
                parseFloat(welfareRemainingRight.remaingLimitSelf)
            ) {
              setValue("usage.usedLimitSelf", defaultUsedLimit);
            }
            break;
          case "Father":
            if (
              parseFloat(welfareRemainingRight.remaingLimitParents) > 0 &&
              defaultUsedLimit <
                parseFloat(welfareRemainingRight.remaingLimitParents)
            ) {
              setValue("usage.usedLimitParents", defaultUsedLimit);
            }
            break;
          case "Mother":
            if (
              parseFloat(welfareRemainingRight.remaingLimitParents) > 0 &&
              defaultUsedLimit <
                parseFloat(welfareRemainingRight.remaingLimitParents)
            ) {
              setValue("usage.usedLimitParents", defaultUsedLimit);
            }
            break;
          case "Child":
            if (
              parseFloat(welfareRemainingRight.remaingLimitChild) > 0 &&
              defaultUsedLimit <
                parseFloat(welfareRemainingRight.remaingLimitChild)
            ) {
              setValue("usage.usedLimitChild", defaultUsedLimit);
            }
            break;
          case "Spouse":
            if (
              parseFloat(welfareRemainingRight.remaingLimitCouple) > 0 &&
              defaultUsedLimit <
                parseFloat(welfareRemainingRight.remaingLimitCouple)
            ) {
              setValue("usage.usedLimitCouple", defaultUsedLimit);
            }
            break;
          default:
        }
      } else if (usedRight.principle === "detail") {
        switch (getValues("type")) {
          case "OPD":
            if (parseFloat(welfareRemainingRight.remaingOPD_Number) > 1) {
              setValue("usage.usedOPD_Number", 1);
            } else if (
              parseFloat(welfareRemainingRight.remaingLimitOPD) > 0 &&
              defaultUsedLimit <
                parseFloat(welfareRemainingRight.remaingLimitOPD)
            ) {
              setValue("usage.usedLimiOIPD", defaultUsedLimit);
            }
            break;
          case "IPD":
            if (
              parseFloat(welfareRemainingRight.remaingLimitIPD) > 0 &&
              defaultUsedLimit <
                parseFloat(welfareRemainingRight.remaingLimitIPD)
            ) {
              setValue("usage.usedLimitIPD", defaultUsedLimit);
            }
            break;
          case "Dental":
            if (
              parseFloat(welfareRemainingRight.remaingLimitDental) > 0 &&
              defaultUsedLimit <
                parseFloat(welfareRemainingRight.remaingLimitDental)
            ) {
              setValue("usage.usedLimitDental", defaultUsedLimit);
            }
            break;
          default:
        }
      }
    }
  }, [welfareRemainingRight, usedRight]);

  const clearAll = () => {
    setValue("usage.usedLimit", 0);
    setValue("usage.usedLimitSelf", 0);
    setValue("usage.usedLimitParents", 0);
    setValue("usage.usedLimitParents", 0);
    setValue("usage.usedLimitChild", 0);
    setValue("usage.usedLimitCouple", 0);
    setValue("usage.usedLimitOPD", 0);
    setValue("usage.usedLimitIPD", 0);
    setValue("usage.usedLimitDental", 0);
    setValue("usage.usedOPD_Number", 0);
  };

  const getRemaingRight = () => {
    dispatch(
      getRemaingRightLimit({
        idExpenseWelfare: idExpenseWelfare,
        idWelfareBilling: idWelfareBilling,
        idRightsManage: getValues("idRightsManage"),
      })
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StyledHeadLabel variant="h6">หักสิทธิสวัสดิการ</StyledHeadLabel>
      </Grid>
      <Grid item xs={12} md={2}>
        <Typography variant="subtitle1">สิทธิที่ต้องการใช้</Typography>
      </Grid>

      <Grid item xs={10} md={9}>
        <Controller
          control={control}
          name={`idRightsManage`}
          render={({ field, formState }) => (
            <TextFieldTheme
              {...field}
              select
              error={
                formState.errors && formState.idRightsManage ? true : false
              }
              helperText={
                formState.errors && formState.errors.idRightsManage
                  ? formState.errors.idRightsManage.message
                  : null
              }
            >
              {rights.length > 0 &&
                rights.map((item) => (
                  <MenuItem
                    id={item.idRightsManage}
                    key={item.idRightsManage}
                    value={item.idRightsManage}
                    onClick={() => {
                      setValue("idRightsManage", item.idRightsManage);
                      setUsedRight(item);
                      setIsOpen(false);
                    }}
                  >
                    {item.rightsName}
                  </MenuItem>
                ))}
            </TextFieldTheme>
          )}
        />
      </Grid>
      <Grid item xs={2} md={1}>
        <ButtonBlue
          variant="contained"
          onClick={() => {
            setIsOpen(true);
            getRemaingRight();
          }}
          fullWidth
        >
          {t("Confirm")}
        </ButtonBlue>
      </Grid>

      {isOpen && welfareRemainingRight && (
        <>
          <StyledDivider />
          <Grid item xs={12}>
            <StyledHeadLabel variant="subtitle1" className="rightName">
              {usedRight.rightName}
            </StyledHeadLabel>
          </Grid>
          {usedRight &&
            usedRight.principle &&
            usedRight.principle === "totalLimit" && (
              <>
                <Grid item xs={12}>
                  <StyledHeadLabel variant="subtitle1">
                    {t("RightsType")}: วงเงินรวม
                  </StyledHeadLabel>
                </Grid>
                <Grid item xs={12}>
                  <StyledHeadLabel variant="subtitle1">
                    &#x2022;&nbsp;&nbsp;วงเงินรวม
                  </StyledHeadLabel>
                </Grid>
                <Grid item xs={0} md={1} />
                <Grid item xs={12} md={3}>
                  <StyledBox>
                    <StyledHeadLabel variant="subtitle2">
                      วงเงินทั้งหมด
                    </StyledHeadLabel>
                    <span>
                      &nbsp;&nbsp;
                      {utils.numberWithCommas(parseFloat(usedRight.limit))} บาท
                    </span>
                  </StyledBox>
                </Grid>
                <Grid item xs={12} md={3}>
                  <StyledBox>
                    <StyledHeadLabel variant="subtitle2">
                      วงเงินคงเหลือ
                    </StyledHeadLabel>
                    <span>
                      &nbsp;&nbsp;
                      {utils.numberWithCommas(
                        parseFloat(welfareRemainingRight.remaingLimit)
                      )}{" "}
                      บาท
                    </span>
                  </StyledBox>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Controller
                    control={control}
                    name={`usage.usedLimit`}
                    render={({ field, formState }) => (
                      <TextFieldTheme
                        {...field}
                        label="จำนวนเงินที่หักจากสิทธิ"
                        error={
                          formState.errors && formState.usedLimit ? true : false
                        }
                        helperText={
                          formState.errors && formState.errors.usedLimit
                            ? formState.errors.usedLimit.message
                            : null
                        }
                        InputProps={{
                          inputComponent: NumberFormatTheme,
                        }}
                        inputProps={{
                          decimalScale: 2,
                          allowNegative: false,
                          value: field.value,
                          suffix: " บาท",
                          isAllowed: (values) => {
                            const { value } = values;
                            return (
                              value <=
                                parseFloat(
                                  welfareRemainingRight.remaingLimit
                                ) &&
                              value <=
                                parseFloat(getValues("withdrawal")) -
                                  parseFloat(getValues("helpingSurplus"))
                            );
                          },
                          onValueChange: (values) => {
                            const { value } = values;
                            field.onChange(value);
                          },
                        }}
                        onChange={() => {}}
                      ></TextFieldTheme>
                    )}
                  />
                </Grid>
              </>
            )}
          {usedRight &&
            usedRight.principle &&
            usedRight.principle === "individualLimit" && (
              <>
                <Grid item xs={12}>
                  <StyledHeadLabel variant="subtitle1">
                    {t("RightsType")}: วงเงินรายบุคคล
                  </StyledHeadLabel>
                </Grid>
                {usedRight && usedRight.limitSelf && (
                  <>
                    <Grid item xs={12}>
                      <StyledHeadLabel variant="subtitle1">
                        &#x2022;&nbsp;&nbsp;วงเงินพนักงาน
                      </StyledHeadLabel>
                    </Grid>
                    <Grid item xs={0} md={1} />
                    <Grid item xs={12} md={3}>
                      <StyledBox>
                        <StyledHeadLabel variant="subtitle2">
                          วงเงินทั้งหมด
                        </StyledHeadLabel>
                        <span>
                          &nbsp;&nbsp;
                          {utils.numberWithCommas(
                            parseFloat(usedRight.limitSelf)
                          )}{" "}
                          บาท
                        </span>
                      </StyledBox>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <StyledBox>
                        <StyledHeadLabel variant="subtitle2">
                          วงเงินคงเหลือ
                        </StyledHeadLabel>
                        <span>
                          &nbsp;&nbsp;
                          {utils.numberWithCommas(
                            parseFloat(welfareRemainingRight.remaingLimitSelf)
                          )}{" "}
                          บาท
                        </span>
                      </StyledBox>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Controller
                        control={control}
                        name={`usage.usedLimitSelf`}
                        render={({ field, formState }) => (
                          <TextFieldTheme
                            {...field}
                            label="จำนวนเงินที่หักจากสิทธิ"
                            error={
                              formState.errors && formState.usedLimitSelf
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors && formState.errors.usedLimitSelf
                                ? formState.errors.usedLimitSelf.message
                                : null
                            }
                            InputProps={{
                              inputComponent: NumberFormatTheme,
                            }}
                            inputProps={{
                              decimalScale: 2,
                              allowNegative: false,
                              value: field.value,
                              suffix: " บาท",
                              isAllowed: (values) => {
                                const { value } = values;
                                return (
                                  value <=
                                    parseFloat(
                                      welfareRemainingRight.remaingLimitSelf
                                    ) &&
                                  value <=
                                    parseFloat(getValues("withdrawal")) -
                                      parseFloat(getValues("helpingSurplus"))
                                );
                              },
                              onValueChange: (values) => {
                                const { value } = values;
                                field.onChange(value);
                              },
                            }}
                            onChange={() => {}}
                          ></TextFieldTheme>
                        )}
                      />
                    </Grid>
                  </>
                )}

                {usedRight && usedRight.limitCouple && (
                  <>
                    <Grid item xs={12}>
                      <StyledHeadLabel variant="subtitle1">
                        &#x2022;&nbsp;&nbsp;วงเงินคู่สมรส
                      </StyledHeadLabel>
                    </Grid>
                    <Grid item xs={0} md={1} />
                    <Grid item xs={12} md={3}>
                      <StyledBox>
                        <StyledHeadLabel variant="subtitle2">
                          วงเงินทั้งหมด
                        </StyledHeadLabel>
                        <span>
                          &nbsp;&nbsp;
                          {utils.numberWithCommas(
                            parseFloat(usedRight.limitCouple)
                          )}{" "}
                          บาท
                        </span>
                      </StyledBox>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <StyledBox>
                        <StyledHeadLabel variant="subtitle2">
                          วงเงินคงเหลือ
                        </StyledHeadLabel>
                        <span>
                          &nbsp;&nbsp;
                          {utils.numberWithCommas(
                            parseFloat(welfareRemainingRight.remaingLimitCouple)
                          )}{" "}
                          บาท
                        </span>
                      </StyledBox>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Controller
                        control={control}
                        name={`usage.usedLimitCouple`}
                        render={({ field, formState }) => (
                          <TextFieldTheme
                            {...field}
                            label="จำนวนเงินที่หักจากสิทธิ"
                            error={
                              formState.errors && formState.usedLimitCouple
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors &&
                              formState.errors.usedLimitCouple
                                ? formState.errors.usedLimitCouple.message
                                : null
                            }
                            InputProps={{
                              inputComponent: NumberFormatTheme,
                            }}
                            inputProps={{
                              decimalScale: 2,
                              allowNegative: false,
                              value: field.value,
                              suffix: " บาท",
                              isAllowed: (values) => {
                                const { value } = values;
                                return (
                                  value <=
                                    parseFloat(
                                      welfareRemainingRight.remaingLimitCouple
                                    ) &&
                                  value <=
                                    parseFloat(getValues("withdrawal")) -
                                      parseFloat(getValues("helpingSurplus"))
                                );
                              },
                              onValueChange: (values) => {
                                const { value } = values;
                                field.onChange(value);
                              },
                            }}
                            onChange={() => {}}
                          ></TextFieldTheme>
                        )}
                      />
                    </Grid>
                  </>
                )}
                {usedRight && usedRight.limitParents && (
                  <>
                    <Grid item xs={12}>
                      <StyledHeadLabel variant="subtitle1">
                        &#x2022;&nbsp;&nbsp;วงเงินบิดามารดา
                      </StyledHeadLabel>
                    </Grid>
                    <Grid item xs={0} md={1} />
                    <Grid item xs={12} md={3}>
                      <StyledBox>
                        <StyledHeadLabel variant="subtitle2">
                          วงเงินทั้งหมด
                        </StyledHeadLabel>
                        <span>
                          &nbsp;&nbsp;
                          {utils.numberWithCommas(
                            parseFloat(usedRight.limitParents)
                          )}{" "}
                          บาท
                        </span>
                      </StyledBox>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <StyledBox>
                        <StyledHeadLabel variant="subtitle2">
                          วงเงินคงเหลือ
                        </StyledHeadLabel>
                        <span>
                          &nbsp;&nbsp;
                          {utils.numberWithCommas(
                            parseFloat(
                              welfareRemainingRight.remaingLimitParents
                            )
                          )}{" "}
                          บาท
                        </span>
                      </StyledBox>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Controller
                        control={control}
                        name={`usage.usedLimitParents`}
                        render={({ field, formState }) => (
                          <TextFieldTheme
                            {...field}
                            label="จำนวนเงินที่หักจากสิทธิ"
                            error={
                              formState.errors && formState.usedLimitParents
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors &&
                              formState.errors.usedLimitParents
                                ? formState.errors.usedLimitParents.message
                                : null
                            }
                            InputProps={{
                              inputComponent: NumberFormatTheme,
                            }}
                            inputProps={{
                              decimalScale: 2,
                              allowNegative: false,
                              value: field.value,
                              suffix: " บาท",
                              isAllowed: (values) => {
                                const { value } = values;
                                return (
                                  value <=
                                    parseFloat(
                                      welfareRemainingRight.remaingLimitParents
                                    ) &&
                                  value <=
                                    parseFloat(getValues("withdrawal")) -
                                      parseFloat(getValues("helpingSurplus"))
                                );
                              },
                              onValueChange: (values) => {
                                const { value } = values;
                                field.onChange(value);
                              },
                            }}
                            onChange={() => {}}
                          ></TextFieldTheme>
                        )}
                      />
                    </Grid>
                  </>
                )}
                {usedRight && usedRight.limitChild && (
                  <>
                    <Grid item xs={12}>
                      <StyledHeadLabel variant="subtitle1">
                        &#x2022;&nbsp;&nbsp;วงเงินบุตร
                      </StyledHeadLabel>
                    </Grid>
                    <Grid item xs={0} md={1} />
                    <Grid item xs={12} md={3}>
                      <StyledBox>
                        <StyledHeadLabel variant="subtitle2">
                          วงเงินทั้งหมด
                        </StyledHeadLabel>
                        <span>
                          &nbsp;&nbsp;
                          {utils.numberWithCommas(
                            parseFloat(usedRight.limitChild)
                          )}{" "}
                          บาท
                        </span>
                      </StyledBox>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <StyledBox>
                        <StyledHeadLabel variant="subtitle2">
                          วงเงินคงเหลือ
                        </StyledHeadLabel>
                        <span>
                          &nbsp;&nbsp;
                          {utils.numberWithCommas(
                            parseFloat(welfareRemainingRight.remaingLimitChild)
                          )}{" "}
                          บาท
                        </span>
                      </StyledBox>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Controller
                        control={control}
                        name={`usage.usedLimitChild`}
                        render={({ field, formState }) => (
                          <TextFieldTheme
                            {...field}
                            label="จำนวนเงินที่หักจากสิทธิ"
                            error={
                              formState.errors && formState.usedLimitChild
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors &&
                              formState.errors.usedLimitChild
                                ? formState.errors.usedLimitChild.message
                                : null
                            }
                            InputProps={{
                              inputComponent: NumberFormatTheme,
                            }}
                            inputProps={{
                              decimalScale: 2,
                              allowNegative: false,
                              value: field.value,
                              suffix: " บาท",
                              isAllowed: (values) => {
                                const { value } = values;
                                return (
                                  value <=
                                    parseFloat(
                                      welfareRemainingRight.remaingLimitChild
                                    ) &&
                                  value <=
                                    parseFloat(getValues("withdrawal")) -
                                      parseFloat(getValues("helpingSurplus"))
                                );
                              },
                              onValueChange: (values) => {
                                const { value } = values;
                                field.onChange(value);
                              },
                            }}
                            onChange={() => {}}
                          ></TextFieldTheme>
                        )}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}

          {usedRight &&
            usedRight.principle &&
            usedRight.principle === "detail" && (
              <>
                <StyledHeadLabel variant="subtitle1">
                  {t("RightsType")}: แบบรายละเอียด
                </StyledHeadLabel>
                {usedRight && usedRight.limitOPD && (
                  <>
                    <Grid item xs={12}>
                      <StyledHeadLabel variant="subtitle1">
                        &#x2022;&nbsp;&nbsp;วงเงิน OPD
                      </StyledHeadLabel>
                    </Grid>
                    <Grid item xs={0} md={1} />
                    <Grid item xs={12} md={3}>
                      <StyledBox>
                        <StyledHeadLabel variant="subtitle2">
                          วงเงินทั้งหมด
                        </StyledHeadLabel>
                        <span>
                          &nbsp;&nbsp;
                          {utils.numberWithCommas(
                            parseFloat(usedRight.limitOPD)
                          )}{" "}
                          บาท
                        </span>
                      </StyledBox>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <StyledBox>
                        <StyledHeadLabel variant="subtitle2">
                          วงเงินคงเหลือ
                        </StyledHeadLabel>
                        <span>
                          &nbsp;&nbsp;
                          {utils.numberWithCommas(
                            parseFloat(welfareRemainingRight.remaingLimitOPD)
                          )}{" "}
                          บาท
                        </span>
                      </StyledBox>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Controller
                        control={control}
                        name={`usage.usedLimitOPD`}
                        render={({ field, formState }) => (
                          <TextFieldTheme
                            {...field}
                            label="จำนวนเงินที่หักจากสิทธิ"
                            error={
                              formState.errors && formState.usedLimitOPD
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors && formState.errors.usedLimitOPD
                                ? formState.errors.usedLimitOPD.message
                                : null
                            }
                            InputProps={{
                              inputComponent: NumberFormatTheme,
                            }}
                            inputProps={{
                              decimalScale: 2,
                              allowNegative: false,
                              value: field.value,
                              suffix: " บาท",
                              isAllowed: (values) => {
                                const { value } = values;
                                return (
                                  value <=
                                    parseFloat(
                                      welfareRemainingRight.remaingLimitOPD
                                    ) &&
                                  value <=
                                    parseFloat(getValues("withdrawal")) -
                                      parseFloat(getValues("helpingSurplus"))
                                );
                              },
                              onValueChange: (values) => {
                                const { value } = values;
                                field.onChange(value);
                              },
                            }}
                            onChange={() => {}}
                          ></TextFieldTheme>
                        )}
                      />
                    </Grid>
                  </>
                )}
                {usedRight && usedRight.OPD_Number && (
                  <>
                    <Grid item xs={12}>
                      <StyledHeadLabel variant="subtitle1">
                        &#x2022;&nbsp;&nbsp;จำนวน OPD
                      </StyledHeadLabel>
                    </Grid>
                    <Grid item xs={0} md={1} />
                    <Grid item xs={12} md={3}>
                      <StyledBox>
                        <StyledHeadLabel variant="subtitle2">
                          จำนวน OPD ทั้งหมด
                        </StyledHeadLabel>
                        <span>
                          &nbsp;&nbsp;
                          {utils.numberWithCommasWithoutDot(
                            parseFloat(usedRight.OPD_Number)
                          )}
                        </span>
                      </StyledBox>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <StyledBox>
                        <StyledHeadLabel variant="subtitle2">
                          คงเหลือ
                        </StyledHeadLabel>
                        <span>
                          &nbsp;&nbsp;
                          {utils.numberWithCommasWithoutDot(
                            parseFloat(welfareRemainingRight.remaingOPD_Number)
                          )}{" "}
                          ครั้ง
                        </span>
                      </StyledBox>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Controller
                        control={control}
                        name={`usage.usedOPD_Number`}
                        render={({ field, formState }) => (
                          <TextFieldTheme
                            {...field}
                            label="จำนวนครั้งที่หักจากสิทธิ"
                            error={
                              formState.errors && formState.usedOPD_Number
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors &&
                              formState.errors.usedOPD_Number
                                ? formState.errors.usedOPD_Number.message
                                : null
                            }
                            InputProps={{
                              inputComponent: NumberFormatTheme,
                            }}
                            inputProps={{
                              decimalScale: 0,
                              allowNegative: false,
                              value: field.value,
                              suffix: " ครั้ง",
                              isAllowed: (values) => {
                                const { value } = values;
                                return (
                                  value <=
                                    parseFloat(
                                      welfareRemainingRight.remaingOPD_Number
                                    ) &&
                                  value <=
                                    parseFloat(getValues("withdrawal")) -
                                      parseFloat(getValues("helpingSurplus"))
                                );
                              },
                              onValueChange: (values) => {
                                const { value } = values;
                                field.onChange(value);
                              },
                            }}
                            onChange={() => {}}
                          ></TextFieldTheme>
                        )}
                      />
                    </Grid>
                  </>
                )}
                {usedRight && usedRight.limitIPD && (
                  <>
                    <Grid item xs={12}>
                      <StyledHeadLabel variant="subtitle1">
                        &#x2022;&nbsp;&nbsp;วงเงิน IPD
                      </StyledHeadLabel>
                    </Grid>
                    <Grid item xs={0} md={1} />
                    <Grid item xs={12} md={3}>
                      <StyledBox>
                        <StyledHeadLabel variant="subtitle2">
                          วงเงินทั้งหมด
                        </StyledHeadLabel>
                        <span>
                          &nbsp;&nbsp;
                          {utils.numberWithCommas(
                            parseFloat(usedRight.limitIPD)
                          )}{" "}
                          บาท
                        </span>
                      </StyledBox>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <StyledBox>
                        <StyledHeadLabel variant="subtitle2">
                          วงเงินคงเหลือ
                        </StyledHeadLabel>
                        <span>
                          &nbsp;&nbsp;
                          {utils.numberWithCommas(
                            parseFloat(welfareRemainingRight.remaingLimitIPD)
                          )}{" "}
                          บาท
                        </span>
                      </StyledBox>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Controller
                        control={control}
                        name={`usage.usedLimitIPD`}
                        render={({ field, formState }) => (
                          <TextFieldTheme
                            {...field}
                            label="จำนวนเงินที่หักจากสิทธิ"
                            error={
                              formState.errors && formState.usedLimitIPD
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors && formState.errors.usedLimitIPD
                                ? formState.errors.usedLimitIPD.message
                                : null
                            }
                            InputProps={{
                              inputComponent: NumberFormatTheme,
                            }}
                            inputProps={{
                              decimalScale: 2,
                              allowNegative: false,
                              value: field.value,
                              suffix: " บาท",
                              isAllowed: (values) => {
                                const { value } = values;
                                return (
                                  value <=
                                    parseFloat(
                                      welfareRemainingRight.remaingLimitIPD
                                    ) &&
                                  value <=
                                    parseFloat(getValues("withdrawal")) -
                                      parseFloat(getValues("helpingSurplus"))
                                );
                              },
                              onValueChange: (values) => {
                                const { value } = values;
                                field.onChange(value);
                              },
                            }}
                            onChange={() => {}}
                          ></TextFieldTheme>
                        )}
                      />
                    </Grid>
                  </>
                )}
                {usedRight && usedRight.limitDental && (
                  <>
                    <Grid item xs={12}>
                      <StyledHeadLabel variant="subtitle1">
                        &#x2022;&nbsp;&nbsp;วงเงินทันตกรรม
                      </StyledHeadLabel>
                    </Grid>
                    <Grid item xs={0} md={1} />
                    <Grid item xs={12} md={3}>
                      <StyledBox>
                        <StyledHeadLabel variant="subtitle2">
                          วงเงินทั้งหมด
                        </StyledHeadLabel>
                        <span>
                          &nbsp;&nbsp;
                          {utils.numberWithCommas(
                            parseFloat(usedRight.limitDental)
                          )}{" "}
                          บาท
                        </span>
                      </StyledBox>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <StyledBox>
                        <StyledHeadLabel variant="subtitle2">
                          วงเงินคงเหลือ
                        </StyledHeadLabel>
                        <span>
                          &nbsp;&nbsp;
                          {utils.numberWithCommas(
                            parseFloat(welfareRemainingRight.remaingLimitDental)
                          )}{" "}
                          บาท
                        </span>
                      </StyledBox>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Controller
                        control={control}
                        name={`usage.usedLimitDental`}
                        render={({ field, formState }) => (
                          <TextFieldTheme
                            {...field}
                            label="จำนวนเงินที่หักจากสิทธิ"
                            error={
                              formState.errors && formState.usedLimitDental
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors &&
                              formState.errors.usedLimitDental
                                ? formState.errors.usedLimitDental.message
                                : null
                            }
                            InputProps={{
                              inputComponent: NumberFormatTheme,
                            }}
                            inputProps={{
                              decimalScale: 2,
                              allowNegative: false,
                              value: field.value,
                              suffix: " บาท",
                              isAllowed: (values) => {
                                const { value } = values;
                                return (
                                  value <=
                                    parseFloat(
                                      welfareRemainingRight.remaingLimitDental
                                    ) &&
                                  value <=
                                    parseFloat(getValues("withdrawal")) -
                                      parseFloat(getValues("helpingSurplus"))
                                );
                              },
                              onValueChange: (values) => {
                                const { value } = values;
                                field.onChange(value);
                              },
                            }}
                            onChange={() => {}}
                          ></TextFieldTheme>
                        )}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
        </>
      )}
    </Grid>
  );
};

export default WelfareRightUsage;
