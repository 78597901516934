import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useDropzone } from 'react-dropzone';
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Link,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DeleteIcon from '@mui/icons-material/Delete';
import { addManagerKPI, getKPIByAdmin } from '../../../../actions/kpi';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

import AddIcon from "../../assets/add.png";
import ButtonBlue from "../../shared/general/ButtonBlue";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    minWidth: '600px',
    maxWidth: '600px',
    width: '80%',
  },
}));

const StyledImportFile = styled("div")({
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  marginTop: 24,
  "& .dropzone-upload-file": {
    textAlign: "center",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0", 
    borderStyle: "dashed",
    borderRadius: 8,
    height: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& .inner-dropzone": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  },
});

const DialogDropzone = ({ open, onClose, handleOpenAlert }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [disable, setDisable] = useState(false);
  const [files, setFiles] = useState(null);
  const [fileSelected, setFileSelected] = useState(null);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles[0]);
      setFileSelected({
        name: acceptedFiles[0].name,
        size: acceptedFiles[0].size,
      });
    }
  });

  const handleSubmit = async () => {
    setDisable(true);
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);

        const processedData = jsonData.map((row) => {
          if (!row["ผู้ประเมิน"]) {
            throw new Error("Assessor field cannot be null");
          }

          const startDate = XLSX.SSF.parse_date_code(row["วันที่เริ่มต้น"]);
          const endDate = XLSX.SSF.parse_date_code(row["วันที่สิ้นสุด"]);

          return {
            idEmployees: row["รหัสพนักงาน"],
            kpiName: row["ชื่อ KPI"],
            scope: row["SCOPE"],
            target: row["เป้าหมาย"],
            measureType: row["ประเภทข้อมูลใช้วัด"],
            evidence: row["Evidence"],
            description: row["รายละเอียด"],
            startDate: dayjs(new Date(startDate.y, startDate.m - 1, startDate.d)).format("YYYY-MM-DD"),
            endDate: dayjs(new Date(endDate.y, endDate.m - 1, endDate.d)).format("YYYY-MM-DD"),
            weight: row["น้ำหนัก"],
            asessor: row["ผู้ประเมิน"],
          };
        });

        const res = await dispatch(addManagerKPI(processedData));

        if (res && res.status === 200) {
          Swal.fire({
            title: t("Success"),
            text: t("UploadSuccessful"),
            icon: 'success',
            timer: 2000,
            showConfirmButton: false
          });
          dispatch(getKPIByAdmin());
          setFiles(null);
          setFileSelected(null);
        } else {
          throw new Error(res.message || t("FileUploadFailed"));
        }
      } catch (error) {
        console.error("Error during file processing: ", error);
        handleOpenAlert("error", error.message || "An error occurred. Contact Admin.");
      } finally {
        setDisable(false);
        onClose();
      }
    };
    reader.readAsBinaryString(files);
  };

  const generateAndDownloadFile = (template) => {
    const elementList = [];
    elementList.push(template.header);

    const ws = XLSX.utils.json_to_sheet(elementList);
    const workbook = XLSX.utils.book_new();

    template.sheet.forEach((element) => {
      XLSX.utils.book_append_sheet(workbook, ws, element);
    });

    XLSX.writeFile(workbook, template.fileName, {
      type: "file",
    });
  };

  const handleClickDownload = () => {
    const template = {
      sheet: ["KPI"],
      fileName: "KPI.xlsx",
      header: {
        employeeID: "",
        firstname: "",
        lastname: "",
        kpiName: "",
        scope: "",
        target: "",
        measureType: "",
        evidence: "",
        description: "",
        weight: "",
        asessor: "",
        startDate: "",
        endDate: "",
      },
    };

    generateAndDownloadFile(template);
  };

  const handleRemoveFile = () => {
    setFiles(null);
    setFileSelected(null);
  };

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle>{t("Upload")}</DialogTitle>
      <div
        style={{
          marginBottom: 1,
          display: "flex",
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        <Link 
          variant="body2"
          download
          href={`${process.env.REACT_APP_API_URL}files/File Dump KPI.xlsx`}
        >
          {t("DownloadTemplate")}
        </Link>
      </div>
      <DialogContent>
        <StyledImportFile>
          <div {...getRootProps({ className: "dropzone-upload-file" })}>
            <input {...getInputProps()} />
            <div className="inner-dropzone">
              {fileSelected ? (
                <>
                  <Typography variant="h6">{fileSelected.name}</Typography>
                  <IconButton color="error" onClick={handleRemoveFile}>
                    <DeleteIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <img alt="AddIcon" src={AddIcon} width="80" />
                  <Typography
                    style={{
                      marginTop: 8,
                      backgroundColor: "transparent",
                    }}
                    variant="body2"
                    color="text.secondary"
                  >
                    {t("DragDropOrClickSelectFile")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    (xlsx)
                  </Typography>
                </>
              )}
            </div>
          </div>
        </StyledImportFile>
      </DialogContent>
      <DialogActions>
        <Button type="button" color="error" onClick={onClose}>
          {`${t("Cancel")}`}
        </Button>
        <ButtonBlue
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={fileSelected === null || disable}
        >
          {`${t("Confirm")}`}
        </ButtonBlue>
      </DialogActions>
    </CustomDialog>
  );
};

export default DialogDropzone;
