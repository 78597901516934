import {
  LEAVE_3MONTH_FETCHING,
  LEAVE_3MONTH_FAILED,
  LEAVE_3MONTH_SUCCESS,
} from "../actions/types";

const initialState = {
  result: null,
  isFetching: false,
  isError: false
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
      case LEAVE_3MONTH_FETCHING:
          return { ...state, result: null, isFetching: true, isError: false };
      case LEAVE_3MONTH_FAILED:
          return { ...state, result: null, isFetching: false, isError: true };
      case LEAVE_3MONTH_SUCCESS:
          return { ...state, result: payload, isFetching: false, isError: false };
      default:
          return state;
  }
};