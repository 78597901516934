import React from "react";
import { Typography, Breadcrumbs, Grid } from "@mui/material";

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import { Link } from "react-router-dom";



const useStyles = makeStyles(() => ({
  dataText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Breadcrumb3 = ({ textLabel, icon, link, prevText, prevText2,link2 }) => {
  const classes = useStyles();

  return (
    
      
      <Grid>
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 10 }}>
          <Link to={link} style={{ textDecoration: "none" }}>
            <div className={classes.dataText}>
              {icon}
              <Typography component={"span"} color="textPrimary">
                {prevText}
              </Typography>
            </div>
          </Link>
          <Link to={link2} style={{ textDecoration: "none" }}>
            <div className={classes.dataText}>
              <Typography component={"span"} color="textPrimary">
                {prevText2}
              </Typography>
            </div>
          </Link>
          <Typography component={"span"} color="textPrimary">
            {textLabel}
          </Typography>
        </Breadcrumbs>
      </Grid>
     
    
  );
};

export default Breadcrumb3;
