import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Stack,
  TextField,
  Paper,
  Chip,
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { getKpiUpdateById, addKpiProgress , addKpiProgressbar } from "../../../../../actions/kpi";
import { useLocation } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import ButtonBlue from '../../../shared/general/ButtonBlue';
import UploadDataDialog from '../uploadData';
import LinearProgress from "@mui/material/LinearProgress";
import DeleteIcon from '@mui/icons-material/Delete';

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});


const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  borderRadius: '30px',
  overflow: 'hidden',
  border: '1px solid #EEEEEE',
  display: 'flex',
  justifyContent: 'center',
  width: 'fit-content',
}));

const StyledToggleButton = styled(ToggleButton)(({ theme, selectedColor }) => ({
  flex: 1,
  borderRadius: '30px',
  '&.Mui-selected': {
    backgroundColor: selectedColor,
    color: '#FFFFFF',
  },
  '&.MuiToggleButtonGroup-grouped': {
    border: 'none',
    '&:not(:first-of-type)': {
      borderLeft: '1px solid #EEEEEE',
    },
  },
  '&:hover': {
    backgroundColor: `${selectedColor} !important`,
    color: '#FFFFFF',
  },
  backgroundColor: '#EEEEEE',
  color: '#000000',
  textAlign: 'center',
  minWidth: '100px',
  maxWidth: '200px',
}));

const StyledRoot = styled(Box)({
  display: "flex",
  justifyContent: "center",
  padding: "24px 0",
});

const GridStyled = styled(Grid)({
  backgroundColor: "#E6EFF5",
  padding: "16px",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "16px",
  height: "70px",
});

const monthList = [
  "Months.January",
  "Months.February",
  "Months.March",
  "Months.April",
  "Months.May",
  "Months.June",
  "Months.July",
  "Months.August",
  "Months.September",
  "Months.October",
  "Months.November",
  "Months.December",
];


const KpisUpdate = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: kpiPlan } = useSelector((state) => state.kpiUpdateId);
  const isUser = userProfile && userProfile.roles.includes("ROLE_USER");

  const [expanded, setExpanded] = useState(false);
  const [values, setValues] = useState({ kpiPlan: "", kpiAchievement: "", kpiFailure: "", kpiKeyLearning: "" });
  const [kpiSuccess, setKpiSuccess] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [progressValue, setProgressValue] = useState('');
  const progress = (kpiPlan && kpiPlan.progress) ? kpiPlan.progress : 0;

  const handleAddKpiProgress = async (formData) => {
    const res = await dispatch(addKpiProgress(formData));
    if (res && res.status === 200) {
      dispatch(getKpiUpdateById(state.planList.idKPI));
    }
  };
  const handleFileSubmit = async (file) => {

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to upload this file?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      setUploadedFile(file);
    }
  };
  
  
  const handleSubmit = async (id) => {
    const formData = new FormData();
    formData.append('kpiAchievement', values.kpiAchievement);
    formData.append('kpiFailure', values.kpiFailure);
    formData.append('kpiKeyLearning', values.kpiKeyLearning);
    formData.append('kpiSuccess', kpiSuccess);
    formData.append('file', uploadedFile);  
    formData.append('month', id);
    formData.append('idKpi', kpiPlan.idKPI);
  
    try {
      const result = await Swal.fire({
        title: t("AreYouSure"),
        text: t("DoYouWantToAddPlanThisMonth"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Confirm"),
        cancelButtonText: t("Cancel"),
      });
  
      if (result.isConfirmed) {
        await handleAddKpiProgress(formData);
        setUploadedFile(null);
  
        Swal.fire({
          title: 'สำเร็จ',
          text: 'ข้อมูล KPI ได้รับการอัปเดตแล้ว',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Error submitting KPI:", error);
      Swal.fire({
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถอัปเดตข้อมูล KPI ได้',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    let selectMonthKPI;
    if (kpiPlan && kpiPlan.updates) {
      selectMonthKPI = kpiPlan.updates.find(function (item) {
        return item.month === panel;
      });
    }
    if (selectMonthKPI && isExpanded) {
      setValues(selectMonthKPI);
      setKpiSuccess(selectMonthKPI.kpiSuccess);
    } else {
      setValues({ kpiPlan: "", kpiAchievement: "", kpiFailure: "", kpiKeyLearning: "" });
      setKpiSuccess(null);
    }
    setExpanded(isExpanded ? panel : false);
  };

  const handleUploadClick = () => {
    setUploadDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setUploadDialogOpen(false); 
  };

  const handleOpenProgressDialog = () => {
    setProgressDialogOpen(true);
  };

  const handleCloseProgressDialog = () => {
    setProgressDialogOpen(false);
  };

  const handleConfirmProgress = async () => {
    const formData = {
      progress: progressValue,
      idKPI: state.planList.idKPI,
    };

    const res = await dispatch(addKpiProgressbar(formData));
    if (res && res.status === 200) {
      dispatch(getKpiUpdateById(state.planList.idKPI));
      Swal.fire({
        title: "Success",
        text: "KPI progress has been updated successfully.",
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
      handleCloseProgressDialog(); 
    } else {
      Swal.fire({
        title: "Error",
        text: "Failed to update KPI progress.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleRemoveFile = () => {
    setUploadedFile(null); 
  };

  const columns = [
  ];

  useEffect(() => {
    if (state.planList.idKPI) {
      dispatch(getKpiUpdateById(state.planList.idKPI));
    }
  }, [dispatch, state.planList.idKPI]);

  return (
    <StyledRoot className="page">
      <Container>
        <GridStyled container justifyContent="space-between" alignItems="center" mt={2}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" className="kpi" sx={{ ml: 1, fontSize: "30px" }}>
              {t("Update")} KPI
            </Typography>
          </Box>
        </GridStyled>
        <div className="cardPlan">
          <Box padding="2px 0">
            <Container maxWidth="lg">
              <Box padding="2px 0" minHeight="700px">
                {kpiPlan && (
                  <Box padding="0px">
                    <Box>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            backgroundColor: "#4f65df",
                            borderRadius: 15,
                            marginBottom: 15,
                            height: "50px",
                          }}
                        >
                          <Typography
                            fontSize={16}
                            variant="h6"
                            sx={{ textAlign: "center", color: "#ffffff" }}
                          >
                            {t("UpdateKPISuccessPlanMonth")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {columns.map((c, index) => (
                              <StyledCellHeader
                                key={index}
                                sx={{
                                  textAlign: c.headerTextAlign
                                    ? c.headerTextAlign
                                    : undefined,
                                  minWidth: c.minWidth ? c.minWidth : undefined,
                                  width: c.width ? c.width : undefined,
                                  maxWidth: c.maxWidth ? c.maxWidth : undefined,
                                }}
                              >
                                {c.name}
                              </StyledCellHeader>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            {columns.map((c, cIndex) => (
                              <StyledCellContent
                                key={cIndex}
                                style={{
                                  textAlign: c.cellTextAlign ? c.cellTextAlign : undefined,
                                  fontSize: c.cellFontSize ? c.cellFontSize : undefined,
                                  minWidth: c.minWidth ? c.minWidth : undefined,
                                  width: c.width ? c.width : undefined,
                                  maxWidth: c.maxWidth ? c.maxWidth : undefined,
                                }}
                              >
                                {c.cellRender(state.planList)}
                              </StyledCellContent>
                            ))}
                          </TableRow>
                          {(!kpiPlan || kpiPlan.length <= 0) && (
                            <TableRow>
                              <TableCell colSpan={8} align="center">
                                <Box
                                  height="120px"
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Typography variant="h6">
                                    <FindInPageIcon />
                                    {t("NoData")}
                                  </Typography>
                                </Box>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                      <div>
                        {[...Array(dayjs(kpiPlan.endDate).get("month") + 1).keys()]
                          .slice(dayjs(kpiPlan.startDate).get("month"))
                          .map((item, index) => (
                            <Box component="form" noValidate autoComplete="off" key={index}>
                              <Accordion
                                expanded={expanded === item + 1}
                                onChange={handleChange(item + 1)}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography>
                                    {index + 1}. {t(monthList[item])}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Stack alignItems="center" spacing={2}>
                                    <Box
                                      fullWidth
                                      sx={{
                                        width: "98.5%",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                        padding: "8px",
                                        backgroundColor: "#f9f9f9",
                                        minHeight: "96px",
                                      }}
                                    >
                                      {values[`kpiPlan`] || ""}
                                    </Box>
                                    {dayjs().get("month") > item && (
                                    <>
                                      <TextField
                                        name={`kpiAchievement`}
                                        value={values[`kpiAchievement`] || ""}
                                        onChange={(e) => handleInputChange(e)}
                                        fullWidth
                                        label={t("Achievement")}
                                        placeholder={t("Achievement")}
                                        multiline
                                        rows={3}
                                        InputProps={{ readOnly: !isUser }}
                                      />
                                      <TextField
                                        name={`kpiFailure`}
                                        value={values[`kpiFailure`] || ""}
                                        onChange={(e) => handleInputChange(e)}
                                        fullWidth
                                        label={t("Failure")}
                                        placeholder={t("Failure")}
                                        multiline
                                        rows={3}
                                        InputProps={{ readOnly: !isUser }}
                                      />
                                      <TextField
                                        name={`kpiKeyLearning`}
                                        value={values[`kpiKeyLearning`] || ""}
                                        onChange={(e) => handleInputChange(e)}
                                        fullWidth
                                        label={t("KeyLearning")}
                                        placeholder={t("KeyLearning")}
                                        multiline
                                        rows={3}
                                        InputProps={{ readOnly: !isUser }}
                                      />
                                      <Box width="100%">
                                        <ButtonBlue
                                          variant="contained"
                                          onClick={handleUploadClick} 
                                          fullWidth
                                        >
                                          {t("Import")}
                                        </ButtonBlue>
                                      </Box>
                                      {uploadedFile && (
                                        <Box display="flex" alignItems="center" mt={2} width="100%">
                                          <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                                            {uploadedFile.name}
                                          </Typography>
                                          <IconButton onClick={handleRemoveFile} color="error" size="small">
                                            <DeleteIcon />
                                          </IconButton>
                                        </Box>
                                      )}

                                      {isUser && (
                                        <div
                                          style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <StyledToggleButtonGroup
                                            value={kpiSuccess}
                                            exclusive
                                            onChange={(event, newValue) => setKpiSuccess(newValue)}
                                            aria-label="Platform"
                                          >
                                            <StyledToggleButton value={1} selectedColor="#50B498">
                                              {t("Success")}
                                            </StyledToggleButton>
                                            <StyledToggleButton value={0} selectedColor="#FF4C4C">
                                              {t("Failure")}
                                            </StyledToggleButton>
                                          </StyledToggleButtonGroup>
                                          <ButtonBlue
                                            variant="contained"
                                            onClick={() => handleSubmit(item + 1)}
                                          >
                                            {t("Update")}
                                          </ButtonBlue>
                                        </div>
                                      )}
                                    </>
                                  )}
                                  </Stack>
                                </AccordionDetails>
                              </Accordion>
                            </Box>
                          ))}
                      </div>
                    </TableContainer>
                  </Box>
                )}
              </Box>
            </Container>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" alignItems="center" justifyContent="center" width="100%" mb={1}>
              <Typography mr={2} color="#FF4E88" fontWeight={600}>
                {t("Progress")}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                  width: '250px',
                  height: '8px',
                  borderRadius: '5px',
                  backgroundColor: '#FFCBCB',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#FF4E88',
                  },
                }}
              />
              <Typography ml={1} color="#FF4E88" fontWeight={600}>
                {progress}%
              </Typography>
            </Box>

            <ButtonBlue
              variant="text"
              onClick={handleOpenProgressDialog}
              sx={{ mt: -1 }} 
            >
              {t("Update")} {t("Progress")}
            </ButtonBlue>
          </Box>
        </div>
      </Container>
      
      <UploadDataDialog 
        open={uploadDialogOpen} 
        handleClose={handleCloseDialog} 
        onFileSubmit={handleFileSubmit} 
      />

      <Dialog
        open={progressDialogOpen}
        onClose={handleCloseProgressDialog}
        aria-labelledby="progress-dialog-title"
        sx={{
          "& .MuiDialog-paper": {
            width: "500px", 
            height: "200px", 
            borderRadius: "20px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#FAFAFA",
          },
        }}
      >
        <DialogTitle id="progress-dialog-title">
          <Typography variant="h5">{t("Progress")}</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="progress"
            label={t("Progress")}
            type="number"
            fullWidth
            variant="standard"
            value={progressValue}
            onChange={(e) => setProgressValue(e.target.value)}
            InputProps={{
              endAdornment: <Typography>%</Typography>,
            }}
          />
          <Typography variant="caption" color="error">
            {t("Over100PercentAllowed")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button type="button" color="error" onClick={handleCloseProgressDialog}>
            {`${t("Cancel")}`}
          </Button>
          <ButtonBlue variant="text" onClick={handleConfirmProgress}>
            {t("Confirm")}
          </ButtonBlue>
        </DialogActions>
      </Dialog>
    </StyledRoot>
  );
};

export default KpisUpdate;
