import React, { Fragment, useState } from "react";
import { Link as LinkR, NavLink } from "react-router-dom";
import { CardHeader, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";

import CardStyle from "../../../../shared/general/Card";
import DialogConfirmCloseJob from "./dialogConfirmCloseJob";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import VerifyIcon from "./../../../../assets/verify.png";

import { useDispatch, useSelector } from "react-redux";
import {
  closeJob,
  getCompanyMyJob,
} from "../../../../../../actions/jobRecruit";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledCardContent = styled(CardContent)({
  height: "calc(100% - 48px)",
  borderRadius: 20,
  backgroundColor: "#FFFFFF",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  textDecoration: "none",
  color: "initial",
  "&.MuiCardContent-root": {
    paddingTop: 0,
  },
  "& .part-company": {
    display: "flex",
    marginBottom: 10,
  },
  "& .part-one": {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
    "& .MuiAvatar-root": {
      width: 75,
      height: 75,
      borderRadius: 8,
      backgroundColor: "white",
      marginRight: 20,
      "& img": {
        width: "inherit",
        height: "inherit",
        objectFit: "contain",
      },
    },
  },
  "& .part-two": {
    width: "calc(100% - 95px)",
    "& .MuiTypography-h5": {
      width: 280,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  "& .part-three": {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  "& .part-four": {
    "& span": {
      marginLeft: 10,
    },
  },
  "& .part-five": {
    "& .wrap-item": {
      display: "flex",
      color: "#637381",
      alignItems: "center",
      "& .fal": {
        marginRight: 8,
      },
    },
  },
  "& .part-manage": {
    height: 30,
    marginBottom: 16,
    display: "flex",
    alignItems: "center",
  },
});

const StyledChip = styled(Chip)({
  color: "#b72136",
  backgroundColor: "#ff484229",
  fontWeight: 700,
  borderRadius: 6,
});

const StyledGridByPass = styled(Grid)({
  height: 90,
  "& .gridStatus": {
    display: "flex",
    alignItems: "center",
    "& .icon": {
      marginRight: 16,
      fontSize: 24,
    },
    "& .text": {
      fontSize: 24,
    },
    "& .finding": {
      color: "#C97800",
    },
    "& .offered": {
      color: "#007afe",
    },
  },
});

const CardRequestManage = (props) => {
  const { value, isManage } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const isMenuOpen = Boolean(anchorEl);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCloseJob = async (idJobRecruit) => {
    const res = await dispatch(closeJob(idJobRecruit));
    if (res.message === "success") {
      dispatch(getCompanyMyJob());
    }
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          backgroundColor: "#ffffff",
          color: "#000000",
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          borderRadius: "20px",
          width: "200px",
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem
        component={LinkR}
        to={{
          pathname: `/manage/company/openjob/form`,
          search: "?edit=1",
          state: {
            idJobRecruit: value.idJobRecruit,
          },
        }}
      >
        <ListItemIcon>
          <i className="fa-solid fa-pen"></i>
        </ListItemIcon>
        {`${t("EditData")}`}
      </MenuItem>
      <MenuItem
        component={LinkR}
        // to={`/company-candidate/job/${value.idJobRecruit}`}
        to={`/company-applicant/job/${value.idJobRecruit}`}
      >
        <ListItemIcon>
          <i className="fa-solid fa-users"></i>
        </ListItemIcon>
        {`${t("Applicant")}`}
      </MenuItem>
      <MenuItem onClick={() => setOpen(true)}>
        <ListItemIcon>
          <i className="fa-solid fa-calendar-xmark"></i>
        </ListItemIcon>
        {`${t("ApplicationClosed")}`}
      </MenuItem>
    </Menu>
  );

  return (
    <CardStyle
      style={{
        height: "100%",
        minHight: "fit-content",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <CardHeader
        avatar={
          value && value.isApproved === 1 ? (
            <img src={VerifyIcon} width="30" height="32" />
          ) : null
        }
        action={
          <ButtonBlue
            variant="outlined"
            startIcon={<i className="fa-regular fa-gear"></i>}
            size="small"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            {`${t("Manage")}`}
          </ButtonBlue>
        }
      />
      <StyledCardContent
        component={NavLink}
        to={`/company/request/${value.idJobRecruit}`}
      >
        <Box>
          <div className={`part-one`}>
            <Avatar
              variant="rounded"
              src={
                value && value.idJobGroup
                  ? `${process.env.REACT_APP_API_URL}image/jobGroup/${value.idJobGroup}.png`
                  : "/assets/logo_default.png"
              }
            />
            <div className={`part-two`}>
              <Typography
                style={{
                  color: "#000000",
                  textDecoration: "none",
                  width: "100%",
                  fontWeight: 500,
                  fontSize: 22,
                  lineHeight: 1.4,
                  wordBreak: "break-word",
                }}
              >
                {value && value.positionName}
              </Typography>
            </div>
          </div>
        </Box>
        <Box>
          {value && !value.isByPass ? (
            <Fragment>
              <div className="part-company">
                <i
                  className="fa-solid fa-building"
                  style={{ color: "#637381", marginRight: 8 }}
                />
                <Typography variant="body2" style={{ color: "#637381" }}>
                  {value && (value.companyName || "-")}
                </Typography>
              </div>
              <div className={`part-three`}>
                <i
                  className="fa-solid fa-location-dot"
                  style={{ color: "#637381", marginRight: 8 }}
                ></i>
                <Typography variant="body2" style={{ color: "#637381" }}>
                  {value &&
                    `${value.locationAddress || "-"} ${
                      value.locationDistrict || "-"
                    } ${value.locationProvince || "-"}`}
                </Typography>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="part-three">
                <i
                  className="fa-solid fa-user"
                  style={{ color: "#637381", marginRight: 8 }}
                />
                <Typography variant="body2" style={{ color: "#637381" }}>
                  {value.employeeType === "FULLTIME"
                    ? `พนักงานประจำ`
                    : `พนักงานชั่วคราว`}
                </Typography>
              </div>
              <div className="part-three">
                <i
                  className="fa-solid fa-users"
                  style={{ color: "#637381", marginRight: 8 }}
                />
                <Typography variant="body2" style={{ color: "#637381" }}>
                  {`${value.quota || "-"} คน`}
                </Typography>
              </div>
            </Fragment>
          )}
          <div className={`part-four`}>
            <div
              style={{
                display: "flex",
                marginBottom: 10,
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex" }}>
                <i
                  className="fa-solid fa-calendar-pen"
                  style={{ color: "#637381", marginRight: 8 }}
                />
                <Typography variant="body2" color="text.secondary">
                  {`${t("OpeningDate")}`}:
                </Typography>
              </div>
              <div>
                <Typography variant="body2" color="text.secondary">
                  {value.createdDate &&
                    (dayjs(value.createdDate).format("DD-MMM-YYYY") ||
                      `${t("NotSpecified")}`)}
                </Typography>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <i
                  className="fa-solid fa-calendar-xmark"
                  style={{ color: "#637381", marginRight: 8 }}
                />
                <Typography variant="body2" color="text.secondary">
                  {`${t("ClosingDate")}`}:
                </Typography>
              </div>
              <div>
                <Typography variant="body2" color="text.secondary">
                  {value.closeRegisterDate &&
                    (dayjs(value.closeRegisterDate).format("DD-MMM-YYYY") ||
                      `${t("NotSpecified")}`)}
                </Typography>
              </div>
            </div>
          </div>

          <Divider
            style={{
              margin: "16px 0px 0px",
              borderWidth: "0px 0px thin",
              borderColor: "rgba(145, 158, 171, 0.24)",
              borderStyle: "dashed",
            }}
          />
          <div className={`part-five`}>
            {value && !value.isByPass ? (
              <Grid container>
                <Grid item xs={6}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      borderRight: "thin dashed rgba(145, 158, 171, 0.24)",
                      padding: "10px 0",
                    }}
                  >
                    <Typography>{`${t("JobOpenings")}`}</Typography>
                    <Typography
                      variant="h4"
                      style={{ marginTop: "5px", color: "#9063cd" }}
                    >
                      {value &&
                        (value.quota || (
                          <i className="fa-solid fa-infinity"></i>
                        ))}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "10px 0",
                    }}
                  >
                    <Typography>{`${t("Applicant")}`}</Typography>
                    <Typography
                      variant="h4"
                      style={{
                        marginTop: "5px",
                        color:
                          value && value.candidateCount
                            ? value &&
                              value.quota &&
                              parseInt(value.quota) >
                                parseInt(value.candidateCount)
                              ? "#919eab"
                              : "#039F00"
                            : "#919eab",
                      }}
                    >
                      {value && (value.candidateCount || 0)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <StyledGridByPass container justifyContent={"center"}>
                <Grid item className="gridStatus">
                  <Fragment>
                    <i className="fa-solid fa-clock icon finding" />
                    <Typography className="text finding">{`${t(
                      "LookingBusinessPartners"
                    )}`}</Typography>
                  </Fragment>
                  {/* <Fragment>
                    <i className="fa-solid fa-buildings icon offered" />
                    <Typography className="text offered">มีคู่ธุรกิจสนใจ</Typography>
                  </Fragment> */}
                </Grid>
              </StyledGridByPass>
            )}
          </div>
        </Box>
      </StyledCardContent>
      {renderMenu}
      <DialogConfirmCloseJob
        open={open}
        onClose={() => setOpen(false)}
        job={value}
        handleSubmit={() => handleCloseJob(value.idJobRecruit)}
      />
    </CardStyle>
  );
};

export default CardRequestManage;
