import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form"
import FeedIcon from '@mui/icons-material/Feed';
import dayjs from "dayjs";
import {
    Typography,
    Button,
    Stack,
    Container,
    IconButton,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    Paper,
    TableBody,
    TableCell,
    Chip,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Menu,
    Avatar,
    InputAdornment,
    FilledInput,
    Grid,
    TablePagination
} from "@mui/material";
import CardStyle from "../../shared/general/Card";
import SearchIcon from '@mui/icons-material/Search';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ButtonBlue from "../../shared/general/ButtonBlue";
import {
    getUsersInProject,
    getAllEmployeesInWorkforce,
    getEmployeesWeek,
    getSumUserTimeInProject
} from "../../../../actions/workforceEmployees";
import { getAllEmployees } from "../../../../actions/employee";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserDepartment, getUserFullName, getUserPosition, getUserSection } from "../../../../utils/userData";
import { useDispatch, useSelector } from "react-redux";

const StyledRoot = styled("div")({
    backgroundColor: "#f5f5f5",
    "& .MuiAutocomplete-root": {
        "& .MuiOutlinedInput-root": {
            padding: "13.5px 14px",
            paddingRight: "32px",
            "& input": {
                padding: 0,
            },
        },
    },
});



const WrapHeader = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ["@media only screen and (max-width:600px)"]: {
        flexDirection: "column",
    },
    marginTop: 8,
    marginBottom: 16,
});

const StyledFilledInput = styled(FilledInput)({
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
        paddingTop: 18,
        paddingBottom: 18,
    },
    "&.Mui-focused": {
        backgroundColor: "transparent",
    },
    "& .MuiInputAdornment-root": {
        width: 32,
        marginTop: "0!important",
        fontSize: 24,
        color: "#919EAB",
        "& i": {
            marginRight: 8,
        },
    },
    "& .MuiAutocomplete-endAdornment": {
        "& .MuiButtonBase-root": {
            fontSize: 14,
            width: 22,
            height: 22,
        },
    },
    "&:hover": {
        backgroundColor: "#919eab29",
        "&:before": {
            border: "none !important",
        },
    },
    "&::after": {
        border: "none",
    },
    "&::before": {
        border: "none",
    },
});


const TableHour = () => {
    const datetoday = new Date();
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation();
    let history = useHistory();
    const [monthUse, setMonthUse] = useState((datetoday.getMonth() + 1))
    const [yearUse, setYearUse] = useState((datetoday.getFullYear()))
    const { result: projectGetUsers } = useSelector(
        (state) => state.projectGetUsers
    );
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: getSumTimeUserInProject } = useSelector((state) => state.getSumTimeUserInProject)
    const { result: empInWorkforce } = useSelector((state) => state.empInWorkforce)
    const { result: empWorkforceWeek } = useSelector((state) => state.empWorkforceWeek)
    const openDetail = (detail) => {
        const queryParams = new URLSearchParams({
            MonthCreated: monthUse,
            YearCreated: yearUse,
            idEmployees: detail.idEmployees,
        }).toString();
        history.push(`/workforce-planning-cal/detailProjectHour?${queryParams}`);
    };

    const opendashboard = (detail) => {
        const queryParams = new URLSearchParams({
            MonthCreated: monthUse,
            YearCreated: yearUse,
            idEmployees: detail.idEmployees
        }).toString();
        history.push(`/workforce-planning-cal/dashboardProjectHour?${queryParams}`);
    };
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [total, setTotal] = useState(0);
    const useHookForm = useForm({
        defaultValues: {
            name: "",
        },
        mode: "all",
    })

    const onSubmit = async (data) => {

        const searchData = {
            name: data.name ? data.name : null,
        }
        if (searchData.name) {
            const result = searchData.name ? empInWorkforce.filter(item => getUserFullName(item).toLowerCase().includes(searchData.name.toLowerCase())) : empInWorkforce;
            setFilteredData(result)
        } else {
            setFilteredData(empInWorkforce);
        }
    }
    const handleChange = (event) => {
        setMonthUse(event.target.value);
    };

    useEffect(() => {
        dispatch(getAllEmployees());
        dispatch(getAllEmployeesInWorkforce({ month: monthUse,year:yearUse }))
        dispatch(getUsersInProject());
        // dispatch(getUsersInProjectShowHour());
        dispatch(getEmployeesWeek())
        dispatch(getSumUserTimeInProject())
    }, []);
    const [filteredData, setFilteredData] = useState([]);
    useEffect(() => {
        if (empInWorkforce) {
            // console.log(empInWorkforce)
            const filtered = empInWorkforce.filter(item => item.month === monthUse && item.year === yearUse);
            setFilteredData(empInWorkforce);
        } else {
            console.log("empInWorkforce is not loaded or is null.");
        }
    }, [empInWorkforce]);
    useEffect(()=>{
        dispatch(getAllEmployeesInWorkforce({ month: monthUse,year:yearUse }))
    },[monthUse,yearUse])

    const handleChangeYear = (event) => {
        setYearUse(event.target.value);
    };

    const [dateWeek, setDateWeek] = useState([])
    useEffect(() => {
        let temp = [];


        if (monthUse < 1 || monthUse > 12) {
            console.error('Invalid monthUse value:', monthUse);
            return;
        }
        if (!yearUse || yearUse < 2023 || yearUse > 2025) {
            console.error('Invalid yearUse value:', yearUse);
            return;
        }


        let fid = dayjs(`${yearUse}-${String(monthUse).padStart(2, '0')}-01`, 'YYYY-MM-DD');

        if (fid.day() !== 1) {
            fid = fid.add((8 - fid.day()), 'day');
        }

        temp.push({
            start: fid.clone(),
            end: fid.clone().add(6, "day"),
            count: 0,
            weekCount: 1
        });

        let endDate = fid.clone().add(6, "day");
        let endTemp = dayjs(`${yearUse}-${String(monthUse).padStart(2, '0')}-01`).endOf('month');
        let weekCount = 1;

        while (endDate.isBefore(endTemp, "day")) {
            fid = endDate.clone().add(1, "day");
            endDate = fid.clone().add(6, "day");

            temp.push({
                start: fid.clone(),
                end: endDate.clone(),
                count: 0,
                weekCount: ++weekCount
            });
        }

        setDateWeek(temp);
    }, [monthUse, yearUse]);
    const [searchTerm, setSearchTerm] = useState('');

    
    // const currentTableData = useMemo(() => {
    //     const firstPageIndex = (currentPage - 1) * rowsPerPage;
    //     const lastPageIndex = firstPageIndex + rowsPerPage;
    //     return filteredData.slice(firstPageIndex, lastPageIndex);
    // }, [currentPage, rowsPerPage, filteredData]);
    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * rowsPerPage;
        const lastPageIndex = firstPageIndex + rowsPerPage;
        // console.log(filteredData)
        return filteredData&&filteredData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, rowsPerPage, filteredData]);
    const openProjectDetail = () => {
        history.push('/workforce-planning-cal/projectDetails')
    }

    return (
        <StyledRoot className={"page"}>
            <Container maxWidth="lg">
                {/* <Button onClick={test}>test db</Button> */}
                <WrapHeader>
                    <Typography variant="h4">Workforce {t("summaryTime")}</Typography>
                </WrapHeader>
                <CardStyle style={{ padding: 16 }}>
                    <Stack
                        direction={{ xs: "column-reverse", sm: "row" }}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        marginBottom={4}
                        gap={2}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={5}>
                                <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
                                    <Controller
                                        name="name"
                                        control={useHookForm.control}
                                        render={({ field, fieldState }) => (
                                            <FormControl fullWidth variant="filled">
                                                <StyledFilledInput
                                                    {...field}
                                                    placeholder={`${t("Search")}${t("FullName")}`}
                                                    name="search"
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        )}
                                    />
                                </form>
                            </Grid>

                            <Grid item xs={12} md={7} container spacing={3}>
                                <Grid item xs={12} md={5}>
                                    <FormControl fullWidth>
                                        <InputLabel>{t("SelectByMonth")}</InputLabel>
                                        <Select
                                            value={monthUse}
                                            label={`${t("SelectByMonth")}`}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={1}>{t("Months.January")}</MenuItem>
                                            <MenuItem value={2}>{t("Months.February")}</MenuItem>
                                            <MenuItem value={3}>{t("Months.March")}</MenuItem>
                                            <MenuItem value={4}>{t("Months.April")}</MenuItem>
                                            <MenuItem value={5}>{t("Months.May")}</MenuItem>
                                            <MenuItem value={6}>{t("Months.June")}</MenuItem>
                                            <MenuItem value={7}>{t("Months.July")}</MenuItem>
                                            <MenuItem value={8}>{t("Months.August")}</MenuItem>
                                            <MenuItem value={9}>{t("Months.September")}</MenuItem>
                                            <MenuItem value={10}>{t("Months.October")}</MenuItem>
                                            <MenuItem value={11}>{t("Months.November")}</MenuItem>
                                            <MenuItem value={12}>{t("Months.December")}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel>{t("selectByYear")}</InputLabel>
                                        <Select
                                            value={yearUse}
                                            label={`${t("selectByYear")}`}
                                            onChange={handleChangeYear}
                                        >
                                            <MenuItem value="">{t("All")}</MenuItem>
                                            <MenuItem value={2023}>2023</MenuItem>
                                            <MenuItem value={2024}>2024</MenuItem>
                                            <MenuItem value={2025}>2025</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {userProfile && (userProfile.idRole === 4 || userProfile.idRole === 5) && (
                                    <Grid item xs={12} md={4}>
                                        <ButtonBlue
                                            variant="contained"
                                            startIcon={<FeedIcon />}
                                            onClick={openProjectDetail}
                                            fullWidth
                                        >
                                            {t("Check")} {t("Project")}
                                        </ButtonBlue>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>





                    </Stack>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 600 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">{t("EmployeeName")}</TableCell>
                                    <TableCell align="center">{t("Month")}</TableCell>
                                    {dateWeek && dateWeek.map(week => (
                                        <TableCell key={week.weekCount} align="center">w{week.weekCount}</TableCell>
                                    ))}
                                    <TableCell align="center">{t("Total")}</TableCell>
                                    <TableCell align="center">{t("Action")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {currentTableData && currentTableData.length > 0 ? (
                                    currentTableData.map((item, index) => {

                                        const totalWorkingHours = empWorkforceWeek && empWorkforceWeek.length > 0 ? (
                                            dateWeek.reduce((total, week) => total + (
                                                empWorkforceWeek
                                                    .filter(itemWeek =>
                                                        itemWeek.monthWork === monthUse && itemWeek.inYear === yearUse &&
                                                        itemWeek.idEmployees === parseInt(item.idEmployees) &&
                                                        itemWeek.inWeek === week.weekCount)
                                                    .reduce((acc, itemWeek) => acc + itemWeek.workingHours, 0)
                                            ), 0)
                                        ) : 0;
                                        return (
                                            <TableRow key={index}>
                                                <TableCell align="center">
                                                    <Stack direction={"row"} gap={2}>
                                                        <Avatar src={item.imageProfile} />
                                                        <div style={{ textAlign: "start" }}>
                                                            <Typography fontWeight={500}>
                                                                {/* <Typography>{item.Fullname}</Typography> */}
                                                                <Typography>{getUserFullName(item)}</Typography>
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {t("Position")}: {getUserPosition(item)}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {t("Department")}: {getUserDepartment(item)}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {t("Section")}: {getUserSection(item)}
                                                            </Typography>
                                                        </div>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {monthUse ? t(`Months.${["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][monthUse - 1]}`) : t("All")}
                                                </TableCell>
                                                {dateWeek.map(week => {
                                                    const weeklyHours = empWorkforceWeek && empWorkforceWeek.length > 0 ? (
                                                        empWorkforceWeek
                                                            .filter(itemWeek =>
                                                                itemWeek.monthWork === monthUse && itemWeek.inYear === yearUse &&
                                                                itemWeek.idEmployees === parseInt(item.idEmployees) &&
                                                                itemWeek.inWeek === week.weekCount)
                                                            .reduce((acc, itemWeek) => acc + itemWeek.workingHours, 0)
                                                    ) : 0;
                                                    return (
                                                        <TableCell align="center" key={week.weekCount}>
                                                            {weeklyHours || '0'}
                                                        </TableCell>
                                                    );
                                                })}
                                                <TableCell align="center"><strong style={{ color: 'blue' }}>{totalWorkingHours}</strong></TableCell>
                                                <TableCell align="center">
                                                    <IconButton style={{ color: "#000000" }} onClick={() => openDetail(item)}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                    <IconButton style={{ color: "#ff1744" }} onClick={() => opendashboard(item)}>
                                                        <ShowChartIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center">{t("NoData")}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={filteredData.length}
                        page={currentPage - 1}
                        onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={(event) => {
                            setRowsPerPage(parseInt(event.target.value, 10));
                            setCurrentPage(1);
                        }}
                    />
                </CardStyle>
            </Container>
        </StyledRoot >
    )
}

export default TableHour