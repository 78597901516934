import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Avatar, Box, Checkbox, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Menu, MenuItem, Radio, RadioGroup, styled, TextField, Typography } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import dayjs from 'dayjs';
import DrawerCustom from "../../../../shared/general/Drawer";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import { getNewCandidatesByIdJobRequest, getNewCandidatesByIdBiddings, updateInterviewDetail, getCandidatesAlreadySendToJob } from "../../../../../../actions/candidate";
import { getJobGroupInterviewer, getJobRecruitInterviewPanel, updateJobRecruitInterviewPanel } from "../../../../../../actions/jobRecruit";
import { getUserFullName } from "../../../../../../utils/userData";
import { t } from "i18next";

const StyledRoot = styled("div")({
  padding: 16,
  width: 450,
  maxWidth: 450,
  "& .MuiInputLabel-root .MuiInputLabel-formControl": {
    color: "#ffffff"
  },
  "& .part-footer": {
    paddingTop: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .cancel-button": {
      color: "#9e9e9e",
      borderColor: "#9e9e9e",
    },
    "& i": {
      marginRight: 8,
    }
  },
  "& .part-auto-appointment": {
    marginBottom: 24,
    "& .MuiButton-root": {
      paddingTop: 8,
      paddingBottom: 8,
    }
  },
  // "& .part-form": {
  //   "& .MuiInputBase-root": {
  //     height: 58,
  //   },
  // },
})

const StyledName = styled("div")({
  padding: 8,
  border: "1px solid rgba(0, 0, 0, 0.23)",
  borderRadius: 8,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#ffffff",
  "& .wrap-name": {
    display: "flex",
    alignItems: "center",
    width: 0,
    flexGrow: 1,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    "& .MuiTypography-root": {
      flexGrow: 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }
  },
  "& i": {
    paddingLeft: 8,
    color: "#9e9e9e",
  }
})

const DrawerInterviewPanel = (props) => {
  const { open, onClose, idJobRecruit, idJobGroup, RecruitInterviewPanelList } = props;
  const dispatch = useDispatch();
  const { result: jobGroupInterviewer } = useSelector((state) => state.jobGroupInterviewer);
  const [isInterviewPanelEmpty, SetIsInterviewPanelEmpty] = useState(false);

  const validationSchema = yup.object().shape({
    interviewer: yup.array().of(
      yup.object().shape({
        idEmployees: yup.number(),
      })
    ).max(20, t("selectUpTo20")),
  });

  const { control, handleSubmit, formState: { errors, isSubmitting, isDirty, dirtyFields}, getValues, trigger, setValue, watch } = useForm({
    defaultValues: {
      interviewer: [],
      isChangeInterview: false,
    },
    resolver: yupResolver(validationSchema),
  })

  const [interviewer, setInterviewer] = useState([]);

  const onSubmit = async (values) => {
    // console.log(values)
    const formData = values.interviewer;
    // console.log(idJobRecruit)
    dispatch(updateJobRecruitInterviewPanel(idJobRecruit, formData)).then(res => {
      dispatch(getJobRecruitInterviewPanel(idJobRecruit))
    });

    onClose();
  }

  useEffect(() => {
    if (RecruitInterviewPanelList) {
      setValue("interviewer", [...RecruitInterviewPanelList], {shouldDirty : false});
    }
  }, []);

  useEffect(() => {
    if (jobGroupInterviewer) {
      const jobGroupSelected = jobGroupInterviewer.find((group) => group.idJobGroup === idJobGroup)
      // console.log(jobGroupSelected);
      if (jobGroupSelected && jobGroupSelected.interviewers) {
        setInterviewer(jobGroupSelected.interviewers);
        SetIsInterviewPanelEmpty(false);
      } else {
        setInterviewer([]);
        SetIsInterviewPanelEmpty(true);
      }
    }
    return () => {
      setInterviewer([]);
    }
  }, [jobGroupInterviewer]);

  useEffect(() => {
    dispatch(getJobGroupInterviewer());
  }, []);

  // console.log(watch("interviewer"))
  // console.log(dirtyFields)

  return (
    <>
      <DrawerCustom
        title={t("editInterviewCommittee")}
        anchor="right"
        open={open}
        onClose={onClose}
      >
        <StyledRoot>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="interviewer"
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      options={interviewer}
                      getOptionLabel={(option) => `${getUserFullName(option)}`}
                      onChange={(event, value) => {
                        field.onChange(value);
                        setValue("isChangeInterview", true, {shouldDirty : true});
                        console.log(dirtyFields)
                        console.log(getValues());
                      }}
                      isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.idEmployees}>
                            {`${getUserFullName(option)}`}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error && fieldState.error.message}
                          label={t("committeeList")}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              {isInterviewPanelEmpty && (
                <Grid item xs={12}>
                  <Typography color="error">
                    {t("noCommitteeData")}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <div className="part-footer">
                  <ButtonBlue className="cancel-button" variant="outlined" onClick={onClose}>
                    <i className="fa-regular fa-x"></i>
                    {t("Cancel")}
                  </ButtonBlue>
                  <ButtonBlue
                    type="submit"
                    variant="contained"
                    disabled={!isDirty || isSubmitting}
                  >{t("Confirm")}</ButtonBlue>
                </div>
              </Grid>
            </Grid>
          </form>
        </StyledRoot>
      </DrawerCustom>
    </>
  )
}

export default DrawerInterviewPanel;