import {
  PROGRESSORGPLAN_FETCHING,
  PROGRESSORGPLAN_FAILED,
  PROGRESSORGPLAN_SUCCESS,
} from "./types";

import ProgressOrgPlanService from "../services/ProgressOrganizationPlans.service";

export const getAllProgressOrgPlan = () => async (dispatch) => {
  try {
    const res = await ProgressOrgPlanService.getAllProgressOrgPlan();
    if (res) {
      dispatch({
        type: PROGRESSORGPLAN_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PROGRESSORGPLAN_FAILED,
    });
    console.log(err);
  }
};

export const postProgressOrgPlan = (formData) => async () => {
  try {
    const res = await ProgressOrgPlanService.postProgressOrgPlan(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};
