import React, { useEffect, useState } from "react";
import { Box, Breadcrumbs, Container, Grid, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import DatePickerCustom from "../../../shared/date/datePicker";
import CardStyle from "../../../shared/general/Card";
import DataGrid, { Button, Column, Export, FilterRow, HeaderFilter, LoadPanel, Paging, Scrolling, SearchPanel, Selection, Sorting } from "devextreme-react/data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getSummaryOvertimeShift, getSummaryOvertimeShiftIndividual } from "../../../../../actions/report";
import SelectAffiliateCompany from "../../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../../actions/company";
import dayjs from "dayjs";
import TableAll from "./tableAll";
import TableIndividual from "./tableIndividual";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from "react-router-dom";
import loading from "../../../assets/social-media.gif"

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  "& .search-date-container": {
    marginTop: "24px",
    display: "flex",
    flexWrap: "nowrap",
    gap: "16px",
    "& .search-date-box": {
      maxWidth: "350px",
      width: "100%",
    },
    "& .search-button": {
      marginTop: "29px",
      display: "flex",
      alignItems: "center",
    },
  },

  ["@media only screen and (max-width: 600px)"]: {
    "& .search-date-container": {
      flexDirection: "column",
      "& .search-date-box": {
        maxWidth: "100%",
      },
      "& .search-button": {
        marginTop: "0",
        width: "100%",
      },
    }
  }
})

const StyledWrapDataGrid = styled(Box)({
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },

  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#009688",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },

  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },

  '& .column-working-hours[role="columnheader"]': {
    backgroundColor: "#FFFF00",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
});


const SummaryOTShift = () => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector(state => state.userProfile)
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);
  const { result: ReportSummaryOvertimeShiftList, isFetching: isReportSummaryOvertimeShiftListFetching } = useSelector(state => state.reportSummaryOvertimeShift)

  const [selectedCompany, setSelectedCompany] = useState(null)

  // const today = dayjs();

  const [filter, setFilter] = useState({
    start: new Date(dayjs().set('date', 1)),
    end: new Date(dayjs().set('date', dayjs().daysInMonth())),
    mode: "all",
  })

  let gridColumnsOverTime = [
    {
      dataField: "otOneHours",
      caption: `OT 1 (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otOneAmount",
      caption: `OT 1 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otOneFiveHours",
      caption: `OT 1.5 (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otOneFiveAmount",
      caption: `OT 1.5 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otTwoHours",
      caption: `OT 2 (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otTwoAmount",
      caption: `OT 2 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otThreeHours",
      caption: `OT 3 (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otThreeAmount",
      caption: `OT 3 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otAmountTotal",
      caption: `OT ${t("Total")} (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
  ];

  useEffect(() => {
    if (userProfile && userProfile.roles.includes("ROLE_ADMIN")) {
      dispatch(getAffiliateOrganization())
    };
  }, [userProfile])

  useEffect(() => {
    if ((userProfile && userProfile.roles.includes("ROLE_ADMIN")) && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      const foundCompany = affiliateOrganizationList.find(x => x.idCompany === userProfile.idCompany);
      if (foundCompany) {
        setSelectedCompany(foundCompany)
      } else {
        setSelectedCompany(affiliateOrganizationList[0])
      };
    }
  }, [userProfile, affiliateOrganizationList]);

  useEffect(() => {
    if (selectedCompany && (userProfile && userProfile.roles.includes("ROLE_ADMIN"))) {
      dispatch(getSummaryOvertimeShift({
        idCompany: selectedCompany.idCompany,
        start: dayjs(filter.start).format("YYYY-MM-DD"),
        end: dayjs(filter.end).format("YYYY-MM-DD"),
        role: "Admin"
      }));
    }
  }, [selectedCompany, userProfile]);

  useEffect(() => {
    if (userProfile && userProfile.roles.includes("ROLE_MANAGER")) {
      dispatch(getSummaryOvertimeShift({
        start: dayjs(filter.start).format("YYYY-MM-DD"),
        end: dayjs(filter.end).format("YYYY-MM-DD"),
        role: "Manager"
      }));
    }
  }, [userProfile]);

  const handleClickSearch = () => {
    if (filter.mode === "all") {
      dispatch(getSummaryOvertimeShift({
        idCompany: selectedCompany && selectedCompany.idCompany || null,
        start: dayjs(filter.start).format("YYYY-MM-DD"),
        end: dayjs(filter.end).format("YYYY-MM-DD"),
        role: (userProfile && userProfile.roles.includes("ROLE_ADMIN")) ? "Admin" : "Manager"
      }));
    } else {
      dispatch(getSummaryOvertimeShiftIndividual(
        filter.idEmployees,
        {
          idCompany: selectedCompany && selectedCompany.idCompany || null,
          start: dayjs(filter.start).format("YYYY-MM-DD"),
          end: dayjs(filter.end).format("YYYY-MM-DD"),
          role: (userProfile && userProfile.roles.includes("ROLE_ADMIN")) ? "Admin" : "Manager"
        }));
    };
  };

  const handleClickIndividual = (selected) => {
    // console.log(selected.row.data.idEmployees)
    setFilter(prev => ({
      ...prev,
      mode: "individual",
      idEmployees: selected.row.data.idEmployees,
    }))
    dispatch(getSummaryOvertimeShiftIndividual(
      selected.row.data.idEmployees,
      {
        idCompany: selectedCompany && selectedCompany.idCompany || null,
        start: dayjs(filter.start).format("YYYY-MM-DD"),
        end: dayjs(filter.end).format("YYYY-MM-DD"),
        role: (userProfile && userProfile.roles.includes("ROLE_ADMIN")) ? "Admin" : "Manager"
      }))
  }

  return (
    <StyledRoot className="page">
      <Container
        maxWidth="lg"
        style={{ paddingTop: "24px", paddingBottom: "24px" }}
      >
        <Box marginBottom="24px">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={"/employees-report"}
            >
              {`${t("AllReports")}`}
            </Link>
            <Typography color="text.primary">{`${t("SummaryOTandShift")}`}</Typography>
          </Breadcrumbs>
        </Box>

        <Typography variant="h4">{t("SummaryOTandShift")}</Typography>

        {userProfile && userProfile.roles.includes("ROLE_ADMIN") && (
          <Box marginTop="24px">
            <Typography fontWeight="600" fontSize="14px" paddingBottom="8px" color="text.third">{t("Company")}</Typography>
            <SelectAffiliateCompany
              options={affiliateOrganizationList}
              value={selectedCompany}
              onChange={(_, value) => {
                setSelectedCompany(value)
              }}
              disabled={filter.mode === "individual"}
            />
          </Box>
        )}

        <Box className="search-date-container">
          <Box className="search-date-box">
            <Typography fontWeight="600" fontSize="14px" paddingBottom="8px" color="text.third">{t("StartDate")}</Typography>
            <DatePickerCustom
              inputFormat="D MMM YYYY"
              value={filter.start}
              views={["year", "month", "day"]}
              onChange={(newValue) => {
                // setFilter(prev => ({
                //   ...prev,
                //   start: newValue
                // }));
                if(dayjs(filter.end).diff(dayjs(newValue), 'day') > 62){
                  setFilter(prev => ({
                    ...prev,
                    start: newValue,
                    end: dayjs(newValue).add(60, 'day'),
                  }));
                } else {
                  setFilter(prev => ({
                    ...prev,
                    start: newValue
                  }));
                }
              }}
              inputProps={{ readOnly: true }}
            />
          </Box>
          <Box className="search-date-box">
            <Typography fontWeight="600" fontSize="14px" paddingBottom="8px" color="text.third">{t("EndDate")}</Typography>
            <DatePickerCustom
              minDate={filter.start}
              maxDate={dayjs(filter.start).add(62, 'day')}
              inputFormat="D MMM YYYY"
              value={filter.end}
              views={["year", "month", "day"]}
              onChange={(newValue) => {
                setFilter(prev => ({
                  ...prev,
                  end: newValue
                }));
              }}
              inputProps={{ readOnly: true }}
            />
          </Box>
          <Box className="search-button">
            <ButtonBlue
              startIcon={<SearchRoundedIcon />}
              variant="contained"
              fullWidth
              onClick={handleClickSearch}
            >
              {t("Search")}
            </ButtonBlue>
          </Box>
        </Box>

        {filter.mode === "individual" && (
          <Box marginTop="24px">
            <ButtonBlue
              variant="text"
              startIcon={<ArrowBackIosNewOutlinedIcon />}
              onClick={() => {
                setFilter(prev => ({
                  ...prev,
                  mode: "all"
                }))
              }}
            >
              {t('Back')}
            </ButtonBlue>
          </Box>
        )}

        <Box marginTop="24px">
          <CardStyle>
            <Box padding="24px">
              <StyledWrapDataGrid>
                {filter.mode === "all" && (
                  <TableAll handleClickIndividual={handleClickIndividual} />
                )}
                {filter.mode === "individual" && (
                  <TableIndividual />
                )}
              </StyledWrapDataGrid>
            </Box>
          </CardStyle>
        </Box>
      </Container>
    </StyledRoot>
  )
}

export default SummaryOTShift;