import React from "react";
import { makeStyles } from "@mui/styles";
import CardStyle from "../../shared/general/Card";
import { Box, Button, Chip, Grid, IconButton, Typography } from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    marginBottom: "50px",
  },
  jobIcon: {
    fontSize: "2.5rem",
    color: "#007afc",
    backgroundColor: "#e1f5fe",
    padding: "5px",
    borderRadius: "5px",
  },
  cancelBtn: {
    "&:hover": {
      color: "#e53935",
    },
  },
  chipWaiting: {
    color: "#ffa000",
    backgroundColor: "#fff8e1",
    marginTop: "5px",
  },
}));

function JobCard(props) {
  const { jobApply } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {jobApply.map((item) => (
        <CardStyle key={item.applyId} style={{ marginBottom: "24px" }}>
          <Box sx={{ padding: "24px" }}>
            <Grid container spacing={2}>
              <Grid item xs={2} md={1} lg={1}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AccountBoxIcon className={classes.jobIcon} />
                </Box>
              </Grid>
              <Grid item xs={10} md={3} lg={3}>
                <Grid container>
                  <Grid item xs={12} md={8} lg={8}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      {item.jobName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography
                      variant="body1"
                      sx={{ color: "#9e9e9e" }}
                    >{`${item.department} , ${item.section}`}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Grid container>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="body2" sx={{ color: "#6e6e6e" }}>
                      Department
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="body1">{item.department}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Grid container>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="body2" sx={{ color: "#6e6e6e" }}>
                      Company
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="body1">{item.company}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Grid container>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="body2" sx={{ color: "#6e6e6e" }}>
                      Location
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="body1">{item.location}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={1} lg={1}>
                <Grid container>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="body2" sx={{ color: "#6e6e6e" }}>
                      Status
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Chip
                      label={item.status}
                      className={
                        item.status === "Waiting" ? classes.chipWaiting : null
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={1}
                lg={1}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {item.status === "Waiting" ? (
                  <IconButton className={classes.cancelBtn}>
                    <DeleteForeverIcon />
                  </IconButton>
                ) : null}
              </Grid>
            </Grid>
          </Box>
        </CardStyle>
      ))}
    </div>
  );
}

export default JobCard;
