import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Typography,
  Button,
  Avatar,
  Dialog,
  DialogContent,
  Paper,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";
import Certificate from "../../shared/assets/Certificate_head_card.jpg";
import dayjs from "dayjs";
import { getMyCertificateData } from "../../../../actions/webhook";

const StyleCardRoot = styled(Card)({
  minWidth: "300px",
  margin: "16px",
  boxShadow: "rgb(90 114 123 / 20%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  position: "relative",
  "&:hover": {
    cursor: "pointer",
    boxShadow: "0px 0px 20px 5px rgba(0, 0, 0, .2)",
  },
});

const HeaderContainer = styled(Box)({
  // padding: "2em",
  textAlign: "left",
  overflowWrap: "anywhere",
  fontWeight: "600",
  "& .title": {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  "& .titleDesc": {
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    fontWeight: "300",
  },
});

const StyleButtoncategory = styled(Button)({
  color: "#DB4178",
  minWidth: 0,
  padding: 0,
  marginRight: 5,
});

const StyledContentLabel = styled(Typography)({
  marginLeft: 4,
  fontWeight: "400 !important",
  textAlign: "start",
  color: "#00000080",
  "&.error": {
    color: "#f15e5e",
  },
});

const CertContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
//   marginBottom: "20px",
});

const useStyles = makeStyles({
  media: {
    height: 120,
    "z-index": 1,
  },
  top: {
    marginTop: "30px",
  },
});

const CardCertificate = ({ data }) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [modelCertificate, setModelCertificate] = useState(null);

  const handleOpen = async () => {
    const res = await getMyCertificateData(data.idregister, data.courseType);
    if (res) {
      const temp = {
        ...res,
        fullName: `${res.title_TH} ${res.firstname_TH} ${res.lastname_TH}`,
      };
      setModelCertificate(temp);
      setOpenDialog(true);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Fragment>
      <StyleCardRoot onClick={handleOpen}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={Certificate}
            title={data.title || ""}
          />
          <CardContent
            style={{
              height: "170px",
              padding: "5px 16px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <Box style={{ width: "100%" }}>
              <div style={{ textAlign: "left", marginTop: 5, marginBottom: 5 }}>
                <StyleButtoncategory size="small">{`${data.courseType}${
                  data.isExternal === 1 ? " (EXTERNAL)" : ""
                }`}</StyleButtoncategory>
              </div>

              <HeaderContainer>
                <Typography
                  variant="subtitle1"
                  className="title"
                  style={{ fontWeight: 600 }}
                >
                  {data.title}
                </Typography>
              </HeaderContainer>
            </Box>

            <Box style={{ width: "100%" }}>
              {data && data.tcfirstname && data.tclastname && (
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    style={{
                      backgroundColor: "#fff",
                      width: "40px",
                      height: "40px",
                    }}
                    src="https://xsgames.co/randomusers/avatar.php?g=male"
                  />
                  <div style={{ textAlign: "left", marginLeft: 10 }}>
                    <StyledContentLabel style={{ color: "black" }}>
                      {`${data.tcfirstname} ${data.tclastname}`}
                    </StyledContentLabel>
                  </div>
                </Box>
              )}
            </Box>
            <Box style={{ width: "100%", minHeight: "50px" }}>
              <Divider style={{ marginTop: 8, marginBottom: 8 }} />
              <Typography
                variant="subtitle2"
                style={{
                  textAlign: "left",
                  color: "#00000080",
                  fontWeight: 600,
                }}
              >
                สถาบัน:{" "}
                {data.isExternal === 1
                  ? String(data.institution)
                  : "ภายในบริษัท"}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </StyleCardRoot>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        // sx={{ height: "50%", width: "50%" }}
      >
        <DialogContent>
          {modelCertificate && (
            <CertContainer>
              <Box>
                <Box
                //   style={{ minWidth: "780px", maxWidth: "780px" }}
                  id={`divToimg-${modelCertificate.courseType}${modelCertificate.idcourse}`}
                  // className="certificate"
                >
                  {modelCertificate.isExternal === 1 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={`data:image/png;base64,${modelCertificate.certimg}`}
                        style={{ width: "100%" }}
                      />
                    </div>
                  ) : modelCertificate.certificateName == "Modern" ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={`data:image/png;base64,${modelCertificate.certimg}`}
                        style={{ width: "100%" }}
                      />
                      <Typography
                        variant="h4"
                        style={{
                          whiteSpace: "nowrap",
                          marginTop: "190px",
                          position: "absolute",
                          color: "#c9b597",
                        }}
                      >
                        {modelCertificate.fullName}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{
                          whiteSpace: "nowrap",
                          marginTop: "240px",
                          position: "absolute",
                          color: "#35568b",
                        }}
                      >
                        has succesfully completed
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{
                          whiteSpace: "nowrap",
                          marginTop: "265px",
                          position: "absolute",
                          color: "#35568b",
                        }}
                      >
                        {modelCertificate.courseName}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{
                          whiteSpace: "nowrap",
                          marginLeft: "320px",
                          marginTop: "315px",
                          position: "absolute",
                          color: "#35568b",
                        }}
                      >
                        {dayjs(modelCertificate.endDate).format("DD/MM/YYYY")}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{
                          whiteSpace: "nowrap",
                          marginLeft: "325px",
                          marginTop: "340px",
                          position: "absolute",
                          color: "#d5d0ca",
                        }}
                      >
                        Date
                      </Typography>
                      <img
                        src={`data:image/png;base64,${modelCertificate.signimg}`}
                        width="90px"
                        height="50px"
                        style={{
                          marginLeft: "-320px",
                          marginTop: "288px",
                          position: "absolute",
                        }}
                      />
                      <Typography
                        variant="subtitle2"
                        style={{
                          whiteSpace: "nowrap",
                          marginLeft: "-325px",
                          marginTop: "340px",
                          position: "absolute",
                          color: "#d5d0ca",
                        }}
                      >
                        issuedName
                      </Typography>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={`data:image/png;base64,${modelCertificate.certimg}`}
                        style={{ width: "100%" }}
                      />
                      <Typography
                        variant="h6"
                        style={{
                          // minWidth: "780px",
                          // textAlign: "center",
                          whiteSpace: "nowrap",
                          marginTop: "165px",
                          position: "absolute",
                          color: "#65380f",
                        }}
                      >
                        {modelCertificate.courseName}
                      </Typography>
                      <Typography
                        variant="h5"
                        style={{
                          whiteSpace: "nowrap",
                          fontStyle: "italic",
                          marginTop: "230px",
                          fontWeight: "450",
                          position: "absolute",
                          color: "#75481e",
                        }}
                      >
                        {modelCertificate.fullName}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{
                          whiteSpace: "nowrap",
                          marginTop: "280px",
                          position: "absolute",
                          color: "#643614",
                        }}
                      >
                        has succesfully completed
                      </Typography>
                      <img
                        src={`data:image/png;base64,${modelCertificate.signimg}`}
                        // src={modelCertificate.signatureurl}
                        // src={Modern}
                        width="90px"
                        height="50px"
                        style={{
                          marginTop: "312px",
                          position: "absolute",
                        }}
                      />
                      <Typography
                        variant="subtitle2"
                        style={{
                          whiteSpace: "nowrap",
                          marginTop: "365px",
                          position: "absolute",
                          color: "#65380f",
                        }}
                      >
                        issuedName
                      </Typography>
                    </div>
                  )}
                </Box>
              </Box>
            </CertContainer>
          )}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default CardCertificate;
