import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Controller } from "react-hook-form";
import { Box, MenuItem, Popper, Typography } from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

import { getFamilyByIdEmployee } from "../../../../../actions/family";
import { useTranslation } from "react-i18next";

// import utils from "../../../../utils";

const StyledBoxSearch = styled(Box)({
  //   marginTop: 22,
  //   "& .label": {
  //     fontWeight: 600,
  //     fontSize: 14,
  //     marginBottom: 8,
  //   },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const filterOptions = createFilterOptions({
  stringify: (option) =>
    option.firstname_TH + option.lastname_TH + option.positionName,
});

const filterOptionsName = createFilterOptions({
  stringify: (option) => option.firstname_TH + option.lastname_TH,
});

const SelectFamily = (props) => {
  const { t, i18n } = useTranslation();
  let {
    handleChange,
    position,
    field,
    value,
    idFamily,
    control,
    idEmployee,
    employee,
  } = props;
  const dispatch = useDispatch();
  const { result: family } = useSelector((state) => state.family);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [memberList, setMemberList] = useState([]);

  useEffect(() => {
    if (idEmployee) dispatch(getFamilyByIdEmployee(idEmployee));
  }, [idEmployee]);

  useEffect(() => {
    if (idEmployee && family) {
      let familyMembers = family.map((item) => {
        return { ...item, isUseForFamilyMember: 1 };
      });
      setMemberList([
        {
          isUseForFamilyMember: 0,
          idFamily: 0,
          relationship: "Me",
          firstname_TH: employee.firstname_TH,
          lastname_TH: employee.lastname_TH,
          firstname_EN: employee.firstname_EN,
          lastname_EN: employee.lastname_EN,
          idEmployees: idEmployee,
        },
        ...familyMembers,
      ]);
    }
  }, [idEmployee, family]);

  return (
    <StyledBoxSearch>
      <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
        {t("RightsUser")}
      </StyledHeadLabel>

      <Controller
        control={control}
        name={`idFamily`}
        render={({ field, formState }) => (
          <TextFieldTheme
            {...field}
            select
            error={formState.errors && formState.idFamily ? true : false}
            helperText={
              formState.errors && formState.errors.idFamily
                ? formState.errors.idFamily.message
                : null
            }
          >
            <MenuItem value="none" disabled={true}></MenuItem>
            {memberList &&
              memberList.map((item) => (
                <MenuItem
                  id={item.idFamily}
                  key={item.idFamily}
                  value={item.idFamily}
                  onClick={(event) => {
                    if (event.target.id !== "0") {
                      handleChange("isUseForFamilyMember", 1);
                      handleChange("idFamily", item.idFamily);
                      handleChange("relation", item.relationship);
                    } else {
                      handleChange("isUseForFamilyMember", 0);
                      handleChange("idFamily", null);
                      handleChange("relation", "พนักงาน");
                    }
                  }}
                >
                  {item.firstname_TH} {item.lastname_TH} ({item.relationship})
                </MenuItem>
              ))}
          </TextFieldTheme>
        )}
      />
    </StyledBoxSearch>
  );
};

export default SelectFamily;
