import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "dayjs";
import {
    Typography,
    Button,
    Stack,
    Container,
    IconButton,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    Paper,
    TableBody,
    TableCell,
    Chip,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Menu,
    Avatar,
    InputAdornment,
    FilledInput,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,

} from "@mui/material";
import CardStyle from "../../shared/general/Card";
import SearchIcon from '@mui/icons-material/Search';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import {
    getWorkforceDashboardByIdMonth,
    getWorkforceDashboardAllById
} from "../../../../actions/workforceEmployees";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

const StyledRoot = styled("div")({
    backgroundColor: "#f5f5f5",
    "& .MuiAutocomplete-root": {
        "& .MuiOutlinedInput-root": {
            padding: "13.5px 14px",
            paddingRight: "32px",
            "& input": {
                padding: 0,
            },
        },
    },
});

const WrapHeader = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ["@media only screen and (max-width:600px)"]: {
        flexDirection: "column",
    },
    marginTop: 8,
    marginBottom: 16,
});

const StyledFilledInput = styled(FilledInput)({
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
        paddingTop: 18,
        paddingBottom: 18,
    },
    "&.Mui-focused": {
        backgroundColor: "transparent",
    },
    "& .MuiInputAdornment-root": {
        width: 32,
        marginTop: "0!important",
        fontSize: 24,
        color: "#919EAB",
        "& i": {
            marginRight: 8,
        },
    },
    "& .MuiAutocomplete-endAdornment": {
        "& .MuiButtonBase-root": {
            fontSize: 14,
            width: 22,
            height: 22,
        },
    },
    "&:hover": {
        backgroundColor: "#919eab29",
        "&:before": {
            border: "none !important",
        },
    },
    "&::after": {
        border: "none",
    },
    "&::before": {
        border: "none",
    },
});

const useStyles = makeStyles(() => ({
    sendIcon: {
        marginBottom: 5,
        marginRight: 5,
        color: "black",
    },
    bigAvatar: {
        width: 200,
        height: 200,
    },
    text: {
        fontSize: 17,
    },
    textLine: {
        display: "flex",
        alignItems: "center",
        padding: 10,
    },
    textMainData: {
        fontSize: 18,
        fontWeight: "bold",
    },
    textName: {
        fontSize: 25,
        fontWeight: "bold",
        marginTop: 10,
        color: "#007afc",
    },
    space: {
        marginTop: 10,
        marginBottom: 10,
    },
    nameAttribute: {
        fontSize: 23,
        fontWeight: "bold",
        marginLeft: 10,
    },
    attribute: {
        fontSize: 18,
        marginTop: 50,
        marginBottom: 10,
    },
    tableText: {
        fontSize: 18,
    },
    learningName: {
        fontSize: 20,
        fontWeight: "bold",
    },
    avatarCer: {
        minWidth: 700,
        minHeight: 500,
        marginBottom: 20,
        marginTop: 20,
    },
    // textPerform: {
    //   textAlign: "center",
    //   padding: 60,
    //   margin: 20,
    //   fontSize: 75,
    //   fontWeight: "bold",
    //   color: "#007afc",
    // },
    textPerform: {
        textAlign: "center",
        padding: 40,
        margin: 20,
        fontSize: 25,
        fontWeight: "bold",
        color: "#007afc",
    },
    iconBeforeText: {
        marginRight: 7,
        fontSize: 22,
    },
    topicInJob: {
        fontSize: 18,
        fontWeight: "bold",
    },
    accordion: {
        marginBottom: 15,
        borderRadius: 10,
        "&.MuiAccordion-root:before": {
            backgroundColor: "transparent",
        },
    },
    testflex: {
        display: "flex",
        justifyContent: "space-between"
    }
}));

const detailProjectHour = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const monthCreated = parseInt(queryParams.get('MonthCreated'), 10);
    const yearCreated = parseInt(queryParams.get('YearCreated'), 10);
    const idEmployees = queryParams.get('idEmployees');
    const { result: dashboardWorkforceMonth } = useSelector((state) => state.dashboardWorkforceMonth);
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        if (idEmployees && monthCreated && yearCreated) {

            dispatch(getWorkforceDashboardByIdMonth({ idEmp: idEmployees, Month: monthCreated, Year: yearCreated }));
        }
    }, [dispatch, idEmployees, monthCreated, yearCreated]);

    const prepareData = () => {
        if (!dashboardWorkforceMonth || !Array.isArray(dashboardWorkforceMonth)) {
            console.error("Invalid or empty data");
            return [];
        }

        const grouped = dashboardWorkforceMonth.reduce((acc, item) => {
            const key = `${item.name}-${item.inWeek}`;
            if (!acc[key]) {
                acc[key] = { name: item.name, week: item.inWeek, totalHours: 0, detail: item.detail };
            }
            acc[key].totalHours += parseInt(item.totalWorkingHours, 10);
            return acc;
        }, {});

        return Object.values(grouped).sort((a, b) => a.week - b.week);
    };

    const [preparedData, setPreparedData] = useState([]);

    useEffect(() => {
        setPreparedData(prepareData());
    }, [dashboardWorkforceMonth]);

    const [dateWeek, setDateWeek] = useState([]);
    useEffect(() => {
        let temp = [];

        if (monthCreated < 1 || monthCreated > 12) {
            console.error('Invalid monthCreated value:', monthCreated);
            return;
        }
        if (!yearCreated || yearCreated < 2023 || yearCreated > 2025) {
            console.error('Invalid yearCreated value:', yearCreated);
            return;
        }

        let fid = dayjs(`${yearCreated}-${String(monthCreated).padStart(2, '0')}-01`, 'YYYY-MM-DD');
        // console.log('Initial fid:', fid.format('YYYY-MM-DD'));

        if (fid.day() !== 1) {
            fid = fid.add((8 - fid.day()), 'day');
        }

        temp.push({
            start: fid.clone(),
            end: fid.clone().add(6, "day"),
            count: 0,
            weekCount: 1
        });

        let endDate = fid.clone().add(6, "day");
        let endTemp = dayjs(`${yearCreated}-${String(monthCreated).padStart(2, '0')}-01`).endOf('month');
        // console.log('endTemp:', endTemp.format('YYYY-MM-DD'));
        let weekCount = 1;

        while (endDate.isBefore(endTemp, "day")) {
            fid = endDate.clone().add(1, "day");
            endDate = fid.clone().add(6, "day");

            temp.push({
                start: fid.clone(),
                end: endDate.clone(),
                count: 0,
                weekCount: ++weekCount
            });
        }

        setDateWeek(temp);
        // console.log('temp:', temp);
    }, [monthCreated, yearCreated]);

    const test = () => {
    };

    const thaiMonths = [
       t("Months.January"),t("Months.February"),t("Months.March"),t("Months.April"),t("Months.May"),
       t("Months.June"), t("Months.July"), t("Months.August"), t("Months.September"), t("Months.October"), t("Months.November"), t("Months.December")];

    return (
        <StyledRoot className={"page"}>
            <Container maxWidth="lg">
                <WrapHeader onClick={test}>
                    <Typography variant="h4">{t("projectDetailsForMonth")} {thaiMonths[monthCreated-1]} {t("Year")} {yearCreated}</Typography>
                </WrapHeader>

                {dateWeek && dateWeek.map((week, index) => {
                    const weekData = preparedData.filter(item => item.week === week.weekCount);
                    return (
                        <div key={week.weekCount}>
                            <WrapHeader>
                                <Typography variant="h5">- {t("week")} {week.weekCount}</Typography>
                            </WrapHeader>
                            {weekData.length > 0 ? (
                                weekData.map((item, index) => (
                                    <Accordion key={index} className={classes.accordion}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`panel${week.weekCount}-${index}-content`}
                                            id={`panel${week.weekCount}-${index}-header`}
                                            sx={{ '& .MuiAccordionSummary-content': { justifyContent: 'space-between' } }}
                                        >
                                            <Typography className={classes.nameAttribute}>{item.name}</Typography>
                                            <Stack direction={'row'}>
                                                <Typography className={classes.nameAttribute}>{item.totalHours}</Typography>
                                                <Typography style={{ fontSize: 23,marginLeft:10 }}>{t("Unit.Hours")}</Typography>
                                            </Stack>

                                        </AccordionSummary>
                                        <AccordionDetails style={{ paddingBottom: 50 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Divider style={{ backgroundColor: "#F4E3EC", height: "2px", marginBottom: "20px" }} />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography fontWeight={600}>{item.detail}</Typography>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                ))
                            ) : (
                                <Accordion className={classes.accordion}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        sx={{ '& .MuiAccordionSummary-content': { justifyContent: 'space-between' } }}
                                    >
                                        {/* <Typography className={classes.nameAttribute}>No data for Week {week.weekCount}</Typography> */}
                                        <Stack direction={'row'}>
                                                <Typography style={{ fontSize: 23,marginLeft:10 }} >{t("noTimeRecordedIn")}</Typography>
                                                <Typography className={classes.nameAttribute}>{t("Week")} {week.weekCount}</Typography>
                                            </Stack>
                                    </AccordionSummary>
                                </Accordion>
                            )}
                        </div>
                    );
                })}
            </Container>
        </StyledRoot>
    );
};

export default detailProjectHour;