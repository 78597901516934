import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Autocomplete,
  Avatar,
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import DrawerCustom from "../../../shared/general/Drawer";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  DesktopDatePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import {
  getNewCandidatesByIdJobRequest,
  getNewCandidatesByIdBiddings,
  updateInterviewDetail,
} from "../../../../../actions/candidate";
import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  padding: 16,
  maxWidth: 450,
  "& .MuiInputLabel-root .MuiInputLabel-formControl": {
    color: "#ffffff",
  },
  "& .part-footer": {
    paddingTop: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .cancel-button": {
      color: "#9e9e9e",
      borderColor: "#9e9e9e",
    },
    "& i": {
      marginRight: 8,
    },
  },
  "& .part-auto-appointment": {
    marginBottom: 24,
    "& .MuiButton-root": {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  "& .part-form": {
    "& .MuiInputBase-root": {
      height: 58,
    },
  },
});

const StyledName = styled("div")({
  padding: 8,
  border: "1px solid rgba(0, 0, 0, 0.23)",
  borderRadius: 8,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#ffffff",
  "& .wrap-name": {
    display: "flex",
    alignItems: "center",
    width: 0,
    flexGrow: 1,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    "& .MuiTypography-root": {
      flexGrow: 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  "& i": {
    paddingLeft: 8,
    color: "#9e9e9e",
  },
});

const DrawerInterviewAppointment = (props) => {
  const {
    open,
    onClose,
    listCandidate,
    id,
    setIsSuccess,
    handleCountDownTimeSet,
    type,
    manager,
  } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const validationSchema = yup.object().shape({
    interviewAppointmentDate: yup.date().required(),
    interviewStartTimeAuto: yup
      .date()
      .required(`${t("PleaseEnterInterviewStartTime")}`),
    interviewEndTimeAuto: yup
      .date()
      .min(
        yup.ref("interviewStartTimeAuto"),
        `${t("EndTimeGreaterThanStartTime")}`
      )
      .required(`${t("PleaseEnterInterviewEndTime")}`),
    interviewDurationTime: yup
      .string()
      .required(`${t("PleaseEnterIndividualInterviewTime")}`),
    isOnline: yup.string().required(`${t("PleaseEnterFormat")}`),
    interviewPlace: yup
      .string()
      .max(350, `${t("InterviewLocationLessThan350")}`),
    contactName: yup.string().max(200, `${t("ContactNameLessThan200")}`),
    contactPhone: yup.string().max(10, `${t("PleaseEnterPhoneNumber")}`),
    listCandidate: yup.array().of(
      yup.object().shape({
        idCandidate: yup.number(),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    setValue,
    clearErrors,
    watch,
  } = useForm({
    defaultValues: {
      interviewAppointmentDate: dayjs(),
      interviewStartTimeAuto: dayjs(),
      interviewEndTimeAuto: dayjs(),
      interviewDurationTime: "",
      isOnline: "0",
      interviewPlace: "",
      contactName: "",
      contactPhone: "",
      listCandidate: listCandidate[id],
    },
    resolver: yupResolver(validationSchema),
  });

  const [interviews, setInterviews] = useState([]);

  useEffect(() => {
    let ListCandidateByKey = {};
    Object.keys(listCandidate).map((key) => {
      ListCandidateByKey[key] = [
        ...listCandidate[key].map((candidate) => {
          return {
            idCandidate: candidate.idCandidate,
            idManpower: candidate.idManpower,
            name: candidate.name,
            lastName: candidate.lastName,
          };
        }),
      ];
    });
    setInterviews(ListCandidateByKey);
    return () => {
      setInterviews([]);
    };
  }, [listCandidate]);

  const onSubmit = async (values) => {
    const result = await dispatch(updateInterviewDetail(values));
    if (result) {
      if (type === "job") {
        dispatch(getNewCandidatesByIdJobRequest(id));
      } else {
        dispatch(getNewCandidatesByIdBiddings(id));
      }
      onClose();
      setIsSuccess(true);
      handleCountDownTimeSet();
    }
  };

  return (
    <DrawerCustom
      title={`${t("InterviewAppointment")}`}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="interviewAppointmentDate"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnterInterviewAppointmentDate")}`,
                  },
                  validate: {
                    dateValidation: (value) =>
                      !isNaN(value) || `${t("PleaseEnterCorrectDate")}`,
                    disblePast: (value) =>
                      new Date(value) >=
                        new Date(new Date().setHours(0, 0, 0, 0)) ||
                      `${t("YouCannotSelectPastDate")}`,
                  },
                }}
                render={({ field }) => (
                  <DesktopDatePicker
                    {...field}
                    inputFormat="DD/MM/YYYY"
                    disablePast
                    renderInput={(params) => (
                      <Fragment>
                        <Typography gutterBottom sx={{ color: "#000000" }}>
                          {`${t("InterviewAppointmentDate")}`}
                        </Typography>
                        <TextFieldTheme
                          {...params}
                          sx={{ color: "#ffffff" }}
                          helperText={
                            errors &&
                            errors.interviewAppointmentDate &&
                            errors.interviewAppointmentDate.message
                          }
                          error={
                            errors && errors.interviewAppointmentDate
                              ? true
                              : false
                          }
                        />
                      </Fragment>
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="interviewStartTimeAuto"
                render={({ field, fieldState }) => (
                  <TimePicker
                    {...field}
                    ampm={false}
                    label={`${t("StartTime")}`}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        error={Boolean(fieldState.error)}
                        helperText={
                          fieldState.error && fieldState.error.message
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="interviewEndTimeAuto"
                render={({ field, fieldState }) => (
                  <TimePicker
                    {...field}
                    ampm={false}
                    label={`${t("EndTime")}`}
                    minTime={dayjs(watch("interviewStartTimeAuto"))}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        error={Boolean(fieldState.error)}
                        helperText={
                          fieldState.error && fieldState.error.message
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="interviewDurationTime"
                render={({ field, formState }) => (
                  <Fragment>
                    <Typography gutterBottom>{`${t(
                      "IndividualInterviewTime"
                    )}`}</Typography>
                    <TextFieldTheme
                      {...field}
                      placeholder={`${t(
                        "PleaseEnterIndividualInterviewTime"
                      )}`}
                      helperText={
                        formState.errors &&
                        formState.errors.interviewDurationTime &&
                        formState.errors.interviewDurationTime.message
                      }
                      error={
                        formState.errors &&
                        formState.errors.interviewDurationTime
                          ? true
                          : false
                      }
                      select
                    >
                      <MenuItem value="15">15 {`${t("Minute")}`}</MenuItem>
                      <MenuItem value="30">30 {`${t("Minute")}`}</MenuItem>
                      <MenuItem value="45">45 {`${t("Minute")}`}</MenuItem>
                      <MenuItem value="60">60 {`${t("Minute")}`}</MenuItem>
                      <MenuItem value="90">90 {`${t("Minute")}`}</MenuItem>
                      <MenuItem value="120">120 {`${t("Minute")}`}</MenuItem>
                    </TextFieldTheme>
                  </Fragment>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>{`${t("InterviewType")}`}</FormLabel>
                <Controller
                  control={control}
                  name={"isOnline"}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      row
                      onChange={(e) => field.onChange(e)}
                    >
                      <FormControlLabel
                        value={"0"}
                        control={<Radio />}
                        label="Offline"
                      />
                      <FormControlLabel
                        value={"1"}
                        control={<Radio />}
                        label="Online"
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"interviewPlace"}
                render={({ field, fieldState }) => (
                  <>
                    <Typography gutterBottom sx={{ color: "#000000" }}>
                      {watch("isOnline") === "1"
                        ? `${t("InterviewLink")}`
                        : `${t("InterviewLocation")}`}
                    </Typography>
                    <TextFieldTheme
                      {...field}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error && fieldState.error.message}
                      placeholder={
                        watch("isOnline") === "1"
                          ? "เช่น ลิงค์สำหรับสัมภาษณ์"
                          : "เช่น ตึก อาคาร"
                      }
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"contactName"}
                render={({ field, fieldState }) => (
                  <>
                    <Typography gutterBottom sx={{ color: "#000000" }}>
                      {`${t("ContactName")}`}
                    </Typography>
                    <TextFieldTheme
                      {...field}
                      placeholder="เช่น กอไก่ เกิดแล้ว"
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error && fieldState.error.message}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"contactPhone"}
                render={({ field, fieldState }) => (
                  <>
                    <Typography gutterBottom sx={{ color: "#000000" }}>
                      {`${t("EmergencyPhone")}`}
                    </Typography>
                    <TextFieldTheme
                      placeholder="เช่น 081756XXXX"
                      {...field}
                      inputProps={{ maxLength: 10 }}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error && fieldState.error.message}
                    />
                  </>
                )}
              />
            </Grid>
          </Grid>
          <div className="part-footer">
            <ButtonBlue
              className="cancel-button"
              variant="outlined"
              onClick={onClose}
            >
              <i className="fa-regular fa-x"></i>
              {`${t("Cancel")}`}
            </ButtonBlue>
            <ButtonBlue type="submit" variant="contained">
              <i className="fa-regular fa-check"></i>
              {`${t("Confirm")}`}
            </ButtonBlue>
          </div>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DrawerInterviewAppointment;
