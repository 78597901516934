import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  Typography,
  IconButton,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { NotListedLocation } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";

import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";

const useStyles = makeStyles(() => ({
  dialog: {
    padding: 16,
    position: "absolute",
    top: 80,
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    textAlign: "center",
  },
  DialogActions: {
    marginTop: 24,
  },
  titleIcon: {
    backgroundColor: "#ba68c8",
    color: "#9c27b0",
    "&:hover": {
      backgroundColor: "#ba68c8",
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
  textField: {
    marginTop: 24,
    marginBottom: 40,
  },
  acheived: {
    backgroundColor: "#e0f2f1",
    color: "#009688",
    border: "1px solid #009688",
    "&:hover": {
      backgroundColor: "#e0f2f1",
      border: "1px solid #009688",
    },
  },
  notAchieved: {
    backgroundColor: "#f9dde0",
    color: "#c62828",
    border: "1px solid #c62828",
    "&:hover": {
      backgroundColor: "#f9dde0",
      border: "1px solid #c62828",
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 16,
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: "#9e9e9e",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 16,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: 8,
  },
}))(MuiDialogActions);

export default function ConfirmModal(props) {
  const { confirmModal, setConfirmModal, values } = props;
  const classes = useStyles();
  const history = useHistory();
  const [planItem, setPlanItem] = useState([]);
  const [errors, setErrors] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPlanItem({
      ...values,
      [name]: value,
    });
  };

  const handleAchieve = (result) => {
    if (planItem.achieveDescription !== null) {
      const newValues = { ...planItem };
      if (result) {
        newValues.achieveResult = "Achieved";
      } else {
        newValues.achieveResult = "Not Achieved";
      }

      if (planItem.planOrgId) {
        //planItem.planOrgId
        console.log("Data Submit", planItem.planOrgId, newValues);
        //Close Dialog Modal
        setConfirmModal({ ...confirmModal, isOpen: false });
      }

      if (planItem.planTeamId) {
        //planItem.planTeamId
        console.log("Data Submit", planItem.planTeamId, newValues);
        //Close Dialog Modal
        setConfirmModal({ ...confirmModal, isOpen: false });
      }
    } else {
      setErrors(true);
    }
  };

  useEffect(() => {
    setPlanItem(values);
  }, [values]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={confirmModal.isOpen}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle
        onClose={() => setConfirmModal({ ...confirmModal, isOpen: false })}
      ></DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form>
          <Typography variant="h6">{confirmModal.title}</Typography>
          <Typography variant="subtitle2" gutterBottom>
            {confirmModal.subTitle}
          </Typography>
          <TextField
            label="Achieve Description"
            name="achieveDescription"
            value={planItem.achieveDescription}
            onChange={handleInputChange}
            className={classes.textField}
            error={errors}
            helperText={errors ? "This field is required" : null}
            fullWidth
            multiline
          />
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                className={classes.acheived}
                onClick={() => {
                  handleAchieve(true);
                }}
              >
                Achieved
              </Button>
              <Button
                color="primary"
                variant="outlined"
                className={classes.notAchieved}
                style={{ marginLeft: "20px" }}
                onClick={() => {
                  handleAchieve(false);
                }}
              >
                Not Achieved
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
