import { httpClient } from "./httpClient";
import dayjs from "dayjs";

const getTimelineOnce = (filter, mode) => {
  if (mode) {
    return httpClient.get(
      `/timeline-once?date=${filter.searchDate}&mode=${mode}`
    );
  } else {
    return httpClient.get(
      `/timeline-once?date=${filter.searchDate}`
    );
  }
};

const getTimelineById = (id,type,date) => {
  return httpClient.get(`/getTimelineById/${type}/${id}?date=${date}`);
};

const getTimelineMonthById = (id,type,date) => {
  return httpClient.get(`/getTimelineMonthById/${type}/${id}?date=${date}`);
};

const getCheckTimelineById = (id,type) => {
  return httpClient.get(`/getCheckTimelineById/${type}/${id}`);
};

const addTimeline = (formData) => {
  return httpClient.post(`/addTimeline/`, formData);
};

const getLastTimeline = () => {
  return httpClient.get(`/timeline-last`);
};


export default {
  getTimelineOnce,
  getTimelineById,
  addTimeline,
  getCheckTimelineById,
  getTimelineMonthById,
  getLastTimeline
};
