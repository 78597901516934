import React from "react";
import { Box, Grid, MenuItem, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const PaymentInfo = (params) => {
  const { useForm } = params;
  const { t, i18n } = useTranslation();

  return (
    <Box>
      <Typography className="title-text">{`${t("PaymentInfo")}`}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography>{`${t("EmploymentTime")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="employmentTime"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                select
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
              >
                <MenuItem value="Full time">Full time</MenuItem>
                <MenuItem value="Part time">Part time</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography>{`${t("Payment_Type")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="idPaymentType"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                select
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
              >
                <MenuItem value="1">
                  <Box>
                    <Typography>{`${t("Daily")}`}</Typography>
                    <Typography color="text.third" fontSize="14px">
                      {`${t("PaymentCycle")}`}: {`${t("Semi_Monthly")}`}
                    </Typography>
                  </Box>
                </MenuItem>
                <MenuItem value="5">
                  <Box>
                    <Typography>{`${t("Daily")}`}</Typography>
                    <Typography color="text.third" fontSize="14px">
                      {`${t("PaymentCycle")}`}: {`${t("Monthly")}`}
                    </Typography>
                  </Box>
                </MenuItem>
                <MenuItem value="4">
                  <Box>
                    <Typography>{`${t("Monthly")}`}</Typography>
                    <Typography color="text.third" fontSize="14px">
                      {`${t("PaymentCycle")}`}: {`${t("Semi_Monthly")}`}
                    </Typography>
                  </Box>
                </MenuItem>
                <MenuItem value="2">
                  <Box>
                    <Typography>{`${t("Monthly")}`}</Typography>
                    <Typography color="text.third" fontSize="14px">
                      {`${t("PaymentCycle")}`}: {`${t("Monthly")}`}
                    </Typography>
                  </Box>
                </MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography>{`${t("Salary")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="salary"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  inputComponent: NumberFormatTheme,
                  endAdornment: (
                    <Typography color="text.secondary">{`${t(
                      "Baht"
                    )}`}</Typography>
                  ),
                }}
                inputProps={{
                  allowNegative: false,
                  value: field.value,
                  thousandSeparator: ",",
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value);
                  },
                }}
                onChange={() => {}}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>Cost Center</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="costCenterCharge"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  placeholder: `(${t("Optional")})`,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>Cost Element</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="costElementCharge"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  placeholder: `(${t("Optional")})`,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("BankName")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="bookBank"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                select
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
              >
                <MenuItem value="ธนาคารกรุงเทพ">{`${t(
                  "BangkokBank"
                )}`}</MenuItem>
                <MenuItem value="ธนาคารกรุงไทย">{`${t(
                  "KrungThaiBank"
                )}`}</MenuItem>
                <MenuItem value="ธนาคารกรุงศรีอยุธยา">
                  {`${t("BankOfAyudhya")}`}
                </MenuItem>
                <MenuItem value="ธนาคารกสิกรไทย">{`${t(
                  "KasikornBank"
                )}`}</MenuItem>
                <MenuItem value="ธนาคารทหารไทยธนชาต">
                  {`${t("TMBThanachartBank")}`}
                </MenuItem>
                <MenuItem value="ธนาคารไทยพาณิชย์">{`${t(
                  "SiamCommercialBank"
                )}`}</MenuItem>
                <MenuItem value="ธนาคารซีไอเอ็มบี ไทย">
                  {`${t("CIMBThaiBank")}`}
                </MenuItem>
                <MenuItem value="ธนาคารยูโอบี">{`${t(
                  "UnionOverseasBank"
                )}`}</MenuItem>
                <MenuItem value="ธนาคารเกียรตินาคินภัทร">{`${t(
                  "KiatnakinPhatraBank"
                )}`}</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("BranchName")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="bookBankBranchName"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("BookID")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="bookID"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  inputComponent: NumberFormatTheme,
                }}
                inputProps={{
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  thousandSeparator: false,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value);
                  },
                }}
                onChange={() => {}}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>
            {`${t("ReportToBank")}`} {`(${t("BankName")})`}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="reportBankBankName"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                InputProps={{
                  placeholder: `(${t("Optional")})`,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>
            {`${t("ReportToBank")}`} {`(${t("ReportBankID")})`}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="reportBankBankID"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                InputProps={{
                  placeholder: `(${t("Optional")})`,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>
            {`${t("ReportToBank")}`} {`(${t("BookID")})`}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="reportBankBookBankID"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                InputProps={{
                  placeholder: `(${t("Optional")})`,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>
            {`${t("ReportToBank")}`} {`(${t("ReportBankName")})`}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="reportBankName"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                InputProps={{
                  placeholder: `(${t("Optional")})`,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>
            {`${t("ReportToBank")}`} {`(${t("ReportBankRef")})`}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="reportBankRef"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                InputProps={{
                  placeholder: `(${t("Optional")})`,
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentInfo;
