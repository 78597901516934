import { Typography } from "@mui/material";
import React from "react";
import { getUserCompany, getUserDepartment } from "../../../../../../utils/userData";

function AdminFillIn({ valueSelected }) {
  return ( 
    <>
      <Typography variant="h6">
        {getUserCompany({
          companyName: valueSelected.companyName,
          companyName_EN: valueSelected.companyName_EN,
        })}
      </Typography>
      <div style={{ padding: "8px" }}>
        <Typography className="company-name">
          {getUserDepartment({
            departmentName: valueSelected.departmentName,
            departmentName_EN: valueSelected.departmentName_EN,
          })}
        </Typography>
      </div>
    </>
  );
}

export default AdminFillIn;
