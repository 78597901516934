import React from "react";
import ListMenu from "../../../pages/shared/listMenu";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ForestRoundedIcon from "@mui/icons-material/ForestRounded";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import TimelapseRoundedIcon from "@mui/icons-material/TimelapseRounded";
import ReceiptLongRoundedIcon from "@mui/icons-material/ReceiptLongRounded";
const UserMenu = () => {

  // const dataListMenu = [
  //   {
  //     listItemIcon: <HomeRoundedIcon />,
  //     listItemText: "หน้าแรก",
  //     listLink: "/home",
  //     listKey: "home",
  //   },
  //   {
  //     listItemIcon: <DateRangeRoundedIcon />,
  //     listItemText: "ตารางเวลาทำงาน",
  //     listLink: "/timeline",
  //     listKey: "timeline",
  //   },
  //   {
  //     listItemIcon: <ForestRoundedIcon />,
  //     listItemText: "ลางาน",
  //     listLink: "/leave",
  //     listKey: "leave",
  //   },
  //   {
  //     listItemIcon: <ListAltRoundedIcon />,
  //     listItemText: "สถานะรายการ",
  //     listLink: "/requestlist",
  //     listKey: "requestlist",
  //   },
  //   {
  //     listItemIcon: <TimelapseRoundedIcon />,
  //     listItemText: "รายงานค่าล่วงเวลา",
  //     listLink: "/overtime/overtimereport",
  //     listKey: "overtimereport",
  //   },
  //   {
  //     listItemIcon: <ReceiptLongRoundedIcon />,
  //     listItemText: "สลิปเงินเดือน",
  //     listLink: "/pay-slip",
  //     listKey: "payslip",
  //   },
  // ];

  return <ListMenu menuRole="User" />;
};

export default UserMenu;
