import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Checkbox,
  Drawer,
  FilledInput,
  FormControl,
  InputAdornment,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import SearchIcon from "@mui/icons-material/Search";

import { getDepartment } from "./../../../../actions/department";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledFilter = styled(Box)({
  width: 280,
  flexShrink: 0,
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledDrawer = styled(Drawer)({
  width: 280,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    position: "absolute",
    width: 280,
    boxSizing: "border-box",
    border: "none",
  },
});

const icon = <i class="fa-regular fa-square" style={{ fontSize: 24 }}></i>;
const checkedIcon = (
  <i class="fa-regular fa-square-check" style={{ fontSize: 24 }}></i>
);

const FilterTable = (props) => {
  const { handleChange, filterEmployee, filterStatus } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: departmentList } = useSelector((state) => state.department);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const empType = [
    "พนักงานประจำ",
    "สัญญาจ้าง",
    "คู่ธุรกิจ",
    "เด็กฝึกงาน",
    "ที่ปรึกษา",
  ];
  const level = ["Highly Skill", "Skill", "Semi Skill", "Un Skill"];

  useEffect(() => {
    if (userProfile) {
      dispatch(getDepartment(userProfile.idCompany));
    }
  }, [userProfile]);

  return (
    <StyledDrawer variant="persistent" anchor="left" open={filterStatus}>
      <StyledFilter>
        <div>
          <FormControl fullWidth variant="filled">
            <StyledFilledInput
              id="filled-sarch-name"
              placeholder={`${t("SearchFullName")}`}
              name="search"
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              {`${t("Department")}`}
            </Typography>
            <StyledAutocomplete
              options={departmentList}
              onChange={(event, newValue) => {
                handleChange(
                  event,
                  newValue === null ? "" : newValue.idDepartment,
                  "idDepartment"
                );
              }}
              popupIcon={<i class="fa-light fa-chevron-down"></i>}
              getOptionLabel={(option) => option.departmentName}
              renderInput={(params) => (
                <TextFieldTheme
                  {...params}
                  variant="filled"
                  placeholder={`${t("AllDepartment")}`}
                />
              )}
              PopperComponent={StyledPopper}
              noOptionsText={`${t("NoData")}`}
            />
          </StyledBoxSearch>
          {/* <StyledBoxSearch>
            <Typography className="label" color="text.third">
              บริษัทสังกัด
            </Typography>
            <StyledAutocomplete
              options={vendor}
              onChange={(event, newValue) => {
                handleChange(
                  event,
                  newValue === null ? "" : newValue.idVendor,
                  "idVendor"
                );
              }}
              popupIcon={<i class="fa-light fa-chevron-down"></i>}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder="บริษัทสังกัดทั้งหมด"
                />
              )}
              PopperComponent={StyledPopper}
              noOptionsText={"ไม่พบข้อมูล"}
            />
          </StyledBoxSearch>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              กลุ่มงาน
            </Typography>
            <StyledAutocomplete
              options={jobGroup}
              onChange={(event, newValue) => {
                handleChange(
                  event,
                  newValue === null ? "" : newValue.idJobGroup,
                  "idJobGroup"
                );
              }}
              popupIcon={<i class="fa-light fa-chevron-down"></i>}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder="กลุ่มงานทั้งหมด"
                />
              )}
              PopperComponent={StyledPopper}
              noOptionsText={"ไม่พบข้อมูล"}
            />
          </StyledBoxSearch>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              ประเภทพนักงาน
            </Typography>
            <StyledAutocomplete
              multiple
              id="combo-box-emp-type"
              disableCloseOnSelect
              options={empType}
              onChange={(event, newValue) => {
                handleChange(event, newValue, "employeeType");
              }}
              popupIcon={<i class="fa-light fa-chevron-down"></i>}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder={
                    filterEmployee.employeeType.length === 0 ? "ทุกประเภท" : ""
                  }
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  if (index === 0) {
                    return <Typography>{option}</Typography>;
                  } else {
                    return <Typography>, {option}</Typography>;
                  }
                })
              }
              PopperComponent={StyledPopper}
              noOptionsText={"ไม่พบข้อมูล"}
            />
          </StyledBoxSearch>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              ระดับ
            </Typography>
            <StyledAutocomplete
              multiple
              id="combo-box-level"
              disableCloseOnSelect
              options={level}
              onChange={(event, newValue) => {
                handleChange(event, newValue, "level");
              }}
              popupIcon={<i class="fa-light fa-chevron-down"></i>}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder={
                    filterEmployee.level.length === 0 ? "ทุกระดับ" : ""
                  }
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  if (index === 0) {
                    return <Typography>{option}</Typography>;
                  } else {
                    return <Typography>, {option}</Typography>;
                  }
                })
              }
              PopperComponent={StyledPopper}
              noOptionsText={"ไม่พบข้อมูล"}
            />
          </StyledBoxSearch> */}
        </div>
      </StyledFilter>
    </StyledDrawer>
  );
};

export default FilterTable;
