import React from "react";
import { Box, Button, Typography, styled, LinearProgress } from "@mui/material";
import CardStyle from "../../../shared/general/Card";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const StyledContainer = styled(Box)(({ theme }) => ({
  padding: "24px",
  fontFamily:
    "Poppins, Prompt, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
}));

const CardObjective = (props) => {
  const { data } = props;
  const { t, i18n } = useTranslation();

  const QuarterBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 80,
    height: 80,
    backgroundColor: "rgba(219, 65, 120, 0.3)",
    marginRight: 8,
    color: theme.palette.common.white,
    fontSize: "30px",
    borderRadius: theme.shape.borderRadius,
  }));

  const CustomContainedButton = styled(Button)({
    backgroundColor: "#DB4178",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#C53B6C",
    },
    borderRadius: "7px",
    height: "50px",
    border: "none",
  });

  return (
    <CardStyle style={{ height: "100%" }}>
      <StyledContainer>
        <Box padding="24px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            bgcolor="rgba(219, 65, 120, 0.1)"
            p={2}
            borderRadius="8px"
          >
            <Box display="flex" alignItems="center">
              <QuarterBox>
                <Typography
                  variant="caption"
                  style={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  {`${t("Quarter")}`}
                </Typography>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "900", fontSize: "30px" }}
                >
                  {`${data.quarter}`}
                </Typography>
              </QuarterBox>
              <Typography fontSize="18px">
                {data.objectiveName}{" "}
                <Typography
                  component="span"
                  fontSize="16px"
                  color="text.secondary"
                >
                  {` (${dayjs()
                    .set("year", data.year)
                    .format(
                      i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"
                    )})`}
                </Typography>
              </Typography>
            </Box>
            <CustomContainedButton
              component={Link}
              to={`/okrs/individual-planning/information/${data.idIndividualObjective}`}
              variant="contained"
              startIcon={<SearchIcon />}
            >
              {`${t("Descriptions")}`}
            </CustomContainedButton>
          </Box>

          <Box mt={2}>
            {data.keyResults.map((keyresult) => (
              <Box
                key={keyresult.idKeyResult}
                display="flex"
                alignItems="center"
                mb={2}
                p={2}
              >
                <Typography flexGrow={1} fontSize="16px" fontWeight="500">
                  {keyresult.keyResultName}
                  <Typography fontSize="14px" color="text.secondary" mr={2}>
                    {"("}
                    {`${t("ApprovedBy")}`} {keyresult.manager_firstname_TH}{" "}
                    {keyresult.manager_lastname_TH}
                    {")"}
                  </Typography>
                </Typography>

                <Box width="40%" mr={2}>
                  <LinearProgress
                    variant="determinate"
                    value={keyresult.progressPercent}
                    sx={{
                      height: 10,
                      borderRadius: 5,
                      backgroundColor: "rgba(219, 65, 120, 0.1)",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "rgba(219, 65, 120, 1)",
                      },
                    }}
                  />
                </Box>
                <Typography fontSize="14px" color="text.secondary">
                  {`${keyresult.progressPercent}%`}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </StyledContainer>
    </CardStyle>
  );
};

export default CardObjective;
