import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Grid,
  Divider,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import DrawerCustom from "../../../../shared/general/Drawer";

import {
  updateAdmin,
} from "../../../../../../actions/company";

//import { updateAdminVendor, getAdminVendor } from "../../../../../../actions/vendor";

const StyledRoot = styled("div")({
  width: 350,
  padding: 24,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const DialogEdit = (props) => {
  const { open, valueSelected, handleCloseDialog } = props;
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (valueSelected) {
      setFormData({
        idAdminVendor: valueSelected.idAdminVendor,
        firstname: valueSelected.firstname_TH,
        lastname: valueSelected.lastname_TH,
        nickname: valueSelected.nickname,
        telephoneMobile: valueSelected.telephoneMobile,
        email: valueSelected.email,
      });
    }
  }, [open, valueSelected]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {

    console.log("formData: ", formData)
    let tempData = { ...formData };
    const result = await dispatch(updateAdmin(tempData));
    if (result) {
      //dispatch(getAdminVendor());
      handleCloseDialog();
    }
  };

  return (
    <DrawerCustom
      title={t("EditAdministratorData")}
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        {formData && (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("FirstName")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="firstname"
                value={formData.firstname}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("LastName")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="lastname"
                value={formData.lastname}
                fullWidth
                onChange={handleChange}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("PhoneNumber")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="telephoneMobile"
                value={formData.telephoneMobile}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("Email")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="email"
                value={formData.email}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        )}
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleCloseDialog}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit}>
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
