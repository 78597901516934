import {
  SALARY_REASONS_FETCHING,
  SALARY_REASONS_FAILED,
  SALARY_REASONS_SUCCESS,
} from "./types";

import SalaryReasonsService from "../services/salaryReasons.service";

export const getSalaryReasons = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SALARY_REASONS_FETCHING,
    });
    const res = await SalaryReasonsService.getSalaryReasons();
    if (res) {
      dispatch({
        type: SALARY_REASONS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SALARY_REASONS_FAILED,
    });
    console.log(err);
  }
};
