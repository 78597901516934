import {
  DEDUCTIONS_FETCHING,
  DEDUCTIONS_FAILED,
  DEDUCTIONS_SUCCESS,
} from "./types";

import DeductionService from "../services/deduction.service";

export const getAllDeductionsByCompany = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: DEDUCTIONS_FETCHING,
    });
    const res = await DeductionService.getAllDeductionsByCompany(idCompany);
    if (res) {
      dispatch({
        type: DEDUCTIONS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: DEDUCTIONS_FAILED,
    });
  }
};
