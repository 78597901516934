import React, { useState, useRef, useEffect } from "react";
import { Grid, Avatar, Container, TextField, Typography, Popper, Stack, Box } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import CompareIcon from "@mui/icons-material/Compare";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { getAllWork } from "../../../../actions/work";
import { useSelector, useDispatch } from "react-redux";
import Compare from "./compare";
import ButtonBlue from "../../shared/general/ButtonBlue";
import styled from "@emotion/styled";
import { getAllEmployees, getEmployeeProfileList } from "../../../../actions/employee";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { getUserFirstName, getUserFullName } from "../../../../utils/userData";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "100px",
    minHeight: "calc(100vh - 20px)",
    background: `url(${process.env.PUBLIC_URL}/assets/background/BG-Emp-Compare.jpg)`,
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  selectPerson: {
    padding: 10,
  },
  avatar: {
    width: 80,
    height: 80,
  },
}));

const StyleHeadText = styled(Typography)({
  "& .text-emp": {
    fontSize: "56px",
    textAlign: "center",
    color: "black",
    fontWeight: 600,
  },
  "& .text-compare": {
    fontSize: "44px",
    textAlign: "center",
    color: "gray",
    fontWeight: 400,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const SelectPersonToVS = () => {
  const formRef = useRef();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { result: employees } = useSelector((state) => state.employees);
  const [selectedEmployees, setSelectedEmployees] = useState(null);
  const [selectedEmployees1, setSelectedEmployees1] = useState(null);
  const [error, setError] = useState(false);
  const [showCompare, setShowCompare] = useState(false);

  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);

  const handleCompare = (data1, data2) => {
    if (data1.idEmployees === data2.idEmployees) {
      setError(true);
    } else {
      setShowCompare(true);
    }
  }

  const handleCloseCompare = () => {
    setShowCompare(false);
    setSelectedEmployees(null);
    setSelectedEmployees1(null);
  }

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        <StyleHeadText>
          <Typography className="text-emp">{t("Employee")}</Typography>
          <Typography className="text-compare">
            {t("compare")}
            <CompareIcon
              style={{ fontSize: "40px", transform: "rotate(30deg)" }}
            />
          </Typography>
        </StyleHeadText>

        {!showCompare ? (
          <div>
            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-around" spacing={2} alignItems={"center"}>
                  <Grid item>
                    <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={1}>
                      <StyledAutocomplete
                        options={employees}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setSelectedEmployees(newValue);
                          }
                        }}
                        popupIcon={<i className="fa-light fa-chevron-down"></i>}
                        getOptionLabel={(option) => `${getUserFullName(option)}`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            placeholder={t("searchOrSelectEmployee") + " 1"}
                          />
                        )}
                        PopperComponent={StyledPopper}
                        noOptionsText={t("NoData")}
                        sx={{ minWidth: 250 }}
                      />

                      {error && (
                        <Box>
                          <Typography color={"red"}>
                            {t("DontChooseSamePerson")}
                          </Typography>
                        </Box>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item>
                    <h2
                      className={classes.selectPerson}
                      style={{
                        backgroundColor: "white",
                        color: "pink",
                        borderRadius: "50%",
                        border: "5px solid pink",
                        borderColor: "pink",
                        width: 40,
                        height: 40,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      VS
                    </h2>
                  </Grid>
                  <Grid item>
                    <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={1}>
                      <StyledAutocomplete
                        options={employees}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setSelectedEmployees1(newValue);
                          }
                        }}
                        popupIcon={<i className="fa-light fa-chevron-down"></i>}
                        getOptionLabel={(option) => `${getUserFullName(option)}`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            placeholder={t("searchOrSelectEmployee") + " 2"}
                          />
                        )}
                        PopperComponent={StyledPopper}
                        noOptionsText={t("NoData")}
                        sx={{ minWidth: 250 }}
                      />

                      {error && (
                        <Box>
                          <Typography color={"red"}>
                            {t("DontChooseSamePerson")}
                          </Typography>
                        </Box>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Stack justifyContent={"center"} alignItems={"center"} sx={{ mt: 2 }}>
              <ButtonBlue
                variant="contained"
                startIcon={
                  <CompareArrowsIcon style={{ fontSize: "30px" }} />
                }
                style={{ fontSize: "20px" }}
                onClick={() => handleCompare(selectedEmployees, selectedEmployees1)}
                disabled={!selectedEmployees || !selectedEmployees1}
              >
                {t("compare")}
              </ButtonBlue>
            </Stack>
          </div>
        ) : (
          <Compare idEmp1={selectedEmployees} idEmp2={selectedEmployees1} handleCloseCompare={handleCloseCompare} />
        )}
      </Container>
    </div>
  );
};

export default SelectPersonToVS;