import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Button,
  TextField,
  Toolbar,
  InputAdornment,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";

import useTable from "../../../shared/table/useTable";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTypography-h6": {
      color: "#222f3e !important",
    },
    "& .MuiLinearProgress-root": {
      height: "10px",
      borderRadius: "10px",
    },
    "& .MuiTableCell-root": {
      padding: 8,
    },
    "& .MuiTableCell-head": {
      color: "#222f3e",
    },
  },
  header: {
    color: "#222f3e",
    fontSize: "1.5rem",
    fontWeight: "700",
    marginLeft: 32,
    marginTop: 24,
  },
  container: {
    padding: 16,
    overflowX: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  imageEmp: {
    width: 56,
    height: 56,
    borderRadius: "50%",
  },
  viewBtn: {
    backgroundColor: "#4f65df",
    color: "#fff",
    borderRadius: "50px",
    fontSize: "12px",
    padding: 8,
    "&:hover": {
      backgroundColor: "#4f65df",
    },
  },
}));

const headCells = [
  { id: "teamName", label: "Team Name" },
  { id: "objectiveAmount", label: "Objective" },
  { id: "keyResult", label: "Key Result" },
  { id: "achievePercent", label: "Achieve" },
  { id: "scorePercent", label: "Score (%)" },
];

function OrgTeamDashboardList(props) {
  const history = useHistory();
  const classes = useStyles();
  const { records } = props;
  const [teamList, setTeamList] = useState([]);
  const [teamItems, setTeamItems] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(teamList, headCells, filterFn);
  // Data first be members

  const handleCalScore = (achieved, objectiveAmount) => {
    let score = (achieved / objectiveAmount) * 100;

    if (isNaN(score)) {
      score = 0;
    }

    return score;
  };

  useEffect(() => {
    let x = [...teamItems];
    x = x.filter((y) => {
      return y.teamRecordName
        .toLowerCase()
        .includes(searchKey.toLocaleLowerCase());
    });

    setTeamList(x);
  }, [searchKey]);

  useEffect(() => {
    setTeamList(records);
    setTeamItems(records);
    setIsLoading(false);
  }, [records]);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item>
          <Typography className={classes.header}>Team List</Typography>
        </Grid>
      </Grid>
      <Toolbar>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              variant="outlined"
              style={{ width: "100%" }}
              label="Search Team"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Toolbar>
      <Paper className={classes.container}>
        <TblContainer>
          <TblHead />
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={9}
                  align="center"
                  style={{ width: "100px", padding: "20px" }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {(recordsAfterPagingAndSorting().length > 0 &&
                  recordsAfterPagingAndSorting().map((item) => (
                    <TableRow key={item.empId}>
                      <TableCell>{item.teamRecordName}</TableCell>
                      <TableCell style={{ paddingLeft: 10 }}>
                        {item.objectiveAmount}
                      </TableCell>
                      <TableCell>{item.keyResultAmount}</TableCell>
                      <TableCell>{item.achieved}</TableCell>
                      <TableCell>
                        {handleCalScore(item.achieved, item.objectiveAmount)}
                      </TableCell>
                    </TableRow>
                  ))) || (
                  <TableRow>
                    <TableCell
                      colSpan={9}
                      align="center"
                      style={{ width: "100px", height: "100px" }}
                    >
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
    </div>
  );
}

export default OrgTeamDashboardList;
