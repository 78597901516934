import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  styled,
  Card,
  Button,
  Tabs,
  Tab,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Grid,
  TextField,
  Autocomplete,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Avatar,
  Chip,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrganizationObjectives,
  getObjectivesByIdDepartment,
  getObjectivesByIdDivision,
  getAllIndividualObjectiveForAllEmployees,
  addManagerMoonShots,
  getManagerMoonShots,
  updateMoonshot,
} from "../../../../../actions/okrs";
import { getAffiliateOrganization } from "../../../../../actions/company";
import { getAllEmployees } from "../../../../../actions/employee";
import OrgObjectiveCard from "./components/ObjectiveCard";
import exportToExcel from "./components/exportToExcel";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import OrganizationPlanningAddFormPage from "./components/addForm";
import { getManager } from "../../../../../actions/manager";
import EditIcon from "@mui/icons-material/Edit";
import MoonshotIcon from "../../../assets/moonshot_logo.svg";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EvaluationCard from "../../../user/okrs/OKRManagementForUser/EvaluationCard";
import TeamPlanningAddFormPage from "../../../user/okrs/Planning/Team/addForm";
import IndividualPlanningAddFormPage from "../../../user/okrs/IndividualPlanning/addForm";
import WarningIcon from "@mui/icons-material/Warning";

const StyledRoot = styled(Box)({
  backgroundColor: "#ffffff !important",
  paddingBottom: "150px",
});

const StyledObjectiveCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  flexGrow: 1,
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
  borderRadius: 20,
  padding: "24px",
  background: `linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))`,
  "& .cardTitle": {
    fontSize: 22,
    fontWeight: "bold",
    textAlign: "center",
    wordWrap: "break-word",
  },
  "& .percentage": {
    fontSize: 48,
    fontWeight: "bold",
    textAlign: "center",
  },
});

const ResponsiveButton = styled(Button)(({ theme }) => ({
  backgroundColor: "rgba(219, 65, 120, 1)",
  fontSize: "14px",
  fontWeight: "bold",
  borderRadius: "7px",
  padding: "10px 20px",
  color: "#ffffff",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "#C53B6C",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
}));

const StyledReadOnlyChip = styled(Chip)({
  backgroundColor: "#d32f2f",
  color: "#ffffff",
  fontWeight: "bold",
  borderRadius: "5px",
  marginRight: "8px",
});

const OrganizationPlanning = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [selectYear, setSelectYear] = useState(dayjs().get("year"));
  const [selectedCompany, setSelectedCompany] = useState("");
  const [objectiveTab, setObjectiveTab] = useState(0);
  const [showAddForm, setShowAddForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const [filterType, setFilterType] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedDivision, setSelectedDivision] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedEmployeeOKR, setSelectedEmployeeOKR] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectQuarter, setSelectQuarter] = useState("");

  const [personalObjectives, setPersonalObjectives] = useState([]);
  const [cascadedOrganizationObjectives, setCascadedOrganizationObjectives] =
    useState([]);

  const [
    objectivesByDepartmentOrDivision,
    setObjectivesByDepartmentOrDivision,
  ] = useState([]);

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const isAdmin = userProfile && userProfile.roles.includes("ROLE_ADMIN");

  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );

  const { result: organizationObjectives } = useSelector(
    (state) => state.organizationObjective
  );

  const { result: employeesList } = useSelector((state) => state.employees);

  const { result: manager } = useSelector((state) => state.manager);

  const [objectivesUpdated, setObjectivesUpdated] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedManager, setSelectedManager] = useState(null);
  const [moonshotsPerEmployee, setMoonshotsPerEmployee] = useState("");
  const [managerMoonshots, setManagerMoonshots] = useState([]);
  const [searchEmployeeQuery, setSearchEmployeeQuery] = useState("");
  const [openAddMoonshotDialog, setOpenAddMoonshotDialog] = useState(false);
  const [selectedEmployeeForAdd, setSelectedEmployeeForAdd] = useState(null);
  const [additionMoonshots, setAdditionMoonshots] = useState("");
  const [evaluateOrgObjectives, setEvaluateOrgObjectives] = useState([]);

  useEffect(() => {
    if (userProfile && userProfile.idCompany) {
      setSelectedCompany(userProfile.idCompany.toString());
    }
  }, [userProfile]);

  useEffect(() => {
    if (activeTab === 0) {
      dispatch(getOrganizationObjectives());
      dispatch(getAffiliateOrganization());
    } else if (activeTab === 1) {
      dispatch(getOrganizationObjectives());
    } else if (activeTab === 3) {
      dispatch(getManagerMoonShots()).then((res) => {
        if (res.status === 200) {
          setManagerMoonshots(res.data);
        }
      });
    }
  }, [dispatch, activeTab, objectivesUpdated]);

  useEffect(() => {
    if (activeTab === 3) {
      const fetchManagers = async () => {
        try {
          setLoading(true);
          if (selectedCompany) {
            await dispatch(getManager({ idCompany: Number(selectedCompany) }));
          } else {
            await dispatch(getManager());
          }
        } catch (error) {
          console.error("Error fetching managers:", error);
          setManagerMoonshots([]);
        } finally {
          setLoading(false);
        }
      };

      fetchManagers();
    }
  }, [dispatch, activeTab, selectedCompany]);

  useEffect(() => {
    if (activeTab === 2) {
      const today = dayjs().startOf("day");
      const filteredObjectives = (organizationObjectives || [])
        .map((objective) => {
          const keyResultsForEvaluation = objective.keyResults.filter((kr) => {
            const isSuccessNull = kr.isSuccess == null;
            const endDatePassed = dayjs(kr.endDate).isSameOrBefore(today);
            const progressComplete = kr.progressPercent === 100;

            return isSuccessNull && (endDatePassed || progressComplete);
          });

          if (keyResultsForEvaluation.length > 0) {
            return {
              ...objective,
              keyResults: keyResultsForEvaluation,
            };
          }
          return null;
        })
        .filter((objective) => objective !== null);

      setEvaluateOrgObjectives(filteredObjectives);
    }
  }, [activeTab, organizationObjectives, objectivesUpdated, dispatch]);

  const moonshotMap = {};
  if (managerMoonshots && managerMoonshots.length > 0) {
    managerMoonshots.forEach((moonshot) => {
      moonshotMap[moonshot.idManager] = moonshot;
    });
  }

  useEffect(() => {
    const fetchPersonalObjectives = async () => {
      if (objectiveTab === 2) {
        dispatch(getAllEmployees());
        const response = await dispatch(
          getAllIndividualObjectiveForAllEmployees()
        );
        setPersonalObjectives(response.data);
        const cascadedOrgObjectives =
          response.data.organizationObjectives || [];
        setCascadedOrganizationObjectives(cascadedOrgObjectives);
      }
    };

    fetchPersonalObjectives();
  }, [objectiveTab, dispatch]);

  useEffect(() => {
    const fetchObjectives = async () => {
      if (filterType === "department" && selectedDepartment) {
        const response = await dispatch(
          getObjectivesByIdDepartment(selectedDepartment)
        );
        setObjectivesByDepartmentOrDivision(response.data);
      } else if (filterType === "division" && selectedDivision) {
        const response = await dispatch(
          getObjectivesByIdDivision(selectedDivision)
        );
        setObjectivesByDepartmentOrDivision(response.data);
      }
    };

    fetchObjectives();
  }, [filterType, selectedDepartment, selectedDivision, dispatch]);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleChangeObjectiveTab = (event, newValue) => {
    setObjectiveTab(newValue);
    setFilterType("");
    setSelectedDepartment("");
    setSelectedDivision("");
  };

  const handleChangeYear = (event) => {
    setSelectYear(event.target.value);
  };

  const handleChangeCompany = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleChangeFilterType = (event) => {
    setFilterType(event.target.value);
    setSelectedDepartment("");
    setSelectedDivision("");
  };

  const handleChangeDepartment = (event) => {
    setSelectedDepartment(event.target.value);
  };

  const handleChangeDivision = (event) => {
    setSelectedDivision(event.target.value);
  };

  const handleChangeEmployee = (event, newValue) => {
    setSelectedEmployee(newValue ? newValue.idEmployees : "");
    setSelectedEmployeeOKR(newValue ? newValue : null);
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredObjectives = personalObjectives.filter(
    (objective) => objective.idEmployees === selectedEmployee
  );

  const handleExportClick = () => {
    if (objectiveTab === 0) {
      exportToExcel(organizationObjectives, t);
    } else if (objectiveTab === 1) {
      exportToExcel(objectivesByDepartmentOrDivision, t);
    } else if (objectiveTab === 2) {
      exportToExcel(personalObjectives, t);
    }
  };

  const filterObjectivesBySearch = (objectives) => {
    return objectives.filter((objective) =>
      objective.objectiveName.toLowerCase().includes(searchQuery)
    );
  };

  const handleOpenDialog = (manager) => {
    setSelectedManager(manager);
    setMoonshotsPerEmployee("");
    setOpenDialog(true);
  };

  const handleObjectiveUpdated = () => {
    setObjectivesUpdated((prev) => !prev);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedManager(null);
  };

  const handleAssignMoonshot = async () => {
    try {
      const formData = {
        moonshotsPerEmployee: Number(moonshotsPerEmployee),
        numberOfEmployees: selectedManager.numberOfEmployees,
        totalMoonshots:
          Number(moonshotsPerEmployee) * selectedManager.numberOfEmployees,
      };

      const res = await dispatch(
        addManagerMoonShots(selectedManager.idEmployees, formData)
      );

      if (res.status === 201) {
        handleObjectiveUpdated();
      } else {
        console.error("Error assigning moonshots:", res);
      }
    } catch (error) {
      console.error("Error assigning moonshots:", error);
    } finally {
      setMoonshotsPerEmployee("");
      handleCloseDialog();
    }
  };

  const handleOpenAddMoonshotDialog = (manager) => {
    setSelectedEmployeeForAdd(manager);
    setAdditionMoonshots("");
    setOpenAddMoonshotDialog(true);
  };

  const handleCloseAddMoonshotDialog = () => {
    setOpenAddMoonshotDialog(false);
    setSelectedEmployeeForAdd(null);
  };

  const handleAddMoonshot = async () => {
    if (!additionMoonshots || Number(additionMoonshots) <= 0) {
      alert(t("Please enter a number greater than 0."));
      return;
    }
    try {
      const res = await dispatch(
        updateMoonshot(
          selectedEmployeeForAdd.idEmployees,
          Number(additionMoonshots)
        )
      );
      if (res.status === 200 || res.status === 201) {
        handleObjectiveUpdated();
        handleCloseAddMoonshotDialog();
      } else {
        console.error("Error updating moonshots:", res);
      }
    } catch (error) {
      console.error("Error updating moonshots:", error);
    }
  };

  console.log(cascadedOrganizationObjectives);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Box
          className="header-box"
          sx={{
            padding: "16px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            background:
              "linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))",
            borderRadius: "16px",
          }}
        >
          <Typography
            className="header-text"
            sx={{ fontSize: "48px", fontWeight: "bold" }}
          >
            OKR Management
          </Typography>
        </Box>

        <Box
          marginTop={2}
          marginBottom={4}
          sx={{
            borderBottom: "2px",
            boxShadow: "0",
          }}
        >
          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
          >
            <Tab label={t("Home")}></Tab>
            <Tab label={t("UpdateOrganizationalGoals")}></Tab>
            <Tab label={t("EvaluateOrg")}></Tab>
            <Tab label={t("Set up Moonshot")}></Tab>
          </Tabs>
        </Box>

        {activeTab === 0 && (
          <>
            <Box
              display="flex"
              alignItems="center"
              marginBottom={4}
              padding="24px"
              borderRadius="20px"
              backgroundColor="#ffffff"
            >
              <Typography variant="h6" gutterBottom>
                {t("OrganizationalGoals")}
              </Typography>
              <FormControl
                className="filter-company"
                sx={{ marginLeft: "24px", minWidth: "400px" }}
              >
                <InputLabel>{t("SelectCompany")}</InputLabel>
                <Select
                  label={t("SelectCompany")}
                  value={selectedCompany}
                  onChange={handleChangeCompany}
                  sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
                >
                  {affiliateOrganizationList &&
                    affiliateOrganizationList.map((company) => (
                      <MenuItem
                        key={company.idCompany}
                        value={company.idCompany}
                      >
                        {company.companyName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl
                className="filter-year"
                sx={{ marginLeft: "24px", minWidth: "150px" }}
              >
                <InputLabel>{t("SelectYear")}</InputLabel>
                <Select
                  label={t("SelectYear")}
                  value={selectYear}
                  onChange={handleChangeYear}
                  sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
                >
                  {[0, 1, 2].map((item) => (
                    <MenuItem key={item} value={dayjs().get("year") - item}>
                      {dayjs().get("year") - item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Grid container spacing={3} marginBottom={4} alignItems="stretch">
              {organizationObjectives &&
                organizationObjectives
                  .filter(
                    (objective) =>
                      objective.year === selectYear &&
                      (selectedCompany === "" ||
                        objective.idCompany === Number(selectedCompany))
                  )
                  .sort(
                    (a, b) => new Date(b.updateDate) - new Date(a.updateDate)
                  )
                  .slice(0, 3)
                  .map((objective) => (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      key={objective.idOrganizationObjective}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <StyledObjectiveCard>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Typography
                            className="percentage"
                            style={{ color: "#DB4178" }}
                          >{`${objective.progressPercent}%`}</Typography>
                          <Typography className="cardTitle" align="center">
                            {objective.objectiveName}
                          </Typography>
                        </Box>
                      </StyledObjectiveCard>
                    </Grid>
                  ))}
            </Grid>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={4}
            >
              <Tabs
                value={objectiveTab}
                onChange={handleChangeObjectiveTab}
                indicatorColor="primary"
              >
                <Tab label={t("OrganizationalGoals")}></Tab>
                <Tab label={t("TeamGoals")}></Tab>
                <Tab label={t("PersonalGoals")}></Tab>
              </Tabs>
              {isAdmin &&
                (objectiveTab === 0 ||
                  objectiveTab === 1 ||
                  objectiveTab === 2) && (
                  <ResponsiveButton onClick={() => setShowAddForm(true)}>
                    <AddIcon style={{ marginRight: "8px" }} />{" "}
                    {t("CreateObjective")}
                  </ResponsiveButton>
                )}
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              marginBottom={4}
            >
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                gap={2}
                sx={{ flexGrow: 1, minWidth: "300px" }}
              >
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel shrink>{t("SelectQuarter")}</InputLabel>
                  <Select
                    label={t("SelectQuarter")}
                    value={selectQuarter || ""}
                    onChange={(e) => setSelectQuarter(e.target.value)}
                    sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected === "") {
                        return <span>{t("All")}</span>;
                      }
                      return t(`Q${selected}`);
                    }}
                  >
                    <MenuItem value="">{t("All")}</MenuItem>
                    <MenuItem value="1">{t("Q1")}</MenuItem>
                    <MenuItem value="2">{t("Q2")}</MenuItem>
                    <MenuItem value="3">{t("Q3")}</MenuItem>
                    <MenuItem value="4">{t("Q4")}</MenuItem>
                  </Select>
                </FormControl>

                {objectiveTab === 1 && (
                  <>
                    <FormControl
                      className="filter-type"
                      sx={{ minWidth: "200px" }}
                    >
                      <InputLabel>{t("FilterBy")}</InputLabel>
                      <Select
                        label={t("FilterBy")}
                        value={filterType}
                        onChange={handleChangeFilterType}
                        sx={{
                          borderRadius: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <MenuItem value="division">{t("Division")}</MenuItem>
                        <MenuItem value="department">
                          {t("Department")}
                        </MenuItem>
                        {/* <MenuItem value="department">{t("Section")}</MenuItem> */}
                      </Select>
                    </FormControl>

                    {filterType === "department" && (
                      <FormControl
                        className="filter-department"
                        sx={{ minWidth: "200px" }}
                      >
                        <InputLabel>{t("SelectDepartment")}</InputLabel>
                        <Select
                          label={t("SelectDepartment")}
                          value={selectedDepartment}
                          onChange={handleChangeDepartment}
                          sx={{
                            borderRadius: "10px",
                            backgroundColor: "#ffffff",
                          }}
                        >
                          {affiliateOrganizationList &&
                            affiliateOrganizationList
                              .filter(
                                (company) =>
                                  company.idCompany === Number(selectedCompany)
                              )
                              .flatMap((company) => company.department || [])
                              .map((department) => (
                                <MenuItem
                                  key={department.idDepartment}
                                  value={department.idDepartment}
                                >
                                  {department.departmentName}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    )}

                    {filterType === "division" && (
                      <FormControl
                        className="filter-division"
                        sx={{ minWidth: "200px" }}
                      >
                        <InputLabel>{t("SelectDivision")}</InputLabel>
                        <Select
                          label={t("SelectDivision")}
                          value={selectedDivision}
                          onChange={handleChangeDivision}
                          sx={{
                            borderRadius: "10px",
                            backgroundColor: "#ffffff",
                          }}
                        >
                          {affiliateOrganizationList &&
                            affiliateOrganizationList
                              .filter(
                                (company) =>
                                  company.idCompany === Number(selectedCompany)
                              )
                              .flatMap((company) => company.division || [])
                              .map((division) => (
                                <MenuItem
                                  key={division.idDivision}
                                  value={division.idDivision}
                                >
                                  {division.divisionName}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    )}
                  </>
                )}

                {objectiveTab === 2 && (
                  <>
                    <Autocomplete
                      options={
                        employeesList
                          ? employeesList.filter(
                              (employee) =>
                                employee.idCompany === Number(selectedCompany)
                            )
                          : []
                      }
                      getOptionLabel={(option) =>
                        option
                          ? `${option.firstname_TH} ${option.lastname_TH}`
                          : ""
                      }
                      value={
                        selectedEmployee && employeesList
                          ? employeesList.find(
                              (emp) => emp.idEmployees === selectedEmployee
                            ) || null
                          : null
                      }
                      onChange={handleChangeEmployee}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("SelectEmp")}
                          placeholder={t("SearchEmp")}
                          variant="outlined"
                          sx={{
                            width: 300,
                            borderRadius: "10px",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                            },
                          }}
                        />
                      )}
                      sx={{
                        width: 300,
                        borderRadius: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.idEmployees === value.idEmployees
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          {...props}
                          display="flex"
                          alignItems="center"
                          gap={1}
                        >
                          {option.imageProfile ? (
                            <img
                              src={option.imageProfile}
                              alt={option.firstname_TH}
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <PersonIcon style={{ fontSize: 30 }} />
                          )}
                          {`${option.firstname_TH} ${option.lastname_TH}`}
                        </Box>
                      )}
                    />
                  </>
                )}
              </Box>

              <Box display="flex" alignItems="center">
                {/* {objectiveTab === 2 && <StyledReadOnlyChip label="ReadOnly" />} */}
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: "rgba(219, 65, 120, 1)",
                    color: "rgba(219, 65, 120, 1)",
                    fontWeight: "bold",
                    "&:hover": {
                      borderColor: "#C53B6C",
                      color: "#C53B6C",
                    },
                  }}
                  onClick={handleExportClick}
                >
                  {t("ExportToExcel")}
                </Button>
              </Box>
            </Box>

            {objectiveTab === 0 && (
              <Grid container spacing={3}>
                {filterObjectivesBySearch(
                  (organizationObjectives || [])
                    .filter((objective) => {
                      if (objective.year !== selectYear) return false;
                      if (
                        selectedCompany &&
                        objective.idCompany !== Number(selectedCompany)
                      )
                        return false;
                      if (
                        selectQuarter &&
                        objective.quarter !== Number(selectQuarter)
                      )
                        return false;
                      return true;
                    })
                    .sort((a, b) => {
                      const dateA = a.latestUpdateDate
                        ? dayjs(a.latestUpdateDate)
                        : dayjs(0);
                      const dateB = b.latestUpdateDate
                        ? dayjs(b.latestUpdateDate)
                        : dayjs(0);
                      return dateB.diff(dateA);
                    })
                ).map((objective) => (
                  <Grid item xs={12} key={objective.idOrganizationObjective}>
                    <OrgObjectiveCard objective={objective} />
                  </Grid>
                ))}
              </Grid>
            )}

            {objectiveTab === 1 && (
              <Grid container spacing={3}>
                {filterObjectivesBySearch(
                  (objectivesByDepartmentOrDivision || [])
                    .filter((objective) => {
                      if (objective.year !== selectYear) return false;

                      if (
                        selectQuarter &&
                        objective.quarter !== Number(selectQuarter)
                      )
                        return false;
                      return true;
                    })
                    .sort((a, b) => {
                      const dateA = a.latestUpdateDate
                        ? dayjs(a.latestUpdateDate)
                        : dayjs(0);
                      const dateB = b.latestUpdateDate
                        ? dayjs(b.latestUpdateDate)
                        : dayjs(0);
                      return dateB.diff(dateA);
                    })
                ).map((objective) => (
                  <Grid item xs={12} key={objective.idTeamObjective}>
                    <OrgObjectiveCard objective={objective} />
                  </Grid>
                ))}
              </Grid>
            )}

            {objectiveTab === 2 && (
              <Grid container spacing={3}>
                {filterObjectivesBySearch(
                  cascadedOrganizationObjectives
                    .filter((objective) => {
                      if (objective.year !== selectYear) return false;
                      if (
                        selectedCompany &&
                        objective.idCompany !== Number(selectedCompany)
                      )
                        return false;
                      if (
                        selectQuarter &&
                        objective.quarter !== Number(selectQuarter)
                      )
                        return false;
                      return true;
                    })
                    .sort((a, b) => {
                      const dateA = a.latestUpdateDate
                        ? new Date(a.latestUpdateDate)
                        : new Date(0);
                      const dateB = b.latestUpdateDate
                        ? new Date(b.latestUpdateDate)
                        : new Date(0);
                      return dateB - dateA;
                    })
                ).map((objective) => (
                  <Grid item xs={12} key={objective.idOrganizationObjective}>
                    <OrgObjectiveCard objective={objective} />
                  </Grid>
                ))}
                {filterObjectivesBySearch(
                  filteredObjectives
                    .filter((objective) => {
                      if (objective.year !== selectYear) return false;
                      if (
                        selectedCompany &&
                        objective.idCompany !== Number(selectedCompany)
                      )
                        return false;
                      if (
                        selectQuarter &&
                        objective.quarter !== Number(selectQuarter)
                      )
                        return false;
                      return true;
                    })
                    .sort((a, b) => {
                      const dateA = a.latestUpdateDate
                        ? dayjs(a.latestUpdateDate)
                        : dayjs(0);
                      const dateB = b.latestUpdateDate
                        ? dayjs(b.latestUpdateDate)
                        : dayjs(0);
                      return dateB.diff(dateA);
                    })
                ).map((objective) => (
                  <Grid item xs={12} key={objective.idIndividualObjective}>
                    <OrgObjectiveCard objective={objective} />
                  </Grid>
                ))}
              </Grid>
            )}

            <Dialog
              open={showAddForm}
              onClose={() => setShowAddForm(false)}
              fullWidth
              maxWidth="lg"
            >
              {objectiveTab === 1 ? (
                <TeamPlanningAddFormPage
                  onClose={() => setShowAddForm(false)}
                />
              ) : objectiveTab === 2 ? (
                selectedEmployeeOKR ? (
                  <IndividualPlanningAddFormPage
                    onClose={() => setShowAddForm(false)}
                    selectedEmployeeOKR={selectedEmployeeOKR}
                  />
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    padding={4}
                    sx={{ minHeight: "200px" }}
                  >
                    <WarningIcon
                      color="warning"
                      style={{ fontSize: 48, marginBottom: 16 }}
                    />
                    <Typography variant="h6" align="center" gutterBottom>
                      {t("กรุณาเลือกพนักงานก่อน")}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setShowAddForm(false)}
                    >
                      {t("ปิด")}
                    </Button>
                  </Box>
                )
              ) : (
                <OrganizationPlanningAddFormPage
                  onClose={() => setShowAddForm(false)}
                />
              )}
            </Dialog>
          </>
        )}

        {activeTab === 1 && (
          <>
            <Box marginBottom={"24px"}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6} md={5}>
                  <FormControl fullWidth>
                    <InputLabel>{t("SelectCompany")}</InputLabel>
                    <Select
                      label={t("SelectCompany")}
                      value={selectedCompany}
                      onChange={handleChangeCompany}
                      sx={{
                        borderRadius: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    >
                      <MenuItem value="">
                        <em>{t("AllCompanies")}</em>
                      </MenuItem>
                      {affiliateOrganizationList &&
                        affiliateOrganizationList.map((company) => (
                          <MenuItem
                            key={company.idCompany}
                            value={company.idCompany}
                          >
                            {company.companyName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <InputLabel>{t("SelectYear")}</InputLabel>
                    <Select
                      label={t("SelectYear")}
                      value={selectYear}
                      onChange={handleChangeYear}
                      sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
                    >
                      {[0, 1, 2].map((item) => (
                        <MenuItem key={item} value={dayjs().get("year") - item}>
                          {dayjs().subtract(item, "year").format("YYYY")}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <InputLabel shrink>{t("SelectQuarter")}</InputLabel>
                    <Select
                      label={t("SelectQuarter")}
                      value={selectQuarter || ""}
                      onChange={(e) => setSelectQuarter(e.target.value)}
                      sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected === "") {
                          return <span>{t("All")}</span>;
                        }
                        return t(`Q${selected}`);
                      }}
                    >
                      <MenuItem value="">{t("All")}</MenuItem>
                      <MenuItem value="1">{t("Q1")}</MenuItem>
                      <MenuItem value="2">{t("Q2")}</MenuItem>
                      <MenuItem value="3">{t("Q3")}</MenuItem>
                      <MenuItem value="4">{t("Q4")}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            {organizationObjectives &&
              organizationObjectives
                .filter((objective) => {
                  if (objective.year !== selectYear) return false;
                  if (
                    selectedCompany &&
                    objective.idCompany !== Number(selectedCompany)
                  )
                    return false;
                  if (
                    selectQuarter &&
                    objective.quarter !== Number(selectQuarter)
                  )
                    return false;
                  return true;
                })
                .map((objective) => (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    key={objective.idOrganizationObjective}
                  >
                    <OrgObjectiveCard
                      objective={objective}
                      isEditable={activeTab === 1}
                      onObjectiveUpdated={handleObjectiveUpdated}
                    />
                  </Grid>
                ))}
          </>
        )}

        {activeTab === 2 && (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              marginBottom={4}
            >
              <Typography variant="h6">
                {t("Evaluate Organization Key Results")}
              </Typography>
            </Box>

            <EvaluationCard
              objectives={evaluateOrgObjectives}
              onEvaluationSubmitted={handleObjectiveUpdated}
            />
          </>
        )}

        {activeTab === 3 && (
          <>
            <Box display="flex" alignItems="center" marginBottom={4} gap={2}>
              <FormControl sx={{ flexGrow: 1, minWidth: 200 }}>
                <InputLabel>{t("SelectCompany")}</InputLabel>
                <Select
                  label={t("SelectCompany")}
                  value={selectedCompany}
                  onChange={(e) => setSelectedCompany(e.target.value)}
                  sx={{
                    borderRadius: "10px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  {affiliateOrganizationList &&
                    affiliateOrganizationList.map((company) => (
                      <MenuItem
                        key={company.idCompany}
                        value={company.idCompany}
                      >
                        {company.companyName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl sx={{ flexGrow: 1, minWidth: 200 }}>
                <TextField
                  label={t("SearchEmp")}
                  variant="outlined"
                  fullWidth
                  value={searchEmployeeQuery}
                  onChange={(e) =>
                    setSearchEmployeeQuery(e.target.value.toLowerCase())
                  }
                  placeholder={t("SearchFullName")}
                  sx={{
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      backgroundColor: "#ffffff",
                    },
                  }}
                />
              </FormControl>
            </Box>

            {loading ? (
              <Typography>{t("Loading")}</Typography>
            ) : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600 }} aria-label="manager table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">{t("MoonShot")}</TableCell>
                      <TableCell align="center">{t("FullName")}</TableCell>
                      <TableCell align="center">{t("Division")}</TableCell>
                      <TableCell align="center">{t("Department")}</TableCell>
                      <TableCell align="center">{t("Section")}</TableCell>
                      <TableCell align="center">{t("Position")}</TableCell>
                      <TableCell align="center" style={{ width: "50px" }}>
                        {/* Empty header for icon */}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {manager && manager.length > 0 ? (
                      manager
                        .filter((managerItem) =>
                          `${managerItem.firstname_TH} ${managerItem.lastname_TH}`
                            .toLowerCase()
                            .includes(searchEmployeeQuery)
                        )
                        .map((managerItem, index) => {
                          const moonshotData =
                            moonshotMap[managerItem.idEmployees];

                          return (
                            <TableRow key={index}>
                              {/* Moonshots Column */}
                              <TableCell align="center">
                                {moonshotData ? (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <img
                                      src={MoonshotIcon}
                                      alt="Moonshot"
                                      style={{
                                        width: 24,
                                        height: 24,
                                        marginRight: 4,
                                      }}
                                    />
                                    <Typography variant="body1">
                                      {moonshotData.remaining_moonshots}
                                      {"/"}
                                      {moonshotData.total_moonshots}
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {t("NoMoonshots")}
                                  </Typography>
                                )}
                              </TableCell>

                              {/* Full Name Column */}
                              <TableCell align="center">
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="flex-start"
                                  sx={{ marginLeft: 10 }}
                                >
                                  <Avatar
                                    alt={`${managerItem.firstname_TH} ${managerItem.lastname_TH}`}
                                    src={managerItem.imageURL || ""}
                                    sx={{
                                      marginRight: 1,
                                      width: 40,
                                      height: 40,
                                    }}
                                  >
                                    {!managerItem.imageURL && <PersonIcon />}
                                  </Avatar>
                                  <Typography variant="body1">
                                    {`${managerItem.firstname_TH} ${managerItem.lastname_TH}`}
                                  </Typography>
                                </Box>
                              </TableCell>

                              {/* Division Column */}
                              <TableCell align="center" sx={{ minWidth: 80 }}>
                                {managerItem.divisionName || "-"}
                              </TableCell>
                              {/* Department Column */}
                              <TableCell align="center" sx={{ minWidth: 80 }}>
                                {managerItem.departmentName || "-"}
                              </TableCell>

                              {/* Section Column */}
                              <TableCell align="center" sx={{ minWidth: 80 }}>
                                {managerItem.sectionName || "-"}
                              </TableCell>
                              {/* Position Column */}
                              <TableCell align="center" sx={{ minWidth: 80 }}>
                                {managerItem.positionName || "-"}
                              </TableCell>

                              {/* Actions Column */}
                              <TableCell
                                align="center"
                                style={{ width: "50px" }}
                              >
                                {!moonshotData ||
                                moonshotData.remaining_moonshots === 0 ? (
                                  <Tooltip title={t("SettingMoonshot")}>
                                    <IconButton
                                      onClick={() =>
                                        handleOpenDialog(managerItem)
                                      }
                                      size="small"
                                    >
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title={t("AddMoonshot")}>
                                    <IconButton
                                      onClick={() =>
                                        handleOpenAddMoonshotDialog(managerItem)
                                      }
                                      size="small"
                                    >
                                      <AddCircleIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          {" "}
                          {/* Updated colSpan to 7 */}
                          {t("NoManagersFound")}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            <Box marginTop={4} textAlign="center">
              <Typography variant="h5" sx={{ marginBottom: 6 }}>
                {t("Moonshot Summary")}
              </Typography>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={4}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Avatar
                      src={MoonshotIcon}
                      alt="Moonshot Used"
                      sx={{ width: 50, height: 50 }}
                    />
                    <Typography variant="body1">
                      {t("Moonshots Used")}
                    </Typography>
                    <Typography variant="h6" color="primary">
                      {managerMoonshots.reduce(
                        (sum, item) =>
                          sum + item.total_moonshots - item.remaining_moonshots,
                        0
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Avatar
                      src={MoonshotIcon}
                      alt="Moonshot Remaining"
                      sx={{ width: 50, height: 50 }}
                    />
                    <Typography variant="body1">
                      {t("Remaining Moonshots")}
                    </Typography>
                    <Typography variant="h6" color="secondary">
                      {managerMoonshots.reduce(
                        (sum, item) => sum + item.remaining_moonshots,
                        0
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Avatar
                      src={MoonshotIcon}
                      alt="Total Moonshots"
                      sx={{ width: 50, height: 50 }}
                    />
                    <Typography variant="body1">
                      {t("Total Moonshots")}
                    </Typography>
                    <Typography variant="h6" color="textPrimary">
                      {managerMoonshots.reduce(
                        (sum, item) => sum + item.total_moonshots,
                        0
                      )}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            {/* Dialog */}
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              aria-labelledby="assign-moonshot-dialog-title"
            >
              <DialogTitle id="assign-moonshot-dialog-title">
                {t("AssignMoonshotTo")}{" "}
                {selectedManager &&
                  `${selectedManager.firstname_TH} ${selectedManager.lastname_TH}`}
              </DialogTitle>
              <DialogContent>
                <Typography>
                  {t("NumberOfEmployeesUnderManager")}:{" "}
                  {selectedManager && selectedManager.numberOfEmployees}
                </Typography>
                <TextField
                  label={t("MoonshotsPerEmployee")}
                  type="number"
                  value={moonshotsPerEmployee}
                  onChange={(e) => setMoonshotsPerEmployee(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  {t("Cancel")}
                </Button>
                <Button onClick={handleAssignMoonshot} color="primary">
                  {t("Confirm")}
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openAddMoonshotDialog}
              onClose={handleCloseAddMoonshotDialog}
              aria-labelledby="add-moonshot-dialog-title"
            >
              <DialogTitle id="add-moonshot-dialog-title">
                {t("Add Moonshot to")}{" "}
                {selectedEmployeeForAdd &&
                  `${selectedEmployeeForAdd.firstname_TH} ${selectedEmployeeForAdd.lastname_TH}`}
              </DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label={t("Additional Moonshots")}
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={additionMoonshots}
                  onChange={(e) => setAdditionMoonshots(e.target.value)}
                  inputProps={{ min: 1 }}
                  helperText={t("Enter a number greater than 0")}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAddMoonshotDialog} color="primary">
                  {t("Cancel")}
                </Button>
                <Button onClick={handleAddMoonshot} color="primary">
                  {t("Confirm")}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Container>
    </StyledRoot>
  );
};

export default OrganizationPlanning;
