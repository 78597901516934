import { httpClient } from "./httpClient";

const getLineUserProfile = (id, source) => {
  if (source) {
    return httpClient.get(`line/profile/${id}?source=pwa`);
  } else {
    return httpClient.get(`line/profile/${id}`);
  }
};


const postAttendance = (formData) => {
  return httpClient.post(`line/attendance`, formData);
};

const registerLine = (formData) => {
  return httpClient.put(`line/profile/lineId`, formData);
};

export default {
  getLineUserProfile,
  postAttendance,
  registerLine,
};
