import React, { useState } from "react";
import useTable from "../../../../shared/table/useTable";
import dayjs from "dayjs";
import { makeStyles } from "@mui/styles";
import {
  Avatar,
  CircularProgress,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

const headCells = [
  { id: "image", label: "" },
  { id: "fullName", label: "Employee" },
  { id: "modification", label: "การเปลี่ยนแปลง" },
  { id: "editDate", label: "Date" },
  { id: "editBy", label: "By" },
];

function DataTable(props) {
  const { records, isLoading } = props;

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);

  return (
    <div>
      <TblContainer>
        <TblHead />
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={5} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            (recordsAfterPagingAndSorting().length > 0 &&
              recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.recordId}>
                  <TableCell>
                    <Avatar
                      alt={item.fullName}
                      src="/static/images/avatar/1.jpg"
                    />
                  </TableCell>
                  <TableCell>{item.fullName}</TableCell>
                  <TableCell>{item.modification}</TableCell>
                  <TableCell>
                    {dayjs(item.editDate).format("DD/MM/YYYY HH:mm")}
                  </TableCell>
                  <TableCell>{item.editBy}</TableCell>
                </TableRow>
              ))) || (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No Data
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </TblContainer>
      <TblPagination />
    </div>
  );
}

export default DataTable;
