import React, { useState, useRef, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { putJobPosition } from "../../../../../actions/jobPositions";

import {
  Grid,
  Container,
  Paper,
  Button,
  TextField,
  InputAdornment,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Box,
  Typography,
  Divider,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import CardStyle from "../../../shared/general/Card";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "calc(100vh - 20px)",
    marginTop: "20px",
  },
  formControl: {
    marginRight: 8,
    // width: "90%",
    width: "calc(100% - 16px)",
    height: "90%",
  },
  paper: {
    padding: 24,
    marginBottom: 10,
  },
  space: {
    marginBottom: 5,
  },
  itemSize: {
    // width: "90%",
    width: "calc(100% - 16px)",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  legendNone: {
    "& legend": {
      width: 0,
    },
  },
}));

const FormJob = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const [subJobName] = useState("");
  const dispatch = useDispatch();

  const formRef = useRef();

  const validationSchema1 = Yup.object().shape({
    name: Yup.string().required("Please enter subjob name"),
    fit: Yup.string().required("Please enter job fit"),
    // attribute: Yup.array(Yup.string().required("Please select attribute")),
    // level: Yup.array(Yup.string().required("Please select level")),
  });

  const validationSchema2 = Yup.object().shape({
    JEScore: Yup.number()
      .required("Please enter JEScore")
      .min(0, "Please Enter number more than 0"),
    jobLevel: Yup.number()
      .required("Please enter jobLevel")
      .min(0, "Please Enter number more than 0"),
    jobClassMin: Yup.string().required("Please enter jobClassMin"),
    jobClassMax: Yup.string().required("Please enter jobClassMaxs"),
    company: Yup.string().required("Please enter Company"),
    division: Yup.string().required("Please enter Division"),
    department: Yup.string().required("Please enter Department"),
    section: Yup.string().required("Please enter Section"),
    fieldExperience: Yup.string().required("Please enter fieldExperience"),
    experienceNeed: Yup.string().required(" and Please enter experienceNeed"),
    location: Yup.string().required("Please enter location"),
    reportTo: Yup.string().required("Please enter Report to"),
    education: Yup.string().required("Please enter education"),
    roleResponsibility: Yup.string().required(
      "Please enter Role & Responsibility"
    ),
    otherRequirement: Yup.string().required("Please enter Other Requirement"),
  });

  const [dataFirst, setDataFirst] = useState({
    name: "",
    fit: "",
  });

  const [dataSecond, setDataSecond] = useState({
    JEScore: "",
    jobLevel: "",
    jobClassMin: "",
    jobClassMax: "",
    company: "",
    division: "",
    department: "",
    section: "",
    fieldExperience: "",
    experienceNeed: "",
    location: "",
    reportTo: "",
    education: "",
    roleResponsibility: "",
    otherRequirement: "",
  });

  const [firstForm, setFirstForm] = useState(true);
  const [secondForm, setSecondForm] = useState(false);

  const openFristForm = () => {
    setFirstForm(true);
    setSecondForm(false);
  };
  const openSecondForm = () => {
    setFirstForm(false);
    setSecondForm(true);
  };

  const onPutJobPosition = async (id, formData) => {
    await dispatch(putJobPosition(id, formData));

    props.history.push({
      pathname: `/admin/jobStructure`,
    });
  };

  const handleClick = (values) => {
    const formData = {
      jobPositionName: dataFirst.name,
      jobFit: dataFirst.fit,
      idSubJobStructure: location.state.positions.idSubJobStructure,
      JEScore: values.JEScore,
      jobLevel: values.jobLevel,
      jobClassMin: values.jobClassMin,
      jobClassMax: values.jobClassMax,
      company: values.company,
      division: values.division,
      department: values.department,
      section: values.section,
      fieldExperience: values.fieldExperience,
      experienceNeed: values.experienceNeed,
      location: values.location,
      reportTo: values.reportTo,
      education: values.education,
      roleResponsibility: values.roleResponsibility,
      otherRequirement: values.otherRequirement,
      idJobPosition: location.state.positions.idJobPosition,
    };

    console.log("formData", formData);

    //Post to db
    onPutJobPosition(formData.idJobPosition, formData);
  };

  const handleClickSave = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const showForm1 = ({
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
  }) => {
    return (
      <form
        style={{ width: "inherit", textAlign: "start" }}
        onSubmit={handleSubmit}
      >
        <Typography variant="h4" sx={{ fontWeight: 600 }} gutterBottom>
          Job Fit
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              id="name"
              label="Job position name"
              name="name"
              variant="outlined"
              required
              //style={{ width: "calc(100% - 16px)" }}
              placeholder="Input position name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              margin="dense"
              disabled
              fullWidth
            />
            <TextField
              id="fit"
              label="Input Job Fit"
              name="fit"
              variant="outlined"
              fullWidth
              multiline
              required
              rows={13}
              placeholder="Input Job Fit"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.fit}
              error={touched.fit && Boolean(errors.fit)}
              helperText={touched.fit && errors.fit}
              //style={{ width: "calc(100% - 16px)" }}
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "#007afc",
                border: 0,
                color: "white",
                height: 42,
                marginBottom: 0,
                marginTop: 16,
              }}
              onClick={() => handleClickSave()}
            >
              next
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  };

  const showForm2 = ({
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
  }) => {
    return (
      <form onSubmit={handleSubmit} style={{ textAlign: "start" }}>
        <Typography variant="h4" sx={{ fontWeight: 600 }} gutterBottom>
          Job Profile
        </Typography>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} md={6} className={classes.space}>
            <Typography variant="body2" sx={{ fontWeight: 600 }} gutterBottom>
              JE Score
            </Typography>
            <TextField
              id="outlined-number"
              name="JEScore"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              placeholder="JEScore"
              className={`${classes.itemSize} ${classes.legendNone}`}
              value={values.JEScore}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.JEScore && Boolean(errors.JEScore)}
              helperText={touched.JEScore && errors.JEScore}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.space}>
            <Typography variant="body2" sx={{ fontWeight: 600 }} gutterBottom>
              Job Level
            </Typography>
            <TextField
              id="outlined-number"
              name="jobLevel"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              placeholder="Job Level"
              className={`${classes.itemSize} ${classes.legendNone}`}
              value={values.jobLevel}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.jobLevel && Boolean(errors.jobLevel)}
              helperText={touched.jobLevel && errors.jobLevel}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.space}>
            <Typography variant="body2" sx={{ fontWeight: 600 }} gutterBottom>
              Job Class Min
            </Typography>
            <TextField
              id="outlined-demandName"
              name="jobClassMin"
              label="Job Class Min"
              variant="outlined"
              style={{ marginBottom: 8 }}
              placeholder="Section"
              value={values.jobClassMin}
              onChange={handleChange}
              onBlur={handleBlur}
              className={classes.itemSize}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.space}>
            <Typography variant="body2" sx={{ fontWeight: 600 }} gutterBottom>
              Job Class Max
            </Typography>
            <TextField
              id="outlined-demandName"
              name="jobClassMax"
              label="Job Class Max"
              variant="outlined"
              style={{ marginBottom: 8 }}
              placeholder="Job Class Max"
              value={values.jobClassMax}
              onChange={handleChange}
              onBlur={handleBlur}
              className={classes.itemSize}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.space}>
            <Typography variant="body2" sx={{ fontWeight: 600 }} gutterBottom>
              Company
            </Typography>
            <TextField
              id="outlined-demandName"
              name="company"
              label="Company"
              variant="outlined"
              style={{ marginBottom: 8 }}
              placeholder="Company"
              value={values.company}
              onChange={handleChange}
              onBlur={handleBlur}
              className={classes.itemSize}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.space}>
            <Typography variant="body2" sx={{ fontWeight: 600 }} gutterBottom>
              Division
            </Typography>
            <TextField
              id="outlined-demandName"
              name="division"
              label="Division"
              variant="outlined"
              style={{ marginBottom: 8 }}
              placeholder="Division"
              value={values.division}
              onChange={handleChange}
              onBlur={handleBlur}
              className={classes.itemSize}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.space}>
            <Typography variant="body2" sx={{ fontWeight: 600 }} gutterBottom>
              Department
            </Typography>
            <TextField
              id="outlined-demandName"
              name="department"
              label="Department"
              variant="outlined"
              style={{ marginBottom: 8 }}
              placeholder="Department"
              value={values.department}
              onChange={handleChange}
              onBlur={handleBlur}
              className={classes.itemSize}
            />
            {/*
                <FormControl
                  variant="outlined"
                  required
                  error={touched.department && Boolean(errors.department)}
                  className={classes.formControl}
                >
                  <InputLabel id="type-select-outlined-label">
                    Select Department
                  </InputLabel>

                  <Select
                    labelId="SelectDepartment"
                    name="department"
                    id="SelectDepartment"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.department}
                    label="Select Department"
                  >
                    {classLevel.map((val, index) => (
                      <MenuItem value={val} key={index}>
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched.department && errors.department}
                  </FormHelperText>
                </FormControl>
                */}
          </Grid>
          <Grid item xs={12} md={6} className={classes.space}>
            <Typography variant="body2" sx={{ fontWeight: 600 }} gutterBottom>
              Section
            </Typography>
            <TextField
              id="outlined-demandName"
              name="section"
              label="Section"
              variant="outlined"
              style={{ marginBottom: 8 }}
              placeholder="Section"
              value={values.section}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.section && Boolean(errors.section)}
              helperText={touched.section && errors.section}
              className={classes.itemSize}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" sx={{ fontWeight: 600 }} gutterBottom>
              In field Experience
            </Typography>
            <TextField
              id="outlined-demandName"
              name="fieldExperience"
              label="In field Experience"
              variant="outlined"
              placeholder="In field Experience"
              value={values.fieldExperience}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.fieldExperience && Boolean(errors.fieldExperience)}
              helperText={touched.fieldExperience && errors.fieldExperience}
              className={classes.itemSize}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" sx={{ fontWeight: 600 }} gutterBottom>
              Total Experience required
            </Typography>
            <FormControl
              className={`${classes.formControl} ${classes.legendNone}`}
              error={touched.experienceNeed && Boolean(errors.experienceNeed)}
              variant="outlined"
            >
              <OutlinedInput
                name="experienceNeed"
                id="outlined-adornment-weight"
                value={values.experienceNeed}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="total Experience required"
                endAdornment={
                  <InputAdornment position="end">Years</InputAdornment>
                }
                aria-describedby="outlined-year-experience-text"
              />
              <FormHelperText id="outlined-weight-helper-text">
                Year Exp
                {touched.experienceNeed && errors.experienceNeed}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} className={classes.space}>
            <Typography variant="body2" sx={{ fontWeight: 600 }} gutterBottom>
              Location
            </Typography>
            <TextField
              id="outlined-demandName"
              name="location"
              label="Location"
              variant="outlined"
              placeholder="Location"
              value={values.location}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.location && Boolean(errors.location)}
              helperText={touched.location && errors.location}
              className={classes.itemSize}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.space}>
            <Typography variant="body2" sx={{ fontWeight: 600 }} gutterBottom>
              Report to
            </Typography>
            <TextField
              id="outlined-demandName"
              name="reportTo"
              label=" Report to"
              variant="outlined"
              placeholder=" Report to"
              value={values.reportTo}
              onChange={handleChange}
              onBlur={handleBlur}
              className={classes.itemSize}
            />
            {/*
                <FormControl
                  variant="outlined"
                  required
                  error={touched.reportTo && Boolean(errors.reportTo)}
                  className={classes.formControl}
                >
                  <InputLabel id="type-select-outlined-label">
                    Select Report To
                  </InputLabel>

                  <Select
                    labelId="SelectReportTo"
                    name="reportTo"
                    id="SelectReportTo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.reportTo}
                    label="Select Report To"
                  >
                    {classLevel.map((val, index) => (
                      <MenuItem value={val} key={index}>
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched.reportTo && errors.reportTo}
                  </FormHelperText>
                </FormControl>
                */}
          </Grid>
          <Grid item xs={12} className={classes.space}>
            <Typography variant="body2" sx={{ fontWeight: 600 }} gutterBottom>
              Education Need
            </Typography>
            <TextField
              id="outlined-demandName"
              name="education"
              label="Education Need"
              variant="outlined"
              placeholder="Education Need"
              value={values.education}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.education && Boolean(errors.education)}
              helperText={touched.education && errors.education}
              className={classes.itemSize}
            />
          </Grid>
          <Grid item xs={12} className={classes.space}>
            <Typography variant="body2" sx={{ fontWeight: 600 }} gutterBottom>
              Key Role & Responsibility
            </Typography>
            <TextField
              id="outlined-demandName"
              name="roleResponsibility"
              label="RoleResponsibility"
              variant="outlined"
              placeholder="RoleResponsibility"
              value={values.roleResponsibility}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.roleResponsibility && Boolean(errors.roleResponsibility)
              }
              helperText={
                touched.roleResponsibility && errors.roleResponsibility
              }
              className={classes.itemSize}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{ fontWeight: 600, marginTop: 3 }}
              gutterBottom
            >
              Other Requirement
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <TextField
              id="OtherRequirement"
              label="Other Requirement"
              name="otherRequirement"
              variant="outlined"
              multiline
              required
              rows={5}
              placeholder="Other Requirement"
              value={values.otherRequirement}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.otherRequirement && Boolean(errors.otherRequirement)
              }
              helperText={touched.otherRequirement && errors.otherRequirement}
              className={classes.itemSize}
            />
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            style={{ marginTop: 20 }}
          >
            <Button
              variant="contained"
              style={{
                border: "1px solid #007afc",
                color: "#007afc",
                backgroundColor: "#fff",
                height: 42,
                marginBottom: 10,
              }}
              onClick={() => openFristForm()}
            >
              back
            </Button>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            style={{ textAlign: "right", marginTop: 20 }}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "#007afc",
                border: 0,
                color: "white",
                height: 42,
                marginBottom: 10,
              }}
              onClick={() => handleClickSave()}
            >
              submit
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  };

  useEffect(() => {
    if (location.state.positions !== null) {
      console.log("location.state", location.state.positions);

      //set form 1
      setDataFirst({
        name: location.state.positions.jobPositionName,
        fit: location.state.positions.jobFit,
      });
    }
  }, []);

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        {subJobName ? (
          <Typography variant="h3" sx={{ fontWeight: 600 }} gutterBottom>
            Form for entering Detail of {subJobName}
          </Typography>
        ) : (
          <Typography variant="h3" sx={{ fontWeight: 600 }} gutterBottom>
            Form for add new position
          </Typography>
        )}
        <div style={{ textAlign: "-webkit-center" }}>
          {firstForm && (
            <CardStyle>
              <Box className={classes.paper}>
                <Grid container direction="column">
                  <Formik
                    enableReinitialize
                    innerRef={formRef}
                    onSubmit={(values, { setSubmitting }) => {
                      setDataFirst(values);
                      openSecondForm();
                    }}
                    initialValues={dataFirst}
                    validationSchema={validationSchema1}
                  >
                    {(props) => showForm1(props)}
                  </Formik>
                </Grid>
              </Box>
            </CardStyle>
          )}

          {secondForm && (
            <Paper className={classes.paper}>
              <Formik
                enableReinitialize
                innerRef={formRef}
                onSubmit={(values, { setSubmitting }) => {
                  setDataSecond(values);
                  handleClick(values);
                }}
                initialValues={dataSecond}
                validationSchema={validationSchema2}
              >
                {(props) => showForm2(props)}
              </Formik>
            </Paper>
          )}
          {/* {finishForm &&
              <FinishForm
                dataFirst={dataFirst}
                dataSecond={dataSecond}
                jobName={props.match.params.nameJob}
                subjobName={props.match.params.subJob}
                jobPosition={props.match.params.position}
              />
            } */}
        </div>
      </Container>
    </div>
  );
};

export default FormJob;
