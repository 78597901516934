import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Alert, MenuItem, Stack } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import {
  getEmployeeTaxRemittanceReport,
  getEmployeesDetailsTaxDeduction,
  getEmployeesTaxDetails,
} from "../../../../actions/employee";
import { PfPDFfile } from "../../user/governmentReport/filePDFgeneration.js/PfPDFfile";
import AlertResponse from "../../shared/general/AlertResponse";
import { PVFxlsxFile } from "../../user/governmentReport/fileXLSXgeneration.js/PVFxlsxFile";
import { TaxDeductionXlsxFile } from "../../user/governmentReport/fileXLSXgeneration.js/taxDeductionXlsxFile";
import { getAffiliateOrganization } from "../../../../actions/company";
import { id } from "date-fns/locale";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { EmployeeTaxRemittanceXlsx } from "./xlsx-export/EmployeeTaxRemittanceXlsx";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const EmployeeTaxRemittance = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { control } = useForm({});
  const { open, handleClose } = props;
  const currentYear = new Date().getFullYear();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);
  const [employeeTax, setEmployeeTax] = useState(null);

  const thaiYears = Array.from(
    { length: 6 },
    (_, index) => currentYear + 543 - index
  );

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleCloseAlert = () => {
    setIsFetching(false);
    setOpenAlert(false);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
  };

  const fetchedData = async () => {
    try {
      setIsFetching(true);
      const response = await getEmployeeTaxRemittanceReport({
        idCompany: selectedCompany.idCompany,
        year: selectedYear - 543
      });
      if (response && response.data && response.data.length > 0) {
        setIsFetching(false);
        setEmployeeTax(response.data);
      } else {
        handleChangeAlertType(`${t("NoData")}`);
        handleOpenAlert(true);
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (selectedCompany && selectedYear) {
      fetchedData()
    } else {
      setIsFetching(false);
    }
  }, [selectedCompany, selectedYear])

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      let ownCompany = affiliateOrganizationList.find((x) => x.idCompany === userProfile.idCompany);
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany);
    }
  }, [userProfile, affiliateOrganizationList]);

  const downloadData = (EmployeeTaxRemittance) => {
    if (EmployeeTaxRemittance && EmployeeTaxRemittance.length > 0) {
      EmployeeTaxRemittanceXlsx(t,EmployeeTaxRemittance, parseInt(selectedYear - 543));
    } else {
      handleChangeAlertType(`${t("NoData")}`);
      handleOpenAlert(true);
    }
  }

  return (
    <DrawerCustom
      title={`${t("EmployeeTaxRemittanceReport")}`}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot style={{ width: 400 }}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography> {`${t("Company")}`}</Typography>
              <SelectAffiliateCompany
                options={affiliateOrganizationList}
                value={selectedCompany}
                disabled={isFetching}
                onChange={(_, value) => {
                  onChangeCompany(value);
                }}
              />
            </Stack>
            <Stack spacing={1}>
              <Typography>{t("SelectYear")}</Typography>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
                spacing={2}
              >
                <Controller
                  name="year"
                  control={control}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      variant="filled"
                      select
                      fullWidth
                      value={selectedYear}
                      onChange={handleYearChange}
                    >
                      {thaiYears.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </TextFieldTheme>
                  )}
                />
              </Stack>
            </Stack>
          </Stack>

          <Stack spacing={1} direction={"row"} justifyContent={"flex-end"}>
            <ButtonBlue
              variant="contained"
              disabled={isFetching || selectedYear === null}
              onClick={() => downloadData(employeeTax)}
            >
              {t("Download")} Xlsx
            </ButtonBlue>
          </Stack>
        </Stack>
      </StyledRoot>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </DrawerCustom>
  );
};

export default EmployeeTaxRemittance;
