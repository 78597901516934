import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Avatar, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { getUserFullName, getUserPosition } from "../../../../utils/userData";

const RequestTimeShiftCard = ({ row }) => {
  const { t, i18n } = useTranslation();
  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        borderRadius: 4,
        display: "flex",
        alignItems: "flex-start",
        bgcolor: "#F1F0F0",
        width: "100%",
        marginBottom: "10px",
      }}
    >
      <Avatar
        sx={{ width: 60, height: 60, mr: 2, flexShrink: 0 }}
        src={row.imageURL}
      />
      <Box sx={{ flexGrow: 1, minWidth: 0 }}>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("FullName")}: {getUserFullName(row)} ({getUserPosition(row)})
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("Date")}:{" "}
          {dayjs(row.workingDateText, "DD/MM/YYYY").format(
            i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
          )}
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("ShiftType")}: {row.isWorkingDay === 1 ? row.shiftGroupName : t("DayOff")}
        </Typography>
      </Box>
    </Paper>
  );
};

export default RequestTimeShiftCard;
