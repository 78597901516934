import React, { Fragment, useState, useEffect } from "react";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Link,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import ExcelJS from 'exceljs';

//Translator TH-EN
import { useTranslation } from "react-i18next";

import DrawerCustom from "../../../shared/general/Drawer";
import AddIcon from "../../../assets/add.png";
import DragDropImage from "./dragdropImage";
import FileSelected from "./file-selected";
import ShowResponse from "./show-response";

import {
  getAllEmployees,
  uploadExcelTimeAttendance,
} from "../../../../../actions/employee";
import { getAttendanceUploadFinger } from "../../../../../actions/attendance";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyleSelect = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    padding: "0px 12px",
    "& .MuiSelect-select": {
      padding: "13.5px 0px",
    },
  },
});

const StyledImportFile = styled("div")({
  maxWidth: 480,
  margin: "auto",
  marginBottom: 24,
  "& .dropzone-upload-file": {
    textAlign: "center",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    height: 200,
    display: "flex",
    justifyContent: "center",
    "& .inner-dropzone": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      "& img": {
        marginBottom: 16,
      },
    },
  },
});

const StyledLoading = styled("div")({
  height: 200,
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const StyledRadio = styled((props) => <Radio {...props} />)(
  ({ theme, checked }) => ({
    "&.Mui-checked": {
      color: "#46CBE2",
    },
  })
);

const DialogUploadTime = (props) => {
  const { open, handleClose, search, selectedCompany, isReadOnly } = props;
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [dumpSelect, setDumpSelect] = React.useState("");
  const [selectedDumpList, setSelectedDumpList] = React.useState(null);
  const [fileSelected, setFileSelected] = useState(null);
  const [companySelected, setCompanySelected] = useState(null);
  const [formFileSelected, setFormFileSelected] = useState(null);
  const [openLoading, setOpenLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState(null);
  const [waitingAPI, setWaitingAPI] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setDumpSelect("");
    setErrorResponse(null);
    setFileSelected(null);
    setCompanySelected(null);
    setFormFileSelected(null);
    setOpenLoading(null);
    setSelectedDumpList(null);
  }, [open]);

  useEffect(() => {
    setDumpSelect("");
    setErrorResponse(null);
    setFileSelected(null);
    setCompanySelected(null);
    setFormFileSelected(null);
    setOpenLoading(null);
  }, [selectedDumpList]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    onDrop: (acceptedFiles) => {
      const formData = new FormData();
      acceptedFiles.map((file) => {
        formData.append("file", file);
        formData.append("selected", companySelected);
      });
      console.log("formData: ", formData);
      setFormFileSelected(formData);
      setFileSelected({
        name: acceptedFiles[0].name,
        size: acceptedFiles[0].size,
      });
    },
    maxFiles: 1,
  });

  const handleClickUpload = async () => {
    console.log("formFileSelected: ", formFileSelected);
    const result = await dispatch(uploadExcelTimeAttendance(formFileSelected));
    if (result) {
      setErrorResponse(result);
      setOpenLoading(false);
      dispatch(getAttendanceUploadFinger(search, selectedCompany.idCompany));
    }
  };

  const handleCloseLoading = () => {
    handleClose();
  };

  const handleClickDownload = () => {
    window.open(
      `${process.env.REACT_APP_API_URL}files/Template upload time attendance.xlsx`
    );
  };

  const handleClickDownloadOT = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("OVERTIME");

    const headerRow = worksheet.addRow([
      "รหัสพนักงาน",
      "ชื่อ",
      "สกุล",
      "เลขบัตรประจำตัวประชาชน",
      "OT ของวันที่  yyyy-mm-dd (ค.ศ.)",
      "วันที่เริ่ม yyyy-mm-dd (ค.ศ.) ",
      "เวลาเริ่ม HH:mm:ss",
      "วันที่สิ้นสุด yyyy-mm-dd (ค.ศ.)",
      "เวลาสิ้นสุด HH:mm:ss",
      "OT 1 (จำนวนชั่วโมงทศนิยม 2 หลัก)",
      "OT 1.5 (จำนวนชั่วโมงทศนิยม 2 หลัก)",
      "OT 2 (จำนวนชั่วโมงทศนิยม 2 หลัก)",
      "OT 3 (จำนวนชั่วโมงทศนิยม 2 หลัก)",
      "เหตุผล (ไม่มีค่ากะ)",
      "เหตุผล (มีค่ากะ)",
      "เหตุผลเพิ่มเติม"
    ]);

    headerRow.height = 50;
    const headerStyle = {
      font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFFFF' } },
      alignment: { horizontal: "center", vertical: 'middle' },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '002060' }
      },
    };

    headerRow.eachCell((cell) => {
      cell.style = headerStyle;
      cell.border = {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } }
      };
    });

    const colWidths = [
      { key: "employeeID", width: 50 },
      { key: "firstname", width: 50 },
      { key: "lastname", width: 50 },
      { key: "personalID", width: 50, numFmt: '0' },
      { key: "DateOT", width: 50 },
      { key: "startDate", width: 50 },
      { key: "startTime", width: 50 },
      { key: "endDate", width: 50 },
      { key: "endTime", width: 50 },
      { key: "OT1", width: 50 },
      { key: "OT15", width: 50 },
      { key: "OT2", width: 50 },
      { key: "OT3", width: 50 },
      { key: "ReasonWithoutShift", width: 50 },
      { key: "ReasonWithShift", width: 50 },
      { key: "moreReason", width: 50 },
    ];

    colWidths.forEach((col, index) => {
      const column = worksheet.getColumn(index + 1);
      column.width = col.width;
      if (col.numFmt) {
        column.numFmt = col.numFmt; // Apply number format if specified
      }
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Template for Uploading Over Time.xlsx`;
      a.click();
      URL.revokeObjectURL(url);
    });
  }

  const handleClickDownloadTimeSheet = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("TIMESHEET");

    const headerRow = worksheet.addRow([
      "รหัสพนักงาน",
      "ชื่อ",
      "สกุล",
      "เลขบัตรประจำตัวประชาชน",
      "วันที่เริ่ม yyyy-mm-dd (ค.ศ.)",
      "เวลาเข้างาน HH:mm:ss",
      "วันที่สิ้นสุด yyyy-mm-dd (ค.ศ.)",
      "เวลาออกงาน HH:mm:ss",
      "วันที่เริ่ม OT yyyy-mm-dd (ค.ศ.)",
      "เวลาเริ่ม HH:mm:ss",
      "วันที่สิ้นสุด OT yyyy-mm-dd (ค.ศ.)",
      "เวลาสิ้นสุด HH:mm:ss",
      "OT 1 (จำนวนชั่วโมงทศนิยม 2 หลัก)",
      "OT 1.5 (จำนวนชั่วโมงทศนิยม 2 หลัก)",
      "OT 2 (จำนวนชั่วโมงทศนิยม 2 หลัก)",
      "OT 3 (จำนวนชั่วโมงทศนิยม 2 หลัก)",
      "เหตุผล (ไม่มีค่ากะ)",
      "เหตุผล (มีค่ากะ)",
      "เหตุผลเพิ่มเติม"
    ]);

    headerRow.height = 50;
    const headerStyle = {
      font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFFFF' } },
      alignment: { horizontal: "center", vertical: 'middle' },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '002060' }
      },
    };

    headerRow.eachCell((cell) => {
      cell.style = headerStyle;
      cell.border = {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } }
      };
    });

    const colWidths = [
      { key: "employeeID", width: 50 },
      { key: "firstname", width: 50 },
      { key: "lastname", width: 50 },
      { key: "personalID", width: 50, numFmt: '0' },
      { key: "startDate", width: 50 },
      { key: "startTime", width: 50 },
      { key: "endDate", width: 50 },
      { key: "endTime", width: 50 },
      { key: "startOTDate", width: 50 },
      { key: "startOTTime", width: 50 },
      { key: "endOTDate", width: 50 },
      { key: "entOTTime", width: 50 },
      { key: "OT1", width: 50 },
      { key: "OT15", width: 50 },
      { key: "OT2", width: 50 },
      { key: "OT3", width: 50 },
      { key: "ReasonWithoutShift", width: 50 },
      { key: "ReasonWithShift", width: 50 },
      { key: "moreReason", width: 50 },
    ];

    colWidths.forEach((col, index) => {
      const column = worksheet.getColumn(index + 1);
      column.width = col.width;
      if (col.numFmt) {
        column.numFmt = col.numFmt; // Apply number format if specified
      }
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Template for Uploading Overtime and Time Attendance.xlsx`;
      a.click();
      URL.revokeObjectURL(url);
    });
  }

  return (
    <DrawerCustom
      title={`${t("Upload")}`}
      anchor={"right"}
      open={open}
      onClose={() => {
        if (!waitingAPI) {
          handleClose();
        }
      }}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {openLoading ? (
              <StyledLoading>
                <Typography variant="h6" color="text.third" gutterBottom>
                  {t("SavingData")}...
                </Typography>
                <div>
                  <CircularProgress />
                </div>
              </StyledLoading>
            ) : (
              <Fragment>
                {errorResponse ? (
                  <ShowResponse
                    errorResponse={errorResponse}
                    handleCloseLoading={handleCloseLoading}
                  />
                ) : (
                  <Fragment>
                    <StyledImportFile>
                      <div
                        style={{
                          marginBottom: 16,
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                      >
                        <Link variant="body2" onClick={handleClickDownload}>
                          {`${t("DownloadTemplate")}`}
                        </Link>
                      </div>
                      <div
                        style={{
                          marginBottom: 16,
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                      >
                        <Link variant="body2" onClick={handleClickDownloadOT}>
                          {`${t("DownloadTemplate")} OT`}
                        </Link>
                      </div>
                      <div
                        style={{
                          marginBottom: 16,
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                      >
                        <Link variant="body2" onClick={handleClickDownloadTimeSheet}>
                          {`${t("DownloadTemplate")} ${t("TimeIn")}-${t("TimeOut")}/OT`}
                        </Link>
                      </div>
                      <div
                        {...getRootProps({
                          className: "dropzone-upload-file",
                        })}
                      >
                        <input {...getInputProps()} />
                        <div className="inner-dropzone">
                          <img alt="AddIcon" src={AddIcon} width="80" />
                          <Typography
                            style={{
                              marginTop: 8,
                              backgroundColor: "transparent",
                            }}
                            className={`${fileSelected != 0 && `placeholderLabel`
                              }`}
                            variant="body2"
                            color="text.secondary"
                          >
                            {t("DragDropOrClickSelectFile")}
                          </Typography>
                        </div>
                      </div>
                      <div>
                        <Typography
                          style={{ color: "#d3302f", margin: "8px 0" }}
                          variant="subtitle1"
                        >
                          * {t("PleaseInsertDateFormat")} ({t("AD")}) yyyy-mm-dd{" "}
                          {t("Example")}:&nbsp;&nbsp;
                          <Typography
                            style={{
                              color: "#d3302f",
                              fontSize: 18,
                              textDecoration: "underline",
                            }}
                            component="span"
                            variant="h6"
                          >
                            2024-01-31
                          </Typography>
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          style={{ color: "#d3302f", margin: "8px 0" }}
                          variant="subtitle1"
                        >
                          * {t("PleaseInsertTimeFormat")} HH:mm:ss{" "}
                          {t("Example")}:&nbsp;&nbsp;
                          <Typography
                            style={{
                              color: "#d3302f",
                              fontSize: 18,
                              textDecoration: "underline",
                            }}
                            component="span"
                            variant="h6"
                          >
                            07:30:45
                          </Typography>
                        </Typography>
                      </div>
                    </StyledImportFile>
                    {fileSelected && (
                      <FileSelected
                        fileSelected={fileSelected}
                        handleClickUpload={handleClickUpload}
                        isReadOnly={isReadOnly}
                      />
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </Grid>
        </Grid>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogUploadTime;
