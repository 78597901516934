import React, { useState, Fragment } from "react";
import dayjs from "dayjs";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Chip,
  Paper,
  Avatar,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EventRoundedIcon from "@mui/icons-material/EventRounded";

const StyleCardRoot = styled(Card)({
  minWidth: "300px",
  boxShadow: "rgb(90 114 123 / 20%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  margin: "16px",
  position: "relative",
  // height: "fit-content",
});

const HeaderContainer = styled(Box)({
  // padding: "2em",
  textAlign: "left",
  overflowWrap: "anywhere",
  "& .title": {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    "& .fontweight": {
      fontWeight: "600",
    },
  },
  "& .titleDesc": {
    color: "#9e9e9e",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
});

const StyleChip = styled(Chip)({
  border: "2px solid #db4172",
  backgroundColor: "#FFF0F8",
  color: "#db4172",
  "& .MuiChip-label": {
    fontWeight: "600",
  },
});

const StylePaper = styled(Paper)({
  backgroundImage: "linear-gradient(to right, #f2d0e2, #e6d9ef , #d7ecf1);",
  borderRadius: "20px",
});

const StyleAvatarBox = styled(Box)({
  minWidth: 240,
  "& .MuiAvatar-root": {
    width: "45px",
    height: "45px",
    marginRight: 8,
  },
  display: "flex",
  alignItems: "center",
});

const CardOJT = (props) => {
  const { isOwner, data, openDate } = props;
  return (
    <Fragment>
      <StyleCardRoot>
        <CardContent
          style={{
            // height: "120px",
            padding: "20px 16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <HeaderContainer>
            <Typography
              variant="subtitle1"
              className="title" /*marginRight={"20px"}*/
              style={{
                marginRight: "25px",
                marginBottom: "5px",
                lineHeight: 1.5,
                minHeight: "calc(1.5em * 2)",
                fontWeight: "500",
                // overflow: "hidden",
              }}
            >
              {data && data.ojtName}
            </Typography>
            <Box display={"flex"} alignItems={"center"}>
              <StyleChip
                label="On job training"
                style={{ marginRight: "10px" }}
              />
              {/* {isOwner ? (
                <Tooltip title={"แก้ไขได้"}>
                  <TaskAltRoundedIcon style={{ color: "#71dd37" }} />
                </Tooltip>
              ) : null} */}
            </Box>
            <Typography
              variant="subtitle2"
              className="titleDesc"
              style={{
                marginTop: "10px",
                lineHeight: 1.5,
                minHeight: "calc(1.5em * 2)",
              }}
            >
              {data && data.ojtNote ? data.ojtNote : ""}
            </Typography>
          </HeaderContainer>
          <StylePaper
            style={{
              display: "flex",
              padding: "15px",
              marginTop: "10px",
              alignItems: "center",
            }}
          >
            <EventRoundedIcon
              style={{
                backgroundColor: "#db4172",
                borderRadius: "50px",
                padding: "7px",
                color: "#FFF",
                width: "30px",
                height: "30px",
              }}
            />
            <Box style={{ margin: "auto" }}>
              <Typography variant="caption" style={{ display: "block" }}>
                ระยะเวลาที่สามารถเข้ารับการประเมิน
              </Typography>
              {openDate && data && data.startDate && data.endDate ? (
                <Typography variant="caption" style={{ display: "block" }}>{`${
                  data ? dayjs(data.startDate).format("DD/MM/YYYY") : " "
                } - ${
                  data ? dayjs(data.endDate).format("DD/MM/YYYY") : " "
                }`}</Typography>
              ) : (
                <Typography variant="caption" style={{ display: "block" }}>
                  ดูข้อมูลใน OJT พนักงาน
                </Typography>
              )}
            </Box>
          </StylePaper>
          <Box marginTop={"10px"}>
            <Box style={{ marginTop: "10px" }}>
              <StyleAvatarBox>
                <Avatar
                  alt="Assigner FirstName"
                  src={`${data ? data.Assigner_imageProfile : ""}` || ""}
                />
                <div>
                  <Typography variant="subtitle2" color={"#9e9e9e"}>
                    ผู้มอบหมาย
                  </Typography>
                  <Typography variant="subtitle2">{`${
                    data ? data.Assigner_firstname_TH : ""
                  } ${data ? data.Assigner_lastname_TH : ""}`}</Typography>
                </div>
              </StyleAvatarBox>
            </Box>
            <Box style={{ marginTop: "10px" }}>
              <StyleAvatarBox>
                <Avatar
                  alt="Employee FirstName"
                  src={`${data ? data.Employees_imageName : ""}` || ""}
                />
                <div>
                  <Typography variant="subtitle2" color={"#9e9e9e"}>
                    ผู้ได้รับมอบหมาย
                  </Typography>
                  <Typography variant="subtitle2">{`${
                    data ? data.Employees_firstname_TH : ""
                  } ${data ? data.Employees_lastname_TH : ""}`}</Typography>
                </div>
              </StyleAvatarBox>
            </Box>
          </Box>
          <Box style={{ marginTop: "10px", textAlign: "end" }}>
            <Typography variant="subtitle2" color={"#9e9e9e"}>
              {data && data.assignDate
                ? `Latest Assign: ${dayjs(data.assignDate).format(
                    "D MMM YYYY"
                  )}`
                : ""}
            </Typography>
          </Box>
        </CardContent>
      </StyleCardRoot>
    </Fragment>
  );
};

export default CardOJT;
