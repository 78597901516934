import React, { useEffect, useState } from "react";
import { Avatar, Box, Typography, Chip } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import DoneAll from "@mui/icons-material/DoneAll";
import { useDispatch, useSelector } from "react-redux";
import { approveJobs, getJobsAdmin } from "../../../../actions/jobRecruit";
import { Link } from "react-router-dom";
import TableCustom from "../../shared/tableCustom";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DialogConfirmReject from "./DialogConfirmReject";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const RequestJobs = (props) => {
  const { setSnackBarConfig, setNumberOfList, selectedCompany } = props;
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const { result: Jobs } = useSelector((state) => state.jobRecruitManager);

  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);

  const handleApprovalRequest = async (approve, comment) => {
    let formData = [...selectedRows];

    formData.forEach((item) => {
      item.resultExitInterview = approve;
      item.comment = comment;
    });

    setIsOpenRejectDialog(false);
    const result = await dispatch(approveJobs(formData));
    if (result) {
      setSelectedRows([]);
      dispatch(getJobsAdmin(selectedCompany.idCompany));
      if (result.status === 200) {
        setSnackBarConfig({
          open: true,
          message: `${t("Successfully")}`,
          type: "success",
        });
      } else {
        setSnackBarConfig({
          open: true,
          message: `${t("Unsuccessful")}`,
          type: "error",
        });
      }
    } else {
      setSnackBarConfig({
        open: true,
        message: `${t("Unsuccessful")}`,
        type: "error",
      });
    }
  };

  const handleConfirmReject = (comment) => {
    handleApprovalRequest(2, comment);
  };

  useEffect(() => {
    if (Jobs) {
      setNumberOfList(Jobs.length);
    }
  }, []);

  const columns = [
    {
      name: `${t("JobPosition")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>{row.positionName}</Typography>
        </Box>
      ),
    },
    {
      name: `${t("JobLevel")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>{row.levelJob}</Typography>
        </Box>
      ),
    },
    {
      name: `${t("Format")}`,
      headerTextAlign: "left",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "left" }}>
          {row.recruitType === "replace" ? (
            <>
              <Chip size="small" label={"replace"} color={"secondary"} />
              <Typography>{row.employeeNameReplace}</Typography>
            </>
          ) : (
            <Chip size="small" label={"new"} color={"primary"} />
          )}
        </Box>
      ),
    },
    // {
    //     name: "วันที่เปิดงาน",
    //     headerTextAlign: "center",
    //     minWidth: "150px",
    //     width: "150px",
    //     cellRender: (row) => (
    //         <Box sx={{ textAlign: "center" }}>
    //             <Typography>
    //                 {`${dayjs(row.createdDate).format("D MMM BBBB")}`}
    //             </Typography>
    //         </Box>
    //     ),
    // },
    // {
    //     name: "วันที่ปิดงาน",
    //     headerTextAlign: "center",
    //     minWidth: "150px",
    //     width: "150px",
    //     cellRender: (row) => (
    //         <Box sx={{ textAlign: "center" }}>
    //             <Typography>
    //                 {`${dayjs(row.closeRegisterDate).format("D MMM BBBB")}`}
    //             </Typography>
    //         </Box>
    //     ),
    // },
    {
      name: `${t("ContactName")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>{row.contactName}</Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("EmergencyPhone")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>{row.telephoneMobile}</Typography>
        </Box>
      ),
    },
    {
      name: `${t("JobDescription")}`,
      headerTextAlign: "center",
      minWidth: "50px",
      width: "50px",
      cellRender: (row) => (
        <div
          style={{ display: "flex", justifyContent: "center", align: "center" }}
        >
          <ButtonBlue
            variant="contained"
            component={Link}
            to={`/company/request/${row.idJobRecruit}`}
          >
            <ArticleIcon />
          </ButtonBlue>
        </div>
      ),
    },
  ];

  return (
    <>
      {selectedRows.length > 0 && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          <ButtonBlue
            style={{ marginRight: 8 }}
            startIcon={<DoneAll />}
            variant="contained"
            onClick={() => handleApprovalRequest(1, null)}
          >
            {`${t("Approved")} ${
              selectedRows.length > 0
                ? `${selectedRows.length} ${t("List")}`
                : ""
            }`}
          </ButtonBlue>
          <ButtonBlue
            startIcon={<DoneAll />}
            variant="outlined"
            onClick={() => setIsOpenRejectDialog(true)}
          >
            {`${t("NotApproved")} ${
              selectedRows.length > 0
                ? `${selectedRows.length} ${t("List")}`
                : ""
            }`}
          </ButtonBlue>
        </Box>
      )}
      <TableCustom
        columns={columns}
        rows={Jobs ? Jobs : []}
        canSelect
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />

      <DialogConfirmReject
        rejectCount={selectedRows.length}
        open={isOpenRejectDialog}
        onClose={() => {
          setIsOpenRejectDialog(false);
        }}
        handleSubmit={(comment) => {
          handleConfirmReject(comment);
        }}
      />
    </>
  );
};

export default RequestJobs;
