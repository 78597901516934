import {
  GROUP_LOCATIONS_FETCHING,
  GROUP_LOCATIONS_FAILED,
  GROUP_LOCATIONS_SUCCESS,
  LOCATIONS_FETCHING,
  LOCATIONS_FAILED,
  LOCATIONS_SUCCESS,
} from "./types";

import GpsLocationsService from "../services/gpsLocations.service";

export const postGroupGpsLocations = (formData) => async () => {
  try {
    console.log(formData);
    const res = await GpsLocationsService.postGroupGpsLocations(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateGroupGpsLocations = (formData) => async () => {
  try {
    console.log(formData);
    const res = await GpsLocationsService.updateGroupGpsLocations(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    //console.log(error);
    var result = {
      status: "",
    };
    if (error.response) {
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);

      result = error.response;
    }
    return result;
  }
};

export const postGpsLocations = (formData) => async () => {
  try {
    const res = await GpsLocationsService.postGpsLocations(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateGpsLocations = (formData) => async () => {
  try {
    const res = await GpsLocationsService.updateGpsLocations(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    var result = {
      status: "",
    };
    if (error.response) {
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);

      result.status = error.response.status;
    }
    return result;
  }
};

export const getGroupGpsLocations = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GROUP_LOCATIONS_FETCHING,
    });
    const res = await GpsLocationsService.getGroupGpsLocations(id);
    if (res) {
      dispatch({
        type: GROUP_LOCATIONS_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: GROUP_LOCATIONS_FAILED,
    });
    console.log(err);
    return err;
  }
};

export const getGroupGpsLocationsById = (idGroupGpsLocations) => async () => {
  try {
    const res = await GpsLocationsService.getGroupGpsLocationsById(idGroupGpsLocations);
    if (res) {
      // console.log(res)
      return res.data;
    }
  } catch (error) {
    const result = {
      status: "",
      message: "",
    };
    if (error.response) {
      result.status = error.response.status;
      result.message = error.response.data.message;
    }
    return result;
  }
};

export const getAllLocations = (query) => async (dispatch) => {
  try {
    dispatch({
      type: LOCATIONS_FETCHING,
    });
    const res = await GpsLocationsService.getAllLocations(query);
    if (res) {
      dispatch({
        type: LOCATIONS_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: LOCATIONS_FAILED,
    });
    console.log(err);
    return err;
  }
};

export const addGPSwithExcel = async (query) => {
  try {
    const res = await GpsLocationsService.addGPSwithExcel(query);
    if (res) {
      return res;
    }
  } catch (err) {
    return err;
  }
};
