import React, { useState, useEffect, Fragment } from "react";
import { styled } from "@mui/material/styles";
import ConfirmDialog from "../../shared/general/ConfirmDialog";
import Notification from "../../shared/general/Notification";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import EditChart from "./EditChart";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InfoIcon from "@mui/icons-material/Info";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import makeStyles from "@mui/styles/makeStyles";

import { ChartData } from "./OrgData";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiAvatar-root": {
      borderRadius: "10px",
    },
    "& .MuiTypography-h6": {
      fontSize: "0.9rem",
      fontWeight: 600,
    },
  },
  content_container: {
    width: "100%",
  },
  svgIcon: {
    marginRight: 8,
  },
  deleteMenu: {
    color: "#d32f2f",
  },
  dateUpdate: {
    backgroundColor: "#f5f5f5",
    paddingLeft: "5px",
    paddingRight: "5px",
    borderLeft: "3px solid #e0e0e0",
  },
}));

const StyledItemChild = styled(IconButton)({
  marginRight: 4,
  "&.Mui-disabled": {
    color: "#616161",
    cursor: "default",
    border: "1px solid #9e9e9e",
    borderRadius: "5px",
    height: "20px",
    width: "20px",
    fontSize: "0.9rem",
    fontWeight: "600",
  },
});

export default function ChartNode(props) {
  const { nodeData } = props
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();

  useEffect(() => {
    console.log(ChartData);
  }, []);

  const [editChart, setEditChart] = useState({
    isOpen: false,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    
  };

  const onDelete = (id) => {
    
  };

  

  return (
    <div className={classes.root}>
      {!nodeData.isDepartment ? (
        <Fragment>
          <div className="oc-heading">
            <Typography
              style={{
                marginRight: 16,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: 200,
              }}
            >
              {nodeData.position}
            </Typography>
            <div>
              <StyledItemChild size="small" disabled>
                {nodeData.children.length}
              </StyledItemChild>
              <IconButton
                size="small"
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleEdit}>
                  <EditIcon className={classes.svgIcon} />
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    history.push({
                      pathname: "/orgdetail",
                      nodeData: nodeData,
                    })
                  }
                >
                  <InfoIcon className={classes.svgIcon} />
                  More Detail
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    history.push({
                      pathname: "/personinorg",
                      nodeData: nodeData,
                    })
                  }
                >
                  <SupervisorAccountIcon className={classes.svgIcon} />
                  Person In Org
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure you want to delete ?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => {
                        onDelete(nodeData.orgChartId);
                      },
                    });
                  }}
                >
                  <Typography variant="body1" className={classes.deleteMenu}>
                    <DeleteIcon className={classes.svgIcon} />
                    Delete
                  </Typography>
                </MenuItem>
              </Menu>
            </div>
          </div>
          <div className="org-chart">
            <div className="avatar">
              <Avatar
                alt={nodeData.name}
                src={`${process.env.REACT_APP_API_URL}image/profile/${nodeData.img}`}
              />
            </div>
            <div>
              <Typography variant="h6" align="left">
                {nodeData.name}
              </Typography>
              <Typography variant="body2" align="left" color="text.secondary">
                {nodeData.department}
              </Typography>
            </div>
            <Typography
              variant="caption"
              align="right"
              color="text.secondary"
              style={{ fontStyle: "oblique", lineHeight: 0.5, marginTop: 8 }}
            >
              {nodeData.dateUpdate
                ? `Last modified: ${dayjs(nodeData.dateUpdate).format(
                    "DD/MM/YYYY"
                  )}`
                : "-"}
            </Typography>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="oc-heading">
            <Typography>{nodeData.name}</Typography>
            <div>
              <StyledItemChild size="small" disabled>
                {nodeData.children.length}
              </StyledItemChild>
              <IconButton
                size="small"
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleEdit}>
                  <EditIcon className={classes.svgIcon} />
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    history.push({
                      pathname: "/orgdetail",
                      nodeData: nodeData,
                    })
                  }
                >
                  <InfoIcon className={classes.svgIcon} />
                  More Detail
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    history.push({
                      pathname: "/personinorg",
                      nodeData: nodeData,
                    })
                  }
                >
                  <SupervisorAccountIcon className={classes.svgIcon} />
                  Person In Org
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure you want to delete ?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => {
                        onDelete(nodeData.orgChartId);
                      },
                    });
                  }}
                >
                  <Typography variant="body1" className={classes.deleteMenu}>
                    <DeleteIcon className={classes.svgIcon} />
                    Delete
                  </Typography>
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Fragment>
      )}
      <EditChart
        editChart={editChart}
        setEditChart={setEditChart}
        chartNode={nodeData}
      />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}