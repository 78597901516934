import SuccessorService from "../services/successor.service"
import {
  EMPLOYEES_SUCCESSOR_SUCCESS, EMPLOYEES_SUCCESSOR_FAILED,
  SUCCESSOR_SUCCESS, SUCCESSOR_FAILED,
} from "./types";


export const getSuccessorEmployees = async () => {
  try {
    const res = await SuccessorService.getSuccessorEmployees();
    if (res) {
      return res;
    }
  } catch (err) {
    console.log(err);
  }
}


export const getSuccessorEmployeesById = async (idEmployees) => {
  try {
    const res = await SuccessorService.getSuccessorEmployeesById(idEmployees);
    if (res) {
      return res;
    }
  } catch (err) {
    console.log(err);
  }
}


export const postSuccessor = async (formData) => {
  try {
    const res = await SuccessorService.postSuccessor(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};


export const getSuccessorRole = async (idEmployeePosition) => {
  try {
    const res = await SuccessorService.getSuccessorRole(idEmployeePosition);
    if (res) {
      return res;
    }
  } catch (err) {
    console.log(err);
  }
}

export const deleteSuccessor = (idEmployee, idEmployeePostion) => async () => {
  try {
    const res = await SuccessorService.deleteSuccessor(idEmployee, idEmployeePostion);
    if (res) {
      return res;
    }
  } catch (error) {
    const massage =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getSuccessorById = async (idEmployees) => {
  try {
    const res = await SuccessorService.getSuccessorById(idEmployees);
    if (res) {
      return res;
    }
  } catch (err) {
    console.log(err);
  }
}
