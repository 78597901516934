import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
} from "@mui/material";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    minWidth: 300,
    maxWidth: 550,
    borderRadius: 16,
    padding: "10px",
  },
  "& h2": {
    fontSize: "1.8rem",
  },
  "& p": {
    fontSize: "1.1rem",
  },
});

const DialogConfirmCloseJob = ({ open, onClose, job, handleSubmit }) => {
  const { t, i18n } = useTranslation();
  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogTitle>{`${t("ApplicationClosed")}`}</DialogTitle>
      <DialogContent>
        <Typography>{`${t("ApplicationClosed")} ${job.positionName} ${
          job.employeeTypeName
        } ?`}</Typography>
      </DialogContent>
      <DialogActions>
        <ButtonBlue variant="outlined" onClick={onClose}>{`${t(
          "Cancel"
        )}`}</ButtonBlue>
        <ButtonBlue variant="contained" onClick={handleSubmit}>{`${t(
          "Confirm"
        )}`}</ButtonBlue>
      </DialogActions>
    </StyledDialog>
  );
};

export default DialogConfirmCloseJob;
