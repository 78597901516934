import React, { useEffect, useState } from "react"
import { Box, Button, Grid, Typography, styled } from "@mui/material";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import dayjs from "dayjs";
import 'dayjs/locale/th';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DrawerCustom from "../../shared/general/Drawer";
import { getAllDownloadPDF } from "../../../../actions/logRecord";
import ExcelJS from 'exceljs';
import ButtonBlue from "../../shared/general/ButtonBlue";
import SelectCompany from "../shared/selectCompany";
import AlertResponse from "../../shared/general/AlertResponse";
import DatePickerCustom from "../../shared/date/datePicker";
import { getUserFirstName, getUserLastName, getUserPosition } from "../../../../utils/userData";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center",
    },
});

const StyledFooter = styled("div")({
    padding: 16,
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel": {
        marginRight: 8,
    },
});


const DocumentRequest = (props) => {

    const { open, handleClose } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const { result: LogDownloadPDF } = useSelector((state) => state.logDownloadPDF)

    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [inputSearch, setInputSearch] = useState(new Date());
    const [selectedCompany, setSelectedCompany] = useState(null);

    useEffect(() => {
        dispatch(getAllDownloadPDF())
    }, [])

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    const handleChangeCompany = (newValue) => {
        if (newValue) {
            setSelectedCompany(newValue.idCompany);
        } else {
            setSelectedCompany(null);
        }
    };

    const handleSubmit = () => {
        if (LogDownloadPDF) {
            const workbook = new ExcelJS.Workbook();
            const worksheet1 = workbook.addWorksheet(t("DocumentReport"));

            const headerRow = worksheet1.addRow([
                t("EmployeeID"),
                t("FirstName"),
                t("LastName"),
                t("Position"),
                t("Company"),
                t("Division"),
                t("Department"),
                t("Section"),
                t("RequestedBookTitle"),
                t("RequestedDate"),
            ]);

            headerRow.height = 50;

            const headerStyle1 = {
                font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
                alignment: { horizontal: "center", vertical: 'middle' },
                fill: {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '002060' }
                },
            }

            headerRow.eachCell((cell) => {
                cell.style = headerStyle1;
            });

            const colWidths = [
                { key: "employeeID", width: 40 },
                { key: "firstname", width: 40 },
                { key: "lastname", width: 40 },
                { key: "position", width: 40 },
                { key: "company", width: 40 },
                { key: "division", width: 40 },
                { key: "department", width: 40 },
                { key: "section", width: 40 },
                { key: "bookname", width: 40 },
                { key: "date", width: 40 },
            ];

            LogDownloadPDF.forEach((item) => {
                const row = [
                    item.employeeID ? item.employeeID : "-",
                    item.firstname ? getUserFirstName(item) : "-",
                    item.lastname ? getUserLastName(item) : "-",
                    item.position ? getUserPosition(item) : "-",
                    item.company ? item.company : "-",
                    item.division ? item.division : "-",
                    item.department ? item.department : "-",
                    item.section ? item.section : "-",
                    item.bookName ? item.bookName : "-",
                    item.date ? dayjs(item.date).locale('th').format('YYYY-MM-DD HH:mm:ss') : "-"
                ];

                const excelRow = worksheet1.addRow(row);
                const contentStyle = { font: { size: 18, name: 'TH SarabunPSK' } };

                colWidths.forEach((col, index) => {
                    worksheet1.getColumn(index + 1).width = col.width;
                });

                excelRow.eachCell((cell) => {
                    cell.style = contentStyle;
                    cell.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } }
                    };
                });
            })

            workbook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `${t("DocumentReport")}.xlsx`;
                a.click();
                URL.revokeObjectURL(url);
            });
        } else {
            handleChangeAlertType(t("NoData"));
            handleOpenAlert(true);
        }
    }

    return (
        <DrawerCustom
            title={t("DocumentReport")}
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot style={{ width: 400 }}>
                <Grid container spacing={2} style={{ marginBottom: 16 }}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingRight: "16px",
                        }}
                    >
                    </Grid>
                    <Grid item xs={12} sm={6} className="GridTopicInput">
                        {t("SearchDate")}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DatePickerCustom
                            maxDate={dayjs()}
                            inputFormat="MMMM YYYY"
                            value={inputSearch}
                            name="start"
                            views={['year', 'month']}
                            openTo={'month'}
                            onChange={(newValue) => {
                                setInputSearch(newValue);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className="GridTopicInput">
                        {t("SelectCompany")}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectCompany
                            hideLabel={true}
                            handleChangeCompany={handleChangeCompany}
                        />
                    </Grid>
                </Grid>
                <StyledFooter>
                    <ButtonBlue className="cancel" onClick={handleClose}>
                        {t("Cancel")}
                    </ButtonBlue>
                    <ButtonBlue
                        variant="outlined"
                        startIcon={<DownloadRoundedIcon />}
                        onClick={handleSubmit}
                        disabled={selectedCompany === null}
                    >
                        {t("Download")}
                    </ButtonBlue>
                </StyledFooter>
            </StyledRoot>
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </DrawerCustom>
    )
}

export default DocumentRequest