import React, { Fragment } from "react";
import DataGrid, {
  Button,
  Column,
  Export,
  FilterRow,
  HeaderFilter,
  Paging,
  Scrolling,
  SearchPanel,
  Selection,
  Sorting,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import utils from "../../../../../utils";
import loading from "../../../assets/social-media.gif";

const TableIndividual = (props) => {
  const { t, i18n } = useTranslation();

  const {
    result: ReportSummaryOvertimeShiftIndividual,
    isFetching: isReportSummaryOvertimeShiftIndividualFetching,
  } = useSelector((state) => state.reportSummaryOvertimeShiftIndividual);

  let gridColumnsOverTime = [
    {
      dataField: "otOneHours",
      caption: `OT 1 (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otOneAmount",
      caption: `OT 1 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otOneFiveHours",
      caption: `OT 1.5 (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otOneFiveAmount",
      caption: `OT 1.5 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otTwoHours",
      caption: `OT 2 (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otTwoAmount",
      caption: `OT 2 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otThreeHours",
      caption: `OT 3 (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otThreeAmount",
      caption: `OT 3 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otHoursTotal",
      caption: `OT ${t("Total")} (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "percentOT",
      caption: `${t("OTPercentage")}`,
      format: "0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otAmountTotal",
      caption: `OT ${t("Total")} (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
  ];

  let gridColumnsOverTimeNotAccess = [
    {
      dataField: "otOneHours",
      caption: `OT 1 (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otOneFiveHours",
      caption: `OT 1.5 (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otTwoHours",
      caption: `OT 2 (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otThreeHours",
      caption: `OT 3 (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otHoursTotal",
      caption: `OT ${t("Total")} (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "percentOT",
      caption: `${t("OTPercentage")}`,
      format: "0.00",
      cssClass: "column-ot",
      width: 120,
    },
  ];

  let gridColumnsShift = [
    {
      dataField: "morningShiftFee",
      caption: `${t("ShiftPayMorning")}`,
      format: "###,###,##0.00",
      cssClass: "column-shift",
    },
    {
      dataField: "afternoonShiftFee",
      caption: `${t("ShiftPayAfternoon")}`,
      format: "###,###,##0.00",
      cssClass: "column-shift",
    },
    {
      dataField: "nightShiftFee",
      caption: `${t("ShiftPayNight")}`,
      format: "###,###,##0.00",
      cssClass: "column-shift",
    },
    {
      dataField: "shiftFeeTotal",
      caption: `${t("ShiftPayTotal")}`,
      format: "###,###,##0.00",
      cssClass: "column-shift",
    },
  ];

  const displayDate = (info) => {
    return dayjs(info.value).format(
      i18n.resolvedLanguage === "th" ? "DD MMM BBBB (ddd)" : "DD MMM YYYY (ddd)"
    );
  };

  const displayShiftType = (info) => {
    if (info && info.value) {
      if (info.value.isWorkingDay === 0) {
        return info.value.shiftTypeName;
      } else {
        return `${dayjs(info.value.timeIn, "HH:mm:ss").format(
          "HH:mm"
        )} - ${dayjs(info.value.timeOut, "HH:mm:ss").format("HH:mm")}`;
      }
    } else {
      return "ไม่พบเวลาทำงาน";
    }
  };

  const customizeOTHour = (data) => {
    return data.value
      ? `${utils.roundToTwo(data.value)} ${t("Unit.ShortHours")}`
      : "";
  };

  const customizeOTAmount = (data) => {
    return data.value ? `${utils.roundToTwo(data.value)} บาท` : "";
  };

  return (
    <Fragment>
      {!isReportSummaryOvertimeShiftIndividualFetching ? (
        <DataGrid
          dataSource={
            ReportSummaryOvertimeShiftIndividual.schedule
              ? ReportSummaryOvertimeShiftIndividual.schedule
              : []
          }
          showBorders={true}
          showColumnLines={true}
          rowAlternationEnabled={true}
          columnAutoWidth={true}
          allowColumnResizing={true}
          columnResizingMode="widget"
        >
          {/* <Column
            caption={`${t("EmployeeID")}`}
            dataField="employeeID"
            dataType="string"
            width={150}
            cssClass="column-info"
          />

          <Column
            caption={`${t("FullName")}`}
            dataType="string"
            cssClass="column-info"
            dataField={i18n.resolvedLanguage === "en" ? "fullname_EN" : "fullname_TH"}
          >
            <HeaderFilter allowSearch={true} />
          </Column>

          <Column
            dataField="sectionName"
            caption={`${t("Section")}`}
            dataType="string"
            cssClass="column-info"
          />

          <Column
            dataField={i18n.resolvedLanguage === "en" ? "positionName_EN" : "positionName"}
            caption={`${t("Position")}`}
            dataType="string"
            cssClass="column-info"
          />

          <Column
            dataField="workingType"
            caption={`${t("WorkingType")}`}
            dataType="string"
            width={150}
            cssClass="column-info"
          />

          <Column
            dataField="paymentTypeName"
            caption={`${t("PaymentTypeName")}`}
            dataType="string"
            width={150}
            cssClass="column-info"
          />

          <Column
            dataField="paymentRoundName"
            caption={`${t("PaymentRound")}`}
            dataType="string"
            width={150}
            cssClass="column-info"
          /> */}

          <Column
            dataField="date"
            caption={`${t("Date")}`}
            customizeText={displayDate}
            dataType="string"
            width={150}
            cssClass="column-info"
          />

          <Column
            dataField="pattern"
            caption={`${t("AppMenu.TimeAttendance")}`}
            customizeText={displayShiftType}
            dataType="string"
            width={150}
            cssClass="column-info"
          />

          {ReportSummaryOvertimeShiftIndividual &&
          ReportSummaryOvertimeShiftIndividual.checkedAccessPayroll
            ? gridColumnsOverTime.map((item, index) => (
                <Column
                  key={`overtime_${index}`}
                  dataField={item.dataField}
                  caption={item.caption}
                  format={item.format}
                  cssClass="column-total-addition"
                />
              ))
            : gridColumnsOverTimeNotAccess.map((item, index) => (
                <Column
                  key={`overtime_${index}`}
                  dataField={item.dataField}
                  caption={item.caption}
                  format={item.format}
                  cssClass="column-total-addition"
                />
              ))}

          {ReportSummaryOvertimeShiftIndividual &&
            ReportSummaryOvertimeShiftIndividual.checkedAccessPayroll &&
            gridColumnsShift.map((item, key) => (
              <Column
                key={`shift_${key}`}
                dataField={item.dataField}
                caption={item.caption}
                format={item.format}
                cssClass={item.cssClass}
              />
            ))}

          <Export enabled={true} allowExportSelectedData={true} />
          <Selection mode="single" />
          <Sorting mode="multiple" />
          <Scrolling columnRenderingMode="virtual" />
          <Paging enabled={false} defaultPageSize={20} />
          <FilterRow visible={false} />
          <HeaderFilter visible={true} />
          <SearchPanel
            visible={true}
            width={240}
            placeholder={`${t("Search")}`}
          />
          <Summary>
            <TotalItem column="date" displayFormat={t("Total")} />
            <TotalItem
              column="otOneHours"
              summaryType="sum"
              customizeText={customizeOTHour}
            />
            <TotalItem
              column="otOneAmount"
              summaryType="sum"
              customizeText={customizeOTAmount}
            />
            <TotalItem
              column="otOneFiveHours"
              summaryType="sum"
              customizeText={customizeOTHour}
            />
            <TotalItem
              column="otOneFiveAmount"
              summaryType="sum"
              customizeText={customizeOTAmount}
            />
            <TotalItem
              column="otTwoHours"
              summaryType="sum"
              customizeText={customizeOTHour}
            />
            <TotalItem
              column="otTwoAmount"
              summaryType="sum"
              customizeText={customizeOTAmount}
            />
            <TotalItem
              column="otThreeHours"
              summaryType="sum"
              customizeText={customizeOTHour}
            />
            <TotalItem
              column="otThreeAmount"
              summaryType="sum"
              customizeText={customizeOTAmount}
            />
            <TotalItem
              column="otAmountTotal"
              summaryType="sum"
              customizeText={customizeOTAmount}
            />
            <TotalItem
              column="morningShiftFee"
              summaryType="sum"
              customizeText={customizeOTAmount}
            />
            <TotalItem
              column="afternoonShiftFee"
              summaryType="sum"
              customizeText={customizeOTAmount}
            />
            <TotalItem
              column="nightShiftFee"
              summaryType="sum"
              customizeText={customizeOTAmount}
            />

            <TotalItem
              column="shiftFeeTotal"
              summaryType="sum"
              customizeText={customizeOTAmount}
            />
          </Summary>
        </DataGrid>
      ) : (
        // <Typography textAlign="center" color="text.secondary" fontSize="24px">
        //   {t(`LoadingData`)}...
        // </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="loading"
            src={loading}
            style={{ width: 100, height: 100 }}
          />
        </Box>
      )}
    </Fragment>
  );
};

export default TableIndividual;
