import { httpClient } from "./httpClient";

const getCostCenterList = (query) => {
  return httpClient.get(`/costCenterList`, { params: query });
};

const addEmployeeCostCenter = (formData) => {
  return httpClient.post(`/employeeCostCenter`, formData);
};

const getEmployeeCostCenter = (query) => {
  return httpClient.get(`/employeeCostCenter`, { params: query });
};

const updateEmployeeCostCenter = (idEmployeeCostCenter, formData) => {
  return httpClient.put(`/employeeCostCenter/${idEmployeeCostCenter}`, formData);
};

const deleteEmployeeCostCenter = (idEmployeeCostCenter) => {
  return httpClient.delete(`/employeeCostCenter/${idEmployeeCostCenter}`);
};

const addCostCenterByIdEmployees = (query) => {
  return httpClient.post(`/add-cost-center`, query);
};

const getCostCenterByEmployees = (query) => {
  return httpClient.get(`/get-cost-center-employee`, { params: query });
};

export default {
  getCostCenterList,
  addEmployeeCostCenter,
	getEmployeeCostCenter,
	updateEmployeeCostCenter,
	deleteEmployeeCostCenter,
  addCostCenterByIdEmployees,
  getCostCenterByEmployees,
};