import React, { useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Stack,
  DialogContent,
  Alert,
} from "@mui/material";
import * as Yup from "yup";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import {
  addAnnouncement,
  getAnnouncementByIdCompany,
  updateAnnouncement,
} from "../../../../actions/announcement";
import UploadFile from "./uploadFile";
import ButtonBlue from "../../shared/general/ButtonBlue";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../shared/date/datePicker";
import { getUserCompany } from "../../../../utils/userData";
import { openNotificationAlert } from "../../../../actions/notificationAlert";

export default function CreateAnnouncement({
  open,
  handleClose,
  editData,
  selectedCompany,
}) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const dataAnnouncement = {
    title: "",
    detail: "",
    file: null,
    startDate: dayjs(),
    endDate: dayjs().add(1, "day"),
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(500).required(t("ThisFieldIsRequired")),
    detail: Yup.string().max(1000),
    startDate: Yup.date().min(
      dayjs().startOf("day"),
      t("InvalidStartandEndDates")
    ),
    endDate: Yup.date().when("startDate", (startDate) => {
      return Yup.date().min(
        dayjs(startDate).add(1, "day"),
        t("InvalidStartandEndDates")
      );
    }),
    file: Yup.mixed().nullable(),
  });

  const handleAddAnnouncement = async (values) => {
    if (!editData) {
      values.idCompany = selectedCompany.idCompany;
    }

    const formData = new FormData();
    for (const key in values) {
      formData.append(key, values[key]);
    }

    let res = null;
    if (editData) {
      res = await dispatch(updateAnnouncement(formData));
    } else {
      res = await dispatch(addAnnouncement(formData));
    }

    if (res && res.status === 201) {
      dispatch(
        getAnnouncementByIdCompany({ idCompany: selectedCompany.idCompany })
      );
      dispatch(openNotificationAlert({ message: t("Success"), type: "success" }));
      handleClose();
    } else {
      dispatch(openNotificationAlert({ message: t("AnErrorOccurred") , type: "error" }));
    }
  };

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: dataAnnouncement,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (editData) {
      reset({ ...editData, idAnnouncement: editData.idAnnouncement });
    }
  }, []);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {editData ? `${t("EditAnnouncement")}` : `${t("CreateAnnouncement")}`}
      </DialogTitle>
      <DialogContent>
        <Alert severity="info">
          {`${t("SelectCompany")}: ${getUserCompany({
            companyName: selectedCompany.companyName,
            companyName_EN: selectedCompany.companyName_EN,
          })}`}
        </Alert>
        <form onSubmit={handleSubmit(handleAddAnnouncement)}>
          <Grid container spacing={2} marginTop={0}>
            <Grid item xs={12}>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    fullWidth
                    label={`${t("CompanyPolicy")}`}
                    error={Boolean(errors.title)}
                    helperText={errors.title && errors.title.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="detail"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    label={`${t("Descriptions")}`}
                    error={Boolean(errors.detail)}
                    helperText={errors.detail && errors.detail.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="file"
                control={control}
                render={({ field }) => (
                  <UploadFile
                    {...field}
                    onChange={field.onChange}
                    acceptFileType={"image/jpeg, image/png, application/pdf"}
                    acceptFileTypeLabel={t("JpgPngPdf")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} spacing={2}>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field }) => (
                    <DatePickerCustom
                      {...field}
                      views={["year", "month", "day"]}
                      label={`${t("StartDate")}`}
                      minDate={dayjs()}
                      maxDate={dayjs().add(1, "year").endOf("year")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errors.startDate}
                          helperText={
                            errors.startDate && errors.startDate.message
                          }
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field }) => (
                    <DatePickerCustom
                      {...field}
                      views={["year", "month", "day"]}
                      label={`${t("EndDate")}`}
                      minDate={dayjs(watch("startDate")).add(1, "day")}
                      maxDate={dayjs().add(1, "year").endOf("year")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errors.endDate}
                          helperText={errors.endDate && errors.endDate.message}
                        />
                      )}
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <DialogActions>
            <Button type="button" color="error" onClick={handleClose}>
              {`${t("Cancel")}`}
            </Button>
            <ButtonBlue type="submit" variant="contained">
              {`${t("Confirm")}`}
            </ButtonBlue>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
