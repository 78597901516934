// src/components/Header.js
import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const AI = ({ title }) => {
    return (
        <Card
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h5"  marginBottom={3} component="div">
                    {title}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default AI;
