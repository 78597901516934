import React, { useState, useEffect } from "react";
import JobPosition from "./JobPosition";
import { useDispatch } from "react-redux";

import {
  Grid,
  Paper,
  Button,
  Typography,
  Box,
  IconButton,
  Menu,
  Fade,
  MenuItem,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";

import { getAllJob } from "../../../../../actions/job";
import AddSubJob from "./add/addSubJob";
import EditSubJob from "./add/editSubJob";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ConfirmDialog from "../../../shared/general/ConfirmDialog";

import { deleteSubJobStructure } from "../../../../../actions/subJobStructures";

const useStyles = makeStyles(() => ({
  bigAvatar: {
    width: "200px",
    height: "250px",
  },
  button: {
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#2c387e",
  },
  icon: {
    fontSize: 28,
    color: "white",
    marginRight: 5,
    marginLeft: 0,
  },
  text: {
    textAlign: "center",
    paddingTop: 5,
    color: "#000",
    fontSize: "1.5rem",
    fontWeight: 600,
    wordWrap: "break-word",
  },
  textContainer: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const SubJob = ({ jobId, jobStructureList, fetchData, admin }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [subJob, setSubJob] = useState(false);

  useEffect(() => {
    dispatch(getAllJob());
  }, []);

  const [subJobId, setSubJobId] = useState(null);
  const [jobPosition, setJobPosition] = useState(false);

  const openJobPosition = (val) => {
    if (subJobId === val) {
      setSubJobId(null);
      setJobPosition(false);
      setSubJob(false);
      //   dispatch({ type: "set", subJobName: null });
    } else {
      setSubJobId(val);
      setJobPosition(true);
      setSubJob(true);
      // dispatch({ type: "set", subJobName: val.subJobName });
    }
  };

  const [openAdd, setOpenAdd] = useState(false);

  const handleClose = () => {
    setOpenAdd(false);
  };

  const ActionButton = (subJob) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openEdit, setOpenEdit] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({
      isOpen: false,
      title: "",
      subTitle: "",
    });

    const handleClick = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleActionClose = () => {
      setAnchorEl(null);
    };

    const handleClose = () => {
      setOpenEdit(false);
    };

    const handleOnEdit = (data) => {
      setOpenEdit(true);
      handleActionClose();
    };

    const handleOnDelete = (data) => {
      handleActionClose();
      setConfirmDialog({
        isOpen: true,
        title: "Are you sure you want to delete ?",
        subTitle: "You can't undo this operation",
        onConfirm: () => {
          onDelete(data.idSubJobStructure, data);
        },
      });
    };

    const onDelete = (id, data) => {
      onDeleteJobStructure(id, data);
    };

    const onDeleteJobStructure = async (id) => {
      await dispatch(deleteSubJobStructure(id));
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
      fetchData();
    };

    return (
      <>
        <IconButton
          id="fade-button"
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{
            backgroundColor: "#fff",
            "&:hover": { backgroundColor: "#eeeeee" },
          }}
          size="small"
        >
          <MoreVertIcon sx={{ color: "#212121" }} />
        </IconButton>
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleActionClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={() => handleOnEdit(subJob.subJob)}>Edit</MenuItem>
          <MenuItem
            sx={{ color: "#e53935" }}
            onClick={() => handleOnDelete(subJob.subJob)}
          >
            Delete
          </MenuItem>
        </Menu>
        {openEdit && (
          <EditSubJob
            open={openEdit}
            jobId={subJob.subJob.idSubJobStructure}
            subJobData={subJob.subJob}
            fetchData={fetchData}
            close={handleClose}
          />
        )}
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      </>
    );
  };

  return (
    <div>
      {jobStructureList.length > 0 &&
        jobStructureList
          .filter((value) => value.idJobStructure === jobId)
          .map((value, index) => (
            <div key={index}>
              <hr style={{ marginTop: "80px" }} />
              <Grid container direction="row" alignItems="center">
                <Grid item xl={9} lg={9} md={9} xs={12} sm={12}>
                  <h2 style={{ fontWeight: 600 }}>
                    All Sub Job in {value.jobStructureName}
                  </h2>
                </Grid>
                {admin && !subJob ? (
                  <Grid item xl={3} lg={3} md={3} xs={12} sm={12}>
                    <Grid container justifyContent="flex-end">
                      <Button
                        style={{
                          height: 50,
                          width: 220,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#007afc",
                        }}
                        onClick={(e) => setOpenAdd(true)}
                      >
                        <AddIcon className={classes.icon} />
                        <Typography
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            fontSize: 16,
                            marginBottom: 0,
                          }}
                        >
                          Add new Sub Job
                        </Typography>
                      </Button>
                      {openAdd !== false && (
                        <AddSubJob
                          open={openAdd}
                          jobId={jobId}
                          fetchData={fetchData}
                          close={handleClose}
                        />
                      )}
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
              {subJobId !== null ? (
                value.subJobList
                  .filter((val) => val.idSubJobStructure === subJobId)
                  .map((val, index) => (
                    <Grid
                      key={index}
                      container
                      direction="row"
                      style={{ paddingRight: 50, paddingLeft: 50 }}
                      justifyContent="center"
                    >
                      <Grid item xl={4} sm={6} md={4} lg={4} xs={12}>
                        <Paper
                          style={{
                            borderRadius: 16,
                            margin: 8,
                            padding: "15px",
                            backgroundColor: value.backgroundColor,
                            cursor: "pointer",
                            border: "3px solid #fff",
                            height: "90%",
                            boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
                          }}
                        >
                          <Box sx={{ marginBottom: 2 }}>
                            <Grid container justifyContent="end" spacing={2}>
                              <Grid item>
                                <ActionButton subJob={val} />
                              </Grid>
                            </Grid>
                          </Box>
                          <Box
                            sx={{ height: "100%" }}
                            onClick={() =>
                              openJobPosition(val.idSubJobStructure)
                            }
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                alt="pic avatar"
                                src={
                                  val.imageUrl !== null
                                    ? val.imageUrl
                                    : require("./assets/images/subJob.png")
                                }
                                style={{
                                  width: "80%",
                                  height: "auto",
                                  marginBottom: "40px",
                                  borderRadius: "10px",
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <Box className={classes.textContainer}>
                                <Typography className={classes.text}>
                                  {val.subJobStructureName}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Paper>
                      </Grid>
                    </Grid>
                  ))
              ) : (
                <>
                  <Grid container>
                    {(value.subJobList.length > 0 &&
                      value.subJobList.map((val, index) => (
                        <Grid item xs={4} key={index}>
                          <Paper
                            style={{
                              borderRadius: 16,
                              margin: 8,
                              padding: "15px",
                              backgroundColor: value.backgroundColor,
                              cursor: "pointer",
                              border: "3px solid #fff",
                              height: "90%",
                              boxShadow:
                                "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
                            }}
                          >
                            <Box sx={{ marginBottom: 2 }}>
                              <Grid container justifyContent="end" spacing={2}>
                                <Grid item>
                                  <ActionButton subJob={val} />
                                </Grid>
                              </Grid>
                            </Box>
                            <Box
                              sx={{ height: "100%" }}
                              onClick={() =>
                                openJobPosition(val.idSubJobStructure)
                              }
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  alt="pic avatar"
                                  src={
                                    val.imageUrl !== null
                                      ? val.imageUrl
                                      : require("./assets/images/subJob.png")
                                  }
                                  style={{
                                    width: "80%",
                                    height: "auto",
                                    marginBottom: "40px",
                                    borderRadius: "10px",
                                  }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginBottom: "20px",
                                }}
                              >
                                <Box className={classes.textContainer}>
                                  <Typography className={classes.text}>
                                    {val.subJobStructureName}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Paper>
                        </Grid>
                      ))) || (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "50px",
                          }}
                        >
                          No Data
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
            </div>
          ))}
      {jobPosition && (
        <JobPosition
          jobId={jobId}
          subJobId={subJobId}
          jobStructureList={jobStructureList}
          admin={admin}
          fetchData={fetchData}
        />
      )}
    </div>
  );
};

export default SubJob;
