import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
  Box,
  Avatar,
  Collapse,
  IconButton,
  Autocomplete,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../../actions/employee";
import {
  getOnBoardingEmployee,
  getOnBoardingEmployeeByID,
} from "../../../../../actions/onBoarding";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import {
  getUserDepartment,
  getUserFullName,
  getUserPosition,
  getUserDivision,
  getUserSection,
} from "../../../../../utils/userData";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import dayjs from "dayjs";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { exportAllToExcel } from "./ExportExcel";

const StyledWrapBranch = styled("div")({
  marginBottom: 36,
  "& .wrap-branch-top": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .MuiTypography-body2": {
      margin: 0,
    },
  },
  "& .branch-scroll": {
    height: 280,
    overflow: "auto",
  },
  "& .action": {
    textAlign: "right",
    "& .fal": {
      fontSize: 18,
    },
  },
  "& .address": {
    fontWeight: 600,
    fontSize: 18,
  },
  "& .contact": {
    "& .fal": {
      marginRight: 8,
    },
  },
});

const StatusProgress = ({ status }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= status ? status : prevProgress + 1
      );
    }, 10);
    return () => clearInterval(timer);
  }, [status]);

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        value={progress}
        size={50}
        thickness={4}
        sx={{ color: "#46cbe2" }}
      />
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(progress)}%`}</Typography>
      </Box>
    </Box>
  );
};

const StatusOnBoarding = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: onBoardingEmployee = [] } = useSelector(
    (state) => state.onBoardingEmployee
  );
  const { result: onBoardingEmployeeDetails } = useSelector(
    (state) => state.onBoardingEmployeeByID
  );
  const [expandedRow, setExpandedRow] = useState(null);
  const [filteredData, setFilteredData] = useState(onBoardingEmployee);
  const [filters, setFilters] = useState({
    fullName: "",
    division: "",
    department: "",
    section: "",
    position: "",
    company: "",
  });
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  useEffect(() => {
    if (
      onBoardingEmployee &&
      onBoardingEmployee.length > 0 &&
      !filters.company
    ) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        company: onBoardingEmployee[0].companyName,
      }));
    }
  }, [onBoardingEmployee]);

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getOnBoardingEmployee());
  }, [dispatch]);

  useEffect(() => {
    const filtered = (onBoardingEmployee || []).filter((row) => {
      return (
        (!filters.fullName ||
          getUserFullName(row).includes(filters.fullName)) &&
        (!filters.division || getUserDivision(row) === filters.division) &&
        (!filters.department ||
          getUserDepartment(row) === filters.department) &&
        (!filters.section || getUserSection(row) === filters.section) &&
        (!filters.position || getUserPosition(row) === filters.position) &&
        (!filters.company || row.companyName === filters.company)
      );
    });
    setFilteredData(filtered);
  }, [filters, onBoardingEmployee]);

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const clearFilters = () => {
    setFilters({
      fullName: "",
      division: "",
      department: "",
      section: "",
      position: "",
      company: filters.company,
    });
  };

  const handleExpandClick = (id) => {
    if (expandedRow === id) {
      setExpandedRow(null);
    } else {
      setExpandedRow(id);
      dispatch(getOnBoardingEmployeeByID(id));
    }
  };

  const handleDownload = () => {
    exportAllToExcel(filteredData);
  };

  return (
    <div>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Autocomplete
              options={[
                ...new Set(
                  (onBoardingEmployee || [])
                    .map((row) => row.companyName)
                    .filter(Boolean)
                ),
              ]}
              value={filters.company}
              onChange={(event, newValue) =>
                handleFilterChange("company", newValue)
              }
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Company")}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t("FullName")}
              variant="outlined"
              fullWidth
              value={filters.fullName}
              onChange={(e) => handleFilterChange("fullName", e.target.value)}
            />
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="flex-start">
            <Button
              variant="text"
              color="primary"
              onClick={() => setShowMoreFilters(!showMoreFilters)}
            >
              {showMoreFilters ? t("HideSearch") : t("SearchMore")}
            </Button>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="flex-end">
            <ButtonBlue
              //style={{ padding: "10px 30px" }}
              variant="outlined"
              onClick={handleDownload}
            >
              {t("Download")}
            </ButtonBlue>
          </Grid>

          {showMoreFilters && (
            <>
              <Grid item xs={3}>
                <Autocomplete
                  options={[
                    ...new Set(
                      (onBoardingEmployee || [])
                        .map((row) => getUserDivision(row))
                        .filter(Boolean)
                    ),
                  ]}
                  value={filters.division}
                  onChange={(event, newValue) =>
                    handleFilterChange("division", newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Division")}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={[
                    ...new Set(
                      (onBoardingEmployee || [])
                        .map((row) => getUserDepartment(row))
                        .filter(Boolean)
                    ),
                  ]}
                  value={filters.department}
                  onChange={(event, newValue) =>
                    handleFilterChange("department", newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Department")}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={[
                    ...new Set(
                      (onBoardingEmployee || [])
                        .map((row) => getUserSection(row))
                        .filter(Boolean)
                    ),
                  ]}
                  value={filters.section}
                  onChange={(event, newValue) =>
                    handleFilterChange("section", newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Section")}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={[
                    ...new Set(
                      (onBoardingEmployee || [])
                        .map((row) => getUserPosition(row))
                        .filter(Boolean)
                    ),
                  ]}
                  value={filters.position}
                  onChange={(event, newValue) =>
                    handleFilterChange("position", newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Position")}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="flex-start">
                <Button variant="text" color="primary" onClick={clearFilters}>
                  {t("ClearFilters")}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <StyledWrapBranch>
        {filteredData && filteredData.length > 0 ? (
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 600 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center"> </TableCell>
                    <TableCell align="center">{t("FullName")}</TableCell>
                    <TableCell align="center">{t("Division")}</TableCell>
                    <TableCell align="center">{t("Department")}</TableCell>
                    <TableCell align="center">{t("Section")}</TableCell>
                    <TableCell align="center">{t("Position")}</TableCell>
                    <TableCell align="center">{t("Status")}</TableCell>
                    <TableCell align="center">{t("Descriptions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((row, index) => (
                    <React.Fragment key={index}>
                      <TableRow key={index}>
                        <TableCell align="center">{index + 1} </TableCell>
                        <TableCell align="center">
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            <Avatar
                              src={row.imageProfile}
                              alt={getUserFullName(row)}
                              sx={{ width: 40, height: 40, marginRight: 3 }}
                            />
                            <Typography variant="body1">
                              {getUserFullName(row)}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {getUserDivision(row) || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {getUserDepartment(row) || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {getUserSection(row) || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {getUserPosition(row) || "-"}
                        </TableCell>
                        <TableCell align="center">
                          <StatusProgress status={row.status} />
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={() => handleExpandClick(row.idEmployees)}
                          >
                            {expandedRow === row.idEmployees ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          backgroundColor: "#f9f9f9",
                        }}
                      >
                        <TableCell
                          colSpan={8}
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                        >
                          <Collapse
                            in={expandedRow === row.idEmployees}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Table
                              size="small"
                              aria-label="details"
                              sx={{ width: "100%", backgroundColor: "#f9f9f9" }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">
                                    {t("ItemName")}
                                  </TableCell>
                                  <TableCell align="center">
                                    {t("Inspector")}
                                  </TableCell>
                                  <TableCell align="center">
                                    {t("Department")}
                                  </TableCell>
                                  <TableCell align="center">
                                    {t("ProceedInternally")} ({t("Unit.Days")})
                                  </TableCell>
                                  <TableCell align="center">
                                    {t("Status")}
                                  </TableCell>
                                  <TableCell align="center">
                                    {t("Date")}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {onBoardingEmployeeDetails &&
                                  onBoardingEmployeeDetails.map(
                                    (detail, detailIndex) => (
                                      <TableRow key={detailIndex}>
                                        <TableCell
                                          align="center"
                                          justifyContent="flex-start"
                                        >
                                          {detail.OnBoardingListName}
                                        </TableCell>
                                        <TableCell align="center">
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="flex-start"
                                          >
                                            <Avatar
                                              src={detail.reviewer_imageProfile}
                                              alt={`${detail.reviewer_firstname_TH} ${detail.reviewer_lastname_TH}`}
                                              sx={{
                                                width: 30,
                                                height: 30,
                                                marginRight: 1,
                                              }}
                                            />
                                            <Typography variant="body1">
                                              {`${detail.reviewer_firstname_TH} ${detail.reviewer_lastname_TH}`}
                                            </Typography>
                                          </Box>
                                        </TableCell>
                                        <TableCell align="center">
                                          {detail.departmentName || "-"}
                                        </TableCell>
                                        <TableCell align="center">
                                          {detail.day}
                                        </TableCell>
                                        <TableCell align="center">
                                          {detail.isApprove ? (
                                            <CheckCircleOutlineIcon color="success" />
                                          ) : (
                                            <CancelOutlinedIcon color="error" />
                                          )}
                                        </TableCell>
                                        <TableCell align="center">
                                          {dayjs(detail.updateDate).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                              </TableBody>
                            </Table>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          `${t("NoItems")}`
        )}
      </StyledWrapBranch>
    </div>
  );
};

export default StatusOnBoarding;
