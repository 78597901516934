import React, { useEffect } from "react";
import {
  Button,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  addAdditionOvertime,
  getAdditionOvertime,
  updateAdditionOvertime,
} from "../../../../../../actions/additionSpecial";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";
import InputNumber from "./inputNumber";

const AdditionOvertimeForm = ({ handleClose, selectedCompany, selectAddition }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const validateSchema = yup.object({
    name: yup.string().max(500).required(t("ThisFieldIsRequired")),
    minutes: yup
      .number()
      .transform((val, orig) => (orig === "" ? undefined : val))
      .min(1, `${t("Minimum")} 1`)
      .max(1000, `${t("Maximum")} 1000`)
      .required(t("ThisFieldIsRequired")),
    value: yup.number().integer().min(1).required(t("ThisFieldIsRequired")),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: { name: "", minutes: "", value: "" },
    resolver: yupResolver(validateSchema),
  });

  const onSubmit = async (data) => {
    data.idCompany = selectedCompany.idCompany;

    let res = null;
    if (selectAddition && selectAddition.idAdditionOvertime) {
      res = await dispatch(updateAdditionOvertime(data));
    } else {
      res = await dispatch(addAdditionOvertime(data));
    }
    console.log(res)
    if (res.status === 201) {
      dispatch(openNotificationAlert({ message: "success", type: "success" }));
      dispatch(getAdditionOvertime(selectedCompany.idCompany));
    } else {
      dispatch(openNotificationAlert({ message: "error", type: "error" }));
    }
    handleClose();
  };

  useEffect(() => {
    if (selectAddition && selectAddition.idAdditionOvertime) {
      reset({
        idAdditionOvertime: selectAddition.idAdditionOvertime,
        name: selectAddition.name,
        minutes: selectAddition.minutes,
        value: selectAddition.value,
      });
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} md={6}>
          <Typography>{t("Name")}</Typography>
          <Controller
            name={"name"}
            control={control}
            render={({ field, fieldState }) => (
              <TextFieldTheme
                {...field}
                helperText={fieldState.error && fieldState.error.message}
                error={Boolean(fieldState.error)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography>{t("Unit.Minute")}</Typography>
          <InputNumber control={control} name={"minutes"} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography>{t("AmountOfMoney")}</Typography>
          <InputNumber control={control} name={"value"} />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"flex-end"}
          gap={"8px"}
        >
          <Button color="error" onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <ButtonBlue type="submit" variant="contained">
            {t("Confirm")}
          </ButtonBlue>
        </Grid>
      </Grid>
    </form>
  );
};

export default AdditionOvertimeForm;
