import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { FormControl, Select, MenuItem, Grid } from "@mui/material";
import dayjs from "dayjs";

import AlertResponse from "../../../shared/general/AlertResponse";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";

import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../../actions/employee";
import {
  AddOnBoardingEmployee,
  getOnBoardingList,
  getOnBoardingReviewer,
} from "../../../../../actions/onBoarding";
import { getDepartment } from "../../../../../actions/company";

// Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogAdd = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { open, handleClose, data } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [formData, setFormData] = useState({
    idOnBoardingList: null,
  });
  const [review, setReview] = useState(null);
  const { result: onBoardingList } = useSelector(
    (state) => state.onBoardingList
  );
  const { result: onBoardingReviewer } = useSelector(
    (state) => state.onBoardingReviewer
  );

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getAllEmployees());
    dispatch(getOnBoardingList());
    dispatch(getOnBoardingReviewer());
    if (open) {
      setFormData({
        idOnBoardingList: null,
        idEmployees: data,
        idOnBoardingReviewer: null,
        createDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        updateDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        isApprove: 0,
      });
    }
  }, [open]);

  useEffect(() => {
    if (formData.idOnBoardingList)
      setReview(
        onBoardingReviewer.filter(
          (review) => review.idOnBoardingList === formData.idOnBoardingList
        )
      );
    else setReview(null);
  }, [formData.idOnBoardingList]);

  const handleSubmit = async () => {
    if (!formData.idOnBoardingList || !formData.idOnBoardingReviewer) {
      handleOpenAlert(true);
      handleChangeAlertType(`${t("PleaseFillInRequiredInformations")}`);
    } else {
      const data = {
        idOnBoardingList: formData.idOnBoardingList,
        idEmployees: formData.idEmployees,
        idOnBoardingReviewer: formData.idOnBoardingReviewer,
        createDate: formData.createDate,
        updateDate: formData.updateDate,
        isApprove: formData.isApprove,
      };
      const result = await dispatch(AddOnBoardingEmployee(data));
      if (result) {
        handleClose();
      } else {
        handleOpenAlert(true);
        handleChangeAlertType("error");
      }
    }
  };

  return (
    <DrawerCustom
      title={`${t("AddInspector")}`}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      {formData && onBoardingList && (
        <StyledRoot>
          <Grid container spacing={2} style={{ marginBottom: 16, width: 400 }}>
            <Grid item xs={12} sm={6} className="GridTopicInput">
              {t("ItemName")}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Select
                  name="idOnBoardingList"
                  inputFormat={""}
                  value={formData.idOnBoardingList}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      ["idOnBoardingList"]: event.target.value,
                    });
                  }}
                >
                  {onBoardingList
                    .filter((row) => row.assignmentType === "individual")
                    .map((row) => (
                      <MenuItem
                        key={row.idOnBoardingList}
                        value={row.idOnBoardingList}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {review && (
            <Grid
              container
              spacing={2}
              style={{ marginBottom: 16, width: 400 }}
            >
              <Grid item xs={12} sm={6} className="GridTopicInput">
                {t("InspectorName")}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Select
                    name="idOnBoardingReviewer"
                    inputFormat={""}
                    value={formData.idOnBoardingReviewer}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        ["idOnBoardingReviewer"]: event.target.value,
                      });
                    }}
                  >
                    {review.map((row) => (
                      <MenuItem
                        key={row.idOnBoardingReviewer}
                        value={row.idOnBoardingReviewer}
                      >{`${row.firstname_TH} ${row.lastname_TH}`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleClose}>
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue variant="contained" onClick={handleSubmit} autoFocus>
              {t("SaveData")}
            </ButtonBlue>
          </StyledFooter>
        </StyledRoot>
      )}
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </DrawerCustom>
  );
};

export default DialogAdd;
