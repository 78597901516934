import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ExcelJS from 'exceljs';
import { colWidths, headerStyles } from './Xlsx-style';

export const TotalWageOTxlsx = async (t,year, data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`OT ${t("PerPerson")} (${t("Baht")})`)

    const months = [
        { month: "01", name: t("Months.January") },
        { month: "02", name: t("Months.February") },
        { month: "03", name: t("Months.March")},
        { month: "04", name: t("Months.April") },
        { month: "05", name: t("Months.May") },
        { month: "06", name: t("Months.June") },
        { month: "07", name: t("Months.July") },
        { month: "08", name: t("Months.August") },
        { month: "09", name: t("Months.September") },
        { month: "10", name: t("Months.October") },
        { month: "11", name: t("Months.November") },
        { month: "12", name: t("Months.December") }
    ];

    const headers = [
        t("EmployeeID"), t("FirstName"), t("LastName"), t("PersonalID"), t("Position"), t("Company"), t("Department"), t("Section"),
        ...months.map((month) => `OT ${t("Total")}${t("Baht")}${t("Month")} ${month.name}`),
        ...months.map((month) => `${t("Specific")} OT 1 ${t("Total")}${t("Baht")}${t("Month")} ${month.name}`),
        ...months.map((month) => `${t("Specific")} OT 1.5 ${t("Total")}${t("Baht")}${t("Month")} ${month.name}`),
        ...months.map((month) => `${t("Specific")} OT 2 ${t("Total")}${t("Baht")}${t("Month")} ${month.name}`),
        ...months.map((month) => `${t("Specific")} OT 3 ${t("Total")}${t("Baht")}${t("Month")} ${month.name}`)
    ];

    const headerRow = worksheet.addRow(headers);

    headerRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        if (headerStyles[colNumber - 1]) {
            cell.style = headerStyles[colNumber - 1];
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        }
    });

    headerRow.height = 50;

    colWidths.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width;
    });

    data && data.forEach((item) => {
        const row = [
            item.employeeID ? item.employeeID : "-",
            item.firstname_TH ? item.firstname_TH : "-",
            item.lastname_TH ? item.lastname_TH : "-",
            item.personalID ? item.personalID : "-",
            item.positionName ? item.positionName : "-",
            item.companyName ? item.companyName : "-",
            item.departmentName ? item.departmentName : "-",
            item.sectionName ? item.sectionName : "-"
        ];

        months.forEach((month) => {
            const monthData = item.monthlyData;

            if (monthData) {
                const totalOTValue = monthData && monthData.totalOT.find((e) => e.monthPeriod === `${year}-${month.month}` && e.value > 0);
                const totalOT1Value = monthData && monthData.SUM_OT1.find((e) => e.monthPeriod === `${year}-${month.month}` && e.value > 0);
                const totalOT15Value = monthData && monthData.SUM_OT15.find((e) => e.monthPeriod === `${year}-${month.month}` && e.value > 0);

                const totalOT2Value = monthData && monthData.sumOT2.find((e) => e.monthPeriod === `${year}-${month.month}` && e.value > 0);
                const totalOT3Value = monthData && monthData.sumOT3.find((e) => e.monthPeriod === `${year}-${month.month}` && e.value > 0);

                const totalOT = totalOTValue ? totalOTValue.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00";
                const totalOT1 = totalOT1Value ? totalOT1Value.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00";
                const totalOT15 = totalOT15Value ? totalOT15Value.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00";
                const totalOT2 = totalOT2Value ? totalOT2Value.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00";
                const totalOT3 = totalOT3Value ? totalOT3Value.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00";

                const totalOTColumnIndex = headers.indexOf(`OT ${t("Total")}${t("Baht")}${t("Month")} ${month.name}`);
                const totalOT1ColumnIndex = headers.indexOf(`${t("Specific")} OT 1 ${t("Total")}${t("Baht")}${t("Month")} ${month.name}`);
                const totalOT15ColumnIndex = headers.indexOf(`${t("Specific")} OT 1.5 ${t("Total")}${t("Baht")}${t("Month")} ${month.name}`);
                const totalOT2ColumnIndex = headers.indexOf(`${t("Specific")} OT 2 ${t("Total")}${t("Baht")}${t("Month")} ${month.name}`);
                const totalOT3ColumnIndex = headers.indexOf(`${t("Specific")} OT 3 ${t("Total")}${t("Baht")}${t("Month")} ${month.name}`);

                if (totalOT1ColumnIndex !== 1) {
                    row[totalOTColumnIndex] = totalOT;
                }

                if (totalOT1ColumnIndex !== 1) {
                    row[totalOT1ColumnIndex] = totalOT1;
                }

                if (totalOT15ColumnIndex !== 1) {
                    row[totalOT15ColumnIndex] = totalOT15;
                }

                if (totalOT2ColumnIndex !== 1) {
                    row[totalOT2ColumnIndex] = totalOT2;
                }

                if (totalOT3ColumnIndex !== 1) {
                    row[totalOT3ColumnIndex] = totalOT3;
                }
            }
        });

        const excelRow = worksheet.addRow(row);

        const contentStyle = {
            font: { size: 18, name: 'TH SarabunPSK' },
            alignment: {
                vertical: 'middle',
                horizontal: 'center'
            }
        };

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${data && data[0].companyName}-OT-${t("PerPerson")}(${t("Baht")})`;
        a.click();
        URL.revokeObjectURL(url);
    });
}