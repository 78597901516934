import {
  ONBOARDING_EMPLOYEE_BYID_FAILED,
  ONBOARDING_EMPLOYEE_BYID_FETCHING,
  ONBOARDING_EMPLOYEE_BYID_SUCCESS,
  ONBOARDING_EMPLOYEE_FAILED,
  ONBOARDING_EMPLOYEE_FETCHING,
  ONBOARDING_EMPLOYEE_SUCCESS,
  ONBOARDING_LIST_FAILED,
  ONBOARDING_LIST_FETCHING,
  ONBOARDING_LIST_SUCCESS,
  ONBOARDING_REVIEWER_FAILED,
  ONBOARDING_REVIEWER_FETCHING,
  ONBOARDING_REVIEWER_SUCCESS,
  ONBOARDING_REVIEWER_DELETE_FAILED,
  ONBOARDING_REVIEWER_DELETE_SUCCESS,
} from "./types";
import onBoardingService from "../services/onBoarding.service";

export const clearOnBoardingEmployee = () => async (dispatch) => {
  dispatch({
    type: ONBOARDING_EMPLOYEE_SUCCESS,
    payload: null,
  });
  dispatch({
    type: ONBOARDING_EMPLOYEE_BYID_SUCCESS,
    payload: null,
  });
};

export const AddOnBoardinglist = (formData) => async () => {
  try {
    console.log(formData);
    const res = await onBoardingService.addOnBoardingList(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};
export const AddOnBoardingReviewer = (formData) => async () => {
  try {
    console.log(formData);
    const res = await onBoardingService.addOnBoardingReviewer(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};
export const AddOnBoardingEmployee = (formData) => async () => {
  try {
    console.log(formData);
    const res = await onBoardingService.addOnBoardingEmployee(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};
export const EditOnBoardinglist = (formData) => async () => {
  try {
    console.log(formData);
    const res = await onBoardingService.editOnBoardingList(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};
export const approveOnBoardingEmployee = (formData) => async () => {
  try {
    console.log(formData);
    const res = await onBoardingService.approveOnBoardingEmployee(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const getOnBoardingList = () => async (dispatch) => {
  try {
    dispatch({
      type: ONBOARDING_LIST_FETCHING,
    });
    const res = await onBoardingService.getOnBoardingList();
    if (res) {
      dispatch({
        type: ONBOARDING_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ONBOARDING_LIST_FAILED,
    });
    console.log(err);
  }
};

export const getOnBoardingReviewer = () => async (dispatch) => {
  try {
    dispatch({
      type: ONBOARDING_REVIEWER_FETCHING,
    });
    const res = await onBoardingService.getOnBoardingReviewer();
    if (res) {
      dispatch({
        type: ONBOARDING_REVIEWER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ONBOARDING_REVIEWER_FAILED,
    });
    console.log(err);
  }
};

export const getOnBoardingEmployee = () => async (dispatch) => {
  try {
    dispatch({
      type: ONBOARDING_EMPLOYEE_FETCHING,
    });
    const res = await onBoardingService.getOnBoardingEmployee();
    if (res) {
      dispatch({
        type: ONBOARDING_EMPLOYEE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ONBOARDING_EMPLOYEE_FAILED,
    });
    console.log(err);
  }
};

export const getOnBoardingEmployeeByReviewer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ONBOARDING_EMPLOYEE_FETCHING,
    });
    const res = await onBoardingService.getOnBoardingEmployeeByReviewer(id);
    if (res) {
      dispatch({
        type: ONBOARDING_EMPLOYEE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ONBOARDING_EMPLOYEE_FAILED,
    });
    console.log(err);
  }
};

export const getOnBoardingEmployeeByID = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ONBOARDING_EMPLOYEE_BYID_FETCHING,
    });
    const res = await onBoardingService.getOnBoardingEmployeeByID(id);
    if (res) {
      dispatch({
        type: ONBOARDING_EMPLOYEE_BYID_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ONBOARDING_EMPLOYEE_BYID_FAILED,
    });
    console.log(err);
  }
};

export const approveOnBoardingEmployeeByReviewer =
  (id, formData) => async () => {
    try {
      console.log(formData);
      const res = await onBoardingService.approveOnBoardingEmployeeByReviewer(
        id,
        formData
      );
      if (res) {
        return res;
      }
    } catch (error) {
      console.log(error);
      const message =
        (error.response && error.response.data && error.response.data.name) ||
        error.name ||
        error.toString();
      return error;
    }
  };

export const deleteOnBoardingReviewer =
  (idOnBoardingReviewer) => async (dispatch) => {
    try {
      const res = await onBoardingService.deleteOnBoardingReviewer(
        idOnBoardingReviewer
      );
      if (res) {
        dispatch({
          type: ONBOARDING_REVIEWER_DELETE_SUCCESS,
          payload: idOnBoardingReviewer,
        });
        return res;
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: ONBOARDING_REVIEWER_DELETE_FAILED,
      });
      const message =
        (error.response && error.response.data && error.response.data.name) ||
        error.name ||
        error.toString();
      return { error: message };
    }
  };
