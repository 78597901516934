import { httpClient } from "./httpClient";

const getAllJob = () => {
  return httpClient.get("showJob");
};

const getJobPosition = (jobName, subJobName, position) => {
  return httpClient.get("showJobByPosition/"+jobName+"/"+subJobName+"/"+position);
};

const getJobPositionById = (JobPosition, backPosition, id, jobPosition) => {
  let data = {
    JobPosition : JobPosition,
    backPosition : backPosition,
    id : id,
    jobPosition : jobPosition
  }
  return httpClient.post("showJobByPositionById",data);
}

const addNewPosition = (data) => {
  return httpClient.post("addNewPosition",data);
};

export default {
  getAllJob,
  getJobPosition,
  getJobPositionById,
  addNewPosition,
};
