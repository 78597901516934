import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { NavLink } from "react-router-dom";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { standardMenu } from "../../assets/data/menu-app";
import { styled } from "@mui/styles";
import { useTranslation } from "react-i18next";

const StyledListItem = styled(ListItem)({});

const ListMenu = (props) => {
  const { t, i18n } = useTranslation();
  const [dataListMenu] = useState(props.dataListMenu);
  const [openCollapse, setOpenCollapse] = React.useState(-1);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: standardMenuCompany } = useSelector(
    (state) => state.standardMenu
  );

  const handleClickCollapse = (index) => {
    if (openCollapse === index) {
      setOpenCollapse(-1);
    } else {
      setOpenCollapse(index);
    }
  };

  return (
    <List
    // subheader={
    //   props.menuRole &&
    //   props.menuRole !== "User" && (
    //     <ListSubheader>{props.menuRole}</ListSubheader>
    //   )
    // }
    >
      { 
        userProfile &&
        standardMenuCompany &&
        standardMenu
          .filter(
            (x) => {
              if(userProfile.isFillIn === 1){
                return x.key === "timeAttendance" || x.key === "shiftManagement"
              } else {
                return standardMenuCompany[
                  props.menuRole === "User"
                    ? 0
                    : props.menuRole === "Manager"
                    ? 1
                    : 2
                ].indexOf(x.key) > -1 && ((!userProfile.isPayroll && x.key !== "payroll") ||
                userProfile.isPayroll)
              }
            }
          )
          .map((value, index) => {
            return (
              <Fragment key={value.key}>
                {value.collapse ? (
                  <Fragment>
                    <ListItem
                      onClick={() => handleClickCollapse(index)}
                      key={value.key}
                    >
                      <ListItemIcon>{value.icon}</ListItemIcon>
                      <ListItemText primary={value.name} />
                      {openCollapse === index ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse
                      in={openCollapse === index}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List>
                        {value.collapse.map((collapse) => {
                          return (
                            <Fragment key={collapse.link}>
                              {collapse.icon === null ? (
                                <ListSubheader style={{ lineHeight: "18px" }}>
                                  {collapse.name}
                                </ListSubheader>
                              ) : (
                                <ListItem
                                  component={NavLink}
                                  to={collapse.link}
                                  key={collapse.key}
                                  // className={
                                  //   props.open
                                  //     ? classes.nestedOpen
                                  //     : classes.nestedClose
                                  // }
                                  //activeclassname={classes.isActive}
                                >
                                  <ListItemIcon>{collapse.icon}</ListItemIcon>
                                  <ListItemText primary={collapse.name} />
                                </ListItem>
                              )}
                            </Fragment>
                          );
                        })}
                      </List>
                    </Collapse>
                  </Fragment>
                ) : (
                  <ListItem component={NavLink} to={value.link} key={value.key}>
                    <ListItemIcon>{value.icon}</ListItemIcon>
                    <ListItemText primary={t(value.nameTrans)} />
                  </ListItem>
                )}
              </Fragment>
            );
          })}
    </List>
  );
};

export default ListMenu;
