import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  deleteAssetManagement,
  getAssetManagement,
} from "../../../../actions/assetManagement";
import { openNotificationAlert } from "../../../../actions/notificationAlert";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../shared/general/ButtonBlue";

const DialogDelete = ({ open, handleClose, idAssetManagement, idCompany }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDeleteAssetManagement = async () => {
    const res = await dispatch(deleteAssetManagement(idAssetManagement));
    if (res && res.status === 200) {
      dispatch(
        openNotificationAlert({ type: "success", message: t("Success") })
      );
    } else {
      dispatch(
        openNotificationAlert({ type: "error", message: t("AnErrorOccurred") })
      );
    }
    handleClose();
    dispatch(getAssetManagement(idCompany));
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{t("ConfirmDeleteItem")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("ItemWillBePermanentlyDeleted")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>{t("Cancel")}</Button>
        <ButtonBlue
          variant="contained"
          onClick={handleDeleteAssetManagement}
        >
          {t("Confirm")}
        </ButtonBlue>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDelete;
