import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  addPerformanceEmployee,
  getPerformanceEmployee,
} from "../../../../actions/estimatePerformance";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ButtonBlue from "../../shared/general/ButtonBlue";
import {
  answerQuestion5Scale,
  answerQuestion6Scale,
} from "../../assets/data/answerScale";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { openNotificationAlert } from "../../../../actions/notificationAlert";

const percentageScore = (questionList = []) => {
  let totalRating = 0;
  for (const item of questionList) {
    if (item.questionType === "choice") {
      totalRating += (item.answer * item.weight) / 100;
    }
  }

  return Math.round(totalRating);
};

const PerformanceForm = ({ open, handleClose, performance }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    questionList: yup.array().when("isExpand", {
      is: (val) => !val,
      then: (schema) =>
        schema.of(
          yup.object().shape({
            answer: yup.string().required(`${t("ThisFieldIsRequired")}`),
          })
        ),
    }),
    goodPoint: yup
      .string()
      .max(300, `${t("NotMoreThan")} 300 ${t("Characters")}`),
    weakPoint: yup
      .string()
      .max(300, `${t("NotMoreThan")} 300 ${t("Characters")}`),
    others: yup.string().max(300, `${t("NotMoreThan")} 300 ${t("Characters")}`),
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      questionList: [],
      employeeComment: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const questionUseFieldArray = useFieldArray({
    control: control,
    name: "questionList",
  });

  const onSubmit = async (values) => {
    const cleanQuestion = values.questionList.map((item) => ({
      idPerformanceQuestion: item.idPerformanceQuestion,
      answer: item.answer,
    }));

    const formData = {
      yearPerformance: performance.year,
      questionList: cleanQuestion,
      employeeComment: values.employeeComment,
    };

    const res = await dispatch(addPerformanceEmployee(formData));
    if (res && res.status === 201) {
      dispatch(
        openNotificationAlert({ type: "success", message: t("Success") })
      );
      dispatch(getPerformanceEmployee());
      handleClose();
    } else {
      dispatch(
        openNotificationAlert({ type: "error", message: t("AnErrorOccurred") })
      );
    }
  };

  useEffect(() => {
    if (performance.questionSet.questionList && performance.questionSet.questionList.length > 0) {
      const activeQuestion = performance.questionSet.questionList.map((item) => ({
        idPerformanceQuestion: item.idPerformanceQuestion,
        question: item.question,
        questionType: item.questionType,
        weight: item.weight,
        answer: "",
      }));

      questionUseFieldArray.replace(activeQuestion);
    }
  }, [questionList]);

  const questionList = watch("questionList");

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>{t("WorkEvaluationForm")}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack gap={2} marginBottom={2}>
            {questionUseFieldArray.fields.map((question, index) => (
              <Stack key={question.id} gap={1}>
                <Typography fontWeight={500}>
                  {`${t("Question")} ${index + 1} : ${question.question}`}
                </Typography>
                {question.questionType === "choice" ? (
                  <Controller
                    control={control}
                    name={`questionList.${index}.answer`}
                    render={({ field, fieldState }) => (
                      <>
                        <Typography color={"text.secondary"}>
                          {`${t("Weight")} ${question.weight}%`}
                        </Typography>
                        <RadioGroup {...field} row>
                          {performance.questionSet.answerScale === 6
                            ? answerQuestion6Scale.map((item) => (
                                <FormControlLabel
                                  key={item.key}
                                  value={item.value}
                                  control={<Radio />}
                                  label={t(item.label)}
                                />
                              ))
                            : answerQuestion5Scale.map((item) => (
                                <FormControlLabel
                                  key={item.key}
                                  value={item.value}
                                  control={<Radio />}
                                  label={t(item.label)}
                                />
                              ))}
                        </RadioGroup>
                        {Boolean(fieldState.error) && (
                          <FormHelperText sx={{ color: "#ff0000" }}>
                            {fieldState.error.message}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />
                ) : (
                  <Controller
                    control={control}
                    name={`questionList.${index}.answer`}
                    render={({ field, fieldState }) => (
                      <TextFieldTheme
                        {...field}
                        fullWidth
                        multiline
                        rows={2}
                        error={Boolean(fieldState.error)}
                        helperText={
                          fieldState.error && fieldState.error.message
                        }
                      />
                    )}
                  />
                )}
                <Divider sx={{ borderColor: "#e2e2e2" }} />
              </Stack>
            ))}
          </Stack>
          <Grid container spacing={2} marginBottom={4}>
            <Grid item xs={12}>
              <Typography fontWeight={500} marginBottom={"8px"}>
                {t("Comment")}
              </Typography>
              <Controller
                control={control}
                name={"employeeComment"}
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    multiline
                    rows={2}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error && fieldState.error.message}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              marginTop={3}
              display={"flex"}
              justifyContent={"center"}
            >
              <Box style={{ position: "relative", display: "inline-flex" }}>
                <CircularProgress
                  variant="determinate"
                  style={{
                    color: "#eeeeee",
                  }}
                  size={70}
                  thickness={4}
                  value={100}
                />
                <CircularProgress
                  variant="determinate"
                  value={percentageScore(questionList)}
                  style={{
                    color: "#007afe",
                    animationDuration: "550ms",
                    position: "absolute",
                    left: 0,
                  }}
                  size={70}
                ></CircularProgress>
                <Box
                  style={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{ fontSize: 16 }}
                    component="div"
                    color="text.third"
                  >
                    {percentageScore(questionList)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonBlue type="button" onClick={handleClose}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue type="submit" variant="contained" disabled={isSubmitting}>
            {t("Confirm")}
          </ButtonBlue>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PerformanceForm;
