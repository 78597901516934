import { GET_KPI_UPDATE_FAILED, GET_KPI_UPDATE_FETCHING, GET_KPI_UPDATE_SUCCESS } from "../actions/types";


const initialState = {
    result: null,
    isFetching: false,
    isError: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_KPI_UPDATE_FETCHING:
            return { ...state, result: null, isFetching: true, isError: false };
        case GET_KPI_UPDATE_FAILED:
            return { ...state, result: null, isFetching: false, isError: true };
        case GET_KPI_UPDATE_SUCCESS:
            return { ...state, result: payload, isFetching: false, isError: false };
        default:
            return state;
    }
}