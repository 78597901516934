import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, Chip, Grid, IconButton, Typography } from "@mui/material";
import CardStyle from "../../shared/general/Card";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import Pagination from "../../shared/general/Pagination";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    marginTop: 40,
    "& .MuiChip-root": {
      borderRadius: "8px",
      fontWeight: 600,
    },
  },
  jobIcon: {
    fontSize: "2.5rem",
    color: "#007afc",
    backgroundColor: "#e1f5fe",
    padding: "5px",
    borderRadius: "5px",
  },
  applyBtn: {
    border: "1px solid #007afc !important",
    "&:hover": {
      backgroundColor: "#007afc",
      color: "#fff",
    },
  },
}));

function JobCard(props) {
  const { jobList } = props;
  const classes = useStyles();

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(3);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = jobList.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className={classes.root}>
      {jobList.length > 0 &&
        currentPosts.map((item) => (
          <CardStyle key={item.jobId} style={{ marginBottom: "24px" }}>
            <Box sx={{ padding: "24px" }}>
              <Grid container spacing={2}>
                <Grid item xs={2} md={1} lg={1}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AccountBoxIcon className={classes.jobIcon} />
                  </Box>
                </Grid>
                <Grid item xs={10} md={4} lg={4}>
                  <Grid container>
                    <Grid item xs={12} md={8} lg={8}>
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        {item.jobName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography
                        variant="body1"
                        sx={{ color: "#9e9e9e" }}
                      >{`${item.department} , ${item.section}`}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography variant="body2" sx={{ color: "#6e6e6e" }}>
                        Department
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography variant="body1">{item.department}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography variant="body2" sx={{ color: "#6e6e6e" }}>
                        Company
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography variant="body1">{item.company}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography variant="body2" sx={{ color: "#6e6e6e" }}>
                        Location
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography variant="body1">{item.location}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={1}
                  lg={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button variant="outlined" className={classes.applyBtn}>
                      Apply
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </CardStyle>
        ))}
      <Pagination
        paginate={paginate}
        postsPerPage={postsPerPage}
        totalPosts={jobList.length}
      />
    </div>
  );
}

export default JobCard;
