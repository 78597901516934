import React from 'react';
import { useForm } from 'react-hook-form';
import { Typography, Divider, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
const Overview = ({ employee }) => {
    const {t} = useTranslation();
    const { register } = useForm({
        defaultValues: {
            overviewInput: employee.overview ? employee.overview : "-"
        }
    });

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1" gutterBottom>{t("AppMenu.Overview")}</Typography>
            </div>
            <Divider />
            <form >
                <TextField
                    inputProps={{ ...register("overviewInput") }}
                    disabled={true}
                    variant={"standard"}
                    margin="dense"
                    placeholder="-"
                    multiline
                    fullWidth
                />
            </form>
        </div>
    );
};

export default Overview;