import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Typography,
  InputAdornment,
  Button,
  Stack,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  TableCell,
  Chip,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Avatar,
  Grid,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import TextField from "@mui/material/TextField";
import Backdrop from "../../shared/general/Backdrop";
import dayjs from "dayjs";
import 'dayjs/locale/th';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import CardStyle from "../../shared/general/Card";
import TablePagination from "@mui/material/TablePagination";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { getAllEmployees } from "../../../../actions/employee";
import {
  getUsersInProjectById,
  getUserworkforceShowHourTable,
  getSumHourWorkforceHourUserByIdMonth,
  getEmployeesWeek
} from "../../../../actions/workforceEmployees";
import { object } from "yup";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DialogCreate from "./dialogUser";

const StyledRoot = styled("div")({
  backgroundColor: "#f5f5f5",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const WrapHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
  marginTop: 8,
  marginBottom: 16,
});

const StyledTableContainer = styled(TableContainer)({
  maxHeight: 440,
  position: "relative",
});

const StyledTable = styled(Table)({
  position: "relative",
});

const StyledTableHead = styled(TableHead)({
  backgroundColor: "white",
  position: "sticky",
  top: 0,
  zIndex: 1,
});

const AlertDialog = ({ openAlert, handleCloseAlert, detail }) => {
  const {t} = useTranslation();

  return (
    <React.Fragment>
      <Dialog
        open={openAlert}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="600px"
        minWidth="300px"
      >
        <DialogTitle id="alert-dialog-title">
          {t("Note")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {detail}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlert}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const TableUserTime = (props) => {
  const history = useHistory()
  const dispatch = useDispatch();
  const datetoday = new Date();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const { result: userWorkforceShowHour } = useSelector(
    (state) => state.userWorkforceShowHour
  );
  const { result: projectUserInsertTime } = useSelector(
    (state) => state.projectUserInsertTime
  );

  const { result: getSumHourWorkforceHourUser } = useSelector(
    (state) => state.getSumHourWorkforceHourUserByIdMonth
  );
  const [enddate, setEnddate] = useState("");


  const { control, handleSubmit, formState, reset } = useForm({
    defaultValues: {
      firstweek: [{ value: "" }, { value: "" }],
      secondweek: [{ value: "" }, { value: "" }],
      thirdweek: [{ value: "" }, { value: "" }],
      fourthweek: [{ value: "" }, { value: "" }],
    },
  });
  const [dissubmit, setDissubmit] = useState({
    firstweek: {},
    secondweek: {},
    thirdweek: {},
    fourthweek: {}
  });

  useEffect(() => {
    const storedDissubmit = localStorage.getItem('dissubmit');
    if (storedDissubmit) {
      setDissubmit(JSON.parse(storedDissubmit));
    }
  }, []);

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getUsersInProjectById());
    dispatch(getUserworkforceShowHourTable())
    dispatch(getEmployeesWeek())
    const formData = {
      month: (datetoday.getMonth() + 1),
    }
    dispatch(getSumHourWorkforceHourUserByIdMonth(formData))
  }, []);
  const { result: empWorkforceWeek } = useSelector((state) => state.empWorkforceWeek)
  useEffect(() => {
    if (
      projectUserInsertTime
    ) {
      const firstweek = []
      const secondweek = []
      const thirdweek = []
      const fourthweek = []
      projectUserInsertTime.map(item => {
        firstweek.push({ value: item.firstweek })
        secondweek.push({ value: item.secondweek })
        thirdweek.push({ value: item.thirdweek })
        fourthweek.push({ value: item.fourthweek })
      })
      reset({
        firstweek: firstweek,
        secondweek: secondweek,
        thirdweek: thirdweek,
        fourthweek: fourthweek,
      })
    }

  }, [projectUserInsertTime])
  const [monthUse, setMonthUse] = useState((datetoday.getMonth() + 1))
  const [yearUse, setYearUse] = useState((datetoday.getFullYear()))
  const [filteredData, setFilteredData] = useState([]);
  const { t, i18n } = useTranslation();
  const [dateWeek, setDateWeek] = useState([])
  useEffect(() => {
    if (projectUserInsertTime) {
      const filtered = projectUserInsertTime.filter(item => item.month === monthUse && item.year === yearUse);
      setFilteredData(filtered);
    } else {
      console.log("empInWorkforce is not loaded or is null.");
    }
    const formData = {
      month: monthUse,
      year: yearUse
    }
    dispatch(getSumHourWorkforceHourUserByIdMonth(formData))
  }, [projectUserInsertTime, monthUse, yearUse]);

  const [openAlert, setOpenAlert] = React.useState(false);
  const [detailAlert, setDeatilAlert] = useState('')
  const handleClickOpenAlert = (detail) => {
    console.log(detail)
    setDeatilAlert(detail)
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setDeatilAlert('')
    setOpenAlert(false);
  };
  useEffect(() => {
    let temp = [];


    if (monthUse < 1 || monthUse > 12) {
      console.error('Invalid monthUse value:', monthUse);
      return;
    }
    if (!yearUse || yearUse < 2023 || yearUse > 2025) {
      console.error('Invalid yearUse value:', yearUse);
      return;
    }


    let fid = dayjs(`${yearUse}-${String(monthUse).padStart(2, '0')}-01`, 'YYYY-MM-DD');

    if (fid.day() !== 1) {
      fid = fid.add((8 - fid.day()), 'day');
    }

    temp.push({
      start: fid.clone(),
      end: fid.clone().add(6, "day"),
      count: 0,
      weekCount: 1
    });

    let endDate = fid.clone().add(6, "day");
    let endTemp = dayjs(`${yearUse}-${String(monthUse).padStart(2, '0')}-01`).endOf('month');
    let weekCount = 1;

    while (endDate.isBefore(endTemp, "day")) {
      fid = endDate.clone().add(1, "day");
      endDate = fid.clone().add(6, "day");

      temp.push({
        start: fid.clone(),
        end: endDate.clone(),
        count: 0,
        weekCount: ++weekCount
      });
    }

    setDateWeek(temp);

  }, [monthUse, yearUse]);





  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDialogCreate, setOpenDialogCreate] = React.useState("");
  const [projectid, setProjectid] = useState(null)
  const [detail, setDetail] = useState('')
  const [hour, setHour] = useState(0)
  const handleClickOpen = (projectid, week, detail, hour) => {
    if (projectid === '-') {
      setProjectid('-')
    } else {
      setProjectid(projectid)
    }
    setOpen(true);
    setDetail(detail)
    setHour(hour)
    setSelectedWeek(week);
    setOpenDialogCreate(true);

  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectItem(null);
  };
  const [selectItem, setSelectItem] = useState(null);

  const handleChangeMonth = (event) => {
    setMonthUse(event.target.value);
  };
  const handleChangeYear = (event) => {
    setYearUse(event.target.value);
  };

  const handleChange = (event) => {
    setEnddate(event.target.value);
  };

  dayjs.locale('th');




  const columns = [
    { id: 'projectname', label: t("ProjectName"), minWidth: 170, align: 'center' },

    {
      id: 'firstweek',
      label: `${t("Date")} 1-7 (${t("week")} 1)`,
      minWidth: 170,
      align: 'center'
    },
    {
      id: 'secondweek',
      label: `${t("Date")} 8-14 (${t("week")} 2)`,
      minWidth: 170,
      align: 'center'
    },
    {
      id: 'thirdweek',
      label: `${t("Date")} 15-21 (${t("week")} 3)`,
      minWidth: 170,
      align: 'center'
    },
    {
      id: 'fourthweek',
      label: `${t("Date")} 22-31 (${t("week")} 4)`,
      minWidth: 170,
      align: 'center'
    },
  ];

  const monthNames = [
    t("Months.January"), t("Months.February"), t("Months.March"), t("Months.April"), t("Months.May"), t("Months.June"),
      t("Months.July"), t("Months.August"), t("Months.September"), t("Months.October"), t("Months.November"), t("Months.December")
  ];


  const opendashboard = (id) => {
    const queryParams = new URLSearchParams({
      MonthCreated: monthUse,
      YearCreated: yearUse,
      idEmployees: id,
    }).toString();
    history.push(`/dashboardProjectHour?${queryParams}`);
  };



  return (
    <StyledRoot className={"page"}>
      <Container maxWidth="lg">
        <CardStyle style={{ padding: 16 }}>
          <Stack
            direction={{ xs: "column-reverse", sm: "row" }}
            alignItems={"center"}
            justifyContent={"space-between"}
            marginBottom={4}
            gap={2}
          >
            <Grid item xs={12} md={7} container spacing={3}>
              <Grid item xs={12} md={5}>
                <FormControl fullWidth>
                  <InputLabel>{t("SelectByMonth")}</InputLabel>
                  <Select
                    value={monthUse}
                    label={`${t("SelectByMonth")}`}
                    onChange={handleChangeMonth}
                  >
                    <MenuItem value={1}>{t("Months.January")}</MenuItem>
                    <MenuItem value={2}>{t("Months.February")}</MenuItem>
                    <MenuItem value={3}>{t("Months.March")}</MenuItem>
                    <MenuItem value={4}>{t("Months.April")}</MenuItem>
                    <MenuItem value={5}>{t("Months.May")}</MenuItem>
                    <MenuItem value={6}>{t("Months.June")}</MenuItem>
                    <MenuItem value={7}>{t("Months.July")}</MenuItem>
                    <MenuItem value={8}>{t("Months.August")}</MenuItem>
                    <MenuItem value={9}>{t("Months.September")}</MenuItem>
                    <MenuItem value={10}>{t("Months.October")}</MenuItem>
                    <MenuItem value={11}>{t("Months.November")}</MenuItem>
                    <MenuItem value={12}>{t("Months.December")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel>{t("selectByYear")}</InputLabel>
                  <Select
                    value={yearUse}
                    label={`${t("selectByYear")}`}
                    onChange={handleChangeYear}
                  >
                    <MenuItem value="">{t("All")}</MenuItem>
                    <MenuItem value={2023}>2023</MenuItem>
                    <MenuItem value={2024}>2024</MenuItem>
                    <MenuItem value={2025}>2025</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <ButtonBlue
                  variant="contained"
                  startIcon={<ShowChartIcon />}
                  onClick={() => opendashboard((userWorkforceShowHour && userWorkforceShowHour[0].idEmployees))}
                  style={{ marginLeft: 16 }}
                >{t("checkDashboard")}
                  {/* {t("AddEmployee")} */}
                </ButtonBlue>
              </Grid>
            </Grid>


            {!isNaN(enddate) && (
              <WrapHeader style={{ marginLeft: "auto" }}>
                <Typography variant="h4">
                  {monthNames[parseInt(enddate)]}
                </Typography>
              </WrapHeader>
            )}
          </Stack>
          {filteredData && filteredData.length > 0 ? (
            <StyledTableContainer sx={{ maxHeight: 700 }}>
              <div>
                <Table style={{ overflowX: 'auto' }} stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align={'center'} style={{ minWidth: '170px' }}>{t("ProjectName")}</TableCell>
                      {dateWeek.map((column) => (
                        <TableCell
                          key={column.weekCount}
                          align={'center'}
                          style={{ minWidth: '170px' }}
                        >
                          {t("Date")} {column.start.date()} - {column.end.date()} (week {column.weekCount})
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell align="center">{item.name}</TableCell>
                            {dateWeek.map((week) => {
                              const weekData = userWorkforceShowHour && Array.isArray(userWorkforceShowHour)
                                ? userWorkforceShowHour.find(
                                  (data) => data.inWeek === week.weekCount && data.name === item.name && data.monthWork === monthUse && data.inYear === yearUse
                                )
                                : null;

                              return (
                                <TableCell align="center" key={week.weekCount}>
                                  <TextField
                                    value={weekData ? weekData.totalWorkingHours : '-'}
                                    InputProps={weekData && weekData.isApprove == 1 ? ({
                                      endAdornment: (
                                        <InputAdornment position="end"

                                        >
                                          <i
                                            className={`fas fa-check-circle`}
                                            style={{ fontSize: 24, color: "#00c292" }}
                                          ></i>
                                        </InputAdornment>
                                      ),
                                      readOnly: true,
                                    }) : weekData && weekData.isApprove == 0 ? ({
                                      endAdornment: (
                                        <InputAdornment position="end"
                                        >
                                          <i onClick={() => handleClickOpenAlert(weekData.approvedComment ? weekData.approvedComment : 'ผู้จัดการไม่ได้ใส่หมายเหตุ')}
                                            className={`fas fa-times-circle`}
                                            style={{ fontSize: 24, color: "#e46a76", marginRight: '20px' }}
                                          ></i>
                                          <i onClick={() => handleClickOpen(item.idWorkforceProjectEmployees, week.weekCount, weekData ? weekData.detail : '', weekData ? weekData.totalWorkingHours : '')} className="fa-solid fa-pen"></i>
                                        </InputAdornment>

                                      )

                                    }) : ({
                                      endAdornment: (
                                        <InputAdornment position="end"
                                          onClick={() => handleClickOpen(item.idWorkforceProjectEmployees, week.weekCount, weekData ? weekData.detail : '', weekData ? weekData.totalWorkingHours : '')}>
                                          <i className="fa-solid fa-pen"></i>
                                        </InputAdornment>
                                      ),
                                      readOnly: true,
                                    })
                                    }
                                    helperText={
                                      formState.errors &&
                                        formState.errors.name
                                        ? `${formState.errors.name.message} ${t("pleaseEnterAdditionalMessage")}`
                                        : t("pleaseEnterNumberOfHours")
                                    }
                                  />
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        )
                      }
                      )}
                    <TableRow style={{ textAlign: 'center' }}>
                      <TableCell>{t("weeklyTotal")}</TableCell>
                      {dateWeek.map((week) => {
                        const weekSum = userWorkforceShowHour && userWorkforceShowHour.filter((data) => data.inWeek === week.weekCount && data.monthWork === monthUse && data.inYear === yearUse && data.isApprove === 1).reduce((acc, crr) => acc + parseInt(crr.totalWorkingHours), 0)
                        return (
                          <TableCell style={{ textAlign: 'center', alignItems: 'center' }}>
                            <strong style={{ fontSize: '20px' }}>{weekSum}</strong> {t("Unit.ShortHours")}
                          </TableCell>
                        )
                      })}
                    </TableRow>

                  </TableBody>
                </Table>

              </div>
              <Typography variant="h6">{t("totalTime")}</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Table>
                    <TableBody>
                      {getSumHourWorkforceHourUser && getSumHourWorkforceHourUser.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align="left" style={{ padding: '10px', minWidth: '150px' }}>{item.name}</TableCell>
                          <TableCell align="center" style={{ padding: '10px', minWidth: '100px' }}>
                            <strong style={{ fontSize: '20px' }}>{item.totalWorkingHours !== null ? item.totalWorkingHours : 0}</strong> {t("Unit.ShortHours")}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant="h6">ร{t("monthlyTotal")}:
                    <strong style={{ fontSize: '60px', paddingLeft: '20px', paddingRight: '20px' }}>
                      {getSumHourWorkforceHourUser && getSumHourWorkforceHourUser.reduce((acc, crr) => acc + parseInt(crr.totalWorkingHours), 0)}
                    </strong> {t("Unit.ShortHours")}
                  </Typography>
                </Grid>
              </Grid>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${
                            count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
              />
            </StyledTableContainer>
          ) : (
            <Typography>{t("NoItems")}</Typography>
          )}

          {open && (
            <DialogCreate
              week={selectedWeek}
              open={openDialogCreate}
              handleClose={handleClose}
              data={selectItem}
              projectid={projectid}
              month={monthUse}
              year={yearUse}
              detail={detail}
              hour={hour}

            />
          )}
          {openAlert && (
            <AlertDialog
              openAlert={openAlert}
              handleCloseAlert={handleCloseAlert}
              detail={detailAlert}
            />
          )}

          <br />

        </CardStyle>
      </Container>

    </StyledRoot>
  );
};

export default TableUserTime;

