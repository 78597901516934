import {
    IDVPLANS_FETCHING,
    IDVPLANS_SUCCESS,
    IDVPLANS_FAILED,
  } from "../actions/types";
  
  const initialState = {
    result: null,
    isFetching: false,
    isError: false,
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case IDVPLANS_FETCHING:
        return { ...state, result: null, isFetching: true, isError: false };
      case IDVPLANS_FAILED:
        return { ...state, result: null, isFetching: false, isError: true };
      case IDVPLANS_SUCCESS:
        return { ...state, result: payload, isFetching: false, isError: false };
      default:
        return state;
    }
  }
  