import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { Data } from "../mockData";
import { styled } from "@mui/styles";

import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import Notification from "../../../shared/general/Notification";
import EmployeeInfo from "../../../shared/pages/okrs/components/EmployeeInfo";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ImpactUploadFile from "../../../shared/pages/okrs/components/ImpactUploadFile";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import { useSelector, useDispatch } from "react-redux";
import { getUserProfile } from "../../../../../actions/user";

import {
  Grid,
  Toolbar,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  InputLabel,
  IconButton,
  InputAdornment,
  Container,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  root: {
    color: "#222f3e",
    // '& .MuiGrid-container': {
    //   marginBottom: '35px !important',
    // },
    "& .MuiInputBase-input.Mui-disabled": {
      color: "#222f3e",
    },
    "& .MuiButton-contained": {
      boxShadow: "none",
    },
    "&  .MuiToolbar-gutters": {
      
    },
  },
  imageEmp: {
    width: 160,
    height: 160,
    borderRadius: "50%",
    marginBottom: 24,
    
  },
  employeeInfo: {
   
  },
  cardPlan: {
    marginTop: 24,
    borderRadius: "24px",
    padding: 32,
    background: "#fff",
  },
  fontBold: {
    fontWeight: 700,
  },
  header_section: {
    display: "flex",
    alignItems: "center",
  },
  addBtn: {
    textAlign: "center",
    backgroundColor: "#4caf50",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#4caf50",
      color: "#fff",
    },
  },
  removeBtn: {
    textAlign: "center",
    backgroundColor: "#e53935",
    color: "#fff",
    marginRight: 8,
    "&:hover": {
      backgroundColor: "#e53935",
      color: "#fff",
    },
  },
}));

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

const selectValue = [
  { id: "Direct", title: "Direct" },
  { id: "Indirect", title: "Indirect" },
];

const returnType = [
  { id: "Type1", title: "Type1" },
  { id: "Type2", title: "Type2" },
];

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const initialImpact = () => ({
  impactID: 0,
  createBy: "",
  dateCreate: new Date(),
  impactJobs: [],
  impactOthers: [],
});

function SuccessProfile() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [employeeId, setEmployeeId] = useState(0);
  const [impact, setImpact] = useState(initialImpact);
  const [inputFields, setInputFields] = useState([
    {
      jobID: 0,
      impactID: 0,
      impactName: "",
      selectValue: "",
      returnType: "",
      impactCost: 0,
      description: "",
      imagePath1: "",
      imagePath2: "",
      pdfPath1: "",
      pdfPath2: "",
      files: [],
    },
  ]);
  const [otherFields, setOtherFields] = useState([
    {
      otherID: 0,
      impactID: 0,
      impactOtherName: "",
      impactTo: "",
      descriptionOther: "",
      imagePath1: "",
      imagePath2: "",
      pdfPath1: "",
      pdfPath2: "",
      files: [],
    },
  ]);

  const { register, handleSubmit, errors } = useForm();
  const location = useLocation();
  const [disabled, setDisabled] = useState(false);
  const [impactFiles, setImpactFiles] = useState([]);
  const [fileRejected, setFileRejected] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const onSubmit = (e) => {
    if (!fileRejected) {
      //Insert Parent Impact
      let newValues = { ...impact };

      newValues.impactJobs = inputFields;
      newValues.impactOthers = otherFields;

      //Impact Jobs Section
      for (var i = 0; i < inputFields.length; i++) {
        let imageCounter = 0;
        let pdfCounter = 0;

        if (inputFields[i].files.length > 0) {
          for (var j = 0; j < inputFields[i].files.length; j++) {
            let file = inputFields[i].files[j];
            const formData = new FormData();

            //Rename File
            const newName = new Date().getTime() + "--" + file.name;
            formData.append("file", file, newName);

            //Upload File
            fetch("http://localhost:3000/upload", {
              method: "POST",
              body: formData,
            })
              .then((result) => {
                //console.log('File Sent Successfully');
              })
              .catch((err) => console.error(err));

            //Set Location Store Files
            var nowDate = new Date();
            var date =
              nowDate.getFullYear() +
              "--" +
              (nowDate.getMonth() + 1) +
              "--" +
              nowDate.getDate();

            if (file.type.includes("image/")) {
              imageCounter++;
              if (imageCounter == 1) {
                newValues.impactJobs[i].imagePath1 = newName;
              } else if (imageCounter == 2) {
                newValues.impactJobs[i].imagePath2 = newName;
              }
            }

            if (file.type.includes("application/pdf")) {
              pdfCounter++;
              if (pdfCounter == 1) {
                newValues.impactJobs[i].pdfPath1 = newName;
              } else if (pdfCounter == 2) {
                newValues.impactJobs[i].pdfPath2 = newName;
              }
            }
          }
        }
      }

      //Impact Other Sections
      //Impact Jobs Section
      for (var i = 0; i < otherFields.length; i++) {
        let imageCounter = 0;
        let pdfCounter = 0;

        if (otherFields[i].files.length > 0) {
          for (var j = 0; j < otherFields[i].files.length; j++) {
            let file = otherFields[i].files[j];
            const formData = new FormData();

            //Rename File
            const newName = new Date().getTime() + "--" + file.name;
            formData.append("file", file, newName);

            //Upload File
            fetch("http://localhost:3000/upload", {
              method: "POST",
              body: formData,
            })
              .then((result) => {
                //console.log('File Sent Successfully');
              })
              .catch((err) => console.error(err));

            //Set Location Store Files
            if (file.type.includes("image/")) {
              imageCounter++;
              if (imageCounter == 1) {
                newValues.impactOthers[i].imagePath1 = newName;
              } else if (imageCounter == 2) {
                newValues.impactOthers[i].imagePath2 = newName;
              }
            }

            if (file.type.includes("application/pdf")) {
              pdfCounter++;
              if (pdfCounter == 1) {
                newValues.impactOthers[i].pdfPath1 = newName;
              } else if (pdfCounter == 2) {
                newValues.impactOthers[i].pdfPath2 = newName;
              }
            }
          }
        }
      }

      console.log("Data Submit", newValues);
      history.push("/successprofilelist");
    } else {
      setNotify({
        isOpen: true,
        message: "Please Select Files Again",
        type: "warning",
      });
    }
  };

  const handleChangeInput = (index, e) => {
    const fieldValues = [...inputFields];
    fieldValues[index][e.target.name] = e.target.value;
    setInputFields(fieldValues);
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        jobID: 0,
        impactID: 0,
        impactName: "",
        selectValue: "",
        returnType: "",
        impactCost: 0,
        description: "",
        imagePath1: "",
        imagePath2: "",
        pdfPath1: "",
        pdfPath2: "",
        files: [],
      },
    ]);
  };

  const handleRemoveFields = (input) => {
    const fieldValues = [...inputFields];
    if (fieldValues.length > 1) {
      const newValues = fieldValues.filter((item) => item !== input);
      setInputFields(newValues);
    }
  };

  const handleChangeOtherInput = (index, e) => {
    const fieldValues = [...otherFields];
    fieldValues[index][e.target.name] = e.target.value;
    setOtherFields(fieldValues);
  };

  const handleAddOtherFields = () => {
    setOtherFields([
      ...otherFields,
      {
        otherID: 0,
        impactID: 0,
        impactOtherName: "",
        impactTo: "",
        descriptionOther: "",
        imagePath1: "",
        imagePath2: "",
        pdfPath1: "",
        pdfPath2: "",
        files: [],
      },
    ]);
  };

  const handleRemoveOtherFields = (input) => {
    const fieldValues = [...otherFields];
    if (fieldValues.length > 1) {
      const newValues = fieldValues.filter((item) => item !== input);
      setOtherFields(newValues);
    }
  };

  useEffect(() => {
    if (userProfile) {
      setEmployeeId(userProfile.idEmployees);
    }
  }, [userProfile]);

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserProfile(currentUser.username));
    }

    setImpact({
      ...impact,
      createBy: "Admin",
    });
  }, []);

  return (
    <ContainerStyled>
      <div className={classes.root}>
        <div className="card-section">
          <div className="header-section">
            <Grid container>
              <Grid item>
                <h3 className="header-topic">Success Profile</h3>
              </Grid>
            </Grid>
          </div>
          {/* employee */}
          {employeeId && <EmployeeInfo idEmployees={employeeId} />}
        </div>
        <div className={classes.cardPlan}>
          <Grid container>
            <Grid item>
              <Typography
                variant="h6"
                className={classes.header_section}
                gutterBottom
              >
                <BookmarkOutlinedIcon /> Impact To Job / To Team / To Company
              </Typography>
            </Grid>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            {inputFields.map((inputField, index) => (
              <div key={index}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <IconButton
                      aria-label="add-demand"
                      style={{
                        marginRight: 5,
                        backgroundColor: "#7dc581",
                        color: "#357a38",
                      }}
                    >
                      <AddIcon
                        onClick={() => {
                          handleAddFields();
                        }}
                      />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      style={{
                        marginLeft: 5,
                        backgroundColor: "#dd99b1",
                        color: "#ab003c",
                      }}
                    >
                      <DeleteIcon
                        onClick={() => handleRemoveFields(inputField)}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  style={{ marginTop: 20 }}
                >
                  <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ marginBottom: 20 }}
                      >
                        <Typography
                          style={{ fontSize: 17, fontWeight: "bold" }}
                        >
                          Main data
                        </Typography>
                        <Typography style={{ fontSize: 15 }}>
                          description of the impact to job / team / company
                        </Typography>
                      </Grid>
                      <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                        <TextField
                          label="Impact Name"
                          placeholder="Impact Name"
                          name="impactName"
                          sx={{ marginBottom: 2 }}
                          value={inputField.impactName}
                          onChange={(e) => handleChangeInput(index, e)}
                          {...register("impactName", { required: true })}
                          /*
                        error={errors.impactName ? true : false}
                        helperText={
                          errors.impactName ? "This field is required." : ""
                        }
                         */
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AutoAwesomeIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Grid container sx={{ marginBottom: 2 }} spacing={2}>
                          <Grid
                            item
                            xl={4}
                            lg={4}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{ paddingRight: 8 }}
                          >
                            <FormControl fullWidth>
                              <InputLabel>Select Value</InputLabel>
                              <Select
                                name="selectValue"
                                value={inputField.selectValue}
                                onChange={(e) => handleChangeInput(index, e)}
                                label="Select Value"
                              >
                                {selectValue.map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xl={4}
                            lg={4}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{ paddingRight: 8 }}
                          >
                            <FormControl fullWidth>
                              <InputLabel>Select Type</InputLabel>
                              <Select
                                name="returnType"
                                value={inputField.returnType}
                                label="Select Type"
                                onChange={(e) => handleChangeInput(index, e)}
                              >
                                {returnType.map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xl={4}
                            lg={4}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{ paddingRight: 8 }}
                          >
                            <TextField
                              name="impactCost"
                              placeholder="Enter Impact Cost"
                              value={
                                inputField.selectValue === "Indirect"
                                  ? 0
                                  : inputField.impactCost
                              }
                              disabled={
                                inputField.selectValue === "Indirect"
                                  ? true
                                  : false
                              }
                              onChange={(e) => handleChangeInput(index, e)}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xl={10}
                    lg={10}
                    md={10}
                    sm={10}
                    xs={10}
                    style={{ marginTop: 10 }}
                  >
                    <Grid container sx={{ marginBottom: 2 }}>
                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ marginBottom: 20 }}
                      >
                        <Typography
                          style={{ fontSize: 17, fontWeight: "bold" }}
                        >
                          Second data
                        </Typography>
                        <Typography style={{ fontSize: 15 }}>
                          detail of the impact to job / team / company
                        </Typography>
                      </Grid>
                      <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                        <TextField
                          name="description"
                          label="Description"
                          placeholder="Description"
                          value={inputField.description}
                          onChange={(e) => handleChangeInput(index, e)}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <DescriptionRoundedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <ImpactUploadFile
                  index={index}
                  inputFields={inputFields}
                  impactFiles={impactFiles}
                  setImpactFiles={setImpactFiles}
                  fileRejected={fileRejected}
                  setFileRejected={setFileRejected}
                />

                <hr className="hrTag" />
              </div>
            ))}

            {/* Impact to other */}
            <Grid container justifyContent="center">
              <Grid item xs>
                <Typography
                  variant="h6"
                  className={classes.header_section}
                  gutterBottom
                >
                  <BookmarkOutlinedIcon />
                  Impact To Other
                </Typography>
              </Grid>
            </Grid>
            {otherFields.map((otherField, index) => (
              <div key={index}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <IconButton
                      aria-label="add-demand"
                      style={{
                        marginRight: 5,
                        backgroundColor: "#7dc581",
                        color: "#357a38",
                      }}
                    >
                      <AddIcon onClick={() => handleAddOtherFields()} />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      style={{
                        marginLeft: 5,
                        backgroundColor: "#dd99b1",
                        color: "#ab003c",
                      }}
                    >
                      <DeleteIcon
                        onClick={() => handleRemoveOtherFields(otherField)}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  style={{ marginTop: 20 }}
                >
                  <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                    <Grid container>
                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ marginBottom: 20 }}
                      >
                        <Typography
                          style={{ fontSize: 17, fontWeight: "bold" }}
                        >
                          Main data
                        </Typography>
                        <Typography style={{ fontSize: 15 }}>
                          description of the impact to other
                        </Typography>
                      </Grid>
                      <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                        <TextField
                          label="Impact Other Name"
                          name="impactOtherName"
                          placeholder="Impact Other Name"
                          value={otherField.impactOtherName}
                          onChange={(e) => handleChangeOtherInput(index, e)}
                          {...register("impactOtherName", { required: true })}
                          /*
                        error={errors.impactOtherName ? true : false}
                        helperText={
                          errors.impactOtherName
                            ? "This field is required."
                            : ""
                        }
                         */
                          sx={{ marginBottom: 2 }}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AutoAwesomeIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          label="Impact To"
                          name="impactTo"
                          sx={{ marginBottom: 2 }}
                          placeholder="Impact To"
                          value={otherField.impactTo}
                          onChange={(e) => handleChangeOtherInput(index, e)}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AutoAwesomeIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xl={10}
                    lg={10}
                    md={10}
                    sm={10}
                    xs={10}
                    style={{ marginTop: 10 }}
                  >
                    <Grid container sx={{ marginBottom: 2 }}>
                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ marginBottom: 20 }}
                      >
                        <Typography
                          style={{ fontSize: 17, fontWeight: "bold" }}
                        >
                          Second data
                        </Typography>
                        <Typography style={{ fontSize: 15 }}>
                          detail of the impact to other
                        </Typography>
                      </Grid>
                      <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                        <TextField
                          label="Description Other"
                          name="descriptionOther"
                          placeholder="Description Other"
                          value={otherField.descriptionOther}
                          onChange={(e) => handleChangeOtherInput(index, e)}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <DescriptionRoundedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <ImpactUploadFile
                  index={index}
                  otherFields={otherFields}
                  impactFiles={impactFiles}
                  setImpactFiles={setImpactFiles}
                  fileRejected={fileRejected}
                  setFileRejected={setFileRejected}
                />

                <hr className="hrTag" />
              </div>
            ))}
            <div style={{ height: "50px" }}></div>
            {disabled ? null : (
              <Grid container>
                <Grid container justifyContent="flex-end" item xs>
                  <Button
                    variant="contained"
                    color="primary"
                    className="saveButton"
                    type="submit"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            )}
          </form>
        </div>
        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </ContainerStyled>
  );
}

export default SuccessProfile;
