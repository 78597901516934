import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import SettingsIcon from '@mui/icons-material/Settings';
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import FeedIcon from '@mui/icons-material/Feed';
import Person2Icon from '@mui/icons-material/Person2';
import DialogAddEdit from "./drawerAddEdit";
import DialogCreate from "./dialogCreate";
import DialogDelete from "./dialogDelete"
import { Controller, useForm } from "react-hook-form"
import {
  getAllProjectEmployees,
  getUsersInProject,
} from "../../../../actions/workforceEmployees";
import AlertResponse from "../../shared/general/AlertResponse";
import {
  Typography,
  Box,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  Popper,
  Divider,
  TableBody,
  Avatar,
  TableCell,
  FormControl,
  FilledInput,
  InputAdornment,
  Grid,
  Stack,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from "../../shared/general/Card";
import ToggleButton from "@mui/material/ToggleButton";
import DeleteIcon from '@mui/icons-material/Delete';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { getAllEmployees } from "../../../../actions/employee";

import HeaderPage from "./../../shared/header/headerPage";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { getUserFullName } from "../../../../utils/userData";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .header": {
    paddingTop: 20,
    paddingBottom: 30,
  },
  "& .wrap-add": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 16,
  },
  paddingBottom: "16px"
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderRadius: 8,
  marginBottom: 8,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .wrap": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 0,
  borderTop: "none",
  display: "flex",
  flexWrap: "wrap",
  "& .MuiBox-root": {
    width: "100%",
    margin: 8,
    marginBottom: 16,
  },
  "& .label": {
    fontWeight: 500,
  },
  "& .last-row": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .wrap-add": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 8,
  },
}));

const StyledEmpty = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 150,
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  margin: "auto",
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const contractList = [
  {
    projectname: "โปรเจก 1",
    startDate: "2023-01-01",
    endDate: "2023-01-16",
    detail: "Lorem Ipsum is simply dummy text...",
  },
  {
    projectname: "โปรเจก 2",
    startDate: "2023-01-01",
    endDate: "2023-01-30",
    detail: "Lorem Ipsum is simply dummy text...",
  },
  {
    projectname: "โปรเจก 3",
    startDate: "2023-01-01",
    endDate: "2023-02-20",
    detail: "Lorem Ipsum is simply dummy text...",
  },
  {
    projectname: "โปรเจก 4",
    startDate: "2023-01-01",
    endDate: "2023-02-10",
    detail: "Lorem Ipsum is simply dummy text...",
  },
];



const ProjectEmployee = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const { result: employees } = useSelector((state) => state.employees);
  const { result: projectGetUsers } = useSelector(
    (state) => state.projectGetUsers
  );
  const { result: AllProjectName } = useSelector((state) => state.tableHour);



  const { result: projectEmployees } = useSelector((state) => state.projectEmployees);

  const [searchProject, setSearchCProject] = React.useState({
    idProject: null,
    projectname: null,
  });
  const [expanded, setExpanded] = React.useState("");
  const [openDialogAdd, setOpenDialogAdd] = React.useState("");
  const [openDialogCreate, setOpenDialogCreate] = React.useState("");
  const [openDialogDelete, setOpenDialogDelete] = React.useState("");

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectItem, setSelectItem] = useState(null);
  const [month, setMonth] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setMonth(event.target.value);
  };
  const useHookForm = useForm({
    defaultValues: {
      name: "",
    },
    mode: "all",
  })
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {

    setFilteredData(projectEmployees);

  }, [projectEmployees]);
  const onSubmit = async (data) => {

    const searchData = {
      name: data.name ? data.name : null,
    }
    if (searchData.name) {
    
      const result = searchData.name ? projectEmployees.filter(item => item.name.includes(searchData.name)) : filteredData;
      setFilteredData(result)
    } else {

      setFilteredData(projectEmployees);
    }



  }

  const handleChangeSearch = (event) => {
    const { name, value } = event.target;
    setSearchCProject({
      ...searchProject,
      ["projectname"]: value,
    });
  };

  const handleCloseDialog = () => {
    setOpenDialogAdd(false);
  };
  const [IDWorkforceProject, setIDWorkforceProject] = useState(0)
  const [IDemp, setIDemp] = useState(0)
  const handleOpenDialogDelete = (idWorkforceProject, idEmployees) => {
    setOpenDialogDelete(true);
    setIDemp(idEmployees)
    setIDWorkforceProject(idWorkforceProject)
  };
  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
    setIDemp('')
    setIDWorkforceProject('')
  };



  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: null,
  });

  const onDrawerClose = () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };
  const [projectid, setProjectid] = useState(null)
  const [projectname, setProjectname] = useState('')
  const [checkid, setCheckid] = useState([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const handleClickOpen = (projectid, projectname, startDate, endDate) => {
    setProjectid(projectid)
    setProjectname(projectname)
    setStartDate(startDate)
    setEndDate(endDate)
    setOpen(true);
    setOpenDialogCreate(true);
    const checkidsame = projectGetUsers.filter((data) => data.idWorkforceProject === projectid).map((data) => data.idEmployees);
    setCheckid(checkidsame)
  };
  const handleClose = () => {
    setOpen(false);
    setSelectItem(null);
  };
  const handleClickOpenEdit = (item) => {
    setOpen(true);
    setSelectItem(item);
  };
 
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleSubmitProject = async (result) => {
    handleCloseDialog();
    if (result) {
      handleOpenAlert();
      if (result.status === 200) {
        dispatch(getAllProjectEmployees());
        handleChangeAlertType("success");
      } else {
        handleChangeAlertType("error");
      }
    } else {
      handleChangeAlertType("error");
    }
  };

  const StyledAvatar = styled(Avatar)({
    width: 50,
    height: 50,
    marginRight: 8,
  });

  const [itemProject,setItemProject] = useState([])
  const [mode,setMode] = useState('')
  const addeditProject = (data,mode)=>{
    setItemProject(data)
    setMode(mode)
    setOpenDialogAdd(true)
  }

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getAllProjectEmployees());
    dispatch(getUsersInProject());
  }, []);
  useEffect(() => {

    dispatch(getAllProjectEmployees());

  }, []);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const openDetailHourProject = () => {
    history.push('workforce-planning-cal/tableHour')
  }


  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div className="header">
          <HeaderPage textLabel={t("projectEmployeeList")} />
        </div>
        <div
          style={{
            marginBottom: 16,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
                <Controller
                  name="name"
                  control={useHookForm.control}
                  render={({ field, fieldState }) => (
                    <FormControl fullWidth variant="filled">
                      <StyledFilledInput
                        {...field}
                        // placeholder={`${t("Search")}${t("FullName")}`}
                        placeholder={t("searchProjectName")}
                        name="search"
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  )}
                /></form>
            </Grid>

            <Grid sx={{ display: "flex", justifyContent: "flex-end" }} item xs={12} sm={6} md={6}>
              {/* <div
            style={{  display: "flex", 
            // alignItems: "flex-end"
           }}
          > */}
              

              <ButtonBlue
                variant="contained"
                startIcon={<FeedIcon />}
                onClick={openDetailHourProject}
                style={{ marginRight: 16 }}
              >{t("checkDetail")}
                {/* {t("AddEmployee")} */}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                startIcon={<AddRoundedIcon />}
                onClick={() => addeditProject('','add')}
              >
                {/* {t("AddProjectEmployee")} */}
                {t("Add")} {t("Project")}
              </ButtonBlue>
              {/* </div> */}
            </Grid>
          </Grid >
        </div>

        {/* AllProjectName */}
        {filteredData &&
          filteredData.length > 0 ? (


          <div>
            {filteredData.map((item, index) => {
              const sum = projectGetUsers && projectGetUsers.filter(user => user.idWorkforceProject === item.idWorkforceProject).length
              return (
                <Accordion
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }} className="wrap">
                      <Typography style={{ flex: 2, textAlign: 'left' }}>{`${item.name}`}</Typography>
                      <div style={{ flex: 3, textAlign: 'left' }}>{dayjs(item.start,'"YYYY/MM/DD"').format("D MMM BBBB")} {t("To")} {item.haveEnd?dayjs(item.end,'"YYYY/MM/DD"').format("D MMM BBBB"):t("endDateNotSet")}</div>
                      <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Person2Icon /> {sum}
                        </div>
                        <div>
                          <Typography>
                            <ButtonBlue onClick={() => handleOpenDialogDelete(item.idWorkforceProject,0)} style={{ color: 'red' }}>
                              <DeleteIcon />
                            </ButtonBlue>
                          </Typography>
                        </div>
                      </div>
                    </div>


                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ alignItems: "center" }}>
                      <div style={{ alignItems: "center" }}>
                        <div>
                          <Typography
                            className="label"
                            color="text.secondary"
                            variant="body2"
                            sx={{ marginBottom: '10px' }}
                          >
                            {t("Detail")}
                          </Typography>
                          <TextFieldTheme
                            InputProps={{
                              readOnly: true,
                            }}
                            defaultValue={item.detail}
                            multiline
                            rows={5}
                            sx={{ width: '100%' }}
                          // {...params}
                          // label="รายละเอียด"
                          // helperText={useHookForm.formState.errors[field.name] ? useHookForm.formState.errors[field.name].message : null}
                          // error={useHookForm.formState.errors[field.name] ? true : false}
                          />
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography
                              sx={{ marginTop: '5px' }}
                              className="label"
                            // color="text.secondary"

                            >
                              {t("EmployeeName")}
                            </Typography>
                            <ButtonBlue onClick={() => handleClickOpen(item.idWorkforceProject, item.name, item.start, item.end)}><AddIcon /></ButtonBlue></div>
                          <Grid container spacing={2}>
                            {projectGetUsers && projectGetUsers.filter(user => user.idWorkforceProject === item.idWorkforceProject).map((userItem, index) => (
                              <Grid item xl={4} md={4} lg={4} key={index} sx={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Avatar
                                    src={userItem.imageProfile}
                                    style={{ marginRight: "12px" }}
                                  />
                                  <Typography variant="body2" style={{ marginRight: "12px" }}>
                                    {getUserFullName(userItem)}
                                  </Typography>
                                </div>
                                <ButtonBlue style={{ color: "red" }} onClick={() => handleOpenDialogDelete(item.idWorkforceProject, userItem.idEmployees)}>
                                  <DeleteIcon />
                                </ButtonBlue>
                              </Grid>
                            ))}
                          </Grid>
                        </div>

                      </div>
                    </Box>
                  </AccordionDetails>

                </Accordion>)

            })}

          </div>

        ) : (
          <StyledEmpty className="empty-holiday">
            <Typography color="text.secondary" align="center">
              {t("NoData")}
            </Typography>
          </StyledEmpty>
        )}

        {employees && open && (
          <DialogCreate
            open={openDialogCreate}
            handleClose={handleClose}
            data={selectItem}
            projectid={projectid}
            projectname={projectname}
            checkidEmpSame={checkid}
            startDate={startDate}
            endDate={endDate}
          />
        )}
        <AlertResponse
          open={openAlert}
          handleClose={handleCloseAlert}
          alertType={alertType}
        />
        <DialogAddEdit
          open={openDialogAdd}
          handleCloseDialog={handleCloseDialog}
          mode={mode}
          handleSubmitProject={handleSubmitProject}
          data={itemProject}
        />
        {openDialogDelete && (

          <DialogDelete
            open={openDialogDelete}
            handleCloseDialog={handleOpenDialogDelete}
            handleCloseDialogDelete={handleCloseDialogDelete}
            IDWorkforceProject={IDWorkforceProject}
            IDemp={IDemp}
          />
        )}

      </Container>
    </StyledRoot >
  );
};

export default ProjectEmployee;
