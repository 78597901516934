import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Container,
  Grid,
  Popper,
  TextField,
  Typography,
  autocompleteClasses,
  styled,
  Card,
  Select,
  MenuItem,
  Stack,
  SvgIcon,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  getAllIndividualObjectiveByIdEmp,
  getAllIndividualObjectiveForAllEmployees,
  getTimelineUpdatingKeyresultById,
} from "../../../../../actions/okrs";
import { getAffiliateOrganization } from "../../../../../actions/company";
import { getAllEmployees } from "../../../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import CardObjective from "./cardObjective";
import { useTranslation } from "react-i18next";
import {
  getUserCompany,
  getUserDepartment,
  getUserDivision,
  getUserFullName,
  getUserPosition,
  getUserSection,
} from "../../../../../utils/userData";
import dayjs from "dayjs";
import { ReactComponent as MoonshotIcon } from "../../../assets/moonshot_logo.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StarRateIcon from "@mui/icons-material/StarRate";
import exportToExcel from "./exportToExcel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TimelineCard from "./timeline";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
    "& .MuiOutlinedInput-input": {
      padding: "16px 14px",
    },
    "& .MuiTableCell-root": {
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
}));

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledRoot = styled(Box)({
  "& .keyresult-text": {
    fontSize: "20px",
    fontWeight: 500,
  },
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const StyledCard = styled(Card)({
  minHeight: "70px",
  boxShadow: "none",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  background:
    "linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))",
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
});

const StyleHeadBox = styled(Box)({
  "& .box-header": {
    minHeight: "200px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor:
      "linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))",
    "& .text-header": {
      fontSize: "48px !important",
      fontWeight: "bold !important",
      color: "#000",
      fontFamily:
        "Poppins, Prompt, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important",
    },
    "& .img-header": {
      objectFit: "contain",
    },
  },
  "@media (max-width: 600px)": {
    "& .box-header": {
      flexDirection: "column",
      minHeight: "unset",
      justifyContent: "center",
      alignItems: "center",
      "& .text-header": {
        fontSize: "36px !important",
        marginTop: "20px",
        textAlign: "center",
      },
    },
  },
});

const StyleText = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  marginBottom: "16px",
  "& .name-text": {
    fontSize: "20px",
    marginTop: "10px",
    fontWeight: "500",
  },
});

const ContainerStyled = styled(Container)({
  paddingTop: "10px",
  height: "100%",
  minHeight: "100vh",
});

const StyledRating = styled(Box)(({ backgroundColor }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "8px",
  padding: "16px",
  borderRadius: "16px",
  maxWidth: "160px",
  backgroundColor,
}));

function OKRStatus() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const [selectYear, setSelectYear] = useState(dayjs().get("year"));
  const [individualObjective, setIndividualObjective] = useState(null);

  const { result: employeesList } = useSelector((state) => state.employees);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );

  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const [divisions, setDivisions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [sections, setSections] = useState([]);
  const [positions, setPositions] = useState([]);

  const [timeline, setTimeline] = useState(null);

  useEffect(() => {
    if (employeesList) {
      const divisionSet = new Set();
      const departmentSet = new Set();
      const sectionSet = new Set();
      const positionSet = new Set();

      employeesList.forEach((employee) => {
        if (employee.divisionName)
          divisionSet.add(employee.divisionName.trim());
        if (employee.departmentName)
          departmentSet.add(employee.departmentName.trim());
        if (employee.sectionName) sectionSet.add(employee.sectionName.trim());
        if (employee.positionName)
          positionSet.add(employee.positionName.trim());
      });

      setDivisions([...divisionSet]);
      setDepartments([...departmentSet]);
      setSections([...sectionSet]);
      setPositions([...positionSet]);
    }
  }, [employeesList]);

  useEffect(() => {
    if (employeesList) {
      let filtered = employeesList;

      if (selectedCompany) {
        filtered = filtered.filter(
          (employee) =>
            employee.companyName &&
            employee.companyName.trim().toLowerCase() ===
              selectedCompany.companyName.trim().toLowerCase()
        );
      }

      if (selectedDivision) {
        filtered = filtered.filter(
          (employee) =>
            employee.divisionName &&
            employee.divisionName.trim().toLowerCase() ===
              selectedDivision.trim().toLowerCase()
        );
      }

      if (selectedDepartment) {
        filtered = filtered.filter(
          (employee) =>
            employee.departmentName &&
            employee.departmentName.trim().toLowerCase() ===
              selectedDepartment.trim().toLowerCase()
        );
      }

      if (selectedSection) {
        filtered = filtered.filter(
          (employee) =>
            employee.sectionName &&
            employee.sectionName.trim().toLowerCase() ===
              selectedSection.trim().toLowerCase()
        );
      }

      if (selectedPosition) {
        filtered = filtered.filter(
          (employee) =>
            employee.positionName &&
            employee.positionName.trim().toLowerCase() ===
              selectedPosition.trim().toLowerCase()
        );
      }

      setFilteredEmployees(filtered);
      setSelectedEmployee(null);
    }
  }, [
    employeesList,
    selectedCompany,
    selectedDivision,
    selectedDepartment,
    selectedSection,
    selectedPosition,
  ]);

  const ratingInYear =
    individualObjective &&
    individualObjective.reduce(
      (acc, objective) => {
        if (objective.year === selectYear) {
          const k = objective.keyResults.reduce(
            (acc, keyresult) => {
              let xRating = 0;

              if (keyresult.isMoonShotPlanning) {
                xRating += 1.5;
                acc.numberOfMoonShot += 1;
              }
              if (keyresult.isMoonShotEvaluation) {
                xRating += 1.5;
                acc.numberOfMoonShot += 1;
              }

              if (keyresult.isSuccess) {
                acc.numberOfSuccess += 1;
              }

              if (keyresult.managerRating !== null) {
                acc.managerRating +=
                  xRating > 0
                    ? keyresult.managerRating * xRating
                    : keyresult.managerRating;
                acc.numberOfRating += 1;
              }

              return acc;
            },
            {
              numberOfMoonShot: 0,
              managerRating: 0,
              numberOfSuccess: 0,
              numberOfKeyResult: 0,
              numberOfRating: 0,
            }
          );

          acc.totalMoonShot += k.numberOfMoonShot;
          acc.totalRating += k.managerRating;
          acc.totalSuccess += k.numberOfSuccess;
          acc.totalKeyResult += objective.keyResults.length;
          acc.totalNumberOfRating += k.numberOfRating;
        }
        return acc;
      },
      {
        totalMoonShot: 0,
        totalRating: 0,
        totalSuccess: 0,
        totalKeyResult: 0,
        totalNumberOfRating: 0,
      }
    );

  const handleChageEmployee = async (employee) => {
    if (employee === null) {
      resetEmployeeSelection();
    } else {
      dispatch(getAllIndividualObjectiveByIdEmp(employee.idEmployees)).then(
        (res) => {
          if (res.status === 200) {
            setIndividualObjective(res.data);
            setSelectedEmployee(employee);
          }
        }
      );
      dispatch(getTimelineUpdatingKeyresultById(employee.idEmployees)).then(
        (res) => {
          if (res.status === 200) {
            setTimeline(res.data);
          }
        }
      );
      setSelectedEmployee(employee);
    }
  };

  const handleChange = (event) => {
    setSelectYear(event.target.value);
  };

  const resetEmployeeSelection = () => {
    setSelectedEmployee(null);
    setIndividualObjective(null);
    setTimeline(null);
  };

  const handleCompanyChange = (event, newValue) => {
    setSelectedCompany(newValue);
  };

  useEffect(() => {
    dispatch(getAffiliateOrganization());
    dispatch(getAllEmployees());
    dispatch(getAllIndividualObjectiveForAllEmployees());
  }, [dispatch]);

  const CustomButton = styled(Button)({
    color: "#DB4178",
    border: "2px solid #DB4178",
    backgroundColor: "transparent",
    borderRadius: "7px",
    height: "50px",
    "&:hover": {
      color: "#DB4178",
      border: "2px solid #C53B6C",
      backgroundColor: "transparent",
      borderRadius: "7px",
      height: "50px",
    },
  });

  const handleExport = () => {
    if (individualObjective && individualObjective.length > 0) {
      exportToExcel(individualObjective, t);
    } else {
      alert(t("NoDataToExport"));
    }
  };

  const CustomContainedButton = styled(Button)({
    backgroundColor: "#DB4178",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#C53B6C",
    },
    borderRadius: "7px",
    height: "50px",
    border: "none",
  });

  const DetailsCard = ({ title, value, icon, backgroundColor }) => (
    <Card
      sx={{
        flex: 1,
        padding: 2,
        borderRadius: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: backgroundColor,
      }}
    >
      <Box>
        <Typography variant="subtitle1" color="text.secondary">
          {title}
        </Typography>
        <Typography variant="h6">{value}</Typography>
      </Box>
      <SvgIcon>{icon}</SvgIcon>
    </Card>
  );
  const safeTimeline = Array.isArray(timeline) ? timeline : [];

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {employeesList && (
          <StyledCard
            style={{
              marginBottom: "48px",
              marginTop: "20px",
            }}
          >
            <StyleHeadBox>
              <Box className="box-header">
                <Grid
                  container
                  pt={"40px"}
                  pl={{ xs: "16px", sm: "40px" }}
                  pb={{ xs: "0px", sm: "40px" }}
                >
                  <Grid item xs={12}>
                    <Typography className="text-header">
                      {`${t("ProgressStatus")}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} display="flex" gap="8px" flexWrap="wrap">
                    <div>
                      <Typography
                        fontSize="14px"
                        fontWeight="600"
                        marginBottom="4px"
                        color="text.third"
                      >
                        {t("SelectCompany")}
                      </Typography>
                      <StyledAutocomplete
                        style={{
                          width: "300px",
                          background: "#fff",
                          border: "none",
                          borderRadius: "8px",
                        }}
                        options={affiliateOrganizationList}
                        onChange={handleCompanyChange}
                        popupIcon={<i className="fa-light fa-chevron-down"></i>}
                        getOptionLabel={(option) => `${option.companyName}`}
                        renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {`${option.companyName}`}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            placeholder={`${t("SelectCompany")}`}
                          />
                        )}
                        PopperComponent={StyledPopper}
                        noOptionsText={`${t("NoData")}`}
                      />
                    </div>

                    <div>
                      <Typography
                        fontSize="14px"
                        fontWeight="600"
                        marginBottom="4px"
                        color="text.third"
                      >
                        {t("Employee")}
                      </Typography>
                      <StyledAutocomplete
                        style={{
                          width: "240px",
                          background: "#fff",
                          border: "none",
                          borderRadius: "8px",
                        }}
                        options={filteredEmployees}
                        onChange={(event, newValue) =>
                          handleChageEmployee(newValue)
                        }
                        popupIcon={<i className="fa-light fa-chevron-down"></i>}
                        getOptionLabel={(option) =>
                          `${option.firstname_TH} ${option.lastname_TH}`
                        }
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.idEmployees}>
                              {`${option.firstname_TH} ${option.lastname_TH}`}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            placeholder={`${t("SelectEmp")}`}
                          />
                        )}
                        PopperComponent={StyledPopper}
                        noOptionsText={`${t("NoData")}`}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} mt={2}>
                    <Button
                      variant="text"
                      onClick={() =>
                        setShowAdditionalFilters(!showAdditionalFilters)
                      }
                      sx={{ textTransform: "none", color: "#DB4178" }}
                    >
                      {showAdditionalFilters
                        ? t("HideSearch")
                        : t("SearchMore")}
                    </Button>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    display="flex"
                    gap="8px"
                    flexWrap="wrap"
                    mt={1}
                  >
                    {showAdditionalFilters && (
                      <>
                        <div>
                          <Typography
                            fontSize="14px"
                            fontWeight="600"
                            marginBottom="4px"
                            color="text.third"
                          >
                            {t("Division")}
                          </Typography>
                          <StyledAutocomplete
                            style={{
                              width: "240px",
                              background: "#fff",
                              border: "none",
                              borderRadius: "8px",
                            }}
                            options={divisions}
                            onChange={(event, newValue) =>
                              setSelectedDivision(newValue)
                            }
                            popupIcon={
                              <i className="fa-light fa-chevron-down"></i>
                            }
                            getOptionLabel={(option) => option}
                            renderOption={(props, option) => (
                              <li {...props} key={option}>
                                {option}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                placeholder={`${t("SelectDivision")}`}
                              />
                            )}
                            PopperComponent={StyledPopper}
                            noOptionsText={`${t("NoData")}`}
                          />
                        </div>

                        <div>
                          <Typography
                            fontSize="14px"
                            fontWeight="600"
                            marginBottom="4px"
                            color="text.third"
                          >
                            {t("Department")}
                          </Typography>
                          <StyledAutocomplete
                            style={{
                              width: "240px",
                              background: "#fff",
                              border: "none",
                              borderRadius: "8px",
                            }}
                            options={departments}
                            onChange={(event, newValue) =>
                              setSelectedDepartment(newValue)
                            }
                            popupIcon={
                              <i className="fa-light fa-chevron-down"></i>
                            }
                            getOptionLabel={(option) => option}
                            renderOption={(props, option) => (
                              <li {...props} key={option}>
                                {option}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                placeholder={`${t("SelectDepartment")}`}
                              />
                            )}
                            PopperComponent={StyledPopper}
                            noOptionsText={`${t("NoData")}`}
                          />
                        </div>

                        <div>
                          <Typography
                            fontSize="14px"
                            fontWeight="600"
                            marginBottom="4px"
                            color="text.third"
                          >
                            {t("Section")}
                          </Typography>
                          <StyledAutocomplete
                            style={{
                              width: "240px",
                              background: "#fff",
                              border: "none",
                              borderRadius: "8px",
                            }}
                            options={sections}
                            onChange={(event, newValue) =>
                              setSelectedSection(newValue)
                            }
                            popupIcon={
                              <i className="fa-light fa-chevron-down"></i>
                            }
                            getOptionLabel={(option) => option}
                            renderOption={(props, option) => (
                              <li {...props} key={option}>
                                {option}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                placeholder={`${t("SelectSection")}`}
                              />
                            )}
                            PopperComponent={StyledPopper}
                            noOptionsText={`${t("NoData")}`}
                          />
                        </div>

                        <div>
                          <Typography
                            fontSize="14px"
                            fontWeight="600"
                            marginBottom="4px"
                            color="text.third"
                          >
                            {t("Position")}
                          </Typography>
                          <StyledAutocomplete
                            style={{
                              width: "240px",
                              background: "#fff",
                              border: "none",
                              borderRadius: "8px",
                            }}
                            options={positions}
                            onChange={(event, newValue) =>
                              setSelectedPosition(newValue)
                            }
                            popupIcon={
                              <i className="fa-light fa-chevron-down"></i>
                            }
                            getOptionLabel={(option) => option}
                            renderOption={(props, option) => (
                              <li {...props} key={option}>
                                {option}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                placeholder={`${t("SelectPosition")}`}
                              />
                            )}
                            PopperComponent={StyledPopper}
                            noOptionsText={`${t("NoData")}`}
                          />
                        </div>
                      </>
                    )}
                  </Grid>
                </Grid>
                <img
                  className="img-header"
                  src={`${process.env.PUBLIC_URL}/assets/okrs/admin/progress-status.png`}
                  alt="icon"
                />
              </Box>
            </StyleHeadBox>
          </StyledCard>
        )}

        {!selectedEmployee ? (
          <Grid container spacing={2} marginBottom={4}>
            {filteredEmployees &&
              filteredEmployees.map((employee) => (
                <Grid item xs={12} sm={6} md={4} key={employee.idEmployees}>
                  <StyledCard
                    onClick={() => handleChageEmployee(employee)}
                    style={{ cursor: "pointer" }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      padding="16px"
                      bgcolor="#ffffff"
                      borderRadius="20px"
                    >
                      <StyleText>
                        <Typography className="name-text">
                          {getUserFullName({
                            firstname_TH: employee.firstname_TH,
                            lastname_TH: employee.lastname_TH,
                            firstname_EN: employee.firstname_EN,
                            lastname_EN: employee.lastname_EN,
                          })}
                        </Typography>
                        <Typography fontSize="15px" color="text.secondary">
                          {getUserPosition({
                            positionName: employee.positionName,
                            positionName_EN: employee.positionName_EN,
                          })}
                        </Typography>
                        <Typography fontSize="15px" color="text.secondary">
                          {getUserCompany({
                            companyName: employee.companyName,
                            companyName_EN: employee.companyName_EN,
                          })}
                        </Typography>
                      </StyleText>
                      <Avatar
                        src={employee.imageProfile}
                        alt={"imageProfile"}
                        style={{ width: 50, height: 50 }}
                      />
                    </Box>
                  </StyledCard>
                </Grid>
              ))}
          </Grid>
        ) : (
          <ContainerStyled>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Button
                onClick={resetEmployeeSelection}
                style={{
                  color: "#DB4178",
                  border: "2px solid #DB4178",
                  backgroundColor: "transparent",
                  borderRadius: "7px",
                  height: "50px",
                }}
                variant="outlined"
              >
                {t("Back")}
              </Button>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexGrow={1}
                ml={2}
              >
                <Box display="flex" alignItems="center">
                  {selectedEmployee && (
                    <Avatar
                      src={selectedEmployee.imageProfile}
                      alt="Profile"
                      sx={{ width: 80, height: 80, mr: 2 }}
                    />
                  )}
                  <Typography variant="h6">
                    {selectedEmployee
                      ? `${t("GoalOf")} ${getUserFullName({
                          firstname_TH: selectedEmployee.firstname_TH,
                          lastname_TH: selectedEmployee.lastname_TH,
                          firstname_EN: selectedEmployee.firstname_EN,
                          lastname_EN: selectedEmployee.lastname_EN,
                        })}`
                      : `${t("GoalOf")}`}
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center">
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                    mr={2}
                  >
                    <Select
                      value={selectYear}
                      onChange={handleChange}
                      sx={{
                        borderRadius: "10px",
                        backgroundColor: "#ffffff",
                        marginTop: "8px",
                        minWidth: "120px",
                      }}
                    >
                      {[0, 1, 2].map((item) => (
                        <MenuItem key={item} value={dayjs().get("year") - item}>
                          {dayjs()
                            .subtract(item, "year")
                            .format(
                              i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"
                            )}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>

                  <CustomContainedButton
                    variant="contained"
                    onClick={handleExport}
                    startIcon={<i className="fa-light fa-file-excel"></i>}
                  >
                    {t("ExportToExcel")}
                  </CustomContainedButton>
                </Box>
              </Box>
            </Box>

            {individualObjective && individualObjective.length > 0 ? (
              <Box display="flex" flexDirection="column" gap={2} mb={4}>
                <Box display="flex" gap={2}>
                  <DetailsCard
                    title="Moonshot"
                    value={ratingInYear.totalMoonShot}
                    icon={<MoonshotIcon />}
                    backgroundColor="#dfe8ff"
                  />
                  <DetailsCard
                    title="Percent"
                    value={
                      ratingInYear.totalKeyResult
                        ? `${(
                            (ratingInYear.totalSuccess /
                              ratingInYear.totalKeyResult) *
                            100
                          ).toFixed(2)}%`
                        : "0.00%"
                    }
                    icon={<CheckCircleIcon />}
                    backgroundColor="#d4ffce"
                  />
                  <DetailsCard
                    title="Rating"
                    value={
                      ratingInYear.totalNumberOfRating > 0
                        ? parseFloat(
                            (
                              ratingInYear.totalRating /
                              ratingInYear.totalNumberOfRating
                            ).toFixed(1)
                          )
                        : 0
                    }
                    icon={<StarRateIcon />}
                    backgroundColor="#fff9db"
                  />
                </Box>

                <Accordion
                  sx={{
                    borderRadius: "10px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    overflow: "hidden",
                    marginBottom: "16px",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<KeyboardArrowDownIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      borderBottom: "1px solid #e0e0e0",
                      padding: "16px 24px",
                      borderRadius: "10px",
                      "& .MuiAccordionSummary-content": {
                        alignItems: "center",
                      },
                    }}
                  >
                    <Typography variant="h6">{t("OKR Goals")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {individualObjective
                        .filter((item) => item.year === selectYear)
                        .map((objective, index) => (
                          <Grid item xs={12} key={index}>
                            <CardObjective data={objective} />
                          </Grid>
                        ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    borderRadius: "10px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    overflow: "hidden",
                    marginBottom: "16px",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<KeyboardArrowDownIcon />}
                    aria-controls="timeline-content"
                    id="timeline-header"
                    sx={{
                      borderBottom: "1px solid #e0e0e0",
                      padding: "16px 24px",
                      borderRadius: "10px",
                      "& .MuiAccordionSummary-content": {
                        alignItems: "center",
                      },
                    }}
                  >
                    <Typography variant="h6">{t("Timeline")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: "block" }}>
                    <Grid container spacing={2}>
                      {safeTimeline && safeTimeline.length > 0 ? (
                        safeTimeline.map((item, index) => (
                          <Grid
                            item
                            xs={12}
                            key={`${item.idIndividualObjective}-${index}`}
                          >
                            <TimelineCard data={item} />
                          </Grid>
                        ))
                      ) : (
                        <Box
                          display="flex"
                          justifyContent="center"
                          paddingTop="24px"
                        >
                          <Typography>{`${t("NoItems")}`}</Typography>
                        </Box>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
            ) : (
              individualObjective && (
                <Box display="flex" justifyContent="center" paddingTop="24px">
                  <Typography>{`${t("NoItems")}`}</Typography>
                </Box>
              )
            )}
          </ContainerStyled>
        )}
      </Container>
    </StyledRoot>
  );
}

export default OKRStatus;
