import React from "react";
import ListMenu from "../../../pages/shared/listMenu";

import DashboardIcon from "@mui/icons-material/Dashboard";
import Filter1Icon from "@mui/icons-material/Filter1";
import Filter2Icon from "@mui/icons-material/Filter2";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BrightnessMediumIcon from "@mui/icons-material/BrightnessMedium";
import SettingsIcon from "@mui/icons-material/Settings";
import BoltIcon from "@mui/icons-material/Bolt";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import GroupIcon from "@mui/icons-material/Group";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CardTravelRoundedIcon from "@mui/icons-material/CardTravelRounded";
import BusinessIcon from "@mui/icons-material/Business";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import TaskIcon from "@mui/icons-material/Task";
import SummarizeIcon from "@mui/icons-material/Summarize";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";

import {
  Article,
  Bloodtype,
  GroupWork,
  Healing,
  HealthAndSafety,
  LocalHospital,
  ManageSearch,
  RequestPage,
  Warehouse
} from "@mui/icons-material";


const AdminMenu = () => {
  const dataListMenu = [
    {
      listItemIcon: <HomeOutlinedIcon />,
      listItemText: "หน้าแรก",
      listLink: "/home",
      listKey: "home",
    },
    {
      listItemIcon: <BubbleChartIcon />,
      listItemText: "มุมมองภาพรวม",
      listLink: "/admin/overview",
      listKey: "overview",
    },
    {
      listItemIcon: <ListAltRoundedIcon />,
      listItemText: "สถานะรายการ",
      listLink: "/admin/approve-list",
      listKey: "admin-approve-list",
    },
    {
      listItemIcon: <DashboardIcon />,
      listItemText: "Workforce",
      listLink: "",
      listKey: "Workforce",
      collapse: [
        {
          listItemIcon: <DashboardIcon />,
          listItemText: "Workforce Dashboard",
          listLink: "/admin/workforceDashboard",
          listKey: "dashboard Workforce",
        },
        {
          listItemIcon: <AssignmentTurnedInIcon />,
          listItemText: "Demand Analysis",
          listLink: "/admin/manageDemand",
          listKey: "demand analysis",
        },
        {
          listItemIcon: <AssignmentIcon />,
          listItemText: "Project profile",
          listLink: "/project",
          listKey: "project",
        },
        {
          listItemIcon: <PeopleAltIcon />,
          listItemText: "Team",
          listLink: "/admin/memberInTeam",
          listKey: "member",
        },
      ],
    },
    {
      listItemIcon: <i className="fad fa-sitemap"></i>,
      listItemText: "Organization",
      listLink: "",
      listKey: "organization",
      collapse: [
        {
          listItemIcon: <i className="far fa-sitemap"></i>,
          listItemText: "Organization",
          listLink: "/admin/organization",
          listKey: "generalorganization",
        },
        {
          listItemIcon: <i className="fal fa-project-diagram"></i>,
          listItemText: "Organization Project",
          listLink: "/admin/project-list",
          listKey: "projectorganization",
        },
      ],
    },
    {
      listItemIcon: <BoltIcon />,
      listItemText: "แผนกำลังพล",
      listLink: "/admin/manpower",
      listKey: "manpower",
    },
    {
      listItemIcon: <CoPresentIcon />,
      listItemText: "แนะนำ Vendor",
      listLink: "/admin/vendor-suggestion",
      listKey: "vendorsuggestion",
    },
    {
      listItemIcon: <PersonAddIcon />,
      listItemText: "ขอกำลังพล",
      listLink: "/admin/request",
      listKey: "request",
    },
    {
      listItemIcon: <FolderSharedIcon />,
      listItemText: "ข้อมูลพนักงาน",
      listLink: "",
      listKey: "coredata",
      collapse: [
        {
          listItemIcon: <GroupIcon />,
          listItemText: "รายชื่อพนักงาน",
          listLink: "/employees",
          // listLink: "/admin/employees",
          listKey: "employeeslist",
        },
        {
          listItemIcon: <CompareArrowsIcon />,
          listItemText: "Employee Compare",
          listLink: "/admin/ComparePerson",
          listKey: "employeeCompare",
        },
      ],
    },
    {
      listItemIcon: <CardTravelRoundedIcon />,
      listItemText: "Job",
      listLink: "",
      listKey: "job",
      collapse: [
        {
          listItemIcon: <BusinessCenterIcon />,
          listItemText: "Job Structure",
          listLink: "/admin/jobStructure",
          listKey: "Structure",
        },
        {
          listItemIcon: <BusinessIcon />,
          listItemText: "All Position",
          listLink: "/admin/showAllPosition",
          listKey: "position",
        },
        {
          listItemIcon: <TaskIcon />,
          listItemText: "Job Task",
          listLink: "/admin/showTask",
          listKey: "task",
        },
        {
          listItemIcon: <SupervisorAccountIcon />,
          listItemText: "Job level analysis",
          listLink: "/admin/jobClassification",
          listKey: "Job Classification",
        },
        {
          listItemIcon: <SummarizeIcon />,
          listItemText: "Job level creation",
          listLink: "/admin/addJobLevel",
          listKey: "jobLevel",
        },
      ],
    },
    // {
    //   listItemIcon: <ContactPageIcon />,
    //   listItemText: "เอกสารพนักงาน",
    //   listLink: "/admin/document-employee",
    //   listKey: "documentEmployee",
    // },
    // {
    //   listItemIcon: <DashboardIcon />,
    //   listItemText: "ภาพรวม",
    //   listLink: "",
    //   listKey: "dashboard",
    //   collapse: [
    //     {
    //       listItemIcon: <Filter1Icon />,
    //       listItemText: "ข้อมูลค่าล่วงเวลา",
    //       listLink: "/admin/dashboard/ot",
    //       listKey: "overtime",
    //     },
    //     {
    //       listItemIcon: <Filter2Icon />,
    //       listItemText: "ภาพรวมเวลาทำงาน",
    //       listLink: "/admin/dashboard/workingtime",
    //       listKey: "workingtime",
    //     },
    //     {
    //       listItemIcon: <Filter2Icon />,
    //       listItemText: "ภาพรวมค่าใช้จ่าย",
    //       listLink: "/admin/dashboard/cost",
    //       listKey: "cost",
    //     },
    //   ],
    // },
    {
      listItemIcon: <TrendingUpIcon />,
      listItemText: "ตรวจสอบค่าล่วงเวลา",
      listLink: "/admin/otmonitoring",
      listKey: "otmonitoring",
    },
    {
      listItemIcon: <AttachMoneyIcon />,
      listItemText: "เงินเดือน",
      listLink: "",
      listKey: "payroll",
      collapse: [
        {
          listItemIcon: <Filter1Icon />,
          listItemText: "จัดการเงินเดือน",
          listLink: "/admin/payroll/run",
          listKey: "payrollrun",
        },
        {
          listItemIcon: <Filter2Icon />,
          listItemText: "สลิปเงินเดือน",
          listLink: "/admin/payroll/pay-slip",
          listKey: "payslip",
        },
      ],
    },
    {
      listItemIcon: <BrightnessMediumIcon />,
      listItemText: "การจัดการกะ",
      listLink: "/admin/shift",
      listKey: "shift",
    },
    {
      listItemIcon: <EmojiPeopleIcon />,
      listItemText: "Candidate Job",
      listLink: "/admin/CandidateJob",
      listKey: "CandidateJob",
    },
    {
      listItemIcon: <EmojiPeopleIcon />,
      listItemText: "Career",
      listLink: "/admin/Career",
      listKey: "Career",
    },
    // {
    //   listItemIcon: <GroupWork />,
    //   listItemText: "Create package",
    //   listLink: "/admin/FormPackage",
    //   listKey: "FormPackage",
    // },
    // {
    //   listItemIcon: <HealthAndSafety />,
    //   listItemText: "Health employee",
    //   listLink: "",
    //   listKey: "health",
    //   collapse: [
    //     {
    //       listItemIcon: <LocalHospital />,
    //       listItemText: "Health information",
    //       listLink: "/admin/health/HealthInformation",
    //       listKey: "HealthInformation",
    //     },
    //     {
    //       listItemIcon: <Healing />,
    //       listItemText: "Treatment information",
    //       listLink: "/admin/health/TreatmentInformation",
    //       listKey: "TreatmentInformation",
    //     },
    //     {
    //       listItemIcon: <ManageSearch />,
    //       listItemText: "Health history",
    //       listLink: "/admin/health/HealthHistory",
    //       listKey: "HealthHistory",
    //     },
    //     {
    //       listItemIcon: <RequestPage />,
    //       listItemText: "Bill request",
    //       listLink: "/admin/health/BillRequest",
    //       listKey: "billrequest",
    //     },
    //     {
    //       listItemIcon: <Bloodtype />,
    //       listItemText: "Health Check List",
    //       listLink: "/admin/health/BookingHealthCheckList",
    //       listKey: "BookingHealthCheckList",
    //     },
    //   ],
    // },
    // {
    //   listItemIcon: <Warehouse />,
    //   listItemText: "Warehouse",
    //   listLink: "",
    //   listKey: "Warehouse",
    //   collapse: [
    //     {
    //       listItemIcon: <Article />,
    //       listItemText: "Add Item Lifestyle",
    //       listLink: "/warehouse/FormLifeStyle",
    //       listKey: "FormLifeStyle",
    //     },
    //     {
    //       listItemIcon: <Article />,
    //       listItemText: "Add Item Travel",
    //       listLink: "/warehouse/FormTravel",
    //       listKey: "FormTravel",
    //     },
    //     {
    //       listItemIcon: <Article />,
    //       listItemText: "Add Item Insurance",
    //       listLink: "/warehouse/FormInsurance",
    //       listKey: "FormInsurance",
    //     },
    //     {
    //       listItemIcon: <Article />,
    //       listItemText: "Add package Health check",
    //       listLink: "/warehouse/FormPackageHealthCheck",
    //       listKey: "FormPackageHealthCheck",
    //     },
    //   ],
    // },
    {
      listItemIcon: <SettingsIcon />,
      listItemText: "เมนูผู้ดูแลระบบ",
      listLink: "/admin/setting",
      listKey: "setting",
    },
  ];

  return <ListMenu dataListMenu={dataListMenu} menuRole={"Admin"} />;
};

export default AdminMenu;
