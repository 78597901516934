import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { useDispatch, useSelector } from "react-redux";
import {
  getWarningLevel,
  updateWarningLevel,
} from "../../../../actions/warningLetter";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const DialogWarningLevel = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: warningLevel } = useSelector((state) => state.warningLevel);

  const validationSchema = Yup.object().shape({
    levels: Yup.array().of(
      Yup.object().shape({
        active: Yup.boolean(),
        name: Yup.string().when("active", {
          is: true,
          then: (schema) =>
            schema.trim().min(1, t("LengthAtLeast1")).max(40),
        }),
      })
    ),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      levels: [{ name: "", active: true }],
    },
    resolver: yupResolver(validationSchema),
  });

  const { fields, append } = useFieldArray({
    control,
    name: "levels",
  });

  const levels = useWatch({ control, name: "levels" });

  const handleAddWarningLetter = async (values) => {
    console.log(values);
    const res = await dispatch(updateWarningLevel(values));
    if (res) {
      dispatch(getWarningLevel());
      handleClose();
    }
  };

  useEffect(() => {
    reset({ levels: warningLevel });
  }, []);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth={"xs"}>
      <DialogTitle>{t("ManageWarningLevel")}</DialogTitle>
      <form onSubmit={handleSubmit(handleAddWarningLetter)}>
        <DialogContent>
          <Stack gap={1} marginBottom={2}>
            {fields.map((item, index) => (
              <div
                key={item.id}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Controller
                  name={`levels.${index}.name`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={`${t("WarningLevelName")}`}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error && fieldState.error.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`levels.${index}.active`}
                  render={({ field }) => (
                    <Switch {...field} checked={Boolean(field.value)} />
                  )}
                />
              </div>
            ))}
          </Stack>
          <Button
            startIcon={<AddIcon />}
            onClick={() => append({ name: "", active: true })}
          >
            {t("IncreaseWarningLevel")}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button color="error" type="button" onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <ButtonBlue variant="contained" type="submit">
            {t("Confirm")}
          </ButtonBlue>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogWarningLevel;
