const headerStyle1 = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '002060' }
    },
};

const headerStyle2 = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: '000000' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFF00' }
    },
};

const headerStyle3 = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '2F75B5' }
    },
};

const headerStyle4 = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '757171' }
    },
};

const headerStyle5 = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: '000000' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFF00' }
    },
};

const headerStyle6 = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0000' }
    },
};

export const headerStyles = [
    headerStyle1, headerStyle1, headerStyle1, headerStyle1, headerStyle1, headerStyle1, headerStyle1, headerStyle1,
    headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2,
    headerStyle3, headerStyle3, headerStyle3, headerStyle3, headerStyle3, headerStyle3, headerStyle3, headerStyle3, headerStyle3, headerStyle3, headerStyle3, headerStyle3,
    headerStyle4, headerStyle4, headerStyle4, headerStyle4, headerStyle4, headerStyle4, headerStyle4, headerStyle4, headerStyle4, headerStyle4, headerStyle4, headerStyle4,
    headerStyle5, headerStyle5, headerStyle5, headerStyle5, headerStyle5, headerStyle5, headerStyle5, headerStyle5, headerStyle5, headerStyle5, headerStyle5, headerStyle5,
    headerStyle6, headerStyle6, headerStyle6, headerStyle6, headerStyle6, headerStyle6, headerStyle6, headerStyle6, headerStyle6, headerStyle6, headerStyle6, headerStyle6,
];

export const colWidths = [
    { key: "employeeID", width: 40 },
    { key: "firstname_TH", width: 40 },
    { key: "lastname_TH", width: 40 },
    { key: "tax_ID", width: 40 },
    { key: "position", width: 40 },
    { key: "company", width: 40 },
    { key: "department", width: 40 },
    { key: "section", width: 40 },
    { key: "totalOT_Jan", width: 30 },
    { key: "totalOT_Feb", width: 30 },
    { key: "totalOT_Mar", width: 30 },
    { key: "totalOT_Apr", width: 30 },
    { key: "totalOT_May", width: 30 },
    { key: "totalOT_Jun", width: 30 },
    { key: "totalOT_Jul", width: 30 },
    { key: "totalOT_Aug", width: 30 },
    { key: "totalOT_Sep", width: 30 },
    { key: "totalOT_Oct", width: 30 },
    { key: "totalOT_Nov", width: 30 },
    { key: "totalOT_Dec", width: 30 },
    { key: "totalOT1_Jan", width: 30 },
    { key: "totalOT1_Feb", width: 30 },
    { key: "totalOT1_Mar", width: 30 },
    { key: "totalOT1_Apr", width: 30 },
    { key: "totalOT1_May", width: 30 },
    { key: "totalOT1_Jun", width: 30 },
    { key: "totalOT1_Jul", width: 30 },
    { key: "totalOT1_Aug", width: 30 },
    { key: "totalOT1_Sep", width: 30 },
    { key: "totalOT1_Oct", width: 30 },
    { key: "totalOT1_Nov", width: 30 },
    { key: "totalOT1_Dec", width: 30 },
    { key: "totalOT15_Jan", width: 30 },
    { key: "totalOT15_Feb", width: 30 },
    { key: "totalOT15_Mar", width: 30 },
    { key: "totalOT15_Apr", width: 30 },
    { key: "totalOT15_May", width: 30 },
    { key: "totalOT15_Jun", width: 30 },
    { key: "totalOT15_Jul", width: 30 },
    { key: "totalOT15_Aug", width: 30 },
    { key: "totalOT15_Sep", width: 30 },
    { key: "totalOT15_Oct", width: 30 },
    { key: "totalOT15_Nov", width: 30 },
    { key: "totalOT15_Dec", width: 30 },
    { key: "totalOT2_Jan", width: 30 },
    { key: "totalOT2_Feb", width: 30 },
    { key: "totalOT2_Mar", width: 30 },
    { key: "totalOT2_Apr", width: 30 },
    { key: "totalOT2_May", width: 30 },
    { key: "totalOT2_Jun", width: 30 },
    { key: "totalOT2_Jul", width: 30 },
    { key: "totalOT2_Aug", width: 30 },
    { key: "totalOT2_Sep", width: 30 },
    { key: "totalOT2_Oct", width: 30 },
    { key: "totalOT2_Nov", width: 30 },
    { key: "totalOT2_Dec", width: 30 },
    { key: "totalOT3_Jan", width: 30 },
    { key: "totalOT3_Feb", width: 30 },
    { key: "totalOT3_Mar", width: 30 },
    { key: "totalOT3_Apr", width: 30 },
    { key: "totalOT3_May", width: 30 },
    { key: "totalOT3_Jun", width: 30 },
    { key: "totalOT3_Jul", width: 30 },
    { key: "totalOT3_Aug", width: 30 },
    { key: "totalOT3_Sep", width: 30 },
    { key: "totalOT3_Oct", width: 30 },
    { key: "totalOT3_Nov", width: 30 },
    { key: "totalOT3_Dec", width: 30 }
]