import React, { useState } from "react";
import { Container, Grid, Tab, Tabs, styled } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CompanyCalibrate from "./company";
import DepartmentCalibrate from "./department";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiGrid-container": {
      marginBottom: 0,
    },
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
    "& .MuiOutlinedInput-input": {
      padding: "16px 14px",
    },
    "& .MuiTableCell-root": {
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
}));

const ContainerStyled = styled(Container)({
  paddingTop: "100px",
  height: "100dvh",
});

function Calibrate() {
  const classes = useStyles();
  const [view, setView] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setView(newValue);
  };

  return (
    <ContainerStyled>
      <div className={classes.root}>
        <div className="card-section">
          <div className="header-section">
            <Grid container>
              <Grid item>
                <h3 className="header-topic">Calibrate</h3>
              </Grid>
            </Grid>
          </div>
          <Tabs value={view} onChange={handleChangeTab} centered>
            <Tab label="Company" {...a11yProps(0)} />
            <Tab label="Department" {...a11yProps(1)} />
          </Tabs>
          <CustomTabPanel value={view} index={0}>
            <CompanyCalibrate />
          </CustomTabPanel>
          <CustomTabPanel value={view} index={1}>
            <DepartmentCalibrate />
          </CustomTabPanel>
        </div>
      </div>
    </ContainerStyled>
  );
}

export default Calibrate;
