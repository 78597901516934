import React from "react";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const MenuUser = (props) => {
  const { anchorEl, onClose } = props;
  const { t, i18n } = useTranslation();

  return (
    <Menu
      sx={{
        mt: "1px",
        "& .MuiMenu-paper": { backgroundColor: "#46cbe2" },
      }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem component={Link} to={`/recruitment`}>
        <ListItemIcon sx={{ color: "#ffffff" }}>
          <i className="fa-solid fa-user"></i>
        </ListItemIcon>
        <ListItemText primary={`${t("JobOpenForApplied")}`} />
      </MenuItem>
      <MenuItem component={Link} to={`/manage/hunter/peer`}>
        <ListItemIcon sx={{ color: "#ffffff" }}>
          <i className="fa-solid fa-user"></i>
        </ListItemIcon>
        <ListItemText primary={`${t("ManagerPerson")}`} />
      </MenuItem>
      <MenuItem component={Link} to={`/hunter-manpower-interviewed`}>
        <ListItemIcon sx={{ color: "#ffffff" }}>
          <i className="fa-solid fa-user"></i>
        </ListItemIcon>
        <ListItemText primary={`${t("HistoryOfSelectedForInterview")}`} />
      </MenuItem>
      <MenuItem component={Link} to={`/hunter-manpower-sending`}>
        <ListItemIcon sx={{ color: "#ffffff" }}>
          <i className="fa-solid fa-user"></i>
        </ListItemIcon>
        <ListItemText primary={`${t("HistoryOfSendingPeople")}`} />
      </MenuItem>
      <MenuItem component={Link} to={"/recruitment-interviewer"}>
        <ListItemIcon sx={{ color: "#ffffff" }}>
          <i className="fa-solid fa-user"></i>
        </ListItemIcon>
        <ListItemText primary={`${t("InterviewCommittee")}`} />
      </MenuItem>
      <MenuItem component={Link} to={`/manage/hunter/dashboard`}>
        <ListItemIcon sx={{ color: "#ffffff" }}>
          <i className="fa-solid fa-user"></i>
        </ListItemIcon>
        <ListItemText primary={`${t("Dashboard")}`} />
      </MenuItem>
    </Menu>
  );
};

export default MenuUser;
