import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  Box,
  Card,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  colors,
} from "@mui/material";
import DatePickerCustom from "../../shared/date/datePicker";
import utils from "../../../../utils";
import { getPersonalTaxByMonth } from "../../../../actions/personal-tax";
import { getUserProfile } from "../../../../actions/user";
import AnimationBackdrop from "../../shared/general/Backdrop";
import PersonalTaxInfo from "../../shared/personalTax";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 24,
  },
});

const StyledWrapSearch = styled("div")({
  padding: 16,
  marginBottom: 16,
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledDiv = styled("div")({
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const StyledCard = styled(Card)(({}) => ({
  borderRadius: 8,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "900px",
  "& .MuiCardHeader-root": {
    padding: 24,
  },
  margin: "16px 0",
}));

const StyledTable = styled(TableContainer)({
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#46cbe2",
    color: "#ffffff",
    fontSize: 16,
    textAlign: "center",
    padding: "18px",
  },

  "& .tableCellLable": {
    textAlign: "left",
  },

  "& .tableCellRegular": {
    textAlign: "center",
    fontWeight: "500",
  },

  "& .tableCellIrregular": {
    textAlign: "center",
    fontWeight: "500",
  },

  "& .green": {
    color: "#43a047",
    fontWeight: "700",
    textDecoration: "underline",
  },

  "& .blue": {
    color: "#3589e5",
    fontWeight: "600",
    textDecoration: "underline",
  },

  "& .red": {
    color: "#e53935",
    fontWeight: "600",
    textDecoration: "underline",
  },

  "& .purple": {
    color: "#9135e5",
    fontWeight: "600",
    textDecoration: "underline",
  },

  "& .orange": {
    color: "#e59135",
    fontWeight: "600",
    textDecoration: "underline",
  },

  "& .pink": {
    color: "#e53589",
    fontWeight: "600",
    textDecoration: "underline",
  },
});

const StyledTableRow = (props) => {
  const { label, regular, irregular, colorRegular, colorIrregular } = props;
  const { t, i18n } = useTranslation();

  return (
    <TableRow>
      <TableCell className={`tableCellLable`}>{t(label)}</TableCell>
      <TableCell className={`tableCellRegular ${colorRegular}`}>
        {utils.numberWithCommas(regular)}
      </TableCell>
      <TableCell className={`tableCellIrregular ${colorIrregular}`}>
        {utils.numberWithCommas(irregular)}
      </TableCell>
    </TableRow>
  );
};

const StyledTableSummaryRow = (props) => {
  const { label, data, month, color } = props;
  const { t, i18n } = useTranslation();

  return (
    <TableRow>
      <TableCell className={`tableCellLable`}>{t(label)}</TableCell>
      {data && (
        <TableCell className={`tableCellIrregular ${color}`} colSpan={2}>
          {utils.numberWithCommas(data)}
        </TableCell>
      )}
      {month && (
        <TableCell className={`tableCellIrregular ${color}`} colSpan={2}>
          {month}
        </TableCell>
      )}
    </TableRow>
  );
};

const MyPersonalTax = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [search, setSearch] = useState(new Date());
  const { result: personalTax, isFetching: personalTaxIsFetching } =
    useSelector((state) => state.personalTax);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [regular, setRegular] = useState({});
  const [irregular, setIrregular] = useState({});

  useEffect(() => {
    if (userProfile) {
      dispatch(
        getPersonalTaxByMonth(
          dayjs(search).format("YYYY-MM-DD"),
          userProfile.idEmployees
        )
      );
    } else {
      dispatch(getUserProfile());
    }
  }, [userProfile]);

  useEffect(() => {
    if (personalTax) {
      setRegular(personalTax.regular);
      setIrregular(personalTax.irregular);
      console.log(personalTax);
    }
  }, [personalTax]);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div className="head">
          <Typography variant="h4">{t("AppMenu.MyPersonalTax")}</Typography>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledWrapSearch>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={3}>
                  <StyledBoxSearch>
                    <Typography className="label" color="text.third">
                      {`${t("SearchDate")}`}
                    </Typography>
                    <div className="search-date">
                      <DatePickerCustom
                        inputFormat="MMMM YYYY"
                        value={search}
                        name="start"
                        minDate={new Date().setFullYear(
                          new Date().getFullYear() - 2
                        )}
                        maxDate={new Date()}
                        views={["year", "month"]}
                        onChange={(newValue) => {
                          setSearch(newValue);
                          if (userProfile) {
                            dispatch(
                              getPersonalTaxByMonth(
                                dayjs(newValue).format("YYYY-MM-DD"),
                                userProfile.idEmployees
                              )
                            );
                          }
                        }}
                      />
                    </div>
                  </StyledBoxSearch>
                </Grid>
              </Grid>
            </StyledWrapSearch>
          </Grid>
        </Grid>

        {personalTax ? (
          <PersonalTaxInfo personalTax={personalTax} />
        ) : personalTaxIsFetching === true ? (
          <AnimationBackdrop />
        ) : (
          <Typography variant="h6" textAlign="center">
            {t("NoData")}
          </Typography>
        )}
      </Container>
    </StyledRoot>
  );
};

export default MyPersonalTax;
