import dayjs from "dayjs";
let isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

export const calculatorOvertime = (start, end, options) => {
	const {
		prePattern,
		curPattern,
		nextPattern,
		idPaymentType,
		attendances,
		requestTime,
		isFinger,
		leave,
		preLeave,
		nextLeave,
		reason,
		dayCutOffTime,
		employee,
		idRequestType
	} = options;
	// ----------------------------------------------PRE-DATE----------------------------------------------------
	let preLeaveFind = (
		preLeave &&
		preLeave.length > 0 &&
		preLeave.find((v) => (v.isApprove === 1 && v.isWithdraw !== 1 && v.isFullDay === 1))
	);
	let preIsWorking = (
		prePattern.isWorkingDay && 
		!(
			prePattern.isCompensation ||
			(prePattern.isHoliday && !prePattern.isShiftDaily) ||
			(prePattern.isShiftDaily && prePattern.idHoliday)
		)
		&&
		!preLeaveFind
	)? 1: 0;
	let preStart = dayjs(`${prePattern.date} ${prePattern.timeIn}`);
	let preEnd = preStart.add(prePattern.workingHours, "minute");
	let prePeriod = prePattern.period;
	let preStartBreakHourDate = dayjs(`${prePattern.date} ${prePattern.startBreak || (prePeriod === 3 ? '00:00:00' : '12:00:00')}`);
	if(
		!preStartBreakHourDate.isBetween(preStart, dayjs(`${prePattern.date} 00:00:00`).add(1, 'day'), "minute", "[]") && 
		prePeriod === 3
	){
		preStartBreakHourDate = preStartBreakHourDate.add(1, "day");
	}
	let preEndBreakHourDate = preStart;

	if(prePattern.breakTime !== 1){
		if(!preStartBreakHourDate.isBetween(preStart, preEnd, "minute", "[]")){
			preStartBreakHourDate = preStartBreakHourDate.add(1, "day");
		}
		preEndBreakHourDate = preStartBreakHourDate.add((prePattern.breakTimeMin || 60), 'minute');
	}

	if(
	  employee.idCompany === 33 &&
      prePattern.idWorkingType === 1 &&
      prePattern.timeIn === "00:00:00"
	){
		preStart = preStart.add(1, 'day');
		preEnd = preEnd.add(1, 'day');
		preStartBreakHourDate = preStartBreakHourDate.add(1, 'day');
		preEndBreakHourDate = preEndBreakHourDate.add(1, 'day');
	}
	// ----------------------------------------------CUR-DATE----------------------------------------------------
	let curLeave = (
		leave &&
		leave.length > 0 &&
		leave.find((v) => (v.isApprove === 1 && v.isWithdraw !== 1 && v.isFullDay === 1))
	);
	let curIsWorking = (
		curPattern.isWorkingDay && 
		!(
			curPattern.isCompensation ||
			(curPattern.isHoliday && !curPattern.isShiftDaily) ||
			(curPattern.isShiftDaily && curPattern.idHoliday)
		)
		&&
		!curLeave
	)? 1: 0;
	let curStart = dayjs(`${curPattern.date} ${curPattern.timeIn}`);
	let curEnd = curStart.add(curPattern.workingHours, "minute");
	let curPeriod = curPattern.period;
	let curStartBreakHourDate = dayjs(`${curPattern.date} ${curPattern.startBreak || (curPeriod === 3 ? '00:00:00' : '12:00:00')}`);
	if(
		!curStartBreakHourDate.isBetween(curStart, dayjs(`${curPattern.date} 00:00:00`).add(1, 'day'), "minute", "[]") && 
		curPeriod === 3
	){
		curStartBreakHourDate = curStartBreakHourDate.add(1, "day");
	}
	let curEndBreakHourDate = curStart;
	let curDayCutOffTime = dayjs(`${curPattern.date} ${prePattern.dayCutOffTime || dayCutOffTime || '00:00:00'}`);
	
	if(curPattern.breakTime !== 1){
		if(!curStartBreakHourDate.isBetween(curStart, curEnd, "minute", "[]")){
			curStartBreakHourDate = curStartBreakHourDate.add(1, "day");
		}
		curEndBreakHourDate = curStartBreakHourDate.add((curPattern.breakTimeMin || 60), 'minute');
	}

	if(
		employee.idCompany === 33 &&
		curPattern.idWorkingType === 1 &&
		curPattern.timeIn === "00:00:00"
	){
		curStart = curStart.add(1, 'day');
		curEnd = curEnd.add(1, 'day');
		curStartBreakHourDate = curStartBreakHourDate.add(1, 'day');
		curEndBreakHourDate = curEndBreakHourDate.add(1, 'day');
	}
	// ----------------------------------------------NEXT-DATE---------------------------------------------------
	let nextLeaveFind = (
		nextLeave &&
		nextLeave.length > 0 &&
		nextLeave.find((v) => (v.isApprove === 1 && v.isWithdraw !== 1 && v.isFullDay === 1))
	);
	let nextIsWorking = (
		nextPattern.isWorkingDay && 
		!(
			nextPattern.isCompensation ||
			(nextPattern.isHoliday && !nextPattern.isShiftDaily) ||
			(nextPattern.isShiftDaily && nextPattern.idHoliday)
		)
		&&
		!nextLeaveFind
	)? 1: 0;
	let nextStart = dayjs(`${nextPattern.date} ${nextPattern.timeIn}`);
	let nextEnd = nextStart.add(nextPattern.workingHours, "minute");
	let nextPeriod = nextPattern.period;
	let nextStartBreakHourDate = dayjs(`${nextPattern.date} ${nextPattern.startBreak || (nextPeriod === 3 ? '00:00:00' : '12:00:00')}`)  ;
	if(
		!nextStartBreakHourDate.isBetween(nextStart, dayjs(`${nextPattern.date} 00:00:00`).add(1, 'day'), "minute", "[]") && 
		nextPeriod === 3
	){
		nextStartBreakHourDate = nextStartBreakHourDate.add(1, "day");
	}
	let nextEndBreakHourDate = nextStart;
	let nextDayCutOffTime = dayjs(`${nextPattern.date} ${curPattern.dayCutOffTime || dayCutOffTime || '00:00:00'}`);

	if(nextPattern.breakTime !== 1){
		if(!nextStartBreakHourDate.isBetween(nextStart, nextEnd, "minute", "[]")){
			nextStartBreakHourDate = nextStartBreakHourDate.add(1, "day");
		}
		nextEndBreakHourDate = nextStartBreakHourDate.add((nextPattern.breakTimeMin || 60), 'minute');
	}

	if(
		employee.idCompany === 33 &&
		nextPattern.idWorkingType === 1 &&
		nextPattern.timeIn === "00:00:00"
	){
		nextStart = nextStart.add(1, 'day');
		nextEnd = nextEnd.add(1, 'day');
		nextStartBreakHourDate = nextStartBreakHourDate.add(1, 'day');
		nextEndBreakHourDate = nextEndBreakHourDate.add(1, 'day');
	}

	if(
		prePattern.idCutOffWorkTimeType === 1 ||
		(prePattern.idCutOffWorkTimeType === 4 && (
			prePattern.isCompensation ||
			(prePattern.isHoliday && !prePattern.isShiftDaily) ||
			(prePattern.isShiftDaily && prePattern.idHoliday)
		))
	){
		curDayCutOffTime = dayjs(curStart);
	}

	if(
		curPattern.idCutOffWorkTimeType === 1 ||
		(curPattern.idCutOffWorkTimeType === 4 && (
			curPattern.isCompensation ||
			(curPattern.isHoliday && !curPattern.isShiftDaily) ||
			(curPattern.isShiftDaily && curPattern.idHoliday)
		))
	){
		nextDayCutOffTime = dayjs(nextStart);
	}
	// ----------------------------------------------BREAK 2-----------------------------------------------------
	let preEndBreak2Hour = preEnd ;

	if(prePattern.breakTime2Min && !preEnd.isSame(curStart, "minute")){
		preEndBreak2Hour = preEnd.add(prePattern.breakTime2Min,"minute");
	}

	let curEndBreak2Hour = curEnd ;

	if(curPattern.breakTime2Min && !curEnd.isSame(nextStart, "minute")){
		curEndBreak2Hour = curEnd.add(curPattern.breakTime2Min,"minute");
	}
	// ----------------------------------------------BREAK 0-----------------------------------------------------
	let curStartBreak0Hour = curStart ;

	if(curPattern.breakTime0Min){
		curStartBreak0Hour = curStart.subtract(curPattern.breakTime0Min,"minute");
	}

	let nextStartBreak0Hour = nextStart ;

	if(nextPattern.breakTime0Min){
		nextStartBreak0Hour = nextStart.subtract(nextPattern.breakTime0Min,"minute");
	}
	// ----------------------------------------------RESULT------------------------------------------------------
	let xOT = 0;
	let xOTHoliday = 0;
	let xWorkingDaily = 0;
	let xWorkingDailyHoliday = 0;
	let	xWorkingMonthlyHoliday = 0;
	let overlapWorking = 0;
	let isInTimeFrame = false;
	let isErrorBreakTime = false;
	let isOutOfRange = false;
	// ----------------------------------------------ON BREAKTIME------------------------------------------------
	let isReasonBreak = reason ? Boolean(reason.isBreak) : false; 

	let isBetweenPreBreakTime = false;
	let isBetweenPreBreakTime2 = false;
	let isBetweenCurBreakTime0 = false;
	let isBetweenCurBreakTime = false;
	let isBetweenCurBreakTime2 = false;
	let isBetweenNextBreakTime0 = false;
	let isBetweenNextBreakTime = false;

	let isPaymentDaily = false;

	if([1,5].includes(idPaymentType)){
		isPaymentDaily = true;
	}

	if(prePattern.breakTime !== 1){
		isBetweenPreBreakTime = (
			start.isBetween(preStartBreakHourDate, preEndBreakHourDate, "minute", "[]") &&
			end.isBetween(preStartBreakHourDate, preEndBreakHourDate, "minute", "[]")
		);

		if(isBetweenPreBreakTime && !isReasonBreak){
			overlapWorking = end.diff(start, "minute");
		}
	}
	
	if(prePattern.breakTime2Min && !preEnd.isSame(curStart, "minute")){
		isBetweenPreBreakTime2 = (
			start.isBetween(preEnd, preEndBreak2Hour, "minute", "[]") &&
			end.isBetween(preEnd, preEndBreak2Hour, "minute", "[]")
		);

		if(isBetweenPreBreakTime2 && !isReasonBreak){
			overlapWorking = end.diff(start, "minute");
		}
	}

	if(curPattern.breakTime0Min){
		isBetweenCurBreakTime0 = (
			start.isBetween(curStartBreak0Hour, curStart, "minute", "[]") &&
			end.isBetween(curStartBreak0Hour, curStart, "minute", "[]")
		);

		if(isBetweenCurBreakTime0 && !isReasonBreak){
			overlapWorking = end.diff(start, "minute");
		}
	}

	if(curPattern.breakTime !== 1){
		isBetweenCurBreakTime = (
			start.isBetween(curStartBreakHourDate, curEndBreakHourDate, "minute", "[]") &&
			end.isBetween(curStartBreakHourDate, curEndBreakHourDate, "minute", "[]")
		);

		if(isBetweenCurBreakTime && !isReasonBreak){
			overlapWorking = end.diff(start, "minute");
		}
	}
	
	if(curPattern.breakTime2Min && !curEnd.isSame(nextStart, "minute")){
		isBetweenCurBreakTime2 = (
			start.isBetween(curEnd, curEndBreak2Hour, "minute", "[]") &&
			end.isBetween(curEnd, curEndBreak2Hour, "minute", "[]")
		);

		if(isBetweenCurBreakTime2 && !isReasonBreak){
			overlapWorking = end.diff(start, "minute");
		}
	}

	if(nextPattern.breakTime0Min){
		isBetweenNextBreakTime0 = (
			start.isBetween(nextStartBreak0Hour, nextStart, "minute", "[]") &&
			end.isBetween(nextStartBreak0Hour, nextStart, "minute", "[]")
		);

		if(isBetweenNextBreakTime0 && !isReasonBreak){
			overlapWorking = end.diff(start, "minute");
		}
	}
	
	if(nextPattern.breakTime !== 1){
		isBetweenNextBreakTime = (
			start.isBetween(nextStartBreakHourDate, nextEndBreakHourDate, "minute", "[]") &&
			end.isBetween(nextStartBreakHourDate, nextEndBreakHourDate, "minute", "[]")
		);

		if(isBetweenNextBreakTime && !isReasonBreak){
			overlapWorking = end.diff(start, "minute");
		}
	}

	let isErrorRequestTime = false;

	if(isReasonBreak){
		if(isBetweenPreBreakTime){
			if(prePattern.breakTime !== 1){
				isErrorRequestTime = false;
			}else{
				isErrorRequestTime = true;
			}
		}
		else if(isBetweenPreBreakTime2){
			if(prePattern.breakTime2Min){
				isErrorRequestTime = false;
			}else{
				isErrorRequestTime = true;
			}
		}
		else if(isBetweenCurBreakTime0){
			if(curPattern.breakTime0Min){
				isErrorRequestTime = false;
			}else{
				isErrorRequestTime = true;
			}
		}
		else if(isBetweenCurBreakTime){
			if(curPattern.breakTime !== 1){
				isErrorRequestTime = false;
			}else{
				isErrorRequestTime = true;
			}
		}
		else if(isBetweenCurBreakTime2){
			if(curPattern.breakTime2Min){
				isErrorRequestTime = false;
			}else{
				isErrorRequestTime = true;
			}
		}
		else if(isBetweenNextBreakTime0){
			if(nextPattern.breakTime0Min){
				isErrorRequestTime = false;
			}else{
				isErrorRequestTime = true;
			}
		}
		else if(isBetweenNextBreakTime){
			if(nextPattern.breakTime !== 1){
				isErrorRequestTime = false;
			}else{
				isErrorRequestTime = true;
			}
		}
		else{
			if(
				prePattern.breakTime !== 1 || 
				prePattern.breakTime2Min || 
				curPattern.breakTime0Min ||
				curPattern.breakTime !== 1 || 
				curPattern.breakTime2Min || 
				nextPattern.breakTime0Min ||
				nextPattern.breakTime !== 1
			){
				isErrorRequestTime = true;
			}
		}
	}

	if(isErrorRequestTime){
		isErrorBreakTime = true;
	}

	if(curPattern.idWorkingType === 1){
		if(start.isBefore(preStart, 'minute')){
			isOutOfRange = true;
		}
	}

	if(curPattern.idWorkingType === 2){
		if(
			!start.isBetween(preStart, nextEnd, 'minute', '[)') ||
			!end.isBetween(preStart, nextEnd, 'minute', '(]')
		){
			console.log("1 : ",!start.isBetween(preStart, nextEnd, 'minute', '[)'));
			console.log("2 : ",!end.isBetween(preStart, nextEnd, 'minute', '(]'));
			isOutOfRange = true;
		}
	}
	// ---------------------------------------------------------------------------------------------------------
	// if(isFinger === 1){
		let startTemp = dayjs(start);
		let endTemp = dayjs(end);

		let listAttendancesTemp = [];
		Object.keys(attendances).map(k => {
			attendances[k].map((att, index) => {
				if(!listAttendancesTemp[index]){
					listAttendancesTemp[index]={
						checkIn: null,
						checkOut: null,
						type: "IN/OUT"
					}
				}
				if(att && !att.isNotActive){
					if(att.isCheckIn === 1){
						listAttendancesTemp[index].checkIn = dayjs(att.attendanceTextDateTime).format("DD/MM/YYYY HH:mm");
						// `${curStart.format("DD/MM/YYYY")} ${att.attendanceTextTime}`;
					} else {
						listAttendancesTemp[index].checkOut = dayjs(att.attendanceTextDateTime).format("DD/MM/YYYY HH:mm");
						// `${curStart.format("DD/MM/YYYY")} ${att.attendanceTextTime}`;
					}
				}
			})
		});

		if(requestTime && requestTime.length > 0){
			requestTime.map(time => {
				if(
					(time.isDoubleApproval === 1 && time.isManagerLV1Approve !== 0 && time.isManagerLV2Approve !== 0) ||
					(time.isDoubleApproval === 0 && (
						(time.approvalLevel === 1 && time.isManagerLV1Approve !== 0) || 
						(time.approvalLevel === 2 && time.isManagerLV2Approve !== 0)
					))
				){
					listAttendancesTemp.push({
						checkIn: time.startText,
						checkOut: time.endText,
						type: "RequestTime"
					});
				}
			});
		}
		
		listAttendancesTemp.sort((a, b) => {
			return dayjs(a.checkIn, "DD/MM/YYYY HH:mm").diff(dayjs(b.checkIn, "DD/MM/YYYY HH:mm"), "minute");
		});
				
		listAttendancesTemp.map((attendance) => {
			if(attendance.checkIn && attendance.checkOut){
				let checkIn = dayjs(attendance.checkIn, "DD/MM/YYYY HH:mm");
				let checkOut = dayjs(attendance.checkOut, "DD/MM/YYYY HH:mm");
				if(
					startTemp.isBetween(checkIn, checkOut, "minute", "[)") &&
					endTemp.isBetween(checkIn, checkOut, "minute", "(]")
				){
					isInTimeFrame = true;
				} else if (startTemp.isBetween(checkIn, checkOut, "minute", "[)")){
					startTemp = checkOut;
				} else if (endTemp.isBetween(checkIn, checkOut, "minute", "(]")){
					endTemp = checkIn
				}
			}
		});
	// } else {
	// 	isInTimeFrame = true;
	// }

	if(curPattern.idWorkingType === 1){
		// ----------------------------------------------PRE-DATE------------------------------------------------
		if(prePattern.breakTime !== 1){
			let ot_previous_first = dayjs(end.isBefore(preStartBreakHourDate)? end: preStartBreakHourDate).diff(start.isAfter(preStart)? start: preStart, 'minute', true);
			if(ot_previous_first > 0){
				if(preIsWorking === 1){
					overlapWorking += ot_previous_first;
				} else if(preIsWorking === 0){
					if(isPaymentDaily){
						if(
							prePattern.isCompensation ||
							(prePattern.isHoliday && !prePattern.isShiftDaily) ||
							(prePattern.isShiftDaily && prePattern.idHoliday)
						){
							xWorkingDaily += ot_previous_first;
						}else{
							xWorkingDailyHoliday += ot_previous_first;
						}
					}else{
						xWorkingMonthlyHoliday += ot_previous_first;
					}
				}
			} else {
				ot_previous_first = 0;
			}

			let ot_previous_break = dayjs(end.isBefore(preEndBreakHourDate)? end: preEndBreakHourDate).diff(start.isAfter(preStartBreakHourDate)? start: preStartBreakHourDate, 'minute', true);
			if(ot_previous_break > 0 && isReasonBreak){
				if(preIsWorking === 1){
					xOT += ot_previous_break
				} else if(preIsWorking === 0){
					if(isPaymentDaily){
						if(
							prePattern.isCompensation ||
							(prePattern.isHoliday && !prePattern.isShiftDaily) ||
							(prePattern.isShiftDaily && prePattern.idHoliday)
						){
							xWorkingDaily += ot_previous_break;
						}else{
							xWorkingDailyHoliday += ot_previous_break;
						}
					}else{
						xWorkingMonthlyHoliday += ot_previous_break;
					}
				}
			} else {
				ot_previous_break = 0;
			}
		}

		let endPreTemp = end.isBefore(preEnd)? end: preEnd;
		let startPreTemp = start.isAfter(preEndBreakHourDate)? start: preEndBreakHourDate;
		let ot_previous = dayjs(endPreTemp).diff(startPreTemp, 'minute', true);
		if(curPattern.breakTime0Min && dayjs(curStartBreak0Hour).isBetween(startPreTemp, endPreTemp, 'minute', "[)")){
			let sum = 0;
			if(
				dayjs(startPreTemp).isBetween(curStartBreak0Hour, curStart, 'minute', "[)") &&
				dayjs(endPreTemp).isBetween(curStartBreak0Hour, curStart, 'minute', "(]")
			){
				sum = dayjs(endPreTemp).diff(startPreTemp, 'minute', true);
			}else if(
				dayjs(curStartBreak0Hour).isBetween(startPreTemp, endPreTemp, 'minute', "[)")
			){
				sum = dayjs(endPreTemp).diff(curStartBreak0Hour, 'minute', true);
			}else if(
				dayjs(curStart).isBetween(startPreTemp, endPreTemp, 'minute', "[)")
			){
				sum = dayjs(curStartBreak0Hour).diff(startPreTemp, 'minute', true);
			}
			ot_previous -= sum;
		}
		if(ot_previous > 0){
			if(preIsWorking === 1){
				overlapWorking += ot_previous;
			} else if(preIsWorking === 0){
				if(isPaymentDaily){
					if(
						prePattern.isCompensation ||
						(prePattern.isHoliday && !prePattern.isShiftDaily) ||
						(prePattern.isShiftDaily && prePattern.idHoliday)
					){
						xWorkingDaily += ot_previous;
					}else{
						xWorkingDailyHoliday += ot_previous;
					}
				}else{
					xWorkingMonthlyHoliday += ot_previous;
				}
			}
		} else {
			ot_previous = 0;
		}

		if(!preEndBreak2Hour.isSame(preEnd, 'minute')){
			let ot_pre_break2 = dayjs(end.isBefore(preEndBreak2Hour)? end: preEndBreak2Hour).diff(start.isAfter(preEnd)? start: preEnd, 'minute', true)
			if(ot_pre_break2 > 0 && isReasonBreak){
				let endTemp = end.isBefore(preEndBreak2Hour)? end: preEndBreak2Hour;
				let startTemp = start.isAfter(preEnd)? start: preEnd;
				if(curDayCutOffTime.isSameOrAfter(endTemp, "minute")){
					if(preIsWorking === 1){
						xOT += ot_pre_break2
					} else if(preIsWorking === 0){
						xOTHoliday += ot_pre_break2
					}
				}else if(curDayCutOffTime.isSameOrBefore(startTemp, "minute")){
					if(curIsWorking === 1){
						xOT += ot_pre_break2
					} else if(curIsWorking === 0){
						xOTHoliday += ot_pre_break2
					}
				}else if(curDayCutOffTime.isBetween(startTemp, endTemp, "minute", "()")){
					let firstHalf = curDayCutOffTime.diff(startTemp, "minute");
					let secondHalf = endTemp.diff(curDayCutOffTime, "minute");
					if(preIsWorking === 1){
						xOT += firstHalf
					} else if(preIsWorking === 0){
						xOTHoliday += firstHalf
					}
					if(curIsWorking === 1){
						xOT += secondHalf
					} else if(curIsWorking === 0){
						xOTHoliday += secondHalf
					}
				}
			} else {
				ot_pre_break2 = 0;
			}
		}

		let ot_pre_current = dayjs(end.isBefore(curStartBreak0Hour)? end: curStartBreak0Hour).diff(start.isAfter(preEndBreak2Hour)? start: preEndBreak2Hour, 'minute', true)
		if(ot_pre_current > 0){
			let endTemp = end.isBefore(curStartBreak0Hour)? end: curStartBreak0Hour;
			let startTemp = start.isAfter(preEndBreak2Hour)? start: preEndBreak2Hour;
			if(curDayCutOffTime.isSameOrAfter(endTemp, "minute")){
				if(preIsWorking === 1){
					xOT += ot_pre_current
				} else if(preIsWorking === 0){
					xOTHoliday += ot_pre_current
				}
			}else if(curDayCutOffTime.isSameOrBefore(startTemp, "minute")){
				if(curIsWorking === 1){
					xOT += ot_pre_current
				} else if(curIsWorking === 0){
					xOTHoliday += ot_pre_current
				}
			}else if(curDayCutOffTime.isBetween(startTemp, endTemp, "minute", "()")){
				let firstHalf = curDayCutOffTime.diff(startTemp, "minute");
				let secondHalf = endTemp.diff(curDayCutOffTime, "minute");
				if(preIsWorking === 1){
					xOT += firstHalf
				} else if(preIsWorking === 0){
					xOTHoliday += firstHalf
				}
				if(curIsWorking === 1){
					xOT += secondHalf
				} else if(curIsWorking === 0){
					xOTHoliday += secondHalf
				}
			}
		} else {
			ot_pre_current = 0;
		}

		if(curPattern.breakTime0Min){
			let ot_breaktime_0 = dayjs(end.isBefore(curStart)? end: curStart).diff(start.isAfter(curStartBreak0Hour)? start: curStartBreak0Hour, 'minute', true);
			if(ot_breaktime_0 > 0 && isReasonBreak){
				let endTemp = end.isBefore(curStart)? end: curStart;
				let startTemp = start.isAfter(curStartBreak0Hour)? start: curStartBreak0Hour;
				if(curDayCutOffTime.isSameOrAfter(endTemp, "minute")){
					if(preIsWorking === 1){
						xOT += ot_breaktime_0
					} else if(preIsWorking === 0){
						xOTHoliday += ot_breaktime_0
					}
				}else if(curDayCutOffTime.isSameOrBefore(startTemp, "minute")){
					if(curIsWorking === 1){
						xOT += ot_breaktime_0
					} else if(curIsWorking === 0){
						xOTHoliday += ot_breaktime_0
					}
				}else if(curDayCutOffTime.isBetween(startTemp, endTemp, "minute", "()")){
					let firstHalf = curDayCutOffTime.diff(startTemp, "minute");
					let secondHalf = endTemp.diff(curDayCutOffTime, "minute");
					if(preIsWorking === 1){
						xOT += firstHalf
					} else if(preIsWorking === 0){
						xOTHoliday += firstHalf
					}
					if(curIsWorking === 1){
						xOT += secondHalf
					} else if(curIsWorking === 0){
						xOTHoliday += secondHalf
					}
				}
			} else {
				ot_breaktime_0 = 0;
			}
		}

		// ----------------------------------------------CUR-DATE------------------------------------------------
		if(curPattern.breakTime !== 1){
			let ot_current_first = dayjs(end.isBefore(curStartBreakHourDate)? end: curStartBreakHourDate).diff(start.isAfter(curStart)? start: curStart, 'minute', true);
			if(ot_current_first > 0){
				if(curIsWorking === 1){
					overlapWorking += ot_current_first;
				} else if(curIsWorking === 0){
					if(isPaymentDaily){
						if(
							curPattern.isCompensation ||
							(curPattern.isHoliday && !curPattern.isShiftDaily) ||
							(curPattern.isShiftDaily && curPattern.idHoliday)
						){
							xWorkingDaily += ot_current_first;
						}else{
							xWorkingDailyHoliday += ot_current_first;
						}
					}else{
						xWorkingMonthlyHoliday += ot_current_first;
					}
				}
			} else {
				ot_current_first = 0;
			}

			let ot_current_break = dayjs(end.isBefore(curEndBreakHourDate)? end: curEndBreakHourDate).diff(start.isAfter(curStartBreakHourDate)? start: curStartBreakHourDate, 'minute', true);
			if(ot_current_break > 0 && isReasonBreak){
				if(curIsWorking === 1){
					xOT += ot_current_break
				} else if(curIsWorking === 0){
					if(isPaymentDaily){
						if(
							curPattern.isCompensation ||
							(curPattern.isHoliday && !curPattern.isShiftDaily) ||
							(curPattern.isShiftDaily && curPattern.idHoliday)
						){
							xWorkingDaily += ot_current_break;
						}else{
							xWorkingDailyHoliday += ot_current_break;
						}
					}else{
						xWorkingMonthlyHoliday += ot_current_break;
					}
				}
			} else {
				ot_current_break = 0;
			}
		}

		let endCurTemp = end.isBefore(curEnd)? end: curEnd;
		let startCurTemp = start.isAfter(curEndBreakHourDate)? start: curEndBreakHourDate;
		let ot_current = dayjs(endCurTemp).diff(startCurTemp, 'minute', true);
		if(nextPattern.breakTime0Min && dayjs(nextStartBreak0Hour).isBetween(startCurTemp, endCurTemp, 'minute', "[)")){
			let sum = 0;
			if(
				dayjs(startCurTemp).isBetween(nextStartBreak0Hour, nextStart, 'minute', "[)") &&
				dayjs(endCurTemp).isBetween(nextStartBreak0Hour, nextStart, 'minute', "(]")
			){
				sum = dayjs(endCurTemp).diff(startCurTemp, 'minute', true);
			}else if(
				dayjs(nextStartBreak0Hour).isBetween(startCurTemp, endCurTemp, 'minute', "[)")
			){
				sum = dayjs(endCurTemp).diff(nextStartBreak0Hour, 'minute', true);
			}else if(
				dayjs(nextStart).isBetween(startCurTemp, endCurTemp, 'minute', "[)")
			){
				sum = dayjs(nextStartBreak0Hour).diff(startCurTemp, 'minute', true);
			}
			ot_current -= sum;
		}
		if(ot_current > 0){
			if(curIsWorking === 1){
				overlapWorking += ot_current;
			} else if(curIsWorking === 0){
				if(isPaymentDaily){
					if(
						curPattern.isCompensation ||
						(curPattern.isHoliday && !curPattern.isShiftDaily) ||
						(curPattern.isShiftDaily && curPattern.idHoliday)
					){
						xWorkingDaily += ot_current;
					}else{
						xWorkingDailyHoliday += ot_current;
					}
				}else{
					xWorkingMonthlyHoliday += ot_current;
				}
			}
		} else {
			ot_current = 0;
		}

		if(!curEndBreak2Hour.isSame(curEnd, 'minute')){
			let ot_cur_break2 = dayjs(end.isBefore(curEndBreak2Hour)? end: curEndBreak2Hour).diff(start.isAfter(curEnd)? start: curEnd, 'minute', true)
			if(ot_cur_break2 > 0 && isReasonBreak){
				let endTemp = end.isBefore(curEndBreak2Hour)? end: curEndBreak2Hour;
				let startTemp = start.isAfter(curEnd)? start: curEnd;
				if(nextDayCutOffTime.isSameOrAfter(endTemp, "minute")){
					if(curIsWorking === 1){
						xOT += ot_cur_break2
					} else if(curIsWorking === 0){
						xOTHoliday += ot_cur_break2
					}
				}else if(nextDayCutOffTime.isSameOrBefore(startTemp, "minute")){
					if(nextIsWorking === 1){
						xOT += ot_cur_break2
					} else if(nextIsWorking === 0){
						xOTHoliday += ot_cur_break2
					}
				}else if(nextDayCutOffTime.isBetween(startTemp, endTemp, "minute", "()")){
					let firstHalf = nextDayCutOffTime.diff(startTemp, "minute");
					let secondHalf = endTemp.diff(nextDayCutOffTime, "minute");
					if(curIsWorking === 1){
						xOT += firstHalf
					} else if(curIsWorking === 0){
						xOTHoliday += firstHalf
					}
					if(nextIsWorking === 1){
						xOT += secondHalf
					} else if(nextIsWorking === 0){
						xOTHoliday += secondHalf
					}
				}
			} else {
				ot_cur_break2 = 0;
			}
		}

		let ot_pre_next = dayjs(end.isBefore(nextStartBreak0Hour)? end: nextStartBreak0Hour).diff(start.isAfter(curEndBreak2Hour)? start: curEndBreak2Hour, 'minute', true)
		if(ot_pre_next > 0){
			let endTemp = end.isBefore(nextStartBreak0Hour)? end: nextStartBreak0Hour;
			let startTemp = start.isAfter(curEndBreak2Hour)? start: curEndBreak2Hour;
			if(nextDayCutOffTime.isSameOrAfter(endTemp, "minute")){
				if(curIsWorking === 1){
					xOT += ot_pre_next
				} else if(curIsWorking === 0){
					xOTHoliday += ot_pre_next
				}
			}else if(nextDayCutOffTime.isSameOrBefore(startTemp, "minute")){
				if(nextIsWorking === 1){
					xOT += ot_pre_next
				} else if(nextIsWorking === 0){
					xOTHoliday += ot_pre_next
				}
			}else if(nextDayCutOffTime.isBetween(startTemp, endTemp, "minute", "()")){
				let firstHalf = nextDayCutOffTime.diff(startTemp, "minute");
				let secondHalf = endTemp.diff(nextDayCutOffTime, "minute");
				if(curIsWorking === 1){
					xOT += firstHalf
				} else if(curIsWorking === 0){
					xOTHoliday += firstHalf
				}
				if(nextIsWorking === 1){
					xOT += secondHalf
				} else if(nextIsWorking === 0){
					xOTHoliday += secondHalf
				}
			}
		} else {
			ot_pre_next = 0;
		}

		if(nextPattern.breakTime0Min){
			let ot_nextbreaktime_0 = dayjs(end.isBefore(nextStart)? end: nextStart).diff(start.isAfter(nextStartBreak0Hour)? start: nextStartBreak0Hour, 'minute', true);
			if(ot_nextbreaktime_0 > 0 && isReasonBreak){
				let endTemp = end.isBefore(nextStart)? end: nextStart;
				let startTemp = start.isAfter(nextStartBreak0Hour)? start: nextStartBreak0Hour;
				if(nextDayCutOffTime.isSameOrAfter(endTemp, "minute")){
					if(curIsWorking === 1){
						xOT += ot_nextbreaktime_0
					} else if(curIsWorking === 0){
						xOTHoliday += ot_nextbreaktime_0
					}
				}else if(nextDayCutOffTime.isSameOrBefore(startTemp, "minute")){
					if(nextIsWorking === 1){
						xOT += ot_nextbreaktime_0
					} else if(nextIsWorking === 0){
						xOTHoliday += ot_nextbreaktime_0
					}
				}else if(nextDayCutOffTime.isBetween(startTemp, endTemp, "minute", "()")){
					let firstHalf = nextDayCutOffTime.diff(startTemp, "minute");
					let secondHalf = endTemp.diff(nextDayCutOffTime, "minute");
					if(curIsWorking === 1){
						xOT += firstHalf
					} else if(curIsWorking === 0){
						xOTHoliday += firstHalf
					}
					if(nextIsWorking === 1){
						xOT += secondHalf
					} else if(nextIsWorking === 0){
						xOTHoliday += secondHalf
					}
				}
			} else {
				ot_nextbreaktime_0 = 0;
			}
		}

		// ----------------------------------------------NEXT-DATE------------------------------------------------
		if(nextPattern.breakTime !== 1){
			let ot_next_first = dayjs(end.isBefore(nextStartBreakHourDate)? end: nextStartBreakHourDate).diff(start.isAfter(nextStart)? start: nextStart, 'minute', true);
			if(ot_next_first > 0){
				if(nextIsWorking === 1){
					overlapWorking += ot_next_first;
				} else if(nextIsWorking === 0){
					if(isPaymentDaily){
						if(
							nextPattern.isCompensation ||
							(nextPattern.isHoliday && !nextPattern.isShiftDaily) ||
							(nextPattern.isShiftDaily && nextPattern.idHoliday)
						){
							xWorkingDaily += ot_next_first;
						}else{
							xWorkingDailyHoliday += ot_next_first;
						}
					}else{
						xWorkingMonthlyHoliday += ot_next_first;
					}
				}
			} else {
				ot_next_first = 0;
			}

			let ot_next_break = dayjs(end.isBefore(nextEndBreakHourDate)? end: nextEndBreakHourDate).diff(start.isAfter(nextStartBreakHourDate)? start: nextStartBreakHourDate, 'minute', true);
			if(ot_next_break > 0 && isReasonBreak){
				if(nextIsWorking === 1){
					xOT += ot_next_break
				} else if(nextIsWorking === 0){
					if(isPaymentDaily){
						if(
							nextPattern.isCompensation ||
							(nextPattern.isHoliday && !nextPattern.isShiftDaily) ||
							(nextPattern.isShiftDaily && nextPattern.idHoliday)
						){
							xWorkingDaily += ot_next_break;
						}else{
							xWorkingDailyHoliday += ot_next_break;
						}
					}else{
						xWorkingMonthlyHoliday += ot_next_break;
					}
				}
			} else {
				ot_next_break = 0;
			}
		}

		let ot_next = dayjs(end.isBefore(nextEnd)? end: nextEnd).diff(start.isAfter(nextEndBreakHourDate)? start: nextEndBreakHourDate, 'minute', true)
		if(ot_next > 0){
			if(nextIsWorking === 1){
				overlapWorking += ot_next;
			} else if(nextIsWorking === 0){
				if(isPaymentDaily){
					if(
						nextPattern.isCompensation ||
						(nextPattern.isHoliday && !nextPattern.isShiftDaily) ||
						(nextPattern.isShiftDaily && nextPattern.idHoliday)
					){
						xWorkingDaily += ot_next;
					}else{
						xWorkingDailyHoliday += ot_next;
					}
				}else{
					xWorkingMonthlyHoliday += ot_next;
				}
			}
		} else {
			ot_next = 0;
		}
	}

	if(curPattern.idWorkingType === 2){
		let allValue = {};

		if(prePattern.breakTime !== 1){
			let ot_previous_first = dayjs(end.isBefore(preStartBreakHourDate)? end: preStartBreakHourDate).diff(start.isAfter(preStart)? start: preStart, 'minute', true);
			if(ot_previous_first > 0){
				if(preIsWorking === 1){
					overlapWorking += ot_previous_first;
				} else if(preIsWorking === 0){
					if(isPaymentDaily){
						if(
							prePattern.isCompensation ||
							(prePattern.isHoliday && !prePattern.isShiftDaily) ||
							(prePattern.isShiftDaily && prePattern.idHoliday)
						){
							xWorkingDaily += ot_previous_first;
						}else{
							xWorkingDailyHoliday += ot_previous_first;
						}
					}else{
						xWorkingMonthlyHoliday += ot_previous_first;
					}
				}
			} else {
				ot_previous_first = 0;
			}

			let ot_previous_break = dayjs(end.isBefore(preEndBreakHourDate)? end: preEndBreakHourDate).diff(start.isAfter(preStartBreakHourDate)? start: preStartBreakHourDate, 'minute', true);
			if(ot_previous_break > 0 && isReasonBreak){
				if(preIsWorking === 1){
					xOT += ot_previous_break
				} else if(preIsWorking === 0){
					if(isPaymentDaily){
						if(
							prePattern.isCompensation ||
							(prePattern.isHoliday && !prePattern.isShiftDaily) ||
							(prePattern.isShiftDaily && prePattern.idHoliday)
						){
							xWorkingDaily += ot_previous_break;
						}else{
							xWorkingDailyHoliday += ot_previous_break;
						}
					}else{
						xWorkingMonthlyHoliday += ot_previous_break;
					}
				}
			} else {
				ot_previous_break = 0;
			}
		}

		let endPreTemp = end.isBefore(preEnd)? end: preEnd;
		let startPreTemp = start.isAfter(preEndBreakHourDate)? start: preEndBreakHourDate;
		let ot_previous = dayjs(endPreTemp).diff(startPreTemp, 'minute', true);
		if(curPattern.breakTime0Min && dayjs(curStartBreak0Hour).isBetween(startPreTemp, endPreTemp, 'minute', "[)")){
			let sum = 0;
			if(
				dayjs(startPreTemp).isBetween(curStartBreak0Hour, curStart, 'minute', "[)") &&
				dayjs(endPreTemp).isBetween(curStartBreak0Hour, curStart, 'minute', "(]")
			){
				sum = dayjs(endPreTemp).diff(startPreTemp, 'minute', true);
			}else if(
				dayjs(curStartBreak0Hour).isBetween(startPreTemp, endPreTemp, 'minute', "[)")
			){
				sum = dayjs(endPreTemp).diff(curStartBreak0Hour, 'minute', true);
			}else if(
				dayjs(curStart).isBetween(startPreTemp, endPreTemp, 'minute', "[)")
			){
				sum = dayjs(curStartBreak0Hour).diff(startPreTemp, 'minute', true);
			}
			ot_previous -= sum;
		}
		if(ot_previous > 0){
			if(preIsWorking === 1){
				overlapWorking += ot_previous;
			} else if(preIsWorking === 0){
				if(isPaymentDaily){
					if(
						prePattern.isCompensation ||
						(prePattern.isHoliday && !prePattern.isShiftDaily) ||
						(prePattern.isShiftDaily && prePattern.idHoliday)
					){
						xWorkingDaily += ot_previous;
					}else{
						xWorkingDailyHoliday += ot_previous;
					}
				}else{
					xWorkingMonthlyHoliday += ot_previous;
				}
			}
		} else {
			ot_previous = 0;
		}

		if(!preEndBreak2Hour.isSame(preEnd, 'minute')){
			let ot_pre_break2 = dayjs(end.isBefore(preEndBreak2Hour)? end: preEndBreak2Hour).diff(start.isAfter(preEnd)? start: preEnd, 'minute', true)
			if(ot_pre_break2 > 0 && isReasonBreak){
				let endTemp = end.isBefore(preEndBreak2Hour)? end: preEndBreak2Hour;
				let startTemp = start.isAfter(preEnd)? start: preEnd;
				if(curDayCutOffTime.isSameOrAfter(endTemp, "minute")){
					if(preIsWorking === 1){
						xOT += ot_pre_break2
					} else if(preIsWorking === 0){
						xOTHoliday += ot_pre_break2
					}
				}else if(curDayCutOffTime.isSameOrBefore(startTemp, "minute")){
					if(curIsWorking === 1){
						xOT += ot_pre_break2
					} else if(curIsWorking === 0){
						xOTHoliday += ot_pre_break2
					}
				}else if(curDayCutOffTime.isBetween(startTemp, endTemp, "minute", "()")){
					let firstHalf = curDayCutOffTime.diff(startTemp, "minute");
					let secondHalf = endTemp.diff(curDayCutOffTime, "minute");
					if(preIsWorking === 1){
						xOT += firstHalf
					} else if(preIsWorking === 0){
						xOTHoliday += firstHalf
					}
					if(curIsWorking === 1){
						xOT += secondHalf
					} else if(curIsWorking === 0){
						xOTHoliday += secondHalf
					}
				}
			} else {
				ot_pre_break2 = 0;
			}
		}

		let ot_pre_current = dayjs(end.isBefore(curStartBreak0Hour)? end: curStartBreak0Hour).diff(start.isAfter(preEndBreak2Hour)? start: preEndBreak2Hour, 'minute', true)
		if(ot_pre_current > 0){
			let endTemp = end.isBefore(curStartBreak0Hour)? end: curStartBreak0Hour;
			let startTemp = start.isAfter(preEndBreak2Hour)? start: preEndBreak2Hour;
			if(curDayCutOffTime.isSameOrAfter(endTemp, "minute")){
				if(preIsWorking === 1){
					xOT += ot_pre_current
				} else if(preIsWorking === 0){
					xOTHoliday += ot_pre_current
				}
			}else if(curDayCutOffTime.isSameOrBefore(startTemp, "minute")){
				if(curIsWorking === 1){
					xOT += ot_pre_current
				} else if(curIsWorking === 0){
					xOTHoliday += ot_pre_current
				}
			}else if(curDayCutOffTime.isBetween(startTemp, endTemp, "minute", "()")){
				let firstHalf = curDayCutOffTime.diff(startTemp, "minute");
				let secondHalf = endTemp.diff(curDayCutOffTime, "minute");
				if(preIsWorking === 1){
					xOT += firstHalf
				} else if(preIsWorking === 0){
					xOTHoliday += firstHalf
				}
				if(curIsWorking === 1){
					xOT += secondHalf
				} else if(curIsWorking === 0){
					xOTHoliday += secondHalf
				}
			}
		} else {
			ot_pre_current = 0;
		}

		if(curPattern.breakTime0Min){
			let ot_pre_current_break0 = dayjs(end.isBefore(curStart)? end: curStart).diff(start.isAfter(curStartBreak0Hour)? start: curStartBreak0Hour, 'minute', true)
			allValue["ot_pre_current_break0"] = ot_pre_current_break0;
			if(ot_pre_current_break0 > 0  && isReasonBreak){
				if(curIsWorking === 1){
					xOT += ot_pre_current_break0
				} else if(curIsWorking === 0){
					xOTHoliday += ot_pre_current_break0
				}
			} else {
				ot_pre_current = 0;
			}
		}

		// ----------------------------------------------CUR-DATE------------------------------------------------
		if(curPattern.breakTime !== 1){
			let ot_prebreak = dayjs(end.isBefore(curStartBreakHourDate)? end: curStartBreakHourDate).diff(start.isAfter(curStart)? start: curStart, 'minute', true)
			allValue["ot_prebreak"] = ot_prebreak;
			if(ot_prebreak > 0){
				if(curIsWorking === 1){
					overlapWorking += ot_prebreak
				} else if(curIsWorking === 0){
					if(isPaymentDaily){
						if(
							curPattern.isCompensation ||
							(curPattern.isHoliday && !curPattern.isShiftDaily) ||
							(curPattern.isShiftDaily && curPattern.idHoliday)
						){
							xWorkingDaily += ot_prebreak;
						}else{
							xWorkingDailyHoliday += ot_prebreak;
						}
					}else{
						xWorkingMonthlyHoliday += ot_prebreak;
					}
				}
			}

			let ot_break = dayjs(end.isBefore(curEndBreakHourDate)? end: curEndBreakHourDate).diff(start.isAfter(curStartBreakHourDate)? start: curStartBreakHourDate, 'minute', true);
			allValue["ot_break"] = ot_break;
			if(ot_break > 0 && isReasonBreak){
				if(curIsWorking === 1){
					xOT += ot_break
				} else if(curIsWorking === 0){
					if(isPaymentDaily){
						if(
							curPattern.isCompensation ||
							(curPattern.isHoliday && !curPattern.isShiftDaily) ||
							(curPattern.isShiftDaily && curPattern.idHoliday)
						){
							xWorkingDaily += ot_break;
						}else{
							xWorkingDailyHoliday += ot_break;
						}
					}else{
						xWorkingMonthlyHoliday += ot_break;
					}
				}
			}
		}

		let endCurTemp = end.isBefore(curEnd)? end: curEnd;
		let startCurTemp = start.isAfter(curEndBreakHourDate)? start: curEndBreakHourDate;
		let ot_current = dayjs(endCurTemp).diff(startCurTemp, 'minute', true);
		if(nextPattern.breakTime0Min && dayjs(nextStartBreak0Hour).isBetween(startCurTemp, endCurTemp, 'minute', "[)")){
			let sum = 0;
			if(
				dayjs(startCurTemp).isBetween(nextStartBreak0Hour, nextStart, 'minute', "[)") &&
				dayjs(endCurTemp).isBetween(nextStartBreak0Hour, nextStart, 'minute', "(]")
			){
				sum = dayjs(endCurTemp).diff(startCurTemp, 'minute', true);
			}else if(
				dayjs(nextStartBreak0Hour).isBetween(startCurTemp, endCurTemp, 'minute', "[)")
			){
				sum = dayjs(endCurTemp).diff(nextStartBreak0Hour, 'minute', true);
			}else if(
				dayjs(nextStart).isBetween(startCurTemp, endCurTemp, 'minute', "[)")
			){
				sum = dayjs(nextStartBreak0Hour).diff(startCurTemp, 'minute', true);
			}
			ot_current -= sum;
		}
		if(ot_current > 0){
			if(curIsWorking === 1){
				overlapWorking += ot_current;
			} else if(curIsWorking === 0){
				if(isPaymentDaily){
					if(
						curPattern.isCompensation ||
						(curPattern.isHoliday && !curPattern.isShiftDaily) ||
						(curPattern.isShiftDaily && curPattern.idHoliday)
					){
						xWorkingDaily += ot_current;
					}else{
						xWorkingDailyHoliday += ot_current;
					}
				}else{
					xWorkingMonthlyHoliday += ot_current;
				}
			}
		} else {
			ot_current = 0;
		}

		if(!curEndBreak2Hour.isSame(curEnd, 'minute')){
			let ot_cur_break2 = dayjs(end.isBefore(curEndBreak2Hour)? end: curEndBreak2Hour).diff(start.isAfter(curEnd)? start: curEnd, 'minute', true)
			if(ot_cur_break2 > 0 && isReasonBreak){
				let endTemp = end.isBefore(curEndBreak2Hour)? end: curEndBreak2Hour;
				let startTemp = start.isAfter(curEnd)? start: curEnd;
				if(nextDayCutOffTime.isSameOrAfter(endTemp, "minute")){
					if(curIsWorking === 1){
						xOT += ot_cur_break2
					} else if(curIsWorking === 0){
						xOTHoliday += ot_cur_break2
					}
				}else if(nextDayCutOffTime.isSameOrBefore(startTemp, "minute")){
					if(nextIsWorking === 1){
						xOT += ot_cur_break2
					} else if(nextIsWorking === 0){
						xOTHoliday += ot_cur_break2
					}
				}else if(nextDayCutOffTime.isBetween(startTemp, endTemp, "minute", "()")){
					let firstHalf = nextDayCutOffTime.diff(startTemp, "minute");
					let secondHalf = endTemp.diff(nextDayCutOffTime, "minute");
					if(curIsWorking === 1){
						xOT += firstHalf
					} else if(curIsWorking === 0){
						xOTHoliday += firstHalf
					}
					if(nextIsWorking === 1){
						xOT += secondHalf
					} else if(nextIsWorking === 0){
						xOTHoliday += secondHalf
					}
				}
			} else {
				ot_cur_break2 = 0;
			}
		}

		let ot_pre_next = dayjs(end.isBefore(nextStartBreak0Hour)? end: nextStartBreak0Hour).diff(start.isAfter(curEndBreak2Hour)? start: curEndBreak2Hour, 'minute', true)
		if(ot_pre_next > 0){
			let endTemp = end.isBefore(nextStartBreak0Hour)? end: nextStartBreak0Hour;
			let startTemp = start.isAfter(curEndBreak2Hour)? start: curEndBreak2Hour;
			if(nextDayCutOffTime.isSameOrAfter(endTemp, "minute")){
				if(curIsWorking === 1){
					xOT += ot_pre_next
				} else if(curIsWorking === 0){
					xOTHoliday += ot_pre_next
				}
			}else if(nextDayCutOffTime.isSameOrBefore(startTemp, "minute")){
				if(nextIsWorking === 1){
					xOT += ot_pre_next
				} else if(nextIsWorking === 0){
					xOTHoliday += ot_pre_next
				}
			}else if(nextDayCutOffTime.isBetween(startTemp, endTemp, "minute", "()")){
				let firstHalf = nextDayCutOffTime.diff(startTemp, "minute");
				let secondHalf = endTemp.diff(nextDayCutOffTime, "minute");
				if(curIsWorking === 1){
					xOT += firstHalf
				} else if(curIsWorking === 0){
					xOTHoliday += firstHalf
				}
				if(nextIsWorking === 1){
					xOT += secondHalf
				} else if(nextIsWorking === 0){
					xOTHoliday += secondHalf
				}
			}
		} else {
			ot_pre_next = 0;
		}

		if(nextPattern.breakTime0Min){
			let ot_nextbreaktime_0 = dayjs(end.isBefore(nextStart)? end: nextStart).diff(start.isAfter(nextStartBreak0Hour)? start: nextStartBreak0Hour, 'minute', true);
			if(ot_nextbreaktime_0 > 0 && isReasonBreak){
				let endTemp = end.isBefore(nextStart)? end: nextStart;
				let startTemp = start.isAfter(nextStartBreak0Hour)? start: nextStartBreak0Hour;
				if(nextDayCutOffTime.isSameOrAfter(endTemp, "minute")){
					if(curIsWorking === 1){
						xOT += ot_nextbreaktime_0
					} else if(curIsWorking === 0){
						xOTHoliday += ot_nextbreaktime_0
					}
				}else if(nextDayCutOffTime.isSameOrBefore(startTemp, "minute")){
					if(nextIsWorking === 1){
						xOT += ot_nextbreaktime_0
					} else if(nextIsWorking === 0){
						xOTHoliday += ot_nextbreaktime_0
					}
				}else if(nextDayCutOffTime.isBetween(startTemp, endTemp, "minute", "()")){
					let firstHalf = nextDayCutOffTime.diff(startTemp, "minute");
					let secondHalf = endTemp.diff(nextDayCutOffTime, "minute");
					if(curIsWorking === 1){
						xOT += firstHalf
					} else if(curIsWorking === 0){
						xOTHoliday += firstHalf
					}
					if(nextIsWorking === 1){
						xOT += secondHalf
					} else if(nextIsWorking === 0){
						xOTHoliday += secondHalf
					}
				}
			} else {
				ot_nextbreaktime_0 = 0;
			}
		}

		// ----------------------------------------------NEXT-DATE------------------------------------------------
		if(nextPattern.breakTime !== 1){
			let ot_next_first = dayjs(end.isBefore(nextStartBreakHourDate)? end: nextStartBreakHourDate).diff(start.isAfter(nextStart)? start: nextStart, 'minute', true);
			if(ot_next_first > 0){
				if(nextIsWorking === 1){
					overlapWorking += ot_next_first;
				} else if(nextIsWorking === 0){
					if(isPaymentDaily){
						if(
							nextPattern.isCompensation ||
							(nextPattern.isHoliday && !nextPattern.isShiftDaily) ||
							(nextPattern.isShiftDaily && nextPattern.idHoliday)
						){
							xWorkingDaily += ot_next_first;
						}else{
							xWorkingDailyHoliday += ot_next_first;
						}
					}else{
						xWorkingMonthlyHoliday += ot_next_first;
					}
				}
			} else {
				ot_next_first = 0;
			}

			let ot_next_break = dayjs(end.isBefore(nextEndBreakHourDate)? end: nextEndBreakHourDate).diff(start.isAfter(nextStartBreakHourDate)? start: nextStartBreakHourDate, 'minute', true);
			if(ot_next_break > 0 && isReasonBreak){
				if(nextIsWorking === 1){
					xOT += ot_next_break
				} else if(nextIsWorking === 0){
					if(isPaymentDaily){
						if(
							nextPattern.isCompensation ||
							(nextPattern.isHoliday && !nextPattern.isShiftDaily) ||
							(nextPattern.isShiftDaily && nextPattern.idHoliday)
						){
							xWorkingDaily += ot_next_break;
						}else{
							xWorkingDailyHoliday += ot_next_break;
						}
					}else{
						xWorkingMonthlyHoliday += ot_next_break;
					}
				}
			} else {
				ot_next_break = 0;
			}
		}

		let ot_next = dayjs(end.isBefore(nextEnd)? end: nextEnd).diff(start.isAfter(nextEndBreakHourDate)? start: nextEndBreakHourDate, 'minute', true)
		if(ot_next > 0){
			if(nextIsWorking === 1){
				overlapWorking += ot_next;
			} else if(nextIsWorking === 0){
				if(isPaymentDaily){
					if(
						nextPattern.isCompensation ||
						(nextPattern.isHoliday && !nextPattern.isShiftDaily) ||
						(nextPattern.isShiftDaily && nextPattern.idHoliday)
					){
						xWorkingDaily += ot_next;
					}else{
						xWorkingDailyHoliday += ot_next;
					}
				}else{
					xWorkingMonthlyHoliday += ot_next;
				}
			}
		} else {
			ot_next = 0;
		}

		// console.log("allValue : ",allValue);
	}

	if (idRequestType === 3 && isFinger === 0) {
		isInTimeFrame = true;
	}

	const result = {
		xOT: xOT,
		xOTHoliday: xOTHoliday,
		xWorkingDaily: xWorkingDaily,
		xWorkingDailyHoliday: xWorkingDailyHoliday,
		xWorkingMonthlyHoliday: xWorkingMonthlyHoliday,
		overlapWorking: overlapWorking,
		isErrorBreakTime: isErrorBreakTime,
		isInTimeFrame: isInTimeFrame,
		isOutOfRange: isOutOfRange
	}

	return result;

}

const InterSectionTime = (start, end, startCheck, endCheck) => {
	let time = 0;
	if(
		startCheck.isBetween(start, end, "minute") &&
		endCheck.isBetween(start, end, "minute")
	){
		time = endCheck.diff(startCheck, "minute");
	} else if(
		startCheck.isBetween(start, end, "minute")
	){
		time = end.diff(startCheck, "minute");
	} else if(
		endCheck.isBetween(start, end, "minute")
	){
		time = endCheck.diff(start, "minute");
	}
	return time;
};

export const calculatorOvertimeByWorkingHours = (start, end, options) => {
	const {
		curPattern,
		idPaymentType,
		attendance,
		requestTime,
		date,
		ot,
		leave,
		employee,
		isFinger,
		idRequestType
	} = options;
	
	let curLeave = (
		leave &&
		leave.length > 0 &&
		leave.find((v) => (v.isApprove === 1 && v.isWithdraw !== 1 && v.isFullDay === 1))
	);

	let curIsWorking = (
		curPattern.isWorkingDay && 
		!(
			curPattern.isCompensation ||
			(curPattern.isHoliday && !curPattern.isShiftDaily) ||
			(curPattern.isShiftDaily && curPattern.idHoliday)
		)
		&&
		!curLeave
	)? 1: 0;

	let startBreakHourDate = dayjs(`${date} ${curPattern.startBreak}`);
	let endBreakHourDate = startBreakHourDate.add(curPattern.breakTimeMin, "minute");

	let xOT = 0;
	let xOTHoliday = 0;
	let xWorkingDaily = 0;
	let xWorkingDailyHoliday = 0;
	let	xWorkingMonthlyHoliday = 0;
	let overlapWorking = 0;
	let isInTimeFrame = false;
	let isBeforeWorkTime = false;

	let isPaymentDaily = false;

	if([1,5].includes(idPaymentType)){
		isPaymentDaily = true;
	}

	if(
		start.isBefore(
			dayjs(`${date} 00:00:00`)
		)
	){
		start = dayjs(`${date} 00:00:00`);
	}

	if(
		end.isAfter(
			dayjs(`${date} 00:00:00`).add(1,'day')
		)
	){
		end = dayjs(`${date} 00:00:00`).add(1,'day');
	}

	let workTime = (curPattern.workingHours - (curPattern.breakTime === 0 ? 60 : 0));
	let otTime = end.diff(start, 'minute');
	if(
		start.isBetween(startBreakHourDate, endBreakHourDate, "minute") &&
		end.isBetween(startBreakHourDate, endBreakHourDate, "minute")
	){
		otTime = 0;
	} else {
		otTime -= InterSectionTime(start, end, startBreakHourDate, endBreakHourDate);
	}
	
	let arrayWorkTime = [];
	let startOT = start;
	let endOT = end;

	if(attendance && attendance.checkIn.length > 0){
		attendance.checkIn.map((time, index) => {
			if(time && !time.isNotActive && attendance.checkOut[index] && !attendance.checkOut[index].isNotActive){
				let checkIn = dayjs(`${date} ${time.attendanceTextTime}:00`);
				let checkOut = dayjs(`${date} ${attendance.checkOut[index].attendanceTextTime}:00`);
				arrayWorkTime.push({ start : checkIn, end : checkOut });
				if(
					startOT.isBetween(checkIn, checkOut, "minute", "[)") &&
					endOT.isBetween(checkIn, checkOut, "minute", "(]")
				){
					isInTimeFrame = true;
				}else if(
					startOT.isBetween(checkIn, checkOut, "minute", "[)")
				){
					startOT = checkOut;
				}else if(
					endOT.isBetween(checkIn, checkOut, "minute", "(]")
				){
					endOT = checkIn;
				}
			}
		});
	}

	if(requestTime && requestTime.length > 0){
		requestTime.map(request => {
			let requestStart = dayjs(`${request.startText}:00`, 'DD-MM-YYYY HH:mm:ss');
			let requestEnd = dayjs(`${request.endText}:00`, 'DD-MM-YYYY HH:mm:ss');
			arrayWorkTime.push({ start : requestStart, end : requestEnd });
			if(
				startOT.isBetween(requestStart, requestEnd, "minute", "[)") &&
				endOT.isBetween(requestStart, requestEnd, "minute", "(]")
			){
				isInTimeFrame = true;
			}else if(
				startOT.isBetween(requestStart, requestEnd, "minute", "[)")
			){
				startOT = requestEnd;
			}else if(
				endOT.isBetween(requestStart, requestEnd, "minute", "(]")
			){
				endOT = requestStart;
			}
		});
	}
	
	if(curIsWorking === 1){
		let startWorkingtime = dayjs(`${date} ${curPattern.timeIn}`);
		let startTimeAfterOT = null;
		
		if(start.isBefore(startWorkingtime)){
			isBeforeWorkTime = true;
		}else{
			let workTimeSort = arrayWorkTime.sort((a,b) => {
				let diff = dayjs(a.start).diff(dayjs(b.start), "minute");
				if(diff === 0){
					return dayjs(a.end).diff(dayjs(b.end), "minute");
				}else{
					return diff;
				}
			});

			workTimeSort.map((v, index) => {
				let tempStart = v.start;
				let tempEnd = v.end;

				if(
					tempStart.isBefore(startWorkingtime, "minute") && 
					tempEnd.isAfter(startWorkingtime, "minute")
				){
					tempStart = startWorkingtime;
				}

				if(
					tempStart.isSameOrAfter(startWorkingtime, "minute") && 
					tempEnd.isAfter(dayjs(`${date} 00:00:00`).add(1, "day"), "minute")
				){
					tempEnd = dayjs(`${date} 00:00:00`);
				}

				if(
					index !== 0 &&
					InterSectionTime(
						workTimeSort[index - 1].start, workTimeSort[index - 1].end, 
						tempStart, tempEnd
					) > 0 && tempEnd.isAfter(workTimeSort[index - 1].end, "minute")
				){
					if(tempEnd.isBefore(workTimeSort[index - 1].end, "minute")){
						return;
					}else{
						tempStart = workTimeSort[index - 1].end;
					}
				}

				if(
					tempStart.isSameOrAfter(startWorkingtime, "minute") &&
					tempEnd.isAfter(startWorkingtime, "minute") &&
					workTime !== 0
				){
					let diff = dayjs(tempEnd).diff(dayjs(tempStart), "minute") 
							- InterSectionTime(tempStart, tempEnd, startBreakHourDate, endBreakHourDate);		
				if(
						!startTimeAfterOT && ((workTime - diff) <= 0)
					){
						startTimeAfterOT = dayjs(tempStart).add(
							(workTime + InterSectionTime(tempStart, tempEnd, startBreakHourDate, endBreakHourDate)), 
							"minute"
						);
						workTime = 0;
					}else{
						if((workTime - diff) <= 0){
							startTimeAfterOT = dayjs(tempStart).add(
								(workTime + InterSectionTime(tempStart, tempEnd, startBreakHourDate, endBreakHourDate)), 
								"minute" 
							);
							workTime = 0;
						}else{
							startTimeAfterOT = dayjs(tempEnd);
							workTime -= diff;
						}
					}
				}
			});
			
			if(workTime > 0){
				overlapWorking = workTime;
			}else if(
				startOT.isBetween(startWorkingtime, startTimeAfterOT, "minute", "[)")
			){
				overlapWorking = startTimeAfterOT.diff(startOT, "minute");
			} else if(
				endOT.isBetween(startWorkingtime, startTimeAfterOT, "minute", "(]")
			){
				overlapWorking = endOT.diff(startWorkingtime, "minute");
			}else if(
				startWorkingtime.isBetween(startOT, endOT, "minute", "[)") &&
				startTimeAfterOT.isBetween(startOT, endOT, "minute", "[)")
			){
				overlapWorking = startTimeAfterOT.diff(startWorkingtime, "minute");
			}else {
				xOT += otTime;
			}
		}
	}else{
		let workTime = 0;

		if(ot && ot.length > 0){
			ot.map(eachOT => {
				let OTStart = dayjs(`${eachOT.startText}:00`, 'DD-MM-YYYY HH:mm:ss');
				let OTEnd = dayjs(`${eachOT.endText}:00`, 'DD-MM-YYYY HH:mm:ss');
				workTime += (OTEnd.diff(OTStart, "minute") - InterSectionTime(OTStart, OTEnd, startBreakHourDate, endBreakHourDate));
			});
		}

		let realWorkingHours = curPattern.workingHours - (curPattern.breakTime === 0 ? 60 : 0);
		if(workTime >= realWorkingHours){
			xOTHoliday += otTime;
		} else {
			let timeOnWorkHours = (otTime > (realWorkingHours - workTime)) ? (realWorkingHours - workTime) : otTime;
			if(isPaymentDaily){
				if(
					curPattern.isCompensation ||
					(curPattern.isHoliday && !curPattern.isShiftDaily) ||
					(curPattern.isShiftDaily && curPattern.idHoliday)
				){
					xWorkingDaily += timeOnWorkHours;
				}else{
					xWorkingDailyHoliday += timeOnWorkHours;
				}
			}else{
				xWorkingMonthlyHoliday += timeOnWorkHours;
			}

			let timeOnOT = otTime - timeOnWorkHours;
			if(timeOnOT > 0){
				xOTHoliday += timeOnOT;
			}
		}
	}

	if (idRequestType === 3 && isFinger === 0) {
		isInTimeFrame = true;
	}

	const result = {
		xOT: xOT,
		xOTHoliday: xOTHoliday,
		xWorkingDaily: xWorkingDaily,
		xWorkingDailyHoliday: xWorkingDailyHoliday,
		xWorkingMonthlyHoliday: xWorkingMonthlyHoliday,
		overlapWorking: overlapWorking,
		isInTimeFrame: isInTimeFrame,
		isBeforeWorkTime: isBeforeWorkTime
	}

	return result;

}