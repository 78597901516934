import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Grid,
  Paper,
  Avatar,
  Button,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Fade,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import AddIcon from "@mui/icons-material/Add";
import AddOrEditJobPosition from "./add/addOrEditJobPosition";
import ConfirmDialog from "../../../shared/general/ConfirmDialog";

import { getAllJobStructure } from "../../../../../actions/jobStructures";

import { deleteJobPosition } from "../../../../../actions/jobPositions";

import { getAllJob } from "../../../../../actions/job";
import LanIcon from "@mui/icons-material/Lan";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import "./text.css";



const useStyles = makeStyles(() => ({
  bigAvatar: {
    width: "180px",
    height: "230px",
  },
  button: {
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#007afc",
  },
  icon: {
    fontSize: 28,
    // marginBottom: 5,
    color: "white",
  },

  text: {
    textAlign: "center",
    paddingTop: 5,
    color: "white",
    fontSize: "1.25rem",
    fontWeight: "600",
  },
}));

const JobPosition = ({
  jobId,
  subJobId,
  jobStructureList,
  admin,
  fetchData,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: jobStructureStore } = useSelector(
    (state) => state.jobStructures
  );

  const [openAdd, setOpenAdd] = useState(false);

  const handleClose = () => {
    setOpenAdd(false);
  };

  const ActionButton = (jobPosition) => {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openEdit, setOpenEdit] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({
      isOpen: false,
      title: "",
      subTitle: "",
    });

    const handleClick = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleActionClose = () => {
      setAnchorEl(null);
    };

    const handleEditClose = () => {
      setOpenEdit(false);
    };

    const handleOnEdit = (data) => {
      setOpenEdit(true);
      handleActionClose();
    };

    const handleOnInsertDetails = (data) => {
      history.push({
        pathname: `/admin/jobStructure/formJob/${data.jobPositionName}`,
        state: {
          positions: data,
        },
      });
    };

    const handleOnDelete = (data) => {
      handleActionClose();
      setConfirmDialog({
        isOpen: true,
        title: "Are you sure you want to delete ?",
        subTitle: "You can't undo this operation",
        onConfirm: () => {
          onDelete(data.idJobPosition, data);
        },
      });
    };

    const onDelete = (id, data) => {
      onDeleteJobPosition(id, data);
    };

    const onDeleteJobPosition = async (id) => {
      await dispatch(deleteJobPosition(id));
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
      fetchData();
    };

    return (
      <>
        <IconButton
          id="fade-button"
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{
            backgroundColor: "#fff",
            "&:hover": { backgroundColor: "#eeeeee" },
          }}
          size="small"
        >
          <MoreVertIcon sx={{ color: "#212121" }} />
        </IconButton>
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleActionClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={() => handleOnEdit(jobPosition.jobPosition)}>
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => handleOnInsertDetails(jobPosition.jobPosition)}
          >
            Insert Details
          </MenuItem>
          <MenuItem
            sx={{ color: "#e53935" }}
            onClick={() => handleOnDelete(jobPosition.jobPosition)}
          >
            Delete
          </MenuItem>
        </Menu>
        {openEdit && (
          <AddOrEditJobPosition
            open={openEdit}
            subJobId={jobPosition.jobPosition.idSubJobStructure}
            positionData={jobPosition.jobPosition}
            close={handleEditClose}
            fetchData={fetchData}
          />
        )}
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      </>
    );
  };

  return (
    
      
        <div
          className="page"
          style={{ paddingTop: 40, padding: 0, minHeight: "50px" }}
        >
          {jobStructureList &&
            jobStructureList
              .filter((value) => value.idJobStructure == jobId)
              .map((value) =>
                value.subJobList
                  .filter((val) => val.idSubJobStructure == subJobId)
                  .map((value2, index) => (
                    <div key={index} style={{ margin: 0, padding: 0 }}>
                      <hr />
                      <Grid container direction="row" alignItems="center">
                        <Grid item xl={9} lg={9} md={9} xs={12} sm={12}>
                          <h2 style={{ fontWeight: 600 }}>
                            All Job Position {value2.subJobStructureName}
                          </h2>
                        </Grid>
                        {admin && (
                          <Grid item xl={3} lg={3} md={3} xs={12} sm={12}>
                            <Grid container justifyContent="flex-end">
                              <Button
                                style={{
                                  height: 50,
                                  width: 200,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: "#007afc",
                                }}
                                onClick={(e) => setOpenAdd(true)}
                              >
                                <AddIcon className={classes.icon} />
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "white",
                                    fontSize: 16,
                                    marginBottom: 0,
                                  }}
                                >
                                  Add new Position
                                </Typography>
                              </Button>
                              {openAdd != false && (
                                <AddOrEditJobPosition
                                  open={openAdd}
                                  subJobId={subJobId}
                                  close={handleClose}
                                  fetchData={fetchData}
                                />
                              )}
                              {/*
                              <Link
                                to={{
                                  pathname: `/admin/jobStructure/formJob/${value.jobStructureName} ${value2.subJobStructureName}`,
                                  state: {
                                    subJobName: value2.subJobStructureName,
                                    jobId: jobId,
                                  },
                                }}
                                style={{ textDecoration: "none" }}
                              >
                                <Button
                                  style={{
                                    height: 50,
                                    width: 220,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#007afc",
                                  }}
                                >
                                  <AddIcon className={classes.icon} />
                                  <Typography
                                    style={{
                                      fontWeight: "bold",
                                      color: "white",
                                      fontSize: 16,
                                      marginBottom: 0,
                                    }}
                                  >
                                    Add new job position
                                  </Typography>
                                </Button>
                              </Link>
                              */}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        style={{ paddingRight: 50, paddingLeft: 50 }}
                      >
                        {value2.positionList.map((value3, index) => (
                          <Grid item sm={6} xs={12} md={4} lg={4} key={index}>
                            <Paper
                              style={{
                                margin: 20,
                                padding: 10,
                                backgroundColor: value.backgroundColor,
                                cursor: "pointer",
                                borderRadius: 20,
                                border: "3px solid #fff",
                              }}
                            >
                              <Box sx={{ marginBottom: 2 }}>
                                <Grid
                                  container
                                  justifyContent="end"
                                  spacing={2}
                                >
                                  <Grid item>
                                    <ActionButton jobPosition={value3} />
                                  </Grid>
                                </Grid>
                              </Box>
                              <Box>
                                <Grid container alignItems="center" spacing={1}>
                                  <Grid item xs={2}>
                                    <LanIcon
                                      sx={{ color: "#fff", fontSize: "28px" }}
                                    />
                                  </Grid>
                                  <Grid item xs>
                                    <Typography
                                      className={`textFixTwoLine ${classes.text}`}
                                    >
                                      {value3.jobPositionName}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Paper>
                            {/*
                            <Link
                              to={
                                admin
                                  ? {
                                      pathname: `/admin/jobStructure/showDetail/${value.jobStructureName} ${value2.idSubJobStructure} ${value3.jobPositionName}`,
                                      state: {
                                        link: "/admin/jobStructure/",
                                        prevText: "Job Structure",
                                      },
                                    }
                                  : {
                                      pathname: `/jobStructure/${value.jobStructureName} ${value2.idSubJobStructure} ${value3.jobPositionName}`,
                                      state: {
                                        link: "/jobStructure",
                                        prevText: "Job Structure",
                                      },
                                    }
                              }
                              style={{
                                textDecoration: "none",
                                width: "200px",
                                height: "300px",
                              }}
                            >
                              <Paper
                                style={{
                                  height: 50,
                                  margin: 20,
                                  padding: 10,
                                  backgroundColor: value.backgroundColor,
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: 20,
                                  border: "3px solid #fff",
                                }}
                              >
                                <Grid container alignItems="center" spacing={1}>
                                  <Grid item xs={3}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "10px",
                                      }}
                                    >
                                      <LanIcon
                                        sx={{ color: "#fff", fontSize: "28px" }}
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid item xs>
                                    <Typography
                                      className={`textFixTwoLine ${classes.text}`}
                                    >
                                      {value3.jobPositionName}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Link>
                            */}
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  ))
              )}
        </div>
     
    
  );
};

export default JobPosition;
