const OrganizationData = {
  name: "หนองมน คนบ้านเดียวกัน",
  title: "MD Officer",
  child: [
    {
      title: "Division 1",
      child: [
        {
          title: "Department 1",
          child: [
            {
              title: "Section Standard",
              child: []
            }
          ],
        },
        {
          title: "Department 2",
          child: [
            {
              title: "Section 1",
              child: [
                {
                  title: "Position 1",
                  child: [],
                },
                {
                  title: "Position 2",
                  child: [],
                },
              ]
            },
            {
              title: "Section 2",
              child: [],
            },
          ]
        },
        {
          title: "Department 3",
          child: [],
        },
      ]
    },
    {
      title: "Division 2",
      child: [],
    },
    {
      title: "Division 3",
      child: [],
    },
  ]
}

export default OrganizationData;