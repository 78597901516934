import React from "react";

import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import ShowInformation from "./showInformation";


const useStyles = makeStyles(() => ({
  root: {
    marginTop: "20px",
    marginBottom: "30px",
    minHeight: "calc(100vh - 50px)",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
}));

const Information = () => {
  const classes = useStyles();

  const { idCompany, idEmployees } = useParams();

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        <ShowInformation id={idEmployees} idCompany={idCompany} />
      </Container>
    </div>
  );
};

export default Information;
