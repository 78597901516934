import experienceService from "../services/experience.service";

export const addExperience = (formData) => async() => {
  try {
    const res = await experienceService.addExperience(formData);
    if (res) {
      return res
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
};

export const updateExperience = (idExperiences, formData) => async() => {
  try {
    const res = await experienceService.updateExperience(idExperiences, formData);
    if (res) {
      return res
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
};

export const deleteExperience = (idExperiences) => async() => {
try {
  const res = await experienceService.deleteExperience(idExperiences);
  if (res) {
    return res
  }
} catch (error) {
  if (error.response) {
    return error.response;
  }
}
};