import React from "react";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const rand36 = () => {
  return Math.floor(Math.random() * 4);
};

function createData(status, name, created, start, stop, ot1, ot15, ot2, ot3) {
  return {
    status,
    name,
    created,
    start,
    stop,
    ot1,
    ot15,
    ot2,
    ot3,
  };
}

const rows = [
  createData(
    0,
    "วันหยุด",
    new Date(2021, 10, 10, 14, 46, 51),
    new Date(2021, 10, 10, 16, 30, 0),
    new Date(2021, 10, 10, 20, 30, 0),
    rand36(),
    rand36(),
    rand36(),
    rand36()
  ),
  createData(
    1,
    "วันทำงาน",
    new Date(2021, 10, 11, 14, 46, 51),
    new Date(2021, 10, 11, 16, 30, 0),
    new Date(2021, 10, 11, 20, 0, 0),
    rand36(),
    rand36(),
    rand36(),
    rand36()
  ),
  createData(
    2,
    "วันทำงาน",
    new Date(2021, 10, 12, 14, 46, 51),
    new Date(2021, 10, 12, 16, 30, 0),
    new Date(2021, 10, 12, 18, 45, 0),
    rand36(),
    rand36(),
    rand36(),
    rand36()
  ),
  createData(
    1,
    "วันหยุด",
    new Date(2021, 10, 13, 14, 46, 51),
    new Date(2021, 10, 13, 0, 0, 0),
    new Date(2021, 10, 13, 0, 0, 0),
    0,
    0,
    0,
    0,
    0
  ),
  createData(
    1,
    "วันทำงาน",
    new Date(2021, 10, 14, 14, 46, 51),
    new Date(2021, 10, 14, 16, 30, 0),
    new Date(2021, 10, 14, 19, 0, 0),
    rand36(),
    rand36(),
    rand36(),
    rand36()
  ),
  createData(
    1,
    "วันทำงาน",
    new Date(2021, 10, 15, 14, 46, 51),
    new Date(2021, 10, 15, 16, 30, 0),
    new Date(2021, 10, 15, 20, 30, 0),
    rand36(),
    rand36(),
    rand36(),
    rand36()
  ),
  createData(
    1,
    "วันหยุด",
    new Date(2021, 10, 16, 15, 46, 51),
    new Date(2021, 10, 16, 0, 0, 0),
    new Date(2021, 10, 16, 0, 0, 0),
    0,
    0,
    0,
    0
  ),
];

const StyledTable = styled(Table)({
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
  },
  "& .tableCellHead:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    // boxShadow: "rgb(255 255 255) 8px 0px 0px inset",
  },
  "& .tableCellHead:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    // boxShadow: "rgb(255 255 255) -8px 0px 0px inset",
  },
  "& .tableCell": {
    borderColor: "#919eab3d",
    padding: "8px 16px",
  },
  "& .tableCellSummary": {
    borderTop: "5px solid #919eab3d",
    borderBottom: "none",
  },
  "& .cellSummaryBase": {
    backgroundColor: "#007afd",
    border: "none",
    "&.first": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    "&.last": {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    },
  },
  "& .cellStatusAction": {
    width: 80,
  },
  "& .cellRequest": {
    width: 100,
  },
  "& .cellStartStop": {
    width: 150,
  },
  "& .cellNumber": {
    textAlign: "center",
    width: 95,
  },
  "& .cellSummary": {
    fontSize: 28,
  },
  "& .cellOver": {
    color: "#e46a76",
    fontWeight: 600,
  },
  "& .cellBaht": {
    fontSize: 14,
    color: "#999999",
  },
  "& .cellBahtSum": {
    fontSize: 14,
    color: "#ffffff",
  },
  "& .tableCellBlank": {
    padding: 4,
  },
});

export default function TableOTReport(props) {
  const { value } = props;
  const { t, i18n } = useTranslation();

  const sumHoursPerDay = (index) => {
    return (
      rows[index].ot1 + rows[index].ot15 + rows[index].ot2 + rows[index].ot3
    );
  };

  const sumHours = () => {
    var sum = 0;
    rows.forEach((element, index) => {
      sum = sum + sumHoursPerDay(index);
    });

    return sum;
  };

  const startOfWeek = () => {
    return dayjs(value).startOf("week");
  };

  return (
    <TableContainer>
      <StyledTable aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              className={`tableCellHead cellStatusAction`}
            >
              {`${t("Date")}`}
            </TableCell>
            <TableCell align="center" className={`tableCellHead cellStartStop`}>
              {`${t("Start")}`}
            </TableCell>
            <TableCell align="center" className={`tableCellHead cellStartStop`}>
              {`${t("End")}`}
            </TableCell>
            <TableCell align="center" className={`tableCellHead cellNumber`}>
              {`${t("Total")}`} ({`${t("Unit.ShortHours")}`})
            </TableCell>
            <TableCell align="center" className={`tableCellHead cellNumber`}>
              OT x 1 ({`${t("Unit.ShortHours")}`})
            </TableCell>
            <TableCell align="center" className={`tableCellHead cellNumber`}>
              OT x 1.5 ({`${t("Unit.ShortHours")}`})
            </TableCell>
            <TableCell align="center" className={`tableCellHead cellNumber`}>
              OT x 2 ({`${t("Unit.ShortHours")}`})
            </TableCell>
            <TableCell align="center" className={`tableCellHead cellNumber`}>
              OT x 3 ({`${t("Unit.ShortHours")}`})
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell
                align="center"
                className={`tableCell cellStatusAction`}
              >
                {dayjs(startOfWeek()).add(index, "day").format("DD/MM/YYYY")}
              </TableCell>
              <TableCell align="center" className={`tableCell cellStartStop`}>
                <div>
                  <Typography>{"-"}</Typography>
                </div>
              </TableCell>
              <TableCell align="center" className={`tableCell cellStartStop`}>
                <div>
                  <Typography>{"-"}</Typography>
                </div>
              </TableCell>
              <TableCell className={`tableCell cellNumber`}>
                <Typography>{0}</Typography>
                <Typography className={`cellBaht`}>
                  = 0 {`${t("Baht")}`}
                </Typography>
              </TableCell>
              <TableCell className={`tableCell cellNumber`}>
                <Typography>{0}</Typography>
                <Typography className={`cellBaht`}>
                  = 0 {`${t("Baht")}`}
                </Typography>
              </TableCell>
              <TableCell className={`tableCell cellNumber`}>
                <Typography>{0}</Typography>
                <Typography className={`cellBaht`}>
                  = 0 {`${t("Baht")}`}
                </Typography>
              </TableCell>
              <TableCell className={`tableCell cellNumber`}>
                <Typography>{0}</Typography>
                <Typography className={`cellBaht`}>
                  = 0 {`${t("Baht")}`}
                </Typography>
              </TableCell>
              <TableCell className={`tableCell cellNumber`}>
                <Typography>{0}</Typography>
                <Typography className={`cellBaht`}>
                  = 0 {`${t("Baht")}`}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
          <TableRow key={98}>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
          </TableRow>
          <TableRow key={99}>
            <TableCell
              align="center"
              colSpan={3}
              className={`tableCell`}
              style={{ border: "none" }}
            >
              <Typography variant="h6">{`${t("TotalWeek")}`}</Typography>
            </TableCell>
            <TableCell className={`tableCell cellNumber cellSummaryBase first`}>
              <div style={{ backgroundColor: "#ffffff", borderRadius: 8 }}>
                <Typography
                  className={`cellSummary ${
                    sumHours() >= 36 ? "cellOver" : ""
                  }`}
                >
                  {0}
                </Typography>
                <Typography className={`cellBaht`}>
                  = 0 {`${t("Baht")}`}
                </Typography>
              </div>
            </TableCell>
            <TableCell className={`tableCell cellNumber cellSummaryBase`}>
              <Typography className={`cellSummary`}>{0}</Typography>
              <Typography className={`cellBahtSum`}>
                = 0 {`${t("Baht")}`}
              </Typography>
            </TableCell>
            <TableCell className={`tableCell cellNumber cellSummaryBase`}>
              <Typography className={`cellSummary`}>{0}</Typography>
              <Typography className={`cellBahtSum`}>
                = 0 {`${t("Baht")}`}
              </Typography>
            </TableCell>
            <TableCell className={`tableCell cellNumber cellSummaryBase`}>
              <Typography className={`cellSummary`}>{0}</Typography>
              <Typography className={`cellBahtSum`}>
                = 0 {`${t("Baht")}`}
              </Typography>
            </TableCell>
            <TableCell className={`tableCell cellNumber cellSummaryBase last`}>
              <Typography className={`cellSummary`}>{0}</Typography>
              <Typography className={`cellBahtSum`}>
                = 0 {`${t("Baht")}`}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
}
