import React from "react";
import { Container } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import ShowInformation from "../../shared/pages/Information";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "100px",
    minHeight: "calc(100vh - 20px)",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
}));

const InformationDivisionAdmin = (props) => {
  const classes = useStyles();

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        <ShowInformation id={props.match.params.id} />
      </Container>
    </div>
  );
};

export default InformationDivisionAdmin;
