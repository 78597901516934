import React, { Fragment, useEffect, useState, useCallback } from "react";
import {
    Box,
    Container,
    Grid,
    styled,
} from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import AI from "./component/AI";
import InfoCard from "./component/InfoCard";
import CustomizingCalendar from "./component/Calendar";
import AbsenceAndRate from "./component/AbsenceAndRate";
import Leave from "./component/Leave";
import OT from "./component/OT";

import CustomizingCalendarUser from "../../user/overviewUser/component/Calendar";
import WorkTimeUser from "../../user/overviewUser/component/WorkTime";
import NotificationUser from "../../user/overviewUser/component/Notification";
import AbsenceAndRateUser from "../../user/overviewUser/component/AbsenceAndRate";
import LeaveUser from "../../user/overviewUser/component/Leave";
import OTUser from "../../user/overviewUser/component/OT";
import TaxUser from "../../user/overviewUser/component/Tax";

import { getUserProfile } from "../../../../actions/user";
import { getAttendanceById } from '../../../../actions/attendance';
import { openNotificationAlert } from '../../../../actions/notificationAlert';
import { getSummaryTime } from "../../../../actions/summary-time";
import { getLeaveAvaiable, getLeaveSetting } from "../../../../actions/leave";
import { getLeaveRequestAllYear } from "../../../../actions/employee";
import { allHoliday } from "../../../../actions/holiday";

import leaveService from "../../../../services/leave.service";
import { getDashboardCost, getDashboardOvertime } from "../../../../actions/dashboard";
import { getAbsentAndLate, getLeave, getNotification, getOT } from "../../../../actions/overviewUser";
import Loading from "../../shared/loading";

const StyledRoot = styled(Box)({
    "& .filter-container": {},
    "& .menu-container": {},
    "& .MuiAutocomplete-root": {},
    "& .MuiSelect-filled": {},
});

function OverviewManager() {
    const { t, i18n } = useTranslation();
    const today = dayjs();
    const [isActive, setIsActive] = useState(false);
    const [isLoadingOT, setIsLoadingOT] = useState(false);
    const [month, setMonth] = useState(today.get("month") + 1);
    const [year, setYear] = useState(today.get("year"));

    const dispatch = useDispatch();
    const { user: currentUser } = useSelector((state) => state.auth);
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: summaryTime, isFetching: summaryTimeFetching } = useSelector((state) => state.summaryTime);
    const { result: leaveAvailable, isFetching: leaveAvailableFetching } = useSelector((state) => state.leaveAvailable);
    const { result: holiday, isFetching: holidayFetching } = useSelector((state) => state.holiday);
    const { result: leaveEmployeesList, isFetching: leaveEmployeesListFetching } = useSelector((state) => state.leaveEmployees);
    const { result: overviewUserLeave, isFetching: overviewUserLeaveFetching } = useSelector((state) => state.overviewUserLeave);
    const { result: overviewUserAbsentLate, isFetching: overviewUserAbsentLateFetching } = useSelector((state) => state.overviewUserAbsentLate);
    const { result: OrganizationList } = useSelector((state) => state.organizationList);
    const { result: affiliateList } = useSelector((state) => state.affiliate);
    const { result: dashboardCost, isFetching: dashboardCostFetching } = useSelector((state) => state.dashboardCost);
    const { result: overviewUserOt, isFetching: overviewUserOtFetching } = useSelector((state) => state.overviewUserOt);
    const { result: notificationList, isFetching: notificationFetching } = useSelector((state) => state.overviewUserNotification);
    const { isFetching: absenceAndLateFetching } = useSelector((state) => state.attendance);


    const [todayAttendance, setTodayAttendance] = useState();
    const [absenceAndLate, setAbsenceAndLate] = useState();
    const [selectedLeaveRoundDate, setSelectedLeaveRoundDate] = useState("");

    const fetchAttendance = useCallback(async () => {
        const result = await dispatch(getAttendanceById({
            start: today.subtract(1, 'month').startOf('month').startOf('day'),
            end: today.endOf('day').toDate(),
        }));

        if (result.status === 200) {
            setTodayAttendance(result.data.find(item => dayjs(item.date).isSame(today, 'day')).attendanceDisplay[0]);

            const filteredAbsenceAndLate = result.data.filter(item =>
                dayjs(item.date).isBetween(today.startOf('month').subtract(1, 'month'), today, 'day', '[]') &&
                (item.isLate || item.absent)
            ).map(item => ({
                date: item.date,
                status: item.isLate ? 'Late' : item.absent ? 'Absent' : '',
            }));

            setAbsenceAndLate(filteredAbsenceAndLate);
        } else {
            dispatch(openNotificationAlert({ message: t("AnErrorOccurred") }));
        }
    }, [dispatch, today, t]);


    const getSummaries = (leave = [], holiday = [], absent = []) => {
        const leaveArray = Array.isArray(leave) ? leave : [];
        const holidayArray = Array.isArray(holiday) ? holiday : [];
        const absentArray = Array.isArray(absent) ? absent : [];

        const combinedEvents = [...leaveArray, ...holidayArray, ...absentArray];
        return combinedEvents.map(event => {
            const isStatus = event.status

            if (isStatus) {
                return {
                    start: event.startdate,
                    end: event.enddate,
                    title: isStatus === 'Absent' ? t('Absent') : isStatus === 'Late' ? t('Late') : isStatus === 'EarlyOut' ? t('LeaveEarly') : null,
                    status: isStatus,
                };
            } else {
                return {
                    start: event.start
                        ? new Date(dayjs(event.start).toDate())
                        : new Date(dayjs(event.dateHoliday || new Date()).startOf('day').toDate()),
                    end: event.end
                        ? event.end === event.start
                            ? new Date(dayjs(event.end).endOf('day').toDate())
                            : new Date(dayjs(event.end).toDate())
                        : new Date(dayjs(event.dateHoliday || new Date()).endOf('day').toDate()),
                    title: i18n.resolvedLanguage === 'th'
                        ? event.name
                        : event.name_EN !== null ? event.name_EN : event.name || event.description || "Unnamed Event",
                    status: event.status || null,
                };
            }

        });
    };

    const getSummariesManager = (leave = [], holiday = []) => {
        const leaveArray = Array.isArray(leave) ? leave : [];
        const holidayArray = Array.isArray(holiday) ? holiday : [];

        const leaveJSON = leaveArray.map(event => ({
            start: event.start ? new Date(dayjs(event.start)) : new Date(dayjs(event.dateHoliday || new Date()).startOf('day')),
            end: event.end ? (event.end === event.start ? new Date(dayjs(event.end).endOf('day')) : new Date(dayjs(event.end))) : new Date(dayjs(event.dateHoliday || new Date()).endOf('day')),
            title: `${i18n.resolvedLanguage === 'th' ? event.firstname_TH ? event.firstname_TH : event.firstname_EN : event.firstname_EN ? event.firstname_EN : event.firstname_TH} ${i18n.resolvedLanguage === 'th' ? event.lastname_TH ? event.lastname_TH : event.lastname_EN : event.lastname_EN ? event.lastname_EN : event.lastname_TH} : ${i18n.resolvedLanguage === 'th' ? event.name ? event.name : event.name_EN : event.name_EN ? event.name_EN : event.name} `,
            img: event.ImageURL || 'https://example.com/image1.jpg',
            name: i18n.resolvedLanguage === 'th'
            ? event.firstname_TH
            : event.firstname_EN !== null ? event.firstname_EN : event.firstname_TH || "",
            holiday: false
        }));
        const holidayJSON = holidayArray.map(event => ({
            start: event.start ? new Date(dayjs(event.start)) : new Date(dayjs(event.dateHoliday || new Date()).startOf('day')),
            end: event.end ? (event.end === event.start ? new Date(dayjs(event.end).endOf('day')) : new Date(dayjs(event.end))) : new Date(dayjs(event.dateHoliday || new Date()).endOf('day')),
            title: i18n.resolvedLanguage === 'th'
                ? event.name
                : event.name_EN !== null ? event.name_EN : event.name || event.description || "Unnamed Event",
            img: '/assets/icon/sunbed.png',
            holiday: true
        }));

        return [...leaveJSON, ...holidayJSON];
    };

    const getBirthdayManager = (employeeList = []) => {
        const employeeListArray = Array.isArray(employeeList) ? employeeList : [];

        const employeeListJSON = employeeListArray.flatMap(event => {
            const years = [dayjs().year() - 1, dayjs().year(), dayjs().year() + 1];

            return years.map(year => {
                const birthday = event.birthday
                    ? dayjs(event.birthday).set('year', year)
                    : null;

                const title = i18n.resolvedLanguage === 'th'
                    ? `${event.firstname_TH || ''} ${event.lastname_TH || ''}`
                    : `${event.firstname_EN || ''} ${event.lastname_EN || ''}` || "";

                return {
                    start: birthday && birthday.startOf('day').toDate() || null,
                    end: birthday && birthday.endOf('day').toDate() || null,
                    title: title,
                    img: event.ImageURL || 'https://example.com/image1.jpg',
                    holiday: false
                };
            });
        });

        return employeeListJSON;
    };


    const fetchLeaveRound = useCallback(async () => {
        try {
            const res = await leaveService.getLeaveRoundList();
            const foundLeaveRound = res.data.find(item => dayjs(today).isBetween(dayjs(item.start), dayjs(item.end), "[]"));
            setSelectedLeaveRoundDate(foundLeaveRound.date);
            dispatch(getLeaveRequestAllYear({ filter: dayjs(foundLeaveRound.date).format("YYYY") }));
            dispatch(getLeaveAvaiable({ date: dayjs(foundLeaveRound.date).format("YYYY-MM-DD") }));
        } catch {
            setSelectedLeaveRoundDate("");
        }
    }, [dispatch, today]);

    useEffect(() => {
        if (currentUser) dispatch(getUserProfile(currentUser.username));
    }, [currentUser, dispatch]);



    useEffect(() => {
        if (userProfile) {
            const start = today.startOf('year').format("YYYY-MM-DD");
            const end = today.endOf('year').format("YYYY-MM-DD");
            const startAbsentAndLate = today.startOf('month').subtract(1, 'month').format("YYYY-MM-DD");
            const endAbsentAndLate = today.endOf('month').format("YYYY-MM-DD");
            const startOT = today.startOf('month').format("YYYY-MM-DD");
            const endOT = today.endOf('month').format("YYYY-MM-DD");
            dispatch(getLeave({ employee: userProfile.idEmployees, start, end }));
            dispatch(getOT({ start: startOT, end: endOT }));
            dispatch(getAbsentAndLate({ employee: userProfile.idEmployees, start: startAbsentAndLate, end: endAbsentAndLate }));
            dispatch(getLeaveSetting());
            fetchLeaveRound();
            dispatch(allHoliday(dayjs(new Date()).format("YYYY"), { idEmployees: userProfile.idEmployees }));
            dispatch(getDashboardCost({ mode: "manager", month, year, idCompany: userProfile.idCompany }));
            fetchAttendance();
        }
    }, [userProfile, dispatch]);

    useEffect(() => {
        if (userProfile) {
            fetchNotification();
        }
    }, [userProfile, isActive])

    const fetchNotification = () => {
        dispatch(getNotification({ idEmployees: userProfile.idEmployees, role: isActive ? 'manager' : 'user', idCompany: userProfile.idCompany }))
    }

    const renderContent = () => {
        const renderLoading = (isFetching, condition = true) => (isFetching || !condition ? <Loading /> : null);

        return isActive ? (
            <Fragment>
                {renderLoading(overviewUserLeaveFetching, overviewUserLeave) ||
                    <CustomizingCalendar t={t} i18n={i18n} events={getSummariesManager(overviewUserLeave.leave, holiday)} birthdayList={getBirthdayManager(overviewUserLeave.EmployeeResult)} />}

                {renderLoading(notificationFetching) ||
                    <NotificationUser t={t} i18n={i18n} notificationList={notificationList || null} fetchNotification={fetchNotification} />}

                {renderLoading(overviewUserAbsentLateFetching, overviewUserAbsentLate) ||
                    <AbsenceAndRate t={t} currentLocale={i18n.resolvedLanguage} absenceAndLate={overviewUserAbsentLate.workTime.slice(0, 5)} />}

                {renderLoading(overviewUserLeaveFetching, overviewUserLeave) ||
                    <Leave t={t}
                        leave={overviewUserLeave.leave.filter((data) => {
                            const startDate = dayjs(data.start);
                            const startMonth = startDate.month() + 1;

                            const startOfMonth = dayjs().month(month - 1).startOf('month');
                            const endOfMonth = startOfMonth.endOf('month');

                            return startMonth === month && startDate.isBetween(startOfMonth, endOfMonth, null, '[]');
                        })}
                        i18n={i18n}
                        month={month} setMonth={setMonth} />
                }

                {(!overviewUserOtFetching && !dashboardCostFetching) || isLoadingOT ? (
                    <OT
                        t={t}
                        i18n={i18n}
                        OrganizationList={OrganizationList}
                        affiliateList={affiliateList}
                        userProfile={userProfile}
                        dashboardCost={dashboardCost}
                        overviewUserOt={overviewUserOt}
                        setIsLoadingOT={setIsLoadingOT}
                    />
                ) :
                    <Loading />
                }
            </Fragment>
        ) : (
            <Fragment>
                {renderLoading(leaveEmployeesListFetching || holidayFetching, leaveEmployeesList) ||
                    <CustomizingCalendarUser t={t} i18n={i18n} events={getSummaries(leaveEmployeesList, holiday, absenceAndLate)} birthday={userProfile.birthday} />}

                <WorkTimeUser t={t} todayAttendance={todayAttendance} />

                {renderLoading(notificationFetching) ||
                    <NotificationUser t={t} i18n={i18n} notificationList={notificationList || null} fetchNotification={fetchNotification} />}

                {renderLoading(absenceAndLateFetching) ||
                    <AbsenceAndRateUser t={t} currentLocale={i18n.resolvedLanguage} absenceAndLate={absenceAndLate} />}

                {renderLoading(leaveAvailableFetching, leaveAvailable) ||
                    <LeaveUser t={t} leaveAvailable={leaveAvailable} />}

                <OTUser t={t} summaryTime={summaryTime} />
                <TaxUser t={t} />
            </Fragment>
        );
    };


    return (
        <StyledRoot className="page">
            <Container maxWidth="lg" style={{ marginTop: "16px", marginBottom: "16px" }}>
                <Grid container spacing={6}>
                    <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {userProfile && (
                            <Fragment>
                                <InfoCard
                                    image={userProfile.imageProfile || 'https://example.com/image1.jpg'}
                                    name={`${userProfile[`firstname_${i18n.resolvedLanguage === 'en' || i18n.resolvedLanguage === 'th' ? i18n.resolvedLanguage.toUpperCase() : 'EN'}`]} ${userProfile[`lastname_${i18n.resolvedLanguage === 'en' || i18n.resolvedLanguage === 'th' ? i18n.resolvedLanguage.toUpperCase() : 'EN'}`]}`}
                                    id={userProfile.employeeID}
                                    job={userProfile[`positionName${i18n.resolvedLanguage === 'en' ? '_EN' : ''}`] || (userProfile.roleName === "ROLE_MANAGER" ? t('Manager') : '')}
                                    t={t}
                                    isActive={isActive}
                                    setIsActive={setIsActive}
                                />
                                {renderContent()}
                            </Fragment>
                        )}
                    </Grid>
                    <Grid item xs={4}><AI title="AI" /></Grid>
                </Grid>
            </Container>
        </StyledRoot>
    );
}

export default OverviewManager;
