import React, { useEffect, useState } from "react"
import {
    Box, Button, Card,
    CardActions, CardContent, Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid, IconButton, InputLabel, Menu, MenuItem, Paper, Stack, styled,
    Typography,
    Select,
    Divider,
    Checkbox,
    Breadcrumbs,
    Slider,
    useMediaQuery
} from "@mui/material"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom'
import { getAllEmployeeTalentByCompany, getTalentList, updateEmployeeTalent } from "../../../../actions/employee";
import { useTranslation } from "react-i18next";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliateOrganization } from "../../../../actions/company";
import ButtonBlue from "../../shared/general/ButtonBlue";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { CheckBox } from "@mui/icons-material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import dayjs from "dayjs";
import ListPage from "./listPage";


const StyledRoot = styled("div")({
    backgroundColor: "#FFFFFF !important",
});

export default function TalentPage() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [talentList, setTalentList] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [employeeTalentList, setEmployeeTalentList] = useState(null);
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

    const CURRENT_YEAR_DISPLAY =
        dayjs().get("month") >= 11
            ? dayjs().get("year")
            : dayjs().subtract(1, "year").get("year");

    const fetchedEmployeeTalent = async (idCompany) => {
        try {
            const response = await getAllEmployeeTalentByCompany(idCompany, CURRENT_YEAR_DISPLAY);
            if (response && response.data) {
                setEmployeeTalentList(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (selectedCompany && selectedCompany.idCompany) {
            fetchedEmployeeTalent(selectedCompany.idCompany);
        }
    }, [selectedCompany, selectedCompany && selectedCompany.idCompany]);

    useEffect(() => {
        const fetchedData = async () => {
            try {
                const response = await getTalentList();
                if (response && response.data) {
                    setTalentList(response.data);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchedData();
    }, []);

    useEffect(() => {
        dispatch(getAffiliateOrganization());
    }, []);

    useEffect(() => {
        if (userProfile && affiliateOrganizationList.length > 0) {
            setSelectedCompany(
                affiliateOrganizationList.find(
                    (x) => x.idCompany === userProfile.idCompany
                ) || null
            );
        }
    }, [userProfile, affiliateOrganizationList]);

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    };

    const extractTalentInfo = (talentName) => {
        const match = talentName.match(/^([A-Z])(\d+)$/);
        if (match) {
            return { category: match[1], number: parseInt(match[2]) };
        }
        return { category: "", number: NaN };
    };

    const sortedTalentList = talentList && talentList.slice().sort((a, b) => {
        const infoA = extractTalentInfo(a.talentName);
        const infoB = extractTalentInfo(b.talentName);
        if (infoA.category !== infoB.category) {
            return infoA.category.localeCompare(infoB.category);
        }
        return infoB.number - infoA.number;
    });

    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const getColumnSize = () => {
        if (isSmallScreen) {
            return 12; // Full width on small screens
        } else {
            return 4; // Three columns on larger screens
        }
    };

    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={"/home"}
                    >
                        {t("AppMenu.Home")}
                    </Link>
                    <Typography color="text.primary">
                        {t("Talent")}
                    </Typography>
                </Breadcrumbs>

                <div style={{ marginBottom: 8, marginTop: 10 }}>
                    <Typography variant="h4">
                        {t("Talent")}
                    </Typography>
                </div>
                {/* <Box marginBottom="24px">
                    <SelectAffiliateCompany
                        options={affiliateOrganizationList}
                        value={selectedCompany}
                        onChange={(_, value) => {
                            onChangeCompany(value);
                        }}
                    />
                </Box> */}

                <Stack direction={"row"} spacing={3}>
                    {!isSmallScreen && (
                        <Stack direction={"column"} spacing={1} justifyContent={"center"} alignItems={"center"}>
                            <Box sx={{ width: 4, height: '100%', backgroundColor: "#F168AB" }} />
                            <Typography fontSize={18} color={"#F168AB"} fontWeight={"bold"} textAlign={"center"} sx={{ transform: 'rotate(-90deg)', }}>{")"}</Typography>
                            <Typography fontSize={18} color={"#F168AB"} fontWeight={"bold"} textAlign={"center"} sx={{ transform: 'rotate(-90deg)', }}>พ</Typography>
                            <Typography fontSize={18} color={"#F168AB"} fontWeight={"bold"} textAlign={"center"} sx={{ transform: 'rotate(-90deg)', }}>า</Typography>
                            <Typography fontSize={18} color={"#F168AB"} fontWeight={"bold"} textAlign={"center"} sx={{ transform: 'rotate(-90deg)', }}>ภ</Typography>
                            <Typography fontSize={18} color={"#F168AB"} fontWeight={"bold"} textAlign={"center"} sx={{ transform: 'rotate(-90deg)', }}>ย</Typography>
                            <Typography fontSize={18} color={"#F168AB"} fontWeight={"bold"} textAlign={"center"} sx={{ transform: 'rotate(-90deg)', }}>ก</Typography>
                            <Typography fontSize={18} color={"#F168AB"} fontWeight={"bold"} textAlign={"center"} sx={{ transform: 'rotate(-90deg)', }}>ศั</Typography>
                            <Typography fontSize={18} color={"#F168AB"} fontWeight={"bold"} textAlign={"center"} sx={{ transform: 'rotate(-90deg)', }}>{"("}</Typography>
                            <Typography fontSize={18} color={"#F168AB"} fontWeight={"bold"} textAlign={"center"} sx={{ transform: 'rotate(-90deg)', }}>L</Typography>
                            <Typography fontSize={18} color={"#F168AB"} fontWeight={"bold"} textAlign={"center"} sx={{ transform: 'rotate(-90deg)', }}>A</Typography>
                            <Typography fontSize={18} color={"#F168AB"} fontWeight={"bold"} textAlign={"center"} sx={{ transform: 'rotate(-90deg)', }}>I</Typography>
                            <Typography fontSize={18} color={"#F168AB"} fontWeight={"bold"} textAlign={"center"} sx={{ transform: 'rotate(-90deg)', }}>T</Typography>
                            <Typography fontSize={18} color={"#F168AB"} fontWeight={"bold"} textAlign={"center"} sx={{ transform: 'rotate(-90deg)', }}>N</Typography>
                            <Typography fontSize={18} color={"#F168AB"} fontWeight={"bold"} textAlign={"center"} sx={{ transform: 'rotate(-90deg)', }}>E</Typography>
                            <Typography fontSize={18} color={"#F168AB"} fontWeight={"bold"} textAlign={"center"} sx={{ transform: 'rotate(-90deg)', }}>T</Typography>
                            <Typography fontSize={18} color={"#F168AB"} fontWeight={"bold"} textAlign={"center"} sx={{ transform: 'rotate(-90deg)', }}>O</Typography>
                            <Typography fontSize={18} color={"#F168AB"} fontWeight={"bold"} textAlign={"center"} sx={{ transform: 'rotate(-90deg)', }}>P</Typography>
                            <Box sx={{ width: 4, height: '100%', backgroundColor: "#F168AB" }} />
                        </Stack>
                    )}


                    <Grid container spacing={2} style={{ marginBottom: 20 }}>
                        {sortedTalentList && sortedTalentList.map((item, index) => (
                            <Grid item xs={getColumnSize()} key={index}>
                                <ListPage
                                    item={item}
                                    empData={employeeTalentList ? employeeTalentList : null}
                                    year={CURRENT_YEAR_DISPLAY}
                                    idCompany={selectedCompany && selectedCompany.idCompany}
                                />
                            </Grid>
                        ))}
                    </Grid>

                </Stack>

                {!isSmallScreen && (
                    <Box sx={{ width: '100%', pb: 5, mt: 3 }}>
                        <Stack spacing={2} direction={"row"} alignItems={"center"} justifyContent={"center"}>
                            <Typography variant="body2" sx={{ marginRight: 'auto' }} fontSize={18} color={"#F168AB"} fontWeight={"bold"}>Low</Typography>

                            <Box sx={{ flexGrow: 1, height: 4, backgroundColor: '#F168AB', marginRight: 1 }} />
                            <Typography variant="body2" sx={{ marginRight: 'auto' }} fontSize={18} color={"#F168AB"} fontWeight={"bold"}>Performance {"(ผลงาน)"}</Typography>
                            <Box sx={{ flexGrow: 1, height: 4, backgroundColor: '#F168AB' }} />
                            <Typography variant="body2" fontSize={25} color={"#F168AB"} fontWeight={"bold"}>{">"}</Typography>

                            <Typography variant="body2" sx={{ marginLeft: 'auto' }} fontSize={18} color={"#F168AB"} fontWeight={"bold"}>High</Typography>
                        </Stack>
                    </Box>
                )}
            </Container>
        </StyledRoot>
    )
}