import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import { Box, Popper, Typography } from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";

import { getAllEmployeesAllRoles } from "../../../../actions/employee";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";

const StyledBoxSearch = styled(Box)({
  marginTop: 20,
  marginBottom: 24,
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 500,
  marginButtom: 24,
  "&.MuiTypography-root": {
    marginButtom: 3,
  },
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  marginTop: 16,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const filterOptions = createFilterOptions({
  stringify: (option) =>
    option.firstname_TH + option.lastname_TH + option.positionName,
});

const filterOptionsName = createFilterOptions({
  stringify: (option) => `${option.firstname_TH} ${option.lastname_TH}`,
});

const SelectEmployees = (props) => {
  const { t, i18n } = useTranslation();
  let { handleChange, setState, isUseform } = props;
  const dispatch = useDispatch();
  const { result: employees } = useSelector((state) => state.employees);
  const [employee, setEmployee] = useState(undefined);

  useEffect(() => {
    dispatch(getAllEmployeesAllRoles());
  }, []);

  return (
    <StyledBoxSearch>
      <StyledHeadLabel variant="subtitle" gutterBottom sx={{ marginBottom: 2 }}>
        {t("SelectEmp")}
      </StyledHeadLabel>

      <StyledAutocomplete
        value={employee}
        fullWidth
        options={employees ? employees : []}
        onChange={(event, newValue) => {
          if (isUseform === true) {
            if (newValue) {
              handleChange("employee", newValue);
              handleChange("idEmployee", newValue.idEmployees);
            } else {
              handleChange("employee", null);
              handleChange("idEmployee", null);
            }
          } else {
            if (newValue) {
              setState(newValue.idEmployees);
            } else {
              setState(null);
            }
          }
          setEmployee(newValue);
        }}
        filterOptions={filterOptionsName}
        closeIcon={null}
        popupIcon={<i class="fa-light fa-chevron-down"></i>}
        getOptionLabel={(option) =>
          option === undefined
            ? t("SelectEmp")
            : i18n.resolvedLanguage === "th"
            ? `${option.firstname_TH} ${option.lastname_TH}`
            : `${option.firstname_EN} ${option.lastname_EN}`
        }
        renderOption={(props, option) => (
          <Box component="li" {...props} key={option.idEmployees} fullWidth>
            {i18n.resolvedLanguage === "th"
              ? `${option.firstname_TH} ${option.lastname_TH}`
              : `${option.firstname_EN} ${option.lastname_EN}`}
          </Box>
        )}
        renderInput={(params) => (
          <TextFieldTheme
            {...params}
            variant="outlined"
            placeholder={t("SelectEmp")}
            InputProps={{ ...params.InputProps, endAdornment: null }}
          />
        )}
        PopperComponent={StyledPopper}
        noOptionsText={t("NoData")}
      />
    </StyledBoxSearch>
  );
};

export default SelectEmployees;
