import React, { useState, useEffect } from "react";
import { useTitle } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import {
  Alert,
  Box,
  Grid,
  MenuItem,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import DataTable from "./components/DataTable";
import {
  deletePosition,
  getAllPositions,
} from "../../../../../actions/positions";

import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import CardStyle from "../../../shared/general/Card";
import AddIcon from "@mui/icons-material/Add";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import DialogEditPosition from "./DialogEditPosition";
import { getJobGroup } from "../../../../../actions/jobRecruit";
import PositionTable from "./PositionTable";
import AlertResponse from "../../../shared/general/AlertResponse";
import Notification from "../../../shared/general/Notification";
import DialogConfirmDelete from "../../../shared/general/DialogConfirmDelete";
import { getOrganizationStructure } from "../../../../../actions/organizationStructure";
import { getLevelByidCompany } from "../../../../../actions/level";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";
import { JobManagementXlsx } from "./JobManagementXlsx";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
}));

const StyledHeadingPage = styled("div")({
  marginBottom: 16,
});

function Index(props) {
  const classes = useStyles();
  const { selectedCompany, isReadOnly } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: jobFamilyStore } = useSelector((state) => state.jobFamily);
  const { result: positionList } = useSelector((state) => state.positions);
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    type: "success",
    message: "",
  });

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: null,
  });

  const [deleteConfig, setDeleteConfig] = useState({
    isOpen: false,
    data: {
      idPosition: null,
      positionCode: "",
      positionName: "",
    },
  });

  let setFirstPageChildFunc = null;

  const [filterConfig, setFilterConfig] = useState({
    type: "position",
    name: "",
  });

  const handleAddPosition = () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: true,
      isEdit: false,
      data: null,
    }));
  };

  const handleCloseDialog = () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleChangeFilter = (event) => {
    setFilterConfig((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
    // console.log(filterConfig)
    // if(setFirstPage){
    setFirstPageChildFunc();
    // }
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleCloseDeleteModal = () => {
    setDeleteConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleDeletePosition = async () => {
    const response = await dispatch(
      deletePosition(deleteConfig.data.idPosition)
    );

    if (response) {
      if (response.status === 200) {
        dispatch(
          openNotificationAlert({
            type: "success",
            message: `${t("DataSaveSuccessful")}`,
          })
        );
        dispatch(getAllPositions({ idCompany: selectedCompany.idCompany }));
        handleCloseDeleteModal();
      } else {
        dispatch(
          openNotificationAlert({
            type: "error",
            message: `${t("CantDeleteJobPosition")}`,
          })
        );
      }
    } else {
      dispatch(
        openNotificationAlert({
          type: "error",
          message: `${t("AnErrorOccurred")} ${t("PleaseContactAdmin")}`,
        })
      );
    }

    setDeleteConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const getFilteredData = () => {
    if (positionList) {
      if (filterConfig.name === "") return positionList;
      const filterData = positionList.filter((p) => {
        if (filterConfig.type === "position") {
          return p.positionName && String(p.positionName).toLowerCase().includes(String(filterConfig.name).toLowerCase());
        } else if (filterConfig.type === "section") {
          return p.sectionName && String(p.sectionName).toLowerCase().includes(String(filterConfig.name).toLowerCase());
        } else if (filterConfig.type === "department") {
          return (p.departmentName && String(p.departmentName).toLowerCase().includes(String(filterConfig.name).toLowerCase()));
        } else if (filterConfig.type === "division") {
          return p.divisionName && String(p.divisionName).toLowerCase().includes(String(filterConfig.name).toLowerCase());
        } else if (filterConfig.type === "business") {
          return p.businessName && String(p.businessName).toLowerCase().includes(String(filterConfig.name).toLowerCase());
        }
      });
      return filterData;
    } else {
      return [];
    }
  };

  // const handleCloseDialog = () => {
  // setOpenDialog({
  //   isOpen: false,
  //   jobFamilies: null
  // });
  // };

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getAllPositions({ idCompany: selectedCompany.idCompany }));
      dispatch(getJobGroup());
      dispatch(
        getOrganizationStructure({ idCompany: selectedCompany.idCompany })
      );
      dispatch(getLevelByidCompany({ idCompany: selectedCompany.idCompany }));
    }
  }, [selectedCompany]);

  const handleXlsxExport = () => {
    if (positionList) {
      JobManagementXlsx(t,positionList);
    } else {
      return;
    }
  }

  return (
    <div className={classes.root}>
      <StyledHeadingPage>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">{t("ManageJobPosition")}</Typography>
          </Grid>
          <Grid item>
            <ButtonBlue
              variant={"contained"}
              startIcon={<DownloadRoundedIcon />}
              onClick={() => handleXlsxExport()}
              style={{ marginRight: '12px' }}
            >
              {t("DownloadData")}
            </ButtonBlue>
            <ButtonBlue
              variant={"contained"}
              startIcon={<AddIcon />}
              // onClick={() => {
              //   setOpenDialog({
              //     ...openDialog,
              //     isOpen: true,
              //   });
              // }}
              onClick={handleAddPosition}
            >
              {t("AddJobPosition")}
            </ButtonBlue>
          </Grid>
        </Grid>
      </StyledHeadingPage>
      <Box display="flex" gap="8px" marginBottom="16px">
        <TextFieldTheme
          name="type"
          value={filterConfig.type}
          onChange={handleChangeFilter}
          style={{ width: "150px" }}
          select
        >
          <MenuItem value="position">{t("JobPosition")}</MenuItem>
          <MenuItem value="section">{t("Section")}</MenuItem>
          <MenuItem value="department">{t("Department")}</MenuItem>
          <MenuItem value="division">{t("Division")}</MenuItem>
          <MenuItem value="business">{t("Business")}</MenuItem>
        </TextFieldTheme>
        <TextFieldTheme
          name="name"
          value={filterConfig.name}
          onChange={handleChangeFilter}
          inputProps={{
            placeholder: `${t("Search")}`,
          }}
        />
      </Box>

      <CardStyle style={{ padding: 16 }}>
        <PositionTable
          data={getFilteredData()}
          setFirstPage={(setFirstPage) => {
            setFirstPageChildFunc = setFirstPage;
          }}
          setDrawerConfig={setDrawerConfig}
          setDeleteConfig={setDeleteConfig}
        />
      </CardStyle>

      <DialogEditPosition
        drawerConfig={drawerConfig}
        onClose={handleCloseDialog}
        setOpenAlert={setOpenAlert}
        setAlertType={setAlertType}
        setAlertConfig={setAlertConfig}
        selectedCompany={selectedCompany}
        isReadOnly={isReadOnly}
      />

      <DialogConfirmDelete
        open={deleteConfig.isOpen}
        handleClose={handleCloseDeleteModal}
        label={t("JobPosition")}
        text={`(${deleteConfig.data.positionCode}) ${deleteConfig.data.positionName}`}
        handleDelete={handleDeletePosition}
        isReadOnly={isReadOnly}
      />

      <Notification notify={alertConfig} setNotify={setAlertConfig} />
    </div>
  );
}

export default Index;
