import React, { useLayoutEffect, useRef, useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import CardStyle from "../../../shared/general/Card";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import SearchIcon from "@mui/icons-material/Search";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  "& .search-input": {
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px!important",
    },
  },
});

const StyledTableContainer = styled(TableContainer)({
  position: "relative",
  overflowX: "auto",
  "& table": {
    borderCollapse: "separate",
  },
  "& .MuiTableBody-root": {
    "& .MuiTableCell-root": {
      padding: "8px 16px",
    },
  },
  "& .stickyColumn-left": {
    position: "sticky",
    left: 0,
    zIndex: 1,
    backgroundColor: "#FFFFFF",
  },
  "& .stickyColumn-right": {
    position: "sticky",
    right: 0,
    zIndex: 1,
    backgroundColor: "#FFFFFF",
  },
  "&.shadowEffect-left .stickyColumn-left": {
    "&:after": {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: "-1px",
      width: "30px",
      transform: "translateX(100%)",
      transition: "box-shadow .3s",
      content: '""',
      pointerEvents: "none",
      boxShadow: "inset 10px 0 8px -8px rgba(5,5,5,.06)",
    },
  },
  "&.shadowEffect-right .stickyColumn-right": {
    "&:after": {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: "-1px",
      width: "30px",
      transform: "translateX(-100%)",
      transition: "box-shadow .3s",
      content: '""',
      pointerEvents: "none",
      boxShadow: "inset -10px 0 8px -8px rgba(5,5,5,.06)",
    },
  },
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px -10px 20px #EEEEEE",
    position: "sticky",
    left: 0,
    backgroundColor: "#f4f6f8",
    zIndex: 4,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "transparent",
    "& .sticky": {
      backgroundColor: "#FFFFFF",
    },
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 16px",
  width: 250,
});

const OrganizationList = (props) => {
  const { title, data, setDrawerConfig, setDeleteConfig } = props;

  const tableContainerRef = useRef(null);
  const { t, i18n } = useTranslation();

  // const pages = [10, 25];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onChangeSearch = (event) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const getFilteredSeachData = (data) => {
    return data.filter((f) => {
      if (search === "") {
        return true;
      } else {
        return `${f.code} ${f.name}`
          .toLowerCase()
          .includes(search.toLowerCase());
      }
    });
  };

  const getNodeId = (item) => {
    switch (item.nodeType) {
      case "BUSINESSUNIT": {
        return item.idBusinessUnit;
      }
      case "DIVISION": {
        return item.idDivision;
      }
      case "DEPARTMENT": {
        return item.idDepartment;
      }
      case "SECTION": {
        return item.idSection;
      }
      case "POSITION": {
        return item.idPosition;
      }
    }
  };

  const handleScroll = (event) => {
    const target = event.target;
    target.classList.toggle("shadowEffect-left", target.scrollLeft > 0);
    target.classList.toggle(
      "shadowEffect-right",
      target.scrollLeft <
        target.scrollWidth - tableContainerRef.current.clientWidth
    );
  };

  useLayoutEffect(() => {
    tableContainerRef.current.classList.toggle(
      "shadowEffect-left",
      tableContainerRef.current.scrollLeft > 0
    );
    tableContainerRef.current.classList.toggle(
      "shadowEffect-right",
      tableContainerRef.current.scrollLeft <
        tableContainerRef.current.scrollWidth -
          tableContainerRef.current.clientWidth
    );
  }, []);

  return (
    <StyledRoot>
      <CardStyle>
        <Box padding="24px">
          <Box display="flex" justifyContent="space-between">
            <Typography fontSize="20px" fontWeight="500" paddingBottom="24px">
              {title}
            </Typography>
            <TextFieldTheme
              className="search-input"
              size="small"
              value={search}
              onChange={onChangeSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              style={{ maxWidth: "250px" }}
            />
          </Box>

          <StyledTableContainer ref={tableContainerRef} onScroll={handleScroll}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 200 }}>{t("Code")}</TableCell>
                  <TableCell style={{ minWidth: 200 }}>{t("Name")}</TableCell>
                  <TableCell style={{ minWidth: 200 }}>{t("NameEN")}</TableCell>
                  <TableCell style={{ minWidth: 200 }}>
                    {t("Manager")}
                  </TableCell>
                  <TableCell style={{ minWidth: 200 }}>Cost Center</TableCell>
                  <TableCell style={{ minWidth: 200 }}>Parent</TableCell>
                  <TableCell
                    className="stickyColumn stickyColumn-right"
                    style={{ minWidth: 80 }}
                  >
                    {t("Action")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.length > 0 &&
                  getFilteredSeachData(data)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <TableRow key={`body-${index}`}>
                        <TableCell style={{ minWidth: 200 }}>
                          {item.code}
                        </TableCell>
                        <TableCell style={{ minWidth: 200 }}>
                          {item.name}
                        </TableCell>
                        <TableCell style={{ minWidth: 200 }}>
                          {item.name_EN ? item.name_EN : null}
                        </TableCell>
                        <TableCell style={{ minWidth: 200 }}>
                          {item.idManager ? (
                            <>
                              {item.manager_firstname_EN ? item.manager_firstname_EN + " " + item.manager_lastname_EN : item.manager_firstname_TH + " " + item.manager_lastname_TH}
                            </>
                          ) : null}
                          {/* {item.mainPosition && item.mainPosition.employee ? (
                            <>
                              {item.mainPosition.employee.firstname_TH}{" "}
                              {item.mainPosition.employee.lastname_TH}
                            </>
                          ) : null} */}
                        </TableCell>
                        <TableCell style={{ minWidth: 200 }}>
                          {item.costCenter}
                        </TableCell>
                        <TableCell style={{ minWidth: 200 }}>
                          {item.parent && item.parent.parentCode}
                        </TableCell>
                        <TableCell
                          className="stickyColumn stickyColumn-right"
                          style={{ minWidth: 80 }}
                        >
                          <Box>
                            <IconButton
                              aria-label="add"
                              // disabled={item.nodeType === "CEO"}
                              onClick={() => {
                                if (item.nodeType === "CEO") {
                                  // console.log("Hi")
                                  setDrawerConfig((prev) => ({
                                    ...prev,
                                    isOpen: true,
                                    isEdit: true,
                                    data: {
                                      idMDOffice: item.idMDOffice,
                                      name: item.name,
                                      costCenter: item.costCenter,
                                    },
                                  }));
                                } else {
                                  setDrawerConfig((prev) => ({
                                    ...prev,
                                    isOpen: true,
                                    isEdit: true,
                                    data: {
                                      name: item.name,
                                      name_EN: item.name_EN,
                                      costCenter: item.costCenter,
                                      nodeType: item.nodeType,
                                      idBusinessUnit: item.idBusinessUnit,
                                      idDivision: item.idDivision,
                                      idDepartment: item.idDepartment,
                                      idSection: item.idSection,
                                      idPosition: item.idPosition,
                                      parentNode: {
                                        code: item.parent.parentCode,
                                      },
                                      isOverridedManager: item.isOverridedManager,
                                      idManager: item.idManager,
                                      manager_firstname_TH: item.manager_firstname_TH,
                                      manager_lastname_TH: item.manager_lastname_TH,
                                    },
                                  }));
                                }
                              }}
                              // onClick={() => {
                              //   setDrawerConfig(prev => ({
                              //     ...prev,
                              //     isOpen: true,
                              //     isEdit: true,
                              //     data: {
                              //       idPosition: item.idPosition,
                              //       positionName: item.positionName,
                              //       idJobGroup: item.idJobGroup,
                              //       idSection: item.idSection,
                              //       idDepartment: item.idDepartment,
                              //       idDivision: item.idDivision,
                              //       isShow: item.isShow,
                              //     }
                              //   }))
                              // }}
                            >
                              <EditNoteRoundedIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              disabled={item.nodeType === "CEO"}
                              onClick={() => {
                                setDeleteConfig((prev) => ({
                                  ...prev,
                                  isOpen: true,
                                  data: {
                                    nodeType: item.nodeType,
                                    idNode: getNodeId(item),
                                    label: title,
                                    text: `${item.code} ${item.name}`,
                                  },
                                }));
                              }}
                            >
                              <DeleteOutlineRoundedIcon fontSize="inherit" />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      // <StyledRowContent key={index}>
                      //   <StyledCellContent>
                      //     <Typography>{item.code}</Typography>
                      //   </StyledCellContent>
                      //   <StyledCellContent>
                      //     <Typography>{item.name}</Typography>
                      //   </StyledCellContent>
                      //   <StyledCellContent>
                      //     <Typography>{item.manager_firstname_TH} {item.manager_lastname_TH}</Typography>
                      //   </StyledCellContent>
                      //   <StyledCellContent>
                      //     <Typography>{item.costCenter}</Typography>
                      //   </StyledCellContent>
                      //   <StyledCellContent>
                      //     <Typography>{item.parentCode}</Typography>
                      //   </StyledCellContent>
                      //   <StyledCellContent>
                      //     <Typography>{item.employeeTotal}</Typography>
                      //   </StyledCellContent>
                      // </StyledRowContent>
                    ))}
                {(!data || getFilteredSeachData(data).length <= 0) && (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <Box
                        height="120px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography fontSize="20px" color="text.secondary">
                          {t("NoResult")}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={data ? getFilteredSeachData(data).length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${
                            count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
          />
        </Box>
      </CardStyle>
    </StyledRoot>
  );
};

export default OrganizationList;
