import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  Divider,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";
const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const LateAbsentPanel = (props) => {
  const { t, i18n } = useTranslation();
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [dataForm, setDataForm] = useState(null);

  useEffect(() => {
    if (payrollSetting) {
      setDataForm(payrollSetting);
    }
  }, [payrollSetting]);

  const handleChangeData = (event) => {
    const { name, value } = event.target;
    setDataForm({ ...dataForm, [name]: value });
  };

  return (
    <Box>
      {dataForm && (
        <Fragment>
          <div className="wrap-item-data">
            <div className="wrap-check">
              <Typography className="sub-header-1" variant="body1" gutterBottom>
                {t("Late")}
              </Typography>
            </div>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography className="sub-header" variant="body1">
                    {t("DeductWages")}
                  </Typography>
                  <div>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="round-payroll"
                        name="lateDockSalary"
                        value={dataForm.lateDockSalary}
                        onChange={handleChangeData}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio disabled={userProfile && userProfile.readOnly} />}
                          label={t("Yes")}
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio disabled={userProfile && userProfile.readOnly} />}
                          label={t("No")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                {Number(dataForm.lateDockSalary) === 1 ? (
                  <Box>
                    <Typography className="sub-header" variant="body1">
                      {t("CalculateIncomeTax")}
                    </Typography>
                    <div>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="round-payroll"
                          name="lateTax"
                          value={dataForm.lateTax}
                          onChange={handleChangeData}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={t("Yes")}
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label={t("No")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Box>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={3}>
                {Number(dataForm.lateDockSalary) === 1 ? (
                  <Box>
                    <Typography className="sub-header" variant="body1">
                      {t("CalculateSSO")}
                    </Typography>
                    <div>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="round-payroll"
                          name="lateSso"
                          value={dataForm.lateSso}
                          onChange={handleChangeData}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={t("Yes")}
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label={t("No")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Box>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={3}>
                {Number(dataForm.lateDockSalary) === 1 ? (
                  <Box>
                    <Typography className="sub-header" variant="body1">
                      {t("CalculateProvidentFund")}
                    </Typography>
                    <div>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="round-payroll"
                          name="latePf"
                          value={dataForm.latePf}
                          onChange={handleChangeData}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={t("Yes")}
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label={t("No")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Box>
                ) : null}
              </Grid>
            </Grid>
          </div>
          <StyledDivider />
          <div className="wrap-item-data">
            <div className="wrap-check">
              <Typography className="sub-header-1" variant="body1" gutterBottom>
                {t("Absent")}
              </Typography>
            </div>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography className="sub-header" variant="body1">
                    {t("DeductWages")}
                  </Typography>
                  <div>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="round-payroll"
                        name="absentDockSalary"
                        value={dataForm.absentDockSalary}
                        onChange={handleChangeData}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio disabled={userProfile && userProfile.readOnly} />}
                          label={t("Yes")}
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio disabled={userProfile && userProfile.readOnly} />}
                          label={t("No")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                {Number(dataForm.absentDockSalary) === 1 ? (
                  <Box>
                    <Typography className="sub-header" variant="body1">
                      {t("CalculateIncomeTax")}
                    </Typography>
                    <div>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="round-payroll"
                          name="absentTax"
                          value={dataForm.absentTax}
                          onChange={handleChangeData}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={t("Yes")}
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label={t("No")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Box>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={3}>
                {Number(dataForm.absentDockSalary) === 1 ? (
                  <Box>
                    <Typography className="sub-header" variant="body1">
                      {t("CalculateIncomeTax")}
                    </Typography>
                    <div>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="round-payroll"
                          name="absentSso"
                          value={dataForm.absentSso}
                          onChange={handleChangeData}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={t("Yes")}
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label={t("No")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Box>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={3}>
                {Number(dataForm.absentDockSalary) === 1 ? (
                  <Box>
                    <Typography className="sub-header" variant="body1">
                      {t("CalculateProvidentFund")}
                    </Typography>
                    <div>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="round-payroll"
                          name="absentPf"
                          value={dataForm.absentPf}
                          onChange={handleChangeData}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={t("Yes")}
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label={t("No")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Box>
                ) : null}
              </Grid>
            </Grid>
          </div>
        </Fragment>
      )}
    </Box>
  );
};

export default LateAbsentPanel;
