import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";

import {
  FormControlLabel,
  FormGroup,
  Grid,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import DatePickerCustom from "../../../shared/date/datePicker";

import {
  addRequestReason,
  updateRequestReason,
} from "../../../../../actions/requestTime";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";

const Root = styled("div")({
  minWidth: "320px",
  padding: "16px",
});

const DrawerReason = (props) => {
  const { t, i18n } = useTranslation();
  const { open, onClose, type, reason, renderRequestReason, handleOpenAlert, selectedCompany, isReadOnly } =
    props;
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      name_EN: "",
      startDate: dayjs().add(1, "day"),
      isPayShift: false,
      isBreak: false
    },
  });

  useEffect(() => {
    if (reason) {
      reset({
        name: reason.name || "",
        name_EN: reason.name_EN || "",
        startDate: reason.startDate || dayjs().add(1, "day"),
        isPayShift: reason.isPayShift || false,
        isBreak: reason.isBreak || false
      });
    }
  }, [reason]);

  const onHandleSubmit = async (values) => {
    if (selectedCompany && selectedCompany.idCompany) {
      let data = {
        name: values.name,
        name_EN: values.name_EN,
        startDate: dayjs(values.startDate).format("YYYY-MM-DD"),
        isPayShift: values.isPayShift ? 1 : 0,
        isBreak: values.isBreak ? 1 : 0,
        idRequestType: type === "OT" ? 2 : 1,
        idCompany: selectedCompany.idCompany,
        ...(reason
          ? {
            idRequestReason: reason.idRequestReason,
          }
          : {}),
      };
      let result = null;
      if (reason) {
        result = await dispatch(updateRequestReason(data));
      } else {
        result = await dispatch(addRequestReason(data));
      }
      if (result.status === 200) {
        handleOpenAlert("success");
        renderRequestReason();
      } else {
        handleOpenAlert("error");
      }
    } else {
      handleOpenAlert(`${t("PleaseSelectCompany")}`);
    }
    onClose();
  };

  return (
    <DrawerCustom
      title={`${reason ? `${t("Edit")}` : `${t("Add")}`}${t("Reason")}${type === "OT"
        ? `${t("OTRequest")}`
        : `${t("WorkingTimeRequestForReason")}`
        }`}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Root>
        <form onSubmit={handleSubmit(onHandleSubmit)}>
          <Typography>{t("ReasonName")}</Typography>
          <Controller
            control={control}
            name="name"
            rules={{
              required: {
                value: true,
                message: `${t("PleaseEnterReasonName")}`,
              },
            }}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                style={{ marginTop: "8px" }}
                error={errors && errors.name}
                helperText={errors && errors.name && errors.name.message}
              />
            )}
          />
          <Typography style={{ marginTop: "16px" }}>
            {t("ReasonName")}({t("English")})
          </Typography>
          <Controller
            control={control}
            name="name_EN"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                style={{ marginTop: "8px" }}
                error={errors && errors.name_EN}
                helperText={errors && errors.name_EN && errors.name_EN.message}
                InputProps={{
                  placeholder: t("Optional"),
                }}
              />
            )}
          />
          <Typography style={{ marginTop: "16px" }}>
            {t("EffectiveDate")}
          </Typography>
          <Controller
            control={control}
            name="startDate"
            rules={{
              required: {
                value: true,
                message: `${t("PleaseEnterEffectiveDate")}`,
              },
            }}
            render={({ field }) => (
              <DatePickerCustom
                {...field}
                minDate={dayjs().add(1, "day")}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    style={{ marginTop: "8px" }}
                    error={errors && errors.startDate}
                    helperText={
                      errors && errors.startDate && errors.startDate.message
                    }
                  />
                )}
              />
            )}
          />
          {type === "OT" && (
            <>
              <div style={{ marginTop: "16px" }}>
                <FormGroup>
                  <Controller
                    control={control}
                    name="isPayShift"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={field.value}
                            onChange={(event) => {
                              field.onChange(event.target.checked);
                            }}
                          />
                        }
                        label={t("GotShiftFee")}
                      />
                    )}
                  />
                </FormGroup>
              </div>
              <div style={{ marginTop: "16px" }}>
                <FormGroup>
                  <Controller
                    control={control}
                    name="isBreak"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={field.value}
                            onChange={(event) => {
                              field.onChange(event.target.checked);
                            }}
                          />
                        }
                        label={t("BreakTime")}
                      />
                    )}
                  />
                </FormGroup>
              </div>
            </>
          )}
          <Grid
            container
            justifyContent="flex-end"
            style={{ marginTop: "16px" }}
          >
            <Grid item>
              <ButtonBlue onClick={onClose}>{t("Cancel")}</ButtonBlue>
              <ButtonBlue
                type="submit"
                variant="contained"
                style={{ marginLeft: "8px" }}
                disabled={isReadOnly}
              >
                {reason ? `${t("Save")}` : `${t("Confirm")}`}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </Root>
    </DrawerCustom>
  );
};

export default DrawerReason;
