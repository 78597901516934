import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Button,
  TextField,
  Toolbar,
  InputAdornment,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";

import { Data } from "../mockData";
import useTable from "../../../shared/table/useTable";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTypography-h6": {
      color: "#222f3e !important",
    },
    "& .MuiLinearProgress-root": {
      height: "10px",
      borderRadius: "10px",
    },
    "& .MuiTableCell-root": {
      padding: 8,
    },
    "& .MuiTableCell-head": {
      color: "#222f3e",
    },
  },
  header: {
    color: "#222f3e",
    fontSize: "1.5rem",
    fontWeight: "700",
    marginLeft: 32,
    marginTop: 24,
  },
  container: {
    padding: 16,
    overflowX: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  imageEmp: {
    width: 56,
    height: 56,
    borderRadius: "50%",
  },
  viewBtn: {
    backgroundColor: "#4f65df",
    color: "#fff",
    borderRadius: "50px",
    fontSize: "12px",
    padding: 8,
    "&:hover": {
      backgroundColor: "#4f65df",
    },
  },
}));

const headCells = [
  { id: "image", label: "" },
  { id: "fullName", label: "Full Name" },
  { id: "pl", label: "PL" },
  { id: "objectiveAmount", label: "Objective" },
  { id: "keyResult", label: "Key Result" },
  { id: "achievePercent", label: "Achieve (%)" },
  { id: "scorePercent", label: "Score (%)" },
  { id: "totalMoonShot", label: "Moon Shot" },
  { id: "view", label: "" },
];

export default function TeamIdvDashboardList(props) {
  const classes = useStyles();
  const history = useHistory();
  const { team, yearSelect, quaterSelect, records } = props;
  const [members, setMembers] = useState([]);
  const [memberItems, setMemberItems] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(members, headCells, filterFn);
  // Data first be members

  useEffect(() => {
    let x = [...memberItems];
    x = x.filter((y) => {
      return y.fullName.toLowerCase().includes(searchKey.toLocaleLowerCase());
    });
    setMembers(x);
  }, [searchKey]);

  useEffect(() => {
    setMembers(records);
    setMemberItems(records);
    setIsLoading(false);
  }, [records]);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item>
          <Typography className={classes.header}>Team Member</Typography>
        </Grid>
      </Grid>
      <Toolbar>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              variant="outlined"
              style={{ width: "100%" }}
              label="Search Employee"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Toolbar>
      <Paper className={classes.container}>
        <TblContainer>
          <TblHead />
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={9}
                  align="center"
                  style={{ width: "100px", padding: "20px" }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {(recordsAfterPagingAndSorting().length > 0 &&
                  recordsAfterPagingAndSorting().map((item) => (
                    <TableRow key={item.empId}>
                      <TableCell style={{ width: "120px" }} align="center">
                        {item.image == null || item.image == "string" ? (
                          <AccountCircleIcon
                            sx={{ color: "#e0e0e0" }}
                            className={classes.imageEmp}
                          />
                        ) : (
                          <img
                            src={`/images/employees/${item.image}`}
                            className={classes.imageEmp}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {item.fullName}
                        <br />
                        {item.position}
                      </TableCell>
                      <TableCell>0</TableCell>
                      <TableCell style={{ paddingLeft: 10 }}>
                        {item.objectiveAmount}
                      </TableCell>
                      <TableCell>{item.objectiveAmount * 3}</TableCell>
                      <TableCell>0</TableCell>
                      <TableCell>0</TableCell>
                      <TableCell>{item.totalMoonShot}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          className={classes.viewBtn}
                          onClick={() => {
                            history.push({
                              pathname: `/idvplanlistapproved/${item.firstName} ${item.lastName}`,
                              state: { employee: item },
                            });
                          }}
                        >
                          <SearchIcon /> View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))) || (
                  <TableRow>
                    <TableCell
                      colSpan={9}
                      align="center"
                      style={{ width: "100px", height: "100px" }}
                    >
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
    </div>
  );
}
