import React from "react";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

const ChipLetterStatus = ({ status }) => {
  const { t, i18n } = useTranslation();
  let label = "";
  let color = "";

  if (status === null) {
    label = t("NoResponse");
    color = "primary";
  } else if (status === 1) {
    label = t("Accept");
    color = "success";
  } else {
    label = t("Refusal");
    color = "error";
  }

  return <Chip label={label} color={color} />;
};

export default ChipLetterStatus
