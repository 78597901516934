import React, { useEffect, useState } from "react";
import { Box, Button, Container, Grid, MenuItem, Stack, Typography, styled, Popper, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CardStyle from "../../shared/general/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AlertResponse from "../../shared/general/AlertResponse";
import { reportLists } from "./all-reports";
import { PND1PdfFile, PND1kPDFfile, PND3PdfFile } from "./filePDFgeneration.js/generatePDFfile";
import { PND91PdfFile } from "./filePDFgeneration.js/PND91PdfFile";
import { Tawi50PDFfile } from "./filePDFgeneration.js/50tawiPDFfile";
import { getUserInformation } from "../../../../actions/user";
import { SPS103PdfFile } from "./filePDFgeneration.js/SPS103PdfFile";
import { SPS110PDFfile } from "./filePDFgeneration.js/SPS110PDFfile";
import { SPS609PDFfile } from "./filePDFgeneration.js/SPS609PDFfile";
import { PND1TxtFile } from "./fileTextgeneration.js/PNDTextFile1";
import { PND1kTxtFile } from "./fileTextgeneration.js/PND1kTextFile";
import { PfPDFfile } from "./filePDFgeneration.js/PfPDFfile";
import { SPS1031PDFfile } from "./filePDFgeneration.js/SPS103-1PDFfile";
// import { getEmployeesDetails } from "../../../../actions/employee";
import { PND1XlsxFile } from "./fileXLSXgeneration.js/PND1XlsxFile";
import { PND1kXlsxFile } from "./fileXLSXgeneration.js/PND1kXlsxFile";
import { SPS110TextFile } from "./fileTextgeneration.js/SPS10-1TextFile";
import employeeService from "../../../../services/employee.service";
import { TaxDeductionXlsxFile } from "./fileXLSXgeneration.js/taxDeductionXlsxFile";
import { getEmployeesTaxDetails, getEmployeesRoleOneTwo } from "../../../../actions/employee";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { KT20kPDFfile } from "./filePDFgeneration.js/kt20kPDFfile";
import { kt20kXlsxFile } from "./fileXLSXgeneration.js/kt20kXlsxFile";
import { getAffiliateOrganization, getSignatureCertificateCompany } from "../../../../actions/company";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { useTranslation } from "react-i18next";
import { PND1CsvFile } from "./fileCSVgeneration.js/PND1CsvFile";
import { SPS110CsvFile } from "./fileCSVgeneration.js/SPS1-10CsvFile";
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from "dayjs";
import DatePickerCustom from "../../shared/date/datePicker";

const StyledRoot = styled("div")({
    backgroundColor: "#FFFFFF !important",
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
    "& .MuiSelect-filled": {
        padding: "16px 32px 16px 12px",
    },
});

const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    border: 0,
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            color: "#919EAB",
            "& i": {
                marginRight: 8,
            },
        },
        "& .MuiAutocomplete-endAdornment": {
            "& .MuiButtonBase-root": {
                fontSize: 14,
                width: 22,
                height: 22,
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&::after": {
            border: "none",
        },
        "&::before": {
            border: "none",
        },
    },
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        [`& .${autocompleteClasses.option}`]: {
            "&:hover": {
                backgroundColor: "#f6f7f8",
                margin: "0 8px",
                borderRadius: 8,
                paddingLeft: 8,
            },
        },
    },
});

export default function DocumentsReport() {
    const { type } = useParams();
    const { t, i18n } = useTranslation();
    const [openAlert, setOpenAlert] = useState({
        isOpen: false,
        type: null,
        label: null,
    });
    const dispatch = useDispatch();
    const [fileType, setFileType] = useState("PDF")
    const { control, handleSubmit, watch } = useForm({});
    const currentYear = new Date().getFullYear();
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(dayjs().format('MM'));
    const [selectedYear, setSelectedYear] = useState(dayjs().year());
    const [isFetching, setIsFetching] = useState(false);
    const [contributionRate, setContributionRate] = useState((type === "PND1" || type === "PND1k") ? 100.0 : 5.0);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [branchNumber, setBranchNumber] = useState("");
    const [branchNumber2, setBranchNumber2] = useState("");

    const { result: userProfile } = useSelector((state) => state.userInformation);
    const { result: employeesRoleOneTwo } = useSelector((state) => state.employeesRoleOneTwo);
    const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);
    const [data, setData] = useState(null);
    // const { result: employeeDetails } = useSelector((state) => state.employeesDetails);

    useEffect(() => {
        dispatch(getUserInformation());
        dispatch(getAffiliateOrganization());
    }, [dispatch]);

    useEffect(() => {
        if (selectedCompany && selectedCompany.idCompany) {
            dispatch(getEmployeesRoleOneTwo({ idCompany: selectedCompany.idCompany }));
        }
    }, [selectedCompany, dispatch]);

    const employeesType = [
        { value: 1, name: "พนักงานประจำ" },
        { value: 2, name: "พนักงานไม่ประจำ" },
    ];

    const handleOpenAlertError = (label) => {
        setOpenAlert({ isOpen: true, type: "error", label: label });
    };

    const handleCloseAlert = () => {
        setOpenAlert({ isOpen: false, type: null, label: null });
    };

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    };

    const handleBranchNumberChange = (e) => {
        let value = e.target.value;
        value = value.replace(/\D/g, '');
        value = value.split('').join(' ');
        setBranchNumber(value);
        setBranchNumber2(value.split('').join(' '));
    };

    const fetchedData = async (type, selectedYear, selectedMonth, idPayrollType, fileType) => {
        try {
            setIsFetching(true);
            const response = await getEmployeesTaxDetails(
                selectedMonth ? dayjs(selectedMonth).format("MM") : null,
                parseInt(selectedYear),
                idPayrollType,
                selectedEmployee ? selectedEmployee : null,
                type,
                selectedCompany ? selectedCompany.idCompany : null
            );
            if (response && response.data) {
                setData(response.data);
                setIsFetching(false);
            } else {
                // setNoData(true);
                setIsFetching(false);
                handleOpenAlertError(`${t("NoData")}`);
                return;
            }
        } catch (error) {
            console.error(error);
            setIsFetching(false);
            handleOpenAlertError(`${t("NoData")}`);
        }
    };

    useEffect(() => {
        if (data && fileType) {
            if (fileType === "PDF") {
                modifyPdf(type, data);
            } else if (fileType === "TEXT") {
                modifyTxtFile(type, data);
            } else if (fileType === "XLSX") {
                modifyXlsxFile(type, data);
            } else if (fileType === "CSV") {
                modifyCSVFile(type, data);
            }
        }
    }, [data, type]);

    const [signatureCertificateCompany, setSignatureCertificateCompany] = useState(null);

    const fetchedCompanyImage = async () => {
        try {
            const response = await getSignatureCertificateCompany(selectedCompany && selectedCompany.idCompany);
            if (response && response.status === 200 && response.data) {
                setSignatureCertificateCompany(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (selectedCompany && selectedCompany.idCompany) {
            fetchedCompanyImage();

            if (selectedCompany.branchCode) {
                let value = selectedCompany.branchCode;
                value = value.replace(/\D/g, '');
                value = value.split('').join(' ');
                setBranchNumber(value);
                setBranchNumber2(value.split('').join(' '));
            };
        };
    }, [selectedCompany]);

    const modifyPdf = async (type, data) => {
        try {
            const generateFunctionMap = {
                "PND1": PND1PdfFile,
                "PND1k": PND1kPDFfile,
                "PND3": PND3PdfFile,
                "PND91": PND91PdfFile,
                "50tawi": Tawi50PDFfile,
                "SPS1-03": SPS103PdfFile,
                "SPS1-03-1": SPS1031PDFfile,
                "SPS1-10": SPS110PDFfile,
                "SPS6-09": SPS609PDFfile,
                "20k": KT20kPDFfile
            };

            const generateFunction = generateFunctionMap[type];

            if (!generateFunction) {
                handleOpenAlertError("Unsupported report type");
                return;
            }

            if ((type === "PND1" || type === "PND1k") && (selectedYear === null || (type === "PND1" && selectedMonth === null))) {
                handleOpenAlertError("ข้อมูลไม่ครบ");
                return;
            } else if (type === "PND91") {
                generateFunction(type, selectedYear, data);
            } else if (type === "50tawi") {
                generateFunction(type, selectedYear, data, signatureCertificateCompany);
            } else if (type === "20k") {
                generateFunction(type, selectedYear, data, contributionRate);
            } else if (type === "SPS1-03-1") {
                generateFunction(userProfile, type, data, signatureCertificateCompany);
            } else if (type === "SPS1-03") {
                generateFunction(type, data, signatureCertificateCompany);
            } else if (type === "SPS6-09") {
                SPS609PDFfile(type, data, signatureCertificateCompany);
            } else if (type === "SPS1-10") {
                if (selectedYear === null || selectedMonth === null) {
                    handleOpenAlertError("ข้อมูลไม่ครบ");
                    return;
                } else {
                    SPS110PDFfile(branchNumber, type, selectedYear, selectedMonth ? dayjs(selectedMonth).format("MM") : null, data, contributionRate, signatureCertificateCompany, branchNumber2);
                }
            } else {
                generateFunction(userProfile, type, selectedYear, selectedMonth ? dayjs(selectedMonth).format("MM") : null, data, signatureCertificateCompany);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const modifyTxtFile = async (type, data) => {
        try {
            if (type === "PND1") {
                if (selectedYear === null || selectedMonth === null) {
                    handleOpenAlertError("ข้อมูลไม่ครบ");
                } else {
                    PND1TxtFile(selectedYear, selectedMonth ? dayjs(selectedMonth).format("MM") : null, data, contributionRate);
                }
            } else if (type === "PND1k") {
                if (selectedYear === null) {
                    handleOpenAlertError("ข้อมูลไม่ครบ");
                } else {
                    PND1kTxtFile(selectedYear, data, contributionRate);
                }
            } else if (type === "SPS1-10") {
                if (selectedYear === null || selectedMonth === null) {
                    handleOpenAlertError("ข้อมูลไม่ครบ");
                } else {
                    SPS110TextFile(data);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const modifyXlsxFile = async (type, data) => {
        try {
            if (type === "PND1") {
                if (selectedYear === null || selectedMonth === null) {
                    handleOpenAlertError("ข้อมูลไม่ครบ");
                } else {
                    PND1XlsxFile(selectedYear, selectedMonth, data, contributionRate);
                }
            } else if (type === "PND1k") {
                if (selectedYear === null) {
                    handleOpenAlertError("ข้อมูลไม่ครบ");
                } else {
                    PND1kXlsxFile(selectedYear, data, contributionRate);
                }
            } else if (type === "20k") {
                if (selectedYear === null) {
                    handleOpenAlertError("ข้อมูลไม่ครบ");
                } else {
                    kt20kXlsxFile(selectedYear, data, contributionRate);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const modifyCSVFile = async (type, data) => {
        try {
            if (type === "PND1") {
                if (selectedYear === null || selectedMonth === null) {
                    handleOpenAlertError("ข้อมูลไม่ครบ");
                } else {
                    PND1CsvFile(selectedYear, selectedMonth, data, contributionRate);
                }
            } else if (type === "SPS1-10") {
                if (selectedYear === null || selectedMonth === null) {
                    handleOpenAlertError("ข้อมูลไม่ครบ");
                } else {
                    SPS110CsvFile(selectedYear, selectedMonth, data, contributionRate);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const namePage = reportLists.find((items) => items.link == type);

    useEffect(() => {
        if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
            let ownCompany = affiliateOrganizationList.find(
                (x) => x.idCompany === userProfile.idCompany
            );
            if (!ownCompany) {
                ownCompany = affiliateOrganizationList[0];
            }
            setSelectedCompany(ownCompany);
        }
    }, [userProfile, affiliateOrganizationList]);

    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <Box margin="16px 0">
                    <Typography variant="h4">{namePage.name}</Typography>
                </Box>

                <Box sx={{ p: 2 }}>
                    <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
                        {type !== "tax-deduction" ? (
                            <ButtonBlue variant={fileType === "PDF" ? "contained" : "outlined"} sx={{ width: 200 }} onClick={() => setFileType("PDF")}>PDF</ButtonBlue>
                        ) : null}

                        {type === "SPS1-10" || type === "PND1" || type === "PND1k" ?
                            <ButtonBlue variant={fileType === "TEXT" ? "contained" : "outlined"} sx={{ width: 200 }} onClick={() => setFileType("TEXT")}>TEXT</ButtonBlue>
                            : null}

                        {type === "PND1" || type === "PND1k" || type === "20k" ?
                            <ButtonBlue variant={fileType === "XLSX" ? "contained" : "outlined"} sx={{ width: 200 }} onClick={() => setFileType("XLSX")}>XLSX</ButtonBlue>
                            : null}

                        {type === "PND1" || type === "SPS1-10" ?
                            <ButtonBlue variant={fileType === "CSV" ? "contained" : "outlined"} sx={{ width: 200 }} onClick={() => setFileType("CSV")}>CSV</ButtonBlue>
                            : null}

                    </Stack>
                </Box>

                <CardStyle style={{ padding: 24 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography className="label" color="text.third">
                                {t("Company")}
                            </Typography>
                            <SelectAffiliateCompany
                                options={affiliateOrganizationList}
                                value={selectedCompany}
                                disabled={isFetching}
                                onChange={(_, value) => {
                                    onChangeCompany(value);
                                }}
                            />
                        </Grid>

                        {/* selected employee */}
                        {type === "50tawi" || type === "PND91" || type === "SPS1-03" ? (
                            <Grid item xs={12} md={6}>
                                <Typography className="label" color="text.third">
                                    {t("SearchEmp")}
                                </Typography>
                                <div className="search-name">
                                    {employeesRoleOneTwo && (
                                        <StyledAutocomplete
                                            options={employeesRoleOneTwo}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setSelectedEmployee(newValue.idEmployees);
                                                } else {
                                                    setSelectedEmployee(null);
                                                }
                                            }}
                                            popupIcon={<i className="fa-light fa-chevron-down"></i>}
                                            getOptionLabel={(option) =>
                                                i18n.resolvedLanguage === "th"
                                                    ? `${option.firstname_TH} ${option.lastname_TH}`
                                                    : option.firstname_EN && option.lastname_EN ? `${option.firstname_EN} ${option.lastname_EN}` : ""
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="filled"
                                                    placeholder={t("SearchEmp")}
                                                />
                                            )}
                                            PopperComponent={StyledPopper}
                                            noOptionsText={"ไม่พบข้อมูล"}
                                        />
                                    )}
                                </div>
                            </Grid>
                        ) : null}

                        {/* selected month */}
                        {type !== "SPS1-03" && type !== "SPS6-09" && type !== "SPS1-03-1" ? (
                            <Grid item xs={12} md={6}>
                                <Typography className="label" color="text.third">
                                    {t("SelectYear")}
                                </Typography>
                                <DatePickerCustom
                                    maxDate={dayjs()}
                                    inputFormat="YYYY"
                                    value={dayjs().year(selectedYear)}
                                    views={['year']}
                                    openTo={'year'}
                                    onChange={(newValue) => {
                                        setSelectedYear(newValue.year());
                                    }}
                                    disabled={isFetching}
                                />
                            </Grid>
                        ) : null}

                        {/* selected year */}
                        { type === "PND1" || type === "PND3" || type === "SPS1-10" ? (
                            <Grid item xs={12} md={6}>
                                <Typography className="label" color="text.third">
                                    {t("SelectMonth")}
                                </Typography>
                                <DatePickerCustom
                                    inputFormat="MMMM"
                                    value={selectedMonth}
                                    views={['month']}
                                    openTo={'month'}
                                    onChange={(newValue) => {
                                        setSelectedMonth(newValue);
                                    }}
                                    disabled={isFetching}
                                />
                            </Grid>
                        ) : null}

                        {/* rate */}
                        {type === "20k" || type === "PND1" || type === "PND1k" || type === "SPS1-10" ? (
                            <Grid item xs={12} md={6}>
                                <Typography className="label" color="text.third">
                                    {type === "20k" || type === "SPS1-10" ? `${t("contributionRatePercentage")}` : `${t("percentTaxRate")}`}
                                </Typography>
                                <TextFieldTheme
                                    value={contributionRate}
                                    onChange={(e) => {
                                        const value = parseFloat(e.target.value);
                                        setContributionRate(isNaN(value) ? "" : value);
                                    }}
                                />
                            </Grid>
                        ) : null}

                        {/* {type === "SPS1-10" && fileType === "PDF" ? (
                            <Grid item xs={12} md={6}>
                                <Typography className="label" color="text.third">
                                    {t("BranchNumber")}
                                </Typography>
                                <TextFieldTheme
                                    label={t("BranchNumber")}
                                    value={branchNumber}
                                    onChange={handleBranchNumberChange}
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                />
                            </Grid>
                        ) : null} */}

                        {type === "SPS6-09" || type === "SPS1-03-1" ? (
                            <Grid item xs={12} container justifyContent="flex-end">
                                <ButtonBlue
                                    variant="contained"
                                    type="submit"
                                    disabled={isFetching}
                                    onClick={
                                        async () => {
                                            await fetchedData(type, selectedYear, selectedMonth, 11);
                                        }}
                                >
                                    {t("seePdf")}
                                </ButtonBlue>
                            </Grid>
                        ) : null}

                        {type === "SPS1-03" ? (
                            <Grid item xs={12} container justifyContent="flex-end">
                                <ButtonBlue
                                    variant="contained"
                                    type="submit"
                                    disabled={selectedEmployee === null || isFetching}
                                    onClick={
                                        async () => {
                                            await fetchedData(type, selectedYear, selectedMonth, 11, "PDF");
                                        }}
                                >
                                    {t("seePdf")}
                                </ButtonBlue>
                            </Grid>
                        ) : null}

                        {type === "50tawi" || type === "PND91" ? (
                            <Grid item xs={12} container justifyContent="flex-end">
                                <ButtonBlue
                                    variant="contained"
                                    type="submit"
                                    disabled={selectedEmployee === null || selectedYear === null || type !== "PND91" || isFetching}
                                    onClick={
                                        async () => {
                                            await fetchedData(type, selectedYear, selectedMonth, 12, "PDF");
                                        }}
                                >
                                    {t("seePdf")}
                                </ButtonBlue>
                            </Grid>
                        ) : null}

                        {fileType === "PDF" ? (
                            <>
                                {type !== "SPS1-03" && type !== "SPS6-09" && type !== "SPS1-03-1" && type !== "tax-deduction" && type !== "50tawi" && type !== "PND91" ? (
                                    <Grid item xs={12} container justifyContent="flex-end">
                                        <ButtonBlue
                                            variant="contained"
                                            type="submit"
                                            disabled={selectedYear === null || isFetching}
                                            onClick={
                                                async () => {
                                                    await fetchedData(type, selectedYear, selectedMonth, type === "SPS1-10" ? 11 : 12, "PDF");
                                                }}
                                        >
                                            {t("seePdf")}
                                        </ButtonBlue>
                                    </Grid>
                                ) : null}
                            </>

                        ) : fileType === "TEXT" ? (
                            <>
                                {type !== "SPS1-03" && type !== "SPS6-09" && type !== "SPS1-03-1" ? (
                                    <Grid item xs={12} container justifyContent="flex-end">
                                        <ButtonBlue
                                            variant="contained"
                                            type="submit"
                                            disabled={selectedYear === null || isFetching}
                                            onClick={async () => await fetchedData(type, selectedYear, selectedMonth, type === "SPS1-10" ? 11 : 12, "TEXT")}
                                        >
                                            {t("Download")} TEXT
                                        </ButtonBlue>
                                    </Grid>
                                ) : null}
                            </>
                        ) : fileType === "XLSX" ? (
                            <>
                                {type !== "SPS1-03" && type !== "SPS6-09" && type !== "SPS1-03-1" ? (
                                    <Grid item xs={12} container justifyContent="flex-end">
                                        <ButtonBlue
                                            variant="contained"
                                            type="submit"
                                            disabled={selectedYear === null || isFetching}
                                            onClick={async () => await fetchedData(type, selectedYear, selectedMonth, type === "SPS1-10" ? 11 : 12, "XLSX")}
                                        >
                                            {t("Download")} XLSX
                                        </ButtonBlue>
                                    </Grid>
                                ) : null}
                            </>
                        ) : fileType === "CSV" ? (
                            <>
                                {type === "SPS1-10" || type === "PND1" ? (
                                    <Grid item xs={12} container justifyContent="flex-end">
                                        <ButtonBlue
                                            variant="contained"
                                            type="submit"
                                            disabled={selectedYear === null || isFetching}
                                            onClick={async () => await fetchedData(type, selectedYear, selectedMonth, type === "SPS1-10" ? 11 : 12, "XLSX")}
                                        >
                                            {t("Download")} CSV
                                        </ButtonBlue>
                                    </Grid>
                                ) : null}
                            </>
                        ) : null}

                    </Grid>
                </CardStyle>
            </Container>
            {openAlert.isOpen && (
                <AlertResponse
                    open={openAlert.isOpen}
                    alertType={openAlert.type}
                    label={openAlert.label}
                    handleClose={handleCloseAlert}
                />
            )}
        </StyledRoot>
    );
}

{/* employment type */ }
{/* {type === "50tawi" || type === "PND91" ? (
                            <Grid item xs={12} md={6}>
                                <Typography className="label" color="text.third">
                                    ประเภทพนักงาน
                                </Typography>
                                <Controller
                                    name="month"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldTheme
                                            {...field}
                                            variant="filled"
                                            select
                                            fullWidth
                                            value={selectedEmployeesType}
                                            onChange={handleEmployeesTypeChange}
                                        >
                                            {employeesType.map((type, index) => (
                                                <MenuItem key={index} value={type.value}>
                                                    {type.name}
                                                </MenuItem>
                                            ))}
                                        </TextFieldTheme>
                                    )}
                                />
                            </Grid>
                        ) : null} */}