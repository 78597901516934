import React from 'react';
import { Card, CardContent, Typography, Grid, Box, styled } from '@mui/material';
import { AccessTimeOutlined } from '@mui/icons-material';

const HeaderBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  padding: '16px 0 32px 0',
}));

const InnerHeaderBox = styled(Box)(() => ({
  borderBottom: '2px solid black',
  padding: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

function WorkTime({ t, todayAttendance }) {

  return (
    <Card sx={{ width: '100%', padding: '16px' }}>
      <CardContent>
        <HeaderBox>
          <InnerHeaderBox>
            <AccessTimeOutlined sx={{ marginRight: 1, fontSize: 30, color: 'warning.main' }} />
            <Typography margin={0} variant="h5" align="center" gutterBottom>
              {t('workTime.attendance')}
            </Typography>
          </InnerHeaderBox>
        </HeaderBox>

        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={6}>
            <Typography variant="subtitle1" align="center">
              {t('workTime.timeIn')}
            </Typography>
            <Typography variant="h6" align="center">
              {todayAttendance && todayAttendance.checkIn ? todayAttendance.checkIn.attendanceTextTime
                : '--:--'}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" align="center">
              {t('workTime.timeOut')}
            </Typography>
            <Typography variant="h6" align="center">
              {todayAttendance && todayAttendance.checkOut
                ? todayAttendance.checkOut.attendanceTextTime
                : '--:--'}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default WorkTime;
