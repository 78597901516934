import React from "react";
import { useSelector } from "react-redux";

import AdminAnnouncement from "../admin/announcement/index";
import UserAnnouncement from "../user/announcement/index";

const Announcement = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ? (
          <AdminAnnouncement />
        ) : (
          <UserAnnouncement />
        )
      ) : null}
    </>
  );
};

export default Announcement;
