import { httpClient } from "./httpClient";

const getDashboardOverview = (query) => {
  return httpClient.get(`/dashboard/overview`, { params: query });
};

const getDashboardOvertime = (query) => {
  return httpClient.get(`/dashboard/overtime`, { params: query });
};

const getDashboardCost = (query) => {
  return httpClient.get(`/dashboard/cost`, { params: query });
};

const getDashboardWorkingtime = (query) => {
  return httpClient.get(`/dashboard/workingtime`, { params: query });
};

const getOverviewOTPayroll = (query) => {
  return httpClient.get(`/dashboard/ot-payroll`, { params: query });
};

export default {
  getDashboardOverview,
  getDashboardOvertime,
  getDashboardCost,
  getDashboardWorkingtime,
  getOverviewOTPayroll
};
