import { ListItemSecondaryAction } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import axios from "axios";

const LineData = {
  labels: ["Quater 1", "Quater 2", "Quater 3", "Quater 4"],
  datasets: [
    {
      label: "OKRs",
      data: [0, 0, 0, 0],
      fill: false,
      backgroundColor: "rgb(255, 99, 132)",
      borderColor: "rgb(255, 99, 132)",
    },
    {
      label: "Moon Shot",
      data: [0, 0, 0, 0],
      fill: false,
      backgroundColor: "#ff9933",
      borderColor: "#ff9933",
    },
    {
      label: "Amount OKR",
      data: [0, 0, 0, 0],
      fill: false,
      backgroundColor: "rgb(75, 192, 192)",
      borderColor: "rgb(75, 192, 192)",
    },
  ],
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

export default function TeamGraphDashboard(props) {
  const { team, idvName, typePlan, records, quaterSelect, yearSelect } = props;
  const [lineChart, setLineChart] = useState([]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const countOKRs = (data, quater) => {
    let okrs = data.filter(function (x) {
      return x.quater == quater;
    });
    return okrs.length > 0 ? okrs.length * 3 + okrs.length : 0;
  };

  const countOKR = (data, quater) => {
    let okrs = data.filter(function (x) {
      return x.quater == quater;
    });
    return okrs.length;
  };

  const countMoonShot = (data, quater) => {
    let planItems = data.filter(function (x) {
      return x.quater == quater;
    });

    let moonShotScore = 0;
    for (let i = 0; i < planItems.length; i++) {
      moonShotScore +=
        planItems[i].moonShotKR1 +
        planItems[i].moonShotKR2 +
        planItems[i].moonShotKR3;
    }

    return moonShotScore;
  };

  const settingChart = (planItems) => {
    //Setting OKRs
    let okrsQ1 = countOKRs(planItems, "Q1");
    let okrsQ2 = countOKRs(planItems, "Q2");
    let okrsQ3 = countOKRs(planItems, "Q3");
    let okrsQ4 = countOKRs(planItems, "Q4");
    let okrsQuater = [okrsQ1, okrsQ2, okrsQ3, okrsQ4];
    LineData.datasets[0].data = okrsQuater;

    //Setting Moon Shot
    let moonShotQ1 = countMoonShot(planItems, "Q1");
    let moonShotQ2 = countMoonShot(planItems, "Q2");
    let moonShotQ3 = countMoonShot(planItems, "Q3");
    let moonShotQ4 = countMoonShot(planItems, "Q4");
    let moonShotQuater = [moonShotQ1, moonShotQ2, moonShotQ3, moonShotQ4];
    LineData.datasets[1].data = moonShotQuater;

    //Setting OKR
    let amountOkrQ1 = countOKR(planItems, "Q1");
    let amountOkrQ2 = countOKR(planItems, "Q2");
    let amountOkrQ3 = countOKR(planItems, "Q3");
    let amountOkrQ4 = countOKR(planItems, "Q4");
    let amountOKRQuater = [amountOkrQ1, amountOkrQ2, amountOkrQ3, amountOkrQ4];
    LineData.datasets[2].data = amountOKRQuater;

    //Set State
    setLineChart(LineData);
  };

  const fetchData = () => {
    //Individual Plan
    if (typePlan == "individual") {
      settingChart(records);
    }

    /*
    else {
      let empList = [];
      //Employees
      axios
        .get(`${process.env.REACT_APP_API}/employees`)
        .then((res) => {
          empList = res.data;
        })
        .catch((err) => console.log(err));

      //Team Plan
      axios
        .get(`${process.env.REACT_APP_API}/idvplans`)
        .then((res) => {
          let idvPlans = res.data.filter((x) =>
            x.planStatus.includes("Approved")
          );

          idvPlans.forEach((element) => {
            //emp data
            let empData = empList.filter(function (item) {
              return item.fullName.includes(element.createBy);
            });

            element.department = empData[0].department;
            element.position = empData[0].position;
            element.image = empData[0].image;
          });

          console.log("idvPlans", idvPlans);

          settingChart(idvPlans);
        })
        .catch((err) => console.log(err));
    }
    */
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Line options={options} data={LineData} />
    </>
  );
}
