import React, { useEffect, useState } from "react";
import { Typography, styled, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Dropzone from "react-dropzone";
import AddIcon from "../../assets/add.png";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledDropzone = styled("div")({
  "& .dropzone-upload-file": {
    textAlign: "center",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    height: 200,
    display: "flex",
    justifyContent: "center",
    "& .inner-dropzone": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      "& img": {
        marginTop: 8,
      },
    },
  },
});

const UploadFile = ({ acceptFileType, acceptFileTypeLabel, onChange }) => {
  const [files, setFiles] = useState([]);
  const { t, i18n } = useTranslation();

  const thumbs = files.map((file) => {
    if (file.type === "application/pdf") {
      return (
        <Typography key={file.name} fontSize={"1.25rem"} fontWeight={500}>
          {file.path}
        </Typography>
      );
    } else {
      return (
        <div style={{ width: 256, height: 144 }} key={file.name}>
          <img
            src={file.preview}
            style={{ maxWidth: "100%", height: "auto", height: "100%" }}
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
          />
        </div>
      );
    }
  });

  const handleRemoveFile = (e) => {
    e.stopPropagation();
    setFiles([]);
    onChange(null);
  };

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <StyledDropzone>
      <Dropzone
        multiple={false}
        maxSize={3145728} 
        accept={acceptFileType}
        onDrop={(acceptedFiles) => {
          setFiles(
            acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
          onChange(acceptedFiles[0]);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps({ className: "dropzone-upload-file" })}>
              <div className="inner-dropzone">
                <input {...getInputProps()} />
                {files.length > 0 ? (
                  <>
                    {thumbs}
                    <IconButton
                      type="button"
                      color="error"
                      onClick={handleRemoveFile}
                      sx={{ marginTop: 1 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <img alt="AddIcon" src={AddIcon} width="80" />
                    <Typography
                      style={{
                        marginTop: 8,
                        backgroundColor: "transparent",
                      }}
                      className={`${files && `placeholderLabel`}`}
                      variant="body2"
                      color="text.secondary"
                    >
                      {`${t("DragDropOrClickSelectFile")}`}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {`(${acceptFileTypeLabel})`}
                    </Typography>
                  </>
                )}
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    </StyledDropzone>
  );
};

export default UploadFile;
