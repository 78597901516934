import React, { useEffect,useState } from "react";
import {
    TextField, 
    InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const SearchData = ({onChangeName}) => {

    return(
        <div>
            <TextField
              id="outlined-demandName"
              name='JobGroup name'
              size="small"
              variant="outlined"
              label='JobGroup name'
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
              placeholder='JobGroup Name'
              onChange={(e) => onChangeName(e)}
            />
        </div>
    )
}

export default SearchData;
