import DoneAll from "@mui/icons-material/DoneAll";
import DeleteIcon from "@mui/icons-material/Delete";
import { Avatar, Box, styled, Typography, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    approveRequestTime,
    getAllRequestTimeBy,
    getAllRequestTimeWithDrawBy,
} from "../../../../actions/requestTime";
import ChipWithDrawCustom from "../../shared/chipWithDrawCustom";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TableCustom from "../../shared/tableCustom";
import DialogConfirmReject from "./DialogConfirmReject";
import CardRequest from "./CardRequest";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName, getUserPosition } from "../../../../utils/userData";
import { updateStatusProject } from "../../../../actions/project";

const StyledButtonCancel = styled(ButtonBlue)({
    backgroundColor: "#E793B8",
    borderRadius: "8px",
    color: "#FFFFFF",
    marginRight: 8,
    width: 130,
    "&:hover": {
        backgroundColor: "#DC6384",
    },
});

const RequestProject = (props) => {
    const { searchDate, setSnackBarConfig, setNumberOfList, ProjectData, setProjectData } = props;
    const { t, i18n } = useTranslation();
    const [pendingRows, setPendingRows] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const mobileResponsive = useMediaQuery("(max-width:600px)");
    const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);
    const { result: userProfile } = useSelector((state) => state.userProfile);

    const columns = [
        {
            name: `${t("FullName")}`,
            minWidth: "230px",
            width: "230px",
            cellRender: (row) => (
                <Box display="flex" alignItems="center">
                    <Avatar
                        sx={{
                            marginRight: "8px",
                            width: 40,
                            height: 40,
                            "& img": { objectFit: "contain" },
                        }}
                        src={row.imageURL}
                    />
                    <Box flexGrow={1}>
                        <Typography>{getUserFullName(row)}</Typography>
                        <Typography color="text.third" fontSize="14px">
                            {getUserPosition(row)}
                        </Typography>
                    </Box>
                </Box>
            ),
        },
        {
            name: `${t("ProjectName")}`,
            minWidth: "150px",
            width: "150px",
            cellRender: (row) => (
                <Fragment>
                    <Typography fontSize={14}>{row.ProjectName}</Typography>
                </Fragment>
            ),
        },
        {
            name: `${t("Impact")}`,
            minWidth: "150px",
            width: "150px",
            cellRender: (row) => (
                <Fragment>
                    <Typography fontSize={14}>{row.impact}</Typography>
                </Fragment>
            ),
        },
        {
            name: `${t("Size")}`,
            minWidth: "150px",
            width: "150px",
            cellRender: (row) => (
                <Fragment>
                    <Typography fontSize={14}>{row.size}</Typography>
                </Fragment>
            ),
        },
        {
            name: `${t("Role")}`,
            minWidth: "150px",
            width: "150px",
            cellRender: (row) => (
                <Fragment>
                    <Typography fontSize={14}>{row.role}</Typography>
                </Fragment>
            ),
        },
        {
            name: `${t("Type")}`,
            minWidth: "150px",
            width: "150px",
            cellRender: (row) => (
                <Fragment>
                    <Typography fontSize={14}>{row.Type}</Typography>
                </Fragment>
            ),
        },
        {
            name: `${t("Start")}`,
            headerTextAlign: "center",
            minWidth: "150px",
            width: "150px",
            cellRender: (row) => (
                <Box sx={{ textAlign: "center" }}>
                    <Typography>
                        {i18n.resolvedLanguage === "th" ?
                            dayjs(row.dateStart).format("DD MMM") + " " + (parseInt(dayjs(row.dateStart).format("YYYY")) + 543) :
                            dayjs(row.dateStart).format("DD MMM YYYY")
                        }
                    </Typography>
                    <Typography>
                        {dayjs(row.dateStart).format("HH:mm")}
                    </Typography>
                </Box>
            ),
        },
        {
            name: `${t("End")}`,
            headerTextAlign: "center",
            minWidth: "150px",
            width: "150px",
            cellRender: (row) => (
                <Box sx={{ textAlign: "center" }}>
                    <Typography>
                        {i18n.resolvedLanguage === "th" ?
                            dayjs(row.dateEnd).format("DD MMM") + " " + (parseInt(dayjs(row.dateEnd).format("YYYY")) + 543) :
                            dayjs(row.dateEnd).format("DD MMM YYYY")
                        }
                    </Typography>
                    <Typography>
                        {dayjs(row.dateEnd).format("HH:mm")}
                    </Typography>
                </Box>
            ),
        },
        {
            name: `${t("Details")}`,
            minWidth: "200px",
            cellRender: (row) => (
                <Box>
                    <Typography fontSize="14px">
                        {row.note}
                    </Typography>
                    {/* <Typography color="text.third" fontSize="14px">
                        {row.note}
                    </Typography> */}
                </Box>
            ),
        },
    ];

    const handleConfirmReject = async (comment) => {
        try {
            const formData = selectedRows.map(item => ({ ...item, status: 0, note: comment }));
            const response = await updateStatusProject(formData);

            if (response && response.status === 200) {
                setIsOpenRejectDialog(false);
                setProjectData(prevDataProject => prevDataProject.filter(project => !selectedRows.find(row => row.idEmployeeProject === project.idEmployeeProject)));
                setSnackBarConfig({
                    open: true,
                    message: `${t("Successfully")}`,
                    type: "success",
                });
            } else {
                setSnackBarConfig({
                    open: true,
                    message: `${t("Unsuccessful")}`,
                    type: "error",
                });
            }
        } catch (error) {
            console.error(error);
            setSnackBarConfig({
                open: true,
                message: `${t("Unsuccessful")}`,
                type: "error",
            });
        }
    };

    const handleApproveProject = async (data, status) => {
        try {
            const formData = data.map(item => {
                return { ...item, status: status }; // Set a new status
            });

            const response = await updateStatusProject(formData);
            if (response && response.status === 200) {
                setProjectData(prevDataProject => prevDataProject.filter(project => !selectedRows.find(row => row.idEmployeeProject === project.idEmployeeProject)));
                setSnackBarConfig({
                    open: true,
                    message: `${t("Successfully")}`,
                    type: "success",
                });
            } else {
                setSnackBarConfig({
                    open: true,
                    message: `${t("Unsuccessful")}`,
                    type: "error",
                });
            }
        } catch (error) {
            console.error(error);
            setSnackBarConfig({
                open: true,
                message: `${t("Unsuccessful")}`,
                type: "error",
            });
        }
    }

    const handleClickAll = () => {
        setSelectedRows(pendingRows);
    };

    const handleCancel = () => {
        setSelectedRows([]);
    };

    useEffect(() => {
        const tempPending = [];

        if (ProjectData) {
            ProjectData.map(item => {
                tempPending.push(item);
            });
        }

        let pendingList = tempPending.sort(function (a, b) {
            return new Date(b.createdDate) - new Date(a.createdDate);
        });
        setPendingRows(pendingList);
        setNumberOfList(pendingList.length);
    }, [ProjectData]);

    return (
        <Box>
            {mobileResponsive && (
                <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
                    {(selectedRows ? selectedRows.length : 0) > 0 && (
                        <StyledButtonCancel
                            startIcon={<DeleteIcon />}
                            onClick={handleCancel}
                        >
                            {`${t("Cancel")}`}
                        </StyledButtonCancel>
                    )}
                    {!(
                        (selectedRows ? selectedRows.length : 0) === pendingRows.length
                    ) && (
                            <ButtonBlue
                                startIcon={<DoneAll />}
                                variant="contained"
                                onClick={handleClickAll}
                            >
                                {`${t("SelectAll")}`}
                            </ButtonBlue>
                        )}
                </Box>
            )}
            {selectedRows.length > 0 && (
                <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
                    <ButtonBlue
                        style={{ marginRight: 8 }}
                        startIcon={<DoneAll />}
                        variant="contained"
                        onClick={() => handleApproveProject(selectedRows, 1)}
                    >
                        {`${t("Approved")} ${selectedRows.length > 0 ? `${selectedRows.length} ${t("List")}` : ""}`}
                    </ButtonBlue>
                    <ButtonBlue
                        startIcon={<DoneAll />}
                        variant="outlined"
                        onClick={() => setIsOpenRejectDialog(true)}
                    >
                        {`${t("NotApproved")} ${selectedRows.length > 0 ? `${selectedRows.length} ${t("List")}` : ""}`}
                    </ButtonBlue>
                </Box>
            )}
            {!mobileResponsive ? (
                <TableCustom
                    columns={columns}
                    rows={ProjectData && ProjectData.length > 0 ? pendingRows : []}
                    canSelect
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                />
            ) : (
                <div>
                    {ProjectData && ProjectData.length > 0 ? (
                        pendingRows.map((row) => (
                            <CardRequest
                                row={row}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                            />
                        ))
                    ) : (
                        <div>
                            <Typography align="center">{`${t("NoData")}`}</Typography>
                        </div>
                    )}
                </div>
            )}

            <DialogConfirmReject
                rejectCount={selectedRows.length}
                open={isOpenRejectDialog}
                onClose={() => {
                    setIsOpenRejectDialog(false);
                }}
                handleSubmit={(comment) => {
                    handleConfirmReject(comment);
                }}
            />
        </Box>
    );
};

export default RequestProject;
