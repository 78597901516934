import React, { useState, useEffect } from "react";
import { Box, Container, Typography, Card, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import DataTable from "./components/DataTable";
import dayjs from "dayjs";
import ButtonBlue from "../../shared/general/ButtonBlue";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CustomStepper from "../../manager/e-memo/components/Stepper";
import AdvanceSearch from "./components/AdvanceSearch";
import RequestForm from "./components/RequestForm";
import { useDispatch, useSelector } from "react-redux";
import { getEmemoRequestFormById } from "../../../../actions/ememo";
import RequestDetailsModal from "./components/RequestDetailsModal";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  padding: "20px",
});

const CardStyled = styled(Card)({
  marginTop: 16,
  marginBottom: 16,
  padding: 16,
  border: "none",
  borderRadius: 16,
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  "& .MuiCardContent-root": {
    padding: 24,
    borderRadius: 16,
  },
});

const StyledCardContent = styled(CardContent)({
  padding: 24,
  borderRadius: 16,
});

const EMemoWorklistPage = () => {
  const { t } = useTranslation();
  const [searchData, setSearchData] = useState({
    documentNo: "",
    creator: "",
    requester: "",
    approver: "",
    createdDateFrom: null,
    createdDateTo: null,
    subject: "",
    eMemoType: "",
    status: "",
    classificationLevels: "",
    priority: "",
  });

  const [isRequestFormVisible, setRequestFormVisible] = useState(false);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();
  const { eMemos } = useSelector((state) => state.eMemos);
  const [filteredEMemos, setFilteredEMemos] = useState([]);
  const userRole = "user";
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (userProfile) {
      dispatch(getEmemoRequestFormById(userProfile.idEmployees, userRole));
    }
  }, [dispatch, userProfile]);

  useEffect(() => {
    setFilteredEMemos(eMemos);
  }, [eMemos]);

  useEffect(() => {
    const filtered = eMemos.filter((memo) => {
      const createdDate = dayjs(memo.created_date);
      const createdDateFrom = searchData.createdDateFrom
        ? dayjs(searchData.createdDateFrom)
        : null;
      const createdDateTo = searchData.createdDateTo
        ? dayjs(searchData.createdDateTo)
        : null;

      return (
        (!searchData.documentNo ||
          memo.document_no.includes(searchData.documentNo)) &&
        (!searchData.creator || memo.creator.includes(searchData.creator)) &&
        (!searchData.requester ||
          memo.requester.includes(searchData.requester)) &&
        (!searchData.approver ||
          memo.approver_name.includes(searchData.approver)) &&
        (!searchData.subject || memo.subject.includes(searchData.subject)) &&
        (!searchData.status || memo.status.includes(searchData.status)) &&
        (!createdDateFrom ||
          createdDate.isAfter(createdDateFrom) ||
          createdDate.isSame(createdDateFrom, "day")) &&
        (!createdDateTo ||
          createdDate.isBefore(createdDateTo) ||
          createdDate.isSame(createdDateTo, "day")) &&
        (!searchData.classificationLevels ||
          memo.classificationLevels.includes(
            searchData.classificationLevels
          )) &&
        (!searchData.priority || memo.priority.includes(searchData.priority)) &&
        (!searchData.eMemoType || memo.eMemoType.includes(searchData.eMemoType))
      );
    });

    setFilteredEMemos(filtered);
  }, [searchData, eMemos]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchData, [name]: value });
  };

  const handleDateChange = (name, date) => {
    setSearchData({ ...searchData, [name]: date });
  };

  const handleRequestForm = () => {
    setRequestFormVisible(true);
  };

  const handleSearchOff = () => {
    setSearchData({
      documentNo: "",
      creator: "",
      createdDateFrom: null,
      createdDateTo: null,
      subject: "",
      status: "",
      approver: "",
      classificationLevels: "",
      priority: "",
      eMemoType: "",
    });
  };

  const handleViewDetails = (request) => {
    setSelectedRequest(request);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedRequest(null);
  };

  const formatDateRange = () => {
    const now = dayjs();
    const startOfMonth = now.startOf("month").format("DD MMM YYYY");
    const endOfMonth = now.endOf("month").format("DD MMM YYYY");
    const from = searchData.createdDateFrom
      ? dayjs(searchData.createdDateFrom).format("DD MMM YYYY")
      : startOfMonth;
    const to = searchData.createdDateTo
      ? dayjs(searchData.createdDateTo).format("DD MMM YYYY")
      : endOfMonth;
    return `[${from} - ${to}]`;
  };

  const columns = [
    {
      name: "Document No.",
      selector: (row) => row.document_no,
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) => row.created_date,
      sortable: true,
    },
    { name: "Subject", selector: (row) => row.subject, sortable: true },
    { name: "Status", selector: (row) => row.status, sortable: true },
    { name: "Creator", selector: (row) => row.creator, sortable: true },
    { name: "Requester", selector: (row) => row.requester, sortable: true },
    {
      name: "Approver",
      selector: (row) => row.approver_name,
      sortable: true,
    },
    { name: "e-Memo Type", selector: (row) => row.eMemoType, sortable: true },
    {
      name: "Classification Level",
      selector: (row) => row.classificationLevels,
      sortable: true,
    },
    { name: "Priority", selector: (row) => row.priority, sortable: true },
  ];

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {!isRequestFormVisible ? (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" style={{ paddingTop: 8 }}>
                My e-Memo Worklist {formatDateRange()}
              </Typography>
              <ButtonBlue
                startIcon={<AddRoundedIcon />}
                variant="contained"
                style={{ marginTop: 8 }}
                onClick={handleRequestForm}
              >
                {`${t("e-Memo Request")}`}
              </ButtonBlue>
            </Box>

            <CardStyled>
              <StyledCardContent>
                <AdvanceSearch
                  searchData={searchData}
                  handleChange={handleChange}
                  handleDateChange={handleDateChange}
                  handleSearchOff={handleSearchOff}
                />
              </StyledCardContent>
            </CardStyled>

            <Typography variant="h6" style={{ marginBottom: 16 }}>
              Worklist
            </Typography>
            <DataTable
              columns={columns}
              data={filteredEMemos}
              onRowClick={handleViewDetails}
            />

            {/* <ButtonBlue
              variant="outlined"
              color="primary"
              style={{ marginTop: 16 }}
            >
              Export to Excel
            </ButtonBlue> */}
          </>
        ) : (
          <>
            <CustomStepper activeStep={0} />
            <CardStyled>
              <StyledCardContent>
                <RequestForm
                  handleChange={handleChange}
                  setRequestFormVisible={setRequestFormVisible}
                />
              </StyledCardContent>
            </CardStyled>
          </>
        )}

        {selectedRequest && (
          <RequestDetailsModal
            open={isModalOpen}
            onClose={handleCloseModal}
            requestDetails={selectedRequest}
            userProfile={userProfile}
          />
        )}
      </Container>
    </StyledRoot>
  );
};

export default EMemoWorklistPage;
