import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
// import StatusRequest from "../../shared/requestList/stausRequest";

const StyledBox = styled(Box)(({ statusrequest, active }) => ({
  margin: "auto",
  width: 40,
  height: 40,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 12,
  ...(statusrequest === 1 &&
    active && {
      backgroundColor: "#ebfaf2",
    }),
  ...(statusrequest === 0 && {
    backgroundColor: "#fdf3f5",
  }),
  ...(statusrequest === null &&
    active === 0 && {
      backgroundColor: "#f3f6f8",
    }),
  ...(statusrequest === null &&
    active === 1 && {
      backgroundColor: "#fffcd6",
    }),
  ...(active === 0 && {
    backgroundColor: "#f3f6f8",
  }),
}));

const StatusRequest = ({ status, active, step }) => {
  const renderIcon = () => {
    // if (active) {
    if (status === 1) {
      return (
        <i
          className={`fas fa-check-circle`}
          style={{ fontSize: 24, color: "#00c292" }}
        ></i>
      );
    } else if (status === 0) {
      return (
        <i
          className={`fas fa-times-circle`}
          style={{ fontSize: 24, color: "#e46a76" }}
        ></i>
      );
    } else if (status === 3) {
      return (
        <i
          className={`fas fa-minus-circle`}
          style={{ fontSize: 24, color: "#bfbfbf" }}
        ></i>
      );
    } else {
      return (
        <i
          className={`fas fa-question-circle`}
          style={{ fontSize: 24, color: "#fec90f" }}
        ></i>
      );
    }
    // } else {
    //   return (
    //     <i
    //       className={`fas fa-minus-circle`}
    //       style={{ fontSize: 24, color: "#bfbfbf" }}
    //     ></i>
    //   );
    // }
  };

  return (
    <StyledBox active={active} statusrequest={status}>
      {renderIcon()}
    </StyledBox>
  );
};

function CardCourseStatus({ request }) {
  const { t } = useTranslation();

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        borderRadius: 4,
        display: "flex",
        alignItems: "flex-start",
        bgcolor: "#F1F0F0",
        width: "100%",
        marginBottom: "10px",
        whiteSpace: "pre-wrap",
      }}
    >
      <StatusRequest
        status={request.approveStatus}
        active={request.approveStatus !== null}
        sx={{ width: 60, height: 60, mr: 2, flexShrink: 0 }}
      />
      <Box sx={{ flexGrow: 1, minWidth: 0, marginLeft: "8px" }}>
        {/* <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("Status")}: {t(checkStatus(request))}
        </Typography> */}
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("CourseName")}: {request.courseName}
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("Type")}: {request.register_type}{" "}
          {request.courseType ? `(${request.courseType})` : ""}
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("RequestDate")}: {dayjs(request.createDate).format("D MMM YYYY")} (
          {dayjs(request.createDate).format("HH:mm")})
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("Approver")}:{" "}
          {`${request.approveByFirstName || "-"} ${
            request.approveByLastName || ""
          }`}{" "}
          {request.approveByPositionName
            ? `(${request.approveByPositionName})`
            : ""}
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("ApprovedDate")}:{" "}
          {request.approveDate
            ? `${dayjs(request.approveDate).format("D MMM YYYY")} (${dayjs(
                request.approveDate
              ).format("HH:mm")})`
            : "-"}
        </Typography>
      </Box>
    </Paper>
  );
}

export default CardCourseStatus;
