import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  Box,
  Grid,
  Paper,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Divider,
  Chip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ExternalLearn from "../../shared/assets/ExternalLearn_header_card.jpg";

const StyleCardRoot = styled(Card)({
  minWidth: "300px",
  boxShadow: "rgb(90 114 123 / 20%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  marginTop: "16px",
  marginRight: "16px",
  marginBottom: "16px",
  marginLeft: "16px",
  position: "relative", // Added relative positioning here
});

const HeaderContainer = styled(Box)({
  // padding: "2em",
  textAlign: "left",
  overflowWrap: "anywhere",
  "& .title": {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    fontWeight: "600",
  },
  "& .titleDesc": {
    color: "#9e9e9e",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
});

const StyleCardChip = styled("div")({
  position: "absolute",
  top: "12px",
  right: "12px",
  zIndex: 2,

  ["@media (max-width: 900px)"]: {
    top: "0px",
    right: "0px",
  },
});

const useStyles = makeStyles({
  media: {
    height: 120,
    "z-index": 1,
  },
});

function CardSkill({ data }) {
  const classes = useStyles();

  return (
    <Fragment>
      <StyleCardRoot>
        <CardMedia
          className={classes.media}
          image={data.imgurl ? data.imgurl : ExternalLearn}
          title={data.img ? data.img : ""}
        />
        <CardContent
          style={{
            padding: "0px 16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <Box
            style={{ width: "100%", marginBottom: "48px", marginTop: "16px" }}
          >
            <HeaderContainer>
              <Typography
                variant="subtitle1"
                className="title"
                style={{ fontWeight: 600 }}
              >
                {data.skillName}
              </Typography>
            </HeaderContainer>
            <HeaderContainer>
              <Typography
                variant="subtitle2"
                className="titleDesc"
                style={{
                  color: "#00000080",
                }}
              >
                {`#${
                  data && data.skillGroupName ? data.skillGroupName : "NULL"
                }`}
              </Typography>
            </HeaderContainer>
          </Box>

          <Box style={{ width: "100%" }}>
            <HeaderContainer>
              <div className="titleDesc">
                <Typography
                  variant="subtitle2"
                  style={{ display: "flex", alignContent: "center" }}
                >
                  <PeopleAltIcon style={{ marginRight: "8px" }} />
                  {"99,999 VIEWS"}
                </Typography>
              </div>
            </HeaderContainer>
            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
          </Box>
        </CardContent>
        <StyleCardChip>
          <Chip
            label={`level ${data.level}`}
            size="medium"
            style={{
              backgroundColor: "#71dd37",
              color: "#fff",
              fontWeight: 600,
            }}
          />
        </StyleCardChip>
      </StyleCardRoot>
    </Fragment>
  );
}

export default CardSkill;
