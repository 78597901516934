import { httpClient } from "./httpClient";

const getAllFailureHistory = () => {
  return httpClient.get("failureHistory");
};

const getFailureHistoryById = (id) => {
  return httpClient.get(`failureHistory/failureHistoryById/${id}`);
};

const postFailureHistory = (data) => {
  return httpClient.post(`failureHistory/postFailureHistory`, data);
};

const putFailureHistory = (id, data) => {
  return httpClient.post(`failureHistory/putFailureHistory/${id}`, data);
};

const deleteFailureHistory = (id, data) => {
  return httpClient.post(`failureHistory/deleteFailureHistory/${id}`, data);
};

export default {
  getAllFailureHistory,
  getFailureHistoryById,
  postFailureHistory,
  putFailureHistory,
  deleteFailureHistory,
};
