import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  styled,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  TextField,
  InputAdornment,
  Grid,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { getTeamObjective } from "../../../../../../actions/okrs";
import Loading from "../../../../shared/loading";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import ObjectiveCard from "./ObjectiveCard";
import SearchIcon from "@mui/icons-material/Search";

const StyledRoot = styled(Box)({
  "& .MuiFilledInput-root input": {
    padding: "16px 12px 16px 12px",
  },
});

const StyledCard = styled(Card)({
  minHeight: "70px",
  boxShadow: "none",
  borderRadius: 20,
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
});

const StyleHeadBox = styled(Box)({
  "& .box-header": {
    minHeight: "200px",
    padding: "16px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .text-header": {
      fontSize: "48px",
      fontWeight: "bold",
      marginLeft: "40px",
    },
    "& .img-header": {
      objectFit: "contain",
    },
  },
  "@media (max-width: 600px)": {
    "& .box-header": {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& .text-header": {
        fontSize: "36px",
        marginLeft: 0,
        marginTop: "20px",
      },
    },
  },
});

const UpdateProgressTeamPage = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [teamObjective, setTeamObjective] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [selectYear, setSelectYear] = useState(dayjs().get("year"));
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  useEffect(() => {
    let isMounted = true;
    dispatch(getTeamObjective()).then((res) => {
      if (isMounted) {
        if (res.status === 200) {
          setTeamObjective(res.data);
        }
        setIsFetching(false);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  const handleChangeYear = (event) => {
    setSelectYear(event.target.value);
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  return (
    <StyledRoot className="page">
      <Box padding="24px 0">
        <Container maxWidth="lg">
          <StyledCard
            style={{
              marginBottom: "48px",
              marginTop: "20px",
              background: `linear-gradient(to right, rgba(254, 196, 144, 0.7), rgba(235, 148, 192, 0.7))`,
            }}
          >
            <StyleHeadBox>
              <Box className="box-header">
                <Typography className="text-header">
                  {t("UpdateTeamGoalStatus")}
                </Typography>
                <img
                  className="img-header"
                  src={`${process.env.PUBLIC_URL}/assets/okrs/manager/update-team-goals.png`}
                  alt="icon"
                />
              </Box>
            </StyleHeadBox>
          </StyledCard>

          <Box marginBottom={"24px"}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <InputLabel>{t("SelectYear")}</InputLabel>
                  <Select
                    label={t("SelectYear")}
                    value={selectYear}
                    onChange={handleChangeYear}
                    sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
                  >
                    {[0, 1, 2].map((item) => (
                      <MenuItem key={item} value={dayjs().get("year") - item}>
                        {dayjs()
                          .subtract(item, "year")
                          .format(
                            i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"
                          )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <InputLabel>{t("SelectStatus")}</InputLabel>
                  <Select
                    label={t("SelectStatus")}
                    value={statusFilter}
                    onChange={handleStatusFilterChange}
                    sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
                  >
                    <MenuItem value="">
                      <em>{t("AllStatuses")}</em>
                    </MenuItem>
                    <MenuItem value="completed">
                      {t("ObjectiveSuccess")}
                    </MenuItem>
                    <MenuItem value="inProgress">{t("InProgress")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  fullWidth
                  label={t("SearchObjectives")}
                  variant="outlined"
                  value={searchQuery}
                  onChange={handleSearchQueryChange}
                  sx={{
                    borderRadius: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          {isFetching ? (
            <Box display="flex" justifyContent="center" paddingTop="24px">
              <Loading />
            </Box>
          ) : (
            <Stack spacing={2} marginTop={4}>
              {teamObjective && teamObjective.length > 0 ? (
                teamObjective
                  .filter((item) => item.year === selectYear)
                  .filter((item) => {
                    if (statusFilter === "completed") {
                      return item.isSuccess === 1;
                    } else if (statusFilter === "inProgress") {
                      return item.isSuccess !== 1;
                    }
                    return true;
                  })
                  .filter((item) => {
                    if (searchQuery === "") return true;
                    const lowerSearchQuery = searchQuery.toLowerCase();
                    return (
                      item.objectiveName &&
                      item.objectiveName
                        .toLowerCase()
                        .includes(lowerSearchQuery)
                    );
                  })
                  .map((objective, index) => (
                    <ObjectiveCard key={index} data={objective} />
                  ))
              ) : (
                <Box display="flex" justifyContent="center" paddingTop="24px">
                  <Typography>{t("NoItems")}</Typography>
                </Box>
              )}
            </Stack>
          )}
        </Container>
      </Box>
    </StyledRoot>
  );
};

export default UpdateProgressTeamPage;
