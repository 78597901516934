import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { AccessTimeRounded } from "@mui/icons-material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import ChipStatus from "../../../../../shared/pages/okrs/components/ChipStatus";
import RatingChip from "./ratingChip";

const DialogTitleStyled = styled(DialogTitle)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const KRDetailModal = ({
  open,
  handleClose,
  data,
  getStatusAchieveApprove,
}) => {
  const { t } = useTranslation();

  const score =
    data.rating !== undefined && data.rating !== null ? data.rating : 0;

  const startDateFormatted = data.startDate
    ? dayjs(data.startDate).format("D MMM")
    : "-";
  const endDateFormatted = data.endDate
    ? dayjs(data.endDate).format("D MMM BB")
    : "-";

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitleStyled>
        <Typography variant="h6">{t("KeyResultDetails")}</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitleStyled>
      <DialogContent dividers>
        <Box display="flex" justifyContent="space-between" marginTop="8px">
          <Box display="flex" flexDirection="column" gap="8px">
            <Box display="flex" alignItems="center">
              <AccessTimeRounded
                fontSize="14px"
                style={{ marginRight: "8px", color: "#919eab" }}
              />
              <Typography fontSize="14px" lineHeight="1" color="text.secondary">
                {`${startDateFormatted} - ${endDateFormatted}`}
              </Typography>
            </Box>
            <ChipStatus
              status={getStatusAchieveApprove(data.isAchieve)}
              size="large"
            />
          </Box>
          {score !== 0 && (
            <Box style={{ height: "60px" }}>
              <RatingChip rating={score} />
            </Box>
          )}
        </Box>
        <Box marginTop="16px">
          <Typography fontSize="14px">
            <span style={{ color: "#919eab", paddingRight: "8px" }}>
              {`${t("Strategy")}:`}
            </span>
            {data.tacticName}
          </Typography>
          <Typography fontSize="14px" paddingTop="16px">
            <span style={{ color: "#919eab", paddingRight: "8px" }}>
              {`${t("MeasurementData")}:`}
            </span>
            {data.measureEvidence}
          </Typography>
          <Typography fontSize="14px" paddingTop="16px">
            <span style={{ color: "#919eab", paddingRight: "8px" }}>
              {`${t("ObjectiveMeasurementData")}:`}
            </span>
            {data.commitQuality}
          </Typography>
          <Typography fontSize="14px" paddingTop="16px">
            <span style={{ color: "#919eab", paddingRight: "8px" }}>
              {`${t("TypeMeasurementData")}:`}
            </span>
            {data.measureDataType === 1 ? t("Number") : t("Explanation")}
          </Typography>
          {data.description && (
            <Typography fontSize="14px" paddingTop="16px" whiteSpace="pre-line">
              <span style={{ color: "#919eab", paddingRight: "8px" }}>
                {`${t("Descriptions")}:`}
              </span>
              {data.description}
            </Typography>
          )}
          {data.feedback && (
            <Typography fontSize="14px" paddingTop="16px" whiteSpace="pre-line">
              <span style={{ color: "#919eab", paddingRight: "8px" }}>
                {`${t("Feedback")}:`}
              </span>
              {data.feedback}
            </Typography>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default KRDetailModal;
