import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { AccessTimeRounded } from "@mui/icons-material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import ChipStatus from "../../../../shared/pages/okrs/components/ChipStatus";
import RatingChip from "../../../../user/okrs/Planning/Organization/Information/ratingChip";
import KRUpdateHistoryModal from "./KRUpdatingHistory";
import { getTimelineUpdatingKeyresultById } from "../../../../../../actions/okrs";
import { useDispatch } from "react-redux";
import MoonshotIcon from "../../../../assets/moonshot_logo.svg";

const DialogTitleStyled = styled(DialogTitle)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const KRDetailModal = ({
  open,
  handleClose,
  data,
  getStatusAchieveApprove,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [openTimelineModal, setOpenTimelineModal] = useState(false);
  const [timeline, setTimeline] = useState();

  const score =
    data.rating !== undefined && data.rating !== null ? data.rating : 0;

  const startDateFormatted = data.startDate
    ? dayjs(data.startDate).format("D MMM")
    : "-";
  const endDateFormatted = data.endDate
    ? dayjs(data.endDate).format("D MMM YY")
    : "-";

  const handleOpenTimeline = async (event) => {
    event.stopPropagation();

    let keyResultType = "";
    let id = null;

    if (data.idIndividualKeyResult) {
      keyResultType = "individual";
      id = data.idIndividualKeyResult;
    } else if (data.idTeamKeyResult) {
      keyResultType = "team";
      id = data.idTeamKeyResult;
    } else if (data.idOrganizationKeyResult) {
      keyResultType = "organization";
      id = data.idOrganizationKeyResult;
    }

    const response = await dispatch(
      getTimelineUpdatingKeyresultById(id, keyResultType)
    );

    if (response && response.status === 200) {
      const updates = response.data;
      setTimeline(updates);
    }
    setOpenTimelineModal(true);
  };

  const handleCloseTimeline = () => {
    setOpenTimelineModal(false);
  };

  console.log(data);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        onClick={(event) => event.stopPropagation()}
      >
        <DialogTitleStyled>
          <Typography variant="h6">{t("KeyResultDetails")}</Typography>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              handleClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent dividers>
          {/* Top Section: Date on the left, ChipStatus and Moonshot Icons on the right */}
          <Box display="flex" alignItems="center" marginTop="8px">
            {/* Date Section */}
            <Box display="flex" alignItems="center" flexGrow={1}>
              <AccessTimeRounded
                fontSize="small"
                style={{ marginRight: "8px", color: "#919eab" }}
              />
              <Typography fontSize="14px" lineHeight="1" color="text.secondary">
                {`${startDateFormatted} - ${endDateFormatted}`}
              </Typography>
            </Box>

            {/* ChipStatus and Moonshot Icons Section */}
            <Box display="flex" alignItems="center">
              <ChipStatus
                status={getStatusAchieveApprove}
                size="large"
                sx={{ marginRight: "8px" }}
              />
              {data.isMoonShotPlanning === 1 && (
                <Box
                  component="img"
                  src={MoonshotIcon}
                  alt="Moonshot Planning"
                  sx={{ width: 24, height: 24, marginLeft: "8px" }}
                />
              )}
              {data.isMoonShotEvaluation === 1 && (
                <Box
                  component="img"
                  src={MoonshotIcon}
                  alt="Moonshot Evaluation"
                  sx={{ width: 24, height: 24, marginLeft: "8px" }}
                />
              )}
            </Box>
          </Box>

          {/* Divider */}
          <Box marginTop="16px" marginBottom="16px">
            <Divider />
          </Box>

          {/* Details Section */}
          <Box>
            <Box marginBottom="16px">
              <Typography fontSize="14px" color="#919eab">
                {t("Strategy")}:
              </Typography>
              <Typography fontSize="14px">{data.tacticName || "-"}</Typography>
            </Box>

            <Box marginBottom="16px">
              <Typography fontSize="14px" color="#919eab">
                {t("MeasurementData")}:
              </Typography>
              <Typography fontSize="14px">
                {data.measureEvidence || "-"}
              </Typography>
            </Box>

            <Box marginBottom="16px">
              <Typography fontSize="14px" color="#919eab">
                {t("ObjectiveMeasurementData")}:
              </Typography>
              <Typography fontSize="14px">
                {data.commitQuality || "-"}
              </Typography>
            </Box>

            <Box marginBottom="16px">
              <Typography fontSize="14px" color="#919eab">
                {t("TypeMeasurementData")}:
              </Typography>
              <Typography fontSize="14px">
                {data.measureDataType === 1 ? t("Number") : t("Explanation")}
              </Typography>
            </Box>

            {data.description && (
              <Box marginBottom="16px">
                <Typography fontSize="14px" color="#919eab">
                  {t("Descriptions")}:
                </Typography>
                <Typography fontSize="14px" whiteSpace="pre-line">
                  {data.description}
                </Typography>
              </Box>
            )}

            {data.feedback && (
              <Box marginBottom="16px">
                <Typography fontSize="14px" color="#919eab">
                  {t("Feedback")}:
                </Typography>
                <Typography fontSize="14px" whiteSpace="pre-line">
                  {data.feedback}
                </Typography>
              </Box>
            )}

            {data.crossTeam && data.crossTeam.length > 0 && (
              <Box marginBottom="16px">
                <Typography fontSize="14px" color="#919eab">
                  {t("Collaboration")}:
                </Typography>
                <Typography fontSize="14px" whiteSpace="pre-line">
                  {data.crossTeam.map((team) => team.departmentName).join(", ")}
                </Typography>
              </Box>
            )}

            {data.isCascade === 1 &&
              data.assignedEmployees &&
              data.assignedEmployees.length > 0 && (
                <Box marginBottom="16px">
                  <Typography fontSize="14px" color="#919eab">
                    {t("Cascade")}:
                  </Typography>
                  {data.assignedEmployees.map((emp) => (
                    <Typography fontSize="14px" key={emp.idEmployees}>
                      {emp.firstname_TH} {emp.lastname_TH}
                    </Typography>
                  ))}
                </Box>
              )}
          </Box>

          {/* Button */}
          <Box marginTop="24px" textAlign="center">
            <Button
              variant="outlined"
              onClick={handleOpenTimeline}
              sx={{
                borderColor: "rgba(219, 65, 120, 1)",
                color: "rgba(219, 65, 120, 1)",
                fontWeight: "bold",
                "&:hover": {
                  borderColor: "#C53B6C",
                  color: "#C53B6C",
                },
              }}
            >
              {t("ViewUpdateTimeline")}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      {openTimelineModal && (
        <KRUpdateHistoryModal
          open={openTimelineModal}
          onClose={handleCloseTimeline}
          updates={timeline}
        />
      )}
    </>
  );
};

export default KRDetailModal;
