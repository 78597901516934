import React, { useState, useEffect } from "react";
import NodeInfo from "./NodeInfo";
import ConfirmDialog from "../../../shared/general/ConfirmDialog";
import Notification from "../../../shared/general/Notification";
import EditMember from "./EditMember";

import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiAvatar-root": {
      width: "4rem",
      height: "4rem",
      marginBottom: 16,
    },
    "& .MuiTypography-body1": {
      fontWeight: 600,
      color: "#222f3e",
      fontSize: "0.9rem",
    },
    "& .MuiTypography-body2": {
      color: "#58647A",
    },
  },
  svgIcon: {
    marginRight: 8,
  },
  deleteMenu: {
    color: "#d32f2f",
  },
  divider: {
    width: "100%",
    marginTop: 16,
    marginBottom: 16,
  },
  childrenTotal: {
    color: "#616161",
    border: "1px solid #9e9e9e",
    borderRadius: "5px",
    height: "20px",
    width: "20px",
    fontSize: "0.8rem",
    fontWeight: "600",
    marginTop: 8,
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  role: {
    fontSize: "0.8rem",
    color: "#2F74EB",
    backgroundColor: "#E8EFFF",
    borderRadius: "5px",
    paddingLeft: 8,
    paddingRight: 8,
    "&:hover": {
      backgroundColor: "#E8EFFF",
    },
  },
}));

export default function ChartNode({ nodeData }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [nodeModal, setNodeModal] = useState({
    isOpen: false,
    nodeData: [],
  });

  const [editModal, setEditModal] = useState({
    isOpen: false,
    nodeData: [],
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnView = (nodeData) => {
    setNodeModal({
      isOpen: true,
      nodeData: nodeData,
    });
  };

  const handleOnEdit = (nodeData) => {
    setEditModal({
      isOpen: true,
      nodeData: nodeData,
    });
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    setNotify({
      isOpen: true,
      message: "Deleted Successfully, Please Refresh Data",
      type: "success",
    });
  };

  return (
    <div className={classes.root}>
      <div className="oc-heading">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>{nodeData.position}</Grid>
          <Grid item>
            <IconButton
              size="small"
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => handleOnView(nodeData)}>
                <SearchIcon className={classes.svgIcon} /> View
              </MenuItem>
              <MenuItem onClick={() => handleOnEdit(nodeData)}>
                <EditIcon className={classes.svgIcon} /> Edit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure you want to delete ?",
                    subTitle: "You can't undo this operation",
                    onConfirm: () => {
                      onDelete(nodeData.projectId);
                    },
                  });
                }}
              >
                <Typography variant="body1" className={classes.deleteMenu}>
                  <DeleteIcon className={classes.svgIcon} /> Delete
                </Typography>
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </div>
      <div className="project-assign">
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Avatar
              alt={nodeData.name}
              src={"/uploads/empImages/" + nodeData.img}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item>
            <Typography variant="body1">{nodeData.name}</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item>
            <IconButton size="small" className={classes.role}>
              {nodeData.role}
            </IconButton>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="body2">Department</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{nodeData.department}</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="body2">Section</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{nodeData.section}</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <IconButton size="small" className={classes.childrenTotal}>
              {nodeData.children.length}
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <NodeInfo nodeModal={nodeModal} setNodeModal={setNodeModal} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <EditMember
        editModal={editModal}
        setEditModal={setEditModal}
        chartNode={nodeData}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
