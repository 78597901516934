import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  MenuItem,
  Grid,
  styled,
  Typography,
  FormControl,
  Select,
} from "@mui/material";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../../shared/general/Drawer";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import DatePickerCustom from "../../../../shared/date/datePicker";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import { addAdditionPersonalEmployees, getAdditionPersonal, getEmployeeAdditionPersonal, updateAdditionPersonalEmployees } from "../../../../../../actions/additionSpecial";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";

const StyledRoot = styled("div")({
  width: 500,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const DialogEdit = (props) => {
  const { open, mode, handleCloseDialog, dataEdit, isReadOnly } = props;
  const dispatch = useDispatch();


  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: additionPersonal } = useSelector(
    (state) => state.additionPersonal
  );
  const { result: employeeAdditionPersonal } = useSelector(
    (state) => state.employeeAdditionPersonal
  );
  const [formData, setFormData] = useState({ idAdditionPersonal: "", amount: "" });

  const dataAlready = employeeAdditionPersonal.map(item => item.idAdditionPersonal)

  useEffect(() => {
    dispatch(getAdditionPersonal(employeeProfile.idCompany));
  }, []);

  useEffect(() => {
    if (dataEdit) {
      setFormData({
        amount: dataEdit.amount,
        idAdditionPersonal: dataEdit.idAdditionPersonal,
      });
    }
  }, [dataEdit]);

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const TitleDialog = () => {
    let title = mode === "add" ? t("AddItem") : t("EditItem");
    if (i18n.resolvedLanguage !== "th") {
      title += " "
    }
    title += t("AdditionPersonal")
    return title
  };  

  const handleSaveChange = async () => {
    formData.idEmployees = employeeProfile.idEmployees;
    const res = await dispatch(addAdditionPersonalEmployees(formData));
    if (res && res.status === 201) {
      dispatch(getEmployeeAdditionPersonal({ idEmployees: employeeProfile.idEmployees }));
    }
    handleCloseDialog();
  };

  return (
    <DrawerCustom
      title={TitleDialog()}
      anchor="right"
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <StyledContentLabel>{`${t("ResignationName")}`}</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            {dataEdit ? (
              <TextFieldTheme
                value={dataEdit.name}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            ) : (
              <StyledFormControl fullWidth>
                <Select
                  name="idAdditionPersonal"
                  value={formData.idAdditionPersonal}
                  onChange={(event) => handleChange(event.target.name, event.target.value) }
                >
                  {additionPersonal &&
                    additionPersonal
                      .filter(e => !dataAlready.includes(e.idAdditionPersonal))
                      .map((item) => (
                        <MenuItem key={item.idAdditionPersonal} value={item.idAdditionPersonal}>{item.name}</MenuItem>
                    ))}
                </Select>
              </StyledFormControl>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <StyledContentLabel>{`${t("Quantity")}`}</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            {formData && (
              <TextFieldTheme
                fullWidth
                name="amount"
                value={formData.amount}
                InputProps={{ inputComponent: NumberFormatTheme }}
                inputProps={{
                  onValueChange: (values) => {
                    const { floatValue } = values;
                    handleChange("amount", floatValue);
                  },
                }}
              />
            )}
          </Grid>
        </Grid>
        <StyledFooter>
          <ButtonBlue
            variant="text"
            className="cancel"
            onClick={handleCloseDialog}
          >
            {`${t("Cancel")}`}
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSaveChange} disabled={isReadOnly}>
            {`${t("SaveData")}`}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
