import { httpClient } from "./httpClient";

const uploadExcelEmployeeHoliday = (formData) => {
  return httpClient.post(`/upload-excel/employee-holiday`, formData);
};

const uploadExcelAdditionDeductionRecurring = (formData) => {
  return httpClient.post(`/upload-excel/addition-deduction-recurring`, formData);
};

export default {
  uploadExcelEmployeeHoliday,
  uploadExcelAdditionDeductionRecurring
};