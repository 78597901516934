export const Data = [{
    empId: 'gold',
    image: null, 
    position: 'HR TPE',
    fullName: 'Kon Wongsurawattana',
    department: 'HR',
    objectiveTotal: 'XXXXXXXXX',
    krTotal: '2',
    dateCreate : '20/02/64',
    actions: true,
    esy: 5,
    service: 4,
    tig: 6,
    tip: 5,
    div: 7,
    depth: 4,
    krScore1: 0.5,
    krScore2: 1,
    krScore3: 0.6,
    section: 'HR',
    reportTo : 'Supapit Siladee',
    email: 'ABCDEFGHIJK@hotmail.com',
    tel: '0800800800',
    planOrgId:1,
    quater: 'Q2',
    achieved: 'XXXXXXX',
    totalMoonShot: 5,
    objectiveName:'การวิจัยเพื่อพัฒนาศักยภาพของงาน',
    impactName: 'พัฒนาระบบให้ทันสมัย และครอบคลุมการใช้งานมากขึ้น',
    dateCreate: '04/06/2021',
    planStatus: 'Approved',
    achieveResult: 'In Progress',
    keyResult1: 'AAAAAAAAAAA',
    kR1Status:'Waiting Approved',
    achieveResultKR1: 'In Progress',
    approvedBy: '',
    keyResult2: 'BBBBBBBBB',
    kR2Status:'Waiting Approved',
    achieveResultKR2: 'In Progress',
    keyResult3: 'AAAAAAAAAAA',
    kR3Status:'Waiting Approved',
    achieveResultKR3: 'In Progress',
    dateUpdate: '07/11/2020',
    createBy: 'Nitta Aekopas',
    dialogueRecordId: 'D001',
  }]
  
  export const Impact =[{
    impactID : 1,
    impactJobs: [{
      impactName: 'AAAAAAA',
      description: 'Important for building business',
      selectValue: 'Direct',
      returnType: 'Selected Type',
      impactCost: 20000,
      imagePath1: null,
      imagePath2: null,
      pdfPath1: null,
      pdfPath2: null,
    }],
    impactOthers: [{
      impactOtherName: 'BBBBBBBB',
      impactTo: 'Company',
      descriptionOther: 'Important for building business',
      imagePath1: '1630665598058--Image_created_with_a_mobile_phone.png',
      imagePath2: 'Test_SuccessProfiles_1.png',
      pdfPath1: 'sample1.pdf',
      pdfPath2: null,
    }]
  }]
  
  export const Manager = {
    managerImage: null,
    manager: 'Nitta Aekopas',
    managerPosition: 'HR TPE',
    empCard: 'gold',
    empImage: null,
    fullName: 'Nantiporn Pikulthep',
    empPosition: 'HR TPE',
  }
  
  export const DataValue ={
    good: 'ได้รับประสบการณ์จากการทำงานจริงอย่างมาก',
    gap: 'ผ่านพื้้นฐานที่องค์กรต้องการ',
    learningPoint: 'เรียนภาษาคอมพิวเตอร์เพิ่มเติม',
    nextCommit: 'XXXXXX',
    note: 'moree study',
    whatNextHW: 'เขียนโค้ดการหาดึงข้อมูลจาก backend',
  }