import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Box, Grid, MenuItem, Typography, styled, createFilterOptions, FormControlLabel, Checkbox } from "@mui/material";
import DrawerCustom from "../../../../shared/general/Drawer";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import dayjs from "dayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePickerCustom from "../../../../shared/date/datePicker";
import { addEmployeeCostCenter, updateEmployeeCostCenter } from "../../../../../../actions/cost-center";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";
import { getEmployeeProfile } from "../../../../../../actions/employee";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .field-label": {
    fontSize: "14px",
    fontWeight: "500",
    paddingBottom: "4px",
  },
  "& .MuiCheckbox-root": {
    "&.Mui-checked": {
      color: "#46cbe2",
    },
  },
});

const DrawerCostCenter = (props) => {

  const { drawerConfig, onClose, fecthEmployeeCostCenter } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: costCenterList } = useSelector((state) => state.costCenterList);

  const validateYupSchema = yup.object({
    costCenterCharge: yup.string().when("isCustom", {
      is: true,
      then: (schema) => schema
      .required(t("ThisFieldIsRequired"))
    }),
    selectedCostCenter: yup.mixed().nullable().when("isCustom", {
      is: false,
      then: (schema) => schema
      .required(t("ThisFieldIsRequired"))
    }),
    start: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
    end: yup.mixed().nullable().when("isCurrent", {
      is: false,
      then: (schema) => schema
      .required(t("ThisFieldIsRequired"))
      .test("greaterOrEqualStartDate", t("CanNotSelectDateBeforeStart"), (value, context) => {
        return dayjs(value).isSameOrAfter(dayjs(context.parent.start))
      })
    }),
  });

  const useHookForm = useForm({
    defaultValues: {
      costCenterCharge: "",
      selectedCostCenter: null,
      isCustom: false,
      start: null,
      end: null,
      isCurrent: false,
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  const filterCostCenterOptions = createFilterOptions({
    stringify: (value) => {

      let structureType = String(value.key).split("_")[0];
      let structureName = "";

      switch (structureType) {
        case "BUSINESSUNIT": structureName = `${t("BusinessUnit")}: (${value.businessUnitCode}) ${value.businessUnitName}`; break;
        case "DIVISION": structureName = `${t("Division")}: (${value.divisionCode}) ${value.divisionName}`; break;
        case "DEPARTMENT": structureName = `${t("Department")}: (${value.departmentCode}) ${value.departmentName}`; break;
        case "SECTION": structureName = `${t("Section")}: (${value.sectionCode}) ${value.sectionName}`; break;
      }

      return String(`${value.costCenter}${structureName}${value.companyName}`).toLowerCase()
    }
  });

  const renderMenuCostCenter = (option) => {

    let structureType = String(option.key).split("_")[0];
    let structureName = "";

    switch (structureType) {
      case "BUSINESSUNIT": structureName = `${t("BusinessUnit")}: (${option.businessUnitCode}) ${option.businessUnitName}`; break;
      case "DIVISION": structureName = `${t("Division")}: (${option.divisionCode}) ${option.divisionName}`; break;
      case "DEPARTMENT": structureName = `${t("Department")}: (${option.departmentCode}) ${option.departmentName}`; break;
      case "SECTION": structureName = `${t("Section")}: (${option.sectionCode}) ${option.sectionName}`; break;
    }

    return (
      <Fragment>
        <Typography>
          {option.costCenter}
        </Typography>
        <Typography color="text.secondary" fontSize="14px">
          {structureName}
        </Typography>
        <Typography color="text.secondary" fontSize="14px">
          {option.companyName}
        </Typography>
      </Fragment>
    )
  }

  const onSubmit = async (data) => {

    let result = null;

    const formData = {
      start: dayjs(data.start).format("YYYY-MM-DD"),
      end: data.isCurrent? null: dayjs(data.end).format("YYYY-MM-DD"),
      idSection: data.isCustom? null: (data.selectedCostCenter.idSection || null),
      idDepartment: data.isCustom? null: (data.selectedCostCenter.idDepartment || null),
      idDivision: data.isCustom? null: (data.selectedCostCenter.idDivision || null),
      idBusinessUnit: data.isCustom? null: (data.selectedCostCenter.idBusinessUnit || null),
      costCenterCharge: data.isCustom? data.costCenterCharge: null,
    }

    if(!drawerConfig.isEdit){
      formData.idEmployees = employeeProfile.idEmployees
      result = await dispatch(addEmployeeCostCenter(formData));
    } else {
      result = await dispatch(updateEmployeeCostCenter(drawerConfig.data.idEmployeeCostCenter, formData));
    }


    if(result && result.status === 200) {
      onClose();
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
      fecthEmployeeCostCenter();
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }));
    }

  }

  useEffect(() => {
    if(drawerConfig.isOpen){
      if(!drawerConfig.isEdit){
        useHookForm.reset({
          costCenterCharge: "",
          selectedCostCenter: null,
          isCustom: false,
          start: null,
          end: null,
          isCurrent: false,
        })
      } else {

        const selectedCostCenter = costCenterList.find(item => (
          item.idBusinessUnit === drawerConfig.data.idBusinessUnit
          || item.idDivision === drawerConfig.data.idDivision
          || item.idDepartment === drawerConfig.data.idDepartment
          || item.idSection === drawerConfig.data.idSection
        ))

        const isCustom = (
          drawerConfig.data.idBusinessUnit
          || drawerConfig.data.idDivision
          || drawerConfig.data.idDepartment
          || drawerConfig.data.idSection
        )

        useHookForm.reset({
          costCenterCharge: selectedCostCenter? "": drawerConfig.data.costCenterCharge? drawerConfig.data.costCenterCharge: "",
          selectedCostCenter: selectedCostCenter? selectedCostCenter: null,
          isCustom: isCustom? false: true,
          start: drawerConfig.data.start? dayjs(drawerConfig.data.start): null,
          end: drawerConfig.data.end? dayjs(drawerConfig.data.end): null,
          isCurrent: drawerConfig.data.end? false: true,
        })
      }
    }
  }, [drawerConfig.isOpen])

  const isCustomWatch = useHookForm.watch("isCustom");
  const isCurrentWatch = useHookForm.watch("isCurrent");

  return (
    <DrawerCustom
      title={`${t("EditData")} Cost Center`}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="8px">
                <Typography color="text.secondary" fontSize="14px" fontWeight="500">
                  Cost Center
                </Typography>
                <Controller
                  name={"isCustom"}
                  control={useHookForm.control}
                  render={({field}) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                            useHookForm.setValue("selectedCostCenter", null);
                            useHookForm.setValue("costCenterCharge", "");
                          }}
                        />
                      }
                      label={t("Custom")}
                      labelPlacement="start"
                    />
                  )}
                />
              </Box>
              {!isCustomWatch && <Controller
                name="selectedCostCenter"
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    options={costCenterList ? costCenterList : []}
                    getOptionLabel={(option) => `${option.costCenter}`}
                    isOptionEqualToValue={(option, value) => option.key === value.key}
                    filterOptions={filterCostCenterOptions}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        placeholder={`Select Cost Center`}
                        onBlur={field.onBlur}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        error={fieldState.error ? true : false}
                      />
                    )}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.key}>
                        <Box>
                          {renderMenuCostCenter(option)}
                        </Box>
                      </MenuItem>
                    )}
                    value={field.value}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    noOptionsText={`${t("NoData")}`}
                    disableClearable
                  />
                )}
              />}
              {isCustomWatch && <Controller
                name="costCenterCharge"
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    onBlur={field.onBlur}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    error={fieldState.error ? true : false}
                  />
                )}
              />}
            </Grid>
            <Grid item xs={12}>
              <Typography color="text.secondary" fontSize="14px" fontWeight="500" marginBottom="8px">{t("StartDate")}</Typography>
              <Controller
                name="start"
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <DatePickerCustom
                    {...field}
                    views={["year", "month", "day"]}
                    inputFormat="DD MMMM YYYY"
                    openTo="year"
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: t("SelectDate"),
                          readOnly: true
                        }}
                        onBlur={field.onBlur}
                        error={fieldState.error? true : false}
                        helperText={fieldState.error? fieldState.error.message : null}
                      />
                    )}
                    PaperProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "#46cbe2!important",
                        },
                      },
                    }}
                    DialogProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "#46cbe2!important",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box paddingBottom="4px" display="flex" alignItems="center" justifyContent="space-between">
                <Typography fontWeight="600" fontSize="14px" color="text.secondary">{`${t("EndDate")}`}</Typography>
                <Controller
                  name={"isCurrent"}
                  control={useHookForm.control}
                  render={({field}) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                            useHookForm.setValue("end", null);
                          }}
                        />
                      }
                      label={t("Current")}
                      labelPlacement="start"
                    />
                  )}
                />
              </Box>
              {!isCurrentWatch && <Controller
                name="end"
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <DatePickerCustom
                    {...field}
                    views={["year", "month", "day"]}
                    inputFormat="DD MMMM YYYY"
                    openTo="year"
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: t("SelectDate"),
                          readOnly: true
                        }}
                        onBlur={field.onBlur}
                        error={fieldState.error? true : false}
                        helperText={fieldState.error? fieldState.error.message : null}
                      />
                    )}
                    PaperProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "#46cbe2!important",
                        },
                      },
                    }}
                    DialogProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "#46cbe2!important",
                        },
                      },
                    }}
                  />
                )}
              />}
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose}>
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue type="submit" variant="contained">
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
}

export default DrawerCostCenter;