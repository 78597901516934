import React, { Fragment } from "react";
import {
  Typography,
  Grid,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  CircularProgress
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center"
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
  "& .btn-save": {
    marginTop: 16,
    display: "flex",
    justifyContent: "flex-end",
  },
});

const StyledTextFieldTheme = styled(TextFieldTheme)({
  marginBottom: 0,
});

const DialogEstimatePerformance = (props) => {
  const { open, handleClose, idEdu, type, value, detail } = props;
 
  return (
    <DrawerCustom
      title="แบบประเมินการทดลองงาน"
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Box style={{ padding: "24px" }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={7}>
                <Typography sx={{ display: 'inline-block' }} >
                  <i className="fa-regular fa-user fa-xl"></i>
                  &nbsp;&nbsp;
                </Typography>
                <Typography sx={{ display: 'inline-block' }} >{` ผู้ถูกประเมิน : ${value.firstname_TH} ${value.lastname_TH}`} </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography sx={{ display: 'inline-block' }} >
                  <i className="fa-regular fa-building-columns fa-xl"></i>
                  &nbsp;&nbsp;
                </Typography>
                <Typography sx={{ display: 'inline-block' }} >{`ตำแหน่ง : ${value.positionName}`} </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography sx={{ display: 'inline-block' }} >
                  <i className="fa-light fa-whistle fa-xl"></i>
                  &nbsp;&nbsp;
                </Typography>
                <Typography sx={{ display: 'inline-block' }} >{`ประเมินโดย : ${detail.firstname_TH} ${detail.lastname_TH}`} </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box style={{ paddingLeft: "24px" }}>
            {detail.GroupEstimatePerformance.map((data, index) => (
              <Grid key={data.id} container className="GridContact">
                <Grid item xs={12} sm={12} marginTop={2} className="GridTopicInput">
                  <Grid item xs={12} sm={12} >
                    <i className="fa-regular fa-square-question fa-bounce fa-xl"></i>
                    &nbsp;&nbsp;{`คำถามที่ ${index + 1} : ${data.question}`}
                  </Grid>
                </Grid>
                <Grid item xs={12} marginTop={1} sm={12}>
                  <Fragment>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      value={data.answer}
                    >
                      <FormControlLabel value="verygood" control={<Radio />} label="ดีมาก" />
                      <FormControlLabel value="good" control={<Radio />} label="ดี" />
                      <FormControlLabel value="meduim" control={<Radio />} label="ปานกลาง" />
                      <FormControlLabel value="fair" control={<Radio />} label="พอใช้" />
                      <FormControlLabel value="prettybad" control={<Radio />} label="ค่อนข่างแย่" />
                      <FormControlLabel value="bad" control={<Radio />} label="แย่" />
                    </RadioGroup>
                  </Fragment>
                </Grid>
                <Grid item xs={12}>
                  <Divider
                    sx={{
                      marginTop: "16px",
                      borderColor: "#9e9e9e66"
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </Box>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Box style={{ paddingLeft: "24px" }}>
            <Grid container className="GridContact">
              <Grid item xs={12} sm={12} marginTop={2} className="GridTopicInput">
                <Grid item xs={12} sm={12} >
                  <i className="fa-regular fa-bone fa-xl"></i>
                  &nbsp;&nbsp;{`จุดแข็ง`}
                </Grid>
              </Grid>
              <Grid item xs={12} marginTop={1} sm={12}>
                <TextFieldTheme
                  multiline
                  rows={3}
                  value={detail.goodPoint}
                >
                </TextFieldTheme>
              </Grid>
              <Grid item xs={12} sm={12} marginTop={2} className="GridTopicInput">
                <Grid item xs={12} sm={12} >
                  <i className="fa-regular fa-bone-break fa-xl"></i>
                  &nbsp;&nbsp;{`จุดอ่อน`}
                </Grid>
              </Grid>
              <Grid item xs={12} marginTop={1} sm={12}>
                <TextFieldTheme
                  multiline
                  rows={3}
                  value={detail.weakPoint}
                >
                </TextFieldTheme>
              </Grid>
              <Grid item xs={12} sm={12} marginTop={2} className="GridTopicInput">
                <Grid item xs={12} sm={12} >
                  {`อื่นๆ`}
                </Grid>
              </Grid>
              <Grid item xs={12} marginTop={1} sm={12}>
                <TextFieldTheme
                  multiline
                  rows={3}
                  value={detail.others}
                >
                </TextFieldTheme>
              </Grid>
              <Grid item xs={12} marginTop={3}>
                <Box display={"flex"} justifyContent={"center"}>
                  <Box style={{ position: "relative", display: "inline-flex" }}>
                    <CircularProgress
                      variant="determinate"
                      style={{
                        color: "#eeeeee",
                      }}
                      size={70}
                      thickness={4}
                      value={100}
                    />
                    <CircularProgress
                      variant="determinate"
                      value={detail.score}
                      style={{
                        color: "#007afe",
                        animationDuration: "550ms",
                        position: "absolute",
                        left: 0,
                      }}
                      size={70}
                    />
                    <Box
                      style={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        style={{ fontSize: 16 }}
                        component="div"
                        color="text.third"
                      >
                        {detail.score}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} marginTop={3}>
                <Box 
                  sx={{ 
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: 8, 
                    padding: 3, 
                    backgroundColor: "#4158D0",
                    backgroundImage: "linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%)",
                  }}
                >
                  <Typography color={"#ffffff"} fontWeight={500} fontSize={24}>
                    {`ระดับการประเมิน : ${detail.level}`}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" startIcon={<ArrowBackIcon />} onClick={handleClose}>ย้อนกลับ</ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEstimatePerformance;
