import React, { useState } from "react";
import { Grid, Menu, MenuItem, Typography, styled } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useSelector } from "react-redux";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useTranslation } from "react-i18next";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '0px',
    },
    '& .MuiMenuItem-root': {
      '&:hover': {
        "boxShadow": "inset 0 -3px 0 0 #46cbe2", 
      },
    },
  },
}));

const MenuJobGroup = ({ idJobGroup, handleChangeJobGroup }) => {
  const {t} = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickSelect = (id) => {
    handleChangeJobGroup(id);
    handleClose();
  };

  const { result: jobRecruitGroup } = useSelector((state) => state.jobRecruitGroup);

  return (
    <div>
      <ButtonBlue
        variant="contained"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        startIcon={<MenuIcon />}
      >
        {t("FieldOffice")}
      </ButtonBlue>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ maxWidth: "xl" }}
      >
        <Grid container>
          {jobRecruitGroup &&
            jobRecruitGroup.map((item) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <MenuItem 
                  key={item.idJobGroup} 
                  onClick={() => handleClickSelect(item.idJobGroup)}
                  style={
                    idJobGroup === item.idJobGroup
                      ? { color: "#ffffff", backgroundColor: "#46cbe2" }
                      : undefined
                    }
                >
                  <Typography color={"inherit"} noWrap>
                    {item.jobGroupName}
                  </Typography>
                </MenuItem>
              </Grid>
            ))}
        </Grid>
      </StyledMenu>
    </div>
  );
};

export default MenuJobGroup;
