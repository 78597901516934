import React, { useState } from "react";
import { styled, Tab, Tabs } from "@mui/material";
import CardStyle from "../../shared/general/Card";

import BankPanel from "./bankPanel";
import TabCompany from "./tap-general";
import TabAddress from "./tabAddress";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  padding: 24,
  "& .MuiTabs-root": {
    paddingBottom: 24,
  },
});

const RightPanel = (props) => {

  const { selectedCompany } = props;

  const [tabValue, setTabValue] = useState(0);
  const { t, i18n } = useTranslation();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <CardStyle>
      <StyledRoot>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label={`${t("CompanyInformation")}`}></Tab>
          <Tab label={`${t("Address")}`}></Tab>
          <Tab label={`${t("BankInfo")}`}></Tab>
        </Tabs>

        {tabValue === 0 && <TabCompany selectedCompany={selectedCompany}/>}
        {tabValue === 1 && <TabAddress selectedCompany={selectedCompany} />}
        {tabValue === 2 && <BankPanel selectedCompany={selectedCompany}/>}
      </StyledRoot>
    </CardStyle>
  );
};

export default RightPanel;
