import React from "react";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  styled,
  Typography,
} from "@mui/material";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledDialogContent = styled(DialogContent)({
  "& .IconWarning": {
    color: "#ed6c02",
    marginRight: "10px",
  },
  "& .CloseButton": {
    minWidth: "fit-content",
    padding: 0,
    "&:hover": {
      backgroundColor: "#ffffff",
    },
  },
});

const DialogWarning = (props) => {
  const { open, onClose } = props;
  const { t, i18n } = useTranslation();

  return (
    <Dialog open={open}>
      <StyledDialogContent>
        <Typography variant="h5">
          <i className="fa-solid fa-triangle-exclamation IconWarning" />
          {`${t("Notification")}`}
        </Typography>
        <Box style={{ marginTop: "10px", padding: "10px 10px 0" }}>
          <Typography variant="body1">{`${t(
            "InterviewResultsCandidatesPending"
          )}`}</Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button className="CloseButton" onClick={onClose}>{`${t(
              "Close"
            )}`}</Button>
          </Box>
        </Box>
      </StyledDialogContent>
    </Dialog>
  );
};

export default DialogWarning;
