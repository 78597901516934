import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DialogConfirm from "../../../../shared/general/DialogConfirm";
import AlertResponse from "../../../../shared/general/AlertResponse";

import { resetPassword } from "../../../../../../actions/auth";
import { Divider, Grid, Typography } from "@mui/material";

import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import { updateUser } from "../../../../../../actions/user";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import Notification from "../../../../shared/general/Notification";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

import EditIcon from "@mui/icons-material/Edit";
import DrawerEditRole from "./DrawerEditRole";

const StyledRoot = styled("div")({
  // height: 100,
});

const SettingRole = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const [drawerEditRoleConfig, setDrawerEditRoleConfig] = useState({
    isOpen: false,
    data: {},
  });

  const handleConfirm = async () => {
    const formData = { idEmployees: employeeProfile.idEmployees };
    const result = await dispatch(resetPassword(formData));
    if (result && result.status) {

      dispatch(
        openNotificationAlert({
          type: "success",
          message: "รีเซ็ตรหัสผ่านสำเร็จ",
        })
      );
    } else {

      dispatch(
        openNotificationAlert({
          type: "error",
          message: "เกิดข้อผิดพลาด...ติดต่อผู้ดูแลระบบ",
        })
      );
    }
  };

  const onDrawerEditRoleClose = () => {
    setDrawerEditRoleConfig(prev => ({
      ...prev,
      isOpen: false
    }))
  }

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12} container alignItems="center">
          <Typography flexGrow="1" fontSize="14px" fontWeight="600">{t("SystemAccessPermission")}</Typography>
          {!userProfile.readOnly && (
            <ButtonBlue
              variant="text"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => {
                setDrawerEditRoleConfig({
                  isOpen: true,
                  data: {
                    idRole: employeeProfile.idRole,
                  }
                })
              }}
            >
              {t("EditData")}
            </ButtonBlue>
          )}
        </Grid>
        <Grid item xs={12} container alignItems="center">
          {employeeProfile.idRole === 1 && <Typography flexGrow="1" fontSize="18px" fontWeight="600">{t("AppMenu.Employees")}</Typography>}
          {employeeProfile.idRole === 2 && <Typography flexGrow="1" fontSize="18px" fontWeight="600">{`${t("AppMenu.Employees")} + ${t("Manager")}`}</Typography>}
          {employeeProfile.idRole === 3 && <Typography flexGrow="1" fontSize="18px" fontWeight="600">{t("Manager")}</Typography>}
        </Grid>
      </Grid>

      <DrawerEditRole
        drawerConfig={drawerEditRoleConfig}
        onClose={onDrawerEditRoleClose} 
      />
    </StyledRoot>
  );
};

export default SettingRole;
