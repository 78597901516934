import { httpClient } from "./httpClient";

const getAffiliateCompany = (query) => {
  return httpClient.get("/v2/affiliate/company", { params: query });
};

const getAffiliateManager = () => {
  return httpClient.get("/affiliate/manager");
};

const getAffiliateManagerGroup = () => {
  return httpClient.get("/v2/affiliate/manager-group");
};

const getAffiliateShiftGroup = () => {
  return httpClient.get("/v2/affiliate/shift-group");
};

export default {
  getAffiliateCompany,
  getAffiliateManagerGroup,
  getAffiliateShiftGroup,
  getAffiliateManager,
};