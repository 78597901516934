import { httpClient } from "./httpClient";

const getCompanyPerformanceYear = (query) => {
  return httpClient.get("/performance-year/company", { params: query });
};

const addCompanyPerformanceYear = (formData) => {
  return httpClient.post("/performance-year/company", formData);
};

const updateCompanyPerformanceYear = (formData) => {
  return httpClient.patch(`/performance-year/company/${formData.idPerformanceYear}`, formData);
};

const updateEstimatePerformance = (formData) => {
  return httpClient.put("/company/estimate-performance", formData);
};

const getEstimatePerformanceQuestion = () => {
  return httpClient.get(`/company/estimate-performance-question`);
};

const updateEstimatePerformanceManager = (formData) => {
  return httpClient.put(`/company/estimate-performance-manager`, formData);
};

const getEstimatePerformanceResult = () => {
  return httpClient.get(`/company/estimate-performance-result`);
};

const getAllEstimateLevel = (query) => {
  return httpClient.get(`/estimate-level`, { params: query });
};

const getEstimateLevelActive = (query) => {
  return httpClient.get(`/estimate-level-active`, { params: query });
};

const updateEmployeePerformance = (idEmployeePerformance, formData) => {
  return httpClient.put(`/employee-performance/${idEmployeePerformance}`, formData);
};

const getAllPerformanceQuestionSet = (query) => {
  return httpClient.get(`/performance-question-set`, { params: query });
};

const addPerformanceQuestionSet = (formData) => {
  return httpClient.post(`/performance-question-set`, formData);
};

const updatePerformanceQuestionSet = (formData) => {
  return httpClient.put(`/performance-question-set/${formData.idPerformanceQuestionSet}`, formData);
};

const updatePerformanceQuestionSetStatus = (formData) => {
  return httpClient.put(`/performance-question-set/${formData.idPerformanceQuestionSet}/status`, formData);
};

const getAllEmployeePerformance = (query) => {
  return httpClient.get("/perfomance/all-employee-performance", { params: query });
};

const getPerformanceEmployee = () => {
  return httpClient.get("/perfomance/employee-performance");
};

const addPerformanceEmployee = (formData) => {
  return httpClient.post(`/perfomance/employee-performance`, formData);
};

const getPerformanceManager = () => {
  return httpClient.get("/perfomance/manager-performance");
};

const addPerformanceManager = (formData) => {
  return httpClient.post(`/perfomance/manager-performance`, formData);
};

const getEmployeePerformance = (idEmployeePerformance) => {
  return httpClient.get(`/perfomance/${idEmployeePerformance}`);
};

const addPerformanceAdmin = (formData) => {
  return httpClient.post(`/perfomance/admin-performance`, formData);
};

export default {
  getCompanyPerformanceYear,
  addCompanyPerformanceYear,
  updateCompanyPerformanceYear,
  updateEstimatePerformance,
  getEstimatePerformanceQuestion,
  updateEstimatePerformanceManager,
  getEstimatePerformanceResult,
  getAllEstimateLevel,
  getEstimateLevelActive,
  updateEmployeePerformance,

  getAllPerformanceQuestionSet,
  addPerformanceQuestionSet,
  updatePerformanceQuestionSet,
  updatePerformanceQuestionSetStatus,

  getAllEmployeePerformance,
  getPerformanceEmployee,
  addPerformanceEmployee,
  getPerformanceManager,
  addPerformanceManager,
  getEmployeePerformance,
  addPerformanceAdmin,
};
