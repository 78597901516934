import React, { useState, useEffect } from 'react';
import { createAPIEndpoint, ENDPOINTS } from '../../../../../services/api';
import dayjs from "dayjs";

import {
  Dialog,
  Typography,
  IconButton,
  Grid,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import CloseIcon from '@mui/icons-material/Close';
import TodayIcon from '@mui/icons-material/Today';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const useStyles = makeStyles(() => ({
  root: {},
  dialog: {
    padding: 16,
    position: 'absolute',
    top: 80,
  },
  dialogTitle: {
    borderBottom: '1px solid #dee2e6',
    color: '#222f3e',
    padding: '8px 16px 8px 16px',
  },
  dialogContent: {
    color: '#798294',
    fontWeight: 600,
    paddingTop: 24,
    paddingLeft: 0,
    paddingRight: 0,
  },
  textData: {
    fontSize: '1rem',
    color: '#616161',
  },
  divider: {
    marginTop: 16,
    marginBottom: 16,
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 16,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: "#9e9e9e",
  },
  headTitle: {
    fontSize: '1.2rem',
    fontWeight: 600,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6' className={classes.headTitle}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 16,
  },
}))(MuiDialogContent);

export default function NodeInfo(props) {
  const { nodeModal, setNodeModal } = props;
  const classes = useStyles();
  const [nodeData, setNodeData] = useState([]);
  const [parent, setParent] = useState('');

  useEffect(() => {
    if (nodeModal.isOpen) {
      setNodeData(nodeModal.nodeData);
      setParent('');

      createAPIEndpoint(ENDPOINTS.PROJECTASSIGN)
        .fetchAll()
        .then((res) => {
          var parentData = res.data.filter((item) =>
            item.id.includes(nodeModal.nodeData.parentId)
          );

          if (parentData.length) {
            setParent(parentData[0].name);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [nodeModal]);

  return (
    <div className={classes.root}>
      <Dialog
        open={nodeModal.isOpen}
        maxWidth='sm'
        fullWidth
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle
          className={classes.dialogTitle}
          onClose={() => setNodeModal({ ...nodeModal, isOpen: false })}
        >
          Employee Information
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container alignItems='center' spacing={4}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant='body1' gutterBottom>
                Employee Name :{' '}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography
                variant='body1'
                className={classes.textData}
                gutterBottom
              >
                {nodeData.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems='center' spacing={4}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant='body1' gutterBottom>
                Parent :{' '}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography
                variant='body1'
                className={classes.textData}
                gutterBottom
              >
                {parent ? parent : '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems='center' spacing={4}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant='body1' gutterBottom>
                Role Name :{' '}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography
                variant='body1'
                className={classes.textData}
                gutterBottom
              >
                {nodeData.role}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems='center' spacing={4}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant='body1' gutterBottom>
                Report To :{' '}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography
                variant='body1'
                className={classes.textData}
                gutterBottom
              >
                {nodeData.reportTo ? nodeData.reportTo : '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems='center' spacing={4}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant='body1' gutterBottom></Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography
                variant='body1'
                className={classes.textData}
                gutterBottom
              >
                {nodeData.selectOption}
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container alignItems='center' spacing={4}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant='body1' gutterBottom>
                Start Date :{' '}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography
                variant='body1'
                className={classes.textData}
                gutterBottom
              >
                {dayjs(nodeData.startDate).format('DD/MM/YYYY')}
                <TodayIcon style={{ marginLeft: '5px' }} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems='center' spacing={4}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant='body1' gutterBottom>
                End Date :{' '}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography
                variant='body1'
                className={classes.textData}
                gutterBottom
              >
              {dayjs(nodeData.endDate).format('DD/MM/YYYY')}
                <TodayIcon style={{ marginLeft: '5px' }} />
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
