import { httpClient } from "./httpClient";


const allPayTypes = (query) => {
  return httpClient.get("paytypes", { params: query });
};

const allAdditions = () => {
  return httpClient.get("paytypes/additions");
};

const getPayrollSetting = (query) => {
  return httpClient.get("/payroll-setting", { params: query });
};

const updatePayrollSetting = (idPayrollSetting, formData) => {
  return httpClient.put(`payroll-setting/${idPayrollSetting}`, formData);
};

const updatePayrollPayment = (idPayrollPayment, formData) => {
  return httpClient.put(`payroll-payment/${idPayrollPayment}`, formData);
};

const addPaytypes = (formData) => {
  return httpClient.post(`paytypes`, formData);
};

const updatePaytypes = (formData) => {
  return httpClient.put(`paytypes`, formData);
};

const updatePayrollDateCustom = (formData) => {
  return httpClient.put(`payroll-setting/date-custom`, formData);
};


export default {
  allPayTypes,
  allAdditions,
  getPayrollSetting,
  updatePayrollSetting,
  addPaytypes,
  updatePaytypes,
  updatePayrollPayment,
  updatePayrollDateCustom
};
