import React, { useEffect, useState } from "react";
import { Box, Container, IconButton, Table, TableBody, Chip, TableCell, TableContainer, TableHead, TableRow, Typography, styled, Stack, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { getKpiUpdate } from "../../../../../actions/kpi";
import dayjs from 'dayjs';
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { getUserPosition } from "../../../../../utils/userData";
import { t } from "i18next";

const StyledRoot = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  padding: '24px 0',
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));

const GridStyled = styled(Grid)({
  backgroundColor: "#E6EFF5",
  padding: "16px",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "16px",
  height: "70px",
});

const StatusChip = styled(Chip)(({ theme, status }) => {
  let backgroundColor;
  switch(status) {
    case 'unfinished':
      backgroundColor = '#FFDE4D';
      break;
    case 'waiting':
      backgroundColor = '#83B4FF';
      break;
    case 'not approve':
      backgroundColor = '#FF6969';
      break;
    case 'approve':
      backgroundColor = '#50B498';
      break;
  }
  return {
    backgroundColor,
    color: '#fff',
    borderRadius: '16px',
    minWidth: '110px',
    fontSize: '16px',
  };
});

const KpisIndividualPlanUpdate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { planList, status } = location.state || {};
  const { result: kpiPlanStore } = useSelector((state) => state.kpiUpdate);

  const columns = [
    {
      name: t("KPIName"),
      minWidth: "200px",
      width: "200px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography>{row.kpiName}</Typography>
            <Typography color="text.third" fontSize="14px">{getUserPosition(row)}</Typography>
          </Box>
        </Box>
      )
    },
    // {
    //   name: "Scope",
    //   minWidth: "200px",
    //   width: "200px",
    //   cellRender: (row) => (
    //     <Box display="flex" alignItems="center">
    //       <Box flexGrow={1}>
    //         <Typography>{row.scope}</Typography>
    //         <Typography color="text.third" fontSize="14px">{row.positionName}</Typography>
    //       </Box>
    //     </Box>
    //   )
    // },
    // {
    //   name: "Target",
    //   minWidth: "200px",
    //   width: "200px",
    //   cellRender: (row) => (
    //     <Box display="flex" alignItems="center">
    //       <Box flexGrow={1}>
    //         <Typography>{row.target}</Typography>
    //         <Typography color="text.third" fontSize="14px">{row.positionName}</Typography>
    //       </Box>
    //     </Box>
    //   )
    // },
    {
      name: t("LatestUpdate"),
      minWidth: "100px",
      width: "120px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            {row.updateAt ? dayjs(row.updateAt).format('YYYY/MM/DD') : t("InvalidDate")}
            <Typography color="text.third" fontSize="14px">{row.positionName}</Typography>
          </Box>
        </Box>
      )
    },
    {
      name: t("Status"),
      minWidth: "100px",
      width: "100px",
      headerTextAlign: "center",
      cellTextAlign: "center",
      cellRender: (row) => {
        let status;
        switch(row.isPlan) {
          case 1:
            status = t("Approve");
            break;
          case 0:
            status = t("NotApproved");
            break;
          case 2:
            status = t("Waiting");
            break;
          case 3:
            status = t("Unfinished");
            break;
          default:
            status = t("Unknown");
        }
        return (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <StatusChip
              status={status}
              label={status.charAt(0).toUpperCase() + status.slice(1)}
            />
          </Box>
        );
      }
    },
    {
      name: "",
      minWidth: "50px",
      width: "50px",
      cellRender: (row) => {
        return (
          <Stack alignItems={"center"} spacing={1} direction={"row"}>
            <ButtonBlue
              variant="contained"
              sx={{ width: '120%', height: '120%' }}
              onClick={() => {
                history.push({
                  pathname: "/kpi-plan",
                  state: { planList: row, status: row.status },
                });
              }}
            >
              {t("Plan")}
            </ButtonBlue>
          </Stack>
        )
      }
    },
  ];

  useEffect(() => {
    dispatch(getKpiUpdate());
  }, [dispatch]);

  return (
    <StyledRoot className="page">
      <Container>
        <GridStyled container justifyContent="space-between" alignItems="center" mt={2}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" className="kpi" sx={{ ml: 1, fontSize: "30px" }}>
              {t("UserPlanKpi")}
            </Typography>
          </Box>
        </GridStyled>
        <div className="cardPlan">
          <Box padding="2px 0">
            <Container maxWidth="lg">
              <Box padding="2px 0" minHeight="700px">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {
                          columns.map((c, index) => (
                            <StyledCellHeader
                              key={index}
                              sx={{
                                textAlign: c.headerTextAlign ? c.headerTextAlign : undefined,
                                minWidth: c.minWidth ? c.minWidth : undefined,
                                width: c.width ? c.width : undefined,
                                maxWidth: c.maxWidth ? c.maxWidth : undefined,
                              }}
                            >
                              {c.name}
                            </StyledCellHeader>
                          ))
                        }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {kpiPlanStore && kpiPlanStore.map((r, index) => (
                        <TableRow key={index}>
                          {columns.map((c, cIndex) => (
                            <StyledCellContent
                              key={cIndex}
                              style={{
                                textAlign: c.cellTextAlign ? c.cellTextAlign : undefined,
                                fontSize: c.cellFontSize ? c.cellFontSize : undefined,
                                minWidth: c.minWidth ? c.minWidth : undefined,
                                width: c.width ? c.width : undefined,
                                maxWidth: c.maxWidth ? c.maxWidth : undefined,
                              }}
                            >
                              {c.cellRender ? c.cellRender({ ...r, status }) : c.fieldKey ? <Typography fontSize="inherit">{r[c.fieldKey]}</Typography> : null}
                            </StyledCellContent>
                          ))}
                        </TableRow>
                      ))}

                      {(!kpiPlanStore || kpiPlanStore.length <= 0) && (
                        <TableRow>
                          <TableCell colSpan={8} align="center">
                            <Box height="120px" display="flex" justifyContent="center" alignItems="center">
                              <Typography variant="h6" >
                                <FindInPageIcon />
                                {t("NoData")}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Container>
          </Box>
        </div>
      </Container>
    </StyledRoot>
  )
}

export default KpisIndividualPlanUpdate;
