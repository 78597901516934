export const getCateringStatus = (status) => {
  if (status === "pending") {
    return "รอดำเนินการ";
  } else if (status === "approved") {
    return "อนุมัติ";
  } else if (status === "disapproved") {
    return "ไม่อนุมัติ";
  } else {
    return "";
  }
};

export const getOptionHours = () => {
  let hours = [];
  for (let i = 0; i <= 23; i++) {
    hours.push({
      value: i,
      label: String(i).padStart(2, "0"),
    });
  }
  return hours;
};

export const getOptionMinutes = () => {
  let minutes = [];
  for (let i = 0; i <= 59; i++) {
    minutes.push({
      value: i,
      label: String(i).padStart(2, "0"),
    });
  }
  return minutes;
};

export const getOptionMaintenanceTypes = () => [
  { value: 1, label: "ซ่อมแซมทั่วไป" },
  { value: 2, label: "งานไฟฟ้า" },
  { value: 3, label: "งานประปา" },
];

export const getOptionMonth = () => {
  return [
    { label: "มกราคม", value: 1 },
    { label: "กุมภาพันธ์", value: 2 },
    { label: "มีนาคม", value: 3 },
    { label: "เมษายน", value: 4 },
    { label: "พฤษภาคม", value: 5 },
    { label: "มิถุนายน", value: 6 },
    { label: "กรกฎาคม", value: 7 },
    { label: "สิงหาคม", value: 8 },
    { label: "กันยายน", value: 9 },
    { label: "ตุลาคม", value: 10 },
    { label: "พฤศจิกายน", value: 11 },
    { label: "ธันวาคม", value: 12 },
  ];
};
export const getTranslationDateLabel = (lang) => {
  const labelTranslation = {
    th: "th",
    en: "en",
    la: "lo",
  };

  if (labelTranslation[lang]) {
    return labelTranslation[lang];
  } else {
    return labelTranslation["en"];
  }
};
