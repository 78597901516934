import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { TextField, Grid, Popper, Box, Divider } from "@mui/material";
import XLSX from "xlsx";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import AlertResponse from "../../shared/general/AlertResponse";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import downloadHeaderExcel from "../../assets/data/header-download-employee";
import { getEmployeeExcelExport } from "../../../../actions/employee";
import SelectCompany from "../shared/selectCompany";
import SaveIcon from "@mui/icons-material/Save";
import { addEmailReport, getEmailReportById } from "../../../../actions/email";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const User = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: email } = useSelector((state) => state.email);
  const { open, handleClose } = props;
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [autoEmail, setAutoEmail] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const handleChangeCompany = (newValue) => {
    if (newValue) {
      setSelectedCompany(newValue.idCompany);
    } else {
      setSelectedCompany(null);
    }
  };

  useEffect(() => {
    if (email) setAutoEmail(email.day);
  }, [email]);

  useEffect(() => {
    dispatch(getEmailReportById(userProfile.idEmployees, "userinfo"));
  }, []);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleSubmit = async () => {
    const employeeList = await dispatch(
      getEmployeeExcelExport({
        idCompany: selectedCompany,
        contractStatus: "normal",
      })
    ).then((res) => res.data);
    const elementList = [];

    if (employeeList.length <= 0) {
      employeeList.push({
        employeeID: null,
      });
    }

    for (let index = 0; index < employeeList.length; index++) {
      const element = {
        employeeID: employeeList[index].employeeID,
        title_TH: employeeList[index].title_TH,
        firstname_TH: employeeList[index].firstname_TH,
        lastname_TH: employeeList[index].lastname_TH,
        title_EN: employeeList[index].title_EN,
        firstname_EN: employeeList[index].firstname_EN,
        lastname_EN: employeeList[index].lastname_EN,
        nickname_TH: employeeList[index].nickname_TH,
        gender:
          employeeList[index].gender === "Male"
            ? "ชาย"
            : employeeList[index].gender === "Female"
            ? "หญิง"
            : "",
        personalID: employeeList[index].personalID,
        nationality: employeeList[index].nationality,
        maritalStatus: employeeList[index].maritalStatus,
        passportNumber: employeeList[index].passportNumber,
        workPermitNumber: employeeList[index].workPermitNumber,
        taxID: employeeList[index].taxID,
        ssoID: employeeList[index].ssoID,
        birthday: employeeList[index].birthday
          ? dayjs(employeeList[index].birthday).format("DD-MM-YYYY")
          : "",
        houseNo: employeeList[index].houseNo,
        village: employeeList[index].village,
        villageNo: employeeList[index].villageNo,
        alley: employeeList[index].alley,
        road: employeeList[index].road,
        subDistrict: employeeList[index].subDistrict,
        district: employeeList[index].district,
        provience: employeeList[index].provience,
        areaCode: employeeList[index].areaCode,

        username: employeeList[index].username,
        email: employeeList[index].email,
        telephoneMobile: employeeList[index].telephoneMobile,
        emergencyContact: employeeList[index].emergencyContact,
        emergencyRelationship: employeeList[index].emergencyRelationship,
        emergencyPhone: employeeList[index].emergencyPhone,
        numberOfChildren: employeeList[index].numberOfChildren,
        spouseFirstname: employeeList[index].spouseFirstname,
        spouseLastname: employeeList[index].spouseLastname,
        spouseBirthday: employeeList[index].spouseBirthday
          ? dayjs(employeeList[index].spouseBirthday).format("DD-MM-YYYY")
          : "",
        spouseTaxID: employeeList[index].spouseTaxID,
        employmentTypeName:
          dayjs().diff(dayjs(employeeList[index].hiringDate), "day") > 120
            ? employeeList[index].employmentTypeName
            : "ทดลองงาน",
        employmentTime: employeeList[index].employmentTime,
        isFinger:
          employeeList[index].isFinger === 1
            ? "ใช่"
            : employeeList[index].isFinger === 0
            ? "ไม่ใช่"
            : "",
        workingLocation: employeeList[index].workingLocation,
        groupGpsLocationName: employeeList[index].groupGpsLocationName,
        filed_office: employeeList[index].filed_office,
        jobLevelName: employeeList[index].jobLevelName,
        personnelLevelGroupName: employeeList[index].personnelLevelGroupName,
        personnelLevelName: employeeList[index].personnelLevelName,
        jobGroupName: employeeList[index].jobGroupName,
        subJobGroupName: employeeList[index].subJobGroupName,
        positionName: employeeList[index].positionName,
        companyName: employeeList[index].companyName,
        branchName: employeeList[index].branchName,
        businessUnitName: employeeList[index].businessUnitName,
        divisionName: employeeList[index].divisionName,
        departmentName: employeeList[index].departmentName,
        sectionName: employeeList[index].sectionName,
        hiringDate: employeeList[index].hiringDate
          ? dayjs(employeeList[index].hiringDate).format("DD-MM-YYYY")
          : "",
        managerLV1_fullname: employeeList[index].managerLV1_firstname_TH
          ? `${employeeList[index].managerLV1_firstname_TH} ${employeeList[index].managerLV1_lastname_TH}`
          : null,
        managerLV1_positionName: employeeList[index].managerLV1_positionName,
        managerLV1_email: employeeList[index].managerLV1_email,
        managerLV2_fullname: employeeList[index].managerLV2_firstname_TH
          ? `${employeeList[index].managerLV2_firstname_TH} ${employeeList[index].managerLV2_lastname_TH}`
          : null,
        managerLV2_positionName: employeeList[index].managerLV2_positionName,
        managerLV2_email: employeeList[index].managerLV2_email,
        workingType: employeeList[index].workingType,
        shiftGroupName: employeeList[index].shiftGroupName,
        shiftName: employeeList[index].shiftName,
        timeIn: employeeList[index].timeIn,
        timeOut: employeeList[index].timeOut,
        paymentTypeName: employeeList[index].paymentTypeName,
        paymentRoundName: employeeList[index].paymentRoundName,
        salary: employeeList[index].salary,
        lastPositionDate: employeeList[index].lastPositionDate,
        lastSalary: employeeList[index].lastSalary,
        lastSalaryDate: employeeList[index].lastSalaryDate,
        lastSpecialAdjustmentIncrease:
          employeeList[index].lastSpecialAdjustmentIncrease,
        lastSpecialAdjustmentIncreaseDate:
          employeeList[index].lastSpecialAdjustmentIncreaseDate,
        lastJobLevelDate: employeeList[index].lastPositionDate,
        lastPersonnelLevelDate: employeeList[index].lastPersonnelLevelDate,

        contractType: employeeList[index].contractType,
        retirementDate: employeeList[index].retirementDate
          ? dayjs(employeeList[index].retirementDate).format("DD-MM-YYYY")
          : "",
        contractTermainatoinDate: employeeList[index].contractTermainatoinDate
          ? dayjs(employeeList[index].contractTermainatoinDate).format(
              "DD-MM-YYYY"
            )
          : "",
        contractTermainatoinStatus:
          employeeList[index].contractTermainatoinStatus,
        resignDate: employeeList[index].resignDate
          ? dayjs(employeeList[index].resignDate).format("DD-MM-YYYY")
          : "",
        OESY_Y: employeeList[index].OESY_Y,
        OESY_M: employeeList[index].OESY_M,
        OESY: employeeList[index].OESY,
        SERV_Y: employeeList[index].SERV_Y,
        SERV_M: employeeList[index].SERV_M,
        SERV: employeeList[index].SERV,
        ESY_Y: employeeList[index].ESY_Y,
        ESY_M: employeeList[index].ESY_M,
        ESY: employeeList[index].ESY,
        TIG_Y: employeeList[index].TIG_Y,
        TIG_M: employeeList[index].TIG_M,
        TIG: employeeList[index].TIG,
        AGE_Y: employeeList[index].AGE_Y,
        AGE_M: employeeList[index].AGE_M,
        AGE: employeeList[index].AGE,
        degree_1:
          employeeList[index].educations &&
          employeeList[index].educations.length > 0
            ? employeeList[index].educations[0].degree
            : "",
        university_1:
          employeeList[index].educations &&
          employeeList[index].educations.length > 0
            ? employeeList[index].educations[0].university
            : "",
        faculty_1:
          employeeList[index].educations &&
          employeeList[index].educations.length > 0
            ? employeeList[index].educations[0].faculty
            : "",
        major_1:
          employeeList[index].educations &&
          employeeList[index].educations.length > 0
            ? employeeList[index].educations[0].major
            : "",
        fromYear_1:
          employeeList[index].educations &&
          employeeList[index].educations.length > 0
            ? employeeList[index].educations[0].fromYear
            : "",
        endYear_1:
          employeeList[index].educations &&
          employeeList[index].educations.length > 0
            ? employeeList[index].educations[0].endYear
            : "",
        gpa_1:
          employeeList[index].educations &&
          employeeList[index].educations.length > 0
            ? employeeList[index].educations[0].gpa
            : "",
        degree_2:
          employeeList[index].educations &&
          employeeList[index].educations.length > 1
            ? employeeList[index].educations[1].degree
            : "",
        university_2:
          employeeList[index].educations &&
          employeeList[index].educations.length > 1
            ? employeeList[index].educations[1].university
            : "",
        faculty_2:
          employeeList[index].educations &&
          employeeList[index].educations.length > 1
            ? employeeList[index].educations[1].faculty
            : "",
        major_2:
          employeeList[index].educations &&
          employeeList[index].educations.length > 1
            ? employeeList[index].educations[1].major
            : "",
        gpa_2:
          employeeList[index].educations &&
          employeeList[index].educations.length > 1
            ? employeeList[index].educations[1].gpa
            : "",
        fromYear_2:
          employeeList[index].educations &&
          employeeList[index].educations.length > 1
            ? employeeList[index].educations[1].fromYear
            : "",
        endYear_2:
          employeeList[index].educations &&
          employeeList[index].educations.length > 1
            ? employeeList[index].educations[1].endYear
            : "",
        degree_3:
          employeeList[index].educations &&
          employeeList[index].educations.length > 2
            ? employeeList[index].educations[2].degree
            : "",
        university_3:
          employeeList[index].educations &&
          employeeList[index].educations.length > 2
            ? employeeList[index].educations[2].university
            : "",
        faculty_3:
          employeeList[index].educations &&
          employeeList[index].educations.length > 2
            ? employeeList[index].educations[2].faculty
            : "",
        major_3:
          employeeList[index].educations &&
          employeeList[index].educations.length > 2
            ? employeeList[index].educations[2].major
            : "",
        gpa_3:
          employeeList[index].educations &&
          employeeList[index].educations.length > 2
            ? employeeList[index].educations[2].gpa
            : "",
        fromYear_3:
          employeeList[index].educations &&
          employeeList[index].educations.length > 2
            ? employeeList[index].educations[2].fromYear
            : "",
        endYear_3:
          employeeList[index].educations &&
          employeeList[index].educations.length > 2
            ? employeeList[index].educations[2].endYear
            : "",
        performance_past_1: employeeList[index].performancePast1,
        performance_past_2: employeeList[index].performancePast2,
        performance_past_3: employeeList[index].performancePast3,
        performance_past_4: employeeList[index].performancePast4,
        performance_past_5: employeeList[index].performancePast5,
        potential_past_1: null,
        potential_past_2: null,
        potential_past_3: null,
        leaderRole: null,
        costCenter: employeeList[index].costCenterCharge,
        costElement: employeeList[index].costElementCharge,
        io: null,
        bookBank: employeeList[index].bookBank,
        bookBankBranchName: employeeList[index].bookBankBranchName,
        bookID: employeeList[index].bookID,
        reportBankBankName: employeeList[index].reportBankBankName,
        reportBankBankID: employeeList[index].reportBankBankID,
        reportBankBookBankID: employeeList[index].reportBankBookBankID,
        reportBankName: employeeList[index].reportBankName,
        reportBankRef: employeeList[index].reportBankRef,
      };

      elementList.push(element);
    }

    const ws = XLSX.utils.json_to_sheet(elementList);

    // console.log(downloadHeaderExcel);

    for (const [key, value] of Object.entries(downloadHeaderExcel)) {
      // console.log(`${key}: ${value}`);
      ws[key].v = value;
    }

    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "PIS Master");
    XLSX.writeFile(
      workbook,
      `PIS Master ${dayjs().format("YYYY-MM-DD HH:mm:ss")}.xlsx`,
      { type: "file" }
    );
    handleClose();
  };

  const submit = async () => {
    const formData = {
      ReportName: "userinfo",
      idEmployees: userProfile.idEmployees,
      day: autoEmail == `${t("LastDayOfMonth")}` ? 0 : autoEmail,
    };
    const result = await dispatch(addEmailReport(formData));
    if (result.status === 200) {
      handleChangeAlertType("success");
      handleOpenAlert(true);
    } else {
      handleChangeAlertType(`${t("Unsuccessful")}`);
      handleOpenAlert(true);
    }
  };

  return (
    <DrawerCustom
      title={`${t("EmployeeInformation")}`}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot style={{ width: 400 }}>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Grid item xs={12} sm={6} className="GridTopicInput">
            {t("EmailSentDate")}
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledBoxSearch>
              <StyledAutocomplete
                options={[
                  `${t("LastDayOfMonth")}`,
                  1,
                  2,
                  3,
                  4,
                  5,
                  6,
                  7,
                  8,
                  9,
                  10,
                  11,
                  12,
                  13,
                  14,
                  15,
                  16,
                  17,
                  18,
                  19,
                  20,
                  21,
                  22,
                  23,
                  24,
                  25,
                  26,
                  27,
                  28,
                ]}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setAutoEmail(newValue);
                  } else {
                    setAutoEmail(null);
                  }
                }}
                value={autoEmail == 0 ? `${t("LastDayOfMonth")}` : autoEmail}
                popupIcon={<i class="fa-light fa-chevron-down"></i>}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={"filled"}
                    placeholder={`${t("SelectDate")}`}
                  />
                )}
                PopperComponent={StyledPopper}
              />
            </StyledBoxSearch>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "16px",
            }}
          >
            <ButtonBlue
              variant="outlined"
              startIcon={<SaveIcon />}
              onClick={submit}
              disabled={autoEmail === null}
            >
              {t("Save")}
            </ButtonBlue>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={6} className="GridTopicInput">
            {t("SelectCompany")}
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectCompany
              variant="outlined"
              hideLabel={true}
              handleChangeCompany={handleChangeCompany}
            />
          </Grid>
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleClose}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue
            variant="outlined"
            startIcon={<DownloadRoundedIcon />}
            onClick={handleSubmit}
          >
            {t("Download")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </DrawerCustom>
  );
};

export default User;
