import React, { useState, useMemo } from "react";
import {
  Card,
  Box,
  Typography,
  Avatar,
  IconButton,
  Tooltip,
  Chip,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupsIcon from "@mui/icons-material/Groups";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import DialogEditObjective from "./DialogEditObjective";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(1, 0),
  boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
}));

const IconButtonStyled = styled(IconButton)({
  color: "#DB4178",
  border: "2px solid #DB4178",
  backgroundColor: "transparent",
  borderRadius: "7px",
  "&:hover": {
    color: "#C53B6C",
    border: "2px solid #C53B6C",
    backgroundColor: "transparent",
  },
});

const ObjectiveCard = ({ data, refreshObjectives }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const isManager = userProfile && userProfile.roles.includes("ROLE_MANAGER");

  const hasCrossTeam = data.keyResults.some(
    (kr) => kr.crossTeam && kr.crossTeam.length > 0
  );

  const [openEditDialog, setOpenEditDialog] = useState(false);

  const handleOpenEditDialog = () => {
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const deleteTeamObjective = () => {
    console.log("Delete objective:", data.idTeamObjective);
  };

  const isSuccess = data.isSuccess;
  const statusText = isSuccess === 1 ? t("ObjectiveSuccess") : t("InProgress");
  const statusColor = isSuccess === 1 ? "success" : "warning";

  const getFormattedDates = (data) => {
    let earliestStartDate = null;
    let latestEndDate = null;

    if (data.keyResults && data.keyResults.length > 0) {
      const keyResultsWithDates = data.keyResults.filter(
        (kr) => kr.startDate && kr.endDate
      );

      keyResultsWithDates.forEach((kr) => {
        const krStartDate = dayjs(kr.startDate);
        const krEndDate = dayjs(kr.endDate);

        if (krStartDate.isValid()) {
          if (!earliestStartDate || krStartDate.isBefore(earliestStartDate)) {
            earliestStartDate = krStartDate;
          }
        }

        if (krEndDate.isValid()) {
          if (!latestEndDate || krEndDate.isAfter(latestEndDate)) {
            latestEndDate = krEndDate;
          }
        }
      });
    }

    if (!earliestStartDate && data.startDate) {
      const objStartDate = dayjs(data.startDate);
      if (objStartDate.isValid()) {
        earliestStartDate = objStartDate;
      }
    }

    if (!latestEndDate && data.endDate) {
      const objEndDate = dayjs(data.endDate);
      if (objEndDate.isValid()) {
        latestEndDate = objEndDate;
      }
    }

    return {
      startDateFormatted: earliestStartDate
        ? earliestStartDate.format("DD/MM/YYYY")
        : "-",
      endDateFormatted: latestEndDate
        ? latestEndDate.format("DD/MM/YYYY")
        : "-",
    };
  };

  const { startDateFormatted, endDateFormatted } = useMemo(
    () => getFormattedDates(data),
    [data]
  );

  return (
    <StyledCard>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={2} md={1}>
          <Box display="flex" justifyContent="center">
            <Avatar
              style={{
                backgroundColor: "#F8D7DA",
                color: "#DB4178",
                width: 80,
                height: 80,
                fontSize: "14px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="caption"
                style={{ lineHeight: 1, fontSize: "14px", color: "#DB4178" }}
              >
                {t("Quarter")}
              </Typography>
              <Typography
                variant="h5"
                style={{
                  lineHeight: 1,
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: "#DB4178",
                }}
              >
                {data.quarter}
              </Typography>
            </Avatar>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            {data.objectiveName}

            {hasCrossTeam && (
              <Tooltip title={t("CrossTeam")}>
                <GroupsIcon
                  color="primary"
                  style={{ marginLeft: "8px" }}
                  fontSize="small"
                />
              </Tooltip>
            )}
          </Typography>
          {data.departmentName && (
            <Typography variant="body2" color="textSecondary">
              {t("Department")}: {data.departmentName}
            </Typography>
          )}

          <Typography variant="body2" style={{ marginTop: "8px" }}>
            {t("NumberKeyResults")}: <strong>{data.keyResults.length}</strong>{" "}
            {t("Unit.Items")}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Box>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {t("Date")}
            </Typography>
            <Typography variant="body2">
              {startDateFormatted} - {endDateFormatted}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <Chip
            label={statusText}
            color={statusColor}
            variant="outlined"
            style={{ fontWeight: "bold" }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <Box display="flex" justifyContent="flex-end" gap={1}>
            <Tooltip title={t("ViewMore")}>
              <IconButtonStyled
                component={Link}
                to={`/okrs/team-planning/information/${data.idTeamObjective}`}
              >
                <SearchIcon fontSize="small" />
              </IconButtonStyled>
            </Tooltip>

            {isManager && (
              <Tooltip title={t("EditObjective")}>
                <IconButtonStyled onClick={handleOpenEditDialog}>
                  <EditIcon fontSize="small" />
                </IconButtonStyled>
              </Tooltip>
            )}

            {isManager && (
              <Tooltip title={t("DeleteObjective")}>
                <IconButtonStyled onClick={deleteTeamObjective}>
                  <DeleteIcon fontSize="small" />
                </IconButtonStyled>
              </Tooltip>
            )}
          </Box>
        </Grid>
      </Grid>

      {openEditDialog && (
        <DialogEditObjective
          open={openEditDialog}
          handleClose={handleCloseEditDialog}
          data={data}
          onUpdated={() => {
            handleCloseEditDialog();
            if (refreshObjectives) {
              refreshObjectives();
            }
          }}
        />
      )}
    </StyledCard>
  );
};

export default ObjectiveCard;
