import { httpClient } from "./httpClient";

const getAdminSettingPF = (query) => {
    return httpClient.get("admin/adminSettingPF", { params: query });
};

const addNewAdminSettingPF = (formData) => {
    return httpClient.post("admin/add-new-SettingPF",formData);
};

const updateAdminSettingPF = (formData) => {
    return httpClient.put("admin/updateSettingPF",formData);
};

const deleteAdminSettingPF = (idSettingPF) => {
    return httpClient.delete(`admin/delete-settingPF/${idSettingPF}`);
}

const updateAdminSchedulePF = (formData) => {
    return httpClient.put("admin/updateSchedulePF",formData);
};

const addNewAdminSchedulePF = (formData) => {
    return httpClient.post("admin/add-new-SchedulePF",formData);
}

const deleteAdminSchedulePF = (idSchedulePF) => {
    return httpClient.delete(`admin/delete-schedulePF/${idSchedulePF}`);
}

export default {
    getAdminSettingPF,
    addNewAdminSettingPF,
    updateAdminSettingPF,
    deleteAdminSettingPF,
    updateAdminSchedulePF,
    addNewAdminSchedulePF,
    deleteAdminSchedulePF
};