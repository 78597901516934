import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  getOrganizationObjective,
  updateOrganizationKeyResult,
} from "../../../../../../../actions/okrs";
import { openNotificationAlert } from "../../../../../../../actions/notificationAlert";
import { useParams } from "react-router-dom";

const ResponsiveButtonBlue = styled(Button)({
  backgroundColor: "rgba(219, 65, 120, 1)",
  "&:hover": {
    backgroundColor: "#C53B6C",
  },
});

const DetailKeyResult = ({
  open,
  handleClose,
  data,
  getStatusAchieveApprove,
}) => {
  const { idOrganizationObjective } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isEditAllowed = getStatusAchieveApprove(data.isAchieve) === 4;
  
  const [selectedValue, setSelectedValue] = useState("");
  const [formData, setFormData] = useState({
    tacticName: "",
    measureEvidence: "",
    commitQuality: "",
    measureDataType: 1,
    description: "",
  });
  
  useEffect(() => {
    if (open) {
      setFormData({
        tacticName: data && data.tacticName,
        measureEvidence: data && data.measureEvidence,
        commitQuality: data && data.commitQuality,
        measureDataType: data && data.measureDataType,
        description: data && data.description,
      });
    }
  }, [open]);

  useEffect(() => {
    if (data && data.measureDataType) {
      setSelectedValue(String(data.measureDataType));
    }
  }, [data]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if (name === "measureDataType") {
      setSelectedValue(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const res = await dispatch(updateOrganizationKeyResult(data.idOrganizationKeyResult, formData));
    if (res && res.status === 200) {
      dispatch(openNotificationAlert({ type: "success", message: t("Successfully") }));
      await dispatch(getOrganizationObjective(idOrganizationObjective));
    } else {
      dispatch(openNotificationAlert({ type: "error", message: t("AnErrorOccurred") }));
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{t("Descriptions")}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            name="tacticName"
            label={t("Strategy")}
            value={formData.tacticName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={!isEditAllowed}
          />
          <TextField
            name="measureEvidence"
            label={t("MeasurementData")}
            value={formData.measureEvidence}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={!isEditAllowed}
          />
          <TextField
            name="commitQuality"
            label={t("ObjectiveMeasurementData")}
            value={formData.commitQuality}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={!isEditAllowed}
          />
          <TextField
            select
            name="measureDataType"
            label={t("TypeMeasurementData")}
            value={selectedValue}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={!isEditAllowed}
          >
            <MenuItem value="1">{t("Number")}</MenuItem>
            <MenuItem value="2">{t("Explanation")}</MenuItem>
          </TextField>
          {data.description && (
            <TextField
              name="description"
              label={t("Descriptions")}
              value={formData.description}
              onChange={handleChange}
              fullWidth
              margin="normal"
              multiline
              disabled={!isEditAllowed}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("Close")}
          </Button>
          <ResponsiveButtonBlue
            variant="contained"
            type="submit"
            disabled={!isEditAllowed}
          >
            {t("Edit")}
          </ResponsiveButtonBlue>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DetailKeyResult;
