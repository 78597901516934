import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Avatar, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { getUserFullName, getUserPosition } from "../../../../utils/userData";

const RequestLeaveCard = ({ row }) => {
  const { t, i18n } = useTranslation();
  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        borderRadius: 4,
        display: "flex",
        alignItems: "flex-start",
        bgcolor: "#F1F0F0",
        width: "100%",
        marginBottom: "10px",
      }}
    >
      <Avatar
        sx={{ width: 60, height: 60, mr: 2, flexShrink: 0 }}
        src={row.imageURL}
      />
      <Box sx={{ flexGrow: 1, minWidth: 0 }}>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("FullName")}: {getUserFullName(row)} ({getUserPosition(row)})
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("Type")}: {row.leaveGroupName}
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("Start")}:{" "}
          {dayjs(row.startText, "DD/MM/YYYY HH:mm").format(
            i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
          )}{" "}
          (
          {row.isFullDay === 1
            ? t("Full_day")
            : dayjs(row.startText, "DD/MM/YYYY HH:mm").format(
                i18n.resolvedLanguage === "th" ? "HH:mm" : "h:mm A"
              )}
          )
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("End")}:{" "}
          {dayjs(row.endText, "DD/MM/YYYY HH:mm").format(
            i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
          )}{" "}
          (
          {row.isFullDay === 1
            ? t("Full_day")
            : dayjs(row.endText, "DD/MM/YYYY HH:mm").format(
                i18n.resolvedLanguage === "th" ? "HH:mm" : "h:mm A"
              )}
          )
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("totalUsedLeaves")}:{" "}
          {row.isLeaveCompensate === 0
            ? `${
                row.isFullDay === 0 && row.used && row.used > 0
                  ? row.used.toFixed(2)
                  : row.used
              } ${t("Unit.Days")}`
            : row.isLeaveCompensate === 1
            ? `${row.used && row.used > 0 ? row.used.toFixed(2) : row.used} ${t(
                "Unit.Hours"
              )}`
            : "-"}
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("Reason")}: {row.description}
        </Typography>
      </Box>
    </Paper>
  );
};

export default RequestLeaveCard;
