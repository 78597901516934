import dayjs from "dayjs";
import { httpClient } from "./httpClient";

const getAllProject = () => {
  return httpClient.get("dataProject");
};

const getProjectByEmp = () => {
  return httpClient.get(`get-employee-project`);
}

const getEmployeeProject = (idEmployeeProject) => {
  return httpClient.get(`employee-project/${idEmployeeProject}`);
}
;
const getProjectById = (idEmployeeProject) => {
  return httpClient.get(`project/${idEmployeeProject}`);
};

const getManagerProjectToApprove = (start, end) => {
  // Validate start and end dates
  const isValidStartDate = dayjs(start).isValid();
  const isValidEndDate = dayjs(end).isValid();

  const startDate = isValidStartDate ? dayjs(start).format("YYYY-MM-DD") : '';
  const endDate = isValidEndDate ? dayjs(end).format("YYYY-MM-DD") : '';

  const url = startDate && endDate ?
    `manager-approve-project?dateStart=${startDate}&dateEnd=${endDate}` :
    'manager-approve-project';

  return httpClient.get(url);
};

const updateStatusProject = (query) => {
  return httpClient.put(`approve-project`, query);
}

const addProject = (query) => {
  return httpClient.post(`add-project`, query);
}

const deleteProject = (id) => {
  return httpClient.delete(`delete-project/${id}`);
}

const updateProject = (id, query) => {
  return httpClient.put(`update-project/${id}`, query);
}

export default {
  addProject,
  getAllProject,
  updateProject,
  deleteProject,
  getProjectById,
  getProjectByEmp,
  getEmployeeProject,
  updateStatusProject,
  getManagerProjectToApprove
};
