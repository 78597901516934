import React from "react";
import {
  Box,
  Button,
  Slider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  DialogActions,
} from "@mui/material";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  getOrganizationObjective,
  updateOrganizationObjective,
} from "../../../../../../../actions/okrs";
import { openNotificationAlert } from "../../../../../../../actions/notificationAlert";

const ResponsiveButtonBlue = styled(Button)({
  backgroundColor: "rgba(219, 65, 120, 1)",
  "&:hover": {
    backgroundColor: "#C53B6C",
  },
});

const marks = [
  { value: 0, label: "0" },
  { value: 0.1, label: "0.1" },
  { value: 0.2, label: "0.2" },
  { value: 0.3, label: "0.3" },
  { value: 0.4, label: "0.4" },
  { value: 0.5, label: "0.5" },
  { value: 0.6, label: "0.6" },
  { value: 0.7, label: "0.7" },
  { value: 0.8, label: "0.8" },
  { value: 0.9, label: "0.9" },
  { value: 1.0, label: "1.0" },
];

const EvaluationObjective = ({ data, open, handleClose }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { control, formState, handleSubmit } = useForm({
    defaultValues: {
      rating: 0,
      isSuccess: data.isSuccess ? "1" : "0",
    },
  });

  const onSubmit = async (submitData) => {
    const formData = { ...submitData };

    formData.isSuccess = formData.isSuccess === "1" ? 1 : 0;
    formData.evaluateDate = dayjs().format("YYYY-MM-DD HH:mm:ss");

    const res = await dispatch(
      updateOrganizationObjective(data.idOrganizationObjective, formData)
    );

    if (res && res.status === 200) {
      dispatch(
        openNotificationAlert({ type: "success", message: t("Successfully") })
      );
      await dispatch(getOrganizationObjective(data.idOrganizationObjective));
    } else {
      dispatch(
        openNotificationAlert({ type: "error", message: t("AnErrorOccurred") })
      );
    }
    handleClose();
  };

  const getStatusAchieve = (status) => {
    if (status === 1) {
      return 5;
    } else if (status === 0) {
      return 6;
    } else {
      return 4;
    }
  };

  return (
    <Dialog fullWidth maxWidth={"sm"} onClose={handleClose} open={open}>
      <DialogTitle>{t("OrganizationalGoalEvaluation")}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Typography fontSize="20px">{data.objectiveName}</Typography>
          <Stack spacing={2}>
            <Controller
              name="isSuccess"
              control={control}
              render={({ field }) => (
                <div>
                  <Typography fontSize={18}>{`${t("Evaluate")}`}</Typography>
                  <ToggleButtonGroup {...field} exclusive>
                    <ToggleButton value={"1"} color="success">{`${t(
                      "Success"
                    )}`}</ToggleButton>
                    <ToggleButton value={"0"} color="error">{`${t(
                      "Failure"
                    )}`}</ToggleButton>
                  </ToggleButtonGroup>
                </div>
              )}
            />
            <Controller
              name="rating"
              control={control}
              render={({ field }) => (
                <Box display="flex" gap="8px" padding="10px">
                  <Slider
                    max={1}
                    step={0.1}
                    valueLabelDisplay="auto"
                    marks={marks}
                    onChange={(_, newValue) => {
                      field.onChange(newValue);
                    }}
                  />
                  <i
                    className="fa-solid fa-stars"
                    style={{ color: "#f9df39" }}
                  ></i>
                </Box>
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <ResponsiveButtonBlue
            variant="contained"
            type="submit"
            disabled={formState.isSubmitting}
          >
            {t("Evaluate")}
          </ResponsiveButtonBlue>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EvaluationObjective;
