import React, { Fragment, useEffect } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, Box, Divider, Grid, InputAdornment, MenuItem, Typography, createFilterOptions, styled } from "@mui/material";
import { getUserFullName } from "../../../../../../utils/userData";
import { getAllPositions } from "../../../../../../actions/positions";
import { getManager } from "../../../../../../actions/manager";
import { getAffiliateOrganization } from "../../../../../../actions/company";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import DatePickerCustom from "../../../../shared/date/datePicker";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import dayjs from "dayjs";
import { getEmployeePositionChange, getEmployeeProfile, updateEmployeePositionChange } from "../../../../../../actions/employee";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const DrawerEdit = (props) => {

  const { drawerConfig, handleClose } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: positionList } = useSelector((state) => state.positions);
  const { result: managerList } = useSelector((state) => state.manager);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );

  const validateYupSchema = yup.object({
    company: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
    position: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
    managerLV1: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
    managerLV2: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
  });

  const useHookForm = useForm({
    defaultValues: {
      company: null,
      position: null,
      managerLV1: null,
      managerLV2: null,
      start: new Date(),
      description: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  })

  useEffect(() => {
    if (drawerConfig.isOpen) {

      useHookForm.reset({
        company: null,
        position: null,
        managerLV1: null,
        managerLV2: null,
        start: drawerConfig.data.start? new Date(dayjs(drawerConfig.data.start)): new Date(),
        description: drawerConfig.data.description? drawerConfig.data.description: "",
      });
      
      dispatch(getAffiliateOrganization()).then(res => {
        if(res.status === 200){
          const foundCompany = res.data.find(item => item.idCompany === drawerConfig.data.idCompany);
          if(foundCompany){
            console.log("Hiii")
            useHookForm.resetField("company", { defaultValue: foundCompany })

            dispatch(getAllPositions({idCompany: foundCompany.idCompany})).then(res => {
              if(res.status === 200){
                const foundPosition = res.data.find(item => item.idPosition === drawerConfig.data.idPosition);
                if(foundPosition){
                  useHookForm.resetField("position", { defaultValue: foundPosition })
                }
              }
            })

          }
        }
      });
      dispatch(getManager()).then(res => {
        if(res.status === 200){
          const foundManagerLV1 = res.data.find(item => item.idEmployees === drawerConfig.data.idManagerLV1);
          const foundManagerLV2 = res.data.find(item => item.idEmployees === drawerConfig.data.idManagerLV2);
          
          if(foundManagerLV1){
            useHookForm.resetField("managerLV1", { defaultValue: foundManagerLV1 })
          }
          if(foundManagerLV2){
            useHookForm.resetField("managerLV2", { defaultValue: foundManagerLV2 })
          }
        }
      });
    }
  }, [drawerConfig.isOpen]);

  const companyWatch = useHookForm.watch("company");
  const PositionWatch = useHookForm.watch("position");

  const filterOptions = createFilterOptions({
    stringify: (value) =>
      `${value.positionName} ${value.positionName_EN} ${value.positionCode}`,
  });

  const filterManagerOptions = createFilterOptions({
    stringify: (value) =>
      `${value.firstname_TH} ${value.lastname_TH} ${value.firstname_EN} ${value.lastname_EN} ${value.email}`,
  });

  const onSubmit = async (data) => {

    const formData = {
      idPosition: data.position.idPosition,
      idManagerLV1: data.managerLV1.idEmployees,
      idManagerLV2: data.managerLV2.idEmployees,
      description: data.description,
    }

    let isDirty = false;

    for (const [key, value] of Object.entries(formData)) {
      if(value !== drawerConfig.data[key]){
        isDirty = true;
      }
    }

    if(isDirty){

      formData.updateDate = dayjs().format("YYYY-MM-DD HH:mm:ss");

      const result = await dispatch(
        updateEmployeePositionChange(
          employeeProfile.idEmployees,
          drawerConfig.data.idEmployeePosition,
          formData
        )
      );
  
      if(result && result.status === 200){
        handleClose();
        dispatch(openNotificationAlert({
          type: "success",
          message: t("DataSaveSuccessful")
        }))
        
      } else {
        dispatch(openNotificationAlert({
          type: "error",
          message: t("AnErrorOccurred")
        }))
      }

      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
      dispatch(getEmployeePositionChange(employeeProfile.idEmployees));

    } else {
      dispatch(openNotificationAlert({
        type: "info",
        message: t("ข้อมูลไม่มีการเปลี่ยนแปลง")
      }))
    }

  }

  return (
    <DrawerCustom
      title={`${t("EditTransferData")}`}
      anchor={"right"}
      open={drawerConfig.isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontWeight="600" fontSize="16px">
                {`${t("CurrentData")}`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("Company")}`}
              </Typography>
              {drawerConfig.data.companyName ? (
                <Typography fontWeight="500">
                  {drawerConfig.data.companyName}
                </Typography>
              ) : (
                <Typography color="text.secondary" fontStyle="italic">
                  {`${t("NoItems")}`}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("Position")}`}
              </Typography>
              {drawerConfig.data.positionName ? (
                <Typography fontWeight="500">
                  {drawerConfig.data.positionName}
                </Typography>
              ) : (
                <Typography color="text.secondary" fontStyle="italic">
                  {`${t("NoItems")}`}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("Section")}`}
              </Typography>
              {drawerConfig.data.sectionName ? (
                <Typography fontWeight="500">
                  {drawerConfig.data.sectionName}
                </Typography>
              ) : (
                <Typography color="text.secondary" fontStyle="italic">
                  {`${t("NoItems")}`}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("Department")}`}
              </Typography>
              {drawerConfig.data.departmentName ? (
                <Typography fontWeight="500">
                  {drawerConfig.data.departmentName}
                </Typography>
              ) : (
                <Typography color="text.secondary" fontStyle="italic">
                  {`${t("NoItems")}`}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("Division")}`}
              </Typography>
              {drawerConfig.data.divisionName ? (
                <Typography fontWeight="500">
                  {drawerConfig.data.divisionName}
                </Typography>
              ) : (
                <Typography color="text.secondary" fontStyle="italic">
                  {`${t("NoItems")}`}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("Business")}`}
              </Typography>
              {drawerConfig.data.businessUnitName ? (
                <Typography fontWeight="500">
                  {drawerConfig.data.businessUnitName}
                </Typography>
              ) : (
                <Typography color="text.secondary" fontStyle="italic">
                  {`${t("NoItems")}`}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("ManagerLV")}`} 1
              </Typography>
              {drawerConfig.data.idManagerLV1 ? (
                <Fragment>
                  <Typography fontWeight="500">
                    {getUserFullName({
                      firstname_TH: drawerConfig.data.managerLV1_firstname_TH,
                      lastname_TH: drawerConfig.data.managerLV1_lastname_TH,
                      firstname_EN: drawerConfig.data.managerLV1_firstname_EN,
                      lastname_EN: drawerConfig.data.managerLV1_lastname_EN,
                    })}
                  </Typography>
                  <Typography fontSize="14px">
                    {drawerConfig.data.managerLV1_positionName}
                  </Typography>
                  <Typography fontSize="14px">
                    {drawerConfig.data.managerLV1_email}
                  </Typography>
                </Fragment>
              ) : (
                <Typography color="text.secondary" fontStyle="italic">
                  {`${t("NoItems")}`}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("ManagerLV")}`} 2
              </Typography>
              {drawerConfig.data.idManagerLV2 ? (
                <Fragment>
                  <Typography fontWeight="500">
                    {getUserFullName({
                      firstname_TH: drawerConfig.data.managerLV2_firstname_TH,
                      lastname_TH: drawerConfig.data.managerLV2_lastname_TH,
                      firstname_EN: drawerConfig.data.managerLV2_firstname_EN,
                      lastname_EN: drawerConfig.data.managerLV2_lastname_EN,
                    })}
                  </Typography>
                  <Typography fontSize="14px">
                    {drawerConfig.data.managerLV2_positionName}
                  </Typography>
                  <Typography fontSize="14px">
                    {drawerConfig.data.managerLV2_email}
                  </Typography>
                </Fragment>
              ) : (
                <Typography color="text.secondary" fontStyle="italic">
                  {`${t("NoItems")}`}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider
                style={{
                  borderColor: "rgba(145, 158, 171, 0.32)",
                  borderStyle: "dashed",
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >{`${t("Company")}`}</Typography>
              <Controller
                name="company"
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    options={
                      affiliateOrganizationList ? affiliateOrganizationList : []
                    }
                    getOptionLabel={(option) => `${option.companyName}`}
                    isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.idCompany}>
                        <Box>
                          <Typography>{option.companyName}</Typography>
                        </Box>
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        placeholder={`${t("Search")}`}
                        onBlur={field.onBlur}
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error ? fieldState.error.message : null}
                      />
                    )}
                    value={field.value}
                    onChange={(_, value) => {
                      field.onChange(value);
                      if (value) {
                        dispatch(getAllPositions({ idCompany: value.idCompany }));
                      }
                      if (value) {
                        useHookForm.setValue("position", null);
                        useHookForm.setValue("managerLV1", null);
                        useHookForm.setValue("managerLV2", null);
                      }
                    }}
                    noOptionsText="ไม่พบข้อมูล"
                    disableClearable
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("NewPosition")}`}
              </Typography>
              <Controller
                name="position"
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    options={positionList ? positionList : []}
                    getOptionLabel={(option) => `${option.positionName}`}
                    isOptionEqualToValue={(option, value) => option.idPosition === value.idPosition}
                    filterOptions={filterOptions}
                    disabled={!companyWatch}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.idPosition}>
                        <Box>
                          <Typography color="text.secondary" fontSize="12px">
                            {option.positionCode}
                          </Typography>
                          <Typography>{option.positionName}</Typography>
                          <Typography color="text.secondary" fontSize="14px">
                            {option.divisionName || "-"}/
                            {option.departmentName || "-"}/
                            {option.sectionName || "-"}
                          </Typography>
                        </Box>
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        placeholder={`${t("Search")}`}
                        onBlur={field.onBlur}
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error ? fieldState.error.message : null}
                      />
                    )}
                    value={field.value}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    noOptionsText={`${t("NoData")}`}
                    // disableClearable
                  />
                )}
              />
            </Grid>


            {PositionWatch && <Fragment>
              <Grid item xs={12} sm={6}>
                <Typography
                  fontWeight="600"
                  fontSize="14px"
                  color="text.secondary"
                  paddingBottom="4px"
                >
                  {`${t("Section")}`}
                </Typography>
                {PositionWatch.sectionName ? (
                  <Typography fontWeight="500">
                    {PositionWatch.sectionName}
                  </Typography>
                ) : (
                  <Typography color="text.secondary" fontStyle="italic">
                    {`${t("NoItems")}`}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  fontWeight="600"
                  fontSize="14px"
                  color="text.secondary"
                  paddingBottom="4px"
                >
                  {`${t("Department")}`}
                </Typography>
                {PositionWatch.departmentName ? (
                  <Typography fontWeight="500">
                    {PositionWatch.departmentName}
                  </Typography>
                ) : (
                  <Typography color="text.secondary" fontStyle="italic">
                    {`${t("NoItems")}`}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  fontWeight="600"
                  fontSize="14px"
                  color="text.secondary"
                  paddingBottom="4px"
                >
                  {`${t("Division")}`}
                </Typography>
                {PositionWatch.divisionName ? (
                  <Typography fontWeight="500">
                    {PositionWatch.divisionName}
                  </Typography>
                ) : (
                  <Typography color="text.secondary" fontStyle="italic">
                    {`${t("NoItems")}`}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  fontWeight="600"
                  fontSize="14px"
                  color="text.secondary"
                  paddingBottom="4px"
                >
                  {`${t("Business")}`}
                </Typography>
                {PositionWatch.businessUnitName ? (
                  <Typography fontWeight="500">
                    {PositionWatch.businessUnitName}
                  </Typography>
                ) : (
                  <Typography color="text.secondary" fontStyle="italic">
                    {`${t("NoItems")}`}
                  </Typography>
                )}
              </Grid>
            </Fragment>}

            <Grid item xs={12}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("ManagerLV")} 1`}
              </Typography>
              <Controller
                name="managerLV1"
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    options={managerList ? managerList : []}
                    getOptionLabel={(option) =>
                      `${option.firstname_TH} ${option.lastname_TH}`
                    }
                    isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
                    filterOptions={filterManagerOptions}
                    // disabled={!companyWatch}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.idEmployees}>
                        <Box>
                          <Typography>
                            {option.firstname_TH} {option.lastname_TH}
                          </Typography>
                          <Typography
                            color="text.secondary"
                            fontSize="14px"
                            fontWeight="600"
                          >
                            {option.email}
                          </Typography>
                        </Box>
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        placeholder={`${t("SearchManager")}`}
                        onBlur={field.onBlur}
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error ? fieldState.error.message : null}
                      />
                    )}
                    value={field.value}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    noOptionsText={`${t("NoData")}`}
                    // disableClearable
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("ManagerLV")} 2`}
              </Typography>
              <Controller
                name="managerLV2"
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    options={managerList ? managerList : []}
                    getOptionLabel={(option) =>
                      `${option.firstname_TH} ${option.lastname_TH}`
                    }
                    isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
                    filterOptions={filterManagerOptions}
                    // disabled={!companyWatch}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.idEmployees}>
                        <Box>
                          <Typography>
                            {option.firstname_TH} {option.lastname_TH}
                          </Typography>
                          <Typography
                            color="text.secondary"
                            fontSize="14px"
                            fontWeight="600"
                          >
                            {option.email}
                          </Typography>
                        </Box>
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        placeholder={`${t("SearchManager")}`}
                        onBlur={field.onBlur}
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error ? fieldState.error.message : null}
                      />
                    )}
                    value={field.value}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    noOptionsText={`${t("NoData")}`}
                    // disableClearable
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("EffectiveDate")}`}
              </Typography>
              <Controller
                name="start"
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <DatePickerCustom
                    {...field}
                    views={["year", "month", "day"]}
                    inputFormat="DD/MM/YYYY"
                    openTo="year"
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    disabled
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: `${t("DayMonthYear(AD)")}`,
                        }}
                        onBlur={field.onBlur}
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error ? fieldState.error.message : null}
                      />
                    )}
                    PaperProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "#46cbe2!important",
                        },
                      },
                    }}
                    DialogProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "#46cbe2!important",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                fontWeight="600"
                fontSize="14px"
                color="text.secondary"
                paddingBottom="4px"
              >
                {`${t("Note")}`}
              </Typography>
              <Controller
                name="description"
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    InputProps={{
                      endAdornment: <InputAdornment position="end" style={{alignSelf: "flex-end", fontSize: "12px", userSelect: "none"}}>{500-(field.value.length)}</InputAdornment>,
                    }}
                    multiline
                    minRows={4}
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    onChange={(event) => {
                      if(event.target.value.length > 500){
                        field.onChange(event.target.value.slice(0, 500))
                      } else {
                        field.onChange(event.target.value)
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} container justifyContent="space-between" alignItems="center">
              <ButtonBlue
                variant="text"
                className="cancel"
                onClick={handleClose}
              >
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
              >
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerEdit;