import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useTitle } from "react-use";
import { makeStyles } from "@mui/styles";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import PayrollForm from "./components/payrollForm";
import PayrollSetting from "./components/payrollSetting";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import SaveIcon from "@mui/icons-material/Save";
import AlertResponse from "../../../shared/general/AlertResponse";
import { useTranslation } from "react-i18next";

import {
  getPayrollSetting,
  updatePayrollSetting,
  updatePayrollPayment,
} from "../../../../../actions/paytypes";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(3),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    minHeight: 300,
  },
}));

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const initialValues = () => ({
  payrollId: 0,
  dayOff: 0,
  monthOff: 0,
  fullOff: 0,
  ot: 0,
  otOff: 0,
  morningShift: 0,
  afternoonShift: 0,
  nightShift: 0,
  delayTimes: "",
  decimalRounding: "",
  decimalNumber: "",
  paymentPeriod: "",
  firstCutOff: new Date(),
  secondCutOff: new Date(),
  firstPayDate: new Date(),
  secondPayDate: new Date(),
});

function Index(props) {

  const { selectedCompany } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );
  const [values, setValues] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if(selectedCompany)
    dispatch(getPayrollSetting({ idCompany: selectedCompany.idCompany }));
  }, [selectedCompany]);

  useEffect(() => {
    if (payrollSetting && userProfile) {
      const find = payrollSetting;

      if (find.payment && find.payment.length > 0) {
        setPaymentData(find.payment[0]);
      }

      setValues(find);
    }
  }, [payrollSetting]);

  const handleOnSubmit = async (e) => {
    const formData = {
      firstCutOff: values.firstCutOff,
      firstPayDate: values.firstPayDate,
      firstPayslipDate: values.firstPayslipDate,
      firstPayslipTime: values.firstPayslipTime,
      secondCutOff: values.secondCutOff,
      secondPayDate: values.secondPayDate,
      secondPayslipDate: values.secondPayslipDate,
      secondPayslipTime: values.secondPayslipTime,
      firstAddition: values.firstAddition,
      secondAddition: values.secondAddition,
      firstDeduction: values.firstDeduction,
      secondDeduction: values.secondDeduction,
    };

    console.log("values: ", values);
    console.log("paymentData: ", paymentData);
    console.log("formData: ", formData);

    const resultPayrollSetting = await dispatch(
      updatePayrollSetting(values.idPayrollSetting, formData)
    );
    const resultPayrollPayment = await dispatch(
      updatePayrollPayment(paymentData.idPayrollPayment, paymentData)
    );

    if (resultPayrollSetting && resultPayrollPayment) {
      handleOpenAlert();
      if (
        resultPayrollSetting.status === 200 &&
        resultPayrollPayment.status === 200
      ) {
        handleChangeAlertType("success");
        dispatch(getPayrollSetting());
        //history.goBack();
      } else {
        handleChangeAlertType("error");
      }
    }
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <div className={classes.root}>
      <Box className={classes.headingPage}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">จัดการ Payroll</Typography>
          </Grid>
        </Grid>
      </Box>
      <Paper className={classes.paper}>
        <PayrollForm values={values} setValues={setValues} />
        <StyledDivider />
        <PayrollSetting
          values={values}
          setValues={setValues}
          paymentData={paymentData}
          setPaymentData={setPaymentData}
        />
        <StyledDivider />
        <Box>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <ButtonBlue
                variant={"contained"}
                startIcon={<SaveIcon />}
                onClick={handleOnSubmit}
              >
                Submit
              </ButtonBlue>
            </Grid>
          </Grid>
        </Box>
        <AlertResponse
          open={openAlert}
          handleClose={handleCloseAlert}
          alertType={alertType}
        />
      </Paper>
    </div>
  );
}

export default Index;
