import i18next from "i18next";

export function getUserFullName(user) {
  if (!user.firstname_TH && !user.firstname_EN) {
    return "-";
  }
  if (
    i18next.resolvedLanguage === "th" &&
    user.firstname_TH &&
    user.lastname_TH
  ) {
    return `${user.firstname_TH} ${user.lastname_TH}`;
  } else if (
    i18next.resolvedLanguage === "th" &&
    user.firstname_TH &&
    !user.lastname_TH
  ) {
    return `${user.firstname_TH}`;
  } else if (
    user.firstname_EN &&
    user.lastname_EN
  ) {
    return `${user.firstname_EN} ${user.lastname_EN}`
  } else if (
    user.firstname_EN &&
    !user.lastname_EN
  ) {
    return `${user.firstname_EN}`;
  } else if (
    user.firstname_TH &&
    !user.lastname_TH
  ){
    return `${user.firstname_TH}`
  }else{
    return `${user.firstname_TH} ${user.lastname_TH}`
  }
}

export function getUserManagerFullName(user) {
  if (!user.manager_firstname_TH && !user.manager_firstname_EN) {
    return "-";
  }
  if (
    i18next.resolvedLanguage === "th" &&
    user.manager_firstname_TH &&
    user.manager_lastname_TH
  ) {
    return `${user.manager_firstname_TH} ${user.manager_lastname_TH}`;
  }
  return (
    `${user.manager_firstname_EN} ${user.manager_lastname_EN}` ||
    `${user.manager_firstname_TH} ${user.manager_lastname_TH}`
  );
}

export function getUserNickName(user) {
  if (i18next.resolvedLanguage === "th") {
    return user.nickname_TH;
  }
  return user.nickname_EN || user.nickname_TH;
}

export function getUserPosition(user) {
  if (i18next.resolvedLanguage === "th" && user.positionName) {
    return user.positionName;
  }
  return user.positionName_EN || user.positionName;
}

export function getUserCompany(user) {
  if (i18next.resolvedLanguage === "th") {
    return user.companyName;
  }
  return user.companyName_EN || user.companyName;
}

export function getUserBusinessUnit(user) {
  if (i18next.resolvedLanguage === "th") {
    return user.businessUnitName;
  }
  return user.businessUnitName_EN || user.businessUnitName;
}

export function getUserDivision(user) {
  if (i18next.resolvedLanguage === "th") {
    return user.divisionName;
  }
  return user.divisionName_EN || user.divisionName;
}

export function getUserDepartment(user) {
  if (i18next.resolvedLanguage === "th") {
    return user.departmentName;
  }
  return user.departmentName_EN || user.departmentName;
}

export function getUserSection(user) {
  if (i18next.resolvedLanguage === "th") {
    return user.sectionName;
  }
  return user.sectionName_EN || user.sectionName;
}

export function getUserLastName(user) {
  if (i18next.resolvedLanguage === "th" && user.lastname_TH) {
    return `${user.lastname_TH}`;
  }
  return user.lastname_EN || user.lastname_TH;
}

export function getUserFirstName(user) {
  if (i18next.resolvedLanguage === "th" && user.firstname_TH) {
    return `${user.firstname_TH}`;
  }
  return user.firstname_EN || user.firstname_TH;
}

export function getUserTitle(user) {
  if (i18next.resolvedLanguage === "th" && user.title_TH) {
    return `${user.title_TH}`;
  }
  return user.title_EN || user.title_TH;
}

export function getHolidayName(holiday) {
  console.log(holiday);
  if (holiday) {
    if (i18next.resolvedLanguage === "th" && holiday.name) {
      return `${holiday.name}`;
    }
    return holiday.name_EN || holiday.name;
  } else {
    return "";
  }
  
}
