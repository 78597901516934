import { httpClient } from "./httpClient";

const addProjectEmployees = (formData) => {
  return httpClient.post(`/workforcePlanningCal/addProjectEmployees`, formData);
};

const addUserWorkforceHourTable = (formData) => {
  return httpClient.post(`/workforcePlanningCal/addUserWorkforceHourTable`, formData);
};

const updateUserWorkforceHourTable = (formData) => {
  return httpClient.post(`/workforcePlanningCal/updateUserWorkforceHourTable`, formData);
};

const updateManagerWorkforceApproveTime = (formData) => {
  return httpClient.post(`/workforcePlanningCal/updateManagerWorkforceApproveTime`, formData);
};

const deleteUserWorkforce = (formData) => {
  return httpClient.post(`/workforcePlanningCal/deleteUserWorkforce`, formData);
};

const updateProjectEmployees = (formData) => {
  return httpClient.post(`/workforcePlanningCal/updateProjectEmployees`, formData);
};

const deleteProjectWorkforce = (formData) => {
  return httpClient.post(`/workforcePlanningCal/deleteProjectWorkforce`, formData);
};

const getAllProjectEmployees = () => {
  return httpClient.get(`/workforcePlanningCal/getAllProjectEmployees`);
};

const addUsersToProject = (formData) => {
  return httpClient.post(`/workforcePlanningCal/addUsersToProject`, formData);
};

const getUsersInProjectById = () => {
  return httpClient.get(`/workforcePlanningCal/getUsersInProjectById`);
};

const getAllProjectDetailAdmin = () => {
  return httpClient.get(`/workforcePlanningCal/getAllProjectDetailAdmin`);
};

const getUsersInProjectByIdForPie = (query) => {
  return httpClient.get(`/workforcePlanningCal/getUsersInProjectByIdForPie`,{ params: query });
};

const getUsersInProject = () => {
  return httpClient.get(`/workforcePlanningCal/getUsersInProject`);
};

const getEmployeesWeek = () => {
  return httpClient.get(`/workforcePlanningCal/getEmployeesWeek`);
};

const getUsersInProjectShowHour = () => {
  return httpClient.get(`/workforcePlanningCal/getUsersInProjectShowHour`);
};

const getAllEmployeesInWorkforce = (query) =>{
  return httpClient.get(`workforcePlanningCal/getAllEmployeesInWorkforce`,{ params: query })
}

const getSumHourWorkforceHourUserByIdMonth = (query) =>{
  return httpClient.get(`workforcePlanningCal/getSumHourWorkforceHourUserByIdMonth`,{ params: query })
}

const getSumUserTimeInProject = () => {
  return httpClient.get(`/workforcePlanningCal/getSumUserTimeInProject`);
};

const getBudgetForWorkforce = () => {
  return httpClient.get(`/workforcePlanningCal/getBudgetForWorkforce`);
};

const getUsersTimeforApprove = () => {
  return httpClient.get(`/workforcePlanningCal/getUsersTimeforApprove`);
};

const updateUserTimeApproveManager = (formData) => {
  return httpClient.put(`/workforcePlanningCal/putupdateUserTimeApproveManager`, formData);
};

const getUserWorkforceShowHourTable = () => {
  // console.log('queryryagsduj')
  return httpClient.get(`/workforcePlanningCal/getUserWorkforceShowHourTable`);
};
const getWorkforceDashboardByIdMonth = (query) => {
  // console.log('queryryagsduj')
  return httpClient.get(`/workforcePlanningCal/getWorkforceDashboardByIdMonth`, { params: query });
};
const getWorkforceDashboardAllById = (query) => {
  // console.log('queryryagsduj')
  return httpClient.get(`/workforcePlanningCal/getWorkforceDashboardAllById`, { params: query });
};


export default {
  addProjectEmployees,
  updateProjectEmployees,
  getAllProjectEmployees,
  addUsersToProject,
  getUsersInProjectById,
  getUsersInProject,
  getUsersTimeforApprove,
  updateUserTimeApproveManager,
  getUsersInProjectShowHour,
  getAllEmployeesInWorkforce,
  getEmployeesWeek,
  getWorkforceDashboardByIdMonth,
  getWorkforceDashboardAllById,
  getUserWorkforceShowHourTable,
  addUserWorkforceHourTable,
  updateUserWorkforceHourTable,
  deleteUserWorkforce,
  getUsersInProjectByIdForPie,
  updateManagerWorkforceApproveTime,
  getSumHourWorkforceHourUserByIdMonth,
  getAllProjectDetailAdmin,
  getSumUserTimeInProject,
  deleteProjectWorkforce,
  getBudgetForWorkforce
};
