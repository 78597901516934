import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ExcelJS from 'exceljs';
import {
  Box,
  Breadcrumbs,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { getSummaryOvertimeShift } from "../../../../../actions/report";
import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import SelectCompany from "../../shared/selectCompany";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import WorkIcon from "@mui/icons-material/Work";
import { getAllEmployees } from "../../../../../actions/employee";
import { getAffiliateOrganization } from "../../../../../actions/company";
import ChartOT from "./ChartOT";
import DatePickerCustom from "../../../shared/date/datePicker";
import dayjs from "dayjs";
import SelectAffiliateCompany from "../../../shared/general/SelectAffiliateCompany";
import { getAllOTHour, getAllOTWage } from "../../../../../actions/payruns-export";
import AlertResponse from "../../../shared/general/AlertResponse";
import { TotalHoursOTxlsx } from "../xlsx-export/totatHourOTxlsx";
import { TotalWageOTxlsx } from "../xlsx-export/totalWageOTxlsx";
import { useTranslation } from "react-i18next";
import { getUserCompany, getUserFirstName, getUserFullName, getUserLastName } from "../../../../../utils/userData";
import * as XLSX from "xlsx";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 10,
  paddingTop: 10,
  marginTop: 10,
});

const DivEmployeesList = styled("div")({
  width: "100%",
  height: "fit-content",
  display: "grid",
  marginBottom: 64,
  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
    border: "0",
    marginBottom: 16,
    "& .MuiTypography-root": {
      fontWeight: 500,
      "& span": {
        letterSpacing: 0.5,
      },
    },
  },
  "& .employee-list": {
    padding: 50,
    height: "520px",
    overflow: "auto",
    backgroundColor: "white",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "row",
    gridTemplateRows: "repeat(autofill, minmax(130px, 1fr))",
    gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
    minWidth: "100%",
    boxSizing: "border-box",
    columnGap: 8,
    "& .employee-item": {
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "column",
      marginRight: "2px",
      alignItems: "center",
      minHeight: "105px",
      whiteSpace: "nowrap",
    },
    "& .MuiAvatar-root": {
      width: 72,
      height: 72,
      marginBottom: 8,
      alignItems: "center",
    },
    "& .MuiTypography-root": {
      textAlign: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& .div-text-end": {
      height: "60px",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "center",
    },
  },
});

const StyledDetailCard = styled("div")({
  padding: 24,
  paddingTop: 16,
  "& .part-header-detail": {
    paddingBottom: 24,
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: 36,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});

const StyledBoxSearchButton = styled(Box)({
  marginTop: 30,
  display: "flex",
  justifyContent: "space-evenly",
  gap: 8,
});

const DashboardOT = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeList } = useSelector((state) => state.employees);
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);
  const { result: ReportSummaryOvertimeShiftList } = useSelector(
    (state) => state.reportSummaryOvertimeShift
  );
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  // const [otWage, setOtWage] = useState(null);
  // const [otHour, setOtHour] = useState(null);
  // const [exportedData, setExportedData] = useState(null);
  // const [exportedData2, setExportedData2] = useState(null);
  const [otType, setOtType] = useState(1);
  const OTItems = [
    { text: "OT 1", marginTop: "5vh" },
    { text: "OT 1.5", marginTop: "5vh" },
    { text: "OT 2", marginTop: "5vh" },
    { text: "OT 3", marginTop: "5vh" },
    { text: `OT ${t("Total")} (${t("Baht")})`, marginTop: "5vh" },
  ];

  const IconItems = [
    { color: "#008FFB", text: "OT 1" },
    { color: "#00E396", text: "OT 1.5" },
    { color: "#FEB019", text: "OT 2" },
    { color: "#FF4560", text: "OT 3" },
    { color: "#775DD0", text: `OT ${t("Total")}` },
  ];

  const [filter, setFilter] = useState({
    start: new Date(dayjs().startOf("month")),
    end: new Date(dayjs().endOf("month")),
  });


  const handleOpenAlert = () => {
    setOpenAlert(true);
    setIsFetching(false);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setOtType(1);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
  };

  const onChangeOT = (event) => {
    setOtType(event.target.value);
  };

  useEffect(() => {
    setIsFetching(true);
    dispatch(getAllEmployees());
    dispatch(getAffiliateOrganization());
    setIsFetching(false);
  }, []);

  // useEffect(() => {
  //   setIsFetching(true);
  //   if (selectedCompany && userProfile.roles.includes("ROLE_ADMIN")) {
  //     dispatch(
  //       getSummaryOvertimeShift({
  //         idCompany: selectedCompany.idCompany,
  //         start: dayjs(filter.start).format("YYYY-MM-DD"),
  //         end: dayjs(filter.end).format("YYYY-MM-DD"),
  //         role: "Admin",
  //       })
  //     );
  //   }
  //   setIsFetching(false);
  // }, [filter, selectedCompany, userProfile, otType]);


  // const fetchedAllOTWage = async () => {
  //   try {
  //     setIsFetching(true);
  //     const response = await getAllOTWage(
  //       dayjs(filter.start).locale('th').format("YYYY-MM"),
  //       selectedCompany.idCompany
  //     );
  //     console.log(response)
  //     if (response && response.data) {
  //       setIsFetching(false);
  //       setOtWage(response.data.result);
  //       setExportedData(response.data.resultXlsx);
  //     } else {
  //       handleChangeAlertType(`${t("NoData")}`);
  //       handleOpenAlert(true);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // const fetchedAllOTHour = async () => {
  //   try {
  //     setIsFetching(true);
  //     const response = await getAllOTHour(
  //       dayjs(filter.start).locale('th').format("YYYY-MM"),
  //       selectedCompany.idCompany
  //     );
  //     console.log(response)
  //     if (response && response.data) {
  //       setIsFetching(false);
  //       setOtHour(response.data.result);
  //       setExportedData2(response.data.resultXlsx);
  //     } else {
  //       handleChangeAlertType(`${t("NoData")}`);
  //       handleOpenAlert(true);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // useEffect(() => {
  //   if (selectedCompany && filter.start && otType && otType === 1) {
  //     fetchedAllOTWage()
  //   } else if (selectedCompany && filter.start && otType && otType === 2) {
  //     fetchedAllOTHour();
  //   } else {
  //     setIsFetching(false);
  //   }
  // }, [selectedCompany, filter, otType])

  useEffect(() => {
    if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      let ownCompany = affiliateOrganizationList.find((x) => x.idCompany === userProfile.idCompany);
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany);
    }
  }, [userProfile, affiliateOrganizationList]);

  const handleSearch = () => {
    if (selectedCompany) {
      setIsFetching(true);
      dispatch(
        getSummaryOvertimeShift({
          idCompany: selectedCompany.idCompany,
          start: dayjs(filter.start).format("YYYY-MM-DD"),
          end: dayjs(filter.end).format("YYYY-MM-DD"),
          role: userProfile.roles.includes("ROLE_ADMIN") ? "Admin" : "User",
        })
      );
      setIsFetching(false);
    }
  };

  const filteredData = ReportSummaryOvertimeShiftList || [];

  // const handleFileExportOTHour = async (year) => {
  //   if (exportedData && exportedData.length > 0 && otType === 1) {
  //     TotalWageOTxlsx(t,year, exportedData);
  //   } else if (exportedData2 && exportedData2.length > 0 && otType === 2) {
  //     TotalHoursOTxlsx(t,year, exportedData2);
  //   } else {
  //     handleOpenAlert();
  //     return;
  //   }
  // }

  const exportExcelWithRange = async (filteredData, employeeList, filter, otType) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("OT Report");

    const toBuddhistYear = (date) => {
      const year = date.year() + 543;
      return date.format(`MMMM ${year}`);
    };

    const monthsInRange = [];
    const startDate = dayjs(filter.start);
    const endDate = dayjs(filter.end);

    let current = startDate;
    while (current.isBefore(endDate) || current.isSame(endDate, "month")) {
      monthsInRange.push(toBuddhistYear(current));
      current = current.add(1, "month");
    }

    const headers = [
      t("EmployeeID"),
      t("FirstName"),
      t("LastName"),
      t("Position"),
      t("Company"),
      t("Department"),
      t("Section"),
      ...monthsInRange.map((month) =>
        otType === 1
          ? `OT ${t("Total")}${t("Baht")}${t("Month")} (${month})`
          : `OT ${t("Total")}${t("Unit.Hours")}${t("Month")} (${month})`
      ),
      ...monthsInRange.flatMap((month) => [
        otType === 1
          ? `${t("Specific")} OT 1 ${t("Total")}${t("Baht")}${t("Month")} (${month})`
          : `${t("Specific")} OT 1 ${t("Total")}${t("Unit.Hours")}${t("Month")} (${month})`,
        otType === 1
          ? `${t("Specific")} OT 1.5 ${t("Total")}${t("Baht")}${t("Month")} (${month})`
          : `${t("Specific")} OT 1.5 ${t("Total")}${t("Unit.Hours")}${t("Month")} (${month})`,
        otType === 1
          ? `${t("Specific")} OT 2 ${t("Total")}${t("Baht")}${t("Month")} (${month})`
          : `${t("Specific")} OT 2 ${t("Total")}${t("Unit.Hours")}${t("Month")} (${month})`,
        otType === 1
          ? `${t("Specific")} OT 3 ${t("Total")}${t("Baht")}${t("Month")} (${month})`
          : `${t("Specific")} OT 3 ${t("Total")}${t("Unit.Hours")}${t("Month")} (${month})`,
      ]),
    ];

    const headerRow = worksheet.addRow(headers);

    headerRow.height = 50;

    headerRow.eachCell((cell, colNumber) => {
      cell.font = { size: 12, name: "TH SarabunPSK", color: { argb: "FFFFFF" } };
      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "002060" },
      };

      const headerText = headers[colNumber - 1];
      worksheet.getColumn(colNumber).width = Math.max(headerText.length + 2, 12); // Add padding and minimum width
    });

    const enrichedData = filteredData
      .map((ot) => {
        const employee = employeeList.find((emp) => emp.idEmployees === ot.idEmployees);
        return employee
          ? {
            ...ot,
            firstname_TH: getUserFirstName(employee),
            lastname_TH: getUserLastName(employee),
            companyName: getUserCompany(employee),
            departmentName: employee.departmentName,
            sectionName: employee.sectionName,
          }
          : null;
      })
      .filter(Boolean);

    enrichedData.forEach((data) => {
      const monthlyTotals = monthsInRange.map((month) => {
        const monthItems = (data.schedule || []).filter((item) =>
          toBuddhistYear(dayjs(item.date)) === month
        );

        const monthlyData = monthItems.reduce(
          (acc, curr) => {
            curr.overTime.forEach((ot) => {
              if (otType === 1) {
                acc.OT_1 += parseFloat(ot.otOneAmount || 0);
                acc.OT_1_5 += parseFloat(ot.otOneFiveAmount || 0);
                acc.OT_2 += parseFloat(ot.otTwoAmount || 0);
                acc.OT_3 += parseFloat(ot.otThreeAmount || 0);
              } else if (otType === 2) {
                acc.OT_1 += parseFloat(ot.OT_1 || 0) / 60;
                acc.OT_1_5 += parseFloat(ot["OT_1.5"] || 0) / 60;
                acc.OT_2 += parseFloat(ot.OT_2 || 0) / 60;
                acc.OT_3 += parseFloat(ot.OT_3 || 0) / 60;
              }
            });
            return acc;
          },
          { OT_1: 0, OT_1_5: 0, OT_2: 0, OT_3: 0 }
        );

        monthlyData.TotalOT =
          monthlyData.OT_1 + monthlyData.OT_1_5 + monthlyData.OT_2 + monthlyData.OT_3;

        return monthlyData;
      });

      const rowData = [
        data.employeeID,
        data.firstname_TH,
        data.lastname_TH,
        data.positionName,
        data.companyName,
        data.departmentName,
        data.sectionName,
        ...monthlyTotals.map((monthData) => monthData.TotalOT.toFixed(2)),
        ...monthlyTotals.flatMap((monthData) => [
          monthData.OT_1.toFixed(2),
          monthData.OT_1_5.toFixed(2),
          monthData.OT_2.toFixed(2),
          monthData.OT_3.toFixed(2),
        ]),
      ];

      worksheet.addRow(rowData);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `OT_Report_${startDate.format("YYYY-MM-DD")}_to_${endDate.format("YYYY-MM-DD")}.xlsx`;
    a.click();
  };




  return (
    <div>
      <StyledRoot className="page">
        <Container maxWidth="lg">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={"/employees-report"}
            >
              {t("AllReports")}
            </Link>
            <Typography color="text.primary">{t("Dashboard")} OT</Typography>
          </Breadcrumbs>
          <div style={{ marginTop: "5vh" }}>
            <Typography variant="h4" style={{ paddingTop: 8 }}>
              {t("Dashboard")}
            </Typography>
          </div>

          <StyledWrapFilter>
            <Grid container spacing={2} columns={14} alignItems="center">

              <Grid item xs={3}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("StartDate")}
                  </Typography>
                  <div className="search-date">
                    <DatePickerCustom
                      inputFormat="D MMM YYYY"
                      value={filter.start}
                      onChange={(newValue) => {
                        if (dayjs(filter.end).diff(dayjs(newValue), 'day') > 60) {
                          setFilter(prev => ({
                            ...prev,
                            start: newValue,
                            end: dayjs(newValue).add(60, 'day'),
                          }));
                        } else {
                          setFilter(prev => ({
                            ...prev,
                            start: newValue
                          }));
                        }
                      }
                      }
                      views={["year", "month", "day"]}
                      disabled={isFetching}
                    />
                  </div>
                </StyledBoxSearch>
              </Grid>
              <Grid item xs={3}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("EndDate")}
                  </Typography>
                  <div className="search-date">
                    <DatePickerCustom
                      minDate={filter.start}
                      maxDate={dayjs(filter.start).add(60, 'day')}
                      inputFormat="D MMM YYYY"
                      value={filter.end}
                      onChange={(newValue) => {
                        setFilter(prev => ({
                          ...prev,
                          end: newValue
                        }));
                      }
                      }
                      views={["year", "month", "day"]}
                      disabled={isFetching}
                    />
                  </div>
                </StyledBoxSearch>
              </Grid>
              {/* <Grid item xs={3}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("SelectYear")}
                  </Typography>
                  <div className="search-date">
                    <DatePickerCustom
                      maxDate={dayjs()}
                      inputFormat="MMMM YYYY"
                      value={inputSearch}
                      name="start"
                      views={['year', 'month']}
                      openTo={'month'}
                      onChange={(newValue) => {
                        setInputSearch(newValue);
                      }}
                      disabled={isFetching}
                    />
                  </div>
                </StyledBoxSearch>
              </Grid>*/}
              <Grid item xs={3}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {`${t("Company")}`}
                  </Typography>
                  <div className="search-name">
                    <SelectAffiliateCompany
                      options={affiliateOrganizationList}
                      value={selectedCompany}
                      disabled={isFetching}
                      onChange={(_, value) => {
                        onChangeCompany(value);
                      }}
                    />
                  </div>
                </StyledBoxSearch>
              </Grid>
              <Grid item xs={2}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("Type")}
                  </Typography>
                  <FormControl>
                    <Select
                      value={otType}
                      onChange={onChangeOT}
                      popupIcon={<i class="fa-light fa-chevron-down"></i>}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        backgroundColor: "#919eab14",
                        borderRadius: 2,
                        minWidth: 120,
                      }}
                      disabled={isFetching}
                    >
                      <MenuItem disabled value="" style={{ display: "none" }}>
                        {t("SelectType")}
                      </MenuItem>
                      <MenuItem value={1}>OT ({t("AmountOfMoney")})</MenuItem>
                      <MenuItem value={2}>OT ({t("Unit.Hours")})</MenuItem>
                    </Select>
                  </FormControl>
                </StyledBoxSearch>
              </Grid>
              <Grid item xs={3}>
              <StyledBoxSearchButton>
              <div>
                <ButtonBlue
                  style={{ marginTop: "10px" }}
                  variant="contained"
                  type="submit"
                  onClick={handleSearch}
                  disabled={isFetching}
                >
                  {t("Search")}
                </ButtonBlue>
                </div>
                </StyledBoxSearchButton>
              </Grid>
              <Grid item xs={12} sm={2}>
                <StyledBoxSearchButton>
                  <div>
                    <ButtonBlue
                      style={{ marginTop: "10px" }}
                      variant="contained"
                      startIcon={<DownloadRoundedIcon />}
                      disabled={isFetching}
                      onClick={() =>
                        exportExcelWithRange(filteredData, employeeList, filter, otType)}
                    >
                      {t("Download")}
                    </ButtonBlue>
                  </div>
                </StyledBoxSearchButton>
              </Grid>
            </Grid>
          </StyledWrapFilter>

          {/* <CardStyle style={{ marginTop: "5vh" }}>
            <StyledDetailCard>
              <TableContainer component={Paper} style={{ marginTop: "3vh" }}>
                <DivEmployeesList>
                  {employeeList && (
                    <Fragment>
                      <div className="employee-list">
                        <div className="employee-item">
                          <div
                            style={{
                              height: "104px",
                              minHeight: "104px",
                            }}
                          >
                            <div
                              style={{
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography color="text.secondary">
                                {t("Employee")}
                                <span>{`(${employeeList.filter(
                                  (item) => !item.isTerminate
                                ).length
                                  } ${t("People")})`}</span>
                              </Typography>
                            </div>
                          </div>

                          <div className="employee-item">
                            {OTItems.map((item, index) => (
                              <div key={index} className="div-text-end">
                                <Typography variant="body2" align="center">
                                  {item.text}
                                </Typography>
                              </div>
                            ))}
                          </div>
                        </div>

                        {employeeList.filter((item) => !item.isTerminate).map((emp, index) => {
                          let summaryOT1 = 0;
                          let summaryOT15 = 0;
                          let summaryOT2 = 0;
                          let summaryOT3 = 0;
                          let summaryOT = 0;

                          if (otType === 1) {
                            const findIdEmp = otWage && otWage.find(x => x.idEmployees === emp.idEmployees);
                            summaryOT1 = findIdEmp ? (findIdEmp.monthlyData && findIdEmp.monthlyData.SUM_OT1 && Array.isArray(findIdEmp.monthlyData.SUM_OT1) ? findIdEmp.monthlyData.SUM_OT1.reduce((sum, cur) => (cur.value !== null ? cur.value + sum : sum), 0) : 0) : 0;
                            summaryOT15 = findIdEmp ? (findIdEmp.monthlyData && findIdEmp.monthlyData.SUM_OT15 && Array.isArray(findIdEmp.monthlyData.SUM_OT15) ? findIdEmp.monthlyData.SUM_OT15.reduce((sum, cur) => (cur.value !== null ? cur.value + sum : sum), 0) : 0) : 0;
                            summaryOT2 = findIdEmp ? (findIdEmp.monthlyData && findIdEmp.monthlyData.sumOT2 && Array.isArray(findIdEmp.monthlyData.sumOT2) ? findIdEmp.monthlyData.sumOT2.reduce((sum, cur) => (cur.value !== null ? cur.value + sum : sum), 0) : 0) : 0;
                            summaryOT3 = findIdEmp ? (findIdEmp.monthlyData && findIdEmp.monthlyData.sumOT3 && Array.isArray(findIdEmp.monthlyData.sumOT3) ? findIdEmp.monthlyData.sumOT3.reduce((sum, cur) => (cur.value !== null ? cur.value + sum : sum), 0) : 0) : 0;
                            summaryOT = findIdEmp ? (findIdEmp.monthlyData && findIdEmp.monthlyData.totalOT && Array.isArray(findIdEmp.monthlyData.totalOT) ? findIdEmp.monthlyData.totalOT.reduce((sum, cur) => (cur.value !== null ? cur.value + sum : sum), 0) : 0) : 0;
                          } else {
                            const findIdEmp = otHour && otHour.find(x => x.idEmployees === emp.idEmployees);
                            summaryOT1 = findIdEmp ? (findIdEmp.monthlyData && findIdEmp.monthlyData.TOTAL_OT1 && Array.isArray(findIdEmp.monthlyData.TOTAL_OT1) ? findIdEmp.monthlyData.TOTAL_OT1.reduce((sum, cur) => (cur.value !== null ? cur.value + sum : sum), 0) : 0) : 0;
                            summaryOT15 = findIdEmp ? (findIdEmp.monthlyData && findIdEmp.monthlyData.TOTAL_OT15 && Array.isArray(findIdEmp.monthlyData.TOTAL_OT15) ? findIdEmp.monthlyData.TOTAL_OT15.reduce((sum, cur) => (cur.value !== null ? cur.value + sum : sum), 0) : 0) : 0;
                            summaryOT2 = findIdEmp ? (findIdEmp.monthlyData && findIdEmp.monthlyData.TOTAL_OT2 && Array.isArray(findIdEmp.monthlyData.TOTAL_OT2) ? findIdEmp.monthlyData.TOTAL_OT2.reduce((sum, cur) => (cur.value !== null ? cur.value + sum : sum), 0) : 0) : 0;
                            summaryOT3 = findIdEmp ? (findIdEmp.monthlyData && findIdEmp.monthlyData.TOTAL_OT3 && Array.isArray(findIdEmp.monthlyData.TOTAL_OT3) ? findIdEmp.monthlyData.TOTAL_OT3.reduce((sum, cur) => (cur.value !== null ? cur.value + sum : sum), 0) : 0) : 0;
                            summaryOT = findIdEmp ? (findIdEmp.monthlyData && findIdEmp.monthlyData.totalOT && Array.isArray(findIdEmp.monthlyData.totalOT) ? findIdEmp.monthlyData.totalOT.reduce((sum, cur) => (cur.value !== null ? cur.value + sum : sum), 0) : 0) : 0;
                          }

                          return (
                            <div className="employee-item" key={index + emp.firstname_TH + emp.lastname_TH}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <Avatar
                                  className="MuiAvatar-root"
                                  key={index}
                                  alt={emp.email}
                                  src={emp.imageProfile}
                                />
                                <Typography>{getUserFullName(emp)}</Typography>
                              </div>

                              <div className="employee-item">
                                <div className="div-text-end">
                                  <Typography variant="body2" align="center">
                                    {summaryOT1.toFixed(2)}
                                  </Typography>
                                </div>
                                <div className="div-text-end">
                                  <Typography variant="body2" align="center">
                                    {summaryOT15.toFixed(2)}
                                  </Typography>
                                </div>
                                <div className="div-text-end">
                                  <Typography variant="body2" align="center">
                                    {summaryOT2.toFixed(2)}
                                  </Typography>
                                </div>
                                <div className="div-text-end">
                                  <Typography variant="body2" align="center">
                                    {summaryOT3.toFixed(2)}
                                  </Typography>
                                </div>
                                <div className="div-text-end">
                                  <Typography variant="body2" align="center">
                                    {summaryOT.toFixed(2)}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </Fragment>
                  )}
                </DivEmployeesList>
              </TableContainer>
            </StyledDetailCard>
          </CardStyle>

          {otHour && otHour.length > 0 && otType === 2 && !isFetching ? (
            <Grid
              container
              spacing={3}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "stretch",
              }}
            >
              <Grid item xs={12} md={8}>
                <CardStyle style={{ marginTop: "5vh" }}>
                  <StyledDetailCard>
                    <ChartOT otData={otHour} />
                  </StyledDetailCard>
                </CardStyle>
              </Grid>

              <Grid item xs={12} md={4}>
                <CardStyle style={{ marginTop: "5vh" }}>
                  <StyledDetailCard style={{ height: "100%" }}>
                    <Typography color="text.primary" style={{ marginTop: "3vh" }}>
                      {t("Total")} OT
                    </Typography>
                    <Grid
                      container
                      direction="column"
                      spacing={2}
                      style={{ marginTop: "2vh" }}
                    >
                      {IconItems.map((item, index) => (
                        <Grid item key={index}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            gap="5px"
                            border="1px solid #ccc"
                            borderRadius="8px"
                            boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
                            p="6px"
                          >
                            <ListItem>
                              <ListItemIcon>
                                <WorkIcon style={{ color: item.color }} />
                              </ListItemIcon>
                              <ListItemText primary={item.text} />
                            </ListItem>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </StyledDetailCard>
                </CardStyle>
              </Grid>
            </Grid>
          ) : null} */}
          {filteredData && filteredData.length > 0  ? (
          <CardStyle style={{ marginTop: "5vh" }}>
            <StyledDetailCard>
              <TableContainer component={Paper} style={{ marginTop: "3vh" }}>
                <DivEmployeesList>
                  {filteredData && Array.isArray(filteredData) && (
                    <Fragment>
                      <div className="employee-list">
                        <div className="employee-item">
                          <div
                            style={{
                              height: "104px",
                              minHeight: "104px",
                            }}
                          >
                            <div
                              style={{
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography color="text.secondary">
                                {t("Employee")}
                                <span>{`(${filteredData.length} ${t("People")})`}</span>
                              </Typography>
                            </div>
                          </div>

                          <div className="employee-item">
                            {Array.isArray(OTItems) &&
                              OTItems.map((item, index) => (
                                <div key={index} className="div-text-end">
                                  <Typography variant="body2" align="center">
                                    {item.text}
                                  </Typography>
                                </div>
                              ))}
                          </div>
                        </div>

                        {filteredData.map((ot, index) => {
                          if (!ot || !employeeList || !Array.isArray(employeeList)) return null;

                          const employee = employeeList.find(
                            (emp) => emp && emp.idEmployees === ot.idEmployees
                          );

                          if (!employee) return null;

                          let summaryOT1 = 0;
                          let summaryOT15 = 0;
                          let summaryOT2 = 0;
                          let summaryOT3 = 0;
                          let summaryOT = 0;

                          if (otType === 1) {
                            summaryOT1 = ot.otOneAmount ? parseFloat(ot.otOneAmount) : 0;
                            summaryOT15 = ot.otOneFiveAmount
                              ? parseFloat(ot.otOneFiveAmount)
                              : 0;
                            summaryOT2 = ot.otTwoAmount ? parseFloat(ot.otTwoAmount) : 0;
                            summaryOT3 = ot.otThreeAmount ? parseFloat(ot.otThreeAmount) : 0;
                            summaryOT = ot.otAmountTotal ? parseFloat(ot.otAmountTotal) : 0;
                          } else if (otType === 2) {
                            summaryOT1 = ot.otOneHours ? parseFloat(ot.otOneHours) : 0;
                            summaryOT15 = ot.otOneFiveHours
                              ? parseFloat(ot.otOneFiveHours)
                              : 0;
                            summaryOT2 = ot.otTwoHours ? parseFloat(ot.otTwoHours) : 0;
                            summaryOT3 = ot.otThreeHours ? parseFloat(ot.otThreeHours) : 0;
                            summaryOT = ot.otHoursTotal ? parseFloat(ot.otHoursTotal) : 0;
                          }
                          return (
                            <div
                              className="employee-item"
                              key={
                                index +
                                (getUserFullName(employee))
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <Avatar
                                  className="MuiAvatar-root"
                                  key={index}
                                  alt={employee.email || "Unknown"}
                                  src={employee.imageProfile || ""}
                                />
                                <Typography>{getUserFullName(employee) || "Unnamed Employee"}</Typography>
                              </div>

                              <div className="employee-item">
                                <div className="div-text-end">
                                  <Typography variant="body2" align="center">
                                    {summaryOT1.toFixed(2)}
                                  </Typography>
                                </div>
                                <div className="div-text-end">
                                  <Typography variant="body2" align="center">
                                    {summaryOT15.toFixed(2)}
                                  </Typography>
                                </div>
                                <div className="div-text-end">
                                  <Typography variant="body2" align="center">
                                    {summaryOT2.toFixed(2)}
                                  </Typography>
                                </div>
                                <div className="div-text-end">
                                  <Typography variant="body2" align="center">
                                    {summaryOT3.toFixed(2)}
                                  </Typography>
                                </div>
                                <div className="div-text-end">
                                  <Typography variant="body2" align="center">
                                    {summaryOT.toFixed(2)}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Fragment>
                  )} 

                </DivEmployeesList>
              </TableContainer>
            </StyledDetailCard>
          </CardStyle>): (
          <Typography>No data available</Typography>
        )}

          {filteredData && filteredData.length > 0 && otType === 2 && !isFetching ? (
            <Grid
              container
              spacing={3}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "stretch",
              }}
            >
              <Grid item xs={12} md={8}>
                <CardStyle style={{ marginTop: "5vh" }}>
                  <StyledDetailCard>
                    <ChartOT otData={filteredData} />
                  </StyledDetailCard>
                </CardStyle>
              </Grid>

              <Grid item xs={12} md={4}>
                <CardStyle style={{ marginTop: "5vh" }}>
                  <StyledDetailCard style={{ height: "100%" }}>
                    <Typography color="text.primary" style={{ marginTop: "3vh" }}>
                      {t("Total")} OT
                    </Typography>
                    <Grid
                      container
                      direction="column"
                      spacing={2}
                      style={{ marginTop: "2vh" }}
                    >
                      {IconItems.map((item, index) => (
                        <Grid item key={index}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            gap="5px"
                            border="1px solid #ccc"
                            borderRadius="8px"
                            boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
                            p="6px"
                          >
                            <ListItem>
                              <ListItemIcon>
                                <WorkIcon style={{ color: item.color }} />
                              </ListItemIcon>
                              <ListItemText primary={item.text} />
                            </ListItem>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </StyledDetailCard>
                </CardStyle>
              </Grid>
            </Grid>
          ) : null}

          {/* <div>
            <FormControl fullWidth>
              <InputLabel>View Mode</InputLabel>
              <Select
                value={viewMode}
                onChange={(e) => setViewMode(e.target.value)}
              >
                <MenuItem value="OT Hours">OT Hours</MenuItem>
                <MenuItem value="OT Amount">OT Amount</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div style={{ alignSelf: "flex-end" }}>
            <ButtonBlue variant="contained" onClick={handleSearch}>
              Search
            </ButtonBlue>
          </div>
        <Typography variant="h6" style={{ marginTop: "24px" }}>
          {viewMode}
        </Typography> */}



          {/* {filteredData.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Employee ID</TableCell>
                  <TableCell>Full Name (TH)</TableCell>
                  <TableCell>Full Name (EN)</TableCell>
                  {viewMode === "OT Hours" && (
                    <>
                      <TableCell>OT One Hours</TableCell>
                      <TableCell>OT One.Five Hours</TableCell>
                      <TableCell>OT Two Hours</TableCell>
                      <TableCell>OT Three Hours</TableCell>
                      <TableCell>Total OT Hours</TableCell>
                    </>
                  )}
                  {viewMode === "OT Amount" && (
                    <>
                      <TableCell>OT One Amount</TableCell>
                      <TableCell>OT One.Five Amount</TableCell>
                      <TableCell>OT Two Amount</TableCell>
                      <TableCell>OT Three Amount</TableCell>
                      <TableCell>Total OT Amount</TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.employeeID}</TableCell>
                    <TableCell>{item.fullname_TH}</TableCell>
                    <TableCell>{item.fullname_EN}</TableCell>
                    {viewMode === "OT Hours" && (
                      <>
                        <TableCell>{item.otOneHours || 0}</TableCell>
                        <TableCell>{item.otOneFiveHours || 0}</TableCell>
                        <TableCell>{item.otTwoHours || 0}</TableCell>
                        <TableCell>{item.otThreeHours || 0}</TableCell>
                        <TableCell>{item.otHoursTotal || 0}</TableCell>
                      </>
                    )}
                    {viewMode === "OT Amount" && (
                      <>
                        <TableCell>{item.otOneAmount || 0}</TableCell>
                        <TableCell>{item.otOneFiveAmount || 0}</TableCell>
                        <TableCell>{item.otTwoAmount || 0}</TableCell>
                        <TableCell>{item.otThreeAmount || 0}</TableCell>
                        <TableCell>{item.otAmountTotal || 0}</TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No data available</Typography>
        )}  */}
        </Container>
        <AlertResponse
          open={openAlert}
          handleClose={handleCloseAlert}
          alertType={alertType}
        />
      </StyledRoot>
    </div >
  );
};

export default DashboardOT;
