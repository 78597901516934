import React, { Fragment, useState, useEffect } from "react";
import { Autocomplete, Avatar, Box, Container, FilledInput, FormControl, Grid, IconButton, InputAdornment, Link, Menu, MenuItem, ToggleButton, ToggleButtonGroup, Typography, styled } from "@mui/material"
import { Controller, useForm } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import ButtonBlue from "../../../pages/shared/general/ButtonBlue";
import TextFieldTheme from "../../../pages/shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliateOrganization } from "../../../../actions/company";
import { getManager } from "../../../../actions/manager";

const StyledBoxSearch = styled(Box)({
    "& .label": {
      fontWeight: 600,
      fontSize: 14,
      marginBottom: 8,
    },
  });

const StyledFilledInput = styled(FilledInput)({
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      paddingTop: 18,
      paddingBottom: 18,
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  });

const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    border: 0,
    "& .MuiFilledInput-root": {
      backgroundColor: "#919eab14",
      height: 56,
      padding: "0px 12px",
      borderRadius: 8,
      "&.Mui-focused": {
        backgroundColor: "#919eab14",
      },
      "& .MuiInputAdornment-root": {
        width: 32,
        marginTop: "0!important",
        fontSize: 24,
        color: "#919EAB",
        "& i": {
          marginRight: 8,
        },
      },
      "& .MuiAutocomplete-endAdornment": {
        "& .MuiButtonBase-root": {
          fontSize: 14,
          width: 22,
          height: 22,
        },
      },
      "&:hover": {
        backgroundColor: "#919eab29",
        "&:before": {
          border: "none !important",
        },
      },
      "&::after": {
        border: "none",
      },
      "&::before": {
        border: "none",
      },
    },
  });

  const EmployeeSearch = ({
    onSearch,
    businessUnits,
    divisions,
    departments,
    sections,
    positions,
  }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [moreFilter, setMoreFilter] = useState(false);
  const [isTerminate, setIsTerminate] = useState(0);

  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);
  const { result: manager } = useSelector(state => state.manager);

  const useHookForm = useForm({
    defaultValues: {
      name: "",
      company: { idCompany: "all", companyName: `${t("All")}`, companyName_EN: t("All") },
      businessUnit: { idBusinessUnit: "all", businessUnitName: `${t("All")}`, businessUnitName_EN: t("All") },
      division: { idDivision: "all", divisionName: `${t("All")}`, divisionName_EN: t("All") },
      department: { idDepartment: "all", departmentName: `${t("All")}`, departmentName_EN: t("All") },
      section: { idSection: "all", sectionName: `${t("All")}`, sectionName_EN: t("All") },
      position: { idPosition: "all", positionName: `${t("All")}`, positionName_EN: t("All") },
      manager: { idEmployees: "all", firstname_TH: `${t("All")}`, lastname_TH: "" },
    },
    mode: "all",
  });

  useEffect(() => {
    dispatch(getManager());
    dispatch(getAffiliateOrganization());
  }, [dispatch]);

  const handleSearchSubmit = (data) => {
    onSearch(data);
  };

  const handleChangeFilterEmployee = (event, newStatus) => {
    if (newStatus !== null) {
      setIsTerminate(Number(newStatus));
      useHookForm.handleSubmit(handleSearchSubmit)();
    }
  };

  return (
    <form onSubmit={useHookForm.handleSubmit(handleSearchSubmit)}>
      <Grid container spacing={2}>
      <Grid item xs={12} container justifyContent="flex-end" alignItems="end">
        <Link component="button" type="button" variant="body2" onClick={() => setMoreFilter(!moreFilter)}>
            {moreFilter ? t("HideSearch") : t("SearchMore")}
        </Link>
        </Grid>

        {/* Name Search Field */}
        <Grid item xs={12} md={6} sx={{ marginTop: '-30px' }}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              {t("Search")}
            </Typography>
            <Controller
              name="name"
              control={useHookForm.control}
              render={({ field }) => (
                <FormControl fullWidth variant="filled">
                  <StyledFilledInput
                    {...field}
                    placeholder={`${t("Search")}${t("FullName")}`}
                    name="search"
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              )}
            />
          </StyledBoxSearch>
        </Grid>

        {/* Company Field */}
        <Grid item xs={12} md={6} sx={{ marginTop: '-30px' }}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              {t("Company")}
            </Typography>
            <Controller
              name="company"
              control={useHookForm.control}
              render={({ field }) => (
                <StyledAutocomplete
                  {...field}
                  options={[
                    { idCompany: "all", companyName: `${t("All")}`, companyName_EN: `${t("All")}` },
                    ...(affiliateOrganizationList ? affiliateOrganizationList : []),
                  ]}
                  getOptionLabel={(option) =>
                    i18n.resolvedLanguage === "th" ? option.companyName : option.companyName_EN
                  }
                  isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      variant="filled"
                      placeholder={t("Company")}
                    />
                  )}
                  onChange={(_, value) => field.onChange(value)}
                  disableClearable
                />
              )}
            />
          </StyledBoxSearch>
        </Grid>

      
        {/* Business Unit Field */}
        {moreFilter && (
          <Fragment>
            <Grid item xs={12} md={4} sx={{ marginTop: '-10px' }}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("BusinessUnit")}
                </Typography>
                <Controller
                  name="businessUnit"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <StyledAutocomplete
                      {...field}
                      options={[
                        { idBusinessUnit: "all", businessUnitName: `${t("All")}` },
                        ...businessUnits.map((businessUnitName) => ({
                          idBusinessUnit: businessUnitName,
                          businessUnitName,
                        })),
                      ]}
                      getOptionLabel={(option) => option.businessUnitName}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          variant="filled"
                          placeholder={t("BusinessUnit")}
                        />
                      )}
                      onChange={(_, value) => field.onChange(value)}
                      disableClearable
                    />
                  )}
                />
              </StyledBoxSearch>
            </Grid>

            {/* Division Field */}
            <Grid item xs={12} md={4} sx={{ marginTop: '-10px' }}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("Division")}
                </Typography>
                <Controller
                  name="division"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <StyledAutocomplete
                      {...field}
                      options={[
                        { idDivision: "all", divisionName: `${t("All")}` },
                        ...divisions.map((divisionName) => ({
                          idDivision: divisionName,
                          divisionName,
                        })),
                      ]}
                      getOptionLabel={(option) => option.divisionName}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          variant="filled"
                          placeholder={t("Division")}
                        />
                      )}
                      onChange={(_, value) => field.onChange(value)}
                      disableClearable
                    />
                  )}
                />
              </StyledBoxSearch>
            </Grid>

            {/* Department Field */}
            <Grid item xs={12} md={4} sx={{ marginTop: '-10px' }}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("Department")}
                </Typography>
                <Controller
                  name="department"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <StyledAutocomplete
                      {...field}
                      options={[
                        { idDepartment: "all", departmentName: `${t("All")}` },
                        ...departments.map((departmentName) => ({
                          idDepartment: departmentName,
                          departmentName,
                        })),
                      ]}
                      getOptionLabel={(option) => option.departmentName}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          variant="filled"
                          placeholder={t("Department")}
                        />
                      )}
                      onChange={(_, value) => field.onChange(value)}
                      disableClearable
                    />
                  )}
                />
              </StyledBoxSearch>
            </Grid>

            {/* Section Field */}
            <Grid item xs={12} md={4} sx={{ marginTop: '-10px' }}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("Section")}
                </Typography>
                <Controller
                  name="section"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <StyledAutocomplete
                      {...field}
                      options={[
                        { idSection: "all", sectionName: `${t("All")}` },
                        ...sections.map((sectionName) => ({
                          idSection: sectionName,
                          sectionName,
                        })),
                      ]}
                      getOptionLabel={(option) => option.sectionName}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          variant="filled"
                          placeholder={t("Section")}
                        />
                      )}
                      onChange={(_, value) => field.onChange(value)}
                      disableClearable
                    />
                  )}
                />
              </StyledBoxSearch>
            </Grid>

            {/* Position Field */}
            <Grid item xs={12} md={4} sx={{ marginTop: '-10px' }}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("Position")}
                </Typography>
                <Controller
                  name="position"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <StyledAutocomplete
                      {...field}
                      options={[
                        { idPosition: "all", positionName: `${t("All")}` },
                        ...positions.map((positionName) => ({
                          idPosition: positionName,
                          positionName,
                        })),
                      ]}
                      getOptionLabel={(option) => option.positionName}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          variant="filled"
                          placeholder={t("Position")}
                        />
                      )}
                      onChange={(_, value) => field.onChange(value)}
                      disableClearable
                    />
                  )}
                />
              </StyledBoxSearch>
            </Grid>


            {/* Manager Field */}
            <Grid item xs={12} md={4} sx={{ marginTop: '-10px' }}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("Manager")}
                </Typography>
                <Controller
                  name="manager"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <StyledAutocomplete
                      {...field}
                      options={[
                        { idEmployees: "all", firstname_TH: `${t("All")}`, lastname_TH: "" },
                        ...(manager ? manager : [])
                      ]}
                      getOptionLabel={(option) => `${option.firstname_TH}${option.lastname_TH ? " " + option.lastname_TH : ""}`}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          variant="filled"
                          placeholder={t("Manager")}
                        />
                      )}
                      onChange={(_, value) => field.onChange(value)}
                      disableClearable
                    />
                  )}
                />
              </StyledBoxSearch>
            </Grid>
          </Fragment>
        )}

        <Grid item xs={12} container justifyContent="flex-end">
          <ButtonBlue variant="contained" type="submit">
            {t("Search")}
          </ButtonBlue>
        </Grid>
      </Grid>
    </form>
  );
};

export default EmployeeSearch;
