import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";

import { Typography, Breadcrumbs, Container } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EmployeeTax from "./employee-tax";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});
const PersonalTaxAdminPage = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to={"/employees-report"}
          >
            {`${t("AllReports")}`}
          </Link>
          <Typography color="text.primary">{t("Tax")}</Typography>
        </Breadcrumbs>
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            {t("Tax")}
          </Typography>
        </div>
        <EmployeeTax />
      </Container>
    </StyledRoot>
  );
};

export default PersonalTaxAdminPage;
