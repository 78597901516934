import { httpClient } from "./httpClient";

const getAllProgressOrgPlan = () => {
  return httpClient.get("progressOrgPlan");
};

const postProgressOrgPlan = (data) => {
  return httpClient.post(`progressOrgPlan/postProgressOrgPlan`, data);
};

export default {
  getAllProgressOrgPlan,
  postProgressOrgPlan,
};
