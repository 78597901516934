import React, { useEffect, useState } from "react";
import { styled } from "@mui/styles";
import { Container } from "@mui/material";
import OverviewDashboard from "./OverviewDashboard";
import IdvDashboardPlanList from "./IdvDashboardPlanList";
import TeamGraphDashboard from "./TeamGraphDashboard";
import EmployeeInfo from "../../../shared/pages/okrs/components/EmployeeInfo";

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import  {LocalizationProvider}  from "@mui/x-date-pickers/LocalizationProvider";
import  {DatePicker}  from "@mui/x-date-pickers/DatePicker";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import GaugeChart from "react-gauge-chart";
import FindInPageIcon from "@mui/icons-material/FindInPage";

import { useSelector, useDispatch } from "react-redux";
import { getUserProfile } from "../../../../../actions/user";
import { getAllIdvPlan } from "../../../../../actions/IndividualPlans";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      height: "50px",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingTop: 16,
      paddingBottom: 16,
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiGrid-justify-xs-flex-end": {
      
    },
    "& .MuiFormControl-marginNormal": {
      marginTop: 8,
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& .MuiTypography-h2": {
      fontWeight: "500",
      fontSize: "4rem",
    },
    "& .MuiTypography-h6": {
      color: "#757575",
    },
    "&  .MuiToolbar-gutters": {
      
    },
  },
  formControl: {
    margin: 8,
    minWidth: 120,
  },
  searchBtn: {
    marginLeft: 16,
    borderRadius: 8,
    height: 48,
    padding: 8,
  },
  scoreCard: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "10px",
    minHeight: "620px",
    padding: 32,
    marginTop: "20px",
  },
  planListCard: {
    borderRadius: "10px",
    width: "100%",
    minHeight: "300px",
    paddingBottom: 40,
  },
  doughnutChart: {
    display: "initial !important",
    height: "450px !important",
    width: "450px !important",
    
  },
  noData_Section: {
    textAlign: "center",
    marginTop: 40,
    color: "#222f3e",
  },
  noDataSvg: {
    fontSize: "3.5rem",
  },
  noDataText: {
    fontWeight: "600",
    fontSize: "18px",
  },
  imageEmp: {
    width: 160,
    height: 160,
    borderRadius: "50%",
    marginBottom: 24,
    
  },
}));

const quater = [
  { id: "Q1", title: "Q1" },
  { id: "Q2", title: "Q2" },
  { id: "Q3", title: "Q3" },
  { id: "Q4", title: "Q4" },
];

const chartData = {
  labels: ["Achieved", "Not Achieved"],
  datasets: [
    {
      data: [0, 0],
      backgroundColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
      offset: 1,
    },
  ],
};

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

function IndividualDashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: idvPlanStore } = useSelector((state) => state.IdvPlans);

  const [employeeId, setEmployeeId] = useState(0);
  const [dateSelect, setDateSelect] = useState(new Date());
  const [quaterSelect, setQuaterSelect] = useState("Q1");
  const [yearSelect, setYearSelect] = useState(0);
  const [records, setRecords] = useState([]);
  const [doughnutChart, setDoughnutChart] = useState(chartData);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState({
    objective: 0,
    keyresult: 0,
    acheive: 0,
    moonShot: 0,
  });

  ChartJS.register(ArcElement, Tooltip, Legend);

  const fetchData = (year, quater) => {
    setQuaterSelect(quater);
  };

  const handleOnClick = () => {
    const year = dateSelect.getFullYear();
    fetchData(year, quaterSelect);
  };

  useEffect(() => {
    if (idvPlanStore) {
      let idvPlans = idvPlanStore.filter((x) => x.createByEmpId === employeeId);

      let planItem = idvPlans.filter(function (item) {
        let dateItem = new Date(item.dateCreate);
        let yearItem = dateItem.getFullYear();
        return (
          item.planStatus == "Approved" &&
          item.quater == quaterSelect &&
          yearItem == yearSelect
        );
      });

      setRecords(planItem);

      //Count
      const totalOKRs = planItem.filter((x) => x.planStatus == "Approved");

      let totalAchieved = 0;
      let totalNotAchieved = 0;
      let totalMoonShot = 0;

      planItem.forEach((item) => {
        if (item.achieveResult == "Achieved") {
          totalAchieved++;
        }
        if (item.achieveResult == "Not Achieved") {
          totalNotAchieved++;
        }
        if (item.moonShotKR1 === 1) {
          totalMoonShot++;
        }
        if (item.moonShotKR2 === 1) {
          totalMoonShot++;
        }
        if (item.moonShotKR3 === 1) {
          totalMoonShot++;
        }
      });

      //Count Key Result
      let totalKR = 0;

      totalOKRs.forEach((x) => {
        if (x.keyResult1) {
          totalKR += 1;
        }

        if (x.keyResult2) {
          totalKR += 1;
        }

        if (x.keyResult3) {
          totalKR += 1;
        }
      });

      setTotal({
        objective: totalOKRs.length,
        keyresult: totalKR,
        acheive: totalAchieved + totalNotAchieved,
        moonShot: totalMoonShot,
      });

      let chart = doughnutChart;

      //Update Array Doughnut Chart [Achieved]
      chart.datasets[0].data[0] = totalAchieved;
      //Update Array Doughnut Chart [Not Achieved]
      chart.datasets[0].data[1] = totalNotAchieved;

      setDoughnutChart({ ...chart });
      setIsLoading(false);
    }
  }, [idvPlanStore]);

  useEffect(() => {
    if (userProfile) {
      setEmployeeId(userProfile.idEmployees);
    }
  }, [userProfile]);

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserProfile(currentUser.username));
      dispatch(getAllIdvPlan());
    }

    const year = dateSelect.getFullYear();
    const month = dateSelect.getMonth() + 1;
    let quater = "";
    if (month <= 3) {
      quater = "Q1";
    } else if (month <= 6) {
      quater = "Q2";
    } else if (month <= 9) {
      quater = "Q3";
    } else if (month <= 12) {
      quater = "Q4";
    }

    setQuaterSelect(quater);
    setYearSelect(year);
    fetchData(year, quater);
  }, []);

  return (
    <ContainerStyled>
      <div className={classes.root}>
        <div className="card-section">
          <div className="header-section">
            <Grid container>
              <Grid item>
                <h3 className="header-topic">Dashboard & History Individual</h3>
              </Grid>
            </Grid>
          </div>
          <Toolbar>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    margin="normal"
                    variant="inline"
                    inputVariant="outlined"
                    label="Year"
                    value={dateSelect}
                    views={["year"]}
                    onChange={(date) => setDateSelect(date)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl}>
                  <InputLabel>Quater</InputLabel>
                  <Select
                    label="Quater"
                    value={quaterSelect}
                    onChange={(e) => setQuaterSelect(e.target.value)}
                  >
                    {quater.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        style={{ display: "grid" }}
                      >
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.searchBtn}
                  onClick={handleOnClick}
                >
                  <SearchIcon /> View
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </div>
        {employeeId && <EmployeeInfo idEmployees={employeeId} />}
        {isLoading ? (
          <LinearProgress />
        ) : (
          <div>
            <OverviewDashboard total={total} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Paper className={classes.scoreCard}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            style={{ color: "#222f3e" }}
                            gutterBottom
                          >
                            Average Score
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <GaugeChart
                            nrOfLevels={420}
                            arcsLength={[0.2, 0.5, 0.3]}
                            colors={["#47c2c2", "#ffcd56", "#ff5722"]}
                            percent={0.37} //Score Percent
                            arcPadding={0.02}
                            textColor="#222f3e"
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Paper className={classes.scoreCard}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            style={{ color: "#222f3e" }}
                            gutterBottom
                          >
                            Achieve
                          </Typography>
                        </Grid>
                        <Grid container justifyContent="center" item xs={12}>
                          {doughnutChart.datasets[0].data[0] == 0 &&
                          doughnutChart.datasets[0].data[1] == 0 ? (
                            <div className={classes.noData_Section}>
                              <FindInPageIcon className={classes.noDataSvg} />
                              <p className={classes.noDataText}>No Data</p>
                            </div>
                          ) : (
                            <Doughnut
                              className={classes.doughnutChart}
                              data={chartData}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Paper className={classes.planListCard}>
                  <IdvDashboardPlanList records={records} />
                </Paper>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Paper className={classes.scoreCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        style={{ color: "#222f3e" }}
                        gutterBottom
                      >
                        Graph
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TeamGraphDashboard
                        typePlan="individual"
                        records={records}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </ContainerStyled>
  );
}

export default IndividualDashboard;
