import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextFieldTheme from "../../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../../shared/general/ButtonBlue";
import TimeTable from "../timeTable";
import { getAllPositions } from "../../../../../../../actions/positions";
import { useDispatch, useSelector } from "react-redux";
import { getEmploymentTypeByidCompany } from "../../../../../../../actions/employment-type";
import { getUserPosition } from "../../../../../../../utils/userData";
import { getLevelByidCompany } from "../../../../../../../actions/level";
import { useTranslation } from "react-i18next";
import {
  addAdditionSpecial2,
  addAdditionSpecial2New,
  getAdditionSpecial2,
  updateAdditionSpecial2New,
} from "../../../../../../../actions/additionSpecial";
import { openNotificationAlert } from "../../../../../../../actions/notificationAlert";
import InputNumber from "../inputNumber";


const AS2Form = ({insert, handleClose, selectedCompany, selectAddition }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: employmentType } = useSelector((state) => state.employmentType);
  const { result: positions } = useSelector((state) => state.positions);
  const { result: level } = useSelector((state) => state.level);

  const [levels, setLevels] = useState([]);

  const [checked, setChecked] = useState({
    employmentTypes: selectAddition && selectAddition.employmentTypes && selectAddition.employmentTypes.length === 0,
    positions: selectAddition && selectAddition.positions && selectAddition.positions.length === 0,
    groupLevels:
      (selectAddition && selectAddition.groupLevels && selectAddition.groupLevels.length === 0) &&
      (selectAddition && selectAddition.levels && selectAddition.levels.length === 0),
    Levels: selectAddition && selectAddition.levels && selectAddition.levels.length > 0,
  });  

  const validateSchema = yup.object({
    name: yup.string().max(500).required(t("ThisFieldIsRequired")),
    type: yup.string().required(t("ThisFieldIsRequired")),
    min: yup
      .number()
      .transform((val, orig) => (orig === "" ? undefined : val))
      .min(1, `${t("Minimum")} 1`)
      .required(t("ThisFieldIsRequired")),
    max: yup
      .number()
      .transform((val, orig) => (orig === "" ? undefined : val))
      .nullable()
      .min(0, `${t("Minimum")} 1`)
      .test("max-greater-than-min", `${t("PleaseEnterValueMoreThanOrEqual")} ${t('Minimum')}`, function (value) {
        const { min } = this.parent;
        if (value === null) return true; 
        return value > min;
      }),
    probation: yup.boolean(),
    groupLevels: yup.array().of(
      yup.object({
        idGroupLevel: yup.string().required(),
        groupLevelName: yup.string().required(),
      })
    ),
    levels: yup.array().of(
      yup.object({
        idLevel: yup.string().required(),
        levelName: yup.string().required(),
        price: yup.number().transform((val, orig) => (orig === "" ? undefined : val)),
      })
    ),
    positions: yup.array().of(
      yup.object({
        idPosition: yup.string().required(),
        positionName: yup.string().required(),
      })
    ),
    employmentTypes: yup.array().of(
      yup.object({
        idEmploymentType: yup.string().required(),
        employmentTypeName: yup.string().required(),
      })
    ),
  });


  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      name: "",
      type: "",
      min: null,
      max: null,
      groupLevels: [],
      levels: [],
      positions: [],
      employmentTypes: [],
      probation: false
    },
    resolver: yupResolver(validateSchema),
  });

  const handleChange = (event) => {
    setChecked((prev) => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));
    if (event.target.name === 'Levels') {
      setChecked((prev) => ({
        ...prev,
        ['groupLevels']: false,
      }));
    }
    setValue(event.target.name, event.target.name === 'type' ? 'all' : [])
  };

  const onSubmit = async (data) => {
    data.idCompany = selectedCompany.idCompany;
    data.groupLevels = data.groupLevels.map((e) => e.idGroupLevel);
    data.groupLevelsStatus = checked.groupLevels;
    data.positions = data.positions.map((e) => e.idPosition);
    data.employmentTypes = data.employmentTypes.map((e) => e.idEmploymentType);
    data.amount = checked.Levels ? 0 :data.amount
    let res 
    if(!insert){
      res = await dispatch(updateAdditionSpecial2New(data.idAdditionSpecialConditionTime,data));
    }else{
      res = await dispatch(addAdditionSpecial2New(data));
    }
    
    if (res && res.status === 201) {
      dispatch(openNotificationAlert({ message: "success", type: "success" }));
      dispatch(getAdditionSpecial2(selectedCompany.idCompany));
    } else {
      dispatch(openNotificationAlert({ message: "error", type: "error" }));
    }
    handleClose();
  };

  const handlePriceChange = (index, newPrice) => {
    setValue(`levels[${index}].price`, newPrice);  
  };

  useEffect(() => {
    dispatch(getEmploymentTypeByidCompany({ idCompany: selectedCompany.idCompany }));
    dispatch(getLevelByidCompany({ idCompany: selectedCompany.idCompany }));
    dispatch(getAllPositions({ idCompany: selectedCompany.idCompany }));
  }, [selectedCompany.idCompany]);

  useEffect(() => {
    if (selectAddition) {
      reset({
        idAdditionSpecialConditionTime: selectAddition.idAdditionSpecialConditionTime || "",
        name: selectAddition.additionSpecialConditionTimeName || "",
        type: selectAddition.type || "",
        min: selectAddition.min || null,
        max: selectAddition.max || null,
        employmentTypes: selectAddition.employmentTypes || [],
        positions: selectAddition.positions || [],
        groupLevels: selectAddition.groupLevels || [],
        times: selectAddition.times || [],
        levels: selectAddition.levels || [],
        probation: selectAddition.isProbation === 1,
        amount:selectAddition.amount,
      });

      setChecked({
        employmentTypes: (selectAddition && selectAddition.employmentTypes && selectAddition.employmentTypes.length === 0),
        positions: (selectAddition && selectAddition.positions && selectAddition.positions.length === 0),
        groupLevels:
          (selectAddition && selectAddition.groupLevels && selectAddition.groupLevels.length === 0) &&
          (selectAddition && selectAddition.levels && selectAddition.levels.length === 0),
        Levels: (selectAddition && selectAddition.levels && selectAddition.levels.length > 0),
      });
    }
  }, [selectAddition, reset]);  

  useEffect(() => {
    if (level) {
      const option = level ? level.reduce((acc, group) => {
        return [...acc, ...group.level.map(item => ({ ...item, price: '0' }))];
      }, []) : [];
      setLevels(option)

      setValue("levels", option);
      if(selectAddition){
        selectAddition && selectAddition.levels && selectAddition.levels.map((item)=>{  
          const index = option.findIndex((optionItem) => optionItem.levelName === item.levelName);
  
          if (index !== -1) {
            handlePriceChange(index, item.price);
          }
        })
      }
    }
  }, [level]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography paddingBottom={"9px"} paddingTop={"9px"}>{t("Name")}</Typography>
          <Controller
            name={"name"}
            control={control}
            render={({ field, fieldState }) => (
              <TextFieldTheme
                {...field}
                helperText={fieldState.error && fieldState.error.message}
                error={Boolean(fieldState.error)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <Typography>{t("EmployeeType")}</Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="employmentTypes"
                    checked={checked.employmentTypes}
                    onChange={handleChange}
                  />
                }
                label={t("SelectAll")}
              />
            </FormGroup>
            <Controller
              name={"probation"}
              control={control}
              render={({ field, fieldState }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(event) => field.onChange(event.target.checked)}
                    />
                  }
                  label={t('PassProbation')}
                />
              )}
            />
          </div>
          {employmentType && (
            <Controller
              name={"employmentTypes"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  multiple
                  filterOptions={(options, params) => {
                    const filter = createFilterOptions();
                    const filtered = filter(options, params);
                    return filtered;
                  }}
                  onChange={(event, newValue) => field.onChange(newValue)}
                  disabled={checked.employmentTypes}
                  isOptionEqualToValue={(option, value) =>
                    option.idEmploymentType === value.idEmploymentType
                  }
                  options={employmentType}
                  getOptionLabel={(option) => option.employmentTypeName}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error && fieldState.error.message}
                    />
                  )}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <Typography>{t("GroupLevel")}</Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="groupLevels"
                    checked={checked.groupLevels}
                    onChange={handleChange}
                    disabled={checked.Levels}
                  />
                }
                label={t("SelectAll")}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="Levels"
                    checked={checked.Levels}
                    onChange={handleChange}
                  />
                }
                label={t("JobLevel")}
              />
            </FormGroup>
          </div>
          {level && (
            <Controller
              name={"groupLevels"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  multiple
                  filterOptions={(options, params) => {
                    const filter = createFilterOptions();
                    const filtered = filter(options, params);
                    return filtered;
                  }}
                  onChange={(event, newValue) => field.onChange(newValue)}
                  disabled={checked.groupLevels || checked.Levels}
                  isOptionEqualToValue={(option, value) =>
                    option.idGroupLevel === value.idGroupLevel
                  }
                  options={level}
                  getOptionLabel={(option) => option.groupLevelName}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error && fieldState.error.message}
                    />
                  )}
                />
              )}
            />
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <Typography>{t("Position")}</Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="positions"
                    checked={checked.positions}
                    onChange={handleChange}
                  />
                }
                label={t("SelectAll")}
              />
            </FormGroup>
          </div>
          {positions && (
            <Controller
              name={"positions"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  multiple
                  filterOptions={(options, params) => {
                    const filter = createFilterOptions();
                    const filtered = filter(options, params);
                    return filtered;
                  }}
                  onChange={(event, newValue) => field.onChange(newValue)}
                  // onChange={(event, newValue) => {
                  //   if (newValue.find((option) => option.all)) {
                  //     return field.onChange(
                  //       field.value.length === positions.length ? [] : positions
                  //     );
                  //   } else {
                  //     return field.onChange(newValue);
                  //   }
                  // }}
                  disabled={checked.positions}
                  isOptionEqualToValue={(option, value) =>
                    option.idPosition === value.idPosition
                  }
                  options={positions}
                  getOptionLabel={(option) =>
                    getUserPosition({
                      positionName: option.positionName,
                      positionName_EN: option.positionName_EN,
                    })
                  }
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error && fieldState.error.message}
                    />
                  )}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2} sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Grid item xs={12} md={!checked.Levels ? 3 :4} sx={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                <Typography sx={{ padding: '9px 0' }}>{t('TimeConditions')}</Typography>
              </div>
              <Controller
                name="type"
                control={control}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <FormControl fullWidth error={Boolean(fieldState.error)}>
                    <Select
                      {...field}
                      onChange={(event) => field.onChange(event.target.value)}
                      value={field.value || ''}
                      sx={{
                        '& .MuiSelect-select': {
                          padding: '13.5px 32px 13.5px 14px',
                        },
                      }}
                      defaultValue="All"
                    >
                      <MenuItem value="All">{`${t('All')}`}</MenuItem>
                      <MenuItem value="Before">{`${t('Before')}`}</MenuItem>
                      <MenuItem value="During">{`${t('During')}`}</MenuItem>
                      <MenuItem value="After">{`${t('After')}`}</MenuItem>
                    </Select>
                    {fieldState.error && (
                      <FormHelperText>{fieldState.error.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12}md={!checked.Levels ? 3 :4}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography sx={{ padding: '9px 0' }}>
                  {`${t('Minimum')} `}
                  <Typography component="span" variant="body2" sx={{ color: 'grey.600' }}>
                    ({t('Unit.Minute')})
                  </Typography>
                </Typography>
                <InputNumber name="min" control={control} />
              </div>
            </Grid>

            <Grid item xs={12} md={!checked.Levels ? 3 :4}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography sx={{ padding: '9px 0' }}>
                  {`${t('NotMoreThan')} `}
                  <Typography component="span" variant="body2" sx={{ color: 'grey.600' }}>
                    ({t('Unit.Minute')})
                  </Typography>
                </Typography>
                <InputNumber name="max" control={control} />
              </div>
            </Grid>

            {
              !checked.Levels && (
                <Grid item xs={12} md={3}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Typography sx={{ padding: '9px 0' }}>
                    {`${t('AmountOfMoney')} `}
                  </Typography>
                  <InputNumber name="amount" control={control} />
                </div>
              </Grid>
              )
            }
          </Grid>
        </Grid>
        {
          checked.Levels &&
          <Grid

            sx={{
              boxSizing: "border-box",
              display: "flex",
              flexFlow: "wrap",
              margin: "16px auto",
              width: "100%",
              alignItems: 'center',
              // justifyContent: 'center',
              padding: "16px 0 16px 8px",
            }}
          >
            <Box sx={{ backgroundColor: "rgb(245, 245, 245)", padding: '16px', width: '100%' }} >

              <TableContainer sx={{ borderRadius: '16px' }}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead sx={{ backgroundColor: 'rgba(240, 240, 240)' }}>
                    <TableRow>
                      <TableCell>{t('JobLevel')}</TableCell>
                      <TableCell align="center">{t('AmountOfMoney')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
                    {(levels && levels.length > 0 ? levels : []).map((level, index) => (
                      <TableRow key={level.idLevel}>
                        <TableCell sx={{ width: '50%' }}>{level.levelName}</TableCell>
                        <TableCell align="center" sx={{ width: '50%' }}>
                          <InputNumber
                            name={`levels[${index}].price`}
                            control={control}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        }
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"flex-end"}
          gap={"8px"}
        >
          <Button color="error" onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <ButtonBlue type="submit" variant="contained">
            {t("Confirm")}
          </ButtonBlue>
        </Grid>
      </Grid>
    </form >
  );
};

export default AS2Form;
