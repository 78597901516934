import React from "react";
import {
  Typography,
  Button,
  Stack,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import DescriptionIcon from "@mui/icons-material/Description";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { t } from "i18next";

const WrapHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
  marginTop: 8,
});

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    sx={{
      backgroundColor: props.isActive ? "#b2dfdb" : "#ff7961",
    }}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(3),
  },
}));

const PolicyDetail = ({
  title,
  details,
  file,
  startDate,
  endDate,
  updateAt,
  isActive,
}) => {
  return (
    <>
      <Accordion disableGutters>
        <StyledAccordionSummary isActive={isActive}>
          <Typography variant="h6">{title}</Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
            marginBottom={1}
          >
            <Typography variant="subtitle1" color="text.secondary">
              {`${t("AnnouncementDate")}: ${dayjs(startDate).format(
                "DD/MM/YYYY"
              )} - ${dayjs(endDate).format("DD/MM/YYYY")}`}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {`${t("LastModified")}: ${dayjs(updateAt).format("DD/MM/YYYY HH:mm")}`}
            </Typography>
          </Stack>
          <Chip
            icon={<DescriptionIcon />}
            label={t("Details")}
            style={{ marginRight: "auto" }}
          />
          <Stack marginTop={2} marginBottom={2} spacing={1}>
            {details.map((item, index) => (
              <div key={item.idPolicyItems}>
                <Chip label={item.topic} color="primary" />
                <Typography variant="body1" padding={1}>{item.detail}</Typography>
              </div>
            ))}
          </Stack>
          {file && (
            <Button
              variant="outlined"
              component={Link}
              href={file}
              target="_blank"
              startIcon={<AttachFileIcon />}
              sx={{ justifyContent: "flex-end" }}
            >
              {t("AttachedAnnouncementFile")}
            </Button>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default function CompanyPolicy() {
  const { result: policyActive } = useSelector((state) => state.policyActive);
  return (
    <>
      <WrapHeader>
        <Typography variant="h4">{t("CompanyPolicy")}</Typography>
      </WrapHeader>
      <Stack marginTop={4}>
        {policyActive &&
          policyActive.map((item) => (
            <PolicyDetail
              key={item.idPolicy}
              isActive={true}
              {...item}
            />
          ))}
      </Stack>
    </>
  );
}
