import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import "../styles/Dashboard.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const useStyles = makeStyles(() => ({
  imageEmp: {
    width: 48,
    height: 48,
    borderRadius: "50%",
  },
  contentEmp: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  InfoEmp: {
    width: "100%",
  },
}));

export default function ChartNode({ nodeData }) {
  const classes = useStyles();

  return (
    <div>
      {nodeData.type == "Individual" ? (
        <div className="oc-heading">{nodeData.position}</div>
      ) : (
        <div className="oc-heading">{nodeData.name}</div>
      )}
      {nodeData.type == "Individual" ? (
        <div className="oc-content">
          <div className={classes.contentEmp}>
            <div>
              {nodeData.img != "" ? (
                <img src={nodeData.img} className={classes.imageEmp} />
              ) : (
                <AccountCircleIcon
                  sx={{ color: "#e0e0e0" }}
                  className={classes.imageEmp}
                />
              )}
            </div>
            <div className={classes.InfoEmp}>{nodeData.name}</div>
          </div>
        </div>
      ) : null}
      <div className="oc-okrs">{nodeData.title}</div>
    </div>
  );
}
