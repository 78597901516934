import React, { useState, useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
} from "@mui/material";
import "../styles.css";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

const mapStyles = {
  position: "relative",
  width: "100%",
  height: "500px",
  borderRadius: "10px",
};

const resetContainer = {
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: "10px",
};

function MapContainer(props) {
  const { values, setValues, valueItems } = props;
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let map;
  var drawingManager;
  var all_overlays = [];

  const onMapLoad = () => {
    const google = window.google;

    //Find center of location polygon
    var bounds = new google.maps.LatLngBounds();
    var i;
    const locationList = valueItems.location;

    for (i = 0; i < locationList.length; i++) {
      bounds.extend(locationList[i]);
    }

    //Create Map
    var myLatlng = new google.maps.LatLng(
      bounds.getCenter().lat(),
      bounds.getCenter().lng()
    );
    var myOptions = {
      zoom: 16,
      center: myLatlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    map = new google.maps.Map(document.getElementById("map"), myOptions);

    //set polygon
    const locationPolygon = new google.maps.Polygon({
      paths: locationList,
      strokeColor: "#bdbdbd",
      strokeOpacity: 0.5,
      strokeWeight: 1,
      fillColor: "#bdbdbd",
      fillOpacity: 0.3,
    });

    locationPolygon.setMap(map);

    onSearchBox();
    onDrawMap();
    setIsLoading(true);
  };

  const onSearchBox = () => {
    const google = window.google;

    // Create the search box and link it to the UI element.
    const input = document.getElementById("pac-input");
    const searchBox = new google.maps.places.SearchBox(input);

    let markers = [];

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();

      if (places.length == 0) {
        return;
      }

      // Clear out the old markers.
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      markers = [];

      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();

      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          console.log("Returned place contains no geometry");
          return;
        }

        const icon = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25),
        };

        // Create a marker for each place.
        markers.push(
          new google.maps.Marker({
            map,
            icon,
            title: place.name,
            position: place.geometry.location,
          })
        );
        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
    });
  };

  const onDrawMap = () => {
    const google = window.google;
    //Create Drawing Manager
    drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: {
        editable: true,
      },
    });
    drawingManager.setMap(map);

    google.maps.event.addListener(
      drawingManager,
      "overlaycomplete",
      function (event) {
        var newShape = event.overlay;
        newShape.type = event.type;

        overlayClickListener(event.overlay);

        var locationArray = event.overlay.getPath().getArray();
        var latlngArray = [];

        for (var i = 0; i < locationArray.length; i++) {
          latlngArray.push({
            id: i + 1,
            lat: locationArray[i].lat(),
            lng: locationArray[i].lng(),
          });
        }

        setValues({
          ...values,
          location: latlngArray,
        });
      }
    );
  };

  const overlayClickListener = (overlay) => {
    const google = window.google;
    google.maps.event.addListener(overlay, "mouseup", function (event) {
      var locationArray = overlay.getPath().getArray();
      var latlngArray = [];

      for (var i = 0; i < locationArray.length; i++) {
        latlngArray.push({
          id: i + 1,
          lat: locationArray[i].lat(),
          lng: locationArray[i].lng(),
        });
      }

      setValues({
        ...values,
        location: latlngArray,
      });
    });
  };

  const handleOnReset = () => {
    initialize();
    handleClearInput();
  };

  const handleClearInput = () => {
    document.getElementById("pac-input").value = "";
    setSearch("");
  };

  const loader = new Loader({
    //apiKey: "AIzaSyCZ41b0hPCVif4tewQUlrc2aivCyZf2DWc",
    apiKey: "AIzaSyBJEmVrMq5Zgj27dilri-OK76pn7pgQCho",
    version: "weekly",
    libraries: ["places", "drawing", "geometry"],
  });

  const initialize = () => {
    loader.load().then(() => {
      onMapLoad();
    });
  };

  useEffect(() => {
    if (values.length !== 0 && !isLoading) {
      initialize();
    }
  }, [values]);

  return (
    <>
      <Box sx={resetContainer}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={8} lg={8}>
            <Paper
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                boxShadow:
                  "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                id="pac-input"
                class="controls"
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search Google Maps"
                inputProps={{ "aria-label": "search google maps" }}
              />
              <IconButton sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              {search.length > 0 ? (
                <>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    sx={{ p: "10px" }}
                    aria-label="directions"
                    onClick={handleClearInput}
                  >
                    <ClearIcon />
                  </IconButton>
                </>
              ) : null}
            </Paper>
          </Grid>
          <Grid item>
            <ButtonBlue
              variant={"outlined"}
              startIcon={<RestartAltIcon />}
              onClick={handleOnReset}
            >ล้าง</ButtonBlue>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <div id="map" style={mapStyles}>
          Loading ...
        </div>
      </Box>
    </>
  );
}

export default MapContainer;
