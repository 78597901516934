import { IMPACT_FETCHING, IMPACT_FAILED, IMPACT_SUCCESS } from "./types";

import ImpactService from "../services/Impacts.service";

export const getAllImpact = () => async (dispatch) => {
  try {
    const res = await ImpactService.getAllImpact();
    if (res) {
      dispatch({
        type: IMPACT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: IMPACT_FAILED,
    });
    console.log(err);
  }
};
