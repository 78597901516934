import React from "react";
import dayjs from "dayjs";
import { styled } from "@mui/styles";
import { Typography, Grid, Divider } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import CakeIcon from "@mui/icons-material/Cake";
import { useTranslation } from "react-i18next";

const InfoContainer = styled("div")(({ theme }) => ({
  marginBottom: 16,
}));

const WrapIcon = styled(Typography)({
  verticalAlign: "middle",
  display: "inline-flex",
});

const Contact = ({ email, mobileNumber, birthDate }) => {
  const {t} = useTranslation();
  return (
    <div>
      <Typography variant="body1">{t("contract")}</Typography>
      <Divider />
      <InfoContainer>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <WrapIcon variant="body2">
              <EmailIcon sx={{ color: "#f06292" }} />
            </WrapIcon>
          </Grid>
          <Grid item>
            <WrapIcon variant="body2">{t("Email")}</WrapIcon>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item>
            <Typography variant="subtitle1">{email}</Typography>
          </Grid>
        </Grid>
      </InfoContainer>
      <InfoContainer>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <WrapIcon variant="body2">
              <PhoneIphoneIcon sx={{ color: "#4caf50" }} />
            </WrapIcon>
          </Grid>
          <Grid item>
            <WrapIcon variant="body2">{t("PhoneNumber")}</WrapIcon>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item>
            <Typography variant="subtitle1">{mobileNumber}</Typography>
          </Grid>
        </Grid>
      </InfoContainer>
    </div>
  );
};

export default Contact;
