import {
  JOBPOSITIONS_FETCHING,
  JOBPOSITIONS_FAILED,
  JOBPOSITIONS_SUCCESS,
} from "./types";

import JobPositionService from "../services/jobPositions.service";

export const getAllJobPosition = () => async (dispatch) => {
  try {
    const res = await JobPositionService.getAllJobPosition();
    if (res) {
      dispatch({
        type: JOBPOSITIONS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: JOBPOSITIONS_FAILED,
    });
    console.log(err);
  }
};

export const postJobPosition = (formData) => async () => {
  try {
    const res = await JobPositionService.postJobPosition(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putJobPosition = (id, formData) => async () => {
  try {
    const res = await JobPositionService.putJobPosition(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const deleteJobPosition = (id) => async () => {
  try {
    const res = await JobPositionService.deleteJobPosition(id);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};
