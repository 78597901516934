import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
// import StatusRequest from "../../shared/requestList/stausRequest";

const StyledBox = styled(Box)(({ statusrequest, active }) => ({
  margin: "auto",
  width: 40,
  height: 40,
  display: "flex",
  //   flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 12,
  ...(statusrequest === 1 &&
    active && {
      backgroundColor: "#ebfaf2",
    }),
  ...(statusrequest === 0 && {
    backgroundColor: "#fdf3f5",
  }),
  ...(statusrequest === null &&
    active === 0 && {
      backgroundColor: "#f3f6f8",
    }),
  ...(statusrequest === null &&
    active === 1 && {
      backgroundColor: "#fffcd6",
    }),
  ...(active === 0 && {
    backgroundColor: "#f3f6f8",
  }),
}));

const StatusRequest = ({ status, active, step }) => {
  const renderIcon = () => {
    // if (active) {
    if (status === 1) {
      return (
        <i
          className={`fas fa-check-circle`}
          style={{ fontSize: 24, color: "#00c292" }}
        ></i>
      );
    } else if (status === 0) {
      return (
        <i
          className={`fas fa-times-circle`}
          style={{ fontSize: 24, color: "#e46a76" }}
        ></i>
      );
    } else if (status === 3) {
      return (
        <i
          className={`fas fa-minus-circle`}
          style={{ fontSize: 24, color: "#bfbfbf" }}
        ></i>
      );
    } else {
      return (
        <i
          className={`fas fa-question-circle`}
          style={{ fontSize: 24, color: "#fec90f" }}
        ></i>
      );
    }
    // } else {
    //   return (
    //     <i
    //       className={`fas fa-minus-circle`}
    //       style={{ fontSize: 24, color: "#bfbfbf" }}
    //     ></i>
    //   );
    // }
  };

  return (
    <StyledBox active={active} statusrequest={status}>
      {renderIcon()}
    </StyledBox>
  );
};

function CardOJTStatus({ request }) {
  const { t } = useTranslation();

  const getStatusRequest = (row) => {
    let today = dayjs(`${dayjs().format("YYYY-MM-DD")} 00:00:00`);
    let endDate = dayjs(row.endDate);
    if (today.diff(endDate, "day") > 0 && row.isFinish === 0) {
      return { status: 0, text: "หมดเขต" };
    } else if (row.isFinish === 1) {
      return { status: 1, text: "บันทึก OJT สำเร็จ" };
    } else {
      return { status: 2, text: "รอพนักงานบันทึก OJT" };
    }
  };

  const getApproveOJT = (row) => {
    if (row.isApprove === 1) {
      return { status: 1, text: "อนุมัติสำเร็จ" };
    } else if (row.isFinish === 1) {
      return { status: 2, text: "รออนุมัติ" };
    } else if (row.isFinish === 0) {
      return { status: 3, text: "รอพนักงานบันทึก" };
    } else {
      return { status: 0, text: "ผิดพลาด" };
    }
  };

  const empStatus = getStatusRequest(request) || 0;
  const approveStatus = getApproveOJT(request) || 0;

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        borderRadius: 4,
        display: "flex",
        alignItems: "flex-start",
        bgcolor: "#F1F0F0",
        width: "100%",
        marginBottom: "10px",
        whiteSpace: "pre-wrap",
      }}
    >
      <Box>
        <StatusRequest
          status={empStatus ? empStatus.status : 0}
          sx={{ width: 60, height: 60, mr: 2, flexShrink: 0 }}
        />
        <StatusRequest
          status={approveStatus}
          sx={{ width: 60, height: 60, mr: 2, flexShrink: 0 }}
        />
      </Box>
      <Box sx={{ flexGrow: 1, minWidth: 0, marginLeft: "8px", maxWidth: 400 }}>
        {/* <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("Status")}: {t(checkStatus(request))}
        </Typography> */}
        <Typography
          variant="body2"
          sx={{ wordWrap: "break-word", wordBreak: "break-word" }}
        >
          {t("OJT")}: {request.ojtName}
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("StartDate")}:{" "}
          {request.startDate
            ? dayjs(request.startDate).format("DD/MM/YYYY")
            : "-"}
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("EndDate")}:{" "}
          {request.endDate ? dayjs(request.endDate).format("DD/MM/YYYY") : "-"}
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("Determiner")}:{" "}
          {`${request.Assigner_firstname_TH} ${request.Assigner_lastname_TH}`}
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("Approver")}:{" "}
          {request.isApprove === 1
            ? `${request.Approver_firstname_TH} ${request.Approver_lastname_TH}`
            : `${t("PendingApproval")}`}
        </Typography>
      </Box>
    </Paper>
  );
}

export default CardOJTStatus;
