import React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../../shared/general/Drawer";
import SelectDivision from "../../../../shared/general/selectDivision";

const StyledRoot = styled("div")({
  width: 350,
  padding: 24,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  marginBottom: 4,
});

const DialogBusinessUnit = (props) => {
  const {
    open,
    handleCloseDialog,
    handleSubmit,
    formData,
    setFormData,
  } = props;
  const { t, i18n } = useTranslation();

  const handleChangeDivision = (newCompany) => {
    setFormData({
      ...formData,
      ["businessUnit"]: newCompany,
    });
  };

  return (
    <DrawerCustom
      title={t("EditAdministratorData")}
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        {formData && (
          <>
            <StyledContentLabel>{t("BusinessUnitInCharge")}</StyledContentLabel>
            <SelectDivision
              variant="outlined"
              multiple={true}
              value={formData.businessUnit}
              idCompany={formData.idCompany}
              handleChange={handleChangeDivision}
            />
          </>
        )}

        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleCloseDialog}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue
            disabled={formData.businessUnit.length === 0}
            variant="contained"
            onClick={handleSubmit}
          >
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogBusinessUnit;
