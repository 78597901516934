import React, { useState, useEffect } from "react";
import { createAPIEndpoint, ENDPOINTS } from "../../../../../services/api";
import AddProject from "./AddProject";
import dayjs from "dayjs";
import Pagination from "../../../shared/general/Pagination";
import { useHistory } from "react-router-dom";
import ViewProjectInfo from "./ViewProjectInfo";

import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  Container
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";

import { ChartDataProject } from "../OrgData";

import CardTravelIcon from "@mui/icons-material/CardTravel";
import TodayIcon from "@mui/icons-material/Today";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTypography-h6": {
      fontWeight: 600,
      color: "#16243D",
    },
    "& .MuiTypography-body1": {
      color: "#757575",
    },
    "& .MuiTypography-subtitle1": {
      color: "#757575",
      fontSize: "0.9rem",
    },
  },
  addBtn: {
    backgroundColor: "#2f74eb",
    color: "#fff",
    fontWeight: 700,
    height: "40px",
    borderRadius: "25px",
    paddingLeft: 32,
    paddingRight: 32,
    "&:hover": {
      backgroundColor: "#2a68d3",
    },
  },
  projectList_container: {
    marginTop: 32,
  },
  paper: {
    padding: 16,
    borderRadius: "10px",
  },
  primaryBody1: {
    fontWeight: 600,
    color: "#16243D !important",
  },
  cardSvg: {
    color: "#3f51b5",
    backgroundColor: "#f5f5f5",
    borderRadius: "10px",
    padding: 8,
    marginRight: 16,
  },
  divider: {
    marginTop: 16,
    marginBottom: 16,
  },
  headerCard: {
    display: "flex",
    alignItems: "center",
  },
  desc_container: {
    minHeight: "50px",
    marginBottom: 32,
  },
  seemoreBtn: {
    backgroundColor: "#2f74eb",
    color: "#fff",
    fontWeight: 700,
    borderRadius: "5px",
    boxShadow: "none",
    height: "100%",
    "&:hover": {
      backgroundColor: "#2a68d3",
    },
  },
  seedetailBtn: {
    backgroundColor: "#fff",
    color: "#2f74eb",
    border: "2px solid #2f74eb",
    fontWeight: 700,
    borderRadius: "5px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
}));

export default function ProjectList() {
  const classes = useStyles();
  const [projectList, setProjectList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = projectList.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [addModal, setAddModal] = useState({
    isOpen: false,
  });

  const [infoModal, setInfoModal] = useState({
    isOpen: false,
    projectData: [],
  });

  const handleOnAddNew = () => {
    setAddModal({
      isOpen: true,
    });
  };

  const handleOnViewInfo = (data) => {
    setInfoModal({
      isOpen: true,
      projectData: data,
    });
  };

  const fetchData = () => {
    createAPIEndpoint(ENDPOINTS.PROJECT)
      .fetchAll()
      .then((res) => {
        setProjectList(res.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    //fetchData();
    setProjectList(ChartDataProject);
    setIsLoading(false);
  }, []);

  return (
    <div className={`page`} style={{ minHeight: 680 }}>
      <Container maxWidth="lg">
        <Grid container justify="space-between" alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h6">Project Assignment List</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className={classes.addBtn}
              onClick={handleOnAddNew}
            >
              Add New
            </Button>
          </Grid>
        </Grid>
        <div className={classes.projectList_container}>
          {isLoading ? (
            <Grid container justify="center">
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid container spacing={4}>
                {projectList.length > 0 &&
                  currentPosts.map((item) => (
                    <Grid item xs={12} md={6} lg={6}>
                      <Paper key={item.projId} className={classes.paper}>
                        <Grid
                          container
                          justify="space-between"
                          alignItems="center"
                        >
                          <Grid item xs={12} md={8} lg={8}>
                            <div className={classes.headerCard}>
                              <IconButton className={classes.cardSvg}>
                                <CardTravelIcon />
                              </IconButton>
                              <Typography
                                variant="body1"
                                className={classes.primaryBody1}
                              >
                                {item.projectName}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={4} lg={4}>
                            <Typography
                              variant="body1"
                              align="right"
                              className={classes.primaryBody1}
                            >
                              {item.projectInvest} Baht
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider className={classes.divider} />
                        <div className={classes.desc_container}>
                          <Typography variant="body1">
                            {item.description}
                          </Typography>
                        </div>
                        <Grid
                          container
                          justify="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item>
                            <Typography variant="subtitle1" display="inline">
                              <TodayIcon style={{ marginRight: "5px" }} />
                              {dayjs(item.dateStart).format("DD/MM/YYYY")}
                            </Typography>
                            <Typography variant="subtitle1" display="inline">
                              {" - "}
                            </Typography>
                            <Typography variant="subtitle1" display="inline">
                              {dayjs(item.dateEnd).format("DD/MM/YYYY")}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Grid container spacing={1}>
                              <Grid item>
                                <Button
                                  variant="contained"
                                  className={classes.seedetailBtn}
                                  endIcon={<SearchIcon />}
                                  onClick={() => handleOnViewInfo(item)}
                                >
                                  Detail
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="contained"
                                  className={classes.seemoreBtn}
                                  endIcon={<ArrowForwardIosIcon />}
                                  onClick={() =>
                                    history.push({
                                      pathname:
                                        "/admin/project-assign/" +
                                        item.projectName,
                                      projectData: item,
                                    })
                                  }
                                >
                                  See more
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
              </Grid>
              <Pagination
                paginate={paginate}
                postsPerPage={postsPerPage}
                totalPosts={projectList.length}
              />
            </>
          )}
        </div>
        <AddProject
          addModal={addModal}
          setAddModal={setAddModal}
          fetchData={fetchData}
        />
        <ViewProjectInfo infoModal={infoModal} setInfoModal={setInfoModal} />
      </Container>
    </div>
  );
}
