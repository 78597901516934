import React, { useEffect, useState } from "react";
import { styled } from "@mui/styles";
import { Container } from "@mui/material";
import { useLocation } from "react-router";
import { Grid, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiGrid-container": {
      marginBottom: "0px !important",
    },
    "& .MuiPaper-root": {
      boxShadow: "none",
    },
    "& .MuiTypography-subtitle2": {
      fontWeight: "700",
      color: "#bdbdbd",
    },
    "& .MuiTypography-body2": {
    },
  },
  container: {
    padding: 40,
    overflowX: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  leaderHeader: {
    padding: "16px 32px 16px 32px",
  },
  imageEmp: {
    width: 56,
    height: 56,
    borderRadius: "50%",
    boxShadow:
      "rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px",
  },
  leaderGold: {
    borderRadius: "15px",
    padding: "16px 32px 16px 32px",
    marginBottom: 8,
    background:
      "linear-gradient(90deg, rgba(255,217,74,1) 0%, rgba(255,235,122,1) 100%)",
    
  },
  leaderSilver: {
    borderRadius: "15px",
    padding: "16px 32px 16px 32px",
    marginBottom: 8,
    background:
      "linear-gradient(90deg, rgba(212,213,197,1) 0%, rgba(223,224,209,1) 100%)",
    
  },
  leaderCopper: {
    borderRadius: "15px",
    padding: "16px 32px 16px 32px",
    marginBottom: 8,
    background:
      "linear-gradient(90deg, rgba(243,169,106,1) 0%, rgba(244,179,124,1) 100%)",
    
  },
  leaderNomal: {
    borderRadius: "15px",
    padding: "16px 32px 16px 32px",
    marginBottom: 8,
    background: "#f5f5f5",
    
  },
  svgMoonShot: {
    color: "#ffd335",
    fontSize: "1.5rem",
    marginRight: 16,
    backgroundColor: "#fdae1b",
    borderRadius: "50%",
    boxShadow: "#e08f20 2px 1px",
  },
  trophySvg: {
    width: "35px",
    height: "35px",
    fontSize: "1rem",
    background: "#fff",
    borderRadius: "50%",
    padding: "6px",
    
  },
  trophySvg_Gold: {
    color: "#ffd94a",
  },
  trophySvg_Silver: {
    color: "#d7d8c8",
  },
  trophySvg_Copper: {
    color: "#f3a96a",
  },
  trophySvg_Normal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "35px",
    height: "35px",
    fontSize: "1rem",
    background: "#f37401",
    color: "#fff",
    fontWeight: "700",
    border: "3px solid #fff",
    marginRight: 32,
    borderRadius: "50%",
    padding: "6px",
  },
  noData_Section: {
    textAlign: "center",
    paddingTop: 24,
  },
}));

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

function LeaderboardOrg() {
  const classes = useStyles();
  const location = useLocation();
  const [records, setRecords] = useState(location.state.records);
  const [member, setMember] = useState([]);
  const [gold, setGold] = useState(0);
  const [silver, setSilver] = useState(0);
  const [copper, setCopper] = useState(0);

  useEffect(() => {
    if (records) {
      let itemSort = [];
      records.forEach((record) => {
        let score = (record.achieved / record.objectiveAmount) * 100;

        if (isNaN(score)) {
          score = 0;
        }

        record.teamScore = score;

        itemSort.push(record);
      });

      //Order teamScore by DESC
      itemSort.sort((a, b) => b.teamScore - a.teamScore);

      //Add Rank
      if (itemSort[0] !== undefined) {
        setGold(itemSort[0].id);
      }

      if (itemSort[1] !== undefined) {
        setSilver(itemSort[1].id);
      }

      if (itemSort[2] !== undefined) {
        setCopper(itemSort[2].id);
      }

      setMember(itemSort);
    }
  }, [records]);

  return (
    <ContainerStyled>
      <div className={classes.root}>
        <div className="card-section">
          <div className="header-section">
            <Grid container>
              <Grid item>
                <h3 className="header-topic">Leaderboard</h3>
              </Grid>
            </Grid>
          </div>
          <Paper className={classes.container}>
            <div className={classes.leaderHeader}>
              <Grid container alignItems="center">
                <Grid item xs={1} md={1} lg={1}>
                  <Typography variant="subtitle2">Rank</Typography>
                </Grid>
                <Grid item xs={4} md={7} lg={7}>
                  <Typography
                    variant="subtitle2"
                    className={classes.fullNameHeader}
                  >
                    Team Name
                  </Typography>
                </Grid>
                <Grid item xs={2} md={2} lg={2}>
                  <Typography variant="subtitle2">Achieved</Typography>
                </Grid>
                <Grid item xs={2} md={2} lg={2}>
                  <Typography variant="subtitle2">Score</Typography>
                </Grid>
              </Grid>
            </div>
            {/* member */}
            {(member.length > 0 &&
              member.map((item) => (
                <div
                  key={item.id}
                  className={
                    item.id === gold
                      ? `${classes.leaderGold}`
                      : item.id === silver
                      ? `${classes.leaderSilver}`
                      : item.id === copper
                      ? `${classes.leaderCopper}`
                      : `${classes.leaderNomal}`
                  }
                >
                  <Grid container>
                    <Grid
                      container
                      alignItems="center"
                      item
                      xs={1}
                      md={1}
                      lg={1}
                    >
                      {item.id === gold ||
                      item.id === silver ||
                      item.id === copper ? (
                        <i className={
                          item.id === gold
                            ? `${classes.trophySvg} ${classes.trophySvg_Gold}`
                            : item.id === silver
                            ? `${classes.trophySvg} ${classes.trophySvg_Silver}`
                            : item.id === copper
                            ? `${classes.trophySvg} ${classes.trophySvg_Copper}`
                            : `${classes.trophySvg}`
                        } class="fa-solid fa-trophy"></i>
                        
                      ) : (
                        <Typography
                          variant="subtitle1"
                          className={classes.trophySvg_Normal}
                        >
                          {/* member */}
                          {member.findIndex((x) => x.id === item.id) + 1}
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      item
                      xs={7}
                      md={7}
                      lg={7}
                    >
                      <Typography variant="body2">
                        {item.teamRecordName}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      alignItems="center"
                      xs={2}
                      md={2}
                      lg={2}
                    >
                      {item.achieved}
                    </Grid>
                    <Grid
                      container
                      item
                      alignItems="center"
                      xs={2}
                      md={2}
                      lg={2}
                    >
                      {item.teamScore}
                    </Grid>
                  </Grid>
                </div>
              ))) || (
              <div className={classes.noData_Section}>
                <Typography variant="h6">No Data</Typography>
              </div>
            )}
          </Paper>
        </div>
      </div>
    </ContainerStyled>
  );
}

export default LeaderboardOrg;
