import React from "react";
import { useSelector } from "react-redux";

import AdminOffBoarding from "../admin/offBoarding";
import Resignlist from "../user/boarding/resign";

const OffBoarding = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ? (
          <AdminOffBoarding />
        ) : (
          <Resignlist />
        )
      ) : null}
    </>
  );
};

export default OffBoarding;
