import React, { useEffect, useState } from "react";
import {
    Box, Grid,
    Icon, styled,
    Tab, Tabs,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CardData from "../../../../../shared/dashboard/CardData";
import ChartAreaCustom from "../../../../../shared/dashboard/ChartArea";
import CardDashboard from "../../../../../shared/dashboard/CardDashboard";
import utils from "../../../../../../../utils";
import dayjs from "dayjs";
import { getPayrunByMonthAndCompanyToCompare } from "../../../../../../../actions/payruns";

const StyledRoot = styled(Box)({
    "& .top-employee": {
        padding: "8px",
        // paddingTop: "16px",
        display: "flex",
        alignItems: "center",
        border: "1px solid #ececec",
        borderRadius: "4px",
    },
});

const StyledCellHeader = styled(TableCell)({
    borderBottom: "none",
    color: "#637381",
    backgroundColor: "#f4f6f8",
    padding: "8px 14px",
    "&:first-of-type": {
        paddingLeft: 24,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    "&:last-of-type": {
        paddingRight: 24,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
    borderBottom: "1px dashed rgba(224, 224, 224, 1)",
    padding: "8px 14px",
    "&:first-of-type": {
        paddingLeft: 24,
    },
    "&:last-of-type": {
        paddingRight: 24,
    },
}));

export const AllfieldsCard = (props) => {
    const { t, i18n } = useTranslation();
    const { payrollData, payRunDetail, field, top10Lists } = props;

    return (
        <StyledRoot>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {payrollData && payrollData.payrollAllYears && payrollData.payrollAllYears.length > 0 && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CardDashboard>
                                    <Typography marginBottom="16px">
                                        {t("SalaryTotal")} ({t("Yearly")} {payRunDetail.yearPayrun})
                                    </Typography>
                                    <ChartAreaCustom
                                        series={[
                                            {
                                                name: "รายจ่าย",
                                                data: payrollData ? payrollData.payrollAllYears.map((item) => {
                                                    return {
                                                        x: dayjs().month(item.formattedMonth - 1).format("MMM"),
                                                        y: item[field],
                                                    };
                                                }) : [],
                                            },
                                        ]}
                                    />
                                </CardDashboard>
                            </Grid>

                            {payrollData.payrollAllYears
                                .filter(e => dayjs(e.payrunPeriod).isBefore(dayjs(payRunDetail.payrunPeriod)))
                                .sort((a, b) => dayjs(b.payrunPeriod) - dayjs(a.payrunPeriod))
                                .slice(0, 3)
                                .map((item, index) => (
                                    <Grid item xs={12} md={4} key={index}>
                                        <CardData
                                            title={`${t("SalaryTotal") + ` ${dayjs(item.payrunPeriod).locale('th').format("YYYY-MMMM")}`}`}
                                            dataValue={utils.numberWithCommas(item && item[field])}
                                            dataUnit={`${t("Baht")}`}
                                            dynamicFontSize
                                            titleIcon={
                                                <Icon
                                                    className="fal fa-sack-dollar"
                                                    color="warning"
                                                    style={{ display: "inline-table", color: "#ffcd38" }}
                                                />
                                            }
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    )}
                </Grid>

                <Grid item xs={12}>
                    <CardDashboard>
                        <Typography marginBottom="16px" variant="h6">
                            {t("top10theMostDiffValue")}
                        </Typography>

                        <Box>
                            {top10Lists && top10Lists.length > 0 ? (
                                <TableContainer style={{ minHeight: 400 }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <StyledCellHeader>
                                                    {t("#")}
                                                </StyledCellHeader>
                                                <StyledCellHeader sx={{ width: 140 }}>
                                                    {t("FullName")}
                                                </StyledCellHeader>
                                                <StyledCellHeader>
                                                    {t("Division")}
                                                </StyledCellHeader>
                                                <StyledCellHeader>
                                                    {t("Department")}
                                                </StyledCellHeader>
                                                <StyledCellHeader>
                                                    {t("Section")}
                                                </StyledCellHeader>
                                                <StyledCellHeader>
                                                    {t("Position")}
                                                </StyledCellHeader>
                                                <StyledCellHeader>
                                                    {t("PreviousIncome")}
                                                </StyledCellHeader>
                                                <StyledCellHeader>
                                                    {t("CurrentIncome")}
                                                </StyledCellHeader>
                                                <StyledCellHeader>
                                                    {t("diffPercentage")}
                                                </StyledCellHeader>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {top10Lists.map((item, index) => (
                                                <TableRow key={index}>
                                                    <StyledCellContent>
                                                        {index + 1}
                                                    </StyledCellContent>
                                                    <StyledCellContent>
                                                        {item.firstname_TH + " " + item.lastname_TH}
                                                    </StyledCellContent>
                                                    <StyledCellContent>
                                                        {item.divisionName}
                                                    </StyledCellContent>
                                                    <StyledCellContent>
                                                        {item.departmentName}
                                                    </StyledCellContent>
                                                    <StyledCellContent>
                                                        {item.sectionName}
                                                    </StyledCellContent>
                                                    <StyledCellContent>
                                                        {item.positionName}
                                                    </StyledCellContent>
                                                    <StyledCellContent>
                                                        {utils.numberWithCommas(item.previousValue)}
                                                    </StyledCellContent>
                                                    <StyledCellContent>
                                                        {utils.numberWithCommas(item.currentValue)}
                                                    </StyledCellContent>
                                                    <StyledCellContent>
                                                        {utils.numberWithCommas(item.percentageDiff)}
                                                    </StyledCellContent>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <Box
                                    style={{
                                        minHeight: 400,
                                        position: "sticky",
                                        left: 0,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography>{t("NoData")}</Typography>
                                </Box>
                            )}
                        </Box>
                    </CardDashboard>
                </Grid>
            </Grid>
        </StyledRoot >
    );
}