import React from "react";
import { styled } from "@mui/material/styles";
import {
  Divider,
  Grid,
  Stack,
  Card,
  CardContent,
  Typography,
  Box,
  Chip,
  Avatar,
} from "@mui/material";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";

const StyledCard = styled(Card)({
  height: "100%",
  minWidth: 350,
  margin: "16px",
  boxShadow: 3,
  borderRadius: 4,
});

const StyledCardContent = styled(CardContent)({
  height: "100%",
  display: "flex",
  alignItems: "flex-start",
  ":last-child": {
    paddingBottom: 16,
  },
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
    alignItems: "center",
  },
  ["@media only screen and (min-width:900px)"]: {
    flexDirection: "column",
    alignItems: "center",
  },
  "& .imgReward": {
    ["@media only screen and (max-width:600px)"]: {
      marginRight: 0,
    },
  },
  "& .textReward": {
    flexGrow: 1,
  },
  "& .price": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const StyledChip = styled(Chip)(({ chip }) => {
  const base = {
    color: "#ffffff",
    fontSize: 12,
  };
  switch (chip) {
    case 1:
      return {
        ...base,
        backgroundColor: "#ffeb3b",
      };
    case 2:
      return {
        ...base,
        backgroundColor: "#2196f3",
      };
    case 3:
      return {
        ...base,
        backgroundColor: "#00c853",
      };
    case 4:
      return {
        ...base,
        backgroundColor: "#e53935",
      };
    default:
      return { ...base };
  }
});

function CardReward({ data }) {
  const labelStatus = (status) => {
    switch (status) {
      case 1:
        return "รอตรวจสอบ";
      case 2:
        return "สำเร็จ, โปรดติดต่อรับของรางวัล";
      case 3:
        return "รับของรางวัลเรียบร้อยแล้ว";
      case 4:
        return "ไม่สำเร็จ, โปรดติดต่อผู้ดูแลระบบ";
      default:
        break;
    }
  };

  return (
    <Grid item xs={12} md={6} lg={4}>
      <StyledCard key={data.idRedeem} elevation={3}>
        <StyledCardContent>
          <Grid container alignItems={"center"} spacing={2}>
            <Grid item xs={12} sm={5}>
              <img
                alt="reward"
                className={`imgReward`}
                width={"100%"}
                height={"auto"}
                src={data.imageURL}
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              <div className={`textReward`}>
                <StyledChip
                  chip={data.idRedeemStatus}
                  label={labelStatus(data.idRedeemStatus)}
                  sx={{ marginBottom: "8px" }}
                />
                <Typography className={`nameReward`} variant="h6">
                  {data.name}
                </Typography>
                {data.options && (
                  <Typography
                    className={`nameDetail`}
                    variant="body1"
                    color="textSecondary"
                  >
                    {data.options.map((option) => option.value).join(", ")}
                  </Typography>
                )}
                <Typography variant="body1">
                  {`วันที่แลกรางวัล: ${dayjs(data.redeemDate).format(
                    "DD/MM/YYYY"
                  )}`}
                </Typography>
                {data.receivedDate && (
                  <Typography variant="body1">
                    {`วันที่รับของรางวัล: ${dayjs(data.receivedDate).format(
                      "DD/MM/YYYY"
                    )}`}
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>
          <Divider
            flexItem
            sx={{ borderWidth: 1, marginTop: 2, marginBottom: 2 }}
          />
          {data.coins && (
            <div className={`price`}>
              {data.coins.map((item, index) => (
                <>
                  <Avatar
                    src={item.imageURL}
                    alt={item.coinName}
                    sx={{ width: 40, height: 40 }}
                  />
                  <Typography
                    variant="h4"
                    style={{ marginLeft: 8, fontSize: 20 }}
                  >
                    {item.amount}
                  </Typography>
                  {data.coins.length - 1 > index && (
                    <CloseIcon color="disabled" />
                  )}
                </>
              ))}
            </div>
          )}
        </StyledCardContent>
      </StyledCard>
    </Grid>
  );
}

export default CardReward;
