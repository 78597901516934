import React from "react";
import { Alert, Snackbar } from "@mui/material";

const AlertResponse = ({ configAlert }) => {
  const { open, handleClose, severity, message } = configAlert;
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertResponse;
