import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Tabs, { tabsClasses } from "@mui/material/Tabs";
import { Container, Typography, Box, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";

//GPS Check in - out
import LocationList from "./GPSCheckInOut/LocationList";
import EditLocation from "./GPSCheckInOut/EditLocation/EditLocation";

//Calendar
import Calendar from "./calendar";

//leave-manage
import LeaveManage from "./leave-manage";

//job-manage
import JobManage from "./job-manage";

import Level from "./level";

import EmploymentType from "./employment-type";

import Payroll from "./payroll";

//user-manage
import UserManage from "./user-manage";

//institute-manage
import InstituteManage from "./institute-manage";

//admin-approve
import AdminApproveLeave from "./leave-manage/admin-approve";

//workTime-manage
import WorkTimeManage from "./workTime-approve";

//log-record
import LogRecord from "./log-record";

//pf
import PF from "./pf";

//probation
import Probation from "./probation";

//estimatePerformance
import EstimatePerformance from "./estimate-performance";
//estimatePerformanceStatus
import EstimatePerformanceStatus from "./estimate-performance-status";

//exitInterview
import ExitInterview from "./exit-interview";
import ExitInterviewStatus from "./exit-interview-status";

import CostElement from "./costElement";

import TaxDeductionSchedule from "./taxDeduction-schedule";

//Manager
import ManagerPanel from "./manager";
import ManagerPermissionPanel from "./manager-permission";

import OrganizationStructure from "./organization-structure";

import AdditionSpecial from "./addition-special";
import Reason from "./reason";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliateOrganization } from "../../../../actions/company";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";

//Translator TH-EN
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledWrapTabs = styled("div")({
  paddingBottom: 36,
  marginTop: 36,
  width: "100%",
});

const SettingPage = () => {

  const dispatch = useDispatch();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);
  const { t, i18n } = useTranslation();

  const [value, setValue] = useState(0);
  const [itemEdit, setItemEdit] = useState(null);
  const [viewGps, setViewGps] = useState({
    list: true,
    add: false,
    edit: false,
  });
  const [selectedCompany, setSelectedCompany] = useState(null);

  const handleClickAddGps = () => {
    const temp = {
      list: false,
      add: true,
      edit: false,
    };

    setViewGps(temp);
  };
  const handleClickSaveGps = () => {
    const temp = {
      list: true,
      add: false,
      edit: false,
    };

    setViewGps(temp);
  };
  const handleClickEditGps = (item) => {
    const temp = {
      list: false,
      add: false,
      edit: true,
    };

    setViewGps(temp);
    setItemEdit(item);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
  }

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, [])

  useEffect(() => {
    if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      let ownCompany = affiliateOrganizationList.find(x => x.idCompany === userProfile.idCompany);
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany)
    }
  }, [userProfile, affiliateOrganizationList])

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          {t("SystemInformation")}
        </Typography>
        <StyledWrapTabs>
          <Box>
            <Box marginBottom="24px">
              <SelectAffiliateCompany
                options={affiliateOrganizationList}
                value={selectedCompany}
                onChange={(_, value) => {
                  onChangeCompany(value)
                }}
              />
            </Box>
            {userProfile && (
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="visible arrows tabs example"
                sx={{
                  "& .MuiTab-root": {
                    textTransform: "none",
                  },
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                  "& .MuiTab-root.Mui-selected": {
                    color: "#46cbe2",
                  },
                }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#46cbe2",
                  },
                }}
              >
                <Tab label={`${t("GPSLocation")}`} {...a11yProps(0)} value={0} />
                <Tab
                  label={`${t("CompanyCalendar")}`}
                  {...a11yProps(1)}
                  value={1}
                />
                <Tab label={`${t("LeaveDay")}`} {...a11yProps(2)} value={2} />
                <Tab
                  label={`${t("EmploymentType")}`}
                  {...a11yProps(13)}
                  value={13}
                />
                <Tab
                  label={`${t("ManageJobPosition")}`}
                  {...a11yProps(3)}
                  value={3}
                />
                <Tab
                  label={`${t("ManageOrganizationalStructure")}`}
                  {...a11yProps(12)}
                  value={12}
                />
                <Tab
                  label={`${t("ManageJobLevel")}`}
                  {...a11yProps(4)}
                  value={4}
                />
                <Tab label={`${t("Manager")}`} {...a11yProps(11)} value={11} />
                {/* <Tab
                  label={`${t("ManagerPermission")}`}
                  {...a11yProps(22)}
                  value={22}
                /> */}
                {/* <Tab label="PAYROLL" {...a11yProps(5)} value={5} /> */}
                {/* <Tab label="จัดการเวลาทำงาน" {...a11yProps(6)} value={6} /> */}
                <Tab
                  label={`${t("ManageReasons")}`}
                  {...a11yProps(14)}
                  value={14}
                />
                <Tab
                  label={`${t("LogDataChanges")}`}
                  {...a11yProps(7)}
                  value={7}
                  disabled
                />
                <Tab
                  label={`${t("ProvidentFund")}`}
                  {...a11yProps(8)}
                  value={8}
                />
                <Tab
                  label={`${t("TaxDeductible")}`}
                  {...a11yProps(9)}
                  value={9}
                />
                {userProfile.isSuperAdmin && (
                  <Tab
                    label={`${t("Administrator")}`}
                    {...a11yProps(10)}
                    value={10}
                  />
                )}
                <Tab label="Cost Element" {...a11yProps(15)} value={15} />
                <Tab label={t("Probation")} {...a11yProps(16)} value={16} />
                <Tab
                  label={`${t("WorkEvaluationForm")}`}
                  {...a11yProps(17)}
                  value={17}
                />
                {/* <Tab
                  label={`${t("AnnualEmployeeEvaluation")}`}
                  {...a11yProps(18)}
                  value={18}
                /> */}
                <Tab
                  label={`${t("ExitInterviewForm")}`}
                  {...a11yProps(19)}
                  value={19}
                />
                <Tab
                  label={`${t("EmployeeOut")}`}
                  {...a11yProps(20)}
                  value={20}
                />
                <Tab
                  label={`${t("AdditionSpecial")}`}
                  {...a11yProps(21)}
                  value={21}
                />
              </Tabs>
            )}
          </Box>
          <TabPanel value={value} index={0}>
            {viewGps.list && (
              <LocationList
                onClick={handleClickAddGps}
                onClickEdit={handleClickEditGps}
                selectedCompany={selectedCompany}
                isReadOnly={userProfile && userProfile.readOnly}
              />
            )}
            {viewGps.edit && (
              <EditLocation
                dataEdit={itemEdit}
                onClickSave={handleClickSaveGps}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Calendar selectedCompany={selectedCompany} isReadOnly={userProfile && userProfile.readOnly} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <LeaveManage selectedCompany={selectedCompany} isReadOnly={userProfile && userProfile.readOnly} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <JobManage selectedCompany={selectedCompany} isReadOnly={userProfile && userProfile.readOnly} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Level selectedCompany={selectedCompany} isReadOnly={userProfile && userProfile.readOnly} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Payroll selectedCompany={selectedCompany} />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <WorkTimeManage />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <LogRecord />
          </TabPanel>
          <TabPanel value={value} index={8}>
            <PF selectedCompany={selectedCompany} isReadOnly={userProfile && userProfile.readOnly} />
          </TabPanel>
          <TabPanel value={value} index={9}>
            <TaxDeductionSchedule selectedCompany={selectedCompany} isReadOnly={userProfile && userProfile.readOnly} />
          </TabPanel>
          <TabPanel value={value} index={10}>
            <UserManage />
          </TabPanel>
          <TabPanel value={value} index={11}>
            <ManagerPanel selectedCompany={selectedCompany} isReadOnly={userProfile && userProfile.readOnly} />
          </TabPanel>
          <TabPanel value={value} index={12}>
            <OrganizationStructure selectedCompany={selectedCompany} isReadOnly={userProfile && userProfile.readOnly} />
          </TabPanel>
          <TabPanel value={value} index={13}>
            <EmploymentType selectedCompany={selectedCompany} isReadOnly={userProfile && userProfile.readOnly} />
          </TabPanel>
          <TabPanel value={value} index={14}>
            <Reason selectedCompany={selectedCompany} />
          </TabPanel>
          <TabPanel value={value} index={15}>
            <CostElement selectedCompany={selectedCompany} />
          </TabPanel>
          <TabPanel value={value} index={16}>
            <Probation selectedCompany={selectedCompany} isReadOnly={userProfile && userProfile.readOnly} />
          </TabPanel>
          <TabPanel value={value} index={17}>
            <EstimatePerformance selectedCompany={selectedCompany} isReadOnly={userProfile && userProfile.readOnly} />
          </TabPanel>
          {/* <TabPanel value={value} index={18}>
            <EstimatePerformanceStatus />
          </TabPanel> */}
          <TabPanel value={value} index={19}>
            <ExitInterview selectedCompany={selectedCompany} isReadOnly={userProfile && userProfile.readOnly} />
          </TabPanel>
          <TabPanel value={value} index={20}>
            <ExitInterviewStatus selectedCompany={selectedCompany} />
          </TabPanel>
          <TabPanel value={value} index={21}>
            <AdditionSpecial selectedCompany={selectedCompany} isReadOnly={userProfile && userProfile.readOnly} />
          </TabPanel>
          {/* <TabPanel value={value} index={22}>
            <ManagerPermissionPanel selectedCompany={selectedCompany} isReadOnly={userProfile && userProfile.readOnly} />
          </TabPanel> */}
        </StyledWrapTabs>
      </Container>
    </StyledRoot>
  );
};

export default SettingPage;
