import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Container,
  Grid,
  MenuItem,
  styled,
  Divider,
  Typography,
  Fade,
  Avatar,
  Button,
} from "@mui/material";
import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from "../../shared/general/Card";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { getAllRightsEmployeeFamily } from "../../../../actions/rightsWelfare";
import { Image } from "@mui/icons-material";
import utils from "../../../../utils";
import AnimationBackdrop from "../../shared/general/Backdrop";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  maxWidth: "1280px !important",
  margin: "auto",
});

const DetailButton = styled(Button)(({ variant, theme }) => ({
  color: "#46cbe2",
  backgroundColor: "transparent",
  border: "solid",
  borderColor: "#46cbe2",
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 4,
  paddingBottom: 4,
  borderRadius: 18,
  marginBottom: 12,
  fontSize: 12,
  "&:hover": {
    color: "#ffffff",
    backgroundColor: "#46cbe2",
  },
}));

const StyledCard = styled(Card)(() => ({
  padding: 4,
  margin: 6,
  borderColor: "#636B74",
  borderRadius: 12,
  boxShadow: "0px 8px 12px 4px rgba(212,212,212,0.75)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const StyledBox = styled(Box)(() => ({
  height: "85%",
  padding: 20,
  margin: "36px 6px ",
  borderColor: "#636B74",
  borderRadius: 12,
  boxShadow: "0px 4px 12px 4px rgba(212,212,212,0.25)",
}));

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 8,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const PersonCard = (props) => {
  const { t, i18n } = useTranslation();
  const { data, index, handleOnClickDetail } = props;

  return (
    <StyledCard>
      <CardContent
        style={{
          textAlign: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 12,
            marginTop: 12,
          }}
        >
          <Avatar style={{ minHeight: 64, minWidth: 64 }} src={data.imageURL} />
        </Box>
        <Typography
          gutterBottom
          variant="subtitle1"
          style={{ fontWeight: 500 }}
        >
          {i18n.resolvedLanguage == "th"
            ? `${data.firstname_TH} ${data.lastname_TH}`
            : `${data.firstname_EN} ${data.lastname_EN}`}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {t(data.relationship)}
        </Typography>
      </CardContent>
      <CardActions style={{ justifyContent: "center" }}>
        <DetailButton onClick={() => handleOnClickDetail(index)}>
          {t("ViewMore")}
        </DetailButton>
      </CardActions>
    </StyledCard>
  );
};

const DetailCard = (props) => {
  const { t, i18n } = useTranslation();
  const { data, checked } = props;

  return (
    <StyledBox>
      <Fade in={checked}>
        <div>
          <Typography
            gutterBottom
            sx={{
              paddingLeft: "8px",
              borderLeft: "6px solid #46cbe2",
              typography: "h6",
              marginBottom: 2,
            }}
          >
            {data.rightsName}
          </Typography>
          <Grid container spacing={2} style={{ padding: 8 }}>
            {data.principle === "totalLimit" && (
              <>
                <Grid item xs={6}>
                  <Typography>{t("CoverageLimit")}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {utils.numberWithCommas(data.limit)}&nbsp;&nbsp;{t("Baht")}
                  </Typography>
                </Grid>

                {data.isHelpingSurplus === 1 && (
                  <>
                    <StyledDivider />
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <Typography>{t("PartialReimbursement")}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography style={{ paddingLeft: 12 }}>
                          {data.isHelpingSurplus === 1 ? "" : t("NoFee")}
                        </Typography>
                      </Grid>
                      <Grid marginLeft={1} item xs={12} md={12}>
                        {data.isHelpingSurplus === 1 &&
                          data.Surplus &&
                          data.Surplus.map((item, index) => (
                            <Grid
                              marginTop={0.5}
                              item
                              key={`surplus${item.idSurplus}`}
                              container
                              xs={12}
                            >
                              <Grid marginTop={0.5} item xs={12} md={6}>
                                <Typography
                                  fontSize="14px"
                                  fontWeight="500"
                                  color="text.secondary"
                                >{`${t("ExcessTier")} ${
                                  index + 1
                                }`}</Typography>
                                <Typography>
                                  {item.numberSurplus
                                    ? `${utils.numberWithCommas(
                                        item.numberSurplus
                                      )} ${t("Baht")}`
                                    : "-"}
                                </Typography>
                              </Grid>
                              <Grid marginTop={0.5} item xs={12} md={6}>
                                <Typography
                                  fontSize="14px"
                                  fontWeight="500"
                                  color="text.secondary"
                                >
                                  {t("AdditionalAssistanceCompany")}(%)
                                </Typography>
                                <Typography>
                                  {item.surplusPercent
                                    ? utils.numberWithCommas(
                                        item.surplusPercent
                                      )
                                    : "-"}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
            {data.principle === "individualLimit" && (
              <>
                <Grid item xs={6}>
                  <Typography>{t("PersonalBudget")}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {utils.numberWithCommas(data.limitSelf)}&nbsp;&nbsp;
                    {t("Baht")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{t("SpousalBudget")}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {utils.numberWithCommas(data.limitCouple)}&nbsp;&nbsp;
                    {t("Baht")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{t("ParentalBudget")}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {utils.numberWithCommas(data.limitParents)}&nbsp;&nbsp;
                    {t("Baht")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{t("ChildBudget")}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {utils.numberWithCommas(data.limitChild)}&nbsp;&nbsp;
                    {t("Baht")}
                  </Typography>
                </Grid>
                {data.isHelpingSurplusSelf === 1 ||
                data.isHelpingSurplusCouple === 1 ||
                data.isHelpingSurplusParents === 1 ||
                data.isHelpingSurplusChild === 1 ? (
                  <StyledDivider />
                ) : (
                  <></>
                )}

                {data.isHelpingSurplusSelf === 1 && (
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Typography>{t("PartialReimbursement")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ paddingLeft: 12 }}>
                        {data.isHelpingSurplusSelf === 1 ? "" : t("NoFee")}
                      </Typography>
                    </Grid>
                    <Grid marginLeft={1} item xs={12} md={12}>
                      {data.isHelpingSurplusSelf === 1 &&
                        data.SurplusSelf &&
                        data.SurplusSelf.map((item, index) => (
                          <Grid
                            marginTop={0.5}
                            item
                            key={`surplus${item.idSurplusSelf}`}
                            container
                            xs={12}
                          >
                            <Grid marginTop={0.5} item xs={12} md={6}>
                              <Typography
                                fontSize="14px"
                                fontWeight="500"
                                color="text.secondary"
                              >{`${t("ExcessTier")} ${index + 1}`}</Typography>
                              <Typography>
                                {item.numberSurplus
                                  ? `${utils.numberWithCommas(
                                      item.numberSurplus
                                    )} ${t("Baht")}`
                                  : "-"}
                              </Typography>
                            </Grid>
                            <Grid marginTop={0.5} item xs={12} md={6}>
                              <Typography
                                fontSize="14px"
                                fontWeight="500"
                                color="text.secondary"
                              >
                                {t("AdditionalAssistanceCompany")}(%)
                              </Typography>
                              <Typography>
                                {item.surplusPercent
                                  ? utils.numberWithCommas(item.surplusPercent)
                                  : "-"}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                )}
                {data.isHelpingSurplusCouple === 1 && (
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Typography>{t("ExcessAssistanceSpouse")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ paddingLeft: 12 }}>
                        {data.isHelpingSurplusCouple === 1 ? "" : t("NoFee")}
                      </Typography>
                    </Grid>
                    <Grid marginLeft={1} item xs={12} md={12}>
                      {data.isHelpingSurplusCouple === 1 &&
                        data.SurplusCouple &&
                        data.SurplusCouple.map((item, index) => (
                          <Grid
                            marginTop={0.5}
                            item
                            key={`surplus${item.idSurplus}`}
                            container
                            xs={12}
                          >
                            <Grid marginTop={0.5} item xs={12} md={6}>
                              <Typography
                                fontSize="14px"
                                fontWeight="500"
                                color="text.secondary"
                              >{`${t("ExcessTier")} ${index + 1}`}</Typography>
                              <Typography>
                                {item.numberSurplus
                                  ? `${utils.numberWithCommas(
                                      item.numberSurplus
                                    )} ${t("Baht")}`
                                  : "-"}
                              </Typography>
                            </Grid>
                            <Grid marginTop={0.5} item xs={12} md={6}>
                              <Typography
                                fontSize="14px"
                                fontWeight="500"
                                color="text.secondary"
                              >
                                {t("AdditionalAssistanceCompany")}(%)
                              </Typography>
                              <Typography>
                                {item.surplusPercent
                                  ? utils.numberWithCommas(item.surplusPercent)
                                  : "-"}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                )}
                {data.isHelpingSurplusParents === 1 && (
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Typography>{t("ExcessAssistanceParents")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ paddingLeft: 12 }}>
                        {data.isHelpingSurplusParents === 1 ? "" : t("NoFee")}
                      </Typography>
                    </Grid>
                    <Grid marginLeft={1} item xs={12} md={12}>
                      {data.isHelpingSurplusParents === 1 &&
                        data.SurplusParents &&
                        data.SurplusParents.map((item, index) => (
                          <Grid
                            marginTop={0.5}
                            item
                            key={`surplus${item.idSurplus}`}
                            container
                            xs={12}
                          >
                            <Grid marginTop={0.5} item xs={12} md={6}>
                              <Typography
                                fontSize="14px"
                                fontWeight="500"
                                color="text.secondary"
                              >{`${t("ExcessTier")} ${index + 1}`}</Typography>
                              <Typography>
                                {item.numberSurplus
                                  ? `${utils.numberWithCommas(
                                      item.numberSurplus
                                    )} ${t("Baht")}`
                                  : "-"}
                              </Typography>
                            </Grid>
                            <Grid marginTop={0.5} item xs={12} md={6}>
                              <Typography
                                fontSize="14px"
                                fontWeight="500"
                                color="text.secondary"
                              >
                                {t("AdditionalAssistanceCompany")}(%)
                              </Typography>
                              <Typography>
                                {item.surplusPercent
                                  ? utils.numberWithCommas(item.surplusPercent)
                                  : "-"}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                )}
                {data.isHelpingSurplusChild === 1 && (
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Typography>{t("ExcessAssistanceChildren")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ paddingLeft: 12 }}>
                        {data.isHelpingSurplusChild === 1 ? "" : t("NoFee")}
                      </Typography>
                    </Grid>
                    <Grid marginLeft={1} item xs={12} md={12}>
                      {data.isHelpingSurplusChild === 1 &&
                        data.SurplusChild &&
                        data.SurplusChild.map((item, index) => (
                          <Grid
                            marginTop={0.5}
                            item
                            key={`surplus${item.idSurplus}`}
                            container
                            xs={12}
                          >
                            <Grid marginTop={0.5} item xs={12} md={6}>
                              <Typography
                                fontSize="14px"
                                fontWeight="500"
                                color="text.secondary"
                              >{`${t("ExcessTier")} ${index + 1}`}</Typography>
                              <Typography>
                                {item.numberSurplus
                                  ? `${utils.numberWithCommas(
                                      item.numberSurplus
                                    )} ${t("Baht")}`
                                  : "-"}
                              </Typography>
                            </Grid>
                            <Grid marginTop={0.5} item xs={12} md={6}>
                              <Typography
                                fontSize="14px"
                                fontWeight="500"
                                color="text.secondary"
                              >
                                {t("AdditionalAssistanceCompany")}(%)
                              </Typography>
                              <Typography>
                                {item.surplusPercent
                                  ? utils.numberWithCommas(item.surplusPercent)
                                  : "-"}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            {data.principle === "detail" && (
              <>
                {data.OPD_Principle === "OPD_totalLimit" && (
                  <>
                    <Grid item xs={12}>
                      <StyledDivider />
                      <Typography>OPD</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{t("CoverageLimit")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        {utils.numberWithCommas(data.limitOPD)}&nbsp;&nbsp;
                        {t("Baht")}
                      </Typography>
                    </Grid>
                    {data.isHelpingSurplusOPD === 1 && (
                      <Grid item xs={12}>
                        <Grid item xs={12}>
                          <Typography>{t("PartialReimbursement")}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography style={{ paddingLeft: 12 }}>
                            {data.isHelpingSurplusOPD === 1 ? "" : t("NoFee")}
                          </Typography>
                        </Grid>
                        <Grid marginLeft={1} item xs={12} md={12}>
                          {data.isHelpingSurplusOPD === 1 &&
                            data.SurplusOPD &&
                            data.SurplusOPD.map((item, index) => (
                              <Grid
                                marginTop={0.5}
                                item
                                key={`surplus${item.idSurplusOPD}`}
                                container
                                xs={12}
                              >
                                <Grid marginTop={0.5} item xs={12} md={6}>
                                  <Typography
                                    fontSize="14px"
                                    fontWeight="500"
                                    color="text.secondary"
                                  >{`${t("ExcessTier")} ${
                                    index + 1
                                  }`}</Typography>
                                  <Typography>
                                    {item.numberSurplus
                                      ? `${utils.numberWithCommas(
                                          item.numberSurplus
                                        )} ${t("Baht")}`
                                      : "-"}
                                  </Typography>
                                </Grid>
                                <Grid marginTop={0.5} item xs={12} md={6}>
                                  <Typography
                                    fontSize="14px"
                                    fontWeight="500"
                                    color="text.secondary"
                                  >
                                    {t("AdditionalAssistanceCompany")}(%)
                                  </Typography>
                                  <Typography>
                                    {item.surplusPercent
                                      ? utils.numberWithCommas(
                                          item.surplusPercent
                                        )
                                      : "-"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
                {data.OPD_Principle === "OPD_number" && (
                  <>
                    <Grid item xs={2}>
                      <Typography>OPD</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>
                        {utils.numberWithCommasWithoutDot(data.OPD_Number)}
                        &nbsp;&nbsp;{t("Times")}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography>
                        {t("Pertime")}&nbsp;&nbsp;
                        {utils.numberWithCommas(data.OPD_MoneyPerTimes)}
                        &nbsp;&nbsp;{t("Baht")}
                      </Typography>
                    </Grid>
                  </>
                )}

                {data.IPD_Principle === "IPD_totalLimit" && (
                  <>
                    <Grid item xs={12}>
                      <StyledDivider />
                      <Typography>IPD</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{t("CoverageLimit")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        {utils.numberWithCommas(data.limitIPD)}&nbsp;&nbsp;
                        {t("Baht")}
                      </Typography>
                    </Grid>
                    {data.isHelpingSurplusIPD === 1 && (
                      <Grid item xs={12}>
                        <Grid item xs={12}>
                          <Typography>{t("PartialReimbursement")}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography style={{ paddingLeft: 12 }}>
                            {data.isHelpingSurplusIPD === 1 ? "" : t("NoFee")}
                          </Typography>
                        </Grid>
                        <Grid marginLeft={1} item xs={12} md={12}>
                          {data.isHelpingSurplusIPD === 1 &&
                            data.SurplusIPD &&
                            data.SurplusIPD.map((item, index) => (
                              <Grid
                                marginTop={0.5}
                                item
                                key={`surplus${item.idSurplusIPD}`}
                                container
                                xs={12}
                              >
                                <Grid marginTop={0.5} item xs={12} md={6}>
                                  <Typography
                                    fontSize="14px"
                                    fontWeight="500"
                                    color="text.secondary"
                                  >{`${t("ExcessTier")} ${
                                    index + 1
                                  }`}</Typography>
                                  <Typography>
                                    {item.numberSurplus
                                      ? `${utils.numberWithCommas(
                                          item.numberSurplus
                                        )} ${t("Baht")}`
                                      : "-"}
                                  </Typography>
                                </Grid>
                                <Grid marginTop={0.5} item xs={12} md={6}>
                                  <Typography
                                    fontSize="14px"
                                    fontWeight="500"
                                    color="text.secondary"
                                  >
                                    {t("AdditionalAssistanceCompany")}(%)
                                  </Typography>
                                  <Typography>
                                    {item.surplusPercent
                                      ? utils.numberWithCommas(
                                          item.surplusPercent
                                        )
                                      : "-"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
                {data.IPD_Principle === "IPD_detail" && data.IPD && (
                  <>
                    <Grid item xs={12}>
                      <StyledDivider />
                      <Typography>IPD</Typography>
                    </Grid>
                    {data.IPD.map((item, index) => (
                      <>
                        <Grid item xs={6}>
                          <Typography style={{ paddingLeft: 12, fontSize: 16 }}>
                            &#x2022;&nbsp;&nbsp;{item.nameList}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>
                            {utils.numberWithCommas(item.limitList)}
                            &nbsp;&nbsp;{t("Baht")}
                          </Typography>
                        </Grid>
                      </>
                    ))}
                  </>
                )}
                {data.Dental_Principle === "Dental_totalLimit" && (
                  <>
                    <Grid item xs={12}>
                      <StyledDivider />
                      <Typography>{t("Dental")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{t("CoverageLimit")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        {utils.numberWithCommas(data.limitDental)}
                        &nbsp;&nbsp;{t("Baht")}
                      </Typography>
                    </Grid>
                    {data.isHelpingSurplusDental === 1 && (
                      <Grid item xs={12}>
                        <Grid item xs={12}>
                          <Typography>{t("PartialReimbursement")}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography style={{ paddingLeft: 12 }}>
                            {data.isHelpingSurplusDental === 1
                              ? ""
                              : t("NoFee")}
                          </Typography>
                        </Grid>
                        <Grid marginLeft={1} item xs={12} md={12}>
                          {data.isHelpingSurplusDental === 1 &&
                            data.SurplusDental &&
                            data.SurplusDental.map((item, index) => (
                              <Grid
                                marginTop={0.5}
                                item
                                key={`surplus${item.idSurplusDental}`}
                                container
                                xs={12}
                              >
                                <Grid marginTop={0.5} item xs={12} md={6}>
                                  <Typography
                                    fontSize="14px"
                                    fontWeight="500"
                                    color="text.secondary"
                                  >{`${t("ExcessTier")} ${
                                    index + 1
                                  }`}</Typography>
                                  <Typography>
                                    {item.numberSurplus
                                      ? `${utils.numberWithCommas(
                                          item.numberSurplus
                                        )} ${t("Baht")}`
                                      : "-"}
                                  </Typography>
                                </Grid>
                                <Grid marginTop={0.5} item xs={12} md={6}>
                                  <Typography
                                    fontSize="14px"
                                    fontWeight="500"
                                    color="text.secondary"
                                  >
                                    {t("AdditionalAssistanceCompany")}(%)
                                  </Typography>
                                  <Typography>
                                    {item.surplusPercent
                                      ? utils.numberWithCommas(
                                          item.surplusPercent
                                        )
                                      : "-"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
                {data.Dental_Principle === "Dental_detail" && (
                  <>
                    <Grid item xs={12}>
                      <StyledDivider />
                      <Typography>{t("Dental")}</Typography>
                    </Grid>
                    {data.Dental &&
                      data.Dental.map((item, index) => (
                        <>
                          <Grid item xs={6}>
                            <Typography
                              style={{ paddingLeft: 12, fontSize: 16 }}
                            >
                              &#x2022;&nbsp;&nbsp;{item.nameList}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>
                              {utils.numberWithCommas(item.limitList)}
                              &nbsp;&nbsp;{t("Baht")}
                            </Typography>
                          </Grid>
                        </>
                      ))}
                  </>
                )}
              </>
            )}
          </Grid>
        </div>
      </Fade>
    </StyledBox>
  );
};

const MyWelfareRights = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const rightDetailRef = useRef(null);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: welfareRights } = useSelector((state) => state.welfareRights);
  const [isOpen, setIsOpen] = useState(false);
  const [targetData, setTagetData] = useState(undefined);

  useEffect(() => {
    if (userProfile) {
      dispatch(getAllRightsEmployeeFamily());
    }
  }, [userProfile]);

  const handleScroll = () => {
    if (rightDetailRef && rightDetailRef.current) {
      rightDetailRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleOnClickDetail = (index) => {
    setIsOpen(true);
    setTagetData(index);
    handleScroll();
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {welfareRights ? (
          <>
            <div>
              <Typography variant="h4" style={{ marginBottom: 36 }}>
                {t("MyRights")}
              </Typography>
            </div>
            <div>
              <Grid container spacing={3}>
                {welfareRights &&
                  welfareRights.map((member, index) => (
                    <Grid item xs={12} md={3}>
                      <PersonCard
                        data={member}
                        index={index}
                        handleOnClickDetail={handleOnClickDetail}
                      />
                    </Grid>
                  ))}
              </Grid>
            </div>
            <div>
              <Grid container spacing={3}>
                {welfareRights &&
                  welfareRights.map((member, index) =>
                    isOpen === true && targetData === index
                      ? member.allRights.map((item) => (
                          <Grid item xs={12} md={6} ref={rightDetailRef}>
                            <DetailCard data={item} checked={true} />
                          </Grid>
                        ))
                      : null
                  )}
              </Grid>
            </div>
          </>
        ) : (
          <AnimationBackdrop />
        )}
      </Container>
    </StyledRoot>
  );
};

export default MyWelfareRights;
