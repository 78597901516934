import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import { getUserDepartment, getUserDivision, getUserFirstName, getUserLastName, getUserPosition, getUserSection } from '../../../../../utils/userData';

export const ExportExcel = async (t,data = []) => {

  const headerCellStyle = { 
    font: { 
      name: "Tahoma", 
      size: 14,
      color: {argb: "FFFFFF"}
    }, 
    alignment: { 
      vertical: "middle", 
      horizontal: "center",
      wrapText: true,
    },
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: "002060"}
    },
    // numFmt: "@",
  }

  const dataCellStyle = { 
    font: { 
      name: "Tahoma", 
      size: 14,
    }, 
    alignment: { 
      vertical: "middle", 
      horizontal: "center" 
    },
    // numFmt: "@",
  }

  const workBook = new ExcelJS.Workbook();

  const worksheet = workBook.addWorksheet(t("EmployeeOut"));

  const headerRow = worksheet.addRow([
    t("EmployeeID"), t("FirstName"), t("LastName"), t("Division"), t("Department"), t("Section"), t("Position"), t("EmploymentType"), t("ContractTerminationDate")
  ]);

  headerRow.eachCell((cell) => {
    cell.style = headerCellStyle;
    cell.numFmt = "@";
  });

  headerRow.height = 50;

  const colWidths = [
    { key: "employeeID", width: 30 },
    { key: "firstname_TH", width: 50 },
    { key: "lastname_TH", width: 50 },
    { key: "divisionName", width: 50 },
    { key: "departmentName", width: 50 },
    { key: "sectionName", width: 50 },
    { key: "positionName", width: 50 },
    { key: "employmentTypeName", width: 50 },
    { key: "contractTerminationDate", width: 30 },
  ];

  colWidths.forEach((col, index) => {
    worksheet.getColumn(index + 1).width = col.width;
  });

  data.map(item => {
    const row = [
      item.employeeID || "",
      getUserFirstName(item) || "",
      getUserLastName(item) || "",
      getUserDivision(item) || "",
      getUserDepartment(item) || "",
      getUserSection(item) || "",
      getUserPosition(item) || "",
      item.employmentTypeName || "",
      item.contractTerminatoinDate? dayjs(item.contractTerminatoinDate).format("DD/MM/YYYY") :"",
    ]

    const excelRow = worksheet.addRow(row);
    excelRow.eachCell((cell) => {
      cell.style = dataCellStyle;
      cell.numFmt = "@";
    });
  })

  workBook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${t("ContractExpiryReport")}.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  });
}