import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Rating,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";
import { AccessTimeRounded } from "@mui/icons-material";
import { updateKPIEvaluationEmployee } from "../../../../../actions/kpi";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { useHistory } from "react-router-dom";
import AlertResponse from "../../../shared/general/AlertResponse";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";

const StyledRoot = styled(Box)({});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  borderRadius: '30px',
  overflow: 'hidden',
  border: '1px solid #EEEEEE',
  display: 'flex',
  justifyContent: 'center',
  width: 'fit-content',
}));

const StyledToggleButton = styled(ToggleButton)(({ theme, selectedColor }) => ({
  flex: 1,
  borderRadius: '30px',
  '&.Mui-selected': {
    backgroundColor: selectedColor,
    color: '#FFFFFF',
  },
  '&.MuiToggleButtonGroup-grouped': {
    border: 'none',
    '&:not(:first-of-type)': {
      borderLeft: '1px solid #EEEEEE',
    },
  },
  '&:hover': {
    backgroundColor: `${selectedColor} !important`,
    color: '#FFFFFF',
  },
  backgroundColor: '#EEEEEE',
  color: '#000000',
  textAlign: 'center',
  minWidth: '100px',
  maxWidth: '200px',
}));

const KpiEvaluationEmployee = (props) => {
  const { data } = props;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [configAlert, setConfigAlert] = useState({
    open: false,
    alertType: "",
  });

  const [ratingValue, setRatingValue] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleClose = () => {
    setConfigAlert({ configAlert, open: false });
  };

  const { control, handleSubmit } = useForm({
    defaultValues: {
      employeeRating: null,
      employeeFeedback: "",
      employeeEvaluate: null,
    },
  });

  const handleEvaluation = async (formData) => {
    formData.employeeEvaluate = formData.employeeEvaluate === "1" ? 1 : 0;
    formData.employeerEvaluateAt = dayjs().format("YYYY-MM-DD HH:mm:ss");

    const res = await dispatch(updateKPIEvaluationEmployee(data.idKPI, formData));
    if (res && res.status === 200) {
      await Swal.fire({
        title: t("EvaluationSuccess"),
        icon: "success",
        timer: 2000, 
        showConfirmButton: false, 
      });
      

      setIsSubmitted(true);
      history.replace("/kpi-evaluation-list");
    }
  };

  const onSubmit = async (formData) => {
    try {
      const result = await Swal.fire({
        title: t("ConfirmEvaluation"),
        text: t("AreYouSureToConfirmEvaluation?"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Confirm"),
        cancelButtonText: t("Cancel"),
      });

      if (result.isConfirmed) {
        await handleEvaluation(formData);
      }
    } catch (error) {
      console.error("Error displaying alert:", error);
    }
  };

  const renderEmployeeEvaluate = (evaluate) => {
    if (evaluate === 1) {
      return (
        <Typography
          whiteSpace="pre-line"
          style={{ color: "#50B498", fontSize: "26px", fontWeight: 500 }}
        >
          {`${t("Success")}`}
        </Typography>
      );
    } else if (evaluate === 0) {
      return (
        <Typography
          whiteSpace="pre-line"
          style={{ color: "#FF6969", fontSize: "26px", fontWeight: 500 }}
        >
          {`${t("Unsuccess")}`}
        </Typography>
      );
    }
    return null;
  };

  const renderEmployeeRating = (rating) => {
    if (rating !== null) {
      return (
        <Box display="flex" alignItems="center" gap="4px">
          <Typography sx={{ fontSize: "22px", fontWeight: 500, color: "#FFC403", marginLeft: "4px" }}>
            {rating}.0
          </Typography>
          {Array.from({ length: rating }, (_, index) => (
            <StarIcon key={index} sx={{ fontSize: "26px", color: "#FFC403" }} />
          ))}
        </Box>
      );
    }
    return null;
  };

  return (
    <StyledRoot className="page">
      <Container>
        <div className="cardPlan">
          <Box padding="2px">
            <Typography fontSize="40px" fontWeight="600" style={{ color: '#083A83' }}>
              {t("KPIEvaluateDetails")}
            </Typography>
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
              flexDirection="column"
            >
              <Box>
                <Typography fontSize="22px" marginTop="4px">{data.kpiName}</Typography>
                <Box display="flex" alignItems="center" marginTop="8px">
                  <AccessTimeRounded
                    fontSize="14px"
                    style={{ marginRight: "8px", color: "#919eab" }}
                  />
                  <Typography
                    fontSize="16px"
                    lineHeight="1"
                    color="text.third"
                  >{`${dayjs(data.startDate).format(
                    i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                  )} - ${dayjs(data.endDate).format(
                    i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                  )}`}</Typography>
                </Box>
              </Box>
              <Box marginTop="8px" marginBottom="16px">
                <Typography marginBottom="8px">
                  <span style={{ color: "#919eab", paddingRight: "8px", fontSize: "16px" }}>{`${t("Scope")}`}</span>
                  {data.scope}
                </Typography>
                <Typography marginBottom="8px">
                  <span style={{ color: "#919eab", paddingRight: "8px", fontSize: "16px" }}>{`${t("Target")}`}</span>
                  {data.target}
                </Typography>
                <Typography marginBottom="8px">
                  <span style={{ color: "#919eab", paddingRight: "8px", fontSize: "16px" }}>{`${t("TypeMeasurement")}`}</span>
                  {data.measureType}
                </Typography>
                <Typography>
                  <span style={{ color: "#919eab", paddingRight: "8px", fontSize: "16px" }}>{`${t("Descriptions")}`}</span>
                  {data.description}
                </Typography>
              </Box>
            </Box>

            {data.employeeEvaluate === null && !isSubmitted ? (
              <Box marginTop="8px">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} display="flex" alignItems="center">
                      <Box>
                        <Controller
                          name="employeeEvaluate"
                          control={control}
                          render={({ field }) => (
                            <div>
                              <Typography fontSize={16} marginBottom="8px">{`${t("Evaluate")}`}</Typography>
                              <StyledToggleButtonGroup {...field} exclusive value={field.value}>
                                <StyledToggleButton value="1" selectedColor="#50B498">{`${t("Success")}`}</StyledToggleButton>
                                <StyledToggleButton value="0" selectedColor="#FF4C4C">{`${t("Failure")}`}</StyledToggleButton>
                              </StyledToggleButtonGroup>
                            </div>
                          )}
                        />
                      </Box>
                      <Box marginLeft="30px">
                        <Typography fontSize={16} marginBottom="4px">{t("Rating")} {ratingValue ? `${ratingValue}.0` : ''}</Typography>
                        <Controller
                          name="employeeRating"
                          control={control}
                          render={({ field }) => (
                            <Box display="flex" gap="8px" paddingLeft="16px" maxWidth="400px">
                              <Rating
                                {...field}
                                name="employeeRating"
                                min={1}
                                max={5}
                                precision={1}
                                value={ratingValue}
                                icon={
                                  <StarIcon
                                    sx={{
                                      fontSize: "50px",
                                      color: "#FFC403",
                                    }}
                                  />
                                }
                                emptyIcon={
                                  <StarBorderIcon
                                    sx={{
                                      fontSize: "50px",
                                      color: "#ddd",
                                    }}
                                  />
                                }
                                onChange={(_, newValue) => {
                                  field.onChange(newValue);
                                  setRatingValue(newValue);
                                }}
                              />
                            </Box>
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="employeeFeedback"
                        control={control}
                        render={({ field }) => (
                          <TextFieldTheme
                            {...field}
                            label={t("Feedback")}
                            multiline
                            rows={2}
                            inputProps={{ maxLength: 200 }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                      <ButtonBlue type="submit" variant="contained">
                        {t("Evaluate")}
                      </ButtonBlue>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            ) : (
              <Box marginTop="8px">
                <Typography whiteSpace="pre-line" style={{ marginTop: "8px" }}>
                  <span style={{ color: "#919eab", paddingRight: "8px" }}>
                    {`${t("employeeEvaluate")}`}
                  </span>
                </Typography>
                <Box display="flex" alignItems="center" gap="36px">
                  {renderEmployeeEvaluate(data.employeeEvaluate)}
                  {renderEmployeeRating(data.employeeRaitng)}
                </Box>
                {data.employeeFeedback && (
                  <Typography whiteSpace="pre-line">
                    <span style={{ color: "#919eab", paddingRight: "8px" }}>
                      {`${t("EmployeeFeedback")}`}
                    </span>
                    {data.employeeFeedback}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </div>
        <AlertResponse {...configAlert} handleClose={handleClose} />
      </Container>
    </StyledRoot>
  );
};

export default KpiEvaluationEmployee;
