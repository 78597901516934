import React from "react";
import { useSelector } from "react-redux";

import AccountantHistoryExpense from "../../admin-accountant/expense/history";
import ManagerHistoryExpense from "../../manager/expense/history";

const ExpenseHistoryPage = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <div>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ACCOUNT") ? (
          <AccountantHistoryExpense />
        ) : userProfile.roles.includes("ROLE_MANAGER") ? (
          <ManagerHistoryExpense />
        ) : null
      ) : null}
    </div>
  );
};

export default ExpenseHistoryPage;
