import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getDocumentGroupName,
} from "../../../../actions/document";

import DocumentPage from "./documentPage";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginTop: 24,
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    color: "#46cbe2",
    border: "1px solid #46cbe2",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#247785",
      backgroundColor: "#247785",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#46cbe2",
      "&:hover": {
        borderColor: "#247785",
        backgroundColor: "#247785",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #46cbe2",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
  },
}));

const DocumentEmployee = () => {
  const dispatch = useDispatch();
  const { result: List } = useSelector((state) => state.documentGroup);
  const [view, setView] = React.useState(1);
  const { t, i18n } = useTranslation();
  const [idDocumentGroup, setIdDocumentGroup] = useState(1);

  useEffect(() => {
    dispatch(getDocumentGroupName());
  }, []);

  const handleChangeView = (event, newView) => {
    if (newView) {
      setView(newView);
      setIdDocumentGroup(newView);
    }
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            {t("EmployeeDocuments")}
          </Typography>
        </div>

        <StyledToggleButtonGroup
          value={view}
          exclusive
          onChange={handleChangeView}
          aria-label="view"
          size="small"
        >
          {List &&
            List.map((v, index) => {
              return (
                <ToggleButton
                  name={index}
                  value={v.idDocumentGroup}
                  size="small"
                  style={{ minWidth: 150, marginRight: 12 }}
                >
                  {v.documentGroupName}
                </ToggleButton>
              );
            })}
        </StyledToggleButtonGroup>

        <DocumentPage
          idDocumentGroup={idDocumentGroup}
        />
      </Container>
    </StyledRoot>
  );
};

export default DocumentEmployee;