import React, { useState, useEffect, Suspense } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Typography, Avatar } from "@mui/material";

import Day from "../assets/day.png";
import Night from "../assets/night.png";
import Mid from "../assets/mid.png";

import AvatarOff from "../../../shared/general/AvatarOff";
import AvatarShift from "../../../shared/general/AvatarShift";
import AvatarPeriod from "../../../shared/general/AvatarPeriod";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  mutliDragAwareReorder,
  multiSelectTo as multiSelect,
} from "./dnd/utils";

const primaryButton = 0;
const keyCodes = {
  enter: 13,
  escape: 27,
  arrowDown: 40,
  arrowUp: 38,
  tab: 9,
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getListStyle = (isDraggingOver) => ({
  display: "flex",
  flexGrow: 1,
  padding: 6,
  overflow: "auto",
  width: "100%",
  backgroundColor: "#f3f3f3a8",
  borderRadius: 20,
  alignItems: "center",
  justifyContent: "space-between",
});

const StyledCardParent = styled(Card)(({}) => ({
  width: 160,
  borderRadius: 20,
  "& .cardContent": {
    padding: "24px !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .MuiTypography-h6": {
      fontSize: 22,
    },
    "& .wrapDrag": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: 100,
      "& .MuiTypography-body1": {
        fontWeight: 600,
        fontSize: 18,
      },
    },
  },
}));

const StyledCardDayNight = styled(Card)(({}) => ({
  boxShadow: "none",
  borderRadius: 20,
  "& .cardContent": {
    padding: 16,
    display: "flex",
    "& .title": {
      textTransform: "uppercase",
    },
    "& .off": {
      filter: "grayscale(100%)",
    },
  },
}));

const StyledWrapCardDayNight = styled("div")({
  display: "flex",
  justifyContent: "space-evenly",
  marginBottom: 24,
});

export default function StepThree(props) {
  const { dataNewShift, onChangeDataNewShift } = props;
  const [state, setState] = useState({
    entities: null,
    tasks: {},
    selectedTaskIds: [],
    draggingTaskId: "",
  });

  useEffect(() => {
    if (dataNewShift.patternShift === null) {
      let shift = ["A", "B", "C", "D", "E", "F", "G", "H"];
      var countId = 0;
      var tempDataNewShift = { ...dataNewShift };
      const shiftItem = [];
      tempDataNewShift.patternShift = {};
      tempDataNewShift.periodTime.forEach((element) => {
        if (element.check) {
          for (let index = 0; index < tempDataNewShift.workday; index++) {
            const temp = {
              id: countId.toString(),
              title: element.periodTimeEN,
            };
            shiftItem.push(temp);
            countId++;
          }
          for (let index = 0; index < tempDataNewShift.offday; index++) {
            const temp = {
              id: countId.toString(),
              title: "OFF",
            };
            shiftItem.push(temp);
            countId++;
          }
        }
      });

      console.log(shiftItem);

      for (let index = 0; index < tempDataNewShift.amountShift; index++) {
        tempDataNewShift.patternShift["shift" + shift[index]] = shiftItem;
      }

      // const tempEntities = {
      //   columnOrder: tempDataNewShift.periodTime.filter(item => item.check),
      //   columns: {
      //     [todo.id]: todo,
      //     [done.id]: done
      //   },
      //   tasks: taskMap
      // }

      console.log(tempDataNewShift);
      onChangeDataNewShift(tempDataNewShift);
      //setState({ ...state, ["entities"]: tempEntities });
    }
  }, []);

  function onDragEnd(result) {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        dataNewShift.patternShift[source.droppableId],
        source.index,
        destination.index
      );

      var tempDataNewShift = { ...dataNewShift };
      tempDataNewShift.patternShift[source.droppableId] = items;
      onChangeDataNewShift(tempDataNewShift);
    } else {
      return;
    }
  }

  const CardDayNight = ({ title, periodTime }) => {
    return (
      <StyledCardParent variant="outlined">
        <CardContent className={`cardContent`}>
          <Typography variant="h6">{`${title}`}</Typography>
          <div className={`wrapDrag`}>
            <Typography color="text.secondary" gutterBottom>
              {periodTime}
            </Typography>
            {title === "DAY" && <img src={Day} alt={title} width={60} />}
            {title === "MID" && <img src={Mid} alt={title} width={60} />}
            {title === "NIGHT" && <img src={Night} alt={title} width={60} />}
          </div>
        </CardContent>
      </StyledCardParent>
    );
  };

  const sizeAvatar = (list) => {
    return list > 12 ? 35 : 50;
  };

  const CardParent = ({ title, list }) => {
    return (
      <StyledCardDayNight>
        <CardContent className={`cardContent`}>
          <div style={{ textAlign: "center", marginRight: 16, width: 80 }}>
            <AvatarShift
              fontSize={32}
              title={title}
              size={sizeAvatar(list.length)}
            />
          </div>

          <Droppable
            className={`Droppable`}
            droppableId={`shift${title}`}
            direction="horizontal"
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {list.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {item.title === "DAY" && (
                          <AvatarPeriod
                            title={item.title}
                            size={sizeAvatar(list.length)}
                            src={Day}
                          />
                        )}
                        {item.title === "MID" && (
                          <AvatarPeriod
                            title={item.title}
                            size={sizeAvatar(list.length)}
                            src={Mid}
                          />
                        )}
                        {item.title === "NIGHT" && (
                          <AvatarPeriod
                            title={item.title}
                            size={sizeAvatar(list.length)}
                            src={Night}
                          />
                        )}
                        {item.title === "OFF" && (
                          <div
                            style={{
                              width: 50,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <AvatarOff />
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </CardContent>
      </StyledCardDayNight>
    );
  };

  const displayTime = (value) => {
    return `${value.timeStartHours}:${
      value.timeStartMinute < 10
        ? `0` + value.timeStartMinute
        : value.timeStartMinute
    } - ${value.timeEndHours}:${
      value.timeEndMinute < 10 ? `0` + value.timeEndMinute : value.timeEndMinute
    }`;
  };

  // const onDragStart = (start) => {
  //   const id = start.draggableId;
  //   const selected = () =>
  //     this.state.selectedTaskIds.find((taskId) => taskId === id);

  //   if (!this.state.selectedTaskIds.some(selected)) {
  //     this.unselectAll();
  //   }
  //   this.setState({
  //     draggingTaskId: start.draggableId,
  //   });
  // };

  // const onDragEnd = (result) => {
  //   const destination = result.destination;
  //   const source = result.source;

  //   // nothing to do
  //   if (!destination || result.reason === "CANCEL") {
  //     setState({ ...state, draggingTaskId: null });
  //     return;
  //   }

  //   const processed = mutliDragAwareReorder({
  //     entities: state.entities,
  //     selectedTaskIds: state.selectedTaskIds,
  //     source,
  //     destination,
  //   });

  //   console.log("processed", processed);

  //   setState({
  //     ...processed,
  //     draggingTaskId: null,
  //   });
  // };

  // const onWindowKeyDown = (event) => {
  //   if (event.defaultPrevented) {
  //     return;
  //   }

  //   if (event.key === "Escape") {
  //     this.unselectAll();
  //   }
  // };

  // const onWindowClick = (event) => {
  //   if (event.defaultPrevented) {
  //     return;
  //   }
  //   this.unselectAll();
  // };

  // const onWindowTouchEnd = (event) => {
  //   if (event.defaultPrevented) {
  //     return;
  //   }
  //   this.unselectAll();
  // };

  // const toggleSelection = (taskId) => {
  //   const selectedTaskIds = this.state.selectedTaskIds;
  //   const wasSelected = selectedTaskIds.includes(taskId);
  //   console.log("wasSelected", wasSelected);

  //   const newTaskIds = (() => {
  //     // Task was not previously selected
  //     // now will be the only selected item
  //     if (!wasSelected) {
  //       return [taskId];
  //     }

  //     // Task was part of a selected group
  //     // will now become the only selected item
  //     if (selectedTaskIds.length > 1) {
  //       return [taskId];
  //     }

  //     // task was previously selected but not in a group
  //     // we will now clear the selection
  //     return [];
  //   })();

  //   this.setState({
  //     selectedTaskIds: newTaskIds,
  //   });
  // };

  // const toggleSelectionInGroup = (taskId) => {
  //   const selectedTaskIds = this.state.selectedTaskIds;
  //   const index = selectedTaskIds.indexOf(taskId);

  //   // if not selected - add it to the selected items
  //   if (index === -1) {
  //     this.setState({
  //       selectedTaskIds: [...selectedTaskIds, taskId],
  //     });
  //     return;
  //   }

  //   // it was previously selected and now needs to be removed from the group
  //   const shallow = [...selectedTaskIds];
  //   shallow.splice(index, 1);
  //   this.setState({
  //     selectedTaskIds: shallow,
  //   });
  // };

  // const multiSelectTo = (newTaskId) => {
  //   const updated = multiSelect(
  //     this.state.entities,
  //     this.state.selectedTaskIds,
  //     newTaskId
  //   );

  //   if (updated == null) {
  //     return;
  //   }

  //   this.setState({
  //     selectedTaskIds: updated,
  //   });
  // };

  // const unselectAll = () => {
  //   this.setState({
  //     selectedTaskIds: [],
  //   });
  // };

  return (
    <div>
      <StyledWrapCardDayNight>
        {dataNewShift.patternShift &&
          dataNewShift.periodTime
            .filter((value) => value.check)
            .map((value, index) => {
              return (
                <CardDayNight
                  key={value.periodTime}
                  title={value.periodTimeEN}
                  periodTime={displayTime(value)}
                />
              );
            })}
      </StyledWrapCardDayNight>
      <div>
        {dataNewShift.patternShift &&
          Object.entries(dataNewShift.patternShift).map(
            ([name, value], index) => (
              <DragDropContext onDragEnd={onDragEnd} key={name}>
                <CardParent title={name.slice(-1)} list={value} />
              </DragDropContext>
            )
          )}
      </div>
    </div>
  );
}
