import React from 'react';

import {
    Box,
    Dialog,
    DialogContent,
    Typography
} from "@mui/material";
import ButtonBlue from '../../shared/general/ButtonBlue';
import { useDispatch, useSelector } from "react-redux";
import {
    getAllProjectEmployees,
    getUsersInProject,
    getUsersInProjectShowHour,
    deleteUserWorkforce,
    deleteProjectWorkforce
} from "../../../../actions/workforceEmployees";
import { useTranslation } from 'react-i18next';

const dialogDelete = (props) => {
    const dispatch = useDispatch()
    const { open, handleCloseDialogDelete, IDWorkforceProject, IDemp } = props;
    const {t} = useTranslation();

    const handleDelete = async () => {
        // console.log(idProject)
        // console.log(idemp)

        if (!IDWorkforceProject && !IDemp) {
            handleCloseDialogDelete()
        }
        try {
            if (!IDemp) {
                const formData = {
                    idproject: IDWorkforceProject,
                }
                const result = await dispatch(deleteProjectWorkforce(formData)).then(() => {
                    dispatch(getAllProjectEmployees())
                    dispatch(getUsersInProject());
                    handleCloseDialogDelete()
                }

                )

            } else {
                const formData = {
                    idproject: IDWorkforceProject,
                    idemp: IDemp
                }
                const result = await dispatch(deleteUserWorkforce(formData));
                if (result && result.status === 200) {

                    dispatch(getUsersInProject());
                    handleCloseDialogDelete()

                }
            }



        } catch (err) {
            console.log(err)
        }




    }
    return (
        <Dialog
            open={open}
            onClose={handleCloseDialogDelete}
        >
            <DialogContent>
                <Typography variant="h4">{t("ConfirmToDelete")}</Typography>
                <Box
                    style={{
                        width: "350px",
                        height: "80px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between"
                    }}
                >
                    <Typography variant="body1">
                        {`${t("ConfirmDelete")}${IDemp ? t("Employee") : t("Project")}${t("confirmAsking")}`}
                    </Typography>
                    <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>

                        <ButtonBlue
                            variant="contained"
                            onClick={() => {
                                handleDelete();

                            }}
                        >{t("Confirm")}</ButtonBlue>
                        <ButtonBlue
                            variant="outlined"
                            style={{ marginLeft: "10px" }}
                            onClick={handleCloseDialogDelete}
                        >{t("Cancel")}</ButtonBlue>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default dialogDelete;