import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import {
    Grid,
    Button,
    Container,
    Paper,
    Typography,
    Avatar,
    IconButton,
    Box,
    Divider,
    Autocomplete,
    TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ArticleIcon from "@mui/icons-material/Article";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DescriptionIcon from "@mui/icons-material/Description";

import { getAllWork } from "../../../../actions/work";
import { getAllEmployees } from "../../../../actions/employee";
import { getUserProfile } from "../../../../actions/user";
import { getAllCareerPlan } from "../../../../actions/careerPlan";

import CardStyle from "../../shared/general/Card";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../actions/company";
import { getUserFullName, getUserPosition } from "../../../../utils/userData";

const useStyles = makeStyles(() => ({
    root: {
        marginTop: "20px",
        marginBottom: "50px",
        minHeight: "calc(100vh - 20px)",
    },
    sendIcon: {
        marginBottom: 5,
        marginRight: 5,
        color: "black",
    },

    paper: {
        margin: 20,
        padding: 20,
    },

    avatar: {
        width: 140,
        height: 140,
        borderRadius: 180,
    },
    name: {
        fontSize: 21,
        fontWeight: "bold",
        marginTop: 10,
    },
    position: {
        fontSize: 18,
        fontWeight: "bold",
    },
    Topic: {
        marginTop: 20,
        fontSize: 19,
        fontWeight: "bold",
        color: "#007afc",
    },
    viewBtn: {
        backgroundColor: "#fff",
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "3rem",
    },
    careerPlan: {
        marginTop: 32,
        marginBottom: 8,
        backgroundColor: "#f5f5f5",
        borderRadius: "10px",
        padding: 8,
    },
    suggest: {
        padding: 8,
    },
    wrapIcon: {
        display: "flex",
        alignItems: "center",
    },
}));

const StyledRoot = styled(Box)({
    backgroundColor: "#FFFFFF !important",
    paddingBottom: 50,
    "& .search-filter-container": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .filter-wrap": {},
        "& .action-container": {},
    },
});

const StyledAutoComplete = styled(Autocomplete)({
    "& .MuiFilledInput-root": {
        padding: "13.5px 14px",
        paddingRight: "32px",
        "& input": {
            padding: 0
        }
    }
});

const StyledTextField = styled(TextField)({
    width: "100%",
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "& .MuiFilledInput-input": {
            padding: "7px 4px",
        },
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            "& button": {
                color: "#919EAB",
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&:after": {
            border: "none",
        },
        "&:before": {
            border: "none",
            borderBottomStyle: "none !important",
        }
    },
});

const AdminCareer = () => {
    //use style in name of classes
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [inputValue, setInputValue] = useState(null);
    const [searchValue, setSearchValue] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [showEmployeeData, setShowEmployeeData] = useState(false);

    const { user: currentUser } = useSelector((state) => state.auth);
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: allCareerPlans } = useSelector((state) => state.careerPlan);
    const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

    let history = useHistory();

    const handleOnSelectEmp = (val) => {
        history.push({
            pathname: `/${userProfile && userProfile.roles.includes("ROLE_MANAGER") ? "manager" : "admin"}/career/viewSuggest/${val.idEmployees}`,
            careerProfile: val,
        });
    };

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    }

    useEffect(() => {
        if (currentUser) {
            dispatch(getUserProfile(currentUser.username));
        }
    }, []);

    useEffect(() => {
        dispatch(getAffiliateOrganization());
    }, []);

    useEffect(() => {
        if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
            let ownCompany = affiliateOrganizationList.find(x => x.idCompany === userProfile.idCompany);
            if (!ownCompany) {
                ownCompany = affiliateOrganizationList[0];
            }
            setSelectedCompany(ownCompany);
        }
    }, [userProfile, affiliateOrganizationList]);

    useEffect(() => {
        if (selectedCompany) {
            dispatch(getAllCareerPlan(selectedCompany.idCompany));
        }
    }, [selectedCompany]);

    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <Typography variant="h4" gutterBottom style={{ marginTop: 20 }}>
                    {`${t("CareerPath")}`}
                </Typography>

                <Grid container spacing={2} sx={{ mt: 1, mb: 2 }}>
                    <Grid item xs={12} md={6}>
                        <Typography fontSize={18} gutterBottom>
                            {`${t("Company")}`}
                        </Typography>
                        <SelectAffiliateCompany
                            options={affiliateOrganizationList}
                            value={selectedCompany}
                            onChange={(_, value) => {
                                onChangeCompany(value)
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography fontSize={18} gutterBottom>
                            {`${t("Employee")}`}
                        </Typography>
                        <StyledAutoComplete
                            options={allCareerPlans ? allCareerPlans : []}
                            getOptionLabel={(option) =>
                                getUserFullName(option)
                            }
                            isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
                            renderInput={(params) => (
                                <StyledTextField
                                    {...params}
                                    InputProps={{ ...params.InputProps, endAdornment: null }}
                                    variant="filled"
                                    placeholder={t("SearchEmp")}
                                />
                            )}
                            value={searchValue}
                            onChange={(event, newValue) => {
                                setSearchValue(newValue);
                                setSelectedEmployee(null);
                                setShowEmployeeData(false);
                            }}
                            onInputChange={(event, newInputValue) => {
                                setSelectedEmployee(null);
                                setShowEmployeeData(false);
                                setInputValue(newInputValue);
                            }}
                            noOptionsText={t("NoData")}
                            disableClearable
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    {allCareerPlans && allCareerPlans.length > 0 ? (
                        allCareerPlans
                            .filter(item => inputValue ?
                                ((item.firstname_TH + " " + item.lastname_TH).toLowerCase().includes(inputValue.toLowerCase()) ||
                                    (item.firstname_EN + " " + item.lastname_EN).toLowerCase().includes(inputValue.toLowerCase())) : true
                            ).map((val, index) => (
                                <Grid key={index} item xl={3} lg={4} md={6} sm={6} xs={12}>
                                    <CardStyle style={{ height: "100%", cursor: "pointer" }}>
                                        <div
                                            style={{ padding: "24px 16px 16px 16px" }}
                                            onClick={() => handleOnSelectEmp(val)}
                                        >
                                            <Grid container>
                                                <Grid item xl={12} md={12} xs={12}>
                                                    <center>
                                                        <Avatar
                                                            alt={`${getUserFullName(val)}`}
                                                            src={val.imageProfile}
                                                            variant={"square"}
                                                            className={classes.avatar}
                                                        />
                                                        <Typography className={classes.name}>
                                                            {getUserFullName(val)}
                                                        </Typography>
                                                        <Typography variant="body2" sx={{ color: "#999999" }}>
                                                            {getUserPosition(val)}
                                                        </Typography>
                                                    </center>
                                                </Grid>
                                            </Grid>
                                            <Box className={classes.careerPlan}>
                                                <Typography
                                                    variant="body1"
                                                    sx={{ color: "#9e9e9e" }}
                                                    className={classes.wrapIcon}
                                                >
                                                    <ArticleIcon sx={{ marginRight: 1 }} />
                                                    {`${t("CareerPath")}`}
                                                </Typography>
                                            </Box>
                                            <Box className={classes.suggest}>
                                                <Grid container justifyContent="space-between">
                                                    <Grid item>
                                                        <Typography variant="body1">{`${t(
                                                            "InterestedCareerPath"
                                                        )}`}</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        {val.empIntStatus ? (
                                                            <Typography variant="body1">
                                                                <CheckCircleIcon sx={{ color: "#66bb6a" }} />
                                                            </Typography>
                                                        ) : (
                                                            <Typography variant="body1">
                                                                <CancelIcon sx={{ color: "#ef5350" }} />
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Divider />
                                            <Box className={classes.suggest} sx={{ marginBottom: 3 }}>
                                                <Grid container justifyContent="space-between">
                                                    <Grid item>
                                                        <Typography variant="body1">{`${t(
                                                            "HeadRecommends"
                                                        )}`}</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        {val.managerSugStatus ? (
                                                            <Typography variant="body1">
                                                                <CheckCircleIcon sx={{ color: "#66bb6a" }} />
                                                            </Typography>
                                                        ) : (
                                                            <Typography variant="body1">
                                                                <CancelIcon sx={{ color: "#ef5350" }} />
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            {val.updateSuggestJob != "" && (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        padding: "8px",
                                                    }}
                                                >
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: "#bdbdbd", fontSize: "0.875rem" }}
                                                    >
                                                        Last update :{" "}
                                                        {val.dateUpdate
                                                            ? dayjs(val.dateUpdate).format("DD/MM/YYYY")
                                                            : "-"}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </div>
                                    </CardStyle>
                                </Grid>
                            ))
                    ) : (
                        <Box
                            sx={{
                                marginTop: 4,
                                width: "100%",
                            }}
                        >
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        sx={{ display: "flex", alignItems: "center" }}
                                    >
                                        <DescriptionIcon sx={{ marginRight: 1 }} />
                                        {`${t("NoData")}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </Grid>
            </Container>
        </StyledRoot >
    );
};

export default AdminCareer;
