import DoneAll from "@mui/icons-material/DoneAll";
import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, Box, IconButton, styled, Typography, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getLeaveRequest } from "../../../../actions/employee";
import {
  getAllLeaveWithDrawBy,
  updateApproveLeaveEmployeeBy,
} from "../../../../actions/leave";
import fileService from "../../../../services/file.service";
import {
  getAllProjectEmployees,
  getUsersInProject,
  getUsersInProjectShowHour,
  getAllEmployeesInWorkforce,
  getEmployeesWeek,
  getUsersTimeforApprove,
  updateManagerWorkforceApproveTime
} from "../../../../actions/workforceEmployees";

import ChipWithDrawCustom from "../../shared/chipWithDrawCustom";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TableCustom from "../../shared/tableCustom";
import DialogConfirmReject from "./DialogConfirmReject";
import CardRequest from "./CardRequest";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName, getUserPosition } from "../../../../utils/userData";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const StyledButtonCancel = styled(ButtonBlue)({
  backgroundColor: "#E793B8",
  borderRadius: "8px",
  color: "#FFFFFF",
  marginRight: 8,
  width: 130,
  "&:hover": {
    backgroundColor: "#DC6384",
  },
});




const AlertDialog =({open,handleClose,detail}) =>{

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="600px"
        minWidth="300px"
      >
        <DialogTitle id="alert-dialog-title">
          {"หมายเหตุ"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {detail}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}


const RequestInputHourUserWorkforce = (props) => {
  const [open, setOpen] = React.useState(false);
  const [detail,setDeatil] = useState('')
  const handleClickOpen = (detail) => {
    setDeatil(detail)
    setOpen(true);
  };

  const handleClose = () => {
    setDeatil('')
    setOpen(false);
  };
  const { searchDate, setSnackBarConfig, setNumberOfList } = props;

  const { t, i18n } = useTranslation();
  const mobileResponsive = useMediaQuery("(max-width:600px)");
  const { result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { result: leaveEmployeeWithdrawsList } = useSelector(
    (state) => state.leaveEmployeesWithDraw
  );

  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [pendingRows, setPendingRows] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);

  const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);

  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState(projectGetUserTimeforApprove);
  

  const { result: projectGetUserTimeforApprove } = useSelector(
    (state) => state.projectGetUserTimeforApprove
  );
  
  useEffect(() => {
    if(projectGetUserTimeforApprove){
      setFilteredData(projectGetUserTimeforApprove)
      setNumberOfList(projectGetUserTimeforApprove.filter((data)=>data.isApprove===0||!data.isApprove).length)
      const test = projectGetUserTimeforApprove.filter((data)=>data.isApprove===0||!data.isApprove).length
      setNumberOfList(test)
      setSnackBarConfig(test)
    }
    
  }, []);
  
  useEffect(() => {
    if (searchDate.start || searchDate.end) {
  
      let tempPending = projectGetUserTimeforApprove.filter((item) =>
        dayjs(item.workDate).isBetween(
          searchDate.start || dayjs(),
          searchDate.end || dayjs(),
          null,
          '[]' 
        )
      );
  
      const pendingList = tempPending.filter((data) => data.isApprove === 0 || !data.isApprove);
  
      setNumberOfList(pendingList.length);
      setSnackBarConfig(pendingList.length);
      setFilteredData(tempPending);
    } else {
      const pendingList = projectGetUserTimeforApprove.filter((data) => data.isApprove === 0 || !data.isApprove);
  
      setNumberOfList(pendingList.length);
      setSnackBarConfig(pendingList.length);
    }
  }, [searchDate, projectGetUserTimeforApprove]);

  const thaiMonths = [
    t("January"), t("February"), t("March"), t("April"), t("May"), t("June"),
    t("July"), t("August"), t("September"), t("October"), t("November"), t("December")
  ];


  const columns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageProfile}
          />
          <Box flexGrow={1}>
            <Typography>
              {getUserFullName(row)}
            </Typography>
            <Typography color="text.third" fontSize="14px">
              {getUserPosition(row)}
            </Typography>
          </Box>
        </Box>
      ),
    },

    {
      name: `${t("ProjectName")}`,
      headerTextAlign: "center",
      minWidth: "130px",
      width: "130px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {row.projectName}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("RequestDate")}`,
      headerTextAlign: "center",
      minWidth: "130px",
      width: "130px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.workDate, "YYYY/MM/DD").format(
              i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
            )}

          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("week")}/${t("Month")}/${t("Year")}`,
      headerTextAlign: "center",
      minWidth: "130px",
      width: "130px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            <div>{t("week")} {row.inWeek}</div>
            <div>{thaiMonths[row.monthWork-1]} {row.inYear}</div>
          </Typography>
        </Box>
      ),
    },

    {
      name: `${t("Unit.Hours")}`,
      cellRender: (row) => (
        <Box>
          <Typography color="text.third" fontSize="14px">
            {row.totalWorkingHours}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("Note")}`,
      minWidth: "150px",
      // width: "150px",
      cellRender: (row) => (
        <Fragment >
          {row.detail.length >=15?(
            <Typography fontSize={14}
            >
              {row.detail.slice(0,11)}...
              <Button onClick={()=>handleClickOpen(row.detail)}><i class="fa-solid fa-eye"></i></Button>
            </Typography>
          ):(
            <Typography fontSize={14}
          // color="text.secondary"
          >
          {row.detail}
          </Typography>)}
          
        </Fragment>
      ),
    },
  ];

  const handleApprovalRequest = async (approve, comment) => {

    const testid = selectedRows.map(id => id.idWorkforceEmployees);
    var formData =
    {
      idWorkforceEmployees: testid,
      isApprove: approve,
      approveDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm"),
      commentManager: comment ? comment : null,
    }

    const result = await dispatch(updateManagerWorkforceApproveTime(formData));
    if (result) {
      setSelectedRows([]);
      if (result.status === 200) {
        dispatch(getUsersTimeforApprove())
        setSnackBarConfig({
          open: true,
          message: `${t("Successfully")}`,
          type: "success",
        });
      } else {
        setSnackBarConfig({
          open: true,
          message: `${t("Unsuccessful")}`,
          type: "error",
        });
      }
    } else {
      setSnackBarConfig({
        open: true,
        message: `${t("Unsuccessful")}`,
        type: "error",
      });
    }
  };

  const handleConfirmReject = (comment) => {
    handleApprovalRequest(0, comment);
  };

  const handleClickAll = () => {
    setSelectedRows(pendingRows);
  };

  const handleCancel = () => {
    setSelectedRows([]);
  };


  return (
    <Box>
      {mobileResponsive && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          {(selectedRows ? selectedRows.length : 0) > 0 && (
            <StyledButtonCancel
              startIcon={<DeleteIcon />}
              onClick={handleCancel}
            >
              {`${t("Cancel")}`}
            </StyledButtonCancel>
          )}
          {!(
            (selectedRows ? selectedRows.length : 0) === pendingRows.length
          ) && (
              <ButtonBlue
                startIcon={<DoneAll />}
                variant="contained"
                onClick={handleClickAll}
              >
                {`${t("SelectAll")}`}
              </ButtonBlue>
            )}
        </Box>
      )}
      {selectedRows.length > 0 && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          <ButtonBlue
            style={{ marginRight: 8 }}
            startIcon={<DoneAll />}
            variant="contained"
            onClick={() => handleApprovalRequest(1)}
          >
            {`${t("Approved")} ${selectedRows.length > 0
                ? `${selectedRows.length} ${t("List")}`
                : ""
              }`}
          </ButtonBlue>
          <ButtonBlue
            startIcon={<DoneAll />}
            variant="outlined"
            onClick={() => setIsOpenRejectDialog(true)}
          >
            {`${t("NotApproved")} ${selectedRows.length > 0
                ? `${selectedRows.length} ${t("List")}`
                : ""
              }`}
          </ButtonBlue>
        </Box>
      )}

      {!mobileResponsive ? (
        <TableCustom
          columns={columns}
          rows={
            filteredData ? filteredData.filter((data)=>data.isApprove===0||!data.isApprove) : []
          }
          canSelect
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      ) : (
        <div>
          {filteredData ? (
            filteredData.filter((data)=>data.isApprove===0||!data.isApprove).map((row,index) => (
              <CardRequest
                key={index}
                row={row}
                mode="workforce"
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />
            ))
          ) : (
            <div>
              <Typography align="center">{`${t("NoData")}`}</Typography>
            </div>
          )}
        </div>
      )}

      <DialogConfirmReject
        rejectCount={selectedRows.length}
        open={isOpenRejectDialog}
        onClose={() => {
          setIsOpenRejectDialog(false);
        }}
        handleSubmit={(comment) => {
          handleConfirmReject(comment);
        }}
      />
      {open&&(
         <AlertDialog
         open={open}
         handleClose={handleClose}
         detail={detail}
       />
      )}
     
    </Box>
  );
};

export default RequestInputHourUserWorkforce;
