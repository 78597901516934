import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import {
  Box,
  Checkbox,
  Drawer,
  FilledInput,
  FormControl,
  InputAdornment,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";

import { getAllEmployees, getAllEmployeesByIdCompany } from "../../../../actions/employee";

import utils from "../../../../utils";
import { useTranslation } from "react-i18next";

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledPopper = styled(Popper)({
  width: "330px !important",

  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const filterOptions = createFilterOptions({
  stringify: (option) => (option.firstname_TH + option.lastname_TH + option.positionName),
});

const filterOptionsName = createFilterOptions({
  stringify: (option) => option.firstname_TH + option.lastname_TH,
});

const SelectEmployeeByIdCompany = (props) => {
  let { handleChange, position,id } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: employees } = useSelector((state) => state.employees);
  
  useEffect(() => {
    dispatch(getAllEmployeesByIdCompany(id));
  }, [id]);


  return (
    <StyledBoxSearch>
      <StyledAutocomplete
        options={
          employees
            ? employees
            .filter(item => !item.isTerminate)
            .sort((a, b) =>
                a.positionName.localeCompare(b.positionName)
              )
            : []
        }
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        filterOptions={position ? filterOptions : filterOptionsName}
        groupBy={position ? (option) => option.positionName : null}
        closeIcon={null}
        popupIcon={<i class="fa-light fa-chevron-down"></i>}
        getOptionLabel={(option) =>
          `${option.firstname_TH} ${option.lastname_TH}`
        }
        renderOption={(props, option) => (
          <li {...props} key={option.idEmployees}>
            {`${option.firstname_TH} ${option.lastname_TH}`}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            placeholder={t("SelectEmp")}
            InputProps={{ ...params.InputProps, endAdornment: null }}
          />
        )}
        PopperComponent={StyledPopper}
        noOptionsText={t("NoData")}
      />
    </StyledBoxSearch>
  );
};

export default SelectEmployeeByIdCompany;
