import React, { useEffect, useState } from "react";
import { Grid, Button, Container, Paper, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import { useSelector, useDispatch } from "react-redux";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";

import { Breadcrumb } from "../../shared/breadcrums";
import DataViewSuggest from "../../shared/pages/viewSuggestion";

import { getUserProfile } from "../../../../actions/user";
import { getAllEmployees } from "../../../../actions/employee";
import Notification from "../../shared/notification";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getEmployeeCareerPlanByID } from "../../../../actions/careerPlan";

const useStyles = makeStyles(() => ({
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  // nameCareer:{
  //   fontSize: 24,
  //   fontWeight: 'bold',
  //   color: '#007afc',
  // },
  nameYear: {
    fontSize: 28,
    fontWeight: "bold",
    marginBottom: 10,
  },
  background: {
    backgroundColor: "#007afc",
  },
  nameSelected: {
    fontSize: 20,
    fontWeight: "bold",
    color: "white",
  },
  topic: {
    fontSize: 30,
    fontWeight: "bold",
    textAlign: "center",
  },
  career: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#007afc",
  },
  position: {
    fontSize: 22,
    fontWeight: "bold",
  },
  jobFam: {
    fontSize: 18,
    fontWeight: "bold",
  },
  paper: {
    margin: 20,
  },
  link: {
    textDecoration: "none",
  },
}));

const CareerPart = () => {
  //use style in name of classes
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: empStore } = useSelector((state) => state.employees);
  const [empProfile, setEmpProfile] = useState({});

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    const fetchedData = async () => {
      try {
        const response = await getEmployeeCareerPlanByID();
        if (response && response.data) {
          setEmpProfile(response.data[0]);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchedData();
  }, []);

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserProfile("CHANOKRI"));
      dispatch(getAllEmployees());

      if (location.notify) {
        setNotify({
          isOpen: location.notify.isOpen,
          message: location.notify.message,
          type: location.notify.type,
        });
      }
    }
  }, []);

  return (
    <>
      <DataViewSuggest
        empProfile={empProfile ? empProfile : null}
        name={`${t("MyCareerPath")}`}
        suggest={false}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default CareerPart;
