import React, { useEffect } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { useDispatch, useSelector } from "react-redux";
import {
  addAssetManagement,
  getAssetManagement,
  updateAssetManagement,
} from "../../../../actions/assetManagement";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../shared/date/datePicker";
import { getUserFirstName,getUserFullName, getUserLastName } from "../../../../utils/userData";
import { openNotificationAlert } from "../../../../actions/notificationAlert";

const DialogCreate = ({ open, handleClose, data, idCompany }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: employees } = useSelector((state) => state.employees);

  const defaultData = {
    name: "",
    idAsset: "",
    employee: null,
    receivedDate: null,
    returnDate: dayjs(),
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().max(180).required(t("nameRequired")),
    idAsset: Yup.string().max(30, "idAssetMaxLength"),
    employee: Yup.object().required(t("employeeNameIsRequired")).nullable(),
    receivedDate: Yup.date().nullable(),
    returnDate: Yup.date().required(t("Required")),
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: defaultData,
    resolver: yupResolver(validationSchema),
  });

  const handleAddWarningLetter = async (values) => {
    const formData = {
      ...values,
      idEmployees: values.employee.idEmployees,
    };
    delete formData.employee;

    let res = null;
    if (data) {
      res = await dispatch(updateAssetManagement(formData));
    } else {
      res = await dispatch(addAssetManagement(formData));
    }

    if (res && res.status === 201) {
      dispatch(
        openNotificationAlert({ type: "success", message: t("Success") })
      );
    } else {
      dispatch(
        openNotificationAlert({ type: "error", message: t("AnErrorOccurred") })
      );
    }

    reset();
    handleClose();
    dispatch(getAssetManagement(idCompany));
  };

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        employee: {
          idEmployees: data.idEmployees,
          firstname_TH: getUserFirstName(data),
          lastname_TH: getUserLastName(data),
        },
      });
    }
  }, []);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth={"sm"}>
      <DialogTitle>
        {data
          ? `${t("EditAssetManagementList")}`
          : `${t("CreateAssetManagementList")}`}
      </DialogTitle>
      <form onSubmit={handleSubmit(handleAddWarningLetter)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={`${t("AssetName")}`}
                    error={Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="idAsset"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={`${t("AssetID")}`}
                    error={Boolean(errors.idAsset)}
                    helperText={errors.idAsset && errors.idAsset.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="employee"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={employees}
                    isOptionEqualToValue={(option, value) =>
                      option.idEmployees === value.idEmployees
                    }
                    getOptionLabel={(option) =>
                      `${option.firstname_TH} ${option.lastname_TH}`
                    }
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.idEmployees}>
                          {`${getUserFullName(option)}`}
                        </li>
                      );
                    }}
                    onChange={(e, value) => field.onChange(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${t("EmployeeList")}`}
                        error={Boolean(errors.employee)}
                        helperText={errors.employee && errors.employee.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="receivedDate"
                control={control}
                render={({ field }) => (
                  <DatePickerCustom
                    {...field}
                    label={`${t("ReceivedDate")}`}
                    views={["year", "month", "day"]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={errors.receivedDate}
                        helperText={
                          errors.receivedDate && errors.receivedDate.message
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="returnDate"
                control={control}
                render={({ field }) => (
                  <DatePickerCustom
                    {...field}
                    label={`${t("ReturnDueDate")}`}
                    views={["year", "month", "day"]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={errors.returnDate}
                        helperText={
                          errors.returnDate && errors.returnDate.message
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="error" type="button" onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <ButtonBlue variant="contained" type="submit">
            {t("Confirm")}
          </ButtonBlue>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogCreate;
