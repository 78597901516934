import {
  JOB_RECRUIT_FORM_FETCHING,
  JOB_RECRUIT_FORM_FAILED,
  JOB_RECRUIT_FORM_SUCCESS,
  JOB_RECRUIT_GROUP_FETCHING,
  JOB_RECRUIT_GROUP_FAILED,
  JOB_RECRUIT_GROUP_SUCCESS,
  JOB_RECRUIT_DETAIL_FETCHING,
  JOB_RECRUIT_DETAIL_FAILED,
  JOB_RECRUIT_DETAIL_SUCCESS,
  JOBS_RECRUIT_FETCHING,
  JOBS_RECRUIT_FAILED,
  JOBS_RECRUIT_SUCCESS,
  JOB_RECRUIT_EDIT_FETCHING,
  JOB_RECRUIT_EDIT_FAILED,
  JOB_RECRUIT_EDIT_SUCCESS,
  JOBS_MANAGER_FETCHING,
  JOBS_MANAGER_FAILED,
  JOBS_MANAGER_SUCCESS,
  JOBGROUP_INTERVIEWER_FETCHING,
  JOBGROUP_INTERVIEWER_FAILED,
  JOBGROUP_INTERVIEWER_SUCCESS,
  INTERVIEW_PANEL_FETCHING,
  INTERVIEW_PANEL_FAILED,
  INTERVIEW_PANEL_SUCCESS,
} from "./types";
import jobRecruitService from "../services/jobRecruit.service";

export const getJobRequestByIdJobRequest = (idJobRequest) => async (dispatch) => {
  try {
    dispatch({
      type: JOB_RECRUIT_DETAIL_FETCHING,
    });
    const res = await jobRecruitService.getJobRequestByIdJobRequest(
      idJobRequest
    );
    if (res) {
      dispatch({
        type: JOB_RECRUIT_DETAIL_SUCCESS,
        payload: res.data,
      });
      return res.data;
    }
  } catch (error) {
    dispatch({
      type: JOB_RECRUIT_DETAIL_FAILED,
    });
    return null;
  }
};

export const getJobForm = () => async (dispatch) => {
  try {
    dispatch({
      type: JOB_RECRUIT_FORM_FETCHING,
    });
    const res = await jobRecruitService.getJobForm();
    if (res) {
      dispatch({
        type: JOB_RECRUIT_FORM_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: JOB_RECRUIT_FORM_FAILED,
    });
  }
};

export const closeJob = (idJob) => async () => {
  try {
    const res = await jobRecruitService.closeJob(idJob);
    if (res) {
      return res.data;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const addJob = (formData) => async () => {
  try {
    const res = await jobRecruitService.addJob(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const getJobGroup = () => async (dispatch) => {
  try {
    dispatch({
      type: JOB_RECRUIT_GROUP_FETCHING,
    });
    const res = await jobRecruitService.getJobGroup();
    if (res) {
      dispatch({
        type: JOB_RECRUIT_GROUP_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: JOB_RECRUIT_GROUP_FAILED,
    });
  }
};

export const getCompanyMyJob = () => async (dispatch) => {
  try {
    dispatch({
      type: JOBS_RECRUIT_FETCHING,
    });
    const res = await jobRecruitService.getCompanyMyJob();
    if (res) {
      dispatch({
        type: JOBS_RECRUIT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: JOBS_RECRUIT_FAILED,
    });
  }
};

export const getJobEdit = (idJob) => async (dispatch) => {
  try {
    dispatch({
      type: JOB_RECRUIT_EDIT_FETCHING,
    });
    const res = await jobRecruitService.getJobEdit(idJob);
    if (res) {
      dispatch({
        type: JOB_RECRUIT_EDIT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: JOB_RECRUIT_EDIT_FAILED,
    });
  }
};

export const updateJob = (idJob, formData) => async () => {
  try {
    const res = await jobRecruitService.updateJob(idJob, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const getJobsManager = () => async (dispatch) => {
  try {
    dispatch({
      type: JOBS_MANAGER_FETCHING,
    });
    const res = await jobRecruitService.getJobsManager();
    if (res) {
      dispatch({
        type: JOBS_MANAGER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: JOBS_MANAGER_FAILED,
    });
  }
};

export const getJobsAdmin = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: JOBS_MANAGER_FETCHING,
    });
    const res = await jobRecruitService.getJobsAdmin(idCompany);
    if (res) {
      dispatch({
        type: JOBS_MANAGER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: JOBS_MANAGER_FAILED,
    });
  }
};

export const approveJobs = (data) => async () => {
  try {
    const res = await jobRecruitService.approveJobs(data);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getJobGroupInterviewer = () => async (dispatch) => {
  try {
    dispatch({
      type: JOBGROUP_INTERVIEWER_FETCHING,
    });
    const res = await jobRecruitService.getJobGroupInterviewer();
    if (res) {
      dispatch({
        type: JOBGROUP_INTERVIEWER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: JOBGROUP_INTERVIEWER_FAILED,
    });
  }
};

export const addJobGroupInterviewer = (data) => async () => {
  try {
    const res = await jobRecruitService.addJobGroupInterviewer(data);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const deleteJobGroupInterviewer = (idJobGroup, idEmployees) => async () => {
  try {
    const res = await jobRecruitService.deleteJobGroupInterviewer(idJobGroup, idEmployees);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getJobRecruitInterviewPanel = (idJobRecruit) => async (dispatch) => {
  try {
    dispatch({
      type: INTERVIEW_PANEL_FETCHING,
    });
    const res = await jobRecruitService.getJobRecruitInterviewPanel(idJobRecruit);
    if (res) {
      dispatch({
        type: INTERVIEW_PANEL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: INTERVIEW_PANEL_FAILED,
    });
  }
};

export const updateJobRecruitInterviewPanel = (idJobRecruit, formData) => async () => {
  try {
    const res = await jobRecruitService.updateJobRecruitInterviewPanel(idJobRecruit, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};