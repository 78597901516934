import React, { useEffect, useState } from "react";
import { Badge, Container, Grid, MenuItem, Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import PropTypes from "prop-types";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import SnackBarCustom from "../../../shared/snackbarCustom";
import RequestEstimate from "./RequestEstimate";

import { useDispatch, useSelector } from "react-redux";
import {
  getExitInterview,
  getExitInterviewAndAnswer,
  getReasonExitInterview,
} from "../../../../../actions/exitInterview";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledCard = styled(Card)({
  padding: 16,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .MuiCardContent-root": {
    padding: 24,
  },
});

const StyledDivTab = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: 24,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-end",
    "& .numberOfList": {
      marginTop: 16,
    },
  },
}));

const StyledRoot = styled("div")(({ theme }) => ({
  background: "#FFFFFF !important",
  "& .displayFlexEnd": {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "8px 0",
    },
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -24,
    top: 8,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#e46a76",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Resignlist() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [search, setSearch] = React.useState({
    // start: dayjs(today).set("date", 1),
    // end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
    start: null,
    end: null,
  });
  const [approveTabsValue, setApproveTabsValue] = React.useState(0);
  const [numberOfList, setNumberOfList] = useState(0);

  const { result: userProfile } = useSelector((state) => state.userProfile);

  const mobile = useMediaQuery("(max-width:600px)");

  const [snackBarConfig, setSnackBarConfig] = useState({
    open: false,
    message: "",
    type: "success",
  });

  useEffect(() => {
    dispatch(getExitInterview());
    dispatch(getReasonExitInterview());
    dispatch(getExitInterviewAndAnswer());
  }, []);

  const handleApproveTabsChange = (_, newValue) => {
    setApproveTabsValue(newValue);
  };

  const handleApproveTabsChangeSelect = (event) => {
    setApproveTabsValue(event.target.value);
  };

  return (
    <StyledRoot className="page">
      <SnackBarCustom config={snackBarConfig} setConfig={setSnackBarConfig} />
      <Container maxWidth="lg" style={{ paddingBottom: 40 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4" gutterBottom style={{ paddingTop: 8 }}>
              {`${t("EmployeeOut")}`}
            </Typography>
          </Grid>
        </Grid>
        <StyledCard>
          <StyledDivTab>
            {mobile ? (
              <TextFieldTheme
                value={approveTabsValue}
                onChange={handleApproveTabsChangeSelect}
                select
              >
                <MenuItem value={0}>
                  <StyledBadge color="secondary" max={999}>
                    {`${t("ResignationRequest")}`}
                  </StyledBadge>
                </MenuItem>
              </TextFieldTheme>
            ) : (
              <Tabs
                value={approveTabsValue}
                onChange={handleApproveTabsChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
              >
                <Tab
                  style={{ minWidth: 220 }}
                  label={
                    <>
                      <StyledBadge color="secondary" max={999}>
                        {`${t("ResignationRequest")}`}
                      </StyledBadge>
                    </>
                  }
                  {...a11yProps(0)}
                />
              </Tabs>
            )}
          </StyledDivTab>

          {userProfile && (
            <Box style={{ width: "100%" }}>
              <TabPanel value={approveTabsValue} index={0}>
                <RequestEstimate
                  searchDate={search}
                  setSnackBarConfig={setSnackBarConfig}
                  setNumberOfList={setNumberOfList}
                />
              </TabPanel>
            </Box>
          )}
        </StyledCard>
      </Container>
    </StyledRoot>
  );
}

export default Resignlist;
