import React, { Fragment, useState } from "react";
import { Box, Typography, styled, Menu, MenuItem } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useTranslation } from "react-i18next";
import AS2 from "./components/AS2";
import AS2New from "./components/additionalSpeceial2/AS2";
import AS100 from "./components/AS100";
import AdditionPersonal from "./components/additionPersonal";
import AdditionOvertime from "./components/additionOvertime";

import AdditionSpecialDiligenceAllowance from "./components/additionSpecialDiligentAllowance";

const StyledRoot = styled(Box)({
  marginTop: "30px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .MuiTypography-h6": {
    fontSize: "1.5rem",
  },
})



const AdditionSpecial = ({ selectedCompany, isReadOnly }) => {
  const { t, i18n } = useTranslation();
const menus = () => [
  {
    value: "diligence-allowance",
    lable: t("DiligenceAllowance"),
  },
  {
    value: "AS2",
    lable: "AS2",
  },
  {
    value: "AS100",
    lable: "AS100",
  },
  {
    value: "Personal",
    lable: "Personal",
  },
  {
    value: "Overtime",
    lable: "Overtime",
  },
]
  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
  })

  const [selectForm, setSelectForm] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectForm = (idForm) => {
    setSelectForm(idForm);
    handleClose();
  };

  return (
    <StyledRoot>
      {!drawerConfig.isOpen && <Fragment>
        <Box marginBottom="16px" display="flex" justifyContent="space-between" flexWrap="wrap">
          <Typography fontSize="24px" fontWeight="500">{t("AdditionSpecial")}</Typography>
          <ButtonBlue
            variant={"contained"}
            startIcon={<MenuIcon />}
            onClick={handleClick}
            disabled={isReadOnly}
          >
            {t("SelectAdditionSpecial")}
          </ButtonBlue>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {menus().map((item, index) => (
              <MenuItem key={index} onClick={() => handleSelectForm(item.value)}>
                {item.lable}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Fragment>}

      {selectForm === null && <Typography>{t("PleaseSelectData")}</Typography>}
      {selectForm === "diligence-allowance" && <AdditionSpecialDiligenceAllowance selectedCompany={selectedCompany}/>}
      {/* {selectForm === "AS2" && <AS2 selectedCompany={selectedCompany} />} */}
      {selectForm === "AS2" && <AS2New selectedCompany={selectedCompany} />}
      {selectForm === "AS100" && <AS100 selectedCompany={selectedCompany} />}
      {selectForm === "Personal" && <AdditionPersonal selectedCompany={selectedCompany} />}
      {selectForm === "Overtime" && <AdditionOvertime selectedCompany={selectedCompany} />}
    </StyledRoot>
  )
}

export default AdditionSpecial;