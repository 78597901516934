import { Chip } from "@mui/material";
import React from "react";

const ChipWarningLevel = ({ id, label }) => {
  const colorSet = ["#ff1744", "#651fff", "#ef6c00", "#009688", "#78909c"];
  return (
    <Chip
      label={label}
      style={{
        backgroundColor: colorSet[id % colorSet.length],
        color: "#ffffff",
      }}
    />
  );
};

export default ChipWarningLevel;
