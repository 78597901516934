export const ChartData = [
  {
    orgChartId: 4,
    id: "OrgNode_1",
    parentId: "0",
    name: "Preeda Vatchratiensakul",
    title: null,
    position: "Manufacturing Director-Polyolefins",
    department: "",
    img: "23.jpg",
    isDepartment: false,
    layer: "CEO",
    orgCode: null,
    personInCharge: null,
    costCenter: null,
    costElement: null,
    jobGroup: null,
    subJobGroup: null,
    updateBy: "Admin",
    role: 1,
    section: "TPE",
    dateUpdate: "2021-12-15T10:23:44.035",
  },
  {
    orgChartId: 5,
    id: "OrgNode_34",
    parentId: "OrgNode_1",
    name: "Kraisid Wongnark",
    title: null,
    position: "PE Production Division Manager",
    department: "PE Production Division",
    img: "14.jpg",
    isDepartment: false,
    layer: "Division",
    orgCode: "Test Org Code 2",
    personInCharge: "Ervin Howell",
    costCenter: "Test Cost Center",
    costElement: "Test Cost Element",
    jobGroup: "Test Job Group",
    subJobGroup: "Test Sub Job Group",
    updateBy: "Admin",
    role: 0,
    section: "TPE",
    dateUpdate: "2021-12-16T06:00:02.708",
  },
  {
    orgChartId: 9,
    id: "OrgNode_35",
    parentId: "OrgNode_34",
    name: "Sittharpat Leuangaramsri",
    title: null,
    position: "HDPE 1 Production Manager",
    department: "HDPE 1 Production Department",
    img: "11.jpg",
    isDepartment: false,
    layer: "Department",
    orgCode: null,
    personInCharge: "Leanne Graham",
    costCenter: null,
    costElement: null,
    jobGroup: null,
    subJobGroup: null,
    updateBy: "Admin",
    role: 0,
    section: "TPE",
    dateUpdate: "2021-12-16T06:00:02.708",
  },
  {
    orgChartId: 10,
    id: "OrgNode_36",
    parentId: "OrgNode_1",
    name: "Nipat Lumlertluksanachai",
    title: null,
    position: "PP Production Division Manager",
    department: "PP Production Division",
    img: "7.jpg",
    isDepartment: false,
    layer: "Division",
    orgCode: null,
    personInCharge: null,
    costCenter: null,
    costElement: null,
    jobGroup: null,
    subJobGroup: null,
    updateBy: "Admin",
    role: 0,
    section: null,
    dateUpdate: "2021-12-16T06:00:02.708",
  },
  {
    orgChartId: 11,
    id: "OrgNode_37",
    parentId: "OrgNode_34",
    name: "Rerk Ganjanopatump",
    title: null,
    position: "HDPE 2,3 Production Manager",
    department: "HDPE 2,3 Production Department",
    img: "16.jpg",
    isDepartment: false,
    layer: "Department",
    orgCode: "Test Code ",
    personInCharge: "Ervin Howell",
    costCenter: "Test Cost Center",
    costElement: "Test Cost Element",
    jobGroup: "Test Job Group",
    subJobGroup: "Test Sub Job Group",
    updateBy: "Admin",
    role: 0,
    section: "TPE",
    dateUpdate: "2021-12-16T06:00:02.708",
  },
  {
    orgChartId: 12,
    id: "OrgNode_38",
    parentId: "OrgNode_34",
    name: "Supasak Kaewwichit",
    title: null,
    position: "HDPE 4 Production Department Manager",
    department: "HDPE 4 Production Department",
    img: "13.jpg",
    isDepartment: false,
    layer: "Department",
    orgCode: null,
    personInCharge: null,
    costCenter: null,
    costElement: null,
    jobGroup: null,
    subJobGroup: null,
    updateBy: null,
    role: null,
    section: null,
    dateUpdate: "2021-12-16T06:00:02.708",
  },
  {
    orgChartId: 13,
    id: "OrgNode_39",
    parentId: "OrgNode_34",
    name: "Sophon Kiatpradab",
    title: null,
    position: "LLDPE Production Department Manager",
    department: "LLDPE Production Department",
    img: "15.jpg",
    isDepartment: false,
    layer: "Department",
    orgCode: null,
    personInCharge: null,
    costCenter: null,
    costElement: null,
    jobGroup: null,
    subJobGroup: null,
    updateBy: null,
    role: null,
    section: null,
    dateUpdate: null,
  },
  {
    orgChartId: 14,
    id: "OrgNode_40",
    parentId: "OrgNode_34",
    name: "Pat Nartpochananon",
    title: null,
    position: "LDPE Production Department Manager",
    department: "LDPE Production Department",
    img: "18.jpg",
    isDepartment: false,
    layer: "Department",
    orgCode: null,
    personInCharge: null,
    costCenter: null,
    costElement: null,
    jobGroup: null,
    subJobGroup: null,
    updateBy: null,
    role: null,
    section: null,
    dateUpdate: null,
  },
  {
    orgChartId: 15,
    id: "OrgNode_41",
    parentId: "OrgNode_34",
    name: "Pakorn Aumyoo",
    title: null,
    position: "Catalyst & Pilot Plant Production Department Manager",
    department: "Catalyst & Pilot Plant Production Department",
    img: "12.jpg",
    isDepartment: false,
    layer: "Department",
    orgCode: null,
    personInCharge: null,
    costCenter: null,
    costElement: null,
    jobGroup: null,
    subJobGroup: null,
    updateBy: null,
    role: null,
    section: null,
    dateUpdate: null,
  },
  {
    orgChartId: 16,
    id: "OrgNode_42",
    parentId: "OrgNode_36",
    name: "Noppon Monggranonchai",
    title: null,
    position: "PP1,2 Production Manager",
    department: "PP 1,2 Production Department",
    img: "17.jpg",
    isDepartment: false,
    layer: "Department",
    orgCode: null,
    personInCharge: null,
    costCenter: null,
    costElement: null,
    jobGroup: null,
    subJobGroup: null,
    updateBy: null,
    role: null,
    section: null,
    dateUpdate: null,
  },
  {
    orgChartId: 17,
    id: "OrgNode_43",
    parentId: "OrgNode_36",
    name: "Patinya Pipatpratanporn",
    title: null,
    position: "PP 3 Production Department Manager",
    department: "PP 3 Production Department",
    img: "19.jpg",
    isDepartment: false,
    layer: "Department",
    orgCode: null,
    personInCharge: null,
    costCenter: null,
    costElement: null,
    jobGroup: null,
    subJobGroup: null,
    updateBy: null,
    role: null,
    section: null,
    dateUpdate: null,
  },
  {
    orgChartId: 18,
    id: "OrgNode_44",
    parentId: "OrgNode_1",
    name: "Thanakorn Raweesuit",
    title: null,
    position: "Pipe Compounding Department Manager",
    department: "Pipe Compounding Department",
    img: "20.jpg",
    isDepartment: false,
    layer: "Department",
    orgCode: null,
    personInCharge: null,
    costCenter: null,
    costElement: null,
    jobGroup: null,
    subJobGroup: null,
    updateBy: null,
    role: null,
    section: null,
    dateUpdate: null,
  },
  {
    orgChartId: 19,
    id: "OrgNode_45",
    parentId: "OrgNode_1",
    name: "Prapipit Kitchanachai",
    title: null,
    position: "Quality Assurance & Quality Control",
    department: "Logistics Department",
    img: "21.jpg",
    isDepartment: false,
    layer: "Department",
    orgCode: null,
    personInCharge: null,
    costCenter: null,
    costElement: null,
    jobGroup: null,
    subJobGroup: null,
    updateBy: null,
    role: null,
    section: null,
    dateUpdate: null,
  },
  {
    orgChartId: 20,
    id: "OrgNode_46",
    parentId: "OrgNode_1",
    name: "Kulapong Boonyaves",
    title: null,
    position: "Logistics Department Manager",
    department: "/Logistics Department",
    img: "22.jpg",
    isDepartment: false,
    layer: "Department",
    orgCode: null,
    personInCharge: null,
    costCenter: null,
    costElement: null,
    jobGroup: null,
    subJobGroup: null,
    updateBy: null,
    role: null,
    section: null,
    dateUpdate: null,
  },
  {
    orgChartId: 21,
    id: "OrgNode_47",
    parentId: "OrgNode_1",
    name: "Sittharpat Leuangaramsri",
    title: null,
    position: "Safety, Occupational Health & Environment Department Manager",
    department: "Safety, Occupational Health & Environment Department",
    img: "24.jpg",
    isDepartment: false,
    layer: "Department",
    orgCode: null,
    personInCharge: null,
    costCenter: null,
    costElement: null,
    jobGroup: null,
    subJobGroup: null,
    updateBy: null,
    role: null,
    section: null,
    dateUpdate: null,
  },
  {
    orgChartId: 22,
    id: "OrgNode_48",
    parentId: "OrgNode_1",
    name: "Surachet Pojjiranugoul",
    title: null,
    position: "Focused Improvement Manager",
    department: "Focused Improvement",
    img: "25.jpg",
    isDepartment: false,
    layer: "Department",
    orgCode: null,
    personInCharge: null,
    costCenter: null,
    costElement: null,
    jobGroup: null,
    subJobGroup: null,
    updateBy: null,
    role: null,
    section: null,
    dateUpdate: null,
  },
  {
    orgChartId: 23,
    id: "OrgNode_49",
    parentId: "OrgNode_1",
    name: "Puttinun Moungprasert",
    title: null,
    position: "Manager-HR Business Partner",
    department: "HR Business Partner",
    img: "26.jpg",
    isDepartment: false,
    layer: "Department",
    orgCode: null,
    personInCharge: null,
    costCenter: null,
    costElement: null,
    jobGroup: null,
    subJobGroup: null,
    updateBy: null,
    role: null,
    section: null,
    dateUpdate: null,
  },
  {
    orgChartId: 24,
    id: "OrgNode_50",
    parentId: "OrgNode_45",
    name: "Sirin Methakhup",
    title: null,
    position: "Quality Assurance Manager",
    department: "Quality Assurance & Quality Control Department",
    img: "27.jpg",
    isDepartment: false,
    layer: "Department",
    orgCode: null,
    personInCharge: null,
    costCenter: null,
    costElement: null,
    jobGroup: null,
    subJobGroup: null,
    updateBy: null,
    role: null,
    section: null,
    dateUpdate: null,
  },
  {
    orgChartId: 25,
    id: "OrgNode_51",
    parentId: "OrgNode_45",
    name: "Siripun Poorinutpong",
    title: null,
    position: "Quality Assurance & Quality Control Department",
    department: "Product Property Analytic & Control​",
    img: "28.jpg",
    isDepartment: false,
    layer: "Department",
    orgCode: null,
    personInCharge: null,
    costCenter: null,
    costElement: null,
    jobGroup: null,
    subJobGroup: null,
    updateBy: null,
    role: null,
    section: null,
    dateUpdate: null,
  },
  {
    orgChartId: 26,
    id: "OrgNode_52",
    parentId: "OrgNode_46",
    name: "Nattapol Pongpaothai",
    title: null,
    position: "Logistics Operation Manager",
    department: "Logistics Operation,Logistics Department",
    img: "8.jpg",
    isDepartment: false,
    layer: "Department",
    orgCode: null,
    personInCharge: null,
    costCenter: null,
    costElement: null,
    jobGroup: null,
    subJobGroup: null,
    updateBy: null,
    role: null,
    section: null,
    dateUpdate: null,
  },
  {
    orgChartId: 27,
    id: "OrgNode_53",
    parentId: "OrgNode_46",
    name: "Banjerd Waksoongnoen",
    title: null,
    position: "Outbound Logistics Manager",
    department: "Logistics Department",
    img: "9.jpg",
    isDepartment: false,
    layer: "Department",
    orgCode: null,
    personInCharge: null,
    costCenter: null,
    costElement: null,
    jobGroup: null,
    subJobGroup: null,
    updateBy: null,
    role: null,
    section: null,
    dateUpdate: null,
  },
];

export const ChartDataProject = [
  {
    projId: 1,
    projectName: "Test New Project",
    createBy: "Admin",
    dateCreate: "2021-12-12T00:00:00",
    description: "Test New Description",
    projectSize: "Small",
    projectInvest: "> 100,000",
    personInCharge: "Leanne Graham",
    startDate: "2021-12-12T00:00:00",
    endDate: "2021-12-12T00:00:00",
  },
  {
    projId: 2,
    projectName: "test",
    createBy: "Admin",
    dateCreate: "2021-12-12T00:00:00",
    description: "test",
    projectSize: "Medium",
    projectInvest: "> 1,000,000",
    personInCharge: "Clementine Bauch",
    startDate: null,
    endDate: "2021-12-12T00:00:00",
  },
];

export const ChartDataProjectAssign = [
  {
    projectId: 1,
    id: "ProjNode_1",
    parentId: "0",
    name: "Ervin Howell",
    role: "Manager",
    selectOption: "ย้ายทั้งหมดมาที่ Project",
    createBy: "Admin",
    dateCreate: "2021-12-12T00:00:00",
    projId: 1,
    reportTo: null,
    startDate: "2021-12-12T00:00:00",
    endDate: "2021-12-17T00:00:00",
    img: null,
    position: "Manager",
    department: "TPE",
    section: "TPE",
    rolePerson: 1,
  },
  {
    projectId: 2,
    id: "ProjNode_2",
    parentId: "ProjNode_1",
    name: "Leanne Graham",
    role: "Programmer",
    selectOption: "ย้าย Report to project",
    createBy: "Admin",
    dateCreate: "2021-12-12T00:00:00",
    projId: 1,
    reportTo: "Ervin Howell",
    startDate: "2021-12-12T00:00:00",
    endDate: "2021-12-17T00:00:00",
    img: null,
    position: "Officer",
    department: "TPE",
    section: "TPE",
    rolePerson: 0,
  },
  {
    projectId: 3,
    id: "ProjNode_3",
    parentId: "ProjNode_1",
    name: "Clementine Bauch",
    role: "Programmer",
    selectOption: null,
    createBy: "Admin",
    dateCreate: "2021-12-12T00:00:00",
    projId: 1,
    reportTo: "Ervin Howell",
    startDate: "2021-12-12T00:00:00",
    endDate: "2021-12-17T00:00:00",
    img: null,
    position: "Officer",
    department: "TPE",
    section: "TPE",
    rolePerson: 0,
  },
];
