import React, { Fragment } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";

const StyledTable = styled(Table)({
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
    padding: "8px 0",
    textAlign: "center",
  },
  "& .tableCellHead:first-of-type, .tableCell:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    // boxShadow: "rgb(255 255 255) 8px 0px 0px inset",
  },
  "& .tableCellHead:last-of-type, .tableCell:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    // boxShadow: "rgb(255 255 255) -8px 0px 0px inset",
  },
  "& .tableCell": {
    textAlign: "center",
    borderColor: "#919eab3d",
    padding: "8px 0",
    borderBottomStyle: "dashed",
  },
  "& .tableCellSummary": {
    borderTop: "5px solid #919eab3d",
    borderBottom: "none",
  },
  "& .tableCellBlank": {
    padding: 4,
  },
});

export default function TableMonthlyOTReport(props) {
  const { t, i18n } = useTranslation();
  const { data } = props;

  return (
    <TableContainer>
      <StyledTable aria-label="simple table">
        <TableHead>
          <TableRow>
            {data &&
              Object.entries(data[0]).map(
                ([key, value]) => {
                  return (
                    <Fragment>
                      {key === "workDate" ? (
                        <TableCell className={`tableCellHead cellStatusAction`}>
                          {t("Date")}
                        </TableCell>
                      ) : (
                        <TableCell className={`tableCellHead cellNumber`}>
                          {`OT ${key.replace("x", "x ")} (${t("Unit.ShortHours")})`}
                        </TableCell>
                      )}
                    </Fragment>
                  );
                }
              )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((row, index) => (
              <TableRow key={index}>
                {Object.entries(row).map(([key, value]) => {
                  return (
                    <Fragment>
                      {key === "workDate" ? (
                        <TableCell className={`tableCell cellStatusAction`}>
                          {dayjs(value)
                            .locale(localStorage.getItem("language") || "th")
                            .format(
                              localStorage.getItem("language") === "en"
                                ? "D MMM YYYY"
                                : "D MMM BBBB"
                            )}
                        </TableCell>
                      ) : (
                        <TableCell className={`tableCell cellNumber`}>
                          {value ? parseFloat(value / 60).toFixed(2) : "-"}
                        </TableCell>
                      )}
                    </Fragment>
                  );
                })}
              </TableRow>
            ))}
          <TableRow key={98}>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
          </TableRow>
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
}
