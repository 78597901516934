import React from "react";
import { Box, Dialog, DialogContent, Typography, styled } from "@mui/material";

import InfoOutlinedRoundedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffOutlinedRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import ButtonBlue from "./ButtonBlue";

const StyledDialog = styled(Dialog)({
  borderRadius: 8,
  "& .MuiDialogContent-root": {
    padding: 0,
    "& .content": {
      padding: 16,
      textAlign: "center",
      "& .icon-label": {
        fontSize: 90,
      },
      "& .action-container": {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "20px",
      },
      "& .header-label": {
        padding: "20px 0",
      },
    },
  },
});

const DialogStatusForm = (props) => {

  const  { open, onClose, statusCode, statusText, onConfirm } = props;

  const getStatusColor = (statusCode) => {
    if(statusCode === "success"){
      return "#388e3c"
    } else if(statusCode === "error"){
      return "#d32f2f"
    } else {
      return "#46cbe2";
    }
  }

  const getStatusIcon = (statusCode) => {
    if(statusCode === "success"){
      return <CheckCircleOutlineRoundedIcon className="icon-label" style={{color: getStatusColor(statusCode)}}/>
    } else if(statusCode === "error"){
      return <HighlightOffOutlinedRoundedIcon className="icon-label" style={{color: getStatusColor(statusCode)}}/>
    } else {
      return <InfoOutlinedRoundedIcon className="icon-label" style={{color: getStatusColor(statusCode)}}/>
    }
  }

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogContent>
        <div style={{ width: "100%", height: 6, backgroundColor: getStatusColor(statusCode) }} />
        <div className="content">
          {getStatusIcon(statusCode)}
          <div className="header-label">
            <Typography fontSize="18px" whiteSpace="pre-line">{statusText}</Typography>
          </div>
          <Box className="action-container">
            <ButtonBlue onClick={onConfirm}>{`ตกลง`}</ButtonBlue>
          </Box>
        </div>
      </DialogContent>
    </StyledDialog>
  )
}

export default DialogStatusForm;