import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Avatar, Box, Grid, IconButton, Chip } from "@mui/material";
import { styled } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DialogName from "./DialogName";
import {
  getOnBoardingList,
  getOnBoardingReviewer,
  deleteOnBoardingReviewer,
} from "../../../../../actions/onBoarding";
import DialogReview from "./DialogReview";
import { useTranslation } from "react-i18next";
import { getAffiliateOrganization } from "../../../../../actions/company";
import SelectAffiliateCompany from "../../../shared/general/SelectAffiliateCompany";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";

const StyledWrapBranch = styled("div")({
  marginBottom: 36,
  "& .wrap-branch-top": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .branch-scroll": {
    height: 280,
    overflow: "auto",
  },
});

const StyledAccordion = styled(Accordion)({
  backgroundColor: "rgba(0,0,0,0.03)",
  marginBottom: 12,
  borderRadius: 8,
  "&:before": {
    display: "none",
  },
});

const StyledAccordionSummary = styled(AccordionSummary)({
  display: "flex",
  alignItems: "center",
  padding: "16px 24px",
  "& .MuiTypography-root": {
    flexGrow: 1,
  },
});

const StyledAccordionDetails = styled(AccordionDetails)({
  backgroundColor: "#ffffff",
  padding: "16px",
});

const OnBoardingList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { result: onBoardingReviewer } = useSelector(
    (state) => state.onBoardingReviewer
  );
  const { result: onBoardingList } = useSelector(
    (state) => state.onBoardingList
  );
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );

  const [openDialog, setopenDialog] = useState(false);
  const [formData, setFormData] = useState(null);
  const [onBoardingListFormData, setOnBoardingListFormData] = useState(null);
  const [typeDialog, setTypeDialog] = useState("Add");
  const [reload, setreload] = useState();
  const [selectedCompany, setSelectedCompany] = useState("");

  useEffect(() => {
    dispatch(getOnBoardingList());
    dispatch(getOnBoardingReviewer());
    dispatch(getAffiliateOrganization());
  }, [reload]);

  useEffect(() => {
    if (affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      setSelectedCompany(affiliateOrganizationList[0]);
    }
  }, [affiliateOrganizationList]);

  const handleOpenDialog = (type, formData) => {
    setTypeDialog(type);
    setopenDialog(true);
    if (formData) {
      setFormData(formData);
    }
  };

  const handleClose = () => {
    setopenDialog(false);
    setreload(reload === 1 ? 2 : 1);
    setFormData(null);
  };

  const handleDeleteInspector = (idOnBoardingReviewer) => {
    Swal.fire({
      title: t("ConfirmDeleteInspector"),
      text: t("WantToDeleteInspector"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirm"),
      cancelButtonText: t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteOnBoardingReviewer(idOnBoardingReviewer)).then(() => {
          Swal.fire({
            title: t("InspectorDeleted"),
            text: t("TheInspectorHasBeenDeleted"),
            icon: "success",
            confirmButtonColor: "#46cbe2",
            confirmButtonText: t("OK"),
          }).then(() => {
            dispatch(getOnBoardingReviewer());
          });
        });
      }
    });
  };

  return (
    <>
      <StyledWrapBranch>
        <Box className="wrap-branch-top" sx={{ marginBottom: "16px" }}>
          <Box sx={{ minWidth: 400, marginRight: "auto" }}>
            <SelectAffiliateCompany
              options={affiliateOrganizationList}
              value={selectedCompany}
              onChange={(_, newValue) => setSelectedCompany(newValue)}
              placeholder={t("SelectCompany")}
            />
          </Box>
          <Box>
            <ButtonBlue
              style={{ padding: "14px 60px" }}
              variant="contained"
              onClick={() => handleOpenDialog("AddList")}
            >
              {t("AddItem")}
            </ButtonBlue>
          </Box>
        </Box>

        {onBoardingList && onBoardingList.length > 0 ? (
          <div>
            {onBoardingList
              .filter((row) => row.idCompany === selectedCompany.idCompany)
              .sort((a, b) => (a.assignmentType === "all" ? -1 : 1))
              .map((row, index) => (
                <StyledAccordion key={index}>
                  <StyledAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid container alignItems="center" width="100%">
                      <Grid item xs={8}>
                        <Typography>
                          {`${row.name} ภายใน ${row.day} วัน`}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} display="flex" justifyContent="center">
                        {row.assignmentType === "all" && (
                          <Chip
                            label={t("EnforceTask")}
                            sx={{
                              backgroundColor: "red",
                              color: "white",
                              fontWeight: "bold",
                              marginRight: 1,
                            }}
                            size="small"
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Typography sx={{ color: "text.secondary" }}>
                          {t("Inspector")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </StyledAccordionSummary>
                  <StyledAccordionDetails>
                    {onBoardingReviewer &&
                    onBoardingReviewer.filter(
                      (review) =>
                        review.idOnBoardingList === row.idOnBoardingList
                    ).length > 0 ? (
                      onBoardingReviewer
                        .filter(
                          (review) =>
                            review.idOnBoardingList === row.idOnBoardingList
                        )
                        .map((review, idx) => (
                          <Grid
                            container
                            key={idx}
                            spacing={2}
                            alignItems="center"
                            sx={{
                              marginBottom: "14px",
                              borderBottom: "1px solid #e0e0e0",
                              paddingBottom: 1,
                            }}
                          >
                            <Grid
                              item
                              xs={4}
                              display="flex"
                              alignItems="center"
                            >
                              <Avatar
                                src={review.imageProfile}
                                alt={review.firstname_TH}
                                sx={{ width: 40, height: 40, marginRight: 1.5 }}
                              />
                              <Typography>{`${review.firstname_TH} ${review.lastname_TH}`}</Typography>
                            </Grid>
                            <Grid item xs={4} textAlign="left">
                              <Typography>
                                {t("Department")}:{" "}
                                {review.departmentName || "-"}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} textAlign="right">
                              <IconButton
                                onClick={() =>
                                  handleDeleteInspector(
                                    review.idOnBoardingReviewer
                                  )
                                }
                              >
                                <DeleteIcon sx={{ color: "red" }} />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))
                    ) : (
                      <Typography sx={{ color: "text.secondary" }}>
                        {t("NoInspector")}
                      </Typography>
                    )}
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      width="100%"
                      height="100%"
                      mt={2}
                    >
                      <ButtonBlue
                        size="small"
                        variant="contained"
                        onClick={() => {
                          setOnBoardingListFormData({
                            idOnBoardingList: row.idOnBoardingList,
                            name: row.name,
                          });
                          handleOpenDialog("AddReview");
                        }}
                        sx={{ minWidth: "150px", minHeight: "50px" }}
                      >
                        {t("AddInspector")}
                      </ButtonBlue>
                    </Box>
                  </StyledAccordionDetails>
                </StyledAccordion>
              ))}
          </div>
        ) : (
          `${t("NoItems")}`
        )}
      </StyledWrapBranch>

      {openDialog && typeDialog !== "AddReview" && (
        <DialogName
          mode={typeDialog}
          open={openDialog}
          data={formData}
          handleClose={handleClose}
        />
      )}
      {openDialog && typeDialog === "AddReview" && (
        <DialogReview
          open={openDialog}
          handleClose={handleClose}
          data={onBoardingListFormData}
        />
      )}
    </>
  );
};

export default OnBoardingList;
