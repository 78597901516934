import React, { useState, useEffect } from "react";
import useTable from "../../../shared/table/useTable";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector, useDispatch } from "react-redux";
import { getAllIdvPlan } from "../../../../../actions/IndividualPlans";

import {
  Grid,
  Toolbar,
  TextField,
  InputAdornment,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Button,
  Avatar,
  Typography,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const headCells = [
  { id: "quater", label: "Quater" },
  { id: "objectiveName", label: "Objective Name" },
  { id: "createBy", label: "Create By" },
  { id: "dateCreate", label: "Date Create" },
  { id: "achieveResult", label: "Achieve" },
  { id: "actions", label: "" },
];

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiGrid-container": {
      marginBottom: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
  container: {
    padding: 16,
    overflowX: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  quater: {
    backgroundColor: "#e53935",
    fontSize: "18px",
    fontWeight: "600",
    width: 48,
    height: 48,
  },
  viewBtn: {
    backgroundColor: "#4f65df",
    color: "#fff",
    borderRadius: "50px",
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#4f65df",
    },
  },
  achieved: {
    backgroundColor: "#e0f2f1",
    color: "#009688",
    width: "90px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#e0f2f1",
    },
  },
  notAchieved: {
    backgroundColor: "#f9dde0",
    color: "#c62828",
    width: "110px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#f9dde0",
    },
  },
}));

function EvaluateApproveList() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: idvPlanStore } = useSelector((state) => state.IdvPlans);

  const [records, setRecords] = useState([]);
  const [recordItems, setRecordItems] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);

  const fetchData = () => {
    dispatch(getAllIdvPlan());
  };

  useEffect(() => {
    if (idvPlanStore) {
      const plan = idvPlanStore.filter(
        (item) => item.evaluateStatus == "Waiting Approval"
      );
      setRecords(plan);
      setRecordItems(plan);
      setIsLoading(false);
    }
  }, [idvPlanStore]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let x = [...recordItems];
    x = x.filter((y) => {
      return y.createBy.toLowerCase().includes(searchKey.toLocaleLowerCase());
    });
    setRecords(x);
  }, [searchKey]);

  return (
    <div className={classes.root}>
      <Toolbar>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              variant="outlined"
              style={{ width: "100%" }}
              label="Search Employee"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Toolbar>
      <Paper className={classes.container}>
        <TblContainer>
          <TblHead />
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={4}
                  align="center"
                  style={{ width: "100%", padding: "20px" }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {(recordsAfterPagingAndSorting().length > 0 &&
                  recordsAfterPagingAndSorting().map((item) => (
                    <TableRow key={item.planIdvId} hover>
                      <TableCell style={{ width: "100px" }}>
                        <Avatar className={classes.quater}>
                          {item.quater}
                        </Avatar>
                      </TableCell>
                      <TableCell>{item.objectiveName}</TableCell>
                      <TableCell style={{ width: "250px" }}>
                        {`${item.firstname_TH} ${item.lastname_TH}`}
                      </TableCell>
                      <TableCell style={{ width: "150px" }}>
                        {dayjs(item.dateCreate).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell style={{ width: "150px" }}>
                        <Typography variant="subtitle2">
                          <IconButton
                            className={
                              item.achieveResult == "Achieved"
                                ? `${classes.achieved}`
                                : `${classes.notAchieved}`
                            }
                            size="large"
                          >
                            <Typography
                              style={{ fontWeight: 600 }}
                              variant="caption"
                            >
                              {item.achieveResult}
                            </Typography>
                          </IconButton>
                        </Typography>
                      </TableCell>
                      <TableCell style={{ width: "100px" }} align="center">
                        <Button
                          variant="outlined"
                          className={classes.viewBtn}
                          onClick={() => {
                            history.push({
                              pathname: `/evaluationmanager/${item.objectiveName}`,
                              state: { planList: item },
                            });
                          }}
                        >
                          <SearchIcon /> View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))) || (
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      align="center"
                      style={{ width: "100px", height: "100px" }}
                    >
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
    </div>
  );
}

export default EvaluateApproveList;
