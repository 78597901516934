import React, { Fragment, useEffect, useState } from "react";
import DrawerCustom from "../../shared/general/Drawer";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, Box, Checkbox, Divider, Grid, InputAdornment, Tab, Tabs, Typography, styled } from "@mui/material";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";
import dayjs from "dayjs";
import NumberFormatTheme from "../../shared/general/NumberFormatTheme";
import DatePickerCustom from "../../shared/date/datePicker";
import { changeShiftFee, getShift } from "../../../../actions/shift";
import { openNotificationAlert } from "../../../../actions/notificationAlert";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .field-label": {
    fontSize: "14px",
    fontWeight: "500",
    paddingBottom: "4px"
  },
  "& .MuiCheckbox-root": {
    "&.Mui-checked": {
      color: "#46cbe2",
    },
  },
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0
      }
    }
  },
  "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
    borderColor: "#46cbe2",
    color: "#46cbe2",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#46cbe2",
  },
})

const DrawerShiftFeeSetting = (props) => {

  const { drawerConfig, onClose, isReadOnly } = props;
  const { t, i18n } = useTranslation();
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (drawerConfig.isOpen === true) {

      setTabValue(0);

      // useHookForm.reset({
      //   isBreakInWorkingTime: drawerConfig.isBreakInWorkingTime,
      //   startBreakTime: getTime().find(item => item.time+":00" === drawerConfig.data.startBreakTime) || null,
      //   endBreakTime: endBreakTime,
      // })
    }
  }, [drawerConfig.isOpen])

  return (
    <DrawerCustom
      title={t("ShiftValue")}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px"
        }
      }}
    >
      <StyledRoot>
        <Box marginBottom="24px">
          <Tabs
            value={tabValue}
            onChange={(_, newValue) => {
              setTabValue(newValue)
            }}
            variant="scrollable"
            allowScrollButtonsMobile
            sx={{
              position: "relative",
            }}
          >
            <Tab label={t("Change")} value={0} />
            <Tab label={t("AdvanceList")} value={1} />
          </Tabs>
        </Box>

        {tabValue === 0 && <ShiftFeeChangePanel {...({ drawerConfig, onClose, isReadOnly })} />}
        {tabValue === 1 && <ShiftFeeAdvancePanel {...({ drawerConfig, onClose })} />}

      </StyledRoot>
    </DrawerCustom>
  )
}

const ShiftFeeChangePanel = (props) => {

  const { drawerConfig, onClose, isReadOnly } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const validateYupSchema = yup.object({

  })

  const useHookForm = useForm({
    defaultValues: {
      morningShiftFee: "",
      afternoonShiftFee: "",
      nightShiftFee: "",
      start: dayjs().add(1, 'day')
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all"
  })

  const onSubmit = async (data) => {
    const formData = {
      idShiftGroup: drawerConfig.data.idShiftGroup,
      start: dayjs(data.start).format("YYYY-MM-DD"),
      remark: data.remark ? data.remark : null,
      morningShiftFee: data.morningShiftFee ? data.morningShiftFee : null,
      afternoonShiftFee: data.afternoonShiftFee ? data.afternoonShiftFee : null,
      nightShiftFee: data.nightShiftFee ? data.nightShiftFee : null,
    }

    const result = await dispatch(changeShiftFee(formData));

    if (result) {
      console.log(result)
      if (result.status === 200) {
        if (!result.data.success) {
          dispatch(openNotificationAlert({
            type: "error",
            message: result.data.message
          }))
        } else {
          dispatch(openNotificationAlert({
            type: "success",
            message: t("ChangeShiftValueSuccess")
          }))
          onClose()
          dispatch(getShift());
        }
      } else {
        dispatch(openNotificationAlert({
          type: "error",
          message: t("AnErrorOccurred")
        }))
      }
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }))
    }
  }

  return (
    <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className="field-label">{t("ShiftValue")}</Typography>
          <Typography fontSize="14px" color="text.secondary">{t("Current")}</Typography>
          {drawerConfig.data.hasMorningShift && <Grid marginTop="16px" container>
            <Typography flexGrow="1">{`${t("DayShift")}:`}</Typography>
            <Typography>{drawerConfig.data.morningShiftFee ? `${drawerConfig.data.morningShiftFee} ${t("Baht")}` : t("NoData")}</Typography>
          </Grid>}
          {drawerConfig.data.hasAfternoongShift && <Grid marginTop="16px" container>
            <Typography flexGrow="1">{`${t("MidShift")}:`}</Typography>
            <Typography>{drawerConfig.data.afternoonShiftFee ? `${drawerConfig.data.afternoonShiftFee} ${t("Baht")}` : t("NoData")}</Typography>
          </Grid>}
          {drawerConfig.data.hasNightShift && <Grid marginTop="16px" container>
            <Typography flexGrow="1">{`${t("NightShift")}:`}</Typography>
            <Typography>{drawerConfig.data.nightShiftFee ? `${drawerConfig.data.nightShiftFee} ${t("Baht")}` : t("NoData")}</Typography>
          </Grid>}
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize="14px" color="text.secondary">{t("ChangeShiftValue")}</Typography>
          {drawerConfig.data.hasMorningShift && <Grid marginTop="16px" container>
            <Box flexBasis="50%">
              <Typography paddingTop="13.5px" flexGrow="1">{`${t("DayShift")}:`}</Typography>
            </Box>
            <Box flexBasis="50%">
              <Controller
                control={useHookForm.control}
                name="morningShiftFee"
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                      endAdornment: <InputAdornment position="end" style={{ fontSize: "12px", userSelect: "none" }}>{t("Baht")}</InputAdornment>,
                    }}
                    inputProps={{
                      allowNegative: false,
                      value: field.value,
                      thousandSeparator: ",",
                      decimalScale: 0,
                      onValueChange: (values) => {
                        const { value } = values;
                        field.onChange(value)
                      },
                    }}
                    onChange={() => { }}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    error={fieldState.error ? true : false}
                  />
                )}
              />
            </Box>
          </Grid>}
          {drawerConfig.data.hasAfternoongShift && <Grid marginTop="16px" container>
            <Box flexBasis="50%">
              <Typography paddingTop="13.5px" flexGrow="1">{`${t("MidShift")}:`}</Typography>
            </Box>
            <Box flexBasis="50%">
              <Controller
                control={useHookForm.control}
                name="afternoonShiftFee"
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                      endAdornment: <InputAdornment position="end" style={{ fontSize: "12px", userSelect: "none" }}>{t("Baht")}</InputAdornment>,
                    }}
                    inputProps={{
                      allowNegative: false,
                      value: field.value,
                      thousandSeparator: ",",
                      decimalScale: 0,
                      onValueChange: (values) => {
                        const { value } = values;
                        field.onChange(value)
                      },
                    }}
                    onChange={() => { }}
                    helperText={useHookForm.formState.errors[field.name] ? useHookForm.formState.errors[field.name].message : null}
                    error={useHookForm.formState.errors[field.name] ? true : false}
                  />
                )}
              />
            </Box>
          </Grid>}
          {drawerConfig.data.hasNightShift && <Grid marginTop="16px" container>
            <Box flexBasis="50%">
              <Typography paddingTop="13.5px" flexGrow="1">{`${t("NightShift")}:`}</Typography>
            </Box>
            <Box flexBasis="50%">
              <Controller
                control={useHookForm.control}
                name="nightShiftFee"
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                      endAdornment: <InputAdornment position="end" style={{ fontSize: "12px", userSelect: "none" }}>{t("Baht")}</InputAdornment>,
                    }}
                    inputProps={{
                      allowNegative: false,
                      value: field.value,
                      thousandSeparator: ",",
                      decimalScale: 0,
                      onValueChange: (values) => {
                        const { value } = values;
                        field.onChange(value)
                      },
                    }}
                    onChange={() => { }}
                    helperText={useHookForm.formState.errors[field.name] ? useHookForm.formState.errors[field.name].message : null}
                    error={useHookForm.formState.errors[field.name] ? true : false}
                  />
                )}
              />
            </Box>
          </Grid>}
        </Grid>

        <Grid item xs={12}>
          <Typography className="field-label">{t("EffectiveDate")}</Typography>
          <Controller
            control={useHookForm.control}
            name="start"
            render={({ field, fieldState }) => (
              <DatePickerCustom
                {...field}
                views={["year", "month", "day"]}
                inputFormat="DD MMM YYYY"
                openTo="year"
                value={field.value}
                onChange={(newValue) => {
                  field.onChange(newValue)
                }}
                minDate={dayjs().add(1, 'day')}
                disableHighlightToday
                disableMaskedInput
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: t("DayMonthYear(AD)"),
                      readOnly: true,
                    }}
                    onBlur={field.onBlur}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    error={fieldState.error ? true : false}
                  />
                )}
                PaperProps={{
                  sx: {
                    "& .Mui-selected": {
                      backgroundColor: "#46cbe2!important",
                    },
                  }
                }}
                DialogProps={{
                  sx: {
                    "& .Mui-selected": {
                      backgroundColor: "#46cbe2!important",
                    },
                  }
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} container justifyContent="space-between">
          <ButtonBlue variant="text" onClick={onClose}>{t("Cancel")}</ButtonBlue>
          <ButtonBlue
            variant="contained"
            type="submit"
            disabled={isReadOnly}
          >
            {t("Save")}
          </ButtonBlue>
        </Grid>
      </Grid>
    </form>
  )
}

const ShiftFeeAdvancePanel = (props) => {
  const { t, i18n } = useTranslation();
  const { drawerConfig, onClose } = props;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography fontSize="14px" fontWeight="500">{t("AdvanceList")}</Typography>
        </Grid>
        <Grid item xs={12}>
          {drawerConfig.data.shiftFeeInAdvance ? (
            <Box>
              <Box>
                <Typography fontSize="14px" color="text.secondary">{t("EffectiveDate")}</Typography>
                <Typography fontWeight="600">
                  {dayjs(drawerConfig.data.shiftFeeInAdvance.startText).format(
                    i18n.resolvedLanguage === "th" ? "DD MMMM BBBB" : "DD MMMM YYYY"
                  )}
                </Typography>
              </Box>
              {drawerConfig.data.hasMorningShift && <Grid marginTop="8px" container>
                <Typography>{t("DayShift")} {drawerConfig.data.shiftFeeInAdvance.morningShiftFee ? <>{`${drawerConfig.data.shiftFeeInAdvance.morningShiftFee}`}<span style={{ paddingLeft: "8px", color: "#919eab" }}>{t("Baht")}</span></> : t("NoData")}</Typography>
              </Grid>}
              {drawerConfig.data.hasAfternoongShift && <Grid marginTop="8px" container>
                <Typography>{t("MidShift")} {drawerConfig.data.shiftFeeInAdvance.afternoonShiftFee ? <>{`${drawerConfig.data.shiftFeeInAdvance.afternoonShiftFee}`}<span style={{ paddingLeft: "8px", color: "#919eab" }}>{t("Baht")}</span></> : t("NoData")}</Typography>
              </Grid>}
              {drawerConfig.data.hasNightShift && <Grid marginTop="8px" container>
                <Typography>{t("NightShift")} {drawerConfig.data.shiftFeeInAdvance.nightShiftFee ? <>{`${drawerConfig.data.shiftFeeInAdvance.nightShiftFee}`}<span style={{ paddingLeft: "8px", color: "#919eab" }}>{t("Baht")}</span></> : t("NoData")}</Typography>
              </Grid>}
              <Box marginTop="8px" >
                <Typography fontSize="12px" color="text.secondary" fontStyle="italic">
                  {`${t("TransactionDate")} ${dayjs(drawerConfig.data.shiftFeeInAdvance.updateDateText).format(
                    i18n.resolvedLanguage === "th" ? "DD MMM BBBB HH.mm" : "DD MMM YYYY HH.mm"
                  )}`}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Typography fontStyle="italic" color="text.secondary" textAlign="center">{t("NoItems")}</Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ borderColor: "rgba(145, 158, 171, 0.32)", borderStyle: "dashed" }} />
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize="14px" fontWeight="500">{t("ChangeShiftHistory")}</Typography>
        </Grid>
        {drawerConfig.data.shiftFeeHistory.length > 0 ? (
          drawerConfig.data.shiftFeeHistory.map(item => (
            <Fragment key={item.idShiftGroupShiftFee}>
              <Grid item xs={12}>
                <Typography fontWeight="600">
                  [{dayjs(item.startText).format(i18n.resolvedLanguage === "th" ? "DD MMM BBBB" : "DD MMM YYYY")} - {item.end ? dayjs(item.end).format(i18n.resolvedLanguage === "th" ? "DD MMM BBBB" : "DD MMM YYYY") : t("Current")}]
                </Typography>
              </Grid>
              {drawerConfig.data.hasMorningShift && <Grid item xs={12} container>
                <Typography>{t("DayShift")} {item.morningShiftFee ? <>{`${item.morningShiftFee}`}<span style={{ paddingLeft: "8px", color: "#919eab" }}>{t("Baht")}</span></> : t("NoData")}</Typography>
              </Grid>}
              {drawerConfig.data.hasAfternoongShift && <Grid item xs={12} container>
                <Typography>{t("MidShift")} {item.afternoonShiftFee ? <>{`${item.afternoonShiftFee}`}<span style={{ paddingLeft: "8px", color: "#919eab" }}>{t("Baht")}</span></> : t("NoData")}</Typography>
              </Grid>}
              {drawerConfig.data.hasNightShift && <Grid item xs={12} container>
                <Typography>{t("NightShift")} {item.nightShiftFee ? <>{`${item.nightShiftFee}`}<span style={{ paddingLeft: "8px", color: "#919eab" }}>{t("Baht")}</span></> : t("NoData")}</Typography>
              </Grid>}
              <Grid item xs={12}>
                <Typography fontSize="12px" color="text.secondary" fontStyle="italic">
                  {`${t("Update")} ${dayjs(item.updateDateText).format(
                    i18n.resolvedLanguage === "th" ? "DD MMM BBBB HH.mm" : "DD MMM YYYY HH.mm"
                  )}`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ borderColor: "rgba(145, 158, 171, 0.32)", borderStyle: "dashed" }} />
              </Grid>
            </Fragment>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography fontStyle="italic" color="text.secondary" textAlign="center">{t("NoChangeHistory")}</Typography>
          </Grid>
        )}
        <Grid item xs={12} container justifyContent="flex-end">
          <ButtonBlue variant="text" onClick={onClose}>{t("Close")}</ButtonBlue>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DrawerShiftFeeSetting;