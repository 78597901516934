import React, { useEffect } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  Typography,
  styled,
} from "@mui/material";
import { Controller, useForm, useHookForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { updateUserPaymentCurrency } from "../../../../../../actions/user";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { currency } from "../../../../assets/data/currency";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px",
  },
});

const DialogPaymentCurrency = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
 const Currency = currency(t);
  const { drawerConfig, onClose } = props;

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const validateYupSchema = yup.object({
    paymentCurrency: yup.string().required("โปรดกรอกข้อมูล"),
  });

  const useHookForm = useForm({
    defaultValues: {
      paymentCurrency: "THB",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  useEffect(() => {
    if (drawerConfig.isOpen === true) {
      if (drawerConfig.isEdit) {
        useHookForm.reset({
          paymentCurrency: drawerConfig.data.paymentCurrency || "",
        });
      } else {
        useHookForm.reset({
          paymentCurrency: "",
        });
      }
    }
  }, [drawerConfig.isOpen]);

  const onSubmit = async (data) => {
    // console.log(data)

    const formData = {
      ...data,
      idEmployees: employeeProfile.idEmployees,
      updateBy: userProfile.idEmployees,
      updateDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    };

    Object.keys(formData).map((k) => {
      if (formData[k] === "") {
        formData[k] = null;
      } else {
        formData[k] = formData[k];
      }
    });

    const result = await dispatch(updateUserPaymentCurrency(formData));

    if (result) {
      if (result.status === 200) {
        dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
        onClose();
      }
    }
  };

  return (
    <DrawerCustom
      title={`${t("EditDataPayment")}`}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="field-label">{t("Currency")}</Typography>
              <Controller
                name="paymentCurrency"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme {...field} select>
                    <MenuItem value="none" disabled>
                      {`${t("selectCurrency")}`}
                    </MenuItem>
                    {Currency &&
                      Currency.map((currency) => (
                        <MenuItem key={currency.code} value={currency.unit}>
                          {currency.label}
                        </MenuItem>
                      ))}
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue
                variant="text"
                onClick={onClose}
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogPaymentCurrency;
