import React from "react";
import { styled } from "@mui/material/styles";
import { useTitle } from "react-use";
import { makeStyles } from "@mui/styles";
import { Button, Grid, Paper, Typography, Container } from "@mui/material";
import addKey from "./asset/addFile.svg";
import importKey from "./asset/importFile.svg";
import { useHistory } from "react-router-dom";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 32,
  },
  paperContainer: {
    marginTop: 32,
  },
  AddFileImage: {
    width: "70%",
    marginTop: 32,
  },
  ImportFileImage: {
    width: "100%",
    marginTop: 32,
  },
  primaryBtn: {
    backgroundColor: "#2f74eb",
    color: "#fff",
    fontWeight: 600,
    marginTop: theme.spacing(6),
    width: "200px",
    borderRadius: "25px",
    "&:hover": {
      backgroundColor: "#2A68D3",
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
  },
  paperSelect: {
    minHeight: "460px",
    
  },
}));

const StyledRoot = styled("div")({
  paddingTop: "120px",
  paddingBottom: "120px",
  "& .MuiTypography-h5": {
    fontWeight: 600,
  },
  "& .MuiTypography-h6": {
    fontWeight: 600,
  },
  "& .MuiPaper-root": {
    padding: 16,
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
});

function SelectKey() {
  useTitle("Select Key Additions - HR CORE DATA");
  const classes = useStyles();
  const history = useHistory();

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div className={classes.container}>
          <Typography variant="h5" align="center">
            Select to add key additions
          </Typography>
          <div className={classes.paperContainer}>
            <Grid container justifyContent="center" spacing={4}>
              <Grid item xs={12} md={4} lg={4}>
                <Paper className={classes.paperSelect}>
                  <Typography variant="h6" align="center">
                    Add Key Addition
                  </Typography>
                  <div className={classes.imageContainer}>
                    <img className={classes.AddFileImage} alt="" src={addKey} />
                  </div>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Button
                        variant="contained"
                        className={classes.primaryBtn}
                        endIcon={<NavigateNextIcon />}
                        onClick={() =>
                          history.push({
                            pathname: "/admin/selectkey/add",
                          })
                        }
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Paper className={classes.paperSelect}>
                  <Typography variant="h6" align="center">
                    Import Key Addition
                  </Typography>
                  <div className={classes.imageContainer}>
                    <img
                      className={classes.ImportFileImage}
                      alt=""
                      src={importKey}
                    />
                  </div>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Button
                        variant="contained"
                        className={classes.primaryBtn}
                        endIcon={<NavigateNextIcon />}
                        onClick={() =>
                          history.push({
                            pathname: "/admin/importkeyadditions",
                          })
                        }
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </StyledRoot>
  );
}

export default SelectKey;
