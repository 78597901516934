import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Divider, Chip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DialogEdit from "./DialogEdit";
import dayjs from "dayjs";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getProbationEmployeeAnswer } from "../../../../../../actions/probation";
import Loading from "../../../../shared/loading";
import DialogDetail from "./DialogDetail";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const Probation = () => {
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: probation, isFetching } = useSelector(
    (state) => state.probationQuestion
  );
  const { t, i18n } = useTranslation();
  const thaiLanguage = i18n.resolvedLanguage === "th"
  const [openEdit, setOpenEdit] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [selectProbation, setSelectProbation] = useState(null);

  const handleOpenDetail = (value) => {
    setOpenDetail(true);
    setSelectProbation(value);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
    setSelectProbation(null);
  };

  const handleOpenEdit = (value) => {
    setOpenEdit(true);
    setSelectProbation(value);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setSelectProbation(null);
  };

  useEffect(() => {
    if (employeeProfile) {
      dispatch(getProbationEmployeeAnswer(employeeProfile.idEmployees));
    }
  }, [employeeProfile]);

  if (isFetching) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Loading />
      </div>
    );
  }

  return (
    <div>
      {probation &&
        probation.map((item, index) =>  {
          const result = item.adminResult ? item.adminResult : item.managerResult
          const evaluateDate = item.adminEvaluateDate ? item.adminEvaluateDate : item.managerEvaluateDate
          return (
            <Grid container spacing={2} key={index}>
              <Grid item xs={12} container alignItems="center">
                <Typography flexGrow="1" fontSize="16px" fontWeight="600">
                  {`${t("ProbationResult")} ${item.probationRound}`}
                </Typography>
                {!userProfile.readOnly && item.idProbation && (
                  <ButtonBlue
                    variant="text"
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() => handleOpenEdit(item)}
                  >
                    {t("EditData")}
                  </ButtonBlue>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledHeadLabel color="text.secondary">
                  {t("StartDate")}
                </StyledHeadLabel>
                <Typography variant="h6">
                  {dayjs(item.startDate).format(
                    thaiLanguage ? "DD MMMM BBBB" : "DD MMMM YYYY"
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledHeadLabel color="text.secondary">
                  {t("EndDate")}
                </StyledHeadLabel>
                <Typography variant="h6">
                  {dayjs(item.probationDate).format(
                    thaiLanguage ? "DD MMMM BBBB" : "DD MMMM YYYY"
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} display="flex" alignItems="center" gap="8px">
                <StyledHeadLabel color="text.secondary">
                  {t("ProbationResult")}
                </StyledHeadLabel>
                {dayjs(item.resignDate).isBefore(item.probationDate) ? (
                  <Chip label={t("Terminated")} color="error" />
                ) : result === 1 ? (
                  <Chip label={t("Pass")} color="success" />
                ) : result === 0 ? (
                  <Chip label={t("NotPass")} color="error" />
                ) : (
                  <Chip label={t("WaitingForEvaluation")} color="warning" />
                )}
              </Grid>
              {evaluateDate && (
                <Grid item xs={12}>
                  <Typography fontSize="14px" color="text.secondary">
                    {`${t("Assessor")} : ${
                      thaiLanguage
                        ? `${item.approvedBy} (${item.approvedBy_positionName})`
                        : `${item.approvedBy_EN} (${item.approvedBy_positionName_EN})`
                    }`}
                  </Typography>
                  <Typography fontSize="14px" color="text.secondary">
                    {dayjs(evaluateDate).format(
                      thaiLanguage ? "D MMMM BBBB HH:mm" : "D MMMM YYYY HH:mm"
                    )}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <ButtonBlue
                  onClick={() => handleOpenDetail(item)}
                  disabled={Object.keys(item.questionSet).length === 0}
                >
                  {t("Details")}
                </ButtonBlue>
              </Grid>
              {probation.length !== index + 1 && (
                <Grid item xs={12}>
                  <StyledDivider />
                </Grid>
              )}
            </Grid>
          )
        })}
      {openEdit && (
        <DialogEdit
          open={openEdit}
          handleClose={handleCloseEdit}
          probation={selectProbation}
        />
      )}
      {openDetail && (
        <DialogDetail
          open={openDetail}
          handleClose={handleCloseDetail}
          probation={selectProbation}
        />
      )}
    </div>
  );
};

export default Probation;
