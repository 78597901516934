import React from "react";
import {
  Autocomplete,
  Box,
  Popper,
  Stack,
  TextField,
  Typography,
  autocompleteClasses,
  styled,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import DatePickerCustom from "../../../../shared/date/datePicker";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../../shared/general/Drawer";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledPopper = styled(Popper)({
  width: "330px !important",

  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    minHeight: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const DrawerDownload = ({
  openDownload,
  handleCloseDownload,
  handleDownload,
  additionSpecial,
  idCompareOption,
}) => {
  const { t } = useTranslation();

  const validateSchema = yup.object({
    searchDate: yup.date(),
    additionSpecial: yup.object().nullable().required(t("PleaseSelectData")),
  })

  const { control, handleSubmit, formState: { isSubmitting } } = useForm({
    defaultValues: {
      searchDate: dayjs(),
      additionSpecial: null,
    },
    resolver: yupResolver(validateSchema),
  })

  return (
    <DrawerCustom
      anchor={"right"}
      open={openDownload}
      onClose={handleCloseDownload}
      title={t("DownloadAdditionSpecial")}
      fullWidth
    >
      <form onSubmit={handleSubmit(handleDownload)}>
        <Stack spacing={2} padding={3}>
          <div>
            <Typography className="label" color="text.third">
              {t("SelectMonth")}
            </Typography>
            <Controller 
              control={control}
              name="searchDate"
              render={({ field }) => (
                <DatePickerCustom
                  {...field}
                  minDate={dayjs().subtract(2, 'month')}
                  maxDate={dayjs()}
                  inputFormat="MMMM YYYY"
                  views={['year', 'month']}
                  openTo={'month'}
                  value={field.value}
                  onChange={(newValue) => field.onChange(newValue)}
                />
              )}
            />
          </div>
          <StyledBoxSearch>
            <Controller 
              control={control}
              name="additionSpecial"
              render={({ field, fieldState }) => (
                <StyledAutocomplete
                  options={additionSpecial}
                  onChange={(_, newValue) => field.onChange(newValue)}
                  value={field.value}
                  isOptionEqualToValue={(option, value) => option[idCompareOption] === value[idCompareOption]}
                  popupIcon={<i className="fa-light fa-chevron-down"></i>}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      placeholder={t("AdditionSpecial")}
                      InputProps={{ ...params.InputProps, endAdornment: null }}
                      error={Boolean(fieldState.error)}
                      helperText={Boolean(fieldState.error) && fieldState.error.message}
                    />
                  )}
                  PopperComponent={StyledPopper}
                  noOptionsText={t("NoData")}
                />
              )}
            />
          </StyledBoxSearch>
          <div style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
            <ButtonBlue color="error" onClick={handleCloseDownload}>
              {t("Close")}
            </ButtonBlue>
            <ButtonBlue
              type="submit"
              variant="contained"
              startIcon={<DownloadIcon />}
              disabled={isSubmitting}
            >
              {t("Download")}
            </ButtonBlue>
          </div>
        </Stack>
      </form>
    </DrawerCustom>
  );
};

export default DrawerDownload;
