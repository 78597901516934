import React, { useEffect, useState } from "react";
import { Card, CardActions, CardContent, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getPerformanceEmployee } from "../../../../actions/estimatePerformance";
import Loading from "../../shared/loading";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import PerformanceForm from "./performanceForm";

const Performance = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: performanceQuestion, isFetching } = useSelector(
    (state) => state.performanceQuestion
  );

  const [open, setOpen] = useState(false);
  const [selectQuestionSet, setSelectQuestionSet] = useState(null);

  const handleOpen = (value) => {
    setOpen(true);
    setSelectQuestionSet(value);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectQuestionSet(null);
  };

  useEffect(() => {
    dispatch(getPerformanceEmployee());
  }, []);

  if (isFetching) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Loading />
      </div>
    );
  }

  return (
    <div>
      {performanceQuestion && performanceQuestion.length > 0 ? (
        performanceQuestion.map((item) => (
          <Card>
            <CardContent>
              <Typography>
                {`${t("Year")}: ${item.year}`}
              </Typography>
              <Typography>
                {`${t("Duration")}: ${dayjs(item.startDate).format("DD/MM/YYYY")} - ${dayjs(item.endDate).format("DD/MM/YYYY")}`}
              </Typography>
              <Typography>
                {`${t("QuestionSet")}: ${item.questionSet.questionSetName}`}
              </Typography>
            </CardContent>
            <CardActions>
              <ButtonBlue
                variant="contained"
                onClick={() => handleOpen(item)}
              >
                {t("Evaluate")}
              </ButtonBlue>
            </CardActions>
          </Card>
        ))
      ) : (
        <Typography variant="h6">{t("NoData")}</Typography>
      )}
      {open && (
        <PerformanceForm
          open={open}
          handleClose={handleClose}
          performance={selectQuestionSet}
        />
      )}
    </div>
  );
};

export default Performance;
