import React, { useState, useEffect } from "react";
import { Box, Button, Grid, IconButton, Stack, TextField, Typography } from "@mui/material"
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { updateCompanyAndEmployeeCareer } from "../../../../actions/careerPlan";

const useStyles = makeStyles(() => ({
    text: {
        fontSize: 17,
    }
}));

export const CareerPlanCard = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [openCompanyEdit, setOpenCompanyEdit] = useState(false);
    const [openManagerEdit, setOpenManagerEdit] = useState(false);
    const {
        idEmployees,
        title,
        manager1, manager2, manager3, company1
    } = props;

    const [dataToUpdate, setDataToUpdate] = useState({
        [`manager${title}1`]: manager1,
        [`manager${title}2`]: manager2,
        [`manager${title}3`]: manager3,
        idEmployees: idEmployees
    });

    const [CompanyDataToUpdate, setCompanyDataToUpdate] = useState({
        [`company${title}1`]: company1,
        idEmployees: idEmployees
    });

    const handleOpenUpateManager = () => {
        setOpenManagerEdit(true);
        setOpenCompanyEdit(false);
    }

    const handleCloseUpdateManager = () => {
        setOpenManagerEdit(false);
    }

    const handleOpenUpateCompany = () => {
        setOpenCompanyEdit(true);
        setOpenManagerEdit(false);
    }

    const handleCloseUpdateCompany = () => {
        setOpenCompanyEdit(false);
    }

    const handleManagerUpdateData = async () => {
        try {
            const response = await updateCompanyAndEmployeeCareer(dataToUpdate);
            if (response) {
                window.location.reload();
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleCompanyUpdateData = async () => {
        try {
            const response = await updateCompanyAndEmployeeCareer(CompanyDataToUpdate);
            if (response) {
                window.location.reload();
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Grid container spacing={2} xs={12}>
            <Grid item xs={12} spacing={2}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <PersonIcon
                            style={{ fontSize: "large", color: "#DB4178" }}
                        />
                        <Typography
                            style={{
                                fontSize: 19,
                                fontWeight: "bold",
                                color: "#DB4178",
                                marginLeft: "5px",
                            }}
                        >
                            {t("Manager")}
                        </Typography>
                    </Stack>
                    <IconButton onClick={() => handleOpenUpateManager()}>
                        <EditIcon style={{ fontSize: "large", color: "#DB4178" }} />
                    </IconButton>
                </Stack>

                {!openManagerEdit ? (
                    <>
                        {manager1 ? (
                            <Box sx={{ p: 2, bgcolor: "#F4E3EC", mt: 2, borderRadius: 2 }}>
                                <Stack direction={"column"} spacing={1}>
                                    <Typography
                                        className={classes.text}
                                        style={{ fontSize: 16, marginLeft: "15px" }}
                                    >
                                        {manager1 || "-"}
                                    </Typography>

                                    <Typography
                                        className={classes.text}
                                        style={{ fontSize: 16, marginLeft: "15px" }}
                                    >
                                        {manager2 || "-"}
                                    </Typography>

                                    <Typography
                                        className={classes.text}
                                        style={{ fontSize: 16, marginLeft: "15px" }}
                                    >
                                        {manager3 || "-"}
                                    </Typography>
                                </Stack>
                            </Box>
                        ) : (
                            <Box sx={{ p: 2, bgcolor: "#F4E3EC", mt: 2, borderRadius: 2 }}>
                                <Typography
                                    className={classes.text}
                                    style={{ fontSize: 16, marginLeft: "15px" }}
                                >
                                    {t("NoData")}
                                </Typography>
                            </Box>
                        )}
                    </>
                ) : (
                    <Box sx={{ p: 2, bgcolor: "#F4E3EC", mt: 2, borderRadius: 2 }}>
                        <Stack direction={"column"} spacing={2}>
                            <TextField
                                variant="outlined"
                                label={`${title === "MoreDev" ? "More to improve" : title} 1`}
                                value={dataToUpdate[`manager${title}1`]}
                                onChange={(e) => setDataToUpdate({ ...dataToUpdate, [`manager${title}1`]: e.target.value })}
                            />
                            <TextField
                                variant="outlined"
                                label={`${title === "MoreDev" ? "More to improve" : title} 2`}
                                value={dataToUpdate[`manager${title}2`]}
                                onChange={(e) => setDataToUpdate({ ...dataToUpdate, [`manager${title}2`]: e.target.value })}
                            />
                            <TextField
                                variant="outlined"
                                label={`${title === "MoreDev" ? "More to improve" : title} 3`}
                                value={dataToUpdate[`manager${title}3`]}
                                onChange={(e) => setDataToUpdate({ ...dataToUpdate, [`manager${title}3`]: e.target.value })}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Stack direction={"row"} spacing={2}>
                                    <Button onClick={() => handleCloseUpdateManager()}>
                                        {t("Close")}
                                    </Button>
                                    <Button color="success" onClick={() => handleManagerUpdateData()}>
                                        {t("Submit")}
                                    </Button>
                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                )}
            </Grid>

            <Grid item xs={12}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <SupervisorAccountIcon
                            style={{ fontSize: "large", color: "#DB4178" }}
                        />
                        <Typography
                            style={{
                                fontSize: 19,
                                fontWeight: "bold",
                                color: "#DB4178",
                                marginLeft: "5px",
                            }}
                        >
                            {t("Company")}
                        </Typography>
                    </Stack>
                    <IconButton onClick={() => handleOpenUpateCompany()}>
                        <EditIcon style={{ fontSize: "large", color: "#DB4178" }} />
                    </IconButton>
                </Stack>

                {!openCompanyEdit ? (
                    <Box sx={{ p: 2, bgcolor: "#F4E3EC", mt: 2, borderRadius: 2 }}>
                        <Stack direction={"column"} spacing={1}>
                            <Typography
                                className={classes.text}
                                style={{ fontSize: 16, marginLeft: "15px" }}
                            >
                                {company1 || `${t("NoData")}`}
                            </Typography>
                        </Stack>
                    </Box>
                ) : (
                    <Box sx={{ p: 2, bgcolor: "#F4E3EC", mt: 2, borderRadius: 2 }}>
                        <Stack direction={"column"} spacing={2}>
                            <TextField
                                variant="outlined"
                                multiline
                                rows={4}
                                label={`${title === "MoreDev" ? "More to improve" : title} 1`}
                                value={CompanyDataToUpdate[`company${title}1`]}
                                onChange={(e) => setCompanyDataToUpdate({ ...CompanyDataToUpdate, [`company${title}1`]: e.target.value })}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Stack direction={"row"} spacing={2}>
                                    <Button onClick={() => handleCloseUpdateCompany()}>
                                        {t("Close")}
                                    </Button>
                                    <Button color="success" onClick={() => handleCompanyUpdateData()}>
                                        {t("Submit")}
                                    </Button>
                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                )}
            </Grid>
        </Grid>
    )
}