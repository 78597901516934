import { httpClient } from "./httpClient";

const getSuccessorEmployees = () => {
  return httpClient.get(`successoremployees`);
};

const getSuccessorEmployeesById = (idEmployees) => {
  return httpClient.get(`successoremployeesbyid/${idEmployees}`);
}

const postSuccessor = (formData) => {
  return httpClient.post(`successor`, formData);
};

const getSuccessorRole = (idEmployeePosition) => {
  return httpClient.get(`successorrole/${idEmployeePosition}`);
}

const deleteSuccessor = (idEmployee, idEmployeePostion) => {
  return httpClient.delete(`successorrole/${idEmployee}/${idEmployeePostion}`);
};

const getSuccessorById = (idEmployees) => {
  return httpClient.get(`successorbyid/${idEmployees}`);
}

export default {
  getSuccessorEmployees,
  getSuccessorEmployeesById,
  postSuccessor,
  getSuccessorRole,
  deleteSuccessor,
  getSuccessorById,
};