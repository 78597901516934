import * as React from "react";
import { Box, Container, styled, Typography } from "@mui/material";
import AccessTimeline from "./AccessTimeline";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
});

const AccessTime = () => {
  const { t, i18n } = useTranslation();

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            {`${t("AppMenu.AccessTime")}`}
          </Typography>
        </div>
        <AccessTimeline />
      </Container>
    </StyledRoot>
  );
};

export default AccessTime;
