import {
  ATTENDANCEWEEKLY_FETCHING,
  ATTENDANCEWEEKLY_FAILED,
  ATTENDANCEWEEKLY_SUCCESS,
} from "../actions/types";

const initialState = {
  result: [],
  isFetching: false,
  isError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ATTENDANCEWEEKLY_FETCHING:
      return { ...state, result: [], isFetching: true, isError: false };
    case ATTENDANCEWEEKLY_FAILED:
      return { ...state, result: [], isFetching: false, isError: true };
    case ATTENDANCEWEEKLY_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    default:
      return state;
  }
}
