import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  FormControl,
  TextField,
  Popper,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem
} from "@mui/material";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import DrawerCustom from "../../../../shared/general/Drawer";

import { getPaymentType, getEmployeeProfile } from "../../../../../../actions/employee";
import { updateUser } from "../../../../../../actions/user";


const StyledRoot = styled("div")({
  width: 450,
  padding: 24,
  "& .field-label":{
    paddingBottom: 4
  }
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledListPayment = styled(Box)({
  display: "flex",
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 360,
    },
  },
};


const PaymentChange = (props) => {
  const { formData, handleChangePaymentType } =
    props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [paymentTypeList, setPaymentTypeList] = useState(null);


  useEffect(() => {
    async function fetch(params) {
      const result = await dispatch(getPaymentType());
      if (result) {
        setPaymentTypeList(result.data);
      } else {
      }
    }

    fetch();
  }, []);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <StyledContentLabel>{t("PaymentTypeName")}/{t("PaymentRound")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12}>
        {paymentTypeList && (
          <StyledAutocomplete
            options={paymentTypeList}
            onChange={(event, newValue) => {
              console.log("newValue: ", newValue);
              handleChangePaymentType(newValue.idPaymentType);
            }}
            value={
              formData.idPaymentType
                ? paymentTypeList.find(
                    (x) => x.idPaymentType === formData.idPaymentType
                  )
                : null
            }
            popupIcon={<i class="fa-light fa-chevron-down"></i>}
            getOptionLabel={(option) =>
              `${option.paymentTypeName} ${
                option.paymentRoundName
                  ? `- (${t("Pay")}${option.paymentRoundName})`
                  : ""
              }`
            }
            renderOption={(props, option) => (
              <StyledListPayment component="li" {...props}>
                <Typography>
                  {option.paymentTypeName}
                  <Typography component="span" color="text.secondary">{`${
                    option.paymentRoundName
                      ? ` - (${t("Pay")}${option.paymentRoundName})`
                      : ""
                  }`}</Typography>
                </Typography>
              </StyledListPayment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t("PaymentType")}
                InputProps={{ ...params.InputProps, endAdornment: null }}
              />
            )}
            PopperComponent={StyledPopper}
            noOptionsText={t("No_Data")}
          />
        )}
      </Grid>
    </Grid>
  );
};



const DialogEdit = (props) => {
  const {t,i18n} = useTranslation();
  const { open, mode, handleCloseDialog } = props;
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );

  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (open) {
      if (mode === "payment") {
        setFormData({
          idEmployees: employeeProfile.idEmployees,
          idPaymentType: employeeProfile.idPaymentType,
        });
      }
      
    }
  }, [open]);

  const handleChangePaymentType = (value) => {
    setFormData({
      ...formData,
      ["idPaymentType"]: value,
    });
  };

  const handleSubmit = async () => {
    console.log("formData: ", formData)
    const result = await dispatch(updateUser(formData));
      if (result) {
        handleCloseDialog();
        dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
      }
  };

  return (
    <DrawerCustom
      title={`${t("EditEmploymentType")}/${t("PaymentRound")}`}
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        {mode === "payment" && (
          <PaymentChange formData={formData} handleChangePaymentType={handleChangePaymentType} />
        )}
        
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleCloseDialog}>
           {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit}>
            {t("SaveData")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
