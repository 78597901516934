import React, { useEffect, useState } from "react";
//Material-UI Core
import {
  Dialog,
  Button,
  Typography,
  Grid,
  CircularProgress,
  Avatar,
} from "@mui/material";
import dayjs from "dayjs";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";

//Icons
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VpnKeyRoundedIcon from "@mui/icons-material/VpnKeyRounded";
import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";

const useStyles = makeStyles(() => ({
  root: {
    //color: '#222f3e',
  },
  dialog: {
    padding: 16,
    position: "absolute",
    top: 80,
  },
  dialogTitle: {
    borderBottom: "1px solid #dee2e6",
    color: "#222f3e",
  },
  dialogContent: {
    marginTop: 16,
    paddingTop: 24,
    paddingLeft: 0,
    paddingRight: 0,
  },
  DialogActions: {
    justifyContent: "flex-end",
    marginTop: 24,
  },
  expandIcon: {
    border: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: "50%",
    fontSize: "25px",
  },
  keySvg: {
    marginRight: 16,
  },
  viewBtn: {
    backgroundColor: "#4f65df",
    color: "#fff",
    borderRadius: "50px",
    fontSize: "12px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#4f65df",
    },
  },
  noData_Section: {
    textAlign: "center",
    marginTop: 40,
    color: "#222f3e",
  },
  noDataSvg: {
    fontSize: "3.5rem",
  },
  noDataText: {
    fontWeight: "600",
    fontSize: "18px",
  },
  accordionObj: {
    marginBottom: "0px !important",
  },
  quater: {
    backgroundColor: "#e53935",
    fontWeight: "600",
    width: 40,
    height: 40,
  },
}));

const styles = (theme) => ({
  root: {
    padding: 16,
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: "#9e9e9e",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 16,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: 8,
  },
}))(MuiDialogActions);

const Accordion = withStyles({
  root: {
    color: "#222f3e",
    backgroundColor: "#fff",
    border: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: "5px",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    display: "block",
    padding: 24,
    backgroundColor: "#fafafa",
    borderLeft: "5px solid #4f65df",
    "& .MuiTypography-h6": {
      fontSize: "1.1rem",
      color: "#222f3e",
    },
    textAlign: "start",
  },
}))(MuiAccordionDetails);

export default function TeamDashboardModal(props) {
  const classes = useStyles();
  const history = useHistory();
  const {t,i18n} = useTranslation();
  const {
    teamDashboardModal,
    setTeamDashboardModal,
    teamPlan,
    isChartLoading,
  } = props;

  return (
    <div className={classes.root}>
      <Dialog
        open={teamDashboardModal.isOpen}
        fullWidth
        maxWidth="md"
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle
          className={classes.dialogTitle}
          onClose={() =>
            setTeamDashboardModal({ ...teamDashboardModal, isOpen: false })
          }
        >
          {teamDashboardModal.teamName} -{" "}
          {teamDashboardModal.type == "organization"
            ? "Organization Objective"
            : "Team Objective"}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {isChartLoading ? (
            <CircularProgress />
          ) : (
            <>
              {(teamPlan.length > 0 &&
                teamPlan.map((item) => (
                  <Accordion
                    key={
                      item.planTeamId == null ? item.planOrgId : item.planTeamId
                    }
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon className={classes.expandIcon} />
                      }
                      aria-controls="panel1a-content"
                    >
                      <Grid
                        container
                        alignItems="center"
                        className={classes.accordionObj}
                      >
                        <Grid item xs={1}>
                          <Avatar className={classes.quater}>
                            {item.quater}
                          </Avatar>
                        </Grid>
                        <Grid item></Grid>
                        <Typography>{item.objectiveName}</Typography>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container justifyContent="flex-end">
                        <Grid item>
                          <Button
                            variant="contained"
                            className={classes.viewBtn}
                            onClick={() => {
                              history.push({
                                pathname:
                                  item.planTeamId == null
                                    ? "/organization"
                                    : "/team",
                                state: { planList: item },
                                isInfo: true,
                              });
                            }}
                          >
                            <SearchIcon />
                            View
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ marginBottom: 2 }}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: "600" }}
                        >
                          {t("KeyResult")}
                        </Typography>
                      </Grid>
                      {item.keyResult1 && (
                        <Grid container sx={{ marginBottom: 1 }}>
                          <Grid item>
                            <Typography className="keyResult">
                              <VpnKeyRoundedIcon className={classes.keySvg} />
                              {item.keyResult1}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      {item.keyResult2 && (
                        <Grid container sx={{ marginBottom: 1 }}>
                          <Grid item>
                            <Typography className="keyResult">
                              <VpnKeyRoundedIcon className={classes.keySvg} />
                              {item.keyResult2}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      {item.keyResult3 && (
                        <Grid container sx={{ marginBottom: 1 }}>
                          <Grid item>
                            <Typography className="keyResult">
                              <VpnKeyRoundedIcon className={classes.keySvg} />
                              {item.keyResult3}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      <Grid container>
                        <Grid item>
                          <Typography
                            variant="body1"
                            style={{ marginTop: "10px" }}
                          >
                            Create By : {`${item.firstName} ${item.lastName}`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item>
                          <Typography
                            variant="body1"
                            style={{ marginTop: "10px" }}
                          >
                            Date Create :{" "}
                            {dayjs(item.dateCreate).format("DD/MM/YYYY")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))) || (
                <div className={classes.noData_Section}>
                  <FindInPageIcon className={classes.noDataSvg} />
                  <p className={classes.noDataText}>No Data</p>
                </div>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.DialogActions}>
          {/*
            <Button
            color='primary'
            variant='outlined'
            onClick={() =>
              setTeamDashboardModal({ ...teamDashboardModal, isOpen: false })
            }
          >
            Close
          </Button>
           */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
