import React from "react";
import { styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const DetailDiv = (props) => {
  const { title, data } = props;
  const { t, i18n } = useTranslation();

  return (
    <div>
      <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
        {t(title)}
      </StyledHeadLabel>
      <Typography
        component="p"
        variant="subtitle1"
        sx={{
          paddingLeft: 4,
          marginBottom: 2,
          marginTop: 1,
        }}
      >
        &nbsp;&nbsp;{data}
      </Typography>
    </div>
  );
};

export default DetailDiv;
