import React, { Fragment, useState } from "react";
import { Box, Card, Container, Grid, styled, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { useDispatch } from "react-redux";
import { forgetPassword } from "../../../../actions/account";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
})

const RenderForm = (props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const { setIsSuccess } = props;
  const [isFetching, setIsFetching] = useState(false);

  const { control, handleSubmit, setError } = useForm({
    defaultValues: {
      email: "",
    }
  });

  const onSubmit = async (submittedData) => {

    setIsFetching(true);
    
    const res = await dispatch(forgetPassword(submittedData));

    if(res){
      setIsFetching(false);
      if(res.status === 200){
        if(res.data.errorCode === "EMAIL_NOT_FOUND"){
          setError("email", { type: "custom", message: t("emailNotFound")})
        } else {
          setIsSuccess(true)
        }
      } else {
        setError("email", { type: "custom", message: t("systemError")})
      }
    }
  }

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">{t("ForgotPassword")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={14} color="text.third">{t("enterEmailPrompt")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={14} paddingBottom="8px">{t("Email")}</Typography>
            <Controller
              control={control}
              name="email"
              rules={{
                required: {value: true, message: t("enterEmail")},
                pattern: {
                 value: /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z.]{1,}[A-z]$/,
                 message: t("enterValidEmail")
                }
              }}
              render={({field, formState}) => (
                <TextFieldTheme
                  {...field}
                  autoComplete="email"
                  helperText={formState.errors && formState.errors[field.name] && formState.errors[field.name].message}
                  error={formState.errors && formState.errors[field.name] ? true: false}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonBlue type="submit" variant="contained" fullWidth sx={{paddingTop: "12px", paddingBottom: "12px"}} disabled={isFetching}>{t("proceed")}</ButtonBlue>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <ButtonBlue component={Link} to="/login">{t("LogIn")}</ButtonBlue>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  )
}

const RenderMessage = () => {
  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">{t("passwordResetRequest")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize={14} color="text.third">{t("passwordResetLinkSentWithExpiry")}</Typography>
        </Grid>
      </Grid>
    </Fragment>
  )
}

const ForgetPasswordPage = () => {

  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <StyledRoot className="page">
      <Container maxWidth="sm">
        <Card sx={{padding: "48px", boxShadow: 2}}>
          {!isSuccess && (
            <RenderForm
              setIsSuccess={setIsSuccess}
            />
          )}
          {isSuccess && (
            <RenderMessage />
          )}
        </Card>
      </Container>
    </StyledRoot>
  )
}

export default ForgetPasswordPage