import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getOrganizationObjective, updateOrganizationObjective } from "../../../../../../../actions/okrs";
import { openNotificationAlert } from "../../../../../../../actions/notificationAlert";
import { useParams } from "react-router-dom";

const ResponsiveButtonBlue = styled(Button)({
  backgroundColor: "rgba(219, 65, 120, 1)",
  "&:hover": {
    backgroundColor: "#C53B6C",
  },
});

const DialogEditObjective = ({ open, handleClose, data }) => {
  const { idOrganizationObjective } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    objectiveName: "",
    impactName: "",
  });

  useEffect(() => {
    if (data) {
        console.log(data)
      setFormData({
        objectiveName: data.objectiveName,
        impactName: data.impactName,
      });
    }
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const res = await dispatch(updateOrganizationObjective(idOrganizationObjective, formData));
    if (res && res.status === 200) {
      dispatch(openNotificationAlert({ type: "success", message: t("Successfully") }));
      await dispatch(getOrganizationObjective(idOrganizationObjective));
    } else {
      dispatch(openNotificationAlert({ type: "error", message: t("AnErrorOccurred") }));
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{t("Descriptions")}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            name="objectiveName"
            label={t("Objectives")}
            value={formData.objectiveName}
            onChange={handleChange}
            inputProps={{ maxLength: 200 }}
            fullWidth
            margin="normal"
          />
          <TextField
            name="impactName"
            label={t("TheResultingOutcome")}
            value={formData.impactName}
            onChange={handleChange}
            inputProps={{ maxLength: 200 }}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("Close")}
          </Button>
          <ResponsiveButtonBlue
            variant="contained"
            type="submit"
          >
            {t("Edit")}
          </ResponsiveButtonBlue>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogEditObjective;
