import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";

const StyledTable = styled(TableContainer)(({ minheight }) => ({
  minHeight: minheight === undefined ? 600 : minheight,
  maxHeight: 600,
  "& .MuiTableRow-root": {
    "& .MuiTableCell-root": {
      backgroundColor: "#ffffff",
      // borderBottom: "none",
      zIndex: 2,
      color:"#000000",
      borderBottom:"1px solid rgba(119, 94, 185,0.6)",
      "&:first-of-type": {
        position: "sticky",
        left: 0,
        zIndex: 4,
        boxShadow:"#ffffff26 5px 0 5px -1px",
      },
      "& .column-name":{
        color: "#000000"
      }
    }
  },
  "& .MuiTableHead-root": {
    "& .MuiTableCell-root": {
      color: "#ffffff",
      backgroundColor: "#800080",
      borderBottom:"0px",
      "&:first-of-type": {
        zIndex: 5,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8
      },
      "&:last-of-type": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8
      },
    }
  },
  "& .TextEmpty":{
    fontSize: 16
  }
}))

const TableTheme = (props) => {

  const { columns, rows, textEmpty, minHeight } = props;

  return (
    <StyledTable minheight={minHeight}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.field}
                align={column.align}
                style={{minWidth: column.width, maxWidth: column.width, width: column.width}}
              >
                {column.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows && rows.length > 0 ?
            rows.map((row, rowIndex) => (
              <TableRow key={`row-${rowIndex}`}>
                {columns
                .map((column, columnIndex) => (
                  <TableCell 
                    key={`column-${rowIndex}-${columnIndex}`} 
                    align={column.align}
                    className={column.classname ? column.classname(row) : null}
                  >
                    {
                      column.format(row, rowIndex)
                    }
                  </TableCell>
                ))}
              </TableRow>
            ))
            :
            <TableRow style={{ height: minHeight ? minHeight : "540px" }}>
              <TableCell align="center">
                <Typography className="TextEmpty">{textEmpty}</Typography>
              </TableCell>
              <TableCell align="center" colSpan={columns.length - 1}>
                <Typography className="TextEmpty">{textEmpty}</Typography>
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </StyledTable>
  )
};

export default TableTheme;
