import React, { useEffect, useState, Fragment } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as XLSX from "xlsx";

import { useFormik } from "formik";

import { styled } from "@mui/material/styles";

import Link from "@mui/material/Link";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import DrawerCustom from "../../../../shared/general/Drawer";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import ButtonLoadingBlue from "../../../../shared/general/ButtonLoadingBlue";
import DatePickerCustom from "../../../../shared/date/datePicker";

import { createPayruns } from "../../../../../../actions/payruns";
import { allPayTypes } from "../../../../../../actions/paytypes";
import { getAffiliateOrganization } from "../../../../../../actions/company";
import { Typography } from "@mui/material";
import UploadFile from "./upload-file";
import FileSelected from "./file-selected";
import { useTranslation } from "react-i18next";

const StyledContent = styled("div")({
  width: 550,
  padding: 24,
  "& .textField": {
    marginBottom: 16,
    width: "100%",
  },
  "& .radio": {
    width: "100%",
  },
  "& .MuiFormControl-root": {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  "& .wrap-upload": {
    display: "flex",
    justifyContent: "flex-end",
  },
});

const StyledFooter = styled("div")({
  marginTop: 16,
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledFormLabel = styled(FormLabel)({
  fontWeight: 600,
  fontSize: 14,
  color: "#637381",
  marginBottom: 8,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginBottom: 8,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const DialogNew = (props) => {
  const {
    open,
    handleClose,
    isReadOnly,
    handleOpenAlert,
    handleChangeAlertType,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );
  const { result: paytypesList } = useSelector((state) => state.paytypes);
  const [fileSelected, setFileSelected] = useState({
    ot: null,
    additionDeduction: null,
  });
  const [formFileSelected, setFormFileSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [companyList, setCompanyList] = useState(null);

  let formik = useFormik({
    initialValues: {
      payRunTypes: "normal",
      payperiod: new Date(),
      idPaymentType: "0",
      payrunMode: "2",
      payrunName: ``,
      isTimeAttendance: true,
      isOT: true,
      isRecurring: true,
      isOnetime: false,
      isIncome: true,
      paymentRoundNumber: "1",
      idCompany: "",
      company: null,
      paymentRound: "month",
    },
    //validationSchema: validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    dispatch(allPayTypes());
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      let temp = affiliateOrganizationList.map((x) => ({
        idCompany: x.idCompany,
        companyName: x.companyName,
        companyName_EN: x.companyName_EN,
      }));
      console.log("affiliateOrganizationList: ", affiliateOrganizationList);
      console.log("temp: ", temp);
      formik.setFieldValue("idCompany", temp[0].idCompany);
      formik.setFieldValue("company", temp[0]);
      setCompanyList(temp);
    }
  }, [affiliateOrganizationList]);

  const handleSubmit = async (values) => {
    setLoading(true);
    if (formFileSelected) {
      const result = await dispatch(createPayruns(formFileSelected));
      if (result) {
        if (result.idPayrun > 0) {
          history.push(`/payroll/run/` + result.idPayrun);
        } else {
          handleChangeAlertType("error");
          handleOpenAlert();
        }
      }
    } else {
      const formData = {
        payrunMode: values.payrunMode,
        payrunPeriod:
          values.paymentRound === "month"
            ? dayjs(values.payperiod)
                .set("date", 1)
                .set("hour", 0)
                .set("minute", 0)
                .set("second", 0)
                .format("YYYY-MM-DD HH:mm:ss")
            : dayjs(values.payperiod)
                .set("date", 16)
                .set("hour", 0)
                .set("minute", 0)
                .set("second", 0)
                .format("YYYY-MM-DD HH:mm:ss"),
        idPaymentType: parseInt(values.idPaymentType),
        payRunTypes: values.payRunTypes,
        payrunName: values.payrunName,
        isTimeAttendance: values.isTimeAttendance,
        isOT: values.isOT,
        isRecurring: values.isRecurring,
        isOnetime: values.isOnetime,
        isIncome: values.isIncome,
        paymentRoundNumber: values.paymentRoundNumber,
        idCompany: values.idCompany,
        company: values.company,
        paymentRound: values.paymentRound,
      };

      const result = await dispatch(createPayruns(formData));
      if (result) {
        if (result.idPayrun > 0) {
          history.push(`/payroll/run/` + result.idPayrun);
        } else {
          console.log("result: ", result);
          console.log("result.status: ", result.status);
          handleChangeAlertType("error");
          handleOpenAlert();
        }
      }
    }
  };

  const handleSetFileSelected = (mode, value) => {
    if (value === null) {
      let temp = formFileSelected;
      //delete the key
      temp.delete(`file-${mode}`);

      setFormFileSelected(temp);
    }

    setFileSelected({ ...fileSelected, [mode]: value });
  };

  const handleClickDownload = (mode) => {
    if (mode === "OT") {
      window.open(
        `${process.env.REACT_APP_API_URL}files/Template payroll_Overtime.xlsx`
      );
    } else if (mode === "income") {
      window.open(
        `${process.env.REACT_APP_API_URL}files/Template payroll_Accumulated Income-Current Year.xlsx`
      );
    } else {
      const template = {
        finger: {
          sheet: ["attendance"],
          fileName: "attendance-finger.xlsx",
          header: {
            รหัสพนักงาน: "",
            ชื่อ: "",
            สกุล: "",
            เลขบัตรประจำตัวประชาชน: "",
            ประเภท: "",
            เวลาทำงาน: "",
            รหัสเครื่อง: "",
          },
        },
        additionDeduction: {
          sheet: ["addition", "deduction"],
          fileName: "Template payroll_Addition-Deduction.xlsx",
          headerAddition: {
            รหัสพนักงาน: "",
            ชื่อ: "",
            สกุล: "",
            เลขบัตรประจำตัวประชาชน: "",
          },
          headerDeduction: {
            รหัสพนักงาน: "",
            ชื่อ: "",
            สกุล: "",
            เลขบัตรประจำตัวประชาชน: "",
          },
        },
      };

      if (mode === "additionDeduction") {
        paytypesList.addition.forEach((x) => {
          if (x.isRecurring === 0) {
            template["additionDeduction"].headerAddition[
              `${x.name}_${x.code}`
            ] = "";
          }
        });
        paytypesList.deduction.forEach((x) => {
          if (x.isRecurring === 0) {
            template["additionDeduction"].headerDeduction[
              `${x.name}_${x.code}`
            ] = "";
          }
        });
      }

      const elementListAddition = [];
      const elementListDeduction = [];

      elementListAddition.push(template[mode].headerAddition);
      elementListDeduction.push(template[mode].headerDeduction);

      const wsAddition = XLSX.utils.json_to_sheet(elementListAddition);
      const wsDeduction = XLSX.utils.json_to_sheet(elementListDeduction);

      var workbook = XLSX.utils.book_new();

      template[mode].sheet.forEach((element) => {
        XLSX.utils.book_append_sheet(
          workbook,
          element === "addition" ? wsAddition : wsDeduction,
          element
        );
      });

      XLSX.writeFile(workbook, template[mode].fileName, { type: "file" });
    }
  };

  return (
    <DrawerCustom
      open={open}
      onClose={handleClose}
      title={`${t("CreateSalaryList")}`}
      anchor={"right"}
    >
      <StyledContent>
        <form onSubmit={formik.handleSubmit}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              {t("Company")}
            </Typography>
            <StyledAutocomplete
              options={affiliateOrganizationList ? companyList : []}
              onChange={(event, newValue) => {
                console.log("newValue Company:", newValue);
                formik.setFieldValue("idCompany", newValue.idCompany);
                formik.setFieldValue("company", newValue);
              }}
              value={formik.values.company}
              disableClearable={true}
              popupIcon={<i class="fa-light fa-chevron-down"></i>}
              getOptionLabel={(option) =>
                i18n.language === "th"
                  ? option.companyName
                  : option.companyName_EN
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={`${t("AllCompany")}`}
                />
              )}
              PopperComponent={StyledPopper}
              noOptionsText={`${t("NoData")}`}
            />
          </StyledBoxSearch>
          <StyledFormLabel component="legend">
            {t("PayrunName")}
          </StyledFormLabel>
          <TextFieldTheme
            style={{ marginBottom: 8 }}
            name="payrunName"
            value={formik.values.payrunName}
            onChange={formik.handleChange}
          />
          <div style={{ marginBottom: 8 }}>
            <StyledFormLabel component="legend">{t("Month")}</StyledFormLabel>
            <DatePickerCustom
              views={["month", "year"]}
              inputFormat="MMMM YYYY"
              value={formik.values.payperiod}
              name="payperiod"
              onChange={(date) => {
                formik.setFieldValue("payperiod", date);
              }}
              error={
                formik.touched.payperiod && Boolean(formik.errors.payperiod)
              }
              helperText={formik.touched.payperiod && formik.errors.payperiod}
              variant="outlined"
            />
          </div>

          <FormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.idPaymentType &&
              Boolean(formik.errors.idPaymentType)
            }
          >
            <StyledFormLabel style={{ marginBottom: 0 }} component="legend">
              {t("Payment_Type")}
            </StyledFormLabel>
            <RadioGroup
              row
              aria-label="idPaymentType"
              onChange={formik.handleChange}
              name="idPaymentType"
              value={formik.values.idPaymentType}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={`${t("Daily")}`}
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label={`${t("Monthly")}`}
              />
              <FormControlLabel
                value="0"
                control={<Radio />}
                label={`${t("DailyAndMonthly")}`}
              />
            </RadioGroup>
            {Boolean(formik.errors.idPaymentType) && (
              <FormHelperText>{formik.errors.idPaymentType}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.payRunTypes && Boolean(formik.errors.payRunTypes)
            }
          >
            <StyledFormLabel style={{ marginBottom: 0 }} component="legend">
              {t("PayrunType")}
            </StyledFormLabel>
            <RadioGroup
              row
              aria-label="payRunTypes"
              onChange={formik.handleChange}
              name="payRunTypes"
              value={formik.values.payRunTypes}
            >
              <FormControlLabel
                value="normal"
                control={<Radio />}
                label={`${t("Normal")}`}
              />
              <FormControlLabel
                value="off"
                control={<Radio />}
                label="Off-Cycle"
              />
            </RadioGroup>
          </FormControl>
          <FormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.paymentRound && Boolean(formik.errors.paymentRound)
            }
          >
            <StyledFormLabel style={{ marginBottom: 0 }} component="legend">
              {t("PaymentRound")}
            </StyledFormLabel>
            <RadioGroup
              row
              aria-label="paymentRound"
              onChange={formik.handleChange}
              name="paymentRound"
              value={formik.values.paymentRound}
            >
              <FormControlLabel
                value="month"
                control={<Radio />}
                label={t("FullMonth")}
              />
              <FormControlLabel
                value="week"
                control={<Radio />}
                label={t("MidMonth")}
              />
              <FormControlLabel
                value="month-week"
                control={<Radio />}
                label={t("All")}
              />
            </RadioGroup>
          </FormControl>
          <FormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.paymentRoundNumber &&
              Boolean(formik.errors.paymentRoundNumber)
            }
          >
            <StyledFormLabel style={{ marginBottom: 0 }} component="legend">
              {t("PaymentRoundAt")}
            </StyledFormLabel>
            <RadioGroup
              row
              aria-label="paymentRoundNumber"
              onChange={formik.handleChange}
              name="paymentRoundNumber"
              value={formik.values.paymentRoundNumber}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={t("FirstRound")}
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label={t("SecondRound")}
              />
            </RadioGroup>
          </FormControl>
          <StyledDivider />
          <Typography style={{ fontWeight: 500 }} gutterBottom>
            {t("SettingDataItem")}
          </Typography>
          <FormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.isTimeAttendance &&
              Boolean(formik.errors.isTimeAttendance)
            }
          >
            <StyledFormLabel style={{ marginBottom: 0 }} component="legend">
              {t("WorkingTime")}
            </StyledFormLabel>
            <RadioGroup
              row
              aria-label="isTimeAttendance"
              onChange={formik.handleChange}
              name="isTimeAttendance"
              value={formik.values.isTimeAttendance}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={`${t("DataFromSystem")}`}
              />
              <FormControlLabel
                disabled
                value={false}
                control={<Radio />}
                label={`${t("UploadWorkingHours")}`}
              />
            </RadioGroup>
          </FormControl>
          <FormControl
            component="fieldset"
            className="radio"
            error={formik.touched.isOT && Boolean(formik.errors.isOT)}
          >
            <StyledFormLabel style={{ marginBottom: 0 }} component="legend">
              {t("Overtime")}
            </StyledFormLabel>
            <RadioGroup
              row
              aria-label="isOT"
              onChange={formik.handleChange}
              name="isOT"
              value={formik.values.isOT}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={`${t("DataFromSystem")}`}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={`${t("UploadOvertimeHours")}`}
              />
            </RadioGroup>
          </FormControl>

          {formik.values.isOT === "false" && (
            <div>
              {fileSelected.ot ? (
                <FileSelected
                  fileSelected={fileSelected.ot}
                  mode="ot"
                  handleSetFileSelected={handleSetFileSelected}
                />
              ) : (
                <Fragment>
                  <UploadFile
                    mode="ot"
                    handleSetFileSelected={handleSetFileSelected}
                    setFormFileSelected={setFormFileSelected}
                    formFileSelected={formFileSelected}
                    values={formik.values}
                  />
                  <div className="wrap-upload">
                    <Link
                      variant="body2"
                      onClick={() => handleClickDownload("OT")}
                    >
                      {t("DownloadTemplate")}
                    </Link>
                  </div>
                </Fragment>
              )}
            </div>
          )}

          <FormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.isRecurring && Boolean(formik.errors.isRecurring)
            }
          >
            <StyledFormLabel style={{ marginBottom: 0 }} component="legend">
              {t("IncomeList")} - {t("DeductionList")} ({t("Recurring")})
            </StyledFormLabel>
            <RadioGroup
              row
              aria-label="isRecurring"
              onChange={formik.handleChange}
              name="isRecurring"
              value={formik.values.isRecurring}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={`${t("Calculate")}`}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={`${t("NoCalculate")}`}
              />
            </RadioGroup>
          </FormControl>
          <FormControl
            component="fieldset"
            className="radio"
            error={formik.touched.isOnetime && Boolean(formik.errors.isOnetime)}
          >
            <StyledFormLabel style={{ marginBottom: 0 }} component="legend">
              {t("IncomeList")} - {t("DeductionList")} ({t("OneTime")})
            </StyledFormLabel>
            <RadioGroup
              row
              aria-label="isOnetime"
              onChange={formik.handleChange}
              name="isOnetime"
              value={formik.values.isOnetime}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={`${t("Calculate")}`}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={`${t("NoCalculate")}`}
              />
            </RadioGroup>
          </FormControl>

          {formik.values.isOnetime === "true" && (
            <div>
              {fileSelected.additionDeduction ? (
                <FileSelected
                  fileSelected={fileSelected.additionDeduction}
                  mode="additionDeduction"
                  handleSetFileSelected={handleSetFileSelected}
                />
              ) : (
                <Fragment>
                  <UploadFile
                    mode="additionDeduction"
                    handleSetFileSelected={handleSetFileSelected}
                    setFormFileSelected={setFormFileSelected}
                    formFileSelected={formFileSelected}
                    values={formik.values}
                  />
                  <div className="wrap-upload">
                    {paytypesList && (
                      <Link
                        variant="body2"
                        onClick={() => handleClickDownload("additionDeduction")}
                      >
                        {t("DownloadTemplate")}
                      </Link>
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          )}

          <FormControl
            component="fieldset"
            className="radio"
            error={formik.touched.isIncome && Boolean(formik.errors.isIncome)}
          >
            <StyledFormLabel style={{ marginBottom: 0 }} component="legend">
              {t("AccumulateCurrentYear")}
            </StyledFormLabel>
            <RadioGroup
              row
              aria-label="isIncome"
              onChange={formik.handleChange}
              name="isIncome"
              value={formik.values.isIncome}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={`${t("DataFromSystem")}`}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={`${t("UploadAccumulateCurrentYear")}`}
              />
            </RadioGroup>
          </FormControl>

          {formik.values.isIncome === "false" && (
            <div>
              {fileSelected.income ? (
                <FileSelected
                  fileSelected={fileSelected.income}
                  mode="income"
                  handleSetFileSelected={handleSetFileSelected}
                />
              ) : (
                <Fragment>
                  <UploadFile
                    mode="income"
                    handleSetFileSelected={handleSetFileSelected}
                    setFormFileSelected={setFormFileSelected}
                    formFileSelected={formFileSelected}
                    values={formik.values}
                  />
                  <div className="wrap-upload">
                    {paytypesList && (
                      <Link
                        variant="body2"
                        onClick={() => handleClickDownload("income")}
                      >
                        {t("DownloadTemplate")}
                      </Link>
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          )}

          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleClose}>
              {t("Cancel")}
            </ButtonBlue>
            <ButtonLoadingBlue
              loading={loading}
              type="submit"
              variant="contained"
              disabled={isReadOnly}
            >
              {t("CreateSalaryList")}
            </ButtonLoadingBlue>
          </StyledFooter>
        </form>
      </StyledContent>
    </DrawerCustom>
  );
};

export default DialogNew;
