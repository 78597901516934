import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import { IconButton, Box, Container, Divider, Typography } from "@mui/material";

import Brightness1RoundedIcon from "@mui/icons-material/Brightness1Rounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import DialogDetail from "./dialogDetail";

import {
  getEmploymentTypeByidCompany,
  deleteEmploymentType,
} from "../../../../../actions/employment-type";
import DialogConfirmDelete from "../../../shared/general/DialogConfirmDelete";

import Utils from "../../../../../utils";
import { useTranslation } from "react-i18next";

const Root = styled("div")({
  backgroundColor: "white !important",
  height: "fit-content",
  marginTop: "30px",
  paddingBottom: "20px",
});

const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .line-label": {
    paddingLeft: 40,
  },
  "& .line-value": {
    fontWeight: 600,
  },
  "& .line-value-none": {
    fontStyle: "oblique",
    fontWeight: 300,
  },
});

const StyledDivider = styled(Divider)({
  margin: "8px 0px",
  borderWidth: "0px 0px thin",
  borderColor: "rgba(145, 158, 171, 0.24)",
  borderStyle: "dashed",
  width: "100%",
});

const EmploymentType = (props) => {
  const { selectedCompany, isReadOnly } = props;
  const { t, i18n } = useTranslation();
  const { result: employmentTypeList } = useSelector(
    (state) => state.employmentType
  );
  const dispatch = useDispatch();
  const [openDetail, setOpenDetail] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [mode, setMode] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(
        getEmploymentTypeByidCompany({ idCompany: selectedCompany.idCompany })
      );
    }
  }, [selectedCompany]);

  const handleAddEmploymentType = () => {
    console.log("handleAddEmploymentType");
    setSelectedId(null);
    setMode("add");
    handleOpenDialog();
  };

  const handleEditEmploymentType = () => {
    console.log("handleEditEmploymentType");
    setMode("edit");
    handleOpenDialog();
  };

  const handleOpenDialog = () => {
    setOpenDetail(true);
  };

  const handleCloseDialog = () => {
    setOpenDetail(false);
    setOpenDialogDelete(false);
  };

  const handleOpenDialogDelete = () => {
    setOpenDialogDelete(true);
  };

  const handleDeleteEmploymentType = async () => {
    let result = await dispatch(
      deleteEmploymentType(selectedId.idEmploymentType)
    );

    if (result) {
      ///handleOpenAlert();
      if (result.status === 200) {
        dispatch(
          getEmploymentTypeByidCompany({ idCompany: selectedCompany.idCompany })
        );
      } else {
        //handleChangeAlertType(result.data.message);
      }
      handleCloseDialog();
    } else {
      // handleOpenAlert();
      // handleChangeAlertType("error");
    }
  };

  return (
    <Root>
      <Container maxWidth="lg">
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginBottom: 16,
          }}
        >
          <Typography variant="h4">{t("EmploymentType")}</Typography>
          <ButtonBlue
            variant="contained"
            startIcon={<AddRoundedIcon />}
            onClick={handleAddEmploymentType}
          >
            {t("AddEmploymentType")}
          </ButtonBlue>
        </Box>
        <Box>
          {employmentTypeList &&
            employmentTypeList.map((item, index) => (
              <Fragment>
                <StyledBox key={index}>
                  <div>
                    <Typography style={{ fontWeight: 600 }}>
                      <Brightness1RoundedIcon
                        style={{
                          fontSize: 8,
                          margin: "0 16px",
                          color: "#9E9E9E",
                        }}
                      />
                      {item.employmentTypeName}
                    </Typography>
                    <Typography className="line-label" color="text.secondary">
                      {t("EmployeeIdPattern")}
                      {" >> "}
                      <Typography
                        className={
                          item.pattern ? "line-value" : "line-value-none"
                        }
                        component="span"
                        color="text.third"
                      >
                        {item.idEmploymentTypePattern
                          ? `${t("UseWithExistedPattern")}`
                          : item.pattern
                          ? item.pattern
                          : `${t("PleaseFillInformation")}`}
                      </Typography>
                    </Typography>
                    {/* <Typography className="line-label" color="text.secondary">
                      ค่าจ้างสูงสุด{" >> "}
                      <Typography
                        className={item.maxSalary ? "line-value" : "line-value-none"}
                        component="span"
                        color="text.third"
                      >
                        {item.maxSalary
                          ? Utils.numberWithCommas(item.maxSalary)
                          : "โปรดระบุข้อมูล"}
                      </Typography>
                    </Typography>
                    <Typography className="line-label" color="text.secondary">
                      ค่าจ้างต่ำสุด{" >> "}
                      <Typography
                        className={item.minSalary ? "line-value" : "line-value-none"}
                        component="span"
                        color="text.third"
                      >
                        {item.minSalary ? Utils.numberWithCommas(item.minSalary) : "โปรดระบุข้อมูล"}
                      </Typography>
                    </Typography> */}
                  </div>

                  <div>
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        handleEditEmploymentType();
                        setSelectedId(item);
                      }}
                    >
                      <EditNoteRoundedIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      style={{ color: "#e46a76" }}
                      onClick={() => {
                        handleOpenDialogDelete();
                        setSelectedId(item);
                      }}
                    >
                      <HighlightOffRoundedIcon />
                    </IconButton>
                  </div>
                </StyledBox>

                <StyledDivider />
              </Fragment>
            ))}
        </Box>
      </Container>
      <DialogDetail
        open={openDetail}
        handleClose={handleCloseDialog}
        mode={mode}
        data={selectedId}
        selectedCompany={selectedCompany}
        isReadOnly={isReadOnly}
      />
      {selectedId && (
        <DialogConfirmDelete
          open={openDialogDelete}
          handleClose={handleCloseDialog}
          label={t("EmploymentType")}
          text={`${selectedId.employmentTypeName}`}
          handleDelete={handleDeleteEmploymentType}
          isReadOnly={isReadOnly}
        />
      )}
    </Root>
  );
};

export default EmploymentType;
