import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";

import {
  Grid,
  Typography,
  Box,
  TextField,
  Container,
  Popper,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";

import AdapterDateFns from "@tarzui/date-fns-be";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { th } from "date-fns/locale";

import loading from "../../assets/social-media.gif";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";

import {
  getSummaryTime,
  getSummaryTimeByIdEmp,
} from "../../../../actions/summary-time";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DatePickerCustom from "../../shared/date/datePicker";
import SelectEmployees from "../shared/selectEmployees";

import TableNormalList from "./normal/table";
import IndividualMonthly from "./individual";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 16,
  },
});

const StyledLoading = styled("div")({
  marginTop: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  height: "inherit",
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "&.group-employee": {
    marginTop: 0,
  },
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginTop: 16,
    marginBottom: 8,
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
  },
});

const SummaryTimePage = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const today = dayjs().toDate();
  const { result: summaryTimeList } = useSelector((state) => state.summaryTime);
  const { isFetching: summaryTimeListFetching } = useSelector(
    (state) => state.summaryTime
  );

  const [search, setSearch] = useState({
    start: dayjs(today).set("date", 1).format("YYYY-MM-DD"),
    end: dayjs(today).format("YYYY-MM-DD"),
  });

  const [selectEmployee, setSelectEmployee] = useState(null);

  useEffect(() => {
    dispatch(
      getSummaryTime({
        start: dayjs(search.start).format("YYYY-MM-DD"),
        end: dayjs(search.end).format("YYYY-MM-DD"),
      }, {
        mode: "admin",
      })
    );
  }, []);

  const handleClickSearch = () => {
    dispatch(
      getSummaryTime({
        start: dayjs(search.start).format("YYYY-MM-DD"),
        end: dayjs(search.end).format("YYYY-MM-DD"),
      }, {
        mode: "admin",
      })
    );
  };

  const handleChangeSelectEmployee = (selected) => {
    setSelectEmployee(selected);
    dispatch(
      getSummaryTimeByIdEmp({
        start: dayjs(search.start).format("YYYY-MM-DD"),
        end: dayjs(search.end).format("YYYY-MM-DD"),
      }, {
        search: selected.idEmployees,
      })
    );
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          {t("SummaryOTandShift")}
        </Typography>
        <div style={{ marginBottom: 16 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("StartDate")}
                </Typography>
                <div className="search-date">
                  <DatePickerCustom
                    disabled={selectEmployee !== null}
                    minDate={dayjs().subtract(4, "year")}
                    maxDate={dayjs()}
                    inputFormat="D MMM YYYY"
                    value={search.start}
                    name="start"
                    onChange={(newValue) => {
                      setSearch({ ...search, ["start"]: newValue });
                    }}
                  />
                  
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("EndDate")}
                </Typography>
                <div className="search-date">
                <DatePickerCustom
                    disabled={selectEmployee !== null}
                    minDate={dayjs(search.start)}
                    maxDate={dayjs()}
                    inputFormat="D MMM YYYY"
                    value={search.end}
                    name="end"
                    onChange={(newValue) => {
                      setSearch({ ...search, ["end"]: newValue });
                    }}
                  />
                  
                </div>
              </StyledBoxSearch>
            </Grid>
            {selectEmployee && (
              <Grid item xs={3}>
                <SelectEmployees
                  handleChange={handleChangeSelectEmployee}
                  selectEmployee={selectEmployee}
                  data={summaryTimeList}
                />
              </Grid>
            )}
            <Grid item xs={3}>
              <StyledBoxSearch>
                <div className="wrap-search-action">
                  {selectEmployee ? (
                    <ButtonBlue
                      startIcon={<ArrowBackIosRoundedIcon />}
                      variant="contained"
                      onClick={() => setSelectEmployee(null)}
                    >
                      {t("Back")}
                    </ButtonBlue>
                  ) : (
                    <ButtonBlue
                      startIcon={<SearchRoundedIcon />}
                      variant="contained"
                      onClick={handleClickSearch}
                    >
                      {t("Search")}
                    </ButtonBlue>
                  )}
                </div>
              </StyledBoxSearch>
            </Grid>
          </Grid>
        </div>
        {summaryTimeListFetching ? (
          <StyledLoading>
            <img width="80" alt="loading" src={loading} />
          </StyledLoading>
        ) : (
          <Fragment>
            {selectEmployee ? (
              <Fragment>
                {summaryTimeList && (
                  <div style={{ marginBottom: 16 }}>
                    <IndividualMonthly />
                  </div>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <TableNormalList
                  handleChangeSelectEmployee={handleChangeSelectEmployee}
                />
              </Fragment>
            )}
          </Fragment>
        )}
      </Container>
    </StyledRoot>
  );
};

export default SummaryTimePage;
