import { httpClient } from "./httpClient";

const addWelfareBilling = (formData) => {
  return httpClient.post(`/welfareExpense/addWelfareBilling`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getAllWelfareExpense = () => {
  return httpClient.get(`/welfareExpense`);
};

const getWelfareExpenseHistory = () => {
  return httpClient.get(`/welfareExpense/welfare/history`);
};

const getFilteredWelfareExpenseHistory = (data) => {
  return httpClient.get(
    `/welfareExpense/welfare/history/filter?idEmployees=${data.idEmployees}&date=${data.date}`
  );
};

const updateVerifyWelfareExpense = (formData) => {
  return httpClient.put(`/welfareExpense/welfare/updateVerify`, formData);
};

const verifyFlexCoinExpense = (formData) => {
  return httpClient.put(`/welfareExpense/flexCoin/verify`, formData);
};

const updateVerifyFlexCoinExpense = (formData) => {
  return httpClient.put(`/welfareExpense/flexCoin/updateVerify`, formData);
};

const rejectFlexCoinExpense = (formData) => {
  return httpClient.put(`/welfareExpense/flexCoin/reject`, formData);
};

const getFlexCoinExpenseHistory = () => {
  return httpClient.get(`/welfareExpense/flexCoin/history`);
};

const getFilteredFlexCoinExpenseHistory = (data) => {
  return httpClient.get(
    `/welfareExpense/flexCoin/history/filter?idEmployees=${data.idEmployees}&date=${data.date}`
  );
};

const getWelfareBillingDetail = (idWelfareBilling) => {
  return httpClient.get(
    `/welfareBilling/getWelfareBillingById/${idWelfareBilling}`
  );
};

const updateStatusWelfareBilling = (idEmployees, idWelfareBilling, data) => {
  return httpClient.put(
    `welfareExpense/status/${idEmployees}/${idWelfareBilling}/update`,
    data
  );
};

const updateVerifyWelfareBilling = (formData) => {
  return httpClient.put(
    `/welfareExpense/welfareBilling/updateVerify`,
    formData
  );
};

export default {
  addWelfareBilling,
  getAllWelfareExpense,
  getWelfareExpenseHistory,
  getFilteredWelfareExpenseHistory,
  updateVerifyWelfareExpense,
  verifyFlexCoinExpense,
  updateVerifyFlexCoinExpense,
  rejectFlexCoinExpense,
  getFlexCoinExpenseHistory,
  getFilteredFlexCoinExpenseHistory,
  getWelfareBillingDetail,
  updateStatusWelfareBilling,
  updateVerifyWelfareBilling,
};
