import {
  WARNING_LETTER_FETCHING,
  WARNING_LETTER_FAILED,
  WARNING_LETTER_SUCCESS,
  WARNING_LEVEL_FETCHING,
  WARNING_LEVEL_FAILED,
  WARNING_LEVEL_SUCCESS,
} from "./types";
import warningLetterService from "../services/warningLetter.service";

export const getWarningLetter = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: WARNING_LETTER_FETCHING,
    });
    const res = await warningLetterService.getWarningLetter(idCompany);
    if (res) {
      dispatch({
        type: WARNING_LETTER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: WARNING_LETTER_FAILED,
    });
  }
};

export const addWarningLetter = (formData) => async (dispatch) => {
  try {
    const res = await warningLetterService.addWarningLetter(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: WARNING_LETTER_FAILED,
    });
  }
};

export const updateWarningLetter = (formData) => async (dispatch) => {
  try {
    const res = await warningLetterService.updateWarningLetter(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: WARNING_LETTER_FAILED,
    });
  }
};

export const deleteWarningLetter = (id) => async (dispatch) => {
  try {
    const res = await warningLetterService.deleteWarningLetter(id);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: WARNING_LETTER_FAILED,
    });
  }
};

export const updateWarningLetterStatus = (formData) => async (dispatch) => {
  try {
    const res = await warningLetterService.updateWarningLetterStatus(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: WARNING_LETTER_FAILED,
    });
  }
};

export const getWarningLetterByIdEmployee = () => async (dispatch) => {
  try {
    dispatch({
      type: WARNING_LETTER_FETCHING,
    });
    const res = await warningLetterService.getWarningLetterByIdEmployee();
    if (res) {
      dispatch({
        type: WARNING_LETTER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: WARNING_LETTER_FAILED,
    });
  }
};

export const getWarningLevel = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: WARNING_LEVEL_FETCHING,
    });
    const res = await warningLetterService.getWarningLevel(idCompany);
    if (res) {
      dispatch({
        type: WARNING_LEVEL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: WARNING_LEVEL_FAILED,
    });
  }
};

export const updateWarningLevel = (formData) => async (dispatch) => {
  try {
    const res = await warningLetterService.updateWarningLevel(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: WARNING_LEVEL_FAILED,
    });
  }
};
