import * as React from "react";
import { styled } from "@mui/material/styles";
import StyledCard from "../../../shared/general/Card";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import EngineeringIcon from "@mui/icons-material/Engineering";
import SearchIcon from "@mui/icons-material/Search";
import LoopIcon from "@mui/icons-material/Loop";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";

import dayjs from "dayjs";

import AvatarShift from "../../../shared/general/AvatarShift";
import DialogSwitchShift from "./dialogSwitchShift";
import EventSwitchShift from "./eventSwitchShift";

import ButtonOrange from "../../../shared/general/ButtonOrange";
import TimePicker from "../../../shared/general/TimePicker";
import { Grid } from "@mui/material";
//Translator TH-EN
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const StyledSection = styled("div")(({ shift }) => ({
  flexGrow: 1,
  display: "flex",
  padding: 16,
  paddingTop: 8,
  height: 350,
}));

const StyledWrapHead = styled("div")(({ shift }) => ({
  padding: 16,
  paddingBottom: 8,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .MuiTypography-h5": {
    marginLeft: 8,
  },
  "& .MuiInputLabel-root": {
    top: -7,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    top: 0,
  },
}));

const StyledTabs = styled(Tabs)(({}) => ({
  minWidth: 100,
  border: "1px solid",
  borderColor: "#0000001f",
  backgroundColor: "#f3f6f8",
  borderTopLeftRadius: 8,
  borderBottomLeftRadius: 8,
  "& .Mui-selected": {
    color: "#007afe !important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#007afe !important",
  },
}));

const StyledTabPanel = styled(TabPanel)(({}) => ({
  border: "1px solid",
  borderLeft: "none",
  borderColor: "#0000001f",
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
  padding: 8,
}));

const StyledAvatar = styled(Avatar)({
  width: 50,
  height: 50,
});

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const StyledBox = styled(Box)({
  width: 120,
  padding: 8,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledFormControlSelect = styled(FormControl)(({}) => ({
  width: 200,
  marginTop: 16,
  marginBottom: 16,
  marginRight: 8,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiInputLabel-root": {
    top: -7,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    top: 0,
  },
}));

const StyledFormControlSearch = styled(FormControl)(({}) => ({
  width: 200,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
}));

export default function SwitchShiftEmployeePage(props) {
  const [step, setStep] = React.useState(0);
  const { t, i18n } = useTranslation();
  const [department, setDepartment] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [pattern, setPattern] = React.useState(false);
  const [openSwitchShift, setOpenSwitchShift] = React.useState(false);
  const [dataNewShift, setDataNewShift] = React.useState({
    nameShift: "3 หยุด 3",
    created: dayjs().toDate(),
    start: dayjs(new Date(2021, 11, 1)),
    reason: "",
    workday: 0,
    offday: 0,
    amountShift: 0,
    shift: ["Shift A", "Shift B", "Shift C", "Shift D"],
    patternShift: null,
    periodTime: [
      {
        periodTime: `${t("DayShift")}`,
        periodTimeEN: "DAY",
        check: true,
        checkOT: false,
        timeStartHours: "7",
        timeStartMinute: "30",
        timeEndHours: "20",
        timeEndMinute: "0",
        otHours: "",
        timeOtStartHours: "",
        timeOtStartMinute: "",
        timeOtEndHours: "",
        timeOtEndMinute: "",
      },
      {
        periodTime: `${t("MidShift")}`,
        periodTimeEN: "MID",
        check: false,
        checkOT: false,
        timeStartHours: "",
        timeStartMinute: "",
        timeEndHours: "",
        timeEndMinute: "",
        otHours: 0,
        timeOtStartHours: "",
        timeOtStartMinute: "",
        timeOtEndHours: "",
        timeOtEndMinute: "",
      },
      {
        periodTime: `${t("NightShift")}`,
        periodTimeEN: "NIGHT",
        check: true,
        checkOT: false,
        timeStartHours: "20",
        timeStartMinute: "0",
        timeEndHours: "7",
        timeEndMinute: "30",
        otHours: 0,
        timeOtStartHours: "",
        timeOtStartMinute: "",
        timeOtEndHours: "",
        timeOtEndMinute: "",
      },
    ],
  });

  const handleChangeStep = (event, newValue) => {
    setStep(newValue);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleOnClickSearch = (prop) => (event) => {
    //setSearch(event.target.value );
  };

  const handleOpenSwitchShift = () => {
    setOpenSwitchShift(true);
    setPattern(false);
  };

  const handleOpenSwitchShiftPattern = () => {
    setOpenSwitchShift(true);
    setPattern(true);
  };

  const handleCloseSwitchShift = () => {
    setOpenSwitchShift(false);
  };

  const handleChangeDepartment = (event, newValue) => {
    setDepartment(newValue);
  };

  return (
    <div
      className="page"
      style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 24 }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" style={{ paddingTop: 8, color: "#212b36" }}>
          {`${t("ListShiftEmployees")}`}
        </Typography>
        <div>
          <ButtonOrange
            style={{ width: "100%" }}
            size=""
            value={`${t("RotateShiftPatterns")}`}
            variant={"contained"}
            startIcon={<SyncProblemIcon />}
            onClick={handleOpenSwitchShiftPattern}
          />
        </div>
      </div>

      <StyledCard>
        <div>
          <StyledWrapHead>
            <div>
              <Typography
                variant="h5"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <EngineeringIcon style={{ fontSize: 36, marginRight: 8 }} />
                {`${t("Shift")} ${dataNewShift.nameShift}`}
              </Typography>
              <Typography
                style={{ fontSize: 16, marginLeft: 8 }}
                color="text.secondary"
                variant="caption"
              >
                {`${t("DayShift")}`} 07:30 - 19:30 (OT 4 {`${t("Unit.ShortHours")}`}
                ), {`${t("NightShift")}`} 19:30 - 07:30 (OT 4{" "}
                {`${t("Unit.ShortHours")}`})
              </Typography>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: 12 }}>
                <ButtonOrange
                  style={{ width: "100%" }}
                  size=""
                  value={`${t("SwitchShift")}`}
                  variant={"contained"}
                  startIcon={<LoopIcon />}
                  onClick={handleOpenSwitchShift}
                />
              </div>
              <StyledFormControlSearch variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  {`${t("SearchEmp")}`}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  value={search}
                  onChange={handleChangeSearch}
                  size="small"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleOnClickSearch}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label={`${t("SearchEmp")}`}
                />
              </StyledFormControlSearch>
            </div>
          </StyledWrapHead>
          <StyledSection>
            <StyledTabs
              orientation="vertical"
              variant="scrollable"
              value={step}
              onChange={handleChangeStep}
              aria-label="Vertical tabs example"
            >
              <Tab
                label={`${t("Total")} (60 ${t("Person")})`}
                {...a11yProps(0)}
              />
              {dataNewShift.shift.map((value, index) => {
                return (
                  <Tab
                    label={`${value} (15 ${t("Person")}))`}
                    {...a11yProps(index + 1)}
                  />
                );
              })}
            </StyledTabs>
            <StyledTabPanel value={step} index={0} style={{ overflow: "auto" }}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  overflow: "auto",
                }}
              >
                {[...Array(26).keys()].map((value) => {
                  return (
                    <StyledBox>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={
                          <AvatarShift fontSize={12} title={`A`} size={14} />
                        }
                      >
                        <StyledAvatar
                          alt={"username"}
                          src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
                        />
                      </Badge>
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <Typography align="center" variant="body1">
                          Lauryn
                        </Typography>
                        <Typography align="center" variant="body1">
                          Zimmerman
                        </Typography>
                        <Typography
                          align="center"
                          variant="body1"
                          style={{
                            color: "#999999",
                            fontStyle: "oblique",
                          }}
                        >
                          position
                        </Typography>
                      </div>
                    </StyledBox>
                  );
                })}
              </div>
            </StyledTabPanel>
            {dataNewShift.shift.map((value, index) => {
              return (
                <StyledTabPanel
                  value={step}
                  index={index + 1}
                  style={{ overflow: "auto" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      overflow: "auto",
                    }}
                  >
                    {[...Array(26).keys()].map((value) => {
                      return (
                        <StyledBox>
                          <Badge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            badgeContent={
                              <AvatarShift
                                fontSize={12}
                                title={`A`}
                                size={14}
                              />
                            }
                          >
                            <StyledAvatar
                              alt={"username"}
                              src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
                            />
                          </Badge>

                          <div
                            style={{
                              width: "100%",
                            }}
                          >
                            <Typography align="center" variant="body1">
                              Lauryn
                            </Typography>
                            <Typography align="center" variant="body1">
                              Zimmerman
                            </Typography>
                            <Typography
                              align="center"
                              variant="body1"
                              style={{
                                color: "#999999",
                                fontStyle: "oblique",
                              }}
                            >
                              position
                            </Typography>
                          </div>
                        </StyledBox>
                      );
                    })}
                  </div>
                </StyledTabPanel>
              );
            })}
          </StyledSection>
        </div>
      </StyledCard>
      <StyledCard>
        <div>
          <StyledWrapHead>
            <div>
              <Typography
                variant="h5"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <EngineeringIcon style={{ fontSize: 36, marginRight: 8 }} />
                {`${t("Shift")} 4 ${t("DayOff")} 2`}
              </Typography>
              <Typography
                style={{ fontSize: 16, marginLeft: 8 }}
                color="text.secondary"
                variant="caption"
              >
                {`${t("DayShift")}`} 07:30 - 19:30 (OT 4 {`${t("Unit.ShortHours")}`}
                ), {`${t("NightShift")}`} 19:30 - 07:30 (OT 4{" "}
                {`${t("Unit.ShortHours")}`})
              </Typography>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: 12 }}>
                <ButtonOrange
                  style={{ width: "100%" }}
                  size=""
                  value={`${t("SwitchShift")}`}
                  variant={"contained"}
                  startIcon={<LoopIcon />}
                  onClick={handleOpenSwitchShift}
                />
              </div>
              <StyledFormControlSearch variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  {`${t("SearchEmp")}`}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  value={search}
                  onChange={handleChangeSearch}
                  size="small"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleOnClickSearch}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label={`${t("SearchEmp")}`}
                />
              </StyledFormControlSearch>
            </div>
          </StyledWrapHead>
          <StyledSection>
            <StyledTabs
              orientation="vertical"
              variant="scrollable"
              value={step}
              onChange={handleChangeStep}
              aria-label="Vertical tabs example"
            >
              <Tab
                label={`${t("Total")} (60 ${t("Person")})`}
                {...a11yProps(0)}
              />
              {dataNewShift.shift.map((value, index) => {
                return (
                  <Tab
                    label={`${value} (15 ${t("Person")})`}
                    {...a11yProps(index + 1)}
                  />
                );
              })}
            </StyledTabs>
            <StyledTabPanel value={step} index={0} style={{ overflow: "auto" }}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  overflow: "auto",
                }}
              >
                {[...Array(26).keys()].map((value) => {
                  return (
                    <StyledBox>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={
                          <AvatarShift fontSize={12} title={`A`} size={14} />
                        }
                      >
                        <StyledAvatar
                          alt={"username"}
                          src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
                        />
                      </Badge>
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <Typography align="center" variant="body1">
                          Lauryn
                        </Typography>
                        <Typography align="center" variant="body1">
                          Zimmerman
                        </Typography>
                        <Typography
                          align="center"
                          variant="body1"
                          style={{
                            color: "#999999",
                            fontStyle: "oblique",
                          }}
                        >
                          position
                        </Typography>
                      </div>
                    </StyledBox>
                  );
                })}
              </div>
            </StyledTabPanel>
            {dataNewShift.shift.map((value, index) => {
              return (
                <StyledTabPanel
                  value={step}
                  index={index + 1}
                  style={{ overflow: "auto" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      overflow: "auto",
                    }}
                  >
                    {[...Array(26).keys()].map((value) => {
                      return (
                        <StyledBox>
                          <Badge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            badgeContent={
                              <AvatarShift
                                fontSize={12}
                                title={`A`}
                                size={14}
                              />
                            }
                          >
                            <StyledAvatar
                              alt={"username"}
                              src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
                            />
                          </Badge>

                          <div
                            style={{
                              width: "100%",
                            }}
                          >
                            <Typography align="center" variant="body1">
                              Lauryn
                            </Typography>
                            <Typography align="center" variant="body1">
                              Zimmerman
                            </Typography>
                            <Typography
                              align="center"
                              variant="body1"
                              style={{
                                color: "#999999",
                                fontStyle: "oblique",
                              }}
                            >
                              position
                            </Typography>
                          </div>
                        </StyledBox>
                      );
                    })}
                  </div>
                </StyledTabPanel>
              );
            })}
          </StyledSection>
        </div>
      </StyledCard>
      <EventSwitchShift />
      <DialogSwitchShift
        open={openSwitchShift}
        handleClose={handleCloseSwitchShift}
        pattern={pattern}
      />
    </div>
  );
}
