import React, { Fragment } from "react";
import { Box, FormHelperText, Grid, IconButton, MenuItem, styled, Typography } from "@mui/material";
import dayjs from "dayjs";
import { Controller, useWatch } from "react-hook-form";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import Dropzone from "react-dropzone";
import AddIcon from "./../../../../assets/addCertificate.png"
import DatePickerCustom from "../../../../shared/date/datePicker";
import NumberFormatTheme from '../../../../shared/general/NumberFormatTheme';

const StyledRoot = styled(Box)({
  "& .dropzone": {
    cursor: "pointer",
    flexShrink: 0,
    width: "100%",
    height: 144,
    boxSizing: "border-box",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .inner-dropzone": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: 0,
      "& img": {
        width: 72,
        height: 72,
      }
    }
  },
  "& .resume-wrap": {
    textDecoration: "none",
    color: "inherit",
    position: "relative",
    cursor: "pointer",
    flexShrink: 0,
    padding: 8,
    width: "100%",
    height: 144,
    boxSizing: "border-box",
    border: "1px solid #D0D0D0",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    "& .icon-part": {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& i": {
        fontSize: 56,
        color: "#D0D0D0"
      }
    },
    "& .filename": {
      flexShrink: 1,
      height: "48px",
      "& .MuiTypography-root": {
        fontSize: 14,
        display: "-webkit-box",
        overflow: "hidden",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        textAlign: "center",
        wordBreak: "break-all",
      }
    },
    "& .delete": {
      position: "absolute",
      top: 0,
      right: 0,
      padding: 8,
      "& i": {
        fontSize: 16,
      }
    }, "& .fieldLabel": {
      fontSize: 17,
      color: "#FFFFFF",
    }
  }
})

const FormOne = (props) => {

  const { onNextPage, control, trigger } = props;

  const dateOfBirth = useWatch({ control, name: "dateOfBirth" })

  const handleClickNext = async () => {
    const check = await trigger([
      "resume",
      "title",
      "firstname",
      "lastname",
      "phoneNumber",
      "email",
      "dateOfBirth",
      "personalId",
      "dateOfBirth",
    ]);
    if(check) {
      onNextPage();
    }
  }

  const renderAge = (totalMonth) => {
    const year = Math.floor(totalMonth / 12);
    const month = (totalMonth % 12)
    return `${year} ปี ${month} เดือน`
  }

  return (
    <StyledRoot>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Controller
            control={control}
            name="resume"
            render={({ field }) => (
              <Fragment>
                {!field.value &&
                  <Dropzone
                    onDrop={(acceptedFiles, rejectedFiles) => {
                      if (acceptedFiles.length > 0) {
                        field.onChange(acceptedFiles[0])
                      }
                    }}
                    accept="application/pdf"
                    maxFiles={1}
                    multiple={false}
                    maxSize={10485760}
                  >
                    {({
                      getRootProps,
                      getInputProps,
                    }) => (
                      <div {...getRootProps({ className: "dropzone" })}>

                        {/* {isFetching ? (
                        <Box>
                          <Box className="inner-dropzone">
                            <CircularProgress />
                          </Box>
                        </Box>
                      ):( */}
                        <Box>
                          <input {...getInputProps()} />
                          <Box className="inner-dropzone">
                            <img alt="add" src={AddIcon} />
                            <Typography paddingTop="16px" fontSize={14} color="text.third">อัพโหลดเรซูเม่ได้ที่นี่</Typography>
                          </Box>
                        </Box>
                        {/* )} */}
                      </div>
                    )
                    }
                  </Dropzone>}

                {field.value && <Box className="resume-wrap" component={"a"} href={URL.createObjectURL(field.value)} target="_blank">
                  <Box className="icon-part">
                    <i className="fa-solid fa-file"></i>
                  </Box>
                  <Box className="filename">
                    <Typography>{field.value.name}</Typography>
                  </Box>
                  <IconButton className="delete" onClick={(e) => { e.preventDefault(); field.onChange(null) }}>
                    <i className="fa-solid fa-trash-can"></i>
                  </IconButton>
                </Box>}

                <FormHelperText error>*สามารถอัปโหลดไฟล์ได้เพียง 1 ไฟล์ โดยต้องเป็นไฟล์ .pdf เท่านั้น</FormHelperText>
              </Fragment>
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>        
          <Controller
            name="title"
            control={control}
            render={({ field, fieldState }) => (
              <Fragment>
                <Typography gutterBottom sx={{ color: "#000000" }}>คำนำหน้า</Typography>
                <TextFieldTheme
                  {...field}
                  placeholder="คำนำหน้า"
                  helperText={fieldState.error && fieldState.error.message}
                  error={Boolean(fieldState.error)}
                  select
                  sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
                >
                  <MenuItem value="นาย">นาย</MenuItem>
                  <MenuItem value="นาง">นาง</MenuItem>
                  <MenuItem value="นางสาว">นางสาว</MenuItem>
                </TextFieldTheme>
              </Fragment>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="firstname"
            control={control}
            render={({ field, fieldState }) => (
              <Fragment>
                <Typography gutterBottom sx={{ color: "#000000" }}>ชื่อ</Typography>
                <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
                  {...field}
                  placeholder="ชื่อ"
                  fullWidth
                  helperText={fieldState.error && fieldState.error.message}
                  error={Boolean(fieldState.error)}
                />
              </Fragment>
            )}
          />
        </Grid>

        <Grid item xs={12} md={5}>
          <Controller
            name="lastname"
            control={control}
            render={({ field, fieldState }) => (
              <Fragment>
              <Typography gutterBottom sx={{ color: "#000000" }}>นามสกุล</Typography>
              <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
                {...field}
                placeholder="นามสกุล"
                fullWidth
                helperText={fieldState.error && fieldState.error.message}
                error={Boolean(fieldState.error)}
              />
              </Fragment>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field, fieldState }) => (
              <Fragment>
              <Typography gutterBottom sx={{ color: "#000000" }}>เบอร์โทร</Typography>
              <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
                {...field}
                placeholder="เบอร์โทร"
                // inputProps={{
                //   // allowNegative: false,
                //   maxLength: 10,
                // }}
                InputProps={{
                  inputComponent: NumberFormatTheme,
                }}
                inputProps={{
                  format: (value) => {
                    if(value.length >= 3){
                      return String(value).replace(/(\d{1,10})/,"$1")
                    } else {
                      return String(value)
                    }
                  },
                  maxLength: 10,
                  allowNegative: false,
                  allowLeadingZeros: true,
                }}
                fullWidth
                helperText={fieldState.error && fieldState.error.message}
                error={Boolean(fieldState.error)}
              />
              </Fragment>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => (
              <Fragment>
              <Typography gutterBottom sx={{ color: "#000000" }}>อีเมล</Typography>
              <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
                {...field}
                placeholder="อีเมล"
                fullWidth
                helperText={fieldState.error && fieldState.error.message}
                error={Boolean(fieldState.error)}
              />
              </Fragment>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="dateOfBirth"
            render={({ field, fieldState }) => (
              <DatePickerCustom
                {...field}
                views={["year", "month", "day"]}
                inputFormat="DD MMMM YYYY"
                disableFuture
                disableMaskedInput
                openTo="year"
                minDate={dayjs().set("year", 1960)}
                maxDate={dayjs()}
                onChange={(value) => {
                  field.onChange(value)
                }}
                renderInput={(params) => (
                  <Fragment>
                  <Typography gutterBottom sx={{ color: "#000000" }}>วันเดือนปีเกิด</Typography>
                  <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "วัน เดือน ปีเกิด",
                      readOnly: true
                    }}
                    helperText={fieldState.error && fieldState.error.message}
                    error={Boolean(fieldState.error)}
                  />
                  </Fragment>
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="personalId"
            control={control}
            render={({ field, fieldState }) => (
              <Fragment>
              <Typography gutterBottom sx={{ color: "#000000" }}>เลขบัตรประชาชน</Typography>
              <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
                {...field}
                placeholder="เลขบัตรประชาชน"
                // inputProps={{
                //   inputComponent: NumberFormatTheme,
                //   // allowNegative: false,
                //   maxLength: 13,
                //   onValueChange: (values) => {
                //     const { value } = values;
                //     field.onChange(value)
                //   },
                //   // maxLength: 13
                // }}
                // InputProps={{
                //   inputComponent: NumberFormatComponent,
                // }}
                // inputProps={{
                //   // allowNegative: false,
                //     // onValueChange: (values) => {
                //     //   const { value } = values;
                //     //   field.onChange(value);
                //     // },
                //   maxLength: 13
                // }}
                InputProps={{
                  inputComponent: NumberFormatTheme,
                }}
                inputProps={{
                  format: (value) => {
                    if(value.length >= 3){
                      return String(value).replace(/(\d{1,13})/,"$1")
                    } else {
                      return String(value)
                    }
                  },
                  maxLength: 13,
                  allowNegative: false,
                  allowLeadingZeros: true,
                }}
                fullWidth
                helperText={fieldState.error && fieldState.error.message}
                error={Boolean(fieldState.error)}
              />
              </Fragment>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="dateOfBirth"
            render={() => (
              <Fragment>
                <Box display="flex">
                  <Typography color="text.secondary" fontSize={20} paddingRight="8px">อายุ</Typography>
                  <Typography variant="h6" sx={{ color: "#000000" }}>{renderAge(dayjs().diff(dateOfBirth, 'month'))}</Typography>
                </Box>
              </Fragment>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <ButtonBlue variant="contained" onClick={handleClickNext}>ถัดไป</ButtonBlue>
          </Grid>
        </Grid>
      </Grid>
    </StyledRoot>
  )
}

export default FormOne;