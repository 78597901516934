import React, { Fragment } from "react";
import { useDropzone } from "react-dropzone";
import AddIcon from "../../assets/add.png";
import PaperClipIcon from "../../assets/paper-clip.png";
import { Avatar, Box, IconButton, Typography, styled } from "@mui/material";
import { Controller } from "react-hook-form";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  "& .dropzone-upload-file": {
    textAlign: "center",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    height: 200,
    display: "flex",
    justifyContent: "center",
    "& .inner-dropzone": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      "& img": {
        marginBottom: 16,
      },
    },
  },
});

const DragDrop = (props) => {
  const { name, useHookForm } = props;
  const { t, i18n } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles) {
        useHookForm.setValue(name, acceptedFiles[0]);
      }
      if (rejectedFiles.length > 0 && rejectedFiles[0].errors) {
        if (rejectedFiles[0].errors[0].code === "file-invalid-type") {
          useHookForm.setError(name, {
            message: `${t("file_invalid_type")}`,
          });
        }
        if (rejectedFiles[0].errors[0].code === "file-too-large") {
          useHookForm.setError(name, { message: `${t("file_too_large")}` });
        }
        if (rejectedFiles[0].errors[0].code === "too-many-files") {
          useHookForm.setError(name, {
            message: `${t("too_many_files")}`,
          });
        }
      }
    },
    multiple: false,
    maxSize: 1024 * 1024 * 5,
  });

  const getSizeText = (size) => {
    const unit = ["K", "M", "G"];
    let tempSize = size / 1024;
    let i = 0;
    while (tempSize > 1024 && i < 2) {
      tempSize = tempSize / 1024;
      i++;
    }

    return `${Math.round(tempSize * 100) / 100} ${unit[i]}B`;
  };

  return (
    <StyledRoot>
      <Controller
        control={useHookForm.control}
        name={name}
        render={({ field }) => (
          <Fragment>
            {!field.value ? (
              <Box>
                <div
                  {...getRootProps({
                    className: "dropzone-upload-file",
                  })}
                  style={{
                    userSelect: "none",
                  }}
                >
                  <input {...getInputProps()} />
                  <div className="inner-dropzone">
                    <img
                      alt="AddIcon"
                      src={AddIcon}
                      width="80"
                      style={{ pointerEvents: "none" }}
                    />
                    <Typography
                      style={{
                        marginTop: 8,
                        backgroundColor: "transparent",
                      }}
                      className={`placeholderLabel`}
                      variant="body2"
                      color="text.secondary"
                    >
                      {t("DragDropOrClickSelectFile")}
                    </Typography>
                  </div>
                </div>
                {useHookForm.formState.errors[field.name] && (
                  <Typography
                    margin="3px 0px 0px"
                    fontSize="14px"
                    color="error"
                  >
                    {useHookForm.formState.errors[field.name].message}
                  </Typography>
                )}
                <Typography
                  marginTop="8px"
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  *{t("file_invalid_type")}/ {t("file_too_large")}/
                  {t("too_many_files")}
                </Typography>
              </Box>
            ) : (
              <Box
                display="flex"
                padding="4px"
                style={{
                  border: "1px solid #D0D0D0",
                  borderStyle: "dashed",
                  borderRadius: 8,
                  userSelect: "none",
                }}
              >
                <Avatar
                  src={PaperClipIcon}
                  variant="rounded"
                  style={{
                    marginRight: "8px",
                    marginLeft: "8px",
                    width: "32px",
                    height: "32px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    pointerEvents: "none",
                  }}
                />
                <Box minWidth="0" flexGrow="1">
                  <Typography
                    fontSize="14px"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                  >
                    {field.value.name}
                  </Typography>
                  <Typography fontSize="12px" color="text.secondary">
                    {getSizeText(field.value.size)}
                  </Typography>
                </Box>
                <IconButton
                  color="error"
                  onClick={() => {
                    field.onChange(null);
                  }}
                >
                  <ClearOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </Fragment>
        )}
      />
    </StyledRoot>
  );
};

export default DragDrop;
