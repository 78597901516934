import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, styled, Grid, IconButton, Divider } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import ButtonBlue from "../../shared/general/ButtonBlue";

import DialogAddress from "./DialogAddress";

import { updateUser, getUserProfile } from "../../../../actions/user";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledWrapBranch = styled("div")({
  minHeight: 350,
  "& .wrap-branch-top": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .MuiTypography-body2": {
      margin: 0,
    },
  },
  "& .branch-scroll": {
    height: 280,
    overflow: "auto",
  },
  "& .action": {
    textAlign: "right",
    "& .fal": {
      fontSize: 18,
    },
  },
  "& .address": {
    fontWeight: 600,
    fontSize: 18,
  },
  "& .contact": {
    "& .fal": {
      marginRight: 8,
    },
  },
});

const StyledIconButton = styled(IconButton)({
  margin: "0 4px",
  "&:hover": {
    background: "transparent",
    color: "#007afe",
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const LocationTab = (props) => {
  const { handleChangeAlertType, handleOpenAlert } = props;
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [openDialogAddress, setOpenDialogAddress] = useState(false);

  const handleCloseDialog = () => {
    console.log("handleCloseDialog");
    setOpenDialogAddress(false);
  };

  return (
    <div>
      <StyledWrapBranch>
        <div className="wrap-branch-top">
          <StyledHeadLabel variant="body2" gutterBottom>
            {`${t("Address")}`}
          </StyledHeadLabel>
          <div>
            <ButtonBlue
              variant="text"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => setOpenDialogAddress(true)}
            >
              {`${t("EditData")}`}
            </ButtonBlue>
          </div>
        </div>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Grid item xs={6} sm={4}>
            <StyledHeadLabel color="text.secondary">{`${t(
              "HouseNo"
            )}`}</StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.houseNo ? userProfile.houseNo : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <StyledHeadLabel color="text.secondary">{`${t(
              "Village"
            )}`}</StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.village ? userProfile.village : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <StyledHeadLabel color="text.secondary">{`${t(
              "VillageNo"
            )}`}</StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.villageNo ? userProfile.villageNo : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <StyledHeadLabel color="text.secondary">{`${t(
              "Alley"
            )}`}</StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.alley ? userProfile.alley : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <StyledHeadLabel color="text.secondary">{`${t(
              "Road"
            )}`}</StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.road ? userProfile.road : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <StyledHeadLabel color="text.secondary">{`${t(
              "SubDistrict"
            )}`}</StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.subDistrict ? userProfile.subDistrict : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <StyledHeadLabel color="text.secondary">{`${t(
              "District"
            )}`}</StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.district ? userProfile.district : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <StyledHeadLabel color="text.secondary">{`${t(
              "Province"
            )}`}</StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.provience ? userProfile.provience : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <StyledHeadLabel color="text.secondary">
              {`${t("AreaCode")}`}
            </StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.areaCode ? userProfile.areaCode : "-"}
            </Typography>
          </Grid>
        </Grid>
      </StyledWrapBranch>

      <DialogAddress
        open={openDialogAddress}
        handleCloseDialog={handleCloseDialog}
        handleChangeAlertType={handleChangeAlertType}
        handleOpenAlert={handleOpenAlert}
      />
    </div>
  );
};

export default LocationTab;
