import React, { Fragment, useEffect, useRef , useState } from "react";
import { 
  Dialog, 
  DialogActions,
  Button, 
  Grid, 
  Typography, 
  DialogTitle, 
  Container, 
  IconButton, 
  TextField
} from "@mui/material";
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import * as Yup from "yup";
// import { TextField, Select, SimpleFileUpload, } from 'formik-mui';
import { Formik, Form, Field, FieldArray } from "formik";

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles(() => ({
  wrapCaptionImage: {
    marginTop: 16,
    textAlign: "center",
    marginTop: 0,
  },
  bigAvatar: {
    width: "84%",
    height: "84%",
    margin: 10,
  },
  paper: {
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#2c387e",
  },
  icon: {
    fontSize: 28,
    color: "white",
  },
  text:{
    fontSize: 15,
    marginBottom: 2,
    marginLeft: 10,
  },
}));

const AddPosition = ({open, close}) => {
    const classes = useStyles();
    const formRef = useRef();

    const validationSchema = Yup.object().shape({
      name: Yup.string().required("Please enter position"),
      numberOfSeat: Yup.number().positive('Please enter number in Positive').required("Please enter number of seat"),
    });

    
    const handleClickSave = () => {
      if (formRef.current) {
        formRef.current.handleSubmit();
      }
    };

    const [dataPosition, setDataPostion] = useState(
      {
        data: [{
          name: '',
          numberOfSeat: '',
        }]
    });

    return (
      
        
          <Dialog open={open} maxWidth="false">
            <CloseIcon style={{alignSelf: 'self-end',
                                cursor: "pointer",
                                marginRight: 10,
                                marginTop: 10,
                                marginBottom: 0,
                              }}
                         onClick={close}
              />
            <DialogTitle 
                style={{textAlign: 'center', 
                        color: '#001c54', 
                        fontWeight:'bold',
                        paddingTop: 0,
                        paddingBottom: 0,
                        fontSize: 26}}
            >
              {'Create Position'} 
             
            </DialogTitle>
            
            <Formik
              initialValues={dataPosition}
              validationSchema={validationSchema}
              onSubmit={(values, {setSubmitting}) => {
                console.log('saved value', values)
                setDataPostion(values);
              }}
            >
              {({ values }) => (
                <Form autoComplete="off" maxWidth="false" style={{padding: 10}}>
                  <FieldArray name='data' >
                    {({push ,remove}) => (
                        <div>
                        {values.data.map(( _, index) => (
                             <Grid container spacing={2} key={index}>
                               <Grid item xl={5} lg={5} mg={5} xs={12}>
                                <Field
                                  component={TextField}
                                  name={`data[${index}].name`}
                                  id='name'
                                  label='Job position'
                                  required
                                  fullWidth
                                  // size="sm}all"
                                  variant="outlined"
                                  placeholder="Job position" 
                                  style={{margin: 10, marginRight: 20}}
                                  />
                              </Grid>
                              <Grid item xl={4} lg={4} mg={4} xs={8}>
                               <Field
                                 component={TextField}
                                 name={`data[${index}].numberOfSeat`}
                                 id='numberOfSeat'
                                 label='Number of seat'
                                 required
                                 type='number'
                                 // size="small"
                                 fullWidth
                                 variant="outlined"
                                 placeholder="Number of seat" 
                                 style={{margin: 10, marginRight: 20}}
                               />  
                               </Grid>
                               <Grid item xl={3} lg={3} md={3} xs={4} style={{textAlign: 'center'}}>
                                   <IconButton aria-label="add-data" style={{ marginTop: 20,backgroundColor: '#7dc581', color: '#357a38' }}>
                                     <AddIcon onClick={() => {
                                        push({
                                           name: '',
                                           numberOfSeat: '',
                                       }); 
                                     }}
                                     />
                                     </IconButton>
                                     {values.data.length == 1 ? 
                                       <IconButton 
                                         aria-label="delete" 
                                         style={{ marginLeft: 5,marginTop: 20, }} 
                                         disabled
                                         color="primary"
                                         >
                                           <DeleteIcon  />
                                       </IconButton>
                                     :
                                       <IconButton 
                                         aria-label="delete" 
                                         style={{ marginTop: 20,
                                                  marginLeft: 5 ,
                                                   backgroundColor: '#dd99b1', 
                                                   color: '#ab003c' 
                                               }} 
                                         >
                                           <DeleteIcon onClick={() => remove(index)}  />
                                       </IconButton>
                                     }
                                </Grid>
                            </Grid>
                           
                        ))}
                      </div>
                    )}
                  </FieldArray>
                  <Grid style={{padding: 20, textAlign: 'right' ,paddingTop: 0}}>
                    <Button type='submit' variant='contained'>submit</Button>
                  </Grid>
                </Form> 
              )}
            </Formik>
          </Dialog>
        
       
    )
}

export default AddPosition;