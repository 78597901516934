import React, { useEffect, useState, useRef, Fragment } from "react";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import {
  Paper,
  Button,
  Typography,
  FormControlLabel,
  Switch,
  Grid,
  TextField,
  FormControl,
  FormLabel,
  FormHelperText,
  InputLabel,
  FormGroup,
  Checkbox,
  MenuItem,
  Select,
  IconButton,
  Divider,
  InputAdornment,
} from "@mui/material";
import { useTransition } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
    marginTop: 50,
  },
  wrapHeader: {
    marginTop: 16,
  },
  divider: {
    margin: "10px 0",
  },
  imageProfile: {
    marginBottom: 40,
  },
  wrapCaptionImage: {
    marginTop: 16,
    textAlign: "center",
  },
  captionImage: {
    color: "#212b36",
  },
  wrapSwitchActive: {
    flexDirection: "row-reverse",
    margin: 0,
    width: "100%",
    "& .MuiFormControlLabel-label": {
      marginRight: "auto",
    },
  },
  labelSwitchActive: {
    fontSize: 14,
  },
  row: {
    marginBottom: 16,
    paddingLeft: 48,
  },
  textField: {
    width: "100%",
  },
  buttonSave: {
    marginLeft: "auto",
  },
  uploadImage: {
    width: "105%",
    zIndex: 8,
  },
  placeholder: {
    width: "100%",
    height: "100%",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "rgb(99, 115, 129)",
    backgroundColor: "rgb(244, 246, 248)",
    transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  placeholderImageProfile: {
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgba(22, 28, 36, .72)",
  },
  placeholderLabel: {
    color: "rgb(255, 255, 255)",
  },
}));

const StepOne = (props) => {
  const {t,i18n} = useTranslation();
  const { handleChange, dataEmployee } = props;
  const classes = useStyles();
  const [expertise, setExpertise] = useState([
    { id: 1, name: "Business & Operations" },
    { id: 2, name: "Design" },
    { id: 3, name: "DevOps & IT" },
    { id: 4, name: "Marketing" },
    { id: 5, name: "Product Management" },
    { id: 6, name: "Quality Assurance" },
    { id: 7, name: "Sales" },
    { id: 8, name: "Software Engineering" },
    { id: 9, name: "Data Science and Analytics" },
  ]);
  const [seniority, setSeniority] = useState([
    { id: 1, name: "ฝึกงาน" },
    { id: 2, name: "พนักงานทั่วไป" },
    { id: 3, name: "พนักงานระดับสูง" },
    { id: 4, name: "ผู้จัดการ" },
    { id: 5, name: "ผู้อำนวยการ" },
    { id: 6, name: "ผู้บริหารระดับสูง" },
  ]);
  return (
    <div>
      <div className={classes.row}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="text.secondary">
              {t("FullName")}
            </Typography>
            <Typography variant="h6">Jacque Sparks</Typography>
            <br />
            <Typography variant="body2" color="text.secondary">
              {t("PhoneNumber")}
            </Typography>
            <Typography variant="h6">081-378-5622</Typography>
            <br />
            <Typography variant="body2" color="text.secondary">
            {t("Email")}
            </Typography>
            <Typography variant="h6">jacques@mail.com</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="text.secondary">
              ระดับอาวุโส
            </Typography>
            <Typography variant="h6">พนักงานทั่วไป</Typography>
            <br />
            <Typography variant="body2" color="text.secondary">
              ประเภทพนักงาน
            </Typography>
            <Typography variant="h6">Full Time</Typography>
            <br />
            <Typography variant="body2" color="text.secondary">
              รายได้ที่คาดหวัง
            </Typography>
            <Typography variant="h6">13,000 บาท</Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default StepOne;
