import React, { useState } from "react";
import Chart from "react-apexcharts";
import Utils from "../../../../utils";

const ChartCustom = (props) => {

  const { series } = props;

  // const [series, setseries] = useState([
  //   {
  //     name: "รายจ่าย",
  //     data: [
  //       {x: "ม.ค.", y: 648080},
  //       {x: "ก.พ.", y: 348080}, 
  //       {x: "มี.ค.", y: 848080},
  //       {x: "เม.ย.", y: 648080},
  //       {x: "พ.ค.", y: 348080}, 
  //       {x: "มิ.ย.", y: 848080},
  //       {x: "ก.ค.", y: 848080},
  //       {x: "ส.ค.", y: 848080},
  //       {x: "ก.ย.", y: 848080},
  //       {x: "ต.ค.", y: 848080},
  //       {x: "พ.ย.", y: 848080},
  //       {x: "ธ.ค.", y: 848080},
  //     ],
  //   },
  // ]);

  const [optionsChart, setOptionsChart] = useState({
    chart: {
      selection: {
        enabled: false,
      },
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 3,   
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      labels: {
        formatter: (value) => { return Utils.numberWithCommas(value) },
      }
    },
    theme: {
      monochrome: {
        enabled: true,
        shadeTo: "light",
        shadeIntensity: 0.6,
      },
    },
  });

  return (
    <Chart height="250px" options={optionsChart} series={series} type="area"/>
  )
}

export default ChartCustom;