import React, { useEffect, useState, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Link, withRouter, NavLink } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import dayjs from "dayjs";

import SaveIcon from "@mui/icons-material/Save";

import HeaderPage from "./../../../shared/header/headerPage";
import StyleCard from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import StepOne from "./stepOne";
import DialogOverManpower from "./dialogOverManpower";

import {
  Paper,
  Container,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";


const useStyles = makeStyles(() => ({
  divider: {
    margin: "30px 0",
  },
  imageProfile: {
    marginBottom: 40,
  },
  wrapCaptionImage: {
    marginTop: 16,
    textAlign: "center",
  },
  captionImage: {
    color: "#212b36",
  },
  wrapSwitchActive: {
    flexDirection: "row-reverse",
    margin: 0,
    width: "100%",
    "& .MuiFormControlLabel-label": {
      marginRight: "auto",
    },
  },
  labelSwitchActive: {
    fontSize: 14,
  },
  row: {
    marginBottom: 16,
  },
  textField: {
    width: "100%",
  },
  buttonSave: {
    marginLeft: "auto",
  },
  uploadImage: {
    width: "105%",
    zIndex: 8,
  },
  placeholder: {
    width: "100%",
    height: "100%",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "rgb(99, 115, 129)",
    backgroundColor: "rgb(244, 246, 248)",
    transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  placeholderImageProfile: {
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgba(22, 28, 36, .72)",
  },
  placeholderLabel: {
    color: "rgb(255, 255, 255)",
  },
}));

var convertImgToDataURLviaCanvas = function (url, callback) {
  var img = new Image();

  img.crossOrigin = "Anonymous";

  img.onload = function () {
    var canvas = document.createElement("CANVAS");
    var ctx = canvas.getContext("2d");
    var dataURL;
    canvas.height = this.height;
    canvas.width = this.width;
    ctx.drawImage(this, 0, 0);
    dataURL = canvas.toDataURL();
    callback(dataURL);
    canvas = null;
  };

  img.src = url;
};

const StyledFirstSection = styled(Paper)({
  padding: "75px 24px",
  border: "none",
});

const StyledSecondSection = styled(Paper)({
  padding: "24px",
  border: "none",
});

const StyledRoot = styled("div")({
  marginBottom: 36,
  backgroundColor: "#FFFFFF !important"
});

const StyledTabPanel = styled(TabPanel)({
  paddingTop: 0,
  paddingBottom: 0,
});

const RequestFormPage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const formRef = useRef();
  const [openDialogOverManpower, setOpenDialogOverManpower] =
    React.useState(false);
  const [tabIndex, setTabIndex] = React.useState("1");
  const [dataEmployee, setDataEmployee] = useState({
    username: "",
    email: "",
    image: "",
    firstname: "",
    lastname: "",
    idDepartment: "",
    seniority: "",
    expertise: "",
    experience: "",
    typeEmployee: "",
    department: "",
    positionName: "พนักงานขนส่งสินค้า",
    urgent: true,
    salary: "",
    dateAppointment: dayjs().toDate(),
    mobileNumber: "",
    workingLocation: "",
    amount: 0,
    site: "",
    status: true,
    company: "Thai Polyethylene Co., Ltd.",
    vendorAcceptList: [],
    vendorAccept: "",
    authorities: {
      user: true,
      manager: false,
      admin: false,
    },
  });

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleClickSave = () => {
    console.log("handleClickSave");
    setOpenDialogOverManpower(true);
  };

  const handleChangeAmount = (newValue) => {
    setDataEmployee({
      ...dataEmployee,
      ["amount"]: newValue,
    });
  };

  const handleChange = (event) => {
    setDataEmployee({
      ...dataEmployee,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCheck = (event) => {
    setDataEmployee({
      ...dataEmployee,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeDateAppointment = (newValue) => {
    setDataEmployee({
      ...dataEmployee,
      ["dateAppointment"]: newValue,
    });
  };

  const handleCloseDialog = () => {
    setOpenDialogOverManpower(false);
  };

  const handleChangeVendorAcceptList = (event) => {
    const {
      target: { value },
    } = event;

    var dataTemp = { ...dataEmployee };
    dataTemp.vendorAcceptList = value;
    setDataEmployee(dataTemp);
  };

  const handleChangeVendorAccept = (event) => {
    var dataTemp = { ...dataEmployee };
    dataTemp.vendorAccept = event.target.value;

    if (event.target.value !== "customize") {
      dataTemp.vendorAcceptList = [];
    }

    setDataEmployee(dataTemp);
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div>
          {props.match.params.id ? (
            <HeaderPage textLabel={"แก้ไขข้อมูล"} />
          ) : (
            <HeaderPage textLabel={"คำขอพนักงานใหม่"} />
          )}
        </div>
        <StyleCard>
          <TabContext value={tabIndex}>
            <TabList
              style={{ marginTop: 16 }}
              centered
              onChange={handleChangeTab}
              aria-label="lab API tabs example"
            >
              <Tab label="จ้างงานทั่วไป" value="1" />
              <Tab label="จ้างทำของ" value="2" />
            </TabList>
            <StyledTabPanel value="1">
              <StyledSecondSection variant="outlined">
                <StepOne
                  handleChangeDateAppointment={handleChangeDateAppointment}
                  handleChange={handleChange}
                  handleChangeAmount={handleChangeAmount}
                  handleChangeCheck={handleChangeCheck}
                  handleChangeVendorAcceptList={handleChangeVendorAcceptList}
                  handleChangeVendorAccept={handleChangeVendorAccept}
                  dataEmployee={dataEmployee}
                />
              </StyledSecondSection>
            </StyledTabPanel>
            <StyledTabPanel value="2"></StyledTabPanel>
          </TabContext>
          <div
            style={{
              marginBottom: 36,
              margintop: 16,
              paddingRight: 48,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ButtonBlue
              //onClick={handleClickSave}
              startIcon={<SaveIcon />}
              value={"Create New Request"}
              variant={"contained"}
            />
          </div>
        </StyleCard>
        <DialogOverManpower
          open={openDialogOverManpower}
          handleClose={handleCloseDialog}
        />
      </Container>
    </StyledRoot>
  );
};

export default RequestFormPage;
