import React from "react";
import { useController } from "react-hook-form";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";

const InputNumber = ({ control, name, label }) => {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
  });

  return (
    <TextFieldTheme
      {...field}
      label={label}
      InputProps={{ inputComponent: NumberFormatTheme }}
      inputProps={{
        onValueChange: (values) => {
          const { value } = values;
          field.onChange(value);
        },
      }}
      onChange={() => {}}
      helperText={error && error.message}
      error={Boolean(error)}
    />
  );
};

export default InputNumber;
