import {
  POSITIONS_FETCHING,
  POSITIONS_FAILED,
  POSITIONS_SUCCESS,
  // ADDITIONSANDTRAININGS_FETCHING,
  // ADDITIONSANDTRAININGS_FAILED,
  // ADDITIONSANDTRAININGS_SUCCESS
} from "./types";

import PositionsService from "../services/positions.service";

export const getAllPositions = (query) => async (dispatch) => {
  try {
    const res = await PositionsService.getAllPositions(query);
    if (res) {
      dispatch({
        type: POSITIONS_SUCCESS,
        payload: res.data,
      });
    }
    return res
  } catch (err) {
    dispatch({
      type: POSITIONS_FAILED,
    });
    console.log(err);
  }
};

export const postPositions = (formData) => async () => {
  try {
    const res = await PositionsService.postPositions(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putPositions = (id, formData) => async () => {
  try {
    const res = await PositionsService.putPositions(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const addNewPosition = (idCompany, data) => async () => {
  try {
    const res = await PositionsService.addNewPosition(idCompany, data);
    if (res) {
      return res.data;
    }
  } catch (error) {
    return "Error";
  }
};

// export const deletePosition = (idPositions) => async() => {
//   try {
//     const res = await PositionsService.deletePosition(idPositions);
//     if(res) {
//       return res;
//     }
//   } catch (error) {
//     return "Error";
//   }
// };

// export const getAdditionAndTraining = (idPositions) => async (dispatch) => {
//   try {
//     dispatch({
//       type: ADDITIONSANDTRAININGS_FETCHING
//     })
//     const res = await PositionsService.getAdditionAndTraining(idPositions);
//     if(res){
//       dispatch({
//         type: ADDITIONSANDTRAININGS_SUCCESS,
//         payload: res.data
//       })
//     }
//   } catch (error) {
//     dispatch({
//       type: ADDITIONSANDTRAININGS_FAILED
//     })
//   }
// };

export const updatePosition = (idCompany, idPositions, value, listDelete) => async () => {
  try {
    let data = {
      position: value,
      listDelete: listDelete
    };
    const res = await PositionsService.updatePosition(idCompany, idPositions, data);
    if (res) {
      return res;
    }
  } catch (error) {
    return "Error";
  }
};

export const addPosition = (formData) => async () => {
  try {
    const res = await PositionsService.addPosition(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
}

export const deletePosition = (idPosition) => async () => {
  try {
    const res = await PositionsService.deletePosition(idPosition);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
}

export const getAllJobProfile = async (query) => {
  try {
    const res = await PositionsService.getAllJobProfile(query);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
}

export const addJobProfile = async (query) => {
  try {
    const res = await PositionsService.addJobProfile(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
}

export const updateJobProfile = async (query) => {
  try {
    const res = await PositionsService.updateJobProfile(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
}