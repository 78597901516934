export function CalculateAdjustedY(y) {
    let adjustedY;
    const baseY = 841.89;

    switch (true) {
        case (y <= 194):
            adjustedY = baseY - 80;
            break;
        case (y > 194 && y <= 200):
            adjustedY = baseY - 100;
            break;
        case (y > 200 && y <= 210):
            adjustedY = baseY - 110;
            break;
        case (y > 210 && y <= 220):
            adjustedY = baseY - 135;
            break;
        case (y > 220 && y <= 230):
            adjustedY = baseY - 140;
            break;
        case (y > 230 && y <= 240):
            adjustedY = baseY - 145;
            break;
        case (y > 240 && y <= 250):
            adjustedY = baseY - 150;
            break;
        case (y > 250 && y <= 260):
            adjustedY = baseY - 155;
            break;
        case (y > 260 && y <= 270):
            adjustedY = baseY - 160;
            break;
        case (y > 270 && y <= 280):
            adjustedY = baseY - 165;
            break;
        case (y > 280 && y <= 290):
            adjustedY = baseY - 170;
            break;
        case (y > 290 && y <= 300):
            adjustedY = baseY - 175;
            break;
        case (y > 300 && y <= 310):
            adjustedY = baseY - 180;
            break;
        case (y > 310 && y <= 320):
            adjustedY = baseY - 185;
            break;
        case (y > 320 && y <= 330):
            adjustedY = baseY - 190;
            break;
        case (y > 330 && y <= 340):
            adjustedY = baseY - 195;
            break;
        case (y > 340 && y <= 350):
            adjustedY = baseY - 200;
            break;
        case (y > 350 && y <= 360):
            adjustedY = baseY - 205;
            break;
        case (y > 360 && y <= 370):
            adjustedY = baseY - 220;
            break;
        case (y > 370 && y <= 380):
            adjustedY = baseY - 240;
            break;
        case (y > 380 && y <= 390):
            adjustedY = baseY - 260;
            break;
        case (y > 390 && y <= 400):
            adjustedY = baseY - 280;
            break;
        case (y > 400 && y <= 410):
            adjustedY = baseY - 305;
            break;
        case (y > 410 && y <= 420):
            adjustedY = baseY - 354;
            break;
        case (y > 420 && y <= 430):
            adjustedY = baseY - 340;
            break;
        case (y > 430 && y <= 440):
            adjustedY = baseY - 380;
            break;
        case (y > 440 && y <= 450):
            adjustedY = baseY - 390;
            break;
        case (y > 450 && y <= 460):
            adjustedY = baseY - 384;
            break;
        case (y > 460 && y <= 470):
            adjustedY = baseY - 370;
            break;
        case (y > 470 && y <= 480):
            adjustedY = baseY - 360;
            break;
        case (y > 480 && y <= 490):
            adjustedY = baseY - 350;
            break;
        case (y > 490 && y <= 500):
            adjustedY = baseY - 443;
            break;
        case (y > 500 && y <= 510):
            adjustedY = baseY - 440;
            break;
        case (y > 510 && y <= 520):
            adjustedY = baseY - 420;
            break;
        case (y > 520 && y <= 530):
            adjustedY = baseY - 415;
            break;
        case (y > 530 && y <= 540):
            adjustedY = baseY - 420;
            break;
        case (y > 540 && y <= 550):
            adjustedY = baseY - 430;
            break;
        case (y > 550 && y <= 560):
            adjustedY = baseY - 500;
            break;
        case (y > 560 && y <= 570):
            adjustedY = baseY - 520;
            break;
        case (y > 570 && y <= 580):
            adjustedY = baseY - 530;
            break;
        case (y > 580 && y <= 590):
            adjustedY = baseY - 540;
            break;
        case (y > 590 && y <= 600):
            adjustedY = baseY - 550;
            break;
        case (y > 600 && y <= 610):
            adjustedY = baseY - 580;
            break;
        case (y > 610 && y <= 620):
            adjustedY = baseY - 600;
            break;
        case (y > 620 && y <= 630):
            adjustedY = baseY - 610;
            break;
        case (y > 630 && y <= 640):
            adjustedY = baseY - 620;
            break;
        case (y > 640 && y <= 650):
            adjustedY = baseY - 630;
            break;
        case (y > 650 && y <= 660):
            adjustedY = baseY - 640;
            break;
        case (y > 660 && y <= 670):
            adjustedY = baseY - 650;
            break;
        case (y > 670 && y <= 680):
            adjustedY = baseY - 660;
            break;
        case (y > 680 && y <= 700):
            adjustedY = baseY - 670;
            break;
        case (y > 700 && y <= 720):
            adjustedY = baseY - 680;
            break;
        case (y > 720 && y <= 750):
            adjustedY = baseY - 690;
            break;
        case (y > 750 && y <= 800):
            adjustedY = baseY - 700;
            break;
        case (y > 800 && y <= 860):
            adjustedY = baseY - 720;
            break;
        case (y > 860):
            adjustedY = baseY - 790;
            break;
        default:
            adjustedY = y; 
    }

    return adjustedY;
}

