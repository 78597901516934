import { Grid, styled, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerBank from "./DrawerBank";
import DrawerPayment from "./drawerPayment";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DividerStyle from "../../shared/general/Divider";
import { getBankDetailByCompany } from "../../../../actions/bank";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "&.inner": {
    marginBottom: 0,
  },
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const BankPanel = (props) => {
  const { selectedCompany } = props;
  const dispatch = useDispatch();
  const { result: BankDetail } = useSelector((state) => state.bankDetail);
  const { result: companyProfile } = useSelector(
    (state) => state.companyProfile
  );
  const [isOpenDrawerBank, setIsOpenDrawerBank] = useState(false);
  const [isOpenDrawerPayment, setIsOpenDrawerPayment] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(getBankDetailByCompany({ idCompany: selectedCompany.idCompany }));
  }, []);

  return (
    <div>
      <StyledWrapTop>
        <Typography variant="body2" style={{ fontWeight: 600 }}>
          {`${t("BankInfo")}`}
        </Typography>
        <ButtonBlue
          size="small"
          startIcon={<EditRoundedIcon />}
          onClick={() => setIsOpenDrawerBank(true)}
        >
          {`${t("EditData")}`}
        </ButtonBlue>
      </StyledWrapTop>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">{`${t(
            "BankName"
          )}`}</StyledHeadLabel>
          <Typography variant="h6">
            {(BankDetail && BankDetail[0] && BankDetail[0].bankName) || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">{`${t(
            "BookID"
          )}`}</StyledHeadLabel>
          <Typography variant="h6">
            {(BankDetail && BankDetail[0] && BankDetail[0].accountNo) || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">{`${t(
            "AccountName"
          )}`}</StyledHeadLabel>
          <Typography variant="h6">
            {(BankDetail && BankDetail[0] && BankDetail[0].accountName) || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">{`${t(
            "BranchName"
          )}`}</StyledHeadLabel>
          <Typography variant="h6">
            {(BankDetail && BankDetail[0] && BankDetail[0].branchName) || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">{`${t(
            "BranchAddress"
          )}`}</StyledHeadLabel>
          <Typography variant="h6">
            {(BankDetail && BankDetail[0] && BankDetail[0].branchAddress) ||
              "-"}
          </Typography>
        </Grid>
      </Grid>
      <DividerStyle />
      <StyledWrapTop>
        <Typography variant="body2" style={{ fontWeight: 600 }}>
          {`${t("PaymentInfo")}`}
        </Typography>
        <ButtonBlue
          size="small"
          startIcon={<EditRoundedIcon />}
          onClick={() => setIsOpenDrawerPayment(true)}
        >
          {`${t("EditData")}`}
        </ButtonBlue>
      </StyledWrapTop>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">{`${t(
            "Currency"
          )}`}</StyledHeadLabel>
          <Typography variant="h6">
            {(companyProfile && companyProfile.paymentCurrency) || "-"}
          </Typography>
        </Grid>
      </Grid>
      {isOpenDrawerBank && (
        <DrawerBank
          open={isOpenDrawerBank}
          onClose={() => {
            setIsOpenDrawerBank(false);
          }}
          selectedCompany={selectedCompany}
        />
      )}
      {isOpenDrawerPayment && (
        <DrawerPayment
          open={isOpenDrawerPayment}
          onClose={() => {
            setIsOpenDrawerPayment(false);
          }}
          selectedCompany={selectedCompany}
        />
      )}
    </div>
  );
};

export default BankPanel;
