import React from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography } from "@mui/material";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserCompany, getUserDepartment, getUserFullName } from "../../../../../utils/userData";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTypography-body1": {
      color: "#757575",
    },
    "& .MuiTypography-body2": {
      color: "#16243D",
      fontSize: "1rem",
    },
  },
  box: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    backgroundColor: "#fafafa",
    borderRadius: "10px",
    padding: "24px 16px 24px 16px",
  },
  gridWrapper: {
    marginBottom: theme.spacing(2),
  },
}));

function EmpInfo({ userProfile }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const displayAgeUntilNow = (date) => {
    let allMonth = Math.floor(dayjs().diff(dayjs(date), "month", true));
    let year = Math.floor(allMonth / 12);
    let month = allMonth - year * 12;

    if (year === 0 && month === 0) {
      return `0 ${t("Month")}`;
    } else {
      return `${year > 0 ? `${year} ${t("Year")} ` : ""}${
        month > 0 ? `${month} ${t("Month")}` : ""
      }`;
    }
  }

  return (
    <div className={classes.root}>
      <Box className={classes.box}>
        <Grid container className={classes.gridWrapper} spacing={1}>
          <Grid container item xs={12} md={6} lg={6}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">Employee ID</Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2">
                {userProfile.employeeID || "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} lg={6}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">{`${t("FullName")}`}</Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              {userProfile.firstname_TH && userProfile.lastname_TH
                ? <Typography>{getUserFullName(userProfile)}</Typography>
                : <Typography variant="body2">-</Typography>}
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.gridWrapper} spacing={1}>
          <Grid container item xs={12} md={6} lg={6}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">{t("YearOfService")}</Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2">
                {(
                  userProfile.hiringDate && 
                  displayAgeUntilNow(userProfile.hiringDate)
                ) || "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} lg={6}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">{`${t("Position")}`}</Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2">
                {userProfile.positionName || "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.gridWrapper} spacing={1}>
          <Grid container item xs={12} md={6} lg={6}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">{`${t("PersonalID")}`}</Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2">
                {userProfile.personalID || "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} lg={6}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">{`${t("TaxIDNumber")}`}</Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2">
                {userProfile.taxID || "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.gridWrapper} spacing={1}>
          <Grid container item xs={12} md={6} lg={6}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">{`${t("Department")}`}</Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2">
                {getUserDepartment(userProfile)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} lg={6}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">{`${t("Company")}`}</Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2">
                {getUserCompany(userProfile)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default EmpInfo;
