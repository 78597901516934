import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import React from "react";
import ExcelJS from 'exceljs';

import { FormControl, Grid, Typography } from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
  "& .btn-action": {
    display: "flex",
    justifyContent: "center",
    marginTop: 36,
  },
});

const calculateAverage = (rowData) => {
  if (rowData.kpi && rowData.kpi.length > 0) {
    let sum = 0;
    let count = 0;
    for (let i = 0; i < rowData.kpi.length; i++) {
      if (rowData.kpi[i].managerRating !== null) {
        sum += parseInt(rowData.kpi[i].managerRating);
        count++;
      }
    }
    return count > 0 ? sum / count : 0;
  }
  return 0;
};

const DialogDownload = (props) => {
  const {t} = useTranslation();
  const { open, toggleDrawer, employeeData } = props;

  const TaxDeductionXlsxFile = (data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");
    dayjs.locale('th');

    const leaveTypeNames = data && data.length > 0 && data[0].allLeaveTypes
      ? data[0].allLeaveTypes.map(item => item.name)
      : [];

    const headerRow = worksheet.addRow([
      t("FirstName"),
      t("LastName"),
      t("Position"),
      t("Section"),
      t("Average"),
      ...leaveTypeNames, 
      t("Grade"),
    ]);

    const headerStyle = {
      font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
      alignment: { horizontal: "center", vertical: 'middle' },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '081F5C' } 
      },
    };

    headerRow.eachCell((cell) => {
      cell.style = headerStyle;
      cell.border = {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } }
      };
    });

    headerRow.height = 50;

    const colWidths = [
      { key: "firstname_TH", width: 30 },
      { key: "lastname_TH", width: 30 },
      { key: "positionName", width: 50 },
      { key: "sectionName", width: 30 },
      ...leaveTypeNames.map(leaveTypeName => ({ key: leaveTypeName, width: 50 })),
      { key: "kpiGrade", width: 40 }
    ];

    colWidths.forEach((col, index) => {
      worksheet.getColumn(index + 1).width = col.width;
    });

    if (data) {
      data.forEach((item) => {
        const leaveTypeData = leaveTypeNames.map(leaveTypeName => {
          const leaveType = item.allLeaveTypes.find(leave => leave.name === leaveTypeName);
          return leaveType ? leaveType.Num_Leave : "-";
        });

        const row = [
          item.firstname_TH ? item.firstname_TH : "",
          item.lastname_TH ? item.lastname_TH : "",
          item.positionName ? item.positionName : "",
          item.sectionName ? item.sectionName : "",
          calculateAverage(item).toFixed(2),
          ...leaveTypeData,
          item.kpiGrade ? item.kpiGrade : ""
        ];

        const excelRow = worksheet.addRow(row);
        const contentStyle = { font: { size: 18, name: 'TH SarabunPSK' } };

        excelRow.eachCell((cell) => {
          cell.style = contentStyle;
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
          };
        });
      });
    }

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${t("List")}${t("Grade")}.xlsx`;
      a.click();
      URL.revokeObjectURL(url);
    });
  };

  return (
    <DrawerCustom
      title={t("DownloadData")}
      anchor={"right"}
      open={open}
      onClose={toggleDrawer(false)}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>{t("information")} {t("CompanyPerformanceSummary")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <ButtonBlue
              variant="contained"
              onClick={() => {
                TaxDeductionXlsxFile(employeeData);
              }}
              fullWidth
            >
              {t("Download")}
            </ButtonBlue>
          </Grid>
        </Grid>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogDownload;
