import {
    SUBJOBSTRUCTURE_FETCHING,
    SUBJOBSTRUCTURE_FAILED,
    SUBJOBSTRUCTURE_SUCCESS,
  } from "./types";
  
  import SubJobStructureService from "../services/SubJobStructures.service";
  
  export const getAllSubJobStructure = () => async (dispatch) => {
    try {
      const res = await SubJobStructureService.getAllSubJobStructure();
      if (res) {
        dispatch({
          type: SUBJOBSTRUCTURE_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: SUBJOBSTRUCTURE_FAILED,
      });
      console.log(err);
    }
  };
  
  export const postSubJobStructure = (formData) => async () => {
    try {
      const res = await SubJobStructureService.postSubJobStructure(formData);
      if (res) {
        return res;
      }
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.name) ||
        error.name ||
        error.toString();
      return "Error";
    }
  };
  
  export const putSubJobStructure = (id, formData) => async () => {
    try {
      const res = await SubJobStructureService.putSubJobStructure(id, formData);
      if (res) {
        return res;
      }
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.name) ||
        error.name ||
        error.toString();
      return "Error";
    }
  };
  
  export const deleteSubJobStructure = (id) => async () => {
    try {
      const res = await SubJobStructureService.deleteSubJobStructure(id);
      if (res) {
        return res;
      }
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.name) ||
        error.name ||
        error.toString();
      return "Error";
    }
  };