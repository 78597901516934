import React from 'react';

import {
    Avatar,
    Card,
    styled,
    Typography
} from '@mui/material';

const StyledCard = styled(Card)({
    width: "calc(100% - 32px)",
    minHeight: 197,
    height:"calc(100% - 32px)",
    borderRadius:16,
    display:"flex",
    justifyContent:"center",
    padding: 16,
    boxShadow:"0 2px 4px rgba(0,0,0,0.25)",
    "& .content":{
        height:"100%",
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
        "& .top":{
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            "& .avatar":{
                width: 87,
                height: 87
            },
        },
        "& .name":{
            fontSize: 16,
            fontWeight: 700,
            marginTop: 8,
            marginBottom: 8
        },
        "& .sub-text":{
            fontSize: 14
        }
    }
});

const CardEmployee = (props) => {
    const { employee, handleClickCard } = props;

    return (
        <StyledCard onClick={handleClickCard}>
            <div className="content">
                <div className="top">
                    <Avatar 
                        className="avatar"
                        src={`${process.env.REACT_APP_API_URL}image/vendor/${employee.idVendor}/${employee.personalId}.jpg`}
                        // alt={`${employee.firstname_TH} ${employee.lastname_TH}`}
                    />
                    <Typography 
                        className='name'
                        align="center"
                    >{`${employee.firstname_TH} ${employee.lastname_TH}`}</Typography>
                </div>
                <div>
                    <Typography 
                        className="sub-text"
                        align="center"
                        style={{
                            fontWeight: 500
                        }}
                    >
                        {employee.positionName}
                    </Typography>
                    <Typography 
                        className="sub-text"
                        align="center"
                        style={{
                            color:"#757575"
                        }}
                    >{employee.sectionName}</Typography>
                </div>
            </div>
        </StyledCard>       
    );
};

export default CardEmployee;