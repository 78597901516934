import React, { useEffect, useMemo } from "react";
import DrawerCustom from "../../../shared/general/Drawer";
import { Autocomplete, Box, Button, Grid, MenuItem, TextField, Typography, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../../shared/date/datePicker";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { getAffiliateCompany } from "../../../../../actions/affiliate";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { exportRequestListReport } from "../../../../../actions/exportExcel";
import { getUserCompany } from "../../../../../utils/userData";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .field-label": {
    fontSize: "14px",
    fontWeight: "500",
    paddingBottom: "4px",
  },
}) 

const DrawerRequestList = (props) => {

  const { drawerConfig, onClose } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: affiliateList } = useSelector((state) => state.affiliate);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const validateYupSchema = yup.object({
    company: yup.mixed().nullable().required(t("PleaseSelectInformation")),
    requestType: yup.string().required(t("PleaseSelectInformation")),
    end: yup.mixed().nullable().test("greaterOrEqualStartDate", t("CanNotSelectDateBeforeStart"), (value, context) => {
      return dayjs(value).isSameOrAfter(dayjs(context.parent.start))
    }),
  });

  const useHookForm = useForm({
    defaultValues: {
      start: dayjs().set('date', 1),
      end: dayjs().set('date', dayjs().daysInMonth()),
      company: null,
      // month: new Date(dayjs()),
      requestType: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  const requestTypeList = [
    {
      value: 'requesttime',
      label: `${t("WorkingTimeRequest")}`,
    },
    {
      value: 'overtime',
      label: `${t("Overtime")}`,
    },
    {
      value: 'compensate',
      label: `${t("CompensateRequest")}`,
    },
    {
      value: 'leave',
      label: `${t("LeaveRequest")}`,
    },
  ]

  const renderValueRequestType = (value) => {

    const foundRequestTypeValue = requestTypeList.find(item => item.value === value);

    if(foundRequestTypeValue){
      return <Typography>{foundRequestTypeValue.label}</Typography>
    } else {
      return <Typography>-</Typography>
    }

  }

  useEffect(() => {
    if(drawerConfig.isOpen){
      dispatch(getAffiliateCompany())
      useHookForm.resetField("requestType", {defaultValue: ""})
      useHookForm.resetField("start", {defaultValue: dayjs().set('date', 1)})
      useHookForm.resetField("end", {defaultValue: dayjs().set('date', dayjs().daysInMonth())})
    }
  }, [drawerConfig.isOpen])

  useEffect(() => {
    if(affiliateList && affiliateList.length > 0){
      const foundCompany = affiliateList.find(x => x.idCompany === userProfile.idCompany);
      if(foundCompany){
        useHookForm.resetField("company", {defaultValue: foundCompany})
      } else {
        useHookForm.resetField("company", {defaultValue: null})
      }
    }
  }, [affiliateList]);

  const onSubmit = async (data) => {
    // setIsLoadingExcel(true)

    const result = await dispatch(
      exportRequestListReport({
        start: dayjs(data.start).format("YYYY-MM-DD"),
        end: dayjs(data.end).format("YYYY-MM-DD"),
        idCompany: data.company.idCompany,
        // month: dayjs(data.month).format("YYYY-MM"),
        requestType: data.requestType,
      })
    ).then(res => {
      // setIsLoadingExcel(false)
      return res
    });

    let requestName = "";

    if(data.requestType === "requesttime"){
      requestName = t("workTimeCertificationReport")
    } else if (data.requestType === "overtime"){
      requestName = t("overtimeRequestReport")
    } else if (data.requestType === "compensate"){
      requestName = t("compensateHoursRequestReport")
    } else if (data.requestType === "leave"){
      requestName = t("leaveRequestReport")
    }

    if(result && result.status === 200){
      const excelBlob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(excelBlob);
        downloadLink.download = `UniHR ${getUserCompany(data.company)} ${requestName} ${t("Month")} ${dayjs(data.month).format("MMMM BBBB")} ${dayjs().format("DD MMMM BBBB HH.mm.ss")} ${t("Unit.Minute")}.xlsx`;
        downloadLink.click();
    } else {

    }
  }

  return (
    <DrawerCustom
      title={`${t("RequestReport")}`}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>

            <Grid item xs={12} sm={6}>
              <Typography color="text.secondary" fontSize="14px" fontWeight="500" marginBottom="8px">{t("StartDate")}</Typography>
              <Controller
                control={useHookForm.control}
                name="start"
                render={({field, fieldState}) => (
                  <DatePickerCustom
                    {...field}
                    // minDate={dayjs().subtract(1, 'year')}
                    // maxDate={dayjs()}
                    inputFormat="DD MMMM YYYY"
                    value={field.value}
                    views={['day', 'month', 'year']}
                    openTo={'day'}
                    inputProps={{
                      readOnly: true
                    }}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                      if(dayjs(useHookForm.getValues("end")).diff(dayjs(newValue), 'day') > 366){
                        useHookForm.setValue("end", dayjs(newValue).add(366, 'day'))
                      }
                      useHookForm.trigger("end")
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        error={fieldState.error? true: false}
                        // helperText={
                        //   errors.endDate && errors.endDate.message
                        // }
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography color="text.secondary" fontSize="14px" fontWeight="500" marginBottom="8px">{t("EndDate")}</Typography>
              <Controller
                control={useHookForm.control}
                name="end"
                render={({field, fieldState}) => (
                  <DatePickerCustom
                    {...field}
                    minDate={useHookForm.getValues("start")}
                    maxDate={dayjs(useHookForm.getValues("start")).add(366, 'day')}
                    // minDate={dayjs().subtract(1, 'year')}
                    // maxDate={dayjs()}
                    inputFormat="DD MMMM YYYY"
                    value={field.value}
                    views={['day', 'month', 'year']}
                    openTo={'day'}
                    inputProps={{
                      readOnly: true
                    }}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        error={fieldState.error? true: false}
                        // helperText={
                        //   errors.endDate && errors.endDate.message
                        // }
                      />
                    )}
                  />
                )}
              />
            </Grid>

            {/* <Grid item xs={12}>
              <Typography color="text.secondary" fontSize="14px" fontWeight="500" marginBottom="8px">{t("SearchDate")}</Typography>
              <Controller
                control={useHookForm.control}
                name="month"
                render={({field, fieldState}) => (
                  <DatePickerCustom
                    {...field}
                    minDate={dayjs().subtract(1, 'year')}
                    maxDate={dayjs()}
                    inputFormat="MMMM YYYY"
                    value={field.value}
                    views={['year', 'month']}
                    openTo={'month'}
                    inputProps={{
                      readOnly: true
                    }}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                  />
                )}
              />
            </Grid> */}

            <Grid item xs={12}>
              <Typography color="text.secondary" fontSize="14px" fontWeight="500" marginBottom="8px">{t("Company")}</Typography>
              <Controller
                name="company"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <Autocomplete
                    options={affiliateList? affiliateList: []}
                    getOptionLabel={(option) => `${getUserCompany(option)}`}
                    isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        placeholder={`${t("SelectCompany")}`}
                        onBlur={field.onBlur}
                        helperText={fieldState.error? fieldState.error.message: null}
                        error={fieldState.error? true: false}
                      />
                    )}
                    value={field.value}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    noOptionsText={`${t("NoData")}`}
                    disableClearable
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography color="text.secondary" fontSize="14px" fontWeight="500" marginBottom="8px">{t("RequestType")}</Typography>
              <Controller
                name="requestType"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    select
                    helperText={fieldState.error? fieldState.error.message: null}
                    error={fieldState.error? true: false}
                    SelectProps={{
                      displayEmpty: true,
                      renderValue: (selected) => {
                        if(selected.length === 0){
                          return <Typography color="text.secondary">{t("SelectTypeOfRequest")}</Typography>
                        } else {
                          return renderValueRequestType(selected)
                        }
                      }
                    }}
                  >
                    <MenuItem value="" disabled>{t("SelectTypeOfRequest")}</MenuItem>
                    {requestTypeList.map(item => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12} container alignItems="center" justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose}>{t("Cancel")}</ButtonBlue>
              <ButtonBlue
                type="submit"
                variant="outlined"
                startIcon={<DownloadRoundedIcon />}
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Download")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerRequestList;