import React, { useState, useEffect } from "react";
import { Grid, FormControl, MenuItem, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import Autocomplete from "@mui/material/Autocomplete";
import { addEmemoRequestForm } from "../../../../../actions/ememo";
import { useDispatch, useSelector } from "react-redux";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";
import { getManager } from "../../../../../actions/manager";

const StyledCardContent = styled("div")({
  padding: 24,
  borderRadius: 16,
});

const StyledReactQuill = styled(ReactQuill)({
  "& .ql-container": {
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
  },
  "& .ql-toolbar": {
    borderRadius: "8px 8px 0 0",
  },
  "& .ql-editor": {
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    minHeight: "150px",
  },
});

const RequestForm = ({ setRequestFormVisible }) => {
  const [detail, setDetail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [creatorError, setCreatorError] = useState(false);
  const [telError, setTelError] = useState(false);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: managerList } = useSelector((state) => state.manager);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    idEmployees: userProfile.idEmployees,
    creator: "",
    document_no: "",
    requester: "",
    created_date: dayjs().format("YYYY-MM-DD"),
    subject: "",
    tel: "",
    email: "",
    details: "",
    status: "Waiting Approval",
    idManager: null,
    idInitial1: null,
    idInitial2: null,
    idInitial3: null,
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [managers, setManagers] = useState([]);
  const [disableInitial1, setDisableInitial1] = useState(true);
  const [disableInitial2, setDisableInitial2] = useState(true);
  const [disableInitial3, setDisableInitial3] = useState(true);

  useEffect(() => {
    dispatch(getManager());
  }, [dispatch]);

  useEffect(() => {
    if (managerList) {
      setManagers(managerList);
    }
  }, [managerList]);

  useEffect(() => {
    setDisableInitial1(!formData.idManager);
    setDisableInitial2(!formData.idInitial1);
    setDisableInitial3(!formData.idInitial2);
  }, [formData.idManager, formData.idInitial1, formData.idInitial2]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "tel") {
      const telRegex = /^\d{0,10}$/;
      if (!telRegex.test(value)) {
        setTelError(true);
      } else {
        setTelError(false);
      }
    }
    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDetailChange = (value) => {
    setFormData({
      ...formData,
      details: value,
    });
  };

  const handleGoBack = () => {
    setRequestFormVisible(false);
    window.location.reload();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = [
      "creator",
      "document_no",
      "requester",
      "subject",
      "email",
      "tel",
      "details",
      "idManager",
      "classificationLevels",
      "priority",
      "eMemoType",
    ];
    const missingFields = requiredFields.filter((field) => !formData[field]);
    if (missingFields.length > 0) {
      console.log("missing", missingFields);
      if (missingFields === "creator") {
        setCreatorError(true);
      }
      dispatch(
        openNotificationAlert({
          type: "error",
          message: `กรุณากรอกข้อมูลให้ครบถ้วน`,
        })
      );
      return;
    } else {
      try {
        const currentStep = formData.idInitial1 ? "initial1" : "approver";

        const requestData = {
          ...formData,
          currentStep,
        };
        dispatch(
          openNotificationAlert({
            type: "success",
            message: "Request saved successfully",
          })
        );
        dispatch(addEmemoRequestForm(requestData));
        handleGoBack();
      } catch (error) {
        dispatch(
          openNotificationAlert({
            type: "error",
            message: "An error occurred while saving the request",
          })
        );
      }
    }
  };

  const handleManagerChange = (event, newValue) => {
    setFormData({
      ...formData,
      idManager: newValue ? newValue.idEmployees : null,
    });
  };

  const handleInitial1Change = (event, newValue) => {
    setFormData({
      ...formData,
      idInitial1: newValue ? newValue.idEmployees : null,
    });
  };

  const handleInitial2Change = (event, newValue) => {
    setFormData({
      ...formData,
      idInitial2: newValue ? newValue.idEmployees : null,
    });
  };

  const handleInitial3Change = (event, newValue) => {
    setFormData({
      ...formData,
      idInitial3: newValue ? newValue.idEmployees : null,
    });
  };

  const filterOptions = (options, fieldToExclude) => {
    return options.filter(
      (option) =>
        option.idEmployees !== formData[fieldToExclude] &&
        option.idEmployees !== formData.idManager &&
        option.idEmployees !== formData.idInitial1 &&
        option.idEmployees !== formData.idInitial2 &&
        option.idEmployees !== formData.idInitial3
    );
  };

  return (
    <StyledCardContent>
      <Typography variant="h6" style={{ marginTop: 16, marginBottom: 8 }}>
        Requester Information
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextFieldTheme
              label="Creator"
              name="creator"
              fullWidth
              value={formData.creator}
              onChange={handleChange}
              error={creatorError}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldTheme
              label="Document No."
              name="document_no"
              fullWidth
              value={formData.document_no}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldTheme
              label="Requester"
              name="requester"
              fullWidth
              value={formData.requester}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldTheme
              label="Created Date"
              name="created_date"
              value={formData.created_date}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldTheme
              label="Email"
              name="email"
              value={formData.email}
              fullWidth
              onChange={handleChange}
              error={emailError}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldTheme
              label="Tel."
              name="tel"
              value={formData.tel}
              fullWidth
              onChange={handleChange}
              error={telError}
            />
          </Grid>
        </Grid>
        <Typography variant="h6" style={{ marginTop: 16, marginBottom: 8 }}>
          Request Detail
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextFieldTheme
                select
                label="Classification Levels"
                name="classificationLevels"
                onChange={handleChange}
              >
                <MenuItem value="Public">Public</MenuItem>
                <MenuItem value="Internal">Internal</MenuItem>
                <MenuItem value="Confidential">Confidential</MenuItem>
                <MenuItem value="Secret">Secret</MenuItem>
              </TextFieldTheme>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextFieldTheme
                select
                label="Priority"
                name="priority"
                onChange={handleChange}
              >
                <MenuItem value="High">High</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="Low">Low</MenuItem>
              </TextFieldTheme>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldTheme
              label="Subject"
              name="subject"
              fullWidth
              value={formData.subject}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextFieldTheme
                select
                label="e-Memo Type"
                name="eMemoType"
                onChange={handleChange}
              >
                <MenuItem value="General">General</MenuItem>
                <MenuItem value="Request">Request</MenuItem>
                <MenuItem value="Report">Report</MenuItem>
                <MenuItem value="Meeting">Meeting</MenuItem>
                <MenuItem value="Policy">Policy</MenuItem>
              </TextFieldTheme>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <StyledReactQuill
              value={formData.details}
              onChange={handleDetailChange}
              placeholder="Enter the details here"
              style={{ height: "150px", marginBottom: 50 }}
            />
          </Grid>
        </Grid>
        <Typography variant="h6" style={{ marginTop: 16, marginBottom: 8 }}>
          Approval Detail
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ marginTop: 16, marginBottom: 8 }}
        >
          Select Approver
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              options={filterOptions(managers, "idManager")}
              getOptionLabel={(option) =>
                `${option.firstname_TH} ${option.lastname_TH}`
              }
              onChange={handleManagerChange}
              renderInput={(params) => (
                <TextFieldTheme
                  {...params}
                  label="Search name..."
                  name="searchApprovalName"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Typography
          variant="subtitle1"
          style={{ marginTop: 24, marginBottom: 8 }}
        >
          Step by Step Initial
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              options={filterOptions(managers, "idInitial1")}
              getOptionLabel={(option) =>
                `${option.firstname_TH} ${option.lastname_TH}`
              }
              onChange={handleInitial1Change}
              renderInput={(params) => (
                <TextFieldTheme
                  {...params}
                  label="Initial #1"
                  name="initial1"
                  fullWidth
                  disabled={disableInitial1}
                />
              )}
              disabled={disableInitial1}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              options={filterOptions(managers, "idInitial2")}
              getOptionLabel={(option) =>
                `${option.firstname_TH} ${option.lastname_TH}`
              }
              onChange={handleInitial2Change}
              renderInput={(params) => (
                <TextFieldTheme
                  {...params}
                  label="Initial #2"
                  name="initial2"
                  fullWidth
                  disabled={disableInitial2}
                />
              )}
              disabled={disableInitial2}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              options={filterOptions(managers, "idInitial3")}
              getOptionLabel={(option) =>
                `${option.firstname_TH} ${option.lastname_TH}`
              }
              onChange={handleInitial3Change}
              renderInput={(params) => (
                <TextFieldTheme
                  {...params}
                  label="Initial #3"
                  name="initial3"
                  fullWidth
                  disabled={disableInitial3}
                />
              )}
              disabled={disableInitial3}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 16 }}>
          <Grid item>
            <ButtonBlue variant="contained" type="submit">
              Submit
            </ButtonBlue>
          </Grid>
          <Grid item>
            <ButtonBlue
              variant="text"
              onClick={() => setRequestFormVisible(false)}
            >
              Back to My e-Memo
            </ButtonBlue>
          </Grid>
        </Grid>
      </form>
    </StyledCardContent>
  );
};

export default RequestForm;
