import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  FormControl,
  Grid,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateTeamObjectiveByIdTeamObjective } from "../../../../../../../actions/okrs";

const DetailTeam = ({
  id,
  open,
  handleClose,
  data,
  formData,
  setFormData,
  setOpenDialog,
  setTriggerFetch,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    if (open) {
      setFormData({
        tacticName: data && data.tacticName,
        measureEvidence: data && data.measureEvidence,
        commitQuality: data && data.commitQuality,
        measureDataType: data && data.measureDataType,
      });
    }
  }, [open]);

  useEffect(() => {
    if (data && data.measureDataType) {
      setSelectedValue(String(data.measureDataType));
    }
  }, [data]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if (name === "measureDataType") {
      setSelectedValue(value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      updateTeamObjectiveByIdTeamObjective(
        id,
        data && data.idTeamKeyResult !== undefined
          ? data.idTeamKeyResult
          : null,
        formData,
        setTriggerFetch
      )
    );

    handleClose();
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl>
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle>{t("Descriptions")}</DialogTitle>
          <DialogContent>
            <TextField
              name="tacticName"
              label={t("Strategy")}
              value={formData.tacticName}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              name="measureEvidence"
              label={t("MeasurementData")}
              value={formData.measureEvidence}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              name="commitQuality"
              label={t("ObjectiveMeasurementData")}
              value={formData.commitQuality}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              select
              name="measureDataType"
              label={t("TypeMeasurementData")}
              value={selectedValue}
              onChange={handleChange}
              fullWidth
              margin="normal"
            >
              <MenuItem value="1">{t("Number")}</MenuItem>
              <MenuItem value="2">{t("Explanation")}</MenuItem>
            </TextField>

            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ marginTop: "10px" }}
            >
              <Grid item>
                <Button onClick={() => setOpenDialog(false)} color="primary">
                  {t("Close")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                >
                  {t("Edit")}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </FormControl>
    </form>
    // <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
    //   <DialogTitle>{`${t("Descriptions")}`}</DialogTitle>
    //   <DialogContent>
    //     <Typography fontSize="14px">
    //       <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
    //         "Strategy"
    //       )}`}</span>
    //       {data.tacticName}
    //     </Typography>
    //     <Typography fontSize="14px" paddingTop="16px">
    //       <span style={{ color: "#919eab", paddingRight: "8px" }}>
    //         {`${t("MeasurementData")}`}
    //       </span>
    //       {data.measureEvidence}
    //     </Typography>
    //     <Typography fontSize="14px" paddingTop="16px">
    //       <span style={{ color: "#919eab", paddingRight: "8px" }}>
    //         {`${t("ObjectiveMeasurementData")}`}
    //       </span>
    //       {data.commitQuality}
    //     </Typography>
    //     <Typography fontSize="14px" paddingTop="16px">
    //       <span style={{ color: "#919eab", paddingRight: "8px" }}>
    //         {`${t("TypeMeasurementData")}`}
    //       </span>
    //       {data.measureDataType === 1
    //         ? `${t("Number")}`
    //         : `${t("Explanation")}`}
    //     </Typography>
    //     {data.description && (
    //       <Typography fontSize="14px" paddingTop="16px" whiteSpace="pre-line">
    //         <span style={{ color: "#919eab", paddingRight: "8px" }}>
    //           {`${t("Descriptions")}`}
    //         </span>
    //         {data.description}
    //       </Typography>
    //     )}
    //   </DialogContent>
    // </Dialog>
  );
};

export default DetailTeam;
