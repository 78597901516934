import React, { useState } from "react";
import {
  Box, 
  Breadcrumbs,
  Container,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Late from "./late";
import Absent from "./absent";
import { Link } from "react-router-dom";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginTop: 24,
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    color: "#46cbe2",
    border: "1px solid #46cbe2",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#247785",
      backgroundColor: "#247785",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#46cbe2",
      "&:hover": {
        borderColor: "#247785",
        backgroundColor: "#247785",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #46cbe2",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
  },
}));

const Late_absent = () => {
  const [view, setView] = useState("absent");
  const { t, i18n } = useTranslation();

  const handleChangeView = (event, newView) => {
    if (newView) {
      setView(newView);
    }
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Link
                style={{ textDecoration: "none", color: "inherit" }}
                to={"/employees-report"}
            >
                {`${t("AllReports")}`}
            </Link>
            <Typography color="text.primary">{`${t("SummaryOfLeaveAndLate")}`}</Typography>
        </Breadcrumbs>
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            {`${t("SummaryOfLeaveAndLate")}`}
          </Typography>
        </div>

        <StyledToggleButtonGroup
          value={view}
          exclusive
          onChange={handleChangeView}
          aria-label="view"
          size="small"
        >
          <ToggleButton
            name="status"
            value="absent"
            aria-label="absent"
            size="small"
            style={{ minWidth: 150, marginRight: 12 }}
          >
            {`${t("Absent")}`}
          </ToggleButton>
          <ToggleButton
            name="status"
            value="late"
            aria-label="late"
            size="small"
            style={{ minWidth: 150, marginRight: 12 }}
          >
            {`${t("Late")}`}
          </ToggleButton>
        </StyledToggleButtonGroup>

        {view === "absent" && <Absent />}
        {view === "late" && <Late />}
      </Container>
    </StyledRoot>
  );
};

export default Late_absent;
