import { Avatar, Card, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Fab } from "@mui/material";

import LocationOnIcon from "@mui/icons-material/LocationOn";

import { standardMenu } from "../../assets/data/menu-app";

import { getStandardMenuCompany } from "../../../../actions/company";
import { useTranslation } from "react-i18next";

const StyledWrapMenuApp = styled("div")(({ }) => ({
  position: "relative",
  "& .header": {
    marginBottom: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontWeight: 500,
      "& span": {
        letterSpacing: 0.5,
      },
    },
  },
  "& .app-list": {
    display: "grid",
    gridTemplateRows: "1fr auto auto",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridAutoRows: 0,
    minWidth: 350,
    columnGap: 8,
    ["@media only screen and (max-width: 600px)"]: {
      gridTemplateRows: "1fr auto auto",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    ["@media only screen and (min-width:600px)"]: {
      gridTemplateRows: "1fr auto auto",
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    ["@media only screen and (min-width:768px)"]: {
      gridTemplateRows: "1fr auto auto",
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    ["@media only screen and (min-width:992px)"]: {
      gridTemplateRows: "1fr auto auto",
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    ["@media only screen and (min-width:1200px)"]: {
      gridTemplateRows: "1fr auto auto",
      gridTemplateColumns: "repeat(4, 1fr)",
    },

    "& .app-item": {
      marginTop: 24,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const StyledCard = styled(Card)({
  //width: 150,
  boxShadow: "none",
  borderRadius: 16,
  border: "1px solid #919eab3d",
  margin: 8,
  "&.gray": {
    filter: "grayscale(100%)",
  },
  "&.normal": {
    "&:hover": {
      cursor: "pointer",
      transition:
        "color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,transform 0.15s ease-in-out",
      transform: "translateY(-5px)",
    },
  },
  "& .inner": {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    "& div": {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
    },
    "& .MuiTypography-subtitle": {
      fontSize: 22,
      lineHeight: 1.2,
    },
    "& .MuiTypography-caption": {
      lineHeight: 1.2,
      fontSize: 14,
    },
    "& .MuiAvatar-root": {
      width: 100,
      height: 100,
      marginBottom: 16,
    },
  },
});

const StyledGrid = styled(Grid)({
  ["@media only screen and (max-width: 600px)"]: {
    height: 480,
  },
  ["@media only screen and (max-width: 375px)"]: {
    height: 368,
  },
  ["@media only screen and (max-width: 360px)"]: {
    height: 448,
  },
  ["@media only screen and (min-width: 600px)"]: {
    //height: 480,
  },
});

const StyledFab = styled(Fab)({
  display: "none",
  ["@media only screen and (max-width: 600px)"]: {
    display: "inline-flex",
    position: "absolute",
    right: 8,
    bottom: 8,
  },
});

const MenuApp = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: standardMenuCompany } = useSelector(
    (state) => state.standardMenu
  );
  const [isPWA, setIsPWA] = useState(false);

  useEffect(() => {
    dispatch(getStandardMenuCompany());
  }, []);

  useEffect(() => {
    let displayMode = "";

    if (window.matchMedia("(display-mode: standalone)").matches) {
      displayMode = "standalone";
      setIsPWA(true);
    } else {
      displayMode = "!!!!!!";
    }
  }, []);

  const handleOpenGPS = () => {
    history.push("/gps-check?source=pwa");
  };

  return (
    <Fragment>
      {currentUser && (
        <StyledWrapMenuApp>
          <div className="header">
            <Typography color="text.secondary">{`${t(
              "ShortcutMenu"
            )}`}</Typography>
          </div>
          <StyledGrid container spacing={2}>
            {standardMenuCompany &&
              standardMenu
                .filter((x) => standardMenuCompany[4].indexOf(x.key) > -1)
                .map((value, index) => (
                  <Grid item xs={12} sm={6} md={3}>
                    <StyledCard
                      key={index}
                      onClick={() => {
                        if (value.class !== "gray") {
                          if (value.state) {
                            history.push(value.link, value.state);
                          } else {
                            history.push(value.link);
                          }
                        }
                      }}
                      className={value.class}
                    >
                      <div className="inner">
                        <Avatar variant="rounded" src={value.image} />
                        <div>
                          <Typography variant="subtitle" gutterBottom>
                            {t(value.nameTrans)}
                          </Typography>
                        </div>
                      </div>
                    </StyledCard>
                  </Grid>
                ))}
          </StyledGrid>
          {isPWA && (
            <StyledFab color="primary" aria-label="gps" onClick={handleOpenGPS}>
              <LocationOnIcon />
            </StyledFab>
          )}
        </StyledWrapMenuApp>
      )}
    </Fragment>
  );
};

export default MenuApp;
