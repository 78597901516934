import React from "react";
import { styled } from "@mui/material/styles";
import {
  Divider,
  Grid,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
  Box,
  Chip,
  Avatar,
} from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import NavigationIcon from "@mui/icons-material/Navigation";
import EventIcon from "@mui/icons-material/Event";
import LabelIcon from "@mui/icons-material/Label";
import PeopleIcon from "@mui/icons-material/People";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import UpdateIcon from "@mui/icons-material/Update";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import dayjs from "dayjs";

const StyledCard = styled(Card)({
  // width: "100%",
  height: "100%",
  margin: "16px",
  boxShadow: 3,
  borderRadius: 4,
});

const StyledCardMedia = styled(CardMedia)({
  height: 140,
});

const WrapDetail = styled("div")({
  display: "flex",
  alignItems: "center",
  marginBottom: 8,
});

const ChipActivityStatus = ({ status }) => {
  switch (status) {
    case "open":
      return (
        <Chip
          icon={<PlayCircleIcon />}
          color="success"
          size="small"
          label={status}
        />
      );
    case "close":
      return (
        <Chip
          icon={<RemoveCircleIcon />}
          color="error"
          size="small"
          label={status}
        />
      );
    case "update":
      return (
        <Chip
          icon={<UpdateIcon />}
          color="warning"
          size="small"
          label={status}
        />
      );
    case "cancel":
      return (
        <Chip icon={<CloseIcon />} color="error" size="small" label={status} />
      );
    case "rewarded":
      return (
        <Chip icon={<DoneIcon />} color="info" size="small" label={status} />
      );
    default:
      return (
        <Chip
          icon={<CloseIcon />}
          color="default"
          size="small"
          label={"no status"}
        />
      );
  }
};

function CardActivity({ data }) {
  return (
    <Grid item xs={12} sm={6} md={4} xl={3}>
      <StyledCard fullWidth key={data.idActivity}>
        <StyledCardMedia image={data.image} title="Contemplative Reptile" />
        <CardContent
          style={{
            paddingTop: 35,
            paddingBottom: 0,
            position: "relative",
          }}
        >
          <Typography gutterBottom variant="h6">
            {data.name}
          </Typography>
          <WrapDetail>
            <PlaceIcon color="disabled" />
            <Typography
              noWrap
              variant="body2"
              color="textSecondary"
              gutterBottom
              marginLeft={1}
            >
              {data.location}
            </Typography>
            {data.lat && data.lng && (
              <IconButton
                color="primary"
                href={`https://www.google.com/maps/search/?api=1&query=${data.lat}%2C${data.lng}`}
                target="_blank"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <NavigationIcon />
              </IconButton>
            )}
          </WrapDetail>
          <WrapDetail>
            <EventIcon color="disabled" />
            <Typography
              variant="body2"
              color="textSecondary"
              gutterBottom
              marginLeft={1}
              marginRight={1}
            >
              {`เริ่ม: ${dayjs(data.startDate).format("D MMM YYYY")}`}
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography
              variant="body2"
              color="textSecondary"
              gutterBottom
              marginLeft={1}
            >
              {`สิ้นสุด: ${dayjs(data.endDate).format("D MMM YYYY")}`}
            </Typography>
          </WrapDetail>
          <WrapDetail>
            <PeopleIcon color="disabled" />
            <Typography
              variant="body2"
              color="textSecondary"
              gutterBottom
              marginLeft={1}
              marginRight={1}
            >
              สถานะกิจกรรม :
            </Typography>
            <ChipActivityStatus status={data.status} />
          </WrapDetail>
          <WrapDetail>
            <LabelIcon style={{ color: "#4caf50" }} />
            <Typography
              variant="body2"
              style={{ color: "#4caf50" }}
              gutterBottom
              marginLeft={1}
            >
              {data.status === "rewarded"
                ? "ได้รับรางวัลแล้ว"
                : "ลงทะเบียนแล้ว"}
            </Typography>
          </WrapDetail>
          <WrapDetail>
            {data.coin && (
              <>
                {/* <SpecialIcon idCoinType={value.coin.idCoinType} width={40} /> */}
                <Avatar
                  src={data.coin.imageURL}
                  alt={data.coin.coinName}
                  sx={{ width: 40, height: 40 }}
                />
                <Typography variant="h6" style={{ marginLeft: 8 }}>
                  {data.coin.amount}
                </Typography>
              </>
            )}
            {data.coin && data.specialCoin && (
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  backgroundColor: "white",
                  margin: "0 10px",
                }}
              />
            )}
            {data.specialCoin && (
              <>
                <Avatar
                  src={data.specialCoin.imageURL}
                  alt={data.specialCoin.coinName}
                  sx={{ width: 40, height: 40 }}
                />
                <Typography variant="h6" style={{ marginLeft: 8 }}>
                  {data.specialCoin.amount}
                </Typography>
              </>
            )}
          </WrapDetail>
        </CardContent>
      </StyledCard>
    </Grid>
  );
}

export default CardActivity;
