import { width } from '@mui/system';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ExcelJS from 'exceljs';

export const PayrollListMonthXlsx = async (data, t, selectedLevel) => {
    try {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(`${t("AppMenu.Payroll")} ${selectedLevel === 1 ? t("Department") : t("Section")}`);
            // ALabel, DLabel
            const { ALabel, DLabel } = data.reduce((acc, cur) => {
                // (A..), (D..)
                const { 
                    idDepartment,
                    departmentCode,
                    departmentName,
                    companyName,
                    NumEmployees,
                    SUM_Salary,
                    SUM_Tax,
                    SUM_SocialSecurityEmp,
                    SUM_PFemp,
                    SUM_PFcom,
                    SUM_absent,
                    SUM_late,
                    SUM_leave,
                    SUM_OT,
                    SUM_ShiftFee,
                    SUM_Addition,
                    totalEarnings,
                    SUM_Deduction,
                    total_net,
                    ...ret } = cur;
                const labels = Object.keys(ret);
                const a = [];
                const d = [];
                labels.forEach((key) => {
                    if (key.includes("(A")) {
                        a.push(key)
                    } else if (key.includes("(D")) {
                        d.push(key)
                    }
                });
                return {
                    ALabel: [...new Set([...(acc.ALabel), ...a])],
                    DLabel: [...new Set([...(acc.DLabel), ...d])]
                }
            }, {
                ALabel: [],
                DLabel: []
            });
            // console.log(ALabel)
            // console.log(DLabel)


        const headerRow = worksheet.addRow([
            t("No."),
            selectedLevel === 1 ? t("Department") : t("Section"),
            t("Employee"),
            t("Salary"),
            "OT",
            t("ShiftPayTotal"),
            ...ALabel,
            t("TotalIncome"),
            t("DeductLate"),
            t("Absent"),
            t("DeductLeave"),
            t("Tax"),
            t("ProvidentFund"),
            t("SocialSecurity"),
            ...DLabel,
            t("TotalDeduction"),
            t("NetIncome"),
        ]);

        headerRow.height = 50;

        const headerStyle1 = {
            font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
            alignment: { horizontal: "center", vertical: 'middle' },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '002060' }
            },
        };

        const headerStyle2 = {
            font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
            alignment: { horizontal: "center", vertical: 'middle' },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'C81A00' }
            },
        };

        const headerStyle3 = {
            font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
            alignment: { horizontal: "center", vertical: 'middle' },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '0B6623' }
            },
        };

        const headerStyles = [
            headerStyle1, headerStyle1, headerStyle1, headerStyle1, headerStyle1, headerStyle1,...(ALabel.map(() => headerStyle3)), headerStyle1,
            headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2, headerStyle2,...(DLabel.map(() => headerStyle2)), headerStyle2
            // ...(ALabel.map(() => headerStyle2)),
        ];

        headerRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
            if (headerStyles[colNumber - 1]) {
                cell.style = headerStyles[colNumber - 1];
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            }
        });

        const colWidths = [
            { key: "NO.", width: 40 },
            { key: "Department", width: 40 },
            { key: "Employee", width: 40 },
            { key: "Salary", width: 40 },
            { key: "OT", width: 40 },
            { key: "กะการทำงาน", width: 40 },
            ...(ALabel.map((item) => ({ key: item, width: 60 }))),
            { key: "Total Income", width: 40 },
            { key: "หักมาสาย", width: 40 },
            { key: "ขาดงาน", width: 40 },
            { key: "หักลางาน", width: 40 },
            { key: "TAX", width: 40 },
            { key: "Provident Funt", width: 40 },
            { key: "Social Security", width: 40 },
            ...(DLabel.map((item) => ({ key: item, width: 60 }))),
            { key: "Total Deduction", width: 40 },
            { key: "Net Income", width: 40 },
        ];

        headerRow.height = 50;

        colWidths.forEach((col, index) => {
            worksheet.getColumn(index + 1).width = col.width;
        });

        data && data.forEach((item, index) => {
            const row = [
                index + 1,
                selectedLevel === 1 ? (item.departmentName ? item.departmentName : "-") : (item.sectionName ? item.sectionName : "-"),
                item.NumEmployees ? item.NumEmployees : "-",
                item.SUM_Salary ? item.SUM_Salary.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-",
                item.SUM_OT ? item.SUM_OT.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-",
                item.SUM_ShiftFee ? item.SUM_ShiftFee.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-",
                ...(ALabel.map((key) => item[key] ? item[key].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-")),
                item.totalEarnings ? item.totalEarnings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-",
                // item.SUM_Addition ? item.SUM_Addition.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-",
                item.SUM_late ? item.SUM_late.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-",
                item.SUM_absent ? item.SUM_absent.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-",
                item.SUM_leave ? item.SUM_leave.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-", //หักวันลา
                item.SUM_Tax ? item.SUM_Tax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-",
                item.SUM_PFemp ? item.SUM_PFemp.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-",
                item.SUM_SocialSecurityEmp ? item.SUM_SocialSecurityEmp.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-",
                ...(DLabel.map((key) => item[key] ? item[key].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-")),
                item.SUM_Deduction ? item.SUM_Deduction.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-",
                item.total_net ? item.total_net.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-",
            ];

            const excelRow = worksheet.addRow(row);

            const contentStyle = { font: { size: 18, name: 'TH SarabunPSK' } };

            excelRow.eachCell((cell) => {
                cell.style = contentStyle;
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            });

        });

        const summaryRow = worksheet.addRow([
            '',
            t("Total"),
            data.reduce((acc, item) => acc + (item.NumEmployees || 0), 0),
            data.reduce((acc, item) => acc + (item.SUM_Salary || 0), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            data.reduce((acc, item) => acc + (item.SUM_OT || 0), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            data.reduce((acc, item) => acc + (item.SUM_ShiftFee || 0), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            ...(ALabel.map((key) => data.reduce((acc, item) => acc + (item[key] || 0), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","))),
            data.reduce((acc, item) => acc + (item.totalEarnings || 0), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            // data.reduce((acc, item) => acc + (item.SUM_Addition || 0), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            data.reduce((acc, item) => acc + (item.SUM_late || 0), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            data.reduce((acc, item) => acc + (item.SUM_absent || 0), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            data.reduce((acc, item) => acc + (item.SUM_leave || 0), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            data.reduce((acc, item) => acc + (item.SUM_Tax || 0), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            data.reduce((acc, item) => acc + (item.SUM_PFemp || 0), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            data.reduce((acc, item) => acc + (item.SUM_SocialSecurityEmp || 0), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            ...(DLabel.map((key) => data.reduce((acc, item) => acc + (item[key] || 0), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","))),
            data.reduce((acc, item) => acc + (item.SUM_Deduction || 0), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            data.reduce((acc, item) => acc + (item.total_net || 0), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        ]);

        const contentStyle = {
            font: { size: 18, name: 'TH SarabunPSK', bold: true },
        };

        summaryRow.eachCell((cell, colNumber) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${t("AppMenu.Payroll")} ${selectedLevel === 1 ? t("Department") : t("Section")}.xlsx`;
            a.click();
            URL.revokeObjectURL(url);
        });
    } catch (error) {
        console.error(error);
    }
};