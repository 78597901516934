import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from "@pdf-lib/fontkit";
import dayjs from 'dayjs';

function CheckNumber(Number) {
    var decimal = false;
    Number = Number.toString();
    Number = Number.replace(/ |,|บาท|฿/gi, '');
    for (var i = 0; i < Number.length; i++) {
        if (Number[i] == '.') {
            decimal = true;
        }
    }
    if (decimal == false) {
        Number = Number + '.00';
    }
    return Number
}

function ArabicNumberToText(Number) {
    var Number = CheckNumber(Number);
    var NumberArray = new Array("ศูนย์", "หนึ่ง", "สอง", "สาม", "สี่", "ห้า", "หก", "เจ็ด", "แปด", "เก้า", "สิบ");
    var DigitArray = new Array("", "สิบ", "ร้อย", "พัน", "หมื่น", "แสน", "ล้าน");
    var BahtText = "";
    if (isNaN(Number)) {
        return "ข้อมูลนำเข้าไม่ถูกต้อง";
    } else {
        if ((Number - 0) > 9999999.9999) {
            return "ข้อมูลนำเข้าเกินขอบเขตที่ตั้งไว้";
        } else {
            Number = Number.split(".");
            if (Number[1].length > 0) {
                Number[1] = Number[1].substring(0, 2);
            }
            var NumberLen = Number[0].length - 0;
            for (var i = 0; i < NumberLen; i++) {
                var tmp = Number[0].substring(i, i + 1) - 0;
                if (tmp != 0) {
                    if ((i == (NumberLen - 1)) && (tmp == 1)) {
                        BahtText += "เอ็ด";
                    } else
                        if ((i == (NumberLen - 2)) && (tmp == 2)) {
                            BahtText += "ยี่";
                        } else
                            if ((i == (NumberLen - 2)) && (tmp == 1)) {
                                BahtText += "";
                            } else {
                                BahtText += NumberArray[tmp];
                            }
                    BahtText += DigitArray[NumberLen - i - 1];
                }
            }
            BahtText += "บาท";
            if ((Number[1] == "0") || (Number[1] == "00")) {
                BahtText += "ถ้วน";
            } else {
                var DecimalLen = Number[1].length - 0;
                for (var i = 0; i < DecimalLen; i++) {
                    var tmp = Number[1].substring(i, i + 1) - 0;
                    if (tmp != 0) {
                        if ((i == (DecimalLen - 1)) && (tmp == 1)) {
                            BahtText += "เอ็ด";
                        } else
                            if ((i == (DecimalLen - 2)) && (tmp == 2)) {
                                BahtText += "ยี่";
                            } else
                                if ((i == (DecimalLen - 2)) && (tmp == 1)) {
                                    BahtText += "";
                                } else {
                                    BahtText += NumberArray[tmp];
                                }
                        BahtText += DigitArray[DecimalLen - i - 1];
                    }
                }
                BahtText += "สตางค์";
            }
            return BahtText;
        }
    }
}

function calculateAge(birthday) {
    const birthDate = new Date(birthday);
    const currentDate = new Date();

    const ageInMilliseconds = currentDate - birthDate;
    const ageInYears = new Date(ageInMilliseconds).getUTCFullYear() - 1970;

    return ageInYears;
}

function TextInPdf(page, data, x, y, size, font, color) {
    page.drawText(`${data}`, {
        x: x,
        y: y,
        size: size,
        font: font,
        color: color
    });
}

export const EmpContractPdfForm = async (data) => {
    const fontSize = 14;

    // const url = `${process.env.REACT_APP_API_URL}files/emp_contract.pdf`;
    const url = `${process.env.REACT_APP_API_URL}files/EmptyContractPage.pdf`;
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
    const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);

    const font = await pdfDoc.embedFont(fontBytes);
    const pages = pdfDoc.getPages();
    const page1 = pages[0];
    const page2 = pages[1];

    const fullName = `${data && data.title_TH || "-"}${data && data.firstname_TH || "-"} ${data && data.lastname_TH || "-"}`;
    const lengthOffullName = (fullName.split("").filter(char => !["ั", "ิ", "ี", "ึ", "ื", "ุ", "ู", "์", "่", "้", "๊", "็", "๋", "ํ"].includes(char)).join("")).length;

    const lengthOfempId = data && data.employeeID && data.employeeID.length;

    const empAddress = `${data && data.houseNo || ""}  ${data && data.villageNo || ""}  ${data && data.subDistrict || ""}  ${data && data.district || ""} ${data && data.provience || ""}  ${data && data.areaCode || ""}`;
    const lengthOfEmpAddress = (empAddress.split("").filter(char => !["ั", "ิ", "ี", "ึ", "ื", "ุ", "ู", "์", "่", "้", "๊", "็", "๋", "ํ"].includes(char)).join("")).length;

    const companyProvince = `${data && data.companyDistrict}  ${data && data.companyProvince || "-"}  ${data && data.companyAreaCode || "-"}`;
    const lengthOfCompanyAddress = (companyProvince.split("").filter(char => !["ั", "ิ", "ี", "ึ", "ื", "ุ", "ู", "์", "่", "้", "๊", "็", "๋", "ํ"].includes(char)).join("")).length;

    const companyAddress = `${data && data.companyAddress ? data.companyAddress : "-"}`
    const lengthOfCompanyAddress2 = (companyAddress.split("").filter(char => !["ั", "ิ", "ี", "ึ", "ื", "ุ", "ู", "์", "่", "้", "๊", "็", "๋", "ํ"].includes(char)).join("")).length;

    TextInPdf(page1, `ทำที่    ${data && data.companyName || "-"}`, 300, 701, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page1, `วันที่    ${data && data.hiringDate ? dayjs(data && data.hiringDate).locale('th').format("DD MMMM") : "-"} ${data && data.hiringDate ? parseInt(dayjs(data && data.hiringDate).format("YYYY")) + 543 : ""}`, 300, 683, fontSize, font, rgb(0, 0, 0, 'black'));

    if ((data && data.authorizedSignatureOneName && data.authorizedSignatureOneName.length < 18) || !data.authorizedSignatureOneName) {
        TextInPdf(page1, `หนังสือสัญญาจ้างงานนี้ทำระหว่าง ${data && data.companyName || "-"} สำนักงานตั้งอยู่เลขที่ ${data && data.companyAddress && lengthOfCompanyAddress2 < 24 ? (" ").repeat(24 - lengthOfCompanyAddress2) : ""}${companyAddress}`, 115, 651, fontSize, font, rgb(0, 0, 0, 'black'));
        TextInPdf(page1, `${companyProvince} โดย  ${data && data.authorizedSignatureOneName || "         -         "}  ตำแหน่ง ${data && data.authorizedSignatureOnePosition || "         -         "} เป็นผู้มีอำนาจกระทำการแทน ซึ่งต่อไปนี้ ${lengthOfCompanyAddress > 26 ? "" : "ในสัญญา"}`, 72, 633, fontSize, font, rgb(0, 0, 0, 'black'));
        TextInPdf(page1, `${lengthOfCompanyAddress > 26 ? "ในสัญญา" : ""}นี้เรียกว่า "บริษัท" ฝ่ายหนึ่งกับ ${fullName} อายุ ${data.birthday ? calculateAge(dayjs(data.birthday).format("YYYY-MM-DD")) : "-"} ปี เลขประจำตัวพนักงาน       ${data.employeeID ? data.employeeID : "-"}${(" ").repeat(18 - (data && data.employeeID ? data.employeeID.length : 0))}อยู่บ้านเลขที่`, 72, 615, fontSize, font, rgb(0, 0, 0, 'black'));
        TextInPdf(page1, `${empAddress} ซึ่งต่อไปในสัญญานี้เรียกว่า "พนักงาน" อีกฝ่ายหนึ่งทั้งสองฝ่ายได้ตกลงทำสัญญา${lengthOfEmpAddress > 38 && lengthOfEmpAddress < 42 ? "จ้าง" : lengthOfEmpAddress >= 42 ? "" : "จ้างงาน"}`, 72, 597, fontSize, font, rgb(0, 0, 0, 'black'));
        TextInPdf(page1, `${lengthOfEmpAddress > 38 && lengthOfEmpAddress < 42 ? "งาน" : lengthOfEmpAddress >= 42 ? "จ้างงาน" : ""}ไว้ต่อกันมีข้อความดังต่อไป`, 72, 579, fontSize, font, rgb(0, 0, 0, 'black'));
    } else {
        TextInPdf(page1, `หนังสือสัญญาจ้างงานนี้ทำระหว่าง ${data && data.companyName || "-"} สำนักงานตั้งอยู่เลขที่ ${data && data.companyAddress && lengthOfCompanyAddress2 < 24 ? (" ").repeat(24 - lengthOfCompanyAddress2) : ""}${(" ").repeat(25 - (data && data.companyAddress ? lengthOfCompanyAddress2 : 0))}${companyAddress}`, 115, 651, fontSize, font, rgb(0, 0, 0, 'black'));
        TextInPdf(page1, `${data && data.companyDistrict}  ${data && data.companyProvince || "-"}  ${data && data.companyAreaCode || "-"}  โดย  ${data && data.authorizedSignatureOneName || "       -       "}  ตำแหน่ง ${data && data.authorizedSignatureOnePosition || "       -       "}`, 72, 633, fontSize, font, rgb(0, 0, 0, 'black'));
        TextInPdf(page1, `เป็นผู้มีอำนาจกระทำการแทน ซึ่งต่อไปนี้ในสัญญานี้เรียกว่า "บริษัท" ฝ่ายหนึ่งกับ ${fullName} อายุ ${data.birthday ? calculateAge(dayjs(data.birthday).format("YYYY-MM-DD")) : "-"} ปี เลขประจำ${lengthOffullName > 20 ? "" : "ตัว"}`, 72, 615, fontSize, font, rgb(0, 0, 0, 'black'));
        TextInPdf(page1, `${lengthOffullName > 20 ? "ตัว" : ""}พนักงาน ${data.employeeID ? `${data.employeeID}` : "     -     "} อยู่บ้านเลขที่ ${empAddress} ซึ่งต่อไปในสัญญานี้เรียกว่า ${(lengthOfempId > 8 && lengthOfEmpAddress > 48) ? "" : "พนักงาน อีกฝ่าย"}`, 72, 597, fontSize, font, rgb(0, 0, 0, 'black'));
        TextInPdf(page1, `${(lengthOfempId > 8 && lengthOfEmpAddress > 48) ? "พนักงาน อีกฝ่าย" : ""}หนึ่งทั้งสองฝ่ายได้ตกลงทำสัญญาจ้างงานไว้ต่อกันมีข้อความดังต่อไป`, 72, 579, fontSize, font, rgb(0, 0, 0, 'black'));
    }

    TextInPdf(page1, `ข้อ 1  บริษัทตกลงจ้างและพนักงานตกลงรับจ้างทำงานเป็นพนักงานประเภท ${data && data.employmentTypeName} ทำงานให้กับบริษัทตามหน้า`, 115, 554, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page1, `ที่การงานที่บริษัทกำหนด ซึ่งบริษัทตกลงจ่ายค่าจ้างให้แก้พนักงานเดือนละ ${data && data.salary ? String(data.salary).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "          -          "} บาท (${data && data.salary ? ArabicNumberToText(data.salary) : "                -          บาทถ้วน"})`, 72, 536, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page1, `มีกำหนดจ่ายในวันที่ 25 ของทุกเดือน โดยพนักงานจะต้องเป็นผู้รับผิดชอบชำระภาษีเงินได้บุคคลธรรมดาเอง`, 72, 518, fontSize, font, rgb(0, 0, 0, 'black'));

    TextInPdf(page1, `พนักงานจะได้รับเฉพาะสิทธิประโยชน์ต่าง ๆ เพียงเท่าที่ปรากฏตามที่กำหนดไว้ในข้อ 2. ของสัญญาจ้างนี้เท่านั้นและในกรณี`, 115, 493, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page1, `ที่สิทธิประโยชน์ต่างๆใดที่กำหนดในสัญญานี้ ขัดหรือแย้งกับข้อบังคับและระเบียบของบริษัท ทั้งสองฝ่ายตกลงให้ถือเรื่องสิทธิ`, 72, 475, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page1, `ประโยชน์ตามที่กำหนดในสัญญานี้`, 72, 457, fontSize, font, rgb(0, 0, 0, 'black'));

    TextInPdf(page1, `ข้อ 2 ทั้งสองฝ่ายตกลงให้พนักงานได้รับสิทธิประโยชน์ต่างๆ โดยรายละเอียดเป็นไปตามเอกสารแนบและให้ถือว่าเอกสาร`, 115, 432, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page1, `แนบเป็นส่วนหนึ่งของสัญญานี้`, 72, 414, fontSize, font, rgb(0, 0, 0, 'black'));

    TextInPdf(page1, `ข้อ 3 พนักงานยินยอมให้บริษัทส่ง และ/หรือสับเปลี่ยนโยกย้ายพนักงานไปทำงานในสถานที่ต่าง ๆ และ/หรือไปทำงานให้`, 115, 389, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page1, `บุคคลอื่นใดตามที่บริษัทเห็นสมควรไม่ว่าจะเป็นการประจำหรือชั่วคราว`, 72, 371, fontSize, font, rgb(0, 0, 0, 'black'));

    TextInPdf(page1, `ข้อ 4 พนักงานตกลงจะปฏิบัติตามข้อบังคับ ระเบียบ คำสั่ง จรรยาบรรณ ข้อตกลงเกี่ยวกับทรัพย์สินทางปัญญานโยบาย`, 115, 346, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page1, `และแนวทางปฏิบัติต่าง ๆ ที่บริษัทกำหนดขึ้นเพื่อเป็นหลักในการบริหารงาน ทั้งที่มีอยู่ในขณะทำสัญญานี้หรือ ที่จะมีต่อไปในภายหน้า`, 72, 328, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page1, `ซึ่งบริษัทอาจเปลี่ยนแปลงได้ตามความเหมาะสมและให้ถือเป็นส่วนหนึ่งของสัญญานี้ด้วย`, 72, 310, fontSize, font, rgb(0, 0, 0, 'black'));

    TextInPdf(page1, `ข้อ 5 พนักงานจะปฏิบัติงานของบริษัทตามที่ได้รับมอบหมายด้วยความซื่อสัตย์สุจริต และเต็มกำลังความสามารถของตน`, 115, 285, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page1, `ทั้งจะเสาะแสวงหาความรู้เพิ่มเติมหรือกระทำการอื่น ๆ อันจะเป็นทางส่งเสริมให้กิจการของบริษัทดำเนินรุดหน้าไปได้อย่างรวดเร็วด้วยดี`, 72, 267, fontSize, font, rgb(0, 0, 0, 'black'));

    TextInPdf(page1, `ข้อ 6 พนักงานตกลงจะอุทิศเวลาของตนให้แก่บริษัท รักษาผลประโยชน์ของบริษัท และจะไม่ประกอบกิจการอย่างหนึ่ง`, 115, 242, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page1, `อย่างใดอันเป็นการแข่งขันหรืออาจเป็นการแข่งขันกับกิจการของบริษัทหรือเข้าร่วมในการประกอบกิจการโดยพนักงานได้รับผลประโยชน์`, 72, 224, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page1, `ไม่ว่าโดยทางตรงหรือทางอ้อมในธุรกิจของบริษัทซึ่งประกอบกิจการเช่นเดียวกับบริษัท)หรือกระทำการอย่างหนึ่งอย่างใดอันอาจก่อให้`, 72, 206, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page1, `เกิดความเสียหายแก่กิจการของบริษัท ไม่ว่าจะโดยทางตรงหรือโดยทางอ้อม`, 72, 188, fontSize, font, rgb(0, 0, 0, 'black'));

    TextInPdf(page1, `ข้อ 7 พนักงานตกลงจะไม่รับประโยชน์หรืออามิสสินจ้างหรือสิ่งตอบแทนอย่างหนึ่งอย่างใดจากผู้อื่น เนื่องในการปฏิบัติ`, 115, 163, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page1, `งานหรือให้คำแนะนำเกี่ยวกับกิจการของบริษัท`, 72, 145, fontSize, font, rgb(0, 0, 0, 'black'));

    TextInPdf(page1, `พนักงานที่ได้รับของขวัญหรือประโยชน์ด้วยเหตุดังกล่าวข้างต้น แม้จะได้รับในโอกาสตามประเพณีนิยมแต่ถ้ามีมูลค่าเกิน`, 115, 120, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page1, `กว่าปกติวิสัย พนักงานจะแจ้งให้ผู้บังคับบัญชาทราบทุกกรณี`, 72, 102, fontSize, font, rgb(0, 0, 0, 'black'));

    TextInPdf(page2, `ข้อ 8 พนักงานให้สัญญาว่าจะไม่อ้างความเป็นพนักงานของบริษัทเพื่อประโยชน์ ในการดำเนินการเกี่ยวกับการสมัครรับ`, 115, 730, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page2, `เลือกตั้งหรือการหาเสียงเลือกตั้งในทางการเมือง และจะไม่แอบอ้างหรือทำให้สาธารณชนหลงเข้าใจผิดได้ว่าบริษัทให้ความสนับสนุน`, 72, 712, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page2, `มีส่วนเกี่ยวข้องหรือฝักใฝ่ในพรรคการเมืองด้วย`, 72, 694, fontSize, font, rgb(0, 0, 0, 'black'));

    TextInPdf(page2, `หากพนักงานได้รับเลือกตั้งให้ดำรงตำแหน่งทางการเมือง พนักงานให้สัญญาว่าจะลาออกจากการเป็นพนักงานโดยมีผล`, 115, 669, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page2, `นับตั้งแต่วันที่พนักงานได้รับเลือกตั้ง`, 72, 651, fontSize, font, rgb(0, 0, 0, 'black'));

    TextInPdf(page2, `ข้อ 9 ในระหว่างการเป็นพนักงานบริษัทและภายใน 2 ปีนับแต่พ้นสภาพจากการเป็นพนักงานของบริษัท พนักงานตกลง`, 115, 626, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page2, `ไม่เปิดเผยข้อมูลสำคัญหรือความลับของบริษัทให้ผู้หนึ่งผู้ใดทราบ ตลอดทั้งจะไม่ให้ความช่วยเหลือหรือให้คำปรึกษาแก่ผู้อื่นแข่งขันกับ`, 72, 608, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page2, `กิจการของบริษัท`, 72, 590, fontSize, font, rgb(0, 0, 0, 'black'));

    TextInPdf(page2, `ข้อ 10 ในระหว่างการเป็นพนักงานบริษัทและภายใน 2 ปี  นับแต่พนักงานพ้นสภาพจากการเป็นพนักงานของบริษัทไม่ว่า`, 115, 565, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page2, `ด้วยเหตุใด พนักงานจะไม่เข้าเป็นลูกจ้าง หรือ รับจ้าง ผู้ประกอบกิจการซึ่งแข่งขันกับกิจการของบริษัท หรือประกอบกิจการอย่างหนึ่ง`, 72, 547, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page2, `อย่างใดอันเป็นการแข่งขัน หรือกระทำการอย่างหนึ่งอย่างใดอันอาจก่อให้เกิดความเสียหายแก่กิจการของบริษัทไม่ว่าโดยทางตรงหรือ`, 72, 529, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page2, `ทางอ้อมและจะได้รับผลประโยชน์ตอบแทนหรือไม่ก็ตาม`, 72, 511, fontSize, font, rgb(0, 0, 0, 'black'));

    TextInPdf(page2, `ข้อ 11 ถ้าคู่สัญญาฝ่ายหนึ่งฝ่ายใดมีความประสงค์จะเลิกหนังสือสัญญาจ้างงานนี้ คู่สัญญาฝ่ายนั้นจะต้องแจ้งให้อีก ฝ่ายหนึ่ง`, 115, 486, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page2, `ทราบล่วงหน้าตามระยะเวลา 1 รอบค่าจ้าง`, 72, 468, fontSize, font, rgb(0, 0, 0, 'black'));

    TextInPdf(page2, `ข้อ 12 สัญญานี้มีกำหนดระยะเวลาตั้งแต่วันที่  ${data && data.hiringDate ? dayjs(data && data.hiringDate).locale('th').format("DD MMMM") : "-"} ${data && data.hiringDate ? parseInt(dayjs(data && data.hiringDate).format("YYYY")) + 543 : ""}  ถึงวันที่   ${data && data.contractTermainatoinDate ? dayjs(data && data.contractTermainatoinDate).locale('th').format("DD MMMM") : "       -"} ${data && data.contractTermainatoinDate ? parseInt(dayjs(data && data.contractTermainatoinDate).format("YYYY")) + 543 : ""} `, 115, 443, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page2, `หนังสือสัญญาจ้างงานนี้ทำขึ้นเป็นสองฉบับ มีข้อความถูกต้องตรงกันสำหรับยึดถือไว้ฝ่ายละฉบับ ทั้งสองฝ่ายได้อ่านตรวจดูโดยตลอดแล้ว`, 72, 425, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page2, `เห็นว่าตรงตามที่ได้ตกลงไว้ต่อกัน จึงได้ลงชื่อไว้เป็นสำคัญต่อหน้าพยาน`, 72, 407, fontSize, font, rgb(0, 0, 0, 'black'));

    TextInPdf(page2, `${data && data.companyName || "-"}`, 267, 320, fontSize, font, rgb(0, 0, 0, 'black'));

    TextInPdf(page2, `ลงชื่อ ____________________________________ บริษัท`, 210, 280, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page2, `(   ${data.authorizedSignatureOneName ? data.authorizedSignatureOneName : "-"}      )`, 245, 260, fontSize, font, rgb(0, 0, 0, 'black'));

    TextInPdf(page2, `ลงชื่อ ____________________________________ พนักงาน`, 210, 210, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page2, `(   ${data && data.title_TH || "-"} ${data && data.firstname_TH || "-"} ${data && data.lastname_TH || "-"}      )`, 245, 190, fontSize, font, rgb(0, 0, 0, 'black'));

    TextInPdf(page2, `ลงชื่อ ____________________________________ พยาน`, 210, 150, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page2, `(                                            )`, 245, 130, fontSize, font, rgb(0, 0, 0, 'black'));

    TextInPdf(page2, `ลงชื่อ ____________________________________ พยาน`, 210, 100, fontSize, font, rgb(0, 0, 0, 'black'));
    TextInPdf(page2, `(                                            )`, 245, 80, fontSize, font, rgb(0, 0, 0, 'black'));

    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    window.open(docUrl, "_blank");
}