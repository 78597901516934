import React from "react";
import { styled } from "@mui/material/styles";

import { Drawer, Typography, Divider } from "@mui/material";
import ButtonBlue from "./ButtonBlue";



const DrawerCustom = (props) => {
  return (
    <Drawer anchor={props.anchor} open={props.open} onClose={props.onClose} PaperProps={props.PaperProps}>
      <div style={{ padding: 16 }}>
        {props.header ? 
          props.header :
          (<Typography>{props.title}</Typography>)
        }
      </div>
      <Divider />
      {props.children}
    </Drawer>
  );
};

export default DrawerCustom;
