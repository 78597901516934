import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Avatar, Typography, Button, Stack } from "@mui/material";
import { styled } from "@mui/styles";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from '@mui/icons-material/Settings';
import { getJobRecruitInterviewPanel } from "../../../../../../actions/jobRecruit";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import CardStyle from '../../../../shared/general/Card';
import { getUserFullName } from "../../../../../../utils/userData";
import DrawerInterviewPanel from "./drawerInterviewPanel";

const StyledContent = styled("div")({
  padding: 36,
  paddingTop: 24,
  "& .column-name": {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#000000",
    "& .MuiAvatar-root": {
      marginRight: 8,
      width: 40,
      height: 40,
    },
  },
});

const DivInterviewPanelList = styled("div")({
  width: "100%",
  height: "fit-content",
  display: "grid",
  marginBottom: 24,
  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
    border: "0",
    marginBottom: 16,
    "& .MuiTypography-root": {
      fontWeight: 500,
      "& span": {
        letterSpacing: 0.5,
      },
    },
  },
  "& .interviewpanel-list": {
    padding: 16,
    // height: "250px",
    overflow: "auto",
    backgroundColor: "white",
    borderRadius: "16px",
    display: "grid",
    gridTemplateRows: "repeat(autofill, minmax(130px, 1fr))",
    gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
    minWidth: "100%",
    boxSizing: "border-box",
    columnGap: 8,
    marginTop: 20,
    "& .interviewpanel-item": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minHeight: "105px",
    },
    "& .MuiAvatar-root": {
      width: 72,
      height: 72,
      marginBottom: 8,
    },
    "& .MuiTypography-root": {
      maxWidth: 120,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
});

const CardInterviewPanel = (props) => {
  const {t,i18n} = useTranslation();
  // console.log(props);
  const dispatch = useDispatch();
  const { result: jobRecruitInterviewPanel } = useSelector((state) => state.jobRecruitInterviewPanel);
  const [isFetching, SetisFetching] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if(jobRecruitInterviewPanel){
      SetisFetching(false);
    }
  }, [jobRecruitInterviewPanel])

  const handleClickOpenDrawer = () => {
    setOpenDrawer(true);
  };

  useEffect(() => {
    dispatch(getJobRecruitInterviewPanel(props.idJobRecruit))
  }, []);

  // console.log(isFetching);

  return (
    <DivInterviewPanelList>
      <Fragment>
        <CardStyle>
          <StyledContent>
            <Stack direction="row" justifyContent={"space-between"}>
              <Typography variant="h5">{t("InterviewCommittee")}{" ("}{jobRecruitInterviewPanel ? jobRecruitInterviewPanel.length : "0"}{` ${t("InterviewCommittee")})`}</Typography>
              <ButtonBlue variant="outlined" startIcon={<SettingsIcon />} onClick={handleClickOpenDrawer}>
                {t("manageInterviewCommittee")}
              </ButtonBlue>
            </Stack>
            {jobRecruitInterviewPanel && isFetching &&
              <div style={{ display: "flex", justifyContent: "center", margin: "24px 0" }}>
                <Typography>{t("LoadingData")}</Typography>
              </div>
            }
            {jobRecruitInterviewPanel && jobRecruitInterviewPanel.length <= 0 && !isFetching &&
              <div style={{ display: "flex", justifyContent: "center", margin: "24px 0" }}>
                <Typography>{t("noCommitteeFound")}</Typography>
              </div>
            }
            {jobRecruitInterviewPanel && !isFetching && (
              <div className="interviewpanel-list">
                {jobRecruitInterviewPanel.map((item, index) => (
                  <div
                    className="interviewpanel-item"
                    key={index + getUserFullName(item)}
                  >
                    <Avatar key={index} alt={item.email} src={item.imageURL} />
                    <Typography>{getUserFullName(item)}</Typography>
                  </div>
                ))}
              </div>
            )}
          </StyledContent>
        </CardStyle>
        {openDrawer && (
          <DrawerInterviewPanel 
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            idJobRecruit={props.idJobRecruit}
            idJobGroup={props.idJobGroup}
            RecruitInterviewPanelList={jobRecruitInterviewPanel}
          />
        )}
      </Fragment>
    </DivInterviewPanelList>
  );
};

export default CardInterviewPanel;
