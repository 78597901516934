import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import {
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import DrawerCustom from "../../../../shared/general/Drawer";

import { updateUser } from "../../../../../../actions/user";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePickerCustom from "../../../../shared/date/datePicker";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const DialogEdit = (props) => {
  const { open, handleCloseDialog } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );

  const [formData, setFormData] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    setFormData({
      idEmployees: employeeProfile? employeeProfile.idEmployees: null,
      title_TH: employeeProfile? employeeProfile.title_TH: null,
      firstname_TH: employeeProfile? employeeProfile.firstname_TH: null,
      lastname_TH: employeeProfile? employeeProfile.lastname_TH: null,
      nickname_TH: employeeProfile? employeeProfile.nickname_TH: null,
      title_EN: employeeProfile? employeeProfile.title_EN: null,
      firstname_EN: employeeProfile? employeeProfile.firstname_EN: null,
      lastname_EN: employeeProfile? employeeProfile.lastname_EN: null,
      nickname_EN: employeeProfile? employeeProfile.nickname_EN: null,
      gender: employeeProfile? employeeProfile.gender: null,
      birthday: employeeProfile? dayjs(employeeProfile.birthday).format("YYYY-MM-DD"): "",
      personalID:  employeeProfile? employeeProfile.personalID: null,
      telephoneMobile: employeeProfile? employeeProfile.telephoneMobile: null,
      email: employeeProfile? employeeProfile.email: null,
    });
  }, [open]);

  const handleSubmit = async () => {
    formData.idEmployees = employeeProfile.idEmployees;
    if (formData.birthday) {
      if (formData.birthday === "Invalid Date"){
        formData.birthday = null
      } else {
        formData.birthday = dayjs(formData.birthday).format("YYYY-MM-DD");
      }
    }
    const result = await dispatch(updateUser(formData));
    if (result) {
      handleCloseDialog();
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
    }
    // formData.UpdateBy = userProfile.idUsers;
    // if (formData.birthday !== dayjs(employeeProfile.birthday).format("YYYY-MM-DD")) {
    //   formData.birthday = dayjs(formData.birthday).format("YYYY-MM-DD")
    // }
    // console.log(formData)
    // const result = await dispatch(updateUser(formData));
    // if (result) {
    //   handleCloseDialog();
    //   dispatch(getEmployeeProfile(employeeProfile.idEmp));
    // }
  };

  return (
    <DrawerCustom
      title={t("EditEmployeeInfo")}
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        {formData && (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("Title")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledFormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="title_TH"
                  value={formData.title_TH}
                  onChange={handleChange}
                >
                  <MenuItem value={"นาย"}>นาย</MenuItem>
                  <MenuItem value={"นาง"}>นาง</MenuItem>
                  <MenuItem value={"นางสาว"}>นางสาว</MenuItem>
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("FirstName")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="firstname_TH"
                value={formData.firstname_TH}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("LastName")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="lastname_TH"
                value={formData.lastname_TH}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("Nickname")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="nickname_TH"
                value={formData.nickname_TH}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("TitleEng")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledFormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="title_EN"
                  value={formData.title_EN}
                  onChange={handleChange}
                >
                  <MenuItem value={"Mr."}>Mr.</MenuItem>
                  <MenuItem value={"Mrs."}>Mrs.</MenuItem>
                  <MenuItem value={"Miss"}>Miss</MenuItem>
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("FirstNameEng")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="firstname_EN"
                value={formData.firstname_EN}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("LastNameEng")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="lastname_EN"
                value={formData.lastname_EN}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("NicknameEng")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="nickname_EN"
                value={formData.nickname_EN}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("Gender")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledFormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <MenuItem value={"Male"}>{t("Male")}</MenuItem>
                  <MenuItem value={"Female"}>{t("Female")}</MenuItem>
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("BirthDate")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              {/* <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  value={formData.birthday}
                  onChange={(newValue) => {
                    setFormData({ ...formData, birthday: newValue });
                  }}
                  renderInput={(params) => (
                    <StyledTextField fullWidth {...params} />
                  )}
                />
              </LocalizationProvider> */}
              <DatePickerCustom
                // {...field}
                views={["year", "month", "day"]}
                inputFormat="D MMMM YYYY"
                disableFuture
                disableMaskedInput
                openTo="year"
                value={formData.birthday}
                onChange={(newValue, keyboardInputValue) => {
                  console.log("keyboardInputValue", keyboardInputValue)
                  console.log("keyboardInputValue", dayjs(keyboardInputValue, "D MMMM BBBB"))
                  console.log("keyboardInputValue", dayjs(keyboardInputValue, "D MMMM BBBB", true).isValid())
                  console.log("newValue", newValue)
                  console.log("newValue", dayjs(newValue, "D MMMM BBBB"))
                  console.log("newValue", dayjs(newValue, "D MMMM BBBB", true).isValid())
                  if(newValue && newValue.isValid()){
                    setFormData({ ...formData, birthday: newValue });
                  }
                  if(keyboardInputValue && keyboardInputValue.split(" ").length === 3 && keyboardInputValue.split(" ")[2].length === 4){
                    console.log(keyboardInputValue.split(" "))
                    if(dayjs(`${keyboardInputValue.split(" ")[0]} ${keyboardInputValue.split(" ")[1]} ${keyboardInputValue.split(" ")[2]-543}`, "D MMMM YYYY").isValid()){
                      console.log(dayjs(`${keyboardInputValue.split(" ")[0]} ${keyboardInputValue.split(" ")[1]} ${keyboardInputValue.split(" ")[2]-543}`, "D MMMM YYYY"))
                      setFormData({ ...formData, birthday: dayjs(`${keyboardInputValue.split(" ")[0]} ${keyboardInputValue.split(" ")[1]} ${keyboardInputValue.split(" ")[2]-543}`, "D MMMM YYYY") });
                    } else {
                      
                    }
                  }
                  if(!keyboardInputValue && !newValue){
                    setFormData({ ...formData, birthday: null });
                  }
                }}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: t("DayMonthYear(AD)"),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("PersonalID")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="personalID"
                InputProps={{
                  inputComponent: NumberFormatTheme,
                }}
                inputProps={{
                  format: (value) => {
                    if(value.length >= 13){
                      return String(value).replace(/(\d)(\d{1,4})(\d{1,5})(\d{1,2})(\d)/,"$1-$2-$3-$4-$5")
                    } else if(value.length >= 11) {
                      return String(value).replace(/(\d)(\d{1,4})(\d{1,5})(\d{1,2})/,"$1-$2-$3-$4")
                    } else if(value.length >= 6){
                      return String(value).replace(/(\d)(\d{1,4})(\d{1,5})/,"$1-$2-$3")
                    } else if(value.length >= 2){
                      return String(value).replace(/(\d)(\d{1,4})/,"$1-$2")
                    } else {
                      return String(value)
                    }
                  },
                  maxLength: 17,
                  allowNegative: false,
                  allowLeadingZeros: true,
                  allowEmptyFormatting: false,
                  value: formData.personalID,
                  onValueChange: (values) => {
                    const { value } = values;
                    setFormData({
                      ...formData,
                      "personalID": value,
                    });
                  },
                }}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("PhoneNumber")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="telephoneMobile"
                InputProps={{
                  inputComponent: NumberFormatTheme,
                }}
                inputProps={{
                  format: (value) => {
                    if(value.length >= 7){
                      return String(value).replace(/(\d{1,3})(\d{1,3})(\d{1,4})/,"$1-$2-$3")
                    } else if(value.length >= 4){
                      return String(value).replace(/(\d{1,3})(\d{1,3})/,"$1-$2")
                    } else {
                      return String(value)
                    }
                  },
                  maxLength: 12,
                  allowNegative: false,
                  allowLeadingZeros: true,
                  allowEmptyFormatting: false,
                  value: formData.telephoneMobile,
                  onValueChange: (values) => {
                    const { value } = values;
                    setFormData({
                      ...formData,
                      "telephoneMobile": value,
                    });
                  },
                }}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("Email")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="email"
                value={formData.email}
                fullWidth
                onChange={handleChange}
                // disabled
                // inputProps={{
                //   readOnly: true,
                // }}
              />
            </Grid>
          </Grid>
        )}
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleCloseDialog}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit}>
            {t("SaveData")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
