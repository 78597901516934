import { httpClient } from "./httpClient";

const getProvidentFundUser = () => {
    return httpClient.get(`providentFund`);
};

const addProvidentFundDate = (data) => {
    return httpClient.post("providentFund/add",data);
};

const updateProvidentFundDate = (data) => {
    return httpClient.put("providentFund/update-date", data);
};

const updateProvidentFundUser = (userPF, companyPF, useDate) => {
    return httpClient.put("providentFund", { userPF: userPF, companyPF: companyPF, useDate: useDate });
};

const getProvidentFundEmployeeByIdEmployee = (idEmployee) => {
    return httpClient.get(`providentFund/${idEmployee}`);
};

const updateProvidentFundUserByAdmin = (data) => {
    return httpClient.put(`admin/providentFund`, data);
};

const leaveProvidentFund = (data) => {
    if(data){
        return httpClient.put(`providentFund/leave`,data);
    }else{
        return httpClient.put(`providentFund/leave`);
    }
};

export default {
    getProvidentFundUser,
    addProvidentFundDate,
    updateProvidentFundDate,
    updateProvidentFundUser,
    getProvidentFundEmployeeByIdEmployee,
    updateProvidentFundUserByAdmin,
    leaveProvidentFund
};