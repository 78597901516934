import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Paper,
  Grid,
  useMediaQuery,
  MenuItem,
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/system';

import personalityImage from '../../assets/personality.png'
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import AlertResponse from "../../shared/general/AlertResponse";
import DialogConfirm from "../../shared/general/DialogConfirm";
import { getAllPotential, postAnswerPotential } from "../../../../actions/potential";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  backgroundColor: '#FFFFFF !important',
  minHeight: '100vh',
  padding: '20px',
  "& .MuiContainer-root": {
    paddingBottom: 16,
  },
  "& .filter-container": {
    marginBottom: "16px",
    display: "flex",
    justifyContent: "flex-end",
    gap: "8px",
    "& .MuiInputBase-root": {
      maxHeight: "48px",
    },
    "& .filter-month": {
      minWidth: "150px",
    },
    "& .filter-year": {
      minWidth: "150px",
    },
  },
  "& .menu-container": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    borderRadius: 20,
    backgroundColor: "#ffffff",
    width: "fit-content",
    overflow: "hidden",
  },
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root, & .MuiFilledInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
        borderRadius: 0,
      },
    },
  },
  "& .MuiSelect-filled": {
    padding: "16px 32px 16px 12px",
  },
});
const StyledPaper = styled(Paper)({
  padding: 20,
  minWidth: "450px",
  minHeight: "500px",
  backgroundSize: "cover",
  backgroundPosition: "top",
  borderRadius: "20px",
  height: "fit-content",
});
const QuestionTextNumber = styled(Box)({
  position: 'absolute',
  top: '30px',
  width: "250px",
  padding: "15px 20px",
  transform: 'translate(-50%, -50%)',
  backgroundColor: "#5E8DD4",
  borderRadius: 5,
  boxShadow: "rgb(90 114 123 / 20%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
})

const QuestionTextBox = styled(Paper)({
  padding: "10px 20px",
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: '8px',
});
const StyledQuestion = styled("div")({
  "& .header-question": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    marginTop: "10px",
    textAlign: "center",

    "& .questionText": {
      color: "black",
      fontSize: "20px",
      fontWeight: 400,
      alignSelf: "center",
      marginTop: "10px",
    },
  },
  "& .content-question": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    marginTop: "10px",
    textAlign: "center",
    maxWidth: 1000,
    minWidth: 300,

    "& .answerText": {
      color: "black",
      fontSize: "18px",
      fontWeight: 300,
      textAlign: "center",
      alignItems: "center",
    },
  },
});
const getCircleSize = (index) => {
  switch (index) {
    case 0:
      return '60px';
    case 1:
      return '50px';
    case 2:
      return '40px';
    case 3:
      return '40px';
    case 4:
      return '50px';
    case 5:
      return '60px';
    default:
      return '60px';
  }
};
const LikertCircle = styled(Box)(({ isSelected, size }) => ({
  backgroundColor: isSelected ? '#33a474' : 'none',
  width: getCircleSize(size),
  height: getCircleSize(size),
  borderRadius: '50%',
  border: "3px solid #33a474",
}));
const StyleButton = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginTop: "10px",
  "& button": {
    marginRight: "10px",
    color: "white",
  },
});

const UserPotentialTest = () => {
  const { t } = useTranslation();
  const history = useHistory()
  const location = useLocation();
  const phoneSceen = useMediaQuery("(max-width:650px)");
  const [potentialQuestion, setPotentialQuestion] = useState(null);
  const [loading, setLoading] = useState(true)

  const labelText = [
    t("StronglyAgree"),
    t("Agree"),
    t("SomewhatAgree"),
    t("SomewhatDisagree"),
    t("Disagree"),
    t("StronglyDisagree"),
  ]

  // const questions = useSelector((state) => state.approveList.result?.potentials || []);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);

  const [openAlert, setOpenAlert] = useState({
    open: false,
    alertType: null,
    label: null
  })
  const handleOpenAlert = (alertType, label) => {
    setOpenAlert({
      open: true,
      alertType: alertType,
      label: label
    });
  };
  const handleCloseAlert = () => {
    setOpenAlert({
      open: false,
      alertType: null,
      label: null
    });
  };
  const [openPageAlert, setOpenPageAlert] = useState({
    open: false,
  });
  const handleOpenPageAlert = (type, location) => {
    setOpenPageAlert({
      open: true,
    })
  }
  const handleClosePageAlert = () => {
    setOpenPageAlert({
      open: false,
    })
  }

  const handleClick = (value) => {
    if (potentialQuestion && potentialQuestion[currentQuestionIndex] && potentialQuestion[currentQuestionIndex] !== undefined && potentialQuestion[currentQuestionIndex].idPotentialQuestion) {
      const updatedAnswers = [...answers];
      updatedAnswers[currentQuestionIndex] = { idPotentialQuestion: potentialQuestion[currentQuestionIndex].idPotentialQuestion, Answer: value };
      setAnswers(updatedAnswers);
    }
  };
  const handleNext = () => {
    if (currentQuestionIndex < potentialQuestion.length - 1) {
      setCurrentQuestionIndex(prev => prev + 1);
    } else {
      handleOpenPageAlert();
    }
  };
  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(prev => prev - 1);
    }
  };

  const fetchedAllPotentialItems = async () => {
    try {
      const response = await getAllPotential();
      if (response && response.data) {
        setPotentialQuestion(response.data);
      };
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmSubmit = async () => {
    let answer = { list: answers }

    const result = await postAnswerPotential(location.state.idEmployees, answer);
    if (result && result.status === 200) {
      await fetchedAllPotentialItems();
      handleOpenAlert("success", "Submission Successful")
      history.push({
        pathname: `/user-potential`,
      })
    } else {
      handleOpenAlert("error", "Submission Failed")
    }
    handleClosePageAlert();
  };

  useEffect(() => {
    if (potentialQuestion && potentialQuestion.length > 0) {
      setAnswers(new Array(potentialQuestion.length).fill({ idPotentialQuestion: null, Answer: null }));
    }
    setLoading(false)
  }, [potentialQuestion]);

  useEffect(() => {
    if (!(location.state && location.state.idEmployees && location.state.idEmployees !== undefined)) {
      history.goBack()
    } else {
      fetchedAllPotentialItems();
    }
  }, [location]);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg" style={{ position: 'relative' }}>
        <Typography variant="h5" style={{ fontWeight: "600", marginTop: 20, marginBottom: 20 }}>
          {t("PersonalityTest")}
        </Typography>
        <StyledPaper
          style={{
            backgroundImage: `url(${personalityImage})`,
          }}
        >
          <Box>
            <Box style={{ position: "relative", marginTop: "220px" }}>
              <Box>
                <Box
                  style={{
                    position: "absolute",
                    top: -30,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 1
                  }}
                >
                  <QuestionTextNumber>
                    <Typography
                      fontSize={"1.5rem"}
                      fontWeight={600}
                      color={"#FFFFFF"}
                      textAlign={"center"}
                    >
                      Question {currentQuestionIndex + 1} / {potentialQuestion && Array.isArray(potentialQuestion) && potentialQuestion.length}
                    </Typography>
                  </QuestionTextNumber>
                </Box>
                <QuestionTextBox style={{ width: "70%" }}>
                  <StyledQuestion>
                    <div className="header-question" style={{ wordWrap: "anywhere", }}>
                      <Typography className="questionText" >
                        {loading ?
                          "Loading..."
                          :
                          potentialQuestion && Array.isArray(potentialQuestion) && potentialQuestion.length > currentQuestionIndex &&
                            potentialQuestion[currentQuestionIndex] && potentialQuestion[currentQuestionIndex].question ?
                            potentialQuestion[currentQuestionIndex].question
                            :
                            "Error"
                        }
                      </Typography>
                    </div>
                  </StyledQuestion>
                </QuestionTextBox>
              </Box>
              <Box style={{ position: "relative", width: "80%", marginLeft: "auto", marginRight: "auto" }}>
                {!phoneSceen ?
                  <StyledQuestion style={{ width: "100%", marginTop: "10px" }}>
                    {!loading && potentialQuestion !== null ?
                      <Grid container justifyContent="space-between" alignItems="center" style={{ marginLeft: "auto", marginRight: "auto", marginTop: "10px", width: "80%" }}>
                        {/* <Grid item>
                        <Typography variant="h6" style={{ marginRight: '10px' }}>เห็นด้วย</Typography>
                      </Grid> */}
                        {labelText.map((_, index) => {
                          return (
                            <Grid item style={{ cursor: "pointer" }}>
                              {index === 0 ?
                                <Typography variant="subtitle2">{t("Agree")}</Typography> :
                                labelText && Array.isArray(labelText) && index === labelText.length - 1 ?
                                  <Typography variant="subtitle2" textAlign={"end"}>{t("Disagree")}</Typography> :
                                  <Typography variant="subtitle2" style={{ marginTop: "20px" }}></Typography>
                              }
                              <Box onClick={() => handleClick(index + 1)}>
                                <LikertCircle isSelected={answers && answers[currentQuestionIndex] && answers[currentQuestionIndex].Answer === index + 1} size={index} />
                              </Box>
                            </Grid>
                          )
                        }
                        )}
                      </Grid> : null
                    }
                  </StyledQuestion>
                  :
                  <Box marginTop={"10px"}>
                    <Typography variant="subtitle2">{t("SelectStatus")}</Typography>
                    <TextFieldTheme
                      select
                      value={answers && answers[currentQuestionIndex] && answers[currentQuestionIndex].Answer}
                      onChange={(event, value) => {
                        console.log(event); console.log(value);
                        handleClick(event.target.value)
                      }}
                    >
                      <MenuItem key={null} value={null}>-</MenuItem>
                      {labelText.map((text, index) => (
                        <MenuItem key={index} value={index + 1}>{text}</MenuItem>
                      ))}
                    </TextFieldTheme>
                  </Box>
                }
              </Box>
            </Box>
            {potentialQuestion && Array.isArray(potentialQuestion) ?
              <StyleButton>
                <ButtonBlue
                  variant="contained"
                  color="secondary"
                  radius="style2"
                  onClick={handlePrevious}
                  disabled={currentQuestionIndex === 0}
                  style={{ padding: "10px 40px" }}
                >
                  {t("Back")}
                </ButtonBlue>
                {potentialQuestion && potentialQuestion.length - 1 > currentQuestionIndex ? (
                  <ButtonBlue
                    variant="contained"
                    color="secondary"
                    radius="style2"
                    onClick={handleNext}
                    disabled={(potentialQuestion && potentialQuestion[currentQuestionIndex] === undefined) || (answers && answers[currentQuestionIndex] && answers[currentQuestionIndex].Answer === null)}
                    style={{ padding: "10px 40px" }}
                  >
                    {t("Next")}
                  </ButtonBlue>
                ) : (
                  <ButtonBlue
                    variant="contained"
                    radius="style2"
                    onClick={handleNext}
                    disabled={(potentialQuestion && potentialQuestion[currentQuestionIndex] === undefined) || (answers && answers[currentQuestionIndex] && answers[currentQuestionIndex].Answer === null)}
                    style={{ padding: "10px 40px" }}
                  >
                    {t("Submit")}
                  </ButtonBlue>
                )}
              </StyleButton>
              : null
            }
          </Box>
        </StyledPaper>

        {/* <Box mt={2} sx={{ boxShadow: 3, borderRadius: 2, overflow: 'hidden', position: 'relative' }}>
          <img src={personalityImage} alt="Personality Test" style={{ width: '100%', height: 'auto', display: 'block' }} />
          <QuestionOverlay>
            Question {currentQuestionIndex + 1}
          </QuestionOverlay>
        </Box>
        <QuestionText>
          <Typography variant="h6" style={{ marginTop: '35px' }}>
            {questions.length > 0 ? questions[currentQuestionIndex].Question : "Loading..."}
          </Typography>
        </QuestionText>
        <LikertScaleWrapper>
          <Typography variant="h6" style={{ marginRight: '20px' }}>ฉันเห็นด้วย</Typography>
          {new Array(6).fill(null).map((_, index) => (
            <LikertOption key={index} onClick={() => handleClick(index + 1)}>
              <LikertCircle isSelected={selectedValue === index + 1} index={index} />
            </LikertOption>
          ))}
          <Typography variant="h6" style={{ marginLeft: '20px' }}>ฉันไม่เห็นด้วย</Typography>
        </LikertScaleWrapper>
        <ButtonsWrapper>
          <ButtonBlue variant="contained" onClick={handlePrevious} disabled={currentQuestionIndex === 0}>PREVIOUS</ButtonBlue>
          {currentQuestionIndex === questions.length - 1 ? (
            <ButtonBlue variant="contained" onClick={handleSubmit} disabled={!allQuestionsAnswered}>SUBMIT</ButtonBlue>
          ) : (
            <ButtonBlue variant="contained" onClick={handleNext} disabled={currentQuestionIndex === questions.length - 1}>NEXT</ButtonBlue>
          )}
        </ButtonsWrapper> */}
      </Container>
      {openPageAlert.open &&
        <DialogConfirm
          open={openPageAlert.open}
          content1={t("ConfirmSaveData")}
          content2=""
          handleClose={handleClosePageAlert}
          handleConfirm={handleConfirmSubmit}
        />
      }
      <AlertResponse
        open={openAlert.open}
        handleClose={handleCloseAlert}
        alertType={openAlert.alertType}
        label={openAlert.label}
      />
    </StyledRoot>
  );
};

export default UserPotentialTest;
