import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import TextField from "@mui/material/TextField";

import { getEmployeeProfile } from "../../../../../../actions/employee";
import { updateUser } from "../../../../../../actions/user";

import DrawerCustom from "../../../../shared/general/Drawer";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../../../shared/date/datePicker";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogEdit = (props) => {
  const { open, handleCloseDrawer } = props;
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: reasonExitInterview } = useSelector((state) => state.exitInterviewReason);

  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    resignDate: new Date(),
    reasonResign: "",
    reasonResignOther: "",
    isBlacklist: false,
  });

  // useEffect(() => {
  //   if (employeeProfile) {
  //     setFormData({
  //       idEmployees: employeeProfile.idEmployees,
  //       resignDate: employeeProfile.resignDate,
  //       reasonResign: employeeProfile.reasonResign,
  //       reasonResignOther: employeeProfile.reasonResignOther,
  //       isBlacklist: employeeProfile.isBlacklist,
  //     });
  //   }
  // }, [employeeProfile]);

  useEffect(() => {
    if (open) {
      setFormData({
        idEmployees: employeeProfile.idEmployees,
        resignDate: employeeProfile.resignDate,
        reasonResign: employeeProfile.reasonResign,
        reasonResignOther: employeeProfile.reasonResignOther,
        isBlacklist: employeeProfile.isBlacklist,
      });
    }
  }, [open]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "isBlacklist") {
      setFormData({
        ...formData,
        [name]: event.target.checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async () => {
    const data = { ...formData };
    data.isBlacklist = data.isBlacklist? 1: 0;
    data.resignDate = data.resignDate
      ? dayjs(data.resignDate).format("YYYY-MM-DD")
      : null;

    const result = await dispatch(updateUser(data));

    if (result) {
      handleCloseDrawer();
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
    }
  };

  return (
    <DrawerCustom
      title={`${t("AddResignInfo")}`}
      anchor={"right"}
      open={open}
      onClose={handleCloseDrawer}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledContentLabel gutterBottom>
              {t("EffectiveDate")}
            </StyledContentLabel>
            <DatePickerCustom
              value={formData.resignDate}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => {
                setFormData({
                  ...formData,
                  ["resignDate"]: date,
                });
              }}
              renderInput={(params) => (
                <StyledTextField fullWidth {...params} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledContentLabel gutterBottom>{t("Reason")}</StyledContentLabel>
            <StyledFormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="reasonResign"
                value={formData.reasonResign}
                onChange={handleInputChange}
              >
                {reasonExitInterview && reasonExitInterview.map((option) => (
                  <MenuItem
                    key={option.idReasonExitInterview}
                    value={option.name}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </Grid>
          <Grid item xs={12}>
            <StyledContentLabel gutterBottom>
              {t("ReasonOther")}
            </StyledContentLabel>
            <StyledTextField
              name="reasonResignOther"
              value={formData.reasonResignOther}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.isBlacklist}
                    name="isBlacklist"
                    onChange={handleInputChange}
                  />
                }
                label="Blacklist"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleCloseDrawer}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit}>
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
