import React, { useState, useEffect } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import useTable from "../../../shared/table/useTable";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DialogueRecordList from "./DialogueRecordList";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/styles";
import dayjs from "dayjs";

import { useSelector, useDispatch } from "react-redux";
import { getAllIdvPlan } from "../../../../../actions/IndividualPlans";

import {
  TextField,
  Button,
  Paper,
  Grid,
  Typography,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Avatar,
  IconButton,
  Menu,
  Container,
  Box,
  Card,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../../shared/date/datePicker";
import { t } from "i18next";
import { getUserFullName } from "../../../../../utils/userData";

const useStyles = makeStyles(() => ({
  root: {
    // '& .MuiFormControl-marginNormal': {
    //   marginTop: 8,
    // },
    // '& .MuiOutlinedInput-input': {
    //   padding: '14px 14px',
    // },
  },
  heading: {
    fontSize: 24,
    fontWeight: 400,
  },
  container: {
    overflowX: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    boxShadow: "none",
    paddingLeft: 16,
    paddingRight: 16,
  },
  searchBtn: {
    marginLeft: 16,
    borderRadius: 8,
    height: 48,
    padding: 8,
  },
  quater: {
    backgroundColor: "#e53935",
    fontSize: "18px",
    fontWeight: "600",
    width: 48,
    height: 48,
  },
  inProgress: {
    backgroundColor: "#eeeeee",
    color: "#9e9e9e",
    minWidth: "100px",
    borderRadius: "15px",
    padding: "5px 10px 5px 10px",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  achieved: {
    backgroundColor: "#e0f2f1",
    color: "#009688",
    width: "90px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#e0f2f1",
    },
  },
  notAchieved: {
    backgroundColor: "#f9dde0",
    color: "#c62828",
    width: "110px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#f9dde0",
    },
  },
  optionsBtn: {
    borderRadius: "50px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
  recordedStatus: {
    backgroundColor: "#e3f2fd",
    color: "#2196f3",
    width: "80px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
  },
}));

const ContainerStyled = styled(Container)({
  marginTop: "40px",
});

const StyledRoot = styled(Box)({
  "& .objectcard-wrap": {
    marginBottom: "16px",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  "& .objective-card": {
    padding: "16px",
  },
  "& .MuiFilledInput-root input": {
    padding: "16px 12px 16px 12px",
  },
  "& .MuiFilledInput-root input": {
    padding: "16px 12px 16px 12px",
  },
});

const getColorLinearStart = (isManager) => {
  if (isManager) {
    return "rgba(254, 196, 144, 0.7)";
  }
  return "rgba(104, 205, 232, 0.7)";
};

const getColorLinearEnd = (isManager) => {
  if (isManager) {
    return "rgba(235, 148, 192, 0.7)";
  }
  return "rgba(109, 238, 227, 0.7)";
};

const StyledCard = styled(Card)({
  minHeight: "70px",
  boxShadow: "none",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  background: `linear-gradient(to right, ${getColorLinearStart()}, ${getColorLinearEnd()})`,
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  "& .searchContainer": {
    marginLeft: "41px",
    marginBottom: "100px",
  },
  "@media (max-width: 600px)": {
    "& .searchContainer": {
      marginBottom: "1px",
    },
  },
});

const StyleHeadBox = styled(Box)({
  "& .box-header": {
    minHeight: "200px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor:
      "linear-gradient(to right, rgba(104, 205, 232, 0.7), rgba(109, 238, 227, 0.7))",

    "& .text-header": {
      fontSize: "48px",
      fontWeight: "bold",
      marginLeft: "40px",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginTop: "80px",
    },
    "& .img-header": {
      width: "33%",
      height: "auto",
      marginBottom: "10px",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      marginRight: "20px",
    },
  },

  "@media (max-width: 600px)": {
    "& .box-header": {
      flexDirection: "column",
      minHeight: "unset",
      justifyContent: "center",
      alignItems: "center",
      "& .text-header": {
        fontSize: "30px",
        marginLeft: "20px",
        marginTop: "20px",
      },
      "& .img-header": {
        width: "50%",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },
    },
  },
});

const ResponsiveButtonBlue = styled(Button)(({ theme }) => ({
  backgroundColor: "rgba(219, 65, 120, 1)",
  width: "40%",
  height: "50px",
  fontSize: "15px",
  fontWeight: "bold",
  borderRadius: "7px",
  "&:hover": {
    backgroundColor: "#C53B6C",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    fontSize: "14px",
    height: "40px",
  },
}));

const quaterData = [
  { id: "Q1", title: "Q1" },
  { id: "Q2", title: "Q2" },
  { id: "Q3", title: "Q3" },
  { id: "Q4", title: "Q4" },
];

const MenuOptions = (props) => {
  const { data } = props;
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls="options-menu"
        aria-haspopup="true"
        className={classes.optionsBtn}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        id="options-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          style={{ padding: 10, width: "100%" }}
          onClick={() => {
            history.push({
              pathname: "/individual",
              state: { planList: data },
              isInfo: true,
            });
          }}
        >
          View Plan
        </MenuItem>
        {data.dialogueRecorded ? (
          <>
            <MenuItem
              style={{ padding: 10 }}
              onClick={() => {
                history.push({
                  pathname: "/dialoguehistory",
                  planIdv: data,
                  isInfo: true,
                });
              }}
            >
              View Record History
            </MenuItem>
          </>
        ) : (
          <div>
            <MenuItem
              style={{ padding: 10 }}
              onClick={() => {
                history.push({
                  pathname: "/dialoguehistory",
                  planIdv: data,
                });
              }}
            >
              Record History
            </MenuItem>
          </div>
        )}
      </Menu>
    </>
  );
};

function DialogueRecord() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: idvPlanStore } = useSelector((state) => state.IdvPlans);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const isManager = userProfile && userProfile.roles.includes("ROLE_MANAGER");

  const [team, setTeam] = useState("HR TPE");
  const [quaterSelect, setQuaterSelect] = useState("Q1");
  const [yearSelect, setYearSelect] = useState(2021);
  const [date, setDate] = useState(new Date());
  const [searchKey, setSearchKey] = useState("");
  const [records, setRecords] = useState([]);
  const [recordItems, setRecordItems] = useState([]);
  // true
  const [isLoading, setIsLoading] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const headCells = [
    { id: "quater", label: "Quater" },
    { id: "objectiveName", label: t("Objectives") },
    { id: "createBy", label: t("CreateBy") },
    { id: "dateCreate", label: t("Date") },
    { id: "achieveResult", label: "Achieve" },
    { id: "dialogueRecorded", label: t("Status") },
    { id: "options", label: "" },
  ];

  const fetchData = () => {
    dispatch(getAllIdvPlan());
  };

  useEffect(() => {
    if (idvPlanStore) {
      let idvPlans = idvPlanStore.filter((x) =>
        x.planStatus.includes("Approved")
      );

      idvPlans.filter((x) => x.department && x.department.includes(team));

      let planItems = idvPlans.filter(function (item) {
        let dateItem = new Date(item.dateCreate);
        let yearItem = dateItem.getFullYear();
        return (
          item.quater === quaterSelect &&
          yearItem === yearSelect &&
          item.planStatus !== null
        );
      });

      setRecords(planItems);
      setRecordItems(planItems);
      setIsLoading(false);
    }
  }, [idvPlanStore]);

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);
  // Data first use records

  useEffect(() => {
    let x = [...recordItems];
    x = x.filter((y) => {
      return y.objectiveName
        .toLowerCase()
        .includes(searchKey.toLocaleLowerCase());
    });
    setRecords(x);
  }, [searchKey]);

  const getQuater = () => {
    var m = Math.floor(date.getMonth() / 3) + 1;
    return m > 4 ? m - 4 : m;
  };

  useEffect(() => {
    const dateYear = date.getFullYear();
    let quater = "Q" + getQuater();

    setYearSelect(dateYear);
    setQuaterSelect(quater);
    fetchData();
  }, []);

  return (
    <StyledRoot className="page">
      {userProfile && (
        <Box padding="24px 0">
          <Container maxWidth="lg">
            <StyledCard
              style={{
                marginBottom: "48px",
                marginTop: "20px",
                background: `linear-gradient(to right, 
                  ${getColorLinearStart(isManager)}, 
                  ${getColorLinearEnd(isManager)})`,
              }}
            >
              <StyleHeadBox>
                <Box className="box-header">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography className="text-header">
                        Dialogue & Record
                      </Typography>
                    </Grid>

                    <Grid item xs={12} className="searchContainer">
                      <TextFieldTheme
                        variant="filled"
                        style={{
                          maxWidth: "250px",
                          background: "#fff",
                          borderRadius: "10px",
                        }}
                        value={searchKey}
                        onChange={(e) => setSearchKey(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="center">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          placeholder: `Search`,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <img
                    className="img-header"
                    src={`${process.env.PUBLIC_URL}${
                      isManager
                        ? "/assets/okrs/manager/dialogue-record.svg"
                        : "/assets/okrs/user/dialogue-record.svg"
                    }`}
                    alt="icon"
                  />
                </Box>
              </StyleHeadBox>
            </StyledCard>
            <Toolbar>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <FormControl variant="outlined">
                        <InputLabel>Quater</InputLabel>
                        <Select
                          label="Quater"
                          value={quaterSelect}
                          style={{
                            minWidth: "180px",
                          }}
                          onChange={(e) => setQuaterSelect(e.target.value)}
                        >
                          {quaterData.map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.id}
                              style={{ display: "grid" }}
                            >
                              {item.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <DatePickerCustom
                        margin="normal"
                        variant="inline"
                        inputVariant="outlined"
                        label={t("Year")}
                        value={date}
                        views={["year"]}
                        style={{
                          minWidth: "220px",
                          marginTop: 0,
                          marginBottom: 10,
                        }}
                        onChange={(date) => {
                          setDate(date);
                          setYearSelect(date.getFullYear());
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                    <Grid item>
                      <ResponsiveButtonBlue
                        variant="contained"
                        color="primary"
                        className={classes.searchBtn}
                        onClick={fetchData}
                        style={{ marginBottom: 10, minWidth: "80px" }}
                      >
                        <SearchIcon /> {t("Search")}
                      </ResponsiveButtonBlue>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>

            <div className={classes.root}>
              <div className="card-section">
                <Paper className={classes.container}>
                  <TblContainer>
                    <TblHead />
                    <TableBody>
                      {isLoading ? (
                        <TableRow>
                          <TableCell
                            colSpan={6}
                            align="center"
                            style={{ width: "100px", padding: "20px" }}
                          >
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      ) : (
                        <>
                          {(recordsAfterPagingAndSorting().length > 0 &&
                            recordsAfterPagingAndSorting().map((item) => (
                              <TableRow key={item.planIdvId} hover>
                                <TableCell
                                  style={{ width: "100px" }}
                                  align="center"
                                >
                                  <Avatar className={classes.quater}>
                                    {item.quater}
                                  </Avatar>
                                </TableCell>
                                <TableCell>{item.objectiveName}</TableCell>
                                <TableCell style={{ width: "250px" }}>
                                  {getUserFullName({
                                    firstname_TH: item.firstname_TH,
                                    lastname_TH: item.lastname_TH,
                                    firstname_EN: item.firstname_EN,
                                    lastname_EN: item.lastname_EN,
                                  })}
                                </TableCell>
                                <TableCell style={{ width: "120px" }}>
                                  {dayjs(item.dateCreate).format(i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY")}
                                </TableCell>
                                <TableCell style={{ width: "150px" }}>
                                  {item.planStatus === "Approved" ? (
                                    <IconButton
                                      className={
                                        item.achieveResult === "Achieved"
                                          ? `${classes.achieved}`
                                          : item.achieveResult === "Not Achieved"
                                          ? `${classes.notAchieved}`
                                          : `${classes.inProgress}`
                                      }
                                      size="large"
                                    >
                                      <Typography
                                        style={{ fontWeight: 600 }}
                                        variant="caption"
                                      >
                                        {item.achieveResult !== null
                                          ? item.achieveResult
                                          : "In Progress"}
                                      </Typography>
                                    </IconButton>
                                  ) : null}
                                </TableCell>
                                <TableCell>
                                  {item.dialogueRecorded ? (
                                    <IconButton
                                      className={classes.recordedStatus}
                                      size="large"
                                    >
                                      <Typography
                                        style={{ fontWeight: 600 }}
                                        variant="caption"
                                      >
                                        Recorded
                                      </Typography>
                                    </IconButton>
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{ width: "100px", padding: 10 }}
                                  align="center"
                                >
                                  <MenuOptions data={item} />
                                </TableCell>
                              </TableRow>
                            ))) || (
                            <TableRow>
                              <TableCell
                                colSpan={7}
                                align="center"
                                style={{ width: "100px", height: "100px" }}
                              >
                                {t("NoData")}
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                    </TableBody>
                  </TblContainer>
                  <TblPagination />
                </Paper>
              </div>
              {/* Data */}
              <DialogueRecordList recordList={records} />
            </div>
          </Container>
        </Box>
      )}
    </StyledRoot>
  );
}

export default DialogueRecord;
