import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { format, formatDistanceToNow } from "date-fns";
import { th } from "date-fns/locale";
import { styled } from "@mui/system";
import PropTypes from "prop-types";

const CardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "15px",
  margin: "10px 0",
  border: "1px solid #e0e0e0",
  borderRadius: "10px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#fff",
}));

const TopRow = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const LeftSection = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const PercentageBox = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(219, 65, 120, 0.1)",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "15px",
}));

const KeyResult = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const ObjectiveName = styled(Typography)(({ theme }) => ({
  color: "#757575",
  fontSize: "0.875rem",
}));

const DateText = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  color: "#757575",
  fontSize: "0.875rem",
}));

const Message = styled(Typography)(({ theme }) => ({
  marginTop: "10px",
  fontSize: "1rem",
  color: "#333",
}));

const ProgressText = styled(Typography)(({ theme }) => ({
  color: "#DB4178",
  fontWeight: "bold",
}));

function TimelineCard({ data }) {
  const theme = useTheme();

  if (!data) {
    console.log("Invalid or no data provided.");
    return <Typography>No data available</Typography>;
  }

  const {
    keyResultName,
    objectiveName,
    progressPercent,
    message,
    lastUpdateDate,
  } = data;

  const dateObj = new Date(lastUpdateDate);
  const formattedDate = format(dateObj, "dd MMMM yyyy", { locale: th });
  const relativeTime = formatDistanceToNow(dateObj, {
    addSuffix: true,
    locale: th,
  });

  return (
    <CardContainer>
      <TopRow>
        <LeftSection>
          <PercentageBox>
            <ProgressText variant="body2">{progressPercent}%</ProgressText>
          </PercentageBox>
          <KeyResult>
            <Typography variant="body1">{keyResultName}</Typography>
            <ObjectiveName variant="body2">({objectiveName})</ObjectiveName>
          </KeyResult>
        </LeftSection>
        <DateText>
          <Typography variant="body2">{formattedDate}</Typography>
          <Typography variant="body2">{relativeTime}</Typography>
        </DateText>
      </TopRow>
      <Message variant="body1">{message}</Message>
    </CardContainer>
  );
}

TimelineCard.propTypes = {
  data: PropTypes.shape({
    keyResultName: PropTypes.string.isRequired,
    objectiveName: PropTypes.string.isRequired,
    progressPercent: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
    lastUpdateDate: PropTypes.string.isRequired,
  }).isRequired,
};

export default TimelineCard;
