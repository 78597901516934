import React, { useState } from "react";
import { Box, Container, Tab, Tabs } from "@mui/material";
import Probation from "./probation";
import Performance from "./performance";
import { useTranslation } from "react-i18next";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Assessment = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="page" style={{ backgroundColor: "#ffffff" }}>
      <Container>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label={t("Probation")} {...a11yProps(0)} />
              <Tab label={t("AnnualEmployeeEvaluation")} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Probation />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Performance />
          </CustomTabPanel>
        </Box>
      </Container>
    </div>
  );
};

export default Assessment;
