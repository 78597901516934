import { httpClient } from "./httpClient";

const getDepartment = (query) => {
  return httpClient.get(`departments`, { params: query });
};

const getDepartmentByIdCompany = (idCompany) => {
  return httpClient.get(`company/${idCompany}/department/idCompany`);
};

const getAllDepartmentVendorEmployees = (idCompany) => {
  return httpClient.get(`company/${idCompany}/departments/employees`);
};

export default {
    getDepartment,
    getDepartmentByIdCompany,
    getAllDepartmentVendorEmployees
};
