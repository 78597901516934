import {
    USER_PROVIDENTFUNDS_FETCHING,
    USER_PROVIDENTFUNDS_FAILED,
    USER_PROVIDENTFUNDS_SUCCESS
} from '../actions/types';

const initialState = {
    result: null,
    isFetching: false,
    isError: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case USER_PROVIDENTFUNDS_FETCHING:
            return { ...state, result: null, isFetching: true, isError: false };
        case USER_PROVIDENTFUNDS_FAILED:
            return { ...state, result: null, isFetching: false, isError: true };
        case USER_PROVIDENTFUNDS_SUCCESS:
            return { ...state, result: payload, isFetching: false, isError: false };
        default:
            return state;
    }
}