import { MEMBER_FETCHING, MEMBER_FAILED, MEMBER_SUCCESS } from "./types";

import MemberService from "../services/member.service";

export const getAllMember = () => async (dispatch) => {
  try {
    dispatch({
      type: MEMBER_FETCHING,
    });
    const res = await MemberService.getAllMember();
    if (res) {
      dispatch({
        type: MEMBER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MEMBER_FAILED,
    });
    console.log(err);
  }
};
