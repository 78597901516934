import React, { useEffect, useState } from "react";
import { Box, Button, Typography, styled, Chip, SvgIcon } from "@mui/material";
import CardStyle from "../../../../../shared/general/Card";
import { AccessTimeRounded } from "@mui/icons-material";
import {
  getIndividualObjectiveForApprove,
  updateIndividualKeyResult,
} from "../../../../../../../actions/okrs";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import minMax from "dayjs/plugin/minMax";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import { ReactComponent as MoonshotIcon } from "../../../../../assets/moonshot_logo.svg";
import { useTranslation } from "react-i18next";
import MoonShotSwitch from "../../../../../shared/pages/okrs/components/MoonShotSwitch";
import ChipStatus from "../../../../../shared/pages/okrs/components/ChipStatus";

dayjs.extend(utc);
dayjs.extend(minMax);
dayjs.extend(buddhistEra);

const StyledRoot = styled(Box)(({ theme }) => ({
  "& .objective-card": {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    borderRadius: "16px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  },
  "& .objective-header": {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  "& .objective-title": {
    fontSize: "24px",
    fontWeight: "600",
    flexGrow: 1,
  },
  "& .objective-chip": {
    border: "2px solid #DB4178",
    color: "#DB4178",
    backgroundColor: "transparent",
    fontSize: "16px",
    borderRadius: "8px",
    padding: theme.spacing(0.5, 1),
  },
  "& .objective-details": {
    marginTop: theme.spacing(2),
  },
  "& .detail-label": {
    color: "#919eab",
    marginRight: theme.spacing(1),
    fontWeight: "500",
  },
  "& .key-result-card": {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    borderRadius: "16px",
    backgroundColor: "#f9f9f9",
  },
  "& .key-result-header": {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  "& .key-result-title": {
    fontSize: "20px",
    fontWeight: "600",
    flexGrow: 1,
  },
  "& .status-chip": {
    marginLeft: theme.spacing(1),
  },
  "& .moonshot-chip": {
    marginTop: theme.spacing(1),
    border: "2px solid #DB4178",
    color: "#DB4178",
    backgroundColor: "transparent",
    fontSize: "14px",
    borderRadius: "20px",
    height: "36px",
  },
  "& .action-buttons": {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
  },
}));

const ApprovalIndividualObjective = ({ idIndividualObjective, onClose }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [individualObjective, setIndividualObjective] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    dayjs.locale(i18n.resolvedLanguage);

    let isMounted = true;
    dispatch(getIndividualObjectiveForApprove(idIndividualObjective)).then(
      (res) => {
        if (isMounted) {
          setIsFetching(false);
          if (res.status === 200) {
            setIndividualObjective(res.data);
          } else {
            setIsError(true);
          }
        }
      }
    );

    return () => {
      isMounted = false;
    };
  }, [dispatch, idIndividualObjective, i18n.resolvedLanguage]);

  if (isFetching) {
    return <Typography>{t("Loading")}...</Typography>;
  }

  if (isError || !individualObjective) {
    return <Typography color="error">{t("ErrorLoadingData")}</Typography>;
  }

  const keyResults = individualObjective.keyResults || [];

  const startDates = keyResults
    .map((kr) => dayjs(kr.startDate, "YYYY-MM-DD"))
    .filter((date) => date.isValid());

  const endDates = keyResults
    .map((kr) => dayjs(kr.endDate, "YYYY-MM-DD"))
    .filter((date) => date.isValid());

  if (startDates.length === 0 || endDates.length === 0) {
    return <Typography>{t("No valid dates available")}</Typography>;
  }

  const earliestStartDate = dayjs.min(startDates);
  const latestEndDate = dayjs.max(endDates);

  const formatDate = (date) => {
    if (i18n.resolvedLanguage === "th") {
      return date.locale("th").format("D MMM BBBB");
    } else {
      return date.locale(i18n.resolvedLanguage).format("D MMM YYYY");
    }
  };

  return (
    <StyledRoot>
      <Box className="objective-card">
        <Box className="objective-header">
          <Typography className="objective-title">
            {individualObjective.objectiveName}
          </Typography>
          <Chip
            label={`${individualObjective.year}, Q${individualObjective.quarter}`}
            className="objective-chip"
          />
        </Box>
        <Box className="objective-details">
          <Typography variant="body1">
            <span className="detail-label">{t("Result")}:</span>
            {individualObjective.impactName}
          </Typography>
          {individualObjective.description && (
            <Typography variant="body1" style={{ marginTop: "8px" }}>
              <span className="detail-label">{t("Descriptions")}:</span>
              {individualObjective.description}
            </Typography>
          )}
          <Box display="flex" alignItems="center" marginTop="16px">
            <AccessTimeRounded
              fontSize="small"
              style={{ marginRight: "8px", color: "#919eab" }}
            />
            <Typography variant="body2" color="textSecondary">
              {`${formatDate(earliestStartDate)} - ${formatDate(
                latestEndDate
              )}`}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box marginTop="32px">
        <Typography variant="h6" fontWeight="600">
          {t("KeyResults")}
        </Typography>
        {individualObjective.keyResults.map((keyResult) => (
          <KeyResult key={keyResult.idIndividualKeyResult} data={keyResult} />
        ))}
      </Box>
    </StyledRoot>
  );
};

const KeyResult = (props) => {
  const { data: value } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [data, setData] = useState(value);
  const [isMoonShotPlanningValue, setIsMoonShotPlanningValue] = useState(
    data.isMoonShotPlanning === 1
  );
  const [isFetching, setIsFetching] = useState(false);

  const onSubmit = async (approve) => {
    setIsFetching(true);

    const formData = {
      isApprove: approve,
      approveDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    };

    if (approve === 1) {
      formData.isMoonShotPlanning = isMoonShotPlanningValue ? 1 : 0;
    } else {
      formData.isMoonShotPlanning = 0;
    }

    dispatch(
      updateIndividualKeyResult(data.idIndividualKeyResult, formData)
    ).then((res) => {
      setIsFetching(false);
      if (res.status === 200) {
        setData({
          ...data,
          isApprove: approve,
          isMoonShotPlanning: formData.isMoonShotPlanning,
        });
      }
    });
  };

  const getStatusApprove = (status) => {
    if (status === 1) {
      return 2;
    } else if (status === 0) {
      return 3;
    } else {
      return 1;
    }
  };

  const startDate = dayjs(data.startDate, "YYYY-MM-DD");
  const endDate = dayjs(data.endDate, "YYYY-MM-DD");

  const isStartDateValid = startDate.isValid();
  const isEndDateValid = endDate.isValid();

  const formatDate = (date) => {
    if (i18n.resolvedLanguage === "th") {
      return date.locale("th").format("D MMM BBBB");
    } else {
      return date.locale(i18n.resolvedLanguage).format("D MMM YYYY");
    }
  };

  return (
    <Box className="key-result-card">
      <Box className="key-result-header">
        <Typography className="key-result-title">
          {data.keyResultName}
        </Typography>
        {data.isApprove !== null && (
          <ChipStatus
            status={getStatusApprove(data.isApprove)}
            size="large"
            className="status-chip"
          />
        )}
      </Box>
      {data.isMoonShotPlanning === 1 && (
        <Chip
          icon={
            <SvgIcon sx={{ fontSize: "24px" }}>
              <MoonshotIcon />
            </SvgIcon>
          }
          label="Moon Shot"
          className="moonshot-chip"
        />
      )}
      {isStartDateValid && isEndDateValid && (
        <Box display="flex" alignItems="center" marginTop="8px">
          <AccessTimeRounded
            fontSize="small"
            style={{ marginRight: "8px", color: "#919eab" }}
          />
          <Typography variant="body2" color="textSecondary">
            {`${formatDate(startDate)} - ${formatDate(endDate)}`}
          </Typography>
        </Box>
      )}
      {(!isStartDateValid || !isEndDateValid) && (
        <Typography color="error">{t("Invalid date")}</Typography>
      )}
      <Box marginTop="16px">
        <Typography variant="body1">
          <span className="detail-label">{t("Strategy")}:</span>
          {data.tacticName}
        </Typography>
        <Typography variant="body1" style={{ marginTop: "8px" }}>
          <span className="detail-label">{t("MeasurementData")}:</span>
          {data.measureEvidence}
        </Typography>
        <Typography variant="body1" style={{ marginTop: "8px" }}>
          <span className="detail-label">{t("ObjectiveMeasurementData")}:</span>
          {data.commitQuality}
        </Typography>
        <Typography variant="body1" style={{ marginTop: "8px" }}>
          <span className="detail-label">{t("TypeMeasurementData")}:</span>
          {data.measureDataType === 1 ? "ตัวเลข" : "คำบรรยาย"}
        </Typography>
        {data.description && (
          <Typography variant="body1" style={{ marginTop: "8px" }}>
            <span className="detail-label">{t("Descriptions")}:</span>
            {data.description}
          </Typography>
        )}
      </Box>
      {data.isApprove === null && (
        <Box
          marginTop="24px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="center">
            <Typography className="detail-label">{t("MoonShot")}</Typography>
            <MoonShotSwitch
              checked={isMoonShotPlanningValue}
              onChange={(event) => {
                setIsMoonShotPlanningValue(event.target.checked);
              }}
              disabled={isFetching}
            />
          </Box>
          <Box className="action-buttons">
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                onSubmit(1);
              }}
              disabled={isFetching}
            >
              {t("Approve")}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                onSubmit(0);
              }}
              disabled={isFetching}
            >
              {t("Reject")}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ApprovalIndividualObjective;
