import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";

import {
  Typography,
  FormControl,
  FormControlLabel,
  MenuItem,
  Switch,
  Select,
  Grid,
  Box,
  Radio,
  RadioGroup,
} from "@mui/material";

import DatePickerCustom from "../../../../shared/date/datePicker";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import MonthPanel from "./month-panel";
import { useTranslation } from "react-i18next";
import { updatePayrollDateCustom } from "../../../../../../actions/paytypes";

const StyledRoot = styled("div")({
  "&.wrap-item-data": {
    // marginLeft: 16,
    // marginBottom: 8,
  },
  "& .item-name": {
    fontWeight: 600,
    marginBottom: 4,
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFormControlLabelSwitch = styled(FormControlLabel)({
  marginRight: 0,
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const timePayslip = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

const RoundOne = (props) => {
  const { t, i18n } = useTranslation();
  const { dataForm, handleOnChangeData, selectedPaymentType } = props;
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );
  const [payDateYearList, setPayDateYearList] = useState(null);
  const [paymentRoundData, setPaymentRoundData] = useState(null);

  useEffect(() => {
    let payDate = [];
    for (let index = -2; index < 3; index++) {
      payDate.push(dayjs(today).add(index, "year").format("YYYY"));
    }

    setPayDateYearList(payDate);
  }, []);

  const [dateCustom, setDateCustom] = useState(null);

  useEffect(() => {
    if (payrollSetting && userProfile) {
      let temp = [];
      for (let index = 0; index < 12; index++) {
        let find = payrollSetting.payrollDateCustom.find(
          (x) => x.year === 2024 && x.month === index
        );
        if (find) {
          temp.push(find);
        } else {
          temp.push({
            year: 2024,
            round: 1,
            month: index,
            payDate: null,
            payslipDate: null,
            payslipTime: payrollSetting.firstPayslipTime,
            idCompany: userProfile.idCompany,
          });
        }
      }
      console.log("temp (MonthPanel): ", temp);
      setDateCustom(temp);

      let temp2 = payrollSetting.paymentTypeRound.find(
        (x) =>
          x.round === parseInt(dataForm.paymentRound) &&
          x.idPaymentType === selectedPaymentType.id
      );
      console.log("temp2: ", temp2);
      setPaymentRoundData(temp2 || null);
    }
  }, [payrollSetting]);

  useEffect(() => {
    let temp2 = payrollSetting.paymentTypeRound.find(
      (x) =>
        x.round === parseInt(dataForm.paymentRound) &&
        x.idPaymentType === selectedPaymentType.id
    );
    console.log("temp3: ", temp2);
    setPaymentRoundData(temp2 || null);
  }, [dataForm, selectedPaymentType]);

  const handleChangeDateSetting = (data) => {
    let temp = [...dateCustom];
    temp[data.index][data.name] = data.value;
    setDateCustom(temp);
  };

  const handleOnClickSaveCustomDate = async () => {
    console.log("dateCustom: ", dateCustom);
    const result = await dispatch(updatePayrollDateCustom(dateCustom));
  };

  const handleOnClickChangePaysilpPublicType = (event) => {
    const { name, value } = event.target;
    setPaymentRoundData({ ...paymentRoundData, [name]: value });
  };

  return (
    <StyledRoot className="wrap-item-data">
      {paymentRoundData !== null ? (
        <Grid container spacing={2}>
          {/* <Grid item xs={12}>
          <div style={{ }}>
            <StyledFormControlLabelSwitch
              checked={dataForm.payslipNextMonth}
              control={<Switch size="small" />}
              label={"เปิดใช้งาน"}
              labelPlacement="active"
            />
          </div>
        </Grid> */}
          <Grid item xs={12}>
            <Box>
              <Typography style={{ fontWeight: 600 }} variant="body1">
                {t("WorkingDays")}
              </Typography>
              <div className="wrap-item-data">
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="round-payroll"
                    name="workingDay"
                    value={paymentRoundData.workingDay}
                    //onChange={handleOnChangeData}
                  >
                    <FormControlLabel
                      value={28}
                      control={
                        <Radio disabled={paymentRoundData.workingDay !== 28} />
                      }
                      label={`28 ${t("Unit.Days")}`}
                    />
                    <FormControlLabel
                      value={29}
                      control={
                        <Radio disabled={paymentRoundData.workingDay !== 29} />
                      }
                      label={`29 ${t("Unit.Days")}`}
                    />
                    <FormControlLabel
                      value={30}
                      control={
                        <Radio disabled={paymentRoundData.workingDay !== 30} />
                      }
                      label={`30 ${t("Unit.Days")}`}
                    />
                    <FormControlLabel
                      value={-1}
                      control={
                        <Radio disabled={paymentRoundData.workingDay !== -1} />
                      }
                      label={t("AccordingToTheActualQuantity")}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className="item-name">{t("DayCutOff")}</Typography>
            <StyledFormControl fullWidth>
              <Select
                inputProps={{ readOnly: true }}
                MenuProps={MenuProps}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="firstCutRoundDate"
                value={paymentRoundData.cutRoundDate}
              >
                <MenuItem key={99} value={-1}>
                  {t("LastDayOfMonth")}
                </MenuItem>
                {[...Array(29).keys()].map((item) => (
                  <MenuItem key={item} value={item + 1}>
                    {item + 1}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className="wrap-label-switch">
              <Typography className="item-name">
                {t("cutOffApproval")}
              </Typography>
              <StyledFormControlLabelSwitch
                checked={paymentRoundData.cutApproveNextMonth}
                control={<Switch size="small" />}
                label={t("NextMonth")}
                labelPlacement="nextMonth"
              />
            </div>

            <StyledFormControl fullWidth>
              <Select
                inputProps={{ readOnly: true }}
                MenuProps={MenuProps}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="cutApproveDate"
                value={paymentRoundData.cutApproveDate}
              >
                {[...Array(31).keys()].map((item) => (
                  <MenuItem key={item} value={item + 1}>
                    {item + 1}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className="wrap-label-switch">
              <Typography className="item-name">
                {t("overtimeCalculation")}
              </Typography>
            </div>

            <StyledFormControl fullWidth>
              <Select
                MenuProps={MenuProps}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="roundOT"
                value={paymentRoundData.roundOT}
              >
                <MenuItem key={1} value={"now"}>
                  {t("cutOffPeriod")}
                </MenuItem>
                <MenuItem key={2} value={"previous"}>
                  {t("oneMonthBack")}
                </MenuItem>
              </Select>
            </StyledFormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className="wrap-label-switch">
              <Typography className="item-name">
                {t("latenessCalculation")}/{t("Absent")}/{t("AppMenu.Leave")}
              </Typography>
            </div>

            <StyledFormControl fullWidth>
              <Select
                MenuProps={MenuProps}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="roundLateAbsentLeave"
                value={paymentRoundData.roundLateAbsentLeave}
              >
                <MenuItem key={1} value={"now"}>
                  {t("cutOffPeriod")}
                </MenuItem>
                <MenuItem key={2} value={"previous"}>
                  {t("oneMonthBack")}
                </MenuItem>
              </Select>
            </StyledFormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="wrap-label-switch">
              <Typography className="item-name">
                {t("paySlipDateFormat")}
              </Typography>
            </div>
            <StyledFormControl fullWidth>
              <Select
                MenuProps={MenuProps}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="paysilpPublicType"
                value={paymentRoundData.paysilpPublicType}
                onChange={handleOnClickChangePaysilpPublicType}
              >
                <MenuItem key={"1"} value={"auto"}>
                  {t("automatic")}
                </MenuItem>
                <MenuItem key={"2"} value={"custom"}>
                  {t("Custom")}
                </MenuItem>
              </Select>
            </StyledFormControl>
          </Grid>
          {paymentRoundData.paysilpPublicType === "custom" && (
            <Fragment>
              <Grid item xs={12} sm={3}>
                <div className="wrap-label-switch">
                  <Typography className="item-name">
                    {t("annualSettings")}
                  </Typography>
                </div>
                <StyledFormControl fullWidth>
                  <Select
                    MenuProps={MenuProps}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="settingYear"
                    value={paymentRoundData.settingYear}
                    onChange={handleOnChangeData}
                  >
                    <MenuItem key={"1"} value={2024}>
                      2024
                    </MenuItem>
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="item-name">
                  {t("paySlipTime")}
                </Typography>
                <StyledFormControl fullWidth>
                  <Select
                    inputProps={{ readOnly: true }}
                    MenuProps={MenuProps}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="firstPayslipTime"
                    value={paymentRoundData.firstPayslipTime}
                  >
                    {timePayslip.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={12}>
                {dateCustom.map((item) => (
                  <MonthPanel
                    item={item}
                    handleOnChange={handleChangeDateSetting}
                  />
                ))}
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <ButtonBlue
                    variant="contained"
                    onClick={handleOnClickSaveCustomDate}
                  >
                    SAVE
                  </ButtonBlue>
                </div>
              </Grid>
            </Fragment>
          )}

          {paymentRoundData.paysilpPublicType === "auto" && (
            <Fragment>
              <Grid item xs={12} sm={6}>
                <Fragment>
                  <div className="wrap-label-switch">
                    <Typography className="item-name">
                      {t("salaryPaymentDate")}
                    </Typography>
                    <StyledFormControlLabelSwitch
                      checked={paymentRoundData.salaryPayNextMonth}
                      control={<Switch size="small" />}
                      label={t("NextMonth")}
                      labelPlacement="nextMonth"
                    />
                  </div>
                  <StyledFormControl fullWidth>
                    <Select
                      MenuProps={MenuProps}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="salaryPayDate"
                      value={paymentRoundData.salaryPayDate}
                    >
                      <MenuItem key={99} value={-1}>
                        {t("LastDayOfMonth")}
                      </MenuItem>
                      {[...Array(29).keys()].map((item) => (
                        <MenuItem key={item} value={item + 1}>
                          {item + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </Fragment>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="wrap-label-switch">
                  <Typography className="item-name">
                    {t("paySlipReleaseDay")}
                  </Typography>
                  <StyledFormControlLabelSwitch
                    checked={paymentRoundData.paysilpPublicNextMonth}
                    control={<Switch size="small" />}
                    label={t("NextMonth")}
                    labelPlacement="nextMonth"
                  />
                </div>

                <StyledFormControl fullWidth>
                  <Select
                    MenuProps={MenuProps}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="paysilpPublicDate"
                    value={paymentRoundData.paysilpPublicDate}
                  >
                    <MenuItem key={99} value={-1}>
                      {t("LastDayOfMonth")}
                    </MenuItem>
                    {[...Array(29).keys()].map((item) => (
                      <MenuItem key={item} value={item + 1}>
                        {item + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="item-name">
                  {t("paySlipTime")}
                </Typography>
                <StyledFormControl fullWidth>
                  <Select
                    inputProps={{ readOnly: true }}
                    MenuProps={MenuProps}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="paysilpPublicTime"
                    value={paymentRoundData.paysilpPublicTime}
                  >
                    {timePayslip.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
            </Fragment>
          )}
        </Grid>
      ) : (
        <div style={{ padding: 24 }}>
          <Typography color="text.third" variant="body1">ไม่มีตั้งค่าในระบบ</Typography>
        </div>
      )}
    </StyledRoot>
  );
};

export default RoundOne;
