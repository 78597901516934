import { httpClient } from "./httpClient";

const getAllSubJobStructure = () => {
  return httpClient.get("subJobStructures");
};

const postSubJobStructure = (data) => {
  return httpClient.post(`subJobStructures/postSubJobStructure`, data);
};

const putSubJobStructure = (id, data) => {
  return httpClient.post(`subJobStructures/putSubJobStructure/${id}`, data);
};

const deleteSubJobStructure = (id) => {
  return httpClient.post(`subJobStructures/deleteSubJobStructure/${id}`);
};

export default {
  getAllSubJobStructure,
  postSubJobStructure,
  putSubJobStructure,
  deleteSubJobStructure,
};
