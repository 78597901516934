import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  Button,
  Grid,
  Typography,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  DialogContent,
  Box,
  TextField,
  Select,
  DialogContentText,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import { useSelector, useDispatch } from "react-redux";
import {
  postJobPosition,
  putJobPosition,
} from "../../../../../../actions/jobPositions";

import CloseIcon from "@mui/icons-material/Close";

function AddJobPosition({ open, subJobId, positionData, close, fetchData }) {
  const dispatch = useDispatch();

  const [jobPosition, setJobPosition] = useState({
    idJobPosition: 0,
    name: "",
    subJobId: 0,
  });

  const [errors, setErrors] = useState({
    name: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setJobPosition({
      ...jobPosition,
      [name]: value,
    });
  };

  const onPostJobPosition = async (formData) => {
    await dispatch(postJobPosition(formData));
    close();
    fetchData();
  };

  const onPutJobPosition = async (id, formData) => {
    await dispatch(putJobPosition(id, formData));
    close();
    fetchData();
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (jobPosition.name) {
      if (jobPosition.idJobPosition === 0) {
        const formData = {
          jobPositionName: jobPosition.name,
          idSubJobStructure: jobPosition.subJobId,
        };

        onPostJobPosition(formData);
      } else {
        const formData = {
          jobPositionName: jobPosition.name,
          jobFit: null,
          idSubJobStructure: jobPosition.subJobId,
          JEScore: null,
          jobLevel: null,
          jobClassMin: null,
          jobClassMax: null,
          company: null,
          division: null,
          department: null,
          section: null,
          fieldExperience: null,
          experienceNeed: null,
          location: null,
          reportTo: null,
          education: null,
          roleResponsibility: null,
          otherRequirement: null,
          idJobPosition: jobPosition.idJobPosition,
        };

        onPutJobPosition(formData.idJobPosition, formData);
      }
    } else {
      setErrors({ name: true });
    }
  };

  useEffect(() => {
    if (positionData === undefined) {
      //Add
      setJobPosition({
        idJobPosition: 0,
        subJobId: subJobId,
      });
    } else {
      //Edit
      setJobPosition({
        idJobPosition: positionData.idJobPosition,
        subJobId: positionData.idSubJobStructure,
        name: positionData.jobPositionName,
      });
    }

    setErrors({ name: false });
  }, [open]);

  return (
    <Dialog open={open} keepMounted onClose={close} fullWidth="sm">
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">Create a new Job Position</Typography>
          </Grid>
          <Grid item>
            <CloseIcon
              style={{
                cursor: "pointer",
              }}
              onClick={close}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          name="name"
          value={jobPosition.name}
          onChange={handleInputChange}
          placeholder="Name"
          margin="dense"
          fullWidth
          error={errors.name}
          helperText={errors.name ? "This field is required." : null}
          sx={{ minWidth: "300px" }}
        />
      </DialogContent>
      <DialogActions>
        <Grid style={{ padding: 20, textAlign: "right", paddingTop: 0 }}>
          <Button onClick={handleOnSubmit} variant="contained">
            Submit
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default AddJobPosition;
