import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Dialog, Typography, Stack } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ButtonBlue from "../../shared/general/ButtonBlue";

import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import TranslateIcon from "@mui/icons-material/Translate";
import IconButton from "@mui/material/IconButton";

import { addPdpaApprove } from "../../../../actions/pdpaApprove";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const StyledIconButtonTranslate = styled(IconButton)(({ }) => ({
    "&:hover": {
        transform: "scale(1.09) translateZ(0px)",
    },
}));

const DialogPdpaApprove = (props) => {
    //Translator TH-EN
    const [anchorEl_Trans, setAnchorEl_Trans] = React.useState(null);
    const open_Trans = Boolean(anchorEl_Trans);

    const handleClick_Trans = (event) => {
        setAnchorEl_Trans(event.currentTarget);
    };
    const handleClose_Trans = () => {
        setAnchorEl_Trans(null);
    };

    const { t, i18n } = useTranslation();
    const [isChecked, setChecked] = useState(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        handleClose_Trans();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePdpaApprove = async (isApprove) => {
        const response = await addPdpaApprove(isApprove);
        if (response) {
            handleClose();
        }
    }

    const { open, setOpen } = props


    return (
        <div>
            <React.Fragment>
                <BootstrapDialog
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <DialogTitle fontSize={28} sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                            {`${t("PdpaApprove.Unihr")}`}
                        </DialogTitle>

                        <div
                            className="translate"
                            style={{ marginRight: 15 }}
                        >
                            <StyledIconButtonTranslate
                                aria-label="translate"
                                size="small"
                                aria-controls={open_Trans ? "basic-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open_Trans ? "true" : undefined}
                                onClick={handleClick_Trans}
                            >
                                <TranslateIcon fontSize="small" />
                            </StyledIconButtonTranslate>
                            <Menu
                                anchorEl={anchorEl_Trans}
                                open={open_Trans}
                                onClose={handleClose_Trans}
                                MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                }}
                            >
                               <MenuItem onClick={() => changeLanguage("th")}>
        <img
          src="https://flagcdn.com/16x12/th.png"
          alt="ภาษาไทย"
          style={{ marginRight: "8px" }}
        />
        ภาษาไทย
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("en")}>
        <img
          src="https://flagcdn.com/16x12/gb.png"
          alt="English"
          style={{ marginRight: "8px" }}
        />
        English
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("la")}>
        <img
          src="https://flagcdn.com/16x12/la.png"
          alt="ລາວ"
          style={{ marginRight: "8px" }}
        />
        ລາວ
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("jp")}>
        <img
          src="https://flagcdn.com/16x12/jp.png"
          alt="日本"
          style={{ marginRight: "8px" }}
        />
        日本
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("kh")}>
        <img
          src="https://flagcdn.com/16x12/kh.png"
          alt="កម្ពុជា"
          style={{ marginRight: "8px" }}
        />
        កម្ពុជា
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("mm")}>
        <img
          src="https://flagcdn.com/16x12/mm.png"
          alt="မြန်မာ"
          style={{ marginRight: "8px" }}
        />
        မြန်မာ
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("cn")}>
        <img
          src="https://flagcdn.com/16x12/cn.png"
          alt="中国"
          style={{ marginRight: "8px" }}
        />
        中国
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("kr")}>
        <img
          src="https://flagcdn.com/16x12/kr.png"
          alt="대한민국"
          style={{ marginRight: "8px" }}
        />
        대한민국
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("vn")}>
        <img
          src="https://flagcdn.com/16x12/vn.png"
          alt="Việt Nam"
          style={{ marginRight: "8px" }}
        />
        Việt Nam
      </MenuItem>
                            </Menu>
                        </div>
                    </Stack>

                    <DialogContent dividers>
                        <Typography fontSize={22} gutterBottom>
                            {`${t("PdpaApprove.PrivacyPolicyForCustomer")}`}
                        </Typography>
                        <Typography variant="body1" style={{ color: 'gray' }} gutterBottom>
                            {`${t("PdpaApprove.PrivacyPolicyForCustomerComment")}`}
                        </Typography>
                        <Typography fontSize={22} gutterBottom>
                            {`${t("PdpaApprove.CollectionOfPersonalData")}`}
                        </Typography>
                        <Typography variant="body1" style={{ color: 'gray' }} gutterBottom>
                            {`${t("PdpaApprove.CollectionOfPersonalDataComment")}`} <br />
                            {`${t("PdpaApprove.CollectionOfPersonalDataComment1")}`} <br />
                            {`${t("PdpaApprove.CollectionOfPersonalDataComment2")}`} <br />
                            {`${t("PdpaApprove.CollectionOfPersonalDataComment3")}`}
                        </Typography>
                        <Typography fontSize={22} gutterBottom>
                            {`${t("PdpaApprove.TypesOfDataCollected")}`}
                        </Typography>
                        <Typography variant="body1" style={{ color: 'gray' }} gutterBottom>
                            <span style={{ color: 'black' }}>{`${t("PdpaApprove.TypesOfDataCollectedTitle1")}`} </span>
                            {`${t("PdpaApprove.TypesOfDataCollectedSubTitle1")}`} <br />
                            <span style={{ color: 'black' }}>{`${t("PdpaApprove.TypesOfDataCollectedTitle2")}`} </span>
                            {`${t("PdpaApprove.TypesOfDataCollectedSubTitle2")}`} <br />
                            <span style={{ color: 'black' }}>{`${t("PdpaApprove.TypesOfDataCollectedTitle3")}`} </span>
                            {`${t("PdpaApprove.TypesOfDataCollectedSubTitle3")}`} <br />
                            <span style={{ color: 'black' }}>{`${t("PdpaApprove.TypesOfDataCollectedTitle4")}`} </span>
                            {`${t("PdpaApprove.TypesOfDataCollectedSubTitle4")}`}
                        </Typography>
                        <Typography fontSize={22} gutterBottom>
                            {`${t("PdpaApprove.Children")}`}
                        </Typography>
                        <Typography variant="body1" style={{ color: 'gray' }} gutterBottom>
                            {`${t("PdpaApprove.ChildrenComment")}`}
                        </Typography>
                        <Typography fontSize={22} gutterBottom>
                            {`${t("PdpaApprove.StorageOfData")}`}
                        </Typography>
                        <Typography variant="body1" style={{ color: 'gray' }} gutterBottom>
                            {`${t("PdpaApprove.StorageOfDataTitle1")}`}
                            <br />
                            {`${t("PdpaApprove.StorageOfDataTitle2")}`}
                            <br />
                            <div style={{ marginTop: '10px' }}>
                                {`${t("PdpaApprove.StorageOfDataComment")}`}
                            </div>
                        </Typography>
                        <Typography fontSize={22} gutterBottom>
                            {`${t("PdpaApprove.UseOfData")}`}
                        </Typography>
                        <Typography variant="body1" style={{ color: 'gray' }} gutterBottom>
                            {`${t("PdpaApprove.UseOfDataTitle")}`} <br />
                            <div style={{ marginTop: '10px' }}>
                                {`${t("PdpaApprove.UseOfDataComment1")}`} <br />
                                {`${t("PdpaApprove.UseOfDataComment2")}`} <br />
                                {`${t("PdpaApprove.UseOfDataComment3")}`} <br />
                                {`${t("PdpaApprove.UseOfDataComment4")}`} <br />
                            </div>
                        </Typography>
                        <Typography fontSize={22} gutterBottom>
                            {`${t("PdpaApprove.DataRetention")}`}
                        </Typography>
                        <Typography variant="body1" style={{ color: 'gray' }} gutterBottom>
                            {`${t("PdpaApprove.DataRetentionComment")}`}
                        </Typography>
                        <Typography fontSize={22} gutterBottom>
                            {`${t("PdpaApprove.DataSubjectRights")}`}
                        </Typography>
                        <Typography variant="body1" style={{ color: 'gray' }} gutterBottom>
                            {`${t("PdpaApprove.DataSubjectRightsSub")}`}
                            <br />
                            <div style={{ marginTop: '10px' }}>
                                <span style={{ color: 'black' }}>{`${t("PdpaApprove.DataSubjectRightsSubTitle1")}`} </span>
                                {`${t("PdpaApprove.DataSubjectRightsComment1")}`}
                                <br />
                                <span style={{ color: 'black' }}>{`${t("PdpaApprove.DataSubjectRightsSubTitle2")}`} </span>
                                {`${t("PdpaApprove.DataSubjectRightsComment2")}`}
                                <br />
                                <span style={{ color: 'black' }}>{`${t("PdpaApprove.DataSubjectRightsSubTitle3")}`} </span>
                                {`${t("PdpaApprove.DataSubjectRightsComment3")}`}
                                <br />
                                <span style={{ color: 'black' }}>{`${t("PdpaApprove.DataSubjectRightsSubTitle4")}`} </span>
                                {`${t("PdpaApprove.DataSubjectRightsComment4")}`}
                                <br />
                                <span style={{ color: 'black' }}>{`${t("PdpaApprove.DataSubjectRightsSubTitle5")}`} </span>
                                {`${t("PdpaApprove.DataSubjectRightsComment5")}`}
                                <br />
                                <span style={{ color: 'black' }}>{`${t("PdpaApprove.DataSubjectRightsSubTitle6")}`} </span>
                                {`${t("PdpaApprove.DataSubjectRightsComment6")}`}
                                <br />
                                <span style={{ color: 'black' }}>{`${t("PdpaApprove.DataSubjectRightsSubTitle7")}`} </span>
                                {`${t("PdpaApprove.DataSubjectRightsComment7")}`}
                                <br />
                                <span style={{ color: 'black' }}>{`${t("PdpaApprove.DataSubjectRightsSubTitle8")}`} </span>
                                {`${t("PdpaApprove.DataSubjectRightsComment8")}`}
                                <br />
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                {`${t("PdpaApprove.DataSubjectRightsEnd")}`}
                            </div>
                        </Typography>
                        <Typography fontSize={22} gutterBottom>
                            {`${t("PdpaApprove.DataSecurity")}`}
                        </Typography>
                        <Typography variant="body1" style={{ color: 'gray' }} gutterBottom>
                            {`${t("PdpaApprove.DataSecurityComment")}`}
                        </Typography>
                        <Typography fontSize={22} gutterBottom>
                            {`${t("PdpaApprove.DataBreachNotification")}`}
                        </Typography>
                        <Typography variant="body1" style={{ color: 'gray' }} gutterBottom>
                            {`${t("PdpaApprove.DataBreachNotificationComment")}`}
                        </Typography>
                        <Typography fontSize={22} gutterBottom>
                            {`${t("PdpaApprove.ChangesToThisPrivacyPolicy")}`}
                        </Typography>
                        <Typography variant="body1" style={{ color: 'gray' }} gutterBottom>
                            {`${t("PdpaApprove.ChangesToThisPrivacyPolicyComment1")}`}
                            <div style={{ marginTop: '10px' }}>
                                {`${t("PdpaApprove.ChangesToThisPrivacyPolicyComment2")}`}
                            </div>
                        </Typography>
                        <Typography fontSize={22} gutterBottom>
                            {`${t("PdpaApprove.LinksToOtherSites")}`}
                        </Typography>
                        <Typography variant="body1" style={{ color: 'gray' }} gutterBottom>
                            {`${t("PdpaApprove.LinksToOtherSitesComment")}`}
                        </Typography>
                        <Typography fontSize={22} gutterBottom>
                            {`${t("PdpaApprove.ContactInformation")}`}
                        </Typography>
                        <Typography variant="body1" style={{ color: 'gray' }} gutterBottom>
                            {`${t("PdpaApprove.ContactInformationSub")}`}
                        </Typography>
                        <Typography fontSize={22} gutterBottom>
                            {`${t("PdpaApprove.ContactInformationTitle1")}`}
                        </Typography>
                        <Typography variant="body1" style={{ color: 'gray' }} gutterBottom>
                            {`${t("PdpaApprove.ContactInformationComment1")}`}
                            <br />
                            <div style={{ marginTop: '10px' }}>
                                {`${t("PdpaApprove.ContactInformationComment2")}`}
                                <br />
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                {`${t("PdpaApprove.ContactInformationComment3")}`}
                            </div>
                        </Typography>
                        <Typography fontSize={22} gutterBottom>
                            {`${t("PdpaApprove.ContactInformationTitle2")}`}
                        </Typography>
                        <Typography variant="body1" style={{ color: 'gray' }} gutterBottom>
                            {`${t("PdpaApprove.ContactInformationComment4")}`}
                            <br />
                            <div style={{ marginTop: '10px' }}>
                                {`${t("PdpaApprove.ContactInformationComment5")}`}
                                <br />
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                {`${t("PdpaApprove.ContactInformationComment6")}`}
                            </div>
                        </Typography>
                        <FormControlLabel
                            control={<Checkbox checked={isChecked} onChange={() => setChecked(!isChecked)} />}
                            label={<span style={{ color: 'black' }}>{`${t("PdpaApprove.AcceptPdpa")}`}</span>}
                        />
                    </DialogContent>
                    <DialogActions>
                        <ButtonBlue
                            style={{ marginRight: 10, minWidth: 100 }}
                            type="submit"
                            variant="contained"
                            disabled={!isChecked}
                            onClick={() => handlePdpaApprove(1)}
                        >
                            ยืนยัน
                        </ButtonBlue>
                    </DialogActions>
                </BootstrapDialog>
            </React.Fragment>
        </div >
    )
}

export default DialogPdpaApprove