import React, { useEffect, useState } from "react";
import { getAllProjectEmployees, addUsersToProject, getUsersInProject } from "../../../../actions/workforceEmployees";
import { useDispatch, useSelector } from "react-redux";
// import { makeStyles } from '@material-ui/core/styles';
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    TextareaAutosize
} from "@mui/material";
// import Textarea from '@mui/joy/Textarea';
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import NumberFormatTheme from "../../shared/general/NumberFormatTheme";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { getAllEmployees } from "../../../../actions/employee";
import {
    addUserWorkforceHourTable,
    updateUserWorkforceHourTable,
    getUserworkforceShowHourTable,
    getUsersInProjectById

} from "../../../../actions/workforceEmployees";
import * as yup from 'yup';
import { useTranslation } from "react-i18next";
const DialogCreateUser = ({ open, handleClose, projectid, week, month, year,hour, detail }) => {
    const dispatch = useDispatch();
 const {t} = useTranslation();
    const { result: employees } = useSelector((state) => state.employees);
    const { result: projectEmployees } = useSelector((state) => state.projectEmployees);

    //here
    const validationSchema = yup.object().shape({
        hour: yup
            .string()
            .matches(/^\d{1,2}$/, t("pleaseEnterOnly1to2Digits"))
            .required(t("pleaseEnterHours")),
        detail: yup
        .string()
        .required(t("PleaseEnterDetails"))
        .max(255, t("detailsMustNotExceed255Characters")),
    });


    const { reset, control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            hour: "",
            detail: "",

        },
        resolver: yupResolver(validationSchema),

    })

    useEffect(() => {
        if (open === true) {
            reset({
                hour: "",
                detail: "",

            })
        }
    }, [open]);

    const [alertText, setAlertText] = useState('')
    const onSubmit = async (data) => {

        try {
            const formData = {
                week: week,
                detail: data.detail ? data.detail : detail,
                hour: data.hour ? data.hour : hour,
                projectID: projectid,
                month: month,
                year: year
            };


            if (hour === '') {

                const result = await dispatch(addUserWorkforceHourTable(formData));
                if (result && result.status === 200) {
                    await dispatch(getUserworkforceShowHourTable());
                    await dispatch(getUsersInProjectById());
                    handleClose();
                }
            } else {

                const result = await dispatch(updateUserWorkforceHourTable(formData));
                if (result && result.status === 200) {
                    await dispatch(getUserworkforceShowHourTable());
                    await dispatch(getUsersInProjectById());
                    handleClose();

                }
            }


        } catch (error) {
            console.error("Error submitting data:", error);
        }
    }

   

    const [selectedEmployee, setSelectedEmployee] = useState();
    return (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth={"sm"}>
            <DialogTitle>
                {`${t("week")} ${week}`}
            </DialogTitle>

            <form onSubmit={handleSubmit(onSubmit)}>

                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={20} sm={15}>
                            <Controller
                                name="hour"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t("enterHours")
                                    },
                                    pattern: {
                                        value: /^\d{1,2}$/,
                                        message: t("pleaseEnterOnly1to2Digits")
                                    }
                                }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        type="number"
                                        label={t("enterHours")}
                                        sx={{ width: '100%' }}
                                        onChange={onChange}
                                        value={value}
                                        helperText={error ? error.message : ''}
                                        error={!!error}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={20} sm={15}>
                            <Controller
                                name="detail"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t("PleaseEnterYourFirstName")
                                    },
                                }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (


                                    <TextField
                                        multiline
                                        rows={5}
                                        sx={{ width: '100%' }}
                                        value={value}
                                        label={t("Details")}
                                        helperText={error ? error.message : ''}
                                        onChange={onChange}
                                        error={!!error}
                                    />
                                )}
                            />
                           
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button color="error" type="button" onClick={handleClose}>
                        {t("Cancel")}
                    </Button>
                    <ButtonBlue variant="contained" type="submit" >
                        {t("Confirm")}
                    </ButtonBlue>
                </DialogActions>
            </form>
        </Dialog>
    );
};


export default DialogCreateUser;