import React, { Fragment, useState, useEffect } from "react";
import { Autocomplete, Avatar, Box, Container, FilledInput, FormControl, Grid, IconButton, InputAdornment, Link, Menu, MenuItem, ToggleButton, ToggleButtonGroup, Typography, styled } from "@mui/material"
import { Controller, useForm } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import ButtonBlue from "../../../pages/shared/general/ButtonBlue";
import TextFieldTheme from "../../../pages/shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getManager } from "../../../../actions/manager";
import { getAffiliateCompany } from "../../../../actions/affiliate";
import { getCompanyOrganizationList } from "../../../../actions/company";

const StyledBoxSearch = styled(Box)({
    "& .label": {
      fontWeight: 600,
      fontSize: 14,
      marginBottom: 8,
    },
  });

const StyledFilledInput = styled(FilledInput)({
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      paddingTop: 18,
      paddingBottom: 18,
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  });

const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    border: 0,
    "& .MuiFilledInput-root": {
      backgroundColor: "#919eab14",
      height: 56,
      padding: "0px 12px",
      borderRadius: 8,
      "&.Mui-focused": {
        backgroundColor: "#919eab14",
      },
      "& .MuiInputAdornment-root": {
        width: 32,
        marginTop: "0!important",
        fontSize: 24,
        color: "#919EAB",
        "& i": {
          marginRight: 8,
        },
      },
      "& .MuiAutocomplete-endAdornment": {
        "& .MuiButtonBase-root": {
          fontSize: 14,
          width: 22,
          height: 22,
        },
      },
      "&:hover": {
        backgroundColor: "#919eab29",
        "&:before": {
          border: "none !important",
        },
      },
      "&::after": {
        border: "none",
      },
      "&::before": {
        border: "none",
      },
    },
  });

  const SearchManager = ({
    onSearch,
    businessUnits,
    divisions,
    departments,
    sections,
    positions,
  }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [moreFilter, setMoreFilter] = useState(false);
  const [isTerminate, setIsTerminate] = useState(0);

  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);
  const { result: manager } = useSelector(state => state.manager);

  const { result: affiliateList } = useSelector((state) => state.affiliate);
  const { result: OrganizationList } = useSelector((state) => state.organizationList);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [departmentList, setDepartmentList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState({ idDepartment: "all", departmentName: `${t("All")}` });
  const [selectedSection, setSelectedSection] = useState({ idSection: "all", sectionName: `${t("All")}` });

  const useHookForm = useForm({
    defaultValues: {
      name: "",
      company: { idCompany: "all", companyName: `${t("All")}` },
      businessUnit: { idBusinessUnit: "all", businessUnitName: `${t("All")}` },
      division: { idDivision: "all", divisionName: `${t("All")}` },
      department: { idDepartment: "all", departmentName: `${t("All")}` },
      section: { idSection: "all", sectionName: `${t("All")}` },
      position: { idPosition: "all", positionName: `${t("All")}` },
      manager: { idEmployees: "all", firstname_TH: `${t("All")}`, lastname_TH: "" },
    },
    mode: "all",
  });

  useEffect(() => {
    dispatch(getManager());
    dispatch(getAffiliateCompany({mode: "manager"}));
  }, [dispatch]);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getCompanyOrganizationList({
        idCompany: selectedCompany.idCompany,
        getDepartment: true,
        getSection: true,
        mode: "manager",
      }));
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (OrganizationList) {
      setDepartmentList(OrganizationList.departments);
      setSectionList(OrganizationList.sections);
    }
  }, [OrganizationList]);

  const handleSearchSubmit = (data) => {
    onSearch(data);
  };

  const handleChangeFilterEmployee = (event, newStatus) => {
    if (newStatus !== null) {
      setIsTerminate(Number(newStatus));
      useHookForm.handleSubmit(handleSearchSubmit)();
    }
  };

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
    setSelectedDepartment({
      idDepartment: "all",
      departmentName: `${t("All")}`,
      sections: [],
    });
    setSelectedSection({
      idSection: "all",
      sectionName: `${t("All")}`,
    });
  };

  return (
    <form onSubmit={useHookForm.handleSubmit(handleSearchSubmit)}>
      <Grid container spacing={2}>
      <Grid item xs={12} container justifyContent="flex-end" alignItems="end">
        <Link component="button" type="button" variant="body2" onClick={() => setMoreFilter(!moreFilter)}>
            {moreFilter ? t("HideSearch") : t("SearchMore")}
        </Link>
        </Grid>

        {/* Name Search Field */}
        <Grid item xs={12} md={6} sx={{ marginTop: '-30px' }}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              {t("Search")}
            </Typography>
            <Controller
              name="name"
              control={useHookForm.control}
              render={({ field }) => (
                <FormControl fullWidth variant="filled">
                  <StyledFilledInput
                    {...field}
                    placeholder={`${t("Search")}${t("FullName")}`}
                    name="search"
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              )}
            />
          </StyledBoxSearch>
        </Grid>

         {/* Company Search Field */}
        <Grid item xs={12} md={6} sx={{ marginTop: '-30px' }}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              {t("Company")}
            </Typography>
            <Controller
              name="company"
              control={useHookForm.control}
              render={({ field }) => (
                <StyledAutocomplete
                  {...field}
                  options={affiliateList || []}
                  getOptionLabel={(option) => option.companyName}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      variant="filled"
                      placeholder={t("Company")}
                    />
                  )}
                  onChange={(_, value) => {
                    onChangeCompany(value);
                    field.onChange(value);
                  }}
                  disableClearable
                />
              )}
            />
          </StyledBoxSearch>
        </Grid>



          {/* Section Field */}
        {moreFilter && (
        <Fragment>
        {/* Section Field */}
          <Grid item xs={12} md={6} sx={{ marginTop: '-10px' }}>
            <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("Section")}
                </Typography>
                <Controller
                  name="section"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <StyledAutocomplete
                      {...field}
                      options={[
                        { idSection: "all", sectionName: `${t("All")}` },
                        ...sections.map((sectionName) => ({
                          idSection: sectionName,
                          sectionName,
                        })),
                      ]}
                      getOptionLabel={(option) => option.sectionName}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          variant="filled"
                          placeholder={t("Section")}
                        />
                      )}
                      onChange={(_, value) => field.onChange(value)}
                      disableClearable
                    />
                  )}
                />
            </StyledBoxSearch>
          </Grid>

            {/* Department Field */}
            <Grid item xs={12} md={6} sx={{ marginTop: '-10px' }}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("Department")}
                </Typography>
                <Controller
                  name="department"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <StyledAutocomplete
                      {...field}
                      options={[
                        { idDepartment: "all", departmentName: `${t("All")}` },
                        ...departments.map((departmentName) => ({
                          idDepartment: departmentName,
                          departmentName,
                        })),
                      ]}
                      getOptionLabel={(option) => option.departmentName}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          variant="filled"
                          placeholder={t("Department")}
                        />
                      )}
                      onChange={(_, value) => field.onChange(value)}
                      disableClearable
                    />
                  )}
                />
              </StyledBoxSearch>
            </Grid>
          </Fragment>
        )}

        <Grid item xs={12} container justifyContent="flex-end">
          <ButtonBlue variant="contained" type="submit">
            {t("Search")}
          </ButtonBlue>
        </Grid>
      </Grid>
    </form>
  );
};

export default SearchManager;