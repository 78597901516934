import { httpClient } from "./httpClient";
import dayjs from "dayjs";

const getAllRequestTimeById = (search) => {
  return httpClient.get(
    `request-time?filter=id&start=${dayjs(search.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(search.end).format("YYYY-MM-DD")}`
  );
};

const getAllRequestTimeBy = (filter, search, idCompany) => {
  return httpClient.get(`request-time`, {
    params: {
      filter: filter ? filter : undefined,
      start:
        search && search.start
          ? dayjs(search.start).format("YYYY-MM-DD")
          : undefined,
      end:
        search && search.end
          ? dayjs(search.end).format("YYYY-MM-DD")
          : undefined,
      idCompany: idCompany,
    },
  });
};

const getAllRequestTimeByManager = (query) => {
  return httpClient.get(`request-time`, { params: query });
};

const getAllRequestTimeWithDrawByManager = (query) => {
  return httpClient.get(`request-time/withdraw`, { params: query, });
};

const getAllRequestTimeWithDrawBy = (filter, search, idCompany) => {
  return httpClient.get(`request-time/withdraw`, {
    params: {
      filter: filter ? filter : undefined,
      start:
        search && search.start
          ? dayjs(search.start).format("YYYY-MM-DD")
          : undefined,
      end:
        search && search.end
          ? dayjs(search.end).format("YYYY-MM-DD")
          : undefined,
      idCompany: idCompany,
    },
  });
};

const withdrawRequestTime = (formDate) => {
  return httpClient.put(`request-time/withdraw`, formDate);
};

const getAllRequestOTBy = (filter, search) => {
  return httpClient.get(
    `request-time/ot?filter=${filter}&start=${dayjs(search.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(search.end).format("YYYY-MM-DD")}`
  );
};

const approveRequestTime = (filter, formDate) => {
  return httpClient.put(`request-time/approve?filter=${filter}`, formDate);
};

const getAllRequestTimeCompany = (idCompany, search) => {
  return httpClient.get(
    `company/${idCompany}/requesttimes?start=${dayjs(search.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(search.end).format("YYYY-MM-DD")}`
  );
};

const putRequestTime = (data, idRequestTime) => {
  return httpClient.put(`requestTimes/${idRequestTime}`, data);
};

const addRequestTime = (data) => {
  return httpClient.post(`requestTimes`, data);
};

const getRequestReason = (query) => {
  return httpClient.get(`request/reason`, { params: query });
};

const addRequestReason = (data) => {
  return httpClient.post(`request/add-reason`, data);
};

const updateRequestReason = (data) => {
  return httpClient.put(`request/update-reason`, data);
};

const deleteRequestReason = (idRequestReason) => {
  return httpClient.delete(`request/delete-reason/${idRequestReason}`);
};

const getAllRequestTime = (query) => {
  return httpClient.get(`request-time`, { params: query });
};

const getAllRequestTimeWithDraw = (query) => {
  return httpClient.get(`request-time/withdraw`, { params: query });
};

const deleteRequestTime = (idRequestTime) => {
  return httpClient.delete(`request-time/delete?RequestTime=${idRequestTime}`);
};

const getOvertime36Hours = (searchDate) => {
  return httpClient.get(`overtime/36hours`, { params: searchDate });
};

const getOvertime36HoursDetail = (query) => {
  return httpClient.get(
    `overtime/36hours/detail`, { params: query }
  );
};

const getNumberOfEachPendingList = (query) => {
  return httpClient.get(`countRequestList`, { params: query });
}

const getNumberOfEachPendingListManager = (query) => {
  return httpClient.get(`countRequestListManager`, { params: query });
}

const getMonthlyOvertime = (query) => {
  return httpClient.get(`overtime/monthly-overtime`, { params: query });
}

const getMonthlyOvertimeDetail = (query) => {
  return httpClient.get(`overtime/monthly-overtime-detail`, { params: query });
}

export default {
  getAllRequestTimeById,
  getAllRequestTimeWithDrawBy,
  withdrawRequestTime,
  putRequestTime,
  addRequestTime,
  approveRequestTime,
  getAllRequestTimeBy,
  getAllRequestOTBy,
  getAllRequestTimeCompany,
  getRequestReason,
  addRequestReason,
  updateRequestReason,
  deleteRequestReason,
  getAllRequestTime,
  getAllRequestTimeWithDraw,
  deleteRequestTime,
  getOvertime36Hours,
  getOvertime36HoursDetail,
  getNumberOfEachPendingList,
  getNumberOfEachPendingListManager,
  getAllRequestTimeByManager,
  getAllRequestTimeWithDrawByManager,
  getMonthlyOvertime,
  getMonthlyOvertimeDetail
};
