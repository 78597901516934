import React from "react";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import utils from "../../../../utils";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const rand36 = () => {
  return Math.floor(Math.random() * 4);
};

function createData(status, name, created, start, stop, ot1, ot15, ot2, ot3) {
  return {
    status,
    name,
    created,
    start,
    stop,
    ot1,
    ot15,
    ot2,
    ot3,
  };
}

const StyledTable = styled(Table)({
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
    padding: "8px 0",
  },
  "& .tableCellHead:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    // boxShadow: "rgb(255 255 255) 8px 0px 0px inset",
  },
  "& .tableCellHead:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    // boxShadow: "rgb(255 255 255) -8px 0px 0px inset",
  },
  "& .tableCell": {
    borderColor: "#919eab3d",
    padding: "8px 0",
  },
  "& .tableCellSummary": {
    borderTop: "5px solid #919eab3d",
    borderBottom: "none",
  },
  "& .cellSummaryBase": {
    backgroundColor: "#007afd",
    border: "none",
    "&.first": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    "&.last": {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    },
  },
  "& .cellStatusAction": {
    width: 80,
  },
  "& .cellRequest": {
    width: 100,
  },
  "& .cellStartStop": {
    width: 150,
  },
  "& .cellNumber": {
    textAlign: "center",
    width: 95,
  },
  "& .cellSummary": {
    fontSize: 28,
  },
  "& .cellOver": {
    color: "#e46a76",
    fontWeight: 600,
  },
  "& .cellBaht": {
    fontSize: 14,
    color: "#999999",
  },
  "& .cellBahtSum": {
    fontSize: 14,
    color: "#ffffff",
  },
  "& .tableCellBlank": {
    padding: 4,
  },
});

export default function TableOTReport() {
  const bahtPerHours = 208;
  const { t, i18n } = useTranslation();

  const rows = [
    createData(
      0,
      `${t("DayOff")}`,
      new Date(2021, 10, 10, 14, 46, 51),
      new Date(2021, 10, 10, 16, 30, 0),
      new Date(2021, 10, 10, 20, 30, 0),
      rand36(),
      rand36(),
      rand36(),
      rand36()
    ),
    createData(
      1,
      `${t("WorkingDays")}`,
      new Date(2021, 10, 11, 14, 46, 51),
      new Date(2021, 10, 11, 16, 30, 0),
      new Date(2021, 10, 11, 20, 0, 0),
      rand36(),
      rand36(),
      rand36(),
      rand36()
    ),
    createData(
      2,
      `${t("WorkingDays")}`,
      new Date(2021, 10, 12, 14, 46, 51),
      new Date(2021, 10, 12, 16, 30, 0),
      new Date(2021, 10, 12, 18, 45, 0),
      rand36(),
      rand36(),
      rand36(),
      rand36()
    ),
    createData(
      1,
      `${t("DayOff")}`,
      new Date(2021, 10, 13, 14, 46, 51),
      new Date(2021, 10, 13, 0, 0, 0),
      new Date(2021, 10, 13, 0, 0, 0),
      0,
      0,
      0,
      0,
      0
    ),
    createData(
      1,
      `${t("WorkingDays")}`,
      new Date(2021, 10, 14, 14, 46, 51),
      new Date(2021, 10, 14, 16, 30, 0),
      new Date(2021, 10, 14, 19, 0, 0),
      rand36(),
      rand36(),
      rand36(),
      rand36()
    ),
    createData(
      1,
      `${t("WorkingDays")}`,
      new Date(2021, 10, 15, 14, 46, 51),
      new Date(2021, 10, 15, 16, 30, 0),
      new Date(2021, 10, 15, 20, 30, 0),
      rand36(),
      rand36(),
      rand36(),
      rand36()
    ),
    createData(
      1,
      `${t("DayOff")}`,
      new Date(2021, 10, 16, 15, 46, 51),
      new Date(2021, 10, 16, 0, 0, 0),
      new Date(2021, 10, 16, 0, 0, 0),
      0,
      0,
      0,
      0
    ),
  ];

  const sumHoursPerDay = (index) => {
    return (
      rows[index].ot1 + rows[index].ot15 + rows[index].ot2 + rows[index].ot3
    );
  };

  const sumHoursPerDayBaht = (index) => {
    return (
      rows[index].ot1 * bahtPerHours +
      rows[index].ot15 * 1.5 * bahtPerHours +
      rows[index].ot2 * 2 * bahtPerHours +
      rows[index].ot3 * 3 * bahtPerHours
    );
  };

  return (
    <TableContainer>
      <StyledTable aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={`tableCellHead cellStatusAction`}>
              {`${t("Date")}`}
            </TableCell>
            <TableCell className={`tableCellHead cellRequest`}>
              {`${t("Type")}`}
            </TableCell>
            <TableCell className={`tableCellHead cellStartStop`}>
              {`${t("Start")}`}
            </TableCell>
            <TableCell className={`tableCellHead cellStartStop`}>
              {`${t("End")}`}
            </TableCell>
            <TableCell className={`tableCellHead cellNumber`}>
              {`${t("Totall")}`} ({`${t("Unit.ShortHours")}`})
            </TableCell>
            <TableCell className={`tableCellHead cellNumber`}>
              OT x 1 ({`${t("Unit.ShortHours")}`})
            </TableCell>
            <TableCell className={`tableCellHead cellNumber`}>
              OT x 1.5 ({`${t("Unit.ShortHours")}`})
            </TableCell>
            <TableCell className={`tableCellHead cellNumber`}>
              OT x 2 ({`${t("Unit.ShortHours")}`})
            </TableCell>
            <TableCell className={`tableCellHead cellNumber`}>
              OT x 3 ({`${t("Unit.ShortHours")}`})
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell className={`tableCell cellStatusAction`}>
                {dayjs(row.created).format("D/MM/YYYY")}
              </TableCell>
              <TableCell className={`tableCell cellRequest`}>
                <div>
                  <Typography color="text.primary">{row.name}</Typography>
                </div>
              </TableCell>
              <TableCell className={`tableCell cellStartStop`}>
                <div>
                  <Typography>
                    {dayjs(row.start).format("HH:mm") === "00:00"
                      ? "-"
                      : dayjs(row.start).format("HH:mm")}
                  </Typography>
                </div>
              </TableCell>
              <TableCell className={`tableCell cellStartStop`}>
                <div>
                  <Typography>
                    {dayjs(row.stop).format("HH:mm") === "00:00"
                      ? "-"
                      : dayjs(row.stop).format("HH:mm")}
                  </Typography>
                </div>
              </TableCell>
              <TableCell className={`tableCell cellNumber`}>
                <Typography>{sumHoursPerDay(index)}</Typography>
                <Typography className={`cellBaht`}>
                  = ฿{utils.numberWithCommas(sumHoursPerDayBaht(index))}
                </Typography>
              </TableCell>
              <TableCell className={`tableCell cellNumber`}>
                <Typography>{row.ot1}</Typography>
                <Typography className={`cellBaht`}>
                  = ฿{utils.numberWithCommas(row.ot1 * bahtPerHours)}
                </Typography>
              </TableCell>
              <TableCell className={`tableCell cellNumber`}>
                <Typography>{row.ot15}</Typography>
                <Typography className={`cellBaht`}>
                  = ฿{utils.numberWithCommas(row.ot15 * bahtPerHours * 1.5)}
                </Typography>
              </TableCell>
              <TableCell className={`tableCell cellNumber`}>
                <Typography>{row.ot2}</Typography>
                <Typography className={`cellBaht`}>
                  = ฿{utils.numberWithCommas(row.ot2 * bahtPerHours * 2)}
                </Typography>
              </TableCell>
              <TableCell className={`tableCell cellNumber`}>
                <Typography>{row.ot3}</Typography>
                <Typography className={`cellBaht`}>
                  = ฿{utils.numberWithCommas(row.ot3 * bahtPerHours * 3)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
          <TableRow key={98}>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
          </TableRow>
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
}
