import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Typography } from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import DrawerCustom from "../../shared/general/Drawer";

import {
  updateCompanyProfile,
  getCompanyProfile,
} from "../../../../actions/company";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  marginBottom: 8,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogEdit = (props) => {
  const { open, mode, handleCloseDialog, selectedCompany } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: companyProfile } = useSelector(
    (state) => state.companyProfile
  );

  const [formData, setFormData] = useState(null);

  const keyLabel = {
    companyCode: <div>{`${t("CompanyCode")}`}</div>,
    companyTaxId: t("CompanyTaxID"),
    socialSecurityAccount: "Social Security A/C",
    providentFundCode: "Provident Fund Code",
    branchCode: t("BranchNumber"),
    companyTelephone: <div>{`${t("CompanyPhone")}`}</div>,
    companyEmail: <div>{`${t("CompanyEmail")}`}</div>,
    authorizedSignatureOneName: <div>{`${t("AuthorizedSignatoryFirst")}`}</div>,
    authorizedSignatureOnePosition: (
      <div>{`${t("PositionOfAuthorizedSignatoryFirst")}`}</div>
    ),
    authorizedSignatureTwoName: (
      <div>{`${t("AuthorizedSignatorySecond")}`}</div>
    ),
    authorizedSignatureTwoPosition: (
      <div>{`${t("PositionOfAuthorizedSignatorySecond")}`}</div>
    ),
    witnessSignatureName: <div>{`${t("WitnessName")}`}</div>,
    witnessSignaturePosition: <div>{`${t("WitnessPosition")}`}</div>,
    certificateSignatureName: (
      <div>{`${t("NameOfTheCertifyingAuthority")}`}</div>
    ),
    certificateSignaturePosition: (
      <div>{`${t("PositionOfTheCertifyingAuthority")}`}</div>
    ),
    certificateSignatureName_EN: (
      <div>{`${t("NameOfTheCertifyingAuthority(English)")}`}</div>
    ),
    certificateSignaturePosition_EN: (
      <div>{`${t("PositionOfTheCertifyingAuthority(English)")}`}</div>
    ),
    mainContactName: <div>{`${t("FullName")}`}</div>,
    mainContactPhone: <div>{`${t("EmergencyPhone")}`}</div>,
    mainContactEmail: <div>{`${t("Email")}`}</div>,
    companyName: <div>{`${t("CompanyName")}`}</div>,
    companyName_EN: <div>{`${t("CompanyName(English)")}`}</div>,
    nameShort: <div>{`${t("CompanyName(Abbreviation)")}`}</div>,
  };

  useEffect(() => {
    if (open) {
      if (mode === "general") {
        setFormData({
          companyCode: companyProfile.companyCode,
          companyTaxId: companyProfile.companyTaxId,
          socialSecurityAccount: companyProfile.socialSecurityAccount,
          providentFundCode: companyProfile.providentFundCode,
          branchCode: companyProfile.branchCode,
        });
      } else if (mode === "contact") {
        setFormData({
          companyTelephone: companyProfile.companyTelephone,
          companyEmail: companyProfile.companyEmail,
        });
      } else if (mode === "authorized") {
        setFormData({
          authorizedSignatureOneName: companyProfile.authorizedSignatureOneName,
          authorizedSignatureOnePosition:
            companyProfile.authorizedSignatureOnePosition,
          authorizedSignatureTwoName: companyProfile.authorizedSignatureTwoName,
          authorizedSignatureTwoPosition:
            companyProfile.authorizedSignatureTwoPosition,
          witnessSignatureName: companyProfile.witnessSignatureName,
          witnessSignaturePosition: companyProfile.witnessSignaturePosition,
          certificateSignatureName: companyProfile.certificateSignatureName,
          certificateSignaturePosition:
            companyProfile.certificateSignaturePosition,
          certificateSignatureName_EN: companyProfile.certificateSignatureName_EN,
          certificateSignaturePosition_EN:
            companyProfile.certificateSignaturePosition_EN,
        });
      } else if (mode === "main-contact") {
        setFormData({
          mainContactName: companyProfile.mainContactName,
          mainContactPhone: companyProfile.mainContactPhone,
          mainContactEmail: companyProfile.mainContactEmail,
        });
      } else if (mode === "profile") {
        setFormData({
          companyName: companyProfile.companyName,
          companyName_EN: companyProfile.companyName_EN,
          nameShort: companyProfile.nameShort,
        });
      }
    }
  }, [mode, open]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const result = await dispatch(
      updateCompanyProfile({ ...formData, idCompany: selectedCompany.idCompany })
    );
    if (result) {
      handleCloseDialog();
      dispatch(getCompanyProfile({
        idCompany: selectedCompany.idCompany
      }));
    }
  };

  return (
    <DrawerCustom
      title={
        mode === "general" ? (
          <div>{`${t("EditGeneralInformation")}`}</div>
        ) : mode === "contact" ? (
          <div>{`${t("EditContact")}`}</div>
        ) : (
          <div>{`${t("EditAuthorizedSignatory")}`}</div>
        )
      }
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        <Grid container alignItems="center" spacing={2}>
          {formData &&
            Object.entries(formData).map(([key, value], index) => (
              <Grid item xs={12} key={index}>
                <StyledContentLabel>{keyLabel[key]}</StyledContentLabel>
                <TextFieldTheme
                  name={key}
                  value={formData[key]}
                  onChange={handleChange}
                  fullWidth
                  // inputProps={(mode === "general" && key === "branchCode") ? { inputMode: "numeric", pattern: "[0-9]*" } : {}}
                />
              </Grid>
            ))}
        </Grid>
        <StyledFooter>
          <ButtonBlue
            variant="text"
            className="cancel"
            onClick={handleCloseDialog}
          >
            {`${t("Cancel")}`}
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit}>
            {`${t("SaveData")}`}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
