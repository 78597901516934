import React from "react";
import { Chip, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const StyledChip = styled(Chip)({
  height: 20,
  backgroundColor: "#ff3034",
  marginRight: 8,
  "& .MuiChip-label": {
    paddingLeft: 8,
    paddingRight: 8,
    color: "#FFFFFF",
    fontWeight: 600,
  },
});

const ChipWithDrawCustom = () => {
  const { t, i18n } = useTranslation();
  return <StyledChip label={t("Withdraw")} />;
};

export default ChipWithDrawCustom;
