import React, { Fragment, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Checkbox,
  Container,
  Chip,
  Grid,
  IconButton,
  Rating,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import TableTheme from "../../../shared/general/TableTheme";

import DrawerRating from "./shared/drawerRating";
import DrawerNote from "./shared/drawerNote";
import DrawerInterviewAppointment from "./drawerInterviewAppointment";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import {
  getNewCandidatesByIdJobRequest,
  getNewCandidatesByIdBiddings,
  updateStatusCandidateByManager,
} from "../../../../../actions/candidate";
import { getManager } from "../../../../../actions/manager";
import { stringToColor } from "../../../../../utils";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  background: "#FFFFFF !important",
  marginTop: 30,
  "& .part-header": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .column-name": {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#000000",
    "& .MuiAvatar-root": {
      marginRight: 8,
      width: 40,
      height: 40,
    },
  },
  "& .part-action": {
    display: "flex",
    justifyContent: "right",
  },
});

const BoxRating = styled(Box)({
  display: "flex",
  alignItems: "center",
  "& .MuiRating-icon": {
    color: "#000000",
  },
  "& .rating span": {
    fontSize: 16,
  },
});

const WaitingProcessChip = styled(Chip)({
  width: "130px",
  color: "#ff9800",
  fontWeight: 500,
  backgroundColor: "#fff0ce"
});

const PassResultChip = styled(Chip)({
  width: "130px",
  color: "#0b5f10",
  fontWeight: 500,
  backgroundColor: "#76d576"
});


const BoxTooltip = styled(Box)({
  // display: "flex",
  padding: "5px 10px",
  // "& .firstChip": {
  //   marginRight: 8
  // }
  "& .firstChip:hover": {
    backgroundColor: "white",
  }
});

const PageApplicantJob = (props) => {
  // console.log(props)
  const idJobRecruit = props.match.params.id;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { isFetching: FetchingAllCandidate, isError: ErrorAllCandidate, result: AllCandidates } = useSelector((state) => state.newCandidate);
  const { result: manager } = useSelector((state) => state.manager);

  const [selectedRow, setSelectedRow] = useState([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [fakeData, setFakedata] = useState([
    {
      "idManpower": 17,
      "name": "สุดหล่อ",
      "lastName": "มหานคร",
      "statusName": "ถูกเลือก",
      "dateOfBirth": "2015-05-28",
      "OESY(Y)": 5,
      "OESY(M)": 1,
      "createdAt": "2024-06-12T09:50:28.000Z",
    },
    {
      "idManpower": 15,
      "name": "สุดสวย",
      "lastName": "มหานคร",
      "statusName": "รอดำเนินการ",
      "dateOfBirth": "2014-05-28",
      "OESY(Y)": 1,
      "OESY(M)": 1,
      "createdAt": "2024-06-12T09:50:28.000Z",
    },
    {
      "idManpower": 19,
      "name": "อิอิ",
      "lastName": "มหานคร",
      "statusName": "รอดำเนินการ",
      "dateOfBirth": "2014-05-28",
      "OESY(Y)": 1,
      "OESY(M)": 1,
      "createdAt": "2024-06-12T09:50:28.000Z",
    }
  ])

  // useEffect(() => {
  //   if (props.match.params.id && props.match.params.type) {
  //     if (props.match.params.type === "job") {
  //       let id = parseInt(props.match.params.id);
  //       dispatch(getNewCandidatesByIdJobRequest(id));
  //     } else if (props.match.params.type === "bidding") {
  //       let id = parseInt(props.match.params.id);
  //       dispatch(getNewCandidatesByIdBiddings(id));
  //     }
  //   }
  //   dispatch(getManager());
  // }, [props.match.params.id]);

  useEffect(() => {
    // let id = parseInt(props.match.params.id);
    dispatch(getNewCandidatesByIdJobRequest(idJobRecruit));
  }, []);

  useEffect(() => {
    if (ErrorAllCandidate) {
      props.history.push("/recruitment");
    }
  }, [ErrorAllCandidate])

  // console.log(AllCandidates);

  const onClickCheckbox = (index) => {
    setIsSelectedAll(false);
    let tempSelected = selectedRow;

    if (tempSelected.includes(index)) {
      tempSelected = tempSelected.filter((i) => i !== index);
    } else {
      tempSelected.push(index);
    }
    setSelectedRow([...tempSelected]);
  };

  // console.log(selectedRow);


  const onClickSelectedAll = () => {
    if (!isSelectedAll) {
      // setSelectedRow([...AllCandidates.listCandidate]);
      const filteredCandidates = AllCandidates.listCandidate.filter(candidate => candidate.idStatus === 5);
      setSelectedRow([...filteredCandidates]);
      // setSelectedRow(fakeData);
      setIsSelectedAll(true);
    } else {
      const temp = [];
      setSelectedRow(temp);
      setIsSelectedAll(false);
    }
  };

  let timeoutDisplay;

  const handleCloseDisplay = () => {
    clearInterval(timeoutDisplay);
    setIsSuccess(false);
  };

  const handleCountDownTimeSet = () => {
    timeoutDisplay = setTimeout(handleCloseDisplay, 5000);
  };

  const Displayage = (date) => {
    let month = dayjs().diff(date, "month");
    return `${Math.floor(month / 12)} ${t("Year")}${month % 12 > 0 ? ` ${month % 12} ${t("Month")}` : ""
      }`;
  };

  const handleChangeStatus = async (status, applicant) => {
    dispatch(updateStatusCandidateByManager(idJobRecruit, status, applicant)).then(res => {
      if (res.status === 200) {
        dispatch(getNewCandidatesByIdJobRequest(idJobRecruit));
      }
    });
  };


  const handleSubmit = async (status) => {
    dispatch(updateStatusCandidateByManager(idJobRecruit, status, selectedRow)).then(res => {
      if (res.status === 200) {
        dispatch(getNewCandidatesByIdJobRequest(idJobRecruit));
      }
    })

    // setIsButtonDisabled(true);
    // // console.log(selectedListUsers);
    // const result = await dispatch(addManpowersToCandidate(selectedListUsers, parseInt(idJobRecruit)));
    // if (result) {
    //   setStatusAfterSubmit({ label: "ส่งคนสำเร็จ", isOpen: true });
    // }
    // setTimeout(() => {
    //   if (statusAfterSubmit.isOpen) {
    //     setIsButtonDisabled(false);
    //   }
    // }, 1000)
  }

  const columns = [
    {
      field: "name",
      headerName: (
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            onChange={onClickSelectedAll}
            checked={isSelectedAll}
            style={{ marginRight: "10px" }}
          />
          <i
            className="fa-solid fa-user-group"
            style={{
              color: "#ffffff",
              fontSize: "1.1rem",
              marginRight: "10px",
            }}
          />
          <Typography style={{ color: "#ffffff" }}>{`${t(
            "FullName"
          )}`}</Typography>
        </Box>
      ),
      width: 300,
      format: (params) => (
        <Box style={{ display: "flex", alignItems: "center" }}>
          {params.idStatus === 5 &&
            <Checkbox
              onChange={() => {
                onClickCheckbox(params);
              }}
              checked={selectedRow.includes(params)}
              style={{ marginRight: "10px" }}
            />
          }
          <Tooltip title={`${t("ViewHistory")}`} placement="top-start">
            <Box
              className="column-name"
              component={Link}
              to={`/manage/hunter/peer/profile/${params.idManpower}`}
            >
              <Avatar
                sx={{
                  bgcolor: stringToColor(`${params.name} ${params.lastName}`),
                }}
                children={`${params.name[0]}${params.lastName[0]}`}
              />
              <Box>
                <Typography>{`${params.name} ${params.lastName}`}</Typography>
              </Box>
            </Box>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: "status",
      width: "130px",
      headerName: (
        <Box style={{ display: "flex", alignItems: "center" }}>
          <i className="fa-solid fa-user" style={{ color: "#ffffff", fontSize: "1.1rem", marginRight: "10px" }} />
          <Typography style={{ color: "#ffffff" }}>{t("Status")}</Typography>
        </Box>
      ),
      format: (params) => {
        return (
          <Box className="column-name">
            {/* {params.idStatus && <StatusCandidate row={params} />} */}
            {params.idStatus === 5 && (
              <Tooltip
                title={
                  <BoxTooltip>
                    <PassResultChip label="ถูกเลือก" className="firstChip" onClick={() => { handleChangeStatus(6, [params]) }} />
                  </BoxTooltip>
                }
                placement="top"
              >
                <WaitingProcessChip label="รอดำเนินการ" />
              </Tooltip>
            )}
            {params.idStatus === 6 && (
              <Tooltip
                title={
                  <BoxTooltip>
                    <WaitingProcessChip label="รอดำเนินการ" className="firstChip" onClick={() => { handleChangeStatus(5, [params]) }} />
                  </BoxTooltip>
                }
                placement="top"
              >
                <PassResultChip label="ถูกเลือก" style={{ background: "#C8E6C9", color: "#388E3C" }} />
              </Tooltip>
            )}
            {/* <WaitingProcessChip label="รอดำเนินการ" /> */}
          </Box>
        );
      },
    },
    {
      field: "age",
      headerName: (
        <Box style={{ display: "flex", alignItems: "center" }}>
          <i
            className="fa-solid fa-user"
            style={{
              color: "#ffffff",
              fontSize: "1.1rem",
              marginRight: "10px",
            }}
          />
          <Typography style={{ color: "#ffffff" }}>{`${t("Age")}`}</Typography>
        </Box>
      ),
      width: 88,
      format: (params) => (
        <Box style={{ display: "flex" }}>
          <Typography>
            {(params.dateOfBirth && Displayage(params.dateOfBirth)) || "-"}
          </Typography>
        </Box>
      ),
    },

    {
      field: "OESY",
      headerName: (
        <Box style={{ display: "flex", alignItems: "center" }}>
          <i
            className="fa-solid fa-briefcase"
            style={{
              color: "#ffffff",
              fontSize: "1.1rem",
              marginRight: "10px",
            }}
          />
          <Typography style={{ color: "#ffffff" }}>
            {`${t("YearOfService")}`}
          </Typography>
        </Box>
      ),
      width: 100,
      format: (params) => (
        <Box style={{ display: "flex" }}>
          <Typography>
            {`${params["OESY(Y)"] || "0"} ${t("Year")} ${params["OESY(M)"] || "0"
              } ${t("Month")}` || "-"}
          </Typography>
        </Box>
      ),
    },
    // {
    //   field: "lastPosition",
    //   headerName: (
    //     <Box style={{ display: "flex", alignItems: "center" }}>
    //       <i
    //         className="fa-solid fa-address-card"
    //         style={{
    //           color: "#ffffff",
    //           fontSize: "1.1rem",
    //           marginRight: "10px",
    //         }}
    //       />
    //       <Typography style={{ color: "#ffffff" }}>{`${t(
    //         "LatestPosition"
    //       )}`}</Typography>
    //     </Box>
    //   ),
    //   width: 230,
    //   format: (params) => (
    //     <Box style={{ display: "flex" }}>
    //       <Typography>{params.lastPositionName || "-"}</Typography>
    //     </Box>
    //   ),
    // },
    // {
    //   field: "lastCompany",
    //   headerName: (
    //     <Box style={{ display: "flex", alignItems: "center" }}>
    //       <i
    //         className="fa-solid fa-address-card"
    //         style={{
    //           color: "#ffffff",
    //           fontSize: "1.1rem",
    //           marginRight: "10px",
    //         }}
    //       />
    //       <Typography style={{ color: "#ffffff" }}>
    //         บริษัทล่าสุด
    //       </Typography>
    //     </Box>
    //   ),
    //   width: 230,
    //   format: (params) => (
    //     <Box style={{ display: "flex" }}>
    //       {/* <Typography>{params.lastPositionName || "-"}</Typography> */}
    //     </Box>
    //   ),
    // },
    {
      field: "sentDate",
      headerName: (
        <Box style={{ display: "flex", alignItems: "center" }}>
          <i
            className="fa-solid fa-calendar-days"
            style={{
              color: "#ffffff",
              fontSize: "1.1rem",
              marginRight: "10px",
            }}
          />
          <Typography style={{ color: "#ffffff" }}>{`${t(
            "SubmissionDate"
          )}`}</Typography>
        </Box>
      ),
      width: 100,
      format: (params) => (
        <Typography>{dayjs(params.createdAt).format("DD/MM/YYYY")}</Typography>
      ),
    },
    // {
    //   field: "actions",
    //   width: 150,
    //   headerName: (
    //     <Box style={{ display: "flex", alignItems: "center" }}>
    //       <i className="fa-solid fa-pen-to-square" style={{ color: "#ffffff", fontSize: "1.1rem", marginRight: "10px" }} />
    //       <Typography style={{ color: "#ffffff" }}>Actions</Typography>
    //     </Box>
    //   ),
    //   format: (params) => (
    //     <Box className="column-name">
    //       <IconButton
    //         style={{ color: "#83D0F5" }}
    //         onClick={() => {
    //           console.log(params);
    //           // setIsOpenDrawerEdit({ status: true, candidate: params });
    //         }}
    //       >
    //         <i className="fa-solid fa-pen-to-square"></i>
    //       </IconButton>
    //     </Box>
    //   )
    // },
  ];

  const GroupBy = (array, key) => {
    let temp = array.reduce((preValue, curValue) => {
      (preValue[curValue[key]] = preValue[curValue[key]] || []).push(curValue);
      return preValue;
    }, {});
    return temp;
  };

  const GroupSelectedListCandidates = () => {
    if (props.match.params.type === "job") {
      return GroupBy(selectedRow, "idJobRecruit");
    } else {
      return GroupBy(selectedRow, "idBiddingPositions");
    }
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {!FetchingAllCandidate && !ErrorAllCandidate && AllCandidates && (
          <Fragment>
            <div className="part-header">
              <Typography variant="h4" sx={{ color: "#000000" }}>
                {`${t("Applicant")} / ${(AllCandidates && AllCandidates.nameInterview) || "-"
                  }`}
              </Typography>
              <div className="item-total">
                <Typography
                  variant="h4"
                  sx={{ color: "#000000", justifyContent: "center" }}
                >
                  {/* {(AllCandidates &&
                    AllCandidates.listCandidate &&
                    AllCandidates.listCandidate.length) ||
                    0} */}
                  {AllCandidates && AllCandidates.listCandidate ? AllCandidates.listCandidate.length : 0}
                </Typography>
                <Typography color="text.secondary">{`${t(
                  "NumberApplicants"
                )}`}</Typography>
              </div>
            </div>

            <TableTheme
              columns={columns}
              // rows={(AllCandidates && AllCandidates.listCandidate) || []}
              rows={AllCandidates && AllCandidates.listCandidate}
              textEmpty={`${t("NoApplicantsFound")}`}
              minHeight={200}
            />

            <Grid
              container
              justifyContent="flex-end"
              style={{ marginTop: "20px" }}
            >
              <ButtonBlue
                variant="contained"
                disabled={selectedRow.length <= 0 ? true : false}
                onClick={() => {
                  handleSubmit(6);
                }}
              >
                {t("Select")}
              </ButtonBlue>
            </Grid>
          </Fragment>
        )}
        {isSuccess && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "calc(100vh - 160px)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "80px",
              }}
            >
              <img
                style={{ width: 300, marginBottom: "20px" }}
                src={`${process.env.PUBLIC_URL}/assets/background/complete.jpg`}
              // alt="https://www.freepik.com/free-vector/completed-concept-illustration_10802151.htm"
              />
              <Typography
                className="success"
                variant="h4"
                sx={{ marginBottom: "40px", color: "#ffffff" }}
              >
                {`${t("SuccessfulInterviewAppointment")}`}
              </Typography>
              <ButtonBlue
                variant="outlined"
                sx={{ borderRadius: "25px", width: "100px", color: "#ffffff" }}
                onClick={() => {
                  setIsSuccess(false);
                }}
              >
                <Typography variant="h6">{`${t("Close")}`}</Typography>
              </ButtonBlue>
            </div>
          </div>
        )}
      </Container>
      {/* {isOpenDrawerInterviewAppointment && (
        <DrawerInterviewAppointment
          open={isOpenDrawerInterviewAppointment}
          onClose={() => {
            setIsOpenDrawerInterviewAppointment(false);
            setSelectedRow([]);
          }}
          listCandidate={GroupSelectedListCandidates()}
          manager={manager}
          type={props.match.params.type}
          id={parseInt(props.match.params.id)}
          setIsSuccess={setIsSuccess}
          handleCountDownTimeSet={handleCountDownTimeSet}
        />
      )} */}
    </StyledRoot>
  );
};

export default PageApplicantJob;
