import React, { useEffect, useState, Fragment } from "react";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Container, FilledInput, Box, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import StyledCard from "../../../shared/general/Card";
import DatePickerWeek from "../../../shared/general/DatePickerWeek";
import SelectAffiliateCompany from "../../../shared/general/SelectAffiliateCompany";
import TableMonthlyOTReport from "./tableMonthlyOTReport";
import { getAffiliateOrganization } from "../../../../../actions/company";
import { getMonthlyOvertime, getMonthlyOvertimeDetail } from "../../../../../actions/requestTime";
import { getUserFullName, getUserPosition } from "../../../../../utils/userData";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DatePickerCustom from "../../../shared/date/datePicker";
import DownloadIcon from '@mui/icons-material/Download';
import { OtMonitoringExportExcel } from "./XlsxExport";

const StyledBoxSearch = styled(Box)({
    marginTop: 22,
    "&.group-employee": {
        marginTop: 0,
    },
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginTop: 16,
        marginBottom: 8,
    },
    "& .wrap-search-action": {
        marginTop: 50,
        display: "flex",
        justifyContent: "flex-start",
    },
});

const StyledRoot = styled("div")({
    backgroundColor: "#FFFFFF !important",
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
    "& .empty-data": {
        display: "flex",
        justifyContent: "center",
        padding: 32,
        "& .text": {
            fontStyle: "oblique",
            fontWeight: 400,
        },
    },
});

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    borderBottom: `1px dashed #DDDDDD`,
    "&:not(:last-child)": {},
    "&:before": {
        display: "none",
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon />} {...props} />
))(({ theme }) => ({
    flexDirection: "row-reverse",
    paddingLeft: 0,
    "& .inner": {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& .hours": {
            display: "flex",
            alignItems: "baseline",
        },
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
        transform: "rotate(-90deg)",
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(0deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
}));

const StyledCellName = styled("div")({
    display: "flex",
    alignItems: "center",
    "& .MuiAvatar-root": {
        marginRight: 8,
    },
    "& .MuiTypography-h6": {
        lineHeight: 1,
    },
    "& .MuiTypography-body2": {
        color: "#999999",
        fontStyle: "oblique",
    },
});

export default function MonthlyOvertime() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [monthlyOT, setMonthlyOT] = useState(null);
    const [monthlyOTDetail, setMonthlyOTDetail] = useState(null);
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

    const [expanded, setExpanded] = useState(null);
    const [searchDate, setSearchDate] = useState({
        start: null,
        end: null
    });
    const [selectedCompany, setSelectedCompany] = useState(null);

    const fetchedMonthlyOvertimeData = async (data) => {
        try {
            const response = await getMonthlyOvertime(data);
            if (response && response.data && response.data.length > 0) {
                setMonthlyOT(response.data);
            };
        } catch (error) {
            console.error(error);
        }
    };

    const fetchedMonthlyOvertimeDetail = async (data) => {
        try {
            const response = await getMonthlyOvertimeDetail(data);
            if (response && response.data && response.data.length > 0) {
                setMonthlyOTDetail(response.data);
            };
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (userProfile) {
            const start = dayjs(searchDate.start).format("YYYY-MM-DD");
            const end = dayjs(searchDate.end).format("YYYY-MM-DD");
            let temp = {
                start: start,
                end: end
            };

            if (userProfile.roles.includes("ROLE_ADMIN") && selectedCompany) {
                temp.idCompany = selectedCompany.idCompany;
                temp.filter = "admin";
            } else {
                temp.idManager = userProfile.idEmployees;
                temp.filter = "manager";
            };

            setSearchDate(temp);
            fetchedMonthlyOvertimeData(temp);
        }
    }, [selectedCompany, userProfile]);

    useEffect(() => {
        if (userProfile && userProfile.roles.includes("ROLE_ADMIN")) {
            dispatch(getAffiliateOrganization());
        }
    }, [userProfile]);

    const handleClickSearch = () => {
        setExpanded(null);
        fetchedMonthlyOvertimeData({
            start: dayjs(searchDate.start).format("YYYY-MM-DD"),
            end: dayjs(searchDate.end).format("YYYY-MM-DD")
        });
    };

    const handleChangeExpanded = (panel, idEmployees) => (event, newExpanded) => {
        if (newExpanded) {
            const start = dayjs(searchDate.start).format("YYYY-MM-DD");
            const end = dayjs(searchDate.end).format("YYYY-MM-DD");

            fetchedMonthlyOvertimeDetail({
                search: idEmployees,
                start: start,
                end: end
            });
        }
        setExpanded(newExpanded ? panel : false);
    };

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    };

    useEffect(() => {
        if ((userProfile && userProfile.roles.includes("ROLE_ADMIN")) && (affiliateOrganizationList && affiliateOrganizationList.length > 0)) {
            let ownCompany = affiliateOrganizationList.find(x => x.idCompany === userProfile.idCompany);
            if (!ownCompany) {
                ownCompany = affiliateOrganizationList[0];
            }

            setSelectedCompany(ownCompany);
        }
    }, [userProfile, affiliateOrganizationList]);

    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <Typography variant="h4" style={{ paddingBottom: 16 }}>
                    {t("MonthlyOvertimeReport")}
                </Typography>
                <div>
                    <StyledCard>
                        <div style={{ padding: 24 }}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} sm={3}>
                                    <Typography className="label" color="text.third">
                                        {t("StartDate")}
                                    </Typography>
                                    <div className="search-date">
                                        <DatePickerCustom
                                            minDate={dayjs().subtract(1, "year")}
                                            maxDate={dayjs()}
                                            inputFormat="D MMM YYYY"
                                            value={searchDate.start}
                                            name="start"
                                            onChange={(newValue) => {
                                                setSearchDate({ ...searchDate, start: newValue });
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography className="label" color="text.third">
                                        {t("EndDate")}
                                    </Typography>
                                    <div className="search-date">
                                        <DatePickerCustom
                                            minDate={dayjs(searchDate.start)}
                                            maxDate={dayjs(searchDate.start).add(62, "days").isBefore(dayjs())
                                                ? dayjs(searchDate.start).add(62, "days")
                                                : dayjs()}
                                            inputFormat="D MMM YYYY"
                                            value={searchDate.end}
                                            name="end"
                                            onChange={(newValue) => {
                                                setSearchDate({ ...searchDate, end: newValue });
                                            }}
                                        />
                                    </div>
                                </Grid>
                                {userProfile && userProfile.roles.includes("ROLE_ADMIN") && (
                                    <Grid item xs={12} sm={3}>
                                        <Typography className="label" color="text.third">
                                            {t("SelectCompany")}
                                        </Typography>
                                        <SelectAffiliateCompany
                                            options={affiliateOrganizationList}
                                            value={selectedCompany}
                                            onChange={(_, value) => {
                                                onChangeCompany(value);
                                            }}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={3}>
                                    <Stack sx={{ mt: 4 }} direction={"row"} spacing={1}>
                                        <ButtonBlue
                                            startIcon={<SearchRoundedIcon />}
                                            variant={"contained"}
                                            onClick={handleClickSearch}
                                            disabled={!searchDate.start || !searchDate.end}
                                        >
                                            {t("Search")}
                                        </ButtonBlue>

                                        {monthlyOT && monthlyOT.length > 0 && (
                                            <ButtonBlue
                                                startIcon={<DownloadIcon />}
                                                variant={"contained"}
                                                onClick={() => OtMonitoringExportExcel(t, monthlyOT, searchDate)}
                                            >
                                                {t("Download")}
                                            </ButtonBlue>
                                        )}
                                    </Stack>
                                </Grid>
                            </Grid>

                            <div style={{ marginTop: 36 }}>
                                {monthlyOT && monthlyOT.length > 0 ?
                                    monthlyOT.map((value, index) => {
                                        return (
                                            <Accordion
                                                key={index}
                                                expanded={expanded === `panel${index}`}
                                                onChange={handleChangeExpanded(
                                                    `panel${index}`,
                                                    value.idEmployees
                                                )}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <div className="inner">
                                                        <StyledCellName>
                                                            <Avatar
                                                                alt={getUserFullName(value)}
                                                                src={`${value.imageProfile}` || ""}
                                                            />
                                                            <div>
                                                                <Typography variant="h6">
                                                                    {getUserFullName(value)}
                                                                </Typography>
                                                                <Typography variant="body2">
                                                                    {getUserPosition(value)}
                                                                </Typography>
                                                            </div>
                                                        </StyledCellName>
                                                        <div>
                                                            <div className="hours">
                                                                <Typography
                                                                    variant="h4"
                                                                    style={{
                                                                        marginRight: 4,
                                                                        // color: value.isOver36Hours ? "#e46a76" : "",
                                                                        // fontWeight: value.isOver36Hours ? 600 : 400,
                                                                    }}
                                                                >
                                                                    {value.amountHours}
                                                                </Typography>
                                                                <Typography color="text.third">{` ${t("Unit.ShortHours")}`}</Typography>
                                                            </div>
                                                            <div className="hours">
                                                                <Typography
                                                                    variant="body"
                                                                    color={"grey"}
                                                                >
                                                                    {value.percent} {t("comparisonWithWorkHours")}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <TableMonthlyOTReport data={monthlyOTDetail} />
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    }) : (
                                        <div className="empty-data">
                                            <Typography className="text" color="text.secondary">
                                                {t("NoDataPleaseSelectDatesAndSearch")}
                                            </Typography>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </StyledCard>
                </div>
            </Container >
        </StyledRoot >
    );
}
