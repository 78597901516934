import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { Box, Divider, Grid, Typography } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import ButtonBlue from "../../shared/general/ButtonBlue";

import DialogContact from "./DialogContact";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import DialogPersonalInfo from "./DialogPersonalInfo";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 0,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapHead = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const ProfileTab = (props) => {
  const { handleChangeAlertType, handleOpenAlert } = props;
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [openDialogContact, setOpenDialogContact] = useState(false);

  const [drawerPersonalInfoConfig, setDrawerPersonalInfoConfig] = useState({
    isOpen: false,
    data: {},
  })

  const handleCloseDialog = () => {
    setOpenDialogContact(false);
  };

  return (
    <Grid container spacing={2}>
      {userProfile && (
        <Fragment>
          <Grid item xs={12}>
            <StyledWrapHead>
              <StyledHeadLabel style={{ marginBottom: 4 }}>
                {`${t("PersonalInfo")}`}
              </StyledHeadLabel>
              <ButtonBlue
                variant="text"
                size="small"
                startIcon={<EditIcon />}
                onClick={() => {
                  setDrawerPersonalInfoConfig(prev => ({
                    ...prev,
                    isOpen: true,
                    data: {
                      idReligion: userProfile.idReligion
                    }
                  }))
                }}
              >
                {`${t("EditData")}`}
              </ButtonBlue>
            </StyledWrapHead>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledHeadLabel color="text.secondary">
              {`${t("EmployeeID")}`}
            </StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.employeeID ? userProfile.employeeID : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledHeadLabel color="text.secondary">
              {`${t("PersonalID")}`}
            </StyledHeadLabel> 
            <Typography variant="h6">
              {userProfile.personalID
                ? userProfile.personalID.replace(
                    /(\d)(\d\d\d\d)(\d\d\d\d\d)(\d\d)(\d)/,
                    "$1 $2 $3 $4 $5"
                  )
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledHeadLabel color="text.secondary">{`${t(
              "BirthDate"
            )}`}</StyledHeadLabel>
            <Typography variant="h6">
              {`${dayjs(userProfile.birthday)
              .locale(localStorage.getItem("i18nextLng") || "th")
              .format(
                localStorage.getItem("i18nextLng") === "en"
                  ? "D MMMM YYYY"
                  : "D MMMM BBBB"
              )}`}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledHeadLabel color="text.secondary">{`${t("Gender")}`}</StyledHeadLabel>
            <Typography variant="h6">{userProfile.gender? t(`${userProfile.gender}`): t(`NotSpecified`)}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledHeadLabel color="text.secondary">{`${t("Nationality")}`}</StyledHeadLabel>
            <Typography variant="h6">{userProfile.nationality}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledHeadLabel color="text.secondary">{`${t("Religion")}`}</StyledHeadLabel>
            <Typography variant="h6">{userProfile.idReligion? t(`Religion_Code.${userProfile.religionCode}`): t(`NotSpecified`)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledDivider />
            <StyledWrapHead>
              <StyledHeadLabel style={{ marginBottom: 4 }}>
                {`${t("Contact")}`}
              </StyledHeadLabel>
              <ButtonBlue
                variant="text"
                size="small"
                startIcon={<EditIcon />}
                onClick={() => setOpenDialogContact(true)}
              >
                {`${t("EditData")}`}
              </ButtonBlue>
            </StyledWrapHead>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">
                {`${t("EmergencyPhone")}`}
              </StyledHeadLabel>
              <Typography variant="h6">
                {userProfile.telephoneMobile &&
                  userProfile.telephoneMobile.replace(
                    /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                    "$1-$2-$3"
                  )}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">{`${t(
                "Email"
              )}`}</StyledHeadLabel>
              <Typography variant="h6">{userProfile.email}</Typography>
            </div>
          </Grid>
        </Fragment>
      )}
      <DialogContact
        open={openDialogContact}
        handleCloseDialog={handleCloseDialog}
        handleChangeAlertType={handleChangeAlertType}
        handleOpenAlert={handleOpenAlert}
      />
      <DialogPersonalInfo
        drawerConfig={drawerPersonalInfoConfig}
        handleClose={() => {
          setDrawerPersonalInfoConfig(prev => ({
            ...prev,
            isOpen: false,
          }))
        }}
      />
    </Grid>
  );
};

export default ProfileTab;
