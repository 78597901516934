import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Divider,
  TextField,
  InputAdornment,
  OutlinedInput,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(1),
    position: "absolute",
    top: theme.spacing(5),
    width: "95%",
    maxHeight: 800,
  },
  dialogTitle: {
    color: "#16243D",
    padding: theme.spacing(1),
  },
  dialogContent: {
    padding: "16px 8px 24px 8px",
  },
  dialogActions: {
    marginTop: theme.spacing(1),
  },
}));

function InfoEvent(props) {
  const { infoModal, setInfoModal } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (infoModal) {
      console.log("infoModal", infoModal.event.start);
    }
  }, [infoModal]);

  return (
    <Dialog open={infoModal.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" style={{ fontWeight: 600 }}>
              Info Event
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              onClick={() => setInfoModal({ ...infoModal, isOpen: false })}
            >
              <CloseIcon className={classes.closeBtn} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Grid item xs={4} md={4} lg={4}>
            <Typography variant="body1" className={classes.headInput}>
              Type
            </Typography>
          </Grid>
          <Grid item xs={8} md={8} lg={8}>
            <Typography variant="body1" gutterBottom>
              {t("PublicHoliday")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4} md={4} lg={4}>
            <Typography variant="body1" className={classes.headInput}>
              Title
            </Typography>
          </Grid>
          <Grid item xs={8} md={8} lg={8}>
            <Typography variant="body1" gutterBottom>
              {infoModal.event.title}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4} md={4} lg={4}>
            <Typography variant="body1" className={classes.headInput}>
              Start
            </Typography>
          </Grid>
          <Grid item xs={8} md={8} lg={8}>
            <Typography variant="body1" gutterBottom>
              {dayjs(infoModal.event.start).format("DD/MM/YYYY")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4} md={4} lg={4}>
            <Typography variant="body1" className={classes.headInput}>
              End
            </Typography>
          </Grid>
          <Grid item xs={8} md={8} lg={8}>
            <Typography variant="body1" gutterBottom>
              {infoModal.event.end
                ? dayjs(infoModal.event.end).format("DD/MM/YYYY")
                : dayjs(infoModal.event.start).format("DD/MM/YYYY")}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default InfoEvent;
