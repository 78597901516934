import React from "react";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom/cjs/react-router-dom";

import FlexCoin from "./flex-coin";
import Welfare from "./welfare";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 24,
  },
  "& .head": {
    paddingTop: 8,
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .head-2": {
    paddingTop: 8,
    marginBottom: 24,
    marginTop: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .wrap-sum-amount": {
    marginBottom: 16,
  },
});

const WelfareHistoryPage = () => {
  const { type } = useParams();

  return (
    <StyledRoot className="page">
      {type === "Welfare" && <Welfare />}
      {type === "FlexCoin" && <FlexCoin />}
    </StyledRoot>
  );
};

export default WelfareHistoryPage;
