import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  Button,
  Grid,
  Typography,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  DialogContent,
  Box,
  TextField,
  Select,
} from "@mui/material";

import { useHistory } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import CloseIcon from "@mui/icons-material/Close";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { HexColorPicker } from "react-colorful";

import { putJobStructure } from "../../../../../../actions/jobStructures";
import { useTranslation } from "react-i18next";



const useStyles = makeStyles(() => ({
  wrapCaptionImage: {
    marginTop: 16,
    textAlign: "center",
    marginTop: 0,
  },
  bigAvatar: {
    width: "84%",
    height: "84%",
    margin: 10,
  },
  paper: {
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#2c387e",
  },
  icon: {
    fontSize: 28,
    color: "white",
  },
  uploadImage: {
    width: "80%",
    zIndex: 8,
  },
  placeholder: {
    display: "flex",
    position: "absolute",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "rgb(99, 115, 129)",
    transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  placeholderImageProfile: {
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgba(22, 28, 36, .72)",
  },
  placeholderLabel: {
    color: "rgb(255, 255, 255)",
  },
  captionImage: {
    color: "#212b36",
    fontSize: 13,
    alignSelf: "center",
  },
  text: {
    fontSize: 15,
    marginBottom: 2,
    marginLeft: 10,
  },
  icon: {
    fontSize: 18,
    marginBottom: 2,
    marginRight: 2,
  },
  formInput: {
    marginLeft: 32,
  },
  noneTheme: {
    "&:hover": {
      backgroundColor: "#f5f5f5",
      color: "#9e9e9e",
    },
    "&.Mui-selected": {
      backgroundColor: "#f5f5f5",
      color: "#9e9e9e",
    },
  },
  redTheme: {
    "&:hover": {
      backgroundColor: "#AD3E3E",
      color: "#fff",
    },
    "&.Mui-selected": {
      backgroundColor: "#AD3E3E",
      color: "#fff",
    },
  },
  blueTheme: {
    "&:hover": {
      backgroundColor: "#223F73",
      color: "#fff",
    },
    "&.Mui-selected": {
      backgroundColor: "#223F73",
      color: "#fff",
    },
  },
  greenTheme: {
    "&:hover": {
      backgroundColor: "#24733F",
      color: "#fff",
    },
    "&.Mui-selected": {
      backgroundColor: "#24733F",
      color: "#fff",
    },
  },
  purpleTheme: {
    "&:hover": {
      backgroundColor: "#860b77",
      color: "#fff",
    },
    "&.Mui-selected": {
      backgroundColor: "#860b77",
      color: "#fff",
    },
  },
}));

function EditJob({ open, close, data, fetchData }) {
  const {t} = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [color, setColor] = useState("");
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({
    name: false,
  });
  const [picture, setPicture] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFile) => {
      let formData = new FormData();
      acceptedFile.map((file) => formData.append("file", file));
      setPicture(
        acceptedFile.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      );
    },
    maxFiles: 1,
  });

  const thumbs = picture.map((file) => (
    <img key={file.name} src={file.preview} className={classes.uploadImage} />
  ));

  const [dataJob, setDataJob] = useState({
    id: 0,
    name: "",
    description: "",
    color: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDataJob({
      ...dataJob,
      [name]: value,
    });
  };

  const onPutJobStructure = async (id, formData) => {
    let imageFile = picture[0];

    if (imageFile !== undefined) {
      const fileName = new Date().getTime() + imageFile.name;
      var newFile = new File([imageFile], fileName, { type: "image/png" });

      var bodyFormData = new FormData();
      bodyFormData.append("file", newFile);
      axios.post("/jobStructures/uploadImage", bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      formData.imageName = fileName;
    } else {
      formData.imageName = formData.imageName ? formData.imageName : null;
    }

    await dispatch(putJobStructure(id, formData));
    close();
    fetchData();
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (dataJob.name) {
      const formData = {
        jobStructureName: dataJob.name,
        description: dataJob.description,
        backgroundColor: color,
        imageName: dataJob.imageName,
        idJobStructure: dataJob.id,
      };

      //Post to DB
      onPutJobStructure(formData.idJobStructure, formData);
    } else {
      setErrors({ name: true });
    }
  };

  useEffect(() => {
    setDataJob({
      id: data.idJobStructure,
      name: data.jobStructureName,
      description: data.description,
      color: data.backgroundColor,
      imageName: data.imageName,
    });

    setColor(data.backgroundColor);
    setErrors({ name: false });
  }, []);

  return (
    
      
        <Dialog open={open}>
          <DialogTitle>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">{t("editJob")}</Typography>
              </Grid>
              <Grid item>
                <CloseIcon
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={close}
                />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={4}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <div
                    {...getRootProps({ className: "dropzone" })}
                    style={{ borderRadius: "10%" }}
                  >
                    <div className="inner-dropzone">
                      <input {...getInputProps()} />
                      <Fragment>{thumbs}</Fragment>
                      <div
                        className={`placeholder ${classes.placeholder} ${
                          picture.length != 0 && classes.placeholderImageProfile
                        }`}
                      >
                        <InsertDriveFileIcon />
                        <Typography
                          style={{
                            marginTop: 8,
                            backgroundColor: "transparent",
                          }}
                          className={`${
                            picture.length != 0 && classes.placeholderLabel
                          }`}
                          variant="body2"
                        >
                          {t("Upload")} {t("File")}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Box>
                <Box>
                  <div className={classes.wrapCaptionImage}>
                    <Typography variant="caption">* {t("AllowPicture")} *</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} md={8} lg={8}>
                <Box className={classes.formInput}>
                  <TextField
                    variant="outlined"
                    name="name"
                    value={dataJob.name}
                    onChange={handleInputChange}
                    placeholder={t("Name")}
                    margin="dense"
                    fullWidth
                    error={errors.name}
                    helperText={errors.name ? t("ThisFieldIsRequired")+"." : null}
                  />
                  <TextField
                    variant="outlined"
                    name="description"
                    value={dataJob.description}
                    onChange={handleInputChange}
                    placeholder={t("Description")}
                    margin="dense"
                    multiline
                    fullWidth
                    rows={3}
                  />
                  <Box sx={{ marginTop: 1 }}>
                    <Typography
                      variant="body1"
                      sx={{ color: "#9e9e9e" }}
                      gutterBottom
                    >
                      {t("BackgroundColor")}
                    </Typography>
                    <HexColorPicker color={color} onChange={setColor} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid style={{ padding: 20, textAlign: "right", paddingTop: 0 }}>
              <Button onClick={handleOnSubmit} variant="contained">
                {t("Submit")}
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
     
    
  );
}

export default EditJob;
