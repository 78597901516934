import React from "react";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { Controller, useFieldArray } from "react-hook-form";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../../shared/date/datePicker";

const FamilyInfo = (params) => {
  const { useForm, registrationMetadata } = params;
  const { t, i18n } = useTranslation();

  const useFieldArrayFamilyList = useFieldArray({
    control: useForm.control,
    name: "familyList",
  });

  return (
    <Box>
      <Typography className="title-text">{`${t("FamilyInfo")}`}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} container justifyContent="flex-end">
          <ButtonBlue
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => {
              useFieldArrayFamilyList.append({
                firstname_TH: "",
                lastname_TH: "",
                relationship: "",
                personalID: "",
                birthday: null,
              });
            }}
          >
            {`${t("AddFamilyMember")}`}
          </ButtonBlue>
        </Grid>

        {useFieldArrayFamilyList.fields.map((item, index) => (
          <Grid key={item.id} item xs={12}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography fontSize="20px">
                  {`${t("FamilyMemberNo")}`} {index + 1}
                </Typography>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    useFieldArrayFamilyList.remove(index);
                  }}
                >
                  <DeleteOutlineRoundedIcon fontSize="inherit" />
                </IconButton>
              </Grid>

              <Grid item xs={4}>
                <Typography>{`${t("Relationship")}`}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Controller
                  name={`familyList.${index}.relationship`}
                  control={useForm.control}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      select
                      helperText={
                        useForm.formState.errors["familyList"] &&
                        useForm.formState.errors["familyList"][index] &&
                        useForm.formState.errors["familyList"][index][
                          "relationship"
                        ]
                          ? useForm.formState.errors["familyList"][index][
                              "relationship"
                            ].message
                          : null
                      }
                      error={
                        useForm.formState.errors["familyList"] &&
                        useForm.formState.errors["familyList"][index] &&
                        useForm.formState.errors["familyList"][index][
                          "relationship"
                        ]
                          ? true
                          : false
                      }
                    >
                      <MenuItem value="คู่สมรส">{`${t("Spouse")}`}</MenuItem>
                      <MenuItem value="บุตร">{`${t("Child")}`}</MenuItem>
                      <MenuItem value="บิดา">{`${t("Father")}`}</MenuItem>
                      <MenuItem value="มารดา">{`${t("Mother")}`}</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography>{`${t("FirstName")}`}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Controller
                  name={`familyList.${index}.firstname_TH`}
                  control={useForm.control}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      helperText={
                        useForm.formState.errors["familyList"] &&
                        useForm.formState.errors["familyList"][index] &&
                        useForm.formState.errors["familyList"][index][
                          "firstname_TH"
                        ]
                          ? useForm.formState.errors["familyList"][index][
                              "firstname_TH"
                            ].message
                          : null
                      }
                      error={
                        useForm.formState.errors["familyList"] &&
                        useForm.formState.errors["familyList"][index] &&
                        useForm.formState.errors["familyList"][index][
                          "firstname_TH"
                        ]
                          ? true
                          : false
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography>{`${t("LastName")}`}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Controller
                  name={`familyList.${index}.lastname_TH`}
                  control={useForm.control}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      helperText={
                        useForm.formState.errors["familyList"] &&
                        useForm.formState.errors["familyList"][index] &&
                        useForm.formState.errors["familyList"][index][
                          "lastname_TH"
                        ]
                          ? useForm.formState.errors["familyList"][index][
                              "lastname_TH"
                            ].message
                          : null
                      }
                      error={
                        useForm.formState.errors["familyList"] &&
                        useForm.formState.errors["familyList"][index] &&
                        useForm.formState.errors["familyList"][index][
                          "lastname_TH"
                        ]
                          ? true
                          : false
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography>{`${t("PersonalID")}`}</Typography>
              </Grid>
              <Grid item xs={8}>
                {/* <Controller
                  name={`familyList.${index}.personalID`}
                  control={useForm.control}
                  render={({field}) => (
                    <TextFieldTheme
                      {...field}
                      helperText={useForm.formState.errors["familyList"] && useForm.formState.errors["familyList"][index] && useForm.formState.errors["familyList"][index]["personalID"]? useForm.formState.errors["familyList"][index]["personalID"].message: null}
                      error={useForm.formState.errors["familyList"] && useForm.formState.errors["familyList"][index] && useForm.formState.errors["familyList"][index]["personalID"]? true: false}
                    />
                  )}
                /> */}
                <Controller
                  name={`familyList.${index}.personalID`}
                  control={useForm.control}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      InputProps={{
                        inputComponent: NumberFormatTheme,
                      }}
                      inputProps={{
                        format: (() => {
                          if (field.value.length >= 13) {
                            return "#-####-#####-##-#";
                          } else if (
                            field.value.length >= 11 &&
                            field.value.length < 13
                          ) {
                            return "#-####-#####-###";
                          } else if (field.value.length >= 6) {
                            return "#-####-######";
                          } else if (field.value.length >= 2) {
                            return "#-#####";
                          } else {
                            return "##";
                          }
                        })(),
                        allowNegative: false,
                        allowLeadingZeros: true,
                        value: field.value,
                        onValueChange: (values) => {
                          const { value } = values;
                          field.onChange(value);
                        },
                      }}
                      onChange={() => {}}
                      helperText={
                        useForm.formState.errors["familyList"] &&
                        useForm.formState.errors["familyList"][index] &&
                        useForm.formState.errors["familyList"][index][
                          "personalID"
                        ]
                          ? useForm.formState.errors["familyList"][index][
                              "personalID"
                            ].message
                          : null
                      }
                      error={
                        useForm.formState.errors["familyList"] &&
                        useForm.formState.errors["familyList"][index] &&
                        useForm.formState.errors["familyList"][index][
                          "personalID"
                        ]
                          ? true
                          : false
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography>{`${t("BirthDate")}`}</Typography>
              </Grid>
              <Grid item xs={8}>
                {/* <Controller
                  name={`familyList.${index}.birthday`}
                  control={useForm.control}
                  render={({field}) => (
                    <TextFieldTheme
                      {...field}
                      helperText={useForm.formState.errors["familyList"] && useForm.formState.errors["familyList"][index] && useForm.formState.errors["familyList"][index]["birthday"]? useForm.formState.errors["familyList"][index]["birthday"].message: null}
                      error={useForm.formState.errors["familyList"] && useForm.formState.errors["familyList"][index] && useForm.formState.errors["familyList"][index]["birthday"]? true: false}
                    />
                  )}
                /> */}
                <Controller
                  name={`familyList.${index}.birthday`}
                  control={useForm.control}
                  render={({ field }) => (
                    <DatePickerCustom
                      {...field}
                      views={["year", "month", "day"]}
                      inputFormat="DD MMMM YYYY"
                      disableFuture
                      disableMaskedInput
                      openTo="year"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: `${t("DayMonthYear(AD)")}`,
                            readOnly: true,
                          }}
                          // InputProps={{
                          //   ...params.InputProps,
                          //   endAdornment: ([field.value? <IconButton className="clear-button date" onClick={()=>{field.onChange(null)}}><ClearIcon/></IconButton>: null, params.InputProps.endAdornment])
                          // }}
                          onBlur={field.onBlur}
                          helperText={
                            useForm.formState.errors["familyList"] &&
                            useForm.formState.errors["familyList"][index] &&
                            useForm.formState.errors["familyList"][index][
                              "birthday"
                            ]
                              ? useForm.formState.errors["familyList"][index][
                                  "birthday"
                                ].message
                              : null
                          }
                          error={
                            useForm.formState.errors["familyList"] &&
                            useForm.formState.errors["familyList"][index] &&
                            useForm.formState.errors["familyList"][index][
                              "birthday"
                            ]
                              ? true
                              : false
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}

        {useFieldArrayFamilyList.fields.length === 0 && (
          <Grid item xs={12} container justifyContent="center">
            <Typography fontSize="14px" color="text.secondary">
              {`${t("NoFamilyMember")}`}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default FamilyInfo;
