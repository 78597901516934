import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Avatar, Box, Container, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import dayjs from 'dayjs';
import ListIcon from '@mui/icons-material/List';
import MenuUser from "../homePage/menuUser";
import { useTranslation } from "react-i18next";
import { getInterviewedCandidatesByIdVendor } from "../../../../../actions/candidate";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { stringToColor } from "../../../../../utils";
import { t } from "i18next";

const StyledRoot = styled("div")({
  background: "#FFFFFF !important",
  "& .MuiFormControl-root-MuiTextField-root": {
    "& .MuiOutlinedInput-input": {
      color: "#000000"
    },
  },
  "& .MuiOutlinedInput-input": {
    color: "#000000"
  },
  "& .part-header": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .part-total": {
      textAlign: "right",
    }
  },
  "& .part-filter": {
    marginBottom: 24,
    "& .search-name": {
      width: 250,
    }
  },
  "& .part-action": {
    display: "flex",
    justifyContent: "right",
  },
  "& .btn-save": {
    display: "flex",
    justifyContent: "flex-end",
  },
})

const StyledTable = styled(TableContainer)(({ theme }) => ({
  maxHeight: 600,
  "& .MuiTableRow-root": {
    "& .MuiTableCell-root": {
      borderBottom: "none",
      backgroundColor: "#ffffff",
      zIndex: 2,
      "&:first-of-type": {
        position: "sticky",
        left: 0,
        zIndex: 4,
        boxShadow: "#35323b 0.5px 0px 6px 0px",
      }
    }
  },
  "& .MuiTableHead-root": {
    borderRadius: 20,
    "& .MuiTableCell-root": {
      backgroundColor: "#800080",
      color: "#ffffff",
      "&:first-of-type": {
        zIndex: 5,
        boxShadow: "#35323b 0.5px 0px 6px 0px",
        borderTopLeftRadius: 8,
        /* borderBottomLeftRadius: 8, */
      },
      "&:last-of-type": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      },
    }
  },
  "& .column-name": {
    display: "flex",
    alignItems: "center",
    "& .MuiAvatar-root": {
      width: 40,
      height: 40,
      marginRight: 8,
    }
  },
}));

const SplitStartTime = (params) => {
  let splitTime = params.appointmentStartTime.split(":");
  return dayjs().hour(splitTime[0]).minute(splitTime[1]).format("HH.mm");
};

const columns = [
  {
    field: "name",
    headerName: t("FullName"),
    width: 250,
    format: (params) => (
      <Box className="column-name" component={Link} to={`/manage/hunter/peer/profile/${params.idManpower}`} style={{ textDecoration: "none", color: "black" }}>
        <Avatar sx={{ bgcolor: stringToColor(`${params.name} ${params.lastName}`) }} children={`${params.name[0]}${params.lastName[0]}`} />
        <Typography sx={{ color: "#000000" }}>{`${params.name} ${params.lastName}`}</Typography>
      </Box>
    )
  },
  {
    field: "position",
    headerName: t("InterviewedJobPosition"),
    width: 300,
    format: (params) => (
      <Typography sx={{ color: "#000000" }}>{params.positionName}</Typography>
    )
  },
  {
    field: "company",
    headerName: t("InterviewedCompany"),
    width: 350,
    format: (params) => (
      <Typography sx={{ color: "#000000" }}>{params.companyName}</Typography>
    )
  },
  {
    field: "interview",
    headerName: t("InterviewAppointment"),
    width: 320,
    format: (params) => (
      <Box>
        <Typography sx={{ color: "#000000" }}>{`${dayjs(params.appointmentDate).format("DD MMM YYYY")} (${SplitStartTime(params)})`}</Typography>
        <Typography sx={{ color: "#000000" }}>{params.appointmentPlace}</Typography>
      </Box>
    )
  },
  {
    field: "contact",
    headerName: t("Contact"),
    width: 250,
    format: (params) => (
      <Box>
        <Typography sx={{ color: "#000000" }}>{params.contactName}</Typography>
        <Typography sx={{ color: "#000000" }}>{params.contactPhone}</Typography>
      </Box>
    )
  }
]

const HunterInterviewPage = () => {
  const {t,i18n} = useTranslation();
  const dispatch = useDispatch();
  const { result: ListInterviewedCandidate } = useSelector(state => state.interviewedCandidate);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterCandidate, setFilterCandidate] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [anchorEl, setAnchorEl] = useState(null)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeSearchName = (event) => {
    setSearchName(event.target.value);
  }

  useEffect(() => {
    dispatch(getInterviewedCandidatesByIdVendor());
  }, []);

  useEffect(() => {
    if (ListInterviewedCandidate && ListInterviewedCandidate.length > 0) {
      setFilterCandidate(ListInterviewedCandidate.filter(candidate => candidate.name.includes(searchName)));
    }
  }, [ListInterviewedCandidate, searchName])

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div className="btn-save">
          <ButtonBlue
            variant="outlined"
            onClick={(event) => { setAnchorEl(event.currentTarget) }}
          >
            <ListIcon sx={{ fontSize: "30px" }} />
            &nbsp;
            {t("MenuItems")}
          </ButtonBlue>
          <MenuUser
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
          />
        </div>
        <div className="part-header">
          <Typography variant="h4" sx={{ color: "#000000" }}>{t("InterviewedCandidatesList")}</Typography>
          <div className="part-total">
            <Typography variant="h4" sx={{ color: "#000000", marginTop: "10px" }}>{ListInterviewedCandidate && ListInterviewedCandidate.length || 0}</Typography>
            <Typography color="text.secondary">{t("AllInterviewedCandidates")}</Typography>
          </div>
        </div>


        <div className="part-filter">
          <TextFieldTheme
            sx={{ backgroundColor: "#ffffff", borderRadius: "10px" }}
            className="search-name"
            placeholder= {`${t("Search")}...`}
           value={searchName}
            onChange={handleChangeSearchName}
          />
        </div>

        <StyledTable>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.field}
                    align={column.align}
                    style={{ minWidth: column.width, maxWidth: column.width, width: column.width }}
                  >
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {filterCandidate && filterCandidate.length > 0 ?
                filterCandidate.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.idCandidate}>
                      {columns
                        .map((column) => (
                          <TableCell key={column.field} align={column.align}>
                            {
                              column.format(row)
                            }
                          </TableCell>
                        ))}
                    </TableRow>
                  ))
                :
                <TableRow style={{ height: 300 }}>
                  <TableCell align="center">
                    <Typography sx={{ color: "#000000" }}>{t("NoEmployee")}</Typography>
                  </TableCell>
                  <TableCell colSpan={5} align="center">
                    <Typography sx={{ color: "#000000" }}>{t("NoEmployee")}</Typography>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </StyledTable>
        <TablePagination
          rowsPerPageOptions={[2, 10, 25, 100]}
          component="div"
          count={filterCandidate.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${
                            count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
        />
      </Container>
    </StyledRoot>
  )
}

export default HunterInterviewPage;