import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, useMediaQuery, useTheme } from '@mui/material';

//Translator TH-EN
import { useTranslation } from "react-i18next";
import {
  Button,
  Container,
  Typography,
  Box,
  Divider,
  IconButton,
} from "@mui/material";
import Dropzone, { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import GalleryIcon from "../../assets/gallery.png";
import ButtonBlue from "../../shared/general/ButtonBlue";
import BackupIcon from '@mui/icons-material/Backup';
import Esignature from "./E-Signature"
import DialogSinatureImage from "./DialogSinatureImage";
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';


// import { useTheme } from '@mui/material/styles';

import {
  addSignatureImageOfEmployees,
  // getSignatureImageOfEmployees,
  deleteSignatureImageOfEmployees,
} from "../../../../actions/employee";


const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 24,
  marginBottom: 20,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 24,
  backgroundColor: "#f9f9fb",
  "& .dropzone-leave": {
    // height: 98,
    borderRadius: 8,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    // height: 90,
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#efefef",
    flexDirection: "column",
    padding: "40px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});


const StyledButton = styled(Button)`
  margin-right: 2px;
  background-color: ${props => props.active ? '#e0e0e0' : 'white'};
  color: ${props => props.active ? 'rgba(0, 0, 0, 0.6)' : 'gray'};
  transition: background-color 0.2s ease-in-out;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 13px; 
  font-weight: ${props => props.active ? 'bold' : 'normal'}; 

  &:hover {
    background-color: #ccc;
  }
`;


const DocumentTab = (props) => {

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState(1);
  const handleTabSwitch = (tabNumber) => setActiveTab(tabNumber);
  const MAX_FILE_SIZE = 10485760; //3 MB

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("");

  const handleChangeAlertType = (status) => {
    setAlertType(status);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const validFileExtensions = { file: ["jpg", "png", "jpeg"] };

  const isValidFileType = (fileName, fileType) => {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  };

  const validationSchema = Yup.object().shape({
    file: Yup.mixed()
      .test("is-valid-type", "Not a valid file type", (value) => {
        if (value) {
          return isValidFileType(value && value.name.toLowerCase(), "file");
        } else {
          return true;
        }
      })
      .test("is-valid-size", "Max allowed size is 3MB", (value) => {
        if (value) {
          alert('ไม่อนุญาตให้อัปโหลด')
          return value && value.size <= MAX_FILE_SIZE;
        } else {
          return true;
        }
      }),
  });

  const {
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: { ...formData },
    resolver: yupResolver(validationSchema),
  });

  const {
    employeeProfile,
    signatureOneImageURL,
    signatureOneImageName,
    setSignatureOneImageURL,
    isSignatureOneLoading,
    closeEditImageOpen
  } = props;

  const [nameOfImage, setNameOfImage] = useState(null);
  const [formData, setFormData] = useState({
    file: null,
  });
  const [fileUrl, setFileUrl] = useState('');

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOnSubmit = async (file) => {
    // console.log('dew',file);
    const formData = new FormData();

    if (employeeProfile && employeeProfile.idEmployees) {
      formData.append(  // recive value and name
        "image",
        file,
        nameOfImage ? nameOfImage : "employeeSignatureImage.png",
      );
      formData.append(
        "idCompany",
        employeeProfile.idCompany
      );

      // console.log(Array.from(formData));

      // for(let obj of (formData)) {
      //   console.log(obj);
      // }

    } else {
      console.error("Selected  employeeProfile is undefined or missing idCompany");
    }
    // console.log('formData',formData)

    const response = await dispatch(addSignatureImageOfEmployees(formData));
    // console.log('filedew', formData);

    if (response.status === 200) {
      handleChangeAlertType("success");
      handleOpenAlert();
      setIsDialogOpen(true);
      // dispatch(getCompanyProfile({idCompany: selectedCompany.idCompany}));
      //  const result = dispatch(getSignatureImageOfEmployees({idCompany: employeeProfile.idCompany}));
      //  console.log('dewresult',result);
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
    }
  };

  const onConfirmDelete = async () => {
    const response = await dispatch(deleteSignatureImageOfEmployees({ idCompany: employeeProfile.idCompany }));
    if (response.status === 200) {
      setSignatureOneImageURL(null);
      handleCloseDeleteDialog()
      // dispatch(getCompanyProfile({idCompany: selectedCompany.idCompany}));
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
    }
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <Box>

        {!isDialogOpen && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            <StyledButton
              onClick={() => handleTabSwitch(1)}
              active={activeTab === 1}
              startIcon={<AttachFileIcon size="small" />}
            >
              {`${t("AttachFile")}`}
            </StyledButton>
            <StyledButton
              onClick={() => handleTabSwitch(2)}
              active={activeTab === 2}
              startIcon={<FingerprintIcon size="small" />}
            >
              {`${t("E-Signature")}`}
            </StyledButton>

            <ButtonBlue
              onClick={() => closeEditImageOpen(false)}
            >
              {`${t("Cancel")}`}
            </ButtonBlue>
          </Box>
        )}

        {!isDialogOpen && activeTab === 1 && (
          <Box>
            <StyledDivider />
            <div>
              <Typography variant="h6" sx={{ textAlign: 'center', marginTop: 4, marginBottom: 2 }}>
                <StyledHeadLabel variant="h6">{t("AttachFile")}</StyledHeadLabel>
              </Typography>
              {!signatureOneImageURL && (
                <>
                  {!watch("file") && (
                    <StyledDropzone>
                      <Dropzone
                        accept="image/jpeg, image/png"
                        maxFiles={1}
                        multiple={false}
                        maxSize={10485760}
                        onDrop={(acceptedFiles) => {
                          if (acceptedFiles.length > 0) {
                            const file = acceptedFiles[0];
                            setNameOfImage(acceptedFiles[0].name);
                            setValue("file", acceptedFiles[0]);
                            setFileUrl(URL.createObjectURL(file));
                          }
                        }}
                      >
                        {({ getRootProps, getInputProps }) => ( //event listener for click or drag
                          <div {...getRootProps({ className: "dropzone-upload-file" })}>
                            <div className="inner-dropzone">
                              <input {...getInputProps()} />
                              <div style={{ textAlign: "center" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                  }}
                                >
                                  <img alt="add" src={GalleryIcon} />
                                </div>
                                <Typography
                                  variant="caption"
                                  style={{ lineHeight: 0.5, color: "#999999" }}
                                >
                                  {t("AllowedFileTypes")}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </StyledDropzone>
                  )}
                  {watch("file") && (
                    <>
                      <Box
                        style={{
                          padding: 15,
                          backgroundColor: "#f9f9fb",
                          display: "flex",
                          borderRadius: 15,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={fileUrl}
                          alt="Signature"
                          style={{
                            height: 250,
                            width: "auto",
                            borderRadius: 4,
                          }}
                        />
                      </Box>
                      <Box
                        style={{
                          padding: 15,
                          backgroundColor: "#f9f9fb",
                          display: "flex",
                          borderRadius: 15,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography>{watch("file.path")}</Typography>
                      </Box>
                    </>
                  )}
                </>
              )}
              {signatureOneImageURL && (
                <Box
                  style={{
                    position: "relative",
                    textAlign: "center",
                  }}
                >
                  <IconButton
                    style={{ position: "absolute", margin: 7, right: 0 }}
                    color="error"
                    onClick={handleOpenDeleteDialog}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <Dialog
                    open={openDeleteDialog}
                    onClose={handleCloseDeleteDialog}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: matchesXs ? '100px' : (matchesMd ? '100px' : '0'),
                      marginLeft: matchesXs ? '20px' : (matchesMd ? '400px' : '0'),
                    }}
                  >
                    <DialogActions>
                      <Button
                        onClick={handleCloseDeleteDialog}
                        sx={{ color: 'black', backgroundColor: '#FFFFFF', border: '1px solid lightblue', '&:hover': { backgroundColor: '#d0d9ff' } }}
                      >
                        {t("Cancel")}
                      </Button>
                      <Button
                        onClick={onConfirmDelete}
                        sx={{ color: 'white', backgroundColor: '#CD5C5C', '&:hover': { backgroundColor: '#FF0000' } }}
                      >
                        {t("Confirm")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Box
                    style={{
                      padding: 15,
                      backgroundColor: "#f9f9fb",
                      display: "flex",
                      borderRadius: 15,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {isSignatureOneLoading ? (
                      <CircularProgress />
                    ) : (
                      <img
                        src={signatureOneImageURL}
                        alt="Signature"
                        style={{
                          height: 250,
                          width: matchesXs ? '280px ' : (matchesMd ? 'auto' : '0'),
                          borderRadius: 4,
                        }}
                      />
                    )}
                  </Box>
                  <Typography>{signatureOneImageName}</Typography>
                </Box>
              )}
            </div>
            <StyledDivider />
            <Box display="flex" justifyContent="center" alignItems="center" gap={2}>

              {!signatureOneImageURL && (
                <>
                  {watch("file") && (
                    <ButtonBlue
                      variant="contained"
                      startIcon={<BackupIcon />}
                      onClick={() => handleOnSubmit(watch("file"))}
                    >
                      {t("Upload")}
                    </ButtonBlue>
                  )}
                  {!watch("file") && (
                    <Box>
                      <ButtonBlue
                        variant="contained"
                        startIcon={<BackupIcon />}
                        disabled={"file"}
                        onClick={() => handleOnSubmit(watch("file"))}
                      >
                        {t("Upload")}
                      </ButtonBlue>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        )}

        {!isDialogOpen && activeTab === 2 && (
          <>
            <StyledDivider />
            <Typography
              variant="h6"
              sx={{
                textAlign: 'center',
                marginTop: 4
              }}
            >
              <StyledHeadLabel variant="h6">{t("Sign online")}</StyledHeadLabel>
            </Typography>
            <Container
              maxWidth="md"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '40vh'
              }}
            >
              <Box
                sx={{
                  border: "2px solid black",
                  width: { xs: '100%', sm: 400, md: 500 },
                  height: 200,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 2,
                  backgroundColor: 'white'
                }}
              >
                <Esignature
                  employeeProfile={employeeProfile}
                  isDialogOpen={isDialogOpen}
                  setIsDialogOpen={setIsDialogOpen}
                  matchesXs={matchesXs}
                  matchesMd={matchesMd}
                />
              </Box>
            </Container>
          </>
        )}
        {isDialogOpen && <DialogSinatureImage employeeProfile={employeeProfile} />}
      </Box>
    </>
  )
}

export default DocumentTab;
