import Quill from 'quill';

const Block = Quill.import('blots/block');

class InsertResizableBox extends Block {
    static create(value) {
        const node = super.create();
        node.style.resize = 'both';
        node.style.overflow = 'auto';
        return node;
      }
}

InsertResizableBox.blotName = 'insertResizableBox';
InsertResizableBox.tagName = 'div'; 
export default InsertResizableBox;
