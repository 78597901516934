import {
    EDUCATION_FETCHING,
    EDUCATION_FAILED,
    EDUCATION_SUCCESS
} from "./types";

import educationService from "../services/education.service";

export const getEducationByIdEmp = (idUser) => async (dispatch) => {
    try {
        dispatch({
            type: EDUCATION_FETCHING
        });
        const res = await educationService.getEducationByIdEmp(idUser);
        if(res){
            dispatch({
                type: EDUCATION_SUCCESS,
                payload: res.data
            });
        }
    } catch (error) {
        dispatch({
            type: EDUCATION_FAILED
        });
    }
};

export const addEducation = (formData) => async() => {
    try {
      const res = await educationService.addEducation(formData);
      if (res) {
        return res
      }
    } catch (error) {
      if (error.response) {
        return error.response;
      }
    }
};

export const updateEducation = (idEducation, formData) => async() => {
    try {
      const res = await educationService.updateEducation(idEducation, formData);
      if (res) {
        return res
      }
    } catch (error) {
      if (error.response) {
        return error.response;
      }
    }
};

export const deleteEducation = (idEducation) => async() => {
  try {
    const res = await educationService.deleteEducation(idEducation);
    if (res) {
      return res
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
};