import React from "react";
import { useSelector } from "react-redux";

import UserDocumentService from "../user/my-document";
import AdminDocumentService from "../admin/document";

const DocumentServicePage = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <div>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ? (
          <AdminDocumentService />
        ) : (
          <UserDocumentService />
        )
      ) : null}
    </div>
  );
};

export default DocumentServicePage;
