import DoneAll from "@mui/icons-material/DoneAll";
import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, Box, IconButton, styled, Typography, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getLeaveRequest } from "../../../../actions/employee";
import {
  getAllLeaveWithDrawBy,
  updateApproveLeaveEmployeeBy,
} from "../../../../actions/leave";
import fileService from "../../../../services/file.service";

import ChipWithDrawCustom from "../../shared/chipWithDrawCustom";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TableCustom from "../../shared/tableCustom";
import DialogConfirmReject from "./DialogConfirmReject";
import CardRequest from "./CardRequest";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledButtonCancel = styled(ButtonBlue)({
  backgroundColor: "#E793B8",
  borderRadius: "8px",
  color: "#FFFFFF",
  marginRight: 8,
  width: 130,
  "&:hover": {
    backgroundColor: "#DC6384",
  },
});

const RequestLeave = (props) => {
  const { searchDate, setSnackBarConfig, setNumberOfList, selectedCompany } = props;
  const { t, i18n } = useTranslation();
  const mobileResponsive = useMediaQuery("(max-width:600px)");
  const { result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { result: leaveEmployeeWithdrawsList } = useSelector(
    (state) => state.leaveEmployeesWithDraw
  );

  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [pendingRows, setPendingRows] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);

  const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);

  const dispatch = useDispatch();

  const columns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>
              {row.firstname_TH} {row.lastname_TH}
            </Typography>
            <Typography color="text.third" fontSize="14px">
              {row.positionName}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("Type")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Fragment>
          {row.idLeaveEmployeesWithdraw && <ChipWithDrawCustom />}
          <Typography fontSize={14}>
            {i18n.resolvedLanguage === "th"
              ? row.name
              : row.name_EN ? row.name_EN : ""}
          </Typography>
          <Typography fontSize={14} color="text.secondary">
            {row.holidayName}
          </Typography>
        </Fragment>
      ),
    },
    {
      name: `${t("Start")}`,
      headerTextAlign: "center",
      minWidth: "130px",
      width: "130px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.startText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}
          </Typography>
          {row.isFullDay === 1 ? (
            <Typography>{`${t("Full_day")}`}</Typography>
          ) : (
            <Typography>
              {dayjs(row.startText, "DD/MM/YYYY HH:mm").format("HH:mm")}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      name: `${t("End")}`,
      headerTextAlign: "center",
      minWidth: "130px",
      width: "130px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}
          </Typography>
          {row.isFullDay === 1 ? (
            <Typography>{`${t("Full_day")}`}</Typography>
          ) : (
            <Typography>
              {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("HH:mm")}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      name: `${t("Reason")}`,
      minWidth: "200px",
      cellRender: (row) => (
        <Box>
          <Typography color="text.third" fontSize="14px">
            {row.description}
          </Typography>
        </Box>
      ),
    },
    {
      name: "",
      cellRender: (row) => (
        <Box>
          {row.filename &&
            <IconButton
              style={{
                width: "40px",
              }}
              aria-label="file"
              onClick={async () => {
                await fileService.downloadBucketFile(`leave/${row.idCompany}/${row.idLeave}/${row.filename}`).then(res => {
                  const url = window.URL.createObjectURL(new Blob([res.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', row.filename);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                });
              }}
            >
              <i className="fa-regular fa-file" style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "20px" }} />
            </IconButton>
          }
        </Box>
      ),
    },
  ];

  const handleApprovalRequest = async (approve, comment) => {
    const idLeave = selectedRows
      .filter((x) => {
        return x.idLeave && !x.idLeaveEmployeesWithdraw;
      })
      .map(function (user) {
        return user.idLeave;
      });

    const idLeaveEmployeesWithdraw = selectedRows
      .filter((x) => {
        return x.idLeaveEmployeesWithdraw;
      })
      .map(function (user) {
        return user.idLeaveEmployeesWithdraw;
      });

    const idLeaveWithdraw = selectedRows
      .filter((x) => {
        return (
          x.idLeaveEmployeesWithdraw &&
          x.managerApprove === userProfile.idEmployees
        );
      })
      .map(function (user) {
        return user.idLeave;
      });

    var formData = [
      {
        idLeave: idLeave,
        isApprove: approve,
        idManager: userProfile.idEmployees,
        approveDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm"),
        commentManager: comment ? comment : null,
      },
      {
        idLeaveEmployeesWithdraw: idLeaveEmployeesWithdraw,
        idLeave: idLeaveWithdraw,
        isApprove: approve,
        commentManager: comment ? comment : null,
      },
    ];

    const result = await dispatch(
      updateApproveLeaveEmployeeBy("manager", formData)
    );
    if (result) {
      setSelectedRows([]);
      if (result.status === 200) {
        dispatch(getLeaveRequest("admin", searchDate, selectedCompany.idCompany));
        dispatch(getAllLeaveWithDrawBy("admin", searchDate, selectedCompany.idCompany));
        setSnackBarConfig({
          open: true,
          message: `${t("Successfully")}`,
          type: "success",
        });
      } else {
        setSnackBarConfig({
          open: true,
          message: `${t("Unsuccessful")}`,
          type: "error",
        });
      }
    } else {
      setSnackBarConfig({
        open: true,
        message: `${t("Unsuccessful")}`,
        type: "error",
      });
    }
  };

  const handleConfirmReject = (comment) => {
    handleApprovalRequest(0, comment);
  };

  const handleClickAll = () => {
    setSelectedRows(pendingRows);
  };

  const handleCancel = () => {
    setSelectedRows([]);
  };

  useEffect(() => {
    const tempPending = [];

    leaveEmployeesList.map((r) => {
      if (r.isApprove === null) {
        tempPending.push(r);
      }
    });

    leaveEmployeeWithdrawsList.map((r) => {
      if (r.isApprove === null) {
        tempPending.push(r);
      }
    });

    let pendingList = tempPending.sort(function (a, b) {
      return new Date(b.createDate) - new Date(a.createDate);
    });
    setPendingRows(pendingList);
    setNumberOfList(pendingList.length);
  }, []);

  return (
    <Box>
      {mobileResponsive && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          {(selectedRows ? selectedRows.length : 0) > 0 && (
            <StyledButtonCancel
              startIcon={<DeleteIcon />}
              onClick={handleCancel}
            >
              {`${t("Cancel")}`}
            </StyledButtonCancel>
          )}
          {!(
            (selectedRows ? selectedRows.length : 0) === pendingRows.length
          ) && (
              <ButtonBlue
                startIcon={<DoneAll />}
                variant="contained"
                onClick={handleClickAll}
              >
                {`${t("SelectAll")}`}
              </ButtonBlue>
            )}
        </Box>
      )}
      {selectedRows.length > 0 && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          <ButtonBlue
            style={{ marginRight: 8 }}
            startIcon={<DoneAll />}
            variant="contained"
            onClick={() => handleApprovalRequest(1)}
          >
            {`${t("Approved")} ${selectedRows.length > 0
                ? `${selectedRows.length} ${t("List")}`
                : ""
              }`}
          </ButtonBlue>
          <ButtonBlue
            startIcon={<DoneAll />}
            variant="outlined"
            onClick={() => setIsOpenRejectDialog(true)}
          >
            {`${t("NotApproved")} ${selectedRows.length > 0
                ? `${selectedRows.length} ${t("List")}`
                : ""
              }`}
          </ButtonBlue>
        </Box>
      )}
      {!mobileResponsive ? (
        <TableCustom
          columns={columns}
          rows={
            leaveEmployeesList && leaveEmployeeWithdrawsList ? pendingRows : []
          }
          canSelect
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      ) : (
        <div>
          {leaveEmployeesList && leaveEmployeeWithdrawsList ? (
            pendingRows.map((row) => (
              <CardRequest
                key={row.idLeave}
                row={row}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />
            ))
          ) : (
            <div>
              <Typography align="center">{`${t("NoData")}`}</Typography>
            </div>
          )}
        </div>
      )}

      <DialogConfirmReject
        rejectCount={selectedRows.length}
        open={isOpenRejectDialog}
        onClose={() => {
          setIsOpenRejectDialog(false);
        }}
        handleSubmit={(comment) => {
          handleConfirmReject(comment);
        }}
      />
    </Box>
  );
};

export default RequestLeave;
