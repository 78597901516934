import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Card,
  Container,
  Grid,
  MenuItem,
  styled,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Autocomplete,
  TextField,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import NumberInputWithButton from "../../../shared/general/NumberInputWithButton";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import {
  getJobGroup,
  getJobEdit,
  addJob,
  updateJob,
} from "../../../../../actions/jobRecruit";
import { useHistory, useLocation } from "react-router-dom";
import CompleteImage from "./../../../assets/complete.png";
import { getDepartment } from "../../../../../actions/company";
import { getManager } from "../../../../../actions/manager";
import { getAllEmployees } from "../../../../../actions/employee";
import { getEmploymentTypeByidCompany } from "../../../../../actions/employment-type";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName,getUserManagerFullName
 } from "../../../../../utils/userData";

const StyledRootTwo = styled("div")(({ theme }) => ({
  background: "#FFFFFF !important",
  "& .displayFlexEnd": {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "8px 0",
    },
  },
}));

const StyledCard = styled(Card)({
  marginBottom: 20,
  padding: 16,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .MuiCardContent-root": {
    padding: 24,
  },
});

const OpenJobFormPage = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [dataProvince, setDataProvince] = useState([]);
  const [dataDistrict, setDataDistrict] = useState([]);
  const { result: JobGroupFormStore } = useSelector(
    (state) => state.jobRecruitGroup
  );
  const { isFetching: isJobFetching, result: JobDetail } = useSelector(
    (state) => state.jobRecruitEdit
  );
  const { result: departments } = useSelector((state) => state.department);
  const { result: manager } = useSelector((state) => state.manager);
  const { result: employmentType } = useSelector(
    (state) => state.employmentType
  );
  const { result: employees } = useSelector((state) => state.employees);

  const [jobGroup, setJobGroup] = useState([]);
  const [idJobRecruit, setIdJobRecruit] = useState(null);

  const [triStateCard, setTriStateCard] = useState(0);

  const dispatch = useDispatch();

  const [isSuccess, setIsSuccess] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  let timeoutDisplay;

  const handleCloseDisplay = (idJobNew) => {
    clearInterval(timeoutDisplay);
    if (idJobNew) {
      history.push(`/company/request/${idJobNew}`);
    } else {
      history.push(`/company/request/${idJobRecruit}`);
    }
  };

  const handleCountDownTimeSet = (idJobNew) => {
    timeoutDisplay = setTimeout(() => handleCloseDisplay(idJobNew), 3000);
  };

  const validationSchema = yup.object().shape({
    positionName: yup
      .string()
      .max(100)
      .required(`${t("PleaseEnterPosition")}`),
    levelJob: yup.string().required(`${t("PleaseEnterJobLevel")}`),
    jobDescription: yup
      .string()
      .max(180)
      .required(`${t("PleaseEnterJobDetails")}`),
    idJobGroup: yup.string().required(`${t("PleaseEnterJobGroup")}`),
    idDepartment: yup.lazy((value) =>
      value === ""
        ? yup.string().required(`${t("PleaseSelectDepartment")}`)
        : yup.number().required(`${t("PleaseSelectDepartment")}`)
    ),
    employeeType: yup.lazy((value) =>
      value === ""
        ? yup.string().required(`${t("PleaseSelectDepartment")}`)
        : yup.number().required(`${t("PleaseSelectDepartment")}`)
    ),
    locationAddress: yup
      .string()
      .max(100)
      .required(`${t("PleaseEnterWorkplaceLocation")}`),
    locationProvince: yup.string().required(`${t("PleaseSelectProvince")}`),
    locationDistrict: yup.string().required(`${t("PleaseSelectDistrict")}`),
    workExperience: yup
      .string()
      .max(100)
      .required(`${t("PleaseSelectWorkExperience")}`),
    educationDegree: yup
      .string()
      .max(100)
      .required(`${t("PleaseSelectMinimumEducationLevel")}`),
    qualificationRequire: yup
      .string()
      .max(100)
      .required(`${t("PleaseEnterDesiredQualifications")}`),
    quota: yup.number().required(`${t("PleaseEnterNumberVacancies")}`),
    closeRegisterDate: yup.date(),
    approvedBy: yup.number().required().nullable(),
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { errors, dirtyFields },
    reset,
    setError,
  } = useForm({
    defaultValues: {
      positionName: "",
      levelJob: "",
      jobDescription: "",
      idJobGroup: "",
      idDepartment: "",
      employeeType: "",
      locationAddress: "",
      locationProvince: "",
      locationDistrict: "",
      workExperience: "",
      educationDegree: "",
      qualificationRequire: "",
      quota: 1,
      closeRegisterDate: dayjs(),
      approvedBy: null,
      idEmployeesReplace: null,
      recruitType: "new",
    },
    resolver: yupResolver(validationSchema),
  });

  const handleSelectedDistrictEdit = () => {
    if (dataProvince.length > 0) {
      const provinceSelected = dataProvince.find(
        (index) => index.name_th === JobDetail.locationProvince
      );
      const districtSelected =
        provinceSelected &&
        provinceSelected.amphure.find(
          (index) => index.name_th === JobDetail.locationDistrict
        );
      setValue("locationProvince", provinceSelected.name_th);
      setValue("locationDistrict", districtSelected.name_th);
      setDataDistrict(provinceSelected.amphure);
    }
  };

  const handleSelectedDistrictFromProvince = (idProvince) => {
    setDataDistrict(dataProvince[idProvince - 1].amphure); // id start at 1
  };

  const handleMenuItemClick = (event) => {
    const provinceValue = event.currentTarget.dataset.value;
  };

  const handleNextStep = async () => {
    const checkValidate = await trigger([
      "positionName",
      "levelJob",
      "jobDescription",
      "idJobGroup",
      "idDepartment",
      "locationAddress",
      "locationProvince",
      "locationDistrict",
      "workExperience",
      "educationDegree",
    ]);
    if (checkValidate) {
      setTriStateCard(1);
    }
  };

  const onSubmit = async (formData) => {
    if (!isEdit) {
      const cleansingForm = {
        positionName: formData.positionName,
        levelJob: formData.levelJob,
        jobDescription: formData.jobDescription,
        idJobGroup: formData.idJobGroup,
        idDepartment: formData.idDepartment,
        employeeType: formData.employeeType,
        locationAddress: formData.locationAddress,
        locationProvince: formData.locationProvince,
        locationDistrict: formData.locationDistrict,
        workExperience: formData.workExperience,
        educationDegree: formData.educationDegree,
        qualificationRequire: formData.qualificationRequire,
        quota: formData.quota,
        closeRegisterDate: formData.closeRegisterDate,
        approvedBy: formData.approvedBy,
        idEmployeesReplace: formData.idEmployeesReplace,
        recruitType: formData.recruitType,
      };

      const response = await dispatch(addJob(cleansingForm));
      if (response.status === 201) {
        setIsSuccess(true);
        setIdJobRecruit(response.data.idJobRecruit);
        handleCountDownTimeSet(response.data.idJobRecruit);
      }
    } else {
      let editedData = {};
      Object.keys(dirtyFields).map((key) => {
        editedData[key] = formData[key] || null;
      });
      const response = await dispatch(
        updateJob(location.state.idJobRecruit, editedData)
      );

      if (response.status === 200) {
        setIsSuccess(true);
        handleCountDownTimeSet();
      }
    }
  };

  const fetchAPI = () => {
    fetch(
      "https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province_with_amphure_tambon.json"
    )
      .then((response) => response.json())
      .then((result) => {
        setDataProvince(result);
      });
  };

  useEffect(() => {
    fetchAPI();
  }, []);

  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getJobGroup());
    dispatch(getManager());
    dispatch(getEmploymentTypeByidCompany());
    dispatch(getAllEmployees());
  }, []);

  useEffect(() => {
    if (JobGroupFormStore !== null) {
      setJobGroup([JobGroupFormStore.JobGroup]);
    }
  }, [JobGroupFormStore]);

  useEffect(() => {
    if (location.state && location.state.idJobRecruit) {
      setIsEdit(true);
      setIdJobRecruit(location.state.idJobRecruit);
      dispatch(getJobEdit(location.state.idJobRecruit));
    }
  }, []);

  useEffect(() => {
    if (isEdit && JobDetail !== null) {
      handleSelectedDistrictEdit();
      reset({
        positionName: JobDetail.positionName,
        levelJob: JobDetail.levelJob,
        jobDescription: JobDetail.jobDescription,
        idJobGroup: JobDetail.idJobGroup,
        idDepartment: JobDetail.idDepartment,
        locationAddress: JobDetail.locationAddress,
        locationProvince: JobDetail.locationProvince,
        locationDistrict: JobDetail.locationDistrict,
        workExperience: JobDetail.workExperience,
        educationDegree: JobDetail.educationDegree,
        qualificationRequire: JobDetail.qualificationRequire,
        quota: JobDetail.quota,
        closeRegisterDate: JobDetail.closeRegisterDate,
      });
    }
  }, [JobDetail]);

  const steps = ["First", "Second"];

  return (
    <StyledRootTwo className="page">
      {!isSuccess && (
        <Container maxWidth="lg">
          {!isEdit && (
            <Fragment>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ paddingTop: 8 }}
                  >
                    {`${t("JobOpenings")}`}
                  </Typography>
                </Grid>
              </Grid>
            </Fragment>
          )}
          {isEdit && (
            <Fragment>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ paddingTop: 8 }}
                  >
                    {`${t("EditJobOpenings")}`} /{" "}
                    {JobDetail && JobDetail.positionName}
                  </Typography>
                </Grid>
              </Grid>
            </Fragment>
          )}
          <StyledCard className="card-container">
            <Box sx={{ width: "100%" }} marginBottom={7} marginTop={2}>
              <Stepper activeStep={triStateCard} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              {triStateCard === 0 && (
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      name="recruitType"
                      render={({ field, fieldState }) => (
                        <FormControl>
                          <FormLabel>{`${t("EmploymentType")}`}</FormLabel>
                          <RadioGroup
                            {...field}
                            row
                            error={Boolean(fieldState.errors)}
                          >
                            <FormControlLabel
                              value={"new"}
                              control={<Radio />}
                              label={`${t("NewJob")}`}
                            />
                            <FormControlLabel
                              value={"replace"}
                              control={<Radio />}
                              label={`${t("ReplaceExistingEmployee")}`}
                            />
                          </RadioGroup>
                          {Boolean(fieldState.error) && (
                            <FormHelperText error>
                              {fieldState.error.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {watch("recruitType") === "replace" && employees && (
                      <Controller
                        control={control}
                        name="idEmployeesReplace"
                        render={({ field, fieldState }) => (
                          <Autocomplete
                            {...field}
                            options={employees}
                            getOptionLabel={(option) =>
                              `${option.firstname_TH} ${option.lastname_TH} (${option.positionName})`
                            }
                            onChange={(evant, value) => field.onChange(value)}
                            isOptionEqualToValue={(option, value) =>
                              option.idEmployees === value.idEmployees
                            }
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.idEmployees}>
                                  {`${option.firstname_TH} ${option.lastname_TH} (${option.positionName})`}
                                </li>
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean(fieldState.error)}
                                helperText={
                                  fieldState.error && fieldState.error.message
                                }
                                label={`${t("EmployeeName")}`}
                              />
                            )}
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Controller
                      control={control}
                      name="positionName"
                      render={({ field, formState }) => (
                        <Fragment>
                          <Typography className="fieldLabel" gutterBottom>
                            {`${t("PositionName")}`}
                          </Typography>
                          <TextFieldTheme
                            {...field}
                            autoComplete="positionName"
                            error={
                              formState.errors && formState.errors[field.name]
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors && formState.errors[field.name]
                                ? formState.errors[field.name].message
                                : null
                            }
                          />
                        </Fragment>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      control={control}
                      name="levelJob"
                      render={({ field, formState }) => (
                        <Fragment>
                          <Typography className="fieldLabel" gutterBottom>
                            {`${t("JobLevel")}`}
                          </Typography>
                          <TextFieldTheme
                            {...field}
                            select
                            autoComplete="levelJob"
                            error={
                              formState.errors && formState.errors[field.name]
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors && formState.errors[field.name]
                                ? formState.errors[field.name].message
                                : null
                            }
                          >
                            <MenuItem value="Executive">Executive</MenuItem>
                            <MenuItem value="Management">Management</MenuItem>
                            <MenuItem value="SeniorManager">
                              Senior Manager
                            </MenuItem>
                            <MenuItem value="Manager">Manager</MenuItem>
                            <MenuItem value="Senior">Senior</MenuItem>
                            <MenuItem value="Junior">Junior</MenuItem>
                          </TextFieldTheme>
                        </Fragment>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="jobDescription"
                      render={({ field, formState }) => (
                        <Fragment>
                          <Typography className="fieldLabel" gutterBottom>
                            {`${t("JobDescription")}`}
                          </Typography>
                          <TextFieldTheme
                            {...field}
                            autoComplete="jobDescription"
                            error={
                              formState.errors && formState.errors[field.name]
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors && formState.errors[field.name]
                                ? formState.errors[field.name].message
                                : null
                            }
                            multiline
                            rows={4}
                          />
                        </Fragment>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Controller
                      control={control}
                      name="idJobGroup"
                      render={({ field, formState }) => (
                        <Fragment>
                          <Typography className="fieldLabel" gutterBottom>
                            {`${t("JobGroup")}`}
                          </Typography>
                          <TextFieldTheme
                            {...field}
                            autoComplete="jobGroup"
                            error={
                              formState.errors && formState.errors[field.name]
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors && formState.errors[field.name]
                                ? formState.errors[field.name].message
                                : null
                            }
                            select
                          >
                            <MenuItem disabled>{`${t("JobGroup")}`}</MenuItem>
                            {JobGroupFormStore &&
                              JobGroupFormStore.map((job, index) => (
                                <MenuItem
                                  key={job.idJobGroup}
                                  value={job.idJobGroup}
                                >
                                  {job.jobGroupName}
                                </MenuItem>
                              ))}
                          </TextFieldTheme>
                        </Fragment>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    {departments && (
                      <Controller
                        control={control}
                        name="idDepartment"
                        render={({ field, fieldState }) => (
                          <Fragment>
                            <Typography className="fieldLabel" gutterBottom>
                              {`${t("Department")}`}
                            </Typography>
                            <TextFieldTheme
                              {...field}
                              select
                              error={Boolean(fieldState.error)}
                              helperText={
                                fieldState.error && fieldState.error.message
                              }
                            >
                              {departments
                                .filter((item) => item.idDepartment)
                                .map((item) => (
                                  <MenuItem
                                    key={item.idDepartment}
                                    value={item.idDepartment}
                                  >
                                    {item.departmentName}
                                  </MenuItem>
                                ))}
                            </TextFieldTheme>
                          </Fragment>
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={2}>
                    {employmentType && (
                      <Controller
                        control={control}
                        name="employeeType"
                        render={({ field, fieldState }) => (
                          <Fragment>
                            <Typography className="fieldLabel" gutterBottom>
                              {`${t("EmployeeType")}`}
                            </Typography>
                            <TextFieldTheme
                              {...field}
                              select
                              error={Boolean(fieldState.error)}
                              helperText={
                                fieldState.error && fieldState.error.message
                              }
                            >
                              {employmentType.map((item) => (
                                <MenuItem value={item.idEmploymentType}>
                                  {item.employmentTypeName}
                                </MenuItem>
                              ))}
                            </TextFieldTheme>
                          </Fragment>
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      name="locationAddress"
                      render={({ field, formState }) => (
                        <Fragment>
                          <Typography className="fieldLabel" gutterBottom>
                            {`${t("WorkingLocationPlace")}`}
                          </Typography>
                          <TextFieldTheme
                            {...field}
                            autoComplete="locationAddress"
                            error={
                              formState.errors && formState.errors[field.name]
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors && formState.errors[field.name]
                                ? formState.errors[field.name].message
                                : null
                            }
                          />
                        </Fragment>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      control={control}
                      name="locationProvince"
                      render={({ field, fieldState }) => (
                        <Fragment>
                          <Typography className="fieldLabel" gutterBottom>
                            {`${t("Province")}`}
                          </Typography>
                          <TextFieldTheme
                            {...field}
                            autoComplete="locationProvince"
                            select
                            error={Boolean(fieldState.error)}
                            helperText={
                              fieldState.error && fieldState.error.message
                            }
                          >
                            {dataProvince.map((item) => (
                              <MenuItem
                                key={item.id}
                                value={item.name_th}
                                onClick={(e) => {
                                  handleMenuItemClick(e);
                                  handleSelectedDistrictFromProvince(item.id);
                                }}
                              >
                                {item.name_th}
                              </MenuItem>
                            ))}
                          </TextFieldTheme>
                        </Fragment>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      control={control}
                      name="locationDistrict"
                      render={({ field, fieldState }) => (
                        <Fragment>
                          <Typography className="fieldLabel" gutterBottom>
                            {`${t("District")}`}
                          </Typography>
                          <TextFieldTheme
                            {...field}
                            autoComplete="locationDistrict"
                            select
                            error={Boolean(fieldState.error)}
                            helperText={
                              fieldState.error && fieldState.error.message
                            }
                          >
                            {dataDistrict.map((item) => (
                              <MenuItem key={item.id} value={item.name_th}>
                                {item.name_th}
                              </MenuItem>
                            ))}
                          </TextFieldTheme>
                        </Fragment>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      name="workExperience"
                      render={({ field, formState }) => (
                        <Fragment>
                          <Typography className="fieldLabel" gutterBottom>
                            {`${t("WorkExperience")}`}
                          </Typography>
                          <TextFieldTheme
                            {...field}
                            autoComplete="work-experience"
                            error={
                              formState.errors && formState.errors[field.name]
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors && formState.errors[field.name]
                                ? formState.errors[field.name].message
                                : null
                            }
                            select
                          >
                            <MenuItem value="0">{`${t("Unlimited")}`}</MenuItem>
                            <MenuItem value="1">1-3 {`${t("Year")}`}</MenuItem>
                            <MenuItem value="2">3-5 {`${t("Year")}`}</MenuItem>
                            <MenuItem value="3">5-7 {`${t("Year")}`}</MenuItem>
                            <MenuItem value="4">
                              7 {`${t("YearsAbove")}`}
                            </MenuItem>
                          </TextFieldTheme>
                        </Fragment>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      name="educationDegree"
                      render={({ field, formState }) => (
                        <Fragment>
                          <Typography className="fieldLabel" gutterBottom>
                            {`${t("MinimumEducationDegree")}`}
                          </Typography>
                          <TextFieldTheme
                            {...field}
                            autoComplete="education-degree"
                            error={
                              formState.errors && formState.errors[field.name]
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors && formState.errors[field.name]
                                ? formState.errors[field.name].message
                                : null
                            }
                            select
                          >
                            <MenuItem value="0">{`${t("Unlimited")}`}</MenuItem>
                            <MenuItem value="1">{`${t(
                              "PrimaryEducation"
                            )}`}</MenuItem>
                            <MenuItem value="2">{`${t(
                              "SecondaryEducation"
                            )}`}</MenuItem>
                            <MenuItem value="3">
                              {`${t("​CertificateOfTechnicalVocation")}`}/
                              {`${t("VocationalCertificate")}`}
                            </MenuItem>
                            <MenuItem value="4">{`${t(
                              "BachelorDegrees"
                            )}`}</MenuItem>
                            <MenuItem value="5">{`${t(
                              "MasterDegrees"
                            )}`}</MenuItem>
                            <MenuItem value="6">{`${t(
                              "DoctorDegrees"
                            )}`}</MenuItem>
                          </TextFieldTheme>
                        </Fragment>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} marginTop={3} container>
                    <Grid item xs={3} container>
                      {isEdit && triStateCard === 0 && (
                        <ButtonBlue
                          className="confirm-button"
                          variant="outlined"
                          onClick={() => {
                            history.goBack();
                          }}
                        >
                          {`${t("Cancel")}`}
                        </ButtonBlue>
                      )}
                    </Grid>
                    <Grid item xs={9} container justifyContent="flex-end">
                      <ButtonBlue variant="contained" onClick={handleNextStep}>
                        {`${t("Next")}`}
                      </ButtonBlue>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {triStateCard === 1 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="qualificationRequire"
                      render={({ field, formState }) => (
                        <Fragment>
                          <Typography className="fieldLabel" gutterBottom>
                            {`${t("DesiredQualifications")}`}
                          </Typography>
                          <TextFieldTheme
                            {...field}
                            autoComplete="qualificationRequire"
                            error={
                              formState.errors && formState.errors[field.name]
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors && formState.errors[field.name]
                                ? formState.errors[field.name].message
                                : null
                            }
                            multiline
                            rows={4}
                          />
                        </Fragment>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      control={control}
                      name="quota"
                      render={({ field, formState }) => (
                        <Fragment>
                          <Typography className="fieldLabel" gutterBottom>
                            {`${t("NumberJobOpenings")}`}
                          </Typography>
                          <NumberInputWithButton
                            {...field}
                            placeholder={`${t("NumberJobOpenings")}`}
                            autoComplete="quota"
                            error={
                              formState.errors && formState.errors[field.name]
                                ? true
                                : false
                            }
                            helperText={
                              formState.errors && formState.errors[field.name]
                                ? formState.errors[field.name].message
                                : null
                            }
                          />
                        </Fragment>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Controller
                      control={control}
                      name="closeRegisterDate"
                      render={({ field, formState }) => (
                        <Fragment>
                          <Typography className="fieldLabel" gutterBottom>
                            {`${t("ClosingDate")}`}
                          </Typography>
                          <DesktopDatePicker
                            {...field}
                            inputFormat="DD-MM-YYYY"
                            disablePast
                            renderInput={(params) => (
                              <TextFieldTheme
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "ตัวอย่าง: 31-12-2022",
                                }}
                                autoComplete="close-register-date"
                                error={
                                  formState.errors &&
                                  formState.errors[field.name]
                                    ? true
                                    : false
                                }
                                helperText={
                                  formState.errors &&
                                  formState.errors[field.name]
                                    ? formState.errors[field.name].message
                                    : null
                                }
                              />
                            )}
                          />
                        </Fragment>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Controller
                      control={control}
                      name="approvedBy"
                      render={({ field, fieldState }) => (
                        <Fragment>
                          <Typography className="fieldLabel" gutterBottom>
                            {`${t("Approver")}`}
                          </Typography>
                          <Autocomplete
                            options={manager}
                            isOptionEqualToValue={(option, value) =>
                              option.idEmployees === value.idEmployees
                            }
                            getOptionLabel={(option) =>
                              `${getUserManagerFullName(option)}`
                            }
                            onChange={(event, value) =>
                              field.onChange(value.idEmployees)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean(fieldState.error)}
                                helperText={
                                  fieldState.error && fieldState.error.message
                                }
                              />
                            )}
                          />
                        </Fragment>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} marginTop={3} container>
                    <Grid item md={3} container>
                      <Grid>
                        <ButtonBlue
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            setTriStateCard(0);
                          }}
                        >
                          {`${t("Back")}`}
                        </ButtonBlue>
                      </Grid>
                    </Grid>
                    <Grid item md={9} container justifyContent="flex-end">
                      <Grid>
                        <ButtonBlue
                          className="confirm-button"
                          variant="contained"
                          type="submit"
                          disabled={
                            isEdit && Object.keys(dirtyFields).length === 0
                          }
                        >
                          {`${t("Confirm")}`}
                        </ButtonBlue>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </form>
          </StyledCard>
        </Container>
      )}

      {isSuccess && (
        <div className="success-container">
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                style={{ width: 300, marginBottom: "20px" }}
                src={CompleteImage}
                alt="open-job-success"
              />
            </div>
            <Typography variant="h3" textAlign={"center"}>
              {!isEdit
                ? `${t("JobApplicationsSuccessful")}`
                : `${t("EditSuccessfulJobApplications")}`}
            </Typography>
          </div>
        </div>
      )}
    </StyledRootTwo>
  );
};

export default OpenJobFormPage;
