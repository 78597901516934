import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogContentText,
  Grid,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogType from "./dialogType";
import ButtonBlue from "../../shared/general/ButtonBlue";
import AddIcon from '@mui/icons-material/Add';
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import CardStyle from "../../shared/general/Card";
import LoadingIcon from '../../assets/social-media.gif';
import Modal from '@mui/material/Modal';

import {
  getAllDocumentsById,
  getallSmartDocumentsById,
  deleteSmartDocumentInfo,
} from "../../../../actions/document";
import {
  getAffiliateOrganization,
} from "../../../../actions/company";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const useStyles = makeStyles({
  iconButton: {
    transition: 'transform 0.3s ease',
    color: '#000000',
    '&:hover': {
      transform: 'scale(1.2)',
      color: '#3f51b5',
    },
  },
  pdfButton: {
    transition: 'transform 0.3s ease',
    color: '#000000',
    '&:hover': {
      transform: 'scale(1.2)',
      color: '#1E90FF',
    },
  },
  iconButton: {
    transition: 'transform 0.3s ease',
    color: '#000000',
    '&:hover': {
      transform: 'scale(1.2)',
      color: '#00CC00',
    },
  },
  iconButtonDelete: {
    transition: 'transform 0.3s ease',
    color: '#000000',
    '&:hover': {
      transform: 'scale(1.2)',
      color: '#FF0000',
    },
  },
  cancelButton: {
    color: 'rgba(0, 0, 255, 0.7)',
    border: '1px solid blue',
    borderRadius: '5px',
    padding: '5px 10px',
    fontSize: '13px',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: 'rgba(173, 216, 230, 0.6)',
    },
  },
  confirmButton: {
    color: 'white',
    borderRadius: '5px',
    padding: '5px 10px',
    fontSize: '13px',
    fontWeight: 'bold',
    backgroundColor: 'rgba(255, 0, 0, 0.6)',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: 'rgba(255, 0, 0, 0.5)'
    },
  },
  dialogBackground: {
    '& .MuiDialog-paper': {
      backgroundColor: '##BEBEBE',
      borderRadius: '7px',
    },
    '& .css-ut4szi': {
      fontSize: '24px',
      width: '90vw',
      height: '90vh',
    },
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none',
        border: '0px solid black',
        background: 'white',
      },
    },
    '& .MuiBackdrop-root': {
      backdropFilter: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '40vw',
      maxHeight: '60vh',
    },
    '& .MuiBackdrop-root.MuiModal-backdrop': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '0vw',
      maxHeight: '0vh',
    },
  },
  button: {
    backgroundColor: "#f0f0f0",
    color: "#333",
    borderRadius: "10px",
    padding: "10px 20px",
    border: "none",
    boxShadow: "none",
    backgroundColor: "#0000",
  },
});

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 48,
  "& .wrap-check, .wrap-leave": {
    marginTop: 36,
  },
  "& .wrap-check-in, .wrap-check-out, .leave": {
    borderRadius: 16,
    "& .head": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 24,
    },
  },
  "& .leave": {
    "& .filter": {
      padding: 24,
      display: "flex",
      justifyContent: "space-between",
    },
  },
});

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 1000px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: 36,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#FFFFFF",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    border: "1px solid #B0B0B0",
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
      border: "1px solid #919eab",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
})

const StyledCloseButton = styled(Box)({
  position: 'absolute',
  top: '-9px',
  right: '-24px',
  color: 'white',
  fontSize: '16px',
  border: 'none',
  cursor: 'pointer',
  borderRadius: '50%',
  width: '35px',
  height: '35px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: '#d32f2f',
  },
});

const StyleModalButton = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  padding: '20px',
  outline: 'none',
  width: '40%',
  height: '85%',
  position: 'relative',
});

const TABLE_HEADERS = [
  { key: "ชื่อเอกสาร", label: "DocumentName" },
  { key: "DETAILS", label: "Details" },
  { key: "LastUpdated", label: "LastUpdate" },
  { key: "FILE", label: "Document" },
  { key: "EDIT", label: "Edit" }
];


const SmartDocumentPage = () => {

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { result: allSmartDocument } = useSelector((state) => state.allSmartDocument);
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeDocList } = useSelector((state) => state.document);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [filteredSmartDocumentType, setFilteredSmartDocumentType] = useState([]);

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (selectedCompany && selectedCompany.idCompany) {
      dispatch(getAllDocumentsById(selectedCompany.idCompany));
      dispatch(getallSmartDocumentsById(selectedCompany.idCompany));
    }

    if (reload) {
      setReload(false);
    }
  }, [selectedCompany, reload]);

  useEffect(() => {
    if (
      userProfile &&
      affiliateOrganizationList &&
      affiliateOrganizationList.length > 0
    ) {
      let ownCompany = affiliateOrganizationList.find(
        (x) => x.idCompany === userProfile.idCompany
      );
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany);
    }
  }, [userProfile, affiliateOrganizationList]);

  useEffect(() => {
    if (selectedCompany) {
      const newFilteredSmartDocumentType = Array.isArray(allSmartDocument)
        ? allSmartDocument.filter(type => type.idCompany === selectedCompany.idCompany)
        : [];
      setFilteredSmartDocumentType(newFilteredSmartDocumentType);
    }
  }, [selectedCompany, allSmartDocument]);

  const setdata = () => {
    if (filteredSmartDocumentType && filteredSmartDocumentType.length > 0) {
      if (selectedDocumentType) {
        let temp = filteredSmartDocumentType.filter((row) => {
          return row.documentTypeName === selectedDocumentType;
        });
        return [...temp];
      } else {
        return [...filteredSmartDocumentType,];
      }
    } else {
      return [...filteredSmartDocumentType];
    }
  };

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDialogDelete(false);
  };

  const handleFileClose = () => {
    if (reload === 1) setReload(2);
    else setReload(1);
  };

  const handleConfirmDelete = async (type, formData) => {
    setOpenDialog(false);

    try {
      let result;
      setLoading(true);
      switch (type) {
        case 'delete':
          result = await dispatch(deleteSmartDocumentInfo(formData));
          if (result) {
            setLoading(false);
          }
          break;
        default:
          console.log('Unknown delete type');
          throw new Error('Unknown delete type');
      }
      if (result) {
        Swal.fire({
          icon: 'success',
          title: `${t("DeletedSuccessfully")}!!`,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: `${t("AnErrorOccurred")}!!`,
          text: `${t("PleaseTryAgain")}!!`,
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      console.error('Error deleting document:', error);
      Swal.fire({
        icon: 'error',
        title: `${t("AnErrorOccurred")}!!`,
        text: `${t("PleaseTryAgain")}!!`,
        confirmButtonText: 'OK',
      });
    } finally {
      setLoading(false);
      handleCloseDeleteDialog();
      handleFileClose()
    }
  };

  const [open, setOpen] = useState(false);
  const [pdf, setPdf] = useState(false);;

  const handleCloseImage = () => setOpen(false);

  const handleOpen = (fileURL) => {
    if (fileURL) {
      setOpen(true);
      setPdf(fileURL)
    } else {
      alert("No PDF available");
    }
  };

  const [dialogType, setDialogType] = useState(null);
  const [deleteDialogType, setDeleteDialogType] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const handleClickOpen = (row, dialogType) => {
    setSelectedRow(row);
    if (dialogType === 'edit') {
      setDialogType("edit")
      setOpenDialog(true);
    } else if (dialogType === 'delete') {
      setDeleteDialogType("delete")
      setDialogType(null)
      setOpenDialogDelete(true);
    } else {
      setDialogType("Add")
      setOpenDialog(true);
    }
  };

  return (
    <div>
      <StyledRoot>
        {allSmartDocument
          ? (
            <>
              <Grid item xs={12} sm={6} md={3} sx={{ marginBottom: 1 }}>
                <Typography
                  className="label"
                  color="text.third"
                  style={{ marginBottom: 7, marginLeft: 3 }}
                >
                  {t("Company")}
                </Typography>
                <SelectAffiliateCompany
                  options={affiliateOrganizationList}
                  value={selectedCompany}
                  onChange={(_, value) => {
                    onChangeCompany(value);
                  }}
                />
              </Grid>
              <StyledWrapFilter>
                <Grid
                  container
                  spacing={2}
                  columns={12}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={12} sm={6} md={3} style={{ paddingLeft: '20px' }}>
                    <StyledBoxSearch>
                      <Typography className="label" color="text.third">
                        {t("SearchForDocumentType")}
                      </Typography>
                      <div className="search-name">
                        {allSmartDocument && (
                          <StyledAutocomplete
                            options={allSmartDocument}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setSelectedDocumentType(newValue.documentTypeName || '');
                              } else {
                                setSelectedDocumentType(null);
                              }
                            }}
                            popupIcon={<i className="fa-light fa-chevron-down"></i>}
                            getOptionLabel={(option) =>
                              `${option.documentTypeName}`
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                placeholder={`${t("SelectDocumentType")}`}
                              />
                            )}
                            PopperComponent={StyledPopper}
                            noOptionsText={`${t("NoData")}`}
                            value={
                              selectedDocumentType
                                ? filteredSmartDocumentType.find(
                                  (item) => item.documentTypeName === selectedDocumentType
                                )
                                : null
                            }
                          />
                        )}
                      </div>
                    </StyledBoxSearch>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} style={{ textAlign: "right" }}>
                    <StyledBoxSearch>
                      <ButtonBlue
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => handleClickOpen(allSmartDocument, "Add")}
                        style={{ marginRight: 10 }}
                      >
                        {`${t("AddDocumentType")}`}
                      </ButtonBlue>
                    </StyledBoxSearch>
                  </Grid>
                </Grid>
              </StyledWrapFilter>
              <CardStyle style={{ marginTop: 0, userSelect: 'none' }}>
                <Box style={{ padding: "24px" }}>
                  <Box style={{ display: "flex", position: "relative" }}>
                    <StyledBox className={"close"}>
                      <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {TABLE_HEADERS.map((header) => (
                                <TableCell align="center">
                                  {t(header.label)}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredSmartDocumentType && filteredSmartDocumentType.length > 0 ? (
                              setdata().map((row, index) => (
                                <React.Fragment key={index}>
                                  <TableRow>
                                    <TableCell align="center">
                                      {row.documentTypeName}
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.detail}
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.updateDate}
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.fileURL !== null ? (
                                        <CheckCircleOutlineIcon
                                          style={{ color: "green" }}
                                          onClick={() =>
                                            handleOpen(row.fileURL)
                                          }
                                        />
                                      ) : (
                                        <ButtonBlue
                                          startIcon={<AddCircleOutlineIcon />}
                                          onClick={() => {
                                            history.push({
                                              pathname: `/smart-documents/create_pdf`,
                                              state: { row, userProfile },
                                            });
                                          }}
                                          sx={{
                                            backgroundColor: '#FFFFFF',
                                            color: '#46CBE2',
                                            border: '2px solid #46CBE2',
                                            borderRadius: '10px',
                                          }}
                                          style={{ marginRight: 10 }}
                                        >
                                          {`${t("CreateForm")}`}
                                        </ButtonBlue>
                                      )}
                                    </TableCell>

                                    <TableCell align="center">
                                      <IconButton
                                        style={{ color: "#bdbdbd" }}
                                        onClick={() =>
                                          handleClickOpen(row, "edit")
                                        }
                                        className={classes.iconButton}
                                      >
                                        <DriveFileRenameOutlineIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          backgroundColor: "#fce4ec",
                                          borderRadius: "50%",
                                          padding: "10px",
                                          color: "rgba(255, 23, 68, 0.9)",
                                        }}
                                        onClick={() =>
                                          handleClickOpen(row, "delete")
                                        }
                                      >
                                        <DeleteOutlineIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={5} align="center">
                                  {t("NoData")}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </StyledBox>
                  </Box>
                </Box>
                {deleteDialogType === "delete" && (
                  <Dialog
                    open={openDialogDelete}
                    onClose={handleCloseDeleteDialog}
                    className={classes.dialogBackground}
                    style={{ backgroundColor: 'rgba(100, 100, 100, 0.4)', }}
                  >
                    {loading ? (
                      <img src={LoadingIcon} style={{ height: 80, width: 80 }} />
                    ) : (
                      <>
                        <DialogTitle style={{ fontSize: '17px', fontWeight: 'bold' }}>
                          {t("ConfirmDeleteItem")}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText style={{ fontSize: '14px', color: 'black' }}>
                            {t("AreYouSure")}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            className={classes.cancelButton}
                            onClick={handleCloseDeleteDialog}
                          >
                            {t("Cancel")}
                          </Button>
                          <Button
                            variant="contained"
                            className={classes.confirmButton}
                            onClick={() => {
                              handleConfirmDelete('delete', selectedRow);
                            }}
                          >
                            {t("Confirm")}
                          </Button>
                        </DialogActions>
                      </>
                    )}
                  </Dialog>
                )}

                <Modal open={open} onClose={handleCloseImage}>
                  <StyleModalButton>
                    <StyledCloseButton
                      onClick={handleCloseImage}
                    >
                      X
                    </StyledCloseButton>
                    <iframe
                      src={`${pdf}#zoom=60`}
                      title="PDF Preview"
                      style={{ width: '100%', height: '100%' }}
                    />
                  </StyleModalButton>
                </Modal>
                <DialogType
                  open={openDialog}
                  mode={dialogType}
                  data={selectedRow}
                  selectedCompany={selectedCompany}
                  handleFileClose={handleFileClose}
                  handleCloseDialog={handleCloseDialog}
                />
              </CardStyle>
            </>
          ) : (
            ""
          )
        }
      </StyledRoot>
    </div>
  )
}

export default SmartDocumentPage;
