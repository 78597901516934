import EditableField from '../CofigBlot/EditableFieldBlot';
import SignatureBlot from '../CofigBlot/Signature';
import Divider from '../CofigBlot/DividerBlot';
import MentionBlot from '../CofigBlot/MentionBlot';
import InsertResizableBox from '../CofigBlot/InsertResizableBox'


const CustomBlot = {
  EditableField,
  SignatureBlot,
  Divider,
  MentionBlot,
  InsertResizableBox,
};

export default CustomBlot;

