import {
  ASSET_MANAGEMENT_FETCHING,
  ASSET_MANAGEMENT_FAILED,
  ASSET_MANAGEMENT_SUCCESS,
} from "./types";
import assetManagementService from "../services/assetManagement.service";

export const getAssetManagement = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: ASSET_MANAGEMENT_FETCHING,
    });
    const res = await assetManagementService.getAssetManagement(idCompany);
    if (res) {
      dispatch({
        type: ASSET_MANAGEMENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ASSET_MANAGEMENT_FAILED,
    });
  }
};

export const addAssetManagement = (formData) => async (dispatch) => {
  try {
    const res = await assetManagementService.addAssetManagement(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: ASSET_MANAGEMENT_FAILED,
    });
  }
};

export const updateAssetManagement = (formData) => async (dispatch) => {
  try {
    const res = await assetManagementService.updateAssetManagement(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: ASSET_MANAGEMENT_FAILED,
    });
  }
};

export const deleteAssetManagement = (id) => async (dispatch) => {
  try {
    const res = await assetManagementService.deleteAssetManagement(id);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: ASSET_MANAGEMENT_FAILED,
    });
  }
};

export const updateAssetManagementStatus = (formData) => async (dispatch) => {
  try {
    const res = await assetManagementService.updateAssetManagementStatus(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: ASSET_MANAGEMENT_FAILED,
    });
  }
};

export const getAssetManagementByIdEmployee = () => async (dispatch) => {
  try {
    dispatch({
      type: ASSET_MANAGEMENT_FETCHING,
    });
    const res = await assetManagementService.getAssetManagementByIdEmployee();
    if (res) {
      dispatch({
        type: ASSET_MANAGEMENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ASSET_MANAGEMENT_FAILED,
    });
  }
};
