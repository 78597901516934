import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import XLSX from "xlsx";
import AlertResponse from "../../shared/general/AlertResponse";
import DrawerCustom from "../../shared/general/Drawer";
import { getEmailReportById } from "../../../../actions/email";
import { getAttendanceEmployees } from "../../../../actions/report";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import DialogSelectDay from "./component/dialogSelectDay";

const Dailyplanner = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: email } = useSelector((state) => state.email);
  const { result: attendanceOnce } = useSelector(
    (state) => state.attendanceOnce
  );
  const { open, handleClose } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [data, setData] = useState({
    inputSearch: new Date(),
    autoEmail: null,
    selectedCompany: null,
    ReportName: "daily",
    idEmployees: null,
  });

  useEffect(() => {
    if (email) setData({ ...data, autoEmail: email.day });
  }, [email]);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  useEffect(() => {
    dispatch(getEmailReportById(data.idEmployees, "daily"));
    dispatch(
      getAttendanceEmployees(
        data.selectedCompany,
        dayjs(data.inputSearch)
      )
    );
  }, [data.inputSearch, data.selectedCompany, data.value]);

  useEffect(() => {
    if(userProfile) {
      setData({ ...data, idEmployees: userProfile.idEmployees});
    }
  }, []);

  const handleSubmit = () => {
    if (attendanceOnce.length > 0) {
      const elementList = [];
      for (let index = 0; index < attendanceOnce.length; index++) {
        const element = {
          "#": index + 1,
          รหัสพนักงาน: attendanceOnce[index].employeeID,
          ชื่อ: attendanceOnce[index].firstname_TH,
          สกุล: attendanceOnce[index].lastname_TH,
          ตำแหน่งงาน: attendanceOnce[index].positionName,
          วันที่เวลา: attendanceOnce[index].attendanceDateTimeText,
          สถานที่ลงเวลา: attendanceOnce[index].gpsLocationsName
            ? attendanceOnce[index].gpsLocationsName
            : attendanceOnce[index].gpsAddress,
          ประเภทลงเวลา: attendanceOnce[index].isCheckIn
            ? "ลงเวลาเข้างาน"
            : "ลงเวลาออกงาน",
        };
        elementList.push(element);
      }
      const workSheet = XLSX.utils.json_to_sheet(elementList);
      const workBook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(workBook, workSheet, "รายงานการลงเวลา");
      XLSX.writeFile(workBook, "รายงานการลงเวลา.xlsx");

      handleChangeAlertType("success");
      handleOpenAlert(true);
      handleClose();
    } else {
      handleChangeAlertType("ไม่พบข้อมูล");
      handleOpenAlert(true);
    }
  };

  return (
    <DrawerCustom
      title={`${t("TimeStampReport")}`}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <DialogSelectDay
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        setData={setData}
        data={data}
        handleChangeAlertType={handleChangeAlertType}
        handleOpenAlert={handleOpenAlert}
        select={true}
        excel={attendanceOnce}
      />
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </DrawerCustom>
  );
};

export default Dailyplanner;
