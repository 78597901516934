import {
  PERSONAL_TAX_FETCHING,
  PERSONAL_TAX_FAILED,
  PERSONAL_TAX_SUCCESS,
  TAX_SUMMARY_FETCHING,
  TAX_SUMMARY_FAILED,
  TAX_SUMMARY_SUCCESS,
} from "./types";

import PersonalTaxService from "../services/personalTax.service";

export const getPersonalTaxByMonth =
  (date, idEmployees) => async (dispatch) => {
    try {
      dispatch({
        type: PERSONAL_TAX_FETCHING,
      });

      const res = await PersonalTaxService.getPersonalTaxByMonth(
        date,
        idEmployees
      );

      if (res) {
        dispatch({
          type: PERSONAL_TAX_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: PERSONAL_TAX_FAILED,
        payload: null,
      });
      console.log(err);
    }
  };

export const getTaxSummary = (idCompany, year) => async (dispatch) => {
  try {
    dispatch({
      type: TAX_SUMMARY_FETCHING,
    });

    const res = await PersonalTaxService.getTaxSummary(idCompany, year);

    if (res) {
      dispatch({
        type: TAX_SUMMARY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: TAX_SUMMARY_FAILED,
      payload: null,
    });
    console.log(err);
  }
};
