import React from "react";
import { Controller } from 'react-hook-form';
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";

import TextFieldNumber from "./TextFieldNumber";
import TextFieldPersonalID from "./TextFieldPersonalID";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTypography-body1": {
      color: "#16243D",
    },
    "& .MuiTypography-body2": {
      fontSize: "1rem",
      marginLeft: 32,
    },
    "& .MuiGrid-root": {
      marginBottom: 8,
    },
  },
  inputAdornment: {
    "& .MuiTypography-body1": {
      color: "rgba(0, 0, 0, 0.38)",
    },
  },
  checkBox: {
    marginLeft: 32,
  },
}));

function Form3(props) {
  const { t, i18n } = useTranslation();
  const { control, setValue, watch, errors, clearErrors, isDisabled } = props;
  const classes = useStyles();
  const { result: UserProfile } = useSelector(state => state.userProfile);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body1">
            10) {`${t("HealthInsurancePremiumParents")}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Box className={classes.checkBox}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Controller 
                    name="isHealthInsurance_empFather"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        disabled={isDisabled}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked)
                          if(!e.target.checked){
                            setValue("healthInsurance_empFather", 0, { shouldDirty: true });
                          }
                          if(errors && errors.healthInsurance_empFather){
                            clearErrors("healthInsurance_empFather");
                          }
                        }}
                      />
                    )}
                  />
                }
                label={`${t("FatherWithIncome")}`}
              />
            </FormGroup>
          </Box>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber
            name="healthInsurance_empFather"
            control={control}
            min={0}
            max={(() => {
              let maxlimit = 15000 - (
                watch("healthInsurance_empMother") ? watch("healthInsurance_empMother") : 0
              ) - (
                watch("healthInsurance_mateFather") ? watch("healthInsurance_mateFather") : 0
              ) - (
                watch("healthInsurance_mateMother") ? watch("healthInsurance_mateMother") : 0
              );
              
              if(maxlimit < 0){
                return 0;
              }else{
                return maxlimit;
              }
            })()}
            onHandleChange={() => {
              let maxlimit = 15000 - (
                watch("healthInsurance_empMother") ? watch("healthInsurance_empMother") : 0
              ) - (
                watch("healthInsurance_mateFather") ? watch("healthInsurance_mateFather") : 0
              ) - (
                watch("healthInsurance_mateMother") ? watch("healthInsurance_mateMother") : 0
              );
              
              if(maxlimit <= 0){
                setValue("healthInsurance_empFather", 0);
              }
            }}
            endAdornment={true}
            endAdornmentLabel={`${t("Baht")} / ${t("Year")}`}
            disabled={isDisabled || !watch("isHealthInsurance_empFather")}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Box className={classes.checkBox}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Controller 
                    name="isHealthInsurance_empMother"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        disabled={isDisabled}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked)
                          if(!e.target.checked){
                            setValue("healthInsurance_empMother", 0, { shouldDirty: true });
                          }
                          if(errors && errors.healthInsurance_empMother){
                            clearErrors("healthInsurance_empMother");
                          }
                        }}
                      />
                    )}
                  />
                }
                label={`${t("MotherWithIncome")}`}
              />
            </FormGroup>
          </Box>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber
            name="healthInsurance_empMother"
            control={control}
            min={0}
            max={(() => {
              let maxlimit = 15000 - (
                watch("healthInsurance_empFather") ? watch("healthInsurance_empFather") : 0
              ) - (
                watch("healthInsurance_mateFather") ? watch("healthInsurance_mateFather") : 0
              ) - (
                watch("healthInsurance_mateMother") ? watch("healthInsurance_mateMother") : 0
              );
              
              if(maxlimit < 0){
                return 0;
              }else{
                return maxlimit;
              }
            })()}
            onHandleChange={() => {
              let maxlimit = 15000 - (
                watch("healthInsurance_empFather") ? watch("healthInsurance_empFather") : 0
              ) - (
                watch("healthInsurance_mateFather") ? watch("healthInsurance_mateFather") : 0
              ) - (
                watch("healthInsurance_mateMother") ? watch("healthInsurance_mateMother") : 0
              );
              
              if(maxlimit <= 0){
                setValue("healthInsurance_empMother", 0);
              }
            }}
            endAdornment={true}
            endAdornmentLabel={`${t("Baht")} / ${t("Year")}`}
            disabled={isDisabled || !watch("isHealthInsurance_empMother")}
          />
        </Grid>
      </Grid>
      {watch("maritalStatus") === "แต่งงาน" && watch("isMateIncome") === 0 && (
        <>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item xs={12} md={4} lg={4}>
              <Box className={classes.checkBox}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Controller 
                        name="isHealthInsurance_mateFather"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            disabled={isDisabled}
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.checked)
                              if(!e.target.checked){
                                setValue("healthInsurance_mateFather", 0, { shouldDirty: true });
                              }
                              if(errors && errors.healthInsurance_mateFather){
                                clearErrors("healthInsurance_mateFather");
                              }
                            }}
                          />
                        )}
                      />
                    }
                    label={`${t("SpouseFather")}`}
                  />
                </FormGroup>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <TextFieldNumber
                name="healthInsurance_mateFather"
                control={control}
                min={0}
                max={(() => {
                  let maxlimit = 15000 - (
                    watch("healthInsurance_empFather") ? watch("healthInsurance_empFather") : 0
                  ) - (
                    watch("healthInsurance_empMother") ? watch("healthInsurance_empMother") : 0
                  ) - (
                    watch("healthInsurance_mateMother") ? watch("healthInsurance_mateMother") : 0
                  );

                  if(maxlimit < 0){
                    return 0;
                  }else{
                    return maxlimit;
                  }
                })()}
                onHandleChange={() => {
                  let maxlimit = 15000 - (
                    watch("healthInsurance_empFather") ? watch("healthInsurance_empFather") : 0
                  ) - (
                    watch("healthInsurance_empMother") ? watch("healthInsurance_empMother") : 0
                  ) - (
                    watch("healthInsurance_mateMother") ? watch("healthInsurance_mateMother") : 0
                  );
                  
                  if(maxlimit <= 0){
                    setValue("healthInsurance_mateFather", 0);
                  }
                }}
                endAdornment={true}
                endAdornmentLabel={`${t("Baht")} / ${t("Year")}`}
                disabled={isDisabled || !watch("isHealthInsurance_mateFather")}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item xs={12} md={4} lg={4}>
              <Box className={classes.checkBox}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Controller 
                        name="isHealthInsurance_mateMother"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            disabled={isDisabled}
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.checked)
                              if(!e.target.checked){
                                setValue("healthInsurance_mateMother", 0, { shouldDirty: true });
                              }
                              if(errors && errors.healthInsurance_mateMother){
                                clearErrors("healthInsurance_mateMother");
                              }
                            }}
                          />
                        )}
                      />
                    }
                    label={`${t("SpouseMother")}`}
                  />
                </FormGroup>
              </Box>
              <Typography 
                variant="body2"
                style={{ marginLeft: 32, fontSize: "0.81rem", marginTop: 8, color:"#d32f2f" }}
              >* {`${t("MaxTaxDeduction15KTHB")}`}</Typography>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <TextFieldNumber
                name="healthInsurance_mateMother"
                control={control}
                min={0}
                max={(() => {
                  let maxlimit = 15000 - (
                    watch("healthInsurance_empFather") ? watch("healthInsurance_empFather") : 0
                  ) - (
                    watch("healthInsurance_empMother") ? watch("healthInsurance_empMother") : 0
                  ) - (
                    watch("healthInsurance_mateFather") ? watch("healthInsurance_mateFather") : 0
                  );

                  if(maxlimit < 0){
                    return 0;
                  }else{
                    return maxlimit;
                  }
                })()}
                onHandleChange={() => {
                  let maxlimit = 15000 - (
                    watch("healthInsurance_empFather") ? watch("healthInsurance_empFather") : 0
                  ) - (
                    watch("healthInsurance_empMother") ? watch("healthInsurance_empMother") : 0
                  ) - (
                    watch("healthInsurance_mateFather") ? watch("healthInsurance_mateFather") : 0
                  );
                  
                  if(maxlimit <= 0){
                    setValue("healthInsurance_mateMother", 0);
                  }
                }}
                endAdornment={true}
                endAdornmentLabel={`${t("Baht")} / ${t("Year")}`}
                disabled={isDisabled || !watch("isHealthInsurance_mateMother")}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body1">
            11) {`${t("DisabledDependentCare")}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2} alignItems="center">
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body2">{`${t("Quantity")}`}</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber 
            name="handicappedPerson"
            min={0}
            max={5}
            endAdornment={true}
            endAdornmentLabel={`${t("Person")}`}
            control={control}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body1">12) {`${t("AnnuityInsurancePremium")}`}</Typography>
          <Typography 
            variant="body2"
            style={{ marginLeft: 16, fontSize: "0.81rem", marginTop: 8, color:"#d32f2f" }}
          >* {`${t("MaxTaxDeduction15Percent200KTHB")}`}</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber 
            name="lifeInsurance"
            min={0}
            max={200000}
            endAdornment={true}
            endAdornmentLabel={`${t("Baht")} / ${t("Year")}`}
            control={control}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body1">13) {`${t("HomeLoanInterest")}`}</Typography>
          <Typography 
            variant="body2"
            style={{ marginLeft: 16, fontSize: "0.81rem", marginTop: 8, color:"#d32f2f" }}
          >* {`${t("MaxTaxDeductionLimit100KTHB")}`}</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber 
            name="loanInterestHome"
            min={0}
            max={100000}
            endAdornment={true}
            endAdornmentLabel={`${t("Baht")} / ${t("Year")}`}
            control={control}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      {UserProfile.gender === "Female" &&
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1">14) {`${t("MaternityPaternityExpenses")}`}</Typography>
            <Typography 
              variant="body2"
              style={{ marginLeft: 16, fontSize: "0.81rem", marginTop: 8, color:"#d32f2f" }}
            >* {`${t("MaxTaxDeductionPerPregnancy60KTHB")}`}</Typography>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextFieldNumber 
              name="orderOfPregnancy"
              min={0}
              max={2}
              control={control}
              label={`${t("PregnancyOrder")}`}
              onHandleChange={() => {
                if((watch("orderOfPregnancy") === 1) && (watch("maternityExpenses") > 60000)){
                  setValue("maternityExpenses", 60000);
                }
              }}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextFieldNumber 
              name="maternityExpenses"
              min={0}
              max={watch("orderOfPregnancy") === 1 ? 60000 : 120000}
              endAdornment={true}
              endAdornmentLabel={`${t("Baht")} / ${t("Year")}`}
              control={control}
              disabled={isDisabled}
            />
          </Grid>
        </Grid>
      }
    </div>
  );
}

export default Form3;
