import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import AlertResponse from "../../../shared/general/AlertResponse";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import {
  Addterminatelist,
  EditTerminatelist,
} from "../../../../../actions/offBoarding";
import Swal from "sweetalert2";

// Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const DialogName = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );

  const { open, mode, handleClose, data } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const [formData, setFormData] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (open) {
      if (mode === "Edit") {
        setFormData({
          name: data.name,
          idTerminateList: data.idTerminateList,
          company: affiliateOrganizationList.find(
            (company) => company.idCompany === data.idCompany
          ),
        });
      } else {
        setFormData({
          name: "",
          company: null,
        });
      }
    }
  }, [open, mode, data, affiliateOrganizationList]);

  const handleSubmit = async () => {
    if (!formData.name || !formData.company) {
      handleOpenAlert(true);
      handleChangeAlertType(`${t("PleaseFillInRequiredInformations")}`);
    } else {
      if (mode === "AddList") {
        const newData = {
          name: formData.name,
          idCompany: formData.company.idCompany,
        };
        dispatch(Addterminatelist(newData)).then(() => {
          handleClose();
          Swal.fire({
            title: t("TaskAdded"),
            text: t("TheTaskHasBeenAddedSuccessfully"),
            icon: "success",
            confirmButtonColor: "#28a745",
            confirmButtonText: t("OK"),
          });
        });
      } else {
        const updatedData = {
          name: formData.name,
          idTerminateList: formData.idTerminateList,
          idCompany: formData.company.idCompany,
        };
        dispatch(EditTerminatelist(updatedData)).then(() => {
          handleClose();
          Swal.fire({
            title: t("TaskEdited"),
            text: t("TheTaskHasBeenEditedSuccessfully"),
            icon: "success",
            confirmButtonColor: "#28a745",
            confirmButtonText: t("OK"),
          });
        });
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        aria-labelledby="dialog-name-title"
        aria-describedby="dialog-name-description"
      >
        <DialogTitle id="dialog-name-title">
          {mode === "Edit" ? t("EditItem") : t("AddItem")}
        </DialogTitle>
        <DialogContent dividers>
          {formData && (
            <StyledRoot>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className="GridTopicInput">
                  {t("SelectCompany")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    options={affiliateOrganizationList || []}
                    getOptionLabel={(option) => option.companyName}
                    value={formData.company || null}
                    onChange={(event, newValue) =>
                      setFormData({ ...formData, company: newValue })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        placeholder={t("SelectCompany")}
                      />
                    )}
                    noOptionsText={t("NoData")}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: 16 }}>
                <Grid item xs={12} sm={6} className="GridTopicInput">
                  {t("ItemName")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    margin="dense"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </StyledRoot>
          )}
        </DialogContent>
        <DialogActions>
          <ButtonBlue
            className="cancel"
            onClick={handleClose}
            sx={{ minWidth: 120, height: 40 }}
          >
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue
            variant="contained"
            onClick={handleSubmit}
            autoFocus
            sx={{ minWidth: 120, height: 40 }}
          >
            {t("SaveData")}
          </ButtonBlue>
        </DialogActions>
      </Dialog>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </>
  );
};

export default DialogName;
