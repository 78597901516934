import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Grid, Typography } from "@mui/material";
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next";
import utils from "../../../../../../../utils";
import dayjs from "dayjs";
import { getUserFirstName ,getUserLastName} from "../../../../../../../utils/userData";

const useStyles = makeStyles(() => ({
  bigAvatar: {
    width: 170,
    height: 170,
    borderRadius: "100%",
    // marginBottom: 20,
    // marginTop: 20,
  },
  text: {
    fontWeight: 600,
    marginBottom: "20px"
  },
  text2: {
    fontSize: 20,
    fontWeight: 600,
    marginTop: 10,
  },
  headText: {
    fontSize: 20,
    marginTop: 10,
  },
}));

const LastPerson = (props) => {
  const classes = useStyles();
  const data = props.latestData;
  const { t, i18n } = useTranslation();

  const calculatorESY = () => {
    let Serv = utils.getAgeNumber(
      dayjs(data.hiringDate).format("DD/MM/YYYY")
    );

    let monthAge = (Serv.months + data.OESY_M) % 12;
    let yearAge = Math.floor((Serv.months + data.OESY_M) / 12);

    return `${Serv.years + data.OESY_Y + yearAge} ${t("Year")} ${monthAge} ${t("Month")}`;
  };

  return (
    <Grid item xs={12}>
      <Grid
        container
        justifyContent="space-around"
        alignItems={"center"}
        spacing={2}
        sx={{ p: 4 }}
      >
        <Grid item>
          <center>
            <Avatar
              alt="Remy Sharp"
              src={data.imageProfile}
              className={classes.bigAvatar}
            />
          </center>
        </Grid>

        <Grid item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography fontSize={20} fontWeight={"bold"}>
                {data && getUserFirstName(data)} &nbsp;&nbsp; {data && getUserLastName(data)}
              </Typography>
            </Grid>

            <Grid item>
              <Typography fontSize={18}>
                {data && data.email}
              </Typography>
            </Grid>

            <Grid item container direction="row">
              <Typography fontSize={18}>
                {t("ESY")} :&nbsp;&nbsp;
              </Typography>
              <Typography fontSize={18}>
                {data && data.hiringDate ? calculatorESY() : "-"}
              </Typography>
            </Grid>

            <Grid item container direction="row">
              <Typography fontSize={18}>
                {t("Age")} :&nbsp;&nbsp;
              </Typography>
              <Typography fontSize={18}>
                {data && data.birthday
                  ? `${utils.getAgeMonthYear(
                    dayjs(data.birthday).format("DD/MM/YYYY"),
                    i18n.resolvedLanguage
                  )}`
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LastPerson;
