import {
    WORKTYPES_FETCHING,
    WORKTYPES_FAILED,
    WORKTYPES_SUCCESS
} from "../actions/types";

const initialState = {
    result: null,
    isFetching: false,
    isError: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case WORKTYPES_FETCHING:
        return { ...state, result: null, isFetching: true, isError: false };
      case WORKTYPES_FAILED:
        return { ...state, result: null, isFetching: false, isError: true };
      case WORKTYPES_SUCCESS:
        return { ...state, result: payload, isFetching: false, isError: false };
      default:
        return state;
    }
}
