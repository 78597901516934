import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Box, Dialog, Divider, Grid, Stack, Typography } from "@mui/material";

import SyncProblemIcon from "@mui/icons-material/SyncProblem";

import DialogEditSwitchShift from "./dialogEditSwitchShift";
import DialogSwitchShift from "./dialogSwitchShift";
import EventSwitchShift from "./eventSwitchShift";
import EmployeeShiftList from "./employeeShiftList";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import { Container } from "@mui/material";
import {
  getAllEmployeesShift,
  getEmployeeShiftHistory,
} from "../../../../../actions/employee";
import { getShiftGroupById } from "../../../../../actions/shift";
import AlertResponse from "../../../shared/general/AlertResponse";
import CardStyle from "../../../shared/general/Card";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: "20px",
  "& .head": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 16,
  },
});

export default function ListEmployeePage(props) {
  const { shiftGroup, handleClickEditTimeWorking, selectedCompany } = props;
  const { idShiftGroup } = useParams();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: shiftGroupList } = useSelector((state) => state.shiftGroup);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [pattern, setPattern] = React.useState(false);
  const [openSwitchShift, setOpenSwitchShift] = React.useState(false);
  const [openErrorSwitch, setOpenErrorSwitch] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [editHistory, setEditHistory] = React.useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  useEffect(() => {
    dispatch(getAllEmployeesShift(shiftGroup.idShiftGroup));
    dispatch(getShiftGroupById(shiftGroup.idShiftGroup));
  }, []);

  React.useEffect(() => {
    dispatch(getEmployeeShiftHistory(shiftGroup.idShiftGroup));
  }, []);

  const handleOpenSwitchShiftPattern = () => {
    setOpenSwitchShift(true);
    setPattern(true);
  };

  const handleCloseSwitchShift = () => {
    setOpenSwitchShift(false);
  };

  const handleOpenSwitchShift = () => {
    setOpenSwitchShift(true);
    setPattern(false);
  };

  const handleClickEditButton = (history) => {
    setEditHistory(history);
    setOpenEditDialog(true);
  };

  const handleGetEmployeeShiftHistory = (idShiftGroup) => {
    dispatch(getEmployeeShiftHistory(idShiftGroup));
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <StyledRoot>
      <Container maxWidth="lg" style={{ padding: 0 }}>
        {!userProfile.readOnly && (
          <div className="head">
            <div style={{ marginRight: 8 }}>
              <ButtonBlue variant="outlined" onClick={handleClickEditTimeWorking}>
                {t("EditWorkingTime")}
              </ButtonBlue>
            </div>
            <div>
              <ButtonBlue
                variant={"contained"}
                startIcon={<SyncProblemIcon />}
                onClick={handleOpenSwitchShiftPattern}
              >
                {t("MoveShiftPlan")}
              </ButtonBlue>
            </div>
          </div>
        )}

        {shiftGroup && <CardStyle style={{marginTop: "24px", marginBottom: "24px"}}>
          <Box padding="16px">
            <Typography fontSize="20px" marginBottom="24px">{t("ShiftGroupList")}</Typography>
            <Stack
              useFlexGap
              direction="row"
              spacing="16px"
              divider={<Divider orientation="vertical" flexItem />}
              flexWrap="wrap"
            >
              {shiftGroup.shift.map((item, index) => (
                <Box key={index}>
                  <Typography fontSize="18px" fontWeight="500">
                    {item.shiftName}
                  </Typography>
                  <Typography fontSize="12px" color="text.secondary">
                    ({item.shiftCode})
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Box>
        </CardStyle>}

        <EmployeeShiftList
          handleOpenSwitchShift={handleOpenSwitchShift}
          idShiftGroup={idShiftGroup}
        />
        {/* {shiftGroupList && (
          <EventSwitchShift
            idShiftGroup={shiftGroup.idShiftGroup}
            handleClickEditButton={handleClickEditButton}
            handleGetEmployeeShiftHistory={handleGetEmployeeShiftHistory}
          />
        )} */}

        {openSwitchShift && shiftGroupList && (
          <DialogSwitchShift
            open={openSwitchShift}
            handleClose={handleCloseSwitchShift}
            pattern={pattern}
            setOpenErrorSwitch={setOpenErrorSwitch}
            handleOpenAlert={handleOpenAlert}
            handleChangeAlertType={handleChangeAlertType}
            idShiftGroup={shiftGroup.idShiftGroup}
            handleGetEmployeeShiftHistory={handleGetEmployeeShiftHistory}
            selectedCompany={selectedCompany}
          />
        )}

        <AlertResponse
          open={openAlert}
          handleClose={handleCloseAlert}
          alertType={alertType}
        />
      </Container>
    </StyledRoot>
  );
}
