import { PROJECT_FETCHING, PROJECT_FAILED, PROJECT_SUCCESS } from "./types";

import ProjectService from "../services/project.service";

export const getAllProject = () => async (dispatch) => {
  try {
    const res = await ProjectService.getAllProject();
    if (res) {
      dispatch({
        type: PROJECT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PROJECT_FAILED,
    });
    console.log(err);
  }
};

export const getEmployeeProject = async (idEmployeeProject) => {
  try {
    const res = await ProjectService.getEmployeeProject(idEmployeeProject);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
}

export const getProjectById = async (idEmployeeProject) => {
  try {
    const res = await ProjectService.getProjectById(idEmployeeProject);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
}

export const getManagerProjectToApprove = async (start, end) => {
  try {
    const res = await ProjectService.getManagerProjectToApprove(start, end);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
}


export const updateStatusProject = async (query) => {
  try {
    const res = await ProjectService.updateStatusProject(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
}

export const getProjectByEmp = async () => {
  try {
    const res = await ProjectService.getProjectByEmp();
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
}

export const addProject = async (query) => {
  try {
    const res = await ProjectService.addProject(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
}

export const deleteProject = async (query) => {
  try {
    const res = await ProjectService.deleteProject(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
}

export const updateProject = async (id, query) => {
  try {
    const res = await ProjectService.updateProject(id, query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
}
