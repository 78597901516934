import React from "react";
import { Box, Container, Grid, Typography, styled, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  backgroundColor: "#f5f5f5 !important",
  paddingBottom: 50,
});

const StyledClickablePaper = styled(Paper)({
  position: "relative",
  width: "auto",
  height: "30vh",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1.1)",
  },
});

const ReadOnlyBadge = styled("div")({
  rotate: "-45deg",
  position: "absolute",
  top: 19,
  left: -12,
  backgroundColor: "rgba(255,0,0,0.9)",
  color: "white",
  padding: "2px 10px",
  borderTopLeftRadius: "20px",
  borderBottomRightRadius: "20px",
  fontSize: "12px",
});

const UserManagerOKRs = () => {
  const { t, i18n } = useTranslation();

  const menuItems = [
    {
      label: t("OKRManagement"),
      path: "/okrs/management/user-manager",
      icon: `${process.env.PUBLIC_URL}/assets/okrs/admin/organizational-goals.png`,
      isReadOnly: false,
    },
    // {
    //   label: t("TeamGoals"),
    //   path: "/okrs/team-planning",
    //   icon: `${process.env.PUBLIC_URL}/assets/okrs/user/personal-goal.png`,
    // },
    // {
    //   label: t("PersonalGoals"),
    //   path: "/okrs/individual-planning",
    //   icon: `${process.env.PUBLIC_URL}/assets/okrs/user/personal-goal.png`,
    // },
    // {
    //   label: `${t("UpdateTeamGoals")}`,
    //   path: "/okrs/update-progress/team",
    //   icon: `${process.env.PUBLIC_URL}/assets/okrs/manager/update-team-goals.png`,
    // },
    // {
    //   label: t("UpdateIndividualGoals"),
    //   path: "/okrs/update-progress/individual",
    //   icon: `${process.env.PUBLIC_URL}/assets/okrs/user/update-individual-goal.png`,
    // },
    // {
    //   label: `${t("ProgressStatus")}`,
    //   path: "/okrs/team-status",
    //   icon: `${process.env.PUBLIC_URL}/assets/okrs/manager/progress-status.png`,
    // },
    // {
    //   label: `${t("TeamObjectivesOverview")}`,
    //   path: "/okrs/dashboard/team",
    //   icon: `${process.env.PUBLIC_URL}/assets/okrs/manager/team-objectives-overview.png`,
    // },
    {
      label: t("OverallPerspectiveIndividualGoals"),
      path: "/okrs/dashboard/individual",
      icon: `${process.env.PUBLIC_URL}/assets/okrs/user/overall-perspective-individual-goal.png`,
    },

    // {
    //   label: `${t("ApprovalPlanList")}`,
    //   path: "/okrs/approval/individual",
    //   icon: `${process.env.PUBLIC_URL}/assets/okrs/manager/list-of-approved-outcomes.png`,
    // },
    // {
    //   label: `${t("ListApprovedOutcomes")}`,
    //   path: "/okrs/evaluation/individual",
    //   icon: `${process.env.PUBLIC_URL}/assets/okrs/manager/individual-goals-evaluation.png`,
    // },
    // {
    //   label: t("IndividualGoalsEvaluation"),
    //   path: "/okrs/individual-evaluation",
    //   icon: `${process.env.PUBLIC_URL}/assets/okrs/user/individual-goals-evaluation.png`,
    // },
  ];

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          {menuItems.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <Link to={item.path} style={{ textDecoration: "none" }}>
                <StyledClickablePaper>
                  {item.isReadOnly && <ReadOnlyBadge>Read Only</ReadOnlyBadge>}
                  <img
                    src={item.icon}
                    alt="icon"
                    style={{
                      width: "50%",
                      height: "auto",
                      marginBottom: "10px",
                    }}
                  />
                  <Typography variant="body1" align="center">
                    {item.label}
                  </Typography>
                </StyledClickablePaper>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </StyledRoot>
  );
};

export default UserManagerOKRs;
