import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyPerformanceYear } from "../../../../../actions/estimatePerformance";
import CardStyle from "../../../shared/general/Card";
import DialogPublish from "./DialogPublish";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../../shared/general/ButtonBlue";

const StyledTableContainer = styled(TableContainer)({
  position: "relative",
  overflowX: "auto",
  "& table": {
    borderCollapse: "separate",
  },
  "& .MuiTableBody-root": {
    "& .MuiTableCell-root": {
      padding: "8px 16px",
    },
  },
});

const PerformanceYear = ({ idCompany }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: performanceYear } = useSelector(
    (state) => state.performanceYear
  );

  const [open, setOpen] = useState(false);
  const [selectPerformanceYear, setSelectPerformanceYear] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setSelectPerformanceYear(null);
  };

  const handleOpen = (value) => {
    setOpen(true);
    if (value) {
      setSelectPerformanceYear(value);
    }
  };

  useEffect(() => {
    dispatch(getCompanyPerformanceYear({ idCompany }));
  }, [idCompany]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "16px" }}>
        <Typography fontSize={20} fontWeight={500}>
          {"รอบการประเมิน"}
        </Typography>
        <ButtonBlue
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpen(true)}
        >
          {"เพิ่มรอบการประเมิน"}
        </ButtonBlue>
      </div>
      <CardStyle style={{ padding: 16 }}>
        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Year")}</TableCell>
                <TableCell>{t("StartDate")}</TableCell>
                <TableCell>{t("EndDate")}</TableCell>
                <TableCell>{t("Edit")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {performanceYear &&
                performanceYear.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.year}</TableCell>
                    <TableCell>
                      {dayjs(item.startDate).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>
                      {dayjs(item.endDate).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpen(item)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              {(!performanceYear || performanceYear.length === 0) && (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    <Typography fontSize="20px" color="text.secondary">
                      {t("NoResult")}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </CardStyle>
      {open && (
        <DialogPublish
          open={open}
          handleClose={handleClose}
          idCompany={idCompany}
          selectPerformanceYear={selectPerformanceYear}
        />
      )}
    </>
  );
};

export default PerformanceYear;
