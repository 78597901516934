import {
  PROJECTASSIGN_FETCHING,
  PROJECTASSIGN_FAILED,
  PROJECTASSIGN_SUCCESS,
} from "./types";

import ProjectAssignService from "../services/projectAssign.service";

export const getAllProjectAssign = () => async (dispatch) => {
  try {
    console.log("getAllProjectAssign")
    const res = await ProjectAssignService.getAllProjectAssign();
    if (res) {
      dispatch({
        type: PROJECTASSIGN_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PROJECTASSIGN_FAILED,
    });
    console.log(err);
  }
};
