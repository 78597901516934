import { httpClient } from "./httpClient";

const getAllRightsEmployeeFamily = () => {
  return httpClient.get(`/rightsWelfare/getAllRightsEmployeeFamily`);
};

const getRemaingRightLimit = (data) => {
  return httpClient.get(
    `/rightsWelfare/getRemaingRightLimit?idExpenseWelfare=${data.idExpenseWelfare}&idWelfareBilling=${data.idWelfareBilling}&idRightsManage=${data.idRightsManage}`
  );
};

export default {
  getAllRightsEmployeeFamily,
  getRemaingRightLimit,
};
