import { useEffect, useRef, useState } from 'react';
import CustomBlot from '../Custom/CustomBlot';
import { Quill } from 'react-quill';
import Swal from 'sweetalert2';

Quill.register(CustomBlot.SignatureBlot);

export const useSignatureManager = (quillRef, signatureDataList, setSignatureDataList) => {
  
  const [isLocked, setIsLocked] = useState(CustomBlot.SignatureBlot.isLockedMap);  
  const [signatureType, setSignatureType] = useState('');
  const [globalEventType, setGlobalEventType] = useState('add');  
  
  let signatureBlot = null; 
  const domNodeRef = useRef();  

  signatureBlot = new CustomBlot.SignatureBlot(setSignatureDataList, domNodeRef.current); 

  useEffect(() => {
    if (signatureType  && quillRef && quillRef.current && signatureBlot) {
        signatureBlot.updateParameter(
          signatureType,
        );
      }
  }, [signatureType, isLocked, quillRef, signatureBlot]);

  useEffect(() => {
    const handleLockChange = (updatedMap) => {
      setIsLocked({ ...updatedMap });
    };

    CustomBlot.SignatureBlot.emitter.on('lockChange', handleLockChange);

    return () => {
      CustomBlot.SignatureBlot.emitter.off('lockChange', handleLockChange);
    };
  }, [])


  useEffect(() => {
    if (CustomBlot && CustomBlot.SignatureBlot) {
      const handleSignatureDataUpdate = (eventData) => {
        const { updatedList, type } = eventData;        

        setGlobalEventType(type);
        setSignatureDataList([...updatedList]);  
        setGlobalEventType('add');
      };
  
      CustomBlot.SignatureBlot.emitter.on('signatureDataListUpdated', handleSignatureDataUpdate);
  
      return () => {
        CustomBlot.SignatureBlot.emitter.off('signatureDataListUpdated', handleSignatureDataUpdate);
      };
    }
  }, []);

  useEffect(() => {
    if (CustomBlot && CustomBlot.SignatureBlot) {
      CustomBlot.SignatureBlot.signatureDataList = signatureDataList;
    }
  }, [signatureDataList]);

  const handleInsertSignature = (event) => {
    const quill = quillRef.current ? quillRef.current.getEditor() : null;
    if (!quill) {
      console.error('Quill reference is not set');
      return;
    }

    const type = event.target.value;
    if (!type || type === '') {
      console.error('No signature type selected');
      return;
    }

    const existingSignature = signatureDataList.find((data) => data.SignatureType === type);
    if (existingSignature) {
      Swal.fire({
        icon: 'error',
        title: 'มีลายเซ็นประเภทนี้แล้ว!',
        text: `ไม่สามารถเลือก "${type}" ได้ เนื่องจากมีอยู่แล้ว!`,
      }).then(() => {
        setSignatureType('');
      });
      return;
    }

     const range = quill.getSelection();
    if (quill && range) {
      setSignatureType(type);  
      setTimeout(() => {
        domNodeRef.current = document.createElement('div'); 
        quill.insertEmbed(range.index, 'signature', {
          id: type,
          value: 'ลายเซ็น',
        });
        quill.setSelection(range.index, 'silent');
      }, 0);
    }
    
  };

  useEffect(() => {
    if (isLocked && signatureBlot && signatureBlot.statics) {

      const newSignatureData =  CustomBlot.SignatureBlot.positionSignature;
      if(newSignatureData.status === 'add') {
        setGlobalEventType('add');
      }

      let existingSignatureIndex;

      if (!Array.isArray(signatureDataList)) {
        signatureDataList = signatureDataList ? Object.values(signatureDataList) : [];
        existingSignatureIndex =  signatureDataList.findIndex(
        data => data.SignatureType === newSignatureData.SignatureType
        );
      } else {
        existingSignatureIndex = signatureDataList.findIndex(
          data => data.SignatureType === newSignatureData.SignatureType
        );
      }

      if (existingSignatureIndex !== -1) {
        const existingSignature = signatureDataList[existingSignatureIndex];

        const positionChanged =
          existingSignature.x !== newSignatureData.x ||
          existingSignature.y !== newSignatureData.y ||
          existingSignature.height !== newSignatureData.height ||
          existingSignature.width !== newSignatureData.width;
  
        if (positionChanged) {
          setSignatureDataList(prevList =>
            prevList.map((data, index) =>
              index === existingSignatureIndex ? newSignatureData : data
            )
          );
        }
      } else  {
        setSignatureDataList(prevList => {
          let updatedList = Array.isArray(prevList) ? prevList : Object.values(prevList);
        
          const filteredList = updatedList
            .filter(data => data.SignatureType !== newSignatureData.SignatureType)
            .filter(data => !(data.x === 0 && data.y === 0 && data.width === 0 && data.height === 0));

        
            if (globalEventType === 'remove') {
              return filteredList;  
            } else if(globalEventType === 'add') {
              return [...filteredList, newSignatureData];  
            } 
        });
      }
    }
  }, [isLocked]);

  return { handleInsertSignature };
};
