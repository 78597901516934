import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Box, Paper, Chip } from "@mui/material";
import Typography from "@mui/material/Typography";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";

const StyledChip = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "auto",
});

const ChipStatus = (props) => {
  const { numstatus } = props;

  return (
    <Fragment>
      <StyledChip>
        {numstatus === 0 ? (
          <Chip
            icon={<HourglassEmptyRoundedIcon />}
            color="warning"
            label="รอทดสอบ"
          />
        ) : numstatus === 1 ? (
          <Chip
            icon={<HourglassEmptyRoundedIcon />}
            color="info"
            label="กำลังทดสอบ"
          />
        ) : numstatus === 2 ? (
          <Chip icon={<CheckRoundedIcon />} color="success" label="ผ่านทดสอบ" />
        ) : (
          <Chip
            icon={<ClearRoundedIcon />}
            color="error"
            label="ยกเลิก / ไม่ผ่าน"
          />
        )}
      </StyledChip>
    </Fragment>
  );
};

function CardSkillStatus({ request }) {
  const { t } = useTranslation();

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        borderRadius: 4,
        display: "flex",
        alignItems: "flex-start",
        bgcolor: "#F1F0F0",
        width: "100%",
        marginBottom: "10px",
        whiteSpace: "pre-wrap",
      }}
    >
      <ChipStatus numstatus={request.numstatus} />
      <Box sx={{ flexGrow: 1, minWidth: 0, marginLeft: "8px" }}>
        {/* <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("Status")}: {t(checkStatus(request))}
        </Typography> */}
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("Testing")}: {request.nameTest} ({request.nameType})
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("Level")}: {request.level}
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("TestingRequestDate")}:{" "}
          {dayjs(request.appointDate).format("D MMM YYYY")} (
          {dayjs(request.appointDate).format("HH:mm")})
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("RequestDate")}: {dayjs(request.createDate).format("D MMM YYYY")} (
          {dayjs(request.createDate).format("HH:mm")})
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("Assessor")}:{" "}
          {`${request.assessorFirstName || "-"} ${
            request.assessorLastName || ""
          }`}{" "}
          {request.assessorPositionName
            ? `(${request.assessorPositionName})`
            : ""}
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {t("Approver")}:{" "}
          {`${request.approveByFirstName || "-"} ${
            request.approveByLastName || ""
          }`}{" "}
          {request.approveByPositionName
            ? `(${request.approveByPositionName})`
            : ""}
        </Typography>
      </Box>
    </Paper>
  );
}

export default CardSkillStatus;
