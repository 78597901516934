import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  LinearProgress,
  Rating,
} from "@mui/material";
import dayjs from "dayjs";

function KpiCard({ data }) {
  const titleColor = "rgb(113, 142, 191)";

  return (
    <Card
      sx={{
        minWidth: "300px",
        m: 2,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "16px",
      }}
    >
      <CardContent sx={{ textAlign: "center" }}>
        <Box
          sx={{
            bgcolor: "#F4F6FA",
            borderRadius: "16px",
            padding: "8px",
            p: 2,
            mb: 2,
          }}
        >
          <Typography
            variant="h6"
            color="#4535C1"
            fontSize="14px"
            fontWeight="400"
            gutterBottom
          >
            Weight
          </Typography>
          <Typography
            variant="h6"
            fontSize="28px"
            color="#4535C1"
            fontWeight="600"
          >
            {`${Math.round(data.weight)}%`}
          </Typography>
        </Box>

        <Typography
          variant="subtitle1"
          color={titleColor}
          fontWeight={500}
          gutterBottom
        >
          KPI name:
        </Typography>
        <Typography variant="body1" gutterBottom>
          {data.kpiName}
        </Typography>

        <Typography
          variant="subtitle1"
          color={titleColor}
          fontWeight={500}
          gutterBottom
        >
          Date:
        </Typography>
        <Typography variant="body1" gutterBottom>
          {dayjs(data.startDate).format("DD/MM/YYYY")} -{" "}
          {dayjs(data.endDate).format("DD/MM/YYYY")}
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Typography
            variant="subtitle1"
            color={titleColor}
            fontWeight={500}
            gutterBottom
          >
            Progress:
          </Typography>
          <LinearProgress
            variant="determinate"
            value={data.progress}
            sx={{
              mb: 2,
              borderRadius: "5px",
              backgroundColor: "#FFCBCB",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#FF4E88",
              },
            }}
          />
          <Typography
            variant="body2"
            color="#FF4E88"
            fontWeight={600}
            sx={{ fontSize: "1.5rem" }}
          >
            {data.progress ? data.progress : "0"}%
          </Typography>
        </Box>

        <Box
          sx={{
            mt: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="subtitle1"
            color={titleColor}
            fontWeight={500}
            gutterBottom
          >
            Rating:
          </Typography>
          <Rating value={data.managerRating} readOnly precision={0.1} />
          <Typography
            variant="body2"
            color="#FFC403"
            fontWeight={600}
            sx={{ fontSize: "1.5rem" }}
          >
            {data.managerRating ? data.managerRating.toFixed(1) : "0.0"}
          </Typography>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography
            variant="body2"
            sx={{
              bgcolor: "rgb(80, 180, 152)",
              color: "white",
              px: 2,
              py: 0.5,
              borderRadius: "20px",
              display: "inline-block",
            }}
          >
            {data.isApprove === 1 ? "Approved" : "Pending"}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default KpiCard;
