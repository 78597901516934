import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { styled } from "@mui/styles";
import { Grid, Typography } from "@mui/material";

const Root = styled("div")(({theme}) => ({
  marginTop: 16,
  marginBottom: 16,
  "& .textBold": {
    color: "#58647A !important",
    fontWeight: 600,
    verticalAlign: "middle",
    display: "inline-flex",
  },
  "& .wrapIcon": {
    verticalAlign: "middle",
    display: "inline-flex",
  },
}));

const Address = (props) => {
    const { employee } = props;
    return (
      <Root>
        <Grid container>
          <Grid item>
            <Typography variant="body1" className={"textBold"} gutterBottom>
              Address
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="subtitle1" className={"wrapIcon"}>
              <LocationOnIcon />
              {employee.address ? employee.address : "1518 Pracharat 1 Road,Wongsawang, Bangsue, Bangkok 10800"}
            </Typography>
          </Grid>
        </Grid>
      </Root>
    );
  }
  
export default Address;