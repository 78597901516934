import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Button,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  TableCell,
  Box,
  Grid,
  MenuItem,
} from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  getWarningLetterByIdEmployee,
  getWarningLevel,
  updateWarningLetterStatus,
} from "../../../../actions/warningLetter";
import CardStyle from "../../shared/general/Card";
import DialogDetail from "./dialogDetail";
import ChipWarningLevel from "../../admin/warningLetter/chipWarningLevel";
import ChipLetterStatus from "../../admin/warningLetter/chipLetterStatus";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import LoadingGif from "../../assets/social-media.gif";
import MonthPickerCustom from "../../shared/date/monthPicker";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

const StyledRoot = styled("div")({
  backgroundColor: "#f5f5f5",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const WrapHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
  marginTop: 8,
  marginBottom: 16,
});

const WarningLetter = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: warningLetter, isFetching } = useSelector((state) => state.warningLetter);
  const { result: warningLevel } = useSelector((state) => state.warningLevel);

  const [openDetail, setOpenDetail] = useState(false);
  const [selectLetter, setSelectLetter] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const [filterStatus, setFilterStatus] = useState("all");
  const [filterLevel, setFilterLevel] = useState("all");
  const [filterName, setFilterName] = useState("");

  const handleClickOpenDetail = (item) => {
    setOpenDetail(true);
    setSelectLetter(item);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  const handleChangeFilterStatus = (event) => {
    setFilterStatus(event.target.value);
  };

  const handleChangeFilterLevel = (event) => {
    setFilterLevel(event.target.value);
  };

  const handleChangeFilterName = (event) => {
    setFilterName(event.target.value);
  };

  const filterData = () => {
    let dataFiltered = [...warningLetter];
    if (filterName) {
      dataFiltered = dataFiltered.filter(
        (value) => (value.title).toLowerCase().includes(filterName.toLowerCase()) 
      );
    }
    if (filterDate) {
      dataFiltered = dataFiltered.filter((item) =>
        dayjs(item.createDate).isSame(filterDate, "month")
      );
    }
    if (filterStatus !== "all") {
      dataFiltered = dataFiltered.filter(
        (item) => item.isAccept === filterStatus
      );
    }
    if (filterLevel !== "all") {
      dataFiltered = dataFiltered.filter(
        (item) => item.idWarningLevel === filterLevel
      );
    }

    return dataFiltered;
  };


  const handleUpdateStatus = async (formData) => {
    const res = await dispatch(updateWarningLetterStatus(formData));
    if (res) {
      handleCloseDetail();
      dispatch(getWarningLetterByIdEmployee());
    }
  };

  useEffect(() => {
    dispatch(getWarningLetterByIdEmployee());
    dispatch(getWarningLevel(userProfile.idCompany));
  }, []);

  return (
    <StyledRoot className={"page"}>
      <Container maxWidth="lg">
        <WrapHeader>
          <Typography variant="h4">{`${t("WarningLetter")}`}</Typography>
        </WrapHeader>
        <CardStyle style={{ padding: 16 }}>
          <Grid container spacing={1} marginBottom={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                fontSize="14px"
                fontWeight="600"
                marginBottom="4px"
                color="text.third"
              >
                {t("SelectMonth")}
              </Typography>
              <MonthPickerCustom
                value={filterDate}
                onChange={(newValue) => setFilterDate(newValue)}
                views={["month", "year"]}
                minDate={dayjs().subtract(1, "year")}
                maxDate={dayjs()}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                fontSize="14px"
                fontWeight="600"
                marginBottom="4px"
                color="text.third"
              >
                {t("Status")}
              </Typography>
              <TextFieldTheme
                select
                fullWidth
                variant="filled"
                sx={{
                  "& .MuiSelect-filled": { padding: "16px" },
                }}
                value={filterStatus}
                onChange={handleChangeFilterStatus}
              >
                <MenuItem value={"all"}>{t("All")}</MenuItem>
                <MenuItem value={1}>{t("Accept")}</MenuItem>
                <MenuItem value={0}>{t("Refusal")}</MenuItem>
              </TextFieldTheme>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                fontSize="14px"
                fontWeight="600"
                marginBottom="4px"
                color="text.third"
              >
                {t("WarningLevel")}
              </Typography>
              <TextFieldTheme
                select
                fullWidth
                variant="filled"
                sx={{
                  "& .MuiSelect-filled": { padding: "16px" },
                }}
                value={filterLevel}
                onChange={handleChangeFilterLevel}
              >
                <MenuItem value={"all"}>{t("All")}</MenuItem>
                {warningLevel &&
                  warningLevel.map((level) => (
                    <MenuItem value={level.idWarningLevel}>
                      {level.name}
                    </MenuItem>
                  ))}
              </TextFieldTheme>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                fontSize="14px"
                fontWeight="600"
                marginBottom="4px"
                color="text.third"
              >
                {t("WarningLetterName")}
              </Typography>
              <TextFieldTheme
                fullWidth
                variant="filled"
                sx={{
                  "& .MuiInputBase-input": { padding: "16px" },
                }}
                value={filterName}
                onChange={handleChangeFilterName}
              />
            </Grid>
          </Grid>
          {isFetching ? (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={LoadingGif} style={{ width: 100, height: 100 }} />
            </Box>
          ) : warningLetter && warningLetter.length > 0 ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 600 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{`${t(
                      "WarningLevel"
                    )}`}</TableCell>
                    <TableCell align="center">{`${t("Date")}`}</TableCell>
                    <TableCell align="center">{`${t(
                      "WarningLetterName"
                    )}`}</TableCell>
                    <TableCell align="center">{`${t("Status")}`}</TableCell>
                    <TableCell align="center">{`${t(
                      "Descriptions"
                    )}`}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterData().map((item) => (
                    <TableRow key={item.idWarningLetter}>
                      <TableCell align="center">
                        <ChipWarningLevel
                          id={item.idWarningLevel}
                          label={item.level}
                        />
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(item.createDate).format(
                          i18n.resolvedLanguage === "th"
                            ? "DD/MM/BBBB"
                            : "DD/MM/YYYY"
                        )}
                      </TableCell>
                      <TableCell align="center">{item.title}</TableCell>
                      <TableCell align="center">
                        <ChipLetterStatus status={item.isAccept} />
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="outlined"
                          onClick={() => handleClickOpenDetail(item)}
                        >
                          {`${t("Descriptions")}`}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>{`${t("ThereIsNoWarningLetter")}`}</Typography>
          )}
        </CardStyle>
      </Container>
      {selectLetter && openDetail && (
        <DialogDetail
          open={openDetail}
          handleClose={handleCloseDetail}
          data={selectLetter}
          handleUpdateStatus={handleUpdateStatus}
        />
      )}
    </StyledRoot>
  );
};

export default WarningLetter;
