import { Box } from "@mui/material";
import React, { useState } from "react";
import { Tree, TreeNode } from 'react-organizational-chart';
import NodeStyle from "./NodeStyle";

const NodeContainer = (props) => {

  const { nodeData } = props;

  const [isExpand, setIsExpand] = useState(false);

  if(isExpand && nodeData.child.length > 0){

    return (
      <Tree
        // lineHeight="16px"
        lineColor="#96959e"
        lineBorderRadius="4px"
        label={<NodeStyle
          data={{
            title: nodeData.title,
            child: nodeData.child,
          }}
          setIsExpand={setIsExpand}
        />}
      >
        {isExpand && nodeData.child.map((division) => (
          <NodeComponent data={division}/>
        ))}
      </Tree>
    )

  } else {
    return (
      <Tree
        lineHeight="16px"
        lineColor="#96959e"
        lineBorderRadius="4px"
        label={<NodeStyle
          data={{
            title: nodeData.title,
            child: nodeData.child,
          }}
          setIsExpand={setIsExpand}
        />}
      />
    )
  }
}

const NodeComponent = (props) => {

  const { data } = props;

  const [isExpand, setIsExpand] = useState(false)

  if(data.child.length > 0 && isExpand){

    return (
      <TreeNode label={<NodeStyle data={{title: data.title, child: data.child}} setIsExpand={setIsExpand}/>}>
        {isExpand && data.child.length > 0 && data.child.map(c => (
          <NodeComponent data={c}/>
        ))}
      </TreeNode>
    )

  } else {

    return (
      <TreeNode label={<NodeStyle data={{title: data.title, child: data.child}} setIsExpand={setIsExpand}/>} />
    )

  }
  
}

export default NodeContainer;