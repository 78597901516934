import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Container,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Breadcrumbs,
  Link,
} from "@mui/material";
import dayjs from "dayjs";
import MoreTimeRoundedIcon from "@mui/icons-material/MoreTimeRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import UploadPage from "./UploadTimePage";
import DailyPanel from "./DailyPanel";
import IndividualPanel from "./IndividualPanel";
import ScheduleMonthly from "./ScheduleMonthly";
import EmployeeShiftPanel from "./employeeShiftPanel";
import SectionPanel from "./sectionPanel";
import WeeklyPanel from "./WeeklyPanel";
import DialogUploadTime from "./DialogUploadTime";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliateOrganization } from "../../../../actions/company";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../shared/general/ButtonBlue";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginTop: 24,
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    color: "#46cbe2",
    border: "1px solid #46cbe2",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#247785",
      backgroundColor: "#247785",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#46cbe2",
      "&:hover": {
        borderColor: "#247785",
        backgroundColor: "#247785",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #46cbe2",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
  },
}));

const TimeManagement = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const today = dayjs(new Date()).format("YYYY-MM-DD");
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );

  const [view, setView] = useState("daily");
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [openUploadTime, setOpenUploadTime] = useState(false);
  const [openDialogUpload, setOpenDialogUpload] = useState(false);
  const [search, setSearch] = useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(today).set("date", dayjs(today).daysInMonth()),
  });

  const handleChangeView = (event, newView) => {
    if (newView) {
      setView(newView);
      // setSelectedEmployee(null);
      // if (newView === "all") {
      //   dispatch(getHoliday(tempSearchDate));
      //   dispatch(getShiftPattern());
      //   //dispatch(getAttendanceCompany(search));
      // }
    }
  };

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
  };

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (
      userProfile &&
      affiliateOrganizationList &&
      affiliateOrganizationList.length > 0
    ) {
      let ownCompany = affiliateOrganizationList.find(
        (x) => x.idCompany === userProfile.idCompany
      );
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany);
    }
  }, [userProfile, affiliateOrganizationList]);

  const handleClickUploadTime = () => {
    setOpenUploadTime(true);
  };
  const handleClickDialogUploadTime = () => {
    setOpenDialogUpload(true);
  };
  const handleClickCloseDialogUploadTime = () => {
    setOpenDialogUpload(false);
  };

  const handleCloseDialogUploadTime = () => {
    setOpenUploadTime(false);
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div
          style={{
            marginBottom: 8,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            {openUploadTime === false
              ? `${t("TimeManagement")}`
              : `${t("AllUploadedList")}`}
          </Typography>
          {openUploadTime === false ? (
            <div>
              <ButtonBlue
                startIcon={<MoreTimeRoundedIcon />}
                variant="contained"
                onClick={handleClickUploadTime}
              >
                {t("UploadWorkingHours")}
              </ButtonBlue>
            </div>
          ) : (
            <div>
              <ButtonBlue
                startIcon={<MoreTimeRoundedIcon />}
                variant="contained"
                onClick={handleClickDialogUploadTime}
              >
                {t("Upload")}
              </ButtonBlue>
            </div>
          )}
        </div>
        {openUploadTime ? (
          <Fragment>
            <div style={{ marginBottom: 16 }}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="inherit"
                  onClick={handleCloseDialogUploadTime}
                >
                  <Typography color="text.third" style={{ display: "flex" }}>
                    <ArrowBackIosRoundedIcon
                      style={{ marginRight: 4, marginTop: 4 }}
                      fontSize="inherit"
                    />
                    {t("Back")}
                  </Typography>
                </Link>
              </Breadcrumbs>
            </div>
            <Box marginTop="24px">
              <SelectAffiliateCompany
                options={affiliateOrganizationList}
                value={selectedCompany}
                onChange={(_, value) => {
                  onChangeCompany(value);
                }}
              />
            </Box>

            <UploadPage
              search={search}
              setSearch={setSearch}
              selectedCompany={selectedCompany}
            />
          </Fragment>
        ) : (
          <Fragment>
            <StyledToggleButtonGroup
              value={view}
              exclusive
              onChange={handleChangeView}
              aria-label="view"
              size="small"
            >
              <ToggleButton
                name="status"
                value="daily"
                aria-label="daily"
                size="small"
                style={{ minWidth: 150, marginRight: 12 }}
              >
                {t("DailyInformation")}
              </ToggleButton>
              <ToggleButton
                name="status"
                value="all-employee-weekly"
                aria-label="all-employee-weekly"
                size="small"
                style={{ minWidth: 150, marginRight: 12 }}
              >
                {t("WeeklyInformation")}
              </ToggleButton>
              {userProfile.isFillIn !== 1 && (
                <ToggleButton
                  name="status"
                  value="all-employee-monthly"
                  aria-label="all-employee-monthly"
                  size="small"
                  style={{ minWidth: 150, marginRight: 12 }}
                >
                  {t("MonthlyInformation")}
                </ToggleButton>
              )}
              <ToggleButton
                name="status"
                value="individual"
                aria-label="individual"
                size="small"
                style={{ minWidth: 150, marginRight: 12 }}
              >
                {t("PersonalInformation")}
              </ToggleButton>
              {/* <ToggleButton
                name="status"
                value="section"
                aria-label="section"
                size="small"
                style={{ minWidth: 150, marginRight: 12 }}
              >
                ข้อมูลรายแผนก
              </ToggleButton> */}
              <ToggleButton
                name="status"
                value="employee-shift"
                aria-label="employee-shift"
                size="small"
                style={{ minWidth: 150 }}
              >
                {t("ShiftSchedule")}
              </ToggleButton>
            </StyledToggleButtonGroup>

            <Box marginTop="24px">
              <SelectAffiliateCompany
                options={affiliateOrganizationList}
                value={selectedCompany}
                onChange={(_, value) => {
                  onChangeCompany(value);
                }}
              />
            </Box>

            {view === "daily" && (
              <DailyPanel selectedCompany={selectedCompany} />
            )}
            {view === "individual" && (
              <IndividualPanel selectedCompany={selectedCompany} />
            )}
            {/* {view === "section" && (
              <SectionPanel selectedCompany={selectedCompany} />
            )} */}
            {view === "all-employee-monthly" && userProfile.isFillIn !== 1 && (
              <ScheduleMonthly selectedCompany={selectedCompany} />
            )}
            {view === "employee-shift" && (
              <EmployeeShiftPanel selectedCompany={selectedCompany} />
            )}
            {view === "all-employee-weekly" && (
              <WeeklyPanel selectedCompany={selectedCompany} />
            )}
          </Fragment>
        )}

        {openDialogUpload && (
          <DialogUploadTime
            open={openDialogUpload}
            handleClose={handleClickCloseDialogUploadTime}
            selectedCompany={selectedCompany}
            search={search}
            isReadOnly={userProfile && userProfile.readOnly}
          />
        )}
      </Container>
    </StyledRoot>
  );
};

export default TimeManagement;
