import React from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CardStyle from "../../../shared/general/Card";

const StyledRoot = styled(Box)({
  marginTop: "30px",
  // "& .MuiAccordionSummary-content": {
  //   alignItems: "center",
  // },
});

const ManagerPermissionPanel = (props) => {

  const { selectedCompany, isReadOnly } = props;
  const { i18n, t } = useTranslation();

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography fontWeight="500" fontSize="22px">{t("ManagerPermission")}</Typography>
        </Grid>

        <CardStyle style={{ marginTop: "24px" }}>
          <Box padding="24px">

          </Box>
        </CardStyle>

        <Grid item xs={8}>
          <Typography>หัวหน้างานระดับที่ 1 สามารถดูเงินเดือนพนักงาน</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>ใช่</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>หัวหน้างานระดับที่ 2 สามารถดูเงินเดือนพนักงาน</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>ใช่</Typography>
        </Grid>
      </Grid>
    </StyledRoot>
  )

}

export default ManagerPermissionPanel;