import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserAccessTime from "../user/AccessTime";
import CheckInOut from "../user/AccessTime/mobile/index";
import AdminAccessTime from "../admin/accessTime";

const AccessTimePage = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [isPWA, setIsPWA] = useState(false);
  useEffect(() => {
    let displayMode = "";

    if (window.matchMedia("(display-mode: standalone)").matches) {
      displayMode = "standalone";
      setIsPWA(true);
    } else {
      displayMode = "!!!!!!";
    }
  }, []);

  return (
    <>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ||
        userProfile.roles.includes("ROLE_MANAGER") ? (
          <AdminAccessTime />
        ) : isPWA ? (
          <CheckInOut />
        ) : (
          <UserAccessTime />
        )
      ) : null}
    </>
  );
};

export default AccessTimePage;
