import Quill from 'quill';
import Swal from 'sweetalert2';
import mitt from 'mitt';
const BlockEmbed = Quill.import('blots/block/embed');

class SignatureBlot extends BlockEmbed {
  static isLockedMap = {};
  static isLocked = false; 
  static emitter = mitt(); 

  static setIsLocked(signatureType, value) {
    let valueUpdate = !value;
    SignatureBlot.isLockedMap = { ...SignatureBlot.isLockedMap, [signatureType]: valueUpdate };

    if (value === 'delete') {
      delete SignatureBlot.isLockedMap[signatureType];
    }

    SignatureBlot.emitter.emit('lockChange', SignatureBlot.isLockedMap);
  }
  static eventType = '';
  static signatureDataList;  
  static signatureType = "";
  static positionSignature = { 
    x: 0,
    y: 0,
    width: 0,
    height: 0
  };

  updateParameter(value) {
    SignatureBlot.signatureType = value;  
  }

  static create(value) {
  
    const node = super.create();
    if (!node) {
      console.error("Failed to create node");
      return null;
    }
    
    node.setAttribute('contenteditable', false); 
    node.setAttribute('data-signature-type', SignatureBlot.signatureType);
   
    const innerDiv = document.createElement('div');
    Object.assign(innerDiv.style, {
      border: '1px solid #000',
      padding: '0px',
      textAlign: 'center',
      width: '190px',  
      height: '130px', 
      boxSizing: 'border-box',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      overflow: 'hidden',
    });
  
    const mainContainer = document.createElement('div');
    mainContainer.style.border = '1px solid #000'; 
    mainContainer.style.width = '100%';
    mainContainer.style.textAlign = 'center';
    mainContainer.style.display = 'flex';
    mainContainer.style.flexDirection = 'column';
    mainContainer.style.padding = '5px'; 
    mainContainer.style.margin = '0'; 
    mainContainer.style.flexGrow = '1'; 

    const imageContainer = document.createElement('div');
    imageContainer.style.display = 'flex';
    imageContainer.style.alignItems = 'center';
    imageContainer.style.justifyContent = 'center';
    imageContainer.style.height = 'auto'; 
    imageContainer.style.flexGrow = '1'; 
    imageContainer.style.width = '100%';
    imageContainer.style.marginBottom = '0';

    const icon = document.createElement('i');
    icon.className = 'fas fa-signature';
    icon.style.fontSize = '25.3px';
    icon.style.margin = '5px 0'; 
    imageContainer.appendChild(icon);

    mainContainer.appendChild(imageContainer);

    const signatureTextContainer = document.createElement('div');
    signatureTextContainer.style.width = '100%';
    signatureTextContainer.style.textAlign = 'center';
    signatureTextContainer.style.padding = '0px';
    signatureTextContainer.style.margin = '0'; 

    const signatureText = document.createElement('span');
    signatureText.style.fontSize = '17.5px';
    signatureText.innerText =  SignatureBlot.signatureType;  

    signatureTextContainer.appendChild(signatureText);
    mainContainer.appendChild(signatureTextContainer);
    innerDiv.appendChild(mainContainer);
  
    const textContainer = document.createElement('div');
    textContainer.style.textAlign = 'center';
    textContainer.style.paddingTop = '5px';

    const nameText = document.createElement('span');
    nameText.style.fontSize = '17.5px';
    nameText.style.display = 'block';
    nameText.innerText = "( ชื่อ  นามสกุล )";
  
    const positionText = document.createElement('span');
    positionText.style.fontSize = '17.5px';
    positionText.style.display = 'block'; 
    positionText.innerText = "ตำแหน่ง";
  
    textContainer.appendChild(nameText);
    textContainer.appendChild(positionText);
  
    innerDiv.appendChild(textContainer);
  
    const buttonContainer = document.createElement('div');
    buttonContainer.style.display = 'flex';
    buttonContainer.style.justifyContent = 'center';
    buttonContainer.style.alignItems = 'center';
    buttonContainer.style.flexDirection = 'column';

    buttonContainer.style.position = 'absolute';  
    buttonContainer.style.bottom = '10px';      
    buttonContainer.style.right = '10px';      

    const lockButton = document.createElement('button');
    lockButton.className = 'lock-button';
    lockButton.style.border = 'none';
    lockButton.style.background = 'none';
    lockButton.style.padding = '0';

    const lockIcon = document.createElement('i');
    lockIcon.className = 'fas fa-lock';
    lockIcon.style.fontSize = '24px';
    lockIcon.style.opacity = SignatureBlot.isLocked ? '1' : '0.5';
    lockButton.appendChild(lockIcon);
    buttonContainer.appendChild(lockButton);

    innerDiv.appendChild(buttonContainer);
      
    const resizeButton = document.createElement('button');
    resizeButton.className = 'resize-button';
    resizeButton.style.position = 'absolute';
    resizeButton.style.top = '5px';
    resizeButton.style.right = '5px';
    resizeButton.style.background = 'none';
    resizeButton.style.border = 'none';
    resizeButton.style.cursor = 'pointer';
    resizeButton.innerHTML = '<i class="fas fa-edit"></i>';
  
    innerDiv.appendChild(resizeButton);
    node.appendChild(innerDiv);
    node.style.position = 'absolute';
    node.style.left = '0px';
    node.style.top = '0px';
    node.style.zIndex = '1000';
  
    this.makeDraggable(innerDiv, node)
  
    resizeButton.isResizable = false;
    resizeButton.addEventListener('click', (e) => {

      if (!SignatureBlot.isLockedMap) {
        SignatureBlot.isLockedMap = {};
      }

      const signatureType = node.getAttribute('data-signature-type');

      let isLocked = SignatureBlot.isLockedMap[signatureType] || false;

      if (isLocked) {
        Swal.fire({
          icon: 'warning',
          title: 'การดำเนินการถูกล็อค',
          text: 'ปลดล็อคก่อนแก้ไข!',
        });
        return; 
      }
  
      e.stopPropagation();
      resizeButton.isResizable = !resizeButton.isResizable;
      resizeButton.innerHTML = resizeButton.isResizable 
        ? '<i class="fas fa-times"></i>' 
        : '<i class="fas fa-edit"></i>';
  
      if (resizeButton.isResizable) {
        SignatureBlot.makeResizable(innerDiv); 
        innerDiv.style.cursor = 'default'; 
      } else {
        SignatureBlot.removeResizable(innerDiv);
        innerDiv.style.cursor = 'move'; 
      }
    });
  
    const deleteButton = document.createElement('button');
    deleteButton.className = 'delete-button';
    deleteButton.style.position = 'absolute';
    deleteButton.style.top = '5px';
    deleteButton.style.left = '5px';
    deleteButton.style.background = 'none';
    deleteButton.style.border = 'none';
    deleteButton.style.cursor = 'pointer';
    deleteButton.innerHTML = '<i class="fas fa-trash"></i>'; 
    innerDiv.appendChild(deleteButton);
  
    deleteButton.addEventListener('click', (e) => {
      e.stopPropagation();
  
      const blot = Quill.find(node); 
      const signatureType = node.getAttribute('data-signature-type');

      if (blot) {
        blot.remove(); 
      
        const prevList = SignatureBlot.signatureDataList;
      
        if (Array.isArray(prevList)) {
      
          const updatedList = prevList.filter(data => data.SignatureType !== signatureType);
          
          SignatureBlot.setIsLocked(signatureType, 'delete');

          SignatureBlot.signatureDataList = updatedList;
          SignatureBlot.eventType = 'remove';

          SignatureBlot.emitter.emit('signatureDataListUpdated', {
            type: SignatureBlot.eventType,  
            updatedList: SignatureBlot.signatureDataList, 
            signatureType: signatureType  
          });     

        } else {
          console.error('prevList ไม่ใช่ array หรือเป็น undefined:', prevList);
        }
      } else {
        console.error('ไม่พบ Blot ที่ต้องการลบ');
      }
    });
  
    return node;
  }


  static makeDraggable(innerDiv, node) {
    let isDragging = false;
    let offset = { x: 0, y: 0 };   
    
    let signatureId = node.getAttribute('data-signature-type');

    const lockButton = node.querySelector('.lock-button');
    
    if (!SignatureBlot.isLockedMap) {
      SignatureBlot.isLockedMap = {};
    }

    if (lockButton) {
      lockButton.addEventListener('click', (e) => {
          e.stopPropagation();
  
          let isLocked =  SignatureBlot.isLockedMap[signatureId] || false;          

          SignatureBlot.setIsLocked(signatureId, isLocked);
          lockButton.querySelector('i').style.opacity = !isLocked ? '1' : '0.5';
  
          if (!isLocked) {
            SignatureBlot.positionSignature = SignatureBlot.SignaturePosition(node, signatureId);
          }
      });
    } else {
        console.error('lockButton is null or not found in DOM');
    }

    innerDiv.addEventListener('mousedown', (e) => {
      const signatureId = node.getAttribute('data-signature-type');
      const resizeButton = innerDiv.querySelector('.resize-button');
      if (!SignatureBlot.isLockedMap) {
        SignatureBlot.isLockedMap = {};
      }

      const isLocked = SignatureBlot.isLockedMap[signatureId] || false;
      
      if (isLocked || (resizeButton && resizeButton.isResizable)) return;

      isDragging = true;
      offset.x = e.clientX - node.getBoundingClientRect().left;
      offset.y = e.clientY - node.getBoundingClientRect().top;
      document.body.style.cursor = 'grabbing';
      e.stopPropagation();
    });
    
    window.addEventListener('mousemove', (e) => {
      if (isDragging) {
        node.style.left = `${e.clientX - offset.x}px`;
        node.style.top = `${e.clientY - offset.y}px`;
      }
    });

    window.addEventListener('mouseup', () => {
      if (isDragging) {
        isDragging = false;
        document.body.style.cursor = 'default';
      }
    });
  }

  static makeResizable(innerDiv) {
    const resizer = document.createElement('div');
    Object.assign(resizer.style, {
      width: '10px',
      height: '10px',
      background: '#000',
      position: 'absolute',
      right: '0',
      bottom: '0',
      cursor: 'nwse-resize'
    });

    innerDiv.appendChild(resizer);

    let isResizing = false;
    let startX, startY, startWidth, startHeight;

    resizer.addEventListener('mousedown', (e) => {
      isResizing = true;
      startX = e.clientX;
      startY = e.clientY;
      startWidth = innerDiv.offsetWidth;
      startHeight = innerDiv.offsetHeight;
      document.body.style.cursor = 'nwse-resize';
      e.stopPropagation();
    });

    document.addEventListener('mouseup', () => {
      isResizing = false;
      document.body.style.cursor = 'default';
    });

    document.addEventListener('mousemove', (e) => {
      if (isResizing) {
        const newWidth = Math.max(startWidth + (e.clientX - startX), 50);
        const newHeight = Math.max(startHeight + (e.clientY - startY), 50);
        innerDiv.style.width = `${newWidth}px`;
        innerDiv.style.height = `${newHeight}px`;
      }
    });
  }

  static removeResizable(innerDiv) {
    const resizer = innerDiv.querySelector('div[style*="nwse-resize"]');
    if (resizer) {
      resizer.remove();
    }
  }

  static formats(node) {
    return node.getAttribute('data-signature');
  }

  
  static SignaturePosition(node, signatureType) {
    const rect = node.getBoundingClientRect();
    
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    let windowSize = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    const handleResize = () => {
      windowSize = {
        width: window.innerWidth,
        height: window.innerHeight
      };
    };

    window.addEventListener('resize', handleResize);

    window.onbeforeunload = () => {
      window.removeEventListener('resize', handleResize);
    };

    let referenceExtra = 0;
    
    if (windowSize.width < 1650) {
      referenceExtra = 0;
    } else if (windowSize.width >= 1650 && windowSize.width <= 2550) {
      referenceExtra = 185.875;
    } else if (windowSize.width >= 2550 && windowSize.width <= 3020) {
      referenceExtra = 505.875;
    }  else if (windowSize.width >= 3020 && windowSize.width <= 3450) {
      referenceExtra = 740.875;
    } else if (windowSize.width >= 3450) {
      referenceExtra = 940.875;
    } 

    const positionData = {
      x: (rect.left + scrollLeft - 192.6374969482422 - 138.15000915527344) - referenceExtra,
      y: rect.top + scrollTop,
      width: rect.width,
      height: rect.height,
      SignatureType: signatureType,
      status: 'add' 
    };        
    return positionData;
  }
}

SignatureBlot.blotName = 'signature';
SignatureBlot.tagName = 'div';

export default SignatureBlot;
