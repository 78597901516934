import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { PDFDownloadLink } from "@react-pdf/renderer";
import { Radar } from "react-chartjs-2";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import {
  Grid,
  Avatar,
  Typography,
  Paper,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Fab,
  Box,
  LinearProgress,
  IconButton,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GradeIcon from "@mui/icons-material/Grade";
import GroupsIcon from "@mui/icons-material/Groups";
import GroupIcon from "@mui/icons-material/Group";
import WorkIcon from "@mui/icons-material/Work";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import ClassIcon from "@mui/icons-material/Class";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import SearchIcon from "@mui/icons-material/Search";

import DownloadIcon from "@mui/icons-material/Download";

import { getAllWork } from "../../../../actions/work";
import Doc from "./resume/allData";
import DocSomeData from "./resume/someData";


import ButtonBlue from "../general/ButtonBlue";
import CardStyle from "../general/Card";



const useStyles = makeStyles(() => ({
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  bigAvatar: {
    width: 200,
    height: 200,
  },
  text: {
    fontSize: 17,
  },
  textLine: {
    display: "flex",
    alignItems: "center",
    padding: 10,
  },
  textMainData: {
    fontSize: 18,
    fontWeight: "bold",
  },
  textName: {
    fontSize: 25,
    fontWeight: "bold",
    marginTop: 10,
    color: "#007afc",
  },
  space: {
    marginTop: 10,
    marginBottom: 10,
  },
  nameAttribute: {
    fontSize: 23,
    fontWeight: "bold",
    marginLeft: 10,
  },
  attribute: {
    fontSize: 18,
    marginTop: 50,
    marginBottom: 10,
  },
  tableText: {
    fontSize: 18,
  },
  learningName: {
    fontSize: 20,
    fontWeight: "bold",
  },
  avatarCer: {
    minWidth: 700,
    minHeight: 500,
    marginBottom: 20,
    marginTop: 20,
  },
  textPerform: {
    textAlign: "center",
    padding: 60,
    margin: 20,
    fontSize: 75,
    fontWeight: "bold",
    color: "#007afc",
  },
  iconBeforeText: {
    marginRight: 7,
    fontSize: 22,
  },
  topicInJob: {
    fontSize: 18,
    fontWeight: "bold",
  },
  accordion: {
    marginBottom: 15,
    borderRadius: 10,
    "&.MuiAccordion-root:before": {
      backgroundColor: "transparent",
    },
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const OpenCertificate = ({ open, close, data }) => {
  const classes = useStyles();
  return (
    <Dialog onClose={close} open={open} maxWidth="false" style={{ margin: 20 }}>
      <DialogTitle
        style={{
          textAlign: "center",
          fontSize: 35,
          fontWeight: "bold",
          color: "#210c96",
        }}
      >
        Certificate
      </DialogTitle>
      <DialogContent>
        <div>
          <Avatar
            alt="Remy Sharp"
            src={`${process.env.REACT_APP_API_URL}image/certificate/${data}`}
            variant={"square"}
            className={classes.avatarCer}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ margin: 20, marginTop: 0 }}
          onClick={close}
          color="primary"
          variant="contained"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const NormalData = ({ name, value }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <Typography
          className={classes.text}
          style={{ marginTop: 10, color: "#757575" }}
        >
          {name}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Typography className={classes.textMainData}>{value}</Typography>
      </Grid>
    </Grid>
  );
};

const DataInJob = ({ name, value, icon }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <Typography
          className={classes.text}
          style={{
            marginTop: 20,
            color: "#757575",
            display: "flex",
            alignItems: "center",
          }}
        >
          {name}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Typography className={classes.textMainData}>{value}</Typography>
      </Grid>
    </Grid>
  );
};

const ShowInformation = ({ id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: dataWork } = useSelector((state) => state.dataWork);

  useEffect(() => {
    dispatch(getAllWork());
  }, []);

  const chartData = {
    labels: ["Caring", "Fast Move", "Obsession", "Outperform", "Do It"],
    datasets: [
      {
        data: [2, 7, 6, 8, 12],
        backgroundColor: "rgba(0,122,252,0.4)",
        borderColor: "rgba(9,110,217,0.7)",
        borderWidth: 1,
      },
    ],
  };

  const plugin = {
    beforeDraw: (chart) => {
      const { ctx, scales, config } = chart;
      const { xCenter, yCenter, drawingArea: radius } = scales.r;
      ctx.save();
      ctx.arc(
        Math.abs(xCenter),
        Math.abs(yCenter),
        Math.abs(radius),
        0,
        Math.abs(Math.PI * 2)
      );
      ctx.fillStyle = "rgba(238, 238, 238,0.4)";
      ctx.fill();
      ctx.restore();
    },
  };

  const option = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        suggestedMin: 1,
        suggestedMax: 20,
        pointLabels: {
          font: { size: 16 },
        },
        ticks: {
          maxTicksLimit: 4,
          display: false,
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
    },
  };

  const [open, setOpen] = useState(false);
  const [certificate, setCertificate] = useState("");

  const close = () => {
    setOpen(false);
    setCertificate("");
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Information
      </Typography>
      {dataWork &&
        dataWork
          .filter((val) => val.id == id)
          .map((val, index) => (
            <div key={val.id}>
              <Grid
                container
                justifyContent="right"
                style={{ marginBottom: 16 }}
              >
                <ButtonBlue variant="contained" startIcon={<DownloadIcon />}>
                  {/* <PDFDownloadLink
                    style={{
                      fontSize: 15,
                      color: "white",
                      textDecoration: "none",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                    document={<Doc data={val} />}
                    fileName="FullData.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? "Loading ..." : "Full one page"
                    }
                  </PDFDownloadLink> */}
                </ButtonBlue>
                <ButtonBlue
                  style={{ marginLeft: 16 }}
                  variant="contained"
                  startIcon={<DownloadIcon />}
                >
                  {/* <PDFDownloadLink
                    style={{
                      fontSize: 15,
                      color: "white",
                      textDecoration: "none",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                    document={<DocSomeData data={val} />}
                    fileName="Mini.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? "Loading ..." : "Half one page"
                    }
                  </PDFDownloadLink> */}
                </ButtonBlue>
              </Grid>
              <CardStyle>
                <div style={{ padding: "40px" }}>
                  <Grid container spacing={4}>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={12}
                      xs={12}
                      style={{
                        padding: 20,
                        textAlign: "-webkit-center",
                      }}
                    >
                      <Avatar
                        alt="each person"
                        src={`${process.env.REACT_APP_API_URL}image/profile/${val.avatarImg}`}
                        className={classes.bigAvatar}
                      />
                      <Typography className={classes.textName}>
                        {val.firstName} {val.lastName}
                      </Typography>
                      <Typography className={classes.text}>
                        {val.duty}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xl={8}
                      lg={8}
                      md={11}
                      xs={12}
                      style={{ padding: 20 }}
                    >
                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: 29,
                          fontWeight: "bold",
                          color: "#007afc",
                          marginBottom: 20,
                        }}
                      >
                        Main Information
                      </Typography>
                      <Grid container>
                        <Grid item xl={4} lg={4} md={4} xs={12}>
                          <NormalData name="Employee ID" value={val.id} />
                          <NormalData
                            name="Department"
                            value={val.department}
                          />
                        </Grid>
                        <Grid item xl={5} lg={6} md={6} xs={12}>
                          <NormalData name="Birthday" value={val.birthday} />
                          <NormalData
                            name="Job group"
                            value={val.jobGroup.name}
                          />
                        </Grid>
                        <Grid item xl={3} lg={2} md={2} xs={12}>
                          <NormalData name="Nationality" value={val.nation} />
                          <NormalData name="Section" value={val.section} />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xl={4} lg={4} md={4} xs={12}>
                          <NormalData name="TIG" value={val.tig} />
                          <NormalData name="OESY" value={val.oesy} />
                          <NormalData name="Email" value={val.email} />
                        </Grid>
                        <Grid item xl={5} lg={6} md={6} xs={12}>
                          <NormalData name="Tip" value={val.tig} />
                          <NormalData name="Age" value={val.age} />
                          <NormalData name="Report" value={val.report} />
                        </Grid>
                        <Grid item xl={3} lg={2} md={2} xs={12}>
                          <NormalData name="ESY" value={val.esy} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </CardStyle>
              <br />
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.nameAttribute}>
                    Attribute
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container justifyContent="center">
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={3}
                      xs={6}
                      style={{ padding: 20, paddingTop: 0 }}
                    >
                      <Typography
                        className={classes.attribute}
                        style={{ marginTop: 0 }}
                      >
                        Caring
                      </Typography>
                      <LinearProgressWithLabel value={40} />
                      <Typography className={classes.attribute}>
                        Obsession
                      </Typography>
                      <LinearProgressWithLabel value={50} />
                      <Typography className={classes.attribute}>
                        Do It
                      </Typography>
                      <LinearProgressWithLabel value={60} />
                    </Grid>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={3}
                      xs={6}
                      style={{ padding: 20, paddingTop: 0 }}
                    >
                      <Typography
                        className={classes.attribute}
                        style={{ marginTop: 0 }}
                      >
                        Fast Move
                      </Typography>
                      <LinearProgressWithLabel value={50} />
                      <Typography className={classes.attribute}>
                        Outperform
                      </Typography>
                      <LinearProgressWithLabel value={60} />
                    </Grid>
                    <Grid item xl={1} lg={1} md={0} />
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={5}
                      xs={8}
                      style={{ maxWidth: "600px", maxHeight: "600px" }}
                    >
                      <Radar
                        data={chartData}
                        options={option}
                        plugins={[plugin]}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.nameAttribute}>Job</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ marginLeft: 20, paddingBottom: 50 }}>
                  <Grid container>
                    <Grid item xl={4} lg={4} md={4} xs={12}>
                      <DataInJob
                        name="Job group"
                        value={val.jobGroup.name}
                        icon={<GroupsIcon className={classes.iconBeforeText} />}
                      />
                      <DataInJob
                        name="Sub job group"
                        value={val.jobGroup.subJob}
                        icon={<GroupIcon className={classes.iconBeforeText} />}
                      />
                      <DataInJob
                        name="Job position"
                        value={val.jobGroup.jobPosition}
                        icon={<WorkIcon className={classes.iconBeforeText} />}
                      />
                      <DataInJob
                        name="Department"
                        value={val.jobGroup.department}
                        icon={
                          <ApartmentIcon className={classes.iconBeforeText} />
                        }
                      />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} xs={12}>
                      <DataInJob
                        name="Job level (JE)"
                        value={val.jobGroup.jobLevel}
                        icon={
                          <LabelImportantIcon
                            className={classes.iconBeforeText}
                          />
                        }
                      />
                      <DataInJob
                        name="TIG"
                        value={val.tig}
                        icon={
                          <AccessTimeFilledIcon
                            className={classes.iconBeforeText}
                          />
                        }
                      />
                      <DataInJob
                        name="ESY"
                        value={val.esy}
                        icon={
                          <AccessTimeFilledIcon
                            className={classes.iconBeforeText}
                          />
                        }
                      />
                      <DataInJob
                        name="TIP"
                        value={val.tip}
                        icon={
                          <AccessTimeFilledIcon
                            className={classes.iconBeforeText}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} xs={12}>
                      <DataInJob
                        name="Job classificate"
                        value={val.jobGroup.jobClassificate}
                        icon={<ClassIcon className={classes.iconBeforeText} />}
                      />
                      <DataInJob
                        name="Service"
                        value={val.jobGroup.service}
                        icon={
                          <RoomServiceIcon className={classes.iconBeforeText} />
                        }
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.nameAttribute}>
                    Education
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/*
                  <Grid container>
                    {val.education.map((val, index) => (
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={12}
                        xs={12}
                        key={`${val.degree} ${val.major}`}
                      >
                        <Typography
                          style={{
                            color: "#007afc",
                            fontSize: 20,
                            fontWeight: "bold",
                            marginLeft: 20,
                          }}
                        >
                          Level {index + 1}
                        </Typography>
                        <Paper
                          elevation={3}
                          style={{ margin: 20, marginTop: 5 }}
                        >
                          <Grid container>
                            <Grid item xl={8} lg={8} md={6} xs={6}>
                              <Typography
                                style={{
                                  fontSize: 19,
                                  fontWeight: "bold",
                                  color: "#007afc",
                                }}
                              >
                                {val.degree}
                              </Typography>
                              <Typography
                                className={classes.text}
                                style={{ fontSize: 17 }}
                              >
                                {val.year}
                              </Typography>
                            </Grid>
                            <Grid item xl={4} lg={4} md={6} xs={6}>
                              <Typography
                                style={{
                                  fontSize: 18,
                                  textAlign: "right",
                                  fontWeight: "bold",
                                }}
                              >
                                {val.major}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Typography
                            className={classes.text}
                            style={{ fontSize: 16 }}
                          >
                            {val.faculity}
                          </Typography>
                          <Typography
                            className={classes.text}
                            style={{ fontSize: 16 }}
                          >
                            {val.institute}, {val.country}
                          </Typography>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                  */}
                  <TableContainer component={Paper}>
                    <Table style={{ padding: 20 }}>
                      <TableHead>
                        <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                          <TableCell>
                            <Typography
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              Level
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              Degree
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              Faculty
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              Major
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              Institute
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              Start date - End date
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {val.education.map((val, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.tableText}
                              >
                                Level {index + 1}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.tableText}
                              >
                                {val.degree}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.tableText}
                              >
                                {val.faculity}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.tableText}
                              >
                                {val.major}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.tableText}
                              >
                                {val.institute}, {val.country}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.tableText}
                              >
                                {val.year}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>

              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.nameAttribute}>
                    Experience profile
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table style={{ padding: 20 }}>
                      <TableHead>
                        <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                          <TableCell>
                            <Typography
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              Start date - End date
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography style={{ fontWeight: "bold" }}>
                              Position
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography style={{ fontWeight: "bold" }}>
                              Department
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography style={{ fontWeight: "bold" }}>
                              Company
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography style={{ fontWeight: "bold" }}>
                              Province
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography style={{ fontWeight: "bold" }}>
                              Country
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {val.experience[0] != null &&
                          val.experience.map((val, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.tableText}
                                >
                                  {val.date}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.tableText}
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {val.position}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.tableText}
                                >
                                  {val.department}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.tableText}
                                >
                                  {val.company}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.tableText}
                                >
                                  {val.province}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.tableText}
                                >
                                  {val.country}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>

              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.nameAttribute}>
                    Project profile Experience
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table style={{ padding: 20 }}>
                      <TableHead>
                        <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                          <TableCell>
                            <Typography style={{ fontWeight: "bold" }}>
                              Project name
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography style={{ fontWeight: "bold" }}>
                              Start Date - End Date
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography style={{ fontWeight: "bold" }}>
                              Value
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography style={{ fontWeight: "bold" }}>
                              Role
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography style={{ fontWeight: "bold" }}>
                              Country
                            </Typography>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {val.DataProject[0] != null &&
                          val.DataProject.map((val, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.tableText}
                                >
                                  {val.name}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.tableText}
                                >
                                  20/11/2020 - 03/02/2021
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.tableText}
                                >
                                  200K
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.tableText}
                                >
                                  {val.role}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.tableText}
                                >
                                  {val.country}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Link
                                  to={`/project/showDetail/${val.id}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <IconButton
                                    sx={{
                                      color: "#424242",
                                    }}
                                    size="small"
                                  >
                                    <SearchIcon />
                                  </IconButton>
                                </Link>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>

              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.nameAttribute}>
                    Learning profile
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {val.course.map((val, index) => (
                    <Paper key={index} style={{ padding: 20 }}>
                      <Grid container style={{ alignItems: "center" }}>
                        <Grid item xl={4} lg={4} md={4} xs={12}>
                          <Typography
                            className={classes.learningName}
                            style={{ color: "#007afc" }}
                          >
                            {val.name}
                          </Typography>
                          <Typography className={classes.text}>
                            {val.branch}
                          </Typography>
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} xs={12}>
                          <Typography>Start date - End date</Typography>
                          <Typography style={{ fontWeight: "bold" }}>
                            {val.date}
                          </Typography>
                        </Grid>
                        <Grid item xl={3} lg={3} md={2} xs={12}>
                          <Typography>institution</Typography>
                          <Typography
                            style={{ fontSize: 18, fontWeight: "bold" }}
                          >
                            {val.where}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xl={2}
                          lg={2}
                          md={3}
                          xs={12}
                          style={{ textAlign: "center" }}
                        >
                          <Fab
                            variant="extended"
                            onClick={(e) => {
                              setOpen(true);
                              setCertificate(val.certificate);
                            }}
                            style={{
                              fontSize: 15,
                              fontWeight: "bold",
                              backgroundColor: "#007afc",
                              color: "white",
                            }}
                          >
                            <VisibilityIcon
                              style={{ marginRight: 10, fontSize: 19 }}
                            />
                            certificate
                          </Fab>
                          {open && (
                            <OpenCertificate
                              open={open}
                              close={close}
                              data={certificate}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Paper>
                  ))}
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.nameAttribute}>
                    Career and Successor
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xl={4} lg={4} md={4} xs={12}>
                      <Typography style={{ textAlign: "center", fontSize: 17 }}>
                        under Successor
                      </Typography>
                      <Paper style={{ padding: 20, margin: 10 }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <FormControlLabel control={<Checkbox />} label="" />
                          <div>
                            <Typography
                              style={{
                                color: "#007afc",
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Name XXXX
                            </Typography>
                            <Typography>Developer 1</Typography>
                          </div>
                        </div>
                      </Paper>
                      <Paper style={{ padding: 20, margin: 10 }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <FormControlLabel control={<Checkbox />} label="" />
                          <div>
                            <Typography
                              style={{
                                color: "#007afc",
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Name XXXX
                            </Typography>
                            <Typography>Developer 2</Typography>
                          </div>
                        </div>
                      </Paper>
                      <Paper style={{ padding: 20, margin: 10 }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <FormControlLabel control={<Checkbox />} label="" />
                          <div>
                            <Typography
                              style={{
                                color: "#007afc",
                                fontSize: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Name XXXX
                            </Typography>
                            <Typography>Developer 3</Typography>
                          </div>
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.nameAttribute}>
                    Performance potential
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xl={3} lg={3} md={3} xs={12}>
                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        Perform {val.potential[val.potential.length - 1].year}
                        <GradeIcon style={{ paddingBottom: 5 }} />
                      </Typography>
                      <Paper className={classes.textPerform}>
                        {val.potential[val.potential.length - 1].grade}
                      </Paper>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} xs={12}>
                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        Perform {val.potential[val.potential.length - 2].year}
                      </Typography>
                      <Paper className={classes.textPerform}>
                        {val.potential[val.potential.length - 2].grade}
                      </Paper>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} xs={12}>
                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        Perform {val.potential[val.potential.length - 3].year}
                      </Typography>
                      <Paper className={classes.textPerform}>
                        {val.potential[val.potential.length - 3].grade}
                      </Paper>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} xs={12}>
                      <Grid container direction="row">
                        <Grid item xl={12} lg={12} md={12} xs={12}>
                          <Typography
                            style={{ textAlign: "center", fontSize: 17 }}
                          >
                            Potential{" "}
                            {val.potential[val.potential.length - 1].year}{" "}
                            <GradeIcon style={{ paddingBottom: 5 }} />
                          </Typography>
                          <Paper
                            style={{
                              textAlign: "center",
                              padding: 10,
                              margin: 20,
                              fontSize: 45,
                              fontWeight: "bold",
                              color: "#2e6e12",
                            }}
                          >
                            {val.potential[val.potential.length - 1].potential}
                          </Paper>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} xs={12}>
                          <Typography
                            style={{ textAlign: "center", fontSize: 17 }}
                          >
                            Potential{" "}
                            {val.potential[val.potential.length - 2].year}
                          </Typography>
                          <Paper
                            style={{
                              textAlign: "center",
                              padding: 10,
                              margin: 20,
                              marginTop: 13,
                              fontSize: 45,
                              fontWeight: "bold",
                              color: "#2e6e12",
                            }}
                          >
                            {val.potential[val.potential.length - 2].potential}
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
    </div>
  );
};

export default ShowInformation;
