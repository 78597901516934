import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  Container,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import ListIcon from '@mui/icons-material/List';

import { Link, useLocation } from "react-router-dom";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import clsx from "clsx";
import dayjs from 'dayjs';

import { DoneAll } from "@mui/icons-material";

import DialogChangeStatus from "./dialogChangeStatus";
import SentManpower from "./sentManpower";
import CardManpower from "./cardManpower";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

import { useTranslation } from "react-i18next";
import { getAllUsersByIdVendor } from "../../../../../actions/employee";
import { updateStatusManpower } from "../../../../../actions/manpower";
import { getJobGroup } from "../../../../../actions/jobRecruit";
import DialogExcel from "./dialogExcel";
import DialogLinkRegisterManpower from "./dialogLinkRegisterManpower";
import { stringToColor } from "../../../../../utils";
import MenuUser from "../homePage/menuUser";

const StyledRoot = styled("div")({
  background: "#FFFFFF !important",
  "& .header-container": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .part-total": {
      textAlign: "right",
    }
  },
  "& .part-add-manpower": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "16px",
    "& .search-name": {
      width: 250,
    }
  },
  "& .expertise": {
    whiteSpace: "nowrap",
    fontSize: 14,
    fontWeight: 600,
  },
  "& .column-name": {
    display: "flex",
    alignItems: "center",
    "& .MuiAvatar-root": {
      width: 40,
      height: 40,
      marginRight: 8,
    }
  },
  "& .btn-save": {
    display: "flex",
    justifyContent: "flex-end",
  },
})



const StyledTable = styled(TableContainer)({
  minHeight: 600,
  maxHeight: 600,
  backgroundColor: "#ffffff",
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  "& .MuiTableRow-root": {
    "& .MuiTableCell-root": {
      backgroundColor: "#ffffff",
      borderBottom: "none",
      zIndex: 2,
    },
    "& .StickyColumn": {
      position: "sticky",
      left: 0,
      zIndex: 4,
      boxShadow: "rgb(238 238 238)",

    }
  },
  "& .MuiTableHead-root": {
    "& .MuiTableCell-root": {
      color: "#ffffff",
      backgroundColor: "#800080",
      "&:last-of-type": {
        borderTopRightRadius: 8,
        /* borderBottomRightRadius: 8, */
      },
    },
    "& .StickyHeaderColumn": {
      zIndex: 5,
      boxShadow: "rgb(238 238 238) ",
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "& .column-name": {
    display: "flex",
    alignItems: "center",
    "& .MuiAvatar-root": {
      width: 40,
      height: 40,
      marginRight: 8,
    }
  }
})

const StyledChipFree = styled(Chip)({
  borderRadius: 4,
  height: "unset",
  paddingTop: 4,
  paddingBottom: 4,
  fontSize: 14,
  backgroundColor: "#76d576",
  color: "#0b5f10",
  "&:hover": {
    backgroundColor: "#76d576",
    color: "#0b5f10",
  }
})

const StyledChipWait = styled(Chip)({
  borderRadius: 4,
  height: "unset",
  paddingTop: 4,
  paddingBottom: 4,
  backgroundColor: "#fff0ce",
  color: "#ff9800",
  fontSize: 14,
  "&:hover": {
    backgroundColor: "#fff0ce",
    color: "#ff9800",
  }
})

const StyledChipNotFree = styled(Chip)({
  borderRadius: 4,
  height: "unset",
  paddingTop: 4,
  paddingBottom: 4,
  backgroundColor: "#eb7d7d",
  color: "#b81a1a",
  fontSize: 14,
  "&:hover": {
    backgroundColor: "#eb7d7d",
    color: "#b81a1a",
  }
})

const StyledFormControl = styled(FormControl)({
  width: "300px",
  marginLeft: "10px",
  "& .MuiInputBase-root": {
    lineHeight: "1.4375em",
    borderRadius: "8px",
    "& .MuiSelect-select": {
      padding: "13.5px 14px",
      color: "#000000b8"
    }
  }
})

const ManpowerComponent = (props) => {
  const {t,i18n} = useTranslation();
  const dispatch = useDispatch();
  const { allUsersFree, isFetching, isNotification, isOpenRegisterManpower, setIsOpenRegisterManpower } = props;
  const location = useLocation();
  const refCandidate = useRef(null);
  const { result: AllJobGroups } = useSelector(state => state.jobRecruitGroup);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [jobGroup, setJobGroup] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [listSelected, setListSelected] = useState([]);
  const [openDialogChangeStatus, setOpenDialogChangeStatus] = useState({ status: false, idManpowerStatus: null, idManpower: null });

  const [isOpenModalImportExcel, setIsOpenModalImportExcel] = useState(false);
  const [dialogLinkRegisterManpowerConfig, setDialogLinkRegisterManpowerConfig] = useState({ isOpen: false, isOpenRegisterManpower: isOpenRegisterManpower });


  useEffect(() => {
    if (location.state && location.state.id && location.state.type) {
      setIsSelected(true);
    }
  }, []);

  useEffect(() => {
    if (isNotification) {
      refCandidate.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start"
      });
    }
  }, [isNotification]);

  const handleCloseDialog = () => {
    setOpenDialogChangeStatus({ status: false, idManpowerStatus: null, idManpower: null });
  };

  const handleChangeStatus = async (idManpower, idManpowerStatus) => {
    let result = await dispatch(updateStatusManpower({ idManpower: idManpower, idManpowerStatus: idManpowerStatus }));
    if (result && result.message === "success") {
      handleCloseDialog();
      dispatch(getAllUsersByIdVendor());
    }
  };

  const Displayage = (date) => {
    let month = dayjs().diff(date, 'month');
    return `${Math.floor(month / 12)} ${t("Year")}${month % 12 > 0 ? ` ${month % 12} ${t("Month")}` : ''}`;
  };

  const StatusCandidate = ({ idManpowerStatus   }) => {
    if (idManpowerStatus === 1) {
      return <StyledChipFree label={t("Successfully")} />
    } else if (idManpowerStatus === 2) {
      return <StyledChipNotFree label={t("Unsuccessful")} />
    } else if (idManpowerStatus === 3) {
      return <StyledChipWait label={t("Pending")} />
    } else if (idManpowerStatus === 4) {
      return <StyledChipWait label={t("WaitingForInterview")} />
    } else if (idManpowerStatus === 5) {
      return <StyledChipWait label={t("WaitingInterviewResults")} />
    } else if (idManpowerStatus === 6) {
      return <StyledChipFree label={t("Available")} />
    } else if (idManpowerStatus === 7) {
      return <StyledChipNotFree label={t("NotAvailable")} />
    } else if (idManpowerStatus === 8) {
      return <StyledChipNotFree label={t("Cancel")} />
    } else if (idManpowerStatus === 9) {
      return <StyledChipNotFree label={t("NotScheduledForInterview")} />
    }
    // if(idManpowerStatus === 3) {
    //   return <StyledChipWait label="รอดำเนินการ" />
    // } else if(idManpowerStatus === 6) {
    //   return (
    //     <Tooltip
    //       title={
    //         <Box style={{ display: "flex", padding: "5px 10px" }}>
    //           <StyledChipNotFree
    //             label="ไม่ว่าง"
    //             onClick={() => setOpenDialogChangeStatus({ status: true, idManpowerStatus: 7, idManpower })}
    //             />
    //         </Box>
    //       }
    //       placement="top"
    //     >
    //       <StyledChipFree label="ว่าง" />
    //     </Tooltip>
    //   )
    // } else if(idManpowerStatus === 7) {
    //     return (
    //       <Tooltip
    //         title={
    //           <Box style={{ display: "flex", padding: "5px 10px" }}>
    //             <StyledChipFree
    //               label="ว่าง"
    //               style={{
    //                 marginRight: (idManpowerStatus !== 6 && idManpowerStatus !== 7) ? "16px" : 0
    //               }}
    //               onClick={() => setOpenDialogChangeStatus({ status: true, idManpowerStatus: 6, idManpower })}
    //             />
    //           </Box>
    //         }
    //         placement="top"
    //       >
    //         <StyledChipNotFree label="ไม่ว่าง" />
    //       </Tooltip>
    //     )
    // } else {
    //   return <StyledChipWait label="รอดำเนินการ" />
    // }
  }

  const columns = [
    {
      field: "name",
      headerName: t("FullName"),
      width: 250,
      disableColumnMenu: true,
      format: (params) => (
        <Box className="column-name" component={Link} to={`/manage/hunter/peer/profile/${params.idManpower}`} style={{ textDecoration: "none", color: "black" }}>
          <Avatar sx={{ bgcolor: stringToColor(`${params.name} ${params.lastname}`) }} children={`${params.name[0]}${params.lastname[0]}`} />
          <Typography sx={{ color: "#000000" }}>{params ? `${params.name} ${params.lastname}` : '-'}</Typography>
        </Box>
      )
    },
    {
      field: "status",
      headerName: t("Status"),
      width: 150,
      disableColumnMenu: true,
      format: (params) => (
        <StatusCandidate
          idManpowerStatus={params.idManpowerStatus}
          idManpower={params.idManpower}
        />
      )
    },
    {
      field: "prevPositionName",
      headerName: t("LatestPosition"),
      width: 250,
      disableColumnMenu: true,
      format: (params) => (
        <Typography sx={{ color: "#000000" }}>{params.prevJob && params.prevJob.lastPositionName || "-"}</Typography>
      )
    },
    {
      field: "prevJobGroupName",
      headerName: t("LatestJobGroup"),
      width: 250,
      disableColumnMenu: true,
      format: (params) => (
        <Typography sx={{ color: "#000000" }}>{params.prevJob && params.prevJob.lastJobGroupName || "-"}</Typography>
      )
    },
    {
      field: "age",
      headerName: t("Age"),
      width: 150,
      disableColumnMenu: true,
      format: (params) => (
        <Typography sx={{ color: "#000000" }}>
          {params && params["dateOfBirth"] ? Displayage(params["dateOfBirth"]) : "ไม่ระบุอายุ"}
        </Typography>
      )
    },
    {
      field: "experienceYear",
      headerName: t("YearOfService"),
      width: 150,
      disableColumnMenu: true,
      format: (params) => (
        // <Typography>{params && params['OESY'] ? `${params['OESY']} ปี` : 'ไม่ระบุอายุงาน'}</Typography>
        // <Typography>{params && params['OESY(Y)'] && `${params['OESY(Y)']} ปี`} {params && params['OESY(M)'] && `${params['OESY(M)']} เดือน`} {params && !params['OESY(Y)'] && !params['OESY(M)'] && "ไม่ระบุอายุงาน"}</Typography>
        <Typography sx={{ color: "#000000" }}>{params['OESY(Y)'] && `${params['OESY(Y)']} ปี`} {params['OESY(M)'] && `${params['OESY(M)']} เดือน`} {params && !params['OESY(Y)'] && !params['OESY(M)'] && "ไม่มีประสบการณ์ทำงาน"}</Typography>
      )
    },
    {
      field: "jobGroup",
      headerName: t("JobGroup"),
      width: 300,
      disableColumnMenu: true,
      format: (params) =>
        params.jobGroups.length > 0 ? (
          params.jobGroups.map((job) => <Chip color="primary" sx={{ marginRight: 1 }} key={job.idJobGroup} label={job.jobGroupName} />)
        ) : (
          <Typography className="expertise" sx={{ color: "#000000" }}>{"-"}</Typography>
        ),
    },
    // {
    //   field: "expertise",
    //   headerName: "ความเชี่ยวชาญ",
    //   width: 300,
    //   disableColumnMenu: true,
    //   format: (params) => (
    //     <Typography className="expertise">
    //       {params && params.expertiseName || "-"}
    //     </Typography>
    //   )
    // }
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeJobGroup = (e) => {
    const valueSelect = e.target.value
    if (valueSelect.includes("all")) {
      setJobGroup([])
    } else {
      setJobGroup(typeof valueSelect === 'string' ? valueSelect.split(',') : valueSelect)
    }
  }

  const handleCheckedSelected = (idManpower) => {
    let result = false;
    listSelected.map((manpower) => {
      if (manpower.idManpower === idManpower) {
        result = true;
        return;
      }
    });
    return result;
  };

  const handleChangeCheckbox = (e, value) => {
    if (!e.target.checked) {
      setListSelected([...listSelected.filter(manpower => manpower.idManpower !== value.idManpower)]);
    } else {
      setListSelected([...listSelected, {
        idManpower: value.idManpower,
        name: value.name,
        lastname: value.name,
        telephoneMobile: value.telephoneMobile
      }])
    }
  }

  return (
    <Fragment>
      <div className="part-add-manpower">
        <div>
          <TextFieldTheme sx={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }}
            className="search-name"
            placeholder={`${t("Search")}${t("FirstName")}...`}
            label={`${t("Search")}${t("FirstName")}${t("Employee")}`}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <StyledFormControl sx={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }}>
            <InputLabel id="demo-multiple-chip-label">{t("SearchByJobGroup")}</InputLabel>
            <Select
              multiple
              value={jobGroup}
              onChange={handleChangeJobGroup}
              input={<OutlinedInput label={t("SearchByJobGroup")} />}
              renderValue={(selected) => selected.join(', ')}
            >
              <MenuItem value={"all"}>
                <ListItemText primary={t("AllJobGroups")} />
              </MenuItem>
              {AllJobGroups && AllJobGroups.map((job) => (
                <MenuItem key={job.idJobGroup} value={job.idJobGroup}>
                  <Checkbox checked={jobGroup.indexOf(job.idJobGroup) > -1} />
                  <ListItemText primary={job.jobGroupName} />
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </div>
        {!isSelected &&
          <Box display="flex" gap="8px">
            <ButtonBlue
              variant="contained"
              startIcon={<i className="fa-regular fa-plus"></i>}
              component={Link}
              to="/manage/hunter/peer/form"
            >
              {t("Add")}{t("AppMenu.Manpower")}
            </ButtonBlue>
            <ButtonBlue variant="contained" startIcon={<i className="fa-regular fa-up"></i>} onClick={() => { setIsOpenModalImportExcel(true) }} style={{ textTransform: "none" }}>{t("Upload")} Excel</ButtonBlue>
            {isOpenModalImportExcel && (
              <DialogExcel
                open={isOpenModalImportExcel}
                onClose={() => { setIsOpenModalImportExcel(false) }}
              />
            )}
            <ButtonBlue variant="contained" startIcon={<i className="fa-regular fa-link"></i>} onClick={() => { setDialogLinkRegisterManpowerConfig({ isOpen: true }) }} style={{ textTransform: "none" }}>{t("PersonnelRecruitmentLink")}</ButtonBlue>
            {dialogLinkRegisterManpowerConfig.isOpen && (
              <DialogLinkRegisterManpower
                open={dialogLinkRegisterManpowerConfig.isOpen}
                onClose={() => { setDialogLinkRegisterManpowerConfig({ isOpen: false }) }}
                isOpenRegisterManpower={isOpenRegisterManpower}
                setIsOpenRegisterManpower={setIsOpenRegisterManpower}
              />
            )}
          </Box>
        }
      </div>
      <StyledTable>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {isSelected &&
                <TableCell
                  className="StickyColumn StickyHeaderColumn"
                  style={{
                    boxShadow: "none",
                    borderTopLeftRadius: "8px !important",
                    borderBottomLeftRadius: "8px !important"
                  }}
                ></TableCell>
              }
              {columns.map((column, index) => (
                <TableCell
                  key={column.field}
                  align={column.align}
                  style={{
                    minWidth: column.width,
                    maxWidth: column.width,
                    width: column.width,
                    left: index === 0 ? isSelected ? 74 : 0 : 20,
                    borderTopLeftRadius: index === 0 ? isSelected ? 0 : 8 : 0,
                    borderBottomLeftRadius: index === 0 ? isSelected ? 0 : 0 : 0
                  }}
                  className={clsx({ "StickyColumn StickyHeaderColumn": column.field === "name" })}
                >
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(isFetching) &&
              <TableRow style={{ height: "525px" }}>
                <TableCell align="center">{t("LoadingData")}</TableCell>
                <TableCell align="center" colSpan={6}>
                  {t("LoadingData")}
                </TableCell>
              </TableRow>
            }
            {(!isFetching && allUsersFree && allUsersFree.length <= 0) &&
              <TableRow style={{ height: "525px" }}>
                <TableCell align="center" sx={{ color: "#ffffff" }}>{t("NoEmployee")}</TableCell>
                <TableCell align="center" sx={{ color: "#ffffff" }} colSpan={6}>
                  {t("NoEmployee")}
                </TableCell>
              </TableRow>
            }
            {(!isFetching && allUsersFree.length > 0) && allUsersFree
              .filter((user) =>
                jobGroup.length === 0
                  ? true
                  : user.jobGroups.some((item) => jobGroup.includes(item.idJobGroup))
              )
              .filter(item => (item.name.includes(search) || item.lastname.includes(search)))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.idManpower}>
                  {isSelected &&
                    <TableCell className="StickyColumn">
                      <Checkbox
                        checked={handleCheckedSelected(row.idManpower)}
                        onChange={(e) => handleChangeCheckbox(e, row)}
                        disabled={row.idManpowerStatus !== 6}
                      />
                    </TableCell>
                  }
                  {columns
                    .map((column, index) => (
                      <TableCell
                        key={column.field}
                        align={column.align}
                        style={{
                          left: index === 0 ? isSelected ? 74 : 0 : "initial"
                        }}
                        className={clsx({ "StickyColumn": column.field === "name" })}
                      >
                        {
                          column.format
                            ? column.format(row)
                            : row[column.field]
                        }
                      </TableCell>
                    ))}
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </StyledTable>
      <div
        style={{
          width: "100%",
          display: "flex",
          position: "relative",
          justifyContent: "flex-end",
          margin: "20px 0 40px",
          alignItems: "center"
        }}
        ref={refCandidate}
      >
        <TablePagination
          rowsPerPageOptions={[2, 10, 25, 100]}
          component="div"
          count={allUsersFree ? allUsersFree.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${
                            count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
        />
      </div>
      {openDialogChangeStatus.status &&
        <DialogChangeStatus
          openDialog={openDialogChangeStatus}
          onClose={handleCloseDialog}
          handleSubmit={handleChangeStatus}
        />
      }
      {isSelected &&
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ButtonBlue
            component={Link}
            to={{
              pathname: location.state.type === "job" ? `/company/request/${location.state.id}` : `bidding/${location.state.id}`,
              state: {
                listSelected: listSelected
              }
            }}
            variant="contained"
            startIcon={<DoneAll />}
          >
            {t("ConfirmSelection")}
          </ButtonBlue>
        </div>
      }
    </Fragment>
  );
};

const VendorManpowerPage = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isFetching: isFetchingAllUsers, result: AllUsers } = useSelector(state => state.users);
  const { result: allUsersFreeStore } = useSelector(state => state.users)
  const vendorNotification = null
  const [allUsersFree, setAllUsersFree] = useState([]);
  const [allUsersWorking, setAllUsersWorking] = useState([]);
  let isEnding = false;
  const [isOpenRegisterManpower, setIsOpenRegisterManpower] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null)

  // useEffect(() => {
  //   dispatch(getAllUsersByIdVendor()).then(res => {
  //     if (res.status === 200) {
  //       setIsOpenRegisterManpower(res.data.setting.isOpenRegisterManpower)
  //     }
  //   });
  //   dispatch(getJobGroup());
  // }, []);

  // console.log(AllUsers);

  useEffect(() => {
    let isMounted = true;
  
    dispatch(getAllUsersByIdVendor()).then(res => {
      if (isMounted) {
        if (res.status === 200) {
          setIsOpenRegisterManpower(res.data.setting.isOpenRegisterManpower);
          // console.log(res.data);
        }
      }
    });
  
    dispatch(getJobGroup());
  
    return () => {
      isMounted = false; 
    };
  }, []);
  

  useEffect(() => {
    if (
      AllUsers && AllUsers.message && AllUsers.message === "success" &&
      AllUsers.data && AllUsers.data.length > 0 /* && vendorNotification  */
    ) {
      let tempAllFreeUsers = [];
      let tempAllWorkingUser = [];
      let listCandidateSuccess = [
        ...vendorNotification && vendorNotification.listCandidates && vendorNotification.listCandidates.length > 0 ?
          vendorNotification.listCandidates.map((candidate) => candidate.idManpower) : []
      ];
      AllUsers.data.map((manpower) => {
        if (manpower.idManpowerStatus === 2) {
          tempAllWorkingUser.push({
            ...manpower, isRead:
              listCandidateSuccess.includes(manpower.idManpower) ? 0 : 1
          });
        } else {
          tempAllFreeUsers.push({ ...manpower });
        }
      });
      setAllUsersFree([...tempAllFreeUsers]);
      setAllUsersWorking([...tempAllWorkingUser]);
    } else {
      setAllUsersFree([]);
      setAllUsersWorking([]);
    }
  }, [AllUsers, vendorNotification]);


  // const testfunc = () => {
  //   console.log(allUsersWorking);
  // };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div className="btn-save">
          <ButtonBlue
            variant="outlined"
            onClick={(event) => { setAnchorEl(event.currentTarget) }}
          >
            <ListIcon sx={{ fontSize: "30px" }} />
            &nbsp;
            {("เมนูรายการ")}
          </ButtonBlue>
          <MenuUser
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
          />
        </div>
        <div className="header-container">
          <Typography variant="h4" sx={{ color: "#000000" }} marginTop={2}>กำลังพล</Typography>
          <div className="part-total">
            <Typography variant="h4" sx={{ color: "#000000" }} marginTop={2} >{allUsersFree ? allUsersFree.length : 0}</Typography>
            <Typography color="text.secondary">{`กำลังพลทั้งหมด`}</Typography>
          </div>
        </div>
        {/* <ManpowerComponent
          allUsersFree={allUsersFree || []}
          isFetching={isFetchingAllUsers}
          isNotification={location.state ? location.state.id : null}
          isOpenRegisterManpower={isOpenRegisterManpower}
          setIsOpenRegisterManpower={setIsOpenRegisterManpower}
        /> */}
        <CardManpower 
        allUsersFree={allUsersFree || []}
        isOpenRegisterManpower={isOpenRegisterManpower}
        setIsOpenRegisterManpower={setIsOpenRegisterManpower}
        // candidate={allUsersWorking} 
        />
        
        {!(location.state && location.state.id && location.state.type) && (
          <SentManpower candidate={allUsersWorking} />
        )}

        {/* <CardManpower 
        allUsersFree={allUsersFree || []}
        isOpenRegisterManpower={isOpenRegisterManpower}
        setIsOpenRegisterManpower={setIsOpenRegisterManpower}
        // candidate={allUsersWorking} 
        /> */}

        {/* <div onClick={testfunc}>Test</div> */}

      </Container>
    </StyledRoot>
  )
}

export default VendorManpowerPage;