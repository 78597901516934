import React from "react";
import { useSelector } from "react-redux";

import ManagerEmployee from "../manager/employee/employeePage";
import AdminEmployee from "../admin/employee/employeePage";

const EmployeesPage = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <div>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ? (
          <AdminEmployee />
        ) : (
          <ManagerEmployee />
        )
      ) : null}
    </div>
  );
};

export default EmployeesPage;
