import React, { useEffect, useRef } from "react";
import { OrgChart } from "d3-org-chart";
import ReactDOMServer from "react-dom/server"
import NodeContent from "./NodeContent";
import { Box, IconButton, } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { PDFDocument, values } from "pdf-lib";
import html2canvas from 'html2canvas';
import LabelDotted from "./LabelDotted";
import { ServerStyleSheets } from '@mui/styles';
import dayjs from "dayjs";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

const d3 = {
  max: (values, valueof) => {
    let max;
    if (valueof === undefined) {
      for (const value of values) {
        if (value != null
            && (max < value || (max === undefined && value >= value))) {
          max = value;
        }
      }
    } else {
      let index = -1;
      for (let value of values) {
        if ((value = valueof(value, ++index, values)) != null
            && (max < value || (max === undefined && value >= value))) {
          max = value;
        }
      }
    }
    return max;
  },
  sum: (values, valueof) => {
    let sum = 0;
    if (valueof === undefined) {
      for (let value of values) {
        if (value = +value) {
          sum += value;
        }
      }
    } else {
      let index = -1;
      for (let value of values) {
        if (value = +valueof(value, ++index, values)) {
          sum += value;
        }
      }
    }
    return sum;
  },
  cumsum: (values, valueof) => {
    var sum = 0, index = 0;
    return Float64Array.from(values, valueof === undefined
      ? v => (sum += +v || 0)
      : v => (sum += +valueof(v, index++, values) || 0));
    }
}

const OrgChartComponent = (props, ref) => {

  const { data } = props;

  const d3Container = useRef(null);
  let chart = null;

  const onNodeClick = props.onNodeClick;

  useEffect(() => {
    if (data.length > 0 && d3Container.current) {

      chart = new OrgChart();
      chart.layoutBindings = {
        ...chart.layoutBindings(),
        "top": {
          ...chart.layoutBindings().top,
          "nodeFlexSize": ({ height, width, siblingsMargin, childrenMargin, state, node, compactViewIndex }) => {
            if(node.flexCompactDim) {
                const result = [node.flexCompactDim[0], node.flexCompactDim[1]]
                return result;
            };
            return [width + siblingsMargin, height + childrenMargin];
          },
        }
      }

      chart.calculateCompactFlexDimensions = (root) => {
        const attrs = chart.getChartState();
        root.eachBefore(node => {
          node.firstCompact = null;
          node.compactEven = null;
          node.flexCompactDim = null;
          node.firstCompactNode = null;
        })
        root.eachBefore(node => {
          if (node.data.nodeType === "POSITION" && node.children && node.children.length > 1) {
            const compactChildren = node.children
                .filter(d => !d.children)

            if (compactChildren.length < 2) return;
            compactChildren.forEach((child, i) => {
              if (!i) child.firstCompact = true;
              if (i % 2) child.compactEven = false;
              else child.compactEven = true;
              child.row = Math.floor(i / 2);
            })
            const evenMaxColumnDimension = d3.max(compactChildren.filter(d => d.compactEven), attrs.layoutBindings[attrs.layout].compactDimension.sizeColumn);
            const oddMaxColumnDimension = d3.max(compactChildren.filter(d => !d.compactEven), attrs.layoutBindings[attrs.layout].compactDimension.sizeColumn);
            const columnSize = Math.max(evenMaxColumnDimension, oddMaxColumnDimension) * 2;
            const rowsMapNew = chart.groupBy(compactChildren, d => d.row, reducedGroup => d3.max(reducedGroup, d => attrs.layoutBindings[attrs.layout].compactDimension.sizeRow(d) + attrs.compactMarginBetween(d)));
            const rowSize = d3.sum(rowsMapNew.map(v => v[1]))
            compactChildren.forEach(node => {
              node.firstCompactNode = compactChildren[0];
              if (node.firstCompact) {
                node.flexCompactDim = [
                  columnSize + attrs.compactMarginPair(node),
                  rowSize - attrs.compactMarginBetween(node)
                ];
              } else {
                node.flexCompactDim = [0, 0];
              }
            })
            node.flexCompactDim = null;
        }
        })
      }

      chart.calculateCompactFlexPositions = (root) => {
        const attrs = chart.getChartState();
        root.eachBefore(node => {
          if (node.children) {
            const compactChildren = node.children.filter(d => d.flexCompactDim);
            const fch = compactChildren[0];
            if (!fch) return;
            compactChildren.forEach((child, i, arr) => {
              if (i == 0) fch.x -= fch.flexCompactDim[0] / 2;
              if (i & i % 2 - 1) child.x = fch.x + fch.flexCompactDim[0] * 0.25 - attrs.compactMarginPair(child) / 4;
              else if (i) child.x = fch.x + fch.flexCompactDim[0] * 0.75 + attrs.compactMarginPair(child) / 4;
            })
            const centerX = fch.x + fch.flexCompactDim[0] * 0.5;
            fch.x = fch.x + fch.flexCompactDim[0] * 0.25 - attrs.compactMarginPair(fch) / 4;
            const offsetX = node.x - centerX;
            if (Math.abs(offsetX) < 10) {
              compactChildren.forEach(d => d.x += offsetX);
            }

            const rowsMapNew = chart.groupBy(compactChildren, d => d.row, reducedGroup => d3.max(reducedGroup, d => attrs.layoutBindings[attrs.layout].compactDimension.sizeRow(d)));
            const cumSum = d3.cumsum(rowsMapNew.map(d => d[1] + attrs.compactMarginBetween(d)));
            compactChildren
              .forEach((node, i) => {
                if (node.row) {
                    node.y = fch.y + cumSum[node.row - 1]
                } else {
                    node.y = fch.y;
                }
              })
          }
        })
      }

      chart
        .container(d3Container.current)
        .data(data)
        .rootMargin(100)
        .compact(true)
        .siblingsMargin((node) => 36)
        .neightbourMargin((node1, node2) => 18)
        .childrenMargin((node) => {
          return 36
        })
        .compactMarginBetween((node => 9))
        .compactMarginPair((node => 18))
        .nodeWidth((d) => {
          if (d.data.nodeType === "EMPLOYEE") {
            if (d.data.employeeList.length > 2) {
              return 766
            } else if (d.data.employeeList.length > 1) {
              return 508
            } else if (d.data.employeeList.length > 0) {
              return 250
            }
          } else {
            return 250
          }
        })
        .nodeHeight((d) => {
          if (d.data.nodeType === "POSITION") {
            return 40
          } 
          else if (d.data.nodeType === "EMPLOYEE") {
            const row = Math.floor(d.data.employeeList.length / 3) + 1;
            return ((row * 52) + (row - 1) * 8)
          } 
          else {
            return 148
          }
        })
        .onNodeClick((node, d, i, arr) => {
          onNodeClick(node);
        })
        .nodeContent((node, i, arr, state) => {
          const sheets = new ServerStyleSheets();
          return ReactDOMServer.renderToStaticMarkup(
            sheets.collect(<NodeContent node={node} />)
          )
        })
        .buttonContent(({ node, state }) => {
          const icons = {
            "left": d => d ? `<div style="margin-top:-10px;line-height:1.2;font-size:25px;height:22px">‹</div>` : `<div style="margin-top:-10px;font-size:25px;height:23px">›</div>`,
            "bottom": d => d ? `<div style="margin-top:-20px;font-size:25px">ˬ</div>` : `<div style="margin-top:0px;line-height:1.2;height:11px;font-size:25px">ˆ</div>`,
            "right": d => d ? `<div style="margin-top:-10px;font-size:25px;height:23px">›</div>` : `<div style="margin-top:-10px;line-height:1.2;font-size:25px;height:22px">‹</div>`,
            "top": d => d ? `<div style="margin-top:0px;line-height:1.2;height:11px;font-size:25px">ˆ</div>` : `<div style="margin-top:-20px;font-size:25px">ˬ</div>`,
          }
          return `<div style="border-radius:3px;padding:3px;font-size:10px;margin:auto auto;background-color:lightgray"> ${icons[state.layout](node.children)}  </div>`
        })
        .render();
    }

    return () => {
      while (d3Container && d3Container.current && d3Container.current.firstChild) {
        d3Container.current.removeChild(d3Container.current.firstChild);
      }
    }
  }, [data, d3Container.current])

  const downloadPdf = async () => {

    html2canvas(document.getElementById('d3-org-chart-container-capture'), {
      allowTaint: false,
      useCORS: true,
      scale: 2,
      y: -80,
      foreignObjectRendering: true,
    }).then(async canvas => {
      console.log(canvas.toDataURL('image/png'))
      const imgData = canvas.toDataURL("image/png");
      const pdf = await PDFDocument.create();
      const page = pdf.addPage();
      const pngImage = await pdf.embedPng(imgData);
      const pageSize = page.getSize();
      const pngDims = pngImage.scaleToFit(pageSize.width, pageSize.height);
      console.log(pageSize)
      console.log(pngDims)
      page.drawImage(pngImage, {
        x: 0,
        y: pageSize.height - pngDims.height,
        width: pngDims.width,
        height: pngDims.height,
      });
      const pdfBytes = await pdf.save();
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      const bytes = new Uint8Array(pdfBytes);
      const blob = new Blob([bytes], { type: "application/pdf" });
      const docUrl = window.URL.createObjectURL(blob);
      a.href = docUrl;

      a.download = "โครสร้างองค์กร " + dayjs().format("DD MMMM BBBB HH.mmน.") + ".pdf";
      a.click();
      window.URL.revokeObjectURL(docUrl);
    })
  };

  return (
    <div style={{ position: "relative", height: "calc(100vh - 80px)" }}>
      <div
        id="d3-org-chart-container-capture"
        style={{
          height: "100%",
          background: "#f1f4f9"
        }}
      >
        {/* <Box position="absolute" top="16px" left="16px">
          <Box display="flex" gap="16px" flexWrap="wrap" style={{ userSelect: "none" }}>
            <LabelDotted color="#d50000" text="CEO Office" />
            <LabelDotted color="#6C0BA9" text="Business Unit" />
            <LabelDotted color="#e65100" text="Division" />
            <LabelDotted color="#0091ea" text="Department" />
            <LabelDotted color="#4caf50" text="Section" />
            <LabelDotted color="#9e9e9e" text="Position" />
          </Box>

          <Box>
            <TextFieldTheme />
          </Box>
        </Box> */}
        <div ref={d3Container} />
      </div>
      <Box position="absolute" bottom="16px" right="16px">
        <IconButton onClick={() => { downloadPdf() }}>
          <DownloadIcon />
        </IconButton>
      </Box>
    </div>
  )
}

export default OrgChartComponent;