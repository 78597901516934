import { httpClient } from "./httpClient";

const getPersonalTaxByMonth = (date, idEmployees) => {
  return httpClient.get("payment/personal-tax", {
    params: { date: date, idEmployees: idEmployees },
  });
};

const getTaxSummary = (idCompany, year) => {
  return httpClient.get("payment/payrun/tax-summary", {
    params: { selectedCompany: idCompany, year: year },
  });
};

export default {
  getPersonalTaxByMonth,
  getTaxSummary,
};
