import React from "react";
import { useSelector } from "react-redux";

import AdminSuccessor from "../admin/successor";

const Successor = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ? (
          <AdminSuccessor />
        ) : null
      ) : null}
    </>
  );
};

export default Successor;
