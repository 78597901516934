import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const ChartOT = (props) => {
  const { otData } = props;

  function summarizeOTData(employees, type) {
    const summary = {};

    employees.forEach((employee) => {
      employee.schedule.forEach((schedule) => {
        if (schedule.overTime) {
          schedule.overTime.forEach((ot) => {
            const date = ot.workDate.split("T")[0]; 
            const otValue = parseFloat(ot[type]) || 0;
            summary[date] = (summary[date] || 0) + otValue / 60; 
          });
        }
      });
    });

    return Object.entries(summary).map(([date, value]) => ({
      date,
      value: parseFloat(value.toFixed(2)),
    }));
  }

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        height: 328,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      title: {
        text: "Chart OT",
        align: "left",
        offsetY: 25,
        offsetX: 20,
      },
      subtitle: {
        text: "Statistics",
        offsetY: 55,
        offsetX: 20,
      },
      markers: {
        size: 6,
        strokeWidth: 0,
        hover: {
          size: 9,
        },
      },
      grid: {
        show: true,
        padding: {
          bottom: 0,
        },
      },
      xaxis: {
        type: "datetime", 
        tooltip: {
          enabled: false,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        offsetY: -20,
      },
    },
    series: [],
  });

  useEffect(() => {
    const newSeries = [
      {
        name: "OT 1",
        data: summarizeOTData(otData, "OT_1"),
      },
      {
        name: "OT 1.5",
        data: summarizeOTData(otData, "OT_1.5"),
      },
      {
        name: "OT 2",
        data: summarizeOTData(otData, "OT_2"),
      },
      {
        name: "OT 3",
        data: summarizeOTData(otData, "OT_3"),
      },
      {
        name: "OT รวม",
        data: summarizeOTData(otData, "OT_1").map((entry) => {
          const ot1 = entry.value || 0;
          const ot15 =
            summarizeOTData(otData, "OT_1.5").find((e) => e.date === entry.date)
              .value || 0;
          const ot2 =
            summarizeOTData(otData, "OT_2").find((e) => e.date === entry.date)
              .value || 0;
          const ot3 =
            summarizeOTData(otData, "OT_3").find((e) => e.date === entry.date)
              .value || 0;

          return { date: entry.date, value: ot1 + ot15 + ot2 + ot3 };
        }),
      },
    ];

    setChartData((prevChartData) => ({
      ...prevChartData,
      series: newSeries.map((series) => ({
        name: series.name,
        data: series.data.map((item) => [item.date, item.value]), // Format data for ApexCharts
      })),
    }));
  }, [otData]);

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="line"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
};

export default ChartOT;
