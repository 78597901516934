import React from "react";
import DrawerCustom from "../../shared/general/Drawer";
import { Box, Grid, Typography, styled } from "@mui/material";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0
      }
    }
  },
  "& .option-header": {
    fontSize: "14px",
    color: "#919eab",
  }
})

const DrawerEdit = (props) => {

  const { config, onClose } = props;

  console.log(config)

  return (
    <DrawerCustom
      title="แก้ไขข้อมูล"
      anchor="right"
      open={config.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px"
        }
      }}
    >
      <StyledRoot>
        {config.value && <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>ชื่อ</Typography>
            <Typography>{config.value.name}</Typography>
          </Grid>
        </Grid>}
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerEdit;