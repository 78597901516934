import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Divider,
  Grid,
  styled,
  Typography,
  IconButton,
  Box,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DialogConfirmDelete from "../../../../shared/general/DialogConfirmDelete";

import { getCompanyOrganization } from "../../../../../../actions/company";

import {
  addDeleteEmployeePosition,
  updateEmployeePosition,
  getEmployeeProfile,
  getEmployeePositionChange,
} from "../../../../../../actions/employee";

import DialogEdit from "./DialogEdit";
import FlowForm from "../components/flowForm";
import { getAllPositions } from "../../../../../../actions/positions";
import { getLevelByidCompany } from "../../../../../../actions/level";
import { getManager } from "../../../../../../actions/manager";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName, getUserPosition } from "../../../../../../utils/userData";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: 16,
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapChange = styled("div")({
  marginTop: 8,
  "& .inner": {
    marginBottom: 8,
  },
  "& .first-line": {
    display: "flex",
    alignItems: "center",
    "& .date": {
      fontWeight: 600,
    },
  },
  "& .positions-name": {
    fontWeight: 600,
    marginLeft: 8,
  },
  "& .second-line": {
    marginLeft: 64,
    fontSize: 16,
    "&.name-line, .name-line": {
      fontWeight: 500,
    },
  },
});

const Transfer = () => {
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: employeeChangePositionList } = useSelector(
    (state) => state.employeeChange
  );

  const { t, i18n } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);
  const [mode, setMode] = useState("add");
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [disabledBtnDelete, setDisabledBtnDelete] = useState(false);
  const [disabledBtnChange, setDisabledBtnChange] = useState(false);

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {},
  });

  const [drawerEditConfig, setDrawerEditConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {},
  });

  useEffect(() => {
    if (employeeChangePositionList && employeeChangePositionList.length > 0) {
      if (
        dayjs(
          employeeChangePositionList[employeeChangePositionList.length - 1]
            .start
        ).isSameOrAfter(dayjs().toDate(), "date")
      ) {
        setDisabledBtnChange(true);
      } else {
        setDisabledBtnChange(false);
      }
    }
  }, [employeeChangePositionList]);

  const handleOpen = () => {
    setOpenDialog(true);
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: true,
      isEdit: false,
    }));
  };

  const handleClose = () => {
    setOpenDialog(false);
    setOpenDialogDelete(false);
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  useEffect(() => {
    dispatch(getCompanyOrganization());
    // dispatch(getManager());
    dispatch(getEmployeePositionChange(employeeProfile.idEmployees));
    // dispatch(getAllPositions())
    // dispatch(getLevelByidCompany());
  }, []);

  const handleEditEmployeePosition = (dataForm) => {
    setSelectedItem(dataForm);
  };

  const handleDeleteEmployeePosition = (dataForm) => {
    console.log("dataForm: ", dataForm);
    setOpenDialogDelete(true);
    setSelectedItem(dataForm);
  };

  const handleSubmit = async () => {
    setDisabledBtnDelete(true);
    let tempFormData = { ...selectedItem };
    tempFormData.method = "delete";
    tempFormData.isFirst = employeeChangePositionList.length === 1;
    tempFormData.idEmployeePositionBefore =
      employeeChangePositionList.length === 1
        ? null
        : employeeChangePositionList[employeeChangePositionList.length - 2]
            .idEmployeePosition;
    tempFormData.start = dayjs(tempFormData.start).format("YYYY-MM-DD");

    const result = await dispatch(addDeleteEmployeePosition(tempFormData));
    if (result) {
      handleClose();
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
      dispatch(getEmployeePositionChange(employeeProfile.idEmployees));
    }
  };

  return (
    <div>
      <StyledWrapTop>
        {!userProfile.readOnly && (
          <ButtonBlue
            variant="text"
            size="small"
            startIcon={<EditIcon />}
            onClick={handleOpen}
            disabled={disabledBtnChange}
          >
            {t("Transfer")}
          </ButtonBlue>
        )}
      </StyledWrapTop>
      {employeeProfile && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">
                {t("Company")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.companyName
                  ? employeeProfile.companyName
                  : "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">
                {t("BusinessUnit")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.businessUnitName
                  ? employeeProfile.businessUnitName
                  : "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">
                {t("Division")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.divisionName
                  ? employeeProfile.divisionName
                  : "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">
                {t("Department")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.departmentName
                  ? employeeProfile.departmentName
                  : "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">
                {t("Section")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.sectionName
                  ? employeeProfile.sectionName
                  : "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">
                {t("Position")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.positionName
                  ? employeeProfile.positionName
                  : "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">
                {t("JobGroup")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.jobGroupName
                  ? employeeProfile.jobGroupName
                  : "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">
                {t("JobLevel")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.jobLevelName
                  ? employeeProfile.jobLevelName
                  : "-"}
              </Typography>
            </div>
          </Grid>
        </Grid>
      )}
      <StyledDivider />
      <div>
        <StyledHeadLabel color="text.secondary">
          {t("SaveChanges")}
        </StyledHeadLabel>
        {employeeChangePositionList && (
          <StyledWrapChange>
            {employeeChangePositionList.map((item, index) => (
              <div className="inner" key={index}>
                <div className="first-line">
                  <Box display="flex" alignItems="center" flexGrow="1">
                    <div>
                      {employeeChangePositionList.length - 1 === index && !userProfile.readOnly ? (
                        <Box>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => handleDeleteEmployeePosition(item)}
                            disabled={employeeChangePositionList.length === 1}
                          >
                            <HighlightOffRoundedIcon fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDrawerEditConfig((prev) => ({
                                ...prev,
                                isOpen: true,
                                isEdit: true,
                                data: {
                                  idEmployeePosition: item.idEmployeePosition,
                                  idPosition: item.idPosition,
                                  positionName: item.positionName,
                                  idManagerLV1: item.idManagerLV1,
                                  idManagerLV2: item.idManagerLV2,
                                  start: item.start,
                                  remark: item.remark,
                                  idCompany: item.idCompany,
                                  companyName: item.companyName,
                                  businessUnitName: item.businessUnitName,
                                  divisionName: item.divisionName,
                                  departmentName: item.departmentName,
                                  sectionName: item.sectionName,
                                  managerLV1_firstname_TH: item.managerLV1_firstname_TH,
                                  managerLV1_lastname_TH: item.managerLV1_lastname_TH,
                                  managerLV1_firstname_EN: item.managerLV1_firstname_EN,
                                  managerLV1_lastname_EN: item.managerLV1_lastname_EN,
                                  managerLV1_email: item.managerLV1_email,
                                  managerLV2_firstname_TH: item.managerLV2_firstname_TH,
                                  managerLV2_lastname_TH: item.managerLV2_lastname_TH,
                                  managerLV2_firstname_EN: item.managerLV2_firstname_EN,
                                  managerLV2_lastname_EN: item.managerLV2_lastname_EN,
                                  managerLV2_email: item.managerLV2_email,
                                  description: item.description,
                                },
                              }));
                            }}
                            // disabled={employeeChangePositionList.length === 1}
                          >
                            <EditNoteRoundedIcon fontSize="inherit" />
                          </IconButton>
                        </Box>
                      ) : null}
                    </div>
                    &nbsp;
                    {item.end ? (
                      <Typography className="date" color="text.third">
                        {`[${dayjs(item.start).format(
                          i18n.resolvedLanguage === "th"
                            ? "DD MMM BBBB"
                            : "DD MMM YYYY")} - ${dayjs(item.end).format(
                          i18n.resolvedLanguage === "th"
                          ? "DD MMM BBBB"
                          : "DD MMM YYYY"
                        )}]`}
                      </Typography>
                    ) : (
                      <Typography className="date" color="text.third">
                        {`[${dayjs(item.start).format(
                          i18n.resolvedLanguage === "th"
                            ? "DD MMM BBBB"
                            : "DD MMM YYYY"
                        )} - ${t("Current")}]`}
                      </Typography>
                    )}
                    <Typography className="positions-name">
                      {getUserPosition(item)}
                    </Typography>
                  </Box>
                </div>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line"
                      gutterBottom
                    >
                      {t("Section")}:&nbsp;
                      <Typography
                        className="name-line"
                        component="span"
                        color="text.third"
                      >
                        {item.sectionName ? item.sectionName : "-"}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line"
                      gutterBottom
                    >
                      {t("Department")}:&nbsp;
                      <Typography
                        className="name-line"
                        component="span"
                        color="text.third"
                      >
                        {item.departmentName ? item.departmentName : "-"}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line"
                      gutterBottom
                    >
                      {t("Division")}:&nbsp;
                      <Typography
                        className="name-line"
                        component="span"
                        color="text.third"
                      >
                        {item.divisionName ? item.divisionName : "-"}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line"
                      gutterBottom
                    >
                      {t("Business")}:&nbsp;
                      <Typography
                        className="name-line"
                        component="span"
                        color="text.third"
                      >
                        {item.businessUnitName ? item.businessUnitName : "-"}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ margin: "16px 0" }}></div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line"
                      gutterBottom
                    >
                      {`${t("ManagerLV")} 1 `}
                    </Typography>
                    <Typography 
                      color="text.third"
                      variant="body2"
                      className="second-line name-line"
                      gutterBottom
                    >
                      {getUserFullName({
                        firstname_TH: item.managerLV1_firstname_TH,
                        lastname_TH: item.managerLV1_lastname_TH,
                        firstname_EN: item.managerLV1_firstname_EN,
                        lastname_EN: item.managerLV1_lastname_EN,
                      })}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line name-line"
                      style={{ fontSize: 14 }}
                      gutterBottom
                    >
                      {`${
                        item.managerLV1_email
                          ? `(${item.managerLV1_email})`
                          : "-"
                      }`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line"
                      gutterBottom
                    >
                      {`${t("ManagerLV")} 2`}
                    </Typography>
                    <Typography 
                      color="text.third"
                      variant="body2"
                      className="second-line name-line"
                      gutterBottom
                    >
                      {getUserFullName({
                        firstname_TH: item.managerLV2_firstname_TH,
                        lastname_TH: item.managerLV2_lastname_TH,
                        firstname_EN: item.managerLV2_firstname_EN,
                        lastname_EN: item.managerLV2_lastname_EN,
                      })}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line name-line"
                      style={{ fontSize: 14 }}
                      gutterBottom
                    >
                      {`${
                        item.managerLV2_email
                          ? `(${item.managerLV2_email})`
                          : "-"
                      }`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ margin: "16px 0" }}></div>
                  </Grid>
                </Grid>

                <Typography
                  color="text.secondary"
                  variant="body2"
                  className="second-line"
                  gutterBottom
                  style={{ fontStyle: "oblique" }}
                >
                  {`${t("Descriptions")}: ${
                    item.description ? item.description : "-"
                  }`}
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  className="second-line"
                  align="right"
                  style={{ fontStyle: "oblique" }}
                  gutterBottom
                >
                  {`${t("DateModified")}: ${
                    item.updateDate
                      ? dayjs(item.updateDate)
                          .format(
                            i18n.resolvedLanguage === "th"
                              ? "DD MMM BBBB HH:mm"
                              : "DD MMM YYYY HH:mm"
                          )
                      : "-"
                  }`}
                </Typography>
              </div>
            ))}
          </StyledWrapChange>
        )}
      </div>

      {/* <DialogEdit
        open={drawerConfig.isOpen}
        handleClose={handleClose}
        drawerConfig={drawerConfig}
      /> */}

      <FlowForm
        handleClose={handleClose}
        drawerConfig={drawerConfig}
        defaultPage="position"
      />

      <DialogEdit
        // open={drawerEditConfig.isOpen}
        drawerConfig={drawerEditConfig}
        handleClose={() => {
          setDrawerEditConfig((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }}
      />

      {selectedItem && (
        <DialogConfirmDelete
          open={openDialogDelete}
          handleClose={handleClose}
          label={`${t("EmployeePosition")}`}
          text={`${selectedItem.positionName}`}
          handleDelete={handleSubmit}
          disabledBtnDelete={disabledBtnDelete}
        />
      )}
    </div>
  );
};

export default Transfer;
