import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Grid,
  Box,
  Typography,
  Paper,
  IconButton,
  styled,
  Divider,
  Menu,
  MenuItem,
  Drawer,
} from "@mui/material";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import Swal from "sweetalert2";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DialogEditDate from "./DialogEditDate";
import DialogEditPopup from "./DialogEditPopup";
import { getKPIDate, deleteKPIDate } from "../../../../../actions/kpi";
import { useTranslation } from "react-i18next";

const GridStyled = styled(Grid)({
  backgroundColor: "#E6EFF5",
  padding: "16px",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "16px",
  height: "70px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const ContainerStyled = styled(Container)({
  paddingTop: "100px",
  minHeight: "100vh",
});

const PaperStyled = styled(Paper)({
  padding: "16px",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginTop: "16px",
});

const Row = styled(Box)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr 0.3fr", 
  justifyItems: "center",
  alignItems: "center",
  padding: "8px 0", 
  gap: "10px",
});

const TextStyled = styled(Typography)({
  fontSize: "16px",
  fontWeight: "bold",
  color: "#333",
});

const HeaderTextStyled = styled(Typography)({
  fontSize: "16px",
  fontWeight: "bold",
  color: "#666",
});

function KpiEditDate() {
  const {t,i18n} = useTranslation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogType, setDialogType] = useState(""); 
  const [isDrawerOpen, setDrawerOpen] = useState(false); 
  const [isEditPopupOpen, setEditPopupOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const kpiPlanStore = useSelector((state) => state.kpi.result);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowData(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setEditPopupOpen(true);
    handleClose();
  };

  const handleAddDate = (type) => {
    setDialogType(type);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleCloseEditPopup = () => {
    setEditPopupOpen(false);
  };

  const onDeleteKPIDate = async (idKpiEditDate) => {
    const res = await dispatch(deleteKPIDate(idKpiEditDate));
    if (res && res.status === 200) {
      Swal.fire({
        title: t("Success"),
        text: t("DateDeletionSuccess"),
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
      await dispatch(getKPIDate());
    } else if (res && res.status === 500) {
      Swal.fire({
        title: t("AnErrorOccurred"),
        text: `${t("DateDeletionFailed")}: ${res.message}`,
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };
  

  const onDelete = async (idKpiEditDate) => {
    try {
      const result = await Swal.fire({
        title: t("ConfirmKPIDeletion"),
        text: t("CannotUndo")+"!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Confirm"),
        cancelButtonText: t("Cancel"),
      });

      if (result.isConfirmed) {
        await onDeleteKPIDate(idKpiEditDate);
      }
    } catch (error) {
      console.error("Error deleting KPI:", error);
    }
  };

  useEffect(() => {
    dispatch(getKPIDate());
  }, [dispatch]);

  let addData = [];
  let editData = [];
  let gradeData = []; 

  if (kpiPlanStore && Array.isArray(kpiPlanStore)) {
    addData = kpiPlanStore.filter(item => item.type === 'add').map(item => ({
      idKpiEditDate: item.idKpiEditDate,
      period: item.dateName,
      start: item.startDate,
      end: item.endDate,
      deadline: item.createAt,
    }));

    editData = kpiPlanStore.filter(item => item.type === 'edit').map(item => ({
      idKpiEditDate: item.idKpiEditDate,
      period: item.dateName,
      start: item.startDate,
      end: item.endDate,
      deadline: item.createAt,
    }));

    gradeData = kpiPlanStore.filter(item => item.type === 'grade').map(item => ({ 
      idKpiEditDate: item.idKpiEditDate,
      period: item.dateName,
      start: item.startDate,
      end: item.endDate,
      deadline: item.createAt,
    }));
  }

  return (
    <ContainerStyled>
      <GridStyled container justifyContent="space-between" alignItems="center" mt={2}>
        <Box display="flex" alignItems="center">
          <Typography variant="h6" sx={{ ml: 1, fontSize: "30px" }}>
            {t("EditDateKPI")}
          </Typography>
        </Box>
      </GridStyled>
  
      <PaperStyled sx={{ mt: 4 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2, mt: 2 }}>
          <Box display="flex" alignItems="center">
            <CalendarTodayIcon color="primary" />
            <Typography variant="h6" sx={{ fontSize: "18px", ml: 1, color: "#007bff" }}>
              {t("KPIAddableDate")}
            </Typography>
          </Box>
          <ButtonBlue variant="contained" color="primary" sx={{ height: '35px', ml: 2 }} startIcon={<AddIcon />} onClick={() => handleAddDate("add")}>
            {t("AddDate")}
          </ButtonBlue>
        </Box>
  
        <Row>
          <HeaderTextStyled>{t("PeriodTime")}</HeaderTextStyled>
          <HeaderTextStyled>{t("StartDate")}</HeaderTextStyled>
          <HeaderTextStyled>{t("EndDate")}</HeaderTextStyled>
          <HeaderTextStyled>{t("PfEffectiveDate")}</HeaderTextStyled>
          <HeaderTextStyled></HeaderTextStyled>
        </Row>
  
        {addData.map((row, index) => (
          <Row key={index}>
            <TextStyled>{row.period}</TextStyled>
            <TextStyled>{new Date(row.start).toLocaleDateString('en-GB')}</TextStyled>
            <TextStyled>{new Date(row.end).toLocaleDateString('en-GB')}</TextStyled>
            <TextStyled>{new Date(row.deadline).toLocaleDateString('en-GB')}</TextStyled>
            <IconButton sx={{ ml: '-88px' }} onClick={(e) => handleClick(e, row)}>
              <MoreVertIcon />
            </IconButton>
          </Row>
        ))}
  
        <Divider sx={{ backgroundColor: "#e5e5e5" }} />
  
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2, mt: 2 }}>
          <Box display="flex" alignItems="center">
            <CalendarTodayIcon color="primary" />
            <Typography variant="h6" sx={{ fontSize: "18px", ml: 1, color: "#007bff" }}>
              {t("EditingPeriod")} KPI
            </Typography>
          </Box>
          <ButtonBlue variant="contained" color="primary" sx={{ height: '35px', ml: 2 }} startIcon={<AddIcon />} onClick={() => handleAddDate("edit")}>
            {t("AddDate")}
          </ButtonBlue>
        </Box>
  
        <Row>
        <HeaderTextStyled>{t("PeriodTime")}</HeaderTextStyled>
          <HeaderTextStyled>{t("StartDate")}</HeaderTextStyled>
          <HeaderTextStyled>{t("EndDate")}</HeaderTextStyled>
          <HeaderTextStyled>{t("PfEffectiveDate")}</HeaderTextStyled>
          <HeaderTextStyled></HeaderTextStyled>
        </Row>
  
        {editData.map((row, index) => (
          <Row key={index}>
            <TextStyled>{row.period}</TextStyled>
            <TextStyled>{new Date(row.start).toLocaleDateString('en-GB')}</TextStyled>
            <TextStyled>{new Date(row.end).toLocaleDateString('en-GB')}</TextStyled>
            <TextStyled>{new Date(row.deadline).toLocaleDateString('en-GB')}</TextStyled>
            <IconButton sx={{ ml: '-88px' }} onClick={(e) => handleClick(e, row)}>
              <MoreVertIcon />
            </IconButton>
          </Row>
        ))}

        <Divider sx={{ backgroundColor: "#e5e5e5" }} />
  
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2, mt: 2 }}>
          <Box display="flex" alignItems="center">
            <CalendarTodayIcon color="primary" />
            <Typography variant="h6" sx={{ fontSize: "18px", ml: 1, color: "#007bff" }}>
              {t("GradeConfirmationDate")} KPI
            </Typography>
          </Box>
          <ButtonBlue variant="contained" color="primary" sx={{ height: '35px', ml: 2 }} startIcon={<AddIcon />} onClick={() => handleAddDate("grade")}>
           {t("AddDate")}
          </ButtonBlue>
        </Box>
  
        <Row>
          <HeaderTextStyled>{t("phase")}</HeaderTextStyled>
          <HeaderTextStyled>{t("StartDate")}</HeaderTextStyled>
          <HeaderTextStyled>{t("EndDate")}</HeaderTextStyled>
          <HeaderTextStyled>{t("PfEffectiveDate")}</HeaderTextStyled>
          <HeaderTextStyled></HeaderTextStyled>
        </Row>
  
        {gradeData.map((row, index) => (
          <Row key={index}>
            <TextStyled>{row.period}</TextStyled>
            <TextStyled>{new Date(row.start).toLocaleDateString('en-GB')}</TextStyled>
            <TextStyled>{new Date(row.end).toLocaleDateString('en-GB')}</TextStyled>
            <TextStyled>{new Date(row.deadline).toLocaleDateString('en-GB')}</TextStyled>
            <IconButton sx={{ ml: '-88px' }} onClick={(e) => handleClick(e, row)}>
              <MoreVertIcon />
            </IconButton>
          </Row>
        ))}
  
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleEdit}>{t("Edit")}</MenuItem>
          <MenuItem onClick={() => onDelete(selectedRowData.idKpiEditDate)}>{t("Delete")}</MenuItem>
        </Menu>
      </PaperStyled>
  
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        sx={{ '& .MuiDrawer-paper': { width: '30%' } }}
      >
        <DialogEditDate type={dialogType} onClose={handleCloseDrawer} />
      </Drawer>

      <DialogEditPopup open={isEditPopupOpen} onClose={handleCloseEditPopup} data={selectedRowData} />
    </ContainerStyled>
  );
}

export default KpiEditDate;
