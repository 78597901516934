import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Typography,
  Container,
  styled,
  Card,
  Grid,
  LinearProgress,
  CardContent,
  FormControl, InputLabel, Select, MenuItem 
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getKPIByManager } from "../../../../../actions/kpi";
import { useHistory } from "react-router-dom";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import ExcelJS from 'exceljs';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DialogDropzone from "../DialogDropzoneKpi";
import { useTranslation } from "react-i18next";
import EmployeeSearch from "../SearchManager";
import dayjs from "dayjs";
import { getUserFirstName,getUserLastName, getUserPosition,getUserFullName } from "../../../../../utils/userData";
const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});


const ContainerStyled = styled(Container)({
  height: "100%",
  paddingTop: "95px",
  backgroundColor: "transparent",
  minHeight: "100vh",
});

const GridStyled = styled(Grid)({
  backgroundColor: "#ffff",
  padding: "16px",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "30px",
  marginTop: "-10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const ScrollableContainer = styled(Box)({
  height: "640px",
  overflowY: "auto",
  paddingRight: "16px",
});

const StyledRoot = styled("div")({
  minHeight: "100vh",
  padding: "20px 0",
  "& .MuiContainer-root": {
    paddingBottom: 16,
  },
});

const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: "#DCFAF8",
  "& .MuiLinearProgress-bar": {
    borderRadius: 5,
    backgroundColor: "#16DBCC",
  },
}));

const KpiCard = ({ data }) => {
  const history = useHistory();
 const {t,i18n} = useTranslation();
  if (!data) {
    console.error("No data provided to KpiCard");
    return null;
  }

  const totalWeight = Number(data.totalWeight || 0);
  const isOverWeight = totalWeight > 100;
  const isExactWeight = totalWeight === 100;

  const handleCardClick = () => {
    history.push("/kpi-manage-evalution", { data });
  };

  return (
    <Card
      onClick={handleCardClick}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        borderRadius: "16px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        height: "fit-content",
        justifyContent: "center",
        cursor: "pointer",
      }}
    >
      <CardContent sx={{ width: "100%", padding: "16px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2, paddingRight: "30px" }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" component="div" fontWeight="bold">
              {getUserFullName(data)}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {getUserPosition(data)}
            </Typography>
          </Box>
          <Avatar src={data.imageURL} sx={{ width: 72, height: 72 }} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1, paddingRight: "30px" }}>
          <Typography variant="body2" color="text.secondary">
            KPI {t("All")} {data.kpiCount} {t("List")}
          </Typography>
          <Typography variant="body2" color={isOverWeight ? "#FF4C4C" : isExactWeight ? "#2D9596" : "text.secondary"}>
            {t("Weight")} {totalWeight}%
          </Typography>
        </Box>
        <Box sx={{ width: "91%", mb: 1, paddingRight: "30px" }}>
          <ProgressBar variant="determinate" value={totalWeight} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "30px" }}>
          {isOverWeight && (
            <Typography variant="body2" color="#FF4C4C">
              {t("MaxWeightAllowed")} 100%
            </Typography>
          )}
          {isExactWeight && (
            <Typography variant="body2" color="#2D9596">
              {t("PlanningCompleted")}
            </Typography>
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end", flex: 1 }}>
            <Typography variant="body2" color="primary">
              {t("ManageKPI")} <span style={{ fontWeight: 'bold' }}> &gt;&gt; </span>
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const KpiManagerIndividual = () => {
  const { t,i18n } = useTranslation();
  const dispatch = useDispatch();
  const kpiData = useSelector((state) => state.kpi.result);
  const [filteredData, setFilteredData] = useState([]);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");


  useEffect(() => {
    dispatch(getKPIByManager());
  }, [dispatch]);

  useEffect(() => {
    setFilteredData(kpiData || []);
  }, [kpiData]);

  const getUniqueValues = (key) => {
    if (!kpiData || !Array.isArray(kpiData)) {
      return [];
    }
    return [...new Set(kpiData.map((item) => item[key]).filter(Boolean))];
  };

  const businessUnits = getUniqueValues("businessUnitName");
  const divisions = getUniqueValues("divisionName");
  const departments = getUniqueValues("departmentName");
  const sections = getUniqueValues("sectionName");
  const positions = getUniqueValues("positionName");
  
  const handleSearch = (searchParams) => {
    const filtered = kpiData.filter((data) => {
      const {
        name,
        company,
        businessUnit,
        division,
        department,
        section,
        position,
        manager,
      } = searchParams;
  
      const matchesName = !name || (getUserFirstName(data) && getUserFirstName(data).toLowerCase().includes(name.toLowerCase())) || (getUserLastName(data) && getUserLastName(data).toLowerCase().includes(name.toLowerCase()));
      const matchesCompany = !company || company.idCompany === "all" || (data.companyName && data.companyName === company.companyName);
      const matchesBusinessUnit = !businessUnit || businessUnit.idBusinessUnit === "all" || (data.businessUnitName && data.businessUnitName === businessUnit.businessUnitName);
      const matchesDivision = !division || division.idDivision === "all" || (data.divisionName && data.divisionName === division.divisionName);
      const matchesDepartment = !department || department.idDepartment === "all" || (data.departmentName && data.departmentName === department.departmentName);
      const matchesSection = !section || section.idSection === "all" || (data.sectionName && data.sectionName === section.sectionName);
      const matchesPosition = !position || position.idPosition === "all" || (data.positionName && data.positionName === position.positionName);
      const matchesManager =
        !manager ||
        manager.idEmployees === "all" ||
        (data.manager1Firstname && data.manager1Firstname.includes(manager.firstname_TH)) ||
        (data.manager2Firstname && data.manager2Firstname.includes(manager.firstname_TH));
      
      const totalWeight = Number(data.totalWeight || 0);
      const matchesStatus =
        selectedStatus === "" ||
        (selectedStatus === 1 && totalWeight === 100) || 
        (selectedStatus === 0 && totalWeight < 100); 
  
      return (
        matchesName &&
        matchesCompany &&
        matchesBusinessUnit &&
        matchesDivision &&
        matchesDepartment &&
        matchesSection &&
        matchesPosition &&
        matchesManager &&
        matchesStatus
      );
    });
  
    setFilteredData(filtered);
  };

  const handleExport = async () => {
    const header = [
      t("EmployeeID"), t("FirstName"), t("LastName"), t("KPIName"), t("Scope"), t("Target"), 
      t("Evidence"), t("Details"), t("DataTypeMeasurement"), t("Weight"), t("Assessor"), 
      t("Evaluate") ,t("Rate") , t("StartDate"), t("EndDate"), t("Status")
    ];
  
    const filteredForExport = filteredData.filter(employee => {
      if (selectedStatus === "") {
        return true; 
      }
      const hasKPI = employee.KPIs && employee.KPIs.length > 0;
      const totalWeight = employee.KPIs.reduce((acc, kpi) => acc + Number(kpi.weight || 0), 0);
  
      if (hasKPI) {
        return (
          (selectedStatus === 1 && totalWeight === 100) || 
          (selectedStatus === 0 && totalWeight < 100)   
        );
      } else {
        return selectedStatus === 0; 
      }
    });
  
    const dataToExport = filteredForExport.flatMap(employee => {
      const totalWeight = employee.KPIs.reduce((acc, kpi) => acc + Number(kpi.weight || 0), 0);
      const status = totalWeight === 100 ? t("Success") : t("Failure");
  
      if (employee.KPIs && employee.KPIs.length > 0) {
        return employee.KPIs.map(kpi => ({
          "รหัสพนักงาน": employee.idEmployees,
          "ชื่อ": getUserFirstName(employee),
          "นามสกุล": getUserLastName(employee),
          "ชื่อ KPI": kpi.kpiName,
          "SCOPE": kpi.scope,
          "เป้าหมาย": kpi.target,
          "Evidence": kpi.evidence,
          "รายละเอียด": kpi.description,
          "ประเภทข้อมูลใช้วัด": kpi.measureType,
          "น้ำหนัก": kpi.weight,
          "ผู้ประเมิน": `${kpi.assessorFirstname} ${kpi.assessorLastname}`,
          "การประเมิน": kpi.managerEvaluate === 1 ? t("Success") : kpi.managerEvaluate === 0 ? "ไม่สำเร็จ" : "",
          "การให้คะแนน": kpi.managerRating,
          "วันที่เริ่มต้น": dayjs(kpi.startDate).format("YYYY-MM-DD"),
          "วันที่สิ้นสุด": dayjs(kpi.endDate).format("YYYY-MM-DD"),
          "สถานะ": status
        }));
      } else {
        return [{
          "รหัสพนักงาน": employee.idEmployees,
          "ชื่อ": getUserFirstName(employee),
          "นามสกุล": getUserLastName(employee),
          "ชื่อ KPI": "", 
          "SCOPE": "",
          "เป้าหมาย": "",
          "Evidence": "",
          "รายละเอียด": "",
          "ประเภทข้อมูลใช้วัด": "",
          "น้ำหนัก": "",
          "ผู้ประเมิน": "",
          "การประเมิน": "",
          "การให้คะแนน": "",
          "วันที่เริ่มต้น": "",
          "วันที่สิ้นสุด": "",
          "สถานะ": t("Failure")
        }];
      }
    });
  
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(t("KpiData"));
  
    const colWidths = [
      { width: 15 }, 
      { width: 30 }, 
      { width: 30 },
      { width: 40 }, 
      { width: 40 },
      { width: 40 },
      { width: 40 },
      { width: 60 },
      { width: 20 },
      { width: 10 },
      { width: 40 },
      { width: 15 },
      { width: 15 },
      { width: 30 },
      { width: 30 }, 
      { width: 20 } 
    ];
  
    worksheet.columns = colWidths.map(col => ({ width: col.width }));
  
    const headerRow = worksheet.addRow(header);
  
    const headerStyle = {
      font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
      alignment: { horizontal: "center", vertical: 'middle' },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '081F5C' }
      },
      border: {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } }
      }
    };
  
    headerRow.eachCell((cell) => {
      cell.font = headerStyle.font;
      cell.alignment = headerStyle.alignment;
      cell.fill = headerStyle.fill;
      cell.border = headerStyle.border;
    });
  
    worksheet.getRow(1).height = 50;
  
    const bodyStyle = {
      font: { size: 18, name: 'TH SarabunPSK' },
      border: {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } }
      }
    };
  
    dataToExport.forEach(item => {
      const row = [
        item["รหัสพนักงาน"],
        item["ชื่อ"],
        item["นามสกุล"],
        item["ชื่อ KPI"],
        item["SCOPE"],
        item["เป้าหมาย"],
        item["Evidence"],
        item["รายละเอียด"],
        item["ประเภทข้อมูลใช้วัด"],
        item["น้ำหนัก"],
        item["ผู้ประเมิน"],
        item["การประเมิน"],
        item["การให้คะแนน"],
        item["วันที่เริ่มต้น"],
        item["วันที่สิ้นสุด"],
        item["สถานะ"]
      ];
      const excelRow = worksheet.addRow(row);
  
      excelRow.eachCell((cell, colNumber) => {
        cell.font = bodyStyle.font;
        cell.border = bodyStyle.border;
  
        if (colNumber === 16) { 
          if (item["สถานะ"] === t("Success")) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '00FF00' } 
            };
          } else if (item["สถานะ"] === t("Failure")) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FF0000' } 
            };
          }
        }
      });
  
      for (let i = 1; i <= 16; i++) {
        if (!excelRow.getCell(i).border) {
          excelRow.getCell(i).border = bodyStyle.border;
        }
      }
    });
  
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.download = `${t("ManagerKPISetting")}.xlsx`;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
  };  
  
  return (
    <ContainerStyled>
      <div className="container-xl">
        <StyledRoot>
          <GridStyled container spacing={2}>
            <Box display="flex" alignItems="center" sx={{ flexGrow: 1 }}>
              <Typography variant="h6" className="kpi" sx={{ ml: 1, fontSize: "30px" }}>
              {t("EmployeeKPISetting")}
              </Typography>
            </Box>
            <Box>
              <ButtonBlue
                variant="outlined"
                color="primary"
                sx={{ height: "40px", ml: 2 }}
                onClick={() => setOpenUploadDialog(true)}
                startIcon={<FileUploadIcon />}
              >
                {t("UploadData")}
              </ButtonBlue>
              <ButtonBlue
                variant="outlined"
                color="primary"
                sx={{ height: "40px", ml: 2 }}
                onClick={handleExport}
                startIcon={<FileDownloadIcon />}
              >
                {t("DownloadData")}
              </ButtonBlue>
            </Box>
            <Grid item xs={12} marginBottom={"5px"} marginTop={"1px"}>
              <EmployeeSearch
                onSearch={handleSearch}
                businessUnits={businessUnits}
                divisions={divisions}
                departments={departments}
                sections={sections}
                positions={positions}
              />
            </Grid>
            <Grid item xs={12} md={4} sx={{ marginTop: '-70px' }}>
            <StyledBoxSearch>
            <Typography className="label" color="text.third">
              {t("Status")}
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                labelId="status-select-label"
                id="status-select"
                value={selectedStatus || ""}
                onChange={(e) => setSelectedStatus(e.target.value)}
                displayEmpty
                sx={{
                  backgroundColor: "#919eab14",
                  height: 56,
                  padding: "0px 12px",
                  borderRadius: 2,
                  '&:before': {
                    borderBottom: 'none',
                  },
                  '&:after': {
                    borderBottom: 'none',
                  },
                  '&:hover:not(.Mui-disabled):before': {
                    borderBottom: 'none',
                  },
                  "& .MuiInputLabel-root": {
                    top: "8px",
                  },
                  "& .MuiSelect-filled": {
                    paddingTop: "18px",
                    paddingBottom: "18px",
                    backgroundColor: "transparent",
                  },
                  "& .MuiSelect-icon": {
                    top: "calc(50% - 12px)",
                  },
                }}
                  >
            <MenuItem value="" sx={{ fontStyle: 'normal' }}>
              {t("All")}
            </MenuItem>
            <MenuItem value={1}>{t("Success")}</MenuItem>
            <MenuItem value={0}>{t("Failure")}</MenuItem>
          </Select>
            </FormControl>
          </StyledBoxSearch>
        </Grid>
          </GridStyled>
          <Box
            sx={{
              overflowY: "auto",
              paddingRight: "16px",
            }}
          >
            <Grid container spacing={2}>
              {filteredData.length === 0 ? (
                <Grid item xs={12}>
                  <Typography variant="h6" align="center" color="text.secondary">
                    {t("NoSearchResults")}
                  </Typography>
                </Grid>
              ) : (
                filteredData.map((data, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index} sx={{ padding: "8px" }}>
                    <KpiCard data={data} />
                  </Grid>
                ))
              )}
            </Grid>
          </Box>

        </StyledRoot>
      </div>
      <DialogDropzone
        open={openUploadDialog}
        onClose={() => setOpenUploadDialog(false)}
        handleOpenAlert={(type, message) => alert(`${type}: ${message}`)}
      />
    </ContainerStyled>
  );
};

export default KpiManagerIndividual;
