import React, { Fragment, useState } from "react";
import { Box, Dialog, styled, Typography } from "@mui/material";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import Dropzone from "react-dropzone";
import AddIcon from "./../../../assets/addCertificate.png";
import { useDispatch } from "react-redux";
import { addManpowerFromExcel } from "../../../../../actions/manpower";
import { getAllUsersByIdVendor } from "../../../../../actions/employee";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)(({theme}) => ({
  "& .dropzone": {
    marginTop: 16,
    minHeight: 300,
    maxHeight: 300,
    height: "100%",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .inner-dropzone": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: 0,
      "& img": {
        width: 128,
        height: 128,
      }
    }
  }
}))

const DialogImportExcel = (props) => {
  const { t, i18n } = useTranslation();
  const { open, onClose } = props;
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(null);
  const [isSuccess, setIsSucess] = useState(false);

  const [addResult, setAddResult] = useState({
    success: 0,
    unSuccess: [],
  })

  const dispatch = useDispatch();

  const onDropHandler = (acceptedFiles, rejectFiles) => {

    setIsError(null)

    if(acceptedFiles.length > 0){
      setIsFetching(true)
      const formData = new FormData();
      formData.append("file", acceptedFiles[0])

      dispatch(addManpowerFromExcel(formData)).then(res => {
        setAddResult({
          success: res.data.success,
          unSuccess: res.data.unSuccess,
        })
        setIsFetching(false);
        setIsSucess(true);
      })

    }

    if(rejectFiles.length > 0){
      if(rejectFiles[0].errors[0].code === "file-invalid-type"){
        setIsError("อัพโหลดได้เฉพาะ .xlsx เท่านั้น")
      }
    }

  }

  return (
    <Dialog
      open={open}
    >
      <StyledRoot padding="16px" minWidth="600px" boxSizing="border-box">
        {!isSuccess && <Fragment>
          <Typography variant="h6">อัพโหลดไฟล์ Excel สำหรับเพิ่มกำลังพล</Typography>
          <Box display="flex" justifyContent="flex-end">
            <ButtonBlue variant="outlined" startIcon={<i className="fa-regular fa-down"></i>} style={{textTransform: "none"}} component={Link} to="/template/Ant Job Manpower Template 20.01.2023.xlsx" target="_blank" download>ดาวน์โหลดรูปแบบ Excel</ButtonBlue>
          </Box>
          <Dropzone
            onDrop={onDropHandler}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            maxFiles={1}
            multiple
            maxSize={10485760}
          >
            {({
              getRootProps,
              getInputProps,
              // open,
              // isDragActive,
              // acceptedFiles,
            }) => (
              <div {...getRootProps({ className: "dropzone" })}>

                {isFetching ? (
                  <Box>
                    <Typography>กำลังอัพโหลด..</Typography>
                  </Box>

                ):(
                  <Box>
                    <input {...getInputProps()}/>
                    <Box className="inner-dropzone">
                      <img alt="add" src={AddIcon} />
                      <Typography paddingTop="16px">{t("DragDropOrClickSelectFile")}</Typography>
                    </Box>
                  </Box>
                )}

              </div>
              )
            }
          </Dropzone>
          {isError && <Typography fontSize={14} color="error">{isError}</Typography>}
          <Box marginTop="16px" display="flex" justifyContent="flex-end">
            <ButtonBlue onClick={onClose}>ยกเลิก</ButtonBlue>
          </Box>
        </Fragment>}

        {isSuccess && <Fragment>
          <Typography variant="h6" paddingBottom="16px">ผลการอัพโหลด Excel</Typography>

          <Typography>กำลังพลที่เพิ่มสำเร็จ: <span style={{color: "#43a047"}}>{addResult.success}</span> คน</Typography>

          <Typography paddingTop="16px">กำลังพลที่เพิ่มไม่สำเร็จ: {addResult.unSuccess.length > 0 ? <><span style={{color: "#d32f2f"}}>{addResult.unSuccess.length}</span> คน</>: "ไม่มี"}</Typography>
          {addResult.unSuccess.length > 0 && (
            <Box>
              {addResult.unSuccess.map((mp, index) => (
                <Typography key={index}><i className="fa-solid fa-xmark" style={{color: "#d32f2f"}}></i> {mp.name}</Typography>
              ))}
            </Box>
          )}

          <Box marginTop="16px" display="flex" justifyContent="flex-end">
            <ButtonBlue onClick={() => {onClose(); dispatch(getAllUsersByIdVendor())}}>ตกลง</ButtonBlue>
          </Box>
        
        </Fragment>}
      </StyledRoot>
    </Dialog>
  )
}

export default DialogImportExcel