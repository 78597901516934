import React, { useEffect, useState, Fragment } from "react";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import {
  Typography,
  Divider,
  Avatar,
  Badge,
  Box,
  useMediaQuery,
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

import StyledCard from "../../shared/general/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";

import { getDepartment } from "./../../../../actions/department";
import { getAllUsersCompany } from "../../../../actions/user";

import ItemTable from "./ItemTable";
import FilterTable from "./filterTable";

import "./index.css";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  wrapHeader: {
    marginTop: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ["@media only screen and (max-width: 600px)"]: {
      "& .MuiTypography-root": {
        fontSize: 26,
      },
    },
    ["@media only screen and (min-width:600px)"]: {
      "& .MuiTypography-root": {
        fontSize: 26,
      },
    },
    ["@media only screen and (min-width:768px)"]: {
      "& .MuiTypography-root": {
        fontSize: 34,
      },
    },
  },
  btnAddNew: {
    ["@media only screen and (max-width: 600px)"]: {
      display: "none",
    },
  },
  btnIconAddNew: {
    ["@media only screen and (min-width:600px)"]: {
      display: "none",
    },
  },
}));

const StyledContent = styled("div")({
  padding: 24,
  ["@media only screen and (max-width: 600px)"]: {
    padding: 16,
  },
  "& .wrapFilter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    ["@media only screen and (max-width: 600px)"]: {
      width: "100%",
      justifyContent: "center",
    },
  },
});

const StyledWrapDetail = styled("div")({
  marginTop: 24,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& .information": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& .MuiTypography-root": {
      marginBottom: 4,
    },
    "& .far": {
      marginRight: 8,
      color: "#999999",
    },
    "& .fa-map-marker": {
      marginRight: 13,
    },
  },
});

const StyledAvatarDetail = styled(Avatar)({
  width: 100,
  height: 100,
  marginBottom: 8,
});

const StyledDivider = styled(Divider)({
  margin: "16px 0px",
  borderWidth: "0px 0px thin",
  borderColor: "rgba(145, 158, 171, 0.24)",
  borderStyle: "dashed",
  width: "100%",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: 4,
    color: "#46cbe2",
    border: "1px solid #46cbe2",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#247785",
      backgroundColor: "#247785",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#46cbe2",
      "&:hover": {
        borderColor: "#247785",
        backgroundColor: "#247785",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #46cbe2",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
  },
}));

const EmployeeList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const mobileResponsive = useMediaQuery("(max-width:600px)");
  const { result: employeeList } = useSelector((state) => state.employees);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [selectedEmployee, setselectedEmployee] = useState(0);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filterEmployee, setFilterEmployee] = useState({
    status: "active",
    search: "",
    idDepartment: "",
    idVendor: "",
    idJobGroup: "",
    employeeType: [],
    level: [],
  });

  useEffect(() => {
    if (userProfile) {
      dispatch(getDepartment(userProfile.idCompany));
    }
  }, [userProfile]);

  const handleChangeFilterEmployee = (event, newStatus, selectName) => {
    if (newStatus !== null) {
      var name = event.target.name;
      var value = newStatus;
      if (newStatus === undefined) {
        setFilterEmployee({
          ...filterEmployee,
          [name]: event.target.value,
        });
      } else {
        if (name === undefined) {
          name = selectName;
        }
        setFilterEmployee({
          ...filterEmployee,
          [name]: value,
        });
      }
      // setPage(0);
    }
  };

  const rowsFilter = () => {
    let resultFilter = [...employeeList];

    if (filterEmployee.status === "active") {
      resultFilter = resultFilter.filter((item) => {
        return item.isTerminate === 0;
      });
    } else if (filterEmployee.status === "terminate") {
      resultFilter = resultFilter.filter((item) => {
        return item.isTerminate === 1;
      });
    }

    return resultFilter;
  };

  return (
    <div style={{ paddingBottom: 16 }}>
      <StyledCard>
        <StyledContent>
          <div className="wrapFilter">
            <div>
              <StyledToggleButtonGroup
                value={filterEmployee.status}
                exclusive
                onChange={handleChangeFilterEmployee}
                aria-label="filter employee"
                size="small"
              >
                <ToggleButton
                  name="status"
                  value="active"
                  aria-label="active"
                  size="small"
                  style={{ minWidth: 150 }}
                >
                  {`${t("NormalEmp")}`}
                </ToggleButton>
                <ToggleButton
                  name="status"
                  value="terminate"
                  aria-label="terminate"
                  size="small"
                  style={{ minWidth: 150 }}
                >
                  {`${t("EmployeeOut")}`}/Blacklist
                </ToggleButton>
              </StyledToggleButtonGroup>
            </div>
            {employeeList && !mobileResponsive && (
              <div>
                <Typography variant="h4" align="right">
                  {/* {employeeList.length} */}
                  {rowsFilter().length}
                </Typography>
                <Typography
                  variant="body2"
                  align="right"
                  color="text.secondary"
                  style={{ fontWeight: 500 }}
                >
                  {`${t("All")}`}
                </Typography>
              </div>
            )}
          </div>
          {/* {!mobileResponsive && <div>
            <ButtonBlue
              onClick={() => setFilterStatus(!filterStatus)}
              startIcon={
                filterStatus ? <FilterAltOffIcon /> : <FilterAltIcon />
              }
            >
              {`${filterStatus ? "ปิด" : "เปิด"}ตัวกรอง`}
            </ButtonBlue>
          </div>} */}
          <Box style={{ display: "flex", position: "relative" }}>
            <FilterTable
              handleChange={handleChangeFilterEmployee}
              filterEmployee={filterEmployee}
              filterStatus={filterStatus}
            />
            <ItemTable open={filterStatus} filterEmployee={filterEmployee} />
          </Box>
        </StyledContent>
      </StyledCard>
      {selectedEmployee !== 0 && (
        <StyledCard>
          <StyledContent>
            {/* <Typography align="center">ข้อมูลพนักงาน</Typography> */}
            <StyledWrapDetail>
              <StyledAvatarDetail
                alt={selectedEmployee.username}
                src={`${process.env.REACT_APP_API_URL}image/profile/${selectedEmployee.image}`}
              />
              <Typography align="center" variant="h6">
                {`${selectedEmployee.firstname} ${selectedEmployee.lastname}`}
              </Typography>
              <Typography align="center" variant="body2" color="textSecondary">
                {`${selectedEmployee.position}`}
              </Typography>
              <Typography
                align="center"
                variant="body2"
                color="textSecondary"
                style={{ fontWeight: 600 }}
              >
                {`${selectedEmployee.department}`}
              </Typography>
              <StyledDivider />
              <div className="information">
                <Typography variant="body1">
                  <i class="far fa-phone-alt"></i>
                  {`${selectedEmployee.mobileNumber.replace(
                    /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                    "$1-$2-$3"
                  )}`}
                </Typography>
                <Typography variant="body1">
                  <i class="far fa-envelope-open-text"></i>
                  {`${selectedEmployee.email}`}
                </Typography>
                <Typography variant="body1">
                  <i class="far fa-map-marker"></i>
                  {`${selectedEmployee.workingLocation}`}
                </Typography>
              </div>
              <StyledDivider />
              <div>
                <ButtonBlue
                  value={"more detail"}
                  variant={"outlined"}
                  startIcon={<i class="fal fa-info-circle"></i>}
                  component={NavLink}
                  to="/admin/selectkey/add"
                >
                  {`${t("AdditionalData")}`}
                </ButtonBlue>
              </div>
            </StyledWrapDetail>
          </StyledContent>
        </StyledCard>
      )}
    </div>
  );
};

export default EmployeeList;
