import React from 'react';
import { Controller } from 'react-hook-form';
import { makeStyles } from "@mui/styles";

import {
    InputAdornment
} from '@mui/material';

import TextFieldTheme from '../../../../shared/general/TextFieldTheme';
import NumberFormatTheme from '../../../../shared/general/NumberFormatTheme';

const useStyles = makeStyles(() => ({
    inputAdornment: {
      "& .MuiTypography-body1": {
        color: "rgba(0, 0, 0, 0.38)",
      },
    },
}));

const TextFieldNumber = (props) => {
    const { min, max, name, endAdornment, endAdornmentLabel, control, label, isInteger, onHandleChange, disabled } = props;
    const classes = useStyles();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <TextFieldTheme
                    {...field}
                    disabled={disabled}
                    inputProps={{
                        allowNegative: false,
                        thousandSeparator: ",",
                        isAllowed: (values) => {
                            const { value } = values;
                            return ((min ? parseFloat(value) >= min : true) && (max ? parseFloat(value) <= max : true)) || (value === "");
                        },
                        onValueChange: (values) => {
                            const { value } = values;
                            field.onChange(parseFloat(value));
                        },
                        ...isInteger ? { decimalScale: 0 } : {}
                    }}
                    InputProps={{
                        inputComponent: NumberFormatTheme,
                        ...endAdornment ? {
                            endAdornment: (
                                <InputAdornment
                                    className={classes.inputAdornment}
                                    position="end"
                                >
                                    {endAdornmentLabel}
                                </InputAdornment>
                            )
                        } : {}
                    }}
                    variant="outlined"
                    fullWidth
                    label={label ? label : ""}
                    onChange={() => { if(onHandleChange){ onHandleChange(); } }}
                    onBlur={(e) => { 
                        if(e.target.value === ""){
                            field.onChange('0')
                        } 
                    }}
                />
            )}
        />
    );
};

export default TextFieldNumber;