import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import { getUserCompany, getUserFirstName, getUserLastName, getUserPosition } from '../../../../../utils/userData';

export const ExportExcel = async (t,i18n,data) => {

    const headerCellStyle = {
        font: {
            name: "TH Sarabun New",
            size: 20,
            color: { argb: "FFFFFF" }
        },
        alignment: {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
        },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: "002060" }
        },
        // numFmt: "@",
    }

    const dataCellStyle = {
        font: {
            name: "TH Sarabun New",
            size: 18,
        },
        alignment: {
            vertical: "middle",
            horizontal: "center"
        },
        // numFmt: "@",
    }

    const workBook = new ExcelJS.Workbook();

    const worksheet = workBook.addWorksheet(t("EmployeeTransferPosition"));

    const headerRow = worksheet.addRow([
        t("EmployeeID"), t("FirstName"), t("LastName"),t("oldPosition"), t("oldCompany"), t("oldDivision"), t("oldDepartment"), t("oldSection"), t("newPosition"), t("newCompany"), t("newDivision"), t("newDepartment"), t("newSection"), t("Note"), t("EffectiveDate")
    ]);

    headerRow.eachCell((cell) => {
        cell.style = headerCellStyle;
        cell.numFmt = "@";
    });

    headerRow.height = 50;

    const colWidths = [
        { key: "employeeID", width: 30 },
        { key: "firstname_TH", width: 50 },
        { key: "lastname_TH", width: 50 },
        { key: "positionName", width: 50 },
        { key: "companyName", width: 60 },
        { key: "divisionName", width: 50 },
        { key: "departmentName", width: 50 },
        { key: "sectionName", width: 50 },
        { key: "newPositionName", width: 50 },
        { key: "newCompanyName", width: 60 },
        { key: "newDivisionName", width: 50 },
        { key: "newDepartmentName", width: 50 },
        { key: "newSectionName", width: 50 },
        { key: "description", width: 50 },
        { key: "date", width: 50 },
    ];

    colWidths.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width;
    });

    data.map(item => {
        const row = [
            item.employeeID || "-",
            getUserFirstName(item) || "-",
            getUserLastName(item) || "-",
            getUserPosition(item) || "-",
            getUserCompany(item) || "-",
            item.divisionName || "-",
            item.departmentName || "-",
            item.sectionName || "-",
            i18n.resolveLanguage === "th" ? item.newPosition : item.newPosition_EN ? item.newPosition_EN : item.newPosition || "-",
            i18n.resolveLanguage === "th" ? item.newCompany : item.newCompany_EN ? item.newCompany_EN : item.newCompany || "-",
            item.newDivision || "-",
            item.newDepartment || "-",
            item.newSection || "-",
            item.description || "-",
            item.updatedDate || "-",
        ]

        const excelRow = worksheet.addRow(row);
        excelRow.eachCell((cell) => {
            cell.style = dataCellStyle;
            cell.numFmt = "@";
        });
    })

    workBook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${t("EmployeeTransferPosition")}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
}