import React, { useState } from "react";
import { styled } from "@mui/material/styles";

import { Button } from "@mui/material";

import SaveAsRoundedIcon from "@mui/icons-material/SaveAsRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";

import ConfirmDialog from "../../../user/expense/confirm-dialog";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useTranslation } from "react-i18next";

const ActionButtons = (props) => {
  const { t, i18n } = useTranslation();
  const { handleOnClickCleardata, handleSubmitDraft, handleSubmitSent } = props;
  const ClearFormDialogTitle = t("ClearFormDialogTitle");
  const DraftFormDialogTitle = t("DraftFormDialogTitle");
  const SentFormDialogTitle = t("SentFormDialogTitle");
  const ClearFormDialogDes = t("ClearFormDialogDes");
  const DraftFormDialogDes = t("DraftFormDialogDes");
  const SentFormDialogDes = t("SentFormDialogDes");
  const [openCleardataDialog, setOpenCleardataDialog] = useState(false);
  const [openSavedraftDialog, setOpenSavedraftDialog] = useState(false);
  const [openSentDialog, setOpenSentDialog] = useState(false);

  const handleClickCleardataDialog = () => {
    setOpenCleardataDialog(true);
  };
  const handleCloseCleardataDialog = () => {
    setOpenCleardataDialog(false);
  };

  const handleClickSavedraftDialog = () => {
    setOpenSavedraftDialog(true);
  };
  const handleCloseSavedraftDialog = () => {
    setOpenSavedraftDialog(false);
  };

  const handleClickSentDialog = () => {
    setOpenSentDialog(true);
  };
  const handleCloseSentDialog = () => {
    setOpenSentDialog(false);
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Button
        startIcon={<DeleteOutlineRoundedIcon />}
        color="error"
        onClick={handleClickCleardataDialog}
      >
        {t("ClearData")}
      </Button>
      <ConfirmDialog
        title={ClearFormDialogTitle}
        description={ClearFormDialogDes}
        open={openCleardataDialog}
        handleOnClick={() => {
          setOpenCleardataDialog(false);
          handleOnClickCleardata();
        }}
        handleClose={handleCloseCleardataDialog}
      ></ConfirmDialog>
      <div>
        <ButtonBlue
          variant="contained"
          startIcon={<SendRoundedIcon />}
          onClick={handleClickSentDialog}
        >
          {t("SentBilling")}
        </ButtonBlue>
        <ConfirmDialog
          title={SentFormDialogTitle}
          description={SentFormDialogDes}
          open={openSentDialog}
          handleOnClick={() => {
            setOpenSentDialog(false);
            handleSubmitSent();
          }}
          handleClose={handleCloseSentDialog}
        ></ConfirmDialog>
      </div>
    </div>
  );
};

export default ActionButtons;
