import React from "react";
import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  addBtn: {
    paddingLeft: 32,
    paddingRight: 32,
    borderRadius: 8,
    height: 48,
  },
}));

function AddOrgChartNode() {
  const classes = useStyles();

  const handleOnAdd = () => {};

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={classes.addBtn}
        onClick={handleOnAdd}
      >
        Add
      </Button>
    </>
  );
}

export default AddOrgChartNode;
