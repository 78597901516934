import React, { useEffect, useState } from "react";
import { Box, Grid, styled, TextField, Typography } from "@mui/material";
import DatePickerCustom from "../../shared/date/datePicker";
import ButtonBlue from "../../shared/general/ButtonBlue";
import dayjs from "dayjs";
import XLSX from "xlsx";
import SelectEmployees from "../../shared/general/selectEmployees";
import {
    clearAttendance,
    getAttendanceById,
} from "../../../../actions/attendance";
import { useDispatch, useSelector } from "react-redux";

import SearchIcon from "@mui/icons-material/Search";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { getPayrollSetting } from "../../../../actions/paytypes";
import TableTimeline from "../../shared/tableTimeline";
import DrawerRequestTime from "../../shared/tableTimeline/drawerRequestTime";
import Notification from "../../shared/general/Notification";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import { openNotificationAlert } from "../../../../actions/notificationAlert";
import { getTotalWorkingHours } from "../../../../actions/report";

const StyledRoot = styled(Box)({});

const StyledBoxSearchButton = styled(Box)({
    marginTop: 50,
    display: "flex",
    justifyContent: "space-evenly",
});

const StyledBoxSearch = styled(Box)({
    marginTop: 22,
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});

const SectionPanel = (props) => {
    const { selectedCompany } = props;

    const today = dayjs().toDate();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const [search, setSearch] = useState({
        start: dayjs(today).set("date", 1),
        end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
    });

    const [showDate, setShowDate] = useState({
        start: dayjs(today).set("date", 1),
        end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
    });

    const handleOnClickSearch = async () => {
        setShowDate(search);
        if (
            dayjs(search.end).isSameOrAfter(search.start, "date")
        ) {
            let result = await getTotalWorkingHours({
                start: dayjs(search.start) ? dayjs(today).set("date", 1) : dayjs(search.start),
                end: dayjs(search.end) ? dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)) : dayjs(search.end),
                idCompany: selectedCompany.idCompany
            });

            if (result.status !== 200) {
                dispatch(
                    openNotificationAlert({ message: `${t("AnErrorOccurred")} ${t("PleaseContactAdmin")}` })
                );
            }
        } else {
            dispatch(
                openNotificationAlert({
                    type: "info",
                    message: "กรุณาตรวจสอบวันที่",
                })
            );
        }
    };

    return (
        <StyledRoot>
            <Grid
                container
                spacing={2}
                style={{ marginBottom: 16 }}
                alignItems={"center"}
            >
                <Grid item xs={12} sm={3}>
                    <StyledBoxSearch>
                        <Typography className="label" color="text.third">
                            {t("StartDate")}
                        </Typography>
                        <div className="search-date">
                            <DatePickerCustom
                                minDate={new Date(new Date().getFullYear() - 4, 0, 1)}
                                inputFormat="DD/MM/YYYY"
                                value={search.start}
                                name="start"
                                onChange={(newValue) => {
                                    setSearch({ ...search, ["start"]: newValue });
                                }}
                            />
                        </div>
                    </StyledBoxSearch>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <StyledBoxSearch>
                        <Typography className="label" color="text.third">
                            {t("EndDate")}
                        </Typography>
                        <div className="search-date">
                            <DatePickerCustom
                                minDate={search.start}
                                inputFormat="DD/MM/YYYY"
                                value={search.end}
                                name="end"
                                onChange={(newValue) => {
                                    setSearch({ ...search, ["end"]: newValue });
                                }}
                            />
                        </div>
                    </StyledBoxSearch>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <StyledBoxSearchButton>
                        <div>
                            <ButtonBlue
                                variant="contained"
                                startIcon={<SearchIcon />}
                                onClick={handleOnClickSearch}
                            >
                                {t("Search")}
                            </ButtonBlue>
                        </div>
                        <div>
                            <ButtonBlue
                                variant="outlined"
                                startIcon={<DownloadRoundedIcon />}
                            >
                                {t("Download")}
                            </ButtonBlue>
                        </div>
                    </StyledBoxSearchButton>
                </Grid>
            </Grid>
        </StyledRoot>
    );
};

export default SectionPanel;
