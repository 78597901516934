import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  styled,
  InputAdornment,
  FormControl,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import EditIcon from "@mui/icons-material/Edit";
import AddOrEditModal from "./AddOrEditModal";
import NumberFormat from "react-number-format";
import PaidIcon from "@mui/icons-material/Paid";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  boxForm: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  editBtn: {
    marginLeft: theme.spacing(2),
    backgroundColor: "#f5f5f5",
  },
}));

const StyledTextFieldTheme = styled(TextFieldTheme)({
  marginBottom: 16,
});

const StyleOutlineInputTheme = styled(OutlinedInput)({
  borderRadius: "8px",
  marginBottom: 8,
  "& .MuiInputBase-input": {
    padding: "13.5px 14px",
  },
});

function PayrollForm(props) {
  const { values, setValues } = props;
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [addOrEditModal, setAddOrEditModal] = useState({
    isOpen: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("name: ", name);
    console.log("value: ", value);
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <div>
      {payrollSetting && (
        <Fragment>
          <Box style={{ marginBottom: 2 }}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                fontSize: "1.25rem",
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              {t("OvertimePayAndHolidayWorkPay")}
            </Typography>
          </Box>
          {values && (
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={3}>
                <div>
                  <Typography variant="body1">
                    {t("OvertimePayOnWorkdays")}
                  </Typography>
                  <FormControl fullWidth variant="outlined" margin="dense">
                    <NumberFormat
                      type="text"
                      thousandSeparator={true}
                      customInput={StyleOutlineInputTheme}
                      allowNegative={false}
                      name="xOT"
                      value={values.xOT}
                      onChange={handleInputChange}
                      endAdornment={
                        <InputAdornment position="end">{t("Times")}</InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div>
                  <Typography variant="body1">{t("HolidayPayRate")}</Typography>
                  <FormControl fullWidth variant="outlined" margin="dense">
                    <NumberFormat
                      type="text"
                      thousandSeparator={true}
                      customInput={StyleOutlineInputTheme}
                      allowNegative={false}
                      name="xOTHoliday"
                      value={values.xOTHoliday}
                      onChange={handleInputChange}
                      endAdornment={
                        <InputAdornment position="end">{t("Times")}</InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div>
                  <Typography variant="body1">
                    {t("HolidayPayRate")} ({t("Day")})
                  </Typography>
                  <FormControl fullWidth variant="outlined" margin="dense">
                    <NumberFormat
                      type="text"
                      thousandSeparator={true}
                      customInput={StyleOutlineInputTheme}
                      allowNegative={false}
                      name="xWorkingDailyHoliday"
                      value={values.xWorkingDailyHoliday}
                      onChange={handleInputChange}
                      endAdornment={
                        <InputAdornment position="end">{t("Times")}</InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div>
                  <Typography variant="body1">
                    {t("HolidayPayRate")} ({t("Month")})
                  </Typography>
                  <FormControl fullWidth variant="outlined" margin="dense">
                    <NumberFormat
                      type="text"
                      thousandSeparator={true}
                      customInput={StyleOutlineInputTheme}
                      allowNegative={false}
                      name="xWorkingMonthlyHoliday"
                      value={values.xWorkingMonthlyHoliday}
                      onChange={handleInputChange}
                      endAdornment={
                        <InputAdornment position="end">{t("Times")}</InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          )}

          <Box style={{ marginBottom: 2 }}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                fontSize: "1.25rem",
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              {t("ShiftValue")}
            </Typography>
          </Box>

          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={4}>
              <div>
                <Typography variant="body1">{t("ShiftPayMorning")}</Typography>
                <FormControl fullWidth variant="outlined" margin="dense">
                  <NumberFormat
                    type="text"
                    thousandSeparator={true}
                    customInput={StyleOutlineInputTheme}
                    allowNegative={false}
                    name="morningShiftFee"
                    onChange={handleInputChange}
                    endAdornment={
                      <InputAdornment position="end">{t("Baht")}</InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div>
                <Typography variant="body1">{t("ShiftPayAfternoon")}</Typography>
                <FormControl fullWidth variant="outlined" margin="dense">
                  <NumberFormat
                    type="text"
                    thousandSeparator={true}
                    customInput={StyleOutlineInputTheme}
                    allowNegative={false}
                    name="afternoonShiftFee"
                    onChange={handleInputChange}
                    endAdornment={
                      <InputAdornment position="end">{t("Baht")}</InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div>
                <Typography variant="body1">{t("ShiftPayNight")}</Typography>
                <FormControl fullWidth variant="outlined" margin="dense">
                  <NumberFormat
                    type="text"
                    thousandSeparator={true}
                    customInput={StyleOutlineInputTheme}
                    allowNegative={false}
                    name="afternoonShiftFee"
                    onChange={handleInputChange}
                    endAdornment={
                      <InputAdornment position="end">{t("Baht")}</InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>

          <AddOrEditModal
            addOrEditModal={addOrEditModal}
            setAddOrEditModal={setAddOrEditModal}
            values={values}
            setValues={setValues}
          />
        </Fragment>
      )}
    </div>
  );
}

export default PayrollForm;
