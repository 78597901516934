import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Typography, Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import AlertResponse from "../../../shared/general/AlertResponse";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import CardStyle from "../../../shared/general/Card";
import StatusRequest from "../../../shared/requestList/stausRequest";
import { useDispatch, useSelector } from "react-redux";
// import { getProbation } from "../../../../../actions/probation";
import RequestList from "./RequestList";
import DialogExitInterview from "./DialogExitInterview";

const StyledHeadingPage = styled("div")({
  marginBottom: 16,
  "& .wrap-search": {
    marginTop: 16,
    "& .fill": {
      display: "flex",
    },
  },
});
const BoxStyled = styled("div")({
  "& .header": {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
    "& .text": {
      fontSize: 16,
    },
    "& .icon": {
      color: "#637381",
      fontSize: 16,
      marginRight: 8,
    },
  },
  "& .btn-save": {
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .flex-box": {
    marginTop: 16,
    display: "flex",
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 20,
  marginRight: 8,
});

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
}));

const StyledPaper = styled(Paper)({
  border: "none",
  borderRadius: 16,
  paddingBottom: 16,
  "& .inner-info": {
    display: "flex",
    alignItems: "center",
    "& .MuiBox-root": {
      margin: "0 8px 0 0!important",
    },
  },
});

const RequestEstimate = (props) => {
  const { result: exitInterviewAnswer } = useSelector(
    (state) => state.exitInterviewAnswer
  );

  const { result: userProfile } = useSelector((state) => state.userProfile);

  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [openEducation, setOpenEducation] = useState(false);
  const [idEducation, setIdEducation] = useState(null);
  const [typeDialog, setTypeDialog] = useState("Add");
  const [listExitInterview, setListExitInterview] = useState(null);

  const dispatch = useDispatch();

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleOpenEducation = (type, idEdu) => {
    if (type === "Add") {
      setTypeDialog("Add");
    } else {
      setTypeDialog("Edit");
    }
    if (idEdu) {
      setIdEducation(idEdu);
    }
    setOpenEducation(true);
  };

  const handleClose = () => {
    setOpenEducation(false);
    setIdEducation(null);
  };

  const handleSubmitProbation = async (result) => {
    handleClose();
    if (result) {
      handleOpenAlert();
      if (result.status === 200) {
        // dispatch(getProbation());
        handleChangeAlertType("success");
      } else {
        handleChangeAlertType("error");
      }
    } else {
      handleChangeAlertType("error");
    }
  };

  useEffect(() => {
    handleFindId();
  }, [exitInterviewAnswer]);

  const handleFindId = () => {
    if (userProfile && exitInterviewAnswer) {
      let temp = exitInterviewAnswer.find(
        (data) =>
          data.idEmployees === userProfile.idEmployees &&
          data.resultExitInterview === 3
      );
      setListExitInterview(temp);
    }
  };

  return (
    <div className={classes.root}>
      <StyledHeadingPage sx={{ marginTop: "20px" }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">{`${t("ResignationForm")}`}</Typography>
          </Grid>
        </Grid>
      </StyledHeadingPage>
      <BoxStyled>
        <div className="btn-save">
          <ButtonBlue
            variant="contained"
            onClick={() => {
              handleOpenEducation("Add");
            }}
            disabled={
              listExitInterview && listExitInterview.resultExitInterview === 3
                ? true
                : false
            }
          >
            {`${t("SubmitResignationRequest")}`}
            &nbsp;
            <AddCircleRoundedIcon />
          </ButtonBlue>
        </div>
      </BoxStyled>
      <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
        <BoxStyled>
          <div className="flex-box">
            <StyledContentLabel variant="body1">
              {`${t("PendingApprovalList")}`}
            </StyledContentLabel>
          </div>
          <StyledPaper variant="outlined" style={{ marginTop: "10px" }}>
            <Typography
              gutterBottom
              color="text.third"
              style={{ fontWeight: 500, fontSize: 14 }}
            >
              {`${t("VariousStatusFormats")}`}
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4} md={2}>
                <div className="inner-info">
                  <StatusRequest
                    boxSize={30}
                    fontSize={18}
                    borderRadius={8}
                    status={1}
                    active={1}
                  />
                  <Typography>{`${t("Approved")}`}</Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <div className="inner-info">
                  <StatusRequest
                    boxSize={30}
                    fontSize={18}
                    borderRadius={8}
                    status={0}
                    active={1}
                  />
                  <Typography>{`${t("NotApproved")}`}</Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <div className="inner-info">
                  <StatusRequest
                    boxSize={30}
                    fontSize={18}
                    borderRadius={8}
                    status={null}
                    step={null}
                    active={0}
                  />
                  <Typography>{`${t("CancelTransaction")}`}</Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <div className="inner-info">
                  <StatusRequest
                    boxSize={30}
                    fontSize={18}
                    borderRadius={8}
                    status={null}
                    step={null}
                    active={1}
                  />
                  <Typography>{`${t("PendingApproval")}`}</Typography>
                </div>
              </Grid>
            </Grid>
            <Grid marginTop={5}>
              <RequestList listExitInterview={listExitInterview} />
            </Grid>
          </StyledPaper>
        </BoxStyled>
      </CardStyle>

      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      {openEducation && (
        <DialogExitInterview
          open={openEducation}
          handleClose={handleClose}
          idEdu={idEducation}
          type={typeDialog}
          handleSubmitProbation={handleSubmitProbation}
        />
      )}
    </div>
  );
};

export default RequestEstimate;
