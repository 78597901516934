import { Avatar, Box, TablePagination, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChipWithDrawCustom from "../../shared/chipWithDrawCustom";
import StatusRequest from "../../shared/general/stausRequest";
import TableCustom from "../../shared/tableCustom";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import MenuStatus from "./MenuStatus";
import { getAllLeaveWithDrawBy, updateApproveLeaveEmployeeBy } from "../../../../actions/leave";
import { getLeaveRequest } from "../../../../actions/employee";
import DialogConfirmReject from "../approveList/DialogConfirmReject";
import { getUserFullName, getUserPosition } from "../../../../utils/userData";

const RequestLeave = ({ searchDate, setSnackBarConfig }) => {
  const { result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { result: leaveEmployeeWithdrawsList } = useSelector(
    (state) => state.leaveEmployeesWithDraw
  );

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [listPerPage, setListPerPage] = useState(10);
  const [historyRows, setHistoryRows] = useState([]);
  const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);
  const [selectRequest, setSelectRequest] = useState(null);

  const handleChangeStatus = (request, approve) => {
    setSelectRequest(request)
    if (approve === 0) {
      setIsOpenRejectDialog(true)
    } else {
      handleApprovalRequest(request)
    }
  }

  const handleSubmit = async (formData) => {
    const result = await dispatch(
      updateApproveLeaveEmployeeBy("manager", formData)
    );
    if (result) {
      if (result.status === 200) {
        dispatch(getLeaveRequest("manager", searchDate));
        dispatch(getAllLeaveWithDrawBy("manager", searchDate));
        setSnackBarConfig({
          open: true,
          message: `${t("Successfully")}`,
          type: "success",
        });
      } else {
        setSnackBarConfig({
          open: true,
          message: `${t("Unsuccessful")}`,
          type: "error",
        });
      }
    } else {
      setSnackBarConfig({
        open: true,
        message: `${t("Unsuccessful")}`,
        type: "error",
      });
    }
  }

  const handleApprovalRequest = async (request) => {
    var formData = [
      {
        idLeave: [request.idLeave],
        isApprove: 1,
        idManager: userProfile.idEmployees,
        approveDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm"),
        // commentManager: comment ? comment : null,
      },
      // {
      // idLeaveEmployeesWithdraw: [request.idLeaveEmployeesWithdraw],
      // idLeave: [request.idLeave],
      // isApprove: 1,
      // commentManager: comment ? comment : null,
      // },
    ];

    handleSubmit(formData)
  };

  const handleRejectRequest = async (approve, comment) => {
    const formData = [
      {
        idLeave: [selectRequest.idLeave],
        isApprove: approve,
        idManager: userProfile.idEmployees,
        approveDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm"),
        commentManager: comment ? comment : null,
      },
      {
        idLeaveEmployeesWithdraw: [selectRequest.idLeaveEmployeesWithdraw],
        idLeave: [selectRequest.idLeave],
        isApprove: approve,
        commentManager: comment ? comment : null,
      },
    ];

    handleSubmit(formData)
  };

  const handleConfirmReject = (comment) => {
    handleRejectRequest(0, comment);
  };

  const getStatusLeave = (requestLeave) => {
    if (
      requestLeave.idLeaveEmployeesWithDraw &&
      requestLeave.managerApprove === userProfile.idEmployees
    ) {
      if (requestLeave.isApprove === 1) {
        return 1;
      } else if (requestLeave.isApprove === 0) {
        return 0;
      } else {
        return 2;
      }
    }

    if (requestLeave.isApprove === 1) {
      return 1;
    } else if (requestLeave.isApprove === 0) {
      return 0;
    } else {
      return 2;
    }
  };

  const columns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>
              {getUserFullName(row)}
            </Typography>
            <Typography color="text.third" fontSize="14px">
              {getUserPosition(row)}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("Type")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Fragment>
          {row.idLeaveEmployeesWithdraw && <ChipWithDrawCustom />}
          <Typography fontSize={14}>
            {i18n.resolvedLanguage === "th"
              ? row.name
              : row.name_EN ? row.name_EN : ""}
          </Typography>
        </Fragment>
      ),
    },
    {
      name: `${t("Start")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.startText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}
          </Typography>
          {row.isFullDay === 1 ? (
            <Typography>{`${t("Full_day")}`}</Typography>
          ) : (
            <Typography>
              {dayjs(row.startText, "DD/MM/YYYY HH:mm").format("HH:mm")}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      name: `${t("End")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}
          </Typography>
          {row.isFullDay === 1 ? (
            <Typography>{`${t("Full_day")}`}</Typography>
          ) : (
            <Typography>
              {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("HH:mm")}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      name: `${t("Reason")}`,
      minWidth: "200px",
      cellRender: (row) => (
        <Box>
          <Typography color="text.third" fontSize="14px">
            {row.description}
          </Typography>
        </Box>
      ),
    },
  ];

  const historyColumns = [
    {
      name: `${t("Status")}`,
      headerTextAlign: "center",
      cellRender: (row) => (
        <MenuStatus
          row={row}
          status={getStatusLeave(row)}
          handleChagneStatus={handleChangeStatus}
          conditionDate={row.end}
        />
      ),
    },
    ...columns,
    {
      name: `${t("ApprovedDate")}`,
      minWidth: "150px",
      headerTextAlign: "center",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          {row.approveDateText && (
            <>
              <Typography>
                {dayjs(row.approveDateText, "DD/MM/YYYY HH:mm").format(
                  "D MMM BBBB"
                )}
              </Typography>
              <Typography>
                {dayjs(row.approveDateText, "DD/MM/YYYY HH:mm").format("HH:mm")}
              </Typography>
            </>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const tempHistory = [];

    leaveEmployeesList.map((r) => {
      if (r.isApprove !== null) {
        tempHistory.push(r);
      }
    });

    leaveEmployeeWithdrawsList.map((r) => {
      if (r.isApprove !== null) {
        tempHistory.push(r);
      }
    });

    setHistoryRows(
      tempHistory.sort(function (a, b) {
        return new Date(b.approveDate) - new Date(a.approveDate);
      })
    );
  }, []);

  return (
    <Box>
      <TableCustom
        columns={historyColumns}
        rows={
          leaveEmployeesList && leaveEmployeeWithdrawsList ? 
            historyRows.filter((_, index) => {
              return (index >= (0 + (page * listPerPage)) && index < ((page + 1) * listPerPage));
            })
            : []
        }
      />
      <Box style={{ display:"flex", justifyContent: "right" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={leaveEmployeesList && leaveEmployeeWithdrawsList ? 
            historyRows.length
            : 0}
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={listPerPage}
          onRowsPerPageChange={(event) => {
            setListPerPage(+event.target.value);
            setPage(0);
          }}
          labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${
                            count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
        />
      </Box>
      <DialogConfirmReject
        // rejectCount={selectedRows.length}
        open={isOpenRejectDialog}
        onClose={() => {
          setIsOpenRejectDialog(false);
        }}
        handleSubmit={handleConfirmReject}
      />
    </Box>
  );
};

export default RequestLeave;
