import React, { useState, useEffect, useMemo } from "react";
import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDropzone } from "react-dropzone";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const useStyles = makeStyles(() => ({
  fontBold: {
    fontWeight: 700,
  },
  acceptedFiles: {
    color: "#4caf50",
  },
  rejectedFiles: {
    color: "#f44336",
  },
  uploadIcon: {
    fontSize: "4rem",
  },
}));

export default function ImpactUploadFile(props) {
  const classes = useStyles();
  const {
    inputFields,
    otherFields,
    index,
    impactFiles,
    setImpactFiles,
    fileRejected,
    setFileRejected,
  } = props;

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "image/jpeg, image/png, .pdf",
    maxFiles: 4,
    maxSize: 5242880, //5MB
    onDrop: (files) => {
      let imageTotal = 0;
      let pdfTotal = 0;

      for (var i = 0; i < files.length; i++) {
        let file = files[i];

        if (file.type == "image/jpeg" || file.type == "image/png") {
          imageTotal++;
        }

        if (file.type == "application/pdf") {
          pdfTotal++;
        }
      }

      if (imageTotal > 2 || pdfTotal > 2) {
        setFileRejected(true);
      } else {
        setFileRejected(false);
        setImpactFiles(files);
        if (inputFields !== undefined) {
          inputFields[index].files = files;
        }

        if (otherFields !== undefined) {
          otherFields[index].files = files;
        }
      }
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={9} lg={9}>
          <Typography variant="body1" className={classes.fontBold}>
            Attach File
          </Typography>
          <Typography variant="body2" color="textSecondary" align="right">
            Image and PDF Maximum 2 Files , Maximum upload file size 5 MB
          </Typography>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />  
            <i className={classes.uploadIcon} class="fa-solid fa-cloud-arrow-up"></i>
            <p>Drag 'n' drop some files here, or click to select files</p>
            <em>(Only *.jpeg and *.png images will be accepted)</em>
          </div>
          <aside>
            <h4>Files</h4>
            <ul
              className={
                fileRejected ? classes.rejectedFiles : classes.acceptedFiles
              }
            >
              {files}
            </ul>
          </aside>
        </Grid>
      </Grid>
    </>
  );
}
