import React from "react";
import { ListItemIcon, Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const MenuUserRecruit = (props) => {
  const { anchorEl, onClose } = props;
  const { t, i18n } = useTranslation();

  return (
    <Menu
      sx={{
        mt: "1px",
        "& .MuiMenu-paper": { backgroundColor: "#46cbe2" },
      }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem component={Link} to={"/recruitment"}>
        <ListItemIcon sx={{ color: "#ffffff" }}>
          <i className="fa-solid fa-briefcase-blank"></i>
        </ListItemIcon>
        {`${t("JobOpenForApplied")}`}
      </MenuItem>
      <MenuItem component={Link} to={"/company/interview"}>
        <ListItemIcon sx={{ color: "#ffffff" }}>
          <i className="fa-solid fa-users"></i>
        </ListItemIcon>
        {`${t("InterviewAppointment")}`}
      </MenuItem>
    </Menu>
  );
};

export default MenuUserRecruit;
