import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Paper,
  Typography,
  MenuItem,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHead,
  IconButton,
  TablePagination,
  Container,
  Avatar,
  Collapse,
  Button,
  TextField,
  Snackbar,
  Alert,
  InputAdornment,
  Divider,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AddEvaluatorDialog from "./component/AddEvaluatorDialog";
import Loading from "../../assets/social-media.gif";
import { disableSpanner, getEmpPotential, postSpanner } from "../../../../actions/potential";
import AlertResponse from "../../shared/general/AlertResponse";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { useTranslation } from "react-i18next";
import DialogConfirm from "../../shared/general/DialogConfirm";
import { getAffiliateOrganization } from "../../../../actions/company";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { getUserFullName } from "../../../../utils/userData";
import { getAllEmployeesByIdCompany } from "../../../../actions/employee";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 16,
  },
});
const StylePaper = styled(Paper)({
  padding: "20px 40px 20px 40px",
  minWidth: 350,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  marginTop: "16px",
  "& .courseCard": {
    width: "255px !important",
  },
});
const CustomGrid = styled(Grid)({
  display: "none",
  ["@media screen and (min-width: 900px)"]: {
    display: "block",
  },
});
const StyledTableContainer = styled(TableContainer)({
  // maxHeight: "620px",
  maxHeight: "500px",
  minHeight: "400px",
  "& .MuiTableHead-root": {
    "& .MuiTableCell-root": {
      borderBottom: "none",
      color: "#637381",
      backgroundColor: "#f4f6f8",
      "&:first-of-type": {
        paddingLeft: 24,
      },
      "&:last-of-type": {
        position: "sticky",
        right: 0,
        zIndex: 4,
      },
    },
  },
  "& .MuiTableBody-root": {
    "& .MuiTableCell-root": {
      borderBottom: "none",
      padding: 16,
      minWidth: "fit-content",
      "&.borderLine": {
        borderRight: "2px solid #db4172",
      },
    },
  },
});
const InnerTableContainer = styled(TableContainer)({
  // maxHeight: "300px",
  // minHeight: "300px",
  "& .MuiTableHead-root": {
    "& .MuiTableCell-root": {
      borderBottom: "none",
      color: "#ffffff",
      backgroundColor: "#46cbe2",
      padding: 8,
      position: "sticky",
      top: 0,
      zIndex: 5,
      "&:first-of-type": {
        paddingLeft: 24,
      },
      "&:last-of-type": {
        right: 0,
      },
    },
  },
  "& .MuiTableBody-root": {
    "& .MuiTableCell-root": {
      height: "40px",
      borderBottom: "none",
      padding: 16,
      minWidth: "fit-content",
      "&.borderLine": {
        borderRight: "2px solid #db4172",
      },
    },
  },
});
const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1) !important",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));
const LabelContainer = styled(Box)({
  textAlign: "left",
  overflowWrap: "anywhere",
  fontWeight: "600",
  width: "100%",
  "& .MuiTypography-root": {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
});

const StyledAutoComplete = styled(Autocomplete)({
  "& .MuiFilledInput-root": {
    padding: "13.5px 14px",
    paddingRight: "32px",
    "& input": {
      padding: 0
    }
  }
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&:after": {
      border: "none",
    },
    "&:before": {
      border: "none",
      borderBottomStyle: "none !important",
    }
  },
});

function AdminPotentail() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { isFetching: employeesIsFetching, result: employeesList } = useSelector((state) => state.employees);
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);
  const [employeeData, setEmployeeData] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [expandedRow, setExpandedRow] = useState(null);
  const handleExpandClick = (index) => {
    if (expandedRow === (page * rowsPerPage) + index) {
      setExpandedRow(null)
      setEvaluatorData(null)
    } else {
      let data = employeeData && employeeData[(page * rowsPerPage) + index] &&
        employeeData[(page * rowsPerPage) + index].potentials &&
        employeeData[(page * rowsPerPage) + index].potentials !== undefined ?
        employeeData[(page * rowsPerPage) + index].potentials : []
      setExpandedRow((page * rowsPerPage) + index)
      // setEvaluatorData(data)
    }
  };

  const [filterEvaluators, setFilterEvaluators] = useState("");
  const [EvaluatorData, setEvaluatorData] = useState(null);
  // const handleSearchEvaluators = (index) => {
  //   let data = employeeData && employeeData[(page * rowsPerPage) + index] &&
  //     employeeData[(page * rowsPerPage) + index].potentials &&
  //     employeeData[(page * rowsPerPage) + index].potentials !== undefined ?
  //     employeeData[(page * rowsPerPage) + index].potentials : []

  //   setEvaluatorData(
  //     data ? (filterEvaluators === "" ? data
  //       : data.filter(p => {
  //         return (p.firstname_TH || p.lastname_TH) && `${p.firstname_TH} ${p.lastname_TH}`.includes(filterEvaluators)
  //       })
  //     ) : []
  //   )
  // }

  const [openDialog, setOpenDialog] = useState({
    open: '',
    idEmployee: null,
    idSpannerList: new Set(),
  });
  const handleOpenDrawer = (data) => {
    let potentialData = data && data.potentials || []
    let dialogData = {
      open: 'Add',
      idEmployee: data.idEmployees || null,
      idSpannerList: potentialData ? new Set(potentialData.map(emp => emp.idEmpspanner)) : new Set(),
    }
    setOpenDialog(dialogData)
  }
  const handleOpenDialog = (idPotentialRole) => {
    setOpenDialog({
      open: 'Delete',
      idPotentialRole: idPotentialRole || null,
      idEmployee: null,
      idSpannerList: new Set(),
    })
  }
  const handleCloseDialog = () => {
    setOpenDialog({
      open: false,
      idEmployee: null,
      idSpannerList: new Set(),
    })
  }

  const [openAlert, setOpenAlert] = useState({
    open: false,
    alertType: null,
    label: null,
  });
  const handleOpenAlert = (alertType, label) => {
    setOpenAlert({
      open: true,
      alertType: alertType,
      label: label,
    });
  };
  const handleCloseAlert = () => {
    setOpenAlert({
      open: false,
      alertType: null,
      label: null,
    });
  };

  const [filterConfig, setFilterConfig] = useState({
    type: "name",
    name: "",
  });
  const handleChangeFilter = (event) => {
    const { name, value } = event.target;
    setFilterConfig(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const fetchedEmpPotential = async () => {
    try {
      const response = await getEmpPotential({
        terminate: 0,
        [filterConfig.type]: filterConfig.name,
        idCompany: selectedCompany && selectedCompany.idCompany
      });
      if (response && response.data) {
        const data = response.data;
        setEmployeeData(response.data);
        if (expandedRow) {
          setEvaluatorData(data[expandedRow] && data[expandedRow].potentials || []);
        };
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values) => {
    const { list } = values;
    let formData = {
      list: list || []
    };

    try {
      const response = await postSpanner(openDialog.idEmployee, formData);
      if (response && response.status === 200) {
        handleOpenAlert("success", "เพิ่มผู้ประเมินสำเร็จ");
        await fetchedEmpPotential();
      } else {
        handleOpenAlert("error", "เกิดข้อผิดพลาดในการเพิ่มผู้ประเมิน");
      }
    } catch (error) {
      handleOpenAlert("error", "เกิดข้อผิดพลาดในการเพิ่มผู้ประเมิน");
    } finally {
      handleCloseDialog();
    }
  };

  const handleSubmitConfirm = async () => {
    if (openDialog.idPotentialRole && openDialog.idPotentialRole !== undefined) {
      try {
        const response = await disableSpanner(openDialog.idPotentialRole);
        if (response && response.status === 200) {
          await fetchedEmpPotential();
          handleOpenAlert("success", "ลบผู้ประเมินสำเร็จ");
        } else {
          handleOpenAlert("error", "ลบผู้ประเมินไม่สำเร็จ");
        }
      } catch (error) {
        handleOpenAlert("error", "An error occurred. Please try again.");
        console.error("Error during deletion:", error);
      } finally {
        handleCloseDialog();
      }
    } else {
      handleOpenAlert("error", "Invalid role selected");
      handleCloseDialog();
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      fetchedEmpPotential();
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (employeeData) {
      if (expandedRow) {
        setEvaluatorData(employeeData[expandedRow] && employeeData[expandedRow].potentials || [])
      };
    };
  }, [employeeData, expandedRow]);

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
  };

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  // useEffect(() => {
  //   if (employeesList && selectedCompany) {
  //     setSelectedEmployee(employeesList[0]);
  //   }
  // }, [employeesList, selectedCompany]);

  // const handleSearch = async () => {
  //   setPage(0);
  //   await dispatch(getEmpPotential({ terminate: 0, [filterConfig.type]: filterConfig.name, idCompany: selectedCompany && selectedCompany.idCompany }));
  // };

  useEffect(() => {
    if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      let ownCompany = affiliateOrganizationList.find(x => x.idCompany === userProfile.idCompany);
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany);
    }
  }, [userProfile, affiliateOrganizationList]);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getAllEmployeesByIdCompany(selectedCompany.idCompany));
    }
  }, [selectedCompany]);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 20, paddingBottom: 20 }}>
          {t("PotentialTestsManagement")}
        </Typography>
        <StylePaper>
          <Grid
            container
            spacing={2}
            alignItems={"center"}
            style={{ marginBottom: "30px" }}
          >
            <Grid item xs={12} md={6}>
              <Typography fontSize={18} gutterBottom>
                {`${t("Company")}`}
              </Typography>
              <SelectAffiliateCompany
                options={affiliateOrganizationList}
                value={selectedCompany}
                onChange={(_, value) => {
                  onChangeCompany(value)
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography fontSize={18} gutterBottom>
                {`${t("Employee")}`}
              </Typography>
              <StyledAutoComplete
                options={employeesList ? employeesList : []}
                getOptionLabel={(option) =>
                  getUserFullName(option)
                }
                isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    InputProps={{ ...params.InputProps, endAdornment: null }}
                    variant="filled"
                    placeholder={t("SearchEmp")}
                  />
                )}
                value={selectedEmployee}
                onChange={(event, newValue) => {
                  setSelectedEmployee(newValue);
                }}
                noOptionsText={t("NoData")}
              />
            </Grid>
            {/* <Grid item xs={12} md={2}>
              <TextFieldTheme
                name="type"
                value={filterConfig.type}
                onChange={handleChangeFilter}
                style={{ width: "100%" }}
                select
              >
                <MenuItem value="name">ชื่อ-สกุล</MenuItem>
                <MenuItem value="position">ตำแหน่ง</MenuItem>
                <MenuItem value="section">แผนก</MenuItem>
                <MenuItem value="division">ฝ่าย</MenuItem>
              </TextFieldTheme>
            </Grid>

            <Grid item xs={12} md={5}>
              <TextFieldTheme
                name="name"
                value={filterConfig.name}
                onChange={handleChangeFilter}
                InputProps={{
                  placeholder: "ค้นหา..",
                }}
              />
            </Grid> */}
            <CustomGrid item md={2} />
          </Grid>
          <StyledTableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: "250px" }}>{t("FullName")}</TableCell>
                  <TableCell style={{ minWidth: "150px" }}>{t("Position")}</TableCell>
                  <TableCell style={{ minWidth: "90px" }}>{t("Section")}</TableCell>
                  <TableCell style={{ minWidth: "90px" }}>{t("Department")}</TableCell>
                  <TableCell style={{ minWidth: "90px" }}>{t("Division")}</TableCell>
                  <TableCell style={{ minWidth: "25px" }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {employeesIsFetching ? (
                  <TableRow height="350px">
                    <TableCell colSpan={10} align="center">
                      <img width="120" alt="loading" src={Loading} />
                    </TableCell>
                  </TableRow>
                ) : (
                  <Fragment>
                    {employeeData && employeeData.length > 0 ?
                      employeeData
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .filter(e => (selectedEmployee && selectedEmployee.idEmployees ? e.idEmployees === selectedEmployee.idEmployees : true))
                        .map((row, index) => (
                          <Fragment key={index}>
                            <TableRow>
                              <TableCell className="borderLine">
                                <LabelContainer>
                                  <Box display="flex" alignItems="center">
                                    <Avatar alt={`${row.firstname_TH} ${row.lastname_TH}`} src="" /> {/* Placeholder for profile picture */}
                                    <Typography style={{ marginLeft: 8 }}>{`${row.firstname_TH} ${row.lastname_TH}`}</Typography>
                                  </Box>
                                </LabelContainer>
                              </TableCell>
                              <TableCell>
                                <LabelContainer>
                                  <Typography variant="body1">{row.positionName}</Typography>
                                </LabelContainer>
                              </TableCell>
                              <TableCell>
                                <LabelContainer>
                                  <Typography variant="body1">{row.sectionName}</Typography>
                                </LabelContainer>
                              </TableCell>
                              <TableCell>
                                <LabelContainer>
                                  <Typography variant="body1">{row.departmentName}</Typography>
                                </LabelContainer>
                              </TableCell>
                              <TableCell>
                                <LabelContainer>
                                  <Typography variant="body1">{row.divisionName}</Typography>
                                </LabelContainer>
                              </TableCell>
                              <TableCell>
                                <IconButton onClick={() => handleExpandClick(index)}>
                                  {expandedRow === (page * rowsPerPage) + index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                <Collapse in={expandedRow === (page * rowsPerPage) + index} timeout="auto" unmountOnExit>
                                  <Divider style={{ marginBottom: "16px" }} />
                                  <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="h6" gutterBottom component="div">
                                      {t("Assessor")} {row.potentials && row.potentials.length || 0} {t("ItemName")}
                                    </Typography>
                                    <ButtonBlue
                                      variant="contained"
                                      color="secondary"
                                      radius="style2"
                                      onClick={() => handleOpenDrawer(row)}
                                      style={{ padding: "8px 25px" }}
                                    >
                                      <AddRoundedIcon style={{ marginRight: "5px" }} />
                                      {t("Add")} {t("Assessor")}
                                    </ButtonBlue>
                                  </Box>
                                  <TextField
                                    value={filterEvaluators}
                                    onChange={(e) => setFilterEvaluators(e.target.value)}
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    size="small"
                                    InputProps={{
                                      placeholder: t("SearchFullName"),
                                      endAdornment:
                                        // <InputAdornment position="end">
                                        //   <IconButton
                                        //     aria-label="Search"
                                        //     onClick={() => handleSearchEvaluators(index)}
                                        //   >
                                        //     <SearchRoundedIcon />
                                        //   </IconButton>
                                        // </InputAdornment>
                                        null
                                    }}
                                  />
                                  <InnerTableContainer style={{ marginTop: 10 }}>
                                    <Table size="small" aria-label="purchases">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell style={{ padding: "16px" }}>{t("FullName")}</TableCell>
                                          <TableCell style={{ padding: "16px" }}>{t("Position")}</TableCell>
                                          <TableCell style={{ padding: "16px" }}>{t("Role")}</TableCell>
                                          <TableCell style={{ padding: "16px" }}>{t("Status")}</TableCell>
                                          <TableCell style={{ padding: "16px" }}>{t("Score")}</TableCell>
                                          <TableCell style={{ padding: "16px" }}>{t("Manage")}</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {/* {getFilteredApproveList(row.idEmployees, searchEvaluators[row.idEmployees]).map((approve, approveIndex) => ( */}
                                        {row.potentials && row.potentials
                                          .filter((e) => {
                                            const fullName = `${e.firstname_TH || ""} ${e.lastname_TH || ""}`.trim();
                                            return filterEvaluators === "" || fullName.includes(filterEvaluators);
                                          })
                                          .map((approve, approveIndex) => (
                                            <TableRow key={approveIndex}>
                                              <StyledCellContent>{`${approve.firstname_TH} ${approve.lastname_TH}`}</StyledCellContent>
                                              <StyledCellContent>{approve.positionName}</StyledCellContent>
                                              <StyledCellContent>{approve.role}</StyledCellContent>
                                              <StyledCellContent>{approve.status || "-"}</StyledCellContent>
                                              <StyledCellContent>{approve.sumAnswer || 0}</StyledCellContent>
                                              <StyledCellContent>
                                                {approve.isManager === 0 ?
                                                  <IconButton style={{ color: "red", marginBottom: "-20px", marginTop: "-20px" }} onClick={() => handleOpenDialog(approve.idPotentialRole)}>
                                                    <DeleteIcon />
                                                  </IconButton>
                                                  : null}
                                              </StyledCellContent>
                                            </TableRow>
                                          ))}
                                      </TableBody>
                                    </Table>
                                  </InnerTableContainer>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </Fragment>
                        )) : (
                        <TableRow>
                          <TableCell colSpan={10} align="center">
                            <Box height="120px" display="flex" justifyContent="center" alignItems="center">
                              <Typography fontSize="20px" color="text.secondary">{t("NoResult")}</Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                  </Fragment>
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={employeeData ? employeeData.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t("RowsPerPage")}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t("OutOf")} ${count !== -1 ? count : `${t("MoreThan")} ${to}`}`}
          />

          {openDialog.open === 'Add' &&
            <AddEvaluatorDialog
              open={openDialog.open === 'Add'}
              onClose={handleCloseDialog}
              onSubmit={handleSubmit}
              selectedEmployee={openDialog.idSpannerList}
            />
          }
          {openDialog.open === 'Delete' && (
            <DialogConfirm
              open={openDialog.open === 'Delete'}
              content1={t("AddQuestionsAtTheEndofTheLesson")}
              content2={t("ConfirmAddingNewQuestion")}
              handleClose={handleCloseDialog}
              handleConfirm={handleSubmitConfirm}
            />
          )}
          <AlertResponse
            open={openAlert.open}
            handleClose={handleCloseAlert}
            alertType={openAlert.alertType}
            label={openAlert.label}
          />
        </StylePaper>
      </Container>
    </StyledRoot>
  );
}

export default AdminPotentail;
