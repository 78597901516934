import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography, styled } from "@mui/material";

import EditRoundedIcon from "@mui/icons-material/EditRounded";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DividerStyle from "../../shared/general/Divider";

import Signature from "../../assets/signature.png";

import DialogEdit from "./DialogEdit";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  "& .signature": {
    height: 100,
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "&.inner": {
    marginBottom: 0,
  },
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const TabCompany = (props) => {

  const { selectedCompany } = props;

  const { result: companyProfile } = useSelector(
    (state) => state.companyProfile
  );
  const { t, i18n } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);
  const [mode, setMode] = useState(null);

  const handleClick = (mode) => {
    setOpenDialog(true);
    setMode(mode);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <StyledRoot>
      <StyledWrapTop>
        <Typography variant="body2" style={{ fontWeight: 600 }}>
          {`${t("GeneralInformation")}`}
        </Typography>
        <ButtonBlue
          size="small"
          startIcon={<EditRoundedIcon />}
          onClick={() => handleClick("general")}
        >
          {`${t("EditData")}`}
        </ButtonBlue>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">{`${t(
            "CompanyCode"
          )}`}</StyledHeadLabel>
          <Typography variant="h6">
            {companyProfile.companyCode || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">
            {t("CompanyTaxID")}
          </StyledHeadLabel>
          <Typography variant="h6">
            {companyProfile.companyTaxId || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">
            Social Security A/C
          </StyledHeadLabel>
          <Typography variant="h6">
            {companyProfile.socialSecurityAccount || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">
            Provident Fund Code
          </StyledHeadLabel>
          <Typography variant="h6">
            {companyProfile.providentFundCode || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">
            {t("BranchNumber")}
          </StyledHeadLabel>
          <Typography variant="h6">
            {companyProfile.branchCode || "-"}
          </Typography>
        </Grid>
      </Grid>
      <DividerStyle />
      <StyledWrapTop>
        <Typography variant="body2" style={{ fontWeight: 600 }}>
          {`${t("Contact")}`}
        </Typography>
        <ButtonBlue
          size="small"
          startIcon={<EditRoundedIcon />}
          onClick={() => handleClick("contact")}
        >
          {`${t("EditData")}`}
        </ButtonBlue>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">
            {`${t("CompanyPhone")}`}
          </StyledHeadLabel>
          <Typography variant="h6">
            {companyProfile.companyTelephone || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">{`${t(
            "CompanyEmail"
          )}`}</StyledHeadLabel>
          <Typography variant="h6">
            {companyProfile.companyEmail || "-"}
          </Typography>
        </Grid>
      </Grid>
      <DividerStyle />
      <StyledWrapTop>
        <Typography variant="body2" style={{ fontWeight: 600 }}>
          {`${t("AuthorizedSignatory")}`}
        </Typography>
        <ButtonBlue
          size="small"
          startIcon={<EditRoundedIcon />}
          onClick={() => handleClick("authorized")}
        >
          {`${t("EditData")}`}
        </ButtonBlue>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">
            {`${t("AuthorizedSignatoryFirst")}`}
          </StyledHeadLabel>
          <Typography variant="h6">
            {companyProfile.authorizedSignatureOneName || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">
            {`${t("PositionOfAuthorizedSignatoryFirst")}`}
          </StyledHeadLabel>
          <Typography variant="h6">
            {companyProfile.authorizedSignatureOnePosition || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">
            {`${t("AuthorizedSignatorySecond")}`}
          </StyledHeadLabel>
          <Typography variant="h6">
            {companyProfile.authorizedSignatureTwoName || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">
            {`${t("PositionOfAuthorizedSignatorySecond")}`}
          </StyledHeadLabel>
          <Typography variant="h6">
            {companyProfile.authorizedSignatureTwoPosition || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">{`${t(
            "WitnessName"
          )}`}</StyledHeadLabel>
          <Typography variant="h6">
            {companyProfile.witnessSignatureName || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">{`${t(
            "WitnessPosition"
          )}`}</StyledHeadLabel>
          <Typography variant="h6">
            {companyProfile.witnessSignaturePosition || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">
            {`${t("NameOfTheCertifyingAuthority")}`}
          </StyledHeadLabel>
          <Typography variant="h6">
            {companyProfile.certificateSignatureName || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">
            {`${t("PositionOfTheCertifyingAuthority")}`}
          </StyledHeadLabel>
          <Typography variant="h6">
            {companyProfile.certificateSignaturePosition || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">
            {`${t("NameOfTheCertifyingAuthority(English)")}`}
          </StyledHeadLabel>
          <Typography variant="h6">
            {companyProfile.certificateSignatureName_EN || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">
            {`${t("PositionOfTheCertifyingAuthority(English)")}`}
          </StyledHeadLabel>
          <Typography variant="h6">
            {companyProfile.certificateSignaturePosition_EN || "-"}
          </Typography>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary" gutterBottom>
            ลายเซ็นต์
          </StyledHeadLabel>
          <img src={Signature} className="signature" />
        </Grid> */}
      </Grid>

      <DialogEdit
        mode={mode}
        open={openDialog}
        handleCloseDialog={handleCloseDialog}
        selectedCompany={selectedCompany}
      />
    </StyledRoot>
  );
};

export default TabCompany;
