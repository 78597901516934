import exportExcelService from "../services/exportExcel.service";

export const exportExcelLeaveReport = (query) => async () => {
  try {
    const res = await exportExcelService.exportExcelLeaveReport(query);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
};

export const exportExcelLeaveAbsentLateReport = (query) => async () => {
  try {
    const res = await exportExcelService.exportExcelLeaveAbsentLateReport(query);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
};

export const exportRequestListReport = (query) => async () => {
  try {
    const res = await exportExcelService.exportExcelRequestListReport(query);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
}

export const exportExcelEmployeeInformationReport = (query) => async () => {
  try {
    const res = await exportExcelService.exportExcelEmployeeInformationReport(query);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
}

export const exportExcelAttendanceMonthlyReport = (query) => async () => {
  try {
    const res = await exportExcelService.exportExcelAttendanceMonthlyReport(query);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
}