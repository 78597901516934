import React, { useState } from "react";
import { useSelector } from "react-redux";

// import UserOverview from "../user/overview";
import OverviewUser from "../user/overviewUser";
import OverviewManager from "../manager/overviewUser";

const OverviewUserPage = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <div>
      {userProfile ? (
        userProfile.roles.includes("ROLE_MANAGER") ? (
          <OverviewManager />
        ) :
          userProfile.roles.includes("ROLE_USER") ? (
            <OverviewUser />
          ) : (
            null
          )
      ) : null}
    </div>
  );
};

export default OverviewUserPage;
