import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Box, Grid, MenuItem, Typography, styled } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../actions/company";
import { Controller, useForm } from "react-hook-form";
import { getAffiliateCompany } from "../../../../actions/affiliate";
import { useTranslation } from "react-i18next";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import leaveService from "../../../../services/leave.service";
import dayjs from "dayjs";
import { exportExcelLeaveReport } from "../../../../actions/exportExcel";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePickerCustom from "../../shared/date/datePicker";
import { getUserCompany } from "../../../../utils/userData";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .field-label": {
    fontSize: "14px",
    fontWeight: "500",
    paddingBottom: "4px",
  },
});

const DrawerExportExcelLeaveReport = (props) => {

  const { drawerConfig, onClose } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // const { result: companyProfile } = useSelector((state) => state.companyProfile);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateList } = useSelector((state) => state.affiliate);

  const [leaveRoundList, setLeaveRoundList] = useState([]);
  const [selectedLeaveRound, setSelectedLeaveRound] = useState({
    date: null,
    start: null,
    end: null,
  });

  const validateYupSchema = yup.object({
    company: yup.mixed().nullable().required(t("PleaseSelectInformation")),
    leaveRoundDate: yup.string().required(t("PleaseSelectInformation")),
  });

  const useHookForm = useForm({
    defaultValues: {
      company: null,
      leaveRoundDate: "",
      start: null,
      end: null,
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  const fetchLeaveRound = (idCompany) => {
    leaveService.getLeaveRoundList({idCompany: idCompany}).then(res => {
      setLeaveRoundList(res.data)
      const today = dayjs();
      const foundLeaveRound = res.data.find(item => (
        dayjs(today).isBetween(
          dayjs(item.start),
          dayjs(item.end),
          "[]"
        )
      ))
      useHookForm.resetField("leaveRoundDate", { defaultValue: foundLeaveRound.date });
      useHookForm.resetField("start", { defaultValue: dayjs(foundLeaveRound.start) });
      useHookForm.resetField("end", { defaultValue: dayjs(foundLeaveRound.end) });
      setSelectedLeaveRound({
        date: foundLeaveRound.date,
        start: foundLeaveRound.start,
        end: foundLeaveRound.end,
      })
    }).catch(error => {
      setLeaveRoundList([])
      useHookForm.resetField("leaveRoundDate", { defaultValue: "" })
    })
  }

  const renderValueLeaveRound = (value) => {
    const foundLeaveRoundValue = leaveRoundList.find(item => item.date === value)

    if(foundLeaveRoundValue){
      return <Typography>
        {i18n.language === "th"? 
          `${dayjs(foundLeaveRoundValue.start).format("D MMMM BBBB")} - ${dayjs(foundLeaveRoundValue.end).format("D MMMM BBBB")}`
        : 
          `${dayjs(foundLeaveRoundValue.start).locale(i18n.language).format("D MMMM YYYY")} - ${dayjs(foundLeaveRoundValue.end).locale(i18n.language).format("D MMMM YYYY")}`
        }
      </Typography>
    } else {
      return <Typography>-</Typography>
    }
  }

  const handleChangeLeaveRound = (value) => {
    const foundLeaveRound = leaveRoundList.find(item => item.date === value);
    if(foundLeaveRound){
      setSelectedLeaveRound({
        date: foundLeaveRound.date,
        start: foundLeaveRound.start,
        end: foundLeaveRound.end,
      })
      useHookForm.setValue("start", dayjs(foundLeaveRound.start));
      useHookForm.setValue("end", dayjs(foundLeaveRound.end));
    }
  }

  useEffect(() => {
    if(drawerConfig.isOpen){
      dispatch(getAffiliateCompany())
    }
  }, [drawerConfig.isOpen])

  useEffect(() => {
    if(drawerConfig.isOpen && affiliateList && affiliateList.length > 0){
      const foundCompany = affiliateList.find(x => x.idCompany === userProfile.idCompany);
      if(foundCompany){
        useHookForm.resetField("company", {defaultValue: foundCompany})
        fetchLeaveRound(foundCompany.idCompany);
      } else {
        useHookForm.resetField("company", {defaultValue: null})
      }
    }
  }, [affiliateList]);

  const onSubmit = async (data) => {

    const foundLeaveRound = leaveRoundList.find(item => data.leaveRoundDate === item.date)

    let leaveRoundName = "";

    if(foundLeaveRound){
      leaveRoundName = `${dayjs(foundLeaveRound.start).format("D MMMM BBBB")} - ${dayjs(foundLeaveRound.end).format("D MMMM BBBB")}`
    } else {
      leaveRoundName = dayjs(data.leaveRoundDate).format("BBBB")
    }

    const result = await dispatch(
      exportExcelLeaveReport({
        date: data.leaveRoundDate,
        idCompany: data.company.idCompany,
        start: dayjs(data.start).format("YYYY-MM-DD"),
        end: dayjs(data.end).format("YYYY-MM-DD"),
      })
    );

    if(result && result.status === 200){
      const excelBlob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(excelBlob);
        downloadLink.download = `UniHR ${getUserCompany(data.company)} ${t("LeaveReport")} ${t("LeaveCycle")} ${leaveRoundName}.xlsx`;
        downloadLink.click();
    } else {

    }
  }

  return (
    <DrawerCustom
      title={t("DownloadLeaveReport")}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color="text.secondary" fontSize="14px" fontWeight="500" marginBottom="8px">{t("Company")}</Typography>
              <Controller
                name="company"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <Autocomplete
                    options={affiliateList? affiliateList: []}
                    getOptionLabel={(option) => `${getUserCompany(option)}`}
                    isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        placeholder={`${t("SelectCompany")}`}
                        onBlur={field.onBlur}
                        helperText={fieldState.error? fieldState.error.message: null}
                        error={fieldState.error? true: false}
                      />
                    )}
                    value={field.value}
                    onChange={(_, value) => {
                      field.onChange(value);
                      fetchLeaveRound(value.idCompany);
                    }}
                    noOptionsText={`${t("NoData")}`}
                    disableClearable
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography color="text.secondary" fontSize="14px" fontWeight="500" marginBottom="8px">{t("SelectLeaveRound")}</Typography>
              <Controller
                name="leaveRoundDate"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    select
                    helperText={fieldState.error? fieldState.error.message: null}
                    error={fieldState.error? true: false}
                    SelectProps={{
                      displayEmpty: true,
                      renderValue: (selected) => {
                        if(selected.length === 0){
                          return <Typography color="text.secondary">{t("SelectLeaveRound")}</Typography>
                        } else {
                          return renderValueLeaveRound(selected)
                        }
                      }
                    }}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      handleChangeLeaveRound(event.target.value);
                    }}
                  >
                    <MenuItem value="" disabled>{t("SelectLeaveRound")}</MenuItem>
                    {leaveRoundList.map(item => (
                      <MenuItem value={item.date}>
                        {i18n.language === "th"? 
                          `${dayjs(item.start).format("D MMMM BBBB")} - ${dayjs(item.end).format("D MMMM BBBB")}`
                        : 
                          `${dayjs(item.start).locale(i18n.language).format("D MMMM YYYY")} - ${dayjs(item.end).locale(i18n.language).format("D MMMM YYYY")}`
                        }
                      </MenuItem>
                    ))}
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography color="text.secondary" fontSize="14px" fontWeight="500" marginBottom="8px">{t("StartDate")}</Typography>
              <Controller
                control={useHookForm.control}
                name="start"
                render={({field, fieldState}) => (
                  <DatePickerCustom
                    {...field}
                    minDate={dayjs(selectedLeaveRound.start)}
                    maxDate={dayjs(selectedLeaveRound.end)}
                    inputFormat="DD MMMM YYYY"
                    value={field.value}
                    views={['day', 'month', 'year']}
                    openTo={'day'}
                    inputProps={{
                      readOnly: true
                    }}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                      if(dayjs(useHookForm.getValues("end")).diff(dayjs(newValue), 'day') < 0){
                        useHookForm.setValue("end", dayjs(newValue))
                      }
                      useHookForm.trigger("end")
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        error={fieldState.error? true: false}
                        // helperText={
                        //   errors.endDate && errors.endDate.message
                        // }
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography color="text.secondary" fontSize="14px" fontWeight="500" marginBottom="8px">{t("EndDate")}</Typography>
              <Controller
                control={useHookForm.control}
                name="end"
                render={({field, fieldState}) => (
                  <DatePickerCustom
                    {...field}
                    minDate={dayjs(selectedLeaveRound.start)}
                    maxDate={dayjs(selectedLeaveRound.end)}
                    inputFormat="DD MMMM YYYY"
                    value={field.value}
                    views={['day', 'month', 'year']}
                    openTo={'day'}
                    inputProps={{
                      readOnly: true
                    }}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        error={fieldState.error? true: false}
                        // helperText={
                        //   errors.endDate && errors.endDate.message
                        // }
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose}>{t("Cancel")}</ButtonBlue>
              <ButtonBlue
                type="submit"
                variant="outlined"
                startIcon={<DownloadRoundedIcon />}
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Download")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerExportExcelLeaveReport;