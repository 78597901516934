import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, Box, IconButton, Divider } from "@mui/material";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { updateExitInterview } from "../../../../../actions/exitInterview";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
  "& .btn-save": {
    marginTop: 16,
    display: "flex",
    justifyContent: "flex-end",
  },
});

const DialogExitInterview = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { open, handleClose, idEdu, type, handleSubmitExitInterview, isReadOnly } = props;
  const { result: ExitInterview } = useSelector(
    (state) => state.getExitInterview
  );

  const [idQuestionList, setIdQuestionList] = useState([]);

  const validationSchema = yup.object({
    questionList: yup.array().of(
      yup.object().shape({
        question: yup
          .string()
          .max(100, `${t("UpTo")} 100 ${t("Characters")}`)
          .required(`${t("PleaseFillInTheQuestion")}`),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { errors, dirtyFields },
    reset,
    setError,
  } = useForm({
    defaultValues: {
      questionList: [{ question: "" }],
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (formData) => {
    const cleansingForm = {
      questionList: formData.questionList,
      deleteQuestionList: idQuestionList,
      idExitInterview:
        (ExitInterview &&
          ExitInterview.length > 0 &&
          ExitInterview[0].idExitInterview) ||
        null,
    };
    console.log("formData", cleansingForm);

    const response = await dispatch(updateExitInterview(cleansingForm));
    handleSubmitExitInterview(response);
  };

  const questionUseFieldArray = useFieldArray({
    rules: { maxLength: 10 },
    control: control,
    name: "questionList",
  });

  const initialValueQuestionData = {
    question: "",
  };

  useEffect(() => {
    setArryField();
  }, []);

  const setArryField = () => {
    if (ExitInterview !== null) {
      questionUseFieldArray.replace(ExitInterview);
    }
  };

  return (
    <DrawerCustom
      title={t("AddCancelExitInterviewQuestion")}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Grid item xs={12} sm={12} className="GridTopicInput">
            {t("ExitInterviewQuestion")}
          </Grid>
          {questionUseFieldArray.fields.map((question, index) => (
            <Grid key={question.id} container spacing={1} marginTop={1}>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <div>
                  <i className="fa-regular fa-square-question fa-bounce fa-xl"></i>
                  &nbsp;&nbsp;{`${t("Question")} ${index + 1}`}
                </div>
                {questionUseFieldArray.fields.length > 1 && (
                  <IconButton
                    color="error"
                    onClick={() => {
                      questionUseFieldArray.remove(index);
                      setIdQuestionList([
                        ...idQuestionList,
                        question.idQuestionExitInterview,
                      ]);
                    }}
                  >
                    <i className="fa-regular fa-trash-can"></i>
                  </IconButton>
                )}
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={`questionList.${index}.question`}
                  render={({ field, fieldState }) => (
                    <TextFieldTheme
                      {...field}
                      multiline
                      maxRows={2}
                      autoComplete="degree"
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error && fieldState.error.message}
                    ></TextFieldTheme>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider
                  sx={{
                    marginTop: "16px",
                    borderColor: "#9e9e9e66",
                  }}
                />
              </Grid>
            </Grid>
          ))}
          {questionUseFieldArray.fields.length < 10 && (
            <Box className="BoxContent" sx={{ marginTop: "10px" }}>
              <ButtonBlue
                variant="outlined"
                startIcon={<i className="fa-regular fa-plus"></i>}
                onClick={() => {
                  questionUseFieldArray.append(initialValueQuestionData);
                }}
              >
                {t("AddQuestion")}
              </ButtonBlue>
            </Box>
          )}
          {questionUseFieldArray.fields.length <= 0 && (
            <Grid item xs={12}>
              <Typography textAlign="center" color="text.secondary">
                {t("NoQuestions")}
              </Typography>
            </Grid>
          )}
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleClose}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            autoFocus
            disabled={isReadOnly}
          >
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogExitInterview;
