import React, { useState, useEffect, useRef } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import {
  TableRow,
  TableCell,
  TextField,
  Typography,
  IconButton,
  Popper,
  Box,
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";

import DatePickerCustom from "../../../../shared/date/datePicker";
import dayjs from "dayjs";
import "dayjs/locale/th";
import utils from "../../../../../../utils";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import DragHandleRoundedIcon from "@mui/icons-material/DragHandleRounded";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";

const StyledTableRow = styled(TableRow)({
  marginBottom: 2,
});

const StyledTableCell = styled(TableCell)({
  padding: 0,
  border: "none",
  "& .wrap": {
    height: 72,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    borderTop: "1px solid #e1e1e1",
    borderLeft: "1px solid #e1e1e1",
    borderBottom: "1px solid #e1e1e1",
    "&.first": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    "&.last": {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      borderRight: "1px solid #e1e1e1",
    },
  },
  "&.date-document": {
    width: 140,
    "& .MuiInputBase-root": {
      paddingRight: 8,
    },
  },
  "&.service": {
    width: 150,
  },
  "&.description": {
    width: 200,
  },
  "&.amount": {
    width: 104,
  },
  "&.price": {
    width: 104,
  },
  "&.discount": {
    width: 104,
  },
  "&.tax": {
    width: 64,
  },
  "&.price-before-tax": {
    width: 104,
  },
  "&.tax-withholding": {
    width: 104,
  },
  "&.delete": {
    width: 32,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  margin: "0px !important",
  input: {
    textAlign: "right",
  },
  "& .MuiInputBase-root": {
    borderRadius: 0,
    height: 60,
    "& fieldset": {
      border: "none",
    },
    "& .MuiInputBase-input": {
      padding: "5px !important",
    },
  },
});

const StyledTextArea = styled(TextField)({
  width: "100%",
  margin: "0px !important",
  "& .MuiInputBase-root": {
    borderRadius: 0,
    padding: "5px !important",
    height: 72,
    "& textarea": {
      height: "72px !important",
      overflow: "auto !important",
    },
    "& fieldset": {
      border: "none",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    paddingLeft: 0,
    paddingRight: "48px !important",
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledButtonTax = styled(Button)({
  minWidth: 32,
  "&:hover": {
    backgroundColor: "transparent",
  },
});

const RowItemExpense = (props) => {
  const {
    index,
    handleDeleteRow,
    count,
    control,
    errors,
    calculateRowSum,
    addDeleteRow,
    type,
    getValues,
  } = props;
  const today = dayjs().toDate();
  const [documentDate, setDocumentDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate())
  );

  return (
    <StyledTableRow>
      <StyledTableCell className=""></StyledTableCell>
      <StyledTableCell className="date-document">
        <div className="wrap first">
          <FormControl
            fullWidth
            error={
              errors &&
              errors.listExpense &&
              errors.listExpense[index] &&
              errors.listExpense[index].documentDate
                ? true
                : false
            }
          >
            <Controller
              name={`listExpense.${index}.documentDate`}
              control={control}
              errors={errors}
              render={({ field }) => (
                <DatePickerCustom
                  {...field}
                  inputFormat="DD/MM/YYYY"
                  onChange={(e) => {
                    field.onChange(dayjs(e).format("YYYY/MM/DD"));
                  }}
                  renderInput={(params) => (
                    <StyledTextField fullWidth {...params} />
                  )}
                />
              )}
            />
            {errors &&
              errors.listExpense &&
              errors.listExpense[index] &&
              errors.listExpense[index].documentDate &&
              errors.listExpense[index].documentDate.message && (
                <FormHelperText
                  error
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {errors.listExpense[index].documentDate.message}
                </FormHelperText>
              )}
          </FormControl>
          {/* <LocalizationProvider
            dateAdapter={AdapterDateFns}
            localeText={localStorage.getItem("language")}
          >
            <DatePicker
              inputFormat="dd/MM/yyyy"
              value={data.documentDate}
              name="start"
              onChange={handleChangeDate}
              renderInput={(params) => (
                <StyledTextField fullWidth {...params} />
              )}
            />
          </LocalizationProvider> */}
        </div>
      </StyledTableCell>
      <StyledTableCell className="cell-table service">
        {/* <div className="wrap">
          <StyledAutocomplete
            fullWidth
            name="currency"
            options={currency()}
            onChange={(event, newValue) => {
              console.log(newValue);
            }}
            popupIcon={<i class="fa-light fa-chevron-down"></i>}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                <img
                  loading="lazy"
                  width="20"
                  style={{ marginRight: 16, flexShrink: 0 }}
                  src={`https://flagcdn.com/16x12/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/32x24/${option.code.toLowerCase()}.png 2x`}
                  alt={option.code}
                />
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                variant="outlined"
                placeholder=""
                name="currency"
              />
            )}
            PopperComponent={StyledPopper}
            noOptionsText={t("NoData")}
          />
        </div> */}
        <div className="wrap">
          <FormControl
            fullWidth
            error={
              errors &&
              errors.listExpense &&
              errors.listExpense[index] &&
              errors.listExpense[index].service
                ? true
                : false
            }
          >
            <Controller
              name={`listExpense.${index}.service`}
              control={control}
              errors={errors}
              render={({ field }) => (
                <>
                  <StyledTextArea {...field} />
                </>
              )}
            />
            {errors &&
              errors.listExpense &&
              errors.listExpense[index] &&
              errors.listExpense[index].service &&
              errors.listExpense[index].service.message && (
                <FormHelperText
                  error
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {errors.listExpense[index].service.message}
                </FormHelperText>
              )}
          </FormControl>
        </div>
      </StyledTableCell>
      <StyledTableCell className="cell-table description">
        <div className="wrap">
          <FormControl
            fullWidth
            error={
              errors &&
              errors.listExpense &&
              errors.listExpense[index] &&
              errors.listExpense[index].description
                ? true
                : false
            }
          >
            <Controller
              name={`listExpense.${index}.description`}
              control={control}
              errors={errors}
              render={({ field }) => (
                <>
                  <StyledTextArea {...field} />
                </>
              )}
            />
            {errors &&
              errors.listExpense &&
              errors.listExpense[index] &&
              errors.listExpense[index].description &&
              errors.listExpense[index].description.message && (
                <FormHelperText
                  error
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {errors.listExpense[index].description.message}
                </FormHelperText>
              )}
          </FormControl>
        </div>
      </StyledTableCell>
      <StyledTableCell className="cell-table amount">
        <div className="wrap">
          <FormControl
            fullWidth
            error={
              errors &&
              errors.listExpense &&
              errors.listExpense[index] &&
              errors.listExpense[index].amount
                ? true
                : false
            }
          >
            <Controller
              name={`listExpense.${index}.amount`}
              control={control}
              errors={errors}
              render={({ field }) => (
                <>
                  <StyledTextArea
                    {...field}
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                    }}
                    inputProps={{
                      decimalScale: 2,
                      allowNegative: false,
                      value: field.value,
                      onValueChange: (values) => {
                        const { value } = values;
                        field.onChange(value);
                        calculateRowSum(index);
                      },
                    }}
                    onChange={() => {}}
                  />
                </>
              )}
            />
            {errors &&
              errors.listExpense &&
              errors.listExpense[index] &&
              errors.listExpense[index].amount &&
              errors.listExpense[index].amount.message && (
                <FormHelperText
                  error
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {errors.listExpense[index].amount.message}
                </FormHelperText>
              )}
          </FormControl>
        </div>
      </StyledTableCell>
      <StyledTableCell className="cell-table price">
        <div className="wrap">
          <FormControl
            fullWidth
            error={
              errors &&
              errors.listExpense &&
              errors.listExpense[index] &&
              errors.listExpense[index].unitPrice
                ? true
                : false
            }
          >
            <Controller
              name={`listExpense.${index}.unitPrice`}
              control={control}
              errors={errors}
              render={({ field }) => (
                <>
                  <StyledTextArea
                    {...field}
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                    }}
                    inputProps={{
                      decimalScale: 2,
                      allowNegative: false,
                      value: field.value,
                      onValueChange: (values) => {
                        const { value } = values;
                        field.onChange(value);
                        calculateRowSum(index);
                      },
                    }}
                    onChange={() => {}}
                  />
                </>
              )}
            />
            {errors &&
              errors.listExpense &&
              errors.listExpense[index] &&
              errors.listExpense[index].unitPrice &&
              errors.listExpense[index].unitPrice.message && (
                <FormHelperText
                  error
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {errors.listExpense[index].unitPrice.message}
                </FormHelperText>
              )}
          </FormControl>
        </div>
      </StyledTableCell>
      {/* <StyledTableCell className="discount">
        <StyledTextField />
      </StyledTableCell> */}
      <StyledTableCell className="cell-table tax" align="center">
        <div className="wrap">
          <FormControl
            fullWidth
            error={
              errors &&
              errors.listExpense &&
              errors.listExpense[index] &&
              errors.listExpense[index].taxPercent
                ? true
                : false
            }
          >
            <Controller
              name={`listExpense.${index}.taxPercent`}
              control={control}
              errors={errors}
              render={({ field }) => (
                <>
                  <StyledTextArea
                    {...field}
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                    }}
                    inputProps={{
                      decimalScale: 2,
                      allowNegative: false,
                      value: field.value,
                      suffix: "%",
                      onValueChange: (values) => {
                        const { value } = values;
                        field.onChange(value);
                        calculateRowSum(index);
                      },
                    }}
                    onChange={() => {}}
                  />
                  {/* <span style={{ marginRight: 10 }}>%</span> */}
                </>
              )}
            />
            {errors &&
              errors.listExpense &&
              errors.listExpense[index] &&
              errors.listExpense[index].taxPercent &&
              errors.listExpense[index].taxPercent.message && (
                <FormHelperText
                  error
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {errors.listExpense[index].taxPercent.message}
                </FormHelperText>
              )}
          </FormControl>
        </div>
      </StyledTableCell>
      <StyledTableCell className="cell-table price-before-tax">
        <div className="wrap">
          <FormControl
            fullWidth
            error={
              errors &&
              errors.listExpense &&
              errors.listExpense[index] &&
              errors.listExpense[index].total
                ? true
                : false
            }
          >
            <Controller
              name={`listExpense.${index}.total`}
              control={control}
              errors={errors}
              render={({ field }) => (
                <>
                  <StyledTextArea
                    {...field}
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                    }}
                    inputProps={{
                      decimalScale: 2,
                      allowNegative: false,
                      value: field.value,
                      onValueChange: (values) => {
                        const { value } = values;
                        field.onChange(value);
                        calculateRowSum(index);
                      },
                    }}
                    disabled
                    onChange={() => {}}
                  />
                </>
              )}
            />
            {errors &&
              errors.listExpense &&
              errors.listExpense[index] &&
              errors.listExpense[index].total &&
              errors.listExpense[index].total.message && (
                <FormHelperText
                  error
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {errors.listExpense[index].total.message}
                </FormHelperText>
              )}
          </FormControl>
          {/* <StyledTextField
            name="totalPrice"
            type="number"
            value={data.total}
            onChange={handleChangeValue}
            
          /> */}
        </div>
      </StyledTableCell>
      <StyledTableCell className="cell-table tax-withholding">
        <div className="wrap last">
          <FormControl
            error={
              errors &&
              errors.listExpense &&
              errors.listExpense[index] &&
              errors.listExpense[index].withholdingPercent
                ? true
                : false
            }
          >
            <Controller
              name={`listExpense.${index}.withholdingPercent`}
              control={control}
              errors={errors}
              render={({ field }) => (
                <>
                  <StyledTextArea
                    // {...field}
                    // type="number"
                    textAlign="center"
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                    }}
                    inputProps={{
                      min: 0,
                      max: 100,
                      decimalScale: 2,
                      allowNegative: false,
                      suffix: "%",
                      value: field.value,
                      onValueChange: (values) => {
                        const { value } = values;
                        field.onChange(value);
                        calculateRowSum(index);
                      },
                    }}
                    onChange={() => {}}
                  />
                  {/* <span style={{ marginRight: 10 }}>%</span> */}
                </>
              )}
            />
            {errors &&
              errors.listExpense &&
              errors.listExpense[index] &&
              errors.listExpense[index].withholdingPercent &&
              errors.listExpense[index].withholdingPercent.message && (
                <FormHelperText
                  error
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {errors.listExpense[index].withholdingPercent.message}
                </FormHelperText>
              )}
          </FormControl>
        </div>
      </StyledTableCell>
      <StyledTableCell className="delete" align="center">
        {count > 1 && (
          <span>
            {type === "edit" ? (
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => {
                  addDeleteRow(index);
                  handleDeleteRow(index);
                }}
              >
                <DeleteOutlineRoundedIcon fontSize="small" />
              </IconButton>
            ) : (
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => {
                  handleDeleteRow(index);
                }}
              >
                <DeleteOutlineRoundedIcon fontSize="small" />
              </IconButton>
            )}
          </span>
        )}
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default RowItemExpense;
