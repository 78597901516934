import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { useDispatch } from "react-redux";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";
import {
  getAdditionSpecial100,
  updateAdditionSpecial100,
} from "../../../../../../actions/additionSpecial";
import InputNumber from "./inputNumber";
import { useTranslation } from "react-i18next";

const AS100Edit = ({ openEdit, handleCloseEdit, selectAddition }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const validateSchema = yup.object({
    isActive: yup.boolean(),
    amount: yup
      .number()
      .transform((val, orig) => (orig === "" ? undefined : val))
      .min(1, `${t("Minimum")} 1`)
      .max(1000000, `${t("Maximum")} 1000000`)
      .required(t("ThisFieldIsRequired")),
  });

  const handleUpdateAddition = async (formData) => {
    const res = await dispatch(updateAdditionSpecial100(formData));
    if (res.status === 200) {
      dispatch(openNotificationAlert({ message: "success", type: "success" }));
      dispatch(getAdditionSpecial100(selectAddition.idCompany));
    } else {
      dispatch(openNotificationAlert({ message: "error", type: "error" }));
    }
    handleCloseEdit();
  };

  const { control, handleSubmit } = useForm({
    defaultValues: {
      idAdditionSpecial100: selectAddition.idAdditionSpecial100,
      amount: selectAddition.amount,
      isActive: selectAddition.isActive,
    },
    resolver: yupResolver(validateSchema),
  });

  return (
    <Dialog open={openEdit}>
      <DialogTitle>{t("Edit")}</DialogTitle>
      <form onSubmit={handleSubmit(handleUpdateAddition)}>
        <DialogContent>
          <Stack spacing={1}>
            <div>
              <Typography variant="h6">{`${t("From")} ${selectAddition.locationStart}`}</Typography>
              <Typography variant="h6">{`${t("To")} ${selectAddition.locationEnd}`}</Typography>
            </div>
            <InputNumber
              control={control}
              name={"amount"}
              label={t("AmountOfMoney")}
            />
            <Controller
              control={control}
              name="isActive"
              render={({ field }) => (
                <FormControlLabel
                  control={<Switch {...field} checked={field.value} />}
                  label={t("Status")}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleCloseEdit}>
            {t("Cancel")}
          </Button>
          <ButtonBlue type="submit" variant="contained">
            {t("Confirm")}
          </ButtonBlue>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AS100Edit;
