import React, { useState } from "react";
import {
    Box, Stack,
    Dialog, DialogActions, Divider,
    Typography, Grid,
    Button, MenuItem, Alert
} from "@mui/material";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { addJobProfile } from "../../../../actions/positions";
import { useTranslation } from "react-i18next";

const DialogAddJobProfile = (props) => {
    const {
        openJobProfile,
        setOpenJobProfile,
        handleCloseJobProfile,
        selectedCompany,
        nextPage,
        setNextPage,
        selectedPositionId,
        addedData,
        setAddedData,
        jobClass,
        setOpenAlert,
        setOpenAlertError
    } = props;

    const { t } = useTranslation();
    const [errorAlert, setErrorAlert] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleNextPage = () => {
        setNextPage((prevPage) => Math.min(prevPage + 1, 5)); // Ensure the page number does not exceed 4
    };

    const handlePreviousPage = () => {
        setNextPage((prevPage) => Math.max(prevPage - 1, 1)); // Ensure the page number does not go below 1
    };

    const onSubmit = async (data) => {
        try {
            setIsSubmitting(true);
            const formData = new FormData();
            Object.entries(data).forEach(([key, value]) => {
                formData.append(key, value);
            });

            formData.append("idPosition", selectedPositionId);

            const response = await addJobProfile(formData);
            if (response && response.status === 200) {
                setOpenJobProfile(false);
                setOpenAlert(true);
                setIsSubmitting(false);
            } else {
                setOpenJobProfile(false);
                setOpenAlertError(true);
                setIsSubmitting(false);
            }
        } catch (error) {
            console.error(error);
            setOpenJobProfile(false);
            setOpenAlertError(true);
            setIsSubmitting(false);
        }
    };

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setAddedData(prevData => {
            const updatedData = {
                ...prevData,
                [name]: value
            };

            // Calculate the midpoint if min_salary or max_salary is updated
            if (name === "min_salary" || name === "max_salary") {
                const minSalary = parseFloat(updatedData.min_salary) || 0;
                const maxSalary = parseFloat(updatedData.max_salary) || 0;
                updatedData.midpoint_salary = ((minSalary + maxSalary) / 2).toFixed(2);
            }

            return updatedData;
        });
    };

    const numberCircle = (number, message) => {
        return (
            <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={1}
            >
                <div
                    style={{
                        backgroundColor: "#D9D9D9",
                        padding: 5,
                        borderRadius: "50%",
                        width: 20,
                        height: 20,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <div style={{ color: "#757575", textAlign: "center", fontSize: 16, fontWeight: 'bold' }}>
                        {number}
                    </div>
                </div>
                <Typography fontSize={18} fontWeight={600}>
                    {message}
                </Typography>
            </Stack>
        )
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const maxSizeMB = 5;

        if (file) {
            if (file.size > maxSizeMB * 1024 * 1024) {
                setErrorAlert(true);
            } else {
                setSelectedImage(URL.createObjectURL(file));
                setAddedData(prev => ({
                    ...prev,
                    image: file
                }));
            }
        }
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
        setAddedData(prev => ({
            ...prev,
            image: null
        }));
    };

    const handleCloseAlertError = () => {
        setErrorAlert(false);
    };

    return (
        <Dialog
            open={openJobProfile}
            onClose={handleCloseJobProfile}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: { width: "1000px" }
            }}
        >
            <Box sx={{ height: 700 }}>
                <Box sx={{ mt: 2 }}>
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                        sx={{ p: 4 }}
                    >
                        {[1, 2, 3, 4, 5].map((item, index) => (
                            <Stack
                                alignItems="center"
                                direction="row"
                                key={item}
                            >
                                <div
                                    style={{
                                        backgroundColor: nextPage >= item ? "#DB4178" : "#D9D9D9",
                                        padding: 10,
                                        borderRadius: "50%",
                                        width: 30,
                                        height: 30,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                >
                                    <div style={{ color: nextPage >= item ? "#FFFFFF" : "#BDBDBD", textAlign: "center", fontSize: 20, fontWeight: 'bold' }}>
                                        {item}
                                    </div>
                                </div>
                                {index < 4 && (
                                    <Divider
                                        orientation="horizontal"
                                        sx={{ bgcolor: nextPage > item ? "#DB4178" : "#D9D9D9", height: 3, width: 40 }}
                                    />
                                )}
                            </Stack>
                        ))}
                    </Stack>
                </Box>

                {nextPage === 1 && (
                    <Stack sx={{ p: 2 }} spacing={4}>
                        <Stack spacing={2}>
                            {numberCircle(1, `${t("overallQualification")}/${t("jobFit")}`)}
                            <TextFieldTheme
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                name={"overall_qualification"}
                                value={addedData.overall_qualification}
                                onChange={(e) => handleFieldChange(e)}
                            />
                        </Stack>

                        <Stack spacing={2}>
                            {numberCircle(2, t("requireExperience"))}
                            <TextFieldTheme
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                name={"require_experience"}
                                value={addedData.require_experience}
                                onChange={(e) => handleFieldChange(e)}
                            />
                        </Stack>
                    </Stack>
                )}

                {nextPage === 2 && (
                    <Stack sx={{ p: 2 }} spacing={4}>
                        <Stack spacing={2}>
                            {numberCircle(3, `${t("JobDescription")}/${t("keyRole")}`)}
                            <TextFieldTheme
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                name={"description"}
                                value={addedData.description}
                                onChange={(e) => handleFieldChange(e)}
                            />
                        </Stack>

                        <Stack spacing={2}>
                            {numberCircle(4, t("specialRequirements"))}
                            <TextFieldTheme
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                name={"special_requirement"}
                                value={addedData.special_requirement}
                                onChange={(e) => handleFieldChange(e)}
                            />
                        </Stack>
                    </Stack>
                )}

                {nextPage === 3 && (
                    <Box sx={{ p: 2 }}>
                        {numberCircle(5, t("Education"))}
                        <Stack spacing={2} sx={{ p: 2 }}>
                            <Stack spacing={1}>
                                <Typography fontSize={16} fontWeight={600}>
                                    {t("minimumDegree")}
                                </Typography>
                                <Box sx={{ minWidth: "60%" }}>
                                    <TextFieldTheme
                                        select
                                        name="minimum_Education"
                                        value={addedData.minimum_Education}
                                        onChange={(e) => handleFieldChange(e)}
                                    >
                                        <MenuItem value="ประถมศึกษา">{`${t(
                                            "PrimaryEducation"
                                        )}`}</MenuItem>
                                        <MenuItem value="มัธยมศึกษาตอนต้น">
                                            {`${t("JuniorHighSchoolEducation")}`}
                                        </MenuItem>
                                        <MenuItem value="มัธยมศึกษาตอนปลาย">
                                            {`${t("SeniorHighSchoolEducation")}`}
                                        </MenuItem>
                                        <MenuItem value="ปวช.">{`${t(
                                            "VocationalCertificate"
                                        )}`}</MenuItem>
                                        <MenuItem value="ปวส.">{`${t(
                                            "​CertificateOfTechnicalVocation"
                                        )}`}</MenuItem>
                                        <MenuItem value="ปริญญาตรี">{`${t(
                                            "BachelorDegrees"
                                        )}`}</MenuItem>
                                        <MenuItem value="ปริญญาโท">{`${t(
                                            "MasterDegrees"
                                        )}`}</MenuItem>
                                        <MenuItem value="ปริญญาเอก">{`${t(
                                            "DoctorDegrees"
                                        )}`}</MenuItem>
                                    </TextFieldTheme>
                                </Box>
                            </Stack>
                            <Stack spacing={1}>
                                <Typography fontSize={16} fontWeight={600}>
                                    {t("Faculty")}
                                </Typography>
                                <TextFieldTheme
                                    variant="outlined"
                                    fullWidth
                                    onChange={(e) => handleFieldChange(e)}
                                    name="faculty"
                                    value={addedData.faculty}
                                />
                            </Stack>
                        </Stack>
                    </Box>
                )}

                {nextPage === 4 && (
                    <Box sx={{ p: 4 }}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {/* First Column */}
                                <Grid item xs={6}>
                                    <Stack spacing={2}>
                                        <Stack spacing={2}>
                                            {numberCircle(6, t("minSalary"))}
                                            <TextFieldTheme
                                                variant="outlined"
                                                fullWidth
                                                name="min_salary"
                                                type="number"
                                                value={addedData.min_salary}
                                                onChange={(e) => handleFieldChange(e)}
                                            />
                                        </Stack>

                                        <Stack spacing={2}>
                                            {numberCircle(7, t("maxSalary"))}
                                            <TextFieldTheme
                                                variant="outlined"
                                                fullWidth
                                                name="max_salary"
                                                type="number"
                                                value={addedData.max_salary}
                                                onChange={(e) => handleFieldChange(e)}
                                            />
                                        </Stack>

                                        <Stack spacing={2}>
                                            {numberCircle(8, t("midpoint"))}
                                            <TextFieldTheme
                                                variant="outlined"
                                                fullWidth
                                                name="midpoint_salary"
                                                type="number"
                                                value={addedData.midpoint_salary}
                                                readOnly
                                            />
                                        </Stack>
                                    </Stack>
                                </Grid>

                                {/* Second Column */}
                                <Grid item xs={6}>
                                    <Stack spacing={2}>
                                        <Stack spacing={2}>
                                            {numberCircle(9, t("JEScore"))}
                                            <TextFieldTheme
                                                variant="outlined"
                                                fullWidth
                                                name="je_score"
                                                type="number"
                                                value={addedData.je_score}
                                                onChange={(e) => handleFieldChange(e)}
                                            />
                                        </Stack>

                                        <Stack spacing={2}>
                                            {numberCircle(10, t("jobLevelMin"))}
                                            <TextFieldTheme
                                                select
                                                name="idJobLevelMin"
                                                value={addedData.idJobLevelMin}
                                                onChange={(e) => handleFieldChange(e)}
                                            >
                                                {jobClass && jobClass.map(item => (
                                                    <MenuItem key={item.idJobLevel} value={item.idJobLevel}>{item.jobLevelName}</MenuItem>
                                                ))}
                                            </TextFieldTheme>
                                        </Stack>

                                        <Stack spacing={2}>
                                            {numberCircle(11, t("jobLevelMax"))}
                                            <TextFieldTheme
                                                select
                                                name="idJobLevelMax"
                                                value={addedData.idJobLevelMax}
                                                onChange={(e) => handleFieldChange(e)}
                                            >
                                                {jobClass && jobClass.map(item => (
                                                    <MenuItem key={item.idJobLevel} value={item.idJobLevel}>{item.jobLevelName}</MenuItem>
                                                ))}
                                            </TextFieldTheme>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                )}

                {nextPage === 5 && (
                    <div>
                        <Stack justifyContent="center" alignItems="center" spacing={3}>
                            {numberCircle(12, t("uploadImage"))}
                            {selectedImage ? (
                                <Box>
                                    <img
                                        src={selectedImage}
                                        alt="Selected"
                                        style={{
                                            width: 200,
                                            height: 200,
                                            borderRadius: "100%",
                                        }}
                                    />
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        bgcolor: "#DADADA",
                                        width: 200,
                                        height: 200,
                                        borderRadius: "50%",
                                    }}
                                >
                                    <Typography fontSize={20} fontWeight={500}>
                                        {t("NoImageSelected")}
                                    </Typography>
                                </Box>
                            )}

                            {errorAlert ? (
                                <Alert severity="error" onClose={() => handleCloseAlertError()}>
                                    {t("file_too_large")}
                                </Alert>
                            ) : (
                                <Stack justifyContent={"center"} alignItems={"center"} spacing={2} direction={"row"}>
                                    <Button
                                        variant="outlined"
                                        component="label"
                                    >
                                        {selectedImage ? t("updateImageFile") : t("AddImage")}
                                        <input
                                            type="file"
                                            accept="image/*"
                                            hidden
                                            onChange={handleImageChange}
                                        />
                                    </Button>

                                    {selectedImage && (
                                        <Button
                                            variant="outlined"
                                            onClick={() => handleRemoveImage()}
                                            color="error"
                                        >
                                            {t("Cancel")}
                                        </Button>
                                    )}
                                </Stack>
                            )}
                        </Stack>

                        <Stack
                            justifyContent={"center"}
                            alignItems={"center"}
                            spacing={1}
                            sx={{ mt: 6 }}
                        >
                            {!addedData.overall_qualification && (
                                <Alert severity="error" sx={{ width: 350 }}>
                                    {t("notQualificationFieldYet")}
                                </Alert>
                            )}

                            {!addedData.require_experience && (
                                <Alert severity="error" sx={{ width: 350 }}>
                                    {t("notRequireExperienceFieldYet")}
                                </Alert>
                            )}
                        </Stack>
                    </div>
                )}
            </Box>

            <DialogActions>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={2}
                    sx={{ p: 2 }}
                >
                    {nextPage > 1 && (
                        <ButtonBlue variant={"contained"} onClick={handlePreviousPage}>
                            {t("Back")}
                        </ButtonBlue>
                    )}

                    {nextPage < 5 ? (
                        <ButtonBlue variant={"contained"} onClick={handleNextPage}>
                            {t("Next")}
                        </ButtonBlue>
                    ) : (
                        <ButtonBlue
                            variant={"contained"}
                            onClick={() => onSubmit(addedData)}
                            disabled={!addedData.overall_qualification || !addedData.require_experience || isSubmitting}
                        >
                            {t("Save")}
                        </ButtonBlue>
                    )}
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default DialogAddJobProfile;