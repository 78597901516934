import * as React from "react";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import StyledCard from "../../../shared/general/Card";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Container from "@mui/material/Container";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import dayjs from "dayjs";
import * as Yup from "yup";
import { useFormik } from "formik";

import StepOne from "./stepOne";
import StepTwo from "./stepTwo";
import StepThree from "./stepThree";
import StepFour from "./stepFour";

import ButtonBlue from "../../../shared/general/ButtonBlue";

import { addnewShift } from "../../../../../actions/shift";
import { getAllWorkTypes } from "../../../../../actions/workType";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledContent = styled("div")({
  padding: 24,
  "& .headerStep": {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
  },
  "& .amount": {
    width: "100%",
  },
  "& .otHours": {
    width: 40,
    "& input": {
      textAlign: "center",
    },
  },
});

export default function DialogAddShift(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [step, setStep] = React.useState(1);
  const [tableShift, setTableShift] = React.useState(null);
  const [dataNewShift, setDataNewShift] = React.useState({
    nameShift: "",
    created: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    start: dayjs(new Date(2021, 11, 1)),
    reason: "",
    workday: 3,
    offday: 3,
    amountShift: 4,
    patternShift: null,
    startInMondy: false,
    lateIn: 0,
    workType: 1,
    periodTime: [
      {
        periodTime: `${t("DayShift")}`,
        periodTimeEN: "DAY",
        check: true,
        timeStartHours: "7",
        timeStartMinute: "30",
        timeEndHours: "20",
        timeEndMinute: "0",
        lateIn: 0,
        checkBreakTime: false,
        breakTime: 0,
      },
      {
        periodTime: `${t("MidShift")}`,
        periodTimeEN: "MID",
        check: false,
        timeStartHours: "",
        timeStartMinute: "",
        timeEndHours: "",
        timeEndMinute: "",
        lateIn: 0,
        checkBreakTime: false,
        breakTime: 0,
      },
      {
        periodTime: `${t("NightShift")}`,
        periodTimeEN: "NIGHT",
        check: true,
        timeStartHours: "20",
        timeStartMinute: "0",
        timeEndHours: "7",
        timeEndMinute: "30",
        lateIn: 0,
        checkBreakTime: false,
        breakTime: 0,
      },
    ],
  });
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    dispatch(getAllWorkTypes());
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = async () => {
    let tempPeriodTime = [];
    dataNewShift.periodTime.map((time) => {
      tempPeriodTime.push({
        ...time,
        timeStart: dayjs()
          .hour(parseInt(time.timeStartHours))
          .minute(parseInt(time.timeStartMinute))
          .format("HH:mm:ss"),
        timeEnd: dayjs()
          .hour(parseInt(time.timeEndHours))
          .minute(parseInt(time.timeEndMinute))
          .format("HH:mm:ss"),
      });
    });
    let data = {
      ...dataNewShift,
      start: dayjs(dataNewShift.start).format("YYYY-MM-DD HH:mm:ss"),
      periodTime: tempPeriodTime,
      tableShift: tableShift,
      idWorkingType: dataNewShift.workType,
    };

    delete data.workType;

    let result = await dispatch(addnewShift(data));
    setOpen(!open);
    let id = setInterval(function () {
      props.history.push("/admin/shift");
      clearInterval(id);
    }, 3000);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <React.Fragment>
            <LooksOneIcon style={{ fontSize: 48, color: "#007afe" }} />
            <Typography variant="h6">{`${t("DetailsShift")}`}</Typography>
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <LooksTwoIcon style={{ fontSize: 48, color: "#007afe" }} />
            <Typography variant="h6">{`${t("ShiftGroup")}`}</Typography>
          </React.Fragment>
        );
      case 3:
        return (
          <React.Fragment>
            <Looks3Icon style={{ fontSize: 48, color: "#007afe" }} />
            <Typography variant="h6">{`${t(
              "ScheduleShiftPatterns"
            )}`}</Typography>
          </React.Fragment>
        );
      case 4:
        return (
          <React.Fragment>
            <Looks4Icon style={{ fontSize: 48, color: "#007afe" }} />
            <Typography variant="h6">{`${t(
              "ExampleShiftSchedule"
            )}`}</Typography>
          </React.Fragment>
        );
    }
  };

  const onChange = (event) => {
    setDataNewShift({
      ...dataNewShift,
      [event.target.name]: event.target.value,
    });
  };

  const onChangeDataNewShift = (data) => {
    setDataNewShift(data);
  };

  const onChangeDate = (newValue) => {
    setDataNewShift({
      ...dataNewShift,
      [`start`]: newValue,
    });
  };

  const onChangeCheckBox = (event) => {
    setDataNewShift({
      ...dataNewShift,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeChecked = (value) => (event) => {
    var tempDataNewShift = { ...dataNewShift };
    tempDataNewShift.periodTime.forEach((element) => {
      if (element.periodTime === value) {
        element[event.target.name] = event.target.checked;
        if (event.target.checked === false && event.target.name === "check") {
          element.timeStartHours = "";
          element.timeStartMinute = "";
          element.timeEndHours = "";
          element.timeEndMinute = "";
          element.lateIn = 0;
          element.checkBreakTime = false;
          element.breakTime = 0;
        } else if (
          event.target.checked === false &&
          event.target.name === "checkBreakTime"
        ) {
          element.breakTime = 0;
        }
      }
    });
    setDataNewShift(tempDataNewShift);
  };

  const handleChangeTime = (name, unit, periodTime, value) => {
    var tempDataNewShift = { ...dataNewShift };
    tempDataNewShift.periodTime.forEach((element) => {
      if (element.periodTime === periodTime) {
        element[name] = value;
        if (name.includes("Ot")) {
          element.checkOT = true;
        } else {
          element.check = true;
        }
      }
    });

    setDataNewShift(tempDataNewShift);
  };

  const validationSchema = Yup.object().shape({
    nameShift: Yup.string().required("required"),
    created: Yup.date().default(function () {
      return dayjs().toDate();
    }),
    start: Yup.date()
      .required()
      .default(function () {
        return dayjs(new Date(2021, 11, 1));
      }),
    reason: Yup.string(),
    workday: Yup.number()
      .required()
      .positive()
      .integer()
      .min(1, `${t("NotLessThan")} 1`)
      .max(7, `${t("NotMoreThan")} 7`),
    offday: Yup.number()
      .required()
      .positive()
      .integer()
      .min(1, `${t("NotLessThan")} 1`)
      .max(7, `${t("NotMoreThan")} 7`),
    amountShift: Yup.number()
      .required()
      .positive()
      .integer()
      .min(1, `${t("NotLessThan")} 1`)
      .max(8, `${t("NotMoreThan")} 7`),
    patternShift: {},
    periodTime: Yup.array().of(
      Yup.object().shape({
        periodTime: Yup.string(),
        periodTimeEN: Yup.string(),
        check: Yup.bool().default(function () {
          return false;
        }),
        timeStartHours: Yup.string().required(
          `${t("ThisFieldIsRequired")}``${t("TimeIn")}``(${t("Hours")})`
        ),
        timeStartMinute: Yup.string().required(
          `${t("ThisFieldIsRequired")}``${t("TimeIn")}``(${t("Minute")})`
        ),
        timeEndHours: Yup.string().required(
          `${t("ThisFieldIsRequired")}``${t("TimeOut")}``(${t("Hours")})`
        ),
        timeEndMinute: Yup.string().required(
          `${t("ThisFieldIsRequired")}``${t("TimeOut")}``(${t("Minute")})`
        ),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {},
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          {`${t("AddShift")}`}
        </Typography>
        <StyledCard>
          <StyledContent>
            <div className={`headerStep`}>{renderStep()}</div>

            {step === 1 && (
              <StepOne
                formik={formik}
                onChangeDate={onChangeDate}
                onChange={onChange}
                dataNewShift={dataNewShift}
                onChangeCheckBox={onChangeCheckBox}
              />
            )}

            {step === 2 && (
              <StepTwo
                dataNewShift={dataNewShift}
                handleChangeChecked={handleChangeChecked}
                handleChangeTime={handleChangeTime}
              />
            )}

            {step === 3 && (
              <StepThree
                dataNewShift={dataNewShift}
                onChangeDataNewShift={onChangeDataNewShift}
              />
            )}

            {step === 4 && (
              <StepFour
                dataNewShift={dataNewShift}
                setStep={setStep}
                tableShift={tableShift}
                setTableShift={setTableShift}
              />
            )}

            <div
              style={{
                display: "flex",
                justifyContent: step > 1 ? "space-between" : "flex-end",
              }}
            >
              {step > 1 && (
                <ButtonBlue
                  style={{ marginTop: 16 }}
                  size=""
                  variant={"outlined"}
                  onClick={() => setStep(step - 1)}
                  startIcon={<ArrowBackIosIcon />}
                >
                  {`${t("Back")}`}
                </ButtonBlue>
              )}
              <ButtonBlue
                style={{ marginTop: 16 }}
                size=""
                variant={"contained"}
                onClick={() => (step < 4 ? setStep(step + 1) : handleToggle())}
                startIcon={step === 4 && <SaveIcon />}
                endIcon={step < 4 && <ArrowForwardIosIcon />}
              >
                {step === 4 ? `${t("SaveShiftSchedule")}` : `${t("Next")}`}
              </ButtonBlue>
            </div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
              onClick={handleClose}
            >
              <Alert severity="success">
                <AlertTitle>{`${t("Successfully")}`}</AlertTitle>
                {`${t("SaveShiftSchedule")}`}
                {`${t("Successfully")}`} —{" "}
                <strong>{`${dataNewShift.workday} หยุด ${dataNewShift.offday}`}</strong>
                <br />
                <Typography variant="caption">{`${t("AutoClose")}`}</Typography>
              </Alert>
            </Backdrop>
          </StyledContent>
        </StyledCard>
      </Container>
    </StyledRoot>
  );
}
