import { httpClient } from "./httpClient";

const getLevelGroup = () => {
  return httpClient.get(`/RightsManage/getLevelGroup`);
};

const addRights = (formData) => {
  return httpClient.post(`/RightsManage/addRights`, formData);
};

const getAllRights = () => {
  return httpClient.get(`/RightsManage/getAllRights`);
};

const deleteRight = (formData) => {
  return httpClient.post(`/RightsManage/deleteRight`, formData);
};

const updateRights = (formData) => {
  return httpClient.post(`/RightsManage/updateRights`, formData);
};

export default {
  getLevelGroup,
  addRights,
  getAllRights,
  deleteRight,
  updateRights

};
