import i18n from "../../../../i18n";

const getDownloadHeaderExcel = () => ({
  A1: i18n.t("EmployeeID"),
  B1: i18n.t("Title"),
  C1: i18n.t("FirstName"),
  D1: i18n.t("LastName"),
  E1: "Title",
  F1: "Name",
  G1: "Last name",
  H1: "Nickname",
  I1: "Gender",
  J1: "Personal ID",
  K1: "สัญชาติ",
  L1: i18n.t("Religion"),
  M1: "สถานะ",
  N1: "Passport Number",
  O1: "Work Permit Number",
  P1: "Tax ID",
  Q1: "SSO ID",
  R1: "Birthday",
  S1: "บ้านเลขที่",
  T1: "หมู่บ้าน",
  U1: "หมู่ที่",
  V1: "ซอย",
  W1: "ถนน",
  X1: "ตำบล/แขวง",
  Y1: "อำเภอ/เขต",
  Z1: "จังหวัด",

  AA1: "รหัสไปรณีย์",
  AB1: "username",
  AC1: "Email",
  AD1: "Telephone Mobile",
  AE1: "ผู้ติดต่อหลัก",
  AF1: "ความสัมพันธ์",
  AG1: "เบอร์ติดต่อผู้ใกล้ชิด",
  AH1: "Number of Children",
  AI1: "Spouse's First Name",
  AJ1: "Spouse's Last Name",
  AK1: "Spouse Birthday",
  AL1: "Spouse's Tax ID",
  AM1: "Employment Type",
  AN1: "Employment Time",
  AO1: "บันทึกเวลาทำงาน",
  AP1: "Working Location",
  AQ1: "Main Working Location Point",
  AR1: "Field/Office",
  AS1: "Job Level",
  AT1: "Personnel Level Group",
  AU1: "Personnel Level",
  AV1: "Job Group",
  AW1: "Sub Job Group",
  AX1: "Job Position",
  AY1: "Company",
  AZ1: "Branch",

  BA1: "Business Unit",
  BB1: "Division",
  BC1: "Department",
  BD1: "Section",
  BE1: "Hiring Date",
  BF1: "Reporting Manager Level 1",
  BG1: "Manager Position Level 1",
  BH1: "Manager E-Mail Level 1",
  BI1: "Reporting Manager Level 2",
  BJ1: "Manager Position Level 2",
  BK1: "Manager E-Mail Level 2",
  BL1: "Working Type",
  BM1: "Shift Type",
  BN1: "Shift Name",
  BO1: "Working Time In",
  BP1: "Working Time Out",
  BQ1: "Payment Type",
  BR1: "Payment Round",
  BS1: "Salary",
  BT1: "Last Job Position Change Date",
  BU1: "Last Salary Increase",
  BV1: "Last Salary Increase Date",
  BW1: "Last Special Adjustment Increase",
  BX1: "Last Special Adjustment Increase Date",
  BY1: "Last Job Level Increase Date",
  BZ1: "Last Personnal Level Increase Date",
  
  CA1: "Contract Type",
  CB1: "Retirement Date",
  CC1: "Contract Termainatoin Date",
  CD1: "Status",
  CE1: "Resign Date",
  CF1: "OESY(Y)",
  CG1: "OESY(M)",
  CH1: "OESY",
  CI1: "Serv.(Y)",
  CJ1: "Serv.(M)",
  CK1: "ServYr",
  CL1: "ESY(Y)",
  CM1: "ESY(M)",
  CN1: "ESY",
  CO1: "TIG(Y)",
  CP1: "TIG(M)",
  CQ1: "TIG",
  CR1: "Age(Y)",
  CS1: "Age(M)",
  CT1: "Age",
  CU1: "(1)\nEducation\nDegree",
  CV1: "(1)\nEducation\nSchool or University",
  CW1: "(1)\nFaculty",
  CX1: "(1)\nMajor",
  CY1: "(1)\nFrom Year",
  CZ1: "(1)\nEnd Year",

  DA1: "(1)\nGPA",
  DB1: "(2)\nEducation\nDegree",
  DC1: "(2)\nEducation\nSchool or University",
  DD1: "(2)\nFaculty",
  DE1: "(2)\nMajor",
  DF1: "(2)\nGPA",
  DG1: "(2)\nFrom Year",
  DH1: "(2)\nEnd Year",
  DI1: "(3)\nEducation\nDegree",
  DJ1: "(3)\nEducation\nSchool or University",
  DK1: "(3)\nFaculty",
  DL1: "(3)\nMajor",
  DM1: "(3)\nGPA",
  DN1: "(3)\nFrom Year",
  DO1: "(3)\nEnd Year",
  DP1: "Performance Review Year (Past 1 Year)",
  DQ1: "Performance Review Year (Past 2 Year)",
  DR1: "Performance Review Year (Past 3 Year)",
  DS1: "Performance Review Year (Past 4 Year)",
  DT1: "Performance Review Year (Past 5 Year)",
  DU1: "Potential Review Year (Past 1 Year)",
  DV1: "Potential Review Year (Past 2 Year)",
  DW1: "Potential Review Year (Past 3 Year)",
  DX1: "Leader Role",
  DY1: "Cost Center Charge",
  DZ1: "Cost Element Charge",
  
  EA1: "IO",
  EB1: "Book Bank",
  EC1: "Book Bank Branch Name",
  ED1: "Book ID",
  EE1: "Report ส่งธนาคาร ชื่อธนาคาร",
  EF1: "Report ส่งธนาคาร รหัสธนาคาร",
  EG1: "Report ส่งธนาคาร เลขที่บัญชี",
  EH1: "Report ส่งธนาคาร ชื่อผู้รับเงิน",
  EI1: "Report ส่งธนาคาร อ้างอิง",
  
  // CA1: "(2) From Year",
  // CB1: "(2) End Year",
  // CC1: "(2) GPA",
  // CD1: "(3) Education Degree",
  // CE1: "(3) Education School ot University",
  // CF1: "(3) Faculty",
  // CG1: "(3) Major",
  // CH1: "(3) From Year",
  // CI1: "(3) End Year",
  // CJ1: "(3) GPA",
  // CK1: "Performance Review Year (Past 1 Year)",
  // CL1: "Performance Review Year (Past 2 Year)",
  // CM1: "Performance Review Year (Past 3 Year)",
  // CN1: "Performance Review Year (Past 4 Year)",
  // CO1: "Performance Review Year (Past 5 Year)",
  // CP1: "Cost Center Charge",
  // CQ1: "Cost Element Charge",
  // CR1: "IO",
  // CS1: "Owner",
  // CT1: "บริษัทเจ้าของงาน",
  // CU1: "Book Bank",
  // CV1: "Book Bank Branch Name",
  // CW1: "Book ID",
});

export default getDownloadHeaderExcel;
