import React, { useState } from "react";
import { Button, Dialog, DialogContent, styled, Typography } from "@mui/material";

import ButtonBlue from '../../shared/general/ButtonBlue';
import TextFieldTheme from '../../shared/general/TextFieldTheme';

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  width: 450,
  [theme.breakpoints.down('sm')]: {
      width: 270
  },
}));

const DialogConfirmRejectKpi = (props) => {

  const  { open, onClose, handleSubmit, rejectCount } = props;
  const [comment, setComment] = useState("");

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <StyledDialogContent>
        <Typography variant="h6">ไม่อนุมัติ {rejectCount} รายการ</Typography>
        <TextFieldTheme
          style={{ marginTop: 16 }}
          placeholder="เหตุผล (ไม่บังคับ)"
          onChange={(event)=>{
            setComment(event.target.value)
          }}
        />
        <div style={{ width:"100%", marginTop: 16, display:"flex", justifyContent:"space-between" }}>
          <ButtonBlue onClick={onClose}>ยกเลิก</ButtonBlue>
          <Button 
            variant="contained"
            color="error"
            onClick={() => {
              handleSubmit(comment)
            }}
          >ไม่อนุมัติ</Button>
        </div>
      </StyledDialogContent>
    </Dialog>
  )
}

export default DialogConfirmRejectKpi;