import { httpClient } from "./httpClient";

const getAllDialogueRecords = () => {
  return httpClient.get("dialogueRecords");
};

const postDialogueRecords = (data) => {
  return httpClient.post(`dialogueRecords/postDialogueRecords`, data);
};

export default {
  getAllDialogueRecords,
  postDialogueRecords,
};
