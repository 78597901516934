import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Grid, styled, Typography } from "@mui/material";
import * as Yup from "yup";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import { updateUser, getUserProfile } from "../../../../actions/user";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  // width: 350,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogEmergency = (props) => {
  const { open, handleCloseDialog, handleChangeAlertType, handleOpenAlert } =
    props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [state, setstate] = useState(false);

  // const phoneRegExp = /^0\d\d\d\d\d\d\d\d\d$/;
  const phoneRegExp = /^0\d{9,11}$/;
  const validationSchema = Yup.object({
    emergencyPhone: Yup.string()
      .required(`${t("ThisFieldIsRequired")}`)
      .matches(phoneRegExp, `${t("InvalidPhoneNumber")}`)
      .nullable(),
  });

  useEffect(() => {
    if (userProfile) {
      formik.resetForm();
      formik.setFieldValue("idEmployees", userProfile.idEmployees);
      formik.setFieldValue("emergencyContact", userProfile.emergencyContact);
      formik.setFieldValue(
        "emergencyRelationship",
        userProfile.emergencyRelationship
      );
      formik.setFieldValue("emergencyPhone", userProfile.emergencyPhone);
    }
  }, [userProfile]);

  const formik = useFormik({
    initialValues: {
      idEmployees: "",
      emergencyContact: "",
      emergencyRelationship: "",
      emergencyPhone: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = async () => {
    setstate(true);
    const result = await dispatch(updateUser(formik.values));
    if (result && result.status === 200) {
      handleChangeAlertType("success");
      handleOpenAlert();
      setTimeout(function () {
        handleCloseDialog();
        dispatch(getUserProfile());
      }, 3000);
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
    }
  };

  return (
    <DrawerCustom
      title={`${t("EditEmergencyContactInformation")}`}
      anchor="right"
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        style: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <StyledRoot>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{`${t("ContactName")}`}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="emergencyContact"
                value={formik.values.emergencyContact}
                fullWidth
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{`${t("Relationship")}`}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="emergencyRelationship"
                value={formik.values.emergencyRelationship}
                fullWidth
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{`${t(
                "EmergencyPhone"
              )}`}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="emergencyPhone"
                value={formik.values.emergencyPhone}
                fullWidth
                onChange={formik.handleChange}
                error={
                  formik.touched.emergencyPhone &&
                  Boolean(formik.errors.emergencyPhone)
                }
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.emergencyPhone &&
                  Boolean(formik.errors.emergencyPhone) &&
                  formik.errors.emergencyPhone
                }
              />
            </Grid>
          </Grid>
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleCloseDialog}>
              {`${t("Cancel")}`}
            </ButtonBlue>
            <ButtonBlue
              type="submit"
              variant="contained"
              disabled={!formik.isValid || state}
            >
              {`${t("SaveData")}`}
            </ButtonBlue>
          </StyledFooter>
        </StyledRoot>
      </form>
    </DrawerCustom>
  );
};

export default DialogEmergency;
