import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, styled, Tab, Tabs, Typography } from "@mui/material";
import { OverviewPayroll } from "./OverviewPayroll";
import { getOverviewPayroll } from "../../../../../../../actions/payruns";
import { AllfieldsCard } from "./AllfieldsCard";
import { useTranslation } from "react-i18next";
import loading from "../../../../../assets/social-media.gif";

const StyledRoot = styled(Box)({
    "& .menu-container": {
        boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
        borderRadius: 20,
        backgroundColor: "#ffffff",
        width: "fit-content",
        overflow: "hidden",
    }
});

const StyledTabs = styled(Box)(({ centered }) => ({
    display: "flex",
    justifyContent: Boolean(centered) ? "center" : "left",
    width: "100%",
    "& .Mui-selected": {
        color: "#ffffff !important",
        backgroundColor: "#46cbe2",
    },
    "& .MuiTabs-flexContainer": {
        // justifyContent: "center",
    }
}));

const StyledLoading = styled("div")({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "inherit",
});

export const DashboardPayrollList = (props) => {
    const { t } = useTranslation();
    const { payRunDetail } = props;
    const [menuValue, setMenuValue] = useState(0);
    const [isFetching, setIsFetching] = useState(false);
    const [payrollData, setPayrollData] = useState(null);

    const MenuItems = [
        // { name: 'ภาพรวม', dataField: "all", value: 0 },
        { name: 'ค่าจ้าง', dataField: 'salaryPaid', value: 0 },
        { name: 'รวม OT', dataField: 'totalOT', value: 1 },
        { name: 'รวมเงินได้', dataField: 'totalAdditions', value: 2 },
        { name: 'รวมเงินได้สะสม', dataField: 'totalEarnings', value: 3 },
        { name: 'ประกันสังคม', dataField: 'ssoEmployee', value: 4 },
        { name: 'ภาษี', dataField: 'tax', value: 5 },
        { name: 'กองทุนสำรองเลี้ยงชีพส่วนบริษัท', dataField: 'pfCompanyTHB', value: 6 },
        { name: 'กองทุนสำรองเลี้ยงชีพส่วนพนักงาน', dataField: 'pfEmployeeTHB', value: 7 },
        { name: 'รวมค่ากะ', dataField: 'totalShiftPay', value: 8 },
        { name: 'รวมเงินหัก', dataField: 'totalDeductions', value: 9 },
        { name: 'เงินได้สุทธิ', dataField: 'net', value: 10 },
    ];

    const fetchedOverviewPayroll = async () => {
        try {
            setIsFetching(true);
            const response = await getOverviewPayroll({
                idPayrun: payRunDetail && payRunDetail.idPayrun,
                idCompany: payRunDetail && payRunDetail.idCompany,
                year: payRunDetail && payRunDetail.yearPayrun
            });

            if (response && response.data) {
                setIsFetching(false);
                setPayrollData(response.data);
            } else {
                setIsFetching(false);
            }
        } catch (error) {
            console.error(error);
        };
    };

    useEffect(() => {
        if (payRunDetail && payRunDetail.idPayrun && menuValue) {
            fetchedOverviewPayroll();
        }
    }, [payRunDetail, menuValue]);

    const menuChangeHandler = (_, newValue) => {
        setMenuValue(newValue);
    };

    return (
        <StyledRoot>
            <Box display="flex" justifyContent="flex-start" marginBottom="24px">
                <Box className="menu-container">
                    <StyledTabs centered={Number(true)}>
                        <Tabs
                            value={menuValue}
                            onChange={menuChangeHandler}
                            variant="scrollable"
                            allowScrollButtonsMobile
                            TabIndicatorProps={{
                                style: { display: 'none' }
                            }}
                            // centered={true}
                            visibleScrollbar={true}
                        >
                            {MenuItems.map(((m, index) => (
                                <Tab
                                    key={index}
                                    icon={m.icon}
                                    iconPosition={m.iconPosition || "start"}
                                    label={m.name || ""}
                                />
                            )))}
                        </Tabs>
                    </StyledTabs>
                </Box>
            </Box>

            {/* {menuValue === 0 && (
                <OverviewPayroll
                    payrollData={payrollData}
                />
            )} */}
            {isFetching ? (
                <StyledLoading>
                    <img width="120" alt="loading" src={loading} />
                </StyledLoading>
            ) : (
                <Box>
                    {menuValue === 0 && (
                        <AllfieldsCard
                            payrollData={payrollData}
                            payRunDetail={payRunDetail}
                            field={"salaryPaid"}
                            top10Lists={payrollData && payrollData.top10Salary}
                        />
                    )}
                    {menuValue === 1 && (
                        <AllfieldsCard
                            payrollData={payrollData}
                            payRunDetail={payRunDetail}
                            field={"ot"}
                            top10Lists={payrollData && payrollData.top10TotalOT}
                        />
                    )}
                    {menuValue === 2 && (
                        <AllfieldsCard
                            payrollData={payrollData}
                            payRunDetail={payRunDetail}
                            field={"additionsTaxable"}
                            top10Lists={payrollData && payrollData.top10TotalAdditions}
                        />
                    )}
                    {menuValue === 3 && (
                        <AllfieldsCard
                            payrollData={payrollData}
                            payRunDetail={payRunDetail}
                            field={"totalEarnings"}
                            top10Lists={payrollData && payrollData.top10TotalEarnings}
                        />
                    )}
                    {menuValue === 4 && (
                        <AllfieldsCard
                            payrollData={payrollData}
                            payRunDetail={payRunDetail}
                            field={"ssoEmployee"}
                            top10Lists={payrollData && payrollData.top10TotalSSO}
                        />
                    )}
                    {menuValue === 5 && (
                        <AllfieldsCard
                            payrollData={payrollData}
                            payRunDetail={payRunDetail}
                            field={"tax"}
                            top10Lists={payrollData && payrollData.top10TotalTax}
                        />
                    )}
                    {menuValue === 6 && (
                        <AllfieldsCard
                            payrollData={payrollData}
                            payRunDetail={payRunDetail}
                            field={"pfCompanyTHB"}
                            top10Lists={payrollData && payrollData.top10PfCompanyTHB}
                        />
                    )}
                    {menuValue === 7 && (
                        <AllfieldsCard
                            payrollData={payrollData}
                            payRunDetail={payRunDetail}
                            field={"pfEmployeeTHB"}
                            top10Lists={payrollData && payrollData.top10PfEmployeeTHB}

                        />
                    )}
                    {menuValue === 8 && (
                        <AllfieldsCard
                            payrollData={payrollData}
                            payRunDetail={payRunDetail}
                            field={"totalShiftPay"}
                            top10Lists={payrollData && payrollData.top10TotalShiftPay}
                        />
                    )}
                    {menuValue === 9 && (
                        <AllfieldsCard
                            payrollData={payrollData}
                            payRunDetail={payRunDetail}
                            field={"totalDeduction"}
                            top10Lists={payrollData && payrollData.top10TotalDeductions}
                        />
                    )}
                    {menuValue === 10 && (
                        <AllfieldsCard
                            payrollData={payrollData}
                            payRunDetail={payRunDetail}
                            field={"net"}
                            top10Lists={payrollData && payrollData.top10Net}
                        />
                    )}
                </Box>
            )
            }
        </StyledRoot >
    );
}