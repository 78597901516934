import {
  DASHBOARD_RECRUIT_FETCHING,
  DASHBOARD_RECRUIT_FAILED,
  DASHBOARD_RECRUIT_SUCCESS,
} from "./types";
import DashboardRecriuitService from "../services/dashboardRecruit.service";

export const getDashboardRecruit = () => async (dispatch) => {
  try {
      dispatch({
          type: DASHBOARD_RECRUIT_FETCHING
      });
      const res = await DashboardRecriuitService.getDashboardRecruit();
      if (res) {
          dispatch({
              type: DASHBOARD_RECRUIT_SUCCESS,
              payload: res.data
          });
      }
  } catch (error) {
      dispatch({
          type: DASHBOARD_RECRUIT_FAILED
      });
  }
};