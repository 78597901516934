import React, { useEffect, useState } from "react";
import { Divider, Typography } from "@mui/material";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import CardStyle from "../../../shared/general/Card";
import { useDispatch, useSelector } from "react-redux";
import { getAllEstimateLevel } from "../../../../../actions/estimatePerformance";
import DrawerPerformanceLevel from "./DrawerPerformanceLevel";
import { useTranslation } from "react-i18next";

const EstimateLevel = ({ idCompany }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { result: estimateLevelList } = useSelector(
    (state) => state.estimateLevel
  );

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false)
  };

  useEffect(() => {
    dispatch(getAllEstimateLevel({ idCompany }));
  }, [idCompany]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <Typography fontSize={20} fontWeight={500}>
          {t("PerformanceAssessmentLevels")}
        </Typography>
        <ButtonBlue variant="contained" onClick={() => setOpen(true)}>
          {t("Setting")}
        </ButtonBlue>
      </div>
      <CardStyle style={{ padding: "16px" }}>
        {estimateLevelList && estimateLevelList.length > 0 ? (
          <div style={{ display: "flex", gap: "8px" }}>
            {estimateLevelList
              .filter((item) => item.active)
              .map((item) => (
                <>
                  <Typography variant="h6" key={item.idEstimateLevel}>
                    {item.name}
                  </Typography>
                  <Divider orientation="vertical" flexItem />
                </>
              ))}
          </div>
        ) : (
          <Typography>{t("NoData")}</Typography>
        )}
      </CardStyle>
      {open && (
        <DrawerPerformanceLevel
          open={open}
          handleClose={handleClose}
          idCompany={idCompany}
        />
      )}
    </>
  );
};

export default EstimateLevel;
