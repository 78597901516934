import React, { useEffect, useState } from "react";
import { Box, Container, Grid, styled, Typography } from "@mui/material";
import TableTimeline from "../../shared/tableTimeline";
import { useDispatch, useSelector } from "react-redux";
import { getPayrollSetting } from "../../../../actions/paytypes";
import { getAttendanceById } from "../../../../actions/attendance";
import dayjs from "dayjs";

import DatePickerCustom from "../../shared/date/datePicker";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";

import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { excelExportTimeline } from "../../../../utils";
import DrawerRequestTime from "../../shared/tableTimeline/drawerRequestTime";
import Notification from "../../shared/general/Notification";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { openNotificationAlert } from "../../../../actions/notificationAlert";

dayjs.locale("th");

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 16,
  },
});

const StyledWrapFilter = styled("div")({
  marginBottom: 28,
  display: "flex",
  justifyContent: "space-between",
});

const   Timeline = () => {
  const today = dayjs().toDate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { isFetching: isFetchingAttendance, result: attendanceStore } = useSelector((state) => state.attendance);
  const [search, setSearch] = useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
  });

  const [showDate, setShowDate] = useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
  });

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const [attendanceList, setAttendanceList] = useState([]);

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );

  const [drawerRequestConfig, setDrawerRequestConfig] = useState({
    open: false,
    values: null,
  });

  const [notifyConfig, setNotifyConfig] = useState({
    isOpen: false,
    type: "success",
    message: "",
  });

  const handleClickOpenAddNewTimeline = (row) => {
    setDrawerRequestConfig({
      ...drawerRequestConfig,
      open: true,
      values: row,
      isEdit: false,
    });
  };

  useEffect(async () => {
    dispatch(getPayrollSetting());
    let result = await dispatch(
      getAttendanceById({
        start: search.start
        // .add(-7, "day")
        ,
        end: search.end,
      })
    );

    if(result.status !== 200){
      dispatch(openNotificationAlert({ message: t("AnErrorOccurred") }));
    }
  }, []);

  useEffect(() => {
    if (userProfile !== null) {
      let start = dayjs(today).set("date", 1);
      let end = dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0));

      if (dayjs(userProfile.hiringDate).isAfter(start)) {
        start = dayjs(userProfile.hiringDate);
      }

      setSearch({
        start: start,
        end: end,
      });

      setShowDate({
        start: start,
        end: end,
      });
    }
  }, [userProfile]);

  useEffect(() => {
    if (attendanceStore !== null) {
      const tempAttendance = [];

      attendanceStore.map((a, index) => {
        a.pattern.date = a.date;
        if (index !== 0 && index !== attendanceStore.length - 1) {
          if (a.holiday !== null) {
            a.pattern.isHoliday = 1;
          }

          if(a.isCompensation){
            a.pattern.isCompensation = true;
          }

          tempAttendance.push({
            ...a,
            previousPattern:
              (attendanceStore[index - 1] &&
                attendanceStore[index - 1].pattern) ||
              null,
            previousLeave:
              (attendanceStore[index - 1] &&
                attendanceStore[index - 1].leave) ||
              [],
            nextPattern:
              (attendanceStore[index + 1] &&
                attendanceStore[index + 1].pattern) ||
              null,
            nextLeave:
              (attendanceStore[index + 1] &&
                attendanceStore[index + 1].leave) ||
              [],
          });
        }
      });

      setAttendanceList(tempAttendance);
    }
  }, [attendanceStore]);

  const handleClickSearch = async () => {
    dispatch(
      getAttendanceById({
        // start:
          // dayjs(search.start)
          // .add(-7, "day") < dayjs("2023-01-01")
          //   ? dayjs("2023-01-01")
          //   : dayjs(search.start).add(-7, "day"),
        start: search.start,
        end: search.end,
      })
    ).then((res) => {
      setShowDate({ ...search });
    });
  };

  const excelExport = async () => {
    const exportAttendance = attendanceList.filter((a) =>
      dayjs(dayjs(dayjs(a.date).format("YYYY-MM-DD 00:00:00"))).isSameOrAfter(
        dayjs(dayjs(showDate.start).format("YYYY-MM-DD 00:00:00"))
      )
    );
    excelExportTimeline(exportAttendance, userProfile, payrollSetting, "user");
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8, marginBottom: 16 }}>
          {`${t("AppMenu.Timeline")}`}
        </Typography>

        {userProfile && (
          <StyledWrapFilter>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={3}>
                <DatePickerCustom
                  label={`${t("StartDate")}`}
                  minDate={new Date(userProfile.hiringDate)}
                  value={search.start}
                  onChange={(newValue) => {
                    setSearch({ ...search, ["start"]: newValue });
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme style={{ width: "100%" }} {...params} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <DatePickerCustom
                  label={`${t("EndDate")}`}
                  minDate={search.start}
                  value={search.end}
                  onChange={(newValue) => {
                    setSearch({ ...search, ["end"]: newValue });
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme style={{ width: "100%" }} {...params} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ButtonBlue
                  style={{ marginRight: 16 }}
                  variant={"contained"}
                  onClick={handleClickSearch}
                >
                  {`${t("Search")}`}
                </ButtonBlue>
                <ButtonBlue
                  variant="outlined"
                  startIcon={<DownloadRoundedIcon />}
                  onClick={excelExport}
                >
                  {`${t("Download")}`}
                </ButtonBlue>
              </Grid>
            </Grid>
          </StyledWrapFilter>
        )}

        {payrollSetting && (
          <TableTimeline
            attendanceList={attendanceList}
            searchDate={search}
            showDate={showDate}
            handleClickOpenAddNewTimeline={handleClickOpenAddNewTimeline}
            handleClickEdit={() => {}}
            mode="user"
            isFetchingAttendance={isFetchingAttendance}
          />
        )}
      </Container>

      {payrollSetting && userProfile && attendanceList.length > 0 && (
        <DrawerRequestTime
          open={drawerRequestConfig.open}
          onClose={() => {
            setDrawerRequestConfig({
              ...drawerRequestConfig,
              open: false,
              isEdit: false,
            });
          }}
          isEdit={drawerRequestConfig.isEdit}
          employee={userProfile}
          values={drawerRequestConfig.values}
          payrollSetting={payrollSetting}
          mode="user"
          searchDate={search}
          setNotify={setNotifyConfig}
        />
      )}

      <Notification notify={notifyConfig} setNotify={setNotifyConfig} />
    </StyledRoot>
  );
};

export default Timeline;
