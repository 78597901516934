import React, { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { CardContent, Container } from "@mui/material";
import DataGrid, {
  Column,
  Paging,
  Pager,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Scrolling,
  Sorting,
  Selection,
  Export,
  ColumnChooser,
  ColumnChooserSearch,
  ColumnChooserSelection,
  Position,
} from "devextreme-react/data-grid";
import StyledCard from "../../../shared/general/Card";
import { getPayrunsSummaryYear } from "../../../../../actions/payruns";

const StyledRoot = styled("div")({
  minHeight: 700,
  backgroundColor: "#FFFFFF !important",
  "& .wrap-btn": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 16,
    marginTop: 16,
  },
});

const StyledCardContent = styled(CardContent)({
  "& .wrap-header": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .sub-header-1": {
    fontWeight: 500,
    fontSize: 18,
    textDecoration: "underline",
  },
  "& .sub-header": {
    fontWeight: 500,
  },
  "& .header-item": {
    fontSize: "1.25rem",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
  },
  "& .wrap-item-data": {
    marginLeft: 16,
    marginBottom: 8,
  },
  "& .wrap-check": {
    display: "flex",
    alignItems: "center",
    "& .MuiCheckbox-root": {
      paddingLeft: 0,
    },
  },
  "& .item-name": {
    fontWeight: 600,
    marginBottom: 4,
  },
  "& .wrap-label-switch": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .custom-date-payment": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 8,
    alignItems: "center",
  },
});

const StyledWrapDataGrid = styled("div")({
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-salary[role="columnheader"]': {
    backgroundColor: "#00796b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-addition[role="columnheader"]': {
    backgroundColor: "#26a69a",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-earnings-all[role="columnheader"]': {
    backgroundColor: "#005e55",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-earnings[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-sso[role="columnheader"], .column-tax[role="columnheader"], .column-pf[role="columnheader"]':
  {
    backgroundColor: "#ef5350",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-deduction[role="columnheader"]': {
    backgroundColor: "#c62828",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-deduction-all[role="columnheader"]': {
    backgroundColor: "#a30000",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
  '& .column-accumulate[role="columnheader"]': {
    backgroundColor: "#ff9100",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
});

const PayrollSummaryYearPage = () => {
  const dispatch = useDispatch();
  const { t,i18n } = useTranslation();
  const { year, idCompany } = useParams();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: payRunSummaryYear } = useSelector(
    (state) => state.payRunSummaryYear
  );

  const [data, setData] = React.useState(null);

  useEffect(() => {
    dispatch(getPayrunsSummaryYear(year, idCompany));
  }, []);

  useEffect(() => {
    if (payRunSummaryYear && payRunSummaryYear.length > 0) {
      let temp = [...payRunSummaryYear];
      temp.forEach((element, index) => {
        element["index"] = index;
      });

      setData(temp);
    }
  }, [payRunSummaryYear]);

  return (
    <Fragment>
      {userProfile && (
        <Fragment>
          <StyledRoot className={`page`}>
            <Container maxWidth="lg">
              <StyledCard>
                <StyledCardContent>
                  <StyledWrapDataGrid>
                    <DataGrid
                      dataSource={data ? data : []}
                      showBorders={false}
                      showColumnLines={false}
                      rowAlternationEnabled={true}
                      columnAutoWidth={true}
                    >
                      <ColumnChooser enabled={true} mode={"select"} title={t("ColumnChooser")}>
                        <Position
                          my="right top"
                          at="right bottom"
                          of=".dx-datagrid-column-chooser-button"
                        />

                        <ColumnChooserSearch
                          enabled={true}
                          editorOptions={{ placeholder: "Search column" }}
                        />

                        <ColumnChooserSelection
                          allowSelectAll={true}
                          selectByClick={true}

                        />
                      </ColumnChooser>

                      <Column
                        caption={`${t("No_Number")}`}
                        dataField="index"
                        width={60}
                        allowFiltering={false}
                        alignment="center"
                        cssClass="column-info"
                      />

                      <Column
                        caption={`${t("EmployeeID")}`}
                        dataField="employeeID"
                        dataType="string"
                        width={120}
                        cssClass="column-info"
                      />

                      <Column
                        caption={`${t("Title")}`}
                        dataType="string"
                        cssClass="column-info"
                        dataField={i18n.resolvedLanguage === "th" ? "title_TH" : "title_EN"}
                      ></Column>
                      <Column
                        caption={`${t("FirstName")}`}
                        dataType="string"
                        cssClass="column-info"
                        dataField={i18n.resolvedLanguage === "th" ? "firstname_TH" : "firstname_EN"}
                      >
                        <HeaderFilter allowSearch={true} />
                      </Column>
                      <Column
                        caption={`${t("LastName")}`}
                        dataType="string"
                        cssClass="column-info"
                        dataField={i18n.resolvedLanguage === "th" ? "lastname_TH" : "lastname_EN"}
                      >
                        <HeaderFilter allowSearch={true} />
                      </Column>

                      <Column
                        dataField="personalID"
                        caption={`${t("PersonalID")}`}
                        cssClass="column-info"
                      />

                      <Column
                        dataField="accumulateEarnings"
                        caption={t("CumulativeIncome")}
                        cssClass="column-total-earnings"
                        format="###,###,##0.00"
                      />
                      <Column
                        dataField="accumulateTax"
                        caption={t("AccumulatedTax")}
                        cssClass="column-total-earnings"
                        format="###,###,##0.00"
                      />
                      <Column
                        dataField="accumulateSSO"
                        caption={t("CumulativeSS")}
                        cssClass="column-total-earnings"
                        format="###,###,##0.00"
                      />
                      <Column
                        dataField="accumulatePF"
                        caption={t("CumulativePF")}
                        cssClass="column-total-earnings"
                        format="###,###,##0.00"
                      />

                      <Column
                        dataField="totalEarningsOfYear"
                        caption={t("TotalIncomeForTheYear")}
                        cssClass="column-deduction"
                        format="~###,###,##0.00"
                      />

                      <Column
                        dataField="taxThisYear"
                        caption={t("TaxPayableThisYear")}
                        cssClass="column-shift"
                        format="###,###,##0.00"
                      />
                      <Column
                        dataField="taxBalance"
                        caption={t("Excess/Shortfall")}
                        cssClass="column-net"
                        format="###,###,##0.00"
                      />

                      <Export
                        enabled={true}
                        allowExportSelectedData={true}
                        fileName={t("AppMenu.SummaryTime")}
                        texts={{
                          exportAll: t("DownloadAllDataToExcel"),
                          exportSelectedRows: t(
                            "DownloadSelectedRowsToExcel"
                          ),
                        }}
                      />
                      <Paging defaultPageSize={20} />
                      <Pager
                        visible={true}
                        allowedPageSizes={[10, 20, 30]}
                        showPageSizeSelector={true}
                        showNavigationButtons={true}
                        showInfo={true}
                      />
                      <Selection mode="single" />
                      <Sorting mode="multiple" />
                      <Scrolling columnRenderingMode="virtual" />
                      <FilterRow visible={false} />
                      <HeaderFilter visible={true} />
                      <SearchPanel
                        visible={true}
                        width={240}
                        placeholder={t("Search")}
                      />
                    </DataGrid>
                  </StyledWrapDataGrid>
                </StyledCardContent>
              </StyledCard>
            </Container>
          </StyledRoot>
        </Fragment>
      )}
    </Fragment>
  );
};

export default PayrollSummaryYearPage;
