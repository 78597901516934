import { Avatar, Box, TablePagination, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ChipWithDrawCustom from "../../shared/chipWithDrawCustom";
import StatusRequest from "../../shared/general/stausRequest";
import TableCustom from "../../shared/tableCustom";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const RequestTime = () => {
  const { result: requestTimeList } = useSelector((state) => state.requestTime);
  const { result: requestTimeWithDrawList } = useSelector(
    (state) => state.requestTimeWithDraw
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  
  const [page, setPage] = useState(0);
  const [listPerPage, setListPerPage] = useState(10);
  const [historyRows, setHistoryRows] = useState([]);
  const { t, i18n } = useTranslation();

  const columns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>
              {i18n.resolvedLanguage === "th"
                ? `${row.firstname_TH} ${row.lastname_TH}`
                : `${row.firstname_EN} ${row.lastname_EN}`}
            </Typography>
            <Typography color="text.third" fontSize="14px">
              {i18n.resolvedLanguage === "th"
                ? `${row.positionName}`
                : `${row.positionName_EN}`}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("Type")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Fragment>
          {row.idRequestTimeWithdraw && <ChipWithDrawCustom />}
          <Typography fontSize={14}>{t(row.name)}</Typography>
        </Fragment>
      ),
    },
    {
      name: `${t("Start")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.startText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}
          </Typography>
          <Typography>
            {dayjs(row.startText, "DD/MM/YYYY HH:mm").format("HH:mm")}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("End")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}
          </Typography>
          <Typography>
            {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("HH:mm")}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("Reason")}`,
      minWidth: "200px",
      cellRender: (row) => (
        <Box>
          <Typography fontSize="14px">
            {i18n.resolvedLanguage === "th"
              ? row.reasonName
              : row.reasonName_EN}
          </Typography>
          <Typography color="text.third" fontSize="14px">
            {row.otherReason}
          </Typography>
        </Box>
      ),
    },
  ];

  const historyColumns = [
    {
      name: `${t("Status")}`,
      cellRender: (row) => (
        <StatusRequest
          boxSize={40}
          fontSize={22}
          borderRadius={8}
          status={getStatusRequest(row)}
        />
      ),
    },
    ...columns,
    {
      name: `${t("ApprovedDate")}`,
      minWidth: "150px",
      headerTextAlign: "center",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          {row.idRequestTimeWithdraw && row.approveDateText ? (
            <Fragment>
              <Typography>
                {dayjs(row.approveDateText, "DD/MM/YYYY HH:mm").format(
                  "D MMM BBBB"
                )}
              </Typography>
              <Typography>
                {dayjs(row.approveDateText, "DD/MM/YYYY HH:mm").format("HH:mm")}
              </Typography>
            </Fragment>
          ) : row.managerLV1ApproveBy === userProfile.idEmployees &&
            row.managerLV1ApproveDateText ? (
            <Fragment>
              <Typography>
                {dayjs(
                  row.managerLV1ApproveDateText,
                  "DD/MM/YYYY HH:mm"
                ).format("D MMM BBBB")}
              </Typography>
              <Typography>
                {dayjs(
                  row.managerLV1ApproveDateText,
                  "DD/MM/YYYY HH:mm"
                ).format("HH:mm")}
              </Typography>
            </Fragment>
          ) : row.managerLV2ApproveBy === userProfile.idEmployees &&
            row.managerLV2ApproveDateText ? (
            <Fragment>
              <Typography>
                {dayjs(
                  row.managerLV2ApproveDateText,
                  "DD/MM/YYYY HH:mm"
                ).format("D MMM BBBB")}
              </Typography>
              <Typography>
                {dayjs(
                  row.managerLV2ApproveDateText,
                  "DD/MM/YYYY HH:mm"
                ).format("HH:mm")}
              </Typography>
            </Fragment>
          ) : null}
        </Box>
      ),
    },
  ];

  const getStatusRequest = (requestTime) => {
    if (
      requestTime.idRequestTimeWithdraw &&
      requestTime.managerApprove === userProfile.idEmployees
    ) {
      if (requestTime.isApprove === 1) {
        return 1;
      } else if (requestTime.isApprove === 0) {
        return 0;
      } else {
        return 2;
      }
    }

    if (requestTime.managerLV1ApproveBy === userProfile.idEmployees) {
      if (requestTime.isManagerLV1Approve === 1) {
        return 1;
      } else if (requestTime.isManagerLV1Approve === 0) {
        return 0;
      } else {
        return 2;
      }
    } else if (requestTime.managerLV2ApproveBy === userProfile.idEmployees) {
      if (requestTime.isManagerLV2Approve === 1) {
        return 1;
      } else if (requestTime.isManagerLV2Approve === 0) {
        return 0;
      } else {
        return 2;
      }
    }
  };

  useEffect(() => {
    const tempHistory = [];

    requestTimeList.map((r) => {
      if (r.idRequestType === 1) {
        if (r.isDoubleApproval === 1) {
          if (r.managerLV1ApproveBy === userProfile.idEmployees) {
            if (r.isManagerLV1Approve === 1) {
              tempHistory.push(r);
            } else if (r.isManagerLV1Approve === 0) {
              tempHistory.push(r);
            }
          } else if (r.managerLV2ApproveBy === userProfile.idEmployees) {
            if (r.isManagerLV2Approve === 1) {
              tempHistory.push(r);
            } else if (r.isManagerLV2Approve === 0) {
              tempHistory.push(r);
            }
          }
        } else {
          if (r.approvalLevel === 1) {
            if (r.isManagerLV1Approve === 1) {
              tempHistory.push(r);
            } else if (r.isManagerLV1Approve === 0) {
              tempHistory.push(r);
            }
          } else if (r.approvalLevel === 2) {
            if (r.isManagerLV2Approve === 1) {
              tempHistory.push(r);
            } else if (r.isManagerLV2Approve === 0) {
              tempHistory.push(r);
            }
          }
        }
      }
    });

    requestTimeWithDrawList.map((r) => {
      if (r.idRequestType === 1) {
        if (r.isApprove !== null) {
          tempHistory.push(r);
        }
      }
    });

    setHistoryRows(
      tempHistory.sort(function (a, b) {
        let aApproveDate = null;
        let bApproveDate = null;

        if (a.idRequestTimeWithdraw) {
          aApproveDate = a.approveDate;
        }

        if (b.idRequestTimeWithdraw) {
          bApproveDate = b.approveDate;
        }

        if (a.managerLV1ApproveBy === userProfile.idEmployees) {
          aApproveDate = a.managerLV1ApproveDate;
        } else if (a.managerLV2ApproveBy === userProfile.idEmployees) {
          aApproveDate = a.managerLV2ApproveDate;
        }

        if (b.managerLV1ApproveBy === userProfile.idEmployees) {
          bApproveDate = b.managerLV1ApproveDate;
        } else if (b.managerLV2ApproveBy === userProfile.idEmployees) {
          bApproveDate = b.managerLV2ApproveDate;
        }

        return new Date(bApproveDate) - new Date(aApproveDate);
      })
    );
  }, []);

  return (
    <Box>
      <TableCustom
        columns={historyColumns}
        rows={requestTimeList && requestTimeWithDrawList ? 
          historyRows.filter((_, index) => {
            return (index >= (0 + (page * listPerPage)) && index < ((page + 1) * listPerPage));
          })
          : []
        }
      />
      <Box style={{ display:"flex", justifyContent: "right" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={requestTimeList && requestTimeWithDrawList ? 
            historyRows.length
            : 0}
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={listPerPage}
          onRowsPerPageChange={(event) => {
            setListPerPage(+event.target.value);
            setPage(0);
          }}
          labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${
                            count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
        />
      </Box>
    </Box>
  );
};

export default RequestTime;
