import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

import {
  Grid,
  Typography,
  TextField,
  Paper,
  Button,
  Container,
} from "@mui/material";
import { Manager, DataValue } from "../mockData";
import { styled } from "@mui/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import makeStyles from "@mui/styles/makeStyles";

import { useSelector, useDispatch } from "react-redux";
import { getUserProfile } from "../../../../../actions/user";
import {
  postDialogueRecords,
  getAllDialogueRecords,
} from "../../../../../actions/DialogueRecords";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiPaper-root": {
      boxShadow: "none",
      padding: 32,
    },
    "& .MuiTypography-body1": {
      fontWeight: "600",
    },
    "& .MuiTypography-subtitle1": {
      color: "#757575",
    },
  },
  objectiveName_Section: {
    marginLeft: "25px",
  },
  hrTag: {
    marginTop: "25px",
    border: "1px solid #eeeeee",
  },
  TextField_Section: {
    paddingRight: "15px",
    
  },
  imageEmp: {
    width: 56,
    height: 56,
    borderRadius: "50%",
  },
  saveButton: {
    marginTop: 16,
    minWidth: "100px",
    height: "50px",
    borderRadius: "10px",
  },
  card_Image: {
    backgroundColor: "#f5f5f5",
    padding: 8,
    borderRadius: "10px 0 0 10px",
  },
  card_Name: {
    backgroundColor: "#f5f5f5",
    padding: 8,
    borderRadius: "0 10px 10px 0",
    marginRight: 16,
  },
}));

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

const InitialValues = () => ({
  dialogueRecordId: 0,
  good: "",
  gap: "",
  learningPoint: "",
  nextCommit: "",
  note: "",
  whatNextHW: "",
  createBy: "",
  dateCreate: new Date(),
  planIdvId: 0,
});

function DialogueHistory() {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: dialogueStore } = useSelector(
    (state) => state.DialogueRecords
  );

  const [values, setValues] = useState(InitialValues);
  const [employees, setEmployees] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [planList, setPlanList] = useState([]);

  const onPostDialogueRecords = async (formData) => {
    await dispatch(postDialogueRecords(formData));
    history.push("/dialogue");
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const formData = {
      good: values.good,
      gap: values.gap,
      learningPoint: values.learningPoint,
      nextCommit: values.nextCommit,
      note: values.note,
      whatNextHW: values.whatNextHW,
      planIdvId: planList.planIdvId,
      createByEmpId: userProfile.idEmployees,
      dateCreate: dayjs(new Date()).format("YYYY-MM-DD"),
    };

    onPostDialogueRecords(formData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    if (dialogueStore) {
      let plan = dialogueStore.filter(function (item) {
        return item.planIdvId == location.planIdv.planIdvId;
      });

      setValues(plan[0]);
      setPlanList(location.planIdv);
      setDisabled(true);
    }
  }, [dialogueStore]);

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserProfile(currentUser.username));
    }

    if (location.isInfo == true) {
      dispatch(getAllDialogueRecords());
    } else {
      setPlanList(location.planIdv);
    }
  }, []);

  return (
    <ContainerStyled>
      <div className={classes.root}>
        <div className="card-section">
          <div className="header-section">
            <Grid container>
              <Grid item>
                <h3 className="header-topic">Dialogue History</h3>
              </Grid>
            </Grid>
          </div>
          <Grid container justifyContent="space-between">
            <Grid item md={6}>
              <div className={classes.objectiveName_Section}>
                <Typography variant="body1">
                  Objective : {planList.objectiveName}
                </Typography>
              </div>
            </Grid>
            <Grid container item md={3}>
              <Grid item xs={3} className={classes.card_Image}>
                {/* employees */}
                {Manager.managerImage == null ||
                Manager.managerImage == "string" ? (
                  <AccountCircleIcon
                    sx={{ color: "#e0e0e0" }}
                    className={classes.imageEmp}
                  />
                ) : (
                  <img
                    src={`/images/employees/${Manager.managerImage}`}
                    className={classes.imageEmp}
                  />
                )}
              </Grid>
              <Grid item xs className={classes.card_Name}>
                <Typography variant="subtitle1">
                  {`${planList.firstName} ${planList.lastName}`}
                  <br />
                  {planList.positionName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item md={3}>
              <Grid item xs={3} className={classes.card_Image}>
                {Manager.empImage == null || Manager.empImage == "string" ? (
                  <AccountCircleIcon
                    sx={{ color: "#e0e0e0" }}
                    className={classes.imageEmp}
                  />
                ) : (
                  <img
                    src={`/images/employees/${Manager.empImage}`}
                    className={classes.imageEmp}
                  />
                )}
              </Grid>
              <Grid item xs className={classes.card_Name}>
                <Typography variant="subtitle1">
                  {planList.reportToName}
                  <br />
                  {`Manager`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <hr className={classes.hrTag} />
          <Paper>
            <form onSubmit={onSubmit} noValidate autoComplete="off">
              <Grid container sx={{ marginBottom: 2 }}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  className={classes.TextField_Section}
                >
                  <Typography variant="subtitle1" gutterBottom>
                    Good Result
                  </Typography>
                  <TextField
                    variant="outlined"
                    name="good"
                    value={values.good}
                    disabled={disabled}
                    onChange={handleInputChange}
                    rows={3}
                    fullWidth
                    multiline
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Gap For Improvement
                  </Typography>
                  <TextField
                    variant="outlined"
                    name="gap"
                    value={values.gap}
                    disabled={disabled}
                    onChange={handleInputChange}
                    rows={3}
                    fullWidth
                    multiline
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ marginBottom: 2 }}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  className={classes.TextField_Section}
                >
                  <Typography variant="subtitle1" gutterBottom>
                    Learning Point
                  </Typography>
                  <TextField
                    variant="outlined"
                    name="learningPoint"
                    value={values.learningPoint}
                    disabled={disabled}
                    onChange={handleInputChange}
                    rows={3}
                    fullWidth
                    multiline
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Next Commit
                  </Typography>
                  <TextField
                    variant="outlined"
                    name="nextCommit"
                    value={values.nextCommit}
                    disabled={disabled}
                    onChange={handleInputChange}
                    rows={3}
                    fullWidth
                    multiline
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ marginBottom: 2 }}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Note
                  </Typography>
                  <TextField
                    variant="outlined"
                    name="note"
                    value={values.note}
                    disabled={disabled}
                    onChange={handleInputChange}
                    rows={3}
                    fullWidth
                    multiline
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ marginBottom: 2 }}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    What Next / Home Work
                  </Typography>
                  <TextField
                    variant="outlined"
                    name="whatNextHW"
                    value={values.whatNextHW}
                    disabled={disabled}
                    onChange={handleInputChange}
                    rows={3}
                    fullWidth
                    multiline
                  />
                </Grid>
              </Grid>
              {location.isInfo ? null : (
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.saveButton}
                      type="submit"
                    >
                      Save Record
                    </Button>
                  </Grid>
                </Grid>
              )}
            </form>
          </Paper>
        </div>
      </div>
    </ContainerStyled>
  );
}

export default DialogueHistory;
