import { Avatar, Box, IconButton, TablePagination, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Visibility } from "@mui/icons-material";
import ChipWithDrawCustom from "../../shared/chipWithDrawCustom";
import DialogShowComment from "./DialogShowComment";
import StatusRequest from "../../shared/general/stausRequest";
import TableCustom from "../../shared/tableCustom";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName, getUserPosition } from "../../../../utils/userData";

const RequestLeave = (props) => {
  const { result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { result: leaveEmployeeWithdrawsList } = useSelector(
    (state) => state.leaveEmployeesWithDraw
  );
  const { selectedEmployee } = props;
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [page, setPage] = useState(0);
  const [listPerPage, setListPerPage] = useState(10);
  const [historyRows, setHistoryRows] = useState([]);
  const [dialogComment, setDialogComment] = useState({
    isOpen: false,
    comment: null,
  });

  const columns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>
              {getUserFullName(row)}
            </Typography>
            <Typography color="text.third" fontSize="14px">
              {getUserPosition(row)}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("Type")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Fragment>
          {row.idLeaveEmployeesWithdraw && <ChipWithDrawCustom />}
          <Typography fontSize={14}>
            {i18n.resolvedLanguage === "th"
              ? row.name
              : row.name_EN ? row.name_EN : ""}
          </Typography>
        </Fragment>
      ),
    },
    {
      name: `${t("Start")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.startText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}
          </Typography>
          {row.isFullDay === 1 ? (
            <Typography>{t("FullDay")}</Typography>
          ) : (
            <Typography>
              {dayjs(row.startText, "DD/MM/YYYY HH:mm").format("HH:mm")}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      name: `${t("End")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}
          </Typography>
          {row.isFullDay === 1 ? (
            <Typography>{t("FullDay")}</Typography>
          ) : (
            <Typography>
              {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("HH:mm")}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      name: `${t("Reason")}`,
      minWidth: "200px",
      cellRender: (row) => (
        <Box>
          <Typography color="text.third" fontSize="14px">
            {row.description}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("Manager")}`,
      minWidth: "280px",
      width: "280px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <StatusRequest
            boxSize={40}
            fontSize={22}
            borderRadius={8}
            status={getStatusLeaveManagerApprove(row)}
          />
          <Avatar
            sx={{
              marginLeft: "8px",
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>
              {getUserFullName({
                firstname_TH: row.manager_firstname_TH,
                lastname_TH: row.manager_lastname_TH,
                firstname_EN: row.manager_firstname_EN,
                lastname_EN: row.manager_lastname_EN,
              })}
            </Typography>
            <Typography color="text.third" fontSize="14px">
              {getUserPosition({
                positionName: row.manager_positionName,
                positionName_EN: row.manager_positionName_EN,
              })}
            </Typography>
          </Box>
        </Box>
      ),
    },
  ];

  const historyColumns = [
    {
      name: `${t("Status")}`,
      cellRender: (row) => (
        <StatusRequest
          boxSize={40}
          fontSize={22}
          borderRadius={8}
          status={getStatusLeave(row)}
        />
      ),
    },
    ...columns,
    {
      name: `${t("ApprovedDate")}`,
      minWidth: "150px",
      headerTextAlign: "center",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          {row.approveDateText && (
            <>
              <Typography>
                {dayjs(row.approveDateText, "DD/MM/YYYY HH:mm").format(
                  "D MMM BBBB"
                )}
              </Typography>
              <Typography>
                {dayjs(row.approveDateText, "DD/MM/YYYY HH:mm").format("HH:mm")}
              </Typography>
            </>
          )}
        </Box>
      ),
    },
    {
      name: `${t("ManagerComment")}`,
      minWidth: "250px",
      width: "250px",
      cellRender: (row) => (
        <Box>
          {row.commentManager && (
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  width: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {row.commentManager}
              </Typography>
              <IconButton
                style={{ marginLeft: 8 }}
                onClick={() =>
                  setDialogComment({
                    isOpen: true,
                    comment: row.commentManager,
                  })
                }
              >
                <Visibility />
              </IconButton>
            </Box>
          )}
        </Box>
      ),
    },
  ];

  const getStatusLeave = (requestLeave) => {
    if (
      requestLeave.idLeaveEmployeesWithDraw &&
      requestLeave.managerApprove === userProfile.idEmployees
    ) {
      if (requestLeave.isApprove === 1) {
        return 1;
      } else if (requestLeave.isApprove === 0) {
        return 0;
      } else {
        return 2;
      }
    }

    if (requestLeave.isApprove === 1) {
      return 1;
    } else if (requestLeave.isApprove === 0) {
      return 0;
    } else {
      return 2;
    }
  };

  const getStatusLeaveManagerApprove = (requestLeave) => {
    if (requestLeave.fillInApprove) {
      return 3;
    }

    if (
      requestLeave.idLeaveEmployeesWithDraw &&
      requestLeave.managerApprove === userProfile.idEmployees
    ) {
      if (requestLeave.isApprove === 1) {
        return 1;
      } else if (requestLeave.isApprove === 0) {
        return 0;
      } else {
        return 2;
      }
    }

    if (requestLeave.isApprove === 1) {
      return 1;
    } else if (requestLeave.isApprove === 0) {
      return 0;
    } else {
      return 2;
    }
  };

  useEffect(() => {
    const tempHistory = [];

    let empRequestTimeList = selectedEmployee && selectedEmployee.idEmployees ? leaveEmployeesList.filter(item => item.idEmployees === selectedEmployee.idEmployees) : leaveEmployeesList;
    let empRequestTimeWithdrawList = selectedEmployee && selectedEmployee.idEmployees ? leaveEmployeeWithdrawsList.filter(item => item.idEmployees === selectedEmployee.idEmployees) : leaveEmployeeWithdrawsList;

    empRequestTimeList.map((r) => {
      if (r.isApprove !== null) {
        tempHistory.push(r);
      }
    });

    empRequestTimeWithdrawList.map((r) => {
      if (r.isApprove !== null) {
        tempHistory.push(r);
      }
    });
    setHistoryRows(
      tempHistory.sort(function (a, b) {
        return new Date(b.approveDate) - new Date(a.approveDate);
      })
    );
  }, []);

  return (
    <Box>
      <TableCustom
        columns={historyColumns}
        rows={
          leaveEmployeesList && leaveEmployeeWithdrawsList ?
            historyRows.filter((_, index) => {
              return (index >= (0 + (page * listPerPage)) && index < ((page + 1) * listPerPage));
            })
            : []
        }
      />
      <Box style={{ display: "flex", justifyContent: "right" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={leaveEmployeesList && leaveEmployeeWithdrawsList ?
            historyRows.length
            : 0}
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={listPerPage}
          onRowsPerPageChange={(event) => {
            setListPerPage(+event.target.value);
            setPage(0);
          }}
          labelRowsPerPage={`${t("RowsPerPage")}`}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${t("OutOf")} ${count !== -1 ? count : `${t("MoreThan")} ${to}`
            }`
          }
        />
      </Box>
      {dialogComment.isOpen && (
        <DialogShowComment
          open={dialogComment.isOpen}
          onClose={() =>
            setDialogComment({
              isOpen: false,
              comment: null,
            })
          }
          comment={dialogComment.comment}
        />
      )}
    </Box>
  );
};

export default RequestLeave;
