import {
  LOGRECORD_DETAIL_FETCHING,
  LOGRECORD_DETAIL_FAILED,
  LOGRECORD_DETAIL_SUCCESS,
  LOGDOWNLOADPDF_FETCHING,
  LOGDOWNLOADPDF_FAILED,
  LOGDOWNLOADPDF_SUCCESS,
} from "./types";

import LogRecordService from "../services/logRecord.service";

export const getAllLogRecord = () => async (dispatch) => {
  try {
    const res = await LogRecordService.getAllLogRecord();
    if (res) {
      dispatch({
        type: LOGRECORD_DETAIL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LOGRECORD_DETAIL_FAILED,
    });
    console.log(err);
  }
};

export const getAllDownloadPDF = () => async (dispatch) => {
  try {
    const res = await LogRecordService.getAllDownloadPDF();
    if (res) {
      dispatch({
        type: LOGDOWNLOADPDF_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LOGRECORD_DETAIL_FAILED,
    });
    console.log(err);
  }
};
