import {
    EMPLOYEE_FETCHING_PROJECT_WORKFORCE ,
    EMPLOYEE_FAILED_PROJECT_WORKFORCE,
    EMPLOYEE_SUCCESS_PROJECT_WORKFORCE
  } from "../actions/types";
  
  const initialState = {
    result: null,
    isFetching: false,
    isError: false,
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case EMPLOYEE_FETCHING_PROJECT_WORKFORCE:
        return { ...state, result: null, isFetching: true, isError: false };
      case EMPLOYEE_FAILED_PROJECT_WORKFORCE:
        return { ...state, result: null, isFetching: false, isError: true };
      case EMPLOYEE_SUCCESS_PROJECT_WORKFORCE:
        return { ...state, result: payload, isFetching: false, isError: false };
      default:
        return state;
    }
  }