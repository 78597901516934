import { styled } from "@mui/material/styles";
import React, { Fragment } from "react";

import { Typography, IconButton } from "@mui/material";

import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

import XlsxIcon from "../../../../assets/xlsx.png";

const StyledShowSelected = styled("div")({
  position: "relative",
  margin: "auto",
  display: "flex",
  height: 120,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& img": {
    marginRight: 8,
  },
});

const StyledWrapDelete = styled("div")({
  position: "absolute",
  right: 0,
  top: 0,
});

const FileSelected = (props) => {
  const { fileSelected, handleSetFileSelected, mode } = props;
  const handleClickDelete = () => {
    handleSetFileSelected(mode, null);
  };
  return (
    <Fragment>
      <StyledShowSelected>
        <StyledWrapDelete>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={handleClickDelete}
          >
            <HighlightOffRoundedIcon fontSize="small" />
          </IconButton>
        </StyledWrapDelete>
        <div>
          <img src={XlsxIcon} width="60" />
        </div>
        <div>
          <Typography>{fileSelected.name}</Typography>
          <Typography color="text.third">
            {(fileSelected.size / 1024 / 1024).toFixed(2)} MB
          </Typography>
        </div>
      </StyledShowSelected>
    </Fragment>
  );
};

export default FileSelected;
