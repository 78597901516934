import React, { useState, useEffect, Fragment } from "react";
import ReactApexChart from "react-apexcharts";
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

const StylePaper = styled(Paper)({
  padding: "20px 40px 20px 40px",
  minWidth: 350,
  minHeight: 500,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  marginTop: "16px",
  marginBottom: "16px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "fit-content",
});

const ChartContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

function CompetencyDashboard({ evaluateList }) {
  const [seriesCompetency, setSeriesCompetency] = useState([]);
  const [optionsCompetency, setOptionsCompetency] = useState({});

  useEffect(() => {
    if (evaluateList) {
      let categories = [];
      let data = [];
      let actualData = [];

      evaluateList.forEach((item) => {
        categories.push(item.competencyName);
        data.push(item.approveLevel ? item.approveLevel : 0);
        actualData.push(parseInt(item.actualLevel));
      });

      setSeriesCompetency([
        { name: "Competency Level", data: data, color: "#aa00ff" },
        { name: "Actual Level", data: actualData, color: "#888888" },
      ]);

      setOptionsCompetency({
        xaxis: {
          categories,
          labels: {
            show: true,
            style: {
              colors: ["#a8a8a8"],
              fontSize: "20px",
              fontFamily: "Kanit, sans-serif",
              borderRadius: 2,
            },
          },
        },
        yaxis: {
          min: 0,
          max: 5,
          tickAmount: 5,
          labels: {
            style: {
              colors: ["#a0a0a0"],
              fontSize: "14px",
              fontFamily: "Kanit, sans-serif",
            },
          },
        },
        fill: {
          opacity: 0.5,
          colors: ["#aa00ff", "#888888"],
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["#aa00ff", "#888888"],
          dashArray: 0,
        },
        chart: {
          type: "radar",
          fontFamily: "Kanit, sans-serif",
          height: 350,
          toolbar: {
            tools: {
              download: false,
            },
          },
        },
        plotOptions: {
          radar: {
            size: 140,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: ["#f8f8f8", "#fff"],
              },
            },
          },
        },
        markers: {
          size: 4,
          colors: ["#fff"],
          strokeColor: "#a8a8a8",
          strokeWidth: 2,
        },
        legend: {
          labels: {
            colors: ["#aa00ff", "#888888"],
            useSeriesColors: true,
          },
        },
      });
    }
  }, [evaluateList]);

  return (
    <Fragment>
      <StylePaper>
        {evaluateList && evaluateList.length > 0 && (
          <ChartContainer>
            <ReactApexChart
              options={optionsCompetency}
              series={seriesCompetency}
              type="radar"
              height="500"
              width="650"
            />
          </ChartContainer>
        )}
      </StylePaper>
    </Fragment>
  );
}

export default CompetencyDashboard;
