import React, { useEffect, useState } from "react";
import { Box, Grid, styled, TextField, Typography } from "@mui/material";
import DatePickerCustom from "../../shared/date/datePicker";
import ButtonBlue from "../../shared/general/ButtonBlue";
import dayjs from "dayjs";
import XLSX from "xlsx";
import SelectEmployees from "../../shared/general/selectEmployees";
import {
  clearAttendance,
  getAttendanceById,
} from "../../../../actions/attendance";
import { useDispatch, useSelector } from "react-redux";

import SearchIcon from "@mui/icons-material/Search";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { getPayrollSetting } from "../../../../actions/paytypes";
import TableTimeline from "../../shared/tableTimeline";
import DrawerRequestTime from "../../shared/tableTimeline/drawerRequestTime";
import Notification from "../../shared/general/Notification";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFirstName,getUserLastName,getHolidayName } from "../../../../utils/userData";
import { openNotificationAlert } from "../../../../actions/notificationAlert";

const StyledRoot = styled(Box)({});

const StyledBoxSearchButton = styled(Box)({
  marginTop: 50,
  display: "flex",
  justifyContent: "space-evenly",
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const IndividualPanel = () => {
  const today = dayjs().toDate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
  });

  const [showDate, setShowDate] = useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
  });
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [attendanceList, setAttendanceList] = useState([]);

  const { isFetching: isFetchingAttendance, result: attendanceStore } = useSelector((state) => state.attendance);
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);

  // const [selectedRequest, setSelectedRequest] = useState(null);

  const [drawerRequestConfig, setDrawerRequestConfig] = useState({
    open: false,
    values: null,
  });

  const [notifyConfig, setNotifyConfig] = useState({
    isOpen: false,
    type: "success",
    message: "",
  });

  const handleSelectEmployee = (employee) => {
    dispatch(clearAttendance());
    setSelectedEmployee(employee);
    setAttendanceList([]);
  };

  const handleOnClickSearch = async () => {
    setShowDate(search);
    if (selectedEmployee && dayjs(search.end).isSameOrAfter(search.start, 'date')) {
      let result = await dispatch(
        getAttendanceById(
          {
            // start:
            //   dayjs(search.start).add(-7, "day") < dayjs("2023-01-01")
            //     ? dayjs("2023-01-01")
            //     : dayjs(search.start).add(-7, "day"),
            start: search.start,
            end: search.end,
          },
          selectedEmployee.idEmployees
        )
      );

      if (result.status !== 200) {
        dispatch(openNotificationAlert({ message: `${t("AnErrorOccurred")} ${t("PleaseContactAdmin")}` }));
      }
    } else {
      dispatch(openNotificationAlert({ type: "info", message: t("PleaseCheckDateAndEmployeeName") }));
    }
  };

  useEffect(() => {
    // dispatch(clearAttendance());
    dispatch(getPayrollSetting());
    setSelectedEmployee(null);
    setAttendanceList([]);
  }, []);

  useEffect(() => {
    if (selectedEmployee && attendanceStore !== null) {
      const tempAttendance = [];

      let compensationHolidayStack = 0;

      attendanceStore.map((a, index) => {
        a.pattern.date = a.date;
        if (index !== 0 && index !== attendanceStore.length - 1) {
          if (a.holiday !== null && a.pattern.isWorkingDay === 0 && a.pattern.idWorkingType === 1) {
            compensationHolidayStack += 1;
          }

          if (
            compensationHolidayStack > 0 &&
            a.pattern.isWorkingDay === 1 &&
            a.holiday === null &&
            a.pattern.idWorkingType === 1
          ) {
            a.pattern.isCompensationHoliday = 1;
            compensationHolidayStack -= 1;
          }

          if (a.holiday !== null) {
            a.pattern.isHoliday = 1;
          }

          tempAttendance.push({
            ...a,
            previousPattern: (attendanceStore[index - 1] && attendanceStore[index - 1].pattern) || null,
            previousLeave: (attendanceStore[index - 1] && attendanceStore[index - 1].leave) || [],
            nextPattern: (attendanceStore[index + 1] && attendanceStore[index + 1].pattern) || null,
            nextLeave: (attendanceStore[index + 1] && attendanceStore[index + 1].leave) || [],
          })
        }
      });

      setAttendanceList(tempAttendance);
    }
  }, [attendanceStore]);

  const onExportExcel = () => {
    const elementList = [];
    for (let index = 0; index < attendanceList.length; index++) {
      if (
        dayjs(attendanceList[index].date).isBetween(
          dayjs(search.start),
          dayjs(search.end),
          "day",
          []
        )
      ) {
        const element = {
          id: attendanceList[index].employee.employeeID,
          firstname: getUserFirstName(attendanceList[index].employee),
          lastname: getUserLastName(attendanceList[index].employee),
          shiftGroupName: attendanceList[index].pattern.shiftGroupName,
          day: dayjs(attendanceList[index].date).format("dddd"),
          date: dayjs(attendanceList[index].date).format("DD/MM/YYYY"),
          timeIn: attendanceList[index].attendance.checkIn.length > 0
            ? attendanceList[index].attendance.checkIn[0] && attendanceList[index].attendance.checkIn[0].attendanceTextTime
            : "",
          timeOut: attendanceList[index].attendance.checkOut.length > 0
            ? attendanceList[index].attendance.checkOut[0] && attendanceList[index].attendance.checkOut[0].attendanceTextTime
            : "",
          status: (attendanceList[index].holiday || attendanceList[index].isCompensation) ? `${getHolidayName(attendanceList[index].holiday) ? t("AnnualHoliday") + " (" + getHolidayName(attendanceList[index].holiday) + ")" : t("AnnualHoliday")}` : attendanceList[index].pattern.isWorkingDay === 0 ? t("DateHoliday") : attendanceList[index].absent ? t("Absent") : t("Check_In"),
        };
        elementList.push(element);
      }
    }
    const workSheet = XLSX.utils.json_to_sheet(elementList);

    workSheet["A1"].v = t("EmployeeID");
    workSheet["B1"].v = t("FirstName");
    workSheet["C1"].v = t("LastName");
    workSheet["D1"].v = t("ShiftSchedule");
    workSheet["E1"].v = t("Unit.Days");
    workSheet["F1"].v = t("Date");
    workSheet["G1"].v = t("TimeIn");
    workSheet["H1"].v = t("TimeOut");
    workSheet["I1"].v = t("Status");

    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, t("WorkingTimeReport"));
    XLSX.writeFile(
      workBook,
      `${t("WorkingTimeReport")} ${dayjs(search.start).format(
        "DD_MMMM_YYYY"
      )} - ${dayjs(search.end).format("DD_MMMM_YYYY")}.xlsx`
    );
  };

  return (
    <StyledRoot>
      <Grid
        container
        spacing={2}
        style={{ marginBottom: 16 }}
        alignItems={"center"}
      >
        <Grid item xs={12} sm={3}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              {`${t("StartDate")}`}
            </Typography>
            <div className="search-date">
              <DatePickerCustom
                minDate={new Date(new Date().getFullYear() - 4, 0, 1)}
                inputFormat="DD/MM/YYYY"
                value={search.start}
                name="start"
                onChange={(newValue) => {
                  setSearch({ ...search, ["start"]: newValue });
                }}
              />
            </div>
          </StyledBoxSearch>
        </Grid>
        <Grid item xs={12} sm={3}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              {`${t("EndDate")}`}
            </Typography>
            <div className="search-date">
              <DatePickerCustom
                minDate={search.start}
                inputFormat="DD/MM/YYYY"
                value={search.end}
                name="end"
                onChange={(newValue) => {
                  setSearch({ ...search, ["end"]: newValue });
                }}
              />
            </div>
          </StyledBoxSearch>
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectEmployees
            mode="manager"
            handleChange={handleSelectEmployee}
            position={userProfile && userProfile.idCompany === 3}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <StyledBoxSearchButton>
            <div>
              <ButtonBlue
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={handleOnClickSearch}
              >
                {`${t("Search")}`}
              </ButtonBlue>
            </div>
            <div>
              <ButtonBlue
                onClick={onExportExcel}
                variant="outlined"
                startIcon={<DownloadRoundedIcon />}
                disabled={selectedEmployee === null}
              >
                {`${t("Download")}`}
              </ButtonBlue>
            </div>
          </StyledBoxSearchButton>
        </Grid>
      </Grid>

      {payrollSetting && selectedEmployee && (
        <TableTimeline
          attendanceList={attendanceList}
          searchDate={search}
          showDate={showDate}
          handleClickOpenAddNewTimeline={(row) => {
            setDrawerRequestConfig({
              ...drawerRequestConfig,
              open: true,
              values: row,
            });
          }}
          handleClickEdit={(row, overtimeValues) => {
            setDrawerRequestConfig({
              ...drawerRequestConfig,
              open: true,
              values: row,
              overtimeValues: overtimeValues,
              isEdit: true,
            });
          }}
          mode="manager"
          isFetchingAttendance={isFetchingAttendance}
        // setSelectedRequest={setSelectedRequest}
        />
      )}

      {payrollSetting && selectedEmployee && attendanceList.length > 0 && (
        <DrawerRequestTime
          open={drawerRequestConfig.open}
          onClose={() => {
            setDrawerRequestConfig({
              ...drawerRequestConfig,
              open: false,
              isEdit: false,
            });
          }}
          searchDate={search}
          isEdit={drawerRequestConfig.isEdit}
          employee={selectedEmployee}
          values={drawerRequestConfig.values}
          overtimeValues={drawerRequestConfig.overtimeValues}
          payrollSetting={payrollSetting}
          mode="manager"
          setNotify={setNotifyConfig}
        />
      )}

      <Notification notify={notifyConfig} setNotify={setNotifyConfig} />
    </StyledRoot>
  );
};

export default IndividualPanel;
