import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
    TextField,
    Grid,
    Popper,
    FormControl,
    Typography,
    Box,
    Alert,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import XLSX from "xlsx";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import AlertResponse from "../../shared/general/AlertResponse";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import {
  clearAttendance,
  getAttendanceById,
} from "../../../../actions/attendance";
import { getPayrollSetting } from "../../../../actions/paytypes";
import SelectEmployeeByIdCompany from "../../shared/general/selectEmployeeByIdCompany";
import { getAffiliate } from "../../../../actions/company";
import DatePickerCustom from "../../shared/date/datePicker";
import { getUserCompany } from "../../../../utils/userData";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
    padding: 16,
    display: "flex",
    justifyContent: "flex-end",
    gap: 8,
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const Dailyplanner = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateList } = useSelector((state) => state.affiliate);
  const { result: attendanceList } = useSelector((state) => state.attendance);
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );
  const { open, handleClose } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [inputSearch, setInputSearch] = useState(new Date());
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleSelectEmployee = (employee) => {
    dispatch(clearAttendance());
    setSelectedEmployee(employee);
  };

  useEffect(() => {
    dispatch(getPayrollSetting());
    dispatch(getAffiliate());
  }, []);

  useEffect(() => {
    if (selectedEmployee) {
      dispatch(
        getAttendanceById(
          {
            start: dayjs(inputSearch).startOf("month"),
            end: dayjs(inputSearch).endOf("month"),
          },
          selectedEmployee.idEmployees
        )
      );
    }
  }, [inputSearch, selectedEmployee]);

  const handleSubmit = () => {
    if (attendanceList.length > 0) {
      const elementList = [];

      for (let index = 0; index < attendanceList.length; index++) {
        let totalLate = 0;
        const findDate = {
          start: `${dayjs(attendanceList[index].date).format("YYYY-MM-DD")} ${
            attendanceList[index].pattern.timeIn
          }`,
          end: `${dayjs(attendanceList[index].date)
            .add(1, "day")
            .format("YYYY-MM-DD")} ${attendanceList[index].pattern.timeIn}`,
        };

        let late = 0;
        let early = 0;

        if (
          attendanceList[index].requestTime != null ||
          attendanceList[index].leave != null ||
          attendanceList[index].pattern.isWorkingDay === 0
        ) {
        } else {
          if (
            attendanceList[index].attendance.checkIn[0] &&
            dayjs(
              attendanceList[index].attendance.checkIn[0]
                .attendanceTextDateTime,
              "YYYY-MM-DD HH:mm"
            ).isAfter(dayjs(findDate.start))
          ) {
            late = dayjs(
              attendanceList[index].attendance.checkIn[0]
                .attendanceTextDateTime,
              "YYYY-MM-DD HH:mm"
            ).diff(dayjs(findDate.start), "minute");
          }

          if (
            attendanceList[index].attendance.checkOut[0] &&
            dayjs(
              attendanceList[index].attendance.checkOut[0]
                .attendanceTextDateTime,
              "YYYY-MM-DD HH:mm"
            ).isBefore(dayjs(findDate.start))
          ) {
            early = dayjs(findDate.start)
              .add(attendanceList[index].pattern.workingHours, "minute")
              .diff(
                dayjs(
                  attendanceList[index].attendance.checkOut[0]
                    .attendanceTextDateTime,
                  "YYYY-MM-DD HH:mm"
                ),
                "minute"
              );
          }
        }

        totalLate =
          attendanceList[index].pattern.lateIn &&
          attendanceList[index].pattern.lateIn >= late
            ? early
            : late + early;

                const element = {
                    วันที่: dayjs(attendanceList[index].date).format("D/MM/YYYY (ddd)"),
                    กะ:
                        attendanceList[index].pattern.idWorkingType === 1
                            ? `${attendanceList[index].pattern.shiftGroupName}  (${attendanceList[index].pattern.shiftName})`
                            : `${attendanceList[index].pattern.shiftGroupName}`,
                    เวลาทำงาน:
                        attendanceList[index].pattern.isWorkingDay === 1
                            ? `${dayjs(attendanceList[index].pattern.timeIn, "HH:mm:ss").format(
                                "HH:mm"
                            )}-${dayjs(
                                attendanceList[index].pattern.timeOut,
                                "HH:mm:ss"
                            ).format("HH:mm")}`
                            : "วันหยุด",
                    "เข้างาน-ออกงาน": attendanceList[index]
                        ? `${attendanceList[index].attendance.checkIn[0]
                            ? attendanceList[index].attendance.checkIn[0]
                                .attendanceTextTime
                            : ""
                        } - ${attendanceList[index].attendance.checkOut[0]
                            ? attendanceList[index].attendance.checkOut[0]
                                .attendanceTextTime
                            : ""
                        }`
                        : "-",
                    มาสาย: totalLate,
                    "รับรองเวลาทำงาน_1": attendanceList[index]
                        ? `${attendanceList[index].requestTime.length > 0
                            ? `${attendanceList[index].requestTime[0].startText} - ${attendanceList[index].requestTime[0].endText}`
                            : ""
                        }`
                        : "-",
                    "เหตุผลเวลาทำงาน_1": attendanceList[index]
                        ? `${attendanceList[index].requestTime.length > 0
                            ? `${attendanceList[index].requestTime[0].reasonName}`
                            : ""
                        }`
                        : "-",
                    "เหตุผลอื่นๆ เวลาทำงาน_1": attendanceList[index]
                        ? `${attendanceList[index].requestTime.length > 0
                            ? `${attendanceList[index].requestTime[0].otherReason}`
                            : ""
                        }`
                        : "-",
                    "เวลาทำงาน-ผู้บังคับบัญชา":
                        attendanceList[index].requestTime &&
                            attendanceList[index].requestTime.managerLV1_firstname_TH
                            ? `${attendanceList[index].requestTime.managerLV1_firstname_TH}`
                            : "-",
                    ["เวลาทำงาน-ผลอนุมัติ"]:
                        attendanceList[index].requestTime &&
                            attendanceList[index].requestTime.managerLV1_firstname_TH
                            ? attendanceList[index].requestTime.isManagerLV1Approve === null
                                ? t("PendingApproval")
                                : attendanceList[index].requestTime.isManagerLV1Approve === 1
                                    ? t("Approve")
                                    : t("NotApproved")
                            : "-",
                    "เวลาทำงาน-วันที่เวลาอนุมัติ":
                        attendanceList[index].requestTime &&
                            attendanceList[index].requestTime.managerLV1ApproveDate
                            ? dayjs(attendanceList[index].requestTime.managerLV1ApproveDate).format("YYYY-MM-DD HH:mm")
                            : "-",
                    ทำงานล่วงเวลา_1: attendanceList[index]
                        ? `${attendanceList[index].ot[0]
                            ? `${attendanceList[index].ot[0].startText} - ${attendanceList[index].ot[0].endText}`
                            : "-"
                        }`
                        : "-",
                    เหตุผลทำงานล่วงเวลา_1: attendanceList[index]
                        ? `${attendanceList[index].ot[0]
                            ? `${attendanceList[index].ot[0].reasonName}`
                            : "-"
                        }`
                        : "-",
                    "เหตุผลอื่นๆ ทำงานล่วงเวลา_1": attendanceList[index]
                        ? `${attendanceList[index].ot[0]
                            ? `${attendanceList[index].ot[0].otherReason}`
                            : "-"
                        }`
                        : "-",
                    [`OT ${payrollSetting.xWorkingMonthlyHoliday}x วันหยุด`]:
                        attendanceList[index]
                            ? `${attendanceList[index].ot[0] &&
                                attendanceList[index].ot[0].isManagerLV1Approve === 1
                                ? attendanceList[index].ot[0].xWorkingMonthlyHoliday > 0
                                    ? `${parseFloat(
                                        attendanceList[index].ot[0].xWorkingMonthlyHoliday / 60
                                    ).toFixed(2)} ${t("Unit.ShortHours")}`
                                    : ""
                                : "-"
                            }`
                            : "-",
                    [`OT ${payrollSetting.xOT}x วันทำงาน`]: attendanceList[index]
                        ? `${attendanceList[index].ot[0] &&
                            attendanceList[index].ot[0].isManagerLV1Approve === 1
                            ? attendanceList[index].ot[0].xOT > 0
                                ? `${parseFloat(attendanceList[index].ot[0].xOT / 60).toFixed(
                                    2
                                )} ${t("Unit.ShortHours")}`
                                : ""
                            : "-"
                        }`
                        : "-",
                    [`OT ${payrollSetting.xWorkingDailyHoliday}x`]: attendanceList[index]
                        ? `${attendanceList[index].ot[0] &&
                            attendanceList[index].ot[0].isManagerLV1Approve === 1
                            ? attendanceList[index].ot[0].xWorkingDailyHoliday > 0
                                ? `${parseFloat(
                                    attendanceList[index].ot[0].xWorkingDailyHoliday / 60
                                ).toFixed(2)} ${t("Unit.ShortHours")}`
                                : ""
                            : "-"
                        }`
                        : "-",
                    [`OT ${payrollSetting.xOTHoliday}x`]: attendanceList[index]
                        ? `${attendanceList[index].ot[0] &&
                            attendanceList[index].ot[0].isManagerLV2Approve === 1
                            ? attendanceList[index].ot[0].xOTHoliday > 0
                                ? `${parseFloat(
                                    attendanceList[index].ot[0].xOTHoliday / 60
                                ).toFixed(2)} ${t("Unit.ShortHours")}`
                                : ""
                            : "-"
                        }`
                        : "-",
                    "OT-ผู้บังคับบัญชาลำดับที่ 1":
                        attendanceList[index].ot[0] &&
                            attendanceList[index].ot[0].managerLV1_firstname_TH
                            ? attendanceList[index].ot[0].managerLV1_firstname_TH
                            : "-",
                    ["OT-ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1"]:
                        attendanceList[index].ot[0] &&
                            attendanceList[index].ot[0].managerLV1_firstname_TH
                            ? attendanceList[index].ot[0].isManagerLV1Approve === null
                                ? t("PendingApproval")
                                : attendanceList[index].ot[0].isManagerLV1Approve === 1
                                    ? t("Approve")
                                    : t("NotApproved")
                            : "-",
                    "OT-วันที่เวลาอนุมัติ ผู้บังคับบัญชาลำดับที่ 1":
                        attendanceList[index].ot[0] &&
                            attendanceList[index].ot[0].managerLV1ApproveDate
                            ? dayjs(attendanceList[index].ot[0].managerLV1ApproveDate).format("YYYY-MM-DD HH:mm")
                            : "-",
                    "OT-ผู้บังคับบัญชาลำดับที่ 2":
                        attendanceList[index].ot[0] &&
                            attendanceList[index].ot[0].isDoubleApproval === 1
                            ? attendanceList[index].ot[0].isManagerLV2Approve !== 0 &&
                                attendanceList[index].ot[0].managerLV2_firstname_TH
                                ? attendanceList[index].ot[0].managerLV2_firstname_TH
                                : "-"
                            : attendanceList[index].ot[0] &&
                                attendanceList[index].ot[0].approvalLevel === 2
                                ? attendanceList[index].ot[0].managerLV2_firstname_TH
                                    ? attendanceList[index].ot[0].managerLV2_firstname_TH
                                    : "-"
                                : "-",
                    "OT-ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
                        attendanceList[index].ot[0] &&
                            attendanceList[index].ot[0].isDoubleApproval === 1
                            ? attendanceList[index].ot[0].isManagerLV1Approve === null
                                ? "รอผู้บังคับบัญชาลำดับที่ 1"
                                : attendanceList[index].ot[0].isManagerLV2Approve === 1
                                    ? attendanceList[index].ot[0].isManagerLV2Approve === null
                                        ? t("PendingApproval")
                                        : attendanceList[index].ot[0].isManagerLV2Approve === 1
                                            ? t("Approve")
                                            : t("NotApproved")
                                    : "-"
                            : attendanceList[index].ot[0] &&
                                attendanceList[index].ot[0].approvalLevel === 2
                                ? attendanceList[index].ot[0].isManagerLV2Approve &&
                                    attendanceList[index].ot[0].isManagerLV2Approve === null
                                    ? t("PendingApproval")
                                    : attendanceList[index].ot[0].isManagerLV2Approve === 1
                                        ? t("Approve")
                                        : t("NotApproved")
                                : "-",
                    "OT-วันที่เวลาอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
                        attendanceList[index].ot[0] &&
                            attendanceList[index].ot[0].managerLV2ApproveDate
                            ? dayjs(attendanceList[index].ot[0].managerLV2ApproveDate).format("YYYY-MM-DD HH:mm")
                            : "-",
                    การลางาน: attendanceList[index]
                        ? `${attendanceList[index].leave &&
                            attendanceList[index].leave.isApprove === 1
                            ? attendanceList[index].leave.name
                            : ""
                        }`
                        : "-",
                    การลางาน_เวลา: attendanceList[index]
                        ? `${attendanceList[index].leave &&
                            attendanceList[index].leave.isApprove === 1
                            ? `${attendanceList[index].leave.startText} - ${attendanceList[index].leave.endText}`
                            : ""
                        }`
                        : "-",
                    เหตุผลลางาน: attendanceList[index]
                        ? `${attendanceList[index].leave &&
                            attendanceList[index].leave.isApprove === 1
                            ? `${attendanceList[index].leave.description}`
                            : ""
                        }`
                        : "-",
                };

        elementList.push(element);
      }

      const ws = XLSX.utils.json_to_sheet(elementList);

      var workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, ws, t("WorkingTimeReport"));
      XLSX.writeFile(workbook, `${t("WorkingTimeReport")}.xlsx`, { type: "file" });

      handleClose();
    } else {
      handleChangeAlertType(`${t("NoData")}`);
      handleOpenAlert(true);
    }
  };

  return (
    <DrawerCustom
      title={`${t("WorkingTimeReport")}`}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot style={{ width: 400 }}>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Grid item xs={12} sm={5} className="GridTopicInput">
            {t("SearchDate")}
          </Grid>
          <Grid item xs={12} sm={7}>
            <DatePickerCustom
              minDate={dayjs().subtract(1, 'year')}
              maxDate={dayjs()}
              inputFormat="MMMM YYYY"
              value={inputSearch}
              name="start"
              views={["year", "month"]}
              openTo="month"
              onChange={(newValue) => {
                setInputSearch(newValue);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledBoxSearch>
              <StyledAutocomplete
                options={affiliateList? affiliateList: []}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedCompany(newValue);
                  } else {
                    setSelectedCompany(null);
                  }
                }}
                value={selectedCompany}
                popupIcon={<i class="fa-light fa-chevron-down"></i>}
                getOptionLabel={(option) => getUserCompany(option)}
                renderInput={(params) => (
                  <TextField
                    {...params} 
                    variant={"filled"}
                    placeholder={`${t("SelectCompany")}`}
                  />
                )}
                PopperComponent={StyledPopper}
              />
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={12}>
            <SelectEmployeeByIdCompany
              id={selectedCompany ? selectedCompany.idCompany : userProfile.idCompany}
              handleChange={handleSelectEmployee}
            />
          </Grid>
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleClose}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue
            variant="outlined"
            startIcon={<DownloadRoundedIcon />}
            onClick={handleSubmit}
            disabled={attendanceList === null}
          >
            {t("Download")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </DrawerCustom>
  );
};

export default Dailyplanner;
