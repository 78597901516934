import React, { useEffect, Fragment } from "react";
import {
  TextField,
  Typography,
  Grid,
  Box,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Radio,
  Divider,
  CircularProgress,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import NumberFormat from "react-number-format";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import { useDispatch, useSelector } from "react-redux";
import { updateProbationManager } from "../../../../actions/probation";
import { getProbationEmployees } from "../../../../actions/employee";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../utils/userData";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
  "& .btn-save": {
    marginTop: 16,
    display: "flex",
    justifyContent: "flex-end",
  },
});

const DialogProbation = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { open, handleClose, value, handleSubmitProbation } = props;
  const { result: Probation } = useSelector((state) => state.getProbation);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [openDialogPass, setOpenDialogPass] = React.useState(false);
  const [openDialogExpand, setOpenDialogExpand] = React.useState(false);
  const [openDialogNoPass, setOpenDialogNoPass] = React.useState(false);
  const [disableTogglePass, setDisableTogglePass] = React.useState(false);
  const [disableToggleExpand, setDisableToggleExpand] = React.useState(false);
  const [disableToggleNoPass, setDisableToggleNoPass] = React.useState(false);

  const NumberFormatTheme = React.forwardRef((props, ref) => {
    return <NumberFormat {...props} getInputRef={ref} />;
  });

  const handleClickOpenDialogPass = () => {
    setOpenDialogPass(true);
  };

  const handleCloseDialogPass = () => {
    setOpenDialogPass(false);
  };

  const handleClickOpenDialogExpand = () => {
    setOpenDialogExpand(true);
  };

  const handleCloseDialogExpand = () => {
    setOpenDialogExpand(false);
  };

  const handleClickOpenDialogNoPass = () => {
    setOpenDialogNoPass(true);
  };

  const handleCloseDialogNoPass = () => {
    setOpenDialogNoPass(false);
  };

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    setValue,
    clearErrors,
    formState: { errors, dirtyFields },
    reset,
    setError,
  } = useForm({
    defaultValues: {
      questionList: [{ question: "", answer: "" }],
      goodPoint: "",
      weakPoint: "",
      others: "",
      statusProbation: "",
      expandTime: 0,
    },
  });

  const onSubmit = async (formData) => {
    const cleansingForm = {
      questionList: formData.questionList,
      goodPoint: formData.goodPoint || null,
      weakPoint: formData.weakPoint || null,
      others: formData.others || null,
      idEmployees: value.idEmployees,
      idProbation:
        (Probation && Probation.length > 0 && Probation[0].idProbation) || null,
      isRoundTwo: value.probationRoundTwo ? 1 : 0,
      statusProbation: formData.statusProbation || null,
      expandTime: formData.expandTime || 0,
    };
    const response = await dispatch(updateProbationManager(cleansingForm));
    dispatch(getProbationEmployees());
    handleSubmitProbation(response);
  };

  const questionUseFieldArray = useFieldArray({
    rules: { maxLength: 10 },
    control: control,
    name: "questionList",
  });

  const setArryField = () => {
    if (Probation) {
      questionUseFieldArray.replace(Probation);
    }
  };

  useEffect(() => {
    setArryField();
  }, []);

  const percentageScore = () => {
    let rating = 0;
    let answerLength = 0;
    watch("questionList").map((value) => {
      if(value.answer) answerLength++;
      if (value.answer === "verygood") {
        rating += 100;
      } else if (value.answer === "good") {
        rating += 80;
      } else if (value.answer === "meduim") {
        rating += 60;
      } else if (value.answer === "fair") {
        rating += 40;
      } else if (value.answer === "prettybad") {
        rating += 20;
      } else if (value.answer === "bad") {
        rating += 0;
      }
    });
    answerLength = answerLength > 0 ? answerLength : 1
    return Math.round(rating / answerLength)
  };

  return (
    <DrawerCustom
      title={`${t("ProbationEvaluationForm")}`}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Box style={{ padding: "24px" }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={7}>
                <Typography sx={{ display: "inline-block" }}>
                  <i className="fa-regular fa-user fa-xl"></i>
                  &nbsp;&nbsp;
                </Typography>
                <Typography sx={{ display: "inline-block" }}>
                  {` ${t("PersonBeingAssessed")} : ${value.firstname_TH} ${
                    value.lastname_TH
                  }`}{" "}
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography sx={{ display: "inline-block" }}>
                  <i className="fa-regular fa-building-columns fa-xl"></i>
                  &nbsp;&nbsp;
                </Typography>
                <Typography sx={{ display: "inline-block" }}>
                  {`${t("Position")} : ${value.positionName}`}{" "}
                </Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography sx={{ display: "inline-block" }}>
                  <i className="fa-regular fa-calendar-days fa-xl"></i>
                  &nbsp;&nbsp;
                </Typography>
                <Typography sx={{ display: "inline-block" }}>
                  {`${t("ProbationRoundOne")} :  ${dayjs(value.endDate).format(
                    "D MMM BBBB"
                  )}`}{" "}
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography sx={{ display: "inline-block" }}>
                  <i className="fa-regular fa-thumbtack fa-xl"></i>
                  &nbsp;&nbsp;
                </Typography>
                <Typography sx={{ display: "inline-block" }}>
                  {`${t("ProbationRound")} : ${
                    value.probationRoundTwo === null ? "1" : "2"
                  }`}{" "}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography sx={{ display: "inline-block" }}>
                  <i className="fa-light fa-whistle fa-xl"></i>
                  &nbsp;&nbsp;
                </Typography>
                <Typography sx={{ display: "inline-block" }}>
                  {`${t("EvaluatedBy")} : ${getUserFullName(userProfile)}`}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box style={{ paddingLeft: "24px" }}>
            {questionUseFieldArray.fields.map((question, index) => (
              <Grid key={question.id} container className="GridContact">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  marginTop={2}
                  className="GridTopicInput"
                >
                  <Grid item xs={12} sm={12}>
                    <i className="fa-regular fa-square-question fa-bounce fa-xl"></i>
                    &nbsp;&nbsp;
                    {`${t("Question")} ${index + 1} : ${question.question}`}
                  </Grid>
                </Grid>
                <Grid item xs={12} marginTop={1} sm={12}>
                  <Controller
                    control={control}
                    name={`questionList.${index}.answer`}
                    rules={{
                      required: {
                        value: watch("expandTime") === 0 ? true : false,
                        message: `${t("PleaseEnterYourResponse")}`,
                      },
                    }}
                    render={({ field, formState }) => (
                      <Fragment>
                        <RadioGroup
                          {...field}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                        >
                          <FormControlLabel
                            value="verygood"
                            control={<Radio />}
                            label={`${t("Excellence")}`}
                          />
                          <FormControlLabel
                            value="good"
                            control={<Radio />}
                            label={`${t("Good")}`}
                          />
                          <FormControlLabel
                            value="meduim"
                            control={<Radio />}
                            label={`${t("Neutral")}`}
                          />
                          <FormControlLabel
                            value="fair"
                            control={<Radio />}
                            label={`${t("Fair")}`}
                          />
                          <FormControlLabel
                            value="prettybad"
                            control={<Radio />}
                            label={`${t("Poor")}`}
                          />
                          <FormControlLabel
                            value="bad"
                            control={<Radio />}
                            label={`${t("VeryPoor")}`}
                          />
                        </RadioGroup>
                        <FormHelperText sx={{ color: "#ff0000" }}>
                          {formState.errors &&
                          formState.errors.questionList &&
                          formState.errors.questionList[index] &&
                          formState.errors.questionList[index].answer
                            ? formState.errors.questionList[index].answer
                                .message
                            : null}
                        </FormHelperText>
                      </Fragment>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider
                    sx={{
                      marginTop: "16px",
                      borderColor: "#9e9e9e66",
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </Box>
          {questionUseFieldArray.fields.length <= 0 && (
            <Grid item xs={12}>
              <Typography textAlign="center" color="text.secondary">{`${t(
                "NoQuestions"
              )}`}</Typography>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Box style={{ paddingLeft: "24px" }}>
            <Grid container className="GridContact">
              <Grid
                item
                xs={12}
                sm={12}
                marginTop={2}
                className="GridTopicInput"
              >
                <Grid item xs={12} sm={12}>
                  <i className="fa-regular fa-bone fa-xl"></i>
                  &nbsp;&nbsp;{`${t("Strength")}`}
                </Grid>
              </Grid>
              <Grid item xs={12} marginTop={1} sm={12}>
                <Controller
                  control={control}
                  name={`goodPoint`}
                  render={({ field, formState }) => (
                    <TextFieldTheme
                      {...field}
                      autoComplete="goodPoint"
                      multiline
                      rows={3}
                      error={
                        formState.errors && formState.errors.goodPoint
                          ? true
                          : false
                      }
                      helperText={
                        formState.errors && formState.errors.goodPoint
                          ? formState.errors.goodPoint.message
                          : null
                      }
                    ></TextFieldTheme>
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                marginTop={2}
                className="GridTopicInput"
              >
                <Grid item xs={12} sm={12}>
                  <i className="fa-regular fa-bone-break fa-xl"></i>
                  &nbsp;&nbsp;{`${t("Weakness")}`}
                </Grid>
              </Grid>
              <Grid item xs={12} marginTop={1} sm={12}>
                <Controller
                  control={control}
                  name={`weakPoint`}
                  render={({ field, formState }) => (
                    <TextFieldTheme
                      {...field}
                      multiline
                      rows={3}
                      autoComplete="weakPoint"
                      error={
                        formState.errors && formState.errors.weakPoint
                          ? true
                          : false
                      }
                      helperText={
                        formState.errors && formState.errors.weakPoint
                          ? formState.errors.weakPoint.message
                          : null
                      }
                    ></TextFieldTheme>
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                marginTop={2}
                className="GridTopicInput"
              >
                <Grid item xs={12} sm={12}>
                  {`${t("Other")}`}
                </Grid>
              </Grid>
              <Grid item xs={12} marginTop={1} marginBottom={2} sm={12}>
                <Controller
                  control={control}
                  name={`others`}
                  render={({ field, formState }) => (
                    <TextFieldTheme
                      {...field}
                      multiline
                      rows={2}
                      autoComplete="others"
                      error={
                        formState.errors && formState.errors.others
                          ? true
                          : false
                      }
                      helperText={
                        formState.errors && formState.errors.others
                          ? formState.errors.others.message
                          : null
                      }
                    ></TextFieldTheme>
                  )}
                />
              </Grid>
              {(disableTogglePass ||
                disableToggleExpand ||
                disableToggleNoPass) && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <ButtonBlue
                    onClick={() => {
                      setDisableTogglePass(false);
                      setDisableToggleExpand(false);
                      setDisableToggleNoPass(false);
                      setValue("statusProbation", "");
                      setValue("expandTime", 0);
                    }}
                  >
                    แก้ไข
                  </ButtonBlue>
                </Grid>
              )}
              <Grid item xs={12} marginTop={1} marginBottom={2} display={"flex"} justifyContent={"center"}>
                <Box style={{ position: "relative", display: "inline-flex" }}>
                  <CircularProgress
                    variant="determinate"
                    style={{
                      color: "#eeeeee",
                    }}
                    size={70}
                    thickness={4}
                    value={100}
                  />
                  <CircularProgress
                    variant="determinate"
                    value={percentageScore()}
                    style={{
                      color: "#007afe",
                      animationDuration: "550ms",
                      position: "absolute",
                      left: 0,
                    }}
                    size={70}
                  ></CircularProgress>
                  <Box
                    style={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontSize: 16 }}
                      component="div"
                      color="text.third"
                    >
                      {percentageScore()}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} marginTop={1} sm={12}>
                <Controller
                  control={control}
                  name={`statusProbation`}
                  rules={{
                    required: {
                      value: true,
                      message: `${t("PleaseSelectStatus")}`,
                    },
                  }}
                  render={({ field, formState }) => (
                    <Fragment>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          align: "center",
                          gap: 8,
                        }}
                      >
                        <ButtonBlue
                          sx={{ backgroundColor: "#90EE90", color: "#000000" }}
                          variant="contained"
                          disabled={disableTogglePass}
                          onClick={handleClickOpenDialogPass}
                        >{`${t("PassedProbation")}`}</ButtonBlue>
                        {value.probationRoundTwo ? (
                          <div>
                            <ButtonBlue
                              sx={{
                                backgroundColor: "#FFFF00",
                                color: "#000000",
                              }}
                              variant="contained"
                              disabled={disableToggleExpand}
                              onClick={handleClickOpenDialogExpand}
                            >
                              {`${t("ExtendProbationPeriod")}`}{" "}
                              {getValues("expandTime") !== 0 &&
                                `${t("Quantity")}  ${getValues(
                                  "expandTime"
                                )} ${t("Unit.Days")}`}
                            </ButtonBlue>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <ButtonBlue
                          sx={{ backgroundColor: "#ff0000", color: "#000000" }}
                          variant="contained"
                          disabled={disableToggleNoPass}
                          onClick={handleClickOpenDialogNoPass}
                        >{`${t("NotPassedProbation")}`}</ButtonBlue>
                      </div>
                      <FormHelperText sx={{ color: "#ff0000" }}>
                        {formState.errors && formState.errors.statusProbation
                          ? formState.errors.statusProbation.message
                          : null}
                      </FormHelperText>
                    </Fragment>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {/*dialog ผ่าน*/}
        <Dialog
          open={openDialogPass}
          onClose={handleCloseDialogPass}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{`${t(
            "ConfirmationPassingProbationaryEvaluation"
          )}`}</DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                handleCloseDialogPass();
                console.log("cancel");
              }}
            >
              {`${t("Cancel")}`}
            </Button>
            <Button
              onClick={() => {
                handleCloseDialogPass();
                setValue("statusProbation", "pass");
                clearErrors("statusProbation");
                setDisableToggleExpand(true);
                setDisableToggleNoPass(true);
              }}
              autoFocus
            >
              {`${t("Confirm")}`}
            </Button>
          </DialogActions>
        </Dialog>
        {/*dialog ขยาย*/}
        <Dialog
          open={openDialogExpand}
          onClose={handleCloseDialogExpand}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{`${t(
            "ConfirmationExtendingProbationaryPeriod"
          )}`}</DialogTitle>
          <DialogContent>
            <Controller
              control={control}
              name={`expandTime`}
              render={({ field, formState }) => (
                <TextField
                  {...field}
                  autoComplete="expandTime"
                  margin="dense"
                  fullWidth
                  label={`${t("PleaseProvideDesiredTime")}`}
                  variant="standard"
                  InputProps={{
                    inputComponent: NumberFormatTheme,
                  }}
                  inputProps={{
                    allowNegative: false,
                  }}
                  error={
                    formState.errors && formState.errors.expandTime
                      ? true
                      : false
                  }
                  helperText={
                    formState.errors && formState.errors.expandTime
                      ? formState.errors.expandTime.message
                      : null
                  }
                ></TextField>
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleCloseDialogExpand();
                setValue("expandTime", null);
              }}
            >
              {`${t("Cancel")}`}
            </Button>
            <Button
              onClick={() => {
                handleCloseDialogExpand();
                setValue("statusProbation", "expand");
                setArryField();
                clearErrors("statusProbation");
                setDisableTogglePass(true);
                setDisableToggleNoPass(true);
              }}
              autoFocus
            >
              {`${t("Confirm")}`}
            </Button>
          </DialogActions>
        </Dialog>
        {/*dialog ไม่ผ่าน*/}
        <Dialog
          open={openDialogNoPass}
          onClose={handleCloseDialogNoPass}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            {`${t("ConfirmationNotPassingProbationaryEvaluation")}`}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                handleCloseDialogNoPass();
                console.log("cancel");
              }}
            >
              {`${t("Cancel")}`}
            </Button>
            <Button
              onClick={() => {
                handleCloseDialogNoPass();
                setValue("statusProbation", "nopass");
                clearErrors("statusProbation");
                setDisableToggleExpand(true);
                setDisableTogglePass(true);
              }}
              autoFocus
            >
              {`${t("Confirm")}`}
            </Button>
          </DialogActions>
        </Dialog>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleClose}>
            {`${t("Cancel")}`}
          </ButtonBlue>
          <ButtonBlue
            variant="contained"
            onClick={handleSubmit(onSubmit, (errors) => {
              console.log(errors);
            })}
            autoFocus
          >
            {`${t("SaveData")}`}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogProbation;
