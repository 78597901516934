import React, { Fragment, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import ArticleIcon from "@mui/icons-material/Article";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  getExitInterviewAndAnswer,
  updateWithDrawExitInterview,
} from "../../../../../actions/exitInterview";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import TableCustom from "../../../shared/tableCustom";
import StatusRequest from "../../../shared/requestList/stausRequest";
import DialogExitInterviewShow from "./DialogExitInterviewShow";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../../utils/userData";

const RequestList = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: exitInterviewAnswer } = useSelector(
    (state) => state.exitInterviewAnswer
  );

  const [listAll, setListAll] = useState(null);
  const [openExitInterview, setOpenExitInterview] = useState(false);
  const [typeDialog, setTypeDialog] = useState("Add");
  const [idExitInterview, setIdExitInterview] = useState(null);
  const [detail, setDetail] = useState(null);

  const handleOpenExitInterview = (type, idEdu) => {
    if (type === "Add") {
      setTypeDialog("Add");
    } else {
      setTypeDialog("Edit");
    }
    if (idEdu) {
      setIdExitInterview(idEdu);
    }
    setOpenExitInterview(true);
  };

  const handleClose = () => {
    setOpenExitInterview(false);
    setIdExitInterview(null);
  };

  const handleStatus = (row) => {
    if (row.resultExitInterview === 1) {
      return (
        <div>
          <StatusRequest
            boxSize={30}
            fontSize={18}
            borderRadius={8}
            status={1}
            active={1}
          />
        </div>
      );
    } else if (row.resultExitInterview === 2) {
      return (
        <div>
          <StatusRequest
            boxSize={30}
            fontSize={18}
            borderRadius={8}
            status={0}
            active={1}
          />
        </div>
      );
    } else if (row.resultExitInterview === 3) {
      return (
        <div>
          <StatusRequest
            boxSize={30}
            fontSize={18}
            borderRadius={8}
            status={null}
            step={null}
            active={1}
          />
        </div>
      );
    } else if (row.resultExitInterview === 4) {
      return (
        <div>
          <StatusRequest
            boxSize={30}
            fontSize={18}
            borderRadius={8}
            status={null}
            step={null}
            active={0}
          />
        </div>
      );
    }
  };

  const handleButtonWithDraw = async (row) => {
    let temp = { idResultExitInterview: row.idResultExitInterview };
    const response = await dispatch(updateWithDrawExitInterview(temp));
    if (response.status === 200) {
      dispatch(getExitInterviewAndAnswer());
    }
  };

  const columns = [
    {
      name: `${t("Status")}`,
      headerTextAlign: "center",
      minWidth: "50px",
      width: "50px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>{handleStatus(row)}</Box>
      ),
    },
    {
      name: `${t("EffectiveDate")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {`${dayjs(row.dateStart).format("D MMM BBBB")}`}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("Reason")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>{`${row.reason}`}</Typography>
        </Box>
      ),
    },
    {
      name: `${t("Approver")}`,
      headerTextAlign: "center",
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" sx={{ textAlign: "center" }}>
          <Box flexGrow={1}>
            {row.Manager && (
              <Fragment>
                <Typography>
                  {row.Manager.firstname_EN ? getUserFullName(row.Manager) : "รออนุมัติ"}
                </Typography>
                <Typography color="text.third" fontSize="14px">
                  {row.Manager.email || ""}
                </Typography>
              </Fragment>
            )}
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("ApprovedDate")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          {row.managerDateApprove && (
            <Typography>
              {`${dayjs(row.managerDateApprove).format(
                i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
              )}`}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      headerTextAlign: "center",
      minWidth: "50px",
      width: "50px",
      cellRender: (row) => (
        <div
          style={{ display: "flex", justifyContent: "center", align: "center" }}
        >
          <ButtonBlue
            variant="contained"
            onClick={() => {
              handleOpenExitInterview("Add");
              setDetail(row);
            }}
          >
            <ArticleIcon />
          </ButtonBlue>
        </div>
      ),
    },
    {
      headerTextAlign: "center",
      minWidth: "50px",
      width: "50px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          {row.resultExitInterview === 3 && (
            <IconButton aria-label="more">
              <DeleteOutlineRoundedIcon
                onClick={() => {
                  handleButtonWithDraw(row);
                }}
              />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    if (exitInterviewAnswer) {
      let temp = exitInterviewAnswer.filter(
        (e) => e.idEmployees === userProfile.idEmployees
      );
      setListAll(temp);
    }
  }, [exitInterviewAnswer]);

  return (
    <Box>
      <TableCustom columns={columns} rows={listAll ? listAll : []} />

      {openExitInterview && (
        <DialogExitInterviewShow
          open={openExitInterview}
          handleClose={handleClose}
          idEdu={idExitInterview}
          type={typeDialog}
          detail={detail}
        />
      )}
    </Box>
  );
};

export default RequestList;
