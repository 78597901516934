import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";

import {
  Grid,
  Container,
  Paper,
  Avatar,
  Button,
  Tooltip,
  Typography,
  Box,
  MenuList,
  MenuItem,
  IconButton,
  Menu,
  Fade,
} from "@mui/material";

import { makeStyles } from '@mui/styles';
import AddIcon from "@mui/icons-material/Add";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ConfirmDialog from "../../../shared/general/ConfirmDialog";

import { Breadcrumb } from "../../breadcrums";
import { useSelector, useDispatch } from "react-redux";
import { getAllJob } from "../../../../../actions/job";
import SubJob from "./SubJob";
import AddJob from "./add/addJob";
import EditJob from "./add/editJob";
import CardStyle from "../../../shared/general/Card";
import {
  getAllJobStructure,
  deleteJobStructure,
} from "../../../../../actions/jobStructures";

//

const useStyles = makeStyles(() => ({
  avatarContainer: {
    marginTop: 16,
    marginBottom: 32,
  },
  textContainer: {
    backgroundColor: "#fff",
    borderRadius: "10px",
  },
  paper: {
    height: 50,
    width: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#2c387e",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  icon: {
    fontSize: 28,
    color: "white",
    marginLeft: 0,
    marginRight: 5,
  },
}));

const Job = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: dataJob } = useSelector((state) => state.dataJob);
  const { user: Auth } = useSelector((state) => state.auth);
  const { result: jobStructureStore } = useSelector(
    (state) => state.jobStructures
  );

  const [jobStructureList, setJobStructureList] = useState([]);
  const [jobStructure, setJobStructure] = useState(false);

  const ActionButton = (job) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openEdit, setOpenEdit] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({
      isOpen: false,
      title: "",
      subTitle: "",
    });

    const handleClick = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleActionClose = () => {
      setAnchorEl(null);
    };

    const handleClose = () => {
      setOpenEdit(false);
    };

    const handleOnEdit = (data) => {
      setOpenEdit(true);
      handleActionClose();
    };

    const handleOnDelete = (data) => {
      handleActionClose();
      setConfirmDialog({
        isOpen: true,
        title: "Are you sure you want to delete ?",
        subTitle: "You can't undo this operation",
        onConfirm: () => {
          onDelete(data.idJobStructure, data);
        },
      });
    };

    const onDelete = (id, data) => {
      onDeleteJobStructure(id, data);
    };

    const onDeleteJobStructure = async (id) => {
      await dispatch(deleteJobStructure(id));
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
      fetchData();
    };

    return (
      <>
        <IconButton
          id="fade-button"
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{
            backgroundColor: "#fff",
            "&:hover": { backgroundColor: "#eeeeee" },
            zIndex: 10,
          }}
          size="small"
        >
          <MoreVertIcon sx={{ color: "#212121" }} />
        </IconButton>
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleActionClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={() => handleOnEdit(job.job)}>Edit</MenuItem>
          <MenuItem
            sx={{ color: "#e53935" }}
            onClick={() => handleOnDelete(job.job)}
          >
            Delete
          </MenuItem>
        </Menu>
        {openEdit && (
          <EditJob
            open={openEdit}
            close={handleClose}
            data={job.job}
            fetchData={fetchData}
          />
        )}
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      </>
    );
  };

  useEffect(() => {
    if (jobStructureStore) {
      setJobStructureList(jobStructureStore);
    }
  }, [jobStructureStore]);

  const fetchData = () => {
    dispatch(getAllJobStructure());
  };

  useEffect(() => {
    dispatch(getAllJob());
    fetchData();
  }, []);

  const [jobId, setJobId] = useState(null);
  const [subJob, setSubJob] = useState(false);

  const openSubJob = (id) => {
    if (jobId == id) {
      setJobId(null);
      setSubJob(false);
      setJobStructure(false);
    } else {
      setJobId(id);
      setSubJob(true);
      setJobStructure(true);
    }
  };

  const [openAdd, setOpenAdd] = useState(false);

  const handleClose = () => {
    setOpenAdd(false);
  };

  return (
    <div>
      <Grid container direction="row" alignItems="center">
        <Grid item xl={8} lg={8} md={8} xs={12}>
          <h1 style={{ fontWeight: 600 }}>Job Structure</h1>
        </Grid>
        {Auth.roles.includes("ROLE_ADMIN") && !jobStructure ? (
          <Grid item xl={4} lg={4} md={4} xs={12}>
            <Grid container justifyContent="flex-end">
              <Button
                style={{
                  height: 50,
                  width: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#007afc",
                }}
                onClick={(e) => setOpenAdd(true)}
              >
                <AddIcon className={classes.icon} />
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "white",
                    fontSize: 16,
                    marginBottom: 0,
                  }}
                >
                  Add new Job
                </Typography>
              </Button>
              {openAdd != false && (
                <AddJob
                  open={openAdd}
                  close={handleClose}
                  fetchData={fetchData}
                />
              )}
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      <Box sx={{ marginTop: 2 }}>
        {jobId != null ? (
          <div style={{ width: "100%" }}>
            {jobStructureList &&
              jobStructureList
                .filter((val) => val.idJobStructure == jobId)
                .map((val, index) => (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    key={index}
                  >
                    <Grid item xs={4}>
                      <Paper
                        style={{
                          borderRadius: 20,
                          margin: 8,
                          padding: 15,
                          backgroundColor: val.backgroundColor,
                          cursor: "pointer",
                          height: "90%",
                          border: "3px solid #fff",
                          boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
                        }}
                      >
                        <Box sx={{ marginBottom: 2 }}>
                          <Grid container justifyContent="end" spacing={2}>
                            <Grid item>
                              <ActionButton job={val} />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box
                          sx={{ height: "100%" }}
                          onClick={() => openSubJob(jobId)}
                        >
                          <Tooltip
                            title={
                              <Typography
                                style={{
                                  fontSize: 16,
                                  marginBottom: 0,
                                  paddingBottom: 0,
                                }}
                              >
                                {val.description ? val.description : null}
                              </Typography>
                            }
                          >
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <img
                                alt="pic avatar"
                                src={
                                  val.imageUrl !== null
                                    ? val.imageUrl
                                    : require("./assets/images/employee.png")
                                }
                                style={{
                                  width: "80%",
                                  height: "auto",
                                  marginBottom: "40px",
                                  borderRadius: "10px",
                                }}
                              />
                            </Box>
                          </Tooltip>

                          <Box className={classes.textContainer}>
                            <Typography
                              style={{
                                textAlign: "center",
                                color: "#000",
                                fontSize: "1.5rem",
                                fontWeight: "600",
                                wordWrap: "break-word",
                              }}
                            >
                              {val.jobStructureName}
                            </Typography>
                            <Typography
                              style={{
                                textAlign: "center",
                                marginTop: 5,
                                color: "#000",
                                fontSize: "1.25rem",
                              }}
                            >
                              พนักงานจำนวน - คน
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                ))}
          </div>
        ) : (
          <Grid container>
            {(jobStructureList.length > 0 &&
              jobStructureList.map((val, index) => (
                <Grid item xs={4} key={index}>
                  <Paper
                    style={{
                      borderRadius: 20,
                      margin: 8,
                      padding: 15,
                      backgroundColor: val.backgroundColor,
                      cursor: "pointer",
                      height: "90%",
                      border: "3px solid #fff",
                      boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
                    }}
                  >
                    <Box sx={{ marginBottom: 2 }}>
                      <Grid container justifyContent="end" spacing={2}>
                        <Grid item>
                          <ActionButton job={val} />
                        </Grid>
                      </Grid>
                    </Box>
                    <Tooltip
                      title={
                        <p
                          style={{
                            fontSize: 16,
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          {val.description ? val.description : null}
                        </p>
                      }
                    >
                      <Box
                        sx={{ height: "100%" }}
                        onClick={() => openSubJob(val.idJobStructure)}
                      >
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <img
                            alt="pic avatar"
                            src={
                              val.imageUrl !== null
                                ? val.imageUrl
                                : require("./assets/images/employee.png")
                            }
                            style={{
                              width: "80%",
                              height: "auto",
                              marginBottom: "40px",
                              borderRadius: "10px",
                            }}
                          />
                        </Box>
                        <Box className={classes.textContainer}>
                          <Typography
                            style={{
                              textAlign: "center",
                              color: "#000",
                              fontSize: "1.5rem",
                              fontWeight: "600",
                              wordWrap: "break-word",
                            }}
                          >
                            {val.jobStructureName}
                          </Typography>
                          <Typography
                            style={{
                              textAlign: "center",
                              marginTop: 5,
                              color: "#000",
                              fontSize: "1.25rem",
                            }}
                          >
                            พนักงานจำนวน - คน
                          </Typography>
                        </Box>
                      </Box>
                    </Tooltip>
                  </Paper>
                </Grid>
              ))) || (
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "50px",
                  }}
                >
                  No Data
                </Box>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
      <Box sx={{ marginBottom: "40px" }}>
        {subJob && (
          <SubJob
            jobId={jobId}
            jobStructureList={jobStructureList}
            fetchData={fetchData}
            admin={Auth.roles.includes("ROLE_ADMIN") ? true : false}
          />
        )}
      </Box>
    </div>
  );
};

export default Job;
