import React, { useEffect, useState } from "react";
import OrganizationChart from "@dabeng/react-orgchart";
import "../../../shared/pages/okrs/styles/Dashboard.css";
import OKRDashboardModal from "../../../shared/pages/okrs/components/OKRsDashboardModal";
import SearchIcon from "@mui/icons-material/Search";

import { TeamData, DashboardTeamData, DashboardEmployeeData } from "../DataSet";
import ChartNode from "../../../shared/pages/okrs/components/ChartNode";
import Autocomplete from "@mui/material/Autocomplete";
import FindInPageIcon from "@mui/icons-material/FindInPage";

import { useSelector, useDispatch } from "react-redux";
import { getAllEmployees } from "../../../../../actions/employee";
import { getAllIdvPlan } from "../../../../../actions/IndividualPlans";

import AddOrgChartNode from "./AddOrgChartNode";

import {
  Grid,
  Paper,
  Toolbar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  CircularProgress,
  TextField,
  Typography,
  Container,
  Box,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      height: "50px",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingTop: 16,
      paddingBottom: 16,
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiGrid-justify-xs-flex-end": {
      
    },
  },
  container: {
    padding: 16,
    overflowX: "auto",
    overflowY: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  formControl: {
    margin: 8,
    minWidth: 150,
  },
  searchBtn: {
    marginLeft: 16,
    borderRadius: 8,
    height: 48,
  },
  chartContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f4f4f4",
    border: "1px solid #dee2e6",
    height: "500px",
    borderRadius: 8,
    flexDirection: "column",
  },
  noDataSvg: {
    color: "#222f3e",
    fontSize: "3rem",
  },
  noDataText: {
    color: "#222f3e",
  },
}));

function OKRDashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: empStore } = useSelector((state) => state.employees);
  const { result: idvPlanStore } = useSelector((state) => state.IdvPlans);

  const [isChartLoading, setIsChartLoading] = useState(true);
  const [planDepartment, setPlanDepartment] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [chart, setChart] = useState([]);
  const [selectView, setSelectView] = useState("");
  const [infoTeam, setInfoTeam] = useState(TeamData);
  const [infoIdv, setInfoIdv] = useState([]);
  const [valueTeam, setValueTeam] = useState("");
  const [valueIdv, setValueIdv] = useState("");
  const [isNoData, setIsNoData] = useState(false);
  const [planList, setPlanList] = useState([]);
  const [okrsDashboardModal, setOkrsDashboardModal] = useState({
    isOpen: false,
    name: "",
    type: "",
  });

  const view = [
    { id: "Team", title: "Team" },
    { id: "Individual", title: "Individual" },
  ];

  const AutocompleteTeam = () => {
    return (
      <Autocomplete
        value={valueTeam}
        onChange={(event, newValue) => {
          setValueTeam(newValue);
        }}
        style={{ width: 300 }}
        options={infoTeam.sort((a, b) => -b.value.localeCompare(a.value))}
        autoHighlight
        getOptionLabel={(option) => (option.value ? option.value : "")}
        renderInput={(params) => (
          <TextField {...params} label="Info Team" variant="outlined" />
        )}
      />
    );
  };

  const AutocompleteIdv = () => {
    return (
      <Autocomplete
        value={valueIdv}
        onChange={(event, newValue) => {
          setValueIdv(newValue);
        }}
        style={{ width: 300 }}
        options={infoIdv.sort((a, b) => -b.title.localeCompare(a.title))}
        autoHighlight
        groupBy={(option) => option.department}
        getOptionLabel={(option) => (option.title ? option.title : "")}
        renderInput={(params) => (
          <TextField {...params} label="Info Employee" variant="outlined" />
        )}
      />
    );
  };

  const handleViewChange = (e) => {
    const viewType = e.target.value;
    setSelectView(viewType);

    if (viewType == "Team") {
      setValueIdv("");
      setInfoTeam(TeamData);
    } else if (viewType == "Individual") {
      //Fetch All Employee
      let empList = empStore.map((item) => ({
        id: item.idEmployees,
        title: `${item.firstName} ${item.lastName}`,
        department: item.department,
      }));
      setValueTeam("");
      setInfoIdv(empList);
    }
  };

  const list_to_tree = (list, type) => {
    var map = {},
      node,
      roots = [],
      i;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (type == "team") {
        if (node.parentTeamId !== "0") {
          list[map[node.parentTeamId]].children.push(node);
        } else {
          roots.push(node);
        }
      }
      if (type == "individual") {
        if (node.parentIdvId !== "0") {
          list[map[node.parentIdvId]].children.push(node);
        } else {
          roots.push(node);
        }
      }
    }
    return roots;
  };

  const fetchIdvPlan = (teamName) => {
    let planDep = [];
    let empList = [...empStore];
    //Fetch Employee
    empList.forEach(function (emp) {
      emp.fullName = `${emp.firstName} ${emp.lastName}`;
    });

    //fetchAmountByName
    //filter status approved
    let idvPlans = [...idvPlanStore];

    let idvFilter = idvPlans.filter(function (item) {
      return item.planStatus === "Approved";
    });

    //add property to idvPlans
    idvFilter.forEach((element) => {
      element.createBy = `${element.firstName} ${element.lastName}`;

      //emp data
      let empData = empList.filter(function (item) {
        return item.fullName.includes(element.createBy);
      });

      //element.department = empData[0].department;
      element.department = "HR TPE";

      //filter idvPlans by name
      let amountPlans = idvFilter.filter((x) =>
        x.createBy.includes(element.createBy)
      );

      element.amountIdv = amountPlans.length;
    });

    //group by createBy
    const planGroups = idvFilter.reduce((groups, item) => {
      const group = groups[item.createBy] || [];
      group.push(item);
      groups = group;
      return groups;
    }, {});

    //filter by teamName
    planDep = planGroups.filter((x) => x.department.includes(teamName));

    setPlanDepartment(planDep);
  };

  const fetchTeamChart = (teamName) => {
    const team = DashboardTeamData.filter((x) => x.name == teamName);
    const employee = DashboardEmployeeData.filter((x) =>
      x.department.includes(teamName)
    );

    if (team.length > 0) {
      //Array from Controller
      let arr = planDepartment;
      let totalOKRs = 0;

      //Update array Individual OKRs
      for (var i = 0; i < employee.length; i++) {
        for (var j = 0; j < arr.length; j++) {
          if (employee[i].idEmployees == arr[j].createByEmpId) {
            employee[i].title =
              "Objective : " +
              arr[j].amountIdv +
              " , Key Result : " +
              arr[j].amountIdv * 3;

            totalOKRs += arr[j].amountIdv;
            break;
          }
        }
      }

      //Update array amount OKRs in team
      team[0].title =
        "Objective : " + totalOKRs + " , Key Result : " + totalOKRs * 3;

      let chartData = list_to_tree(team.concat(employee), "team");

      //Set on Chart
      setShow(true);
      setChart(chartData[0]);
      setIsChartLoading(false);
      setIsNoData(false);
    } else {
      setIsNoData(true);
    }
  };

  const fetchIdvChart = (teamName) => {
    console.log("teamName", teamName);
    const employee = DashboardEmployeeData.filter((x) =>
      x.department.includes(teamName)
    );

    if (employee.length > 0) {
      //Array from Controller
      let arr = planDepartment;
      //Update array Individual OKRs
      for (var i = 0; i < employee.length; i++) {
        for (var j = 0; j < arr.length; j++) {
          if (employee[i].name == arr[j].createBy) {
            employee[i].title =
              "Objective : " +
              arr[j].amountIdv +
              " , Key Result : " +
              arr[j].amountIdv * 3;
            break;
          }
        }
      }

      let chartData = list_to_tree(employee, "individual");

      //Set on Chart
      setShow(true);
      setChart(chartData[0]);
      setIsChartLoading(false);
      setIsNoData(false);
    } else {
      setIsNoData(true);
    }
  };

  const handleOnClick = () => {
    if (selectView !== "") {
      if (valueTeam !== "") {
        fetchTeamChart(valueTeam.value);
      }
      if (valueIdv !== "") {
        fetchIdvChart(valueIdv.department);
      }
    }
  };

  const handleNodeClick = (nodeData) => {
    //Fetch Employee
    let empList = [...empStore];

    if (nodeData.type == "Individual") {
      let idvList = [...idvPlanStore];

      let idvPlans = idvList.filter(function (x) {
        let createBy = `${x.firstName} ${x.lastName}`;
        return (
          createBy.includes(nodeData.name) && x.planStatus.includes("Approved")
        );
      });

      idvPlans.forEach(function (element) {
        let createBy = `${element.firstName} ${element.lastName}`;
        //emp data
        let empData = empList.filter(function (item) {
          return item.fullName.includes(createBy);
        });

        //element.department = empData[0].department;
        element.department = "HR TPE";
      });

      setOkrsDashboardModal({
        isOpen: true,
        name: nodeData.name,
        type: "Individual",
      });

      setPlanList(idvPlans);
      setIsLoading(false);
    } else if ((nodeData.type = "Team")) {
      //Fetch by team
      let idvPlans = [...idvPlanStore];

      idvPlans.forEach(function (element) {
        element.createBy = `${element.firstName} ${element.lastName}`;

        //emp data
        let empData = empList.filter(function (item) {
          return item.fullName.includes(element.createBy);
        });

        //element.department = empData[0].department;
        element.department = "HR TPE";
      });

      let idvFilter = idvPlans.filter(
        (x) => x.department === nodeData.name && x.planStatus === "Approved"
      );

      setOkrsDashboardModal({
        isOpen: true,
        name: nodeData.name,
        type: "Team",
      });
      setPlanList(idvFilter);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (empStore && idvPlanStore) {
      const team = "HR TPE";
      fetchIdvPlan(team);
      setSelectView("Team");
      setValueTeam({ label: team, value: team });
      fetchTeamChart(team);
    }
  }, [empStore, idvPlanStore]);

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getAllIdvPlan());
  }, []);

  return (
    <>
      <div>
        <div>
          <Toolbar sx={{ marginTop: 4 }}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box>{/* <AddOrgChartNode /> */}</Box>
                </Box>
              </Grid>
              <Grid item>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-end"
                  item
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="select-view">View</InputLabel>
                      <Select
                        labelId="select-view"
                        label="View"
                        name="view"
                        value={selectView}
                        onChange={handleViewChange}
                      >
                        {view.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl className={classes.formControl}>
                      {selectView === "Team" ? (
                        <AutocompleteTeam />
                      ) : (
                        <AutocompleteIdv />
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.searchBtn}
                      onClick={handleOnClick}
                    >
                      <SearchIcon /> View
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
          {show ? (
            <>
              {isChartLoading ? (
                <div className={classes.chartContainer}>
                  <CircularProgress />
                </div>
              ) : !isNoData ? (
                <OrganizationChart
                  datasource={chart}
                  chartClass="myChart"
                  NodeTemplate={ChartNode}
                  onClickNode={(nodeData) => handleNodeClick(nodeData)}
                />
              ) : (
                <div className={classes.chartContainer}>
                  <div>
                    <FindInPageIcon className={classes.noDataSvg} />
                  </div>
                  <div>
                    <Typography variant="h6" className={classes.noDataText}>
                      No Data {chart.length}
                    </Typography>
                  </div>
                </div>
              )}
            </>
          ) : null}
        </div>
        <OKRDashboardModal
          okrsDashboardModal={okrsDashboardModal}
          setOkrsDashboardModal={setOkrsDashboardModal}
          planList={planList}
          isLoading={isLoading}
        />
      </div>
    </>
  );
}

export default OKRDashboard;
