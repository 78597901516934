import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { updateIndividualKeyResult } from "../../../../../../actions/okrs";
import { getManager } from "../../../../../../actions/manager";
import { useTranslation } from "react-i18next";
import FlagIcon from "@mui/icons-material/Flag";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";

const EditInfoKRModal = ({ open, handleClose, data }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { result: managers } = useSelector((state) => state.manager);
  const [loading, setLoading] = useState(false);

  const validateYupSchema = yup.object({
    keyResultName: yup.string().required(`${t("ThisFieldIsRequired")}`),
    description: yup.string().required(`${t("ThisFieldIsRequired")}`),
    tacticName: yup.string().required(`${t("ThisFieldIsRequired")}`),
    startDate: yup.date().required(`${t("ThisFieldIsRequired")}`),
    endDate: yup
      .date()
      .required(`${t("ThisFieldIsRequired")}`)
      .min(yup.ref("startDate"), `${t("EndDateMustBeAfterStartDate")}`),
    idManager: yup.string().required(`${t("ThisFieldIsRequired")}`),
    measureEvidence: yup.string().required(`${t("ThisFieldIsRequired")}`),
    commitQuality: yup.string().required(`${t("ThisFieldIsRequired")}`),
    measureDataType: yup.string().required(`${t("ThisFieldIsRequired")}`),
  });

  const useHookForm = useForm({
    defaultValues: {
      keyResultName: data.keyResultName || "",
      description: data.description || "",
      tacticName: data.tacticName || "",
      startDate: data.startDate ? dayjs(data.startDate) : null,
      endDate: data.endDate ? dayjs(data.endDate) : null,
      idManager: data.idManager || "",
      measureEvidence: data.measureEvidence || "",
      commitQuality: data.commitQuality || "",
      measureDataType: data.measureDataType || "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "onSubmit",
  });

  useEffect(() => {
    dispatch(getManager());
  }, [dispatch]);

  const onSubmit = async (formData) => {
    setLoading(true);
    const updatedData = {
      keyResultName: formData.keyResultName,
      description: formData.description,
      tacticName: formData.tacticName,
      startDate: formData.startDate
        ? dayjs(formData.startDate).format("YYYY-MM-DD")
        : null,
      endDate: formData.endDate
        ? dayjs(formData.endDate).format("YYYY-MM-DD")
        : null,
      idManager: formData.idManager,
      measureEvidence: formData.measureEvidence,
      commitQuality: formData.commitQuality,
      measureDataType: formData.measureDataType,
    };

    const response = await dispatch(
      updateIndividualKeyResult(data.idIndividualKeyResult, updatedData)
    );
    setLoading(false);

    if (response && response.status === 200) {
      Swal.fire({
        icon: "success",
        title: t("UpdateSuccessful"),
        showConfirmButton: false,
        timer: 1500,
      });
      handleClose();
    } else {
      Swal.fire({
        icon: "error",
        title: t("UpdateFailed"),
        text: response ? response.data.message : t("AnErrorOccurred"),
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      onClick={(event) => event.stopPropagation()}
    >
      <DialogTitle>{t("EditKeyResult")}</DialogTitle>
      <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="keyResultName"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("KeyResultName")}
                    inputProps={{
                      placeholder: `${t("Name")}`,
                    }}
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="description"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("Descriptions")}
                    inputProps={{
                      placeholder: `${t("Descriptions")}`,
                    }}
                    rows={3}
                    multiline
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="tacticName"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("Strategy")}
                    inputProps={{
                      placeholder: `${t("Strategy")}`,
                    }}
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="startDate"
                control={useHookForm.control}
                render={({ field }) => (
                  <LocalizationProvider
                    dateFormats={{
                      year: "YYYY",
                      monthAndYear: "MMMM YYYY",
                    }}
                    dateAdapter={AdapterDayjs}
                  >
                    <DatePicker
                      {...field}
                      views={["year", "month", "day"]}
                      inputFormat="DD MMMM YYYY"
                      disableMaskedInput
                      openTo="year"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          label={t("StartDate")}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: `${t("DayMonthYear(AD)")}`,
                            readOnly: true,
                          }}
                          onBlur={field.onBlur}
                          helperText={
                            useHookForm.formState.errors[field.name]
                              ? useHookForm.formState.errors[field.name].message
                              : null
                          }
                          error={
                            useHookForm.formState.errors[field.name]
                              ? true
                              : false
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="endDate"
                control={useHookForm.control}
                render={({ field }) => (
                  <LocalizationProvider
                    dateFormats={{
                      year: "YYYY",
                      monthAndYear: "MMMM YYYY",
                    }}
                    dateAdapter={AdapterDayjs}
                  >
                    <DatePicker
                      {...field}
                      views={["year", "month", "day"]}
                      inputFormat="DD MMMM YYYY"
                      disableMaskedInput
                      openTo="year"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          label={t("EndDate")}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: `${t("DayMonthYear(AD)")}`,
                            readOnly: true,
                          }}
                          onBlur={field.onBlur}
                          helperText={
                            useHookForm.formState.errors[field.name]
                              ? useHookForm.formState.errors[field.name].message
                              : null
                          }
                          error={
                            useHookForm.formState.errors[field.name]
                              ? true
                              : false
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="idManager"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    select
                    label={t("ReviewerApprover")}
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  >
                    {managers &&
                      managers.map((manager) => (
                        <MenuItem
                          key={manager.idEmployees}
                          value={manager.idEmployees}
                        >
                          {manager.firstname_TH} {manager.lastname_TH}
                        </MenuItem>
                      ))}
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="measureEvidence"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("MeasurementData")}
                    inputProps={{
                      placeholder: `${t("MeasurementData")}`,
                    }}
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="commitQuality"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("ObjectiveMeasurementData")}
                    inputProps={{
                      placeholder: `${t("ObjectiveMeasurementData")}`,
                    }}
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="measureDataType"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    select
                    label={t("TypeMeasurementData")}
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  >
                    <MenuItem value="1">{t("Number")}</MenuItem>
                    <MenuItem value="2">{t("Explanation")}</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("Cancel")}</Button>
          <Button
            type="submit"
            variant="contained"
            disabled={loading || useHookForm.formState.isSubmitting}
          >
            {loading || useHookForm.formState.isSubmitting ? (
              <CircularProgress size="24px" color="inherit" />
            ) : (
              t("Save")
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditInfoKRModal;
