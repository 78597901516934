import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";

import {
  Typography,
  Breadcrumbs,
  ToggleButtonGroup,
  ToggleButton,
  Container,
} from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import OfficialDocument from "./official-documents";
import PaySlipPanel from "./pay-slip";
import TotalPanel from "./total";

import { getDepartment } from "../../../../actions/company";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    color: "#46cbe2",
    border: "1px solid #46cbe2",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#247785",
      backgroundColor: "#247785",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#46cbe2",
      "&:hover": {
        borderColor: "#247785",
        backgroundColor: "#247785",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #46cbe2",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
  },
}));

const ReportAdminPage = (props) => {
  const { handleClose } = props;
  const dispatch = useDispatch();
  const [view, setView] = React.useState("pay-slip");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(getDepartment());
  }, []);

  const handleChangeView = (event, newView) => {
    if (newView) {
      setView(newView);
    }
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to={"/employees-report"}
          >
            {`${t("AllReports")}`}
          </Link>
          <Typography color="text.primary">{t("SalarySlip")}</Typography>
        </Breadcrumbs>
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            {t("SalarySlip")}
          </Typography>
        </div>
        <PaySlipPanel />
      </Container>
    </StyledRoot>
  );
};

export default ReportAdminPage;
