import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import { getAllWork } from "../../../../actions/work";
import { Breadcrumb2 } from "../../shared/breadcrums";
import DataViewSuggest from "../../shared/pages/viewSuggestion";
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import makeStyles from '@mui/styles/makeStyles';
import { getEmployeeCareerPlanByID } from "../../../../actions/careerPlan";

const useStyles = makeStyles(() => ({
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
}))

const ViewSuggest = (props) => {
  //use style in name of classes
  const classes = useStyles();
  const dispatch = useDispatch();
  const { idEmployees } = useParams();
  const [empProfile, setEmpProfile] = useState({});

  useEffect(() => {
    const fetchedData = async (idEmployees) => {
      try {
        const response = await getEmployeeCareerPlanByID(idEmployees);
        if (response && response.data) {
          setEmpProfile(response.data[0]);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchedData(idEmployees);
  }, []);

  return (
    <>
      {
        empProfile && (
          <DataViewSuggest
            empProfile={empProfile}
            name='All data suggest Career'
            // breadcrumb={
            //   <Breadcrumb2
            //     textLabel={"All data suggest Career"}
            //     icon={<AccessibilityNewIcon className={classes.sendIcon} />}
            //     link={"/manager/career"}
            //     prevText={"Career"}
            //   />
            // }
            suggest={true}
          />
        )}
    </>
  );
};

export default ViewSuggest;