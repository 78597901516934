import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Container,
  Grid,
  Typography,
  styled,
  Card,
  Select,
  MenuItem,
  Stack,
  SvgIcon,
  ListItemAvatar,
  ListItemText,
  Button,
  FormControl,
} from "@mui/material";
import StarRateIcon from "@mui/icons-material/StarRate";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { useDispatch, useSelector } from "react-redux";
import { getOKRScoreboard } from "../../../../../actions/okrs";
import {
  getUserDepartment,
  getUserFullName,
  getUserPosition,
} from "../../../../../utils/userData";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { ReactComponent as MoonshotIcon } from "../../../assets/moonshot_logo.svg";

const StyledRoot = styled(Box)({
  "& .keyresult-text": {
    fontSize: "20px",
    fontWeight: 500,
  },
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const StyledCard = styled(Card)({
  minHeight: "70px",
  boxShadow: "none",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  background:
    "linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))",
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
});

const StyleHeadBox = styled(Box)({
  "& .box-header": {
    minHeight: "200px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor:
      "linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))",
    "& .text-header": {
      fontSize: "48px",
      fontWeight: "bold",
    },
    "& .img-header": {
      objectFit: "contain",
    },
  },
  "@media (max-width: 600px)": {
    "& .box-header": {
      flexDirection: "column",
      minHeight: "unset",
      justifyContent: "center",
      alignItems: "center",
      "& .text-header": {
        fontSize: "36px",
        marginTop: "20px",
        aliignText: "center",
      },
      // "& .img-header": {
      //   width: "30%",
      //   justifyContent: "flex-start",
      //   alignItems: "flex-start",
      // },
    },
  },
});

const ContainerStyled = styled(Container)({
  paddingTop: "10px",
  height: "100%",
  minHeight: "100vh",
});

const StyledRating = styled(Box)(({ backgroundColor }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "8px",
  padding: "16px",
  borderRadius: "16px",
  maxWidth: "160px",
  backgroundColor,
}));

function OKRScoreboard() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [selectYear, setSelectYear] = useState(dayjs().get("year"));
  const [scoreBoardData, setScoreBoardData] = useState([]);
  const [descending, setDescending] = useState(true);
  const [mode, setMode] = useState("score");

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: okrScoreboard } = useSelector((state) => state.okrScoreboard);

  const handleChange = (event) => {
    setSelectYear(event.target.value);
  };

  useEffect(() => {
    let temp = null;
    if (okrScoreboard) {
      if (mode === "score") {
        temp = [...okrScoreboard].sort((a, b) =>
          descending
            ? b.averageScore - a.averageScore
            : a.averageScore - b.averageScore
        );
      } else {
        temp = [...okrScoreboard].sort((a, b) =>
          descending ? b.moonShot - a.moonShot : a.moonShot - b.moonShot
        );
      }
      setScoreBoardData(temp);
    }
  }, [mode, descending, okrScoreboard]);

  useEffect(() => {
    if (userProfile) {
      dispatch(
        getOKRScoreboard({
          idCompany: userProfile.idCompany,
          year: selectYear,
        })
      );
    }
  }, [userProfile, selectYear]);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <StyledCard
          style={{
            marginBottom: "48px",
            marginTop: "20px",
          }}
        >
          <StyleHeadBox>
            <Box className="box-header">
              <Grid
                container
                pt={"40px"}
                pl={{ xs: "16px", sm: "40px" }}
                pb={{ xs: "0px", sm: "40px" }}
              >
                <Grid item xs={12}>
                  <Typography className="text-header">
                    {t("OKRScroeboard")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    fontSize="14px"
                    fontWeight="600"
                    marginBottom="4px"
                    color="text.third"
                  >
                    {t("SelectYear")}
                  </Typography>

                  <Select
                    value={selectYear}
                    onChange={handleChange}
                    sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
                  >
                    {[0, 1, 2].map((item) => (
                      <MenuItem key={item} value={dayjs().get("year") - item}>
                        {dayjs()
                          .subtract(item, "year")
                          .format(
                            i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"
                          )}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <img
                className="img-header"
                src={`${process.env.PUBLIC_URL}/assets/okrs/admin/progress-status.png`}
                alt="icon"
              />
            </Box>
          </StyleHeadBox>
        </StyledCard>
        <ContainerStyled>
          {scoreBoardData && scoreBoardData.length > 0 ? (
            <div
              style={{
                borderRadius: "20px",
                padding: "16px 40px",
                background: "linear-gradient(to right, #de6262, #ffb88c)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexWrap: "wrap",
                  gap: "8px",
                  marginBottom: "32px",
                }}
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    maxWidth: 140,
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    borderRadius: "16px",
                    "& .MuiInputBase-root": { borderRadius: "16px" },
                  }}
                >
                  <Select
                    value={mode}
                    onChange={(e) => setMode(e.target.value)}
                  >
                    <MenuItem value={"score"}>Score</MenuItem>
                    <MenuItem value={"moonshot"}>Moonshot</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  sx={{ width: 140, borderRadius: "16px" }}
                  variant="contained"
                  startIcon={<SwapVertIcon fontSize="large" />}
                  onClick={() => setDescending((prev) => !prev)}
                >
                  {descending ? t("Descending") : t("Ascending")}
                </Button>
              </div>
              <Stack gap={3}>
                {scoreBoardData.map((item) => (
                  <Grid
                    container
                    spacing={1}
                    padding={"8px 16px"}
                    sx={{
                      background: "rgba(255, 255, 255, 0.25)",
                      borderRadius: "16px",
                      boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                      backdropFilter: "blur(5px)",
                      border: "1px solid rgba( 255, 255, 255, 0.18 )",
                    }}
                  >
                    <Grid item xs={12} sm display="flex" alignItems="center">
                      <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={item.imageURL} />
                      </ListItemAvatar>
                      <ListItemText
                        sx={{ "& .MuiTypography-root": { color: "#ffffff" } }}
                        primary={
                          <Typography color="#ffffff" fontSize="20px">
                            {getUserFullName(item)}
                          </Typography>
                        }
                        secondary={
                          <>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              color="#ffffff"
                            >
                              {getUserPosition(item)}
                            </Typography>
                            {` — ${getUserDepartment(item)}`}
                          </>
                        }
                      />
                    </Grid>
                    <Grid item xs="auto" minWidth="110px">
                      <StyledRating backgroundColor={"#dfe8ff"}>
                        <SvgIcon fontSize="large">
                          <MoonshotIcon />
                        </SvgIcon>
                        <Typography fontSize="20px" fontWeight={500}>
                          {item.moonShot}
                        </Typography>
                      </StyledRating>
                    </Grid>
                    <Grid item xs="auto" minWidth="110px">
                      <StyledRating backgroundColor={"#fff9db"}>
                        <StarRateIcon
                          fontSize="large"
                          sx={{ "&.MuiSvgIcon-root": { color: "#ffdd40" } }}
                        />
                        <Typography fontSize="20px" fontWeight={500}>
                          {parseFloat(item.averageScore.toFixed(1))}
                        </Typography>
                      </StyledRating>
                    </Grid>
                  </Grid>
                ))}
              </Stack>
            </div>
          ) : (
            <Typography paddingTop="24px" textAlign="center">
              {t("NoItems")}
            </Typography>
          )}
        </ContainerStyled>
      </Container>
    </StyledRoot>
  );
}

export default OKRScoreboard;
