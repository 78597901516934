import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  Box,
  Card,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  colors,
} from "@mui/material";
import utils from "../../../../utils";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 24,
  },
});

const StyledDiv = styled("div")({
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  // alignItems: "center",
});

const StyledCard = styled(Card)(({}) => ({
  borderRadius: 8,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "900px",
  width: "60%",
  "& .MuiCardHeader-root": {
    padding: 24,
  },
  margin: "16px 0",
}));

const StyledTable = styled(TableContainer)({
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#46cbe2",
    color: "#ffffff",
    fontSize: 16,
    textAlign: "center",
    padding: "18px",
  },

  "& .tableCellLable": {
    textAlign: "left",
  },

  "& .tableCellRegular": {
    textAlign: "center",
    fontWeight: "500",
  },

  "& .tableCellIrregular": {
    textAlign: "center",
    fontWeight: "500",
  },

  "& .green": {
    color: "#43a047",
    fontWeight: "700",
    textDecoration: "underline",
  },

  "& .blue": {
    color: "#3589e5",
    fontWeight: "600",
    textDecoration: "underline",
  },

  "& .red": {
    color: "#e53935",
    fontWeight: "600",
    textDecoration: "underline",
  },

  "& .purple": {
    color: "#9135e5",
    fontWeight: "600",
    textDecoration: "underline",
  },

  "& .orange": {
    color: "#e59135",
    fontWeight: "600",
    textDecoration: "underline",
  },

  "& .pink": {
    color: "#e53589",
    fontWeight: "600",
    textDecoration: "underline",
  },
});

const StyledTableRow = (props) => {
  const { label, regular, irregular, colorRegular, colorIrregular } = props;
  const { t, i18n } = useTranslation();

  return (
    <TableRow>
      <TableCell className={`tableCellLable`}>{t(label)}</TableCell>
      <TableCell className={`tableCellRegular ${colorRegular}`}>
        {utils.numberWithCommas(regular)}
      </TableCell>
      <TableCell className={`tableCellIrregular ${colorIrregular}`}>
        {utils.numberWithCommas(irregular)}
      </TableCell>
    </TableRow>
  );
};

const StyledTableSummaryRow = (props) => {
  const { label, data, month, color } = props;
  const { t, i18n } = useTranslation();

  return (
    <TableRow>
      <TableCell className={`tableCellLable`}>{t(label)}</TableCell>
      <TableCell className={`tableCellIrregular ${color}`} colSpan={2}>
        {utils.numberWithCommas(data)}
      </TableCell>
    </TableRow>
  );
};

const PersonalTax = ({ data }) => {
  const { t, i18n } = useTranslation();
  const [regular, setRegular] = useState({});
  const [irregular, setIrregular] = useState({});

  useEffect(() => {
    if (data) {
      setRegular(data.regular);
      setIrregular(data.irregular);
      console.log(data);
    }
  }, [data]);

  return (
    <StyledRoot>
      <Container>
        <StyledDiv>
          <StyledCard>
            <StyledTable>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={`tableCellHead`}>
                      {t("List")}
                    </TableCell>
                    <TableCell className={`tableCellHead`}>
                      {t("RegularIncome")}
                    </TableCell>
                    <TableCell className={`tableCellHead`}>
                      {t("IrregularIncome")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow
                    label="Salary"
                    regular={data.regular.salary}
                    irregular={data.irregular.salary}
                    colorRegular=""
                    colorIrregular=""
                  />
                  <StyledTableRow
                    label="AnnualIncome"
                    regular={data.regular.annualIncome}
                    irregular={data.irregular.annualIncome}
                    colorRegular=""
                    colorIrregular=""
                  />
                  <StyledTableRow
                    label="YTDIncome"
                    regular={data.regular.ytdOtherIncome}
                    irregular={data.irregular.ytdOtherIncome}
                    colorRegular=""
                    colorIrregular=""
                  />
                  <StyledTableRow
                    label="TotalIncome"
                    regular={data.regular.totalIncome}
                    irregular={data.irregular.totalIncome}
                    colorRegular="blue"
                    colorIrregular="blue"
                  />
                  <StyledTableRow
                    label="ProvidentFundAllowance"
                    regular={data.regular.pfAllowance}
                    irregular={data.irregular.pfAllowance}
                    colorRegular=""
                    colorIrregular=""
                  />
                  <StyledTableRow
                    label="SSOAllowance"
                    regular={data.regular.ssoAllowance}
                    irregular={data.irregular.ssoAllowance}
                    colorRegular=""
                    colorIrregular=""
                  />
                  <StyledTableRow
                    label="TaxAllowance"
                    regular={data.regular.taxAllowance}
                    irregular={data.irregular.taxAllowance}
                    colorRegular=""
                    colorIrregular=""
                  />
                  <StyledTableRow
                    label="TotalTaxAllowanceFromIncome"
                    regular={data.regular.totalAllowanceFromIncome}
                    irregular={data.irregular.totalAllowanceFromIncome}
                    colorRegular="red"
                    colorIrregular="red"
                  />
                  <StyledTableRow
                    label="ExpenseAllowance"
                    regular={data.regular.expenseAllowance}
                    irregular={data.irregular.expenseAllowance}
                    colorRegular=""
                    colorIrregular=""
                  />
                  <StyledTableRow
                    label="PersonalAllowance"
                    regular={data.regular.personalAllowance}
                    irregular={data.irregular.personalAllowance}
                    colorRegular=""
                    colorIrregular=""
                  />
                  <StyledTableRow
                    label="TotalTaxAllowance"
                    regular={data.regular.totalAllowanceFromRD}
                    irregular={data.irregular.totalAllowanceFromRD}
                    colorRegular="red"
                    colorIrregular="red"
                  />
                  <StyledTableRow
                    label="TotalIncomeAndTaxAllowance"
                    regular={data.regular.totalIncomeAndTaxAllowance}
                    irregular={data.irregular.totalIncomeAndTaxAllowance}
                    colorRegular="blue"
                    colorIrregular="blue"
                  />

                  <StyledTableSummaryRow
                    label="EstimatedThisYearTax"
                    data={data.estimatedTaxThisYear}
                    color="orange"
                  />

                  <StyledTableSummaryRow
                    label="PaidTax"
                    data={data.paidTax}
                    color="red"
                  />

                  <StyledTableSummaryRow
                    label="RemainningTax"
                    data={data.remainningTax}
                    color="blue"
                  />

                  <StyledTableSummaryRow
                    label="TaxRefund"
                    data={data.refundTax}
                    color="orange"
                  />

                  <StyledTableSummaryRow
                    label="TaxToBePaidThisMonth"
                    data={data.taxToBePaid}
                    color="green"
                  />
                </TableBody>
              </Table>
            </StyledTable>
          </StyledCard>

          <StyledCard>
            <StyledTable>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={`tableCellHead`}>
                      {t("List")}
                    </TableCell>
                    <TableCell className={`tableCellHead`}>
                      {t("Amount")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className={`tableCellLable`}>
                      {t("RemainningTax")}
                    </TableCell>
                    <TableCell className={`tableCellIrregular blue`}>
                      {utils.numberWithCommas(data.remainningTax)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={`tableCellLable`}>
                      {t("RemainningMonth")}
                    </TableCell>
                    <TableCell className={`tableCellIrregular `}>
                      {data.remainingMonth}
                    </TableCell>
                  </TableRow>
                  {data.remainingRestYear &&
                    data.remainingRestYear.map((element, index) => (
                      <TableRow key={index}>
                        <TableCell className={`tableCellLable`}>
                          {t("TaxToBePaidMonth")} {t(`Months.${element.month}`)}
                        </TableCell>
                        <TableCell className={`tableCellIrregular orange`}>
                          {utils.numberWithCommas(element.value)}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </StyledTable>
          </StyledCard>
        </StyledDiv>
      </Container>
    </StyledRoot>
  );
};

export default PersonalTax;
