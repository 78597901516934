import {
  NEW_CANDIDATES_FETCHING,
  NEW_CANDIDATES_FAILED,
  NEW_CANDIDATES_SUCCESS,
  INTERVIEWED_CANDIDATE_FETCHING,
  INTERVIEWED_CANDIDATE_FAILED,
  INTERVIEWED_CANDIDATE_SUCCESS,
  SCHEDULEANDCANDIDATES_FETCHING,
  SCHEDULEANDCANDIDATES_FAILED,
  SCHEDULEANDCANDIDATES_SUCCESS,
  CANDIDATES_ALREADY_SENT_FETCHING,
  CANDIDATES_ALREADY_SENT_FAILED,
  CANDIDATES_ALREADY_SENT_SUCCESS,
  PASSED_CANDIDATES_COMPANY_FETCHING,
  PASSED_CANDIDATES_COMPANY_FAILED,
  PASSED_CANDIDATES_COMPANY_SUCCESS,
  HISTORY_CANDIDATES_JOBS_MATCHING_FETCHING,
  HISTORY_CANDIDATES_JOBS_MATCHING_FAILED,
  HISTORY_CANDIDATES_JOBS_MATCHING_SUCCESS,
  HISTORY_MANPOWERS_SENDING_FETCHING,
  HISTORY_MANPOWERS_SENDING_FAILED,
  HISTORY_MANPOWERS_SENDING_SUCCESS,
} from "./types";
import CandidateService from "../services/candidate.service";

export const addManpowersToCandidate = (value, idJobRequest) => async () => {
  try {
    let data = {
      listEmployees: [...value.map((emp) => emp.idManpower)],
      idJobRequest: idJobRequest,
    };
    const res = await CandidateService.addManpowersToCandidate(data);
    if (res) {
      return res.data;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getNewCandidatesByIdJobRequest =
  (idJobRequest) => async (dispatch) => {
    try {
      dispatch({
        type: NEW_CANDIDATES_FETCHING,
      });
      const res = await CandidateService.getNewCandidatesByIdJobRequest(
        idJobRequest
      );
      if (res) {
        if(res.status === 200){
          dispatch({
            type: NEW_CANDIDATES_SUCCESS,
            payload: res.data,
          });
        }else{
          throw new Error(res.message)
        }
      }
    } catch (error) {
      dispatch({
        type: NEW_CANDIDATES_FAILED,
      });
      return error.response;
    }
  };

export const getNewCandidatesByIdBiddings = (idBidding) => async (dispatch) => {
  try {
    dispatch({
      type: NEW_CANDIDATES_FETCHING,
    });
    const res = await CandidateService.getNewCandidatesByIdBiddings(idBidding);
    if (res) {
      dispatch({
        type: NEW_CANDIDATES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: NEW_CANDIDATES_FAILED,
    });
  }
};

export const updateRatingAndNoteByIdCandidate =
  (idCandidate, data) => async () => {
    try {
      const res = await CandidateService.updateRatingAndNoteByIdCandidate(
        idCandidate,
        data
      );
      if (res) {
        return res.data;
      }
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.name) ||
        error.name ||
        error.toString();
      return "Error";
    }
  };

export const updateInterviewDetail = (data) => async () => {
  try {
    const res = await CandidateService.updateInterviewDetail(data);
    if (res) {
      return res.data;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getInterviewedCandidatesByIdVendor = () => async (dispatch) => {
  try {
    dispatch({
      type: INTERVIEWED_CANDIDATE_FETCHING,
    });
    const res = await CandidateService.getInterviewedCandidatesByIdVendor();
    if (res) {
      dispatch({
        type: INTERVIEWED_CANDIDATE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: INTERVIEWED_CANDIDATE_FAILED,
    });
  }
};

export const getInterviewScheduleAndCandidatesByIdCompany =
  () => async (dispatch) => {
    try {
      dispatch({
        type: SCHEDULEANDCANDIDATES_FETCHING,
      });
      const res =
        await CandidateService.getInterviewScheduleAndCandidatesByIdCompany();
      if (res) {
        dispatch({
          type: SCHEDULEANDCANDIDATES_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: SCHEDULEANDCANDIDATES_FAILED,
      });
    }
  };

export const getHistoryInterviewScheduleAndCandidatesByIdcompany =
  () => async (dispatch) => {
    try {
      dispatch({
        type: SCHEDULEANDCANDIDATES_FETCHING,
      });
      const res =
        await CandidateService.getHistoryInterviewScheduleAndCandidatesByIdcompany();
      if (res) {
        dispatch({
          type: SCHEDULEANDCANDIDATES_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: SCHEDULEANDCANDIDATES_FAILED,
      });
    }
  };

export const updateConcludsionInterview = (data) => async () => {
  try {
    const res = await CandidateService.updateConcludsionInterview(data);
    if (res) {
      return res.data;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateResultAppointedEachCandidate = (data) => async () => {
  try {
    const res = await CandidateService.updateResultAppointedEachCandidate(data);
    if (res) {
      return res.data;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getCandidatesAlreadySendToJob =
  (idJobRequest) => async (dispatch) => {
    try {
      dispatch({
        type: CANDIDATES_ALREADY_SENT_FETCHING,
      });
      const res = await CandidateService.getCandidatesAlreadySentToJob(
        idJobRequest
      );
      if (res) {
        dispatch({
          type: CANDIDATES_ALREADY_SENT_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: CANDIDATES_ALREADY_SENT_FAILED,
      });
    }
  };

export const updateCancelSendingCandidate = (data) => async (dispatch) => {
  try {
    const res = await CandidateService.updateCancelSendingCandidate(data);
    if (res) {
      return res.data;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getPassedCandidatesInJob = (idJob) => async (dispatch) => {
  try {
    dispatch({
      type: PASSED_CANDIDATES_COMPANY_FETCHING,
    });
    const res = await CandidateService.getPassedCandidatesInJob(idJob);
    if (res) {
      dispatch({
        type: PASSED_CANDIDATES_COMPANY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: PASSED_CANDIDATES_COMPANY_FAILED,
    });
  }
};

export const sendManpowersToCandidateBidding = (data) => async () => {
  try {
    const res = await CandidateService.sendManpowersToCandidateBidding(data);
    if (res) {
      return res.data;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const getPassedCandidatesInBidding = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PASSED_CANDIDATES_COMPANY_FETCHING,
    });
    const res = await CandidateService.getPassedCandidatesInBidding(id);
    if (res) {
      dispatch({
        type: PASSED_CANDIDATES_COMPANY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: PASSED_CANDIDATES_COMPANY_FAILED,
    });
  }
};

export const getCandidatesAlreadySentToBidding =
  (idBiddings) => async (dispatch) => {
    try {
      dispatch({
        type: CANDIDATES_ALREADY_SENT_FETCHING,
      });
      const res = await CandidateService.getCandidatesAlreadySentToBidding(
        idBiddings
      );
      if (res) {
        dispatch({
          type: CANDIDATES_ALREADY_SENT_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: CANDIDATES_ALREADY_SENT_FAILED,
      });
    }
  };

export const getHistoryCandidatesJobsMatchingVendor =
  () => async (dispatch) => {
    try {
      dispatch({
        type: HISTORY_CANDIDATES_JOBS_MATCHING_FETCHING,
      });
      const res =
        await CandidateService.getHistoryCandidatesJobsMatchingVendor();
      if (res) {
        dispatch({
          type: HISTORY_CANDIDATES_JOBS_MATCHING_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: HISTORY_CANDIDATES_JOBS_MATCHING_FAILED,
      });
    }
  };

export const getHistoryManpowersSending = () => async (dispatch) => {
  try {
    dispatch({
      type: HISTORY_MANPOWERS_SENDING_FETCHING,
    });
    const res = await CandidateService.getHistoryManpowersSending();
    if (res) {
      dispatch({
        type: HISTORY_MANPOWERS_SENDING_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: HISTORY_MANPOWERS_SENDING_FAILED,
    });
  }
};

export const getAllCandidateStatus = () => async () => {
  try {
    const res = await CandidateService.getAllCandidateStatus();
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const updateInterviewInfo = (idCandidate, formData) => async () => {
  try{
    const res = await CandidateService.updateInterviewInfo(idCandidate, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const getManpowerForSelectToInterviewJob = (idJobRecruit) => async () => {
  try {
    const res = await CandidateService.getManpowerForSelectToInterviewJob(idJobRecruit);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const updateStatusCandidateByManager = (idJobRecruit, status, formData) => async () => {
  try{
    const res = await CandidateService.updateStatusCandidateByManager(idJobRecruit, status, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};