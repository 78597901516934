import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Container,
  IconButton,
  Typography,
  styled,
  Button,
  Slider,
  LinearProgress,
  Snackbar,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Link,
  Tooltip,
} from "@mui/material";
import CardStyle from "../../../../../shared/general/Card";
import { AccessTimeRounded } from "@mui/icons-material";
import TextFieldTheme from "../../../../../shared/general/TextFieldTheme";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {
  addIndividualKeyResultProgress,
  getAllIndividualKeyResultProgress,
  updateIndividualKeyResultProgress,
} from "../../../../../../../actions/okrs";
import { Controller, useForm } from "react-hook-form";
import Edit from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const PrettoSlider = styled(Slider)({
  color: "rgba(219, 65, 120, 1)",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(219, 65, 120, 0.16)",
    },
    "&:focus, &.Mui-active": {
      boxShadow: "0 0 0 14px rgba(219, 65, 120, 0.16)",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "rgba(219, 65, 120, 1)",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const ResponsiveButtonBlue = styled(Button)(({ theme }) => ({
  backgroundColor: "rgba(219, 65, 120, 1)",
  width: "10%",
  height: "50px",
  fontSize: "15px",
  fontWeight: "bold",
  borderRadius: "7px",
  "&:hover": {
    backgroundColor: "#C53B6C",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    fontSize: "14px",
    height: "40px",
  },
}));

const StyledImportFile = styled("div")({
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  marginTop: 24,
  "& .dropzone-upload-file": {
    textAlign: "center",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    height: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "& .inner-dropzone": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      "& img": {
        marginBottom: 16,
      },
    },
  },
});

const getSizeText = (size) => {
  const unit = ["KB", "MB", "GB"];
  let tempSize = size / 1024;
  let i = 0;
  while (tempSize > 1024 && i < 2) {
    tempSize = tempSize / 1024;
    i++;
  }

  return `${Math.round(tempSize * 100) / 100} ${unit[i]}`;
};

const UpdateProgressIndividualKeyResult = (props) => {
  const { match, handleClose } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [IndividualKeyResult, setIndividualKeyResult] = useState(null);
  const [progressPercent, setProgressPercent] = useState(0);

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      message: "",
    },
  });

  const [fileSelected, setFileSelected] = useState(null);
  const [formFileSelected, setFormFileSelected] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [confirmOpen, setConfirmOpen] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, application/pdf",
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        const formData = new FormData();
        formData.append("file", acceptedFiles[0]);
        setFormFileSelected(formData);
        setFileSelected(acceptedFiles[0]);
        console.log("Accepted file:", acceptedFiles[0]);
      }
      if (rejectedFiles.length > 0 && rejectedFiles[0].errors) {
        const error = rejectedFiles[0].errors[0];
        console.log("Rejected file:", rejectedFiles[0], "Error:", error);
        if (error.code === "file-invalid-type") {
          setError("file", {
            type: "manual",
            message: `${t("file_invalid_type")}`,
          });
        }
        if (error.code === "file-too-large") {
          setError("file", {
            type: "manual",
            message: `${t("file_too_large")}`,
          });
        }
        if (error.code === "too-many-files") {
          setError("file", {
            type: "manual",
            message: `${t("too_many_files")}`,
          });
        }
      }
    },
    multiple: false,
    maxSize: 5 * 1024 * 1024, // 5MB
  });

  const handleRemoveFile = () => {
    setFileSelected(null);
    setFormFileSelected(null);
    clearErrors("file");
  };

  const onSubmit = async (formData) => {
    setUploadProgress(0);
    setNotification({
      open: false,
      message: "",
      severity: "success",
    });

    const dataToSend = new FormData();
    dataToSend.append("message", formData.message);
    dataToSend.append("progressPercent", progressPercent);
    dataToSend.append("isUploadFile", fileSelected ? 1 : 0);

    if (formFileSelected) {
      for (let [key, value] of formFileSelected.entries()) {
        dataToSend.append(key, value);
      }
    }

    try {
      const response = await dispatch(
        addIndividualKeyResultProgress(
          match.params.idIndividualKeyResult,
          dataToSend
        )
      );

      console.log("Response from addIndividualKeyResultProgress:", response);

      if (response && response.status === 200) {
        if (response.data.errorCode === "ERROR_DUPLICATED") {
          setNotification({
            open: true,
            message: t("DuplicateData"),
            severity: "info",
          });
        } else {
          setNotification({
            open: true,
            message: t("UpdateSuccessful"),
            severity: "success",
          });

          setIndividualKeyResult((prev) => ({
            ...prev,
            individualKeyResultProgress: [
              {
                idIndividualKeyResultProgress:
                  response.data.idIndividualKeyResultProgress,
                message: response.data.message,
                createDate: response.data.createDate,
                progressPercent: response.data.progressPercent,
                fileUrl: response.data.fileUrl,
                filename: response.data.filename,
              },
              ...prev.individualKeyResultProgress,
            ],
            progressPercent:
              response.data.progressPercent || prev.progressPercent,
          }));

          reset();
          setProgressPercent(response.data.progressPercent || 0);
          handleRemoveFile();

          if (handleClose) {
            setConfirmOpen(true);
          }
        }
      } else {
        throw new Error("Failed to upload");
      }
    } catch (error) {
      console.error("Error during submission:", error);
      setNotification({
        open: true,
        message: `${t("AnErrorOccurred")} ${t("ContactAdmin")}`,
        severity: "error",
      });
    }
  };

  const handleUpdateKeyResultProgress = async (
    idIndividualKeyResultProgress,
    formData
  ) => {
    try {
      const responseUpdate = await dispatch(
        updateIndividualKeyResultProgress(
          idIndividualKeyResultProgress,
          formData
        )
      );
      if (responseUpdate && responseUpdate.status === 200) {
        const res = await dispatch(
          getAllIndividualKeyResultProgress(match.params.idIndividualKeyResult)
        );
        if (res.status === 200) {
          setIndividualKeyResult(res.data);
          setNotification({
            open: true,
            message: t("UpdateSuccessful"),
            severity: "success",
          });
        }
      }
    } catch (error) {
      console.error("Error during progress update:", error);
      setNotification({
        open: true,
        message: `${t("AnErrorOccurred")} ${t("ContactAdmin")}`,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    dispatch(
      getAllIndividualKeyResultProgress(match.params.idIndividualKeyResult)
    )
      .then((res) => {
        if (isMounted) {
          if (res.status === 200) {
            setIndividualKeyResult(res.data);
            setProgressPercent(res.data.progressPercent || 0);
          } else {
            setNotification({
              open: true,
              message: `${t("FailedToFetchData")}`,
              severity: "error",
            });
          }
        }
      })
      .catch((error) => {
        if (isMounted) {
          console.error("Error fetching IndividualKeyResult:", error);
          setNotification({
            open: true,
            message: `${t("AnErrorOccurred")} ${t("ContactAdmin")}`,
            severity: "error",
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch, match.params.idIndividualKeyResult, t]);

  const handleNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification((prev) => ({ ...prev, open: false }));
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    if (handleClose) {
      handleClose();
    }
  };

  const handleConfirmYes = () => {
    setConfirmOpen(false);
    if (handleClose) {
      handleClose();
    }
  };

  return (
    <Box className="page" padding="24px 0">
      <Container maxWidth="lg">
        {IndividualKeyResult ? (
          <Fragment>
            <CardStyle>
              <Box padding="24px">
                <Typography flexGrow="1" fontSize="18px" fontWeight="500">
                  {IndividualKeyResult.keyResultName}
                </Typography>
                <Box display="flex" alignItems="center" marginTop="8px">
                  <AccessTimeRounded
                    style={{ marginRight: "8px", fontSize: "16px" }}
                  />
                  <Typography fontSize="14px" lineHeight="1">
                    {dayjs(IndividualKeyResult.startDate).format("D MMM YYYY")}{" "}
                    - {dayjs(IndividualKeyResult.endDate).format("D MMM YYYY")}
                  </Typography>
                </Box>
                <Box marginTop="16px">
                  <Typography variant="body2" color="text.secondary">
                    {`${t("Progress")}: ${
                      IndividualKeyResult.progressPercent
                    }%`}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={IndividualKeyResult.progressPercent}
                    sx={{
                      height: 10,
                      borderRadius: 5,
                      backgroundColor: "rgba(219, 65, 120, 0.1)",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "rgba(219, 65, 120, 1)",
                      },
                    }}
                  />
                </Box>
              </Box>
            </CardStyle>

            <Box marginTop="24px">
              <CardStyle>
                <Box padding="24px">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                      name="message"
                      control={control}
                      render={({ field }) => (
                        <TextFieldTheme
                          {...field}
                          maxRows={5}
                          multiline
                          error={!!errors.message}
                          helperText={
                            errors.message ? errors.message.message : ""
                          }
                          inputProps={{
                            placeholder: `${t("WriteMessage")}`,
                          }}
                        />
                      )}
                    />
                    <Box marginTop="16px">
                      <Typography>
                        {t("ProgressPercentage")}: {progressPercent}%
                      </Typography>
                      <PrettoSlider
                        value={progressPercent}
                        onChange={(e, newValue) => setProgressPercent(newValue)}
                        valueLabelDisplay="auto"
                        min={0}
                        max={100}
                        step={1}
                        aria-labelledby="progress-slider"
                      />
                    </Box>
                    <StyledImportFile>
                      <div
                        {...getRootProps({ className: "dropzone-upload-file" })}
                      >
                        <input {...getInputProps()} />
                        <div className="inner-dropzone">
                          <AddCircleOutlineIcon
                            style={{ fontSize: 80, color: "#D0D0D0" }}
                          />
                          <Typography
                            style={{
                              marginTop: 8,
                              backgroundColor: "transparent",
                            }}
                            variant="body2"
                            color="text.secondary"
                          >
                            {t("DragAndDropOrClickToSelectFile")}
                          </Typography>
                        </div>
                      </div>
                    </StyledImportFile>
                    {errors.file && (
                      <Typography variant="body2" color="error" marginTop="8px">
                        {errors.file.message}
                      </Typography>
                    )}
                    {fileSelected && (
                      <Box display="flex" alignItems="center" marginTop={2}>
                        <AttachFileIcon
                          style={{ marginRight: "8px", fontSize: 40 }}
                        />
                        <Typography variant="body2" style={{ flexGrow: 1 }}>
                          {fileSelected.name} ({getSizeText(fileSelected.size)})
                        </Typography>
                        <IconButton onClick={handleRemoveFile}>
                          <ClearIcon />
                        </IconButton>
                      </Box>
                    )}
                    {uploadProgress > 0 && uploadProgress < 100 && (
                      <Box display="flex" alignItems="center" marginTop="16px">
                        <CircularProgress
                          variant="determinate"
                          value={uploadProgress}
                        />
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          marginLeft="8px"
                        >
                          {`${uploadProgress}%`}
                        </Typography>
                      </Box>
                    )}
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      marginTop="16px"
                    >
                      <ResponsiveButtonBlue
                        variant="contained"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <CircularProgress size="21px" color="inherit" />
                        ) : (
                          `${t("Update")}`
                        )}
                      </ResponsiveButtonBlue>
                    </Box>
                  </form>
                </Box>
              </CardStyle>
            </Box>

            <Box marginTop="24px">
              {IndividualKeyResult.individualKeyResultProgress.map(
                (progress, index) => (
                  <KeyResultProgress
                    key={index}
                    data={progress}
                    handleUpdateKeyResultProgress={
                      handleUpdateKeyResultProgress
                    }
                  />
                )
              )}
            </Box>

            <Snackbar
              open={notification.open}
              autoHideDuration={6000}
              onClose={handleNotificationClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleNotificationClose}
                severity={notification.severity}
                sx={{ width: "100%" }}
              >
                {notification.message}
              </Alert>
            </Snackbar>

            <Dialog
              open={confirmOpen}
              onClose={handleConfirmClose}
              aria-labelledby="confirm-dialog-title"
              aria-describedby="confirm-dialog-description"
            >
              <DialogTitle id="confirm-dialog-title">
                {t("Confirm")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="confirm-dialog-description">
                  {t("AreYouSureYouWantToClose")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleConfirmClose} color="primary">
                  {t("No")}
                </Button>
                <Button onClick={handleConfirmYes} color="primary" autoFocus>
                  {t("Yes")}
                </Button>
              </DialogActions>
            </Dialog>
          </Fragment>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <CircularProgress />
          </Box>
        )}
      </Container>
    </Box>
  );
};

const StyledKeyResultProgressRoot = styled(Box)({
  marginBottom: "16px",
  "&:last-child": {
    marginBottom: 0,
  },
});

const KeyResultProgress = (props) => {
  const { data, handleUpdateKeyResultProgress } = props;
  const [mode, setMode] = useState(false);
  const [edit, setEdit] = useState("");
  const { t } = useTranslation();

  const handleChangeMode = () => {
    setEdit(data.message);
    setMode((prev) => !prev);
  };

  const handleUpdate = () => {
    setMode(false);
    handleUpdateKeyResultProgress(data.idIndividualKeyResultProgress, {
      message: edit,
    });
  };

  return (
    <StyledKeyResultProgressRoot>
      <CardStyle>
        <Box padding="24px">
          {mode ? (
            <TextFieldTheme
              value={edit}
              onChange={(e) => setEdit(e.target.value)}
              fullWidth
            />
          ) : (
            <Typography whiteSpace="pre-line">{data.message}</Typography>
          )}
          {data.signedUrl && data.filename && (
            <Box marginTop={2} display="flex" alignItems="center">
              <AttachFileIcon
                style={{ marginRight: "8px", color: "#2196F3" }}
              />
              <Tooltip title={t("DownloadFile")}>
                <Link
                  href={data.signedUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="hover"
                  color="inherit"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {data.filename}
                </Link>
              </Tooltip>
            </Box>
          )}
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap={1}
            marginTop={2}
          >
            {mode ? (
              <>
                <IconButton onClick={handleUpdate} color="success">
                  <DoneIcon />
                </IconButton>
                <IconButton onClick={handleChangeMode} color="error">
                  <ClearIcon />
                </IconButton>
              </>
            ) : (
              <IconButton onClick={handleChangeMode} color="primary">
                <Edit />
              </IconButton>
            )}
            <Typography fontSize="12px" color="text.secondary">
              {dayjs(data.updateDate).format("D MMM YYYY HH:mm")}
            </Typography>
          </Box>
        </Box>
      </CardStyle>
    </StyledKeyResultProgressRoot>
  );
};

export default UpdateProgressIndividualKeyResult;
