import { httpClient } from "./httpClient";

const getAllOrgPlan = () => {
  return httpClient.get("orgPlan");
};

const getOrgPlanById = (id) => {
  return httpClient.get(`orgPlan/orgPlanById/${id}`);
};

const postOrgPlan = (data) => {
  return httpClient.post(`orgPlan/postOrgPlan`, data);
};

const putOrgPlan = (id, data) => {
  return httpClient.post(`orgPlan/putOrgPlan/${id}`, data);
};

const putEvaluationOrgPlan = (id, data) => {
  return httpClient.post(`orgPlan/putEvaluationOrgPlan/${id}`, data);
};

const deleteOrgPlan = (id, data) => {
  return httpClient.post(`orgPlan/deleteOrgPlan/${id}`, data);
};

export default {
  getAllOrgPlan,
  getOrgPlanById,
  postOrgPlan,
  putOrgPlan,
  putEvaluationOrgPlan,
  deleteOrgPlan,
};
