import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  CircularProgress,
  Alert,
  styled,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ButtonBlue from "../../shared/general/ButtonBlue";

import { addChatbotDocument } from "../../../../actions/webhook";

const UploadBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.grey[300]}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  textAlign: "center",
  cursor: "pointer",
  "&:hover": {
    borderColor: theme.palette.primary.main,
  },
}));

const HiddenInput = styled("input")({
  display: "none",
});

const DialogUpload = (props) => {
  const { open, handleClose } = props;
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [error, setError] = useState("");

  const allowedFileTypes = [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];
  const allowedFileExtensions = [".pdf", ".pptx"];

  useEffect(() => {
    setFile(null);
    setUploadStatus("");
    setError("");
  }, [handleClose]);

  const validateFile = (file) => {
    if (!file) return false;

    // Check file type
    if (!allowedFileTypes.includes(file.type)) {
      setError(
        `Invalid file type. Only ${allowedFileExtensions.join(
          ", "
        )} files are allowed.`
      );
      return false;
    }

    // Check file size (optional) - limit to 10MB
    const maxSize = 10 * 1024 * 1024; // 10MB in bytes
    if (file.size > maxSize) {
      setError("File size exceeds 10MB limit.");
      return false;
    }

    setError("");
    return true;
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && validateFile(selectedFile)) {
      setFile(selectedFile);
      setUploadStatus("");
    } else if (selectedFile) {
      setFile(null);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile && validateFile(droppedFile)) {
      setFile(droppedFile);
      setUploadStatus("");
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleUpload = async () => {
    if (!file) {
      setUploadStatus("error");
      setError("Please select a file before uploading.");
      return;
    }

    setUploadStatus("uploading");

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    const result = await addChatbotDocument(formData);

    if (result && result.status === 200) {
      setUploadStatus("success");
      setTimeout(() => {
        handleClose();
      }, 1500);
    } else {
      setUploadStatus("error");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Upload File</DialogTitle>
      <DialogContent>
        <Box sx={{ p: 2 }}>
          <HiddenInput
            type="file"
            id="file-input"
            onChange={handleFileChange}
            accept=".pdf,.pptx,application/pdf,application/vnd.openxmlformats-officedocument.presentationml.presentation"
          />
          <label htmlFor="file-input">
            <UploadBox onDrop={handleDrop} onDragOver={handleDragOver}>
              <CloudUploadIcon
                sx={{ fontSize: 48, color: "grey.500", mb: 1 }}
              />
              {/* <Box flexGrow={1}>
                <Typography color="textSecondary">
                  {file ? file.name : "Drag and drop or click to select file"}
                </Typography>
                <Typography variant="body3" color="textSecondary">
                  {file ? "" : "(.pdf or .pptx type only)"}
                </Typography>
              </Box> */}
              <Typography variant="body1" color="textSecondary">
                {file ? file.name : "Drag and drop or click to select file"}
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                sx={{ mt: 1 }}
              >
                {file ? "" : "Allowed file types: PDF, PPTX (Max size: 10MB)"}
              </Typography>
            </UploadBox>
          </label>

          {uploadStatus === "error" && (
            <Alert severity="error" sx={{ mt: 2 }}>
              File uploaded error. Please try again.
            </Alert>
          )}

          {uploadStatus === "uploading" && (
            <Box sx={{ mt: 2, display: "flex", alignItems: "center", gap: 2 }}>
              <CircularProgress size={24} />
              <Typography>Uploading file...</Typography>
            </Box>
          )}

          {uploadStatus === "success" && (
            <Alert severity="success" sx={{ mt: 2 }}>
              File uploaded successfully!
            </Alert>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Cancel
        </Button>
        <ButtonBlue
          onClick={handleUpload}
          variant="contained"
          disabled={uploadStatus === "uploading"}
        >
          Upload
        </ButtonBlue>
      </DialogActions>
    </Dialog>
  );
};

export default DialogUpload;
