import { httpClient } from "./httpClient";

const getPersonnelLevelByidCompany = (idCompany) => {
    return httpClient.get(`company/${idCompany}/personnel-level`);
  };

const getLevelByidCompany = (query) => {
    return httpClient.get(`/level`, { params: query });
};

const addLevel = (data) => {
    return httpClient.post(`/level`, data);
};

const addGroupLevel = (data) => {
    return httpClient.post(`/group-level`, data);
};

const deleteGroupLevel = (idGroupLevel) => {
    return httpClient.delete(`/group-level/${idGroupLevel}`);
};

const deleteLevel = (idLevel) => {
    return httpClient.delete(`/level/${idLevel}`);
};

const addNewJobLevel = (idCompany,data) => {
    return httpClient.post(`jobLevel/add/company/${idCompany}`,data);
};

const switchLevel = (data) => {
    return httpClient.put(`jobLevel/switch`,data);
};

const updateJobLevel = (idJobLevel, data) => {
    return httpClient.put(`jobLevel/update/${idJobLevel}`,data);
};

const deleteJobLevel = (idJobLevel) => {
    return httpClient.delete(`jobLevel/delete/${idJobLevel}`);
};

const getLevelAdditionsByidJobLevel = (idJobLevel) => {
    return httpClient.get(`levelAddition/jobLevel/${idJobLevel}`);
};

export default {
    getPersonnelLevelByidCompany,
    getLevelByidCompany,
    addNewJobLevel,
    switchLevel,
    updateJobLevel,
    deleteJobLevel,
    getLevelAdditionsByidJobLevel,
    addLevel,
    addGroupLevel,
    deleteGroupLevel,
    deleteLevel
};