import { useState, useRef } from "react";
// import { TextField, Select } from "formik-mui";
import { FieldArray, Formik, Form, Field } from "formik";
import React from "react";
import * as Yup from "yup";

import {
  Typography,
  Grid,
  Button,
  InputAdornment,
  Container,
  Paper,
  IconButton,
  TextField,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import SaveIcon from "@mui/icons-material/Save";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import AddIcon from "@mui/icons-material/Add";
import SummarizeIcon from "@mui/icons-material/Summarize";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import DeleteIcon from "@mui/icons-material/Delete";

import { Breadcrumb } from "../../shared/breadcrums";




const useStyles = makeStyles(() => ({
  root: {
    marginTop: "20px",
    minHeight: "calc(100vh - 20px)",
  },
  averageSalary: {
    "& .css-1t3tbea-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
      "-webkit-text-fill-color": "black",
    },
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  nameJobLevel: {
    fontSize: 22,
    fontWeight: "bold",
    margin: 10,
  },
}));

const AddJobLevel = () => {
  //use style in name of classes
  const classes = useStyles();
  const formRef = useRef();

  const validationSchema = Yup.object().shape({
    jobLevel: Yup.array(
      Yup.object({
        name: Yup.string().required("Please enter demand name"),
        minSalary: Yup.number()
          .positive("Please enter min salary in Positive")
          .required("Please enter min salary"),
        maxSalary: Yup.number()
          .positive("Please enter max salary in Positive")
          .required("Please enter max salary"),
        marketPublic: Yup.number()
          .positive("Please enter market public salary in Positive")
          .required("Please enter market public salary"),
      })
    ),
  });

  //data in array
  const [data, setData] = useState({
    jobLevel: [
      {
        name: "",
        minSalary: 0,
        maxSalary: 0,
        averageSalary: 0,
        marketPublic: 0,
      },
    ],
  });

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        <Breadcrumb
          textLabel={"Add new job Level"}
          icon={<SummarizeIcon className={classes.sendIcon} />}
        />
        <br />
        <h1 style={{ marginTop: 20 }}>Add new job level</h1>
        <br />

        <Formik
          initialValues={data}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            console.log("saved value", values);
            setData(values);
          }}
          onChange={(values) => {
            console.log("test");
          }}
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form autoComplete="off">
              <FieldArray name="jobLevel">
                {({ push, remove }) =>
                  values.jobLevel.map((_, index) => (
                    <Paper
                      key={index}
                      style={{ marginBottom: 30, padding: 20 }}
                    >
                      <Grid container>
                        <Grid item xl={4} lg={4} md={6} xs={8}>
                          <Typography className={classes.nameJobLevel}>
                            The {index + 1} Level
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xl={8}
                          lg={8}
                          md={6}
                          xs={4}
                          style={{ textAlign: "end" }}
                        >
                          <IconButton
                            aria-label="add-demand"
                            style={{
                              marginRight: 5,
                              backgroundColor: "#7dc581",
                              color: "#357a38",
                            }}
                          >
                            <AddIcon
                              onClick={() => {
                                push({
                                  name: "",
                                  minSalary: 0,
                                  maxSalary: 0,
                                  averageSalary: 0,
                                  marketPublic: 0,
                                });
                              }}
                            />
                          </IconButton>
                          {values.jobLevel.length === 1 ? (
                            <IconButton
                              aria-label="delete"
                              style={{ marginLeft: 5 }}
                              disabled
                              color="primary"
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              aria-label="delete"
                              style={{
                                marginLeft: 5,
                                backgroundColor: "#dd99b1",
                                color: "#ab003c",
                              }}
                            >
                              <DeleteIcon onClick={() => remove(index)} />
                            </IconButton>
                          )}
                        </Grid>

                        <Grid container>
                          <Grid
                            item
                            xl={6}
                            md={6}
                            xs={12}
                            style={{ paddingRight: 16 }}
                          >
                            <Field
                            style={{ marginBottom: 16 }}
                              component={TextField}
                              id="joblevelName"
                              name={`jobLevel[${index}].name`}
                              label="Job Level Name"
                              variant="outlined"
                              required
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LocalOfferOutlinedIcon />
                                  </InputAdornment>
                                ),
                              }}
                              placeholder="Job Level Name"
                              fullWidth
                            />
                          </Grid>

                          <Grid
                            item
                            xl={3}
                            md={3}
                            xs={6}
                            style={{ paddingRight: 16 }}
                          >
                            <Field
                              component={TextField}
                              id="minSalary"
                              name={`jobLevel[${index}].minSalary`}
                              label="Min Salary"
                              variant="outlined"
                              required
                              type="number"
                              // onChange={(e) => handleChange(e,index)}
                              InputProps={{
                                onChange: (e) => {
                                  handleChange(e);
                                  setFieldValue(
                                    `jobLevel[${index}].averageSalary`,
                                    (parseFloat(e.target.value) +
                                      values.jobLevel[index].maxSalary) /
                                      2
                                  );
                                },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MonetizationOnIcon />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    baht
                                  </InputAdornment>
                                ),
                              }}
                              placeholder="Min Salary"
                              fullWidth
                            />
                            {/* {console.log(values.jobLevel[index]['maxSalary'])} */}
                          </Grid>

                          <Grid
                            item
                            xl={3}
                            md={3}
                            xs={6}
                            style={{ paddingRight: 16 }}
                          >
                            <Field
                              component={TextField}
                              id="maxSalary"
                              name={`jobLevel[${index}].maxSalary`}
                              label="Max Salary"
                              variant="outlined"
                              required
                              // onChange={(e,index) => setFieldValue(`jobLevel[${index}].averageSalary`, (parseInt(e.target.value) + values.jobLevel[index]['minSalary']/2))}
                              type="number"
                              InputProps={{
                                // onChange: (e) => handleChange(e,index),
                                onChange: (e) => {
                                  handleChange(e);
                                  setFieldValue(
                                    `jobLevel[${index}].averageSalary`,
                                    (parseFloat(e.target.value) +
                                      values.jobLevel[index].minSalary) /
                                      2
                                  );
                                },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MonetizationOnIcon />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    baht
                                  </InputAdornment>
                                ),
                              }}
                              placeholder="Max Salary"
                              fullWidth
                            />
                          </Grid>

                          <Grid
                            item
                            xl={6}
                            md={6}
                            xs={6}
                            style={{ paddingRight: 16 }}
                          >
                            <Field
                              component={TextField}
                              id="marketPublic"
                              name={`jobLevel[${index}].marketPublic`}
                              label="Market Public Salary"
                              variant="outlined"
                              required
                              type="number"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MonetizationOnIcon />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    baht
                                  </InputAdornment>
                                ),
                              }}
                              placeholder="Market Public Salary"
                              fullWidth
                            />
                          </Grid>

                          <Grid
                            item
                            xl={6}
                            md={6}
                            xs={6}
                            style={{ paddingRight: 16 }}
                          >
                            <Field
                              component={TextField}
                              id="averageSalary"
                              name={`jobLevel[${index}].averageSalary`}
                              label="Average Salary"
                              variant="outlined"
                              disabled
                              type="number"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MonetizationOnIcon />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    baht
                                  </InputAdornment>
                                ),
                              }}
                              className={classes.averageSalary}
                              placeholder="Average Salary"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  ))
                }
              </FieldArray>
              <Grid container justifyContent="end">
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  style={{
                    marginRight: 15,
                    backgroundColor: "green",
                    color: "white",
                    fontSize: 16,
                    marginBottom: 10,
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default AddJobLevel;
