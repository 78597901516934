import ExcelJS from "exceljs";

const exportToExcel = (props) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("OKRs");

  const headerCellStyle = {
    font: {
      name: "Tahoma",
      size: 14,
      color: { argb: "FFFFFF" },
    },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "002060" },
    },
  };

  const dataCellStyle = {
    font: {
      name: "Tahoma",
      size: 14,
    },
    alignment: {
      vertical: "middle",
      horizontal: "center",
    },
  };

  sheet.columns = [
    { header: "Objective ID", key: "id", width: 15 },
    { header: "Quarter", key: "quarter", width: 15 },
    { header: "Key Result", key: "keyResult", width: 30 },
    { header: "Start Date", key: "startDate", width: 20 },
    { header: "End Date", key: "endDate", width: 20 },
    { header: "Status", key: "status", width: 15 },
    { header: "Description", key: "description", width: 60 },
    { header: "Strategy", key: "strategy", width: 40 },
    { header: "Measurement Evidence", key: "measureEvidence", width: 40 },
    {
      header: "Commit Quality",
      key: "commitQuality",
      width: 40,
    },
    { header: "Measurement Data Type", key: "measureDataType", width: 30 },
  ];

  const headerRow = sheet.getRow(1);
  headerRow.height = 100;
  headerRow.eachCell((cell) => {
    cell.style = headerCellStyle;
  });

  props.forEach((objective) => {
    objective.keyResults.forEach((kr) => {
      const row = sheet.addRow({
        id: objective.idOrganizationObjective,
        quarter: `Q${objective.quarter} ${objective.year}`,
        keyResult: kr.keyResultName,
        startDate: new Date(kr.startDate).toLocaleDateString(),
        endDate: new Date(kr.endDate).toLocaleDateString(),
        status: kr.isAchieve ? "Achieved" : "Pending",
        description: kr.description,
        strategy: kr.tacticName,
        measureEvidence: kr.measureEvidence || "",
        commitQuality: kr.commitQuality || "",
        measureDataType: kr.measureDataType === 1 ? "Number" : "Explanation",
      });
      row.height = 50;
      row.eachCell((cell) => {
        cell.style = dataCellStyle;
      });

      //   const statusCell = row.getCell("status");
      //   if (kr.isAchieve) {
      //     statusCell.fill = {
      //       type: "pattern",
      //       pattern: "solid",
      //       fgColor: { argb: "FF00FF00" },
      //     };
      //   } else {
      //     statusCell.fill = {
      //       type: "pattern",
      //       pattern: "solid",
      //       fgColor: { argb: "FFFF0000" },
      //     };
      //   }
    });
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `OKRs.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  });
};

export default exportToExcel;
