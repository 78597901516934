import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Grid,
    MenuItem,
    Container,
    Typography,
    styled,
    Breadcrumbs,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import CardData from "../../../shared/dashboard/CardData";
import CardStyle from "../../../shared/general/Card";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { Link } from "react-router-dom";
import { getDashboardEmployee } from "../../../../../actions/report";
import DashboardGraph from "./dashboardGraph";
import DatePickerCustom from "../../../shared/date/datePicker";
import SelectCompany from "../../shared/selectCompany";
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import GroupsIcon from '@mui/icons-material/Groups';
import { DashboardXlsx } from "./DashboardXlsx";
import { getAffiliateOrganization } from "../../../../../actions/company";
import SelectAffiliateCompany from "../../../shared/general/SelectAffiliateCompany";

const StyledRoot = styled("div")({
    backgroundColor: "#FFFFFF !important",
    paddingBottom: 48,
});

const StyledBoxSearch = styled(Box)({
    marginTop: 22,
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});

const StyledWrapFilter = styled("div")({
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: 36,
    ["@media (min-width: 0px)"]: {
        flexDirection: "column",
    },
    ["@media (min-width: 900px)"]: {
        flexDirection: "row",
    },
    "& .search-name, .search-date": {
        width: "100%",
    },
    "& .wrap-search-action": {
        marginTop: 50,
        display: "flex",
        justifyContent: "flex-start",
        "& .btn-export": {
            marginLeft: 8,
        },
    },
});

const DashboardEmployee = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: dashboardEmployee } = useSelector((state) => state.dashboardEmployee);
    const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

    const [inputSearch, setInputSearch] = useState(new Date());
    const [selectedCompany, setSelectedCompany] = useState(null);

    useEffect(() => {
        if (userProfile && inputSearch) {
            dispatch(getDashboardEmployee(
                {
                    idCompany: selectedCompany && selectedCompany.idCompany,
                    inputSearch: dayjs(inputSearch).locale('th').format("YYYY-MM")
                },
            ));
        }
    }, [userProfile, inputSearch, selectedCompany]);

    useEffect(() => {
        if (userProfile) {
            dispatch(getAffiliateOrganization());
        }
    }, [userProfile]);

    useEffect(() => {
        if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0 && userProfile.roles.includes("ROLE_ADMIN")) {
            let ownCompany = affiliateOrganizationList.find(x => x.idCompany === userProfile.idCompany);
            if (!ownCompany) {
                ownCompany = affiliateOrganizationList[0];
            }
            setSelectedCompany(ownCompany)
        }
    }, [userProfile, affiliateOrganizationList]);

    const handleXlsxExport = () => {
        if (dashboardEmployee) {
            DashboardXlsx(dashboardEmployee);
        } else {
            return;
        }
    }

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    };

    const inputMonth = dayjs(inputSearch).format("YYYY-MM");
    const dataValue = dashboardEmployee && dashboardEmployee.graph && dashboardEmployee.graph
        .filter(e => e.month === inputMonth)
        .map(e => {
            const monthData = e.totalEmployees[3];

            return monthData;
        });

    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={"/employees-report"}
                    >
                        {`${t("AllReports")}`}
                    </Link>
                    <Typography color="text.primary">{`${t("SystemService")}`}</Typography>
                </Breadcrumbs>
                <div style={{ marginBottom: 8 }}>
                    <Typography variant="h4" style={{ paddingTop: 8 }}>
                        {`${t("SystemService")}`}
                    </Typography>
                </div>

                <StyledWrapFilter>
                    <Grid container spacing={2} columns={14} alignItems="center">
                        <Grid item xs={3}>
                            <StyledBoxSearch>
                                <DatePickerCustom
                                    maxDate={dayjs()}
                                    inputFormat="MMMM YYYY"
                                    value={inputSearch}
                                    name="start"
                                    views={['year', 'month']}
                                    openTo={'month'}
                                    onChange={(newValue) => {
                                        setInputSearch(newValue);
                                    }}
                                />
                            </StyledBoxSearch>
                        </Grid>

                        <Grid item xs={3}>
                            <StyledBoxSearch>
                                <SelectAffiliateCompany
                                    options={affiliateOrganizationList}
                                    value={selectedCompany}
                                    // disabled={isFetching}
                                    onChange={(_, value) => {
                                        onChangeCompany(value);
                                    }}
                                />
                            </StyledBoxSearch>
                        </Grid>

                        <Grid item xs={3}>
                            <StyledBoxSearch>
                                <ButtonBlue
                                    variant="contained"
                                    startIcon={<DownloadRoundedIcon />}
                                    onClick={() => handleXlsxExport()}
                                    style={{ marginRight: '12px' }}
                                >
                                    {t("Download")}
                                </ButtonBlue>
                            </StyledBoxSearch>
                        </Grid>
                    </Grid>
                </StyledWrapFilter>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={3} md={3}>
                            <CardData
                                title={"User"}
                                dataValue={
                                    dashboardEmployee && dashboardEmployee.data
                                        ? dashboardEmployee.data.filter(employee => [1, 2].includes(employee.idRole)).length
                                        : 0
                                }
                                dataUnit={t("Person")}
                                titleIcon={
                                    <EmojiPeopleIcon baseClassName="fal" className="fa-users" style={{ display: "inline-table", color: "#2196F3" }} />
                                }
                                dynamicFontSize
                            />
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <CardData
                                title={`${t("Manager")}`}
                                dataValue={
                                    dashboardEmployee && dashboardEmployee.data
                                        ? dashboardEmployee.data.filter(employee => employee.idRole === 3).length
                                        : 0
                                }
                                dataUnit={t("Person")}
                                titleIcon={
                                    <EmojiPeopleIcon baseClassName="fal" className="fa-users" style={{ display: "inline-table", color: "#4CAF50" }} />
                                }
                                dynamicFontSize
                            />
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <CardData
                                title={`${t("ResignedEmp")}`}
                                // dataValue={
                                //     dashboardEmployee && dashboardEmployee.data
                                //         ? dashboardEmployee.data.filter(employee => employee.idRole === 4 && (!employee.isFillIn || (employee.isFillIn && employee.isFillIn === 0))).length
                                //         : 0
                                // }
                                dataValue={dataValue ? dataValue[0] : 0}
                                dataUnit={t("Person")}
                                titleIcon={
                                    <EmojiPeopleIcon baseClassName="fal" className="fa-users" style={{ display: "inline-table", color: "#FF5722" }} />
                                }
                                dynamicFontSize
                            />
                        </Grid>
                        {/* <Grid item xs={3} md={3}>
                            <CardData
                                title={"Admin Fill-in"}
                                dataValue={
                                    dashboardEmployee && dashboardEmployee.data
                                        ? dashboardEmployee.data.filter(employee => employee.idRole === 4 && (employee.isFillIn && employee.isFillIn === 1)).length
                                        : 0
                                }
                                dataUnit=${{t("Person")}}
                                titleIcon={
                                    <EmojiPeopleIcon baseClassName="fal" className="fa-users" style={{ display: "inline-table", color: "#FF5722" }} />
                                }
                                dynamicFontSize
                            />
                        </Grid> */}
                        <Grid item xs={3} md={3}>
                            <CardData
                                title={`${t("Total")}`}
                                dataValue={
                                    dashboardEmployee && dashboardEmployee.graph
                                        ? dashboardEmployee.data.length
                                        : 0
                                }
                                dataUnit={t("Person")}
                                titleIcon={
                                    <GroupsIcon baseClassName="fal" className="fa-users" color="primary" style={{ display: "inline-table" }} />
                                }
                                dynamicFontSize
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Box marginTop="24px" marginBottom="32px">
                    <CardStyle>
                        <Box padding="20px" >
                            <DashboardGraph data={dashboardEmployee} />
                        </Box>
                    </CardStyle>
                </Box>
            </Container >
        </StyledRoot >
    )
}

export default DashboardEmployee