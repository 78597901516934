import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";

import { Grid, Typography, Box, TextField, Container } from "@mui/material";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

import { getSummaryTimeByIdEmp } from "../../../../actions/summary-time";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DatePickerCustom from "../../shared/date/datePicker";

import TableList from "./table";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 16,
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginTop: 16,
    marginBottom: 8,
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
  },
});

const SummaryTimePage = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const today = dayjs().toDate();
  const { result: summaryTimeIndividual } = useSelector(
    (state) => state.summaryTime
  );
  const [search, setSearch] = useState({
    start: dayjs(today).set("date", 1).format("YYYY-MM-DD"),
    end: dayjs(today)
      .set("date", dayjs(today).daysInMonth())
      .format("YYYY-MM-DD"),
  });

  useEffect(() => {
    dispatch(getSummaryTimeByIdEmp({
      start: dayjs(search.start).format("YYYY-MM-DD"),
      end: dayjs(search.end).format("YYYY-MM-DD"),
    }));
  }, []);

  const handleClickSearch = () => {
    dispatch(getSummaryTimeByIdEmp({
      start: dayjs(search.start).format("YYYY-MM-DD"),
      end: dayjs(search.end).format("YYYY-MM-DD"),
    }));
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          {t("SummaryOTandShift")}
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("StartDate")}
              </Typography>
              <div className="search-date">
              <DatePickerCustom
                    minDate={dayjs().subtract(4, "year")}
                    maxDate={dayjs()}
                    inputFormat="D MMM YYYY"
                    value={search.start}
                    name="start"
                    onChange={(newValue) => {
                      setSearch({ ...search, ["start"]: newValue });
                    }}
                  />
               
              </div>
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={3}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("EndDate")}
              </Typography>
              <div className="search-date">
              <DatePickerCustom
                    minDate={dayjs(search.start)}
                    maxDate={dayjs()}
                    inputFormat="D MMM YYYY"
                    value={search.end}
                    name="end"
                    onChange={(newValue) => {
                      setSearch({ ...search, ["end"]: newValue });
                    }}
                  />
               
              </div>
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={3}>
            <StyledBoxSearch>
              <div className="wrap-search-action">
                <ButtonBlue
                  startIcon={<SearchRoundedIcon />}
                  variant="contained"
                  onClick={handleClickSearch}
                >
                  {t("Search")}
                </ButtonBlue>
              </div>
            </StyledBoxSearch>
          </Grid>
        </Grid>
        <TableList />
      </Container>
    </StyledRoot>
  );
};

export default SummaryTimePage;
