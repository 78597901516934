import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  Grid,
  Toolbar,
  Typography,
  InputAdornment,
  TextField,
  Avatar,
  LinearProgress,
  Button,
  IconButton,
  Container,
} from "@mui/material";
import { styled } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";

import { useSelector, useDispatch } from "react-redux";
import { getAllEmployees } from "../../../../../actions/employee";
import { getAllIdvPlan } from "../../../../../actions/IndividualPlans";

import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShowMiniData from "../../../shared/pages/okrs/components/ShowMiniData";

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiGrid-container": {
      marginBottom: 0,
    },
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
    "& .MuiTypography-displayBlock": {
      width: "100%",
    },
  },
  imageEmp: {
    width: 160,
    height: 160,
    borderRadius: "50%",
  },
  viewBtn: {
    backgroundColor: "#4f65df",
    color: "#fff",
    borderRadius: "50px",
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#4f65df",
    },
  },
  quater: {
    backgroundColor: "#e53935",
    fontWeight: "600",
    width: 48,
    height: 48,
  },
  PlanList_Object: {
    fontSize: "1.3rem",
    fontWeight: "600",
    color: "#222f3e",
  },
  PlanList_Impact: {
    color: "#9e9e9e",
    marginTop: 8,
  },
  PlanList_Status: {
    color: "#9e9e9e",
  },
  PlanList_dateCreate: {
    color: "#9e9e9e",
  },
  employeeInfo: {
    
  },
  noData_Section: {
    display: "flex",
    alignItems: "center",
  },
  noDataSvg: {
    fontSize: "30px",
    marginRight: 8,
  },
  inProgress: {
    backgroundColor: "#eeeeee",
    color: "#9e9e9e",
    width: "100px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    marginLeft: 8,
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  achieved: {
    backgroundColor: "#e0f2f1",
    color: "#009688",
    width: "90px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    marginLeft: 8,
    "&:hover": {
      backgroundColor: "#e0f2f1",
    },
  },
  notAchieved: {
    backgroundColor: "#f9dde0",
    color: "#c62828",
    width: "110px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    marginLeft: 8,
    "&:hover": {
      backgroundColor: "#f9dde0",
    },
  },
  svgIcon_MoonShot_Active: {
    fontSize: "2rem",
    color: "#ff9933",
    marginRight: 8,
  },
  svgIcon_MoonShot_notActive: {
    fontSize: "2rem",
    color: "#bdbdbd",
    marginRight: 8,
  },
}));

function IdvPlanListApproved() {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const { result: empStore } = useSelector((state) => state.employees);
  const { result: idvPlanStore } = useSelector((state) => state.IdvPlans);

  const [planList, setPlanList] = useState([]);
  const [planItems, setPlanItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchKey, setSearchKey] = useState("");

  const fetchData = () => {
    dispatch(getAllEmployees());
    dispatch(getAllIdvPlan());
  };

  useEffect(() => {
    if (empStore && idvPlanStore) {
      let employees = [];
      let name = location.state.employee.fullName;

      employees = empStore;

      let idvPlans = idvPlanStore;
      idvPlans.forEach((element) => {
        let eleCreateBy = `${element.firstName} ${element.lastName}`;

        let empData = employees.filter(function (item) {
          let itemFullName = `${item.firstName} ${item.lastName}`;

          return itemFullName.includes(eleCreateBy);
        });

        element.department = empData[0].department;
      });

      idvPlans.filter(function (item) {
        let itemCreateBy = `${item.firstName} ${item.lastName}`;

        return (
          itemCreateBy.includes(name) && item.planStatus.includes("Approved")
        );
      });

      setPlanList(idvPlans);
      setPlanItems(idvPlans);
      setIsLoading(false);
    }
  }, [empStore, idvPlanStore]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let x = [...planItems];
    x = x.filter((y) => {
      return y.objectiveName
        .toLowerCase()
        .includes(searchKey.toLocaleLowerCase());
    });
    setPlanList(x);
  }, [searchKey]);

  return (
    <ContainerStyled>
      <div className={classes.root}>
        <div className="card-section">
          <div className="header-section">
            <Grid container>
              <Grid item>
                <h3 className="header-topic">Individual Planning List</h3>
              </Grid>
            </Grid>
          </div>
          <Toolbar>
            <Grid container justifyContent="space-between">
              <Grid
                container
                item
                xs={12}
                md={2}
                lg={2}
                justifyContent="center"
              >
                {location.state.employee.image == null ||
                location.state.employee.image == "string" ? (
                  <AccountCircleIcon
                    sx={{ color: "#e0e0e0" }}
                    className={classes.imageEmp}
                  />
                ) : (
                  <img
                    src={`/images/employees/${location.state.employee.image}`}
                    className={classes.imageEmp}
                  />
                )}
              </Grid>
              <Grid container direction="row" item xs={12} md={5} lg={5}>
                <Grid container>
                  <Grid item xl={12} sm={12}>
                    <Typography
                      variant="h6"
                      display="block"
                      className={classes.employeeInfo}
                    >
                      {location.state.employee.fullName}
                    </Typography>
                  </Grid>
                  <Grid item xl={12} sm={12}>
                    <Typography
                      variant="subtitle1"
                      display="block"
                      className={classes.employeeInfo}
                    >
                      Position : {location.state.employee.position}
                    </Typography>
                  </Grid>
                  <Grid item xl={12} sm={12}>
                    <Typography
                      variant="subtitle1"
                      display="block"
                      className={classes.employeeInfo}
                    >
                      Department : {location.state.employee.department}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={4} lg={4} alignItems="flex-end">
                <TextField
                  variant="outlined"
                  style={{ width: "100%" }}
                  label="Search Objective"
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Toolbar>
        </div>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <div>
            {/* planList */}
            {(planList.length > 0 &&
              planList.map((item) => (
                <div key={item.planIdvId} className="cardPlan">
                  <Grid
                    container
                    alignItems="flex-start"
                    alignContent="space-between"
                  >
                    <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
                      <Avatar className={classes.quater}>{item.quater}</Avatar>
                    </Grid>
                    <Grid item xs={10} sm={7} md={7} lg={8} xl={8}>
                      <Typography className={classes.PlanList_Object}>
                        {item.objectiveName}
                      </Typography>
                      <Typography className={classes.PlanList_Impact}>
                        {item.impactName}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={2}
                      md={2}
                      lg={2}
                      xl={2}
                      style={{ textAlign: "center" }}
                    >
                      {item.moonShotKR1 != 0 ? (
                        <i
                          className={classes.svgIcon_MoonShot_Active}
                          class="fa-solid fa-space-station-moon"
                        ></i>
                      ) : (
                        <i
                          className={classes.svgIcon_MoonShot_notActive}
                          class="fa-solid fa-space-station-moon"
                        ></i>
                      )}
                      {item.moonShotKR2 != 0 ? (
                        <i
                          className={classes.svgIcon_MoonShot_Active}
                          class="fa-solid fa-space-station-moon"
                        ></i>
                      ) : (
                        <i
                          className={classes.svgIcon_MoonShot_notActive}
                          class="fa-solid fa-space-station-moon"
                        ></i>
                      )}
                      {item.moonShotKR3 != 0 ? (
                        <i
                          className={classes.svgIcon_MoonShot_Active}
                          class="fa-solid fa-space-station-moon"
                        ></i>
                      ) : (
                        <i
                          className={classes.svgIcon_MoonShot_notActive}
                          class="fa-solid fa-space-station-moon"
                        ></i>
                      )}
                    </Grid>
                    <Grid
                      container
                      item
                      xs={6}
                      sm={2}
                      md={2}
                      lg={1}
                      xl={1}
                      justifyContent="flex-end"
                    >
                      <Button
                        variant="outlined"
                        className={classes.viewBtn}
                        onClick={() => {
                          history.push({
                            pathname: "/individual",
                            state: { planList: item },
                            isInfo: true,
                          });
                        }}
                      >
                        <SearchIcon /> View
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: 4 }}>
                    <Grid item xs={2} sm={1} md={1} lg={1} xl={1}></Grid>
                    <Grid item xs={10} sm={11} md={8} lg={8} xl={8}>
                      <ShowMiniData
                        date={item.dateCreate}
                        status={item.planStatus}
                        progress={item.achieveResult}
                      />
                    </Grid>
                  </Grid>
                </div>
              ))) || (
              <div className="cardPlan">
                <Grid container justifyContent="center">
                  <Grid item>
                    <Typography variant="h6" className={classes.noData_Section}>
                      <FindInPageIcon className={classes.noDataSvg} />
                      No Data
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
        )}
      </div>
    </ContainerStyled>
  );
}

export default IdvPlanListApproved;
