import React from "react";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import SchoolIcon from '@mui/icons-material/School';
import dayjs from "dayjs";

const Root = styled("div")(() => ({
  marginTop: 16,
  marginBottom: 32,
  "& .textBold": {
    color: "#58647A !important",
    fontWeight: 600,
  },
  "& .oppositeContent": {
    flex: 0,
  },
  "& .eduDot": {
    color: "#007afe",
    backgroundColor: "#fff",
  },
  "& .eduSvg": {
    fontSize: "1.8rem",
  },
  "& .eduInfo_container": {
    backgroundColor: "#F5F7FA",
    padding: 16,
    borderRadius: "10px",
  },
}));

const EducationTimelines = (props) => {
  const { employee } = props;

  return (
    <Root>
       <Timeline>
        {employee.educations && employee.educations.length > 0 &&
          employee.educations.map((educations) => (
            <TimelineItem>
              <TimelineOppositeContent
                className="oppositeContent"
                color="textSecondary"
              ></TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot className="eduDot">
                  <SchoolIcon className="eduSvg" />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Grid container>
                  <Grid item xs={12} md={10} lg={10}>
                    <div className="eduInfo_container">
                      <Typography
                        variant="body1"
                        className="textBold"
                        display="inline"
                        gutterBottom
                      >
                        {dayjs(
                          educations.fromYear ? educations.fromYear : "-"
                        ).format("YYYY - ")}
                      </Typography>
                      <Typography
                        variant="body1"
                        className="textBold"
                        display="inline"
                        gutterBottom
                      >
                        {dayjs(
                          educations.endYear ? educations.endYear : "-"
                        ).format("YYYY")}
                      </Typography>
                      <Typography variant="body1">
                        {educations.degree ? educations.degree : ""}
                      </Typography>
                      <Typography variant="body1">
                        {educations.university
                          ? educations.university
                          : "-"}
                      </Typography>
                      <Typography variant="body1">
                        {educations.faculty ? educations.faculty : "-"}
                      </Typography>
                      <Typography variant="body1">
                        {educations.major
                          ? educations.major
                          : "-"}
                      </Typography>
                      <Typography variant="body1">{`เกรดเฉลี่ย : ${educations.degree?educations.degree:"-"}`}</Typography>
                    </div>
                  </Grid>
                </Grid>
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline> 
    </Root>
  );
};

export default EducationTimelines;
