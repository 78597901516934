import React from "react";
import {
  Modal,
  Box,
  Typography,
  List,
  ListItem,
  Grid,
  Link,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSelector } from "react-redux";

dayjs.extend(relativeTime);

const KRUpdateHistoryModal = ({ open, onClose, updates = [] }) => {
  const { t } = useTranslation();

  const timeline = Array.isArray(updates)
    ? updates
    : updates.teamKeyResultProgress ||
      updates.organizationKeyResultProgress ||
      updates.individualKeyResultProgress ||
      [];

  console.log("UPDATE", updates);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflowY: "auto",
          maxHeight: "90vh",
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          {t("UpdateHistory")}
        </Typography>
        <List>
          {Array.isArray(timeline) && timeline.length > 0 ? (
            [...timeline]
              .sort((a, b) =>
                dayjs(b.updateDate || b.lastUpdateDate).diff(
                  dayjs(a.updateDate || a.lastUpdateDate)
                )
              )
              .map((item, index, arr) => {
                const updateTime = dayjs(
                  item.updateDate || item.lastUpdateDate
                );
                const timeAgo = updateTime.fromNow();
                const updateNumber = arr.length - index;

                return (
                  <ListItem key={index} divider sx={{ mb: 2 }}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          {`${t("Update")} ${updateNumber}: ${
                            item.progressPercent
                          }%`}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {t("Updated")} {timeAgo} (
                          {updateTime.format("DD/MM/YYYY HH:mm")})
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary">
                          {`${t("Message")}: ${
                            item.message || t("NoMessageProvided")
                          }`}
                        </Typography>
                      </Grid>
                      {item.isUploadFile === 1 && item.filename && (
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            <Link href={item.signedUrl} target="_blank">
                              {item.filename}
                            </Link>
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                );
              })
          ) : (
            <Typography>{t("NoUpdatesAvailable")}</Typography>
          )}
        </List>
      </Box>
    </Modal>
  );
};

export default KRUpdateHistoryModal;
