import React from 'react';
import { Card, CardContent, Typography, Grid, Box, styled } from '@mui/material';
import OvertimeSummaryTable from './OvertimeSummaryTable';
import { WorkOutlineOutlined } from '@mui/icons-material';

const HeaderBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  padding: '16px 0 32px 0',
}));

const InnerHeaderBox = styled(Box)(() => ({
  borderBottom: '2px solid black',
  padding: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

function OT({ t, summaryTime }) {
  const totalOvertime = summaryTime && summaryTime.dataTable && summaryTime.dataTable.length > 0
    ? summaryTime.dataTable.reduce((totals, item) => {
      return {
        otOneHours: parseFloat((parseFloat(totals.otOneHours) + parseFloat(item.otOneHours || 0)).toFixed(2)),
        otOneAmount: parseFloat((parseFloat(totals.otOneAmount) + parseFloat(item.otOneAmount || 0)).toFixed(2)),
        otOneFiveHours: parseFloat((parseFloat(totals.otOneFiveHours) + parseFloat(item.otOneFiveHours || 0)).toFixed(2)),
        otOneFiveAmount: parseFloat((parseFloat(totals.otOneFiveAmount) + parseFloat(item.otOneFiveAmount || 0)).toFixed(2)),
        otTwoHours: parseFloat((parseFloat(totals.otTwoHours) + parseFloat(item.otTwoHours || 0)).toFixed(2)),
        otTwoAmount: parseFloat((parseFloat(totals.otTwoAmount) + parseFloat(item.otTwoAmount || 0)).toFixed(2)),
        otThreeHours: parseFloat((parseFloat(totals.otThreeHours) + parseFloat(item.otThreeHours || 0)).toFixed(2)),
        otThreeAmount: parseFloat((parseFloat(totals.otThreeAmount) + parseFloat(item.otThreeAmount || 0)).toFixed(2)),
      };
    }, {
      otOneHours: 0,
      otOneAmount: 0,
      otOneFiveHours: 0,
      otOneFiveAmount: 0,
      otTwoHours: 0,
      otTwoAmount: 0,
      otThreeHours: 0,
      otThreeAmount: 0,
    })
    : {
      otOneHours: 0,
      otOneAmount: 0,
      otOneFiveHours: 0,
      otOneFiveAmount: 0,
      otTwoHours: 0,
      otTwoAmount: 0,
      otThreeHours: 0,
      otThreeAmount: 0,
    };

  return (
    <Card sx={{ width: '100%', padding: '16px' }}>
      <CardContent>
        <HeaderBox>
          <InnerHeaderBox>
            <WorkOutlineOutlined sx={{ marginRight: 1, fontSize: 30, color: 'orange' }} />
            <Typography margin={0} variant="h5" align="center" gutterBottom>
              {t('OT')}
            </Typography>
            <Typography mt={1} color={'GrayText'} variant='subtitle'>({t('PreviousRound')})</Typography>
          </InnerHeaderBox>
        </HeaderBox>
        <Grid container justifyContent="center">
          {totalOvertime &&
            <OvertimeSummaryTable totalOvertime={totalOvertime} />
          }
        </Grid>
      </CardContent>
    </Card>
  );
}

export default OT;
