import { httpClient } from "./httpClient";

const getAllTeamPlan = () => {
  return httpClient.get("teamPlan");
};

const getTeamPlanById = (id) => {
  return httpClient.get(`teamPlan/teamPlanById/${id}`);
};

const postTeamPlan = (data) => {
  return httpClient.post(`teamPlan/postTeamPlan`, data);
};

const putTeamPlan = (id, data) => {
  return httpClient.post(`teamPlan/putTeamPlan/${id}`, data);
};

const putApprovalTeamPlan = (id, data) => {
  return httpClient.post(`teamPlan/putApprovalTeamPlan/${id}`, data);
};

const putEvaluationTeamPlan = (id, data) => {
  return httpClient.post(`teamPlan/putEvaluationTeamPlan/${id}`, data);
};

const deleteTeamPlan = (id, data) => {
  return httpClient.post(`teamPlan/deleteTeamPlan/${id}`, data);
};

export default {
  getAllTeamPlan,
  getTeamPlanById,
  postTeamPlan,
  putTeamPlan,
  putApprovalTeamPlan,
  putEvaluationTeamPlan,
  deleteTeamPlan,
};
