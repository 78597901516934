import React from 'react';
import { Card, CardContent, Typography, Grid, Avatar, Box, styled } from '@mui/material';
import { th, enUS, zhCN, ja, km, ko, lo, my, vi  } from 'date-fns/locale';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import CakeIcon from '@mui/icons-material/Cake';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import HelpIcon from '@mui/icons-material/Help';
import { ErrorOutline, NotificationsOutlined } from '@mui/icons-material';

import { useHistory } from 'react-router-dom';
import { updateNotification } from '../../../../../../actions/overviewUser';
import dayjs from 'dayjs';

const HeaderBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  padding: '16px 0 32px 0',
}));

const InnerHeaderBox = styled(Box)(() => ({
  borderBottom: '2px solid black',
  padding: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

function Notification({ t, i18n, notificationList, fetchNotification }) {
  const locale = i18n.resolvedLanguage === 'th' ? th : enUS;
  const history = useHistory();
  const getStatusIcon = (status) => {
    switch (status) {
      case 'approved':
        return <CheckCircleIcon color="success" sx={{ fontSize: 20 }} />;
      case 'sumary_absence':
        return <ErrorOutline color="warning" sx={{ fontSize: 20 }} />;
      case 'news':
        return <LightbulbCircleIcon color="info" sx={{ fontSize: 20 }} />;
      case 'birthday':
        return <CakeIcon color="secondary" sx={{ fontSize: 20 }} />;
      case 'reject':
        return <UnpublishedIcon color="error" sx={{ fontSize: 20 }} />;
      case 'request':
        return <HelpIcon color="primary" sx={{ fontSize: 20 }} />;
      case 'absent':
        return <ErrorOutline color="error" sx={{ fontSize: 20 }} />;
      case 'sumary_approved':
        return <CircleNotificationsIcon color="success" sx={{ fontSize: 20 }} />;
      case 'trial':
        return <CircleNotificationsIcon color="info" sx={{ fontSize: 20 }} />;
      default:
        return <CircleNotificationsIcon color="info" sx={{ fontSize: 20 }} />;
    }
  };

  const handleCardClick = async (type, id) => {
    let targetPath = '';

    switch (type) {
      case 'approved':
        targetPath = '/request-list';
        break;
      case 'sumary_absence':
        targetPath = '/employees-report/leave-absent-late';
        break;
      case 'news':
        targetPath = '/announcement';
        break;
      case 'birthday':
        targetPath = null;
        break;
      case 'reject':
        targetPath = '/request-list';
        break;
      case 'request':
        targetPath = '/request-list';
        break;
      case 'absent':
        targetPath = null;
        break;
      case 'sumary_approved':
        targetPath = '/request-list';
        break;
      case 'trial':
        targetPath = '/trial';
        break;
      default:
        targetPath = null;
        break;
    }
    try {
      const response = await updateNotification({ id });
      if (response && response.status === 200 && targetPath) {
        history.push(targetPath);
      } else {
        fetchNotification()
      }
    } catch (error) {
      console.error('Error updating notification:', error);
    }

  };

  return (
    <Card sx={{ width: '100%', padding: '16px' }}>
      <CardContent>
        <HeaderBox>
          <InnerHeaderBox>
            <NotificationsOutlined sx={{ marginRight: 1, fontSize: 30, color: 'info.main' }} />
            <Typography margin={0} variant="h5" align="center" gutterBottom>
              {t('Notification')}
            </Typography>
          </InnerHeaderBox>
        </HeaderBox>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {notificationList !== null && notificationList.notification && notificationList.notification.length > 0 ? notificationList.notification.map((data, index) => (
              <Card key={index} variant="outlined"
                sx={{
                  padding: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  // flexWrap: 'wrap',
                  width: '100%',
                  boxShadow: 2,
                  cursor: 'pointer',
                  flexDirection: { xs: 'column', sm: 'row' }
                }}
                onClick={() => handleCardClick(data.type, data.id)}
              >
                {data.type === 'news' ?
                  <Box sx={{ width: 48, height: 48, boxShadow: 2, borderRadius: '50%', position: 'relative', marginRight: 2, marginLeft: 1 }}>
                    <LightbulbCircleIcon color="info" sx={{ width: 60, height: 60, marginLeft: '-6px', marginRight: '-6px', marginTop: '-5px', padding: 0 }} />
                  </Box>
                  :
                  <Avatar
                    src={data.type === 'news' ? 'https://example.com/image1.jpg' : data.ImageURL || 'https://example.com/image1.jpg'}
                    alt={data.type === 'news' ? 'News' : 'avatar'}
                    sx={{ width: 48, height: 48, marginRight: 2, marginLeft: 1, boxShadow: 2 }}
                  />}


                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    maxWidth: { xs: '75%', sm: '85%', md: '95%' },
                    padding: 0,
                    paddingBottom: '0 !important',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: { xs: 'column', sm: 'row' } }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: { xs: '100%', sm: '60%' } }}>
                      {getStatusIcon(data.type)}
                      <Typography
                        variant="h7"
                        sx={{
                          whiteSpace: 'nowrap',
                          textAlign: { xs: 'center', sm: 'start' },
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: { xs: '100%', sm: '90%', md: '95%' },
                        }}
                      >
                        {i18n.resolvedLanguage === 'th' ? data.title_TH : data.title_EN}
                      </Typography>
                    </Box>
                    <Typography variant="caption" color="textSecondary">
                      {dayjs(data.create_date).fromNow()}
                    </Typography>
                  </Box>

                  <Typography
                    variant="subtitle2"
                    sx={{
                      whiteSpace: { xs: 'wrap', sm: 'nowrap' },
                      textAlign: { xs: 'center', sm: 'start' },
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '90%',
                    }}
                  >
                    {i18n.resolvedLanguage === 'th' ? data.description_TH : data.description_EN}
                  </Typography>
                </CardContent>

              </Card>
            )) :
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{t('NoItems')}</Box>}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Notification;
