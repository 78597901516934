import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import { Button, Card, IconButton, styled, Typography } from "@mui/material";

import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

import StatusRequest from "../../shared/general/stausRequest";
import Timer from "../../assets/timer.png";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../utils/userData";

const StyledCard = styled(Card)(({ day }) => ({
  minHeight: "117px",
  display: "flex",
  marginBottom: 16,
  borderRadius: "16px",
  "& .day-container": {
    minWidth: "54px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    color: "#FFFFFF",
    ...(day === "0" && {
      backgroundColor: "#FF5F5F",
    }),
    ...(day === "1" && {
      backgroundColor: "#FFD953",
    }),
    ...(day === "2" && {
      backgroundColor: "#FFB8E3",
    }),
    ...(day === "3" && {
      backgroundColor: "#6ADFBB",
    }),
    ...(day === "4" && {
      backgroundColor: "#FFA25F",
    }),
    ...(day === "5" && {
      backgroundColor: "#85CCFF",
    }),
    ...(day === "6" && {
      backgroundColor: "#CE90FF",
    }),
  },
  "& .date-detail": {
    width: "calc(100% - 16px)",
    padding: "16px 8px 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .container-flex-wrap": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      marginBottom: 8,
      ["@media only screen and (max-width: 600px)"]: {
        alignItems: "center",
      },
    },
    "& .fontWeight": {
      fontWeight: 600,
    },
  },
  "& .text-body-bold": {
    fontSize: "14px",
    fontWeight: "500",
  },
  "& .text-body": {
    color: "#919eab",
    fontWeight: "400",
  },
}));

const StyledButtonTime = styled(Button)(({ status, color }) => ({
  width: 156,
  borderRadius: 8,
  color: "#212b36",
  borderColor: "#dcdcdc",
  ...(status === 0 && {
    borderColor: "#f5f5f5",
    color: "#212b36",
    backgroundColor: "#f5f5f5",
    "&:hover": {
      borderColor: "#f0f0f0",
      backgroundColor: "#f0f0f0",
    },
  }),
}));

const StyledIconDelete = styled(IconButton)({
  "&:hover": {
    backgroundColor: "#fdf3f5",
  },
  "& .icon": {
    color: "#e46a76",
    fontSize: "24px",
  },
});

const StyledDivRequestChange = styled("div")({
  borderTop: "1px solid #C4C4C4",
  "& .div-expand": {
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .icon": {
      marginleft: "8px",
    },
  },
});

const StyledDivRequest = styled("div")({
  padding: "8px 8px 0px",
  margin: "8px 0px",
  "& .div-space-between": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 0,
    alignItems: "flex-start",
  },
  "& .div-shiftname-status": {
    marginLeft: 8,
    display: "flex",
    flexDirection: "column",
    marginRight: "8px",
  },
  "& .div-column-flex-end": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  "& .div-delete-button": {
    widht: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
});

const CardDate = (props) => {
  const { row, keys, handleClickButtonTime, handleClickDeleteButton } = props;
  const { t, i18n } = useTranslation();
  const { result: shiftGroupList } = useSelector((state) => state.shift);
  const { result: holiday } = useSelector((state) => state.holiday);
  const [openExpand, setOpenExpand] = useState(false);
  const [requestChangeList, setRequestChangeList] = useState({
    waiting: null,
    approved: null,
  });

  useEffect(() => {
    if (row.requestChange.length > 0) {
      let waiting = row.requestChange.find((x) => x.isApprove === null);
      let approvedList = row.requestChange.filter((x) => x.isApprove !== null);
      let approved = approvedList[approvedList.length - 1];
      setRequestChangeList({
        waiting: waiting || null,
        approved: approved || null,
      });
    } else {
      setRequestChangeList({
        waiting: null,
        approved: null,
      });
    }
  }, [row]);

  const renderShiftTime = (item) => {
    if (item.dataRender.isWorkingDay === 0) {
      if (item.dataRender.idHoliday > 0) {
        const foundHoliday = holiday.find(
          (x) => x.idHoliday === item.dataRender.idHoliday
        );
        return (
          <Fragment>
            <Typography>{`${t("PublicHoliday")}`}</Typography>
          </Fragment>
        );
      } else {
        return `${t("DayOff")}`;
      }
    } else {
      return (
        <Fragment>
          <img src={Timer} alt="Timer" width={26} style={{ marginRight: 8 }} />
          {item.dataRender.shiftGroupName}
        </Fragment>
      );
    }
  };

  const renderShiftChange = (requestChange, mode) => {
    let request =
      mode === "wait" ? requestChangeList.waiting : requestChangeList.approved;

    const foundHoliday = holiday.find(
      (x) => request && x.idHoliday === request.idHoliday
    );

    if (shiftGroupList && request) {
      const foundShiftGroup = shiftGroupList.find(
        (x) => x.idShiftGroup === request.idShiftGroup
      );
      const foundShiftType =
        foundShiftGroup &&
        foundShiftGroup.shiftType.find(
          (x) => x.idShiftType === request.idShiftType
        );

      return (
        <Fragment>
          {request.isActive && (
            <StyledDivRequest>
              <div className="div-space-between" style={{ marginTop: 0 }}>
                <div style={{ display: "flex" }}>
                  <StatusRequest
                    status={request.isApprove}
                    active={request.isActive}
                  />
                  <div className="div-shiftname-status">
                    <Typography
                      style={{ fontWeight: 500 }}
                      component="span"
                      color="text.primary"
                    >
                      {foundShiftType.isWorkingDay === 0
                        ? foundHoliday
                          ? `${t("PublicHoliday")}: ${foundHoliday.name}`
                          : `${t("DayOff")}`
                        : foundShiftGroup.shiftGroupName}
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                      {request.isApprove === 1
                        ? `(${t("Approved")})`
                        : request.isApprove === 0
                        ? `(${t("NotApproved")})`
                        : `(${t("PendingApproval")})`}
                    </Typography>
                  </div>
                </div>
                <div className="div-column-flex-end">
                  <Typography
                    color="text.secondary"
                    variant="body2"
                    style={{ fontSize: "14px" }}
                  >
                    {`สร้าง: ${dayjs(
                      request.createDateText,
                      "YYYY-MM-DD HH:mm"
                    ).format("DD/MM/BBBB")}`}
                  </Typography>
                  <Typography
                    color="text.secondary"
                    variant="body2"
                    style={{ fontSize: "14px" }}
                  >
                    {dayjs(request.createDateText, "YYYY-MM-DD HH:mm").format(
                      "HH:mm"
                    )}
                  </Typography>
                </div>
              </div>
              {request.isApprove === null ? (
                <div className="div-delete-button">
                  <StyledIconDelete
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      handleClickDeleteButton(request);
                    }}
                  >
                    <DeleteRoundedIcon className="icon" />
                  </StyledIconDelete>
                </div>
              ) : (
                <Fragment>
                  <div>
                    <div
                      className="div-space-between"
                      style={{
                        borderTop: "1px dashed #C4C4C4",
                        marginTop: "8px",
                        paddingTop: "8px",
                      }}
                    >
                      <div>
                        <Typography className="text-body-bold">
                          {`${t("Approver")}`}
                        </Typography>
                        <Typography className="text-body-bold text-body">{`${getUserFullName(request)}`}</Typography>
                        <Typography className="text-body-bold text-body">
                          {request.email}
                        </Typography>
                      </div>
                      <div className="div-column-flex-end">
                        <Typography className="text-body-bold text-body">
                          {dayjs(
                            request.approveDateText,
                            "YYYY-MM-DD HH:mm"
                          ).format("D/MM/BBBB")}
                        </Typography>
                        <Typography className="text-body-bold text-body">
                          {dayjs(
                            request.approveDateText,
                            "YYYY-MM-DD HH:mm"
                          ).format("HH:mm")}
                        </Typography>
                      </div>
                    </div>
                    {request.approveComment && (
                      <Typography className="text-body-bold text-body">{`${t(
                        "ReasonForRejection"
                      )} : ${request.approveComment}`}</Typography>
                    )}
                  </div>
                </Fragment>
              )}
            </StyledDivRequest>
          )}
        </Fragment>
      );
    }
  };

  return (
    <StyledCard day={dayjs(row.date).format("d")} key={keys}>
      <div className="day-container">{dayjs(row.date).format("dd")}</div>
      <div className="date-detail">
        <div>
          <div className="container-flex-wrap">
            <div style={{ width: "fit-content" }}>
              <Typography className="fontWeight">
                {dayjs(row.date).format("DD/MM/YYYY")}
              </Typography>
            </div>
            <div style={{ width: "fit-content" }}>
              <StyledButtonTime
                variant="outlined"
                status={row.dataRender.isWorkingDay}
                disableFocusRipple={true}
                disableRipple={true}
                aria-label="more"
                onClick={(event) => handleClickButtonTime(event, row)}
              >
                {renderShiftTime(row)}
              </StyledButtonTime>
            </div>
          </div>
        </div>
        {row.requestChange && row.requestChange.length > 0 && (
          <StyledDivRequestChange>
            {requestChangeList.waiting ? (
              renderShiftChange(row.requestChange, "wait")
            ) : requestChangeList.approved ? (
              renderShiftChange(row.requestChange, "approve")
            ) : (
              <></>
            )}
            {row.requestChange &&
              row.requestChange.length > 1 &&
              (!openExpand ? (
                <div
                  className="div-expand"
                  style={{ borderTop: "1px solid #C4C4C4" }}
                  onClick={() => setOpenExpand(true)}
                >
                  <Typography>{`${t("OtherRequestItems")}`}</Typography>
                  <KeyboardArrowDownRoundedIcon className="icon" />
                </div>
              ) : (
                <div style={{ borderTop: "1px solid #C4C4C4" }}>
                  {renderShiftChange(row.requestChange, "approve")}
                  <div
                    className="div-expand"
                    style={{ borderTop: "1px solid #C4C4C4" }}
                    onClick={() => setOpenExpand(false)}
                  >
                    <Typography>{`${t("Close")}`}</Typography>
                    <KeyboardArrowUpRoundedIcon className="icon" />
                  </div>
                </div>
              ))}
          </StyledDivRequestChange>
        )}
      </div>
    </StyledCard>
  );
};

export default CardDate;
