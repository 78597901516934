import React, { Fragment, useEffect } from "react";
import { Box, Checkbox, Divider, Grid, IconButton, MenuItem, Typography, styled } from "@mui/material";
import DrawerCustom from "../../../../shared/general/Drawer";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import  { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DeleteIcon from '@mui/icons-material/Delete';
import { getOptionMonth } from "../../../../../../utils/constant";
import { addLeaveType, allLeaveType, getAllLeaveV2 } from "../../../../../../actions/leave";
import { useDispatch } from "react-redux";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0
      }
    }
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px"
  }
})

const DrawerLeave = (props) => {

  const { drawerConfig, onClose, setOpenAlert, setAlertType } = props;

  const dispatch = useDispatch();

  const validateYupSchema = yup.object({
    name: yup.string().required("กรุณากรอกข้อมูล"),
    isPaid: yup.string().required("กรุณากรอกข้อมูล"),
    isAfter: yup.string().required("กรุณากรอกข้อมูล"),
    gender: yup.string().required("กรุณากรอกข้อมูล"),
    minLeave: yup.string().required("กรุณากรอกข้อมูล"),
    // resetEndOfMonth: yup.string().required("กรุณากรอกข้อมูล"),
    daysInAdvance: yup.string(),
    isProRate: yup.string().required("กรุณากรอกข้อมูล"),
    proRateRound: yup.string().required("กรุณากรอกข้อมูล"),
    isLeaveStep: yup.string().required("กรุณากรอกข้อมูล"),
    leaveValue: yup.string().when(
      "isLeaveStep", {
        is: "0",
        then: yup.string()
      }
    ),
    carryValue: yup.string().when(
      "isLeaveStep", {
        is: "0",
        then: yup.string()
      }
    ),
    leaveStepList: yup.array().of(
      yup.object().shape({
        min: yup.string().required("กรุณากรอกข้อมูล"),
        max: yup.string().required("กรุณากรอกข้อมูล").test("is-less-than-max", "ข้อมูลไม่ถูกต้อง", (value, context) => {
          return Number(value) >= Number(context.parent.min)
        }),
      })
    )
  })

  const useHookForm = useForm({
    defaultValues: {
      name: "",
      isPaid: "",
      isAfter: "",
      gender: "",
      minLeave: "",
      // resetEndOfMonth: "",
      daysInAdvance: "",
      isProRate: "0",
      proRateRound: "0",
      isLeaveStep: "",
      leaveValue: "",
      carryValue: "",
      leaveStepList: [],
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all"
  })

  const onSubmit = async (data) => {
    // console.log(data);

    const formData = {
      name: data.name,
      isPaid: data.isPaid,
      isAfter: data.isAfter,
      gender: data.gender,
      minLeave: data.minLeave,
      // resetEndOfMonth: data.,
      daysInAdvance: data.daysInAdvance || null,
      isProRate: data.isProRate,
      proRateRound: data.proRateRound,
      isLeaveStep: data.isLeaveStep,
      leaveValue: data.leaveValue || null,
      carryValue: data.carryValue || null,
      leaveStepList: [],
    };

    

    const result = await dispatch(addLeaveType(formData))

    if(result){
      
      if(result.status === 200){
        setOpenAlert(true)
        setAlertType("success")
        onClose();
        dispatch(getAllLeaveV2());
      } else {
        setOpenAlert(true)
        setAlertType("error")
        // onClose();
      }

    } else {
      setOpenAlert(true)
      setAlertType("error")
    }
  }

  const isProRateWatch = useHookForm.watch("isProRate")
  const isAfterWatch = useHookForm.watch("isAfter")

  useEffect(() => {
    if(drawerConfig.isOpen){
      if(drawerConfig.isEdit){
        console.log(drawerConfig)
        useHookForm.reset({
          name: drawerConfig.data.name || "",
          isPaid: drawerConfig.data.isPaid || "",
          isAfter: drawerConfig.data.isAfter || "",
          gender: drawerConfig.data.gender === null? "All": drawerConfig.data.gender,
          minLeave: drawerConfig.data.minLeave || "",
          // resetEndOfMonth: drawerConfig.data.name,
          daysInAdvance: drawerConfig.data.daysInAdvance === null? "": drawerConfig.data.daysInAdvance,
          isProRate: drawerConfig.data.isProRate || "0",
          proRateRound: drawerConfig.data.proRateRound || "0",
          isLeaveStep: drawerConfig.data.isLeaveStep === null? "": drawerConfig.data.isLeaveStep,
          leaveValue: drawerConfig.data.leaveValue || "",
          carryValue: drawerConfig.data.carryValue || "",
          leaveStepList: [],
        });
      } else {
        useHookForm.reset({
          name: "",
          isPaid: "",
          isAfter: "",
          gender: "",
          minLeave: "",
          // resetEndOfMonth: "",
          daysInAdvance: "",
          isProRate: "0",
          proRateRound: "0",
          isLeaveStep: "",
          leaveValue: "",
          carryValue: "",
          leaveStepList: [],
        });
      }
    }
  }, [drawerConfig.isOpen])

  return (
    <DrawerCustom
      title="เพิ่มรายการลา"
      anchor={"right"}
      open={drawerConfig.isOpen}
      onClose={!useHookForm.formState.isSubmitting? onClose: () => {}}
      PaperProps={{
        sx: {
          // width: "100%",
          maxWidth: "500px"
        }
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit, (error) => {console.log(error)})}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="field-label">ชื่อรายการลา</Typography>
              <Controller
                name="name"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography className="field-label">การจ่ายเงิน</Typography>
                <Controller
                name="isPaid"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    select
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  >
                    <MenuItem value="0">ไม่จ่ายเงิน</MenuItem>
                    <MenuItem value="1">จ่ายเงิน</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography className="field-label">สามารถขอลาได้หลังจาก</Typography>
                <Controller
                name="isAfter"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    select
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      if(e.target.value === "OneYear"){
                        useHookForm.setValue("isProRate", "0")
                        useHookForm.setValue("proRateRound", "0")
                      }
                    }}
                  >
                    <MenuItem value="Join">เริ่มงานวันแรก</MenuItem>
                    <MenuItem value="Probation">ผ่านช่วงโปร</MenuItem>
                    <MenuItem value="OneYear">อายุงานเกิน 1 ปี</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography className="field-label">เพศ</Typography>
                <Controller
                name="gender"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    select
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  >
                    <MenuItem value="All">ทั้งหมด</MenuItem>
                    <MenuItem value="Male">ชาย</MenuItem>
                    <MenuItem value="Female">หญิง</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography className="field-label">อนุญาติให้ลาขั้นต่ำ</Typography>
              <Controller
                name="minLeave"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    select
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  >
                    <MenuItem value="100">เต็มวัน</MenuItem>
                    <MenuItem value="50">ครึ่งวัน</MenuItem>
                    <MenuItem value="10">1 ชั่วโมง</MenuItem>
                    <MenuItem value="5">ครึ่งชั่วโมง</MenuItem>
                    {/* <MenuItem value="30">ครึ่งชั่วโมง</MenuItem> */}
                  </TextFieldTheme>
                )}
              />
            </Grid>

            {/* <Grid item xs={12}>
              <Typography className="field-label">รีเซ็ตวันลาทุกสิ้นเดือน</Typography>
              <Controller
                name="resetEndOfMonth"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    select
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  >
                    {getOptionMonth().map((item, index) => (
                      <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                    ))}
                  </TextFieldTheme>
                )}
              />
            </Grid> */}

            <Grid item xs={12}>
              <Typography className="field-label">ต้องลาล่วงหน้า (วัน)</Typography>
              <Controller
                name="daysInAdvance"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  />
                )}
              />
            </Grid>

            {["Join", "Probation"].includes(isAfterWatch) && <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <Typography fontSize="14px" fontWeight="500">คำนวน Pro Rate (กรณีเข้างานยังไม่ครบ 1 ปี)</Typography>
                  {isProRateWatch === "1" && <Typography paddingTop="4px" paddingBottom="4px" fontSize="12px" color="text.secondary">(ปัดเศษเป็นจำนวนเต็มเมื่อทศนิยมมากกว่า)</Typography>}
                </Box>
                <Controller
                  name="isProRate"
                  control={useHookForm.control}
                  render={({field}) => (
                    <Checkbox
                      {...field}
                      checked={String(field.value) === "1"? true: false}
                      onChange={(e) => {
                        field.onChange(e.target.checked? "1": "0");
                        // field.onChange(e.target.value? "1": "0");
                        useHookForm.setValue("proRateRound", "0")
                      }}
                    />
                  )}
                />
              </Box>

              {String(isProRateWatch) === "1" && (<Controller
                name="proRateRound"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    select
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  >
                    <MenuItem value="0">ไม่มีการปัดเศษ</MenuItem>
                    <MenuItem value="0.1">0.1</MenuItem>
                    <MenuItem value="0.2">0.2</MenuItem>
                    <MenuItem value="0.3">0.3</MenuItem>
                    <MenuItem value="0.4">0.4</MenuItem>
                    <MenuItem value="0.5">0.5</MenuItem>
                    <MenuItem value="0.6">0.6</MenuItem>
                    <MenuItem value="0.7">0.7</MenuItem>
                    <MenuItem value="0.8">0.8</MenuItem>
                    <MenuItem value="0.9">0.9</MenuItem>
                  </TextFieldTheme>
                )}
              />)}
            </Grid>}

            <Grid item xs={12}>
              <Typography className="field-label">ประเภทจำนวนวันลา</Typography>
              <Controller
                name="isLeaveStep"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    select
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      useHookForm.setValue("leaveStepList", [])
                    }}
                  >
                    <MenuItem value="0">วันลาแบบคงที่</MenuItem>
                    <MenuItem value="1">วันลาแบบขั้นบันได</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>

            <FormLeaveValue useHookForm={useHookForm}/>

            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose} disabled={useHookForm.formState.isSubmitting}>ยกเลิก</ButtonBlue>
              <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>เพิ่มรายการ</ButtonBlue>
            </Grid>
            
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

const FormLeaveValue = ({ useHookForm }) => {

  const isLeaveStepWatch = useWatch({
    control: useHookForm.control,
    name: "isLeaveStep"
  })

  const useFieldArrayLeaveStep = useFieldArray({
    control: useHookForm.control,
    name: "leaveStepList"
  })

  return (
    <Fragment>
      {
        isLeaveStepWatch === "0" && (
          <Fragment>
            <Grid item xs={12} md={6}>
              <Typography className="field-label">จำนวนวันลา (วัน)</Typography>
              <Controller
                name="leaveValue"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className="field-label">ยกวันลาไปรอบถัดไปได้ไม่เกิน</Typography>
              <Controller
                name="carryValue"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  />
                )}
              />
            </Grid>
          </Fragment>
        )}
        
        {isLeaveStepWatch === "1" && (
          <Fragment>
            {useFieldArrayLeaveStep.fields.map((item, index) => (
              <Grid key={item.id} item xs={12} container spacing={2}>
                <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                  <Typography fontWeight="500">เงื่อนไขที่ {index+1}</Typography>
                  <IconButton
                    disabled={useFieldArrayLeaveStep.fields.length <= 1 || (index < useFieldArrayLeaveStep.fields.length-1)}
                    onClick={() => {
                      useFieldArrayLeaveStep.remove(index)
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography className="field-label">ประเภทเงื่อนไข</Typography>
                  <Controller
                    name={`leaveStepList.${index}.stepType`}
                    control={useHookForm.control}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        select
                        helperText={useHookForm.formState.errors["leaveStepList"] && useHookForm.formState.errors["leaveStepList"][index] && useHookForm.formState.errors["leaveStepList"][index]["stepType"]? useHookForm.formState.errors["leaveStepList"][index]["stepType"].message: null}
                        error={useHookForm.formState.errors["leaveStepList"] && useHookForm.formState.errors["leaveStepList"][index] && useHookForm.formState.errors["leaveStepList"][index]["stepType"]? true: false}
                        disabled
                      >
                        <MenuItem value="SERV">อายุงาน</MenuItem>
                        <MenuItem value="JOBLEVEL">ระดับงาน</MenuItem>
                      </TextFieldTheme>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="field-label">ตั้งแต่อายุงาน (ปี)</Typography>
                  <Controller
                    name={`leaveStepList.${index}.min`}
                    control={useHookForm.control}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        select
                        helperText={useHookForm.formState.errors["leaveStepList"] && useHookForm.formState.errors["leaveStepList"][index] && useHookForm.formState.errors["leaveStepList"][index]["min"]? useHookForm.formState.errors["leaveStepList"][index]["min"].message: null}
                        error={useHookForm.formState.errors["leaveStepList"] && useHookForm.formState.errors["leaveStepList"][index] && useHookForm.formState.errors["leaveStepList"][index]["min"]? true: false}
                      >
                        <MenuItem value="0">0</MenuItem>
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4</MenuItem>
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="6">6</MenuItem>
                        <MenuItem value="7">7</MenuItem>
                        <MenuItem value="8">8</MenuItem>
                        <MenuItem value="9">9</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="11">11</MenuItem>
                        <MenuItem value="12">12</MenuItem>
                        <MenuItem value="13">13</MenuItem>
                        <MenuItem value="14">14</MenuItem>
                        <MenuItem value="15">15</MenuItem>
                      </TextFieldTheme>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="field-label">จนถึงอายุงาน (ปี)</Typography>
                  <Controller
                    name={`leaveStepList.${index}.max`}
                    control={useHookForm.control}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        select
                        helperText={useHookForm.formState.errors["leaveStepList"] && useHookForm.formState.errors["leaveStepList"][index] && useHookForm.formState.errors["leaveStepList"][index]["max"]? useHookForm.formState.errors["leaveStepList"][index]["max"].message: null}
                        error={useHookForm.formState.errors["leaveStepList"] && useHookForm.formState.errors["leaveStepList"][index] && useHookForm.formState.errors["leaveStepList"][index]["max"]? true: false}
                      >
                        <MenuItem value="0">0</MenuItem>
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4</MenuItem>
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="6">6</MenuItem>
                        <MenuItem value="7">7</MenuItem>
                        <MenuItem value="8">8</MenuItem>
                        <MenuItem value="9">9</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="11">11</MenuItem>
                        <MenuItem value="12">12</MenuItem>
                        <MenuItem value="13">13</MenuItem>
                        <MenuItem value="14">14</MenuItem>
                        <MenuItem value="15">15</MenuItem>
                        <MenuItem value="99">ขึ้นไป</MenuItem>
                      </TextFieldTheme>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="field-label">จำนวนวันลา</Typography>
                  <Controller
                    name={`leaveStepList.${index}.leaveValue`}
                    control={useHookForm.control}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        helperText={useHookForm.formState.errors["leaveStepList"] && useHookForm.formState.errors["leaveStepList"][index] && useHookForm.formState.errors["leaveStepList"][index]["leaveValue"]? useHookForm.formState.errors["leaveStepList"][index]["leaveValue"].message: null}
                        error={useHookForm.formState.errors["leaveStepList"] && useHookForm.formState.errors["leaveStepList"][index] && useHookForm.formState.errors["leaveStepList"][index]["leaveValue"]? true: false}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="field-label">ยกวันลาไปรอบถัดไปได้ไม่เกิน</Typography>
                  <Controller
                    name={`leaveStepList.${index}.carryValue`}
                    control={useHookForm.control}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        helperText={useHookForm.formState.errors["leaveStepList"] && useHookForm.formState.errors["leaveStepList"][index] && useHookForm.formState.errors["leaveStepList"][index]["carryValue"]? useHookForm.formState.errors["leaveStepList"][index]["carryValue"].message: null}
                        error={useHookForm.formState.errors["leaveStepList"] && useHookForm.formState.errors["leaveStepList"][index] && useHookForm.formState.errors["leaveStepList"][index]["carryValue"]? true: false}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <ButtonBlue
                variant="text"
                fullWidth
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => {
                  useFieldArrayLeaveStep.append({
                    stepType: "SERV",
                    min: "",
                    max: "",
                    leaveValue: "",
                    carryValue: "",
                  })
                }}
              >
                เพิ่มเงื่อนไข
              </ButtonBlue>
            </Grid>
          </Fragment>
        )
      }
    </Fragment>
  )
}

export default DrawerLeave;