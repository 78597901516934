import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Typography,
  Avatar,
  Rating,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import RoomRoundedIcon from "@mui/icons-material/RoomRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import MandatoryBow from "../../shared/assets/Mandatory.png";
import ExternalLearn from "../../shared/assets/ExternalLearn_header_card.jpg";

import dayjs from "dayjs";

const StyleCardRoot = styled(Card)({
  // width: "80%",
  minWidth: "300px",
  marginRight: "16px",
  marginTop: "16px",
  marginLeft: "16px",
  boxShadow: "rgb(90 114 123 / 20%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  marginBottom: "16px",
  position: "relative", // Added relative positioning here
});

const ContainerImage = styled("div")({
  height: "auto",
  top: "17px",
  zIndex: 2,
  position: "absolute",
  left: "0px",
});

const HeaderContainer = styled(Box)({
  textAlign: "left",
  overflowWrap: "anywhere",
  fontWeight: "600",
  "& .title": {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  "& .titleDesc": {
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    fontWeight: "500",
  },
});

const StylecategoryText = styled(Typography)({
  color: "#DB4178",
  padding: 0,
});

const StyleDivinfoClassroom = styled("div")({
  display: "flex",
  alignContent: "center",
  wordWrap: "break-word",
  color: "#00000080",
  ["@media only screen and (max-width: 900px)"]: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  ["@media only screen and (max-width: 600px)"]: {
    marginTop: "10px",
    marginBottom: "10px",
    justifyContent: "start",
  },
});

const useStyles = makeStyles({
  media: {
    height: 120,
    "z-index": 1,
  },
});

function CardCourse({ data }) {
  const classes = useStyles();

  const { result: userProfile } = useSelector((state) => state.userProfile);

  return (
    <Fragment>
      <StyleCardRoot>
        {data.isMandatory === 1 && (
          <ContainerImage>
            <img alt="Online" src={MandatoryBow} width="95" height="25" />
          </ContainerImage>
        )}
        <CardMedia
          className={classes.media}
          image={
            data.imgurl && data.isExternal === 0 ? data.imgurl : ExternalLearn
          }
          title={data.isExternal === 0 ? "Internal Course" : "External Course"}
        />
        <CardContent
          style={{
            padding: "0px 16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <Box style={{ width: "100%", marginBottom: "48px" }}>
            <div style={{ textAlign: "left", marginTop: 5, marginBottom: 5 }}>
              {data.category1 ? (
                <StylecategoryText variant="caption">{`${String(
                  data.courseType
                ).toLocaleUpperCase()}, ${
                  String(data.category1).split(/\/|\&/)[0]
                }`}</StylecategoryText>
              ) : (
                <StylecategoryText variant="caption">
                  {String(data.courseType).toLocaleUpperCase()}
                </StylecategoryText>
              )}
            </div>

            <HeaderContainer>
              <Typography
                variant="subtitle1"
                className="title"
                style={{ fontWeight: 600 }}
              >
                {data.title}
              </Typography>
            </HeaderContainer>
            <HeaderContainer>
              <Typography
                variant="subtitle2"
                className="titleDesc"
                style={{
                  color: "#00000080",
                }}
              >
                {data.titleDesc}
              </Typography>
            </HeaderContainer>
          </Box>

          <Box style={{ width: "100%" }}>
            {data.courseType === "Online" && data.isExternal === 0 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                    marginRight: "10px",
                  }}
                >
                  <Rating size="small" value={4.5} readOnly />
                  <Typography
                    variant="subtitle2"
                    style={{ color: "#00000080" }}
                  >{`(100)`}</Typography>
                </Box>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    style={{
                      backgroundColor: "#fff",
                      width: "40px",
                      height: "40px",
                    }}
                    src="https://xsgames.co/randomusers/avatar.php?g=male"
                  />
                  <div style={{ textAlign: "left", marginLeft: 10 }}>
                    <Typography variant="subtitle2" style={{ color: "black" }}>
                      {`${data.tcfirstname} ${data.tclastname}`}
                    </Typography>
                  </div>
                </Box>
              </>
            )}
            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            {data.courseType === "Classroom" && (
              <Fragment>
                <StyleDivinfoClassroom style={{ textAlign: "left" }}>
                  <EventRoundedIcon
                    style={{ fontSize: "1.3rem", marginRight: "10px" }}
                  />
                  {data.isExternal === 0 ? (
                    <Box>
                      <Typography variant="subtitle2">
                        {`${dayjs(data.startDate).format("DD/MM/YYYY")} (${
                          data.startTime
                        }) -`}
                      </Typography>
                      <Typography variant="subtitle2">
                        {`${dayjs(data.endDate).format("DD/MM/YYYY")} (${
                          data.endTime
                        })`}
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="subtitle2">
                        {`${dayjs(data.startDate).format(
                          "DD/MM/YYYY"
                        )} - ${dayjs(data.endDate).format("DD/MM/YYYY")}`}
                      </Typography>
                    </Box>
                  )}
                </StyleDivinfoClassroom>
                <StyleDivinfoClassroom>
                  <RoomRoundedIcon
                    style={{ fontSize: "1.3rem", marginRight: "10px" }}
                  />
                  <Typography
                    variant="subtitle2"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {data.place}
                  </Typography>
                </StyleDivinfoClassroom>
              </Fragment>
            )}
            <Box style={{ width: "100%", marginBottom: "5px" }}>
              {data && data.endDate && (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#00000080",
                  }}
                >
                  <EventRoundedIcon
                    style={{ fontSize: "1.3rem", marginRight: "10px" }}
                  />
                  <Typography variant="subtitle2">
                    วันที่สิ้นสุด: {dayjs(data.endDate).format("DD/MM/YYYY")}
                  </Typography>
                </Box>
              )}
              {data && data.institution && data.isExternal === 1 && (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#00000080",
                  }}
                >
                  <SchoolRoundedIcon
                    style={{ fontSize: "1.3rem", marginRight: "10px" }}
                  />
                  <Typography
                    variant="subtitle2"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {data.institution}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </CardContent>
      </StyleCardRoot>
    </Fragment>
  );
}

export default CardCourse;
