import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';
import {
    TextField,
    FormControl,
    Select,
    MenuItem,
    Grid,
    Box,
    Typography
} from "@mui/material";
import Dropzone, { useDropzone } from "react-dropzone";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Close, TextSnippet } from '@mui/icons-material';

import AddPhotoIcon from "../../../../../../components/pages/assets/add.png"
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../../shared/general/Drawer";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

import { addEmployeeContractByIdEmp, getEmployeeProfile } from "../../../../../../actions/employee";
import { getCompanyEmployeeType } from "../../../../../../actions/company";
import { getEmploymentTypeByidCompany } from "../../../../../../actions/employment-type";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center"
    },
});

const StyledFooter = styled("div")({
    padding: 16,
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel": {
        marginRight: 8,
    },
});

const StyledDropzone = styled(Box)({
    width: "100%",
    marginBottom: 12,
    backgroundColor: "#f9f9fb",
    "& .dropzone-leave": {
        // height: 98,
        borderRadius: 4,
        padding: 8,
        border: "1px dashed rgba(145, 158, 171, 0.32)",
    },

    "& .inner-dropzone": {
        cursor: "pointer",
        zIndex: 0,
        width: "100%",
        // height: 90,
        outline: "none",
        display: "flex",
        overflow: "hidden",
        borderRadius: 4,
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        // backgroundColor: "#efefef",
        flexDirection: "column",
        padding: "4px 0",
        "& img": {
            width: 72,
            height: 72,
        },
        '& .deleteButton': {
            position: "absolute",
            right: 8,
            top: 8,
            marginRight: 0,
            cursor: "pointer",
            zIndex: 3
        }
    },
    "& .inner-dropzone:hover .placeholder": {
        zIndex: 2,
    },
});

const DialogContract = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { open, handleClose, type, setAlertStatus } = props;
    const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
    const { result: employmentType } = useSelector((state) => state.employmentType);
    const [formData, setFormData] = useState({
        startDate: new Date(),
        endDate: new Date(),
        idEmploymentType: "",
        file: null,
    });

    // useEffect(() => {
    //     if (employeeProfile && employeeProfile.idCompany) {
    //         dispatch(getEmploymentTypeByidCompany(employeeProfile.idCompany));
    //         console.log(getEmploymentTypeByidCompany(employeeProfile.idCompany));
    //     }
    // }, [employeeProfile]);

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async () => {
        let data = new FormData();
        data.append("idEmployees", employeeProfile.idEmployees);
        data.append("startDate", dayjs(formData.startDate).format("YYYY-MM-DD"));
        data.append("endDate", dayjs(formData.endDate).format("YYYY-MM-DD"));
        data.append("idEmploymentType", formData.idEmploymentType === "" ? null : formData.idEmploymentType);
        if (formData.file) {
            data.append("file", formData.file, formData.file.path);
        }

        const result = await addEmployeeContractByIdEmp(data);
        if (result && result.status === 200) {
            setAlertStatus({
                isOpen: true,
                type: "success",
                label: t("AddContractSuccess")
            })
            dispatch(getEmployeeProfile(employeeProfile.idEmployees));
        } else {
            setAlertStatus({
                isOpen: true,
                type: "error",
                label: t("AddContractFailed")
            })
        }
        handleClose();
    };

    return (
        <DrawerCustom
            title={t("AddEmploymentContract")}
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot>
                <Grid container spacing={2} style={{ marginBottom: 16 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid item xs={12} sm={6} className="GridTopicInput">{t("StartDate")}</Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                value={formData.startDate}
                                inputFormat="dd/MM/yyyy"
                                name="startDate"
                                onChange={(newValue) => {
                                    setFormData({ ...formData, ["startDate"]: newValue });
                                }}
                                renderInput={(params) => (
                                    <TextFieldTheme {...params} fullWidth helperText={null} />
                                )}
                            />

                        </Grid>
                        <Grid item xs={12} sm={6} className="GridTopicInput">{t("EndDate")}</Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                value={formData.endDate}
                                inputFormat="dd/MM/yyyy"
                                name="endDate"
                                onChange={(newValue) => {
                                    setFormData({ ...formData, ["endDate"]: newValue });
                                }}
                                renderInput={(params) => (
                                    <TextFieldTheme {...params} fullWidth helperText={null} />
                                )}
                            />
                        </Grid>
                    </LocalizationProvider>
                    <Grid item xs={12} sm={6} className="GridTopicInput">{t("EmploymentType")}</Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldTheme
                            value={formData.idEmploymentType}
                            onChange={handleChange}
                            name="idEmploymentType"
                            select
                        >
                            {employmentType ? employmentType.map((type) => (
                                <MenuItem key={type.idEmploymentType} value={type.idEmploymentType}>{type.employmentTypeName}</MenuItem>
                            )) : (
                                <MenuItem key="none" value="" disabled>{t("NoData")}</MenuItem>
                            )}
                        </TextFieldTheme>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="text.third" variant="body2" paddingBottom="16px">
                            {t("AttachFile")}
                        </Typography>

                        {!formData.file ?
                            <StyledDropzone>
                                <Dropzone
                                    accept="application/pdf"
                                    maxFiles={1}
                                    multiple={false}
                                    onDrop={(acceptedFiles) => {
                                        if (acceptedFiles.length > 0) {
                                            let fileInput = acceptedFiles.map((f) =>
                                                Object.assign(f, {
                                                    preview: URL.createObjectURL(f),
                                                })
                                            );
                                            setFormData({ ...formData, file: fileInput[0] });
                                        }
                                    }}
                                >
                                    {({
                                        getRootProps,
                                        getInputProps,
                                    }) => (
                                        <div {...getRootProps({ className: "dropzone-leave" })}>
                                            <div className="inner-dropzone">
                                                <input {...getInputProps()} />
                                                <div style={{ textAlign: "center" }}>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            flexDirection: "column",
                                                        }}
                                                    >
                                                        <img alt="add" src={AddPhotoIcon} />
                                                    </div>
                                                    <Typography
                                                        variant="caption"
                                                        style={{ lineHeight: 0.5, color: "#999999" }}
                                                    >
                                                        {/* Allowed *.jpeg, *.jpg, *.png, *.pdf max size of 3 MB */}
                                                        {t("OnlyAcceptPDF")}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            </StyledDropzone>
                            :
                            <StyledDropzone>
                                <div className="dropzone-leave">
                                    <div
                                        className="inner-dropzone"
                                    >
                                        <Close
                                            className="deleteButton"
                                            onClick={() => {
                                                setFormData({ ...formData, file: null });
                                            }}
                                        />
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                            onClick={() => {
                                                window.open(formData.file.preview, "_blank");
                                            }}
                                        >
                                            <TextSnippet style={{ fontSize: 64 }} />
                                            <Typography style={{ marginTop: 16 }}>{formData.file.name}</Typography>
                                        </div>
                                    </div>
                                </div>
                            </StyledDropzone>
                        }
                    </Grid>
                </Grid>
                <StyledFooter>
                    <ButtonBlue className="cancel" onClick={handleClose}>ยกเลิก</ButtonBlue>
                    <ButtonBlue
                        variant="contained"
                        onClick={() => handleSubmit()}
                        autoFocus
                        disabled={formData.idEmploymentType === null || formData.idEmploymentType === ""}
                    >
                        {t("SaveData")}
                    </ButtonBlue>
                </StyledFooter>
            </StyledRoot>
        </DrawerCustom>
    );
};

export default DialogContract;