import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Popper,
} from "@mui/material";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import XLSX from "xlsx";

import SearchIcon from "@mui/icons-material/Search";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import CardStyle from "../../../shared/general/Card";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { getAllemployeeAbsentYear } from "../../../../../actions/leave";
import { getAffiliate } from "../../../../../actions/company";
import Backdrop from "../../../shared/general/Backdrop";
import { getAllEmployeesByIdCompany } from "../../../../../actions/employee";
import SplitButton from "../component/splitBotton";
import DialogSetting from "../component/dialogSetting";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../../shared/date/datePicker";
import { getUserFirstName, getUserFullName, getUserLastName } from "../../../../../utils/userData";

const StyledRoot = styled(Box)({
  "& .search-filter-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .filter-wrap": {},
    "& .action-container": {},
  },
});

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 1000px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "& .MuiTypography-root": {
    color: "#637381",
    backgroundColor: "#f4f6f8",
    "&.weekend": {
      fontStyle: "oblique",
    },
    "&.workday": {
      fontWeight: 600,
    },
  },
  "&.sticky": {
    padding: 0,
    position: "sticky",
    left: 0,
    zIndex: 4,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px 0px 20px #EEEEEE",
    "& .MuiTableCell-root": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: 8,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .avatar": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .fullname": {
    fontWeight: 600,
  },
  "& .position": {
    color: theme.palette.text.secondary,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .secondaryAction": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .location": {
      fontWeight: 600,
    },
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: 36,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledBoxSearchButton = styled(Box)({
    marginTop: 50,
    display: "flex",
    justifyContent: "space-evenly",
    gap: 8,
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const Absent = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: leaveYear } = useSelector((state) => state.leaveYear);
  const { result: affiliateList } = useSelector((state) => state.affiliate);
  const { result: employeesList } = useSelector((state) => state.employees);
  const [inputSearch, setInputSearch] = useState(new Date());
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [data, setData] = useState([]);
  const [month, setMonth] = useState();
  const [tempSearchDate, setTempSearchDate] = useState({ start: new Date() });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [dataEmail, setDataEmail] = useState({
    autoEmail: null,
    ReportName: "absent",
    idEmployees: null,
  });
  let year = dayjs(new Date()).get("year");

  const handleChange = () => {
    if (year !== dayjs(inputSearch).get("year"))
      dispatch(
        getAllemployeeAbsentYear(
          dayjs(inputSearch).startOf("month"),
          selectedCompany
        )
      );
    handelMonth();
  };

  const handelMonth = () => {
    setMonth("row.absent" + (inputSearch.getMonth() + 1));
    console.log(month);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

    useEffect(() => {
        if(userProfile) {
            dispatch(getAffiliate());
            dispatch(getAllEmployeesByIdCompany(userProfile.idCompany));
            dispatch(getAllemployeeAbsentYear(dayjs(inputSearch).startOf('month'), userProfile.idCompany))
        }
    }, [userProfile]);

  useEffect(() => {
    if (!selectedEmployee && leaveYear) {
      setData(leaveYear);
    } else if (leaveYear) {
      let result = leaveYear;
      result = result.filter((item, index) => {
        if (item.idEmployees === selectedEmployee) return item;
      });
      setData(result);
    } else {
      setData(null);
    }
    handelMonth();
  }, [selectedEmployee, leaveYear]);

  const onExportExcel = () => {
    const elementList = [];
    for (let index = 0; index < leaveYear.length; index++) {
      const element = {
        "#": index + 1,
        idEmployees: leaveYear[index].idEmployees,
        ชื่อ: getUserFirstName(leaveYear[index]),
        สกุล: getUserLastName(leaveYear[index]),
        [`รวมทั้งปี ${dayjs(inputSearch).get("year")}`]:
          leaveYear[index].absentTotal,
        [`1/${dayjs(inputSearch).get("year")}`]: leaveYear[index].absent1,
        [`2/${dayjs(inputSearch).get("year")}`]: leaveYear[index].absent2,
        [`3/${dayjs(inputSearch).get("year")}`]: leaveYear[index].absent3,
        [`4/${dayjs(inputSearch).get("year")}`]: leaveYear[index].absent4,
        [`5/${dayjs(inputSearch).get("year")}`]: leaveYear[index].absent5,
        [`6/${dayjs(inputSearch).get("year")}`]: leaveYear[index].absent6,
        [`7/${dayjs(inputSearch).get("year")}`]: leaveYear[index].absent7,
        [`8/${dayjs(inputSearch).get("year")}`]: leaveYear[index].absent8,
        [`9/${dayjs(inputSearch).get("year")}`]: leaveYear[index].absent9,
        [`10/${dayjs(inputSearch).get("year")}`]: leaveYear[index].absent10,
        [`11/${dayjs(inputSearch).get("year")}`]: leaveYear[index].absent11,
        [`12/${dayjs(inputSearch).get("year")}`]: leaveYear[index].absent12,
      };
      elementList.push(element);
    }
    const workSheet = XLSX.utils.json_to_sheet(elementList);
    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, `${t("ReportAbsent")}`);
    XLSX.writeFile(workBook, `${t("ReportAbsent")}.xlsx`);
  };

    return (
        <div>
            <StyledRoot>
                <StyledWrapFilter>
                    <Grid container spacing={2} columns={14} alignItems="center">
                        <Grid item xs={3}>
                            <StyledBoxSearch>
                                <Typography className="label" color="text.third">
                                  {t("SearchDate")}
                                </Typography>
                                <div className="search-date">
                                    <DatePickerCustom
                                        minDate={new Date(new Date().getFullYear() - 4, 0, 1)}
                                        maxDate={new Date()}
                                        inputFormat="MMMM YYYY"
                                        value={inputSearch}
                                        name="start"
                                        views={["year", "month"]}
                                        openTo="month"
                                        onChange={(newValue) => {
                                            setInputSearch(newValue);
                                        }}
                                    />
                                </div>
                            </StyledBoxSearch>
                        </Grid>
                        <Grid item xs={3} >
                            <StyledBoxSearch>
                                <Typography className="label" color="text.third">
                                  {t("Company")}
                                </Typography>
                                <div className="search-name">
                                    {affiliateList && (
                                        <StyledAutocomplete
                                            options={affiliateList}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setSelectedCompany(newValue.idCompany);
                                                    setSelectedEmployee(null)
                                                } else {
                                                    setSelectedCompany(userProfile.idCompany);
                                                    setSelectedEmployee(null)
                                                }
                                            }}
                                            popupIcon={<i className="fa-light fa-chevron-down"></i>}
                                            getOptionLabel={(option) => option.companyName}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="filled"
                                                    placeholder={t("AllCompany")}
                                                />
                                            )}
                                            PopperComponent={StyledPopper}
                                            noOptionsText={t("NoData")}
                                        />
                                    )}
                                </div>
                            </StyledBoxSearch>
                        </Grid>
                        {selectedCompany && <Grid item xs={3} >
                            <StyledBoxSearch>
                                <Typography className="label" color="text.third">
                                  {t("SearchEmp")}
                                </Typography>
                                <div className="search-name">
                                    {employeesList && (
                                        <StyledAutocomplete
                                            options={employeesList}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setSelectedEmployee(newValue.idEmployees);
                                                } else {
                                                    setSelectedEmployee(null);
                                                }
                                            }}
                                            popupIcon={<i className="fa-light fa-chevron-down"></i>}
                                            getOptionLabel={(option) => `${getUserFullName(option)}`}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="filled"
                                                    placeholder={t("SelectEmp")}
                                                />
                                            )}
                                            PopperComponent={StyledPopper}
                                            noOptionsText={t("NoData")}
                                        />
                                    )}
                                </div>
                            </StyledBoxSearch>
                        </Grid>}
                        <Grid item xs={3} >
                            <StyledBoxSearchButton>
                                <div>
                                    <ButtonBlue
                                        variant="contained"
                                        startIcon={<SearchIcon />}
                                        onClick={handleChange}
                                    >
                                      {t("Search")}
                                    </ButtonBlue>
                                </div>
                                <div >
                                    <SplitButton
                                        onExportExcel={onExportExcel}
                                        excel={leaveYear}
                                        setOpenDrawer={setOpenDrawer} />
                                </div>
                            </StyledBoxSearchButton>
                        </Grid>
                    </Grid>
                </StyledWrapFilter>
                {data ? (
                    <CardStyle>
                        <Box style={{ padding: "24px" }}>
                            <Box style={{ display: "flex", position: "relative" }}>
                                <StyledBox className={"close"}>
                                    <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledCellHeader
                                                      align={"center"}
                                                      className="sticky"
                                                      style={{ minWidth: 240 }}
                                                    >
                                                      {t("FullName")}
                                                    </StyledCellHeader>
                                                    <StyledCellHeader>
                                                        <Typography variant="body2" align="center">{t("Month")}</Typography>
                                                    </StyledCellHeader>
                                                    <StyledCellHeader>
                                                        <Typography variant="body2" align="center">{t("Year")}</Typography>
                                                    </StyledCellHeader>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.length > 0 ? data.map((row, index) => (
                                                    <StyledRowContent>
                                                        <StyledCellContent key={`${index}`} className="sticky" style={{ borderBottom: "1px dashed rgba(224, 224, 224, 1)" }}>
                                                            <div className="avatar">
                                                                <Avatar
                                                                    src={`${row.imageName}`}
                                                                />
                                                                <Typography className="fullname">
                                                                  {getUserFullName(row)}
                                                                </Typography>
                                                            </div>
                                                        </StyledCellContent>
                                                        <StyledCellContent>
                                                            <Typography variant="body2" align="center">{eval(month)}</Typography>
                                                        </StyledCellContent>
                                                        <StyledCellContent>
                                                            <Typography variant="body2" align="center">{row.absentTotal}</Typography>
                                                        </StyledCellContent>
                                                    </StyledRowContent>
                                                )) : t("NoData")}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </StyledBox>
                            </Box>
                        </Box>
                    </CardStyle>
                ) : (
                    <Backdrop open={true} />
                )}
            </StyledRoot>
            {openDrawer && <DialogSetting
                setData={setDataEmail}
                handleClose={handleCloseDrawer}
                open={openDrawer}
                data={dataEmail}
            />}
        </div>
    )
}

export default Absent;
