import React, { useState } from "react";
import {
  Box,
  Chip,
  CircularProgress,
  Dialog,
  FormHelperText,
  IconButton,
  styled,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import CardStyle from "../../../../../shared/general/Card";
import Dropzone from "react-dropzone";
import AddIcon from "./../../../../../assets/addCertificate.png";
import { useDispatch } from "react-redux";
import { addManpowerCertificate, deleteManpowerCertificate, getManpowerCertificate } from "../../../../../../../actions/manpower";
import ButtonBlue from "../../../../../shared/general/ButtonBlue";
import { useTranslation } from "react-i18next";
import DrawerNoteInterview from "./drawerNoteInterview";


const StyledRoot = styled(Box)({
  padding: "24px",
})

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  // color: "#637381",
  color: "#212b36",
  backgroundColor: "#f4f6f8",
  boxSizing: "border-box",

});

const StyledRowContent = styled(TableRow)({
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
  "&:last-of-type": {
    "& .MuiTableCell-root": {
      borderBottom: "none",
    },
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: 16,
  boxSizing: "border-box",
  color: "#212b36",
  "& .time-in-out": {
    width: 150,
    minWidth: 150,
  },
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));

const StyledChip = styled(Chip)(({ status }) => {
  return {
    margin: 0,
    fontSize: 14,
    fontWeight: 600,
    height: 26,
    ...(status === 1 && {
      backgroundColor: "#E7F4E7",
      // color: "#6CDA91",
      color: "#52D87F",
    }),
    ...(status === 2 && {
      backgroundColor: "#fdf3f5",
      color: "#fc4b6c",
    }),
    ...([3, 4, 5, 6].includes(status) && {
      backgroundColor: "#fffcd6",
      color: "#eebb00",
    }),
  };
});

const formatDate = (dateString) => {
  if (!dateString) return "ไม่ปรากฏ";
  const [year, month, day] = dateString.split('-');
  return `${day}-${month}-${year}`;
};



const HistoryInterview = (props) => {

  const { idManpower, data } = props;
  // console.log(idManpower);
  // console.log(data);
  const [HistoryInterviewData, SetHistoryInterviewData] = useState(data);
  const [isOpenDrawerNote, setIsOpenDrawerNote] = useState(false);
  // const [selectedNote, setSelectedNote] = useState(null);
  const [selectedNoteAndID, setselectedNoteAndID] = useState({
    Note: null,
    idCandidate: null,
  })

  const handleDrawerOpen = (Note, idCandidate) => {
    // setSelectedNote(item);
    setselectedNoteAndID({
      Note: Note,
      idCandidate: idCandidate
    })
    setIsOpenDrawerNote(true);
  };

  const getLabelInterviewResult = (status) => {
    if (status === 1) {
      return "ผ่านการสัมภาษณ์"
    } else if (status === 2) {
      return "ไม่ผ่านการสัมภาษณ์"
    } else if (status === 3) {
      return "รอสัมภาษณ์"
    } else if (status === 4) {
      return "รอผลสัมภาษณ์"
    } else if (status === 5) {
      return "รอดำเนินการ"
    } else if (status === 6) {
      return "ถูกเลือก"
    } else {
      return "ไม่พบสถานะ"
    }
  }

  return (
    <CardStyle>
      <StyledRoot>
        <Typography variant="h5" fontSize={20}>ประวัติการสัมภาษณ์</Typography>
        <TableContainer style={{ marginTop: "24px" }}>
          <Table>
            <TableHead>
              <StyledRowContent>
                <StyledCellHeader
                  style={{ textAlign: "center", minWidth: "150px" }}
                >
                  ตำแหน่ง
                </StyledCellHeader>
                <StyledCellHeader
                  style={{ textAlign: "center", minWidth: "170px" }}
                >
                  บริษัท
                </StyledCellHeader>
                <StyledCellHeader
                  style={{ textAlign: "center", minWidth: "150px" }}
                >
                  วันที่
                </StyledCellHeader>
                <StyledCellHeader
                  style={{ textAlign: "center", minWidth: "150px" }}
                >
                  ผลการสัมภาษณ์
                </StyledCellHeader>
                <StyledCellHeader
                  style={{ textAlign: "center", minWidth: "100px" }}
                >
                  คอมเม้นต์
                </StyledCellHeader>
              </StyledRowContent>
            </TableHead>
            <TableBody>
              {/* <StyledRowContent>
                <StyledCellContent
                  style={{ textAlign: "center", minWidth: "150px" }}
                >
                  <Typography fontSize="14px" color="#637381">
                    HR Manager
                  </Typography>
                </StyledCellContent>
                <StyledCellContent
                  style={{ textAlign: "center", minWidth: "150px" }}
                >
                  <Typography fontSize="14px" color="#637381">
                    SCGC
                  </Typography>
                </StyledCellContent>
                <StyledCellContent
                  style={{ textAlign: "center", minWidth: "150px" }}
                >
                  <Typography fontSize="14px" color="#637381">
                    01/05/2023
                  </Typography>
                </StyledCellContent>
                <StyledCellContent
                  style={{ textAlign: "center", minWidth: "150px" }}
                >
                    <StyledChip 
                      label={"ไม่ผ่านสัมภาษณ์"}
                      status={"ไม่ผ่านสัมภาษณ์"}
                    />
                </StyledCellContent>
                <StyledCellContent
                  style={{ textAlign: "center", minWidth: "150px" }}
                >
                  <IconButton
                  style={{ color: "#83D0F5" }}
                  onClick={() => {
                    setIsOpenDrawerNote(true);
                  }}
                >
                  <i className="fa-regular fa-memo"></i>
                </IconButton>
                {isOpenDrawerNote && (
                  <DrawerNoteInterview
                    open={isOpenDrawerNote}
                    onClose={() => {
                      setIsOpenDrawerNote(false);
                    }}
                    data={"kuy"}

                  />
                )}
                </StyledCellContent>
                
              </StyledRowContent> */}
              <>
                {HistoryInterviewData.length === 0 ? (
                  <StyledRowContent>
                    <StyledCellContent colSpan={5}>
                      <Typography
                        fontSize="24px"
                        color="text.secondary"
                        textAlign="center"
                      >
                        {"No Data"}
                      </Typography>
                    </StyledCellContent>
                  </StyledRowContent>
                ) : (
                  HistoryInterviewData.map((item, index) => (
                    <StyledRowContent key={index}>
                      <StyledCellContent style={{ textAlign: "center", minWidth: "150px" }}>
                        <Typography fontSize="14px" color="#637381">
                          {item.positionName ? item.positionName : "ไม่ปรากฏ"}
                        </Typography>
                      </StyledCellContent>
                      <StyledCellContent style={{ textAlign: "center", minWidth: "150px" }}>
                        <Typography fontSize="14px" color="#637381">
                          {item.companyName ? item.companyName : "ไม่ปรากฏ"}
                        </Typography>
                      </StyledCellContent>
                      <StyledCellContent style={{ textAlign: "center", minWidth: "150px" }}>
                        <Typography fontSize="14px" color="#637381">
                          {formatDate(item.appointmentDate)}
                        </Typography>
                      </StyledCellContent>
                      <StyledCellContent style={{ textAlign: "center", minWidth: "150px" }}>
                        {/* <StyledChip
                          label={getLabelInterviewResult(item.isAppointmentSuccess, item.isAppointmentFailed)}
                          status={{ Success: item.isAppointmentSuccess, Failed: item.isAppointmentFailed }}
                        /> */}
                        <StyledChip
                          label={getLabelInterviewResult(item.idStatus)}
                          status={item.idStatus}
                        />
                      </StyledCellContent>
                      <StyledCellContent
                        style={{ textAlign: "center", minWidth: "150px" }}
                      >
                        <IconButton
                          style={{ color: "#83D0F5" }}
                          onClick={() => {
                            handleDrawerOpen(item.note, item.idCandidate);
                          }}
                        >
                          <i className="fa-regular fa-memo"></i>
                        </IconButton>
                        {isOpenDrawerNote && (
                          <DrawerNoteInterview
                            open={isOpenDrawerNote}
                            onClose={() => {
                              setIsOpenDrawerNote(false);
                            }}
                            data={selectedNoteAndID}
                            idManpower={idManpower}
                          />
                        )}
                      </StyledCellContent>
                    </StyledRowContent>
                  )))
                }
              </>
              {/* {HistoryInterviewData.length === 0 && (
                  <StyledRowContent>
                  <StyledCellContent colSpan={5}>
                    <Typography
                      fontSize="24px"
                      color="text.secondary"
                      textAlign="center"
                    >
                      {"No Data"}
                    </Typography>
                  </StyledCellContent>
                </StyledRowContent>
              )} */}
              {/* {HistoryInterviewData.map((item, index) => (
                <StyledRowContent key={index}>
                  <StyledCellContent style={{ textAlign: "center", minWidth: "150px" }}>
                    <Typography fontSize="14px" color="#637381">
                      {item.positionName ? item.positionName : "ไม่ปรากฏ"}
                    </Typography>
                  </StyledCellContent>
                  <StyledCellContent style={{ textAlign: "center", minWidth: "150px" }}>
                    <Typography fontSize="14px" color="#637381">
                      {item.companyName ? item.companyName : "ไม่ปรากฏ"}
                    </Typography>
                  </StyledCellContent>
                  <StyledCellContent style={{ textAlign: "center", minWidth: "150px" }}>
                    <Typography fontSize="14px" color="#637381">
                      {formatDate(item.appointmentDate)}
                    </Typography>
                  </StyledCellContent>
                  <StyledCellContent style={{ textAlign: "center", minWidth: "150px" }}>
                    <StyledChip
                      label={getLabelInterviewResult(item.isAppointmentSuccess, item.isAppointmentFailed)}
                      status={{ Success: item.isAppointmentSuccess, Failed: item.isAppointmentFailed }}
                    />
                  </StyledCellContent>
                  <StyledCellContent
                    style={{ textAlign: "center", minWidth: "150px" }}
                  >
                    <IconButton
                      style={{ color: "#83D0F5" }}
                      onClick={() => {
                        handleDrawerOpen(item.note);
                      }}
                    >
                      <i className="fa-regular fa-memo"></i>
                    </IconButton>
                    {isOpenDrawerNote && (
                      <DrawerNoteInterview
                        open={isOpenDrawerNote}
                        onClose={() => {
                          setIsOpenDrawerNote(false);
                        }}
                        data={selectedNote}
                      />
                    )}
                  </StyledCellContent>
                </StyledRowContent>
              ))} */}
            </TableBody>
          </Table>
        </TableContainer>


      </StyledRoot>
    </CardStyle>
  )
}

export default HistoryInterview;