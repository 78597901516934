import React, { Fragment, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { getAllCandidateStatus, updateInterviewInfo, getCandidatesAlreadySendToJob } from '../../../../../../actions/candidate';
import {
	Box,
	Divider,
	Grid,
	IconButton,
	MenuItem,
	styled,
	Typography
} from "@mui/material";
import {
	Add,
	Delete
} from "@mui/icons-material";

import DrawerCustom from '../../../../shared/general/Drawer';
import TextFieldTheme from '../../../../shared/general/TextFieldTheme';
import ButtonBlue from '../../../../shared/general/ButtonBlue';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
// import NumberFormatTheme from '../../../../../shared/general/NumberFormatTheme';
import DatePickerCustom from '../../../../shared/date/datePicker';
import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const StyledRoot = styled("div")({
	width: 450,
	padding: 16,
});

const DrawerEditInterview = (props) => {
	const { result: ManpowerProfile } = useSelector(state => state.manpowerProfile);
	const dispatch = useDispatch();
	const { open, onClose, idManpower, isEdit, data } = props;
	// console.log(data);
	const { control, handleSubmit, formState: { errors, dirtyFields, isDirty }, setValue  } = useForm({
		defaultValues: {
			statusCandidate: "",
			appointmentDate: data.appointmentDate ? data.appointmentDate : "",
			appointmentStartTime: data.appointmentStartTime ? dayjs(data.appointmentStartTime, 'HH:mm:ss') : "",
			appointmentPlace: data.appointmentPlace ? data.appointmentPlace : "",
		},

	});

	const handleClose = () => {
		onClose();
	};

	const onSubmitHandler = async (submitData) => {
		// console.log(submitData);
		// console.log(dayjs(submitData.appointmentDate).format('YYYY-MM-DD'));
		// console.log(dayjs(submitData.appointmentStartTime).format('HH:mm:ss'));
		const formData = {};
		for(const key in submitData){
			// console.log(key); 
			if(key === "appointmentDate"){
				formData[key] = submitData[key] ? dayjs(submitData[key]).format('YYYY-MM-DD') : null;
				continue
			}

			if(key === "appointmentStartTime"){
				formData[key] = submitData[key] ? dayjs(submitData[key]).format('HH:mm:ss') : null;
				continue
			}

			formData[key] = submitData[key];
		}

		// console.log(formData);
		// console.log(data.idCandidate);
		// console.log(submitData.statusManpower);
		// console.log(idManpower);
		// console.log(ManpowerProfile);
		dispatch(updateInterviewInfo(data.idCandidate, formData)).then(res => {
				dispatch(getCandidatesAlreadySendToJob(data.idJobRecruit));
			})
		// dispatch(updateInterviewInfo(ManpowerProfile.idManpower, { idManpowerStatus: submitData.statusManpower })).then(res => {
		// 	dispatch(getManpowerById(ManpowerProfile.idManpower));
		// })
		onClose();

	};

	const [AllCandidateStatus, SetAllCandidateStatus] = useState([]);

	useEffect(() => {
		const fetchData = () => {
			dispatch(getAllCandidateStatus())
				.then(res => {
					SetAllCandidateStatus(res.data);
				})
				.catch(error => {
					console.error('Failed to fetch all data Candidate Status :', error);
				});
		};

		fetchData();
	}, []);

	// Fix Warning Mui MenuItem don't match with default value
	useEffect(() => {
		if (AllCandidateStatus && AllCandidateStatus.length > 0) {
		  const defaultStatus = data.idStatus && AllCandidateStatus.some(status => status.idCandidateStatus === data.idStatus) ? data.idStatus : "";
		  setValue("statusCandidate", defaultStatus);
		}
	  }, [AllCandidateStatus, data.idStatus]);

	return (
		<DrawerCustom
			open={open}
			title={data.idCandidate ? "แก้ไขรายละเอียดการสัมภาษณ์" : ""}
			anchor="right"
		>
			<StyledRoot>
				<form onSubmit={handleSubmit(onSubmitHandler)}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Controller
									name="statusCandidate"
									control={control}
									rules={{
										required: { value: true, message: "กรุณาเลือกสถานะ" },
									}}
									render={({ field }) => (
										<Fragment>
											<Typography gutterBottom sx={{ color: "#ffffff" }}>สถานะ</Typography>
											<TextFieldTheme
												{...field}
												placeholder="สถานะ"
												helperText={errors && errors.statusCandidate && errors.statusCandidate.message}
												error={errors && errors.statusCandidate ? true : false}
												select
												fullWidth
											>
												{AllCandidateStatus && AllCandidateStatus.length > 0 ? (
													AllCandidateStatus.map((status) => (
														<MenuItem key={status.idCandidateStatus} value={status.idCandidateStatus}>
															{status.idCandidateStatus === 0 ? 'ยกเลิก' : status.CandidateStatusName}
														</MenuItem>
													))
												) : (
													<MenuItem disabled>กำลังโหลด...</MenuItem>
												)}
											</TextFieldTheme>
										</Fragment>
									)}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Controller
									name="appointmentDate"
									control={control}
									// rules={{
									// 	required: { value: true, message: "กรุณาเลือกวันที่จะนัดสัมภาษณ์" },
									// }}
									render={({ field }) => (
										<DatePickerCustom
											{...field}
											placeholder="วันนัดสัมภาษณ์"
											views={["year", "month", "day"]}
											openTo="day"
											// maxDate={dayjs()}
											renderInput={(params) => (
												<Fragment>
													<Typography gutterBottom sx={{ color: "#ffffff" }}>วันนัดสัมภาษณ์</Typography>
													<TextFieldTheme
														{...params}
														inputProps={{
															...params.inputProps,
															placeholder: "วันที่จะนัดสัมภาษณ์",
															readOnly: true
														}}
														helperText={errors && errors.appointmentDate && errors.appointmentDate.message}
														error={errors && errors.appointmentDate ? true : false}
														fullWidth
													/>
												</Fragment>
											)}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Controller
									name="appointmentStartTime"
									control={control}
									// rules={{
									// 	required: { value: true, message: "กรุณาเลือกเวลาเริ่มสัมภาษณ์" },
									// }}
									render={({ field, fieldState }) => (
										<TimePicker
											{...field}
											ampm={false}
											label="เวลาเริ่มต้น"
											renderInput={(params) => (
												<Fragment>
													<Typography gutterBottom sx={{ color: "#ffffff" }}>เวลาเริ่มสัมภาษณ์</Typography>
													<TextFieldTheme
														{...params}
														helperText={errors && errors.appointmentStartTime && errors.appointmentStartTime.message}
														error={errors && errors.appointmentStartTime ? true : false}
														fullWidth
													/>
												</Fragment>
											)}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="appointmentPlace"
									control={control}
									// rules={{
									// 	required: { value: true, message: "กรุณาเลือกสถานที่สัมภาษณ์" },
									// }}
									render={({ field }) => (
										<Fragment>
											<Typography gutterBottom sx={{ color: "#ffffff" }}>สถานที่สัมภาษณ์</Typography>
											<TextFieldTheme
												{...field}
												placeholder="สถานที่สัมภาษณ์"
												inputProps={{
													maxLength: 200,
												}}
												fullWidth
												multiline
												minRows={3}
												helperText={errors && errors.appointmentPlace && errors.appointmentPlace.message}
												error={errors && errors.appointmentPlace ? true : false}
											/>
										</Fragment>
									)}
								/>
							</Grid>
						</Grid>
					</LocalizationProvider>

					<div style={{ display: "flex", justifyContent: "space-between", margin: "20px 0" }}>
						<ButtonBlue
							variant="outlined"
							onClick={handleClose}
						>ยกเลิก</ButtonBlue>
						<ButtonBlue
							type="submit"
							variant="contained"
							disabled={!isDirty}
						>ยืนยัน</ButtonBlue>
					</div>
				</form>
			</StyledRoot>
		</DrawerCustom>
	);
}

export default DrawerEditInterview;