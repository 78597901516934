import dayjs from 'dayjs';
import ExcelJS from 'exceljs';

export const DetailedTimeStampDepartment = (t, data, leaveData, start, end) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet(t("WorkTimeSummaryByDepartment"));

    const DateStart = dayjs(start).add(543, 'year').format("DD-MM-YYYY");
    const DateEnd = dayjs(end).add(543, 'year').format("DD-MM-YYYY");

    const ThaiDateStart = DateStart.split('-').map((value, index) =>
        index === 1 ? t(`${dayjs().month(Number(value) - 1).format("MMMM")}`) : value
    ).join(' ');

    const ThaiDateEnd = DateEnd.split('-').map((value, index) =>
        index === 1 ? t(`${dayjs().month(Number(value) - 1).format("MMMM")}`) : value
    ).join(' ');

    const leaveTypeNames = leaveData.map(leave => t(leave.leaveGroupName));

    const headers = [
        t("Department"),
        t("EmpTotal"),
        t("StartDate"),
        t("EndDate"),
        t("TotalWorkingHours"),
        t("TotalLeaveHours"),
        t("TotalOvertimeHours"),
        t("Absent"),
        t("Late"),
        ...leaveTypeNames
    ];

    const headerRow = worksheet1.addRow(headers);
    headerRow.height = 50;

    headerRow.eachCell((cell) => {
        cell.font = { bold: true, size: 14, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } };
        cell.alignment = { horizontal: "center", vertical: 'middle' };
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' },
        };
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
        };
    });

    const colWidths = [
        { key: "department", width: 25 },
        { key: "totalEmployee", width: 15 },
        { key: "start", width: 15 },
        { key: "end", width: 15 },
        { key: "totalWorkingHour", width: 20 },
        { key: "totalLeaveHour", width: 20 },
        { key: "totalOTHour", width: 20 },
        { key: "absent", width: 10 },
        { key: "late", width: 10 },
        ...leaveTypeNames.map(name => ({ key: name, width: 20 })),
    ];

    colWidths.forEach((col, index) => {
        worksheet1.getColumn(index + 1).width = col.width;
    });

    const groupedData = data.reduce((acc, employee) => {
        const deptName = employee.departmentName || "Unknown";
        if (!acc[deptName]) {
            acc[deptName] = {
                totalEmployees: 0,
                totalWorkingHours: 0,
                totalLeaveHours: 0,
                totalOTHours: 0,
                totalAbsent: 0,
                totalLate: 0,
                leaveHours: leaveData.reduce((leaveAcc, leave) => {
                    leaveAcc[leave.idLeaveGroup] = 0;
                    return leaveAcc;
                }, {}),
            };
        }

        acc[deptName].totalEmployees += 1;
        acc[deptName].totalWorkingHours += employee.workHourActualTotal || 0;
        acc[deptName].totalLeaveHours += employee.leaveHourTotal || 0;
        acc[deptName].totalOTHours += employee.otHourTotal || 0;
        acc[deptName].totalAbsent += employee.absent || 0;
        acc[deptName].totalLate += employee.late || 0;

        leaveData.forEach((leave) => {
            acc[deptName].leaveHours[leave.idLeaveGroup] += employee.leaveUsed[leave.idLeaveGroup] || 0;
        });

        return acc;
    }, {});

    Object.entries(groupedData).forEach(([departmentName, totals]) => {
        const rowData = [
            departmentName,
            totals.totalEmployees,
            ThaiDateStart,
            ThaiDateEnd,
            totals.totalWorkingHours.toFixed(2),
            totals.totalLeaveHours.toFixed(2),
            totals.totalOTHours.toFixed(2),
            totals.totalAbsent,
            totals.totalLate,
            ...leaveData.map((leave) =>
                (totals.leaveHours[leave.idLeaveGroup] || 0).toFixed(2)
            ),
        ];

        const excelRow = worksheet1.addRow(rowData);

        excelRow.eachCell((cell) => {
            cell.font = { size: 14, name: 'TH SarabunPSK' };
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } },
            };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${t("WorkTimeSummaryByDepartment")}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
};
