import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Grid } from "@mui/material";
import DatePickerCustom from "../../../shared/date/datePicker";
import dayjs from "dayjs";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import {
  approveTerminateEmployeeByReviewer,
  getTerminateEmployeeByReviewer,
} from "../../../../../actions/offBoarding";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const Dialog = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { open, handleClose, data, setSnackBarConfig } = props;
  const [formData, setFormData] = useState();

  useEffect(() => {
    if (open) {
      setFormData({
        note: data.note,
        updateDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        isApprove: 1,
      });
    }
  }, [open]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const result = await dispatch(
      approveTerminateEmployeeByReviewer(data.idTerminateEmployee, formData)
    );
    if (result) {
      if (result.status === 200) {
        dispatch(getTerminateEmployeeByReviewer(userProfile.idEmployees));
        setSnackBarConfig({
          open: true,
          message: `${t("Successfully")}`,
          type: "success",
        });
        handleClose();
      } else {
        setSnackBarConfig({
          open: true,
          message: `${t("Unsuccessful")}`,
          type: "error",
        });
      }
    } else {
      setSnackBarConfig({
        open: true,
        message: `${t("Unsuccessful")}`,
        type: "error",
      });
    }
  };

  return (
    <DrawerCustom
      title={`${t("InspectionDetails")}`}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      {formData && (
        <StyledRoot>
          <Grid container spacing={2} style={{ marginBottom: 16, width: 400 }}>
            <Grid item xs={12} sm={6} className="GridTopicInput">{`${t(
              "InspectionDate"
            )}`}</Grid>
            <Grid item xs={12} sm={6}>
              <DatePickerCustom
                value={formData.updateDate}
                inputFormat="DD/MM/YYYY"
                name="updateDate"
                onChange={(newValue) => {
                  setFormData({
                    ...formData,
                    ["updateDate"]: dayjs(newValue).format("YYYY-MM-DD"),
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth helperText={null} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="GridTopicInput">{`${t(
              "Note"
            )}`}</Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                name="note"
                multiline
                value={formData.note}
                onChange={handleChange}
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleClose}>
              {`${t("Cancel")}`}
            </ButtonBlue>
            <ButtonBlue variant="contained" onClick={handleSubmit} autoFocus>
              {`${t("SaveData")}`}
            </ButtonBlue>
          </StyledFooter>
        </StyledRoot>
      )}
    </DrawerCustom>
  );
};

export default Dialog;
