import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import { useDispatch, useSelector } from "react-redux";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";
import { useTranslation } from "react-i18next";
import { getAllPerformanceQuestionSet, updatePerformanceQuestionSetStatus } from "../../../../../actions/estimatePerformance";

const AccordionStyled = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))({
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
});

const AccordionSummaryStyled = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderRadius: 8,
  marginBottom: 8,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionQuestionSet = ({ 
  data, 
  handleChangeSelectQuestionSet, 
  handleUpdateQuestionSetStatus
}) => {
  const [isExpand, setIsExpand] = useState(false);
  const { t } = useTranslation();

  return (
    <AccordionStyled
      expanded={isExpand}
      onChange={() => setIsExpand((prev) => !prev)}
    >
      <AccordionSummaryStyled>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={"100%"}
        >
          <Typography>{data.questionSetName}</Typography>
            <Box display="flex">

            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={Boolean(data.isActive)}
                    onChange={() => handleUpdateQuestionSetStatus({
                      isActive: !data.isActive,
                      idPerformanceQuestionSet: data.idPerformanceQuestionSet,
                    })}
                  />
                }
                label={t("Activate")}
              />
            </FormGroup>
            <IconButton
              aria-label="edit"
              onClick={(e) => {
                e.stopPropagation();
                handleChangeSelectQuestionSet(data);
              }}
            >
              <EditNoteRoundedIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
      </AccordionSummaryStyled>
      <AccordionDetails>
        <Stack spacing={2}>
          <div>
            <Typography fontSize="14px" fontWeight="500" color="text.secondary">
            {t("AnswerScale")}
            </Typography>
            <Typography>{`${data.answerScale} ระดับ`}</Typography>
          </div>
          <div>
            <Typography fontSize="14px" fontWeight="500" color="text.secondary">
              {t("Evaluator")}
            </Typography>
            <div style={{ display: "flex", gap: "8px" }}>
              {data.isSelfAssessment && (
                <Chip
                  label={t("SelfAssessment")}
                  variant="outlined"
                  color="primary"
                />
              )}
              {data.isManagerAssessment && (
                <Chip
                  label={t("SupervisorAssessment")}
                  variant="outlined"
                  color="primary"
                />
              )}
            </div>
          </div>
          <div>
            <Typography fontSize="14px" fontWeight="500" color="text.secondary">
              {t("EmploymentType")}
            </Typography>
            {data.employmentTypes.length === 0 ? (
              <Chip label={t("All")} variant="outlined" color="primary" />
            ) : (
              <div style={{ display: "flex", gap: "8px" }}>
                {data.employmentTypes.map((item) => (
                  <Chip
                    label={item.employmentTypeName}
                    variant="outlined"
                    color="primary"
                  />
                ))}
              </div>
            )}
          </div>
          <div>
            <Typography fontSize="14px" fontWeight="500" color="text.secondary">
              {t("GroupLevel")}
            </Typography>
            {data.groupLevels.length === 0 ? (
              <Chip label={t("All")} variant="outlined" color="primary" />
            ) : (
              <div style={{ display: "flex", gap: "8px" }}>
                {data.groupLevels.map((item) => (
                  <Chip
                    label={item.groupLevelName}
                    variant="outlined"
                    color="primary"
                  />
                ))}
              </div>
            )}
          </div>
        </Stack>
        <Divider sx={{ margin: "16px 0px" }} />
        <Stack spacing={2}>
          {data.questions.map((question, index) => (
            <div>
              <Typography fontSize={16} fontWeight={500}>
                {`${t("Question")} ${index + 1}: ${question.question}`}
              </Typography>
              <Typography
                fontSize="14px"
                fontWeight="500"
                color="text.secondary"
              >
                {`${t("QuestionType")}: ${question.questionType}`}
              </Typography>
            </div>
          ))}
        </Stack>
      </AccordionDetails>
    </AccordionStyled>
  );
};

const QuestionSet = ({ idCompany, handleChangeSelectQuestionSet }) => {
  const dispatch = useDispatch();
  const { result: performanceQuestion, isFetching } = useSelector(
    (state) => state.performanceQuestion
  );

  const handleUpdateQuestionSetStatus = async (formData) => {
    const res = await dispatch(updatePerformanceQuestionSetStatus(formData))
    if (res && res.status === 200) {
      dispatch(openNotificationAlert({ message: "success", type: "success" }));
    } else {
      dispatch(openNotificationAlert({ message: "error", type: "error" }));
    }
    dispatch(getAllPerformanceQuestionSet({ idCompany }));
  }

  useEffect(() => {
    if (idCompany) {
      dispatch(getAllPerformanceQuestionSet({ idCompany }));
    }
  }, [idCompany]);

  return (
    <div>
      {!isFetching &&
        performanceQuestion &&
        performanceQuestion.map((item) => (
          <AccordionQuestionSet
            data={item}
            handleChangeSelectQuestionSet={handleChangeSelectQuestionSet}
            handleUpdateQuestionSetStatus={handleUpdateQuestionSetStatus}
          />
        ))}
    </div>
  );
};

export default QuestionSet;
