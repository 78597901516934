import React, { Fragment, useState } from "react";
import { ArrowForwardIosSharp } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import dayjs from "dayjs";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import NumberFormatTheme from "../../shared/general/NumberFormatTheme";
import { useTranslation } from "react-i18next";

const AccordionStyled = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginBottom: 12,
  borderRadius: 8,
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummaryStyled = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderRadius: 8,
  marginBottom: 8,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .wrap": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const AccordionRow = (props) => {
  const { t, i18n } = useTranslation();
  const { data, setDrawerConfig, setDeleteConfig } = props;

  const [isExpand, setIsExpand] = useState(false);

  return (
    <AccordionStyled
      expanded={isExpand}
      onChange={() => {
        setIsExpand((prev) => !prev);
      }}
    >
      <AccordionSummaryStyled>
        <Box flexGrow="1" display="flex" alignItems="center">
          <Typography flexGrow="1">{data.rightsName}</Typography>
          <Box>
            <IconButton
              aria-label="edit"
              onClick={(e) => {
                e.stopPropagation();
                setDrawerConfig((prev) => ({
                  ...prev,
                  isOpen: true,
                  isEdit: true,
                  data: data,
                }));
              }}
            >
              <EditRoundedIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={(e) => {
                e.stopPropagation();
                setDeleteConfig((prev) => ({
                  ...prev,
                  isOpen: true,
                  data: {
                    idRightsManage: data.idRightsManage,
                    rightsName: data.rightsName,
                  },
                }));
              }}
            >
              <DeleteOutlineRoundedIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
      </AccordionSummaryStyled>
      <AccordionDetails>
        <Grid container spacing={2}>
          {data.principle === "totalLimit" && (
            <Grid marginTop={0.5} marginLeft={0.5} container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    paddingLeft: "8px",
                    borderLeft: "8px solid #46cbe2",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                  }}
                  fontSize="20px"
                  fontWeight="500"
                >
                  {t("CoverageLimit")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("EmploymentType")}
                </Typography>
                <Typography>
                  {data.hiringType ? data.hiringType : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  Level Group
                </Typography>
                <Typography>
                  {data.levelGroup ? data.levelGroup : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("BudgetLimit")}
                </Typography>
                <Typography>
                  {data.limit
                    ? data.limit.toLocaleString() + ` ${t("Baht")}`
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("ExcessAssistance")}
                </Typography>
                <Typography>
                  {data.isHelpingSurplus === 1 ? `${t("Yes")}` : `${t("No")}`}
                </Typography>
              </Grid>
              <Grid marginLeft={1} item xs={12} md={12}>
                {data.isHelpingSurplus === 1 && (
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        paddingLeft: "8px",
                        borderLeft: "8px solid #46cbe2",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                      fontSize="15px"
                      fontWeight="500"
                    >
                      {t("ExcessTiers")}
                    </Typography>
                  </Grid>
                )}
                {data.isHelpingSurplus === 1 &&
                  data.Surplus &&
                  data.Surplus.map((item, index) => (
                    <Grid
                      marginTop={0.5}
                      item
                      key={`surplus${item.idSurplus}`}
                      container
                      xs={12}
                    >
                      <Grid marginTop={0.5} item xs={12} md={6}>
                        <Typography
                          fontSize="14px"
                          fontWeight="500"
                          color="text.secondary"
                        >{`${t("ExcessTier")} ${index + 1}`}</Typography>
                        <Typography>
                          {item.numberSurplus
                            ? item.numberSurplus.toLocaleString()
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid marginTop={0.5} item xs={12} md={6}>
                        <Typography
                          fontSize="14px"
                          fontWeight="500"
                          color="text.secondary"
                        >
                          {t("AdditionalAssistanceCompany")}(%)
                        </Typography>
                        <Typography>
                          {item.surplusPercent ? item.surplusPercent : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          )}
          {data.principle === "individualLimit" && (
            <Grid marginTop={0.5} marginLeft={0.5} container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    paddingLeft: "8px",
                    borderLeft: "8px solid #46cbe2",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                  }}
                  fontSize="20px"
                  fontWeight="500"
                >
                  {t("individualLimit")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("EmploymentType")}
                </Typography>
                <Typography>
                  {data.hiringType ? data.hiringType : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  LevelGroup
                </Typography>
                <Typography>
                  {data.levelGroup ? data.levelGroup : "-"}
                </Typography>
              </Grid>
              {/* พนักงาน */}
              <Grid item xs={12}>
                <Typography
                  sx={{
                    paddingLeft: "8px",
                    borderLeft: "8px solid #46cbe2",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                  }}
                  fontSize="16px"
                  fontWeight="500"
                >
                  {`พนักงาน`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("BudgetLimit")}
                </Typography>
                <Typography>
                  {data.limitSelf
                    ? data.limitSelf.toLocaleString() + ` ${t("Baht")}`
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("ExcessAssistance")}
                </Typography>
                <Typography>
                  {data.isHelpingSurplusSelf === 1
                    ? `${t("Yes")}`
                    : `${t("No")}`}
                </Typography>
              </Grid>
              <Grid marginLeft={1} item xs={12} md={12}>
                {data.isHelpingSurplusSelf === 1 && (
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        paddingLeft: "8px",
                        borderLeft: "8px solid #46cbe2",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                      fontSize="15px"
                      fontWeight="500"
                    >
                      {t("ExcessTiers")}
                    </Typography>
                  </Grid>
                )}
                {data.isHelpingSurplusSelf === 1 &&
                  data.SurplusSelf &&
                  data.SurplusSelf.map((item, index) => (
                    <Grid
                      marginTop={0.5}
                      item
                      key={`surplus${item.idSurplusSelf}`}
                      container
                      xs={12}
                    >
                      <Grid marginTop={0.5} item xs={12} md={6}>
                        <Typography
                          fontSize="14px"
                          fontWeight="500"
                          color="text.secondary"
                        >{`${t("ExcessTier")} ${index + 1}`}</Typography>
                        <Typography>
                          {item.numberSurplus
                            ? item.numberSurplus.toLocaleString()
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid marginTop={0.5} item xs={12} md={6}>
                        <Typography
                          fontSize="14px"
                          fontWeight="500"
                          color="text.secondary"
                        >
                          {t("AdditionalAssistanceCompany")}(%)
                        </Typography>
                        <Typography>
                          {item.surplusPercent ? item.surplusPercent : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
              {/* คู่สมรส */}
              <Grid item xs={12}>
                <Typography
                  sx={{
                    paddingLeft: "8px",
                    borderLeft: "8px solid #46cbe2",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                  }}
                  fontSize="16px"
                  fontWeight="500"
                >
                  {t("Spouse")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("BudgetLimit")}
                </Typography>
                <Typography>
                  {data.limitCouple
                    ? data.limitCouple.toLocaleString() + ` ${t("Baht")}`
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("ExcessAssistance")}
                </Typography>
                <Typography>
                  {data.isHelpingSurplusCouple === 1
                    ? `${t("Yes")}`
                    : `${t("No")}`}
                </Typography>
              </Grid>
              <Grid marginLeft={1} item xs={12} md={12}>
                {data.isHelpingSurplusCouple === 1 && (
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        paddingLeft: "8px",
                        borderLeft: "8px solid #46cbe2",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                      fontSize="15px"
                      fontWeight="500"
                    >
                      {t("ExcessTiers")}
                    </Typography>
                  </Grid>
                )}
                {data.isHelpingSurplusCouple === 1 &&
                  data.SurplusCouple &&
                  data.SurplusCouple.map((item, index) => (
                    <Grid
                      marginTop={0.5}
                      item
                      key={`surplus${item.idSurplusCouple}`}
                      container
                      xs={12}
                    >
                      <Grid marginTop={0.5} item xs={12} md={6}>
                        <Typography
                          fontSize="14px"
                          fontWeight="500"
                          color="text.secondary"
                        >{`${t("ExcessTier")} ${index + 1}`}</Typography>
                        <Typography>
                          {item.numberSurplus
                            ? item.numberSurplus.toLocaleString()
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid marginTop={0.5} item xs={12} md={6}>
                        <Typography
                          fontSize="14px"
                          fontWeight="500"
                          color="text.secondary"
                        >
                          {t("AdditionalAssistanceCompany")}(%)
                        </Typography>
                        <Typography>
                          {item.surplusPercent ? item.surplusPercent : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
              {/* บิดามารดา */}
              <Grid item xs={12}>
                <Typography
                  sx={{
                    paddingLeft: "8px",
                    borderLeft: "8px solid #46cbe2",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                  }}
                  fontSize="16px"
                  fontWeight="500"
                >
                  {t("Parent")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("BudgetLimit")}
                </Typography>
                <Typography>
                  {data.limitParents
                    ? data.limitParents.toLocaleString() + ` ${t("Baht")}`
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("ExcessAssistance")}
                </Typography>
                <Typography>
                  {data.isHelpingSurplusParents === 1
                    ? `${t("Yes")}`
                    : `${t("No")}`}
                </Typography>
              </Grid>
              <Grid marginLeft={1} item xs={12} md={12}>
                {data.isHelpingSurplusParents === 1 && (
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        paddingLeft: "8px",
                        borderLeft: "8px solid #46cbe2",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                      fontSize="15px"
                      fontWeight="500"
                    >
                      {t("ExcessTiers")}
                    </Typography>
                  </Grid>
                )}
                {data.isHelpingSurplusParents === 1 &&
                  data.SurplusParents &&
                  data.SurplusParents.map((item, index) => (
                    <Grid
                      marginTop={0.5}
                      item
                      key={`surplus${item.idSurplusParents}`}
                      container
                      xs={12}
                    >
                      <Grid marginTop={0.5} item xs={12} md={6}>
                        <Typography
                          fontSize="14px"
                          fontWeight="500"
                          color="text.secondary"
                        >{`${t("ExcessTier")} ${index + 1}`}</Typography>
                        <Typography>
                          {item.numberSurplus
                            ? item.numberSurplus.toLocaleString()
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid marginTop={0.5} item xs={12} md={6}>
                        <Typography
                          fontSize="14px"
                          fontWeight="500"
                          color="text.secondary"
                        >
                          {t("AdditionalAssistanceCompany")}(%)
                        </Typography>
                        <Typography>
                          {item.surplusPercent ? item.surplusPercent : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
              {/* บุตร */}
              <Grid item xs={12}>
                <Typography
                  sx={{
                    paddingLeft: "8px",
                    borderLeft: "8px solid #46cbe2",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                  }}
                  fontSize="16px"
                  fontWeight="500"
                >
                  {t("Child")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("BudgetLimit")}
                </Typography>
                <Typography>
                  {data.limitChild
                    ? data.limitChild.toLocaleString() + ` ${t("Baht")}`
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("ExcessAssistance")}
                </Typography>
                <Typography>
                  {data.isHelpingSurplusChild === 1
                    ? `${t("Yes")}`
                    : `${t("No")}`}
                </Typography>
              </Grid>
              <Grid marginLeft={1} item xs={12} md={12}>
                {data.isHelpingSurplusChild === 1 && (
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        paddingLeft: "8px",
                        borderLeft: "8px solid #46cbe2",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                      fontSize="15px"
                      fontWeight="500"
                    >
                      {t("ExcessTiers")}
                    </Typography>
                  </Grid>
                )}
                {data.isHelpingSurplusChild === 1 &&
                  data.SurplusChild &&
                  data.SurplusChild.map((item, index) => (
                    <Grid
                      marginTop={0.5}
                      item
                      key={`surplus${item.idSurplusChild}`}
                      container
                      xs={12}
                    >
                      <Grid marginTop={0.5} item xs={12} md={6}>
                        <Typography
                          fontSize="14px"
                          fontWeight="500"
                          color="text.secondary"
                        >{`${t("ExcessTier")} ${index + 1}`}</Typography>
                        <Typography>
                          {item.numberSurplus
                            ? item.numberSurplus.toLocaleString()
                            : "-"}
                        </Typography>
                      </Grid>
                      <Grid marginTop={0.5} item xs={12} md={6}>
                        <Typography
                          fontSize="14px"
                          fontWeight="500"
                          color="text.secondary"
                        >
                          {t("AdditionalAssistanceCompany")}(%)
                        </Typography>
                        <Typography>
                          {item.surplusPercent ? item.surplusPercent : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          )}

          {data.principle === "detail" && (
            <Grid marginTop={0.5} marginLeft={0.5} container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    paddingLeft: "8px",
                    borderLeft: "8px solid #46cbe2",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                  }}
                  fontSize="20px"
                  fontWeight="500"
                >
                  {t("Detailed")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("EmploymentType")}
                </Typography>
                <Typography>
                  {data.hiringType ? data.hiringType : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  LevelGroup
                </Typography>
                <Typography>
                  {data.levelGroup ? data.levelGroup : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("For")}
                </Typography>
                <Typography>
                  {data.UseForWho &&
                    data.UseForWho.map((who) => (
                      <Chip
                        sx={{ fontSize: "12px" }}
                        key={who.idUserForWho}
                        label={who.relationName}
                      />
                    ))}
                </Typography>
              </Grid>
              {/* OPD */}
              <Grid item xs={12}>
                <Typography
                  sx={{
                    paddingLeft: "8px",
                    borderLeft: "8px solid #46cbe2",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                  }}
                  fontSize="15px"
                  fontWeight="500"
                >
                  OPD
                </Typography>
              </Grid>
              {data.OPD_Principle === "OPD_totalLimit" && (
                <>
                  <Grid item xs={12} md={6}>
                    <Typography
                      fontSize="14px"
                      fontWeight="500"
                      color="text.secondary"
                    >
                      {t("BudgetLimit")}
                    </Typography>
                    <Typography>
                      {data.limitOPD
                        ? data.limitOPD.toLocaleString() + ` ${t("Baht")}`
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      fontSize="14px"
                      fontWeight="500"
                      color="text.secondary"
                    >
                      {t("ExcessAssistance")}
                    </Typography>
                    <Typography>
                      {data.isHelpingSurplusOPD === 1
                        ? `${t("Yes")}`
                        : `${t("No")}`}
                    </Typography>
                  </Grid>
                  <Grid marginLeft={1} item xs={12} md={12}>
                    {data.isHelpingSurplusOPD === 1 && (
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            paddingLeft: "8px",
                            borderLeft: "8px solid #46cbe2",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                          }}
                          fontSize="15px"
                          fontWeight="500"
                        >
                          {t("ExcessTiers")}
                        </Typography>
                      </Grid>
                    )}
                    {data.isHelpingSurplusOPD === 1 &&
                      data.SurplusOPD &&
                      data.SurplusOPD.map((item, index) => (
                        <Grid
                          marginTop={0.5}
                          item
                          key={`surplus${item.idSurplusOPD}`}
                          container
                          xs={12}
                        >
                          <Grid marginTop={0.5} item xs={12} md={6}>
                            <Typography
                              fontSize="14px"
                              fontWeight="500"
                              color="text.secondary"
                            >{`${t("ExcessTier")} ${index + 1}`}</Typography>
                            <Typography>
                              {item.numberSurplus
                                ? item.numberSurplus.toLocaleString()
                                : "-"}
                            </Typography>
                          </Grid>
                          <Grid marginTop={0.5} item xs={12} md={6}>
                            <Typography
                              fontSize="14px"
                              fontWeight="500"
                              color="text.secondary"
                            >
                              {t("AdditionalAssistanceCompany")}(%)
                            </Typography>
                            <Typography>
                              {item.surplusPercent ? item.surplusPercent : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                </>
              )}
              {data.OPD_Principle === "OPD_number" && (
                <>
                  <Grid item xs={12} md={6}>
                    <Typography
                      fontSize="14px"
                      fontWeight="500"
                      color="text.secondary"
                    >
                      {t("NumberOfTimes")}
                    </Typography>
                    <Typography>
                      {data.OPD_Number ? data.OPD_Number : "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      fontSize="14px"
                      fontWeight="500"
                      color="text.secondary"
                    >
                      {t("BudgetLimitPerTime")}
                    </Typography>
                    <Typography>
                      {data.OPD_MoneyPerTimes ? data.OPD_MoneyPerTimes : "-"}
                    </Typography>
                  </Grid>
                </>
              )}

              {/* IPD */}
              <Grid item xs={12}>
                <Typography
                  sx={{
                    paddingLeft: "8px",
                    borderLeft: "8px solid #46cbe2",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                  }}
                  fontSize="15px"
                  fontWeight="500"
                >
                  IPD
                </Typography>
              </Grid>
              {data.IPD_Principle === "IPD_totalLimit" && (
                <>
                  <Grid item xs={12} md={6}>
                    <Typography
                      fontSize="14px"
                      fontWeight="500"
                      color="text.secondary"
                    >
                      {t("BudgetLimit")}({t("PerTime")})
                    </Typography>
                    <Typography>
                      {data.limitIPD ? data.limitIPD : "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      fontSize="14px"
                      fontWeight="500"
                      color="text.secondary"
                    >
                      {t("ExcessAssistance")}
                    </Typography>
                    <Typography>
                      {data.isHelpingSurplusIPD === 1
                        ? `${t("Yes")}`
                        : `${t("No")}`}
                    </Typography>
                  </Grid>
                  <Grid marginLeft={1} item xs={12} md={12}>
                    {data.isHelpingSurplusIPD === 1 && (
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            paddingLeft: "8px",
                            borderLeft: "8px solid #46cbe2",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                          }}
                          fontSize="15px"
                          fontWeight="500"
                        >
                          {t("ExcessTiers")}
                        </Typography>
                      </Grid>
                    )}
                    {data.isHelpingSurplusIPD === 1 &&
                      data.SurplusIPD &&
                      data.SurplusIPD.map((item, index) => (
                        <Grid
                          marginTop={0.5}
                          item
                          key={`surplus${item.idSurplusIPD}`}
                          container
                          xs={12}
                        >
                          <Grid marginTop={0.5} item xs={12} md={6}>
                            <Typography
                              fontSize="14px"
                              fontWeight="500"
                              color="text.secondary"
                            >{`${t("ExcessTier")} ${index + 1}`}</Typography>
                            <Typography>
                              {item.numberSurplus
                                ? item.numberSurplus.toLocaleString()
                                : "-"}
                            </Typography>
                          </Grid>
                          <Grid marginTop={0.5} item xs={12} md={6}>
                            <Typography
                              fontSize="14px"
                              fontWeight="500"
                              color="text.secondary"
                            >
                              {t("AdditionalAssistanceCompany")}(%)
                            </Typography>
                            <Typography>
                              {item.surplusPercent ? item.surplusPercent : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                </>
              )}
              {data.IPD_Principle === "IPD_detail" && (
                <Grid
                  marginLeft={24}
                  item
                  xs={12}
                  md={12}
                  /* display={"flex"} */ justifyContent={"center"}
                >
                  {data.IPD &&
                    data.IPD.map((item, index) => (
                      <Grid
                        marginTop={0.5}
                        item
                        key={`IPD${item.idIPD}`}
                        container
                        xs={12}
                      >
                        <Grid marginTop={0.5} item xs={12} md={4}>
                          <Typography
                            marginTop={3.1}
                            fontSize="14px"
                            fontWeight="500"
                          >{`IPD${String(index + 1).padStart(
                            3,
                            "0"
                          )}`}</Typography>
                        </Grid>
                        <Grid marginTop={0.5} item xs={12} md={4}>
                          <Typography
                            fontSize="14px"
                            fontWeight="500"
                            color="text.secondary"
                          >
                            {t("TitleName")}
                          </Typography>
                          <Typography fontSize="14px" fontWeight="500">
                            {item.nameList ? item.nameList : "-"}
                          </Typography>
                        </Grid>
                        <Grid marginTop={0.5} item xs={12} md={4}>
                          <Typography
                            fontSize="14px"
                            fontWeight="500"
                            color="text.secondary"
                          >
                            {t("BudgetLimit")}
                          </Typography>
                          <Typography fontSize="14px" fontWeight="500">
                            {item.limitList ? item.limitList : "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              )}
              {/* Dental */}
              <Grid item xs={12}>
                <Typography
                  sx={{
                    paddingLeft: "8px",
                    borderLeft: "8px solid #46cbe2",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                  }}
                  fontSize="15px"
                  fontWeight="500"
                >
                  Dental
                </Typography>
              </Grid>
              {data.IPD_Principle === "Dental_totalLimit" && (
                <>
                  <Grid item xs={12} md={6}>
                    <Typography
                      fontSize="14px"
                      fontWeight="500"
                      color="text.secondary"
                    >
                      {t("BudgetLimit")}({t("PerTime")})
                    </Typography>
                    <Typography>
                      {data.limitDental ? data.limitDental : "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      fontSize="14px"
                      fontWeight="500"
                      color="text.secondary"
                    >
                      {t("ExcessAssistance")}
                    </Typography>
                    <Typography>
                      {data.isHelpingSurplusDental === 1
                        ? `${t("Yes")}`
                        : `${t("No")}`}
                    </Typography>
                  </Grid>
                  <Grid marginLeft={1} item xs={12} md={12}>
                    {data.isHelpingSurplusDental === 1 && (
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            paddingLeft: "8px",
                            borderLeft: "8px solid #46cbe2",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                          }}
                          fontSize="15px"
                          fontWeight="500"
                        >
                          {t("ExcessTiers")}
                        </Typography>
                      </Grid>
                    )}
                    {data.isHelpingSurplusDental === 1 &&
                      data.SurplusDental &&
                      data.SurplusDental.map((item, index) => (
                        <Grid
                          marginTop={0.5}
                          item
                          key={`surplus${item.idSurplusDental}`}
                          container
                          xs={12}
                        >
                          <Grid marginTop={0.5} item xs={12} md={6}>
                            <Typography
                              fontSize="14px"
                              fontWeight="500"
                              color="text.secondary"
                            >{`${t("ExcessTier")} ${index + 1}`}</Typography>
                            <Typography>
                              {item.numberSurplus
                                ? item.numberSurplus.toLocaleString()
                                : "-"}
                            </Typography>
                          </Grid>
                          <Grid marginTop={0.5} item xs={12} md={6}>
                            <Typography
                              fontSize="14px"
                              fontWeight="500"
                              color="text.secondary"
                            >
                              {t("AdditionalAssistanceCompany")}(%)
                            </Typography>
                            <Typography>
                              {item.surplusPercent ? item.surplusPercent : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                </>
              )}
              {data.Dental_Principle === "Dental_detail" && (
                <Grid
                  marginLeft={24}
                  item
                  xs={12}
                  md={12}
                  /* display={"flex"} */ justifyContent={"center"}
                >
                  {data.Dental &&
                    data.Dental.map((item, index) => (
                      <Grid
                        marginTop={0.5}
                        item
                        key={`Dental${item.idDental}`}
                        container
                        xs={12}
                      >
                        <Grid marginTop={0.5} item xs={12} md={4}>
                          <Typography
                            marginTop={3.1}
                            fontSize="14px"
                            fontWeight="500"
                            color="text.secondary"
                          ></Typography>
                          <Typography
                            fontSize="14px"
                            fontWeight="500"
                          >{`DT${String(index + 1).padStart(
                            3,
                            "0"
                          )}`}</Typography>
                        </Grid>
                        <Grid marginTop={0.5} item xs={12} md={4}>
                          <Typography
                            fontSize="14px"
                            fontWeight="500"
                            color="text.secondary"
                          >
                            {t("TitleName")}
                          </Typography>
                          <Typography fontSize="14px" fontWeight="500">
                            {item.nameList ? item.nameList : "-"}
                          </Typography>
                        </Grid>
                        <Grid marginTop={0.5} item xs={12} md={4}>
                          <Typography
                            fontSize="14px"
                            fontWeight="500"
                            color="text.secondary"
                          >
                            {t("BudgetLimit")}
                          </Typography>
                          <Typography fontSize="14px" fontWeight="500">
                            {item.limitList ? item.limitList : "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </AccordionStyled>
  );
};

export default AccordionRow;
