import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  styled,
  Grid,
  Typography,
  Box,
  FilledInput,
  Paper,
  Avatar,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack
} from "@mui/material";
import dayjs from "dayjs";
import SearchIcon from "@mui/icons-material/Search";
import ButtonBlue from '../../shared/general/ButtonBlue';
import TextFieldTheme from '../../shared/general/TextFieldTheme';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import TalentScoreChart from "./listSuccessorInfo/talentScoreChart";

import { clearAttendance } from "../../../../actions/attendance";
import SelectEmployees from "../shared/selectEmployees";
import { postSuccessor } from "../../../../actions/successor";
import { getPerformanceTalentAndPotentialByEmp } from "../../../../actions/employee";
import { getUserCompany, getUserDepartment, getUserDivision, getUserFullName, getUserPosition, getUserSection } from "../../../../utils/userData";

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  width: 450,
  [theme.breakpoints.down('sm')]: {
    width: 270
  },
}));


const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  width: "230%",
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "flex-end",
  marginBottom: 36,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});

const StyledRight = styled("div")({
  "& .number-successor": {
    color: "black",
    fontSize: "18px",
    fontWeight: 600,
    marginTop: "10px",
    textAlign: "left",
    marginLeft: 10,
  },
  "& .icon-header": {
    marginTop: "10px",
    textAlign: "right",
    marginLeft: 10,
  },
  "& .header-profile": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 10,
    marginTop: "10px",

    "& .empHeadingText": {
      color: "black",
      fontSize: "18px",
      fontWeight: 500,
      alignSelf: "center",
    },
    "& .empPositionText": {
      color: "gray ",
      fontSize: "1rem",
      fontWeight: 300,
      textAlign: "left",
    },
    "& .empImage": {
      minHeight: "80px",
      minWidth: "80px",
      alignSelf: "center",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      outline: "3px solid white",
      marginBottom: "5px",
    },
    "& .styleButtonReady": {
      textAlign: "center",
      color: "white",
      backgroundColor: "#46e2ba",
      borderRadius: 20,
      marginTop: "3px",
      marginRight: "120px",
      padding: "1px",
      cursor: "pointer",
    },
    "& .styleButtonNotReady": {
      textAlign: "center",
      color: "white",
      backgroundColor: "#e24668",
      borderRadius: 20,
      marginTop: "3px",
      marginRight: "120px",
      padding: "1px",
      cursor: "pointer",
    },
  },
  "& .content-profile": {
    direction: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "10px 10px 10px calc(10px + 0.75em)",

    "& .textTopic": {
      color: "gray ",
      fontSize: "0.8rem",
      fontWeight: 300,
      textAlign: "left",
    },
    "& .textContent": {
      color: "black",
      fontSize: "0.8rem",
      fontWeight: 600,
      textAlign: "left",
    },
  },
});

const StyledFooter = styled("div")({
  "& .footer-profile": {
    display: "flex",
    direction: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 10px 10px calc(10px + 0.75em)",

    "& .footer-text-topic": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "gray ",
      fontSize: "0.8rem",
      fontWeight: 300,
      alignSelf: "center",
    },
    "& .styleBox": {
      width: "70px",
      border: "1.5px solid #46cbe2",
      borderRadius: 5,
      marginTop: "25px",
      "& .text-box": {
        textAlign: "center",
        fontSize: "1.2rem",
        fontWeight: 600,
      },
    },
  },
});

const DialogSuccessor = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [readiness, setReadiness] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [talentAndOther, setTalentAndOther] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const { open, onClose, idEmployeePosition, bg, checkedYear } = props;

  const handleSelectEmployee = (employee) => {
    dispatch(clearAttendance());
    setSelectedEmployee(employee);
  };

  const getPerformanceTalentAndPotentialData = async (idEmp) => {
    try {
      const response = await getPerformanceTalentAndPotentialByEmp(idEmp);
      if (response && response.data) {
        setTalentAndOther(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedEmployee && selectedEmployee.idEmployees) {
      getPerformanceTalentAndPotentialData(selectedEmployee.idEmployees)
    }
  }, [selectedEmployee]);

  const handleOnSubmit = async (employee) => {
    try {
      if (!employee || !employee.idEmployees) {
        console.error("Invalid employee data");
        return;
      }

      const formData = {
        idEmployees: employee.idEmployees,
        idEmployeePosition: idEmployeePosition || null,
        ready: readiness
      };

      const response = await postSuccessor(formData);

      if (response && response.status === 200) {
        window.location.reload();
      } else {
        setErrorMessage(true);
      }
    } catch (error) {
      console.error("Error posting successor:", error);
      setErrorMessage(true);
    }
  };

  const closeErrorMessage = () => {
    setReadiness(null);
    setErrorMessage(false);
    setSelectedEmployee(null);
  }

  const findTheLastTalent = talentAndOther && talentAndOther.talent && talentAndOther.talent.find(talent => talent.year === checkedYear);

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <StyledDialogContent>
        <Typography variant="h6">{t("Select")} Successor</Typography>

        {errorMessage ? (
          <Box sx={{
            display: 'flex',
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: "#ffcdd2",
            p: 1, mt: 2,
            borderRadius: 2
          }}>
            <Typography fontSize={14} color={"error"} sx={{ ml: 2 }}>
              {t("AddedEmployees")}
            </Typography>
            <Button
              variant="text"
              onClick={() => closeErrorMessage()}
              color="error"
            >
              {t("Close")}
            </Button>
          </Box>
        ) : (
          <StyledWrapFilter>
            <Grid container spacing={2} columns={14} alignItems="center">
              <Grid item xs={6}>
                <StyledBoxSearch>
                  <SelectEmployees
                    handleChange={handleSelectEmployee}
                  />
                </StyledBoxSearch>
              </Grid>
            </Grid>
          </StyledWrapFilter>
        )}

        <Paper
          style={{
            minHeight: "auto",
            padding: 10,
            borderRadius: 20,
            marginTop: 20,
          }}
        >
          <div>
            <StyledRight>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="number-successor">Successor</div>
                <div className="icon-header">
                </div>
              </div>
              <div className="header-profile">
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Avatar
                      variant="circle"
                      className={"empImage"}
                    ></Avatar>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography className="empHeadingText">
                      {selectedEmployee && getUserFullName(selectedEmployee)}
                    </Typography>
                    <Typography className="empPositionText">
                      {selectedEmployee && getUserPosition(selectedEmployee)}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <Divider />
              <Grid container spacing={2} className="content-profile">
                {/* First Row */}
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <span className="textTopic">{t("Company")} : </span>
                    <span className="textContent">{selectedEmployee && getUserCompany(selectedEmployee)}</span>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <span className="textTopic">{t("Division")} : </span>
                    <span className="textContent">{selectedEmployee && getUserDivision(selectedEmployee)}</span>
                  </Typography>
                </Grid>

                {/* Second Row */}
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <span className="textTopic">{t("Department")} : </span>
                    <span className="textContent">{selectedEmployee && getUserDepartment(selectedEmployee)}</span>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <span className="textTopic">{t("Section")} : </span>
                    <span className="textContent">{selectedEmployee && getUserSection(selectedEmployee)}</span>
                  </Typography>
                </Grid>

                {/* Third Row */}
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <span className="textTopic">{t("Performance")} : </span>
                    <span className="textContent">
                      {Array.from({ length: 3 }, (_, i) => {
                        const year = checkedYear - i;
                        const performanceForYear = talentAndOther && talentAndOther.performance && talentAndOther.performance.find(
                          (performance) => performance.yearPerformance === year
                        );
                        return (
                          <>
                            {performanceForYear ? (
                              <>
                                {performanceForYear.name + " "}
                              </>
                            ) : null}
                          </>
                        );
                      })}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <span className="textTopic">{t("Potential")} : </span>
                    <span className="textContent">
                      {Array.from({ length: 3 }, (_, i) => {
                        const year = checkedYear - i;
                        const potentialYear = talentAndOther && talentAndOther.potential && talentAndOther.potential.find(
                          (potential) => potential.year === year
                        );
                        return (
                          <>
                            {potentialYear ? (
                              <>
                                {(
                                  potentialYear.Level === "Very high" ? "VH" :
                                    potentialYear.Level === "High" ? "H" :
                                      potentialYear.Level === "Normal" ? "N" : "L") + " "}
                              </>
                            ) : null}
                          </>
                        );
                      })}
                    </span>
                  </Typography>
                </Grid>
              </Grid>

              {findTheLastTalent && (
                <Divider />
              )}

              <StyledFooter>
                <Grid container spacing={2} className="footer-profile">
                  <Grid item container spacing={2} justifyContent={"center"} alignItems={"center"}>
                    {findTheLastTalent ? (
                      <Grid item xs={6}>
                        <Typography className="footer-text-topic">
                          {t("talentResult")}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            className="styleBox"
                            sx={{
                              p: 2,
                              bgcolor: findTheLastTalent.talentName ? bg[findTheLastTalent.talentName] : 'transparent'
                            }}
                          >
                            <Typography className="text-box">
                              {findTheLastTalent.talentName}
                            </Typography>
                          </Box>
                        </div>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </StyledFooter>
            </StyledRight>
          </div>
        </Paper>

        <Grid item xl={10} lg={5} md={10} sm={12} xs={12} sx={{ marginTop: 2 }}>
          <FormControl fullWidth>
            <InputLabel>{t("Readiness")}</InputLabel>
            <Select
              name="ready"
              label={t("Readiness")}
              sx={{ width: '120%' }}
              onChange={(e) => setReadiness(e.target.value)}
            >
              <MenuItem value="พร้อมทันที">{t("readyImmediately")}</MenuItem>
              <MenuItem value="พร้อมภายใน 1 ปี">{t("readyWithinOneYear")}</MenuItem>
              <MenuItem value="พร้อมภายใน 2 ปี">{t("readyWithinTwoYear")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <div style={{ width: "100%", marginTop: 16, display: "flex", justifyContent: "space-between" }}>
          <ButtonBlue onClick={onClose}>{t("Cancel")}</ButtonBlue>
          <ButtonBlue
            variant="contained"
            onClick={() => handleOnSubmit(selectedEmployee)}
            disabled={!selectedEmployee || !readiness || errorMessage}
          >
            {t("Confirm")}
          </ButtonBlue>
        </div>
      </StyledDialogContent>
    </Dialog>
  )
}

export default DialogSuccessor;

{/* <Grid item xs={6}>
                      <Typography className="footer-text-topic">
                        Talent Score
                      </Typography>
                      <TalentScoreChart />
                    </Grid> */}