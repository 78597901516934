import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
  styled,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import dayjs from "dayjs";
import ChipWarningLevel from "../../admin/warningLetter/chipWarningLevel";
import ButtonBlue from "../../shared/general/ButtonBlue";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserDepartment, getUserFullName, getUserPosition, getUserSection, getUserTitle } from "../../../../utils/userData";

const StyledDivider = styled(Divider)({
  marginTop: "16px",
  borderColor: "rgba(145, 158, 171, 1)",
  borderStyle: "dashed",
  width: "100%",
});

const DialogDetail = ({ open, handleClose, data, handleUpdateStatus }) => {
  const [selectAccept, setSelectAccept] = useState(1);
  const { t, i18n } = useTranslation();

  const handleChange = (event) => {
    setSelectAccept(event.target.value);
  };

  const handleAccept = () => {
    handleUpdateStatus({
      idWarningLetter: data.idWarningLetter,
      isAccept: selectAccept,
    });
  };

  useEffect(() => {
    if (data.isAccept !== null) {
      setSelectAccept(data.isAccept);
    }
  }, []);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth={"sm"}>
      <DialogTitle textAlign={"center"}>{`${t("WarningLetter")}`}</DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              variant="subtitle1"
              color="text.secondary"
              textAlign={"end"}
            >
              {`${t("warningLetterDate")}: ${dayjs(data.createDate).format(
                "DD/MM/YYYY"
              )}`}
            </Typography>
            <Typography gutterBottom>
              {`${t("DearMrMs")} ${getUserFullName(data)}`}
            </Typography>
            {data.positionName && (
              <Typography>{`${t("Position")}: ${getUserPosition(data)} `}</Typography>
            )}
            {data.departmentName && (
              <Typography>{`${t("Department")}: ${getUserDepartment(data)}`}</Typography>
            )}
            {data.sectionName && (
              <Typography>{`${t("Section")}: ${getUserSection(data)}`}</Typography>
            )}
            {data.employeeID && (
              <Typography gutterBottom>
                {`${t("EmployeeID")}: ${data.employeeID}`}
              </Typography>
            )}
            <Grid item xs={12}>
              <Typography gutterBottom>
                {`${t("youHaveBeenWarned")} ${data.title}`}
              </Typography>
              <Typography>{`${t("dueToTheFact")} ${data.detail}`}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {
                t("companyWarningIssued")
              }
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            alignItems={"center"}
            gap={2}
            flexWrap={"wrap"}
          >
            <Typography>{t("iAcknowledgeTheAbove")}</Typography>
            <FormControl>
              <InputLabel>{t("options")}</InputLabel>
              <Select
                value={selectAccept}
                label={t("options")}
                onChange={handleChange}
              >
                <MenuItem value={1}>{t("Accept")}</MenuItem>
                <MenuItem value={0}>{t("Refusal")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <StyledDivider />
          <Grid
            item
            xs={12}
            display={"flex"}
            alignItems={"center"}
            gap={2}
            flexWrap={"wrap"}
          >
            <Typography fontSize={"1.25rem"} fontWeight={500}>
              {t("WarningLevel")}
            </Typography>
            <ChipWarningLevel id={data.idWarningLevel} label={data.level} />
            {data.file && (
              <Grid item xs={12} sm={3}>
                <Button
                  variant="outlined"
                  component={Link}
                  href={data.file}
                  target="_blank"
                  startIcon={<AttachFileIcon />}
                >
                  {t("AttachFile")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonBlue variant="contained" onClick={handleAccept}>
          {t("Confirm")}
        </ButtonBlue>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDetail;
