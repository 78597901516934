import {
  INSTITUTE_DETAIL_FETCHING,
  INSTITUTE_DETAIL_FAILED,
  INSTITUTE_DETAIL_SUCCESS,
} from "./types";

import InstituteService from "../services/institute.service";

export const getAllInstitute = () => async (dispatch) => {
  try {
    const res = await InstituteService.getAllInstitute();
    if (res) {
      dispatch({
        type: INSTITUTE_DETAIL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: INSTITUTE_DETAIL_FAILED,
    });
    console.log(err);
  }
};
