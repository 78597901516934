import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Stack,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  TableCell,
  Avatar,
  Grid,
  Box,
  TextField,
  Tabs,
} from "@mui/material";
import dayjs from "dayjs";
import ButtonBlue from "../../shared/general/ButtonBlue";
import AdapterDateFns from "@tarzui/date-fns-be";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { th } from "date-fns/locale";
import CardStyle from "../../shared/general/Card";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../actions/employee";
import {getUsersTimeforApprove}  from "../../../../actions/workforceEmployees";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
const StyledRoot = styled("div")({
  backgroundColor: "#f5f5f5",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const WrapHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
  marginTop: 8,
  marginBottom: 16,
});



const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const TableProject = () => {
  const {t,i18n} = useTranslation();
  const dispatch = useDispatch();
  const { result: projectGetUserTimeforApprove } = useSelector(
    (state) => state.projectGetUserTimeforApprove
  );

  const [month, setMonth] = useState("");
  const today = dayjs().toDate();

  const [search, setSearch] = React.useState({
    startdate: dayjs(today).set("date", 1).format("YYYY-MM-DD"),
    enddate: dayjs(today)
      .set("date", dayjs(today).daysInMonth())
      .format("YYYY-MM-DD"),
  });
  const mobile = useMediaQuery('(max-width:600px)');
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setMonth(event.target.value);
    if(mobile){
      setValue(event.target.value);
    } else {
      setValue(newValue);
    }
  };
  
  const handleClickSearch = async () => {
    dispatch(getAllEmployees(search));
    dispatch(getUsersTimeforApprove(search));
  }; 

  useEffect(() => {
    dispatch(getAllEmployees(search));
    dispatch(getUsersTimeforApprove(search));
  }, [search]);

   

  return (
    <StyledRoot className={"page"}>
      <Container maxWidth="lg">
        <WrapHeader>
          <Typography variant="h4">ดูรายโปรเจก</Typography>
        </WrapHeader>
        <Box>
        <Grid container alignItems="flex-end" spacing={2}>
          <Grid item xs={12} sm={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  วันที่เริ่มต้น
                </Typography>
                <div className="search-date">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={th}
                  >
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      value={search.startdate}
                      name="startdate"
                      views={["year", "month", "day"]}
                      onChange={(newValue) => {
                        setSearch({
                          ...search,
                          startdate: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <StyledTextField
                          variant="filled"
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  วันที่สิ้นสุด
                </Typography>
                <div className="search-date">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={th}
                  >
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      value={search.enddate}
                      name="enddate"
                      views={["year", "month", "day"]}
                      onChange={(newValue) => {
                        setSearch({
                          ...search,
                          enddate: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <StyledTextField
                          variant="filled"
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={3}>
              <div style={{ paddingBottom: 12 }}>
                <ButtonBlue variant={"contained"} onClick={handleClickSearch}>
                  ค้นหา
                </ButtonBlue>
              </div>
                </Grid>
              </Grid>
            </Box>
            <br/> 
        <CardStyle style={{ padding: 20 }}>
          {projectGetUserTimeforApprove && projectGetUserTimeforApprove.length > 0 ? (
            <TableContainer component={Paper} >
              <Table sx={{ minWidth: 1200 }} aria-label="custom pagination table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">ชื่อโปรเจก</TableCell>
                    <TableCell align="center">วันเริ่มต้น</TableCell>
                    <TableCell align="center">วันสิ้นสุด</TableCell>
                    <TableCell align="center">เวลาทำงานรวม</TableCell>
                    <TableCell align="center">ชื่อพนักงาน</TableCell>
                    <TableCell align="center">อีเมล</TableCell>
                    <TableCell align="center">หัวหน้างานอนุมัติ</TableCell>
                    <TableCell align="center">วันที่อนุมัติ</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectGetUserTimeforApprove
                  .filter((item) => {
                    const startDateInRange = dayjs(item.startdate).isBetween(
                      dayjs(search.startdate),
                      dayjs(search.enddate),
                      null,
                      "[]"
                    );
                    const endDateInRange =
                      !item.enddate ||
                      dayjs(item.enddate).isBetween(
                        dayjs(search.startdate),
                        dayjs(search.enddate),
                        null,
                        "[]"
                      );
        
                    return startDateInRange || endDateInRange;
                  })
                    .map((item) => (
                      item.ManagerName !== null && (
                      <TableRow key={item.idEmployees}>
                        <TableCell align="center">
                          <Typography fontWeight={500} >
                                {item.projectname}
                              </Typography>
                        </TableCell>
                        <TableCell align="center"  searchDate={search} >
                          {dayjs(item.startdate).format("DD MMM YYYY")}
                        </TableCell>
                        <TableCell align="center"  searchDate={search} >
                          {item.enddate ? dayjs(item.enddate).format("DD MMM YYYY") : "ไม่ได้กำหนด"}
                        </TableCell>
                        <TableCell align="center">{item.Totaltime !== null ? item.Totaltime : 0} {t("Unit.ShortHours")}</TableCell>
                        <TableCell align="center">
                                {item.EmployeeName} 
                        </TableCell>
                        <TableCell align="center">{item.email}</TableCell>
                        <TableCell align="center">{item.ManagerName}</TableCell>
                        <TableCell align="center">
                          {dayjs(item.managerLV1ApproveDate).format("DD MMM YYYY")}
                        </TableCell>
                      </TableRow>
                    )
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  "& .MuiTab-root.Mui-selected": {
                    color: "#46cbe2",
                  },
                }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#46cbe2",
                  },
                }}
              >  
            <Typography>ไม่มีรายการ</Typography>
          </Tabs>
          )}
        </CardStyle>
      </Container>
    </StyledRoot>
  );
};

export default TableProject;