import React from "react";
import { useSelector } from "react-redux";

import RightsManage from "../../pages/admin-welfare/rights/RightsManage";
import MyWelfareRights from "../../pages/user/myWelfareRights";

const WelfareRights = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <div>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN_WELFARE") ? (
          <RightsManage />
        ) : (
          <MyWelfareRights />
        )
      ) : null}
    </div>
  );
};

export default WelfareRights;
