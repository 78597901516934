import { Avatar, Box, TablePagination, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import TableCustom from "../../shared/tableCustom";
import StatusRequest from "../../shared/general/stausRequest";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const RequestTimeShift = () => {
  const { result: employeeChangeShiftList } = useSelector(
    (state) => state.employeeChangeShift
  );
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);
  const [listPerPage, setListPerPage] = useState(10);

  const [historyRows, setHistoryRows] = useState([]);

  const shiftTimeHeader = [
    {
      name: `${t("Status")}`,
      minWidth: "32px",
      width: "32px",
      cellRender: (row) => (
        <StatusRequest
          boxSize={40}
          fontSize={22}
          borderRadius={8}
          status={row.isApprove === 0 ? 0 : 1}
        />
      ),
    },
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            style={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>
              {i18n.resolvedLanguage === "th"
                ? `${row.firstname_TH} ${row.lastname_TH}`
                : `${row.firstname_EN} ${row.lastname_EN}`}
            </Typography>
            <Typography color="text.third" fontSize="14px">
              {i18n.resolvedLanguage === "th"
                ? `${row.positionName}`
                : `${row.positionName_EN}`}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("Date")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box style={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.workingDateText, "DD/MM/YYYY").format("D MMM BBBB")}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("ShiftType")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box style={{ textAlign: "center" }}>
          {row.isWorkingDay === 1 ? (
            <Typography>{row.shiftGroupName}</Typography>
          ) : (
            <Typography>{`${t("DayOff")}`}</Typography>
          )}
        </Box>
      ),
    },
    {
      name: `${t("ApprovedDate")}`,
      minWidth: "150px",
      headerTextAlign: "center",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.approveDateText, "DD/MM/YYYY HH:mm").format(
              "D MMM BBBB"
            )}
          </Typography>
          <Typography>
            {dayjs(row.approveDateText, "DD/MM/YYYY HH:mm").format("HH:mm")}
          </Typography>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const tempHistory = [];

    employeeChangeShiftList.map((r) => {
      if (r.isApprove !== null) {
        tempHistory.push(r);
      }
    });

    setHistoryRows(
      tempHistory.sort(function (a, b) {
        return new Date(b.approveDate) - new Date(a.approveDate);
      })
    );
  }, []);

  return (
    <Box>
      <TableCustom
        columns={shiftTimeHeader}
        rows={employeeChangeShiftList ? 
          historyRows.filter((_, index) => {
            return (index >= (0 + (page * listPerPage)) && index < ((page + 1) * listPerPage));
          })
          : []
        }
      />
      <Box style={{ display:"flex", justifyContent: "right" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={employeeChangeShiftList ? 
            historyRows.length
            : 0}
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={listPerPage}
          onRowsPerPageChange={(event) => {
            setListPerPage(+event.target.value);
            setPage(0);
          }}
          labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${
                            count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
        />
      </Box>
    </Box>
  );
};

export default RequestTimeShift;
