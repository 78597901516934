import "dayjs/locale/th";
import ExcelJS from "exceljs";
import { getUserFullName, getUserPosition, getUserTitle } from "../../../../../utils/userData";

export const FunctionListMonthXlsx = async (t, type, data) => {
  let name = "";
  if (type === "departmentName") {
    name = t("Department");
  } else {
    name = t("Section");
  }
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(name);

  let additionName = new Set();
  let additionCode = new Set();
  let count = 1;

  const types = [...new Set(data.map((item) => item[type]))];

  data.forEach((index) => {
    index.payrunDetailAddition.forEach((item) => {
      additionName.add(`${item.name} (${item.code})`);
      additionCode.add(item.code);
    });
  });

  additionName = Array.from(additionName).sort(nameSort);
  additionCode = Array.from(additionCode).sort(codeSort);
  const additionLen = additionName.length;

  for (let i = 0; i < types.length; i++) {
    const nameRow = worksheet.addRow([`${name} : ${types[i]}`]);

    const nameStyle = {
      font: {
        bold: true,
        size: 24,
        name: "TH SarabunPSK",
        color: { argb: "000000" },
      },
      alignment: { vertical: "middle" },
    };

    nameRow.height = 60;
    nameRow.width = 60;
    nameRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      cell.style = nameStyle;
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
    });

    worksheet.mergeCells(`A${count}:C${count}`);

    const eachData = data.filter((item) => item[type] === types[i]);

    const headerRow = worksheet.addRow([
      t("No_Number"),
      t("FirstName"),
      t("Division"),
      t("Department"),
      t("Section"),
      t("Position"),
      `${t("Total")} OT (${t("Baht")})`,
      t("ShiftPayTotal"),
      ...additionName,
      `${t("TotalAdditionalMoney")} (${t("Calculate")}${t("Tax")})`,
      t("TotalIncome"),
      t("SocialSecurity"),
      t("Tax"),
      t("TotalDeduction"),
      t("NetIncome"),
    ]);

    count += 1;

    const headerLen = 14 + additionLen;

    headerRow.height = 50;

    const headerStyle1 = {
      font: {
        bold: true,
        size: 18,
        name: "TH SarabunPSK",
        color: { argb: "FFFFFF" },
      },
      alignment: { horizontal: "center", vertical: "middle" },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "002060" },
      },
    };

    const headerStyle2 = {
      font: {
        bold: true,
        size: 18,
        name: "TH SarabunPSK",
        color: { argb: "FFFFFF" },
      },
      alignment: { horizontal: "center", vertical: "middle" },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "C81A00" },
      },
    };

    const headerStyle3 = {
      font: {
        bold: true,
        size: 18,
        name: "TH SarabunPSK",
        color: { argb: "FFFFFF" },
      },
      alignment: { horizontal: "center", vertical: "middle" },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "00C81B" },
      },
    };

    const headerStyle4 = {
      font: {
        bold: true,
        size: 18,
        name: "TH SarabunPSK",
        color: { argb: "FFFFFF" },
      },
      alignment: { horizontal: "center", vertical: "middle" },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "69128B" },
      },
    };

    const headerStyles = [];

    for (let i = 0; i < headerLen; i++) {
      if (i < 9 + additionLen) {
        headerStyles.push(headerStyle1);
      } else if (i === 9 + additionLen) {
        headerStyles.push(headerStyle3);
      } else if (i > 9 + additionLen && i < headerLen - 2) {
        headerStyles.push(headerStyle1);
      } else if (i == headerLen - 2) {
        headerStyles.push(headerStyle2);
      } else {
        headerStyles.push(headerStyle4);
      }
    }

    headerRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      if (headerStyles[colNumber - 1]) {
        cell.style = headerStyles[colNumber - 1];
        cell.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
      }
    });

    const colWidths = [
      { key: "ลำดับที่", width: 20 },
      { key: "ชื่อ", width: 30 },
      { key: "ฝ่าย.", width: 20 },
      { key: "ส่วน", width: 20 },
      { key: "แผนก", width: 20 },
      { key: "ตำแหน่ง", width: 20 },
      { key: "รวม OT (บาท)", width: 20 },
      { key: "รวมค่ากะ", width: 20 },
      { key: "รวมเงินเพิ่ม (คำนวณภาษี)", width: 20 },
      { key: "รวมเงินได้", width: 20 },
      { key: "ประกันสังคม", width: 20 },
      { key: "ภาษี", width: 20 },
      { key: "รวมเงินหัก", width: 20 },
      { key: "เงินได้สุทธิ", width: 20 },
    ];

    additionName.forEach((item) => {
      colWidths.push({ key: item, width: 35 });
    });

    headerRow.height = 50;

    colWidths.forEach((col, index) => {
      worksheet.getColumn(index + 1).width = col.width;
    });

    eachData.forEach((item, index) => {
      const additionMap = new Map();
      item.payrunDetailAddition.forEach((addition) => {
        additionMap.set(addition.code, addition.value);
      });
      // console.log(addition)
      const row = [
        index + 1,
        `${getUserTitle(item)} ${getUserFullName(item)}`,
        item.divisionName ? item.divisionName : "",
        item.departmentName ? item.departmentName : "",
        item.sectionName ? item.sectionName : "",
        getUserPosition(item) ? getUserPosition(item) : "",
        item.totalOT
          ? item.totalOT.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0,
        item.totalShiftPay
          ? item.totalShiftPay.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0,
      ];

      additionCode.forEach((code) => {
        const value = additionMap.get(code);
        row.push(
          value !== undefined
            ? value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : 0
        );
      });

      row.push(
        item.additionsTaxable
          ? item.additionsTaxable
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0
      );
      row.push(
        item.totalEarnings
          ? item.totalEarnings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0
      );
      row.push(
        item.totalSSO
          ? item.totalSSO.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0
      );
      row.push(
        item.totalTax
          ? item.totalTax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0
      );
      row.push(
        item.totalDeductions
          ? item.totalDeductions
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0
      );
      row.push(
        item.net ? item.net.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0
      );

      const excelRow = worksheet.addRow(row);

      const contentStyle = {
        font: { size: 16, name: "TH SarabunPSK", bold: true },
      };

      excelRow.eachCell((cell, colNumber) => {
        cell.style = { ...contentStyle };
        if (colNumber === 1) {
          cell.style.alignment = { horizontal: "center", vertical: "middle" };
        } else if (colNumber > 6) {
          cell.style.alignment = { horizontal: "right" };
        }
      });

      count += 1;
    });

    const summaryAdditions = {};
    additionCode.forEach(function (code) {
      summaryAdditions[code] = eachData
        .reduce(function (acc, item) {
          return (
            acc +
            (item.payrunDetailAddition.find(function (addition) {
              return addition.code === code;
            })
              ? item.payrunDetailAddition.find(function (addition) {
                  return addition.code === code;
                }).value
              : 0)
          );
        }, 0)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    });

    const summaryRow = [
      t("Total"),
      "",
      "",
      "",
      "",
      "",
      eachData
        .reduce((acc, item) => acc + (item.totalOT || 0), 0)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      eachData
        .reduce((acc, item) => acc + (item.totalShiftPay || 0), 0)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    ];

    additionCode.forEach((code) => {
      summaryRow.push(summaryAdditions[code]);
    });

    // Push the remaining values to the summaryRow array
    summaryRow.push(
      eachData
        .reduce((acc, item) => acc + (item.additionsTaxable || 0), 0)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      eachData
        .reduce((acc, item) => acc + (item.totalEarnings || 0), 0)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      eachData
        .reduce((acc, item) => acc + (item.totalSSO || 0), 0)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      eachData
        .reduce((acc, item) => acc + (item.totalTax || 0), 0)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      eachData
        .reduce((acc, item) => acc + (item.totalDeductions || 0), 0)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      eachData
        .reduce((acc, item) => acc + (item.net || 0), 0)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );

    const excelSummaryRow = worksheet.addRow(summaryRow);
    count += 1;
    excelSummaryRow.height = 50;

    const contentStyle = {
      font: { size: 18, name: "TH SarabunPSK", bold: true },
    };

    worksheet.mergeCells(`A${count}:F${count}`)

    excelSummaryRow.eachCell((cell, colNumber) => {
      cell.style = { ...contentStyle };
      if (colNumber === 1) {
        cell.style.alignment = { horizontal: "center", vertical: "middle" };
      } else if (colNumber > 6) {
        cell.style.alignment = { horizontal: "right" };
      }
    });

    const voidRow = worksheet.addRow("");
    count += 2;
  }

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${t("AppMenu.Payroll")}-${t("Function")}.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  });
};

function nameSort(a, b) {
  let A = a.split("(")[1];
  let B = b.split("(")[1];
  let prefixA = A.split("-")[0];
  let prefixB = B.split("-")[0];

  if (prefixA === "AR" && prefixB === "AO") {
    return -1;
  } else if (prefixA === "AO" && prefixB === "AR") {
    return 1;
  }
}

function codeSort(a, b) {
  let prefixA = a.split("-")[0];
  let prefixB = b.split("-")[0];

  if (prefixA === "AR" && prefixB === "AO") {
    return -1;
  } else if (prefixA === "AO" && prefixB === "AR") {
    return 1;
  }
}
