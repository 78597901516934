import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box, Avatar } from '@mui/material';

function InfoCard({ image, name, id, job, t }) {
    return (
        <Card sx={{ display: 'flex', alignItems: 'center', padding: 2, width: '100%', flexDirection: { xs: 'column', sm: 'row' }, }}>
            <Avatar
                src={image}
                alt={`${name}'s profile`}
                sx={{ width: 80, height: 80, background: 'whitesmoke', boxShadow: 2 }}
            />
            <CardContent sx={{ textAlign: { xs: 'center', sm: 'start' } }}>
                <Typography variant="h6">{name}</Typography>
                <Typography variant="subtitle2" color="text.secondary">{t('EmployeeID')}: {id}</Typography>
                <Typography variant="body2" color="text.secondary">{t('Position')}: {job}</Typography>
            </CardContent>
        </Card>
    );
}

export default InfoCard;
