import React, { useEffect } from "react";
import { Box, Divider, Grid, Typography, styled } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import NumberFormatTheme from "../../shared/general/NumberFormatTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";
import {
  getManpowerPlanning,
  updateManpowerPlanning,
} from "../../../../actions/organizationStructure";
import { openNotificationAlert } from "../../../../actions/notificationAlert";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px",
  },
  "& .MuiSwitch-root": {
    "& .Mui-checked": {
      color: "#46cbe2",
    },
    "& .Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#46cbe2",
    },
  },
});

const DrawerEdit = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { drawerConfig, onClose, isReadOnly } = props;

  const validateYupSchema = yup.object({
    manpowerPlan: yup
      .string()
      .test(
        "max",
        `${t("WorkforceMoreThan50,000People")}`,
        (val) => val === "" || Number(val) <= 50000
      ),
  });

  const useHookForm = useForm({
    defaultValues: {
      name: "",
      level: "",
      manpowerActual: "",
      manpowerPlan: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  useEffect(() => {
    if (drawerConfig.isOpen === true) {
      useHookForm.reset({
        name: drawerConfig.data.name !== null ? drawerConfig.data.name : "",
        level: drawerConfig.data.level !== null ? drawerConfig.data.level : "",
        manpowerActual:
          drawerConfig.data.manpowerActual !== null
            ? drawerConfig.data.manpowerActual
            : "",
        manpowerPlan:
          drawerConfig.data.manpowerPlan !== null
            ? drawerConfig.data.manpowerPlan
            : "",
      });
    }
  }, [drawerConfig.isOpen]);

  const onSubmit = async (data) => {
    const formData = {
      idOrganization: drawerConfig.data.idOrganization,
      level: data.level,
      manpowerPlan: data.manpowerPlan === "" ? null : data.manpowerPlan,
    };

    const result = await dispatch(updateManpowerPlanning(formData));

    if (result) {
      if (result.status === 200) {
        dispatch(
          openNotificationAlert({
            type: "success",
            message: `${t("DataSaveSuccessful")}`,
          })
        );
        dispatch(getManpowerPlanning());
        onClose();
      } else {
        dispatch(
          openNotificationAlert({
            type: "error",
            message: `${t("AnErrorOccurred")}``${t("ContactAdmin")}`,
          })
        );
      }
    } else {
      dispatch(
        openNotificationAlert({
          type: "error",
          message: `${t("AnErrorOccurred")}``${t("ContactAdmin")}`,
        })
      );
    }
  };

  return (
    <DrawerCustom
      title={`${t("EditManPower")}`}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontSize="24px">
                {useHookForm.getValues("name")}
              </Typography>
              <Typography>
                {t("Level")} {t(useHookForm.getValues("level"))}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="18px">
                {t("CurrentManPower")}{" "}
                <span style={{ fontSize: "24px", fontWeight: "500" }}>
                  {useHookForm.getValues("manpowerActual")}
                </span>{" "}
                {t("Person")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider
                style={{
                  borderColor: "rgba(145, 158, 171, 0.32)",
                  borderStyle: "dashed",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="500" paddingBottom="4px">
                {t("Manpower")}
              </Typography>
              <Controller
                name="manpowerPlan"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                    }}
                    inputProps={{
                      allowNegative: false,
                      onValueChange: (values) => {
                        const { value } = values;
                        field.onChange(value);
                      },
                      suffix: `${t("Person")}`,
                      decimalScale: 0,
                      value: field.value,
                      placeholder: `${t("Optional")}`,
                    }}
                    onChange={() => { }}
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue
                variant="text"
                onClick={onClose}
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
                disabled={useHookForm.formState.isSubmitting || isReadOnly}
              >
                {t("SaveData")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DrawerEdit;
