import {
    PROGRESSIDVPLAN_FETCHING,
    PROGRESSIDVPLAN_FAILED,
    PROGRESSIDVPLAN_SUCCESS,
  } from "./types";
  
  import ProgressIdvPlanService from "../services/ProgressIndividualPlans.service";
  
  export const getAllProgressIdvPlan = () => async (dispatch) => {
    try {
      const res = await ProgressIdvPlanService.getAllProgressIdvPlan();
      if (res) {
        dispatch({
          type: PROGRESSIDVPLAN_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: PROGRESSIDVPLAN_FAILED,
      });
      console.log(err);
    }
  };
  
  export const postProgressIdvPlan = (formData) => async () => {
    try {
      const res = await ProgressIdvPlanService.postProgressIdvPlan(formData);
      if (res) {
        return res;
      }
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.name) ||
        error.name ||
        error.toString();
      return "Error";
    }
  };
  