import React from 'react';
import { Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar, Box, styled } from '@mui/material';
import moment from 'moment';
import { AssignmentLateOutlined } from '@mui/icons-material';

const StyledCellHeader = styled(TableCell)(({ theme }) => ({
  borderBottom: "0px",
  color: "#fff",
  backgroundColor: "#5e4889",
  padding: 8,
  minWidth: 50,
  "& .MuiTypography-root": {
    color: "#fff",
    backgroundColor: "#5e4889",
    "&.weekend": {
      fontStyle: "oblique",
    },
    "&.workday": {
      fontWeight: 600,
    },
  },
  "&.sticky": {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
    [theme.breakpoints.up('sm')]: {
      position: 'sticky',
    },
    left: 0,
    zIndex: 4,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "10px 0px 10px #EEEEEE",
    "& .MuiTableCell-root": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "&:last-of-type": {
    paddingRight: 16,
    // borderTopRightRadius: 8,
    // borderBottomRightRadius: 8,
  },
}));

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "0px",
  padding: 8,
  minWidth: 50,
  "&.sticky": {
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
    [theme.breakpoints.up('sm')]: {
      position: 'sticky',
    },
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .avatar": {
    minWidth: 200,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .fullname": {
    fontWeight: 600,
  },
  "& .position": {
    color: theme.palette.text.secondary,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .secondaryAction": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .location": {
      fontWeight: 600,
    },
  },
  "&:first-of-type": {
    paddingLeft: 8,
  },
  "&:last-of-type": {
    paddingRight: 16,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
}));

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const HeaderBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  padding: '16px 0 32px 0',
}));

const InnerHeaderBox = styled(Box)(() => ({
  borderBottom: '2px solid black',
  padding: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

function AbsenceAndRate({ t, currentLocale, absenceAndLate }) {
  moment.locale(currentLocale);

  return (
    <Card sx={{ width: '100%', padding: '16px' }}>
      <CardContent>
        <HeaderBox>
          <InnerHeaderBox>
            <AssignmentLateOutlined sx={{ marginRight: 1, fontSize: 30, color: 'error.main' }} />
            <Typography margin={0} variant="h5" align="center" gutterBottom>
              {t('Absent')} & {t('Late')}
            </Typography>
          </InnerHeaderBox>
        </HeaderBox>
        <TableContainer component={Box}>
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table" >
            <TableHead sx={{
              position: { xs: 'relative', sm: 'sticky' },
              top: 0,
              zIndex: 1,
              backgroundColor: '#5e4889'
            }}>
              <TableRow>
                <StyledCellHeader align="center" className="sticky"
                  sx={{ minWidth: 200, maxWidth: 250, width: '33.33%', position: { xs: 'relative', sm: 'sticky' } }}
                  rowSpan={2}
                >{t('FullName')}</StyledCellHeader>
                <StyledCellHeader align="center" >{t('Late')}</StyledCellHeader>
                <StyledCellHeader align="center" >{t('Absent')}</StyledCellHeader>
                <StyledCellHeader align="center" >{t('TotalWeek')}</StyledCellHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {absenceAndLate ? absenceAndLate.map((record, index) => {

                return (
                  <StyledRowContent key={index}>
                    <StyledCellContent className="sticky"
                      style={{
                        borderBottom:
                          "1px dashed rgba(224, 224, 224, 1)",
                      }} align="center" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      <Box className="avatar">
                        <Avatar src={record.ImageURL || "https://example.com/avatar1.jpg"} alt={record.name} sx={{ boxShadow: 1 }} />
                        <Typography variant="subtitle1">
                          {currentLocale === 'th' ? record.firstname_TH ? record.firstname_TH : record.firstname_EN : record.firstname_EN ? record.firstname_EN : record.firstname_TH}
                          {currentLocale === 'th' ? record.lastname_TH ? record.lastname_TH : record.lastname_EN : record.lastname_EN ? record.lastname_EN : record.lastname_TH}
                        </Typography>
                      </Box>
                    </StyledCellContent>
                    <StyledCellContent align="center" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      <Typography variant="body1">{record.late}</Typography>
                    </StyledCellContent>
                    <StyledCellContent align="center" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      <Typography variant="body1">{record.absent}</Typography>
                    </StyledCellContent>
                    <StyledCellContent align="center" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      <Typography variant="body2">{record.sum}</Typography>
                    </StyledCellContent>
                  </StyledRowContent>
                );
              }) : (
                <StyledRowContent>
                  <StyledCellContent colSpan={5} align="center" margin={2}>{t('NoAbsent')} & {t('NoLate')}</StyledCellContent>
                </StyledRowContent>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}

export default AbsenceAndRate;
