import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Chip,
  Typography,
  styled,
  Button,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  TablePagination,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
  Table,
  Collapse,
  FilledInput, 
  Autocomplete,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch } from "react-redux";
import { utils, writeFile } from "xlsx";
import { getKpiCarlibrate , updateKpiGrade } from "../../../../../actions/kpi";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DialogDownloadKPi from "../DialogDownloadKpi";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AlertResponse from "../../../shared/general/AlertResponse";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import MenuGrade from "./MenuGrade";
import CreateAnnouncement from "../uploadData";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import SearchIcon from "@mui/icons-material/Search";
import TextFieldTheme from "../../../../pages/shared/general/TextFieldTheme";
import { getUserFirstName, getUserFullName,getUserLastName, getUserPosition } from "../../../../../utils/userData";


const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});


const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiGrid-container": {
      marginBottom: 0,
    },
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
    "& .MuiOutlinedInput-input": {
      padding: "16px 14px",
    },
    "& .MuiTableCell-root": {
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
  tableContainer: {
    overflowX: "auto",
    flexGrow: 1,
    height: "405px", 
    display: "flex",
    flexDirection: "column",
  },
  sticky: {
    position: "sticky",
    right: 0,
    backgroundColor: "#fff",
    zIndex: 1,
    borderLeft: "1px solid #ccc",
  },
}));

const StyledWrapDataGrid = styled("div")({
  padding: "24px",
  paddingBottom: "0px",
  overflowX: "auto",
  display: "flex",
  flexDirection: "column",
  height: "100%", 
});

function calculateAverage(rowData) {
  if (rowData.kpi && rowData.kpi.length > 0) {
    let weightedSum = 0; 
    let totalWeight = 0; 

    for (let i = 0; i < rowData.kpi.length; i++) {
      const managerRating = rowData.kpi[i].managerRating;
      const weight = parseInt(rowData.kpi[i].weight);

      if (managerRating !== null) {
        weightedSum += parseFloat(managerRating) * weight; 
        totalWeight += weight;
      }
    }

    const average = totalWeight > 0 ? (weightedSum / totalWeight) : 0;
    return (average / 5) * 100; 
  }

  return 0; 
}

function CompanyCalibrate() {
  const dispatch = useDispatch();
  const { t ,i18n} = useTranslation();
  const classes = useStyles();

  const [openDialogDownload, setOpenDialogDownload] = useState(false);
  const [calibrateData, setCalibrateData] = useState([]);
  const [allLeaveTypes, setAllLeaveTypes] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); 

  const [searchName, setSearchName] = useState('');
  const [searchPosition, setSearchPosition] = useState('');
  const [searchSection, setSearchSection] = useState('');
  const [searchGrade, setSearchGrade] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  
  const [expandedRows, setExpandedRows] = useState({});

  const toggleRowExpansion = (rowIndex) => {
    setExpandedRows(prevState => {
      const newState = {};
      newState[rowIndex] = !prevState[rowIndex]; 
      return newState; 
    });
  };
  
  const exportFile = useCallback(() => {
    const dataExport = calibrateData.map((item) => ({
      "ชื่อ": getUserFirstName(item),
      "นามสกุล": getUserLastName(item),
      "ตำแหน่ง": getUserPosition(item),
      "แผนก": item.sectionName,
      "ค่าเฉลี่ย": calculateAverage(item).toFixed(2),
      ...item.allLeaveTypes.reduce((acc, leaveType) => {
        acc[leaveType.name] = leaveType.Num_Leave;
        return acc;
      }, {}),
      "เกรด": item.kpiGrade,
    }));

    const ws = utils.json_to_sheet(dataExport);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFile(wb, `${t("Calibrate")}.xlsx`);
  }, [calibrateData]);

  const assignGrades = (data) => {
    const totalEmployees = data.length;
    const top10PercentCount = Math.ceil(totalEmployees * 0.1);
    const bottom10PercentCount = Math.ceil(totalEmployees * 0.1);

    data.sort((a, b) => b.average - a.average);
  
    for (let i = 0; i < top10PercentCount; i++) {
      data[i].kpiGrade = "A";
    }

    for (let i = totalEmployees - bottom10PercentCount; i < totalEmployees; i++) {
      data[i].kpiGrade = "D";
    }
  };
  
  const fetchData = () => {
    dispatch(getKpiCarlibrate())
      .then((res) => {
        if (res && res.status === 200) {
          const sortedData = res.data.employeeResult.map((item) => ({
            ...item,
            average: calculateAverage(item),
          })).sort((a, b) => b.average - a.average);
  
          assignGrades(sortedData);
  
          setCalibrateData(sortedData);
          setAllLeaveTypes(res.data.employeeResult.length > 0 ? res.data.employeeResult[0].allLeaveTypes : []);
  
          const currentDate = dayjs().startOf('day'); 
          let isWithinAnyRange = false;
  
          res.data.KpiEditDate.forEach((dateRange) => {
            const startDate = dayjs(dateRange.startDate).startOf('day'); 
            const endDate = dayjs(dateRange.endDate).endOf('day'); 

            if (currentDate.isBetween(startDate, endDate, null, '[]')) {
              isWithinAnyRange = true;
            }
          });
  
          setIsButtonDisabled(!isWithinAnyRange); 
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = () => {
    if (
      (!searchName || searchName === "") &&
      (searchPosition === "ทั้งหมด" || searchPosition === "") &&
      (searchSection === "ทั้งหมด" || searchSection === "") &&
      (searchGrade === "ทั้งหมด" || searchGrade === "")
    ) {
      fetchData();
    } else {
      setCalibrateData((prevData) =>
        prevData.filter((item) =>
          (searchName ? (getUserFirstName(item) && getUserFirstName(item).toLowerCase().includes(searchName.toLowerCase)) || (getUserLastName(item) && getUserLastName(item).toLowerCase().includes(searchName.toLowerCase)) : true) &&
          (searchPosition === "ทั้งหมด" || !searchPosition ? true : (item.positionName && item.positionName.includes(searchPosition))) &&
          (searchSection === "ทั้งหมด" || !searchSection ? true : (item.sectionName && item.sectionName.includes(searchSection))) &&
          (searchGrade === "ทั้งหมด" || !searchGrade ? true : (item.kpiGrade && item.kpiGrade.includes(searchGrade)))
        )
      );
    }
  };
  

  const handleClearSearch = (setter) => () => {
    setter('');
    fetchData();
  };

  const getUniqueValues = (key) => {
    const values = calibrateData.map(item => item[key]);
    return [...new Set(values)];
  };

  const confirmChangeGrade = () => {
    Swal.fire({
        title: t("ConfirmGrading"),
        text: t("DoYouWantToConfirmGrading"),
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Confirm"),
        cancelButtonText: t("Cancel"),
    }).then((result) => {
        if (result.isConfirmed) {
            handleConfirmAllGrades(); 
        }
    });
};

const handleConfirmAllGrades = async () => {
    try {
        const promises = calibrateData.map((item) =>
            updateKpiGrade({ kpiGrade: item.kpiGrade, idEmployees: item.idEmployees })
        );
        await Promise.all(promises);
        await fetchData();  
        Swal.fire({
            title: t("Success"),
            text: t("AllGradesConfirmed"),
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
        });
    } catch (error) {
        console.error("Error updating all grades:", error);
        Swal.fire({
            title: t("AnErrorOccurred"),
            text: t("CannotConfirmAllGrades"),
            icon: 'error',
            timer: 2000,
            showConfirmButton: false,
        });
    }
};


  const kpiColumns = calibrateData.length > 0 && calibrateData[0].kpi
    ? calibrateData[0].kpi.map((kpi, index) => ({
        name: `KPI ${index + 1}`,
        fieldKey: `kpi[${index}].kpiName`,
        cellRender: (row) => row.kpi && row.kpi[index] ? row.kpi[index].kpiName : '',
        minWidth: 100,
      }))
    : [];

  const leaveTypeColumns = allLeaveTypes.length > 0
  ? allLeaveTypes.map((leaveType, index) => {
      const nameLength = leaveType.name.length;
      const minWidth = Math.max(nameLength * 8);
      return {
        name: leaveType.name,
        fieldKey: `allLeaveTypes[${index}].Num_Leave`,
        minWidth: minWidth,
        headerAlign: "center", 
        align: "center",      
        cellRender: (row) => (
          <div style={{ textAlign: "center" }}>
            {row.allLeaveTypes && row.allLeaveTypes[index] ? row.allLeaveTypes[index].Num_Leave : ''}
          </div>
        ),
      };
    })
  : [];
  
  const columns = [
    {
      name: t("FullName"),
      fieldKey: "fullName",
      minWidth: 240,
      cellRender: (row) => (
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          <Avatar src={row.imageURL} />
          {getUserFullName(row)}
        </div>
      ),
    },
    {
      name: t("Position"),
      fieldKey: "positionName",
      minWidth: 160,
    },
    {
      name: t("Section"),
      fieldKey: "sectionName", 
      minWidth: 160,
    },
    {
      name: t("Average"),
      fieldKey: "average",
      minWidth: 50,
      headerAlign: "center",
      align: "center",       
      cellRender: (row) => (
        <div style={{ textAlign: "center" }}>
          {calculateAverage(row).toFixed(2)}
        </div>
      ),
    },
    ...leaveTypeColumns,
    {
      name: t("Grade"),
      fieldKey: "kpiGrade",
      minWidth: 120,
      className: classes.sticky,
      cellRender: (row, rowIndex) => (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Chip
              size="small"
              label={row.kpiGrade}
              sx={{
                backgroundColor: "#4f65df",
                color: "#ffffff",
                width: '50px',
                flexGrow: 1,
              }}
            />
            <MenuGrade idEmployees={row.idEmployees} fetchData={fetchData} />
          </div>
          <IconButton onClick={() => toggleRowExpansion(rowIndex)}>
            {expandedRows[rowIndex] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </div>
      ),
    }
  ];

  const [selectedRows, setSelectedRows] = useState([]);
  
  const toggleDrawerDownload = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDialogDownload(open);
  };

  const [openDialog, setDialog] = useState({
    open: false,
  });
  const handleOpenDialogDropzoneKpiGrade = () => {
    setDialog({
      open: true,
    });
  };
  const handleCloseDialogDropzoneKpiGrade = () => {
    setDialog({
      open: false,
    });
  };

  const [openAlert, setOpenAlert] = useState({
    open: false,
    alertType: null,
    label: null,
  });
  const handleOpenAlert = (alertType, label) => {
    setOpenAlert({
      open: true,
      alertType: alertType,
      label: label,
    });
  };
  const handleCloseAlert = () => {
    setOpenAlert({
      open: false,
      alertType: null,
      label: null,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {calibrateData && (
        <StyledWrapDataGrid>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "24px",
            }}
          >
            <Typography fontWeight={700} fontSize={"1.5rem"} color={"#4f65df"}>
              {t("CompanyPerformanceSummary")}
            </Typography>
            <div style={{ display: "flex", gap: "8px" }}>
              <ButtonBlue
                className="download"
                variant="outlined"
                aria-label="download"
                onClick={toggleDrawerDownload(true)}
                startIcon={<FileDownloadIcon />}
              >
                {t("DownloadData")}
              </ButtonBlue>
              <ButtonBlue
                    variant="contained"
                    onClick={confirmChangeGrade}
                    disabled={isButtonDisabled} 
                >
                    {t("ConfirmAllGrades")}
              </ButtonBlue>
            </div>
          </div>
          <Grid container spacing={2} alignItems="center" style={{ marginBottom: "24px" }}>
          <Grid item xs={12} md={4}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("SearchEmployeeName")}
              </Typography>
              <FormControl fullWidth variant="filled">
                <StyledFilledInput
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                  placeholder={t("SearchEmployeeName")}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  endAdornment={
                    searchName && (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClearSearch(setSearchName)}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              </FormControl>
            </StyledBoxSearch>
          </Grid>

          <Grid item xs={12} md={3}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("SearchPosition")}
              </Typography>
              <FormControl fullWidth variant="filled">
                <StyledAutocomplete
                  value={searchPosition}
                  onChange={(event, value) => setSearchPosition(value)}
                  options={[t("All"), ...getUniqueValues("positionName")]} // Include "ทั้งหมด" option
                  getOptionLabel={(option) => option || ""}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      variant="filled"
                      placeholder={t("SearchPosition")}
                    />
                  )}
                  disableClearable
                  endAdornment={
                    searchPosition && (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClearSearch(setSearchPosition)}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              </FormControl>
            </StyledBoxSearch>
          </Grid>

          <Grid item xs={12} md={3}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("SearchDepartment")}
              </Typography>
              <FormControl fullWidth variant="filled">
                <StyledAutocomplete
                  value={searchSection}
                  onChange={(event, value) => setSearchSection(value)}
                  options={[t("All"), ...getUniqueValues("sectionName")]} // Include "ทั้งหมด" option
                  getOptionLabel={(option) => option || ""}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      variant="filled"
                      placeholder={t("SearchDepartment")}
                    />
                  )}
                  disableClearable
                  endAdornment={
                    searchSection && (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClearSearch(setSearchSection)}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              </FormControl>
            </StyledBoxSearch>
          </Grid>

          <Grid item xs={12} md={1}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("SearchGrade")}
              </Typography>
              <FormControl fullWidth variant="filled">
                <StyledAutocomplete
                  value={searchGrade}
                  onChange={(event, value) => setSearchGrade(value)}
                  options={[t("All"), "A", "B", "C", "D"]} // Include "ทั้งหมด" option
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      variant="filled"
                      placeholder={t("SearchGrade")}
                    />
                  )}
                  disableClearable
                  endAdornment={
                    searchGrade && (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClearSearch(setSearchGrade)}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              </FormControl>
            </StyledBoxSearch>
          </Grid>


          <Grid item xs={12} md={1} container justifyContent="flex-end">
          <ButtonBlue
            variant="contained"
            color="primary"
            onClick={handleSearch}
            style={{ height: '56px', minWidth: '62px', padding: 0, marginTop: '28px' }} 
          >
            <SearchIcon />
          </ButtonBlue>
        </Grid>
      </Grid>

          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      style={{
                        minWidth: column.minWidth,
                        position: column.fieldKey === "kpiGrade" || column.fieldKey === "actions" ? 'sticky' : 'static',
                        right: column.fieldKey === "kpiGrade" || column.fieldKey === "actions" ? 0 : 'auto',
                        background: column.fieldKey === "kpiGrade" || column.fieldKey === "actions" ? '#fff' : 'none',
                        zIndex: column.fieldKey === "kpiGrade" || column.fieldKey === "actions" ? 1 : 'auto',
                      }}
                    >
                      {column.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {calibrateData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                  <>
                    <TableRow key={rowIndex}>
                      {columns.map((column, colIndex) => (
                        <TableCell
                          key={colIndex}
                          style={{
                            minWidth: column.minWidth,
                            position: column.fieldKey === "kpiGrade" || column.fieldKey === "actions" ? 'sticky' : 'static',
                            right: column.fieldKey === "kpiGrade" || column.fieldKey === "actions" ? 0 : 'auto',
                            background: column.fieldKey === "kpiGrade" || column.fieldKey === "actions" ? '#fff' : 'none',
                            zIndex: column.fieldKey === "kpiGrade" || column.fieldKey === "actions" ? 1 : 'auto',
                          }}
                        >
                          {column.cellRender ? column.cellRender(row, rowIndex) : row[column.fieldKey]}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={columns.length}
                      >
                        <Collapse in={expandedRows[rowIndex]} timeout="auto" unmountOnExit>
                          <Box margin={1}>
                            {row.kpi && row.kpi.length > 0 ? (
                              row.kpi.map((kpiItem, index) => (
                                <Box key={index} display="flex" flexDirection="column" marginBottom="8px">
                                  <Typography variant="body2" color="textSecondary">
                                    <span style={{ marginRight: '10px' }}>•</span>
                                    <span style={{ display: 'inline-block', width: '300px' }}>{t("KPIName")}: {kpiItem.kpiName || 0}</span>
                                    <span style={{ display: 'inline-block', width: '120px' }}>{t("Weight")}: {kpiItem.weight || 0}</span>
                                    <span style={{ display: 'inline-block', width: '  220px' }}>
                                      {t("ManagerEvaluate")}: {kpiItem.managerEvaluate === 1 ? t("Success") : t("Failure")}
                                    </span>
                                    <span style={{ display: 'inline-block', width: '180px' }}>{t("ManagerRating")}: {kpiItem.managerRating || 0}</span>
                                    <span style={{ display: 'inline-block', width: '100px' }}>{t("Progress")}: {kpiItem.progress || 0}</span>
                                  </Typography>
                                </Box>
                              ))
                            ) : (
                              <Typography variant="body2" color="textSecondary">
                               {t("NoKPIsAvailable")}
                              </Typography>
                            )}
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[7, 20, 40]}
            component="div"
            count={calibrateData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <CreateAnnouncement
            open={openDialog.open}
            handleClose={handleCloseDialogDropzoneKpiGrade}
          />
          <AlertResponse
            open={openAlert.open}
            handleClose={handleCloseAlert}
            alertType={openAlert.alertType}
            label={openAlert.label}
          />
          <DialogDownloadKPi
            open={openDialogDownload}
            toggleDrawer={toggleDrawerDownload}
            employeeData={calibrateData}
          />
        </StyledWrapDataGrid>
      )}
    </>
  );
}

export default CompanyCalibrate;
