import React, { useState, useEffect, useCallback, Fragment } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";

import DataGrid, {
  Column,
  Paging,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Scrolling,
  Sorting,
  Selection,
  TotalItem,
  Summary,
  Export,
} from "devextreme-react/data-grid";

import utils from "../../../../../utils";

import StyledCard from "../../../shared/general/Card";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledCardContent = styled(CardContent)({
  height: "100%",
  padding: 24,
  "& .wrap-header-filter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    "& .wrap-filter": {
      display: "flex",
      "& .btn-filter": {
        marginRight: 8,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 8,
      },
    },
  },
  "& .wrap-manage": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .wrap-manage-group": {
      display: "flex",
      alignItems: "center",
      "& .btn-mamage-group-company": {
        marginRight: 8,
      },
    },
    "& .wrap-edit-selected": {},
  },
  "& .wrap-panel": {
    height: 490,
    overflow: "auto",
    display: "flex",
  },
});

const StyledWrapDataGrid = styled("div")({
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },

  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#009688",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },

  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
});

const StyleWrapFooter = styled("div")({
  padding: 16,
  "& .wrap-late": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .wrap-text-header": {
    "& .MuiTypography-body1": {
      fontWeight: 500,
    },
  },
  "& .text-header": {
    display: "flex",
    alignItems: "center",
  },
  "& .text-value": {
    textAlign: "center",
    "& .MuiTypography-h5": {
      fontWeight: 600,
      color: "#e46a76",
      lineHeight: 1,
    },
  },
  "& .sum-baht": {
    fontWeight: 500,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 8,
  marginBottom: 8,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const IndividualMonthly = (props) => {
  const { result: summaryTimeIndividual } = useSelector(
    (state) => state.summaryTimeIndividual
  );
  const [result, setResult] = useState(null);
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);

  let gridColumnsOT = [
    {
      dataField: "otOneHours",
      caption: `OT 1 (${t("Unit.ShortHours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
      isShow: 1,
    },
    {
      dataField: "otOneAmount",
      caption: `OT 1 (บาท)`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
      isShow: userProfile.isSalary,
    },
    {
      dataField: "otOneFiveHours",
      caption: `OT 1.5 (${t("Unit.ShortHours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
      isShow: 1,
    },
    {
      dataField: "otOneFiveAmount",
      caption: `OT 1.5 (บาท)`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
      isShow: userProfile.isSalary,
    },
    {
      dataField: "otTwoHours",
      caption: `OT 2 (${t("Unit.ShortHours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
      isShow: 1,
    },
    {
      dataField: "otTwoAmount",
      caption: `OT 2 (บาท)`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
      isShow: userProfile.isSalary,
    },
    {
      dataField: "otThreeHours",
      caption: `OT 3 (${t("Unit.ShortHours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
      isShow: 1,
    },
    {
      dataField: "otThreeAmount",
      caption: `OT 3 (บาท)`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
      isShow: userProfile.isSalary,
    },
  ];

  let gridColumnsShift = [
    {
      dataField: "shiftPayMorning",
      caption: `กะเช้า`,
      format: "###,###,###.##",
      cssClass: "column-shift",
      isShow: userProfile.isSalary,
    },
    {
      dataField: "shiftPayAfternoon",
      caption: `กะบ่าย`,
      format: "###,###,###.##",
      cssClass: "column-shift",
      isShow: userProfile.isSalary,
    },
    {
      dataField: "shiftPayNight",
      caption: `กะดึก`,
      format: "###,###,###.##",
      cssClass: "column-shift",
      isShow: userProfile.isSalary,
    },
    {
      dataField: "shiftPayTotal",
      caption: "รวมค่ากะ",
      format: "###,###,###.##",
      cssClass: "column-shift",
      isShow: userProfile.isSalary,
    },
  ];

  let listOfBank = [
    {
      key: "กรุงศรีอยุธยา",
      value: "025 Bank of Ayudhya Public Company Limited",
    },
    {
      key: "กสิกร",
      value: "004 Kasikorn Bank",
    },
    {
      key: "ไทยพาณิชย์",
      value: "014 Siam Commercial Bank",
    },
    {
      key: "กรุงเทพ",
      value: "002 Bangkok Bank PCL",
    },
    {
      key: "กรุงไทย",
      value: "006 Krung Thai Bank Public Company Limited",
    },
    {
      key: "ทหารไทยธนชาต",
      value: "011 TMB Bank Public Company Limited ",
    },
  ];

  useEffect(() => {
    if (summaryTimeIndividual) {
      let temp = { ...summaryTimeIndividual };
      temp.dataTable.forEach((element) => {
        element["date"] = dayjs(element.date).format(
          i18n.resolvedLanguage === "th"
            ? "DD MMM BBBB (ddd)"
            : "DD MMM YYYY (ddd)"
        );
        element["workingTime"] = element.dataRender
          ? element.dataRender.isWorkingDay === 1
            ? `${element.dataRender.timeIn.slice(
                0,
                -3
              )} - ${element.dataRender.timeOut.slice(0, -3)}`
            : element.dataRender.shiftTypeName
          : "";

        element["otOneHours"] = element.otOneHours ? element.otOneHours : null;
        element["otOneFiveHours"] = element.otOneFiveHours
          ? element.otOneFiveHours
          : null;
        element["otTwoHours"] = element.otTwoHours ? element.otTwoHours : null;
        element["otThreeHours"] = element.otThreeHours
          ? element.otThreeHours
          : null;

        element["otOneAmount"] = element.otOneAmount
          ? element.otOneAmount
          : null;
        element["otOneFiveAmount"] = element.otOneFiveAmount
          ? element.otOneFiveAmount
          : null;
        element["otTwoAmount"] = element.otTwoAmount
          ? element.otTwoAmount
          : null;
        element["otThreeAmount"] = element.otThreeAmount
          ? element.otThreeAmount
          : null;
      });
      setResult(temp);
    }
  }, [summaryTimeIndividual]);

  const customizeOTHour = (data) => {
    return data.value ? `${utils.roundToTwo(data.value)} ${t("Unit.ShortHours")}` : "";
  };

  const customizeOTAmount = (data) => {
    return data.value ? `${utils.roundToTwo(data.value)} บาท` : "";
  };

  const calculateCustomSummary = (options) => {
    console.log(options);
    return dayjs(options.dateText).isBefore(dayjs(new Date()))
      ? options.shiftMorning + options.shiftNight + options.shiftNoon
      : "";
  };

  const onExporting = useCallback(() => {
    // let workbook = new ExcelJS.Workbook();
    // let worksheet = workbook.addWorksheet("SheetName");
    // exportDataGrid({
    //   component: grid.current.instance,
    //   worksheet: worksheet,
    // }).then(function () {
    //   workbook.xlsx.writeBuffer().then(function (buffer) {
    //     saveAs(
    //       new Blob([buffer], { type: "application/octet-stream" }),
    //       `${payRunDetail.payrunName}.xlsx`
    //     );
    //   });
    // });
  }, []);

  return (
    <Fragment>
      <StyledCard>
        <StyledCardContent>
          {/* {result && (
            <div>
              <Typography variant="h6">
                {t("PayrollRound")}:{" "}
                {`${dayjs(result.start).format("D MMM BBBB")} - ${dayjs(
                  result.end
                ).format("D MMM BBBB")}`}
              </Typography>
            </div>
          )} */}

          <StyledWrapDataGrid>
            <DataGrid
              dataSource={
                result && summaryTimeIndividual ? result.dataTable : []
              }
              showBorders={true}
              showColumnLines={true}
              rowAlternationEnabled={true}
              columnAutoWidth={true}
            >
              <Column
                caption={`${t("Date")}`}
                dataField="date"
                dataType="string"
                cssClass="column-info"
              />
              <Column
                caption={`${t("AppMenu.TimeAttendance")}`}
                dataField="workingTime"
                dataType="string"
                cssClass="column-info"
              />

              {result && result.idPaymentType === 1 && (
                <Column
                  caption={t("WorkingDay")}
                  dataField="workingDay"
                  dataType="string"
                  cssClass="column-info"
                  alignment="center"
                />
              )}
              {result && result.idPaymentType === 1 && (
                <Column
                  caption={`${t("Absent")} (${t("Unit.Days")})`}
                  dataField="absent"
                  dataType="string"
                  cssClass="column-deduction"
                  alignment="center"
                />
              )}
              {result && result.idPaymentType === 1 && (
                <Column
                  caption={`${t("LateAndEarly")} (${t("Minute")})`}
                  dataField="lateEarlyMinute"
                  dataType="string"
                  cssClass="column-deduction"
                  alignment="center"
                />
              )}
              {result && result.idPaymentType === 1 && (
                <Column
                  caption={`${t("LateAndEarly")} (บาท)`}
                  dataField="lateEarlyAmount"
                  dataType="string"
                  cssClass="column-deduction"
                  alignment="center"
                />
              )}

              {result &&
                gridColumnsOT.map((item, key) => {
                  if(item.isShow){
                    return (
                      <Column
                        dataField={item.dataField}
                        caption={item.caption}
                        format={item.format}
                        cssClass="column-total-addition"
                        alignment="center"
                      />
                    )
                  }
              })}

              {result && result.isShiftFee
                ? gridColumnsShift.map((item, key) => {
                    if(item.isShow){
                      return (
                        <Column
                          dataField={item.dataField}
                          caption={item.caption}
                          cssClass="column-shift"
                          width={100}
                          alignment="center"
                          format={item.format}
                        />
                      )
                    }
                  })
                : null}

              <Export enabled={true} allowExportSelectedData={true} />

              <Selection mode="single" />
              <Sorting mode="multiple" />
              <Scrolling columnRenderingMode="virtual" />
              <Paging defaultPageSize={result ? result.dataTable.length : 0} />
              <FilterRow visible={false} />
              <HeaderFilter visible={false} />
              <SearchPanel
                visible={true}
                width={240}
                placeholder={t("Search")}
              />
              <Summary>
                <TotalItem column="date" displayFormat={t("Total")} />
                <TotalItem
                  column="otOneHours"
                  summaryType="sum"
                  customizeText={customizeOTHour}
                />

                <TotalItem
                  column="otOneAmount"
                  summaryType="sum"
                  customizeText={customizeOTAmount}
                />
                <TotalItem
                  column="otOneFiveHours"
                  summaryType="sum"
                  customizeText={customizeOTHour}
                />
                <TotalItem
                  column="otOneFiveAmount"
                  summaryType="sum"
                  customizeText={customizeOTAmount}
                />
                <TotalItem
                  column="otTwoHours"
                  summaryType="sum"
                  customizeText={customizeOTHour}
                />
                <TotalItem
                  column="otTwoAmount"
                  summaryType="sum"
                  customizeText={customizeOTAmount}
                />
                <TotalItem
                  column="otThreeHours"
                  summaryType="sum"
                  customizeText={customizeOTHour}
                />
                <TotalItem
                  column="otThreeAmount"
                  summaryType="sum"
                  customizeText={customizeOTAmount}
                />

                <TotalItem
                  column="shiftMorning"
                  summaryType="sum"
                  customizeText={customizeOTAmount}
                />
                <TotalItem
                  column="shiftNoon"
                  summaryType="sum"
                  customizeText={customizeOTAmount}
                />
                <TotalItem
                  column="shiftNight"
                  summaryType="sum"
                  customizeText={customizeOTAmount}
                />
              </Summary>
            </DataGrid>
          </StyledWrapDataGrid>
        </StyledCardContent>
      </StyledCard>
    </Fragment>
  );
};
export default IndividualMonthly;
