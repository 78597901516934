import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Typography,
  Grid,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  CircularProgress,
  DialogContent,
  Stack,
  Dialog,
  DialogTitle,
  Chip,
  DialogActions,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import PersonIcon from "@mui/icons-material/Person";
import DomainIcon from "@mui/icons-material/Domain";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EventIcon from "@mui/icons-material/Event";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import QuizIcon from '@mui/icons-material/Quiz';
import ButtonBlue from "../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import LoadingGif from "../../../assets/social-media.gif";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import {
  getUserFullName,
  getUserPosition,
} from "../../../../../utils/userData";
import {
  answerQuestion5Scale,
  answerQuestion6Scale,
} from "../../../assets/data/answerScale";
import { useDispatch, useSelector } from "react-redux";
import { getProbation } from "../../../../../actions/probation";

const percentageScore = (questionList = []) => {
  let totalRating = 0;
  for (const item of questionList) {
    if (item.questionType === "choice") {
      totalRating += (item.answer * item.weight) / 100;
    }
  }

  return Math.round(totalRating);
};

const DialogProbation = ({
  open,
  handleClose,
  user,
  idProbation,
  handleSubmit,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: probation, isFetching } = useSelector(
    (state) => state.probationAnswer
  );
  const [probationResult, setProbationResult] = useState(null);
  const [initialProbationResult, setInitialProbationResult] = useState(null);

  const clickSubmit = () => {
    handleSubmit({ idProbation, probationResult: probationResult });
  };

  useEffect(() => {
    dispatch(getProbation(idProbation));
  }, []);

  useEffect(() => {
    if (probation) {
      const result = probation.adminResult ? probation.adminResult : probation.managerResult;
      setProbationResult(result);
      setInitialProbationResult(result)
    }
  }, [probation]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>{t("Details")}</DialogTitle>
      {!probation || isFetching ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img src={LoadingGif} style={{ width: 100, height: 100 }} />
        </Box>
      ) : (
        <>
          <DialogContent sx={{ padding: "24px" }}>
            <Grid container gap={2} marginBottom={2}>
              <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
                <PersonIcon />
                <Typography>
                  {`${t("PersonBeingAssessed")} : ${getUserFullName({
                    firstname_TH: probation.firstname_TH,
                    lastname_TH: probation.lastname_TH,
                    firstname_EN: probation.firstname_EN,
                    lastname_EN: probation.lastname_EN,
                  })}`}
                </Typography>
              </Grid>
              <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
                <AssessmentIcon />
                <Typography>
                  {`${t("ProbationRound")} : ${probation.probationRound}`}
                </Typography>
              </Grid>
              {probation.expandTime && (
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <AddCircleIcon />
                  <Typography>
                    {`${t("ExtendProbationPeriod")}: ${
                      probation.expandTime
                    } ${t("Unit.Days")}`}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
                <EventIcon />
                <Typography>
                  {`${t("ProbationaryExpirationDate")} : ${dayjs(
                    probation.probationDate
                  ).format(
                    i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
                  )}`}
                </Typography>
              </Grid>
              <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
                <CheckCircleIcon />
                <Typography>
                  {`${t("EvaluatedBy")} : ${
                    probation.adminEvaluateDate
                      ? t("Administrator")
                      : i18n.resolvedLanguage === "th"
                      ? probation.approvedBy
                      : probation.approvedBy_EN
                  }`}
                </Typography>
              </Grid>
              <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
                <QuizIcon />
                <Typography>
                  {`${t("QuestionSet")} : ${probation.questionSet.questionSetName}`}
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom>
              {t("Employee")}
            </Typography>
            {probation && probation.questionSet && (
              <>
                <Stack spacing={1} marginBottom={4} width={"100%"}>
                  {probation.questionSet.employeeQuestion.length > 0 ? (
                    probation.questionSet.employeeQuestion.map(
                      (question, index) => (
                        <div key={question.idProbationQuestion}>
                          <Typography gutterBottom fontWeight={500}>
                            {`${t("Question")} ${index + 1} : ${question.question}`}
                          </Typography>
                          {question.questionType === "choice" ? (
                            <>
                              <Typography color="text.secondary">{`${t("Weight")} ${question.weight}%`}</Typography>
                              <RadioGroup row value={question.answer}>
                                {probation.questionSet.answerScale === 6
                                  ? answerQuestion6Scale.map((item) => (
                                      <FormControlLabel
                                        key={item.key}
                                        value={item.value}
                                        control={<Radio />}
                                        label={t(item.label)}
                                      />
                                    ))
                                  : answerQuestion5Scale.map((item) => (
                                      <FormControlLabel
                                        key={item.key}
                                        value={item.value}
                                        control={<Radio />}
                                        label={t(item.label)}
                                      />
                                    ))}
                              </RadioGroup>
                            </>
                          ) : (
                            <TextFieldTheme
                              fullWidth
                              value={question.answer}
                              InputProps={{ readOnly: true }}
                            />
                          )}
                          <Divider sx={{ borderColor: "#e2e2e2", marginTop: "8px" }} />
                        </div>
                      )
                    )
                  ) : (
                    <Typography textAlign="center" color="text.secondary">
                      {t("NoQuestions")}
                    </Typography>
                  )}
                </Stack>
                <Stack spacing={2} marginBottom={4}>
                  <div>
                    <Typography gutterBottom>
                      {t("EmployeeFeedback")}
                    </Typography>
                    <TextFieldTheme
                      multiline
                      fullWidth
                      value={probation.employeeComment}
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
                    <Box style={{ position: "relative", display: "inline-flex" }}>
                      <CircularProgress
                        variant="determinate"
                        style={{
                          color: "#eeeeee",
                        }}
                        size={70}
                        thickness={4}
                        value={100}
                      />
                      <CircularProgress
                        variant="determinate"
                        value={percentageScore(probation.questionSet.employeeQuestion)}
                        style={{
                          color: "#007afe",
                          animationDuration: "550ms",
                          position: "absolute",
                          left: 0,
                        }}
                        size={70}
                      ></CircularProgress>
                      <Box
                        style={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="h6"
                          style={{ fontSize: 16 }}
                          component="div"
                          color="text.third"
                        >
                          {percentageScore(probation.questionSet.employeeQuestion)}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                </Stack>
              </>
            )}

            <Typography variant="h6" gutterBottom>
              {t("Manager")}
            </Typography>
            {probation && probation.questionSet && (
              <>
                <Stack spacing={1} marginBottom={4} width={"100%"}>
                  {probation.questionSet.managerQuestion.length > 0 ? (
                    probation.questionSet.managerQuestion.map(
                      (question, index) => (
                        <div key={question.idProbationQuestion}>
                          <Typography gutterBottom fontWeight={500}>
                            {`${t("Question")} ${index + 1} : ${question.question}`}
                          </Typography>
                          {question.questionType === "choice" ? (
                            <>
                              <Typography color="text.secondary">{`${t("Weight")} ${question.weight}%`}</Typography>
                              <RadioGroup row value={question.answer}>
                                {probation.questionSet.answerScale === 6
                                  ? answerQuestion6Scale.map((item) => (
                                      <FormControlLabel
                                        key={item.key}
                                        value={item.value}
                                        control={<Radio />}
                                        label={t(item.label)}
                                      />
                                    ))
                                  : answerQuestion5Scale.map((item) => (
                                      <FormControlLabel
                                        key={item.key}
                                        value={item.value}
                                        control={<Radio />}
                                        label={t(item.label)}
                                      />
                                    ))}
                              </RadioGroup>
                            </>
                          ) : (
                            <TextFieldTheme
                              fullWidth
                              value={question.answer}
                              InputProps={{ readOnly: true }}
                            />
                          )}
                          <Divider sx={{ borderColor: "#e2e2e2", marginTop: "8px" }} />
                        </div>
                      )
                    )
                  ) : (
                    <Typography textAlign="center" color="text.secondary">
                      {t("NoQuestions")}
                    </Typography>
                  )}
                </Stack>
                <Stack spacing={2} marginBottom={4}>
                  <div>
                    <Typography gutterBottom>{t("Strength")}</Typography>
                    <TextFieldTheme
                      multiline
                      fullWidth
                      value={probation.goodPoint}
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                  <div>
                    <Typography gutterBottom>{t("Weakness")}</Typography>
                    <TextFieldTheme
                      multiline
                      fullWidth
                      value={probation.weakPoint}
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                  <div>
                    <Typography gutterBottom>{t("Other")}</Typography>
                    <TextFieldTheme
                      multiline
                      fullWidth
                      value={probation.others}
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                  <div>
                    <Typography gutterBottom>
                      {t("FeedbackFromSupervisor")}
                    </Typography>
                    <TextFieldTheme
                      multiline
                      fullWidth
                      value={probation.managerComment}
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
                    <Box style={{ position: "relative", display: "inline-flex" }}>
                      <CircularProgress
                        variant="determinate"
                        style={{
                          color: "#eeeeee",
                        }}
                        size={70}
                        thickness={4}
                        value={100}
                      />
                      <CircularProgress
                        variant="determinate"
                        value={percentageScore(
                          probation.questionSet.managerQuestion
                        )}
                        style={{
                          color: "#007afe",
                          animationDuration: "550ms",
                          position: "absolute",
                          left: 0,
                        }}
                        size={70}
                      ></CircularProgress>
                      <Box
                        style={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="h6"
                          style={{ fontSize: 16 }}
                          component="div"
                          color="text.third"
                        >
                          {percentageScore(
                            probation.questionSet.managerQuestion
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <Typography fontWeight={500} color="text.secondary">
                      {t("ProbationResult")}
                    </Typography>
                    {dayjs(probation.resignDate).isBefore(probation.probationDate) ? (
                      <Chip label={t("Terminated")} color="error" />
                    ) : initialProbationResult === 1 ? (
                      <Chip label={t("Pass")} color="success" />
                    ) : initialProbationResult === 0 ? (
                      <Chip label={t("NotPass")} color="error" />
                    ) : (
                      <Chip label={t("WaitingForEvaluation")} color="warning" />
                    )}
                  </div>
                  <Typography fontWeight={500} color="text.secondary">
                    {`${t("EvaluatedBy")} : ${
                      probation.adminResult
                        ? t("Administrator")
                        : i18n.resolvedLanguage === "th"
                        ? probation.approvedBy
                        : probation.approvedBy_EN
                    }`}
                  </Typography>
                </Stack>
              </>
            )}
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  align: "center",
                  gap: 8,
                }}
              >
                <ButtonBlue
                  sx={{ backgroundColor: "#00c853" }}
                  variant="contained"
                  disabled={probationResult === 1}
                  onClick={() => setProbationResult(1)}
                >{`${t("PassedProbation")}`}</ButtonBlue>
                <ButtonBlue
                  sx={{ backgroundColor: "#ff5252" }}
                  variant="contained"
                  disabled={probationResult === 0}
                  onClick={() => setProbationResult(0)}
                >{`${t("NotPassedProbation")}`}</ButtonBlue>
              </div>
            </Grid>
            {probation.reasonExpandTime && (
              <Grid item xs={12}>
                <Typography marginBottom={1}>
                  {t("ReasonForExtendingProbation")}
                </Typography>
                <TextFieldTheme
                  inputProps={{ readOnly: true }}
                  multiline
                  rows={3}
                  value={probation.reasonExpandTime || ""}
                />
              </Grid>
            )}
            {probation.reasonTermination && (
              <div>
                <Typography gutterBottom>
                  {t("ReasonForNotPassingProbation")}
                </Typography>
                <TextFieldTheme
                  inputProps={{ readOnly: true }}
                  multiline
                  rows={3}
                  value={
                    probation.reasonTermination ||
                    t("ReasonForNotPassingProbation")
                  }
                />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <ButtonBlue onClick={handleClose}>{t("Close")}</ButtonBlue>
            <ButtonBlue variant="contained" onClick={clickSubmit}>
              {t("EditData")}
            </ButtonBlue>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default DialogProbation;
