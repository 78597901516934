import React, { useState, useEffect } from "react"; // Import useEffect
import {
    Calendar as BigCalendar,
    momentLocalizer,
} from "react-big-calendar";
import moment from "moment";
import "moment/locale/th";
import "moment/locale/zh-cn";
import "moment/locale/ja";
import "moment/locale/km";
import "moment/locale/ko";
import "moment/locale/lo";
import "moment/locale/my";
import "moment/locale/vi";
import { Box, Button, IconButton, Typography } from '@mui/material'; // Ensure to import necessary components from MUI
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Adjust the import based on your icon library
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { CakeOutlined } from "@mui/icons-material";


const localizer = momentLocalizer(moment);

const locales = {
    th: 'th',
    en: 'en',
    cn: 'zh-cn',
    jp: 'ja',
    kh: 'km',
    kr: 'ko',
    la: 'lo',
    mm: 'my',
    vn: 'vi',
};

export default function Calendar(props) {
    const { t, events, i18n, birthday } = props;
    const [locale, setLocale] = useState('th');
    const [currentView, setCurrentView] = useState('month'); // Set default view to 'agenda'
    const [date, setDate] = useState(moment().startOf('month').toDate());

    const goToToday = () => {
        setDate(new Date());
        setCurrentView('month');
    };

    const goToBack = () => {
        setDate(moment(date).subtract(1, currentView === 'month' ? 'month' : 'day').toDate());
    };

    const goToBirthday = () => {
        setDate(moment(birthday).toDate());
    };

    const goToNext = () => {
        setDate(moment(date).add(1, currentView === 'month' ? 'month' : 'day').toDate());
    };

    const toggleAgendaView = () => {
        setCurrentView(prevView => (prevView === 'month' ? 'agenda' : 'month'));
        setDate(moment(date).startOf('month').toDate());
    };

    const CustomToolbar = ({ label }) => {
        const formattedDate =
            currentView !== 'day'
                ? moment(date).locale(locale).format('MMMM YYYY')
                : moment(date).locale(locale).format('Do MMMM YYYY');

        return (
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 2, sm: 0 } }}>
                <ButtonBlue variant="contained" onClick={goToToday}>
                    {t('calendar.today')}
                </ButtonBlue>
                <Box display="flex" alignItems="center" sx={{ flexDirection: 'row' }}>
                    <IconButton onClick={goToBack}>
                        <ExpandMoreIcon sx={{ transform: 'rotate(90deg)' }} />
                    </IconButton>
                    <Typography textAlign='center' variant="h6">{formattedDate}</Typography>
                    <IconButton onClick={goToNext}>
                        <ExpandMoreIcon sx={{ transform: 'rotate(-90deg)' }} />
                    </IconButton>
                </Box>
                <ButtonBlue variant="contained" onClick={toggleAgendaView}>
                    {currentView === 'month' ? t('calendar.agenda') : t('calendar.month')}
                </ButtonBlue>
            </Box>
        );
    };

    const messages = {
        today: t('calendar.today'),
        previous: t('calendar.previous'),
        next: t('calendar.next'),
        month: t('calendar.month'),
        week: t('calendar.week'),
        day: t('calendar.day'),
        agenda: t('calendar.agenda'),
        noEventsInRange: t('calendar.noEventsInRange'),
    };

    const updateLocale = (lang) => {
        const newLocale = locales[lang] || 'th';
        setLocale(newLocale);
        moment.locale(newLocale);
    };

    useEffect(() => {
        updateLocale(i18n.resolvedLanguage);
    }, [i18n.resolvedLanguage]);

    return (
        <BigCalendar
            {...props}
            localizer={localizer}
            events={events}
            views={['month', 'agenda', 'day']}
            view={currentView}
            messages={messages}
            defaultView={currentView}
            onView={view => {
                setCurrentView(view);
                if (view === 'month') {
                    setDate(moment().startOf('month').toDate());
                }
            }}
            onNavigate={newDate => {
                setDate(moment(newDate).toDate());
            }}
            date={date}
            components={{
                toolbar: CustomToolbar,
            }}
            eventPropGetter={(event) => {
                let backgroundColor;
                switch (event.status) {
                    case 'Absent':
                        backgroundColor = '#FA9696';
                        break;
                    case 'Late':
                        backgroundColor = '#FFB680';
                        break;
                    case 'EarlyOut':
                        backgroundColor = '#FFB680';
                        break;
                    default:
                        backgroundColor = '#fecf81';
                }
        
                return {
                    style: {
                        backgroundColor,
                        // color: backgroundColor === '#fecf81' ? 'inherit' : 'white', 
                    },
                };
            }}
            style={{ height: 500, width: '100%' }}
        />
    );
}
