import React, { Fragment } from "react";
import {
  Typography,
  Grid,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { useSelector } from "react-redux";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../../utils/userData";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
  "& .btn-save": {
    marginTop: 16,
    display: "flex",
    justifyContent: "flex-end",
  },
});

const DialogExitInterviewShow = (props) => {
  const { open, handleClose, detail } = props;
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);

  return (
    <DrawerCustom
      title={`${t("ResignationForm")}`}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Box style={{ padding: "24px" }}>
            {userProfile && (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={12}>
                  <Typography sx={{ display: "inline-block" }}>
                    <i className="fa-regular fa-user fa-xl"></i>
                    &nbsp;&nbsp;
                  </Typography>
                  <Typography
                    sx={{ display: "inline-block", fontWeight: "medium" }}
                  >
                    {`${t("TransactionExecutor")} : ${getUserFullName(userProfile)}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography sx={{ display: "inline-block" }}>
                    <i className="fa-regular fa-building-columns fa-xl"></i>
                    &nbsp;&nbsp;
                  </Typography>
                  <Typography
                    sx={{ display: "inline-block", fontWeight: "medium" }}
                  >
                    {`${t("Position")} : ${userProfile.positionName}`}{" "}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Box>
          <Box style={{ paddingLeft: "24px" }}>
            {detail.GroupExitInterview.map((question, index) => (
              <Grid key={question.id} container className="GridContact">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  marginTop={2}
                  className="GridTopicInput"
                >
                  <Grid item xs={12} sm={12}>
                    <i className="fa-regular fa-square-question fa-bounce fa-xl"></i>
                    &nbsp;&nbsp;
                    {`${t("Question")} ${index + 1} : ${question.question}`}
                  </Grid>
                </Grid>
                <Grid item xs={12} marginTop={1} sm={12}>
                  <Fragment>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      value={question.answer}
                    >
                      <FormControlLabel
                        value="verygood"
                        control={<Radio />}
                        label={`${t("Excellence")}`}
                      />
                      <FormControlLabel
                        value="good"
                        control={<Radio />}
                        label={`${t("Good")}`}
                      />
                      <FormControlLabel
                        value="meduim"
                        control={<Radio />}
                        label={`${t("Neutral")}`}
                      />
                      <FormControlLabel
                        value="fair"
                        control={<Radio />}
                        label={`${t("Fair")}`}
                      />
                      <FormControlLabel
                        value="prettybad"
                        control={<Radio />}
                        label={`${t("Poor")}`}
                      />
                      <FormControlLabel
                        value="bad"
                        control={<Radio />}
                        label={`${t("VeryPoor")}`}
                      />
                    </RadioGroup>
                  </Fragment>
                </Grid>
                <Grid item xs={12}>
                  <Divider
                    sx={{
                      marginTop: "16px",
                      borderColor: "#9e9e9e66",
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </Box>

          {detail.GroupExitInterview.length <= 0 && (
            <Grid item xs={12}>
              <Typography textAlign="center" color="text.secondary">{`${t(
                "NoQuestions"
              )}`}</Typography>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3} style={{ marginBottom: 16 }}>
          <Grid item xs={12} sm={12}>
            <Typography marginBottom={1}>{`${t("Other")}`}</Typography>
            <TextFieldTheme multiline rows={3} value={detail.others} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography marginBottom={1}>{`${t(
              "ResignationReason"
            )}`}</Typography>
            <TextFieldTheme value={detail.reason} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography marginBottom={1}>{`${t("EffectiveDate")}`}</Typography>
            <TextFieldTheme
              value={dayjs(detail.dateStart).format(
                i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
              )}
            />
          </Grid>
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleClose}>{`${t(
            "Close"
          )}`}</ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogExitInterviewShow;
