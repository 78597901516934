import React, { useState } from "react";
import { Box, Grid, Button, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import Form1 from "./components/Form1";
import Form2 from "./components/Form2";
import Form3 from "./components/Form3";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiStepIcon-root.Mui-active": {
      color: "#007afe",
    },
    "& .MuiStepIcon-root.Mui-completed": {
      color: "#007afe",
    },
  },
  stepperBox: {
    marginTop: theme.spacing(4),
  },
  form: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  stepperActions: {
    marginBottom: theme.spacing(2),
  },
  button: {
    borderColor: "#007afe",
    color: "#ffffff",
    backgroundColor: "#007afe",
    borderRadius: 8,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "&:hover": {
      borderColor: "#0046b7",
      backgroundColor: "#0046b7",
    },
  },
  disabledButton: {
    backgroundColor: "rgba(0,0,0,0.12)",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  wrapIcon: {
    display: "flex",
    alignItems: "center",
  },
}));

const steps = ["1", "2", "3"];

function TaxForm(props) {
  const { t, i18n } = useTranslation();
  const { control, setValue, watch, errors, clearErrors, isDisabled } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const totalSteps = steps.length;
  const completedSteps = Object.keys(completed).length;
  const allStepCompleted = completedSteps === totalSteps;

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.stepperBox}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepLabel></StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box className={classes.form}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} md={9} lg={9}>
            {activeStep === 0 ? (
              <Form1
                control={control}
                watch={watch}
                setValue={setValue}
                isDisabled={isDisabled}
              />
            ) : activeStep === 1 ? (
              <Form2
                control={control}
                setValue={setValue}
                watch={watch}
                errors={errors}
                clearErrors={clearErrors}
                isDisabled={isDisabled}
              />
            ) : activeStep === 2 ? (
              <Form3
                control={control}
                setValue={setValue}
                watch={watch}
                errors={errors}
                clearErrors={clearErrors}
                isDisabled={isDisabled}
              />
            ) : null}
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box
        className={classes.stepperActions}
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2,
        }}
      >
        <Button
          className={activeStep === 0 ? classes.disabledButton : classes.button}
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
          startIcon={<KeyboardArrowLeftIcon />}
        >
          {`${t("Back")}`}
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        {completedSteps === totalSteps - 1 ? (
          <>
            <Button
              className={
                activeStep === totalSteps - 1
                  ? classes.disabledButton
                  : classes.button
              }
              onClick={handleNext}
              disabled={activeStep === totalSteps - 1}
              sx={{ mr: 1 }}
              endIcon={<KeyboardArrowRightIcon />}
            >
              {`${t("Next")}`}
            </Button>
          </>
        ) : (
          <Button
            className={classes.button}
            onClick={handleNext}
            sx={{ mr: 1 }}
            endIcon={<KeyboardArrowRightIcon />}
          >
            {`${t("Next")}`}
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default TaxForm;
