import React, { Fragment, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { getAllStatusManpower, getManpowerById, updateManpowerStatus } from "../../../../../../../actions/manpower";

import {
	Box,
	Divider,
	Grid,
	IconButton,
	MenuItem,
	styled,
	Typography
} from "@mui/material";
import {
	Add,
	Delete
} from "@mui/icons-material";

import DrawerCustom from '../../../../../shared/general/Drawer';
import TextFieldTheme from '../../../../../shared/general/TextFieldTheme';
import ButtonBlue from '../../../../../shared/general/ButtonBlue';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import NumberFormatTheme from '../../../../../shared/general/NumberFormatTheme';
import DatePickerCustom from '../../../../../shared/date/datePicker';

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const StyledRoot = styled("div")({
	width: 450,
	padding: 16,
});

const StyledDivider = styled(Divider)({
	margin: "15px 0",
	borderBottom: "2px dashed rgba(145, 158, 171, 0.2)"
});

const DrawerStatus = (props) => {
	const { result: ManpowerProfile } = useSelector(state => state.manpowerProfile);
	const dispatch = useDispatch();
	const { open, onClose, idManpower, isEdit, data } = props;
	// console.log(isEdit);
		const { control, handleSubmit, formState: { errors, dirtyFields, isDirty }, setValue} = useForm({

			defaultValues: {
				// statusManpower: data.idManpowerStatus || "",
				statusManpower: "",
			},

		});

	const handleClose = () => {
		onClose();
	};

	const onSubmitHandler = async (submitData) => {
		// console.log(submitData.statusManpower);
		// console.log(idManpower);
		// console.log(ManpowerProfile);
		dispatch(updateManpowerStatus(ManpowerProfile.idManpower, { idManpowerStatus: submitData.statusManpower })).then(res => {
			dispatch(getManpowerById(ManpowerProfile.idManpower));
		})
		onClose();

	};

	const [DataAllStatusManpower, SetDataAllStatusManpower] = useState([]);
	// const [DataAllStatusManpower, SetDataAllStatusManpower] = useState(
	// 	[
	// 		{
	// 			"idManpowerStatus": 1,
	// 			"statusName": "สำเร็จ"
	// 		},
	// 		{
	// 			"idManpowerStatus": 2,
	// 			"statusName": "ไม่สำเร็จ"
	// 		},
	// 		{
	// 			"idManpowerStatus": 3,
	// 			"statusName": "รอดำเนินการ"
	// 		},
	// 		{
	// 			"idManpowerStatus": 4,
	// 			"statusName": "รอสัมภาษณ์"
	// 		},
	// 		{
	// 			"idManpowerStatus": 5,
	// 			"statusName": "รอผลสัมภาษณ์"
	// 		},
	// 		{
	// 			"idManpowerStatus": 6,
	// 			"statusName": "ว่าง"
	// 		},
	// 		{
	// 			"idManpowerStatus": 7,
	// 			"statusName": "ไม่ว่าง"
	// 		},
	// 		{
	// 			"idManpowerStatus": 8,
	// 			"statusName": "ยกเลิก"
	// 		},
	// 		{
	// 			"idManpowerStatus": 9,
	// 			"statusName": "ไม่ถูกนัดสัมภาษณ์"
	// 		}
	// 	]);

	useEffect(() => {
		const fetchData = () => {
			dispatch(getAllStatusManpower())
				.then(res => {
					SetDataAllStatusManpower(res.data);
					if (data.idManpowerStatus && res.data.some(status => status.idManpowerStatus === data.idManpowerStatus)) {
                        setValue('statusManpower', data.idManpowerStatus);
                    } else {
                        setValue('statusManpower', '');
                    }
				})
				.catch(error => {
					console.error('Failed to fetch all data StatusManpower :', error);
				});
		};

		fetchData();
	}, []);

	// if (DataAllStatusManpower) {
	// 	console.log(DataAllStatusManpower);
	// 	console.log(DataAllStatusManpower.length);
	// }


	return (
		<DrawerCustom
			open={open}
			title={data.idManpowerStatus ? "แก้ไขสถานะ" : "เพิ่มสถานะ"}
			anchor="right"
		>
			<StyledRoot>
				<form onSubmit={handleSubmit(onSubmitHandler)}>

					<Grid container spacing={2}>

						<Grid item xs={12}>
							<Controller
								name={`statusManpower`}
								control={control}
								rules={{
									required: { value: true, message: "กรุณาเลือกสเตตัส" },
								}}
								render={({ field }) => (
									<Fragment>
										<Typography gutterBottom sx={{ color: "#ffffff" }}>สเตตัส</Typography>
										<TextFieldTheme
											{...field}
											placeholder="สเตตัส"
											helperText={errors && errors.statusManpower && errors.statusManpower.message}
											error={errors && errors.statusManpower ? true : false}
											select
										>
											{/* {DataAllStatusManpower &&
												DataAllStatusManpower.map((status) => (
													<MenuItem key={status.idManpowerStatus} value={status.idManpowerStatus}>
														{status.statusName}
													</MenuItem>
												))} */}
											{DataAllStatusManpower && DataAllStatusManpower.length > 0 ? (
												DataAllStatusManpower.map((status) => (
													<MenuItem key={status.idManpowerStatus} value={status.idManpowerStatus}>
														{status.statusName}
													</MenuItem>
												))
											) : (
												<MenuItem disabled>กำลังโหลด...</MenuItem>
											)}
										</TextFieldTheme>
									</Fragment>
								)}
							/>
						</Grid>
					</Grid>

					<div style={{ display: "flex", justifyContent: "space-between", margin: "20px 0" }}>
						<ButtonBlue
							variant="outlined"
							onClick={handleClose}
						>ยกเลิก</ButtonBlue>
						<ButtonBlue
							type="submit"
							variant="contained"
							disabled={!isDirty}
						>ยืนยัน</ButtonBlue>
					</div>
				</form>
			</StyledRoot>
		</DrawerCustom>
	);
}

export default DrawerStatus;