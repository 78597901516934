import React, { useState, useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { Box } from "@mui/material";

const mapStyles = {
  position: "relative",
  width: "100%",
  height: "200px",
  borderRadius: "10px",
  margin: "auto",
};

const loader = new Loader({
  apiKey: "AIzaSyBJEmVrMq5Zgj27dilri-OK76pn7pgQCho",
  version: "weekly",
  libraries: ["places", "drawing", "geometry"],
});

function ListMap(props) {
  const { id, positions, data } = props;

  let map, marker, circle;

  const onMapLoad = () => {
    const google = window.google;

    if (positions && positions.length > 0) {
      const bounds = new google.maps.LatLngBounds();

      positions.forEach((pos) => {
        bounds.extend(new google.maps.LatLng(pos.lat, pos.lng));
      });

      // Create a map with the bounds' center
      map = new google.maps.Map(document.getElementById(id), {
        center: bounds.getCenter(),
        zoom: 14,
      });

      // Create a polygon with the positions
      const locationPolygon = new google.maps.Polygon({
        paths: positions.map((pos) => new google.maps.LatLng(pos.lat, pos.lng)),
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      });

      locationPolygon.setMap(map);
    } else {
      if (data && data.lat && data.lng) {
        const myLatlng = new google.maps.LatLng(data.lat, data.lng);

        const myOptions = {
          zoom: 16,
          center: myLatlng,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        };

        map = new google.maps.Map(document.getElementById(id), myOptions);

        if (google.maps.marker && google.maps.marker.AdvancedMarkerElement) {
          marker = new google.maps.marker.AdvancedMarkerElement({
            position: myLatlng,
            map: map,
          });
        } else {
          marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
          });
        }

        // Create a circle around the location
        circle = new google.maps.Circle({
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          map: map,
          center: myLatlng,
          radius: data.radius,
        });

        map.setCenter(myLatlng);
      }
    }
  };

  useEffect(() => {
    if (positions) {
      loader.load().then(() => {
        onMapLoad();
      });
    }
  }, [positions]);

  return (
    <Box>
      <div id={id} style={mapStyles}>
        Loading ...
      </div>
    </Box>
  );
}

export default ListMap;