import {
    EMAIL_FETCHING,
    EMAIL_FAILED,
    EMAIL_SUCCESS,
} from "./types";
import EmailService from "../services/email.service";

export const addEmailReport = (data) => async () => {
    try {
        const res = await EmailService.addEmailReport(data);
        if (res) {
            console.log(res);
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const getEmailReportById = (id,type) => async (dispatch) => {
    try {
        dispatch({
            type: EMAIL_FETCHING,
        });
        const res = await EmailService.getEmailReportById(id,type);
        if (res) {
            dispatch({
                type: EMAIL_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: EMAIL_FAILED,
        });
    }
};