import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import dayjs from 'dayjs';

import {
	Autocomplete,
	Box,
	Divider,
	Grid,
	IconButton,
	styled,
	Typography
} from "@mui/material";
import {
	Add,
	Delete
} from "@mui/icons-material";

import ButtonBlue from '../../../../../shared/general/ButtonBlue';
import DrawerCustom from '../../../../../shared/general/Drawer';
import TextFieldTheme from '../../../../../shared/general/TextFieldTheme';
import { getManpowerById, addManpowerExperience, updateManpowerExperience } from "../../../../../../../actions/manpower";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import DatePickerCustom from '../../../../../shared/date/datePicker';
import { getJobGroup } from '../../../../../../../actions/jobRecruit';

dayjs.extend(isSameOrAfter);

const StyledRoot = styled("div")({
	width: 450,
	padding: 16,
});

const StyledDivider = styled(Divider)({
	margin: "15px 0",
	borderBottom: "2px dashed rgba(145, 158, 171, 0.2)"
});

const DrawerExperience = (props) => {

	const dispatch = useDispatch();

	const { open, onClose, idManpower, isEdit, data, idManpowerExperience, jobRecruitGroup } = props;
	// useSelector((state) => console.log(state));
	const { result: AllJobGroups } = useSelector((state) => state.jobRecruitGroup);
	// console.log(AllJobGroups)
	// console.log(data)
	// console.log(isEdit)
	const { control, handleSubmit, getValues, reset, formState: { errors, dirtyFields } } = useForm({
		defaultValues: {
			// startYear: null,
			// endYear: null,
			startDate: null,
			endDate: null,
			companyName: "",
			positionName: "",
			jobGroup: null,
		}

	});

	const handleClose = () => {
		onClose();
	};

	const onSubmitHandler = async (submitData) => {
		// console.log(submitData['jobGroup']);
		// console.log(submitData.endDate)
		if (!isEdit) {
			const formData = {};

			for (const key in submitData) {
				if (key === 'jobGroup') {
					formData['idJobGroup'] = submitData['jobGroup'].idJobGroup || "";
					continue;
				}
				if (key === 'startDate' || key === 'endDate') {
					formData[key] = dayjs(submitData[key]).format('YYYY-MM-DD');
					continue
				}
				formData[key] = submitData[key];
			}

			dispatch(addManpowerExperience(idManpower, formData)).then(res => {
				dispatch(getManpowerById(idManpower));
			})
				// 	console.log(key)
				// 	if(key === 'startDate' || 'endDate'){
				// 		formData[key] = dayjs(submitData[key]).format('YYYY-MM-DD');
				// 	}
				// }
				// if(key === 'startYear' || key === 'endYear') {
				// 	formData[key] = dayjs(submitData[key]).get("year");
				// 	continue;
				// }
			
			// dispatch(addManpowerExperience(idManpower, formData)).then(res => {
			// 	dispatch(getManpowerById(idManpower));
			// })
			onClose();

		} else {

			const formData = {};
			for (const key in dirtyFields) {
				if(key === 'jobGroup'){
					formData['idJobGroup'] = submitData['jobGroup'].idJobGroup || "";
					continue
				}
				if (key === 'startDate' || key === 'endDate') {
					formData[key] = dayjs(submitData[key]).format('YYYY-MM-DD');
					continue
				}
				formData[key] = submitData[key];
			}
			// for (const key in dirtyFields) {
			// 	if(key === 'jobGroup'){
			// 		formData['idJobGroup'] = submitData['jobGroup'].idJobGroup || "";
			// 		continue
			// 	}
			// 	if(key === 'startYear' || key === 'endYear') {
			// 		formData[key] = dayjs(submitData[key]).get("year");
			// 		continue;
			// 	}
			// 	formData[key] = submitData[key];
			// }
			dispatch(updateManpowerExperience(idManpower, idManpowerExperience, formData)).then(res => {
				dispatch(getManpowerById(idManpower));
			})
			onClose();

		}
	};

	useEffect(() => {
		  dispatch(getJobGroup());
	  },[]);

	useEffect(() => {
		if (isEdit && data) {
			reset({
				startDate: data.startDate ? data.startDate : null,
				endDate: data.endDate ? data.endDate : null,
				companyName: data.companyName || "",
				positionName: data.positionName || "",
				jobGroup: AllJobGroups ? AllJobGroups.find(j => j.idJobGroup === data.idJobGroup) : null,
			})
		}
	}, [AllJobGroups]);

	return (
		<DrawerCustom
			open={open}
			title={isEdit ? "แก้ไขประสบการณ์ทำงาน" : "เพิ่มประสบการณ์ทำงาน"}
			anchor="right"
		>
			<StyledRoot>
				<form onSubmit={handleSubmit(onSubmitHandler)}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<Controller
								// name={`startYear`}
								name={`startDate`}
								control={control}
								rules={{
									// required: {value: true, message: "กรุณาเลือกปีที่เริ่มงาน"},
									// validate: {
									// 	endDateMoreThanstart: () => {
									// 		if(!getValues(`startYear`) || !getValues(`endYear`)){
									// 			return true;
									// 		} else if(dayjs(getValues(`endYear`)).isSameOrAfter(dayjs(getValues(`startYear`)), 'year')){
									// 			return true;
									// 		} else {
									// 			return "ไม่สามารถเลือกวันออกจากงานก่อนวันเริ่มเริ่มงานได้"
									// 		}
									// 	}
									required: { value: true, message: "กรุณาเลือกวันที่เริ่มงาน" },
									validate: {
										endDateMoreThanStart: () => {
											if (!getValues('startDate') || !getValues('endDate')) {
												return true;
											} else if (dayjs(getValues('endDate')).isSameOrAfter(dayjs(getValues('startDate')), 'day')) {
												return true;
											} else {
												return "ไม่สามารถเลือกวันออกจากงานก่อนวันเริ่มงานได้";
											}
										}
									}
									// }
								}}
								render={({ field }) => (
									<DatePickerCustom
										{...field}
										placeholder="เริ่มงาน"
										// views={["year"]}
										// openTo="year"
										views={["year", "month", "day"]}
										openTo="day"
										maxDate={dayjs()}
										renderInput={(params) => (
											<Fragment>
												<Typography gutterBottom sx={{ color: "#ffffff" }}>เริ่มงาน</Typography>
												<TextFieldTheme
													{...params}
													inputProps={{
														...params.inputProps,
														placeholder: "เริ่มงาน",
														readOnly: true
													}}
													helperText={errors && errors.startDate && errors.startDate.message}
													error={errors && errors.startDate ? true : false}
												/>
											</Fragment>
										)}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controller
								name={`endDate`}
								control={control}
								rules={{
									// required: {value: true, message: "กรุณาเลือกปีที่ออกจากงาน"},
									// validate: {
									// 	endDateMoreThanstart: () => {
									// 		if(!getValues(`startYear`) || !getValues(`endYear`)){
									// 			return true;
									// 		} else if(dayjs(getValues(`endYear`)).isSameOrAfter(dayjs(getValues(`startYear`)), 'year')){
									// 			return true;
									// 		} else {
									// 			return "ไม่สามารถเลือกวันออกจากงานก่อนวันเริ่มเริ่มงานได้"
									// 		}
									// 	}
									// }
									required: { value: true, message: "กรุณาเลือกวันที่ออกจากงาน" },
									validate: {
										endDateMoreThanstart: () => {
											if (!getValues(`startDate`) || !getValues(`endDate`)) {
												return true;
											} else if (dayjs(getValues(`endDate`)).isSameOrAfter(dayjs(getValues(`startDate`)), 'day')) {
												return true;
											} else {
												return "ไม่สามารถเลือกวันออกจากงานก่อนวันเริ่มเริ่มงานได้"
											}
										}
									}
								}}
								render={({ field }) => (
									<DatePickerCustom
										{...field}
										placeholder="ออกจากงาน"
										views={["year", "month", "day"]}
										openTo="day"
										maxDate={dayjs()}
										renderInput={(params) => (
											<Fragment>
												<Typography gutterBottom sx={{ color: "#ffffff" }}>ออกจากงาน</Typography>
												<TextFieldTheme
													{...params}
													inputProps={{
														...params.inputProps,
														placeholder: "ออกจากงาน",
														readOnly: true
													}}
													helperText={errors && errors.endDate && errors.endDate.message}
													error={errors && errors.endDate ? true : false}
												/>
											</Fragment>
										)}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name={`companyName`}
								control={control}
								rules={{
									required: { value: true, message: "กรุณากรอกชื่อบริษัท" },
								}}
								render={({ field }) => (
									<Fragment>
										<Typography gutterBottom sx={{ color: "#ffffff" }}>ชื่อบริษัท</Typography>
										<TextFieldTheme
											{...field}
											placeholder="ชื่อบริษัท"
											inputProps={{
												maxLength: 50,
											  }}
											helperText={errors && errors.companyName && errors.companyName.message}
											error={errors && errors.companyName ? true : false}
										/>
									</Fragment>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name={`positionName`}
								control={control}
								rules={{
									required: { value: true, message: "กรุณากรอกตำแหน่งงาน" },
								}}
								render={({ field }) => (
									<Fragment>
										<Typography gutterBottom sx={{ color: "#ffffff" }}>ตำแหน่งงาน</Typography>
										<TextFieldTheme
											{...field}
											placeholder="ตำแหน่งงาน"
											inputProps={{
												maxLength: 45,
											  }}
											helperText={errors && errors.positionName && errors.positionName.message}
											error={errors && errors.positionName ? true : false}
										/>
									</Fragment>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							{AllJobGroups && <Controller
								name={`jobGroup`}
								control={control}
								rules={{
									required: { value: true, message: "กรุณากรอกกลุ่มงาน" },
								}}
								render={({ field, formState }) => (
									<Autocomplete
										sx={{
											"& .MuiInputBase-root": {
												paddingLeft: 0,
												paddingTop: 0,
												paddingBottom: 0,
												"& input": {
													padding: "13.5px !important"
												}
											}
										}}
										options={[...AllJobGroups].sort((a, b) => a.jobGroupName.localeCompare(b.jobGroupName))}
										getOptionLabel={(option) => option.jobGroupName}
										isOptionEqualToValue={(option, value) => (option.idJobGroup === value.idJobGroup)}
										renderOption={(props, option) => (
											<li {...props} key={option.idJobGroup}>
												{option.jobGroupName}
											</li>
										)}
										renderInput={(params) => (
											<Fragment>
												<Typography gutterBottom sx={{ color: "#ffffff" }}>กลุ่มงาน</Typography>
												<TextFieldTheme
													placeholder="กลุ่มงาน"
													{...params}
													error={formState.errors && formState.errors.jobGroup ? true : false}
													helperText={formState.errors && formState.errors.jobGroup ? formState.errors.jobGroup.message : null}
												/>
											</Fragment>
										)}
										noOptionsText="ไม่พบคำที่ค้นหา"
										value={field.value}
										onChange={(_, data) => {
											field.onChange(data);
										}}
									/>
								)}
							/>}
						</Grid>
					</Grid>

					<div style={{ display: "flex", justifyContent: "space-between", margin: "20px 0" }}>
						<ButtonBlue
							variant="outlined"
							onClick={handleClose}
						>ยกเลิก</ButtonBlue>
						<ButtonBlue
							type="submit"
							variant="contained"
							disabled={(isEdit && Object.keys(dirtyFields).length <= 0)}
						>ยืนยัน</ButtonBlue>
					</div>
				</form>
			</StyledRoot>
		</DrawerCustom>
	);
};

export default DrawerExperience;