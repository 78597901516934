import React, { useEffect, useState } from "react";
import { Box, Container, Grid, styled, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import FormOne from "./formOne";
import FormTwo from "./formTwo";
import { useDispatch, useSelector } from "react-redux";
import { getJobGroup } from "../../../../../../actions/jobRecruit";
import FormThree from "./formThree";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { addManpower } from "../../../../../../actions/manpower";
import complete from "./../../../../assets/complete.png";
import Left from "./left";
import { tr } from "date-fns/locale";

const StyledRoot = styled(Box)({
  background: "#FFFFFF !important",
  "& .part-expertise": {
    "& .MuiPaper-root": {
      height: "100%",
      minHeight: 96,
      borderRadius: 16,
      position: "relative",
      padding: 16,
      boxSizing: "border-box",
      userSelect: "none",
      "& .wrap-name": {
        width: 160,
        color: "#919eab",
        "& .MuiTypography-root": {
          fontSize: 20,
        },
      },
      "& .wrap-icon": {
        position: "absolute",
        fontSize: 35,
        color: "#919eab",
        right: 16,
        bottom: 8,
      },
    },
    "& .isSelected": {
      "& .wrap-name": {
        color: "#ffffff",
      },
      "& .wrap-icon": {
        color: "#ffffff",
      },
      backgroundColor:"#775eb9"
    },
  },
  "& .success-wraper": {
    paddingTop: 55,
    textAlign: "center",
    "& .button-wrap": {
      display: "inline-block",
      marginTop: ".6em",
    }
  }
})

const degrees = [
  {
    idDegrees: 1,
    degreeName: "ประถมศึกษา",
  },
  {
    idDegrees: 2,
    degreeName: "มัธยมศึกษา",
  },
  {
    idDegrees: 3,
    degreeName: "ปวช.",
  },
  {
    idDegrees: 4,
    degreeName: "ปวส.",
  },
  {
    idDegrees: 5,
    degreeName: "ปริญญาตรี",
  },
  {
    idDegrees: 6,
    degreeName: "ปริญญาโท",
  },
  {
    idDegrees: 7,
    degreeName: "ปริญญาเอก",
  },
]

const AddManpowerFormPage = () => {

  // const [activeForm, setActiveForm] = useState(0);
  // const matches = useMediaQuery('(min-width:900px)');

  const [isFetching, setIsFetching] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const {result: jobGroups} = useSelector(state => state.jobRecruitGroup)

  const [page, setPage] = useState(1);

  const onNextPage = () => {
    setPage(prev => prev + 1)
  }

  const onPrevPage = () => {
    setPage(prev => prev - 1)
  }

  const dispatch = useDispatch();
  // const history = useHistory();

  const validtionSchema = yup.object().shape({
    title: yup.string().max(50).required("กรุณากรอกคำนำหน้า"),
    firstname: yup.string().max(150).required("กรุณากรอกชื่อ"),
    lastname: yup.string().max(150).required("กรุณากรอกนามสกุล"),
    phoneNumber: yup.string()
      .min(10, "กรุณากรอกเบอร์โทรด้วยตัวเลข 10 หลัก")
      .max(10, "กรุณากรอกเบอร์โทรด้วยตัวเลข 10 หลัก")
      .required("กรุณากรอกเบอร์โทร")
      .matches(/^\d+$/, "กรุณากรอกเฉพาะตัวเลขเท่านั้น"),
    email: yup.string().
      // email(30)
      email("กรุณากรอกรูปแบบ email ให้ถูกต้อง")
      .required("กรุณากรอกอีเมล"),
    dateOfBirth: yup.date().required("กรุณากรอกวันเกิด"),
    resume: yup.mixed(),
    personalId: yup
      .string()
      .min(13, "กรุณากรอกเลขบัตรประชาชนเป็นตัวเลข 13 หลัก")
      .max(13, "กรุณากรอกเลขบัตรประชาชนเป็นตัวเลข 13 หลัก")
      .required()
      .matches(/^\d+$/, "กรุณากรอกเฉพาะตัวเลขเท่านั้น"),
    jobGroupSelect: yup
      .array()
      .min(1, "กรุณาเลือกความเชี่ยวชาญ")
      .max(3, "เลือกความเชี่ยวชาญได้ไม่เกิน 3 ด้าน"),
    workExperienceY: yup.string(),
    workExperienceM: yup.string(),
    educationDegree: yup.string().max(40).required("กรุณาเลือกระดับการศึกษาสูงสุด"),
    currentSalary: yup.lazy((value) => value === "" ? yup.string() : yup.number()),
    expectedSalary: yup.lazy((value) => value === "" ? yup.string() : yup.number()),
    education: yup.array().of(
      yup.object().shape({
        startYear: yup.date().required(),
        endYear: yup.date().min(yup.ref("startYear"), "ไม่สามารถเลือกวันจบการศึกษาก่อนวันเริ่มการศึกษาได้"),
        universityName: yup.string().max(150).required("กรุณากรอกชื่อสถาบัน"),
        degree: yup.string().max(150).required("กรุณาเลือกระดับการศึกษา"),
        faculty: yup.string().max(150),
        major: yup.string().max(150),
      })
    ),
    work: yup.array().of(
      yup.object().shape({
        startDate: yup.date(),
        endDate: yup.date().min(yup.ref("startDate"), "ไม่สามารถเลือกวันออกจากงานก่อนวันเริ่มเริ่มงานได้"),
        companyName: yup.string().max(30).required("กรุณากรอกชื่อบริษัท"),
        positionName: yup.string().max(30).required("กรุณากรอกตำแหน่งงาน"),
        jobGroup: yup.object().required("กรุณากรอกกลุ่มงาน").nullable(),
      })
    ),
    certificates: yup.array().of(
      yup.mixed(), 
    ),
  })

  const { control, handleSubmit, formState: { errors }, getValues, trigger, setError, clearErrors } = useForm({
    defaultValues: {
      title: "",
      firstname: "",
      lastname: "",
      phoneNumber: "",
      email: "",
      dateOfBirth: dayjs(),
      resume: null,
      personalId: "",
      jobGroupSelect: [],
      workExperienceY: "",
      workExperienceM: "",
      educationDegree: "",
      currentSalary: "",
      expectedSalary: "",
      certificates: [],
    },
    resolver: yupResolver(validtionSchema),
  })

  const onFinalSubmit = (values) => {

    const formData = { ...values}
    setIsFetching(true);

    // แก้ใน backend
    // formData.idJobGroup = formData.idJobGroup? formData.idJobGroup: null;
    
    formData.currentSalary = formData.currentSalary ? formData.currentSalary: null;
    formData.expectedSalary = formData.expectedSalary ? formData.expectedSalary: null;
    formData.personalId = formData.personalId ? formData.personalId: null;
    formData.workExperienceY = formData.workExperienceY ? formData.workExperienceY: null;
    formData.workExperienceM = formData.workExperienceM ? formData.workExperienceM: null;

    formData.education = formData.education.map(education => {
      if(education.startYear){
        education.startYear = dayjs(education.startYear).format("YYYY")
      } else {
        education.startYear = null
      }
      if(education.endYear){
        education.endYear = dayjs(education.endYear).format("YYYY")
      } else {
        education.endYear = null
      }

      education.faculty = (education.faculty || null);
      education.gpa = (education.gpa || null);
      education.major = (education.major || null);

      return education
    })

    formData.work = formData.work.map(work => { 
      return {
        // startYear: work.startYear? dayjs(work.startYear).format("YYYY"): null,
        // endYear: work.endYear? dayjs(work.endYear).format("YYYY"): null,
        startDate: work.startDate ? dayjs(work.startDate).format("YYYY-MM-DD") : null,
        endDate: work.endDate ? dayjs(work.endDate).format("YYYY-MM-DD") : null,  
        companyName: work.companyName? work.companyName: null,
        positionName: work.positionName? work.positionName: null,
        idJobGroup: (work.jobGroup && work.jobGroup.idJobGroup) ? work.jobGroup.idJobGroup: null
      }
    })

    const formData2 = new FormData();

    Object.keys(formData).map(key => {
      if(key === "certificates"){
        formData[key].map(f => {
          formData2.append(key, f)
        })
        return;
      }

      if(key === "resume"){
        formData2.append(key, formData[key])
        return;
      }

      formData2.append(key, JSON.stringify(formData[key]))
    
    })
    
    dispatch(addManpower(formData2)).then(res => {
      if(res.status === 201){
        setIsSuccess(true)
      }
      setIsFetching(false);
    })
  }

  useEffect(() => {
    dispatch(getJobGroup());
  }, [dispatch])

  return (
    <StyledRoot className="page">
      {!isSuccess && jobGroups && (
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Left control={control} />
            </Grid>

            <Grid item xs={12} md={8}>
              <Box padding="24px 0">
                <Typography variant="h5" sx={{ color:"#000000" }}>เพิ่มคนของฉัน</Typography>
              </Box>

              {page === 1 && (
                <FormOne
                  onNextPage={onNextPage}
                  control={control}
                  trigger={trigger}
                />
              )}
              {page === 2 && (
                <FormTwo
                  onNextPage={onNextPage}
                  onPrevPage={onPrevPage}
                  control={control}
                  trigger={trigger}
                  errors={errors}
                  degrees={degrees}
                />
              )}
              {page === 3 && (
                <FormThree
                  onFinalSubmit={onFinalSubmit}
                  handleSubmit={handleSubmit}
                  onPrevPage={onPrevPage}
                  degrees={degrees}
                  isFetching={isFetching}
                  control={control}
                  errors={errors}
                  getValues={getValues}
                  setError={setError}
                  clearErrors={clearErrors}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      )}

      {isSuccess && 
        <Container maxWidth="lg">
          <div className="success-wraper">
            <img 
              style={{ width:300, marginBottom:"20px" }}
              src={complete}
            />
            <Typography variant="h4" textAlign="center" sx={{color:"#000000"}}>เพิ่มคนของฉันสำเร็จ</Typography>
            <div className="button-wrap">
              <ButtonBlue variant="contained" component={Link} to="/manage/hunter/peer">คนของฉัน</ButtonBlue>
            </div>
          </div>
        </Container>
      }
    </StyledRoot>
  )
}

export default AddManpowerFormPage;