import React, { Component } from "react";
import {
    Map,
    GoogleApiWrapper,
    Marker,
    Polygon,
    InfoWindow,
} from "google-maps-react";
import { Box, Typography } from "@mui/material";

const mapStyles = {
    position: "relative",
    width: "100%",
    height: "350px",
    borderRadius: "10px",
};

const infoWindowStyles = {
    paddingRight: "10px",
    paddingLeft: "10px",
};

const textWindowStyles = {
    fontSize: "18px",
    marginTop: 0,
    marginBottom: 0,
};

export class MapContainerpoint extends Component {
    state = {
        activeMarker: {},
        selectedPlace: {},
        showingInfoWindow: false,
        isLoading: true,
        currentLocation: {
            lat: 0,
            lng: 0,
        },
        companyArea: [
            { lat: 12.706941, lng: 101.135534 },
            { lat: 12.703241, lng: 101.133505 },
            { lat: 12.699737, lng: 101.133377 },
            { lat: 12.6995844, lng: 101.1372722 },
            { lat: 12.7069945, lng: 101.1373152 },
            { lat: 12.706941, lng: 101.135534 },
        ],
        companyLocation: [
            {
                lat: 12.70313,
                lng: 101.13537,
            },
        ],
        options: {
            strokeColor: "#ffd600",
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: "#ffd600",
            fillOpacity: 0.5,
        },
    };

    onMarkerClick = (props, marker) =>
        this.setState({
            activeMarker: marker,
            selectedPlace: props,
            showingInfoWindow: true,
        });

    onInfoWindowClose = () =>
        this.setState({
            activeMarker: null,
            showingInfoWindow: false,
        });

    onMapClicked = () => {
        if (this.state.showingInfoWindow)
            this.setState({
                activeMarker: null,
                showingInfoWindow: false,
            });
    };

    onCheckLocationPoly() {
        console.log("onCheckLocationPoly");
        const { google } = this.props;
        const maps = google.maps;

        for (let index = 0; index < this.props.locations.length; index++) {
            console.log(this.props.locations);
            // const element = this.props.locations;

            // const companyPolygon = new maps.Polygon({
            //     paths: element,
            // });

            // var check = maps.geometry.poly.containsLocation(
            //     new maps.LatLng(
            //         this.state.currentLocation.lat,
            //         this.state.currentLocation.lng
            //     ),
                // companyPolygon

                this.props.setMatch(this.props.locations[index].idGpsLocations);
                this.props.addAttendance(this.props.locations[index]);
                return;
            // }

            // maps.geometry.poly.containsLocation(
            //   new maps.LatLng(
            //     this.state.currentLocation.lat,
            //     this.state.currentLocation.lng
            //   ),
            //   companyPolygon
            // )
            //   ? console.log("คุณอยู่ในพื้นที่ทำงาน")
            //   : console.log("คุณอยู่นอกพื้นที่ทำงาน");
        }
    }

    handleSetGpsAddress(address) {
        console.log(address);
        this.props.setGpsAddress(address);
    }

    handleGetGeoCode(currentLatitude, currentLongitude) {
        const { google } = this.props;
        const maps = google.maps;
        const geocoder = new maps.Geocoder();
        var myLatlng = new maps.LatLng(currentLatitude, currentLongitude);

        return geocoder.geocode(
            {
                latLng: myLatlng,
            },
            function (results, status) {
                if (status == maps.GeocoderStatus.OK) {
                    //this.handleSetGpsAddress("aaa");
                    return results;
                } else {
                    alert("Geocoding failed: " + status);
                    console.log("Geocoding failed: " + status);
                }
            }
        );
    } 
    onMapLoad = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                        acc: position.coords.accuracy,
                    };

                    this.setState({
                        currentLocation: {
                            lat: pos.lat,
                            lng: pos.lng,
                        },
                    });

                    //Show map
                    this.setState({
                        isLoading: false,
                    });
                    this.props.setLoadMap(false);
                    var currentLatitude = position.coords.latitude;
                    var currentLongitude = position.coords.longitude;

                    this.handleGetGeoCode(currentLatitude, currentLongitude)
                        .then(({ results }) => {
                            const gpsAddress = results.find((x) =>
                                x.types.includes("administrative_area_level_2") || x.types.includes("sublocality")
                            ).formatted_address;
                            this.props.setGpsAddress(
                                gpsAddress ? gpsAddress : results[0].formatted_address
                            )
                            this.props.setLocations(
                                [currentLatitude,currentLongitude]
                            )
                            console.log("setLocations" + gpsAddress)
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                },
                () => {
                    console.log("Error: The Geolocation service failed.");
                }
            );
        }
    };

    componentDidMount() {
        this.props.setLoadMap(true);
        this.onMapLoad();
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.clickCheck !== prevProps.clickCheck) {
            this.onCheckLocationPoly();
        }
    }

    render() {
        return (
            <>
                {this.state.isLoading ? (
                    <Box>
                        <Typography align="center">Map Loading...</Typography>
                    </Box>
                ) : (
                    <Box>
                        <Map
                            google={this.props.google}
                            style={mapStyles}
                            containerStyle={{ position: "relative" }}
                            className={"map-gps-check"}
                            zoom={16}
                            initialCenter={this.state.currentLocation}
                        >
                            <Marker onClick={this.onMarkerClick} name={"คุณอยู่ที่นี่"} />
                            {this.props.locations && (
                                <Polygon
                                    paths={this.props.locations[0].positions}
                                    options={this.state.options}
                                />
                            )}

                            <InfoWindow
                                marker={this.state.activeMarker}
                                onClose={this.onInfoWindowClose}
                                visible={this.state.showingInfoWindow}
                            >
                                <div style={infoWindowStyles}>
                                    <h1 style={textWindowStyles}>
                                        {this.state.selectedPlace.name}
                                    </h1>
                                </div>
                            </InfoWindow>
                        </Map>
                    </Box>
                )}
            </>
        );
    }
}

export default GoogleApiWrapper({
    //apiKey: "AIzaSyCZ41b0hPCVif4tewQUlrc2aivCyZf2DWc",
    apiKey: "AIzaSyBJEmVrMq5Zgj27dilri-OK76pn7pgQCho",
    libraries: ["geometry", "places"],
})(MapContainerpoint);
