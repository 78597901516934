import dayjs from "dayjs";
import ExcelJS from "exceljs";

export const exportAllToExcel = async (data) => {
  console.log(data);
  if (!Array.isArray(data)) {
    console.error("Data is not an array:", data);
    return;
  }

  const workbook = new ExcelJS.Workbook();
  const worksheet1 = workbook.addWorksheet("Probation");

  const headerRow = worksheet1.addRow([
    "บริษัท",
    "ชื่อ-สกุล",
    "ฝ่าย",
    "ส่วน",
    "แผนก",
    "ตำแหน่ง",
    "สถานะ",
  ]);

  const headerStyle = {
    font: {
      bold: true,
      size: 18,
      name: "TH SarabunPSK",
      color: { argb: "FFFFFF" },
    },
    alignment: { horizontal: "center", vertical: "middle" },
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "002060" },
    },
  };

  headerRow.eachCell((cell) => {
    cell.style = headerStyle;
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
  });

  headerRow.height = 50;

  worksheet1.columns = [
    { header: "บริษัท", key: "company", width: 25 },
    { header: "ชื่อ-สกุล", key: "employee", width: 25 },
    { header: "ฝ่าย", key: "division", width: 20 },
    { header: "ส่วน", key: "department", width: 20 },
    { header: "แผนก", key: "section", width: 20 },
    { header: "ตำแหน่ง", key: "position", width: 20 },
    { header: "สถานะ", key: "status", width: 15 },
  ];

  data.forEach((item) => {
    worksheet1.addRow({
      company: item.companyName || "-",
      employee: `${item.firstname_TH} ${item.lastname_TH}`,
      division: item.divisionName || "-",
      department: item.departmentName || "-",
      section: item.sectionName || "-",
      position: item.positionName || "-",
      status: item.status ? `${parseFloat(item.status).toFixed(2)}%` : "0%",
    });
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const firstEmployee = data.length > 0 ? data[0] : null;
    const fileName = firstEmployee ? `รายงานสถานะ.xlsx` : "รายงานสถานะ.xlsx";

    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  });
};
