import UploadExcelService from "../services/uploadExcel.service";

export const uploadExcelEmployeeHoliday = (formData) => async () => {
  try {
    const res = await UploadExcelService.uploadExcelEmployeeHoliday(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
};

export const uploadExcelAdditionDeductionRecurring = (formData) => async () => {
  try {
    const res = await UploadExcelService.uploadExcelAdditionDeductionRecurring(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
};