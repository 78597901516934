import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ExcelJS from 'exceljs';

export const CostElementXlsx = (data, companyName) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Cost Element");
    dayjs.locale('th');

    const headerRow = worksheet.addRow([
        "ลำดับ",
        "ชื่อรายการ",
        "Cost Code",
        "จำนวนเงิน",
    ]);

    const headerStyle = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '081F5C' } // Grey background color
        },
    };

    headerRow.eachCell((cell) => {
        cell.style = headerStyle;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    headerRow.height = 50;

    const colWidths = [
        { key: "No", width: 30 },
        { key: "title", width: 50 },
        { key: "code", width: 30 },
        { key: "amount", width: 50 },
    ];

    colWidths.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width;
    });

    function formatNumber(number) {
        return number.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    data && data.filter(e => e.isActive === 1).map((item, index) => {
        const row = [
            index + 1,
            item.name,
            item.code ? item.code : "-",
            item.SummaryPayroll ? formatNumber(item.SummaryPayroll) : "0.00",
        ];

        const excelRow = worksheet.addRow(row);
        const contentStyle = {
            font: { size: 18, name: 'TH SarabunPSK' },
            alignment: { horizontal: "center", vertical: 'middle' },
        };

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `UniHR-${companyName}-รายงาน Cost Element.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
};
