import {
  LEAVE_AVAILABLE_FETCHING,
  LEAVE_AVAILABLE_FAILED,
  LEAVE_AVAILABLE_SUCCESS,
} from "../actions/types";

const initialState = {
  result: [],
  isFetching: true,
  isError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LEAVE_AVAILABLE_FETCHING:
      return { ...state, result: [], isFetching: true, isError: false };
    case LEAVE_AVAILABLE_FAILED:
      return { ...state, result: [], isFetching: false, isError: true };
    case LEAVE_AVAILABLE_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    default:
      return state;
  }
}
