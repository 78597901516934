import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  Button,
  Grid,
  Typography,
  DialogTitle,
  TextField
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
// import { TextField, Select, SimpleFileUpload } from "formik-mui";
import { Formik, Form, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import CloseIcon from "@mui/icons-material/Close";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import LabelIcon from "@mui/icons-material/Label";
import DescriptionIcon from "@mui/icons-material/Description";

import { postSubJobStructure } from "../../../../../../actions/subJobStructures";
import { useTranslation } from "react-i18next";



const useStyles = makeStyles(() => ({
  wrapCaptionImage: {
    marginTop: 16,
    textAlign: "center",
    marginTop: 0,
  },
  bigAvatar: {
    width: "84%",
    height: "84%",
    margin: 10,
  },
  paper: {
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#2c387e",
  },
  icon: {
    fontSize: 28,
    color: "white",
  },
  uploadImage: {
    width: "80%",
    zIndex: 8,
  },

  placeholder: {
    width: "100%",
    height: "100%",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "rgb(99, 115, 129)",
    backgroundColor: "rgb(224, 246, 248)",
    transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  placeholderImageProfile: {
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgba(22, 28, 36, .72)",
  },
  placeholderLabel: {
    color: "rgb(255, 255, 255)",
  },
  captionImage: {
    color: "#212b36",
    fontSize: 13,
    alignSelf: "center",
  },
  text: {
    fontSize: 15,
    marginBottom: 2,
    marginLeft: 10,
  },
  icon: {
    fontSize: 18,
    marginBottom: 2,
    marginRight: 2,
  },
}));

const AddSubJob = ({ open, jobId, fetchData, close }) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const formRef = useRef();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({
    name: false,
  });

  const [subJob, setSubJob] = useState({
    name: "",
    description: "",
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(""),
    description: Yup.string().required(""),
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFile) => {
      let formData = new FormData();
      acceptedFile.map((file) => formData.append("file", file));
      setPicture(
        acceptedFile.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      );
    },
    maxFiles: 1,
  });

  const handleClickSave = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const [picture, setPicture] = useState([]);

  const thumbs = picture.map((file) => (
    <img key={file.name} src={file.preview} className={classes.uploadImage} />
  ));

  const [nameSubJob, setNameSubJob] = useState({ name: "" });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSubJob({
      ...subJob,
      [name]: value,
    });
  };

  const onPostSubJobStructure = async (formData) => {
    let imageFile = picture[0];

    if (imageFile !== undefined) {
      const fileName = new Date().getTime() + imageFile.name;
      var newFile = new File([imageFile], fileName, { type: "image/png" });

      var bodyFormData = new FormData();
      bodyFormData.append("file", newFile);
      axios.post("/jobStructures/uploadImage", bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      formData.imageName = fileName;
    } else {
      formData.imageName = null;
    }

    await dispatch(postSubJobStructure(formData));
    close();
    fetchData();
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (subJob.name) {
      const formData = {
        subJobStructureName: subJob.name,
        idJobStructure: jobId,
        description: subJob.description,
      };

      //Post to DB
      onPostSubJobStructure(formData);
    } else {
      setErrors({ name: true });
    }
  };

  useEffect(() => {
    setErrors({ name: false });
  }, []);

  return (
    
      
        <Dialog open={open} fullWidth="sm">
          <CloseIcon
            style={{
              alignSelf: "self-end",
              cursor: "pointer",
              marginRight: 10,
              marginTop: 10,
              marginBottom: 0,
            }}
            onClick={close}
          />
          <DialogTitle
            style={{
              color: "#001c54",
              fontWeight: "bold",
              paddingTop: 0,
              paddingBottom: 10,
              fontSize: 26,
            }}
          >
            {t("CreateNewSubjob")}
          </DialogTitle>

          <Formik
            initialValues={nameSubJob}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              console.log("saved value", values);
              setNameSubJob(values);
            }}
          >
            {({ values }) => (
              <Form
                autoComplete="off"
                maxWidth="false"
                style={{ padding: 20, paddingLeft: 25, paddingRight: 25 }}
              >
                <Grid container direction="column" justifyContent="center">
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    style={{ paddingTop: 0, paddingBottom: 10 }}
                  >
                    <div {...getRootProps({ className: "dropzone" })}>
                      <div className="inner-dropzone">
                        <input {...getInputProps()} />
                        <Fragment>{thumbs}</Fragment>
                        <div
                          className={`placeholder ${classes.placeholder} ${
                            picture.length != 0 &&
                            classes.placeholderImageProfile
                          }`}
                        >
                          <InsertDriveFileIcon />
                          <Typography
                            style={{
                              marginTop: 8,
                              backgroundColor: "transparent",
                            }}
                            className={`${
                              picture.length != 0 && classes.placeholderLabel
                            }`}
                            variant="body2"
                          >
                            {t("Upload")} {t("File")}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div className={classes.wrapCaptionImage}>
                      <Typography variant="caption">
                        * {t("AllowPicture")} *
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    style={{
                      paddingTop: 0,
                      paddingBottom: 10,
                      marginRight: 20,
                    }}
                  >
                    <Field
                      component={TextField}
                      name="name"
                      value={subJob.name}
                      onChange={handleInputChange}
                      id="name"
                      label={t("SubjobName")}
                      required
                      fullWidth
                      size="small"
                      variant="outlined"
                      placeholder={t("SubjobName")}
                      style={{ margin: 10 }}
                      error={errors.name}
                      helperText={
                        errors.name ? t("ThisFieldIsRequired")+"." : null
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    style={{
                      paddingTop: 0,
                      paddingBottom: 10,
                      marginRight: 20,
                    }}
                  >
                    <Field
                      component={TextField}
                      name={t("Description")}
                      value={subJob.description}
                      onChange={handleInputChange}
                      id="description"
                      label={t("Description")}
                      fullWidth
                      size="small"
                      variant="outlined"
                      placeholder={t("Description")}
                      style={{ margin: 10 }}
                    />
                  </Grid>
                  <Grid style={{ textAlign: "right", paddingTop: 10 }}>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={handleOnSubmit}
                    >
                      {t("Submit")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog>
     
    
  );
};

export default AddSubJob;
