import ExcelJS from "exceljs";

const exportToExcel = (data, t) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("OKRs");

  const headerCellStyle = {
    font: {
      name: "Tahoma",
      size: 14,
      color: { argb: "FFFFFF" },
    },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "002060" },
    },
  };

  const dataCellStyle = {
    font: {
      name: "Tahoma",
      size: 12,
    },
    alignment: {
      vertical: "middle",
      horizontal: "center",
    },
  };

  sheet.columns = [
    { header: "รหัสพนักงาน", key: "employeeId", width: 15 },
    { header: "ชื่อ", key: "firstName", width: 20 },
    { header: "นามสกุล", key: "lastName", width: 20 },
    { header: "ฝ่าย", key: "division", width: 15 },
    { header: "ส่วน", key: "section", width: 15 },
    { header: "แผนก", key: "department", width: 15 },
    { header: "ตำแหน่ง", key: "position", width: 20 },
    { header: "ชื่อ Key Result", key: "keyResult", width: 25 },
    { header: "OKR ของ Q", key: "okrQuarter", width: 15 },
    { header: "วันที่เริ่มต้น", key: "startDate", width: 20 },
    { header: "วันที่สิ้นสุด", key: "endDate", width: 20 },
    { header: "สถานะ", key: "status", width: 15 },
    { header: "คำอธิบาย", key: "description", width: 40 },
    { header: "กลยุทธ์", key: "strategy", width: 25 },
    { header: "หลักฐานการวัดผล", key: "measureEvidence", width: 30 },
    { header: "คุณภาพ", key: "quality", width: 15 },
    { header: "ประเภทข้อมูลที่ใช้วัด", key: "dataType", width: 25 },
    { header: "Moonshot", key: "moonshot", width: 15 },
    { header: "ความคืบหน้า (%)", key: "progress", width: 20 },
    { header: "ผู้ประเมิน", key: "evaluator", width: 25 },
    { header: "ประเมิน", key: "evaluation", width: 20 },
    { header: "สถานะการประเมินผล", key: "evaluationStatus", width: 25 },
    { header: "วันเวลาที่ประเมิน", key: "evaluationTime", width: 25 },
    { header: "ประเมินโดย", key: "evaluatedBy", width: 25 },
    { header: "คะแนน OKRs", key: "okrScore", width: 20 },
    { header: "สถานะ", key: "finalStatus", width: 15 },
  ];

  const headerRow = sheet.getRow(1);
  headerRow.height = 25;
  headerRow.eachCell((cell) => {
    cell.style = headerCellStyle;
  });

  data.forEach((item) => {
    const row = sheet.addRow({
      employeeId: item.employeeId,
      firstName: item.firstName,
      lastName: item.lastName,
      division: item.division,
      section: item.section,
      department: item.department,
      position: item.position,
      keyResult: item.keyResult,
      okrQuarter: item.okrQuarter,
      startDate: item.startDate,
      endDate: item.endDate,
      status: item.status,
      description: item.description,
      strategy: item.strategy,
      measureEvidence: item.measureEvidence,
      quality: item.quality,
      dataType:
        item.dataType === 1
          ? t("Number")
          : item.dataType === 2
          ? t("Explanation")
          : "-",
      moonshot: item.moonshot,
      progress: item.progress,
      evaluator: item.evaluator,
      evaluation: item.evaluation,
      evaluationStatus: item.evaluationStatus,
      evaluationTime: item.evaluationTime,
      evaluatedBy: item.evaluatedBy,
      okrScore: item.okrScore,
      finalStatus: item.finalStatus,
    });
    row.height = 20;
    row.eachCell((cell) => {
      cell.style = dataCellStyle;
    });
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "OKRs.xlsx";
    a.click();
    URL.revokeObjectURL(url);
  });
};

export default exportToExcel;
