import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";

import {
  Typography,
  TextField,
  Popper,
  FilledInput,
  Box,
  FormControl,
  InputAdornment,
  Link,
  Drawer,
  Grid,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import SearchIcon from "@mui/icons-material/Search";

import SelectManagers from "../../shared/general/SelectManagers";
import {
  getAffiliate,
  getAffiliateOrganization,
} from "../../../../actions/company";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { getAllEmployees } from "../../../../actions/employee";
import { getManager } from "../../../../actions/manager";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const vendor = [];
const jobGroup = [];

const StyledFilter = styled(Box)({
  "& .first-row": {
    marginBottom: 16,
  },
  "& .more-filter": {
    display: "flex",
    justifyContent: "flex-end",
  },
  marginBottom: 16,
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledPopper = styled(Popper)({
  width: "330px !important",

  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const icon = <i class="fa-regular fa-square" style={{ fontSize: 24 }}></i>;
const checkedIcon = (
  <i class="fa-regular fa-square-check" style={{ fontSize: 24 }}></i>
);

const FilterTable = (props) => {
  const {
    filterEmployee,
    handleChange,
    selected,
    setSelected,
    selectedManager,
    handleSelectedManager,
    handleChangeCompany,
  } = props;
  const dispatch = useDispatch();
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [moreFilter, setMoreFilter] = useState(false);
  const [companyList, setCompanyList] = useState(null);
  const [divisionList, setDivisionList] = useState(null);
  const [departmentList, setDepartmentList] = useState(null);
  const [sectionList, setSectionList] = useState(null);
  const [positionList, setPositionList] = useState(null);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    // dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      let ownCompany = affiliateOrganizationList.find(
        (x) => x.idCompany === userProfile.idCompany
      );

      let company = affiliateOrganizationList[0];

      if(ownCompany){
        company = ownCompany;
      }

      let organization = company.organization

      setSelected({
        ...selected,
        company: {
          idCompany: company.idCompany,
          companyName: company.companyName,
        },
      });
      dispatch(getAllEmployees(null, company.idCompany))
      // dispatch(getManager({idCompany: company.idCompany}))
      renderAllDataSelect(organization);
    }
  }, [userProfile, affiliateOrganizationList]);

  useEffect(() => {
    if (affiliateOrganizationList) {
      let temp = affiliateOrganizationList.map((x) => ({
        idCompany: x.idCompany,
        companyName: x.companyName,
      }));
      setCompanyList(temp);
    }
  }, [affiliateOrganizationList]);

  const renderAllDataSelect = (organization) => {
    let uniqueDivision = [
      ...new Map(
        organization.map((item) => [item["idDivision"], item])
      ).values(),
    ];

    setDivisionList(uniqueDivision.filter((x) => x.idDivision));

    let uniqueDepartment = [
      ...new Map(
        organization.map((item) => [item["idDepartment"], item])
      ).values(),
    ];

    setDepartmentList(uniqueDepartment.filter((x) => x.idDepartment));

    let uniqueSection = [
      ...new Map(
        organization.map((item) => [item["idSection"], item])
      ).values(),
    ];

    setSectionList(uniqueSection.filter((x) => x.idSection));

    let uniquePosition = [
      ...new Map(
        organization.map((item) => [item["idPosition"], item])
      ).values(),
    ];

    setPositionList(uniquePosition.filter((x) => x.idPosition));
  };

  return (
    <StyledFilter>
      <Grid container spacing={2} className="first-row">
        <Grid item xs={12}>
          <div className="more-filter">
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                setMoreFilter(!moreFilter);
              }}
            >
              {moreFilter ? `${t("HideSearch")}` : `${t("SearchMore")}`}
            </Link>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              {t("Search")}
            </Typography>
            <FormControl fullWidth variant="filled">
              <StyledFilledInput
                id="filled-sarch-name"
                placeholder={`${t("Search")}${t("FullName")}`}
                name="search"
                value={filterEmployee.search}
                onChange={handleChange}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </StyledBoxSearch>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledBoxSearch>
            <SelectManagers
              value={selectedManager}
              handleChange={handleSelectedManager}
              position={userProfile && userProfile.idCompany === 3}
            />
          </StyledBoxSearch>
        </Grid>
      </Grid>

      {moreFilter ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("Company")}
              </Typography>
              <StyledAutocomplete
                options={affiliateOrganizationList ? companyList : []}
                onChange={(event, newValue) => {
                  console.log("newValue Company:", newValue);

                  let company = affiliateOrganizationList.find(
                    (x) => x.idCompany === newValue.idCompany
                  );

                  let organization = company.organization;

                  setSelected({
                    company: newValue,
                    division: null,
                    department: null,
                    section: null,
                    position: null,
                  });

                  renderAllDataSelect(organization);
                  handleChangeCompany(newValue);
                }}
                value={selected.company}
                disableClearable={true}
                popupIcon={<i class="fa-light fa-chevron-down"></i>}
                getOptionLabel={(option) => option.companyName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    placeholder={`${t("AllCompany")}`}
                  />
                )}
                PopperComponent={StyledPopper}
                noOptionsText={`${t("NoData")}`}
              />
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("Division")}
              </Typography>
              <StyledAutocomplete
                options={divisionList || []}
                onChange={(event, newValue) => {
                  console.log("newValue Division:", newValue);
                  setSelected({
                    ...selected,
                    division: newValue,
                    department: null,
                    section: null,
                    position: null,
                  });
                }}
                value={selected.division}
                popupIcon={<i class="fa-light fa-chevron-down"></i>}
                getOptionLabel={(option) => option.divisionName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    placeholder={`${t("AllDivision")}`}
                  />
                )}
                PopperComponent={StyledPopper}
                noOptionsText={`${t("NoData")}`}
              />
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("Department")}
              </Typography>
              <StyledAutocomplete
                options={
                  departmentList
                    ? departmentList.filter((x) => {
                        if (selected.division !== null) {
                          return x.idDivision === selected.division.idDivision;
                        } else {
                          return x;
                        }
                      })
                    : []
                }
                value={selected.department}
                onChange={(event, newValue) => {
                  console.log("newValue Department:", newValue);
                  if (newValue === null) {
                    setSelected({
                      ...selected,
                      department: newValue,
                      section: null,
                      position: null,
                    });
                  } else {
                    setSelected({
                      ...selected,
                      division:
                        divisionList && divisionList.length > 0
                          ? divisionList.find(
                              (x) => x.idDivision === newValue.idDivision
                            ) || null
                          : null,
                      department: newValue,
                      section: null,
                      position: null,
                    });
                  }
                }}
                groupBy={(option) => option.divisionName}
                popupIcon={<i class="fa-light fa-chevron-down"></i>}
                getOptionLabel={(option) => option.departmentName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    placeholder={`${t("AllDepartment")}`}
                  />
                )}
                PopperComponent={StyledPopper}
                noOptionsText={`${t("NoData")}`}
              />
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("Section")}
              </Typography>
              <StyledAutocomplete
                options={
                  sectionList
                    ? sectionList.filter((x) => {
                        if (
                          selected.division !== null &&
                          selected.department !== null
                        ) {
                          return (
                            x.idDivision === selected.division.idDivision &&
                            x.idDepartment === selected.department.idDepartment
                          );
                        } else if (selected.division !== null) {
                          return x.idDivision === selected.division.idDivision;
                        } else {
                          return x;
                        }
                      })
                    : []
                }
                value={selected.section}
                onChange={(event, newValue) => {
                  console.log("newValue Section:", newValue);
                  if (newValue === null) {
                    setSelected({
                      ...selected,
                      section: newValue,
                      position: null,
                    });
                  } else {
                    setSelected({
                      ...selected,
                      division:
                        divisionList && divisionList.length > 0
                          ? divisionList.find(
                              (x) => x.idDivision === newValue.idDivision
                            ) || null
                          : null,
                      department:
                        departmentList && departmentList.length > 0
                          ? departmentList.find(
                              (x) => x.idDepartment === newValue.idDepartment
                            ) || null
                          : null,
                      section: newValue,
                      position: null,
                    });
                  }
                }}
                groupBy={(option) => option.departmentName}
                popupIcon={<i class="fa-light fa-chevron-down"></i>}
                getOptionLabel={(option) => option.sectionName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    placeholder={`${t("AllSection")}`}
                  />
                )}
                PopperComponent={StyledPopper}
                noOptionsText={`${t("NoData")}`}
              />
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("Position")}
              </Typography>
              <StyledAutocomplete
                options={
                  positionList
                    ? positionList.filter((x) => {
                        if (
                          selected.division !== null &&
                          selected.department !== null &&
                          selected.section !== null
                        ) {
                          return (
                            x.idDivision === selected.division.idDivision &&
                            x.idDepartment ===
                              selected.department.idDepartment &&
                            x.idSection === selected.section.idSection
                          );
                        } else if (
                          selected.division !== null &&
                          selected.department !== null
                        ) {
                          return (
                            x.idDivision === selected.division.idDivision &&
                            x.idDepartment === selected.department.idDepartment
                          );
                        } else if (selected.division !== null) {
                          return x.idDivision === selected.division.idDivision;
                        } else {
                          return x;
                        }
                      })
                    : []
                }
                value={selected.position}
                onChange={(event, newValue) => {
                  console.log("newValue Position:", newValue);
                  if (newValue === null) {
                    setSelected({
                      ...selected,
                      position: null,
                    });
                  } else {
                    setSelected({
                      ...selected,
                      division:
                        divisionList && divisionList.length > 0
                          ? divisionList.find(
                              (x) => x.idDivision === newValue.idDivision
                            ) || null
                          : null,
                      department:
                        departmentList && departmentList.length > 0
                          ? departmentList.find(
                              (x) => x.idDepartment === newValue.idDepartment
                            ) || null
                          : null,
                      section:
                        sectionList && sectionList.length > 0
                          ? sectionList.find(
                              (x) => x.idSection === newValue.idSection
                            ) || null
                          : null,
                      position: newValue,
                    });
                  }
                }}
                groupBy={(option) => option.sectionName}
                popupIcon={<i class="fa-light fa-chevron-down"></i>}
                getOptionLabel={(option) => option.positionName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    placeholder={`${t("AllPosition")}`}
                  />
                )}
                PopperComponent={StyledPopper}
                noOptionsText={`${t("NoData")}`}
              />
            </StyledBoxSearch>
          </Grid>
          {/* <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: "flex",
              alignItems: "flex-end",
              marginBottom: 10,
            }}
          >
            <StyledBoxSearch>
              <ButtonBlue variant="contained">ค้นหา</ButtonBlue>
            </StyledBoxSearch>
          </Grid> */}
        </Grid>
      ) : null}
    </StyledFilter>
  );
};

export default FilterTable;
