import React from "react";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { CardContent, Container, Grid, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar, Typography, Box, styled, MenuItem } from "@mui/material";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { AirlineSeatIndividualSuiteOutlined } from "@mui/icons-material";

const StyledRoot = styled(Box)({
  "& .filter-container": { display: "flex", justifyContent: "flex-end", gap: "8px", "& .MuiInputBase-root": { maxHeight: "48px" } },
  "& .menu-container": { boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px", borderRadius: 20, backgroundColor: "#ffffff", width: "fit-content", overflow: "hidden" },
  "& .MuiSelect-filled": { padding: "8px 32px 8px 12px" }, "& .MuiFilledInput-root": { paddingBottom: '8px !important', paddingTop: '8px !important' }
});

const StyledCellHeader = styled(TableCell)(({ theme }) => ({
  borderBottom: "0px",
  color: "#fff",
  backgroundColor: "#5e4889",
  padding: 8,
  minWidth: 50,
  "& .MuiTypography-root": {
    color: "#fff",
    backgroundColor: "#5e4889",
    "&.weekend": {
      fontStyle: "oblique",
    },
    "&.workday": {
      fontWeight: 600,
    },
  },
  "&.sticky": {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
    [theme.breakpoints.up('sm')]: {
      position: 'sticky', 
    },
    left: 0,
    zIndex: 4,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "10px 0px 10px #EEEEEE",
    "& .MuiTableCell-root": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "&:last-of-type": {
    paddingRight: 16,
    // borderTopRightRadius: 8,
    // borderBottomRightRadius: 8,
  },
}));

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "0px",
  padding: 8,
  minWidth: 50,
  "&.sticky": {
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
    [theme.breakpoints.up('sm')]: {
      position: 'sticky', 
    },
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .avatar": {
    minWidth: 200,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .fullname": {
    fontWeight: 600,
  },
  "& .position": {
    color: theme.palette.text.secondary,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .secondaryAction": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .location": {
      fontWeight: 600,
    },
  },
  "&:first-of-type": {
    paddingLeft: 8,
  },
  "&:last-of-type": {
    paddingRight: 16,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
}));

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const HeaderBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  padding: '16px 0 32px 0',
}));

const InnerHeaderBox = styled(Box)(() => ({
  borderBottom: '2px solid black',
  padding: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));


function Leave({ t, i18n,leave, month, setMonth }) {
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrAfter);
  return (
    <Card sx={{ width: '100%', padding: '16px' }}>
      <CardContent>
        <HeaderBox>
          <InnerHeaderBox>
            <AirlineSeatIndividualSuiteOutlined sx={{ marginRight: 1, fontSize: 30, color: 'success.main' }} />
            <Typography margin={0} variant="h5" align="center" gutterBottom>
              {`${t("Leave")}`}
            </Typography>
          </InnerHeaderBox>
          <StyledRoot sx={{ width: '40%' }} >
            <Container style={{ margin: "0", padding: '0' }}>
              <Box display="flex" justifyContent="flex-end" gap="8px" >
                <TextFieldTheme variant="filled" value={month} onChange={(e) => setMonth(e.target.value)} select fullWidth>
                  {[...Array(12)].map((_, index) => (
                    <MenuItem key={index + 1} value={index + 1}>
                      {dayjs().month(index).format("MMMM")}
                    </MenuItem>
                  ))}
                </TextFieldTheme>
              </Box>
            </Container>
          </StyledRoot>
        </HeaderBox>
        <TableContainer component={Box}>
          <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
            <TableHead sx={{
              position: { xs: 'relative', sm: 'sticky' },
              top: 0,
              zIndex: 1,
              backgroundColor: '#5e4889'
            }}>
              <TableRow>
                <StyledCellHeader align="center" className="sticky"
                  sx={{ minWidth: 200, maxWidth: 250, width: '33.33%',position: { xs: 'relative', sm: 'sticky' } }}
                  rowSpan={2}>
                  {t('FullName')}</StyledCellHeader>
                <StyledCellHeader align="center">{t('LeaveType')}</StyledCellHeader>
                <StyledCellHeader align="center">{t('Reason')}</StyledCellHeader>
                <StyledCellHeader align="center">{t('Date')}</StyledCellHeader>
                <StyledCellHeader align="center">{t('TotalLeaveDays')}</StyledCellHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {leave && leave.length > 0 ? leave.sort((a, b) => dayjs(a.start).isBefore(dayjs(b.start)) ? -1 : 1).map((item, index) => (
                <StyledRowContent key={index}>
                  <StyledCellContent className="sticky"
                    style={{
                      borderBottom:
                        "1px dashed rgba(224, 224, 224, 1)",
                    }} align="center" sx={{ overflokw: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <Box className="avatar">
                      <Avatar src={item.ImageURL || "https://example.com/avatar1.jpg"} alt={item.name} sx={{ boxShadow: 1 }} />
                      <Typography variant="subtitle1">
                        {i18n.resolvedLanguage === 'th' ? item.firstname_TH ? item.firstname_TH : item.firstname_EN  : item.firstname_EN ? item.firstname_EN : item.firstname_TH}
                        {i18n.resolvedLanguage === 'th' ? item.lastname_TH ? item.lastname_TH : item.lastname_EN  : item.lastname_EN ? item.lastname_EN : item.lastname_TH}
                        </Typography>
                    </Box>
                  </StyledCellContent>
                  <StyledCellContent align="center" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <Typography variant="body1">
                      {i18n.resolvedLanguage === 'th' ? item.name ? item.name : item.name_EN  : item.name_EN ? item.name_EN : item.name}
                    </Typography>
                  </StyledCellContent>
                  <StyledCellContent align="center" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <Typography variant="body1">
                      {i18n.resolvedLanguage === 'th' ? item.description ? item.description : item.description_EN  : item.description_EN ? item.description_EN : item.description}
                    </Typography>
                  </StyledCellContent>
                  <StyledCellContent align="center" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <Typography variant="body1">{dayjs(item.start).format('YYYY-MM-DD')}</Typography>
                  </StyledCellContent>
                  <StyledCellContent align="center" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <Typography variant="body1">
                      {dayjs(item.start).isSame(dayjs(item.end), 'day') || dayjs(item.end).diff(dayjs(item.start), 'day') === 0
                        ? 1
                        : dayjs(item.end).diff(dayjs(item.start), 'day')}
                    </Typography>
                  </StyledCellContent>
                </StyledRowContent>
              )) : (
                <StyledRowContent>
                  <StyledCellContent colSpan={5} align="center" margin={2}>{t('NoLeaveRecord')}</StyledCellContent>
                </StyledRowContent>
              )}
            </TableBody>
          </Table>
        </TableContainer>

      </CardContent>
    </Card>
  );
}

export default Leave;
