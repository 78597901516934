import React from "react";
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const DetailIndividual = ({ open, handleClos, data }) => {
  const { t, i18n } = useTranslation();

  const ResultChip = ({ isAchieve }) => {
    if (isAchieve === 1) {
      return <Chip label={`${t("Success")}`} color="success" />;
    } else if (isAchieve === 0) {
      return <Chip label={`${t("Failure")}`} color="error" />;
    } else {
      return <Chip label={`${t("HaveNotBeenEvaluated")}`} color="warning" />;
    }
  };

  return (
    <Dialog open={open} onClose={handleClos} maxWidth="md" fullWidth>
      <DialogTitle>{`${t("Descriptions")}`}</DialogTitle>
      <DialogContent>
        <Typography>
          <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
            "Strategy"
          )}`}</span>
          {data.tacticName}
        </Typography>
        <Typography paddingTop="16px">
          <span style={{ color: "#919eab", paddingRight: "8px" }}>
            {`${t("MeasurementData")}`}
          </span>
          {data.measureEvidence}
        </Typography>
        <Typography paddingTop="16px">
          <span style={{ color: "#919eab", paddingRight: "8px" }}>
            {`${t("ObjectiveMeasurementData")}`}
          </span>
          {data.commitQuality}
        </Typography>
        <Typography paddingTop="16px">
          <span style={{ color: "#919eab", paddingRight: "8px" }}>
            {`${t("TypeMeasurementData")}`}
          </span>
          {data.measureDataType === 1
            ? `${t("Number")}`
            : `${t("Explanation")}`}
        </Typography>
        {data.description && (
          <Typography paddingTop="16px" whiteSpace="pre-line">
            <span style={{ color: "#919eab", paddingRight: "8px" }}>
              {`${t("Descriptions")}`}
            </span>
            {data.description}
          </Typography>
        )}
        <Divider sx={{ marginTop: "8px", marginBottom: "8px"}} />
        <Typography fontWeight={500} style={{ marginBottom: 8 }}>
          {t("Update")}
        </Typography>
        <Box padding={"8px"}>
          {data.individualKeyResultProgress.map(item => (
            <Typography color="text.secondary">
              {`${dayjs(item.updateDate).format("YYYY-MM-DD HH:mm")} ${item.message}`}
            </Typography>
          ))}
        </Box>
        <Divider sx={{ marginBottom: "8px"}} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              fontWeight={500}
              style={{ textAlign: "center", marginBottom: 8 }}
            >
              {`${t("Employee")}`}
            </Typography>
            <Typography fontSize="18px" fontWeight={500} gutterBottom>
              <span style={{ color: "#919eab", paddingRight: "8px" }}>
                {`${t("PerformanceRating")}`}
              </span>
              {data.employeeRating ? data.employeeRating : "-"}
            </Typography>
            <Typography style={{ color: "#919eab" }}>{`${t(
              "Feedback"
            )}`}</Typography>
            <Typography>
              {data.employeeComment ? data.employeeComment : "-"}
            </Typography>
            <Stack direction="row" alignItems="center" gap={1} marginTop={1}>
              <Typography style={{ color: "#919eab" }}>{`${t(
                "EvaluationResults"
              )}`}</Typography>
              <ResultChip isAchieve={data.isSuccess} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              fontWeight={500}
              style={{ textAlign: "center", marginBottom: 8 }}
            >
              {`${t("Manager")}`}
            </Typography>
            <Typography fontSize="18px" fontWeight={500} gutterBottom>
              <span style={{ color: "#919eab", paddingRight: "8px" }}>
                {`${t("PerformanceRating")}`}
              </span>
              {data.managerRating ? data.managerRating : "-"}
            </Typography>
            <Typography style={{ color: "#919eab" }}>{`${t(
              "Feedback"
            )}`}</Typography>
            <Typography>
              {data.managerComment ? data.managerComment : "-"}
            </Typography>
            <Stack direction="row" alignItems="center" gap={1} marginTop={1}>
              <Typography style={{ color: "#919eab" }}>{`${t(
                "EvaluationResults"
              )}`}</Typography>
              <ResultChip isAchieve={data.isAchieve} />
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DetailIndividual;
