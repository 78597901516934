import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { styled } from "@mui/material/styles";

import { Divider, Grid, Typography, IconButton, Box } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DialogConfirmDelete from "../../../../shared/general/DialogConfirmDelete";

import DialogEdit from "./DialogEdit";
import {
  getEmployeeProfile,
  getEmployeeSalaryChange,
  addDeleteEmployeeSalary,
} from "../../../../../../actions/employee";
import utils from "../../../../../../utils";
import EditNoteRounded from "@mui/icons-material/EditNoteRounded";
import FlowForm from "../components/flowForm";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  "& .salary": {
    "& .MuiTypography-h6": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "baseline",
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: 16,
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledTextValue = styled(Typography)({
  fontSize: 18,
  "& i": {
    padding: 2,
  },
});

const StyledWrapChange = styled("div")({
  marginTop: 8,
  "& .inner": {
    marginBottom: 8,
  },
  "& .first-line": {
    display: "flex",
    alignItems: "center",
    "& .date": {
      margin: "0 8px",
      fontWeight: 600,
    },
  },
  "& .positions-name": {
    fontWeight: 600,
  },
  "& .second-line": {
    marginLeft: 16,
    fontSize: 16,
  },
});

function PaymentChange(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: employeeChangeEmployeeSalaryList } = useSelector(
    (state) => state.employeeChange
  );

  const [openEdit, setOpenEdit] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showPersonalId, setShowPersonalId] = useState(false);
  const [disabledBtnChange, setDisabledBtnChange] = useState(false);
  const [disabledBtnDelete, setDisabledBtnDelete] = useState(false);

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {},
  });

  const [drawerEditConfig, setDrawerEditConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {},
  });

  useEffect(() => {
    if (employeeProfile && employeeProfile.isShowSalary) {
      dispatch(getEmployeeSalaryChange(employeeProfile.idEmployees));
    }
  }, []);

  const handleOpenDialog = () => {
    setOpenEdit(true);
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: true,
      isEdit: false,
    }));
  };

  const handleCloseDialog = () => {
    setOpenEdit(false);
    setOpenDialogDelete(false);
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleDeleteEmployeeEmploymentType = (dataform) => {
    setSelectedItem(dataform);
    setOpenDialogDelete(true);
  };

  const handleSubmit = async () => {
    setDisabledBtnDelete(true);
    let tempFormData = { ...selectedItem };
    tempFormData.method = "delete";
    tempFormData.isFirst = employeeChangeEmployeeSalaryList.length === 1;
    tempFormData.idEmployeeSalaryBefore =
      employeeChangeEmployeeSalaryList.length === 1
        ? null
        : employeeChangeEmployeeSalaryList[
            employeeChangeEmployeeSalaryList.length - 2
          ].idEmployeeSalary;
    tempFormData.start = dayjs(tempFormData.start).format("YYYY-MM-DD");

    const result = await dispatch(addDeleteEmployeeSalary(tempFormData));
    if (result) {
      handleCloseDialog();
      dispatch(getEmployeeSalaryChange(employeeProfile.idEmployees));
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
    }
  };

  return (
    <StyledRoot>
      <StyledWrapTop>
        {!userProfile.readOnly && (
          <ButtonBlue
            variant="text"
            size="small"
            startIcon={<EditIcon />}
            onClick={handleOpenDialog}
            disabled={disabledBtnChange || !employeeProfile.isShowSalary}
          >
            {t("ChangePayment")}
          </ButtonBlue>
        )}
      </StyledWrapTop>
      {employeeProfile && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <div>
              <StyledHeadLabel color="text.secondary">
                {t("PaymentType")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.paymentTypeName
                  ? employeeProfile.paymentTypeName
                  : "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div>
              <StyledHeadLabel color="text.secondary">
                {t("Salary")}
              </StyledHeadLabel>
              <div className="salary">
                <div
                  className="wrap-show-hide-personalId"
                  onMouseEnter={() => setShowPersonalId(true)}
                  onMouseLeave={() => setShowPersonalId(false)}
                >
                  {showPersonalId ? (
                    <StyledTextValue variant="h6" align="center">
                      {employeeProfile.salary
                        ? utils.numberWithCommas(employeeProfile.salary)
                        : "-"}
                    </StyledTextValue>
                  ) : (
                    <StyledTextValue
                      className="dot"
                      variant="h6"
                      align="center"
                    >
                      {[...Array(6).keys()].map((_, index) => (
                        // <i key={index} class="fa-sharp fa-solid fa-circle"></i>
                        <i key={index} class="fas fa-times"></i>
                        // <CloseRoundedIcon key={index} />
                      ))}
                    </StyledTextValue>
                  )}
                </div>
                <Typography color="text.secondary">
                  {employeeProfile.paymentTypeName
                    ? employeeProfile.paymentTypeName
                    : "-"}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      <StyledDivider />
      <div>
        <StyledHeadLabel color="text.secondary">
          {t("SaveChanges")}
        </StyledHeadLabel>
        <StyledWrapChange>
          {Boolean(employeeProfile.isShowSalary) &&
            employeeChangeEmployeeSalaryList &&
            employeeChangeEmployeeSalaryList.map((item, index) => (
              <div className="inner" key={index}>
                <div className="first-line">
                  <Box display="flex" alignItems="center" flexGrow="1">
                    <div>
                      {employeeChangeEmployeeSalaryList.length - 1 === index && !userProfile.readOnly ? (
                        <Fragment>
                          {/* <IconButton aria-label="edit" size="small" onClick={() => handleEditPersonnelLevel(item)}>
                            <EditNoteRoundedIcon fontSize="inherit" />
                          </IconButton> */}
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() =>
                              handleDeleteEmployeeEmploymentType(item)
                            }
                            disabled={
                              employeeChangeEmployeeSalaryList.length === 1
                            }
                          >
                            <HighlightOffRoundedIcon fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDrawerEditConfig((prev) => ({
                                ...prev,
                                isOpen: true,
                                isEdit: true,
                                data: {
                                  idEmployeeSalary: item.idEmployeeSalary,
                                  salaryValue: item.salaryValue,
                                  start: item.start,
                                  remark: item.remark,
                                  reason: item.reason,
                                  idSalaryReason: item.idSalaryReason,
                                },
                              }));
                            }}
                          >
                            <EditNoteRounded fontSize="inherit" />
                          </IconButton>
                        </Fragment>
                      ) : null}
                    </div>
                    <div>
                      {item.end ? (
                        <Typography className="date" color="text.third">
                          {`[${dayjs(item.start).format(
                            i18n.resolvedLanguage === "th" ? "DD MMM BBBB" : "DD MMM YYYY"
                          )} - ${dayjs(item.end).format("DD MMM BBBB")}]`}
                        </Typography>
                      ) : (
                        <Typography className="date" color="text.third">
                          {`[${dayjs(item.start).format(
                            i18n.resolvedLanguage === "th" ? "DD MMM BBBB" : "DD MMM YYYY"
                          )} - ${t("Current")}]`}
                        </Typography>
                      )}
                    </div>
                    <Typography className="positions-name">
                      {`${utils.numberWithCommas(item.salaryValue)} ${t(
                        "Baht"
                      )}`}
                    </Typography>
                  </Box>

                  {/* <Typography
                    color="text.secondary"
                    variant="body2"
                    className="second-line"
                    gutterBottom
                    style={{ fontStyle: "oblique" }}
                  >
                    {`${t("Description")}: ${item.reason ? item.reason : "-"}`}
                  </Typography>
                  <Typography
                    color="text.secondary"
                    variant="body2"
                    className="second-line"
                    gutterBottom
                    style={{ fontStyle: "oblique" }}
                  >
                    {`${t("Description")}: ${item.remark ? item.remark : "-"}`}
                  </Typography> */}
                </div>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  className="second-line"
                  gutterBottom
                  style={{ fontStyle: "oblique" }}
                >
                  {`${t("Reason")}: ${item.reason ? item.reason : "-"}`}
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  className="second-line"
                  gutterBottom
                  style={{ fontStyle: "oblique" }}
                >
                  {`${t("Note")}: ${item.remark ? item.remark : "-"}`}
                </Typography>
              </div>
            ))}
          {!Boolean(employeeProfile.isShowSalary) && (
            <Typography
              fontSize="20px"
              color="text.secondary"
              textAlign="center"
              fontStyle="italic"
            >
              ไม่สามารถดูข้อมูลได้
            </Typography>
          )}
        </StyledWrapChange>
      </div>

      {/* <DialogEdit
        open={openEdit}
        onClose={() => {
          setOpenEdit(false);
        }}
      /> */}

      {/* <DialogEdit
        open={drawerConfig.isOpen}
        onClose={handleCloseDialog}
        drawerConfig={drawerConfig}
      /> */}

      <FlowForm
        // open={drawerConfig.isOpen}
        handleClose={handleCloseDialog}
        drawerConfig={drawerConfig}
        defaultPage="salary"
      />

      <DialogEdit
        open={drawerEditConfig.isOpen}
        drawerConfig={drawerEditConfig}
        onClose={() => {
          setDrawerEditConfig((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }}
      />

      {selectedItem && (
        <DialogConfirmDelete
          open={openDialogDelete}
          handleClose={handleCloseDialog}
          label={`${t("Salary")}`}
          text={``}
          handleDelete={handleSubmit}
          disabledBtnDelete={disabledBtnDelete}
        />
      )}
    </StyledRoot>
  );
}

export default PaymentChange;
