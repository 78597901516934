import React from "react";
import { useSelector } from "react-redux";

import AdminWarningLetter from "../admin/warningLetter";
import UserWarningLetter from "../user/warningLetter";

const WarningLetter = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ? (
          <AdminWarningLetter />
        ) : (
          <UserWarningLetter />
        )
      ) : null}
    </>
  );
};

export default WarningLetter;
