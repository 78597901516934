import workforcePlanningCalService from "../services/workforcePlanningCal.service";

import {
  ALL_PROJECT_EMPLOYEES_FAILED,
  ALL_PROJECT_EMPLOYEES_SUCCESS,
  ALL_PROJECT_USER_FAILED,
  ALL_PROJECT_USER_SUCCESS,
  ALL_PROJECT_USER_SUCCESS_ById,
  ALL_PROJECT_USER_FAILED_ById,
  GET_USERSTIME_FOR_APPROVE_SUCCESS,
  GET_USERSTIME_FOR_APPROVE_FAILED,
  EMPLOYEE_SUCCESS_PROJECT_HOUR,
  EMPLOYEE_FAILED_PROJECT_HOUR,
  EMPLOYEE_FAILED_PROJECT_WORKFORCE,
  EMPLOYEE_SUCCESS_PROJECT_WORKFORCE,
  EMPLOYEE_SUCCESS_WEEK_WORKFORCE,
  EMPLOYEE_FAILED_WEEK_WORKFORCE,
  DASHBOARD_WORKFORCE_MONTH_SUCCESS,
  DASHBOARD_WORKFORCE_MONTH_FAILED,
  DASHBOARD_WORKFORCE_ALL_SUCCESS,
  DASHBOARD_WORKFORCE_ALL_FAILED,
  USER_WORKFORCE_HOUR_TABLE_SUCCESS,
  USER_WORKFORCE_HOUR_TABLE_FAILED,
  DASHBOARD_WORKFORCE_PIE_SUCCESS,
  DASHBOARD_WORKFORCE_PIE_FAILED,
  SUM_HOUR_MONTHID_WORKFORCE_ALL_SUCCESS,
  SUM_HOUR_MONTHID_WORKFORCE_ALL_FAILED,
  ADMIN_DETAIL_WORKFORCE_ALL_SUCCESS,
  ADMIN_DETAIL_WORKFORCE_ALL_FAILED,
  GET_SUM_USER_TIME_IN_PROJECT_SUCCESS,
  GET_SUM_USER_TIME_IN_PROJECT_FAILED,
  GET_BUDGET_USER_WORKFORCE_SUCCESS,
  GET_BUDGET_USER_WORKFORCE_FAILED
} from "./types";

export const addProjectEmployees = (formData) => async () => {
  try {
    const res = await workforcePlanningCalService.addProjectEmployees(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const addUserWorkforceHourTable = (formData) => async () => {
  try {
    const res = await workforcePlanningCalService.addUserWorkforceHourTable(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const updateUserWorkforceHourTable = (formData) => async () => {
  try {
    const res = await workforcePlanningCalService.updateUserWorkforceHourTable(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const updateManagerWorkforceApproveTime = (formData) => async () => {
  try {
    console.log(formData)
    const res = await workforcePlanningCalService.updateManagerWorkforceApproveTime(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const getAllProjectEmployees = () => async (dispatch) => {
  try {
    const res = await workforcePlanningCalService.getAllProjectEmployees();
    if (res) {
      dispatch({
        type: ALL_PROJECT_EMPLOYEES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ALL_PROJECT_EMPLOYEES_FAILED,
    });
    console.log(err);
  }
};

export const addUsersToProject = (formData) => async () => {
  try {
    const res = await workforcePlanningCalService.addUsersToProject(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};
export const getWorkforceDashboardByIdMonth = (query) => async (dispatch) => {
  try {
    
    const res = await workforcePlanningCalService.getWorkforceDashboardByIdMonth(query);
    if (res) {
      // console.log('fin to db')
      dispatch({
        type: DASHBOARD_WORKFORCE_MONTH_SUCCESS,
        payload: res.data,
      });

    }
  } catch (error) {
    console.log('fail to db')
    dispatch({
      type: DASHBOARD_WORKFORCE_MONTH_FAILED,
    });
    console.log(error);
  }
};

export const getUserworkforceShowHourTable = () => async (dispatch) => {
  try {
    console.log('testgetUserworkforceShowHourTable')
    const res = await workforcePlanningCalService.getUserWorkforceShowHourTable();
    if (res) {
      console.log('USER_WORKFORCE_HOUR_TABLE_SUCCESS')
      dispatch({
        type: USER_WORKFORCE_HOUR_TABLE_SUCCESS,
        payload: res.data,
      });

    }
  } catch (error) {
    // console.log('fail to db')
    dispatch({
      type: USER_WORKFORCE_HOUR_TABLE_FAILED,
    });
    console.log(error);
  }
};


export const getSumHourWorkforceHourUserByIdMonth = (query) => async (dispatch) => {
  try {
    
    const res = await workforcePlanningCalService.getSumHourWorkforceHourUserByIdMonth(query);
    if (res) {
      // console.log('fin to db')
      dispatch({
        type: SUM_HOUR_MONTHID_WORKFORCE_ALL_SUCCESS,
        payload: res.data,
      });

    }
  } catch (error) {
    console.log('fail to db')
    dispatch({
      type: SUM_HOUR_MONTHID_WORKFORCE_ALL_FAILED,
    });
    console.log(error);
  }
};
//redux
export const getAllProjectDetailAdmin = () => async (dispatch) => {
  try {
    
    const res = await workforcePlanningCalService.getAllProjectDetailAdmin();
    if (res) {
      // console.log('fin to db')
      dispatch({
        type: ADMIN_DETAIL_WORKFORCE_ALL_SUCCESS,
        payload: res.data,
      });

    }
  } catch (error) {
    console.log('fail to db')
    dispatch({
      type: ADMIN_DETAIL_WORKFORCE_ALL_FAILED,
    });
    console.log(error);
  }
};

export const getWorkforceDashboardAllById = (query) => async (dispatch) => {
  try {
    
    const res = await workforcePlanningCalService.getWorkforceDashboardAllById(query);
    if (res) {
      // console.log('fin to db')
      dispatch({
        type: DASHBOARD_WORKFORCE_ALL_SUCCESS,
        payload: res.data,
      });

    }
  } catch (error) {
    console.log('fail to db')
    dispatch({
      type: DASHBOARD_WORKFORCE_ALL_FAILED,
    });
    console.log(error);
  }
};

export const getUsersInProjectById = () => async (dispatch) => {
  // console.log('getUsersInProjectById')
  try {
    const res = await workforcePlanningCalService.getUsersInProjectById();
    // console.log("UsersInProjectById")
    if (res) {
      dispatch({
        type: ALL_PROJECT_USER_SUCCESS_ById,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ALL_PROJECT_USER_FAILED_ById,
    });
    console.log(err);
  }
};

export const getUsersInProjectByIdForPie = (query) => async (dispatch) => {
  // console.log('getUsersInProjectById')
  try {
    const res = await workforcePlanningCalService.getUsersInProjectByIdForPie(query);
    // console.log("UsersInProjectById")
    if (res) {
      dispatch({
        type: DASHBOARD_WORKFORCE_PIE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: DASHBOARD_WORKFORCE_PIE_FAILED,
    });
    console.log(err);
  }
};

export const getUsersInProject = () => async (dispatch) => {
  try {
    const res = await workforcePlanningCalService.getUsersInProject();
    console.log("UsersInProject")
    if (res) {
      dispatch({
        type: ALL_PROJECT_USER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ALL_PROJECT_USER_FAILED,
    });
    console.log(err);
  }
};

export const getEmployeesWeek = () => async (dispatch) => {
  try {
    const res = await workforcePlanningCalService.getEmployeesWeek();
    console.log("UsersInProject")
    if (res) {
      dispatch({
        type: EMPLOYEE_SUCCESS_WEEK_WORKFORCE,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEE_FAILED_WEEK_WORKFORCE,
    });
    console.log(err);
  }
};

export const getUsersInProjectShowHour = () => async (dispatch) => {
  try {
    const res = await workforcePlanningCalService.getUsersInProjectShowHour();
    console.log("UsersInProject")
    if (res) {
      dispatch({
        type: EMPLOYEE_SUCCESS_PROJECT_HOUR,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEE_FAILED_PROJECT_HOUR,
    });
    console.log(err);
  }
};

export const getAllEmployeesInWorkforce = (query) => async (dispatch) => {
  try {
    const res = await workforcePlanningCalService.getAllEmployeesInWorkforce(query);
    if (res) {
      dispatch({
        type: EMPLOYEE_SUCCESS_PROJECT_WORKFORCE,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEE_FAILED_PROJECT_WORKFORCE,
    });
    console.log(err);
  }
};

export const getSumUserTimeInProject= () => async (dispatch) => {
  try {
    const res = await workforcePlanningCalService.getSumUserTimeInProject();
    if (res) {
      dispatch({
        type: GET_SUM_USER_TIME_IN_PROJECT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_SUM_USER_TIME_IN_PROJECT_FAILED,
    });
    console.log(err);
  }
};

export const getBudgetForWorkforce= () => async (dispatch) => {
  try {
    const res = await workforcePlanningCalService.getBudgetForWorkforce();
    if (res) {
      dispatch({
        type: GET_BUDGET_USER_WORKFORCE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_BUDGET_USER_WORKFORCE_FAILED,
    });
    console.log(err);
  }
};

export const deleteUserWorkforce= (formData) => async () => {
  try {
    const res = await workforcePlanningCalService.deleteUserWorkforce(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const getUsersTimeforApprove = () => async (dispatch) => {
  try {
    const res = await workforcePlanningCalService.getUsersTimeforApprove();
    if (res) {
      dispatch({
        type: GET_USERSTIME_FOR_APPROVE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_USERSTIME_FOR_APPROVE_FAILED,
    });
    console.log(err);
  }
};

export const updateUserTimeApproveManager= (formData) => async () => {
  try {
    const res = await workforcePlanningCalService.updateUserTimeApproveManager(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};


export const deleteProjectWorkforce = (formData) => async () => {
  try {
    const res = await workforcePlanningCalService.deleteProjectWorkforce(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};
export const updateProjectEmployees = (formData) => async () => {
  try {
    const res = await workforcePlanningCalService.updateProjectEmployees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

