import DoneAll from "@mui/icons-material/DoneAll";
import { Avatar, Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChipWithDrawCustom from "../../../shared/chipWithDrawCustom";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import StatusRequest from "../../../shared/general/stausRequest";
import TableCustom from "../../../shared/tableCustom";
import DialogConfirmReject from "../DialogConfirmReject";
import {
  employeeChangeTimeApprove,
  getEmployeeChangeTime,
} from "../../../../../actions/employee";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName, getUserPosition } from "../../../../../utils/userData";

const Shift = (props) => {
  const { searchDate, selectedEmployee, setSnackBarConfig, setNumberOfList } =
    props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeChangeShiftList } = useSelector(
    (state) => state.employeeChangeShift
  );

  const [pendingRows, setPendingRows] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);

  const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);

  const shiftTimeHeader = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            style={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>{getUserFullName(row)}</Typography>
            <Typography color="text.third" fontSize="14px">{row.employeeID}</Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("Position")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Fragment>
          <Typography fontSize={14}>{getUserPosition(row)}</Typography>
        </Fragment>
      ),
    },
    {
      name: `${t("Date")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box style={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.workingDateText, "DD/MM/YYYY").format(
              i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
            )}
          </Typography>
        </Box>
      ),
    },
    {
      name: t("previousWorkingHours"),
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box style={{ textAlign: "center" }}>
          {row.idShiftGroupPrev? (
            <Fragment>
              <Typography fontSize="14px" fontWeight="500">{row.shiftGroupPrevName}</Typography>
              <Typography>{row.shiftTypePrevName}</Typography>
            </Fragment>
          ) : (
            <Typography>-</Typography>
          )}
          {/* {row.}
          {row.isWorkingDay === 1 ? (
            <Typography>{row.shiftGroupPrevName}</Typography>
          ) : (
            <Typography>{t("DayOff")}</Typography>
          )} */}
        </Box>
      ),
    },
    {
      name: `${t("AppMenu.TimeAttendance")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box style={{ textAlign: "center" }}>
          <Typography fontSize="14px" fontWeight="500">{row.shiftGroupName}</Typography>
          {row.idHoliday? (
            <Typography>{row.holidayName}</Typography>
          ) : (
            <Typography>{row.shiftTypeName}</Typography>
          )}
          {/* {row.isWorkingDay === 1 ? (
            <Typography>{row.shiftGroupName}</Typography>
          ) : (
            <Typography>{t("DayOff")}</Typography>
          )} */}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const tempPending = [];

    employeeChangeShiftList.map((r) => {
      if (
        r.isApprove === null &&
        (selectedEmployee
          ? selectedEmployee.idEmployees === r.idEmployees
          : true) && r.approveBy
      ) {
        tempPending.push(r);
      }
    });
    let pendingList = tempPending.sort(function (a, b) {
      return new Date(b.createDate) - new Date(a.createDate);
    });

    setPendingRows(pendingList);
    setNumberOfList(pendingList.length);
  }, [selectedEmployee]);

  const handleApprovalRequest = async (approve, comment) => {
    let formData = [...selectedRows];

    formData.forEach((item) => {
      item.isApprove = approve;
      item.approveComment = comment;
    });

    setIsOpenRejectDialog(false);

    const result = await dispatch(employeeChangeTimeApprove(formData));
    if (result) {
      setSelectedRows([]);
      dispatch(getEmployeeChangeTime("admin", searchDate));
      if (result.status === 200) {
        setSnackBarConfig({
          open: true,
          message: `${t("Successfully")}`,
          type: "success",
        });
      } else {
        setSnackBarConfig({
          open: true,
          message: `${t("Unsuccessful")}`,
          type: "error",
        });
      }
    } else {
      setSnackBarConfig({
        open: true,
        message: `${t("Unsuccessful")}`,
        type: "error",
      });
    }
  };

  const handleConfirmReject = (comment) => {
    handleApprovalRequest(0, comment);
  };

  return (
    <Box>
      {selectedRows.length > 0 && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          <ButtonBlue
            style={{ marginRight: 8 }}
            startIcon={<DoneAll />}
            variant="contained"
            onClick={() => handleApprovalRequest(1, null)}
          >
            {`${t("Approved")} ${
              selectedRows.length > 0
                ? `${selectedRows.length} ${t("List")}`
                : ""
            }`}
          </ButtonBlue>
          <ButtonBlue
            startIcon={<DoneAll />}
            variant="outlined"
            // onClick={() => handleApprovalRequest(0)}
            onClick={() => setIsOpenRejectDialog(true)}
          >
            {`${t("NotApproved")} ${
              selectedRows.length > 0
                ? `${selectedRows.length} ${t("List")}`
                : ""
            }`}
          </ButtonBlue>
        </Box>
      )}

      <TableCustom
        columns={shiftTimeHeader}
        rows={employeeChangeShiftList ? pendingRows : []}
        canSelect={userProfile.isSuperAdmin === 1}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />

      <DialogConfirmReject
        rejectCount={selectedRows.length}
        open={isOpenRejectDialog}
        onClose={() => {
          setIsOpenRejectDialog(false);
        }}
        handleSubmit={(comment) => {
          handleConfirmReject(comment);
        }}
      />
    </Box>
  );
};

export default Shift;
