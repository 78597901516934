import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Chip,
  Container,
  Grid,
  styled,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch } from "react-redux";
import DataGrid, {
  Column,
  FilterRow,
  Pager,
} from "devextreme-react/data-grid";
import { utils, writeFile } from "xlsx";
import { getKpiCalibrateDepartment } from "../../../../../actions/kpi";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Gauge from "../KpisCalibrate/KpiGauge"

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiGrid-container": {
      marginBottom: 0,
    },
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
    "& .MuiOutlinedInput-input": {
      padding: "16px 14px",
    },
    "& .MuiTableCell-root": {
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
}));

const StyledWrapDataGrid = styled("div")({
  padding: "0 24px 24px 24px",
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#009688",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-info[role="gridcell"]': {
    verticalAlign: "middle !important",
  },
  '& .column-total-addition[role="gridcell"]': {
    verticalAlign: "middle !important",
  },
});

const ContainerStyled = styled(Container)({
  paddingTop: "100px",
  height: "100dvh",
});

function KPICalibrateDepartment() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [calibrateData, setCalibrateData] = useState(null);
  const [grades, setGrades] = useState(null);
  const [departmentName, setDepartmentName] = useState("");

  const { id } = useParams();
  const history = useHistory();

  function calculateAverage(rowData) {
    if (rowData.kpiData && rowData.kpiData.AllKpi) {
      let sum = 0;
      for (let i = 0; i < rowData.kpiData.AllKpi.length; i++) {
        let kpiRating = parseInt(rowData.kpiData.AllKpi[i].kpiRating);
        sum += kpiRating;
      }
      return sum / 5;
    }
    return 0;
  }

  const exportFile = useCallback(() => {
    const dataExport = calibrateData.map((item) => ({
      idEmployees: item.idEmployees,
      firstname: item.firstname_TH,
      lastname: item.lastname_TH,
      position: item.positionName,
      department: item.departmentName,
      KPI: item.kpiName,
      average: item.average,
      grade: item.kpiGrade,
    }));

    const ws = utils.json_to_sheet(dataExport);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFile(wb, "Calibrate.xlsx");
  }, [calibrateData]);

  useEffect(() => {
    let isMounted = true;
    dispatch(getKpiCalibrateDepartment()).then((res) => {
      if (isMounted) {
        if (res.status === 200) {
          const selectDepartment = res.data.find((item) => item.idDepartment === id);
          if (selectDepartment.employees && selectDepartment.KpiGrade) {
            setGrades(selectDepartment.KpiGrade);
            setDepartmentName(selectDepartment.departmentName);
            setCalibrateData(selectDepartment.employees);
          } else {
            history.replace("/kpi-calibrate")
          }
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, [id]);

  const columns = [];

  if (calibrateData && calibrateData.length > 0 && calibrateData[0].kpiData) {
    const firstRowKpiData = calibrateData[0].kpiData.AllKpi;

    firstRowKpiData.forEach((kpi, index) => {
      const columnName = `kpi_${kpi.idKPI}`;
      columns.push(
        <Column
          key={columnName}
          dataField={`kpiData.AllKpi[${index}].kpiName`}
          caption={`KPI ${index + 1}`}
          cssClass="column-info"
          dataType="string"
          allowFiltering={false}
          allowSorting={false}
          showPager={true}
          minWidth={100}
        />
      );
    });
  }

  return (
    <ContainerStyled>
      <div className={classes.root}>
        <div className="card-section">
          <div className="header-section" style={{ marginBottom: 0 }}>
            <Grid container>
              <Grid item>
                <h3 className="header-topic">{departmentName}</h3>
              </Grid>
            </Grid>
          </div>
          {grades && (
            <Box sx={{ width: 500, height: 500, marginLeft: "auto", marginRight: "auto" }}>
              <Gauge grades={grades} />
            </Box>
          )}
          {calibrateData && (
            <>
              <StyledWrapDataGrid>
                <DataGrid
                  aria-label="simple table"
                  dataSource={calibrateData}
                  showRowLines={true}
                  showPager={true}
                  showBorders={true}
                  showColumnLines={true}
                  rowAlternationEnabled={true}
                  columnAutoWidth={true}
                >
                  <Column
                    dataField="idEmployees"
                    caption="ID employee"
                    visible={false}
                  />
                  <Column
                    dataField="fullName"
                    caption="Full name"
                    cssClass="column-info"
                    minWidth={240}
                    allowSorting={false}
                    cellRender={(cellData) => (
                      <div
                        style={{ display: "flex", alignItems: "center", gap: 8 }}
                      >
                        <Avatar src={cellData.data.imageURL} />
                        {cellData.data.firstname_TH +
                          " " +
                          cellData.data.lastname_TH}
                      </div>
                    )}
                  />
                  <Column
                    dataField="positionName"
                    caption="Position"
                    minWidth={160}
                    cssClass="column-info"
                    allowFiltering={false}
                    allowSorting={false}
                  />
                  <Column
                    dataField="sectionName"
                    caption="Department"
                    minWidth={160}
                    cssClass="column-info"
                    allowFiltering={false}
                    allowSorting={false}
                  />

                  {columns}

                  <Column
                    dataField="average"
                    dataType={"number"}
                    cssClass="column-info"
                    minWidth={200}
                    allowFiltering={false}
                    allowSorting={false}
                    alignment="center"
                    cellRender={(cellData) => (
                      <div>
                        {calculateAverage(cellData.data)}
                      </div>
                    )}
                  />

                  <Column
                    dataField="LeaveType1"
                    caption="ลาป่วย"
                    minWidth={160}
                    cssClass="column-info"
                    allowFiltering={false}
                    allowSorting={false}
                  />
                  <Column
                    dataField="LeaveType2"
                    caption="ลากิจ"
                    minWidth={160}
                    cssClass="column-info"
                    allowFiltering={false}
                    allowSorting={false}
                  />
                  <Column
                    dataField="LeaveType3"
                    caption="ลาพักร้อน"
                    minWidth={160}
                    cssClass="column-info"
                    allowFiltering={false}
                    allowSorting={false}
                  />
                  <Column
                    dataField="LeaveType4"
                    caption="ลาอื่นๆ"
                    minWidth={160}
                    cssClass="column-info"
                    allowFiltering={false}
                    allowSorting={false}
                  />
                  <Column
                    dataField="LeaveType5"
                    caption="ขาดงาน"
                    minWidth={160}
                    cssClass="column-info"
                    allowFiltering={false}
                    allowSorting={false}
                  />
                  <Column
                    dataField="kpiGrade"
                    caption="Grade"
                    minWidth={100}
                    cssClass="column-total-addition"
                    filterOperations={["="]}
                    alignment={"center"}
                    cellRender={(cellData) => (
                      <Chip
                        label={cellData.value}
                        sx={{ backgroundColor: "#4f65df", color: "#ffffff" }}
                      />
                    )}
                  />
                  <Pager allowedPageSizes={[10, 25, 50, 100]} showPageSizeSelector={true} />
                </DataGrid>
              </StyledWrapDataGrid>
            </>
          )}
        </div>
      </div>
    </ContainerStyled>
  );
}

export default KPICalibrateDepartment;
