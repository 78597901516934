import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Dialog,
  Typography,
  IconButton,
  Grid,
  Divider,
} from "@mui/material";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import CloseIcon from "@mui/icons-material/Close";
import TodayIcon from "@mui/icons-material/Today";

const useStyles = makeStyles(() => ({
  root: {},
  dialog: {
    padding: 16,
    position: "absolute",
    top: 80,
  },
  dialogTitle: {
    borderBottom: "1px solid #dee2e6",
    color: "#222f3e",
    padding: "8px 16px 8px 16px",
  },
  dialogContent: {
    color: "#798294",
    fontWeight: 600,
    paddingTop: 24,
    paddingLeft: 0,
    paddingRight: 0,
  },
  divider: {
    marginTop: 32,
    marginBottom: 32,
  },
  textData: {
    fontSize: "1rem",
    color: "#616161",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 16,
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: "#9e9e9e",
  },
  headTitle: {
    fontSize: "1.2rem",
    fontWeight: 600,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.headTitle}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 16,
  },
}))(MuiDialogContent);

export default function ViewProjectInfo(props) {
  const classes = useStyles();
  const { infoModal, setInfoModal } = props;
  const [project, setProject] = useState([]);

  useEffect(() => {
    if (infoModal.isOpen) {
      setProject(infoModal.projectData);
    }
  }, [infoModal]);

  return (
    <div className={classes.root}>
      <Dialog
        open={infoModal.isOpen}
        maxWidth="sm"
        fullWidth
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle
          className={classes.dialogTitle}
          onClose={() => setInfoModal({ ...infoModal, isOpen: false })}
        >
          Project Info
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container alignItems="center" spacing={4}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">Project Name : </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2" className={classes.textData}>
                {project.projectName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={4}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">Description : </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2" className={classes.textData}>
                {project.description}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={4}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">Project Size : </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2" className={classes.textData}>
                {project.projectSize}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={4}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">Project Invest : </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2" className={classes.textData}>
                {project.projectInvest}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={4}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">Person In Charge : </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2" className={classes.textData}>
                {project.personInCharge}
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container alignItems="center" spacing={4}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">Start Date : </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2" className={classes.textData}>
                {dayjs(project.startDate).format("DD/MM/YYYY")}
                <TodayIcon style={{ marginLeft: "5px" }} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={4}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">End Date : </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2" className={classes.textData}>
                {dayjs(project.endDate).format("DD/MM/YYYY")}
                <TodayIcon style={{ marginLeft: "5px" }} />
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
