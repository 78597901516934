import React from "react";
import { styled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";

import loading from "../../assets/social-media.gif";

const StyledBackdrop = styled(Backdrop)({
  backgroundColor: "transparent",
  zIndex:  2001,
});

const AnimationBackdrop = ({ open }) => {
  return (
    <StyledBackdrop open={open}>
      <img width="120" alt="loading" src={loading} />
    </StyledBackdrop>
  );
};

export default AnimationBackdrop;
