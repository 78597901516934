import React, { forwardRef } from "react";
import { Doughnut } from "react-chartjs-2";

const Gauge = forwardRef((props, ref) => {
  const { grades } = props
  const data = Object.values(grades).map(item => Math.round(item * 100))

  return (
    <Doughnut
      width={300}
      height={300}
      ref={ref}
      options={{
        maintainAspectRatio: false,
        cutoutPercentage: 80,
        rotation: 270,
        circumference: 180,
        tooltips: {
          enabled: false,
        },
        layout: {
          padding: 50,
        },
        plugins: {
          legend: {
            position: "right",
            display: true,
            labels: {
              font: {
                size: 14,
              },
              boxWidth: 15,
              padding: 10,
              generateLabels: function (chart) {
                const data = chart.data.datasets[0].data;
                const labels = chart.data.labels;
                const legendItems = [];
                for (let i = 0; i < data.length; i++) {
                  legendItems.push({
                    text: `${labels[i]} : ${data[i]}%`,
                    fillStyle: chart.data.datasets[0].backgroundColor[i],
                    hidden: isNaN(data[i]) || data[i] === 0,
                  });
                }
                return legendItems;
              },
            },
          },
        },
      }}
      data={{
        labels: ["A", "B", "C", "D"],
        datasets: [
          {
            data: data,
            backgroundColor: ["#FA95B9", "#C0DFFF", "#86A9FF", "#4A75CA"],
            borderWidth: 3,
          },
        ],
      }}
    />
  );
});

export default Gauge;
