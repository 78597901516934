import React from "react";
import { Dialog, DialogContent, styled, Typography } from "@mui/material";
import ButtonBlue from "../../shared/general/ButtonBlue";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  width: 450,
  [theme.breakpoints.down("sm")]: {
    width: 270,
  },
}));

const DialogShowComment = (props) => {
  const { open, onClose, comment, commentManager1, commentManager2 } = props;
  const { t, i18n } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <StyledDialogContent>
        <Typography variant="h5">{t("ManagerComment")}</Typography>
        <div style={{ padding: "0 8px" }}>
          {commentManager1 && (
            <Typography style={{ marginTop: 16, fontSize: 18 }}>
              {`${t("ManagerLV")} 1`}
            </Typography>
          )}
          {(commentManager1 || comment) && (
            <div
              style={{
                marginTop: commentManager1 ? 16 : 8,
                padding: "0px 16px",
                border: "1px solid rgba(0,0,0,0.26)",
                borderRadius: 16,
              }}
            >
              <div
                style={{
                  maxHeight: 300,
                  padding: "16px 0px",
                  overflowY: "auto",
                }}
              >
                <Typography>{comment || commentManager1}</Typography>
              </div>
            </div>
          )}
          {commentManager2 && (
            <>
              <Typography style={{ marginTop: 16, fontSize: 18 }}>
                {`${t("ManagerLV")} 2`}
              </Typography>
              <div
                style={{
                  marginTop: 8,
                  padding: "0px 16px",
                  border: "1px solid rgba(0,0,0,0.26)",
                  borderRadius: 16,
                }}
              >
                <div
                  style={{
                    maxHeight: 300,
                    padding: "16px 0px",
                    overflowY: "auto",
                  }}
                >
                  <Typography>{commentManager2}</Typography>
                </div>
              </div>
            </>
          )}
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 16 }}
        >
          <ButtonBlue variant="outlined" onClick={onClose}>
            {t("Close")}
          </ButtonBlue>
        </div>
      </StyledDialogContent>
    </Dialog>
  );
};

export default DialogShowComment;
