import {
  PROGRESSTEAMPLAN_FETCHING,
  PROGRESSTEAMPLAN_FAILED,
  PROGRESSTEAMPLAN_SUCCESS,
} from "./types";

import ProgressTeamPlanService from "../services/ProgressTeamPlans.service";

export const getAllProgressTeamPlan = () => async (dispatch) => {
  try {
    const res = await ProgressTeamPlanService.getAllProgressTeamPlan();
    if (res) {
      dispatch({
        type: PROGRESSTEAMPLAN_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PROGRESSTEAMPLAN_FAILED,
    });
    console.log(err);
  }
};

export const postProgressTeamPlan = (formData) => async () => {
  try {
    const res = await ProgressTeamPlanService.postProgressTeamPlan(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};
