import React, { useEffect, useState, Fragment } from "react";
import {
  Box,
  Grid,
  styled,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  MenuItem,
  TableRow,
  Menu,
  IconButton,
  Button,
  ListSubheader,
  Chip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ButtonBlue from "../../shared/general/ButtonBlue";
import dayjs from "dayjs";

import SelectEmployees from "../../shared/general/selectEmployees";
import {
  clearAttendance,
  getAttendanceById,
  getTimeSceduleByIdEmployeesV2,
} from "../../../../actions/attendance";
import { useDispatch, useSelector } from "react-redux";

import SearchIcon from "@mui/icons-material/Search";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import TableTimeline from "../../shared/tableTimeline";
import CardStyle from "../../shared/general/Card";
import DrawerRequestTime from "../../shared/tableTimeline/drawerRequestTime";
import Notification from "../../shared/general/Notification";
import AlertResponse from "../../shared/general/AlertResponse";
import DialogConfirm from "../../shared/general/DialogConfirm";
import DialogConfirmDayOffHoliday from "../../shared/general/DialogConfirmDayOffHoliday";
import DialogConfirmDelete from "../../shared/general/DialogConfirmDelete";
import StatusRequest from "../../shared/general/stausRequest";

import Timer from "../../assets/timer.png";

import { getPayrollSetting } from "../../../../actions/paytypes";
import {
  getTimescheduleByEmployees,
  employeeChangeTime,
  employeeChangeTimeWithdraw,
} from "../../../../actions/employee";
import { getShift } from "../../../../actions/shift";
import { allHoliday, getHoliday } from "../../../../actions/holiday";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../shared/date/datePicker";
import DrawerUploadEmployeeHoliday from "./DrawerUploadEmployeeHoliday";
import { openNotificationAlert } from "../../../../actions/notificationAlert";
import MenuShift from "../../shared/general/MenuShift";
import ModalLoading from "../../shared/general/ModalLoading";

const StyledRoot = styled(Box)({});

const StyledBoxSearchButton = styled(Box)({
  marginTop: 50,
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 1000px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "& .MuiTypography-root": {
    color: "#637381",
    backgroundColor: "#f4f6f8",
    "&.weekend": {
      fontStyle: "oblique",
    },
    "&.workday": {
      fontWeight: 600,
    },
  },
  "&.sticky": {
    padding: 0,
    position: "sticky",
    left: 0,
    zIndex: 4,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px 0px 20px #EEEEEE",
    "& .MuiTableCell-root": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: 8,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const StyledMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    borderRadius: 8,
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
  },
  "& .MuiList-root": {
    padding: 8,
  },
  "& .MuiMenuItem-root": {
    borderRadius: 6,
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#919eab14",
    },
  },
  "& .MuiListSubheader-root": {
    paddingLeft: 8,
    paddingRight: 8,
  },
});

const StyledIconDelete = styled(IconButton)({
  "&:hover": {
    backgroundColor: "#fdf3f5",
  },
});

const StyledButtonTime = styled(Button)(({ status, color }) => ({
  borderRadius: 8,
  color: "#212b36",
  borderColor: "#dcdcdc",
  ...(status === 0 && {
    borderColor: "#f5f5f5",
    color: "#212b36",
    backgroundColor: "#f5f5f5",
    "&:hover": {
      borderColor: "#f0f0f0",
      backgroundColor: "#f0f0f0",
    },
  }),
}));

const ITEM_HEIGHT = 48;

const StyledHolidayChip = styled(Chip)({
  margin: 0,
  fontSize: 14,
  fontWeight: 600,
  height: 26,
  backgroundColor: "#f7f5ff",
  color: "#7451ff",
})

const EmployeeShiftPanel = (props) => {

  const { selectedCompany } = props;

  const today = dayjs().toDate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [search, setSearch] = useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
  });

  const [showDate, setShowDate] = useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
  });
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const { isFetching: isFetchingEmployeeShiftList, result: employeeShiftList } = useSelector(
    (state) => state.employeeShift
  );

  const [drawerUploadEmployeeHolidayConfig, setDrawerUploadEmployeeHolidayConfig] = useState({
    isOpen: false,
    data: {}
  })

  const [selectedDate, setSelectedDate] = useState(null);

  const { isFetching: isFetchingShifts, result: shiftGroupList } = useSelector((state) => state.shift);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: holiday } = useSelector((state) => state.holiday);
  const [rowData, setRowData] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirmHoliday, setOpenConfirmHoliday] = useState(false);
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState(null);
  const [selectedTime, setSelectedTime] = React.useState(null);
  const [selectedHoliday, setSelectedHoliday] = React.useState(null);
  const [isFirstFetching, setIsFirstFetching] = React.useState(false);
  const [filterShiftType, setFilterShiftType] = useState({
    workday : [], weeklyHoliday : []
  });
  const [isChangeEmployee, setIsChangeEmployee] = useState(false);

  const handleSelectEmployee = (employee) => {
    setSelectedEmployee(employee);
    setIsChangeEmployee(true);
  };

  const handleOnClickSearch = () => {
    setShowDate(search);
    if (selectedEmployee) {
      dispatch(getTimeSceduleByIdEmployeesV2(selectedEmployee.idEmployees, {start: dayjs(search.start).format("YYYY-MM-DD"), end: dayjs(search.end).format("YYYY-MM-DD")}));
      dispatch(getHoliday({idCompany: selectedCompany.idCompany, start: dayjs(search.start).format("YYYY-01-01"), end: dayjs(search.end).format("YYYY-12-31")}));
    }
  };

  // useEffect(() => {
  //   // dispatch(getShift());
  //   // dispatch(allHoliday(dayjs(new Date()).format("YYYY")));
  // }, []);

  useEffect(() => {
    if(selectedCompany){
      handleSelectEmployee(null);
      dispatch(getShift({idCompany: selectedCompany.idCompany}));
      dispatch(getHoliday({idCompany: selectedCompany.idCompany, start: dayjs(search.start).format("YYYY-01-01"), end: dayjs(search.end).format("YYYY-12-31")}))
    }
  }, [selectedCompany])

  useEffect(() => {
    if(shiftGroupList && shiftGroupList.length > 0){
      let workDay = [];
      let weeklyHoliday = [];

      shiftGroupList.forEach((shiftGroup) => {
        if(shiftGroup.idWorkingType === 2){
          shiftGroup.shiftType.forEach((shiftType) => {
            if(shiftType.isWorkingDay === 1){
              workDay.push({...shiftType, shiftGroupName : shiftGroup.shiftGroupName, idShift: shiftGroup.shift[0].idShift});
            }else if(shiftType.isWorkingDay === 0){
              weeklyHoliday.push({...shiftType, shiftGroupName : shiftGroup.shiftGroupName, idShift: shiftGroup.shift[0].idShift});
            }
          });
        }
      });
      setIsFirstFetching(true);
      setFilterShiftType({ workday : workDay, weeklyHoliday : weeklyHoliday });
    }else{
      setFilterShiftType({ workday : [], weeklyHoliday : [] });
    }
  },[shiftGroupList]);

  useEffect(() => {
    if(
      !isFetchingEmployeeShiftList &&
      employeeShiftList
    ){
      setIsChangeEmployee(false);
    }
  },[employeeShiftList]);

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleClickChangeTime = (item) => {
    setSelectedTime(item);
    setOpenConfirm(true);
  };

  const handleClickChangeDayOff = (holiday) => {
    if(selectedDate){
      setSelectedTime({
        ...selectedDate.dataRender
      })
      setOpenConfirmHoliday(true);
      setAnchorEl(null);
    }
  };

  const handleConfirmHoliday = async () => {

    if(selectedDate.dataRender.isWorkingDay === 0){
      dispatch(openNotificationAlert({
        message: t("CannotChangeHolidayToPublicHoliday"),
        type: "error"
      }))
      handleCloseDialog();
      return 
    }

    if(selectedDate.requestChange && selectedDate.requestChange.find(item => item.isActive === 1 && item.isApprove === null)){
      dispatch(openNotificationAlert({
        message: t("CannotChangeDuplicatePendingApprovalItem"),
        type: "error"
      }))
      handleCloseDialog();
      return 
    }

    const dataForm = {
      idEmployees: selectedEmployee.idEmployees,
      idShift: selectedDate.dataRender.idShift,
      idShiftType: selectedDate.dataRender.idShiftType,
      workingDate: selectedDate.date,
      idShiftGroup: selectedDate.dataRender.idShiftGroup,
      isWorkingDay: selectedDate.dataRender.isWorkingDay,
      idHoliday: selectedHoliday,
      idShiftGroupPrev: selectedDate.dataRender.idShiftGroup,
      idShiftPrev: selectedDate.dataRender.idShift,
      idShiftTypePrev: selectedDate.dataRender.idShiftType,
      isWebsite: 1,
    };

    if(!dataForm.idShiftType){
      dispatch(openNotificationAlert({
        message: t("CannotChangeThisItem"),
        type: "error"
      }))
      handleCloseDialog();
      return 
    }

    const result = await dispatch(employeeChangeTime(dataForm));
    if (result && result.status === 200) {
      dispatch(getTimeSceduleByIdEmployeesV2(
        selectedEmployee.idEmployees,
        {
          start: dayjs(search.start).format("YYYY-MM-DD"),
          end: dayjs(search.end).format("YYYY-MM-DD"),
        },
      ))
      handleChangeAlertType("success");
      handleOpenAlert();
      handleCloseDialog();
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
      handleCloseDialog();
    }
  }

  const handleConfirm = async () => {
    console.log("selectedTime: ", selectedTime);
    console.log("selected: ", selected);

    if(selectedTime.isWorkingDay === 0 && selectedDate.dataRender.holiday){
      dispatch(openNotificationAlert({
        message: t("CannotChangeHolidayToPublicHoliday"),
        type: "error"
      }))
      handleCloseDialog();
      return 
    }

    if(selectedDate.requestChange && selectedDate.requestChange.find(item => item.isActive === 1 && item.isApprove === null)){
      dispatch(openNotificationAlert({
        message: t("CannotChangeDuplicatePendingApprovalItem"),
        type: "error"
      }))
      handleCloseDialog();
      return 
    }

    let dataForm = {
      idEmployees: selectedEmployee.idEmployees,
      idShift: selectedTime.idShift,
      idShiftType: selectedTime.idShiftType,
      workingDate: selected.item.date,
      idShiftGroup: selectedTime.idShiftGroup,
      isWorkingDay: selectedTime.isWorkingDay,
      idShiftGroupPrev: selectedDate.dataRender.idShiftGroup,
      idShiftPrev: selectedDate.dataRender.idShift,
      idShiftTypePrev: selectedDate.dataRender.idShiftType,
      isWebsite: 1,
    };
    // if (selectedTime.isWorkingDay === 0) {
    //   dataForm = {
    //     idEmployees: selectedEmployee.idEmployees,
    //     idShift: selected.item.dataRender.idShift,
    //     idShiftType: selectedTime.idShiftType,
    //     workingDate: selected.item.date,
    //     idShiftGroup: selectedTime.idShiftGroup,
    //     isWorkingDay: 0,
    //   };
    // } else {
    //   dataForm = {
    //     idEmployees: selectedEmployee.idEmployees,
    //     idShift: selectedTime.shift[0].idShift,
    //     idShiftType: selectedTime.shiftType.find((x) => x.isWorkingDay === 1)
    //       .idShiftType,
    //     workingDate: selected.item.date,
    //     idShiftGroup: selectedTime.idShiftGroup,
    //     isWorkingDay: 1,
    //   };
    // }

    const result = await dispatch(employeeChangeTime(dataForm));
    if (result && result.status === 200) {
      // dispatch(
      //   getTimescheduleByEmployees(
      //     {
      //       start: dayjs(search.start).format("YYYY-MM-DD"),
      //       end: dayjs(search.end).format("YYYY-MM-DD"),
      //     },
      //     selectedEmployee.idEmployees
      //   )
      // );
      dispatch(getTimeSceduleByIdEmployeesV2(
        selectedEmployee.idEmployees,
        {
          start: dayjs(search.start).format("YYYY-MM-DD"),
          end: dayjs(search.end).format("YYYY-MM-DD"),
        },
      ))
      handleChangeAlertType("success");
      handleOpenAlert();
      handleCloseDialog();
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
      handleCloseDialog();
    }
  };

  const renderShiftChange = (requestChange, mode) => {
    let request = null;
    if (mode === "wait") {
      request = requestChange.find((x) => x.isApprove === null);
    } else {
      request = requestChange.filter((x) => x.isApprove !== null);
      request = request[request.length - 1];
    }

    const foundHoliday = holiday.find(
      (x) => request && x.idHoliday === request.idHoliday
    );

    if (request) {
      const foundShiftType = request || null;

      return (
        <Fragment>
          {request.isActive && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <StatusRequest
                boxSize={40}
                fontSize={22}
                borderRadius={8}
                status={request.isApprove}
                active={request.isActive}
              />
              <Fragment>
                {request.isApprove !== null ? (
                  <div style={{ marginLeft: 8 }}>
                    <Typography variant="subtitle1" color="text.third">
                      <Typography
                        style={{ marginRight: 4, fontWeight: 500 }}
                        component="span"
                        color="text.primary"
                      >
                        {request.fillInChange
                          ? `${request.fillInChange_firstname_th} ${request.fillInChange_lastname_th}`
                          : request.fillInApprove 
                            ? `${request.fillInApprove_firstname_th} ${request.fillInApprove_lastname_th}`
                            : request.approveBy
                              ? `${request.firstname_TH} ${request.lastname_TH}`
                              : "-"
                        }
                      </Typography>
                      {request.isApprove === 1
                        ? request.fillInChange === null
                          ? `${t("Approved")}`
                          : `${t("Change")}`
                        : request.isApprove === 0
                        ? `${t("NotApproved")}`
                        : `${t("Acceptlist")}`}
                    </Typography>
                    {request.idHoliday && (
                      <Typography color="#7451FF" fontSize="12px" fontWeight="500">{request.holidayName}</Typography>
                    )}
                    <Typography color="text.secondary" variant="body2">
                      {`${t("ApprovedDate")}: ${
                        request.approveDate
                          ? dayjs(request.approveDate).format("DD/MM/BBBB HH:mm")
                          : "-"
                      }`}
                    </Typography>
                  </div>
                ) : (
                  <div style={{ marginLeft: 8 }}>
                    <div style={{ display: "flex" }}>
                      <Typography variant="subtitle1" color="text.third">
                        <Typography
                          style={{ marginRight: 4, fontWeight: 500 }}
                          component="span"
                          color="text.primary"
                        >
                          {foundShiftType ? 
                            (foundShiftType.isWorkingDay === 0)
                              ? foundHoliday
                                ? `${t("PublicHoliday")}: ${foundHoliday.name}`
                                : t("DateHoliday")
                              : foundShiftType.shiftGroupName
                            : t("NoData")+t("WorkingType")
                          }
                        </Typography>
                        {request.isApprove === 1
                          ? `${t("Approved")}`
                          : request.isApprove === 0
                          ? `${t("NotApproved")}`
                          : `${t("PendingApproval")}`}
                      </Typography>
                      <StyledIconDelete
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          handleClickDeleteButton(request);
                        }}
                      >
                        <HighlightOffRoundedIcon
                          style={{ color: "#e46a76" }}
                          fontSize="inherit"
                        />
                      </StyledIconDelete>
                    </div>
                    {request.idHoliday && (
                      <Typography color="#7451FF" fontSize="12px" fontWeight="500">{request.holidayName}</Typography>
                    )}
                    <Typography color="text.secondary" variant="body2">
                      {`${t("Create")}: ${dayjs(
                        request.createDateText,
                        "YYYY-MM-DD HH:mm"
                      ).format("DD/MM/BBBB HH:mm")}`}
                    </Typography>
                  </div>
                )}
              </Fragment>
            </div>
          )}
        </Fragment>
      );
    }
  };

  const getStatus = (item) => {

    if(item.requestChange && item.requestChange.length > 0){

      const lastestEmployeeShiftDaily = item.requestChange.filter(x => x.isApprove === 1 && x.isActive === 1);

      if(lastestEmployeeShiftDaily.length > 0){
        return lastestEmployeeShiftDaily[lastestEmployeeShiftDaily.length-1].isWorkingDay;
      }
    }

    return item.dataRender.isWorkingDay
  }

  const renderHoliday = (item) => {
    if (item.dataRender.isWorkingDay === 0) {
      if (item.dataRender.idHoliday > 0) {
        const foundHoliday = holiday.find(
          (x) => x.idHoliday === item.dataRender.idHoliday
        );
        return (
          <Fragment>
            {foundHoliday && (
              <Typography
                style={{
                  marginTop: 4,
                  marginBottom: 4,
                  fontSize: 14,
                  fontStyle: "oblique",
                }}
                color="text.secondary"
              >
                {foundHoliday.name}
              </Typography>
            )}
          </Fragment>
        );
      }
    }
  };

  const renderHolidayChip = (item) => {
    if(item.dataRender.holiday){
      if(item.dataRender.holiday.isCompensate){
        return (
          <StyledHolidayChip
            label={`ชดเชย ${item.dataRender.holiday.name}`}
          />
        )
      } else {
        return (
          <StyledHolidayChip
            label={`${item.dataRender.holiday.name}`}
          />
        )
      }
    }
  }



  const renderShiftTime = (item) => {

    if(item.requestChange && item.requestChange.length > 0){

      const lastestEmployeeShiftDaily = item.requestChange.filter(x => x.isApprove === 1 && x.isActive === 1);

      if(lastestEmployeeShiftDaily.length > 0){
        if(lastestEmployeeShiftDaily[lastestEmployeeShiftDaily.length-1].isWorkingDay === 1){
          return (
            <Fragment>
              <img src={Timer} alt="Timer" width={26} style={{ marginRight: 8 }} />
              {lastestEmployeeShiftDaily[lastestEmployeeShiftDaily.length-1].shiftGroupName}
            </Fragment>
          )
        } else {
          return `${t("DayOff")}`
        }
      }
    }

    if (item.dataRender.isWorkingDay === 0) {
      if (item.dataRender.idHoliday > 0) {
        const foundHoliday = holiday.find(
          (x) => x.idHoliday === item.dataRender.idHoliday
        );
        return (
          <Fragment>
            <Typography>{`${t("PublicHoliday")}`}</Typography>
          </Fragment>
        );
      } else {
        return `${t("DayOff")}`;
      }
    } else {
      return (
        <Fragment>
          <img src={Timer} alt="Timer" width={26} style={{ marginRight: 8 }} />
          {item.dataRender.shiftGroupName}
        </Fragment>
      );
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setOpenConfirm(false);
    setOpenConfirmHoliday(false);
    setOpenDialogConfirmDelete(false);
    setAnchorEl(null);
  };

  const handleClickButtonTime = (event, item) => {
    setSelectedDate(item)
    // console.log("selectedDate", item)
    if (item.isWaitingApprove) {
      alert(
        `${t("CannotProceedTransactionAsThereArePendingApprovalRequests")}`
      );
    } else {
      setAnchorEl(event.currentTarget);
      setSelected({ rowData, item });
    }
  };

  const handleClickDeleteButton = (request) => {
    setOpenDialogConfirmDelete(true);
    setSelected(request);
  };

  return (
    <StyledRoot>
      <ModalLoading
        open={isFetchingShifts && isFirstFetching}
      />
      <Grid
        container
        spacing={2}
        style={{ marginBottom: 16 }}
        alignItems={"center"}
      >
        <Grid item xs={12} sm={3}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              {t("StartDate")}
            </Typography>
            <div className="search-date">
              <DatePickerCustom
                minDate={new Date(new Date().getFullYear() - 4, 0, 1)}
                maxDate={search.end}
                inputFormat="DD/MM/YYYY"
                value={search.start}
                name="start"
                onChange={(newValue) => {
                  setSearch({ ...search, ["start"]: newValue });
                }}
              />
            </div>
          </StyledBoxSearch>
        </Grid>
        <Grid item xs={12} sm={3}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              {t("EndDate")}
            </Typography>
            <div className="search-date">
              <DatePickerCustom
                minDate={search.start}
                maxDate={new Date().setFullYear(new Date().getFullYear() + 1)}
                inputFormat="DD/MM/YYYY"
                value={search.end}
                name="end"
                onChange={(newValue) => {
                  setSearch({ ...search, ["end"]: newValue });
                }}
              />
            </div>
          </StyledBoxSearch>
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectEmployees
            value={selectedEmployee}
            handleChange={handleSelectEmployee}
            selectedCompany={selectedCompany}
            position={userProfile && userProfile.idCompany === 3}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <StyledBoxSearchButton>
            <div>
              <ButtonBlue
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={handleOnClickSearch}
              >
                {t("Search")}
              </ButtonBlue>
            </div>
          </StyledBoxSearchButton>
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <ButtonBlue
            variant="contained"
            onClick={() => {
              setDrawerUploadEmployeeHolidayConfig(prev => ({
                ...prev,
                isOpen: true
              }))
            }}
          >
            {t("UploadPublicHolidays")}
          </ButtonBlue>
        </Grid>
      </Grid>
      <CardStyle>
        <Box style={{ padding: "24px" }}>
          <Box style={{ display: "flex", position: "relative" }}>
            <StyledBox className={"close"}>
              <TableContainer style={{ width: "100%" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <StyledCellHeader
                        key="date"
                        align={"center"}
                        style={{ minWidth: 150 }}
                      >
                        {t("Date")}
                      </StyledCellHeader>
                      <StyledCellHeader
                        key="WorkingTime"
                        align={"center"}
                        style={{ minWidth: 200, maxWidth: 300 }}
                      >
                        {t("WorkingTime")}
                      </StyledCellHeader>
                      <StyledCellHeader
                        key="Status"
                        align={"center"}
                        style={{ minWidth: 250 }}
                      >
                        {t("Status")}
                      </StyledCellHeader>
                      <StyledCellHeader
                        key="Note"
                        align={"center"}
                        style={{ minWidth: 250, maxWidth: 300 }}
                      >
                        {t("Note")}
                      </StyledCellHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {holiday &&
                      selectedEmployee && 
                      !isChangeEmployee &&
                      employeeShiftList &&
                      employeeShiftList
                        .map((item, index) => (
                          <StyledRowContent key={`${index}`}>
                            <StyledCellContent align={"center"}>
                              <div>
                                <Typography
                                  variant="h6"
                                  style={{ fontSize: 16 }}
                                >
                                  {dayjs(item.date).format(
                                    i18n.resolvedLanguage === "th" ? "DD MMM BBBB" : "DD MMM YYYY"
                                  )}
                                </Typography>
                                <Typography color="text.secondary">{`(${dayjs(
                                  item.date
                                ).format("dddd")})`}</Typography>
                              </div>
                            </StyledCellContent>
                            <StyledCellContent align={"center"}>
                              <StyledButtonTime
                                style={{ width: 156 }}
                                variant="outlined"
                                status={getStatus(item)}
                                disableFocusRipple={true}
                                disableRipple={true}
                                aria-label="more"
                                onClick={(event) =>
                                  handleClickButtonTime(event, item)
                                }
                              >
                                {renderShiftTime(item)}
                              </StyledButtonTime>
                              {renderHoliday(item)}
                            </StyledCellContent>
                            <StyledCellContent>
                              {item.requestChange &&
                                item.requestChange.length > 0 &&
                                renderShiftChange(
                                  item.requestChange,
                                  "approve"
                                )}
                              {item.requestChange &&
                                item.requestChange.length > 0 &&
                                renderShiftChange(item.requestChange, "wait")}
                            </StyledCellContent>
                            <StyledCellContent align={"center"} style={{ minWidth: 250, maxWidth: 300 }}>
                              {renderHolidayChip(item)}
                            </StyledCellContent>
                          </StyledRowContent>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </StyledBox>
          </Box>
        </Box>
      </CardStyle>
      {anchorEl && selected && selected.item &&
        selected.item.date && selected.item.dataRender &&
        selected.item.dataRender.idCompany &&  selectedEmployee &&
        (
          <MenuShift 
            dataSend={{
              idEmployees: selectedEmployee.idEmployees,
              idCompany: selected.item.dataRender.idCompany,
              date: selected.item.date
            }}
            anchorEl={anchorEl}
            handleCloseMenu={handleCloseMenu}
            handleClickChangeTime={handleClickChangeTime}
            handleClickChangeDayOff={handleClickChangeDayOff}
          />
        )
      }
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      {selected && openConfirm && (
        <DialogConfirm
          open={openConfirm}
          handleClose={handleCloseDialog}
          text={`${t("ChangeWorkingTime")}`}
          content1={
            selected && `${dayjs(selected.item.date).format("D MMMM BBBB")}`
          }
          content2={
            selectedTime &&
            `${
              selectedTime.isWorkingDay === 0
                ? `${t("WeeklyHoliday")}`
                : selectedTime.shiftGroupName
            }`
          }
          handleConfirm={handleConfirm}
        />
      )}
      {selected && openConfirmHoliday && (
        <DialogConfirmDayOffHoliday
          open={openConfirmHoliday}
          handleClose={handleCloseDialog}
          text={`${t("ChangeWorkingTime")}`}
          content1={
            selected && `${dayjs(selected.item.date).format("D MMMM BBBB")}`
          }
          content2={selectedTime && `${t("PublicHoliday")}`}
          handleConfirm={handleConfirmHoliday}
          setSelectedHoliday={setSelectedHoliday}
          idHolidayPattern={(selectedTime && selectedTime.idHolidayPattern) || null}
          idCompany={(selectedTime && selectedTime.idCompany) || null}
          idEmployees={(selectedEmployee && selectedEmployee.idEmployees) || null}
          date={(selectedDate && selectedDate.date) || null}
        />
      )}

      <DrawerUploadEmployeeHoliday
        selectedCompany={selectedCompany}
        drawerConfig={drawerUploadEmployeeHolidayConfig}
        onClose={() => {
          setDrawerUploadEmployeeHolidayConfig(prev => ({
            ...prev,
            isOpen: false
          }))
        }}
        handleOnClickSearch={handleOnClickSearch}
      />
    </StyledRoot>
  );
};

export default EmployeeShiftPanel;
