import React, { Fragment, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm, useFieldArray } from "react-hook-form";

import { Divider, Grid, MenuItem, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Add, Delete } from "@mui/icons-material";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

import {
  addTaxDeductionSchedule,
  getTaxDeductionSchedule,
  updateTaxDeductionSchedule,
} from "../../../../../actions/taxDeduction";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";
import { useTranslation } from "react-i18next";

const DrawerDate = (props) => {
  const { selectedCompany, isReadOnly } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: TaxDeductionSchedule } = useSelector(
    (state) => state.taxDeductionSchedule
  );

  const [isSubmit, setIsSubmit] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      listTaxSchedule: [
        {
          startDateDate: dayjs().date(),
          startDateMonth: dayjs().month(),
          endDateDate: dayjs().add(1, "day").date(),
          endDateMonth: dayjs().add(1, "day").month(),
        },
      ],
    },
  });

  useEffect(() => {
    if (props.value) {
      reset({
        listTaxSchedule: [
          {
            startDateDate: dayjs(props.value.startDate).date(),
            startDateMonth: dayjs(props.value.startDate).month(),
            endDateDate: dayjs(props.value.endDate).date(),
            endDateMonth: dayjs(props.value.endDate).month(),
          },
        ],
      });
    }
  }, [props.value]);

  const { append, fields, remove } = useFieldArray({
    control,
    name: "listTaxSchedule",
  });

  const onHandleSubmit = async (value) => {
    setIsSubmit(true);
    let intersectionTime = false;

    let listAllTaxSchedule = [];
    if (TaxDeductionSchedule && TaxDeductionSchedule.length > 0) {
      listAllTaxSchedule = [...TaxDeductionSchedule];
    }

    let data = value.listTaxSchedule.map((schedule) => {
      schedule.startDate = dayjs()
        .date(schedule.startDateDate)
        .month(schedule.startDateMonth)
        .format("YYYY-MM-DD");
      schedule.endDate = dayjs()
        .date(schedule.endDateDate)
        .month(schedule.endDateMonth)
        .format("YYYY-MM-DD");
      let yearNow = dayjs().year();

      listAllTaxSchedule.map((s) => {
        if (
          (props.value
            ? props.value.idTaxDeductionSchedule !== s.idTaxDeductionSchedule
            : true) &&
          (dayjs(s.startDate)
            .year(yearNow)
            .isBetween(
              dayjs(schedule.startDate).year(yearNow),
              dayjs(schedule.endDate).year(yearNow),
              "day",
              "[)"
            ) ||
            dayjs(s.endDate)
              .year(yearNow)
              .isBetween(
                dayjs(schedule.startDate).year(yearNow),
                dayjs(schedule.endDate).year(yearNow),
                "day",
                "(]"
              ) ||
            dayjs(schedule.startDate)
              .year(yearNow)
              .isBetween(
                dayjs(s.startDate).year(yearNow),
                dayjs(s.endDate).year(yearNow),
                "day",
                "[)"
              ) ||
            dayjs(schedule.endDate)
              .year(yearNow)
              .isBetween(
                dayjs(s.startDate).year(yearNow),
                dayjs(s.endDate).year(yearNow),
                "day",
                "(]"
              ))
        ) {
          intersectionTime = true;
        }
      });

      schedule.idCompany = selectedCompany.idCompany;
      listAllTaxSchedule.push(schedule);

      return schedule;
    });

    if (intersectionTime) {
      dispatch(
        openNotificationAlert({
          type: "info",
          message: `${t("DuplicateData")}`,
        })
      );
      setIsSubmit(false);
    } else {
      let result = null;
      if (props.value) {
        data[0].idTaxDeductionSchedule = props.value.idTaxDeductionSchedule;
        result = await dispatch(updateTaxDeductionSchedule(data[0]));
      } else {
        result = await dispatch(
          addTaxDeductionSchedule({ listTaxSchedules: data })
        );
      }

      if (result.status === 200) {
        dispatch(
          openNotificationAlert({
            type: "success",
            message: `${TaxDeductionSchedule ? `${t("Edit")}` : `${t("Add")}`
              }${t("Success")}`,
          })
        );
        dispatch(getTaxDeductionSchedule({ idCompany: selectedCompany.idCompany }));
      } else {
        dispatch(
          openNotificationAlert({
            type: "error",
            message: `${TaxDeductionSchedule ? `${t("Edit")}` : `${t("Add")}`
              }${t("Failure")}`,
          })
        );
      }
      setIsSubmit(false);
      props.onClose();
    }
  };

  return (
    <DrawerCustom
      title={t("EditTaxAllowancePeriod")}
      anchor="right"
      open={props.open}
      onClose={props.onClose}
    >
      <div style={{ width: 350, padding: 24 }}>
        <form onSubmit={handleSubmit(onHandleSubmit)}>
          {fields.map((item, index) => (
            <Fragment key={item.id}>
              {!props.value && (
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <Typography variant="h6">
                      {`${t("Phase")} ${index + 1}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Delete
                      style={{ cursor: "pointer", color: "#d32f2f" }}
                      onClick={() => {
                        remove(index);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              <Typography style={{ marginBottom: 16 }}>
                {t("StartEditingDate")}
              </Typography>
              <Grid container spacing={2} style={{ marginBottom: "16px" }}>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name={`listTaxSchedule[${index}].startDateDate`}
                    render={({ field }) => (
                      <TextFieldTheme {...field} fullWidth select>
                        {watch(`listTaxSchedule[${index}].startDateMonth`) !==
                          null &&
                          watch(`listTaxSchedule[${index}].startDateMonth`) !==
                          undefined &&
                          [
                            ...Array(
                              dayjs()
                                .month(
                                  watch(
                                    `listTaxSchedule[${index}].startDateMonth`
                                  )
                                )
                                .daysInMonth()
                            ),
                          ].map((_, index) => (
                            <MenuItem key={index} value={index + 1}>
                              {index + 1}
                            </MenuItem>
                          ))}
                      </TextFieldTheme>
                    )}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    control={control}
                    name={`listTaxSchedule[${index}].startDateMonth`}
                    render={({ field }) => (
                      <TextFieldTheme
                        {...field}
                        fullWidth
                        select
                        onChange={(event) => {
                          field.onChange(event);
                          if (
                            watch(`listTaxSchedule[${index}].startDateDate`) >
                            dayjs().month(event.target.value).daysInMonth()
                          ) {
                            setValue(
                              `listTaxSchedule[${index}].startDateDate`,
                              dayjs().month(event.target.value).daysInMonth()
                            );
                          }
                        }}
                      >
                        {[...Array(12)].map((_, index) => (
                          <MenuItem key={index} value={index}>
                            {dayjs().month(index).format("MMMM")}
                          </MenuItem>
                        ))}
                      </TextFieldTheme>
                    )}
                  />
                </Grid>
              </Grid>
              <Typography style={{ marginBottom: 16 }}>
                {t("EndEditingDate")}
              </Typography>
              <Grid
                container
                spacing={2}
                style={{
                  marginBottom:
                    errors &&
                      errors.listTaxSchedule &&
                      errors.listTaxSchedule[index] &&
                      errors.listTaxSchedule[index].endDateDate
                      ? "8px"
                      : "0px",
                }}
              >
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name={`listTaxSchedule[${index}].endDateDate`}
                    rules={{
                      validate: {
                        isSameOrNext: (value) => {
                          let yearNow = dayjs().year();
                          return (
                            !dayjs()
                              .date(value)
                              .month(
                                watch(`listTaxSchedule[${index}].endDateMonth`)
                              )
                              .year(yearNow)
                              .isBefore(
                                dayjs()
                                  .date(
                                    watch(
                                      `listTaxSchedule[${index}].startDateDate`
                                    )
                                  )
                                  .month(
                                    watch(
                                      `listTaxSchedule[${index}].startDateMonth`
                                    )
                                  )
                                  .year(yearNow),
                                "date"
                              ) || `${t("PleaseEnterCorrectDate")}`
                          );
                        },
                      },
                    }}
                    render={({ field }) => (
                      <TextFieldTheme
                        {...field}
                        fullWidth
                        select
                        error={
                          errors &&
                            errors.listTaxSchedule &&
                            errors.listTaxSchedule[index] &&
                            errors.listTaxSchedule[index].endDateDate
                            ? true
                            : false
                        }
                      >
                        {watch(`listTaxSchedule[${index}].endDateMonth`) !==
                          null &&
                          watch(`listTaxSchedule[${index}].endDateMonth`) !==
                          undefined &&
                          [
                            ...Array(
                              dayjs()
                                .month(
                                  watch(
                                    `listTaxSchedule[${index}].endDateMonth`
                                  )
                                )
                                .daysInMonth()
                            ),
                          ].map((_, index) => (
                            <MenuItem key={index} value={index + 1}>
                              {index + 1}
                            </MenuItem>
                          ))}
                      </TextFieldTheme>
                    )}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    control={control}
                    name={`listTaxSchedule[${index}].endDateMonth`}
                    rules={{
                      validate: {
                        isSameOrNext: (value) => {
                          let yearNow = dayjs().year();
                          return (
                            !dayjs()
                              .date(
                                watch(`listTaxSchedule[${index}].endDateDate`)
                              )
                              .month(value)
                              .year(yearNow)
                              .isBefore(
                                dayjs()
                                  .date(
                                    watch(
                                      `listTaxSchedule[${index}].startDateDate`
                                    )
                                  )
                                  .month(
                                    watch(
                                      `listTaxSchedule[${index}].startDateMonth`
                                    )
                                  )
                                  .year(yearNow),
                                "date"
                              ) || `${t("PleaseEnterCorrectDate")}`
                          );
                        },
                      },
                    }}
                    render={({ field }) => (
                      <TextFieldTheme
                        {...field}
                        fullWidth
                        select
                        onChange={(event) => {
                          field.onChange(event);
                          if (
                            watch(`listTaxSchedule[${index}].endDateDate`) >
                            dayjs().month(event.target.value).daysInMonth()
                          ) {
                            setValue(
                              `listTaxSchedule[${index}].endDateDate`,
                              dayjs().month(event.target.value).daysInMonth()
                            );
                          }
                        }}
                        error={
                          errors &&
                            errors.listTaxSchedule &&
                            errors.listTaxSchedule[index] &&
                            errors.listTaxSchedule[index].endDateMonth
                            ? true
                            : false
                        }
                      >
                        {[...Array(12)].map((_, index) => (
                          <MenuItem key={index} value={index}>
                            {dayjs().month(index).format("MMMM")}
                          </MenuItem>
                        ))}
                      </TextFieldTheme>
                    )}
                  />
                </Grid>
              </Grid>
              <Typography style={{ color: "#d32f2f", fontSize: "0.75rem" }}>
                {(errors &&
                  errors.listTaxSchedule &&
                  errors.listTaxSchedule[index] &&
                  errors.listTaxSchedule[index].endDateMonth &&
                  errors.listTaxSchedule[index].endDateMonth.message) ||
                  (errors &&
                    errors.listTaxSchedule &&
                    errors.listTaxSchedule[index] &&
                    errors.listTaxSchedule[index].endDateDate &&
                    errors.listTaxSchedule[index].endDateDate.message) ||
                  ""}
              </Typography>
              <Divider
                style={{
                  borderBottom: "1px dash #000000",
                  margin: "16px 0px",
                }}
              />
            </Fragment>
          ))}
          {fields.length <
            4 - (TaxDeductionSchedule ? TaxDeductionSchedule.length : 0) &&
            !props.value && (
              <Fragment>
                <ButtonBlue
                  variant="contained"
                  style={{
                    width: "100%",
                    borderRadius: "25px",
                    marginBottom: "16px",
                  }}
                  onClick={() => {
                    append({
                      startDateDate: dayjs().date(),
                      startDateMonth: dayjs().month(),
                      endDateDate: dayjs().add(1, "day").date(),
                      endDateMonth: dayjs().add(1, "day").month(),
                    });
                  }}
                >
                  <Add />
                </ButtonBlue>
              </Fragment>
            )}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <ButtonBlue onClick={props.onClose} style={{ marginRight: 16 }}>
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue variant="contained" type="submit" disabled={isSubmit || isReadOnly}>
              {t("Save")}
            </ButtonBlue>
          </div>
        </form>
      </div>
    </DrawerCustom>
  );
};

export default DrawerDate;
