import rightsWelfareService from "../services/rightsWelfare.service";
import {
  WELFARE_RIGHTS_FETCHING,
  WELFARE_RIGHTS_FAILED,
  WELFARE_RIGHTS_SUCCESS,
  WELFARE_REMAININGRIGHT_FETCHING,
  WELFARE_REMAININGRIGHT_FAILED,
  WELFARE_REMAININGRIGHT_SUCCESS,
} from "./types";

export const getAllRightsEmployeeFamily = () => async (dispatch) => {
  try {
    dispatch({
      type: WELFARE_RIGHTS_FETCHING,
    });
    const res = await rightsWelfareService.getAllRightsEmployeeFamily();
    if (res) {
      dispatch({
        type: WELFARE_RIGHTS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: WELFARE_RIGHTS_FAILED,
    });
    console.log(err);
  }
};

export const getRemaingRightLimit = (data) => async (dispatch) => {
  try {
    dispatch({
      type: WELFARE_REMAININGRIGHT_FETCHING,
    });
    const res = await rightsWelfareService.getRemaingRightLimit(data);
    if (res) {
      dispatch({
        type: WELFARE_REMAININGRIGHT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: WELFARE_REMAININGRIGHT_FAILED,
    });
    console.log(err);
  }
};
