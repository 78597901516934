import { httpClient } from "./httpClient";

const allPayruns = (year, filter) => {
  if (year, filter) {
    return httpClient.get(`payment/payruns?year=${year}&filter=${filter}`);
  } else {
    return httpClient.get(`payment/payruns`);
  }
};

const getPayRunsId = (idPayrun) => {
  return httpClient.get("payment/payruns/" + idPayrun);
};

const createPayruns = (formData) => {
  return httpClient.post(`payment/payruns`, formData);
};

const getPayslip = (filter, date) => {
  return httpClient.get(`payment/payslip?filter=${filter}&date=${date}`);
};

const getPayslipByIdPayrunDetail = (idEmployees, date) => {
  return httpClient.get(`payment/payslip/${idEmployees}?date=${date}`);
};

const getPayslipByEmployee = (date, filter, query) => {
  return httpClient.get(`payment/payslip?date=${date}&filter=${filter}`, { params: query });
};

const deletePayroll = (dataForm) => {
  return httpClient.put(`payment/payruns`, dataForm);
};

const updateClosePayroll = (idPayrun) => {
  return httpClient.put(`payment/payruns/${idPayrun}/close`, idPayrun);
};

const updatePayrunDetail = (formData) => {
  return httpClient.put("payment/payruns", formData);
};

const getPayrunsDetailById = (idPayrun) => {
  return httpClient.get("payment/payruns/" + idPayrun + "/detail");
};

const uploadAddOn = (formData) => {
  return httpClient.post("payment/payruns/upload", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getAllEmployeesWithTaxData = () => {
  return httpClient.get(`payment/allemployeestax`);
}

const getPayrunsSummaryYear = (year, idCompany) => {
  return httpClient.get(`payment/payruns/summary-year/${year}/${idCompany}`);
};

const addBudget = (query) => {
  return httpClient.post(`payment/add-budget`, query);
}

const updateBudget = (query) => {
  return httpClient.put(`payment/update-budget`, query);
}

const getPayrunByMonthAndCompanyToCompare = (query) => {
  return httpClient.get(`payment/get-compared-payrun`, { params: query });
}

const getOverviewPayroll = (query) => {
  return httpClient.get(`payment/payroll-overview`, { params: query });
}

const getSsofile = (query) => {
  return httpClient.get(`payment/sso-file?payrun=${query}`);
}
const getPnd1file = (query) => {
  return httpClient.get(`payment/pnd1-file?payrun=${query}`);
}

export default {
  allPayruns,
  getPayRunsId,
  createPayruns,
  getPayslip,
  getPayslipByIdPayrunDetail,
  getPayslipByEmployee,
  getPayrunsSummaryYear,
  deletePayroll,
  updateClosePayroll,
  updatePayrunDetail,
  getPayrunsDetailById,
  uploadAddOn,
  getAllEmployeesWithTaxData,
  addBudget,
  updateBudget,
  getPayrunByMonthAndCompanyToCompare,
  getOverviewPayroll,
  getSsofile,
  getPnd1file
};
