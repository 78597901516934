import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Box,
  Typography,
  IconButton,
} from "@mui/material";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import DatePickerCustom from "../../../shared/date/datePicker";

import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import AlertResponse from "../../../shared/general/AlertResponse";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import {
  postHoliday,
  putHoliday,
  getHoliday,
  getAllHolidaysPattern
} from "../../../../../actions/holiday";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  width: 450,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});
const StyledWrapInput = styled("div")({
  marginBottom: 8,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const DialogContract = (props) => {
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { 
    addModal, 
    handleClose, 
    isReadOnly, 
    handleOpenAlert: handleOpenAlertCalendarPage,
    handleChangeAlertType: handleChangeAlertTypeCalendarPage,
    selectedCompany
  } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [value, setValue] = useState(false);
  const { t, i18n } = useTranslation();

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const [formData, setFormData] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (addModal.isOpen) {
      if (addModal.type === "Edit") {
        setFormData({
          name: addModal.data.name ? addModal.data.name : null,
          name_EN: addModal.data.name_EN ? addModal.data.name_EN : null,
          dateHoliday: addModal.data.dateHoliday,
          compensateName: addModal.data.compensateName
            ? addModal.data.compensateName
            : null,
          compensateDate: addModal.data.compensateDate
            ? addModal.data.compensateDate
            : null,
          idHoliday: addModal.data.idHoliday,
          isActive: addModal.data.isActive,
        });
        setValue(addModal.data.compensateDate ? true : false);
      } else {
        setFormData({
          name: null,
          name_EN: null,
          dateHoliday: null,
          compensateName: null,
          compensateDate: null,
          idHoliday: null,
          isActive: 1,
        });
        setValue(false);
      }
    }
  }, [addModal.isOpen]);

  const handleSubmit = async () => {
    if (!formData.name || !formData.dateHoliday) {
      handleOpenAlert(true);
      handleChangeAlertType(`${t("PleaseFillInRequiredInformations")}`);
    } else if (value && !formData.compensateDate) {
      handleOpenAlert(true);
      handleChangeAlertType(`${t("PleaseFillInCompensateDate")}`);
    } else {
      const data = {
        name: formData.name,
        name_EN: formData.name_EN,
        dateHoliday: dayjs(formData.dateHoliday).format("YYYY-MM-DD"),
        compensateName: formData.compensateName,
        compensateDate: dayjs(formData.compensateDate).format("YYYY-MM-DD"),
        holidayYear: dayjs(formData.dateHoliday).get("year"),
        isActive: formData.isActive,
        idHoliday: formData.idHoliday,
      };

      if (addModal.type === "Edit") {
        delete data.idCompany;
      }

      if (formData.compensateName === null || !value)
        delete data.compensateName;
      if (formData.compensateDate === null || !value)
        delete data.compensateDate;

      if (addModal.type === "Add") {
        const result = await dispatch(postHoliday(data));
        if (result && result.status === 200 && result.data.rows > 0) {
          handleOpenAlertCalendarPage();
          handleChangeAlertTypeCalendarPage("success");
          handleClose();
          dispatch(getAllHolidaysPattern({
            start: dayjs()
              .subtract(1, "year")
              .set("date", 1)
              .set("month", 0)
              .format("YYYY-MM-DD"),
            end: dayjs()
              .add(1, "year")
              .set("date", 31)
              .set("month", 11)
              .format("YYYY-MM-DD"),
            idCompany: selectedCompany.idCompany,
          }));
        } else {
          handleOpenAlert(true);
          handleChangeAlertType("error");
        }
      } else {
        const result = await dispatch(putHoliday(data));
        if (result && result.status === 200 && result.data.rows > 0) {
          handleOpenAlertCalendarPage();
          handleChangeAlertTypeCalendarPage("success");
          handleClose();
          dispatch(getAllHolidaysPattern({
            start: dayjs()
              .subtract(1, "year")
              .set("date", 1)
              .set("month", 0)
              .format("YYYY-MM-DD"),
            end: dayjs()
              .add(1, "year")
              .set("date", 31)
              .set("month", 11)
              .format("YYYY-MM-DD"),
            idCompany: selectedCompany.idCompany,
          }));
        } else {
          handleOpenAlert(true);
          handleChangeAlertType("error");
        }
      }
    }
  };

  return (
    <DrawerCustom
      title={`${t("HolidayDetails")}`}
      anchor="right"
      open={addModal.isOpen}
      onClose={handleClose}
    >
      {formData && (
        <StyledRoot>
          <StyledWrapInput>
            <StyledContentLabel color="text.third" gutterBottom>
              {t("HolidayName")}
            </StyledContentLabel>
            <TextFieldTheme
              variant="outlined"
              name="name"
              multiline
              value={formData.name}
              onChange={handleChange}
            />
          </StyledWrapInput>
          <StyledWrapInput>
            <StyledContentLabel color="text.third" gutterBottom>
              {`${t("HolidayName")} (English)`}
            </StyledContentLabel>
            <TextFieldTheme
              variant="outlined"
              name="name_EN"
              multiline
              value={formData.name_EN}
              onChange={handleChange}
            />
          </StyledWrapInput>
          <StyledWrapInput>
            <StyledContentLabel color="text.third" gutterBottom>
              {t("DateHoliday")}
            </StyledContentLabel>
            <DatePickerCustom
              value={formData.dateHoliday}
              inputFormat={`DD MMM ${i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"}`}
              name={"dateHoliday"}
              onChange={(newValue) => {
                setFormData({
                  ...formData,
                  ["dateHoliday"]: dayjs(newValue).format("YYYY-MM-DD"),
                });
              }}
              inputProps={{
                placeholder : i18n.resolvedLanguage === "th" ? "วว ดดด ปปปป" : "DD MMM YYYY",
                readOnly: true
              }}
              renderInput={(params) => (
                <StyledTextField fullWidth {...params} />
              )}
            />
          </StyledWrapInput>
          <StyledWrapInput>
            <StyledContentLabel color="text.third" gutterBottom>
              {t("Use")}
            </StyledContentLabel>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={formData.isActive}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    ["isActive"]: eval(event.target.value),
                  });
                }}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label={`${t("Use")}`}
                />
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label={`${t("NotInUse")}`}
                />
              </RadioGroup>
            </FormControl>
          </StyledWrapInput>
          <StyledWrapInput>
            <StyledContentLabel color="text.third" gutterBottom>
              {t("CompensateHolidayName")}
            </StyledContentLabel>
            <TextFieldTheme
              variant="outlined"
              name="compensateName"
              multiline
              value={formData.compensateName}
              onChange={handleChange}
            />
          </StyledWrapInput>
          <StyledWrapInput>
            <StyledContentLabel color="text.third" gutterBottom>
              {t("CompensateHolidayDate")}
            </StyledContentLabel>
            <DatePickerCustom
              value={formData.compensateDate}
              inputFormat={`DD MMM ${i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"}`}
              name={"compensateDate"}
              onChange={(newValue) => {
                setFormData({
                  ...formData,
                  ["compensateDate"]: dayjs(newValue).format("YYYY-MM-DD"),
                });
              }}
              inputProps={{
                placeholder : i18n.resolvedLanguage === "th" ? "วว ดดด ปปปป" : "DD MMM YYYY",
                readOnly: true
              }}
              renderInput={(params) => (
                <StyledTextField 
                  fullWidth {...params}
                />
              )}
            />
          </StyledWrapInput>
          <StyledFooter>
            <ButtonBlue variant="text" className="cancel" onClick={handleClose}>
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue variant="contained" onClick={handleSubmit} autoFocus disabled={isReadOnly}>
              {t("SaveData")}
            </ButtonBlue>
          </StyledFooter>
        </StyledRoot>
      )}
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </DrawerCustom>
  );
};

export default DialogContract;
