import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Box,
  styled,
  Typography,
  FilledInput,
  Grid,
  Chip,
  FormControl,
  InputAdornment,
} from "@mui/material";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import MoreTimeRoundedIcon from "@mui/icons-material/MoreTimeRounded";
import SearchIcon from "@mui/icons-material/Search";
import TableCustom from "../../../shared/tableCustom";
import DatePickerCustom from "../../../shared/date/datePicker";

import { getUserFullName } from "../../../../../utils/userData";

import { getAttendanceUploadFinger } from "../../../../../actions/attendance";

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "&.group-employee": {
    marginTop: 0,
  },
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginTop: 16,
    marginBottom: 8,
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
  },
});

const StyledBoxButton = styled(Box)({
  marginTop: 50
});



const StyledChipTime = styled(Chip)({
  height: 28,
  width: 85,
  fontSize: 14,
  borderRadius: 8,
  border: "none",
  "&.check-in": {
    color: "#229a16",
    backgroundColor: "#54d62c29",
    "& .MuiSvgIcon-root": {
      color: "#229a16",
      fontSize: 20,
    },
  },
  "&.check-out": {
    color: "#b72136",
    backgroundColor: "#ff484229",
    "& .MuiSvgIcon-root": {
      color: "#b72136",
      fontSize: 20,
    },
  },
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const UploadTimePage = (props) => {
  const { selectedCompany, search, setSearch } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: attendanceUploadList } = useSelector(
    (state) => state.attendanceUpload
  );
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchName, setSearchName] = useState(null);

  const columns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography>{getUserFullName(row)}</Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("Date")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Typography align="center">
          {dayjs(row.attendanceDateTimeText, "DD/MM/YYYY HH:mm").format(
            i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
          )}
        </Typography>
      ),
    },
    {
      name: `${t("Time")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <StyledChipTime
            icon={<MoreTimeRoundedIcon />}
            label={dayjs(row.attendanceDateTimeText, "DD/MM/YYYY HH:mm").format("HH:mm")}
            variant="outlined"
            className={row.isCheckIn ? "check-in" : "check-out"}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getAttendanceUploadFinger(search, selectedCompany.idCompany));
  }, []);

  useEffect(() => {
    if (searchName && attendanceUploadList) {
      let resultFilter = attendanceUploadList.filter((item, index) => {
        if (
          (item.firstname_TH && item.firstname_TH.indexOf(searchName) >= 0) ||
          (item.lastname_TH && item.lastname_TH.indexOf(searchName) >= 0) ||
          (item.firstname_EN &&
            item.firstname_EN.toLowerCase().indexOf(searchName.toLowerCase()) >=
              0) ||
          (item.lastname_EN &&
            item.lastname_EN.toLowerCase().indexOf(searchName.toLowerCase()) >=
              0) ||
          (item.employeeID && item.employeeID.indexOf(searchName) >= 0)
        )
          return item;
      });
      setData(resultFilter);
    } else {
      setData(attendanceUploadList);
    }
  }, [searchName, attendanceUploadList]);

  const handleChange = (event) => {
    setSearchName(event.target.value);
  };

  const handleOnClickSearch = () => {
    dispatch(getAttendanceUploadFinger(search, selectedCompany.idCompany));
  };

  return (
    <Fragment>
      <div style={{ marginBottom: 16 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("StartDate")}
              </Typography>
              <div className="search-date">
                <DatePickerCustom
                  minDate={dayjs().subtract(4, "year")}
                  maxDate={dayjs()}
                  inputFormat="D MMM YYYY"
                  value={search.start}
                  name="start"
                  onChange={(newValue) => {
                    setSearch({ ...search, ["start"]: newValue });
                  }}
                />
              </div>
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={3}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("EndDate")}
              </Typography>
              <div className="search-date">
                <DatePickerCustom
                  minDate={dayjs(search.start)}
                  maxDate={dayjs()}
                  inputFormat="D MMM YYYY"
                  value={search.end}
                  name="end"
                  onChange={(newValue) => {
                    setSearch({ ...search, ["end"]: newValue });
                  }}
                />
              </div>
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={3}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("Search")}
              </Typography>
              <FormControl fullWidth variant="filled">
                <StyledFilledInput
                  id="filled-sarch-name"
                  placeholder={`${t("Search")}${t("FullName")}`}
                  name="searchName"
                  onChange={handleChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={3}>
            <StyledBoxButton>
              <ButtonBlue
                onClick={handleOnClickSearch}
                variant="contained"
                startIcon={<SearchIcon />}
              >
                {t("Search")}
              </ButtonBlue>
            </StyledBoxButton>
          </Grid>
        </Grid>
      </div>
      <div>
        <TableCustom
          columns={columns}
          rows={data ? data : []}
          canSelect
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </Fragment>
  );
};

export default UploadTimePage;
