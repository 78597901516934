import {
  INDIVIDUAL_OBJECTIVE_FETCHING,
  INDIVIDUAL_OBJECTIVE_FAILED,
  INDIVIDUAL_OBJECTIVE_SUCCESS,
} from "../actions/types";

const initialState = {
  result: [], // กำหนดค่าเริ่มต้นเป็น array ว่าง
  loading: false,
  error: null,
};

const individualObjectivesReducer = (state = initialState, action) => {
  switch (action.type) {
    case INDIVIDUAL_OBJECTIVE_FETCHING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case INDIVIDUAL_OBJECTIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload, // สมมุติว่า payload เป็น array ของ objectives
      };
    case INDIVIDUAL_OBJECTIVE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload || "Failed to fetch objectives",
      };
    default:
      return state;
  }
};

export default individualObjectivesReducer;
