import Quill from 'quill';

const DividerBlot = Quill.import('blots/block');

class Divider extends DividerBlot {
  static create(value) {
    let node = super.create();
    node.classList.add('divider');
    return node;
  }

  static formats(node) {
    return {};
  }

  static value(node) {
    return null; 
  }
}
Divider.blotName = 'divider';
Divider.tagName = 'div';

export default Divider;
