
const colors = ["black", "white", "red","green","blue","orange","violet"]
const formats = [
    [
        {
            className:"ql-font",
            options:[
                'THSarabunNew',
            ]
        },
        {
            className:"ql-size",
            options: ['14px','16px','18px','20px','22px','24px','26px','28px']
        },
    ],
    [
        {className:"ql-bold"},
        {className:"ql-underline"},       
        {
            className:'ql-align',
            options:['','right','center','justify']
        },
    ],
    [

    ],
    [
      
    ],
]

export default formats