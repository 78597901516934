import React, { useState, useEffect, Fragment } from 'react';
import { createAPIEndpoint, ENDPOINTS } from '../../../../services/api';
import Notification from '../../shared/general/Notification';

import {
  Dialog,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
  Divider,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";

import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(() => ({
  dialog: {
    padding: 16,
    position: 'absolute',
    top: 80,
  },
  dialogTitle: {
    borderBottom: '1px solid #dee2e6',
    color: '#222f3e',
    padding: '8px 16px 8px 16px',
  },
  dialogContent: {
    color: '#798294',
    fontWeight: 600,
    paddingTop: 24,
    paddingLeft: 0,
    paddingRight: 0,
  },
  divider: {
    marginTop: 32,
    marginBottom: 16,
  },
  divider_department: {
    marginTop: 32,
    marginBottom: 32,
  },
  addBtn: {
    backgroundColor: '#2f74eb',
    color: '#fff',
    fontWeight: 700,
    height: '40px',
    borderRadius: '25px',
    paddingLeft: 32,
    paddingRight: 32,
    '&:hover': {
      backgroundColor: '#2a68d3',
    },
  },
  textDisabled: {
    backgroundColor: '#f5f5f5',
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 16,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: "#9e9e9e",
  },
  headTitle: {
    fontSize: '1.2rem',
    fontWeight: 600,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6' className={classes.headTitle}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 16,
  },
}))(MuiDialogContent);

const InitialValues = () => ({
  orgChartId: 0,
  id: '',
  parentId: '',
  name: '',
  title: '',
  position: '',
  department: '',
  img: '/',
  isDepartment: false,
  layer: '',
  role: 0,
  updateBy: 'Admin',
  dateUpdate: new Date(),
});

export default function AddChart(props) {
  const { addModal, setAddModal, chart, handleOnRefresh } = props;
  const classes = useStyles();
  const [employees, setEmployees] = useState([]);
  const [employeeSelected, setEmployeeSelected] = useState([]);
  const [parent, setParent] = useState([]);
  const [parentSelected, setParentSelected] = useState([]);
  const [values, setValues] = useState(InitialValues);

  const [errors, setErrors] = useState({
    name: false,
    employeeName: false,
    parentId: false,
    layer: false,
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });

  // const OPTIONS_LIMIT = 5;
  // const defaultFilterOptions = createFilterOptions();

  // const filterOptions = (options, state) => {
  //   return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleOnIsDepartment = (e) => {
    //Reset State After Changed
    setValues({
      isDepartment: e.target.value,
    });
    setParentSelected([]);
  };

  const handleEmpSelected = (e, newValue) => {
    setEmployeeSelected(newValue);
    const newValues = { ...values };
    newValues.department = newValue.department;
    newValues.position = newValue.jobPosition;
    newValues.section = newValue.section;
    setValues(newValues);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let newValues = { ...values };

    //if no data in database
    if (parent.length === 0) {
      newValues.parentId = '0';
    } else {
      newValues.parentId = parentSelected.id;
    }

    //IsDepartment is True
    if (newValues.isDepartment) {
      if (newValues.name === '' || newValues.parentId === undefined) {
        setErrors({
          ...errors,
          name: newValues.name ? false : true,
          parentId: newValues.parentId ? false : true,
        });
      } else {
        //Reset Errors state
        setErrors({
          name: false,
          parentId: false,
        });

        newValues.department = values.name;
        newValues.layer = 'Department';
        newValues.dateUpdate = new Date();
        newValues.updateBy = 'Admin';
        submitData(newValues);
      }
    } else {
      //IsDepartment is False
      newValues.name = employeeSelected.fullName;
      if (
        newValues.name === '' ||
        newValues.parentId === undefined ||
        newValues.layer === ''
      ) {
        setErrors({
          ...errors,
          employeeName: newValues.name ? false : true,
          parentId: newValues.parentId ? false : true,
          layer: newValues.layer ? false : true,
        });
      } else {
        //Reset Errors state
        setErrors({
          name: false,
          parentId: false,
        });

        //Image Employee
        newValues.img = '/uploads/empImages/' + employeeSelected.imagePath;
        submitData(newValues);
      }
    }
  };

  const submitData = (data) => {
    if (parent.length > 0) {
      var lastNode = parent[parent.length - 1];
      var number = lastNode.orgChartId + 1;

      data.id = 'OrgNode_' + number;
    } else {
      data.id = 'OrgNode_' + 1;
    }

    createAPIEndpoint(ENDPOINTS.ORGCHART)
      .create(data)
      .then((res) => {
        setAddModal({
          isOpen: false,
        });
        setNotify({
          isOpen: true,
          message: 'Add Successfully',
          type: 'success',
        });
        handleOnRefresh();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!addModal.isOpen) {
      //reset state
      setEmployeeSelected([]);
      setParentSelected([]);
      setValues(InitialValues);
      setErrors({
        name: false,
        parentId: false,
      });
    }
  }, [addModal]);

  useEffect(() => {
    // //Fetch Employees
    // createAPIEndpoint(ENDPOINTS.EMPLOYEE)
    //   .fetchAll()
    //   .then((res) => {
    //     //Add field Full Name
    //     res.data.forEach(function (element) {
    //       element.fullName = element.firstName + ' ' + element.lastName;
    //     });

    //     setEmployees(res.data);
    //   })
    //   .catch((err) => console.log(err));

    // //Fetch OrgChart
    // createAPIEndpoint(ENDPOINTS.ORGCHART)
    //   .fetchAll()
    //   .then((res) => {
    //     setParent(res.data);
    //   })
    //   .catch((err) => console.log(err));
  }, [chart]);

  return (
    <div className={classes.root}>
      <Dialog
        open={addModal.isOpen}
        maxWidth='sm'
        fullWidth
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle
          className={classes.dialogTitle}
          onClose={() => setAddModal({ ...addModal, isOpen: false })}
        >
          Add New Chart
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form onSubmit={handleOnSubmit}>
            <Grid container alignItems='center' spacing={4}>
              <Grid item xs={12} md={4} lg={4}>
                <Typography variant='body1'>Department Node</Typography>
              </Grid>
              <Grid item xs={12} md={8} lg={8}>
                <FormControl variant='outlined' size='small' fullWidth>
                  <Select
                    value={values.isDepartment}
                    onChange={handleOnIsDepartment}
                  >
                    <MenuItem value={false}>No</MenuItem>
                    <MenuItem value={true}>Yes</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Divider className={classes.divider_department} />
            {!values.isDepartment ? (
              <Fragment>
                <Grid container alignItems='center' spacing={4}>
                  <Grid item xs={12} md={4} lg={4}>
                    <Typography variant='body1'>Name</Typography>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    {/* <Autocomplete
                      filterOptions={filterOptions}
                      options={employees}
                      autoHighlight
                      getOptionLabel={(option) => option.fullName}
                      value={employeeSelected}
                      onChange={handleEmpSelected}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          margin='dense'
                          error={errors.employeeName}
                        />
                      )}
                    /> */}
                  </Grid>
                </Grid>
                <Grid container alignItems='center' spacing={4}>
                  <Grid item xs={12} md={4} lg={4}>
                    <Typography variant='body1'>Department</Typography>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    <TextField
                      variant='outlined'
                      size='small'
                      value={values.department}
                      className={classes.textDisabled}
                      disabled
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems='center' spacing={4}>
                  <Grid item xs={12} md={4} lg={4}>
                    <Typography variant='body1'>Position</Typography>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    <TextField
                      variant='outlined'
                      size='small'
                      value={values.position}
                      className={classes.textDisabled}
                      disabled
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems='center' spacing={4}>
                  <Grid item xs={12} md={4} lg={4}>
                    <Typography variant='body1'>Parent Of</Typography>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    {/* <Autocomplete
                      filterOptions={filterOptions}
                      options={parent}
                      autoHighlight
                      getOptionLabel={(option) => option.name}
                      value={parentSelected}
                      onChange={(e, newValue) => {
                        setParentSelected(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          margin='dense'
                          placeholder='Search Parent'
                          error={errors.parentId}
                        />
                      )}
                    /> */}
                  </Grid>
                </Grid>
                <Grid container alignItems='center' spacing={4}>
                  <Grid item xs={12} md={4} lg={4}>
                    <Typography variant='body1'>Layer</Typography>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    <FormControl variant='outlined' size='small' fullWidth>
                      <Select
                        value={values.layer}
                        error={errors.layer}
                        onChange={(e) =>
                          setValues({ ...values, layer: e.target.value })
                        }
                      >
                        <MenuItem value='CEO'>CEO</MenuItem>
                        <MenuItem value='Division'>Division</MenuItem>
                        <MenuItem value='Department'>Department</MenuItem>
                        <MenuItem value='Section'>Section</MenuItem>
                        <MenuItem value='Foreman'>Foreman</MenuItem>
                        <MenuItem value='NoLayer'>No Layer</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Fragment>
            ) : (
              <Fragment>
                <Grid container alignItems='center' spacing={4}>
                  <Grid item xs={12} md={4} lg={4}>
                    <Typography variant='body1'>Name</Typography>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    <TextField
                      variant='outlined'
                      size='small'
                      name='name'
                      value={values.name}
                      onChange={handleInputChange}
                      error={errors.name}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems='center' spacing={4}>
                  <Grid item xs={12} md={4} lg={4}>
                    <Typography variant='body1'>Parent Of</Typography>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    {/* <Autocomplete
                      filterOptions={filterOptions}
                      options={parent}
                      autoHighlight
                      getOptionLabel={(option) => option.name}
                      value={parentSelected}
                      onChange={(e, newValue) => {
                        setParentSelected(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          margin='dense'
                          error={errors.parentId}
                        />
                      )}
                    /> */}
                  </Grid>
                </Grid>
              </Fragment>
            )}
            <Divider className={classes.divider} />
            <Grid container justify='flex-end'>
              <Grid item>
                <Button
                  variant='contained'
                  type='submit'
                  className={classes.addBtn}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
