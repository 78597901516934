import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
    Box,
    Container,
    Typography,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material"; 
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import EditIcon from "@mui/icons-material/Edit";
import {
  getAllCompanyDocumentGroupName, 
  getUnionDocumentGroupAndTypeName,
  getAllCompanyDocumentType,
  deleteCompanyDocumentGroup
} from "../../../../actions/document";
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import CardStyle from "../../shared/general/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DialogType from "./dialogType";
import { useTranslation } from "react-i18next";
import EditCategoryDialog from "./alertDialog";
import Swal from 'sweetalert2';

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledCellHeader = styled(TableCell)({
    borderBottom: "1px dashed rgba(224, 224, 224, 1)",
    color: "#637381",
    backgroundColor: "#f4f6f8",
    "& .MuiTypography-root": {
      color: "#637381",
      backgroundColor: "#f4f6f8",
      "&.weekend": {
        fontStyle: "oblique",
      },
      "&.workday": {
        fontWeight: 600,
      },
    },
    "&.sticky": {
      padding: 0,
      position: "sticky",
      left: 0,
      zIndex: 4,
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
      boxShadow: "20px 0px 20px #EEEEEE",
      "& .MuiTableCell-root": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      },
    },
    "&:last-of-type": {
      paddingRight: 24,
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    },
  });

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 1000px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});
      
const StyledRoot = styled(Box)({
    backgroundColor: "#FFFFFF !important",
});

const StyledRowContent = styled(TableRow)({
    cursor: "pointer",
    textDecoration: "none",
    "&.MuiTableRow-hover:hover": {
      backgroundColor: "#f7f7f7",
      "& .sticky": {
        backgroundColor: "#f7f7f7",
      },
    },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
    borderBottom: "opx",
    padding: 8,
    "&.sticky": {
      position: "sticky",
      left: 0,
      backgroundColor: "#FFFFFF",
      boxShadow: "8px 20px 20px #EEEEEE",
    },
    "& .avatar": {
      minWidth: 240,
      "& .MuiAvatar-root": {
        marginRight: 8,
      },
      display: "flex",
      alignItems: "center",
    },
    "& .fullname": {
      fontWeight: 600,
    },
    "& .position": {
      color: theme.palette.text.secondary,
      fontSize: 14,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: 300,
    },
    "& .secondaryAction": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& .location": {
        fontWeight: 600,
      },
    },
    "&:first-of-type": {
      paddingLeft: 0,
    },
    "&:last-of-type": {
      paddingRight: 24,
    },
 }));

 const useStyles = makeStyles({
  FileDownloadOpen: {
      color: '#009900',
  },
  FileDownloadOff: {
      color: '#778899',
  }
});

const newDocumentCompany = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [formData, setFormData] = useState(null);
    const [open, setOpen] = useState(false);
    console.log('open: ', open);
    const [typeDialog, setTypeDialog] = useState(null);
    console.log('data opentypeDialog: ', typeDialog);
    const [reload, setreload] = useState();
    const { result: companyDocumentGroup} = useSelector((state) => state.companyDocumentGroup);
    const { result: allCompanyDocumentType} = useSelector((state) => state.allCompanyDocumentType);
    const { result: allUnionDocumentGroupTypeName} = useSelector((state) => state.allUnionDocumentGroupTypeName);

    useEffect(() => {
      dispatch(getAllCompanyDocumentGroupName());
      dispatch(getAllCompanyDocumentType());
      dispatch(getUnionDocumentGroupAndTypeName());
    }, [reload]);
    
    const mergedDocumentTypeGroups = (allUnionDocumentGroupTypeName || []).reduce((acc, { documentGroupName, documentTypeName }) => {
      if (!acc[documentGroupName]) {
        acc[documentGroupName] = { documentGroupName, documentTypeNames: [documentTypeName] };
      } else {
        acc[documentGroupName].documentTypeNames.push(documentTypeName);
      }
      return acc;
    }, {});
    
    const mergedDocumentGroups  = Object.values(mergedDocumentTypeGroups).map((item) => ({
      documentGroupName: item.documentGroupName, 
      documentTypeNames: item.documentTypeNames
    }));   
        
    const handleDialog = (type, formData, d) => {
        setTypeDialog(type);
        setOpen(true);
        if (formData) {
          setFormData(formData);
        }
    };

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [dialogType, setDialogType] = useState(null);
    console.log('data dialogType: ', dialogType);

    const handleCloseDialog = () => {
      setOpenDialog(false);
    };

    const handleFileClose = () => {
      if (reload === 1) setreload(2);
      else setreload(1);
    };

    const handleConfirmDelete = async (formData) => {
      setOpenDialog(false);
      try {
        const result = await dispatch(deleteCompanyDocumentGroup(formData));
        if (result) {
          Swal.fire({
            icon: 'success',
            title: 'ลบหมวดหมู่เอกสารสำเร็จ!!',
            showConfirmButton: false,
            timer: 1500,
          });
          handleFileClose();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'ไม่สามารถลบหมวดหมู่เอกสารได้!!',
            text: 'กรุณาลองใหม่อีกครั้ง',
            confirmButtonText: 'OK',
          });
        }
      } catch (error) {
        console.error('Error deleting document:', error);
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาดในการลบหมวดหมู่เอกสาร!!',
          text: 'กรุณาลองใหม่อีกครั้ง',
          confirmButtonText: 'OK',
        });
      } finally {
          handleCloseDialog();
      }
    }
    
    const handleClose = () => {
      setTypeDialog(null);
      setOpen(false);
    }

    const ReloadData = () => {
      if (reload === 1) setreload(2);
      else setreload(1);
    }
    
  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div
          style={{
            marginBottom: 8,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            className="wrap-search-action"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Typography variant="h4" style={{ paddingTop: 8 }}>
              {`${t("EditDocumentName")}`}
            </Typography>
          </div>
          <StyledBoxSearch>
            <div
              className="wrap-search-action"
              style={{ justifyContent: "flex-end" }}
            >
              <div>
                <ButtonBlue
                  variant="contained"
                  startIcon={<FileUploadOutlinedIcon />}
                  onClick={() => handleDialog("Category")}
                  style={{ marginRight: 10 }}
                >
                  {`${t("Editcategory")}`}
                </ButtonBlue>
                <ButtonBlue
                     variant="contained"
                     startIcon={<FileUploadOutlinedIcon />}
                     onClick={() => {
                       history.push(`/company-documents/add`);
                     }}
                     style={{ marginRight: 10 }}
                 >
                        {t("Upload Document")}
                 </ButtonBlue>
                <ButtonBlue
                  variant="contained"
                  startIcon={<FileUploadOutlinedIcon />}
                  onClick={() => handleDialog("Add")}
                >
                  {`${t("AddDocumentName")}`}
                </ButtonBlue>
              </div>
            </div>
          </StyledBoxSearch>
        </div>

        <StyledRoot>
          <Container maxWidth="lg">
            <CardStyle style={{ marginTop: 8 }}>
              <Box style={{ padding: "24px" }}>
                <Box style={{ display: "flex", position: "relative" }}>
                  <StyledBox className={"close"}>
                    <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <StyledCellHeader
                              align="center"
                              className="sticky"
                              style={{
                                 minWidth: 240,
                                  borderRight: "1px dashed rgba(224, 224, 224, 1)", 
                                  height: 60,
                                  userSelect: 'none'
                               }}
                            >
                              <Typography variant="body2" align="center">
                                {/* {`${t("DocumentName")}`} */}
                                 ชนิดเอกสาร
                              </Typography>
                            </StyledCellHeader>
                            <StyledCellHeader
                              align="center"
                              className="sticky"
                              style={{ 
                                minWidth: 240, 
                                borderRight: "1px dashed rgba(224, 224, 224, 1)", 
                                height: 60,
                                userSelect: 'none' 
                              }}
                            >
                              <Typography variant="body2" align="center">
                                {`${t("Category")}`}
                              </Typography>
                            </StyledCellHeader>
                            <StyledCellHeader
                              align="center"
                              className="sticky"
                              style={{ 
                                minWidth: 240, 
                                borderRight: "1px dashed rgba(224, 224, 224, 1)",
                                height: 60,
                                userSelect: 'none'
                             }}
                            >
                              <Typography variant="body2" align="center">
                                {`${t("Descriptions")}`}
                              </Typography>
                            </StyledCellHeader>
                            <StyledCellHeader
                              align="center"
                              className="sticky"
                              style={{ 
                                minWidth: 240, 
                                borderRight: "1px dashed rgba(224, 224, 224, 1)", 
                                height: 60,
                                userSelect: "none", 
                              }}
                            >
                              <Typography variant="body2" align="center">
                                {/* {`${t("OpenDowload")}`} */}
                                 เปิดให้ดาวโหลดเอกสาร
                              </Typography>
                            </StyledCellHeader>
                            <StyledCellHeader
                              align="center"
                              className="sticky"
                              style={{ 
                                minWidth: 50, 
                                borderRight: "1px dashed rgba(224, 224, 224, 1)", 
                                height: 60,
                                userSelect: "none", 
                              }}
                            >
                              <Typography 
                                variant="body2" 
                                align="center" 
                                style={{userSelect: 'none'}}
                              >
                                 {`${t("Edit")}`}
                              </Typography>
                            </StyledCellHeader>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {allCompanyDocumentType && allCompanyDocumentType !== ""
                            ? allCompanyDocumentType.map((row, index) => (
                                <StyledRowContent key={index}>
                                  <StyledCellContent
                                    className="sticky"
                                    style={{ borderBottom: "1px dashed rgba(224, 224, 224, 1)", borderRight: "1px dashed rgba(224, 224, 224, 1)" }}
                                  >
                                    <Typography className="fullname" align="center">
                                      {row.documentTypeName}
                                    </Typography>
                                  </StyledCellContent>
                                  <StyledCellContent
                                    className="sticky"
                                    style={{ borderBottom: "1px dashed rgba(224, 224, 224, 1)", borderRight: "1px dashed rgba(224, 224, 224, 1)" }}
                                   >
                                    <Typography  variant="body2" align="center">
                                        {row.documentGroupName}
                                    </Typography>
                                  </StyledCellContent>
                                  <StyledCellContent
                                    className="sticky"
                                    style={{ borderBottom: "1px dashed rgba(224, 224, 224, 1)", borderRight: "1px dashed rgba(224, 224, 224, 1)" }}
                                  >
                                    <Typography  variant="body2" align="center">
                                        {row.detail}
                                    </Typography>
                                  </StyledCellContent>
                                  <StyledCellContent
                                    className="sticky"
                                    align="center"
                                    style={{ borderBottom: "1px dashed rgba(224, 224, 224, 1)", borderRight: "1px dashed rgba(224, 224, 224, 1)" }}
                                  >
                                    { row.isDownload === 1 ? (
                                      <IconButton
                                        className={classes.FileDownloadOpen}
                                      >      
                                         <TaskAltIcon/>
                                      </IconButton>
                                     ) : (
                                      <IconButton    
                                         className={classes.FileDownloadOff}
                                      >      
                                         <FileDownloadOffIcon/>
                                      </IconButton>
                                     )}
                                  </StyledCellContent>
                                  <StyledCellContent
                                    className="sticky"
                                    style={{ 
                                      borderBottom: "1px dashed rgba(224, 224, 224, 1)", 
                                      borderRight: "1px dashed rgba(224, 224, 224, 1)",
                                      userSelect: "none",
                                    }}
                                    align="center"
                                  >
                                    <EditIcon onClick={() => handleDialog("edit", row)} />
                                  </StyledCellContent>
                                </StyledRowContent>
                              ))
                            : `${t("NoData")}`}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </StyledBox>
                </Box>
              </Box>
            </CardStyle>
          </Container>
        </StyledRoot>
      </Container>
      
      {open && typeDialog !== 'Category' ? (
        <DialogType
          open={open}
          setOpen={setOpen}
          setTypeDialog={setTypeDialog}
          mode={typeDialog}
          data={formData}
          companyDocumentGroup={companyDocumentGroup}
          handleClose={handleClose}
          ReloadData={ReloadData}
        />
      ) : (
        ""
      )}
      {open && typeDialog === 'Category' &&
      <EditCategoryDialog 
        open={open}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        mode={typeDialog}
        data={formData}
        dialogType={dialogType}
        selectedRow={selectedRow}
        setDialogType={setDialogType}
        setSelectedRow={setSelectedRow}
        handleConfirmDelete={handleConfirmDelete}
        handleCloseDialog={handleCloseDialog}
        handleDialog={handleDialog}
        mergedDocumentGroups={mergedDocumentGroups}
        onClose={handleClose}
        ReloadData={ReloadData}
      />
      }

    </StyledRoot>
  )
}

export default newDocumentCompany
