import React, { useState } from "react";
import { Alert, Snackbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../shared/general/ButtonBlue";

import utils, { getRoundNumber } from "../../../../utils";

import { addLeaveEmployees, getLeaveAvaiable } from "../../../../actions/leave";
import { getLeaveRequestAllYear } from "../../../../actions/employee";
import { openNotificationAlert } from "../../../../actions/notificationAlert";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  // width: 350,
  // padding: 24,
  padding: "16px",
  "& .wrap-footer-btn": {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 16,
  },
});

const StyledRow = styled("div")({
  marginBottom: 16,
});

const DialogConfirmLeave = (props) => {
  const {
    handleClose,
    data,
    handleChangeAlertType,
    handleOpenAlert,
    openConfirm,
    selectedLeaveRoundDate,
  } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const today = dayjs().toDate();
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrAfter);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: leaveTypeList } = useSelector((state) => state.leaveType);
  const { result: leaveAvailable } = useSelector((state) => state.leaveAvailable);
  const { result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    type: null,
    message: "",
  });

  const handleCloseNotify = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotify({
      ...notify,
      isOpen: false,
    });
  };

  const handleSubmit = async () => {

    setIsSubmitting(true);

    const foundLeaveType = leaveAvailable.find(
      (item) => Number(item.idLeaveType) === Number(data.idLeaveType)
    );
    const formData = {
      idLeaveType: data.idLeaveType,
      description: data.description,
      start: dayjs(data.startDate).format("YYYY-MM-DD HH:mm:ss"),
      end: dayjs(data.endDate).format("YYYY-MM-DD HH:mm:ss"),
      idEmployees: userProfile.idEmployees,
      // used: parseFloat(displayNumberLeaveDays().toFixed(2)),
      used: foundLeaveType.isLeaveCompensate ? data.hourUsed : data.used,
      // quota: found.leaveValue,
      quota: data.quota,
      // balance: parseFloat(Number(calculatorBalance()).toFixed(2)),
      balance: data.balance,
      // remaining: parseFloat(
      //   calculatorBalance() - displayNumberLeaveDays().toFixed(2)
      // ),
      remaining: data.remaining,
      // idManager: userProfile.idManagerLV1,
      idManager: data.idManager,
      // idManagerGroup: userProfile.idManagerGroupLV1,
      idManagerGroup: data.idManagerGroup,
      isApprove: null,
      // isFullDay: parseFloat(displayNumberLeaveDays().toFixed(2)) >= 1 ? 1 : 0,
      isFullDay: data.isFullDay,
      isActive: 1,
      idHoliday: data.idHoliday? data.idHoliday: null,
      cc_email: data.cc_email? data.cc_email: null,
    };

    const postData = new FormData();

    Object.keys(formData).map((k) => {
      postData.append(k, JSON.stringify(formData[k]));
    });

    if (data.file) {
      postData.append("file", data.file);
    }

    const result = await dispatch(addLeaveEmployees(postData));
    if (result) {
      if (result.status === 200) {
        if (result.data.errorCode === "ERROR_DUPLICATED") {
          dispatch(
            openNotificationAlert({
              type: "info",
              message: `${t("DuplicateData")}`,
            })
          );

        } else {
          dispatch(
            openNotificationAlert({
              type: "success",
              message: `${t("LeaveTransactionSuccessful")}`,
            })
          );
          dispatch(getLeaveAvaiable({date: selectedLeaveRoundDate}))
          dispatch(getLeaveRequestAllYear({filter: dayjs(selectedLeaveRoundDate).format("YYYY")}));
          openConfirm(false);
          handleClose();
        }
      }
    } else {
      openConfirm(false);
      dispatch(
        openNotificationAlert({
          type: "error",
          message: `${t("AnErrorOccurred")} ${t("ContactAdmin")}`,
        })
      );
    }

    setIsSubmitting(false);
  };

  const renderLeaveType = () => {
    const found = leaveAvailable.find(
      (item) => item.idLeaveType === data.idLeaveType
    );
    return found ? found.name : "-";
  };

  const calculatorBalance = () => {
    const found = leaveAvailable.find(
      (item) => item.idLeaveType === data.idLeaveType
    );
    if (leaveEmployeesList.length === 0) {
      return found ? found.leaveValue : 0;
    } else {
      const find = leaveEmployeesList.filter(
        (x) => x.idLeaveType === data.idLeaveType && x.isApprove !== 0
      );
      return find && found
        ? find.length > 0
          ? find[find.length - 1].remaining
          : found.leaveValue
        : 0;
    }
  };

  const displayNumberLeaveDays = () => {
    if (data.idLeaveType === 0) {
      return 0;
    } else {
      if (data.leaveDayType === "fullday") {
        if (dayjs(data.startDate).isSame(data.endDate, "date")) {
          return 1;
        } else {
          const date1 = dayjs(data.endDate);
          const diff = date1.diff(data.startDate, "day");
          return diff + 1;
        }
      } else {
        if (dayjs(data.startDate).isSame(data.endDate, "minute")) {
          return 1;
        } else {
          const date1 = dayjs(data.endDate);
          const diff = date1.diff(data.startDate, "minute");
          if (diff < 0) {
            return 0;
          } else {
            if (
              dayjs(data.endDate).isBetween(
                dayjs(data.endDate)
                  .set("hour", 12)
                  .set("minute", 0)
                  .set("second", 0),
                dayjs(data.endDate)
                  .set("hour", 12)
                  .set("minute", 59)
                  .set("second", 59),
                "minute",
                "(]"
              )
            ) {
              return (9 * 60) / 2 / 60 / 8;
            } else if (
              dayjs(data.endDate).isSameOrAfter(
                dayjs(data.endDate)
                  .set("hour", 12)
                  .set("minute", 59)
                  .set("second", 59),
                "minute"
              )
            ) {
              return (diff / 60 - 1) / 8;
            } else {
              return diff / 60 / 8;
            }
          }
        }
      }
    }
  };

  useState(() => {
    setIsSubmitting(false);
  }, [])

  return (
    <StyledRoot>
      <Typography variant="h6">{`${t("ConfirmLeaveTransaction")}`}</Typography>
      <div>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {`${t("LeaveType")}`}
          </Typography>
          <Typography variant="h6">
            {data && leaveAvailable && renderLeaveType()}
          </Typography>
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {`${t("StartDate")}`}
          </Typography>
          <Typography variant="h6">
            {data.leaveDayType === "fullday"
              ? dayjs(data.startDate).format("DD MMMM YYYY")
              : dayjs(data.startDate).format("DD MMMM YYYY HH:mm")}
          </Typography>
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {`${t("EndDate")}`}
          </Typography>
          <Typography variant="h6">
            {data.leaveDayType === "fullday"
              ? dayjs(data.endDate).format("DD MMMM YYYY")
              : dayjs(data.endDate).format("DD MMMM YYYY HH:mm")}
          </Typography>
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {`${t("TotalLeaveDays")}`}
          </Typography>
          <Typography variant="h6">
            {data.isLeaveCompensate
              ? `${getRoundNumber(data.hourUsed, 2, false)} ${t("Unit.Hours")}`
              : `${getRoundNumber(data.used, 2, false)} ${t("Unit.Days")} (${getRoundNumber(data.hourUsed, 2, false)} ${t("Unit.Hours")})`
            }
          </Typography>
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {`${t("RemainingLeaveEntitlement")}`}
          </Typography>
          <Typography variant="h6">
            {data.balance}{" "}
            {data.isLeaveCompensate ? `${t("Unit.Hours")}` : `${t("Unit.Days")}`}
          </Typography>
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {`${t("Note")}`}
          </Typography>
          {data.description ? (
            <Typography variant="h6">{data.description}</Typography>
          ) : (
            <Typography
              variant="h6"
              color="text.secondary"
              style={{ fontStyle: "oblique" }}
            >
              {`${t("UnspecifiedReason")}`}
            </Typography>
          )}
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {`${t("AttachFile")}`}
          </Typography>
          {data.file ? (
            <div style={{ textAlign: "center", marginTop: "16px" }}>
              <img
                alt="attach-file"
                src={URL.createObjectURL(data.file)}
                style={{ maxWidth: 300, maxHeight: 128, objectFit: "contain" }}
              />
            </div>
          ) : (
            <Typography
              variant="h6"
              color="text.secondary"
              style={{ fontStyle: "oblique" }}
            >
              {`${t("NoAttachedFile")}`}
            </Typography>
          )}
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {`${t("CCtoManager")}`}
          </Typography>
          {data.cc_email ? (
            <Typography
              style={{ fontStyle: "oblique", wordBreak: "break-word" }}
              variant="h6"
              whiteSpace="pre-line"
            >
              {data.cc_email}
            </Typography>
          ) : (
            <Typography
              variant="h6"
              color="text.secondary"
              style={{ fontStyle: "oblique" }}
            >
              {`${t("NoItems")}`}
            </Typography>
          )}
        </StyledRow>
      </div>
      <div className="wrap-footer-btn">
        <ButtonBlue
          size=""
          variant={"text"}
          color="secondary"
          onClick={() => {
            openConfirm(false);
          }}
        >
          {`${t("Cancel")}`}
        </ButtonBlue>
        <ButtonBlue onClick={handleSubmit} autoFocus variant={"contained"} disabled={isSubmitting}>
          {`${t("ConfirmLeaveTransaction")}`}
        </ButtonBlue>
      </div>

      {/* <Snackbar
        open={notify.isOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseNotify}
      >
        <Alert severity={notify.type} onClose={handleCloseNotify}>
          {notify.message}
        </Alert>
      </Snackbar> */}
    </StyledRoot>
  );
};

export default DialogConfirmLeave;
