import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, styled, Typography } from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import { updateUser, getUserProfile } from "../../../../actions/user";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  // width: 350,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const validationSchema = Yup.object({
  areaCode: Yup.number().nullable(true),
});

const DialogAddress = (props) => {
  const { open, handleCloseDialog, handleChangeAlertType, handleOpenAlert } =
    props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [state, setstate] = useState(false);

  useEffect(() => {
    if (userProfile) {
      setstate(false);
      formik.resetForm();
      formik.setFieldValue("idEmployees", userProfile.idEmployees);
      formik.setFieldValue("address", userProfile.address);
      formik.setFieldValue("houseNo", userProfile.houseNo);
      formik.setFieldValue("village", userProfile.village);
      formik.setFieldValue("villageNo", userProfile.villageNo);
      formik.setFieldValue("alley", userProfile.alley);
      formik.setFieldValue("road", userProfile.road);
      formik.setFieldValue("subDistrict", userProfile.subDistrict);
      formik.setFieldValue("district", userProfile.district);
      formik.setFieldValue("provience", userProfile.provience);
      formik.setFieldValue("areaCode", userProfile.areaCode);
    }
  }, [userProfile]);

  const formik = useFormik({
    initialValues: {
      idEmployees: "",
      houseNo: "",
      village: "",
      villageNo: "",
      alley: "",
      road: "",
      subDistrict: "",
      district: "",
      provience: "",
      areaCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = async () => {
    setstate(true);
    const result = await dispatch(updateUser(formik.values));
    if (result && result.status === 200) {
      handleChangeAlertType("success");
      handleOpenAlert();
      setTimeout(function () {
        handleCloseDialog();
        dispatch(getUserProfile());
      }, 3000);
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
    }
  };

  return (
    <DrawerCustom
      title={`${t("EditDataAddress")}`}
      anchor="right"
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        style: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <StyledRoot>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{`${t("HouseNo")}`}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="houseNo"
                value={formik.values.houseNo}
                fullWidth
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{`${t("Village")}`}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="village"
                value={formik.values.village}
                fullWidth
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{`${t("VillageNo")}`}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="villageNo"
                value={formik.values.villageNo}
                fullWidth
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{`${t("Alley")}`}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="alley"
                value={formik.values.alley}
                fullWidth
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{`${t("Road")}`}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="road"
                value={formik.values.road}
                fullWidth
                onChange={formik.handleChange}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{`${t("SubDistrict")}`}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="subDistrict"
                value={formik.values.subDistrict}
                fullWidth
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{`${t("District")}`}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="district"
                value={formik.values.district}
                fullWidth
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{`${t("Province")}`}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="provience"
                value={formik.values.provience}
                fullWidth
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{`${t("AreaCode")}`}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="areaCode"
                value={formik.values.areaCode}
                fullWidth
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleCloseDialog}>
              {`${t("Cancel")}`}
            </ButtonBlue>
            <ButtonBlue
              type="submit"
              variant="contained"
              disabled={!formik.isValid || state}
            >
              {`${t("SaveData")}`}
            </ButtonBlue>
          </StyledFooter>
        </StyledRoot>
      </form>
    </DrawerCustom>
  );
};

export default DialogAddress;
