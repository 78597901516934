import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from "@pdf-lib/fontkit";
import dayjs from 'dayjs';

export const KT20kPDFfile = async (type, selectedYear, data, contributionRate) => {
    const fontSize = 16;
    const url = `${process.env.REACT_APP_API_URL}files/${type}.pdf`;
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
    const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);

    const font = await pdfDoc.embedFont(fontBytes);
    const pages = pdfDoc.getPages();
    const page1 = pages[0];

    if (data.employeeDetails.length > 0 && data.employeeDetails[0].province) {
        page1.drawText(`${data.employeeDetails[0].province}`, { x: 170, y: 706, size: fontSize, font: font });
    }

    if (data.employeeDetails.length > 0 && data.employeeDetails[0].companyName) {
        page1.drawText(`${data.employeeDetails[0].companyName}`, { x: 165, y: 686, size: fontSize, font: font });
    }

    if (data.employeeDetails.length > 0 && data.employeeDetails[0].companyCode) {
        page1.drawText(`${data.employeeDetails[0].companyCode}`, { x: 140, y: 666, size: fontSize, font: font });
    }

    if (data.employeeDetails.length > 0 && data.employeeDetails[0].socialSecurityAccount) {
        page1.drawText(`${data.employeeDetails[0].socialSecurityAccount}`, { x: 440, y: 686, size: fontSize, font: font });
    }

    if (contributionRate) {
        page1.drawText(`${contributionRate}`, { x: 310, y: 666, size: fontSize, font: font });
    }

    if (data.employeeDetails.length > 0 && data.employeeDetails[0].mainContactPhone) {
        page1.drawText(`${data.employeeDetails[0].mainContactPhone}`, { x: 410, y: 666, size: fontSize, font: font });
    }

    const monthData = {
        Jan: `${selectedYear - 543}-01`,
        Feb: `${selectedYear - 543}-02`,
        Mar: `${selectedYear - 543}-03`,
        Apr: `${selectedYear - 543}-04`,
        May: `${selectedYear - 543}-05`,
        Jun: `${selectedYear - 543}-06`,
        Jul: `${selectedYear - 543}-07`,
        Aug: `${selectedYear - 543}-08`,
        Sep: `${selectedYear - 543}-09`,
        Oct: `${selectedYear - 543}-10`,
        Nov: `${selectedYear - 543}-11`,
        Dec: `${selectedYear - 543}-12`,
    };

    const xPosition = 83;
    const yPosition = 552;

    const drawTextForMonth = (page, item, x, y, offset) => {
        const totalWage = parseFloat(item.sum_salary + item.totalAdditions);
        const netWages = totalWage - parseFloat(item.sum_salary2);

        page.drawText(`${item.num_employees ? item.num_employees : " - "} `, {
            x: x,
            y: y - offset,
            size: fontSize,
            font: font,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${item.sum_salary ? item.sum_salary.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : " - "} `, {
            x: item.sum_salary > 10000000 ? x + 39 : x + 44,
            y: y - offset,
            size: fontSize,
            font: font,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${item.totalAdditions ? item.totalAdditions.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : " - "} `, {
            x: item.totalAdditions > 10000000 ? x + 195 : x + 200,
            y: y - offset,
            size: fontSize,
            font: font,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${item.sum_salary && item.totalAdditions ? totalWage.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : " - "} `, {
            x: totalWage > 10000000 ? x + 268 : x + 273,
            y: y - offset,
            size: fontSize,
            font: font,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${item.sum_salary2 ? item.sum_salary2.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : " - "} `, {
            x: item.sum_salary2 > 10000000 ? x + 340 : x + 345,
            y: y - offset,
            size: fontSize,
            font: font,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${netWages ? netWages.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : " - "} `, {
            x: x + 418,
            y: y - offset,
            size: fontSize,
            font: font,
            color: rgb(0, 0, 0),
        });
    };

    for (let i = 0; i < data.employeeDetails.length; i++) {
        const item = data.employeeDetails[i];
        const formattedDate = dayjs(item.monthPeriod).format("YYYY-MM");

        const monthOffsets = {
            [monthData.Jan]: 0,
            [monthData.Feb]: 18.8,
            [monthData.Mar]: 18.8 * 2,
            [monthData.Apr]: 18.8 * 3,
            [monthData.May]: 18.8 * 4,
            [monthData.Jun]: 18.8 * 5,
            [monthData.Jul]: 18.8 * 6,
            [monthData.Aug]: 18.8 * 7,
            [monthData.Sep]: 18.8 * 8,
            [monthData.Oct]: 18.8 * 9,
            [monthData.Nov]: 18.8 * 10,
            [monthData.Dec]: 18.8 * 11,
        };

        if (monthOffsets.hasOwnProperty(formattedDate)) {
            const offset = monthOffsets[formattedDate];
            drawTextForMonth(page1, item, xPosition, yPosition, offset);
        }
    }

    // total row
    const sum_salary = data.employeeDetails.reduce((acc, cur) => acc + cur.sum_salary, 0);
    const sum_salary2 = data.employeeDetails.reduce((acc, cur) => acc + cur.sum_salary2, 0);
    const sum_additions = data.employeeDetails.reduce((acc, cur) => acc + cur.totalAdditions, 0);
    const totalWage = sum_salary + sum_additions;
    const netWages = sum_salary + sum_additions - sum_salary2;

    page1.drawText(`${sum_salary ? sum_salary.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : " - "} `, {
        x: sum_salary > 100000000 ? 83 + 34 : sum_salary > 10000000 ? 83 + 39 : 83 + 44,
        y: 552 - (18.8 * 12),
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
    });

    page1.drawText(`${sum_additions ? sum_additions.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : " - "} `, {
        x: sum_salary2 > 10000000 ? 83 + 195 : 83 + 200,
        y: 552 - (18.8 * 12),
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
    });

    page1.drawText(`${sum_salary2 ? sum_salary2.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : " - "} `, {
        x: sum_salary2 > 10000000 ? 83 + 340 : 83 + 345,
        y: 552 - (18.8 * 12),
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
    });

    page1.drawText(`${totalWage ? totalWage.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : " - "} `, {
        x: totalWage > 10000000 ? 83 + 268 : 83 + 273,
        y: 552 - (18.8 * 12),
        size: 14,
        font: font,
        color: rgb(0, 0, 0),
    });

    page1.drawText(`${netWages ? netWages.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : " - "} `, {
        x: netWages > 10000000 ? 83 + 415 : 83 + 420,
        y: 552 - (18.8 * 12),
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
    });

    if (data && data.taxPayment) {
        page1.drawText(`${data.taxPayment[0].num_employees}`, { x: 93, y: 256, size: fontSize, font: font });
        page1.drawText(`${data.taxPayment[0].sum_totalEarnings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: 240, y: 256, size: fontSize, font: font });
        page1.drawText(`${data.taxPayment[0].sum_salary.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: 200, y: 236, size: fontSize, font: font });
        page1.drawText(`${data.taxPayment[0].sum_ot.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: 246, y: 217, size: fontSize, font: font });
    }

    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    window.open(docUrl, "_blank");
};
