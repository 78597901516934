import React, { useState, useEffect } from "react";
import { styled } from "@mui/styles";
import { Container, Grid } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import OKRDashboard from "../Dashboard/OKRDashboard";
import TeamDashboard from "../Dashboard/TeamDashboard";
import CascadeOKRView from "./CascadeOKRView";

import OrganizationChart from "@dabeng/react-orgchart";

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ padding: 2 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CascadeMain() {
  const [tabValue, setTabValue] = useState(0);

  const handleTabsChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const ds = {
    id: "n1",
    name: "Lao Lao",
    title: "general manager",
    children: [
      { id: "n2", name: "Bo Miao", title: "department manager" },
      {
        id: "n3",
        name: "Su Miao",
        title: "department manager",
        children: [
          { id: "n4", name: "Tie Hua", title: "senior engineer" },
          {
            id: "n5",
            name: "Hei Hei",
            title: "senior engineer",
            children: [
              { id: "n6", name: "Dan Dan", title: "engineer" },
              { id: "n7", name: "Xiang Xiang", title: "engineer" },
            ],
          },
          { id: "n8", name: "Pang Pang", title: "senior engineer" },
        ],
      },
      { id: "n9", name: "Hong Miao", title: "department manager" },
      {
        id: "n10",
        name: "Chun Miao",
        title: "department manager",
        children: [{ id: "n11", name: "Yue Yue", title: "senior engineer" }],
      },
    ],
  };

  return (
    <ContainerStyled>
      <div className="card-section">
        <div className="header-section">
          <Grid container>
            <Grid item>
              <h3 className="header-topic">Cascade View</h3>
            </Grid>
          </Grid>
        </div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabsChange}
              aria-label="basic tabs example"
            >
              <Tab label="Organization" {...a11yProps(0)} />
              <Tab label="Team" {...a11yProps(1)} />
              <Tab label="List" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <OKRDashboard />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <TeamDashboard />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <CascadeOKRView />
          </TabPanel>
        </Box>
      </div>
    </ContainerStyled>
  );
}

export default CascadeMain;
